import { css } from '@emotion/react';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import { SURVEY_ANSWERS_SHAPE, SURVEY_QUESTION_TYPES_SHAPE } from '~Common/const/proptypes';

import { freeformCardGray } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import FreeformResponse from '~Feedback/components/Print/FreeformResponse';
import ViewSurveyQuestionCard from '../Cards/ViewSurveyQuestionCard';
import MultipleChoiceBarChart from '../Charts/MultipleChoiceBarChart';
import RankingBarChart from '../Charts/RankingBarChart';

const styles = {
  container: css``,
  card: css`
    margin-top: 18px;
  `,
  cardBackground: (hasDarkBackground) => css`
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${hasDarkBackground && `
    background: ${freeformCardGray};
  `}
`,
  freeformCard: css`
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    border-radius: .3125rem;
  `,
  freeformInfo: css`
    margin-left: 14px;
  `,
  freeformAnswer: css``,
  freeformName: css`
    margin-top: 16px;
    ${withTruncate()}
  `,
};

const View = ({ byQuestions, questionTypes, ...props }) => (
  <div
    css={styles.container}
    {...props}
  >
    {byQuestions.map((byQuestion) => (
      <ViewSurveyQuestionCard
        css={styles.card}
        question={byQuestion.question}
        renderResponseContent={() => (
          <>
            {byQuestion.question.type.key === questionTypes.MultipleChoice.id && (
              <MultipleChoiceBarChart question={byQuestion.question} answers={byQuestion.participantsAndAnswers} />
            )}
            {byQuestion.question.type.key === questionTypes.CustomScale.id && (
              <RankingBarChart question={byQuestion.question} answers={byQuestion.participantsAndAnswers} />
            )}
            {byQuestion.question.type.key === questionTypes.ShortAnswer.id && (
            <>
              {byQuestion.participantsAndAnswers.map((participantAndAnswer) => (
                <FreeformResponse
                  userId={participantAndAnswer.participant.participant.uid}
                  richText={participantAndAnswer.answer?.answer || ''}
                  status={participantAndAnswer.participant.completedOn ? ANSWER_STATUS.COMPLETED : ANSWER_STATUS.INCOMPLETE}
                  key={participantAndAnswer.participant.participant.uid}
                  css={styles.freeformCard}
                />
              ))}
            </>
            )}
          </>
        )}
      />
    ))}
  </div>
);

View.propTypes = {
  byQuestions: SURVEY_ANSWERS_SHAPE.isRequired,
  questionTypes: SURVEY_QUESTION_TYPES_SHAPE.isRequired,
};

View.defaultProps = {};

const ByQuestionResults = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ByQuestionResults.propTypes = {};

ByQuestionResults.defaultProps = {};

export { View };
export default ByQuestionResults;
