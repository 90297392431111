import { css } from '@emotion/react';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';

import { palette } from '~Common/styles/colors';
import { withSpacedOutTitleStyles } from '~Common/styles/mixins';

const styles = {
  clearFiltersButton: (hasFilters: boolean, isMobile: boolean) => css({
    border: 'none',
    whiteSpace: 'nowrap',
    ':focus, :hover': {
      backgroundColor: 'transparent',
    },
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: 400,
    fontSize: '.625rem',
  }, hasFilters && {
    color: `${palette.brand.blue} !important`,
  }, isMobile && {
    display: 'none',
  }, withSpacedOutTitleStyles()),
};

interface ClearFiltersButtonProps extends Omit<LeadrButtonProps<'button'>, 'data-test-id' | 'variant' | 'size'> {
  hasFilters: boolean,
  isMobile?: boolean,
}

const ClearFiltersButton = ({
  hasFilters,
  isMobile = false,
  ...props
}: ClearFiltersButtonProps): JSX.Element => (
  <LeadrButton
    data-test-id="clearFiltersButton"
    variant="ghost"
    size="small"
    css={styles.clearFiltersButton(hasFilters, isMobile)}
    {...props}
  >
    CLEAR FILTERS
  </LeadrButton>
);

export default ClearFiltersButton;
