import { css } from '@emotion/react';
import { useInsightPrivileges } from '~Insights/hooks/useInsightPrivileges';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import { forMobileTinyObject } from '~Common/styles/mixins';
import ManagerToggle from './buttons/ManagerToggle';
import AdminSettingsButton from './buttons/AdminSettingsButton';

const styles = {
  toggleTab: (hasDirectReports: number) => css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    width: hasDirectReports ? '100%' : '50%',
  })),
};

interface ViewProps {
  isAdmin: boolean,
  hasDirectReports: number,
}

const View = ({
  isAdmin,
  hasDirectReports,
}: ViewProps): JSX.Element => (
  <ModuleTopbar
    moduleTopbarLayout="toggleTabsAndButtons"
    renderTabNavigationToggle={() => <ManagerToggle toggleTabStyles={styles.toggleTab(hasDirectReports)} />}
    renderRightSection={isAdmin ? () => (
      <AdminSettingsButton />
    ) : undefined}
  />
);

const InsightsHeader = ({ ...props }): JSX.Element => {
  const { isAdmin, isExecutive, hasDirectReports } = useInsightPrivileges();

  if (!isAdmin && !isExecutive) {
    return <></>;
  }

  const hookProps = {
    isAdmin,
    hasDirectReports,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default InsightsHeader;
