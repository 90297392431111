import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { REVIEWS } from '~Common/const/routes';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Card from '~Common/V3/components/Card';
import { navigateAction } from '~Deprecated/actions/navigate';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  Form,
  TextField,
} from '~Common/V3/components/uncontrolled';
import Froala from '~Common/V3/components/Froala';
import { usePrintView } from '~Common/stores/usePrintView';
import { ValidationErrors } from '~Goals/const/types';
import { toast } from '~Common/components/Toasts';
import {
  PAGE_STYLES, REVIEW_DISPLAY_STYLES, CENTER_CONTENTS, FORM_STYLES,
} from '../Const/pageStyles';
import { ReviewShape } from '../Const/types';
import { useSignoffReview } from '../Hooks/useSignoffReview';
import { ContextButtons } from '../Shared/ContextButtons';
import {
  FormValues, reviewSignoff, conformToDto, reviewSignoffFormSchema,
} from '../schemata/ReviewSignoff';
import { returnReviewParticipantType } from '../Hooks/returnReviewParticipantType';

const styles = {
  ...PAGE_STYLES,
  ...REVIEW_DISPLAY_STYLES,
  ...CENTER_CONTENTS,
  ...FORM_STYLES,
  spacerBottom: css({
    marginBottom: '1rem',
  }),
};

interface ViewProps {
  handleClick: () => void,
  formContext: UseFormReturn<FormValues>,
  signoffComment: string,
  runValidations: () => void,
  isPrintView: boolean,
}

const View = ({
  handleClick,
  formContext,
  signoffComment,
  runValidations,
  isPrintView,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="signoffButton"
      renderContents={() => (
        <LeadrButton
          onClick={handleClick}
          size="small"
          color="light"
          data-test-id="reviewsGoToSignOff"
        >
          Go to Sign-off
        </LeadrButton>
      )}
    />
    <Card
      css={[styles.baseCard(false), ...[isPrintView ? [] : [styles.centeredContent]]]}
      renderContents={() => (
        <>
          <div id="signoffArea" />
          <div
            css={styles.ReviewTitle}
          >
            Sign off Review
          </div>
          <p css={styles.signoffDescription}>Please sign and leave a comment </p>
          <Form
            formContext={formContext}
            onSubmit={() => null}
          >
            <TextField
              css={[styles.inputField, styles.fullInputField, styles.spacerBottom]}
              name="name"
              label="Your Name"
              disabled={isPrintView}
            />
            <Froala
              enableEmojis
              styleOverrides={styles.spacerBottom}
              label="Comment"
              name="signoffComment"
              isDisabled={isPrintView}
              froalaConfigProps={{
                charCounterCount: true,
                charCounterMax: 2000,
              }}
              richTextEditorProps={{
                name: 'signoffComment',
                onChange: ({ value: newText }) => formContext.setValue('signoffComment', newText, { shouldDirty: true }),
                initialValue: signoffComment,
              }}
            />
            <LeadrButton
              onClick={runValidations}
              data-test-id="reviewsSignOff"
              disabled={isPrintView}
            >
              Sign-off
            </LeadrButton>
          </Form>
        </>
      )}
    />
  </>
);

interface SignoffReviewProps {
  review: ReviewShape,
}

const SignoffReview = ({
  review,
}: SignoffReviewProps): JSX.Element => {
  const isPrintView = usePrintView((state) => state.isPrintView);
  const signoffReviewMutation = useSignoffReview();

  const handleClick = (): void => {
    const element = document.getElementById('signoffArea');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const dispatch = useDispatch();
  const onSuccessReviewSignoff = (): void => {
    dispatch(navigateAction({
      pathname: REVIEWS,
    }));
  };

  const { participantUid } = returnReviewParticipantType(review);
  const { participants } = review;
  const findCurrentParticipant = participants.find((participant) => (participant.uid === participantUid));

  const signoffComment = findCurrentParticipant?.signOffComment ?? '';

  const formValues = (): FormValues => ({
    signoffComment,
  });

  const formContext = useForm<FormValues>({
    defaultValues: formValues(),
    resolver: reviewSignoff,
  });

  const runValidations = (): void => {
    const data = formContext.getValues();
    reviewSignoffFormSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        const formData = conformToDto(data);
        signoffReviewMutation({ reviewId: review?.uid, signoffComment: formData }, { onSuccess: onSuccessReviewSignoff });
      })
      .catch((err: ValidationErrors) => {
        err.errors.forEach((error) => {
          toast.error(error);
        });
      });
  };

  const hookProps = {
    handleClick,
    runValidations,
    formContext,
    signoffComment,
    isPrintView,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default SignoffReview;
