import { ViewType } from '~Common/const/interfaces';
import { useGenericStoreAndLocalStorage } from '~Common/hooks/useGenericStoreAndLocalStorage';

export const useSurveyViewTyperFilter = () => {
  const [value, setValue] = useGenericStoreAndLocalStorage({
    localStorageKey: 'surveyViewTypeFilter',
    defaultValue: ViewType.GRID,
    genericStoreProps: {
      module: 'leadr',
      workflow: 'surveys',
      feature: 'surveys-view-type-filter',
    },
  });

  return [
    value,
    setValue,
  ];
};
