import './styles.scss';
import moment from 'moment-timezone';
import UserInfo from '../../../UserInfo';
import { MDYFormat } from '../../config';
import RichText from '../../../RichText';
import Froala, { FROALA_SINGLE_LINE_HEIGHT } from '~Common/components/Fields/RichTextEditors/Froala';

// TODO: Rewrite this with new UI in meetings
const FeedbackListItem = (props) => {
  const itemObj = props.queAnsData;

  const { owner, assignee } = props.user;

  return (
    <div className="feedback-list-item-wrapper">
      <div className="feedback-data-list">
        <div className="discussion">
          <div className="user-image">
            <UserInfo users={owner} />
          </div>
          <div>
            {!props.isEditable ? (
              <div className="due-date">
                {/* {moment(props.user.receivedDateTime).format(monthWithDate)} */}
                {/* {props.user.receivedDateInMillis && moment(props.user.receivedDateInMillis).format(MDYFormat)} */}
              </div>
            ) : ''}
            <div className="agenda-text-wrapper">
              <RichText text={itemObj.text} className="ql-editor editor-wrapper" />
            </div>
          </div>
        </div>
      </div>
      <div className="notes-list">
        <div className="feedback-input-wrapper">
          <div className="user-image pl-2">
            <UserInfo users={assignee} />
          </div>
          {props.isEditable ? (
            <div className="agenda-text-wrapper">
              <Froala
                initialValue=""
                onChange={({ value: text }) => props.handleChange(itemObj.id, '', 'sendResponsAndViewFeedback', true, text)}
                autoFocus={props.page?.toUpperCase() !== 'COACHING'}
                heightMin={FROALA_SINGLE_LINE_HEIGHT}
              />
            </div>
          ) : (
            <div className="agenda-text-wrapper">
              <div className="due-date">
                {props.isShowDate ? props.user.receivedDateInMillis && moment(props.user.receivedDateInMillis).format(MDYFormat) : ''}
              </div>

              {itemObj.response && <RichText text={itemObj.response[0].text} className="ql-editor editor-wrapper" />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default FeedbackListItem;
