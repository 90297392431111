import { RRule } from 'rrule';
import { RecurrenceDropdownOptions } from '~ActionItems/const/constants';
import { ActionItemRecurrenceFrequencyParams, MonthlyRecurrence } from '~ActionItems/hooks/useActionItemRecurrenceFrequency';

interface UseConvertRecurrenceRulesParams {
  recurrenceRule?: string | undefined;
}

type ReturnValue = Omit<ActionItemRecurrenceFrequencyParams, 'dueDate'>

const defaults: ReturnValue = {
  monthlyRecurrence: MonthlyRecurrence.ByDay,
  selectedRecurrence: RecurrenceDropdownOptions.Weekly,
  selectedDays: [],
};

export const convertRecurrenceRules = ({ recurrenceRule }: UseConvertRecurrenceRulesParams): ReturnValue => {
  if (!recurrenceRule) {
    return defaults;
  }

  const rule = RRule.fromString(recurrenceRule ?? '');
  const options = rule?.options;

  if (!options) {
    return defaults;
  }

  const {
    byweekday,
    interval,
  } = options;

  const intervalIsExplicitlySet = recurrenceRule.includes('INTERVAL');
  const byWeekdayIsExplicitlySet = recurrenceRule.includes('BYDAY');
  const isMonthly = recurrenceRule.includes('MONTHLY');

  const selectedRecurrence = isMonthly ? RecurrenceDropdownOptions.Monthly : RecurrenceDropdownOptions.Weekly;
  const monthlyRecurrence = byWeekdayIsExplicitlySet && isMonthly ? MonthlyRecurrence.ByWeekday : MonthlyRecurrence.ByDay;
  const recurrenceInterval = intervalIsExplicitlySet ? interval : undefined;
  const selectedDays = byWeekdayIsExplicitlySet && byweekday ? byweekday : [];

  return {
    monthlyRecurrence,
    recurrenceInterval,
    selectedDays,
    selectedRecurrence,
  };
};
