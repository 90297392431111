import { useGenericStore } from '~Common/hooks/useGenericStore';

interface ImpersonationStore {
  setValue: (value: boolean) => void,
  value: boolean,
}

interface UseImpersonationStoreReturn {
  isImpersonating: boolean,
  setIsImpersonating: (value: boolean) => void,
}

export const useImpersonationStore = (): UseImpersonationStoreReturn => {
  const { setValue: setIsImpersonating, value: isImpersonating } = useGenericStore({
    module: 'leadr',
    workflow: 'impersonation',
    feature: 'user-impersonation',
    defaultValue: !!window.sessionStorage.getItem('sessionId'),
  }) as ImpersonationStore;

  return {
    setIsImpersonating,
    isImpersonating,
  };
};
