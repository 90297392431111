// The commented out console.logs should be converted into a TRACE or DEBUG log in the future when we can do this.
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsFullyInitializedAction } from '~Deprecated/actions/featureFlags/setIsFullyInitialized';

const LaunchDarkly = (props) => {
  const {
    userDetail,
    userId,
    orgId,
    orgName,
    ldClient,
    flags,
    setFlagsAction,
    onFlagChange,
    isOverriding,
    orgUserId,
  } = props;

  const dispatch = useDispatch();
  const [isAnonymous, setIsAnonymous] = useState(true);
  const [identifiedAsAnonymous, setIdentifiedAsAnonymous] = useState(false);

  useEffect(() => {
    if (!isOverriding) {
      // console.log('setting flags: ', flags);
      setFlagsAction(flags);
      onFlagChange(flags);
    }
  }, [flags, setFlagsAction, onFlagChange, isOverriding]);

  useEffect(() => {
    // console.log('setting anonymous');
    setIsAnonymous(!(userDetail && userDetail.email));
  }, [userDetail]);

  // The way this is wired up, it's expecting to be mounted before we can determine who the user is to work properly
  // Not great, not bad. Just be aware
  useEffect(() => {
    if (isAnonymous) {
      if (!identifiedAsAnonymous) {
        // console.log('is anonymous');
        ldClient.identify({
          key: 0,
          anonymous: true,
        });
        setIdentifiedAsAnonymous(true);
      }
    } else if (identifiedAsAnonymous) {
      // console.log('is user');
      setIdentifiedAsAnonymous(false);

      const key = userId;
      const {
        firstName, lastName, profileImageUrl, email,
      } = userDetail;
      // Add user information to Launch Darkly.
      // TODO: We should look into adding Organization and Device contexts to the user, as seen here: https://docs.launchdarkly.com/sdk/client-side/javascript/migration-2-to-3#understanding-contexts
      ldClient.identify({
        kind: 'user',
        key,
        firstName,
        lastName,
        email,
        avatar: profileImageUrl,
        anonymous: false,
        organizationId: orgId,
        organizationName: orgName,
        organizationUserId: orgUserId,
        organization: `${orgId}|${orgName}`,
      }, undefined, () => {
        dispatch(setIsFullyInitializedAction(true));
      });
    }
  }, [
    userDetail,
    isAnonymous,
    ldClient,
    orgId,
    orgName,
    userId,
    identifiedAsAnonymous,
    orgUserId,
    dispatch,
  ]);

  return false;
};

export default LaunchDarkly;
