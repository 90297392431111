import { CardDetail } from '~Insights/const/types';

interface InsightCardProps {
  card: CardDetail,
  size: 'tiny' | 'dashboard' | 'expanded',
}

const InsightCard = ({
  card,
  size,
}: InsightCardProps): JSX.Element => {
  let component: JSX.Element | undefined = card.dashboardComponent;
  if (size === 'expanded') {
    component = card.expandedComponent;
  } else if (size === 'tiny') {
    component = card.tinyComponent;
  }

  if (!component) {
    return <></>;
  }

  return (
    <>
      {component}
    </>
  );
};

export default InsightCard;
