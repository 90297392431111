import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { palette } from '~Common/styles/colors';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { useMemo } from 'react';
import { MeetingTypeEnum, MeetingDetailsInterface } from '~Meetings/const/meetingsInterfaces';
import useGetAgendas, { AgendaItem } from '~Meetings/hooks/v2/useGetAgendas';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import moment from 'moment-timezone';
import { forMobileObject, hideForPrint, withPrintStylesObject } from '~Common/styles/mixins';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import PrintWrapper from '~Common/V3/components/Print/PrintWrapper';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { useMeetingActionItems } from '~ActionItems/hooks/useMeetingActionItems';
import { ActionItemSortField } from '~ActionItems/hooks/useActionItems';
import { SortDirection } from '~Common/const/interfaces';
import { ActionItem, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { Goals } from '@leadr-hr/types';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { useHidePrivateAgenda } from '../../stores/useHidePrivateAgenda';
import { MeetingInfoContext } from '../../providers/MeetingInfoContext';
import Header from './Header';
import Agenda from './Agenda';
import { filterOutPrivateAgendaItems } from '../../utils/filterOutPrivateAgendaItems';
import HidePrivateItemsToggle from './Header/HidePrivateItemsToggle';
import ActionItems from './ActionItems';
import ThematicGoal from './ThematicGoal';
import DefiningObjectives from './DefiningObjectives';
import StandardOperatingObjectives from './StandardOperationObjectives';

const styles = {
  body: css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '2.8125rem',
    pageBreakBefore: 'avoid',
  }, forMobileObject({
    display: 'none',
  }), withPrintStylesObject({
    display: 'grid',
  })),
  hidePrivateItemsToggle: css({
    marginTop: '.5rem',
    // marginBottom: '2.8125rem',
    pageBreakBefore: 'avoid',
    pageBreakAfter: 'avoid',
  }, hideForPrint()),
  section: css({
    pageBreakBefore: 'avoid',
  }),
  mobileBody: css({
    fontSize: '1.25rem',
    color: palette.neutrals.gray800,
    textAlign: 'center',
  }, hideForPrint()),
};

interface ViewProps {
  isLoading: boolean,
  agendas: AgendaItem[],
  title: string,
  isMobile: boolean,
  meetingStartTimeText: string,
  actionItems: ActionItem[],
  meetingInfoContext: MeetingDetailsInterface,
  thematicGoal?: string,
  definingObjectives?: Goals.Goal[],
  standardOperatingObjectives?: Goals.Goal[],
}

const View = ({
  isLoading,
  meetingInfoContext,
  agendas,
  title,
  meetingStartTimeText,
  isMobile,
  actionItems,
  thematicGoal,
  definingObjectives,
  standardOperatingObjectives,
}: ViewProps): JSX.Element => (
  <PrintWrapper>
    {isLoading && (
      <PrintWrapper.BasePrintHeaderSkeleton />
    )}
    {!isLoading && (
      <PrintWrapper.BasePrintHeader
        title={`Meetings - ${title}`}
        subTitle={meetingStartTimeText}
        printButtonDataTestId="printMeetings"
      />
    )}
    <PrintWrapper.BasePrintBody css={styles.section}>
      {isLoading && (
        <div>Loading...</div>
      )}
      {!isLoading && (
        <MeetingInfoContext.Provider value={meetingInfoContext}>
          <HidePrivateItemsToggle css={styles.hidePrivateItemsToggle} />
          <div css={styles.body}>
            <Header />
            {thematicGoal && (
              <ThematicGoal thematicGoalHTML={thematicGoal} />
            )}
            {definingObjectives && definingObjectives.length > 0 && (
              <DefiningObjectives definingObjectives={definingObjectives} />
            )}
            {standardOperatingObjectives && standardOperatingObjectives.length > 0 && (
              <StandardOperatingObjectives standardOperatingObjectives={standardOperatingObjectives} />
            )}
            {agendas.length > 0 && (
              <Agenda agendas={agendas} />
            )}
            {actionItems.length > 0 && (
              <ActionItems actionItems={actionItems} />
            )}
          </div>
          {isMobile && (
            <div css={styles.mobileBody}>
              <div>Mobile print preview not accommodated.</div>
              <br />
              <div>Click “Print” to view full print preview.</div>
            </div>
          )}
        </MeetingInfoContext.Provider>
      )}
    </PrintWrapper.BasePrintBody>
  </PrintWrapper>
);

interface UseParams {
  type: string, // Lowercase string of meeting type
  id: string,
}

const MeetingsPrint = ({ ...props }): JSX.Element => {
  const { type: meetingType, id: huddleId } = useParams<UseParams>();
  const isHidden = useHidePrivateAgenda((state) => state.isHidden);
  const isMobile = useIsMobileQuery();

  // TODO: Convert meetingType to uppercase?
  const { item: meetingDetails, isLoading: areMeetingDetailsLoading } = useMeetingDetails({
    id: huddleId,
    type: meetingType.toUpperCase() as MeetingTypeEnum,
  }) ?? {};

  const { data: teamDetailsData, isLoading: areTeamDetailsLoading } = useGetTeamDetails({ teamId: meetingDetails?.teamId });

  const params: Goals.Requests.GetGoalsRequestQueryParameters = {
    contextType: Goals.GoalContextType.Team,
    contextId: meetingDetails?.teamId,
    take: 100,
  };

  const { data: goalsData, isLoading: areGoalsLoading } = useGetGoals({ params, enabled: !!meetingDetails?.teamId });

  const meetingInfoContext = useMemo(
    () => ({
      ...(meetingDetails || {}),
    }),
    [meetingDetails],
  );

  const thematicGoal = useMemo(() => (
    teamDetailsData?.response.teamClarityAnswerList.find((teamClarityAnswer) => (
      teamClarityAnswer.rank === teamDetailsData.response.thematicGoalQuestionRank
    ))?.answer
  ), [
    teamDetailsData?.response,
  ]);

  const definingObjectives = useMemo(() => (
    goalsData?.response.filter((goal) => (goal.category === Goals.GoalCategory.DefiningObjectives))
  ), [
    goalsData?.response,
  ]);

  const standardOperatingObjectives = useMemo(() => (
    goalsData?.response.filter((goal) => (goal.category === Goals.GoalCategory.StandardOperatingObjectives))
  ), [
    goalsData?.response,
  ]);

  const { data: actionItemsData, isLoading: areActionItemsLoading } = useMeetingActionItems({
    factoryId: meetingDetails?.factoryId ?? '',
    sortByField: ActionItemSortField.DueDate,
    sortByOrder: SortDirection.Ascending,
    status: [
      NewActionItemStatus.ToDo,
      NewActionItemStatus.InProgress,
      NewActionItemStatus.Blocked,
    ],
  });

  const { data: agendaItems, isLoading: areAgendasLoading } = useGetAgendas({ huddleId });

  const [isLoading] = useSkeletonLoaders(areMeetingDetailsLoading || areAgendasLoading || areTeamDetailsLoading || areActionItemsLoading || areGoalsLoading);

  const actionItems = actionItemsData?.response ?? [];

  const agendas = useMemo(() => {
    const tempAgendas = agendaItems?.response.agendas ?? [];
    if (isHidden) {
      return filterOutPrivateAgendaItems(tempAgendas);
    }

    return tempAgendas;
  }, [agendaItems?.response.agendas, isHidden]);

  const { timezone } = useTimezone();

  const meetingStartTimeText = useMemo(() => (
    moment.tz(meetingDetails?.startTimeInMillis, timezone).format('MMMM Do, YYYY @ h:mm a')
  ), [meetingDetails?.startTimeInMillis, timezone]);

  const hookProps = {
    isLoading,
    meetingInfoContext: meetingInfoContext as MeetingDetailsInterface,
    agendas,
    title: meetingDetails?.title ?? '',
    meetingStartTimeText,
    isMobile,
    actionItems,
    thematicGoal,
    definingObjectives,
    standardOperatingObjectives,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingsPrint;
