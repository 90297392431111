import { css } from '@emotion/react';

import { LearningType, LearningPersonDisplayInformation } from '~Learning/const/interfaces';

import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';

import { OPTIMISTIC_ID } from '~Learning/const';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import GridCard from '~Common/components/Cards/GridCard';

const styles = {
  leadrCard: (isOptimisticUpdateCard: boolean) => css(
    isOptimisticUpdateCard && ({
      cursor: 'not-allowed',
    }),
  ),
};
interface GridLearningCardProps {
  learningType: LearningType,
  title: string,
  description: string,
  dueDate: string | undefined,
  assignedUsersInfo: LearningPersonDisplayInformation[],
  numberOfUsersAssigned: number,
  renderCompletedSection?: () => JSX.Element,
  onCardClick: () => void,
  isComplete: boolean,
  id: string | number,
}

const GridLearningCard = ({
  learningType,
  title,
  description,
  dueDate,
  assignedUsersInfo,
  numberOfUsersAssigned,
  renderCompletedSection,
  onCardClick,
  isComplete,
  id,
  ...props
}: GridLearningCardProps): JSX.Element => (
  <GridCard
    css={styles.leadrCard(id === OPTIMISTIC_ID)}
    onClick={id === OPTIMISTIC_ID ? undefined : onCardClick}
    title={title}
    feature="learning"
    description={description}
    assignedUsersInfo={assignedUsersInfo}
    numberOfUsersAssigned={numberOfUsersAssigned}
    renderHeader={() => (
      <LearningTypeInfo
        learningType={learningType}
      />
    )}
    renderFooter={() => (
      <>
        <DueDateFromNow
          dueDate={dueDate}
          isComplete={isComplete}
        />
        {renderCompletedSection?.()}
      </>
    )}
    {...props}
  />
);

export default GridLearningCard;
