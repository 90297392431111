import { PropTypes } from 'prop-types';
import 'react-image-crop/dist/ReactCrop.css';
import { v4 as uuidv4 } from 'uuid';
import {
  useCallback, useEffect, useState,
} from 'react';
import ImagePicker from '~Deprecated/ui/components/Pickers/ImagePicker';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import { useProfileImagePreSignedUrl } from '~Deprecated/hooks/fileUpload/useProfileImagePresignedUrl';

/**
 * @deprecated Old UI
 */
const ImageUpload = ({
  user, uploadFile, status, cancelRequest, ...props
}) => {
  const userName = `${user.firstName} ${user.lastName}`;
  const [resourceUrl, setResourceUrl] = useState(user.profileImageUrl);
  const [tempResourceUrl, setTempResourceUrl] = useState('');
  const [isLoading, setLoading] = useState(false);
  const {
    refetch: getSignedUrl,
  } = useProfileImagePreSignedUrl();

  useEffect(() => {
    if (status === 'success' && tempResourceUrl?.length > 0) {
      setResourceUrl(tempResourceUrl);
    }
  }, [tempResourceUrl, status]);

  const removeProfileImage = useCallback(() => {
    setResourceUrl('');
  }, []);

  const handleCroppedBlob = async (blob) => {
    setLoading(true);
    const { data: { response } } = await getSignedUrl();
    setLoading(false);
    uploadFile(response.signedUrl, new File([blob], `${uuidv4()}.png`));
    setTempResourceUrl(response.resourceUrl);
  };

  return (
    <>
      <ImagePicker
        initialValue={resourceUrl}
        userName={userName}
        handleCroppedBlob={handleCroppedBlob}
        onRemove={removeProfileImage}
        isLoading={isLoading || status === 'loading'}
        {...props}
      />
      <input type="hidden" name="profileImageUrl" value={resourceUrl} />
    </>
  );
};

ImageUpload.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  uploadFile: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  cancelRequest: PropTypes.func.isRequired,
};

ImageUpload.defaultProps = {};

export default ImageUpload;
