import { Dispatch, SetStateAction, useState } from 'react';
import moment from 'moment';
import { capitalize } from 'lodash';
import { ENPSDataForDate, ENPSStats } from '~Insights/hooks/useENPSData';
import { LineGraphChart } from '~Common/components/Charts/LineGraphChart';
import DateRangeSelect from '~Insights/components/DateRangeSelect';
import SidebarFilters, { SidebarFilterOptions } from '~Insights/components/SidebarFilters';
import { GraphDataset, makeDelta } from '~Insights/const/dataUtils';
import InsightsDelta from '~Insights/components/InsightsDelta';
import { EnpsCategory } from '~Insights/const/types';
import { BarChart } from '~Common/components/Charts/BarChart';
import { palette } from '~Common/styles/colors';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import { ExpandedProps, styles } from './Expanded';

function makeSidebarFilters(data: ENPSDataForDate[]): SidebarFilterOptions {
  if (data.length === 0) return [];

  if (data.length > 1) {
    const promoters: number[] = [];
    const passives: number[] = [];
    const detractors: number[] = [];
    data.forEach(({ scores, surveysAnswered }) => {
      promoters.push(surveysAnswered ? scores.promoters / surveysAnswered : 0);
      passives.push(surveysAnswered ? scores.passives / surveysAnswered : 0);
      detractors.push(surveysAnswered ? scores.detractors / surveysAnswered : 0);
    });

    const promotersDelta = makeDelta(promoters[0], promoters[promoters.length - 1]);
    const passivesDelta = makeDelta(passives[0], passives[passives.length - 1]);
    const detractorsDelta = makeDelta(detractors[0], detractors[detractors.length - 1]);

    return [
      {
        value: EnpsCategory.promoters,
        renderContents: () => (
          <>
            <InsightsDelta value={promotersDelta} />
            <p>Promoters</p>
          </>
        ),
      },
      {
        value: EnpsCategory.passives,
        renderContents: () => (
          <>
            <InsightsDelta value={passivesDelta} />
            <p>Passives</p>
          </>
        ),
      },
      {
        value: EnpsCategory.detractors,
        renderContents: () => (
          <>
            <InsightsDelta value={detractorsDelta} />
            <p>Detractors</p>
          </>
        ),
      },
    ];
  }
  const { surveysAnswered, scores } = data[0];
  const { promoters, passives, detractors } = scores;

  const promoterPct = surveysAnswered ? `${Math.round((promoters / surveysAnswered) * 100)}%` : 'N/A';
  const passivePct = surveysAnswered ? `${Math.round((passives / surveysAnswered) * 100)}%` : 'N/A';
  const detractorPct = surveysAnswered ? `${Math.round((detractors / surveysAnswered) * 100)}%` : 'N/A';

  return [
    {
      value: 'promoters',
      renderContents: () => (
        <>
          <p css={styles.bigNumber}>
            {promoterPct}
          </p>
          <p>Promoters</p>
        </>
      ),
    },
    {
      value: 'passives',
      renderContents: () => (
        <>
          <p css={styles.bigNumber}>
            {passivePct}
          </p>
          <p>Passives</p>
        </>
      ),
    },
    {
      value: 'detractors',
      renderContents: () => (
        <>
          <p css={styles.bigNumber}>
            {detractorPct}
          </p>
          <p>Detractors</p>
        </>
      ),
    },
  ];
}

interface ENPSBreakdownViewProps extends ENPSBreakdownSectionProps {
  barGraphData: {
    labels: string[];
    datasets: {
        data: number[];
        backgroundColor: string[];
    }[];
  }
  filteredGraphData: GraphDataset[],
  maxDataPoints: number,
  selectedSidebarFilters: EnpsCategory[],
  setSidebarFilters: Dispatch<SetStateAction<EnpsCategory[]>>,
  sidebarFilters: SidebarFilterOptions,
}

const ENPSBreakdownView = ({
  barGraphData,
  filteredGraphData,
  isMobile = false,
  maxDataPoints,
  onSetDateRange,
  selectedSidebarFilters,
  setSidebarFilters,
  sidebarFilters,
  surveyDates,
}: ENPSBreakdownViewProps): JSX.Element => (
  <>
    <div css={styles.main}>
      <div css={[styles.header(isMobile), styles.smallHeader]}>
        <h3 css={styles.subtitle}>eNPS Breakdown</h3>
        <DateRangeSelect
          dates={surveyDates}
          onChange={onSetDateRange}
          defaultMonthsBack={12}
        />
      </div>
      <div css={styles.expandedLineGraph}>
        {maxDataPoints > 1 && (
          <LineGraphChart
            datasets={filteredGraphData}
            options={{
              scales: {
                y: {
                  min: 0,
                  max: 100,
                },
              },
            }}
            tooltipOptions={{
              callbacks: {
                // @ts-expect-error : There's something funky about the typing here.
                footer: (ctx: { raw: { participantInfo: string }}[]) => ctx[0]?.raw?.participantInfo ?? '',
              },
              footerMarginTop: 8,
            }}
          />
        )}
        {maxDataPoints === 1 && (
          <div css={styles.barChart}>
            <BarChart
              data={barGraphData}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    min: 0,
                    max: 100,
                    title: {
                      text: 'Percentage of Respondents',
                      display: true,
                    },
                  },
                },
              }}
              tooltipOptions={{
                callbacks: {
                  // @ts-expect-error : There's something funky about the typing here.
                  footer: (ctx: { raw: { participantInfo: string }}[]) => ctx[0]?.raw?.participantInfo ?? '',
                },
                footerMarginTop: 8,
              }}
            />
          </div>
        ) }
        {maxDataPoints === 0 && (
          <div css={styles.emptyState}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <img src={insightsEmptyGraph} alt="Empty graph" />
            <p css={styles.emptyStateText}>
              Data will show here as your team answers the eNPS question, which, when enabled, is included every 3 months.
            </p>
          </div>
        )}
      </div>
    </div>
    <div css={styles.verticalDivider(isMobile)} />
    <div css={[styles.sidebar, styles.expandedSidebar(isMobile)]}>
      {sidebarFilters.length ? (
        <SidebarFilters
          selectedValues={selectedSidebarFilters}
          options={sidebarFilters}
          onChange={(values) => setSidebarFilters([...values] as EnpsCategory[])}
        />
      ) : (
        <div>
          <p>eNPS</p>
          <p css={styles.bigNumber}>
            N/A
          </p>
        </div>
      )}
    </div>
  </>
);

type ENPSBreakdownSectionProps = Pick<ExpandedProps,
  'data'
  | 'onSetDateRange'
  | 'surveyDates'
  | 'pickerValue'
  | 'isMobile'
>;

export const HookedENPSBreakdown = ({
  data,
  ...props
}: ENPSBreakdownSectionProps): JSX.Element => {
  const [selectedSidebarFilters, setSidebarFilters] = useState<EnpsCategory[]>([]);

  const filters = selectedSidebarFilters.length ? selectedSidebarFilters : Object.values(EnpsCategory);
  const filteredGraphData = filters.map((category) => ({
    label: capitalize(category),
    data: data.reduce<Array<{x: string, y: number}>>((dataset, slice) => {
      if (slice.surveysAnswered) {
        dataset.push({
          x: moment(slice.surveyDate).format('MMM D YY'),
          y: (slice.scores[category.toLowerCase() as keyof ENPSStats] / slice.surveysAnswered) * 100,
        });
      }

      return dataset;
    }, []),
  }));
  const maxDataPoints = Math.max(...filteredGraphData.map((slice) => slice.data.length));

  const barGraphData = maxDataPoints !== 1 ? { labels: [], datasets: [] } : {
    labels: filters.map((category) => capitalize(category)),
    datasets: [{
      data: filters.map((category) => (data[0].scores[category.toLowerCase() as keyof ENPSStats] / data[0].surveysAnswered) * 100),
      backgroundColor: Object.values(palette.brand),
    }],
  };

  const hookProps = {
    barGraphData,
    data,
    filteredGraphData,
    maxDataPoints,
    sidebarFilters: makeSidebarFilters(data),
    selectedSidebarFilters,
    setSidebarFilters,
  };

  return (
    <ENPSBreakdownView
      {...hookProps}
      {...props}
    />
  );
};
