import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import LinearProgress from '@mui/material/LinearProgress';
import LinkButton from '~Common/V3/components/LinkButton';
import ClampLines from '~Common/components/ClampLines';
import { palette } from '~Common/styles/colors';
import { getDateString } from '~Common/utils/dateString';
import { PROGRESS_BAR_STYLES } from '~Goals/const/styles';

const styles = {
  ...PROGRESS_BAR_STYLES,
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  statusButton: css({
    ':not(:last-of-type)': {
      borderBottom: `1px solid ${palette.neutrals.gray200}`,
    },
    height: '5.9375rem',

    color: palette.neutrals.gray800,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0.75rem',

    ':hover': {
      color: palette.neutrals.gray800,
    },
  }),
  highlight: css({
    backgroundColor: palette.neutrals.gray100,
  }),

  topRow: css({
    alignItems: 'center',
    columnGap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.75rem',
    fontWeight: 400,
  }),
  bottomRow: css({
    fontSize: '0.875rem',
    fontWeight: 500,
  }),
  percentageBar: css({
    alignItems: 'center',
    flexShrink: 0,
    width: '3rem',
  }),
  shrinkProgress: css({
    height: '0.3rem',
  }),
  percentage: css({
    flexShrink: 0,
    textAlign: 'right',
    width: '1.5rem',
  }),
  dateStamp: css({
    flex: 1,
    textAlign: 'right',
  }),
  statusCommentary: css({
    overflowY: 'hidden',
    maxHeight: '2.5rem',
    '& button': {
      display: 'none',
    },
  }),
};

interface LeftPanelProps {
  statusId: string,
  statusUpdates: Goals.GoalStatusUpdate[],
}

export const LeftPanel = ({
  statusId,
  statusUpdates,
}: LeftPanelProps): JSX.Element => (
  <section css={styles.container}>
    {statusUpdates.map((status) => (
      <LinkButton
        to={status.statusId}
        key={status.statusId}
        css={[styles.statusButton, statusId === status.statusId && styles.highlight]}
        variant="text"
        renderContents={() => (
          <>
            <div css={styles.topRow}>
              <div css={styles.percentageBar}>
                <LinearProgress
                  css={[styles.progressBar(status.status ?? Goals.GoalStatus.OnTrack), styles.shrinkProgress]}
                  variant="determinate"
                  value={status?.completionPercentage ?? 0}
                />
              </div>
              <div css={styles.percentage}>
                {`${status.completionPercentage ?? 0}%`}
              </div>
              <div css={styles.dateStamp}>
                {getDateString({ timestamp: status.createdInMillis }).dateString}
              </div>
            </div>
            <div css={styles.bottomRow}>
              <ClampLines
                text={status.statusCommentary}
                id="really-unique-id"
                lines={2}
                ellipsis="..."
                emotionStyle={styles.statusCommentary}
              />
            </div>
            {/* This is an empty element to make the flex work. */}
            <div />
          </>
        )}
      />
    ))}
  </section>
);
