import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import Section from '../Section';
import DefiningObjective from './DefiningObjective';

const styles = {
  title: css({
    marginBottom: '1.125rem',
  }),
  definingObjectivesContainer: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(20rem, 1fr));',
    gap: '.625rem',
  }),
};

interface DefiningObjectivesProps {
  definingObjectives: Goals.Goal[],
}

const DefiningObjectives = ({
  definingObjectives,
  ...props
}: DefiningObjectivesProps): JSX.Element => (
  <Section
    {...props}
  >
    <Section.Title css={styles.title} title={`Defining Objectives (${definingObjectives.length})`} />
    <div css={styles.definingObjectivesContainer}>
      {definingObjectives.map((definingObjective) => (
        <DefiningObjective key={definingObjective.goalId} definingObjective={definingObjective} />
      ))}
    </div>
  </Section>
);

export default DefiningObjectives;
