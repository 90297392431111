import { css } from '@emotion/react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlatButton from '~Common/V3/components/FlatButton';
import { palette } from '~Common/styles/colors';
import Popover from '@mui/material/Popover';
import { useState, MouseEvent } from 'react';
import { DateRange, DateRangeCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import LeadrButton from '../LeadrButtons/LeadrButton';

const styles = {
  display: (disabled: boolean) => css({
    visibility: disabled ? 'hidden' : 'visible',
  }),
  contextMenuContainer: (open: boolean, hasValue: boolean) => css({
    color: palette.neutrals.gray500,
    borderRadius: '.1875rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& span': {
      fontSize: '.75rem',
      color: palette.neutrals.gray800,
      whiteSpace: 'nowrap',
      fontWeight: '400',
    },
    '& div': {
      background: palette.neutrals.gray50,
      borderRadius: '.5rem',
      display: 'flex',
      border: `1px solid ${palette.neutrals.white}`,
      padding: '.375rem .75rem !important',
      // marginLeft: '.5rem',
      '& span': {
        fontWeight: '500',
      },
    },
  }, (open || hasValue) && ({
    '& div': {
      borderColor: palette.brand.indigo,
    },
  })),
  icon: css({
    color: palette.brand.indigo,
    marginLeft: '.5rem',
    fontSize: '1rem',
  }),
  popoverWrapper: css({
    padding: '.75rem',
    display: 'flex',
    gap: '.5rem',
    flexDirection: 'column',
    alignItems: 'flex-start',

    label: {
      display: 'none',
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none ',
    },
    '.MuiMultiInputDateRangeField-root, .MuiDateRangeCalendar-root': {
      width: '20rem',
    },
    '.Mui-selected': {
      backgroundColor: palette.brand.blue,
    },
    input: {
      color: palette.neutrals.gray800,
      fontSize: '.75rem',
      fontWeight: '500',
      background: palette.neutrals.gray50,
      borderRadius: '.5rem',
      padding: '.5rem .75rem',
      textAlign: 'center',

      '&:placeholder': {
        color: palette.neutrals.gray800,
      },
    },
  }),
  clearBtn: css({
    color: palette.brand.sky,
    fontSize: '.625rem',
    fontWeight: '400',
  }),
};

interface ViewProps {
  label?: string,
  disabled: boolean,
  id: string | undefined,
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void,
  handleClearDates: () => void,
  handleClose: () => void,
  open: boolean,
  anchorEl: HTMLButtonElement | null,
  value: DateRange<Date> | undefined,
  setValue: (newValue: DateRange<Date> | undefined) => void,
  hasValue: boolean,
  placeHolderText: string,
}

const View = ({
  label,
  disabled,
  id,
  handleClick,
  handleClearDates,
  handleClose,
  open,
  anchorEl,
  value,
  setValue,
  hasValue,
  placeHolderText,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <FlatButton
      css={styles.display(disabled)}
      onClick={handleClick}
      renderButtonContents={() => (
        <>
          <div css={styles.contextMenuContainer(open, hasValue)}>
            <div>
              <span>{label}</span>
              <FontAwesomeIcon css={styles.icon} icon={faCaretDown} size="sm" />
            </div>
          </div>
        </>
      )}
    />
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div css={styles.popoverWrapper}>
        <LeadrButton
          onClick={handleClearDates}
          css={styles.clearBtn}
          variant="text"
          size="small"
          fullWidth
          data-test-id="clearDates"
        >
          Clear Dates
        </LeadrButton>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateRangePicker
            value={hasValue ? value : [null, null]}
            open={false}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            calendars={1}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
              textField: {
                placeholder: placeHolderText,
                label: null,
              },
            }}
          />
          <DateRangeCalendar
            value={hasValue ? value : [null, null]}
            calendars={1}
            autoFocus
            onChange={(newValue) => setValue(newValue)}
          />
        </LocalizationProvider>
      </div>
    </Popover>
  </div>
);

interface LeadrDateRangePickerProps {
  label?: string,
  disabled?: boolean,
  value: DateRange<Date> | undefined,
  setValue: (newValue: DateRange<Date> | undefined) => void,
  placeHolderText?: string,
}

const LeadrDateRangePicker = ({
  label,
  disabled = false,
  value,
  setValue,
  placeHolderText = 'Select Date',
  ...props
}: LeadrDateRangePickerProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const hasValue = Boolean(value && value?.every((item) => item !== undefined));
  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClearDates = (): void => {
    setValue(undefined);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'leadr-date-range-popover' : undefined;
  const hookProps = {
    label,
    disabled,
    id,
    handleClick,
    handleClearDates,
    handleClose,
    open,
    anchorEl,
    value,
    setValue,
    hasValue,
    placeHolderText,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LeadrDateRangePicker;
