import { css } from '@emotion/react';
import LeadrModal from '~Common/V3/components/LeadrModal';
import { palette } from '~Common/styles/colors';
import { useAddResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { ResourceType } from '~DevelopmentPlan/const/types';
import { OPTIMISTIC_ID, PersonalDevelopmentResourceTypeLabels } from '~DevelopmentPlan/const/defaults';
import { ViewResourceModalBody } from './ViewResourceModalBody';
import RecognitionDetails from './Recognition/RecognitionDetails';

const styles = {
  footer: css({
    '&>#viewModalButtons': {
      alignItems: 'center',
      columnGap: '0.5rem',
      display: 'flex',
      flexDirection: 'row',
    },
  }),
  modal: css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }),
  modalBody: css({
    flex: 1,
    overflow: 'unset',
  }),
  title: css({
    color: palette.brand.indigo,
    fontSize: '1.125rem',
    fontWeight: 600,
  }),
  tabWrapper: css({
    backgroundColor: palette.neutrals.gray100,
    display: 'flex',
    height: '3.125rem',
    alignItems: 'flex-end',
    position: 'relative',

    ':before': {
      width: '300%',
      height: '100%',
      content: '""',
      background: 'inherit',
      position: 'absolute',
      top: 0,
      left: '-100%',
      zIndex: -1,
    },
  }),
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

interface ViewProps {
  closeViewResourceModal: () => void,
  showViewResourceModal: boolean,
  resourceId: ResourceType | undefined,
  showData: boolean,
}

const View = ({
  closeViewResourceModal,
  showViewResourceModal,
  resourceId,
  showData,
}: ViewProps): JSX.Element => (
  <>
    {showData && resourceId === ResourceType.Recognition && (
      <RecognitionDetails />
    )}
    {showData && resourceId !== ResourceType.Recognition && resourceId !== ResourceType.All && (
      <LeadrModal
        css={styles.modal}
        open={showViewResourceModal}
        onClose={closeViewResourceModal}
      >
        <LeadrModal.Header>
          <LeadrModal.Title css={styles.title}>
            {PersonalDevelopmentResourceTypeLabels[resourceId ?? ResourceType.All]}
          </LeadrModal.Title>
        </LeadrModal.Header>
        <LeadrModal.Body css={styles.modalBody}>
          <ViewResourceModalBody />
        </LeadrModal.Body>
        <LeadrModal.Footer css={styles.footer}>
          <div id="viewModalButtons" />
        </LeadrModal.Footer>
      </LeadrModal>
    )}
  </>
);

export const ViewResourceModal = (): JSX.Element => {
  const {
    closeViewResourceModal,
    showViewResourceModal,
    resourceId,
    resourceContentId,
  } = useAddResourceModalStore((state) => ({
    closeViewResourceModal: state.closeViewResourceModal,
    showViewResourceModal: state.showViewResourceModal,
    resourceId: state.resourceId,
    resourceContentId: state.resourceContentId,
  }));

  const showData = !!resourceContentId && resourceContentId !== OPTIMISTIC_ID;

  const hookProps = {
    closeViewResourceModal,
    showViewResourceModal,
    resourceId,
    showData,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
