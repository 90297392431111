import PropTypes from 'prop-types';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import FormDrawer from '~Common/components/Drawers/Forms/Form';
import { DRAWER_STATE_SHAPE, QUESTION_SHAPE } from '~Common/const/proptypes';
/* eslint-disable-next-line import/no-cycle */
import { useUpdateQuestionsOnTemplate, useAddQuestionsToTemplate, useDeleteQuestionsOnTemplate } from '~Deprecated/hooks/feedback/useUpdateFeedbackTemplate';
import DeleteButton from '~Common/components/Buttons/Delete';
import { CONFIRM_TYPE } from '~Common/const/confirmType';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';

export const createQuestionTemplate = {
  name: 'CREATE_QUESTION_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

export const createTemplateQuestionTemplate = {
  name: 'CREATE_TEMPLATE_QUESTION_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const CreateQuestionDrawer = ({
  drawerState,
  setDrawerState,
  popDrawer,
  questionKey,
  context,
  templateId,
  isEditing,
  templateUsed,
  ...props
}) => {
  const { question } = context;
  const isReadOnly = false;

  const questions = [...(drawerState?.[questionKey] ?? [])];

  const questionIndex = questions.findIndex((q) => q.rank === question?.rank);

  const updateMutation = useUpdateQuestionsOnTemplate({});
  const addMutation = useAddQuestionsToTemplate({});
  const deleteMutation = useDeleteQuestionsOnTemplate({ drawerTemplate: templateUsed });

  const deleteQuestionFromTemplate = () => {
    if (templateId && questionIndex >= 0) {
      deleteMutation({ questions: [{ questionId: question.id }], id: templateId });
    }

    questions.splice(questionIndex, 1);
    setDrawerState({ ...drawerState, [questionKey]: questions });
  };

  const onSubmit = (e) => {
    const formData = new FormData(e.target);

    const rteValue = Object.fromEntries(formData);

    if (templateId) {
      // edit
      if (questionIndex >= 0 && isEditing) {
        const newQuestion = {
          questionId: question?.id,
          text: rteValue.question ?? questions[questionIndex].text,
          rank: questionIndex ?? questions[questionIndex].rank,
        };

        updateMutation({ questions: [newQuestion], id: templateId });
      } else {
        // add
        addMutation({ questions: [{ text: rteValue.question, rank: questions.length }], id: templateId });
      }
    }

    if (questionIndex >= 0 && isEditing) {
      questions[questionIndex] = { ...question, text: rteValue.question, rank: questionIndex };
    } else {
      questions.push({ text: rteValue.question, rank: questions.length });
    }
    setDrawerState({ ...drawerState, [questionKey]: questions });

    popDrawer({ drawerName: templateUsed.name });
  };

  const onDeleteQuestion = ({ onToggleConfirm }) => {
    const renderConfirmButton = () => (
      <DeleteButton onClick={deleteQuestionFromTemplate} text="Delete" />
    );

    if (templateId) {
      onToggleConfirm({
        show: true,
        type: CONFIRM_TYPE.DESTRUCTIVE,
        message: 'Are you sure you want to delete this question?',
        drawerName: templateUsed.name,
        renderConfirmButton,
      });
    } else {
      questions.splice(questionIndex, 1);
      setDrawerState({ ...drawerState, [questionKey]: questions });
      popDrawer({ drawerName: templateUsed.name });
    }
  };

  const renderEditor = () => (
    <Froala
      name="question"
      label="Question Text"
      initialValue={question?.text}
      required
    />
  );

  const hookProps = {
    drawerName: templateUsed.name,
    isReadOnly,
    onSubmit,
    drawerTitle: 'Add Question',
    tabs: [
      ['RTE',
        <>
          {renderEditor()}
        </>,
      ],
    ],
    // eslint-disable-next-line react/prop-types
    renderBottomButtons: ({ onToggleConfirm }) => (
      <>
        {!isReadOnly && isEditing && (
          <DeleteButton
            text="Delete"
            onClick={() => onDeleteQuestion({ onToggleConfirm })}
          />
        )}
      </>
    ),
  };

  return (
    <FormDrawer
      {...hookProps}
      {...props}
    />
  );
};

CreateQuestionDrawer.propTypes = {
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  popDrawer: PropTypes.func.isRequired,
  questionKey: PropTypes.string,
  context: PropTypes.shape({
    question: QUESTION_SHAPE,
  }),
  templateId: PropTypes.string,
  isEditing: PropTypes.bool,
  templateUsed: PropTypes.shape({
    name: PropTypes.string,
    width: PropTypes.number,
  }),
};

CreateQuestionDrawer.defaultProps = {
  questionKey: 'questions',
  context: null,
  templateId: null,
  isEditing: false,
  templateUsed: createQuestionTemplate,
};

registerDrawer({ templateName: createQuestionTemplate.name, component: CreateQuestionDrawer });
registerDrawer({ templateName: createTemplateQuestionTemplate.name, component: CreateQuestionDrawer });

export default CreateQuestionDrawer;
