import {
  ParticipantTypeEnum, ReviewCycleDetail, ReviewParticipant, ReviewStatusEnum,
} from '~Reviews/V2/Const/types';
import { union } from 'lodash';

function getParticipantsInReviews(cycle: ReviewCycleDetail | undefined): ReviewParticipant[][] {
  const participants: ReviewParticipant[][] = [];
  cycle?.reviews.forEach((item) => {
    const participantObject = item.participants.filter((participant) => (participant.statusEnum !== ReviewStatusEnum.Finished
        && participant.participantTypeEnum === ParticipantTypeEnum.Reviewee));
    participants.push(participantObject);
  });
  return participants;
}

function filterParticipants(reviewParticipants: ReviewParticipant[][]): string[] {
  const filteredParticipantNames: string[] | ReviewParticipant[] = reviewParticipants.reduce((accReviewParticipantNames, curReviews) => {
    const arrayOfParticipantNames = curReviews.map((review) => `${review.firstName} ${review.lastName}`);
    // @ts-expect-error || Not sure how to fix this one - Doesn't seem to recognise the type switch here
    accReviewParticipantNames.push(...arrayOfParticipantNames);
    return accReviewParticipantNames;
  }, []);

  const uniqueFilteredParticipantNames = union(filteredParticipantNames);

  return uniqueFilteredParticipantNames as unknown as string[];
}
export const convertFinishedReviewParticipantsList = (cycle: ReviewCycleDetail | undefined): string[] => {
  const reviewParticipants = getParticipantsInReviews(cycle);

  const participants = filterParticipants(reviewParticipants);
  return participants;
};
