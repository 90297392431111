import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerInput from '~Common/V3/components/DrawerInput';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { palette } from '~Common/styles/colors';
import emptyParticipants from '~Assets/images/empty-views/emptyParticipants.png';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { selectParticipantsDrawerTemplate } from '~Common/components/Drawers/SelectParticipantsDrawer';
import AttendeeItem from '~Meetings/components/attendees/AttendeeItem';
import DrawerTextArea from '~Common/components/Inputs/DrawerTextArea';
import { DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import { BREAKPOINTS } from '~Common/styles/variables';
import { useWindowWidth } from '~Common/hooks/useWindowWidth';
import useSelectedAttendees from '~Deprecated/hooks/attendees/useSelectedAttendees';
import Button from '../../../common/V3/components/Button';
import { useCreateGroup } from '../../hooks/useCreateGroup';

const styles = {
  titleInput: css`
    margin-bottom: .5rem;
  `,
  descriptionInput: css`
    margin-bottom: 1.125rem;
  `,
  participantsHeader: css`
    color: ${palette.neutrals.gray500};
    font-size: .625rem;
  `,
  participantsContainer: css`
    margin-top: .625rem;
    margin-bottom: 1.875rem;
  `,
  emptyStateImage: css`
    width: 100%;
  `,
  emptyStateText: css`
    color: ${palette.brand.indigo};
    text-align: center;
    margin: 0 15%;
    margin-bottom: 1.75rem;
  `,
  buttonsContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  saveGroupButton: css({
    marginRight: '.625rem',
  }),
  attendeeItem: css`
    &:not(:last-child) {
      margin-bottom: .625rem;
    }
  `,
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

export const createUserGroupDrawerTemplate = {
  name: 'CREATE_USER_GROUP_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const CreateUserGroupDrawer = ({
  popDrawer, drawerState, transitionState, setDrawerState,
}) => {
  const dispatch = useDispatch();
  const [initialTitle] = useState('');
  const [initialDescription] = useState('');
  const { windowWidth } = useWindowWidth();
  const createGroupMutation = useCreateGroup();

  const closeDrawerClick = () => {
    popDrawer({ popAll: true });
  };

  const {
    isAttendeeSelected,
    onAttendeeSelect,
  } = useSelectedAttendees({ drawerState, setDrawerState });

  const selectedIds = useMemo(() => [...(drawerState.selectedAttendees || [])], [drawerState.selectedAttendees]);

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const newGroup = {
      name: data.title,
      description: data.description,
      members: selectedIds,
    };

    createGroupMutation(newGroup);
  };

  useEffect(() => {
    const isMobile = windowWidth <= BREAKPOINTS.MAX.MOBILE;
    const hasEntered = transitionState === TRANSITION_STATE.ENTERED;

    if (!isMobile && hasEntered) {
      dispatch(pushDrawerAction({
        drawer: {
          ...selectParticipantsDrawerTemplate,
        },
      }));
    }
  }, [windowWidth, dispatch, transitionState]);

  return (
    <DrawerLayout
      onSubmit={onSubmit}
      renderHeader={() => (
        <DrawerHeader
          renderCloseButton={(closeButtonStyles) => (
            <IconButton
              onClick={closeDrawerClick}
              icon={faTimes}
              css={closeButtonStyles}
              size="large"
            />
          )}
          title="New Group"
        />
      )}
      renderBody={() => (
        <div css={styles.drawerBody}>
          <DrawerInput
            name="title"
            maxLength={250}
            css={styles.titleInput}
            initialValue={initialTitle}
            label="Title"
            required
          />
          <DrawerTextArea
            name="description"
            css={styles.descriptionInput}
            initialValue={initialDescription}
            label="Description"
          />
          <div css={styles.participantsHeader}>PARTICIPANTS</div>
          <div css={styles.participantsContainer}>
            {selectedIds.length === 0 && (
              <div>
                <img src={emptyParticipants} alt="You have no participants." css={styles.emptyStateImage} />
                <div css={styles.emptyStateText}>You have not added any group members yet. Please select the group members you would like to add.</div>
              </div>
            )}
            {selectedIds.length > 0 && (
              <>
                {selectedIds.map((id) => (
                  <div css={styles.attendeeItem} key={id}>
                    <AttendeeItem
                      id={id}
                      isAttendeeSelected={isAttendeeSelected}
                      onClick={onAttendeeSelect}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div css={styles.buttonsContainer}>
            <div>
              <Button
                css={styles.saveGroupButton}
                color="secondary"
                type="submit"
                renderContents={() => (
                  <div>Save Group</div>
                )}
              />
              <Button
                color="light"
                variant="outline"
                onClick={closeDrawerClick}
                renderContents={() => (
                  <div>Cancel</div>
                )}
              />
            </div>
          </div>
        </div>
      )}
    />
  );
};

CreateUserGroupDrawer.propTypes = {
  popDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  transitionState: PropTypes.oneOf(Object.values(TRANSITION_STATE)).isRequired,
  setDrawerState: PropTypes.func.isRequired,
};

CreateUserGroupDrawer.defaultProps = {};

registerDrawer({
  templateName: createUserGroupDrawerTemplate.name,
  component: CreateUserGroupDrawer,
});

export default CreateUserGroupDrawer;
