import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, getApi } from '~Deprecated/services/HttpService';

export interface OrgUserIdentity {
  email: string,
  firstName: string,
  lastName: string,
  orgUserId: string,
  userId: string,
}

export interface OrgUserPermission {
  isAdmin: boolean;
  isManager: boolean;
  canActivateUser: boolean;
  canUpdateTaskAssignee: boolean;
  canRemoveAttendee: boolean;
  canRequestFeedback: boolean;
  canRecallLearning: boolean;
  canCreateTemplate: boolean;
  canCreateCoaching: boolean;
  canDeleteTask: boolean;
  canUpdateUserAccount: boolean;
  canAddNewAttendee: boolean;
  canDeleteGoals: boolean;
  canBeIncludedInPeopleMetrics: boolean;
  canRescheduleHuddle: boolean;
  canUpdateGoalsDescription: boolean;
  canUpdateTemplate: boolean;
  canViewPeopleMetrics: boolean;
  canEditHuddle: boolean;
  canRecallFeedback: boolean;
  canUpdateUserEmail: boolean;
  canDeactivateUser: boolean;
  canImportUser: boolean;
  canDeleteTeamMeeting: boolean;
  canCreateGoals: boolean;
  canHaveDirects: boolean;
  canStopHuddle: boolean;
  canUpdateCoaching: boolean;
  canDeleteCoaching: boolean;
  canUpdateTeamMeeting: boolean;
  canAssignLearning: boolean;
  canDeleteTemplate: boolean;
  canSkipHuddle: boolean;
  canInviteUser: boolean;
  canCreateTask: boolean;
  canUpdateTaskDueDate: boolean;
  canViewReview: boolean;
  canViewAdministration: boolean;
  canCreateTeamMeeting: boolean;
  canAssignTasks: boolean;
  canUpdateManager: boolean;
  canDeleteUser: boolean;
  canDeleteTasks: boolean;
}

export interface UserPermission {
  isCustomerSuccessAdmin: boolean,
  isCustomerSuccessManager: boolean,
  isBenefitsSpecialist: boolean,
  isImpersonator: boolean,
}

interface getOrgUserPermissionsParams {
  orgId: string;
}

export interface GetOrgUserPermissionsReturn {
  user: UserPermission[];
  orgUser: OrgUserPermission[],
  identity: OrgUserIdentity,
}

const getOrgUserPermissions = ({
  orgId,
}: getOrgUserPermissionsParams): Promise<HttpCallReturn<GetOrgUserPermissionsReturn>> => {
  const url = `/permissions?organizationId=${orgId}`;
  return getApi<GetOrgUserPermissionsReturn>(url);
};

type UseGetOrgUserPermissionsParams<T> = Omit<UseQueryOptions<HttpCallReturn<GetOrgUserPermissionsReturn>, Error, T>, 'queryKey' | 'queryFn'>;

interface OrgUserPermissionsQueryReturn {
  queryKey: [string, 'permissions'];
  queryFn: () => Promise<HttpCallReturn<GetOrgUserPermissionsReturn>>;
}

export const getOrgUserPermissionsQuery = (orgId: string): OrgUserPermissionsQueryReturn => ({
  queryKey: [orgId, 'permissions'],
  queryFn: () => getOrgUserPermissions({ orgId }),
});

/*
  Use useOrgUserPermissionsContext instead of this hook directly
  This is following a pattern that can be found here: https://tkdodo.eu/blog/you-might-not-need-react-query
*/
export const useGetOrgUserPermissions = <T = HttpCallReturn<GetOrgUserPermissionsReturn>>({
  ...options
}: UseGetOrgUserPermissionsParams<T>): UseQueryResult<T, Error> => {
  const orgId = getOrganizationId() ?? '';
  return useQuery({
    ...getOrgUserPermissionsQuery(orgId),
    enabled: !!orgId,
    staleTime: Infinity,
    gcTime: Infinity,
    ...options,
  });
};
