import { css, SerializedStyles } from '@emotion/react';
import { ComponentProps } from 'react';

const styles = {
  footerButton: css({
    fontSize: '0.625rem',
    paddingLeft: 0,
  }),
};

interface RenderFooterButtonParams {
  css: SerializedStyles,
}

interface ModuleFooterProps extends ComponentProps<'footer'> {
  renderFooterButton: (params: RenderFooterButtonParams) => JSX.Element,
}

const ModuleFooter = ({
  renderFooterButton,
  ...props
}: ModuleFooterProps): JSX.Element => (
  <footer
    {...props}
  >
    {renderFooterButton({ css: styles.footerButton })}
  </footer>
);

export default ModuleFooter;
