import { css } from '@emotion/react';
import { ComponentProps } from 'react';

import {
  palette,
} from '~Common/styles/colors';
import { forMobileTinyObject } from '~Common/styles/mixins';
import { noop } from '~Deprecated/utils';

const styles = {
  tabNavItem: (isActive: boolean) => css(
    isActive && ({
      color: palette.brand.indigo,
      backgroundColor: palette.neutrals.white,
      fontWeight: 600,
      borderRadius: '.3125rem .3125rem 0rem 0rem',
    }),
    !isActive && ({
      color: palette.neutrals.gray600,
    }),
    ({
      fontSize: '.75rem',
      height: '2.625rem',
      padding: '0.75rem',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    }),
    forMobileTinyObject({
      fontSize: '0.65rem',
      padding: '0.65rem',
      letterSpacing: 'normal',
    }),
  ),
};

interface TabNavItemProps extends ComponentProps<'div'> {
  isActive: boolean,
  renderNavItem: () => JSX.Element,
  onClick: () => void,
}

const TabNavItem = ({
  isActive,
  renderNavItem,
  onClick,
  ...props
}: TabNavItemProps): JSX.Element => (
  <div
    css={styles.tabNavItem(isActive)}
    onClick={onClick}
    onKeyUp={noop}
    role="button"
    tabIndex={0}
    {...props}
  >
    {renderNavItem()}
  </div>
);

export default TabNavItem;
