import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { drawerBackgroundColor } from '~Deprecated/ui/styles/colors';
import { RENDER_FUNCTION_SHAPE } from '~Common/const/proptypes';
import { popAfterAction } from '~Deprecated/actions/drawers/popAfter';
import ConstructiveConfirm from '~Common/components/Drawers/Forms/Overlays/Constructive';
import DestructiveConfirm from '~Common/components/Drawers/Forms/Overlays/Destructive';
import { CONFIRM_TYPE } from '~Common/const/confirmType';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { forMobile } from '~Common/styles/mixins';

const styles = {
  header: css`
    padding: 16px 16px 0;
    background: ${drawerBackgroundColor};
    z-index: 9001;
    flex-shrink: 0;
  `,
  body: css`
    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  `,
  formBodyWrapper: css`
    display: flex;
    flex-direction: column;
    min-height: 0;
  `,
  bottomClass: css`
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    z-index: 9100;
    flex-shrink: 0;

    position: sticky;
    bottom: 0;
    backdrop-filter: blur(3px);
    background: ${hexToRGBA(palette.neutrals.white, 0.7)};

    & button {
      margin: 0 18px;

      ${forMobile(`
        margin: 0 5px;
      `)}
    }
  `,
  form: css`
    height: 100%;
    background: ${drawerBackgroundColor};
    display: flex;
    flex-direction: column;
  `,
  overlay: css`
    z-index: 20000;
    display: flex;
    align-items: center;
  `,
  hidden: css`
    display: none;
  `,
  layoutWrapper: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
};

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
function BaseLayout({
  renderHeader,
  renderBody,
  renderBottomButtons,
  renderBottomWarning,
  onSubmit,
  className,
  headerClass,
  bodyClass,
  activeTab,
  formClass,
}) {
  const dispatch = useDispatch();
  const [isConfirming, showConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [confirmButton, setConfirmButton] = useState(null);
  const confirmMessage = useRef();
  const submitter = useRef();
  const submitButton = useRef();
  const form = useRef();

  const onToggleConfirm = ({
    show, type, message, drawerName, renderConfirmButton,
  }) => {
    showConfirm(show);
    setConfirmType(type);

    if (drawerName) {
      dispatch(popAfterAction({ drawerName }));
    }

    if (show) {
      confirmMessage.current = message;
      setConfirmButton(renderConfirmButton);
    } else {
      confirmMessage.current = null;
      setConfirmButton(() => false);
    }
  };

  const onCloseConfirm = () => {
    showConfirm(false);
    setConfirmButton(() => false);
  };

  const setSubmitter = (name) => {
    submitter.current = name;
    submitButton.current.click();
  };

  const doSubmit = (e) => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit(e, submitter.current);
    }
  };

  return (
    <>
      <form
        css={styles.form}
        ref={form}
        onSubmit={doSubmit}
        className={formClass}
      >
        <div
          css={styles.formBodyWrapper}
        >
          <div
            css={styles.layoutWrapper}
            className={className}
          >
            <div
              css={[styles.header, headerClass]}
            >
              { renderHeader({ setSubmitter, onToggleConfirm }) }
            </div>
            <div
              css={[styles.body, bodyClass]}
            >
              { renderBody() }
            </div>
          </div>
        </div>
        { renderBottomButtons && (
          <div css={styles.bottomClass}>
            { renderBottomButtons({ activeTab, setSubmitter, onToggleConfirm })}
          </div>
        ) }
        {renderBottomWarning && (
          <div css={styles.bottomWarning}>
            {renderBottomWarning()}
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          css={styles.hidden}
          ref={submitButton}
          type="submit"
        />
      </form>
      {confirmType === CONFIRM_TYPE.CONSTRUCTIVE && isConfirming && (
        <ConstructiveConfirm
          setSubmitter={setSubmitter}
          onToggleConfirm={onToggleConfirm}
          message={confirmMessage.current}
          renderConfirmButton={confirmButton}
        />
      )}
      {confirmType === CONFIRM_TYPE.DESTRUCTIVE && isConfirming && (
        <DestructiveConfirm
          setSubmitter={setSubmitter}
          onToggleConfirm={onToggleConfirm}
          message={confirmMessage.current}
          renderConfirmButton={confirmButton}
          css={styles.overlay}
          onClose={onCloseConfirm}
        />
      )}
    </>
  );
}

BaseLayout.propTypes = {
  renderHeader: RENDER_FUNCTION_SHAPE.isRequired,
  renderBody: RENDER_FUNCTION_SHAPE.isRequired,
  renderBottomButtons: RENDER_FUNCTION_SHAPE,
  renderBottomWarning: RENDER_FUNCTION_SHAPE,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  headerClass: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  bodyClass: PropTypes.object,
  activeTab: PropTypes.number,
  formClass: PropTypes.string,
};

BaseLayout.defaultProps = {
  onSubmit: null,
  className: '',
  headerClass: null,
  bodyClass: null,
  renderBottomButtons: null,
  renderBottomWarning: null,
  activeTab: 0,
  formClass: '',
};

export default BaseLayout;
