import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { ANIMATION_TIME } from '~Deprecated/ui/components/DrawerManager/BaseDrawer';
import { hexToRGBA, palette } from '~Common/styles/colors';
import BaseDrawerOverlay from './Base';

const style = css`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  transition: backdrop-filter ${ANIMATION_TIME}ms ease-in;
  background: ${hexToRGBA(palette.neutrals.white, 0.7)};
`;

const Destructive = (props) => (
  <BaseDrawerOverlay
    css={style}
    {...props}
  />
);

Destructive.propTypes = {
  setSubmitter: PropTypes.func.isRequired,
  context: PropTypes.func.isRequired,
};

export default Destructive;
