import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { palette } from '~Common/styles/colors';
import UserInsightsReport, { InsightsRenderTooltipProps } from '~Deprecated/ui/components/Insights/InsightDetails/UserInsightsReport';
import InsightStatusIndicatorTooltip from '~Deprecated/ui/views/Old/People/PeopleList/InsightStatusIndicatorTooltip';
import { withTruncate } from '~Common/styles/mixins';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { noop } from '~Deprecated/utils/';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { PeoplePagePerson, PeoplePageReport, PeoplePageSummaryReport } from '~People/const/interfaces';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import LeadrCard from '~Common/V3/components/LeadrCard';
import { Person } from '~Common/const/interfaces';
import Tooltip from '~Common/components/Tooltip';
import SummaryReportSection from './SummaryReportSection';
import EditProfileButton from './EditProfileButton';

const styles = {
  peopleCard: css({
    padding: '.75rem 1rem',
  }),
  cardBody: css({
    display: 'flex',
  }),
  editProfileContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridColumn: '2/2',
  }),
  avatar: css({
    display: 'flex',
    alignItems: 'center',
  }),
  detailsContainer: css({
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  }),
  userInfoContainer: css({
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
  }),
  row: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  }),
  department: css({
    fontSize: '.625rem',
    color: palette.neutrals.gray700,
    letterSpacing: '.0938rem',
  }, withTruncate()),
  name: css({
    fontSize: '1rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
  }, withTruncate()),
  jobTitle: css({
    color: palette.neutrals.gray700,
  }, withTruncate()),
  summaryReportSection: css({
    marginTop: '1.1875rem',
  }),
  workingGenius: css({
    marginLeft: '1rem',
    fontSize: '.625rem',
    color: palette.neutrals.gray700,
    letterSpacing: '.0938rem',
  }),
};

interface ViewProps {
  orgUserId: string,
  firstName: string,
  lastName: string,
  jobTitle?: string,
  department: string,
  profileImageUrl?: string,
  handlePersonClick: () => void,
  canEditProfile: boolean,
  report?: PeoplePageReport,
  user: PeoplePagePerson,
  summaryReport?: PeoplePageSummaryReport,
  hasManager: boolean,
  hasWorkingGenius: boolean,
  workingGeniusSkills: string[],
}

const View = ({
  orgUserId,
  firstName,
  lastName,
  jobTitle,
  department,
  profileImageUrl,
  handlePersonClick,
  canEditProfile,
  report,
  user,
  summaryReport,
  hasManager,
  hasWorkingGenius,
  workingGeniusSkills,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard
    css={styles.peopleCard}
    onClick={handlePersonClick}
    onKeyDown={noop}
    tabIndex={0}
    role="button"
    {...props}
  >
    <div css={styles.cardBody}>
      <div css={styles.avatar}>
        <AvatarWithTooltip
          profileImageUrl={profileImageUrl}
          firstName={firstName}
          lastName={lastName}
        />
      </div>
      <div css={styles.detailsContainer}>
        <div css={styles.row}>
          {department && (
            <div css={styles.department}>
              {department}
            </div>
          )}
          <div css={styles.editProfileContainer}>
            {canEditProfile && (
              <EditProfileButton userId={user.userId} />
            )}
            {hasWorkingGenius && workingGeniusSkills && (
              <Tooltip content={(
                <>
                  <span>Working Genius</span>
                  <br />
                  <span>{workingGeniusSkills.join(', ')}</span>
                </>
              )}
              >
                <div css={styles.workingGenius}>
                  {workingGeniusSkills.map((skill) => skill.slice(0, 1).toUpperCase()).join('')}
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div css={styles.row}>
          <div css={styles.userInfoContainer}>
            <div css={styles.name}>
              {`${firstName} ${lastName}`}
            </div>
            {jobTitle && (
              <div css={styles.jobTitle}>
                {jobTitle}
              </div>
            )}
          </div>
          {report && (
            <UserInsightsReport
              metricHealthReport={report}
              renderTooltip={(tooltipProps: InsightsRenderTooltipProps) => (
                <InsightStatusIndicatorTooltip
                  id={orgUserId}
                  user={user as unknown as Person}
                  {...tooltipProps}
                />
              )}
              hasManager={hasManager}
              className={undefined}
              size={undefined}
            />
          )}
        </div>
      </div>
    </div>
    { summaryReport && (
      <SummaryReportSection
        css={styles.summaryReportSection}
        user={user}
        summaryReport={summaryReport}
      />
    )}
  </LeadrCard>
);

interface PeopleCardProps {
  user: PeoplePagePerson,
}

const PeopleCard = ({
  user,
  ...props
}: PeopleCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    isAdmin: canEditProfile,
    hasWorkingGenius,
  } = useUserPermissions();

  const userId = user?.userId;
  const orgUserId = user?.orgUserId;
  const profileImageUrl = user?.profileImageUrl;
  const firstName = user?.firstName?.trim();
  const lastName = user?.lastName?.trim();
  const jobTitle = user?.jobTitle?.trim();
  const department = user?.department?.trim().toUpperCase();
  const report = user?.report;
  const summaryReport = user?.summaryReport;
  const hasManager = !!user?.manager?.orgUserId;
  const workingGeniusSkills = user?.workingGeniuses;

  const handlePersonClick = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...baseballCardDrawerTemplate,
        args: {
          id: userId,
        },
      },
    }));
  }, [dispatch, userId]);

  const hookProps = {
    orgUserId,
    jobTitle,
    department,
    report,
    summaryReport,
    user,
    firstName,
    lastName,
    handlePersonClick,
    canEditProfile,
    profileImageUrl,
    hasManager,
    hasWorkingGenius,
    workingGeniusSkills,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default PeopleCard;
