import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import {
  deleteApi,
} from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { queryClient } from '~Common/const/queryClient';
import { USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';

const recallFeedback = ({ feedback }) => {
  const URL = {
    host: hosts.feedback,
    uri: `/organizations/${getOrganizationId()}/feedbacks/${feedback.id}`,
  };
  return deleteApi(URL, { recallForUsers: feedback.assignees.map((x) => x.id) }, {});
};

export const useRecallFeedback = ({ queryKey = [getOrganizationId(), 'feedback'], template }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: recallFeedback,
    onMutate: () => {
      toastId.current = toast.info('Recalling the feedback request...', { autoClose: false });

      dispatch(
        popDrawerAction({ drawerName: template.name }),
      );
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error recalling the feedback request. Please try again',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (template) {
        dispatch(pushDrawerAction({ drawer: { ...template, args: variables } }));
      }
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Succesfully recalled the feedback request.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
      queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
  });

  return mutation.mutate;
};
