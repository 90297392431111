// Note: This was initially Typescript but I admitted defeat in handling mui's
// AutocompleteProps generic. There are open issues for some of their typing choices
// on Github but in the meantime, JSX.

// Note: This component allows the user to create new options - if you do not need or want that use the AutoComplete component
import * as PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import MuiAutocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { autoCompleteStyles } from '~Common/V3/styles/AutoComplete';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  ...autoCompleteStyles,

  autocompleteTags: css({
    '&[data-shrink=true]': {
      top: '0',
    },
    'label[data-shrink=true]': {
      marginTop: '-.5rem',
    },
    'label:not(.MuiFormLabel-filled)': {
      marginTop: '.5rem',

      '& ~ .MuiInputBase-formControl': {
        paddingTop: '1.5rem !important',
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-adornedStart': {
      paddingTop: '2rem !important',
    },
    '& .MuiOutlinedInput-root': {
      height: 'auto',
    },
    '.MuiAutocomplete-option': {
      textOverflow: 'ellipsis',
      display: 'inline-block',
      overflow: 'hidden',
    },
    '.MuiAutocomplete-inputRoot': {
      overflowY: 'auto',
      maxHeight: '150px',
    },
  }),
  error: css({
    '.MuiFormControl-root': {
      borderColor: palette.brand.red,
    },
    '.MuiSvgIcon-root': {
      color: palette.brand.red,
    },
  }),
  errorMessage: css({
    marginTop: '-0.25rem',
    marginLeft: '0.875rem',
  }),
};

export function AutoCompleteTags({
  name,
  label,
  defaults,
  multiple,
  disableClearable = false,
  ...props
}) {
  const { control, formState: { errors } } = useFormContext();
  const error = errors[name];

  return (

    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <>

          <MuiAutocomplete
            css={[styles.autocomplete, styles.autocompleteTags, (error ? styles.error : null)]}
            limitTags={3}
            disableClearable={disableClearable}
            disablePortal
            isOptionEqualToValue={(option, value) => option.value === value.value}
            multiple={multiple}
            disableCloseOnSelect
            defaultValue={[...defaults]}
            onChange={(_, newValue) => { onChange(newValue.map((newOption) => newOption.value)); }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
              />
            )}
            ListboxProps={{
              sx: {
                '.MuiAutocomplete-option': {
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  overflow: 'hidden',
                  width: '100%',
                },
              },
            }}
            {...props}
          />
          {error && (
          <FormHelperText error css={styles.errorMessage}>{error.message}</FormHelperText>
          )}
        </>
      )}
    />

  );
}

AutoCompleteTags.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  defaults: PropTypes.arrayOf({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  disableClearable: PropTypes.bool,
};

AutoCompleteTags.defaultProps = {
  defaults: [],
  label: undefined,
  multiple: false,
  disableClearable: false,
};
