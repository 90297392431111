import { css, SerializedStyles } from '@emotion/react';
import { Divider } from '@mui/material';
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import EmptyState from '~Home/components/common/EmptyState';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { goalsPreview } from '~Home/const/HomeEmptyState';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { GOALS_BASE_ROUTE } from '~Common/const/routes';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { Goals } from '@leadr-hr/types';
import GoalListItem from './GoalListItem';

const styles = {
  header: css({
    marginBottom: '0.5rem',
  }),
  goalListItemWrapper: css({
    marginBottom: '0.5rem',
  }),
  goalListItem: css({
    marginBottom: '0.5rem',
  }),
  divider: css({
    marginBottom: '0.5rem',
  }),
};

interface OldHomeGoalsCardViewProps extends Omit<LeadrCardProps, 'children'> {
  areGoalsLoading: boolean,
  headerText: string,
  goals: Goals.Goal[],
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const View = ({
  areGoalsLoading,
  headerText,
  goals,
  dragHandleProps,
  ...props
}: OldHomeGoalsCardViewProps): JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <ModuleHeader
      css={styles.header}
      headerText={headerText}
      {...dragHandleProps}
    />

    {!areGoalsLoading && goals?.length === 0 && (
      <EmptyState
        image={goalsPreview}
        renderIcon={(iconStyles: SerializedStyles) => (
          <FontAwesomeIcon
            css={iconStyles}
            icon={faBullseyeArrow}
            size="5x"
          />
        )}
        renderAction={(actionStyles: SerializedStyles) => (
          <LeadrButton
            css={actionStyles}
            component={Link}
            to={GOALS_BASE_ROUTE}
            variant="text"
            data-test-id="postRecognition"
          >
            Create a goal
          </LeadrButton>
        )}
        text="to start tracking progress"
        altText="Create a goal to start tracking progress"
      />
    )}

    {areGoalsLoading && (
      <HomeSkeleton
        numberOfSkeletons={4}
        height={2.5}
      />
    )}

    {!areGoalsLoading && goals && goals?.map((goal) => (
      <>
        <GoalListItem
          css={styles.goalListItem}
          key={goal?.goalId}
          goal={goal}
        />
        <Divider css={styles.divider} />
      </>
    ))}

    <ModuleFooter
      renderFooterButton={(params) => (
        <LeadrButton
          data-test-id="viewAllGoals"
          component={Link}
          to={GOALS_BASE_ROUTE}
          variant="text"
          size="small"
          {...params}
        >
          View All
        </LeadrButton>
      )}
    />
  </LeadrCard>
);

export interface OldHomeGoalsCardProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const OldHomeGoalsCard = ({
  dragHandleProps,
  ...props
}: OldHomeGoalsCardProps): JSX.Element => {
  const numberOfGoalsToFetch = 4;

  const params = {
    take: numberOfGoalsToFetch,
    ownerIds: getOrganizationUserId(),
    isCompleted: false,
    contextType: Goals.GoalContextType.Personal,
  } as Goals.Requests.GetGoalsRequestQueryParameters;

  const { data, isLoading } = useGetGoals({ params });
  const [areGoalsLoading] = useSkeletonLoaders(isLoading);

  const goals = data?.response ?? [];

  const hookProps = {
    areGoalsLoading,
    headerText: 'Goals',
    goals,
    dragHandleProps,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default OldHomeGoalsCard;
