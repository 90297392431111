import { createNextState } from '@reduxjs/toolkit';

export function flattenData({
  responseData = [],
  idName = 'id',
  pathToId = [],
  onItemFlatten,
}) {
  const ids = [];
  const data = {};

  responseData.forEach((responseItem, index) => {
    let item = responseItem;

    if (pathToId.length > 0) {
      pathToId.forEach((key) => {
        item = item[key];
      });
    }

    if (item) {
      if (onItemFlatten) {
        const result = onItemFlatten(responseItem);
        if (result) {
          data[item[idName]] = result;
          ids.push(item[idName]);
        }
      } else {
        ids.push(item[idName]);
        data[item[idName]] = item;
      }
    }
  });

  return {
    ids,
    data,
  };
}

export function mutateState(baseState, mutator) {
  return createNextState(baseState, mutator);
}

export const formToPayload = (formElements) => [...formElements]
  .filter((ele) => ele.name !== '')
  .reduce((obj, ele) => {
    obj[ele.name] = ele.files || (ele.type === 'checkbox' ? ele.checked : ele.value);
    return obj;
  }, {});

export const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const deleteRecordFromState = (draftState, id) => {
  if (draftState.data && draftState.data[id]) {
    delete draftState.data[id];
  }

  if (draftState.ids) {
    const index = draftState.ids.findIndex((draftId) => draftId === id);

    if (index > -1 && draftState.ids[index]) {
      draftState.ids.splice(index, 1);
    }
  }

  return draftState;
};

export const hardRedirect = (path) => {
  window.location.href = `${process.env.REACT_APP_LEADR_SELF_URL}${path}`;
};
