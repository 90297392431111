import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import Section from '../Section';
import StandardOperatingObjective from './StandardOperatingObjective';

const styles = {
  title: css({
    marginBottom: '1.125rem',
  }),
  definingObjectivesContainer: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(20rem, 1fr));',
    gap: '.625rem',
  }),
};

interface StandardOperatingObjectivesProps {
  standardOperatingObjectives: Goals.Goal[],
}

const StandardOperatingObjectives = ({
  standardOperatingObjectives,
  ...props
}: StandardOperatingObjectivesProps): JSX.Element => (
  <Section
    {...props}
  >
    <Section.Title css={styles.title} title={`Standard Operating Objectives (${standardOperatingObjectives.length})`} />
    <div css={styles.definingObjectivesContainer}>
      {standardOperatingObjectives.map((standardOperatingObjective) => (
        <StandardOperatingObjective key={standardOperatingObjective.goalId} standardOperationgObjective={standardOperatingObjective} />
      ))}
    </div>
  </Section>
);

export default StandardOperatingObjectives;
