import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Router } from 'react-router-dom';
import history from '~Deprecated/store/configureStore/history';

import StyleCompat from '~Deprecated/ui/containers/StyleCompat';
import IdleDetector from '~Deprecated/ui/containers/DataLoaders/IdleDetector';
import LeadrStyleRoot from '~Deprecated/ui/components/LeadrStyleRoot';
import useRemoveUnusedDrafts from '~Common/hooks/useRemoveUnusedDrafts';
import AppUpdated from '~Deprecated/common/Components/Notifications/AppUpdated';
import { useSkeletonLoadersStore } from '~Common/hooks/useSkeletonLoadersStore';
import PropTypes from 'prop-types';
import { MobileDeepLinkHandler } from '~Root/components/MobileDeepLinkHandler';
import Lightbox from '~Common/V3/components/Lightbox';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import LeadrRouter from './LeadrRouter';
import Toasts from '../lib/Toasts';
import Intl from '../lib/Intl';

const PreRouter = ({ flags }) => {
  // This hook checks the expiry for each draft and deletes them if the expiry check passes.
  useRemoveUnusedDrafts();

  CapacitorUpdater.notifyAppReady();

  /*
    Create a global function to enable/disable skeleton loaders
    There is also the SkeletonLoaders drawer in the Test Tools
    However, this lets you test skeleton loaders in drawers and is faster to use
  */
  // eslint-disable-next-line no-unused-vars
  const [_, setSkeletonLoadersOverride] = useSkeletonLoadersStore();
  if (flags?.showTestPage) {
    window.enableSkeletonLoaders = () => setSkeletonLoadersOverride(true);
    window.disableSkeletonLoaders = () => setSkeletonLoadersOverride(false);
  }

  return (
    <>
      <LeadrStyleRoot />
      {/* TODO: Once we rewrite meetings, we can delete this https://leadrhq.atlassian.net/browse/LW-6983 */}
      <IdleDetector />
      { /* This is to help bridge the transition from Layout 1.0 to Layout 2.0 */}
      <StyleCompat />
      <Intl>
        <Router
          history={history}
        >
          {IS_MOBILE_APP && <MobileDeepLinkHandler />}
          <LeadrRouter />
          <Lightbox />
          <Toasts />
          <AppUpdated />
        </Router>
      </Intl>
    </>
  );
};

PreRouter.propTypes = {
  flags: PropTypes.object.isRequired,
};

export default withLDConsumer()(PreRouter);
