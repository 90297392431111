/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  useEffect, useState, useRef, RefObject, Dispatch, SetStateAction,
} from 'react';
import { css, SerializedStyles } from '@emotion/react';
import ReactFroalaEditor from 'react-froala-wysiwyg';

import { hexToRGBA, palette } from '~Common/styles/colors';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import DisabledInputBox from '~Deprecated/ui/components/Inputs/DisabledInputBox';
import { useDraft } from '~Common/hooks/useDraft';
import { noop } from '~Deprecated/utils';
import '~Common/V3/components/FroalaImports';
import { useUpdateEffect } from '~Common/hooks/useUpdateEffect';

export const FROALA_SINGLE_LINE_HEIGHT = 22;
export const FROALA_DEFAULT_HEIGHT_MIN = FROALA_SINGLE_LINE_HEIGHT;

const styles = {
  container: css(
    {
      position: 'relative',
      '.fr-box.fr-basic .fr-element.fr-view': {
        p: {
          color: palette.neutrals.gray800,
          fontSize: '1rem',
        },
        li: {
          color: palette.neutrals.gray800,
          fontSize: '1rem',
        },
      },
      '.fr-toolbar, .fr-second-toolbar': {
        background: palette.neutrals.gray50,
        border: 0,
        borderRadius: 0,
      },
      '.fr-box.fr-basic .fr-wrapper': {
        background: palette.neutrals.gray50,
        border: 0,
        borderRadius: 0,
        paddingTop: '1.125rem',
      },
      '.fr-placeholder': {
        paddingTop: '1.125rem !important',
        color: palette.neutrals.gray400,
      },
      '.fr-toolbar .fr-btn-grp': {
        background: palette.neutrals.white,
        margin: '0 1rem',
        padding: '0 0.5rem',
        borderRadius: '.3125rem',
        button: {
          margin: '0',
          padding: '0.25rem 0.5rem',
          height: 'unset',
          svg: {
            height: '1.25rem !important',
            width: '1.25rem !important',
          },
        },
      },
      '.fr-toolbar .fr-command.fr-btn svg.fr-svg, .fr-popup .fr-command.fr-btn svg.fr-svg, .fr-modal .fr-command.fr-btn svg.fr-svg': {
        margin: 0,
        path: {
          fill: palette.neutrals.gray700,
        },
      },
      '.fr-box.fr-basic .fr-element': {
        paddingBottom: 0,
        paddingTop: 0,
        height: 'calc(100% - 2.5rem)',
        maxHeight: '30vh',
      },
      '.fr-box .fr-counter': {
        marginBottom: '0rem !important',
      },
      '.fr-toolbar .fr-newline': {
        display: 'none',
      },
      '.fr-second-toolbar, .fr-toolbar.fr-bottom': {
        borderRadius: '0 0 0.5rem 0.5rem',
      },
      '.fr-wrapper.show-placeholder .fr-placeholder': {
        whiteSpace: 'unset',
      },
      '.fr-popup': {
        boxShadow: 'none !important',
        paddingTop: '3.5rem',
        position: 'initial !important',
      },
      '.fr-mobile > .fr-btn-grp.fr-float-left': {
        margin: 0,
        padding: 0,
      },
      p: {
        whiteSpace: 'normal',
      },
    },
    ({
      borderRadius: '0.5rem',
    }),
  ),
  invisibleInput: css({
    display: 'block',
    lineHeight: 0,
    height: '.0625rem !important',
    overflow: 'hidden',
    padding: '0 !important',
    boxShadow: 'none',
    opacity: 0,
  }),
  labelContainer: css({
    padding: '.75rem 1rem .5rem 1rem',
    backgroundColor: palette.neutrals.gray50,
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    svg: {
      position: 'right',
      marginRight: '.625rem',
    },
  }),
  label: css({
    color: palette.neutrals.gray700,
    fontWeight: 400,
    fontSize: '0.75rem',
    marginBottom: 0,
    // Need this to allow clickthrough for the Froala focus stuff
    pointerEvents: 'none',
  }),
  readonlyInputStyles: css({
    fontSize: '1rem',
    background: hexToRGBA(palette.neutrals.gray50, 0.6),
    color: palette.neutrals.gray900,
  }),
  disabledInputStyles: css({
    background: hexToRGBA(palette.neutrals.gray50, 0.6),
    opacity: 0.4,
    p: {
      fontWeight: 600,
      color: palette.neutrals.gray800,
      fontSize: '1rem',
    },
    h6: {
      color: palette.neutrals.gray500,
      fontSize: '.75rem',
      textTransform: 'none',
      fontWeight: 400,
    },
  }),
};

interface ViewProps {
  value?: string,
  doChange?: (newValue: string) => void,
  clickFocusFroala?: (event: React.MouseEvent<HTMLElement>) => void,
  styleOverrides?: SerializedStyles,
  labelStyleOverrides?: SerializedStyles,
  name: string,
  label?: string,
  config: FroalaEditor,
  required?: boolean,
  isDisabled?: boolean,
  isReadOnly?: boolean,
  editorRef: RefObject<FroalaEditor>,
  labelStyles?: SerializedStyles,
  renderLabel?: (name: string, labelStyles: SerializedStyles) => JSX.Element,
  renderRightIcon?: () => JSX.Element,
  'data-test-id'?: string,
}

const View = ({
  value,
  doChange,
  clickFocusFroala,
  styleOverrides,
  labelStyleOverrides,
  name,
  label,
  config,
  required,
  isDisabled,
  isReadOnly,
  editorRef,
  labelStyles,
  renderLabel,
  renderRightIcon,
  'data-test-id': dataTestId,
}: ViewProps): JSX.Element => (
  <>
    {isReadOnly && (
      <DisabledInputBox
        label={label}
        htmlValue={value}
        css={
          [...Array.isArray(styleOverrides) ? styleOverrides : [styleOverrides], styles.readonlyInputStyles]
        }
      />
    )}
    {isDisabled && (
      <DisabledInputBox
        label={label}
        htmlValue={value}
        css={
          [...Array.isArray(styleOverrides) ? styleOverrides : [styleOverrides], styles.disabledInputStyles]
        }
      />
    )}
    {!isReadOnly && !isDisabled && (
      <>
        <div
          data-test-id={dataTestId}
          id="froalaWrapper"
          role="presentation"
          onClick={clickFocusFroala}
          css={[
            styles.container,
            ...(Array.isArray(styleOverrides)
              ? styleOverrides
              : [styleOverrides]),
          ]}
        >
          <div css={[
            styles.labelContainer,
            ...(Array.isArray(labelStyleOverrides)
              ? labelStyleOverrides
              : [labelStyleOverrides]),
          ]}
          >
            {label && (
              <label htmlFor={name} css={[styles.label, labelStyles]}>
                {label}
              </label>
            )}
            {renderLabel?.(name, styles.label)}
            {renderRightIcon?.()}
          </div>
          <ReactFroalaEditor
            ref={editorRef}
            model={value}
            name={name}
            onModelChange={doChange}
            config={config}
            tag="textarea"
          />
        </div>
        <input
          css={styles.invisibleInput}
          name={name}
          value={value}
          required={required}
          tabIndex={-1}
          // Here to fix console error, the actual change here is handled by doChange above and then this invisible input is used for forms
          onChange={noop}
        />
      </>
    )}
  </>
);

type OnChangeParams = {
  value: string,
  name?: string,
};

interface UseRichTextEditorProps {
  initialValue?: string,
  onChange?: (params: OnChangeParams) => void,
  name?: string,
  draftKey?: string[],
  preferDraftValue?: boolean,
}

interface UseRichTextEditorReturn {
  doChange: (newValue: string) => void,
  onBlur: () => void,
  value: string | undefined,
  setValue: Dispatch<SetStateAction<string | undefined>>,
  removeDraft: () => void,
}

const useRichTextEditor = ({
  initialValue,
  onChange,
  name,
  draftKey,
  draftConfig,
  preferDraftValue,
}: UseRichTextEditorProps): UseRichTextEditorReturn => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { draft, setDraft, removeDraft } = useDraft(draftKey, draftConfig);
  const actualInitialValue = preferDraftValue ? draft.value || initialValue || '' : initialValue || draft.value || '';

  const [value, setValue] = useState(actualInitialValue);

  const previousInitialValue = usePrevious(initialValue);
  const previousDraftKey = usePrevious(draftKey);

  useUpdateEffect(() => {
    if (draftKey !== previousDraftKey) {
      setValue(draft.value);
    }

    if (initialValue !== previousInitialValue) {
      setValue(initialValue);
    }

    // We very specifically only want to update the stored value if
    // the initial value or the draft -key- changes. Not if the draft
    // itself changes. Everything else excluded.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, draftKey]);

  const doChange = (newValue: string): void => {
    const text = newValue || '';
    setValue(text);

    if (setDraft) {
      // Use object spread to ensure a fresh object
      setDraft({
        ...draft,
        value: text,
      });
    }

    if (onChange) {
      onChange({ value: text, name });
    }
  };

  const onBlur = (): void => {
    const trimmedValue = value?.trim();

    if (trimmedValue !== value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setValue(trimmedValue);
    }
  };

  return {
    doChange,
    onBlur,
    value,
    setValue,
    removeDraft,
  };
};

const useFroalaConfig = ({
  heightMin = FROALA_DEFAULT_HEIGHT_MIN,
  enableEmojis = true,
  placeholderText = '',
  charCounterCount = true,
  charCounterMax = 5000,
  onBlur,
  onFocus,
  ...otherConfigOptions
}: Partial<FroalaOptions> & { enableEmojis: boolean | undefined }): Partial<FroalaOptions> => ({
  placeholderText,
  customButtons: {
    attach: {
      title: 'Attach',
      icon: {
        type: 'font',
        value: 'fa fa-paperclip',
      },
    },
  },
  toolbarButtons: {
    moreText: {
      buttons: ['attach', 'bold', 'italic', 'underline', 'formatOL', 'formatUL', 'insertLink', ...(enableEmojis ? ['emoticons'] : [])],
      buttonsVisible: 8,
    },
  },
  heightMin,
  listAdvancedTypes: false,
  emoticonsUseImage: false,
  charCounterCount,
  charCounterMax,
  key: process.env.REACT_APP_FROALA_KEY ?? '',
  toolbarSticky: false,
  quickInsertEnabled: false,
  pasteAllowedStyleProps: [],
  linkAlwaysBlank: true,
  linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
  pasteDeniedTags: ['pre'],
  attribution: false,
  events: {
    click: (e: MouseEvent) => {
      e.stopPropagation();
    },
    blur: () => {
      onBlur?.();
    },
    focus: () => {
      onFocus?.();
    },
  },
  ...otherConfigOptions,
});

interface UseFroalaEventsProps {
  defaultBold?: boolean,
  autoFocus?: boolean,
}

interface UseFroalaEventsPropsReturn {
  editorRef: RefObject<FroalaEditor>,
  focusFroala: () => void,
}

const useFroalaEvents = ({ defaultBold = false, autoFocus = false }: UseFroalaEventsProps): UseFroalaEventsPropsReturn => {
  const editorRef = useRef<FroalaEditor>();
  const focusFroala = (): void => {
    if (editorRef?.current?.editor.charCounter.count() > 0) {
      editorRef?.current?.editor.selection.setAtEnd(
        editorRef?.current?.editor.$el.get(0),
      );
      editorRef?.current?.editor.selection.restore();
      // Has to default to bold at the end of the selection, this does create a new strong tag
      if (defaultBold) {
        editorRef?.current?.editor?.commands?.bold();
      }
    } else {
      // Separate workflow for focusing if there is no text in the editor, since selecting at the end was causing a new line to happen
      editorRef?.current?.editor.events.focus();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (autoFocus) {
        // Sets the autofocus to the end of the input
        focusFroala();
      }

      if (defaultBold) {
        editorRef?.current?.editor?.commands?.bold();
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { editorRef, focusFroala };
};

const clickFocusFroala = (e: Event): UseFroalaEventsPropsReturns => {
  const parent = e.target.parentNode;
  const container = parent?.querySelector('[contenteditable="true"]');

  if (container) {
    const childNodeLen = container?.childNodes?.length;
    const { childNodes } = container;
    const setNodeCount = childNodeLen - 1;
    const selection = document.getSelection();
    const range = document.createRange();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    range.setStart(childNodes[setNodeCount], 1);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export interface FroalaProps extends
  Partial<ViewProps> {
  enableEmojis?: boolean,
  froalaEventsProps?: UseFroalaEventsProps,
  richTextEditorProps?: UseRichTextEditorProps,
  froalaConfigProps?: FroalaOptions,
}

const Froala = ({
  enableEmojis,
  froalaEventsProps,
  richTextEditorProps,
  froalaConfigProps,
  ...props
}: FroalaProps): JSX.Element => {
  const { editorRef } = useFroalaEvents({ ...froalaEventsProps });

  const {
    doChange, onBlur, value, setValue,
  } = useRichTextEditor({ ...richTextEditorProps });

  const config = useFroalaConfig({
    ...froalaConfigProps,
    enableEmojis,
  });

  const hookProps = {
    doChange,
    clickFocusFroala,
    onBlur,
    value,
    setValue,
    enableEmojis,
    config,
    editorRef,
  };

  return <View {...hookProps} {...props} />;
};

export {
  View,
  useRichTextEditor,
  useFroalaConfig,
  useFroalaEvents,
  clickFocusFroala,
};

export default Froala;
