import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import BaseballCard from '~People/BaseballCard';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';
import DrawerHeader from '~Common/components/Drawers/Header';
import CloseDrawerButton from '~Common/components/Buttons/CloseDrawer';

export const baseballCardDrawerTemplate = {
  name: 'BASEBALL_CARD_DRAWER',
  width: DRAWER_WIDTHS.BASEBALL_CARD,
};

const styles = {
  header: css` 
    position: absolute;
    right: 8px;
    top: 8px;
    padding: unset;
    margin-block-start: unset;
  `,
  layout: css`
    height: 100%;

    & > div {
      padding: unset;
    }

    & > div:nth-last-type(1) {
      height: 100%;
    }
  `,
};

function View({
  id, orgId, huddleId, renderButtons,
}) {
  return (
    <DrawerLayout
      css={styles.layout}
      renderHeader={() => (
        <DrawerHeader
          css={styles.header}
          renderButtons={renderButtons}
        />
      )}
      renderBody={() => (
        <BaseballCard
          id={id}
          orgId={orgId}
          huddleId={huddleId}
          isDrawer
        />
      )}
    />
  );
}

View.propTypes = {
  id: PropTypes.string.isRequired,
  huddleId: PropTypes.string.isRequired,
  renderButtons: PropTypes.func.isRequired,
  orgId: PropTypes.string,
};

View.defaultProps = {
  orgId: null,
};

function BaseballCardDrawer({
  popDrawer, ...props
}) {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(popDrawer({
      drawerName: baseballCardDrawerTemplate.name,
    }));
  };

  const hookProps = {
    renderButtons: () => (
      <CloseDrawerButton onClick={onClose} />
    ),
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
}

BaseballCardDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  popDrawer: PropTypes.func.isRequired,
};

BaseballCardDrawer.defaultProps = {};

registerDrawer({
  templateName: baseballCardDrawerTemplate.name,
  component: BaseballCardDrawer,
});

export { View };
export default BaseballCardDrawer;
