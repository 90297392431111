import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import { baseballCardColors } from '~Common/styles/colors';
import { withLineClamp } from '~Common/styles/mixins';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import { withNunitoFonts } from '~Common/styles/typography';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { editBioTemplate } from '../EditBioDrawer';

const styles = {
  container: css`
    margin-bottom: 1.5625rem;
  `,
  bioOverview: css`
    margin-block-end: 0.25rem;
    font-size: 1rem;
    color: ${baseballCardColors.bio};
    ${withLineClamp(3)}
    max-height: 4.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    ${withNunitoFonts()}
  `,
};

const View = ({ renderEditButton, bioText }) => (
  <div css={styles.container}>
    <SectionHeader text="About Me (Bio)" renderButton={(renderEditButton)} />
    <HTMLRenderer css={styles.bioOverview} htmlText={bioText} />
    <input type="hidden" value={bioText} name="aboutMe" />
  </div>
);

View.propTypes = {
  renderEditButton: PropTypes.func.isRequired,
  bioText: PropTypes.string.isRequired,
};

const AboutMe = ({
  user, ...props
}) => {
  const dispatch = useDispatch();

  const [bioText, setBioText] = useState(user.aboutMe || '');

  const onEdit = () => {
    dispatch(pushDrawerAction({
      drawer: {
        ...editBioTemplate,
        args: {
          user,
          bioText,
          setBioText,
        },
      },
    }));
  };

  const hookProps = {
    renderEditButton: () => (
      <LeadrButton
        onClick={onEdit}
        data-test-id="aboutMeEdit"
        size="small"
      >
        Edit
      </LeadrButton>
    ),
    user,
    bioText,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

AboutMe.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
};

AboutMe.defaultProps = {};

export { View };
export default AboutMe;
