import { css } from '@emotion/react';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '~Reviews/V2/Const/pageStyles';
import AdminReviewsTabNavigation from '~Reviews/V2/TopBar/AdminReviewsTabNavigation';
import { REVIEWS_CREATE_CYCLE } from '~Common/const/routes';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { ChangeEvent } from 'react';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Link } from 'react-router-dom';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';
import { forMobileTinyObject } from '~Common/styles/mixins';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useReviewTemplateDrawer } from '../templates/useReviewTemplateDrawer';
import { ViewPerspective } from '../Const/types';
import { useReviewsDashboardSearch } from '../stores/useReviewsDashboardSearch';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
  iconAndText: css({
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  }),
  leadrButton: css(
    forMobileTinyObject({
      width: '50%',
    }),
  ),
  toggleTab: (isAdmin: boolean) => css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    // TODO: Update this to account for reflections, once that is added, so 50% if 2 tabs (My Reviews and Reflections) and 1/3 if 3 tabs (My Reviews, Reflections, Admin)
    width: isAdmin ? '50%' : '100%',
  })),
};

interface ViewProps {
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  openReviewTemplateDrawer: () => void,
  viewPerspective: ViewPerspective,
  isAdmin: boolean,
  searchText: string,
}

const View = ({
  handleSearchTextChange,
  openReviewTemplateDrawer,
  viewPerspective,
  isAdmin,
  searchText,
}: ViewProps): JSX.Element => (
  <ModuleTopbar
    moduleTopbarLayout={isAdmin ? 'default' : 'defaultWithoutRightSection'}
    renderTabNavigationToggle={() => (
      <AdminReviewsTabNavigation
        viewPerspective={viewPerspective}
        toggleTabStyles={styles.toggleTab(isAdmin)}
      />
    )}
    renderSearchField={() => (
      <LeadrSearchField
        data-test-id="reviewsTopBarSearchField"
        defaultValue={searchText}
        onChange={handleSearchTextChange}
      />
    )}
    renderRightSection={isAdmin && viewPerspective !== ViewPerspective.Reflections ? () => (
      <>
        <LeadrButton
          onClick={openReviewTemplateDrawer}
          data-test-id="reviewTemplates"
          variant="ghost"
          css={styles.leadrButton}
        >
          Templates
        </LeadrButton>
        <CreateButton
          component={Link}
          to={REVIEWS_CREATE_CYCLE}
          itemName="Review Cycle"
          data-test-id="reviewsCreateNew"
          css={styles.leadrButton}
        />
      </>
    ) : undefined}
  />
);

interface TopBarLayoutProps {
  viewPerspective: ViewPerspective,
}

const TopBarLayout = ({
  viewPerspective,
}: TopBarLayoutProps): JSX.Element => {
  const { openDrawer: openReviewTemplateDrawer } = useReviewTemplateDrawer();
  const { isAdmin } = useUserPermissions();

  const {
    searchText,
    setSearchText,
  } = useReviewsDashboardSearch((state) => ({
    searchText: state.searchText,
    setSearchText: state.setSearchText,
  }));

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const hookProps = {
    handleSearchTextChange,
    openReviewTemplateDrawer,
    viewPerspective,
    isAdmin,
    searchText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, TopBarLayout };
export default TopBarLayout;
