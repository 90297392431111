import PropTypes from 'prop-types';

import { HIDDEN_VALUE_SHAPE } from '~Common/const/proptypes';

const HiddenField = ({
  value,
  name,
  ...props
}) => (
  <input
    type="hidden"
    name={name}
    value={value}
    {...props}
  />
);

HiddenField.propTypes = {
  value: HIDDEN_VALUE_SHAPE.isRequired,
  name: PropTypes.string.isRequired,
};

export default HiddenField;
