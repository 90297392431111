import Collapse from '@mui/material/Collapse';
import { ComponentProps, useState } from 'react';

interface RenderSectionHeaderParams {
  showContent: boolean,
  toggleShowContent: () => void,
}

interface ViewProps extends ComponentProps<'div'> {
  showContent: boolean,
  toggleShowContent: () => void,
  renderSectionHeader: (renderSectionHeaderParams: RenderSectionHeaderParams) => JSX.Element,
  renderCollapseContents: () => JSX.Element,
}

const View = ({
  showContent,
  toggleShowContent,
  renderSectionHeader,
  renderCollapseContents,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {renderSectionHeader({ showContent, toggleShowContent })}
    <Collapse in={showContent}>
      {renderCollapseContents()}
    </Collapse>
  </div>
);

interface LearningSectionCollapseProps extends Omit<ViewProps, 'showContent' | 'toggleShowContent'> {
  startCollapseOpen: boolean,
}

const LearningSectionCollapse = ({
  startCollapseOpen,
  ...props
}: LearningSectionCollapseProps): JSX.Element => {
  const [showContent, setShowContent] = useState(startCollapseOpen);

  const toggleShowContent = (): void => {
    setShowContent(!showContent);
  };

  const hookProps = {
    showContent,
    toggleShowContent,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LearningSectionCollapse;
