import { useQuery } from '@tanstack/react-query';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, getApi } from '~Deprecated/services/HttpService';

const getImportStatus = (): Promise<HttpCallReturn<boolean>> => {
  const url = `/organizations/${getOrganizationId() ?? ''}/backgroundJob/users/scheduleImport/isProcessing`;
  return getApi(url);
};

interface useImportStatusReturn {
  isImporting: boolean;
}

export const useImportStatus = (): useImportStatusReturn => {
  const userImportBackgroundJob = useFeatureFlag('userImportBackgroundJob');
  const result = useQuery({
    queryKey: [getOrganizationId(), 'importProcessing'],
    queryFn: getImportStatus,
    // 1 minute
    staleTime: 60000,
    enabled: userImportBackgroundJob,
  });

  return {
    isImporting: result.data?.response ?? false,
  };
};
