import { css, SerializedStyles } from '@emotion/react';
import {
  faArrowLeft,
  faPaperPlane,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { useCallback, useEffect } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { selectParticipantsDrawerTemplate } from '~Common/components/Drawers/SelectParticipantsDrawer';
import AttendeeList from '~Common/V3/components/Attendees/AttendeesList';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import emptyParticipants from '~Assets/images/empty-views/emptyParticipants.png';
import { palette } from '~Common/styles/colors';
import LearningOverviewCard from '~Learning/components/Shared/LearningOverviewCard';
import { CreateLearningReturn, useCreateLearning } from '~Learning/hooks/assigned/useCreateLearning';
import { CreateLearningPlaylistDrawerState, createLearningPlaylistDrawerTemplate } from '~Learning/components/CreateLearningPlaylistDrawer';
import { AssignLearningOptions } from '~Learning/const/interfaces';
import { CreateLearningPlaylistReturn, useCreateLearningPlaylist } from '~Learning/hooks/assigned/useCreateLearningPlaylist';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { ResourceType } from '~DevelopmentPlan/const/types';
import { addResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { DEFAULT_DATE, DEFAULT_PDP_PATHNAME } from '~DevelopmentPlan/const/defaults';
import { useHistory } from 'react-router-dom';
import { CreateLearningDrawerState, createLearningTemplate } from '..';

const styles = {
  drawerBody: css({}),
  emptyStateContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  emptyStateImage: css({
    width: '100%',
  }),
  emptyStateText: css({
    textAlign: 'center',
    color: palette.brand.indigo,
  }),
  buttonsContainer: css({
    display: 'flex',
    marginTop: '1.125rem',
  }),
  assignButton: css({
    marginRight: '0.75rem',
  }),
  learningContentCardBackButton: css({
    fontSize: '.625rem',
  }),
  buttonLoadingContainer: css({
    alignItems: 'center',
    columnGap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
  }),
};

export interface CreateLearningParticipantsDrawerState extends CreateLearningDrawerState, CreateLearningPlaylistDrawerState {
  selectedAttendees: string[],
  workflow?: AssignLearningOptions,
  isSelectParticipantsDrawerOpen: boolean,
  resourceId?: ResourceType,
}

export const createLearningParticipantsDrawerTemplate = {
  name: 'CREATE_LEARNING_PARTICIPANTS_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const CreateLearningParticipantsDrawer = ({
  drawerState,
  setDrawerState,
  transitionState,
  popDrawer,
  pushDrawer,
}: DrawerProps<CreateLearningParticipantsDrawerState>): JSX.Element => {
  const createLearningMutation = useCreateLearning(setDrawerState);
  const createLearningPlaylistMutation = useCreateLearningPlaylist(setDrawerState);
  const showOpenParticipantsButton = !drawerState.isSelectParticipantsDrawerOpen;

  const { runAddResourceValidations } = drawerState;
  const {
    pdpId,
  } = addResourceModalStore.getState();

  const isPlaylistWorkflow = drawerState.workflow === AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST;

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: createLearningParticipantsDrawerTemplate.name });
  };

  const onBackToLearningClick = (): void => {
    popDrawer({ popAll: true });
    pushDrawer({
      drawer: createLearningTemplate,
    });
  };

  const onBackToPlaylistClick = (): void => {
    popDrawer({ popAll: true });
    pushDrawer({
      drawer: createLearningPlaylistDrawerTemplate,
    });
  };

  const backDrawerClick = isPlaylistWorkflow ? onBackToPlaylistClick : onBackToLearningClick;

  const isMobile = useIsMobileQuery();

  const openSelectParticipantsDrawer = useCallback(() => {
    pushDrawer({
      drawer: {
        ...selectParticipantsDrawerTemplate,
        args: {
          alwaysShowCloseButton: true,
          useOrgIds: false,
        },
      },
    });
  }, [pushDrawer]);

  const closeSelectParticipantsDrawer = (): void => {
    popDrawer({ drawerName: selectParticipantsDrawerTemplate.name });
  };

  useEffect(() => {
    const hasEntered = transitionState === TRANSITION_STATE.ENTERED;

    if (!isMobile && hasEntered) {
      openSelectParticipantsDrawer();
    }
  }, [isMobile, transitionState, openSelectParticipantsDrawer]);

  const { selectedAttendees, resourceId } = drawerState;
  const history = useHistory();
  const hasSelectedParticipants = selectedAttendees?.length > 0;
  const notInPdps = !history.location.pathname.includes(DEFAULT_PDP_PATHNAME);
  const inPdps = runAddResourceValidations !== undefined && pdpId && resourceId !== undefined;
  const dispatch = useDispatch();
  const successfulLearningCreation = (data: HttpCallReturn<CreateLearningReturn | CreateLearningPlaylistReturn>): void => {
    toast.success('Successfully created your learning.', {
      autoClose: 1500,
    });
    if (inPdps && !notInPdps) {
      if (resourceId === ResourceType.LearningPlaylist) {
        const { playlistId } = data.response as CreateLearningPlaylistReturn;
        runAddResourceValidations(resourceId, playlistId);
      } else {
        const { learningId } = data.response as CreateLearningReturn;
        runAddResourceValidations(resourceId, learningId);
      }
    }
    if (notInPdps) {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));
    }
  };

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title={isPlaylistWorkflow ? 'Learning Playlist Participants' : 'Learning Participants'}
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
        renderBackButton={(backButtonStyles: SerializedStyles) => (
          <IconButton onClick={backDrawerClick} tooltip="Back" type="button" icon={faArrowLeft} css={backButtonStyles} />
        )}
      />
    ),
    onSubmit: () => {
      const { setResourceContentDueDate, setResourceContentTitle } = addResourceModalStore.getState();
      let dateToUse;
      if (drawerState.playlistDueDate) {
        dateToUse = new Date(drawerState.playlistDueDate);
      } else if (drawerState.dueDate) {
        dateToUse = new Date(drawerState.dueDate);
      } else {
        dateToUse = DEFAULT_DATE;
      }

      if (isPlaylistWorkflow) {
        // Build the create learning playlist request
        const createLearningPlaylistRequest = {
          title: drawerState.playlistTitle,
          description: drawerState.playlistDescription,
          dueDate: drawerState.playlistDueDate,
          assigneeUserIds: selectedAttendees,
          learnings: drawerState.playlistContentList,
        };
        if (inPdps) {
          setResourceContentTitle(createLearningPlaylistRequest.title);
          setResourceContentDueDate(dateToUse);
        }

        createLearningPlaylistMutation(createLearningPlaylistRequest, { onSuccess: successfulLearningCreation });
      } else {
      // Build the create learning request
        const createLearningRequest = {
          title: drawerState.contentTitle,
          contentURL: drawerState.contentLink,
          assigneeUserIds: selectedAttendees,
          dueDate: drawerState.dueDate,
          introduction: drawerState.introduction,
          questions: drawerState.questions,
        };
        if (inPdps) {
          setResourceContentTitle(createLearningRequest.title);
          setResourceContentDueDate(dateToUse);
        }
        createLearningMutation(createLearningRequest, { onSuccess: successfulLearningCreation });
      }
    },
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {isPlaylistWorkflow && (
          <LearningOverviewCard
            cardHeaderText="LEARNING PLAYLIST OVERVIEW"
            introductionHTML={drawerState.playlistDescription}
            title={drawerState.playlistTitle}
            renderRightHeaderItem={() => (
              <LeadrButton
                textButtonColor={palette.brand.blue}
                css={styles.learningContentCardBackButton}
                variant="text"
                onClick={onBackToPlaylistClick}
                data-test-id="learningBackToLearningPlaylist"
              >
                BACK TO LEARNING PLAYLIST
              </LeadrButton>
            )}
          />
        )}
        {!isPlaylistWorkflow && (
          <LearningOverviewCard
            introductionHTML={drawerState.introduction}
            title={drawerState.contentTitle}
            renderRightHeaderItem={() => (
              <LeadrButton
                textButtonColor={palette.brand.blue}
                css={styles.learningContentCardBackButton}
                variant="text"
                onClick={onBackToLearningClick}
                data-test-id="learningBackToLearning"
              >
                BACK TO LEARNING
              </LeadrButton>
            )}
          />
        )}
        {hasSelectedParticipants && (
          <AttendeeList
            isLoading={false}
            attendees={selectedAttendees}
            // TODO: Remove these when AttendeeList is typed
            isAttendeeSelected={undefined}
            onClick={undefined}
            disableUsers={undefined}
          />
        )}
        {!hasSelectedParticipants && (
          <div css={styles.emptyStateContainer}>
            <img src={emptyParticipants} alt="You have no participants." css={styles.emptyStateImage} />
            <div css={styles.emptyStateText}>You have not added any participants yet. Please select the participants you would like to participate.</div>
          </div>
        )}
        <div css={styles.buttonsContainer}>
          {hasSelectedParticipants && (
            <LeadrButton
              type="submit"
              css={styles.assignButton}
              data-test-id="learningAssign"
            >
              <LeadrButton.IconAndText icon={faPaperPlane} text="Assign" />
            </LeadrButton>
          )}
          {showOpenParticipantsButton && (
            <LeadrButton
              variant="ghost"
              onClick={openSelectParticipantsDrawer}
              data-test-id="learningOpenParticipants"
            >
              Open Participants
            </LeadrButton>
          )}
          {!showOpenParticipantsButton && (
            <LeadrButton
              variant="ghost"
              onClick={closeSelectParticipantsDrawer}
              data-test-id="learningCloseParticipants"
            >
              Close Participants
            </LeadrButton>
          )}
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: createLearningParticipantsDrawerTemplate.name,
  component: CreateLearningParticipantsDrawer,
});

export default CreateLearningParticipantsDrawer;
