import { css } from '@emotion/react';

import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useDisconnectExternalCalendar } from '~Meetings/hooks/CalendarIntegration/useDisconnectExternalCalendar';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { InputLabel, SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';
import { getEmail } from '~Common/utils/localStorage';
import { getAvailableCalendarsListLatencyErrorText, useGetAvailableCalendarsList } from '~Meetings/hooks/CalendarIntegration/useGetAvailableCalendarsList';
import { useGetSelectedCalendarId } from '~Meetings/hooks/CalendarIntegration/useGetSelectedCalendarId';
import { useSelectExternalCalendar } from '~Meetings/hooks/CalendarIntegration/useSelectExternalCalendar';
import { palette } from '~Common/styles/colors';
import CalendarSettingsSkeleton from './CalendarSettingsSkeleton';

const styles = {
  selectedCalendarDropdown: css({
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  }),
  subHeadingText: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    marginBottom: '1.5rem',
  }),
};

interface ViewProps {
  selectedCalendarId: string | undefined,
  isInformationLoading: boolean,
  calendarDropdownOptions: DropdownItem<string>[],
  handleDisconnectFromCalendar: () => void,
  handleSelectedCalendarChange: (event: SelectChangeEvent) => void,
  hasNylasLatency: boolean,
}

const View = ({
  selectedCalendarId,
  isInformationLoading,
  calendarDropdownOptions,
  handleSelectedCalendarChange,
  handleDisconnectFromCalendar,
  hasNylasLatency,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {isInformationLoading && (
      <CalendarSettingsSkeleton showButtonLoader={false} />
    )}
    {!isInformationLoading && (
      <>
        {calendarDropdownOptions.length === 0 && (
          <p css={styles.subHeadingText}>
            {hasNylasLatency && (
              <>{getAvailableCalendarsListLatencyErrorText}</>
            )}
            {!hasNylasLatency && (
              <>No calendars were detected, please connect your calendar or contact support.</>
            )}
          </p>
        )}
        {calendarDropdownOptions.length > 0 && (
          <>
            {!selectedCalendarId && (
              <p css={styles.subHeadingText}>Please select a calendar to complete your calendar integration setup.</p>
            )}
            <Dropdown
              items={calendarDropdownOptions}
              onChange={handleSelectedCalendarChange}
              value={selectedCalendarId ?? ''}
              renderLabel={() => (
                <InputLabel>
                  Selected Calendar
                </InputLabel>
              )}
              css={styles.selectedCalendarDropdown}
            />
          </>
        )}
      </>
    )}
    <LeadrButton
      color="danger"
      onClick={handleDisconnectFromCalendar}
      data-test-id="settingsDisconnectCalendar"
      size="small"
    >
      Disconnect from Calendar
    </LeadrButton>
  </div>
);

const SelectCalendar = ({ ...props }): JSX.Element => {
  const {
    availableCalendarsList,
    isLoading: areAvailableCalendarsLoading,
    availableCalendarsListCount,
    hasNylasLatency,
  } = useGetAvailableCalendarsList();

  const { selectedCalendarId, isLoading: isSelectedCalendarIdLoading } = useGetSelectedCalendarId({
    availableCalendarsListCount,
  });
  const disconnectExternalCalendarMutation = useDisconnectExternalCalendar();
  const selectExternalCalendar = useSelectExternalCalendar();

  const calendarDropdownOptions = useMemo(() => {
    if (!availableCalendarsList) return [];
    return Object.entries(availableCalendarsList).map(([calendarId, calendarName]) => ({
      value: calendarId,
      text: calendarName,
    }));
  }, [availableCalendarsList]);

  const handleDisconnectFromCalendar = (): void => {
    disconnectExternalCalendarMutation();
  };

  const handleSelectedCalendarChange = (event: SelectChangeEvent): void => {
    selectExternalCalendar({ email: getEmail() ?? '', calendarId: event.target.value });
  };

  const isInformationLoading = areAvailableCalendarsLoading || isSelectedCalendarIdLoading;

  const hookProps = {
    selectedCalendarId,
    isInformationLoading,
    calendarDropdownOptions,
    handleSelectedCalendarChange,
    handleDisconnectFromCalendar,
    hasNylasLatency,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default SelectCalendar;
