import { css } from '@emotion/react';
import AvatarMaybeMultipleMaybeNot from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { participantViewerTemplate } from '~Meetings/components/drawers/ParticipantViewer';
import { useDispatch } from 'react-redux';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';

const styles = {
  avatars: (isTablet: boolean, isMobile: boolean, isDrawer: boolean) => css({
    marginTop: '.5rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridGap: '.5rem',
    minWidth: '22.8125rem',
  }, isTablet && {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    minWidth: '11.375rem',
  }, isMobile && {
    gap: '.25rem',
    minWidth: '300px',
  }, isDrawer && {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  }),
  defaultMessage: css({
    color: palette.neutrals.gray700,
    fontSize: '.875rem',
    marginTop: '.5rem',
  }),
  avatarSkelly: css({
    height: '2.1875rem',
    width: '2.1875rem',
  }),
  showAllAttendees: (isTablet: boolean) => css({
    textTransform: 'uppercase',
    position: 'absolute',
    top: '.9375rem',
    right: 0,
    fontSize: '.6875rem',
    color: palette.brand.indigo,
    fontWeight: 500,
  }, isTablet && {
    top: '0',
    right: '.3125rem',
  }),
  fauxButton: css({
    cursor: 'pointer',
  }),
};

interface ParticipantAvatarProps {
  isTablet: boolean,
  isDrawer: boolean,
  usersInfo: PersonDisplayInformation[],
  usersCount: number,
  numberOfUsersToShow: number,
  onPersonClick: (orgUserId: string) => void,
}

export function ParticipantAvatar({
  isTablet,
  isDrawer,
  usersInfo,
  usersCount,
  numberOfUsersToShow,
  onPersonClick,
}: ParticipantAvatarProps): JSX.Element {
  const dispatch = useDispatch();
  const isMobile = useIsMobileQuery();

  const attendees = usersInfo.map((user) => user.orgUserId ?? '');

  const showAllAttendeeClick = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...participantViewerTemplate,
        args: {
          onSelect: (participantId: string) => {
            dispatch(pushDrawerAction({
              drawer: {
                ...baseballCardDrawerTemplate,
                args: {
                  id: participantId,
                  backdropStyles: css({
                    backdropFilter: 'none',
                    position: 'unset',
                    width: 0,
                    height: 0,
                  }),
                },
              },
            }));
          },
          useOrgIds: true,
          allowSelf: true,
          customFilter: attendees,
          title: 'Goal Participants',
        },
      },
    }));
  };
  return (
    <>
      {usersInfo.length > numberOfUsersToShow && (
      <LeadrButton
        css={styles.showAllAttendees(isTablet)}
        onClick={showAllAttendeeClick}
        variant="text"
        data-test-id="show-all-attendees"
      >
        VIEW ALL
      </LeadrButton>
      )}
      <AvatarMaybeMultipleMaybeNot
        css={styles.avatars(isTablet, isMobile, isDrawer)}
         // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        usersInfo={usersInfo}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        numberOfUsers={usersCount}
        numberOfUsersToShow={numberOfUsersToShow}
        avatarHeight={isMobile ? 28 : 35}
        avatarWidth={isMobile ? 28 : 35}
        renderAvatar={({ user, avatarHeight, avatarWidth }) => (
          <div
            key={user.orgUserId}
            onClick={() => onPersonClick(user.orgUserId ?? '')}
            onKeyDown={() => onPersonClick(user.orgUserId ?? '')}
            tabIndex={0}
            role="button"
          >
            <AvatarWithTooltip
              firstName={user.firstName}
              lastName={user.lastName}
              profileImageUrl={user.profileImageUrl}
              height={avatarHeight}
              width={avatarWidth}
            />
          </div>
        )}
        renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
          <OverflowAvatar
            onClick={showAllAttendeeClick}
            css={styles.fauxButton}
            {...overflowAvatarProps}
          />
        )}
      />
    </>
  );
}
