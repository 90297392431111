import { css } from '@emotion/react';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { Link, useParams } from 'react-router-dom';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import { TEAMS } from '~Common/const/routes';

import { palette } from '~Common/styles/colors';
import { useGetTeamDetails } from '../../hooks/useGetTeamDetails';
import { useShowCreateEditTeamModal } from '../../stores/useShowCreateEditTeamModal';

const styles = {
  moduleTopbar: css({
    gridTemplateAreas: '"toggleTabs"',
    gridAutoColumns: 'min-content',
  }),
  backButton: css({
    fontSize: '1rem',
    whiteSpace: 'nowrap',
  }),
};

interface ViewProps {
  showEditTeamButton: boolean,
  handleEditTeamClick: () => void,
}

const View = ({
  showEditTeamButton,
  handleEditTeamClick,
}: ViewProps): JSX.Element => (
  <ModuleTopbar
    moduleTopbarLayout="toggleTabsAndButtons"
    renderTabNavigationToggle={() => (
      <LeadrButton
        css={styles.backButton}
        variant="text"
        textButtonColor={palette.neutrals.gray800}
        data-test-id="peopleTeamDetailsBackToTeamsTable"
        component={Link}
        to={TEAMS}
      >
        <LeadrButton.IconAndText icon={faArrowLeft} text="Back to Teams" />
      </LeadrButton>
    )}
    renderRightSection={showEditTeamButton ? () => (
      <LeadrButton
        data-test-id="peopleTeamDetailsEditTeam"
        onClick={handleEditTeamClick}
      >
        Edit Team
      </LeadrButton>
    ) : undefined}
  />
);

const TeamDetailsTopbar = (): JSX.Element => {
  const { teamId } = useParams<{ teamId: string }>();
  const { data: teamDetails, isLoading } = useGetTeamDetails({ teamId });

  const showEditTeamButton = (teamDetails?.response.canEdit && !isLoading) || false;

  const setShowModal = useShowCreateEditTeamModal((state) => state.setShowModal);

  const handleEditTeamClick = (): void => {
    setShowModal(true);
  };

  const hookProps = {
    showEditTeamButton,
    handleEditTeamClick,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default TeamDetailsTopbar;
