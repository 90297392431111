import { css } from '@emotion/react';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormHelperText, FormLabel, FormControl } from '@mui/material';
import { palette } from '~Common/styles/colors';
import { forMobile } from '~Common/styles/mixins';
import { uuid } from '~Common/utils/uuid';

const styles = {
  root: css({
    width: '100%',
  }),
  label: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    color: palette.neutrals.gray700,
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    fontSize: '.875rem',
    fontWeight: '400',
    justifyContent: 'center',
    margin: '0',
    minHeight: '2.75rem',
    padding: '0.5rem',
    textAlign: 'center',

    'input[type="radio"][checked] + &, &:active': {
      backgroundColor: `${palette.brand.sky} !important`,
      color: palette.neutrals.white,
    },
  }, forMobile(`
    padding: 0.25rem;
  `)),
  optionWrap: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: '.5rem',
    width: '100%',
  }, forMobile(`
    gap: .25rem;
  `)),
  option: css({
    flex: '1',
  }),
  optionsValueLabels: css({
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '.625rem',

    '& span': {
      width: '33%',
      color: palette.neutrals.gray500,
      fontSize: '.75rem',

      '&:last-child': {
        textAlign: 'right',
      },
    },
  }),
};

interface CustomScaleProps {
  defaultValue?: number,
  label?: string,
  minLabel: string,
  maxLabel: string,
  maxValue: number,
  name: string,
  disabled?: boolean,
}

export const CustomScale = ({
  defaultValue,
  label = '',
  maxValue,
  minLabel,
  maxLabel,
  name,
  disabled = false,
}: CustomScaleProps): JSX.Element => {
  const { register, formState: { errors } } = useFormContext();
  const error = errors[name] as { message: string; };

  const scaleNumbers = [];
  for (let i = 1; i <= maxValue; i += 1) {
    // I'm looking for the index i, when the condition is true
    scaleNumbers.push({ value: i, label: i.toString() });
  }

  const idPrefix = uuid();

  return (
    <>
      <FormControl
        css={styles.root}
        error={!!error}
      >
        <FormLabel>
          {label}
        </FormLabel>
        <div css={styles.optionWrap}>
          {scaleNumbers.map((option) => (
            <Fragment key={option.value}>
              <input
                type="radio"
                hidden
                disabled={disabled}
                id={`${idPrefix}-radio-buttons-group-${option.value}`}
                value={option.value}
                defaultChecked={defaultValue === option.value}
                {...register(name)}
              />
              <label
                key={option.value}
                css={styles.label}
                htmlFor={`${idPrefix}-radio-buttons-group-${option.value}`}
              >
                {option.label}
              </label>
            </Fragment>
          ))}
        </div>
        <div css={styles.optionsValueLabels}>
          <span>{minLabel}</span>
          <span>{maxLabel}</span>
        </div>
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    </>
  );
};
