/* eslint-disable react/no-danger */
import { ComponentProps } from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faBookmark,
} from '@fortawesome/pro-solid-svg-icons';

import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import { AgendaTopic } from '~Meetings/hooks/v2/useGetAgendas';

const styles = {
  indicatorContainer: css({
    display: 'flex',
    alignItems: 'flex-start',
  }),
  indicator: css({
    marginRight: '0.4rem',
    color: `${palette.brand.indigo}`,
    height: '.625rem',
    width: '.625rem',
  }),
};

interface AgendaIndicatorsViewProps extends ComponentProps<'div'> {
  isRecurring: boolean;
  isBookmarked: boolean;
}

const View = ({ isRecurring, isBookmarked, ...props }: AgendaIndicatorsViewProps): JSX.Element => (
  <div css={styles.indicatorContainer} {...props}>
    {isBookmarked && (
      <Tooltip content="This topic is bookmarked for reflections.">
        <span>
          <FontAwesomeIcon
            icon={faBookmark}
            css={styles.indicator}
          />
        </span>
      </Tooltip>
    )}
    {isRecurring && (
      <Tooltip content="This topic will recur in future meetings.">
        <span>
          <FontAwesomeIcon
            icon={faArrowsRepeat}
            css={styles.indicator}
          />
        </span>
      </Tooltip>
    )}
  </div>
);

interface AgendaIndicatorsProps {
  agendaItem: AgendaTopic,
}

const AgendaIndicators = ({
  agendaItem,
  ...props
}: AgendaIndicatorsProps): JSX.Element => {
  const { isRecurring, isBookmarked } = agendaItem ?? {};
  const hookProps = {
    isRecurring,
    isBookmarked,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaIndicators;
