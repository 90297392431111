import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { usePeopleMetricsSystypes } from '~Insights/hooks/usePeopleInsightsData';

function colorFromEnumCode(code: string): string {
  if (code in palette.brand) {
    return palette.brand[code as keyof typeof palette.brand];
  }

  return palette.neutrals.gray400;
}

const styles = {
  dotContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.625rem',
  }),

  dot(code: string) {
    return css({
      height: '0.625rem',
      width: '0.625rem',
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: colorFromEnumCode(code),
    });
  },
};

interface ViewProps {
  colorCode: string,
  displayValue: string,
}

const View = ({
  colorCode,
  displayValue,
}: ViewProps): JSX.Element => (
  <div css={styles.dotContainer}>
    <div css={styles.dot(colorCode)} />
    {displayValue}
  </div>
);

interface MetricIndicatorProps {
  displayValue?: string,
  indicatorValue: number,
  useYesno?: boolean,
}

const MetricIndicator = ({
  indicatorValue,
  displayValue,
  useYesno = false,
  ...props
}: MetricIndicatorProps): JSX.Element => {
  const { metricIndicators } = usePeopleMetricsSystypes();
  const colorCode = Object.values(metricIndicators).find((item) => item.id === indicatorValue)?.code ?? 'grey';

  let yesnoDisplay = '';
  if (!displayValue && useYesno) {
    if (indicatorValue === 0) {
      yesnoDisplay = 'N/A';
    } else if (indicatorValue === 1) {
      yesnoDisplay = 'No';
    } else {
      yesnoDisplay = 'Yes';
    }
  }

  const hookProps = {
    colorCode,
    displayValue: displayValue ?? yesnoDisplay,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View, MetricIndicator };
export default MetricIndicator;
