import {
  MutableRefObject, useMemo, useRef,
} from 'react';
import { toast } from '~Common/components/Toasts';
import { useGetLinkedGoalsById } from '../linkGoals/useGetLinkedGoalsById';
import { UseLinkGoalParams, UseLinkGoalsReturn, useLinkGoal } from '../linkGoals/useLinkGoal';

interface UseLinkGoalWithExistingGoalLinksParams extends UseLinkGoalParams {
  goalId: string,
}

export interface UseLinkGoalWithExistingGoalLinksReturn extends Omit<UseLinkGoalsReturn, 'mutate'> {
  linkParentGoal: (parentGoalId: string | null) => void,
  unlinkParentGoal: () => void,
  linkSupportingGoals: (childGoalIds: string[]) => void,
  unlinkSupportingGoals: (childGoalIds: string[]) => void,
}

interface HandleErrorParams {
  error: Error,
  toastId: MutableRefObject<string | number | null>,
  defaultError: string,
}

const handleError = ({ error, toastId, defaultError }: HandleErrorParams): void => {
  if (error.message === 'Encountered error error.goals.updateLinkedGoals.circular.child.goal.hierarchy.detected'
    || error.message === 'Encountered error error.goals.updateLinkedGoals.circular.parent.goal.hierarchy.detected'
  ) {
    toast.update(toastId.current, {
      render: 'One of the goals you selected is already linked in the cascade.',
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    });
  } else {
    toast.update(toastId.current, {
      render: defaultError,
      type: toast.TYPE.ERROR,
      autoClose: 3000,
    });
  }
};

export const useLinkGoalWithExistingGoalLinks = ({
  goalId,
  ...options
}: UseLinkGoalWithExistingGoalLinksParams): UseLinkGoalWithExistingGoalLinksReturn => {
  const toastId = useRef<string | number | null>(null);
  const { data } = useGetLinkedGoalsById({ goalId });
  const originalParentGoalId = data?.response.parentGoal?.goalId;
  const orginalSupportingGoalIds = useMemo(() => data?.response.childGoals?.map((goal) => goal.goalId) || [], [data]);

  const {
    mutate: doLinkGoal,
    ...rest
  } = useLinkGoal({
    onMutate: () => {
      toastId.current = toast.info('Updating your linked goals...', { autoClose: false });
    },
    ...options,
  });

  const linkParentGoal = (parentGoalId: string | null): void => {
    doLinkGoal({
      goalId,
      parentGoalId: parentGoalId || '',
      childGoalIds: orginalSupportingGoalIds,
    }, {
      onSuccess: () => {
        toast.update(toastId.current, {
          render: 'Successfully linked the parent goal!',
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      },
      onError: (error) => {
        handleError({ error, toastId, defaultError: 'Failed to link the parent goal. Please try again.' });
      },
    });
  };

  const linkSupportingGoals = (childGoalIds: string[]): void => {
    const isMultipleNewGoals = childGoalIds.length > 1;

    doLinkGoal({
      goalId,
      parentGoalId: originalParentGoalId || '',
      childGoalIds,
    }, {
      onSuccess: () => {
        toast.update(toastId.current, {
          render: `Successfully linked the supporting goal${isMultipleNewGoals ? 's' : ''}!`,
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      },
      onError: (error) => {
        handleError({ error, toastId, defaultError: 'Failed to link the supporting goal. Please try again.' });
      },
    });
  };

  const unlinkParentGoal = (): void => {
    doLinkGoal({
      goalId,
      parentGoalId: '',
      childGoalIds: orginalSupportingGoalIds,
    }, {
      onSuccess: () => {
        toast.update(toastId.current, {
          render: 'Successfully unlinked the parent goal!',
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      },
      onError: (error) => {
        handleError({ error, toastId, defaultError: 'Failed to unlink the parent goal. Please try again.' });
      },
    });
  };

  const unlinkSupportingGoals = (childGoalIds: string[]): void => {
    const isMultipleNewGoals = childGoalIds.length > 1;

    doLinkGoal({
      goalId,
      parentGoalId: originalParentGoalId || '',
      childGoalIds: orginalSupportingGoalIds.filter((id) => !childGoalIds.includes(id)),
    }, {
      onSuccess: () => {
        toast.update(toastId.current, {
          render: `Successfully unlinked the supporting goal${isMultipleNewGoals ? 's' : ''}!`,
          type: toast.TYPE.SUCCESS,
          autoClose: 2000,
        });
      },
      onError: (error) => {
        handleError({ error, toastId, defaultError: 'Failed to unlink the supporting goal. Please try again.' });
      },
    });
  };

  return {
    linkParentGoal,
    linkSupportingGoals,
    unlinkParentGoal,
    unlinkSupportingGoals,
    ...rest,
  };
};
