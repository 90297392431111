/* eslint-disable */
// @ts-nocheck Not going to try and type this, its a mess, there are Algolia docs and comments below to see what is going on
import algoliasearch from 'algoliasearch/lite';
import { SearchClient } from 'instantsearch.js';
import { useMemo } from 'react';
import { getAlgoliaToken, useAlgoliaAuth } from './useAlgoliaAuth';

export const useGetAlgoliaSearchClient = (): SearchClient => {
  const result = useAlgoliaAuth();
  const { searchAccessToken } = result.data?.response ?? {};
  const { refetch } = result;
  const searchClient = useMemo(() => {
    let algoliaClient: SearchClient;
    const algoliaToken = getAlgoliaToken();
    const applicationId = process.env.ALGOLIA_APPLICATION_ID;

    if (algoliaToken && applicationId) {
      algoliaClient = algoliasearch(
        process.env.ALGOLIA_APPLICATION_ID,
        algoliaToken,
      );

      return {
        ...algoliaClient,
        // Stops the initial request when there is no query, we only want results after text has been typed in docs: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/
        // @ts-expect-error Not even going to attempt to type this Algolia stuff
        async search(requests) {
          try {
          // @ts-expect-error Not even going to attempt to type this Algolia stuff
            if (requests.every(({ params }) => !params.query)) {
              return Promise.resolve({
                results: requests.map(() => ({
                  hits: [],
                  nbHits: 0,
                  nbPages: 0,
                  page: 0,
                  processingTimeMS: 0,
                  hitsPerPage: 0,
                  exhaustiveNbHits: false,
                  query: '',
                  params: '',
                })),
              });
            }

            return await algoliaClient.search(requests);
          } catch (error) {
            // If the token has expired, refresh it and try again.
            if (error.status === 400) {
              await refetch();

              // When we get the new search token, do the search
              if (searchAccessToken) {
                return algoliaClient.search(requests);
              }
            }

            // I don't think we will ever hit this, but just to not break things, return an empty search result if we don't get the token after reauthing
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: '',
              })),
            });
          }
        },
      };
    }

    return undefined;
  }, [refetch, searchAccessToken]);

  return searchClient as SearchClient;
};
