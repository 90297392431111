import { SyntheticEvent, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { COACHING_LIST_PAST, COACHING_LIST_UPCOMING } from '~Common/const/routes';
import { navigateAction } from '~Deprecated/actions/navigate';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { SerializedStyles } from '@emotion/react';

interface ViewProps {
  value: string,
  onChange: (event: SyntheticEvent, route: string) => void,
  tabItems: LeadrToggleTextTabProps[],
  toggleTabStyles: SerializedStyles,
}

const View = ({
  tabItems, toggleTabStyles, ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs {...props}>
    {tabItems.map((tabItem) => (
      <LeadrToggleTabs.TextTab
        css={toggleTabStyles}
        data-test-id={tabItem['data-test-id']}
        key={tabItem.value}
        text={tabItem.text}
        value={tabItem.value}
      />
    ))}
  </LeadrToggleTabs>
);

interface MeetingsTabNavigationProps extends Omit<ViewProps, 'value' | 'tabItems' | 'onChange' | 'isUserDataLoading'> {
  initialRoute: string,
}

const MeetingsTabNavigation = ({ initialRoute, ...props }: MeetingsTabNavigationProps): JSX.Element => {
  const dispatch = useDispatch();

  const tabItems = [
    {
      text: 'Upcoming',
      value: COACHING_LIST_UPCOMING,
      'data-test-id': 'meetingsUpcoming',
    },
    {
      text: 'Past',
      value: COACHING_LIST_PAST,
      'data-test-id': 'meetingsPast',
    },
  ];

  const [value, setValue] = useState(initialRoute);

  useEffect(() => {
    setValue(initialRoute);
  }, [initialRoute]);

  const onChange = (event: SyntheticEvent, route: string): void => {
    if (route) {
      dispatch(navigateAction({ pathname: route }));
    }
  };

  const hookProps = {
    value,
    tabItems,
    onChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingsTabNavigation;
