import moment, { Moment } from 'moment-timezone';

interface GetDateStringProps {
  timestamp?: number | Date | Moment,
}

interface relativeDateStringReturn {
  dateString?: string,
  isPast?: boolean,
  isYesterday?: boolean,
  isToday?: boolean,
  isTomorrow?: boolean,
}
export const getDateString = ({ timestamp }: GetDateStringProps): relativeDateStringReturn => {
  let dateString;
  let isPast;
  let isYesterday;
  let isToday;
  let isTomorrow;

  if (timestamp) {
    const mDate = moment(timestamp);
    const now = moment();
    // .add() and .subtract() are mutative, so .clone() is very needed here
    isYesterday = mDate.clone().add(1, 'days').isSame(now, 'day');
    isToday = mDate.isSame(now, 'day');
    isTomorrow = mDate.clone().subtract(1, 'days').isSame(now, 'day');
    isPast = mDate.diff(now) < 0 && !mDate.isSame(now, 'day');

    if (isYesterday) {
      dateString = 'Yesterday';
    } else if (isToday) {
      dateString = 'Today';
    } else if (isTomorrow) {
      dateString = 'Tomorrow';
    } else if (mDate.isSame(now, 'year')) {
      dateString = mDate.format('MMM DD');
    } else {
      dateString = mDate.format('ll');
    }
  }

  return {
    dateString,
    isPast,
    isYesterday,
    isToday,
    isTomorrow,
  };
};
