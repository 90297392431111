import { DAYS_IN_WEEK } from '~Common/const/defaults';
import { getStorageItem, removeStorageItem, setStorageItem } from '~Common/utils/localStorage';

export function getDraft(key) {
  const obj = JSON.parse(getStorageItem(key));
  if (!obj) {
    return undefined;
  }
  if (new Date().getTime() > obj.expire) {
    removeStorageItem(key);
    return undefined;
  }
  return obj.value;
}

// You would probably want to debounce this by like 250ms or something
export function setDraft(key, value, update = true) {
  const now = new Date();
  const isDraftExist = getStorageItem(key);
  if (!isDraftExist || update) {
    setStorageItem(key, JSON.stringify({
      value,
      expire: now.setDate(now.getDate() + DAYS_IN_WEEK),
    }));
  }
}

export function clearDraft(key) {
  removeStorageItem(key);
}
