import {
  getApi, patchApi,
} from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';
import { appConfig } from '../config';

/** Start Meeting Deatil Page */
// Get Coaching by Id
export const getMeetingByIdService = (meetingId) => {
  const serverUrl = {
    host: hosts.coaching || appConfig.apiUrl,
    uri: `/organizations/${getOrganizationId()}/coachings/${meetingId}`,
  };
  return getApi(serverUrl, {}, {});
};

// Get Meeting by Id
export const getTeamMeetingByIdService = (meetingId) => {
  const serverUrl = {
    host: hosts.meeting || appConfig.apiUrl,
    uri: `/organizations/${getOrganizationId()}/meetings/${meetingId}`,
  };
  return getApi(serverUrl, {}, {});
};
/** End Meeting Deatil Page */

export const removeFeedbackQuestionFromCoachingService = async (meetingDetails) => {
  const serverUrl = {
    host: hosts.coaching || appConfig.apiUrl,
    uri: `/organizations/${getOrganizationId()}/coachings/${meetingDetails.coachingId}`,
  };
  return patchApi(serverUrl, meetingDetails.deleteObj, {});
};
