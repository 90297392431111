import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import {
  AssignedLearning, AssignedPlaylistDetails, LearningStatus, ReceivedLearning, ReceivedPlaylistDetail,
} from '~Learning/const/interfaces';
import moment from 'moment';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { receivedLearningQueryKeys } from '../received/queryKeys';
import { assignedLearningQueryKeys } from './queryKeys';

interface Learning {
  dueDate?: Date | string | null,
  learningId: string,
}

interface UpdatePlaylistDueDatesParams {
  playlistId: number,
  dueDate: string | null,
  learnings: Learning[],
}

const updatePlaylistDueDates = async (
  { playlistId, dueDate, learnings }: UpdatePlaylistDueDatesParams,
): Promise<HttpCallReturn<unknown>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/update/${playlistId}`;

  return patchApi<unknown>(serverUrl, { dueDate, learnings });
};

export const useUpdatePlaylistDueDates = (): UseMutateFunction<HttpCallReturn<unknown>, unknown, UpdatePlaylistDueDatesParams> => {
  const mutation = useMutation({
    mutationFn: updatePlaylistDueDates,

    onMutate: async ({ playlistId, dueDate, learnings }: UpdatePlaylistDueDatesParams) => {
      // #region Cancel Queries
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.playlistDetail(playlistId) });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.playlistDetail(playlistId) });
      // #endregion

      // #region Get Previous Data
      const previousReceivedLearningList = queryClient.getQueryData(receivedLearningQueryKeys.list());
      const previousAssignedLearningList = queryClient.getQueryData(assignedLearningQueryKeys.list());
      const previousReceivedPlaylistDetail = queryClient.getQueryData(receivedLearningQueryKeys.playlistDetail(playlistId));
      const previousAssignedPlaylistDetail = queryClient.getQueryData<HttpCallReturn<AssignedPlaylistDetails>>(
        assignedLearningQueryKeys.playlistDetail(playlistId),
      );
      // #endregion

      // #region Update Received Playlist Detail for the Playlist that was updated
      queryClient.setQueryData<HttpCallReturn<ReceivedPlaylistDetail>>(
        receivedLearningQueryKeys.playlistDetail(playlistId),
        (oldReceivedPlaylistDetail) => {
          if (oldReceivedPlaylistDetail) {
            const newReceivedPlaylistDetail = cloneDeep(oldReceivedPlaylistDetail);
            if (newReceivedPlaylistDetail) {
              newReceivedPlaylistDetail.response.dueDate = dueDate || undefined;
              // Update the learning due dates as well
              newReceivedPlaylistDetail.response.learnings = newReceivedPlaylistDetail.response.learnings.map((learning) => {
                const learningDueDate = learnings.find((learningWithUpdatedDueDate) => (learning.id === learningWithUpdatedDueDate.learningId))?.dueDate;
                return {
                  ...learning,
                  dueDate: learningDueDate instanceof Date ? learningDueDate?.toDateString() : learningDueDate || undefined,
                };
              });
            }

            return newReceivedPlaylistDetail;
          }

          return oldReceivedPlaylistDetail;
        },
      );
      // #endregion

      // #region Update Received List to have the Playlist update
      queryClient.setQueryData<ReceivedLearning[]>(receivedLearningQueryKeys.list(), (oldReceivedLearningList) => {
        if (oldReceivedLearningList) {
          const newReceivedLearningList = [...oldReceivedLearningList];

          const receivedPlaylistToUpdateIndex = newReceivedLearningList.findIndex(
            (receivedLearning) => receivedLearning.id === playlistId.toString(),
          );

          if (receivedPlaylistToUpdateIndex !== -1) {
            const newReceivedPlaylist = cloneDeep(newReceivedLearningList[receivedPlaylistToUpdateIndex]);

            const incompleteLearningIds = previousAssignedPlaylistDetail?.response.learnings.filter(
              (learning) => learning.status === LearningStatus.INCOMPLETE,
            ).map((learning) => learning.id);

            const incompleteLearningsWithDueDates = learnings.filter((learningWithDueDate) => incompleteLearningIds?.includes(learningWithDueDate.learningId));

            // Earliest due date from an incomplete learning
            const earliestLearningDueDate = new Date(Math.max.apply(null, incompleteLearningsWithDueDates?.map(
              (learningWithDueDate) => moment(learningWithDueDate.dueDate)?.valueOf() || -Infinity,
            ) || [-Infinity]));

            // Needs this to be the most recent not finished learning
            newReceivedPlaylist.dueDate = earliestLearningDueDate.toDateString();

            newReceivedLearningList.splice(receivedPlaylistToUpdateIndex, 1, newReceivedPlaylist);
          }

          return newReceivedLearningList;
        }

        return [];
      });
      // #endregion

      // #region Update Assigned Playlist Details for the Playlist that was updated
      queryClient.setQueryData<HttpCallReturn<AssignedPlaylistDetails>>(
        assignedLearningQueryKeys.playlistDetail(playlistId),
        (oldAssignedPlaylistDetail) => {
          if (oldAssignedPlaylistDetail) {
            const newAssignedPlaylistDetail = cloneDeep(oldAssignedPlaylistDetail);
            newAssignedPlaylistDetail.response.dueDate = dueDate || undefined;

            newAssignedPlaylistDetail.response.learnings = newAssignedPlaylistDetail.response.learnings.map((learning) => {
              const learningDueDate = learnings.find(
                (learningWithUpdatedDueDate) => (learning.id === learningWithUpdatedDueDate.learningId),
              )?.dueDate || undefined;

              return {
                ...learning,
                dueDate: learningDueDate instanceof Date ? learningDueDate?.toDateString() : learningDueDate,
              };
            });

            return newAssignedPlaylistDetail;
          }

          return oldAssignedPlaylistDetail;
        },
      );
      // #endregion

      // #region Update Assigned List to have the Playlist update
      queryClient.setQueryData<AssignedLearning[]>(assignedLearningQueryKeys.list(), (oldAssignedLearningList) => {
        if (oldAssignedLearningList) {
          const newAssignedLearningList = [...oldAssignedLearningList];

          const assignedPlaylistToUpdateIndex = newAssignedLearningList.findIndex(
            (assignedLearning) => assignedLearning.id === playlistId.toString(),
          );

          const newAssignedPlaylist = cloneDeep(newAssignedLearningList[assignedPlaylistToUpdateIndex]);
          newAssignedPlaylist.dueDate = dueDate || undefined;

          newAssignedLearningList.splice(assignedPlaylistToUpdateIndex, 1, newAssignedPlaylist);

          return newAssignedLearningList;
        }

        return [];
      });
      // #endregion

      // Return a context object with the old snapshotted values
      return {
        previousReceivedLearningList,
        previousAssignedLearningList,
        previousReceivedPlaylistDetail,
        previousAssignedPlaylistDetail,
      };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: receivedLearningQueryKeys.list() });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
    onError: (_, { playlistId }: UpdatePlaylistDueDatesParams, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(receivedLearningQueryKeys.list(), snapshot?.previousReceivedLearningList);
      queryClient.setQueryData(assignedLearningQueryKeys.list(), snapshot?.previousAssignedLearningList);
      queryClient.setQueryData(receivedLearningQueryKeys.playlistDetail(playlistId), snapshot?.previousReceivedPlaylistDetail);
      queryClient.setQueryData(assignedLearningQueryKeys.playlistDetail(playlistId), snapshot?.previousAssignedPlaylistDetail);

      toast.error('There was an error updating the playlist due dates. Please try again.', {
        autoClose: 1500,
      });
    },
  });

  return mutation.mutate;
};
