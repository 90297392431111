import './styles.scss';
import moment from 'moment-timezone';
import UserInfo from '../../../../UserInfo';
import RichText from '../../../../RichText';
import { getUserTimezone } from '~Common/utils/localStorage';

// TODO: Rewrite this with new UI in meetings
/**
 * @deprecated Old UI
 */
const ContentListItem = (props) => {
  const itemObj = props.queAnsData;
  const { ownerInfo } = props;
  const getTimezone = getUserTimezone();
  // const responseList = itemObj.response && itemObj.response.filter(d => d.respondedBy[0].id === getUserId() || d.respondedBy[0].id === ownerInfo[0].id) || [];
  const responseList = itemObj.response;
  return (
    <div className="content-list-item-wrapper">
      <div className="feedback-data-list">
        <div className="discussion">
          <div className="user-image">
            <UserInfo
              users={ownerInfo}
            />
          </div>
          <div className="agenda-text-wrapper">
            <RichText text={itemObj.text} className="ql-editor" />
          </div>
        </div>
      </div>
      {responseList && responseList.map((res, index) => (
        <div className="notes-list" key={index}>
          <div className="feedback-input-wrapper">
            <div className="user-image pl-2">
              <UserInfo
                users={res.respondedBy}
              />
            </div>

            <div className="agenda-text-wrapper">
              <div className="due-date">
                {res.createdDateTime
                  ? moment(res.createdDateTime).format('MMMM Do, YYYY')
                  : moment().format('MMMM Do, YYYY')}
              </div>
              <RichText text={res.text || ''} className="ql-editor" />
            </div>
          </div>
        </div>
      ))}

    </div>
  );
};
export default ContentListItem;
