export const BREAKPOINTS = {
  MIN: {
    TABLET: 768,
    DESKTOP: 1025,
  },
  MAX: {
    TINY: 480,
    MOBILE: 767,
    TABLET: 1024,
  },
};
