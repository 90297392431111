import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';

import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import { CardSkeleton } from '~Common/V3/components/Card';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';

import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useGetAssignedPlaylistDetails } from '~Learning/hooks/assigned/useGetAssignedPlaylistDetails';
import { RequestedLearningDrawerTab, useRequestedLearningDrawerTab } from '~Learning/hooks/utils/useRequestedLearningDrawerTab';
import { useRequestedLearningAssigneeResults } from '~Learning/hooks/utils/useRequestedLearningAssigneeResults';
import { LearningParticipantsDrawerState } from '../LearningParticipantsDrawer';

import DetailsTab from './Tabs/DetailsTab';
import QuestionsTab from './Tabs/ByQuestionsTab';
import ByParticipantTab from './Tabs/ByParticipantTab';
import { playlistParticipantsDrawerTemplate } from '../PlaylistParticipantsDrawer';

const styles = {
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
  cardSkeleton: css({
    minWidth: '100%',
    height: '12.5rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
  tabBody: css({
  }),
};

export const assignedPlaylistDetailDrawerTemplate = {
  name: 'ASSIGNED_PLAYLIST_DETAIL_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface LearningPlaylistCreatorDrawerProps extends DrawerProps<LearningParticipantsDrawerState> {
  playlistId: number,
  transitionState: TRANSITION_STATE,
}

const AssignedPlaylistDetailDrawer = ({
  playlistId,
  transitionState,
  pushDrawer,
  popDrawer,
  drawerState,
  ...props
}: LearningPlaylistCreatorDrawerProps): JSX.Element => {
  const dispatch = useDispatch();
  const showViewParticipantsButton = !drawerState.isLearningParticipantsDrawerOpen;

  const { detail: learningPlaylist, isLoading } = useGetAssignedPlaylistDetails({ playlistId });

  const [activeTab, setActiveTab] = useRequestedLearningDrawerTab();
  const [, setPersonIndex] = useRequestedLearningAssigneeResults();

  const closeDrawerClick = (): void => {
    dispatch(popDrawer({ drawerName: assignedPlaylistDetailDrawerTemplate.name }));
  };

  const isMobile = useIsMobileQuery();

  useEffect(() => {
    const hasEntered = transitionState === TRANSITION_STATE.ENTERED;

    if (!isMobile && hasEntered && learningPlaylist?.assignedUsersInfo) {
      dispatch(pushDrawer({
        drawer: {
          ...playlistParticipantsDrawerTemplate,
          args: {
            assignees: learningPlaylist?.assignedUsersInfo,
            playlistId: learningPlaylist?.id,
          },
        },
      }));
    }

    return () => {
      setActiveTab(RequestedLearningDrawerTab.DETAILS);
      setPersonIndex(0);
    };
  }, [isMobile, transitionState, learningPlaylist?.assignedUsersInfo, learningPlaylist?.id, pushDrawer, setPersonIndex, dispatch, setActiveTab]);

  const learningPlaylistDropdown = useMemo(() => learningPlaylist?.learnings.map((singleContent) => ({
    text: singleContent.title,
    value: singleContent.id,
  })), [learningPlaylist]);

  const hookProps = {
    renderHeader: () => (
      <TabDrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip={undefined} />
        )}
        title="Playlist Results"
        renderTabNav={(tabNavStyles: SerializedStyles) => (
          <div css={tabNavStyles}>
            <TabNavItem
              css={styles.tabNavItem}
              isActive={activeTab === RequestedLearningDrawerTab.DETAILS}
              onClick={() => setActiveTab(RequestedLearningDrawerTab.DETAILS)}
              renderNavItem={() => (
                <div>Details</div>
              )}
            />
            <TabNavItem
              isActive={activeTab === RequestedLearningDrawerTab.QUESTIONS}
              onClick={() => setActiveTab(RequestedLearningDrawerTab.QUESTIONS)}
              renderNavItem={() => (
                <div>Results By Question</div>
              )}
            />
            <TabNavItem
              isActive={activeTab === RequestedLearningDrawerTab.PERSON}
              onClick={() => setActiveTab(RequestedLearningDrawerTab.PERSON)}
              renderNavItem={() => (
                <div>Results By Participant</div>
              )}
            />
          </div>
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {isLoading && (
          <MultipleSkeletonLoaders
            numberOfSkeletons={3}
            renderSkeletonItem={() => (
              <CardSkeleton css={styles.cardSkeleton} />
            )}
          />
        )}
        {(!isLoading) && learningPlaylist && (
          <div css={styles.tabBody}>
            {activeTab === RequestedLearningDrawerTab.DETAILS && (
              <DetailsTab
                learningPlaylist={learningPlaylist}
                showViewParticipantsButton={showViewParticipantsButton}
              />
            )}
            {activeTab === RequestedLearningDrawerTab.QUESTIONS && (
              <QuestionsTab
                learningPlaylist={learningPlaylist}
                // TO DO try to find a better way to do the or empty array
                learningPlaylistDropdownItems={learningPlaylistDropdown || []}
                showViewParticipantsButton={showViewParticipantsButton}
              />
            )}
            {activeTab === RequestedLearningDrawerTab.PERSON && (
              <ByParticipantTab
                learningPlaylist={learningPlaylist}
                learningPlaylistDropdownItems={learningPlaylistDropdown || []}
                showViewParticipantsButton={showViewParticipantsButton}
              />
            )}
          </div>
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: assignedPlaylistDetailDrawerTemplate.name,
  component: AssignedPlaylistDetailDrawer,
});

export default AssignedPlaylistDetailDrawer;
