import { css } from '@emotion/react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import { NavigationIndicatorData, NavigationItem } from '~Root/components/OldNavigationSidebar/types';
import { useNavigationStore } from '~Root/components/OldNavigationSidebar/useNavigationStore';
import { SHARED_STYLES } from '~Root/components/OldNavigationSidebar/NavigationItems/styles';

const styles = {
  ...SHARED_STYLES,
  indicator: (isCollapsed: boolean) => css({
    color: palette.brand.indigo,
    width: '0.4rem',
  }, isCollapsed && {
    width: '0.4rem',
    position: 'absolute',
    right: '0.6rem',
  }, !isCollapsed && {
    paddingRight: '0.3rem',
  }),
};

interface ViewProps extends LinkButtonProps {
  onClick: () => void,
}

const View = ({
  isCollapsed,
  item,
  navigationIndicatorData,
  onClick,
}: ViewProps): JSX.Element => (
  <>
    {!!item.href && (
      <Tooltip
        key={item.label}
        content={isCollapsed ? item.label : null}
        placement="right"
      >
        <NavLink
          to={item.href}
          css={styles.navItem(isCollapsed)}
          activeClassName="active"
          onClick={onClick}
          data-href={item.href}
          data-label={item.label}
        >
          <FontAwesomeIcon icon={item.icon} css={styles.icon} />
          {!isCollapsed && (
            <div css={styles.label}>
              {item.label}
            </div>
          )}
          {item.hasIndicator?.(navigationIndicatorData) && (
            <FontAwesomeIcon
              icon={faCircle}
              css={styles.indicator(isCollapsed)}
            />
          )}
        </NavLink>
      </Tooltip>
    )}
  </>
);

interface LinkButtonProps {
  isCollapsed: boolean,
  item: NavigationItem,
  navigationIndicatorData: NavigationIndicatorData,
}

export const LinkButton = ({
  isCollapsed,
  item,
  navigationIndicatorData,
}: LinkButtonProps): JSX.Element => {
  const closeMobileNavigation = useNavigationStore((state) => state.closeMobileNavigation);
  const onClick = (): void => {
    closeMobileNavigation();
  };

  const hookProps = {
    isCollapsed,
    item,
    navigationIndicatorData,
    onClick,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
