import { css } from '@emotion/react';
import { getCreatedByMessage, getRecognizedMessage } from '~Recognition/const/functions';
import { RecognitionUserShape } from '~Recognition/const/types';

const styles = {
  text: (shouldBold: boolean) => css({
  }, shouldBold && {
    fontWeight: 'bold',
  }),
  recognizeMessage: (isHome: boolean) => css({
    maxWidth: 'calc(100% - 13.375rem)',
  }, isHome && {
    maxWidth: '100%',
  }),
};

interface ViewProps {
  createdByMessage: string,
  recognizedByMessage: string,
  bold?: boolean,
  adjustedTotalRecipients: number,
  isHome?: boolean,
}

const View = ({
  createdByMessage,
  recognizedByMessage,
  bold = false,
  adjustedTotalRecipients,
  isHome = false,
}: ViewProps): JSX.Element => (
  <div css={styles.recognizeMessage(isHome)}>
    <span>{createdByMessage}</span>
    {adjustedTotalRecipients === 0 && (
    <>
      <span css={styles.text(bold)}>
        {` ${recognizedByMessage} `}
      </span>
    </>
    )}
    {adjustedTotalRecipients === 1 && (
    <>
      <span css={styles.text(bold)}>
        {` ${recognizedByMessage} `}
      </span>
      {'and '}
      <span css={styles.text(bold)}>{`${adjustedTotalRecipients} other`}</span>
    </>
    )}
    {adjustedTotalRecipients > 1 && (
    <>
      <span css={styles.text(bold)}>
        {` ${recognizedByMessage} `}
      </span>
      {'and '}
      <span css={styles.text(bold)}>{`${adjustedTotalRecipients} others`}</span>
    </>
    )}
  </div>
);

interface RecognitionMessagingProps {
  totalRecipients: number,
  loggedInUserRecognized: boolean,
  recipients: RecognitionUserShape[],
  createdBy: RecognitionUserShape,
  bold?: boolean,
  isHome?: boolean,
}

const RecognitionMessaging = ({
  totalRecipients,
  loggedInUserRecognized,
  recipients,
  createdBy,
  bold = false,
  isHome = false,
}: RecognitionMessagingProps): JSX.Element => {
  const createdByMessage = getCreatedByMessage(createdBy);
  const recognizedByMessage = getRecognizedMessage(loggedInUserRecognized, recipients);
  const adjustedTotalRecipients = totalRecipients - 1;

  const hookProps = {
    createdByMessage,
    recognizedByMessage,
    bold,
    adjustedTotalRecipients,
    isHome,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default RecognitionMessaging;
