import { createAction } from '@reduxjs/toolkit';

import { registerAction } from '~Deprecated/reducers/organizationInsights';
import { mutateState } from '~Deprecated/utils/reduxUtils';

const clearOrganizationInsightsAction = createAction('LEADR.CLEAR_ORGANIZATION_INSIGHTS');

function clearOrganizationInsights(state) {
  return mutateState(state, (draftState) => {
    draftState.ids = [];
    draftState.data = {};

    return draftState;
  });
}

registerAction(clearOrganizationInsightsAction, clearOrganizationInsights);

export {
  clearOrganizationInsightsAction,
};
