import { css } from '@emotion/react';

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const withPrintStyles = (styles) => css`
  @media print {
    ${styles}
  }
`;
