import { createContext } from 'react';
import {
  MeetingDetailsInterface, MeetingFactoryType, MeetingFrequency, User,
} from '~Meetings/const/meetingsInterfaces';

export const MeetingInfoContext = createContext<MeetingDetailsInterface>({
  id: '',
  title: '',
  startTimeInMillis: 0,
  endTimeInMillis: 0,
  duration: 0,
  factoryId: '',
  frequency: MeetingFrequency.ONE_TIME,
  meetingTimeZone: '',
  isSeriesActive: true,
  factoryType: MeetingFactoryType.COACHING,
  daysOfWeek: [],
  organizer: {
    orgUserId: '',
    userId: '',
    firstName: '',
    lastName: '',
  } as User,
  attendees: [],
  attendeeOrgUserIds: [],
  seriesOwner: '',
  agendaItems: [],
  actionItems: [],
  permissions: [],
  nextHuddleId: '',
  nextHuddleStartTime: '',
  huddlePrivateNotes: [],
  kebabActions: [],
  nonLeadrAttendees: [],
  location: '',
});
