// This is a library component, disabling non-friendly library rules
/* eslint react/forbid-prop-types: 0 */

import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import MuiInputLabel from '@mui/material/InputLabel';
import MuiFormControl from '@mui/material/FormControl';
import MuiInput from '@mui/material/Input';

const style = css`
  & .MuiInputBase-input {
    cursor: pointer;
  }

  & .MuiInputLabel-root {
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
  }

  & .MuiInputBase-input {
    border: none;
  }
`;

/**
 * @deprecated Old UI
 */
const FilePicker = ({
  label, className, variant, BaseInput, name, ...props
}) => (
  <MuiFormControl
    css={style}
    className={className}
    variant={variant}
  >
    <MuiInputLabel
      className=""
      shrink
    >
      {label}
    </MuiInputLabel>
    <BaseInput
      type="file"
      label={label}
      name={name}
      {...props}
    />
  </MuiFormControl>
);

FilePicker.propTypes = {
  BaseInput: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard']),
  name: PropTypes.string.isRequired,
};

FilePicker.defaultProps = {
  BaseInput: MuiInput,
  label: 'Placeholder',
  className: '',
  variant: 'standard',
};

export default FilePicker;
