import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useState } from 'react';
import { palette } from '~Common/styles/colors';
import { forMobile } from '~Common/styles/mixins';

const styles = {
  label: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    color: palette.neutrals.gray700,
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    fontSize: '.875rem',
    fontWeight: '400',
    justifyContent: 'center',
    margin: '0',
    minHeight: '2.75rem',
    padding: '0.5rem',
    textAlign: 'center',

    'input[type="radio"]:checked + &, &:active': {
      backgroundColor: palette.brand.sky,
      color: palette.neutrals.white,
    },
  }, forMobile(`
    padding: 0.25rem;
  `)),
  optionWrap: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: '.5rem',
    width: '100%',
  }, forMobile(`
    gap: .25rem;
  `)),
  option: css({
    flex: '1',
  }),
  optionsValueLabels: css({
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '.625rem',

    '& span': {
      width: '33%',
      color: palette.neutrals.gray500,
      fontSize: '.75rem',

      '&:last-child': {
        textAlign: 'right',
      },
    },
  }),
};

const CustomScale = ({
  marks,
  minLabel,
  maxLabel,
  id,
  initialValue,
  disabled,
}) => {
  const [sliderValue, setSliderValue] = useState();
  const [stringifiedAnswer, setStringifiedAnswer] = useState();
  const onSelectedValue = (value) => {
    setStringifiedAnswer(JSON.stringify({
      id,
      response: value,
    }));
    setSliderValue(value);
  };
  const scaleNumbers = [];
  for (let i = 1; i <= marks; i += 1) {
    // I'm looking for the index i, when the condition is true
    scaleNumbers.push({ value: i, label: i.toString() });
  }
  return (
    <>
      <div css={styles.optionWrap}>
        {scaleNumbers.map((option, i) => (
          <div css={styles.option} key={option.value}>
            {initialValue && !sliderValue && (
              <>
                {/* NOTE: If you're changing the name here, you'll need to update the filters on responded surveys: ~Surveys/components/drawers/respond/index */}
                <input
                  name={`option-${option.value.toString()}-${id}`}
                  id={`${id}-${i}`}
                  type="radio"
                  value={option.value}
                  checked={option.value.toString() === initialValue.toString()}
                  onChange={() => onSelectedValue(option.value)}
                  hidden
                  disabled={disabled}
                />
                <label
                  htmlFor={`${id}-${i}`}
                  key={option.value}
                  css={styles.label}
                >
                  {option.label}
                </label>
              </>
            )}
            {!initialValue && (
            <>
              {/* NOTE: If you're changing the name here, you'll need to update the filters on responded surveys: ~Surveys/components/drawers/respond/index */}
              <input
                name={`option-${option.value.toString()}-${id}`}
                id={`${id}-${i}`}
                type="radio"
                value={option.value}
                checked={option.value === sliderValue}
                onChange={() => onSelectedValue(option.value)}
                hidden
                disabled={disabled}
              />
              <label
                htmlFor={`${id}-${i}`}
                key={option.value}
                css={styles.label}
              >
                {option.label}
              </label>
            </>
            )}

          </div>
        ))}
      </div>
      <input hidden type="input" id={id} name={id} value={stringifiedAnswer} />
      <div css={styles.optionsValueLabels}>
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </div>
    </>
  );
};

CustomScale.propTypes = {
  marks: PropTypes.number.isRequired,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomScale.defaultProps = {
  minLabel: '',
  maxLabel: '',
  id: '',
  initialValue: '',
  disabled: false,
};

export default CustomScale;
