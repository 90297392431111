import { css } from '@emotion/react';
import moment from 'moment-timezone';
import { Goals } from '@leadr-hr/types';
import { palette } from '~Common/styles/colors';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { PROGRESS_BAR_STYLES } from '~Goals/const/styles';
import { getDateString } from '~Common/utils/dateString';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import NewGoalStatus from '../Shared/NewGoalStatus';

const styles = {
  ...PROGRESS_BAR_STYLES,
  container: css({
    color: palette.neutrals.gray800,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: '1.5rem 1.875rem',
    rowGap: '1.125rem',
  }),
  progressRow: css({
    alignItems: 'center',
    columnGap: '0.50rem',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
    width: '12rem',
  }),
  timestamp: css({
    color: palette.neutrals.gray700,
    fontSize: '0.875rem',
    fontWeight: 500,
    textAlign: 'right',
  }),
  topRow: css({
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  }),
  lighterBackground: css({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: palette.neutrals.gray200,
    },
  }),

  header: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    fontWeight: 400,
  }),
  creator: css({
    alignItems: 'center',
    columnGap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
  }),
};

interface RightPanelProps {
  status: Goals.GoalStatusUpdate,
}

export const RightPanel = ({
  status,
}: RightPanelProps): JSX.Element => (
  <section css={styles.container}>
    <div css={styles.topRow}>
      <div>
        <p css={styles.header}>Progress</p>
        <div css={styles.progressRow}>
          <LinearProgress
            css={[styles.progressBar(status.status ?? Goals.GoalStatus.OnTrack), styles.lighterBackground]}
            variant="determinate"
            value={status.completionPercentage ?? 0}
          />
          {`${status.completionPercentage ?? 0}%`}
        </div>
      </div>
      <div>
        <p css={styles.header}>Status</p>
        <NewGoalStatus
          status={status.status}
          largerDisplay
        />
      </div>
      <div css={styles.timestamp}>
        <p>{getDateString({ timestamp: status.createdInMillis }).dateString}</p>
        <p>{moment(status.createdInMillis).format('h:mma')}</p>
      </div>
    </div>
    <div css={styles.creator}>
      <SquareAvatar
        height={22}
        width={22}
        imgUrl={status.creator.profileImageUrl}
      />
      {`${status.creator.firstName} ${status.creator.lastName}`}
    </div>
    <div>
      <p css={styles.header}>Update</p>
      <HTMLRenderer htmlText={status.statusCommentary} />
    </div>
  </section>
);
