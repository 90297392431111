import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useGenericStore } from './useGenericStore';

type Param = string | number | string[] | number[];

const separator = '|';

function stringifyValue(value: Param): string {
  if (typeof value === 'string') {
    return value;
  }
  if (Array.isArray(value) && value.length) {
    return value.join(separator);
  }

  return value.toString();
}

type UseQueryParamState<T extends Param> = [
  state: T,
  stateSetter: (val: T) => void,
];

export function useQueryParamState<T extends Param>(
  workflow: string,
  paramName: string,
  explicitInitialValue: T,
  isArray = false,
): UseQueryParamState<T> {
  const checkForArray = (paramValue: string): Param => {
    if (isArray) {
      // Actual array
      if (Array.isArray(paramValue)) {
        return paramValue;
      }

      // Splittable string
      if (paramValue.includes(separator)) {
        return paramValue.split(separator);
      }

      // Single string, no split
      if (paramValue.length) {
        return [paramValue];
      }

      return [];
    }

    return paramValue;
  };

  const queryParams = new URLSearchParams(window.location.search);
  const paramValue = queryParams.get(paramName);
  const defaultValue = paramValue || explicitInitialValue;

  const genericStore: { value: T, setValue: (val: T) => void } = useGenericStore({
    module: 'queryParams',
    workflow,
    feature: paramName,
    defaultValue,
  });
  const { value, setValue: setStateValue } = genericStore;
  const returnValue = checkForArray(value as string) as T;
  const history = useHistory();

  const setValue = useCallback((newValue: T): void => {
    const updatedQueryParams = new URLSearchParams(window.location.search);
    setStateValue(newValue);
    const stringified = stringifyValue(newValue);
    if (stringified === '') {
      updatedQueryParams.delete(paramName);
    } else {
      updatedQueryParams.set(paramName, stringified);
    }
    history.push({
      search: updatedQueryParams.toString(),
    });
    // window.history.pushState(null, '', `${window.location.pathname}?${updatedQueryParams.toString()}`);
  }, [history, paramName, setStateValue]);

  return [returnValue, setValue];
}
