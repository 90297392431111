import { EngagementCategory, EnpsCategory } from './types';

export interface Question {
  id: number,
  text: string,
  category?: EngagementCategory,
  options: QuestionOption[],
}
export interface QuestionEnps {
  id: number,
  text: string,
  category?: EnpsCategory,
  options: QuestionOption[],
}
export interface QuestionOption {
  text: string,
  value: number,
}

export const likertOptions: QuestionOption[] = [
  {
    text: 'Strongly Disagree',
    value: 1,
  },
  {
    text: 'Disagree',
    value: 2,
  },
  {
    text: 'Neutral',
    value: 3,
  },
  {
    text: 'Agree',
    value: 4,
  },
  {
    text: 'Strongly Agree',
    value: 5,
  },
];

export const enpsOptions: QuestionOption[] = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
].map((key) => ({
  text: key.toString(),
  value: key,
}));
