import {
  faCheck, faMessages, faBook, faSquarePollVertical, faClipboardUser,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { WhatsDueFilterOptions } from '~Home/const';
import {
  ACTION_ITEMS_BASE_ROUTE, FEEDBACK_BASE_ROUTE,
} from '~Common/const/routes';

export interface EmptyStateActionProps {
  route: string | undefined;
  content: string | undefined;
}
export interface EmptyStateContentReturn {
  action: EmptyStateActionProps;
  altText: string;
  text: string;
  iconToRender: IconProp;
}
interface EmptyStateContentProps {
  type: WhatsDueFilterOptions;
}
const iconLookUp = {
  [WhatsDueFilterOptions.Feedback]: faMessages,
  [WhatsDueFilterOptions.Learning]: faBook,
  [WhatsDueFilterOptions.Surveys]: faSquarePollVertical,
  [WhatsDueFilterOptions.Reviews]: faClipboardUser,
  [WhatsDueFilterOptions.ActionItems]: faCheck,
  [WhatsDueFilterOptions.AllDue]: faCheck,
};
const textLookUp = {
  [WhatsDueFilterOptions.Feedback]: 'and get Feedback from your team',
  [WhatsDueFilterOptions.Learning]: 'you have no Learnings to complete',
  [WhatsDueFilterOptions.Surveys]: 'you have no Surveys to complete',
  [WhatsDueFilterOptions.Reviews]: 'You have no Reviews to complete',
  [WhatsDueFilterOptions.ActionItems]: 'and track your progress',
  [WhatsDueFilterOptions.AllDue]: 'and track your progress',
};
const altTextLookUp = {
  [WhatsDueFilterOptions.Feedback]: 'Feedback',
  [WhatsDueFilterOptions.Learning]: 'You have no learning to complete',
  [WhatsDueFilterOptions.Surveys]: 'You have no surveys to complete',
  [WhatsDueFilterOptions.Reviews]: 'You have no reviews to complete',
  [WhatsDueFilterOptions.ActionItems]: 'Create an Action Item and track your progress',
  [WhatsDueFilterOptions.AllDue]: 'Create an Action Item and track your progress',
};
const actionLookUp = {
  [WhatsDueFilterOptions.Feedback]: { route: FEEDBACK_BASE_ROUTE, content: 'Request Feedback' },
  [WhatsDueFilterOptions.Learning]: { route: undefined, content: undefined },
  [WhatsDueFilterOptions.Surveys]: { route: undefined, content: undefined },
  [WhatsDueFilterOptions.Reviews]: { route: undefined, content: undefined },
  [WhatsDueFilterOptions.ActionItems]: { route: ACTION_ITEMS_BASE_ROUTE, content: 'Create an Action Item' },
  [WhatsDueFilterOptions.AllDue]: { route: ACTION_ITEMS_BASE_ROUTE, content: 'Create an Action Item' },
};
export const useEmptyStateContent = ({ type }: EmptyStateContentProps):EmptyStateContentReturn => {
  const iconToRender = iconLookUp[type] || faCheck;

  const text = textLookUp[type] || 'and track your progress';

  const altText = altTextLookUp[type] || 'Create an Action Item and track your progress';
  const action = actionLookUp[type] || { route: ACTION_ITEMS_BASE_ROUTE, content: 'Create an Action Item' };

  return {
    iconToRender,
    text,
    altText,
    action,
  };
};
