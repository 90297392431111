import {
  array, InferType, object, string,
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const editSurveyTemplateFormSchema = object({
  title: string().required(),
  objective: string().ensure(),
  questions: array().required(),
});

export const editSurveyTemplateFormResolver = yupResolver(editSurveyTemplateFormSchema);

export type FormValues = InferType<typeof editSurveyTemplateFormSchema>;
