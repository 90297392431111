import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { postApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import {
  AssignedLearning, FrontendRankedQuestion, LearningPersonDisplayInformation, LearningStatus, LearningType, ReceivedLearning,
} from '~Learning/const/interfaces';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { UserPendingItems, USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { OPTIMISTIC_ID } from '~Learning/const';
import { toast } from '~Common/components/Toasts';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import {
  CreateLearningParticipantsDrawerState,
  createLearningParticipantsDrawerTemplate,
} from '~Learning/components/CreateLearningDrawer/CreateLearningParticipantsDrawerTemplate';
import { PartialDrawerState } from '~Common/const/drawers';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PDP_PATHNAME } from '~DevelopmentPlan/const/defaults';
import { receivedLearningQueryKeys } from '../received/queryKeys';
import { assignedLearningQueryKeys } from './queryKeys';

interface CreateLearningParams {
  title: string,
  contentURL: string,
  assigneeUserIds: string[],
  dueDate: Date,
  introduction: string,
  questions: FrontendRankedQuestion[],
}

export interface CreateLearningReturn {
  learningId: string,
  organizationId: string,
  assigneeUserIds: string[],
}

const createLearning = async (
  shareLearningRequest: CreateLearningParams,
): Promise<HttpCallReturn<CreateLearningReturn>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/assign`;

  return postApi<CreateLearningReturn>(serverUrl, shareLearningRequest);
};

export const useCreateLearning = (
  setDrawerState: (
    callback: (prev: PartialDrawerState<CreateLearningParticipantsDrawerState>) => PartialDrawerState<CreateLearningParticipantsDrawerState>) => void,
):UseMutateFunction<HttpCallReturn<CreateLearningReturn>, unknown, CreateLearningParams> => {
  // TODO: Remove the casting when useNewPeople gets typed
  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, LearningPersonDisplayInformation>>;
  const currentUserId = getUserId() ?? '';

  const dispatch = useDispatch();
  const history = useHistory();
  const notInPdps = !history.location.pathname.includes(DEFAULT_PDP_PATHNAME);
  const mutation = useMutation({
    mutationFn: createLearning,
    onMutate: async (newLearning: CreateLearningParams) => {
      if (notInPdps) {
        // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
        dispatch(popDrawerAction({ popAll: true }));
      } else {
        toast.info('Creating your learning...', {
          autoClose: 5000,
        });
      }
      // Cancel any existing outbound queries
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });

      const previousReceivedLearningList = queryClient.getQueryData(receivedLearningQueryKeys.list());
      const previousAssignedLearningList = queryClient.getQueryData(assignedLearningQueryKeys.list());
      const previousUserPendingItems = queryClient.getQueryData(USER_PENDING_ITEMS_QUERY_KEY);

      const commonLearningDetails = {
        id: OPTIMISTIC_ID,
        title: newLearning.title,
        createdDateTime: new Date().toISOString(),
        dueDate: newLearning.dueDate?.toISOString() || undefined,
        description: newLearning.introduction,
        learningType: LearningType.SINGLE_LEARNING,
        status: LearningStatus.INCOMPLETE,
      };

      // Get first name, last name, etc from the assigneeUserIds
      const assignedUsersInfo = newLearning.assigneeUserIds.map((assigneeUserId) => peopleData[assigneeUserId]);

      const newAssignedLearning = {
        ...commonLearningDetails,
        assignedUsersInfo,
        numberOfUsersCompleted: 0,
        numberOfUsersAssigned: newLearning.assigneeUserIds.length,
      };

      queryClient.setQueryData<AssignedLearning[]>(assignedLearningQueryKeys.list(), (oldAssignedLearningList) => {
        if (oldAssignedLearningList) {
          if (oldAssignedLearningList?.length) {
            return [...oldAssignedLearningList, newAssignedLearning].sort(
              (learning1, learning2) => (learning2.createdDateTime < learning1.createdDateTime ? -1 : 1),
            );
          }

          return [newAssignedLearning];
        }

        return oldAssignedLearningList;
      });

      // If the user assigned the learning to themselves, update the received learning list with the new learning
      if (newLearning.assigneeUserIds.includes(currentUserId)) {
        const assignerPersonInformation = peopleData[currentUserId];

        const newReceivedLearning = {
          ...commonLearningDetails,
          assigner: assignerPersonInformation,
          completedLearningsCount: 0,
          totalLearningsCount: 0,
        };

        queryClient.setQueryData<ReceivedLearning[]>(receivedLearningQueryKeys.list(), (oldReceivedLearningList) => {
          if (oldReceivedLearningList) {
            if (oldReceivedLearningList?.length) {
              return [...oldReceivedLearningList, newReceivedLearning].sort(
                (learning1, learning2) => (learning2.createdDateTime < learning1.createdDateTime ? -1 : 1),
              );
            }

            return [newReceivedLearning];
          }

          return oldReceivedLearningList;
        });

        // Update the pendingLearningCount as well, since they have a new learning
        queryClient.setQueryData<HttpCallReturn<UserPendingItems>>(
          USER_PENDING_ITEMS_QUERY_KEY,
          (oldUserPendingItems) => oldUserPendingItems && ({
            ...oldUserPendingItems,
            response: {
              ...oldUserPendingItems.response,
              pendingLearningCount: oldUserPendingItems.response.pendingLearningCount + 1,
            },
          }),
        );
      }

      // Return a context object with the old snapshotted values
      return {
        previousReceivedLearningList,
        previousAssignedLearningList,
        previousUserPendingItems,
      };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: assignedLearningQueryKeys.list() });
      // Invalidate the learning notification dot and received learning list, since you can send a learning to yourself :)
      await queryClient.invalidateQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
    },
    onError: (_, newLearning, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(receivedLearningQueryKeys.list(), snapshot?.previousReceivedLearningList);
      queryClient.setQueryData(assignedLearningQueryKeys.list(), snapshot?.previousAssignedLearningList);
      queryClient.setQueryData(USER_PENDING_ITEMS_QUERY_KEY, snapshot?.previousUserPendingItems);

      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));
      // Reopen the drawer
      dispatch(pushDrawerAction({
        drawer: createLearningParticipantsDrawerTemplate,
      }));

      // Fill drawer state with the learning that was being created
      setDrawerState((prev) => ({
        ...prev,
        contentTitle: newLearning.title,
        contentLink: newLearning.contentURL,
        selectedAttendees: newLearning.assigneeUserIds,
        dueDate: newLearning.dueDate,
        introduction: newLearning.introduction,
        questions: newLearning.questions,
      }));

      toast.error('There was an error sending the learning. Please try again.', {
        autoClose: 1500,
      });
    },
  });

  return mutation.mutate;
};
