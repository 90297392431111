import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import BaseToast from './BaseToast';

const InfoLightToast = ({ text }) => (
  <BaseToast
    icon={faInfoCircle}
    text={text}
  />
);

InfoLightToast.propTypes = {
  text: PropTypes.string.isRequired,
};

InfoLightToast.defaultProps = {

};

export default InfoLightToast;
