import { useGenericStore } from '~Common/hooks/useGenericStore';

export interface UseShowCommentSectionResponse {
  setShowCommentSection: (showCommentSection: boolean) => void,
  showCommentSection: boolean,
}

export const useShowCommentSection = (agendaItemId: string): UseShowCommentSectionResponse => {
  const { setValue: setShowCommentSection, value: showCommentSection } = useGenericStore({
    module: 'leadr',
    workflow: 'meetings',
    feature: `comment-section-${agendaItemId}`,
    defaultValue: false,
  }) as { setValue: (value: boolean) => void, value: boolean,};

  return {
    setShowCommentSection,
    showCommentSection,
  };
};
