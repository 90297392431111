import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

interface UseKeyPressParams {
  keys: string[],
  additionalChecksCallback?: (event: KeyboardEvent) => boolean,
  callback: (event: KeyboardEvent) => void,
  node?: HTMLElement | null,
}

// If things get more complicated and we need a better/broader approach, we can replace this with: https://github.com/JohannesKlauss/react-hotkeys-hook/edit/main/src/validators.ts
// Stolen and altered a bit from: https://devtrium.com/posts/how-keyboard-shortcut
export const useKeyPress = ({
  keys,
  additionalChecksCallback,
  callback,
  node,
}: UseKeyPressParams): void => {
  // implement the callback ref pattern, this ensures that the passed in callback doesn't trigger rerenders, even if it isnt memoized
  const callbackRef = useRef(callback);
  const additionalChecksCallbackRef = useRef(additionalChecksCallback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
    additionalChecksCallbackRef.current = additionalChecksCallback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      if (keys.includes(event.key) && additionalChecksCallbackRef.current?.(event)) {
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [keys],
  ) as EventListenerOrEventListenerObject;

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode?.addEventListener('keydown', handleKeyPress);

    // remove the event listener so we don't get memory leaks
    return () => targetNode?.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};
