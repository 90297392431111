import { getApi, postApi } from '~Deprecated/services/HttpService';
import { hosts } from '~Deprecated/services/config';
import { appConfig } from '../config';

export const forgotPasswordService = (payload) => {
  const { data } = payload.user;
  const URL = {
    host: hosts.auth || appConfig.apiUrl,
    uri: '/auth/forgot-password',
  };
  return postApi(URL, data, {})
    .then((responseData) => responseData)
    .catch((message) => {
      throw message;
    });
};

export const selectOrganizationService = (data) => {
  const URL = {
    host: hosts.auth || appConfig.apiUrl,
    uri: '/selectOrganization/success.json',
  };
  return getApi(URL, data, {})
    .then((responseData) => responseData)
    .catch((message) => {
      throw message;
    });
};
