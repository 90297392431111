import { css } from '@emotion/react';
import { useMemo } from 'react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { personalDevelopmentStatusColor } from '~DevelopmentPlan/const/defaults';
import {
  getPersonalDevelopmentStatusIcon, getResourceStatusIcon, getPDPStatusName,
} from '~DevelopmentPlan/const/functions';
import {
  CompetencyResourceStatus, CompetencyResourceStatusType, PDPStatus,
} from '~DevelopmentPlan/const/types';

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
  }),
  goalDescription: css({
    fontSize: '.75rem',
  }),
  icon: (color: string) => css({
    marginRight: '0.5rem',
    verticalAlign: 0,
    color,
  }),
};

interface NewGoalStatusViewProps {
  icon: IconDefinition,
  goalColor: string,
  statusName: string,
}
const View = ({
  icon,
  goalColor,
  statusName,
}: NewGoalStatusViewProps): JSX.Element => (
  <div css={styles.container}>
    <FontAwesomeIcon
      icon={icon}
      css={styles.icon(goalColor)}
    />
    <div css={styles.goalDescription}>
      {statusName}
    </div>
  </div>
);
interface PersonalDevelopmentStatusProps {
  status: CompetencyResourceStatus | PDPStatus,
  isResource?: boolean,
}

const PersonalDevelopmentStatus = ({
  status,
  isResource,
}: PersonalDevelopmentStatusProps): JSX.Element => {
  const goalColor = personalDevelopmentStatusColor[status.id];
  const icon = getPersonalDevelopmentStatusIcon(status.id);
  const resourceIcon = getResourceStatusIcon(status.id);

  const statusName = useMemo(() => getPDPStatusName(status.description as CompetencyResourceStatusType), [status.description]);

  const hookProps = {
    icon: isResource ? resourceIcon : icon,
    goalColor,
    statusName,
  };
  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default PersonalDevelopmentStatus;
