import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  header: css({
    background: palette.neutrals.white,
    color: palette.neutrals.gray800,
  }),
  headerFlexContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  }),
  buttonContainer: css({
    display: 'flex',
  }),
  ancillaryButtonContainer: css({
    marginBottom: '.375rem',
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  title: css({
    flex: 1,
    fontWeight: 600,
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
  }),
  hr: css({
    height: '.5rem',
    margin: '0.625rem 0 0',
  }),
};

interface DetailsHeaderProps {
  title: string,
  renderAddButton?: () => JSX.Element,
  renderSecondButton?: () => JSX.Element,
  renderAncillaryButton?: () => JSX.Element,
}

const DetailsHeader = ({
  title,
  renderAddButton,
  renderSecondButton,
  renderAncillaryButton,
}: DetailsHeaderProps): JSX.Element => (
  <div css={styles.header}>
    <div css={styles.headerFlexContainer}>
      <div css={styles.title}>{title}</div>
      <div css={styles.buttonContainer}>
        {renderAddButton?.()}
        {renderSecondButton?.()}
      </div>
    </div>

    <hr css={styles.hr} />

    {renderAncillaryButton && (
      <div css={styles.ancillaryButtonContainer}>
        {renderAncillaryButton()}
      </div>
    )}
  </div>
);

export default DetailsHeader;
