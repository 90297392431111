import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import LeadrModal from '~Common/V3/components/LeadrModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { ParticipantList } from './ParticipantList';

const styles = {
  modalTitle: css({
    color: palette.brand.indigo,
  }),
  modalContent: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
  warningText: css({
    color: palette.brand.red,
    fontWeight: '600',
  }),
  spacer: css({
    margin: '2rem 0',
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  cancelButton: css({
    marginLeft: 'auto',
  }),
};

interface DisplayCompleteModalProps {
  open: boolean,
  bulkActionModalParams: string[],
  setBulkActionModalParams:(value: string[]) => void,
  completeCycle: (value: boolean) => void,
  setOpenCompleteModal: (value: boolean) => void,
  nudgeMultipleParticipants: (isFinishing: boolean) => void,
}

const DisplayCompleteModal = ({
  open,
  bulkActionModalParams,
  setBulkActionModalParams,
  completeCycle,
  setOpenCompleteModal,
  nudgeMultipleParticipants,
}: DisplayCompleteModalProps): JSX.Element => (
  <LeadrModal
    open={open}
    onClose={() => { setOpenCompleteModal(false); setBulkActionModalParams([]); }}
  >
    <LeadrModal.Header>
      <LeadrModal.Title css={styles.modalTitle}>
        Are you sure you want to finish this review cycle?
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body>
      {bulkActionModalParams?.length >= 1 && (
        <>
          <p css={styles.modalContent}>
            Unfinished reviews will be marked as incomplete and will not be editable for the following
            <span css={styles.warningText}>{` ${bulkActionModalParams?.length ?? 0} participants`}</span>
            :
          </p>
          <ParticipantList
            participants={bulkActionModalParams}
          />
        </>
      )}
      <p
        css={[styles.spacer, styles.modalContent]}
      >
        If edits are needed in the future, the review cycle can be reopened.
      </p>
    </LeadrModal.Body>
    <LeadrModal.Footer css={styles.footer}>
      <LeadrButton
        onClick={() => completeCycle(true)}
        data-test-id="reviewCycleFinish"
        size="small"
      >
        Finish Cycle
      </LeadrButton>
      {bulkActionModalParams?.length >= 1 && (
        <LeadrButton
          onClick={() => nudgeMultipleParticipants(true)}
          data-test-id="reviewCycleRemind"
          variant="ghost"
          size="small"
        >
          Remind Participants Instead
        </LeadrButton>
      )}
      <LeadrButton
        css={styles.cancelButton}
        onClick={() => { setOpenCompleteModal(false); setBulkActionModalParams([]); }}
        data-test-id="reviewCycleCancelComplete"
        color="danger"
        variant="ghost"
        size="small"
      >
        Cancel
      </LeadrButton>
    </LeadrModal.Footer>
  </LeadrModal>
);

export default DisplayCompleteModal;
