import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

export const IMPORT_STYLES = {
  icon: css({
    color: palette.neutrals.gray300,
    fontSize: '7.5rem',
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  headerTitle: css({
    color: '#2B2B8C',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '1rem',
    lineHeight: '1rem',
  }),
  userListContainer: css({
    display: 'flex',
    flexDirection: 'column',
    background: palette.neutrals.white,
    borderRadius: '.25rem',
    width: '100%',
    maxHeight: 'calc(100vh - 4.25rem - 3.8125rem - 3rem)',
    marginBlockStart: '1.5rem',
  }),
  userRow: css({
    display: 'flex',
    flex: '1',
  }),
  importUIContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBlockStart: '1.5rem',
    minHeight: '25rem',
  }),
  browse: css({
    color: palette.brand.primary,
    cursor: 'pointer',
  }),
  downloadSample: css({
    fontWeight: '400',
    fontSize: '.75rem',
    lineHeight: '1rem',
    marginBlockStart: '1.5rem',
    color: palette.brand.indigo,
  }),
  dropZone: css({
    boxSizing: 'border-box',
    borderRadius: '.5rem',
    width: '27.5625rem',
    height: '37.5rem',
    maxWidth: 'calc(100vw - 1rem)',
    maxHeight: 'calc(100vh - 21.875rem)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  dropText: css({
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: palette.brand.indigo,
    width: '27.5625rem',
    maxWidth: 'calc(100vw - 1rem)',
    padding: '0 3rem',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  }),
  uploadIcon: css({
    marginBlockEnd: '1.5rem',
    width: '7.5rem',
    height: '7.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};
