import { useQuery } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { useInsightsUserPerspective, UserPerspective } from './useInsightsUserPerspective';

export interface PeopleInsightsData {
  orgUserInsights: MetricsForPerson[],
  collectiveInsights: MetricsForGroup,
}

export interface MetricsForPerson {
  orgUserId: string,
  firstName: string,
  lastName: string,
  profilePictureUrl?: string,
  activeGoals: number,
  activityLastLogin: string,
  activityPerformedAction: number,
  agendaItemRatio: string,
  feedbackAverageCompletionTime: string,
  feedbackRequestedIn30Days: number,
  goalsUpdatedIn30Days: number,
  lastOneOnOneDateWithManager: string,
  oneOnOneFrequency: string,
  oneOnOneWithManagerIn30Days: number,
}

export interface MetricsForGroup {
  activeGoalsPercent: number,
  activityLastLoginIn7DaysPercent: number,
  activityPerformedActionIn7DaysPercent: number,
  agendaItemPercent: number,
  feedbackCompletedByOnePersonIn30DaysPercent: number,
  feedbackRequestedIn30DaysPercent: number,
  goalsUpdatedIn30DaysPercent: number,
  mamPercent: number,
  mauPercent: number,
  oneOnOneWithManagerIn30DaysPercent: number,
}

interface GetPeopleInsightsDataQuery {
  organizationId: string,
  userPerspective: UserPerspective,
}

function getPeopleInsightsDataQueryKey({
  organizationId,
  userPerspective,
}: GetPeopleInsightsDataQuery): string[] {
  return [organizationId, 'people-insights', 'metrics', userPerspective];
}

function getPeopleInsightsDataQuery({
  organizationId,
  userPerspective,
}: GetPeopleInsightsDataQuery): Promise<HttpCallReturn<PeopleInsightsData>> {
  const serverUrl = `/organizations/${organizationId}/people-insights/metrics/users?insightsFor=${userPerspective}`;
  return getApi<PeopleInsightsData>(serverUrl);
}

interface UsePeopleMetricsReturn {
  insightsData?: PeopleInsightsData,
  isLoading: boolean,
}

export function usePeopleMetrics(): UsePeopleMetricsReturn {
  const organizationId = getOrganizationId() ?? '';
  const { userPerspective } = useInsightsUserPerspective();

  const result = useQuery({
    queryKey: getPeopleInsightsDataQueryKey({ organizationId, userPerspective }),
    queryFn: () => getPeopleInsightsDataQuery({ organizationId, userPerspective }),
  });

  return {
    insightsData: result?.data?.response,
    isLoading: result?.isLoading,
  };
}

interface GetPeopleInsightsSystypesQuery {
  organizationId: string,
}

interface UsePeopleMetricsSystypesReturn {
  metricIndicators: Record<string, InsightsSystype>,
}

export interface InsightsSystype {
  id: number,
  code: string,
}

function getPeopleInsightsSystypesQuery({
  organizationId,
}: GetPeopleInsightsSystypesQuery): Promise<HttpCallReturn<UsePeopleMetricsSystypesReturn>> {
  const serverUrl = `/organizations/${organizationId}/people-insights/metrics/systypes`;
  return getApi<UsePeopleMetricsSystypesReturn>(serverUrl);
}

export function usePeopleMetricsSystypes(): UsePeopleMetricsSystypesReturn {
  const organizationId = getOrganizationId() ?? '';
  const result = useQuery({
    queryKey: [organizationId, 'people-insights', 'systypes'],
    queryFn: () => getPeopleInsightsSystypesQuery({ organizationId }),
    staleTime: Infinity,
  });

  return result?.data?.response ?? { metricIndicators: {} };
}
