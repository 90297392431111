import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { SerializedStyles, css } from '@emotion/react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { palette } from '~Common/styles/colors';
import { Goals } from '@leadr-hr/types';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import moment from 'moment';
import { useGoalStatusModalStore } from '../../stores/useGoalsStatusModalStore';
import { GoalUpdateStatusModal } from '../GoalUpdateStatusModal';
import { GoalHistoryDrawerDetails } from '../Shared/GoalHistoryDrawerDetails';

export const viewGoalHistoryTemplate = {
  name: 'VIEW_GOAL_HISTORY_DRAWER',
  type: templateType.PRIMARY,
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  drawerBodyContainer: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
    flex: 1,
  }),
  footer: (shouldBeSticky: boolean) => css({
    padding: '1.5rem',
  }, shouldBeSticky && {
    position: 'sticky',
    bottom: 0,
  }),
  creatorDate: css({
    color: palette.neutrals.gray600,
    fontWeight: 500,
    fontSize: '.75rem',
    marginTop: '-1rem',
  }),
};

export enum FormSubmittedByButton {
  UPDATE_ACTION_ITEM_BUTTON = 'UPDATE_ACTION_ITEM_BUTTON',
  COMPLETE_ACTION_ITEM_BUTTON = 'COMPLETE_ACTION_ITEM_BUTTON'
}

export interface CreateActionItemViewProps{
  closeDrawerClick: () => void,
  onAddStatusUpdate: () => void,
  goal: Goals.Goal,
  isReadOnly: boolean,
}

const View = ({
  closeDrawerClick,
  onAddStatusUpdate,
  goal,
  isReadOnly,
}:CreateActionItemViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title="Goal History"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            tooltip="Close"
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBodyContainer}>
        <div css={styles.drawerBody}>
          <GoalHistoryDrawerDetails
            statusUpdates={goal.statusUpdates}
          />
          <br />
          <div
            css={styles.creatorDate}
          >
            {`Created by ${goal.creator.firstName} ${goal.creator.lastName} on ${moment(goal.createdInMillis).format('MMM DD, YYYY')}`}
          </div>

        </div>
        {!isReadOnly && goal.permissions.includes(Goals.GoalPermission.CanEditGoalStatus) && (
          <footer css={styles.footer(false)}>
            <LeadrButton
              data-test-id="goalHistoryModalAddUpdate"
              onClick={onAddStatusUpdate}
              size="small"
            >
              <LeadrButton.IconAndText
                icon={faPlus}
                text="Add Update"
              />
            </LeadrButton>
            <GoalUpdateStatusModal />
          </footer>
        )}
      </div>
    )}
  />
);

interface CreateActionItemDrawerState {
  selectedAttendees?: string[],
  dueDate: Date,
}
interface GoalHistoryDrawerProps extends DrawerProps<CreateActionItemDrawerState> {
  goal: Goals.Goal,
  isReadOnly?: boolean,
}

const GoalHistoryDrawer = ({
  popDrawer,
  goal,
  isReadOnly = false,
}: GoalHistoryDrawerProps): JSX.Element => {
  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: viewGoalHistoryTemplate.name });
  };

  const closeButton = (closeButtonStyles: SerializedStyles): JSX.Element => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const { openStatusUpdateModal } = useGoalStatusModalStore((state) => ({
    openStatusUpdateModal: state.openStatusUpdateModal,
  }));

  const onAddStatusUpdate = (): void => {
    openStatusUpdateModal(goal);
  };

  const hookProps = {
    closeDrawerClick,
    closeButton,
    onAddStatusUpdate,
    goal,
    isReadOnly,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: viewGoalHistoryTemplate.name,
  component: GoalHistoryDrawer,
});

export { View };
export default GoalHistoryDrawer;
