import { Route, Switch } from 'react-router-dom';
import {
  PRINT_AGENDA,
  PRINT_FEEDBACK_BY_PERSON,
  PRINT_FEEDBACK_BY_QUESTION,
  PRINT_SURVEYS_BY_PERSON,
  PRINT_SURVEYS_BY_QUESTION,
  PRINT_INSIGHTS,
  PRINT_REVIEWS_BY_ID_V2,
} from '~Common/const/routes';
import SurveysPrint from '~Surveys/Components/Print/SurveysPrint';
import InsightsPrint from '~Insights/components/Print/InsightsPrint';
import ReviewsPrint from '~Reviews/V2/Layouts/ReviewsPrint';
import FeedbackPrint from '~Feedback/components/Print/FeedbackPrint';
import MeetingsPrint from '~Meetings/components/print';

const View = (): JSX.Element => (
  <Switch>
    <Route
      exact
      path={PRINT_REVIEWS_BY_ID_V2}
      render={() => (
        <ReviewsPrint />
      )}
    />
    <Route
      exact
      path={PRINT_FEEDBACK_BY_PERSON}
      render={() => (
        <FeedbackPrint byPerson byQuestion={false} />
      )}
    />
    <Route
      exact
      path={PRINT_FEEDBACK_BY_QUESTION}
      render={() => (
        <FeedbackPrint byQuestion byPerson={false} />
      )}
    />
    <Route
      exact
      path={PRINT_SURVEYS_BY_QUESTION}
      render={() => (
        <SurveysPrint byQuestion byPerson={false} />
      )}
    />
    <Route
      exact
      path={PRINT_SURVEYS_BY_PERSON}
      render={() => (
        <SurveysPrint byPerson byQuestion={false} />
      )}
    />
    <Route
      exact
      path={PRINT_INSIGHTS}
      render={() => (
        <InsightsPrint />
      )}
    />
    <Route
      exact
      path={PRINT_AGENDA}
      render={() => (
        <MeetingsPrint />
      )}
    />
  </Switch>
);

const Print = (): JSX.Element => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
    />
  );
};

export default Print;
