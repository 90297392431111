import * as types from '../../actions';

const error = (state = {}, action) => {
  const response = action.payload;
  switch (action.type) {
    case types.GET_MEETING_BY_ID_ERROR:
      if (response) {
        state.message = response.error;
      }
      return { ...state };

    case types.GLOBAL_API_ERROR:
      return { ...state };

    default:
      return state;
  }
};

export default error;
