import { css } from '@emotion/react';
import { ActionItem as ActionItemType } from '~ActionItems/const/interfaces';
import ActionItem from './ActionItem';
import Section from '../Section';

const styles = {
  title: css({
    marginBottom: '1.125rem',
  }),
  actionItemContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.375rem',
  }),
};

interface ViewProps {
  actionItems: ActionItemType[],
}

const View = ({
  actionItems,
  ...props
}: ViewProps): JSX.Element => (
  <Section
    {...props}
  >
    <Section.Title css={styles.title} title="Action Items & Cascading Communication" />
    <div css={styles.actionItemContainer}>
      {actionItems.map((actionItem) => (
        <ActionItem key={actionItem.id} actionItem={actionItem} />
      ))}
    </div>
  </Section>
);

interface ActionItemsProps {
  actionItems: ActionItemType[],
}

const ActionItems = ({
  ...props
}: ActionItemsProps): JSX.Element => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItems;
