import { useMutation } from '@tanstack/react-query';
import { postApiFormData } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

const uploadCSV = async ({ file }) => {
  const URL = `/organizations/${getOrganizationId()}/spreadsheets/user-import/upload`;
  return postApiFormData(URL, file);
};

export const useUploadCSV = () => {
  const mutation = useMutation({
    mutationFn: uploadCSV,
    onMutate: () => {},
    onError: (error, { onErrorCallback }) => {
      onErrorCallback?.(error);
      // Very odd return from the API, but this is how it is
      const { message } = error.status.messages[0];
      if (message) {
        toast.error(message, { autoClose: 5000 });
      } else {
        toast.error('The CSV failed to upload. Please try again...', { autoClose: 5000 });
      }
    },
    onSuccess: (data, { onSuccessCallback }) => {
      onSuccessCallback?.(data.response, true);
      toast.success('The CSV was uploaded successfully!', { autoClose: 5000 });
    },
  });
  return { uploadCSVMutation: mutation.mutate, isUploading: mutation.isPending };
};
