import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useMemo, useEffect } from 'react';
import { drawerInputLabelText } from '~Deprecated/ui/styles/colors';
import DrawerInput from '~Common/V3/components/DrawerInput';
import DrawerDropdown from '~Deprecated/ui/components/Inputs/DrawerDropdown';
import { noop } from '~Deprecated/utils';
import { forMobile } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';

const styles = {
  configContainer: css({
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '1.25rem',
  }),
  inputDisabledWrapper: css({
    opacity: '.5',
  }),
  input: css({
    background: 'rgba(245, 245, 247, 0.6)',
    display: 'flex',
    alignItems: 'flex-start',
    padding: '.75rem 1rem',
    borderRadius: '.5rem',
    maxWidth: '20%',
    marginRight: '.625rem',

    '& label': {
      fontSize: '.75rem',
      fontWeight: '400',
    },
  }, forMobile(`
    padding: .5rem;
  `)),
  disabledInput: css({
    background: 'transparent',
  }),
  borderlessInput: css({
    border: 'unset',
    padding: '0',
    background: 'none',

    '& input': {
      paddingBottom: '0',
      fontSize: '.875rem',
      paddingTop: '0',
    },
  }),
  label: css({
    color: drawerInputLabelText,
    marginBottom: '0',
    fontWeight: 'bold',
    minWidth: '1.25rem',
    marginLeft: '0.5rem',
    paddingBottom: '0.2rem',
  }, forMobile(`
  white-space: break-spaces;
`)),
  borderlessSelect: css({
    padding: '0 !important',
    border: 'unset !important',
    background: 'none',

    '& .MuiSelect-root': {
      border: 'unset',
      paddingTop: '0',
      paddingBottom: '0.2rem',
    },
    '& .MuiInputLabel-root': {
      fontSize: '.75rem',
      fontWeight: '400',
      color: palette.neutrals.gray700,
    },
    '& .MuiInput-input > div': {
      fontSize: '.875rem',
    },
  }),
  labelLowercase: css({
    textTransform: 'none !important',

    '& h6': {
      textTransform: 'none',
    },
  }),
};

const View = ({
  onMinLabelChange,
  onMaxLabelChange,
  onNumScaleChange,
  maxValueOptions,
  minLabel,
  maxLabel,
  numScale,
}) => (
  <div css={styles.configContainer}>
    <div
      css={styles.input}
    >
      <DrawerInput
        name="minLabel"
        label="Min Label"
        maxlength="100"
        initialValue={minLabel}
        css={[styles.borderlessInput, styles.labelLowercase]}
        onChange={(e) => onMinLabelChange(e.target.value)}
      />
    </div>
    <div css={[styles.input, styles.inputDisabledWrapper]}>
      <DrawerInput
        name="minValue"
        label="Min Value"
        initialValue={1}
        disabled="disabled"
        css={[styles.borderlessInput, styles.labelLowercase, styles.disabledInput]}
      />
    </div>
    <div css={styles.input}>
      <DrawerInput
        name="maxLabel"
        label="Max Label"
        maxlength="100"
        initialValue={maxLabel}
        css={[styles.borderlessInput, styles.labelLowercase]}
        onChange={(e) => onMaxLabelChange(e.target.value)}
      />
    </div>
    <div css={styles.input}>
      <DrawerDropdown
        name="numScale"
        label="Max Value"
        value={numScale}
        items={maxValueOptions}
        css={styles.borderlessSelect}
        onChange={(e) => onNumScaleChange(e.target.value)}
      />
    </div>
  </div>
);

View.propTypes = {
  onMinLabelChange: PropTypes.func,
  onMaxLabelChange: PropTypes.func,
  onNumScaleChange: PropTypes.func,
  minLabel: PropTypes.string.isRequired,
  maxLabel: PropTypes.string.isRequired,
  numScale: PropTypes.number.isRequired,
  maxValueOptions: PropTypes.arrayOf(PropTypes.objectOf({
    text: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
};

View.defaultProps = {
  onMinLabelChange: noop,
  onMaxLabelChange: noop,
  onNumScaleChange: noop,
};

const RankingPicker = ({
  minLabel, maxLabel, numScale, setDefaultValues = null, ...props
}) => {
  useEffect(() => {
    if (!minLabel || !maxLabel || !numScale) {
      setDefaultValues?.();
    }
  // eslint-disable-next-line
  }, []);

  const maxValueOptions = useMemo(() => [3, 4, 5, 6, 7, 8, 9, 10].map((num) => ({ text: num, value: num })), []);

  const hookProps = {
    maxValueOptions,
    minLabel,
    maxLabel,
    numScale,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

RankingPicker.propTypes = {
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  numScale: PropTypes.number,
  setDefaultValues: PropTypes.func,
};

RankingPicker.defaultProps = {
  minLabel: null,
  maxLabel: null,
  numScale: null,
  setDefaultValues: noop,
};

export default RankingPicker;
