import { SerializedStyles } from '@emotion/react';
import { SyntheticEvent } from 'react';
import { useHistory } from 'react-router';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { ViewPerspective } from '~Goals/const/types';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';

interface ViewProps {
  onChange: (event: SyntheticEvent, option: string) => void,
  tabItems: LeadrToggleTextTabProps[],
  value: string,
  viewPerspective: string,
  toggleTabStyles: SerializedStyles,
}

const View = ({
  tabItems,
  toggleTabStyles,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs {...props}>
    {tabItems.map((tabItem) => (
      <LeadrToggleTabs.TextTab
        data-test-id={tabItem['data-test-id']}
        css={toggleTabStyles}
        key={tabItem.value}
        text={tabItem.text}
        value={tabItem.value}
      />
    ))}
  </LeadrToggleTabs>
);

interface TabNavigationProps {
  viewPerspective: ViewPerspective,
  toggleTabStyles: SerializedStyles,
}

export const TabNavigation = ({
  viewPerspective,
  toggleTabStyles,
  ...props
}: TabNavigationProps): JSX.Element => {
  const tabItems = [
    {
      text: 'Open',
      value: GoalsRoutes?.ListOpen,
      'data-test-id': 'goalsTopBarOpenTab',
    },
    {
      text: 'Completed',
      value: GoalsRoutes?.ListComplete,
      'data-test-id': 'goalsTopBarCompletedTab',
    },
  ];

  const passedInTab = tabItems.find((option) => option.text.toLocaleLowerCase() === viewPerspective.toLocaleLowerCase());
  const value = passedInTab ? passedInTab.value : GoalsRoutes?.ListOpen;
  const history = useHistory();

  const onChange = (e: SyntheticEvent, route: string): void => {
    if (route) {
      const { location: { search } } = history;

      history.push({
        pathname: route,
        search,
      });
    }
  };

  const hookProps = {
    onChange,
    tabItems,
    value,
    viewPerspective,
    toggleTabStyles,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
