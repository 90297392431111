import { useEffect } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import PersonalityTypesList from '~Deprecated/ui/containers/Nexus/PersonalityTypes/PersonalityTypesList';
import { noop } from '~Deprecated/utils';
import { CircularProgress } from '@mui/material';
import { palette } from '~Common/styles/colors';

const styles = {
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const PersonalityTypes = ({ hasPendingCall, loadPersonalitiesAction }) => {
  useEffect(() => {
    loadPersonalitiesAction();
  }, [loadPersonalitiesAction]);

  return (
    <div>
      { hasPendingCall ? (
        <div className="center">
          <CircularProgress css={styles.circularProgress} />
        </div>
      ) : <PersonalityTypesList /> }
    </div>
  );
};

PersonalityTypes.propTypes = {
  hasPendingCall: PropTypes.bool,
  loadPersonalitiesAction: PropTypes.func,
};

PersonalityTypes.defaultProps = {
  hasPendingCall: false,
  loadPersonalitiesAction: noop,
};

export default PersonalityTypes;
