import { SerializedStyles, css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useCallback, useContext, useMemo } from 'react';
import { MeetingInfoContext } from '~Meetings/components/topic-suggestions/contexts/MeetingInfoContext';
import { useGetOldCuratedMeetingTemplates } from '~Meetings/hooks/templates/useGetOldCuratedMeetingTemplates';
import { AgendaTemplates } from '@leadr-hr/types';
import { getLocalId } from '~Common/utils/uuid';
import useAddTemplateTopics from '~Meetings/hooks/templates/useOldAddTemplateTopicsToMeeting';
import { MeetingFactoryType } from '~Meetings/const/meetingsInterfaces';
import {
  GetCuratedAgendaTemplateDetails,
  GetAgendaTopicTemplateDetails,
  GetCuratedMeetingTemplateDetails,
  GetTemplateTopic,
} from '~Meetings/components/topic-suggestions/const/types';
import { CURATED_BY_LEADR_TEXT } from '~Common/const/curated';
import MeetingTemplate, { TemplateSectionSkeleton } from '../MeetingTemplate';
import { useIsTopicAddedToMeeting } from '../../stores/useIsTopicAddedToMeeting';
import TopicSuggestionsBanner from '../../shared/TopicSuggestionsBanner';

const styles = {
  curatedByLeadrTab: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',
  }),
};

interface ViewProps {
  curatedTemplates: GetCuratedMeetingTemplateDetails[] | undefined,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
  handleAddTopicsToMeeting: (agendaTopics: GetCuratedAgendaTemplateDetails[]) => void,
  isLoading: boolean,
  getDisableAddAllTopicsButton: (templateTopics: GetTemplateTopic[]) => boolean,
}

const View = ({
  curatedTemplates,
  addTopicToMeeting,
  handleAddTopicsToMeeting,
  isLoading,
  getDisableAddAllTopicsButton,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.curatedByLeadrTab}
    {...props}
  >
    <TopicSuggestionsBanner curatedByLeadr />
    {isLoading && (
      <MultipleSkeletonLoaders
        css={styles.curatedByLeadrTab}
        renderSkeletonItem={() => (
          <TemplateSectionSkeleton />
        )}
        numberOfSkeletons={3}
      />
    )}
    {!isLoading && curatedTemplates && (
      <>
        {curatedTemplates.map((template) => (
          <MeetingTemplate
            key={template.id}
            addTopicToMeeting={addTopicToMeeting}
            template={template}
            creator={CURATED_BY_LEADR_TEXT}
            renderRightButton={(rightButtonStyles: SerializedStyles) => (
              <LeadrButton
                css={rightButtonStyles}
                variant="text"
                textButtonColor={palette.brand.indigo}
                onClick={() => handleAddTopicsToMeeting(template.children)}
                data-test-id="meetingTopicSuggestionsAddAllTemplateTopics"
                disabled={getDisableAddAllTopicsButton(template.children)}
              >
                + Add All
              </LeadrButton>
            )}
          />
        ))}
      </>
    )}
  </div>
);

const CuratedByLeadrTab = ({
  ...props
}): JSX.Element => {
  const { huddleId, meetingTemplateType } = useContext(MeetingInfoContext);

  // Converting the meeting template type to the meeting factory type to use old hooks and old query keys
  const oldMeetingTemplateType = useMemo(() => {
    if (meetingTemplateType === AgendaTemplates.MeetingTemplateType.Coaching) {
      return MeetingFactoryType.COACHING;
    }

    return MeetingFactoryType.TEAM_MEETING;
  }, [meetingTemplateType]);

  const { getIsAnyTemplateTopicInMeeting } = useIsTopicAddedToMeeting(huddleId);

  const getDisableAddAllTopicsButton = useCallback((templateTopics: GetTemplateTopic[]) => {
    const isAnyTemplaleTopicInMeeting = getIsAnyTemplateTopicInMeeting(templateTopics);
    return isAnyTemplaleTopicInMeeting;
  }, [getIsAnyTemplateTopicInMeeting]);

  // Since the new API does not return Curated By Leadr templates, we need to keep using the old API for now
  const { data, isLoading } = useGetOldCuratedMeetingTemplates({ meetingTemplateType: oldMeetingTemplateType });
  const { mutate: addTemplateItemsToMeetingMutation } = useAddTemplateTopics();

  // Transforming the old data to match the new data to be able to reuse logic and components
  const curatedTemplates = useMemo<GetCuratedMeetingTemplateDetails[] | undefined>(() => data?.response.map((template) => ({
    id: getLocalId(),
    text: template.meetingTemplateTitle,
    isIndividualQuestionsTemplate: false,
    children: template.agendaQuestions.map((question) => ({
      id: getLocalId(),
      text: question,
      type: AgendaTemplates.AgendaTemplateType.AgendaTopic,
    })),
  })), [data?.response]);

  const [areTemplatesLoading] = useSkeletonLoaders(isLoading);

  const handleAddTopicsToMeeting = useCallback((topics: GetAgendaTopicTemplateDetails[] | GetCuratedAgendaTemplateDetails[]): void => {
    const templateTopics = topics.map((item) => ({
      isRecurring: false,
      isPrivate: false,
      text: item.text,
      rank: 0,
    }));

    addTemplateItemsToMeetingMutation({
      meetingType: oldMeetingTemplateType,
      meetingId: huddleId,
      templateTopics,
    });
  }, [addTemplateItemsToMeetingMutation, huddleId, oldMeetingTemplateType]);

  const addTopicToMeeting = (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails): void => {
    handleAddTopicsToMeeting([agendaTopic]);
  };

  const hookProps = {
    curatedTemplates,
    addTopicToMeeting,
    handleAddTopicsToMeeting,
    isLoading: areTemplatesLoading,
    getDisableAddAllTopicsButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CuratedByLeadrTab;
