import { css } from '@emotion/react';
import { faBarsSort } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Tooltip from '~Common/components/Tooltip';

import { palette } from '~Common/styles/colors';
import { useSetUserLevelEnableCascadingGoals, useUserLevelEnableCascadingGoals } from '~Goals/stores/useUserLevelEnableCascadingGoals';

const styles = {
  cascadingToggleButton: css({
    padding: '.25rem .75rem',
  }),
};

interface ViewProps {
  handleToggle: () => void,
  isActive: boolean,
}

const View = ({
  handleToggle,
  isActive,
  ...props
}: ViewProps): JSX.Element => (
  <Tooltip content={isActive ? 'Disable cascading' : 'Enable cascading'}>
    <LeadrButton
      css={styles.cascadingToggleButton}
      onClick={handleToggle}
      variant="text"
      textButtonColor={isActive ? palette.brand.indigo : palette.neutrals.gray500}
      data-test-id="homeToggleCascadingGoals"
      {...props}
    >
      <FontAwesomeIcon
        icon={faBarsSort}
        flip="horizontal"
      />
    </LeadrButton>
  </Tooltip>
);

const CascadingToggleButton = ({ ...props }): JSX.Element => {
  const setUserLevelEnableCascadingGoals = useSetUserLevelEnableCascadingGoals();
  const userLevelEnableCascadingGoals = useUserLevelEnableCascadingGoals();

  const handleToggle = (): void => {
    setUserLevelEnableCascadingGoals(!userLevelEnableCascadingGoals);
  };

  const hookProps = {
    handleToggle,
    isActive: userLevelEnableCascadingGoals,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CascadingToggleButton;
