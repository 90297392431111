import { css } from '@emotion/react';
import { NormalizedReceivedSurvey } from '~Surveys/const/Survey';
import GridSurveyCard from '../GridSurveyCard';

const styles = {
  gridSurveyCard: css({
    width: '100%',
  }),
  gridSurveyCardSkeleton: css({
    minWidth: '100%',
    borderRadius: '0.5rem',
    height: '8.75rem',
    boxShadow: '0px 4px 8px -2px rgba(28, 42, 55, 0.2)',
  }),
};
const GridReceivedSurveyCardSkeletonStyles = styles.gridSurveyCardSkeleton;

interface ViewProps {
  survey: NormalizedReceivedSurvey,
  onSurveyClick: () => void,
}

const GridReceivedSurveyCard = ({
  onSurveyClick,
  survey,
}: ViewProps): JSX.Element => (
  <>
    <GridSurveyCard
      css={styles.gridSurveyCard}
      title={survey.title}
      description={survey.objective}
      isComplete={!!survey.surveyParticipant.completedOn}
      onCardClick={onSurveyClick}
      dueDate={survey.completeBy}
      numberOfUsersAssigned={1}
      assignedUsersInfo={[survey.createdBy]}
      status={survey.status.value}
    />
  </>
);

export default GridReceivedSurveyCard;

export { GridReceivedSurveyCardSkeletonStyles };
