interface UseSupportChatReturn {
  closeChat: () => void
  openChat: (message?: string) => void,
}

export function useSupportChat(): UseSupportChatReturn {
  const beacon = window.Beacon;

  const openChat = (message?: string): void => {
    beacon('prefill', {
      text: message,
    });
    beacon('navigate', '/ask/chat/');
    beacon('open');
  };

  const closeChat = (): void => {
    beacon('close');
  };

  return {
    closeChat,
    openChat,
  };
}
