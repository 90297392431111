import { css } from '@emotion/react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import LearningAvatar from '~Learning/components/Shared/LearningAvatar';

import { RequestedLearningQuestion } from '~Learning/const/interfaces';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  accordionMain: css({
    boxShadow: 'none',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '.3125rem',
  }),
  summary: css({
    borderRadius: '.3125rem',
    // this is temporary
    border: `1px solid ${palette.neutrals.gray300}`,
    color: palette.neutrals.gray800,
  }),
  aDetails: css({
    padding: '1.125rem 0 0 0',
    backgroundColor: palette.neutrals.white,
  }),
  card: css({
  }),
  responseContainer: css({
    display: 'flex',
    flexDirection: 'row',
    padding: '1rem',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '.3125rem',
  }),
  avatar: css({
    marginRight: '.875rem',
    height: '2rem',
    width: '2rem',
  }),
  responsesInfo: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  assigneeInfo: css({
    marginTop: '1rem',
    color: palette.neutrals.gray700,
  }),
  icon: css({
    color: palette.brand.indigo,
  }),
  cardBackground: (hasDarkBackground: boolean) => css(
    {
      padding: '1rem',
      boxShadow: 'none',
      '&:not(:last-child)': {
        marginBottom: '.625rem',
      },
    },
    hasDarkBackground && {
      background: palette.neutrals.gray50,
    },
  ),
  freeformCard: css({
    display: 'flex',
  }),
  title: css({
    fontSize: '1.125rem',
    marginBottom: '1rem',
    color: palette.neutrals.gray900,
  }),
  freeformInfo: css({
  }),
};

interface LearningsAccordionProps {
  questions: RequestedLearningQuestion[],
}

const LearningsAccordion = ({
  questions,
}: LearningsAccordionProps): JSX.Element => (
  <div>

    {questions.map((question) => (
      <>
        <Accordion
          css={styles.accordionMain}
        >
          <AccordionSummary
            css={styles.summary}
            expandIcon={<FontAwesomeIcon css={styles.icon} icon={faCaretDown} />}
          >
            <HTMLRenderer htmlText={question.topic} />
          </AccordionSummary>

          <AccordionDetails css={styles.aDetails}>

            <Card
              css={styles.card}
              renderContents={() => (
                <>
                  {question.responses.map((response, index) => (
                    <Card
                      key={response.id}
                      css={styles.cardBackground(index % 2 === 0)}
                      renderContents={() => (
                        <div css={styles.freeformCard}>
                          <LearningAvatar
                            css={styles.avatar}
                            userId={response.assignee.id}
                          />
                          <div css={styles.freeformInfo}>
                            <div>
                              {response.text && (
                                <HTMLRenderer css={styles.responsesInfo} htmlText={response.text} />
                              )}
                            </div>
                            <div css={styles.assigneeInfo}>{`${response.assignee.firstName} ${response.assignee.lastName}`}</div>
                          </div>
                        </div>
                      )}
                    />
                  ))}
                </>
              )}
            />
          </AccordionDetails>

        </Accordion>
      </>
    ))}

  </div>
);

export default LearningsAccordion;
