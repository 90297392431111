import { MultiRadio } from '~Common/V3/components/uncontrolled';
import { SelectOption, usePeopleTagPicker } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import { UserGroup } from '~Common/const/userGroups';
import { ChangeEvent, useMemo, useState } from 'react';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import AdminSelector from './AdminSelector';

enum AdminVisibilityType {
  AllAdmins = 'allAdmins',
  SpecificAdmins = 'specificAdmins',
}

interface ViewProps {
  adminVisibility: AdminVisibilityType,
  handleAdminVisibilityChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void,
  isMobile: boolean,
  isLoading: boolean,
  adminList: SelectOption[],
  initialSelectedAdmins: SelectOption[],
  onSelectedAdminsChange: (value: string[]) => void,
  disabled?: boolean,
}

const View = ({
  disabled = false,
  adminVisibility,
  handleAdminVisibilityChange,
  isLoading,
  isMobile,
  adminList,
  initialSelectedAdmins,
  onSelectedAdminsChange,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <MultiRadio
      name="adminVisibility"
      label="ADMIN VISIBILITY"
      vertical={isMobile}
      defaultValue={adminVisibility}
      disabled={disabled}
      onChange={handleAdminVisibilityChange}
      radios={[
        {
          name: (
            <p>
              <span>All Admins</span>
            </p>
          ),
          value: 'allAdmins',
        },
        {
          name: (
            <p>
              <span>Select Specific Admins</span>
            </p>
          ),
          value: 'specificAdmins',
        },
      ]}
    />
    {adminVisibility === AdminVisibilityType.SpecificAdmins && !isLoading && (
      <AdminSelector
        adminList={adminList}
        initialSelectedAdmins={initialSelectedAdmins}
        onChange={onSelectedAdminsChange}
        disabled={disabled}
        shouldDisableSelectedAdmin={(selectedAdmin) => selectedAdmin.value === getOrganizationUserId()}
      />
    )}
  </div>
);

interface AdminVisibilityProps extends Pick<ViewProps, 'disabled'>{
  onSelectedAdminsChange: (value: string[]) => void,
  initialSelectedAdminIds?: string[],
}

const AdminVisibility = ({
  initialSelectedAdminIds = [],
  onSelectedAdminsChange,
  ...props
}: AdminVisibilityProps): JSX.Element => {
  const {
    peopleList: adminList = [],
    isLoadingPeopleData,
  } = usePeopleTagPicker({
    administrativeStatusesToInclude: ['ACTIVE', 'INVITED'],
    allowSelf: true,
    allowLeadrCoach: false,
    shouldShowUser: (person) => person.applicationGroupName === UserGroup.Admin,
    shouldDisableUser: (person) => person.orgUserId === getOrganizationUserId(),
  });

  const isMobile = useIsMobileQuery();
  const [isLoading] = useSkeletonLoaders(isLoadingPeopleData);

  const initialSelectedAdmins = useMemo(() => (
    adminList.filter((admin) => initialSelectedAdminIds.includes(admin.value) || admin.value === getOrganizationUserId())
  ), [adminList, initialSelectedAdminIds]);

  const [adminVisibility, setAdminVisibility] = useState<AdminVisibilityType>(
    initialSelectedAdminIds?.length ? AdminVisibilityType.SpecificAdmins : AdminVisibilityType.AllAdmins,
  );

  const handleAdminVisibilityChange = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    if (value === AdminVisibilityType.AllAdmins) {
      onSelectedAdminsChange([]);
    }
    setAdminVisibility(value as AdminVisibilityType);
  };

  const hookProps = {
    adminList,
    adminVisibility,
    handleAdminVisibilityChange,
    isMobile,
    isLoading,
    initialSelectedAdmins,
    onSelectedAdminsChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default AdminVisibility;
