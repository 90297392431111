import { css } from '@emotion/react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { UseSingleRteReturn } from '~Common/hooks/useSingleRte';
import { FrontendRankedQuestion } from '~Learning/const/interfaces';
import QuestionCard from '~Common/V3/components/QuestionItem/QuestionCard';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import QuestionInput from './QuestionInput';

const styles = {
  buttonsContainer: css({
    margin: '0.5rem 0',
  }),
  cancelAddQuestionButton: css({
    marginLeft: '.625rem',
  }),
};

interface QuestionItemProps extends Omit<UseSingleRteReturn, 'editorText' | 'hasOpenEditor'> {
  question: FrontendRankedQuestion,
  dragHandleProps: DraggableProvidedDragHandleProps,
  saveQuestionEdit: (questionRank: number) => void,
  onDeleteQuestion: (questionRank: number, questionId: string | undefined) => void,
}

const QuestionItem = ({
  dragHandleProps,
  question,
  onEditorTextChange,
  initializeEditor,
  saveQuestionEdit,
  onEditorClose,
  shouldShowEditor,
  onDeleteQuestion,
}: QuestionItemProps): JSX.Element => (
  <>
    {shouldShowEditor(question.rank.toString()) && (
      <QuestionInput
        initialValue={question.text}
        onChange={(value) => onEditorTextChange(value)}
        renderButtons={() => (
          <div css={styles.buttonsContainer}>
            <LeadrButton
              size="small"
              onClick={() => saveQuestionEdit(question.rank)}
              data-test-id="learningQuestionSave"
            >
              Save
            </LeadrButton>
            <LeadrButton
              size="small"
              variant="ghost"
              css={styles.cancelAddQuestionButton}
              onClick={onEditorClose}
              data-test-id="learningQuestionCancel"
            >
              Cancel
            </LeadrButton>
          </div>
        )}
      />
    )}
    {!shouldShowEditor(question.rank.toString()) && (
      <QuestionCard
        renderText={(questionTextStyles) => (
          <HTMLRenderer css={questionTextStyles} htmlText={question.text} />
        )}
        handleEditClick={() => initializeEditor({ editorId: question.rank.toString(), initialValue: question.text })}
        handleDeleteClick={() => onDeleteQuestion(question.rank, question.questionId)}
        dragHandleProps={dragHandleProps}
      />
    )}
  </>
);

export default QuestionItem;
