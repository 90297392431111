import { useSelector, useDispatch } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import {
  getOrganizationId,
  getOrganizationName,
  getOrganizationUserId,
  getUserId,
} from '~Common/utils/localStorage';
import {
  setFlagsAction,
} from '~Deprecated/actions/featureFlags/setFlags';
import { getIsOverriding } from '~Deprecated/selectors/featureFlags/getIsOverriding';
import LaunchDarkly from '~Deprecated/ui/lib/LaunchDarkly';
import { setIdleTimeoutAction } from '~Deprecated/actions/idle/setIdleTimeout';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';

const LaunchDarklyContainer = (props) => {
  const dispatch = useDispatch();
  const userId = getUserId();
  const { user } = useUserProfile(userId);

  const userDetail = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
  };

  const orgId = getOrganizationId();
  const orgName = getOrganizationName();
  const orgUserId = getOrganizationUserId();
  const isOverriding = useSelector((state) => getIsOverriding(state));

  const setFlags = (action) => {
    dispatch(setFlagsAction(action));
  };

  const onFlagChange = (flags) => {
    dispatch(setIdleTimeoutAction({
      timeout: flags.frontendPollingIdleTimeout || (20 * 60 * 1000),
    }));
  };

  return (
    <LaunchDarkly
      orgUserId={orgUserId}
      userDetail={userDetail}
      userId={userId}
      orgId={orgId}
      orgName={orgName}
      isOverriding={isOverriding}
      setFlagsAction={setFlags}
      onFlagChange={onFlagChange}
      {...props}
    />
  );
};

export default withLDConsumer()(LaunchDarklyContainer);
