import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

// eslint-disable-next-line no-underscore-dangle
const _leadrPubSub = new Subject();

// The Main Subject/Stream to be listened on.
export const leadrPubSub = {
  publish: ({ type, data, ...others }) => {
    if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean' || typeof data === 'bigint') {
      _leadrPubSub.next({ type, data });
    } else {
      _leadrPubSub.next({ type, data: { ...data, ...others } });
    }
  },
  subject: (topic) => _leadrPubSub.asObservable().pipe(filter((e) => e.type === topic)),
};
