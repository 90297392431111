import { useEffect } from 'react';
import { createQueryKey, setLocalStore } from '~Common/hooks/useGenericStore';
import { AgendaType } from '@leadr-hr/types';
import { useShowAgendaSection } from '~Meetings/components/topic-suggestions/stores/useShowAgendaSection';
import { AgendaItem } from './v2/useGetAgendas';

interface UseShowCommentsParams {
  agendas: AgendaItem[],
  isShowingAllCommentSections: boolean,
  isAgendaLoading: boolean,
}

/**
 * @description
 * When changed from false to true:
 *  - Opens all agenda sections that are currently collapsed
 *  - Opens all comment sections that are currently collapsed
 * @description
 * When changed from true to false:
 *  - Closes all comments sections that are currently expanded
 *  - Does NOT close agenda sections that are currently expanded
*/
export const useShowComments = ({
  agendas,
  isShowingAllCommentSections,
  isAgendaLoading,
}: UseShowCommentsParams): void => {
  const expandAgendaSection = useShowAgendaSection((state) => state.expandAgendaSection);

  // TODO: Move this over to Zustand and clean up when this is run if time allows
  // Open or close all comment sections based on isShowingAllCommentSections
  useEffect(() => {
    if (agendas && Array.isArray(agendas)) {
      agendas.forEach((agendaItem) => {
        if (agendaItem.type === AgendaType.AgendaSection) {
          // We only want this to open the agenda sections if they are collapsed, we don't want it to close agenda sections for hide all comments
          if (isShowingAllCommentSections) {
            expandAgendaSection(agendaItem.id);
          }

          // Traverse through the children of the agenda section and create a query key for each agenda topic
          agendaItem.children.forEach((agendaTopic) => {
            const topicKey = createQueryKey({
              module: 'leadr',
              workflow: 'meetings',
              feature: `comment-section-${agendaTopic.id}`,
            });

            setLocalStore({ key: topicKey, value: isShowingAllCommentSections });
          });
        }

        if (agendaItem.type === AgendaType.AgendaTopic) {
          const key = createQueryKey({
            module: 'leadr',
            workflow: 'meetings',
            feature: `comment-section-${agendaItem.id}`,
          });

          setLocalStore({ key, value: isShowingAllCommentSections });
        }
      });
    }
    // children not included in deps to prevent comment sections from randomly closing on comment create/delete
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgendaLoading, isShowingAllCommentSections]);
};
