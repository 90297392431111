import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { HTMLString } from '~Common/types';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  textStyle: css({
    fontSize: '1rem',
    lineHeight: '1.125rem',
    color: palette.neutrals.gray500,
  }),
};

interface DrawerInstructionsProps {
  text?: string,
  html?: HTMLString,
}

const DrawerInstructions = ({ text, html, ...props }: DrawerInstructionsProps): JSX.Element => {
  if (html) {
    return (
      <HTMLRenderer htmlText={html} css={styles.textStyle} {...props} />
    );
  }
  return (
    <div
      css={styles.textStyle}
      {...props}
    >
      {text}
    </div>
  );
};

export default DrawerInstructions;
