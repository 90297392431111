import { css, SerializedStyles } from '@emotion/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'react';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import { stripHtml } from '~Common/utils/stripHtml';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import FloatingActionMenu from '~Common/components/FloatingActionMenu';
import { HoverState, useHoverState } from '~Common/hooks/useHoverState';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import { AssignedReflection } from '../Const/types';
import { ReflectionTypeEnum } from './types';
import { useRemoveReflection } from './hooks/useRemoveReflection';
import { useReflectionDetailsDrawer } from './hooks/useReflectionDetailsDrawer';
import { useGetReviewById } from '../Hooks/useGetReviewById';
import { useGetRevieweePersonInformation } from '../Hooks/useGetRevieweePersonInformation';
import { getReflectionIcon } from './const/functions';

function getReflectionType(type: ReflectionTypeEnum): string {
  switch (type) {
    case ReflectionTypeEnum.Accomplishment:
      return 'Reflection - Accomplishment';
    case ReflectionTypeEnum.BookmarkedTopic:
      return 'Reflection - Agenda Topic';
    case ReflectionTypeEnum.Feedback:
      return 'Reflection - Feedback';
    case ReflectionTypeEnum.Goal:
      return 'Reflection - Goal';
    case ReflectionTypeEnum.Learning:
      return 'Reflection - Learning';
    default:
      return 'Reflection';
  }
}

const styles = {
  button: (isLoadingRevieweeData: boolean) => css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    display: 'flex',
    gap: '0.75rem',
    padding: '0.75rem 1rem',
    width: '100%',
    position: 'relative',
  }, isLoadingRevieweeData && ({
    cursor: 'wait',
  })),
  details: css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }),
  icon: css({
    color: palette.brand.indigo,
    height: '1.75rem',
  }),
  reflectionType: css({
    color: palette.neutrals.gray500,
    fontSize: '0.8125rem',
    fontWeight: 600,
    textAlign: 'start',
  }),
  reflectionTitle: css({
    color: palette.neutrals.gray800,
    textAlign: 'start',
  }, withTruncate()),
  floatingActionMenu: css({
    right: '1rem',
  }),
};

interface ViewProps extends HoverState {
  addedStyles?: SerializedStyles,
  icon: IconDefinition,
  openDetailsDrawer: MouseEventHandler,
  reflectionType: string,
  title: string,
  handleRemoveReflection: () => void,
  isLoadingRevieweeData: boolean,
  showHoverMenu: boolean,
}

export const View = ({
  addedStyles,
  icon,
  openDetailsDrawer,
  reflectionType,
  title,
  isHovering,
  handleMouseEnter,
  handleMouseLeave,
  handleRemoveReflection,
  isLoadingRevieweeData,
  showHoverMenu,
}: ViewProps): JSX.Element => (
  <LeadrButton
    variant="text"
    css={[styles.button(isLoadingRevieweeData), addedStyles]}
    onClick={isLoadingRevieweeData ? undefined : openDetailsDrawer}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    data-test-id="reviewsDisplayReflection"
  >
    <FontAwesomeIcon icon={icon} css={styles.icon} />
    <div css={styles.details}>
      <p css={styles.reflectionType}>
        {reflectionType}
      </p>
      <p css={styles.reflectionTitle}>
        {title}
      </p>
    </div>
    {isHovering && showHoverMenu && (
      <FloatingActionMenu
        css={styles.floatingActionMenu}
      >
        <DeleteButtonWithConfirmation
          renderDeleteButton={({ onClick }) => (
            <FloatingActionMenu.TooltipButton
              tooltipContent="Remove Reflection"
              data-test-id="reviewsRemoveReflection"
              onClick={onClick}
            >
              <FontAwesomeIcon
                icon={faTrash}
              />
            </FloatingActionMenu.TooltipButton>
          )}
          renderConfirmationButtons={({
            informationStyles,
            optionStyles,
            popoverButtonStyles,
          }) => (
            <DeleteConfirmationButtons
              onDelete={handleRemoveReflection}
              informationStyles={informationStyles}
              optionStyles={optionStyles}
              popoverButtonStyles={popoverButtonStyles}
            />
          )}
        />
      </FloatingActionMenu>
    )}
  </LeadrButton>
);

interface DisplayReflectionProps {
  addedStyles?: SerializedStyles,
  reflection: AssignedReflection,
  reviewUid: string,
  showHoverMenu?: boolean,
}

export const DisplayReflection = ({
  addedStyles,
  reflection,
  reviewUid,
  showHoverMenu = true,
  ...props
}: DisplayReflectionProps): JSX.Element => {
  const { data } = useGetReviewById({
    id: reviewUid,
  });
  const review = data?.response;
  const { openDrawer: openReflectionDetailsDrawer } = useReflectionDetailsDrawer();
  const { mutate: removeReflectionMutation } = useRemoveReflection({ reviewUid });
  const { reflectionTypeEnum, subjectUid, title: dirtyTitle } = reflection;
  const title = dirtyTitle ? stripHtml(dirtyTitle) : 'Click to see details';
  const {
    isHovering,
    handleMouseEnter,
    handleMouseLeave,
  } = useHoverState();

  const handleRemoveReflection = (): void => {
    removeReflectionMutation({
      reviewUid,
      reviewTopicUid: reflection.topicUid,
      subjectUid: reflection.subjectUid,
    });
  };

  const { person: reviewee, isLoading: isLoadingRevieweeData } = useGetRevieweePersonInformation(review);

  const openDetailsDrawer = (): void => {
    openReflectionDetailsDrawer({
      reflectionTypeEnum,
      reviewUid,
      subjectUid,
      revieweeId: reviewee?.userId ?? '',
      revieweeOrgID: reviewee?.orgUserId ?? '',
      reflectionId: reflection.subjectUid,
    });
  };
  const reflectionType = getReflectionType(reflectionTypeEnum);
  const icon = getReflectionIcon(reflectionTypeEnum);

  const hookProps = {
    addedStyles,
    icon,
    openDetailsDrawer,
    reflectionType,
    title,
    isHovering,
    handleMouseEnter,
    handleMouseLeave,
    handleRemoveReflection,
    isLoadingRevieweeData,
    showHoverMenu,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
