import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  FormEvent,
  Fragment,
  useMemo,
  useState,
  useCallback,
  ChangeEvent,
  useEffect,
  KeyboardEvent,
} from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { useLocation } from 'react-router-dom';
import { COACHING_ROUTE } from '~Common/const/routes';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerInput from '~Common/V3/components/DrawerInput';
import { withSpacedOutTitleStyles } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';
import { toast } from '~Common/components/Toasts';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useCreateMeetingTemplate } from '~Meetings/hooks/templates/useCreateMeetingTemplate';
import { AgendaTemplates, AgendaType } from '@leadr-hr/types';
import { useDeleteMeetingTemplate } from '~Meetings/hooks/templates/useDeleteMeetingTemplate';
import { useEditMeetingTemplate } from '~Meetings/hooks/templates/useEditMeetingTemplate';
import AddAgendaButton from '~Meetings/components/details/AddAgendaButton';
import { getLocalId } from '~Common/utils/uuid';
import { EditTemplateItemParams, TemplateItemActionsContext } from '~Meetings/components/topic-suggestions/contexts/TemplateItemsActionContext';
import { MoveTemplateAgendaItemContext } from '~Meetings/components/topic-suggestions/contexts/MoveTemplateAgendaItemContext';
import {
  DragDropContext,
  DragStart,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useUpdateEffect } from '~Common/hooks/useUpdateEffect';
import { MoveTemplateAgendaItemOnePositionParams, useMoveTemplateAgendaItem } from '~Meetings/hooks/utils/useMoveTemplateAgendaItem';
import { useTemplateItemLocalIds } from '~Meetings/hooks/utils/useTemplateItemLocalIds';
import DrawerCloseButtonWithConfirmation from '~Learning/components/Shared/ConfirmationButtons/DrawerCloseButtonWithConfirmation';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import {
  EditAgendaTemplateDetails,
  CreateAgendaTemplateDetails,
  CreateAgendaSectionTemplateDetails,
  AgendaTemplateType,
  CreateAgendaTopicTemplateDetailsWithLocalID,
  EditAgendaTopicTemplateDetailsWithLocalID,
  CreateEditTemplateTopic,
} from '~Meetings/components/topic-suggestions/const/types';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import InfoCircleTooltip from '~Common/components/InfoCircleTooltip';
import AgendaSectionTitleEditor from './AgendaSection/AgendaSectionTitleEditor';
import AgendaTopicTextEditor from './AgendaTopic/AgendaTopicTextEditor';
import SaveButton from './SaveButton';
import CancelButton from './CancelButton';
import DeleteButton from './DeleteButton';
import DraggableAgendaTopic from './AgendaTopic/DraggableAgendaTopic';
import DNDAgendaSection from './AgendaSection/DNDAgendaSection';
import { useShowAgendaSection } from '../stores/useShowAgendaSection';

const styles = {
  drawerBody: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    paddingBottom: '1.875rem',
  }),
  topicHeader: css({
    fontSize: '.625rem',
    margin: '.625rem 0',
  }, withSpacedOutTitleStyles(palette.neutrals.gray500)),
  topicsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.125rem',
    marginBottom: '1.125rem',
  }),
  footerButtons: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginTop: '1.5rem',
  }),
  deleteButton: css({
    marginLeft: 'auto',
  }),
  checkboxContainer: css({
    marginTop: '.75rem',
    display: 'flex',
    alignItems: 'center',
  }),
  checkbox: css({
    marginLeft: '-.5rem',
  }),
  infoTooltip: css({
    display: 'inline-block',
    marginLeft: '.5rem',
  }),
};

function countAgendaTopics(templateItems: CreateEditTemplateTopic[]): number {
  let count = 0;

  function recursiveCount(items: CreateEditTemplateTopic[]): void {
    items.forEach((item) => {
      if (item.type === AgendaTemplates.AgendaTemplateType.AgendaTopic) {
        count += 1;
      }
      if (item.type === AgendaTemplates.AgendaTemplateType.AgendaSection && item.children) {
        recursiveCount(item.children);
      }
    });
  }

  recursiveCount(templateItems);
  return count;
}

export const createTopicTemplateDrawerTemplate = {
  name: 'CREATE_TOPIC_TEMPLATE_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface TopicSuggestionsDrawerProps extends DrawerProps<Record<string, unknown>> {
  initialTitle?: string,
  initialTopics?: EditAgendaTemplateDetails[],
  initialIsManager?: boolean,
  creatorName?: string,
  isEditMode?: boolean,
  templateId: string,
  openTopicSuggestionsDrawer: () => void,
  maxTopics: number
  maxSections: number,
  isPrivate: boolean,
}

const TopicSuggestionsDrawer = ({
  initialTitle = '',
  initialTopics = [],
  templateId = '',
  isEditMode = false,
  initialIsManager = false,
  creatorName,
  openTopicSuggestionsDrawer,
  popDrawer,
  setDrawerState,
  maxTopics,
  maxSections,
  isPrivate,
}: TopicSuggestionsDrawerProps): JSX.Element => {
  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      shouldConfirmDrawerClose: true,
    }));
  }, [setDrawerState]);

  const location = useLocation();

  const {
    getIsItemEditorVisible,
    showItemEditor,
    removeItemEditor,
  } = useShowItemEditor((state) => ({
    getIsItemEditorVisible: state.getIsItemEditorVisible,
    showItemEditor: state.showItemEditor,
    removeItemEditor: state.removeItemEditor,
  }));

  const removeAgendaSection = useShowAgendaSection((state) => state.removeAgendaSection);

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: createTopicTemplateDrawerTemplate.name });
    setDrawerState((prev) => ({
      ...prev,
      shouldConfirmDrawerClose: false,
    }));
    openTopicSuggestionsDrawer();
  };

  const isMobile = useIsMobileQuery();
  const [itemBeingDragged, setItemBeingDragged] = useState<CreateEditTemplateTopic>();

  const {
    fillLocalIds,
    removeLocalIdsAndPermissions,
  } = useTemplateItemLocalIds();

  const [templateItems, setTemplateItems] = useState<CreateEditTemplateTopic[]>(() => fillLocalIds(initialTopics));

  const createTemplateItem = useCallback((agendaTemplateType: AgendaTemplateType, text: string): void => {
    if (agendaTemplateType === AgendaTemplates.AgendaTemplateType.AgendaSection) {
      setTemplateItems((prevTemplateItems) => [
        ...prevTemplateItems,
        {
          text,
          type: AgendaTemplates.AgendaTemplateType.AgendaSection,
          children: [],
          localId: getLocalId(),
        },
      ]);
    } else if (agendaTemplateType === AgendaTemplates.AgendaTemplateType.AgendaTopic) {
      setTemplateItems((prevTemplateItems) => [
        ...prevTemplateItems,
        {
          text,
          type: AgendaTemplates.AgendaTemplateType.AgendaTopic,
          localId: getLocalId(),
        },
      ]);
    }
  }, []);

  const deleteTemplateItem = useCallback((localId: string, sectionLocalId?: string): void => {
    setTemplateItems((prevTemplateItems) => {
      if (sectionLocalId) {
        return prevTemplateItems.map((templateItem) => {
          if (templateItem.localId === sectionLocalId && templateItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
            return {
              ...templateItem,
              children: templateItem.children.filter((child) => child.localId !== localId),
            };
          }
          return templateItem;
        });
      }

      return prevTemplateItems.filter((templateItem) => templateItem.localId !== localId);
    });

    // Removing the localId from the stores
    removeItemEditor(localId);
    removeAgendaSection(localId);
  }, [removeAgendaSection, removeItemEditor]);

  const editTemplateItem = useCallback(({
    localId,
    item,
    sectionLocalId,
  }: EditTemplateItemParams): void => {
    setTemplateItems((prevTemplateItems) => prevTemplateItems.map((templateItem) => {
      if (sectionLocalId && templateItem.localId === sectionLocalId && templateItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
        return {
          ...templateItem,
          children: templateItem.children.map(
            (child) => (child.localId === localId ? { ...child, ...item } : child),
          ) as CreateAgendaTopicTemplateDetailsWithLocalID[] | EditAgendaTopicTemplateDetailsWithLocalID[],
        };
      }

      if (!sectionLocalId && templateItem.localId === localId) {
        return { ...templateItem, ...item };
      }

      return templateItem;
    }));
  }, []);

  const addTopicToSection = useCallback((sectionLocalId: string, text: string): void => {
    setTemplateItems((prevTemplateItems) => prevTemplateItems.map((templateItem) => {
      if (templateItem.localId === sectionLocalId && templateItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
        return {
          ...templateItem,
          children: [
            ...templateItem.children,
            {
              text,
              type: AgendaTemplates.AgendaTemplateType.AgendaTopic,
              localId: getLocalId(),
            },
          ],
        };
      }
      return templateItem;
    }));
  }, []);

  const ungroupSection = useCallback((sectionLocalId: string): void => {
    setTemplateItems((prevTemplateItems) => {
      const sectionToUngroup = prevTemplateItems.find((templateItem) => templateItem.localId === sectionLocalId);
      if (sectionToUngroup && sectionToUngroup.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
        return [
          ...prevTemplateItems.filter((templateItem) => templateItem.localId !== sectionLocalId),
          ...sectionToUngroup.children,
        ];
      }
      return prevTemplateItems;
    });
  }, []);

  const templateItemsActionsContext = useMemo(
    () => ({
      createTemplateItem,
      deleteTemplateItem,
      editTemplateItem,
      addTopicToSection,
      ungroupSection,
    }),
    [createTemplateItem, deleteTemplateItem, editTemplateItem, addTopicToSection, ungroupSection],
  );

  const {
    disableMoveUp,
    disableMoveDown,
    moveTemplateAgendaItemOnePosition: bareMoveTemplateAgendaItemOnePosition,
  } = useMoveTemplateAgendaItem(templateItems);

  const moveTemplateAgendaItemOnePosition = useCallback((params: MoveTemplateAgendaItemOnePositionParams) => {
    const newTemplateItems = bareMoveTemplateAgendaItemOnePosition(params);
    setTemplateItems(newTemplateItems);
  }, [bareMoveTemplateAgendaItemOnePosition]);

  const moveTemplateAgendaItemContext = useMemo(
    () => ({
      disableMoveUp,
      disableMoveDown,
      moveTemplateAgendaItemOnePosition,
    }),
    [disableMoveUp, disableMoveDown, moveTemplateAgendaItemOnePosition],
  );

  const meetingType = useMemo(() => {
    if (location.pathname === COACHING_ROUTE) {
      return AgendaTemplates.MeetingTemplateType.Coaching;
    }

    return AgendaTemplates.MeetingTemplateType.TeamMeeting;
  }, [location.pathname]);

  const { mutate: createMeetingTemplateMutation } = useCreateMeetingTemplate({
    onMutateCallback: () => {
      closeDrawerClick();
    },
    meetingTemplateType: meetingType,
    templateVisibility: isPrivate ? 'private' : 'public',
  });

  const { mutate: deleteMeetingTemplateMutation } = useDeleteMeetingTemplate({
    meetingTemplateType: meetingType,
    onMutateCallback: () => {
      closeDrawerClick();
    },
  });

  const { mutate: editMeetingTemplateMutation } = useEditMeetingTemplate({
    meetingTemplateType: meetingType,
    templateVisibility: isPrivate ? 'private' : 'public',
    onMutateCallback: () => {
      closeDrawerClick();
    },
    openTopicSuggestionsDrawer,
    maxTopics,
    maxSections,
  });

  const sectionLocalId = useMemo(() => getLocalId(), []);
  const newTopicLocalId = useMemo(() => getLocalId(), []);

  const showNewAgendaSectionEditor = getIsItemEditorVisible(sectionLocalId);
  const showNewAgendaTopicEditor = getIsItemEditorVisible(newTopicLocalId);

  const handleAddTemplateChild = useCallback((agendaType: AgendaType): void => {
    if (agendaType === AgendaType.AgendaSection) {
      showItemEditor(sectionLocalId);
    }

    if (agendaType === AgendaType.AgendaTopic) {
      showItemEditor(newTopicLocalId);
    }
  }, [showItemEditor, sectionLocalId, newTopicLocalId]);

  const handleDeleteTemplate = (): void => {
    deleteMeetingTemplateMutation({ id: templateId });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (templateItems.length === 0) {
      toast.error('Please add at least one topic to your template.');
      showItemEditor(newTopicLocalId);
      return;
    }

    const formData = new FormData(event.currentTarget);
    const meetingTemplateTitle = formData.get('meetingTemplateTitle') as string;

    const templateItemsWithoutLocalIds = removeLocalIdsAndPermissions(templateItems);

    if (isEditMode) {
      editMeetingTemplateMutation({
        id: templateId,
        text: meetingTemplateTitle,
        isPrivate: !isPrivate ? isPrivateFlag : true,
        isManagerOnly: isManagerOnlyFlag,
        children: templateItemsWithoutLocalIds as EditAgendaTemplateDetails[],
      });
    } else {
      createMeetingTemplateMutation({
        type: meetingType,
        text: meetingTemplateTitle,
        isPrivate,
        isManagerOnly: isManagerOnlyFlag,
        children: templateItemsWithoutLocalIds as CreateAgendaTemplateDetails[],
      });
    }
  };

  const handleDragStart = (initial: DragStart): void => {
    // If the item being moved is in an agenda section
    if (initial.source.droppableId.includes('agendaSection')) {
      const sourceDroppableId = initial.source.droppableId.replace('agendaSectionDroppable', '');
      const sourceAgendaSection = templateItems.find((agendaSection) => agendaSection.localId === sourceDroppableId);
      if (sourceAgendaSection && sourceAgendaSection.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
        const tempItemBeingDragged = sourceAgendaSection.children?.[initial.source.index];
        setItemBeingDragged(tempItemBeingDragged);
      }
    } else { // If the item being moved is on the top level
      const tempItemBeingDragged = templateItems[initial.source.index];
      setItemBeingDragged(tempItemBeingDragged);
    }
  };

  const handleDragEnd = (result: DropResult): void => {
    // Reset the item being dragged type
    const tempItemBeingDragged = itemBeingDragged;
    setItemBeingDragged(undefined);

    const { destination, source } = result;

    // Item was dragged to a nondropable area on the screen (not in the <Droppable /> component)
    if (!destination) {
      return;
    }

    // Item was not moved
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // We didn't properly store the item being dragged
    if (!tempItemBeingDragged) {
      // eslint-disable-next-line no-console
      console.error('Item being dragged was not properly stored');
      return;
    }

    if (destination.droppableId === 'agenda') {
      // Moving an item to the top level
      if (source.droppableId === 'agenda') {
        // The item is already in the top level
        setTemplateItems((prevTemplateItems) => {
          const newTemplateItems = [...prevTemplateItems];
          newTemplateItems.splice(source.index, 1);
          newTemplateItems.splice(destination.index, 0, tempItemBeingDragged);
          return newTemplateItems;
        });
      } else {
        // The item is in an agenda section
        setTemplateItems((prevTemplateItems) => {
          const newTemplateItems = [...prevTemplateItems];
          const sourceDroppableId = source.droppableId.replace('agendaSectionDroppable', '');
          // eslint-disable-next-line max-len
          const sourceAgendaSection = newTemplateItems.find((agendaSection) => agendaSection.localId === sourceDroppableId);
          if (sourceAgendaSection && sourceAgendaSection.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
            sourceAgendaSection.children.splice(source.index, 1);
            newTemplateItems.splice(destination.index, 0, tempItemBeingDragged);
          }
          return newTemplateItems;
        });
      }
    } else if (destination.droppableId.includes('agendaSection')) {
      // Moving an item to an agenda section
      if (source.droppableId === 'agenda') {
        // Moving an item from the top level
        setTemplateItems((prevTemplateItems) => {
          const newTemplateItems = [...prevTemplateItems];
          const destinationDroppableId = destination.droppableId.replace('agendaSectionDroppable', '');
          newTemplateItems.splice(source.index, 1);
          // eslint-disable-next-line max-len
          const destinationAgendaSection = newTemplateItems.find((agendaSection) => agendaSection.localId === destinationDroppableId);
          if (destinationAgendaSection && destinationAgendaSection.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
            destinationAgendaSection.children.splice(
              destination.index,
              0,
              tempItemBeingDragged as CreateAgendaTopicTemplateDetailsWithLocalID | EditAgendaTopicTemplateDetailsWithLocalID,
            );
          }
          return newTemplateItems;
        });
      } else {
        // Moving an item from an agenda section
        setTemplateItems((prevTemplateItems) => {
          const newTemplateItems = [...prevTemplateItems];
          const sourceDroppableId = source.droppableId.replace('agendaSectionDroppable', '');
          const destinationDroppableId = destination.droppableId.replace('agendaSectionDroppable', '');
          // eslint-disable-next-line max-len
          const sourceAgendaSection = newTemplateItems.find((agendaSection) => agendaSection.localId === sourceDroppableId) as CreateAgendaSectionTemplateDetails;
          // eslint-disable-next-line max-len
          const destinationAgendaSection = newTemplateItems.find((agendaSection) => agendaSection.localId === destinationDroppableId) as CreateAgendaSectionTemplateDetails;
          if (sourceAgendaSection && destinationAgendaSection) {
            const movedAgendaItem = sourceAgendaSection.children.splice(source.index, 1);
            destinationAgendaSection.children.splice(destination.index, 0, ...movedAgendaItem);
          }
          return newTemplateItems;
        });
      }
    }
  };

  /*
    React-beautiful-DND has a bug with adding new droppable regions in a nested dropable region
    The library can't detect the new droppable region and won't allow you to drop items into it
    Theres a workaround found here: https://github.com/atlassian/react-beautiful-dnd/issues/860#issuecomment-432623681
    Where you can add a key to the top level droppable region and then update that when a new region is added
    However, we only want to update that after the API call has returned with the new ID, so that the proper ID is on the droppable region
    The below code shenanigans covers that
  */
  const [droppableHackKey, setDroppableHackKey] = useState('');

  // Then a use effect down here
  useUpdateEffect(() => {
    const tempDroppableHackKey = templateItems
      .filter((agendaItem) => agendaItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection)
      .reduce((acc, curr) => `${acc}${curr.localId}`, '');

    // Only update the key if the length is longer (a new agenda section has been added)
    if (tempDroppableHackKey.length > droppableHackKey.length) {
      // Set the useState for the key
      setDroppableHackKey(tempDroppableHackKey);
    }
  }, [templateItems, droppableHackKey]);

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
    }
  }, []);

  const showAddAgendaButton = !showNewAgendaSectionEditor
    && !showNewAgendaTopicEditor
    && !itemBeingDragged;
  const showManagerOnlyCheckbox = !isPrivate && !itemBeingDragged;
  const showPrivateCheckbox = !isPrivate && isEditMode && !itemBeingDragged;

  const hasMaxSections = templateItems.filter((templateItem) => templateItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection).length >= maxSections;

  const hasMaxTopics = countAgendaTopics(templateItems) > maxTopics;

  const showEmptyState = templateItems.length === 0 && !showNewAgendaSectionEditor && !showNewAgendaTopicEditor;
  const showDeleteButton = isEditMode;

  const [isManagerOnlyFlag, setIsManagerOnlyFlag] = useState(initialIsManager);
  const handleManagerOnlyChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsManagerOnlyFlag(event.target.checked);
  };

  const [isPrivateFlag, setisPrivateFlag] = useState(false);
  const handlePrivateChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setisPrivateFlag(event.target.checked);
  };
  const privateInfoStr = `If checked, this template will be visible only to ${creatorName || 'the creator'}
    in their “My Topics” section, but hidden to the rest of the organization.`;

  const drawerTitle = isEditMode ? 'Edit Agenda Topic Template' : 'New Agenda Topic Template';

  const hookProps = {
    onSubmit: handleSubmit,
    renderHeader: () => (
      <DrawerHeader
        title={drawerTitle}
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <DrawerCloseButtonWithConfirmation
            renderDrawerCloseButton={({ onClick }) => (
              <IconButton
                onClick={onClick}
                type="button"
                icon={faTimes}
                css={closeButtonStyles}
                tooltip="Close"
                size="large"
              />
            )}
            onCloseDrawer={closeDrawerClick}
            shouldConfirmClose
          />
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={[defaultBodyPadding, styles.drawerBody]}>
        <div>
          <DrawerInput
            label="Template Title"
            name="meetingTemplateTitle"
            required
            initialValue={initialTitle}
            onKeyDown={handleKeyPress}
          />
          <div css={styles.topicHeader}>
            {`Topics ${templateItems.length > 0 ? `(${templateItems.length})` : ''} - DRAG TO REORDER`}
          </div>
          <div>
            {showEmptyState && (
              <div>Select &quot;+ Add Topic&quot; to start creating your template.</div>
            )}
            <TemplateItemActionsContext.Provider value={templateItemsActionsContext}>
              <MoveTemplateAgendaItemContext.Provider value={moveTemplateAgendaItemContext}>
                <DragDropContext
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                >
                  <Droppable key={droppableHackKey} droppableId="agenda">
                    {(agendaDropProvided) => (
                      <div
                        css={styles.topicsContainer}
                        ref={agendaDropProvided.innerRef}
                        {...agendaDropProvided.droppableProps}
                      >
                        {templateItems.map((templateItem, index) => (
                          <Fragment key={templateItem.localId}>
                            {templateItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection && (
                              <DNDAgendaSection
                                index={index}
                                itemBeingDragged={itemBeingDragged}
                                agendaSection={templateItem}
                                sectionLocalId={templateItem.localId}
                                hasMaxTopics={hasMaxTopics}
                              />
                            )}
                            {templateItem.type === AgendaTemplates.AgendaTemplateType.AgendaTopic && (
                              <DraggableAgendaTopic
                                index={index}
                                agendaTopic={templateItem}
                                topicLocalId={templateItem.localId}
                              />
                            )}
                          </Fragment>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </MoveTemplateAgendaItemContext.Provider>
            </TemplateItemActionsContext.Provider>
          </div>
          {showNewAgendaSectionEditor && (
            <AgendaSectionTitleEditor
              sectionLocalId={sectionLocalId}
              onSave={(sectionTitle) => createTemplateItem(AgendaTemplates.AgendaTemplateType.AgendaSection, sectionTitle)}
            />
          )}
          {showNewAgendaTopicEditor && (
            <AgendaTopicTextEditor
              topicLocalId={newTopicLocalId}
              onSave={(topicText) => createTemplateItem(AgendaTemplates.AgendaTemplateType.AgendaTopic, topicText)}
            />
          )}
          {showAddAgendaButton && (
            <AddAgendaButton
              maxTopics={maxTopics}
              maxSections={maxSections}
              hasMaxSections={hasMaxSections}
              hasMaxTopics={hasMaxTopics}
              onClick={handleAddTemplateChild}
            />
          )}
          {showManagerOnlyCheckbox && (
            <div css={styles.checkboxContainer}>
              <CustomCheckbox
                css={styles.checkbox}
                id="isManagerCheckbox"
                value="isManagerCheckbox"
                checked={isManagerOnlyFlag}
                onChange={handleManagerOnlyChange}
              />
              Manager Template
              <InfoCircleTooltip
                css={styles.infoTooltip}
                content="This template will only be visible and accessible to managers."
              />
            </div>
          )}
          {showPrivateCheckbox && (
            <div css={styles.checkboxContainer}>
              <CustomCheckbox
                css={styles.checkbox}
                id="isPrivateCheckbox"
                value="isPrivateCheckbox"
                checked={isPrivateFlag}
                onChange={handlePrivateChange}
              />
              Mark as Private
              <InfoCircleTooltip
                css={styles.infoTooltip}
                content={privateInfoStr}
              />
            </div>
          )}
        </div>
        <div css={styles.footerButtons}>
          <SaveButton isPrivate={isPrivate} isEdit={isEditMode} isMobile={isMobile} />
          <DrawerCloseButtonWithConfirmation
            renderDrawerCloseButton={({ onClick }) => (
              <CancelButton onClick={onClick} isEdit={isEditMode} isMobile={isMobile} />
            )}
            onCloseDrawer={closeDrawerClick}
            shouldConfirmClose
          />
          {showDeleteButton && (
            <DeleteButton css={styles.deleteButton} onDelete={handleDeleteTemplate} isMobile={isMobile} />
          )}
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: createTopicTemplateDrawerTemplate.name,
  component: TopicSuggestionsDrawer,
});

export default TopicSuggestionsDrawer;
