import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { anime } from 'react-anime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faBox,
  faCopy,
  faEdit,
  faFilePlus,
  faFlag,
  faArrowRightToBracket,
  faEllipsisVertical,
  faSend,
  faGear,
  faTrash,
  faUser,
  faUserCheck,
  faUserTimes,
  faPlus,
  faUserXmark,
} from '@fortawesome/pro-light-svg-icons';

import { Portal } from 'react-portal';

import Checkbox from '../../Checkbox';
import { startParallelPollingAction, stopParallelPollingAction } from '../../../redux/actions/pollingActions';
import { getMeetingById } from '../../../redux/sagas/meetingSaga';
import { parseQueryString } from '~Deprecated/utils';
import { COACHING_ROUTE, MEETING_ROUTE } from '~Common/const/routes';
import '../styles.scss';
import { reviewBookmarkMenuItems } from '../../Meetings/CreateAgenda/AgendaListItem/config';

/**
 * @deprecated Old Meetings UI
 */
const AgendaActionMenu = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const wrapperRef = useRef(null);
  const childRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  let param; // Param consist of coaching/meeeting ID for coaching/meeting polling tasks
  const {
    coachingId, meetingId, feedbackId, contentId,
  } = parseQueryString(
    history.location.search,
  );

  if (history.location.pathname === COACHING_ROUTE) {
    param = { history, data: coachingId, contextType: 'COACHING' };
  } else if (history.location.pathname === MEETING_ROUTE) {
    param = { history, data: meetingId, contextType: 'TEAM_MEETING' };
  }

  const handleClickOutside = () => {
    // if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //     setShowMenu(false);
    // }
  };

  const handleClick = () => {
    setShowMenu(!showMenu);
    // Pretty confidient this is not doing anything anymore, however Id like to delete where this component is used in some old code first, to narrow the potential effects of removing this
    dispatch(stopParallelPollingAction('MEETING_DETAILS'));
  };

  const handleOnCheck = () => {
    // Pretty confidient this is not doing anything anymore, however Id like to delete where this component is used in some old code first, to narrow the potential effects of removing this
    setTimeout(() => {
      dispatch(
        startParallelPollingAction(getMeetingById, param, 'MEETING_DETAILS'),
      );
    }, 2000);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const averageLetterWidth = 6.557;
  const menuPaddingLR = 30;
  const singleMenuHeight = 34;

  useEffect(() => {
    if (
      wrapperRef
      && childRef
      && showMenu
      && wrapperRef.current
      && childRef.current
    ) {
      const parentRect = wrapperRef.current.getBoundingClientRect();
      const { top, right } = parentRect;

      const numberOfItems = props.menuWithIcons
        ? props.menuWithIcons.length
        : props.staticContents
          ? props.staticContents.length
          : 0;
      const largestMenuInWidth = props.menuWithIcons
        ? Math.max(
          ...props.menuWithIcons.map((m) => {
            if (m.name === 'isRecurring') {
              return 'Recurring'.length;
            }
            if (m.name === 'isPrivate') {
              return 'Visible only to you'.length;
            }
            if (m.name === 'isComplete') {
              return 'Mark as Complete'.length;
            }
            return m.name.length;
          }),
        )
        : props.staticContents
          ? Math.max(
            ...props.staticContents.map((m) => {
              if (m === 'isRecurring') {
                return 'Recurring'.length;
              }
              if (m === 'isPrivate') {
                return 'Visible only to you'.length;
              }
              if (m === 'isComplete') {
                return 'Mark as Complete'.length;
              }
              return m.length;
            }),
          )
          : 0;

      const menuWidth = largestMenuInWidth * averageLetterWidth + menuPaddingLR + 11.2 + 16;
      const menuHeight = numberOfItems * singleMenuHeight;

      /**
       * @var px Parent X
       * @var py Parent Y
       * @var ww Window Width
       * @var wh Window Height
       * @var mw Menu Width
       * @var mh Menu Height
       */
      const {
        px, py, mw, mh, ww, wh,
      } = {
        px: parentRect.x,
        py: parentRect.y,
        mw: menuWidth,
        mh: menuHeight,
        ww: window.innerWidth,
        wh: window.innerHeight,
      };

      /**
       * @var mx Menu X
       */
      const mx = Math.max(0, px + mw - ww + 10);
      /**
       * @var my Menu Y
       */
      const my = Math.max(0, py + mh - wh + 10);

      const element = childRef.current;
      element.style.width = `${mw}px`;
      element.style.height = `${mh}px`;
      element.style.position = 'absolute';
      element.style.top = `${top - my}px`;
      element.style.right = `${window.innerWidth - right}px`;
      element.style.background = 'white';
      element.style['border-radius'] = '4px';
      element.style['z-index'] = '9999';
      element.style['box-shadow'] = '0px 20px 32px rgba(0, 0, 0, 0.24)';

      anime({
        targets: element,
        translateY: [-15, 0],
        opacity: [0, 1],
        duration: 250,
        easing: 'easeInOutQuad',
      });
    }
  }, [showMenu]);

  const selectedItem = (e, menuText, index) => {
    setIsSelected(true);
    props.selectedItem(e, menuText, index);
    setShowMenu(false);
  };

  const getMenu = (item, menuText, index) => {
    let leftSection;
    let label;
    switch (menuText) {
      case reviewBookmarkMenuItems.ADD:
      case reviewBookmarkMenuItems.REMOVE:
        label = menuText === reviewBookmarkMenuItems.ADD
          ? 'Add to next Review'
          : 'Remove from next Review';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faBookmark} />
            <span className="suggestion-custom-label">{ label }</span>
          </React.Fragment>
        );
        break;
      case 'isRecurring':
        label = 'Recurring';
        leftSection = (
          <Checkbox
            className="suggestion-custom-control"
            label={label}
            name={item.name}
            checked={item[menuText]}
            id={item.name}
            onClick={handleOnCheck}
          />
        );
        break;
      case 'isPrivate':
        label = 'Visible only to you';
        leftSection = (
          <Checkbox
            className="suggestion-custom-control"
            label={label}
            name={item.name}
            checked={item[menuText]}
            id={item.name}
            onClick={handleOnCheck}
          />
        );
        break;
      case 'isComplete':
        label = 'Mark as Complete';
        leftSection = (
          <Checkbox
            className="suggestion-custom-control"
            label={label}
            name={item.name}
            checked={item[menuText]}
            id={item.name}
            onClick={handleOnCheck}
          />
        );
        break;
      case 'Edit':
        label = 'Edit';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faEdit} />
            <span className="suggestion-custom-label">{ label }</span>
          </React.Fragment>
        );
        break;
      case 'Edit Meeting':
        label = 'Edit Meeting';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faEdit} />
            <span className="suggestion-custom-label">{ label }</span>
          </React.Fragment>
        );
        break;
      case 'Remove':
        label = 'Remove';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faTrash} />
            <span className="suggestion-custom-label">{ label }</span>
          </React.Fragment>
        );
        break;
      case 'Delete Meeting':
        label = 'Delete Meeting';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faTrash} />
            <span className="suggestion-custom-label">{ label }</span>
          </React.Fragment>
        );
        break;
      case 'Make Action':
        label = 'Make Action';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faPlus} />
            <span className="suggestion-custom-label">{ menuText }</span>
          </React.Fragment>
        );
        break;
      case 'Carry Over':
        label = 'Carry Over';
        leftSection = (
          <React.Fragment>
            <span className="suggestion-custom-label">{ label }</span>
          </React.Fragment>
        );
        break;
      case 'Resend Invite':
        label = 'Resend Invite';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faSend} />
            <span className="suggestion-custom-label">{ menuText }</span>
          </React.Fragment>
        );
        break;
      case 'Activate Account':
        label = 'Activate Account';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faUserCheck} />
            <span className="suggestion-custom-label">{ menuText }</span>
          </React.Fragment>
        );
        break;
      case 'Deactivate':
        label = 'Deactivate';
        leftSection = (
          <React.Fragment>
            <FontAwesomeIcon size="1x" icon={faUserXmark} />
            <span className="suggestion-custom-label">{ menuText }</span>
          </React.Fragment>
        );
        break;
      default:
        leftSection = (
          <span className="suggestion-custom-label">{ menuText }</span>
        );
        break;
    }
    return (
      <div
        key={`${menuText}_${index}`}
        className="action-list-menu-item"
        onClick={(e) => selectedItem(e, menuText, index)}
      >
        { leftSection }
      </div>
    );
  };

  /**
   * Render Menu with icon.
   * @param {*} menuDetails Store Menu details.
   * @param {*} index Store index of menu.
   */
  const renderMenuWithIcon = (menuDetails, index) => {
    let IconTag;
    switch (menuDetails.icon) {
      case 'User':
        IconTag = faUser;
        break;
      case 'Settings':
        IconTag = faGear;
        break;
      case 'LogIn':
        IconTag = faArrowRightToBracket;
        break;
      case 'Box':
        IconTag = faBox;
        break;
      case 'Flag':
        IconTag = faFlag;
        break;
    }
    return (
      <div
        key={`${menuDetails.name}_${index}`}
        className="action-list-menu-item"
        onClick={(e) => selectedItem(e, menuDetails.name, index)}
      >
        <FontAwesomeIcon size="1x" icon={IconTag} />
        <span className="suggestion-custom-label">{ menuDetails.name }</span>
      </div>
    );
  };
  return (
    <React.Fragment>
      { props.menuWithIcons?.length || props.staticContents?.length ? (
        <div className="action-menu-wrapper actionmenu-space">
          <span
            className="action-btns"
            onClick={handleClick}
            ref={wrapperRef}
          >
            { props.icon ? props.icon : <FontAwesomeIcon size="1x" icon={faEllipsisVertical} /> }
          </span>
          { showMenu ? (
            <Portal node={document && document.getElementById('modal-root')}>
              <div
                ref={childRef}
                className="action-menu-wrapper actionmenu-space"
              >
                { !props.menuWithIcons && props.staticContents ? (
                  <div
                    className={`action-list-menu asd p-0 ${
                      showMenu ? 'display-block' : 'display-none'
                    }`}
                  >
                    { props.staticContents.map((menuText, index) => getMenu(props.menuData, menuText, index)) }
                  </div>
                ) : null }
                { props.menuWithIcons ? (
                  <div
                    className={`action-list-menu asd1 p-0 ${
                      showMenu ? 'display-block' : 'display-none'
                    }`}
                  >
                    { props.menuWithIcons.map((menuDetails, index) => renderMenuWithIcon(menuDetails, index)) }
                  </div>
                ) : null }
              </div>
              <div
                onClick={() => {
                  setShowMenu(false);
                  handleOnCheck();
                }}
                className={showMenu ? 'portal-overlay' : 'display-none'}
              />
            </Portal>
          ) : null }
        </div>
      ) : null }
    </React.Fragment>
  );
};

export default AgendaActionMenu;
