import { useMemo, useState } from 'react';

interface InitializeEditorParams {
  editorId: string,
  initialValue?: string,
}

export interface UseSingleRteReturn {
  editorText: string,
  shouldShowEditor: (editorId: string) => boolean,
  initializeEditor: ({ editorId, initialValue }: InitializeEditorParams) => void,
  onEditorTextChange: (newText: string) => void,
  onEditorClose: () => void,
  hasOpenEditor: boolean,
}

/*
  This is a hook for only having one RTE open at a time.
  Works well when you have the ability to add new questions/topics, but also the ability to edit them.
*/

export const useSingleRte = (): UseSingleRteReturn => {
  const [editorText, setEditorText] = useState('');
  const [openEditorId, setOpenEditorId] = useState('');

  const shouldShowEditor = (editorId: string): boolean => openEditorId === editorId;

  const hasOpenEditor = useMemo(() => openEditorId !== '', [openEditorId]);

  const initializeEditor = ({ editorId, initialValue = '' }: InitializeEditorParams): void => {
    setEditorText(initialValue);
    setOpenEditorId(editorId);
  };

  const onEditorTextChange = (newText: string): void => {
    setEditorText(newText);
  };

  const onEditorClose = (): void => {
    setOpenEditorId('');
    setEditorText('');
  };

  return {
    editorText,
    shouldShowEditor,
    initializeEditor,
    onEditorTextChange,
    onEditorClose,
    hasOpenEditor,
  };
};
