import { ComponentProps, useMemo } from 'react';
import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { Link } from 'react-router-dom';

import { palette } from '~Common/styles/colors';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { forDesktopObject, withoutDesktopObject } from '~Common/styles/mixins';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import OldMeetingGoalCard from './OldMeetingGoalCard';

const styles = {
  container: css({
    padding: '1rem 1.75rem',
  }, withoutDesktopObject({
    boxShadow: 'none',
    padding: '1rem',
  }), forDesktopObject({
    backgroundColor: palette.neutrals.white,
    boxShadow: '0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.07)',
    borderRadius: '0.5rem',
  })),

  header: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.75rem',
  }),

  title: css({
    flex: 1,
    fontSize: '1.125rem',
    fontWeight: 600,
  }),

  divider: css({
    margin: '0 0 0.75rem 0',
  }),

  cardContainer: css({
    display: 'flex',
    overflowX: 'auto',

    // Needed so the box shadow of the goal cards isn't cut off
    padding: '0.5rem',
  }),

  goalCard: css({
    marginRight: '0.625rem',
  }),

  emptyStateButton: css({
    padding: 0,
    fontStyle: 'italic',
  }),

  emptyStateMessage: css({
    fontStyle: 'italic',
    color: palette.neutrals.gray700,
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  goals?: Goals.Goal[],
  isLoading: boolean,
  shouldShowActionableEmptyState?: boolean,
  isUserOnTeam?: boolean,
}

export const View = ({
  isLoading,
  goals = [],
  shouldShowActionableEmptyState,
  isUserOnTeam,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    <header css={styles.header}>
      <div css={styles.title}>
        <span>
          {`Team Goals ${goals.length ? `(${goals.length})` : ''}`}
        </span>
      </div>
    </header>

    <hr css={styles.divider} />

    <div>
      { isLoading && (
        <div css={styles.cardContainer}>
          <OldMeetingGoalCard.Skeleton css={styles.goalCard} />
          <OldMeetingGoalCard.Skeleton css={styles.goalCard} />
          <OldMeetingGoalCard.Skeleton css={styles.goalCard} />
          <OldMeetingGoalCard.Skeleton css={styles.goalCard} />
        </div>
      )}

      {!isLoading && (
        <>
          {!!goals.length && (
            <div css={styles.cardContainer}>
              { goals.map((goal) => (
                <OldMeetingGoalCard
                  css={styles.goalCard}
                  goal={goal}
                  canAccessGoal={isUserOnTeam}
                />
              ))}
            </div>
          )}

          {!goals.length && (
            <>
              {shouldShowActionableEmptyState && (
                <div>
                  <LeadrButton
                    data-test-id="teamGoalsEmptyStateButton"
                    css={styles.emptyStateButton}
                    variant="text"
                    component={Link}
                    to={GoalsRoutes.Create}
                  >
                    Add Team Goals
                  </LeadrButton>

                  <span css={styles.emptyStateMessage}>
                    &nbsp;for this team
                  </span>
                </div>
              )}

              {!shouldShowActionableEmptyState && (
                <span css={styles.emptyStateMessage}>
                  Team Goals not created
                </span>
              )}
            </>
          )}
        </>
      )}
    </div>
  </div>
);

export interface OldTeamGoalsProps extends ComponentProps<'div'> {
  teamId: string,
  huddleId: string,
}

const OldTeamGoals = ({
  teamId,
  huddleId,
  ...props
}: OldTeamGoalsProps): JSX.Element => {
  const orgUserId = getOrganizationUserId();
  const { isAdmin } = useUserPermissions();
  const { data: teamDetailsData, isLoading: isTeamDetailsLoading } = useGetTeamDetails({ teamId, huddleId });
  const params = {
    contextType: Goals.GoalContextType.Team,
    contextId: teamId,
  };
  const { data, isLoading: isGoalsLoading } = useGetGoals({ params });

  const shouldShowActionableEmptyState = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    isAdmin || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    isAdmin,
    orgUserId,
    teamDetailsData?.response.ownerOrgUserIds,
  ]);

  const [isLoading] = useSkeletonLoaders(isTeamDetailsLoading || isGoalsLoading);

  const isUserOnTeam = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    teamDetailsData?.response.memberOrgUserIds.includes(orgUserId!)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    orgUserId,
    teamDetailsData?.response,
  ]);

  const hookProps = {
    goals: data?.response,
    isLoading,
    shouldShowActionableEmptyState,
    isUserOnTeam,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default OldTeamGoals;
