import { css } from '@emotion/react';
import { CardSkeleton } from '~Common/V3/components/Card';
import { useGetPeopleFilters } from '~Common/hooks/useGetPeopleFilters';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import {
  RefObject,
  useMemo, useRef, useState,
} from 'react';
import { SelectChangeEvent } from '@mui/material';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import {
  RECOGNITION_DEFAULT_DEPARTMENT, RECOGNITION_DEFAULT_END_DATE, RECOGNITION_DEFAULT_START_DATE, RECOGNITION_DEFAULT_USER_GROUP,
} from '~Recognition/const/defaults';
import _ from 'lodash';
import moment from 'moment-timezone';
import DatePicker from '~Common/V3/components/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useComponentVisible } from '~Recognition/hooks/useComponentVisible';
import FilterBarCard from '~Common/components/Cards/FilterBarCard';
import ClearFiltersButton from '~Common/components/Cards/FilterBarCard/ClearFiltersButton';
import { PAGE_STYLES } from '../const/pageStyles';

const styles = {
  ...PAGE_STYLES,
  skeletonWrapper: css({
    width: '100%',
    flexFlow: 'column',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '3.75rem',
  }),
  dashboardSpace: css({
    display: 'flex',
    alignContent: 'center',
    marginBottom: '1.875rem',
  }),
  recognitionFilter: (isActive: boolean) => css({
    maxWidth: '9.0625rem',
    fontSize: '0.75rem',
    padding: '0 .75rem',
    border: '1px solid transparent',
    '&>*': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    '.MuiSelect-select': {
      minWidth: '90px !important',
      maxWidth: '90px !important',
      textOverflow: 'ellipsis',
    },
  }, forMobileObject({
    '.MuiInputBase-input': {
      fontSize: '.75rem',
    },
  }), isActive && {
    border: `1px solid ${palette.brand.indigo}`,
  }),
  recognitionFiltersButton: (canClear: boolean) => css({
    border: 'none',
    color: `${palette.neutrals.gray700} !important`,
    whiteSpace: 'nowrap',
    ':focus, :hover': {
      backgroundColor: 'transparent',
    },
  }, canClear && {
    color: `${palette.brand.indigo} !important`,

  }),
  datePickerWrapper: css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  }, forMobileObject({
    display: 'none',
  })),
  datePickers: (showDatePickers: boolean) => css({
    position: 'absolute',
    bottom: '-6.375rem',
    zIndex: '2',
    background: 'white',
    width: '29rem',
    alignItems: 'center',
    padding: '1.5rem',
    display: 'none',
    borderRadius: '0 0 0.5rem 0.5rem',
    boxShadow: '0px 10px 8px -2px rgba(28, 42, 55, 0.2)',
  }, showDatePickers && {
    display: 'flex',
  }),
  datePicker: (secondPicker: boolean) => css({
    padding: '.25rem .3125rem',

    '& svg': {
      color: palette.brand.indigo,
      margin: '0 .75rem',
    },
  }, secondPicker && {
    marginRight: '0',
  }),
  toggleDatePickersButton: (hasValue: boolean) => css({
    padding: '.5rem',
    fontWeight: '500',
    borderRadius: '0.5rem',
    fontSize: '1rem',
    color: palette.neutrals.gray800,
    border: '1px solid transparent',
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    '&:hover, &:focus': {
      backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    },
    '& svg': {
      margin: '0 .75rem',
      verticalAlign: 'middle',
      color: palette.brand.indigo,
    },
  }, hasValue && {
    border: `1px solid ${palette.brand.indigo}`,
  }, forMobileObject({
    fontSize: '.75rem',
    whiteSpace: 'nowrap',
  })),
  filtersDash: css({
    fontSize: '1.875rem',
    fontWeight: '500',
    color: palette.neutrals.gray800,
    margin: '0 .25rem',
  }),
  allowScroll: css({
    overflow: 'initial',
  }, forMobileObject({
    overflowX: 'auto',
  })),
};

interface ViewProps {
  departmentsDropdown: DropdownItem[];
  userGroupDropdown: DropdownItem[];
  showSkeleton: boolean;
  hasFilters: () => boolean,
  clearFilters: () => void,
  department: string,
  setDepartment: (department: string) => void,
  userGroup: string,
  setUserGroup: (userGroup: string) => void,
  setStartDate: (startDate: string) => void,
  startDate: string,
  setEndDate: (endDate: string) => void,
  endDate: string,
  toggleDatePickers: () => void,
  hasTimeFilters: () => boolean,
  clearTimeFilters: () => void,
  innerRef: RefObject<HTMLDivElement>,
  showDatePickers: boolean,
}

const View = ({
  departmentsDropdown,
  userGroupDropdown,
  showSkeleton,
  hasFilters,
  clearFilters,
  department,
  setDepartment,
  userGroup,
  setUserGroup,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  toggleDatePickers,
  hasTimeFilters,
  clearTimeFilters,
  innerRef,
  showDatePickers,
}: ViewProps): JSX.Element => (
  <>
    {showSkeleton && (
    <MultipleSkeletonLoaders
      css={[styles.skeletonWrapper, styles.dashboardSpace]}
      numberOfSkeletons={1}
      renderSkeletonItem={() => (
        <CardSkeleton css={styles.cardSkeleton} />
      )}
    />
    )}
    {!showSkeleton && (
      <FilterBarCard
        css={[styles.dashboardSpace, styles.allowScroll]}
      >
        <div css={styles.datePickerWrapper}>
          <LeadrButton
            css={styles.toggleDatePickersButton(startDate !== RECOGNITION_DEFAULT_START_DATE || endDate !== RECOGNITION_DEFAULT_END_DATE)}
            data-test-id="displayDatePickers"
            size="standard"
            variant="ghost"
            onClick={toggleDatePickers}
          >
            <>
              Time Frame
              <FontAwesomeIcon
                icon={faCaretDown}
              />
            </>
          </LeadrButton>
          <div
            ref={innerRef}
            css={styles.datePickers(showDatePickers)}
          >
            {showDatePickers && (
              <>
                <DatePicker
                  css={styles.datePicker(false)}
                  onDateSelected={({ date: newDate }) => setStartDate(moment(newDate).format('L'))}
                  initialDate={moment(startDate)}
                  label="Start Date"
                  rightIconType={() => (
                    <div>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                      />
                    </div>
                  )}
                  name="startDate"
                  disablePast={false}
                  data-test-id="recognitionListTimeFrameStartDate"
                />
                <span css={styles.filtersDash}>-</span>
                <DatePicker
                  css={styles.datePicker(true)}
                  onDateSelected={({ date: newDate }) => setEndDate(moment(newDate).format('L'))}
                  initialDate={moment(endDate)}
                  label="End Date"
                  rightIconType={() => (
                    <div>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                      />
                    </div>
                  )}
                  name="endDate"
                  disablePast={false}
                  minDate={moment(startDate) as unknown as Date}
                  data-test-id="recognitionListTimeFrameEndDate"
                />
                <LeadrButton
                  data-test-id="clearFiltersButton"
                  variant="ghost"
                  size="small"
                  css={styles.recognitionFiltersButton(hasTimeFilters())}
                  onClick={clearTimeFilters}
                >
                  CLEAR DATES
                </LeadrButton>
              </>
            )}
          </div>
        </div>
        <Dropdown
          css={styles.recognitionFilter(department !== RECOGNITION_DEFAULT_DEPARTMENT.value)}
          items={departmentsDropdown}
          onChange={(event: SelectChangeEvent) => setDepartment(event.target.value)}
          value={department ?? RECOGNITION_DEFAULT_DEPARTMENT.value}
          data-test-id="recognitionListFilterDepartment"
          renderItem={(item: DropdownItem) => (
            <>
              {item.text}
            </>
          )}
        />
        <Dropdown
          css={styles.recognitionFilter(userGroup !== RECOGNITION_DEFAULT_USER_GROUP.value)}
          items={userGroupDropdown}
          onChange={(event: SelectChangeEvent) => setUserGroup(event.target.value)}
          value={userGroup ?? RECOGNITION_DEFAULT_USER_GROUP.value}
          data-test-id="recognitionListFilterUserGroup"
          renderItem={(item: DropdownItem) => (
            <>
              {item.text}
            </>
          )}
        />
        <ClearFiltersButton
          onClick={clearFilters}
          hasFilters={hasFilters()}
        />
      </FilterBarCard>
    )}
  </>
);

interface RecognitionFiltersProps {
  setDepartment: (department: string) => void,
  department: string,
  userGroup: string,
  setUserGroup: (value: string) => void,
  setStartDate: (startDate: string) => void,
  startDate: string,
  setEndDate: (endDate: string) => void,
  endDate: string,
}

const RecognitionFilters = ({
  setDepartment,
  department,
  userGroup,
  setUserGroup,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
}: RecognitionFiltersProps): JSX.Element => {
  const {
    departments,
    groups,
    isLoading: isFilterListLoading,
  } = useGetPeopleFilters();
  const [showSkeleton] = useSkeletonLoaders(isFilterListLoading);

  const departmentsDropdown = useMemo(() => {
    const allDepartments = departments.map((depart) => ({
      text: _.truncate(depart.value, {
        length: 40,
      }),
      value: depart.value,
    }));
    allDepartments.unshift({
      text: RECOGNITION_DEFAULT_DEPARTMENT.title,
      value: RECOGNITION_DEFAULT_DEPARTMENT.value,
    });
    return allDepartments;
  }, [departments]);

  const userGroupDropdown = useMemo(() => {
    const usergroups = groups.map((group) => ({
      text: _.truncate(group.name, {
        length: 40,
      }),
      value: group.teamId,
    }));
    usergroups.unshift({
      text: RECOGNITION_DEFAULT_USER_GROUP.title,
      value: RECOGNITION_DEFAULT_USER_GROUP.value,
    });
    return usergroups;
  }, [groups]);

  const hasFilters = (): boolean => {
    if (department !== RECOGNITION_DEFAULT_DEPARTMENT.value) {
      return true;
    }
    if (userGroup !== RECOGNITION_DEFAULT_USER_GROUP.value) {
      return true;
    }
    if (startDate !== RECOGNITION_DEFAULT_START_DATE || endDate !== RECOGNITION_DEFAULT_END_DATE) {
      return true;
    }

    return false;
  };
  const hasTimeFilters = (): boolean => {
    if (startDate !== RECOGNITION_DEFAULT_START_DATE || endDate !== RECOGNITION_DEFAULT_END_DATE) {
      return true;
    }

    return false;
  };
  const clearTimeFilters = (): void => {
    setStartDate(RECOGNITION_DEFAULT_START_DATE);
    setEndDate(RECOGNITION_DEFAULT_END_DATE);
  };
  const clearFilters = (): void => {
    setDepartment(RECOGNITION_DEFAULT_DEPARTMENT.value);
    setUserGroup(RECOGNITION_DEFAULT_USER_GROUP.value);
    setStartDate(RECOGNITION_DEFAULT_START_DATE);
    setEndDate(RECOGNITION_DEFAULT_END_DATE);
  };
  const [showDatePickers, setShowDatePickers] = useState(false);
  const innerRef = useRef<HTMLDivElement>(null);
  const toggleDatePickers = (): void => {
    setShowDatePickers(!showDatePickers);
  };

  useComponentVisible(innerRef, toggleDatePickers);

  const hookProps = {
    departmentsDropdown,
    userGroupDropdown,
    showSkeleton,
    hasFilters,
    clearFilters,
    department,
    setDepartment,
    userGroup,
    setUserGroup,
    setStartDate,
    startDate,
    setEndDate,
    endDate,
    toggleDatePickers,
    showDatePickers,
    hasTimeFilters,
    clearTimeFilters,
    innerRef,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default RecognitionFilters;
