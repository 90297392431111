import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { ParticipantTypeEnum, ReviewShape } from '~Reviews/V2/Const/types';
import { returnReviewParticipantType } from '~Reviews/V2/Hooks/returnReviewParticipantType';

const styles = {
  textWrapper: css({
    color: palette.neutrals.gray800,
    lineHeight: '1.25rem',
  }),
  boldedText: css({
    fontWeight: 600,
  }),
};

interface RenderTextParams {
  isReviewee: boolean,
  reviewerFullName: string,
  secondaryReviewerFullName: string,
  revieweeFullName: string,
  hasSecondaryReviewer: boolean,
}

interface ViewProps extends RenderTextParams {
  reviewCycleDateRangeText: string,
  renderText: ({
    isReviewee,
    reviewerFullName,
    secondaryReviewerFullName,
    revieweeFullName,
    hasSecondaryReviewer,
  }: RenderTextParams) => JSX.Element,
}

const View = ({
  reviewCycleDateRangeText,
  renderText,
  reviewerFullName,
  secondaryReviewerFullName,
  revieweeFullName,
  isReviewee,
  hasSecondaryReviewer,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.textWrapper}
    {...props}
  >
    {renderText({
      isReviewee,
      reviewerFullName,
      secondaryReviewerFullName,
      revieweeFullName,
      hasSecondaryReviewer,
    })}
    within the review period
    {' '}
    <span css={styles.boldedText}>{reviewCycleDateRangeText}</span>
    .
  </div>
);

interface TextWrapperProps extends
  Omit<ViewProps, 'reviewCycleText' | 'reviewerFullName' | 'secondaryReviewerFullName' | 'revieweeFullName' | 'isReviewee' | 'hasSecondaryReviewer'>
{
  review: ReviewShape,
}

const TextWrapper = ({
  review,
  ...props
}: TextWrapperProps): JSX.Element => {
  const { isReviewee } = returnReviewParticipantType(review);
  const reviewer = review.participants.find((participant) => participant.participantTypeEnum === ParticipantTypeEnum.Reviewer);
  const secondaryReviewer = review.participants.find((participant) => participant.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer);
  const reviewee = review.participants.find((participant) => participant.participantTypeEnum === ParticipantTypeEnum.Reviewee);

  let reviewerFullName = '';
  let secondaryReviewerFullName = '';
  let revieweeFullName = '';

  if (reviewer) {
    reviewerFullName = `${reviewer.firstName} ${reviewer.lastName}`;
  }
  if (secondaryReviewer) {
    secondaryReviewerFullName = `${secondaryReviewer.firstName} ${secondaryReviewer.lastName}`;
  }

  if (reviewee) {
    revieweeFullName = `${reviewee.firstName} ${reviewee.lastName}`;
  }

  const hasSecondaryReviewer = !!secondaryReviewer;

  const hookProps = {
    reviewerFullName,
    secondaryReviewerFullName,
    revieweeFullName,
    isReviewee,
    hasSecondaryReviewer,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default TextWrapper;
