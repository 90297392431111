import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';

const getAdminData = async ({ queryKey }) => {
  const [organizationId, _, administrativeStatus, count, skip] = queryKey;

  const URL = `/organizations/${organizationId}/users?count=${count}&skip=${skip}&administrativeStatus=${administrativeStatus}`;

  return getApi(URL);
};

export const useGetAdminData = ({
  count, skip = 0, administrativeStatus,
}) => {
  const organizationId = getOrganizationId();
  const queryKey = [organizationId, 'admin', administrativeStatus, count, skip];

  const result = useQuery({
    queryKey,
    queryFn: getAdminData,
    enabled: !!organizationId,
    placeholderData: keepPreviousData,

  });

  const response = result.data?.response;
  return {
    users: response?.users || [],
    total: response?.total,
    isLoading: result.isLoading,
    refetch: result.refetch,
  };
};
