import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import {
  FormControl,
  InputBase,
  MenuItem,
  Select,
  Divider,
} from '@mui/material';
import { palette } from '~Common/styles/colors';

import { truncate } from '~Deprecated/ui/styles/mixins';

import { noop } from '~Deprecated/utils/';
import Tooltip from '~Common/components/Tooltip';
import { DROPDOWN_ITEMS_SHAPE } from '~Common/const/proptypes';

const iconStyle = (disabled) => css`
  width: 16px !important;
  height: 16px !important;
  position: absolute;
  right: 40px;
  top: 50%;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  color: ${disabled ? 'gray' : ''};
`;

const disabledInput = css`
  background: ${palette.neutrals.gray100};
  border: unset;
`;

const formControl = css`
  min-width: 150px !important;
  background: ${palette.neutrals.gray100};
  border: none;
  padding: 7px 16px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;

  .MuiSelect-selectMenu {
    padding-right: 48px;
    font-weight: 700;
    color: ${palette.neutrals.gray700};
  }

  .MuiInputBase-input:focus {
    background-color: transparent;
  }

  .MuiSelect-icon {
    top: calc(50% - 8px);
  }
`;

const labelStyles = css`
  color: ${palette.neutrals.gray700};
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
  width: 100%;
`;

const preferredOptionsSeparatorStyles = css`
  width: 50%;
  margin: 0.25rem 0;
`;

const selectStyles = css({
  background: palette.neutrals.gray100,
  color: palette.neutrals.gray800,
  fontWeight: '500',
});

const View = ({
  label,
  doChange,
  onClear,
  currentValue,
  disabled,
  items,
  preferredItems,
  name,
  showClearOption,
  selectProps,
  placeholder,
  renderIcon,
  ...props
}) => (
  <FormControl
    css={[formControl, (disabled ? disabledInput : '')]}
    {...props}
  >
    <label css={[labelStyles, truncate]} htmlFor={name}>{ label }</label>
    <Select
      css={selectStyles}
      name={name}
      value={currentValue}
      onChange={doChange}
      disabled={disabled}
      displayEmpty
      IconComponent={renderIcon}
      input={(
        <InputBase fullWidth />
      )}
      {...selectProps}
    >
      { placeholder && (
      <MenuItem value="" disable>
        { placeholder }
      </MenuItem>
      ) }

      { preferredItems?.map((item) => (
        <MenuItem
          value={item.value}
          key={item.value}
        >
          { item.text }
        </MenuItem>
      )) }

      {preferredItems && (
        <MenuItem
          disabled
          value=""
        >
          <Divider css={preferredOptionsSeparatorStyles} />
        </MenuItem>
      )}

      { items?.map((item) => (
        <MenuItem
          value={item.value}
          key={item.value}
        >
          { item.text }
        </MenuItem>
      )) }
    </Select>
    { currentValue && showClearOption && (
    <Tooltip content={disabled ? null : 'Clear'}>
      <div css={iconStyle(disabled)}>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            if (!disabled) onClear(e);
          }}
        />
      </div>
    </Tooltip>
    ) }
  </FormControl>
);

View.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentValue: PropTypes.any.isRequired,
  onClear: PropTypes.func.isRequired,
  doChange: PropTypes.func.isRequired,
  preferredItems: DROPDOWN_ITEMS_SHAPE,
  items: DROPDOWN_ITEMS_SHAPE,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.any,
  showClearOption: PropTypes.bool,
  placeholder: PropTypes.string,
  renderIcon: PropTypes.func.isRequired,
};

View.defaultProps = {
  items: null,
  preferredItems: null,
  label: '',
  name: '',
  disabled: false,
  selectProps: null,
  showClearOption: true,
  placeholder: '',
};

/**
 * @deprecated Old Dropdown UI, use packages/leadr-frontend/src/common/V3/components/Dropdown.tsx instead
 */
const ClearableDropdown = ({
  onChange, initialValue, placeholder, ...props
}) => {
  const [currentValue, updateState] = useState(initialValue);

  useEffect(() => {
    // Switching between add / edit workflow was not clearing out the last set value.
    updateState(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (initialValue || (initialValue === '' && placeholder)) {
      updateState(initialValue);
    }
  }, [initialValue, placeholder]);

  const renderIcon = (iconProps) => (
    <FontAwesomeIcon {...iconProps} icon={faCaretDown} />
  );

  const hookProps = {
    doChange: (event) => {
      if (event.target.value !== '') {
        if (onChange) onChange(event);
        updateState(event.target.value);
      }
    },
    onClear: (event) => {
      if (onChange) onChange(event);
      updateState('');
    },
    currentValue,
    placeholder,
    renderIcon,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ClearableDropdown.propTypes = {
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  initialValue: PropTypes.any,
  placeholder: PropTypes.string,
};

ClearableDropdown.defaultProps = {
  onChange: noop,
  initialValue: null,
  placeholder: null,
};

export { View };
export default ClearableDropdown;
