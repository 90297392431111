import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useCallback } from 'react';
import { FONT_AWESOME_ICON } from '~Common/const/proptypes';
import { palette } from '~Common/styles/colors';
import ListItem from './ListItem';

const styles = {
  leftIcon: css`
    color: ${palette.brand.indigo};
    width: 34px !important;
    height: 34px !important;
  `,
  rightIcon: css`
    width: 21px !important;
    height: 21px !important;
  `,
  listItem: css`
    color: ${palette.neutrals.gray700};
  `,
};

const SurveyListItem = ({
  icon, ...props
}) => {
  const leftAdornment = useCallback(({ style }) => (
    <div
      css={style}
    >
      <FontAwesomeIcon
        css={[
          styles.leftIcon,
        ]}
        icon={icon}
      />
    </div>
  ), [icon]);

  return (
    <ListItem
      css={[styles.listItem]}
      renderLeftAdornment={leftAdornment}
      {...props}
    />
  );
};

SurveyListItem.propTypes = {
  showSuggestion: PropTypes.bool,
  icon: FONT_AWESOME_ICON.isRequired,
};

SurveyListItem.defaultProps = {
  showSuggestion: false,
};

export default SurveyListItem;
