import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { SurveyTemplate } from '~Surveys/Hooks/useSurveyTemplates';

const styles = {
  title: css({
    color: palette.neutrals.gray900,
    fontSize: '1.25rem',
    fontWeight: 600,
    wordBreak: 'break-word',
  }),
  byline: css({
    color: palette.neutrals.gray500,
    fontSize: '0.8125rem',
    fontWeight: 600,
    marginTop: '0.75rem',
  }),
  objective: css({
    color: palette.neutrals.gray600,
    fontSize: '1rem',
    fontWeight: 400,
    marginTop: '1.25rem',
  }),
};

interface SurveySummaryCardProps {
  createdBy: string,
  template: SurveyTemplate,
}

export const SurveySummaryCard = ({
  createdBy,
  template,
}: SurveySummaryCardProps): JSX.Element => (
  <Card
    renderContents={() => (
      <>
        <h2 css={styles.title}>
          {template.title}
        </h2>
        <p css={styles.byline}>
          {`Created by ${createdBy}`}
        </p>
        <HTMLRenderer css={styles.objective} htmlText={template.objective} />
      </>
    )}
  />
);
