import { camelCase, some } from 'lodash';
import { useMemo } from 'react';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { ParticipantTypeEnum, ParticipantTypeEnumConversion, ReviewCycleDetail } from '~Reviews/V2/Const/types';

const orgUserId = getOrganizationUserId();

interface checkReviewCycleForIsManagerProps {
  isManager: boolean,
  isSecondaryManager: boolean,
}

export const checkReviewCycleForIsManager = (activeReviewCycle: ReviewCycleDetail | undefined): checkReviewCycleForIsManagerProps => {
  const allParticipants = activeReviewCycle?.reviews.flatMap((review) => review.participants);
  const isManager = some(allParticipants, (participant) => participant.orgUserId === orgUserId
      && participant.participantTypeEnum === ParticipantTypeEnum.Reviewer);
  const isSecondaryManager = some(allParticipants, (participant) => participant.orgUserId === orgUserId
      && participant.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer);

  return {
    isManager,
    isSecondaryManager,
  };
};

export const returnParticipantTypeEnum = (
  isSecondaryManagerOnCurrentReviewCycle: boolean,
  isManagerOnCurrentReviewCycle: boolean,
): ParticipantTypeEnumConversion => {
  let managerTypeEnum = ParticipantTypeEnumConversion.Reviewee;
  if (isSecondaryManagerOnCurrentReviewCycle) {
    managerTypeEnum = ParticipantTypeEnumConversion.SecondaryReviewer;
  } else if (isManagerOnCurrentReviewCycle) {
    managerTypeEnum = ParticipantTypeEnumConversion.Reviewer;
  }

  return managerTypeEnum;
};

interface useReturnReviewParticipantTypeReturnProps {
    isAnyManagerOnCycle: boolean,
    participantTypeEnum: ParticipantTypeEnumConversion,
    participantStatusType: string,
}

export const useReturnReviewCycleParticipantType = (fullReview: ReviewCycleDetail | undefined): useReturnReviewParticipantTypeReturnProps => {
  const { isManager, isSecondaryManager } = useMemo(
    () => checkReviewCycleForIsManager(fullReview),
    [fullReview],
  );
  const isAnyManagerOnCycle = isSecondaryManager || isManager;

  const participantTypeEnum = returnParticipantTypeEnum(isSecondaryManager, isManager);

  const participantStatusType = `${camelCase(participantTypeEnum.replace(/\s+/g, ''))}Status`;

  return {
    isAnyManagerOnCycle,
    participantTypeEnum,
    participantStatusType,
  };
};
