import {
  faBook,
  faBookUser,
  faBriefcase,
  faBullseyeArrow,
  faChartBar,
  faClipboardUser,
  faCog,
  faCommentsAlt,
  faDraftingCompass,
  faFlag,
  faHandHoldingSeedling,
  faHouse,
  faIdBadge,
  faIdCard,
  faNetworkWired,
  faPartyHorn,
  faPoll,
  faSearch,
  faSignOut,
  faSkull,
  faTasks,
  faTerminal,
  faUserFriends,
  faUsers,
  faUsersCog,
  faUserSecret,
  faBuilding,
} from '@fortawesome/pro-light-svg-icons';
import { getUserId, removeStorageItem } from '~Common/utils/localStorage';

import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { editProfileTemplate } from '~People/components/Drawers/EditProfile';
import { testToolsDrawerTemplate } from '~Deprecated/ui/views/TestTools/Drawers/Flags';
import { skeletonLoadersDrawerTemplate } from '~Deprecated/ui/views/TestTools/Drawers/SkeletonLoaders';
import { insightsDevToolsTemplate } from '~Insights/drawers/DevTools';
import { logoutFromAuth0 } from '~Root/functions/logoutFromAuth0';
import { signout } from '~Deprecated/redux/actions/common';
import { navigateAction } from '~Deprecated/actions/navigate';

import { globalSearchModalStore } from '~GlobalSearch/stores/useShowGlobalSearchModal';
import {
  ACTION_ITEMS_BASE_ROUTE,
  ADMIN_PEOPLE,
  COACHING_HOME,
  DEVELOPMENT_PLAN,
  FEEDBACK_BASE_ROUTE,
  GOALS_BASE_ROUTE,
  HOME_PAGE_HOME,
  IMPERSONATE,
  INSIGHTS_BASE_ROUTE,
  INSIGHTS_MARKETING_ROUTE,
  LEARNING_LIST_RECEIVED,
  NEXUS_ORGANIZATIONS,
  PEOPLE,
  PERSONALITY_TYPES,
  RECOGNITION,
  REVIEWS,
  SETTINGS,
  SURVEY_ROUTE,
  SELECT_ORGANIZATION,
} from '~Common/const/routes';
import { NavigationItem } from '~Root/components/OldNavigationSidebar/types';

export const navigationItemList: readonly NavigationItem[] = [
  {
    label: 'Search',
    icon: faSearch,
    onClick() {
      const { setShowModal } = globalSearchModalStore.getState();
      setShowModal(true);
    },
    isVisible: ({ isCollapsed, flags: { useGlobalSearch } }) => isCollapsed && !!useGlobalSearch,
  },
  {
    label: 'Home',
    href: HOME_PAGE_HOME,
    icon: faHouse,
  },
  {
    label: 'Meetings',
    href: COACHING_HOME,
    icon: faBriefcase,
  },
  {
    label: 'Goals',
    href: GOALS_BASE_ROUTE,
    icon: faBullseyeArrow,
  },
  {
    label: 'Feedback',
    href: FEEDBACK_BASE_ROUTE,
    icon: faCommentsAlt,
    hasIndicator: ({ pendingFeedback }) => pendingFeedback > 0,
  },
  {
    label: 'Recognition',
    href: RECOGNITION,
    icon: faPartyHorn,
    isVisible: ({ organization: { enableRecognition }, flags: { featureRecognition } }) => enableRecognition && !!featureRecognition,
  },
  {
    label: 'Surveys',
    href: SURVEY_ROUTE,
    icon: faPoll,
    isVisible: ({ organization: { enableInsights }, flags: { hideSurveysFromNavigation } }) => !!enableInsights && !hideSurveysFromNavigation,
    hasIndicator: ({ pendingSurveys }) => pendingSurveys > 0,
  },
  {
    label: 'Insights',
    href: INSIGHTS_BASE_ROUTE,
    icon: faChartBar,
    isVisible: ({ canSeeInsights }) => canSeeInsights,
  },
  {
    label: 'Insights Preview',
    href: INSIGHTS_MARKETING_ROUTE,
    icon: faChartBar,
    // It may be that we choose to enable Insights for specific trials, so let's not double up in that case.
    isVisible: ({ organizationInTrial, canSeeInsights }) => !canSeeInsights && organizationInTrial,
  },
  {
    label: 'Learning',
    href: LEARNING_LIST_RECEIVED,
    icon: faBook,
    hasIndicator: ({ pendingLearnings }) => pendingLearnings > 0,
  },
  {
    label: 'Development Plans',
    href: DEVELOPMENT_PLAN,
    icon: faHandHoldingSeedling,
    isVisible: ({ organization: { enablePdps }, flags: { pdpsPhaseOne } }) => !!pdpsPhaseOne && !!enablePdps,
  },
  {
    label: 'Action Items',
    href: ACTION_ITEMS_BASE_ROUTE,
    icon: faTasks,
    hasIndicator: ({ pendingActionItems }) => pendingActionItems > 0,
  },
  {
    label: 'People',
    href: PEOPLE,
    icon: faUserFriends,
  },
  {
    label: 'Reviews',
    href: REVIEWS,
    icon: faClipboardUser,
    isVisible: ({ organization: { enableReviews } }) => !!enableReviews,
    hasIndicator: ({ pendingReviews }) => pendingReviews > 0,
  },
  {
    label: 'HR',
    icon: faBookUser,
    isExternalLink: true,
    onClick: ({
      flags: { hrModuleLink },
      organization: { enableHRModule, easeUrl, enableISolvedModule },
    }) => hrModuleLink && handleHrModuleClick({ enableHRModule, enableISolvedModule, easeUrl }),
    isVisible: ({ flags, organization: { enableHRModule, enableISolvedModule } }) => (
      !!flags.hrModuleLink && (enableHRModule || enableISolvedModule)
    ),
  },
  {
    label: 'Admin',
    icon: faUsersCog,
    isVisible: ({ isAdmin, flags: { webAppTeamsV2 } }) => isAdmin && !webAppTeamsV2,
    children: [
      {
        label: 'People',
        icon: faUsers,
        href: ADMIN_PEOPLE,
      },
    ],
  },
  {
    label: 'Nexus',
    icon: faDraftingCompass,
    isVisible: ({ canSeeNexus, canImpersonate }) => canSeeNexus || canImpersonate,
    children: [
      {
        label: 'Orgs',
        href: NEXUS_ORGANIZATIONS,
        icon: faNetworkWired,
        isVisible: ({ canSeeNexus }) => canSeeNexus,
      },
      {
        label: 'Personality Types',
        href: PERSONALITY_TYPES,
        icon: faIdCard,
        isVisible: ({ canSeeNexus }) => canSeeNexus,
      },
      {
        label: 'Impersonate',
        href: IMPERSONATE,
        icon: faUserSecret,
        isVisible: ({ canImpersonate }) => canImpersonate,
      },
    ],
  },
  {
    label: 'Dev Tools',
    icon: faTerminal,
    isVisible: ({ flags }) => !!flags.showTestPage,
    children: [
      {
        label: 'Flags',
        icon: faFlag,
        onClick: ({ dispatch }) => {
          dispatch(pushDrawerAction({
            drawer: {
              ...testToolsDrawerTemplate,
            },
          }));
        },
      },
      {
        label: 'Skeleton Loaders',
        icon: faSkull,
        onClick: ({ dispatch }) => {
          dispatch(pushDrawerAction({
            drawer: {
              ...skeletonLoadersDrawerTemplate,
            },
          }));
        },
      },
      {
        label: 'Insights',
        icon: faChartBar,
        onClick: ({ dispatch }) => {
          dispatch(pushDrawerAction({
            drawer: {
              ...insightsDevToolsTemplate,
            },
          }));
        },
      },
    ],
  },
] as const;

interface HandleHrModuleClickProps {
  enableHRModule?: boolean,
  enableISolvedModule?: boolean,
  easeUrl?: string,
}

function handleHrModuleClick({
  enableHRModule,
  enableISolvedModule,
  easeUrl,
}: HandleHrModuleClickProps): void {
  if (enableISolvedModule) {
    window.open('https://connect.threadhcm.com/cloudservice/home', '_blank');
  } else if (enableHRModule && easeUrl) {
    window.open(easeUrl, '_blank');
  }
}

export const profileItems: readonly NavigationItem[] = [
  {
    label: 'Profile',
    icon: faIdBadge,
    onClick: ({ dispatch }) => {
      dispatch(pushDrawerAction({
        drawer: {
          ...editProfileTemplate,
          args: {
            userId: getUserId(),
          },
        },
      }));
    },
  },
  {
    label: 'Settings',
    icon: faCog,
    href: SETTINGS,
  },
  {
    label: 'Switch Orgs',
    icon: faBuilding,
    isVisible: ({ hasMultipleOrganizations }) => hasMultipleOrganizations,
    onClick: ({ dispatch }) => {
      dispatch(signout({ fullSignout: false }));
      removeStorageItem('organizationId');
      removeStorageItem('orgUserId');

      dispatch(navigateAction({
        pathname: SELECT_ORGANIZATION,
      }));
    },
  },
  {
    label: 'Logout',
    icon: faSignOut,
    onClick: () => {
      logoutFromAuth0();
    },
  },
];
