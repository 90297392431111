import { connect } from 'react-redux';
import { getOrganizationById } from '~Deprecated/selectors/organizationInsights/getOrganizationById';
import { logIntoOrganizationAction } from '~Deprecated/actions/organizationInsights/logIntoOrganization';

const mapStateToProps = (state, { id }) => {
  const organization = getOrganizationById(state, { id }) || {};

  return {
    id,
    header: organization.name,
    total: organization.summaryReport ? organization.count : 1,
    summary: organization.summaryReport,
    actionText: 'View As',
  };
};

const mapDispatchToProps = {
  onView: logIntoOrganizationAction,
};

export default connect(mapStateToProps, mapDispatchToProps);
