import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { calendarIntQueryKeys } from '~Meetings/hooks/CalendarIntegration/queryKeys';

interface IgnoreMeetingParams {
  nylasEventId: string,
}

const ignoreMeeting = ({ nylasEventId }: IgnoreMeetingParams): Promise<HttpCallReturn<null>> => {
  const URL = {
    host: hosts.meeting,
    uri: `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/ignoreCalendarEvent`,
  };

  return postApi(URL, nylasEventId);
};

export const useIgnoreMeeting = (): UseMutateFunction<HttpCallReturn<null>, unknown, IgnoreMeetingParams, void> => {
  const toastId = useRef<ReactText | null>(null);

  const mutation = useMutation({
    mutationFn: ignoreMeeting,
    onMutate: () => {
      toastId.current = toast.info('Moving calendar event to \'ignored\'...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error moving your calendar event to \'ignored\'. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully moved your calendar event to \'ignored\'.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: calendarIntQueryKeys.all });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId() ?? '') });
    },
  });

  return mutation.mutate;
};
