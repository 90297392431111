import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import noSearchResults from '~Assets/images/noSearchResults.png';

const styles = {
  emptySearchOrFilters: css({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: palette.neutrals.gray600,
  }),
  noSearchResultsImage: css({
    maxHeight: '15.625rem',
  }),
};

const LearningEmptySearchOrFilters = (): JSX.Element => (
  <div css={styles.emptySearchOrFilters}>
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
    <img css={styles.noSearchResultsImage} src={noSearchResults} alt="No Search Results" data-test-id="learningNoSearchResults" />
    <div>Your search or filters have no results.</div>
  </div>
);

export default LearningEmptySearchOrFilters;
