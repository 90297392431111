import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import {
  postApi, patchApi, deleteApi,
} from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';
import { toast } from '~Common/components/Toasts';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { useAddQuestionsToTemplate } from './useCreateFeedbackTemplate';
import { queryClient } from '~Common/const/queryClient';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';

const updateTemplate = async ({ template, id }) => {
  const url = {
    host: hosts.feedback,
    uri: `/organizations/${getOrganizationId()}/templates/feedbacks/${id}`,
  };

  return patchApi(url, template, {});
};

const updateQuestionsToTemplate = async ({ questions, id }) => {
  const localUrl = {
    host: hosts.questions,
    uri: `/organizations/${getOrganizationId()}/questions`,
  };
  return patchApi(localUrl, { questions, context: { type: 'FEEDBACK_TEMPLATE', id } }, {});
};

const deleteQuestionsFromTemplate = async ({ questions, id }) => {
  const localUrl = {
    host: hosts.questions,
    uri: `/organizations/${getOrganizationId()}/questions`,
  };
  return deleteApi(localUrl, { questions, context: { type: 'FEEDBACK_TEMPLATE', id } }, {});
};

export const useUpdateFeedbackTemplate = ({ queryKey = [getOrganizationId(), 'feedback'], drawerTemplate }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: updateTemplate,
    onMutate: ({ template }) => {
      toastId.current = toast.info(`Updating ${template.title}...`, { autoClose: false });

      dispatch(
        popDrawerAction({ drawerName: drawerTemplate.name }),
      );
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error saving the template. Please check that all fields have been filled out and try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (drawerTemplate) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...drawerTemplate,
              args: {
                ...variables,
              },
            },
          }));
        });
      }
    },
    onSuccess: (data, variables, context) => {
      toast.update(toastId.current, {
        render: `Successfully updated ${variables.template.title}`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey });
    },
  });
  return mutation.mutate;
};

export { useAddQuestionsToTemplate };

export const useUpdateQuestionsOnTemplate = ({ queryKey = [getOrganizationId(), 'feedback'] }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: updateQuestionsToTemplate,
    onMutate: (variables) => {
      toastId.current = toast.info('Updating question...', { autoClose: false });
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error updating the question. Please try again',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      // maybe need to revert the question
      // opening the drawer has the complication that it might overwrite what someone was already working on
    },
    onSuccess: (data, variables, context) => {
      toast.update(toastId.current, {
        render: 'Successfully updated the question',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey });
    },
  });
  return mutation.mutate;
};

export const useDeleteQuestionsOnTemplate = ({ queryKey = [getOrganizationId(), 'feedback'], drawerTemplate }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: deleteQuestionsFromTemplate,
    onMutate: (variables) => {
      toastId.current = toast.info('Deleting question...', { autoClose: false });
      dispatch(
        popDrawerAction({ drawerName: drawerTemplate.name }),
      );
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error deleting the question. Please try again',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables, context) => {
      toast.update(toastId.current, {
        render: 'Successfully deleted the question.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutate;
};
