import { css } from '@emotion/react';
import AvatarMaybeMultipleMaybeNot from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import ListCard from '~Common/components/Cards/ListCard';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { Divider } from '@mui/material';
import { palette } from '~Common/styles/colors';

const styles = {
  avatar: css({
    '&:not(:last-child)': {
      marginRight: '.25rem',
    },
  }),
  underTitleSection: css({
    display: 'flex',
    alignItem: 'center',
  }),
  divider: css({
    color: palette.neutrals.gray500,
    margin: '0 0.5rem',
  }),
  completedSection: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

interface ListFeedbackCardProps {
  title: string,
  dueDate: string | undefined,
  assignedUsersInfo: PersonDisplayInformation[],
  numberOfUsersAssigned: number,
  renderCompletedSection?: () => JSX.Element,
  onCardClick: () => void,
  isComplete: boolean,
}

const ListFeedbackCard = ({
  title,
  dueDate,
  assignedUsersInfo,
  numberOfUsersAssigned,
  renderCompletedSection,
  onCardClick,
  isComplete,
  ...props
}: ListFeedbackCardProps): JSX.Element => (
  <ListCard
    onClick={onCardClick}
    title={title}
    renderRightSection={() => (
      <AvatarMaybeMultipleMaybeNot
        usersInfo={assignedUsersInfo}
        numberOfUsers={numberOfUsersAssigned}
        numberOfUsersToShow={3}
        avatarHeight={35}
        avatarWidth={35}
        renderAvatar={({ user, avatarHeight, avatarWidth }) => (
          <div css={styles.avatar}>
            <AvatarWithTooltip
              firstName={user.firstName}
              lastName={user.lastName}
              profileImageUrl={user.profileImageUrl}
              height={avatarHeight}
              width={avatarWidth}
            />
          </div>
        )}
        renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
          <OverflowAvatar
            css={styles.avatar}
            {...overflowAvatarProps}
          />
        )}
      />
    )}
    renderUnderTitleSection={() => (
      <div css={styles.underTitleSection}>
        <DueDateFromNow
          dueDate={dueDate}
          isComplete={isComplete}
        />
        {renderCompletedSection && (
          <div css={styles.completedSection}>
            <Divider orientation="vertical" css={styles.divider} />
            {renderCompletedSection()}
          </div>
        )}
      </div>
    )}
    {...props}
  />
);

export default ListFeedbackCard;
