import { css } from '@emotion/react';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { USER_GROUP_SHAPE } from '~Common/const/proptypes';

import { palette, userGroupsCard } from '~Common/styles/colors';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import Avatar from '~Deprecated/common/Components/People/Avatar';
import { noop } from '~Deprecated/utils';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { withTruncate } from '~Common/styles/mixins';
import { editUserGroupDrawerTemplate } from '../Drawers/EditUserGroupDrawer';
import GenericTable from '../GenericTable';

const styles = {
  tableContainer: css`
    box-shadow: none;
    border-radius: 0;

    .MuiTableCell-root {
      border: 0;
    }
  `,
  tableHeader: css`
    background-color: ${userGroupsCard.tableHeaderGray};
  `,
  headerText: css`
    font-weight: bold;
    padding: 0.25rem 0.75rem;
    color: ${palette.neutrals.gray800};
  `,
  tableRow: css`
    &:nth-of-type(even) {
      background-color: ${palette.neutrals.gray50};
    }

    &:hover {
      cursor: pointer;
    }

    .MuiTableCell-body {
      color: ${palette.neutrals.gray800};
    }
  `,
  totalMembersContainer: css`
    display: inline-flex;
    align-items: center;
    border-radius: 0.3125rem;
    border: 0.0625rem solid ${userGroupsCard.borderColor};
    justify-content: flex-end;
    max-width: 5rem;
  `,
  userComponent: css`
    padding: 0.375rem 0.625rem;
  `,
  userIcon: css`
    color: ${userGroupsCard.brandTextColor};
    border-right: 0.0625rem solid ${userGroupsCard.borderColor};
  `,
  totalMembersText: css`
    color: ${userGroupsCard.brandTextColor};
  `,
  avatarSection: css`
    display: flex;
  `,
  avatarContainer: css`
    &:not(:last-child) {
      margin-right: 0.25rem;
    }
  `,
  avatar: css`
    border-radius: 0.5rem;
  `,
  overflowStyle: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.875rem;
    width: 1.875rem;
    border-radius: 0.5rem;
    flex-grow: 0;
    text-align: center;
    padding-inline: 0.5rem;
    font-weight: bold;
    color: ${palette.neutrals.white};
    background-color: ${palette.neutrals.gray500};
  `,
  tableCell: css`
    display: inline-block;
    max-width: 15rem;
    ${withTruncate()}
  `,
};

const MAX_AVATARS_SHOWN = 5;

const View = ({
  groupsToUse, onRowClick, ...props
}) => (
  <GenericTable
    css={styles.tableContainer}
    renderHeader={() => (
      <TableHead css={styles.tableHeader}>
        <TableRow>
          <TableCell css={styles.headerText}>Group Name</TableCell>
          <TableCell css={styles.headerText}>Description</TableCell>
          <TableCell css={styles.headerText}>Avatars</TableCell>
          <TableCell css={styles.headerText}>Total Members</TableCell>
        </TableRow>
      </TableHead>
    )}
    renderBody={() => (
      <TableBody>
        {groupsToUse.map((group) => (
          <TableRow
            css={styles.tableRow}
            key={group.teamId}
            onClick={() => onRowClick(group)}
          >
            <TableCell component="th" scope="row">
              <div css={styles.tableCell}>{group.name}</div>
            </TableCell>
            <TableCell>
              <div css={styles.tableCell}>{group.description}</div>
            </TableCell>
            <TableCell>
              <div css={styles.avatarSection}>
                {group.members?.map((person) => (
                  <div
                    css={styles.avatarContainer}
                    key={`${person.firstName}-${person.lastName}`}
                  >
                    <Avatar
                      width={30}
                      height={30}
                      imgUrl={person?.profileImageUrl}
                      name={`${person?.firstName} ${person?.lastName}`}
                      css={styles.avatar}
                    />
                  </div>
                ))}
                {group.totalMembers > MAX_AVATARS_SHOWN && (
                  <div css={styles.overflowStyle}>
                    <div>{`+${group.totalMembers - MAX_AVATARS_SHOWN}`}</div>
                  </div>
                )}
              </div>
            </TableCell>
            <TableCell>
              <div css={styles.totalMembersContainer}>
                <FontAwesomeIcon
                  icon={faUsers}
                  css={[styles.userIcon, styles.userComponent]}
                />
                <div css={[styles.totalMembersText, styles.userComponent]}>
                  {group.totalMembers ?? 0}
                </div>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )}
    {...props}
  />
);

View.propTypes = {
  onRowClick: PropTypes.func,
  groupsToUse: PropTypes.arrayOf(USER_GROUP_SHAPE),
  onClickExpand: PropTypes.func,
};

View.defaultProps = {
  onRowClick: noop,
  groupsToUse: [],
  onClickExpand: noop,
};

const SkeletonView = () => (
  <SkeletonLoader
    height={500}
    width="100%"
    variant="rect"
    renderComponent={() => <GenericTable />}
  />
);

const UserGroupsTable = ({
  groups, numberOfRows, page, ...props
}) => {
  const [groupsToUse, setGroupsToUse] = useState(groups);

  const dispatch = useDispatch();

  useEffect(() => {
    setGroupsToUse(
      groups.slice((page - 1) * numberOfRows, page * (numberOfRows - 1)),
    );
    setGroupsToUse(groups.slice((page - 1) * numberOfRows, page * (numberOfRows)));
  }, [numberOfRows, groups, page]);

  const hookProps = {
    groupsToUse,
    onRowClick: (group) => dispatch(
      pushDrawerAction({
        drawer: {
          ...editUserGroupDrawerTemplate,
          args: {
            teamId: group.teamId,
          },
        },
      }),
    ),
  };

  return <View {...hookProps} {...props} />;
};

UserGroupsTable.propTypes = {
  numberOfRows: PropTypes.number,
  groups: PropTypes.arrayOf(USER_GROUP_SHAPE),
  page: PropTypes.number.isRequired,
};

UserGroupsTable.defaultProps = {
  numberOfRows: 10,
  groups: [],
};

export { View, SkeletonView as UserGroupsTableSkeleton };
export default UserGroupsTable;
