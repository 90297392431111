import { ActionItemAssigneeType, useActionItems } from '~ActionItems/hooks/useActionItems';
import { emptyStateFilterValue } from '~ActionItems/components/ActionItems';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { AllDueItemTypes, UseTransformItemsReturn } from '~Home/const/interfaces';
import moment from 'moment-timezone';
import { onActionItemClickHandler } from '~Home/const/WhatsDueItemClickHandlers';
import { WhatsDueFilterOptions } from '~Home/const';

export const useTransformActionItems = ():UseTransformItemsReturn => {
  const { isLoading, data } = useActionItems({
    skip: 0,
    take: 10,
    status: emptyStateFilterValue,
    assigneeType: [ActionItemAssigneeType.Me],
  });
  const taggedActionItems = data?.response?.items?.map((item) => ({ ...item, type: AllDueItemTypes.AllDueActionItem }));
  const transformedActionItems = taggedActionItems?.map((actionItem) => ({
    type: WhatsDueFilterOptions.ActionItems,
    text: actionItem.text,
    iconToRender: faCheck,
    dueDate: actionItem.dueDateInMillis ? moment(actionItem.dueDateInMillis) : undefined,
    onItemClick: onActionItemClickHandler,
    id: actionItem.id,
  }));
  return {
    isLoading,
    data: transformedActionItems ?? [],
  };
};
