import { css } from '@emotion/react';
import { ChangeEvent, useMemo, useState } from 'react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { palette } from '~Common/styles/colors';
import { CardSkeleton } from '~Common/V3/components/Card';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import moment from 'moment';
import { ParticipantTypeEnum, ReviewShape } from '~Reviews/V2/Const/types';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import {
  Reflection, ReflectionType, ReflectionTypeEnum, ReflectionsFavoriteType, ReflectionsIsActiveType,
} from '../../types';
import { InfoBox } from '../Infobox';
import { ReflectionListSection } from './ReflectionListSection';
import { AddReflectionDrawerProps } from '..';
import { useGetProperReflectionsList } from '../../hooks/useGetProperReflectionsList';

function getReflectionHeading(reflectionType: ReflectionTypeEnum): string {
  switch (reflectionType) {
    case ReflectionTypeEnum.Accomplishment:
      return 'Accomplishments';
    case ReflectionTypeEnum.BookmarkedTopic:
      return 'Agenda Items';
    case ReflectionTypeEnum.Feedback:
      return 'Feedback Requests';
    case ReflectionTypeEnum.Goal:
      return 'Goals';
    case ReflectionTypeEnum.Learning:
      return 'Learnings';
    case ReflectionTypeEnum.Recognition:
      return 'Recognitions';
    default:
      return 'Reflections';
  }
}

const styles = {
  addReflectionDrawerBody: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  leadrSearchField: css({
    marginTop: '.625rem',
  }),
  reflectionListSection: css({
    marginTop: '1.125rem',
  }),
  cardSkeleton: css({
    minWidth: '100%',
    height: '4.25rem',
    marginTop: '1.375rem',
    ':not(:first-of-type)': {
      marginTop: '.375rem',
    },
  }),
  emptyImage: css({
    height: '100%',
    marginBottom: '0.25rem',
    maxHeight: '16rem',
    maxWidth: '16rem',
  }),
  emptyState: css({
    textAlign: 'center',
    color: palette.brand.indigo,
    fontSize: '0.875rem',
    fontWeight: 600,
  }),
};

interface ViewProps extends AddReflectionDrawerBodyProps {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
  orderedReflections: Reflection[] | undefined,
  reviewCycleDateRangeText: string,
  headingText: string,
  showSkeleton: boolean,
}

export const View = ({
  onSearch,
  reflectionType,
  reviewUid,
  reviewTopicUid,
  review,
  orderedReflections,
  reviewCycleDateRangeText,
  headingText,
  showSkeleton,
  attachedSubjectIds,
}: ViewProps): JSX.Element => (
  <div css={styles.addReflectionDrawerBody}>
    <InfoBox review={review} reflectionType={reflectionType} reviewCycleDateRangeText={reviewCycleDateRangeText} />
    {showSkeleton && (
      <MultipleSkeletonLoaders
        numberOfSkeletons={3}
        renderSkeletonItem={() => (
          <CardSkeleton css={styles.cardSkeleton} />
        )}
      />
    )}
    {!showSkeleton && (
      <>
        <LeadrSearchField
          css={styles.leadrSearchField}
          data-test-id="reviewsReflectionsSearchField"
          onChange={onSearch}
        />
        <ReflectionListSection
          css={styles.reflectionListSection}
          reviewUid={reviewUid}
          reviewTopicUid={reviewTopicUid}
          reflections={orderedReflections}
          headingText={headingText}
          review={review}
          reflectionType={reflectionType}
          attachedSubjectIds={attachedSubjectIds}
        />
      </>
    )}
  </div>
);

interface AddReflectionDrawerBodyProps extends AddReflectionDrawerProps {
  reflectionType: ReflectionTypeEnum,
  review: ReviewShape,
  attachedSubjectIds: string[],
}

export const AddReflectionDrawerBody = ({
  reflectionType,
  reviewTopicUid,
  reviewUid,
  review,
  ...props
}: AddReflectionDrawerBodyProps): JSX.Element => {
  const [searchText, setSearchText] = useState('');
  // eslint-disable-next-line max-len
  const reviewCycleDateRangeText = `${moment(review.reviewCycle.assessmentPeriodStart).format('MMM D, YYYY')} - ${moment(review.reviewCycle.assessmentPeriodEnd).format('MMM D, YYYY')}`;

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event?.target.value);
  };
  const loggedInUserOrgId = getOrganizationUserId();

  const loggedInUserRecord = review.participants.find((participant) => participant.orgUserId === loggedInUserOrgId);

  const isManager = loggedInUserRecord?.participantTypeEnum !== ParticipantTypeEnum.Reviewee;

  const reviewee = review.participants.find((participant) => participant.participantTypeEnum === ParticipantTypeEnum.Reviewee);
  const revieweeId = reviewee?.orgUserId;

  const { reflections, isLoading } = useGetProperReflectionsList({
    search: searchText,
    index: 1,
    limit: 1000,
    reflectionType: [reflectionType as unknown as ReflectionType],
    favoriteStatus: [ReflectionsFavoriteType.All],
    isInActiveReview: [ReflectionsIsActiveType.All],
    ownerId: revieweeId ?? '',
    startDate: review.reviewCycle.assessmentPeriodStart.toLocaleString(),
    endDate: review.reviewCycle.assessmentPeriodEnd.toLocaleString(),
    reviewPeriod: '',
    isManager,
  });

  const headingText = `${getReflectionHeading(reflectionType).toLocaleLowerCase()} between ${reviewCycleDateRangeText}`;
  const filteredReflections = useMemo(
    () => {
      const search = searchText.toLowerCase();
      return reflections?.filter((reflection) => reflection.contentTitle.toLowerCase().includes(search));
    },
    [searchText, reflections],
  );

  const orderedReflections = filteredReflections?.sort((a, b) => {
    const aValue = a.isFavorite ? 1 : 0;
    const bValue = b.isFavorite ? 1 : 0;
    return bValue - aValue;
  });

  const [showSkeleton] = useSkeletonLoaders(isLoading);

  const hookProps = {
    onSearch,
    orderedReflections,
    reflectionType,
    reviewTopicUid,
    reviewUid,
    review,
    reviewCycleDateRangeText,
    headingText,
    showSkeleton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
