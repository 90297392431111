import { useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import { Moment } from 'moment-timezone';
import { RecurrenceDropdownOptions } from '~ActionItems/const/constants';
import DrawerInput from '~Common/V3/components/DrawerInput';
import { ActionItemRecurrenceFrequencyParams, MonthlyRecurrence, useActionItemRecurrenceFrequency } from '~ActionItems/hooks/useActionItemRecurrenceFrequency';
import { palette } from '~Common/styles/colors';
import { SelectChangeEvent } from '@mui/material/Select';
import { forMobileObject } from '~Common/styles/mixins';
import { convertRecurrenceRules } from '~ActionItems/functions/convertRecurrenceRules';
import RecurrenceFrequencyDropdown from './RecurrenceFrequencyDropDown';
import MonthlyRecurrenceDropdown from './MonthlyRecurrenceDropdown';
import { ActionItemWeekdaySelector } from './ActionItemWeekdaySelector';

function isValid({
  dueDate,
  selectedDays,
  selectedRecurrence,
  recurrenceInterval,
}: ActionItemRecurrenceFrequencyParams): boolean {
  if (!dueDate) {
    return false;
  }

  if (selectedRecurrence === RecurrenceDropdownOptions.Weekly && !selectedDays.length) {
    return false;
  }

  if (!recurrenceInterval || recurrenceInterval < 0) {
    return false;
  }

  return true;
}

const styles = {
  recurrenceContainer: css({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.5rem',
  }, forMobileObject({
    margin: '0 0 0.5rem',
  })),
  recurrenceText: css({
    fontWeight: '400',
    color: palette.neutrals.gray700,
    marginBottom: '0.25rem',
  }),
  recurrenceInputContainer: css({
    display: 'flex',
    alignItems: 'center',
  }, forMobileObject({
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '0.5rem',
  })),
  recurrenceSetUp: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  }, forMobileObject({
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    width: '100%',
  })),
  recurrenceInput: css({
    width: '3.25rem',
    height: '3.0625rem',
    padding: '0.5rem 0.75rem',
    flexGrow: 0,
    flexShrink: 0,
  }),
  text: css({
    fontWeight: '400',
    color: palette.neutrals.gray700,
    margin: '0 1rem 0 2.5rem',
    justifyContent: 'flex-end',
  }, forMobileObject({
    margin: 0,
    marginTop: '0.5rem',
    flexBasis: '100%',
  })),
};
interface ActionItemRecurrenceViewProps {
  dueDate?: Moment,
  handleMonthlyRecurrenceChange: (event: SelectChangeEvent) => void,
  handleRecurrenceIntervalChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleSelectedRecurrenceChange: (event: SelectChangeEvent<RecurrenceDropdownOptions>) => void,
  handleWeekdaySelectorChange: (updatedDays: number[]) => void,
  monthlyRecurrence: MonthlyRecurrence,
  recurrenceInterval?: number,
  recurrenceRule: string,
  selectedRecurrence: RecurrenceDropdownOptions,
  selectedDays: number[],
  disabled?: boolean,
}

const View = ({
  dueDate,
  handleMonthlyRecurrenceChange,
  handleRecurrenceIntervalChange,
  handleSelectedRecurrenceChange,
  handleWeekdaySelectorChange,
  monthlyRecurrence,
  recurrenceInterval,
  recurrenceRule,
  selectedRecurrence,
  selectedDays,
  disabled,
}: ActionItemRecurrenceViewProps): JSX.Element => (
  <div css={styles.recurrenceContainer}>
    <div css={styles.recurrenceText}>
      Repeats every
    </div>

    <div css={styles.recurrenceInputContainer}>
      <div css={styles.recurrenceSetUp}>
        <input
          type="hidden"
          name="recurrenceRule"
          value={recurrenceRule}
        />
        <DrawerInput
          css={styles.recurrenceInput}
          value={recurrenceInterval}
          onChange={handleRecurrenceIntervalChange}
          type="number"
          name="recurrenceInterval"
          showRequiredIndicator={false}
          min={1}
          data-test-id="actionItemRecurrenceInterval"
          disabled={disabled}
          required
        />
        <RecurrenceFrequencyDropdown
          interval={recurrenceInterval}
          value={selectedRecurrence}
          onChange={handleSelectedRecurrenceChange}
          name="recurrenceFrequency"
          data-test-id="actionItemRecurrenceDropdown"
          disabled={disabled}
        />
        <p css={styles.text}>
          On
        </p>
      </div>

      {selectedRecurrence === RecurrenceDropdownOptions.Monthly && dueDate && (
        <MonthlyRecurrenceDropdown
          dueDate={dueDate}
          value={monthlyRecurrence}
          onChange={handleMonthlyRecurrenceChange}
          data-test-id="actionItemMonthlyRecurrence"
          disabled={disabled}
        />
      )}
      {selectedRecurrence === RecurrenceDropdownOptions.Weekly && (
        <ActionItemWeekdaySelector
          selectedDays={selectedDays}
          onChange={handleWeekdaySelectorChange}
        />
      )}
    </div>
  </div>
);

interface ActionItemRecurrenceProps {
  initialRecurrenceRule?: string,
  dueDate?: Moment,
  onValidityChange: (newValidity: boolean) => void,
  disabled?: boolean,
}

export const ActionItemRecurrence = ({
  initialRecurrenceRule = '',
  dueDate,
  onValidityChange,
  disabled,
}: ActionItemRecurrenceProps): JSX.Element => {
  const { getActionItemRecurrenceFrequency } = useActionItemRecurrenceFrequency();
  const [recurrenceRule, setRecurrenceRule] = useState(initialRecurrenceRule);

  const recurrenceOptions = useMemo(() => convertRecurrenceRules({
    recurrenceRule,
  }), [recurrenceRule]);

  const onChange = (options: Partial<ActionItemRecurrenceFrequencyParams>): void => {
    if (!dueDate) {
      return;
    }

    const combinedOptions = {
      dueDate,
      ...recurrenceOptions,
      ...options,
    };

    try {
      const rrule = getActionItemRecurrenceFrequency(combinedOptions);
      if (rrule) {
        setRecurrenceRule(rrule.toString());
        const newValidity = isValid(combinedOptions);
        onValidityChange(newValidity);
      } else {
        onValidityChange(false);
      }
    } catch (e) {
      onValidityChange(false);
    }
  };

  useEffect(() => {
    if (!dueDate) {
      return;
    }

    onChange(recurrenceOptions);

    const newValidity = isValid({
      dueDate,
      ...recurrenceOptions,
    });
    onValidityChange(newValidity);
    // First render only.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedRecurrenceChange = (event: SelectChangeEvent): void => {
    const newRecurrence = event.target.value as RecurrenceDropdownOptions;

    onChange({
      selectedRecurrence: newRecurrence,
      selectedDays: undefined,
    });
  };

  const handleMonthlyRecurrenceChange = (event: SelectChangeEvent): void => {
    onChange({
      monthlyRecurrence: event.target.value as MonthlyRecurrence,
    });
  };

  const handleRecurrenceIntervalChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let newInterval;
    if (event.target.value) {
      const parsed = parseInt(event.target.value, 10);
      newInterval = parsed;
    }

    onChange({
      recurrenceInterval: newInterval,
    });
  };

  const handleWeekdaySelectorChange = (updatedDays: number[]): void => {
    onChange({
      selectedDays: updatedDays ?? [],
    });
  };

  const {
    monthlyRecurrence,
    recurrenceInterval,
    selectedDays,
    selectedRecurrence,
  } = recurrenceOptions;

  useEffect(() => {
    if (
      dueDate
      && selectedRecurrence === RecurrenceDropdownOptions.Weekly
      && (!selectedDays || !selectedDays.length)
    ) {
      const converted = (dueDate.day() + 6) % 7;

      onChange({
        // We have to convert to RRule's indexing
        selectedDays: [converted],
        ...(!recurrenceInterval && { recurrenceInterval: 1 }),
      });
    }

    // Even if the selectedRecurrence didn't change but the DueDate did, we need to re-trigger the
    // recurrence rule calculation.
    if (dueDate && selectedRecurrence === RecurrenceDropdownOptions.Monthly) {
      onChange({
        selectedRecurrence: RecurrenceDropdownOptions.Monthly,
      });
    }

    // Don't want to update on selectedDays or interval
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDate, selectedRecurrence]);

  const hookProps = {
    dueDate,
    handleMonthlyRecurrenceChange,
    handleRecurrenceIntervalChange,
    handleSelectedRecurrenceChange,
    handleWeekdaySelectorChange,
    monthlyRecurrence,
    recurrenceInterval,
    recurrenceRule,
    selectedDays,
    selectedRecurrence,
    disabled,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default ActionItemRecurrence;
