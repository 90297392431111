import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import CascadingToggleButton from './CascadingToggleButton';
import GoalContextTypeDropdown, { GoalContextTypeDropdownProps } from './GoalContextTypeDropdown';

const items = [
  {
    value: '',
    text: 'All Goals',
  },
  {
    value: Goals.GoalContextType.Personal,
    text: 'Personal Goals',
  },
  {
    value: Goals.GoalContextType.Team,
    text: 'Team Goals',
  },
  {
    value: Goals.GoalContextType.Organization,
    text: 'Organization Goals',
  },
];

const styles = {
  filters: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

interface FiltersProps extends Pick<GoalContextTypeDropdownProps, 'selectedContextType' | 'onContextTypeChange'> {
  showCascadingButton: boolean,
}

const Filters = ({
  selectedContextType,
  onContextTypeChange,
  showCascadingButton,
  ...props
}: FiltersProps): JSX.Element => (
  <div
    css={styles.filters}
    {...props}
  >
    <GoalContextTypeDropdown
      items={items}
      selectedContextType={selectedContextType}
      onContextTypeChange={onContextTypeChange}
      renderValue={(value) => (<span>{value === '' ? 'All' : `${value}`}</span>)}
    />
    {showCascadingButton && (
      <CascadingToggleButton />
    )}
  </div>
);

export default Filters;
