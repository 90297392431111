import moment from 'moment-timezone';
import { css } from '@emotion/react';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import { GaugeChart } from '~Common/components/Charts/GaugeChart';
import { CARD_STYLES, DASHBOARD_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import { useIsMobileQuery, useIsTabletQuery } from '~Common/hooks/useMediaListener';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { useENPSDataForDateRange } from '~Insights/hooks/useENPSData';
import { useInsightsUserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import Tooltip from '~Common/components/Tooltip';
import { getOrganizationName } from '~Common/utils/localStorage';
import { SetDisplay } from '~Insights/const/types';
import { defualtMonthsToSubtract } from '~Insights/const/defaults';
import InsightCardTitle from '../InsightCardTitle';

export const styles = {
  dashboard: () => css({
    ...CARD_STYLES.dashboard,
  }),
  chartContainer: css({
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1.75rem',
  }),
  overallScore: css({
    lineHeight: '2rem',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    fontSize: '1.75rem',
  }),
  label: css({
    fontSize: '1rem',
    color: palette.neutrals.gray600,
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
  }),
  footer: css({
    alignItems: 'center',
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '.6875rem',
    fontWeight: 400,
    justifyContent: 'space-between',
    paddingTop: '1rem',
  }),
  footerIcon: css({
    color: palette.neutrals.gray700,
  }),
  ...DASHBOARD_CARD_COMPONENTS,
};

interface DashboardProps {
  score: number,
  toolTip: JSX.Element,
  className?: string,
}

const DashboardView = ({
  score,
  toolTip,
  className,
}: DashboardProps): JSX.Element => (
  <Card
    className={className}
    css={styles.dashboard}
    renderContents={() => (
      <>
        <InsightCardTitle>eNPS</InsightCardTitle>
        <div css={styles.chartContainer}>
          <GaugeChart
            height="175px"
            width="175px"
            isSmall
            value={score}
          />
          <div css={styles.overallScore}>
            {score ?? 'N/A'}
          </div>
          <div css={styles.label}>
            eNPS
          </div>
        </div>
        <div css={styles.footer}>
          <p>*Based on most recent eNPS</p>
          <Tooltip content={toolTip}>
            <div><FontAwesomeIcon css={styles.footerIcon} icon={faCircleInfo} /></div>
          </Tooltip>

        </div>
      </>
    )}
  />
);

interface ENPSSmallCardProps {
  size: 'tiny' | 'dashboard'
}

const ENPSSmallCard = ({
  size,
  ...props
}: ENPSSmallCardProps): JSX.Element => {
  const [setDisplay] = useQueryParamState<SetDisplay>('insights', 'setDisplay', SetDisplay.Desktop);
  const { userPerspective } = useInsightsUserPerspective();
  const checkMobileQuery = useIsMobileQuery();
  const isTablet = useIsTabletQuery();
  const toolTip = (
    <>
      <p>eNPS stands for Employee Net Promoter Score and is used to measure employee statisfaction.</p>
      <br />
      {/* eslint-disable-next-line max-len */}
      <p>
        All employees will recieve one question, &quot;How likely are you to recommend
        {' '}
        {getOrganizationName()}
        {' '}
        as a place to work?&quot;, which they will answer on a scale from 0 to 10.
      </p>
      <br />
      <p>According to how they answer, each employee will fall into one of these categories:</p>
      <ul>
        <li>Detractors: 0-6</li>
        <li>Passives: 7-8</li>
        <li>Promoters: 9-10</li>
      </ul>
      <p>eNPS is calculated by subtracting the percentage of detractors from the percentage of promoters. Score range from -100 to 100.</p>
    </>
  );
  const { data, isLoading } = useENPSDataForDateRange({
    startDate: moment().subtract(defualtMonthsToSubtract, 'months').startOf('day').toDate(),
    endDate: moment().startOf('day').toDate(),
    userPerspective,
  });
  const lastSurvey = data[data.length - 1];

  const hookProps = {
    isLoading,
    isMobile: setDisplay === SetDisplay.Mobile || checkMobileQuery === true,
    isTablet,
    score: lastSurvey?.scores?.score,
    toolTip,
  };

  if (size === 'dashboard' || size === 'tiny') {
    return (
      <DashboardView
        {...hookProps}
        {...props}
      />
    );
  }

  return <></>;
};

export { DashboardView };
export default ENPSSmallCard;
