import { useGenericStore } from '~Common/hooks/useGenericStore';

export enum RequestSendSurvey {
  ALLOWED = 'allowed',
  NOTALLOWED = 'notallowed',
}

export const useSendSurveyState = (): [RequestSendSurvey, (sendsurvey: RequestSendSurvey) => void] => {
  // TODO: Update this when useGenericStore is typed
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value: sendSurvey, setValue: setSendSurvey } = useGenericStore({
    module: 'leadr',
    workflow: 'surveys',
    feature: 'send-survey',
    defaultValue: RequestSendSurvey.ALLOWED,
  });

  // TODO: Update this when useGenericStore is typed
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [
    sendSurvey,
    setSendSurvey,
  ];
};
