import { css } from '@emotion/react';
import { Checkbox } from '~Common/V3/components/uncontrolled';
import InfoTooltip from './InfoTooltip';

const styles = {
  privateGoalToggle: css({
    display: 'flex',
    alignItems: 'center',

    label: {
      marginBottom: '0 !important',
    },
  }),
};

interface PrivateGoalToggleProps {
  goalTypeSelected: string,
  isPrivate?: boolean,
}

const PrivateGoalToggle = ({
  goalTypeSelected,
  isPrivate,
  ...props
}: PrivateGoalToggleProps): JSX.Element => (
  <div
    css={styles.privateGoalToggle}
    {...props}
  >
    <Checkbox
      name="isPrivate"
      label="Make this a private goal"
      defaultChecked={isPrivate}
      size={20}
      data-test-id="goalsCreateGoalPrivateCheckbox"
    />
    <InfoTooltip goalTypeSelected={goalTypeSelected} />
  </div>
);

export default PrivateGoalToggle;
