import {
  Dispatch, SetStateAction, useMemo, useState,
} from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '~Meetings/components/buttons/IconButton';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { ReviewShape } from '~Reviews/V2/Const/types';
import { useGetReviewById } from '~Reviews/V2/Hooks/useGetReviewById';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { ReflectionTypeEnum } from '../types';
import { AddReflectionDrawerBody } from './AddReflectionDrawerBody';
import { useAddReflectionDrawer } from '../hooks/useAddReflectionDrawer';

export const addReflectionDrawerTemplate = {
  name: 'ADD_REFLECTION',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface ViewProps extends AddReflectionDrawerProps {
  activeTab: ReflectionTypeEnum,
  closeDrawer: () => void,
  setActiveTab: Dispatch<SetStateAction<ReflectionTypeEnum>>,
  attachedSubjectIds: string[],
  review: ReviewShape,
}

export const View = ({
  activeTab,
  closeDrawer,
  reviewTopicUid,
  reviewUid,
  setActiveTab,
  review,
  attachedSubjectIds,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <TabDrawerHeader
        renderCloseButton={(closeButtonStyles) => (
          <IconButton
            css={closeButtonStyles}
            onClick={closeDrawer}
            type="button"
            icon={faTimes}
            tooltip="Close"
            size="large"
          />
        )}
        title="Add Reflection"
        renderDrawerInstructions={(drawerInstructionStyles) => (
          <p css={drawerInstructionStyles}>Attach a reference to work you did during this Review Period.</p>
        )}
        renderTabNav={(tabNavStyles, scroller) => (
          <div css={tabNavStyles} ref={scroller}>
            <TabNavItem
              onClick={() => setActiveTab(ReflectionTypeEnum.Accomplishment)}
              isActive={activeTab === ReflectionTypeEnum.Accomplishment}
              renderNavItem={() => <>Accomplishments</>}
            />
            <TabNavItem
              onClick={() => setActiveTab(ReflectionTypeEnum.BookmarkedTopic)}
              isActive={activeTab === ReflectionTypeEnum.BookmarkedTopic}
              renderNavItem={() => <>Agenda Topics</>}
            />
            <TabNavItem
              onClick={() => setActiveTab(ReflectionTypeEnum.Goal)}
              isActive={activeTab === ReflectionTypeEnum.Goal}
              renderNavItem={() => <>Goals</>}
            />
            <TabNavItem
              onClick={() => setActiveTab(ReflectionTypeEnum.Feedback)}
              isActive={activeTab === ReflectionTypeEnum.Feedback}
              renderNavItem={() => <>Feedback</>}
            />
            <TabNavItem
              onClick={() => setActiveTab(ReflectionTypeEnum.Learning)}
              isActive={activeTab === ReflectionTypeEnum.Learning}
              renderNavItem={() => <>Learning</>}
            />
            <TabNavItem
              onClick={() => setActiveTab(ReflectionTypeEnum.Recognition)}
              isActive={activeTab === ReflectionTypeEnum.Recognition}
              renderNavItem={() => <>Recognition</>}
            />
          </div>
        )}
      />
    )}
    renderBody={(drawerBodyStyles) => (
      <div css={drawerBodyStyles}>
        <AddReflectionDrawerBody
          reflectionType={activeTab}
          reviewUid={reviewUid}
          reviewTopicUid={reviewTopicUid}
          review={review}
          attachedSubjectIds={attachedSubjectIds}
        />
      </div>
    )}
  />
);

export interface AddReflectionDrawerProps {
  reviewUid: string,
  reviewTopicUid: string,
}

const AddReflectionDrawer = ({
  reviewTopicUid,
  reviewUid,
  ...props
}: AddReflectionDrawerProps): JSX.Element => {
  const { closeDrawer } = useAddReflectionDrawer();
  const [activeTab, setActiveTab] = useState(ReflectionTypeEnum.Accomplishment);
  const { data } = useGetReviewById({
    id: reviewUid,
  });
  const review = data?.response as ReviewShape;

  const attachedSubjectIds = useMemo(() => {
    // TODO: This filtering can be removed after the backend handles it https://leadrhq.atlassian.net/browse/LW-15878
    const currentParticipantUID = review.participants.find((participant) => participant.orgUserId === getOrganizationUserId() ?? '')?.uid ?? '';
    const currentTopicReflections = review.topics.find((topic) => topic.uid === reviewTopicUid)?.reflections;
    const currentUserReflections = currentTopicReflections?.filter((reflection) => reflection.participantUid === currentParticipantUID) || [];
    const currentUserReflectionIds = currentUserReflections.map((reflection) => reflection.subjectUid);
    return currentUserReflectionIds;
  }, [review.topics, review.participants, reviewTopicUid]);

  const hookProps = {
    activeTab,
    closeDrawer,
    reviewTopicUid,
    reviewUid,
    setActiveTab,
    review,
    attachedSubjectIds,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: addReflectionDrawerTemplate.name,
  component: AddReflectionDrawer,
});
