import { useCallback, useMemo } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { AssignedLearning, LearningStatus } from '~Learning/const/interfaces';
import { useLearningSearch } from '../utils/useLearningSearch';
import { useSortLearningsAndPlaylists } from '../utils/useSortLearningsAndPlaylists';
import { useGetAssignedLearnings } from './useGetAssignedLearnings';

export interface AssignedLearningSection {
  sectionHeader: string,
  learnings: AssignedLearning[] | undefined,
}

type AssignedLearningByStatus = Omit<UseQueryResult<AssignedLearning[]>, 'data'> & { learningsByStatus: AssignedLearningSection[] };

export const useGetAssignedLearningsByStatus = (): AssignedLearningByStatus => {
  const { data: learnings, ...rest } = useGetAssignedLearnings();

  const [search] = useLearningSearch();

  const searchAssignedLearnings = useCallback((unsearchedLearnings: AssignedLearning[] | undefined): AssignedLearning[] => {
    if (unsearchedLearnings) {
      return [...unsearchedLearnings].filter((assignedLearning) => assignedLearning.title.toLowerCase().includes(search.toLowerCase())) ?? [];
    }

    return [];
  }, [search]);

  const searchedAssignedLearnings = useMemo(() => searchAssignedLearnings(learnings), [learnings, searchAssignedLearnings]);

  const incompleteSearchedLearnings = useMemo(
    () => searchedAssignedLearnings?.filter((learning) => learning.status === LearningStatus.INCOMPLETE),
    [searchedAssignedLearnings],
  );

  const completedSearchedLearnings = useMemo(
    () => searchedAssignedLearnings?.filter((learning) => learning.status === LearningStatus.COMPLETED),
    [searchedAssignedLearnings],
  );

  const sortLearningsAndPlaylists = useSortLearningsAndPlaylists();

  const sortedAndSearchedIncompleteLearnings = sortLearningsAndPlaylists({
    unsortedLearningsAndPlaylists: incompleteSearchedLearnings,
  }) as AssignedLearning[];

  const sortedAndSearchedCompletedLearning = sortLearningsAndPlaylists({
    unsortedLearningsAndPlaylists: completedSearchedLearnings,
    shouldReverse: true,
  }) as AssignedLearning[];

  const learningsByStatus = [
    ...(sortedAndSearchedIncompleteLearnings?.length
      ? [{ sectionHeader: 'Incomplete', learnings: sortedAndSearchedIncompleteLearnings }] : []),
    ...(sortedAndSearchedCompletedLearning?.length
      ? [{ sectionHeader: 'Completed', learnings: sortedAndSearchedCompletedLearning }] : []),
  ];

  return {
    learningsByStatus,
    ...rest,
  };
};
