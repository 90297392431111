import { css } from '@emotion/react';
import moment from 'moment';
import { BASE_ROUTE } from '~Common/const/routes';
import { Link } from 'react-router-dom';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { PAGE_STYLES } from '../../Const/pageStyles';
import {
  ParticipantTypeEnum, ReviewCycleDetail, ReviewCycleStatusEnum,
} from '../../Const/types';
import CompletedProgressText from '../../Shared/CompletedProgressText';
import ReviewCard from '../ReviewCard';
import { useReturnRevieweeReviewId } from '../../Hooks/useReturnRevieweeReviewId';
import CompletedReviewCountText from './CompletedReviewCountText';

const styles = {
  ...PAGE_STYLES,
  cardLink: css({
    '&:hover': {
      textDecoration: 'none',
      scale: '1.03',
      transition: 'all 150ms',
    },
  }),
};

interface ViewProps {
  reviewCycle: ReviewCycleDetail,
  route: string,
  isManager: boolean,
}

const View = ({
  reviewCycle,
  route,
  isManager,
}: ViewProps): JSX.Element => (
  <>
    {isManager && (
      <>
        <Link
          key={`${reviewCycle.uid}-${reviewCycle.uid}`}
          css={styles.cardLink}
          to={route}
        >
          <ReviewCard
            // eslint-disable-next-line max-len
            reviewTitle={`REVIEW PERIOD:  ${moment(reviewCycle.assessmentPeriodStart).format('MMM D, YY')} - ${moment(reviewCycle.assessmentPeriodEnd).format('MMM D, YY')}`}
            title={reviewCycle.name}
            description={reviewCycle.description}
            // If user hasn't submitted, it should use the assessmentDueDate.
            // If they've submitted but not signed off, it should use the signOffDueDate
            dueDate={reviewCycle.assessmentDueDate}
            numberOfUsersAssigned={reviewCycle.reviews[0].participants.length}
            isComplete={reviewCycle.statusEnum === ReviewCycleStatusEnum.Finished}
            renderCompletedSection={() => (
              <>
                {/* Hiding this for now - This will be brought back on the % is updated to reflect the steps on the reviews not the cycle
                <CompletedProgressBar
                  totalCount={reviewCycle.completionPct}
                  completedCount={reviewCycle.completionPct}
                  completedPercentage={reviewCycle.completionPct}
                  renderPercentText={() => (
                    <>{`${reviewCycle.completionPct}%`}</>
                  )}
                /> */}
                <CompletedReviewCountText
                  reviews={reviewCycle.reviews}
                />
              </>
            )}
          />
        </Link>
      </>
    )}
    {!isManager && (
    <>
      <Link
        key={`${reviewCycle.uid}-${reviewCycle.uid}`}
        css={styles.cardLink}
        to={route}
      >
        <ReviewCard
         // eslint-disable-next-line max-len
          reviewTitle={`REVIEW PERIOD:  ${moment(reviewCycle.assessmentPeriodStart).format('MMM D, YY')} - ${moment(reviewCycle.assessmentPeriodEnd).format('MMM D, YY')}`}
          title={reviewCycle.name}
          description={reviewCycle.description}
          // If user hasn't submitted, it should use the assessmentDueDate.
          // If they've submitted but not signed off, it should use the signOffDueDate
          dueDate={reviewCycle.assessmentDueDate}
          assignedUsersInfo={reviewCycle.reviews[0].participants.filter((participant) => (participant.participantTypeEnum !== ParticipantTypeEnum.Reviewee))}
          numberOfUsersAssigned={reviewCycle.reviews[0].participants.length}
          isComplete={reviewCycle.statusEnum === ReviewCycleStatusEnum.Finished}
          renderCompletedSection={() => (
            <CompletedProgressText
              status={reviewCycle.statusEnum}
              renderStatusText={() => reviewCycle.reviews[0].revieweeStatus}
            />
          )}
        />
      </Link>
    </>
    )}
  </>
);

interface DisplayMyReviewCardProps {
  reviewCycle: ReviewCycleDetail,
}

const DisplayMyReviewCard = ({ reviewCycle }: DisplayMyReviewCardProps): JSX.Element => {
  const { revieweeReviewId, isManager } = useReturnRevieweeReviewId(reviewCycle);
  const myReviewRoute = `${BASE_ROUTE}reviews/${revieweeReviewId ?? ''}`;
  const myReviewManagerRoute = `${BASE_ROUTE}reviews/cycle/${reviewCycle.uid ?? ''}`;
  const adminRoute = `${BASE_ROUTE}reviews/cycle/${reviewCycle.uid}`;

  const { isAdmin } = useUserPermissions();

  const managerOnly = isManager && !isAdmin;
  // eslint-disable-next-line no-nested-ternary
  const route = managerOnly ? myReviewManagerRoute : isAdmin ? adminRoute : myReviewRoute;

  const hookProps = {
    reviewCycle,
    route,
    isReviewee: !!revieweeReviewId,
    isManager,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default DisplayMyReviewCard;
