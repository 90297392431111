import { css } from '@emotion/react';
import Collapse from '@mui/material/Collapse';
import {
  GetAgendaTopicTemplateDetails,
  GetCuratedAgendaTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { useShowAgendaSection } from '../../stores/useShowAgendaSection';
import AgendaTopic from '../AgendaTopic';
import EmptyState from './EmptyState';

const styles = {
  sectionCollapseContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.375rem',
    paddingLeft: '.5rem',
  }),
};

interface ViewProps {
  isAgendaSectionExpanded: boolean,
  showEmptyState: boolean,
  agendaTopics: GetAgendaTopicTemplateDetails[] | GetCuratedAgendaTemplateDetails[],
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
}

const View = ({
  isAgendaSectionExpanded,
  showEmptyState,
  agendaTopics,
  addTopicToMeeting,
  ...props
}: ViewProps): JSX.Element => (
  <Collapse in={isAgendaSectionExpanded} {...props}>
    <div css={styles.sectionCollapseContainer}>
      {agendaTopics.map((agendaTopic) => (
        <AgendaTopic
          key={agendaTopic.id}
          agendaTopic={agendaTopic}
          addTopicToMeeting={addTopicToMeeting}
          followsAgendaSection={false}
        />
      ))}
      {showEmptyState && (
        <EmptyState />
      )}
    </div>
  </Collapse>
);

interface CollapseContentProps extends Omit<ViewProps, 'isAgendaSectionExpanded' | 'showEmptyState'> {
  sectionId: string,
}

const CollapseContent = ({
  sectionId,
  agendaTopics,
  ...props
}: CollapseContentProps): JSX.Element => {
  const {
    getIsAgendaSectionExpanded,
  } = useShowAgendaSection((state) => ({
    getIsAgendaSectionExpanded: state.getIsAgendaSectionExpanded,
  }));

  const isAgendaSectionExpanded = getIsAgendaSectionExpanded(sectionId);

  const showEmptyState = agendaTopics.length === 0;

  const hookProps = {
    isAgendaSectionExpanded,
    showEmptyState,
    agendaTopics,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CollapseContent;
