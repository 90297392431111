import cloneDeep from 'lodash/cloneDeep';

import { MEETING_FREQUENCY } from '~Common/const/defaults';
import { getOrganizationId, getOrganizationName, getUserTimezone } from '~Common/utils/localStorage';

const state = () => ({
  auth: {
    login: {
      showLogoutConfirmationModal: false,
      connection: '',
    },
    organizations: {
      organizations: [],
      isLoaded: false,
    },
  },
  user: {
    settings: {
      sendMeetingReminderValue: '',
      sendActionItemReminderType: '',
      sendActionItemReminderValue: '',
      sendMeetingReminderType: '',
      shouldSendActionItemReminder: false,
      shouldSendAssignedActionItemNotification: false,
      shouldSendMeetingReminder: false,
      timezone: getUserTimezone(),
      dismissTimeZoneAlert: false,
      shouldSendTimezoneTrackerNotification: '',
      orgUserSettings: {
        orgUserSettingsId: null,
        enableSummaryEmail: true,
        includeSpecialDaysInSummaryEmailFor: 'EVERYONE',
        timezone: '',
        useSettingsTimezone: false,
      },

    },
    profile: {
      profileImageUrl: '',
      applicationGroupType: '',
      applicationGroupName: '',
      firstName: '',
      lastName: '',
      email: '',
      birthday: '',
      phoneNumber: '',
      jobTitle: '',
      department: '',
      aboutMe: '',
      food: '',
      snack: '',
      drink: '',
      enneagram: '',
      myersBriggs: '',
      disc: '',
      strengths: '',
      instagram: '',
      linkedIn: '',
      twitter: '',
      selectedOrganizationId: getOrganizationId(),
      selectedOrganizationName: getOrganizationName(),
    },
  },
  error: {
    message: '',
    type: 'error',
  },
  loaderState: [],
});

function getInitialState() {
  return cloneDeep(state());
}

export default getInitialState;
