import { createAction } from '@reduxjs/toolkit';

import { registerAction } from '~Deprecated/reducers/featureFlags';
import { mutateState } from '~Deprecated/utils/reduxUtils';

export const setIsFullyInitializedAction = createAction('LEADR.SET_IS_FULLY_INITIALIZED');

const setIsFullyInitialized = (state, action) => mutateState(state, (draftState) => {
  draftState.isFullyInitialized = action.payload;

  return draftState;
});

registerAction(setIsFullyInitializedAction.toString(), setIsFullyInitialized);
