import { css } from '@emotion/react';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useGetLandingPageUrl } from '~Common/hooks/useGetHomePageUrl';
import { palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';
import LeadrShieldIndigo from '~Assets/images/leadrShieldIndigo.svg';
import LeadrLogoIndigo from '~Assets/images/leadrLogoIndigo.svg';
import LeadrAdvantageLogo from '~Root/assets/images/leadrAdvantageLogo.svg';
import LeadrAdvantageIconLogo from '~Root/assets/images/leadrAdvantageIconLogo.svg';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { NAVBAR_LEFT_PADDING } from '~Root/components/OldNavigationSidebar/types';
import { useNavigationStore } from '~Root/components/OldNavigationSidebar/useNavigationStore';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  closeButton: css({
    color: palette.neutrals.gray700,
  }),
  divider: css({
    backgroundColor: palette.neutrals.gray200,
    height: '1px',
    margin: `0rem ${NAVBAR_LEFT_PADDING} !important`,
  }),
  logoContainer: (isCollapsed: boolean) => css({
    alignItems: 'center',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: NAVBAR_LEFT_PADDING,
    marginRight: '1rem',
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
  }, isCollapsed && {
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  logo: css({
    height: '2rem',
  }),
  // Even though these are currently the same, it's nice to have the separation in case we use different images or need to update one
  partnerLogo: (isCollapsed: boolean) => css({
    height: '2.5rem',
  }, isCollapsed && {
    height: '2.5rem',
  }),
  partnerContent: (isCollapsed: boolean) => css({
    margin: '-0.5rem 0 0 0',
    fontStyle: 'italic',
    fontWeight: 600,
    color: palette.neutrals.gray800,

    ':hover': {
      textDecoration: 'none',
    },
  }, isCollapsed && {
    display: 'none',
  }),
  skeleton: css({
    height: '2rem',
    maxWidth: '100%',
    width: '100%',
  }),
  logosContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  partnerWrap: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
};

interface ViewProps extends NavigationHeaderProps {
  closeMobileNavigation: () => void,
  landingPageUrl: string,
  canViewTableGroup: boolean,
}

const View = ({
  closeMobileNavigation,
  landingPageUrl,
  isCollapsed,
  isMobile,
  canViewTableGroup,
}: ViewProps): JSX.Element => (
  <section css={styles.logoContainer(isCollapsed)}>
    <Link to={landingPageUrl}>
      {canViewTableGroup && (
        <>
          <div
            css={styles.logosContainer}
          >
            {isCollapsed && (
              <LeadrAdvantageIconLogo css={[styles.logo, styles.partnerContent(true)]} title="The Table Group logo" data-test-id="leadrAdvantageIconLogo" />
            )}
            {!isCollapsed && (
              <LeadrAdvantageLogo css={[styles.logo, styles.partnerLogo(false)]} title="The Table Group logo" data-test-id="leadrAdvantageLogo" />
            )}
          </div>
        </>
      )}
      {!canViewTableGroup && (
        <>
          {isCollapsed && (
            <LeadrShieldIndigo css={styles.logo} title="Leadr logo" data-test-id="leadrShieldIndigo" />
          )}
          {!isCollapsed && (
            <LeadrLogoIndigo css={styles.logo} title="Leadr logo" data-test-id="leadrLogoIndigo" />
          )}
        </>
      )}
    </Link>

    {isMobile && (
      <Button
        variant="text"
        css={styles.closeButton}
        renderContents={() => <FontAwesomeIcon icon={faClose} size="2x" />}
        onClick={closeMobileNavigation}
      />
    )}
  </section>
);

interface NavigationHeaderProps {
  isCollapsed: boolean,
  isMobile: boolean,
}

export const NavigationHeader = ({
  isCollapsed,
  isMobile,
}: NavigationHeaderProps): JSX.Element => {
  const landingPageUrl = useGetLandingPageUrl();
  const closeMobileNavigation = useNavigationStore((state) => state.closeMobileNavigation);

  const isTableGroupCobrandingEnabled = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const { enableTheTable } = useOrgDetailsContext();
  const canViewTableGroup = enableTheTable && isTableGroupCobrandingEnabled;

  const hookProps = {
    closeMobileNavigation,
    isCollapsed: isCollapsed && !isMobile,
    isMobile,
    landingPageUrl,
    canViewTableGroup,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
