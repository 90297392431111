import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';
import { noop } from '~Deprecated/utils';
import ViewCard from '~Common/components/Cards/ViewCard';
import { palette } from '~Common/styles/colors';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  cardBody: css``,
  title: css`
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1em;
  `,
  description: css`
    margin-bottom: 1em;
    color: ${palette.neutrals.gray700};
    font-weight: 400;
  `,
};

const View = ({
  question, description, renderResponseContent, ...props
}) => (
  <ViewCard
    renderBody={() => (
      <div css={styles.cardBody}>
        <div css={styles.title}>{question}</div>
        <HTMLRenderer css={styles.description} htmlText={description} />
        {renderResponseContent()}
      </div>
    )}
    {...props}
  />
);

View.propTypes = {
  renderResponseContent: PropTypes.func,
  question: PropTypes.string,
  description: PropTypes.string,
};

View.defaultProps = {
  renderResponseContent: noop,
  question: '',
  description: '',
};

const ViewSurveyQuestionCard = ({ question, ...props }) => {
  const hookProps = {
    ...question,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ViewSurveyQuestionCard.propTypes = {
  question: SURVEY_QUESTION_SHAPE.isRequired,
};

ViewSurveyQuestionCard.defaultProps = {};

export { View };
export default ViewSurveyQuestionCard;
