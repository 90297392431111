import { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { SerializedStyles } from '@emotion/react';
import {
  REVIEWS, ADMIN_REVIEWS_V2, ADMIN_REVIEWS_REFLECTIONS_V2,
} from '~Common/const/routes';
import { ViewPerspective } from '~Reviews/V2/Const/types';

import { navigateAction } from '~Deprecated/actions/navigate';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';

interface ViewProps {
  onChange: (event: SyntheticEvent, option: string) => void,
  tabItems: LeadrToggleTextTabProps[],
  value: string,
  viewPerspective: string,
  toggleTabStyles: SerializedStyles,
}

const View = ({
  tabItems,
  toggleTabStyles,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs {...props}>
    {tabItems.map((tabItem) => (
      <LeadrToggleTabs.TextTab
        css={toggleTabStyles}
        data-test-id={tabItem['data-test-id']}
        key={tabItem.value}
        text={tabItem.text}
        value={tabItem.value}
      />
    ))}
  </LeadrToggleTabs>
);

interface AdminReviewsTabNavigationProps extends Omit<ViewProps, 'onChange' | 'tabItems' | 'value'> {
  viewPerspective: ViewPerspective,
}

const AdminReviewsTabNavigation = ({
  viewPerspective,
  ...props
}: AdminReviewsTabNavigationProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isAdmin } = useUserPermissions();

  const tabItems = [
    {
      text: 'My Reviews',
      value: REVIEWS,
      viewPerspective: ViewPerspective.MyReviews,
      'data-test-id': 'reviewsMyReviews',
    },
    {
      text: 'Reflections',
      value: ADMIN_REVIEWS_REFLECTIONS_V2,
      viewPerspective: ViewPerspective.Reflections,
      'data-test-id': 'reviewsReflections',
    },
    ...(isAdmin ? [{
      text: 'Admin',
      value: ADMIN_REVIEWS_V2,
      viewPerspective: ViewPerspective.Admin,
      'data-test-id': 'reviewsAdmin',
    }] : []),
  ];

  const value = tabItems.find((option) => option.viewPerspective === viewPerspective)?.value ?? REVIEWS;

  const onChange = (e: SyntheticEvent, route: string): void => {
    if (route) {
      dispatch(navigateAction({ pathname: route }));
    }
  };

  const hookProps = {
    onChange,
    tabItems,
    value,
    viewPerspective,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AdminReviewsTabNavigation;
