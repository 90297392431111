import { useState } from 'react';
import {
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import {
  REVIEW_SETUP_LAYOUT, BUTTON_STYLES,
} from '~Reviews/V2/Const/pageStyles';
import { useDispatch } from 'react-redux';
import { navigateAction } from '~Deprecated/actions/navigate';
import { ADMIN_REVIEWS_BY_CYCLE, REVIEWS_CONTINUE_CREATING_CYCLE } from '~Common/const/routes';

import { noop } from 'lodash';
import { DEFAULT_TOPIC } from '../Const/defaults';
import {
  CycleShape,
  TopicReturnById, TopicShape, ViewCyclePerspective,
} from '../Const/types';
import { ContextButtons } from '../Shared/ContextButtons';
import CycleHeader from '../Shared/CycleHeader';
import QuestionPreview from '../Shared/QuestionPreview';
import { usePublishCycle } from '../Hooks/usePublishCycle';
import { useGetTopicForCycle } from '../Hooks/useGetTopics';
import { createTopicMatrixOrgsFormResolver, FormValuesMatrixOrg } from '../schemata/CreateTopicForCycleMatrixOrgs';
import { FormValues } from '../schemata/CreateTopicForCycle';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...BUTTON_STYLES,
};
interface ViewProps {
  topics: TopicShape[] | undefined,
  viewCyclePerspective: ViewCyclePerspective,
  handleClick: (action: ViewCyclePerspective) => void,
  formContext: UseFormReturn<FormValues | FormValuesMatrixOrg>,
  onPublishCycle: () => void,
  cycle: CycleShape,
  cycleId: string,
  setEditQuestion: (topic: string) => void,
  editQuestion: string,
}

const View = ({
  topics,
  viewCyclePerspective,
  handleClick,
  formContext,
  onPublishCycle,
  cycle,
  cycleId,
  setEditQuestion,
  editQuestion,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="contextButtons"
      renderContents={() => (
        <>
          <LeadrButton
            css={styles.outlineButton}
            variant="ghost"
            onClick={() => { handleClick(ViewCyclePerspective.Add_Participants); }}
            data-test-id="reviewsBackToAddParticipants"
          >
            Previous Step
          </LeadrButton>
          <LeadrButton
            onClick={onPublishCycle}
            data-test-id="reviewsPublishCycle"
          >
            Publish Review Cycle
          </LeadrButton>
        </>
      )}
    />
    <CycleHeader
      cycleTitle={cycle?.name}
      cycleDecriptionHTML={cycle?.description}
      assesmentPeriodEnd={cycle.assessmentPeriodEnd}
      assesmentPeriodStart={cycle.assessmentPeriodStart}
      reviewDueBy={cycle.assessmentDueDate}
      signOffDueBy={cycle.signOffDueDate}
      type={cycle.typeEnum}
      viewCyclePerspective={viewCyclePerspective}
    />
    <div css={[styles.setupContainer, styles.boxShadow]}>
      <QuestionPreview
        topics={topics}
        formContext={formContext}
        isQuestions={false}
        cycleId={cycleId}
        cycle={cycle}
        onFormSubmit={noop}
        updateQuestionView={noop}
        setEditQuestion={setEditQuestion}
        editQuestion={editQuestion}
      />
    </div>
  </>
);
interface PreviewSectionProps {
  viewCyclePerspective: ViewCyclePerspective,
  cycleId: string,
  cycle: CycleShape,
}
const PreviewSection = ({
  viewCyclePerspective,
  cycleId,
  cycle,
}: PreviewSectionProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleClick = (perspective: ViewCyclePerspective): void => {
    dispatch(navigateAction({
      pathname: `${REVIEWS_CONTINUE_CREATING_CYCLE}/${perspective}`,
      params: {
        cycleId,
      },
    }));
  };

  function defaultValues(): FormValuesMatrixOrg {
    const {
      text,
      typeEnum,
      rank,
    } = DEFAULT_TOPIC;
    return {
      text,
      rank,
      typeEnum,
      topicIsForReviewers: false,
      topicIsForMentors: false,
      topicIsForReviewees: false,
      description: '',
      multiChoiceConfig: [],
      linearConfig: { minLabel: 'Worst', maxLabel: 'Best', numScale: 5 },
    };
  }
  const formContext = useForm<FormValues | FormValuesMatrixOrg>({
    defaultValues: defaultValues(),
    resolver: createTopicMatrixOrgsFormResolver,
  });

  const onSuccess = (): void => {
    dispatch(navigateAction({
      pathname: ADMIN_REVIEWS_BY_CYCLE,
      params: {
        cycleId,
      },
    }));
  };

  const publishMutation = usePublishCycle({ cycleId });
  const onPublishCycle = (): void => {
    publishMutation({ id: cycleId }, { onSuccess });
  };

  const [editQuestion, setEditQuestion] = useState('');
  const { data: topics }: TopicReturnById = useGetTopicForCycle({ cycleId });

  const hookProps = {
    topics,
    viewCyclePerspective,
    handleClick,
    formContext,
    onPublishCycle,
    cycle,
    cycleId,
    setEditQuestion,
    editQuestion,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, PreviewSection };
export default PreviewSection;
