import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';

export enum CloudAttachmentFileSource {
  GoogleDrive = 'GoogleDrive',
  OneDrive = 'OneDrive',
}

export interface CloudAttachment {
  uri: string,
  fileSource: CloudAttachmentFileSource,
  fileName: string
}

export interface ConfirmAttachmentsRequest {
  uploadedAttachmentIds?: number[],
  cloudAttachments?: CloudAttachment[],
}

export interface ConfirmAttachmentsProps extends ConfirmAttachmentsRequest {
  organizationId: string,
  huddleId: string,
  topicId: string,
}

const confirmAttachments = async ({
  organizationId,
  huddleId,
  topicId,
  uploadedAttachmentIds,
  cloudAttachments,
}: ConfirmAttachmentsProps): Promise<HttpCallReturn<null>> => {
  const url = {
    host: getHost(hosts.action, '2'),
    uri: `/organizations/${organizationId}/huddles/${huddleId}/agendas/${topicId}/confirmAttachments`,
  };

  const request: ConfirmAttachmentsRequest = {};

  if (uploadedAttachmentIds?.length) {
    request.uploadedAttachmentIds = uploadedAttachmentIds;
  }

  if (cloudAttachments?.length) {
    request.cloudAttachments = cloudAttachments;
  }

  return postApi(url, request);
};

export interface UseConfirmAttachmentsProps extends UseMutationOptions<HttpCallReturn<null>, unknown, ConfirmAttachmentsRequest> {
  huddleId: string,
  topicId: string,
}

export const useConfirmAttachments = ({
  huddleId,
  topicId,
  ...options
}: UseConfirmAttachmentsProps): UseMutationResult<HttpCallReturn<null>, unknown, ConfirmAttachmentsRequest> => {
  const organizationId = getOrganizationId();

  return useMutation({
    mutationFn: (params: ConfirmAttachmentsRequest) => confirmAttachments({
      organizationId: organizationId!,
      huddleId,
      topicId,
      ...params,
    }),
    ...options,
  });
};
