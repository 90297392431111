import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { drawerCollapseIconColor, palette } from '~Common/styles/colors';
import { withoutMobile, withTruncate } from '~Common/styles/mixins';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import {
  registerDrawer,
  templateType,
} from '~Deprecated/ui/views/DrawerManager';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { TEAM_DRAWER_TEAM_SHAPE, USER_GROUP_SHAPE } from '~Common/const/proptypes';
import Card from '~Common/V3/components/Card';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import TeamUserSection from './TeamUserSection';

const styles = {
  layout: css`
    & > div {
      padding: unset;
    }
  `,
  drawerContainer: css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 1.25rem 1.5rem 1.5rem 1.5rem;
  `,
  collapseButton: css`
    font-size: 1rem;
    color: ${drawerCollapseIconColor};
    ${withoutMobile(`
      display: none;
    `)}
  `,
  Avatar: css`
    padding: 0.625rem;
    width: 100%;
  `,
  groupDescription: css`
    letter-spacing: 0.2em;
    font-size: 0.625rem;
    color: ${palette.neutrals.gray500};
  `,
  teamName: css`
    font-weight: 500;
    font-size: 1.125rem;
    color: ${palette.neutrals.gray900};
  `,
  container: css`
    width: 20rem;
    overflow: hidden;
    ${withTruncate()};
  `,
  teamNameDepartment: css`
    color: ${palette.neutrals.gray700};
  `,
  groupName: css`
    padding: 0.625rem 0rem 0rem 0.625rem;
    color: ${palette.neutrals.gray500};
  `,
  attendeeItem: css`
    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  `,
  skeletonLoaderWrap: css`
    width: 100%;
  `,
  skeletonLoader: css`
    max-width: 100%;
    height: 5rem;
    width:100%;
  `,
};

export const teamDetailDrawerTemplate = {
  name: 'BASEBALL_CARD_TEAM_DETAIL_DRAWER',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};

function View({
  renderButtons, title, closeButton, group, isReadOnly, isLoading, webAppTeamsV2,
}) {
  return (
    <DrawerLayout
      css={styles.layout}
      renderHeader={() => (
        <DrawerHeader
          title={title}
          renderButtons={renderButtons}
          renderCloseButton={closeButton}
        />
      )}
      renderBody={() => (
        <div css={styles.drawerContainer}>
          <Card
            renderContents={() => (
              <div css={styles.container}>
                <div css={styles.groupDescription}>{webAppTeamsV2 ? 'TEAM DESCRIPTION ' : 'GROUP DESCRIPTION'}</div>
                <div css={styles.teamName}>{group.name}</div>
                <div css={styles.teamNameDepartment}>{group.description}</div>
              </div>
            )}
          />
          {webAppTeamsV2 && (
            <TeamUserSection
              sectionName="TEAM OWNER"
              orgUserIdList={group.ownerOrgUserIds}
              isReadOnly={isReadOnly}
              isLoading={isLoading}
            />
          )}
          <TeamUserSection
            sectionName={webAppTeamsV2 ? 'TEAM MEMBER ' : 'GROUP MEMBER'}
            orgUserIdList={group.memberOrgUserIds}
            isReadOnly={isReadOnly}
            isLoading={isLoading}
          />
        </div>
      )}
    />
  );
}

View.propTypes = {
  renderButtons: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  closeButton: PropTypes.func.isRequired,
  group: USER_GROUP_SHAPE,
  isReadOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  webAppTeamsV2: PropTypes.bool,
};

View.defaultProps = {
  group: {},
  isReadOnly: true,
  isLoading: false,
  webAppTeamsV2: false,
};

const TeamDetailDrawer = ({ popDrawer, team, ...props }) => {
  const dispatch = useDispatch();
  const closeDrawerClick = () => {
    dispatch(popDrawer({ drawerName: teamDetailDrawerTemplate.name }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
    />
  );

  const { teamId } = team;
  const { data: teamDetails, isLoading } = useGetTeamDetails({ teamId });

  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');
  const hookProps = {
    group: teamDetails?.response,
    title: webAppTeamsV2 ? 'Team Details' : 'Group Details',
    closeButton,
    isLoading,
    webAppTeamsV2,
  };

  return <View {...hookProps} {...props} />;
};

TeamDetailDrawer.propTypes = {
  popDrawer: PropTypes.func.isRequired,
  team: TEAM_DRAWER_TEAM_SHAPE.isRequired,
  isAttendeeSelected: PropTypes.func,
};

TeamDetailDrawer.defaultProps = {
  isAttendeeSelected: null,
};

registerDrawer({
  templateName: teamDetailDrawerTemplate.name,
  component: TeamDetailDrawer,
});

export { View };
export default TeamDetailDrawer;
