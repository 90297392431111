import { useCallback, useEffect } from 'react';
import {
  useLocalStore, setLocalStore, createQueryKey,
} from '~Common/hooks/useGenericStore';

export const useTabs = ({ module, workflow, defaultValue }) => {
  const activeTabKey = createQueryKey({ module, workflow, feature: 'activeTab' });
  const nextTabKey = createQueryKey({ module, workflow, feature: 'nextTab' });
  const activeTab = useLocalStore({ key: activeTabKey, defaultValue: defaultValue ?? 0 });
  const nextTab = useLocalStore({ key: nextTabKey, defaultValue: defaultValue ?? 0 });

  const setActiveTab = useCallback((newTab) => {
    setLocalStore({ key: activeTabKey, value: newTab });
  }, [activeTabKey]);

  const setNextTab = useCallback((next) => {
    setLocalStore({ key: nextTabKey, value: next });
  }, [nextTabKey]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => () => {
    setActiveTab(defaultValue);
  }, [defaultValue]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    activeTab,
    setActiveTab,
    nextTab,
    setNextTab,
  };
};
