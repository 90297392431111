import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';

const emptyTimezonesObject = {};

export const getUsTimezones = (): Promise<HttpCallReturn<Record<string, string>>> => {
  const serverUrl = '/timezones/list/us';
  return getApi(serverUrl);
};

export const getAllTimezones = (): Promise<HttpCallReturn<Record<string, string>>> => {
  const serverUrl = '/timezones/list/all';
  return getApi(serverUrl);
};

interface UseTimezonesListReturn {
  isLoading: boolean,
  usTimezones: Record<string, string>,
  otherTimezones: Record<string, string>,
}

export const useTimezonesList = (): UseTimezonesListReturn => {
  const usTimezonesDetail = useQuery({
    queryKey: ['timezones', 'us'],
    queryFn: getUsTimezones,
    staleTime: Infinity,
  });

  const allTimezonesDetail = useQuery({
    queryKey: ['timezones', 'all'],
    queryFn: getAllTimezones,
    staleTime: Infinity,
  });

  const usTimezones = usTimezonesDetail?.data?.response ?? emptyTimezonesObject;
  const allTimezones = allTimezonesDetail?.data?.response ?? emptyTimezonesObject;

  const otherTimezones = useMemo(() => (
    Object.fromEntries(Object.entries(allTimezones).filter(([key]) => !usTimezones[key]))
  ), [usTimezones, allTimezones]);

  return {
    isLoading: usTimezonesDetail?.isLoading || allTimezonesDetail?.isLoading,
    usTimezones,
    otherTimezones,
  };
};
