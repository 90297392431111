import { Browser } from '@capacitor/browser';
import { IS_MOBILE_APP } from './isMobileApp';

export function logoutFromAuth0(): void {
  const auth0Domain = process.env.AUTH0_URL ?? 'leadrapp.auth0.com';
  const redirectUri = IS_MOBILE_APP ? 'com.leadr.app:///logout' : `${window.location.origin}/logout`;
  const baseUrl = `https://${auth0Domain}/v2/logout`;

  const params = new URLSearchParams({
    client_id: process.env.AUTH0_CLIENT_ID ?? '',
    returnTo: redirectUri,
  });

  const fullUrl = `${baseUrl}/?${params.toString()}`;

  if (IS_MOBILE_APP) {
    void Browser.open({
      presentationStyle: 'popover',
      url: fullUrl,
    });
  } else {
    window.location.replace(fullUrl);
  }
}
