import { ComponentProps } from 'react';
import emptyLearningsHTML from '~Learning/assets/images/emptyLearningsHTML.png';

const EmptyContent = ({
  ...props
}: ComponentProps<'img'>): JSX.Element => (
  <>
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
    <img src={emptyLearningsHTML} alt="No Preview Available" {...props} />
  </>

);

export default EmptyContent;
