import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useFeedbackById } from '~Deprecated/hooks/feedback/useFeedback';
import { ViewType } from '~Common/const/interfaces';
import CompletedCountText from '~Common/V3/components/TextUtils/CompletedCountText';
import GridFeedbackCard from './Grid/GridFeedbackCard';
import ListFeedbackCard from './List/ListFeedbackCard';

const styles = {
  gridFeedbackCard: css({
    width: '100%',
  }),
};

const FeedbackCard = ({
  onItemClick, id, isReceivedFeedback, viewType, ...props
}) => {
  const { feedback } = useFeedbackById({ id });

  const onFeedbackClick = () => onItemClick(id);

  const numberOfFeedbacks = feedback.assignees.length;

  if (!feedback) return null;
  return (
    <>
      {viewType === ViewType.GRID && (
        <>
          {isReceivedFeedback && (
            <GridFeedbackCard
              css={styles.gridFeedbackCard}
              onCardClick={onFeedbackClick}
              title={feedback.title}
              description={feedback.introduction}
              dueDate={feedback.dueDate}
              isComplete={feedback.isCompletedByLoggedInUser}
              assignedUsersInfo={[feedback.owner]}
              numberOfUsersAssigned={1}
              {...props}
            />
          )}
          {!isReceivedFeedback && (
            <GridFeedbackCard
              css={styles.gridFeedbackCard}
              onCardClick={onFeedbackClick}
              title={feedback.title}
              description={feedback.introduction}
              dueDate={feedback.dueDate}
              isComplete={feedback.isCompleted}
              assignedUsersInfo={feedback.assignees}
              numberOfUsersAssigned={feedback.assignees.length}
              renderCompletedSection={() => (
                <CompletedCountText
                  completedCount={feedback.completedCount}
                  totalCount={feedback.assignees.length}
                />
              )}
              {...props}
            />
          )}
        </>
      )}
      {viewType === ViewType.LIST && (
        <>
          {isReceivedFeedback && (
            <ListFeedbackCard
              onCardClick={onFeedbackClick}
              title={feedback.title}
              dueDate={feedback.dueDate}
              isComplete={feedback.isCompletedByLoggedInUser}
              assignedUsersInfo={[feedback.owner]}
              numberOfUsersAssigned={1}
              {...props}
            />
          )}
          {!isReceivedFeedback && (
            <ListFeedbackCard
              onCardClick={onFeedbackClick}
              title={feedback.title}
              dueDate={feedback.dueDate}
              isComplete={feedback.isCompleted}
              assignedUsersInfo={feedback.assignees}
              numberOfUsersAssigned={feedback.assignees.length}
              renderCompletedSection={() => (
                <CompletedCountText
                  completedCount={feedback.completedCount}
                  totalCount={numberOfFeedbacks}
                />
              )}
              {...props}
            />
          )}
        </>
      )}
    </>
  );
};

FeedbackCard.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isReceivedFeedback: PropTypes.bool.isRequired,
  viewType: PropTypes.oneOf(Object.values(ViewType)).isRequired,
};

FeedbackCard.defaultProps = {};

export default FeedbackCard;
