import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { Accomplishment } from '~DevelopmentPlan/const/types';
import { OPTIMISTIC_ID } from '~DevelopmentPlan/const/defaults';
import { pdpPlanKeys } from '../const/queryKeys';

interface GetAccomplishmentByIdProps {
  id: string,
}

const getPlanById = ({ id }: GetAccomplishmentByIdProps): Promise<HttpCallReturn<Accomplishment>> => {
  const url = {
    host: getHost(hosts.developmentplans, '1'),
    uri: `/organizations/${getOrganizationId() ?? ''}/accomplishments/${id}`,
  };

  return getApi<Accomplishment>(url);
};

interface UseGetAccomplishmentByIdProps<T> extends Omit<UseQueryOptions<HttpCallReturn<Accomplishment>, Error, T>, 'queryKey' | 'queryFn'> {
  id: string,
}

export const useGetAccomplishmentById = <T = HttpCallReturn<Accomplishment>> ({
  id,
  ...options
}: UseGetAccomplishmentByIdProps<T>): UseQueryResult<T, Error> => useQuery({
    queryKey: pdpPlanKeys.detail(id),
    queryFn: () => getPlanById({ id }),
    enabled: !!id && id !== OPTIMISTIC_ID.toString(),
    ...options,
  });
