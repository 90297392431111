import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SURVEY_SHAPE } from '~Common/const/proptypes';
import { useDragAndDrop } from '~Deprecated/hooks/useDragAndDrop';
import DragAndDrop from '~Deprecated/ui/components/DragAndDrop/DragAndDrop';
import DashedBoxWithText from '~Common/components/EmptyStates/DashedBoxWithText';
import { noop } from '~Deprecated/utils';
import { hexToRGBA, palette } from '~Common/styles/colors';
import SurveyQuestionCard from '../../Cards/SurveyQuestionCard';

const styles = {
  questions: css`
    margin: 10px 0;
    font-size:.875rem;
    background: ${hexToRGBA(palette.neutrals.gray50, 0.6)};
  `,
  content: css`
    display: flex;
    align-items: center;
    text-align: left;
    font-size: .8125rem;

    &:hover{
      cursor:pointer;
    }
  `,
  leftIcon: css`
    font-size:2.5em;
    font-weight:300;
    margin-right:.25em;
    color: ${palette.brand.indigo};
  `,
};

const View = ({
  questions,
  isReadOnly,
  onDragEnd,
  renderItem,
  stringifiedQuestions,
  setSurveyQuestions,
  onAddQuestion,
  isDragDisabled,
}) => (
  <>
    {questions.length === 0 && !isReadOnly && (
      <DashedBoxWithText
        text="No questions in this survey yet. Click below to add your first question."
        onClick={onAddQuestion}
        renderBody={() => (
          <p css={styles.content}>
            <FontAwesomeIcon
              css={[
                styles.leftIcon,
              ]}
              icon={faTriangleExclamation}
            />
            No questions in this survey yet. Click below to add your first question.
          </p>
        )}
      />
    )}
    <DragAndDrop
      isDragDisabled={isDragDisabled}
      list={questions}
      renderItem={renderItem}
      onDragEnd={onDragEnd}
      dragEndProps={{
        initialArray: questions,
        movedItem: (draggableId) => questions.find((question) => question.rank.toString() === draggableId),
        endFunctions: (newArray) => {
          const tempNewArray = newArray.map((question, index) => ({ ...question, rank: index }));
          setSurveyQuestions(tempNewArray);
        },
      }}
      valueToUse="rank"
    />
    <input type="hidden" value={stringifiedQuestions} name="questions" />
  </>
);

View.propTypes = {
  questions: PropTypes.arrayOf(SURVEY_SHAPE).isRequired,
  isReadOnly: PropTypes.bool,
  onDragEnd: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  stringifiedQuestions: PropTypes.string.isRequired,
  setSurveyQuestions: PropTypes.func.isRequired,
  onAddQuestion: PropTypes.func.isRequired,
  isDragDisabled: PropTypes.bool,
};

View.defaultProps = {
  isReadOnly: false,
  isDragDisabled: false,
};

const Questions = ({
  questions, onEditQuestion, isDragDisabled = false, ...props
}) => {
  const { onDragEnd } = useDragAndDrop();

  const [stringifiedQuestions, setStringifiedQuestions] = useState(null);

  useEffect(() => {
    if (questions) {
      setStringifiedQuestions(JSON.stringify(questions));
    }
  }, [questions]);
  const renderItem = ({ id: question, index }, dragHandleProps) => (
    <SurveyQuestionCard
      dragHandleProps={dragHandleProps}
      css={styles.questions}
      question={question}
      key={question.rank}
      onEditQuestion={() => onEditQuestion(question)}
      tabIndex={index}
      isDragDisabled={isDragDisabled}
    />
  );

  const hookProps = {
    onDragEnd,
    questions,
    renderItem,
    stringifiedQuestions,
    isDragDisabled,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

Questions.propTypes = {
  questions: PropTypes.arrayOf(SURVEY_SHAPE),
  onEditQuestion: PropTypes.func,
  isDragDisabled: PropTypes.bool,
};

Questions.defaultProps = {
  questions: [],
  onEditQuestion: noop,
  isDragDisabled: false,
};

export default Questions;
