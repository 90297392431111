import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import MeetingFeedback from '~Deprecated/components/Meetings/MeetingFeedback';
import MeetingContent from '~Deprecated/components/Meetings/MeetingContent';

import DetailsAttendees from '~Meetings/components/attendees/DetailsAttendees';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { MEETING_FACTORY_TYPES } from '~Meetings/const/meetingTypes';
import { palette } from '~Common/styles/colors';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useGetPeopleByList } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { participantViewerTemplate } from '~Meetings/components/drawers/ParticipantViewer';
import { withoutDesktop } from '~Common/styles/mixins';
import { NonLeadrUser } from '~Common/const/classes';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import ThematicGoal from '~Meetings/components/details/goals/ThematicGoal';
import DefiningObjectives from '~Meetings/components/details/goals/DefiningObjectives';
import StandardOperatingObjectives from '~Meetings/components/details/goals/StandardOperatingObjectives';
import { MeetingPDP } from '~DevelopmentPlan/components/Shared/MeetingPDP';
import { useHuddleDataLoader } from '~Meetings/hooks/useHuddleDataLoader';
import OldTeamGoals from '~Goals/OldGoals/OldTeamGoals';
import TeamGoals from '~Meetings/components/details/goals/TeamGoals';
import CoachingGoals from '~Meetings/components/details/goals/CoachingGoals';
import OldCoachingGoals from '~Goals/OldGoals/OldCoachingGoals';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import TopSummary from './TopSummary';
import Timeline from './Timeline';
import MeetingActionItems from './MeetingActionItems';
import MeetingAgenda from './MeetingAgenda';
import SkippedMeetingBanner from './SkippedMeetingBanner';

const styles = {
  main: css`
    background-color: ${palette.neutrals.white};
    padding-bottom: 50px;
  `,
  row: css`
    margin-bottom: 24px;
  `,
  timeline: css`
    width: 100%;
    height: 115px;
  `,
  agenda: css``,
  titleBar: css`
    display: flex;
    align-items: flex-start;
    padding: 0.5em 0 !important;

    ${withoutDesktop(`
      padding: 0.5rem  !important;
    `)}
    header {
      flex-direction: column;
      margin: 8px 0;
    }
    .summaryTitle{
      flex: 1;
    }
    .menuButton {
      flex-shrink: 0;
      align-items: flex-end;
    }
    .leftSide {
      width: 100%;
      justify-content: space-between;
    }
    .timeFrequency {
      border-radius: 10px;
      padding: 8px 0;
      margin: 8px 0;

      ${withoutDesktop(`
        padding: 0;
        margin: 0;
      `)}
    }
    .rightSide {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  titleDiv: css`
    width: 95%;
    margin: 10px auto;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid ${palette.neutrals.gray300};
    font-size: 16px;
    font-weight: 600;
    padding-left: 6px;
    line-height: 24px;
  `,
  attendeesContainer: css`
    margin: 1rem 0;
    width: 100%;
  `,
};
const TimelineHeader = () => (
  <div css={styles.titleDiv}>
    Meeting Timeline
  </div>
);
const View = ({
  id,
  className,
  showTimeline,
  frequency,
  avatarList,
  displayOnPage,
  mobileAttendees,
  isCoaching,
  teamId,
  areTeamMeetingsEnabled,
  isTableGroupEnabled,
  shouldShowWorkGeniusMap,
  otherUserInMeeting,
  canSeePdps,
  isSkipped,
  cascadingGoals,
  meetingFactoryId,
  ...props
}) => (
  <div
    className={className}
    css={styles.main}
  >
    <div
      css={styles.titleBar}
    >
      <TopSummary
        renderAttendees={mobileAttendees}
        teamId={teamId}
        shouldShowWorkGeniusMap={shouldShowWorkGeniusMap}
        css={styles.titleBar}
        {...props}
      />
    </div>
    {isSkipped && (
      <SkippedMeetingBanner />
    )}
    <div
      css={[styles.row, styles.agenda]}
    >
      <>
        { areTeamMeetingsEnabled && teamId && (
          <>
            {isTableGroupEnabled && (
              <>
                <ThematicGoal
                  teamId={teamId}
                  huddleId={id}
                />

                <DefiningObjectives
                  teamId={teamId}
                  huddleId={id}
                />

                <StandardOperatingObjectives
                  teamId={teamId}
                  huddleId={id}
                />
              </>
            )}

            {!isTableGroupEnabled && (
              <>
                {cascadingGoals && (
                  <TeamGoals
                    teamId={teamId}
                    huddleId={id}
                  />
                )}
                {!cascadingGoals && (
                  <OldTeamGoals
                    teamId={teamId}
                    huddleId={id}
                  />
                )}
              </>
            )}
          </>
        )}

        {isCoaching && (
          <>
            {canSeePdps && (
              <MeetingPDP
                otherUserInMeeting={otherUserInMeeting}
              />
            )}
            {cascadingGoals && (
              <CoachingGoals
                css={styles.items}
                meetingFactoryId={meetingFactoryId}
              />
            )}
            {!cascadingGoals && (
              <OldCoachingGoals
                id={id}
                css={styles.items}
                {...props}
              />
            )}
          </>
        )}
        <MeetingAgenda
          huddleId={id}
          {...props}
        />
        <MeetingActionItems
          id={id}
          {...props}
        />

        {isCoaching && (
          <>
            <MeetingFeedback page="COACHING" {...props} />
            <MeetingContent page="COACHING" {...props} />
          </>
        )}
      </>
    </div>
    <div
      css={[styles.row, styles.timeline]}
    >
      {frequency && showTimeline && (
        <Timeline
          renderExpander={() => (
            <TimelineHeader />
          )}
          id={id}
          {...props}
        />
      )}

    </div>
  </div>
);

View.propTypes = {
  className: PropTypes.string,
  frequency: PropTypes.string,
  showTimeline: PropTypes.bool,
  avatarList: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  displayOnPage: PropTypes.number.isRequired,
  mobileAttendees: PropTypes.number.isRequired,
  isCoaching: PropTypes.bool.isRequired,
  teamId: PropTypes.string,
  areTeamMeetingsEnabled: PropTypes.bool,
  isTableGroupEnabled: PropTypes.bool,
  shouldShowWorkGeniusMap: PropTypes.bool.isRequired,
  otherUserInMeeting: PropTypes.string,
  canSeePdps: PropTypes.bool,
  isSkipped: PropTypes.bool.isRequired,
  cascadingGoals: PropTypes.bool.isRequired,
  meetingFactoryId: PropTypes.string.isRequired,
};

View.defaultProps = {
  className: '',
  frequency: '',
  showTimeline: true,
  teamId: '',
  areTeamMeetingsEnabled: false,
  isTableGroupEnabled: false,
  otherUserInMeeting: '',
  canSeePdps: false,
};

const HuddleDetails = ({
  id, type, frequency, factoryId, teamId, ...props
}) => {
  const showTimeline = frequency !== 'ONE_TIME' || type === 'COACHING';
  const { item: meetingDetails } = useMeetingDetails({ id, type });
  const { enablePdps: orgHasPdps, enableTheTable } = useOrgDetailsContext();
  const areTeamMeetingsEnabled = useFeatureFlag('webAppTgxMeetings');
  const cascadingGoals = useFeatureFlag('cascadingGoals');
  const tgxWorkingGeniusMap = useFeatureFlag('tgxWorkingGeniusMap');
  const pdpFeatureFlag = useFeatureFlag('pdpsPhaseOne');
  const dispatch = useDispatch();
  const { teamId: urlTeamId } = useParams();

  const meetingTeamId = teamId || meetingDetails?.teamId || urlTeamId;

  const { item } = useMeetingDetails({ id, type }) ?? {};
  const { organizer, attendeeOrgUserIds = [], factoryType } = item ?? {};

  const isCoaching = factoryType === MEETING_FACTORY_TYPES.COACHING;
  const attendeeList = [organizer?.orgUserId, ...(attendeeOrgUserIds ?? [])];

  const leadrAttendees = useGetPeopleByList({
    selectedIds: attendeeList.filter((attendeeOrgUserId) => (
      !isCoaching ? attendeeOrgUserId !== getOrganizationUserId() && attendeeOrgUserId !== item?.huddleOwner : attendeeOrgUserId !== item?.huddleOwner)),
  });

  const shouldShowWorkGeniusMap = enableTheTable && tgxWorkingGeniusMap && !isCoaching;

  useHuddleDataLoader({
    id,
    type,
    factoryId,
    teamId: meetingTeamId,
    otherUserInMeetingId: otherUserInMeeting,
  });

  const onShowMore = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...participantViewerTemplate,
        args: {
          onSelect: (participantId) => {
            dispatch(pushDrawerAction({
              drawer: {
                ...baseballCardDrawerTemplate,
                args: {
                  id: participantId,
                },
              },
            }));
          },
          useOrgIds: true,
          allowSelf: true,
          customFilter: attendeeOrgUserIds,
        },
      },
    }));
  }, [dispatch, attendeeOrgUserIds]);

  const nonLeadrAttendees = item?.nonLeadrAttendees.map((user) => new NonLeadrUser(user.email)) ?? [];

  const avatarList = [...leadrAttendees, ...nonLeadrAttendees];

  const mobileAttendees = () => (
    <div
      css={styles.attendeesContainer}
    >
      <DetailsAttendees
        avatarList={avatarList}
        css={styles.attendee}
        onShowMore={onShowMore}
        {...props}
      />
    </div>
  );

  const otherUserInMeeting = attendeeList.find((attendee) => attendee !== getOrganizationUserId());

  const hookProps = {
    showTimeline,
    frequency,
    avatarList,
    id,
    type,
    onShowMore,
    mobileAttendees,
    isCoaching,
    teamId: meetingTeamId,
    areTeamMeetingsEnabled,
    isTableGroupEnabled: enableTheTable,
    shouldShowWorkGeniusMap,
    otherUserInMeeting,
    canSeePdps: orgHasPdps && pdpFeatureFlag,
    isSkipped: meetingDetails?.isSkipped,
    cascadingGoals,
    meetingFactoryId: factoryId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

HuddleDetails.propTypes = {
  id: PropTypes.string.isRequired,
  factoryId: PropTypes.string,
  teamId: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  frequency: PropTypes.string,
  avatarList: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayOnPage: PropTypes.number,
};

HuddleDetails.defaultProps = {
  className: '',
  type: '',
  frequency: '',
  displayOnPage: 17,
  factoryId: '',
  teamId: '',
};

export { View };
export default HuddleDetails;
