import { Goals } from '@leadr-hr/types';
import moment from 'moment';
import { EditPayloads } from './types';

export function formatGoalsFilters(priority: string | string[]): string {
  // Replace '^' with ','
  return String(priority).replace(/\^/g, ',');
}

export const setupPayLoads = (formDataToSetuup: Goals.Requests.CreateGoalRequestPayload): EditPayloads => {
  const editGoalPayload = { ...formDataToSetuup };
  const participantsPayload = editGoalPayload.participants;
  delete editGoalPayload.participants;

  return { editGoalPayload, participantsPayload };
};

export const getGoalDateString = (startMoment: moment.Moment, endMoment: moment.Moment): { startDate: string, endDate: string } => {
  let startDate;
  let endDate;
  const currentYear = moment().year();

  if (startMoment.year() !== endMoment.year() || startMoment.year() !== currentYear) {
    startDate = startMoment.format('MMM D');
    endDate = endMoment.format('MMM D, YYYY');
  } else {
    startDate = startMoment.format('MMM D');
    endDate = endMoment.format('MMM D');
  }
  return { startDate, endDate };
};
