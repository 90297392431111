/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import DataGridTable from '~Common/components/Tables/DataGridTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { noop } from 'lodash';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import Tooltip from '~Common/components/Tooltip';
import {
  ErrorShape,
  ResponseDataShape, tableColumnHeadersShape, userRowsShape,
} from '../Const/types';
import { DEFAULT_ROWS_PER_PAGE } from '../Const/defaults';
import { TABLE_STYLES } from '../Const/pageStyles';

const styles = {
  ...TABLE_STYLES,
};

interface ViewProps {
  userRows: userRowsShape[],
  tableColumnHeaders: tableColumnHeadersShape[],
  rowsPerPage: number,
}

const View = ({
  tableColumnHeaders,
  userRows,
  rowsPerPage,
}: ViewProps): JSX.Element => (
  <>
    <DataGridTable
      css={styles.hideIdColumn}
      rows={userRows}
      columns={tableColumnHeaders}
      pageSize={rowsPerPage}
      handleCellClickEvent={noop}
      selectionModel={[]}
      setSelectionModel={noop}
      isLoading={false}
    />
  </>
);

interface SuccessfulDataTableProps {
  filteredImportData: ResponseDataShape[],
}

const SuccessfulDataTable = ({
  filteredImportData,
}: SuccessfulDataTableProps): JSX.Element => {
  const [rowsPerPage] = useQueryParamState<number>('admin', 'rowsPerPage', DEFAULT_ROWS_PER_PAGE);
  const tableColumnHeaders = [
    {
      field: 'firstName',
      headerName: 'First Name',
      maxWidth: 150,
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      maxWidth: 200,
      flex: 1,
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      maxWidth: 350,
      flex: 1,
      editable: true,
    },
    {
      field: 'managerEmail',
      headerName: 'Manager Email',
      maxWidth: 350,
      flex: 1,
      editable: true,
    },
    {
      field: 'mentorEmail',
      headerName: 'Secondary Manager Email',
      maxWidth: 350,
      flex: 1,
      editable: true,
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'department',
      headerName: 'Department',
      maxWidth: 200,
      flex: 1,
    },
    {
      field: 'userGroupName',
      headerName: 'Account Type',
      maxWidth: 150,
      flex: 1,
    },
    {
      field: 'issues',
      headerName: 'Issues',
      maxWidth: 75,
      flex: 1,
      renderHeader: () => (
        <FontAwesomeIcon
          css={styles.issuesIconHeader}
          icon={faTriangleExclamation}
        />
      ),
      renderCell: (cellValues: { row: { errors: ErrorShape[]; warnings: ErrorShape[]; }; }) => (
        <>
          {(cellValues.row.errors.length > 0 || cellValues.row.warnings.length > 0) && (
            <>
              <Tooltip content={cellValues.row.warnings.map((warning) => (
                <>
                  <div css={styles.tippyContent}>
                    {warning.message}
                  </div>
                </>
              ))}
              >
                <div>
                  <FontAwesomeIcon
                    css={styles.issuesIcon(cellValues.row.errors.length > 0)}
                    icon={faTriangleExclamation}
                  />
                </div>
              </Tooltip>
            </>
          )}
        </>
      ),
    },

  ];
  const userRows = useMemo(() => filteredImportData.map((item, index) => ({
    id: index,
    firstName: item.firstName,
    lastName: item.lastName,
    emailAddress: item.email,
    managerEmail: item.managerEmail,
    mentorEmail: item.mentorEmail,
    jobTitle: item.jobTitle ?? '-',
    department: item.department ?? '-',
    userGroupName: item.userGroupName ?? '-',
    warnings: item.warnings,
    errors: item.errors,
    issues: item.errors.length > 0 && item.warnings.length > 0 ? 'both'
      : item.errors.length > 0 ? 'errors'
        : item.warnings.length > 0 ? 'warnings' : 'zero',
  })), [filteredImportData]);

  const hookProps = {
    tableColumnHeaders,
    userRows,
    rowsPerPage,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default SuccessfulDataTable;
