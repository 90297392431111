import { useEffect, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { css } from '@emotion/react';

import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import WhatsDueOptions from '~Home/components/Module/WhatsDue/WhatsDueOptions';
import { WhatsDueFilterOptions } from '~Home/const/index';
import { getUserId } from '~Common/utils/localStorage';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { useWhatsDueFilter } from '~Home/hooks/useWhatsDueFilter';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useHomeSettings } from '~Home/hooks/useHomeSettings';

const styles = {
  dropdown: css({
    '.MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: '0.75rem',
    },
  }),
};

type ViewProps = Omit <DropdownProps<WhatsDueFilterOptions>, 'renderItem'>

const View = ({
  items, value, onChange, ...props
}:ViewProps): JSX.Element => (
  <Dropdown
    css={styles.dropdown}
    items={items}
    value={value}
    onChange={onChange}
    renderItem={(item: DropdownItem<WhatsDueFilterOptions>) => (
      <WhatsDueOptions
        option={item.value}
      />
    )}
    {...props}
  />
);

interface WhatsDueDropdownProps {
  value: WhatsDueFilterOptions,
}

const WhatsDueDropdown = ({
  value, ...props
}: WhatsDueDropdownProps):JSX.Element => {
  const { setWhatsDueFilter } = useWhatsDueFilter();
  const handleFilterChange = (event: SelectChangeEvent<WhatsDueFilterOptions>):void => {
    setWhatsDueFilter(event.target.value as WhatsDueFilterOptions);
  };
  const useAllDueFilter = useFeatureFlag('webClientCombinedWhatsDueCard');
  const { user } = useUserProfile(getUserId() ?? '');
  const { data: homeSettingsData } = useHomeSettings();

  const filterOptions = useMemo(() => {
    const options: DropdownItem<WhatsDueFilterOptions>[] = [];
    const hasInsights = user?.orgDetails?.enableInsights;
    const hasReviews = user?.orgDetails?.enableReviews;

    if (homeSettingsData?.response) {
      const whatsDue = homeSettingsData?.response.whatsDue;

      if (whatsDue?.actionItems) {
        options.push({
          value: WhatsDueFilterOptions.ActionItems,
          text: 'Action Items',
        });
      }

      if (whatsDue?.feedback) {
        options.push({
          value: WhatsDueFilterOptions.Feedback,
          text: 'Feedback',
        });
      }

      if (hasInsights && (whatsDue?.surveys)) {
        options.push({
          value: WhatsDueFilterOptions.Surveys,
          text: 'Surveys',
        });
      }

      if (whatsDue?.learning) {
        options.push({
          value: WhatsDueFilterOptions.Learning,
          text: 'Feedback',
        });
      }

      if (hasReviews && (whatsDue?.reviews)) {
        options.push({
          value: WhatsDueFilterOptions.Reviews,
          text: 'Reviews',
        });
      }
    }

    if (useAllDueFilter) {
      options.unshift({
        value: WhatsDueFilterOptions.AllDue,
        text: 'All Due',
      });
    }

    return options;
  }, [
    user,
    useAllDueFilter,
    homeSettingsData?.response,
  ]);

  useEffect(() => {
    if (!useAllDueFilter && value === WhatsDueFilterOptions.AllDue) {
      setWhatsDueFilter(WhatsDueFilterOptions.ActionItems);
    }
  }, [value, setWhatsDueFilter, useAllDueFilter]);

  const hookProps = {
    items: filterOptions,
    value,
    onChange: handleFilterChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
export { View };
export default WhatsDueDropdown;
