import { useGenericStore } from '~Common/hooks/useGenericStore';

export enum RequestedLearningDrawerTab {
  DETAILS = 'DETAILS',
  QUESTIONS = 'QUESTIONS',
  PERSON = 'PERSON',
}

export const useRequestedLearningDrawerTab = (): [RequestedLearningDrawerTab, (learningDrawerTab: RequestedLearningDrawerTab) => void] => {
  // TODO: Update this when useGenericStore is typed
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value: selectedTab, setValue: setSelectedTab } = useGenericStore({
    module: 'leadr',
    workflow: 'learning',
    feature: 'requested-tab',
    defaultValue: RequestedLearningDrawerTab.DETAILS,
  });

  // TODO: Update this when useGenericStore is typed
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [
    selectedTab,
    setSelectedTab,
  ];
};
