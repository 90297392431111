import {
  array,
  object,
  string,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEPARTMENT_LENGTH, JOB_TITLE_LENGTH, PROFILE_NAME_MAX_LENGTH } from '~Common/const/constraints';

export const employmentTypes = ['full-time', 'part-time', 'other'] as const;
const requiredMsg = 'This field is required.';

const editUserFormSchema = object({
  firstName: string()
    .required(requiredMsg)
    .max(PROFILE_NAME_MAX_LENGTH, `This field is limited to ${PROFILE_NAME_MAX_LENGTH} characters.`),
  lastName: string()
    .required(requiredMsg)
    .max(PROFILE_NAME_MAX_LENGTH, `This field is limited to ${PROFILE_NAME_MAX_LENGTH} characters.`),
  email: string()
    .required(requiredMsg)
    .email('This is not a valid email address.'),
  jobTitle: string()
    .ensure()
    .max(JOB_TITLE_LENGTH, `This field is limited to ${JOB_TITLE_LENGTH} characters.`),
  managerId: string().ensure(),
  secondaryManagerId: string().ensure(),
  department: string()
    .ensure()
    .max(DEPARTMENT_LENGTH, `This field is limited to ${DEPARTMENT_LENGTH} characters.`),
  accountType: string().required(requiredMsg),
  userGroups: array(string().ensure()).default([]),
});
export const editUserFormResolver = yupResolver(editUserFormSchema);

export type FormValues = InferType<typeof editUserFormSchema>;

export interface EditUserDTO {
  email: string,
  firstName: string,
  lastName: string,
  managerId?: string | null,
  mentors?: string[],
  professionalInformation: {
    jobTitle: string,
    department: string,
    // employmentType?: string | null,
  }
  userGroupIds: string[],
  teams: string[],
}

interface ConformToDtoOptions {
  enableMatrixOrgs: boolean,
}

export function conformToDto(data: FormValues, {
  enableMatrixOrgs,

}: ConformToDtoOptions): EditUserDTO {
  const result: EditUserDTO = {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    managerId: data.managerId !== '' ? data.managerId : null,
    professionalInformation: {
      jobTitle: data.jobTitle,
      department: data.department,
    },
    userGroupIds: [data.accountType],
    teams: data.userGroups,
  };

  if (enableMatrixOrgs) {
    result.mentors = data.secondaryManagerId ? [data.secondaryManagerId] : [];
  }

  return result;
}
