import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/pro-regular-svg-icons';
import { palette } from '~Common/styles/colors';

const styles = {
  comments: css({
    display: 'flex',
    alignItems: 'center',
  }),
  commentCount: css({
    marginRight: '0.25rem',
    fontSize: '0.75rem',
    color: palette.neutrals.gray700,
  }),
  commentIcon: css({
    fontSize: '0.625rem',
    color: palette.neutrals.gray700,
  }),
};

interface CommentsProps {
  commentCount: number,
}

const Comments = ({
  commentCount,
  ...props
}: CommentsProps): JSX.Element => (
  <div css={styles.comments} {...props}>
    <span css={styles.commentCount}>
      {commentCount}
    </span>

    <FontAwesomeIcon
      css={styles.commentIcon}
      icon={faMessage}
    />
  </div>
);

export default Comments;
