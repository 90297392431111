import { useEffect, useState } from 'react';
import { useAddResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import RecognitionModalDetails from '~Recognition/components/RecognitionModalDetails';
import { DEFAULT_EDIT_POST } from '~Recognition/const/defaults';
import { EditPostShape, InfiniteQueryParamsProps } from '~Recognition/const/types';
import CreateRecognitionModal from '~Recognition/components/CreateRecognitionModal';
import { useShowRecognitionModal } from '~Recognition/hooks/useShowRecognitionModal';

interface ViewProps {
  infiniteQueryParams: InfiniteQueryParamsProps,
  onRecognitionDetailsModalClose: () => void,
  setEditPost: (value: EditPostShape) => void,
  recognitionId: string,
  handleCreateModalDisplay: (value: boolean) => void,
  editPost: EditPostShape,
  openCreateModal: boolean,
}

const View = ({
  infiniteQueryParams,
  onRecognitionDetailsModalClose,
  setEditPost,
  recognitionId,
  handleCreateModalDisplay,
  editPost,
  openCreateModal,
}: ViewProps): JSX.Element => (
  <>
    <CreateRecognitionModal
      open={openCreateModal}
      handleModalDisplay={handleCreateModalDisplay}
      editPost={editPost}
      setEditPost={setEditPost}
      infiniteQueryParams={infiniteQueryParams}
    />
    <RecognitionModalDetails
      infiniteQueryParams={infiniteQueryParams}
      recognitionId={parseInt(recognitionId, 10)}
      setEditPost={setEditPost}
      onCloseModal={onRecognitionDetailsModalClose}
    />
  </>
);

export const RecognitionDetails = (): JSX.Element => {
  const {
    resourceContentId,
    closeViewResourceModal,
  } = useAddResourceModalStore((state) => ({
    resourceContentId: state.resourceContentId,
    closeViewResourceModal: state.closeViewResourceModal,
  }));
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const {
    openModal,
    closeModal,
  } = useShowRecognitionModal();

  const recognitionId = resourceContentId.toString();
  const [editPost, setEditPost] = useState(DEFAULT_EDIT_POST);

  useEffect(() => {
    if (resourceContentId) {
      openModal();
    } else {
      closeModal();
    }
  }, [closeModal, openModal, resourceContentId]);

  useEffect(() => {
    if (editPost.editPost) {
      setOpenCreateModal(true);
    }
  }, [editPost]);

  const infiniteQueryParams = {} as InfiniteQueryParamsProps;

  const onRecognitionDetailsModalClose = (): void => {
    closeViewResourceModal();
  };

  const handleCreateModalDisplay = (value: boolean): void => {
    setOpenCreateModal(value);
  };

  const hookProps = {
    infiniteQueryParams,
    onRecognitionDetailsModalClose,
    closeViewResourceModal,
    setEditPost,
    recognitionId,
    handleCreateModalDisplay,
    editPost,
    openCreateModal,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default RecognitionDetails;
