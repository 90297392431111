import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { LearningPlaylistTemplateDetail, useGetLearningPlaylistTemplateDetail } from '../templates/playlists/useGetLearningPlaylistTemplateDetail';
import { useGetLearningTableGroupPlaylistTemplateDetail } from '../templates/curatedByTheTableGroup/useGetLearningTableGroupPlaylistTemplateDetail';
import { useGetLearningCuratedPlaylistTemplateDetail } from '../templates/curatedByLeadr/useGetLearningCuratedPlaylistTemplateDetail';

interface UseGetCombinedLearningPlaylistTemplateDetailParams {
  playlistTemplateId: number,
  isCuratedByLeadr: boolean,
  isCuratedByTableGroup: boolean,
}

interface UseGetCombinedLearningPlaylistTemplateDetailReturn {
  learningPlaylistTemplate: LearningPlaylistTemplateDetail | undefined,
  isLoading: boolean,
}

export const useGetCombinedLearningPlaylistTemplateDetail = ({
  playlistTemplateId,
  isCuratedByLeadr,
  isCuratedByTableGroup,
}: UseGetCombinedLearningPlaylistTemplateDetailParams): UseGetCombinedLearningPlaylistTemplateDetailReturn => {
  const useDynamicCuratedByLeadrLibrary = useFeatureFlag('learningCuratedByLeadrInApp') && isCuratedByLeadr;

  const {
    detail: orgLearningPlaylistTemplate,
    isLoading: orgPlaylistTemplateIsLoading,
  } = useGetLearningPlaylistTemplateDetail({
    playlistTemplateId,
    enabled: !useDynamicCuratedByLeadrLibrary && !isCuratedByTableGroup,
  });

  const {
    detail: leadrCuratedLearningPlaylistTemplate,
    isLoading: leadrCuratedPlaylistTemplateIsLoading,
  } = useGetLearningCuratedPlaylistTemplateDetail({
    playlistTemplateId,
    enabled: useDynamicCuratedByLeadrLibrary && !isCuratedByTableGroup,
  });

  const {
    detail: tableGroupPlaylistTemplate,
    isLoading: tableGroupPlaylistTemplateIsLoading,
  } = useGetLearningTableGroupPlaylistTemplateDetail({
    playlistTemplateId,
    enabled: isCuratedByTableGroup,
  });

  const leadrLearningPlaylistTemplate = useDynamicCuratedByLeadrLibrary ? leadrCuratedLearningPlaylistTemplate : orgLearningPlaylistTemplate;
  const leadrPlaylistTemplateIsLoading = useDynamicCuratedByLeadrLibrary ? leadrCuratedPlaylistTemplateIsLoading : orgPlaylistTemplateIsLoading;
  const learningPlaylistTemplate = isCuratedByTableGroup ? tableGroupPlaylistTemplate : leadrLearningPlaylistTemplate;

  const isLoading = isCuratedByTableGroup ? tableGroupPlaylistTemplateIsLoading : leadrPlaylistTemplateIsLoading;

  return {
    learningPlaylistTemplate,
    isLoading,
  };
};
