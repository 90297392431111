import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import moment from 'moment-timezone';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import CompletedProgressBar from '~Common/V3/components/ProgressBar/CompletedProgressBar';
import {
  ReviewCycleDetail, ViewPerspective, ReviewCycleStatusEnum, ReviewCycleWithParticipants,
} from '~Reviews/V2/Const/types';
import { Link } from 'react-router-dom';
import { BASE_ROUTE } from '~Common/const/routes';
import CycleCard from './CycleCard';
import DisplayMyReviewCard from './DisplayMyReviewCard';

const styles = {
  reviewDashboard: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(20.9375rem, 1fr));',
    gridAutoRows: '1fr',
    width: '100%',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '8.75rem',
  }),
  cardLink: css({
    '&:hover': {
      textDecoration: 'none',
      scale: '1.03',
      transition: 'all 150ms',
    },
  }),
};

interface ReviewCardsProps extends ComponentProps<'div'> {
  reviewCycles: ReviewCycleWithParticipants[] | ReviewCycleDetail[] | undefined,
  viewPerspective: ViewPerspective,
}

const ReviewCards = ({
  reviewCycles,
  viewPerspective,
  ...props
}: ReviewCardsProps): JSX.Element => (
  <div css={styles.reviewDashboard} {...props}>
    {viewPerspective === ViewPerspective.Admin && (
      <>
        {(reviewCycles as ReviewCycleWithParticipants[])?.map((cycle) => (
          <Link
            key={cycle.uid}
            css={styles.cardLink}
            to={cycle.statusEnum === ReviewCycleStatusEnum.Draft || cycle.statusEnum === ReviewCycleStatusEnum.Unknown
              ? `${BASE_ROUTE}reviews/admin/createCycle/${cycle.uid}`
              : `${BASE_ROUTE}reviews/admin/cycle/${cycle.uid}`}
          >
            <CycleCard
              id={cycle.uid}
              // eslint-disable-next-line max-len
              reviewTitle={`REVIEW PERIOD: ${moment(cycle.assessmentPeriodStart).format('MMM D, YY')} - ${moment(cycle.assessmentPeriodEnd).format('MMM D, YY')}`}
              title={cycle.name}
              description={cycle.description}
              dueDate={moment(cycle.signOffDueDate).format('MMM D, YY')}
              isComplete={cycle.statusEnum === ReviewCycleStatusEnum.Finished}
              renderCompletedSection={() => (
                <>
                  <CompletedProgressBar
                    totalCount={cycle.participants?.length}
                    completedCount={Math.round(((cycle.participants?.length ?? 0) * (cycle.completionPct / 100)))}
                    completedPercentage={cycle.completionPct}
                    renderPercentText={() => (
                      <>{`${cycle.completionPct}%`}</>
                    )}
                  />
                </>
              )}
            />
          </Link>
        ))}
      </>
    )}
    {viewPerspective === ViewPerspective.MyReviews && (
      <>
        {(reviewCycles as ReviewCycleDetail[])?.map((reviewCycle) => (
          <DisplayMyReviewCard
            reviewCycle={reviewCycle}
          />
        ))}
      </>
    )}
  </div>
);

const SkeletonView = (): JSX.Element => (
  <MultipleSkeletonLoaders
    css={styles.reviewDashboard}
    numberOfSkeletons={12}
    renderSkeletonItem={() => (
      <CardSkeleton css={styles.cardSkeleton} />
    )}
  />
);

export default ReviewCards;

export { SkeletonView as ReviewCardsSkeleton };
