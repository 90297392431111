import './styles.scss';
import FeedbackListItem from './FeedbackListItem';

// TODO: Rewrite this with new UI in meetings
/**
 * @deprecated Old UI
 */
const FeedbackList = (props) => (props.questions
  ? (
    <div className="feedback-list-wrapper">
      { props.questions && props.questions.length ? props.questions.map((itemObj, index) => (
        <FeedbackListItem
          key={itemObj.id}
          queAnsData={itemObj}
          isEditable={props.isEditable}
          index={index}
          user={props.questions}
          meetingId={props.meetingId}
        />
      )) : null }

    </div>
  ) : null);
export default FeedbackList;
