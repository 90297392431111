import React from 'react';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import BaseToast from './BaseToast';

interface PromiseToastProps {
  text: string;
}

const PromiseToast = ({ text }: PromiseToastProps): JSX.Element => (
  <BaseToast
    icon={faSpinner}
    text={text}
    spin
  />
);

export default PromiseToast;
