import { css } from '@emotion/react';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { DropdownSkeleton } from '~Common/V3/components/Dropdown';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  dropdownSkeleton: css({
    marginBottom: '1.5rem',
  }),
};

const CalendarSettingsSkeleton = ({
  showButtonLoader = true,
  ...props
}): JSX.Element => (
  <div {...props}>
    <DropdownSkeleton css={styles.dropdownSkeleton} />
    {showButtonLoader && (
      <SkeletonLoader
        renderComponent={() => (
          <LeadrButton
            data-test-id="settingsConnectCalendar"
          >
            Connect a Calendar
          </LeadrButton>
        )}
      />
    )}
  </div>
);

export default CalendarSettingsSkeleton;
