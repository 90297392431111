import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

const updateCycle = ({ cycleId, cycle }) => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId()}/reviewCycles/${cycleId}`,
  };

  return patchApi(serverUrl, { ...cycle }, {});
};

export const useUpdateCycle = ({ queryKey = [getOrganizationId(), 'reviewCycles'], cycleId }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: updateCycle,
    onMutate: () => {
      toastId.current = toast.info('Updating the review cycle...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error updating the review cycle. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully updated the review cycle.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey: [...queryKey, cycleId] });
      // invalidate the reviews
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), getOrganizationUserId(), 'reviews', 'list'] });
      dispatch(popDrawerAction({ popAll: true }));
    },
  });

  return mutation.mutate;
};
