import { css } from '@emotion/react';
import {
  useCallback,
  useState,
  useMemo,
} from 'react';
import {
  Card,
  CardContent,
  CardProps,
  SelectChangeEvent,
} from '@mui/material';

import ActionItemsTable from '~ActionItems/components/ActionItemsTable';
import { ActionItemAssigneeType } from '~ActionItems/hooks/useActionItems';
import { NewActionItemStatus } from '~ActionItems/const/interfaces';
import { withoutMobileObject } from '~Common/styles/mixins';
import { ACTION_ITEMS_OPEN, ACTION_ITEMS_COMPLETED } from '~Common/const/routes';
import { useLocation } from 'react-router-dom';
import TaskForFilter from './TaskForFilter';
import StatusFilter from './StatusFilter';

const styles = {
  header: css({
    marginBottom: '0.75rem',
  }, withoutMobileObject({
    marginBottom: '2rem',
  })),
  taskForFilter: css({
    marginRight: '1rem',
    marginBottom: '0.25rem',
  }),
  card: css({
    marginBottom: '4rem',
  }),
};

export const emptyStateFilterValue = [
  NewActionItemStatus.ToDo,
  NewActionItemStatus.InProgress,
  NewActionItemStatus.Blocked,
];

export const completedStateFilterValue = [
  NewActionItemStatus.Completed,
];

export interface ActionItemsViewProps extends CardProps {
  assigneeTypeValue: ActionItemAssigneeType[],
  onAssigneeTypeChange: (event: SelectChangeEvent<ActionItemAssigneeType[]>) => void,
  statusValue: NewActionItemStatus[],
  onStatusChange: (event: SelectChangeEvent<NewActionItemStatus[]>) => void,
  statusFilter: NewActionItemStatus[],
  isCompletedTab: boolean,
}

const View = ({
  statusValue,
  onStatusChange,
  assigneeTypeValue,
  onAssigneeTypeChange,
  statusFilter,
  isCompletedTab,
  ...props
}: ActionItemsViewProps): JSX.Element => (
  <div>
    <Card
      css={styles.card}
      {...props}
    >
      <CardContent>
        <div>
          <TaskForFilter
            data-test-id="actionItemsTableTaskForFilter"
            css={styles.taskForFilter}
            value={assigneeTypeValue}
            onChange={onAssigneeTypeChange}
          />

          { !isCompletedTab && (
            <StatusFilter
              data-test-id="actionItemsTableStatusFilter"
              value={statusValue}
              onChange={onStatusChange}
            />
          )}
        </div>

        <hr />

        <ActionItemsTable
          statusFilter={statusFilter}
          assigneeTypeFilter={assigneeTypeValue}
        />
      </CardContent>
    </Card>
  </div>
);

const ActionItems = ({ ...props }: CardProps): JSX.Element => {
  const location = useLocation();
  const tab = location.pathname === ACTION_ITEMS_OPEN ? ACTION_ITEMS_OPEN : ACTION_ITEMS_COMPLETED;

  const isCompletedTab = tab === ACTION_ITEMS_COMPLETED;

  const [assigneeTypeValue, setAssigneeTypeValue] = useState<ActionItemAssigneeType[]>([]);
  const [statusValue, setStatusValue] = useState<NewActionItemStatus[]>([]);

  const onAssigneeTypeChange = useCallback((event: SelectChangeEvent<ActionItemAssigneeType[]>) => {
    setAssigneeTypeValue(event.target.value as ActionItemAssigneeType[]);
  }, []);

  const onStatusChange = useCallback((event: SelectChangeEvent<NewActionItemStatus[]>) => {
    setStatusValue(event.target.value as NewActionItemStatus[]);
  }, []);

  const statusFilter = useMemo(() => {
    if (isCompletedTab) {
      return completedStateFilterValue;
    }

    return statusValue.length ? statusValue : emptyStateFilterValue;
  }, [isCompletedTab, statusValue]);

  const hookProps = {
    assigneeTypeValue,
    onAssigneeTypeChange,
    statusValue,
    onStatusChange,
    statusFilter,
    isCompletedTab,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItems;
