import { createSelector } from 'reselect';

export const DEFAULT_PAGE_SIZE = 1000;

// Given a single page of data, flatten it into { ids, data }
export function flattenData({
  responseData = [],
  idName = 'id',
  pathToId = [],
  onItemFlatten,
}) {
  const ids = [];
  const data = {};

  responseData.forEach((responseItem) => {
    let item = responseItem;

    if (pathToId.length > 0) {
      pathToId.forEach((key) => {
        item = item[key];
      });
    }

    if (item) {
      if (onItemFlatten) {
        const result = onItemFlatten(responseItem);
        if (result) {
          data[item[idName]] = result;
          ids.push(item[idName]);
        }
      } else if (item[idName]) {
        ids.push(item[idName]);
        data[item[idName]] = item;
      }
    }
  });

  return {
    ids,
    data,
  };
}

// Given a way to select a list and an id, return a flattened { id, data } data set from an infinite query
export const createInfiniteFlattener = ({
  listSelector,
  idName = 'id',
}) => createSelector(
  (infiniteResult) => infiniteResult?.data?.pages ?? [],
  (pages) => pages.reduce((acc, item) => {
    const responseData = listSelector?.(item) ?? item?.response?.items ?? [];

    const { ids, data } = flattenData({
      responseData,
      idName,
    });

    return ({
      ids: [...acc.ids, ...ids],
      data: { ...acc.data, ...data },
    });
  }, { ids: [], data: {} }),
);

// A useful object to spread into react queries with sensible defaults.
export const DEFAULT_QUERY_OPTIONS = {
  getNextPageParam: (lastPage) => (lastPage.response.skip > 0 && lastPage.response.skip !== lastPage.response.total ? lastPage.response.skip : undefined),
  staleTime: 60 * 1000 * 5,
  gcTime: 60 * 1000 * 60,
  retryDelay: (attempt) => attempt * 1500,
};
// TODO: Remove this once we can get over to goals and fix the BE to return the correct skip value. Should only be one DEFAULT QUERY OPTIONS
export const DEFAULT_QUERY_OPTIONS_RECOGNITION = {
  getNextPageParam: (lastPage) => {
    const { skip, total } = lastPage.response;
    return skip < total ? skip : undefined;
  },
};

// Takes in a single page of an API response, and flattens it into { list: [uids], listData: { [uid]: object } }
export const buildFlattener = ({
  listSelector,
  idName = 'id',
}) => (result, normalizer) => {
  const responseData = (listSelector && listSelector(result)) || result.response.items || [];
  return responseData.reduce((acc, item) => {
    acc.list.push(item[idName] ?? item.id);
    // eslint-disable-next-line no-param-reassign
    acc.listData[item[idName] ?? item.id] = normalizer ? normalizer(item) : item;
    return acc;
  }, { list: [], listData: {} });
};

// Used by buildInfiniteFlattener, and is also fallback behavior if a memoizer doesnt exist in useQueryConfig.
export const memoFlattener = (data) => data?.pages?.reduce((acc, item) => ({
  list: [...acc.list, ...item.list],
  listData: { ...acc.listData, ...item.listData },
}), { list: [], listData: {} }) ?? { list: [], listData: {} };

// Builds a set of memoizers that should correspond to the different types of views for a concept like Goals or Action Items.
// If you don't need a set, statuses can be null and it will return a single memoizer.
// The intent of this is to not flatten an infiniteQuery for every item in the list.
export const buildInfiniteFlattener = (statuses) => {
  if (!statuses) {
    return createSelector(
      [
        (queryResult) => queryResult.data,
      ],
      memoFlattener,
    );
  }
  return Object.values(statuses).reduce((acc, value) => {
    // eslint-disable-next-line no-param-reassign
    acc[value] = createSelector(
      [
        (queryResult) => queryResult.data,
      ],
      memoFlattener,
    );

    return acc;
  }, {});
};
