import { Goals } from '@leadr-hr/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SetContextType = (contextType: Goals.GoalContextType | string) => void;

interface LeadrHomeGoalsContextTypeStoreState {
  contextType: Goals.GoalContextType | string,
  setContextType: SetContextType,
}

const useLeadrHomeGoalsContextTypeStore = create<LeadrHomeGoalsContextTypeStoreState>()(
  persist(
    (set) => ({
      contextType: '',
      setContextType: (contextType: Goals.GoalContextType | string) => set({ contextType }),
    }),
    {
      name: 'leadrHomeGoalsContextType',
    },
  ),
);

export const useLeadrHomeGoalsContextType = (): Goals.GoalContextType | string => useLeadrHomeGoalsContextTypeStore((state) => state.contextType);
export const useSetLeadrHomeGoalsContextType = (): SetContextType => useLeadrHomeGoalsContextTypeStore((state) => state.setContextType);
