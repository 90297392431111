export enum CSVPerspective {
    Upload = 'upload csv',
    Preview = 'preview upload',
    Import = 'import',
}
export const ImportSteps = {
  [CSVPerspective.Upload]: 0,
  [CSVPerspective.Preview]: 1,
  [CSVPerspective.Import]: 2,
};

export interface ResponseDataShape {
  firstName: string,
  lastName: string,
  email: string,
  managerEmail: string,
  mentorEmail: string,
  userGroupName: string,
  jobTitle: string,
  department: string,
  warnings: ErrorShape[],
  errors: ErrorShape[],
  spreadsheetRowNumber: number,
  columnName: string,
  [key: string]: unknown,
}

export interface ErrorShape {
  errorCode: string,
  message: string,
  columnName: string,
}

export enum AdminImporterTabValues {
  Successful = 'successful',
  Errors = 'errors',
  Warnings = 'warnings',
  // Saving this for later - will be used in a later iteration of the importer
  // Edit = 'edit',
}
export type AdminImporterTypes = AdminImporterTabValues.Warnings | AdminImporterTabValues.Errors;

export interface userRowsShape{
  id: number,
  firstName: string,
  lastName: string,
  jobTitle: string,
  department: string,
  userGroupName: string,
  warnings: ErrorShape[],
  errors: ErrorShape[],
  issues: string,
}
export interface errorRowShape{
  id: number,
  CSVRows: string,
  ColumnName: string,
  ErrorMessage: string,
}

export interface tableColumnHeadersShape {
  field: string,
  headerName: string,
  maxWidth: number,
  flex: number,
}

export interface ErrorAndWarningRecordShape {
  errorCode: string,
  message: string,
}
