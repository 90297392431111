import { css } from '@emotion/react';
import MultipleSkeletonLoaders, { MultipleSkeletonLoadersProps } from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';

const styles = {
  skeleton: (height: number) => css({
    maxWidth: '100%',
    height: `${height}rem`,
    borderRadius: '0.25rem',
    marginBottom: '0.5rem',
  }),
};

interface HomeSkeletonProps extends Omit<MultipleSkeletonLoadersProps, 'renderSkeletonItem'> {
  height: number,
}
const HomeSkeleton = ({ height, ...props }:HomeSkeletonProps): JSX.Element => (
  <MultipleSkeletonLoaders
    {...props}
    renderSkeletonItem={() => (<SkeletonLoader variant="rectangular" css={styles.skeleton(height)} renderComponent={() => (<div />)} />)}
  />
);

export default HomeSkeleton;
