import { getUserTimezone } from '~Common/utils/localStorage';
import { appConfig } from '../config';
import LeadrFetch from './LeadrFetch';

export class HttpError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = 'HttpError';
  }
}

interface Url {
  host: string,
  uri: string,
}

interface BuildHeadersOptions {
  includeContentType: boolean,
}

const buildHeaders = (
  headers: Record<string, string> = {},
  options?: BuildHeadersOptions,
): Record<string, string> => {
  const timezone = getUserTimezone();

  const {
    includeContentType = true,
  } = options ?? {};

  const customHeaders = {
    Accept: 'application/vnd.leadr.json; version=1.0',
    'Accept-Language': 'en',
    ...(includeContentType && { 'Content-Type': 'application/json' }),
    ...(timezone && { 'X-Timezone': timezone }),
  };

  return {
    ...customHeaders,
    ...headers,
  };
};

export const getUrl = (url: Url | string): string => {
  let urlPath;
  if (typeof url === 'string') {
    urlPath = `${appConfig.apiUrl}${url}`;
  } else {
    urlPath = `${url.host}${url.uri}`;
  }
  return urlPath;
};

export interface HttpCallReturn<TResponse> {
  status: number,
  response: TResponse,
  message?: string,
  errorCode?: string,
  success?: boolean,
  meta?: Record<string, number>,
  _metadata?: Record<string, number>,
}
export interface LeadrResponse<TResponseData> {
  data: TResponseData,
  message?: string,
  messageKey?: string,
  errorCode?: string,
  success?: boolean,
  meta?: Record<string, number>,
  status?: {'success': boolean, 'messages': [{ type: string, message: string }]}
  _metadata?: Record<string, number>,
}

const httpCall = async <TResponseData>(urlPath: string, config: RequestInit): Promise<HttpCallReturn<TResponseData>> => {
  try {
    const apiResponse = await LeadrFetch(urlPath, config) as Response;
    let returnObj = {} as HttpCallReturn<TResponseData>;

    if (apiResponse) {
      const data = await apiResponse.json() as LeadrResponse<TResponseData>;
      returnObj = {
        ...data,
        status: apiResponse.status,
        response: data.data,
        message: data.messageKey || data.message,
      };

      if (!apiResponse.ok) {
        const error = new HttpError((data.message ?? data.status?.messages?.[0].message) || '', apiResponse.status);
        throw error;
      }
    }
    return returnObj;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // We're doing this so we can actually access the error within React Query
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (error.status === 401) {
      throw error;
    }
    // TODO: Log to Loggly
    // ToDo: Find a better way to handle error typing at some point
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (error.json) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      throw await error.json();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (error.response) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      throw await error.response.json();
    }

    throw error;
  }
};

const getApi = async <TResponseData>(url: Url | string, headers?: Record<string, string>): Promise<HttpCallReturn<TResponseData>> => {
  const urlPath = getUrl(url);
  const config = {
    method: 'GET',
    headers: buildHeaders(headers),
  };

  const response = await httpCall<TResponseData>(urlPath, config);

  return response;
};

const postApi = async <TResponseData>(
  url: Url | string,
  data: Record<string, unknown> | unknown,
  headers?: Record<string, string>,
): Promise<HttpCallReturn<TResponseData>> => {
  const urlPath = getUrl(url);
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: buildHeaders(headers),
  };

  const response = await httpCall<TResponseData>(urlPath, config);
  return response;
};

const postApiFormData = async <TResponseData>(
  url: Url | string,
  data: Record<string, unknown> | unknown,
  headers?: Record<string, string>,
): Promise<HttpCallReturn<TResponseData>> => {
  const urlPath = getUrl(url);
  const config = {
    method: 'POST',
    body: data as FormData,
    headers: buildHeaders(headers, { includeContentType: false }),
  };

  const response = await httpCall<TResponseData>(urlPath, config);
  return response;
};

const patchApi = async <TResponseData>(
  url: Url | string,
  data?: Record<string, unknown> | unknown,
  headers?: Record<string, string>,
): Promise<HttpCallReturn<TResponseData>> => {
  const urlPath = getUrl(url);

  const config = {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: buildHeaders(headers),
  };

  const response = await httpCall<TResponseData>(urlPath, config);
  return response;
};

const putApi = async <TResponseData>(
  url: Url | string,
  data: Record<string, unknown> | unknown,
  headers?: Record<string, string>,
): Promise<HttpCallReturn<TResponseData>> => {
  const urlPath = getUrl(url);

  const config = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: buildHeaders(headers),
  };

  const response = await httpCall<TResponseData>(urlPath, config);
  return response;
};

const deleteApi = async <TResponseData>(
  url: Url | string,
  data?: Record<string, unknown> | unknown,
  headers?: Record<string, string>,
): Promise<HttpCallReturn<TResponseData>> => {
  const urlPath = getUrl(url);

  const config: RequestInit = {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: buildHeaders(headers),
  };

  const response = await httpCall<TResponseData>(urlPath, config);
  return response;
};

export {
  getApi, putApi, postApi, postApiFormData, patchApi, deleteApi,
};
