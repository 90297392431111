import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import TextField from '~Common/components/Fields/TextField';
import { FEEDBACK_TEMPLATE_SHAPE } from '~Common/const/proptypes';
import FeedbackQuestion from '~Deprecated/ui/components/Feedback/FeedbackQuestion';
import { withH4Font } from '~Common/styles/typography';
import { drawerInputBackground } from '~Deprecated/ui/styles/colors';
import Loader from '~Common/components/Loader';
import DashedBoxWithText from '~Deprecated/ui/components/EmptyStates/DashedBoxWithText';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';

const styles = {
  field: css`
    margin: 8px 0;
    background: ${drawerInputBackground};
  `,
  header: css`
    margin: 8px 0 16px;
  `,
  section: css`
    margin: 24px 0;
  `,
  createdByName: css`
    ${withH4Font()}
  `,
  question: css`
    margin: 8px 0;
  `,
};

const View = ({
  isReadOnly, template, onEditQuestion, setCurrentTitle, renderEditor, hasChanges,
}) => (
  <div>
    {!template && (
      <Loader />
    )}
    {hasChanges && (
      <DashedBoxWithText text="You have unsaved changes. Please save before starting a feedback request from this template." />
    )}
    {template && (
      <>
        <TextField
          name="title"
          disabled={isReadOnly}
          label="Template Name"
          initialValue={template.title}
          onChange={setCurrentTitle}
          css={styles.field}
          required
        />
        {renderEditor()}
        {template.owner && (
          <div css={styles.section}>
            <h4>Created by</h4>
            <div css={styles.createdByName}>
              {`${template.owner.name}`}
            </div>
          </div>
        )}
        <div css={styles.section}>
          <h4 css={styles.header}>Questions</h4>
          {template.question?.map((question, index) => (
            <FeedbackQuestion
              question={{ ...question, rank: index }}
              key={question.id}
              tabIndex={index}
              css={styles.question}
              onEditQuestion={onEditQuestion}
              disabled={isReadOnly}
            />
          ))}
        </div>
        {!isReadOnly && (
          <>
            {template.question?.length === 0 && (
              <DashedBoxWithText
                text="Click ‘Add Question’ to get started."
                renderBody={() => (
                  <h4>
                    Click&nbsp;
                    <span css={styles.questionsLabel}>‘Add Question’</span>
                    &nbsp;to get started
                  </h4>
                )}
              />
            )}
          </>
        )}
      </>
    )}
  </div>
);

View.propTypes = {
  isReadOnly: PropTypes.bool,
  template: FEEDBACK_TEMPLATE_SHAPE,
  onEditQuestion: PropTypes.func.isRequired,
  setCurrentTitle: PropTypes.func.isRequired,
  renderEditor: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
};

View.defaultProps = {
  isReadOnly: true,
  template: null,
};

const TemplateDetails = ({
  template, isReadOnly, isCreating, ...props
}) => {
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentDescription, setCurrentDescription] = useState('');
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setCurrentTitle(template?.title);
    setCurrentDescription(template?.introduction);
  }, [setCurrentTitle, setCurrentDescription, template]);

  useEffect(() => {
    if (!isCreating && !isReadOnly) {
      setHasChanges(template?.title !== currentTitle || template?.introduction !== currentDescription);
    }
  }, [currentTitle, currentDescription, template, setHasChanges, isReadOnly, isCreating]);

  const renderEditor = () => (
    <Froala
      name="description"
      isReadOnly={isReadOnly}
      label="Template Description"
      initialValue={template.description}
      styleOverrides={styles.field}
      onChange={setCurrentDescription}
      required
    />
  );

  const hookProps = {
    hasChanges,
    setCurrentTitle,
    isReadOnly,
    template,
    renderEditor,
  };

  return (
    <View {...props} {...hookProps} />
  );
};

TemplateDetails.propTypes = {
  template: FEEDBACK_TEMPLATE_SHAPE,
  isReadOnly: PropTypes.bool,
  isCreating: PropTypes.bool,
};

TemplateDetails.defaultProps = {
  template: null,
  isReadOnly: true,
  isCreating: false,
};

export default TemplateDetails;
