import { useEffect, useState } from 'react';
import moment from 'moment';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { getDateInfo, ordinalSuffix } from '~Meetings/hooks/useMeetingDateTimeFrequencyWeekdayTimezone';
import {
  useAdjustPulseSurveyFrequency,
  useAdminSettingsState,
  useDisablePulseSurvey,
} from '~Insights/hooks/useAdminSettingsDrawer';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import LabeledSwitch from '~Common/V3/components/LabeledSwitch';
import DatePicker from '~Deprecated/ui/components/Pickers/DatePicker';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import Dropdown from '~Common/V3/components/Dropdown';
import { SelectChangeEvent } from '@mui/material';

const styles = {
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
  closeButton: css`
    border: none;
    box-shadow: none;
    background-color: transparent;
  `,
  subHeading: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    lineHeight: 'unset',
    marginBottom: '0',
  }),
  link: css({
    color: palette.brand.indigo,
    fontSize: '.825rem',
    marginBottom: '1.25rem',
  }),
  datePicker: css({
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    border: '0',
    width: '100%',
    marginBottom: '.5rem',
  }),
  dropdown: css({
    marginTop: '0.5rem',
    '& .Mui-disabled': {
      cursor: 'not-allowed',
    },
  }),
  switch: css({
    margin: '1.25rem 0',
  }),
  text: css({
    color: palette.neutrals.gray500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  }),
  cushion: css({
    margin: '3rem 0',
  }),
  skeleton: css({
    margin: '0rem 1rem',
    maxWidth: '100%',
  }),
};

const adminSettingsTemplate = {
  name: 'INSIGHTS_ADMIN_SETTINGS',
  width: DRAWER_WIDTHS.BASE,
};

const Skeleton = (): JSX.Element => (
  <>
    <SkeletonLoader
      height="3rem"
      css={styles.skeleton}
      renderComponent={() => <></>}
    />
    <SkeletonLoader
      height="1rem"
      css={styles.skeleton}
      renderComponent={() => <></>}
    />
    <SkeletonLoader
      height="1rem"
      css={styles.skeleton}
      renderComponent={() => <></>}
    />
  </>
);

interface ViewProps {
  showSkeleton: boolean,
  onSaveFrequency?: () => void,
  closeDrawerClick: () => void,
  startDate: Date,
  onDateSelected: (value: { date: Date; }) => void,
  isEngagementEnabled: boolean,
  onToggleEngagementEnabled: (value: boolean) => void,
  isENPSEnabled: boolean,
  onToggleENPSEnabled: (value: boolean) => void,
  frequencyOptions: {
    value: string,
    text: string,
  }[],
  selectedFrequency: string,
  onFrequencyChange: (event: SelectChangeEvent<string>) => void,
  isPulseSurveyFrequencyLoading: boolean,
  isPulseSurveyDisabling: boolean,
}

const View = ({
  showSkeleton,
  closeDrawerClick,
  onSaveFrequency,
  startDate,
  onDateSelected,
  isEngagementEnabled,
  onToggleEngagementEnabled,
  isENPSEnabled,
  onToggleENPSEnabled,
  frequencyOptions,
  selectedFrequency,
  onFrequencyChange,
  isPulseSurveyFrequencyLoading,
  isPulseSurveyDisabling,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={() => (
          <button onClick={closeDrawerClick} css={styles.closeButton} title="Close drawer">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        title="Admin Settings"
      />
    )}
    renderBody={showSkeleton ? () => <Skeleton /> : () => (
      <div
        css={styles.drawerBody}
      >
        <h2 css={styles.subHeading}>Engagement</h2>
        <p css={styles.link}>
          <a rel="noreferrer" target="_blank" href="https://leadr.helpscoutdocs.com/article/29-insights-admin-or-executive">Learn more about Leadr&apos;s engagement survey here.</a>
        </p>
        <LabeledSwitch
          id="is_engagement_enabled"
          checked={isEngagementEnabled}
          onChange={(e) => onToggleEngagementEnabled(e.target.checked)}
          activeLabel="Enabled"
          inactiveLabel="Disabled"
          css={styles.switch}
        />
        <DatePicker
          css={styles.datePicker}
          required
          disablePast
          minDate={moment().add(1, 'd')}
          selectedDate={startDate}
          label="Start Date"
          name="date"
          onDateSelected={onDateSelected}
          iconType={null}
          className=""
          size=""
          disabled={!isEngagementEnabled}
        />
        <Dropdown
          css={styles.dropdown}
          name="frequency"
          items={frequencyOptions}
          label="Frequency"
          onChange={onFrequencyChange}
          value={selectedFrequency}
          disabled={!isEngagementEnabled || frequencyOptions.length < 2}
        />

        {isEngagementEnabled && (
          <>
            <div css={styles.cushion} />

            <h2 css={styles.subHeading}>eNPS</h2>
            <p css={styles.link}>
              <a rel="noreferrer" target="_blank" href="https://leadr.helpscoutdocs.com/article/75-insights-dashboard-breakdown">Learn more about eNPS here.</a>
            </p>
            <LabeledSwitch
              id="is_enps_enabled"
              css={styles.switch}
              checked={isENPSEnabled}
              onChange={(e) => onToggleENPSEnabled(e.target.checked)}
              activeLabel="Enabled"
              inactiveLabel="Disabled"
            />
            <p css={styles.text}>This will add one additional question every three months to your engagement pulse survey.</p>
          </>
        )}

        <div css={styles.cushion} />
        <SpinnerButton
          color="secondary"
          size="small"
          label="Save"
          loadingLabel="Saving..."
          isLoading={isPulseSurveyFrequencyLoading || isPulseSurveyDisabling}
          onClick={onSaveFrequency}
        />
      </div>
    )}
  />
);

interface FrequencyOption {
  text: string,
  value: string,
}

const AdminSettings = ({ ...props }): JSX.Element => {
  const dispatch = useDispatch();
  const { isLoading, insightSettings } = useAdminSettingsState();
  const [showSkeleton] = useSkeletonLoaders(isLoading);

  const [isEngagementEnabled, setIsEngagementEnabled] = useState(false);
  const [isENPSEnabled, setIsENPSEnabled] = useState(false);
  const [startDate, setStartDate] = useState(moment().add(1, 'd').toDate());
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [frequencyOptions, setFrequencyOptions] = useState<FrequencyOption[]>([]);
  const [isFrequencyDirty, setIsFrequencyDirty] = useState(false);

  const { mutateAsync: disablePulseSurveyMutation, isPending: isPulseSurveyDisabling } = useDisablePulseSurvey();
  const { mutateAsync: adjustPulseSurveyFrequencyMutation, isPending: isPulseSurveyFrequencyLoading } = useAdjustPulseSurveyFrequency();

  useEffect(() => {
    if (!insightSettings) return;

    const { pulseEnabled, pulseFrequency, eNPSEnabled } = insightSettings;
    setIsEngagementEnabled(pulseEnabled);
    setIsENPSEnabled(eNPSEnabled);

    if (pulseFrequency && 'startOn' in pulseFrequency) {
      const { startOn, dayOfMonth } = pulseFrequency;
      setStartDate(new Date(startOn));
      setSelectedFrequency(dayOfMonth.key.toString());
    }
  }, [insightSettings]);

  const onFrequencyChange = (event: SelectChangeEvent<string>): void => {
    setSelectedFrequency(event.target.value);
    setIsFrequencyDirty(true);
  };

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
  };

  const onSaveFrequency = async (): Promise<void> => {
    if (!isEngagementEnabled) {
      await disablePulseSurveyMutation();
      setIsFrequencyDirty(false);
      return;
    }

    const mDate = moment(startDate);
    await adjustPulseSurveyFrequencyMutation({
      startOn: mDate.format('YYYY-MM-DD'),
      dayOfMonth: selectedFrequency,
      dayOfWeek: (2 ** mDate.weekday()).toString(),
      enableENPS: isENPSEnabled,
    });
    setIsFrequencyDirty(false);
  };

  const onDateSelected = (value: { date: Date; }): void => {
    setStartDate(value.date);
    setIsFrequencyDirty(true);
  };

  const onToggleEngagementEnabled = (value: boolean): void => {
    setIsEngagementEnabled(value);
    setIsFrequencyDirty(true);
  };

  const onToggleENPSEnabled = (value: boolean): void => {
    setIsENPSEnabled(value);
    setIsFrequencyDirty(true);
  };

  useEffect(() => {
    const dateInfo: { weekOfMonth: number, isLastWeekDay: boolean, weekDay: string; } = getDateInfo(moment(startDate));
    const { weekOfMonth, isLastWeekDay, weekDay } = dateInfo;

    const options = [];
    if (weekOfMonth <= 4) {
      options.push({
        value: (2 ** (weekOfMonth - 1)).toString(),
        text: `Monthly on the ${ordinalSuffix(weekOfMonth)} ${weekDay}`,
      });
    }

    if (isLastWeekDay) {
      options.push({
        value: (2 ** 4).toString(),
        text: `Monthly on the last ${weekDay}`,
      });
    }

    setFrequencyOptions([...options]);

    if (!options.find((option) => option.value === selectedFrequency)) {
      setSelectedFrequency(options[0]?.value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const hookProps = {
    showSkeleton,
    closeDrawerClick,
    startDate,
    frequencyOptions,
    selectedFrequency,
    isEngagementEnabled,
    isENPSEnabled,
    isFrequencyDirty,
    isPulseSurveyFrequencyLoading,
    isPulseSurveyDisabling,
    onDateSelected,
    onToggleEngagementEnabled,
    onToggleENPSEnabled,
    onFrequencyChange,
    onSaveFrequency,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: adminSettingsTemplate.name,
  component: AdminSettings,
});

export { View, adminSettingsTemplate };
export default AdminSettings;
