import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { DEFAULT_PAGE_SIZE } from '~Common/hooks/utils';
import { hosts } from '~Deprecated/services/config';
import { getApi } from '~Deprecated/services/HttpService';
import { flattenData } from '~Deprecated/utils/reduxUtils';
import { getOrganizationId } from '~Common/utils/localStorage';
import { normalizeFeedbackFromServer } from '~Deprecated/utils/normalizers/feedback';
import { useGroupings } from '~Common/hooks/useGroupings';
import { useGlobalSearch } from '~Common/hooks/useGlobalSearch';
import { buildQueryString } from '~Common/utils';
import { FeedbackSortByField, FeedbackType } from '~Feedback/const/interfaces';
import { useFeedbackSearch } from '~Feedback/stores/useFeedbackSearch';
import { FEEDBACK_RECEIVED } from '~Common/const/routes';
import { useLocation } from 'react-router-dom';
import { SortDirection } from '~Common/const/interfaces';

export const feedbackQuery = async ({ queryKey, pageParam }) => {
  const [organizationId, _, type, pageSize, sortOrder, sortField] = queryKey;

  const queryParams = {
    skip: pageParam || 0,
    take: pageSize,
    sortByField: sortField,
    sortByOrder: sortOrder,
  };
  const queryData = buildQueryString(queryParams);

  const URL = {
    host: hosts.feedback,
    uri: `/organizations/${organizationId}/feedbacks/${type.toLowerCase()}${queryData}`,
  };

  const headers = {
    'endpoint-version': 2,
  };

  const { response } = await getApi(URL, headers, {});

  const { ids, data } = flattenData({
    responseData: (response && response.items),
    onItemFlatten: (item) => normalizeFeedbackFromServer({ data: item }),
  });

  return { ids, data };
};

export const useFeedback = ({
  type,
  pageSize = DEFAULT_PAGE_SIZE,
  sortOrder = SortDirection.DESCENDING,
  sortField = FeedbackSortByField.ASSIGNED_DATE,
}) => {
  const {
    debouncedSearchText,
  } = useFeedbackSearch((state) => ({
    debouncedSearchText: state.debouncedSearchText,
  }));
  const location = useLocation();

  const routeFeedbackType = location.pathname === FEEDBACK_RECEIVED ? FeedbackType.RECEIVED : FeedbackType.REQUESTED;

  const feedbackTypeToUse = type || routeFeedbackType;

  const isReceivedFeedback = feedbackTypeToUse === FeedbackType.RECEIVED;

  const queryKey = [getOrganizationId(), 'feedback', feedbackTypeToUse, pageSize, sortOrder, sortField];
  const result = useQuery({
    queryKey,
    queryFn: feedbackQuery,
  });
  const { ids, data } = result.data ?? { ids: [], data: {} };

  const buildRelationships = useCallback((peopleData) => (
    ids.map((id) => {
      const feedback = data[id];
      const statusCheck = isReceivedFeedback ? feedback.isCompletedByLoggedInUser : feedback.isCompleted;

      return {
        ...feedback,
        groupBy: {
          status: statusCheck ? 'Completed' : 'Incomplete',
        },
      };
    })
  ), [ids, data]);

  const { ids: searchIds, data: searchData } = useGlobalSearch(ids, buildRelationships, debouncedSearchText, 'id');

  const { groupedIds, groupedData } = useGroupings({
    ids: searchIds,
    data: searchData,
    groupKey: 'groupBy.status',
    groupKeyOrder: {},
    comparator: (a, b) => (a === 'Incomplete' ? -1 : 1),
  });

  return {
    ids: searchIds,
    groupedIds,
    data,
    result,
    initialPageItemCount: result?.data?.ids?.length,
    searchString: debouncedSearchText,
    isLoading: result.isLoading,
  };
};

export const useFeedbackById = ({ id, type }) => {
  const { data } = useFeedback({ type });
  const feedback = data[id];

  return {
    feedback,
  };
};

export const getFeedbackDetails = async ({ queryKey }) => {
  const [organizationId, _, feedbackId, assigneeId, isReadOnly] = queryKey;
  const URL = `/organizations/${organizationId}/feedbacks/${feedbackId}/assignee/${assigneeId}?asReadOnly=${isReadOnly}`;

  return getApi(URL);
};

export const useFeedbackDetails = ({ feedbackId, assigneeId, isReadOnly = true }) => {
  const detail = useQuery({
    queryKey: [getOrganizationId(), 'feedback', feedbackId, assigneeId, isReadOnly],
    queryFn: getFeedbackDetails,
  });

  return {
    feedback: detail?.data?.response,
    isLoading: detail.isLoading,
  };
};
