import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { useMemo, useContext } from 'react';
import { MeetingInfoContext } from '../../../providers/MeetingInfoContext';

const styles = {
  teamNameText: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
  icon: css({
    marginRight: '.375rem',
    width: '.75rem',
    height: '.75rem',
  }),
};

interface ViewProps {
  teamName: string,
}

const View = ({
  teamName,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.teamNameText}
    {...props}
  >
    <FontAwesomeIcon
      css={styles.icon}
      icon={faUsers}
    />
    <span>{teamName}</span>
  </div>
);

const TeamNameText = ({ ...props }): JSX.Element => {
  const { teamId } = useContext(MeetingInfoContext);
  const { data: teamDetailsData } = useGetTeamDetails({ teamId });

  const teamName = useMemo(() => (
    teamDetailsData?.response.name || ''
  ), [teamDetailsData?.response.name]);

  const hookProps = {
    teamName,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TeamNameText;
