import { useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { learningTemplatesQueryKeys } from '../queryKeys';
import { LearningPlaylistTemplateDetail } from '../playlists/useGetLearningPlaylistTemplateDetail';

interface GetLearningCuratedPlaylistDetailParams {
  playlistTemplateId: number,
}

const getLearningCuratedPlaylistTemplateDetail = async ({
  playlistTemplateId,
}: GetLearningCuratedPlaylistDetailParams): Promise<HttpCallReturn<LearningPlaylistTemplateDetail>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/templates/curatedByLeadr/${playlistTemplateId}`;

  return getApi<LearningPlaylistTemplateDetail>(serverUrl);
};

interface UseGetLearningPlaylistTemplateDetailParams {
  playlistTemplateId: number,
  enabled?: boolean,
}

interface UseGetLearningCuratedPlaylistTemplateDetailReturn {
  detail: LearningPlaylistTemplateDetail | undefined,
  isLoading: boolean,
}

export const useGetLearningCuratedPlaylistTemplateDetail = ({
  playlistTemplateId,
  enabled = true,
}: UseGetLearningPlaylistTemplateDetailParams): UseGetLearningCuratedPlaylistTemplateDetailReturn => {
  const result = useQuery({
    queryKey: learningTemplatesQueryKeys.playlistDetail(playlistTemplateId),
    queryFn: () => getLearningCuratedPlaylistTemplateDetail({
      playlistTemplateId,
    }),
    enabled: enabled && !!playlistTemplateId,
  });

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
