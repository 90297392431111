import { Profile } from '~Common/const/interfaces';
import { OrgSettings } from '~Common/const/Organization';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';

const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

type CustomFavoriteKey = 'customFavorite1Name' | 'customFavorite2Name';

const getCustomFavorites = (orgSettingEntries: [keyof OrgSettings, unknown]): [CustomFavoriteKey, string][] => {
  // @ts-expect-error TS Mess
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const customFavoriteSettings = orgSettingEntries.filter(([key]) => key.includes('customFavorite')) as [CustomFavoriteKey, string][];

  customFavoriteSettings.sort(
    ([favorite1Key], [favorite2Key]) => parseInt(favorite1Key.replace(/[^0-9]/g, ''), 10) - parseInt(favorite2Key.replace(/[^0-9]/g, ''), 10),
  );
  return customFavoriteSettings;
};

interface GetFormattedCustomFavoritesParams {
  user: Profile,
  customFavorites: [CustomFavoriteKey, string][],
  orgSettings: OrgSettings,
}

interface FormattedCustomFavorite {
  title: string,
  valueName: string,
  value: string,
  enabled: boolean,
}

const getFormattedCustomFavorites = ({
  user,
  customFavorites,
  orgSettings,
}: GetFormattedCustomFavoritesParams): FormattedCustomFavorite[] => (
  customFavorites.map(([key, value]) => ({
    title: value,
    valueName: key.replace('Name', 'Value'),
    // @ts-expect-error TS Mess
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    value: user.orgUserProfile[key.replace('Name', 'Value')],
    // @ts-expect-error TS Mess
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    enabled: orgSettings[`enable${capitalizeFirstLetter(key).replace('Name', '')}`],
  })).filter((p) => !!p.enabled)
);

interface UseGetFormattedCustomFavoritesReturn {
  isLoading: boolean,
  formattedCustomFavorites: FormattedCustomFavorite[],
}

export const useGetFormattedCustomFavorites = (userId: string): UseGetFormattedCustomFavoritesReturn => {
  const { orgSettings } = useOrgDetailsContext();
  const orgSettingsEntries = Object.entries(orgSettings) as [keyof OrgSettings, unknown];
  const { detail, isLoading } = useUserProfile(userId);
  const user = detail.data?.response;
  if (!user) {
    return {
      isLoading,
      formattedCustomFavorites: [],
    };
  }
  const customFavorites = getCustomFavorites(orgSettingsEntries);
  console.log('customFavorites', customFavorites);
  const formattedCustomFavorites = getFormattedCustomFavorites({
    user,
    customFavorites,
    orgSettings,
  });

  return {
    isLoading,
    formattedCustomFavorites,
  };
};
