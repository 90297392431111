import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { PropsWithChildren, useEffect } from 'react';
import { withPrintStylesObject } from '~Common/styles/mixins';
import { usePrintView } from '~Common/stores/usePrintView';
import BasePrintHeader, { BasePrintHeaderSkeleton } from './BasePrintHeader';
import BasePrintBody from './BasePrintBody';

const styles = {
  printWrapper: css({
    display: 'flex',
    width: '100%',
    height: '100%',
  }),
  printWrapperSecondLayer: css({
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: palette.neutrals.white,
    '@page': {
      padding: '.25in',
      size: 'auto',
    },
    '@page :first': {
      marginTop: 0,
    },
    '-webkit-print-color-adjust': 'exact',
    '-webkit-filter': 'opacity(1)',
    printColorAdjust: 'exact',
    paddingBottom: '1.5rem',
  }, withPrintStylesObject({
    overflowY: 'visible',
  })),
  printWrapperThirdLayer: css({
    width: '75%',
    maxWidth: '62.5rem',
  }, withPrintStylesObject({
    width: '100%',
    maxWidth: '100%',
  })),
};

const View = ({
  children,
  ...props
}: PropsWithChildren<unknown>): JSX.Element => (
  <div css={styles.printWrapper} {...props}>
    <div css={styles.printWrapperSecondLayer}>
      <div css={styles.printWrapperThirdLayer}>
        {children}
      </div>
    </div>
  </div>
);

const PrintWrapperBase = ({ ...props }: PropsWithChildren<unknown>): JSX.Element => {
  /*
    TODO: Ideally we remove this and we have separate print view components per page so we have more separation of concerns
    But keeping this around now to not break old stuff
  */
  const setPrintView = usePrintView((state) => state.setPrintView);

  useEffect(() => {
    setPrintView(true);

    return () => {
      setPrintView(false);
    };
  }, [setPrintView]);

  const hookProps = {};

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

// Way to add children as something you can use in this component, has to be done with Object.assign to get around weird TS error
const PrintWrapper = Object.assign(PrintWrapperBase, { BasePrintHeader, BasePrintHeaderSkeleton, BasePrintBody });

export default PrintWrapper;
