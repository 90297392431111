import { css } from '@emotion/react';
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle, faMessage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DataGrid,
  DataGridProps,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { usePagination } from '~Common/hooks/usePagination';
import { Person, SortDirection, UserStatus } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import {
  ActionItem, ActionItemContext, ActionItemContextType, NewActionItemStatus,
} from '~ActionItems/const/interfaces';
import ActionItemStatus from '~ActionItems/components/ActionItemStatus';
import { ActionItemSortField, ActionItemAssigneeType, useActionItems } from '~ActionItems/hooks/useActionItems';
import Pagination, { PaginationProps } from '~Common/V3/components/Pagination';
import { useDispatch } from 'react-redux';
import { createEditActionItemTemplate } from '~ActionItems/components/Drawers/CreateEditActionItemDrawer';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { getAssociationData } from '~ActionItems/functions/utils';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import noSearchResults from '~Assets/images/noSearchResults.png';
import emptyActionItems from '~ActionItems/assets/images/emptyActionItems.png';
import { useEditActionItem } from '~ActionItems/hooks/useEditActionItem';
import { completedStateFilterValue, emptyStateFilterValue } from '~ActionItems/components/ActionItems';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { useActionItemsSearch } from '~ActionItems/stores/useActionItemsSearch';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { getDateString } from '~Common/utils/dateString';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const ACTION_ITEMS_PAGE_SIZE = 25;

const actionItemSortColumnField: Record<string, ActionItemSortField> = {
  text: ActionItemSortField.Text,
  assignee: ActionItemSortField.Assignee,
  dueDate: ActionItemSortField.DueDate,
  completedDate: ActionItemSortField.CompletedDate,
  status: ActionItemSortField.Status,
  association: ActionItemSortField.TaskContext,
  commentCount: ActionItemSortField.CommentCount,
};

const actionItemSortColumnFieldInverse: Record<string, string> = {
  [ActionItemSortField.Text]: 'text',
  [ActionItemSortField.Assignee]: 'assignee',
  [ActionItemSortField.DueDate]: 'dueDate',
  [ActionItemSortField.CompletedDate]: 'completedDate',
  [ActionItemSortField.Status]: 'status',
  [ActionItemSortField.TaskContext]: 'association',
  [ActionItemSortField.CommentCount]: 'commentCount',
};

const styles = {
  actionItemsTable: css({
    color: palette.neutrals.gray800,
    border: 0,

    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '.MuiDataGrid-columnHeaders': {
      backgroundColor: palette.neutrals.gray100,
      border: 0,
      borderRadius: 0,
    },
  }),
  completedIconButton: css({
    backgroundColor: 'transparent',
    padding: 0,
    border: 0,

    // Flex needed to align the icon vertically
    display: 'flex',
  }),
  completedIcon: (isComplete: boolean) => css({
    height: '1.25rem',
    width: '1.25rem',
    color: isComplete ? palette.brand.indigo : palette.neutrals.gray400,
  }),
  assigneeField: css({
    display: 'flex',
    alignItems: 'center',
  }),
  avatar: css({
    marginRight: '0.5rem',
  }),
  associationIcon: css({
    marginRight: '0.5rem',
  }),
  dueDate: (hasDueDate?: boolean, isPastDue?: boolean, isSoon?: boolean) => {
    let color = 'inherit';

    if (!hasDueDate) {
      color = palette.neutrals.gray300;
    } else if (isPastDue) {
      color = palette.brand.red;
    } else if (isSoon) {
      color = palette.brand.green;
    }

    return css({
      alignItems: 'center',
      color,
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
    });
  },
  recurrenceIcon: css({
    color: palette.neutrals.gray500,
  }),
  skeleton: css({
    width: '100%',
    maxWidth: '100%',
    height: '5rem',
    transform: 'initial',
  }),
  assigneeName: (isMobile: boolean) => css({
    fontSize: isMobile ? '12px' : '14px',
  }),
  createActionItemLinkButton: css({
    fontWeight: 800,
    textDecoration: 'underline',
  }),
  emptyStateContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    h6: {
      textTransform: 'unset',
    },
  }),
  emptyStateImage: css({
    height: '20rem',
  }),
};

export interface ActionItemRowAssignee {
  imgUrl?: string,
  name: string,
  isDeactivated: boolean,
}

export interface ActionItemRowDueDate {
  dueDate: number,
  isRecurring: boolean,
}

export interface ActionItemRow {
  id: string,
  completed: boolean,
  text: string,
  assignee: ActionItemRowAssignee,
  dueDate: ActionItemRowDueDate,
  association?: ActionItemContext,
  status: NewActionItemStatus,
}

export interface ViewProps extends Omit<DataGridProps<ActionItemRow>, 'page' | 'onPageChange'>, Omit<PaginationProps, 'onError' | 'onResize'> {
  isLoading: boolean,
  onCreateActionItemClick: () => void,
  isSearchedOrFiltered: boolean,
  sortByField: ActionItemSortField,
  sortByOrder: SortDirection,
}

const View = ({
  isLoading,
  rows,
  columns,
  pageSize,
  onSortModelChange,
  page,
  onPageChange,
  numberOfPages,
  onPreviousClick,
  onNextClick,
  onRowClick,
  onCreateActionItemClick,
  isSearchedOrFiltered,
  sortByField,
  sortByOrder,
}: ViewProps): JSX.Element => (
  <>
    {isLoading && (
      <SkeletonLoader
        css={styles.skeleton}
        renderComponent={() => (<></>)}
      />
    )}

    {!isLoading && (
      <>
        {rows.length === 0 && (
          <div css={styles.emptyStateContainer}>
            {isSearchedOrFiltered && (
              <EmptyStateWithImage
                renderImage={() => (
                  <img
                    css={styles.emptyStateImage}
                    src={noSearchResults}
                    alt="No Search Results"
                    data-test-id="actionItemsNoSearchResults"
                  />
                )}
                text="No action items found."
              />
            )}

            {!isSearchedOrFiltered && (
              <EmptyStateWithImage
                renderImage={() => (
                  <img
                    css={styles.emptyStateImage}
                    src={emptyActionItems}
                    alt="Empty Action Items"
                    data-test-id="emptyActionItems"
                  />
                )}
                renderText={() => (
                  <span>
                    You have no action items.
                    <LeadrButton
                      variant="text"
                      textButtonColor={palette.brand.blue}
                      onClick={onCreateActionItemClick}
                      data-test-id="actionItemsEmptyStateCreateActionItem"
                    >
                      Click here to create one.
                    </LeadrButton>
                  </span>
                )}
              />
            )}
          </div>
        )}

        {rows.length > 0 && (
          <>
            <DataGrid
              css={styles.actionItemsTable}
              autoHeight
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick
              sortingMode="server"
              headerHeight={38}
              rowHeight={56}
              hideFooter
              pageSize={pageSize}
              rows={rows}
              sortingOrder={['desc', 'asc']}
              onSortModelChange={onSortModelChange}
              onRowClick={onRowClick}
              sortModel={[
                {
                  field: actionItemSortColumnFieldInverse[sortByField],
                  sort: sortByOrder === SortDirection.Ascending ? 'asc' : 'desc',
                },
              ]}
            />

            <Pagination
              page={page}
              onPageChange={onPageChange}
              numberOfPages={numberOfPages}
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
            />
          </>
        )}
      </>
    )}
  </>
);

interface ActionItemSearchProps {
  statusFilter: NewActionItemStatus[],
  assigneeTypeFilter: ActionItemAssigneeType[],
}

const ActionItemsTable = ({
  statusFilter,
  assigneeTypeFilter,
}: ActionItemSearchProps): JSX.Element => {
  const { mutate: doEditActionItem } = useEditActionItem({});
  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, Person>>;
  const isMobile = useIsMobileQuery();
  const areCommentsEnabled = useFeatureFlag('webClientUseActionItemComments');

  const {
    debouncedSearchText,
  } = useActionItemsSearch((state) => ({
    debouncedSearchText: state.debouncedSearchText,
  }));

  const previousSearch = usePrevious(debouncedSearchText);
  const previousStatusFilter = usePrevious(statusFilter);
  const previousAssigneeTypeFilter = usePrevious(assigneeTypeFilter);

  const [page, setPage] = useState(1);
  const [taskContext] = useState('');

  const [sortByField, setSortByField] = useState(ActionItemSortField.DueDate);
  const [sortByOrder, setSortByOrder] = useState(SortDirection.Ascending);

  const isCompletedFilter = useMemo(() => (
    statusFilter.includes(NewActionItemStatus.Completed)
  ), [statusFilter]);

  // If the filters/searchText change, reset the page number so we don't look at page 2 of a 1 page searchText as an example.
  useEffect(() => {
    if (
      debouncedSearchText !== previousSearch
      || statusFilter !== previousStatusFilter
      || assigneeTypeFilter !== previousAssigneeTypeFilter
    ) {
      setPage(1);
    }
  }, [debouncedSearchText, statusFilter, assigneeTypeFilter, previousAssigneeTypeFilter, previousSearch, previousStatusFilter]);

  useEffect(() => {
    if (isCompletedFilter && sortByField === ActionItemSortField.DueDate) {
      setSortByField(ActionItemSortField.CompletedDate);
      setSortByOrder(SortDirection.Descending);
    } else if (!isCompletedFilter && sortByField === ActionItemSortField.CompletedDate) {
      setSortByField(ActionItemSortField.DueDate);
      setSortByOrder(SortDirection.Ascending);
    }
  }, [isCompletedFilter, sortByField]);

  const { data, isLoading } = useActionItems({
    skip: (page - 1) * ACTION_ITEMS_PAGE_SIZE,
    take: ACTION_ITEMS_PAGE_SIZE,
    status: statusFilter,
    taskContext,
    assigneeType: assigneeTypeFilter,
    search: debouncedSearchText,
    sortByField,
    sortByOrder,
  });

  const usePaginationProps = usePagination({
    totalCount: data?.response?.total ?? 0,
    pageSize: ACTION_ITEMS_PAGE_SIZE,
    page,
    setPage,
  });

  const dispatch = useDispatch();

  const onClick = useCallback((e: MouseEvent, id: string, status: NewActionItemStatus) => {
    // Prevent the click event from bubbling to the row and opening the edit drawer
    e.stopPropagation();

    if (id) {
      doEditActionItem({
        id,
        actionItem: {
          status: status === NewActionItemStatus.Completed ? NewActionItemStatus.ToDo : NewActionItemStatus.Completed,
        },
      });
    }
  }, [doEditActionItem]);

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel.length) {
      setSortByField(actionItemSortColumnField[sortModel[0].field]);
      setSortByOrder(sortModel[0].sort === 'asc' ? SortDirection.Ascending : SortDirection.Descending);
    }
  }, []);

  const tableColumnHeaders: GridColumns<ActionItemRow> = [
    {
      field: 'completed',
      headerName: '',
      width: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams<boolean, ActionItemRow>) => {
        if (params.value === undefined) {
          return null;
        }

        return (
          <button
            css={styles.completedIconButton}
            type="button"
            onClick={(e) => onClick(e, params.row.id, params.row.status)}
            data-test-id="actionItemsCompleteButton"
          >
            <FontAwesomeIcon
              css={styles.completedIcon(params.value)}
              icon={params.value ? faCircleCheck : faCircle}
            />
          </button>
        );
      },
    },
    {
      field: 'text',
      headerName: 'Action Item',
      flex: 1,
      hide: isMobile,
    },
    {
      field: 'commentCount',
      headerName: '',
      maxWidth: 75,
      sortable: false,
      hide: !areCommentsEnabled || isMobile,
      renderHeader: () => (
        <FontAwesomeIcon
          icon={faMessage}
        />
      ),
      renderCell: (params: GridRenderCellParams<ActionItemRowAssignee, ActionItemRow>) => {
        if (!params.value) {
          return <></>;
        }

        return <>{params.value}</>;
      },
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      minWidth: 250,
      flex: isMobile ? 1 : 0,
      sortable: false,
      renderCell: (params: GridRenderCellParams<ActionItemRowAssignee, ActionItemRow>) => {
        if (!params.value) {
          return null;
        }

        const {
          imgUrl,
          name,
          isDeactivated,
        } = params.value;

        return (
          <div>
            {isMobile && (
              <div>
                { params.row.text }
              </div>
            )}

            <div css={styles.assigneeField}>
              <SquareAvatar
                imgUrl={imgUrl}
                name={name}
                isDeactivated={isDeactivated}
                width={isMobile ? 20 : 30}
                height={isMobile ? 20 : 30}
                includeInitials
                css={styles.avatar}
              />

              <div css={styles.assigneeName(isMobile)}>
                {isDeactivated ? `${name} (Deactivated)` : name}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: isCompletedFilter ? 'completedDate' : 'dueDate',
      headerName: isCompletedFilter ? 'Date Completed' : 'Due Date',
      minWidth: isCompletedFilter ? 175 : 150,
      renderCell: (params: GridRenderCellParams<ActionItemRowDueDate, ActionItemRow>) => {
        const { dueDate = 0, isRecurring = false } = params.value ?? {};
        const {
          dateString,
          isPast,
          isToday,
          isTomorrow,
        } = getDateString({ timestamp: dueDate });

        const hasDueDate = dueDate > 0;

        return (
          <div>
            <div css={styles.dueDate(hasDueDate, !!isPast && !isCompletedFilter, isToday || isTomorrow)}>
              { dateString }
              {isRecurring && (
                <FontAwesomeIcon css={styles.recurrenceIcon} icon={faArrowsRepeat} flip="horizontal" />
              )}
            </div>

            {isMobile && (
              <ActionItemStatus status={params.row.status} />
            )}
          </div>
        );
      },
    },
    {
      field: 'association',
      headerName: 'Association',
      minWidth: 150,
      hide: isMobile,
      // API does not currently support sorting by context/association.
      // ToDo: Re-enable sorting on this column once it does.
      sortable: false,
      renderCell: (params: GridRenderCellParams<ActionItemContext>) => {
        const associationData = getAssociationData(params.value);

        return (
          <div>
            <FontAwesomeIcon
              css={styles.associationIcon}
              flip={params?.value?.type === ActionItemContextType.Goal ? 'horizontal' : undefined}
              icon={associationData.icon}
            />

            <span>
              {associationData.text}
            </span>
          </div>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      hide: isMobile,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<NewActionItemStatus>) => {
        if (!params.value) {
          return null;
        }

        return (
          <ActionItemStatus status={params.value} />
        );
      },
    },
  ];

  const rows: ActionItemRow[] = useMemo(() => data?.response?.items?.map((actionItem: ActionItem) => {
    const assignee = peopleData?.[actionItem.assigneeId];
    const isDeactivated = assignee?.administrativeStatus === UserStatus.inactive;

    return {
      id: actionItem.id,
      completed: actionItem.status === NewActionItemStatus.Completed,
      text: actionItem.text,
      assignee: {
        imgUrl: assignee?.profileImageUrl,
        name: `${assignee?.firstName} ${assignee?.lastName}`,
        isDeactivated,
      },
      dueDate: { dueDate: actionItem.dueDateInMillis, isRecurring: actionItem.isRecurring },
      completedDate: { dueDate: actionItem.completedInMillis, isRecurring: actionItem.isRecurring },
      association: actionItem.context,
      status: actionItem.status,
      commentCount: actionItem.commentCount,
    };
  }) ?? [], [data, peopleData]);

  const onActionItemSelection = useCallback((params: GridRowParams<ActionItemRow>) => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
        args: {
          id: params.row.id,
        },
      },
    }));
  }, [dispatch]);

  const onCreateActionItemClick = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
      },
    }));
  }, [dispatch]);

  const isSearchedOrFiltered = useMemo(() => {
    const hasSearch = !!debouncedSearchText;
    const hasAssigneeFilter = !!assigneeTypeFilter.length;
    const hasStatusFilter = statusFilter !== emptyStateFilterValue && statusFilter !== completedStateFilterValue;

    return hasSearch || hasAssigneeFilter || hasStatusFilter;
  }, [debouncedSearchText, assigneeTypeFilter, statusFilter]);

  const hookProps = {
    isLoading,
    rows,
    columns: tableColumnHeaders,
    pageSize: ACTION_ITEMS_PAGE_SIZE,
    onSortModelChange,
    onRowClick: onActionItemSelection,
    onCreateActionItemClick,
    page,
    isSearchedOrFiltered,
    sortByField,
    sortByOrder,
    ...usePaginationProps,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default ActionItemsTable;
