import { css } from '@emotion/react';
import { SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import React, {
  useState, useEffect, ChangeEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { usePagination } from '~Common/hooks/usePagination';
import { CardSkeleton } from '~Common/V3/components/Card';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import Pagination, { PaginationProps } from '~Common/V3/components/Pagination';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import {
  LEARNING_DEFAULT_PAGE_NUMBER, LEARNING_LIBRARY_PAGE_SIZE,
} from '~Learning/const';
import { LearningTemplate, LearningType, OnUseTemplateParams } from '~Learning/const/interfaces';

import { viewLearningPlaylistTemplateDrawerTemplate } from '~Learning/components/LearningLibrary/ViewLearningPlaylistTemplateDrawer';

import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { ViewLearningLibraryDrawerState, viewLearningLibraryDrawerTemplate } from '~Learning/components/LearningLibrary/ViewLearningLibraryDrawer';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import IconContents from '~Common/V3/components/Button/IconContents';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import yellowQuestionMark from '~Assets/images/empty-views/yellowQuestionMark.png';
import emptyLearningTemplate from '~Learning/assets/images/emptyLearningTemplate.png';
import { palette, hexToRGBA } from '~Common/styles/colors';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { PartialDrawerState } from '~Common/const/drawers';
import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';
import PreviewWithContentCount from '~Learning/components/Shared/ContentPreviews/PreviewWithContentCount';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { useLearningLibrarySearchStore } from '~Learning/stores/useLearningLibrarySearchStore';
import { useCombinedLearningTemplateSearch } from '~Learning/hooks/utils/useCombinedLearningTemplateSearch';
import { useGetCombinedLearningTemplateList } from '~Learning/hooks/utils/useGetCombinedLearningTemplateList';
import { selectLearningTypeDrawerTemplate } from '../SelectLearningTypeDrawer';
import { createLearningTemplateDrawerTemplate } from '../CreateLearningTemplateDrawer';
import { viewLearningTemplateDrawerTemplate } from '../ViewLearningTemplateDrawer';
import ContentCard from './ContentCard';

const styles = {
  cardSkeleton: css({
    minWidth: '100%',
    height: '6.875rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
  dropdown: css({
    marginBottom: '.625rem',
  }),
  contentCard: css({
    '&:not(:last-child)': {
      marginBottom: '.625rem',
    },
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1.5rem',
    marginBottom: '.5rem',
  }),
  pagination: css({
    height: 'max-content',
    marginLeft: 'auto',
  }),
  emptyStateText: css({
    fontWeight: '600',
    color: palette.brand.indigo,
    textAlign: 'center',
    fontSize: '1rem',
    marginTop: '1.125rem',
  }),
  emptyState: css({
    img: {
      width: '100%',
    },
  }),
  noResults: css({
    paddingTop: '3.625rem',
    paddingBottom: '3.625rem',
    textAlign: 'center',
    img: {
      width: '75%',
      paddingBottom: '.625rem',
    },
  }),
  noResultsText: css({
    color: palette.brand.indigo,
  }),
  searchBox: css({
    marginBottom: '1.25rem',
  }),
  searchResults: css({
    color: palette.brand.indigo,
    textTransform: 'uppercase',
    fontWeight: '400',
    marginTop: '1.25rem',
  }),
  searchTerm: css({
    fontWeight: '600',
    wordWrap: 'break-word',
  }),
  searchError: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: '.625rem',
    backgroundColor: hexToRGBA(palette.brand.red, 0.1),
    color: palette.brand.red,
    borderRadius: '.5rem',
    padding: '.75rem 0rem',
    height: '2.5625rem',
    width: '100%',
    paddingLeft: '.625rem',
  }),
};

interface ViewProps extends PaginationProps {
  learningTemplates: LearningTemplate[],
  areLearningTemplatesLoading: boolean,
  learningTemplateCategoryItems: DropdownItem[],
  selectedCategory: string,
  isCurated: boolean,
  setSelectedCategory: (selectedCategory: string) => void,
  onViewLearningTemplate: (learningTemplateId: string) => void,
  onViewLearningPlaylistTemplate: (learningTemplateId: string) => void,
  onAddNewContent: () => void,
  isMobile: boolean,
  hasValidLearningSearch: boolean,
  shouldUsePlaylistPhase2: boolean,
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
  search: string,
  learningTemplatesFromSearchTotal: number,
  shouldShowLengthError: boolean,
  shouldShowCharacterError: boolean,
  renderBanner?: () => JSX.Element,
  canCreateLearningTemplate?: boolean,
  debouncedSearchText: string,
}

const View = ({
  learningTemplates,
  areLearningTemplatesLoading,
  page,
  isCurated,
  numberOfPages,
  onPageChange,
  onPreviousClick,
  onNextClick,
  learningTemplateCategoryItems,
  selectedCategory,
  setSelectedCategory,
  onViewLearningTemplate,
  onViewLearningPlaylistTemplate,
  onAddNewContent,
  isMobile,
  hasValidLearningSearch,
  shouldUsePlaylistPhase2,
  onSearch,
  search,
  learningTemplatesFromSearchTotal,
  shouldShowCharacterError,
  shouldShowLengthError,
  renderBanner,
  canCreateLearningTemplate = true,
  debouncedSearchText,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {renderBanner?.()}
    <div>
      <LeadrSearchField
        data-test-id="learningLibraryDrawerSearchField"
        css={styles.searchBox}
        value={search}
        onChange={onSearch}
      />
      <div>
        {shouldShowCharacterError && (
          <div css={styles.searchError}>
            <IconContents
              renderIcon={() => <FontAwesomeIcon icon={faExclamationTriangle} />}
              text="Please use only the following characters : A-Z, 0-9, -,',&quot;"
            />
          </div>
        )}

        {shouldShowLengthError && (
          <div css={styles.searchError}>
            <IconContents
              renderIcon={() => <FontAwesomeIcon icon={faExclamationTriangle} />}
              text="Please use 250 characters or less"
            />
          </div>
        )}
      </div>
      {!shouldShowCharacterError && !shouldShowLengthError && (
        <>
          {hasValidLearningSearch && (
            <div css={styles.searchResults}>
              {`${learningTemplatesFromSearchTotal} Results for `}
              <span css={styles.searchTerm}>
                &quot;
                {debouncedSearchText}
                &quot;
              </span>
            </div>
          )}
        </>
      )}
    </div>
    {!hasValidLearningSearch && (
      <Dropdown
        items={learningTemplateCategoryItems}
        onChange={(event: SelectChangeEvent) => setSelectedCategory(event.target.value)}
        value={selectedCategory}
        css={styles.dropdown}
        label="Category"
        renderLabel={() => (
          <InputLabel>
            Category
          </InputLabel>
        )}
      />
    )}
    {areLearningTemplatesLoading && (
      <MultipleSkeletonLoaders
        numberOfSkeletons={5}
        renderSkeletonItem={() => (
          <CardSkeleton css={styles.cardSkeleton} />
        )}
      />
    )}
    {!areLearningTemplatesLoading && (
      <>
        {!shouldUsePlaylistPhase2 && learningTemplates.map((learningTemplate) => (
          <ContentCard
            css={styles.contentCard}
            learningTemplate={learningTemplate}
            key={learningTemplate.uid}
            onViewLearningTemplate={onViewLearningTemplate}
            isMobile={isMobile}
            renderPreview={() => (
              <PreviewWithExternalNavigation
                contentUrl={learningTemplate.link}
                viewOption="Thumbnail"
              />
            )}
          />
        ))}
        {shouldUsePlaylistPhase2 && learningTemplates.map((learningTemplate) => (
          <React.Fragment key={learningTemplate.uid}>
            {learningTemplate.learningType !== LearningType.PLAYLIST && (
              <ContentCard
                renderLearningType={() => (
                  <LearningTypeInfo
                    learningType={learningTemplate.learningType}
                  />
                )}
                css={styles.contentCard}
                learningTemplate={learningTemplate}
                onViewLearningTemplate={onViewLearningTemplate}
                isMobile={isMobile}
                renderPreview={() => (
                  <PreviewWithExternalNavigation
                    contentUrl={learningTemplate.link}
                    viewOption="Thumbnail"
                  />
                )}
              />
            )}
            {learningTemplate.learningType === LearningType.PLAYLIST && (
              <ContentCard
                renderLearningType={() => (
                  <LearningTypeInfo
                    learningType={learningTemplate.learningType}
                  />
                )}
                css={styles.contentCard}
                learningTemplate={learningTemplate}
                onViewLearningTemplate={onViewLearningPlaylistTemplate}
                isMobile={isMobile}
                renderPreview={() => (
                  <PreviewWithContentCount
                    contentUrl={learningTemplate.link}
                    numberOfContents={learningTemplate.numberOfContents}
                    viewOption="Thumbnail"
                  />
                )}
              />
            )}
          </React.Fragment>
        ))}
        {learningTemplates.length === 0 && (
          <>
            {hasValidLearningSearch && (
              <div css={styles.noResults}>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <img src={yellowQuestionMark} alt="No Results" />
                <div css={styles.noResultsText}>We found no matches for your search criteria</div>
              </div>
            )}
            {!hasValidLearningSearch && (
              <div css={styles.emptyState}>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <img src={emptyLearningTemplate} alt="No Template" />
                <div css={styles.emptyStateText}>No templates were found under this category</div>
              </div>
            )}
          </>
        )}
      </>
    )}
    <div css={styles.footer}>
      {!isCurated && canCreateLearningTemplate && (
        <LeadrButton
          size="small"
          onClick={onAddNewContent}
          data-test-id="learningLibraryCreateNewTemplate"
        >
          <LeadrButton.IconAndText icon={faPlus} text={isMobile ? 'Add' : 'Add New Template'} />
        </LeadrButton>
      )}
      <Pagination
        css={styles.pagination}
        page={page}
        onPageChange={onPageChange}
        numberOfPages={numberOfPages}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
      />
    </div>
  </div>
);

interface CuratedByLeadrTabProps {
  learningTemplateCategoryItems: DropdownItem[],
  isCurated: boolean,
  isCuratedByLeadr: boolean,
  isCuratedByTableGroup: boolean,
  setDrawerState: (callback: (prev: PartialDrawerState<ViewLearningLibraryDrawerState>) => PartialDrawerState<ViewLearningLibraryDrawerState>) => void
  onUseTemplate: ({ templateId, isPlaylist }: OnUseTemplateParams) => void,
  renderBanner?: () => JSX.Element,
  canCreateLearningTemplate?: boolean,
  openTemplateLibrary: () => void,
}

const LearningLibraryDrawerTab = ({
  learningTemplateCategoryItems,
  isCurated,
  isCuratedByLeadr,
  isCuratedByTableGroup,
  setDrawerState,
  onUseTemplate,
  openTemplateLibrary,
  ...props
}: CuratedByLeadrTabProps): JSX.Element => {
  const shouldUsePlaylistPhase2 = useFeatureFlag('webAppLearningPlaylistsPhase2');
  const isMobile = useIsMobileQuery();
  const [page, setPage] = useState(LEARNING_DEFAULT_PAGE_NUMBER);
  const dispatch = useDispatch();

  const {
    searchText,
    setSearchText,
    clearSearchText,
    debouncedSearchText,
  } = useLearningLibrarySearchStore((state) => ({
    searchText: state.searchText, setSearchText: state.setSearchText, clearSearchText: state.clearSearchText, debouncedSearchText: state.debouncedSearchText,
  }));

  useEffect(() => {
    clearSearchText();
  }, [isCurated, clearSearchText]);

  const shouldShowCharacterError = !/^[a-zA-Z0-9-'"\s]*$/.test(searchText);
  const shouldShowLengthError = searchText.length > 250;

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const isValidSearch = !(shouldShowCharacterError || shouldShowLengthError);

  const {
    learningTemplates: learningTemplatesFromSearch,
    isLoading: areLearningTemplatesFromSearchLoading,
    total: learningTemplatesFromSearchTotal,
  } = useCombinedLearningTemplateSearch({
    page,
    count: LEARNING_LIBRARY_PAGE_SIZE,
    searchString: debouncedSearchText,
    isValidSearch,
    isCuratedByLeadr,
    isCuratedByTableGroup,
  });

  const hasValidLearningSearch = isValidSearch && !!debouncedSearchText;

  const [selectedCategory, setSelectedCategory] = useState(learningTemplateCategoryItems[0].value ?? '');

  const onViewLearningTemplate = (templateId: string): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...viewLearningTemplateDrawerTemplate,
        args: {
          templateId,
          page,
          count: LEARNING_LIBRARY_PAGE_SIZE,
          isCuratedByLeadr,
          onUseTemplate,
          isCuratedByTableGroup,
        },
      },
    }));
  };

  const onViewLearningPlaylistTemplate = (playlistTemplateId: string): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...viewLearningPlaylistTemplateDrawerTemplate,
        args: {
          playlistTemplateId,
          page,
          count: LEARNING_LIBRARY_PAGE_SIZE,
          isCuratedByLeadr,
          onUseTemplate,
          isCuratedByTableGroup,
        },
      },
    }));
  };

  const onAddNewContent = (): void => {
    setDrawerState((prev) => ({
      ...prev,
      isLearningLibraryWorkflow: true,
    }));

    if (shouldUsePlaylistPhase2) {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ drawerName: viewLearningLibraryDrawerTemplate.name }));

      dispatch(pushDrawerAction({
        drawer: {
          ...selectLearningTypeDrawerTemplate,
          args: {
            initialTemplateCategoryId: selectedCategory,
            openTemplateLibrary,
          },
        },
      }));
    } else {
      dispatch(pushDrawerAction({
        drawer: {
          ...createLearningTemplateDrawerTemplate,
          args: {
            initialTemplateCategoryId: selectedCategory,
          },
        },
      }));
    }
  };

  useEffect(() => {
    setSelectedCategory(learningTemplateCategoryItems[0].value ?? '');
  }, [learningTemplateCategoryItems]);

  // This needs to toggle for curated by the table group
  const {
    learningTemplates,
    isLoading: areLearningTemplatesLoading,
    total: learningTemplatesTotalCount,
  } = useGetCombinedLearningTemplateList({
    page,
    count: LEARNING_LIBRARY_PAGE_SIZE,
    categoryId: selectedCategory,
    isCuratedByLeadr,
    isCuratedByTableGroup,
  });

  const {
    numberOfPages,
    onPageChange,
    onPreviousClick,
    onNextClick,
  } = usePagination({
    totalCount: hasValidLearningSearch ? learningTemplatesFromSearchTotal : learningTemplatesTotalCount,
    pageSize: LEARNING_LIBRARY_PAGE_SIZE,
    page,
    setPage,
  });

  const previousPage = usePrevious(page) as unknown as number;

  // If the last template on the last page is deleted, it should go to the previous page
  useEffect(() => {
    if (previousPage === page && page > numberOfPages && page - 1 >= LEARNING_DEFAULT_PAGE_NUMBER) {
      setPage(page - 1);
    }
  }, [numberOfPages, page, previousPage]);

  useEffect(() => {
    setPage(LEARNING_DEFAULT_PAGE_NUMBER);
  }, [searchText, selectedCategory, setPage]);

  const learningTemplatesToUse = hasValidLearningSearch ? learningTemplatesFromSearch : learningTemplates;

  const hookProps = {
    learningTemplates: learningTemplatesToUse,
    areLearningTemplatesLoading: hasValidLearningSearch ? areLearningTemplatesFromSearchLoading : areLearningTemplatesLoading,
    page,
    isCurated,
    numberOfPages,
    onPageChange,
    onPreviousClick,
    onNextClick,
    selectedCategory,
    setSelectedCategory,
    learningTemplateCategoryItems,
    onViewLearningTemplate,
    onViewLearningPlaylistTemplate,
    onAddNewContent,
    isMobile,
    hasValidLearningSearch,
    search: searchText,
    onSearch,
    shouldUsePlaylistPhase2,
    learningTemplatesFromSearchTotal,
    shouldShowLengthError,
    shouldShowCharacterError,
    debouncedSearchText,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LearningLibraryDrawerTab;
