import { css } from '@emotion/react';
import { navigationItemColor } from '~Common/styles/colors';
import { useAdminSettingsDrawer } from '~Insights/hooks/useAdminSettingsDrawer';
import Button from '~Common/V3/components/Button/index';

const styles = {
  root: css({
    color: navigationItemColor,
  }),
};

interface ViewProps {
  openAdminSettingsDrawer: () => void
}

const View = ({
  openAdminSettingsDrawer,
}: ViewProps): JSX.Element => (
  <Button
    css={styles.root}
    variant="text"
    onClick={openAdminSettingsDrawer}
    renderContents={() => (
      <>Admin Settings</>
    )}
  />
);

const AdminSettingsButton = (
  { ...props },
): JSX.Element => {
  const { openAdminSettingsDrawer } = useAdminSettingsDrawer();

  const hookProps = {
    openAdminSettingsDrawer,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AdminSettingsButton;
