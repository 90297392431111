import moment from 'moment';
import { CreateRecognitionShape } from './types';

export const DEFAULT_RECOGNITION: CreateRecognitionShape = {
  content: '',
  recipients: [],
  canDelete: false,
  canEdit: false,
};

export const RECOGNITION_RECIPIENT_LIMIT = 25;
export const OPTIMISTIC_ID = 99999999999;
export const RECOGNITION_PAGE_SIZE = 25;
export const RECOGNITION_HOME_PAGE_SIZE = 5;

export const RECOGNITION_DEFAULT_DEPARTMENT = {
  title: 'Department',
  value: 'defaultDepartment',
};

export const RECOGNITION_DEFAULT_USER_GROUP = {
  title: 'User Groups',
  value: 'defaultGroup',
};

export const RECOGNITION_DEFAULT_START_DATE = moment().format('L');
export const RECOGNITION_DEFAULT_END_DATE = moment().add(1, 'd').format('L');

export const RECOGNITION_DEFAULT_FILTERS = {
  department: 'defaultDepartment',
  search: '',
  userGroup: 'defaultGroup',
  startDate: moment().format('L'),
  endDate: moment().add(1, 'd').format('L'),
};

export const DEFAULT_EDIT_POST = {
  editPost: false,
  postId: 0,
};
