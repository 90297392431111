import MuiTextField, { StandardTextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { startCase } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import { inputStyles } from './styles';

const styles = {
  input: inputStyles,
  hideLabel: css({
    '& .MuiInputBase-input': {
      padding: '0.5rem 0.75rem',
    },
    '& .MuiInputLabel-root': {
      display: 'none',
    },
  }),
};

interface TextFieldProps extends MuiTextFieldProps {
  hideLabel?: boolean,
  label?: string,
  name: string,
}

export function TextField({
  name,
  label = startCase(name),
  hideLabel = false,
  ...props
}: TextFieldProps): JSX.Element {
  const { register, formState: { errors } } = useFormContext();
  const error = errors[name] as { message: string };

  return (
    <MuiTextField
      css={[styles.input, hideLabel && styles.hideLabel]}
      error={!!error}
      helperText={error?.message}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
      {...register(name)}
    />
  );
}
