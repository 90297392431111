import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Tooltip from '~Common/components/Tooltip';
import Card from '~Common/V3/components/Card';
import { NO_RESPONSE_TEXT } from '~Common/const/surveys';
import {
  AssignedPlaylistDetails, FrontendQuestionResponse, AssignedPlaylistLearning, LearningPersonDisplayInformation,
} from '~Learning/const/interfaces';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { View as LearningAvatarView } from '~Learning/components/Shared/LearningAvatar';
import { addToMeetingDrawerTemplate } from '~Learning/components/Shared/AddToMeetingDrawer';
import PaginationButton from '~Common/V3/components/Pagination/PaginationButton';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { useRequestedLearningAssigneeResults } from '~Learning/hooks/utils/useRequestedLearningAssigneeResults';
import { isCurrentUser } from '~Common/utils';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { playlistParticipantsDrawerTemplate } from '../../PlaylistParticipantsDrawer';
import ContentDetailsCard from '../Cards/ContentDetailsCard';

const styles = {
  responsesContainer: css({
    marginTop: '3.125rem',
    display: 'flex',
    flexDirection: 'column',
  }),
  responsesHeader: css({
    textTransform: 'uppercase',
    fontSize: '.6875rem',
    color: palette.neutrals.gray600,
    marginBottom: '1.4688rem',
    letterSpacing: '0.2rem',
    fontWeight: '600',
  }),
  dropdown: css({
    marginBottom: '2rem',
  }),
  addToMeetingButton: css({
    marginTop: '1.125rem',
  }),
  learningPreview: css({
    marginRight: '1.125rem',
  }),
  participantHeader: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2.5938rem',
  }),
  participant: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  participantAvatar: css({
    display: 'flex',
    marginRight: '1.0625rem',
    alignItems: 'center',
    height: '3.125rem',
    width: '3.125rem',
  }),
  participantInfo: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  participantName: css({
    fontSize: '1.5rem',
    color: palette.neutrals.gray900,
    fontWeight: '600',
  }),
  participantTitle: css({
    fontSize: '.875rem',
    color: palette.neutrals.gray500,
    fontWeight: '400',
  }),
  paginationButton: css({
    padding: '0.5rem .6563rem',
    width: '3.125rem',
    height: '2.625rem',

    '&:not(:last-child)': {
      borderRight: `0.0625rem solid ${palette.neutrals.gray300}`,
    },

    '&: disabled': {
      cursor: 'not-allowed',
      color: palette.neutrals.gray500,
    },
  }),
  responsesSection: css({
    alignItems: 'center',
  }),
  questionCard: css({
    marginTop: '1.125rem',
  }),
  question: css({
    fontSize: '1.125rem',
    color: palette.neutrals.gray900,
    fontWeight: '500',
  }),
  responses: css({
    fontSize: '.875rem',
    color: palette.neutrals.gray800,
    fontWeight: '400',
  }),
  buttonsContainer: css({
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
  }),
};

interface ViewProps {
  learningPlaylistDropdownItems: DropdownItem[],
  selectedLearningId: string,
  setSelectedLearningId: (selectedLearningId: string) => void,
  selectedLearning: AssignedPlaylistLearning,
  personIndex: number,
  onPreviousClick: () => void,
  onNextClick: () => void,
  questionsWithResponse: FrontendQuestionResponse[],
  person: LearningPersonDisplayInformation,
  addToMeetingClick: () => void,
  onViewParticipantsClick: () => void,
  showViewParticipantsButton: boolean,
  onCloseParticipantsClick: () => void,
  canAddToMeeting: boolean,
}

const View = ({
  learningPlaylistDropdownItems,
  selectedLearningId,
  setSelectedLearningId,
  selectedLearning,
  personIndex,
  onPreviousClick,
  onNextClick,
  questionsWithResponse,
  person,
  addToMeetingClick,
  onViewParticipantsClick,
  showViewParticipantsButton,
  onCloseParticipantsClick,
  canAddToMeeting,
}: ViewProps): JSX.Element => (
  <>
    <Dropdown
      items={learningPlaylistDropdownItems}
      onChange={(event: SelectChangeEvent) => setSelectedLearningId(event.target.value)}
      value={selectedLearningId}
      css={styles.dropdown}
      renderLabel={() => (
        <InputLabel>
          Playlist Content
        </InputLabel>
      )}
    />
    <>
      <ContentDetailsCard
        contentUrl={selectedLearning.contentUrl}
        title={selectedLearning.title}
        introductionHTML={selectedLearning.introduction}
        dueDate={selectedLearning.dueDate}
        status={selectedLearning.status}
      />
      {canAddToMeeting && (
        <LeadrButton
          css={styles.addToMeetingButton}
          variant="ghost"
          onClick={addToMeetingClick}
          data-test-id="learningAddToMeeting"
        >
          + Add to Meeting
        </LeadrButton>
      )}
      <div css={styles.responsesContainer}>
        <span css={styles.responsesHeader}>Participant Responses</span>
        <div css={styles.participantHeader}>
          <div css={styles.participant}>
            <LearningAvatarView
            // @ts-expect-error learning avatar/avatar needs to be updated to no longer user person display information type
              person={person}
              css={styles.participantAvatar}
            />
            <div css={styles.participantInfo}>
              <span css={styles.participantName}>{`${person.firstName} ${person.lastName}`}</span>
              <span css={styles.participantTitle}>{person.jobTitle}</span>
            </div>
          </div>
          <PaginationButton
            renderPreviousButton={() => (
              <LeadrButton
                onClick={onPreviousClick}
                css={styles.paginationButton}
                textButtonColor={palette.brand.indigo}
                variant="text"
                disabled={personIndex === 0}
                data-test-id="learningPlaylistPreviousParticipant"
              >
                <Tooltip content="Previous">
                  <div><FontAwesomeIcon icon={faChevronLeft} /></div>
                </Tooltip>
              </LeadrButton>
            )}
            renderNextButton={() => (
              <LeadrButton
                onClick={onNextClick}
                css={styles.paginationButton}
                textButtonColor={palette.brand.indigo}
                variant="text"
                data-test-id="learningPlaylistNextParticipant"
              >
                <Tooltip content="Next">
                  <div><FontAwesomeIcon icon={faChevronRight} /></div>
                </Tooltip>
              </LeadrButton>
            )}
          />
        </div>
        {questionsWithResponse?.map((questionWithResponse) => (
          <div
            key={questionWithResponse.id}
            css={styles.responsesSection}
          >
            <Card
              css={styles.questionCard}
              renderContents={() => (
                <div>
                  <HTMLRenderer css={styles.question} htmlText={questionWithResponse.topic} />
                  {questionWithResponse.responses?.text && (
                    <HTMLRenderer css={styles.responses} htmlText={questionWithResponse.responses?.text} />
                  )}
                  {!questionWithResponse.responses?.text && (
                    <div css={styles.responses}>{NO_RESPONSE_TEXT}</div>
                  )}
                </div>
              )}
            />
          </div>
        ))}
      </div>
      <div css={styles.buttonsContainer}>
        {showViewParticipantsButton && (
          <LeadrButton
            onClick={onViewParticipantsClick}
            data-test-id="learningPlaylistViewParticipants"
          >
            Open Participants
          </LeadrButton>
        )}
        {!showViewParticipantsButton && (
          <LeadrButton
            onClick={onCloseParticipantsClick}
            data-test-id="learningPlaylistCloseParticipants"
          >
            Close Participants
          </LeadrButton>
        )}
      </div>
    </>
  </>
);

interface ByParticipantTabProps {
  learningPlaylist: AssignedPlaylistDetails,
  learningPlaylistDropdownItems: DropdownItem[],
  showViewParticipantsButton: boolean,
}

const ByParticipantTab = ({
  learningPlaylist,
  learningPlaylistDropdownItems,
  showViewParticipantsButton,
  ...props
}: ByParticipantTabProps): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedLearningId, setSelectedLearningId] = useState(learningPlaylistDropdownItems[0].value ?? '');

  const selectedLearning = useMemo(
    () => learningPlaylist.learnings.find((learning) => learning.id === selectedLearningId) ?? learningPlaylist.learnings[0],
    [selectedLearningId, learningPlaylist.learnings],
  );

  const [personIndex, setPersonIndex] = useRequestedLearningAssigneeResults();

  // Handles an edge case where you are viewing the participant in the PersonTab and you recall that participant
  useEffect(() => {
    if (!learningPlaylist.assignedUsersInfo?.[personIndex]?.assignee) {
      setPersonIndex(0);
    }
  }, [learningPlaylist.assignedUsersInfo, personIndex, setPersonIndex]);

  const person = useMemo(
    () => learningPlaylist.assignedUsersInfo?.[personIndex]?.assignee || learningPlaylist.assignedUsersInfo[0].assignee,
    [personIndex, learningPlaylist.assignedUsersInfo],
  );

  const canAddToMeeting = !isCurrentUser(person.id);

  const questionsWithResponse = useMemo(() => selectedLearning.questions.map((question) => (
    { ...question, responses: question.responses.filter((response) => response.assignee.id === person.id)[0] }
  )), [person, selectedLearning.questions]);

  const addToMeetingClick = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...addToMeetingDrawerTemplate,
        args: {
          otherUserId: person.id,
          learningId: selectedLearning.id,
        },
      },
    }));
  };

  const onNextClick = (): void => {
    if (personIndex + 1 < learningPlaylist.assignedUsersInfo.length) {
      setPersonIndex(personIndex + 1);
    } else {
      setPersonIndex(0);
    }
  };

  const onPreviousClick = (): void => {
    if (personIndex - 1 >= 0) {
      setPersonIndex(personIndex - 1);
    }
  };

  const hookProps = {
    selectedLearning,
    learningPlaylistDropdownItems,
    selectedLearningId,
    setSelectedLearningId,
    onPreviousClick,
    onNextClick,
    canAddToMeeting,
    questionsWithResponse,
    personIndex,
    person,
    addToMeetingClick,
    showViewParticipantsButton,
    onViewParticipantsClick: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...playlistParticipantsDrawerTemplate,
          args: {
            assignees: learningPlaylist.assignedUsersInfo,
            playlistId: learningPlaylist.id,
          },
        },
      }));
    },
    onCloseParticipantsClick: () => {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ drawerName: playlistParticipantsDrawerTemplate.name }));
    },
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default ByParticipantTab;
