import { css } from '@emotion/react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { keyBy } from 'lodash';
import { Fragment, useMemo } from 'react';
import { palette } from '~Common/styles/colors';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import Card from '~Common/V3/components/Card';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { detect } from 'detect-browser';
import { usePrintView } from '~Common/stores/usePrintView';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';
import { CENTER_CONTENTS, PAGE_STYLES, REVIEW_DISPLAY_STYLES } from '../Const/pageStyles';
import {
  AssignedReflection,
  multiChoiceTopicConfig,
  ParticipantTypeEnum, PersonDisplayInformationReviews, ReviewAnswer, ReviewShape, ReviewStatusEnum, TopicShape, TopicTypeEnum,
} from '../Const/types';
import { UserAssignmentDisplay } from '../Shared/UserAssignmentDisplay';
import { ReviewResponseForm } from './ReviewResponseForm';
import { SingleReviewResponse } from './SingleReviewResponse';

function getMultipleChoiceOptions(typeConfig: string): string[] {
  const parsed = JSON.parse(typeConfig) as multiChoiceTopicConfig;
  return parsed.multipleChoiceOptions.map((option) => option.text);
}

const styles = {
  ...PAGE_STYLES,
  ...REVIEW_DISPLAY_STYLES,
  ...CENTER_CONTENTS,
  topic: css({
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${palette.neutrals.gray400}`,
      paddingBottom: '4.5rem',
    },
  }),
  noMargin: css({
    margin: 0,
  }),
  questionWrapOveride: css({
    padding: '0 !important',
    border: 'none',
  }),
  questionList: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '4.5rem',
    pageBreakAfter: 'avoid',
    pageBreakBefore: 'avoid',
  }),
  radioLabel: css({
    color: palette.neutrals.gray900,
    flex: 1,
    margin: '0 0 0.5rem 0',
    alignContent: 'center',

    '& .MuiRadio-root': {
      padding: '0 .75rem 0 0',
    },
    '.MuiTypography-root.Mui-disabled': {
      color: palette.neutrals.gray900,
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  }),
  spacer: css({
    marginTop: '4rem',
    marginBottom: '4rem',
    borderBottom: `1px solid ${palette.neutrals.gray400}`,

    ':last-of-type': {
      display: 'none',
    },
  }),
};

type AnswerAndParticipant = [ReviewAnswer | undefined, AssignedReflection[], PersonDisplayInformationReviews];

interface TopicMap {
  topic: TopicShape,
  answersAndParticipants: AnswerAndParticipant[],
}

interface ViewProps {
  isViewerActive: boolean,
  hasReviewerReleased: boolean,
  review: ReviewShape,
  topicMaps: TopicMap[],
  viewerParticipant?: PersonDisplayInformationReviews,
  viewerParticipantUid: string,
  isSafari: boolean,
  isPrintView: boolean,
}

const View = ({
  isViewerActive,
  review,
  topicMaps,
  hasReviewerReleased,
  viewerParticipant,
  viewerParticipantUid,
  isSafari,
  isPrintView,
}: ViewProps): JSX.Element => (
  <Card
    css={isPrintView ? [styles.baseCard(false), styles.fullWidth] : [styles.baseCard(false), styles.centeredContent]}
    renderContents={() => (
      <div>
        <p css={[styles.sectionTitle]}>
          Questions
        </p>
        <div css={styles.questionList}>
          {topicMaps.map((topic) => (
            <div css={styles.topic} key={topic.topic.uid}>
              <div
                css={styles.pageBreak(isSafari)}
              >
                {topic.topic.text && (
                  <>
                    {isHTMLText(topic.topic.text) && (
                      <HTMLRenderer css={styles.answerTitle} htmlText={topic.topic.text} />
                    )}
                    {!isHTMLText(topic.topic.text) && (
                      <div css={styles.answerTitle}>
                        {topic.topic.text}
                      </div>
                    )}
                  </>
                )}
                {topic.topic.description && (
                  <>
                    {isHTMLText(topic.topic.description) && (
                      <HTMLRenderer css={styles.answerDescription} htmlText={topic.topic.description} />
                    )}
                    {!isHTMLText(topic.topic.description) && (
                      <div css={styles.answerDescription}>
                        {topic.topic.description}
                      </div>
                    )}
                  </>
                )}

                {topic.topic.typeEnum === TopicTypeEnum.MultipleChoice && !isViewerActive && typeof topic.topic.typeConfig === 'string' && (
                  <RadioGroup>
                    {getMultipleChoiceOptions(topic.topic.typeConfig).map((option, index) => (
                      <FormControlLabel
                        css={styles.radioLabel}
                        key={option}
                        value={index + 1}
                        label={option}
                        disabled
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                )}

                <UserAssignmentDisplay participationEnum={topic.topic.topicTargetEnum} />
                {topic.answersAndParticipants.map(([answer, reflections, participant]) => (
                  <Fragment key={participant.uid}>
                    {participant.uid === viewerParticipantUid
                    && viewerParticipant !== undefined
                    && FlaggedEnum(topic.topic.topicTargetEnum).hasFlag(viewerParticipant?.participantTypeEnum)
                    && isViewerActive && (
                    <>
                      <ReviewResponseForm
                        answerRecord={answer}
                        reflectionRecords={reflections}
                        review={review}
                        topic={topic.topic}
                      />
                    </>
                    )}
                    {(participant.uid !== viewerParticipantUid || !isViewerActive) && (
                      <SingleReviewResponse
                        answerRecord={answer}
                        hasReviewerReleased={hasReviewerReleased}
                        participant={participant}
                        reflectionRecords={reflections}
                        reviewUid={review.uid}
                        topic={topic.topic}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
              {isSafari && (
                <div css={styles.pageBreak(isSafari)} />
              )}
            </div>
          ))}
        </div>
      </div>
    )}
  />
);

interface DisplayReviewResponsesProps {
  isReadOnly?: boolean,
  review: ReviewShape,
}

export const DisplayReviewResponses = ({
  isReadOnly = false,
  review,
}: DisplayReviewResponsesProps): JSX.Element => {
  const isPrintView = usePrintView((state) => state.isPrintView);
  const { topics, participants } = review;
  const sortedTopicsByRank = useMemo(() => (topics ? [...topics].sort((a, b) => a.rank - b.rank) : []), [topics]);
  const reviewer = participants.find((participant) => participant.participantTypeEnum === ParticipantTypeEnum.Reviewer);
  const viewerParticipant = participants.find((participant) => participant.orgUserId === getOrganizationUserId());
  const viewerParticipantUid = viewerParticipant?.uid ?? '';

  const { isAdmin: isViewerAdmin } = useUserPermissions();
  const isViewerActive = !isReadOnly
    && viewerParticipant?.statusEnum !== ReviewStatusEnum.Submitted
    && viewerParticipant?.statusEnum !== ReviewStatusEnum.Finished;
  const isViewerParticipant = viewerParticipant !== undefined;
  const hasReviewerReleased = reviewer?.releasedAt !== null;

  const topicMaps = useMemo(() => {
    const participantsByUid = keyBy(participants, (participant) => participant.uid);
    return sortedTopicsByRank.map((topic) => {
      let answersAndParticipants = topic.answers.map<AnswerAndParticipant>((answer) => ([
        answer,
        // TODO: This filtering can be removed after the backend handles it https://leadrhq.atlassian.net/browse/LW-15878
        topic.reflections.filter((reflection) => reflection.participantUid === answer.participantUid),
        participantsByUid[answer.participantUid],
      ]));
      answersAndParticipants = answersAndParticipants.sort((a, b) => a[2].participantTypeEnum - b[2].participantTypeEnum);
      // If the viewer is a participant, we want to put them at the end of the list.
      if (isViewerParticipant) {
        answersAndParticipants = answersAndParticipants.sort(
          (a) => (a[2].uid === viewerParticipant.uid ? 1 : -1),
        );

        // If the viewer doesn't already have an answer record, we need to make sure it appears anyway.
        if (FlaggedEnum(topic.topicTargetEnum).hasFlag(viewerParticipant.participantTypeEnum)
          && !topic.answers.find((answer) => answer.participantUid === viewerParticipantUid)) {
          const viewerParticipantID = viewerParticipant?.uid;
          // TODO: This filtering can be removed after the backend handles it https://leadrhq.atlassian.net/browse/LW-15878
          const viewerReflections = topic.reflections.filter((reflection) => reflection.participantUid === viewerParticipantID);
          answersAndParticipants.push([undefined, viewerReflections, viewerParticipant]);
        }
      }

      const topicMap: TopicMap = {
        topic,
        answersAndParticipants,
      };
      return topicMap;
    }).filter((entry) => isViewerAdmin || isViewerParticipant || entry.answersAndParticipants.length > 0);
  }, [isViewerAdmin, isViewerParticipant, participants, sortedTopicsByRank, viewerParticipant, viewerParticipantUid]);

  const browser = detect();
  const name = browser?.name;
  const isSafari = name === 'safari';

  const hookProps = {
    hasReviewerReleased,
    isViewerActive,
    review,
    topicMaps,
    viewerParticipant,
    viewerParticipantUid,
    isSafari,
    isPrintView,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
