import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { postApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost } from '~Deprecated/services/config';

const inviteUser = async (userInformation) => {
  const organizationId = getOrganizationId() ?? '';
  const URL = {
    host: getHost('', '2'),
    uri: `/organizations/${organizationId}/auth/invite`,
  };

  return postApi(URL, userInformation.userInformation ? userInformation.userInformation : userInformation);
};

const messages = {
  Sending: 'Sending the invite...',
  Success: 'The invite was sent successfully!',
  Default: 'The invite failed to send. Please try again...',
};

export const useInviteUser = () => {
  const toastId = useRef(null);

  const mutation = useMutation({
    mutationFn: inviteUser,
    onMutate: () => {
      toastId.current = toast.info(messages.Sending, { autoClose: false });
    },
    onError: (error) => {
      if (error.message) {
        toast.update(toastId.current, {
          render: error.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: messages.Default,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      }
    },
    onSuccess: (_, data) => {
      if (data.userInformation) {
        toast.update(toastId.current, {
          render: `Invitation has been sent to ${data.userInformation.firstName} ${data.userInformation.lastName}.`,
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: messages.Success,
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
      }

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'people'] });
    },
  });

  return mutation;
};
