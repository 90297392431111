import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { putApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { FrontendRankedQuestion, LearningTemplateList } from '~Learning/const/interfaces';
import { OPTIMISTIC_ID } from '~Learning/const';
import { useLearningLibrarySearchStore } from '~Learning/stores/useLearningLibrarySearchStore';
import { learningTemplatesQueryKeys } from '../queryKeys';
import { LearningTemplateDetail } from './useGetLearningTemplate';
import { LearningTemplateSearchHookReturn } from './useLearningTemplateSearch';

interface LearningTemplateUpdateRequest {
  templateId: string,
  contentURL: string,
  title: string,
  categoryId: string,
  questions: FrontendRankedQuestion[],
}

interface LearningTemplateUpdateResponse {
  templateId: string,
  questionsCreated: string,
  questionsUpdated: string,
  questionsDeleted: string
}

const updateLearningTemplate = async (
  learningTemplateUpdateRequest: LearningTemplateUpdateRequest,
): Promise<HttpCallReturn<LearningTemplateUpdateResponse>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/update`;

  return putApi<LearningTemplateUpdateResponse>(serverUrl, learningTemplateUpdateRequest, {});
};

interface UseUpdateLearningTemplateParams {
  onMutateCallback: () => void,
  page: number,
  count: number,
  curated: boolean,
  categoryId: string,
}

export const useUpdateLearningTemplate = ({
  onMutateCallback,
  page,
  count,
  curated,
  categoryId,
}: UseUpdateLearningTemplateParams):
    UseMutateFunction<HttpCallReturn<LearningTemplateUpdateResponse>, unknown, LearningTemplateUpdateRequest> => {
  const {
    searchText,
  } = useLearningLibrarySearchStore((state) => ({
    searchText: state.searchText,
  }));

  const mutation = useMutation({
    mutationFn: updateLearningTemplate,
    onMutate: async (learningTemplateUpdateRequest: LearningTemplateUpdateRequest) => {
      onMutateCallback?.();

      // #region Cancel Queries
      await queryClient.cancelQueries({
        queryKey: learningTemplatesQueryKeys.list({
          page, count, curated, categoryId,
        }),
      });
      await queryClient.cancelQueries({ queryKey: learningTemplatesQueryKeys.singleContentDetail(learningTemplateUpdateRequest.templateId) });
      // #endregion

      // #region Get Previous Data
      const previousLearningTemplatesList = queryClient.getQueryData<HttpCallReturn<LearningTemplateList>>(learningTemplatesQueryKeys.list({
        page, count, curated, categoryId,
      }));
      const previousLearningTemplateDetail = queryClient.getQueryData<HttpCallReturn<LearningTemplateDetail>>(
        learningTemplatesQueryKeys.singleContentDetail(learningTemplateUpdateRequest.templateId),
      );
      // #endregion

      // #region Update the learning templates list
      queryClient.setQueryData<HttpCallReturn<LearningTemplateList>>(
        learningTemplatesQueryKeys.list({
          page, count, curated, categoryId,
        }),
        (oldLearningTemplatesList) => {
          if (oldLearningTemplatesList) {
            const newLearningTemplatesList = cloneDeep(oldLearningTemplatesList);
            // Sometimes the data for the list will not exist, like if you searched for the template and then updated it
            if (newLearningTemplatesList) {
              const templateToUpdateIndex = newLearningTemplatesList.response.learningTemplates.findIndex(
                (template) => template.uid === learningTemplateUpdateRequest.templateId,
              );

              if (learningTemplateUpdateRequest.categoryId === categoryId) {
                const newTemplate = cloneDeep(newLearningTemplatesList.response.learningTemplates[templateToUpdateIndex]);

                newTemplate.contentTemplateTitle = learningTemplateUpdateRequest.title;
                newTemplate.link = learningTemplateUpdateRequest.contentURL;

                newLearningTemplatesList.response.learningTemplates.splice(templateToUpdateIndex, 1, newTemplate);
              } else {
                /*
                  If the categoryId was changed, then we remove it from the currently shown list
                  Letting the new API data fetch handle showing that in the new category section, as that is a micro optimization that is a lot of work
                */
                newLearningTemplatesList.response.learningTemplates.splice(templateToUpdateIndex, 1);
              }
            }

            return newLearningTemplatesList;
          }

          return oldLearningTemplatesList;
        },
      );
      // #endregion

      // #region Update the learning templates search list
      if (searchText) {
        queryClient.setQueryData<HttpCallReturn<LearningTemplateSearchHookReturn>>(
          learningTemplatesQueryKeys.search({
            page,
            count,
            curated,
            searchString: searchText,
          }),
          (oldLearningTemplatesSearchReturn) => {
            if (oldLearningTemplatesSearchReturn) {
              const newLearningTemplatesSearchList = cloneDeep(oldLearningTemplatesSearchReturn);
              if (newLearningTemplatesSearchList) {
                const templateToUpdateIndex = newLearningTemplatesSearchList.response.learningTemplates.findIndex(
                  (template) => template.uid === learningTemplateUpdateRequest.templateId,
                );

                const newTemplate = cloneDeep(newLearningTemplatesSearchList.response.learningTemplates[templateToUpdateIndex]);

                newTemplate.contentTemplateTitle = learningTemplateUpdateRequest.title;
                newTemplate.link = learningTemplateUpdateRequest.contentURL;

                newLearningTemplatesSearchList.response.learningTemplates.splice(templateToUpdateIndex, 1, newTemplate);
              }
              return newLearningTemplatesSearchList;
            }

            return oldLearningTemplatesSearchReturn;
          },
        );
      }
      // #endregion

      // #region Update the learning template detail
      queryClient.setQueryData<HttpCallReturn<LearningTemplateDetail>>(
        learningTemplatesQueryKeys.singleContentDetail(learningTemplateUpdateRequest.templateId),
        (oldLearningTemplateDetail) => {
          if (oldLearningTemplateDetail) {
            const newLearningTemplateDetail = cloneDeep(oldLearningTemplateDetail);

            newLearningTemplateDetail.response.categoryId = learningTemplateUpdateRequest.categoryId;
            newLearningTemplateDetail.response.contentURL = learningTemplateUpdateRequest.contentURL;
            newLearningTemplateDetail.response.questions = learningTemplateUpdateRequest.questions.map(
              (question) => ({
                ...question, rank: question.rank.toString(), questionId: question.questionId || OPTIMISTIC_ID, questionText: question.text,
              }),
            );
            newLearningTemplateDetail.response.title = learningTemplateUpdateRequest.title;

            return newLearningTemplateDetail;
          }

          return oldLearningTemplateDetail;
        },
      );
      // #endregion

      // Return a context object with the old snapshotted values
      return {
        previousLearningTemplatesList,
        previousLearningTemplateDetail,
      };
    },
    onError: (
      _,
      learningTemplateUpdateRequest,
      snapshot,
    ) => {
      toast.error('There was an error updating the learning template. Please try again.', {
        autoClose: 1500,
      });

      // Revert the data to the previous data
      queryClient.setQueryData(learningTemplatesQueryKeys.list({
        page, count, curated, categoryId,
      }), snapshot?.previousLearningTemplatesList);
      queryClient.setQueryData(
        learningTemplatesQueryKeys.singleContentDetail(learningTemplateUpdateRequest.templateId),
        snapshot?.previousLearningTemplateDetail,
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: learningTemplatesQueryKeys.lists() });
    },
  });

  return mutation.mutate;
};
