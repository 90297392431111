import { debounce } from 'lodash';
import { useState } from 'react';

interface UseDebouncedSearchReturn {
  searchText: string,
  onSearch: (newText: string) => void,
}

export function useDebouncedSearch(): UseDebouncedSearchReturn {
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = debounce((newText: string) => setSearchText(newText), 50);
  const onSearch = (newText: string): void => {
    debouncedSearch(newText);
  };

  return {
    searchText,
    onSearch,
  };
}
