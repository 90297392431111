import {
  useRef,
  useEffect,
  EffectCallback,
  DependencyList,
} from 'react';

/**
 * Extended version of React's useEffect that skips running on initial mount and only runs on update.
 * @param effect — Imperative function that can return a cleanup function
 * @param deps — If present, effect will only activate if the values in the list change.
 * @see — https://reactjs.org/docs/hooks-reference.html#useeffect
 */
export const useUpdateEffect: typeof useEffect = (effect: EffectCallback, deps?: DependencyList): void => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }

    // effect not included in deps so changes in the effect method don't trigger an immediate call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
