import { create } from 'zustand';
import {
  GetPersonalDevelopmentResourceSortBy,
  GetPersonalDevelopmentSortBy,
  GetPersonalDevelopmentSortOrder,
  PDPPermissions,
  PersonalDevelopmentType,
  ViewPersonalDevelopmentPlanPerspective,
  ViewPerspective,
} from '~DevelopmentPlan/const/types';
import {
  PERSONAL_DEVELOPMENT_MY_PLANS_PAGE_SIZE, PERSONAL_DEVELOPMENT_OTHER_PLANS_PAGE_SIZE,
} from '~DevelopmentPlan/const/defaults';
import { DateRange } from '@mui/x-date-pickers-pro';

interface UseStoreParamsStoreState {
  sortByField: GetPersonalDevelopmentSortBy | undefined,
  setSortByField: (field: GetPersonalDevelopmentSortBy) => void,
  sortByResourceField: GetPersonalDevelopmentResourceSortBy | undefined,
  setSortByResourceField: (field: GetPersonalDevelopmentResourceSortBy) => void,
  sortByOrder: GetPersonalDevelopmentSortOrder,
  setSortByOrder: (order: GetPersonalDevelopmentSortOrder) => void,
  sortByResourceOrder: GetPersonalDevelopmentSortOrder,
  setSortByResourceOrder: (order: GetPersonalDevelopmentSortOrder) => void,
  page: number,
  setPage: (newPage: number) => void,
  takeMyPlans: number,
  setTakeMyPlans: (newTake: number) => void,
  takeOtherPlans: number,
  setTakeOtherPlans: (newTake: number) => void,
  expandedPlanTypes: PersonalDevelopmentType[],
  setExpandedPlanTypes: (value:PersonalDevelopmentType[]) => void,
  dateRangeValue: DateRange<Date> | undefined,
  setdateRangeValue: (newValue: DateRange<Date> | undefined) => void,
  viewDashboardPerspective: ViewPerspective,
  setViewDashbaordPerspective: (newPerspective: ViewPerspective) => void,
  viewPerspective: ViewPersonalDevelopmentPlanPerspective,
  setViewPerspective: (newPerspective: ViewPersonalDevelopmentPlanPerspective) => void,
  planPermissions: PDPPermissions[],
  setPlanPermissions: (permissions: PDPPermissions[]) => void,
  meetingPdpId: string | undefined,
  setMeetingPdpId: (id: string | undefined) => void,
}

export const useStoreParams = create<UseStoreParamsStoreState>((set) => ({
  sortByField: undefined,
  setSortByField: (field: GetPersonalDevelopmentSortBy) => set(() => ({ sortByField: field })),
  sortByResourceField: undefined,
  setSortByResourceField: (field: GetPersonalDevelopmentResourceSortBy) => set(() => ({ sortByResourceField: field })),
  sortByOrder: GetPersonalDevelopmentSortOrder.Ascending,
  setSortByOrder: (order: GetPersonalDevelopmentSortOrder) => set(() => ({ sortByOrder: order })),
  sortByResourceOrder: GetPersonalDevelopmentSortOrder.Ascending,
  setSortByResourceOrder: (order: GetPersonalDevelopmentSortOrder) => set(() => ({ sortByResourceOrder: order })),
  page: 1,
  setPage: (newPage: number) => set(() => ({ page: newPage })),
  takeMyPlans: PERSONAL_DEVELOPMENT_MY_PLANS_PAGE_SIZE,
  setTakeMyPlans: (newTake: number) => set(() => ({ takeMyPlans: newTake })),
  takeOtherPlans: PERSONAL_DEVELOPMENT_OTHER_PLANS_PAGE_SIZE,
  setTakeOtherPlans: (newTake: number) => set(() => ({ takeOtherPlans: newTake })),
  // expandedPlanTypes: Object.keys(PersonalDevelopmentType) as PersonalDevelopmentType[],
  expandedPlanTypes: [PersonalDevelopmentType.Active],
  setExpandedPlanTypes: (newType: PersonalDevelopmentType[]) => set(() => ({ expandedPlanTypes: newType })),
  dateRangeValue: undefined,
  setdateRangeValue: (newDate: DateRange<Date> | undefined) => set(() => ({ dateRangeValue: newDate })),
  viewDashboardPerspective: ViewPerspective.MyPlans,
  setViewDashbaordPerspective: (newPerspective: ViewPerspective) => set(() => ({ viewDashboardPerspective: newPerspective })),
  viewPerspective: ViewPersonalDevelopmentPlanPerspective.Setup,
  setViewPerspective: (newPerspective: ViewPersonalDevelopmentPlanPerspective) => set(() => ({ viewPerspective: newPerspective })),
  planPermissions: [],
  setPlanPermissions: (permissions: PDPPermissions[]) => set(() => ({ planPermissions: permissions })),
  meetingPdpId: undefined,
  setMeetingPdpId: (id: string | undefined) => set(() => ({ meetingPdpId: id })),
}));
