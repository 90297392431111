import { Link, useHistory } from 'react-router-dom';
import { ADMIN_REVIEWS_V2, REVIEWS } from '~Common/const/routes';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { ChangeEvent } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '../Const/pageStyles';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
};

interface ViewProps {
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  searchString: string,
  goBackToAdminView: boolean,
}

export const View = ({
  handleSearchTextChange,
  searchString,
  goBackToAdminView,
}: ViewProps): JSX.Element => (
  <div css={styles.topBar}>
    <div css={styles.leftSide(true)}>
      <p>
        <LeadrButton
          css={styles.topBarBackTextButton}
          variant="text"
          textButtonColor={palette.neutrals.gray800}
          component={Link}
          to={goBackToAdminView ? ADMIN_REVIEWS_V2 : REVIEWS}
          data-test-id="reviewsCycleDetailsBackButton"
        >
          {goBackToAdminView ? 'Back to Admin Reviews Page' : 'Back to Reviews Page'}
        </LeadrButton>
      </p>
      <LeadrSearchField data-test-id="reviewsCycleDetailsSearchField" defaultValue={searchString} onChange={handleSearchTextChange} />
    </div>
    <div id="contextButtons" />
  </div>
);

interface CycleDetailsTopBarProps {
  onSearch: (newValue: string) => void,
  searchString: string,
}

export const CycleDetailsTopBar = ({
  onSearch,
  searchString,
}: CycleDetailsTopBarProps): JSX.Element => {
  const history = useHistory();
  const isAdminCycleDetails = history.location.pathname.includes('reviews/admin/cycle/');
  const { isAdmin } = useOrgUserPermissionsContext();
  const goBackToAdminView = isAdminCycleDetails && isAdmin;

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onSearch(event?.target.value);
  };

  const hookProps = {
    handleSearchTextChange,
    searchString,
    goBackToAdminView,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
