// import { NewActionItemStatus } from '~ActionItems/const/interfaces';

interface MeetingKebabAction {
  actionName: string,
  isEnabled: boolean,
  rank: number,
  section: number,
  disabledReason?: string,
}
export interface MeetingDetailsInterface {
  id: string,
  title: string,
  startTimeInMillis: number,
  endTimeInMillis: number,
  duration: number,
  factoryId: string,
  frequency: MeetingFrequency,
  meetingTimeZone: string,
  isSeriesActive: boolean,
  factoryType: MeetingFactoryType,
  daysOfWeek: DaysOfWeekType[],
  organizer: User,
  attendees: string[],
  attendeeOrgUserIds: string[],
  seriesOwner: string,
  agendaItems: AgendaItemInterface[],
  actionItems: ActionItemInterface[],
  permissions: MeetingPermissionInterface[],
  nextHuddleId: string,
  nextHuddleStartTime: string,
  huddlePrivateNotes: HuddlePrivateNotes[],
  kebabActions: MeetingKebabAction[],
  nonLeadrAttendees: NonLeadrAttendee[],
  teamId?: string,
  isSkipped?: boolean,
  location?: string,
  // Only for 1:1s
  contents?: unknown[],
  questions?: unknown[],
}

export interface MeetingPermissionInterface {
  action: MeetingPermissionEnum
  allow: boolean,
  until: number,
}
export enum MeetingPermissionEnum {
  CAN_EDIT_HUDDLE = 'canEditHuddle',
  CAN_SKIP_HUDDLE = 'canSkipHuddle',
  CAN_STOP_HUDDLE = 'canStopHuddle',
}
export interface AgendaItemInterface {
  id: string,
  text: string,
  isPrivate: boolean,
  isRecurring: boolean,
  creator: string,
  isBookmarked: boolean,
  isComplete: boolean,
  notes?: AgendaTopicNotes[],
  permissions: AgendaPermissionType[],
  rank?: number,
}

export interface AgendaTopicNotes {
  id: string,
  text: string,
  rank: number,
  isPrivate: boolean,
  creator: string
}
export interface HuddlePrivateNotes {
  noteId: string,
  rank: number,
  text: string,
}

export interface ActionItemInterface {
  id: string,
  text: string,
  status: ActionItemStatus,
  dueDateInMillis: number,
  assignee: string,
  creator: string
}

export interface User {
  firstName: string,
  lastName: string,
  orgUserId: string,
  userId: string,
}

// New Action Item data returned by the Huddle Action Items API
export interface HuddleActionItem {
  id: string,
  text: string,
  status: ActionItemStatus,
  dueDateInMillis: number,
  assignee: User,
  creator: User,
}

/**
 * @deprecated Depreciated In favor of NewActionItemStatus
 */
export enum ActionItemStatus {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETED = 'COMPLETED',
}

export enum AgendaPermissionType {
  CAN_CARRY_OVER_AGENDA = 'canCarryOverAgenda',
  CAN_EDIT_AGENDA = 'canEditAgenda',
  CAN_UPDATE_AGENDA_VISIBILITY = 'canUpdateAgendaVisibility',
  CAN_UPDATE_AGENDA_RECURRENCE = 'canUpdateAgendaRecurrence',
  CAN_UPDATE_AGENDA_STATUS = 'canUpdateAgendaStatus',
  CAN_REORDER_AGENDA = 'canReorderAgenda',
  CAN_DELETE_AGENDA = 'canDeleteAgenda',
  CAN_ADD_NOTE = 'canAddNote'
}

export enum MeetingFrequency {
  ONE_TIME = 'ONE_TIME',
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  MONTHLY_LAST = 'MONTHLY_LAST'
}

export enum DaysOfWeekType {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export enum MeetingTitle {
  ONE_ON_ONE_MEETING = '1:1 Meeting',
  ONE_TIME_ONE_ON_ONE = 'One-time 1:1 Meeting',
  TEAM_MEETING = 'Team Meeting',
}

export interface MeetingDefaults {
  defaultFrequency: MeetingFrequency,
  limit?: number,
  type: MeetingTypeEnum,
  title: MeetingTitle,
}

export enum MeetingTypeEnum {
  COACHING = 'COACHING',
  MEETING = 'MEETING',
}

export enum MeetingFactoryType {
  COACHING = 'COACHING',
  TEAM_MEETING = 'TEAM_MEETING'
}

export const CONVERT_MEETING_TYPES_TO_MEETING_FACTORY_TYPES = {
  [MeetingTypeEnum.MEETING]: MeetingFactoryType.TEAM_MEETING,
  [MeetingTypeEnum.COACHING]: MeetingFactoryType.COACHING,
};

export const CONVERT_MEETING_FACTORY_TYPES_TO_MEETING_TYPES = {
  [MeetingFactoryType.TEAM_MEETING]: MeetingTypeEnum.MEETING,
  [MeetingFactoryType.COACHING]: MeetingTypeEnum.COACHING,
};

interface AttendeeIdObject {
  id: string,
}

export interface NonLeadrAttendee {
  email: string,
}

interface MeetingOf {
  owner: AttendeeIdObject[],
  attendees: AttendeeIdObject[],
}
export interface Coaching {
  id: string,
  title: string,
  startTimeInMillis: number,
  endTimeInMillis: number,
  meetingOf: MeetingOf,
  ownerId: string,
  attendees: AttendeeIdObject[],
  category: MeetingFactoryType,
  teamId?: string,
}

// TODO: Finish typing this when you need them :P
export interface MeetingsTimeline {
  COACHING: Coaching[],
  CONTENT: unknown[],
  FEEDBACK: unknown[],
  TEAM_MEETING: unknown[],
}

export interface UpcomingMeeting {
  huddleAttendees: string[],
  id: string,
  title: string,
  startTimeInMillis: number,
  endTimeInMillis: number,
  huddleOwner: string, // orgUserId
  seriesOwner: string, // orgUserId
  factoryId: string,
  factoryType: MeetingFactoryType,
  frequency: MeetingFrequency,
  daysOfWeek: number[],
  nonLeadrAttendees: NonLeadrAttendee[],
}

export interface ArchivedMeeting {
  huddleAttendees: string[],
  huddleId: string,
  title: string,
  startTimeInMillis: number,
  endTimeInMillis: number,
  huddleOwner: string, // orgUserId
  seriesOwner: string, // orgUserId
  factoryId: string,
  factoryType: MeetingFactoryType,
  frequency: MeetingFrequency,
  daysOfWeek: number[],
  nonLeadrAttendees: NonLeadrAttendee[],
}

export interface PrivateMeetingNote {
  id: string,
  text: string,
  lastModifiedInMillis: number,
}

export interface NoteCreator {
  firstName: string,
  lastName: string,
  orgUserId: string,
  profileImageUrl?: string,
}

export interface AgendaTopicNote {
  id: string,
  text: string,
  rank: number,
  isPrivate: boolean,
  createdInMillis: number,
  lastModifiedInMillis?: number,
  creator: NoteCreator,
}

export interface AgendaTopicCreator {
  orgUserId: string,
  firstName: string,
  lastName: string,
  jobTitle?: string,
  profileImageUrl?: string,
}

export interface AgendaTopic {
  id: string,
  text: string,
  isPrivate: boolean,
  isRecurring: boolean,
  createdInMillis: number,
  lastModifiedInMillis?: number,
  creator: AgendaTopicCreator,
  notes: AgendaTopicNote[],
  isBookmarked: boolean,
  isComplete: boolean,
  permissions: AgendaPermissionType[],
}

// Templates
export interface CuratedMeetingTemplate {
  meetingTemplateTitle: string,
  isAssortedQuestionsMeetingTemplate: boolean,
  agendaQuestions: string[],
}

export interface TemplateTopic extends BaseTopic {
  isRecurring: boolean,
  isPrivate: boolean,
}

export interface BaseTopic {
  text: string,
  rank: number,
  id?: string,
}

export type TemplateType = MeetingFactoryType | 'MEETING_TEMPLATE';

export enum WorkingGeniusType {
  Wonder = 'Wonder',
  Invention = 'Invention',
  Discernment = 'Discernment',
  Galvanizing = 'Galvanizing',
  Enablement = 'Enablement',
  Tenacity = 'Tenacity',
}

export interface WorkingGenius {
  geniusOrgUserIdList: string[],
  frustrationOrgUserIdList: string[],
}

export interface WorkingGeniusWithType extends WorkingGenius {
  type: WorkingGeniusType,
}

// Timeline Types:
interface TimelineFeedback {
  assigneeId: string,
  category: 'FEEDBACK',
  createdDateInMillis: number,
  dueDate: number,
  id: string,
  introduction: string,
  title: string,
}

interface TimelineContent {
  assigneeId: string,
  category: 'CONTENT',
  createdDateInMillis: number,
  dueDate: number,
  id: string,
  introduction: string,
  title: string,
}

export interface TimelineData {
  huddles: Coaching[];
  feedback: TimelineFeedback[];
  content: TimelineContent[];
}
