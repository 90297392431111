import {
  Chart as ChartJS, ArcElement, Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { forMobile } from '~Deprecated/ui/styles/mixins';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';

ChartJS.register(ArcElement, Legend);

const styles = {
  container: (isExpanded) => css({
    display: 'grid',
    gridTemplateAreas: '"overlay"',
    height: '130px',
    width: '130px',
    margin: isExpanded ? '0 auto' : 0,

    '& > *': {
      gridArea: 'overlay',
    },
  }),
  donut: css({
    placeSelf: 'center',
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    maxWidth: '100%',
  }),
  percentage: css({
    paddingTop: '0.75rem',
    placeSelf: 'center',
    fontSize: '1.5rem',
    textAlign: 'center',
  }),
  mobile: css`
    ${forMobile(`
        width: 160px !important;
        height: 160px !important;
    `)}
  `,

};

const View = ({
  overallPercent, options, data, isExpanded, ...props
}) => (
  <div css={styles.container(isExpanded)}>
    <div css={styles.donut}>
      <Doughnut options={options} data={data} {...props} />
    </div>
    <div css={styles.percentage}>
      {overallPercent}
      %
    </div>
  </div>
);

View.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  options: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  overallPercent: PropTypes.number,
  isExpanded: PropTypes.bool,
};
View.defaultProps = {
  overallPercent: 0,
  isExpanded: false,
};

export const DonutChart = ({ actualValue, isExpanded, ...props }) => {
  const isMobile = useIsMobileQuery();
  const emptyArea = 100 - actualValue;
  const hookProps = {
    isExpanded,
    overallPercent: actualValue,
    label: [],
    actualValue: 0,
    maxValue: 0,
    options: {
      maintainAspectRatio: false,
      padding: 0,
      responsive: true,
      rotation: 180,
      weight: 2,
      cutout: isMobile ? 55 : 40,
      legend: {
        display: false,
      },
    },
    data: {
      datasets: [{
        data: [actualValue, emptyArea],
        backgroundColor: [palette.brand.orange, palette.neutrals.gray200],
        borderWidth: 0,
      }],
    },
  };

  return (
    <View {...hookProps} {...props} />
  );
};

DonutChart.propTypes = {
  maxValue: PropTypes.number,
  actualValue: PropTypes.number,
  value: PropTypes.shape({
    actualValue: PropTypes.number,
    labels: PropTypes.string,
  }),
  isExpanded: PropTypes.bool,
};

DonutChart.defaultProps = {
  maxValue: 0,
  actualValue: 0,
  value: [],
  isExpanded: false,
};
