import { useShowGlobalSearchModal } from '~GlobalSearch/stores/useShowGlobalSearchModal';
import { useKeyPress } from '~Common/hooks/useKeyPress';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { noop } from 'lodash';

export const useGlobalSearchKeyboardShortcut = (): void => {
  const setShowModal = useShowGlobalSearchModal((state) => state.setShowModal);

  const useGlobalSearch = useFeatureFlag('useGlobalSearch');

  useKeyPress({
    keys: ['k'],
    callback: useGlobalSearch ? () => {
      setShowModal(true);
    } : noop,
    // Metakey is command on Mac, CtrlKey is for Windows
    additionalChecksCallback: (event: KeyboardEvent) => event.metaKey || event.ctrlKey,
  });
};
