import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { ReceivedLearning } from '~Learning/const/interfaces';
import { receivedLearningQueryKeys } from './queryKeys';

const getReceivedLearnings = async (): Promise<ReceivedLearning[]> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/received/list`;

  const result = await getApi<{learnings: ReceivedLearning[]}>(serverUrl);

  return result.response.learnings;
};

export const useGetReceivedLearnings = (): UseQueryResult<ReceivedLearning[]> => useQuery({
  queryKey: receivedLearningQueryKeys.list(),
  queryFn: getReceivedLearnings,
});
