import { useSelector } from 'react-redux';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import { getFeatureFlags } from '~Deprecated/selectors/featureFlags/getFeatureFlags';
import { useInsightPrivileges } from '~Insights/hooks/useInsightPrivileges';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useIsDesktopQuery } from '~Common/hooks/useMediaListener';
import { FeatureFlags, IsVisibleProps } from '~Root/components/OldNavigationSidebar/types';
import { useGetUserOrganizationList } from '~Root/hooks/useGetUserOrganizationList';
import { useHasNexus } from '~Common/hooks/permissions/useHasNexus';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

export function useNavigationPermissions(
  isCollapsed = false,
): IsVisibleProps {
  // TODO: figure out how to store feature flags outside of redux
  const flags = useSelector(getFeatureFlags) as FeatureFlags;
  const { isAdmin } = useUserPermissions();
  const { canSeeInsights } = useInsightPrivileges();
  const organization = useOrgDetailsContext();
  const { organizationInTrial } = useIsOrganizationInTrial();
  const { organizations } = useGetUserOrganizationList();

  const hasMultipleOrganizations = organizations?.length > 1;
  const { isImpersonator } = useOrgUserPermissionsContext();
  const canImpersonate = !!flags.whiteHatImpersonator && isImpersonator && !window.sessionStorage.getItem('sessionId');

  const canSeeNexus = useHasNexus();

  const isMobile = !useIsDesktopQuery();

  return {
    isAdmin,
    isCollapsed,
    isMobile,
    canSeeInsights,
    canSeeNexus,
    canImpersonate,
    flags,
    hasMultipleOrganizations,
    organization,
    organizationInTrial,
  };
}
