import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { LearningTemplateDetail, useGetLearningTemplate } from '../templates/singleContent/useGetLearningTemplate';
import { useGetLearningTableGroupTemplateDetail } from '../templates/curatedByTheTableGroup/useGetLearningTableGroupTemplateDetail';
import { useGetLearningCuratedTemplateDetail } from '../templates/curatedByLeadr/useGetLearningCuratedTemplateDetail';

interface UseGetCombinedLearningTemplateDetailParams {
  templateId: string,
  isCuratedByLeadr?: boolean,
  isCuratedByTableGroup: boolean,
}

interface UseGetCombinedLearningTemplateDetailReturn {
  learningTemplate: LearningTemplateDetail | undefined,
  isLoading: boolean,
}

export const useGetCombinedLearningTemplateDetail = ({
  templateId,
  isCuratedByLeadr = false,
  isCuratedByTableGroup,
}: UseGetCombinedLearningTemplateDetailParams): UseGetCombinedLearningTemplateDetailReturn => {
  const useDynamicCuratedByLeadrLibrary = useFeatureFlag('learningCuratedByLeadrInApp') && isCuratedByLeadr;

  const {
    detail: orgLearningTemplate,
    isLoading: orgTemplateIsLoading,
  } = useGetLearningTemplate({
    templateId,
    enabled: !isCuratedByTableGroup && !useDynamicCuratedByLeadrLibrary,
  });

  const {
    detail: leadrCuratedLearningTemplate,
    isLoading: leadrCuratedTemplateIsLoading,
  } = useGetLearningCuratedTemplateDetail({
    templateId,
    enabled: useDynamicCuratedByLeadrLibrary && !isCuratedByTableGroup,
  });

  const {
    detail: tableGroupLearningTemplate,
    isLoading: tableGroupTemplateIsLoading,
  } = useGetLearningTableGroupTemplateDetail({
    templateId,
    enabled: isCuratedByTableGroup,
  });

  const leadrLearningTemplate = useDynamicCuratedByLeadrLibrary ? leadrCuratedLearningTemplate : orgLearningTemplate;
  const leadrTemplateIsLoading = useDynamicCuratedByLeadrLibrary ? orgTemplateIsLoading : leadrCuratedTemplateIsLoading;

  const learningTemplate = isCuratedByTableGroup ? tableGroupLearningTemplate : leadrLearningTemplate;

  const isLoading = isCuratedByTableGroup ? tableGroupTemplateIsLoading : leadrTemplateIsLoading;

  return {
    learningTemplate,
    isLoading,
  };
};
