import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { Goals } from '@leadr-hr/types';
import Tooltip from '~Common/components/Tooltip';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { PROGRESS_BAR_STYLES } from '~Goals/const/styles';

const styles = {
  ...PROGRESS_BAR_STYLES,
  progress: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '.3125rem',
  }),
  progressBarWrap: (isMobile: boolean) => css({
    flexBasis: '80%',
  }, isMobile && {
    flexBasis: '90%',
  }),
  percent: (isMobile: boolean, smallBar: boolean) => css({
    flexBasis: '15%',
    fontSize: '16px',
    fontWeight: 500,
    color: palette.neutrals.gray800,
  }, isMobile && {
    flexBasis: '10%',
    alignContent: 'center',
    textAlign: 'right',
  }, smallBar && {
    fontSize: '.75rem',
    fontWeight: 400,
  }),
  lighterBackground: css({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: palette.neutrals.gray200,
    },
  }),
  progressBarAdjustment: (smallBar: boolean) => css({}, smallBar && {
    height: '.25rem',
  }),
};

interface GoalProgressBarProps {
  isMobile: boolean,
  status: Goals.GoalStatusUpdate | undefined,
  smallBar?: boolean,
}

export function GoalProgressBar({
  isMobile,
  status,
  smallBar = false,
}: GoalProgressBarProps): JSX.Element {
  return (
    <>
      <div css={styles.progress}>
        <div css={styles.progressBarWrap(isMobile)}>
          <Tooltip content={`${status?.completionPercentage ?? 0}%`}>
            <LinearProgress
              css={[styles.progressBar(status?.status ?? Goals.GoalStatus.OnTrack), styles.lighterBackground, styles.progressBarAdjustment(smallBar)]}
              variant="determinate"
              value={status?.completionPercentage ?? 0}
            />
          </Tooltip>
        </div>
        <div css={styles.percent(isMobile, smallBar)}>
          {`${status?.completionPercentage || 0}`}
          %
        </div>
      </div>
    </>
  );
}
