import { css } from '@emotion/react';
import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { REVIEWS_CONTINUE_CREATING_CYCLE } from '~Common/const/routes';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import { navigateAction } from '~Deprecated/actions/navigate';
import { conformToTemplateCycle } from '~Reviews/V2/Const/functions';
import { TopicShape, ViewCyclePerspective } from '~Reviews/V2/Const/types';
import { useCreateTopicForCycle } from '~Reviews/V2/Hooks/useCreateTopicForCycle';
import { useCreateTopicForTemplateCycle } from '~Reviews/V2/Hooks/useCreateTopicForTemplateCycle';
import { ReviewTemplate } from '../useReviewTemplates';

const styles = {
  button: css({
    flexShrink: 0,
  }),
};

interface ViewProps {
  isAlreadyAdded: boolean,
  isLoading: boolean,
  onClick: MouseEventHandler,
}

export const View = ({
  isAlreadyAdded,
  isLoading,
  onClick,
  ...props
}: ViewProps): JSX.Element => (
  <SpinnerButton
    css={styles.button}
    color={isAlreadyAdded ? 'primary' : 'secondary'}
    icon={isAlreadyAdded ? faCheck : faPlus}
    isLoading={isLoading}
    label={isAlreadyAdded ? 'Added' : 'Add All to Review'}
    loadingLabel="Adding..."
    onClick={onClick}
    size="small"
    {...props}
  />
);

interface AddAllTopicsButtonProps {
  topics: TopicShape[],
  reviewCycleUid: string,
  template: ReviewTemplate
}

export const AddAllTopicsButton = ({
  topics,
  reviewCycleUid,
  template,
  ...props
}: AddAllTopicsButtonProps): JSX.Element => {
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsAlreadyAdded(false);
  }, [template]);
  // I didn't have enough type to chain the generic typing for the hook so when you update the key, update ReviewTemplateForCycle as well
  const keysToRemove = ['createdBy', 'organizationUid', 'isDeleted', 'createdAt', 'lastModifiedAt'];
  const newTemplate = conformToTemplateCycle(template, keysToRemove as (keyof ReviewTemplate)[]);

  const { isPending: isLoading, mutate: createTopicForCycle } = useCreateTopicForCycle();
  const { mutate: createTopicForTemplateCycle } = useCreateTopicForTemplateCycle();
  const onClick: MouseEventHandler = () => {
    // Checking to see if we're in the createCycle flow or not
    if (typeof reviewCycleUid === 'string') {
      createTopicForCycle({ cycleId: reviewCycleUid, topics }, {
        onSuccess() {
          setIsAlreadyAdded(true);
        },
      });
    } else {
      // We're not in the createCycle flow
      createTopicForTemplateCycle({ template: newTemplate }, {
        onSuccess(data) {
          const cycleIDResponse = data.response;
          dispatch(navigateAction({
            pathname: `${REVIEWS_CONTINUE_CREATING_CYCLE}/${ViewCyclePerspective.Setup}`,
            params: {
              cycleId: cycleIDResponse,
            },
          }));
        },
      });
    }
  };

  const hookProps = {
    isAlreadyAdded,
    isLoading,
    onClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
