import { css, SerializedStyles } from '@emotion/react';
import { faArrowLeft, faTimes } from '@fortawesome/pro-light-svg-icons';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { useCreateContentDetails } from '~Learning/hooks/utils/useCreateContentDetails';
import { useCreateLearningTemplate } from '~Learning/hooks/templates/singleContent/useCreateLearningTemplate';
import TitleAndLink from '~Learning/components/Shared/TitleAndLink';
import QuestionsList from '~Learning/components/Shared/QuestionList';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FrontendRankedQuestion } from '~Learning/const/interfaces';
import LearningTemplateCategoryButtons from '~Learning/components/LearningLibrary/LearningTemplateCategoryButtons';
import DrawerCloseButtonWithConfirmation from '~Learning/components/Shared/ConfirmationButtons/DrawerCloseButtonWithConfirmation';
import { createLearningTemplate } from '~Learning/components/CreateLearningDrawer';

const styles = {
  categorySection: css({
    marginTop: '2.375rem',
  }),
  buttonsContainer: css({
    marginTop: '1.125rem',
    display: 'flex',
  }),
  saveButton: css({
    marginRight: '.625rem',
  }),
};

export const createLearningTemplateDrawerTemplate = {
  name: 'CREATE_LEARNING_TEMPLATE_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface CreateLearningTemplateDrawerState {
  shouldConfirmDrawerClose?: boolean,
  isLearningLibraryWorkflow?: boolean,
}

interface CreateLearningTemplateDrawerProps extends DrawerProps<CreateLearningTemplateDrawerState> {
  initialTemplateCategoryId?: string,
  initialContentTitle?: string,
  initialContentLink?: string,
  initialQuestions?: FrontendRankedQuestion[],
  saveTemplateCallback?: () => void,
}

const CreateLearningTemplateDrawer = ({
  initialContentTitle,
  initialContentLink,
  initialQuestions,
  initialTemplateCategoryId,
  saveTemplateCallback,
  drawerState,
  popDrawer,
  pushDrawer,
}: CreateLearningTemplateDrawerProps): JSX.Element => {
  const closeDrawer = (): void => {
    popDrawer({ drawerName: createLearningTemplateDrawerTemplate.name });
  };

  const createLearningTemplateMutation = useCreateLearningTemplate({
    onSuccessCallback: () => {
      closeDrawer();
      saveTemplateCallback?.();
    },
  });

  const backDrawerClick = (): void => {
    closeDrawer();
    pushDrawer({ drawer: createLearningTemplate });
  };

  const {
    onSubmit,
    questions,
    setQuestions,
    contentTitle,
    setContentTitle,
    contentLink,
    setContentLinkError,
    categoryId,
  } = useCreateContentDetails({
    afterSubmitValidationCallback: ({
      contentTitle: contentTitleFromForm,
      contentLink: contentLinkFromForm,
      categoryId: categoryIdFromForm,
    }) => {
      const newLearningTemplate = {
        categoryId: categoryIdFromForm,
        title: contentTitleFromForm,
        contentURL: contentLinkFromForm,
        questions,
      };

      createLearningTemplateMutation(newLearningTemplate);
    },
    initialContentLink,
    initialContentTitle,
    initialQuestions,
    initialTemplateCategoryId,
  });

  const shouldConfirmDrawerClose = drawerState?.shouldConfirmDrawerClose && !drawerState?.isLearningLibraryWorkflow;

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title="Create Learning Template"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <DrawerCloseButtonWithConfirmation
            renderDrawerCloseButton={({ onClick }) => (
              <IconButton onClick={onClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
            )}
            onCloseDrawer={closeDrawer}
            shouldConfirmClose={shouldConfirmDrawerClose}
          />
        )}
        renderBackButton={!drawerState.isLearningLibraryWorkflow ? ((backButtonStyles: SerializedStyles) => (
          <IconButton onClick={backDrawerClick} type="button" icon={faArrowLeft} css={backButtonStyles} tooltip="Back" />
        )) : undefined}
      />
    ),
    renderBody: (defaultDrawerPadding: SerializedStyles) => (
      <div css={defaultDrawerPadding}>
        <form onSubmit={onSubmit}>
          <TitleAndLink
            contentTitle={contentTitle}
            contentLink={contentLink}
            setContentTitle={setContentTitle}
            setContentLinkError={setContentLinkError}
          />
          <QuestionsList
            questions={questions}
            setQuestions={setQuestions}
          />
          <LearningTemplateCategoryButtons
            css={styles.categorySection}
            categoryId={categoryId}
          />
          <div css={styles.buttonsContainer}>
            <LeadrButton
              type="submit"
              css={styles.saveButton}
              data-test-id="learningSaveNewTemplate"
            >
              Save Template
            </LeadrButton>
            <DrawerCloseButtonWithConfirmation
              renderDrawerCloseButton={({ onClick }) => (
                <LeadrButton
                  variant="ghost"
                  onClick={onClick}
                  data-test-id="learningCancelNewTemplate"
                >
                  Cancel
                </LeadrButton>
              )}
              onCloseDrawer={closeDrawer}
              shouldConfirmClose={shouldConfirmDrawerClose}
            />
          </div>
        </form>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: createLearningTemplateDrawerTemplate.name,
  component: CreateLearningTemplateDrawer,
});

export default CreateLearningTemplateDrawer;
