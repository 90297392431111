import moment from 'moment';
import { PRINTVIEW_TIMESTAMP } from '~Common/const/dateFormats';
import { usePrintView } from '~Common/stores/usePrintView';

export enum LEADR_DUE_DATE_STATUS {
  NO_DUE_DATE = 'NO_DUE_DATE',
  TODAY_OR_TOMORROW = 'TODAY_OR_TOMORROW',
  YESTERDAY = 'YESTERDAY',
  PAST = 'PAST',
  FUTURE = 'FUTURE',
  COMPLETE = 'COMPLETE',
  DRAFT = 'DRAFT',
  CLOSED = 'CLOSED',
}

export type DueDate = string | Date | number;
export type SecondDate = string | Date | number;
export type ItemStatus = 'active' | 'complete' | 'draft' | 'closed';

interface UseLeadrDueDateActiveProps {
  itemStatus: 'active',
  dueDate: DueDate,
  secondDate?: SecondDate,
}

interface UseLeadrDueDateNotActiveProps {
  itemStatus: 'complete' | 'draft' | 'closed',
  secondDate: SecondDate,
  dueDate?: DueDate,
}

export type UseDueDateFromNowProps = UseLeadrDueDateActiveProps | UseLeadrDueDateNotActiveProps;

interface useDueDateFromNowReturn {
  dueDateText: string,
  dueDateStatus: LEADR_DUE_DATE_STATUS,
}

export const useLeadrDueDate = ({ dueDate, itemStatus, secondDate }: UseDueDateFromNowProps): useDueDateFromNowReturn => {
  let dueDateText = 'No Due Date';
  let dueDateStatus: LEADR_DUE_DATE_STATUS = LEADR_DUE_DATE_STATUS.NO_DUE_DATE;

  const isPrintView = usePrintView((state) => state.isPrintView);

  if (!dueDate && !secondDate) {
    return {
      dueDateText,
      dueDateStatus,
    };
  }

  const isActive = itemStatus === 'active';

  // If the item is not active, we still have to make sure there is a secondDate, since some cases won't have that
  const momentDate = moment(!isActive && secondDate ? secondDate : dueDate);
  const currentDate = moment();

  const isToday = momentDate.isSame(currentDate, 'day');
  const isTomorrow = momentDate.isSame(currentDate.clone().add(1, 'day'), 'day');
  const isYesterday = momentDate.isSame(currentDate.clone().subtract(1, 'day'), 'day');
  const isPast = momentDate.isBefore(currentDate, 'day');

  if (isToday) {
    dueDateText = 'Today';
    dueDateStatus = LEADR_DUE_DATE_STATUS.TODAY_OR_TOMORROW;
  } else if (isTomorrow) {
    dueDateText = 'Tomorrow';
    dueDateStatus = LEADR_DUE_DATE_STATUS.TODAY_OR_TOMORROW;
  } else if (isYesterday) {
    dueDateText = 'Yesterday';
    dueDateStatus = LEADR_DUE_DATE_STATUS.YESTERDAY;
  } else {
    const isSameYear = momentDate.isSame(currentDate, 'year');

    if (isSameYear) {
      dueDateText = momentDate.format('MMM D');
    } else {
      dueDateText = momentDate.format('MMM D, YYYY');
    }

    if (isPrintView) {
      dueDateText = momentDate.format(PRINTVIEW_TIMESTAMP);
    }

    if (isPast) {
      dueDateStatus = LEADR_DUE_DATE_STATUS.PAST;
    } else {
      dueDateStatus = LEADR_DUE_DATE_STATUS.FUTURE;
    }
  }

  if (itemStatus === 'complete') {
    dueDateStatus = LEADR_DUE_DATE_STATUS.COMPLETE;
  } else if (itemStatus === 'draft') {
    dueDateStatus = LEADR_DUE_DATE_STATUS.DRAFT;
    dueDateText = 'Draft - Not Sent';
  } else if (itemStatus === 'closed') {
    dueDateStatus = LEADR_DUE_DATE_STATUS.CLOSED;
    dueDateText = `Closed ${dueDateText}`;
  }

  return {
    dueDateText,
    dueDateStatus,
  };
};
