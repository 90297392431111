import { SelectChangeEvent } from '@mui/material';
import { useHitsPerPage } from 'react-instantsearch-hooks-web';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';

const styles = {
  algoliaCustomHitsPerPage: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    gap: '.25rem',
  }, forMobileObject({
    display: 'none',
  })),
  dropdown: css({
    fontSize: '0.75rem',
    padding: '0.25rem',
    width: 'auto',

    '.MuiInput-root': {
      fontSize: '0.75rem',
      marginTop: 0,
    },
  }),
};

interface ViewProps {
  handleHitsPerPageChange: (event: SelectChangeEvent) => void,
  currentValue: number,
  dropdownItems: DropdownItem[],
}

const rowsPerPageOptions = [
  {
    label: '50 Rows',
    value: 50,
    default: true,
    'data-test-id': '50rowsPerPage',
  },
  {
    label: '100 Rows',
    value: 100,
    'data-test-id': '100rowsPerPage',
  },
];

const View = ({
  handleHitsPerPageChange,
  currentValue,
  dropdownItems,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.algoliaCustomHitsPerPage}>
    <span>Showing:</span>
    <Dropdown
      data-test-id="rowsDisplayed"
      css={styles.dropdown}
      items={dropdownItems}
      onChange={handleHitsPerPageChange}
      value={currentValue.toString()}
      {...props}
    />
  </div>
);

type AlgoliaCustomHitsPerPageProps = Omit<ViewProps, 'handleHitsPerPageChange' | 'currentValue' | 'dropdownItems'>;

const AlgoliaCustomHitsPerPage = ({ ...props }: AlgoliaCustomHitsPerPageProps): JSX.Element => {
  const { refine, items } = useHitsPerPage({
    items: rowsPerPageOptions,
  });

  const { value: currentValue = rowsPerPageOptions[0].value } = items.find(({ isRefined }) => isRefined) || {};

  const handleHitsPerPageChange = (event: SelectChangeEvent): void => {
    refine(Number(event.target.value));
  };

  const dropdownItems = useMemo(() => rowsPerPageOptions.map(({ label, value, ...rest }) => ({
    text: label,
    value: value.toString(),
    ...rest,
  })), []);

  const hookProps = {
    handleHitsPerPageChange,
    currentValue,
    dropdownItems,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AlgoliaCustomHitsPerPage;
