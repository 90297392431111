import { getOrganizationId } from '~Common/utils/localStorage';
import { useFileUploadMutation } from '~Deprecated/hooks/fileUpload/mutators';

export const useFileUpload = ({ queryKey = [getOrganizationId(), 'profile_picture'], ...args }) => {
  const {
    fileUploadMutation, isLoading, status, resetUploadFile,
  } = useFileUploadMutation({
    queryKey,
    ...args,
  });

  const uploadFile = (url, file) => {
    fileUploadMutation({
      url,
      file,
    });
  };

  return {
    uploadFile,
    resetUploadFile,
    status,
    isLoading,
  };
};
