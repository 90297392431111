import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Goals } from '@leadr-hr/types';
import { getDateString } from '~Common/utils/dateString';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { GoalProgressBar } from './GoalProgressBar';
import NewGoalStatus from './NewGoalStatus';

const styles = {
  accordian: css({
    boxShadow: 'none',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
    borderRadius: '0 !important',

    '&:before': {
      display: 'none',
    },
    '.Mui-expanded': {
      margin: 0,
      minHeight: 'unset',
    },
  }),
  accordianSummaryContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: '.625rem',
  }),
  progressbarContainer: css({
    maxWidth: '5rem',
    width: '100%',
  }),
  dateString: css({
    fontSize: '.75rem',
    fontWeight: 400,
    color: palette.neutrals.gray700,
  }),
  content: css({
    color: palette.neutrals.gray800,
    fontSize: '.875rem',
    fontWeight: 500,
    marginTop: '.75rem',
  }),
  icon: css({
    color: palette.neutrals.gray800,
  }),
  statusUpdateCreatorContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '-1.5rem',
  }),
  avatarContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '.5rem',
    alignItems: 'center',
    fontSize: '.875rem',
    color: palette.neutrals.gray800,
    fontWeight: 500,
    paddingRight: '1.4rem',
  }),
};

interface GoalHistoryDrawerDetailsProps {
    statusUpdates: Goals.GoalStatusUpdate[],
}

export function GoalHistoryDrawerDetails({
  statusUpdates,
}: GoalHistoryDrawerDetailsProps): JSX.Element {
  return (
    <>
      { statusUpdates.map((statusUpdate) => {
        const { dateString } = getDateString({ timestamp: statusUpdate.createdInMillis });

        return (
          <>
            <Accordion
              css={styles.accordian}
              key={statusUpdate.statusId}
            >
              <AccordionSummary
                expandIcon={(
                  <FontAwesomeIcon
                    css={styles.icon}
                    icon={faChevronDown}
                  />
                    )}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div
                  css={styles.accordianSummaryContainer}
                >
                  <div
                    css={styles.progressbarContainer}
                  >
                    <GoalProgressBar
                      status={statusUpdate}
                      isMobile
                      smallBar
                    />
                  </div>
                  <div
                    css={styles.dateString}
                  >
                    {dateString}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  css={styles.statusUpdateCreatorContainer}
                >
                  <NewGoalStatus
                    status={statusUpdate?.status}
                    largeFont
                  />
                  <div
                    css={styles.avatarContainer}
                  >
                    <AvatarWithTooltip
                      firstName={statusUpdate.creator.firstName}
                      lastName={statusUpdate.creator.lastName}
                      profileImageUrl={statusUpdate.creator.profileImageUrl}
                      height={22}
                      width={22}
                    />
                    {`${statusUpdate.creator.firstName} ${statusUpdate.creator.lastName}`}
                  </div>
                </div>
                <HTMLRenderer css={styles.content} htmlText={statusUpdate.statusCommentary} />
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </>
  );
}
