import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import BaseDrawerOverlay from './Base';

const style = css`

`;

const Constructive = (props) => (
  <BaseDrawerOverlay
    css={style}
    {...props}
  />
);

Constructive.propTypes = {
  setSubmitter: PropTypes.func.isRequired,
  context: PropTypes.func.isRequired,
};

export default Constructive;
