import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { palette } from '~Common/styles/colors';
import { noop } from '~Deprecated/utils';

const styles = {
  selectButtons: css`
    display: flex;
  `,
  buttonDivider: css`
    margin: 0 .5rem;
    font-size: .625rem;
    color: ${palette.neutrals.gray700};
  `,
};

const View = ({ renderSelectAllButton, renderDeselectAllButton, ...props }) => (
  <div css={styles.selectButtons} {...props}>
    {renderSelectAllButton()}
    <div css={styles.buttonDivider}>|</div>
    {renderDeselectAllButton()}
  </div>
);

View.propTypes = {
  renderSelectAllButton: PropTypes.func,
  renderDeselectAllButton: PropTypes.func,
};

View.defaultProps = {
  renderSelectAllButton: noop,
  renderDeselectAllButton: noop,
};

const SelectAllButtons = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SelectAllButtons.propTypes = {};

SelectAllButtons.defaultProps = {};

export { View };
export default SelectAllButtons;
