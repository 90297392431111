import MeetingsList from '~Deprecated/ui/views/Meetings';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { useUpcomingMeetingsByDate } from '~Meetings/hooks/useUpcomingMeetings';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { useMeetingUrl } from '~Common/hooks/useMeetingUrl';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useGenericStore } from '~Common/hooks/useGenericStore';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { useGetUnsyncedCalendarMeetings } from '~Meetings/hooks/CalendarIntegration/useGetUnsyncedCalendarMeetings';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useShowConnectCalendarBanner } from '~Meetings/hooks/useShowConnectCalendarBanner';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';

const UpcomingMeetings = () => {
  const { meetingListByDate: meetings, meetingDates, isLoading } = useUpcomingMeetingsByDate();
  const { data: isAuthenticated, isLoading: isCalendarAuthStatusLoading } = useGetCalendarAuthStatus();
  const { unsyncedCalendarMeetingsCount } = useGetUnsyncedCalendarMeetings();
  const { isLimitedAccess: isLimitedAccessUser } = useUserPermissions();

  const {
    setValue: setShowReviewUnsyncedMeetingsBanner,
    value: showReviewUnsyncedMeetingsBanner,
  } = useGenericStore({
    module: 'nylas', workflow: 'notification', feature: 'showReviewUnsyncedMeetingsBanner', defaultValue: true,
  });

  const { showConnectCalendarBanner, dismissConnectCalendarBanner } = useShowConnectCalendarBanner();

  // Upcoming Meetings have a dependency on our People Hook
  usePeopleQuery();
  const nylasEnabled = useFeatureFlag('nylas');
  const { navigateToMeeting } = useMeetingUrl();

  const onCardClick = (meetingDetails, isCoaching) => {
    const meetingType = isCoaching ? MeetingTypeEnum.COACHING : MeetingTypeEnum.MEETING;

    navigateToMeeting({
      meetingType,
      factoryId: meetingDetails.factoryId,
      meetingInstanceId: meetingDetails.id,
      teamId: meetingDetails.teamId,
    });
  };

  const dismissReviewUnsyncedMeetingsBanner = () => setShowReviewUnsyncedMeetingsBanner(false);

  const hookProps = {
    isLoading,
    meetings,
    meetingDates,
    onCardClick,
    isAuthenticated,
    numberOfMeetingsToReview: unsyncedCalendarMeetingsCount,
    showEmptyMeetingState: isLoading || meetingDates.length === 0,
    showConnectCalendarBanner: !isCalendarAuthStatusLoading && nylasEnabled
      && isAuthenticated !== AUTH_STATUS.LINKED && showConnectCalendarBanner && !isLimitedAccessUser,
    dismissConnectCalendarBanner,
    dismissReviewUnsyncedMeetingsBanner,
    showReviewUnsyncedMeetingsBanner: !isCalendarAuthStatusLoading && nylasEnabled && isAuthenticated === AUTH_STATUS.LINKED
    && unsyncedCalendarMeetingsCount > 0 && showReviewUnsyncedMeetingsBanner && !isLimitedAccessUser,
  };

  return (
    <MeetingsList
      {...hookProps}
    />
  );
};

UpcomingMeetings.propTypes = {

};

UpcomingMeetings.defaultProps = {

};

export default UpcomingMeetings;
