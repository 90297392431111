import { SyntheticEvent } from 'react';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { ViewType } from '~Common/const/interfaces';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';
import { useFeedbackViewType } from '~Feedback/stores/useFeedbackViewType';

interface ViewProps {
  selectedViewType: ViewType,
  onChange: (event: SyntheticEvent, option: ViewType) => void,
}

const viewTypes = [
  {
    value: ViewType.GRID,
    icon: faGripHorizontal,
    'data-test-id': 'feedbackGrid',
  },
  {
    value: ViewType.LIST,
    icon: faList,
    'data-test-id': 'feedbackList',
  },
];

const View = ({
  selectedViewType,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs value={selectedViewType} {...props}>
    {viewTypes.map((viewType) => (
      <LeadrToggleTabs.IconTab data-test-id={viewType['data-test-id']} key={viewType.value} icon={viewType.icon} value={viewType.value} />
    ))}
  </LeadrToggleTabs>
);

const FeedbackViewToggle = ({ ...props }): JSX.Element => {
  const {
    viewType: selectedViewType,
    setViewType,
  } = useFeedbackViewType((state) => ({
    viewType: state.viewType,
    setViewType: state.setViewType,
  }));

  const onChange = (e: SyntheticEvent, newViewType: ViewType): void => {
    if (newViewType) {
      setViewType(newViewType);
    }
  };

  const hookProps = {
    selectedViewType,
    onChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default FeedbackViewToggle;
