import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareArrowUpLeft } from '@fortawesome/pro-solid-svg-icons';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';

const styles = {
  openOverlay: css({
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: palette.neutrals.white,
    color: palette.neutrals.gray900,
    padding: '.1875rem',
    borderBottomRightRadius: '.3125rem',
    display: 'flex',
  }),
  navigateButton: css({
    lineHeight: 1,
  }),
};

interface ExternalNavigationOverlayProps extends ComponentProps<'div'> {
  contentUrl: string,
}

const ExternalNavigationOverlay = ({
  contentUrl,
  ...props
}: ExternalNavigationOverlayProps): JSX.Element => (
  <div css={styles.openOverlay} {...props}>
    <Tooltip content="Click to navigate.">
      <a
        target="_blank"
        rel="noreferrer"
        href={contentUrl}
        css={styles.navigateButton}
      >
        <FontAwesomeIcon icon={faSquareArrowUpLeft} size="2x" />
      </a>
    </Tooltip>
  </div>
);

export default ExternalNavigationOverlay;
