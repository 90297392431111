import { css } from '@emotion/react';
import { Person } from '~Common/const/interfaces';
import { useMemo } from 'react';

import { palette } from '~Common/styles/colors';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { WorkingGeniusWithType } from '~Meetings/const/meetingsInterfaces';
import { WorkingGeniusMap } from '~Meetings/const/workingGenius';
import UserDisplay from './UserDisplay';
import WorkingGeniusImageMap from './WorkingGeniusImageMap';

const styles = {
  workingGeniusSection: css({
    backgroundColor: palette.neutrals.gray50,
    padding: '.75rem',
    position: 'relative',
  }),
  workingGeniusType: css({
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: '.75rem',
  }),
  typeImage: css({
    position: 'absolute',
    top: '.75rem',
    right: '1.3125rem',
    height: '2.375rem',
    width: '2.375rem',
    filter: 'invert(87%) sepia(4%) saturate(126%) hue-rotate(201deg) brightness(88%) contrast(84%)', // gray600
  }),
  table: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  }),
  columnHeaderText: css({
    fontWeight: 600,
    paddingBottom: '.125rem',
    borderBottom: `1px solid ${palette.neutrals.gray400}`,
  }),
  geniusText: css({
    color: palette.brand.green,
    borderRight: `1px solid ${palette.neutrals.gray400}`,
  }),
  frustrationText: css({
    color: palette.brand.red,
    paddingLeft: '.625rem',
  }),
  usersContainer: css({
    paddingTop: '.875rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    overflow: 'hidden',
  }),
  geniusUsersContainer: css({
    borderRight: `1px solid ${palette.neutrals.gray400}`,
    paddingRight: '.3125rem',
  }),
  frustrationUsersContainer: css({
    paddingLeft: '.625rem',
  }),
  emptyState: css({
    fontStyle: 'italic',
    color: palette.neutrals.gray700,
  }),
};

interface ViewProps {
  workingGeniusSection: WorkingGeniusWithType,
  geniusUsers: Person[],
  frustrationUsers: Person[],
}

const View = ({
  workingGeniusSection,
  geniusUsers,
  frustrationUsers,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.workingGeniusSection}
    {...props}
  >
    <div>
      <h3 css={styles.workingGeniusType}>{WorkingGeniusMap[workingGeniusSection.type].text}</h3>
      <WorkingGeniusImageMap css={styles.typeImage} type={workingGeniusSection.type} />
    </div>
    <div css={styles.table}>
      <div css={[styles.columnHeaderText, styles.geniusText]}>
        Genius
      </div>
      <div css={[styles.columnHeaderText, styles.frustrationText]}>
        Frustration
      </div>
      <div css={[styles.usersContainer, styles.geniusUsersContainer]}>
        {geniusUsers.map((geniusUser) => (
          <UserDisplay
            key={geniusUser.orgUserId}
            firstName={geniusUser.firstName}
            lastName={geniusUser.lastName}
            profileImageUrl={geniusUser.profileImageUrl}
          />
        ))}
        {geniusUsers.length === 0 && (
          <div css={styles.emptyState}>0 participants</div>
        )}
      </div>
      <div css={[styles.usersContainer, styles.frustrationUsersContainer]}>
        {frustrationUsers.map((frustrationUser) => (
          <UserDisplay
            key={frustrationUser.orgUserId}
            firstName={frustrationUser.firstName}
            lastName={frustrationUser.lastName}
            profileImageUrl={frustrationUser.profileImageUrl}
          />
        ))}
        {frustrationUsers.length === 0 && (
          <div css={styles.emptyState}>0 participants</div>
        )}
      </div>
    </div>
  </div>
);

interface WorkingGeniusSectionProps {
  workingGeniusSection: WorkingGeniusWithType,
}

const WorkingGeniusSection = ({
  workingGeniusSection,
  ...props
}: WorkingGeniusSectionProps): JSX.Element => {
  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, Person>>;

  const geniusUsers = useMemo(() => (
    workingGeniusSection.geniusOrgUserIdList.map((orgUserId) => peopleData[orgUserId])
  ), [peopleData, workingGeniusSection.geniusOrgUserIdList]);

  const frustrationUsers = useMemo(() => (
    workingGeniusSection.frustrationOrgUserIdList.map((orgUserId) => peopleData[orgUserId])
  ), [peopleData, workingGeniusSection.frustrationOrgUserIdList]);

  const hookProps = {
    workingGeniusSection,
    geniusUsers,
    frustrationUsers,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default WorkingGeniusSection;
