import { Goals } from '@leadr-hr/types';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { buildQueryString } from '~Common/utils';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getHost, hosts } from '~Deprecated/services/config';
import { goalKeys } from '~Goals/const/queryKeys';

const getLinkableChildGoals = async ({
  organizationId,
  goalId,
  queryParameters,
}: Goals.Requests.GetLinkableChildGoalsRequest): Promise<HttpCallReturn<Goals.LinkedGoal[]>> => {
  const queryParams = buildQueryString(queryParameters ?? {});
  const url = {
    host: getHost(hosts.goals, '3'),
    uri: `/organizations/${organizationId}/goals/${goalId}/linkable/child${queryParams}`,
  };

  return getApi<Goals.LinkedGoal[]>(url);
};

type UseGetLinkableChildGoalsParams<T> = Omit<UseQueryOptions<HttpCallReturn<Goals.LinkedGoal[]>, Error, T>, 'queryKey' | 'queryFn'> &
  Omit<Goals.Requests.GetLinkableChildGoalsRequest, 'organizationId'>

export const useGetLinkableChildGoals = <T = HttpCallReturn<Goals.LinkedGoal[]>>({
  goalId,
  queryParameters,
  ...options
}: UseGetLinkableChildGoalsParams<T>): UseQueryResult<T, Error> => {
  const organizationId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: goalKeys.linkableChildGoals(goalId, queryParameters),
    queryFn: () => getLinkableChildGoals({ organizationId, goalId, queryParameters }),
    ...options,
  });
};
