import { css } from '@emotion/react';
import Tooltip from '~Common/components/Tooltip';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'react';

const styles = {
  recurringIconContainer: css({
    display: 'flex',
  }),
  recurringIcon: css({
    color: palette.brand.indigo,
    marginLeft: '.5rem',
    height: '.625rem',
    width: '.625rem',
  }),
};

const RecurringIcon = ({ ...props }: ComponentProps<'div'>): JSX.Element => (
  <Tooltip content="This section will recur in future meetings.">
    <div css={styles.recurringIconContainer} {...props}>
      <FontAwesomeIcon
        icon={faArrowsRepeat}
        css={styles.recurringIcon}
      />
    </div>
  </Tooltip>
);

export default RecurringIcon;
