import { pick } from 'lodash';
import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { SURVEY_QUESTION_TYPES } from '~Common/const/surveys';
import { buildQueryString } from '~Common/utils';
import { getOrganizationId } from '~Common/utils/localStorage';
import {
  deleteApi,
  getApi,
  HttpCallReturn,
  patchApi,
  postApi,
} from '~Deprecated/services/HttpService';

export interface SurveyQuestionFromApi {
  id: number,
  rank?: number,
  templateId: number,
  category: {
    key: number,
    value: string,
  },
  type: {
    key: number,
    value: string,
  },
  configuration: string,
  description: string,
  question: string,
}

export interface SurveyQuestion {
  id: number,
  rank?: number,
  templateId: number,
  category: number,
  type: {
    key: number,
    value: string,
  },
  configuration: Record<string, unknown>,
  description: string,
  question: string,
}

export interface SurveyTemplateFromApi {
  id: number,
  createdByUid: string,
  createdBy: {
    firstName: string,
    lastName: string,
    organization: string,
    profileImageUrl: string,
  }
  organizationUid: string,
  type: {
    key: number,
    value: string,
  },
  configuration: Record<string, unknown>, // JSON
  title: string,
  objective: string,
  questions: SurveyQuestionFromApi[],
}

export interface SurveyTemplate {
  id: number,
  createdByUid: string,
  createdBy: {
    firstName: string,
    lastName: string,
    organization: string,
    profileImageUrl: string,
  }
  organizationUid: string,
  type: number,
  configuration: Record<string, unknown>, // JSON
  title: string,
  objective: string,
  questions: SurveyQuestion[],
}

interface GetSurveyTemplatesQueryParams {
  organizationId: string,
}

function getSurveyTemplatesQueryKey({
  organizationId,
}: GetSurveyTemplatesQueryParams): string[] {
  return ['organization', organizationId, 'surveys', 'templates'];
}

export function getSurveyTemplatesQuery({
  organizationId,
}: GetSurveyTemplatesQueryParams): Promise<HttpCallReturn<SurveyTemplateFromApi[]>> {
  const queryParams = {
    curated: true,
    questions: true,
  };
  const queryString = buildQueryString(queryParams);
  const serverUrl = `/organizations/${organizationId}/surveys/templates${queryString}`;

  return getApi<SurveyTemplateFromApi[]>(serverUrl);
}

interface UseSurveyTemplateListReturn {
  templates: SurveyTemplate[],
  isLoading: boolean,
}

export function useSurveyTemplateList(): UseSurveyTemplateListReturn {
  const organizationId = getOrganizationId() ?? '';
  const result = useQuery({
    queryKey: getSurveyTemplatesQueryKey({ organizationId }),
    queryFn: () => getSurveyTemplatesQuery({ organizationId }),
  });

  const templates = result.data?.response ?? [];

  const parsedTemplates: SurveyTemplate[] = templates.map((template) => ({
    ...template,
    type: template.type.key,
    questions: template.questions.map((question) => ({
      ...question,
      configuration: typeof question.configuration === 'string' && question.type.key === SURVEY_QUESTION_TYPES.MULTIPLE_CHOICE.key
        ? JSON.parse(question.configuration) as Record<string, unknown>
        : question.configuration,
      category: question.category.key,
    })) as SurveyQuestion[],
  }));

  return {
    templates: parsedTemplates,
    isLoading: result.isLoading,
  };
}

export type CreateSurveyDTO = Pick<SurveyTemplate,
  | 'configuration'
  | 'objective'
  | 'questions'
  | 'title'
>;

function createSurveyTemplateMutation(organizationId: string, survey: CreateSurveyDTO): Promise<HttpCallReturn<void>> {
  const serverUrl = `/organizations/${organizationId}/surveys/templates/create`;

  const pickedSurvey = pick(survey, ['configuration', 'objective', 'questions', 'title']);

  const conformedQuestions = pickedSurvey.questions.map((question) => ({
    ...question,
    configuration: typeof question.configuration === 'string' ? question.configuration : JSON.stringify(question.configuration),
  }));

  return postApi<void>(serverUrl, {
    ...pickedSurvey,
    questions: conformedQuestions,
  });
}

export function useCreateSurveyTemplate(): UseMutationResult<HttpCallReturn<void>, unknown, CreateSurveyDTO, unknown> {
  const organizationId = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: (survey: CreateSurveyDTO) => createSurveyTemplateMutation(organizationId, survey),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getSurveyTemplatesQueryKey({ organizationId }) });
    },
  });
}

function updateSurveyTemplateMutation(organizationId: string, template: SurveyTemplate): Promise<HttpCallReturn<void>> {
  const serverUrl = `/organizations/${organizationId}/surveys/templates/${template.id}`;

  const conformedQuestions = template.questions.map((question) => ({
    ...question,
    configuration: typeof question.configuration === 'string'
      ? question.configuration
      : JSON.stringify(question.configuration),
  }));

  return patchApi<void>(serverUrl, {
    ...template,
    questions: conformedQuestions,
  });
}

export function useUpdateSurveyTemplate(): UseMutationResult<HttpCallReturn<void>, unknown, SurveyTemplate, unknown> {
  const organizationId = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: (updatedTemplate: SurveyTemplate) => updateSurveyTemplateMutation(organizationId, updatedTemplate),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: getSurveyTemplatesQueryKey({ organizationId }) });
    },
  });
}

function deleteSurveyTemplateMutation(organizationId: string, templateId: number): Promise<HttpCallReturn<void>> {
  const serverUrl = `/organizations/${organizationId}/surveys/templates/${templateId}`;

  return deleteApi<void>(serverUrl, {
    organizationId,
    templateId,
  });
}

export function useDeleteSurveyTemplate({
  onSuccess,
}: {
  onSuccess: () => void,
}): UseMutationResult<HttpCallReturn<void>, unknown, number, unknown> {
  const organizationId = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: (templateId: number) => deleteSurveyTemplateMutation(organizationId, templateId),
    async onSuccess() {
      onSuccess();
      await queryClient.invalidateQueries({ queryKey: getSurveyTemplatesQueryKey({ organizationId }) });
    },
  });
}
