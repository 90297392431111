import PropTypes from 'prop-types';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { useUpdateUserProfile, useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import FormDrawer from '~Common/components/Drawers/Forms/Form';
import { useTabs } from '~Common/hooks/useTabs';
import Loader from '~Common/components/Loader';
import { useFileUpload } from '~Deprecated/hooks/fileUpload/useFileUpload';
import { toast } from '~Common/components/Toasts';
import Profile from './Profile';

export const editProfileTemplate = {
  name: 'EDIT_PROFILE',
  type: templateType.PRIMARY,
  width: DRAWER_WIDTHS.PRIMARY,
};

const tabQueryConfig = { module: 'editProfile', workflow: 'drawer', defaultValue: 0 };

const EditProfile = ({
  popDrawer, userId, ...props
}) => {
  const userIdToUse = userId || getUserId();
  const { user, isLoading: isUserLoading } = useUserProfile(userIdToUse);
  const { user: userThatIsEditing, isLoading: userThatIsEditingLoading } = useUserProfile(getUserId());

  const { updateUserProfile } = useUpdateUserProfile(userIdToUse, editProfileTemplate);

  const isLoading = userThatIsEditingLoading || isUserLoading;

  const { activeTab, setActiveTab } = useTabs(tabQueryConfig);
  const getSignedUrlQueryKey = [getOrganizationId(), 'profileImageUpload', 'png'];
  const fileUploadQueryKey = [getOrganizationId(), 'profile_picture', 'upload'];
  const fileUploadProps = useFileUpload({});
  const isFetchingSignedUrl = useIsFetching({
    queryKey: getSignedUrlQueryKey,
  });
  const isUploadingFileToS3 = useIsMutating({
    mutationKey: fileUploadQueryKey,
  });
  const isUploadingFile = isFetchingSignedUrl || isUploadingFileToS3;

  const onSubmit = (e) => {
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    const personalityIds = [];

    if (data.enneagram) {
      personalityIds.push(data.enneagram);
    }
    if (data.myersBriggs) {
      personalityIds.push(data.myersBriggs);
    }
    if (data.disc) {
      personalityIds.push(data.disc);
    }

    const specialDays = [];

    if (data.birthday) {
      const birthday = new Date(data.birthday);
      specialDays.push({
        type: 'BIRTHDAY',
        month: birthday.getMonth() + 1,
        date: birthday.getDate(),
        year: birthday.getFullYear(),
      });
    }

    if (data.joiningDate) {
      const joiningDate = new Date(data.joiningDate);
      specialDays.push({
        type: 'JOINING_DATE',
        month: joiningDate.getMonth() + 1,
        date: joiningDate.getDate(),
        year: joiningDate.getFullYear(),
      });
    }

    const formattedData = {
      firstName: data.firstName,
      lastName: data.lastName,
      personalInformation: {
        phoneNumber: data.phoneNumber,
        aboutMe: data.aboutMe,
        twitter: data.twitter,
        instagram: data.instagram,
        linkedIn: data.linkedIn,
        food: data.food,
        snacks: data.snacks,
        drink: data.drink,
        personalityIds,
        strengths: JSON.parse(data.strengths || '[]'),
        workingGeniuses: JSON.parse(data.workingGeniuses || '[]'),
        workingFrustrations: JSON.parse(data.workingFrustrations || '[]'),
        workingCompetencies: JSON.parse(data.workingCompetencies || '[]'),
        imageUrl: data.profileImageUrl,
      },
      professionalInformation: {
        jobTitle: data.jobTitle,
        department: data.department,
      },
      specialDays,
      orgUserProfile: {
        customPersonality1Value: data.customPersonality1Value,
        customPersonality1UrlValue: data.customPersonality1UrlValue,
        customPersonality2Value: data.customPersonality2Value,
        customPersonality2UrlValue: data.customPersonality2UrlValue,
        customFavorite1Value: data.customFavorite1Value,
        customFavorite2Value: data.customFavorite2Value,
      },
    };

    const { workingCompetencies, workingGeniuses, workingFrustrations } = formattedData.personalInformation;

    // Checking if there are common values between arrays
    const hasCommonValues = (array1, array2) => array1.some((value) => array2.includes(value));

    // Checking if array length is correct
    const hasCorrectLength = (array, length) => array.length === length || array.length === 0;

    const commonValuesError = 'Please make sure your Working Geniuses, Working Frustrations, Working Competencies do not have any common values.';
    const lengthError = 'Please make sure your {name} has at least two values.';

    if (hasCommonValues(workingFrustrations, [...workingGeniuses, ...workingCompetencies])) {
      toast.error(commonValuesError);
    } else {
      if (!hasCorrectLength(workingFrustrations, 2)) {
        toast.error(lengthError.replace('{name}', 'Working Frustrations'));
      }
      if (!hasCorrectLength(workingGeniuses, 2)) {
        toast.error(lengthError.replace('{name}', 'Working Geniuses'));
      }
      if (!hasCorrectLength(workingCompetencies, 2)) {
        toast.error(lengthError.replace('{name}', 'Working Competencies'));
      }

      if (
        !hasCorrectLength(workingFrustrations, 2)
        || !hasCorrectLength(workingGeniuses, 2)
        || !hasCorrectLength(workingCompetencies, 2)
      ) {
        return; // No need to continue if any length is incorrect
      }

      updateUserProfile(formattedData);
    }
  };

  const hookProps = {
    onSubmit,
    drawerName: editProfileTemplate.name,
    drawerTitle: 'Edit Profile',
    tabs: [
      ['Profile', <Profile
        user={user}
        userThatIsEditing={userThatIsEditing}
        fileUploadProps={fileUploadProps}
      />],
    ],
    onTabChange: (index) => {
      setActiveTab(index);
    },
    tabQueryConfig,
    activeTab,
    user,
    disableSaveButton: isUploadingFile,
    userThatIsEditing,
  };

  return (
    <>
      {isLoading && (<Loader />)}
      {!isLoading && (
        <FormDrawer
          {...hookProps}
          {...props}
        />
      )}
    </>
  );
};

EditProfile.propTypes = {
  popDrawer: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

EditProfile.defaultProps = {
  userId: null,
};

registerDrawer({ templateName: editProfileTemplate.name, component: EditProfile });

export default EditProfile;
