import {
  call, race, take, delay, select,
} from 'redux-saga/effects';
import { getFeatureFlags } from '~Deprecated/selectors/featureFlags/getFeatureFlags';

function* methodCall(action) {
  try {
    const params = { ...action };
    let isFirstCall = true;
    while (true) {
      const { isIdle } = yield select((state) => state.idle);
      const { frontendPollingPollingFrequency } = yield select(getFeatureFlags);
      if (!isIdle) {
        yield call(params.method, params.data || null, isFirstCall);
        isFirstCall = false;
      }
      yield delay(frontendPollingPollingFrequency || 60000);
    }
  } catch (e) { }
}

export function* meetingDetailsPollingTask(action) {
  yield call(methodCall, action);
}
