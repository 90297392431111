import { css } from '@emotion/react';
import { useMemo, useCallback } from 'react';
import {
  DataGrid,
  DataGridProps,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { palette } from '~Common/styles/colors';
import { PaginationProps } from '~Common/V3/components/Pagination';
import moment from 'moment';
import { Goals } from '@leadr-hr/types';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import Tooltip from '~Common/components/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { useHistory } from 'react-router';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { PROGRESS_BAR_STYLES } from '~Goals/const/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { getGoalDateString } from '~Goals/const/functions';
import {
  GoalAssignee, GoalProgress, GoalRow,
} from '~Goals/const/types';
import { DEFAULT_OWNER } from '~Goals/const/defaults';
import NewGoalStatus from '~Goals/components/Shared/NewGoalStatus';
import { GoalDatesParams, GoalTitleParams } from '../types';

const styles = {
  ...PROGRESS_BAR_STYLES,
  goalsTable: css({
    color: palette.neutrals.gray800,
    border: 0,

    '.linearProgress': {
      backgroundColor: palette.brand.blue,
      '.MuiLinearProgress-bar1': {
        backgroundColor: palette.brand.indigo,
      },
      '.MuiLinearProgress-bar2': {
        backgroundColor: palette.brand.indigo,
      },
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '.MuiDataGrid-columnHeaders': {
      backgroundColor: palette.neutrals.gray100,
      border: 0,
      borderRadius: 0,
    },
    '.MuiDataGrid-cell, .MuiDataGrid-row': {
      minHeight: '4.375rem  !important',
      maxHeight: '5.625rem !important',
    },
  }),
  completedIconButton: css({
    backgroundColor: 'transparent',
    padding: 0,
    border: 0,

    // Flex needed to align the icon vertically
    display: 'flex',
  }),
  completedIcon: (isComplete: boolean) => css({
    height: '1.25rem',
    width: '1.25rem',
    color: isComplete ? palette.brand.indigo : palette.neutrals.gray400,
  }),
  assigneeField: css({
    display: 'flex',
    alignItems: 'center',
  }),
  avatar: css({
    marginRight: '.5rem',
  }),
  associationIcon: css({
    marginRight: '.5rem',
  }),
  dueDate: (hasDueDate?: boolean, isPastDue?: boolean, isSoon?: boolean) => {
    let color = 'inherit';

    if (!hasDueDate) {
      color = palette.neutrals.gray300;
    } else if (isPastDue) {
      color = palette.brand.red;
    } else if (isSoon) {
      color = palette.brand.green;
    }

    return css({
      alignItems: 'center',
      color,
      display: 'flex',
      flexDirection: 'row',
      gap: '.5rem',
    });
  },
  recurrenceIcon: css({
    color: palette.neutrals.gray500,
  }),
  assigneeName: (isMobile: boolean) => css({
    fontSize: isMobile ? '.75rem' : '.875rem',
  }),
  createActionItemLinkButton: css({
    fontWeight: 800,
    textDecoration: 'underline',
  }),
  emptyStateContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    h6: {
      textTransform: 'unset',
    },
  }),
  emptyStateImage: css({
    height: '20rem',
  }),
  progressWrapper: (isMobile?: boolean) => css({
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
  }, isMobile && {
    width: '100%',
    flexDirection: 'row',
    gap: '.5rem',
    alignItems: 'center',
  }),
  progress: (short?: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '.3125rem',
  }, short && {
    maxWidth: '4.0625rem',
  }),
  progressBarWrap: css({
    flexBasis: '80%',
  }),
  percent: css({
    flexBasis: '15%',
  }),
  goalTitleWrap: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  goalTitle: css({
    fontSize: '.875rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
    width: '100%',
    gap: '.5rem',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
  }),
  goalteam: css({
    fontSize: '.75rem',
    fontWeight: 400,
    color: palette.neutrals.gray800,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  lockIcon: css({
    color: palette.neutrals.gray700,
    fontSize: '.625rem',
    verticalAlign: 'initial',
  }),
  goalDates: css({
    whiteSpace: 'normal',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.25rem',
  }),
  progressBarAdjustment: css({
    height: '.25rem',
  }),
  isPrivate: css({
    display: 'inline-block',
    marginLeft: '.5rem',
  }),
};

export const goalSortColumnField: Record<string, Goals.GetGoalsSortBy> = {
  title: Goals.GetGoalsSortBy.Title,
  priority: Goals.GetGoalsSortBy.Priority,
  dueDate: Goals.GetGoalsSortBy.DueDate,
  createdDate: Goals.GetGoalsSortBy.CreatedDate,
};

export interface ViewProps extends Omit<DataGridProps<GoalRow>, 'page' | 'onPageChange' | 'onError'>,
Omit<PaginationProps, 'page' | 'onPageChange' | 'numberOfPages' | 'onPreviousClick' | 'onNextClick' | 'onResize'> {
  sortByField: Goals.GetGoalsSortBy,
  sortByOrder: Goals.GetGoalsSortOrder,
  isFetching: boolean,
}

const View = ({
  rows,
  columns,
  pageSize,
  onSortModelChange,
  onRowClick,
  sortByField,
  sortByOrder,
  isFetching,
}: ViewProps): JSX.Element => (
  <>
    {rows.length > 0 && (
      <>
        <DataGrid
          css={styles.goalsTable}
          autoHeight
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          sortingMode="server"
          headerHeight={38}
          rowHeight={90}
          getRowHeight={() => 'auto'}
          hideFooter
          pageSize={pageSize}
          rows={rows}
          sortingOrder={['asc', 'desc']}
          onSortModelChange={onSortModelChange}
          onRowClick={onRowClick}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={isFetching}
          sortModel={[
            {
              field: goalSortColumnField[sortByField],
              sort: sortByOrder,
            },
          ]}
        />
      </>
    )}
  </>
);

interface GoalsTableProps {
  data: Goals.Responses.GetGoalsResponse['data'],
  canViewTableGroup: boolean,
  onSortModelChange: (sortModel: GridSortModel) => void,
  sortByField: Goals.GetGoalsSortBy,
  sortByOrder: Goals.GetGoalsSortOrder,
  isFetching: boolean,
}

const GoalsTable = ({
  data,
  canViewTableGroup,
  onSortModelChange,
  sortByField,
  sortByOrder,
  isFetching,
}: GoalsTableProps): JSX.Element => {
  const isMobile = useIsMobileQuery();

  const tableColumnHeaders: GridColumns<GoalRow> = [
    {
      field: 'title',
      headerName: 'Name',
      flex: isMobile ? 2 : 3,
      renderCell: (params: GridRenderCellParams<GoalTitleParams, GoalRow>) => {
        const isPrivate = params.value?.isPrivate;
        const hasTeam = params.value?.team;
        const title = params.value?.title;
        if (!params.value) {
          return null;
        }
        return (
          <>
            <div css={styles.goalTitleWrap}>
              <span css={styles.goalTitle}>
                {title}
                {isPrivate && (
                  <div css={styles.isPrivate}>
                    <Tooltip content="This is a private goal. It is only visible to you.">
                      <div>
                        <FontAwesomeIcon css={styles.lockIcon} icon={faLock} />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </span>
              {hasTeam && (
                <span css={styles.goalteam}>{params.value?.team}</span>
              )}
              {isMobile && (
                <div css={styles.progressWrapper(isMobile)}>
                  <div css={styles.progress(true)}>
                    <div css={styles.progressBarWrap}>
                      <Tooltip content={`${params?.value?.progress.percentage ?? 0}%`}>
                        <LinearProgress
                          css={[styles.progressBar(params?.value.progress.status ?? Goals.GoalStatus.OnTrack), styles.progressBarAdjustment]}
                          value={params?.value?.progress.percentage ?? 0}
                          variant="determinate"
                        />
                      </Tooltip>
                    </div>
                    <div css={styles.percent}>
                      {`${params?.value?.progress.percentage || 0}`}
                      %
                    </div>
                  </div>
                  <div>
                    <NewGoalStatus
                      status={params?.value?.progress.status}
                      isMobile={isMobile}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      field: 'progress',
      sortable: false,
      headerName: 'Status',
      flex: isMobile ? 2 : 1,
      minWidth: 175,
      hide: isMobile,
      renderCell: (params: GridRenderCellParams<GoalProgress, GoalRow>) => {
        if (!params.value) {
          return null;
        }

        return (
          <>
            <div css={styles.progressWrapper(isMobile)}>
              <div css={styles.progress(true)}>
                <div css={styles.progressBarWrap}>
                  <Tooltip content={`${params?.value?.percentage ?? 0}%`}>
                    <LinearProgress
                      css={[styles.progressBar(params?.value.status ?? Goals.GoalStatus.OnTrack), styles.progressBarAdjustment]}
                      value={params?.value?.percentage ?? 0}
                      variant="determinate"
                    />
                  </Tooltip>
                </div>
                <div css={styles.percent}>
                  {`${params?.value?.percentage || 0}`}
                  %
                </div>
              </div>
              <div>
                <NewGoalStatus
                  status={params?.value?.status}
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'dueDate',
      headerName: 'Dates',
      flex: 1,
      maxWidth: isMobile ? 225 : 175,
      renderCell: (params: GridRenderCellParams<GoalDatesParams, GoalRow>) => {
        if (!params.value) {
          return null;
        }
        return (
          <div css={styles.goalDates}>
            <span>{`${params.value.startDate} - `}</span>
            <span>{params.value.endDate}</span>
          </div>
        );
      },
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      maxWidth: 125,
      align: 'center',
      headerAlign: 'center',
      hide: isMobile || canViewTableGroup,
    },
    {
      field: 'role',
      sortable: false,
      headerName: 'My Role',
      flex: 1,
      maxWidth: 150,
      align: 'center',
      headerAlign: 'center',
      hide: isMobile,
    },
    {
      field: 'objectiveType',
      sortable: false,
      headerName: 'Objective Type',
      flex: 2,
      maxWidth: 225,
      hide: !canViewTableGroup || isMobile,
    },
    {
      field: 'owner',
      sortable: false,
      headerName: 'Owner',
      align: 'center',
      flex: 1,
      maxWidth: 75,
      hide: isMobile,
      renderCell: (params: GridRenderCellParams<GoalAssignee, GoalRow>) => {
        if (params.value?.name === '') {
          return <></>;
        }

        const {
          imgUrl,
          name,
        } = params.value || {};

        return (
          <Tooltip content={name}>
            <div css={styles.assigneeField}>
              <SquareAvatar
                imgUrl={imgUrl}
                name={name}
                width={30}
                height={30}
                includeInitials
                css={styles.avatar}
              />
            </div>
          </Tooltip>
        );
      },
    },
  ];

  // @ts-expect-error This will be deleted after cascadingGoals feature flag is permanent
  const rows: GoalRow[] = useMemo(() => data?.map((goal) => {
    const { startDate, endDate } = getGoalDateString(moment(goal.startTimeInMillis), moment(goal.endTimeInMillis));

    const loggedInUserIsParticipant = goal.participants.find((item) => item.orgUserId === getOrganizationUserId());
    const myRole = loggedInUserIsParticipant?.role ?? 'Viewer';
    const owner = goal?.participants?.find((participant) => participant.role === Goals.GoalParticipantRole.Owner && participant.firstName !== undefined);
    const ownerToUse = owner ?? DEFAULT_OWNER;
    const name = ownerToUse?.firstName ? `${ownerToUse?.firstName} ${ownerToUse?.lastName}` : '';
    const goalStatusUpdates = goal?.statusUpdates ?? [];
    const finalGoalStatus = goalStatusUpdates[goalStatusUpdates.length - 1];
    const priority = Goals.GoalPriority[goal?.priority ?? Goals.GoalPriority.Medium];

    return {
      id: goal.goalId,
      title: {
        title: goal.title,
        team: goal.context.contextName ?? '',
        isPrivate: goal.isPrivate,
        progress: {
          percentage: finalGoalStatus?.completionPercentage ?? 0,
          statusCommentary: finalGoalStatus?.statusCommentary ?? '',
          status: finalGoalStatus?.status ?? '',
        },
      },
      progress: {
        percentage: finalGoalStatus?.completionPercentage ?? 0,
        statusCommentary: finalGoalStatus?.statusCommentary ?? '',
        status: finalGoalStatus?.status ?? '',
      },
      priority,
      dueDate: {
        startDate,
        endDate,
      },
      role: myRole,
      objectiveType: goal.category,
      owner: {
        imgUrl: ownerToUse?.profileImageUrl ?? '',
        name,
      },
    };
  }) ?? [], [data]);

  const history = useHistory();
  const onRowClick = useCallback((params: GridRowParams<GoalRow>) => {
    history.push(GoalsRoutes.ViewById.replace(':goalId', params.id as string));
  }, [history]);

  const hookProps = {
    rows,
    columns: tableColumnHeaders,
    onRowClick,
    onSortModelChange,
    sortByField,
    sortByOrder,
    isFetching,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default GoalsTable;
