import { SelectChangeEvent } from '@mui/material';
import { DropdownItem } from '~Common/V3/components/Dropdown';
import { InsightsFor } from '~Home/const/interfaces';
import FilterDropdownView from './DropdownView';

interface ViewProps {
  relativeOptions: DropdownItem[],
  value: string,
  handleFilterChange: (event: SelectChangeEvent) => void,
}

const View = ({
  relativeOptions,
  value,
  handleFilterChange,
  ...props
}: ViewProps): JSX.Element => (
  <FilterDropdownView
    options={relativeOptions}
    value={value}
    handleFilterChange={handleFilterChange}
    labelForNoValue="Everyone"
    data-test-id="peopleRelativeDropdown"
    {...props}
  />
);

type RelativeDropdownProps = Omit<ViewProps, 'relativeOptions' | 'isInvalidDropdownValue'>;

// WARNING: The naming for this component sucks
const RelativeDropdown = ({
  value,
  ...props
}: RelativeDropdownProps): JSX.Element => {
  const relativeOptions = [
    {
      text: 'My Direct Reports',
      value: InsightsFor.DirectReports,
      'data-test-id': 'myDirectReportsOption',
    },
    {
      text: 'My Downlines',
      value: InsightsFor.Downline,
      'data-test-id': 'myDownlinesOption',
    },
    {
      text: 'Recent',
      value: InsightsFor.Recent,
      'data-test-id': 'recentsOption',
    },
    {
      text: 'Managers',
      value: InsightsFor.Managers,
      'data-test-id': 'managersOption',
    },
  ];

  const hookProps = {
    relativeOptions,
    value,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default RelativeDropdown;
