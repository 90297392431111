/* eslint-disable react/button-has-type */

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const DefaultButton = (props) => (
  <Button
    buttonColor={BUTTON_COLOR.DEFAULT}
    {...props}
  />
);

DefaultButton.propTypes = {};

DefaultButton.defaultProps = {};

export default DefaultButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
