import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  text: css({
    color: palette.neutrals.gray700,
    fontStyle: 'italic',
    span: {
      fontStyle: 'italic',
    },
  }),
  bold: css({
    fontWeight: 600,
  }),
};

interface SeveralPeopleCreatingItemProps {
  inline?: boolean,
}

const SeveralPeopleCreatingDifferentItems = ({
  inline = false,
  ...props
}: SeveralPeopleCreatingItemProps): JSX.Element => (
  <span css={styles.text} {...props}>
    {inline && (
      <>
        <span css={styles.bold}>Several people</span>
        <span> are contributing</span>
      </>
    )}
    {!inline && (
      <span>Several people are contributing</span>
    )}
  </span>
);

export default SeveralPeopleCreatingDifferentItems;
