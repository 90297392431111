import { QueryStatus, useQuery } from '@tanstack/react-query';
import { hosts } from '~Deprecated/services/config';
import { getApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { calendarIntQueryKeys } from '~Meetings/hooks/CalendarIntegration/queryKeys';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

const getCalendarAuthStatus = async (): Promise<AUTH_STATUS> => {
  const URL = {
    host: hosts.meeting,
    uri: `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/isUserAuthenticated`,
  };

  const result = await getApi<AUTH_STATUS>(URL, {});

  return result.response;
};

interface UseGetCalendarAuthStatusParams {
  isEnabled?: boolean,
}

interface UseGetCalendarAuthStatusReturn {
  data?: AUTH_STATUS,
  status: QueryStatus,
  isLoading: boolean,
}

export const useGetCalendarAuthStatus = ({ isEnabled = true }: UseGetCalendarAuthStatusParams = {}): UseGetCalendarAuthStatusReturn => {
  const nylasEnabled = useFeatureFlag('nylas');

  const { data, status, isLoading } = useQuery({
    queryKey: calendarIntQueryKeys.isUserAuthenticated(),
    queryFn: getCalendarAuthStatus,
    enabled: !!nylasEnabled && isEnabled,
  });

  return { data, status, isLoading };
};
