import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useState } from 'react';
import { stripUrl } from '~Common/const/socialLinks';
import TextField from './TextField';

const styles = {
  textField: css`
    text-transform: none;
  `,
};

const View = ({
  staticUrl,
  dynamicUrl,
  onChange,
  onPaste,
  name,
  ...props
}) => (
  <div>
    <TextField
      css={styles.textField}
      value={dynamicUrl}
      label={staticUrl}
      onChange={onChange}
      placeholder="username"
      onPaste={onPaste}
      {...props}
    />
    <input type="hidden" name={name} readOnly value={dynamicUrl ? staticUrl + dynamicUrl : ''} />
  </div>
);

View.propTypes = {
  staticUrl: PropTypes.string.isRequired,
  dynamicUrl: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onPaste: PropTypes.func.isRequired,
  name: PropTypes.string,
};

View.defaultProps = {
  name: '',
};

const MaskedUrlField = ({
  staticUrl,
  initialValue,
  name,
  ...props
}) => {
  const [dynamicUrl, setDynamicUrl] = useState(stripUrl(initialValue, name));

  const hooksProps = {
    name,
    dynamicUrl,
    onChange: (e) => {
      let newValue = e.target.value?.trim();
      if (stripUrl(newValue, name)?.trim()?.length === 0) {
        newValue = '';
      }
      setDynamicUrl(newValue);
    },
    onPaste: (e) => {
      let text = e.clipboardData.getData('Text');
      e.preventDefault();
      if (stripUrl(text, name)?.trim()?.length === 0) {
        text = '';
      }
      setDynamicUrl(text);
    },
    staticUrl,
  };

  return (
    <View
      {...hooksProps}
      {...props}
    />
  );
};
MaskedUrlField.propTypes = {
  staticUrl: PropTypes.string,
  initialValue: PropTypes.string,
  name: PropTypes.string.isRequired,
};

MaskedUrlField.defaultProps = {
  staticUrl: '',
  initialValue: '',
};

export { View };
export default MaskedUrlField;
