import { css } from '@emotion/react';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { Goals } from '@leadr-hr/types';
import { SKELETON_STYLES } from '~Goals/const/styles';
import { useEffect } from 'react';
import { useIsMobileQuery, useIsTabletQuery } from '~Common/hooks/useMediaListener';
import { useGetLinkedGoalsById } from '~Goals/hooks/linkGoals/useGetLinkedGoalsById';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { InteriorTopBar } from '../components/GoalsTopBar/InteriorTopBar';
import { GoalsRoutes } from './GoalsRouter';
import { useGetGoalById } from '../hooks/useGetGoalById';
import { useGoalStatusModalStore } from '../stores/useGoalsStatusModalStore';
import ViewGoalDetails from '../components/Shared/ViewGoalDetails';

const styles = {
  ...SKELETON_STYLES,
  container: css({
    width: '100%',
    margin: '1.875rem 1.875rem 0 1.875rem',
  }),
  textBackButton: css({
    fontSize: '1rem',
    fontWeight: 400,
    color: palette.neutrals.gray800,
  }),
  icon: css({
    marginRight: '0.5rem',
  }),
  buttonSpacing: css({
    marginRight: '.625rem',
  }),
  actionItems: css({
    height: '19.0625rem',
  }),
  description: css({
    height: '8.4375rem',
  }),
  status: css({
    height: '4.0625rem',
  }),
  rightSide: (isMobile: boolean) => css({}, isMobile && {
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  goalEditButton: (isMobile: boolean) => css({}, isMobile && {
    width: '100%',
  }),
};

interface ViewProps {
  goal?: Goals.Goal,
  showSkeleton: boolean,
  isMobile: boolean,
  isTablet: boolean,
  onEditClick: () => void,
}

const View = ({
  goal,
  showSkeleton,
  isMobile,
  isTablet,
  onEditClick,
}: ViewProps): JSX.Element => (
  <>
    <div css={styles.container}>
      <InteriorTopBar
        renderLeftSide={() => (
          <LeadrButton
            component={Link}
            to={GoalsRoutes?.Dashboard}
            variant="text"
            css={styles.textBackButton}
            textButtonColor={palette.neutrals.gray700}
            data-test-id="goalsBackToGoalsList"
          >
            <FontAwesomeIcon
              css={styles.icon}
              icon={faArrowLeft}
            />
            Goals
          </LeadrButton>
        )}
        renderRightSide={() => (
          <div css={styles.rightSide(isMobile)}>
            {goal?.permissions.includes(Goals.GoalPermission.CanEditGoal) && (
              <LeadrButton
                type="submit"
                data-test-id="goalsEditGoal"
                onClick={onEditClick}
                disabled={showSkeleton}
                css={styles.goalEditButton(isMobile)}
              >
                Edit
              </LeadrButton>
            )}
          </div>
        )}
      />
      <ViewGoalDetails
        goal={goal}
        showSkeleton={showSkeleton}
        isMobile={isMobile}
        isTablet={isTablet}
      />
    </div>
  </>
);

interface GoalDetailsParams {
  goalId: string,
  statusId?: string,
}

interface ViewGoalProps {
  isOnStatusRoute?: boolean,
}

const ViewGoal = ({
  isOnStatusRoute = false,
}: ViewGoalProps): JSX.Element => {
  const { goalId, statusId } = useParams<GoalDetailsParams>();
  const { data: goal, isLoading } = useGetGoalById({ id: goalId });

  const {
    orgLevelEnableCascadingGoals,
    featureFlagEnableCascadingGoals,
  } = useEnableCascadingGoals();

  // Prefetch linked goals, but only if the feature is enabled
  useGetLinkedGoalsById({ goalId, enabled: featureFlagEnableCascadingGoals && orgLevelEnableCascadingGoals });

  const isMobile = useIsMobileQuery();
  const isTablet = useIsTabletQuery();
  if (goal) {
    goal.statusUpdates.sort((a, b) => b.createdInMillis - a.createdInMillis);
  }

  const [showSkeleton] = useSkeletonLoaders(isLoading);
  const history = useHistory();
  const onEditClick = (): void => {
    history.push(GoalsRoutes.EditById.replace(':goalId', goalId));
  };

  const {
    openStatusHistoryModal,
    closeStatusHistoryModal,
  } = useGoalStatusModalStore((state) => ({
    openStatusHistoryModal: state.openStatusHistoryModal,
    closeStatusHistoryModal: state.closeStatusHistoryModal,
  }));

  useEffect(() => {
    if (isOnStatusRoute && goal) {
      openStatusHistoryModal(goal, statusId);
    } else {
      closeStatusHistoryModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps -- Zustand setters
  }, [isOnStatusRoute, statusId, goal]);

  useEffect(() => {
    if (!goal?.permissions.includes(Goals.GoalPermission.CanViewGoal) && !isLoading) {
      history.push(GoalsRoutes?.PermissionsDenied);
    }
  }, [goal, isLoading, history]);

  const hookProps = {
    goal,
    onEditClick,
    showSkeleton,
    isMobile,
    isTablet,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };

export default ViewGoal;
