import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, getApi } from '~Deprecated/services/HttpService';
import { getHost } from '~Deprecated/services/config';

export interface GetPresignedUrlParams {
  organizationId: string,
  huddleId: string,
  fileName: string,
  fileSize: number,
}

interface GetPresignedUrlResponse {
  attachmentId: number,
  presignedUrl: string,
}

const getPresignedUrl = ({
  organizationId,
  huddleId,
  fileName,
  fileSize,
}: GetPresignedUrlParams): Promise<HttpCallReturn<GetPresignedUrlResponse>> => {
  const queryParams = new URLSearchParams({
    fileName,
    fileSize: fileSize.toString(),
  });

  const url = {
    host: getHost('', '2'),
    uri: `/organizations/${organizationId}/huddles/${huddleId}/presignedUrl/upload?${queryParams.toString()}`,
  };

  return getApi<GetPresignedUrlResponse>(url);
};

interface UploadFileParams {
  file: File,
  url: string,
  onError?: (uploadId: string) => void,
  onProgress?: (e: ProgressEvent, uploadId: string) => void,
  onLoadEnd?: (uploadId: string, attachmentId: number, file: File) => void,
  uploadId: string,
  attachmentId: number,
  abortSignal?: AbortSignal,
}

const uploadFile = async ({
  file,
  url,
  onProgress,
  onLoadEnd,
  onError,
  uploadId,
  attachmentId,
  abortSignal,
}: UploadFileParams): Promise<void> => {
  const xhr = new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    const handleAbort = (): void => {
      xhr.abort();
      resolve();
    };

    abortSignal?.addEventListener('abort', handleAbort);

    if (onProgress) {
      xhr.upload.addEventListener('progress', (e) => onProgress(e, uploadId));
    }

    xhr.addEventListener('error', () => {
      onError?.(uploadId);
    });

    xhr.addEventListener('loadend', () => {
      abortSignal?.removeEventListener('abort', handleAbort);

      if (!abortSignal?.aborted) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          onLoadEnd?.(uploadId, attachmentId, file);
          resolve();
        } else {
          onError?.(uploadId);
          reject();
        }
      }
    });
    xhr.open('PUT', url, true);

    // Doing this to encode the filename in the header to avoid issues with special characters
    const encodedName = encodeURI(file.name);
    xhr.setRequestHeader('Content-Disposition', `attachment; filename="${encodedName}"`);
    xhr.send(file);
  });
};

export interface DoUpload {
  organizationId: string,
  huddleId: string,
  file: File,
  onProgress?: (e: ProgressEvent, uploadId: string) => void,
  onLoadEnd?: (uploadId: string, attachmentId: number, file: File) => void,
  uploadId: string,
  onError?: (uploadId: string) => void,
  abortSignal?: AbortSignal,
}

const doUpload = async ({
  organizationId,
  huddleId,
  file,
  onProgress,
  onLoadEnd,
  uploadId,
  abortSignal,
  onError,
}: DoUpload): Promise<void> => {
  const presignedUrlRequest = await getPresignedUrl({
    organizationId,
    huddleId,
    fileName: file.name,
    fileSize: file.size,
  });

  await uploadFile({
    file,
    url: presignedUrlRequest.response.presignedUrl,
    onProgress,
    onLoadEnd,
    onError,
    uploadId,
    attachmentId: presignedUrlRequest.response.attachmentId,
    abortSignal,
  });
};

export interface UseUploadFileParams {
  huddleId: string,
  onProgress?: (e: ProgressEvent, uploadId: string) => void,
  onLoadEnd?: (uploadId: string, attachmentId: number, file: File) => void,
  onErrorCallback?: (uploadId: string) => void,
}

export const useUploadFile = ({
  huddleId,
  onProgress,
  onLoadEnd,
  onErrorCallback,
}: UseUploadFileParams): UseMutationResult<void, unknown, Omit<DoUpload, 'organizationId' | 'huddleId' | 'onProgress' | 'onLoadEnd'>> => {
  const organizationId = getOrganizationId();

  return useMutation({
    mutationFn: (params: Omit<DoUpload, 'organizationId' | 'huddleId' | 'onProgress' | 'onLoadEnd'>) => doUpload({
      ...params,
      huddleId,
      organizationId: organizationId!,
      onProgress,
      onLoadEnd,
    }),
    onError: (_, { uploadId }) => {
      toast.error('One or more files failed to upload. Please try again.');
      onErrorCallback?.(uploadId);
    },
  });
};
