import { createSelector } from 'reselect';
import { getOrganizationById } from './getOrganizationById';

export const makeGetOrganizationContent = () => createSelector(
  (state, { id }) => getOrganizationById(state, { id }),
  ({ city, state: stateName, count }) => ([
    [`${city ? `${city}, ` : ''}${stateName || ''}`, 'Location'],
    [`${count > 1 ? `${count} People` : `${count} Person`}`, 'Count'],
  ]),
);
