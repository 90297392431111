import { css, SerializedStyles } from '@emotion/react';
import { faTimes, faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerInput from '~Common/V3/components/DrawerInput';
import Details from '~Learning/components/CreateLearningPlaylistDrawer/EditablePlaylistLearningCard/Details';
import {
  OnUseTemplateParams,
  FrontendPlaylistLearning,
} from '~Learning/const/interfaces';
import DrawerCloseButtonWithConfirmation from '~Learning/components/Shared/ConfirmationButtons/DrawerCloseButtonWithConfirmation';
import RequiredIcon from '~Common/V3/components/RequiredIcon';
import Froala from '~Common/V3/components/Froala';
import PlaylistContentBanner from '~Learning/components/Shared/PlaylistContentBanner';
import DragAndDrop from '~Deprecated/ui/components/DragAndDrop/DragAndDrop';
import EditablePlaylistLearningCard from '~Learning/components/CreateLearningPlaylistDrawer/EditablePlaylistLearningCard';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import { toast } from '~Common/components/Toasts';
import { useDragAndDrop } from '~Deprecated/hooks/useDragAndDrop';
import { useRankedListHelpers } from '~Learning/hooks/utils/useRankedListHelpers';
import ContentListCount from '~Learning/components/Shared/ContentListLength';
import { LEARNING_MAX_CONTENT_IN_PLAYLIST } from '~Learning/const/index';
import { useUpdateLearningPlaylistTemplate } from '~Learning/hooks/templates/playlists/useUpdateLearningPlaylistTemplate';
import LearningTemplateCategoryButtons from '../LearningTemplateCategoryButtons';
import { addNewTemplateContentDrawerTemplate } from '../CreatePlaylistTemplateDrawer/AddNewContentDrawer';
import SelectTemplateBanner from '../CreatePlaylistTemplateDrawer/SelectTemplateBanner';
import { LearningLibraryRenderBackButtonParams, viewLearningLibraryDrawerTemplate } from '../ViewLearningLibraryDrawer';

const styles = {
  detailsContainer: css({
  }),
  contentHeader: css({
    marginTop: '1.125rem',
  }),
  categorySection: css({
    marginTop: '0.5rem',
  }),
  learningPlaylistContentCard: css({
    margin: '.25rem',
    marginBottom: '.625rem',
  }),
  contentButtons: css({
    marginTop: '.625rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  }),
  addContentButton: css({
    marginRight: '.625rem',
  }),
  footerButtons: css({
    marginTop: '2.875rem',
    display: 'flex',
  }),
  saveButton: css({
    marginRight: '.625rem',
  }),
  titleInput: css({
    marginBottom: '0.5rem',
  }),
  dragAndDrop: css({
    color: palette.neutrals.gray700,
  }),
  backButton: css({
    paddingLeft: '0px',
  }),
};

export const editLearningPlaylistTemplateDrawerTemplate = {
  name: 'EDIT_LEARNING_PLAYLIST_TEMPLATE_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

enum FormSubmittedByButton {
  ADD_NEW_CONTENT = 'ADD_NEW_CONTENT',
  ADD_FROM_TEMPLATE = 'ADD_FROM_TEMPLATE',
  SAVE = 'SAVE',
  EDIT_CONTENT_PIECE = 'EDIT_CONTENT_PIECE',
}

export interface EditLearningPlaylistTemplateDrawerState {
  playlistTitle: string,
  playlistDescription: string,
  playlistContentList: FrontendPlaylistLearning[],
  categoryId: string,
  deletedLearningIds: string[],
  deletedQuestionIds: string[],
}

interface EditLearningPlaylistTemplateDrawerProps extends DrawerProps<EditLearningPlaylistTemplateDrawerState> {
  playlistTemplateId: number,
  onUseTemplate: ({ templateId, isPlaylist }: OnUseTemplateParams) => void,
  openViewPlaylistTemplateDrawer: () => void,
  page: number,
  count: number,
}

const EditLearningPlaylistTemplateDrawer = ({
  playlistTemplateId,
  openViewPlaylistTemplateDrawer,
  page,
  count,
  setDrawerState,
  drawerState,
  popDrawer,
  pushDrawer,
}: EditLearningPlaylistTemplateDrawerProps): JSX.Element => {
  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      shouldConfirmDrawerClose: true,
    }));
  }, [setDrawerState]);

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: editLearningPlaylistTemplateDrawerTemplate.name });
  };

  const openThisDrawer = (): void => {
    pushDrawer({
      drawer: {
        ...editLearningPlaylistTemplateDrawerTemplate,
        args: {
          playlistTemplateId,
          openViewPlaylistTemplateDrawer,
          page,
          count,
        },
      },
    });
  };

  const {
    mutation: updateLearningPlaylistTemplateMutation,
  } = useUpdateLearningPlaylistTemplate({
    openEditPlaylistTemplateDrawer: openThisDrawer,
    page,
    count,
    categoryId: drawerState.categoryId,
    onMutateCallback: () => {
      setDrawerState((prev) => ({
        ...prev,
        isUpdatingLearningPlaylistTemplate: true,
      }));

      openViewPlaylistTemplateDrawer();
    },
    onSuccessCallback: () => {
      setDrawerState((prev) => ({
        ...prev,
        deletedLearningIds: [],
      }));
    },
    onSettledCallback: () => {
      setDrawerState((prev) => ({
        ...prev,
        isUpdatingLearningPlaylistTemplate: false,
      }));
    },
    onErrorCallback: () => {
      popDrawer({ popAll: true });
      openThisDrawer();
    },
  });

  const [formSubmittedByButton, setFormSubmittedByButton] = useState<FormSubmittedByButton>();
  const [contentList, setContentList] = useState<FrontendPlaylistLearning[]>(drawerState.playlistContentList || []);
  const [contentIndexToEdit, setContentIndexToEdit] = useState<number | null>(null);
  const { onDragEnd } = useDragAndDrop();
  const { getArrayWithoutItem } = useRankedListHelpers(contentList);
  const hasValidation = formSubmittedByButton === FormSubmittedByButton.SAVE;

  const onEditContent = (contentIndex: number): void => {
    setContentIndexToEdit(contentIndex);
    setFormSubmittedByButton(FormSubmittedByButton.EDIT_CONTENT_PIECE);
  };

  const onDeleteContent = (contentIndex: number, learningId?: string): void => {
    const newContentList = getArrayWithoutItem(contentIndex);
    if (learningId) {
      const previousDeletedLearningIds = drawerState.deletedLearningIds || [];
      const newDeletedLearningIds = [...previousDeletedLearningIds, learningId];
      setDrawerState((prev) => ({
        ...prev,
        deletedLearningIds: newDeletedLearningIds,
      }));
    }
    setContentList(newContentList);
  };

  const cancelEditMode = (): void => {
    closeDrawerClick();
    setDrawerState((prev) => ({
      ...prev,
      workflow: null,
    }));
    openViewPlaylistTemplateDrawer();
  };

  const onUseTemplate = ({ templateId, isPlaylist, playlistContentList }: OnUseTemplateParams): void => {
    popDrawer({ popAll: true });
    if (isPlaylist && playlistContentList) {
      const currentPlaylistContentList = contentList;
      const playlistContentListWithoutIds = playlistContentList.map((playlistContent) => ({
        ...playlistContent,
        id: null,
      }));

      const unrankedPlaylistContentList = [...currentPlaylistContentList, ...playlistContentListWithoutIds];

      const rankedPlaylistContentList = unrankedPlaylistContentList.map((learning, index) => ({
        ...learning,
        rank: index,
      }));

      setDrawerState((prev) => ({
        ...prev,
        playlistContentList: rankedPlaylistContentList,
      }));

      openThisDrawer();
    } else {
      pushDrawer({
        drawer: {
          ...addNewTemplateContentDrawerTemplate,
          args: {
            templateId,
            onDrawerClose: openThisDrawer,
          },
        },
      });
    }
  };

  const onQuestionDeleteCallback = (questionId: string): void => {
    if (questionId) {
      const previousDeletedQuestionIds = drawerState.deletedQuestionIds || [];
      const newDeletedQuestionIds = [...previousDeletedQuestionIds, questionId];
      setDrawerState((prev) => ({
        ...prev,
        deletedQuestionIds: newDeletedQuestionIds,
      }));
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (hasValidation) {
      if (contentList.length < 2) {
        toast.error('You need at least two pieces of content.');
        return;
      }
      if (contentList.length > LEARNING_MAX_CONTENT_IN_PLAYLIST) {
        toast.error(`You can have a max of ${LEARNING_MAX_CONTENT_IN_PLAYLIST} content pieces in a playlist.`);
        return;
      }
    }

    const formData = new FormData(event.currentTarget);
    const playlistTitle = formData.get('title') as string;
    const playlistDescription = formData.get('description') as string;
    const categoryId = formData.get('categoryId') as string;

    // Store all the information in drawer state, as temporarily closing this drawer.
    setDrawerState((prev) => ({
      ...prev,
      playlistContentList: contentList,
      playlistTitle,
      playlistDescription,
      categoryId,
    }));

    popDrawer({ popAll: true });

    if (formSubmittedByButton === FormSubmittedByButton.ADD_NEW_CONTENT) {
      // Open the AddNewContentDrawer to add new content
      pushDrawer({
        drawer: {
          ...addNewTemplateContentDrawerTemplate,
          args: {
            onDrawerClose: openThisDrawer,
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.ADD_FROM_TEMPLATE) {
      // Open the LearningLibrary Drawer
      const onBackClick = (closeLearningLibrary: () => void): void => {
        closeLearningLibrary();
        openThisDrawer();
      };

      pushDrawer({
        drawer: {
          ...viewLearningLibraryDrawerTemplate,
          args: {
            onUseTemplate,
            renderBanner: () => (
              <SelectTemplateBanner />
            ),
            renderBackButton: ({ backButtonStyles, closeLearningLibrary }: LearningLibraryRenderBackButtonParams) => (
              <IconButton
                onClick={() => onBackClick(closeLearningLibrary)}
                type="button"
                icon={faArrowLeft}
                css={[backButtonStyles, styles.backButton]}
                tooltip="Back"
              />
            ),
            canCreateLearningTemplate: false,
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.EDIT_CONTENT_PIECE) {
      // Open the AddNewContentDrawer to edit content
      if (contentIndexToEdit !== null) {
        setDrawerState((prev) => ({
          ...prev,
          contentTitle: contentList[contentIndexToEdit].title,
          contentLink: contentList[contentIndexToEdit].contentURL,
          introduction: contentList[contentIndexToEdit].introduction,
          questions: contentList[contentIndexToEdit].questions,
          dueDate: contentList[contentIndexToEdit].dueDate,
          contentId: contentList[contentIndexToEdit].id || null,
        }));
      }
      pushDrawer({
        drawer: {
          ...addNewTemplateContentDrawerTemplate,
          args: {
            contentIndexToEdit,
            onDrawerClose: openThisDrawer,
            onQuestionDeleteCallback,
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.SAVE) {
      const apiObject = {
        id: playlistTemplateId,
        title: playlistTitle,
        description: playlistDescription,
        categoryId,
        learnings: contentList,
        idsOfLearningsToDelete: drawerState.deletedLearningIds || [],
      };

      setDrawerState((prev) => ({
        ...prev,
        workflow: null,
      }));
      updateLearningPlaylistTemplateMutation(apiObject);
    }
  };

  const hookProps = {
    onSubmit,
    renderHeader: () => (
      <DrawerHeader
        title="Edit Playlist"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <DrawerCloseButtonWithConfirmation
            renderDrawerCloseButton={({ onClick }) => (
              <IconButton onClick={onClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
            )}
            onCloseDrawer={cancelEditMode}
            shouldConfirmClose
          />
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <div>
          <div css={styles.detailsContainer}>
            <DrawerInput
              name="title"
              label="Learning Playlist Title"
              css={styles.titleInput}
              maxLength={250}
              required
              initialValue={drawerState.playlistTitle}
            />
            <Froala
              name="description"
              label="Description"
              required
              richTextEditorProps={{
                initialValue: drawerState.playlistDescription,
              }}
              froalaConfigProps={{
                charCounterMax: 1000,
                charCounterCount: true,
              }}
              renderRightIcon={() => (
                <RequiredIcon />
              )}
            />
            <LearningTemplateCategoryButtons
              css={styles.categorySection}
              showHeader={false}
              categoryId={drawerState.categoryId}
            />
          </div>
          <ContentListCount
            css={styles.contentHeader}
            contentListCount={contentList.length}
          />
          {contentList.length < 2 && (
            <PlaylistContentBanner
              contentListLength={contentList.length}
            />
          )}
          <DragAndDrop
            css={styles.dragAndDrop}
            list={contentList}
            renderItem={({ id: content }: { id: FrontendPlaylistLearning }, dragHandleProps: DraggableProvidedDragHandleProps) => (
              <EditablePlaylistLearningCard
                key={`${content.contentURL}-${content.title}`}
                css={styles.learningPlaylistContentCard}
                contentUrl={content.contentURL}
                onEditContent={() => onEditContent(content.rank)}
                onDeleteContent={() => onDeleteContent(content.rank, content.id)}
                dragHandleProps={dragHandleProps}
                renderDetails={() => (
                  <Details
                    title={content.title}
                    introduction={content.introduction}
                  />
                )}
              />
            )}
            onDragEnd={onDragEnd}
            dragEndProps={{
              initialArray: contentList,
              movedItem: (rank: string) => contentList.find((content) => content.rank.toString() === rank),
              // @ts-expect-error TODO: Remove once DragAndDrop is typed
              endFunctions: (newArray: FrontendPlaylistLearning[]) => {
                const updatedContentList = newArray.map((content, index) => ({ ...content, rank: index }));
                setContentList(updatedContentList);
              },
            }}
            valueToUse="rank"
          />
          {contentList.length < LEARNING_MAX_CONTENT_IN_PLAYLIST && (
            <div css={styles.contentButtons}>
              <LeadrButton
                css={styles.addContentButton}
                variant="ghost"
                size="small"
                type="submit"
                onClick={() => setFormSubmittedByButton(FormSubmittedByButton.ADD_NEW_CONTENT)}
                data-test-id="learningEditPlaylistAddNewContent"
              >
                + Add New Content
              </LeadrButton>
              <LeadrButton
                variant="ghost"
                size="small"
                type="submit"
                onClick={() => setFormSubmittedByButton(FormSubmittedByButton.ADD_FROM_TEMPLATE)}
                data-test-id="learningEditPlaylistAddFromTemplate"
              >
                + Add From Template
              </LeadrButton>
            </div>
          )}
        </div>
        <div css={styles.footerButtons}>
          <LeadrButton
            css={styles.saveButton}
            type="submit"
            onClick={() => setFormSubmittedByButton(FormSubmittedByButton.SAVE)}
            data-test-id="learningEditPlaylistSave"
          >
            Save
          </LeadrButton>
          <DrawerCloseButtonWithConfirmation
            renderDrawerCloseButton={({ onClick }) => (
              <LeadrButton
                variant="ghost"
                data-test-id="learningEditPlaylistCancel"
                onClick={onClick}
              >
                Cancel
              </LeadrButton>
            )}
            onCloseDrawer={cancelEditMode}
            shouldConfirmClose
          />
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: editLearningPlaylistTemplateDrawerTemplate.name,
  component: EditLearningPlaylistTemplateDrawer,
});

export default EditLearningPlaylistTemplateDrawer;
