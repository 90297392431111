import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';

import { palette } from '~Common/styles/colors';
import { DaySectionSkeleton } from '~Home/components/Module/Meetings/DaySection';
import MeetingCard from '~Home/components/Module/Meetings/MeetingCard';
import { UnsyncedCalendarMeeting, useGetIgnoredUnsyncedCalendarMeetings } from '~Meetings/hooks/CalendarIntegration/useGetIgnoredUnsyncedCalendarMeetings';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TeamsListScope } from '~People/components/Teams/const/types';
import ReviewUnsyncedMeetingsDrawerTabBody from '../ReviewUnsyncedMeetingsDrawerTabBody';

const styles = {
  daySection: css({
    '&:not(:first-of-type)': {
      paddingTop: '1.125rem',
      borderTop: `1px solid ${palette.neutrals.gray400}`,
    },
    '&:not(:last-of-type)': {
      paddingBottom: '1.125rem',
    },
  }),
};

interface ViewProps {
  ignoredUnsyncedCalendarMeetings: UnsyncedCalendarMeeting[] | undefined,
  areIgnoredUnsyncedCalendarMeetingsLoading: boolean,
  hasTeams: boolean
}

const View = ({
  ignoredUnsyncedCalendarMeetings,
  areIgnoredUnsyncedCalendarMeetingsLoading,
  hasTeams,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {areIgnoredUnsyncedCalendarMeetingsLoading && (
      <DaySectionSkeleton css={styles.daySection} />
    )}
    {!areIgnoredUnsyncedCalendarMeetingsLoading && (
      <>
        {(!ignoredUnsyncedCalendarMeetings || ignoredUnsyncedCalendarMeetings.length === 0) && (
          <div>No unsynced previously ignored meetings</div>
        )}
        {ignoredUnsyncedCalendarMeetings && ignoredUnsyncedCalendarMeetings.length > 0 && (
          <ReviewUnsyncedMeetingsDrawerTabBody
            unsyncedMeetings={ignoredUnsyncedCalendarMeetings}
            renderMeetingCard={(meeting) => (
              <MeetingCard
                meeting={meeting}
                key={meeting.nylasEventId}
                hasTeams={hasTeams}
              />
            )}
          />
        )}
      </>
    )}
  </div>
);

const PreviouslyIgnoredMeetings = ({ ...props }): JSX.Element => {
  const { ignoredUnsyncedCalendarMeetings, isLoading: areIgnoredMeetingsLoading } = useGetIgnoredUnsyncedCalendarMeetings();
  const { data, isLoading: areTeamsLoading } = useGetTeams({
    listScope: TeamsListScope.MyTeams,
    page: 0,
    count: 200,
  });

  const [areIgnoredUnsyncedCalendarMeetingsLoading] = useSkeletonLoaders(areTeamsLoading || areIgnoredMeetingsLoading);

  const hasTeams = useMemo(() => (
    !!data?.response.totalTeamCount
  ), [
    data?.response,
  ]);

  const hookProps = {
    ignoredUnsyncedCalendarMeetings,
    areIgnoredUnsyncedCalendarMeetingsLoading,
    hasTeams,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default PreviouslyIgnoredMeetings;
