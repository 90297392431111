// Note: This was initially Typescript but I admitted defeat in handling mui's
// AutocompleteProps generic. There are open issues for some of their typing choices
// on Github but in the meantime, JSX.

// Note: This component allows the user to create new options - if you do not need or want that use the AutoComplete component
import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { uuid } from '~Common/utils/uuid';
import { autoCompleteStyles } from '~Common/V3/styles/AutoComplete';

const styles = {
  ...autoCompleteStyles,
};
const filter = createFilterOptions();

export function AutoCompleteFreeForm({
  name,
  options,
  disablePortal,
  label,
  required,
  defaultValue,
  freeSolo,
  handleHomeEndKeys,
  selectOnFocus,
  disabled,
}) {
  const { control, formState: { errors } } = useFormContext();
  const error = errors[name];
  const [value, setValue] = useState(defaultValue ?? options);
  return (

    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <>
          <MuiAutocomplete
            css={[styles.autocomplete, (error ? styles.error : null)]}
            value={value}
            disabled={disabled}
            disablePortal={disablePortal}
            ListboxProps={{
              sx: {
                '.MuiAutocomplete-option': {
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  overflow: 'hidden',
                  width: '100%',
                },
              },
            }}
            onChange={(_, newValue) => {
              if (newValue && newValue.inputValue) {
                setValue(newValue.inputValue);
                onChange(newValue.inputValue);
              } else {
                setValue(newValue);
                onChange(newValue);
              }
            }}
            filterOptions={(listOptions, params) => {
              const filtered = filter(listOptions, params);
              const { inputValue } = params;
              const isExisting = listOptions.some((option) => inputValue === option.title);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  title: `Add "${inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus={selectOnFocus}
            clearOnBlur
            handleHomeEndKeys={handleHomeEndKeys}
            options={options}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            renderOption={(optionProps, option) => (
              <li {...optionProps} key={typeof option === 'object' ? option.value : uuid()}>
                {typeof option === 'object' ? option.title : option}
              </li>
            )}
            freeSolo={freeSolo}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                required={required}
              />
            )}
          />
          {error && (
          <FormHelperText error css={styles.errorMessage}>{error.message}</FormHelperText>
          )}
        </>
      )}
    />

  );
}

AutoCompleteFreeForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  disablePortal: PropTypes.bool,
  freeSolo: PropTypes.bool,
  handleHomeEndKeys: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
};

AutoCompleteFreeForm.defaultProps = {
  defaultValue: undefined,
  label: undefined,
  required: false,
  disablePortal: false,
  freeSolo: false,
  handleHomeEndKeys: false,
  selectOnFocus: false,
  disabled: false,
};
