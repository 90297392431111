export const useDragAndDrop = () => {
  const onDragEnd = (result, { initialArray, movedItem, endFunctions }) => {
    const { destination, source, draggableId } = result;

    // Item was dragged to a nondropable area on the screen (not in the <Droppable /> component)
    if (!destination) {
      return;
    }

    // Item was not moved
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Create new array with the moved item in the proper place
    const newArray = Array.from(initialArray);
    newArray.splice(source.index, 1);
    newArray.splice(destination.index, 0, movedItem(draggableId));

    // Update the state of the list view to show the newly arranged list
    endFunctions(newArray, draggableId);
  };

  return { onDragEnd };
};
