import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import { withBaseShadow } from '~Common/styles/mixins';
import { elevate } from '~Common/styles/animations';

const styles = {
  container: css`
    cursor: pointer;
    border-radius: 16px;
    background: ${palette.neutrals.white};
    padding: 0px;
    ${withBaseShadow()};
  `,
};

/**
 * @deprecated Old Card UI, use packages/leadr-frontend/src/common/V3/components/Card.tsx instead
 */
const Card = ({ children, className }) => (
  <div
    css={[styles.container, elevate]}
    className={className}
  >
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  className: '',
};

export default Card;
