import { useMemo } from 'react';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { ReviewShape, ReviewStatusEnum } from '~Reviews/V2/Const/types';

function getParticipantReviewType(review?: ReviewShape): number {
  if (!review) {
    return ReviewStatusEnum.Unknown;
  }

  const { participants } = review;
  const orgUserId = getOrganizationUserId();

  const findCurrentParticipant = participants.find((participant) => (participant.orgUserId === orgUserId));

  return findCurrentParticipant?.statusEnum ?? ReviewStatusEnum.Unknown;
}

export const useReturnLoggedInReviewStatus = (fullReview: ReviewShape | undefined): number => {
  const participantReviewType = useMemo(
    () => getParticipantReviewType(fullReview),
    [fullReview],
  );

  return participantReviewType;
};
