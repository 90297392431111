import { connect } from 'react-redux';
import NexusOrganizations from '~Deprecated/ui/views/Nexus/NexusOrganizations';
import { loadOrganizationInsightsAction } from '~Deprecated/actions/organizationInsights/loadOrganizationInsights';
import { clearOrganizationInsightsAction } from '~Deprecated/actions/organizationInsights/clearOrganizationInsights';
import { getOrganizations, getTotalOrganizationsCount } from '~Deprecated/selectors/organizationInsights/getOrganizations';
import { getSagaStatusForScope } from '~Deprecated/selectors/getSagaStatusForScope';

const mapStateToProps = (state) => {
  const status = getSagaStatusForScope(state, { scope: 'organizationInsights' });

  return {
    ids: getOrganizations(state),
    totalCount: getTotalOrganizationsCount(state),
    ...status,
  };
};

const mapDispatchToProps = {
  loadOrganizationInsightsAction,
  clearOrganizationInsightsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NexusOrganizations);
