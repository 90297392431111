import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SyntheticEvent } from 'react';
import { Moment } from 'moment';
import { MonthlyRecurrence } from '~ActionItems/hooks/useActionItemRecurrenceFrequency';
import { RecurrenceDropdownOptions } from './constants';

export interface ActionItemDrawerState {
  selectedAttendees?: string[],
  dueDate: Moment | null,
  setDueDate: (newDate: Moment | null) => void,
}

export interface ActionMenuItem {
  text: string,
  icon: IconProp,
  disabled?: boolean,
  onClick?: (event: SyntheticEvent<HTMLElement>) => void,
}

export enum ActionItemStatus {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETED',
}

export enum NewActionItemStatus {
  Completed = 'COMPLETED',
  ToDo = 'TO DO',
  InProgress = 'IN PROGRESS',
  Blocked = 'BLOCKED',
}

export interface ActionItemCreator {
  firstName: string,
  lastName: string,
  imageUrl: string,
  isDisabled: boolean,
}

export interface ActionItemAssignee {
  administraviteStatus: string,
  department: string,
  firstName:string,
  jobTitle: string,
  lastName:string,
  managerId: string,
  managerName: string,
  orgUserId: string,
  profileImageUrl: string,
  userId: string,
}

export enum ActionItemContextType {
  Goal = 'GOAL',
  Meeting = 'MEETING',
}

export interface ActionItemContext {
  type: ActionItemContextType,
  id: string,
  description: string,
}

export interface ActionItem {
  assigneeId: string,
  assigneeUserId: string,
  completedInMillis?: number,
  completedById?: string,
  context: ActionItemContext,
  createdInMillis: number,
  creatorId: string,
  creatorUserId: string,
  dueDateInMillis: number,
  externalLink: string,
  id: string,
  isRecurring: boolean,
  lastModifiedInMillis: number,
  status: NewActionItemStatus,
  text: string,
  recurrenceRule?: string,
  description?: string,
  commentCount: number,
}

export interface ActionItemRecurrenceRRule {
  dueDate: Moment,
  selectedWeekDays: number[],
  recurrenceInterval: number,
  selectedRecurrence: RecurrenceDropdownOptions,
  monthlyRecurrence: MonthlyRecurrence,
}
