import { SyntheticEvent } from 'react';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';

import DrawerCloseConfirmationButtons from './DrawerCloseConfirmationButtons';

interface DrawerCloseButtonWithConfirmationProps {
  onCloseDrawer: (event: SyntheticEvent<HTMLButtonElement>) => void,
  renderDrawerCloseButton: ({ onClick }: {onClick: (event: SyntheticEvent<HTMLButtonElement>) => void}) => JSX.Element,
  shouldConfirmClose?: boolean,
}

const DrawerCloseButtonWithConfirmation = ({
  renderDrawerCloseButton,
  onCloseDrawer,
  shouldConfirmClose = false,
  ...props
}: DrawerCloseButtonWithConfirmationProps): JSX.Element => (
  <>
    {shouldConfirmClose && (
      <DeleteButtonWithConfirmation
        {...props}
        renderDeleteButton={renderDrawerCloseButton}
        renderConfirmationButtons={({ informationStyles, optionStyles, popoverButtonStyles }) => (
          <DrawerCloseConfirmationButtons
            informationStyles={informationStyles}
            optionStyles={optionStyles}
            popoverButtonStyles={popoverButtonStyles}
            onCloseDrawer={onCloseDrawer}
          />
        )}
      />
    )}
    {!shouldConfirmClose && renderDrawerCloseButton({ onClick: onCloseDrawer })}
  </>

);

export default DrawerCloseButtonWithConfirmation;
