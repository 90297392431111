import * as types from './index';

export const sendResponseData = (payload) => ({
  type: types.SEND_RESPONSE_CONTENT_FOR_ME_LIST,
  payload,
});

// select single teammate flow

export const clearMeetingSuccessResponse = () => ({
  type: types.CLEAR_MEETING_SUCCESS_RESPONSE,
});

export const clearCreateContentResponse = () => ({
  type: types.CLEAR_CREATE_CONTENT_RESPONSE,
});
