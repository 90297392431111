import { MeetingAgendaTopicInstance } from '~Meetings/const/meetingsSearchInterfaces';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import SectionItem from '../SectionItem';

interface MeetingTopicItemProps {
  hit: MeetingAgendaTopicInstance,
  usersInfo: PersonDisplayInformation[],
  meetingTitle: string,
  meetingStartTime: string,
  link: string,
}

const MeetingTopicItem = ({
  hit,
  usersInfo,
  meetingTitle,
  meetingStartTime,
  ...props
}: MeetingTopicItemProps): JSX.Element => (
  <SectionItem
    data-test-id="globalSearchMeetingTopicItem"
    renderLeftItem={() => (
      <SectionItem.Icon icon={faLightbulb} />
    )}
    renderMainText={(mainTextStyles) => (
      <>
        {/* eslint-disable-next-line no-underscore-dangle */}
        <HTMLRenderer css={mainTextStyles} htmlText={hit._snippetResult?.agendaTopic?.text?.value} />
      </>
    )}
    renderSecondaryText={() => (
      <SectionItem.SecondaryText
        firstText={meetingTitle}
        secondText={meetingStartTime}
      />
    )}
    renderAvatarSection={() => (
      <SectionItem.ParticipantAvatars usersInfo={usersInfo} />
    )}
    {...props}
  />
);

export default MeetingTopicItem;
