import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import { palette } from '~Common/styles/colors';
import { useShowCommentEditor } from '~Meetings/hooks/useShowAgendaEditor';
import { withoutDesktopObject } from '~Common/styles/mixins';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import CareCardAvatar from '~Common/V3/components/CareCardAvatar';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import useMeetingRealtime from '~Meetings/hooks/useMeetingRealtime';
import { AgendaCommentRealTimeMessageType } from '~Meetings/const/realTimeMeetingMessages';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { useGetTopicTimeString } from '~Meetings/hooks/useGetTopicTimeString';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useUpdateAgendaComment } from '~Meetings/hooks/v2/useUpdateAgendaComment';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import PrivateIcon from '../PrivateIcon';
import { AgendaNoteActionMenu } from './AgendaNoteActionMenu';
import CreateAgendaItem from '../CreateAgendaItem';

const styles = {
  note: (addShowAgendaEditor) => css({
    width: '100%',
    border: '1px solid transparent',
    transition: 'border-color 250ms ease',
    scrollMarginTop: '4rem',
    borderRadius: '.5rem',
    background: palette.neutrals.white,
    padding: '.75rem',
    margin: '.5rem 0',
  }, addShowAgendaEditor && ({
    background: 'none',
    padding: 0,
  }), withoutDesktopObject({
    padding: '.2rem',
  })),
  noteBody: css({
    flex: 1,
  }),
  noteContainer: css({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  }),
  avatarContainer: css({
    marginRight: '.75rem',
  }),
  text: css({
    fontSize: '.875rem',
    color: palette.neutrals.gray800,
    fontWeight: 400,
    overflowWrap: 'break-word',
    cursor: 'text',
    lineHeight: '1.25rem',

    // Some targeted spacing control
    'ul, ol': {
      lineHeight: '1.5rem',
      marginBottom: '0.5rem',
    },
  }),
  time: css({
    color: palette.neutrals.gray600,
    fontSize: '0.75rem',
  }),
  textContainer: css({
    paddingBottom: '0.1rem',
  }),
  highlight: css({
    borderColor: palette.brand.blue,
  }),
  editNoteEditor: css({
    '.fr-toolbar.fr-desktop.fr-top.fr-basic': {
      backgroundColor: palette.neutrals.white,
    },
    '.fr-toolbar.fr-mobile.fr-top.fr-basic': {
      backgroundColor: palette.neutrals.white,
    },
    '.fr-box.fr-basic.fr-top > .fr-wrapper': {
      backgroundColor: palette.neutrals.white,
    },
    '.fr-second-toolbar': {
      backgroundColor: `${palette.neutrals.white} !important`,
    },
  }),
};

const View = ({
  agendaItemId,
  agendaTopicCommentUpdateEventId,
  createdAt,
  creatorId,
  hasBeenEdited,
  huddleId,
  isHighlighted,
  isPrivate,
  noteId,
  onCancelEditor,
  scrollRef,
  setAgendaTopicCommentUpdateEventId,
  setShowCommentEditor,
  showAddCommentEditor,
  text,
  timeSinceEdit,
  onSave,
  sectionId,
  draftKey,
}) => (
  <div css={[styles.note(showAddCommentEditor), isHighlighted && styles.highlight]}>
    <div css={styles.noteContainer} ref={scrollRef}>
      {!showAddCommentEditor && (
        <div css={styles.avatarContainer}>
          {isPrivate && (
            <PrivateIcon />
          )}
          {!isPrivate && (
            <CareCardAvatar
              id={creatorId}
              noBackdrop
              renderAvatar={(imageUrl, fullName, isDeactivated) => (
                <NewBaseAvatar
                  profileImageUrl={imageUrl}
                  name={fullName}
                  isDeactivated={isDeactivated}
                  avatarSize={30}
                />
              )}
              renderSkeletonLoader={() => (
                <SkeletonLoader
                  width={30}
                  height={30}
                  variant="rect"
                  renderComponent={() => <div />}
                />
              )}
            />
          )}
        </div>
      )}

      <div css={styles.noteBody}>
        {showAddCommentEditor && (
          <CreateAgendaItem
            css={styles.editNoteEditor}
            huddleId={huddleId}
            initialValue={text}
            onCancel={onCancelEditor}
            onSave={onSave}
            defaultBold={false}
            draftKey={draftKey}
            preferDraftValue
            isPrivate={isPrivate}
          />
        )}

        {!showAddCommentEditor && (
          <div css={styles.textContainer}>
            <HTMLRenderer css={styles.text} htmlText={text} />
            <span css={styles.time}>
              {createdAt}
              {hasBeenEdited && (
                <>
                  {` (edited ${timeSinceEdit}) `}
                </>
              )}
            </span>
          </div>
        )}
      </div>
      {!showAddCommentEditor && (
        <AgendaNoteActionMenu
          agendaItemId={agendaItemId}
          agendaTopicCommentUpdateEventId={agendaTopicCommentUpdateEventId}
          huddleId={huddleId}
          isPrivate={isPrivate}
          noteId={noteId}
          noteText={text}
          setAgendaTopicCommentUpdateEventId={setAgendaTopicCommentUpdateEventId}
          setShowCommentEditor={setShowCommentEditor}
          sectionId={sectionId}
        />
      )}
    </div>
  </div>
);

View.propTypes = {
  agendaItemId: PropTypes.string.isRequired,
  agendaTopicCommentUpdateEventId: PropTypes.string,
  createdAt: PropTypes.number.isRequired,
  creatorId: PropTypes.string.isRequired,
  hasBeenEdited: PropTypes.bool.isRequired,
  huddleId: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  noteId: PropTypes.string.isRequired,
  onCancelEditor: PropTypes.func.isRequired,
  scrollRef: PropTypes.object.isRequired,
  setAgendaTopicCommentUpdateEventId: PropTypes.func.isRequired,
  setShowCommentEditor: PropTypes.func.isRequired,
  showAddCommentEditor: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  timeSinceEdit: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  sectionId: PropTypes.string,
  draftKey: PropTypes.arrayOf(PropTypes.string).isRequired,
};

View.defaultProps = {
  agendaTopicCommentUpdateEventId: undefined,
  sectionId: '',
};

const HuddleAgendaNote = ({
  agendaItemId,
  huddleId,
  note,
  shouldHighlight,
  type,
  sectionId,
  realtimeUpdatingAgenda,
  ...props
}) => {
  const orgId = getOrganizationId();

  const draftKey = useMemo(() => [orgId, huddleId, 'agendaItem', agendaItemId, 'comment', note.id], [orgId, huddleId, agendaItemId, note.id]);

  const { mutate: doEditTopicNote } = useUpdateAgendaComment({
    sectionId,
    topicId: agendaItemId,
    draftKey,
    commentId: note.id,
  });

  const [agendaTopicCommentUpdateEventId, setAgendaTopicCommentUpdateEventId] = useState('');

  const { showAddCommentEditor, setShowCommentEditor } = useShowCommentEditor(
    agendaItemId,
    note.id,
  );

  const isMobile = useIsMobileQuery();
  const timeSinceComment = useGetTopicTimeString(note.createdInMillis);
  const timeSinceEdit = useGetTopicTimeString(note.lastModifiedInMillis);
  const hasBeenEdited = note.lastModifiedInMillis > note.createdInMillis;

  const [isHighlighted, setIsHighlighted] = useState(false);

  const { sendMessage } = useMeetingRealtime({ huddleId });

  useEffect(() => {
    if (shouldHighlight) {
      setIsHighlighted(true);
      setTimeout(() => {
        setIsHighlighted(false);
      }, 3500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only want this on first render
  }, []);

  const scrollRef = useRef(null);

  if (isHighlighted) {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  useEffect(
    () => () => {
      setShowCommentEditor(false);
    },
    [setShowCommentEditor],
  );

  const onCancelEditor = () => {
    const parentIds = [];

    if (sectionId) {
      parentIds.push(sectionId);
    }

    parentIds.push(agendaItemId);

    sendMessage(AgendaCommentRealTimeMessageType.UpdateCanceled, {
      id: note.id,
      eventId: agendaTopicCommentUpdateEventId,
      parentIds,
    });

    setAgendaTopicCommentUpdateEventId('');
    setShowCommentEditor(false);
  };

  const onSave = useCallback(({
    text,
    isPrivate,
  }) => {
    doEditTopicNote({
      commentId: note.id,
      ...(!!agendaTopicCommentUpdateEventId && { eventId: agendaTopicCommentUpdateEventId }),
      context:
      {
        type: 'AGENDA_ITEM',
        id: agendaItemId,
        meetingId: huddleId,
      },
      item: {
        text,
        rank: 1.2,
        isPrivate,
      },
    });
  }, [
    agendaItemId,
    huddleId,
    agendaTopicCommentUpdateEventId,
    doEditTopicNote,
    note.id,
  ]);

  const hookProps = {
    agendaItemId,
    agendaTopicCommentUpdateEventId,
    createdAt: timeSinceComment,
    creatorId: note.creator.orgUserId,
    hasBeenEdited,
    huddleId,
    isHighlighted,
    isMobile,
    isPrivate: note.isPrivate,
    noteId: note.id,
    onCancelEditor,
    scrollRef,
    setAgendaTopicCommentUpdateEventId,
    setShowCommentEditor,
    showAddCommentEditor,
    text: note.text,
    timeSinceComment,
    timeSinceEdit,
    type,
    onSave,
    sectionId,
    draftKey,
  };

  return <View {...hookProps} {...props} />;
};

HuddleAgendaNote.propTypes = {
  creator: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  note: PropTypes.object.isRequired,
  agendaItemId: PropTypes.string.isRequired,
  huddleId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  createdAt: PropTypes.number.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  realtimeCommentEditingData: PropTypes.arrayOf(PropTypes.object),
  shouldHighlight: PropTypes.bool,
  sectionId: PropTypes.string,
  realtimeUpdatingAgenda: PropTypes.object,
};

HuddleAgendaNote.defaultProps = {
  realtimeCommentEditingData: [],
  shouldHighlight: false,
  sectionId: '',
  realtimeUpdatingAgenda: undefined,
};

export { View };
export default HuddleAgendaNote;
