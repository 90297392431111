import Card from '~Common/V3/components/Card';
import {
  ActionMenuActions, EditPostShape, InfiniteQueryParamsProps, RecognitionShape,
} from '~Recognition/const/types';
import ActionMenu, { MenuItem, useActionMenu } from '~Meetings/components/shared/ActionMenu';
import { useCallback } from 'react';
import { setRecognitionMenuItems } from '~Recognition/const/functions';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { useDeleteRecognition } from '~Recognition/hooks/useDeleteRecognition';
import { OPTIMISTIC_ID } from '~Recognition/const/defaults';
import { noop } from 'lodash';
import { slideFromBottom } from '~Common/styles/animations';
import { useIsMobileQuery, useIsTabletQuery } from '~Common/hooks/useMediaListener';
import { css } from '@emotion/react';
import { PAGE_STYLES } from '../const/pageStyles';
import CardHeader from './Cards/CardHeader';
import CardBanner from './Cards/CardBanner';
import CardBody from './Cards/CardBody';

const styles = {
  cardHeader: css({
    marginBottom: '.625rem',
  }),
  ...PAGE_STYLES,
};

interface ViewProps {
  recognition: RecognitionShape;
  doOpen: (event: React.MouseEvent<HTMLElement>) => void,
  onClickCallback: () => void,
  menuItems: MenuItem[][],
  canDeletePost: boolean,
  canEditPost: boolean,
  actionMenuProps: {
    anchorEle: HTMLElement | null;
    onClose: () => void;
  },
  filters: InfiniteQueryParamsProps,
  index: number,
  overFlowAvatarNumber: number,
  isMobile: boolean,
  isTablet: boolean,
}

const View = ({
  recognition,
  doOpen,
  onClickCallback,
  menuItems,
  canDeletePost,
  canEditPost,
  actionMenuProps,
  filters,
  index,
  overFlowAvatarNumber,
  isMobile,
  isTablet,
}: ViewProps): JSX.Element => (
  <>
    <Card
      data-test-id="recognitionListCard"
      key={recognition.recognitionId}
      css={[slideFromBottom(index)]}
      renderContents={() => (
        <>
          <CardHeader
            css={styles.cardHeader}
            recipients={recognition.recipients}
            date={recognition.createdOn}
            createdBy={recognition.createdBy}
            recognitionId={recognition.recognitionId}
            isMobile={isMobile || isTablet}
            renderEdit={() => (
              <>
                {(canDeletePost || canEditPost) && (
                <>
                  {recognition.recognitionId === OPTIMISTIC_ID && (
                    <IconButton
                      onClick={noop}
                      data-test-id="recognitionActionMenu"
                      tooltip="Loading Recognition Options"
                      type="button"
                      icon={faEllipsisV}
                      css={styles.recognitionEllipsis(true)}
                      size="large"
                    />
                  )}
                  {recognition.recognitionId !== OPTIMISTIC_ID && (
                    <>
                      <IconButton
                        onClick={doOpen}
                        data-test-id="recognitionActionMenu"
                        tooltip="Recognition Options"
                        type="button"
                        icon={faEllipsisV}
                        css={styles.recognitionEllipsis(true)}
                        size="large"
                      />
                      <ActionMenu
                        css={styles.recognitionActionMenu}
                        menuItems={menuItems}
                        onClick={onClickCallback}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        {...actionMenuProps}
                      />
                    </>
                  )}
                </>
                )}
              </>
            )}
          />
          <CardBanner
            recipients={recognition.recipients}
            createdBy={recognition.createdBy}
            // eslint-disable-next-line no-nested-ternary
            showOverflowAvatarNumber={overFlowAvatarNumber}
          />
          <CardBody
            descriptionHTML={recognition.content}
            recognitionId={recognition.recognitionId}
            filters={filters}
            modifiedOn={recognition.modifiedOn}
          />
        </>
      )}
    />
  </>
);

interface RecognitionListCardProps {
  recognition: RecognitionShape;
  setEditPost: (value: EditPostShape) => void;
  filters: InfiniteQueryParamsProps,
  infiniteQueryParams: InfiniteQueryParamsProps,
  index: number,
}

const RecognitionListCard = ({
  recognition,
  setEditPost,
  filters,
  infiniteQueryParams,
  index,
}: RecognitionListCardProps): JSX.Element => {
  const { doOpen, ...actionMenuProps } = useActionMenu();

  const onClickCallback = useCallback(() => {
    actionMenuProps.onClose();
  }, [actionMenuProps]);
  const { canEdit, canDelete } = recognition;
  const deleteMutation = useDeleteRecognition({ infiniteQueryParams });

  const deleteRecognition = (recognitionId: number):void => {
    deleteMutation({ recognitionId });
  };
  const handleActionmenuClick = (type: ActionMenuActions, id: number): void => {
    if (type === ActionMenuActions.Edit) {
      setEditPost({ editPost: true, postId: id });
    } else if (type === ActionMenuActions.Delete) {
      deleteRecognition(id);
    }
  };

  const menuItems:MenuItem[][] = setRecognitionMenuItems(recognition, handleActionmenuClick);

  const isTablet = useIsTabletQuery();
  const isMobile = useIsMobileQuery();
  const getOverFlowAvatarNumber = (tabletQueryReturn: boolean, mobileQueryReturn: boolean): number => {
    if (tabletQueryReturn) {
      return 4;
    }
    if (mobileQueryReturn) {
      return 6;
    }
    return 18;
  };
  const overFlowAvatarNumber = getOverFlowAvatarNumber(isTablet, isMobile);

  const hookProps = {
    recognition,
    doOpen,
    onClickCallback,
    menuItems,
    canDeletePost: canDelete,
    canEditPost: canEdit,
    actionMenuProps,
    filters,
    index,
    overFlowAvatarNumber,
    isMobile,
    isTablet,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default RecognitionListCard;
