import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import UserInfo from '../../../../UserInfo';
import RichText from '../../../../RichText';
import { feedbackActionMenu } from './config';
import LDRModal from '../../../../Modal/LDRModal';
import { getUserTimezone } from '~Common/utils/localStorage';
import { removeFeedbackQuestionFromCoaching } from '~Deprecated/redux/actions/meetingActions';
import AgendaActionMenu from '../../../../ActionMenu/AgendaActionMenu';
import { startParallelPollingAction } from '~Deprecated/redux/actions/pollingActions';
import { getMeetingById } from '~Deprecated/redux/sagas/meetingSaga';
import { parseQueryString } from '~Deprecated/utils';

// TODO: Rewrite this with new UI in meetings
/**
 * @deprecated Old UI
 */
const FeedbackListItem = ({
  queAnsData, user, meetingId, index, isEditable,
}) => {
  // console.log('ques ans ddara::',queAnsData,meetingId);
  const history = useHistory();
  const { coachingId } = parseQueryString(history.location.search);
  const param = { history, data: coachingId, contextType: 'COACHING' };
  // const responseList = queAnsData.response && queAnsData.response.filter(d => (d.respondedBy[0].id && d.respondedBy[0].id === getUserId()) || d.respondedBy[0].id === queAnsData.belongsTo[0].creator[0].id) || [];
  const getTimezone = getUserTimezone();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const dispatch = useDispatch();
  const handleActionMenuSelection = (e, actionMenu) => {
    if (actionMenu === 'Remove') {
      setShowConfirmationModal(!showConfirmationModal);
    }
  };

  const onClickOfConfirm = () => {
    setShowConfirmationModal(!showConfirmationModal);
    if (meetingId && queAnsData) {
      const payload = {
        deleteObj: {
          deleteObject: { context: { type: 'QUESTION', id: queAnsData.id } },
          reflectInFutureInstances: false,
        },
        coachingId: meetingId,
        index,
      };
      dispatch(removeFeedbackQuestionFromCoaching(payload));
      dispatch(startParallelPollingAction(getMeetingById, param, 'MEETING_DETAILS'));
    } else {
      const payload = {
        index,
        local: true,
        id: queAnsData.id,
      };
      dispatch(removeFeedbackQuestionFromCoaching(payload));
    }
  };

  return (
    <React.Fragment>
      { showConfirmationModal
        ? (
          <LDRModal
            show={showConfirmationModal}
            close={() => {
              setShowConfirmationModal(false);
            }}
            title="Confirm"
            position="centered"
            size="sm"
            footer
            body="Are you sure you want to delete?"
          >
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setShowConfirmationModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(data) => onClickOfConfirm(data)}
            >
              Confirm
            </button>
          </LDRModal>
        )

        : null }
      <div className="feedback-list-item-wrapper">
        <div className="feedback-data-list">
          <div className="discussion">
            <div className="user-image">
              <UserInfo users={queAnsData.creator} />

            </div>
            <div className="agenda-text-wrapper">
              <RichText text={queAnsData.text} className="ql-editor" />
            </div>

            <div className="assign-agenda-btn">
              { isEditable && (
                <AgendaActionMenu
                  staticContents={feedbackActionMenu}
                  selectedItem={(e, actionMenu) => handleActionMenuSelection(e, actionMenu)}
                />
              ) }
            </div>
          </div>
        </div>

        { queAnsData.response && queAnsData.response.map((res, idx) => (
          <div className="notes-list" key={idx}>
            <div className="feedback-input-wrapper">
              <div className="user-image pl-2">
                <UserInfo users={res.respondedBy} />
              </div>
              <div className="agenda-text-wrapper">
                <div className="due-date">
                  { res.lastModifiedInMillis && moment(res.lastModifiedInMillis).format('MMMM Do, YYYY') || '' }
                </div>
                <RichText text={res.text} className="ql-editor" />
              </div>
            </div>

          </div>

        )) }
      </div>
    </React.Fragment>
  );
};
export default FeedbackListItem;
