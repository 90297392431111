import { css } from '@emotion/react';
import { palette, userGroupsCard } from '~Common/styles/colors';
import emptyUserGroups from '~UserGroups/assets/images/emptyUserGroups.png';

const styles = {
  emptyStateContainer: css({
    paddingTop: '1rem',
    borderTop: `0.0625rem solid ${userGroupsCard.borderColor}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  emptyStateImage: css({
    height: '23.5625rem',
    width: '26.0625rem',
  }),
  emptyStateText: css({
    color: palette.brand.indigo,
    maxWidth: '20.625rem',
  }),
};

const EmptyTableState = ({ ...props }): JSX.Element => (
  <div css={styles.emptyStateContainer} {...props}>
    <img
      css={styles.emptyStateImage}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      src={emptyUserGroups}
      alt="Empty user groups"
    />
    <div css={styles.emptyStateText}>
      You have not added any groups. Please select “Create New Group” at the top
      of the page.
    </div>
  </div>
);

export default EmptyTableState;
