import { useRef, useCallback } from 'react';

export const useHookWithRefCallback = ({ cleanupFunction, afterNodeMountFunction }) => {
  const ref = useRef(null);
  const setRef = useCallback((node) => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
      cleanupFunction?.(ref.current);
    }

    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      afterNodeMountFunction(node);
    }

    // Save a reference to the node
    ref.current = node;
  }, [cleanupFunction, afterNodeMountFunction]);

  return [setRef];
};
