import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { useTime } from '~Deprecated/hooks/useTime';

import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';

import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import IconButton from '~Common/V3/components/Buttons/IconButton';

import { useRescheduleMeeting } from '~Meetings/hooks/useRescheduleMeeting';
import { pageBackground } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';
import DateTimePicker from '../shared/DateTimePicker';

const styles = {
  container: css`
    background-color: ${pageBackground};
  `,
  drawerInput: css`
    margin: 8px 0 !important;
  `,
  timeContainer: css`
    margin: 10px 0;
    display: flex;
  `,
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

export const rescheduleMeetingTemplate = {
  name: 'RESCHEDULE_MEETING_SERIES',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton,
  dayToReschedule,
  onFormSubmit,
  createButton,
  renderDrawerInstructions,
  meetingDuration,
  disableNextMeeting,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={closeButton}
        title="Reschedule this Meeting"
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        {renderDrawerInstructions()}
        <DateTimePicker
          css={styles.drawerInput}
          label="Date"
          initialDate={dayToReschedule}
          defaultDuration={meetingDuration}
          nextMeeting={disableNextMeeting}
        />
        {createButton()}
      </div>
    )}
  />
);

View.propTypes = {
  dayToReschedule: PropTypes.instanceOf(Date).isRequired,
  disableNextMeeting: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  closeButton: PropTypes.func.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
  meetingDuration: PropTypes.number.isRequired,
};

View.defaultProps = {};

const RescheduleMeeting = ({
  id, type, ...props
}) => {
  const dispatch = useDispatch();
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const { startTimeInMillis, endTimeInMillis, nextHuddleStartTime } = meetingDetails;
  const meetingStart = moment(startTimeInMillis);
  const { date: dayToReschedule } = useTime(meetingStart, 15);
  const meetingDuration = endTimeInMillis - startTimeInMillis;

  const doReschedule = useRescheduleMeeting({ template: rescheduleMeetingTemplate });

  const disableNextMeeting = (dateVal) => {
    if (nextHuddleStartTime === null) {
      return false;
    }

    const nextMeetingStartDay = new Date(nextHuddleStartTime);
    nextMeetingStartDay.setHours(0, 0, 0, 0);

    return dateVal.valueOf() >= nextMeetingStartDay.getTime();
  };
  const renderDrawerInstructions = () => {
    const drawerText = `Rescheduling the occurence of this meeting on <b>${meetingStart.format('MMMM Do, YYYY')}</b>.`;

    return (
      <div>
        <DrawerInstructions
          html={drawerText}
        />
        <br />
      </div>
    );
  };

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const onFormSubmit = (e) => {
    const formData = new FormData(e.target);
    const rescheduledMeeting = Object.fromEntries(formData);

    doReschedule({
      type,
      lastState: meetingDetails,
      factoryId: meetingDetails?.factoryId,
      id,
      title: meetingDetails.title,
      location: meetingDetails.location,
      frequency: meetingDetails.frequency,
      endTimeInMillis: parseInt(rescheduledMeeting.endTimeInMillis, 10),
      startTimeInMillis: parseInt(rescheduledMeeting.startTimeInMillis, 10),
      daysOfWeek: meetingDetails.daysOfWeek,
      attendeeIds: meetingDetails.attendees,
      reflectInFutureInstances: false,
    });
  };

  const createButton = useCallback(() => (
    <LeadrButton
      data-test-id="meetingReschedule"
      type="submit"
    >
      Reschedule Meeting
    </LeadrButton>
  ), []);

  const hookProps = {
    closeButton,
    dayToReschedule,
    renderDrawerInstructions,
    onFormSubmit,
    createButton,
    meetingDuration,
    disableNextMeeting,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

RescheduleMeeting.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

RescheduleMeeting.defaultProps = {};

registerDrawer({
  templateName: rescheduleMeetingTemplate.name,
  component: RescheduleMeeting,
});

export { View };
export default RescheduleMeeting;
