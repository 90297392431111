import { ActionItemContext, ActionItemContextType } from '~ActionItems/const/interfaces';
import {
  faCalendarCheck,
  faBullseyeArrow,
  faBriefcase,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';

export interface AssociationData {
  icon: IconDefinition,
  text: string,
}

export const getAssociationData = (contextType?: ActionItemContext, includeTitle = false): AssociationData => {
  switch (contextType?.type) {
    case ActionItemContextType.Goal:
      return {
        icon: faBullseyeArrow,
        text: includeTitle ? `Goal - ${contextType.description}` : 'Goal',
      };
    case ActionItemContextType.Meeting:
      return {
        icon: faBriefcase,
        text: includeTitle ? `Meeting - ${contextType.description}` : 'Meeting',
      };
    default:
      return {
        icon: faCalendarCheck,
        text: 'Independent',
      };
  }
};
