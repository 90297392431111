import { css } from '@emotion/react';
import { InputBase, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

import { hexToRGBA, palette } from '~Common/styles/colors';

const styles = {
  rowSelect: css`
    background-color: ${hexToRGBA(palette.neutrals.gray50, 0.6)};
    padding: .375rem .75rem;
    border-radius: 0.5rem;
    margin-left: .3125rem;
    
    .MuiSelect-select:focus {
      background-color: ${palette.neutrals.gray50};
    }
  `,
};

const View = ({ numberOfRows, onNumberOfRowsChange, ...props }) => (
  <Select
    {...props}
    labelId="filter-select"
    id="filter-select"
    value={numberOfRows}
    onChange={onNumberOfRowsChange}
    input={
      <InputBase css={styles.rowSelect} />
    }
  >
    <MenuItem value={10}>10 Rows</MenuItem>
    <MenuItem value={20}>20 Rows</MenuItem>
    <MenuItem value={50}>50 Rows</MenuItem>
  </Select>
);

View.propTypes = {
  numberOfRows: PropTypes.number.isRequired,
  onNumberOfRowsChange: PropTypes.func.isRequired,
};

View.defaultProps = {};

const NumberOfRowSelect = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

NumberOfRowSelect.propTypes = {};

NumberOfRowSelect.defaultProps = {};

export { View };
export default NumberOfRowSelect;
