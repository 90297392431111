import { RefObject, useCallback } from 'react';
import { css } from '@emotion/react';
import { useMergedHuddleTimelineByStartTime } from '~Meetings/hooks/useHuddleTimeline';
import { withoutDesktopObject } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';
import TimelineTile, { TimelineTileSkeletonStyles, View as TimelineTileView } from '~Meetings/components/timeline/TimelineTile';
import useTimelineClick, { OnTimelineTileClick } from '~Meetings/hooks/useTimelineClick';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useHorizontalScroll } from '~Common/hooks/useHorizontalScroll';
import { useUrlQuery } from '~Common/hooks/useUrlQuery';
import { useHookWithRefCallback } from '~Common/hooks/useHookWithRefCallback';
import { MeetingTypeEnum, TimelineData } from '~Meetings/const/meetingsInterfaces';

const styles = {
  skeletonStyles: css({
    width: '100%',
  }),
  container: css({
    height: '9rem',
    overflowY: 'hidden',
    width: '100%',
  }, withoutDesktopObject({
    backgroundColor: palette.neutrals.white,
    margin: '0 auto',
  })),
  tileHolder: css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '0 .25rem',
    overflowX: 'auto',
    paddingBottom: '0.75rem',
    position: 'relative',
  }),
};
const { skeletonStyles } = styles;

type TimelineItem = [string, TimelineData];

interface ViewProps {
  renderExpander?: () => JSX.Element,
  items?: TimelineItem[],
  onTimelineTileClick: OnTimelineTileClick,
  isLoading?: boolean,
  isSelected: (huddleId: string) => boolean,
  scroller: RefObject<HTMLDivElement>
  selectedTileRef: RefObject<HTMLDivElement>
}

const View = ({
  renderExpander,
  items,
  onTimelineTileClick,
  isLoading,
  scroller,
  isSelected,
  selectedTileRef,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    {renderExpander?.()}
    {isLoading && (
      <div>
        <MultipleSkeletonLoaders
          numberOfSkeletons={5}
          css={styles.tileHolder}
          renderSkeletonItem={() => (
            <SkeletonLoader
              variant="rectangular"
              css={TimelineTileSkeletonStyles}
              renderComponent={() => (
                <TimelineTileView />
              )}
            />
          )}
        />
      </div>
    )}

    <div
      data-test-id="timelineTilesContainer"
      css={styles.tileHolder}
      ref={scroller}
    >
      {!isLoading && items?.map((item) => (
        <>
          {
            item[1].huddles.length === 1 && (
              <TimelineTile
                key={item[1].huddles[0].id}
                // @ts-expect-error: TODO: Fix when typed
                huddle={item[1].huddles[0]}
                item={item}
                onClick={onTimelineTileClick}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ref={isSelected(item[1].huddles[0].id) ? selectedTileRef : null}
                isSelected={isSelected(item[1].huddles[0].id)}
              />
            )
          }
          {
            item[1].huddles.length > 1 && item[1].huddles.map((huddle) => (
              <TimelineTile
                key={huddle.id}
                // @ts-expect-error: TODO: Fix when typed
                huddle={huddle}
                item={item}
                onClick={onTimelineTileClick}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ref={isSelected(huddle.id) ? selectedTileRef : null}
                isSelected={isSelected(huddle.id)}
              />
            ))
          }
        </>
      )) }
    </div>
  </div>
);

interface TimelineProps {
  id: string,
  type: MeetingTypeEnum,
  meetingFactoryId: string,
}

const Timeline = ({
  id,
  type,
  meetingFactoryId,
  ...props
}: TimelineProps): JSX.Element => {
  const location = useUrlQuery();
  const { onTimelineTileClick } = useTimelineClick({ id, type, meetingFactoryId });
  const { isLoading, timeline } = useMergedHuddleTimelineByStartTime({ id, type });
  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);
  const { coachingId, meetingId } = location ?? {};
  const isSelected = (huddleId: string): boolean => coachingId === huddleId || meetingId === huddleId;

  const scroller = useHorizontalScroll();
  // @ts-expect-error TODO: Fix when typed
  const [selectedTileRef] = useHookWithRefCallback({
    afterNodeMountFunction: useCallback((node: { offsetLeft?: unknown; offsetWidth?: unknown; parentNode?: unknown; }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { parentNode } = node;

      // Use scrollLeft rather than scrollIntoView so the page does not move vertically
      // @ts-expect-error TODO: Fix when typed
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      parentNode.scrollLeft = (node.offsetLeft + (node.offsetWidth / 2) - (parentNode.offsetWidth / 2));
    }, []),
  }) as unknown as [RefObject<HTMLDivElement>];

  const hookProps = {
    items: timeline,
    id,
    onTimelineTileClick,
    isLoading: showSkeletonLoaders,
    scroller,
    isSelected,
    selectedTileRef,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export { View, skeletonStyles };
export default Timeline;
