import { palette } from '~Common/styles/colors';
import { DevelopmentPlanRoutes } from '~DevelopmentPlan/routes/DevelopmentPlanRouter';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { OrgUser } from '@leadr-hr/types';
import {
  PDPStatusEnum, ResourceType, PersonalDevelopmentTypeOption, TabType,
  ModalTabs, Accomplishment, NextResource, PDPRole, ResourceToastText, CompetencyResourceStatusEnum,
  CompetencyResourceStatusType,
} from './types';

export const PERSONAL_DEVELOPMENT_MY_PLANS_PAGE_SIZE = 1000;
export const PERSONAL_DEVELOPMENT_OTHER_PLANS_PAGE_SIZE = 12;
export const COMPETENCY_RESOURCE_PAGE_SIZE = 8;
export const MEETING_LIST_PAGE_SIZE = 25;
export const TIMELINE_THRESHOLD = 5;
export const TIMELINE_ITEM_WIDTH = 1.75;
export const TIMELINE_ITEM_WIDTH_GROUPED = 3.75;
export const TIMELINE_FADE_PERCENTAGE = 0.5;
export const GOALS_PAGE_SIZE = 100;
export const RECOGNITION_PAGE_SIZE = 100;
export const ACTION_ITEMS_PAGE_SIZE = 100;
export const DEFAULT_OPACITY = 0.45;
export const OPTIMISTIC_ID = 99999999999;
export const DEFAULT_ID = 0;
export const DEFAULT_RESOURCE_TITLE = 'Resource was not saved properly';
export const DEFAULT_DATE = new Date(1986, 2, 11);
export const DUPLICATE_RESOURCE_TEXT = 'You already have this resource linked to this competency. Please select another competency.';
export const DEFAULT_TRUNCATE_TIMELINE_TITLE_LENGTH = 15;
export const DEFAULT_PDP_PATHNAME = 'development-plans';

export const personalDevelopmentStatusColor: Record<number, string> = {
  [CompetencyResourceStatusEnum.NotStarted]: palette.neutrals.gray600,
  [CompetencyResourceStatusEnum.InProgress]: palette.brand.orange,
  [CompetencyResourceStatusEnum.Complete]: palette.brand.green,
  [CompetencyResourceStatusEnum.Incompleted]: palette.brand.green,
  [CompetencyResourceStatusEnum.ToDo]: palette.neutrals.gray600,
  [CompetencyResourceStatusEnum.Blocked]: palette.brand.red,
  [CompetencyResourceStatusEnum.OnTrack]: palette.brand.green,
  [CompetencyResourceStatusEnum.AtRisk]: palette.brand.orange,
  [CompetencyResourceStatusEnum.OffTrack]: palette.brand.red,
  [CompetencyResourceStatusEnum.Completed]: palette.brand.green,
  [CompetencyResourceStatusEnum.PartiallyCompleted]: palette.brand.orange,
  [CompetencyResourceStatusEnum.Missed]: palette.brand.red,
  [CompetencyResourceStatusEnum.Cancelled]: palette.neutrals.gray600,
  [CompetencyResourceStatusEnum.Behind]: palette.brand.red,
  [CompetencyResourceStatusEnum.Caution]: palette.brand.orange,
};

export type LabelType = Record<number | string, string>;

export const PersonalDevelopmentStatusLabels = {
  [PDPStatusEnum.Active]: 'Active',
  [PDPStatusEnum.Completed]: 'Completed',
  [PDPStatusEnum.Draft]: 'Draft',
  [PDPStatusEnum.PendingReview]: 'Pending Review',
  [PDPStatusEnum.Closed]: 'Closed',
};
export const PersonalDevelopmentStatuses: PersonalDevelopmentTypeOption[] = [
  {
    value: PDPStatusEnum.Active,
    text: PersonalDevelopmentStatusLabels[PDPStatusEnum.Active],
  },
  {
    value: PDPStatusEnum.Completed,
    text: PersonalDevelopmentStatusLabels[PDPStatusEnum.Completed],
  },
  {
    value: PDPStatusEnum.Draft,
    text: PersonalDevelopmentStatusLabels[PDPStatusEnum.Draft],
  },
  {
    value: PDPStatusEnum.PendingReview,
    text: PersonalDevelopmentStatusLabels[PDPStatusEnum.PendingReview],
  },
  {
    value: PDPStatusEnum.Closed,
    text: PersonalDevelopmentStatusLabels[PDPStatusEnum.Closed],
  },
];

export const PERSONAL_DEVELOPMENT_TABS_LABELS = {
  Plan: {
    Label: 'Plans',
    Value: TabType.Plan,
  },
};

export const PERSONAL_DEVELOPMENT_TABS = [
  {
    label: PERSONAL_DEVELOPMENT_TABS_LABELS.Plan.Label,
    value: PERSONAL_DEVELOPMENT_TABS_LABELS.Plan.Value,
    toObject: {
      pathname: DevelopmentPlanRoutes?.OtherPlans,
      search: `?tab=${PERSONAL_DEVELOPMENT_TABS_LABELS.Plan.Value}`,
    },
    'data-test-id': 'personalDevelopmentTab',
  },
];

export const COMPETENCY_RESOURCE_TABS_LABELS = {
  Steps: {
    Label: 'Steps',
    Value: TabType.Steps,
  },
  Accomplishments: {
    Label: 'Accomplishments',
    Value: TabType.Accomplishments,
  },
};

export const COMPETENCY_RESOURCE_TABS = [
  {
    label: COMPETENCY_RESOURCE_TABS_LABELS.Steps.Label,
    value: COMPETENCY_RESOURCE_TABS_LABELS.Steps.Value,
    toObject: {
      pathname: DevelopmentPlanRoutes?.OtherPlans,
      search: `?tab=${COMPETENCY_RESOURCE_TABS_LABELS.Steps.Value}`,
    },
    'data-test-id': 'personalStepsTab',
  },
  {
    label: COMPETENCY_RESOURCE_TABS_LABELS.Accomplishments.Label,
    value: COMPETENCY_RESOURCE_TABS_LABELS.Accomplishments.Value,
    toObject: {
      pathname: DevelopmentPlanRoutes?.OtherPlans,
      search: `?tab=${COMPETENCY_RESOURCE_TABS_LABELS.Accomplishments.Value}`,
    },
    'data-test-id': 'personalAccomplishmentsTab',
  },
];

export const PersonalDevelopmentResourceTypeLabels = {
  [ResourceType.All]: 'All',
  [ResourceType.Goal]: 'Goal',
  [ResourceType.Learning]: 'Learning',
  [ResourceType.Feedback]: 'Feedback',
  [ResourceType.ActionItem]: 'Action Item',
  [ResourceType.Recognition]: 'Recognition',
  [ResourceType.Accomplishment]: 'Accomplishment',
  [ResourceType.Meeting]: 'Meeting',
  [ResourceType.LearningPlaylist]: 'Learning Playlist',
};

export const PersonalDevelopmentResourceTypes: PersonalDevelopmentTypeOption[] = [
  {
    value: ResourceType.Goal,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.Goal],
  },
  {
    value: ResourceType.Learning,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.Learning],
  },
  {
    value: ResourceType.LearningPlaylist,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.LearningPlaylist],
  },
  {
    value: ResourceType.Feedback,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.Feedback],
  },
  {
    value: ResourceType.ActionItem,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.ActionItem],
  },
  {
    value: ResourceType.Recognition,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.Recognition],
  },
  {
    value: ResourceType.Accomplishment,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.Accomplishment],
  },
  {
    value: ResourceType.Meeting,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.Meeting],
  },
];

export const PersonalDevelopmentAccomplishmentTypes: PersonalDevelopmentTypeOption[] = [
  {
    value: ResourceType.Accomplishment,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.Accomplishment],
  },
  {
    value: ResourceType.Recognition,
    text: PersonalDevelopmentResourceTypeLabels[ResourceType.Recognition],
  },
];
export const DEFAULT_USER = {
  orgUserId: getOrganizationUserId() ?? '',
  firstName: '',
  lastName: '',
  profileImageUrl: '',
  jobTitle: '',
};
export const DEFAULT_COMPETENCY = {
  id: 0,
  name: '',
  description: '',
  createdBy: DEFAULT_USER,
  createdDate: new Date(),
  modifiedDate: new Date(),
  isDeleted: false,
};

export const DEFAULT_COMMENT = {
  id: 0,
  content: '',
  createdDate: new Date(),
  modifiedDate: new Date(),
  isDeleted: false,
  createdBy: DEFAULT_USER,
  isSystemGenerated: false,
  isApprovalComment: false,
  isFinalThought: false,
  pdpId: 0,
};

export const DEFAULT_STATUS = {
  id: PDPStatusEnum.Draft,
  description: 'Draft',
};

const currentDate = new Date();
const futureDate = new Date();
futureDate.setDate(currentDate.getDate() + 90);

export const DEFAULT_PDP = {
  id: 0,
  name: '',
  summary: '',
  startDate: currentDate,
  endDate: futureDate,
  mentor: undefined as unknown as OrgUser,
  status: DEFAULT_STATUS,
  owner: DEFAULT_USER,
  milestonesCompleted: 0,
  milestonesTotal: 0,
  isCompleted: false,
  role: {} as PDPRole,
  nextResource: {} as NextResource,
  viewers: [],
  createdDate: currentDate,
  modifiedDate: currentDate,
  isDeleted: false,
  createdBy: DEFAULT_USER,
  competencies: [DEFAULT_COMPETENCY],
  permissions: [],
};

export const DEFAULT_RESOURCE_COMPETENCY = {
  competencyId: 0,
  contentTypeId: 1,
  contentId: '',
};

export const modalTabs: ModalTabs = {
  [ResourceType.All]: {
    New: {
      value: 0,
      label: 'Not Found',
    },
    Existing: {
      value: 1,
      label: 'Not Found',
    },
  },
  [ResourceType.Goal]: {
    New: {
      value: 0,
      label: 'New Goal',
    },
    Existing: {
      value: 1,
      label: 'Existing Goal',
    },
  },
  [ResourceType.Learning]: {
    New: {
      value: 0,
      label: 'New Learning',
    },
    Existing: {
      value: 1,
      label: 'Existing Learning',
    },
  },
  [ResourceType.Feedback]: {
    New: {
      value: 0,
      label: 'New Feedback',
    },
    Existing: {
      value: 1,
      label: 'Existing Feedback',
    },
  },
  [ResourceType.ActionItem]: {
    New: {
      value: 0,
      label: 'New Action Item',
    },
    Existing: {
      value: 1,
      label: 'Existing Action Item',
    },
  },
  [ResourceType.Recognition]: {
    New: {
      value: 0,
      label: 'New Recognition',
    },
    Existing: {
      value: 1,
      label: 'Existing Recognition',
    },
  },
  [ResourceType.Accomplishment]: {
    New: {
      value: 0,
      label: 'New Accomplishment',
    },
    Existing: {
      value: 1,
      label: 'Existing Accomplishment',
    },
  },
  [ResourceType.Meeting]: {
    New: {
      value: 0,
      label: 'New Meeting',
    },
    Existing: {
      value: 1,
      label: 'Existing Meeting',
    },
  },
  [ResourceType.LearningPlaylist]: {
    New: {
      value: 0,
      label: 'New Learning Playlist',
    },
    Existing: {
      value: 1,
      label: 'Existing Learning Playlist',
    },
  },
};

export const DEFAULT_ACCOMPLISHMENT: Accomplishment = {
  id: 0,
  date: new Date(),
  title: '',
  description: '',
  orgUserId: '',
};

export const linkingResourceText: ResourceToastText = {
  [ResourceType.ActionItem]: {
    error: 'An error occurred while attaching the action item to your plan.',
    success: 'The action item was successfully added to your plan.',
  },
  [ResourceType.Learning]: {
    error: 'An error occurred while attaching the learning assignment to your plan.',
    success: 'The learning assignment was successfully added to your plan.',
  },
  [ResourceType.LearningPlaylist]: {
    error: 'An error occurred while attaching the learning assignment to your plan.',
    success: 'The learning assignment was successfully added to your plan.',
  },
  [ResourceType.Meeting]: {
    error: 'An error occurred while attaching the meeting to your plan.',
    success: 'Your meeting was successfully added to your plan.',
  },
  [ResourceType.Feedback]: {
    error: 'An error occurred while attaching the feedback to your plan.',
    success: 'The feedback was successfully added to your plan.',
  },
  [ResourceType.Goal]: {
    error: 'An error occurred while attaching the goal to your plan.',
    success: 'The goal was successfully added to your plan.',
  },
  [ResourceType.Recognition]: {
    error: 'An error occurred while attaching the recognition to your plan.',
    success: 'The recognition was successfully added to your plan.',
  },
  [ResourceType.Accomplishment]: {
    error: 'An error occurred while attaching your accomplishment to your plan.',
    success: 'The accomplishment was successfully added to your plan.',
  },
};

export const CompetencyResourceStatuses = {
  [CompetencyResourceStatusType.InProgress]: {
    id: CompetencyResourceStatusEnum.InProgress,
    description: CompetencyResourceStatusType.InProgress,
  },
  [CompetencyResourceStatusType.Complete]: {
    id: CompetencyResourceStatusEnum.Complete,
    description: CompetencyResourceStatusType.Complete,
  },
};

// Using this as I can see this growing over time so wanted to make it global
export const CompletedStatuses = [
  CompetencyResourceStatusType.Complete,
  CompetencyResourceStatusType.Completed,
];
