import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { recognitionKeys } from '~Recognition/const/queryKeys';
import { RecognitionShape } from '~Recognition/const/types';
import { buildQueryString } from '~Common/utils';
import { useMemo } from 'react';
import {
  createInfiniteFlattener,
  DEFAULT_QUERY_OPTIONS_RECOGNITION,
} from '~Common/hooks/utils';
import {
  RECOGNITION_DEFAULT_DEPARTMENT, RECOGNITION_DEFAULT_USER_GROUP, RECOGNITION_PAGE_SIZE,
} from '~Recognition/const/defaults';
import { getStartAndEndDates } from '~Recognition/const/functions';

interface RecognitionFlattenerProps {
  ids: number[],
  data: RecognitionShape[],
}

export interface RecognitionListResponse {
  items: RecognitionShape[],
}

const recognitionFlattener:
  (
    result: UseInfiniteQueryResult<InfiniteData<HttpCallReturn<RecognitionListResponse>, unknown>, Error>
  ) => RecognitionFlattenerProps = createInfiniteFlattener({
    idName: 'recognitionId',
    listSelector: (item: { response: { items: RecognitionShape[]; }; }) => item.response?.items,
  });
export interface GetRecognitionParams {
  take?: number,
  department?: string,
  search?: string,
  userGroup?: string,
  startDate?: string,
  endDate?: string,
  pageParam: number,
  recipientOrgUserIds?: string[],
}

const getRecognitionList = async ({
  pageParam,
  department,
  search,
  userGroup,
  startDate,
  endDate,
  recipientOrgUserIds,
  take,
}: GetRecognitionParams): Promise<HttpCallReturn<RecognitionListResponse>> => {
  const { returnedStartDate, returnedEndDate } = getStartAndEndDates(startDate, endDate);

  const recognitionQueryParams = {
    skip: pageParam || 0,
    take: take ?? RECOGNITION_PAGE_SIZE,
    department: department !== RECOGNITION_DEFAULT_DEPARTMENT.value ? department : undefined,
    search: search !== '' ? search : undefined,
    userGroup: userGroup !== RECOGNITION_DEFAULT_USER_GROUP.value ? userGroup : undefined,
    startDate: returnedStartDate,
    endDate: returnedEndDate,
    recipientOrgUserIds,
  };

  const queryString = buildQueryString(recognitionQueryParams);
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/recognition${queryString}`;

  return getApi<RecognitionListResponse>(serverUrl);
};

interface UseGetRecognitionListResponse {
  data: RecognitionShape[],
  result: UseInfiniteQueryResult<HttpCallReturn<RecognitionListResponse>, Error>,
  initialPageItemCount: number,
  ids: number[],
  isLoading: boolean,
  isFetching: boolean,
  dataValues: RecognitionShape[],
}

export const useGetRecognition = ({
  take,
  department,
  search,
  userGroup,
  startDate,
  endDate,
  recipientOrgUserIds,
}: Omit<GetRecognitionParams, 'pageParam'>): UseGetRecognitionListResponse => {
  const result = useInfiniteQuery({
    queryKey: recognitionKeys.list({
      take,
      department,
      search,
      userGroup,
      startDate,
      endDate,
      recipientOrgUserIds,
    }),
    queryFn: ({ pageParam }: { pageParam: number }) => (
      getRecognitionList({
        take,
        department,
        search,
        userGroup,
        startDate,
        endDate,
        recipientOrgUserIds,
        pageParam,
      })
    ),
    initialPageParam: 0,
    ...DEFAULT_QUERY_OPTIONS_RECOGNITION,
  });

  const { ids, data } = useMemo(() => recognitionFlattener(result), [result]);
  const initialPageItemCount = result?.data?.pages?.[0]?.response?.items.length ?? 0;
  const dataValues = Object.values(data).map((item) => item);

  // TODO: Remove this once the backend is fixed - This kills me that it exists
  const memoizedFilterAndTransform = useMemo(() => {
    const filterByRecipients = Object.values(data).filter(
      (recognition) => recognition.recipients.every((value) => value !== null),
    );
    const transformedData = filterByRecipients.reduce(
      (acc, curr) => {
        const { recognitionId } = curr;
        return {
          ...acc,
          [recognitionId]: curr,
        };
      },
      {},
    );
    return transformedData;
  }, [data]);

  const filteredResult = useMemo(() => result?.data?.pages
    .map((page) => page.response.items)
    .flat()
    .filter((item) => item.recipients.every((value) => value !== null)), [result]);

  const updatedResult = useMemo(() => ({
    ...result,
    data: filteredResult
      ? {
        ...result.data,
        pages: result?.data?.pages.map((page) => ({
          ...page,
          response: {
            ...page.response,
            items: filteredResult,
          },
        })),
      }
      : result.data,
  }), [result, filteredResult]);

  const idsFiltered = useMemo(() => ids.filter((id) => filteredResult?.some((obj) => obj.recognitionId === id)), [filteredResult, ids]);
  const filteredDataValues = useMemo(() => dataValues.filter((item) => item.recipients.every((value) => value !== null)), [dataValues]);

  return {
    result: updatedResult as unknown as UseInfiniteQueryResult<HttpCallReturn<RecognitionListResponse>>,
    ids: idsFiltered,
    data: memoizedFilterAndTransform as RecognitionShape[],
    initialPageItemCount,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    dataValues: filteredDataValues,
  };
};
