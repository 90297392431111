import { ComponentProps } from 'react';
import {
  buildButtonStyles, buttonColor, buttonSize, buttonVariant,
} from '../../styles/Button';

export interface ButtonProps extends ComponentProps<'button'> {
  renderContents: () => JSX.Element,
  variant?: buttonVariant
  size?: buttonSize,
  color?: buttonColor,
}

const Button = ({
  renderContents, variant, size, color, ...props
}: ButtonProps): JSX.Element => (
  <button css={buildButtonStyles({ color, size, variant })} type="button" {...props}>
    {renderContents()}
  </button>
);

export default Button;
