import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const styles = {
  wrapper: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  }),
  child: css({
    maxWidth: '33%',
  }),
  noteArea: css({
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
    maxWidth: '25%',
  }),
  leadrButton: css({
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    color: `${palette.brand.indigo} !important`,

    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      color: `${palette.brand.indigo} !important`,
    },
  }),
  title: css({
    fontSize: '1.5rem',
    fontWeight: 600,
    color: palette.brand.indigo,
    marginBottom: '.25rem',
  }),
  content: css({
    fontSize: '.875rem',
    color: palette.neutrals.gray700,
  }),
  skellyHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',

    '& > *': {
      width: '15%',
    },
    '& > :first-of-type': {
      width: '30%',
    },
  }),
  learnMoreLink: css({
    color: palette.brand.indigo,
    textDecoration: 'none',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  }),
};

interface ReflectionsHeaderProps {
  isLoading: boolean,
}

const ReflectionsHeader = ({
  isLoading,
}: ReflectionsHeaderProps): JSX.Element => (
  <>
    <div css={styles.wrapper}>
      {isLoading && (
      <>
        <div css={styles.child}>
          <Stack spacing={1}>
            <Skeleton animation="wave" variant="rectangular" height={20} width={150} />
            <Skeleton animation="wave" variant="rectangular" height={42} width={320} />
          </Stack>
        </div>
        <div css={[styles.child, styles.noteArea]}>
          <Skeleton animation="wave" variant="rectangular" height={42} width={320} />
        </div>
      </>
      )}
      {!isLoading && (
        <>
          <div css={styles.child}>
            <h1 css={styles.title}>
              Reflections
            </h1>
            <p css={styles.content}>
              Access content from the last 18 months. Favorite topics you&rsquo;d like to easily access during a Review.
              {' '}
              <a
                css={styles.learnMoreLink}
                href="https://leadr.helpscoutdocs.com/article/52-reflections-using-reflections-in-leadr"
                target="_blank"
                rel="noreferrer"
              >
                Learn more about Reflections
              </a>
            </p>
          </div>
          <div css={[styles.child, styles.noteArea]}>
            <p>
              <strong>Please note</strong>
              : Agenda topics from Meetings are added manually by you. If unchecked, topics will stay until 18 months have passed.
            </p>
          </div>
        </>
      )}
    </div>
  </>
);

export default ReflectionsHeader;
