import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';

import { surveyQuestionPreviewColors } from '~Common/styles/colors';
import { useGetMarksMemoized } from '~Surveys/Hooks/useGetMarksMemoized';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { withTruncate } from '~Common/styles/mixins';
import QuestionPreview from '.';
import SurveyScale from '../../Respond/SurveyScale';

const styles = {
  questionText: css({
    fontSize: '1.125rem',
    fontWeight: 'bold',
    marginBottom: '0.1875rem',
  }, withTruncate()),
  description: css({
    marginBottom: '0.625rem',
  }),
  slider: css`
    width: 90% !important;
    color: ${surveyQuestionPreviewColors.slider} !important;
    margin-bottom: .625rem;
  `,
};

const View = ({
  question,
  description,
  marks,
  maxValue,
  minLabel,
  maxLabel,
  ...props
}) => (
  <QuestionPreview
    renderBody={() => (
      <div {...props}>
        <div css={styles.questionText}>{question}</div>
        {description && (
          <HTMLRenderer css={styles.description} htmlText={description} />
        )}
        <SurveyScale
          marks={marks}
          minLabel={minLabel}
          maxLabel={maxLabel}
          initialValue={1}
          disabled
        />
      </div>
    )}
  />
);

View.propTypes = {
  question: PropTypes.string,
  description: PropTypes.string,
  marks: PropTypes.arrayOf(PropTypes.objectOf({
    value: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  maxValue: PropTypes.number,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
};

View.defaultProps = {
  question: '',
  description: '',
  maxValue: 3,
  minLabel: '',
  maxLabel: '',
};

const RatingPreview = ({
  question, ...props
}) => {
  const { minLabel, maxValue, maxLabel } = question;

  const marks = useGetMarksMemoized({ maxValue });

  const hookProps = {
    marks,
    maxValue,
    minLabel,
    maxLabel,
    ...question,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

RatingPreview.propTypes = {
  question: SURVEY_QUESTION_SHAPE,
};

RatingPreview.defaultProps = {
  question: {},
};

export { View };
export default RatingPreview;
