import { css } from '@emotion/react';
import { useMemo, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import moment from 'moment-timezone';
import { MeetingInfoContext } from '../../../providers/MeetingInfoContext';

const styles = {
  startTimeText: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
  icon: css({
    marginRight: '.375rem',
    width: '.75rem',
    height: '.75rem',
  }),
};

interface ViewProps {
  meetingStartTimeText: string,
}

const View = ({
  meetingStartTimeText,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.startTimeText}
    {...props}
  >
    <FontAwesomeIcon
      css={styles.icon}
      icon={faCalendar}
    />
    <span>{meetingStartTimeText}</span>
  </div>
);

const StartTimeText = ({
  ...props
}): JSX.Element => {
  const { startTimeInMillis } = useContext(MeetingInfoContext);

  const { timezone } = useTimezone();

  const meetingStartTimeText = useMemo(() => (
    moment.tz(startTimeInMillis, timezone).format('MMMM Do, YYYY @ h:mm a')
  ), [startTimeInMillis, timezone]);

  const hookProps = {
    meetingStartTimeText,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default StartTimeText;
