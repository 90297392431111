/* eslint-disable no-bitwise */
/* eslint-disable max-len */
import { css } from '@emotion/react';
import { REVIEW_SETUP_LAYOUT, FORM_STYLES, PAGE_STYLES } from '~Reviews/V2/Const/pageStyles';
import ListSection from '~Common/V3/components/ListSection';
import Tooltip from '~Common/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCalendarDays } from '@fortawesome/pro-light-svg-icons';
import { DEFAULT_CYCLE } from '~Reviews/V2/Const/defaults';
import {
  CycleShape, CycleTypeEnum, ParticipantTypeEnum, ReviewCycleStatusEnum, ViewCyclePerspective,
} from '~Reviews/V2/Const/types';

import {
  Form,
  TextField,
  MultiRadio,
  Checkbox,
} from '~Common/V3/components/uncontrolled';
import DatePicker from '~Common/V3/components/DatePicker';
import { ContextButtons } from '~Reviews/V2/Shared/ContextButtons';

import {
  useForm,
  UseFormReturn,
  SubmitHandler,
} from 'react-hook-form';
import { useCreateCycle } from '~Reviews/V2/Hooks/useCreateCycle';
import { BASE_ROUTE, REVIEWS_CONTINUE_CREATING_CYCLE } from '~Common/const/routes';
import { navigateAction } from '~Deprecated/actions/navigate';
import { useDispatch } from 'react-redux';
import { palette } from '~Common/styles/colors';
import Froala from '~Common/V3/components/Froala';
import moment from 'moment';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useCallback } from 'react';
import { useUpdateCycle } from '../../Hooks/useUpdateCycle';
import {
  createCycleFormResolver,
  FormValues,
  conformToDto,
} from '../../schemata/CreateCycleSchemata';
import AdminVisibility from './AdminVisibility';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...FORM_STYLES,
  ...PAGE_STYLES,
  checkboxLabelOverride: css({
    paddingLeft: '0',
    '& + span': {
      fontSize: '1rem',
      color: palette.neutrals.gray700,
      fontWeight: '500',
    },
  }),
  inputContainer: css({
    marginTop: '0 !important',
  }),
  adminVisiblity: css({
    marginBottom: '1.5rem',
  }),
};

interface ViewProps {
  formContext: UseFormReturn<FormValues>,
  cycle: CycleShape,
  onFormSubmit: SubmitHandler<FormValues>,
  cycleDescription: string,
  cycleIsNotDraft: boolean,
  currentDate: moment.Moment,
  isMobile: boolean,
  handleSelectedAdminsChange: (value: string[]) => void,
}

const View = ({
  formContext,
  onFormSubmit,
  cycle,
  cycleDescription,
  cycleIsNotDraft,
  currentDate,
  isMobile,
  handleSelectedAdminsChange,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="contextButtons"
      renderContents={() => (
        <>
          {cycleIsNotDraft && (
            <LeadrButton
              css={styles.adminWrapperButton}
              onClick={formContext.handleSubmit(onFormSubmit)}
              data-test-id="reviewsUpdateCycle"
            >
              Update Cycle
            </LeadrButton>
          )}
          {!cycleIsNotDraft && (
            <LeadrButton
              css={styles.adminWrapperButton}
              onClick={formContext.handleSubmit(onFormSubmit)}
              data-test-id="reviewsSetupSectionSaveAndContinue"
            >
              Save & Continue
            </LeadrButton>
          )}
        </>
      )}
    />
    <div css={[styles.setupContainer, styles.boxShadow]}>
      <ListSection
        css={styles.sectionHeader}
        renderContents={() => (
          <div>Create Review Cycle</div>
        )}
      />
      <Form
        formContext={formContext}
        onSubmit={() => null}
      >
        <div
          css={styles.formControl}
        >
          <div
            css={[styles.columnWrap]}
          >
            <TextField
              css={[styles.inputField, styles.fullInputField]}
              name="name"
              label="Review Cycle Name"
              required
            />
            <span
              data-test-id="fauxReviewNameField"
              css={styles.hideFauxInput}
            >
              {formContext.watch('name')}
            </span>
            <Froala
              styleOverrides={styles.froalaDescriptionField}
              enableEmojis
              label="Review Cycle Description"
              name="description"
              froalaConfigProps={{
                charCounterCount: true,
                charCounterMax: 2000,
              }}
              richTextEditorProps={{
                name: 'description',
                onChange: ({ value: newText }) => formContext.setValue('description', newText, { shouldDirty: true }),
                initialValue: cycleDescription,
              }}
            />
          </div>
          <MultiRadio
            name="typeEnum"
            disabled={cycleIsNotDraft}
            defaultValue={cycle?.typeEnum.toString() ?? '1'}
            label="SELECT Cycle type"
            vertical={isMobile}
            radios={[
              {
                name: (
                  <p>
                    <span>Open Review</span>
                    {' '}
                    <p>
                      The manager
                      {' '}
                      <strong>can see</strong>
                      {' '}
                      the reviewee&lsquo;s responses before submitting their own responses
                    </p>
                  </p>
                ),
                value: CycleTypeEnum.Open,
              },
              {
                name: (
                  <p>
                    <span>Closed Review</span>
                    {' '}
                    <p>
                      The manager
                      {' '}
                      <strong>cannot see</strong>
                      {' '}
                      the reviewee&lsquo;s responses until the manager submits their own responses
                    </p>
                  </p>
                ),
                value: CycleTypeEnum.Closed,
              },
            ]}
          />
          <AdminVisibility
            css={styles.adminVisiblity}
            onSelectedAdminsChange={handleSelectedAdminsChange}
            initialSelectedAdminIds={formContext.getValues('allowedAdminOrgUserIds')}
          />
          <div
            css={styles.checkboxWrap}
          >
            <Checkbox
              name="includeMentor"
              label="Allow Secondary Managers to Contribute to Reviews"
              defaultChecked={formContext.getValues('includeMentor')}
              css={styles.checkboxLabelOverride}
              disabled={cycleIsNotDraft}
              data-test-id="reviewsIncludeMentor"
              size={20}
            />
            <Tooltip
              content="Secondary managers will be able to respond to questions specified in the questions tab and get notified about reviews they need to complete."
            >
              <div><FontAwesomeIcon css={styles.neutralIcon} icon={faInfoCircle} /></div>
            </Tooltip>
          </div>
          <div
            css={[styles.columnWrap]}
          >
            <span
              css={styles.labelTitle}
            >
              Review Period
            </span>
            <DatePicker
              required
              initialDate={cycle?.assessmentPeriodStart}
              onDateSelected={({ date: newDate }) => formContext.setValue('assessmentPeriodStart', newDate?.toDate() ?? new Date())}
              renderLabel={() => (
                <div css={styles.datePickerLabel}>
                  <div>Start Date</div>
                  <FontAwesomeIcon icon={faCalendarDays} />
                </div>
              )}
              name="assessmentPeriodStart"
              disablePast={false}
              css={[styles.inputField, styles.datePicker, styles.removeMargin]}
            />
            <DatePicker
              required
              initialDate={cycle?.assessmentPeriodEnd}
              // @ts-expect-error Typing is weird here - This date picker needs to be fixed
              minDate={moment(formContext.watch('assessmentPeriodStart')).add(1, 'day')}
              onDateSelected={({ date: newDate }) => formContext.setValue('assessmentPeriodEnd', newDate?.toDate() ?? new Date())}
              renderLabel={() => (
                <div css={styles.datePickerLabel}>
                  <div>End Date</div>
                  <FontAwesomeIcon icon={faCalendarDays} />
                </div>
              )}
              name="assessmentPeriodEnd"
              disablePast={false}
              css={[styles.inputField, styles.datePicker, styles.removeMargin]}
            />
            <p css={styles.description}>
              Describes the period of time that review participants should consider in this review cycle. (e.g. - Oct 1st - Dec 31st)
            </p>
          </div>
          <div
            css={[styles.columnWrap]}
          >
            <span
              css={styles.labelTitle}
            >
              Assessment Deadline
            </span>
            <DatePicker
              required
              // @ts-expect-error Typing is weird here - This date picker needs to be fixed
              minDate={currentDate}
              initialDate={cycle?.assessmentDueDate}
              onDateSelected={({ date: newDate }) => formContext.setValue('assessmentDueDate', newDate?.toDate() ?? new Date())}
              renderLabel={() => (
                <div css={styles.datePickerLabel}>
                  <div>Due by</div>
                  <FontAwesomeIcon icon={faCalendarDays} />
                </div>
              )}
              name="assessmentDueDate"
              disablePast
              css={[styles.inputField, styles.datePicker, styles.removeMargin, styles.fullInputField]}
            />
            <p css={styles.description}>
              Specify a date by which all parties should complete their assessments. This will be used in email reminders, notifications, and when “overdue” is determined.
            </p>
          </div>
          <div
            css={[styles.columnWrap]}
          >
            <span
              css={styles.labelTitle}
            >
              Review Sign-off Deadline
            </span>
            <DatePicker
              required
              // @ts-expect-error Typing is weird here - This date picker needs to be fixed
              minDate={currentDate}
              initialDate={cycle?.signOffDueDate}
              onDateSelected={({ date: newDate }) => formContext.setValue('signOffDueDate', newDate?.toDate() ?? new Date())}
              renderLabel={() => (
                <div css={styles.datePickerLabel}>
                  <div>Due by</div>
                  <FontAwesomeIcon icon={faCalendarDays} />
                </div>
              )}
              name="signOffDueDate"
              disablePast
              css={[styles.inputField, styles.datePicker, styles.removeMargin, styles.fullInputField]}
            />
            <p css={styles.description}>
              Specify a date by which all parties should sign-off on the review. This will be used in email reminders, notifications, and when “overdue” is determined.
            </p>
          </div>
        </div>
      </Form>
    </div>
  </>
);

interface SetupSectionProps {
  cycle: CycleShape,
  cycleId: string,
}

const SetupSection = ({
  cycle,
  cycleId,
}: SetupSectionProps): JSX.Element => {
  const dispatch = useDispatch();
  const cycleDescription = cycle?.description ?? '';
  const isMobile = useIsMobileQuery();
  // Have to do [cycle.statusEnum !== undefined] since we're checking against the enum here -> cycle.statusEnum simply won't work
  const cycleIsNotDraft = cycle.statusEnum !== ReviewCycleStatusEnum.Draft && cycle.statusEnum !== ReviewCycleStatusEnum.Unknown && cycle.statusEnum !== undefined;
  function defaultValues(): FormValues {
    const {
      assessmentPeriodStart,
      assessmentPeriodEnd,
      assessmentDueDate,
      signOffDueDate,
      name,
      description,
      participationEnum,
      typeEnum,
      allowedAdminOrgUserIds,
    } = cycle ?? DEFAULT_CYCLE;

    return {
      assessmentPeriodStart,
      assessmentPeriodEnd,
      assessmentDueDate,
      signOffDueDate,
      name,
      description,
      participationEnum,
      typeEnum,
      includeMentor: (cycle.participationEnum & ParticipantTypeEnum.SecondaryReviewer) === ParticipantTypeEnum.SecondaryReviewer,
      allowedAdminOrgUserIds,
    };
  }

  const formContext = useForm<FormValues>({
    defaultValues: defaultValues(),
    resolver: createCycleFormResolver,
  });

  const { mutate: createCycleMutation } = useCreateCycle();

  const updateCycleMutation = useUpdateCycle({ cycleId });

  const onSuccess = (thisCycleId: string): void => {
    if (cycleIsNotDraft) {
      dispatch(navigateAction({
        pathname: `${BASE_ROUTE}reviews/admin/cycle/${thisCycleId}`,
        params: {
          cycleId: thisCycleId,
        },
      }));
    } else {
      dispatch(navigateAction({
        pathname: `${REVIEWS_CONTINUE_CREATING_CYCLE}/${ViewCyclePerspective.Create_Questions}`,
        params: {
          cycleId: thisCycleId,
        },
      }));
    }
  };

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    const formData = conformToDto(data);
    if (cycleId) {
      updateCycleMutation({ cycleId, cycle: formData }, { onSuccess: () => onSuccess(cycleId) });
    } else {
      createCycleMutation({ cycle: formData }, { onSuccess: ({ response }) => onSuccess(response) });
    }
  };

  const currentDate = moment(new Date());

  const handleSelectedAdminsChange = useCallback((value: string[]): void => {
    formContext.setValue('allowedAdminOrgUserIds', value);
  }, [formContext]);

  const hookProps = {
    formContext,
    onFormSubmit,
    cycle,
    cycleId,
    cycleDescription,
    cycleIsNotDraft,
    currentDate,
    isMobile,
    handleSelectedAdminsChange,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, SetupSection };
export default SetupSection;
