import { useParams } from 'react-router';
import { ListDashboardSkeleton } from '~Common/components/Loader/ListDashboard';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { ListSectionSkeleton } from '~Common/V3/components/ListSection';
import { useMemo } from 'react';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '../Const/pageStyles';
import { ReviewCycleDetail } from '../Const/types';
import { useDebouncedSearch } from '../Hooks/useDebouncedSearch';
import { useGetReviews } from '../Hooks/useGetReviews';
import ReviewCycleLayout from '../Layouts/ReviewCycle';
import { CycleDetailsTopBar } from '../TopBar/CycleDetailsTopBar';
import ReviewsPermissionDenied from './ReviewsPermissionDenied';
import { useGetCycleById } from '../Hooks/useGetCycleById';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
};

interface ViewProps {
  showSkeleton: boolean,
  onSearch: (newValue: string) => void,
  reviewCycleToUse?: ReviewCycleDetail,
  searchText: string,
}

export const View = ({
  showSkeleton,
  onSearch,
  reviewCycleToUse,
  searchText,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    {showSkeleton && (
      <>
        <div css={[styles.topBar]}>
          <div css={styles.leftSide(true)}>
            <MultipleSkeletonLoaders
              css={styles.topBarSkellyLoader}
              numberOfSkeletons={1}
              renderSkeletonItem={() => (
                <CardSkeleton css={styles.cardSkeleton} />
              )}
            />
          </div>
        </div>
        <ListSectionSkeleton css={styles.listSectionSkeleton} />
        <ListDashboardSkeleton
          amountOfLoaders={12}
        />
      </>
    )}
    {!showSkeleton && (
      <>
        {(!!reviewCycleToUse) && (
        <>
          <CycleDetailsTopBar
            onSearch={onSearch}
            searchString={searchText}
          />
          <ReviewCycleLayout
            activeReviewCycle={reviewCycleToUse}
            searchText={searchText}
            showManagerView
          />
        </>
        )}
        {!reviewCycleToUse && (
          <ReviewsPermissionDenied />
        )}
      </>
    )}
  </div>
);

interface ReviewDetailsParams {
  cycleId: string,
}
export const ReviewCycleManagerDetails = (): JSX.Element => {
  const { cycleId } = useParams<ReviewDetailsParams>();
  const { data: reviews, isLoading } = useGetReviews({});
  const hasReviewCycle = useMemo(() => reviews?.find((review) => review.uid === cycleId), [reviews, cycleId]);

  const { isAdmin } = useUserPermissions();
  const { data: reviewCycle } = useGetCycleById({ id: cycleId, isAdmin });

  const { searchText, onSearch } = useDebouncedSearch();
  const [showSkeleton] = useSkeletonLoaders(isLoading);

  const reviewCycleToUse = isAdmin && !hasReviewCycle && reviewCycle ? reviewCycle as unknown as ReviewCycleDetail : hasReviewCycle;

  const hookProps = {
    showSkeleton,
    onSearch,
    reviewCycleToUse,
    searchText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
