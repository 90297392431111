import { useMemo } from 'react';
import { sortBy, intersection, every } from 'lodash';
import { User } from '~Common/const/interfaces';
import { AdminTab } from '~Admin/const/defaults';
import { FilterType } from '~Common/const/drawers';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';

export const FILTER_SEPARATOR = '^';

function separateUsers(allUsers: User[]): Record<string, User[]> {
  const activeUsers: User[] = [];
  const invitedUsers: User[] = [];
  const importedUsers: User[] = [];
  const deactivatedUsers: User[] = [];

  allUsers.forEach((user) => {
    switch (user.administrativeStatus) {
      case 'ACTIVE':
        activeUsers.push(user);
        break;
      case 'CREATED':
        importedUsers.push(user);
        break;
      case 'INVITED':
        invitedUsers.push(user);
        break;
      case 'INACTIVE':
      default:
        deactivatedUsers.push(user);
        break;
    }
  });

  return {
    activeUsers, invitedUsers, importedUsers, deactivatedUsers,
  };
}

function checkIfFilterIsInArray(filters: string | string[]): string[] {
  let filterArray;
  if (!Array.isArray(filters)) {
    filterArray = [filters];
  } else {
    filterArray = filters;
  }

  return filterArray;
}

function makeFilteredUsers(allUsers: User[], filters: string[]): User[] {
  const filterArray = checkIfFilterIsInArray(filters);
  const filteredUsers: User[] = [];
  const splitFilters: Array<[FilterType, string]> = filterArray.map((filter) => filter.split(FILTER_SEPARATOR) as [FilterType, string]);
  const validDepartments: string[] = [];
  const validUserGroups: string[] = [];
  const validAccountTypes: string[] = [];

  splitFilters.forEach(([type, value]) => {
    if (type === FilterType.Department) {
      validDepartments.push(value);
    } else if (type === FilterType.UserGroup) {
      validUserGroups.push(value);
    } else if (type === FilterType.Account) {
      validAccountTypes.push(value);
    }
  });

  allUsers.forEach((user) => {
    if (validAccountTypes.length) {
      if (validAccountTypes.includes(user.userGroupId)) {
        // Good, do nothing.
      } else {
        // Bad, they're out.
        return;
      }
    }
    if (validDepartments.length) {
      if (validDepartments.includes('No Department') && (!user.department || user.department === '')) {
        // Good, do nothing.
      } else if (validDepartments.includes(user.department)) {
        // Good, do nothing.
      } else {
        // Bad, they're out.
        return;
      }
    }

    if (!every(splitFilters, ([type, value]) => {
      if (type === FilterType.Manager && value !== user.managerId) return false;
      return true;
    })) {
      return;
    }

    if (
      validUserGroups.length && !intersection(user.teams.map((team) => team.teamId), validUserGroups).length
    ) {
      return;
    }

    filteredUsers.push(user);
  });
  // We want to get the index of Leadr Coach and remove it from the list so users can't edit/remove it.
  const indexofLeadrCoach = filteredUsers.findIndex((user) => user.firstName === 'Leadr' && user.lastName === 'Coach');
  // Now we remove it from the list if it exists.
  if (indexofLeadrCoach >= 0 && indexofLeadrCoach <= filteredUsers.length) {
    filteredUsers.splice(indexofLeadrCoach, 1);
  }

  return filteredUsers;
}
export const useFilterUsers = (expandedTab: AdminTab, allUsers: User[]): User[] => {
  const [filters] = useQueryParamState<string[]>('admin', 'filters', []);

  const allFilteredUsers = useMemo(
    () => makeFilteredUsers(sortBy(allUsers, 'firstName'), filters),
    [allUsers, filters],
  );

  const {
    activeUsers, invitedUsers, importedUsers, deactivatedUsers,
  } = useMemo(
    () => separateUsers(allFilteredUsers),
    [allFilteredUsers],
  );

  const filteredUsers = useMemo(
    (): User[] => {
      switch (expandedTab) {
        case AdminTab.Active:
          return activeUsers;
        case AdminTab.Deactivated:
          return deactivatedUsers;
        case AdminTab.Imported:
          return importedUsers;
        case AdminTab.Invited:
          return invitedUsers;
        default:
          return [];
      }
    },
    [activeUsers, deactivatedUsers, expandedTab, importedUsers, invitedUsers],
  );

  return filteredUsers;
};
