import { useMemo } from 'react';
import { Person } from '~Common/const/interfaces';
import _, { uniqBy } from 'lodash';
import { defaultDirectReportFilter } from '../const/defaults';
import { ReflectionsItem } from '../types';

export const useGetDirectReportsFilterItems = (userIds: string[], userData: Record<string, Person> = {}, currentUser: string): ReflectionsItem[] => {
  const directReports = useMemo(() => Object.values(userData)
    .filter((user) => userIds.includes(user.orgUserId) && user.orgUserId !== currentUser)
    .map((user) => ({
      profileImageUrl: user.profileImageUrl,
      firstName: user.firstName,
      lastName: user.lastName,
      orgUserId: user.orgUserId,
    })), [userIds, userData, currentUser]);

  const directReportsFilterItems = useMemo((): ReflectionsItem[] => {
    const data = directReports.map((report) => ({
      value: report.orgUserId,
      text: _.truncate(`${report.firstName} ${report.lastName}`, {
        length: 40,
      }),
      'data-test-id': 'reflectionsDirectReportItem',
    }));
    const allItems = uniqBy([defaultDirectReportFilter, ...data], 'value');

    return allItems;
  }, [directReports]);

  return directReportsFilterItems;
};
