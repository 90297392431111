import { ReactText, useRef } from 'react';
import {
  UseMutateFunction, useMutation,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import SupportChatErrorToast from '~Common/components/SupportChatErrorToast';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';
import { getOrganizationId } from '~Common/utils/localStorage';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';

interface DeleteActionItemProps {
  id: string,
}
const deleteActionItem = async ({ id }: DeleteActionItemProps): Promise<HttpCallReturn<void>> => {
  const organizationId = getOrganizationId();
  const URL = { host: getHost(hosts.action, '2'), uri: `/organizations/${organizationId ?? ''}/action-items/${id}` };
  return deleteApi(URL, { id });
};

export const useDeleteActionItem = (): UseMutateFunction<HttpCallReturn<void>, unknown, DeleteActionItemProps> => {
  const dispatch = useDispatch();
  const toastId = useRef<ReactText | number | null>(null);
  const mutation = useMutation({
    mutationFn: deleteActionItem,
    onMutate: () => {
      toastId.current = toast.info('Deleting action item', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: (
          <SupportChatErrorToast
            error="The action item deletion failed due to a technical issue on our end."
            chatMessage="Trouble deleting an action item."
          />
        ),
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async (_, variables: DeleteActionItemProps) => {
      // @ts-expect-error Remove when redux is done
      dispatch(popDrawer({ popAll: true }));

      setTimeout(async () => {
        await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'action-items'] });
      }, 350);
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'goals', 'for_user'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'goals', variables.id] });

      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles'] });

      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
      toast.update(toastId.current, {
        render: 'The action item was successfully deleted.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });

  return mutation.mutate;
};
