import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { capitalize } from 'lodash';
import { css } from '@emotion/react';

import { ExecutiveENPSDataForDate, ENPSStats } from '~Insights/hooks/useENPSData';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { CARD_STYLES, DASHBOARD_CARD_COMPONENTS, EXPANDED_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import {
  ViewPerspective, SetDisplay, EnpsCategory,
} from '~Insights/const/types';
import {
  GraphDataset, GroupedENPSData, makeENPSGraphData, makeENPSGroupings, makeTwelveMonthDelta, makeDelta,
} from '~Insights/const/dataUtils';
import { palette } from '~Common/styles/colors';

import { LineGraphChart } from '~Common/components/Charts/LineGraphChart';
import Card from '~Common/V3/components/Card';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';
import { GaugeChart } from '~Common/components/Charts/GaugeChart';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import { styles as gaugeStyles } from '~Insights/components/cards/ENPSSmallCard';
import { BarChart } from '~Common/components/Charts/BarChart';
import InsightsDelta from '../../InsightsDelta';
import InsightCardTitle from '../../InsightCardTitle';
import ExpandButton from '../../buttons/ExpandButton';
import { SharedProps } from './index';

const styles = {
  dashboard: css({
    ...CARD_STYLES.dashboard,
    padding: '0',
  }),

  dashboardLineGraph: css({
    backgroundColor: palette.neutrals.gray400,
    height: '18rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    p: {
      margin: '0',
    },
  }),
  gauge: css({
    textAlign: 'center',
  }),
  ...DASHBOARD_CARD_COMPONENTS,
  ...EXPANDED_CARD_COMPONENTS,
};

interface DashboardProps extends SharedProps {
  barGraphData: {
    labels: string[];
    datasets: {
        data: number[];
        backgroundColor: string[];
    }[];
  },
  graphData: GraphDataset[],
  groupedData: Record<string, GroupedENPSData[]>,
  mostRecentGraphData: GraphDataset,
  twelveMonthDelta: number|null,
  surveyDates: string[],
}

const DashboardView = ({
  changeToggleMenu,
  barGraphData,
  graphData,
  groupedData,
  isMobile,
  setDisplay,
  mostRecentGraphData,
  surveyDates,
  toggleMenuOptions,
  toggleMenuValue,
  twelveMonthDelta,
  userPerspective,
}: DashboardProps): JSX.Element => (
  <Card
    css={[styles.dashboard, styles.dashboardEnps]}
    renderContents={() => (
      <>
        {setDisplay !== SetDisplay.Mobile && !isMobile && (

        <div css={styles.dashboardContainer}>
          <div css={styles.main}>
            <div css={styles.header(false)}>
              {!isMobile && (
              <ExpandButton css={styles.expandButton(false)} cardKey="enps" />
              )}
              <div css={styles.titleAndSubtitle(false)}>
                <InsightCardTitle>eNPS Analysis</InsightCardTitle>
                <p>Hover over data for more information.</p>
              </div>
              {toggleMenuValue !== ViewPerspective.DirectReports && (
                <LeadrToggleTabs
                  value={toggleMenuValue}
                  onChange={(e, newValue) => changeToggleMenu(newValue as ViewPerspective)}
                >
                  {toggleMenuOptions.map((toggleMenuOption) => (
                    <LeadrToggleTabs.TextTab
                      data-test-id={toggleMenuOption['data-test-id']}
                      key={toggleMenuOption.value}
                      text={toggleMenuOption.text}
                      value={toggleMenuOption.value}
                    />
                  ))}
                </LeadrToggleTabs>
              )}
              {toggleMenuValue === ViewPerspective.DirectReports && (
                <span
                  css={styles.fauxToggleTab}
                >
                  Direct Reports
                </span>
              )}
            </div>
            {toggleMenuValue === ViewPerspective.Company && surveyDates.length === 1 && graphData && (
            <>
              {mostRecentGraphData?.data?.[0]?.y !== undefined && (
                <>
                  <div css={styles.chart}>
                    <GaugeChart
                      value={mostRecentGraphData?.data[0]?.y}
                    />
                  </div>
                  <div css={[styles.gauge, gaugeStyles.overallScore]}>
                    {mostRecentGraphData?.data[0]?.y}
                  </div>
                  <div css={[styles.gauge, gaugeStyles.label]}>
                    eNPS
                  </div>
                </>
              )}
            </>
            )}
            {toggleMenuValue === ViewPerspective.Departments && surveyDates.length === 1 && graphData.length > 0 && (
            <div css={styles.chart}>
              <BarChart
                data={barGraphData}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: false,
                      min: 0,
                      max: 200,
                      title: {
                        text: 'eNPS',
                        display: true,
                      },
                      ticks: {
                        // @ts-expect-error : There's something funky about the typing here.
                        callback: (tick: number) => (tick - 100).toString(),
                      },
                    },
                  },
                }}
                tooltipOptions={{
                  callbacks: {
                    // @ts-expect-error : There's something funky about the typing here.
                    label: (ctx: { parsed: { y: number; }; }) => (ctx?.parsed?.y ?? 0) - 100,
                    // @ts-expect-error : There's something funky about the typing here.
                    footer: (ctx: { raw: { participantInfo: string }}[]) => ctx[0]?.raw?.participantInfo ?? '',
                  },
                  footerMarginTop: 8,
                }}
              />
            </div>
            )}
            {surveyDates.length > 1 && graphData && (
              <LineGraphChart
                height="20rem"
                datasets={graphData}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      ticks: {
                        autoSkip: false,
                      },
                    },
                    y: {
                      min: -100,
                      max: 100,
                    },
                  },
                }}
                tooltipOptions={{
                  callbacks: {
                    // @ts-expect-error : There's something funky about the typing here.
                    footer: (ctx: { raw: { participantInfo: string }}[]) => ctx[0]?.raw?.participantInfo ?? '',
                  },
                  footerMarginTop: 8,
                }}
              />
            )}
            {(!surveyDates.length || !graphData.length) && (
            <div css={styles.emptyState}>
              <div css={styles.emptyImageContainer}>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <img src={insightsEmptyGraph} alt="Empty graph" />
              </div>
              <p css={styles.emptyStateText}>
                Data will show here as your team answers the eNPS question, which, when enabled, is included every 3 months.
              </p>
            </div>
            )}
          </div>
          <div css={styles.verticalDivider(isMobile)} />
          <div css={[styles.sidebar, styles.dashboardSidebar]}>
            <h3>Last 12 months</h3>
            <p css={styles.explainer}>Percentages represent change over time.</p>
            {(userPerspective === UserPerspective.manager || toggleMenuValue === ViewPerspective.Company || Object.keys(groupedData).length === 0) && (
            <div>
              <p>eNPS</p>
              <InsightsDelta value={twelveMonthDelta} />
            </div>
            )}
            {userPerspective === UserPerspective.executive
            && toggleMenuValue !== ViewPerspective.Company
            && surveyDates.length > 1
            && graphData.length > 0
            && Object.entries(groupedData).map(([name, surveys]) => (
              <div key={name}>
                <p>{name}</p>
                <InsightsDelta
                  value={makeDelta(surveys[0].scores.score, surveys[surveys.length - 1].scores.score)}
                />
              </div>
            ))}
            {userPerspective === UserPerspective.executive
            && toggleMenuValue !== ViewPerspective.Company
            && surveyDates.length === 1
            && graphData.length > 0
            && Object.entries(groupedData).map(([name, surveys]) => (
              <div key={name}>
                <p css={styles.bigNumber}>
                  {surveys[surveys.length - 1].scores.score}
                </p>
                <p>{name}</p>
              </div>
            ))}
          </div>
        </div>
        )}
      </>
    )}
  />
);

const HookedDashboard = ({
  data,
  changeToggleMenu,
  toggleMenuValue,
  userPerspective,
  ...props
}: SharedProps): JSX.Element => {
  const twelveMonthSlice = data.slice(-12) as ExecutiveENPSDataForDate[];
  const surveyDates = twelveMonthSlice.map((slice) => slice.surveyDate);

  const [selectedSidebarFilters] = useState<EnpsCategory[]>([]);

  const filters = selectedSidebarFilters.length ? selectedSidebarFilters : Object.values(EnpsCategory);
  const filteredGraphData = filters.map((category) => ({
    label: capitalize(category),
    data: data.reduce<Array<{x: string, y: number}>>((dataset, slice) => {
      if (slice.surveysAnswered) {
        dataset.push({
          x: moment(slice.surveyDate).format('MMM D YY'),
          y: (slice.scores[category.toLowerCase() as keyof ENPSStats] / slice.surveysAnswered) * 100,
        });
      }

      return dataset;
    }, []),
  }));
  const maxDataPoints = Math.max(...filteredGraphData.map((slice) => slice.data.length));

  useEffect(() => {
    if (userPerspective === UserPerspective.executive
      && toggleMenuValue !== ViewPerspective.Company
      && toggleMenuValue !== ViewPerspective.Departments) {
      changeToggleMenu(ViewPerspective.Company);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want this on first render.
  }, []);

  const groupedData = useMemo(
    () => (userPerspective === UserPerspective.executive ? makeENPSGroupings(twelveMonthSlice, toggleMenuValue) : {}),
    [twelveMonthSlice, toggleMenuValue, userPerspective],
  );

  const graphData = useMemo(
    () => makeENPSGraphData({
      dataSlice: twelveMonthSlice,
      groupedData,
      userPerspective,
      viewPerspective: toggleMenuValue,
    }),
    [twelveMonthSlice, groupedData, toggleMenuValue, userPerspective],
  );
  const mostRecentGraphData = graphData[graphData.length - 1] ?? {};

  // Note -- because eNPS is -100 to 100, Chart.js chokes. This is a hack to make it display correctly.
  const barGraphData = maxDataPoints !== 1 ? { labels: [], datasets: [] } : {
    labels: graphData.map((slice) => slice.label),
    datasets: [{
      data: graphData.map((slice) => slice.data[0].y + 100),
      backgroundColor: [palette.brand.sky],
    }],
  };

  const hookProps = {
    changeToggleMenu,
    barGraphData,
    data,
    toggleMenuValue,
    userPerspective,
    graphData,
    groupedData,
    mostRecentGraphData,
    twelveMonthDelta: makeTwelveMonthDelta(twelveMonthSlice),
    surveyDates,
  };

  return (
    <DashboardView
      {...hookProps}
      {...props}
    />
  );
};

export { DashboardView, HookedDashboard };
export default HookedDashboard;
