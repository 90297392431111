import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import React from 'react';
import ListSection, { ListSectionSkeleton } from '~Common/V3/components/ListSection';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { ViewType } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useLearningSearch } from '~Learning/hooks/utils/useLearningSearch';
import emptyRequestedLearnings from '~Learning/assets/images/emptyRequestedLearnings.png';
import { AssignedLearningSection, useGetAssignedLearningsByStatus } from '~Learning/hooks/assigned/useGetAssignedLearningsByStatus';
import { assignedPlaylistDetailDrawerTemplate } from '~Learning/components/AssignedLearningDashboard/AssignedPlaylistDetailDrawer';
import { AssignedLearning, LearningType } from '~Learning/const/interfaces';
import { useLearningViewTypeFilter } from '~Learning/hooks/utils/useLearningViewTypeFilter';
import AssignedListDashboard, { AssignedListDashboardSkeleton } from './AssignedListDashboard';
import AssignedGridDashboard, { AssignedGridDashboardSkeleton } from './AssignedGridDashboard';

import { requestedLearningDrawerTemplate } from './RequestedLearningDrawer';
import LearningEmptySearchOrFilters from '../Shared/LearningEmptySearchOrFilters';

const styles = {
  container: css({
    display: 'flex',
    flexWrap: 'wrap',
  }),
  sectionHeader: css({
    color: palette.neutrals.gray700,
    fontWeight: 500,
    fontSize: '1rem',
    marginBottom: '2rem',
    '&:not(:first-of-type)': {
      marginTop: '2rem',
    },
  }),
  listSectionSkeleton: css({
    marginBottom: '2rem',
    '&:not(:first-of-type)': {
      marginTop: '2rem',
    },
  }),
  emptyStateContainer: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  }),
  emptyStateImage: css({
    maxWidth: '18.75rem',
  }),
  emptyStateText: css({
    color: palette.neutrals.gray900,
    textAlign: 'center',
  }),
};

interface ViewProps {
  learningsByStatus: AssignedLearningSection[],
  onAssignedCardClick: (assignedLearning: AssignedLearning) => void,
  viewType: ViewType,
  isLoading: boolean,
  hasSearchOrFilters: boolean,
}

const View = ({
  isLoading,
  viewType,
  learningsByStatus,
  onAssignedCardClick,
  hasSearchOrFilters,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    {!isLoading && learningsByStatus.length > 0 && (
      <>
        {learningsByStatus.map((learningByStatus) => (
          <React.Fragment key={learningByStatus.sectionHeader}>
            <ListSection
              css={styles.sectionHeader}
              renderContents={() => (
                <div>{learningByStatus.sectionHeader}</div>
              )}
            />
            {viewType === ViewType.GRID && (
              <AssignedGridDashboard onAssignedCardClick={onAssignedCardClick} assignedLearnings={learningByStatus.learnings} />
            )}
            {viewType === ViewType.LIST && (
              <AssignedListDashboard onAssignedCardClick={onAssignedCardClick} assignedLearnings={learningByStatus.learnings} />
            )}
          </React.Fragment>
        ))}
      </>
    )}
    {!isLoading && learningsByStatus.length === 0 && (
      <>
        {hasSearchOrFilters && (
          <LearningEmptySearchOrFilters />
        )}
        {!hasSearchOrFilters && (
          <div css={styles.emptyStateContainer}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <img css={styles.emptyStateImage} src={emptyRequestedLearnings} alt="No Learnings Assigned" />
            <span css={styles.emptyStateText}>You haven&#39;t assigned any learnings yet. Click “Assign Learning” to send someone a learning.</span>
          </div>
        )}
      </>
    )}
    {isLoading && (
      <>
        <ListSectionSkeleton css={styles.listSectionSkeleton} />
        {viewType === ViewType.GRID && (
          <AssignedGridDashboardSkeleton />
        )}
        {viewType === ViewType.LIST && (
          <AssignedListDashboardSkeleton />
        )}
      </>
    )}
  </div>
);

const NewAssignedLearningDashboard = ({ ...props }): JSX.Element => {
  const [viewType] = useLearningViewTypeFilter();

  const { learningsByStatus, isLoading } = useGetAssignedLearningsByStatus();
  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);
  const [search] = useLearningSearch();
  const dispatch = useDispatch();

  const hasSearchOrFilters = search !== '';

  const hookProps = {
    isLoading: showSkeletonLoaders,
    viewType,
    learningsByStatus,
    hasSearchOrFilters,
    onAssignedCardClick: (assignedLearning: AssignedLearning) => {
      const drawerToOpen = assignedLearning.learningType === LearningType.PLAYLIST ? {
        ...assignedPlaylistDetailDrawerTemplate,
        args: {
          playlistId: assignedLearning.id,
        },
      } : {
        ...requestedLearningDrawerTemplate,
        args: {
          learningId: assignedLearning.id,
        },
      };
      dispatch(pushDrawerAction({
        drawer: {
          ...drawerToOpen,
        },
      }));
    },
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default NewAssignedLearningDashboard;
