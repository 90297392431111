import { css } from '@emotion/react';

import { BREAKPOINTS } from '~Deprecated/ui/styles/variables';
import { shadowColor } from './colors';

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const withWordWrap = () => `
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const wordWrap = css`
  ${withWordWrap()}
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const withTruncate = () => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const truncate = css`
  ${withTruncate()}
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const withLineClamp = (lines) => `
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;  
  overflow: hidden;
  word-break: break-word;
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const lineClamp = (lines) => css`
  ${withLineClamp(lines)}
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const withShadow = (colorOverride = null) => `
  0px 4px 16px -4px ${colorOverride || shadowColor};
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const forMobile = (styles) => `
  @media only screen and (max-width: ${BREAKPOINTS.MAX.MOBILE}px) {
    ${styles}
  }
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const forTablet = (styles) => `
  @media only screen and (min-width: ${BREAKPOINTS.MIN.TABLET}px) and (max-width: ${BREAKPOINTS.MAX.TABLET}px) {
    ${styles}
  }
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const forDesktop = (styles) => `
  @media only screen and (min-width: ${BREAKPOINTS.MIN.DESKTOP}px) {
    ${styles}
  }
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const withoutMobile = (styles) => `
  @media only screen and (min-width: ${BREAKPOINTS.MIN.TABLET}px) {
    ${styles}
  }
`;

/**
 * @deprecated Old mixins use packages/leadr-frontend/src/common/styles/mixins.ts instead
 */
export const withoutDesktop = (styles) => `
  @media only screen and (max-width: ${BREAKPOINTS.MAX.TABLET}px) {
    ${styles}
  }
`;
