import MuiDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import MomentUtils from '@mui/lab/AdapterMoment';
import moment, { Moment } from 'moment-timezone';
import { RefObject, useEffect, useState } from 'react';

import DrawerInput, { DrawerInputProps } from '~Common/V3/components/DrawerInput';

type MomentOrNull = Moment | null;

export interface DatePickerProps extends DrawerInputProps {
  label?: string,
  initialDate?: Date | Moment | string | null,
  onDateSelected?: ({ date, name }: {date: Moment | null, name: string}) => void,
  name: string,
  disablePast: boolean,
  disabled?: boolean,
  clearable?: boolean,
  minDate?: Date,
  maxDate?: Date,
}

const DatePicker = ({
  label,
  initialDate,
  onDateSelected,
  name,
  disablePast,
  disabled,
  clearable = false,
  minDate,
  maxDate,
  ...props
}: DatePickerProps): JSX.Element => {
  const [date, setDate] = useState<MomentOrNull>(initialDate ? moment(initialDate) : null);
  const [isPickerOpen, togglePicker] = useState(false);

  const onInputClick = (): void => {
    togglePicker(true);
  };

  const closePicker = (): void => {
    togglePicker(false);
  };

  const onDateSelect = (newDate: MomentOrNull): void => {
    setDate(newDate);
    onDateSelected?.({ date: newDate, name });
    closePicker();
  };

  useEffect(() => {
    if (initialDate) {
      setDate(moment(initialDate));
    }
  }, [initialDate]);

  return (
    <LocalizationProvider dateAdapter={MomentUtils} dateLibInstance={moment}>
      <MuiDatePicker
        minDate={minDate as unknown as Moment}
        maxDate={maxDate as unknown as Moment}
        clearable={clearable}
        disablePast={disablePast}
        label={label}
        value={date}
        open={isPickerOpen}
        onClose={closePicker}
        onChange={onDateSelect}
        // The mobile calendar has all kind of extra stuff on it that we dont need. Disabling it until we can be more intentional around it.
        desktopModeMediaQuery="(min-width: 0px)"
        renderInput={({
          inputRef,
        }) => (
          <DrawerInput
            label={label}
            inputRef={inputRef as RefObject<HTMLInputElement>}
            onClick={onInputClick}
            name={name}
            value={date ? moment(date).format('MM/DD/YYYY') : ''}
            disabled={disabled}
            {...props}
          />
        )}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
