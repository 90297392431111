import { css } from '@emotion/react';
import { useSupportChat } from '~Common/hooks/useSupportChat';
import { palette } from '~Common/styles/colors';

const styles = {
  button: css({
    color: palette.brand.blue,
    fontWeight: 800,
  }),
};

export interface SupportChatErrorToastProps {
  error: string,
  chatMessage: string,
}

const SupportChatErrorToast = ({
  error,
  chatMessage,
}: SupportChatErrorToastProps): JSX.Element => {
  const { openChat } = useSupportChat();

  const handleClick = (): void => {
    openChat(chatMessage);
  };

  return (

    <div>
      { error }

      &nbsp;Please try again in a few minutes. If the issue continues,&nbsp;

      {/* Button would not text-wrap inside of a paragraph correctly so using an anchor instead  */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        css={styles.button}
        onClick={handleClick}
        href="#"
      >
        please contact our customer success team
      </a>

      .
    </div>
  );
};

export default SupportChatErrorToast;
