import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  ChartOptions,
  TooltipOptions,
  CoreChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { merge } from 'lodash';
import { css } from '@emotion/react';
import { RefObject, useRef } from 'react';
import { GraphScrollIndicators } from '~Common/components/Charts/GraphScrollIndicators';
import { palette } from '~Common/styles/colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export interface BarGraphData {
    labels: string[] | string[][];
    datasets: {
      data: {
        x: number | string,
        y: number,
      }[] | number[],
      grouped?: boolean,
      backgroundColor?: string[],
      label?: string,
      minBarLength?: number,
    }[];
  }

const DATAPOINT_WIDTH = 10;
const PRINT_DATAPOINT_HEIGHT = 7;

const styles = {
  wrapper: (isMobile: boolean) => css({
    position: 'relative',
    overflowX: isMobile ? 'unset' : 'auto',
    height: '100%',
    maxWidth: '100%',
    width: '100%',
    marginBottom: '2rem',
  }),
  chart: (numDatapoints: number, height?: string, isMobile = false) => css({
    width: `${numDatapoints * DATAPOINT_WIDTH}rem`,
    minWidth: '100%',
    height: height ?? '100%',
    position: 'relative',
  }, {
    ...(isMobile ? {
      height: `${numDatapoints * PRINT_DATAPOINT_HEIGHT}rem`,
      width: '100%',
    } : { }),
  }),
  title: css({
    fontSize: '1.25rem',
    color: palette.brand.indigo,
    margin: '3.375rem 0 2.8125rem 1.5rem',
    fontWeight: 500,
  }),
};

  interface ViewProps {
    height?: string,
    data: BarGraphData,
    maxDatasetLength: number,
    options: ChartOptions,
    wrapperRef: RefObject<HTMLDivElement>
    isMobile?: boolean,
  }

const View = ({
  options,
  data,
  height = '20rem',
  maxDatasetLength,
  wrapperRef,
  isMobile = false,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.wrapper(isMobile)} ref={wrapperRef}>
    <h1 css={styles.title}>Review Cycle Progress</h1>
    <GraphScrollIndicators wrapperRef={wrapperRef} />
    <div css={styles.chart(maxDatasetLength, height, isMobile)}>
      <Bar options={options as CoreChartOptions<'bar'>} data={data as ChartData<'bar'>} {...props} />
    </div>
  </div>
);

  interface BarChartProps {
    height?: string,
    data: BarGraphData
    options: ChartOptions,
    tooltipOptions: Partial<TooltipOptions>
    isMobile?: boolean,
  }

export const BarChart = ({
  data,
  options,
  tooltipOptions,
  ...props
}: BarChartProps): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const maxDatasetLength = Math.max(...data.datasets.map((item) => item.data?.length ?? 0));

  const defaultOptions: ChartOptions = {
    responsive: true,
    layout: {
      padding: 0,
    },
    interaction: {
      mode: 'nearest',
      axis: 'xy',
      intersect: false,
    },
    plugins: {
      legend: {
        display: true,
        fullSize: true,
        align: 'center',
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 50,
        },
      },
      tooltip: {
        ...tooltipOptions,
      },
    },
    // @ts-expect-error not sure how to solve this one
    barPercentage: 1.0,
    categoryPercentage: 0.8,
    scales: {
      y: {
        title: {
          display: true,
        },
        ticks: {
          stepSize: 25, /* total/4 shows 0, 25%, 50%, 75%, 100% */
          callback(value: number | string) {
            return `${(value as number / 100) * 100}%`;
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    ticks: {
      autoSkip: true,
      minRotation: 0,
      align: 'start',
      font: {
        size: 10,
      },
    },
  };

  const hookProps = {
    data: {
      ...data,
    },
    maxDatasetLength,
    options: merge(defaultOptions, options),
    wrapperRef,
  };

  return (
    <View {...hookProps} {...props} />
  );
};
