import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { hosts, getHost } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { useDispatch } from 'react-redux';
import { navigateAction } from '~Deprecated/actions/navigate';
import { ADMIN_REVIEWS_V2 } from '~Common/const/routes';

interface completeCycleProps {
    cycleId: string,
}

const completeCycle = ({ cycleId }: completeCycleProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${cycleId}/finish`,
  };

  return postApi(serverUrl, {}, {});
};

// eslint-disable-next-line max-len
export const useCompleteCycle = (): UseMutateFunction<HttpCallReturn<void>, unknown, completeCycleProps, void> => {
  // const queryKey = [getOrganizationId() ?? '', 'reviewCycles'];
  const toastId = useRef<ReactText | number | null>(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: completeCycle,

    onMutate: () => {
      toastId.current = toast.info('Completing the review cycle...', { autoClose: false });
    },

    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error Completing the review cycle. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },

    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Review cycle completed successfully.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviews'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), getOrganizationUserId(), 'reviewCycles'] });
      dispatch(navigateAction({
        pathname: ADMIN_REVIEWS_V2,
      }));
    },
  });

  return mutation.mutate;
};
