import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { Goals } from '@leadr-hr/types';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { goalKeys } from '../const/queryKeys';

interface EditGoalParticipantsProps {
  payload: Goals.Requests.UpdateGoalParticipantsRequestPayload,
  goalId: string,
}

const createGoal = ({ payload, goalId }: EditGoalParticipantsProps): Promise<HttpCallReturn<string>> => {
  const serverUrl = {
    host: getHost(hosts.goals, '3'),
    uri: `/organizations/${getOrganizationId() ?? ''}/goals/${goalId}/participants`,
  };

  return patchApi<string>(serverUrl, { ...payload }, {});
};

export const useEditGoalParticipants = (): UseMutationResult<HttpCallReturn<string>, unknown, EditGoalParticipantsProps, unknown> => {
  const toastId = useRef<ReactText | number | null>(null);
  const mutation = useMutation({
    mutationFn: createGoal,
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error updating your participants. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: goalKeys.all });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeGoals(getOrganizationId() ?? '') });
    },
  });

  return mutation;
};
