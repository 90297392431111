import { css } from '@emotion/react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import styled from '@mui/system/styled';
import { buttonStyleMap } from '~Deprecated/ui/styles/buttonStyleMap';
import { BUTTON_VARIANTS } from '~Common/const/buttonVariants';
import { palette } from '~Common/styles/colors';

const iconWrapper = css`
  &.MuiListItemIcon-root {
    min-width: 1.625rem;
  }
`;
const anchorElStyle = (variant) => css`
  &.MuiButton-contained {
    height: auto;
    padding: 0.75rem 1rem;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    & svg {
      position: relative;
    }

    ${buttonStyleMap[variant]}
  }
`;

const listItemTextStyle = css`
  color: ${palette.neutrals.gray700};

  & span {
    font-weight: 800;
  }
`;

/**
 *
 * @param menuAnchorText #Text to be used for menu button
 * @param menuItems #Array of menu items. Each object must have a text property, icon is optional.
 * @param onItemSelected #function to call when an item is selected from the menu.
 * @param menuButtonColor #Button background color. If not provided, will default to default color.
 * @returns {{Component: JSX.Element, selectedOption: unknown}}
 */
const useMenu = ({
  menuAnchorText,
  menuItems,
  onItemSelected,
  variant = BUTTON_VARIANTS.NEUTRAL,
  menuItemStyles = {},
  listItemIconStyles = {},
  listItemTextStyles = {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const MenuItem = styled(MuiMenuItem)(() => ({
    ...menuItemStyles,
  }));

  const ListItemIcon = styled(MuiListItemIcon)(() => ({
    ...listItemIconStyles,
  }));

  const ListItemText = styled(MuiListItemText)(() => ({
    ...listItemTextStyles,
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (menu) => {
    onItemSelected(menu);
    handleClose();
  };

  return {
    Component: (
      <div>
        <Button
          disableElevation
          disableRipple
          aria-controls="material-menu"
          aria-haspopup="true"
          variant="contained"
          css={anchorElStyle(variant)}
          onClick={handleClick}
        >
          { menuAnchorText }
        </Button>
        <Menu
          anchorEl={anchorEl}
          id="material-menu"
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          { menuItems.map((menu, i) => (
            <MenuItem key={i} onClick={() => handleSelection(menu)} disableRipple>
              { menu.icon && (
                <ListItemIcon css={iconWrapper}>
                  { menu.icon }
                </ListItemIcon>
              ) }
              <ListItemText
                primary={menu.text}
                css={listItemTextStyle}
              />
            </MenuItem>
          )) }
        </Menu>
      </div>
    ),
  };
};

export default useMenu;
