/* eslint-disable react/destructuring-assignment */
/*
Original Code: https://raw.githubusercontent.com/zoltantothcom/react-clamp-lines/master/src/index.js
NPM Link: https://www.npmjs.com/package/react-clamp-lines?activeTab=readme
*/

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isHTMLText } from '~Common/utils/isHTMLText';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

export default class ClampLines extends PureComponent {
  constructor(props) {
    super(props);
    this.element = null;
    this.original = props.text;
    this.watch = true;
    this.lineHeight = 0;
    this.start = 0;
    this.middle = 0;
    this.end = 0;
    this.uuid = props.id;
    this.state = {
      expanded: true,
      noClamp: false,
      text: props.text.substring(0, 20),
    };
    this.emotionStyle = props.emotionStyle;
    this.minLineHeight = props.minLineHeight;
    this.link = props.link;
    // If window is undefined it means the code is executed server-side
    this.ssr = typeof window === 'undefined';
    this.testId = props.testId;

    this.action = this.action.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.setIsClamped = props.setIsClamped;

    if (!this.ssr) {
      this.debounced = this.debounce(this.action, props.delay);
    } else {
      this.state.text = props.text.substring(0, 20);
    }
  }

  componentDidMount() {
    if (this.props.text && !this.ssr) {
      this.lineHeight = this.element.clientHeight + 1;
      // Honestly dumb as hell, but it works because for some reason when someone uses only emojis it can't get the line height at times
      this.lineHeight = Math.max(this.lineHeight, this.minLineHeight + 1);
      this.clampLines();

      if (this.watch) {
        window.addEventListener('resize', this.debounced);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.original = this.props.text;
      this.clampLines();
    }
  }

  componentWillUnmount() {
    if (!this.ssr) {
      window.removeEventListener('resize', this.debounced);
    }
  }

  getClassName() {
    const className = this.props.className || '';

    return `clamp-lines ${className}`;
  }

  getEllipsis() {
    return this.watch && !this.state.noClamp ? this.props.ellipsis : '';
  }

  getButton() {
    if (this.state.noClamp || !this.props.buttons) {
      this.setIsClamped(true);
      return null;
    }

    const buttonText = this.watch ? this.props.moreText : this.props.lessText;

    return (
      <button
        className="clamp-lines__button"
        onClick={this.link ? undefined : this.clickHandler}
        aria-controls={`clamped-content-${this.uuid}`}
        aria-expanded={!this.state.expanded}
      >
        {buttonText}
      </button>
    );
  }

  moveMarkers(maxHeight) {
    if (this.element.clientHeight <= maxHeight) {
      this.start = this.middle + 1;
    } else {
      this.end = this.middle - 1;
    }
  }

  clampLines() {
    if (!this.element) return;

    this.setState({
      text: '',
    });

    const maxHeight = this.lineHeight * this.props.lines + 1;
    this.start = 0;
    this.middle = 0;
    this.end = this.original.length;

    while (this.start <= this.end) {
      this.middle = Math.floor((this.start + this.end) / 2);
      this.element.innerText = this.original.slice(0, this.middle);
      if (this.middle === this.original.length) {
        this.setState({
          text: this.original,
          noClamp: true,
        });
        this.element.innerHTML = this.original;
        return;
      }

      this.moveMarkers(maxHeight);
    }
    this.element.innerText = this.original.slice(0, this.middle - 5) + this.getEllipsis();
    this.setState({
      text: this.original.slice(0, this.middle - 5) + this.getEllipsis(),
    });
  }

  action() {
    if (this.watch) {
      this.setState({
        noClamp: false,
      });
      this.clampLines();
      this.setState((current) => ({ expanded: current.expanded }));
    }
  }

  debounce(func, wait, immediate) {
    let timeout;

    return () => {
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  clickHandler(e) {
    const { stopPropagation } = this.props;

    e.preventDefault();
    if (stopPropagation) {
      e.stopPropagation();
    }

    this.watch = !this.watch;
    if (this.watch) {
      this.clampLines();
    } else {
      this.setState({
        text: this.original,
      });
    }
    this.setState((current) => ({ expanded: current.expanded }));
  }

  render() {
    if (!this.props.text) {
      return null;
    }

    return (
      <div css={this.emotionStyle} className={this.getClassName()}>
        {isHTMLText(this.state.text) && (
          <HTMLRenderer
            ref={(e) => {
              this.element = e;
            }}
            id={`clamped-content-${this.uuid}`}
            data-test-id={this.testId}
            aria-hidden={this.state.expanded}
            htmlText={this.state.text}
          />
        )}
        {!isHTMLText(this.state.text) && (
          <div
            ref={(e) => {
              this.element = e;
            }}
            id={`clamped-content-${this.uuid}`}
            data-test-id={this.testId}
            aria-hidden={this.state.expanded}
          >
            {this.state.text}
          </div>
        )}
        {this.getButton()}
      </div>
    );
  }
}

ClampLines.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lines: PropTypes.number,
  ellipsis: PropTypes.string,
  buttons: PropTypes.bool,
  moreText: PropTypes.string,
  lessText: PropTypes.string,
  className: PropTypes.string,
  delay: PropTypes.number,
  stopPropagation: PropTypes.bool,
  emotionStyle: PropTypes.object,
  minLineHeight: PropTypes.number,
  link: PropTypes.bool,
  setIsClamped: PropTypes.func,
  testId: PropTypes.string,
};

ClampLines.defaultProps = {
  lines: 3,
  ellipsis: '...',
  buttons: true,
  moreText: 'Read more',
  lessText: 'Read less',
  delay: 300,
  className: 'clampLines',
  stopPropagation: true,
  emotionStyle: '',
  minLineHeight: 24, // seems to be the standard height of a p tag used in our descriptions
  link: false,
  setIsClamped: () => {},
  testId: 'clamp-lines',
};
