import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import Froala, { FROALA_SINGLE_LINE_HEIGHT } from '~Common/components/Fields/RichTextEditors/Froala';
import { printColors, palette } from '~Common/styles/colors';
import { SURVEY_QUESTIONS_AND_ANSWER_SHAPE, SURVEY_QUESTION_TYPES_SHAPE } from '~Common/const/proptypes';
import { NO_RESPONSE_TEXT } from '~Common/const/surveys';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import SurveyRating from '../Drawers/Respond/SurveyRating';
import SurveyMultipleChoice from '../Drawers/Respond/SurveyMultipleChoice';

const styles = {
  container: css``,
  questionContainer: css`
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  `,
  title: css`
    font-size: 1.125rem;
    font-weight: bold;
  `,
  description: css`
    margin-bottom: 1rem;
  `,
  freeformBox: css`
    background-color: white;
    border: .0625rem solid ${printColors.responseBorders};
    padding: 1rem;
    border-radius: .3125rem;
  `,
  multi: css`
    & span{
      color: ${palette.neutrals.gray700};
      width:1.25rem;
    }
    & svg{
      width:1.25rem;
    }
  `,
};

const View = ({
  participantQuestionAndAnswer, questionTypes, disabled, ...props
}) => (
  <div
    css={styles.container}
    {...props}
  >
    <div>
      {participantQuestionAndAnswer.questionsAndAnswers.map((questionAndAnswer) => (
        <div css={styles.questionContainer} key={questionAndAnswer.question.id}>
          <div css={styles.title}>{questionAndAnswer.question.question}</div>
          <HTMLRenderer css={styles.description} htmlText={questionAndAnswer.question.description} />
          {questionAndAnswer.question.type.key === questionTypes.CustomScale.id && (
            <SurveyRating
              question={questionAndAnswer.question}
              disabled
              initialValue={questionAndAnswer.answer?.answer}
            />
          )}
          {questionAndAnswer.question.type.key === questionTypes.MultipleChoice.id && (
            <SurveyMultipleChoice
              question={questionAndAnswer.question}
              isReadOnly
              initialValue={questionAndAnswer.answer?.answer}
              css={styles.multi}
            />
          )}
          {questionAndAnswer.question.type.key === questionTypes.ShortAnswer.id && (
            <Froala
              heightMin={FROALA_SINGLE_LINE_HEIGHT}
              isReadOnly
              value={questionAndAnswer.answer?.answer || NO_RESPONSE_TEXT}
              styleOverrides={styles.freeformBox}
            />
          )}
        </div>
      ))}
    </div>
  </div>
);

View.propTypes = {
  questionTypes: SURVEY_QUESTION_TYPES_SHAPE.isRequired,
  participantQuestionAndAnswer: SURVEY_QUESTIONS_AND_ANSWER_SHAPE.isRequired,
  disabled: PropTypes.bool,
};

View.defaultProps = {
  disabled: false,
};

const ByPersonQuestion = ({ ...props }) => {
  const disabled = true;
  const hookProps = {
    disabled,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ByPersonQuestion.propTypes = {};

ByPersonQuestion.defaultProps = {};

export { View };
export default ByPersonQuestion;
