import { css } from '@emotion/react';
import { Fragment } from 'react';
import { AgendaItem } from '~Meetings/hooks/v2/useGetAgendas';
import { AgendaType } from '@leadr-hr/types';
import AgendaSection from './AgendaSection';
import AgendaTopic from './AgendaTopic';
import Section from '../Section';

const styles = {
  title: css({
    marginBottom: '1.125rem',
  }),
  agendaItemsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.125rem',
  }),
};

interface AgendaProps {
  agendas: AgendaItem[],
}

const Agenda = ({
  agendas,
  ...props
}: AgendaProps): JSX.Element => (
  <Section
    {...props}
  >
    <Section.Title css={styles.title} title="Agenda" />
    <div css={styles.agendaItemsContainer}>
      {agendas.map((agenda) => (
        <Fragment key={agenda.id}>
          {agenda.type === AgendaType.AgendaSection && (
            <AgendaSection agendaSection={agenda} />
          )}
          {agenda.type === AgendaType.AgendaTopic && (
            <AgendaTopic agendaTopic={agenda} />
          )}
        </Fragment>
      ))}
    </div>
  </Section>
);

export default Agenda;
