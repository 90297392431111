import { create } from 'zustand';

interface AgendaSectionRealtimeEvents {
  [key: string]: string,
}

interface UseAgendaSectionCreateTopicRealtimeEventsStoreState {
  agendaSectionRealtimeEvents: AgendaSectionRealtimeEvents,
  setRealtimeEventId: (agendaSectionId: string, realtimeEventId: string) => void,
  removeRealtimeEventId: (agendaSectionId: string) => void,
  getAgendaSectionRealtimeEventId: (agendaSectionId: string) => string,
  removeAgendaSection: (agendaSectionId: string) => void,
}

export const useAgendaSectionCreateTopicRealtimeEvents = create<UseAgendaSectionCreateTopicRealtimeEventsStoreState>((set, get) => ({
  agendaSectionRealtimeEvents: {},
  setRealtimeEventId: (agendaSectionId: string, realtimeEventId: string) => set(() => ({
    agendaSectionRealtimeEvents: {
      ...get().agendaSectionRealtimeEvents,
      [agendaSectionId]: realtimeEventId,
    },
  })),
  removeRealtimeEventId: (agendaSectionId: string) => set(() => ({
    agendaSectionRealtimeEvents: {
      ...get().agendaSectionRealtimeEvents,
      [agendaSectionId]: '',
    },
  })),
  getAgendaSectionRealtimeEventId: (agendaSectionId: string) => {
    const realtimeEventId = get().agendaSectionRealtimeEvents[agendaSectionId];

    return realtimeEventId ?? '';
  },
  removeAgendaSection: (agendaSectionId: string) => set(() => {
    if (!get().agendaSectionRealtimeEvents[agendaSectionId]) {
      const newAgendaSectionRealtimeEvents = { ...get().agendaSectionRealtimeEvents };
      delete newAgendaSectionRealtimeEvents[agendaSectionId];
      return {
        agendaSectionRealtimeEvents: newAgendaSectionRealtimeEvents,
      };
    }

    return get().agendaSectionRealtimeEvents;
  }),
}));
