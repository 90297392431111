import { postApi, putApi } from '~Deprecated/services/HttpService';

export const getPreSignedUrlService = async ({ queryKey }) => {
  const [orgId, operationType, extension] = queryKey;

  const URL = `/organizations/${orgId}/users/presigned`;

  return postApi(URL, {
    operationType, extension,
  });
};

export const fileUploadService = async ({ url, file }) => fetch(url, {
  method: 'PUT',
  body: file,
});
