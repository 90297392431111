import { User } from '~Common/const/interfaces';
import { useGenericStore } from './useGenericStore';

export const useStoreData = (): [User[], (data: User[]) => void] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value, setValue } = useGenericStore({
    defaultValue: [],
    module: 'leadr',
    workflow: 'data',
    feature: 'data-store',
  });

  return [
    value as User[],
    setValue as (data: User[]) => void,
  ];
};
