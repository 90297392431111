import { css } from '@emotion/react';

const styles = {
  boldedText: css({
    fontWeight: 600,
  }),
};

interface AgendaTopicsTextProps {
  isReviewee: boolean,
  reviewerFullName: string,
  secondaryReviewerFullName: string,
  revieweeFullName: string,
  hasSecondaryReviewer: boolean,
}

const AgendaTopicsText = ({
  isReviewee,
  reviewerFullName,
  secondaryReviewerFullName,
  revieweeFullName,
  hasSecondaryReviewer,
  ...props
}: AgendaTopicsTextProps): JSX.Element => (
  <span {...props}>
    Agenda topics you have saved in your 1:1 with
    {isReviewee && (
      <span>
        <span css={styles.boldedText}>{` ${reviewerFullName} `}</span>
        {hasSecondaryReviewer && (
          <>
            and
            <span css={styles.boldedText}>
              {` ${secondaryReviewerFullName} `}
            </span>
          </>
        )}
      </span>
    )}
    {!isReviewee && (
      <span css={styles.boldedText}>{` ${revieweeFullName} `}</span>
    )}
  </span>
);

export default AgendaTopicsText;
