import { css } from '@emotion/react';
import {
  ComponentProps,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useShowCommentEditor } from '~Meetings/hooks/useShowAgendaEditor';
import { forMobileObject } from '~Common/styles/mixins';
import { useShowCommentSection } from '~Meetings/hooks/useShowCommentSection';
import ShowLessCommentsComponent from '~Meetings/components/details/agenda/ShowLessCommentsComponent';
import HuddleAgendaNote from '~Meetings/components/details/agenda/HuddleAgendaNote';
import AgendaIndicators from '~Meetings/components/details/agenda/AgendaIndicators';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ShowCommentsComponent from '~Meetings/components/details/agenda/ShowCommentsComponent';
import useMeetingRealtime from '~Meetings/hooks/useMeetingRealtime';
import { useQueryCache } from '~Common/const/queryClient';
import { palette } from '~Common/styles/colors';
import { getOrganizationUserId, getUserId } from '~Common/utils/localStorage';
import { getLocalId } from '~Common/utils/uuid';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useDraft } from '~Common/hooks/useDraft';
import { AgendaTopic, AgendaTopicComment } from '~Meetings/hooks/v2/useGetAgendas';
import { useCreateComment } from '~Meetings/hooks/v2/useCreateComment';
import { useAgendaPermissions } from '~Meetings/hooks/utils/useAgendaPermissions';
import CreateAgendaItem, { OnMakePrivateProps, OnSaveProps } from '~Meetings/components/details/agenda/CreateAgendaItem';
import { AgendaCommentRealTimeMessageType, AgendaCreatePayload, AgendaUpdatePayload } from '~Meetings/const/realTimeMeetingMessages';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { AgendaType } from '@leadr-hr/types';
import RealtimeInlineText from '~Meetings/components/details/agenda/RealtimeCreationCard/RealtimeInlineText';
import RealtimeCreationCard from '~Meetings/components/details/agenda/RealtimeCreationCard';
import { RecursiveObject } from '~Meetings/stores/useMeetingRealtimeStore';
import OnePersonCreatingItem from '~Meetings/components/details/agenda/RealtimeCreationCard/OnePersonCreatingItem';

const styles = {
  commentSection: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    breakInside: 'auto',
    margin: '0.5rem 0',
  }),
  createSection: css({
    width: '100%',
    marginBottom: '1rem',
  }),
  editSection: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  }),
  addCommentButton: (showCommentSection: boolean) => css({
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: '1.125rem',
    fontWeight: 400,
    fontSize: '.75rem',
  }, showCommentSection && {
    fontWeight: 600,
  }),
  existingNotesControlsContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '0.5rem',
  }, forMobileObject({
    width: '100%',
  })),

  existingNotesControlsContainerLeft: css({
    display: 'flex',
    alignItems: 'center',
  }, forMobileObject({
    flex: 1,
  })),
  openNotesControlsContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '0.5rem',
    width: '100%',
  }),
  createNoteEditor: css({
    '.fr-toolbar.fr-desktop.fr-top.fr-basic': {
      backgroundColor: palette.neutrals.white,
    },
    '.fr-toolbar.fr-mobile.fr-top.fr-basic': {
      backgroundColor: palette.neutrals.white,
    },
    '.fr-box.fr-basic.fr-top > .fr-wrapper': {
      backgroundColor: palette.neutrals.white,
    },
    '.fr-second-toolbar': {
      backgroundColor: `${palette.neutrals.white} !important`,
    },
  }),
  noteLabelOverrides: css({
    backgroundColor: palette.neutrals.white,
  }),
  realTimeCreatingSection: css({
    '&:not(:last-of-type)': {
      marginBottom: '.5rem',
    },
  }),
  realTimeInlineText: css({
    fontSize: '.75rem',
  }),
  agendaIndicators: css({
    marginLeft: 'auto',
  }),
};

export interface ViewProps extends Omit<ComponentProps<'div'>, 'onChange'> {
  notes: AgendaTopicComment[],
  showCommentSection: boolean,
  setShowCommentSection: (showCommentSection: boolean) => void,
  editAgendaTopic: () => void,
  canAddNote: boolean,
  addNoteCommentEditor: () => void,
  onCancelCommentEditor: () => void,
  showAddCommentEditor: boolean,
  agendaId: string,
  huddleId: string,
  type: MeetingTypeEnum,
  highlightedItem?: string,
  onChange: ({ value }: { value: string }) => void,
  onSave: (props: OnSaveProps) => void,
  agendaItem: AgendaTopic,
  disableAddNoteButton: boolean,
  draftKey: string[],
  handleMakePrivate: ({ isPrivate }: OnMakePrivateProps) => void,
  showCreatingCommentsRealtimeEvent: boolean,
  sectionId?: string,
  realtimeCreatingAgenda?: RecursiveObject<AgendaCreatePayload[]>,
  realtimeUpdatingAgenda?: AgendaUpdatePayload | RecursiveObject<AgendaUpdatePayload>,
}

const View = ({
  notes,
  showCommentSection,
  setShowCommentSection,
  editAgendaTopic,
  canAddNote,
  addNoteCommentEditor,
  onCancelCommentEditor,
  showAddCommentEditor,
  agendaId,
  huddleId,
  type,
  highlightedItem,
  onSave,
  onChange,
  sectionId,
  agendaItem,
  disableAddNoteButton,
  realtimeCreatingAgenda,
  realtimeUpdatingAgenda,
  draftKey,
  handleMakePrivate,
  showCreatingCommentsRealtimeEvent,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.commentSection} {...props}>
    { notes?.length > 0 && !showCommentSection && (
      <div css={styles.existingNotesControlsContainer}>
        <div css={styles.existingNotesControlsContainerLeft}>
          <ShowCommentsComponent
            notes={notes}
            setShowComments={setShowCommentSection}
            editAgendaTopic={editAgendaTopic}
            huddleId={huddleId}
            type={type}
            agendaItemId={agendaId}
          />
        </div>

          {showCreatingCommentsRealtimeEvent && (
          <RealtimeInlineText
            css={styles.realTimeInlineText}
                // @ts-expect-error TODO: Fix later
            peopleCreatingItemsInRealTime={realtimeCreatingAgenda?.data}
          />
          )}

          {!showCreatingCommentsRealtimeEvent && (
          <AgendaIndicators
            agendaItem={agendaItem}
          />
          )}
      </div>
    )}

    {(showCommentSection || showAddCommentEditor) && (
      <AgendaIndicators css={styles.agendaIndicators} agendaItem={agendaItem} />
    )}

    {showCommentSection && notes?.length > 0 && (
      <ShowLessCommentsComponent setShowComments={setShowCommentSection} />
    )}

    {showCommentSection && notes && notes?.length > 0 && notes?.map((note) => (
      <HuddleAgendaNote
        key={note.id}
        note={note}
        huddleId={huddleId}
        agendaItemId={agendaId}
        type={type}
        shouldHighlight={highlightedItem === note.id}
        sectionId={sectionId}
        realtimeUpdatingAgenda={realtimeUpdatingAgenda}
      />
    ))}

    {showCommentSection && (
      <>
        { /* @ts-expect-error TODO: Fix later */ }
        {realtimeCreatingAgenda?.data?.map(({ creator, type: agendaType, eventId }) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <RealtimeCreationCard key={eventId} css={styles.realTimeCreatingSection}>
            <OnePersonCreatingItem
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              person={creator}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              agendaType={agendaType}
            />
          </RealtimeCreationCard>
        ))}
      </>
    )}

    {showAddCommentEditor && (
      <div
        css={styles.createSection}
      >
        <CreateAgendaItem
          css={styles.createNoteEditor}
          huddleId={huddleId}
          onCancel={onCancelCommentEditor}
          onSave={onSave}
          onChange={onChange}
          defaultBold={false}
          draftKey={draftKey}
          showPrivateButton
          onMakePrivate={handleMakePrivate}
        />
      </div>
    )}

    {(showCommentSection || !notes?.length) && (
      <div css={styles.editSection}>
        <div css={styles.openNotesControlsContainer}>
          {(!showAddCommentEditor && (canAddNote || disableAddNoteButton)) && (
            <LeadrButton
              disabled={disableAddNoteButton}
              variant="text"
              data-test-id="meetingAddComment"
              onClick={addNoteCommentEditor}
              textButtonColor={showCommentSection ? palette.brand.indigo : palette.neutrals.gray700}
              css={styles.addCommentButton(showCommentSection)}
            >
              + Add Comment
            </LeadrButton>
          )}

          {!showCommentSection && (
            <>
              {showCreatingCommentsRealtimeEvent && (
                <RealtimeInlineText
                  css={styles.realTimeInlineText}
                  // @ts-expect-error TODO: Fix later
                  peopleCreatingItemsInRealTime={realtimeCreatingAgenda?.data}
                />
              )}
              {!showCreatingCommentsRealtimeEvent && !showAddCommentEditor && (
                <AgendaIndicators agendaItem={agendaItem} />
              )}
            </>
          )}
        </div>
      </div>
    )}
  </div>
);

export interface AgendaCommentSectionProps extends Omit<ComponentProps<'div'>, 'onChange'> {
  agendaItem: AgendaTopic,
  notes: AgendaTopicComment[],
  type: MeetingTypeEnum,
  huddleId: string,
  editAgendaTopic: () => void,
  highlightedItem?: string,
  sectionId?: string,
  realtimeCreatingAgenda?: AgendaCreatePayload[],
  realtimeUpdatingAgenda?: AgendaUpdatePayload | RecursiveObject<AgendaUpdatePayload>,
}

const AgendaCommentSection = ({
  notes,
  type,
  huddleId,
  editAgendaTopic,
  highlightedItem,
  agendaItem,
  sectionId,
  realtimeCreatingAgenda,
  realtimeUpdatingAgenda,
  ...props
}: AgendaCommentSectionProps): JSX.Element => {
  const orgId = getOrganizationUserId();
  const userId = getUserId();
  const agendaId = agendaItem.id;

  const draftKey = useMemo(() => (
    [orgId ?? '', huddleId, 'agenda', agendaId, 'create', notes.length.toString()]
  ), [orgId, huddleId, agendaId, notes.length]);

  const { draft, setDraft } = useDraft(draftKey);

  const [agendaTopicCommentCreateEventId, setAgendaTopicCommentCreateEventId] = useState('');
  const hidePrivateNotes = useQueryCache(['print', 'hidePrivateAgenda']);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user } = useUserProfile(userId!);

  const { sendMessage } = useMeetingRealtime({ huddleId });

  const { mutate: doCreateComment } = useCreateComment({
    agendaItemId: agendaId,
    draftKey,
    sectionId,
  });

  const { showAddCommentEditor, setShowCommentEditor } = useShowCommentEditor(agendaId);
  const { showCommentSection, setShowCommentSection } = useShowCommentSection(agendaId);

  const { getTopicPermissions } = useAgendaPermissions(huddleId);
  const { canAddNote, areWorkflowsDisabled } = getTopicPermissions(agendaId, sectionId);

  const sendCreateCommentRealTimeEvent = useCallback((eventId: string) => {
    const parentIds = [];

    if (sectionId) {
      parentIds.push(sectionId);
    }

    sendMessage(AgendaCommentRealTimeMessageType.Create, {
      type: AgendaType.Comment,
      eventId,
      creator: {
        // We should always have the current user's profile
        orgUserId: user!.organizationUserId, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        firstName: user!.firstName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        lastName: user!.lastName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        profileImageUrl: user!.profileImageUrl, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      },
      parentIds: [...parentIds, agendaId],
    });
  }, [agendaId, sectionId, sendMessage, user]);

  const sendCancelCommentRealTimeEvent = useCallback((eventId: string) => {
    const parentIds = [];

    if (sectionId) {
      parentIds.push(sectionId);
    }

    sendMessage(AgendaCommentRealTimeMessageType.CreateCanceled, {
      eventId,
      parentIds: [...parentIds, agendaId],
    });
  }, [agendaId, sectionId, sendMessage]);

  const addNoteCommentEditor = useCallback(() => {
    const newEventId = getLocalId();
    setShowCommentEditor(true);
    setAgendaTopicCommentCreateEventId(newEventId);
    sendCreateCommentRealTimeEvent(newEventId);
  }, [setShowCommentEditor, sendCreateCommentRealTimeEvent]);

  const onCancelCommentEditor = useCallback(() => {
    setShowCommentEditor(false);
    setAgendaTopicCommentCreateEventId('');
    sendCancelCommentRealTimeEvent(agendaTopicCommentCreateEventId);
  }, [setShowCommentEditor, sendCancelCommentRealTimeEvent, agendaTopicCommentCreateEventId]);

  const handleMakePrivate = useCallback(({ isPrivate }: OnMakePrivateProps) => {
    if (isPrivate) {
      sendCancelCommentRealTimeEvent(agendaTopicCommentCreateEventId);
    } else {
      sendCreateCommentRealTimeEvent(agendaTopicCommentCreateEventId);
    }
  }, [agendaTopicCommentCreateEventId, sendCancelCommentRealTimeEvent, sendCreateCommentRealTimeEvent]);

  const onChange = useCallback(({ value }: { value: string }) => {
    setDraft({
      ...draft,
      value,
    });
  }, [
    draft,
    setDraft,
  ]);

  const onSave = useCallback(({
    text,
    isPrivate,
  }: OnSaveProps) => {
    doCreateComment({
      context: {
        type: 'AGENDA_ITEM',
        id: agendaId,
        meetingId: huddleId,
      },
      items: [{ text, rank: 1.2, isPrivate }],
      ...(!!agendaTopicCommentCreateEventId && { eventId: agendaTopicCommentCreateEventId }),
    });
  }, [
    agendaId,
    huddleId,
    doCreateComment,
    agendaTopicCommentCreateEventId,
  ]);

  // @ts-expect-error TODO: Fix later
  // eslint-disable-next-line max-len, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const showCreatingCommentsRealtimeEvent: boolean = realtimeCreatingAgenda?.data?.length && typeof realtimeCreatingAgenda?.data?.length === 'number' && realtimeCreatingAgenda?.data?.length > 0;

  const hookProps = {
    notes: (notes || []).filter((note) => (note.isPrivate ? !hidePrivateNotes : true)),
    showCommentSection,
    setShowCommentSection,
    canAddNote,
    editAgendaTopic,
    addNoteCommentEditor,
    onCancelCommentEditor,
    showAddCommentEditor,
    setShowCommentEditor,
    huddleId,
    agendaId,
    type,
    highlightedItem,
    onChange,
    onSave,
    agendaItem,
    sectionId,
    disableAddNoteButton: areWorkflowsDisabled,
    // TODO: Fix later
    realtimeCreatingAgenda: realtimeCreatingAgenda as unknown as RecursiveObject<AgendaCreatePayload[]>,
    realtimeUpdatingAgenda,
    draftKey,
    handleMakePrivate,
    showCreatingCommentsRealtimeEvent,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaCommentSection;
