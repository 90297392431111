import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import { MouseEvent, useMemo } from 'react';
import { faArrowRightFromLine, faTrash } from '@fortawesome/pro-light-svg-icons';
import DeleteConfirmationPopover, {
  UseDeleteConfirmationPopoverHookReturn,
  useDeleteConfirmationPopover,
} from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import { TeamRowAction, TeamRowAllowedAction } from '../../const/types';
import { useDeleteTeam } from '../../hooks/useDeleteTeam';

const styles = {
  actionMenu: css({
    backgroundColor: palette.neutrals.gray500,
  }),
  overflowMenuButton: css({
    padding: '.3906rem .7594rem',
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    borderRadius: '.3125rem',
  }),
  icon: css({
    height: '1.125rem',
    color: palette.neutrals.gray500,
  }),
  deleteTeam: css({
    color: palette.brand.red,
  }),
};

interface ViewProps extends Omit<UseDeleteConfirmationPopoverHookReturn, 'openConfirmationPopover'> {
  overflowMenuItems: MenuItem[][],
  handleDeleteTeamClick: () => void,
}

const View = ({
  overflowMenuItems,
  closeConfirmationPopover,
  isOpen,
  popoverId,
  anchorEl,
  handleDeleteTeamClick,
  ...props
}: ViewProps): JSX.Element => (
  <>
    <OverflowMenu
      menuItems={overflowMenuItems}
      renderOverflowMenuButton={(doOpen) => (
        <LeadrButton
          css={styles.overflowMenuButton}
          onClick={doOpen}
          variant="text"
          data-test-id="peopleTeamsActionMenu"
        >
          <FontAwesomeIcon css={styles.icon} icon={faEllipsisVertical} />
        </LeadrButton>
      )}
      {...props}
    />
    <DeleteConfirmationPopover
      closeConfirmationPopover={closeConfirmationPopover}
      open={isOpen}
      popoverId={popoverId}
      anchorEl={anchorEl}
      renderConfirmationButtons={({
        informationStyles,
        optionStyles,
        popoverButtonStyles,
      }) => (
        <DeleteConfirmationButtons
          informationStyles={informationStyles}
          optionStyles={optionStyles}
          popoverButtonStyles={popoverButtonStyles}
          onDelete={handleDeleteTeamClick}
        />
      )}
    />
  </>
);

interface ActionMenuProps {
  teamId: string,
  allowedActions: TeamRowAllowedAction[],
  navigateToTeamDetails: (teamId: string) => void,
}

const ActionMenu = ({
  teamId,
  allowedActions,
  navigateToTeamDetails,
  ...props
}: ActionMenuProps): JSX.Element => {
  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('peopleTeamsDeleteConfirmationPopover');

  const { mutate: deleteTeamMutation } = useDeleteTeam();

  const handleDeleteTeamClick = (): void => {
    deleteTeamMutation(teamId);
  };

  const allActions = useMemo(() => ({
    [TeamRowAction.ViewTeam]: {
      text: 'View',
      icon: faArrowRightFromLine,
      onClick: () => {
        navigateToTeamDetails(teamId);
      },
    },
    [TeamRowAction.DeleteTeam]: {
      text: 'Delete',
      icon: faTrash,
      onClick: (event: MouseEvent<HTMLLIElement>) => {
        openConfirmationPopover(event);
      },
      styles: {
        menuText: styles.deleteTeam,
        menuItem: styles.deleteTeam,
        iconColor: 'red',
      },
    },
  }), [navigateToTeamDetails, openConfirmationPopover, teamId]);

  const overflowMenuItems: MenuItem[][] = useMemo(() => [allowedActions.map((action) => allActions[action.actionName])], [allActions, allowedActions]);

  const hookProps = {
    overflowMenuItems,
    anchorEl,
    closeConfirmationPopover,
    isOpen,
    popoverId,
    handleDeleteTeamClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionMenu;
