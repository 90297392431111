import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { SerializedStyles } from '@emotion/react';
import { requestorFeedbackDetailTemplate } from '~Feedback/components/Drawers/RequestorDetail/RequestorDetailDrawer';
import { FeedbackType } from '~Feedback/const/interfaces';
import { viewGoalDetailsTemplate } from '~Goals/components/Drawers/ViewGoalDetailsDrawer';
import { ResourceType } from '~DevelopmentPlan/const/types';
import { createEditActionItemTemplate } from '~ActionItems/components/Drawers/CreateEditActionItemDrawer';
import { receivedLearningDrawerTemplate } from '~Learning/components/ReceivedLearningDashboard/ReceivedLearningDrawer';
import { receivedPlaylistDrawerTemplate } from '~Learning/components/ReceivedLearningDashboard/ReceivedPlaylistDrawer';

interface ActionForReflectionProps {
  resourceType: ResourceType,
  subjectUid: string,
  renderBottomButton?: () => JSX.Element,
  ownerId?: string,
  ownerOrgID: string,
}

function actionForResource({
  resourceType,
  subjectUid,
  renderBottomButton,
  ownerOrgID,
}: ActionForReflectionProps): Record<string, unknown> {
  switch (resourceType) {
    case ResourceType.Goal:
      return pushDrawerAction({
        drawer: {
          ...viewGoalDetailsTemplate,
          args: {
            goalId: subjectUid,
            isReadOnly: true,
            renderBottomButton,
          },
        },
      });
    case ResourceType.Feedback:
      return pushDrawerAction({
        drawer: {
          ...requestorFeedbackDetailTemplate,
          args: {
            id: subjectUid,
            type: FeedbackType.REQUESTED,
            assigneeId: ownerOrgID,
            isReadOnly: true,
            renderBottomButton,
          },
        },
      });
    case ResourceType.ActionItem:
      return pushDrawerAction({
        drawer: {
          ...createEditActionItemTemplate,
          args: {
            id: subjectUid,
          },
        },
      });

    case ResourceType.Learning:
      return pushDrawerAction({
        drawer: {
          ...receivedLearningDrawerTemplate,
          args: {
            learningId: subjectUid,
            learningOwnerId: ownerOrgID,
            showAddToMeetingButton: false,
            isReadOnly: true,
          },
        },
      });
    case ResourceType.LearningPlaylist:
      return pushDrawerAction({
        drawer: {
          ...receivedPlaylistDrawerTemplate,
          args: {
            playlistId: subjectUid,
            learningOwnerId: ownerOrgID,
            isReadOnly: true,
          },
        },
      });
    default:
      return {};
  }
}

interface OpenDrawerProps {
  resourceType: ResourceType,
  subjectUid: string,
  renderBottomButton?: (bottomButtonStyles?: SerializedStyles) => JSX.Element,
  ownerId: string,
  ownerOrgID: string,
}

interface UseResourceDetailsDrawerReturn {
  openDrawer: (props: OpenDrawerProps) => void,
  closeDrawer: () => void,
}

export function useResourceDetailsDrawer(): UseResourceDetailsDrawerReturn {
  const dispatch = useDispatch();

  const openDrawer = ({
    resourceType,
    subjectUid,
    ownerId,
    ownerOrgID,
    renderBottomButton,
  }: OpenDrawerProps): void => {
    dispatch(actionForResource({
      resourceType,
      subjectUid,
      ownerId,
      renderBottomButton,
      ownerOrgID,
    }));
  };

  const closeDrawer = (): void => {
    // @ts-expect-error : Fix when drawers are typed
    dispatch(popDrawerAction({
      popAll: true,
    }));
  };

  return {
    openDrawer,
    closeDrawer,
  };
}
