import { css } from '@emotion/react';

const styles = {
  boldedText: css({
    fontWeight: 600,
  }),
};

interface RecognitionTextProps {
  isReviewee: boolean,
  revieweeFullName: string,
}

const RecognitionText = ({
  isReviewee,
  revieweeFullName,
  ...props
}: RecognitionTextProps): JSX.Element => (
  <span {...props}>
    {'Recognition '}
    {isReviewee && (
      <span>{'given to you '}</span>
    )}
    {!isReviewee && (
      <span>
        given to
        <span css={styles.boldedText}>{` ${revieweeFullName} `}</span>
      </span>
    )}
    {'that falls '}
  </span>
);

export default RecognitionText;
