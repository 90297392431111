import { css } from '@emotion/react';
import { faClose, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import {
  useCallback,
  useMemo,
} from 'react';

import { useGetLandingPageUrl } from '~Common/hooks/useGetHomePageUrl';
import { palette } from '~Common/styles/colors';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { NAVBAR_LEFT_PADDING } from '~Root/components/NavigationSidebar/types';
import { useNavigationStore } from '~Root/components/NavigationSidebar/useNavigationStore';
import {
  baseNavigationItems,
  organizationNavigationItems,
  alignmentNavigationItems,
  growthNavigationItems,
  nexusNavigationItems,
  devToolNavigationItems,
} from '~Root/components/NavigationSidebar/navigationItemList';
import LeadrShieldIndigo from '~Assets/images/leadrShieldIndigo.svg';
import LeadrLogoIndigo from '~Assets/images/leadrLogoIndigo.svg';
import LeadrAdvantageLogo from '~Root/assets/images/leadrAdvantageLogo.svg';
import LeadrAdvantageIconLogo from '~Root/assets/images/leadrAdvantageIconLogo.svg';
import { useShowGlobalSearchModal } from '~GlobalSearch/stores/useShowGlobalSearchModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  headerButton: css({
    fontSize: '1.5rem',
  }),
  divider: css({
    backgroundColor: palette.neutrals.gray200,
    height: '1px',
    margin: `0rem ${NAVBAR_LEFT_PADDING} !important`,
  }),
  logoContainer: css({
    alignItems: 'center',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
    color: palette.neutrals.gray700,
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: NAVBAR_LEFT_PADDING,
    marginRight: '1rem',
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
  }, IS_MOBILE_APP && {
    paddingTop: 'calc(1rem + env(safe-area-inset-top))',
  }),
  logo: css({
    height: '2rem',
  }),
  // Even though these are currently the same, it's nice to have the separation in case we use different images or need to update one
  partnerLogo: (compact: boolean) => css({
    height: '2.5rem',
  }, compact && {
    height: '2.5rem',
  }),
  partnerContent: (compact: boolean) => css({
    margin: '-0.5rem 0 0 0',
    fontStyle: 'italic',
    fontWeight: 600,
    color: palette.neutrals.gray800,

    ':hover': {
      textDecoration: 'none',
    },
  }, compact && {
    display: 'none',
  }),
  logosContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  partnerWrap: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  imageContainer: css({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  }),
  pageTitle: css({
    alignItems: 'center',
    color: palette.neutrals.gray800,
    display: 'flex',
    flex: 1,
    fontSize: '1.125rem',
    fontWeight: 600,
    marginLeft: '1rem',
  }),
};

export interface ViewProps extends NavigationHeaderProps {
  closeMobileNavigation: () => void,
  landingPageUrl: string,
  canViewTableGroup: boolean,
  mobileLabel?: string,
  onSearchClick?: () => void,
}

const View = ({
  closeMobileNavigation,
  landingPageUrl,
  compact,
  isMobile,
  canViewTableGroup,
  mobileLabel,
  onSearchClick,
}: ViewProps): JSX.Element => (
  <section css={styles.logoContainer}>
    <div css={styles.imageContainer}>
      <Link to={landingPageUrl}>
        {canViewTableGroup && (
          <>
            <div
              css={styles.logosContainer}
            >
              {(compact || isMobile) && (
                <LeadrAdvantageIconLogo
                  css={styles.partnerLogo(compact)}
                  title="The Table Group icon logo"
                  data-test-id="tableGroupIconLogo"
                />
              )}
              {!compact && !isMobile && (
                <LeadrAdvantageLogo
                  css={styles.partnerLogo(compact)}
                  title="The Table Group logo"
                  data-test-id="tableGroupLogo"
                />
              )}
            </div>
          </>
        )}

        {!canViewTableGroup && (
          <>
            {(compact || isMobile) && (
              <LeadrShieldIndigo
                title="Leadr icon logo"
                data-test-id="leadrShieldIndigo"
                css={styles.logo}
              />
            )}
            {!compact && !isMobile && (
              <LeadrLogoIndigo
                title="Leadr logo"
                data-test-id="leadrLogoIndigo"
                css={styles.logo}
              />
            )}
          </>
        )}
      </Link>

      {isMobile && (
        <title css={styles.pageTitle}>
          {mobileLabel}
        </title>
      )}
    </div>

    <div>
      {isMobile && (
        <>
          <LeadrButton
            css={styles.headerButton}
            data-test-id="navigationSearch"
            variant="text"
            textButtonColor={palette.neutrals.gray700}
            onClick={onSearchClick}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </LeadrButton>

          <LeadrButton
            css={styles.headerButton}
            data-test-id="openMobileNavigation"
            variant="text"
            textButtonColor={palette.neutrals.gray700}
            onClick={closeMobileNavigation}
          >
            <FontAwesomeIcon icon={faClose} />
          </LeadrButton>
        </>
      )}
    </div>
  </section>
);

export interface NavigationHeaderProps {
  compact: boolean,
  isMobile: boolean,
}

export const NavigationHeader = ({
  compact,
  isMobile,
}: NavigationHeaderProps): JSX.Element => {
  const location = useLocation();
  const landingPageUrl = useGetLandingPageUrl();
  const closeMobileNavigation = useNavigationStore((state) => state.closeMobileNavigation);

  const mobileLabel = useMemo(() => (
    [
      ...baseNavigationItems,
      ...organizationNavigationItems,
      ...alignmentNavigationItems,
      ...growthNavigationItems,
      ...nexusNavigationItems,
      ...devToolNavigationItems,
    ].find((navItem) => (
      navItem.href && location.pathname.includes(navItem.href)
    ))?.label
  ), [
    location.pathname,
  ]);

  const isTableGroupCobrandingEnabled = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const {
    setShowModal,
  } = useShowGlobalSearchModal((state) => ({
    setShowModal: state.setShowModal,
  }));

  const onSearchClick = useCallback(() => {
    setShowModal(true);
    closeMobileNavigation();
  }, [
    setShowModal,
    closeMobileNavigation,
  ]);

  const { enableTheTable } = useOrgDetailsContext();
  const canViewTableGroup = !!enableTheTable && isTableGroupCobrandingEnabled;

  const hookProps = {
    closeMobileNavigation,
    compact: compact && !isMobile,
    isMobile,
    landingPageUrl,
    canViewTableGroup,
    mobileLabel,
    onSearchClick,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
