import { css } from '@emotion/react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { printColors } from '~Common/styles/colors';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';
import PersonHeading from './PersonHeading';
import FreeformResponse from './FreeformResponse';

const styles = {
  container: css``,
  section: css`
    margin-top: 3.4375rem;
    &:not(:last-child) {
      padding-bottom: 3.75rem;
      border-bottom: .0625rem solid ${printColors.responseBorders};
    }
  `,
  question: css`
    margin-bottom: 3rem;
  `,
  questionText: css`
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 1rem;
  `,
};

const View = ({ answeredFirstSortedPeople, ...props }) => (
  <div
    css={styles.container}
    {...props}
  >
    {answeredFirstSortedPeople.map((person) => (
      <div css={styles.section} key={person.userId}>
        <PersonHeading userId={person.userId} />
        {person.questions?.map((question) => (
          <div key={question.questionId} css={styles.question}>
            {isHTMLText(question.questionText) && (
            <HTMLRenderer css={styles.questionText} htmlText={question.questionText} />
            )}
            {!isHTMLText(question.questionText) && (
            <div css={styles.questionText}>
              {question.questionText}
            </div>
            )}
            <div>
              <FreeformResponse
                userId={person.userId}
                richText={question?.responseText || ''}
                status={ANSWER_STATUS.COMPLETED}
                css={styles.view}
                tooltip="Add this response to the next meeting"
              />
            </div>
          </div>
        ))}
        {person.questions.length === 0 && (
          <div css={styles.question}>Waiting for Response...</div>
        )}
      </div>
    ))}
  </div>
);

View.propTypes = {
  answeredFirstSortedPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

View.defaultProps = {};

const ResultsByPerson = ({ groupByPeople, ...props }) => {
  const answeredFirstSortedPeople = useMemo(() => groupByPeople?.sort((person, person2) => {
    if (person.hasCompletedFeedback === person2.hasCompletedFeedback) {
      return 0;
    } if (person.hasCompletedFeedback) {
      return -1;
    }
    return 1;
  }), [groupByPeople]);

  const hookProps = {
    answeredFirstSortedPeople,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ResultsByPerson.propTypes = {
  groupByPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ResultsByPerson.defaultProps = {};

export { View };
export default ResultsByPerson;
