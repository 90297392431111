import { useQuery } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getPreSignedUrlService } from './api';

export const useProfileImagePreSignedUrl = () => {
  const {
    data,
    refetch,
  } = useQuery({
    queryKey: [getOrganizationId(), 'profileImageUpload', 'png'],
    queryFn: getPreSignedUrlService,
    staleTime: 0,
    enabled: false,
  });

  const response = data?.response;

  return {
    response,
    refetch,
  };
};
