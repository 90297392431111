import { css } from '@emotion/react';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import { HTMLString } from '~Common/types';
import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';
import LearningSectionsCard, { LearningSectionsCardProps } from '~Learning/components/Shared/LearningSectionsCard';
import { LearningType } from '~Learning/const/interfaces';

const styles = {
  body: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  title: css({
    color: palette.neutrals.gray900,
    fontSize: '1.25rem',
    fontWeight: 600,
  }, withTruncate()),
  author: css({
    color: palette.neutrals.gray500,
    fontWeight: 600,
    fontSize: '0.8125rem',
    marginTop: '0.625rem',
  }),
  description: css({
    marginTop: '1.25rem',
    color: palette.neutrals.gray700,
  }),
};

interface PlaylistTemplateDetailsCardProps extends Omit<LearningSectionsCardProps, 'renderHeader' | 'renderBody'> {
  title: string,
  descriptionHTML: HTMLString,
  author: string,
}

const PlaylistTemplateDetailsCard = ({
  title,
  descriptionHTML,
  author,
  ...props
}: PlaylistTemplateDetailsCardProps): JSX.Element => (
  <LearningSectionsCard
    renderHeader={() => (
      <LearningTypeInfo
        learningType={LearningType.PLAYLIST}
      />
    )}
    renderBody={() => (
      <div css={styles.body}>
        <div css={styles.title}>{title}</div>
        <div css={styles.author}>{`Created by ${author}`}</div>
        <HTMLRenderer css={styles.description} htmlText={descriptionHTML} />
      </div>
    )}
    {...props}
  />
);

export default PlaylistTemplateDetailsCard;
