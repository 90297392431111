import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { css } from '@emotion/react';
import { Collapse, FormControlLabel, Radio } from '@mui/material';
import { useDispatch } from 'react-redux';
import { compact, uniq } from 'lodash';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { User } from '~Common/const/interfaces';
import { useGetPeopleFilters } from '~Common/hooks/useGetPeopleFilters';
import { UserGroup as UserGroupType } from '~UserGroups/hooks/useGetGroups';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { palette, radioColor } from '~Common/styles/colors';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import Button from '~Common/V3/components/Button';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';

export enum FilterType {
  Everyone = 'none',
  Department = 'department',
  Manager = 'manager',
  UserGroup = 'usergroup',
}

function pullManagerUsers(allPeople: User[]): User[] {
  const managerIds = uniq(compact(Object.values(allPeople).map((person) => person.managerId)));
  const managerUsers = allPeople.filter((person) => managerIds.includes(person.id));

  return managerUsers;
}

const styles = {
  drawerBody: css({
    color: palette.neutrals.gray700,
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
  closeButton: css`
    border: none;
    box-shadow: none;
    background-color: transparent;
  `,
  everyone: css({
    marginBottom: '0',
  }),
  heading: css({
    alignItems: 'center',
    color: palette.neutrals.gray800,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '1rem',
    marginBottom: '0.5rem',
    marginTop: '1.25rem',
    padding: 0,
    width: '100%',

    svg: {
      display: 'block',
    },
  }),
  label: css({
    marginBottom: '-0.5rem',
    width: '100%',
    '& .MuiFormControlLabel-label': {
      color: palette.neutrals.gray700,
      fontSize: '0.875rem',
    },
  }),
  radio: css({
    color: `${radioColor} !important`,
    '& .MuiSelected': {
      color: `${radioColor} !important`,
    },
  }),
  switch: css({
    margin: '1.25rem 0',
  }),
};

const peopleMetricsFiltersTemplate = {
  name: 'INSIGHTS_PEOPLE_METRICS_FILTERS',
  width: DRAWER_WIDTHS.BASE,
};

interface ViewProps {
  closeDrawerClick: () => void,
  departments: {
    value: string;
    total: number;
  }[],
  expandedMenus: string[],
  filterType: FilterType,
  filterValue: string,
  handleClick: (filterType: FilterType, filterValue: string) => void,
  handleClickHeading: (filterType: FilterType) => void,
  managerUsers: User[],
  userGroups: Pick<UserGroupType, 'name' | 'teamId'>[],
}

const View = ({
  closeDrawerClick,
  departments,
  expandedMenus,
  filterType,
  filterValue,
  handleClick,
  handleClickHeading,
  managerUsers,
  userGroups,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={() => (
          <button onClick={closeDrawerClick} css={styles.closeButton} title="Close drawer">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        renderDrawerInstructions={() => (
          <p>Select one group at a time to view stats.</p>
        )}
        title="People Metrics Filter"
      />
    )}
    renderBody={() => (
      <div
        css={styles.drawerBody}
      >
        <FormControlLabel
          label="Everyone"
          css={[styles.label, styles.everyone]}
          control={(
            <Radio
              css={styles.radio}
              checked={filterType === FilterType.Everyone}
              onChange={() => handleClick(FilterType.Everyone, '')}
            />
          )}
        />
        <Button
          css={styles.heading}
          variant="text"
          onClick={() => handleClickHeading(FilterType.Department)}
          renderContents={() => (
            <>
              Departments
              <FontAwesomeIcon icon={expandedMenus.includes(FilterType.Department) ? faMinus : faPlus} />
            </>
          )}
        />
        <Collapse
          in={expandedMenus.includes(FilterType.Department)}
        >
          {departments.map((department) => (
            <FormControlLabel
              key={`${department.total}-${department.value}`}
              label={department.value}
              css={styles.label}
              control={(
                <Radio
                  css={styles.radio}
                  checked={filterType === FilterType.Department && filterValue === department.value}
                  onClick={() => handleClick(FilterType.Department, department.value)}
                />
              )}
            />
          ))}
        </Collapse>

        <Button
          css={styles.heading}
          variant="text"
          onClick={() => handleClickHeading(FilterType.Manager)}
          renderContents={() => (
            <>
              Manager Direct Reports
              <FontAwesomeIcon icon={expandedMenus.includes(FilterType.Manager) ? faMinus : faPlus} />
            </>
          )}
        />
        <Collapse
          in={expandedMenus.includes(FilterType.Manager)}
        >
          {managerUsers.map((manager) => (
            <FormControlLabel
              key={manager.id}
              label={`${manager.firstName} ${manager.lastName}'s Direct Reports`}
              css={styles.label}
              control={(
                <Radio
                  css={styles.radio}
                  checked={filterType === FilterType.Manager && filterValue === manager.id}
                  onClick={() => handleClick(FilterType.Manager, manager.id)}
                />
              )}
            />
          ))}
        </Collapse>

        <Button
          css={styles.heading}
          variant="text"
          onClick={() => handleClickHeading(FilterType.UserGroup)}
          renderContents={() => (
            <>
              Teams
              <FontAwesomeIcon icon={expandedMenus.includes(FilterType.UserGroup) ? faMinus : faPlus} />
            </>
          )}
        />
        <Collapse
          in={expandedMenus.includes(FilterType.UserGroup)}
        >
          {userGroups.map((group) => (
            <FormControlLabel
              key={group.teamId}
              label={group.name}
              css={styles.label}
              control={(
                <Radio
                  css={styles.radio}
                  checked={filterType === FilterType.UserGroup && filterValue === group.teamId}
                  onClick={() => handleClick(FilterType.UserGroup, group.teamId)}
                />
              )}
            />
          ))}
        </Collapse>
      </div>
    )}
  />
);

interface FilterDrawerProps {
  allPeople: User[],
  updateFilteredOrgUserIds: (filterType: FilterType, filterValue: string) => void,
}

const FilterDrawer = ({
  allPeople,
  updateFilteredOrgUserIds,
}: FilterDrawerProps): JSX.Element => {
  const dispatch = useDispatch();
  const [filterType] = useQueryParamState<FilterType>('insights', 'filterType', FilterType.Everyone);
  const [filterValue] = useQueryParamState<string>('insights', 'filterValue', '');
  const { groups: userGroups, departments } = useGetPeopleFilters();
  const [expandedMenus, setExpandedMenus] = useState<string[]>([]);

  const managerUsers = useMemo(() => pullManagerUsers(allPeople), [allPeople]);

  if (filterType !== FilterType.Everyone && !expandedMenus.includes(filterType)) {
    setExpandedMenus([...expandedMenus, filterType]);
  }

  useEffect(() => {
    updateFilteredOrgUserIds(filterType, filterValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPeople]);

  const handleClick = useCallback((clickedFilterType: FilterType, clickedFilterValue: string) => {
    // LW-8989 : We want to allow users to 'un-click' a filter
    if (filterType === clickedFilterType && filterValue === clickedFilterValue) {
      updateFilteredOrgUserIds(FilterType.Everyone, '');
    } else {
      updateFilteredOrgUserIds(clickedFilterType, clickedFilterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, filterValue]);

  const handleClickHeading = useCallback((clickedFilterType: FilterType) => {
    const result = Array.from(expandedMenus);
    const index = result.indexOf(clickedFilterType);

    if (index === -1) {
      result.push(clickedFilterType);
    } else {
      result.splice(index, 1);
    }

    setExpandedMenus([...result]);
  }, [expandedMenus]);

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
  };

  const hookProps = {
    closeDrawerClick,
    departments,
    expandedMenus,
    filterType,
    filterValue,
    handleClick,
    handleClickHeading,
    managerUsers,
    userGroups,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: peopleMetricsFiltersTemplate.name,
  component: FilterDrawer,
});

export { View, peopleMetricsFiltersTemplate };
export default FilterDrawer;
