import { DataGridProProps, GridRowParams } from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { useHistory } from 'react-router-dom';
import CustomTreeCell from '../../Tables/GoalsTable/CustomTreeCell';
import StyledGoalsTable from './StyledGoalsTable';

interface ViewProps extends DataGridProProps {
  onRowClick: (params: GridRowParams<{ id: string }>) => void,
}

const View = ({
  onRowClick,
  ...props
}: ViewProps): JSX.Element => (
  <StyledGoalsTable
    treeData
    groupingColDef={{
      headerName: 'Name',
      flex: 3,
      minWidth: 175,
      renderCell: CustomTreeCell,
    }}
    disableColumnFilter
    disableColumnMenu
    sortingMode="server"
    onRowClick={onRowClick}
    {...props}
  />
);

type CascadingGoalsTableProps = Omit<ViewProps, 'onRowClick'>;

const CascadingGoalsTable = ({ ...props }: CascadingGoalsTableProps): JSX.Element => {
  const history = useHistory();

  const onRowClick = useCallback((params: GridRowParams<{ id: string }>) => {
    history.push(GoalsRoutes.ViewById.replace(':goalId', params.id as string));
  }, [history]);

  const hookProps = {
    onRowClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CascadingGoalsTable;
