import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import {
  LearningStatus, SCRequestedLearningDetail,
} from '~Learning/const/interfaces';
import LearningPreview from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import { useRecallLearning } from '~Learning/hooks/assigned/useRecallLearning';
import RecallConfirmationButtons from '~Learning/components/AssignedLearningDashboard/RecallConfirmationButtons';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import RequestedLearningTabWrapper from '../RequestedLearningTabWrapper';

const styles = {
  learningPreview: css({
    marginBottom: '1rem',
  }),
  recallLearningButton: css({
    marginLeft: '.625rem',
  }),
};

interface ViewProps {
  numberOfCompletedLearnings: number,
  contentUrl: string,
  onRecallLearningClick: () => void,
  learning: SCRequestedLearningDetail,
  showViewParticipantsButton: boolean,
}

const View = ({
  numberOfCompletedLearnings,
  onRecallLearningClick,
  learning,
  contentUrl,
  showViewParticipantsButton,
}: ViewProps): JSX.Element => (
  <>
    <LearningPreview
      contentUrl={contentUrl}
      css={styles.learningPreview}
    />
    <RequestedLearningTabWrapper
      title={learning.title}
      introduction={learning.introduction}
      assignees={learning.assignees}
      dueDate={learning.dueDate}
      learningId={learning.id}
      status={learning.status}
      showViewParticipantsButton={showViewParticipantsButton}
      renderBottomButtons={() => (
        <>
          {numberOfCompletedLearnings < 1 && (
            <DeleteButtonWithConfirmation
              css={styles.recallLearningButton}
              renderDeleteButton={({ onClick }) => (
                <LeadrButton
                  onClick={onClick}
                  color="danger"
                  variant="ghost"
                  data-test-id="learningRecall"
                >
                  Recall Learning
                </LeadrButton>
              )}
              renderConfirmationButtons={({ informationStyles, optionStyles, popoverButtonStyles }) => (
                <RecallConfirmationButtons
                  informationStyles={informationStyles}
                  optionStyles={optionStyles}
                  popoverButtonStyles={popoverButtonStyles}
                  onRecall={onRecallLearningClick}
                />
              )}
            />
          )}
        </>
      )}
    />
  </>
);

interface DetailsTabProps extends Omit<ViewProps, 'contentUrl' | 'numberOfCompletedLearnings' | 'onRecallLearningClick' | 'learning'> {
  learning: SCRequestedLearningDetail,
}

const DetailsTab = ({
  learning,
  ...props
}: DetailsTabProps): JSX.Element => {
  const recallLearningMutation = useRecallLearning();

  const numberOfCompletedLearnings = learning.assignees.filter((assignee) => assignee.status === LearningStatus.COMPLETED).length;

  const hookProps = {
    contentUrl: learning.contentUrl,
    numberOfCompletedLearnings,
    onRecallLearningClick: () => {
      recallLearningMutation({
        userIds: learning.assignees.map((learningAssignee) => learningAssignee.assignee.id),
        learningId: learning.id,
        isRecallingFromAll: true,
      });
    },
    learning,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default DetailsTab;
