import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { palette } from '~Common/styles/colors';
import { withLineClamp, withTruncate } from '~Common/styles/mixins';

const styles = {
  details: css({
    minWidth: 0,
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1rem',
  }, withTruncate()),
  introduction: css({
    color: palette.neutrals.gray500,
    fontWeight: 600,
    fontSize: '.8125rem',
    marginTop: '.625rem',
  }, withLineClamp(2)),
};

interface ViewProps extends ComponentProps<'div'> {
  title: string,
  introduction?: string,
  renderDueDate?: () => JSX.Element,
}

const Details = ({
  title,
  introduction,
  renderDueDate,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.details} {...props}>
    <div css={styles.title}>
      {title}
    </div>
    {introduction && (
      <HTMLRenderer css={styles.introduction} htmlText={introduction} />
    )}
    {renderDueDate?.()}
  </div>
);

export default Details;
