import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { ManagerType } from '~Admin/hooks/useGetPeopleManagers';

export interface Manager {
  id: string;
  type: ManagerType;
}
interface EmployeeManagers {
  orgUserId: string,
  managers: Manager[],
}

interface GetUserManagersResponse {
  items: EmployeeManagers[],
}

const getUserManagers = async (): Promise<HttpCallReturn<GetUserManagersResponse>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/ops/managers`;
  return getApi<GetUserManagersResponse>(URL);
};

interface useGetEmployeeManagersReturn {
  normalizedManagers: Record<string, Manager[]>,
  isLoading: boolean,
}

export const useGetEmployeeManagers = (): useGetEmployeeManagersReturn => {
  const result = useQuery({
    queryKey: [getOrganizationId(), 'user-managers'],
    queryFn: getUserManagers,
  });
  const empAndManager = result.data?.response?.items;
  const normalizedManagers = useMemo(() => {
    const data: Record<string, Manager[]> = {};
    // loop over items
    // for each item, do the following:
    empAndManager?.forEach((emp) => {
      if (emp.managers?.length) {
        data[emp.orgUserId] = emp.managers;
      }
    });
    return data;
    // data[item.orgUserId] = item.managers
    // and return data, which will be a dictionary of users:managers that you can do lookups on
  }, [empAndManager]);
  return {
    normalizedManagers,
    isLoading: result.isLoading,
  };
};
