import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import { ViewPerspective } from '~Goals/const/types';
import { ChangeEvent } from 'react';
import { useGoalsIndexSearch } from '~Goals/stores/useGoalsIndexSearch';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { forMobileTinyObject } from '~Common/styles/mixins';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { TabNavigation } from './TabNavigation';

const styles = {
  moduleTopbar: css({
    gridTemplateAreas: '"toggleTabs searchField rightSection"',
    gridTemplateColumns: 'auto 1fr auto',
  }, forMobileTinyObject({
    gridTemplateAreas: `
    "toggleTabs toggleTabs"
    "searchField rightSection"
  `,
    gridTemplateColumns: '1fr auto',
  })),
  toggleTab: css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    width: '50%',
  })),
};

interface ViewProps {
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  searchText: string,
  viewPerspective: ViewPerspective,
  isLimitedAccess: boolean,
}

const View = ({
  handleSearchTextChange,
  searchText,
  viewPerspective,
  isLimitedAccess,
}: ViewProps): JSX.Element => (
  <ModuleTopbar
    css={styles.moduleTopbar}
    moduleTopbarLayout="custom"
    renderTabNavigationToggle={() => (
      <>
        {viewPerspective !== ViewPerspective.Create && (
          <TabNavigation
            viewPerspective={viewPerspective}
            toggleTabStyles={styles.toggleTab}
          />
        )}
        {viewPerspective === ViewPerspective.Create && (
          <LeadrButton
            type="submit"
            data-test-id="goalsBack"
            variant="text"
          >
            Back
          </LeadrButton>
        )}
      </>
    )}
    renderSearchField={() => (
      <>
        {viewPerspective !== ViewPerspective.Create && (
          <>
            <LeadrSearchField
              data-test-id="goalsTopBarSearchField"
              defaultValue={searchText}
              onChange={handleSearchTextChange}
            />
          </>
        )}
      </>
    )}
    renderRightSection={() => (
      <>
        {viewPerspective !== ViewPerspective.Create && !isLimitedAccess && (
          <CreateButton
            component={Link}
            to={GoalsRoutes?.Create}
            itemName="Goal"
            data-test-id="goalsCreateNew"
          />
        )}
        {viewPerspective === ViewPerspective.Create && (
          <>
            <LeadrButton
              type="submit"
              data-test-id="goalsCancelNew"
              variant="ghost"
            >
              Cancel
            </LeadrButton>
            <LeadrButton
              type="submit"
              data-test-id="goalsCreateNew"
            >
              Save Goal
            </LeadrButton>
          </>
        )}
      </>
    )}
  />
);

interface GoalsTopBarProps {
  viewPerspective: ViewPerspective,
}

const GoalsTopBar = ({
  viewPerspective,
}: GoalsTopBarProps): JSX.Element => {
  const {
    searchText,
    setSearchText,
  } = useGoalsIndexSearch((state) => ({
    searchText: state.searchText,
    setSearchText: state.setSearchText,
  }));
  const isMobile = useIsMobileQuery();

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const { isLimitedAccess } = useUserPermissions();

  const hookProps = {
    handleSearchTextChange,
    searchText,
    viewPerspective,
    isMobile,
    isLimitedAccess,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };

export default GoalsTopBar;
