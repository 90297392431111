import { ComponentProps } from 'react';
import { css } from '@emotion/react';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import CareCardAvatar from '~Common/V3/components/CareCardAvatar';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import PrivateIcon from '~Meetings/components/details/agenda/PrivateIcon';
import { AgendaItemActionMenu } from '~Meetings/components/details/agenda/AgendaTopic/AgendaTopicActionMenu';
import { AgendaTopic } from '~Meetings/hooks/v2/useGetAgendas';

const styles = {
  rightSideContainer: css({
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '0.5rem',
  }),
};

export interface RightSideComponentProps extends ComponentProps<'div'> {
  agendaItem: AgendaTopic,
  creatorOrgUserId: string,
  huddleId: string,
  onAgendaEditClick: () => void,
  type: MeetingTypeEnum,
  sectionId?: string,
}

const RightSideComponent = ({
  agendaItem,
  creatorOrgUserId,
  huddleId,
  onAgendaEditClick,
  type,
  sectionId,
}: RightSideComponentProps): JSX.Element => (
  <div css={styles.rightSideContainer}>
    {agendaItem.isPrivate && (
      <PrivateIcon />
    )}
    {!agendaItem.isPrivate && (
      <CareCardAvatar
        id={creatorOrgUserId}
        noBackdrop
        renderAvatar={(imageUrl, fullName, isDeactivated) => (
          <SquareAvatar
            imgUrl={imageUrl}
            name={fullName}
            isDeactivated={isDeactivated}
            width={30}
            height={30}
            includeInitials
          />
        )}
        renderSkeletonLoader={() => (
          <SkeletonLoader
            width={30}
            height={30}
            // @ts-expect-error TS is a lier. variant="rect" is valid
            variant="rect"
            renderComponent={() => <div />}
          />
        )}
      />
    )}

    <AgendaItemActionMenu
      agendaItem={agendaItem}
      huddleId={huddleId}
      meetingType={type}
      onAgendaEditClick={onAgendaEditClick}
      sectionId={sectionId}
    />
  </div>
);

export default RightSideComponent;
