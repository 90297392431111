import {
  postApi, getApi,
} from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';

export const sendResponseToContentQuestionDataService = async (requestBody) => {
  const { questionId, answer } = requestBody;
  // Send response to questions in parallel.
  const serverUrl = { host: hosts.questions, uri: `/organizations/${getOrganizationId()}/questions/${questionId}/responses` };
  return postApi(serverUrl, answer, {});
};

export const getContentDetailsByIdDataService = async ({ payload }) => {
  const URL = {
    host: hosts.content,
    uri: `/organizations/${getOrganizationId()}/contents/${payload.contentId}/assignee/${payload.assigneeId}`,
  };
  return getApi(URL, {}, {});
};
