import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  getOrganizationId, getUserId,
} from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import moment from 'moment-timezone';
import { UserGroup } from '~Common/const/userGroups';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { useEffect } from 'react';
import { palette } from '~Common/styles/colors';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

declare global {
  interface Window { Beacon: (action: string, payload?: unknown) => void }
}

export interface HelpScoutIdentity {
  hash: string,
}

export const getHelpScoutIdentity = (): Promise<HttpCallReturn<HelpScoutIdentity>> => {
  const URL = {
    host: hosts.auth,
    uri: '/users/helpscout/hash',
  };
  return getApi(URL, {});
};

export const useHelpScoutIdentity = (): UseQueryResult<HttpCallReturn<HelpScoutIdentity>> => (
  useQuery({
    queryKey: ['helpscout', 'identity'],
    queryFn: getHelpScoutIdentity,
    staleTime: Infinity,
    gcTime: Infinity,
  })
);

// We don't want the resource center and chat bubble to be open at the same time.
function closePendo(): void {
  // @ts-expect-error: Typing pendo is a nightmare
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  window?.pendo?.BuildingBlocks?.BuildingBlockResourceCenter?.dismissResourceCenter?.();
}

export const useHelpScout = (): void => {
  const userId = getUserId() ?? '';
  const { detail: profileDetail } = useUserProfile(userId);
  const {
    organizationName,
    organizationInTrial,
    trialEndsAt,
  } = useOrgDetailsContext();
  const { data: helpScoutIdentityResult } = useHelpScoutIdentity();

  const profileResult = profileDetail.data;

  useEffect(() => {
    window.Beacon('init', process.env.HELPSCOUT_BEACON_API_KEY);
    // @ts-expect-error : Typing Beacon is a nightmare.
    window.Beacon('on', 'open', closePendo);
  }, []);

  useEffect(() => {
    if (profileResult && helpScoutIdentityResult?.response) {
      const organizationId = getOrganizationId();
      const profileResponse = profileResult.response;
      const helpScoutIdentityResponse = helpScoutIdentityResult.response;
      const isAdminOrExec = profileResponse.userGroupName === UserGroup.Admin || profileResponse.userGroupName === UserGroup.Executive;

      window.Beacon('identify', {
        email: profileResponse.email,
        signature: helpScoutIdentityResponse.hash,
        name: `${profileResponse.firstName} ${profileResponse.lastName}`,

        avatar: profileResponse.profileImageUrl,
        company: organizationName,
        jobTitle: profileResponse.jobTitle,

        department: profileResponse.department,
        direct_reports: profileResponse.countActiveDirects || 0,
        is_trial_admin: organizationInTrial && isAdminOrExec ? 'Y' : 'N',
        is_trial_user: organizationInTrial ? 'Y' : 'N',
        organization_id: organizationId ?? '',
        organization_user_id: profileResponse.organizationUserId,
        phone: profileResponse.phoneNumber,
        trial_expiration_date: trialEndsAt ? moment(trialEndsAt).endOf('day').format('YYYY-MM-DD') : undefined,
        user_id: profileResponse.userId ?? '',
        // This is different, but I'd have to delete it from HelpScout to fix it.
        'user-type': profileResponse.applicationGroupName,
      });

      window.Beacon('config', {
        color: palette.brand.indigo,
        enableFabAnimation: false,
        mode: 'neutral',
      });
    }
  }, [helpScoutIdentityResult, organizationInTrial, organizationName, profileResult, trialEndsAt]);
};
