import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import Froala from '~Common/V3/components/Froala';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { getUserId } from '~Common/utils/localStorage';
import LearningOverviewCard from '~Learning/components/Shared/LearningOverviewCard';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import { forMobileObject } from '~Common/styles/mixins';
import { useDeleteLearningTemplate } from '~Learning/hooks/templates/singleContent/useDeleteLearningTemplate';
import { LearningTemplateDetail } from '~Learning/hooks/templates/singleContent/useGetLearningTemplate';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import { OnUseTemplateParams, AssignLearningOptions } from '~Learning/const/interfaces';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useGetCombinedLearningTemplateDetail } from '~Learning/hooks/utils/useGetCombinedLearningTemplateDetail';
import EditView from './EditView';
import { SkeletonView } from './SkeletonView';

const styles = {
  drawerBody: css({
    padding: '1rem 2.1875rem 1rem 1.5rem',
  }),
  learningContentCard: css({
    marginTop: '2.5rem',
    marginBottom: '2.125rem',
  }),
  questionsSectionTitleText: css({
    color: palette.neutrals.gray500,
    fontSize: '.625rem',
    marginTop: '2.125rem',
    marginBottom: '.625rem',
  }),
  questionsBox: css({
    marginTop: '.625rem',
  }),
  afterQuestionSpace: css({
    marginBottom: '1.5rem',
  }),
  buttonsContainer: css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    rowGap: '0.5rem',
    gridGap: '0.625rem',
  }),
  modifyButtonsContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
  }, forMobileObject({
    gridRowStart: '2',
    justifyContent: 'flex-start',
  })),
  editButton: css(
    {},
    forMobileObject({
      marginRight: '.625rem',
    }),
  ),
  deleteButton: css({
    justifySelf: 'end',
  }),
};

export const viewLearningTemplateDrawerTemplate = {
  name: 'VIEW_LEARNING_TEMPLATE_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface ViewLearningTemplateDrawerState {
  workflow: AssignLearningOptions,
}

interface ViewLearningTemplateDrawerProps extends DrawerProps<ViewLearningTemplateDrawerState> {
  templateId: string,
  page: number,
  count: number,
  isCuratedByLeadr: boolean,
  isCuratedByTableGroup?: boolean,
  onUseTemplate: ({ templateId, isPlaylist }: OnUseTemplateParams) => void,
}

const ViewLearningTemplateDrawer = ({
  templateId,
  page,
  count,
  isCuratedByLeadr,
  isCuratedByTableGroup = false,
  onUseTemplate,
  popDrawer,
  drawerState,
}: ViewLearningTemplateDrawerProps): JSX.Element => {
  const isPlaylistWorkflow = drawerState.workflow === AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST;

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: viewLearningTemplateDrawerTemplate.name });
  };

  const [isInEditMode, setIsInEditMode] = useState(false);

  const onEditClick = (): void => {
    setIsInEditMode(true);
  };

  const onCancelEdit = (): void => {
    setIsInEditMode(false);
  };

  useEffect(() => () => {
    onCancelEdit();
  }, [templateId]);

  const {
    learningTemplate,
    isLoading: templateIsLoading,
  } = useGetCombinedLearningTemplateDetail({
    templateId,
    isCuratedByLeadr,
    isCuratedByTableGroup,
  });

  const isLoading = templateIsLoading;

  const currentUserId = getUserId();

  const { isAdmin } = useUserPermissions();

  const isAdminOrTemplateOwner = isAdmin || learningTemplate?.ownerId === currentUserId;
  const canDeleteLearningTemplate = isAdminOrTemplateOwner && !isCuratedByLeadr && !isCuratedByTableGroup;
  const canEditLearningTemplate = isAdminOrTemplateOwner && !isCuratedByLeadr && !isCuratedByTableGroup;
  const showLearningTemplateQuestions = !!learningTemplate?.questions?.length && !isCuratedByLeadr;

  const deleteLearningTemplateMutation = useDeleteLearningTemplate({
    templateToPop: viewLearningTemplateDrawerTemplate,
    page,
    count,
    categoryId: learningTemplate?.categoryId || '',
  });

  const onDeleteLearningTemplate = (): void => {
    if (learningTemplate) {
      deleteLearningTemplateMutation({ templateId, categoryId: learningTemplate.categoryId });
    }
  };

  const onStartWithThisTemplateClick = (): void => {
    onUseTemplate({ templateId, isPlaylist: false });
  };

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title="Use Learning Template"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
      />
    ),
    renderBody: () => (
      <div css={styles.drawerBody}>
        {isLoading && SkeletonView()}
        <>
          {!isLoading && learningTemplate && (
            <>
              {!isInEditMode && View({
                contentUrl: learningTemplate?.contentURL,
                learningTemplate,
                canDeleteLearningTemplate,
                onDeleteLearningTemplate,
                onStartWithThisTemplateClick,
                onEditClick,
                canEditLearningTemplate,
                showLearningTemplateQuestions,
                isPlaylistWorkflow,
              })}
              {isInEditMode && (
                <EditView
                  learningTemplate={learningTemplate}
                  onCancelEdit={onCancelEdit}
                  page={page}
                  count={count}
                  curated={false}
                />
              )}
            </>
          )}
        </>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

interface ViewProps {
  contentUrl: string,
  learningTemplate: LearningTemplateDetail,
  canDeleteLearningTemplate: boolean,
  canEditLearningTemplate: boolean,
  onDeleteLearningTemplate: () => void,
  onStartWithThisTemplateClick: () => void,
  onEditClick: () => void,
  showLearningTemplateQuestions: boolean,
  isPlaylistWorkflow: boolean,
}

const View = ({
  contentUrl,
  learningTemplate,
  canDeleteLearningTemplate,
  canEditLearningTemplate,
  onDeleteLearningTemplate,
  onStartWithThisTemplateClick,
  onEditClick,
  showLearningTemplateQuestions,
  isPlaylistWorkflow,
}: ViewProps): JSX.Element => (
  <div>
    <PreviewWithExternalNavigation
      contentUrl={contentUrl}
    />
    <LearningOverviewCard
      css={styles.learningContentCard}
      title={learningTemplate?.title ?? ''}
    />
    {showLearningTemplateQuestions && (
      <div css={styles.afterQuestionSpace}>
        <div css={styles.questionsSectionTitleText}>
          QUESTIONS
        </div>
        {
          learningTemplate?.questions?.map((question) => (
            <div css={styles.questionsBox} key={question.questionId}>
              <Froala
                name={question.rank}
                isReadOnly
                richTextEditorProps={{
                  initialValue: question.questionText,
                }}
              />
            </div>
          ))
        }
      </div>
    )}
    <div css={styles.buttonsContainer}>
      <LeadrButton
        onClick={onStartWithThisTemplateClick}
        data-test-id={isPlaylistWorkflow ? 'learningLibaryAddFromThisTemplate' : 'learningLibraryUseThisTemplate'}
      >
        {isPlaylistWorkflow ? 'Add from this Template' : 'Use this Template'}
      </LeadrButton>
      <div css={styles.modifyButtonsContainer}>
        {canEditLearningTemplate && (
          <LeadrButton
            variant="ghost"
            onClick={onEditClick}
            css={styles.editButton}
            data-test-id="learningLibraryEditTemplate"
          >
            Edit
          </LeadrButton>
        )}
        {canDeleteLearningTemplate && (
          <DeleteButtonWithConfirmation
            css={styles.deleteButton}
            renderDeleteButton={({ onClick }) => (
              <LeadrButton
                onClick={onClick}
                color="danger"
                variant="ghost"
                data-test-id="learningLibraryDeleteTemplate"
              >
                Delete
              </LeadrButton>
            )}
            renderConfirmationButtons={({
              informationStyles,
              optionStyles,
              popoverButtonStyles,
            }) => (
              <DeleteConfirmationButtons
                informationStyles={informationStyles}
                optionStyles={optionStyles}
                popoverButtonStyles={popoverButtonStyles}
                onDelete={onDeleteLearningTemplate}
              />
            )}
          />
        )}
      </div>
    </div>
  </div>
);

registerDrawer({
  templateName: viewLearningTemplateDrawerTemplate.name,
  component: ViewLearningTemplateDrawer,
});

export default ViewLearningTemplateDrawer;
