import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { useGetIframelyContent } from '~Learning/hooks/useIframely';
import { CardSkeleton } from '~Common/V3/components/Card';
import { IframelyContent } from '~Learning/const/interfaces';
import UnsanitizedHTMLRenderer from '~Common/V3/components/HTML/UnsanitizedHTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';
import EmptyContent from './EmptyContent';

const styles = {
  container: css({
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
  }),
  iframelyContent: css({
    flex: 1,
    overflow: 'hidden',
    borderRadius: '.625rem',
  }),
  contentSkeleton: css({}),
  thumbnailImage: css({
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '.625rem',
  }),
};

enum ViewOption {
  Preview = 'Preview',
  Thumbnail = 'Thumbnail',
}

interface ViewProps extends Omit<ComponentProps<'div'>, 'content'> {
  content: IframelyContent | undefined,
  isLoading: boolean,
  thumbnailSource?: string,
  renderOverlay?: () => JSX.Element,
  viewOption?: 'Preview' | 'Thumbnail',
}

const View = ({
  content,
  isLoading,
  thumbnailSource,
  renderOverlay,
  viewOption = 'Preview',
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    {isLoading && (
      <CardSkeleton css={styles.contentSkeleton} />
    )}
    {!isLoading && (
      <>
        {viewOption === ViewOption.Preview && content?.html && (
          <>
            {isHTMLText(content?.html) && (
              <UnsanitizedHTMLRenderer
                css={styles.iframelyContent}
                htmlText={content.html}
                reason="Using the HTMLRenderer strips out iframes. Learning uses iframely to display learning content"
              />
            )}
            {!isHTMLText(content?.html) && (
              <div css={styles.iframelyContent}>{content.html}</div>
            )}
          </>
        )}
        {(viewOption === ViewOption.Thumbnail || !content?.html) && (
          <>
            {thumbnailSource && (
              <div>
                <img css={styles.thumbnailImage} src={thumbnailSource} alt="Content Thumbnail" />
              </div>
            )}
            {!thumbnailSource && (
              <EmptyContent
                css={[styles.iframelyContent, styles.thumbnailImage]}
              />
            )}
          </>
        )}
        {renderOverlay?.()}
      </>
    )}
  </div>
);

export interface LearningPreviewProps
  extends Omit<ViewProps, 'isLoading' | 'content' | 'contentUrl'> {
  contentUrl: string,
}

/**
 * @description Warning: You don't want to use this component directly. Generally you should use one of the components that already uses this one
*/

const LearningPreview = ({ contentUrl, ...props }: LearningPreviewProps): JSX.Element => {
  const { data: content, isLoading } = useGetIframelyContent({ url: contentUrl });
  const thumbnailSource = content?.links?.thumbnail?.[0]?.href;

  const hookProps = {
    isLoading,
    content,
    thumbnailSource,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LearningPreview;
