const appDomain = `${process.env.REACT_APP_API_DOMAIN ?? ''}`;
const islocal = false;

interface AppConfig {
  domain: string,
  apiUrl: string,
}

let appConfig: AppConfig;

if (islocal) {
  appConfig = {
    domain: appDomain,
    apiUrl: `${appDomain}/json`,
  };
} else {
  appConfig = {
    domain: appDomain,
    apiUrl: `${appDomain}/v1`,
  };
}

export { appConfig };
