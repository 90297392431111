export const huddleActions = {
  ARCHIVE_SERIES: 'ARCHIVE_SERIES',
  COPY_SHARE_LINK: 'COPY_SHARE_LINK',
  EDIT_SERIES: 'EDIT_SERIES',
  EDIT_TEAM_LINK: 'EDIT_TEAM_LINK',
  LEAVE_MEETING: 'LEAVE_MEETING',
  PRINT: 'PRINT',
  RESCHEDULE_INSTANCE: 'RESCHEDULE_INSTANCE',
  RESTORE_SERIES: 'RESTORE_SERIES',
  RESYNC_SERIES: 'RESYNC_SERIES',
  SKIP_INSTANCE: 'SKIP_INSTANCE',
  TRANSFER_OWNERSHIP: 'TRANSFER_OWNERSHIP',
  VIEW_IN_CALENDAR: 'VIEW_IN_CALENDAR',
  VIEW_PRIVATE_NOTES: 'VIEW_PRIVATE_NOTES',
};
