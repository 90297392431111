import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import CardHeader from '~Deprecated/ui/containers/Nexus/CardHeader';
import CardContentRow from '~Deprecated/ui/containers/Nexus/OrganizationCardContentRow';
import InsightPlot from '~Deprecated/ui/containers/Nexus/InsightPlot';
import FavoriteButton from '~Deprecated/ui/containers/Nexus/FavoriteButton';

import InsightGraph from '~Deprecated/ui/components/Insights/InsightPlot/InsightGraph';
import InsightMetric from '~Deprecated/ui/components/Insights/InsightMetric';
import Button, { BUTTON_VARIANTS } from '~Deprecated/ui/components/Buttons/Button';
import CardRow from '~Deprecated/ui/components/Card/CardRow';
import CardActionFooter from '~Deprecated/ui/components/Card/CardActionFooter';
import Card from '~Common/components/Cards/Card';
import CircleAvatar from '~Deprecated/ui/components/Users/Avatars/CircleAvatar';
import { noop } from '~Deprecated/utils';

const style = css`
  width: 100%;
  padding: 0.5em;
  margin: 25px 0;
`;

const rowStyle = css`
  padding: 5px;
  margin: 5px 0;
`;

const OrganizationCard = ({
  id, onEdit, onInfo,
}) => {
  const actionButtons = [
    {
      text: 'edit',
      action: () => onEdit(id),
    },
    {
      text: 'metrics',
      action: () => onInfo(id),
    },
  ];

  return (
    <Card
      css={style}
    >
      <CardRow css={rowStyle}>
        <CardHeader
          renderAvatar={(avatarProps) => (
            <CircleAvatar
              {...avatarProps}
            />
          )}
          id={id}
        />
      </CardRow>
      <CardRow css={rowStyle}>
        <CardContentRow
          id={id}
        />
      </CardRow>
      <CardRow css={rowStyle}>
        <InsightPlot
          id={id}
          renderMetric={InsightMetric}
          renderGraph={({ metric, scoreSummary }) => (
            <InsightGraph
              metric={metric}
              scoreSummaryForMetric={scoreSummary}
            />
          )}
        />
      </CardRow>
      <CardRow css={rowStyle}>
        <CardActionFooter
          leftAction={(
            <FavoriteButton
              id={id}
            />
          )}
          rightActions={actionButtons.map((button) => (
            <Button
              variant={BUTTON_VARIANTS.LINK}
              key={button.text}
              onClick={button.action}
            >
              {button.text}
            </Button>
          ))}
        />
      </CardRow>
    </Card>
  );
};

OrganizationCard.propTypes = {
  id: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onInfo: PropTypes.func,
};

OrganizationCard.defaultProps = {
  onEdit: noop,
  onInfo: noop,
};

export default React.memo(OrganizationCard, (prev, next) => prev.id === next.id);
