import { useMemo } from 'react';

import {
  ManagerReviewStatuses,
  ParticipantTypeEnum,
  ReviewCycleDetail,
  RevieweeReviewStatuses,
  SecondaryManagerReviewStatuses,
  revieweeStartedStatuses,
  reviewerStartedStatuses,
  secondaryStartedStatuses,
} from '~Reviews/V2/Const/types';

import { palette } from '~Common/styles/colors';
import { lighten } from '@mui/material';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import { BarGraphData } from '../Charts/BarChart';

interface GraphDataShape {
  x: string;
  y: number;
  participantInfo: string;
  noParticipantInfo?: string;
}

interface MakeGraphDataShapeReturn {
  currentCompletedData: GraphDataShape[];
  notCompletedData: GraphDataShape[];
  inProgressData: GraphDataShape[];
  submittedData: GraphDataShape[];
}

function makeGraphDataShape(
  totalReviewParticipants: number,
  signedOff: number,
  inProgress: number,
  submitted: number,
  labelName: string,
  naAccounts = 0,
): MakeGraphDataShapeReturn {
  const startedCount = signedOff + inProgress + submitted + naAccounts;
  const notCompleted = totalReviewParticipants - startedCount;

  const currentCompletedData: GraphDataShape[] = [
    {
      x: labelName,
      y: (signedOff / totalReviewParticipants) * 100,
      participantInfo: `${signedOff}/${totalReviewParticipants} ${labelName} have signed off their Reviews`,
      noParticipantInfo: `No ${labelName} are at this stage`,
    },
  ];

  const inProgressData: GraphDataShape[] = [
    {
      x: labelName,
      y: (inProgress / totalReviewParticipants) * 100,
      participantInfo: `${inProgress}/${totalReviewParticipants} ${labelName} are in Progress`,
      noParticipantInfo: `No ${labelName} are at this stage`,
    },
  ];

  const submittedData: GraphDataShape[] = [
    {
      x: labelName,
      y: (submitted / totalReviewParticipants) * 100,
      participantInfo: `${submitted}/${totalReviewParticipants} ${labelName} have submitted their Reviews`,
      noParticipantInfo: `No ${labelName} are at this stage`,
    },
  ];

  const notCompletedData: GraphDataShape[] = [
    {
      x: labelName,
      y: (notCompleted / totalReviewParticipants) * 100,
      participantInfo: `${notCompleted}/${totalReviewParticipants} ${labelName} have not started their Reviews`,
      noParticipantInfo: `No ${labelName} are at this stage`,
    },
  ];

  return {
    currentCompletedData,
    inProgressData,
    submittedData,
    notCompletedData,
  };
}

function setBarGraphData(review: ReviewCycleDetail): BarGraphData {
  const { reviews } = review;
  const totalReviews = reviews.length;

  const isMatrixOrg = FlaggedEnum(review.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer);

  const signedOffReviewees = reviews.filter(
    (item) => item.revieweeStatus === RevieweeReviewStatuses.Complete,
  );
  const signedOffReviewers = reviews.filter(
    (item) => item.reviewerStatus === ManagerReviewStatuses.Complete,
  );
  const signedOffSecondaryReviewers = reviews.filter(
    (item) => item.secondaryReviewerStatus === SecondaryManagerReviewStatuses.Complete,
  );

  const inProgressReviewees = reviews.filter(
    (item) => item.revieweeStatus === RevieweeReviewStatuses.Submit_Review,
  );
  const inProgressReviewers = reviews.filter(
    (item) => item.reviewerStatus === ManagerReviewStatuses.Submit_Review,
  );
  const inProgressSecondaryReviewers = reviews.filter(
    (item) => item.secondaryReviewerStatus === SecondaryManagerReviewStatuses.Submit_Review,
  );

  const submittedReviewees = reviews.filter((item) => revieweeStartedStatuses.includes(item.revieweeStatus as RevieweeReviewStatuses));
  const submittedReviewers = reviews.filter((item) => reviewerStartedStatuses.includes(item.reviewerStatus as ManagerReviewStatuses));
  const submittedSecondaryReviewers = reviews.filter((item) => secondaryStartedStatuses.includes(
      item.secondaryReviewerStatus as SecondaryManagerReviewStatuses,
  ));

  const naSecondaryReviewers = reviews.filter(
    (item) => item.secondaryReviewerStatus === SecondaryManagerReviewStatuses.NA,
  );

  const setLabels = {
    reviewee: 'Reviewees',
    reviewer: 'Primary Reviewers',
    secondaryReviewer: 'Secondary Reviewers',
  };

  const revieweeData = makeGraphDataShape(
    totalReviews,
    signedOffReviewees.length,
    inProgressReviewees.length,
    submittedReviewees.length,
    setLabels.reviewee,
  );

  const reviewerData = makeGraphDataShape(
    totalReviews,
    signedOffReviewers.length,
    inProgressReviewers.length,
    submittedReviewers.length,
    setLabels.reviewer,
  );

  const secondaryReviewerData = makeGraphDataShape(
    totalReviews,
    signedOffSecondaryReviewers.length,
    inProgressSecondaryReviewers.length,
    submittedSecondaryReviewers.length,
    setLabels.secondaryReviewer,
    naSecondaryReviewers.length,
  );

  let completedData: GraphDataShape[] = [];
  let notCompletedData: GraphDataShape[] = [];
  let inProgressData: GraphDataShape[] = [];
  let submittedData: GraphDataShape[] = [];

  completedData = [
    ...revieweeData.currentCompletedData,
    ...reviewerData.currentCompletedData,
  ];

  inProgressData = [
    ...revieweeData.inProgressData,
    ...reviewerData.inProgressData,
  ];

  submittedData = [
    ...revieweeData.submittedData,
    ...reviewerData.submittedData,
  ];

  notCompletedData = [
    ...revieweeData.notCompletedData,
    ...reviewerData.notCompletedData,
  ];

  if (isMatrixOrg) {
    completedData.push(...secondaryReviewerData.currentCompletedData);
    inProgressData.push(...secondaryReviewerData.inProgressData);
    submittedData.push(...secondaryReviewerData.submittedData);
    notCompletedData.push(...secondaryReviewerData.notCompletedData);
  }

  const data: BarGraphData = {
    labels: isMatrixOrg
      ? Object.values(setLabels)
      : Object.values(setLabels).slice(0, -1),
    datasets: [
      {
        data: notCompletedData,
        backgroundColor: [palette.brand.indigo],
        grouped: true,
        label: 'Not Started',
        minBarLength: 7,
      },
      {
        data: inProgressData,
        backgroundColor: [palette.brand.sky],
        grouped: true,
        label: 'In Progress',
        minBarLength: 7,
      },
      {
        data: submittedData,
        backgroundColor: [lighten(palette.brand.green, 0.5)],
        grouped: true,
        label: 'Submitted',
        minBarLength: 7,
      },
      {
        data: completedData,
        backgroundColor: [palette.brand.green],
        grouped: true,
        label: 'Completed',
        minBarLength: 7,
      },
    ],
  };

  return data;
}

export const useConvertToBarGraphData = (
  activeReview: ReviewCycleDetail | undefined,
): BarGraphData => {
  const barGraphData = useMemo(() => {
    if (activeReview) {
      return setBarGraphData(activeReview);
    }
    return {} as BarGraphData;
  }, [activeReview]);

  return barGraphData;
};
