import { css } from '@emotion/react';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '~Common/components/Tooltip';

import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';

const styles = {
  noTeamOwnerContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  }),
  warningIcon: css({
    color: palette.brand.orange,
  }),
  name: css({}, withTruncate()),
};

interface NameCellProps {
  hasTeamOwner: boolean,
  name: string,
}

const NameCell = ({
  hasTeamOwner,
  name,
}: NameCellProps): JSX.Element => (
  <>
    {hasTeamOwner && (
      <span css={styles.name}>{name}</span>
    )}
    {!hasTeamOwner && (
      <Tooltip content="No team owner">
        <div css={styles.noTeamOwnerContainer}>
          <FontAwesomeIcon css={styles.warningIcon} icon={faTriangleExclamation} />
          <span css={styles.name}>{name}</span>
        </div>
      </Tooltip>
    )}
  </>
);

export default NameCell;
