import { useState } from 'react';
import { toast } from '~Common/components/Toasts';
import { IframelyErrorMessage, FrontendRankedQuestion } from '~Learning/const/interfaces';

interface AfterValidationCallbackParams {
  contentLink: string,
  introduction: string,
  contentTitle: string,
  categoryId: string,
  questions: FrontendRankedQuestion[],
  sourceTitle?: string,
}

interface UseCreateContentDetailsProps {
  initialQuestions?: FrontendRankedQuestion[],
  initialContentTitle?: string,
  initialContentLink?: string,
  initialTemplateCategoryId?: string,
  initialSourceTitle?: string,
  hasValidation?: boolean,
  afterSubmitValidationCallback: (params: AfterValidationCallbackParams) => void,
}

interface UseCreateContentDetailsReturn {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  questions: FrontendRankedQuestion[],
  setQuestions: (questions: FrontendRankedQuestion[]) => void,
  contentTitle: string,
  setContentTitle: (contentTitle: string) => void,
  contentLink: string,
  setContentLink: (contentLink: string) => void,
  contentLinkError: string,
  setContentLinkError: (contentLinkError: IframelyErrorMessage) => void,
  categoryId: string,
  setCategoryId: (categoryId: string) => void,
}

export const useCreateContentDetails = ({
  initialQuestions = [],
  initialContentTitle = '',
  initialContentLink = '',
  initialTemplateCategoryId = '',
  hasValidation = true,
  afterSubmitValidationCallback,
}: UseCreateContentDetailsProps): UseCreateContentDetailsReturn => {
  const [questions, setQuestions] = useState<FrontendRankedQuestion[]>(initialQuestions);
  const [contentTitle, setContentTitle] = useState(initialContentTitle);
  const [contentLink, setContentLink] = useState(initialContentLink);
  const [contentLinkError, setContentLinkError] = useState(IframelyErrorMessage.VALID);
  const [categoryId, setCategoryId] = useState(initialTemplateCategoryId);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (hasValidation) {
      if (questions.length === 0) {
        toast.error('Please add at least one question.');
        return;
      }

      if (contentLinkError !== IframelyErrorMessage.VALID) {
        toast.error(contentLinkError);
        return;
      }
    }

    // Get the data from the form
    const formData = new FormData(event.currentTarget);
    const contentLinkFromForm = formData.get('contentLink') as string;
    const introductionFromForm = formData.get('introduction') as string;
    const contentTitleFromForm = formData.get('contentTitle') as string;
    const categoryIdFromForm = formData.get('categoryId') as string;
    const sourceTitleFromForm = formData.get('sourceTitle') as string;
    afterSubmitValidationCallback({
      introduction: introductionFromForm,
      questions,
      contentTitle: contentTitleFromForm,
      contentLink: contentLinkFromForm,
      categoryId: categoryIdFromForm,
      sourceTitle: sourceTitleFromForm,
    });
  };

  return {
    onSubmit,
    questions,
    setQuestions,
    contentTitle,
    setContentTitle,
    contentLink,
    setContentLink,
    contentLinkError,
    setContentLinkError,
    categoryId,
    setCategoryId,
  };
};
