import {
  UseQueryOptions, UseQueryResult, keepPreviousData, useQuery,
} from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getHost, hosts } from '~Deprecated/services/config';
import { Goals } from '@leadr-hr/types';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';
import { goalKeys } from '../const/queryKeys';

type GetGoalsForCoachingReturn = Goals.Responses.GetGoalsForOneOnOneMeetingResponse['data'];

const getGoalsForCoaching = async ({
  meetingFactoryId,
  organizationId,
  queryParameters,
}: Goals.Requests.GetGoalsForOneOnOneMeetingRequest): Promise<HttpCallReturn<GetGoalsForCoachingReturn>> => {
  const queryParams = buildQueryString(queryParameters ?? {});

  const url = {
    host: getHost(hosts.goals, '3'),
    uri: `/organizations/${organizationId}/goals/coachings/${meetingFactoryId}${queryParams}`,
  };

  return getApi<GetGoalsForCoachingReturn>(url);
};

type UseGetGoalsForCoachingProps<T> = Omit<UseQueryOptions<HttpCallReturn<GetGoalsForCoachingReturn>, Error, T>, 'queryKey' | 'queryFn'> &
  Omit<Goals.Requests.GetGoalsForOneOnOneMeetingRequest, 'organizationId'>;

export const useGetGoalsForCoaching = <T = HttpCallReturn<GetGoalsForCoachingReturn>>({
  meetingFactoryId,
  queryParameters,
  ...options
}: UseGetGoalsForCoachingProps<T>): UseQueryResult<T, Error> => {
  const organizationId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: goalKeys.forCoaching(meetingFactoryId, queryParameters),
    queryFn: () => getGoalsForCoaching({ meetingFactoryId, organizationId, queryParameters }),
    enabled: !!meetingFactoryId,
    placeholderData: keepPreviousData,
    ...options,
  });
};
