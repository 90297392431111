import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { faPaperPlane, faPenToSquare, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { useEditUser } from '~Admin/hooks/usePeopleTableActions';
import { PERSON } from '~Common/const/proptypes';
import { ActionType } from '~Admin/const/defaults';
import DataGridTable from '~Common/components/Tables/DataGridTable';
import { dateRowComparator, dateRowPreciseFormatter } from '~Admin/functions';
import AdminActionMenu from './AdminActionMenu';
import { getFullName } from './ActiveUsers';

const ImportedUsersTable = ({
  allUsers,
  filteredUsers,
  pageSize,
  selectionModel,
  setSelectionModel,
  openConfirmationPopover,
  setActionType,
  deleteUser,
  setInviteUser,
  handleCellClickEvent,
  filterModel,
  setFilterModel,
}) => {
  const { editUser } = useEditUser();

  const menuItems = (param) => [[{
    text: `Edit ${param.name}`,
    icon: faPenToSquare,
    onClick: () => {
      const user = filteredUsers.find(({ orgUserId }) => orgUserId === param.orgUserId);
      editUser({ user, allUsers });
    },
    dataTestId: 'actionMenuItem-edit',
  },
  {
    text: `Invite ${param.name}`,
    icon: faPaperPlane,
    onClick: () => {
      const userData = filteredUsers.filter((user) => user.orgUserId === param.orgUserId);
      setActionType(ActionType.Invite);
      setInviteUser(userData[0].orgUserId);
    },
    dataTestId: 'actionMenuItem-invite',
  },
  {
    text: `Delete ${param.name}`,
    icon: faTrashCan,
    onClick: (e) => {
      const userData = filteredUsers.filter((user) => user.orgUserId === param.orgUserId);
      setActionType(ActionType.Delete);
      openConfirmationPopover(e);
      deleteUser([userData[0].orgUserId]);
    },
    dataTestId: 'actionMenuItem-delete',
  },
  ]];
  const tableColumnHeaders = [
    {
      field: 'firstName',
      headerName: 'First Name',
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      maxWidth: 750,
      flex: 1,
      valueGetter: getFullName,
      hide: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'managerEmail',
      headerName: 'Manager Email',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'invitedOn',
      headerName: 'Imported On',
      maxWidth: 175,
      flex: 1,
      valueFormatter: dateRowPreciseFormatter,
      sortComparator: dateRowComparator,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'right',
      headerAlign: 'right',
      sortable: false,
      maxWidth: 100,
      renderCell: (cellValues) => (
        <AdminActionMenu menuItems={menuItems({ orgUserId: cellValues.row.id, name: `${cellValues.row.firstName} ${cellValues.row.lastName}` })} />
      ),
      flex: 1,
    },
  ];
  const userRows = useMemo(() => filteredUsers.map((user) => ({
    id: user.orgUserId,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    managerEmail: user.managerEmail,
    invitedOn: user.invitedAtInMillis,
    actions: user.orgUserId,
  })), [filteredUsers]);

  return (
    <DataGridTable
      rows={userRows}
      columns={tableColumnHeaders}
      pageSize={pageSize}
      selectionModel={selectionModel}
      setSelectionModel={setSelectionModel}
      filterModel={filterModel}
      setFilterModel={setFilterModel}
      handleCellClickEvent={handleCellClickEvent}
    />
  );
};

ImportedUsersTable.propTypes = {
  filteredUsers: PropTypes.arrayOf(PERSON).isRequired,
  allUsers: PropTypes.arrayOf(PERSON).isRequired,
  pageSize: PropTypes.number.isRequired,
  setSelectionModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  selectionModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  openConfirmationPopover: PropTypes.func.isRequired,
  setActionType: PropTypes.func,
  deleteUser: PropTypes.func,
  setInviteUser: PropTypes.func,
  setFilterModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  filterModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  handleCellClickEvent: PropTypes.func.isRequired,
};

ImportedUsersTable.defaultProps = {
  setActionType: '',
  deleteUser: null,
  setInviteUser: null,
};

export default ImportedUsersTable;
