import {
  faBook,
  faBookUser,
  faBriefcase,
  faBuilding,
  faBullseyeArrow,
  faChartBar,
  faClipboardUser,
  faCog,
  faCommentsAlt,
  faFlag,
  faHandHoldingSeedling,
  faHouse,
  faIdBadge,
  faIdCard,
  faNetworkWired,
  faPartyHorn,
  faPoll,
  faSignOut,
  faSkull,
  faTasks,
  faUserFriends,
  faUserSecret,
  faMagnifyingGlass,
} from '@fortawesome/pro-light-svg-icons';
import { getUserId, removeStorageItem } from '~Common/utils/localStorage';

import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { navigateAction } from '~Deprecated/actions/navigate';
import { signout } from '~Deprecated/redux/actions/common';
import { editProfileTemplate } from '~People/components/Drawers/EditProfile';
import { testToolsDrawerTemplate } from '~Deprecated/ui/views/TestTools/Drawers/Flags';
import { skeletonLoadersDrawerTemplate } from '~Deprecated/ui/views/TestTools/Drawers/SkeletonLoaders';
import { insightsDevToolsTemplate } from '~Insights/drawers/DevTools';
import { logoutFromAuth0 } from '~Root/functions/logoutFromAuth0';

import {
  ACTION_ITEMS_BASE_ROUTE,
  COACHING_HOME,
  FEEDBACK_BASE_ROUTE,
  GOALS_BASE_ROUTE,
  HOME_PAGE_HOME,
  IMPERSONATE,
  INSIGHTS_BASE_ROUTE,
  LEARNING_LIST_RECEIVED,
  NEXUS_ORGANIZATIONS,
  PEOPLE,
  PERSONALITY_TYPES,
  RECOGNITION,
  REVIEWS,
  SELECT_ORGANIZATION,
  SETTINGS,
  SURVEY_ROUTE,
  DEVELOPMENT_PLAN,
} from '../../../common/const/routes';
import { NavigationItem } from './types';

export const baseNavigationItems: readonly NavigationItem[] = [
  {
    label: 'Home',
    href: HOME_PAGE_HOME,
    icon: faHouse,
    testId: 'homeNavButton',
  },
  {
    label: 'Meetings',
    href: COACHING_HOME,
    icon: faBriefcase,
    testId: 'meetingsNavButton',
  },
];

export const organizationNavigationItems: readonly NavigationItem[] = [
  {
    label: 'People',
    href: PEOPLE,
    icon: faUserFriends,
    testId: 'peopleNavButton',
  },
  {
    label: 'Recognition',
    href: RECOGNITION,
    icon: faPartyHorn,
    isVisible: ({ organization: { enableRecognition }, flags: { featureRecognition } }) => enableRecognition && !!featureRecognition,
    testId: 'recognitionNavButton',
  },
  {
    label: 'Insights',
    href: INSIGHTS_BASE_ROUTE,
    icon: faChartBar,
    isVisible: ({ canSeeInsights }) => canSeeInsights,
    testId: 'insightsNavButton',
  },
];

export const alignmentNavigationItems: readonly NavigationItem[] = [
  {
    label: 'Goals',
    href: GOALS_BASE_ROUTE,
    icon: faBullseyeArrow,
    isVisible: ({ organization }) => !!organization.enableGoals,
    testId: 'goalsNavButton',
  },
  {
    label: 'Action Items',
    href: ACTION_ITEMS_BASE_ROUTE,
    icon: faTasks,
    getNotificationTotal: ({ pendingActionItems }) => pendingActionItems,
    isVisible: ({ organization }) => !!organization.enableActionItems,
    testId: 'actionItemsNavButton',
  },
  {
    label: 'Surveys',
    href: SURVEY_ROUTE,
    icon: faPoll,
    isVisible: ({ organization: { enableInsights }, flags: { hideSurveysFromNavigation } }) => !!enableInsights && !hideSurveysFromNavigation,
    getNotificationTotal: ({ pendingSurveys }) => pendingSurveys,
    testId: 'surveysNavButton',
  },
];

export const growthNavigationItems: readonly NavigationItem[] = [
  {
    label: 'Development Plans',
    href: DEVELOPMENT_PLAN,
    icon: faHandHoldingSeedling,
    isVisible: ({ organization: { enablePdps }, flags: { pdpsPhaseOne } }) => !!pdpsPhaseOne && !!enablePdps,
    testId: 'personalDevelopmentPlansNavButton',
  },
  {
    label: 'Learning',
    href: LEARNING_LIST_RECEIVED,
    icon: faBook,
    getNotificationTotal: ({ pendingLearnings }) => pendingLearnings,
    isVisible: ({ organization }) => !!organization.enableLearning,
    testId: 'learningNavButton',
  },
  {
    label: 'Feedback',
    href: FEEDBACK_BASE_ROUTE,
    icon: faCommentsAlt,
    getNotificationTotal: ({ pendingFeedback }) => pendingFeedback,
    isVisible: ({ organization }) => !!organization.enableFeedback,
    testId: 'feedbackNavButton',
  },
  {
    label: 'Reviews',
    href: REVIEWS,
    icon: faClipboardUser,
    isVisible: ({ organization: { enableReviews } }) => !!enableReviews,
    getNotificationTotal: ({ pendingReviews }) => pendingReviews,
    testId: 'reviewsNavButton',
  },
];

export const nexusNavigationItems: readonly NavigationItem[] = [
  {
    label: 'Orgs',
    href: NEXUS_ORGANIZATIONS,
    icon: faNetworkWired,
    isVisible: ({ canSeeNexus }) => canSeeNexus,
    testId: 'orgsNavButton',
  },
  {
    label: 'Personality Types',
    href: PERSONALITY_TYPES,
    icon: faIdCard,
    isVisible: ({ canSeeNexus }) => canSeeNexus,
    testId: 'personalityTypesNavButton',
  },
  {
    label: 'Impersonate',
    href: IMPERSONATE,
    icon: faUserSecret,
    isVisible: ({ canImpersonate }) => canImpersonate,
    testId: 'impersonateNavButton',
  },
];

export const devToolNavigationItems: readonly NavigationItem[] = [
  {
    label: 'Flags',
    icon: faFlag,
    onClick: ({ dispatch }) => {
      dispatch(pushDrawerAction({
        drawer: {
          ...testToolsDrawerTemplate,
        },
      }));
    },
    testId: 'flagsNavButton',
  },
  {
    label: 'Skeleton Loaders',
    icon: faSkull,
    onClick: ({ dispatch }) => {
      dispatch(pushDrawerAction({
        drawer: {
          ...skeletonLoadersDrawerTemplate,
        },
      }));
    },
    testId: 'skeletonLoadersNavButton',
  },
  {
    label: 'Insights',
    icon: faChartBar,
    onClick: ({ dispatch }) => {
      dispatch(pushDrawerAction({
        drawer: {
          ...insightsDevToolsTemplate,
        },
      }));
    },
    testId: 'insightsNavButton',
  },
];

export const hrNavigationItem: NavigationItem = {
  label: 'HR',
  icon: faBookUser,
  isExternalLink: true,
  onClick: ({
    flags: { hrModuleLink },
    organization: { enableHRModule, easeUrl, enableISolvedModule },
  }) => hrModuleLink && handleHrModuleClick({ enableHRModule, enableISolvedModule, easeUrl }),
  isVisible: ({ flags, organization: { enableHRModule, enableISolvedModule } }) => (
    !!flags.hrModuleLink && (enableHRModule || enableISolvedModule)
  ),
  testId: 'hrNavButton',
};

export const searchNavigationItem: NavigationItem = {
  label: 'Search',
  icon: faMagnifyingGlass,
  onClick: ({
    setShowSearchModal,
  }) => setShowSearchModal(true),
  testId: 'searchNavButton',
};

interface HandleHrModuleClickProps {
  enableHRModule?: boolean,
  enableISolvedModule?: boolean,
  easeUrl?: string,
}

function handleHrModuleClick({
  enableHRModule,
  enableISolvedModule,
  easeUrl,
}: HandleHrModuleClickProps): void {
  if (enableISolvedModule) {
    window.open('https://connect.threadhcm.com/cloudservice/home', '_blank');
  } else if (enableHRModule && easeUrl) {
    window.open(easeUrl, '_blank');
  }
}

export const profileItems: readonly NavigationItem[] = [
  {
    label: 'Profile',
    icon: faIdBadge,
    onClick: ({ dispatch }) => {
      dispatch(pushDrawerAction({
        drawer: {
          ...editProfileTemplate,
          args: {
            userId: getUserId(),
          },
        },
      }));
    },
    testId: 'profileNavButton',
  },
  {
    label: 'Settings',
    icon: faCog,
    href: SETTINGS,
    testId: 'settingsNavButton',
  },
  {
    label: 'Switch Orgs',
    icon: faBuilding,
    isVisible: ({ hasMultipleOrganizations }) => hasMultipleOrganizations,
    onClick: ({ dispatch }) => {
      dispatch(signout({ fullSignout: false }));
      removeStorageItem('organizationId');
      removeStorageItem('orgUserId');

      dispatch(navigateAction({
        pathname: SELECT_ORGANIZATION,
      }));
    },
    testId: 'switchOrgsNavButton',
  },
  {
    label: 'Logout',
    icon: faSignOut,
    onClick: () => {
      logoutFromAuth0();
    },
    testId: 'logoutNavButton',
  },
];
