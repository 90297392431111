import { css, SerializedStyles } from '@emotion/react';
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import EmptyState from '~Home/components/common/EmptyState';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { goalsPreview } from '~Home/const/HomeEmptyState';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { GOALS_BASE_ROUTE } from '~Common/const/routes';
import { Goals } from '@leadr-hr/types';
import { useGetLeadrHomeGoals } from '~Home/hooks/goals/useGetLeadrHomeGoals';
import CascadingGoalsTable from '~Goals/components/Shared/Tables/CascadingGoalsTable';
import useGetLeadrHomeCascadingTableColumns from '~Home/hooks/goals/useGetLeadrHomeCascadingTableColumns';
import { getLeadrHomeCascadingTableRows, LeadrHomeCascadingGoalRow } from '~Home/hooks/goals/getLeadrHomeCascadingTableRows';
import CustomTreeCell from '~Goals/components/Tables/GoalsTable/CustomTreeCell';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { useLeadrHomeGoalsContextType, useSetLeadrHomeGoalsContextType } from '~Home/stores/useLeadrHomeGoalsContextType';
import { SelectChangeEvent } from '@mui/material';
import Filters from './Filters/Filters';

const styles = {
  header: css({
    marginBottom: '0.5rem',
  }),
  goalListItemWrapper: css({
    marginBottom: '0.5rem',
  }),
  goalListItem: css({
    marginBottom: '0.5rem',
  }),
  divider: css({
    marginBottom: '0.5rem',
  }),
  expandButton: css({
    marginLeft: '-0.5rem',
    padding: '0 0.5rem',
  }),
};

interface HomeGoalsCardViewProps extends Omit<LeadrCardProps, 'children'> {
  areGoalsLoading: boolean,
  columns: GridColDef<LeadrHomeCascadingGoalRow>[],
  rows: LeadrHomeCascadingGoalRow[],
  dragHandleProps?: DraggableProvidedDragHandleProps,
  selectedContextType: Goals.GoalContextType | string,
  onContextTypeChange: (event: SelectChangeEvent<Goals.GoalContextType | string>) => void,
  showCascadingButton: boolean,
}

const View = ({
  areGoalsLoading,
  dragHandleProps,
  columns,
  rows,
  selectedContextType,
  onContextTypeChange,
  showCascadingButton,
  ...props
}: HomeGoalsCardViewProps): JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <ModuleHeader
      css={styles.header}
      headerText="Goals"
      renderRightSection={() => (
        <Filters
          selectedContextType={selectedContextType}
          onContextTypeChange={onContextTypeChange}
          showCascadingButton={showCascadingButton}
        />
      )}
      {...dragHandleProps}
    />

    {!areGoalsLoading && rows.length === 0 && (
      <EmptyState
        image={goalsPreview}
        renderIcon={(iconStyles: SerializedStyles) => (
          <FontAwesomeIcon
            css={iconStyles}
            icon={faBullseyeArrow}
            size="5x"
          />
        )}
        renderAction={(actionStyles: SerializedStyles) => (
          <LeadrButton
            css={actionStyles}
            component={Link}
            to={GOALS_BASE_ROUTE}
            variant="text"
            data-test-id="postRecognition"
          >
            Create a goal
          </LeadrButton>
        )}
        text="to start tracking progress"
        altText="Create a goal to start tracking progress"
      />
    )}

    {areGoalsLoading && (
      <HomeSkeleton
        numberOfSkeletons={4}
        height={2.5}
      />
    )}

    {!areGoalsLoading && rows.length > 0 && (
      <CascadingGoalsTable
        columns={columns}
        rows={rows}
        getTreeDataPath={(row: LeadrHomeCascadingGoalRow) => row.path}
        slots={{
          columnHeaders: () => null,
        }}
        groupingColDef={{
          headerName: 'Name',
          flex: 3,
          minWidth: 175,
          renderCell: (params) => (
            <CustomTreeCell
              buttonStyles={styles.expandButton}
              showProgressBar={false}
              {...params}
            />
          ),
        }}
      />
    )}

    <ModuleFooter
      renderFooterButton={(params) => (
        <LeadrButton
          data-test-id="viewAllGoals"
          component={Link}
          to={GOALS_BASE_ROUTE}
          variant="text"
          size="small"
          {...params}
        >
          View All
        </LeadrButton>
      )}
    />
  </LeadrCard>
);

export interface HomeGoalsCardProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const numberOfGoalsToFetch = 5;

const HomeGoalsCard = ({
  dragHandleProps,
  ...props
}: HomeGoalsCardProps): JSX.Element => {
  const {
    userLevelEnableCascadingGoals,
    orgLevelEnableCascadingGoals,
  } = useEnableCascadingGoals();

  const selectedContextType = useLeadrHomeGoalsContextType();
  const setSelectedContextType = useSetLeadrHomeGoalsContextType();

  const onContextTypeChange = (event: SelectChangeEvent<Goals.GoalContextType | string>): void => {
    setSelectedContextType(event.target.value);
  };

  const queryParameters: Goals.Requests.GetLeadrHomeGoalsRequestQueryParameters = {
    take: numberOfGoalsToFetch,
    enableCascading: orgLevelEnableCascadingGoals && userLevelEnableCascadingGoals,
    ...(selectedContextType !== '' ? { contextType: selectedContextType as Goals.GoalContextType } : {}),
  };

  const { data, isLoading } = useGetLeadrHomeGoals({
    queryParameters,
  });

  const goals = data?.response ?? [];

  const tableColumns = useGetLeadrHomeCascadingTableColumns();
  const tableRows = getLeadrHomeCascadingTableRows(goals);

  const [areGoalsLoading] = useSkeletonLoaders(isLoading);

  const hookProps = {
    areGoalsLoading,
    dragHandleProps,
    columns: tableColumns,
    rows: tableRows,
    selectedContextType,
    onContextTypeChange,
    showCascadingButton: orgLevelEnableCascadingGoals,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default HomeGoalsCard;
