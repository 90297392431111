import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { queryClient } from '~Common/const/queryClient';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { MEETING_FACTORY_TYPES } from '~Meetings/const/meetingTypes';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { getMeetingById } from '~Deprecated/redux/actions/meetingActions';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { useManageParticipants } from './useManageParticipants';

const newEditMeeting = ({
  type,
  lastState,
  factoryId,
  id,
  ...meeting
}) => {
  const serverUrl = `/organizations/${getOrganizationId()}/huddles/seriesAndParticipants`;

  return patchApi(serverUrl, {
    huddleId: id,
    factoryId,
    ...meeting,
  });
};

const editMeeting = ({
  type,
  lastState,
  factoryId,
  id,
  attendeeIds,
  ...meeting
}) => {
  // Huddle endpoint...
  const serverUrl = `/organizations/${getOrganizationId()}/huddles/series`;

  return patchApi(serverUrl, {
    huddleId: id,
    factoryId,
    // New scheduler edit meeting API doesn't accept timezone currently. Potentially re-add if re-added to the new endpoint.
    // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...meeting,
  });
};

export const useEditMeeting = ({ template }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const { data: peopleData } = usePeopleQuery({});
  const doManageParticipants = useManageParticipants();
  const shouldUseNewEditMeetingApi = useFeatureFlag('webClientEnableConsolidatedPatchHuddle');
  const mutationFunction = shouldUseNewEditMeetingApi ? newEditMeeting : editMeeting;

  const mutation = useMutation({
    mutationFn: mutationFunction,
    onMutate: (variables) => {
      if (variables.frequency === 'ONE_TIME') {
        toastId.current = toast.info('Editing this meeting... Hope you are having a good day.', { autoClose: false });
      } else {
        toastId.current = toast.info('Editing this and future meetings... Hope you are having a good day.', { autoClose: false });
      }
    },
    onError: () => {
      toast.update(toastId.current, {
        render: <MeetingsErrorToast />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables) => {
      toast.update(toastId.current, {
        render: 'Your meeting has been updated. Have a great day.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const {
        type, lastState, attendeeIds,
      } = variables;

      const {
        factoryType, id, organizer, attendees, factoryId,
      } = lastState;

      const currentUser = getOrganizationUserId();

      dispatch(
        popDrawerAction({ drawerName: template.name }),
      );

      queryClient.invalidateQueries({ queryKey: meetingKeys.all });
      queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId()) });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });

      if (factoryType === MEETING_FACTORY_TYPES.COACHING) {
        queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });

        dispatch({
          type: 'GET_FULL_TIMELINE',
          payload: {
            page: 1,
            count: 100,
            filters: 'COACHING,FEEDBACK,CONTENT',
            relatedType: 'USER',
            relatedId: currentUser === organizer ? peopleData[attendees[0]]?.userId : peopleData[organizer]?.userId,
            coachingId: id,
            pageType: MEETING_FACTORY_TYPES.COACHING,
          },
        });

        dispatch(getMeetingById({ contextType: 'COACHING', data: id }));
      } else {
        dispatch({
          type: 'GET_FULL_TIMELINE',
          payload: {
            page: 1,
            count: 100,
            filters: MEETING_FACTORY_TYPES.TEAM_MEETING,
            relatedType: MEETING_FACTORY_TYPES.TEAM_MEETING,
            relatedId: id,
            meetingId: id,
            pageType: MEETING_FACTORY_TYPES.TEAM_MEETING,
          },
        });

        if (!shouldUseNewEditMeetingApi) {
          doManageParticipants({
            type,
            factoryId,
            id,
            attendeeIds,
            lastState,
          });
        }
      }
    },
  });

  return {
    editMeetingMutation: mutation.mutate,
    isLoading: mutation.isPending,
  };
};
