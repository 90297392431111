import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSearchBox, UseSearchBoxProps, useInstantSearch } from 'react-instantsearch-hooks-web';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { GLOBAL_SEARCH } from '~Common/const/routes';
import { useHistory } from 'react-router-dom';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useGlobalSearch } from '~GlobalSearch/stores/useGlobalSearch';
import { MIN_CHARACTERS_TO_SEARCH } from '~GlobalSearch/const/GlobalSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';

const styles = {
  algoliaCustomSearchField: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    width: '100%',
  }),
  leadrSearchField: css({
    flex: 1,
  }),
};

interface ViewProps {
  className?: string,
  searchText: string,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void,
  searchDebounceInMs: number,
  handleAlgoliaSearch: () => void,
  showLoadingState: boolean,
  dataTestId: string,
}

const View = ({
  className,
  searchText,
  handleSearchTextChange,
  handleKeyDown,
  searchDebounceInMs,
  handleAlgoliaSearch,
  showLoadingState,
  dataTestId,
}: ViewProps): JSX.Element => (
  <div css={styles.algoliaCustomSearchField}>
    <LeadrSearchField
      css={styles.leadrSearchField}
      data-test-id={dataTestId}
      className={className}
      value={searchText}
      placeholder="Search Meetings..."
      onChange={handleSearchTextChange}
      renderLeftIcon={showLoadingState ? (iconStyles) => <FontAwesomeIcon css={iconStyles} icon={faCircleNotch} spin /> : undefined}
      inputProps={{
        autoFocus: true,
        onKeyDown: handleKeyDown,
      }}
    />
    {searchDebounceInMs === 0 && (
      <LeadrButton
        data-test-id="submitAlgoliaSearch"
        onClick={handleAlgoliaSearch}
      >
        Search
      </LeadrButton>
    )}
  </div>
);

interface AlgoliaCustomSearchFieldProps extends UseSearchBoxProps {
  className?: string,
  initialSearchText?: string,
  ['data-test-id']: string,
}

const AlgoliaCustomSearchField = ({
  className,
  initialSearchText,
  'data-test-id': dataTestId,
  ...props
}: AlgoliaCustomSearchFieldProps): JSX.Element => {
  const searchDebounceInMs = useFeatureFlag<number>('searchDebounceInMs');
  const searchStore = useGlobalSearch();
  const { status } = useInstantSearch();
  const history = useHistory();

  const {
    query,
    refine,
  } = useSearchBox(props);

  const {
    searchText,
    setSearchText,
    debouncedSearchText,
  } = searchStore((state) => ({
    searchText: state.searchText,
    setSearchText: state.setSearchText,
    debouncedSearchText: state.debouncedSearchText,
  }));

  useEffect(() => {
    // We want to do an initial search if the user navigated here with text in the modal
    if (initialSearchText) {
      setSearchText(initialSearchText);
    }
  }, [initialSearchText, refine, setSearchText]);

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    // Only navigate to the page on enter if we're not already on the page
    if (event.key === 'Enter' && !history.location.pathname.includes(GLOBAL_SEARCH)) {
      history.push({
        pathname: GLOBAL_SEARCH,
        state: { search: searchText },
      });
    }
  };

  const handleAlgoliaSearch = useCallback((): void => {
    // Don't search if the text is the same as the last query
    if (debouncedSearchText !== query && debouncedSearchText.length >= MIN_CHARACTERS_TO_SEARCH) {
      refine(debouncedSearchText);
    }
  }, [debouncedSearchText, query, refine]);

  useEffect(() => {
    // If searchDebounceInMs is 0, don't run this, we have a button to submit the search
    if (searchDebounceInMs > 0) {
      handleAlgoliaSearch();
    }
  }, [handleAlgoliaSearch, searchDebounceInMs]);

  const showLoadingState = useMemo(
    () => searchText.length > MIN_CHARACTERS_TO_SEARCH && (searchText !== debouncedSearchText || status === 'loading' || status === 'stalled'),
    [searchText, debouncedSearchText, status],
  );

  const hookProps = {
    searchText,
    handleSearchTextChange,
    className,
    handleKeyDown,
    searchDebounceInMs,
    handleAlgoliaSearch,
    showLoadingState,
    dataTestId,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default AlgoliaCustomSearchField;
