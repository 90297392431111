import { createAction } from '@reduxjs/toolkit';

import { registerAction } from '~Deprecated/reducers/personalityDetalis';
import { mutateState } from '~Deprecated/utils/reduxUtils';

const clearPersonalityDetails = createAction('LEADR.CLEAR_PERSONALITY_DETAILS_BY_ID');

const onClearPersonalityDetails = (state) => mutateState(state, (draftState) => {
  draftState.hasUpdateCallPending = null;
  return draftState;
});

registerAction(clearPersonalityDetails, onClearPersonalityDetails);

export {
  clearPersonalityDetails,
};
