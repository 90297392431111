import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import GridCard from '~Common/components/Cards/GridCard';
import { PersonDisplayInformation } from '~Common/const/interfaces';

interface GridFeedbackCardProps {
  title: string,
  description: string,
  dueDate: string | undefined,
  assignedUsersInfo: PersonDisplayInformation[],
  numberOfUsersAssigned: number,
  renderCompletedSection?: () => JSX.Element,
  onCardClick: () => void,
  isComplete: boolean,
}

const GridFeedbackCard = ({
  title,
  description,
  dueDate,
  assignedUsersInfo,
  numberOfUsersAssigned,
  renderCompletedSection,
  onCardClick,
  isComplete,
  ...props
}: GridFeedbackCardProps): JSX.Element => (
  <GridCard
    onClick={onCardClick}
    title={title}
    feature="feedback"
    description={description}
    assignedUsersInfo={assignedUsersInfo}
    numberOfUsersAssigned={numberOfUsersAssigned}
    renderFooter={() => (
      <>
        <DueDateFromNow
          dueDate={dueDate}
          isComplete={isComplete}
        />
        {renderCompletedSection?.()}
      </>
    )}
    {...props}
  />
);

export default GridFeedbackCard;
