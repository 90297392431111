import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Banner, { BannerProps } from '~Common/V3/components/Banner';
import IconContents from '~Common/V3/components/Banner/IconContents';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { hexToRGBA, palette } from '~Common/styles/colors';

const styles = {
  banner: css({
    backgroundColor: hexToRGBA(palette.brand.blue, 0.10),
    margin: '.625rem 0',
  }),
  icon: css({
    color: palette.brand.indigo,
  }),
  innerNoQuestionsText: css({
    color: palette.neutrals.gray800,
  }),
};

interface PlaylistContentBanner extends Omit<BannerProps, 'renderContents'> {
  contentListLength: number,
}

const PlaylistContentBanner = ({
  contentListLength,
  ...props
}: PlaylistContentBanner): JSX.Element => (
  <Banner
    css={styles.banner}
    renderContents={() => (
      <IconContents
        renderIcon={() => (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="3x"
            css={styles.icon}
          />
        )}
        renderText={() => (
          <div css={styles.innerNoQuestionsText}>
            {contentListLength === 0 && 'At least two pieces of content are needed. Click below to add new content.'}
            {contentListLength === 1 && 'At least two pieces of content are needed. Click below to add new content.'}
          </div>
        )}
      />
    )}
    {...props}
  />
);

export default PlaylistContentBanner;
