import { useMutation } from '@tanstack/react-query';
import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';

const getFilteredPeopleMutation = (filters) => {
  const URL = `/organizations/${getOrganizationId()}/filterPeople`;

  return postApi(URL, filters, {});
};

export const useGetFilteredPeople = (onSuccessCallback) => {
  const mutation = useMutation({
    mutationFn: getFilteredPeopleMutation,
    onSuccess: (data) => {
      onSuccessCallback(data.response);
    },
  });

  return {
    getFilteredPeopleMutation: mutation.mutate,
    isLoading: mutation.isPending,
  };
};
