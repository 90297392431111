import { css, SerializedStyles } from '@emotion/react';
import { hexToRGBA, palette } from '~Common/styles/colors';

const alertColorArray = ['primary', 'warning', 'danger'] as const;
export type alertColor = typeof alertColorArray[number];

const primaryButtonColors = css({
  background: hexToRGBA(palette.brand.red, 0.3),
  color: palette.neutrals.white,
});
const warningButtonColors = css({
  background: hexToRGBA(palette.brand.orange, 0.3),
  color: palette.neutrals.gray800,
});
const dangerButtonColors = css({
  background: hexToRGBA(palette.brand.red, 0.3),
  color: palette.brand.red,
});

function isOfTypeAlertColor(color: alertColor): boolean {
  return alertColorArray.includes(color);
}

const defaultColorMap = {
  primary: primaryButtonColors,
  warning: warningButtonColors,
  danger: dangerButtonColors,
};
interface AlertStylesProps {
  color?: alertColor,
}

export const buildAlertStyles = (
  { color = 'primary' }: AlertStylesProps = {},
): SerializedStyles => {
  const colorMap = defaultColorMap;

  let alertColors = colorMap.primary;

  if (isOfTypeAlertColor(color)) {
    alertColors = colorMap[color];
  }

  const alertStyles = css(alertColors);

  return css(
    alertStyles,
  );
};
