import { css } from '@emotion/react';

import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { followCursor } from 'tippy.js';

const styles = {
  tippy: css({
    '.tippy-content': {
      maxHeight: '12rem',
      overflowY: 'auto',
      overflowWrap: 'break-word',
    },
  }),
};

interface TooltipProps extends TippyProps {
  children: JSX.Element,
  interactive?: boolean,
  content: React.ReactNode,
}

const Tooltip = ({
  children,
  interactive = true,
  content,
  ...props
}: TooltipProps): JSX.Element => (
  <>
    {content ? (
      <Tippy
        content={content}
        plugins={[followCursor]}
        css={styles.tippy}
        interactive={interactive}
        appendTo={() => document.body}
        {...props}
      >
        {children}
      </Tippy>
    ) : (
      <>
        {children}
      </>
    )}
  </>
);

export default Tooltip;
