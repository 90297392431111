import { MeetingInstance } from '~Meetings/const/meetingsSearchInterfaces';
import { faArrowsRepeat, faBriefcase } from '@fortawesome/pro-light-svg-icons';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import { css } from '@emotion/react';
import { Divider } from '@mui/material';
import { palette } from '~Common/styles/colors';
import { MeetingFactoryType } from '~Meetings/const/meetingsInterfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTruncate } from '~Common/styles/mixins';
import { HTMLString } from '~Common/types';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import SectionItem from '../SectionItem';

const styles = {
  meetingType: css(withTruncate()),
  meetingStartTime: css({
    whiteSpace: 'nowrap',
  }),
  avatarSection: css({
    display: 'flex',
    alignItems: 'center',
  }),
  divider: css({
    backgroundColor: palette.neutrals.gray400,
    margin: '.3125rem .25rem',
  }),
};

interface ViewProps {
  meetingOrganizer: PersonDisplayInformation,
  usersInfo: PersonDisplayInformation[],
  highlightedMeetingTitleHTML: HTMLString,
  meetingStartTime: string,
  meetingType: string,
  link: string,
  fullMeetingTitle: string,
}

const View = ({
  meetingOrganizer,
  usersInfo,
  highlightedMeetingTitleHTML,
  meetingType,
  meetingStartTime,
  ...props
}: ViewProps): JSX.Element => (
  <SectionItem
    data-test-id="globalSearchMeetingTitleItem"
    renderLeftItem={() => (
      <SectionItem.Icon icon={faBriefcase} />
    )}
    renderMainText={(mainTextStyles) => (
      <HTMLRenderer css={mainTextStyles} htmlText={highlightedMeetingTitleHTML} />
    )}
    renderSecondaryText={() => (
      <SectionItem.SecondarySection
        renderFirst={meetingType ? () => (
          <>
            <FontAwesomeIcon icon={faArrowsRepeat} />
            <span css={styles.meetingType}>{meetingType}</span>
          </>
        ) : undefined}
        renderSecond={() => (
          <span css={styles.meetingStartTime}>{meetingStartTime}</span>
        )}
      />
    )}
    renderAvatarSection={() => (
      <div css={styles.avatarSection}>
        <AvatarWithTooltip
          height={22}
          width={22}
          firstName={meetingOrganizer?.firstName}
          lastName={meetingOrganizer?.lastName}
          profileImageUrl={meetingOrganizer?.profileImageUrl}
        />
        <Divider css={styles.divider} orientation="vertical" flexItem />
        <SectionItem.ParticipantAvatars usersInfo={usersInfo} />
      </div>
    )}
    {...props}
  />
);

interface MeetingTitleItemProps extends Omit<ViewProps, 'meetingId' | 'meetingType' | 'highlightedMeetingTitleHTML' | 'fullMeetingTitle'> {
  hit: MeetingInstance,
}

const MeetingTitleItem = ({
  hit,
  ...props
}: MeetingTitleItemProps): JSX.Element => {
  const fullMeetingTitle = hit.meeting.title;
  let meetingType = '';
  if (hit.meeting.isRecurring) {
    if (hit.meeting.type === MeetingFactoryType.COACHING) {
      meetingType = '1:1';
    } else if (hit.meeting.type === MeetingFactoryType.TEAM_MEETING) {
      meetingType = 'Team Meeting';
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  const highlightedMeetingTitleHTML = hit._highlightResult.meeting.title.value;

  const hookProps = {
    highlightedMeetingTitleHTML,
    meetingType,
    fullMeetingTitle,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingTitleItem;
