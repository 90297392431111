/* eslint-disable react/button-has-type */

import { faBriefcase } from '@fortawesome/pro-duotone-svg-icons';

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */

// TODO: Delete this after feedback UI rewrite
const AddToMeetingButton = (props) => (
  <Button
    variant={BUTTON_VARIANT.ICON}
    icon={faBriefcase}
    buttonColor={BUTTON_COLOR.ADDITIVE}
    {...props}
  />
);

AddToMeetingButton.propTypes = {};

AddToMeetingButton.defaultProps = {};

export default AddToMeetingButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
