import {
  DataGridProProps,
  GridSortModel,
  GridRowParams,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { CascadingGoalRow, GoalRow } from '~Goals/const/types';
import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import useGetTableColumns from '~Goals/hooks/utils/useGetTableColumns';
import { Goals } from '@leadr-hr/types';
import useGetTableRows from '~Goals/hooks/utils/useGetTableRows';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import StyledGoalsTable from '~Goals/components/Shared/Tables/StyledGoalsTable';
import CustomTreeCell from './CustomTreeCell';

export const goalSortColumnField: Record<string, Goals.GetGoalsSortBy> = {
  title: Goals.GetGoalsSortBy.Title,
  priority: Goals.GetGoalsSortBy.Priority,
  dueDate: Goals.GetGoalsSortBy.DueDate,
  createdDate: Goals.GetGoalsSortBy.CreatedDate,
};

interface ViewProps extends DataGridProProps<GoalRow | CascadingGoalRow> {
  sortByField: Goals.GetGoalsSortBy,
  sortByOrder: Goals.GetGoalsSortOrder,
  isFetching: boolean,
  columnVisibilityModel: GridColumnVisibilityModel,
  enableCascadingGoals: boolean,
}

const View = ({
  rows,
  columns,
  onSortModelChange,
  onRowClick,
  sortByField,
  sortByOrder,
  isFetching,
  columnVisibilityModel,
  enableCascadingGoals,
}: ViewProps): JSX.Element => (
  <>
    { /* TODO: Doesn't need to be 2 tables for this, probably??? */ }
    {enableCascadingGoals && (
      <StyledGoalsTable
        columnVisibilityModel={columnVisibilityModel}
        columns={columns}
        rows={rows}
        getTreeDataPath={(row: CascadingGoalRow) => row.path}
        onSortModelChange={onSortModelChange}
        loading={isFetching}
        sortingOrder={['asc', 'desc']}
        treeData
        groupingColDef={{
          headerName: 'Name',
          flex: 3,
          minWidth: 200,
          renderCell: CustomTreeCell,
          cellClassName: 'leadrGridTitleCell',
        }}
        disableColumnFilter
        disableColumnMenu
        sortingMode="server"
        onRowClick={onRowClick}
        sortModel={[
          {
            field: goalSortColumnField[sortByField],
            sort: sortByOrder,
          },
        ]}
      />
    )}
    {!enableCascadingGoals && (
      <StyledGoalsTable
        columnVisibilityModel={columnVisibilityModel}
        columns={columns}
        rows={rows}
        disableColumnFilter
        disableColumnMenu
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
        onSortModelChange={onSortModelChange}
        onRowClick={onRowClick}
        loading={isFetching}
        sortModel={[
          {
            field: goalSortColumnField[sortByField],
            sort: sortByOrder,
          },
        ]}
      />
    )}
  </>
);

interface GoalsTableProps {
  data: Goals.Responses.GetGoalsResponse['data'],
  canViewTableGroup: boolean,
  onSortModelChange: (sortModel: GridSortModel) => void,
  sortByField: Goals.GetGoalsSortBy,
  sortByOrder: Goals.GetGoalsSortOrder,
  isFetching: boolean,
}

const GoalsTable = ({
  data,
  canViewTableGroup,
  onSortModelChange,
  sortByField,
  sortByOrder,
  isFetching,
  ...props
}: GoalsTableProps): JSX.Element => {
  const { userLevelEnableCascadingGoals, orgLevelEnableCascadingGoals } = useEnableCascadingGoals();
  const enableCascadingGoals = orgLevelEnableCascadingGoals && userLevelEnableCascadingGoals;
  const isMobile = useIsMobileQuery();

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(() => ({
    progress: !isMobile,
    dueDate: true,
    priority: !isMobile && !canViewTableGroup,
    role: !isMobile,
    objectiveType: !isMobile && canViewTableGroup,
    owner: !isMobile,
  }), [canViewTableGroup, isMobile]);

  const tableColumns = useGetTableColumns(enableCascadingGoals);
  const tableRows = useGetTableRows({ enableCascadingGoals, data });

  const history = useHistory();

  const onRowClick = useCallback((params: GridRowParams<GoalRow>) => {
    history.push(GoalsRoutes.ViewById.replace(':goalId', params.id as string));
  }, [history]);

  const hookProps = {
    rows: tableRows,
    columns: tableColumns,
    onRowClick,
    onSortModelChange,
    sortByField,
    sortByOrder,
    isFetching,
    columnVisibilityModel,
    enableCascadingGoals,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default GoalsTable;
