import { SyntheticEvent } from 'react';
import DeleteConfirmationPopover, {
  RenderConfirmationButtonsParams,
  useDeleteConfirmationPopover,
  UseDeleteConfirmationPopoverHookReturn,
} from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';

interface RenderDeleteButtonsParams {
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void,
}

interface ViewProps extends UseDeleteConfirmationPopoverHookReturn {
  renderDeleteButton: (params: RenderDeleteButtonsParams) => JSX.Element,
  renderConfirmationButtons: (params: RenderConfirmationButtonsParams) => JSX.Element,
}

const View = ({
  openConfirmationPopover,
  popoverId,
  isOpen,
  anchorEl,
  closeConfirmationPopover,
  renderDeleteButton,
  renderConfirmationButtons,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    { renderDeleteButton({ onClick: openConfirmationPopover })}
    <DeleteConfirmationPopover
      closeConfirmationPopover={closeConfirmationPopover}
      open={isOpen}
      popoverId={popoverId}
      anchorEl={anchorEl}
      renderConfirmationButtons={renderConfirmationButtons}
    />
  </div>
);

type DeleteButtonWithConfirmationProps = Omit<ViewProps, 'isOpen' | 'anchorEl' | 'popoverId' | 'openConfirmationPopover' | 'closeConfirmationPopover'>;

/**
 * @deprecated Use new generic ButtonWithConfirmation component
 */
const DeleteButtonWithConfirmation = ({ ...props }: DeleteButtonWithConfirmationProps): JSX.Element => {
  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('deleteButtonWithConfirmation');

  const hookProps = {
    isOpen,
    anchorEl,
    popoverId,
    openConfirmationPopover,
    closeConfirmationPopover,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default DeleteButtonWithConfirmation;
