import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useIsDesktopQuery } from '~Common/hooks/useMediaListener';
import { OldNavigationSidebar } from '~Root/components/OldNavigationSidebar';
import { NavigationSidebar } from '~Root/components/NavigationSidebar';
import { MobileNavigation } from '~Root/components/NavigationSidebar/MobileNavigation';
import { MobileNavigation as OldMobileNavigation } from '~Root/components/OldNavigationSidebar/MobileNavigation';
import { palette } from '~Common/styles/colors';
import { forDesktopObject } from '~Common/styles/mixins';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

const layoutWrapper = css({
  background: palette.neutrals.gray50,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100vw',
}, forDesktopObject({
  // The old navigation breaks at desktop breakpoints, so we address THAT for the layout change.
  flexDirection: 'row',
}));

/*
  This needs to eventually live at the top of Routes so it isn't being constantly re-mounted.
*/
function BaseLayout({ children }) {
  const isDesktop = useIsDesktopQuery();
  const useSimplifiedNav = useFeatureFlag('webAppSimplifiedNavigation');

  return (
    <div
      css={layoutWrapper}
    >
      {!isDesktop && (
        <>
          {useSimplifiedNav && (
            <MobileNavigation />
          )}

          {!useSimplifiedNav && (
            <OldMobileNavigation />
          )}
        </>
      )}

      {isDesktop && (
        <>
          {useSimplifiedNav && (
            <NavigationSidebar />
          )}

          {!useSimplifiedNav && (
            <OldNavigationSidebar />
          )}
        </>
      )}
      {children}
    </div>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

BaseLayout.defaultProps = {};

export default BaseLayout;
