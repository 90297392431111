import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import moment from 'moment-timezone';

import { useTime } from '~Deprecated/hooks/useTime';
import DatePicker from '~Deprecated/ui/components/Pickers/DatePicker';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { pallette } from '~Deprecated/ui/styles/colors';
import DrawerDropdown from '~Deprecated/ui/components/Inputs/DrawerDropdown';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { palette } from '~Common/styles/colors';
import TimePicker from '../TimePicker';

const styles = {
  container: css`
    background: rgba(245, 245, 247, 0.6);
    padding: 7px 16px;
    border-radius: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    align-items: center;
    letter-spacing: 0.5px;
    width: 100%;

    label {
      color: ${pallette.gray7};
      margin-bottom: 0;
    }
  `,
  datetimeContainer: css`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 450px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  `,
  datePicker: css`
    border: unset !important;
    background: white;
    padding: 7px 8px;
    width: 35%;

    input {
      color: ${palette.neutrals.gray800} !important;
    }

    &:hover {
      background-color: #00000014;
    }

    @media only screen and (max-width: 450px) {
      width: 100%;
    }
  `,
  timePicker: css`
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: unset !important;
  `,
  atAt: css`
    padding: 0 0 0 10px;

    @media only screen and (max-width: 450px) {
      display: none;
    }
  `,
  timeContainer: css`
    width: 66%;

    label {
      color: ${pallette.gray7};
      margin-bottom: 0;
    }

    @media only screen and (max-width: 450px) {
      width: 100%;
      margin-block-start: 0.5rem;
    }
  `,
  selectStyle: css`
    border: unset !important;
    background: white;
    padding: 7px 7px !important;

    .MuiSelect-icon {
      display: none;
    }

    .MuiSelect-select.MuiSelect-select {
      width: 90px;
      display: flex;
      justify-content: center;
      padding: 5px 0 !important;
    }

    &:hover {
      background-color: #00000014;
    }
  `,
};

// eslint-disable-next-line no-empty-pattern
const View = ({
  currentDate,
  doDateChange,
  defaultDuration,
  startTimeName,
  endTimeName,
  nextMeeting,
  label,
  format,
  className,
}) => (
  <div
    css={styles.container}
    className={className}
  >
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
    <label>{ label }</label>
    <div
      css={styles.datetimeContainer}
    >
      <DatePicker
        label=""
        required
        disablePast
        selectedDate={currentDate}
        onDateSelected={doDateChange}
        shouldDisableDate={nextMeeting}
        format={format}
        css={styles.datePicker}
      />
      <h4
        css={styles.atAt}
      >
        @
      </h4>
      <div css={styles.timeContainer}>
        <TimePicker
          initialDate={currentDate}
          defaultDuration={defaultDuration}
          css={styles.timePicker}
          renderStartTime={(props) => (
            <DrawerDropdown
              {...props}
              css={styles.selectStyle}
              name={startTimeName}
            />
          )}
          renderEndTime={(props) => (
            <DrawerDropdown
              {...props}
              css={styles.selectStyle}
              name={endTimeName}
            />
          )}
        />
      </div>
    </div>
  </div>
);
View.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  doDateChange: PropTypes.func.isRequired,
  defaultDuration: PropTypes.number.isRequired,
  startTimeName: PropTypes.string.isRequired,
  endTimeName: PropTypes.string.isRequired,
  nextMeeting: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  className: PropTypes.string,
};

View.defaultProps = {
  className: '',
};

const DateTimePicker = ({
  initialDate, onDateChange, startTimeName, endTimeName, nextMeeting, ...props
}) => {
  const { timezone } = useTimezone();
  const { date: currentDate, setDate } = useTime(moment.tz(initialDate, timezone), 15);
  const previousDate = usePrevious(initialDate);

  useEffect(() => {
    if (initialDate && previousDate && initialDate !== previousDate) {
      setDate(moment.tz(initialDate, timezone));
    }
  }, [setDate, initialDate, previousDate, timezone]);

  const doDateChange = ({ date }) => {
    setDate(moment.tz(date, timezone));
    onDateChange?.(date);
  };

  const hookProps = {
    currentDate,
    doDateChange,
    startTimeName,
    endTimeName,
    nextMeeting,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

DateTimePicker.propTypes = {
  initialDate: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  nextMeeting: PropTypes.func.isRequired,
  startTimeName: PropTypes.string,
  endTimeName: PropTypes.string,
  defaultDuration: PropTypes.number,
  label: PropTypes.string,
  format: PropTypes.string,

};

DateTimePicker.defaultProps = {
  // default props only on app initilization, undefined to force component to re-render on mount
  initialDate: undefined,
  onDateChange: null,
  startTimeName: 'startTimeInMillis',
  endTimeName: 'endTimeInMillis',
  defaultDuration: 1000 * 60 * 30,
  label: 'Date',
  format: 'ddd, MMM D',
};

export { View };
export default DateTimePicker;
