import PropTypes from 'prop-types';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import FormDrawer from '~Common/components/Drawers/Forms/Form';
import { getOrganizationName } from '~Common/utils/localStorage';
import { useTabs } from '~Common/hooks/useTabs';
import AddButton, { BUTTON_VARIANT } from '~Common/components/Buttons/AddButton';
import { DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import { createTemplateDrawerTemplate } from './Create';
/* eslint-disable import/no-cycle */
import TemplatesByOrg from '../Tabs/TemplatesByOrg';
import TemplatesByLeadr from '../Tabs/TemplatesByLeadr';
/* eslint-enable import/no-cycle */

export const templateListTemplate = {
  name: 'FEEDBACK_TEMPLATE_LIST',
  width: DRAWER_WIDTHS.PRIMARY,
};

const TemplateList = ({
  onlyQuestions, pushDrawer, drawerState, ...props
}) => {
  const tabQueryConfig = {
    module: 'feedback',
    workflow: 'templates_list',
    defaultValue: 0,
  };

  const { activeTab } = useTabs(tabQueryConfig);

  const onSubmit = () => {};
  const onCreate = () => {
    pushDrawer({
      drawer: {
        ...createTemplateDrawerTemplate,
      },
    });
  };
  const hookProps = {
    drawerName: templateListTemplate.name,
    isReadOnly: true,
    onSubmit,
    drawerTitle: 'Feedback Templates',
    tabs: [
      [
        getOrganizationName(),
        <TemplatesByOrg
          onlyQuestions={onlyQuestions}
          drawerName={templateListTemplate.name}
          existingQuestions={drawerState?.questions ?? []}
        />,
      ],
      [
        'Curated by Leadr',
        <TemplatesByLeadr
          onlyQuestions={onlyQuestions}
          drawerName={templateListTemplate.name}
          existingQuestions={drawerState?.questions ?? []}
        />,
      ],
    ],
    tabQueryConfig,
    renderBottomButtons: () => (
      <>
        {activeTab === 0 && (
          <AddButton
            variant={BUTTON_VARIANT.STACKED}
            text="Create Template"
            onClick={onCreate}
          />
        )}
      </>
    ),
  };

  return (
    <FormDrawer
      {...hookProps}
      {...props}
    />
  );
};

TemplateList.propTypes = {
  onlyQuestions: PropTypes.bool,
  pushDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
};

TemplateList.defaultProps = {
  onlyQuestions: false,
};

registerDrawer({ templateName: templateListTemplate.name, component: TemplateList });

export default TemplateList;
