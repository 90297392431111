import { useMemo } from 'react';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useGetTeamDetails } from '../useGetTeamDetails';
import { TeamClarityQuestion } from '../../const/types';

interface UseGetTeamClarityQuestionsParams {
  teamId: string,
  huddleId?: string,
}

interface UseGetTeamClarityQuestionsReturn {
  teamClarityQuestionList: TeamClarityQuestion[],
  teamClarityQuestionListEdit: TeamClarityQuestion[],
  isLoading: boolean,
}

export const useGetTeamClarityQuestions = ({ teamId, huddleId }: UseGetTeamClarityQuestionsParams): UseGetTeamClarityQuestionsReturn => {
  const { data: teamDetails, isLoading: areTeamDetailsLoading } = useGetTeamDetails({ teamId, huddleId });
  const { orgSettings } = useOrgDetailsContext();

  const teamClarityQuestionListDisplay = useMemo(() => {
    const questionList = orgSettings.teamClarityQuestionList || [];
    const answerList = teamDetails?.response.teamClarityAnswerList || [];
    const canEdit = teamDetails?.response.canEdit ?? false;

    const tempClarityQuestionList = questionList.map((question) => {
      const answer = answerList.find((tempAnswer) => tempAnswer.rank === question.rank);
      return {
        ...question,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...answer!,
      };
    });

    if (canEdit) {
      return tempClarityQuestionList.map((question) => ({
        ...question,
        // TODO: This is probably bad practice and we should update it
        answer: question.answer || 'Please provide an answer to this clarity question.',
      }));
    }

    return tempClarityQuestionList.filter((question) => question.answer);
  }, [orgSettings.teamClarityQuestionList, teamDetails?.response.canEdit, teamDetails?.response.teamClarityAnswerList]);

  const teamClarityQuestionListEdit = useMemo(() => {
    const questionList = orgSettings.teamClarityQuestionList || [];
    const answerList = teamDetails?.response.teamClarityAnswerList || [];

    return questionList.map((question) => {
      const answer = answerList.find((tempAnswer) => tempAnswer.rank === question.rank);
      return {
        ...question,
        answer: answer?.answer || '',
      };
    });
  }, [orgSettings.teamClarityQuestionList, teamDetails?.response.teamClarityAnswerList]);

  return {
    teamClarityQuestionList: teamClarityQuestionListDisplay,
    teamClarityQuestionListEdit,
    isLoading: areTeamDetailsLoading,
  };
};
