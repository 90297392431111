import { css } from '@emotion/react';
import { ContextButtons } from '~Reviews/V2/Shared/ContextButtons';
import { useDispatch } from 'react-redux';
import { navigateAction } from '~Deprecated/actions/navigate';
import {
  REVIEWS,
} from '~Common/const/routes';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { faPrint, faFileExport } from '@fortawesome/pro-light-svg-icons';
import { usePrintView } from '~Deprecated/hooks/usePrintView';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useHistory } from 'react-router-dom';
import {
  ManagerReviewStatuses, PersonDisplayInformationReviews, ReviewShape, ParticipantTypeEnum,
} from '../Const/types';
import ReviewHeader from '../Shared/ReviewHeader';
import { useReturnReviewee } from '../Hooks/useReturnReviewee';
import { returnReviewParticipantType } from '../Hooks/returnReviewParticipantType';
import SignoffComments from '../Response/SignoffComments';
import { DisplayReviewResponses } from '../Response/DisplayReviewResponses';
import { useCreateCsvInBackground, useGetCSV } from '../Hooks/useGetCSV';
import { ReviewHistoryState } from './ReviewResponseDetails';

const styles = {
  exportButton: css({
    marginRight: '.75rem',
  }),
};

interface ViewProps {
  review: ReviewShape,
  reviewee: PersonDisplayInformationReviews | Record<string, never>,
  reviewer: PersonDisplayInformationReviews | Record<string, never> | undefined,
  secondaryReviewer: PersonDisplayInformationReviews | Record<string, never> | undefined,
  onBackToReviews: () => void,
  printReview: () => void,
  exportReview: () => void,
  canExportReviews: boolean,
}

export const View = ({
  review,
  reviewee,
  reviewer,
  secondaryReviewer,
  onBackToReviews,
  printReview,
  exportReview,
  canExportReviews,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="contextBackButton"
      renderContents={() => (
        <LeadrButton
          variant="text"
          onClick={onBackToReviews}
          textButtonColor={palette.neutrals.gray700}
          data-test-id="reviewsReadOnlyResponseDetailsBackToReviews"
        >
          {`Back to '${review.reviewCycle.name}' details`}
        </LeadrButton>
      )}
    />
    <ContextButtons
      portalId="contextButtons"
      renderContents={() => (
        <>
          {canExportReviews && (
            <LeadrButton
              onClick={exportReview}
              data-test-id="reviewsReadOnlyResponseDetailsExportReview"
              css={styles.exportButton}
              variant="ghost"
            >
              <LeadrButton.IconAndText icon={faFileExport} text="Export CSV" />
            </LeadrButton>
          )}
          <LeadrButton
            onClick={printReview}
            data-test-id="reviewsReadOnlyResponseDetailsPrintReview"
          >
            <LeadrButton.IconAndText icon={faPrint} text="Print Review" />
          </LeadrButton>
        </>
      )}
    />
    <ReviewHeader
      review={review}
      reviewee={reviewee as PersonDisplayInformationReviews}
      reviewer={reviewer as PersonDisplayInformationReviews}
      secondaryReviewer={secondaryReviewer as PersonDisplayInformationReviews}
    />
    {(review.reviewerStatus === ManagerReviewStatuses.Complete || review.reviewerStatus === ManagerReviewStatuses.Signoff_Review) && (
      <SignoffComments
        review={review}
      />
    )}
    <DisplayReviewResponses
      review={review}
    />
  </>
);

interface ReadOnlyReviewResponseDetailsProps {
  review: ReviewShape,
}

export const ReadOnlyReviewResponseDetails = ({ review }: ReadOnlyReviewResponseDetailsProps): JSX.Element => {
  const enableReviewExportBackgroundJob = useFeatureFlag('reviewExportBackgroundJob');
  const reviewee = useReturnReviewee(review);
  const {
    isManager, isSecondaryManager,
  } = returnReviewParticipantType(review);

  const { isAdmin, isExecutive } = useUserPermissions();
  const isAdminOrExecutive = isAdmin || isExecutive;

  const { participants } = review;
  const reviewer = participants?.find((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.Reviewer));
  const secondaryReviewer = participants?.find((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer));
  const dispatch = useDispatch();
  const history = useHistory();
  const routeState = history.location.state as ReviewHistoryState;
  const prevPage = routeState?.prevPage;

  const onBackToReviews = (): void => {
    if (isManager || isSecondaryManager || isAdminOrExecutive) {
      dispatch(navigateAction({
        pathname: prevPage,
        params: {
          cycleId: review.reviewCycle.uid,
        },
      }));
    } else {
      dispatch(navigateAction({
        pathname: REVIEWS,
      }));
    }
  };
  const { onPrint } = usePrintView();
  const printReview = (): void => {
    onPrint({ location: `reviews/${review.uid}` });
  };

  const getReviewsCSV = useGetCSV();
  const createReviewsCSVInBackground = useCreateCsvInBackground();
  const exportReview = (): void => {
    const { orgUserId } = reviewee;
    const cycleId = review.reviewCycle.uid;
    if (enableReviewExportBackgroundJob) {
      createReviewsCSVInBackground({ cycleId, orgUserIds: [orgUserId] });
    } else {
      getReviewsCSV({ cycleId, orgUserIds: [orgUserId] });
    }
  };

  const canExportReviews = useFeatureFlag('reviewsDataExport');

  const hookProps = {
    review,
    reviewee,
    onBackToReviews,
    printReview,
    reviewer,
    secondaryReviewer,
    exportReview,
    canExportReviews,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
