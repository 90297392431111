import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { calendarIntQueryKeys } from '~Meetings/hooks/CalendarIntegration/queryKeys';

interface AddMeetingToCalendarParams {
  meetingFactoryId: string,
}

const addMeetingToCalendar = ({ meetingFactoryId }: AddMeetingToCalendarParams): Promise<HttpCallReturn<null>> => {
  const serverURL = `/organizations/${getOrganizationId() ?? ''}/huddles/series/syncHuddleSeries/${meetingFactoryId}`;

  return patchApi<null>(serverURL);
};

export const useAddMeetingToCalendar = (): UseMutateFunction<HttpCallReturn<null>, unknown, AddMeetingToCalendarParams, void> => {
  const toastId = useRef<ReactText | null>(null);

  const mutation = useMutation({
    mutationFn: addMeetingToCalendar,
    onMutate: () => {
      toastId.current = toast.info('Adding the meeting to your calendar...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error adding the meeting to your calendar. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully added the meeting to your calendar.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: calendarIntQueryKeys.all });
    },
  });

  return mutation.mutate;
};
