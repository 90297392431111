import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { palette } from '~Common/styles/colors';
import LeadrShieldIndigo from '~Assets/images/leadrShieldIndigo.svg';
import LeadrAdvantageIconLogo from '~Root/assets/images/leadrAdvantageIconLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch } from '@fortawesome/pro-regular-svg-icons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useShowGlobalSearchModal } from '~GlobalSearch/stores/useShowGlobalSearchModal';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { NavigationSidebar } from '~Root/components/NavigationSidebar';
import { useNavigationStore } from '~Root/components/NavigationSidebar/useNavigationStore';
import {
  baseNavigationItems,
  organizationNavigationItems,
  alignmentNavigationItems,
  growthNavigationItems,
  nexusNavigationItems,
  devToolNavigationItems,
} from '~Root/components/NavigationSidebar/navigationItemList';
import { hideForPrint } from '~Common/styles/mixins';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  header: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.white,
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    height: '4.25rem',
    minHeight: '4.25rem',
    padding: '0.5rem',
    width: '100%',
  }, IS_MOBILE_APP && {
    paddingTop: 'env(safe-area-inset-top)',
    height: 'calc(4.5rem + env(safe-area-inset-top))',
    minHeight: 'calc(4.5rem + env(safe-area-inset-top))',
    maxHeight: 'calc(4.5rem + env(safe-area-inset-top))',
  }, hideForPrint()),
  icon: css({
    fontSize: '1.5rem',
  }),
  logo: css({
    height: '2.25rem',
    marginLeft: '1rem',
  }),
  buttons: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem',
  }),
  menuWrapper: (isOpen: boolean) => css({
    height: '100%',
    left: 0,
    maxHeight: '100vh',
    position: 'absolute',
    top: 0,
    transform: 'translateX(-100%)',
    transition: 'transform 250ms ease-in-out',
    width: '100%',
    zIndex: 10000,
  }, isOpen && {
    visibility: 'visible',
    transform: 'translateX(0%)',
  }),
  pageTitle: css({
    alignItems: 'center',
    color: palette.neutrals.gray800,
    display: 'flex',
    flex: 1,
    fontSize: '1.125rem',
    fontWeight: 600,
  }),
  partnerLogo: css({
    height: '2.5rem',
  }),
};

interface ViewProps {
  isMobileNavigationOpen: boolean,
  openMobileNavigation: () => void,
  handleShowSearchModalClick: () => void,
  pageTitle: string,
  useGlobalSearch: boolean,
  canViewTableGroup: boolean,
}

const View = ({
  isMobileNavigationOpen,
  openMobileNavigation,
  handleShowSearchModalClick,
  pageTitle,
  useGlobalSearch,
  canViewTableGroup,
}: ViewProps): JSX.Element => (
  <>
    <header css={styles.header}>
      {canViewTableGroup && (
        <LeadrAdvantageIconLogo
          css={[styles.logo, styles.partnerLogo]}
          title="The Table group logo"
          data-test-id="leadrAdvantageIconLogo"
        />
      )}

      {!canViewTableGroup && (
        <LeadrShieldIndigo
          css={styles.logo}
          title="Leadr logo"
          data-test-id="leadrShieldIndigo"
        />
      )}

      <title css={styles.pageTitle}>
        {pageTitle}
      </title>

      <div css={styles.buttons}>
        {useGlobalSearch && (
          <LeadrButton
            css={styles.icon}
            data-test-id="mobileSearchMeetings"
            variant="text"
            textButtonColor={palette.neutrals.gray700}
            onClick={handleShowSearchModalClick}
          >
            <FontAwesomeIcon icon={faSearch} />
          </LeadrButton>
        )}
        <LeadrButton
          css={styles.icon}
          data-test-id="openMobileNavigation"
          variant="text"
          textButtonColor={palette.neutrals.gray700}
          onClick={openMobileNavigation}
        >
          <FontAwesomeIcon icon={faBars} />
        </LeadrButton>
      </div>
    </header>
    <div css={styles.menuWrapper(isMobileNavigationOpen)}>
      <NavigationSidebar
        allowCollapse={false}
        isMobile
      />
    </div>
  </>
);

export const MobileNavigation = (): JSX.Element => {
  const isMobileNavigationOpen = useNavigationStore((state) => state.isMobileNavigationOpen);
  const openMobileNavigation = useNavigationStore((state) => state.openMobileNavigation);
  const location = useLocation();
  const useGlobalSearch = useFeatureFlag('useGlobalSearch');
  const isTableGroupCobrandingEnabled = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const { enableTheTable } = useOrgDetailsContext();
  const canViewTableGroup = enableTheTable && isTableGroupCobrandingEnabled;

  const setShowModal = useShowGlobalSearchModal((state) => state.setShowModal);

  const handleShowSearchModalClick = (): void => {
    setShowModal(true);
  };

  const pageTitle = useMemo(() => (
    [
      ...baseNavigationItems,
      ...organizationNavigationItems,
      ...alignmentNavigationItems,
      ...growthNavigationItems,
      ...nexusNavigationItems,
      ...devToolNavigationItems,
    ].find((navItem) => (
      navItem.href && location.pathname.includes(navItem.href)
    ))?.label || 'Leadr'
  ), [
    location.pathname,
  ]);

  const hookProps = {
    isMobileNavigationOpen,
    openMobileNavigation,
    handleShowSearchModalClick,
    pageTitle,
    useGlobalSearch,
    canViewTableGroup,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
