import { css } from '@emotion/react';
import { SURVEY_ANSWERS_SHAPE, SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';

import { singleBarChartColor, surveyQuestionTypeText } from '~Common/styles/colors';
import { useGetMarksMemoized } from '~Common/hooks/useGetMarksMemoized';
import { BarChart } from '~Common/components/Charts/BarChart';

const styles = {
  type: css`
    text-align: center;
    margin-top: .625rem;
    color: ${surveyQuestionTypeText};
    font-weight:600;
    font-size: .875rem;
  `,
  barChart: css({
    height: 'unset',
    width: 'unset',
  }),
};

const View = ({ ...props }) => (
  <>
    <BarChart chartStyleOverrides={styles.barChart} {...props} />
    <div css={styles.type}>
      Ranking
    </div>
  </>
);

View.propTypes = {};

View.defaultProps = {};

const RankingBarChart = ({ question, answers, ...props }) => {
  const config = JSON.parse(question.configuration);
  const marks = useGetMarksMemoized({ maxValue: parseInt(config.maxValue, 10) });

  const labels = marks.slice(0, config.maxValue.toString()).map((mark) => mark.label);

  const data = labels.map((label, index) => answers?.filter((answer) => answer.answer?.answer === (index + 1).toString()).length);

  const hookProps = {
    data: {
      labels,
      datasets: [
        {
          barThickness: 25,
          data,
          backgroundColor: singleBarChartColor,
        },
      ],
    },
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

RankingBarChart.propTypes = {
  question: SURVEY_QUESTION_SHAPE.isRequired,
  answers: SURVEY_ANSWERS_SHAPE,
};

RankingBarChart.defaultProps = {
  answers: [],
};

export { View };
export default RankingBarChart;
