import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import { transparentize } from 'polished';

import { NAVBAR_LEFT_PADDING } from '../types';

const NAV_ITEM_VERTICAL_PADDING = '0.75rem';

export const SHARED_STYLES = {
  icon: css({
    height: '1.125rem',
    width: '2rem',
  }),
  labelContainer: css({
    flex: 1,
    paddingTop: '0.125rem',
    textAlign: 'start',
  }),
  navContainer: css({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    marginBottom: 0,
    padding: '0',
    position: 'relative',

    '& .MuiCollapse-root': {
      // This seems to correct a bizarre bug in Chrome where the
      // collapse area overlaps with the button below it.
      minHeight: 'unset !important',
    },
  }),
  navItem: (isCollapsed: boolean) => css({
    alignItems: 'center',
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    fontWeight: 500,
    fontSize: '0.875rem',
    gap: '0.5rem',
    height: '2.625rem',
    padding: `${NAV_ITEM_VERTICAL_PADDING} ${NAVBAR_LEFT_PADDING}`,

    ':hover, :focus': {
      backgroundColor: palette.neutrals.gray100,
      textDecoration: 'none',
    },

    '&.active': {
      backgroundColor: transparentize(0.85, palette.brand.indigo),
      color: palette.brand.indigo,
    },
  }, isCollapsed && {
    paddingRight: NAVBAR_LEFT_PADDING,
  }, forMobileObject({
    fontSize: '1rem',
    height: '3rem',
  })),
};
