import { css } from '@emotion/react';
import { useMemo } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { palette } from '~Common/styles/colors';
import { useRealtimePresence } from '~Meetings/stores/useMeetingRealtimeStore';

const styles = {
  button: css({
    padding: 0,
  }),
  avatar: (isActive: boolean) => css({
    border: isActive ? `0.125rem solid ${palette.brand.blue}` : 'initial',
  }),
  container: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75rem',
  }),
  jobTitle: css({
    color: palette.neutrals.gray700,
    fontSize: '0.875rem',
    fontWeight: 400,
  }),
  name: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '19.5px',
  }),
  nameAndTitle: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }),
};

interface ViewProps {
  avatarUrl?: string,
  doClick: () => void,
  jobTitle?: string,
  name: string,
  isActive: boolean,
}

const View = ({
  avatarUrl,
  doClick,
  jobTitle,
  name,
  isActive,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    <LeadrButton
      css={styles.button}
      variant="text"
      data-test-id="meetingOwnerAvatar"
      onClick={doClick}
    >
      <SquareAvatar
        css={styles.avatar(isActive)}
        imgUrl={avatarUrl}
        name={name}
        width={36}
        height={36}
        includeInitials
      />
    </LeadrButton>
    <div css={styles.nameAndTitle}>
      <p css={styles.name}>{name}</p>
      {!!jobTitle && jobTitle !== '' && (
        <p css={styles.jobTitle}>{jobTitle}</p>
      )}
    </div>
  </div>
);

const SkeletonView = (): JSX.Element => (
  <div css={styles.container}>
    <SkeletonLoader
      variant="circular"
      height={36}
      width={36}
      renderComponent={() => <div />}
    />
    <div css={styles.nameAndTitle}>
      <SkeletonLoader
        variant="rectangular"
        width="100%"
        height="1.5rem"
        renderComponent={() => <div />}
      />
    </div>
  </div>
);

interface MeetingHostItemProps {
  huddleId: string
  orgUserId: string,
  onClick: (orgUserId: string, selected: boolean) => void,
}

export const MeetingHostItem = ({
  huddleId,
  orgUserId,
  onClick,
}: MeetingHostItemProps): JSX.Element => {
  const { person, isLoading } = useGetPersonById({ orgUserId });
  const [showSkeleton] = useSkeletonLoaders(isLoading);
  const { activeUsers } = useRealtimePresence(huddleId);

  const isActive = useMemo(() => (
    activeUsers.some((activeUser) => (activeUser === orgUserId))
  ), [activeUsers, orgUserId]);

  if (showSkeleton || !person) {
    return (
      <SkeletonView />
    );
  }

  const {
    firstName,
    lastName,
    jobTitle,
    profileImageUrl,
  } = person;

  const name = `${firstName} ${lastName}`;

  const doClick = (): void => {
    onClick(orgUserId, false);
  };

  const hookProps = {
    avatarUrl: profileImageUrl,
    doClick,
    jobTitle,
    name,
    isActive,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
