import { useFormContext } from 'react-hook-form';
import { startCase } from 'lodash';
import { css } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { palette } from '~Common/styles/colors';
import { uuid } from '~Common/utils/uuid';
import { inputStyles } from './styles';
import RequiredIcon from '../RequiredIcon';

const styles = {
  selectLabel: css({
    color: palette.neutrals.gray500,
    fontWeight: 400,
    left: '0.1rem',
    letterSpacing: '0.5px',
    position: 'absolute',
    top: '.75rem',

    '&.Mui-focused, &.MuiFormLabel-filled': {
      color: palette.neutrals.gray500,
      top: '1rem',
    },
  }),
  input: inputStyles,
  requiredIcon: css({
    position: 'absolute',
    top: '0.75rem !important',
    right: '1rem',
    zIndex: 1,
  }),
};

interface SelectProps extends MuiSelectProps {
  name: string,
  label?: string,
  showRequiredIcon?: boolean,
}

export function Select({
  id = uuid(),
  name,
  label = startCase(name),
  children,
  showRequiredIcon,
  ...props
}: SelectProps): JSX.Element {
  const { register, formState: { errors } } = useFormContext();
  const error = errors[name] as { message: string; };

  return (
    <FormControl>
      <InputLabel
        css={styles.selectLabel}
        id={`${id}_label`}
        error={!!error}
      >
        {label}
      </InputLabel>
      {showRequiredIcon && (
        <RequiredIcon css={styles.requiredIcon} />
      )}
      <MuiSelect
        css={styles.input}
        id={id}
        labelId={`${id}_label`}
        error={!!error}
        {...register(name)}
        {...props}
      >
        {children}
      </MuiSelect>
      {error && (
        <FormHelperText error>{error.message}</FormHelperText>
      )}
    </FormControl>
  );
}
