import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Goals } from '@leadr-hr/types';
import Tooltip from '~Common/components/Tooltip';
import NewGoalStatus from '~Goals/components/Shared/NewGoalStatus';
import { forMobileObject } from '~Common/styles/mixins';
import ProgressBarWithText from '~Goals/components/Shared/ProgressBarWithText';
import ChildGoalCount from '~Goals/components/Shared/ChildGoalCount';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';

const styles = {
  nameCell: css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }),
  totalChildGoalsAndTeamNameContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.25rem',
    color: palette.neutrals.gray700,
  }),
  goalTitleWrapper: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  }),
  goalTitle: css({
    fontSize: '.875rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
  }),
  subText: css({
    fontSize: '.75rem',
    fontWeight: 400,
    color: palette.neutrals.gray800,
  }),
  lockIcon: css({
    color: palette.neutrals.gray700,
    fontSize: '.625rem',
  }),
  goalDates: css({
    whiteSpace: 'normal',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.25rem',
  }),
  isPrivate: css({
    display: 'inline-block',
    marginLeft: '.5rem',
  }),
  progressBarWithText: css({}, forMobileObject({
    width: '100%',
    flexDirection: 'row',
    gap: '.5rem',
    alignItems: 'center',
  })),
};

interface NameCellProps {
  title: string,
  status: Goals.GoalStatus,
  subText?: string,
  percentage?: number,
  isPrivate?: boolean,
  totalChildGoals?: number,
  showProgressBar?: boolean,
}

const NameCell = ({
  title,
  isPrivate,
  totalChildGoals = 0,
  subText,
  percentage,
  status,
  showProgressBar,
  ...props
}: NameCellProps): JSX.Element => (
  <div css={styles.nameCell} {...props}>
    <div css={styles.goalTitleWrapper}>
      <TooltipOnTruncate data-test-id="goalsTableTitle" css={styles.goalTitle} text={title} />
      {isPrivate && (
        <div css={styles.isPrivate}>
          <Tooltip content="This is a private goal. It is only visible to you.">
            <div>
              <FontAwesomeIcon css={styles.lockIcon} icon={faLock} />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
    {(totalChildGoals > 0 || !!subText) && (
    <div css={styles.totalChildGoalsAndTeamNameContainer}>
      {totalChildGoals > 0 && (
        <ChildGoalCount totalChildGoals={totalChildGoals} />
      )}
      {!!subText && (
        <span data-test-id="goalsTableSubText" css={styles.subText}>{subText}</span>
      )}
    </div>
    )}
    {showProgressBar && (
      <ProgressBarWithText
        data-test-id="goalsTableProgressBar"
        css={styles.progressBarWithText}
        status={status}
        percentage={percentage}
        renderText={() => (
          <NewGoalStatus
            status={status}
            isMobile
          />
        )}
      />
    )}
  </div>
);

export default NameCell;
