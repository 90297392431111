import {
  ChangeEvent,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { css } from '@emotion/react';
import {
  Collapse, FormControlLabel, Checkbox,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { compact, uniq } from 'lodash';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { withTruncate } from '~Common/styles/mixins';

import { User } from '~Common/const/interfaces';
import {
  useGetPeopleFilters, DepartmentsFromAPI, AccountTypesFromAPI, UserGroupsFromAPI,
} from '~Common/hooks/useGetPeopleFilters';
import { DRAWER_WIDTHS, FilterType, FilterCountsType } from '~Common/const/drawers';
import { palette, radioColor } from '~Common/styles/colors';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import Button from '~Common/V3/components/Button';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { useStoreData } from '~Common/hooks/useStoreData';
import { CardSkeleton } from '~Common/V3/components/Card';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { PAGE_STYLES } from '~Admin/const/pageStyles';
import { AdminTab, MapResultTotals } from '~Admin/const/defaults';
import { FILTER_SEPARATOR } from '~Admin/hooks/useFilterUsers';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

function pullManagerUsers(allPeople: User[]): User[] {
  const managerIds = uniq(compact(Object.values(allPeople).map((person) => person.managerId)));
  const managerUsers = allPeople.filter((person) => managerIds.includes(person.id));

  return managerUsers;
}

const styles = {
  ...PAGE_STYLES,
  headerOverride: css({
    borderBottom: 'none',
  }),
  drawerBody: css({
    color: palette.neutrals.gray700,
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
  closeButton: css({
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  }),
  everyone: css({
    marginBottom: '0',
    width: 'auto',
    marginLeft: '0',

    '& .MuiFormControlLabel-label': {
      color: palette.brand.indigo,
    },
    '& .MuiCheckbox-root ': {
      display: 'none',
    },
  }),
  heading: css({
    alignItems: 'center',
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '1rem',
    marginBottom: '0.5rem',
    marginTop: '1.25rem',
    padding: 0,
    width: '100%',

    svg: {
      display: 'block',
      color: palette.brand.indigo,
    },
  }),
  label: css({
    marginBottom: '-0.5rem',
    width: '100%',
    display: 'inline-block',

    '& .Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.3) !important',
    },
    '& .MuiFormControlLabel-label': {
      color: palette.neutrals.gray700,
      fontSize: '0.875rem',
    },
  }, withTruncate()),
  radio: css({
    color: `${palette.neutrals.gray400} !important`,
    '& .MuiSelected,&.Mui-checked': {
      color: `${radioColor} !important`,
    },
  }),
  switch: css({
    margin: '1.25rem 0',
  }),
  searchBox: css({
    marginTop: '1.75rem',
  }),
  cardSkeletonOveride: css({
    height: '.5rem',
  }),
  results: css({
    color: palette.neutrals.gray500,
    fontSize: '.875rem',
  }),
  drawerHeaderDetails: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '.375rem',
  }),
};

function filterCounts(filteredUsers: User[]): FilterCountsType {
  const counts: FilterCountsType = {
    users: filteredUsers.length,
    accounttype: {},
    department: {},
    usergroup: {},
    none: {},
    manager: {},
  };

  filteredUsers.forEach((user) => {
    counts.accounttype[user.userGroupId] ??= 0;
    counts.accounttype[user.userGroupId] += 1;

    if (user.department) {
      counts.department[user.department] ??= 0;
      counts.department[user.department] += 1;
    }

    user.teams.forEach((team) => {
      counts.usergroup[team.teamId] ??= 0;
      counts.usergroup[team.teamId] += 1;
    });
  });

  return counts;
}

const filtersTemplate = {
  name: 'GENERIC_FILTERS',
  width: DRAWER_WIDTHS.BASE,
};

  interface ViewProps {
    closeDrawerClick: () => void,
    departments:DepartmentsFromAPI[],
    accountTypes: AccountTypesFromAPI[],
    expandedMenus: string[],
    filterType: FilterType[],
    filterValue: string[],
    handleClick: (filterType: FilterType, filterValue: string) => void,
    handleClickHeading: (filterType: FilterType) => void,
    managerUsers: User[],
    userGroups: UserGroupsFromAPI[],
    drawerTitle: string,
    drawerInstruction?: string,
    includeDirectReports?: boolean,
    onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
    counts: FilterCountsType,
    resultTotals: MapResultTotals,
    teamsText: string,
  }

const View = ({
  closeDrawerClick,
  departments,
  accountTypes,
  expandedMenus,
  filterType,
  filterValue,
  handleClick,
  handleClickHeading,
  managerUsers,
  userGroups,
  drawerTitle,
  drawerInstruction,
  includeDirectReports,
  onSearch,
  counts,
  resultTotals,
  teamsText,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        hideBorder
        renderCloseButton={() => (
          <button onClick={closeDrawerClick} css={styles.closeButton} title="Close drawer">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        renderDrawerInstructions={() => (
          <>
            <p>{drawerInstruction}</p>
            <LeadrSearchField data-test-id="filterDrawerSearchField" onChange={onSearch} css={styles.searchBox} />
            <div css={styles.drawerHeaderDetails}>
              <FormControlLabel
                label="Clear All Filters"
                css={[styles.label, styles.everyone]}
                control={(
                  <Checkbox
                    css={styles.radio}
                    checked={filterType.length === 0 || filterType.includes(FilterType.Everyone)}
                    onChange={() => handleClick(FilterType.Everyone, '')}
                  />
                  )}
              />
              <span css={styles.results}>{`${counts.users} Results`}</span>
            </div>
          </>
        )}
        title={`${drawerTitle} Filters`}
      />
    )}
    renderBody={() => (
      <>
        <div
          css={styles.drawerBody}
        >
          {(counts.users === 0 && expandedMenus.length === 0 && filterValue.length === 0) && (
            <MultipleSkeletonLoaders
              css={styles.listSection}
              numberOfSkeletons={4}
              renderSkeletonItem={() => (
                <CardSkeleton css={[styles.cardSkeleton, styles.cardSkeletonOveride]} />
              )}
            />
          )}
          {(counts.users > 0 || expandedMenus.length > 0 || filterValue.length !== 0) && (
            <>
              {!includeDirectReports && (
              <>
                <Button
                  css={styles.heading}
                  variant="text"
                  onClick={() => handleClickHeading(FilterType.Account)}
                  renderContents={() => (
                    <>
                      Account Types
                      {' '}
                      {/*
                         Use this if you want the numbers to be dynamic
                        {Object.keys(counts[FilterType.Account]).length ? `(${Object.keys(counts[FilterType.Account]).length})` : '(0)'}
                      */}
                      {`(${accountTypes.length})`}
                      <FontAwesomeIcon icon={expandedMenus.includes(FilterType.Account) ? faMinus : faPlus} />
                    </>
                  )}
                />
                <Collapse
                  in={expandedMenus.includes(FilterType.Account)}
                >
                  {accountTypes.map((account) => (
                    <>
                      <FormControlLabel
                        key={account.typeId}
                        // Use this if you want the numbers to be dynamic
                        // label={`${account.name} (${counts.accounttype[account.typeId] ? counts.accounttype[account.typeId] : '0'})`}
                        label={`${account.name} (${account[resultTotals] ? account[resultTotals] : '0'})`}
                        css={styles.label}
                        control={(
                          <Checkbox
                            css={styles.radio}
                            checked={filterType.includes(FilterType.Account) && filterValue.includes(account.typeId)}
                            onChange={() => handleClick(FilterType.Account, account.typeId)}
                          />
                      )}
                      />
                    </>
                  ))}
                </Collapse>
              </>
              )}
              {includeDirectReports && (
              <>
                <Button
                  css={styles.heading}
                  variant="text"
                  onClick={() => handleClickHeading(FilterType.Manager)}
                  renderContents={() => (
                    <>
                      Manager Direct Reports
                      {Object.keys(counts[FilterType.Manager]).length ? `(${Object.keys(counts[FilterType.Manager]).length})` : '(0)'}
                      <FontAwesomeIcon icon={expandedMenus.includes(FilterType.Manager) ? faMinus : faPlus} />
                    </>
                  )}
                />
                <Collapse
                  in={expandedMenus.includes(FilterType.Manager)}
                >
                  {managerUsers.map((manager) => (
                    <FormControlLabel
                      key={manager.id}
                      label={`${manager.firstName} ${manager.lastName}'s Direct Reports`}
                      css={styles.label}
                      control={(
                        <Checkbox
                          css={styles.radio}
                          checked={filterType.includes(FilterType.Manager) && filterValue.includes(manager.id)}
                          onChange={() => handleClick(FilterType.Manager, manager.id)}
                        />
                      )}
                    />
                  ))}
                </Collapse>
              </>
              )}

              <Button
                css={styles.heading}
                variant="text"
                onClick={() => handleClickHeading(FilterType.Department)}
                renderContents={() => (
                  <>
                    Departments
                    {' '}
                    {/*
                      Use this if you want the numbers to be dynamic
                      {Object.keys(counts[FilterType.Department]).length ? `(${Object.keys(counts[FilterType.Department]).length})` : '(0)'}
                    */}
                    {`(${departments.length})`}
                    <FontAwesomeIcon icon={expandedMenus.includes(FilterType.Department) ? faMinus : faPlus} />
                  </>
                )}
              />
              <Collapse
                in={expandedMenus.includes(FilterType.Department)}
              >
                {departments.map((department) => (
                  <>
                    <FormControlLabel
                      key={`${department.total}-${department.value}`}
                      // Use this if you want the numbers to be dynamic
                      // label={`${department.value} (${counts.department[department.value] ? counts.department[department.value] : '0'})`}
                      label={`${department.value} (${department[resultTotals] ? department[resultTotals] : '0'})`}
                      css={styles.label}
                      control={(
                        <Checkbox
                          css={styles.radio}
                          checked={filterType.includes(FilterType.Department) && filterValue.includes(department.value)}
                          onChange={() => handleClick(FilterType.Department, department.value)}
                        />
                      )}
                    />
                  </>
                ))}
              </Collapse>

              <Button
                css={styles.heading}
                variant="text"
                onClick={() => handleClickHeading(FilterType.UserGroup)}
                renderContents={() => (
                  <>
                    {teamsText}
                    {' '}
                    {/*
                      Use this if you want the numbers to be dynamic
                      {Object.keys(counts[FilterType.UserGroup]).length ? `(${Object.keys(counts[FilterType.UserGroup]).length})` : '(0)'}
                    */}
                    {`(${userGroups.length})`}
                    <FontAwesomeIcon icon={expandedMenus.includes(FilterType.UserGroup) ? faMinus : faPlus} />
                  </>
                )}
              />
              <Collapse
                in={expandedMenus.includes(FilterType.UserGroup)}
              >
                {userGroups.map((group) => (
                  <FormControlLabel
                    key={group.teamId}
                    // Use this if you want the numbers to be dynamic
                    // label={`${group.name} (${counts.usergroup[group.teamId] ? counts.usergroup[group.teamId] : '0'})`}
                    label={`${group.name} (${group[resultTotals] ? group[resultTotals] : '0'})`}
                    css={styles.label}
                    control={(
                      <Checkbox
                        css={styles.radio}
                        checked={filterType.includes(FilterType.UserGroup) && filterValue.includes(group.teamId)}
                        onChange={() => handleClick(FilterType.UserGroup, group.teamId)}
                      />
                    )}
                  />
                ))}
              </Collapse>
            </>
          )}
        </div>
      </>
    )}
  />
);

  interface FilterDrawerProps {
    allPeople: User[],
    updateFilters: (filters: string[]) => void,
    drawerTitle: string,
    drawerInstruction: string,
    includeDirectReports: boolean,
    workflow?: string,
  }

const FilterDrawer = ({
  allPeople,
  updateFilters,
  drawerTitle,
  drawerInstruction,
  includeDirectReports,
  workflow = 'filter-drawer',
}: FilterDrawerProps): JSX.Element => {
  const dispatch = useDispatch();
  const [filters] = useQueryParamState<string[]>(workflow, 'filters', []);
  const [filterType, filterValue] = filters.reduce<[FilterType[], string[]]>(([type, value], joined) => {
    const [newType, newValue] = joined.split(FILTER_SEPARATOR);
    type.push(newType as FilterType);
    value.push(newValue);
    return [uniq(type), value];
  }, [[], []]);
  const [expandedTab] = useQueryParamState<AdminTab>(workflow, 'tab', AdminTab.Active);
  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');

  const teamsText = webAppTeamsV2 ? 'Teams' : 'User Groups';

  const resultTotals = MapResultTotals[expandedTab];
  const {
    groups: userGroups, departments, accountTypes,
  } = useGetPeopleFilters();

  const [expandedMenus, setExpandedMenus] = useState<string[]>([...filterType]);
  const managerUsers = useMemo(() => pullManagerUsers(allPeople), [allPeople]);

  useEffect(() => {
    updateFilters(filters);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dataStore] = useStoreData();
  const counts = useMemo(
    () => filterCounts(dataStore),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataStore.length],
  );

  const handleClick = useCallback((clickedFilterType: FilterType, clickedFilterValue: string) => {
    if (clickedFilterType === FilterType.Everyone) {
      updateFilters([]);
      return;
    }

    let resultFilters: string[] = [];

    if (typeof filters === 'string') {
      resultFilters = [filters];
    } else {
      resultFilters = Array.from(filters);
    }

    const clickedFilter = `${clickedFilterType}${FILTER_SEPARATOR}${clickedFilterValue}`;
    const indexFilter = resultFilters.indexOf(clickedFilter);

    if (indexFilter === -1) {
      resultFilters.push(clickedFilter);
    } else {
      resultFilters.splice(indexFilter, 1);
    }
    updateFilters([...resultFilters]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleClickHeading = useCallback((clickedFilterType: FilterType) => {
    const result = Array.from(expandedMenus);
    const index = result.indexOf(clickedFilterType);

    if (index === -1) {
      result.push(clickedFilterType);
    } else {
      result.splice(index, 1);
    }
    setExpandedMenus([...result]);
  }, [expandedMenus]);

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
  };

  const [searchedAccountTypes, setSearchedAccountTypes] = useState(accountTypes);
  const [searchedDepartments, setSearchedDepartments] = useState(departments);
  const [searchedUsergroups, setSearchedUsergroups] = useState(userGroups);
  const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const searchFiltersText = event.target.value;
    setSearchedAccountTypes(accountTypes.filter((accountType) => accountType.name.toLowerCase().includes(searchFiltersText.toLowerCase())));
    setSearchedDepartments(departments.filter((department) => department.value.toLowerCase().includes(searchFiltersText.toLowerCase())));
    setSearchedUsergroups(userGroups.filter((userGroup) => userGroup.name.toLowerCase().includes(searchFiltersText.toLowerCase())));
  }, [accountTypes, departments, userGroups]);

  const hookProps = {
    closeDrawerClick,
    departments: searchedDepartments.length ? searchedDepartments : departments,
    accountTypes: searchedAccountTypes.length ? searchedAccountTypes : accountTypes,
    userGroups: searchedUsergroups.length ? searchedUsergroups : userGroups,
    expandedMenus,
    filterType,
    filterValue,
    handleClick,
    handleClickHeading,
    managerUsers,
    drawerTitle,
    drawerInstruction,
    includeDirectReports,
    onSearch,
    counts,
    resultTotals,
    teamsText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: filtersTemplate.name,
  component: FilterDrawer,
});

export { View, filtersTemplate };
export default FilterDrawer;
