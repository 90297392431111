import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import IconButton from '~Common/V3/components/Buttons/IconButton';

import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';

import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { PrivateMeetingNotes } from '../details/meetingNotes/PrivateMeetingNotes';

export const privateNotesTemplate = {
  name: 'PRIVATE_MEETING_NOTES',
  width: DRAWER_WIDTHS.MEETING_NOTES,
};

const styles = {
  notes: css`
    width: calc(100% - 0.3rem);
    height: 100%;
    max-height: 100%;
  `,
  backgroundColor: css`
    background-color: white;
  `,
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

const View = ({
  closeButton,
  renderDrawerInstructions,
  meetingFactoryId,
}) => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title="Private Meeting Notes"
        renderCloseButton={closeButton}
        renderDrawerInstructions={renderDrawerInstructions}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <PrivateMeetingNotes
          css={styles.notes}
          inDrawer
          meetingFactoryId={meetingFactoryId}
        />
      </div>
    )}
  />
);

View.propTypes = {
  closeButton: PropTypes.func.isRequired,
  meetingFactoryId: PropTypes.string.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
};

View.defaultProps = {
};

const PrivateNotesDrawer = ({
  ...props
}) => {
  const dispatch = useDispatch();

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const renderDrawerInstructions = useCallback(() => 'Keep notes on this meeting that only you can see.', []);

  const hookProps = {
    closeButton,
    renderDrawerInstructions,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

PrivateNotesDrawer.propTypes = {
  drawerState: PropTypes.func.isRequired,
  setDrawerState: PropTypes.func.isRequired,
};

PrivateNotesDrawer.defaultProps = {
};

registerDrawer({
  templateName: privateNotesTemplate.name,
  component: PrivateNotesDrawer,
});

export { View };
export default PrivateNotesDrawer;
