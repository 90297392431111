import { ReactText, useRef } from 'react';
import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { TemplateTopic, TemplateType } from '~Meetings/const/meetingsInterfaces';
import { meetingKeys } from '~Meetings/const/queryKeys';

/*
  This is the old add topics from template to meeting API.
  However, we need to keep it around since Curated By Leadr templates still uses this call
  Eventually we will update to use the newer bulk add API
  But as of 2/27/2024, we still need this for Curated By Leadr templates
*/

interface AddTemplateTopicParams {
  meetingType: TemplateType,
  meetingId: string,
  templateTopics: TemplateTopic[],
}

interface AddTemplateTopicsReturn {
  agendaItemIds: {
    agendaItemId: string,
    id: string,
  },
}

const addTemplateTopics = ({
  meetingType,
  meetingId,
  templateTopics,
}: AddTemplateTopicParams): Promise<HttpCallReturn<AddTemplateTopicsReturn>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-items`;
  const data = {
    items: templateTopics,
    context: {
      type: meetingType,
      id: meetingId,
    },
  };

  return postApi<AddTemplateTopicsReturn>(URL, data);
};

interface UseAddTemplateTopicsParams {
  options?: Omit<UseMutationOptions<HttpCallReturn<AddTemplateTopicsReturn>, unknown, AddTemplateTopicParams>, 'mutationKey' | 'mutationFn'>
}

const useAddTemplateTopics = ({
  options = {},
// eslint-disable-next-line max-len
}: UseAddTemplateTopicsParams = {}): UseMutationResult<HttpCallReturn<AddTemplateTopicsReturn>, unknown, AddTemplateTopicParams, unknown> => {
  const toastId = useRef<ReactText | number | null>(null);

  return useMutation({
    mutationFn: addTemplateTopics,
    onMutate: () => {
      toastId.current = toast.info('Adding your topic(s) to the meeting...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error adding your topic(s) to the meeting. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully added your topic(s) to the meeting!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
    onSettled: (_, __, { meetingId: huddleId }) => {
      void queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId() ?? '') });
    },
    ...options,
  });
};

export default useAddTemplateTopics;
