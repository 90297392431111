import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { NoteContext } from '~Common/const/interfaces';

import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';

export interface DeleteNoteData {
  id: string,
  context: NoteContext,
}

export const deleteNote = async ({
  id,
  context,
}: DeleteNoteData): Promise<HttpCallReturn<null>> => {
  const url = `/organizations/${getOrganizationId() ?? ''}/notes/${id}`;

  const requestData = {
    context,
  };

  return deleteApi(url, requestData);
};

export const useDeleteNote = (
  options?: Omit<UseMutationOptions<HttpCallReturn<null>, unknown, DeleteNoteData>, 'mutationFn'>,
): UseMutationResult<HttpCallReturn<null>, unknown, DeleteNoteData> => (
  useMutation({
    mutationFn: deleteNote,
    ...options,
  })
);
