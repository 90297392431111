import { createAction } from '@reduxjs/toolkit';

import { registerAction } from '~Deprecated/reducers/organizationMembers';
import { mutateState } from '~Deprecated/utils/reduxUtils';

const clearOrganizationMembersAction = createAction('LEADR.CLEAR_ORGANIZATION_MEMBERS');

function clearOrganizationMembers(state) {
  return mutateState(state, (draftState) => {
    draftState.ids = [];
    draftState.data = {};

    return draftState;
  });
}

registerAction(clearOrganizationMembersAction, clearOrganizationMembers);

export {
  clearOrganizationMembersAction,
};
