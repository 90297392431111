import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useMemo, useState } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
// import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useLocation } from 'react-router-dom';
import { COACHING_ROUTE } from '~Common/const/routes';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { palette } from '~Common/styles/colors';
import { AgendaTemplates } from '@leadr-hr/types';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { CURATED_BY_LEADR_TEXT, CURATED_BY_TABLE_GROUP_TEXT } from '~Common/const/curated';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import CuratedByLeadrTab from './Tabs/CuratedByLeadrTab';
import { createTopicTemplateDrawerTemplate } from '../CreateTopicTemplateDrawer';
import { MeetingInfoContext } from '../contexts/MeetingInfoContext';
import CuratedByTableGroupTab from './Tabs/CuratedByTableGroupTab';
import YourOrgAndMyTopicsTab from './Tabs/YourOrgAndMyTopicsTab';

const styles = {
  drawerContainer: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  }),
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
  drawerBody: css({
    marginBottom: '1.5rem',
    flex: 1,
  }),
  leadrSearchField: css({
    marginBottom: '1.125rem',
  }),
  drawerFooter: css({
    position: 'sticky',
    bottom: '0',
    padding: '.75rem 0 .75rem 0',
    background: palette.neutrals.white,
  }),
};

export const topicSuggestionsDrawerTemplate = {
  name: 'TOPIC_SUGGESTIONS_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

enum Topic_Suggestion_Tabs {
  MyTopics = 0,
  MyOrg = 1,
  Leadr = 2,
  TableGroup = 3,
}
interface TopicSuggestionsDrawerProps extends DrawerProps<Record<string, unknown>> {
  huddleId: string,
  maxTopics: number,
  maxSections: number,
  isEditingFromOrg?: boolean,
}

const TopicSuggestionsDrawer = ({
  huddleId,
  popDrawer,
  pushDrawer,
  maxTopics,
  maxSections,
  isEditingFromOrg = false,
}: TopicSuggestionsDrawerProps): JSX.Element => {
  const location = useLocation();
  const { isLimitedAccess, isAdmin } = useUserPermissions();
  const { enableTheTable } = useOrgDetailsContext();
  const webAppTlaCuratedMeetingTemplates = useFeatureFlag('webAppTlaCuratedMeetingTemplates');

  const showTableGroupTab = webAppTlaCuratedMeetingTemplates && enableTheTable;
  const showMyTopicsTab = !isLimitedAccess;

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: topicSuggestionsDrawerTemplate.name });
  };

  const openTopicSuggestionsDrawer = (): void => {
    pushDrawer({
      drawer: {
        ...topicSuggestionsDrawerTemplate,
        args: {
          huddleId,
          maxTopics,
          maxSections,
          isEditingFromOrg: activeTab === Topic_Suggestion_Tabs.MyOrg,
        },
      },
    });
  };

  const meetingTemplateType = useMemo(() => {
    if (location.pathname === COACHING_ROUTE) {
      return AgendaTemplates.MeetingTemplateType.Coaching;
    }

    return AgendaTemplates.MeetingTemplateType.TeamMeeting;
  }, [location.pathname]);

  const [activeTab, setActiveTab] = useState((isEditingFromOrg || isLimitedAccess ? Topic_Suggestion_Tabs.MyOrg : Topic_Suggestion_Tabs.MyTopics));

  const handleNewTemplateClick = (): void => {
    closeDrawerClick();
    // Create new template
    pushDrawer({
      drawer: {
        ...createTopicTemplateDrawerTemplate,
        args: {
          openTopicSuggestionsDrawer,
          maxTopics,
          maxSections,
          isPrivate: activeTab === Topic_Suggestion_Tabs.MyTopics,
        },
      },
    });
  };

  const meetingInfoContext = useMemo(
    () => ({
      huddleId,
      meetingTemplateType,
    }),
    [huddleId, meetingTemplateType],
  );

  const hookProps = {
    renderHeader: () => (
      <TabDrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            tooltip="Close"
            size="large"
          />
        )}
        title="Topic Suggestions"
        renderTabNav={(tabNavStyles: SerializedStyles) => (
          <div css={tabNavStyles}>
            {showMyTopicsTab && (
              <TabNavItem
                css={styles.tabNavItem}
                isActive={activeTab === Topic_Suggestion_Tabs.MyTopics}
                onClick={() => setActiveTab(Topic_Suggestion_Tabs.MyTopics)}
                renderNavItem={() => (
                  <div>My Topics</div>
                )}
              />
            )}
            <TabNavItem
              css={styles.tabNavItem}
              onClick={() => setActiveTab(Topic_Suggestion_Tabs.MyOrg)}
              isActive={activeTab === Topic_Suggestion_Tabs.MyOrg}
              renderNavItem={() => (
                <div>My Org</div>
              )}
            />
            <TabNavItem
              css={styles.tabNavItem}
              isActive={activeTab === Topic_Suggestion_Tabs.Leadr}
              onClick={() => setActiveTab(Topic_Suggestion_Tabs.Leadr)}
              renderNavItem={() => (
                <>{`By ${CURATED_BY_LEADR_TEXT}`}</>
              )}
            />
            {showTableGroupTab && (
              <TabNavItem
                css={styles.tabNavItem}
                isActive={activeTab === Topic_Suggestion_Tabs.TableGroup}
                onClick={() => setActiveTab(Topic_Suggestion_Tabs.TableGroup)}
                renderNavItem={() => (
                  <>{`By ${CURATED_BY_TABLE_GROUP_TEXT}`}</>
                )}
              />
            )}
          </div>
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <MeetingInfoContext.Provider value={meetingInfoContext}>
        <div css={[defaultBodyPadding, styles.drawerContainer]}>
          <div css={styles.drawerBody}>
            { /* TODO: Add search (coming later) */ }
            {/* <LeadrSearchField css={styles.leadrSearchField} /> */}
            {(activeTab === Topic_Suggestion_Tabs.MyOrg || activeTab === Topic_Suggestion_Tabs.MyTopics) && (
              <YourOrgAndMyTopicsTab
                handleNewTemplateClick={handleNewTemplateClick}
                closeDrawer={closeDrawerClick}
                openTopicSuggestionsDrawer={openTopicSuggestionsDrawer}
                maxTopics={maxTopics}
                maxSections={maxSections}
                isPrivate={activeTab === Topic_Suggestion_Tabs.MyTopics}
              />
            )}
            {activeTab === Topic_Suggestion_Tabs.Leadr && (
              <CuratedByLeadrTab />
            )}
            {showTableGroupTab && activeTab === Topic_Suggestion_Tabs.TableGroup && (
              <CuratedByTableGroupTab />
            )}
          </div>
          {(!isLimitedAccess && ((activeTab === Topic_Suggestion_Tabs.MyOrg && isAdmin) || activeTab === Topic_Suggestion_Tabs.MyTopics)) && (
            <div css={styles.drawerFooter}>
              <LeadrButton
                data-test-id="meetingTopicSuggestionsNewTemplate"
                onClick={handleNewTemplateClick}
              >
                {activeTab === Topic_Suggestion_Tabs.MyTopics && (
                  <span>+ New Private Template</span>
                )}
                {activeTab === Topic_Suggestion_Tabs.MyOrg && (
                  <span>+ New Public Template</span>
                )}
              </LeadrButton>
            </div>
          )}
        </div>
      </MeetingInfoContext.Provider>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: topicSuggestionsDrawerTemplate.name,
  component: TopicSuggestionsDrawer,
});

export default TopicSuggestionsDrawer;
