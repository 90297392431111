import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import PrivateIcon from './LinkedGoalCard/PrivateIcon';

const styles = {
  hiddenLinkedGoalsCard: css({
    cursor: 'not-allowed',
    gridColumn: '1/5',
    display: 'grid',
    gridTemplateColumns: 'subgrid',
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    gridColumn: '1',
  }),
  privateIcon: css({
    gridColumn: '3',
  }),
};

interface HiddenLinkedGoalsCardProps {
  hiddenGoalsCount: number,
}

const HiddenLinkedGoalsCard = ({
  hiddenGoalsCount,
  ...props
}: HiddenLinkedGoalsCardProps): JSX.Element => (
  <div
    css={styles.hiddenLinkedGoalsCard}
    {...props}
  >
    <div css={styles.title}>{`${hiddenGoalsCount} Private Goal${hiddenGoalsCount > 1 ? 's' : ''}`}</div>
    <PrivateIcon css={styles.privateIcon} />
  </div>
);

export default HiddenLinkedGoalsCard;
