import { InstantSearch } from 'react-instantsearch-hooks-web';
import { ReactNode } from 'react';
import { useGetAlgoliaSearchClient } from '~GlobalSearch/hooks/useGetAlgoliaSearchClient';
import { SearchClient } from 'instantsearch.js';

interface ViewProps {
  children: ReactNode,
  searchClient: SearchClient,
}

const View = ({
  children,
  searchClient,
  ...props
}: ViewProps): JSX.Element => (
  <InstantSearch
    // We need one index name here so that useInstantSearch returns scopedResults for the count for each tab
    indexName="MEETING-INSTANCES"
    searchClient={searchClient}
    {...props}
  >
    {children}
  </InstantSearch>
);

type AlgoliaInstantSearchProps = Omit<ViewProps, 'searchClient'>;

const AlgoliaInstantSearch = ({ ...props }: AlgoliaInstantSearchProps): JSX.Element => {
  const searchClient = useGetAlgoliaSearchClient();

  const hookProps = {
    searchClient,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AlgoliaInstantSearch;
