import { useGenericStore } from '~Common/hooks/useGenericStore';

interface UseShowConnectCalendarBannerReturn {
  showConnectCalendarBanner: boolean,
  dismissConnectCalendarBanner: () => void,
}

export const useShowConnectCalendarBanner = (): UseShowConnectCalendarBannerReturn => {
  const {
    value: showConnectCalendarBanner,
    setValue: setShowConnectCalendarBanner,
  } = useGenericStore({
    module: 'nylas', workflow: 'notification', feature: 'showConnectCalendar', defaultValue: true,
  }) as { value: boolean, setValue: (value: boolean) => void };

  const dismissConnectCalendarBanner = (): void => setShowConnectCalendarBanner(false);

  return {
    showConnectCalendarBanner,
    dismissConnectCalendarBanner,
  };
};
