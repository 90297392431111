import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { useSkipMeeting } from '~Meetings/hooks/useSkipMeeting';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';

const styles = {
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

export const skipMeetingTemplate = {
  name: 'SKIP_MEETING_OCCURENCE',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton,
  createButton,
  renderDrawerInstructions,
  onFormSubmit,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={closeButton}
        title="Skip Meeting"
      />
    )}
    renderBody={() => (
      <div
        css={styles.drawerBody}
      >
        {renderDrawerInstructions()}
        <div />
        {createButton()}
      </div>
    )}
  />
);

View.propTypes = {
  closeButton: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

View.defaultProps = {};

const SkipMeeting = ({ id, type, ...props }) => {
  const dispatch = useDispatch();
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const { startTimeInMillis, nextHuddleStartTime, nextHuddleId } = meetingDetails ?? {};
  const meetingStart = moment(startTimeInMillis).format('MMMM Do, YYYY');
  const nextMeeting = moment(nextHuddleStartTime).format('MMMM Do, YYYY');

  const doSkipMeeting = useSkipMeeting({ template: skipMeetingTemplate, nextHuddleId });

  const renderDrawerInstructions = () => {
    const drawerText = `This will skip the occurence of this meeting on <b>${meetingStart}</b> for all attendees.`;
    const drawerSubText = `The next meeting in this series will be on the upcoming date of <b>${nextMeeting}</b>.`;
    const warningText = 'All Notes and Topics will be cloned into the next meeting, regardless of duplication.';

    return (
      <div>
        <DrawerInstructions
          html={drawerText}
        />
        <br />
        <DrawerInstructions
          html={drawerSubText}
        />
        <br />
        <DrawerInstructions
          text={warningText}
        />
      </div>
    );
  };
  const createButton = useCallback(() => (
    <LeadrButton
      data-test-id="meetingSkip"
      type="submit"
    >
      Skip Meeting
    </LeadrButton>
  ), []);

  const onFormSubmit = useCallback((e) => {
    e.preventDefault();

    doSkipMeeting({
      id,
      type,
      lastState: meetingDetails,
    });
  }, [doSkipMeeting, meetingDetails, type, id]);

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const hookProps = {
    closeButton,
    createButton,
    renderDrawerInstructions,
    onFormSubmit,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SkipMeeting.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  drawerState: PropTypes.func.isRequired,
  setDrawerState: PropTypes.func.isRequired,
};

SkipMeeting.defaultProps = {};

registerDrawer({
  templateName: skipMeetingTemplate.name,
  component: SkipMeeting,
});

export { View };
export default SkipMeeting;
