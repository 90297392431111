import { createTheme } from '@mui/material/styles';
import { palette } from '~Common/styles/colors';
import { BREAKPOINTS } from '~Common/styles/variables';

const appTheme = createTheme({
  typography: {
    fontFamily: 'ProximaNova',
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        colorPrimary: palette.brand.indigo,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  breakpoints: {
    values: {
      // @ts-expect-error MUI doesn't know how to make Typescript interfaces, this works though
      min: 0,
      mobileTiny: BREAKPOINTS.MAX.TINY,
      mobile: BREAKPOINTS.MAX.MOBILE,
      tablet: BREAKPOINTS.MIN.TABLET,
      desktop: BREAKPOINTS.MIN.DESKTOP,
      // This is something specific for our Masonry Layout component, if we this shows up multiple times, we can move it to a more appropriate place
      desktopLarge: 1400,
    },
  },
  /*
    These are super arbritrary, so far we only use 12px in the Masonry Layout component
    This can be altered if we need to, just make sure you update the places that use this as well, since when you pick spacing in those places, its by the index in this array
  */
  spacing: [0, 4, 8, 12, 16, 32, 64],
});

export default appTheme;
