import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autoCompleteStyles } from '~Common/V3/styles/AutoComplete';
import { css } from '@emotion/react';
import { BaseOption } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import AutocompleteChip, { AutocompleteChipProps } from './AutocompleteChip';

const styles = {
  ...autoCompleteStyles,

  autocompleteTags: css({
    '&[data-shrink=true]': {
      top: '0',
    },
    'label[data-shrink=true]': {
      display: 'none !important',
    },
    'label:not(.MuiFormLabel-filled)': {
      marginTop: '.5rem',

      '& ~ .MuiInputBase-formControl': {
        paddingTop: '1rem !important',
      },
    },
    '& .MuiOutlinedInput-root.MuiInputBase-adornedStart': {
      paddingTop: '2rem !important',
    },
    '& .MuiOutlinedInput-root': {
      height: 'auto',
    },
    '.MuiAutocomplete-inputRoot': {
      overflowY: 'auto',
      maxHeight: '150px',
    },
  }),
};

export interface BaseAutoCompleteTagsProps<T, Multiple extends boolean> extends Omit<AutocompleteProps<T, Multiple, true, false>, 'renderInput'> {
  multiple: Multiple,
  label: string,
  chipProps?: Omit<AutocompleteChipProps, 'label' | 'value' | 'onDelete' | 'tabIndex'>,
}

const BaseAutoCompleteTags = <T extends BaseOption, Multiple extends boolean> ({
  label,
  chipProps,
  ...props
}: BaseAutoCompleteTagsProps<T, Multiple>): JSX.Element => (
  <Autocomplete
    css={[styles.autocomplete, styles.autocompleteTags]}
    limitTags={3}
    disablePortal
    isOptionEqualToValue={(option, value) => option.value === value.value}
    disableCloseOnSelect
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        // Stops users from deleting chips with backspace https://github.com/mui/material-ui/issues/21129
        onKeyDown={(event) => {
          if (event.key === 'Backspace' || event.key === 'Delete') {
            event.stopPropagation();
          }
        }}
      />
    )}
    renderTags={(value, getTagProps) => value.map((option, index) => (
      <AutocompleteChip
        label={option.label}
        value={option.value}
        {...getTagProps({ index })}
        {...chipProps}
      />
    ))}
    {...props}
  />
  );

export default BaseAutoCompleteTags;
