import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const styles = {
  listWrapper: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 0.5rem;
  `,
};

const ListView = ({
  listWrapperStyle, ids, renderItem, data,
}) => (
  // For some reason, having this extra div here, fixes an issue with Safari making cards grow to the entire height of the window
  <div>
    <div css={[styles.listWrapper, listWrapperStyle]}>
      { ids?.map((id, index) => renderItem(id, index, data?.[id])) }
    </div>
  </div>

);

ListView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listWrapperStyle: PropTypes.object,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderItem: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

ListView.defaultProps = {
  listWrapperStyle: {},
  data: {},
};

export default ListView;
