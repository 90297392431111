import { connect } from 'react-redux';

import CardHeader from '~Deprecated/ui/components/Card/CardHeader';
import { getOrganizationById } from '~Deprecated/selectors/organizationInsights/getOrganizationById';

const mapStateToProps = (state, ownProps) => {
  const data = getOrganizationById(state, { id: ownProps.id });

  return {
    name: data.name,
    imgUrl: data.customerLogo,
  };
};

export default connect(mapStateToProps)(CardHeader);
