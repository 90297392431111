import { MouseEvent, useMemo } from 'react';
import DeleteConfirmationPopover,
{ UseDeleteConfirmationPopoverHookReturn, useDeleteConfirmationPopover } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import { TeamUserRowAction, TeamUserRowAllowedAction } from '~People/components/Teams/const/types';
import {
  faArrowRightArrowLeft,
  faTrash,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { palette } from '~Common/styles/colors';
import { css } from '@emotion/react';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import { useTeamUserActions } from '~People/components/Teams/hooks/utils/useTeamUserActions';

const styles = {
  actionMenu: css({
    backgroundColor: palette.neutrals.gray500,
  }),
  overflowMenuButton: css({
    padding: '.3906rem .7594rem',
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    borderRadius: '.3125rem',
  }),
  icon: css({
    height: '1.125rem',
    color: palette.neutrals.gray500,
  }),
  deleteTeam: css({
    color: palette.brand.red,
  }),
};

interface ViewProps extends Omit<UseDeleteConfirmationPopoverHookReturn, 'openConfirmationPopover'> {
  overflowMenuItems: MenuItem[][],
  handleRemoveFromTeamClick: () => void,
}

const View = ({
  overflowMenuItems,
  closeConfirmationPopover,
  isOpen,
  popoverId,
  anchorEl,
  handleRemoveFromTeamClick,
  ...props
}: ViewProps): JSX.Element => (
  <>
    <OverflowMenu
      menuItems={overflowMenuItems}
      renderOverflowMenuButton={(doOpen) => (
        <LeadrButton
          css={styles.overflowMenuButton}
          onClick={doOpen}
          variant="text"
          data-test-id="peopleTeamsActionMenu"
        >
          <FontAwesomeIcon css={styles.icon} icon={faEllipsisVertical} />
        </LeadrButton>
      )}
      {...props}
    />
    <DeleteConfirmationPopover
      closeConfirmationPopover={closeConfirmationPopover}
      open={isOpen}
      popoverId={popoverId}
      anchorEl={anchorEl}
      renderConfirmationButtons={({
        informationStyles,
        optionStyles,
        popoverButtonStyles,
      }) => (
        <DeleteConfirmationButtons
          informationStyles={informationStyles}
          optionStyles={optionStyles}
          popoverButtonStyles={popoverButtonStyles}
          onDelete={handleRemoveFromTeamClick}
        />
      )}
    />
  </>
);

interface ActionMenuProps {
  allowedActions: TeamUserRowAllowedAction[],
  orgUserId: string,
  handleViewUser: () => void,
  teamId: string,
}

const ActionMenu = ({
  allowedActions,
  orgUserId,
  handleViewUser,
  teamId,
  ...props
}: ActionMenuProps): JSX.Element => {
  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('peopleTeamsRemoveMemberConfirmationPopover');

  const {
    changeUsersToOwner,
    changeUsersToMember,
    removeUsersFromTeam,
  } = useTeamUserActions({ teamId });

  const allActions = useMemo(() => ({
    [TeamUserRowAction.ViewUser]: {
      text: 'View User',
      icon: faUser,
      section: 0,
      onClick: () => {
        handleViewUser();
      },
    },
    [TeamUserRowAction.ChangeToMember]: {
      text: 'Change Role to Member',
      icon: faArrowRightArrowLeft,
      section: 0,
      onClick: () => {
        changeUsersToMember([orgUserId]);
      },
    },
    [TeamUserRowAction.ChangeToOwner]: {
      text: 'Change Role to Owner',
      icon: faArrowRightArrowLeft,
      section: 0,
      onClick: () => {
        changeUsersToOwner([orgUserId]);
      },
    },
    [TeamUserRowAction.RemoveFromTeam]: {
      text: 'Remove From Team',
      icon: faTrash,
      section: 1,
      onClick: (event: MouseEvent<HTMLLIElement>) => {
        openConfirmationPopover(event);
      },
      styles: {
        menuText: styles.deleteTeam,
        menuItem: styles.deleteTeam,
        iconColor: 'red',
      },
    },
  }), [changeUsersToMember, changeUsersToOwner, handleViewUser, openConfirmationPopover, orgUserId]);

  const overflowMenuItems: MenuItem[][] = useMemo(() => {
    const items: MenuItem[][] = [];
    allowedActions.forEach((action) => {
      const tempAction = allActions[action.actionName];
      const { section, ...restOfTempAction } = tempAction;
      if (items[section] === undefined) {
        items[section] = [];
      }
      items[section].push({
        ...restOfTempAction,
      });
    });

    return items.filter((section) => section.length > 0);
  }, [allActions, allowedActions]);

  const handleRemoveFromTeamClick = (): void => {
    removeUsersFromTeam([orgUserId]);
  };

  const hookProps = {
    overflowMenuItems,
    anchorEl,
    closeConfirmationPopover,
    isOpen,
    popoverId,
    handleRemoveFromTeamClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionMenu;
