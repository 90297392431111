import { ParticipantTypeEnum, ReviewCycleDetail, ReviewParticipant } from '~Reviews/V2/Const/types';
import { GridSelectionModel } from '@mui/x-data-grid';
import { union } from 'lodash';

function getParticipantsInReviews(cycle: ReviewCycleDetail | undefined, reviewIDs: GridSelectionModel): ReviewParticipant[][] {
  const participants: ReviewParticipant[][] = [];
  reviewIDs.forEach((item) => {
    const reviews = cycle?.reviews.filter((review) => review.uid === item);
    const reviewParticipants = reviews?.flatMap((review) => review.participants);
    const reviewRevieweeParticipants = reviewParticipants?.filter((participant) => participant.participantTypeEnum === ParticipantTypeEnum.Reviewee);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    participants.push(reviewRevieweeParticipants as ReviewParticipant[]);
  });
  return participants;
}

function filterParticipants(reviewParticipants: ReviewParticipant[][]): string[] {
  const filteredParticipantNames: string[] | ReviewParticipant[] = reviewParticipants.reduce((accReviewParticipantNames, curReviews) => {
    const arrayOfParticipantNames = curReviews.map((review) => `${review.firstName} ${review.lastName}`);
    // @ts-expect-error || Not sure how to fix this one - Doesn't seem to recognise the type switch here
    accReviewParticipantNames.push(...arrayOfParticipantNames);
    return accReviewParticipantNames;
  }, []);

  const uniqueFilteredParticipantNames = union(filteredParticipantNames);

  return uniqueFilteredParticipantNames as unknown as string[];
}
export const convertDeletedReviewParticipantsList = (cycle: ReviewCycleDetail | undefined, reviewIDs: GridSelectionModel): string[] => {
  const reviewParticipants = getParticipantsInReviews(cycle, reviewIDs);

  const participants = filterParticipants(reviewParticipants);
  return participants;
};
