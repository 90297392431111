import { css, SerializedStyles } from '@emotion/react';
import {
  faTimes, faLightbulb, faListCheck,
} from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import DrawerOptionSelectItem from '~Learning/components/Shared/DrawerOptionSelectItem/DrawerOptionSelectItem';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import IconButton from '~Meetings/components/buttons/IconButton';
import { AssignLearningOptions } from '~Learning/const/interfaces';
import { learningTypePickerTemplate } from '../LearningTypePickerDrawer';
import { createLearningPlaylistDrawerTemplate } from '../CreateLearningPlaylistDrawer';

const styles = {
  drawerBody: css({}),
  instructions: css({}),
  drawerOptionSelectItem: css({
    ':not(:first-of-type)': {
      marginTop: '.625rem',
    },
  }),
};

export const shareLearningDrawerTemplate = {
  name: 'SHARE_LEARNING_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

const options = [
  {
    content: AssignLearningOptions.CREATE_A_SINGLE_LEARNING_CONTENT,
    icon: faLightbulb,
    dataTestId: 'learningCreateASingleLearningButton',
  },
  {
    content: AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST,
    icon: faListCheck,
    dataTestId: 'learningCreateALearningPlaylistButton',
  },
];

const ShareLearningDrawer = ({ popDrawer, pushDrawer, setDrawerState }: DrawerProps<Record<string, unknown>>): JSX.Element => {
  const dispatch = useDispatch();

  const closeDrawerClick = (): void => {
    dispatch(popDrawer({ drawerName: shareLearningDrawerTemplate.name }));
  };

  const onPathwayClick = useCallback((item: AssignLearningOptions): void => {
    popDrawer({ popAll: true });
    if (item === AssignLearningOptions.CREATE_A_SINGLE_LEARNING_CONTENT) {
      setDrawerState((prev) => ({
        ...prev,
        workflow: AssignLearningOptions.CREATE_A_SINGLE_LEARNING_CONTENT,
      }));
      pushDrawer({
        drawer: learningTypePickerTemplate,
      });
    } else if (item === AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST) {
      setDrawerState((prev) => ({
        ...prev,
        workflow: AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST,
      }));
      pushDrawer({
        drawer: createLearningPlaylistDrawerTemplate,
      });
    }
  }, [popDrawer, pushDrawer, setDrawerState]);

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title="Assign Learning"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
        renderDrawerInstructions={(drawerInstructionsStyles) => (
          <div css={drawerInstructionsStyles}>
            Would you like to start with a Learning Playlist or create a Single Learning?
          </div>
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div
        css={defaultBodyPadding}
      >
        <div>
          {options.map((option) => (
            <DrawerOptionSelectItem
              css={styles.drawerOptionSelectItem}
              onClick={() => onPathwayClick(option.content)}
              icon={option.icon}
              content={option.content}
              data-test-id={option.dataTestId}
            />
          ))}
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: shareLearningDrawerTemplate.name,
  component: ShareLearningDrawer,
});

export default ShareLearningDrawer;
