import moment from 'moment-timezone';

export const convertTimestampToDateTime = (timestamp, timezone = '') => moment.tz(timestamp, timezone || moment.tz.guess()).format('MMM DD @ h:mm A');
export const convertTimestampToDate = (timestamp, timezone = '') => moment.tz(timestamp, timezone || moment.tz.guess()).format('MMM DD');
export const isToday = (timestamp, timezone = '') => moment.tz(timestamp, timezone || moment.tz.guess()).format('MMM/DD/YYYY') === moment.tz(timezone).format('MMM/DD/YYYY');
export const isTomorrow = (timestamp, timezone = '') => moment.tz(timestamp, timezone || moment.tz.guess()).format('MMM/DD/YYYY') === moment.tz(timezone).add(1, 'd').format('MMM/DD/YYYY');
export const convertTimestampToDayOfWeek = (timestamp, timezone = '') => moment.tz(timestamp, timezone || moment.tz.guess()).format('dddd');

export function nearestFutureMinutes(interval, someMoment = moment()) {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().set({
    minutes: roundedMinutes,
    seconds: 0,
    milliseconds: 0,
  });
}
