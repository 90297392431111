import { Fragment } from 'react';
import { css } from '@emotion/react';
import './styles.scss';
import ContentListItem from './ContentListItem';
import Player from '../../../Player';
import { palette } from '~Common/styles/colors';

const styles = {
  sectionTitle: css({
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: '0.5rem',
    marginLeft: '1.5rem',
    color: palette.neutrals.gray800,
  }),
};

// TODO: Rewrite this with new UI in meetings
/**
 * @deprecated Old UI
 */
const ContentList = (props) => {
  const { items } = props;
  const formatContentStatus = (item) => {
    // filter questions only for user with id user.uid
    const statusArray = [];
    item.questions && item.questions.forEach((question) => {
      const questionElement = { ...question };
      if (questionElement.response && questionElement.response.length) {
        statusArray.push(true);
      } else {
        statusArray.push(false);
      }
    });
    const format = statusArray.filter((element) => element === false);
    return !format.length;
  };

  const renderContent = (contentData) => {
    const { link, title } = contentData;
    if (link) {
      return (
        <>
          <div css={styles.sectionTitle}>
            {title}
          </div>
          <div className="content-media">
            <div className="media-main">
              <Player
                link={link}
                type="content"
                title={title}
                layout={false}
              />
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  return items && items.map((item, index) => (
    <div className="content-list-wrapper" key={index}>
      <div className="">
        { renderContent(item) }
        { formatContentStatus(item) && <div className="pt-3 pl-3 pb-3">Application Question</div> }
        { formatContentStatus(item) && item.questions && item.questions.map((itemObj, questionindex) => (
          <Fragment key={questionindex}>
            <ContentListItem
              key={questionindex}
              queAnsData={itemObj}
              ownerInfo={item.creator}

            />
          </Fragment>
        )) }

      </div>
    </div>
  ));
};
export default ContentList;
