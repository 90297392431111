import { css } from '@emotion/react';
import { FormControl } from '@mui/material';
import { range } from 'lodash';
import { Fragment } from 'react';
import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';

const styles = {
  root: css({
    width: '100%',
  }),
  label: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray300,
    borderRadius: '0.5rem',
    color: palette.neutrals.gray700,
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    fontSize: '.875rem',
    fontWeight: '400',
    justifyContent: 'center',
    margin: '0',
    minHeight: '2.75rem',
    padding: '0.5rem',
    textAlign: 'center',
  }, forMobileObject({
    padding: '0.25rem',
  })),
  active: css({
    backgroundColor: `${palette.brand.sky} !important`,
    color: palette.neutrals.white,
  }),
  optionWrap: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: '.5rem',
    width: '100%',
  }, forMobileObject({
    gap: '0.25rem',
  })),
  option: css({
    flex: '1',
  }),
  optionsValueLabels: css({
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '.625rem',

    '& span': {
      width: '33%',
      color: palette.neutrals.gray500,
      fontSize: '.75rem',

      '&:last-of-type': {
        textAlign: 'right',
      },
    },
  }),
};

interface ReadOnlyLinearScaleProps {
  minLabel: string,
  maxLabel: string,
  maxValue: number,
  value?: number,
}

export const ReadOnlyLinearScale = ({
  minLabel,
  maxLabel,
  maxValue,
  value,
}: ReadOnlyLinearScaleProps): JSX.Element => (
  <FormControl css={styles.root}>
    <div css={styles.optionWrap}>
      {range(1, Number(maxValue) + 1).map((thisValue) => (
        <Fragment key={thisValue}>
          <div
            css={[styles.label, thisValue === value && styles.active]}
          >
            {thisValue}
          </div>
        </Fragment>
      ))}
    </div>
    <div css={styles.optionsValueLabels}>
      <span>{minLabel}</span>
      <span>{maxLabel}</span>
    </div>
  </FormControl>
);
