import { connect } from 'react-redux';

import InsightDetailRow from '~Deprecated/ui/components/Insights/InsightDetails/InsightDetailRow';
import { getMemberById } from '~Deprecated/selectors/organizationMembers/getMemberById';

const mapStateToProps = (state, { id }) => {
  const member = getMemberById(state, { id }) || {};

  return {
    id,
    name: `${member.firstName} ${member.lastName}`,
    managerId: member.manager && member.manager.orgUserId,
    imgUrl: member.profileImageUrl,
    metricHealthReport: member.report,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(InsightDetailRow);
