// Composed of 2 TimeDropdownPickers
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { css } from '@emotion/react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { drawerInputBorder, pallette } from '~Deprecated/ui/styles/colors';
import { drawerInputBackground } from '~Common/styles/colors';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';

const styles = {
  container: css`
    background: ${drawerInputBackground};
    border: 1px solid ${drawerInputBorder};
    padding: 7px 16px;
    border-radius: 8px;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    align-items: center;
    letter-spacing: 0.5px;

    label {
      color: ${pallette.gray7};
      margin-bottom: 0;
    }
  `,
  pickerContainer: css`
    display: flex;
    align-items: center;
    margin-left: -3px;
    margin-right: -20px;
    width: 100%;

    @media only screen and (max-width: 450px) {
      margin: unset;
    }
  `,
  timepicker: css`
    width: 46%;
    margin: 0 1%;

    @media only screen and (max-width: 450px) {
      flex: 1;
      width: 100%;
      margin: unset;
    }
  `,
  timeSeparator: css`
    @media only screen and (max-width: 450px) {
      margin-inline: 0.5rem;
    }
  `,
  selectStyle: css`
    border: unset !important;
    background: white;

    .MuiSelect-select.MuiSelect-select {
      font-size: 48px !important
    }

    &:hover {
      background-color: #00000014;
    }
  `,
};

const View = ({
  renderStartTime,
  renderEndTime,
  startTime,
  endTime,
  startTimeItems,
  getEndTimeItems,
  onStartTimeChange,
  onEndTimeChange,
  className,
  label,
}) => (
  <div
    className={className}
    css={styles.container}
  >
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
    <label>{ label }</label>
    <div
      css={styles.pickerContainer}
    >
      <div
        css={styles.timepicker}
      >
        { renderStartTime({
          css: styles.selectStyle,
          value: startTime,
          items: startTimeItems,
          onChange: onStartTimeChange,
          MenuProps: {
            sx: {
              maxHeight: '50%',
            },
          },
        }) }
      </div>

      <span css={styles.timeSeparator}>
        -
      </span>

      <div css={styles.timepicker}>
        {renderEndTime({
          css: styles.selectStyle,
          value: endTime,
          getItems: getEndTimeItems,
          onChange: onEndTimeChange,
          MenuProps: {
            sx: {
              maxHeight: '50%',
            },
          },
        })}
      </div>
    </div>
  </div>
);

View.propTypes = {
  renderStartTime: PropTypes.func.isRequired,
  renderEndTime: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  startTimeItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  getEndTimeItems: PropTypes.func.isRequired,
  onStartTimeChange: PropTypes.func.isRequired,
  onEndTimeChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

View.defaultProps = {
  className: '',
};

const newTimeArray = [
  '8:00 am', '8:15 am', '8:30 am', '8:45 am',
  '9:00 am', '9:15 am', '9:30 am', '9:45 am',
  '10:00 am', '10:15 am', '10:30 am', '10:45 am',
  '11:00 am', '11:15 am', '11:30 am', '11:45 am',
  '12:00 pm', '12:15 pm', '12:30 pm', '12:45 pm',
  '1:00 pm', '1:15 pm', '1:30 pm', '1:45 pm',
  '2:00 pm', '2:15 pm', '2:30 pm', '2:45 pm',
  '3:00 pm', '3:15 pm', '3:30 pm', '3:45 pm',
  '4:00 pm', '4:15 pm', '4:30 pm', '4:45 pm',
  '5:00 pm', '5:15 pm', '5:30 pm', '5:45 pm',
  '6:00 pm', '6:15 pm', '6:30 pm', '6:45 pm',
  '7:00 pm', '7:15 pm', '7:30 pm', '7:45 pm',
  '8:00 pm', '8:15 pm', '8:30 pm', '8:45 pm',
  '9:00 pm', '9:15 pm', '9:30 pm', '9:45 pm',
  '10:00 pm', '10:15 pm', '10:30 pm', '10:45 pm',
  '11:00 pm', '11:15 pm', '11:30 pm', '11:45 pm',
  '12:00 am', '12:15 am', '12:30 am', '12:45 am',
  '1:00 am', '1:15 am', '1:30 am', '1:45 am',
  '2:00 am', '2:15 am', '2:30 am', '2:45 am',
  '3:00 am', '3:15 am', '3:30 am', '3:45 am',
  '4:00 am', '4:15 am', '4:30 am', '4:45 am',
  '5:00 am', '5:15 am', '5:30 am', '5:45 am',
  '6:00 am', '6:15 am', '6:30 am', '6:45 am',
  '7:00 am', '7:15 am', '7:30 am', '7:45 am',
];

const useTimeItems = (date, startTime, isEndTime) => {
  const { timezone } = useTimezone();

  // Prevent 0 minute meetings by adjusting startTime ahead 15 minutes
  const adjustedStartTime = useMemo(() => moment.tz(startTime + (1000 * 60 * 15), timezone), [startTime, timezone]);

  // "date" in this context is a vanilla JS Date object coming from the calendar date picker.
  // Vanilla Date objects cannot carry timezone information
  // Need to contextualize thisDate object to the user's timezone to ensure the time options
  // are generated for the correct day
  const dateString = useMemo(() => moment.tz(date, timezone).format('ddd MMM DD YYYY'), [date, timezone]);

  const buildTimeItems = useCallback((showDurations) => newTimeArray.map((time) => {
    // Take the start time's date and inject the dropdown time into it, giving us a valid moment date
    const currentTimeWithItem = moment.tz(`${dateString} ${time}`, ['ddd MMM DD YYYY h:mm a'], timezone);

    let timeInMillis = currentTimeWithItem.valueOf();
    const meetingDuration = timeInMillis - startTime;
    let meetingDurInMin = Math.floor(meetingDuration / 60000);

    // Next, we check if this new time is before or after startTime. Before means we need to add a day because it wraps around.
    if (isEndTime && currentTimeWithItem.isBefore(adjustedStartTime)) {
      timeInMillis += (1000 * 60 * 60 * 24);
      meetingDurInMin += (24 * 60);
    }

    let durationText;

    if (meetingDurInMin < 60) {
      durationText = `(${meetingDurInMin} minutes)`;
    } else {
      const hours = Math.floor(meetingDurInMin / 60);
      const minutes = meetingDurInMin % 60;
      durationText = `(${hours} hour${hours > 1 ? 's' : ''}${minutes > 0 ? ` ${minutes} minutes` : ''})`;
    }

    if (isEndTime && showDurations) {
      return {
        value: timeInMillis,
        text: `${time} ${durationText}`,
      };
    }
    return {
      value: timeInMillis,
      text: `${time}`,
    };
  }).sort((a, b) => a.value - b.value), [dateString, startTime, isEndTime, adjustedStartTime, timezone]);

  return buildTimeItems;
};

const TimePicker = ({ initialDate, defaultDuration, ...props }) => {
  // Track initialDate with useState so we can properly react to date changes
  const [date, setDate] = useState(initialDate);

  const [startTime, setStartTime] = useState(moment(initialDate).valueOf());
  const [endTime, setEndTime] = useState(moment(initialDate).valueOf() + (defaultDuration));
  const [duration, setDuration] = useState(defaultDuration);

  const previousStartTime = usePrevious(startTime);
  const previousEndTime = usePrevious(endTime);
  const previousInitialDate = usePrevious(initialDate);
  const previousDuration = usePrevious(defaultDuration);

  const startTimeItems = useTimeItems(date, startTime, false)();
  const getEndTimeItems = useTimeItems(date, startTime, true);

  useEffect(() => {
    if (previousDuration && previousDuration !== defaultDuration) {
      setDuration(defaultDuration);
      setEndTime(startTime + defaultDuration);
    }
  }, [previousDuration, defaultDuration, startTime]);

  // when we change end time, update duration for easier start time calculations
  useEffect(() => {
    if (previousEndTime && endTime !== previousEndTime) {
      setDuration(endTime - startTime);
    }
  }, [endTime, startTime, previousEndTime]);

  // When we change start time, update end time to keep duration
  useEffect(() => {
    if (previousStartTime && startTime !== previousStartTime) {
      setEndTime(startTime + duration);
    }
  }, [startTime, duration, previousStartTime]);

  // If the date changes, we need to update all our menu item values with the new date, but keep the same relative time values for start/end based on duration
  // TODO: We need to check if the date changed, and if so transition startTime and endTime along with startTimeItems and getEndTimeItems to keep the relative time/duration the same
  useEffect(() => {
    if (previousInitialDate && initialDate !== previousInitialDate) {
      const startTimeMillies = startTime - new Date(startTime).setHours(0, 0, 0, 0);
      const endTimeMillies = endTime - new Date(endTime).setHours(0, 0, 0, 0);
      setDate(initialDate);

      // setHours is mutative. Ensure we don't call it directly on initialDate or we'll lose the attached time
      // which can cause issues with timezone contextualization.
      setStartTime(new Date(initialDate).setHours(0, 0, 0, 0) + startTimeMillies);
      setEndTime(new Date(initialDate).setHours(0, 0, 0, 0) + endTimeMillies);
    }
  }, [previousInitialDate, initialDate, setStartTime, setEndTime, duration, startTime, endTime]);

  const onStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const onEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const hookProps = {
    startTime,
    endTime,
    startTimeItems,
    getEndTimeItems,
    duration,
    onStartTimeChange,
    onEndTimeChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

TimePicker.propTypes = {
  initialDate: PropTypes.instanceOf(Date).isRequired,
  defaultDuration: PropTypes.number,
};

TimePicker.defaultProps = {
  defaultDuration: 1000 * 60 * 30,
};

export { View };
export default TimePicker;
