import { css } from '@emotion/react';
import { faFileExport, faPrint } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import {
  MENU_ITEMS_SHAPE, SURVEY_QUESTIONS_AND_ANSWER_SHAPE, SURVEY_QUESTION_SHAPE, SURVEY_QUESTION_TYPES_SHAPE, SURVEY_SHAPE,
} from '~Common/const/proptypes';
import { NO_RESPONSE_TEXT } from '~Common/const/surveys';
import { freeformCardGray } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import { usePrintView } from '~Deprecated/hooks/usePrintView';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { buttonDefaultColor } from '~Deprecated/ui/styles/colors';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { useGetSurveyCSV } from '../../../Hooks/useSurveysList';
import FreeformAnswerCard from '../../Cards/FreeformAnswerCard';

import SurveyDetailsCard from '../../Cards/SurveyDetailsCard';
import ViewSurveyQuestionCard from '../../Cards/ViewSurveyQuestionCard';
import MultipleChoiceBarChart from '../../Charts/MultipleChoiceBarChart';
import RatingBarChart from '../../Charts/RankingBarChart';
import SurveyActionMenu from './SurveyActionMenu';

const styles = {
  card: css`
    margin-top: 1.125rem;
  `,
  cardBackground: (hasDarkBackground) => css`
    &:not(:last-child) {
      margin-bottom: .625rem;
    }

    ${hasDarkBackground && `
      background: ${freeformCardGray};
    `}
  `,
  freeformCard: css`
    display: flex;
  `,
  freeformInfo: css`
    margin-left: .875rem;
  `,
  freeformAnswer: css``,
  freeformName: css`
    margin-top: 1rem;
    ${withTruncate()}
  `,
  printButton: css`
    color: ${buttonDefaultColor};
    display: flex;
    align-items: center;
    font-weight: bold;
  `,
  printIcon: css`
    margin-right: .5rem;
  `,
};

const View = ({
  survey, questions, isLoading, questionTypes, byQuestions, menuItems, ...props
}) => (
  <div {...props}>
    {!isLoading && (
      <>
        <SurveyDetailsCard
          survey={survey}
          renderContextMenu={() => (
            <SurveyActionMenu menuItems={menuItems} />
          )}
        />
        {byQuestions.map((byQuestion) => (
          <ViewSurveyQuestionCard
            css={styles.card}
            question={byQuestion.question}
            renderResponseContent={() => (
              <>
                {byQuestion.question.type.key === questionTypes.MultipleChoice.id && (
                  <MultipleChoiceBarChart question={byQuestion.question} answers={byQuestion.participantsAndAnswers} />
                )}
                {byQuestion.question.type.key === questionTypes.CustomScale.id && (
                  <RatingBarChart question={byQuestion.question} answers={byQuestion.participantsAndAnswers} />
                )}
                {byQuestion.question.type.key === questionTypes.ShortAnswer.id && (
                <>
                  {byQuestion.participantsAndAnswers.map((participantAndAnswer, index) => (
                    <FreeformAnswerCard
                      css={styles.cardBackground(index % 2 === 0)}
                      renderBody={() => (
                        <div css={styles.freeformCard}>
                          <SquareAvatar
                            imgUrl={participantAndAnswer.participant.participant.profileImageUrl}
                            name={participantAndAnswer.participant.participant.fullName}
                            width={32}
                            height={32}
                            includeInitials
                          />
                          <div css={styles.freeformInfo}>
                            <div css={styles.freeformAnswer}>
                              {participantAndAnswer.answer?.answer && (
                                <HTMLRenderer htmlText={participantAndAnswer.answer.answer} />
                              )}
                              {!participantAndAnswer.answer?.answer && (
                                <div>{NO_RESPONSE_TEXT}</div>
                              )}
                            </div>
                            <div css={styles.freeformName}>{participantAndAnswer.participant.participant.fullName}</div>
                          </div>
                        </div>
                      )}
                    />
                  ))}
                </>
                )}
              </>
            )}
          />
        ))}
      </>
    )}
  </div>
);

View.propTypes = {
  survey: SURVEY_SHAPE,
  questions: PropTypes.arrayOf(SURVEY_QUESTION_SHAPE).isRequired,
  isLoading: PropTypes.bool.isRequired,
  questionTypes: SURVEY_QUESTION_TYPES_SHAPE.isRequired,
  byQuestions: SURVEY_QUESTIONS_AND_ANSWER_SHAPE.isRequired,
  menuItems: MENU_ITEMS_SHAPE.isRequired,
};

View.defaultProps = {
  survey: {},
};

const ByQuestion = ({ survey, ...props }) => {
  const { onPrint } = usePrintView();
  const getSurevyCSV = useGetSurveyCSV();

  const menuItems = [[{
    text: 'Print',
    icon: faPrint,
    onClick: () => {
      onPrint({ location: `surveys/${survey.id}/byQuestion` });
    },
  },
  {
    text: 'Export',
    icon: faFileExport,
    onClick: () => {
      getSurevyCSV({
        surveyId: survey.id,
        byQuestion: true,
        byParticipant: false,
        withNullAnswers: true,
      });
    },
  },
  ]];

  const {
    questions,
    isLoading,
    questionTypes,
    byQuestions,
  } = survey;
  const hookProps = {
    survey,
    menuItems,
    questions,
    isLoading,
    questionTypes,
    byQuestions,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ByQuestion.propTypes = {
  survey: SURVEY_SHAPE,
};

ByQuestion.defaultProps = {
  survey: {},
};

export { View };
export default ByQuestion;
