import { css } from '@emotion/react';
import EmptyStateWithImage, { EmptyStateWithImageProps } from '~Common/components/EmptyStates/EmptyStateWithImage';
import emptySearch from '~Assets/images/empty-views/emptySearch.png';

const styles = {
  emptyStateImage: css({
    width: '7.5rem',
  }),
};

type EmptySearchProps = Omit<EmptyStateWithImageProps, 'renderImage'>

const EmptySearch = ({
  ...props
}: EmptySearchProps): JSX.Element => (
  <EmptyStateWithImage
    renderImage={() => (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      <img css={styles.emptyStateImage} src={emptySearch} alt="No search results" data-test-id="commonNoSearchResults" />
    )}
    {...props}
  />
);

export default EmptySearch;
