import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { noop } from '~Deprecated/utils';
import BaseBody from './BaseBody';
import DrawerActionHeader from '../DrawerActionHeader';

const style = css`
  width: 100%;

  & .drawerBodyWrapper {
    width: 100%;

    & .drawerBodyHeaderWrapper {
      width: 100%;
    }
  
    & .drawerBodyListWrapper {
      width: 100%;
      margin-top: 40px;
    }
  }
`;

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
const HeaderAndListDrawer = ({
  onClose, onAction, renderHeader, renderList, actionText, header,
}) => (
  <BaseBody
    css={style}
    header={header}
    onClose={onClose}
    renderHeader={({ ...baseHeaderProps }) => (
      <DrawerActionHeader
        {...baseHeaderProps}
        actionText={actionText}
        cancelText="close"
        onAction={onAction}
      />
    )}
    renderBody={() => (
      <div
        className="drawerBodyWrapper"
      >
        <div
          className="drawerBodyHeaderWrapper"
        >
          {renderHeader({})}
        </div>
        <div
          className="drawerBodyListWrapper"
        >
          {renderList({})}
        </div>
      </div>
    )}
  />
);

HeaderAndListDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  header: PropTypes.string,
  actionText: PropTypes.string,
  renderHeader: PropTypes.func.isRequired,
  renderList: PropTypes.func.isRequired,
};

HeaderAndListDrawer.defaultProps = {
  header: 'Customer Name',
  actionText: '',
  onAction: noop,
};

export default HeaderAndListDrawer;
