import { css } from '@emotion/react';
import { hexToRGBA, palette } from '~Common/styles/colors';

interface SpinnerProps {
  backgroundColor?: string,
  foregroundColor?: string,
  lineSize?: string,
  size?: string,
}

const styles = {
  spinner: ({
    foregroundColor = palette.neutrals.white,
    backgroundColor = hexToRGBA(foregroundColor, 0.2),
    lineSize = '0.1rem',
    size = '1rem',
  }: SpinnerProps) => css({
    borderRadius: '50%',
    width: size,
    height: size,

    '&:after': {
      borderRadius: '50%',
      width: size,
      height: size,
    },

    border: `${lineSize} solid ${backgroundColor}`,
    borderLeft: `${lineSize} solid ${foregroundColor}`,
    transform: 'translateZ(0)',
    animation: 'spin 1.1s infinite linear',

    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
};

export const Spinner = ({
  foregroundColor,
  backgroundColor,
  lineSize,
  size,
  ...props
}: SpinnerProps): JSX.Element => (
  <div
    css={styles.spinner({
      foregroundColor,
      backgroundColor,
      lineSize,
      size,
    })}
    {...props}
  />
);
