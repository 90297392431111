import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { SURVEY_PARTICIPANT_SHAPE, SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';
import { barChartColors, surveyQuestionTypeText } from '~Common/styles/colors';
import { BarChart } from '~Common/components/Charts/BarChart';
import { withLineClamp } from '~Common/styles/mixins';
import Tooltip from '~Common/components/Tooltip';

const styles = {
  labelsContainer: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
    margin-left: 50px;
    row-gap: 10px;
    margin-top: 1.25rem;
  `,
  labelContainer: css`
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 15px;
    }
  `,
  colorSquare: (color) => css`
    background-color: ${color};
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    flex-shrink: 0;
  `,
  label: css`
    ${withLineClamp(2)};
    font-size: 0.75rem;
  `,
  type: css`
    text-align: center;
    margin-top: .625rem;
    color: ${surveyQuestionTypeText};
    font-weight:600;
    font-size: .875rem;
  `,
};

const View = ({ labels, ...props }) => (
  <div>
    <BarChart {...props} />
    <div css={styles.labelsContainer}>
      {labels.map((label, index) => (
        <div css={styles.labelContainer}>
          <Tooltip content={label}>
            <span css={styles.colorSquare(barChartColors[index])} />
          </Tooltip>
          <span css={styles.label}>{label}</span>
        </div>
      ))}
    </div>
    <div css={styles.type}>
      Multiple Choice
    </div>
  </div>
);

View.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
};

View.defaultProps = {
  labels: [],
};

const MultipleChoiceBarChart = ({ question, answers, ...props }) => {
  const multipleChoiceOptions = JSON.parse(question.configuration);
  const labels = multipleChoiceOptions.map((option) => option.option);

  const data = labels.map((label, index) => answers?.filter((answer) => answer.answer?.answer === index.toString()).length);

  const hookProps = {
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: barChartColors,
        },
      ],
    },
    options: {
      scales: {
        x: {
          ticks: {
            display: false,
          },
        },
      },
    },
    labels,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

MultipleChoiceBarChart.propTypes = {
  question: SURVEY_QUESTION_SHAPE.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.shape({
      answer: PropTypes.string,
      id: PropTypes.number,
      participantId: PropTypes.number,
      questionId: PropTypes.number,
    }),
    participant: SURVEY_PARTICIPANT_SHAPE,
  })),
};

MultipleChoiceBarChart.defaultProps = {
  answers: [],
};

export { View };
export default MultipleChoiceBarChart;
