import {
  getApi, postApi,
} from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';

// feedback - send response to for me tabs feedback
export const sendResponseToFeedbackQuestionsService = async (requestBody) => {
  const { questionId, answer } = requestBody;
  // Send response to questions in parallel.
  const serverUrl = {
    host: hosts.questions,
    uri: `/organizations/${getOrganizationId()}/questions/${questionId}/responses`,
  };
  return postApi(serverUrl, answer, {});
};

// feedback - getFeedbackDetailsById
export const getFeedbackDetailsByIdDataService = async (requestBody) => {
  // requestBody.assigneeId = '0x41ec5';
  const { id, assigneeId } = requestBody;
  const serverUrl = {
    host: hosts.feedback,
    uri: `/organizations/${getOrganizationId()}/feedbacks/${id}/assignee/${assigneeId}`,
  };
  return getApi(serverUrl, {}, {});
};
