import { css } from '@emotion/react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  completedProgressBar: css({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    height: '.9375rem',
  }),
  progressBar: css({
    height: '100%',
    flexGrow: 1,
    borderRadius: '0.5rem',
    maxWidth: '130px',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: palette.neutrals.gray50,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: palette.brand.indigo,
    },
  }),
  percentText: css({
    color: palette.neutrals.gray800,
    fontWeight: 500,
    marginLeft: '.4375rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  value: number,
  renderPercentText: (value: number) => JSX.Element,
}

const View = ({
  value,
  renderPercentText,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.completedProgressBar} {...props}>
    <LinearProgress
      css={styles.progressBar}
      variant="determinate"
      value={value}
    />
    <div css={styles.percentText}>
      {renderPercentText(value)}
    </div>
  </div>
);

interface CompletedProgressBarProps extends Omit<ViewProps, 'value'> {
  totalCount?: number,
  completedCount?: number,
  completedPercentage?: number,
}

const CompletedProgressBar = ({
  totalCount,
  completedCount,
  completedPercentage,
  ...props
}: CompletedProgressBarProps): JSX.Element => {
  let value = 0;
  if (completedPercentage !== undefined) {
    value = completedPercentage;
  } else if (totalCount !== undefined && completedCount !== undefined) {
    value = Math.round((completedCount / totalCount) * 100);
  }

  const hookProps = {
    value,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default CompletedProgressBar;
