import LeadrModal from '~Common/V3/components/LeadrModal';
import { EditPostShape, InfiniteQueryParamsProps, RecognitionShape } from '~Recognition/const/types';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { useGetRecognitionById } from '~Recognition/hooks/useGetRecognitionById';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { DEFAULT_RECOGNITION, DEFAULT_EDIT_POST } from '~Recognition/const/defaults';
import { Form } from '~Common/V3/components/uncontrolled';
import {
  createRecognitionFormResolver,
  FormValues,
  conformToDto,
} from '~Recognition/schemata/CreateRecognitionSchemata';
import {
  useForm,
  UseFormReturn,
  SubmitHandler,
  useWatch,
} from 'react-hook-form';
import { useState } from 'react';
import { useCreateRecognition } from '~Recognition/hooks/useCreateRecognition';
import { useEditRecognition } from '~Recognition/hooks/useEditRecognition';
import { useDeleteRecognition } from '~Recognition/hooks/useDeleteRecognition';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { ModalCloseReason } from '~Common/const/ModalTypes';
import CreateRecognitionForm from './CreateRecognitionForm';

const styles = {
  title: css({
    color: palette.brand.indigo,
  }),
  modalDescription: css({
    color: palette.neutrals.gray700,
    marginBottom: '1.5rem',
    fontWeight: 400,
  }),
  secondaryTitle: css({
    color: palette.brand.indigo,
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '.5rem',

    ':after': {
      content: '"Max: 25"',
      color: palette.neutrals.gray700,
      float: 'right',
      fontSize: '.875rem',
      fontWeight: 400,
    },
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
  }),
  deleteButton: css({
    marginLeft: 'auto',
  }),
  cardSkeleton: css({
    minWidth: '100%',
    height: '4.5rem',
    marginBottom: '.625rem',
    ':last-of-type': {
      height: '12rem',
    },
  }),
  buttonSkeleton: css({
    width: '9.375rem',
    height: '2.5rem',
    marginTop: '1.25rem',
  }),
};

interface ViewProps {
  open: boolean,
  isEditing: boolean,
  editRecognitionDetails: RecognitionShape | undefined,
  canDelete: boolean,
  formContext: UseFormReturn<FormValues>,
  onFormSubmit: SubmitHandler<FormValues>,
  handleDelete: () => void,
  disableSubmittion: boolean,
  recipientsSelected: (string | undefined)[] | undefined,
  showSkeletonLoaders: boolean,
  handleCloseModal: (_: unknown, reason?: ModalCloseReason) => void,
}

const View = ({
  open,
  isEditing,
  editRecognitionDetails,
  canDelete,
  formContext,
  onFormSubmit,
  handleDelete,
  disableSubmittion,
  recipientsSelected,
  showSkeletonLoaders,
  handleCloseModal,
}: ViewProps): JSX.Element => (
  <Form
    formContext={formContext}
    onSubmit={() => null}
  >
    <LeadrModal
      open={open}
      // @ts-expect-error No TS, it is you who is wrong
      onClose={handleCloseModal}
    >
      <LeadrModal.Header>
        <LeadrModal.Title css={styles.title}>
          {isEditing && (
            <>Edit Recognition</>
          )}
          {!isEditing && (
            <>New Recognition</>
          )}
        </LeadrModal.Title>
      </LeadrModal.Header>
      {showSkeletonLoaders && (
        <>
          <MultipleSkeletonLoaders
            numberOfSkeletons={2}
            renderSkeletonItem={() => (
              <CardSkeleton css={styles.cardSkeleton} />
            )}
          />
          <MultipleSkeletonLoaders
            numberOfSkeletons={1}
            renderSkeletonItem={() => (
              <CardSkeleton css={styles.buttonSkeleton} />
            )}
          />
        </>
      )}
      {!showSkeletonLoaders && (
      <>
        <LeadrModal.Body>
          <p css={styles.modalDescription}>Recognize your teammates for their good work!</p>
          <h2 css={styles.secondaryTitle}>Who would you like to recognize?</h2>
          <CreateRecognitionForm
            editRecognitionDetails={editRecognitionDetails}
            formContext={formContext}
            recipientsSelected={recipientsSelected}
          />
        </LeadrModal.Body>
        <LeadrModal.Footer css={styles.footer}>
          {isEditing && (
            <>
              <LeadrButton
                data-test-id="saveRecognition"
                onClick={formContext.handleSubmit(onFormSubmit)}
                disabled={!formContext.formState.isDirty}
                size="small"
              >
                Save
              </LeadrButton>
              <LeadrButton
                variant="ghost"
                data-test-id="cancelRecognition"
                onClick={handleCloseModal}
                size="small"
              >
                Cancel
              </LeadrButton>
              {canDelete && (
                <LeadrButton
                  css={styles.deleteButton}
                  variant="ghost"
                  color="danger"
                  data-test-id="deleteRecognition"
                  onClick={handleDelete}
                  size="small"
                >
                  Delete Post
                </LeadrButton>
              )}
            </>
          )}
          {!isEditing && (
          <LeadrButton
            disabled={disableSubmittion}
            data-test-id="createRecognition"
            onClick={formContext.handleSubmit(onFormSubmit)}
            size="small"
          >
            Share Recognition
          </LeadrButton>
          )}
        </LeadrModal.Footer>
      </>
      )}
    </LeadrModal>
  </Form>
);

interface CreateRecognitionModalProps {
  editPost: EditPostShape,
  infiniteQueryParams: InfiniteQueryParamsProps,
  handleModalDisplay: (open: boolean) => void,
  setEditPost: (editPost: EditPostShape) => void,
  open: boolean,
}

const CreateRecognitionModal = ({
  editPost,
  infiniteQueryParams,
  handleModalDisplay,
  setEditPost,
  ...props
}: CreateRecognitionModalProps): JSX.Element => {
  const { data: editRecognitionDetails, isLoading } = useGetRecognitionById({ id: editPost.postId });
  const { canEdit, canDelete } = editRecognitionDetails ?? { canEdit: false, canDelete: false };
  const isEditing = editPost.editPost && canEdit;

  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);

  function defaultValues(): FormValues {
    const {
      content,
      recipients,
    } = editRecognitionDetails ?? DEFAULT_RECOGNITION;

    const selectedRecipientIds = editRecognitionDetails?.recipients.map((person) => person.orgUserId);
    return {
      content,
      // @ts-expect-error not sure whats going on here
      recipients: selectedRecipientIds || recipients,
    };
  }

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const formContext = useForm<FormValues>({
    defaultValues: defaultValues(),
    resolver: createRecognitionFormResolver,
  });

  const deleteMutation = useDeleteRecognition({ infiniteQueryParams });

  const handleDelete = (): void => {
    const id = editRecognitionDetails?.recognitionId;
    deleteMutation({ recognitionId: id || 0 }, { onSettled: () => handleModalDisplay(false) });
    setEditPost(DEFAULT_EDIT_POST);
  };

  const handleCloseModal = (_: unknown, reason?: ModalCloseReason): void => {
    if (reason === ModalCloseReason.Backdrop_Click || reason === ModalCloseReason.Escape_Key) {
      return;
    }
    handleEditReset();
  };

  const handleEditReset = (): void => {
    handleModalDisplay(false);
    setEditPost(DEFAULT_EDIT_POST);
    setIsFormSubmitting(false);
  };

  const handleCreateReset = (): void => {
    handleModalDisplay(false);
    setIsFormSubmitting(false);
  };

  const { mutate: createRecognitionMutation } = useCreateRecognition({ infiniteQueryParams });
  const { mutate: editRecognitionMutation } = useEditRecognition({ infiniteQueryParams });

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    setIsFormSubmitting(true);
    const formData = conformToDto(data);
    if (!isEditing) {
      createRecognitionMutation({ recognition: formData }, { onSettled: handleCreateReset });
    } else {
      // This is a fallback if the user removes all recipients
      if (formData.recipients.length === 0 && editRecognitionDetails) {
        editRecognitionDetails.recipients.map((person) => formData.recipients.push(person.orgUserId));
      }

      editRecognitionMutation(
        { recognition: formData, id: editRecognitionDetails?.recognitionId || 0 },
        { onSettled: handleEditReset },
      );
    }
  };

  const watchedData = useWatch({
    control: formContext.control,
  });

  const { recipients: recipientsSelected } = watchedData;

  const hasRecipients = recipientsSelected ? recipientsSelected?.length > 0 : false;
  const disableSubmittion = !(hasRecipients && formContext.formState.isDirty && !isFormSubmitting);

  const hookProps = {
    isEditing,
    editRecognitionDetails,
    editPost,
    canDelete,
    onFormSubmit,
    formContext,
    handleDelete,
    handleEditReset,
    disableSubmittion,
    recipientsSelected,
    showSkeletonLoaders,
    handleCloseModal,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default CreateRecognitionModal;
