import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { ReactText, useRef } from 'react';
import { queryClient } from '~Common/const/queryClient';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { ResponseDataShape } from '../Const/types';

interface finishImportProps {
  data: ResponseDataShape[],
}

const finishImport = ({ data }: finishImportProps): Promise<HttpCallReturn<void>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/users/import`;
  return postApi(URL, { data }, {});
};

const scheduleImportJob = ({ data }: finishImportProps): Promise<HttpCallReturn<void>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/users/scheduleImport`;
  return postApi(URL, { data }, {});
};

interface errorShape {
  message: string,
  messageKey: string,
  success: boolean,
}

export const useFinishImport = (): UseMutationResult<HttpCallReturn<unknown>, unknown, {data: ResponseDataShape[];}, void> => {
  const userImportBackgroundJob = useFeatureFlag('userImportBackgroundJob');
  const toastId = useRef<ReactText | number | null>(null);

  const importAction = userImportBackgroundJob ? scheduleImportJob : finishImport;

  const mutation = useMutation({
    mutationFn: importAction,
    onMutate: () => {
      toastId.current = toast.info('Uploading the import data...', { autoClose: false });
    },
    onError: (error: errorShape) => {
      if (error.message) {
        toast.update(toastId.current, {
          render: error.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: 'There was an error uploading the import data. Please try again.',
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });

      queryClient.setQueryData<HttpCallReturn<boolean>>([getOrganizationId(), 'importProcessing'], (status) => {
        if (status) {
          const newStatus = cloneDeep(status);

          if (newStatus) {
            newStatus.response = true;
          }

          return newStatus;
        }

        return status;
      });

      toast.update(toastId.current, {
        render: 'Successfully uploaded the import data. You will receive an email when the import has completed.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });
  return mutation;
};
