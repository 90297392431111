import { useCallback } from 'react';
import { SerializedStyles } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import EmptyState from '~Home/components/common/EmptyState';
import RecognitionListItem from '~Home/components/Module/Recognition/RecognitionListItem';
import { recognitionPreview } from '~Home/const/HomeEmptyState';
import { faPartyHorn } from '@fortawesome/pro-light-svg-icons';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { RecognitionShape } from '~Recognition/const/types';
import { navigateAction } from '~Deprecated/actions/navigate';
import { RECOGNITION } from '~Common/const/routes';
import { useGetRecognition } from '~Recognition/hooks/useGetRecognition';
import { RECOGNITION_HOME_PAGE_SIZE } from '~Recognition/const/defaults';

interface RecognitionModuleViewProps extends Omit<LeadrCardProps, 'children'> {
  areRecognitionsLoading: boolean,
  headerText: string,
  recognitions?: RecognitionShape[],
  navigateToRecognition: () => void,
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const View = ({
  areRecognitionsLoading,
  headerText,
  recognitions,
  navigateToRecognition,
  dragHandleProps,
  ...props
}: RecognitionModuleViewProps): JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <ModuleHeader
      headerText={headerText}
      {...dragHandleProps}
    />

    <div>
      {areRecognitionsLoading && (
        <HomeSkeleton
          numberOfSkeletons={5}
          height={2.5}
        />
      )}

      {!areRecognitionsLoading && recognitions?.length === 0 && (
        <EmptyState
          image={recognitionPreview}
          renderIcon={(iconStyles: SerializedStyles) => (
            <FontAwesomeIcon
              css={iconStyles}
              icon={faPartyHorn}
              size="5x"
            />
          )}
          renderAction={(actionStyles: SerializedStyles) => (
            <LeadrButton
              css={actionStyles}
              component={Link}
              to={RECOGNITION}
              variant="text"
              data-test-id="postRecognition"
            >
              Post a Recognition
            </LeadrButton>
          )}
          text="to celebrate your team"
          altText="Create a Recognition to celebrate your team"
        />
      )}

      {!areRecognitionsLoading && recognitions?.map((recognition) => (
        <RecognitionListItem
          key={recognition.recognitionId}
          recognition={recognition}
        />
      ))}
    </div>

    <ModuleFooter
      renderFooterButton={(params) => (
        <LeadrButton
          data-test-id="viewAllRecognition"
          onClick={navigateToRecognition}
          variant="text"
          size="small"
          {...params}
        >
          View All
        </LeadrButton>
      )}
    />
  </LeadrCard>
);

export interface RecognitionModuleProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const RecognitionModule = ({
  dragHandleProps,
  ...props
}: RecognitionModuleProps): JSX.Element => {
  const dispatch = useDispatch();
  const { dataValues: recognitionData, isLoading: isRecognitionsLoading } = useGetRecognition({ take: RECOGNITION_HOME_PAGE_SIZE });
  const [areRecognitionsLoading] = useSkeletonLoaders(isRecognitionsLoading);
  const navigateToRecognition = useCallback(() => {
    dispatch(navigateAction({ pathname: RECOGNITION }));
  }, [dispatch]);

  const hookProps = {
    headerText: 'Recognition',
    recognitions: recognitionData.reverse(),
    navigateToRecognition,
    areRecognitionsLoading,
    dragHandleProps,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default RecognitionModule;
