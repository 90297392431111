import { css } from '@emotion/react';
import { NewGoal } from '~DevelopmentPlan/components/Modals/Goals/NewGoal';
import {
  FormValues,
  createGoalFormResolver,
  createGoalFormSchema,
  conformToDto,
} from '~Goals/schemata/CreateGoalSchemata';
import { DEFAULT_GOAL, DEFAULT_TEAM_ID } from '~Goals/const/defaults';
import { UseFormReturn, useForm } from 'react-hook-form';
import { GoalShape, LinkedGoalType, ValidationErrors } from '~Goals/const/types';
import { toast } from '~Common/components/Toasts';
import { useShowLinkGoalModal } from '~Goals/hooks/utils/useShowLinkGoalModal';
import { useCreateGoal } from '~Goals/hooks/useCreateGoal';
import { UseLinkGoalWithExistingGoalLinksReturn } from '~Goals/hooks/utils/useLinkGoalWithExistingGoalLinks';
import { useMemo, useRef } from 'react';
import { useGetLinkedGoalsById } from '~Goals/hooks/linkGoals/useGetLinkedGoalsById';
import { getOrganizationUserId } from '~Common/utils/localStorage';

const styles = {
  newGoal: css({
    boxShadow: 'none',
  }),
};

interface ViewProps {
  formContext: UseFormReturn<FormValues>,
  goal: GoalShape,
  runGoalValidations: () => void,
  closeModal: () => void,
}

const View = ({
  formContext,
  goal,
  runGoalValidations,
  closeModal,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <NewGoal
      formContext={formContext}
      goal={goal}
      runGoalValidations={runGoalValidations}
      actionTextToUse="Save"
      onCancel={closeModal}
      overrideStyles={styles.newGoal}
    />
  </div>
);

interface LinkNewGoalProps extends Pick<UseLinkGoalWithExistingGoalLinksReturn, 'linkParentGoal' | 'linkSupportingGoals'> {
  goalId: string,
  linkedGoalType: LinkedGoalType,
}

const LinkNewGoal = ({
  goalId,
  linkedGoalType,
  linkParentGoal,
  linkSupportingGoals,
  ...props
}: LinkNewGoalProps): JSX.Element => {
  const { closeModal } = useShowLinkGoalModal();
  const { data: linkedGoalsData } = useGetLinkedGoalsById({ goalId });
  const orginalSupportingGoalIds = useMemo(() => linkedGoalsData?.response.childGoals?.map((goal) => goal.goalId) || [], [linkedGoalsData]);

  const goal = useMemo(() => ({ ...DEFAULT_GOAL }), []);

  const defaultValues = useMemo<FormValues>(() => ({
    ...DEFAULT_GOAL,
    owner: getOrganizationUserId() ?? '',
    type: DEFAULT_GOAL.context.contextType,
    team: DEFAULT_TEAM_ID,
  }), []);

  const formContext = useForm<FormValues>({
    defaultValues,
    resolver: createGoalFormResolver,
  });

  const linkGoal = (linkedGoalId: string): void => {
    if (linkedGoalType === LinkedGoalType.Parent) {
      linkParentGoal(linkedGoalId);
    } else {
      linkSupportingGoals([...orginalSupportingGoalIds, linkedGoalId]);
    }

    closeModal();
  };

  const toastId = useRef<string | number | null>(null);
  const { mutate: createGoalMutation } = useCreateGoal({
    onMutate: () => {
      toastId.current = toast.info('Creating your goal...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error creating your goal. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (responseData) => {
      toast.update(toastId.current, {
        render: 'Successfully created your goal.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      linkGoal(responseData.response.goalId);
    },
  });

  const runGoalValidations = (): void => {
    const data = formContext.getValues();
    createGoalFormSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        const formData = conformToDto(data);
        createGoalMutation({ goal: formData });
      })
      .catch((err: ValidationErrors) => {
        err.errors.forEach((error) => {
          toast.error(error);
        });
      });
  };

  const hookProps = {
    goal,
    formContext,
    runGoalValidations,
    closeModal,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LinkNewGoal;
