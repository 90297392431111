import { css } from '@emotion/react';
import Button from '~Common/V3/components/Button';

import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import InsightGraph from '~Deprecated/ui/components/Insights/InsightPlot/InsightGraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import InsightMetric from '~Deprecated/ui/components/Insights/InsightMetric';
import { peopleMetricsTemplate } from '~People/components/Drawers/Metrics';
import { PeoplePageSummaryReport, PeoplePagePerson } from '~People/const/interfaces';
import { MouseEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import CondensedInsightPlot from '../CondensedInsightPlot';

const styles = {
  summaryReportSection: css({
    border: `1px solid ${palette.neutrals.gray300}`,
    borderRadius: '0.5rem',
    padding: '.75rem',
  }),
  textButton: css({
    fontSize: '0.75rem',
    color: palette.brand.indigo,
    fontWeight: 500,
    padding: 0,
  }),
  directReportMetrics: css({
    fontSize: '.625rem',
    fontWeight: 600,
    color: palette.brand.indigo,
    display: 'flex',
    gap: '.25rem',
    alignItems: 'center',
    marginBottom: '.75rem',
  }),
};

const tooltipText = `
  This is an average of your direct reports' engagement in Leadr, including recurring 1:1s, Goals, Feedback, and Weekly Activity.
  To see each person's individual metrics, click "Metrics". 
  To learn more about what each stoplight metric means, visit this Support article.
`;

interface ViewProps {
  handleMetricsClick: (event: MouseEvent<HTMLElement>) => void,
  summaryReport: PeoplePageSummaryReport,
}

const View = ({
  handleMetricsClick,
  summaryReport,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.summaryReportSection} {...props}>
    <div css={styles.directReportMetrics}>
      <Button
        css={styles.textButton}
        variant="text"
        onClick={handleMetricsClick}
        renderContents={() => (
          <>Direct Report Metrics</>
        )}
      />
      <Tooltip content={tooltipText}>
        <div>
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
      </Tooltip>
    </div>
    <CondensedInsightPlot
      metricSummaryReport={summaryReport}
      renderMetric={({ metric }) => (
        <InsightMetric
          metric={metric}
        />
      )}
      renderGraph={({ metric, scoreSummary }) => (
        <InsightGraph
          metric={metric}
          // @ts-expect-error TODO: fix this when InsightsGraph is typed
          scoreSummaryForMetric={scoreSummary}
        />
      )}
    />
  </div>
);

interface SummaryReportSectionProps extends Omit<ViewProps, 'handleMetricsClick' | 'orgUserId'> {
  user: PeoplePagePerson,
  summaryReport: PeoplePageSummaryReport,
}

const SummaryReportSection = ({
  user,
  ...props
}: SummaryReportSectionProps): JSX.Element => {
  const dispatch = useDispatch();

  const {
    userId,
    firstName,
    lastName,
    jobTitle,
    department,
    report,
    manager,
    orgUserId,
  } = user;

  const managerName = manager ? `${manager.firstName?.trim()} ${manager.lastName.trim()}`.trim() : '';

  const handleMetricsClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(pushDrawerAction({
      drawer: {
        ...peopleMetricsTemplate,
        args: {
          userId,
          firstName,
          lastName,
          jobTitle,
          department,
          report,
          managerName,
        },
      },
    }));
  }, [department, dispatch, firstName, jobTitle, lastName, report, managerName, userId]);

  const hookProps = {
    handleMetricsClick,
    orgUserId,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default SummaryReportSection;
