import { css, SerializedStyles } from '@emotion/react';
import { darken } from 'polished';
import { hexToRGBA, palette } from '~Common/styles/colors';

// Regular button colors
const secondaryButtonColors = css(
  {
    backgroundColor: palette.brand.indigo,
    color: palette.neutrals.white,
    border: `1px solid ${palette.brand.indigo}`,

    '&: hover, &: focus': {
      backgroundColor: darken(0.1, palette.brand.indigo),
      color: palette.neutrals.white,
    },

    '&: disabled': {
      backgroundColor: hexToRGBA(palette.brand.indigo, 0.1),
      color: hexToRGBA(palette.brand.indigo, 0.3),
      borderColor: hexToRGBA(palette.brand.indigo, 0.1),
    },
  },
);

const skyButtonColors = css({
  backgroundColor: palette.brand.sky,
  color: palette.neutrals.white,
  border: `1px solid ${palette.brand.sky}`,

  '&: hover, &: focus': {
    backgroundColor: darken(0.1, palette.brand.sky),
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.sky, 0.1),
    color: hexToRGBA(palette.brand.sky, 0.3),
    borderColor: hexToRGBA(palette.brand.sky, 0.1),
  },
});

const primaryButtonColors = css({
  backgroundColor: palette.brand.green,
  color: palette.neutrals.white,
  border: `1px solid ${palette.brand.green}`,

  '&: hover, &: focus': {
    backgroundColor: darken(0.1, palette.brand.green),
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.green, 0.1),
    color: hexToRGBA(palette.brand.green, 0.3),
    borderColor: hexToRGBA(palette.brand.green, 0.1),
  },
});

const warningButtonColors = css({
  backgroundColor: palette.brand.orange,
  color: palette.neutrals.white,
  border: `1px solid ${palette.brand.orange}`,

  '&: hover, &: focus': {
    backgroundColor: darken(0.1, palette.brand.orange),
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.orange, 0.1),
    color: hexToRGBA(palette.brand.orange, 0.3),
    borderColor: hexToRGBA(palette.brand.orange, 0.1),
  },
});

const dangerButtonColors = css({
  backgroundColor: palette.brand.red,
  color: palette.neutrals.white,
  border: `1px solid ${palette.brand.red}`,

  '&: hover, &: focus': {
    backgroundColor: darken(0.1, palette.brand.red),
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.red, 0.1),
    color: hexToRGBA(palette.brand.red, 0.3),
    borderColor: hexToRGBA(palette.brand.red, 0.1),
  },
});

const darkButtonColors = css({
  backgroundColor: palette.neutrals.gray900,
  color: palette.neutrals.white,
  border: `1px solid ${palette.neutrals.gray900}`,

  '&: hover, &: focus': {
    backgroundColor: darken(0.1, palette.neutrals.gray900),
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.neutrals.gray900, 0.1),
    color: hexToRGBA(palette.neutrals.gray900, 0.3),
    borderColor: hexToRGBA(palette.neutrals.gray900, 0.1),
  },
});

const lightButtonColors = css({
  backgroundColor: palette.neutrals.gray200,
  color: palette.neutrals.gray800,
  border: `1px solid ${palette.neutrals.gray200}`,

  '&: hover, &: focus': {
    backgroundColor: darken(0.1, palette.neutrals.gray200),
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: palette.neutrals.white,
    color: palette.neutrals.gray300,
    borderColor: palette.neutrals.gray300,
  },
});

// Outline button colors
const outlineSecondaryButtonColors = css({
  backgroundColor: palette.neutrals.white,
  color: palette.brand.indigo,
  border: `1px solid ${palette.brand.indigo}`,

  '&: hover, &: focus': {
    backgroundColor: palette.brand.indigo,
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.indigo, 0.1),
    color: hexToRGBA(palette.brand.indigo, 0.3),
    borderColor: hexToRGBA(palette.brand.indigo, 0.1),
  },
});

const outlineSkyButtonColors = css({
  backgroundColor: palette.neutrals.white,
  color: palette.brand.sky,
  border: `1px solid ${palette.brand.sky}`,

  '&: hover, &: focus': {
    backgroundColor: palette.brand.sky,
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.sky, 0.1),
    color: hexToRGBA(palette.brand.sky, 0.3),
    borderColor: hexToRGBA(palette.brand.sky, 0.1),
  },
});

const outlinePrimaryButtonColors = css({
  backgroundColor: palette.neutrals.white,
  color: palette.brand.green,
  border: `1px solid ${palette.brand.green}`,

  '&: hover, &: focus': {
    backgroundColor: palette.brand.green,
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.green, 0.1),
    color: hexToRGBA(palette.brand.green, 0.3),
    borderColor: hexToRGBA(palette.brand.green, 0.1),
  },
});

const outlineWarningButtonColors = css({
  backgroundColor: palette.neutrals.white,
  color: palette.brand.orange,
  border: `1px solid ${palette.brand.orange}`,

  '&: hover, &: focus': {
    backgroundColor: palette.brand.orange,
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.orange, 0.1),
    color: hexToRGBA(palette.brand.orange, 0.3),
    borderColor: hexToRGBA(palette.brand.orange, 0.1),
  },
});

const outlineDangerButtonColors = css({
  backgroundColor: palette.neutrals.white,
  color: palette.brand.red,
  border: `1px solid ${palette.brand.red}`,

  '&: hover, &: focus': {
    backgroundColor: palette.brand.red,
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.brand.red, 0.1),
    color: hexToRGBA(palette.brand.red, 0.3),
    borderColor: hexToRGBA(palette.brand.red, 0.1),
  },
});

const outlineDarkButtonColors = css({
  backgroundColor: palette.neutrals.white,
  color: palette.neutrals.gray900,
  border: `1px solid ${palette.neutrals.gray900}`,

  '&: hover, &: focus': {
    backgroundColor: palette.neutrals.gray900,
    color: palette.neutrals.white,
  },

  '&: disabled': {
    backgroundColor: hexToRGBA(palette.neutrals.gray900, 0.1),
    color: hexToRGBA(palette.neutrals.gray900, 0.3),
    borderColor: hexToRGBA(palette.neutrals.gray900, 0.1),
  },
});

const outlineLightButtonColors = css({
  backgroundColor: palette.neutrals.white,
  color: palette.neutrals.gray600,
  border: `1px solid ${palette.neutrals.gray600}`,

  '&: hover, &: focus': {
    backgroundColor: palette.neutrals.gray200,
    color: palette.neutrals.gray800,
    border: `1px solid ${palette.neutrals.gray200}`,
  },

  '&: disabled': {
    backgroundColor: palette.neutrals.white,
    color: palette.neutrals.gray300,
    borderColor: palette.neutrals.gray300,
  },
});

// Sizes
const standardButtonStyles = css({
  padding: '1rem 1.5rem',
  fontWeight: 600,
});

const smallButtonStyles = css({
  padding: '0.5rem 1rem',
  fontWeight: 400,
});

const largeButtonStyles = css({
  padding: '1.25rem 1.5rem',
  fontWeight: 600,
});

// Types
const buttonColorArray = ['primary', 'secondary', 'sky', 'warning', 'danger', 'dark', 'light'] as const;
export type buttonColor = typeof buttonColorArray[number];

const buttonSizeArray = ['standard', 'small', 'large'] as const;
export type buttonSize = typeof buttonSizeArray[number];

const buttonVariantArray = ['outline', 'text', 'normal'] as const;
export type buttonVariant = typeof buttonVariantArray[number];

// Maps
const defaultColorMap = {
  primary: primaryButtonColors,
  secondary: secondaryButtonColors,
  sky: skyButtonColors,
  warning: warningButtonColors,
  danger: dangerButtonColors,
  dark: darkButtonColors,
  light: lightButtonColors,
};

const outlineColorMap = {
  primary: outlinePrimaryButtonColors,
  secondary: outlineSecondaryButtonColors,
  sky: outlineSkyButtonColors,
  warning: outlineWarningButtonColors,
  danger: outlineDangerButtonColors,
  dark: outlineDarkButtonColors,
  light: outlineLightButtonColors,
};

const sizeMap = {
  small: smallButtonStyles,
  standard: standardButtonStyles,
  large: largeButtonStyles,
};

/*
  These are here because Typescript does not actually force you to pass the right string in
  So, if someone passes something in that is not a proper value, which would be easy to do in a file that is not Typescript,
  we still want to show the default button, and not break the app
*/
function isOfTypeButtonColor(color: buttonColor): boolean {
  return buttonColorArray.includes(color);
}

function isOfTypeButtonSize(size: buttonSize): boolean {
  return buttonSizeArray.includes(size);
}

function isOfTypeButtonVariant(variant: buttonVariant): boolean {
  return buttonVariantArray.includes(variant);
}

interface ButtonStylesProps {
  color?: buttonColor,
  size?: buttonSize,
  variant?: buttonVariant,
}

export const buildButtonStyles = (
  { color = 'primary', size = 'standard', variant = 'normal' }: ButtonStylesProps = {},
): SerializedStyles => {
  let buttonVariant = 'normal';

  if (isOfTypeButtonVariant(variant)) {
    buttonVariant = variant;
  }
  const colorMap = buttonVariant === 'outline' ? outlineColorMap : defaultColorMap;

  let buttonColors = colorMap.primary;
  let buttonSizes = sizeMap.standard;

  if (isOfTypeButtonColor(color)) {
    buttonColors = colorMap[color];
  }

  if (isOfTypeButtonSize(size)) {
    buttonSizes = sizeMap[size];
  }

  const buttonStyles = css(buttonColors, buttonSizes, ({ borderRadius: '.1875rem' }));

  // Since text buttons come in a lot of different colors, going to keep this super simple, and then you can add the text color per usage
  const textButtonStyles = ({
    background: 'transparent',
    border: 0,
  });

  return css(
    variant === 'text' ? textButtonStyles : buttonStyles,
  );
};
