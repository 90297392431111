import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import LearningCompletedCount from '~Learning/components/Shared/LearningDashboardCards/LearningCompletedCount';
import ListLearningCard from '~Learning/components/Shared/LearningDashboardCards/ListLearningCard';
import { AssignedLearning, LearningStatus } from '~Learning/const/interfaces';

const styles = {
  assignedListDashboard: css({
    display: 'grid',
    gridGap: '0.5rem',
    gridTemplateColumns: 'repeat(auto-fill)',
    width: '100%',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '6.25rem',
  }),
};

interface AssignedListDashboardProps extends ComponentProps<'div'> {
  assignedLearnings: AssignedLearning[] | undefined,
  onAssignedCardClick: (assignedLearning: AssignedLearning) => void,
}

const AssignedListDashboard = ({
  assignedLearnings,
  onAssignedCardClick,
  ...props
}: AssignedListDashboardProps): JSX.Element => (
  <div css={styles.assignedListDashboard} {...props}>
    {assignedLearnings?.map((assignedLearning) => (
      <ListLearningCard
        key={assignedLearning.id}
        id={assignedLearning.id}
        data-test-id={`assignedLearningListItem-${assignedLearning.id}`}
        onCardClick={() => onAssignedCardClick(assignedLearning)}
        learningType={assignedLearning.learningType}
        title={assignedLearning.title}
        dueDate={assignedLearning.dueDate}
        assignedUsersInfo={assignedLearning.assignedUsersInfo}
        numberOfUsersAssigned={assignedLearning.numberOfUsersAssigned}
        isComplete={assignedLearning.status === LearningStatus.COMPLETED}
        renderCompletedSection={() => (
          <LearningCompletedCount
            numberOfUsersAssigned={assignedLearning.numberOfUsersAssigned}
            numberOfUsersCompleted={assignedLearning.numberOfUsersCompleted}
          />
        )}
      />
    ))}
  </div>
);

const SkeletonView = (): JSX.Element => (
  <MultipleSkeletonLoaders
    css={styles.assignedListDashboard}
    numberOfSkeletons={10}
    renderSkeletonItem={() => (
      <CardSkeleton css={styles.cardSkeleton} />
    )}
  />
);

export default AssignedListDashboard;

export { SkeletonView as AssignedListDashboardSkeleton };
