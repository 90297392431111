import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { learningQueryKeys } from './queryKeys';

function getPendingLearningCountQuery(): Promise<HttpCallReturn<number>> {
  const orgId = getOrganizationId() ?? '';
  const url = `/organizations/${orgId}/learning/pending`;

  return getApi<number>(url);
}

export const useGetPendingLearningCount = (): UseQueryResult<HttpCallReturn<number>> => (
  useQuery({ queryKey: learningQueryKeys.pending(), queryFn: getPendingLearningCountQuery })
);
