import { useState } from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useActivePulseSurvey, useSubmitPulseSurvey } from '~Insights/hooks/usePulseSurvey';
import { enpsOptions, likertOptions, Question } from '~Insights/const/pulseSurveyQuestions';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { getUserId } from '~Common/utils/localStorage';
import { useGenericStore } from '~Common/hooks/useGenericStore';
import PulseSurveyQuestion, { PulseSurveyQuestionProps } from '~Insights/components/PulseSurveyQuestion';
import '~Common/styles/transitions.css';

interface TransitionClasses {
  enterActive: string,
  exitActive: string,
}

interface ViewProps extends PulseSurveyQuestionProps {
  currentQuestionIndex: number,
  transitionClasses: TransitionClasses,
  forceMobile?: boolean,
}

const View = ({
  currentQuestionIndex,
  transitionClasses,
  forceMobile,
  ...props
}: ViewProps): JSX.Element => (

  <SwitchTransition mode="out-in">
    <CSSTransition
      key={currentQuestionIndex}
      classNames={transitionClasses}
      timeout={{ enter: 500, exit: 0 }}
    >
      <PulseSurveyQuestion
        forceMobile={forceMobile}
        {...props}
      />
    </CSSTransition>
  </SwitchTransition>
);

interface PulseSurveyProps {
  forceMobile?: boolean,
}

const PulseSurvey = ({ forceMobile }:PulseSurveyProps): JSX.Element => {
  // const pulseSurveyEnabled = useFeatureFlag('frontendPulseSurvey');
  const { survey } = useActivePulseSurvey();
  const { value: answers, setValue: setAnswers } = useGenericStore({
    module: 'insights',
    workflow: 'pulseSurveys',
    feature: 'answers',
    defaultValue: [],
  }) as { value: number[], setValue: (value: number[]) => void; };
  // If they've started answering this earlier (or the page re-rendered), we want to get them
  // started with the next blank question.
  const startingQuestion = survey ? Math.min(survey.questions.length - 1, answers?.length) : 0;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(startingQuestion ?? 0);
  const [reverseTransition, setReverseTransition] = useState(false);

  const { mutateAsync: submitPulseSurveyMutation, isPending: pulseSurveyIsSubmitting } = useSubmitPulseSurvey();

  const questions = survey?.questions.map((question) => ({
    id: question.id,
    text: question.question,
    options: question.type.value === 'MultipleChoice' ? likertOptions : enpsOptions,
  }));

  const { user } = useUserProfile(getUserId() ?? '');
  const isInsightsEnabled = user?.orgDetails?.enableInsights;

  if (!isInsightsEnabled || !questions?.length) return <></>;

  const lastQuestion = questions.length - 1;
  const currentQuestion = questions[currentQuestionIndex];

  const onClickPrevButton = (): void => {
    const newIndex = Math.max(0, currentQuestionIndex - 1);
    setReverseTransition(true);
    setCurrentQuestionIndex(newIndex);
  };

  const onClickNextButton = (): void => {
    const newIndex = Math.min(lastQuestion, currentQuestionIndex + 1);
    setReverseTransition(false);
    setCurrentQuestionIndex(newIndex);
  };

  function transitionClasses(): TransitionClasses {
    const duration = 'animation-500';

    if (reverseTransition) {
      return {
        enterActive: `${duration} slideInLeft`,
        exitActive: `${duration} slideOutRight`,
      };
    }

    return {
      enterActive: `${duration} slideInRight`,
      exitActive: `${duration} slideOutLeft`,
    };
  }

  const onSelectValue = (value: number): void => {
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = value;
    setAnswers(newAnswers);
    onClickNextButton();
  };

  function allQuestionsAnswered(): boolean {
    return answers.filter((answer) => (typeof answer === 'number')).length === questions?.length;
  }

  const onSubmit = async (): Promise<void> => {
    if (!survey || pulseSurveyIsSubmitting) return;

    await submitPulseSurveyMutation({
      surveyId: survey.id,
      surveyAnswers: {
        answers: questions.map((question, index) => ({
          questionId: question.id,
          answer: answers[index].toString(),
        })),
      },
    }, {
      onSuccess: () => {
        setAnswers([]);
      },
    });
  };

  const hookProps = {
    isSubmitEnabled: allQuestionsAnswered(),
    onClickPrevButton,
    onClickNextButton,
    onSelectValue,
    onSubmit,
    questionIndex: currentQuestionIndex,
    question: currentQuestion as Question,
    questionCount: questions.length,
    selectedValue: answers[currentQuestionIndex] ?? undefined,
    transitionClasses: transitionClasses(),
    currentQuestionIndex,
    pulseSurveyIsSubmitting,
    forceMobile,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, PulseSurvey };
export default PulseSurvey;
