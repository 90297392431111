import { css } from '@emotion/react';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { withTruncate } from '~Common/styles/mixins';

const styles = {
  noTeamOwnerContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.75rem',
  }),
  name: css({}, withTruncate()),
};

interface ProfileCellProps {
  profileImageUrl: string,
  fullName: string,
}

const ProfileCell = ({
  profileImageUrl,
  fullName,
}: ProfileCellProps): JSX.Element => (
  <div css={styles.noTeamOwnerContainer}>
    <NewBaseAvatar
      profileImageUrl={profileImageUrl}
      avatarSize={30}
      name={fullName}
    />
    <span css={styles.name}>{fullName}</span>
  </div>
);

export default ProfileCell;
