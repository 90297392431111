import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import moment from 'moment-timezone';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';

import { faSave } from '@fortawesome/pro-duotone-svg-icons';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_STATE_SHAPE, FEEDBACK_SHAPE } from '~Common/const/proptypes';
import FormDrawer from '~Common/components/Drawers/Forms/Form';
import { useDispatch } from 'react-redux';
import { conformToDto, createResourceFormSchema } from '~DevelopmentPlan/schemata/addResourceSchemata';
import { useCreateResource } from '~DevelopmentPlan/hooks/useCreateResource';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import TemplatesButton from '~Common/components/Buttons/Templates';
import CreateButton from '~Common/components/Buttons/Create';
import AddPersonButton from '~Common/components/Buttons/AddPerson';
import { DrawerCompetencySelect } from '~DevelopmentPlan/components/Shared/DrawerCompetencySelect';
import { addResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { DEFAULT_DATE, DUPLICATE_RESOURCE_TEXT } from '~DevelopmentPlan/const/defaults';
import { checkShouldCreateResource } from '~DevelopmentPlan/const/functions';

import Button, {
  BUTTON_COLOR,
  BUTTON_VARIANT,
} from '~Common/components/Buttons/Button';

import { useTabs } from '~Common/hooks/useTabs';
import { peopleDrawerTemplate } from '~Deprecated/common/Containers/Drawers/PeopleDrawer';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { useFeedbackQuestions } from '~Deprecated/hooks/feedback/useFeedbackQuestions';
import { toast } from '~Common/components/Toasts';
import {
  useCreateFeedback,
  useAddQuestionsToFeedback,
} from '~Deprecated/hooks/feedback/useCreateFeedback';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { selectParticipantsDrawerTemplate } from '~Common/components/Drawers/SelectParticipantsDrawer';
import { palette } from '~Common/styles/colors';
import { competencyDrawerSelect } from '~DevelopmentPlan/const/pageStyles';
import { CompetencyResourceStatusEnum } from '~DevelopmentPlan/const/types';
import RequestRespondents from '../Tabs/RequestRespondents';
import RequestQuestions from '../Tabs/RequestQuestions';
import RequestDetails from '../Tabs/RequestDetails';
/* eslint-disable import/no-cycle */
import { templateListTemplate } from '../Templates/List';
import { createTemplateDrawerTemplate } from '../Templates/Create';
/* eslint-enable import/no-cycle */

export const createIncompleteFeedbackTemplate = {
  name: 'CREATE_INCOMPLETE_FEEDBACK_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  questionButtonContainer: css({
    display: 'flex',

    '& button': {
      margin: '0 .625rem',
    },
  }),
  questions: css({
    margin: '10px 0',
  }),
  bottomWarning: css({
    margin: '2rem 0 0 1.5rem',
    fontSize: '.75rem',
    color: palette.neutrals.gray700,
    maxWidth: '17.1875rem',
  }),
  bottomWarningLink: css({
    fontWeight: 600,
    color: palette.brand.indigo,
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'none',
    },
  }),
  competencySelect: css({
    marginTop: '.5rem',
    ...competencyDrawerSelect,
  }),
};

const CreateIncompleteFeedbackDrawer = ({
  drawerState,
  setDrawerState,
  popDrawer,
  popAfter,
  pushDrawer,
  feedbackContext,
  pdpId,
  ...props
}) => {
  const [tempFeedback, setTempFeedback] = useState({});
  const feedbackToUse = feedbackContext ?? {};
  const createQuestionsMutation = useAddQuestionsToFeedback({
    template: createIncompleteFeedbackTemplate,
    activeTab: 0,
  });
  const createFeedbackMutation = useCreateFeedback({
    nextMutation: createQuestionsMutation,
    template: createIncompleteFeedbackTemplate,
    activeTab: 1,
  });
  const { data: peopleData } = usePeopleQuery({});
  const frontendUserGroups = useFeatureFlag('frontendUserGroups');
  const peopleDrawerTemplateV2 = frontendUserGroups
    ? selectParticipantsDrawerTemplate
    : peopleDrawerTemplate;

  const tabQueryConfig = {
    module: 'feedback',
    workflow: 'create',
    defaultValue: 0,
  };

  useEffect(() => {
    if (feedbackContext?.questions) {
      setDrawerState({
        ...drawerState,
        questions: feedbackContext.questions,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [feedbackContext]);

  const {
    activeTab, setActiveTab, nextTab, setNextTab,
  } = useTabs(tabQueryConfig);

  const { onAddQuestion, onEditQuestion } = useFeedbackQuestions({
    setDrawerState,
    pushDrawer,
    popAfter,
    drawerState,
    drawerName: createIncompleteFeedbackTemplate.name,
    existingQuestions: feedbackContext?.questions,
  });

  const onTemplates = () => {
    pushDrawer({
      drawer: {
        ...templateListTemplate,
        args: {
          onlyQuestions: true,
        },
      },
    });
  };

  const onAddRespondent = () => {
    pushDrawer({
      drawer: {
        ...peopleDrawerTemplateV2,
        args: {
          useOrgIds: false,
          allowSelf: false,
        },
      },
    });
  };

  const onSaveTemplate = () => {
    pushDrawer({
      drawer: {
        ...createTemplateDrawerTemplate,
        args: {
          templateContext: {
            title: tempFeedback.feedbackTitle,
            description: tempFeedback.introduction,
            question: drawerState?.questions,
          },
        },
      },
    });
  };
  const tabs = [
    ['Details', <RequestDetails pdpId={pdpId} feedback={feedbackToUse} isReadOnly={false} />],
    [
      'Questions',
      <RequestQuestions
        questions={drawerState?.questions}
        isReadOnly={false}
        onEditQuestion={onEditQuestion}
      />,
    ],
    [
      'Respondents',
      <RequestRespondents
        respondents={drawerState?.selectedAttendees?.map(
          (attendee) => peopleData[attendee],
        )}
        isReadOnly={false}
      />,
    ],
  ];
  const { setResourceContentDueDate, setResourceContentTitle } = addResourceModalStore.getState();
  const onSubmit = (e) => {
    if (activeTab === 0) {
      const formData = new FormData(e.target);
      const newFeedback = Object.fromEntries(formData.entries());

      setTempFeedback(newFeedback);
    }
    // Questions Tab
    if (activeTab >= 1 && drawerState?.questions.length === 0) {
      toast.error(
        'You must add at least one question for people to respond to.',
      );
      return false;
    }

    // People Tab
    if (
      activeTab === 2
      && (!drawerState.selectedAttendees
        || drawerState.selectedAttendees?.length === 0)
    ) {
      toast.error(
        'You must add at least one person to respond to this feedback',
      );
      return false;
    }

    if (activeTab < tabs.length - 1) {
      const newTab = nextTab !== activeTab ? nextTab : activeTab + 1;
      setActiveTab(newTab);
      setNextTab(newTab);
    } else {
      // make api call
      const formData = new FormData(e.target);
      const newFeedback = Object.fromEntries(formData.entries());
      // Check if competencyId exists in the object since we do not want to pass it to the API
      if (Object.prototype.hasOwnProperty.call(newFeedback, 'competencyId')) {
        // Remove competencyId from the object
        delete newFeedback.competencyId;
      }
      if (newFeedback.dueDate) {
        newFeedback.dueDate = parseInt(
          moment(newFeedback.dueDate, 'MM/DD/YYYY').format('x'),
          10,
        );
      }
      const { feedbackTitle, dueDate } = newFeedback;
      const dateToUse = dueDate ? new Date(dueDate) : DEFAULT_DATE;
      setResourceContentDueDate(dateToUse);
      setResourceContentTitle(feedbackTitle);
      createFeedbackMutation({
        feedback: newFeedback,
        questions: drawerState.questions,
        requestedTo: drawerState.selectedAttendees,
      }, { onSuccess: successfulFeedbackCreation });
    }

    return true;
  };
  const { mutate: createResourceMutation } = useCreateResource();
  const {
    resourceId, competencyId,
  } = addResourceModalStore.getState();
  const runAddResourceValidations = (resourceIdClicked, contentId) => {
    const {
      resourceContentTitle, resourceContentDueDate,
    } = addResourceModalStore.getState();

    const useFallBackDate = resourceContentDueDate?.getTime() === DEFAULT_DATE.getTime();

    const addResourceDataToValidate = {
      contentId,
      contentTypeId: resourceIdClicked,
      competencyId,
      contentTitle: resourceContentTitle,
      contentDueDate: useFallBackDate ? new Date() : resourceContentDueDate,
      contentStatus: CompetencyResourceStatusEnum.NotStarted,
    };

    // Need to run these validations as a faux form submission
    runValidations(addResourceDataToValidate);
  };
  const runValidations = (data) => {
    const resourceFound = checkShouldCreateResource(data, pdpId ?? '');
    if (!resourceFound) {
      createResourceFormSchema
        .validate(data, { abortEarly: false })
        .then(() => {
          const resourceDataDTO = conformToDto(data);
          createResourceMutation({ id: pdpId ?? '', resource: resourceDataDTO }, { onSuccess: onSuccessfulResourceCreation });
        })
        .catch((err) => {
          if (err.errors) {
            err.errors.forEach((error) => {
              toast.error(error);
            });
          } else {
            toast.error('There was an issue creating this resource. Please try again. You will find it in the existing tab.');
          }
        });
    } else {
      toast.error(DUPLICATE_RESOURCE_TEXT);
    }
  };
  const onSuccessfulResourceCreation = () => {
    dispatch(popDrawerAction({ popAll: true }));
  };
  const isPDPDrawer = pdpId && resourceId && competencyId;
  const dispatch = useDispatch();
  const successfulFeedbackCreation = (data) => {
    if (isPDPDrawer) {
      const { feedbackId } = data.response;
      runAddResourceValidations(resourceId, feedbackId);
    }
    if (!isPDPDrawer) {
      dispatch(popDrawerAction({ popAll: true }));
    }
  };

  const hookProps = {
    drawerName: createIncompleteFeedbackTemplate.name,
    onSubmit,
    drawerTitle: 'Request Feedback',
    tabs,
    renderPersonalDevelopmentContent: () => (
      <>
        {pdpId && (
        <DrawerCompetencySelect
          pdpId={pdpId}
          selectStyles={styles.competencySelect}
          setDrawerState={setDrawerState}
        />
        )}
      </>
    ),
    renderBottomButtons: () => (
      <>
        {activeTab === 1 && (
          <>
            <div css={styles.questionButtonContainer}>
              <CreateButton
                text="Add Question"
                onClick={onAddQuestion}
                disabled={drawerState?.questions?.length >= 25}
                data-test-id="feedbackAddQuestionButton"
              />
              <TemplatesButton onClick={onTemplates} data-test-id="feedbackAddViewTemplatesButton" />
              <Button
                text="Save As Template"
                onClick={onSaveTemplate}
                variant={BUTTON_VARIANT.STACKED}
                buttonColor={BUTTON_COLOR.OPTION}
                icon={faSave}
                data-test-id="feedbackSaveAsTemplateButton"
              />
            </div>
          </>
        )}
        {activeTab === 2 && (
          <AddPersonButton onClick={onAddRespondent} text="Add Respondent" data-test-id="feedbackAddRespondentButton" />
        )}
      </>
    ),
    renderBottomWarning: () => (
      <>
        {activeTab === 1 && (
        <div css={styles.bottomWarning}>
          <p>
            Please note: Managers can view and
            {' '}
            <a
              css={styles.bottomWarningLink}
              href="https://leadr.helpscoutdocs.com/article/52-reflections-using-reflections-in-leadr"
              target="_blank"
              rel="noreferrer"
            >
              reflect on
            </a>
            {' '}
            feedback requests you send out
          </p>
        </div>
        )}
      </>
    ),
    showNextButton: activeTab + 1 < tabs.length,
    showSendButton: activeTab + 1 === tabs.length,
    onNextClick: () => {
      setNextTab(activeTab + 1);
    },
    tabQueryConfig,
    formName: 'createFeedback',
    formToastError:
      "Oops! You've missed a required field. Please be sure to include a title and at least 1 question and respondent before saving.",
  };

  return <FormDrawer {...hookProps} {...props} />;
};

CreateIncompleteFeedbackDrawer.propTypes = {
  popDrawer: PropTypes.func.isRequired,
  popAfter: PropTypes.func.isRequired,
  pushDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  feedbackContext: FEEDBACK_SHAPE,
  pdpId: PropTypes.string,
};

CreateIncompleteFeedbackDrawer.defaultProps = {
  feedbackContext: null,
  pdpId: undefined,
};

registerDrawer({
  templateName: createIncompleteFeedbackTemplate.name,
  component: CreateIncompleteFeedbackDrawer,
});

export default CreateIncompleteFeedbackDrawer;
