import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import {
  ActionItemContext, ActionItemContextType, NewActionItemStatus,
} from '~ActionItems/const/interfaces';
import { queryClient } from '~Common/const/queryClient';
import React, { ReactText, useRef } from 'react';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import SupportChatErrorToast from '~Common/components/SupportChatErrorToast';
import { USER_PENDING_ITEMS_QUERY_KEY } from '~Root/components/OldNavigationSidebar/useNavigationIndicators';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';

// Not sending a field in the request maintains its current value
// Sending null clear any existing value
// assigneeUserId is required if assigneeId is sent
export type EditActionItemData = {
  // assigneeId: never,
  // assigneeUserId: never,
  description?: string,
  dueDateInMillis?: number | null,
  externalLink?: string | null,
  status?: NewActionItemStatus,
  text?: string,
} | {
  description?: string,
  assigneeId: string,
  assigneeUserId: string,
  dueDateInMillis?: number | null,
  externalLink?: string | null,
  status?: NewActionItemStatus,
  text?: string,
  isRecurring?: boolean,
  recurrenceRule?: string,
};

interface CreateActionItemProps {
  id: string,
  actionItem: EditActionItemData,
  context?: ActionItemContext,
}

export const editActionItem = async ({
  id,
  actionItem,
}: CreateActionItemProps): Promise<HttpCallReturn<null>> => {
  const url = {
    host: getHost(hosts.action, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/action-items/${id}`,
  };

  return patchApi(url, actionItem);
};

export interface UseEditActionItemProps {
  drawerName?: string,
}

export const useEditActionItem = ({
  drawerName,
}: UseEditActionItemProps): UseMutationResult<HttpCallReturn<null>, unknown, CreateActionItemProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: editActionItem,
    onMutate: () => {
      toastId.current = toast.info('Updating action item', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: (
          <SupportChatErrorToast
            error="The action item update failed due to a technical issue on our end."
            chatMessage="Trouble editing an action item."
          />
        ),
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async (_, variables) => {
      const { context } = variables;

      if (drawerName) {
        // @ts-expect-error Removed once we type or replace Redux
        dispatch(popDrawer({ drawerName }));
      }

      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'action-items'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'homeActionItems', 'all'] });
      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });

      if (context?.type === ActionItemContextType.Meeting) {
        await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', context.id, 'actionItems'] });
      }

      if (context?.type === ActionItemContextType.Goal) {
        await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'goals', context.id] });
      }

      toast.update(toastId.current, {
        render: 'Your action item was successfully updated.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });

  return mutation;
};
