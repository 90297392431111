import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { withH3Font } from '~Common/styles/typography';
import { noop } from '~Deprecated/utils/';
import { forMobile } from '~Common/styles/mixins';

const styles = {
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3rem;
    padding-right: max(1.5rem, env(safe-area-inset-right));
    padding-bottom: 0;
    padding-left: max(1.5rem, env(safe-area-inset-left));
    position: sticky;

    ${forMobile(`
      padding: 0.5rem;
    `)}
  `,
  title: css`
    text-transform: unset;
    ${withH3Font()}
    color: ${palette.neutrals.gray800};
  `,
  buttons: css`
    display: flex;
    align-items: flex-start;
    margin-top: env(safe-area-inset-top);

    button {
      ${forMobile(`
        margin: 0 5px;
      `)}
    }
  `,
  empty: css`
    width: 116px;
    min-height: 40px;
  `,
  introText: css`
    margin: 1.5rem 0 0;
    color: ${palette.neutrals.gray700};
    font-size: 16px;
  `,
};

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx
 * with packages/leadr-frontend/src/common/V3/components/Drawers/DrawerHeader.tsx instead
 */

const DrawerHeader = ({
  title, renderCustomHeader, renderButtons, setSubmitter, className, introText,
}) => {
  const renderTitle = () => (
    <h6
      css={styles.title}
    >
      { title }
    </h6>
  );

  const renderIntro = () => (
    <p css={styles.introText}>
      {introText}
    </p>
  );

  const renderHeader = () => {
    if (renderCustomHeader && typeof renderCustomHeader === 'function') {
      return renderCustomHeader();
    }
    return (
      <div>
        {renderTitle()}
        {renderIntro()}
      </div>
    );
  };

  return (
    <div css={styles.header} className={className}>
      { renderHeader() }
      <div
        css={styles.buttons}
      >
        { renderButtons({ setSubmitter }) || <div css={styles.empty} /> }
      </div>
    </div>
  );
};

DrawerHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  renderButtons: PropTypes.func,
  renderCustomHeader: PropTypes.func,
  setSubmitter: PropTypes.func,
  introText: PropTypes.string,
};

DrawerHeader.defaultProps = {
  className: '',
  title: '',
  renderButtons: noop,
  renderCustomHeader: null,
  setSubmitter: noop,
  introText: '',
};

export default DrawerHeader;
