import { put } from 'redux-saga/effects';

import history from '~Deprecated/store/configureStore/history';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { buildQueryString } from '~Common/utils';

export function* navigate(props) {
  const propsToUse = props.payload ? props.payload : props;

  const {
    pathname, search = {}, popAll = true, doPop = true, params = {},
  } = propsToUse;
  let pathnameToUse = pathname;

  const keys = Object.keys(params);

  // Close drawers on navigate if specified
  if (doPop) {
    yield put(popDrawerAction({ popAll }));
  }

  // Scroll the content pane to make the page feel 'fresh'. Add an exclusion if we ever want to not do this.
  document.querySelector('.top-content-pane > div')?.scrollTo?.(0, 0);

  // Inject params into the url if possible
  keys.forEach((key) => {
    pathnameToUse = pathnameToUse.replace(`:${key}`, params[key]);
  });
  history.push({
    pathname: pathnameToUse,
    search: buildQueryString(search),
  });
}
