import { startCase } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, FormHelperText } from '@mui/material';
import { palette } from '~Common/styles/colors';
import LeadrCheckbox, { LeadrCheckboxProps } from '../LeadrCheckbox';

const styles = {
  checkboxLabel: css({
    fontSize: '0.875rem',

    '& .Mui-checked': {
      color: palette.brand.indigo,
    },
  }),
  leadrCheckbox: css({
    marginRight: '.75rem',
  }),
};

interface CheckboxProps extends LeadrCheckboxProps {
  label?: string,
  name: string,
}

export function Checkbox({
  name,
  label = startCase(name),
  ...props
}: CheckboxProps): JSX.Element {
  const { register, formState: { errors } } = useFormContext();
  const error = errors[name] as { message: string; };

  return (
    <FormControl
      error={!!error}
    >
      <FormControlLabel
        css={styles.checkboxLabel}
        control={(
          <LeadrCheckbox
            css={styles.leadrCheckbox}
            {...props}
            {...register(name)}
          />
        )}
        label={label}
      />
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
}
