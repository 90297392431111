import { css } from '@emotion/react';
import { Card as MaterialUICard, CardProps as MaterialUICardProps } from '@mui/material';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import {
  ComponentProps,
  ForwardedRef,
  ReactNode,
  forwardRef,
} from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  leadrCard: css({
    padding: '.875rem 1.125rem',
    borderRadius: '.5rem',
    boxShadow: '0px 4px 8px -2px rgba(28, 42, 55, 0.2)',

    '@media print': {
      WebkitFilter: 'drop-shadow(0px 4px 8px rgba(28, 42, 55, 0.2))',
    },
  }),
  leadrCardFooter: css({
    paddingTop: '.75rem',
    marginTop: '.75rem',
    borderTop: `1px solid ${palette.neutrals.gray200}`,
  }),
};

export interface LeadrCardProps extends MaterialUICardProps {
  children: ReactNode,
}

const Card = forwardRef(({ children, ...props }: LeadrCardProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => (
  <MaterialUICard css={styles.leadrCard} ref={ref} {...props}>
    {children}
  </MaterialUICard>
));

interface LeadrCardFooterProps extends ComponentProps<'div'> {
  children: ReactNode,
}

const Footer = ({ children, ...props }: LeadrCardFooterProps): JSX.Element => (
  <div css={styles.leadrCardFooter} {...props}>
    {children}
  </div>
);

const SkeletonView = ({
  ...props
}): JSX.Element => (
  <SkeletonLoader
    css={styles.leadrCard}
    variant="rectangular"
    renderComponent={() => <></>}
    {...props}
  />
);

// Way to add LeadrCard.Footer as something you can use in this component, has to be done with Object.assign to get around weird TS error
const LeadrCard = Object.assign(Card, { Footer });

export { SkeletonView as LeadrCardSkeleton };

export default LeadrCard;
