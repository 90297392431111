import { useFeedback } from '~Deprecated/hooks/feedback/useFeedback';
import { FeedbackItem, UseTransformItemsReturn } from '~Home/const/interfaces';
import {
  FeedbackSortByField, FeedbackType,
} from '~Feedback/const/interfaces';
import moment from 'moment-timezone';
import { faCommentAlt } from '@fortawesome/pro-regular-svg-icons';
import { WhatsDueFilterOptions } from '~Home/const';
import { onFeedbackItemClickHandler } from '~Home/const/WhatsDueItemClickHandlers';
import { SortDirection } from '~Common/const/interfaces';

const feedbackParser = (feedbackObject: Record<string, FeedbackItem>): FeedbackItem[] => {
  const feedbacks = Object.values(feedbackObject);
  const filteredFeedback = feedbacks.filter((feedback) => feedback.isCompleted !== true && feedback.isCompletedByLoggedInUser !== true);
  return filteredFeedback;
};

export const useTransformFeedbackItems = ():UseTransformItemsReturn => {
  const { isLoading, data: feedback = {} } = useFeedback({
    type: FeedbackType.RECEIVED,
    pageSize: 1000,
    sortOrder: SortDirection.Ascending,
    sortField: FeedbackSortByField.DUE_DATE,
  }) as unknown as {data: Record<string, FeedbackItem>, isLoading: boolean};

  const parsedFeedback = feedbackParser(feedback);
  const transformedFeedback = parsedFeedback.map((item) => ({
    type: WhatsDueFilterOptions.Feedback,
    text: item.title,
    iconToRender: faCommentAlt,
    onItemClick: onFeedbackItemClickHandler,
    dueDate: item.dueDate ? moment(item.dueDate) : undefined,
    id: item.id,
  }));
  return {
    isLoading,
    data: transformedFeedback,
  };
};
