import { meetingGoals } from '~Meetings/const/sharedStyles';
import MeetingGoalCard from '../MeetingGoalCard';

const styles = {
  ...meetingGoals,
};

export const MeetingGoalsLoader = ({ ...props }): JSX.Element => (
  <>
    <div
      css={styles.container}
      {...props}
    >
      <header css={styles.header}>
        <div css={styles.title}>
          <span>
            Goals
          </span>
        </div>
      </header>
      <hr css={styles.divider} />
      <div css={styles.body}>
        <>
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
        </>
      </div>
    </div>
  </>
);

export default MeetingGoalsLoader;
