import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { TeamListDataOptions, teamsQueryKeys } from './queryKeys';
import { TeamRow } from '../const/types';

interface GetTeamsParams extends TeamListDataOptions {
  orgId: string,
}

interface GetTeamsReturn {
  teams: TeamRow[],
  totalTeamCount: number,
}

function getTeams({
  orgId,
  page,
  count,
  listScope,
}: GetTeamsParams): Promise<HttpCallReturn<GetTeamsReturn>> {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    count: count.toString(),
    listScope,
  });

  const serverUrl = {
    // Host doesn't matter, but need this format to use the V2 endpoint
    host: getHost(hosts.reviewCycles, '2'),
    uri: `/organizations/${orgId}/teams?${queryParams.toString()}`,
  };

  return getApi<GetTeamsReturn>(serverUrl);
}

type UseGetTeamsParams = TeamListDataOptions;

export function useGetTeams({
  page,
  count,
  listScope,
}: UseGetTeamsParams): UseQueryResult<HttpCallReturn<GetTeamsReturn>> {
  const orgId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: teamsQueryKeys.list({
      orgId,
      dataOptions: {
        page,
        count,
        listScope,
      },
    }),

    queryFn: () => getTeams({
      orgId,
      page,
      count,
      listScope,
    }),
  });
}
