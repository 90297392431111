import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import DrawerLayout from '~Common/components/Drawers/BaseLayout';
import TabContainer from '~Deprecated/common/Components/Tabs/TabContainer';
import TabItem from '~Deprecated/common/Components/Tabs/TabItem';
import { useTabs } from '~Common/hooks/useTabs';
import { forMobile } from '~Common/styles/mixins';

const styles = {
  header: css`
    padding: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
  `,
  drawerBody: css`
    padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  `,
  drawerBodyContent: css`
    padding: 0 1.5rem 1.5rem 1.5rem;

    ${forMobile(`
      padding: 0.5rem;
    `)}
  `,
  tabBody: (isActive, length) => css`
    display: ${!isActive ? 'none' : 'block'};
    margin: ${length > 1 ? '1rem' : 0} 0;
  `,
};

function a11yProps(index) {
  return {
    id: `tasks-tab-${index}`,
    'aria-controls': `tasks-tab-body-${index}`,
  };
}

const nextButtonFormClass = 'formWithNextButton';

const View = ({
  tabs, // An array of [key, object] pairs describing individual tabs
  renderHeader,
  renderBottomButtons,
  activeTab,
  setActiveTab,
  onSubmit,
  showNextButton,
  renderPersonalDevelopmentContent,
  ...props
}) => (
  <DrawerLayout
    headerClass={styles.header}
    renderHeader={renderHeader}
    activeTab={activeTab}
    renderBottomButtons={renderBottomButtons}
    bodyClass={styles.drawerBody}
    onSubmit={onSubmit}
    formClass={showNextButton ? nextButtonFormClass : ''}
    renderBody={() => (
      <div css={styles.drawerBodyContent}>
        { tabs.length > 1 && (
          <TabContainer value={activeTab} onChange={setActiveTab}>
            { tabs.map((tab, index) => (
              <TabItem
                key={tab[0]}
                label={tab[0]}
                {...a11yProps(index)}
              />
            )) }
          </TabContainer>
        ) }
        {renderPersonalDevelopmentContent()}
        { tabs.map((tab, index) => (
          <div
            key={tab[0]}
            css={[styles.tabBody(activeTab === index, tabs.length)]}
          >
            { tab[1] }
          </div>
        )) }
      </div>
    )}
    {...props}
  />
);

View.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.array).isRequired,
  renderHeader: PropTypes.func.isRequired,
  renderBottomButtons: PropTypes.func,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  showNextButton: PropTypes.bool.isRequired,
  renderPersonalDevelopmentContent: PropTypes.func,
};

View.defaultProps = {
  onSubmit: null,
  renderBottomButtons: null,
  renderPersonalDevelopmentContent: () => undefined,
};

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx
 * with packages/leadr-frontend/src/common/V3/components/Drawers/TabDrawerHeader.tsx instead
 */
const TabDrawer = ({
  tabQueryConfig,
  showNextButton,
  formToastError,
  renderPersonalDevelopmentContent,
  ...props
}) => {
  const { activeTab, setActiveTab, setNextTab } = useTabs(tabQueryConfig);

  /* this needs some work. But will do for now */
  const changeTab = (event, newValue) => {
    if (showNextButton && newValue > activeTab) {
      setNextTab(newValue);
      setTimeout(() => {
        [
          ...[
            ...document.forms,
          ].find((form) => form.className.indexOf(nextButtonFormClass) >= 0).elements,
        ].find((element) => element.type === 'submit').click();
      }, 250);
    } else {
      setActiveTab(newValue);
    }
  };

  const hookProps = {
    activeTab,
    setActiveTab: changeTab,
    showNextButton,
    renderPersonalDevelopmentContent,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

TabDrawer.propTypes = {
  tabQueryConfig: PropTypes.objectOf(PropTypes.any),
  showNextButton: PropTypes.bool,
  formToastError: PropTypes.string,
  renderPersonalDevelopmentContent: PropTypes.func,
};

TabDrawer.defaultProps = {
  tabQueryConfig: {},
  showNextButton: false,
  formToastError: '',
  renderPersonalDevelopmentContent: () => undefined,
};

export { View };
export default TabDrawer;
