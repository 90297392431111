import { normalizeAttendeesFromServer } from '~Deprecated/utils/normalizers/attendees';
import { normalizeAgendaItemsFromServer } from './agendaItems';
import { flattenData } from '~Deprecated/utils/reduxUtils';
import { ONE_TIME_MEETING } from '~Common/const/constraints';
import { MEETING_FACTORY_TYPES, MEETING_TYPES } from '~Meetings/const/meetingTypes';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';

/*
  Key Takeaways:

  Sometimes a 'meeting' is an upcoming meeting that is part of an object with larger context
  Sometimes a 'meeting' is simply just an object
  And then the details of a meeting does some things differently, while also having traits of both meetings and coachings

  This function serves to normalize all cases into a consistent object
*/
export const normalizeMeetingFromServer = ({ state = {}, data }) => {
  const nextMeeting = data.nextSession ? data.nextSession : data;
  let meeting;

  const {
    attendeeUid, ...meetingData
  } = nextMeeting;

  // isCoaching is always applied at the top object level from Sagas
  const {
    isCoaching,
  } = data;

  // API can return invalid meetings, filter those out
  if (!nextMeeting.id || !nextMeeting.startTimeInMillis) {
    return null;
  }

  // Maintain existing data if it exists, but overwrite
  const existingData = state.data && state.data[nextMeeting.id];

  if (existingData) {
    meeting = {
      ...existingData,
      ...meetingData,
    };
  } else {
    meeting = meetingData;
  }

  // Normalize attendee naming and data - This should be broken out into a people workflow later on
  let actualAttendees;
  if (data.attendees || nextMeeting.attendees) {
    actualAttendees = data.attendees ? data.attendees : nextMeeting.attendees;
  } else if (data.attendee || nextMeeting.attendee) {
    actualAttendees = data.attendee ? data.attendee : nextMeeting.attendees;
  } else {
    // Log wtfery
  }

  actualAttendees = normalizeAttendeesFromServer({
    data: actualAttendees,
  });

  if (meeting.agendaItems) {
    meeting.agendaItems = normalizeAgendaItemsFromServer({
      data: meeting.agendaItems,
    });
  }

  if (meeting.actionItems) {
    // Saving in a new variable for backwards compatibility.
    meeting.tasks = flattenData({
      responseData: meeting.actionItems,
      idName: 'id',
    });
  }

  // For Coachings, filter out meetings if the other user in inActive
  if (isCoaching && actualAttendees.length === 0) {
    return null;
  }

  meeting.attendees = actualAttendees;

  // Organizer lives in different places depending on the payload
  if (data.organizer) {
    meeting.organizer = data.organizer;
  }

  // Coaching calls only contain the other person, but meeting data includes you. Except for details calls, which always includes you, so we need some wiggle room here.
  meeting.isCoaching = isCoaching;

  return meeting;
};

export const normalizeMeetingToServer = ({
  title,
  attendees,
  date,
  time,
  location,
  frequency,
  questionIds,
  daysOfWeek,
  incomingMeeting,
  usingNylas,
  eventId,
  startTimeInMillis,
  endTimeInMillis,
  isPrivate,
  type,
  timezone,
  isCreateHuddle,
  teamId,
}) => {
  // Needed for older UI that didn't include an end time. Most has been removed, but I think this might still be required for now?
  // ToDo: Remove this when no longer needed.
  const endTime = endTimeInMillis ?? parseInt(startTimeInMillis, 10) + (60 * 60 * 1000);

  const isCoaching = type === MEETING_TYPES.COACHING || isPrivate;

  const initialMeetingPayload = {
    endTimeInMillis: parseInt(endTime, 10),
    frequency: frequency ?? ONE_TIME_MEETING,
    location,
    startTimeInMillis: parseInt(startTimeInMillis, 10),
    title,
    isCoaching,
    questionIds,
  };

  const meetingPayload = usingNylas ? { timezone, ...initialMeetingPayload } : initialMeetingPayload;

  if (daysOfWeek?.length) {
    meetingPayload.daysOfWeek = daysOfWeek;
  }

  if (incomingMeeting && !isCreateHuddle) {
    meetingPayload.incomingMeeting = true;
  }

  if (eventId) {
    meetingPayload.nylasEventId = eventId;
  }

  if (teamId) {
    meetingPayload.teamId = teamId;
  }

  // ToDo: This can be moved in to the initialMeetingPayload once the old
  // create meeting logic is completely removed.
  if (isCreateHuddle) {
    meetingPayload.attendeeIds = attendees;
    meetingPayload.meetingType = isCoaching ? MEETING_FACTORY_TYPES.COACHING : MEETING_FACTORY_TYPES.TEAM_MEETING;
  } else {
    meetingPayload.attendeeId = attendees;
  }

  return meetingPayload;
};

// This is only going to be used for the list call
export const normalizePastMeetingFromServer = ({ state = {}, data }) => {
  const prevMeeting = data.previousSession;
  let meeting;

  const {
    attendeeUid, ...meetingData
  } = prevMeeting;

  // isCoaching is always applied at the top object level from Sagas
  const {
    isCoaching,
  } = data;

  // We want meeting that are no longer occuring
  if (data.nextSession && (data.nextSession.id || data.nextSession.startTimeInMillis)) {
    return null;
  }

  // Maintain existing data if it exists, but overwrite
  const existingData = state.data && state.data[prevMeeting.id];

  if (existingData) {
    meeting = {
      ...existingData,
      ...meetingData,
    };
  } else {
    meeting = meetingData;
  }

  // Normalize attendee namiing and data - This should be broken out into a people workflow later on
  let actualAttendees;
  if (data.attendees || prevMeeting.attendees) {
    actualAttendees = data.attendees ? data.attendees : prevMeeting.attendees;
  } else if (data.attendee || prevMeeting.attendee) {
    actualAttendees = data.attendee ? data.attendee : prevMeeting.attendees;
  } else {
    // Log wtfery
  }

  actualAttendees = normalizeAttendeesFromServer({
    data: actualAttendees,
  });

  if (meeting.agendaItems) {
    meeting.agendaItems = normalizeAgendaItemsFromServer({
      data: meeting.agendaItems,
    });
  }

  // For Coachings, filter out meetings if the other user in inActive
  if (isCoaching && actualAttendees.length === 0) {
    return null;
  }

  meeting.attendees = actualAttendees;

  // Organizer lives in different places depending on the payload
  if (data.organizer) {
    meeting.organizer = data.organizer;
  }

  // Coaching calls only contain the other person, but meeting data includes you. Except for details calls, which always includes you, so we need some wiggle room here.
  meeting.isCoaching = isCoaching;

  return meeting;
};
