import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useMemo, useEffect } from 'react';
import { drawerInputLabelText } from '~Deprecated/ui/styles/colors';
import DrawerInput from '~Common/V3/components/DrawerInput';
import DrawerDropdown from '~Deprecated/ui/components/Inputs/DrawerDropdown';
import { noop } from '~Deprecated/utils';
import { forMobile } from '~Common/styles/mixins';

const styles = {
  configContainer: css`
    display: flex;
    justify-content: flex-start;
    margin-top: 1.25rem;
  `,
  inputDisabledWrapper: css`
    opacity: .5;
  `,
  input: css`
    background: rgba(245, 245, 247, 0.6);
    display: flex;
    align-items: flex-end;
    padding: 7px 16px;
    border-radius: 8px;
    max-width: 20%;
    margin-right: .625rem;

    & label{
      font-size: 12px;
      font-weight: 400;
    }
    ${forMobile(`
      padding: .5rem;
    `)}

  `,
  disabledInput: css`
    background: transparent;
  `,
  borderlessInput: css`
    border: unset;
    padding: 0;

    input {
      padding-bottom: 0;
      font-size: 14px;
      padding-top: 0;
    }
  `,
  label: css`
    color: ${drawerInputLabelText};
    margin-bottom: 0;
    font-weight: bold;
    min-width: 20px;
    margin-left: 0.5rem;
    padding-bottom: 0.2rem;

    ${forMobile(`
      white-space: break-spaces;
    `)}
  `,
  borderlessSelect: css`
    padding: 0 !important;
    border: unset !important;

    .MuiSelect-root {
      border: unset;
      padding-top: 0;
      padding-bottom: 0.2rem;
    }
    .MuiInputLabel-root{
      font-size: 12px;
      font-weight: 400;
    }
    .MuiInput-input > div{
      font-size:14px;
    }
  `,
  labelLowercase: css`
    text-transform: none !important;

    h6 {
      text-transform: none;
    }
  `,
};

const View = ({
  onMinLabelChange,
  onMaxLabelChange,
  onMaxValueChange,
  maxValueOptions,
  minLabel,
  maxLabel,
  maxValue,
}) => (
  <div css={styles.configContainer}>
    <div
      css={styles.input}
    >
      <DrawerInput
        name="minLabel"
        label="Min Label"
        maxlength="25"
        initialValue={minLabel}
        css={[styles.borderlessInput, styles.labelLowercase]}
        onChange={(e) => onMinLabelChange(e.target.value)}
      />
      {/* <div css={styles.label}>= 1</div> */}
    </div>
    <div css={[styles.input, styles.inputDisabledWrapper]}>
      <DrawerInput
        name="minValue"
        label="Min Value"
        initialValue={1}
        disabled="disabled"
        css={[styles.borderlessInput, styles.labelLowercase, styles.disabledInput]}
      />
    </div>
    <div css={styles.input}>
      <DrawerInput
        name="maxLabel"
        label="Max Label"
        maxlength="25"
        initialValue={maxLabel}
        css={[styles.borderlessInput, styles.labelLowercase]}
        onChange={(e) => onMaxLabelChange(e.target.value)}
      />
    </div>
    <div css={styles.input}>
      <DrawerDropdown
        name="numScale"
        label="Max Value"
        value={maxValue}
        items={maxValueOptions}
        css={styles.borderlessSelect}
        onChange={(e) => onMaxValueChange(e.target.value)}
      />
    </div>
  </div>
);

View.propTypes = {
  onMinLabelChange: PropTypes.func,
  onMaxLabelChange: PropTypes.func,
  onMaxValueChange: PropTypes.func,
  minLabel: PropTypes.string.isRequired,
  maxLabel: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  maxValueOptions: PropTypes.arrayOf(PropTypes.objectOf({
    text: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
};

View.defaultProps = {
  onMinLabelChange: noop,
  onMaxLabelChange: noop,
  onMaxValueChange: noop,
};

const RankingPicker = ({
  minLabel, maxLabel, maxValue, setDefaultValues, ...props
}) => {
  useEffect(() => {
    if (!minLabel || !maxLabel || !maxValue) {
      setDefaultValues();
    }
  // eslint-disable-next-line
  }, []);

  const maxValueOptions = useMemo(() => [3, 4, 5, 6, 7, 8, 9, 10].map((num) => ({ text: num, value: num })), []);

  const hookProps = {
    maxValueOptions,
    minLabel,
    maxLabel,
    maxValue,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

RankingPicker.propTypes = {
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  maxValue: PropTypes.number,
  setDefaultValues: PropTypes.func,
};

RankingPicker.defaultProps = {
  minLabel: null,
  maxLabel: null,
  maxValue: null,
  setDefaultValues: noop,
};

export default RankingPicker;
