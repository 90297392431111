import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { useDraft } from '~Common/hooks/useDraft';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { produce } from 'immer';
import { toast } from '~Common/components/Toasts';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import {
  AgendaItem,
  AgendaSection,
  AgendaTopic,
  AgendaTopicComment,
  GetAgendaReturn,
} from '~Meetings/hooks/v2/useGetAgendas';
import { useShowCommentEditor } from '~Meetings/hooks/useShowAgendaEditor';

interface CommentItem {
  isPrivate: boolean,
  rank: number,
  text: string,
}

interface Context {
  id: string,
  meetingId: string,
  type: 'AGENDA_ITEM',
}

interface UpdateAgendaCommentParams {
  commentId: string,
  item: CommentItem,
  context: Context,
  eventId?: string,
}

interface UpdateAgendaCommentReturn {
  noteId: string,
}

const updateAgendaComment = ({
  commentId,
  item,
  context,
  eventId,
}: UpdateAgendaCommentParams): Promise<HttpCallReturn<UpdateAgendaCommentReturn>> => {
  const url = `/organizations/${getOrganizationId() ?? ''}/notes/${commentId}`;

  return patchApi(url, { item, context, eventId }, { 'realtime-version': '2' });
};

// Helper functions for optimstic updates
const findAgendaIndex = (agendas: AgendaItem[], id: string): number => agendas.findIndex((agenda) => agenda.id === id);
const updateComment = (comment: AgendaTopicComment, isPrivate: boolean, text: string): void => {
  // eslint-disable-next-line no-param-reassign
  comment.isPrivate = isPrivate;
  // eslint-disable-next-line no-param-reassign
  comment.text = text;
};
const updateAgendaTopic = (agendaTopic: AgendaTopic, isPrivate: boolean, text: string, commentId: string): void => {
  const commentIndex = agendaTopic.children.findIndex((comment) => comment.id === commentId);

  if (commentIndex !== -1) {
    updateComment(agendaTopic.children[commentIndex], isPrivate, text);
  }
};

interface UseUpdateAgendaCommentParams {
  topicId: string,
  draftKey?: string[],
  commentId: string,
  sectionId?: string,
}

export const useUpdateAgendaComment = ({
  sectionId,
  topicId,
  draftKey,
  commentId,
}: UseUpdateAgendaCommentParams): UseMutationResult<HttpCallReturn<UpdateAgendaCommentReturn>, unknown, UpdateAgendaCommentParams> => {
  const { setShowCommentEditor } = useShowCommentEditor(topicId, commentId);
  const { removeDraft } = useDraft(draftKey);

  return useMutation({
    mutationFn: updateAgendaComment,
    onMutate: async ({ item, context }) => {
      setShowCommentEditor(false);
      const { meetingId } = context;
      const { isPrivate, text } = item;
      const previousAgendas = queryClient.getQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(meetingId));

      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(meetingId) });

      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(meetingId), (originalData) => {
        if (originalData) {
          const sectionIndex = sectionId ? findAgendaIndex(originalData.response.agendas, sectionId) : -1;

          if (sectionIndex !== -1) {
            return produce(originalData, (draft) => {
              const agendaSection = draft.response.agendas[sectionIndex] as AgendaSection;
              const agendaTopicIndex = findAgendaIndex(agendaSection.children, topicId);

              if (agendaTopicIndex !== -1) {
                updateAgendaTopic(agendaSection.children[agendaTopicIndex], isPrivate, text, commentId);
              }
            });
          }
          const agendaTopicIndex = findAgendaIndex(originalData.response.agendas, topicId);

          if (agendaTopicIndex !== -1) {
            return produce(originalData, (draft) => {
              updateAgendaTopic(draft.response.agendas[agendaTopicIndex] as AgendaTopic, isPrivate, text, commentId);
            });
          }
        }

        return originalData;
      });

      return { previousAgendas };
    },
    onError: (_, { item, context }, snapshot) => {
      const { isPrivate } = item;
      toast.error(<MeetingsErrorToast />, {
        autoClose: 1500,
      });

      let agendaTopic;
      if (sectionId) {
        const agendaSection = snapshot?.previousAgendas?.response.agendas.find((agenda) => agenda.id === sectionId) as AgendaSection;
        agendaTopic = agendaSection.children.find((topic) => topic.id === topicId);
      } else {
        agendaTopic = snapshot?.previousAgendas?.response.agendas.find((topic) => topic.id === topicId) as AgendaTopic;
      }

      if (agendaTopic) {
        const comment = agendaTopic.children.find((tempComment) => tempComment.id === commentId);

        if (comment) {
          /*
            The only time the editor is available is when you are editing the text of the comment
            We reuse this endpoint for editing multiple things in the comment
            This if statement makes it so that we only reopen the editor if the request fails to update the text of the comment
            If it fails to update the visibility, then we don't want to reopen the editor
          */
          if (isPrivate === comment.isPrivate) {
            setShowCommentEditor(true);
          }
        }
      }

      queryClient.setQueryData(meetingKeys.agendaList(context.meetingId), snapshot?.previousAgendas);
    },
    onSettled: (_, __, { context }) => {
      void queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(context.meetingId) });
    },
    onSuccess: () => {
      removeDraft();
    },
  });
};
