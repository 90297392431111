import { createStore, useStore } from 'zustand';

interface GlobalSearchModalState {
  showModal: boolean,
  setShowModal: (nextValue: boolean) => void,
}

export const globalSearchModalStore = createStore<GlobalSearchModalState>()((set) => ({
  showModal: false,
  setShowModal: (nextValue: boolean) => set(() => ({ showModal: nextValue })),
}));

export function useShowGlobalSearchModal<T>(
  selector: (state: GlobalSearchModalState) => T,
  equals?: (a: T, b: T) => boolean,
): T {
  return useStore(globalSearchModalStore, selector, equals);
}
