import { useMutation } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';

import { queryClient } from '~Common/const/queryClient';

import { deleteApi, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { meetingKeys } from '~Meetings/const/queryKeys';

export const bookmarkAgendaTopic = async ({ topicId }) => postApi(`/organizations/${getOrganizationId()}/bookmarks`, {
  itemId: topicId,
});

export const unbookmarkAgendaTopic = async ({ topicId }) => deleteApi(`/organizations/${getOrganizationId()}/bookmarks`, {
  itemId: topicId,
});

export const useBookmarkAgendaTopic = () => {
  const mutation = useMutation({
    mutationFn: bookmarkAgendaTopic,
    onError: () => {
      toast.error(<MeetingsErrorToast />, { autoClose: 1500 });
    },
    onSuccess: (data, variables) => {
      const { huddleId: id } = variables;

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', id] });
      queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(id) });
    },
  });

  return mutation.mutate;
};

export const useUnbookmarkAgendaTopic = () => {
  const mutation = useMutation({
    mutationFn: unbookmarkAgendaTopic,
    onError: () => {
      toast.error('Our attempt to unbookmark this topic has failed.');
    },
    onSuccess: (_, variables) => {
      const { huddleId: id } = variables;

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', id] });
      queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(id) });
    },
  });

  return mutation.mutate;
};
