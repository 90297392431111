import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { queryClient } from '~Common/const/queryClient';
import { deleteApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { MEETING_FACTORY_TYPES } from '~Meetings/const/meetingTypes';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { navigateAction } from '~Deprecated/actions/navigate';
import { MEETING_ROUTE, COACHING_ROUTE } from '~Common/const/routes';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { homeQueryKeys } from '~Home/hooks/queryKeys';

const skipMeeting = ({ id }) => {
  const serverUrl = `/organizations/${getOrganizationId()}/huddles/${id}`;

  return deleteApi(serverUrl, {
    id,
  });
};

export const useSkipMeeting = ({ template, nextHuddleId }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const { data: peopleData } = usePeopleQuery({});

  const mutation = useMutation({
    mutationFn: skipMeeting,
    onMutate: () => {
      toastId.current = toast.info('Skipping this meeting, but keeping the next in the series.', { autoClose: true });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: <MeetingsErrorToast />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async (data, variables) => {
      toast.update(toastId.current, {
        render: 'This meeting has been skipped. Enjoy the rest of your day.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const {
        nextHuddleId: nextHuddleIdFromResponse,
      } = data.response;

      const nextHuddleIdToUse = nextHuddleId ?? nextHuddleIdFromResponse;

      const {
        id, lastState,
      } = variables;

      const {
        factoryType, organizer, attendees,
      } = lastState;

      const currentUser = getOrganizationUserId();

      dispatch(
        popDrawerAction({ drawerName: template.name }),
      );

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles'] }),
        queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] }),
        queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId()) }),
      ]);

      if (factoryType === MEETING_FACTORY_TYPES.COACHING) {
        dispatch({
          type: 'GET_FULL_TIMELINE',
          payload: {
            page: 1,
            count: 100,
            filters: 'COACHING,FEEDBACK,CONTENT',
            relatedType: 'USER',
            relatedId: currentUser === organizer ? peopleData[attendees[0]].userId : peopleData[organizer].userId,
            coachingId: nextHuddleIdToUse,
            pageType: MEETING_FACTORY_TYPES.COACHING,
          },
        });

        dispatch(
          navigateAction({
            pathname: COACHING_ROUTE,
            search: {
              orgId: getOrganizationId(),
              coachingId: nextHuddleIdToUse,
              userIdFortheCoach: currentUser === organizer ? peopleData[attendees[0]].userId : peopleData[organizer].userId,
            },
          }),
        );
      } else {
        dispatch({
          type: 'GET_FULL_TIMELINE',
          payload: {
            page: 1,
            count: 100,
            filters: MEETING_FACTORY_TYPES.TEAM_MEETING,
            relatedType: MEETING_FACTORY_TYPES.TEAM_MEETING,
            relatedId: id,
            meetingId: nextHuddleIdToUse,
            pageType: MEETING_FACTORY_TYPES.TEAM_MEETING,
          },
        });

        dispatch(
          navigateAction({
            pathname: MEETING_ROUTE,
            search: {
              orgId: getOrganizationId(),
              meetingId: nextHuddleIdToUse,
            },
          }),
        );
      }
    },
  });

  return mutation.mutate;
};
