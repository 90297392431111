import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsMaximize, faArrowsMinimize } from '@fortawesome/pro-light-svg-icons';

import Timeline from '~Meetings/components/details/Timeline';
import { palette } from '~Common/styles/colors';
import TimelineLegend from '~Meetings/components/details/TimelineLegend';
import MeetingFeedback from '~Deprecated/components/Meetings/MeetingFeedback';
import { MEETING_TYPES } from '~Meetings/const/meetingTypes';
import MeetingContent from '~Deprecated/components/Meetings/MeetingContent';
import ThematicGoal from '~Meetings/components/details/goals/ThematicGoal';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import DefiningObjectives from '~Meetings/components/details/goals/DefiningObjectives';
import StandardOperatingObjectives from '~Meetings/components/details/goals/StandardOperatingObjectives';
import { MeetingPDP } from '~DevelopmentPlan/components/Shared/MeetingPDP';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useHuddleDataLoader } from '~Meetings/hooks/useHuddleDataLoader';
import OldTeamGoals from '~Goals/OldGoals/OldTeamGoals';
import OldCoachingGoals from '~Goals/OldGoals/OldCoachingGoals';
import CoachingGoals from '~Meetings/components/details/goals/CoachingGoals';
import TeamGoals from '~Meetings/components/details/goals/TeamGoals';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import TopSummary from './TopSummary';
import MeetingActionItems from './MeetingActionItems';
import MeetingRightPanel from './MeetingRightPanel';
import MeetingAgenda from './MeetingAgenda';
import SkippedMeetingBanner from './SkippedMeetingBanner';

const topRowHeight = 140;
const topRowBottomMargin = 0;

const styles = {
  conatiner: css({}),
  layoutRow: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexGrow: 1,
  }),
  detailsBody: css({
    padding: '0.3rem 2rem',
  }),
  leftCol: css({
    justifyContent: 'flex-start',
    width: '100%',
    marginRight: '1.5rem',
  }),
  rightCol: css({
    justifyContent: 'flex-start',
    flexBasis: 300,
    flexShrink: 0,
    flexGrow: 1,
  }),
  topRow: css({
    marginBottom: `${topRowBottomMargin}px`,
    transition: 'width 0.5s',
    width: '100%',
  }),
  bottomRow: css({}),
  topSummary: css({
    height: '5rem',
    marginBottom: '1rem',
  }),
  void: (isOpen) => css({

  }, isOpen && ({
    width: '0px !important',
    marginRight: '0px !important',
  })),
  timeline: (isOpen) => css({
    overflowX: 'hidden',
  }, isOpen && ({
    width: '100% !important',
  })),
  agenda: (isOpen, hasTimeline) => css({
    position: 'relative',
    transition: 'top 0.5s',
    minWidth: 0,
  }, hasTimeline && ({
    top: `-${topRowHeight + topRowBottomMargin}px`,
  }), isOpen && ({
    top: '0px !important',
  })),
  panel: (hasTimeline) => css({

  }, hasTimeline && ({
    marginTop: '1.5rem',
  }), !hasTimeline && ({
    position: 'relative',
  })),
  expanderDiv: css({
    width: '100%',
    marginBottom: '.625rem',
    paddingBottom: '.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: `.0625rem solid ${palette.neutrals.gray300}`,
    fontSize: '.875rem',
    fontWeight: 600,
  }),
  expandBtn: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '.3125rem 0',
    height: '1.375rem',
    width: '1.375rem',
    background: palette.neutrals.white,
    border: 0,
    borderRadius: '.125rem',
  }),
  expanderIcon: css({
    color: palette.neutrals.gray500,
  }),
  timelineData: css({
    width: '100%',
    marginLeft: '.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  items: css({
    marginBottom: '1.5rem',
  }),
};

// eslint-disable-next-line react/prop-types
const Expander = ({ onClick, isOpen }) => (
  <div css={styles.expanderDiv}>
    <button
      onClick={onClick}
      css={styles.expandBtn}
      data-test-id="meetingsExpandTimelineButton"
    >
      <FontAwesomeIcon
        icon={isOpen ? faArrowsMinimize : faArrowsMaximize}
        css={styles.expanderIcon}
      />
    </button>
    <div css={styles.timelineData}>
      <div>
        <span>
          Timeline
        </span>
      </div>
      {isOpen && (
        <TimelineLegend />
      )}
    </div>
  </div>
);

const View = ({
  id,
  className,
  onExpand,
  isOpen,
  frequency,
  showTimeline,
  isLoading,
  isCoaching,
  teamId,
  areTeamMeetingsEnabled,
  isTableGroupEnabled,
  otherUserInMeeting,
  canSeePdps,
  isSkipped,
  cascadingGoals,
  meetingFactoryId,
  ...props
}) => (
  <div
    css={styles.container}
    className={className}
  >
    <TopSummary
      css={styles.topSummary}
      {...props}
    />
    <div css={styles.detailsBody}>
      {isSkipped && (
        <SkippedMeetingBanner />
      )}
      <div
        css={styles.layoutRow}
      >
        <div
          css={[styles.leftCol, styles.topRow, styles.void(isOpen)]}
        />
        <div
          css={[styles.rightCol, styles.topRow, styles.timeline(isOpen)]}
        >
          {showTimeline && (
            <Timeline
              renderExpander={() => (
                <Expander
                  onClick={onExpand}
                  isOpen={isOpen}
                />
              )}
              id={id}
              meetingFactoryId={meetingFactoryId}
              {...props}
            />
          )}
        </div>
      </div>
      <div
        css={styles.layoutRow}
      >
        <div
          css={[styles.leftCol, styles.bottomRow, styles.agenda(isOpen, showTimeline)]}
        >
          <>
            { areTeamMeetingsEnabled && teamId && (
              <>
                {isTableGroupEnabled && (
                  <>
                    <ThematicGoal
                      teamId={teamId}
                      huddleId={id}
                      css={styles.items}
                    />

                    <DefiningObjectives
                      teamId={teamId}
                      huddleId={id}
                      css={styles.items}
                    />

                    <StandardOperatingObjectives
                      teamId={teamId}
                      huddleId={id}
                      css={styles.items}
                    />
                  </>
                )}

                {!isTableGroupEnabled && (
                  <>
                    {cascadingGoals && (
                      <TeamGoals
                        teamId={teamId}
                        huddleId={id}
                        css={styles.items}
                      />
                    )}
                    {!cascadingGoals && (
                      <OldTeamGoals
                        teamId={teamId}
                        huddleId={id}
                        css={styles.items}
                      />
                    )}
                  </>
                )}
              </>
            )}

            { isCoaching && (
              <>
                {canSeePdps && (
                  <MeetingPDP
                    otherUserInMeeting={otherUserInMeeting}
                  />
                )}
                {cascadingGoals && (
                  <CoachingGoals
                    css={styles.items}
                    meetingFactoryId={meetingFactoryId}
                  />
                )}
                {!cascadingGoals && (
                  <OldCoachingGoals
                    id={id}
                    css={styles.items}
                    {...props}
                  />
                )}
              </>
            )}

            <MeetingAgenda
              huddleId={id}
              {...props}
              css={styles.items}
            />

            <MeetingActionItems
              id={id}
              {...props}
              css={styles.items}
            />

            { isCoaching && (
              <>
                <MeetingFeedback page="COACHING" {...props} />
                <MeetingContent page="COACHING" {...props} />
              </>
            )}
          </>
        </div>
        <div
          css={[styles.rightCol, styles.bottomRow, styles.panel(showTimeline)]}
        >
          <MeetingRightPanel
            id={id}
            teamId={teamId}
            {...props}
          />
        </div>
      </div>
    </div>
  </div>
);

View.propTypes = {
  id: PropTypes.string.isRequired,
  onExpand: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isCoaching: PropTypes.bool.isRequired,
  className: PropTypes.string,
  frequency: PropTypes.string,
  showTimeline: PropTypes.bool,
  isLoading: PropTypes.bool,
  teamId: PropTypes.string,
  areTeamMeetingsEnabled: PropTypes.bool,
  isTableGroupEnabled: PropTypes.bool,
  otherUserInMeeting: PropTypes.string,
  canSeePdps: PropTypes.bool,
  isSkipped: PropTypes.bool.isRequired,
  cascadingGoals: PropTypes.bool.isRequired,
  meetingFactoryId: PropTypes.string.isRequired,
};

View.defaultProps = {
  className: '',
  frequency: '',
  showTimeline: false,
  isLoading: true,
  teamId: '',
  areTeamMeetingsEnabled: false,
  isTableGroupEnabled: false,
  otherUserInMeeting: '',
  canSeePdps: false,
};

const HuddleDetails = ({
  id,
  type,
  factoryId,
  teamId,
  frequency,
  ...props
}) => {
  const { item: meetingDetails } = useMeetingDetails({ id, type });
  const areTeamMeetingsEnabled = useFeatureFlag('webAppTgxMeetings');
  const pdpFeatureFlag = useFeatureFlag('pdpsPhaseOne');
  const cascadingGoals = useFeatureFlag('cascadingGoals');
  const { enablePdps: orgHasPdps, enableTheTable } = useOrgDetailsContext();

  const showTimeline = frequency !== 'ONE_TIME' || type === 'COACHING';
  const isCoaching = type === MEETING_TYPES.COACHING;

  const { teamId: urlTeamId } = useParams();

  const { organizer, attendeeOrgUserIds } = meetingDetails ?? { organizer: null, attendeeOrgUserIds: [] };
  const allAttendees = [organizer?.orgUserId, ...attendeeOrgUserIds];
  const otherUserInMeeting = allAttendees.find((attendee) => attendee !== getOrganizationUserId());

  const meetingTeamId = teamId || meetingDetails?.teamId || urlTeamId;

  useHuddleDataLoader({
    id,
    type,
    factoryId,
    teamId: meetingTeamId,
    otherUserInMeetingId: otherUserInMeeting,
  });

  const hookProps = {
    id,
    showTimeline,
    frequency,
    type,
    isCoaching,
    teamId: meetingTeamId,
    areTeamMeetingsEnabled,
    isTableGroupEnabled: enableTheTable,
    otherUserInMeeting,
    canSeePdps: orgHasPdps && pdpFeatureFlag,
    isSkipped: meetingDetails?.isSkipped,
    cascadingGoals,
    meetingFactoryId: factoryId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

HuddleDetails.propTypes = {
  id: PropTypes.string.isRequired,
  factoryId: PropTypes.string,
  teamId: PropTypes.string,
  type: PropTypes.string,
  frequency: PropTypes.string,
  isLoading: PropTypes.bool,
};

HuddleDetails.defaultProps = {
  factoryId: '',
  teamId: '',
  type: '',
  frequency: '',
  isLoading: true,
};

export { View };
export default HuddleDetails;
