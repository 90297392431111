import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';

export interface BackendEnum {
  id: number, // 2 ^ index
  code: string,
  description: string,
}

interface SurveySysTypes {
  Frequencies: Record<string, BackendEnum>,
  DaysOfMonth: Record<string, BackendEnum>,
  DaysOfWeek: Record<string, BackendEnum>,
  QuestionCategories: Record<string, BackendEnum>,
  QuestionTypes: Record<string, BackendEnum>,
}

const surveyTypesQuery = async (): Promise<HttpCallReturn<SurveySysTypes>> => {
  const organizationId = getOrganizationId() ?? '';
  const URL = `/organizations/${organizationId}/surveys/systypes`;

  return getApi<SurveySysTypes>(URL);
};

interface UseGetSurveyTypesProps {
  queryKey?: string[],
}

interface UseGetSurveyTypesReturn extends Partial<SurveySysTypes> {
  result: UseQueryResult<HttpCallReturn<SurveySysTypes>, unknown>,
  surveyTypes?: SurveySysTypes,
  isLoading: boolean
}

export const useGetSurveyTypes = ({
  queryKey = [getOrganizationId() ?? '', 'survey', 'types'],
}: UseGetSurveyTypesProps): UseGetSurveyTypesReturn => {
  const result = useQuery({
    queryKey,
    queryFn: surveyTypesQuery,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const surveyTypes = result?.data?.response;

  return {
    result,
    surveyTypes,
    isLoading: result.isLoading,
    ...surveyTypes,
  };
};
