import UserInfo from '../../UserInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';

/**
 *
 * @param {object} props
 */
const OrgCard = (props) => {
  const {
    current,
    setSelectedUser,
    isCurrent,
  } = props;

  // const [ expanded, setExpanded ] = useState(false);
  const handleDropdown = (e) => {
    e.preventDefault();
    props.setExpanded && props.setExpanded(!props.expanded);
    setSelectedUser && setSelectedUser(current.userId);
  };

  return (
    <div className="org-card-container">
      <div className={isCurrent ? 'org-card-header-current' : 'org-card-header'}>
        { isCurrent ? 'You' : current.department }
&nbsp;
      </div>
      <div className="org-card-content">
        <div className="org-card-user-image">
          <div className="org-card-wrapper">
            <UserInfo
              users={[{ ...current, id: current.userId }]}
              additionalClasses="profile-image"
              largeLogo
              showTooltip={false}
            />

            {/* <div className='org-card-avatar'> */ }
            {/*      {[current.firstName, current.lastName].filter(e => e).map(e => e[0]).join('').toUpperCase()} */ }
            {/*  </div> */ }
            {/*  /!* <img className='org-card-avatar org-card-image' src={} alt={} /> *!/ */ }
          </div>
        </div>
        <div className="org-card-emp-name">
          { [current.firstName, current.lastName].filter((e) => e).join(' ') }
        </div>
        <div className="org-card-emp-jobtitle">
          { current.jobTitle }
        </div>
        <div />
      </div>
      <div className="org-card-footer">
        { current.directsCount
          ? (
            <button onClick={handleDropdown}>
              <span className="org-btn-wrapper">
                { current.directsCount }
                { ' ' }
                <FontAwesomeIcon size="1x" icon={props.expanded ? faChevronDown : faChevronUp} />
              </span>
              <div className="org-card-btn-ripple" />
              <div className="org-card-btn-overlay" />
            </button>
          )
          : <div />}
      </div>
    </div>
  );
};

export default OrgCard;
