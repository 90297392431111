import { uniqBy } from 'lodash';
import { GridSelectionModel } from '@mui/x-data-grid';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import {
  ManagerReviewStatuses,
  ParticipantTypeEnum, RadioOptionsShape, ReOpenReviewOptionsValues, Review, ReviewCycleDetail,
  ReviewCycleStatusEnum,
  ReviewStatusEnum, RevieweeReviewStatuses, SecondaryManagerReviewStatuses,
  revieweeCompletedExcludedStatuses, revieweeNotStartedExcludedStatuses,
  reviewerCompletedExcludedStatuses, reviewerNotStartedExcludedStatuses,
  secondaryCompletedExcludedStatuses, secondaryNotStartedExcludedStatuses,
} from './types';
import { MenuItem } from '../Shared/ActionMenu';
import { ReviewTemplate } from '../templates/useReviewTemplates';

export const getReviewIDs = (reviewCycle: ReviewCycleDetail | undefined): string[] => {
  const reviewIDs = reviewCycle?.reviews.map((review) => review.uid);
  return reviewIDs ?? [];
};

export const getOnlyFinishedReviews = (activeReviewCycle: ReviewCycleDetail | undefined, reviewIDs: GridSelectionModel): string[] => {
  const filteredIDs = activeReviewCycle?.reviews
    .filter((review) => reviewIDs.includes(review.uid)
      && review.participants.some((participant) => participant.statusEnum >= ReviewStatusEnum.Submitted)) // Includes Finished and Submitted
    .map((review) => review.uid);
  return filteredIDs ?? [];
};

export const convertDataToMenuItemShape = (data: MenuItem[]):MenuItem[][] => {
  const uniqueData = uniqBy(data, 'text');
  const array = [uniqueData];

  return array;
};

export const returnParticipantNextStep = (review: Review, participantTypeEnum: ParticipantTypeEnum): string => {
  let participantNextStep = '';
  if (participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer) {
    participantNextStep = review.secondaryReviewerStatus;
  } else if (participantTypeEnum === ParticipantTypeEnum.Reviewer) {
    participantNextStep = review.reviewerStatus;
  } else if (participantTypeEnum === ParticipantTypeEnum.Reviewee) {
    participantNextStep = review.revieweeStatus;
  }

  return participantNextStep;
};

export const getParticipantTypeName = (type: ParticipantTypeEnum): string => {
  let name = 'N/A';

  if (type === ParticipantTypeEnum.Reviewer) {
    name = 'Reviewer';
  } else if (type === ParticipantTypeEnum.SecondaryReviewer) {
    name = 'Secondary Reviewer';
  } else if (type === ParticipantTypeEnum.Reviewee) {
    name = 'Reviewee';
  }

  return name;
};

interface dirtyFieldsProps {
  stringResponse?: boolean;
  numericResponse?: boolean;
}

export const checkDirtyFields = (dirtyFields: dirtyFieldsProps, keyName: string): boolean => Object.keys(dirtyFields).some((key) => key === keyName);

export const conformToTemplateCycle = <T extends ReviewTemplate>(obj: T, keysToRemove: (keyof T)[]): Omit<T, keyof typeof keysToRemove> => {
  const newObj = { ...obj };
  keysToRemove.forEach((key) => {
    delete newObj[key];
  });
  return newObj as Omit<T, keyof typeof keysToRemove>;
};

interface buildRadioOptionsReturnProps {
  allReviewsCompleted: boolean;
  someReviewsCompleted: boolean;
  radioOptions: RadioOptionsShape[];
}

export const buildRadioOptions = (cycle: ReviewCycleDetail): buildRadioOptionsReturnProps => {
  const allReviewsCompleted = cycle.completionPct === 100;
  const cycleIsClosed = cycle.statusEnum === ReviewCycleStatusEnum.Finished;

  const someReviewsCompleted = cycle.reviews.some((review) => {
    const { reviewerStatus, secondaryReviewerStatus, revieweeStatus } = review;

    if (FlaggedEnum(cycle.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer)) {
      return (
        !revieweeNotStartedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
          || !reviewerNotStartedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
          || !secondaryNotStartedExcludedStatuses.includes(secondaryReviewerStatus as SecondaryManagerReviewStatuses)
      );
    }
    return (
      !revieweeNotStartedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
        || !reviewerNotStartedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
    );
  });

  const radioOptions = [{
    name: 'All Reviews',
    value: ReOpenReviewOptionsValues.All,
    tooltip: 'Reopen all submitted and signed-off reviews',
  }];

  if (someReviewsCompleted && !allReviewsCompleted) {
    const completedReviewOption = {
      name: 'Completed Reviews',
      value: ReOpenReviewOptionsValues.Completed,
      tooltip: 'Reopen all reviews that were signed-off by all parties',
    };

    const incompleteReviewOption = {
      name: cycleIsClosed ? 'Incomplete Reviews' : 'Submitted Reviews',
      value: ReOpenReviewOptionsValues.Submitted,
      tooltip: 'Any review submitted but not signed off.',
    };

    radioOptions.push(completedReviewOption, incompleteReviewOption);
  }
  if (someReviewsCompleted) {
    radioOptions.push({
      name: 'Select Reviews',
      value: ReOpenReviewOptionsValues.Select,
      tooltip: 'Select the reviews you want to reopen.',
    });
  }

  return {
    allReviewsCompleted,
    someReviewsCompleted,
    radioOptions,
  };
};

interface getReopenReviewIdsReturnProps {
  incompleteReviewIds: string[];
  allReviewIDs: string[];
  notStartedReviewIds: string[];
  completeReviewIds: string[];
}

export const getReopenReviewIds = (cycle: ReviewCycleDetail): getReopenReviewIdsReturnProps => {
  const completeReviewIds = cycle.reviews
    .filter((review) => {
      const { reviewerStatus, secondaryReviewerStatus, revieweeStatus } = review;
      if (FlaggedEnum(cycle.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer) && review.participants.length > 2) {
        return (
          revieweeCompletedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
          || reviewerCompletedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
          || secondaryCompletedExcludedStatuses.includes(secondaryReviewerStatus as SecondaryManagerReviewStatuses)
        );
      }
      return (
        revieweeCompletedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
        || reviewerCompletedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
      );
    })
    .map((review) => review.uid);
  const incompleteReviewIds = cycle.reviews
    .filter((review) => {
      const { reviewerStatus, secondaryReviewerStatus, revieweeStatus } = review;
      if (FlaggedEnum(cycle.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer) && review.participants.length > 2) {
        return (
          !revieweeCompletedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
          || !reviewerCompletedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
          || !secondaryCompletedExcludedStatuses.includes(secondaryReviewerStatus as SecondaryManagerReviewStatuses)
        );
      }
      return (
        !revieweeCompletedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
        || !reviewerCompletedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
      );
    })
    .map((review) => review.uid);
  const notStartedReviewIds = cycle.reviews
    .filter((review) => {
      const { reviewerStatus, secondaryReviewerStatus, revieweeStatus } = review;
      if (FlaggedEnum(cycle.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer) && review.participants.length > 2) {
        return (
          !revieweeNotStartedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
            || !reviewerNotStartedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
            || !secondaryNotStartedExcludedStatuses.includes(secondaryReviewerStatus as SecondaryManagerReviewStatuses)
        );
      }
      return (
        !revieweeNotStartedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
          || !reviewerNotStartedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
      );
    })
    .map((review) => review.uid);
  const allReviewIDs = cycle.reviews.map((review) => review.uid);

  return {
    incompleteReviewIds,
    allReviewIDs,
    notStartedReviewIds,
    completeReviewIds,
  };
};
