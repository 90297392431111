import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCalendar,
  faArrowsRepeat,
  faLocationPin,
} from '@fortawesome/pro-light-svg-icons';

import {
  palette, titleBarFontColor,
} from '~Common/styles/colors';
import { withoutDesktopObject, withTruncate } from '~Common/styles/mixins';
import MeetingDetailsMenu from '~Meetings/components/MeetingDetailsMenu';
import { MEETING_TYPES } from '~Meetings/const/meetingTypes';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { useFrequencyString } from '~Meetings/hooks/useFrequencyString';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { useUpcomingHuddleByFactoryId } from '~Meetings/hooks/useHuddleFactoryForInstance';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import LocationLink from '~Deprecated/ui/components/LocationLink';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { newEditMeetingTemplate } from '~Meetings/components/drawers/EditMeeting';
import { useIsDesktopQuery } from '~Common/hooks/useMediaListener';
import Tooltip from '~Common/components/Tooltip';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import MobileWorkingGeniusMap from './MobileItems/MobileWorkingGeniusMap';
import MobileTeamName from './MobileItems/MobileTeamName';

const styles = {
  container: css({
    backgroundColor: palette.neutrals.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '1.5rem',
  }),
  leftSide: css({
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    flex: 1,
  }),
  rightSide: css({
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  }, withoutDesktopObject({
    width: '100%',
  })),
  title: css({
    marginRight: '1.5rem',
    color: titleBarFontColor,
    fontWeight: 600,
  }, withTruncate()),
  timeFrequency: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    margin: '0 1rem',
    whiteSpace: 'nowrap',
  }, withoutDesktopObject({
    margin: 0,
  }), withoutDesktopObject({
    fontSize: '.75rem',
  })),
  calendarIntegratedContainer: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
  }, withoutDesktopObject({
    fontSize: '.75rem',
  })),
  infoIconContainer: css({
    display: 'flex',
  }),
  icon: css({
    marginRight: '.375rem',
  }, withoutDesktopObject({
    height: '.75rem',
    width: '.75rem',
  })),
  location: css({
    maxWidth: '12.5rem',
  }, withTruncate(), withoutDesktopObject({
    fontSize: '.75rem',
  })),
  editButton: css({
    paddingLeft: 0,
  }),
  mobileDetailsContainer: css({
    width: '100%',
  }),
  horizontalRule: css({
    margin: '0.5rem 0',
  }),
};

const View = ({
  title,
  id,
  type,
  location,
  meetingFrequency,
  renderAttendees,
  meetingStartDateTimeString,
  isLoading,
  onEditClick,
  isLimitedAccess,
  isDesktop,
  isMeetingOrganizerOneWayCalendarIntegrated,
  isEditOptionAvailable,
  teamId,
  shouldShowWorkGeniusMap,
  ...props
}) => (
  <header
    css={styles.container}
    {...props}
  >
    <div
      css={styles.leftSide}
      className="leftSide"
    >
      {isLoading && (
        <SkeletonLoader
          variant="rect"
          width={280}
          height={30}
          renderComponent={() => (
            <div
              css={styles.skeletitle}
              className="summaryTitle"
            />
          )}
        />
      )}
      {!isLoading && (
        <>
          <h3
            css={styles.title}
            className="summaryTitle"
          >
            {title}
          </h3>
          <div
            className="menuButton"
          >
            <MeetingDetailsMenu
              id={id}
              type={type}
            />
          </div>
        </>
      )}
    </div>

    {renderAttendees?.()}

    {isEditOptionAvailable && !isDesktop && (
      <LeadrButton
        css={styles.editButton}
        textButtonColor={palette.brand.indigo}
        onClick={onEditClick}
        variant="text"
      >
        Edit
      </LeadrButton>
    )}

    <div
      css={styles.rightSide}
      className="rightSide"
    >
      <div
        css={styles.timeFrequency}
        className="timeFrequency"
      >
        <FontAwesomeIcon
          css={styles.icon}
          icon={faCalendar}
        />
        {isLoading && (
          <SkeletonLoader
            variant="rect"
            width={150}
            height={30}
            renderComponent={() => (
              <div
                css={styles.skeletitle}
                className="summaryTitle"
              />
            )}
          />
        )}
        {!isLoading && (
          <div>{meetingStartDateTimeString}</div>
        )}
      </div>
      {!isLoading && isMeetingOrganizerOneWayCalendarIntegrated && (
        <div css={styles.calendarIntegratedContainer}>
          <Tooltip
            content="The meeting organizer has this meeting integrated with their calendar.
            To edit the meeting, open the three-dot menu and click 'View External Calendar'."
          >
            <div css={[styles.icon, styles.infoIconContainer]}>
              <FontAwesomeIcon
                css={styles.icon}
                icon={faCircleInfo}
              />
            </div>
          </Tooltip>
          <div>Calendar Integrated</div>
        </div>
      )}
      <div
        css={styles.timeFrequency}
        className="timeFrequency"
      >
        <FontAwesomeIcon
          css={styles.icon}
          icon={faArrowsRepeat}
        />
        {isLoading && (
          <SkeletonLoader
            variant="rect"
            width={150}
            height={30}
            renderComponent={() => (
              <div
                className="summaryTitle"
              />
            )}
          />
        )}
        {!isLoading && (
          <div>{`${meetingFrequency}`}</div>
        )}
      </div>
      {(isLoading || location) && (
        <div
          css={styles.timeFrequency}
          className="timeFrequency"
        >
          <FontAwesomeIcon
            css={styles.icon}
            icon={faLocationPin}
          />
          {isLoading && (
            <SkeletonLoader
              variant="rect"
              width={150}
              height={30}
              renderComponent={() => (
                <div
                  className="summaryTitle"
                />
              )}
            />
          )}
          {!isLoading && (
            <div css={styles.location}>
              <LocationLink meetingLocation={location} />
            </div>
          )}
        </div>
      )}
      {!isDesktop && (teamId || shouldShowWorkGeniusMap) && (
        <div css={styles.mobileDetailsContainer}>
          <hr css={styles.horizontalRule} />
          {teamId && (
            <MobileTeamName teamId={teamId} huddleId={id} />
          )}
          {shouldShowWorkGeniusMap && (
            <MobileWorkingGeniusMap />
          )}
        </div>
      )}
    </div>
  </header>
);

View.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meetingFrequency: PropTypes.string.isRequired,
  meetingStartDateTimeString: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  renderAttendees: PropTypes.func,
  location: PropTypes.string,
  onEditClick: PropTypes.func.isRequired,
  isLimitedAccess: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMeetingOrganizerOneWayCalendarIntegrated: PropTypes.bool,
  isEditOptionAvailable: PropTypes.bool,
  teamId: PropTypes.string,
  shouldShowWorkGeniusMap: PropTypes.bool,
};

View.defaultProps = {
  isLoading: true,
  renderAttendees: null,
  location: '',
  isLimitedAccess: true,
  isDesktop: true,
  isMeetingOrganizerOneWayCalendarIntegrated: false,
  isEditOptionAvailable: true,
  teamId: '',
  shouldShowWorkGeniusMap: false,
};

const TopSummary = ({
  renderAttendees,
  teamId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { timezone } = useTimezone();
  const { meetingId, coachingId } = useUpcomingHuddleByFactoryId();
  const { isLimitedAccess } = useUserPermissions();
  const isDesktop = useIsDesktopQuery();
  const id = meetingId || coachingId;
  const type = meetingId ? MEETING_TYPES.MEETING : MEETING_TYPES.COACHING;

  const { item: meetingDetails, isLoading } = useMeetingDetails({ id, type }) ?? {};

  const { startTimeInMillis, title, location } = meetingDetails ?? {};

  const meetingFrequency = useFrequencyString({ ...meetingDetails, includeTime: false });

  const meetingStartDateTimeString = useMemo(() => (
    moment.tz(startTimeInMillis, timezone).format('MMMM Do, YYYY @ h:mm a')
  ), [startTimeInMillis, timezone]);

  const onEditClick = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...newEditMeetingTemplate,
        args: {
          id,
          type,
        },
      },
    }));
  }, [dispatch, id, type]);

  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);

  const hookProps = {
    id,
    type,
    location,
    meetingFrequency,
    title,
    meetingStartDateTimeString,
    isLoading: showSkeletonLoaders,
    renderAttendees,
    onEditClick,
    isLimitedAccess,
    isDesktop,
    isMeetingOrganizerOneWayCalendarIntegrated: meetingDetails?.calendarIntegration?.isMeetingOrganizerOneWayCalendarIntegrated,
    teamId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

TopSummary.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  renderAttendees: PropTypes.func,
  teamId: PropTypes.string,
};

TopSummary.defaultProps = {
  renderAttendees: null,
  teamId: '',
};

export { View };
export default TopSummary;
