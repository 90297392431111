import moment from 'moment-timezone';

export const isImpersonating = (): boolean => !!window.sessionStorage.getItem('sessionId');

export const getStorageItem = (key: string, useSessionStorage = true): string | null => {
  if (useSessionStorage && isImpersonating()) {
    return window.sessionStorage.getItem(key);
  }
  return window.localStorage.getItem(key);
};

export const setStorageItem = (key: string, value: string, useSessionStorage = true): void => {
  if (useSessionStorage && isImpersonating()) {
    return window.sessionStorage.setItem(key, value);
  }
  return window.localStorage.setItem(key, value);
};

export const removeStorageItem = (key: string, useSessionStorage = true): void => {
  if (useSessionStorage && isImpersonating()) {
    return window.sessionStorage.removeItem(key);
  }
  return window.localStorage.removeItem(key);
};

export const getUserId = (): null | undefined | string => {
  const userId = getStorageItem('userId');

  // This sucks but something (i think) is setting these to 'null' incorrectly
  if (userId === 'null') {
    // Add Logging
    return null;
  }

  if (userId === 'undefined') {
    // Add Logging
    return undefined;
  }

  return userId;
};

export const setUserId = (userId: string): void => {
  setStorageItem('userId', userId);
};
export const getOrganizationId = (): null | undefined | string => {
  const orgId = getStorageItem('organizationId');
  // Yes, this is indicative of an issue elsewhere. We will get to it. Localstorage stuff is on my radar
  // ^ This aged well. Called it months ago apparently --> Mike, several months later
  if (orgId === 'null') {
    // Add Logging
    return null;
  }

  if (orgId === 'undefined') {
    // Add Logging
    return undefined;
  }

  return orgId;
};

export const getOrganizationName = (): string | null => getStorageItem('organizationName');

export const setOrganizationName = (orgName: string): void => setStorageItem('organizationName', orgName);

export const setFirstName = (firstName: string): void => setStorageItem('firstName', firstName);

export const getFirstName = (): string | null => getStorageItem('firstName');

export const setLastName = (lastName: string): void => setStorageItem('lastName', lastName);

export const setTimezone = (timezone: string): void => {
  setStorageItem('timezone', timezone);
  moment.tz.setDefault(timezone);
};

export const getUserTimezone = (): string | null => getStorageItem('timezone') || Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getLastName = (): string | null => getStorageItem('lastName');

export const getUsername = (): string => `${getFirstName() ?? ''} ${getLastName() ?? ''}`;

export const setOrganizationId = (organizationId: string): void => {
  if (organizationId === 'undefined') {
    // Try to track down workflows that set undefined organization ids
    try {
      throw new Error(`Undefined organization set at ${window.location.href}`);
    } catch (e) {
      // TODO: Log to Loggly
    }
    removeStorageItem('organizationId');
  } else {
    setStorageItem('organizationId', organizationId);
  }
};

export const setOrganizationCreatedDate = (createdDateInMillis: string): void => (
  setStorageItem('organizationCreatedDate', createdDateInMillis)
);

export const getOrganizationCreatedDate = (): string | null => getStorageItem('organizationCreatedDate');

export const setEmail = (email: string): void => (setStorageItem('email', email));

export const getEmail = (): string | null => (getStorageItem('email'));

export const setOrganizationUserId = (orgUserId: string): void => (setStorageItem('orgUserId', orgUserId));

export const getOrganizationUserId = (): string | null => (getStorageItem('orgUserId'));

export const setRedirectionUrl = (pathname: string, search: string): void => {
  setStorageItem('redirectionPathname', pathname);
  setStorageItem('redirectionSearch', search);
};

interface RedirectionUrl {
  pathname: string | null,
  search: string | null,
  url: string,
}

export const getRedirectionUrl = (): RedirectionUrl | null => {
  const pathname = getStorageItem('redirectionPathname');
  const search = getStorageItem('redirectionSearch');

  removeStorageItem('redirectionPathname');
  removeStorageItem('redirectionSearch');

  if (pathname || search) {
    return {
      pathname,
      search,
      url: `${pathname ?? ''}${search ?? ''}`,
    };
  }

  return null;
};

interface LoggedInUser {
  id: string | null | undefined,
  firstName: string,
  lastName: string,
  email: string | null,
  organizationUserId: string | null,
}

export const getLoggedInUser = (): LoggedInUser => {
  const username = getUsername().split(' ');
  return {
    id: getUserId(),
    firstName: username[0],
    lastName: username[1],
    email: getEmail(),
    organizationUserId: getOrganizationUserId(),
  };
};
