import PropTypes from 'prop-types';
import {
  useEffect, useState,
} from 'react';
import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { palette, inputBackgrounds } from '~Common/styles/colors';
import { DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerFooter from '~Common/V3/components/Drawers/DrawerFooter';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { DRAWER_STATE_SHAPE, SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';
import Froala from '~Common/V3/components/Froala';
import DrawerInput, { SIZE_OPTIONS } from '~Common/V3/components/DrawerInput';
import { toast } from '~Common/components/Toasts';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import DeleteConfirmationPopover, { useDeleteConfirmationPopover } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import { noop } from '~Deprecated/utils';
import RadioButtons from '~Common/components/Drawers/RadioButton';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import { forMobile } from '~Common/styles/mixins';
import FreeformPreview from './QuestionPreview/FreeformPreview';
import QuestionDetails from './QuestionDetails';
import RankingPicker from './RatingPicker';
import RatingPreview from './QuestionPreview/RatingPreview';
import MultipleChoice from './MultipleChoice';
import MultipleChoicePreview from './QuestionPreview/MultipleChoicePreview';
import { useGetSurveyTypes } from '../../../Hooks/useSurveyTypes';

export const newQuestionTemplate = {
  name: 'NEW_QUESTION_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};
const styles = {
  inputs: css`
    margin: 10px 0 !important;
  `,
  inputField: css`
    margin-top: 1.125rem;
    margin-bottom: .625rem;
    font-size: 1rem;
    color: ${palette.neutrals.gray900};
    background: ${inputBackgrounds.rgba};
    border-radius: .5rem;
    border:none;

    & input{
      font-weight: 600 !important;
      font-size: .875rem;
      padding: 0;
      min-height: auto;
    }
    & label{
      font-size: .75rem;
      color: ${palette.neutrals.gray500};
      width: 100%;
      background: ${inputBackgrounds.hex};
      border-radius: .5rem;
      font-weight: 400;
    }
  `,
  label: css`
    text-transform: none !important;
    font-size: .75rem;
    color: ${palette.neutrals.gray500};

    h6 {
      text-transform: none !important;
    }
  `,
  editor: css`

  & .fr-element.fr-view p{
    font-size: .875rem !important;
  }
  `,
  previewContainer: css`
    margin-top: 40px;
  `,
  drawerBody: css`
    margin: 1.5rem 1.5rem 0 1.5rem;
  `,
  saveButton: css`
    border-radius: 3px;
    min-width: auto;

    &[disabled]{
      cursor: not-allowed;
    }
  `,
  radioButton: css`
    ${forMobile(`
      justify-content: space-between;
    `)}
    
    & > div{
      border:2px solid ${palette.neutrals.gray100}; 
      border-radius: .5rem;
      background: rgba(245, 245, 247, 0.6);
      padding-right: .5625rem;
      margin-right:.5rem;
      padding-top:.5rem;

      &[data-checked]{
        background:#fff;
      }
      & label{
        color: ${palette.neutrals.gray800};
        font-size: .875rem;

        &:hover{
          cursor: pointer;
        }
      }
      ${forMobile(`
        margin-bottom:.5rem;
        width: calc(50% - .25rem);
        margin-right: 0;
      `)}
    }
  `,
  optionButtons: css`
    &:not(:last-of-type) {
      margin-right:.5rem;
    }
  `,
};

const View = ({
  onFormSubmit,
  closeButton,
  cancelButton,
  saveButton,
  deleteButton,
  isReadOnly,
  onQuestionTextChange,
  onDescriptionChange,
  onTypeChange,
  question,
  questionTypeOptions,
  QuestionTypes,
  setQuestion,
  drawerTitle,
  closeConfirmationPopover,
  isOpen,
  popoverId,
  anchorEl,
  onDeleteClick,
  questionType,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        title={drawerTitle}
        renderCloseButton={closeButton}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <div>
          <RadioButtons
            row
            initialValue={questionType.toString() ?? questionTypeOptions[0].value.toString()}
            onChange={onTypeChange}
            options={translateForRadioButton(questionTypeOptions)}
            css={styles.radioButton}
            width="100%"
          />
        </div>
        <DrawerInput
          name="questionText"
          size={SIZE_OPTIONS.FULL_ROW}
          label="Question Text"
          css={styles.inputField}
          value={question.question}
          disabled={isReadOnly}
          onChange={onQuestionTextChange}
          inputStyles={styles.label}
          required
        />
        <Froala
          enableEmojis
          styleOverrides={styles.editor}
          label="Description"
          name="description"
          froalaConfigProps={{
            charCounterCount: true,
            charCounterMax: 2000,
          }}
          richTextEditorProps={{
            name: 'description',
            initialValue: question.description,
            onChange: onDescriptionChange,
          }}
        />
        {questionType === QuestionTypes.ShortAnswer.id && (
        <QuestionDetails
          renderPreview={() => (
            <FreeformPreview question={question} />
          )}
        />
        )}
        {questionType === QuestionTypes.CustomScale.id && (
        <QuestionDetails
          renderOptions={() => (
            <RankingPicker
              {...question}
              onMinLabelChange={(e) => {
                setQuestion({ ...question, minLabel: e });
              }}
              onMaxLabelChange={(e) => {
                setQuestion({ ...question, maxLabel: e });
              }}
              onMaxValueChange={(e) => {
                setQuestion({ ...question, maxValue: e });
              }}
              setDefaultValues={() => {
                setQuestion({
                  ...question, minLabel: 'Worst', maxLabel: 'Best', maxValue: 3,
                });
              }}
            />
          )}
          renderPreview={() => (
            <RatingPreview
              question={question}
            />
          )}
        />
        )}
        {questionType === QuestionTypes.MultipleChoice.id && (
        <QuestionDetails
          renderOptions={() => (
            <MultipleChoice
              multipleChoiceOptions={question.multipleChoiceOptions}
              setQuestion={setQuestion}
            />
          )}
          renderPreview={() => (
            <MultipleChoicePreview
              question={question}
            />
          )}
        />
        )}
        <DeleteConfirmationPopover
          closeConfirmationPopover={closeConfirmationPopover}
          open={isOpen}
          popoverId={popoverId}
          anchorEl={anchorEl}
          renderConfirmationButtons={({
            informationStyles,
            optionStyles,
            popoverButtonStyles,
          }) => (
            <DeleteConfirmationButtons
              informationStyles={informationStyles}
              optionStyles={optionStyles}
              popoverButtonStyles={popoverButtonStyles}
              onDelete={onDeleteClick}
            />
          )}
        />
      </div>
    )}
    renderFooter={() => (
      <DrawerFooter
        renderSaveButton={saveButton}
        renderCancelButton={cancelButton}
        renderDeleteButton={deleteButton}
      />
    )}
  />
);
View.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  closeButton: PropTypes.func.isRequired,
  cancelButton: PropTypes.func.isRequired,
  saveButton: PropTypes.func.isRequired,
  deleteButton: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onQuestionTextChange: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  QuestionTypes: PropTypes.func.isRequired,
  questionTypeOptions: PropTypes.objectOf().isRequired,
  onTypeChange: PropTypes.func.isRequired,
  questionKey: PropTypes.string.isRequired,
  openConfirmationPopover: PropTypes.func.isRequired,
  closeConfirmationPopover: PropTypes.func.isRequired,
  setQuestion: PropTypes.func.isRequired,
  question: PropTypes.arrayOf(SURVEY_QUESTION_SHAPE).isRequired,
  isOpen: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  popoverId: PropTypes.string,
  anchorEl: PropTypes.instanceOf(Element),
  onDeleteClick: PropTypes.func,
  initialValue: PropTypes.string.isRequired,
  drawerTitle: PropTypes.string.isRequired,
  questionType: PropTypes.number.isRequired,
};
View.defaultProps = {
  isOpen: false,
  isReadOnly: false,
  popoverId: '',
  anchorEl: null,
  onDeleteClick: noop,
};
function translateForRadioButton(sublist) {
  return sublist.map((item) => ({
    label: item.text,
    value: item.value.toString(),
  }));
}
const NewQuestionDrawer = ({
  drawerState,
  setDrawerState,
  popDrawer,
  questionKey,
  isEditing,
  drawerTitle,
  initialQuestion,
  isReadOnly,
  transitionState,
  ...props
}) => {
  const dispatch = useDispatch();
  const questions = [...((drawerState?.[questionKey]) ?? [])];
  const saveButton = () => (
    <LeadrButton
      css={styles.optionButtons}
      onClick={onSave}
      disabled={question.question === ''}
      data-test-id="surveysNewQuestionSave"
    >
      Save Question
    </LeadrButton>
  );
  const cancelButton = () => (
    <LeadrButton
      css={styles.optionButtons}
      variant="outline"
      onClick={closeDrawerClick}
      data-test-id="surveysNewQuestionCancel"
    >
      Cancel
    </LeadrButton>
  );
  const deleteButton = () => (
    <>
      {!isReadOnly && isEditing && (
        <LeadrButton
          css={styles.optionButtons}
          color="danger"
          variant="ghost"
          onClick={openConfirmationPopover}
          data-test-id="surveysNewQuestionDelete"
        >
          Delete
        </LeadrButton>
      )}
    </>
  );
  const closeButton = (closeButtonStyles) => (
    <IconButton onClick={closeDrawerClick} tooltip="Close" type="button" icon={faTimes} css={closeButtonStyles} />
  );
  const closeDrawerClick = () => {
    dispatch(popDrawer({ drawerName: newQuestionTemplate.name }));
  };
  const [clickingBack] = useState();
  useEffect(() => {
    const hasExited = transitionState === TRANSITION_STATE.EXITED;

    if (hasExited && clickingBack) {
      dispatch(pushDrawerAction({
        drawer: newQuestionTemplate,
      }));
    }
  }, [dispatch, clickingBack, transitionState]);
  const onTypeChange = (e) => {
    const int = Number(e);
    setQuestion({ ...question, type: int });
  };

  const onQuestionTextChange = (e) => {
    setQuestion({ ...question, question: e.target.value });
  };

  const onDescriptionChange = ({ value }) => {
    setQuestion({ ...question, description: value });
  };
  const onSave = () => {
    const questionText = question.question;
    const { description } = question;
    const { type } = question;
    const { multipleChoiceOptions } = question;
    const { minLabel } = question;
    const { maxLabel } = question;
    const numScale = question.maxValue;
    const typeToUse = parseInt(type, 10);

    let configuration;
    let stopSave = false;
    if (typeToUse === QuestionTypes.MultipleChoice.id) {
      if (multipleChoiceOptions?.length < 2 || multipleChoiceOptions === undefined) {
        toast.error('You must have at least 2 multiple choice options.');
        stopSave = true;
      } else {
        stopSave = false;
      }

      configuration = multipleChoiceOptions;
    } else if (typeToUse === QuestionTypes.CustomScale.id) {
      const tempConfig = {
        minLabel,
        minValue: '1',
        maxLabel,
        maxValue: numScale,
      };
      configuration = JSON.stringify(tempConfig);
    } else {
      configuration = '';
    }

    const newQuestion = {
      type: parseInt(type, 10),
      configuration,
      description,
      question: questionText,
      rank: isEditing ? question.rank : questions.length,
    };
    if (questionIndex >= 0 && isEditing) {
      questions[questionIndex] = newQuestion;
    } else {
      questions.push(newQuestion);
    }
    if (!stopSave) {
      setDrawerState({ ...drawerState, [questionKey]: questions });
      popDrawer({ drawerName: newQuestionTemplate.name });
    }
  };
  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('surveysDeleteConfirmationPopover');
  const onDeleteClick = () => {
    questions.splice(questionIndex, 1);
    const newQuestions = questions.map((tempQuestion, index) => ({ ...tempQuestion, rank: index }));
    setDrawerState({ ...drawerState, [questionKey]: newQuestions });
    popDrawer({ drawerName: newQuestionTemplate.name });
  };

  const { QuestionTypes } = useGetSurveyTypes({});

  let values = Object.values(QuestionTypes);
  values = [values[values.length - 1], ...values.slice(0, -1)];
  const questionTypeOptions = values.map((type) => ({
    value: type.id,
    text: type.description,
  }), [values]);

  const [question, setQuestion] = useState(initialQuestion ?? {
    question: '',
    description: '',
    type: QuestionTypes.MultipleChoice.id,
  });

  useEffect(() => {
    if (initialQuestion) {
      setQuestion(initialQuestion);
    }
  }, [initialQuestion]);

  const questionIndex = questions.findIndex((q) => q.rank === question?.rank);
  useEffect(() => {
    if (isEditing && questionType === QuestionTypes.MultipleChoice.id) {
      setQuestion({ ...question, multipleChoiceOptions: question.configuration });
    } else if (isEditing && questionType === QuestionTypes.CustomScale.id) {
      const { minLabel, maxLabel, maxValue } = typeof question.configuration === 'string'
        ? JSON.parse(question.configuration) : question.configuration;
      setQuestion({
        ...question, minLabel, maxLabel, maxValue,
      });
    }
  // eslint-disable-next-line
  }, [question.configuration]);

  const questionType = typeof question.type === 'number' ? question.type : question.type.key;

  const hookProps = {
    isReadOnly,
    setQuestion,
    onQuestionTextChange,
    onDescriptionChange,
    onTypeChange,
    question,
    questionTypeOptions,
    QuestionTypes,
    drawerTitle,
    closeButton,
    cancelButton,
    saveButton,
    deleteButton,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    onDeleteClick,
    popoverId,
    anchorEl,
    questionType,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

NewQuestionDrawer.propTypes = {
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  popDrawer: PropTypes.func.isRequired,
  questionKey: PropTypes.string,
  isEditing: PropTypes.bool,
  initialQuestion: SURVEY_QUESTION_SHAPE,
  isReadOnly: PropTypes.bool,
  onQuestionTextChange: PropTypes.func.isRequired,
  transitionState: PropTypes.oneOf(Object.values(TRANSITION_STATE)).isRequired,
  drawerTitle: PropTypes.string.isRequired,
};

NewQuestionDrawer.defaultProps = {
  questionKey: 'surveyQuestions',
  isEditing: false,
  initialQuestion: null,
  isReadOnly: false,
};

registerDrawer({
  templateName: newQuestionTemplate.name,
  component: NewQuestionDrawer,
});

export { View };
export default NewQuestionDrawer;
