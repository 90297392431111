import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';

export const useCareCard = (drawerArgs) => {
  const dispatch = useDispatch();

  return useCallback(({
    avatar = {}, id, orgUserId, userId,
  }) => {
    const idToUse = avatar?.id || avatar?.userId || avatar?.orgUserId || id || orgUserId || userId;
    if (idToUse) {
      dispatch(pushDrawerAction({
        drawer: {
          ...baseballCardDrawerTemplate,
          args: {
            id: idToUse,
            ...drawerArgs,
          },
        },
      }));
    }
  }, [dispatch, drawerArgs]);
};
