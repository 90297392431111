import { useDispatch } from 'react-redux';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { createIncompleteFeedbackTemplate } from '~Feedback/components/Drawers/Request/Create';

export const useCreateFromTemplate = ({
  template, rootDrawerName, onlyQuestions, existingQuestions,
}) => {
  const dispatch = useDispatch();

  const popDrawer = (payload) => {
    dispatch(popDrawerAction(payload));
  };

  const pushDrawer = (payload) => {
    dispatch(pushDrawerAction(payload));
  };

  const onCreateFromTemplate = () => {
    if (existingQuestions.length >= 25) {
      toast.error('Feedback requests are limited to 25 questions.');
      return;
    }

    let templateQuestions = template.question.map((question, index) => ({ text: question.text, rank: existingQuestions.length + index }));
    if (existingQuestions.length + templateQuestions.length > 25) {
      const remainder = 25 - existingQuestions.length;

      toast.info('Feedback requests are limited to 25 questions. Only some of the template questions have been copied.');
      templateQuestions = templateQuestions.slice(0, remainder);
    }

    const context = {
      questions: [...existingQuestions, ...templateQuestions],
    };

    if (!onlyQuestions) {
      context.text = template.title;
      context.introduction = template.description;
    }

    popDrawer({ drawerName: rootDrawerName });
    setTimeout(() => {
      pushDrawer({
        drawer: {
          ...createIncompleteFeedbackTemplate,
          args: {
            feedbackContext: context,
          },
        },
      });
    }, 500);
  };

  return { onCreateFromTemplate };
};
