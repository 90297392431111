import {
  number,
  object,
  string,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const respondTopicFormSchema = object({
  stringResponse: string().trim()
    // Saving this for possible future start
    // .test('value', (val, { createError }) => {
    //   const validation = stripHtml(val);
    //   if (validation.length === 0) {
    //     toast.error('There was an error answering. Please try again.', {
    //       type: toast.TYPE.ERROR,
    //       autoClose: 5000,
    //     });
    //     return false;
    //   }
    //   return true;
    // })
    .ensure(),
  numericResponse: number().optional().typeError('Please make sure to select an option'),
});

export const responseTopicForReview = yupResolver(respondTopicFormSchema);

export type FormValues = InferType<typeof respondTopicFormSchema>;

export interface TopicResponseDTO {
  stringResponse: string,
  numericResponse?: number,
}

export function conformToDto(data: FormValues): TopicResponseDTO {
  const { stringResponse, numericResponse } = data;
  return {
    stringResponse,
    numericResponse,
  };
}
