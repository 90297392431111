import { call, put, select } from 'redux-saga/effects';

import * as types from '../actions';
import {
  getMeetingByIdService,
  getTeamMeetingByIdService,
  removeFeedbackQuestionFromCoachingService,
} from '~Deprecated/services/meetingService';
import { GlobalAPIErrorHandler, hasError } from './utils';
import { isLoadingAction } from '~Deprecated/actions/loading/isLoading';
import { BASE_ROUTE, COACHING_LIST_UPCOMING } from '~Common/const/routes';
import { navigateAction } from '~Deprecated/actions/navigate';

/** Start Meeting Deatil Page */
export function* getMeetingById(meetingDetails, isInitialCall) {
  let { data, contextType } = meetingDetails;
  if (meetingDetails.payload && !data && !contextType) {
    data = meetingDetails.payload.data;
    contextType = meetingDetails.payload.contextType;
  }
  // meetingDetails.payload
  let apiResponse;
  let result = {};
  // const isGoalsEnabled = LDClient.variation('goals', false);
  try {
    switch (contextType) {
      case 'TEAM_MEETING':
        apiResponse = yield call(getTeamMeetingByIdService, data);
        // Note: if we need to show errors on get api call
        if (apiResponse.status !== 200) {
          // yield put({ type: types.GET_MEETING_BY_ID_ERROR, payload: { error: apiResponse.message } });
          yield put({ type: types.GET_MEETING_BY_ID_ERROR, payload: {} });
          yield put(navigateAction({
            pathname: BASE_ROUTE,
          }));
        }
        result.success = true;
        result.data = apiResponse.response.sessions;
        yield put({ type: types.GET_MEETING_BY_ID_SUCCESS, response: result });
        break;
      case 'COACHING':
        apiResponse = yield call(getMeetingByIdService, data);
        // Note: if we need to show errors on get api call
        // if(apiResponse.status !== 200) {
        //     yield put({ type: types.GET_MEETING_BY_ID_ERROR, payload: { error: apiResponse.message } });
        // }
        result = {
          success: true,
          data: apiResponse.response.sessions,
        };
        yield put({ type: types.GET_MEETING_BY_ID_SUCCESS, response: result });
        break;
      default:
        break;
    }
  } catch (error) {
    // We only want to kick back to the meetings page if the initial calls fails out, likely due to the meeting not existing
    if (isInitialCall && apiResponse === undefined) {
      yield put(navigateAction({
        pathname: COACHING_LIST_UPCOMING,
      }));
    }
    // Note: if we need to show errors on get api call
    // yield put({ type: types.GET_MEETING_BY_ID_ERROR, error });
    // yield call(GlobalAPIErrorHandler, error.message );
  } finally {
    yield put(isLoadingAction(false));
  }
}

/** Start Meeting Deatil Page */
export function* removeFeedbackQuestionFromCoaching(payload) {
  if (!payload.payload.local) {
    try {
      yield put(isLoadingAction(true));
      const meetingResponse = yield call(removeFeedbackQuestionFromCoachingService, payload.payload);
      if (yield hasError(meetingResponse)) return;
      const { status } = meetingResponse;
      const result = {};
      if (status === 200) {
        result.success = true;
        yield put({
          type: types.REMOVE_FEEDBACK_QUESTION_FROM_ARRAY,
          response: payload.payload.index,
        });
      }
    } catch (error) {
      yield call(GlobalAPIErrorHandler, error.message);
      // yield put({ type: types.UPDATE_AGENDA_ITEMS_ERROR, error });
    } finally {
      yield put(isLoadingAction(false));
    }
  }
}

/** End TeamMeetings */
