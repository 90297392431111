import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';
import { LEARNING_MAX_CONTENT_IN_PLAYLIST } from '~Learning/const/index';

const styles = {
  contentHeader: css({
    color: palette.neutrals.gray500,
    fontSize: '.625rem',
  }),
};

interface ContentListCount extends ComponentProps<'div'> {
  contentListCount: number,
}

const ContentListCount = ({
  contentListCount,
  ...props
}: ContentListCount): JSX.Element => (
  <div css={styles.contentHeader} {...props}>
    {`DRAG TO REORDER - CONTENT ${contentListCount ? `(${contentListCount} / ${LEARNING_MAX_CONTENT_IN_PLAYLIST})` : ''}`}
  </div>
);

export default ContentListCount;
