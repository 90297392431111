import { ManagedDrawer } from '~Common/const/drawers';
import { createReducer } from '../redux/reducers/index';

interface DrawerState {
  order: ManagedDrawer[],
}

const initialState: DrawerState = {
  order: [],
};

const { reducer, registerAction } = createReducer('drawers', initialState);

export {
  reducer,
  registerAction,
  initialState,
  DrawerState,
};
