import { css } from '@emotion/react';
import { useCallback, useState } from 'react';
import Input from '~Deprecated/ui/components/Inputs/Input';
import { BUTTON_COLOR, BUTTON_VARIANT } from '~Common/components/Buttons/Button';
import Button from '~Deprecated/ui/components/Buttons/Button';
import { useImpersonation } from '~Deprecated/hooks/nexus/useImpersonation';
import Loader from '~Deprecated/ui/components/Misc/Loader';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    padding: 2rem;
  `,
  button: css`
    margin-block-start: 1rem;
    width: 250px;
  `,
};

const Impersonate = () => {
  const [email, setEmail] = useState('');
  const { mutate: initiateImpersonation, isPending: isLoading } = useImpersonation();

  const impersonate = useCallback(() => {
    initiateImpersonation(email.trim());
  }, [initiateImpersonation, email]);

  return (
    <div css={styles.container}>
      <Input
        variant="outlined"
        name="impersonate"
        placeholder="Enter email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />

      <Button
        css={styles.button}
        variant={BUTTON_VARIANT.DEFAULT}
        buttonColor={BUTTON_COLOR.ADDITIVE}
        onClick={impersonate}
        disabled={isLoading}
      >
        { isLoading ? <Loader /> : 'Continue' }
      </Button>
    </div>
  );
};

export default Impersonate;
