import { SerializedStyles, css } from '@emotion/react';
import Tooltip from '~Common/components/Tooltip';

const styles = {
  image: (geniusCount: number) => css({
    width: '38px',
    height: '38px',
  }, geniusCount === 0 && css({
    filter: 'invert(87%) sepia(4%) saturate(126%) hue-rotate(201deg) brightness(88%) contrast(84%)', // gray600
  }), geniusCount === 1 && css({
    filter: 'invert(60%) sepia(7%) saturate(122%) hue-rotate(202deg) brightness(100%) contrast(92%)', // gray500
  }), geniusCount > 1 && css({
    filter: 'invert(55%) sepia(1%) saturate(1465%) hue-rotate(207deg) brightness(88%) contrast(91%)', // gray400
  })),
  imageContainer: css({
    position: 'absolute',
  }),
};

interface WorkingGeniusGearProps {
  geniusCount: number,
  geniusName: string,
  renderImage: (imageStyles: SerializedStyles) => JSX.Element,
}

const WorkingGeniusGear = ({
  geniusCount,
  geniusName,
  renderImage,
  ...props
}: WorkingGeniusGearProps): JSX.Element => (
  <Tooltip content={`${geniusCount} team member${geniusCount === 1 ? '' : 's'} ${geniusCount === 1 ? 'has' : 'have'} ${geniusName}`}>
    <div
      {...props}
      css={styles.imageContainer}
    >
      {renderImage(styles.image(geniusCount))}
    </div>
  </Tooltip>
);

export default WorkingGeniusGear;
