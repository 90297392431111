import { css } from '@emotion/react';

export const withBaseFont = () => css`
  font-family: ProximaNova !important;
  letter-spacing: 0.5px;
  font-style: normal;
`;

export const withH1Font = () => css`
  font-size: 2.5rem;
  font-weight: normal;
`;

export const withH2Font = () => css`
  font-size: 2rem;
  font-weight: normal;
`;

export const withH3Font = () => css`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const withH4Font = () => css`
  font-size: 1rem !important;
  font-weight: 600;
`;

export const withH5Font = () => css`
  font-size: .75rem;
  font-weight: 600;
`;

export const withH6Font = () => css`
  font-size: .75rem;
  font-weight: 800;
  text-transform: uppercase;
`;

/**
 * @deprecated Old UI Button, we use Proxima Nova now
 */
export const withNunitoFonts = () => css`
  font-family: "Nunito", sans-serif !important;
`;
