import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'react';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { FrontendRankedQuestion } from '~Learning/const/interfaces';
import DragAndDrop from '~Deprecated/ui/components/DragAndDrop/DragAndDrop';
import { useSingleRte, UseSingleRteReturn } from '~Common/hooks/useSingleRte';
import { useDragAndDrop } from '~Deprecated/hooks/useDragAndDrop';
import { LEARNING_MAX_QUESTIONS, NEW_QUESTION_EDITOR_ID } from '~Learning/const';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Banner from '~Common/V3/components/Banner';
import IconContents from '~Common/V3/components/Banner/IconContents';
import { useRankedListHelpers } from '~Learning/hooks/utils/useRankedListHelpers';
import QuestionInput from './QuestionInput';
import QuestionItem from './QuestionItem';

const styles = {
  reorderQuestionText: css({
    marginTop: '1.125rem',
    marginBottom: '.625rem',
    color: palette.neutrals.gray500,
    fontSize: '.625rem',
  }),
  banner: css({
    backgroundColor: hexToRGBA(palette.brand.blue, 0.10),
  }),
  booksIcon: css({
    color: palette.brand.indigo,
  }),
  innerNoQuestionsText: css({
    color: palette.neutrals.gray800,
  }),
  listContainer: css({}),
  addQuestionButton: css({
    marginTop: '.625rem',
  }),
  buttonsContainer: css({
    marginTop: '0.5rem',
  }),
  cancelAddQuestionButton: css({
    marginLeft: '.625rem',
  }),
  dragAndDrop: css({
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  }),
};

interface ViewProps extends UseSingleRteReturn {
  questions: FrontendRankedQuestion[],
  hasOpenEditor: boolean,
  onDragEnd: unknown,
  saveQuestionEdit: (questionRank: number) => void,
  onDeleteQuestion: (questionRank: number, questionId: string | undefined) => void,
  setQuestions: (questions: FrontendRankedQuestion[]) => void,
  openNewQuestionEditor: () => void,
  createQuestion: () => void,
  editorText: string,
}

const View = ({
  questions,
  hasOpenEditor,
  onDragEnd,
  setQuestions,
  editorText,
  onEditorTextChange,
  initializeEditor,
  onEditorClose,
  shouldShowEditor,
  saveQuestionEdit,
  onDeleteQuestion,
  openNewQuestionEditor,
  createQuestion,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <div css={styles.reorderQuestionText}>
      DRAG TO REORDER - QUESTIONS
    </div>
    {(!hasOpenEditor && questions.length === 0) && (
      <Banner
        css={styles.banner}
        renderContents={() => (
          <IconContents
            renderIcon={() => (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                css={styles.booksIcon}
              />
            )}
            renderText={() => (
              <div css={styles.innerNoQuestionsText}>
                No questions have been added yet. Click below to add a question.
              </div>
            )}
          />
        )}
      />
    )}
    <div css={styles.listContainer}>
      <DragAndDrop
        wrapperStyles={styles.dragAndDrop}
        isDragDisabled={hasOpenEditor}
        list={questions}
        // eslint-disable-next-line react/no-unused-prop-types
        renderItem={({ id: question }: { id: FrontendRankedQuestion }, dragHandleProps: DraggableProvidedDragHandleProps) => (
          <QuestionItem
            question={question}
            dragHandleProps={dragHandleProps}
            key={question.rank}
            onEditorTextChange={onEditorTextChange}
            initializeEditor={initializeEditor}
            saveQuestionEdit={saveQuestionEdit}
            onEditorClose={onEditorClose}
            shouldShowEditor={shouldShowEditor}
            onDeleteQuestion={onDeleteQuestion}
          />
        )}
        // @ts-expect-error TODO: Remove once DragAndDrop is typed
        onDragEnd={onDragEnd}
        dragEndProps={{
          initialArray: questions,
          movedItem: (rank: string) => questions.find((question) => question.rank.toString() === rank),
          // @ts-expect-error TODO: Remove once DragAndDrop is typed
          endFunctions: (newArray: FrontendRankedQuestion[]) => {
            const updatedQuestions = newArray.map((question, index) => ({ ...question, rank: index }));
            setQuestions(updatedQuestions);
          },
        }}
        valueToUse="rank"
      />
    </div>
    {!hasOpenEditor && questions.length < LEARNING_MAX_QUESTIONS && (
      <LeadrButton
        variant="ghost"
        size="small"
        css={styles.addQuestionButton}
        onClick={openNewQuestionEditor}
        data-test-id="learningAddQuestion"
      >
        + Add Question
      </LeadrButton>
    )}
    {shouldShowEditor(NEW_QUESTION_EDITOR_ID) && (
      <QuestionInput
        initialValue={editorText}
        onChange={(value) => onEditorTextChange(value)}
        renderButtons={() => (
          <div css={styles.buttonsContainer}>
            <LeadrButton
              size="small"
              css={styles.addQuestionButton}
              disabled={!editorText}
              onClick={createQuestion}
              data-test-id="learningAddQuestion"
            >
              Add
            </LeadrButton>
            <LeadrButton
              size="small"
              variant="ghost"
              css={styles.cancelAddQuestionButton}
              onClick={onEditorClose}
              data-test-id="learningCancelQuestion"
            >
              Cancel
            </LeadrButton>
          </div>
        )}
      />
    )}
  </div>
);

interface QuestionsListProps extends ComponentProps<'div'> {
  questions: FrontendRankedQuestion[],
  setQuestions: (questions: FrontendRankedQuestion[]) => void,
  onQuestionDeleteCallback?: (questionId: string | undefined) => void,
}

const QuestionsList = ({
  questions,
  setQuestions,
  onQuestionDeleteCallback,
  ...props
}: QuestionsListProps): JSX.Element => {
  const {
    editorText,
    shouldShowEditor,
    initializeEditor,
    onEditorTextChange,
    onEditorClose,
    hasOpenEditor,
  } = useSingleRte();

  const { onDragEnd } = useDragAndDrop();

  const saveQuestionEdit = (questionRank: number): void => {
    setQuestions(questions.map((question) => {
      if (question.rank === questionRank) {
        return {
          ...question,
          text: editorText,
        };
      }
      return question;
    }));

    onEditorClose();
  };

  const {
    getNewItemRank,
    getArrayWithoutItem,
  } = useRankedListHelpers(questions);

  const createQuestion = (): void => {
    setQuestions([...questions, { text: editorText, rank: getNewItemRank() }]);
    onEditorClose();
  };

  const onDeleteQuestion = (questionRank: number, questionId: string | undefined): void => {
    setQuestions(getArrayWithoutItem(questionRank));
    onQuestionDeleteCallback?.(questionId);
  };

  const openNewQuestionEditor = (): void => {
    initializeEditor({ editorId: NEW_QUESTION_EDITOR_ID });
  };

  const hookProps = {
    editorText,
    shouldShowEditor,
    onDragEnd,
    saveQuestionEdit,
    onDeleteQuestion,
    openNewQuestionEditor,
    createQuestion,
    initializeEditor,
    onEditorTextChange,
    onEditorClose,
    hasOpenEditor,
    questions,
    setQuestions,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default QuestionsList;
