import { useCallback, useEffect, useState } from 'react';
import { getStorageItem, removeStorageItem, setStorageItem } from '~Common/utils/localStorage';

export const useLocalStorage = <StateType>(key: string, fallbackValue: StateType): [StateType, (newState: StateType) => void, () => void] => {
  const storedState = getStorageItem(key);
  const parsedState = storedState ? JSON.parse(storedState) as StateType : fallbackValue;

  const [state, setLocalStorageState] = useState<StateType>(parsedState);

  const setState = useCallback((newState: StateType): void => {
    setStorageItem(key, JSON.stringify(newState));
    setLocalStorageState(newState);
  }, [key, setLocalStorageState]);

  const removeState = useCallback(() => {
    removeStorageItem(key);
    setLocalStorageState(fallbackValue);
  }, [key, fallbackValue]);

  useEffect(() => {
    const newStoredState = getStorageItem(key);
    const newParsedState = newStoredState ? JSON.parse(newStoredState) as StateType : fallbackValue;

    setLocalStorageState(newParsedState);
    // We only want to update state if the key has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return [
    state,
    setState,
    removeState,
  ];
};
