import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import { drawerHeaderCloseButtonColor } from '~Common/styles/colors';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { getOrganizationId } from '~Common/utils/localStorage';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { queryClient } from '~Common/const/queryClient';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import DrawerFooter from '~Common/V3/components/Drawers/DrawerFooter';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import IconButton from '~Meetings/components/buttons/IconButton';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { noop } from '~Deprecated/utils';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import DeleteModal from '~Surveys/Components/Shared/DeleteModal';
import CloseModal from '~Surveys/Components/Shared/CloseModal';
import ByQuestion from './ByQuestion';
import ByPerson from './ByPerson';
import Details from './Details';
import { useSurveyDetail } from '../../../Hooks/useSurveysList';
import { useDeleteSurvey } from '../../../Hooks/useDeleteSurvey';
import { useCloseSurvey } from '../../../Hooks/useCloseSurvey';

import { useGetSurveyTypes } from '../../../Hooks/useSurveyTypes';
import { surveyParticipantsDrawerTemplate } from '../SurveyParticipants';
import { tabStyles } from '../tabStyles';

export const createdSurveyTemplate = {
  name: 'CREATED_SURVEY_TEMPLATE',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  ...tabStyles,
  drawerBody: css({
    padding: '1rem 1.5rem',
    maxHeight: '100vh',
  }),
  skeletonContainer: css({
    padding: '1rem 1.5rem 1.5rem 1.5rem',
  }),
  cardSkeleton: css({
    minWidth: '100%',
    height: '12.5rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
  saveButton: css({
    marginRight: '.5rem',
  }),
  closeButton: css`
    flex-shrink: 0;
    color: ${drawerHeaderCloseButtonColor};
  `,
  closeSurveyButton: css({
    marginRight: '.5rem',
  }),
};

const CreatedSurveyDrawer = ({
  surveyId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { QuestionTypes } = useGetSurveyTypes({});
  const [index, setIndex] = useState(0);

  const { survey, isLoading } = useSurveyDetail({
    surveyId,
    withAnswers: true,
    withParticipants: true,
    withQuestions: true,
  });
  const getParticipantsIndex = (value) => {
    for (let i = 0; i < survey?.surveyParticipants.length; i += 1) {
      if (survey?.surveyParticipants[i].participantUid === value.uid) {
        setIndex(i);
      }
    }
  };
  const onNextClick = () => {
    if (index + 1 < survey?.surveyParticipants.length) {
      setIndex(index + 1);
    // This is place to reset the paging to the start since responded participants is at the end it makes the UI feel weird otherwise
    } else {
      setIndex(0);
    }
  };
  const closeDrawerClick = () => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawer({ drawerName: createdSurveyTemplate.name }));
  };
  const handleViewParticipants = () => {
    dispatch(pushDrawerAction({
      drawer: {
        ...surveyParticipantsDrawerTemplate,
        args: {
          getParticipantsIndex,
          surveyId,
        },
      },
    }));
  };
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(false);

  const handleDeleteModal = (value) => {
    setOpenDeleteModal(value);
  };
  const handleCloseModal = (value) => {
    setOpenCloseModal(value);
  };

  const deleteSurveyMutation = useDeleteSurvey();
  const deleteSurvey = () => {
    deleteSurveyMutation({ id: survey?.id ?? '' }, { onSuccess: onSuccessfulDeleteOrClose });
  };

  const closeSurveyMutation = useCloseSurvey();
  const closeSurvey = () => {
    closeSurveyMutation({ id: survey?.id ?? '' }, { onSuccess: onSuccessfulDeleteOrClose });
  };

  const onSuccessfulDeleteOrClose = () => {
    handleDeleteModal(false);
    handleCloseModal(false);
    dispatch(popDrawer({ drawerName: createdSurveyTemplate.name }));
    // Honestly, I see survey and surveys used all over, I have no idea what it is supposed to be so we do both!!
    // Also, invalidate isn't working for some reason so we are just canceling the queries
    queryClient.cancelQueries([getOrganizationId(), 'surveys', 'assignedSurveys']);
    queryClient.cancelQueries([getOrganizationId(), 'surveys', 'mySurveys']);
    queryClient.cancelQueries([getOrganizationId(), 'survey', 'assignedSurveys']);
    queryClient.cancelQueries([getOrganizationId(), 'survey', 'mySurveys']);
  };

  const [activeTab, setActiveTab] = useState(0);
  const onPreviousClick = () => {
    if (index - 1 >= 0) {
      setIndex(index - 1);
    }
  };

  const hookProps = {
    drawerName: createdSurveyTemplate.name,
    drawerTitle: 'Survey Results',
    onSubmit: noop,
    isReadOnly: true,
    renderHeader: () => (
      <TabDrawerHeader
        renderCloseButton={() => (
          <IconButton
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            css={styles.closeButton}
            tooltip={undefined}
            size="large"
          />
        )}
        title="Survey Results"
        renderTabNav={() => (
          <div css={styles.tabNav}>
            <TabNavItem
              css={styles.tabNavItem}
              onClick={() => setActiveTab(0)}
              isActive={activeTab === 0}
              renderNavItem={() => (
                <div>Details</div>
              )}
            />
            <TabNavItem
              isActive={activeTab === 1}
              onClick={() => setActiveTab(1)}
              renderNavItem={() => (
                <>By Question</>
              )}
            />
            <TabNavItem
              isActive={activeTab === 2}
              onClick={() => setActiveTab(2)}
              renderNavItem={() => (
                <>By Person</>
              )}
            />
          </div>
        )}
      />
    ),
    renderBody: () => (
      <>
        {isLoading && (
          <div css={styles.skeletonContainer}>
            <MultipleSkeletonLoaders
              numberOfSkeletons={3}
              renderSkeletonItem={() => (
                <CardSkeleton css={styles.cardSkeleton} />
              )}
            />
          </div>
        )}
        {(!isLoading && survey) && (
          <div css={styles.drawerBody}>
            {activeTab === 0 && (
              <Details
                survey={survey}
                isLoading={isLoading}
              />
            )}
            {activeTab === 1 && (
              <ByQuestion
                survey={survey}
                isLoading={isLoading}
                questionTypes={QuestionTypes}
              />
            )}
            {activeTab === 2 && (
            <ByPerson
              survey={survey}
              questionTypes={QuestionTypes}
              personIndex={index}
              onNextClick={onNextClick}
              onPreviousClick={onPreviousClick}
            />
            )}

          </div>
        )}
      </>
    ),
    renderFooter: () => (
      <DrawerFooter
        renderCancelButton={() => (
          <LeadrButton
            variant="ghost"
            onClick={handleViewParticipants}
            data-test-id="surveysViewParticipants"
          >
            View Participants
          </LeadrButton>
        )}
        renderDeleteButton={() => (
          <>
            {survey && survey.completedOn === null && (
            <LeadrButton
              variant="ghost"
              color="danger"
              onClick={() => handleCloseModal(true)}
              data-test-id="surveysCloseSurvey"
              css={styles.closeSurveyButton}
            >
              End Survey
            </LeadrButton>
            )}
            <LeadrButton
              variant="ghost"
              color="danger"
              onClick={() => handleDeleteModal(true)}
              data-test-id="surveysDeleteSurvey"
            >
              Delete
            </LeadrButton>
          </>
        )}
      />
    ),
    renderModal: () => (
      <>
        <DeleteModal
          open={openDeleteModal}
          handleModalDisplay={handleDeleteModal}
          onDelete={deleteSurvey}
          title={survey?.title}
        />
        <CloseModal
          open={openCloseModal}
          handleModalDisplay={handleCloseModal}
          onClose={closeSurvey}
          title={survey?.title}
        />
      </>
    ),
  };
  return (
    <DrawerLayout
      {...hookProps}
      {...props}
    />
  );
};

CreatedSurveyDrawer.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

CreatedSurveyDrawer.defaultProps = {};

registerDrawer({
  templateName: createdSurveyTemplate.name,
  component: CreatedSurveyDrawer,
});

export default CreatedSurveyDrawer;
