import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import UserSocialLinks from '~People/BaseballCard/UserSocialLinks';
import CardAvatar from '~Deprecated/common/Components/People/Avatar/CardAvatar';
import { withBaseShadow, withLineClamp } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare, faSquarePhone } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from '~Common/components/Tooltip';

const style = {
  container: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  }),
  avatar: css({
    borderRadius: '0.5rem',
    width: '2.1875rem',
    height: '2.1875rem',

    '.initials': {
      height: '2.1875rem',
    },
  }, withBaseShadow()),
  content: css({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.75rem',
  }),
  name: css({
    wordBreak: 'break-all',
    width: '100%',
    fontWeight: '600',
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    marginBottom: '1.875rem',
    paddingTop: '.8125rem',
  }, withLineClamp(1)),
  email: css({
    wordBreak: 'break-all',
  }, withLineClamp(1)),
  number: css({
    wordBreak: 'break-all',
  }, withLineClamp(1)),
  socialIconsWrapper: css({
    display: 'flex',
    a: {
      marginRight: '0.25rem',
    },
    svg: {
      color: palette.neutrals.gray600,
    },
  }),
  fullName: css({
    fontSize: '1rem',
    color: palette.neutrals.gray800,
    fontWeight: 500,
  }),
};

const BioDrawerHeader = ({ user, className }) => {
  const fullName = () => `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim();

  return (
    <div css={style.container} className={className}>
      <div css={style.name}>{ `About ${user.firstName}` }</div>
      <div css={style.content}>
        <CardAvatar
          css={style.avatar}
          imgUrl={user.profileImageUrl}
          name={`${fullName()}`}
        />
        <div>
          <span css={style.fullName}>{fullName()}</span>
          <div css={style.socialIconsWrapper}>
            <div css={style.email}>
              <Tooltip
                content={`${user.email}`}
              >
                <a href={`mailto:${user.email}`}>
                  <FontAwesomeIcon
                    icon={faEnvelopeSquare}
                  />
                </a>
              </Tooltip>
            </div>
            {user.phoneNumber && (
              <div css={style.number}>
                <Tooltip
                  content={`${user.phoneNumber}`}
                >
                  <a href={`tel:${user.phoneNumber}`}>
                    <FontAwesomeIcon
                      icon={faSquarePhone}
                    />
                  </a>
                </Tooltip>
              </div>
            )}
            <UserSocialLinks user={user} css={style.linksContainer} />
          </div>
        </div>
      </div>
    </div>
  );
};

BioDrawerHeader.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  className: PropTypes.string,
};

BioDrawerHeader.defaultProps = {
  className: '',
};

export default BioDrawerHeader;
