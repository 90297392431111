import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { getOrganizationId } from '~Common/utils/localStorage';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { useResyncMeeting } from '~Meetings/hooks/useResyncMeeting';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';

const styles = {
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',

    '.instructionParagraph': {
      marginBottom: '1.5rem',
    },
  }),
};

export const resyncMeetingTemplate = {
  name: 'RESYNC_MEETING',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton,
  createButton,
  renderDrawerInstructions,
  onFormSubmit,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={closeButton}
        title="Re-sync Meeting"
      />
    )}
    renderBody={() => (
      <div
        css={styles.drawerBody}
      >
        {renderDrawerInstructions()}
        <div />
        {createButton()}
      </div>
    )}
  />
);

View.propTypes = {
  closeButton: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

View.defaultProps = {};

const ResyncMeetingDrawer = ({ id, type, ...props }) => {
  const dispatch = useDispatch();
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const { factoryId } = meetingDetails ?? {};
  const organizationId = getOrganizationId();
  const { mutate: doResyncMeeting } = useResyncMeeting({ template: resyncMeetingTemplate });
  const renderDrawerInstructions = () => {
    const drawerText = `
          <p class="instructionParagraph">
            Re-syncing will cause the calendar data for this meeting to be completely deleted and re-created. Following the re-sync,
            your calendar will reflect the same meeting information that is currently in Leadr.
          </p>
    `;

    return (
      <div>
        <DrawerInstructions
          html={drawerText}
        />
      </div>
    );
  };
  const createButton = useCallback(() => (
    <LeadrButton
      data-test-id="meetingRestore"
      type="submit"
    >
      Re-sync Meeting
    </LeadrButton>
  ), []);

  const onFormSubmit = useCallback((e) => {
    e.preventDefault();
    doResyncMeeting({ factoryId, organizationId });
  }, [factoryId, doResyncMeeting, organizationId]);

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const hookProps = {
    closeButton,
    createButton,
    renderDrawerInstructions,
    onFormSubmit,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ResyncMeetingDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  drawerState: PropTypes.func.isRequired,
  setDrawerState: PropTypes.func.isRequired,
};

ResyncMeetingDrawer.defaultProps = {};

registerDrawer({
  templateName: resyncMeetingTemplate.name,
  component: ResyncMeetingDrawer,
});

export { View };
export default ResyncMeetingDrawer;
