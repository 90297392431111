import { Tabs } from '@mui/material';
import styled from '@mui/system/styled';
import { palette } from '~Common/styles/colors';

function createGradient(light, dark) {
  return `linear-gradient(120deg, ${light} 50%, ${dark} 99%)`;
}

const StyledTabContainer = styled(Tabs)(() => ({
  backgroundColor: palette.neutrals.white,
  borderRadius: '8px',
  width: '100%',

  '& .MuiTabs-flexContainer': {
    '& .MuiTab-root:not(:first-of-type)': {
      marginInlineStart: '0.5rem',
    },
  },
  '.MuiTabs-indicator': {
    borderRadius: '8px',
    backgroundImage: createGradient(palette.brand.indigo, palette.brand.blue),
    height: '100%',
  },
}));

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx
 * with packages/leadr-frontend/src/common/V3/components/Drawers/TabDrawerHeader.tsx instead
 */
const TabContainer = (props) => (
  <StyledTabContainer
    variant="scrollable"
    scrollButtons="auto"
    textColor="inherit"
    {...props}
  />
);

TabContainer.propTypes = {};

TabContainer.defaultProps = {};

export default TabContainer;
