import {
  useCallback, useEffect, useState, Fragment,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Portal } from 'react-portal';
import { Transition } from 'react-transition-group';
import Backdrop from '~Deprecated/ui/components/DrawerManager/Backdrop';
import BaseDrawer, { ANIMATION_TIME } from '~Deprecated/ui/components/DrawerManager/BaseDrawer';
import { DRAWER_STATE_SHAPE, DRAWER_TYPE_SHAPE } from '~Common/const/proptypes';
import { backdropZ, primaryDrawerZ, secondaryDrawerZ } from '~Common/const/zIndicies';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { DRAWER_STATE } from '~Common/const/drawers';
import { popAfterAction } from '~Deprecated/actions/drawers/popAfter';
import ErrorBoundary from '~Common/components/ErrorBoundary';

const styles = {
  empty: css``,
  isHidden: (isHidden) => css`
    ${isHidden ? 'display: none;' : ''}
  `,
  backdropCss: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: ${backdropZ};
  `,
  mobileTopLayer: css`
    @media only screen and (max-width: 768px) {
      z-index: ${primaryDrawerZ};
      top: 80px;
      height: calc(100% - 100px);
    }
  `,
  mobileBottomLayer: css`
    @media only screen and (max-width: 768px) {
      z-index: ${secondaryDrawerZ};
    }
  `,
};

const templateComponents = {};

export const templateType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

// eventually break this into another file
export function registerDrawer({ templateName, component }) {
  templateComponents[templateName] = component;
}

const View = ({
  drawers,
  createDrawer,
  closeAllDrawers,
  drawerState,
}) => (
  (drawers.length > 0) && (
    <Portal node={document && document.getElementById('modal-root')}>
      <ErrorBoundary>
        <div css={styles.drawers}>
          {drawers && drawers.map((drawer, index) => (
            <Fragment key={drawer.name}>
              {
                // We do not want the backdrop to appear on every drawer, so limiting backdrop to only first drawer.
                index === 0 && (
                  <Transition
                    in={drawers.length > 0}
                    timeout={ANIMATION_TIME}
                  >
                    {(state) => (
                      <Backdrop
                        css={[styles.backdropCss, drawer?.args?.backdropStyles]}
                        state={state}
                        closeAction={drawerState.shouldConfirmDrawerClose ? undefined : closeAllDrawers}
                      />
                    )}
                  </Transition>
                )
              }
              <Transition
                key={drawer.name}
                in={drawer.state === DRAWER_STATE.OPEN}
                appear
                timeout={ANIMATION_TIME}
              >
                {(state) => (
                  <BaseDrawer
                    drawerIndex={index}
                    drawer={drawer}
                    state={state}
                  >
                    <ErrorBoundary>
                      {createDrawer(drawer, state)}
                    </ErrorBoundary>
                  </BaseDrawer>
                )}
              </Transition>
            </Fragment>
          ))}
        </div>
      </ErrorBoundary>
    </Portal>
  )
);

View.propTypes = {
  closeAllDrawers: PropTypes.func.isRequired,
  drawers: PropTypes.arrayOf(DRAWER_TYPE_SHAPE),
  createDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
};

View.defaultProps = {
  drawers: [],
};

const DrawerManager = () => {
  const [drawerState, setDrawerState] = useState({});
  const dispatch = useDispatch();

  const popDrawer = useCallback((payload) => dispatch(popDrawerAction(payload)), [dispatch]);
  const pushDrawer = useCallback((payload) => dispatch(pushDrawerAction(payload)), [dispatch]);
  const popAfter = useCallback((payload) => dispatch(popAfterAction(payload)), [dispatch]);
  const drawers = useSelector((state) => state.drawers.order);

  useEffect(() => {
    if (drawers.length === 0) {
      setDrawerState({});
    }
  }, [drawers, setDrawerState]);

  const hookProps = {
    createDrawer: (drawer, transitionState) => {
      const DrawerComponent = templateComponents[drawer.name];

      return (
        <DrawerComponent
          popDrawer={popDrawer}
          pushDrawer={pushDrawer}
          popAfter={popAfter}
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          transitionState={transitionState}
          {...drawer.args}
        />
      );
    },
    closeAllDrawers: () => {
      popDrawer({ popAll: true });
    },
    drawers,
    drawerState,
  };

  return (
    <View {...hookProps} />
  );
};

export default DrawerManager;
