import { useDropzone } from 'react-dropzone';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faFile } from '@fortawesome/pro-light-svg-icons';
import LoadingSpinner from '~Deprecated/components/LoadingSpinner';
import { JOB_TITLE_LENGTH } from '~Common/const/constraints';
import { useUploadCSV } from '~Deprecated/hooks/admin/useUploadCSV';
import { toast } from '~Common/components/Toasts';
import { IMPORT_STYLES } from '~Admin/components/styles/styles';
import sampleCSV from './sampleCsv.csv';

const alertMessageTimeout = 6000;
const csvConstants = {
  columnCount: 7, //
  minRows: 2, // 1 Header row + 1 data row
  maxRows: 201, // 1 Header row + 200 data rows
};

const COLUMNS = {
  FIRST_NAME: 0,
  LAST_NAME: 1,
  USER_EMAIL: 2,
  MANAGER_EMAIL: 3,
  ROLE: 4,
  JOB_TITLE: 5,
  DEPARTMENT: 6,
};

const styles = {
  ...IMPORT_STYLES,
};

const CSVFileUploadInterface = ({
  setIsFileUploading,
  setShowImportUI,
}) => {
  const [uploadFileSelected, setUploadFileSelected] = useState(false);
  const toastId = useRef(null);
  const [selectedFileName, setSelectedFileName] = useState(null);

  const { uploadCSVMutation, isLoading: uploadingFile } = useUploadCSV();

  const processFile = (file) => {
    toastId.current = toast.info('Processing the file...', { autoClose: 1000 });
    const reader = new FileReader();
    reader.onload = (e) => {
      let errorMessage = '';
      const rows = e.target.result.split('\n');
      if (rows.length < csvConstants.minRows) {
        errorMessage = 'Please add at least 1 user data to import.';
      } else if (rows.length > csvConstants.maxRows) {
        errorMessage = 'We currently support importing up to 200 user at a time.';
      } else if (
        !(
          rows.reduce((previousRowValid, dataRow) => {
            if (!previousRowValid) {
              return previousRowValid;
            }

            const columns = dataRow.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);

            if (columns.length !== csvConstants.columnCount) {
              errorMessage = 'Missing or invalid data in the file or an empty line at the end.';
              return false;
            }

            if (columns[COLUMNS.JOB_TITLE].length > JOB_TITLE_LENGTH) {
              errorMessage = `File contains a job title larger than ${JOB_TITLE_LENGTH} characters.`;
              return false;
            }

            return true;
          }, true)
        )
      ) {
        errorMessage = `${errorMessage} Please correct and upload again.`;
      } else {
        const columns = rows[0].split(',');
        if (columns.length !== csvConstants.columnCount || !(
          columns[COLUMNS.FIRST_NAME].trim() === 'first_name'
          && columns[COLUMNS.LAST_NAME].trim() === 'last_name'
          && columns[COLUMNS.USER_EMAIL].trim() === 'user_email'
          && columns[COLUMNS.MANAGER_EMAIL].trim() === 'manager_email'
          && columns[COLUMNS.ROLE].trim() === 'role'
          && columns[COLUMNS.JOB_TITLE].trim() === 'job_title'
          && columns[COLUMNS.DEPARTMENT].trim() === 'department'
        )) {
          errorMessage = 'The first line does not contain the appropriate headers. Please try again.';
        }
      }
      if (errorMessage !== '') {
        toast.update(toastId.current, {
          render: errorMessage,
          type: toast.TYPE.ERROR,
          autoClose: alertMessageTimeout,
        });
      } else {
        setIsFileUploading(true);
        uploadCSVMutation({
          csvInformation: {
            extension: 'csv',
            operationType: 'bulkUserUpload',
          },
          file,
          onSuccessCallback: () => {
            setIsFileUploading(false);
            setShowImportUI(false);
          },
        });
        setSelectedFileName(file.name);
        setUploadFileSelected(true);
      }
    };
    reader.readAsText(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv, application/vnd.ms-excel,',
    multiple: false,
    onDrop(acceptedFiles, fileRejections) {
      let file;
      if (fileRejections.length > 0) {
        const fileRejection = fileRejections.pop();
        if (!fileRejection.file.name.endsWith('.csv')) {
          // TODO: Log to Loggly
        }
        file = fileRejection.file;
      } else if (acceptedFiles.length > 0) {
        file = acceptedFiles.pop();
      }

      if (!file.name.endsWith('.csv')) {
        toast.update(toastId.current, {
          render: 'File is not a CSV. Please download the sample file above and try again.',
          type: toast.TYPE.ERROR,
          autoClose: alertMessageTimeout,
        });
        return;
      }

      processFile(file);
    },
  });
  return (
    <div css={styles.importUIContainer}>
      <div {...getRootProps({ css: styles.dropZone })}>
        { !uploadFileSelected && <input {...getInputProps()} /> }
        <div css={styles.uploadIcon} className="uploadIcon">
          { uploadFileSelected ? (
            <FontAwesomeIcon css={styles.icon} icon={faFile} />
          ) : (
            <FontAwesomeIcon css={styles.icon} icon={faFileImport} />
          ) }
        </div>
        { uploadFileSelected ? (
          <div css={styles.dropText}>
            <span css={styles.browse}>{ selectedFileName }</span>
          </div>
        ) : (
          <>
            <div css={styles.dropText}>
              Drag and drop the CSV to add the file here or
              { ' ' }
              <span css={styles.browse}>browse</span>
              { ' ' }
              to choose a file
            </div>
            <div css={styles.downloadSample}>
              <a download href={sampleCSV}>Download sample CSV</a>
            </div>
          </>
        ) }
        { uploadingFile && <LoadingSpinner minHeight="unset;" /> }
      </div>
    </div>
  );
};

CSVFileUploadInterface.defaultProps = {
  uploadFileSelected: false,
  uploadingFile: false,
  initiateFileUpload: () => {},
  setUploadFileSelected: () => {},
};

CSVFileUploadInterface.propTypes = {
  uploadFileSelected: PropTypes.bool,
  uploadingFile: PropTypes.bool,
  initiateFileUpload: PropTypes.func,
  setUploadFileSelected: PropTypes.func,
  setIsFileUploading: PropTypes.func.isRequired,
  setShowImportUI: PropTypes.func.isRequired,
};

export default CSVFileUploadInterface;
