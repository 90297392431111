import { Skeleton } from '@mui/material';
import { SkeletonProps } from '@mui/lab';
import { skeletonGlobalAnimation } from '~Common/const/skeletons';

// Documentation: https://leadrhq.atlassian.net/l/c/E7qyTJD3

export interface SkeletonLoaderProps extends SkeletonProps {
  renderComponent: () => JSX.Element,
}
const SkeletonLoader = ({ renderComponent, ...props }: SkeletonLoaderProps): JSX.Element => (
  <Skeleton animation={skeletonGlobalAnimation} {...props}>
    {renderComponent()}
  </Skeleton>
);

export default SkeletonLoader;
