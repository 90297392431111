import { useMemo } from 'react';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';

export const useDisableLAUsers = (users) => {
  const { limitedAccessUserIds } = usePeopleQuery();

  return useMemo(() => (
    users.filter((user) => limitedAccessUserIds.includes(user)).map((user) => (
      {
        user,
        tooltip: 'Limited Access users can not take ownership of a meeting',
      }
    ))
  ), [users, limitedAccessUserIds]);
};
