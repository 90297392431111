import { learningQueryKeys } from '../queryKeys';

interface ListParams {
  page: number,
  count: number,
  curated: boolean,
}
interface ListParamsWithCategoryId extends ListParams {
  categoryId: string,
}

interface ListParamsWithSearchString extends ListParams {
  searchString: string,
}

export const learningTemplatesQueryKeys = {
  all: [...learningQueryKeys.all, 'templates'] as const,
  categories: () => [...learningTemplatesQueryKeys.all, 'categories'] as const,
  lists: () => [...learningTemplatesQueryKeys.all, 'list'] as const,
  list: ({
    page, count, curated, categoryId,
  }: ListParamsWithCategoryId) => [...learningTemplatesQueryKeys.lists(), curated.toString(), categoryId, page.toString(), count.toString()] as const,
  search: ({
    curated, page, count, searchString,
  }: ListParamsWithSearchString) => [
    ...learningTemplatesQueryKeys.lists(),
    'search',
    curated.toString(),
    page.toString(),
    count.toString(),
    searchString,
  ] as const,
  details: () => [...learningTemplatesQueryKeys.lists(), 'detail'] as const,
  singleContentDetails: () => [...learningTemplatesQueryKeys.details(), 'single content'] as const,
  singleContentDetail: (templateId: string) => [...learningTemplatesQueryKeys.singleContentDetails(), templateId] as const,
  playlistDetails: () => [...learningTemplatesQueryKeys.details(), 'playlist'] as const,
  playlistDetail: (playlistId: number) => [...learningTemplatesQueryKeys.playlistDetails(), playlistId.toString()] as const,
};

interface CuratedByTableGroupListParams {
  page: number,
  count: number,
}

interface CuratedByTableGroupListParamsWithCategoryId extends CuratedByTableGroupListParams {
  categoryId: string,
}

interface CuratedByTableGroupListParamsWithSearchString extends CuratedByTableGroupListParams {
  searchString: string,
}

export const learningTableGroupTemplatesQueryKeys = {
  all: [...learningQueryKeys.all, 'table group templates'] as const,
  lists: () => [...learningTableGroupTemplatesQueryKeys.all, 'list'] as const,
  list: ({
    page, count, categoryId,
  }: CuratedByTableGroupListParamsWithCategoryId) => [...learningTableGroupTemplatesQueryKeys.lists(), categoryId, page.toString(), count.toString()] as const,
  search: ({
    page, count, searchString,
  }: CuratedByTableGroupListParamsWithSearchString) => [
    ...learningTableGroupTemplatesQueryKeys.lists(),
    'search',
    page.toString(),
    count.toString(),
    searchString,
  ] as const,
  details: () => [...learningTableGroupTemplatesQueryKeys.lists(), 'detail'] as const,
  singleContentDetails: () => [...learningTableGroupTemplatesQueryKeys.details(), 'single content'] as const,
  singleContentDetail: (templateId: string) => [...learningTableGroupTemplatesQueryKeys.singleContentDetails(), templateId] as const,
  playlistDetails: () => [...learningTableGroupTemplatesQueryKeys.details(), 'playlist'] as const,
  playlistDetail: (playlistId: number) => [...learningTableGroupTemplatesQueryKeys.playlistDetails(), playlistId.toString()] as const,
};

export const learningCuratedTemplatesQueryKeys = {
  all: [...learningQueryKeys.all, 'curated by Leadr templates'] as const,
  lists: () => [...learningCuratedTemplatesQueryKeys.all, 'list'] as const,
  list: ({
    page, count, categoryId,
  }: CuratedByTableGroupListParamsWithCategoryId) => [...learningCuratedTemplatesQueryKeys.lists(), categoryId, page.toString(), count.toString()] as const,
  search: ({
    page, count, searchString,
  }: CuratedByTableGroupListParamsWithSearchString) => [
    ...learningCuratedTemplatesQueryKeys.lists(),
    'search',
    page.toString(),
    count.toString(),
    searchString,
  ] as const,
  details: () => [...learningCuratedTemplatesQueryKeys.lists(), 'detail'] as const,
  singleContentDetails: () => [...learningCuratedTemplatesQueryKeys.details(), 'single content'] as const,
  singleContentDetail: (templateId: string) => [...learningCuratedTemplatesQueryKeys.singleContentDetails(), templateId] as const,
  playlistDetails: () => [...learningCuratedTemplatesQueryKeys.details(), 'playlist'] as const,
  playlistDetail: (playlistId: number) => [...learningCuratedTemplatesQueryKeys.playlistDetails(), playlistId.toString()] as const,
};
