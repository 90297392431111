import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ViewCard from '~Common/components/Cards/ViewCard';
import { withTruncate } from '~Common/styles/mixins'; import { cardDivider, drawerInstructionColor } from '~Common/styles/colors';
import { buttonDefaultColor } from '~Deprecated/ui/styles/colors';
import { SURVEY_SHAPE } from '~Common/const/proptypes';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  surveyCardBody: css`
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  `,
  title: css`
    font-size: 1.25rem;
    font-weight: bold;
    ${withTruncate()}
  `,
  buttonsContainer: css`
    display: flex;
    margin-left: 8px;
  `,
  footer: css`
    padding-top: 18px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${cardDivider};
    margin-top: 28px;
  `,
  completedText: css`
    color: ${drawerInstructionColor};
  `,
  flatButton: css`
    color: ${buttonDefaultColor};
    display: flex;
    align-items: center;
    font-weight: bold;
  `,
  flatButtonIcon: css`
    margin-right: 8px;
  `,
};

const View = ({
  title,
  onPrintClick,
  onExportClick,
  objective,
  completeBy,
  numberOfCompletedSurveys,
  numberOfParticipants,
  renderContextMenu,
  ...props
}) => (
  <ViewCard
    renderBody={() => (
      <>
        <div css={styles.surveyCardBody}>
          <div css={styles.header}>
            <div css={styles.title}>{title}</div>
            <div css={styles.buttonsContainer}>
              {renderContextMenu && renderContextMenu()}
            </div>
          </div>
          <HTMLRenderer htmlText={objective} />
        </div>
        <div css={styles.footer}>
          <div>
            {completeBy && (
            <div>
              Due:
              {' '}
              {moment(completeBy).format('MM/DD/YYYY')}
            </div>
            ) }
          </div>
          <div css={styles.completedText}>
            {numberOfCompletedSurveys}
            {' '}
            of
            {' '}
            {numberOfParticipants}
            {' '}
            completed
          </div>
        </div>
      </>
    )}
    {...props}
  />
);

View.propTypes = {
  title: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  onPrintClick: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  objective: PropTypes.string,
  completeBy: PropTypes.string,
  numberOfCompletedSurveys: PropTypes.number.isRequired,
  numberOfParticipants: PropTypes.number.isRequired,
  renderContextMenu: PropTypes.func,
};

View.defaultProps = {
  objective: '',
  completeBy: null,
  renderContextMenu: null,
};

const SurveyDetailsCard = ({ survey, ...props }) => {
  const numberOfCompletedSurveys = survey.surveyParticipants.filter((participant) => participant.completedOn).length;

  const hookProps = {
    numberOfCompletedSurveys,
    numberOfParticipants: survey.participants.length,
    ...survey,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SurveyDetailsCard.propTypes = {
  survey: SURVEY_SHAPE,
};

SurveyDetailsCard.defaultProps = {
  survey: {},
};

export { View };
export default SurveyDetailsCard;
