import { css } from '@emotion/react';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import LeadrCard from '~Common/V3/components/LeadrCard';
import { Goals as GoalsTypes } from '@leadr-hr/types';
import { ViewPerspective } from '~Goals/const/types';
import { OPEN_GOAL_TABS } from '~Goals/const/defaults';
import OldTabs from '~Goals/OldGoals/OldTabs';
import { IndexTable } from '~Goals/OldGoals/OldTables';

const styles = {
  leadrCard: css({
    marginBottom: '1.5rem',
  }),
};

interface ViewProps {
  viewPerspective: ViewPerspective,
  activeTab: GoalsTypes.GoalContextType,
  setActiveTab: (tab: GoalsTypes.GoalContextType) => void,
}

const View = ({
  viewPerspective,
  activeTab,
  setActiveTab,
}:ViewProps): JSX.Element => (
  <>
    <LeadrCard
      css={styles.leadrCard}
    >
      <OldTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        viewPerspective={viewPerspective}
      />
      <IndexTable
        activeTab={activeTab}
        viewPerspective={viewPerspective}
      />
    </LeadrCard>
  </>
);

interface OldGoalsProps {
  viewPerspective: ViewPerspective,
}

const OldGoals = ({
  viewPerspective,
}: OldGoalsProps): JSX.Element => {
  const [activeTab, setActiveTab] = useQueryParamState<GoalsTypes.GoalContextType>(
    'goals',
    'tab',
    OPEN_GOAL_TABS[0].value as GoalsTypes.GoalContextType,
  );

  const hookProps = {
    viewPerspective,
    activeTab,
    setActiveTab,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };

export default OldGoals;
