import { css, SerializedStyles } from '@emotion/react';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useState, useEffect } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import Froala from '~Common/V3/components/Froala';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FrontendRankedQuestion } from '~Learning/const/interfaces';
import DatePicker from '~Common/V3/components/DatePicker';
import moment, { Moment } from 'moment';
import { useCreateContentDetails } from '~Learning/hooks/utils/useCreateContentDetails';
import { useGetCombinedLearningTemplateDetail } from '~Learning/hooks/utils/useGetCombinedLearningTemplateDetail';
import { personalDevelopmentShareLearningDrawer } from '~DevelopmentPlan/components/Drawers/Learning/ShareLearningDrawer';
import DrawerTabs from '~DevelopmentPlan/components/Tabs/DrawerTabs';
import { ResourceType } from '~DevelopmentPlan/const/types';
import {
  DEFAULT_DATE, DEFAULT_RESOURCE_TITLE, DUPLICATE_RESOURCE_TEXT, modalTabs,
} from '~DevelopmentPlan/const/defaults';
import { ExistingLearning } from '~DevelopmentPlan/components/Drawers/Learning/ExistingLearning';
import { DrawerCompetencySelect } from '~DevelopmentPlan/components/Shared/DrawerCompetencySelect';
import {
  conformToDto, CreateResourceDTO, createResourceFormSchema, FormValues,
} from '~DevelopmentPlan/schemata/addResourceSchemata';
import { addResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';

import { useCreateResource } from '~DevelopmentPlan/hooks/useCreateResource';
import { ValidationErrors } from '~Goals/const/types';
import { toast } from '~Common/components/Toasts';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { competencyDrawerSelect } from '~DevelopmentPlan/const/pageStyles';
import { checkShouldCreateResource } from '~DevelopmentPlan/const/functions';
import { useLearningSearch } from '~Learning/hooks/utils/useLearningSearch';
import { createLearningParticipantsDrawerTemplate } from './CreateLearningParticipantsDrawerTemplate';
import TitleAndLink from '../Shared/TitleAndLink';
import QuestionsList from '../Shared/QuestionList';
import { createLearningTemplateDrawerTemplate } from '../LearningLibrary/CreateLearningTemplateDrawer';
import { learningTypePickerTemplate } from '../LearningTypePickerDrawer';

const styles = {
  icon: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  saveButton: css({
    marginRight: '.625rem',
  }),
  datePicker: css({
    marginTop: '0.5rem',
  }),
  datePickerLabel: css({
    display: 'flex',
    gap: '0.25rem',
    color: palette.neutrals.gray600,
    fontWeight: 500,
  }),
  caretIcon: css({
    color: palette.brand.indigo,
    fontSize: '1rem',
  }),
  buttonsContainer: css({
    marginTop: '2.875rem',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  leftButtons: css({
    display: 'flex',
  }),
  backButton: css({
    paddingLeft: '0px',
  }),
  competencySelect: css({
    marginBottom: '.625rem',
    ...competencyDrawerSelect,
  }),
  drawerFooterBtns: css({
    margin: '1.25rem 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '.625rem',
  }),
};

export interface CreateLearningDrawerState {
  contentTitle: string,
  contentLink: string,
  introduction: string,
  questions: FrontendRankedQuestion[],
  dueDate: Date,
  isCuratedByTableGroup: boolean,
  isPersonalDevelopment?: boolean,
  activeTab?: number,
  setActiveTab?: (tab: number) => void,
  pdpId?: string,
  runAddResourceValidations?: (resourceIdClicked: ResourceType, contentId: string | number) => void,
  competencyId?: number,
  setCompetencyId?: (competencyId: number) => void,
}

interface CreateLearningDrawerParams extends DrawerProps<CreateLearningDrawerState> {
  templateId: string,
}

export const createLearningTemplate = {
  name: 'CREATE_LEARNING_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

enum FormSubmittedByButton {
  SAVE_AS_TEMPLATE_BUTTON = 'SAVE_AS_TEMPLATE_BUTTON',
  SAVE_AND_SELECT_PARTICIPANTS_BUTTON = 'SAVE_AND_SELECT_PARTICIPANTS_BUTTON',
}

const CreateLearningDrawer = ({
  drawerState,
  templateId,
  popDrawer,
  pushDrawer,
  setDrawerState,
}: CreateLearningDrawerParams): JSX.Element => {
  const [dueDate, setDueDate] = useState<Moment | null>(drawerState.dueDate ? moment(drawerState.dueDate) : null);
  const [formSubmittedByButton, setFormSubmittedByButton] = useState<FormSubmittedByButton | null>();
  const [resourceIdSelected, setResourceIdSelected] = useState<string[]>([]);
  const {
    isPersonalDevelopment, activeTab, setActiveTab,
  } = drawerState;

  const onSaveAsTemplate = (): void => {
    setFormSubmittedByButton(FormSubmittedByButton.SAVE_AS_TEMPLATE_BUTTON);
    setDrawerState((prev) => ({
      ...prev,
      isLearningLibraryWorkflow: false,
    }));
  };

  const { pdpId, competencyId } = addResourceModalStore.getState();
  const { mutate: createResourceMutation } = useCreateResource();
  const runAddResourceValidations = (resourceIdClicked:ResourceType, contentId: string): void => {
    const {
      resourceContentTitle, resourceContentDueDate, resourceContentStatus,
    } = addResourceModalStore.getState();

    const fallBackDate = moment(resourceContentDueDate ?? DEFAULT_DATE).valueOf() === moment(DEFAULT_DATE).valueOf();

    const addResourceDataToValidate = {
      contentId,
      contentTypeId: resourceIdClicked,
      competencyId,
      contentTitle: resourceContentTitle ?? DEFAULT_RESOURCE_TITLE,
      contentDueDate: fallBackDate ? undefined : resourceContentDueDate,
      contentStatus: resourceContentStatus,
    };
    // Need to run these validations as a faux form submission
    runValidations(addResourceDataToValidate);
  };

  const runValidations = (data: CreateResourceDTO): void => {
    const resourceFound = checkShouldCreateResource(data, pdpId ?? '');
    if (!resourceFound) {
      createResourceFormSchema
        .validate(data, { abortEarly: false })
        .then(() => {
          const resourceDataDTO = conformToDto(data as FormValues);
          createResourceMutation({ id: pdpId ?? '', resource: resourceDataDTO }, { onSuccess: onSuccessfulCreate });
        })
        .catch((err: ValidationErrors) => {
          if (err.errors) {
            err.errors.forEach((error) => {
              toast.error(error);
            });
          } else {
            toast.error(
              'There was an issue creating this resource. Please try again.',
              { autoClose: 1500 },
            );
          }
        });
    } else {
      toast.error(DUPLICATE_RESOURCE_TEXT);
    }
  };
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchText] = useLearningSearch();
  const onSuccessfulCreate = (): void => {
    setSearchText('');
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
  };

  const {
    onSubmit,
    questions,
    setQuestions,
    contentTitle,
    setContentTitle,
    contentLink,
    setContentLink,
    setContentLinkError,
  } = useCreateContentDetails({
    initialQuestions: drawerState.questions,
    initialContentLink: drawerState.contentLink,
    initialContentTitle: drawerState.contentTitle,
    hasValidation: formSubmittedByButton !== FormSubmittedByButton.SAVE_AS_TEMPLATE_BUTTON,
    afterSubmitValidationCallback: ({
      introduction: introductionFromForm,
      contentTitle: contentTitleFromForm,
      contentLink: contentLinkFromForm,
    }) => {
      // Store all of this data in drawer state
      setDrawerState((prev) => ({
        ...prev,
        questions,
        contentLink: contentLinkFromForm,
        contentTitle: contentTitleFromForm,
        introduction: introductionFromForm,
        dueDate,
      }));

      onCloseDrawer();

      if (formSubmittedByButton === FormSubmittedByButton.SAVE_AND_SELECT_PARTICIPANTS_BUTTON) {
        setDrawerState((prev) => ({
          ...prev,
          runAddResourceValidations,
          resourceId: ResourceType.Learning,
        }));
        pushDrawer({
          drawer: {
            ...createLearningParticipantsDrawerTemplate,
          },
        });
      } else if (formSubmittedByButton === FormSubmittedByButton.SAVE_AS_TEMPLATE_BUTTON) {
        pushDrawer({
          drawer: {
            ...createLearningTemplateDrawerTemplate,
            args: {
              initialContentTitle: contentTitleFromForm,
              initialContentLink: contentLinkFromForm,
              initialQuestions: questions,
              saveTemplateCallback: () => {
                pushDrawer({
                  drawer: createLearningTemplate,
                });
              },
            },
          },
        });
      }
    },
  });

  //  Begin Learning Template data grab (only used when templateId is passed into the drawer)
  const { learningTemplate } = useGetCombinedLearningTemplateDetail({
    templateId,
    isCuratedByTableGroup: drawerState.isCuratedByTableGroup,
  });

  useEffect(() => {
    if (learningTemplate) {
      setQuestions(learningTemplate.questions?.map((question) => ({ rank: parseInt(question.rank, 10), text: question.questionText })) ?? []);
      setContentTitle(learningTemplate.title);
      setContentLink(learningTemplate.contentURL);
    }
  }, [learningTemplate, setContentLink, setContentTitle, setQuestions]);
  // End Learning Template data grab

  const onCloseDrawer = (): void => {
    popDrawer({ drawerName: createLearningTemplate.name });
    setSearchText('');
  };

  const onGoBack = (): void => {
    popDrawer({ popAll: true });
    if (isPersonalDevelopment) {
      pushDrawer({
        drawer: personalDevelopmentShareLearningDrawer,
      });
    } else {
      pushDrawer({
        drawer: learningTypePickerTemplate,
      });
    }
  };

  const hookProps = {
    noValidate: formSubmittedByButton === FormSubmittedByButton.SAVE_AS_TEMPLATE_BUTTON,
    renderHeader: () => (
      <DrawerHeader
        title="Assign Learning"
        renderBackButton={(backButtonStyles: SerializedStyles) => (
          <IconButton onClick={onGoBack} type="button" icon={faArrowLeft} css={[backButtonStyles, styles.backButton]} tooltip="Back" />
        )}
      />
    ),
    onSubmit,
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {isPersonalDevelopment && (
          <>
            <DrawerTabs
              resourceId={ResourceType.Learning}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setDrawerState={setDrawerState}
            />
            {activeTab === modalTabs[ResourceType.Learning].Existing.value && pdpId && (
              <>
                <DrawerCompetencySelect
                  pdpId={pdpId}
                  selectStyles={styles.competencySelect}
                  competencyId={competencyId}
                  setDrawerState={setDrawerState}
                />
                <ExistingLearning
                  resourceIdSelected={resourceIdSelected}
                  setResourceIdSelected={setResourceIdSelected}
                />
              </>
            )}
          </>
        )}
        {((isPersonalDevelopment && activeTab === modalTabs[ResourceType.Learning].New.value) || isPersonalDevelopment === undefined) && (
          <>
            <TitleAndLink
              setContentLinkError={setContentLinkError}
              contentTitle={contentTitle}
              contentLink={contentLink}
              setContentTitle={setContentTitle}
            />
            <Froala
              name="introduction"
              label="Introduction"
              richTextEditorProps={{
                initialValue: drawerState.introduction,
              }}
            />
            <DatePicker
              clearable
              initialDate={dueDate?.toDate()}
              onDateSelected={({ date: newDate }) => setDueDate(newDate)}
              label="Due By"
              name="dueDate"
              disablePast
              css={styles.datePicker}
              rightIconType={() => (
                <div css={styles.icon}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    css={styles.caretIcon}
                  />
                </div>
              )}
            />
            <QuestionsList
              questions={questions}
              setQuestions={setQuestions}
            />
            <div css={styles.buttonsContainer}>
              <div css={styles.leftButtons}>
                <LeadrButton
                  css={styles.saveButton}
                  type="submit"
                  onClick={() => setFormSubmittedByButton(FormSubmittedByButton.SAVE_AND_SELECT_PARTICIPANTS_BUTTON)}
                  data-test-id="learningCreateNew"
                >
                  Save & Select Participants
                </LeadrButton>
                <LeadrButton
                  variant="ghost"
                  onClick={onCloseDrawer}
                  data-test-id="learningCancelNew"
                >
                  Cancel
                </LeadrButton>
              </div>
              <LeadrButton
                variant="ghost"
                type="submit"
                onClick={onSaveAsTemplate}
                data-test-id="learningSaveAsTemplate"
              >
                Save as Template
              </LeadrButton>
            </div>
          </>
        )}
      </div>
    ),
    renderFooter: () => {
      if (activeTab === modalTabs[ResourceType.Learning].Existing.value && pdpId) {
        return (
          <div css={styles.drawerFooterBtns}>
            <LeadrButton
              data-test-id="addResourceDrawerSaveChanges"
              size="small"
              onClick={() => {
                if (runAddResourceValidations !== undefined) {
                  runAddResourceValidations(ResourceType.Learning, resourceIdSelected[0]);
                }
              }}
              disabled={resourceIdSelected.length === 0}
            >
              Link Learning
            </LeadrButton>
            <LeadrButton
              data-test-id="addResourceModalCancelChanges"
              onClick={onGoBack}
              size="small"
              variant="ghost"
            >
              Cancel
            </LeadrButton>
          </div>
        );
      }
      return <></>;
    },
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: createLearningTemplate.name,
  component: CreateLearningDrawer,
});

export default CreateLearningDrawer;
