export const APPLICATION_NAME = 'Web Client';
export const TELEMETRY_APPLICATION_KEY = '?Z$=X9jwZL=tZqMfc8tN/Nd-xrYLUm[*am.';

export const LOG_LEVEL = {
  TRACE: 0,
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
  SILENT: 5,
};

export enum MetricType {
  count = 'count',
  gauge = 'gauge',
  rate = 'rate',
}
