import { css } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withBaseShadow } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';

// https://fkhadra.github.io/react-toastify/introduction

const styles = css`
  /** Used to define container behavior: width, position: fixed etc... **/
  z-index: 99999999999999; // Make the toast appear over all the other components (Including intercom button)
  
  .Toastify__toast-container {
    padding: unset;
  }

  /** Used to define the position of the ToastContainer **/

  .Toastify__toast-container--top-left {

  }

  .Toastify__toast-container--top-center {

  }

  .Toastify__toast-container--top-right {

  }

  .Toastify__toast-container--bottom-left {

  }

  .Toastify__toast-container--bottom-center {

  }

  .Toastify__toast-container--bottom-right {

  }

  /** Classes for the displayed toast **/

  .Toastify__toast {
    ${withBaseShadow()}
    border-radius: 0.5rem;
    padding: 1rem;
  }

  .Toastify__toast--dark {
    background: ${palette.neutrals.gray900};
  }

  .Toastify__toast--default {
    background: ${palette.brand.blue};
  }

  .Toastify__toast--info {
    background: ${palette.brand.blue};
  }

  .Toastify__toast--success {
    background: ${palette.brand.green};
  }

  .Toastify__toast--warning {
    background: ${palette.brand.orange};

    & .Toastify__toast-body {
      color: ${palette.neutrals.gray900} !important;
    }
  }

  .Toastify__toast--error {
    background: ${palette.brand.red};
  }

  .Toastify__toast-body {
    color: ${palette.neutrals.white};
    font-weight: 600;

    & a {
      font-weight: 700;
    }
  }

  /** Classes for the close button. Better use your own closeButton **/

  .Toastify__close-button {

  }

  .Toastify__close-button--default {

  }

  .Toastify__close-button > svg {

  }

  .Toastify__close-button:hover, .Toastify__close-button:focus {

  }

  /** Classes for the progress bar **/

  .Toastify__progress-bar {
    visibility: hidden;
  }

  .Toastify__progress-bar--animated {

  }

  .Toastify__progress-bar--controlled {

  }

  .Toastify__progress-bar--rtl {

  }

  .Toastify__progress-bar--default {

  }

  .Toastify__progress-bar--dark {

  }
`;

const Toasts = () => (
  <ToastContainer
    css={styles}
    position="bottom-center"
    autoClose={5000}
    closeButton={null}
    pauseOnHover
    limit={3}
    hideProgressBar
  />
);

export default Toasts;
