import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { DASHBOARD_CARD_COMPONENTS, EXPANDED_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { PeopleInsightsRow } from '../ExpandedSubtab';
import MetricIndicator from '../MetricIndicator';

const styles = {
  ...DASHBOARD_CARD_COMPONENTS,
  ...EXPANDED_CARD_COMPONENTS,
};

export const ActivityRow = ({
  userData,
}: PeopleInsightsRow): JSX.Element => (
  <>
    <TableRow
      css={styles.tableRow}
    >
      <TableCell css={styles.avatarCell} component="td" scope="column">
        <SquareAvatar
          imgUrl={userData.profilePictureUrl}
          name={`${userData.firstName} ${userData.lastName}`}
          width={30}
          height={30}
          includeInitials
        />
        <div css={styles.metricsWrapper}>{`${userData.firstName} ${userData.lastName}`}</div>
      </TableCell>
      <TableCell component="td" scope="column">
        <div css={styles.metricsWrapper}>{userData?.activityLastLogin}</div>
      </TableCell>
      <TableCell component="td" scope="column">
        <div css={styles.metricsWrapper}>
          <MetricIndicator
            indicatorValue={userData.activityPerformedAction}
            useYesno
          />
        </div>
      </TableCell>
    </TableRow>
  </>
);
