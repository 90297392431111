import { css, SerializedStyles } from '@emotion/react';
import { darken, lighten } from 'polished';
import { palette } from '~Common/styles/colors';

const primaryButtonColors = css({
  backgroundColor: palette.brand.indigo,
  border: `1px solid ${palette.brand.indigo}`,
  /*
    The !important is here for the LeadrLinkButton
    Right now, we have the bootstrap styles applied, which adds a text color to <a /> elements
    Then we override that in root.js with our own color we want for <a /> elements
    However, here we don't want that color, we want the color to be white for this button
    So, once we remove all that wild styling, we should be good to remove the important here
    Also, you might need to do this on the other button types if you use them with the LeadrLinkButton as well, which is fine, just make a note
  */
  color: `${palette.neutrals.white} !important`,

  '&:hover, &:focus': {
    backgroundColor: darken(0.1, palette.brand.indigo),
  },

  '&:active': {
    backgroundColor: darken(0.2, palette.brand.indigo),
  },

  '&:disabled': {
    backgroundColor: lighten(0.3, palette.brand.indigo),
    borderColor: lighten(0.3, palette.brand.indigo),
  },
});

const ghostPrimaryButtonColors = css({
  color: palette.brand.indigo,
  backgroundColor: 'transparent',
  border: `1px solid ${palette.brand.indigo}`,

  '&:hover, &:focus': {
    backgroundColor: lighten(0.50, palette.brand.indigo),
  },

  '&:active': {
    backgroundColor: lighten(0.45, palette.brand.indigo),
  },

  '&:disabled': {
    color: lighten(0.3, palette.brand.indigo),
    borderColor: lighten(0.3, palette.brand.indigo),
  },
});

const iconPrimaryButtonColors = css({
  color: palette.neutrals.gray700,
  backgroundColor: 'transparent',
  border: 'none',

  '&:hover, &:focus': {
    color: palette.neutrals.gray900,
  },

  '&:active': {
    color: palette.neutrals.gray900,
  },

  '&:disabled': {
    color: palette.neutrals.gray600,
  },
});

const deleteButtonColors = css({
  backgroundColor: palette.brand.red,
  border: `1px solid ${palette.brand.red}`,
  color: palette.neutrals.white,

  '&:hover, &:focus': {
    backgroundColor: darken(0.1, palette.brand.red),
  },

  '&:active': {
    backgroundColor: darken(0.2, palette.brand.red),
  },

  '&:disabled': {
    backgroundColor: lighten(0.3, palette.brand.red),
    borderColor: lighten(0.3, palette.brand.red),
  },
});

const ghostDeleteButtonColors = css({
  backgroundColor: 'transparent',
  color: palette.brand.red,
  border: `1px solid ${palette.brand.red}`,

  '&:hover, &:focus': {
    backgroundColor: lighten(0.40, palette.brand.red),
  },

  '&:active': {
    backgroundColor: lighten(0.35, palette.brand.red),
  },

  '&:disabled': {
    color: lighten(0.3, palette.brand.red),
    borderColor: lighten(0.3, palette.brand.red),
  },
});

const lightButtonColors = css({
  backgroundColor: palette.neutrals.white,
  border: `1px solid ${palette.neutrals.white}`,
  color: palette.neutrals.gray800,

  '&:hover, &:focus': {
    backgroundColor: palette.neutrals.gray300,
    border: `1px solid ${palette.neutrals.gray300}`,
  },

  '&:active': {
    backgroundColor: palette.neutrals.gray400,
    border: `1px solid ${palette.neutrals.gray400}`,
  },

  '&:disabled': {
    opacity: 0.5,
  },
});

const ghostLightButtonColors = css({
  backgroundColor: 'transparent',
  color: palette.neutrals.white,
  border: `1px solid ${palette.neutrals.white}`,

  '&:hover, &:focus': {
    backgroundColor: palette.neutrals.gray300,
    color: palette.neutrals.gray800,
  },

  '&:active': {
    backgroundColor: palette.neutrals.gray400,
    color: palette.neutrals.gray800,
  },

  '&:disabled': {
    opacity: 0.5,
  },
});

// Sizes
// Note: All the buttons have 1px less on the top and bottom padding from the Designs to account for the 1px border and get the same height
const standardButtonStyles = css({
  padding: '.8125rem 1.25rem',
  lineHeight: '1.0625rem',
});

const smallButtonStyles = css({
  padding: '.4375rem 1rem',
  fontSize: '.875rem',
  lineHeight: '1rem',
});

const miniButtonStyles = css({
  padding: '.3125rem .875rem',
  fontSize: '.6875rem',
  lineHeight: '.8125rem',
});

// Maps
const primaryStyleMap = {
  primary: primaryButtonColors,
  danger: deleteButtonColors,
  light: lightButtonColors,
};

const ghostStyleMap = {
  primary: ghostPrimaryButtonColors,
  danger: ghostDeleteButtonColors,
  light: ghostLightButtonColors,
};
const iconStyleMap = {
  primary: iconPrimaryButtonColors,
  danger: iconPrimaryButtonColors,
  light: iconPrimaryButtonColors,
};

const sizeMap = {
  standard: standardButtonStyles,
  small: smallButtonStyles,
  mini: miniButtonStyles,
};

// Types
const leadrButtonColorArray = ['primary', 'danger', 'light'] as const;
export type leadrButtonColor = typeof leadrButtonColorArray[number];

const leadrButtonVariantArray = ['default', 'ghost', 'text', 'icon'] as const;
type leadrButtonVariant = typeof leadrButtonVariantArray[number];

const leadrButtonSizeArray = ['standard', 'small', 'mini'] as const;
type leadrButtonSize = typeof leadrButtonSizeArray[number];

/*
  These are here because Typescript does not actually force you to pass the right string in
  So, if someone passes something in that is not a proper value, which would be easy to do in a file that is not Typescript,
  we still want to show the default button, and not break the app
*/
function isOfTypeLeadrButtonColor(style: leadrButtonColor): boolean {
  return leadrButtonColorArray.includes(style);
}

function isOfTypeLeadrButtonVariant(variant: leadrButtonVariant): boolean {
  return leadrButtonVariantArray.includes(variant);
}

function isOfTypeLeadrButtonSize(size: leadrButtonSize): boolean {
  return leadrButtonSizeArray.includes(size);
}

interface BuildTextButtonStyles {
  textButtonColor?: string,
}

export const buildTextButtonStyles = ({
  textButtonColor = palette.brand.indigo,
}: BuildTextButtonStyles): SerializedStyles => {
  const buttonStyles = css(({
    fontWeight: 600,
    textDecoration: 'none !important',
    background: 'transparent',
    color: textButtonColor,
    border: 0,

    '&:hover:not(:disabled), &:focus:not(:disabled)': {
      color: darken(0.2, textButtonColor),
    },

    '&:disabled': {
      cursor: 'not-allowed',
      color: lighten(0.3, textButtonColor),
    },
  }));

  return css(
    buttonStyles,
  );
};

export interface LeadrButtonStyleProps {
  color?: leadrButtonColor,
  variant?: leadrButtonVariant,
  size?: leadrButtonSize,
  textButtonColor?: string,
}

export const buildLeadrButtonStyles = ({
  color = 'primary',
  variant = 'default',
  size = 'standard',
  textButtonColor,
}: LeadrButtonStyleProps): SerializedStyles => {
  if (variant === 'text') {
    return buildTextButtonStyles({ textButtonColor });
  }
  // Non text button below

  let buttonVariant = 'default';

  if (isOfTypeLeadrButtonVariant(variant)) {
    buttonVariant = variant;
  }

  // const colorMap = buttonVariant === 'default' ? primaryStyleMap : ghostStyleMap;

  let colorMap = primaryStyleMap;
  switch (buttonVariant) {
    case 'icon':
      colorMap = iconStyleMap;
      break;
    case 'ghost':
      colorMap = ghostStyleMap;
      break;
    default:
      // Handle cases where buttonVariant is not recognized
      break;
  }

  let buttonColors = colorMap.primary;
  let buttonSizes = sizeMap.standard;

  if (isOfTypeLeadrButtonColor(color)) {
    buttonColors = colorMap[color];
  }

  if (isOfTypeLeadrButtonSize(size)) {
    buttonSizes = sizeMap[size];
  }

  const buttonStyles = css(({
    borderRadius: '.1875rem',
    fontWeight: 600,
    textDecoration: 'none !important',
    '&:disabled': {
      cursor: 'not-allowed',
    },
  }), buttonColors, buttonSizes);

  return css(
    buttonStyles,
  );
};
