import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

import MorphicModule from '~Home/components/Module/MorphicModule';
import { GetHomeSettingsResponse, useHomeSettings } from '~Home/hooks/useHomeSettings';
import { HomeModule } from '~Home/const';
import { useUpdateHomeSettings } from '~Home/hooks/useUpdateHomeSettings';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { filterHomeModules, getVisibleModuleList } from '~Home/functions/utils';
import { palette } from '~Common/styles/colors';
import { forDesktopObject, forTabletObject } from '~Common/styles/mixins';
import { useActivePulseSurvey } from '~Insights/hooks/usePulseSurvey';

export enum HomeModuleColumnArea {
  column0 = 0,
  column1 = 1,
  column2 = 2,
}

export const getOrderedColumnModules = (homeModuleSettings: GetHomeSettingsResponse, columnNumber: number): HomeModule[] => {
  const filteredModuleSettings = filterHomeModules(homeModuleSettings);
  const columnKeys = getVisibleModuleList(filteredModuleSettings, columnNumber);

  return columnKeys.sort((a, b) => (homeModuleSettings[a].rank - homeModuleSettings[b].rank));
};

const styles = {
  container: css({
    display: 'grid',
    columnGap: '1.875rem',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
      "${HomeModuleColumnArea[0]}"
      "${HomeModuleColumnArea[1]}"
      "${HomeModuleColumnArea[2]}"
    `,
  }, forTabletObject({
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: `
      "${HomeModuleColumnArea[0]} ${HomeModuleColumnArea[1]}"
      "${HomeModuleColumnArea[0]} ${HomeModuleColumnArea[2]}"
      "${HomeModuleColumnArea[0]}             -             "
    `,
  }), forDesktopObject({
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateAreas: `
      "${HomeModuleColumnArea[0]} ${HomeModuleColumnArea[1]} ${HomeModuleColumnArea[2]}"
    `,
  })),
  column: (index: number, isEmpty: boolean, isDraggingOver: boolean) => css({
    display: 'flex',
    flexDirection: 'column',
    // gap: '1.25rem',
    minWidth: 0,
    gridArea: HomeModuleColumnArea[index],
    border: '0.125rem dotted transparent',
    borderRadius: '0.5rem',
  }, isEmpty && {
    '&:hover': {
      borderColor: palette.neutrals.gray300,
    },
  }, isDraggingOver && {
    borderColor: `${palette.neutrals.gray700} !important`,
  }),
  module: (isDragging: boolean) => css({
    marginBottom: '1.25rem',
  }, isDragging && {
    pointerEvents: 'none',
  }),
  placeholder: css({
    backgroundColor: 'red',
  }),
};

interface ViewProps {
  columns: HomeModule[][],
  onDragEnd: (result: DropResult) => void,
}

const View = ({
  columns,
  onDragEnd,
}: ViewProps): JSX.Element => (
  <DragDropContext
    onDragEnd={onDragEnd}
  >
    <div css={styles.container}>
      { columns.map((column, columnIndex) => (
        <Droppable
          // eslint-disable-next-line react/no-array-index-key
          key={`column${columnIndex}`}
          droppableId={columnIndex.toString()}
          isDropDisabled={false}
        >
          {(dropProvided, dropSnapshot) => (
            <div
              {...dropProvided.droppableProps}
              ref={dropProvided.innerRef}
              css={styles.column(columnIndex, !column.length, dropSnapshot.isDraggingOver)}
            >
              {column.map((type, index) => (
                <Draggable
                  key={type}
                  draggableId={type}
                  index={index}
                  isDragDisabled={false}
                >
                  {(dragProvided, dragSnapshot) => (
                    <div
                      ref={dragProvided.innerRef}
                      css={styles.module(dragSnapshot.isDragging)}
                      {...dragProvided.draggableProps}
                    >
                      <MorphicModule
                        type={type}
                        dragHandleProps={dragProvided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}

              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </div>
  </DragDropContext>
);

const Home = (): JSX.Element => {
  const { data: homeSettingsData } = useHomeSettings();
  const { mutate: doUpdate } = useUpdateHomeSettings();
  const userId = getUserId();
  const orgId = getOrganizationId();

  const { survey } = useActivePulseSurvey();

  const columns = useMemo(() => {
    const homeSettings = homeSettingsData?.response;
    if (!homeSettings) {
      return [];
    }

    if (HomeModule.EngagementSurvey in homeSettings && !survey) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore -- This is a weird typing issue that's gnarlier to fix than it is to ignore.
      delete homeSettings[HomeModule.EngagementSurvey];
    }

    const column0 = getOrderedColumnModules(homeSettings, 0);
    const column1 = getOrderedColumnModules(homeSettings, 1);
    const column2 = getOrderedColumnModules(homeSettings, 2);

    return [
      column0,
      column1,
      column2,
    ];
  }, [homeSettingsData?.response, survey]);

  const onDragEnd = useCallback((result: DropResult) => {
    const { destination, source } = result;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const updatedSettings = filterHomeModules(homeSettingsData!.response);

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceColumn = columns[Number(source.droppableId)];
    const destinationColumn = columns[Number(destination.droppableId)];

    const movedType = sourceColumn[source.index];
    sourceColumn.splice(source.index, 1);
    destinationColumn.splice(destination.index, 0, movedType);

    sourceColumn.forEach((module, index) => {
      updatedSettings[module].rank = index;
    });

    if (sourceColumn !== destinationColumn) {
      destinationColumn.forEach((module, index) => {
        updatedSettings[module].rank = index;
      });
    }

    updatedSettings[movedType].column = Number(destination.droppableId);

    doUpdate({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      orgId: orgId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      userId: userId!,
      settings: updatedSettings,
    });
  }, [
    orgId,
    userId,
    homeSettingsData,
    doUpdate,
    columns,
  ]);

  const hookProps = {
    columns,
    onDragEnd,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default Home;
