import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { useGetLinkedGoalsById } from '~Goals/hooks/linkGoals/useGetLinkedGoalsById';
import { Goals } from '@leadr-hr/types';
import { ShowLinkGoalModalProps, useShowLinkGoalModal } from '~Goals/hooks/utils/useShowLinkGoalModal';
import { UseLinkGoalWithExistingGoalLinksReturn, useLinkGoalWithExistingGoalLinks } from '~Goals/hooks/utils/useLinkGoalWithExistingGoalLinks';
import { queryClient } from '~Common/const/queryClient';
import { goalKeys } from '~Goals/const/queryKeys';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { getOrganizationId } from '~Common/utils/localStorage';
import LinkedGoalModal from './LinkGoalModal';
import ParentGoal from './ParentGoal';
import SupportingGoals from './SupportingGoals';

const styles = {
  linkedGoals: css({}),
  parentGoal: css({
    borderBottom: `1px solid ${palette.neutrals.gray300}`,
    paddingBottom: '1.125rem',
    marginBottom: '1.125rem',
  }),
};

interface ViewProps extends Pick<UseLinkGoalWithExistingGoalLinksReturn, 'linkParentGoal' | 'linkSupportingGoals'> {
  parentGoal: Goals.LinkedGoal | undefined,
  supportingGoals: Goals.LinkedGoal[] | undefined,
  canLinkGoal: boolean,
  showModal: boolean,
  goalId: string,
  modalProps: ShowLinkGoalModalProps,
}

const View = ({
  parentGoal,
  supportingGoals,
  canLinkGoal,
  showModal,
  goalId,
  modalProps,
  linkParentGoal,
  linkSupportingGoals,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.linkedGoals}
    {...props}
  >
    <ParentGoal
      css={styles.parentGoal}
      parentGoal={parentGoal}
      canLinkGoal={canLinkGoal}
      goalId={goalId}
    />
    <SupportingGoals
      supportingGoals={supportingGoals}
      canLinkGoal={canLinkGoal}
      goalId={goalId}
    />
    {showModal && (
      <LinkedGoalModal
        showModal={showModal}
        goalId={goalId}
        linkParentGoal={linkParentGoal}
        linkSupportingGoals={linkSupportingGoals}
        {...modalProps}
      />
    )}
  </div>
);

interface LinkedGoalsProps extends Pick<ViewProps, 'canLinkGoal'> {
  goal: Goals.Goal,
}

const LinkedGoals = ({
  goal,
  ...props
}: LinkedGoalsProps): JSX.Element => {
  const { data } = useGetLinkedGoalsById({ goalId: goal.goalId });
  const { useIsModalOpen, useGetModalProps, closeModal } = useShowLinkGoalModal();
  const showModal = useIsModalOpen();
  const modalProps = useGetModalProps();

  const { linkParentGoal, linkSupportingGoals } = useLinkGoalWithExistingGoalLinks({
    goalId: goal.goalId,
    onSuccess: async () => {
      closeModal();
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeGoals(getOrganizationId() ?? '') });
      await queryClient.invalidateQueries({ queryKey: goalKeys.all });
    },
  });

  const hookProps = {
    parentGoal: data?.response.parentGoal,
    supportingGoals: data?.response.childGoals,
    showModal,
    goalId: goal.goalId,
    modalProps,
    linkParentGoal,
    linkSupportingGoals,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LinkedGoals;
