import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import RadioButtons from '~Common/components/Drawers/RadioButton';
import { SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';

import { useGetFormattedMultipleChoiceMemoized } from '../../../Hooks/useGetFormattedMultipleChoice';

const View = ({
  formattedMultipleChoiceOptions, id, stringifiedAnswer, value, setValue, ...props
}) => (
  <>
    <RadioButtons
      name="radioButtons"
      options={formattedMultipleChoiceOptions}
      onChange={(newValue) => { setValue(newValue); }}
      initialValue={value}
      isRequired
      {...props}
    />
    <input type="hidden" name={id} value={stringifiedAnswer} />
  </>

);

View.propTypes = {
  formattedMultipleChoiceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.number.isRequired,
  stringifiedAnswer: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

View.defaultProps = {};

const SurveyMultipleChoice = ({
  question, isReadOnly, initialValue, ...props
}) => {
  const formattedMultipleChoiceOptions = useGetFormattedMultipleChoiceMemoized(
    question?.multipleChoiceOptions || (typeof question?.configuration === 'string' ? JSON.parse(question?.configuration) : question?.configuration),
    isReadOnly,
  );

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const [stringifiedAnswer, setStringifiedAnswer] = useState();

  useEffect(() => {
    setStringifiedAnswer(JSON.stringify({
      id: question.id,
      response: value,
    }));
  }, [value, question]);

  const hookProps = {
    formattedMultipleChoiceOptions,
    stringifiedAnswer,
    value,
    setValue,
    ...question,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SurveyMultipleChoice.propTypes = {
  question: SURVEY_QUESTION_SHAPE,
  isReadOnly: PropTypes.bool,
  initialValue: PropTypes.string,
};

SurveyMultipleChoice.defaultProps = {
  question: {},
  isReadOnly: false,
  initialValue: '',
};

export { View };
export default SurveyMultipleChoice;
