import { css } from '@emotion/react';
import { hexToRGBA, palette } from '~Common/styles/colors';

const styles = {
  emptyState: css({
    color: palette.neutrals.gray900,
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    fontStyle: 'italic',
    padding: '.5rem 1rem',
  }),
};

const EmptyState = ({ ...props }): JSX.Element => (
  <div
    css={styles.emptyState}
    {...props}
  >
    [no topic provided]
  </div>
);

export default EmptyState;
