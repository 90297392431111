import { css } from '@emotion/react';
import { FORM_STYLES } from '~Goals/const/styles';
import { CardSkeleton } from '~Common/V3/components/Card';
import { InteriorTopBar } from '../GoalsTopBar/InteriorTopBar';

const styles = {
  ...FORM_STYLES,
  inlineBlock: css({
    display: 'inline-block',
  }),
  buttonSpacing: css({
    marginRight: '.625rem',
  }),
  largeButton: css({
    width: '9.0625rem',
    height: '2.8125rem',
  }),
  button: css({
    width: '5.3125rem',
    height: '2.8125rem',
  }),
  backButton: css({
    width: '70px',
    height: '20px',
  }),
  formField: css({
    flex: 1,
    maxWidth: 'inherit',
    height: '5rem',
  }),
  textArea: css({
    marginTop: '.75rem',
    width: '100%',
    maxWidth: '100%',
    height: '10.625rem',
  }),
  container: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1.5rem', /* Add some spacing between grid items */
  }),
  containerOneColumn: css({
    gridTemplateColumns: '1fr',
  }),
  containerThreeColumns: css({
    gridTemplateColumns: '1fr 1fr 1fr',
  }),
  containerTitle: css({
    gridTemplateColumns: '9fr 1fr',
  }),
  containerCheckbox: css({
    gridTemplateColumns: '.5fr 1fr 7fr',
  }),

};

interface CreateGoalFormSkellyProps {
  showSkeleton: boolean,
  isMobile: boolean,
}

export function CreateGoalFormSkelly({
  showSkeleton,
  isMobile,
}: CreateGoalFormSkellyProps): JSX.Element {
  return (
    <>
      {showSkeleton && (
        <>
          <InteriorTopBar
            renderLeftSide={() => (
              <>
                <CardSkeleton css={styles.backButton} />
              </>
            )}
            renderRightSide={() => (
              <>
                <CardSkeleton css={[styles.inlineBlock, styles.buttonSpacing, styles.button]} />
                <CardSkeleton css={[styles.inlineBlock, styles.largeButton]} />
              </>
            )}
          />
          <div css={[styles.setupContainer(isMobile), styles.boxShadow]}>
            <div
              css={[styles.container, styles.containerTitle]}
            >
              <CardSkeleton css={[styles.inlineBlock, styles.formField]} />
              <CardSkeleton css={[styles.inlineBlock, styles.formField]} />
            </div>
            <div
              css={[styles.container, styles.containerOneColumn, styles.formBorderSpacer, styles.formSpacingTop, styles.formSpacing]}
            >
              <CardSkeleton css={[styles.inlineBlock, styles.formField, styles.textArea]} />
            </div>
            <div
              css={[styles.container, styles.containerOneColumn, styles.formBorderSpacer, styles.formSpacing]}
            >
              <CardSkeleton css={[styles.inlineBlock, styles.formField]} />
            </div>
            <div
              css={[styles.container, styles.formBorderSpacer, styles.formSpacing]}
            >
              <CardSkeleton css={[styles.inlineBlock, styles.formField]} />
              <CardSkeleton css={[styles.inlineBlock, styles.formField]} />
            </div>
            <div
              css={[styles.container, styles.containerOneColumn]}
            >
              <CardSkeleton css={[styles.inlineBlock, styles.formField]} />
              <CardSkeleton css={[styles.inlineBlock, styles.formField]} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
