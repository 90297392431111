import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import LeadrModal from '~Common/V3/components/LeadrModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import {
  MultiRadio,
  Form,
} from '~Common/V3/components/uncontrolled';
import {
  UseFormReturn,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import { AutoCompleteTags } from '~Common/V3/components/uncontrolled/AutocompleteTags';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import {
  RadioOptionsShape, ReviewCycleDetail, ReviewCycleStatusEnum, RevieweeReviewStatuses,
  modalParamsProps, ReOpenReviewOptionsValues, ParticipantTypeEnum, ManagerReviewStatuses,
  SecondaryManagerReviewStatuses, reviewerNotStartedExcludedStatuses, revieweeNotStartedExcludedStatuses, secondaryNotStartedExcludedStatuses,
} from '../Const/types';
import { buildRadioOptions, getReopenReviewIds } from '../Const/functions';
import { FormValues, conformToDto, reOpenReviewFormResolver } from '../schemata/ReOpenReviewSchemata';
import { useReOpenCycle } from '../Hooks/useReOpenCycle';
import { useReopenReviews } from '../Hooks/useReopenReviews';
import { useGetCycleById } from '../Hooks/useGetCycleById';

const styles = {
  title: css({
    color: palette.brand.indigo,
  }),
  description: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
  descriptionSpace: css({
    marginTop: '1rem',
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  subTitle: css({
    color: palette.brand.indigo,
    fontSize: '1rem',
    fontWeight: '600',
    margin: '.75rem 0 .375rem 0',
  }),
  radioStyles: css({
    '> label': {
      display: 'none',
      margin: '0 !important',
    },
    '[role="radiogroup"]': {
      '> label': {
        background: 'none !important',
        border: 'none !important',
        padding: '0 !important',
        marginBottom: '0 !important',

        '.MuiFormControlLabel-label': {
          width: '100% !important',

        },
      },
    },
  }),
  radioContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  informationIcon: css({
    color: palette.neutrals.gray400,
  }),
  boldText: css({
    fontWeight: '600',
  }),
};

type SelectOption = {
    label: string,
    value: string,
  };

interface ViewProps {
  open: boolean,
  handleModalDisplay: (value: boolean, params?: modalParamsProps) => void,
  radioOptions: RadioOptionsShape[],
  formContext: UseFormReturn<FormValues>,
  reOpenReveiwOptionsValue: string | undefined,
  selectedRevieweesAutocomplete: SelectOption[],
  onFormSubmit: SubmitHandler<FormValues>,
  disableReOpenReviewsButton: () => boolean,
  reOpenType: string,
}

const View = ({
  open,
  handleModalDisplay,
  radioOptions,
  formContext,
  reOpenReveiwOptionsValue,
  selectedRevieweesAutocomplete,
  onFormSubmit,
  disableReOpenReviewsButton,
  reOpenType,
}: ViewProps): JSX.Element => (
  <LeadrModal
    open={open}
    onClose={() => handleModalDisplay(false)}
  >
    <LeadrModal.Header>
      <LeadrModal.Title css={styles.title}>
        {`Are you sure you would like to reopen ${reOpenType}`}
        ?
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body>
      <p css={styles.description}>
        This action will allow all participants on the review to edit their responses and require them to resubmit their reviews.
      </p>
      <p css={[styles.description, styles.descriptionSpace]}>
        <span css={styles.boldText}>Please note</span>
        : If final comments were left, they will be deleted.
      </p>
      <p css={styles.subTitle}>Choose Reviews to Open</p>
      <Form
        name="review-reopen-form"
        formContext={formContext}
        onSubmit={() => null}
      >
        <MultiRadio
          name="reOpenReveiwOptions"
          vertical
          radioStyles={styles.radioStyles}
          label=""
          radios={radioOptions.map((option: RadioOptionsShape) => ({
            name: (
              <p css={styles.radioContent}>
                <span>{option.name}</span>
                <Tooltip content={option.tooltip}>
                  <div>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      css={styles.informationIcon}
                    />
                  </div>
                </Tooltip>
              </p>
            ),
            value: option.value,
          }))}
        />
        {reOpenReveiwOptionsValue === ReOpenReviewOptionsValues.Select && (
        <AutoCompleteTags
          multiple
          name="reviewsIds"
          options={selectedRevieweesAutocomplete}
          label="Select Reviews"
          getOptionSelected={(option: SelectOption, optionValue: string) => option.value === optionValue}
          renderOption={(optionProps: Record<string, unknown>, option: SelectOption) => (
            <li {...optionProps} key={option.value}>
              {option.label}
            </li>
          )}
          defaults={undefined}
        />
        )}
      </Form>
    </LeadrModal.Body>
    <LeadrModal.Footer css={styles.footer}>
      <LeadrButton
        onClick={formContext.handleSubmit(onFormSubmit)}
        data-test-id="ReopenCycle"
        size="small"
        disabled={disableReOpenReviewsButton()}
      >
        Reopen Cycle
      </LeadrButton>
      <LeadrButton
        onClick={() => handleModalDisplay(false)}
        data-test-id="cancelReopenCycle"
        variant="ghost"
        size="small"
      >
        Cancel
      </LeadrButton>
    </LeadrModal.Footer>
  </LeadrModal>
);

interface DisplayReopenModalProps {
  open: boolean,
  handleModalDisplay: (value: boolean, params?: modalParamsProps) => void,
  filterParticipantsOnCycle: ReviewCycleDetail,
}

const DisplayReopenModal = ({
  open,
  handleModalDisplay,
  filterParticipantsOnCycle,
}: DisplayReopenModalProps): JSX.Element => {
  const reOpenCycleMutation = useReOpenCycle();
  const reOpenReviewsMutation = useReopenReviews();
  const selectedRevieweesAutocomplete = useMemo(() => filterParticipantsOnCycle.reviews
    .filter((review) => {
      const { reviewerStatus, secondaryReviewerStatus, revieweeStatus } = review;
      if (filterParticipantsOnCycle.statusEnum === ReviewCycleStatusEnum.Finished) {
        return true; // Include all reviews if filterParticipantsOnCycle.statusEnum is 4
      }
      // If we don't check this we could get false positives
      if (FlaggedEnum(filterParticipantsOnCycle.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer)) {
        return (
          !revieweeNotStartedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
          || !reviewerNotStartedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
          || !secondaryNotStartedExcludedStatuses.includes(secondaryReviewerStatus as SecondaryManagerReviewStatuses)
        );
      }
      return (
        !revieweeNotStartedExcludedStatuses.includes(revieweeStatus as RevieweeReviewStatuses)
          || !reviewerNotStartedExcludedStatuses.includes(reviewerStatus as ManagerReviewStatuses)
      );
    })
    .map((review) => {
      const filteredParticipants = review.participants.filter(
        (participant) => participant.participantTypeEnum === ParticipantTypeEnum.Reviewee,
      );

      return filteredParticipants.map((participant) => ({
        label: `${participant.firstName} ${participant.lastName}`,
        value: review.uid,
      }));
    })
    .flat(), [filterParticipantsOnCycle]);

  const { radioOptions } = buildRadioOptions(filterParticipantsOnCycle);
  const formValues = (): FormValues => ({
    reOpenReveiwOptions: '',
    reviewsIds: [],
  });
  const formContext = useForm<FormValues>({
    defaultValues: formValues(),
    resolver: reOpenReviewFormResolver,
  });
  const cycleId = filterParticipantsOnCycle.uid;
  const { isAdmin } = useUserPermissions();
  const { data: reviewCycle } = useGetCycleById({ id: cycleId, isAdmin });
  const cycleIsFinished = reviewCycle?.statusEnum === ReviewCycleStatusEnum.Finished;
  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    const formData = conformToDto(data);
    const { incompleteReviewIds, allReviewIDs, completeReviewIds } = getReopenReviewIds(filterParticipantsOnCycle);

    const reopenOptions = formData.reOpenReveiwOptions;
    let reviewIDs = [];

    if (reopenOptions === ReOpenReviewOptionsValues.All) {
      reviewIDs = allReviewIDs;
    } else if (reopenOptions === ReOpenReviewOptionsValues.Submitted) {
      reviewIDs = incompleteReviewIds;
    } else if (reopenOptions === ReOpenReviewOptionsValues.Completed) {
      reviewIDs = completeReviewIds;
    } else {
      reviewIDs = formData.reviewsIds ?? [];
    }
    if (cycleIsFinished) {
      reOpenCycleMutation({ cycleId, reviewIDs }, { onSuccess: onSuccessReOpenCycle });
    } else {
      reOpenReviewsMutation({ reviewIDs }, { onSuccess: onSuccessReOpenCycle });
    }
  };

  const onSuccessReOpenCycle = (): void => {
    handleModalDisplay(false);
  };

  const reOpenReveiwOptionsValue = formContext.watch('reOpenReveiwOptions');
  const disableReOpenReviewsButton = ():boolean => {
    if (reOpenReveiwOptionsValue === ReOpenReviewOptionsValues.Select) {
      if (formContext.watch('reviewsIds')?.length !== 0) {
        return false;
      }
      return true;
    }
    if (reOpenReveiwOptionsValue !== '') {
      return false;
    }
    return true;
  };

  const reOpenType = cycleIsFinished ? 'cycle' : 'reviews';

  const hookProps = {
    open,
    handleModalDisplay,
    radioOptions,
    formContext,
    reOpenReveiwOptionsValue,
    selectedRevieweesAutocomplete,
    onFormSubmit,
    disableReOpenReviewsButton,
    reOpenType,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default DisplayReopenModal;
