import { useMemo } from 'react';
import { ViewType } from '~Common/const/interfaces';
import { useGenericStoreAndLocalStorage } from '~Common/hooks/useGenericStoreAndLocalStorage';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';

export const useLearningViewTypeFilter = (): [ViewType, (viewType: ViewType) => void] => {
  const [value, setValue] = useGenericStoreAndLocalStorage<ViewType>({
    localStorageKey: 'learningViewTypeFilter',
    defaultValue: ViewType.GRID,
    genericStoreProps: {
      module: 'leadr',
      workflow: 'learning',
      feature: 'learning-view-type-filter',
    },
  });

  const isMobile = useIsMobileQuery();

  const valueToUse = useMemo(() => (isMobile ? ViewType.GRID : value), [isMobile, value]);

  return [
    valueToUse,
    setValue,
  ];
};
