import { css } from '@emotion/react';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { faMinus } from '@fortawesome/pro-duotone-svg-icons';
import { IMAGE_STREAM_DATA, REF_SHAPE } from '~Common/const/proptypes';
import AnimatedModal from '~Deprecated/ui/components/AnimatedModal';
import Button, { BUTTON_COLOR, BUTTON_VARIANT } from '~Common/components/Buttons/Button';
import ImagePickerPreviewAvatar from '~Deprecated/common/Components/People/Avatar/ImagePickerPreviewAvatar';
import { toast } from '~Common/components/Toasts';

const containerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  max-height: 90vh;

  & > .ReactCrop {
    overflow: auto;
  }
`;

const buttonStyles = css`
  display: flex;
  padding: 12px 0 0 0;

  & button:not(:first-of-type) {
    margin-inline-start: 1rem;
  }
`;

const footerButtons = css`
  display: flex;
  justify-content: space-between;
  z-index: 1200;
`;

const imagePreviewWrapper = css`
  display: flex;
  justify-content: center;
  position: relative;
`;

const removeBtnStyles = css`
  height: 40px;
  position: absolute;
  bottom: 1rem;
  right: calc(50% - 100px);
`;

const View = ({
  upImg,
  onSelectFile,
  filePickerRef,
  onCropChange,
  onCancel,
  onConfirm,
  onLoad,
  crop,
  previewCanvasRef,
  previewStyles,
  previewSrc,
  onRemove,
  userName,
  isLoading,
}) => (
  <div className="App">
    <div css={imagePreviewWrapper}>
      <ImagePickerPreviewAvatar imgUrl={previewSrc} name={userName} css={previewStyles} />
      {previewSrc && !isLoading && (
        <Button
          css={removeBtnStyles}
          variant={BUTTON_VARIANT.ICON}
          icon={faMinus}
          buttonColor={BUTTON_COLOR.DESTRUCTIVE}
          tooltip="Remove Image"
          onClick={onRemove}
        />
      )}
    </div>
    <div>
      <input type="file" accept="image/*" onChange={onSelectFile} ref={filePickerRef} />
    </div>
    <AnimatedModal
      isOpen={upImg}
      onClose={onCancel}
    >
      <div css={containerStyles}>
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          keepSelection
          onChange={onCropChange}
        />
        <div css={footerButtons}>
          <div />
          <div css={buttonStyles}>
            <Button
              text="Cancel"
              buttonColor={BUTTON_COLOR.DESTRUCTIVE}
              variant={BUTTON_VARIANT.TEXT}
              onClick={onCancel}
            />
            <Button
              text="Confirm"
              buttonColor={BUTTON_COLOR.DEFAULT}
              variant={BUTTON_VARIANT.TEXT}
              onClick={onConfirm}
            />
          </div>
        </div>
      </div>
    </AnimatedModal>
    <div>
      <canvas
        ref={previewCanvasRef}
        style={{
          display: 'none',
        }}
      />
    </div>
  </div>
);

View.propTypes = {
  upImg: IMAGE_STREAM_DATA,
  onSelectFile: PropTypes.func.isRequired,
  onCropChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  crop: PropTypes.shape({
    aspect: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    unit: PropTypes.string,
  }).isRequired,
  filePickerRef: REF_SHAPE.isRequired,
  previewCanvasRef: REF_SHAPE.isRequired,
  previewStyles: PropTypes.string.isRequired,
  previewSrc: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

View.defaultProps = {
  upImg: null,
};

/**
 *
 * @param handleCroppedBlob
 * @param initialValue
 * @param resetUploadFile
 * @param isLoading
 * @param props
 * @returns {JSX.Element}
 *
 * Sample Usage
 * ------------
 * <ImagePicker
 handleCroppedBlob={(blob) => {
       const previewUrl = window.URL.createObjectURL(blob);
       window.open(previewUrl, '_blank');
       window.URL.revokeObjectURL(previewUrl);
     }}
 />
 *
 */

/**
 * @deprecated Old UI
 */
const ImagePicker = ({
  handleCroppedBlob, initialValue, resetUploadFile, isLoading, ...props
}) => {
  const [upImg, setUpImg] = useState();
  const [previewSrc, setPreviewSrc] = useState(initialValue);
  const imgRef = useRef(null);
  const filePickerRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 16 });

  useEffect(() => {
    setPreviewSrc(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [crop]);

  function generateBlob(canvas, cropData) {
    if (!cropData || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        setPreviewSrc(window.URL.createObjectURL(blob));
        handleCroppedBlob(blob);
      },
      'image/png',
      1,
    );
  }

  const imageRegex = /\.(jpe?g|png)$/i;

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (!imageRegex.test(e.target.files[0].name)) {
        filePickerRef.current.value = '';
        setUpImg(null);
        toast.warning('File format not supported.');
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', () => setUpImg(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const hookProps = {
    upImg,
    filePickerRef,
    previewCanvasRef,
    crop,
    previewSrc,
    generateBlob,
    onSelectFile,
    onLoad,
    isLoading,
    onCancel: () => {
      filePickerRef.current.value = '';
      setUpImg(null);
    },
    onConfirm: () => {
      resetUploadFile();
      generateBlob(previewCanvasRef.current, crop);
      filePickerRef.current.value = '';
      setUpImg(null);
    },
    onCropChange: (c) => setCrop(c),
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ImagePicker.propTypes = {
  handleCroppedBlob: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  resetUploadFile: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ImagePicker.defaultProps = {};

export default ImagePicker;
