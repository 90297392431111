import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { palette } from '~Common/styles/colors';
import { withButtonStripper } from '~Common/styles/mixins';

const styles = {
  container: css`
    ${withButtonStripper()}
    border-radius: 8px;
    display: flex;
    padding: 16px;
    margin: auto;
    align-items: center;
    background-color: ${palette.neutrals.gray50};
  `,
  adornmentStyle: css`
    flex: 0 0 20%;
    display: flex;
    justify-content: left;
  `,
  contentText: css`
    width: 60%;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    flex: 1 1 auto;
  `,
};

const ListItem = ({
  content, renderLeftAdornment, onClick, ...props
}) => {
  const doClick = useCallback(() => onClick(content), [content, onClick]);

  return (
    <button type="button" css={styles.container} onClick={doClick} {...props}>
      {renderLeftAdornment({ style: [styles.adornmentStyle] })}
      <div css={styles.contentText}>{content}</div>
      {/* TODO: subtext */}
    </button>
  );
};

ListItem.propTypes = {
  content: PropTypes.string.isRequired,
  renderLeftAdornment: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ListItem;
