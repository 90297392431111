import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-solid-svg-icons';
import { withSpacedOutTitleStyles } from '~Common/styles/mixins';
import { ComponentProps } from 'react';
import { ReviewShape } from '~Reviews/V2/Const/types';
import { Reflection } from '../../types';
import { ReflectionCard } from '../ReflectionCard';
import { ReflectionEmptyState } from './ReflectionEmptyState';

const styles = {
  reflectionsHeading: css({
    textTransform: 'uppercase',
    marginBottom: '.375rem',
  }, withSpacedOutTitleStyles()),
  reflectionsCardListContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.375rem',
    padding: 0,
  }),
  favoritesIcon: css({
    color: palette.brand.indigo,
  }),
  emptyState: css({
    textAlign: 'center',
    color: palette.brand.indigo,
    fontSize: '0.875rem',
    fontWeight: 600,
  }),
  emptyImage: css({
    height: '100%',
    marginBottom: '0.25rem',
    maxHeight: '16rem',
    maxWidth: '16rem',
  }),
};

interface ReflectionListSectionProps extends ComponentProps<'div'> {
  reviewTopicUid: string,
  reviewUid: string,
  reflections: Reflection[] | undefined,
  headingText: string,
  review: ReviewShape,
  reflectionType: number,
  attachedSubjectIds?: string[],
}

export const ReflectionListSection = ({
  reviewUid,
  reviewTopicUid,
  reflections,
  headingText,
  review,
  reflectionType,
  attachedSubjectIds = [],
  ...props
}: ReflectionListSectionProps): JSX.Element => (
  <div {...props}>
    <p css={styles.reflectionsHeading}>
      {headingText}
    </p>
    {reflections && reflections?.length > 0 && (
      <div css={styles.reflectionsCardListContainer}>
        {reflections?.map((reflection) => (
          <ReflectionCard
            key={reflection.id}
            reflection={reflection}
            reviewUid={reviewUid}
            reviewTopicUid={reviewTopicUid}
            review={review}
            renderTopLeftItem={reflection.isFavorite ? () => (
              <FontAwesomeIcon css={styles.favoritesIcon} icon={faBookmark} />
            ) : undefined}
            isSelected={attachedSubjectIds.includes(reflection.contentId)}
          />
        ))}
      </div>
    )}
    {reflections?.length === 0 && (
      <ReflectionEmptyState
        reflectionType={reflectionType}
        headingText={headingText}
      />
    )}
  </div>
);
