import { createReducer } from '../redux/reducers/index';

const initialState = {
  data: {},
  personalityId: '',
  activeTabIndex: 0,
  hasPendingCall: false,
  hasUpdateCallPending: '',
};

const { reducer, registerAction } = createReducer('personalityDetails', initialState);

export { reducer, registerAction, initialState };
