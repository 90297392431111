import { css } from '@emotion/react';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Goals } from '@leadr-hr/types';
import { useMemo } from 'react';
import Tooltip from '~Common/components/Tooltip';
import { palette } from '~Common/styles/colors';
import GoalPermissionsLearnMore from '~Goals/components/Shared/GoalPermissionsLearnMore';

const Tooltips = {
  [Goals.GoalContextType.Personal]: `
    Public Goals are visible to participants in the goal, as well as your manager and executives.
    Private goals are only visible to the participants in the goal.
  `,
  [Goals.GoalContextType.Team]: `
    Public team goals are visible to all team members and participants in the goal, as well as executives.
    Private team goals are visible only to specified collaborators and viewers.
  `,
  [Goals.GoalContextType.Organization]: `
    Public organization goals are visible to everyone. Private organization goals are visible only to collaborators and viewers.
  `,
};

const styles = {
  tooltipText: css({
    marginRight: '0.25rem',
  }),
  infoIcon: css({
    color: palette.neutrals.gray400,
  }),
};

interface ViewProps {
  privateGoalTooltip: JSX.Element,
}

const View = ({
  privateGoalTooltip,
  ...props
}: ViewProps): JSX.Element => (
  <Tooltip content={privateGoalTooltip}>
    <div css={styles.infoIcon} {...props}>
      <FontAwesomeIcon
        icon={faInfoCircle}
      />
    </div>
  </Tooltip>
);

interface InfoTooltipProps {
  goalTypeSelected: string,
}

const InfoTooltip = ({
  goalTypeSelected,
  ...props
}: InfoTooltipProps): JSX.Element => {
  const privateGoalTooltip = useMemo(() => {
    let text = '';

    if (goalTypeSelected in Tooltips) {
      text = Tooltips[goalTypeSelected as keyof typeof Tooltips];
    }

    return (
      <>
        <span css={styles.tooltipText}>
          {text}
        </span>
        <GoalPermissionsLearnMore textButtonColor={palette.neutrals.white} />
      </>
    );
  }, [goalTypeSelected]);

  const hookProps = {
    privateGoalTooltip,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default InfoTooltip;
