import { css } from '@emotion/react';
import { SelectChangeEvent } from '@mui/material';
import { SyntheticEvent, useCallback } from 'react';
import { DropdownItem } from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {
  DashboardTab, ReflectionsFavoriteType, ReflectionsIsActiveType, ReflectionsItem, ReflectionType,
} from '../types';
import ReflectionsDirectReportsFilter from './Filters/ReflectionsDirectReportsFilter';
import ReflectionsFavoriteFilter from './Filters/ReflectionsFavoriteFilter';
import ReflectionsPerPageFilter from './Filters/ReflectionsPerPageFilter';
import ReflectionsToggleTabs from './Filters/ReflectionsToggleTabs';
import ReflectionsTypeFilter from './Filters/ReflectionsTypeFilter';
import ReflectionsIsActiveFilter from './Filters/ReflectionsIsActiveFilter';

const styles = {
  tableControlsWrapper: css({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
    marginBottom: '1.5rem',
    flexWrap: 'wrap',
    gap: '1rem',
  }),
};

export interface ViewProps {
  changeTab: (event: SyntheticEvent, tab: DashboardTab) => void,
  expandedTab: DashboardTab,
  onFavoriteChange: (event: SelectChangeEvent<ReflectionsFavoriteType[]>) => void,
  onIsActiveChange: (event: SelectChangeEvent<ReflectionsIsActiveType[]>) => void,
  onReflectionsTypeChange: (event: SelectChangeEvent<ReflectionType[]>) => void,
  onDirectReportsChange: (event: SelectChangeEvent<string[]>) => void,
  reflectionsFavoriteValue: ReflectionsFavoriteType[],
  reflectionsIsActiveValue: ReflectionsIsActiveType[],
  reflectionsTypeValue: ReflectionType[],
  directReport: string[],
  directReportsFilterItems: DropdownItem<ReflectionsItem[] | string[]>[],
  tablePageSize: string,
  setTablePageSize: (tablePageSize: string) => void,
  isLoading: boolean,
}

const View = ({
  changeTab,
  expandedTab,
  onFavoriteChange,
  onIsActiveChange,
  onReflectionsTypeChange,
  onDirectReportsChange,
  reflectionsFavoriteValue,
  reflectionsIsActiveValue,
  reflectionsTypeValue,
  directReport,
  directReportsFilterItems,
  tablePageSize,
  setTablePageSize,
  isLoading,
}: ViewProps): JSX.Element => (
  <div
    css={styles.tableControlsWrapper}
  >
    {isLoading && (
      <>
        <Stack spacing={1} direction="row">
          <Skeleton animation="wave" variant="rectangular" height={35} width={120} />
          <Skeleton animation="wave" variant="rectangular" height={35} width={120} />
        </Stack>
        <div>
          <Stack spacing={1} direction="row">
            <Skeleton animation="wave" variant="rectangular" height={35} width={120} />
            <Skeleton animation="wave" variant="rectangular" height={35} width={120} />
            <Skeleton animation="wave" variant="rectangular" height={35} width={120} />
          </Stack>
        </div>
      </>
    )}
    {!isLoading && (
      <>
        <ReflectionsToggleTabs
          changeTab={changeTab}
          expandedTab={expandedTab}
        />
        <div>
          <ReflectionsFavoriteFilter
            data-test-id="reflectionsFavoriteFilter"
            value={reflectionsFavoriteValue}
            onChange={onFavoriteChange}
          />
          <ReflectionsTypeFilter
            data-test-id="reflectionsTypeFilter"
            value={reflectionsTypeValue}
            onChange={onReflectionsTypeChange}
          />
          {directReportsFilterItems.length > 1 && expandedTab === DashboardTab.Manager && (
            <ReflectionsDirectReportsFilter
              data-test-id="reflectionsDirectReportsFilter"
              value={directReport}
              onChange={onDirectReportsChange}
              directReportsFilterItems={directReportsFilterItems}
            />
          )}
          <ReflectionsIsActiveFilter
            data-test-id="reflectionsIsActiveFilter"
            value={reflectionsIsActiveValue}
            onChange={onIsActiveChange}
          />
          <ReflectionsPerPageFilter
            data-test-id="reflectionsPerPageFilter"
            tablePageSize={tablePageSize}
            setTablePageSize={setTablePageSize}
          />
        </div>
      </>
    )}
  </div>
);

interface ReflectionsTableControlsProps {
  changeTab: (event: SyntheticEvent, tab: DashboardTab) => void,
  expandedTab: DashboardTab,
  directReport: string[],
  setDirectReport: (directReport: string[]) => void,
  directReportsFilterItems: DropdownItem<ReflectionsItem[] | string[]>[],
  tablePageSize: string,
  setTablePageSize: (tablePageSize: string) => void,
  isLoading: boolean,
  reflectionsFavoriteValue: ReflectionsFavoriteType[],
  setReflectionsFavoriteValue: (reflectionsFavoriteValue: ReflectionsFavoriteType[]) => void,
  reflectionsIsActiveValue: ReflectionsIsActiveType[],
  setIsActiveValue: (reflectionsIsActiveValue: ReflectionsIsActiveType[]) => void,
  reflectionsTypeValue: ReflectionType[],
  setReflectionsTypeValue: (reflectionsTypeValue: ReflectionType[]) => void,
}

const ReflectionsTableControls = ({
  changeTab,
  expandedTab,
  directReport,
  setDirectReport,
  directReportsFilterItems,
  tablePageSize,
  setTablePageSize,
  isLoading,
  reflectionsFavoriteValue,
  setReflectionsFavoriteValue,
  reflectionsIsActiveValue,
  setIsActiveValue,
  reflectionsTypeValue,
  setReflectionsTypeValue,
}: ReflectionsTableControlsProps): JSX.Element => {
  const onFavoriteChange = useCallback((event: SelectChangeEvent<ReflectionsFavoriteType[]>) => {
    setReflectionsFavoriteValue([event.target.value] as ReflectionsFavoriteType[]);
  }, [setReflectionsFavoriteValue]);

  const onIsActiveChange = useCallback((event: SelectChangeEvent<ReflectionsIsActiveType[]>) => {
    setIsActiveValue([event.target.value] as ReflectionsIsActiveType[]);
  }, [setIsActiveValue]);

  const onReflectionsTypeChange = useCallback((event: SelectChangeEvent<ReflectionType[]>) => {
    setReflectionsTypeValue([event.target.value] as unknown as ReflectionType[]);
  }, [setReflectionsTypeValue]);

  const onDirectReportsChange = useCallback((event: SelectChangeEvent<string[]>) => {
    setDirectReport([event.target.value] as string[]);
  }, [setDirectReport]);

  const hookProps = {
    changeTab,
    expandedTab,
    onFavoriteChange,
    onIsActiveChange,
    onReflectionsTypeChange,
    onDirectReportsChange,
    reflectionsFavoriteValue,
    reflectionsIsActiveValue,
    reflectionsTypeValue,
    directReportsFilterItems,
    directReport,
    tablePageSize,
    setTablePageSize,
    isLoading,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default ReflectionsTableControls;
