import { useShortenFromNowString } from '~Common/hooks/dates/useShortenFromNowString';
import moment from 'moment';

export const useGetTopicTimeString = (unformattedTopicTime?: string | number): string => {
  const { getShortFromNowString } = useShortenFromNowString();

  let formattedTopicTime = '';

  formattedTopicTime = getShortFromNowString(moment(unformattedTopicTime).fromNow());

  return formattedTopicTime;
};
