import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import DrawerAvatar from '~Deprecated/common/Components/People/Avatar/DrawerAvatar';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import { useGetPersonById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import { noop } from '~Deprecated/utils/';
import { gradients } from '~Deprecated/ui/styles/colors';

const styles = {
  drawerItem: (status) => css`
    width: 100%;
    padding: 1rem;
    background: ${status === ANSWER_STATUS.COMPLETED ? gradients.green : gradients.sky};
    border-radius: 0.5rem;
    display: flex;
    justify-content: flex-start;
    margin: 1rem 0;
  `,
  itemInfo: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: transparent;
    font-size: 16px;
    color: white;
    padding: 0 1rem;
  `,
  rightButtonContainer: css`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
  `,
};

const View = ({
  person, status, renderRightButton, ...props
}) => (
  <div
    css={styles.drawerItem(status)}
    {...props}
  >
    <div>
      <DrawerAvatar
        imgUrl={person?.profileImageUrl}
        name={`${person?.firstName} ${person?.lastName}`}
      />
    </div>
    <div css={styles.itemInfo}>
      <h4>
        {`${person?.firstName} ${person?.lastName}`}
      </h4>
    </div>
    {renderRightButton && (
      <div css={styles.rightButtonContainer}>
        {renderRightButton()}
      </div>
    )}
  </div>
);

View.propTypes = {
  person: ATTENDEE_SHAPE.isRequired,
  status: PropTypes.string.isRequired,
  renderRightButton: PropTypes.func,
};

View.defaultProps = {
  renderRightButton: noop,
};

const PersonDisplay = ({ userId, ...props }) => {
  const person = useGetPersonById({ id: userId });

  const hookProps = {
    person,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

PersonDisplay.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default PersonDisplay;
