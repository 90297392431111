import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';
import { ReviewStatusColor } from '../Const/types';
import { CENTER_CONTENTS } from '../Const/pageStyles';

const styles = {
  wrapper: css({
    background: palette.brand.indigo,
    padding: '.75rem 1.5rem',
    borderRadius: '.5rem',
    color: palette.neutrals.white,
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  status: css({
    borderRadius: '50%',
    display: 'inline-block',
    height: '0.625rem',
    marginRight: '0.625rem',
    width: '0.625rem',
  }),
  boldText: css({
    fontWeight: '600',
    marginRight: '.625rem',
  }),
  statusColor: (status: string) => {
    let background = '';
    switch (status) {
      case ReviewStatusColor.Green:
        background = palette.brand.green;
        break;
      case ReviewStatusColor.Orange:
        background = palette.brand.orange;
        break;
      default:
        background = palette.neutrals.gray600;
    }
    return css`background: ${background}`;
  },
  avatar: css({
    marginRight: '.5rem',
  }),
  ...CENTER_CONTENTS,
};

interface ViewProps extends ComponentProps<'div'> {
  statusColor: string,
  renderStatusText: string,
  renderButton?: () => JSX.Element,
}

const View = ({
  statusColor,
  renderStatusText,
  renderButton,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.wrapper}
    {...props}
  >
    <div css={styles.stepper}>
      <span css={[styles.status, styles.statusColor(statusColor)]} />
      {renderStatusText}
    </div>
    {renderButton?.()}
  </div>
);

interface ReviewNextStepStepperProps {
  statusColor: string,
  renderStatusText: string,
  renderButton?: () => JSX.Element,
}

const ReviewNextStepStepper = ({
  statusColor,
  renderStatusText,
  renderButton = () => (<></>),
  ...props
}: ReviewNextStepStepperProps): JSX.Element => {
  const hookProps = {
    statusColor,
    renderStatusText,
    renderButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default ReviewNextStepStepper;
