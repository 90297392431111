import { css } from '@emotion/react';
import { LearningStatus, ReceivedPlaylistLearning } from '~Learning/const/interfaces';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import { withLineClamp } from '~Common/styles/mixins';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  learningCard: (isLearningSelected: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
    border: `1px solid ${palette.neutrals.white}`,

    '&:not(:last-child)': {
      marginBottom: '1.125rem',
    },
  }, isLearningSelected && ({
    border: `1px solid ${palette.brand.blue}`,
  })),
  contentDetails: css({
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    columnGap: '1.125rem',
    alignItems: 'center',
  }),
  learningTitle: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1rem',
  }),
  introduction: css({
    color: palette.neutrals.gray500,
    fontSize: '.8125rem',
    marginTop: '.625rem',
  }, withLineClamp(2)),
  dueDateFromNow: css({
    marginTop: '.625rem',
  }),
};

interface LearningCardProps {
  learning: ReceivedPlaylistLearning,
  onLearningClick: () => void,
  isLearningSelected: boolean,
  renderRightItem?: () => JSX.Element,
}

const LearningCard = ({
  learning,
  onLearningClick,
  isLearningSelected,
  renderRightItem,
}: LearningCardProps): JSX.Element => (
  <Card
    onClick={onLearningClick}
    css={styles.learningCard(isLearningSelected)}
    renderContents={() => (
      <>
        <div css={styles.contentDetails}>
          <PreviewWithExternalNavigation
            viewOption="Thumbnail"
            contentUrl={learning.contentUrl}
          />
          <div>
            <div css={styles.learningTitle}>{learning.title}</div>
            {learning.introduction && (
              <HTMLRenderer css={styles.introduction} htmlText={learning.introduction} />
            )}
            {learning.status === LearningStatus.INCOMPLETE && (
              <DueDateFromNow
                css={styles.dueDateFromNow}
                dueDate={learning.dueDate}
              />
            )}
          </div>
        </div>
        {renderRightItem?.()}
      </>
    )}
  />
);

export default LearningCard;
