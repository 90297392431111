import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';

export const PEOPLE_FILTERS_QUERY_KEY = [getOrganizationId() ?? '', 'peopleFilters'];

export interface DepartmentsFromAPI {
  value: string,
  total: number,
  invitedTotal: number,
  importedTotal: number,
  deactivatedTotal: number,
}
export interface AccountTypesFromAPI {
  name: string,
  total: number,
  invitedTotal: number,
  importedTotal: number,
  deactivatedTotal: number,
  typeId: string,
}
export interface UserGroupsFromAPI {
  name: string,
  total: number,
  invitedTotal: number,
  importedTotal: number,
  deactivatedTotal: number,
  teamId: string,
}

interface GetPeopleFiltersReturn {
  accountTypes: AccountTypesFromAPI[],
  departments: DepartmentsFromAPI[],
  teams: UserGroupsFromAPI[],
  myTeams: UserGroupsFromAPI[],
  workingGeniusSkills: string[],
}

const getPeopleFilters = (): Promise<HttpCallReturn<GetPeopleFiltersReturn>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/filters`;

  return getApi<GetPeopleFiltersReturn>(URL);
};

interface UseGetPeopleFiltersReturn extends Omit<GetPeopleFiltersReturn, 'teams'> {
  peopleFilters?: GetPeopleFiltersReturn,
  isLoading: boolean,
  groups: UserGroupsFromAPI[],
  myTeams: UserGroupsFromAPI[],
}

export const useGetPeopleFilters = (): UseGetPeopleFiltersReturn => {
  const result = useQuery({
    queryKey: PEOPLE_FILTERS_QUERY_KEY,
    queryFn: getPeopleFilters,
  });

  const response = result.data?.response;

  const alphabeticallySortedAccountTypes = useMemo(() => {
    const temp = response?.accountTypes?.sort(
      (accountTypes1, accountTypes2) => accountTypes1.name.toLowerCase().localeCompare(accountTypes2.name.toLowerCase()),
    );
    const index = temp?.findIndex((object) => object.name === 'Limited Access');
    temp?.push(temp.splice(index || -1, 1)[0]);

    return temp;
  }, [response]);

  const alphabeticallySortedDepartments = useMemo(() => response?.departments?.sort(
    (department1, department2) => department1.value.toLowerCase().localeCompare(department2.value.toLowerCase()),
  ), [response]);
  const sortedDepartmentsMinusDeactivated = alphabeticallySortedDepartments?.filter((department) => department.total > 0
  || department.importedTotal > 0 || department.invitedTotal > 0);

  const alphabeticallySortedGroups = useMemo(() => response?.teams.map((group) => group).sort(
    (department1, department2) => (
      department1.name.toLowerCase().localeCompare(department2.name.toLowerCase())
    ),
  ), [response]);

  const alphabeticallySortedMyTeams = useMemo(() => response?.myTeams?.map((group) => group).sort(
    (department1, department2) => (
      department1.name.toLowerCase().localeCompare(department2.name.toLowerCase())
    ),
  ), [response]);

  // We're doing this because LC is returned as a count but we don't want to show it since we're hiding it
  const adjustedAccountTypes = useMemo(() => {
    const temp = alphabeticallySortedAccountTypes?.map((accountType) => {
      if (accountType.name === 'Admin') {
        return {
          ...accountType,
          total: accountType.total - 1,
        };
      }
      return accountType;
    });

    return temp;
  }, [alphabeticallySortedAccountTypes]);

  return {
    peopleFilters: response,
    accountTypes: adjustedAccountTypes ?? [],
    departments: sortedDepartmentsMinusDeactivated ?? [],
    groups: alphabeticallySortedGroups ?? [],
    myTeams: alphabeticallySortedMyTeams ?? [],
    workingGeniusSkills: response?.workingGeniusSkills ?? [],
    isLoading: result.isLoading,
  };
};
