import { css } from '@emotion/react';
import {
  faMinus, faFilter, faPlus, faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FILTER_GROUP_MENU_ITEMS_SHAPE } from '~Common/const/proptypes';

import { palette } from '~Common/styles/colors';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import FlatButton from '~Common/V3/components/FlatButton';
import { noop } from '~Deprecated/utils';
import { withTruncate } from '~Common/styles/mixins';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import GroupItem from './GroupItem';
import SectionCollapse from './SectionCollapse';

const styles = {
  filterButtonContainer: css`
    .MuiSelect-select.MuiSelect-select {
      min-width: 0;
      min-height: 0;
      padding: 0;
    }
    .MuiSelect-nativeInput {
      display: none;
    }
  `,
  filterButton: (hasFilters) => css`
    color: ${palette.neutrals.gray500};
    border: .0625rem solid ${palette.neutrals.gray300};
    width: 2.8125rem;
    height: 2.8125rem;
    border-radius: .3125rem;

    ${hasFilters && `
      border: .0625rem solid ${palette.brand.indigo};
      color: ${palette.brand.indigo};
    `}
  `,
  listItem: css`
    display: flex;
    max-width:25rem;
    justify-content: space-between;
    padding: 0;
  `,
  infoText: css`
    max-width: 25rem;
    ${withTruncate()};
  `,

  flatButton: (isSubItem) => css`
    width: 100%;
    display: flex;

    ${isSubItem && `
      padding-left: 2.25rem;
    `};
  `,
  checkbox: css`
    margin-right: .25rem;
  `,
  collapseButton: css`
    padding-right: .75rem;
    color: ${palette.brand.indigo};
  `,
  select: css`
    opacity: 0;
    width: 0;
  `,
  closeButtonStyles: css`
    color: ${palette.neutrals.gray400};
    align-self: flex-end;
    display:none;

    @media only screen and (max-width: 768px) {
      display:block;
    }
  `,
  wrapper: css`
    display:flex;
    flex-wrap:wrap;
    flex-direction: column;
    & li, & > div, & + button{
      width:100%;
    }
  `,
};

const selectedType = {
  PARTIALLY: 'PARTIALLY',
  SELECTED: 'SELECTED',
  NONE: 'NONE',
};

const View = ({
  open,
  isSelected,
  handleOpen,
  handleClose,
  handleChange,
  selectedFilters,
  menuItems,
  onTagClick,
  ...props
}) => (
  <div css={styles.filterButtonContainer} {...props}>
    <FlatButton
      css={styles.filterButton(selectedFilters?.length > 0)}
      onClick={handleOpen}
      renderButtonContents={() => (
        <FontAwesomeIcon icon={faFilter} size="lg" />
      )}
    />
    <Select
      labelId="demo-mutiple-checkbox-label"
      id="demo-mutiple-checkbox"
      open={open}
      multiple
      value={selectedFilters}
      onClose={handleClose}
      css={styles.select}
    >
      <div css={styles.wrapper}>
        <IconButton onClick={handleClose} tooltip="Back" type="button" icon={faTimes} css={styles.closeButtonStyles} />
        {menuItems.map((menuItem) => (
          <React.Fragment key={menuItem.text}>
            {menuItem.subList && (
              <SectionCollapse
                menuItem={menuItem}
                renderMenuItem={({
                  itemInfo, isSubItem, onToggleCollapse, showCollapse,
                }) => (
                  <GroupItem
                    css={styles.listItem}
                    renderContent={() => (
                      <>
                        <FlatButton
                          css={styles.flatButton(isSubItem)}
                          onClick={() => handleChange(itemInfo)}
                          renderButtonContents={() => (
                            <div css={styles.infoText}>
                              <CustomCheckbox
                                css={styles.checkbox}
                                checked={isSelected(itemInfo) === selectedType.SELECTED}
                                indeterminate={isSelected(itemInfo) === selectedType.PARTIALLY}
                              />
                              {itemInfo.text}
                            </div>
                          )}
                        />
                        {itemInfo.subList && (
                        <FlatButton
                          css={styles.collapseButton}
                          onClick={onToggleCollapse}
                          renderButtonContents={() => (
                            <FontAwesomeIcon icon={showCollapse ? faMinus : faPlus} />
                          )}
                        />
                        )}
                      </>
                    )}
                  />
                )}
              />
            )}
            {!menuItem.subList && (
            <GroupItem
              css={styles.listItem}
              renderContent={() => (
                <FlatButton
                  css={styles.flatButton(false)}
                  onClick={() => handleChange(menuItem)}
                  renderButtonContents={() => (
                    <div>
                      <CustomCheckbox
                        css={styles.checkbox}
                        checked={isSelected(menuItem) === selectedType.SELECTED}
                      />
                      {menuItem.text}
                    </div>
                  )}
                />
              )}
            />
            )}
          </React.Fragment>
        ))}
      </div>
    </Select>
  </div>
);

View.propTypes = {
  open: PropTypes.bool.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  menuItems: FILTER_GROUP_MENU_ITEMS_SHAPE.isRequired,
  onTagClick: PropTypes.func.isRequired,
};

View.defaultProps = {};

const FilterGroupsButton = ({
  selectedFilters, setSelectedFilters, menuItems, onChangeCallback, ...props
}) => {
  const [open, setOpen] = useState(false);

  const [idOnlyFilters, setIdOnlyFilters] = useState();

  useEffect(() => {
    const textFilters = selectedFilters.map((filter) => filter.id);
    setIdOnlyFilters(textFilters);
  }, [selectedFilters]);

  const isSelected = (menuItem) => {
    if (menuItem.subList) {
      const numberOfSelectedFilters = menuItem.subList.filter((subListItem) => idOnlyFilters.includes(subListItem.id)).length;

      if (numberOfSelectedFilters === menuItem.subList.length) {
        return selectedType.SELECTED;
      } if (numberOfSelectedFilters > 0) {
        return selectedType.PARTIALLY;
      }
    }

    return idOnlyFilters.includes(menuItem.id) ? selectedType.SELECTED : selectedType.NONE;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cascadeSelections = (tempArray, arrayToCascade) => {
    arrayToCascade.forEach((filter) => {
      const index = idOnlyFilters.findIndex((selectedFilter) => selectedFilter === filter.id);
      if (index === -1) {
        tempArray.push(filter);
      }
    });
  };

  const cascadeDeselections = (tempArray, arrayToCascade) => {
    arrayToCascade.forEach((filter) => {
      const index = idOnlyFilters.findIndex((selectedFilter) => selectedFilter === filter.id);
      if (index !== -1) {
        tempArray.splice(index, 1);
        setIdOnlyFilters(idOnlyFilters.splice(index, 1));
      }
    });
  };

  const handleChange = (menuItem) => {
    const tempArray = [...selectedFilters];
    if (menuItem.subList) {
      if (isSelected(menuItem) === selectedType.SELECTED) {
        cascadeDeselections(tempArray, menuItem.subList);
      } else {
        cascadeSelections(tempArray, menuItem.subList);
      }
    } else {
      const index = idOnlyFilters.findIndex((filter) => filter === menuItem.id);

      if (index === -1) {
        tempArray.push(menuItem);
      } else {
        tempArray.splice(index, 1);
      }
    }

    onChangeCallback(tempArray);

    setSelectedFilters(tempArray);
  };

  const onTagClick = (text) => {
    const tempArray = [...selectedFilters];
    const index = tempArray.findIndex((filter) => filter === text);

    tempArray.splice(index, 1);
    setSelectedFilters(tempArray);
  };

  const hookProps = {
    open,
    menuItems,
    handleOpen,
    handleClose,
    handleChange,
    selectedFilters,
    isSelected,
    onTagClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

FilterGroupsButton.propTypes = {
  menuItems: FILTER_GROUP_MENU_ITEMS_SHAPE,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  onChangeCallback: PropTypes.func,
  setSelectedFilters: PropTypes.func,
};

FilterGroupsButton.defaultProps = {
  menuItems: [],
  selectedFilters: [],
  onChangeCallback: noop,
  setSelectedFilters: noop,
};

export { View };
export default FilterGroupsButton;
