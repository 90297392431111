import React from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import TextField from '~Common/components/Fields/TextField';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import { palette } from '~Common/styles/colors';
import { useGetFormattedCustomFavorites } from '~Admin/hooks/settings/useGetFormattedCustomFavorites';

const styles = {
  container: css`
    margin-bottom: 25px;
  `,
  field: css({
    marginBottom: '10px',
    background: palette.neutrals.gray100,
    color: palette.neutrals.gray700,
    border: 'none',

    '& label': {
      color: palette.neutrals.gray700,
      fontWeight: '400',
      textTransform: 'none',
    },
  }),
  customFavorites: {
    container: css`
      h4 {
        margin-bottom: 8px;
      }
    `,
  },
};

const View = ({ user, customFavorites }) => (
  <div css={styles.container}>
    <SectionHeader text="Favorites" />
    <TextField css={styles.field} label="Favorite Food" initialValue={user.food} name="food" />
    <TextField css={styles.field} label="Favorite Drink" initialValue={user.drink} name="drink" />
    <TextField css={styles.field} label="Favorite Snack" initialValue={user.snacks} name="snacks" />
    <div>
      {customFavorites.map((favorite) => (
        <React.Fragment key={`fav-${favorite.title}`}>
          <div css={styles.customFavorites.container}>
            <TextField
              label={favorite.title}
              css={styles.field}
              initialValue={favorite.value}
              name={favorite.valueName}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  </div>
);

View.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  customFavorites: PropTypes.arrayOf(PropTypes.string),
};

View.defaultProps = {
  customFavorites: [],
};

const Favorites = ({
  user, ...props
}) => {
  const { formattedCustomFavorites } = useGetFormattedCustomFavorites(user.userId);

  const hookProps = {
    customFavorites: formattedCustomFavorites,
    user,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

Favorites.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
};

Favorites.defaultProps = {};

export { View };
export default Favorites;
