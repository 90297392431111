import { css } from '@emotion/react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'react';

import { palette } from '~Common/styles/colors';

const styles = {
  checkedIconContainer: (disabled: boolean) => css({
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '.5rem',
    backgroundColor: disabled ? palette.neutrals.gray400 : palette.brand.indigo,
    color: palette.neutrals.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  checkedIcon: css({
    width: '60%',
  }),
};

interface CheckedIconProps extends ComponentProps<'div'> {
  disabled?: boolean,
}

const CheckedIcon = ({ disabled = false, ...props }: CheckedIconProps): JSX.Element => (
  <div css={styles.checkedIconContainer(disabled)} {...props}>
    <FontAwesomeIcon icon={faCheck} css={styles.checkedIcon} />
  </div>
);

export default CheckedIcon;
