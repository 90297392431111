import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import InfiniteListView from '~Common/V3/components/InfiniteList';
import { forMobile } from '~Common/styles/mixins';
import { REQUEST_TYPE } from '~Deprecated/common/const/request-type';
import { slideFromBottom } from '~Deprecated/ui/styles/animations';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import emptySurveys from '~Surveys/assets/images/emptySurveys.png';
import { SURVEY_FILTER_NAMES } from '~Common/const/surveys';
import { GroupedListViewSkeleton } from '~Common/V3/components/InfiniteList/GroupedListView';
import PulseSurveyCard from '~Insights/components/cards/PulseSurveyCard';
import { ViewType } from '~Common/const/interfaces';
import { useSurveyViewTyperFilter } from './Hooks/useSurveyViewTypeFilter';
import { useGetSurveys } from './Hooks/useSurveysList';
import { useSurveyRequestTypeFilter } from './Hooks/useSurveyRequestType';
import SurveyCard, { SurveyCardSkeleton } from './Components/Cards/SurveyCard';

const styles = {
  pageStyle: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  listWrapper: {
    [ViewType.LIST]: css`
      width: 100%;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 0.5rem;
    `,
    [ViewType.GRID]: css`
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20.9375rem, 1fr));
      width: 100%;
      grid-gap: 1rem;
      place-items: center;
      grid-auto-rows: 1fr;

      ${forMobile(`
        grid-gap: 0.5rem;
        grid-template-columns: 1fr;
      `)}
    `,
  },
  itemWrapper: {
    common: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    [ViewType.LIST]: css`
      height: auto;
      margin: -1rem;
      padding: 1rem;
      width: calc(100% + 2rem);
      overflow: hidden;
    `,
    [ViewType.GRID]: css`
      width: 100%;
      min-height: 145px;
    `,
  },
  emptyState: css`
    margin-top: 50px;

    & h6 {
      width: 80%;
    }
  `,
  emptyStateImage: css`
    height: 183px;
  `,
  wrapper: css({
    maxWidth: '50.35rem',
    marginBottom: '1.5rem',
  }),
};

const View = ({
  surveyViewTypeFilter, surveyRequestTypeFilter, renderImage, emptyStateText, ...props
}) => (
  <div css={styles.pageStyle}>
    <div css={styles.wrapper}>
      <PulseSurveyCard />
    </div>
    <InfiniteListView
      listWrapperStyle={styles.listWrapper[surveyViewTypeFilter]}
      listQuery={useGetSurveys}
      requestType={surveyRequestTypeFilter}
      renderItem={(id, index) => (
        <SurveyCard
          id={id}
          surveyViewTypeFilter={surveyViewTypeFilter}
          surveyRequestTypeFilter={surveyRequestTypeFilter}
          key={`survey-item-${index}`}
          css={[styles.itemWrapper.common, styles.itemWrapper[surveyViewTypeFilter], slideFromBottom(index)]}
          {...props}
        />
      )}
      itemKeyName="id"
      renderEmptyState={() => (
        <EmptyStateWithImage css={styles.emptyState} renderImage={renderImage} text={emptyStateText} />
      )}
      renderLoadingState={() => (
        <SkeletonView surveyRequestTypeFilter={surveyRequestTypeFilter} surveyViewTypeFilter={surveyViewTypeFilter} />
      )}
    />
  </div>
);

View.propTypes = {
  surveyViewTypeFilter: PropTypes.oneOf(Object.values(ViewType)).isRequired,
  surveyRequestTypeFilter: PropTypes.oneOf(Object.values(REQUEST_TYPE)).isRequired,
  renderImage: PropTypes.func.isRequired,
  emptyStateText: PropTypes.string.isRequired,
};

View.defaultProps = {};

const SkeletonView = ({ surveyViewTypeFilter, surveyRequestTypeFilter }) => (
  <GroupedListViewSkeleton
    listWrapperStyle={styles.listWrapper[surveyViewTypeFilter]}
    numberOfSkeletonCards={6}
    numberOfGroups={2}
    renderSkeletonItem={() => (
      <SurveyCardSkeleton
        surveyViewTypeFilter={surveyViewTypeFilter}
        surveyRequestTypeFilter={surveyRequestTypeFilter}
      />
    )}
  />
);

SkeletonView.propTypes = {
  surveyViewTypeFilter: PropTypes.oneOf(Object.values(ViewType)).isRequired,
  surveyRequestTypeFilter: PropTypes.oneOf(Object.values(REQUEST_TYPE)).isRequired,
};

SkeletonView.defaultProps = {};

const Surveys = ({ isLoading, ...props }) => {
  const [surveyViewTypeFilter] = useSurveyViewTyperFilter();
  const [surveyRequestTypeFilter] = useSurveyRequestTypeFilter();

  const isReceivedSurvey = surveyRequestTypeFilter === REQUEST_TYPE.RECEIVED;

  let emptyStateText;
  if (isReceivedSurvey) {
    emptyStateText = `There are no surveys sent to you. 
    Select the ${SURVEY_FILTER_NAMES.CREATED_BY_YOU} filter to view surveys you sent to others.`;
  } else {
    emptyStateText = `There are no surveys sent by you.
    Select the ${SURVEY_FILTER_NAMES.SENT_TO_YOU} filter to view surveys others sent to you.`;
  }
  emptyStateText = `${emptyStateText} To Request a survey click 'Create Survey'.`;

  const hookProps = {
    renderImage: () => (
      <img css={styles.emptyStateImage} src={emptySurveys} alt="Empty Surveys" />
    ),
    emptyStateText,
    surveyViewTypeFilter,
    surveyRequestTypeFilter,
  };

  return (
    <>
      {isLoading && (
        <SkeletonView surveyRequestTypeFilter={surveyRequestTypeFilter} surveyViewTypeFilter={surveyViewTypeFilter} />
      )}
      {!isLoading && (
        <View
          {...hookProps}
          {...props}
        />
      )}
    </>
  );
};
Surveys.propTypes = {
  isLoading: PropTypes.bool,
};

Surveys.defaultProps = {
  isLoading: false,
};

export default Surveys;
