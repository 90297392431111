import { ComponentProps } from 'react';
import { css } from '@emotion/react';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import { palette } from '~Common/styles/colors';
import { WhatsDueFilterOptions } from '~Home/const';
import { actionItemsEmptyStateRows } from '~Home/const/HomeEmptyState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import LinkButton from '~Common/V3/components/LinkButton';
import { EmptyStateActionProps, useEmptyStateContent } from '~Home/hooks/useEmptyStateContent';

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  topSection: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0.5rem 0',
  }),
  emptyStateImage: css({
    width: '100%',
  }),
  icon: css({
    color: palette.brand.blue,
  }),
  textSection: css({
    margin: '0.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  action: css({
    color: palette.brand.indigo,
    textDecoration: 'underline',
    fontSize: '0.75rem',
    fontWeight: 700,
    textUnderlineOffset: '0.1875rem',
  }),
  text: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
  }),
};
interface EmptyStateProps extends ComponentProps<'div'> {
  iconToRender: IconProp,
  text: string,
  altText: string,
  action: EmptyStateActionProps,
}

const View = ({
  iconToRender,
  action,
  text,
  altText,
}: EmptyStateProps): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.topSection}>
      <div>
        <FontAwesomeIcon
          css={styles.icon}
          icon={iconToRender}
          size="5x"
        />
      </div>
      <div css={styles.textSection}>
        {action && (
          <LinkButton
            css={styles.action}
            to={action.route || ''}
            variant="text"
            renderContents={() => (
              <>{action.content}</>
            )}
          />
        )}
        <div css={styles.text}>
          {text}
        </div>
      </div>
    </div>
    <EmptyStateWithImage
      renderImage={() => (
        <img
          css={styles.emptyStateImage}
          src={actionItemsEmptyStateRows}
          alt={altText}
        />
      )}
    />
  </div>
);

interface WhatsDueEmptyStateProps {
  type: WhatsDueFilterOptions,
}

const WhatsDueEmptyState = ({ type }: WhatsDueEmptyStateProps):JSX.Element => {
  const {
    iconToRender, action, text, altText,
  } = useEmptyStateContent({ type });
  const hookProps = {
    iconToRender,
    action,
    text,
    altText,
  };

  return (
    <View
      {...hookProps}

    />
  );
};
export default WhatsDueEmptyState;
