import { Fragment } from 'react';

export interface MultipleSkeletonLoadersProps {
  numberOfSkeletons: number,
  renderSkeletonItem: () => JSX.Element,
}

const MultipleSkeletonLoaders = ({ numberOfSkeletons, renderSkeletonItem, ...props }: MultipleSkeletonLoadersProps): JSX.Element => (
  <div {...props}>
    {[...Array<number>(numberOfSkeletons)].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={index}>
        { renderSkeletonItem() }
      </Fragment>
    ))}
  </div>
);

export default MultipleSkeletonLoaders;
