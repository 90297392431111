import { fork, all } from 'redux-saga/effects';
import {
  watchUserAuthentication,
  watchMeeting,
  watchFeedback,
  watchContent,
  watchParallelPollingTasks,
} from './watchers';

export default function* startForman() {
  yield fork(watchUserAuthentication);
  yield fork(watchMeeting);
  yield fork(watchFeedback);
  yield fork(watchContent);
  yield all([watchParallelPollingTasks()]);
}
