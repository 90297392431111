import { css } from '@emotion/react';
import { ReactNode } from 'react';
import DrawerInput, { DrawerInputProps } from '~Common/V3/components/DrawerInput';
import Froala, { FroalaProps } from '~Common/V3/components/Froala';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  inputWithConfirmButtons: css({
    width: '100%',
  }),
  buttonsContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginTop: '.375rem',
  }),
};

interface InputWithConfirmButtonsProps {
  children: ReactNode,
}

const InputWithConfirmButtons = ({
  children,
  ...props
}: InputWithConfirmButtonsProps): JSX.Element => (
  <div css={styles.inputWithConfirmButtons} {...props}>
    {children}
  </div>
);

const NormalInput = ({ ...props }: DrawerInputProps): JSX.Element => (
  <DrawerInput
    {...props}
  />
);

const RTEInput = ({ ...props }: FroalaProps): JSX.Element => (
  <Froala
    {...props}
  />
);

interface ConfirmButtonsProps {
  handleSave: () => void,
  handleCancel: () => void,
  disableSaveButton?: boolean,
  dataTestIdPrefix: string,
}

const SaveConfirmButtons = ({
  handleSave,
  handleCancel,
  disableSaveButton = false,
  dataTestIdPrefix,
  ...props
}: ConfirmButtonsProps): JSX.Element => (
  <div css={styles.buttonsContainer} {...props}>
    <LeadrButton
      size="small"
      onClick={handleSave}
      data-test-id={`${dataTestIdPrefix}Save`}
      disabled={disableSaveButton}
    >
      Save
    </LeadrButton>
    <LeadrButton
      size="small"
      variant="ghost"
      onClick={handleCancel}
      data-test-id={`${dataTestIdPrefix}Cancel`}
    >
      Cancel
    </LeadrButton>
  </div>
);

InputWithConfirmButtons.NormalInput = NormalInput;
InputWithConfirmButtons.RTEInput = RTEInput;
InputWithConfirmButtons.SaveConfirmButtons = SaveConfirmButtons;

export default InputWithConfirmButtons;
