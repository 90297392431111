import moment from 'moment-timezone';
import { Goals } from '@leadr-hr/types';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { GoalTypeOption } from './types';
import { GoalsRoutes } from '../routes/GoalsRouter';

export const GOAL_OWNER_LIMIT = 1;
export const GOALS_PAGE_SIZE = 25;
export const defaultDate = moment().valueOf();
export const DEFAULT_TEAM_ID = 'noTeamSelected';

export const DEFAULT_GOAL = {
  title: '',
  description: '',
  participants: [],
  priority: Goals.GoalPriority.Medium,
  context: {
    contextId: '',
    contextType: Goals.GoalContextType.Personal,
  },
  category: Goals.GoalCategory.DefiningObjectives,
  externalLink: '',
  isPrivate: false,
  startTimeInMillis: defaultDate,
  endTimeInMillis: moment(defaultDate).add(90, 'day').valueOf(),
  owner: [getOrganizationUserId() ?? ''],
};

export const GoalOptionTypes: GoalTypeOption<Goals.GoalContextType>[] = [
  {
    value: Goals.GoalContextType.Personal,
    text: 'Personal Goal',
  },
  {
    value: Goals.GoalContextType.Team,
    text: 'Team Goal',
  },
  {
    value: Goals.GoalContextType.Organization,
    text: 'Organization Goal',
  },
];
export const GoalCategoryTypes: GoalTypeOption<string>[] = [
  {
    value: Goals.GoalCategory.DefiningObjectives,
    text: Goals.GoalCategory.DefiningObjectives,
  },
  {
    value: Goals.GoalCategory.StandardOperatingObjectives,
    text: Goals.GoalCategory.StandardOperatingObjectives,
  },
];

export const GoalPriorityLabels = {
  [Goals.GoalPriority.Lowest]: 'Lowest',
  [Goals.GoalPriority.Low]: 'Low',
  [Goals.GoalPriority.Medium]: 'Medium',
  [Goals.GoalPriority.High]: 'High',
  [Goals.GoalPriority.Highest]: 'Highest',
};

export const GoalPriorities: GoalTypeOption<number>[] = [
  {
    value: Goals.GoalPriority.Highest,
    text: GoalPriorityLabels[Goals.GoalPriority.Highest],
  },
  {
    value: Goals.GoalPriority.High,
    text: GoalPriorityLabels[Goals.GoalPriority.High],
  },
  {
    value: Goals.GoalPriority.Medium,
    text: GoalPriorityLabels[Goals.GoalPriority.Medium],
  },
  {
    value: Goals.GoalPriority.Low,
    text: GoalPriorityLabels[Goals.GoalPriority.Low],
  },
  {
    value: Goals.GoalPriority.Lowest,
    text: GoalPriorityLabels[Goals.GoalPriority.Lowest],
  },
];

export const GOAL_TABS_LABELS = {
  Personal: {
    Label: 'Personal Goals',
    Value: Goals.GoalContextType.Personal,
  },
  Team: {
    Label: 'Team Goals',
    Value: Goals.GoalContextType.Team,
  },
  Organization: {
    Label: 'Organization Goals',
    Value: Goals.GoalContextType.Organization,
  },
};

export const OPEN_GOAL_TABS = [
  {
    label: GOAL_TABS_LABELS.Personal.Label,
    value: `${GOAL_TABS_LABELS.Personal.Value}`,
    toObject: {
      pathname: GoalsRoutes?.ListOpen,
      search: `?tab=${GOAL_TABS_LABELS.Personal.Value}`,
    },
    'data-test-id': 'personalGoalsTab',
  },
  {
    label: GOAL_TABS_LABELS.Team.Label,
    value: `${GOAL_TABS_LABELS.Team.Value}`,
    toObject: {
      pathname: GoalsRoutes?.ListOpen,
      search: `?tab=${GOAL_TABS_LABELS.Team.Value}`,
    },
    'data-test-id': 'teamGoalsTab',
  },
  {
    label: GOAL_TABS_LABELS.Organization.Label,
    value: `${GOAL_TABS_LABELS.Organization.Value}`,
    toObject: {
      pathname: GoalsRoutes?.ListOpen,
      search: `?tab=${GOAL_TABS_LABELS.Organization.Value}`,
    },
    'data-test-id': 'organizationGoalsTab',
  },
];
export const COMPLETED_GOAL_TABS = [
  {
    label: GOAL_TABS_LABELS.Personal.Label,
    value: `${GOAL_TABS_LABELS.Personal.Value}`,
    toObject: {
      pathname: GoalsRoutes?.ListComplete,
      search: `?tab=${GOAL_TABS_LABELS.Personal.Value}`,
    },
    'data-test-id': 'personalGoalsTab',
  },
  {
    label: GOAL_TABS_LABELS.Team.Label,
    value: `${GOAL_TABS_LABELS.Team.Value}`,
    toObject: {
      pathname: GoalsRoutes?.ListComplete,
      search: `?tab=${GOAL_TABS_LABELS.Team.Value}`,
    },
    'data-test-id': 'teamGoalsTab',
  },
  {
    label: GOAL_TABS_LABELS.Organization.Label,
    value: `${GOAL_TABS_LABELS.Organization.Value}`,
    toObject: {
      pathname: GoalsRoutes?.ListComplete,
      search: `?tab=${GOAL_TABS_LABELS.Organization.Value}`,
    },
    'data-test-id': 'organizationGoalsTab',
  },
];
export const PARTICIPANT_TABS = [
  {
    label: Goals.GoalParticipantRole.Collaborator,
    value: Goals.GoalParticipantRole.Collaborator,
    'data-test-id': 'collaboratorTab',
  },
  {
    label: Goals.GoalParticipantRole.Viewer,
    value: Goals.GoalParticipantRole.Viewer,
    'data-test-id': 'ViewerTab',
  },
];

export const DEFAULT_OWNER = {
  orgUserId: '',
  jobTitle: '',
  profileImageUrl: '',
  firstName: 'Deactivated',
  lastName: 'User',
  role: Goals.GoalParticipantRole.Owner,
};

export const DEFAULT_STARTDATE_ENDDATE_SPREAD = 90;
