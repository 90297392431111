import * as types from './index';

export const forgotPasswordAction = (user) => ({
  type: types.FORGOT_PASSWORD,
  user,
});

export const loginUserSuccessAction = (response) => ({
  type: types.LOGIN_USER_SUCCESS,
  response,
});
