import {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { withH4Font } from '~Common/styles/typography';
import Tooltip from '~Common/components/Tooltip';
import { RADIO_OPTIONS_SHAPE } from '~Common/const/proptypes';
import {
  palette, radioColor,
} from '~Common/styles/colors';

const styles = {
  conatiner: css`
    display: flex;
  `,

  wrapper: (width) => css`
    width: ${width};
    display: inline-flex;
    align-items: center;

    & > h4, & .MuiFormControlLabel-label {
      ${withH4Font()};
      color: ${palette.neutrals.gray700};
      min-width: 250px;
    }
  `,
  radioWrapper: (isSelected) => isSelected && (css`background: ${palette.neutrals.white}!important;`),
  radioContainer: css`
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: .5rem;

    & > label {
      ${withH4Font()};
      color: ${palette.neutrals.gray700};
      margin-bottom: 0;
      font-size: 14px !important;
    }
    & > div > span{
      padding: 0 .5rem 0 1rem;
    }
  `,
};

const useRadioButtonField = ({
  initialValue,
  options,
  onChange,
  name,
  isRequired,
  radioButtonStyle,
  disabled,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleChange = useCallback((event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  }, [onChange]);

  const Component = (
    <RadioGroup name={name} value={selectedValue} onChange={handleChange} {...props}>
      { options.map((option) => {
        const WrapperComponent = (option.tooltip?.length > 0) ? Tooltip : Fragment;
        const radioLabel = option.label.replace(' ', '');
        const wrapperProps = {};

        if (option.tooltip?.length > 0) {
          wrapperProps.content = option.tooltip;
          wrapperProps.placement = 'top-start';
        }
        return (
          <WrapperComponent {...wrapperProps} key={`radio-option-${option.value}-${radioLabel}`}>
            <div css={[radioButtonStyle, styles.radioWrapper((option.value === selectedValue))]}>
              <div css={styles.radioContainer}>
                <div>
                  <Radio
                    sx={{
                      color: palette.neutrals.gray400,
                      '&.Mui-checked': {
                        color: radioColor,
                      },
                    }}
                    id={`radio-value-${option.value}-${radioLabel}`}
                    value={option.value}
                    disabled={(option?.disabled || disabled) ?? false}
                    required={isRequired}
                  />
                </div>
                <label htmlFor={`radio-value-${option.value}-${radioLabel}`}>
                  {option.label}
                </label>
              </div>
              {option?.descriptiveText && (
                <div>
                  {option.descriptiveText}
                </div>
              )}
            </div>
          </WrapperComponent>
        );
      }) }
    </RadioGroup>
  );

  return {
    Component,
    selectedValue,
  };
};

/**
 * @deprecated Old RadioButton UI, use packages/leadr-frontend/src/common/V3/components/Form/CustomRadioButton.tsx instead
 */

const RadioButtons = ({
  initialValue, options, name, onChange, isRequired, width, disabled, ...props
}) => {
  const {
    Component: RadioButtonsComponent,
  } = useRadioButtonField({
    initialValue,
    options,
    onChange,
    name,
    isRequired,
    disabled,
    ...props,
  });

  return (
    <div css={styles.conatiner}>
      <div css={styles.wrapper(width)}>
        { RadioButtonsComponent }
      </div>
    </div>
  );
};

RadioButtons.propTypes = {
  initialValue: PropTypes.string,
  options: RADIO_OPTIONS_SHAPE,
  onChange: PropTypes.func,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioButtons.defaultProps = {
  initialValue: '',
  options: [],
  onChange: null,
  name: null,
  isRequired: false,
  width: '75%',
  disabled: false,
};

export default RadioButtons;
