import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import {
  GetCuratedMeetingTemplatesQueryParameters,
  GetCuratedMeetingTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { meetingTopicTemplatesQueryKeys } from './queryKeys';

interface GetCuratedMeetingTemplatesResponse {
  meetingTemplates: GetCuratedMeetingTemplateDetails[],
}

const getCuratedMeetingTemplates = ({
  meetingTemplateType,
  curatedSource,
}: GetCuratedMeetingTemplatesQueryParameters): Promise<HttpCallReturn<GetCuratedMeetingTemplatesResponse>> => {
  const searchParams = new URLSearchParams({
    meetingTemplateType,
    curatedSource,
  });

  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates/curated?${searchParams.toString()}`;

  return getApi<GetCuratedMeetingTemplatesResponse>(URL);
};

type UseGetCuratedMeetingTemplatesParams = GetCuratedMeetingTemplatesQueryParameters;

const useGetCuratedMeetingTemplates = ({
  meetingTemplateType,
  curatedSource,
}: UseGetCuratedMeetingTemplatesParams): UseQueryResult<HttpCallReturn<GetCuratedMeetingTemplatesResponse>> => {
  const orgId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: meetingTopicTemplatesQueryKeys.curatedTemplatesList({
      orgId,
      meetingTemplateType,
      curatedSource,
    }),
    queryFn: () => getCuratedMeetingTemplates({
      meetingTemplateType,
      curatedSource,
    }),
  });
};

export default useGetCuratedMeetingTemplates;
