import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import BaseAvatar from './BaseAvatar';

const style = (margin) => css`
  border-radius: 5px;
  margin-right: ${margin}px;
`;

const SquareAvatar = ({
  height, width, margin = 0, imgUrl, ...props
}) => (
  <BaseAvatar
    height={height}
    width={width}
    margin={margin}
    css={style(margin)}
    imgUrl={imgUrl}
    {...props}
  />
);

SquareAvatar.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  imgUrl: PropTypes.string,
  margin: PropTypes.number,
};

SquareAvatar.defaultProps = {
  height: 40,
  width: 40,
  imgUrl: '',
  margin: 0,
};

export default SquareAvatar;
