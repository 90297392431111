import { useState } from 'react';

import { useNewPeople, filterOptions } from '~Deprecated/hooks/peoplePicker/useNewPeople';

const usePeoplePicker = ({ useOrgIds, allowSelf, customFilter }) => {
  const [keyword, setKeyword] = useState('');
  const [searchFilter, setSearchFilter] = useState(filterOptions[0]);

  const {
    status,
    data,
    allData,
  } = useNewPeople({
    search: keyword,
    useOrgIds,
    allowSelf,
    filter: searchFilter,
    customFilter,
  });

  return {
    onSearch: setKeyword,
    isLoading: status === 'loading',
    attendees: data,
    allAttendees: allData,
    setSearchFilter,
    searchFilter,
  };
};

export default usePeoplePicker;
