import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

import {
  AssignedPlaylistDetails, AssignedPlaylistLearning,
} from '~Learning/const/interfaces';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import LearningsAccordion from '~Learning/components/Shared/LearningsAccordion';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import emptyLearningTemplate from '~Learning/assets/images/emptyLearningTemplate.png';
import { playlistParticipantsDrawerTemplate } from '../../PlaylistParticipantsDrawer';
import ContentDetailsCard from '../Cards/ContentDetailsCard';

const styles = {
  contentContainer: css({
    position: 'sticky',
    top: '0',
    backgroundColor: palette.neutrals.white,
    marginTop: '1rem',
    marginBottom: '1rem',
  }),
  responsesContainer: css({
    marginTop: '3.125rem',
    display: 'flex',
    flexDirection: 'column',
  }),
  responsesHeader: css({
    textTransform: 'uppercase',
    fontSize: '.6875rem',
    color: palette.neutrals.gray600,
    marginBottom: '2rem',
    letterSpacing: '0.2rem',
    fontWeight: '600',
  }),
  dropdown: css({
    marginBottom: '2rem',
  }),
  urlContent: css({}),
  completedLearningsText: css({
    color: palette.neutrals.gray500,
  }),
  buttonsContainer: css({
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
  }),
  emptyState: css({
    paddingBottom: '1.5rem',
    img: {
      width: '100%',
    },
  }),
  emptyStateText: css({
    fontWeight: '400',
    color: palette.brand.indigo,
    textAlign: 'center',
    fontSize: '.875rem',
    marginTop: '1.125rem',
  }),
};

interface ViewProps {
  learningPlaylistDropdownItems: DropdownItem[],
  selectedLearningId: string,
  setSelectedLearningId: (selectedLearningId: string) => void,
  selectedLearning: AssignedPlaylistLearning,
  onViewParticipantsClick: () => void,
  showViewParticipantsButton: boolean,
  onCloseParticipantsClick: () => void,
  checkForResponse: boolean,
}

const View = ({
  learningPlaylistDropdownItems,
  selectedLearningId,
  setSelectedLearningId,
  selectedLearning,
  onViewParticipantsClick,
  showViewParticipantsButton,
  onCloseParticipantsClick,
  checkForResponse,
}: ViewProps): JSX.Element => (
  <>
    <Dropdown
      items={learningPlaylistDropdownItems}
      onChange={(event: SelectChangeEvent) => setSelectedLearningId(event.target.value)}
      value={selectedLearningId}
      css={styles.dropdown}
      renderLabel={() => (
        <InputLabel>
          Playlist Content
        </InputLabel>
      )}
    />
    <>
      <ContentDetailsCard
        contentUrl={selectedLearning.contentUrl}
        title={selectedLearning.title}
        introductionHTML={selectedLearning.introduction}
        dueDate={selectedLearning.dueDate}
        status={selectedLearning.status}
      />
      <div css={styles.responsesContainer}>
        <span css={styles.responsesHeader}>Participant Responses</span>
        {checkForResponse && (
          <LearningsAccordion
            questions={selectedLearning.questions}
          />
        )}

        {!checkForResponse && (
          <div css={styles.emptyState}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <img src={emptyLearningTemplate} alt="No Template" />
            <div css={styles.emptyStateText}>You have not received any responses yet. Please check back later</div>
          </div>
        )}
      </div>

    </>
    <div css={styles.buttonsContainer}>
      {showViewParticipantsButton && (
        <LeadrButton
          onClick={onViewParticipantsClick}
          data-test-id="learningPlaylistViewParticipants"
        >
          Open Participants
        </LeadrButton>
      )}
      {!showViewParticipantsButton && (
        <LeadrButton
          onClick={onCloseParticipantsClick}
          data-test-id="learningPlaylistCloseParticipants"
        >
          Close Participants
        </LeadrButton>
      )}
    </div>
  </>
);

interface QuestionsTabProps {
  learningPlaylist: AssignedPlaylistDetails,
  learningPlaylistDropdownItems: DropdownItem[],
  showViewParticipantsButton: boolean,
}

const QuestionsTab = ({
  learningPlaylist,
  learningPlaylistDropdownItems,
  showViewParticipantsButton,
  ...props
}: QuestionsTabProps): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedLearningId, setSelectedLearningId] = useState(learningPlaylistDropdownItems[0].value ?? '');

  const selectedLearning = useMemo(
    () => learningPlaylist.learnings.find((learning) => learning.id === selectedLearningId) ?? learningPlaylist.learnings[0],
    [selectedLearningId, learningPlaylist.learnings],
  );

  const checkForResponse = useMemo(
    () => selectedLearning.questions.every((question) => question.responses.length > 0),
    [selectedLearning.questions],
  );

  const hookProps = {
    checkForResponse,
    selectedLearning,
    learningPlaylist,
    learningPlaylistDropdownItems,
    selectedLearningId,
    setSelectedLearningId,
    showViewParticipantsButton,
    onViewParticipantsClick: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...playlistParticipantsDrawerTemplate,
          args: {
            assignees: learningPlaylist.assignedUsersInfo,
            playlistId: learningPlaylist.id,
          },
        },
      }));
    },
    onCloseParticipantsClick: () => {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ drawerName: playlistParticipantsDrawerTemplate.name }));
    },
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default QuestionsTab;
