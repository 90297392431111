import { css } from '@emotion/react';
import {
  Checkbox,
  CheckboxProps,
} from '@mui/material';

import CheckedIcon from './Icons/CheckedIcon';
import IndeterminateIcon from './Icons/IndeterminateIcon';
import UncheckedIcon from './Icons/UncheckedIcon';

const styles = {
  checkbox: css({
    padding: '.5rem .75rem',
  }),
};

const CustomCheckbox = ({ disabled, ...props }: CheckboxProps): JSX.Element => (
  <Checkbox
    css={styles.checkbox}
    icon={(
      <UncheckedIcon
        disabled={disabled}
      />
    )}
    disabled={disabled}
    checkedIcon={(
      <CheckedIcon
        disabled={disabled}
      />
    )}
    indeterminateIcon={(
      <IndeterminateIcon />
    )}
    {...props}
  />
);

export default CustomCheckbox;
