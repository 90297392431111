import { ComponentProps } from 'react';
import { ReviewCycleDetail, modalParamsProps } from '../Const/types';
import DisplayCompleteModal from './DisplayCompleteModal';
import DisplayDeleteModal from './DisplayDeleteModal';
import DisplayCycleModals from './DisplayCycleModals';
import DisplayReopenModal from './DisplayReopenModal';
import DisplayReopenModalConfirm from './DisplayReopenModalConfirm';

interface ViewProps extends ComponentProps<'div'> {
  openDeleteModal: boolean,
  openDeleteCycleModal: boolean,
  openFinishModal: boolean,
  bulkActionModalParams: string[],
  removeMultipleReviews: () => void,
  handleModalDisplay: (value: boolean, params?: modalParamsProps) => void,
  setBulkActionModalParams:(value: string[]) => void,
  modalParams?: modalParamsProps,
  removeSingleReview: () => void,
  completeCycle: (value: boolean) => void,
  setOpenCompleteModal: (value: boolean) => void,
  nudgeMultipleParticipants: (isFinishing: boolean) => void,
  handleModalDisplayDeleteCycle: (value: boolean) => void,
  deleteCycle: () => void,
  title: string,
  openReopenModal: boolean,
  filterParticipantsOnCycle: ReviewCycleDetail,
  setopenReopenModal: (value: boolean) => void,
  openReopenModalConfirm: boolean,
  setOpenReopenModalConfirm: (value: boolean) => void,
  reOpenReviews: () => void,
  reOpenTitle?: string,
}

const View = ({
  openDeleteModal,
  openDeleteCycleModal,
  openFinishModal,
  bulkActionModalParams,
  removeMultipleReviews,
  handleModalDisplay,
  setBulkActionModalParams,
  modalParams,
  removeSingleReview,
  completeCycle,
  setOpenCompleteModal,
  nudgeMultipleParticipants,
  handleModalDisplayDeleteCycle,
  deleteCycle,
  title,
  openReopenModal,
  filterParticipantsOnCycle,
  setopenReopenModal,
  openReopenModalConfirm,
  setOpenReopenModalConfirm,
  reOpenReviews,
  reOpenTitle,
}: ViewProps): JSX.Element => (
  <>
    <DisplayCycleModals
      open={openDeleteModal}
      bulkActionModalParams={bulkActionModalParams}
      removeMultipleReviews={removeMultipleReviews}
      handleModalDisplay={handleModalDisplay}
      setBulkActionModalParams={setBulkActionModalParams}
      modalParams={modalParams}
      removeSingleReview={removeSingleReview}
    />
    <DisplayCompleteModal
      open={openFinishModal}
      bulkActionModalParams={bulkActionModalParams}
      setBulkActionModalParams={setBulkActionModalParams}
      completeCycle={completeCycle}
      setOpenCompleteModal={setOpenCompleteModal}
      nudgeMultipleParticipants={nudgeMultipleParticipants}
    />
    <DisplayDeleteModal
      open={openDeleteCycleModal}
      handleModalDisplay={handleModalDisplayDeleteCycle}
      deleteCycle={deleteCycle}
      title={title}
    />
    <DisplayReopenModal
      open={openReopenModal}
      handleModalDisplay={setopenReopenModal}
      filterParticipantsOnCycle={filterParticipantsOnCycle}
    />
    <DisplayReopenModalConfirm
      open={openReopenModalConfirm}
      handleModalDisplay={setOpenReopenModalConfirm}
      reOpenReviews={reOpenReviews}
      reOpenTypeTitle={reOpenTitle}
    />
  </>
);

interface DisplayCycleModalsContainerProps extends ComponentProps<'div'> {
  openDeleteModal: boolean,
  openDeleteCycleModal: boolean,
  openFinishModal: boolean,
  bulkActionModalParams: string[],
  removeMultipleReviews: () => void,
  handleModalDisplay: (value: boolean, params?: modalParamsProps) => void,
  setBulkActionModalParams:(value: string[]) => void,
  modalParams?: modalParamsProps,
  removeSingleReview: () => void,
  completeCycle: (value: boolean) => void,
  setOpenCompleteModal: (value: boolean) => void,
  nudgeMultipleParticipants: (isFinishing: boolean) => void,
  handleModalDisplayDeleteCycle: (value: boolean) => void,
  deleteCycle: () => void,
  title: string,
  openReopenModal: boolean,
  filterParticipantsOnCycle: ReviewCycleDetail,
  setopenReopenModal: (value: boolean) => void,
  setOpenReopenModalConfirm: (value: boolean) => void,
  openReopenModalConfirm: boolean,
  reOpenReviews: () => void,
}

const DisplayCycleModalsContainer = ({
  openDeleteModal,
  openDeleteCycleModal,
  openFinishModal,
  bulkActionModalParams,
  removeMultipleReviews,
  handleModalDisplay,
  setBulkActionModalParams,
  modalParams,
  removeSingleReview,
  completeCycle,
  setOpenCompleteModal,
  nudgeMultipleParticipants,
  handleModalDisplayDeleteCycle,
  deleteCycle,
  title,
  openReopenModal,
  filterParticipantsOnCycle,
  setopenReopenModal,
  openReopenModalConfirm,
  setOpenReopenModalConfirm,
  reOpenReviews,
}: DisplayCycleModalsContainerProps): JSX.Element => {
  const reOpenTitle = modalParams?.name ? `Are you sure you want to reopen ${modalParams?.name}’s Review` : 'Are you sure you want to reopen these Reviews?';

  const hookProps = {
    openDeleteModal,
    openDeleteCycleModal,
    openFinishModal,
    bulkActionModalParams,
    removeMultipleReviews,
    handleModalDisplay,
    setBulkActionModalParams,
    modalParams,
    removeSingleReview,
    completeCycle,
    setOpenCompleteModal,
    nudgeMultipleParticipants,
    handleModalDisplayDeleteCycle,
    deleteCycle,
    title,
    openReopenModal,
    filterParticipantsOnCycle,
    setopenReopenModal,
    openReopenModalConfirm,
    setOpenReopenModalConfirm,
    reOpenReviews,
    reOpenTitle,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default DisplayCycleModalsContainer;
