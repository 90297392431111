import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { privateNotesTemplate } from '~Meetings/components/drawers/PrivateNotesDrawer';

interface UsePrivateMeetingNotesDrawerReturn {
  openDrawer: (meetingFactoryId: string) => void,
}

export function usePrivateMeetingNotesDrawer(): UsePrivateMeetingNotesDrawerReturn {
  const dispatch = useDispatch();

  const openDrawer = (meetingFactoryId: string): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...privateNotesTemplate,
        args: {
          meetingFactoryId,
        },
      },
    }));
  };

  return {
    openDrawer,
  };
}
