import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { LinearProgress } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import Tooltip from '~Common/components/Tooltip';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import NewGoalStatus from '~Goals/components/Shared/NewGoalStatus';
import { PROGRESS_BAR_STYLES } from '~Goals/const/styles';
import { forMobileObject } from '~Common/styles/mixins';
import { LeadrHomeCascadingGoalRow } from './getLeadrHomeCascadingTableRows';

const styles = {
  ...PROGRESS_BAR_STYLES,
  assigneeField: css({
    display: 'flex',
    alignItems: 'center',
  }),
  progressWrapper: css({
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
  }, forMobileObject({
    width: '100%',
    flexDirection: 'row',
    gap: '.5rem',
    alignItems: 'center',
  })),
  progress: (short?: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '.3125rem',
  }, short && {
    maxWidth: '4.0625rem',
  }),
  progressBarWrap: css({
    flexBasis: '80%',
  }),
  percent: css({
    flexBasis: '15%',
  }),
  goalDates: css({
    whiteSpace: 'normal',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.25rem',
  }),
  progressBarAdjustment: css({
    height: '.25rem',
  }),
};

const useGetLeadrHomeCascadingTableColumns = (): GridColDef<LeadrHomeCascadingGoalRow>[] => {
  const tableColumnHeaders: GridColDef<LeadrHomeCascadingGoalRow>[] = useMemo(() => [
    {
      field: 'progress',
      sortable: false,
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<LeadrHomeCascadingGoalRow>) => {
        const {
          percentage,
          status,
        } = params.row.progress;

        return (
          <>
            <div css={styles.progressWrapper}>
              <div css={styles.progress(true)}>
                <div css={styles.progressBarWrap}>
                  <Tooltip content={`${percentage ?? 0}%`}>
                    <LinearProgress
                      css={[styles.progressBar(status ?? Goals.GoalStatus.OnTrack), styles.progressBarAdjustment]}
                      value={percentage ?? 0}
                      variant="determinate"
                    />
                  </Tooltip>
                </div>
                <div css={styles.percent}>
                  {`${percentage || 0}`}
                  %
                </div>
              </div>
              <div>
                <NewGoalStatus
                  isMobile
                  status={status}
                />
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: 'owner',
      sortable: false,
      headerName: 'Owner',
      flex: 1,
      maxWidth: 75,
      renderCell: (params: GridRenderCellParams<LeadrHomeCascadingGoalRow>) => {
        const {
          name,
          imgUrl,
        } = params.row.owner;

        return (
          <div css={styles.assigneeField}>
            <NewBaseAvatar
              name={name}
              profileImageUrl={imgUrl}
              avatarSize={30}
            />
          </div>
        );
      },
    },
  ], []);

  return tableColumnHeaders;
};

export default useGetLeadrHomeCascadingTableColumns;
