import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { drawerInputDisabled, drawerInputText, gradients } from '~Deprecated/ui/styles/colors';
import { palette } from '~Common/styles/colors';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import DrawerAvatar from '~Deprecated/common/Components/People/Avatar/DrawerAvatar';
import { useGetPersonById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import AddToMeetingButton from '../Buttons/AddToMeeting';

const placeholderText = ({ status, richText }) => {
  switch (status) {
    case ANSWER_STATUS.COMPLETED:
      return richText;
    case ANSWER_STATUS.INCOMPLETE:
      return '<em>Waiting for Response...</em>';
    case ANSWER_STATUS.OPTED_OUT:
      return 'Opted Out';
    default:
      // Should we log something here?
      return '';
  }
};

const backgroundColor = (status) => {
  switch (status) {
    case ANSWER_STATUS.COMPLETED:
      return drawerInputDisabled;
    case ANSWER_STATUS.INCOMPLETE:
      return gradients.sky;
    case ANSWER_STATUS.OPTED_OUT:
      return palette.neutrals.gray900;
    default:
      // Should we log something here?
      return drawerInputDisabled;
  }
};

const styles = {
  drawerItem: (status) => css`
    width: 100%;
    padding: 1rem;
    background: ${backgroundColor(status)};
    border-radius: 0.5rem;
    display: flex;
    justify-content: flex-start;
  `,
  itemInfo: (status) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: transparent;
    font-size: 16px;
    color: ${status === ANSWER_STATUS.COMPLETED ? drawerInputText : 'white'};

    & ol, ul, dl {
      margin-bottom: 0;
    }
  `,
  avatar: css`
    justify-content: flex-start;
  `,
};

// TODO: Delete this after feedback UI rewrite
const AnswerDisplay = ({
  userId,
  richText,
  status,
  className,
  rteTextColor,
  ...props
}) => {
  const person = useGetPersonById({ id: userId });
  const { canRequestFeedback: canAddToMeeting } = useOrgUserPermissionsContext();

  return (
    <div
      css={styles.drawerItem(status)}
      className={className}
      data-test-id="feedbackResponseWrapper"
    >
      <div css={[styles.itemInfo(status), styles.avatar]}>
        {person && (
          <DrawerAvatar
            imgUrl={person?.profileImageUrl}
            name={`${person?.firstName} ${person?.lastName}`}
          />
        )}
      </div>
      <Froala
        initialValue={placeholderText({ status, richText })}
        isReadOnly
        styleOverrides={styles.itemInfo(status, rteTextColor)}
      />
      {status === ANSWER_STATUS.COMPLETED
        && person?.administrativeStatus !== 'INACTIVE'
        && canAddToMeeting
        && (
        <div css={styles.itemInfo(status)}>
          <AddToMeetingButton
            {...props}
          />
        </div>
        )}
    </div>
  );
};

AnswerDisplay.propTypes = {
  userId: PropTypes.string.isRequired,
  richText: PropTypes.string,
  status: PropTypes.string,
  className: PropTypes.string,
  rteTextColor: PropTypes.string,
};

AnswerDisplay.defaultProps = {
  richText: '',
  status: ANSWER_STATUS.COMPLETED,
  className: '',
  rteTextColor: '',
};

export default AnswerDisplay;
