import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { ReactText, useRef } from 'react';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useHistory } from 'react-router-dom';
import { MEETING_ROUTE } from '~Common/const/routes';

interface EditMeetingLinkedTeamParams {
  factoryId: string,
  teamId: string,
}

const editMeetingLinkedTeam = async ({
  factoryId,
  teamId,
}: EditMeetingLinkedTeamParams): Promise<HttpCallReturn<unknown>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/huddles/series/teamLink`;

  return patchApi<unknown>(serverUrl, { factoryId, teamId });
};

interface UseEditMeetingLinkedTeamParams {
  meetingId: string,
  onSuccessCallback?: () => void,
}

export const useEditMeetingLinkedTeam = ({
  meetingId,
  onSuccessCallback,
}: UseEditMeetingLinkedTeamParams): UseMutationResult<HttpCallReturn<unknown>, unknown, EditMeetingLinkedTeamParams> => {
  const toastId = useRef<ReactText | number | null>(null);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);

  return useMutation({
    mutationFn: editMeetingLinkedTeam,
    onMutate: () => {
      toastId.current = toast.info('Updating your linked team...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error updating your linked team. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (_, variables) => {
      onSuccessCallback?.();

      // Can only link teams to team meetings
      void queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', meetingId, MeetingTypeEnum.MEETING] });

      /**
       * The team id was added to the url search params to help pre-load the goals and team data for the meeting before the meeting details are loaded.
       * Because of this when the team for the meeting changes we need to change the team ID in teh route when it changes
       * To get the proper goals and team clarity questions to show.
       */
      const teamId = variables?.teamId;
      if (teamId) {
        urlParams.set('teamId', teamId);
      } else {
        urlParams.delete('teamId');
      }

      history.push({
        pathname: MEETING_ROUTE,
        search: `?${urlParams.toString()}`,
      });

      toast.update(toastId.current, {
        render: 'Successfully updated your linked team.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });
};
