import React from 'react';
import './styles.scss';
import moment from 'moment-timezone';
import UserInfo from '../../../UserInfo';
import { dateFormat } from '../../Config';
import RichText from '../../../RichText';
import Froala, { FROALA_SINGLE_LINE_HEIGHT } from '~Common/components/Fields/RichTextEditors/Froala';
import { isHTMLText } from '~Common/utils/isHTMLText';

// TODO: Rewrite this with new UI in meetings
const ContentListItem = (props) => {
  const itemObj = props.queAnsData;
  const ownerInfo = props.ownerInfo;
  const assigneeInfo = props.assigneeInfo;
  return (
    <div className="content-list-item-wrapper mb-2">
      <div className="feedback-data-list">
        <div className="discussion">
          <div className="user-image">
            <UserInfo
              users={[
                ownerInfo,
              ]}
            />
          </div>
          <div className="agenda-text-wrapper">
            {isHTMLText(itemObj.topic) && (
              <RichText text={itemObj.topic} className="ql-editor" />
            )}
            {!isHTMLText && (
              <div className="ql-editor custom-ml">{ itemObj.topic }</div>
            )}
          </div>
        </div>
      </div>
      <div className="notes-list">
        <div className="feedback-input-wrapper">
          <div className="user-image pl-2">
            <UserInfo
              users={[assigneeInfo]}
            />
          </div>
          { props.isEditable ? (
            <div className="agenda-text-wrapper">
              <Froala
                initialValue=""
                onChange={({ value: text }) => props.handleChange(itemObj.id, '', 'sendResponsAndViewContent', true, text)}
                autoFocus={props.page?.toUpperCase() !== 'COACHING'}
                heightMin={FROALA_SINGLE_LINE_HEIGHT}
              />
              { ' ' }
            </div>
          ) : (
            <React.Fragment>
              {itemObj.response && itemObj.response.length > 0 && (

                <div className="agenda-text-wrapper">
                  <div className="due-date">
                    { props.isShowDate
                      ? moment(itemObj.response[0].createdDateTime).format(dateFormat)
                      : '' }
                  </div>
                  <RichText
                    text={itemObj.response[0] ? itemObj.response[0].text : props.answer}
                    className="ql-editor editor-wrapper"
                  />
                  {/* {itemObj.response[0] ? itemObj.response[0].text : props.answer} */ }

                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContentListItem;
