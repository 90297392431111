import { Goals } from '@leadr-hr/types';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { InsightsFor } from '~Home/const/interfaces';

interface upcomingWeekListParams {
  includeIgnoredMeetings: boolean,
  timezone: string,
  count: number,
}
interface HomeQueryKeysParamsProps {
  take?: number,
  daysAhead?: number,
}
export const homeQueryKeys = {
  all: (orgId: string) => [orgId, 'home'] as const,
  upcomingWeekLists: (orgId: string) => [...homeQueryKeys.all(orgId), 'upcomingWeek', 'list'] as const,
  upcomingWeekList: (orgId: string, {
    includeIgnoredMeetings,
    timezone,
    count,
  }: upcomingWeekListParams) => [...homeQueryKeys.upcomingWeekLists(orgId), includeIgnoredMeetings.toString(), timezone, count.toString()] as const,
  insightsPeople: (orgId: string) => [...homeQueryKeys.all(orgId), 'insightsPeople', getUserId()] as const,
  insightsPeopleDetail: (orgId: string, insightsFor: InsightsFor) => [...homeQueryKeys.insightsPeople(orgId), insightsFor] as const,
  celebrate: (orgId: string) => [...homeQueryKeys.all(orgId), 'celebrate', getOrganizationId()] as const,
  celebrateDetails: (orgId:string, params: HomeQueryKeysParamsProps) => [...homeQueryKeys.celebrate(orgId), params] as const,
  insightsPeers: (orgId: string) => [...homeQueryKeys.all(orgId), 'insightsPeers'] as const,
  homeActionItems: (orgId: string) => [...homeQueryKeys.all(orgId), 'homeActionItems'] as const,
  homeFeedback: (orgId: string) => [...homeQueryKeys.all(orgId), 'homeFeedback'] as const,
  homeLearning: (orgId: string) => [...homeQueryKeys.all(orgId), 'homeLearning'] as const,
  homeSurvey: (orgId: string) => [...homeQueryKeys.all(orgId), 'homeSurvey'] as const,
  homeReviews: (orgId: string) => [...homeQueryKeys.all(orgId), 'homeReviews'] as const,
  homeSettings: (orgId: string, userId: string) => [...homeQueryKeys.all(orgId), 'settings', userId] as const,
  homeTeamClarity: (orgId: string) => [...homeQueryKeys.all(orgId), 'homeTeamClarity'] as const,
  homeGoals: (
    orgId: string,
    filters: Goals.Requests.GetLeadrHomeGoalsRequestQueryParameters = {},
  ) => [...homeQueryKeys.all(orgId), 'homeGoals', filters] as const,
};
