import { HTMLString } from '~Common/types';

/* eslint-disable no-bitwise */
export const SIZE_OPTIONS = {
  FULL_ROW: 'fullRow',
  HALF_ROW: 'halfRow',
  HALF_ROW_NO_MARGIN: 'halfRowNoMargin',
  QUARTER_ROW: 'quarterRow',
};
export enum CycleStatus {
  Draft = 1,
  Active = 2,
  Completed = 3,
  New = 4,
}

export enum ViewPerspective {
  Admin = 'admin',
  MyReviews = 'reviews',
  Reflections = 'reflections',
  Create_Cycle = 'createCycle'
}

export enum ViewSubPerspective {
  Default = 'default',
  Show_Details = 'showDetails',
}

export enum ViewCyclePerspective {
  Setup = 'setup',
  Create_Questions = 'questions',
  Add_Participants = 'participants',
  Preview_Publish = 'preview',
  Publish = 'publish',
}

export const CycleSteps = {
  [ViewCyclePerspective.Setup]: 0,
  [ViewCyclePerspective.Create_Questions]: 1,
  [ViewCyclePerspective.Add_Participants]: 2,
  [ViewCyclePerspective.Preview_Publish]: 3,
};

export interface AllCycles {
  Active: ReviewCycleWithParticipants[],
  Draft: ReviewCycleWithParticipants[],
  Completed: ReviewCycleWithParticipants[],
}

export interface AllReviews {
  Active: ReviewCycleDetail[],
  Completed: ReviewCycleDetail[],
}

export enum CycleType {
  Active = 'Active',
  Draft = 'Draft',
  Completed = 'Completed',
}

export enum ReviewType {
  Active = 'Active',
  Completed = 'Completed',
}

export interface GetCycleData {
  data: CycleShape,
  topics:TopicShape[],
  status: string,
  isLoading: boolean,
}

export interface CycleTopicsMap {
  topicTypeConfig: linearTopicConfig | multiChoiceTopicConfig,
  type: number;
  topicText: string,
  topicIsForMentors: boolean,
  topicIsForReviewees: boolean,
  topicIsForReviewers: boolean,
  id: string
}
export interface CycleShape {
  uid: string,
  createdAt: Date,
  lastModifiedAt: Date,
  isDeleted: boolean,
  assessmentPeriodStart: Date,
  assessmentPeriodEnd: Date,
  assessmentDueDate: Date,
  signOffDueDate: Date,
  name: string,
  description: string,
  participationEnum: ParticipantTypeEnum, // Defaults to 'Reviewer | Reviewee'
  statusEnum: ReviewCycleStatusEnum,
  typeEnum: ReviewCycleTypeEnum,
  allowedAdminOrgUserIds: string[],
}

export interface ReviewCycleWithParticipants extends CycleShape {
  participants: ReviewParticipant[],
  completionPct: number,
}
export interface ReviewTopic {
  uid: string,
  rank: number,
  text: string,
  topicTargetEnum: ParticipantTypeEnum, // NEW
  typeConfig: linearTopicConfig | multiChoiceTopicConfig | string,
  typeEnum: TopicTypeEnum,
}
export interface CreateReviewDto {
  participants: Array<{
    orgUserId: string,
    participantType: ParticipantTypeEnum,
  }>
}
export enum TopicTypeEnum {
  Unknown = 0,
  Freeform = 1,
  Linear = 2,
  MultipleChoice = 3,
}
export enum ReviewCycleStatusEnum {
  Unknown = 0,
  Draft = 1,
  Active = 2,
  AllSubmitted = 3, // Completed naturally by participants
  Finished = 4, // Fully closed
}
export enum ReviewCycleStatusEnumConversion {
  Unknown = 'Unknown',
  Draft = 'Draft',
  Active = 'Active',
  AllSubmitted = 'All Submitted',
  Finished = 'Finished',
}
export enum ReviewCycleTypeEnum{
  Unknown = 0,
  Open = 1,
  Closed = 2,
}
export interface CycleReturnById {
  data: ReviewCycleWithParticipants | undefined,
  status: string,
  isLoading: boolean,
}
export interface TopicReturnById {
  data: TopicShape[] | undefined,
  status: string,
  isLoading: boolean,
}
export enum CycleTypeEnum {
  Unknown = 0,
  Open = 1,
  Closed = 2,
}
export interface CreateCycle {
  name: string,
  description: string,
  typeEnum: CycleTypeEnum,
  participationEnum: ParticipantTypeEnum,
  assessmentPeriodStart: string, // Date, but we have to serialize
  assessmentPeriodEnd: string, // same
  assessmentDueDate: string, // same
  signOffDueDate: string, // same
}

export enum ParticipantTypeEnum {
  Unknown = 0,
  Reviewee = 1 << 0,
  Reviewer = 1 << 1,
  SecondaryReviewer = 1 << 2,
  Everyone = Reviewee | Reviewer | SecondaryReviewer,
}

export enum ParticipantTypeEnumConversion {
  SecondaryReviewer = 'Secondary Reviewer',
  Reviewer = 'Reviewer',
  Reviewee = 'Reviewee',
}

export interface linearTopicConfig {
  minLabel: string,
  maxLabel: string,
  numScale: number,
}
export interface multiChoiceTopicConfig {
  multipleChoiceOptions: Array<{
      text: string;
  }>,
}
export interface TopicShape {
  isDeleted: boolean,
  rank: number,
  reviewCycleUid: string,
  text: HTMLString,
  topicTargetEnum: ParticipantTypeEnum, // NEW
  typeConfig: linearTopicConfig | multiChoiceTopicConfig | string | Record<string, unknown>,
  typeEnum: TopicTypeEnum,
  uid: string,
  description: HTMLString,
  reflections: AssignedReflection[],
  answers: ReviewAnswer[],
}

export interface AssignedReflection {
  uid: string,
  createdAt: Date,
  lastModifiedAt: Date,
  reflectionTypeEnum: ReflectionTypeEnum,
  participantUid: string,
  topicUid: string,
  subjectUid: string,
  title: string,
}

enum ReflectionTypeEnum {
  Unknown = 0,
  Goal = 1,
  Learning = 2,
  Feedback = 3,
  BookmarkedTopic = 4
}

export interface ReviewAnswer {
  uid: string,
  participantUid: string,
  topicUid: string,
  stringResponse?: string,
  numericResponse?: number,
  createdAt: Date,
  lastModifiedAt: Date,
}
export interface ReviewShape {
  isDeleted: boolean,
  participants: PersonDisplayInformationReviews[],
  reviewCycle: ReviewCycle,
  uid: string,
  topics: TopicShape[],
  reviewerStatus: string,
  secondaryReviewerStatus: string,
  revieweeStatus: string,
  releasedAt: Date,
}
export interface ReviewCycle {
  uid: string,
  createdAt: Date,
  lastModifiedAt: Date,
  isDeleted: boolean,
  organizationUid: string,
  assessmentPeriodStart: Date,
  assessmentPeriodEnd: Date,
  assessmentDueDate: Date,
  signOffDueDate: Date,
  completionPct: number,
  name: string,
  description: string,
  participationEnum: ParticipantTypeEnum, // Defaults to 'Reviewer | Reviewee'
  statusEnum: ReviewCycleStatusEnum,
  typeEnum: ReviewCycleTypeEnum,
  publishedAt: Date,
}
export interface ReviewCycleDetail extends ReviewCycle {
  reviews: Review[]
}

export interface Review {
  uid: string,
  isDeleted: boolean,
  participants: ReviewParticipant[],
  reviewerStatus: string,
  secondaryReviewerStatus: string,
  revieweeStatus: string,
}

export interface ReviewParticipant {
  uid: string,
  orgUserId: string,
  firstName: string,
  lastName: string,
  profileImageUrl?: string,
  participantTypeEnum: ParticipantTypeEnum,
  signOffComment?: string,
  statusEnum: ReviewStatusEnum,
  releasedAt?: Date | null, // Notable change -- this is an explicit value rather than part of the enum.
  signedOffAt?: Date | null, // Notable change -- this is an explicit value rather than part of the enum.
}

export enum ReviewStatusEnum {
  Unknown = 0,
  Active = 1,
  Submitted = 2,
  Finished = 3,
}

export enum ReviewStatusColor {
  Green = 'green',
  Orange = 'orange',
  Gray = 'gray',
}

export enum ReviewDetailsTabs {
  Reviewees = 'Reviewees',
  Progress_Chart = 'Progress Chart',
}

export interface PersonDisplayInformationReviews {
  firstName: string,
  lastName: string,
  uid: string,
  jobTitle: string,
  profileImageUrl?: string,
  comment: string,
  statusEnum: ReviewStatusEnum, // Status of their review -> Completed, in-progress, Closed, Released
  signedOffAt: string,
  signOffComment: HTMLString,
  releasedAt: string,
  participantTypeEnum: number,
  orgUserId: string,
}

export enum QuestionType {
  Freeform = 1,
  Custom = 8,
  MultipleChoice = 9,
}

export enum UpdateCycle {
  Title = 'title',
  Description = 'description',
}

export interface AnswerShape {
  reviewTopicUid: string,
  stringResponse: string,
  numericResponse?: number,
  updatedAt: string,
}

export enum ParticipantsFilterType {
  All_Participants = 0,
  Manager = 1,
}

export enum ModifyQuestionType {
  Edit = 'edit',
  Delete = 'delete',
}

export enum FilterType {
  All = 'all',
  Managers = 'managers',
  Status = 'status',
}
export enum FilterTypeTranslations {
  Managers = 'managers',
  Statuses = 'status',
}

export enum ManagerReviewStatuses {
  Start_Review = 'Start Review',
  Submit_Review = 'Submit Review',
  Awaiting_Release = 'Awaiting Review Release',
  Awaiting_Responses = 'Awaiting Responses',
  Release_Review = 'Release Review',
  Signoff_Review = 'Sign-off Review',
  Complete = 'Complete',
  Closed = 'Closed',
}

export enum SecondaryManagerReviewStatuses {
  Start_Review = 'Start Review',
  Submit_Review = 'Submit Review',
  Awaiting_Release = 'Awaiting Review Release',
  Signoff_Review = 'Sign-off Review',
  Complete = 'Complete',
  Closed = 'Closed',
  NA = 'N/A',
}
export enum RevieweeReviewStatuses {
  Start_Review = 'Start Review',
  Submit_Review = 'Submit Review',
  Awaiting_Release = 'Awaiting Review Release',
  Signoff_Review = 'Sign-off Review',
  Complete = 'Complete',
  Closed = 'Closed',
}

export const inProgressConverstion = [
  RevieweeReviewStatuses.Submit_Review,
  ManagerReviewStatuses.Submit_Review,
  SecondaryManagerReviewStatuses.Submit_Review,
];

export const reviewerNotStartedExcludedStatuses = [
  ManagerReviewStatuses.Start_Review,
  ManagerReviewStatuses.Submit_Review,
];
export const revieweeNotStartedExcludedStatuses = [
  RevieweeReviewStatuses.Start_Review,
  RevieweeReviewStatuses.Submit_Review,
];
export const secondaryNotStartedExcludedStatuses = [
  SecondaryManagerReviewStatuses.Start_Review,
  SecondaryManagerReviewStatuses.Submit_Review,
];

export const reviewerStartedStatuses = [
  ManagerReviewStatuses.Awaiting_Responses,
  ManagerReviewStatuses.Signoff_Review,
];
export const revieweeStartedStatuses = [
  RevieweeReviewStatuses.Awaiting_Release,
  RevieweeReviewStatuses.Signoff_Review,
];
export const secondaryStartedStatuses = [
  SecondaryManagerReviewStatuses.Awaiting_Release,
  SecondaryManagerReviewStatuses.Signoff_Review,
];

export const reviewerCompletedExcludedStatuses = [
  ManagerReviewStatuses.Complete,
];
export const revieweeCompletedExcludedStatuses = [
  RevieweeReviewStatuses.Complete,
];
export const secondaryCompletedExcludedStatuses = [
  SecondaryManagerReviewStatuses.Complete,
];

export enum SetDisplay {
  Desktop = 'desktop',
  Print = 'print',
  Mobile = 'mobile',
}

export interface PermissionsProps {
  [key: string]: boolean,
}

export interface modalParamsProps {
  id: string,
  name: string,
  orgUserId?: string,
}

export interface userRowsProps{
  id: string,
  name: {
    name: string,
    image: string
  },
  orgUserId: string,
  firstName: string,
  lastName: string,
  revieweeStatus: string,
  reviewerStatus: string,
  secondaryReviewerStatus: string,
  actions: string,
}

export type RadioOptionsShape = {
  name: string,
  value: string,
  tooltip?: string,
}

export enum ReOpenReviewOptionsValues {
  All = '0',
  Submitted = '1',
  Select = '2',
  Completed = '3',
}
