import { css } from '@emotion/react';
import {
  faUser,
  faTrash, faPenToSquare,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import {
  REVIEW_SETUP_LAYOUT, REVIEW_DISPLAY_STYLES, BUTTON_STYLES,
} from '~Reviews/V2/Const/pageStyles';
import {
  MultiRadio,
  Form,
} from '~Common/V3/components/uncontrolled';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import { UseFormReturn } from 'react-hook-form';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import Froala from '~Common/V3/components/Froala';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { stripHtml } from '~Common/utils/stripHtml';
import {
  ParticipantTypeEnum, QuestionType, TopicShape, TopicTypeEnum, ModifyQuestionType, multiChoiceTopicConfig, linearTopicConfig,
} from '../Const/types';
import CustomScale from './CustomScale';
import { FormValues } from '../schemata/CreateTopicForCycle';
import { FormValuesMatrixOrg } from '../schemata/CreateTopicForCycleMatrixOrgs';
import { ReviewTemplateQuestion } from '../templates/useReviewTemplates';
import { AddTopicButton } from '../templates/TemplateDetailsDrawer/AddTopicButton';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...REVIEW_DISPLAY_STYLES,
  ...BUTTON_STYLES,
  previewRadio: css({
    marginBottom: '1.5rem',

    '& .MuiFormGroup-root label, & .MuiFormControlLabel-label.Mui-disabled': {
      border: 'none !important',
      background: 'none !important',
      padding: '0 !important',
      margin: '0 !important',
      color: `${palette.neutrals.gray800} !important`,
    },
    '& .MuiRadio-root': {
      padding: '.25rem .5rem .25rem 0',
    },
  }),
  actionIcons: css({
    color: palette.neutrals.gray700,

    '& svg': {
      width: '.875rem !important',
    },
  }),
  hide: css({
    display: 'none',
  }),
  titleRow: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  disabledFrola: css({
    minHeight: '8.125rem',
    '& h6': {
      color: ` ${palette.neutrals.gray700} !important`,

    },
  }),
};

interface ViewProps {
  topic: TopicShape | ReviewTemplateQuestion,
  isQuestions: boolean,
  onModifyQuestion: (uid: string, action: ModifyQuestionType) => void,
  multipleChoiceOptions: multiChoiceTopicConfig | undefined,
  linearChoiceOptions: linearTopicConfig | undefined,
  formContext: UseFormReturn<FormValues | FormValuesMatrixOrg>,
  isReadOnly: boolean,
  isTemplateView: boolean,
  reviewCycleUid?: string,
  isLastQuestion?: boolean,
}

const View = ({
  topic,
  isQuestions,
  onModifyQuestion,
  multipleChoiceOptions,
  linearChoiceOptions,
  formContext,
  isReadOnly,
  isTemplateView,
  reviewCycleUid,
  isLastQuestion,
}: ViewProps): JSX.Element => (
  <Fragment
    key={topic.uid}
  >
    <div
      css={[styles.questionWrap(isQuestions), styles.questionWrapBorder(isLastQuestion ?? false)]}
    >
      <div css={styles.titleRow}>
        <div css={styles.answerTitle}>{stripHtml(topic.text)}</div>
        {isTemplateView && reviewCycleUid && (
          <AddTopicButton
            topic={topic as TopicShape}
            reviewCycleUid={reviewCycleUid}
          />
        )}
      </div>
      {topic.description && (
        <HTMLRenderer css={styles.answerDescription} htmlText={topic.description} />
      )}
      {!isReadOnly && (
        <div css={styles.modifyQuestionWrapper}>
          <Tooltip interactive content="Edit Question">
            <IconButton
              onClick={() => onModifyQuestion(topic.uid, ModifyQuestionType.Edit)}
              type="button"
              icon={faPenToSquare}
              css={styles.actionIcons}
              tooltip={undefined}
              size="small"
            />
          </Tooltip>
          <Tooltip interactive content="Delete Question">
            <IconButton
              onClick={() => onModifyQuestion(topic.uid, ModifyQuestionType.Delete)}
              type="button"
              icon={faTrash}
              css={styles.actionIcons}
              tooltip={undefined}
              size="small"
            />
          </Tooltip>
        </div>
      )}
      {topic.typeEnum === TopicTypeEnum.Freeform && (
        <div css={styles.previewRadio}>
          <Froala
            enableEmojis
            label="Your Answer"
            name="description"
            froalaConfigProps={{
              charCounterCount: true,
              charCounterMax: 2000,
            }}
            richTextEditorProps={{
              name: 'description',
              onChange: ({ value: newText }) => formContext.setValue('description', newText, { shouldDirty: true }),
              initialValue: '',
            }}
            isDisabled
            styleOverrides={styles.disabledFrola}
          />
        </div>
      )}
      {topic.typeEnum === TopicTypeEnum.MultipleChoice && multipleChoiceOptions && (
        <div css={styles.previewRadio}>
          <Form
            formContext={formContext}
            onSubmit={() => null}
          >
            <MultiRadio
              name="multipleChoice"
              defaultValue={QuestionType.Freeform}
              disabled
              vertical
              label=""
              radios={multipleChoiceOptions.multipleChoiceOptions.map((option: { text: string; }) => ({
                name: (
                  <p>
                    <span>{option.text}</span>
                  </p>
                ),
                value: option.text,
              }))}
            />
          </Form>
        </div>
      )}
      {topic.typeEnum === TopicTypeEnum.Linear && linearChoiceOptions && (
        <CustomScale
          marks={linearChoiceOptions.numScale}
          minLabel={linearChoiceOptions.minLabel}
          maxLabel={linearChoiceOptions.maxLabel}
          id={topic.uid}
          initialValue="20"
          disabled
        />
      )}
      {topic.topicTargetEnum !== 0 && (
        <div css={styles.assignmentDisplay}>
          <FontAwesomeIcon icon={faUser} />
          {FlaggedEnum(topic.topicTargetEnum).hasFlag(ParticipantTypeEnum.Reviewer) ? (
            <span>Reviewer</span>
          ) : null}
          {FlaggedEnum(topic.topicTargetEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer) ? (
            <span>Secondary Reviewer</span>
          ) : null}
          {FlaggedEnum(topic.topicTargetEnum).hasFlag(ParticipantTypeEnum.Reviewee) ? (
            <span>Reviewee</span>
          ) : null}
        </div>
      )}
    </div>
  </Fragment>
);

interface QuestionsProps {
    topic: TopicShape | ReviewTemplateQuestion,
    isQuestions: boolean,
    onModifyQuestion: (uid: string, action: ModifyQuestionType) => void,
    formContext: UseFormReturn<FormValues | FormValuesMatrixOrg>,
  isReadOnly?: boolean,
  isTemplateView?: boolean,
  reviewCycleUid?: string,
  isLastQuestion?: boolean,
}

const Questions = ({
  topic,
  isQuestions,
  onModifyQuestion,
  formContext,
  isReadOnly = false,
  isTemplateView = false,
  reviewCycleUid,
  isLastQuestion,
}: QuestionsProps): JSX.Element => {
  let multipleChoiceOptions;
  let linearChoiceOptions;
  if (typeof topic?.typeConfig === 'string' && topic?.typeConfig) {
    multipleChoiceOptions = JSON.parse(topic?.typeConfig) as multiChoiceTopicConfig;
    linearChoiceOptions = JSON.parse(topic?.typeConfig) as linearTopicConfig;
  }
  const hookProps = {
    topic,
    isQuestions,
    onModifyQuestion,
    multipleChoiceOptions,
    linearChoiceOptions,
    formContext,
    isReadOnly,
    isTemplateView,
    reviewCycleUid,
    isLastQuestion,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default Questions;
