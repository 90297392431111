import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import InsightDetailRow from '~Deprecated/ui/containers/Nexus/OrganizationDetailMember';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';

import List from '~Deprecated/ui/components/List';
import OrganizationDetailMemberTooltip from './OrganizationDetailMemberTooltip';

const OrganizationDetailMembers = ({
  ids, loadOrganizationInsightDetailsAction, id, clearOrganizationMembersAction,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadOrganizationInsightDetailsAction({
      page: 1,
      count: 100,
      id,
    });

    return () => {
      clearOrganizationMembersAction();
    };
  }, [loadOrganizationInsightDetailsAction, id, clearOrganizationMembersAction]);

  const onPersonClick = (userId) => dispatch(
    pushDrawerAction({
      drawer: {
        ...baseballCardDrawerTemplate,
        args: {
          id: userId,
          orgId: id,
        },
      },
    }),
  );

  return (
    <List
      ids={ids}
      renderTemplate={({ id: rowId }) => (
        <InsightDetailRow
          key={rowId}
          id={rowId}
          onClick={onPersonClick}
          renderTooltip={(tooltipProps) => (
            <OrganizationDetailMemberTooltip
              {...tooltipProps}
              rowId={rowId}
            />
          )}
        />
      )}
    />
  );
};

OrganizationDetailMembers.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  loadOrganizationInsightDetailsAction: PropTypes.func.isRequired,
  clearOrganizationMembersAction: PropTypes.func.isRequired,
};

OrganizationDetailMembers.defaultProps = {

};

export default OrganizationDetailMembers;
