import {
  Switch, Route,
} from 'react-router-dom';
import {
  // TEAM_DETAILS_GOALS, TODO: Coming later
  TEAM_DETAILS_USERS,
} from '~Common/const/routes';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import TeamUsersTable from '../components/TeamDetails/TeamUsersTable';

interface ViewProps {
  teamId: string,
}

const View = ({
  teamId,
}: ViewProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[TEAM_DETAILS_USERS]}
      render={() => (
        <TeamUsersTable teamId={teamId} />
      )}
    />
    { /* TODO: Coming later */ }
    {/* <Route
      exact
      path={[TEAM_DETAILS_GOALS]}
      render={() => (
        <TeamsTable
          listScope={TeamsListScope.AllTeams}
        />
      )}
    /> */}
    <SmartRedirect from="*" to={TEAM_DETAILS_USERS} />
  </Switch>
);

type TeamDetailsRouterParams = ViewProps;

const TeamDetailsRouter = ({
  ...props
}: TeamDetailsRouterParams): JSX.Element => {
  const hookProps = {};

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default TeamDetailsRouter;
