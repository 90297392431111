import { create } from 'zustand';
import { InsightsFor } from '~Home/const/interfaces';
import { PeoplePageFilterType } from '~People/const/interfaces';

export const defaultPeoplePageFilterValue = InsightsFor.Everyone;

interface PeoplePageFilterStoreState {
  filterValue: string,
  setFilterValue: (nextValue: string) => void,
  filterType: PeoplePageFilterType | null,
  setFilterType: (nextValue: PeoplePageFilterType | null) => void,
  workingGeniusValue: string[],
  setWorkingGeniusValue: (nextValue: string[]) => void,
}

export const usePeoplePageFilter = create<PeoplePageFilterStoreState>((set) => ({
  filterValue: defaultPeoplePageFilterValue,
  setFilterValue: (nextValue: string) => set(() => ({ filterValue: nextValue })),
  filterType: null,
  setFilterType: (nextValue: PeoplePageFilterType | null) => set(() => ({ filterType: nextValue })),
  workingGeniusValue: [],
  setWorkingGeniusValue: (nextValue: string[]) => set(() => ({ workingGeniusValue: nextValue })),
}));
