import { css } from '@emotion/react';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { matchPath, useLocation } from 'react-router-dom';
import { SyntheticEvent } from 'react';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '~Reviews/V2/Const/pageStyles';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { palette } from '~Common/styles/colors';
import { FILTER_SEPARATOR } from '~Reviews/V2/Const/defaults';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { FilterType, ReviewDetailsTabs } from '../../Const/types';
import FauxDropdown from '../FauxDropdown';
import { MenuItem } from '../ActionMenu';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
  dataTableFilterBarWrapper: (isAdmin: boolean) => css({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
  }, isAdmin && {
    marginBottom: '2.1875rem',
  }),
  filtersArea: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  tabs: css({
    maxWidth: '50%',

    '& .MuiTab-root': {
      minWidth: 'unset',
      paddingBottom: '.75rem',
      paddingLeft: '0',
      paddingRight: '0',
      textTransform: 'unset',
    },
    '& .MuiTabs-flexContainer': {
      columnGap: '1.875rem',
      rowGap: '.5rem',
    },
  }),
};

interface ViewProps {
    expandedTab: ReviewDetailsTabs,
    changeTab: (event: SyntheticEvent, tab: ReviewDetailsTabs) => void,
    managerMenuItems: MenuItem[][],
    statusMenuItems: MenuItem[][],
    showSkeleton: boolean,
    setActiveFilterText: (filterType: FilterType) => string[],
    isAdmin?: boolean,
    isInAdminView?: boolean,
}

const View = ({
  expandedTab,
  changeTab,
  managerMenuItems,
  statusMenuItems,
  showSkeleton,
  setActiveFilterText,
  isAdmin = false,
  isInAdminView = false,
}: ViewProps): JSX.Element => (
  <>
    <div
      css={styles.dataTableFilterBarWrapper(!isAdmin)}
    >
      {showSkeleton && (
        <MultipleSkeletonLoaders
          css={styles.topBarSkellyLoader}
          numberOfSkeletons={1}
          renderSkeletonItem={() => (
            <CardSkeleton css={styles.cardSkeleton} />
          )}
        />
      )}
      {!showSkeleton && (
        <>
          <TabContext value={expandedTab}>
            <TabList css={styles.tabs} onChange={changeTab} aria-label="Review Cycle Tabs">
              {Object.entries(ReviewDetailsTabs).map(([, value]) => {
                if ((!isAdmin && value === ReviewDetailsTabs.Progress_Chart) || (!isInAdminView && value === ReviewDetailsTabs.Progress_Chart)) {
                  return null;
                }
                return <Tab label={value} key={value} value={value} />;
              })}
            </TabList>
          </TabContext>
          {expandedTab !== ReviewDetailsTabs.Progress_Chart && (
          <div
            css={styles.filtersArea}
          >
            <FauxDropdown
              menuItems={managerMenuItems}
              label=""
              listEmptyText="All Managers"
              activeFilters={setActiveFilterText(FilterType.Managers).join(', ')}
            />
            <FauxDropdown
              menuItems={statusMenuItems}
              label=""
              listEmptyText="All Statuses"
              activeFilters={setActiveFilterText(FilterType.Status).join(', ')}
            />
          </div>
          )}
        </>
      )}
    </div>
  </>
);

interface DataTableFilterBarProps {
    expandedTab: ReviewDetailsTabs,
    changeTab: (event: SyntheticEvent, tab: ReviewDetailsTabs) => void,
    managerMenuItems: MenuItem[][],
    statusMenuItems: MenuItem[][],
    isLoading: boolean,
    filters: string[],
    isAdmin?: boolean,
}

const DataTableFilterBar = ({
  expandedTab,
  changeTab,
  managerMenuItems,
  statusMenuItems,
  isLoading,
  filters,
  isAdmin,
}: DataTableFilterBarProps): JSX.Element => {
  const setActiveFilterText = (filterType: FilterType): string[] => {
    const parsedFilters = filters.map((filter) => filter.split(FILTER_SEPARATOR));
    const setActiveFilters = parsedFilters.filter((filter) => filter.includes(filterType));
    const activeFilters = setActiveFilters.map((filter) => filter[1]);

    return activeFilters;
  };
  const { pathname } = useLocation();
  const getMatchPath = matchPath(pathname, {
    path: '/reviews/admin/cycle/:cycleID',
    exact: true,
  });
  const isInAdminView = getMatchPath?.isExact === true;
  const [showSkeleton] = useSkeletonLoaders(isLoading);

  const hookProps = {
    expandedTab,
    changeTab,
    managerMenuItems,
    statusMenuItems,
    showSkeleton,
    setActiveFilterText,
    isAdmin,
    isInAdminView,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default DataTableFilterBar;
