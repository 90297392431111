import {
  ChangeEvent,
  Dispatch, SetStateAction, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { faArrowLeft, faTimes } from '@fortawesome/pro-light-svg-icons';

import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { SurveyTemplate, useSurveyTemplateList } from '~Surveys/Hooks/useSurveyTemplates';
import { getOrganizationName, getOrganizationUserId } from '~Common/utils/localStorage';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import IconButton from '~Meetings/components/buttons/IconButton';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { TemplatesDrawerBody } from './TemplatesDrawerBody';
import { editSurveyTemplatesTemplate } from '../EditTemplateDrawer';

export const surveyTemplatesTemplate = {
  name: 'SURVEY_TEMPLATES_LIST',
  width: DRAWER_WIDTHS.PRIMARY,
};

export enum DrawerTab {
  YourTemplates,
  CompanyTemplates,
  LeadrTemplates,
}

interface FilterTemplatesParams {
  activeTab: DrawerTab,
  searchText: string,
  orgUserId: string,
}

function filterTemplates(templates: SurveyTemplate[], {
  activeTab,
  searchText,
  orgUserId,
}: FilterTemplatesParams): SurveyTemplate[] {
  const result: SurveyTemplate[] = [];
  const search = searchText.toLocaleLowerCase();

  templates.forEach((template) => {
    if (activeTab === DrawerTab.LeadrTemplates && template.organizationUid) return;
    if (activeTab !== DrawerTab.LeadrTemplates && !template.organizationUid) return;
    if (activeTab === DrawerTab.YourTemplates && template.createdByUid !== orgUserId) return;

    if (searchText === '' || template.title.toLocaleLowerCase().includes(search)) {
      result.push(template);
    }
  });

  return result;
}

const styles = {
  cardSkeleton: css({
    minWidth: '100%',
    height: '12.5rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
  drawerBody: css({
    padding: '1rem 1.5rem',
    maxHeight: '100vh',
    'p, ul': {
      marginBlockStart: 0,
    },
  }),
};

interface ViewProps extends SurveyTemplateDrawerProps {
  activeTab: DrawerTab,
  closeDrawerClick: () => void,
  companyName: string,
  onAddSurveyTemplate: () => void,
  onBackArrow?: () => void,
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
  setActiveTab: Dispatch<SetStateAction<DrawerTab>>,
  templates: SurveyTemplate[],
}

export const View = ({
  activeTab,
  closeDrawerClick,
  companyName,
  onAddSurveyTemplate,
  onBackArrow,
  onSearch,
  setActiveTab,
  templates,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <TabDrawerHeader
        renderCloseButton={(closeButtonStyles) => (
          <IconButton
            css={closeButtonStyles}
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            tooltip={undefined}
            size="large"
          />
        )}
        renderBackButton={(backButtonStyles) => (onBackArrow ? (
          <IconButton
            onClick={onBackArrow}
            tooltip="Go Back"
            type="button"
            icon={faArrowLeft}
            css={backButtonStyles}
            size="large"
          />
        ) : <></>)}
        title="Survey Templates"
        renderTabNav={(tabNavStyles) => (
          <div css={tabNavStyles}>
            <TabNavItem
              onClick={() => setActiveTab(DrawerTab.YourTemplates)}
              isActive={activeTab === DrawerTab.YourTemplates}
              renderNavItem={() => <>Your Templates</>}
            />
            <TabNavItem
              onClick={() => setActiveTab(DrawerTab.CompanyTemplates)}
              isActive={activeTab === DrawerTab.CompanyTemplates}
              renderNavItem={() => <>{`Added By ${companyName}`}</>}
            />
            <TabNavItem
              onClick={() => setActiveTab(DrawerTab.LeadrTemplates)}
              isActive={activeTab === DrawerTab.LeadrTemplates}
              renderNavItem={() => <>Curated by Leadr</>}
            />
          </div>
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <TemplatesDrawerBody
          activeTab={activeTab}
          companyName={companyName}
          onSearch={onSearch}
          templates={templates}
        />
      </div>
    )}
    renderFooter={() => (
      <div css={styles.drawerBody}>
        {activeTab !== DrawerTab.LeadrTemplates && (
          <LeadrButton
            onClick={onAddSurveyTemplate}
            data-test-id="surveysTemplatesAddNew"
          >
            + Add New Survey Template
          </LeadrButton>
        )}
      </div>
    )}
  />
);

interface SurveyTemplateDrawerProps {
  onBackArrow?: () => void,
}

export const SurveyTemplateDrawer = (props: SurveyTemplateDrawerProps): JSX.Element => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<DrawerTab>(DrawerTab.YourTemplates);
  const companyName = getOrganizationName() ?? 'Your Company';
  const [searchText, setSearchText] = useState('');

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ drawerName: surveyTemplatesTemplate.name }));
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const onAddSurveyTemplate = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...editSurveyTemplatesTemplate,
      },
    }));
  };

  const { templates, isLoading } = useSurveyTemplateList();

  const orgUserId = getOrganizationUserId() ?? '';
  const filteredTemplates = filterTemplates(templates, {
    activeTab,
    searchText,
    orgUserId,
  });

  const hookProps = {
    activeTab,
    closeDrawerClick,
    companyName,
    onAddSurveyTemplate,
    onSearch,
    setActiveTab,
    templates: filteredTemplates,
  };

  if (isLoading) {
    return (
      <div css={styles.drawerBody}>
        <MultipleSkeletonLoaders
          numberOfSkeletons={3}
          renderSkeletonItem={() => (
            <CardSkeleton css={styles.cardSkeleton} />
          )}
        />
      </div>
    );
  }

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: surveyTemplatesTemplate.name,
  component: SurveyTemplateDrawer,
});
