import { css } from '@emotion/react';
import { withBaseShadow } from '~Common/styles/mixins';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { AVATAR_CONTEXT } from './index';

const style = css`
  border-radius: 0.5rem;
  ${withBaseShadow()}
`;

const DrawerAvatar = ({
  ...props
}) => (
  <SquareAvatar
    css={style}
    {...props}
    {...AVATAR_CONTEXT.DRAWER}
  />
);

export default DrawerAvatar;
