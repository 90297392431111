import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { NoteContext, NoteData } from '~Common/const/interfaces';

import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';

export interface EditNoteData {
  id: string,
  context: NoteContext,
  note: NoteData
}

export const editNote = async ({
  id,
  context,
  note,
}: EditNoteData): Promise<HttpCallReturn<null>> => {
  const url = `/organizations/${getOrganizationId() ?? ''}/notes/${id}`;

  const requestData = {
    context,
    item: note,
  };

  return patchApi(url, requestData);
};

export const useEditNote = (
  options?: Omit<UseMutationOptions<HttpCallReturn<null>, unknown, EditNoteData>, 'mutationFn'>,
): UseMutationResult<HttpCallReturn<null>, unknown, EditNoteData> => (
  useMutation({
    mutationFn: editNote,
    ...options,
  })
);
