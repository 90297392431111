import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import RecognitionTopBar from '~Recognition/components/TopBars';
import { useParams, useHistory } from 'react-router-dom';
import queryString, { stringify } from 'query-string';
import {
  ChangeEvent, useEffect, useState,
} from 'react';
import { EditPostShape, InfiniteQueryParamsProps } from '~Recognition/const/types';
import {
  DEFAULT_EDIT_POST, RECOGNITION_DEFAULT_DEPARTMENT,
  RECOGNITION_DEFAULT_END_DATE,
  RECOGNITION_DEFAULT_FILTERS, RECOGNITION_DEFAULT_START_DATE, RECOGNITION_DEFAULT_USER_GROUP,
} from '~Recognition/const/defaults';
import { BASE_ROUTE } from '~Common/const/routes';
import { useRecognitionSearch } from '~Recognition/stores/useRecognitionSearch';
import { useShowRecognitionModal } from '~Recognition/hooks/useShowRecognitionModal';
import RecognitionDashboard from './RecognitionDashboard';

interface ViewProps {
  handleCreateModalDisplay: (value: boolean) => void,
  openCreateModal: boolean,
  searchText: string,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  infiniteQueryParams: InfiniteQueryParamsProps,
  setDepartment: (value: string) => void,
  department: string,
  userGroup: string,
  setUserGroup: (value: string) => void,
  setEditPost: (value: EditPostShape) => void,
  editPost: EditPostShape,
  filters: InfiniteQueryParamsProps,
  recognitionId?: string,
  setStartDate: (startDate: string) => void,
  startDate: string,
  setEndDate: (endDate: string) => void,
  endDate: string,
  setSearchText: (value: string) => void,
  onCloseRecognitionDetailsModal: () => void,
}

const View = ({
  handleCreateModalDisplay,
  openCreateModal,
  searchText,
  handleSearchTextChange,
  infiniteQueryParams,
  setDepartment,
  department,
  userGroup,
  setUserGroup,
  setEditPost,
  editPost,
  filters,
  recognitionId,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  setSearchText,
  onCloseRecognitionDetailsModal,
}: ViewProps): JSX.Element => (
  <>
    <BaseLayout>
      <ModuleLandingPageWrapper
        TopComponent={(
          <RecognitionTopBar
            handleSearchTextChange={handleSearchTextChange}
            searchText={searchText}
            handleCreateModalDisplay={handleCreateModalDisplay}
          />
        )}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        ChildComponent={(
          <RecognitionDashboard
            handleCreateModalDisplay={handleCreateModalDisplay}
            openCreateModal={openCreateModal}
            searchText={searchText}
            handleSearchTextChange={handleSearchTextChange}
            infiniteQueryParams={infiniteQueryParams}
            setDepartment={setDepartment}
            department={department}
            userGroup={userGroup}
            setUserGroup={setUserGroup}
            setEditPost={setEditPost}
            editPost={editPost}
            filters={filters}
            recognitionId={recognitionId}
            setStartDate={setStartDate}
            startDate={startDate}
            setEndDate={setEndDate}
            endDate={endDate}
            setSearchText={setSearchText}
            onCloseRecognitionDetailsModal={onCloseRecognitionDetailsModal}
          />
        )}
      />
    </BaseLayout>
  </>
);

interface RecognitionDetailsParams {
  recognitionId: string,
}

const RecognitionLayout = (): JSX.Element => {
  const history = useHistory();

  const recognitionHistory = queryString.parse(history.location.search);
  const { recognitionId } = useParams<RecognitionDetailsParams>();
  const [filters, setFilters] = useState(RECOGNITION_DEFAULT_FILTERS);
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const [department, setDepartment] = useState(filters.department);
  const [userGroup, setUserGroup] = useState(filters.userGroup);
  const [editPost, setEditPost] = useState(DEFAULT_EDIT_POST);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const {
    openModal,
    closeModal,
  } = useShowRecognitionModal();

  const searchText = useRecognitionSearch((state) => state.searchText);
  const debouncedSearchText = useRecognitionSearch((state) => state.debouncedSearchText);
  const setSearchText = useRecognitionSearch((state) => state.setSearchText);

  useEffect(() => {
    if (editPost.editPost) {
      setOpenCreateModal(true);
      closeModal();
    } else {
      setOpenCreateModal(false);
    }
  }, [closeModal, editPost]);
  const updateRecognitionFilters = (): InfiniteQueryParamsProps => {
    const recognitionFilters: InfiniteQueryParamsProps = { ...RECOGNITION_DEFAULT_FILTERS };

    if (debouncedSearchText !== '') {
      recognitionFilters.search = debouncedSearchText;
    } else {
      recognitionFilters.search = '';
      Reflect.deleteProperty(recognitionFilters, 'search');
    }
    if (department !== RECOGNITION_DEFAULT_DEPARTMENT.value) {
      recognitionFilters.department = department;
    } else {
      recognitionFilters.department = RECOGNITION_DEFAULT_DEPARTMENT.value;
      Reflect.deleteProperty(recognitionFilters, 'department');
    }
    if (userGroup !== RECOGNITION_DEFAULT_USER_GROUP.value) {
      recognitionFilters.userGroup = userGroup;
    } else {
      recognitionFilters.userGroup = RECOGNITION_DEFAULT_USER_GROUP.value;
      Reflect.deleteProperty(recognitionFilters, 'userGroup');
    }
    if (startDate !== RECOGNITION_DEFAULT_START_DATE) {
      recognitionFilters.startDate = startDate;
    } else {
      recognitionFilters.startDate = RECOGNITION_DEFAULT_START_DATE;
      Reflect.deleteProperty(recognitionFilters, 'startDate');
    }
    if (endDate !== RECOGNITION_DEFAULT_END_DATE) {
      recognitionFilters.endDate = endDate;
    } else {
      recognitionFilters.endDate = RECOGNITION_DEFAULT_END_DATE;
      Reflect.deleteProperty(recognitionFilters, 'endDate');
    }

    return recognitionFilters;
  };

  useEffect(() => {
    const recognitionFilters = updateRecognitionFilters();
    history.replace({ search: stringify(recognitionFilters) });
    setFilters(recognitionFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, history, debouncedSearchText, userGroup, startDate, endDate]);

  useEffect(() => {
    if (initialLoad) {
      const departmentHistory = recognitionHistory.department as string;
      const userGroupHistory = recognitionHistory.userGroup as string;
      const searchHistory = recognitionHistory.search as string;
      const startDateHistory = recognitionHistory.startDate as string;
      const endDateHistory = recognitionHistory.endDate as string;

      if (searchHistory) {
        setSearchText(searchHistory);
      }
      if (departmentHistory) {
        setDepartment(departmentHistory);
      }
      if (userGroupHistory) {
        setUserGroup(userGroupHistory);
      }
      if (startDateHistory) {
        setStartDate(startDateHistory);
      }
      if (endDateHistory) {
        setEndDate(endDateHistory);
      }
      setInitialLoad(false);
    }
  }, [initialLoad, recognitionHistory, setSearchText]);

  const infiniteQueryParams = {
    department,
    search: debouncedSearchText,
    userGroup,
    startDate,
    endDate,
  };

  useEffect(() => {
    if (recognitionId) {
      openModal();
    } else {
      closeModal();
    }
  }, [closeModal, openModal, recognitionId]);

  const handleCreateModalDisplay = (value: boolean): void => {
    setOpenCreateModal(value);
    history.push({ pathname: `${BASE_ROUTE}recognition/`, search: stringify(filters) });
  };

  const onCloseRecognitionDetailsModal = (): void => {
    history.push({ pathname: `${BASE_ROUTE}recognition/`, search: stringify(filters) });
  };

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const hookProps = {
    handleCreateModalDisplay,
    openCreateModal,
    searchText,
    handleSearchTextChange,
    infiniteQueryParams,
    setDepartment,
    department,
    userGroup,
    setUserGroup,
    setEditPost,
    editPost,
    filters,
    recognitionId,
    setStartDate,
    startDate,
    setEndDate,
    endDate,
    setSearchText,
    onCloseRecognitionDetailsModal,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default RecognitionLayout;
