import { css } from '@emotion/react';
import { CardProps as MaterialUICardProps } from '@mui/material';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import { useGetPersonById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { getUserId } from '~Common/utils/localStorage';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { convertTimestampToDateTime } from '~Deprecated/utils/meeting';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Coaching } from '~Meetings/const/meetingsInterfaces';
import LearningAvatar from '../LearningAvatar';

const styles = {
  headerContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  name: css({
    marginLeft: '1rem',
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    fontWeight: 500,
  }),
  bodyContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  meetingTitle: css({
    fontWeight: 600,
    marginTop: '0.75rem',
    fontSize: '1rem',
    color: palette.neutrals.gray800,
  }),
  startTime: css({
    color: palette.neutrals.gray600,
  }),
  selectButton: css({
    alignSelf: 'flex-end',
  }),
};

interface ViewProps{
  otherParticipant: TempOtherParticipant,
  meeting: Coaching,
  onAddToMeeting: () => void,
  startTime: string,
}

const View = ({
  otherParticipant,
  meeting,
  onAddToMeeting,
  startTime,
  ...props
}: ViewProps): JSX.Element => (
  <Card
    {...props}
    renderContents={() => (
      <div>
        <div css={styles.headerContainer}>
          <LearningAvatar userId={otherParticipant.userId} key={otherParticipant.userId} />
          <div css={styles.name}>{`${otherParticipant.firstName} ${otherParticipant.lastName}`}</div>
        </div>
        <div css={styles.bodyContainer}>
          <div>
            <div css={styles.meetingTitle}>{meeting.title}</div>
            <div css={styles.startTime}>{startTime}</div>
          </div>
          <LeadrButton
            onClick={onAddToMeeting}
            css={styles.selectButton}
            size="small"
            data-test-id="learningSelectMeetingForContent"
          >
            Select
          </LeadrButton>
        </div>
      </div>
    )}
  />
);

interface ContentTabProps extends MaterialUICardProps {
  meeting: Coaching,
  onAddToMeeting: () => void,
}

// TODO: Replace this with real types, when useNewPeople gets typed
interface TempOtherParticipant {
  userId: string,
  firstName: string,
  lastName: string,
}

const MeetingCard = ({ meeting, ...props }: ContentTabProps): JSX.Element => {
  const otherParticipant = useGetPersonById({ id: meeting?.attendees?.filter((attendee) => attendee.id !== getUserId())[0].id }) as TempOtherParticipant;

  const { timezone } = useTimezone();

  const hookProps = {
    meeting,
    otherParticipant,
    startTime: convertTimestampToDateTime(meeting.startTimeInMillis, timezone),
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default MeetingCard;
