import moment from 'moment';
import { css } from '@emotion/react';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { ViewPerspective } from '~Insights/const/types';
import { HookedExpanded as Engagement } from '~Insights/components/cards/EngagementCard/Expanded';
import { HookedExpanded as Enps } from '~Insights/components/cards/ENPSCard/Expanded';
import { useToggleMenuOptions } from '~Insights/hooks/useToggleMenuOptions';
import { useInsightsUserPerspective, UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { ENPSDataForDate, useENPSDataForDateRange } from '~Insights/hooks/useENPSData';
import { EngagementDataForDate, useEngagementDataForDateRange } from '~Insights/hooks/useEngagementData';
import { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { defualtMonthsToSubtract } from '~Insights/const/defaults';
import PrintWrapper from '~Common/V3/components/Print/PrintWrapper';

const styles = {
  body: css({
    marginTop: '1.5rem',
  }),
};

enum CardTypes {
  engagement = 'engagement',
  enps = 'enps',
}
interface ViewProps {
  expandedToggleMenuOptions: LeadrToggleTextTabProps[],
  toggleMenuValue: ViewPerspective,
  card: CardTypes,
  changeToggleMenu: (value: ViewPerspective) => void,
  userPerspective: UserPerspective,
  enpsData: ENPSDataForDate[],
  isLoadingEnps: boolean,
  engagementData: EngagementDataForDate[],
  isLoadingEngagement: boolean,
  title: string,
}

const View = ({
  expandedToggleMenuOptions,
  toggleMenuValue,
  card,
  enpsData,
  isLoadingEnps,
  engagementData,
  isLoadingEngagement,
  userPerspective,
  changeToggleMenu,
  title,
  ...props
}: ViewProps): JSX.Element => (
  <PrintWrapper {...props}>
    <PrintWrapper.BasePrintHeader
      title={title}
      printButtonDataTestId="printInsights"
    />
    <PrintWrapper.BasePrintBody css={styles.body}>
      {card === CardTypes.engagement && (
        <Engagement
          toggleMenuOptions={expandedToggleMenuOptions}
          toggleMenuValue={toggleMenuValue}
          changeToggleMenu={changeToggleMenu}
          isMobile
          isLoading={isLoadingEngagement}
          data={engagementData}
          userPerspective={userPerspective}
        />
      )}
      {card === CardTypes.enps && (
        <Enps
          toggleMenuOptions={expandedToggleMenuOptions}
          toggleMenuValue={toggleMenuValue}
          changeToggleMenu={changeToggleMenu}
          isMobile
          isLoading={isLoadingEnps}
          data={enpsData}
          userPerspective={userPerspective}
        />
      )}
    </PrintWrapper.BasePrintBody>
  </PrintWrapper>
);

const InsightsPrint = ({
  ...props
}): JSX.Element => {
  const [card] = useQueryParamState<CardTypes>('insights', 'setCard', CardTypes.engagement);
  const { expandedToggleMenuOptions, toggleMenuValue, setToggleMenuValue } = useToggleMenuOptions('perspective');
  const { userPerspective } = useInsightsUserPerspective();

  const { data: enpsData, isLoading: isLoadingEnps } = useENPSDataForDateRange({
    startDate: moment().subtract(defualtMonthsToSubtract, 'months').startOf('day').toDate(),
    endDate: moment().startOf('day').toDate(),
    userPerspective,
  });

  const { data: engagementData, isLoading: isLoadingEngagement } = useEngagementDataForDateRange({
    startDate: moment().subtract(defualtMonthsToSubtract, 'months').startOf('day').toDate(),
    endDate: moment().startOf('day').toDate(),
    userPerspective,
  });

  const title = card === CardTypes.engagement ? 'Insights - Engagement Graph' : 'Insights - eNPS Analysis Graph';

  const changeToggleMenu = (value: ViewPerspective): void => {
    if (value) {
      setToggleMenuValue(value);
    }
  };

  const hookProps = {
    expandedToggleMenuOptions,
    toggleMenuValue,
    card,
    userPerspective,
    enpsData,
    isLoadingEnps,
    engagementData,
    isLoadingEngagement,
    changeToggleMenu,
    title,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default InsightsPrint;
