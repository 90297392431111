import moment from 'moment-timezone';

export const dateRowRelativeFormatter = ({ value }) => (value ? moment(value).fromNow() : 'N/A');

export const dateRowPreciseFormatter = ({ value }) => (value ? moment(value).format('MMM Do, YYYY') : 'N/A');

export const dateRowComparator = (a, b) => {
  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  return a - b;
};
