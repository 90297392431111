import { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import MenuPanel from '../MenuPanel';
import './style.scss';
import Tooltip from '../Tooltip';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { useGetPeopleByList } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { CircularProgress } from '@mui/material';
import { palette } from '~Common/styles/colors';

const styles = {
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const userBackgroundColors = [
  '#FF595E',
  '#FFCA3A',
  '#8AC926',
  '#1982C4',
  '#6A4C93',
];

function stringToRGB(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return userBackgroundColors[Math.abs(hash % userBackgroundColors.length)];
}

const UserInfo = (props) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [showMenuPanel, setShowMenuPanel] = useState(false);
  const [userDataLoaded, setUserDataLoaded] = useState(true);
  const [clickedUser, setClickedUser] = useState({});
  const parentRef = useRef(null);
  const dispatch = useDispatch();

  // New meeting APIs only return orgUserIds for users so we need to pull the require user data since this component gets rendered under new UI currently
  const orgUserIds = useMemo(() => props.users?.filter((user) => user.orgUserId).map((user) => user.orgUserId), [props.users]);
  const peopleList = useGetPeopleByList({ selectedIds: orgUserIds });
  const usersList = useMemo(() => (peopleList.length ? peopleList : props.users), [peopleList, props.users]);

  const checkIfImageIsPresent = (user) => {
    let userLogoUrl = null;
    if (!user) {
      return null;
    }
    if (user.profileImageUrl) {
      if (Array.isArray(user.profileImageUrl) && user.profileImageUrl.length > 0) {
        userLogoUrl = user.profileImageUrl[0].imageUrl;
      } else if (typeof user.profileImageUrl === 'string' && user.profileImageUrl.length > 0) {
        userLogoUrl = user.profileImageUrl;
      }
    } else if (user.personalInformation) {
      if (Array.isArray(user.personalInformation) && user.personalInformation.length > 0) {
        if (user.personalInformation[0].profileImageUrl) {
          userLogoUrl = user.personalInformation[0].profileImageUrl;
        } else if (user.personalInformation[0].imageUrl) {
          userLogoUrl = user.personalInformation[0].imageUrl;
        }
      }
    }
    return userLogoUrl;
  };

  // logo style for small user logo with diff, color and initials
  const getUserLogoStyle = (user) => {
    const userLogoUrl = checkIfImageIsPresent(user);
    if (!userLogoUrl) {
      return usersList.length > 2 ? 'avatar avatar-border user-default-avatar' : 'avatar user-default-avatar';
    }
    return '';
  };

  // logo style for count
  const getUserLogoStyleForCount = () => (usersList.length > 2 ? 'avatar avatar-border user-default-avatar' : 'avatar user-default-avatar default');

  // get z-index for overlapping image
  const getZIndex = (index) => {
    if (index === 0) return usersList.length > 1 ? '3' : '0';
    if (index === 1) return '2';
    if (index === 2) return '1';
    return '0';
  };

  // if image url present
  const getUserImageUrlLogo = (user) => {
    const userLogoUrl = checkIfImageIsPresent(user);
    let userLogo = null;
    if (userLogoUrl) {
      userLogo = (
        <img
          src={userLogoUrl}
          onClick={() => {
            if (props.onClick) props.onClick();
          }}
          className={(!props.largeLogoModal ? usersList.length > 2 ? 'avatar avatar-border' : 'avatar' : 'large-avatar')}
          alt={user.imageAlt}
        />
      );
    }
    return userLogo;
  };
  // get user initials
  const getUserInitialsLogo = (user) => {
    const userLogoUrl = checkIfImageIsPresent(user);
    let userInitialsLogo = null;
    if (!userLogoUrl) {
      userInitialsLogo = (
        <span onClick={() => {
          if (props.onClick) props.onClick();
        }}
        >
          { [user.firstName, user.lastName].filter((e) => e).map((e) => e[0]).join('').toUpperCase() }
        </span>
      );
    }
    return userInitialsLogo;
  };
  // get logo for user count
  const getUserCountLogo = () => {
    const userCount = usersList.length - 2;
    const userLogo = <span>{ `+${userCount}` }</span>;
    return userLogo;
  };

  // click event for userlogo
  const onClickOfUserLogo = (e, index, user) => {
    if (props.hideMoreInfo) {
      return;
    }
    if (index === 2 && usersList.length > 3) {
      setShowMenuPanel(true);
    } else {
      dispatch(pushDrawerAction({
        drawer: {
          ...baseballCardDrawerTemplate,
          args: {
            id: user.id || user.uid || user.userId,
          },
        },
      }));
    }
  };

  const renderUserLogo = (userLogo, user) => {
    if (clickedUser.id && user.id && clickedUser.id === user.id && !userDataLoaded && props.hideMoreInfo) {
      return (
        <CircularProgress css={styles.circularProgress} />
      );
    }
    return userLogo;
  };

  const getImage = (user, index) => {
    let userLogo = null;
    let userLogoStyle = '';
    let isImage = false;
    if (!usersList) {

    } else {
      const userLogoUrl = checkIfImageIsPresent(user);
      // Users array length equal to or less than 3
      let userLogoStyleProp = '';
      if (usersList.length <= 3) {
        if (index < 3 && user) {
          if (userLogoUrl) {
            isImage = true;
            userLogo = getUserImageUrlLogo(user);
          } else {
            userLogo = getUserInitialsLogo(user);
            userLogoStyleProp = getUserLogoStyle(user);
          }
        }
      } else if (usersList.length > 3) {
        if (index < 2 && user) {
          if (userLogoUrl) {
            isImage = true;
            userLogo = getUserImageUrlLogo(user);
          } else {
            userLogo = getUserInitialsLogo(user);
            userLogoStyleProp = getUserLogoStyle(user);
          }
        } else if (index === 2) {
          // at index 2 show counts not user initials/or picture
          userLogo = getUserCountLogo();
          userLogoStyleProp = `default ${getUserLogoStyleForCount()}`;
        }
      }
      if (!props.largeLogoModal) {
        if (props.largeLogo) {
          userLogoStyle = 'user-large-logo';
        } else {
          userLogoStyle = userLogoStyleProp;
        }
      } else if (!userLogoUrl) {
        userLogoStyle = 'user-large-logo-modal';
      } else {
        userLogoStyle = '';
      }

      // Generate avatar colors based on first and last name.
      const rgb = !(user && userLogoUrl) && `${user ? stringToRGB([user.firstName, user.lastName].filter((e) => e).join('|')) : '#000'}`;
      let backgroundColor = (isImage || (index === 2 && usersList.length > 3)) ? '' : rgb;
      if (!userDataLoaded) {
        backgroundColor = stringToRGB([user.firstName, user.lastName].filter((e) => e).join('|'));
      }
      return (
        <div
          style={{
            zIndex: getZIndex(index),
            borderRadius: '8px',
            backgroundColor,
            ...(props.overrideStyle ? {
              margin: 'unset',
              border: 'unset',
              width: '32px',
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            } : {}),
          }}
          className={`${userLogoStyle} ${props.additionalClasses ? props.additionalClasses : ''} ${!userDataLoaded && userLogoStyle === '' ? 'avatar avatar-border' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickOfUserLogo(e, index, user);
          }}
        >
          { renderUserLogo(userLogo, user) }
        </div>
      );
    }
    return null;
  };

  const getUserName = (item) => {
    if (!item) return '';
    const { firstName, lastName } = item;
    if (firstName && lastName) {
      return `${item.firstName} ${item.lastName}`;
    }
    return '';
  };

  const getTooltip = (item, index) => (
    <Tooltip message={getUserName(item)} showTooltip={props.showTooltip && !showUserModal}>
      <div className="user-image mr-0">
        { getImage(item, index) }
      </div>
    </Tooltip>
  );
  const renderUserInfo = (item, index) => (
    <div className="assigned-to-details-list" key={`user-info_${index}`}>
      { showMenuPanel && index === 2
        ? (
          <MenuPanel
            parentRef={parentRef}
            showMenuPanel={showMenuPanel}
            setShowMenuPanel={setShowMenuPanel}
            users={usersList}
            isFloat
          />
        ) : null }
      { (usersList.length > 3 && index === 2) || props.hideTooltip ? (
        <div className="user-image mr-0">
          { getImage(item, index) }
        </div>
      ) : getTooltip(item, index) }
      { !props.largeLogoModal ? item && props.showName ? (
        <div className="user-name">
          <span
            className={item.showJobTitle && !item.jobTitle ? 'user-name-center' : 'job-title-center'}
            style={props.overrideStyle}
          >
            { item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : '' }
          </span>
          <span style={props.overrideJobTitleStyle}>{ item.showJobTitle ? `${item.jobTitle}` : '' }</span>
        </div>
      ) : null : null }
    </div>
  );
  const renderMultipleUsers = () => (
    <div className="user-details assigned-to-details">
      { usersList ? usersList.map((item, index) => (index < 2 ? renderUserInfo(item, index) : index === 2 ? renderUserInfo(item, index) : null)) : null }
    </div>
  );
  const renderSingleUser = () => (
    <div
      className="user-details assigned-to-details"
      css={{
        transform: `translateX(calc((-50% - 0.5rem) * ${props.index || 0}))`,
        zIndex: `calc(100 - ${props.index || 0})`,
        marginRight: '0.5rem',
        '.user-image': {
          marginRight: 'unset',
        },
        '.avatar': {
          border: 'unset !important',
        },
      }}
      style={props.overrideStyle ? { padding: '16px' } : {}}
    >
      { renderUserInfo(usersList[0], 0) }
    </div>
  );

  return usersList ? (usersList.length > 1 ? renderMultipleUsers() : renderSingleUser()) : null;
};

export default UserInfo;
