import { css } from '@emotion/react';
import { faCircleX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, ChipProps } from '@mui/material';

import { palette } from '~Common/styles/colors';

const styles = {
  chip: css({
    color: palette.neutrals.gray700,
    backgroundColor: palette.neutrals.gray300,
    borderRadius: '0.5rem',
    padding: '.25rem .75rem',
    fontWeight: 600,
    '.MuiChip-label': {
      padding: 0,
    },
  }),
  clearIcon: css({
    color: palette.neutrals.gray700,
    width: '.875rem',
    height: '.875rem',
    margin: '0 0 0 0.5rem !important', // MUI SUCKS
  }),
};

export interface AutocompleteChipProps extends ChipProps {
  value: string,
  shouldDisableChip?: (value: string) => boolean,
}

const AutocompleteChip = ({
  onDelete,
  value,
  shouldDisableChip,
  disabled,
  ...props
}: AutocompleteChipProps): JSX.Element => (
  <Chip
    css={styles.chip}
    deleteIcon={<FontAwesomeIcon css={styles.clearIcon} icon={faCircleX} />}
    clickable
    onClick={onDelete}
    onDelete={onDelete}
    disabled={shouldDisableChip?.(value) || disabled}
    {...props}
  />
);

export default AutocompleteChip;
