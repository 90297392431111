import { css } from '@emotion/react';

const styles = {
  iconContents: css({
    display: 'flex',
    alignItems: 'center',
  }),
  text: css({
    marginLeft: '.625rem',
  }),
};

interface IconContentsProps {
  renderIcon: () => JSX.Element,
  text: string,
}

const IconContents = ({
  renderIcon,
  text,
}: IconContentsProps): JSX.Element => (
  <div css={styles.iconContents}>
    {renderIcon()}
    <div css={styles.text}>{text}</div>
  </div>
);

export default IconContents;
