import { useGenericSearch } from '~Common/hooks/useGenericSearch';

export const useUserGroupSearch = () => {
  const searchConfig = {
    module: 'user-groups',
    workflow: 'table-search',
    defaultValue: '',
  };
  const { search, setSearch } = useGenericSearch(searchConfig);

  return {
    search, setSearch,
  };
};
