/* eslint-disable react/no-danger */
import { css } from '@emotion/react';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import moment from 'moment';
import { ComponentProps } from 'react';
import CompletedProgressBar from '~Common/V3/components/ProgressBar/CompletedProgressBar';
import {
  REVIEW_SETUP_LAYOUT, REVIEW_DISPLAY_STYLES, PAGE_STYLES,
} from '~Reviews/V2/Const/pageStyles';
import { ViewCyclePerspective, CycleTypeEnum, ReviewCycleStatusEnumConversion } from '~Reviews/V2/Const/types';
import ActionMenu, { MenuItem } from '~Meetings/components/shared/ActionMenu';
import Card from '~Common/V3/components/Card';
import ClampLines from '~Common/components/ClampLines';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...REVIEW_DISPLAY_STYLES,
  ...PAGE_STYLES,
  progressBarHeightSmall: css({
    flexGrow: '.25',
    minHeight: '1.5rem',
    marginTop: '1.5rem',
  }),
};

interface CycleHeaderProps extends ComponentProps<'div'> {
  cycleTitle: string,
  cycleDecriptionHTML: HTMLString,
  assesmentPeriodEnd: Date,
  assesmentPeriodStart: Date,
  reviewDueBy: Date,
  signOffDueBy: Date,
  type: number,
  viewCyclePerspective?: ViewCyclePerspective,
  totalCount?: number,
  completedCount?: number,
  completionPct?: number,
  onClickCallback?: () => void,
  doOpen?: (event: React.MouseEvent<HTMLElement>) => void,
  actionMenuProps?: {
    anchorEle: HTMLElement | null;
    onClose: () => void;
  },
  menuItems?:MenuItem[][],
  removeCardStyles?: boolean,
  cycleStatus?: keyof typeof ReviewCycleStatusEnumConversion,
  showProgressBar?: boolean,
  hasExportCSV?: boolean,
  hasDeleteCycle?: boolean,
}

const CycleHeader = ({
  cycleTitle,
  cycleDecriptionHTML,
  assesmentPeriodEnd,
  assesmentPeriodStart,
  reviewDueBy,
  signOffDueBy,
  type,
  viewCyclePerspective,
  totalCount,
  completedCount,
  completionPct,
  onClickCallback,
  doOpen,
  actionMenuProps,
  menuItems,
  removeCardStyles = false,
  cycleStatus,
  showProgressBar = true,
  hasExportCSV = false,
  hasDeleteCycle = false,
}: CycleHeaderProps): JSX.Element => (
  <>
    <Card
      css={styles.baseCard(removeCardStyles)}
      renderContents={() => (
        <>
          <div>
            {viewCyclePerspective === ViewCyclePerspective.Create_Questions && (
            <>
              <div
                css={styles.ReviewTitle}
              >
                {cycleTitle}
              </div>
              <ClampLines
                text={cycleDecriptionHTML}
                id="really-unique-id"
                lines={4}
                ellipsis="..."
                moreText="Read More"
                lessText="Show Less"
                emotionStyle={styles.reviewDescription}
              />
            </>
            )}
            {viewCyclePerspective === ViewCyclePerspective.Preview_Publish && (
            <>
              <div
                css={styles.ReviewTitle}
              >
                {cycleTitle}
              </div>
              {cycleDecriptionHTML && (
                <HTMLRenderer css={styles.reviewDescription} htmlText={cycleDecriptionHTML} />
              )}
              <div css={styles.cycleDetails(false)}>
                <div>
                  <span css={styles.cycleDetailsTitle}>Review Period</span>
                  <span>
                    {moment(assesmentPeriodStart).format('MMM Do')}
                    {' - '}
                    {moment(assesmentPeriodEnd).format('MMM Do YYYY')}
                  </span>
                </div>
                <div>
                  <span css={styles.cycleDetailsTitle}>Assessment Due Date</span>
                  <span>{moment(reviewDueBy).format('MMM Do YYYY')}</span>
                </div>
                <div>
                  <span css={styles.cycleDetailsTitle}>Review Sign-off Due Date</span>
                  <span>{moment(signOffDueBy).format('MMM Do YYYY')}</span>
                </div>
                <div>
                  <span css={styles.cycleDetailsTitle}>Cycle Type</span>
                  <span>{Object.keys(CycleTypeEnum)[Object.values(CycleTypeEnum).indexOf(type)]}</span>
                </div>
              </div>
            </>
            )}
            {viewCyclePerspective === ViewCyclePerspective.Publish && (
            <div
              css={styles.reviewDetailsWrap}
            >
              <div
                css={styles.reviewBaseDetails}
              >
                <div
                  css={styles.ReviewTitle}
                  data-test-id="reviewCycleTitle"
                >
                  {cycleTitle}
                  <>
                    <IconButton
                      onClick={doOpen}
                      data-test-id="reviewCycleActionMenu"
                      tooltip="Edit Cycle Options"
                      type="button"
                      icon={faEllipsisV}
                      css={styles.cycleEllipsis}
                      size="large"
                    />
                    {actionMenuProps && menuItems && (
                    <ActionMenu
                      css={styles.cycleActionMenu(hasExportCSV, hasDeleteCycle)}
                      menuItems={menuItems}
                      onClick={onClickCallback}
                      {...actionMenuProps}
                    />
                    )}
                  </>
                </div>
                <ClampLines
                  text={cycleDecriptionHTML}
                  id="really-unique-id"
                  lines={4}
                  ellipsis="..."
                  moreText="Read More"
                  lessText="Show Less"
                  emotionStyle={styles.reviewDescription}
                />
                {showProgressBar && (
                  <CompletedProgressBar
                    css={styles.progressBarHeightSmall}
                    totalCount={totalCount ?? 0}
                    completedCount={completedCount ?? 0}
                    completedPercentage={completionPct}
                    renderPercentText={() => (
                      <>{`${completionPct ?? 0}% Complete`}</>
                    )}
                  />
                )}
              </div>
              <div css={styles.cycleDetails(true)}>
                {cycleStatus && (
                  <div>
                    <span css={styles.cycleDetailsTitle}>Review Cycle Status</span>
                    <span>
                      {ReviewCycleStatusEnumConversion[cycleStatus]}
                    </span>
                  </div>
                )}
                <div>
                  <span css={styles.cycleDetailsTitle}>Review Period</span>
                  <span>
                    {moment(assesmentPeriodStart).format('MMM Do')}
                    {' - '}
                    {moment(assesmentPeriodEnd).format('MMM Do YYYY')}
                  </span>
                </div>
                <div>
                  <span css={styles.cycleDetailsTitle}>Assessment Due Date</span>
                  <span>{moment(reviewDueBy).format('MMM Do YYYY')}</span>
                </div>
                <div>
                  <span css={styles.cycleDetailsTitle}>Review Sign-off Due Date</span>
                  <span>{moment(signOffDueBy).format('MMM Do YYYY')}</span>
                </div>
                <div>
                  <span css={styles.cycleDetailsTitle}>Cycle Type</span>
                  <span>{Object.keys(CycleTypeEnum)[Object.values(CycleTypeEnum).indexOf(type)]}</span>
                </div>
              </div>
            </div>
            )}
          </div>
        </>
      )}
    />
  </>
);

export default CycleHeader;
