import { css } from '@emotion/react';
import LeadrCard, { LeadrCardSkeleton } from '~Common/V3/components/LeadrCard';

import { palette } from '~Common/styles/colors';

const styles = {
  meetingTitle: css({
    color: palette.neutrals.gray900,
    fontWeight: 600,
    fontSize: '1.25rem',
  }),
  date: css({
    fontSize: '1rem',
    color: palette.neutrals.gray600,
  }),
  meetingDetailsCardSkeleton: css({
    maxWidth: '100%',
    height: '6.5rem',
  }),
};

interface MeetingDetailsCardProps {
  title: string,
  date: string,
}

const MeetingDetailsCard = ({
  title,
  date,
  ...props
}: MeetingDetailsCardProps): JSX.Element => (
  <LeadrCard {...props}>
    <div css={styles.meetingTitle}>{title}</div>
    <LeadrCard.Footer>
      <span css={styles.date}>{date}</span>
    </LeadrCard.Footer>
  </LeadrCard>
);

const SkeletonView = ({
  ...props
}): JSX.Element => (
  <LeadrCardSkeleton
    css={styles.meetingDetailsCardSkeleton}
    {...props}
  />
);

export { SkeletonView as MeetingDetailsCardSkeleton };

export default MeetingDetailsCard;
