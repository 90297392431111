import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';
import { withTruncate } from '~Common/styles/mixins';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import QuestionPreview from './index';

const styles = {
  questionText: css`
    font-size: 1.125rem;
    font-weight: bold;
    ${withTruncate()};
  `,
};

const View = ({
  question, description, ...props
}) => (
  <QuestionPreview
    renderBody={() => (
      <div {...props}>
        <div css={styles.questionText}>{question}</div>
        {description && (
          <HTMLRenderer htmlText={description} />
        )}
      </div>
    )}
  />
);

View.propTypes = {
  question: PropTypes.string,
  description: PropTypes.string,
};

View.defaultProps = {
  question: '',
  description: '',
};

const FreeformPreview = ({ question, ...props }) => {
  const hookProps = {
    ...question,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

FreeformPreview.propTypes = {
  question: SURVEY_QUESTION_SHAPE,
};

FreeformPreview.defaultProps = {
  question: {},
};

export { View };
export default FreeformPreview;
