import { css } from '@emotion/react';
import { faMaximize } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hexToRGBA, palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';
import { DoExpand } from '~Insights/const/types';
import { useExpandingCards } from '~Insights/hooks/useExpandingCards';

const styles = {
  root: css({
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    borderRadius: '2px',
    color: palette.neutrals.gray500,
    fontSize: '1rem',
    lineHeight: '1rem',
    padding: '0.3rem',
  }),
};

interface ViewProps {
  doExpand: DoExpand
  cardKey: string
}

const View = ({
  doExpand,
  cardKey,
  ...props
}: ViewProps): JSX.Element => (
  <Button
    css={styles.root}
    {...props}
    variant="text"
    onClick={() => doExpand(cardKey)}
    renderContents={() => (
      <FontAwesomeIcon icon={faMaximize} />
    )}
  />
);

interface ExpandButtonProps {
  cardKey: string
}

const ExpandButton = ({
  cardKey,
  ...props
}: ExpandButtonProps): JSX.Element => {
  const { doExpand } = useExpandingCards();
  const hookProps = {
    doExpand,
    cardKey,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ExpandButton;
