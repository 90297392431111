import Tooltip from '~Common/components/Tooltip';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';

interface AvatarWithTooltipProps {
  profileImageUrl: string | undefined,
  firstName: string,
  lastName: string,
  width?: number,
  height?: number,
  isDeactivated?: boolean,
  email?: string,
}

/*
  This is not directly included in the AvatarMaybeMultipleMaybeNot component because:
  If you split it out like this and pass it into the renderOverflowAvatar,
  then you get direct access to passing css into there without having to pass prob objects to multiple things in the AvatarMaybeMultipleMaybeNot component
*/
const AvatarWithTooltip = ({
  profileImageUrl,
  firstName,
  lastName,
  isDeactivated,
  ...props
}: AvatarWithTooltipProps): JSX.Element => (
  <Tooltip
    content={`${firstName} ${lastName}`}
  >
    {/* This span tag is helpful for forwarding the Tippy ref to a custom component */}
    <span>
      <SquareAvatar
        imgUrl={profileImageUrl}
        name={`${firstName} ${lastName}`}
        width={40}
        height={40}
        isDeactivated={isDeactivated}
        {...props}
      />
    </span>
  </Tooltip>
);

export default AvatarWithTooltip;
