import { createAction } from '@reduxjs/toolkit';

import { mutateState } from '~Deprecated/utils/reduxUtils';
import { registerAction } from '~Deprecated/reducers/drawers';
import { DRAWER_STATE } from '~Common/const/drawers';

const popDrawerAction = createAction('LEADR.POP_DRAWER');

function onPopDrawer(state, { payload }) {
  return mutateState(state, (draftState) => {
    const { popAll, drawerName } = payload;

    if (popAll) {
      draftState.order.forEach((drawer) => {
        /* eslint-disable-next-line */
        drawer.state = DRAWER_STATE.CLOSED;
      });
    } else if (drawerName) {
      const drawerIndex = draftState.order.findIndex((drawer) => drawer.name === drawerName);
      if (drawerIndex >= 0) {
        for (let i = drawerIndex; i < draftState.order.length; i += 1) {
          draftState.order[i].state = DRAWER_STATE.CLOSED;
        }
      }
    } else {
      draftState.order[draftState.order.length - 1].state = DRAWER_STATE.CLOSED;
    }

    return draftState;
  });
}

registerAction(popDrawerAction, onPopDrawer);

export {
  popDrawerAction,
};
