import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import Drawer from '~Deprecated/ui/components//Drawer/Drawer';
import InsightDetailsSummary from '~Deprecated/ui/components/Insights/InsightDetails/InsightDetailSummary';
import HeaderAndListDrawer from '~Deprecated/ui/components/Drawer/DrawerBodies/HeaderAndListDrawer';
import {
  DEFAULT_METRIC_REPORT, DEFAULT_MAX_SUMMARY_COUNT, METRIC_SUMMARY_REPORT_SHAPE,
} from '~Common/const/executiveInsights';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import OrganizationDetailMembersContainer from '~Deprecated/ui/containers/Nexus/OrganizationDetailMembers';
import OrganizationDetailMembers from './OrganizationDetailMembers';

const styles = {
  summary: css`
    margin-left: 25px;

    & svg {
      bottom: 3px !important;
    }
  `,
};

const WiredOrganizationDetailMembers = OrganizationDetailMembersContainer(OrganizationDetailMembers);

const OrganizationFormDrawer = ({
  isOpen, onClose, onView, header, actionText, summary, total, id,
}) => {
  const { isCustomerSuccessAdmin: canView } = useOrgUserPermissionsContext();

  const doAction = () => {
    onView({
      id,
      orgName: header,
    });
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      css={css`
      & .MuiDrawer-paper {
        width: 360px;
        padding: 16px;
      }
    `}
      renderBody={(drawerBodyProps) => (
        <HeaderAndListDrawer
          {...drawerBodyProps}
          header={header}
          actionText={actionText}
          onAction={canView ? doAction : null}
          renderHeader={() => (
            <InsightDetailsSummary
              summary={summary}
              total={total}
              css={styles.summary}
            />
          )}
          renderList={() => (
            <WiredOrganizationDetailMembers
              id={id}
            />
          )}
        />
      )}
    />
  );
};

OrganizationFormDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  onView: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  header: PropTypes.string,
  actionText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  summary: METRIC_SUMMARY_REPORT_SHAPE,
  total: PropTypes.number,
};

OrganizationFormDrawer.defaultProps = {
  isOpen: false,
  header: 'MISSING HEADER',
  actionText: 'MISSING ACTION TEXT',
  summary: DEFAULT_METRIC_REPORT,
  total: DEFAULT_MAX_SUMMARY_COUNT,
};

export default OrganizationFormDrawer;
