import { css } from '@emotion/react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment, { MomentInput } from 'moment';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import LearningCompletedCount from '~Learning/components/Shared/LearningDashboardCards/LearningCompletedCount';
import LearningSectionsCard from '~Learning/components/Shared/LearningSectionsCard';
import TitleAndIntroduction from '~Learning/components/Shared/TitleAndIntroduction';
import { LearningAssignee, LearningStatus, LearningType } from '~Learning/const/interfaces';
import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';
import { useUpdateSingleLearningDueDate } from '~Learning/hooks/assigned/useUpdateSingleLearningDueDate';
import LearningDueDatePicker from '~Learning/components/Shared/LearningDueDatePicker';
import { learningParticipantsDrawerTemplate } from '../../LearningParticipantsDrawer';
import EditDueDateButton from './EditDueDateButton/EditDueDateButton';

const styles = {
  requestedLearningTabWrapper: css({}),
  questionCard: css({
    marginTop: '1.125rem',
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  completedLearningsText: css({
    color: palette.neutrals.gray500,
  }),
  buttonsContainer: css({
    marginTop: '1.5rem',
    display: 'flex',
  }),
  dueDateContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  editDueDateButton: css({
    marginLeft: '.25rem',
  }),
  formButtonsContainer: css({
    marginTop: '1.5rem',
    display: 'flex',
    gap: '0.5rem',
  }),
  learningDueDatePicker: css({}),
};

interface ViewProps {
  title: string,
  introduction: string,
  dueDate: string | undefined,
  numberOfCompletedLearnings: number,
  totalLearnings: number,
  onViewParticipantsClick: () => void,
  onCloseParticipantsClick: () => void,
  renderDrawerContents?: () => JSX.Element,
  renderBottomButtons?: () => JSX.Element,
  status: LearningStatus,
  showViewParticipantsButton: boolean,
  isEditing: boolean,
  setIsEditing: (isEditing: boolean) => void,
  onEditDate: (event: React.FormEvent<HTMLFormElement>) => void,
}

const View = ({
  title,
  introduction,
  dueDate,
  numberOfCompletedLearnings,
  totalLearnings,
  onViewParticipantsClick,
  onCloseParticipantsClick,
  renderDrawerContents,
  renderBottomButtons,
  status,
  showViewParticipantsButton,
  isEditing,
  setIsEditing,
  onEditDate,
}: ViewProps): JSX.Element => (
  <div css={styles.requestedLearningTabWrapper}>
    <LearningSectionsCard
      data-test-id="learningDetailsCard"
      renderHeader={() => (
        <LearningTypeInfo
          learningType={LearningType.SINGLE_LEARNING}
        />
      )}
      renderBody={() => (
        <TitleAndIntroduction
          title={title}
          introductionHTML={introduction}
        />
      )}
      renderFooter={() => (
        <>
          {isEditing && (
            <form onSubmit={onEditDate}>
              <LearningDueDatePicker
                css={styles.learningDueDatePicker}
                initialDate={dueDate}
                name="dueDate"
              />
              <div css={styles.formButtonsContainer}>
                <LeadrButton
                  type="submit"
                  data-test-id="learningEditDueDateSave"
                >
                  Save
                </LeadrButton>
                <LeadrButton
                  variant="ghost"
                  onClick={() => setIsEditing(false)}
                  data-test-id="learningEditDueDateCancel"
                >
                  Cancel
                </LeadrButton>
              </div>
            </form>
          )}
          {!isEditing && (
            <div css={styles.footer}>
              <div css={styles.dueDateContainer}>
                <DueDateFromNow dueDate={dueDate} isComplete={status === LearningStatus.COMPLETED} />
                {status !== LearningStatus.COMPLETED && (
                  <EditDueDateButton
                    css={styles.editDueDateButton}
                    onEdit={() => setIsEditing(true)}
                  />
                )}
              </div>
              <LearningCompletedCount
                css={styles.completedLearningsText}
                numberOfUsersCompleted={numberOfCompletedLearnings}
                numberOfUsersAssigned={totalLearnings}
              />
            </div>
          )}
        </>
      )}
    />
    {renderDrawerContents?.()}
    <div css={styles.buttonsContainer}>
      {showViewParticipantsButton && (
        <LeadrButton
          onClick={onViewParticipantsClick}
          data-test-id="learningViewParticipants"
        >
          Open Participants
        </LeadrButton>
      )}
      {!showViewParticipantsButton && (
        <LeadrButton
          onClick={onCloseParticipantsClick}
          data-test-id="learningCloseParticipants"
        >
          Close Participants
        </LeadrButton>
      )}
      {renderBottomButtons?.()}
    </div>
  </div>
);

interface PersonTabProps extends Omit<
  ViewProps,
  'isEditing' | 'setIsEditing' | 'numberOfCompletedLearnings' | 'totalLearnings' | 'onViewParticipantsClick' | 'onCloseParticipantsClick' | 'onEditDate'
> {
  assignees: LearningAssignee[],
  learningId: string,
}

const RequestedLearningTabWrapper = ({
  assignees, learningId, ...props
}: PersonTabProps): JSX.Element => {
  const dispatch = useDispatch();

  const updateSingleLearningDueDateMutation = useUpdateSingleLearningDueDate();

  const [isEditing, setIsEditing] = useState(false);

  const numberOfCompletedLearnings = assignees.filter((assignee) => assignee.status === LearningStatus.COMPLETED).length;

  const hookProps = {
    isEditing,
    setIsEditing,
    numberOfCompletedLearnings,
    totalLearnings: assignees.length,
    onViewParticipantsClick: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...learningParticipantsDrawerTemplate,
          args: {
            assignees,
            learningId,
          },
        },
      }));
    },
    onCloseParticipantsClick: () => {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ drawerName: learningParticipantsDrawerTemplate.name }));
    },
    onEditDate: (event: React.FormEvent<HTMLFormElement>): void => {
      setIsEditing(false);
      event.preventDefault();

      const formData = new FormData(event.currentTarget);
      const dueDate = moment(formData.get('dueDate') as unknown as MomentInput).toISOString();
      updateSingleLearningDueDateMutation({
        learningId,
        dueDate,
      });
    },
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default RequestedLearningTabWrapper;
