export const normalizeFeedbackTemplateFromServer = ({ data }) => {
  const normalizedTemplate = { ...data };

  if (normalizedTemplate.owner) {
    normalizedTemplate.owner = {
      name: normalizedTemplate.owner.name ?? `${normalizedTemplate.owner.firstName} ${normalizedTemplate.owner.lastName}`,
      id: normalizedTemplate.owner.id ?? null,
    };
  }

  normalizedTemplate.question = normalizedTemplate?.question?.map((question, index) => ({ ...question, rank: index }));

  return normalizedTemplate;
};
