import { css } from '@emotion/react';
import { SyntheticEvent, useEffect, useMemo } from 'react';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPencil,
  faUser,
  faCalendarAlt,
  faEllipsisVertical,
} from '@fortawesome/pro-regular-svg-icons';
import DeleteConfirmationPopover, { useDeleteConfirmationPopover } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { createEditActionItemTemplate } from '~ActionItems/components/Drawers/CreateEditActionItemDrawer';
import Tooltip from '~Common/components/Tooltip';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import { withButtonStripper } from '~Common/styles/mixins';
import { useActionMenu } from '~Meetings/components/shared/ActionMenu';
import ActionMenu from '~ActionItems/components/Shared/ActionMenu';
import { useDeleteActionItem } from '~ActionItems/hooks/useDeleteActionItem';
import { ActionItemContextType } from '~ActionItems/const/interfaces';
import { MeetingFactoryType, MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { Person } from '~Common/const/interfaces';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';

const styles = {
  controlBody: css`
    display: flex;
    padding: 1em;
    border-radius: 5px;
    background: ${palette.neutrals.white};
    flex-flow: wrap row;
    align-items: center;
    justify-content: space-evenly;
    width: auto;
    position: absolute;
    margin-left: auto;
    top: 2px;
    right: 2px;
    z-index: 500;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);
  `,
  controlIcon: (disabled: boolean) => css({
    color: !disabled ? `${palette.brand.indigo} !important` : `${palette.neutrals.gray500} !important`,
    margin: '0 5px',
    border: '0',
    background: 'transparent',
    padding: '0',
  }),
  button: css`
    ${withButtonStripper()}
    background: unset;
    border: 0;
    border-radius: 3px;
    color: ${palette.brand.indigo} !important;
    padding: 0;
    flex: 0;
  `,
};

interface HoverControlsProps {
  actionItemId: string,
  meetingId: string,
  meetingType: MeetingTypeEnum,
  setShowControls: (isOpen: boolean) => void,
  canDelete: boolean,
  canEdit: boolean,
  isComplete?: boolean,
}

const HoverControls = ({
  actionItemId,
  meetingId,
  meetingType,
  setShowControls,
  canDelete,
  canEdit,
  isComplete,
}: HoverControlsProps): JSX.Element => {
  const dispatch = useDispatch();
  const { peopleData } = useNewPeople({}) as { peopleData: Record<string, Person>};
  const { item: meeting } = useMeetingDetails({ id: meetingId, type: meetingType });
  const doDeleteActionItem = useDeleteActionItem();
  const {
    doOpen, close, anchorEle, ...actionMenuProps
  } = useActionMenu();
  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('actionMenu');
  const { isLimitedAccess: hasLimitedAccess } = useUserPermissions();
  useEffect(() => {
    setShowControls(!!anchorEle);
  }, [anchorEle, setShowControls]);

  const attendees = useMemo(() => {
    let newAttendees: string[] = [];

    if (meeting && peopleData) {
      const meetingAttendees: string[] = [...meeting.attendeeOrgUserIds, meeting.organizer.orgUserId];

      newAttendees = meetingAttendees.map((orgUserId) => peopleData[orgUserId]?.userId);
    }

    return newAttendees;
  }, [meeting, peopleData]);

  const onEditActionItem = ():void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
        args: {
          id: actionItemId,
          context: {
            type: ActionItemContextType.Meeting,
            id: meetingId,
          },
          validAssignees: meeting?.factoryType === MeetingFactoryType.COACHING ? attendees : undefined,
        },
      },
    }));
    setShowControls(false);
    close();
  };

  const onReassignActionItem = ():void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
        args: {
          id: actionItemId,
          fromActionMenu: true,
          context: {
            type: ActionItemContextType.Meeting,
            id: meetingId,
          },
          validAssignees: meeting?.factoryType === MeetingFactoryType.COACHING ? attendees : undefined,
        },
      },
    }));

    setShowControls(false);
    close();
  };

  const onDeleteActionItem = (): void => {
    doDeleteActionItem({ id: actionItemId });
    setShowControls(false);
    close();
  };

  const confirmDelete = (event: SyntheticEvent<HTMLElement>):void => {
    openConfirmationPopover(event);
  };

  const onEditClick = ():void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
        args: {
          id: actionItemId,
          context: {
            type: ActionItemContextType.Meeting,
            id: meetingId,
          },
          validAssignees: meeting?.factoryType === MeetingFactoryType.COACHING ? attendees : undefined,
        },
      },
    }));

    setShowControls(false);
    close();
  };

  const menuOptions = [
    {
      icon: faPencil,
      onClick: onEditActionItem,
      text: 'Edit Action Item',
      // Leaving edit enabled even when canEdit is false to allow comments from
      // those users. ToDo: Disable this once we have a dedicated add comment button
    },
    {
      icon: faTrash,
      onClick: confirmDelete,
      text: 'Delete',
      disabled: !canDelete,
    },
    {
      icon: faUser,
      onClick: onReassignActionItem,
      text: 'Re-Assign',
      disabled: !canEdit || isComplete || hasLimitedAccess,
    },
    {
      icon: faCalendarAlt,
      onClick: onEditActionItem,
      text: 'Change Due Date',
      disabled: !canEdit || isComplete || hasLimitedAccess,
    },
  ];
  return (
    <div css={styles.controlBody}>
      <Tooltip content="Edit this action item">
        <button css={styles.controlIcon(false)} onClick={onEditClick}>
          <FontAwesomeIcon
            icon={faPencil}
          />
        </button>
      </Tooltip>
      <DeleteButtonWithConfirmation
        renderDeleteButton={({ onClick }) => (
          <Tooltip content="Delete this action item">
            <button css={styles.controlIcon(!canDelete)} onClick={onClick} disabled={!canDelete}>
              <FontAwesomeIcon
                icon={faTrash}
              />
            </button>
          </Tooltip>
        )}
        renderConfirmationButtons={({
          informationStyles,
          optionStyles,
          popoverButtonStyles,
        }) => (
          <DeleteConfirmationButtons
            onDelete={onDeleteActionItem}
            informationStyles={informationStyles}
            optionStyles={optionStyles}
            popoverButtonStyles={popoverButtonStyles}
          />
        )}
      />
      <button
        onClick={doOpen}
        css={styles.button}
      >
        <FontAwesomeIcon
          fixedWidth
          size="sm"
          icon={faEllipsisVertical}
        />
      </button>
      <DeleteConfirmationPopover
        closeConfirmationPopover={closeConfirmationPopover}
        open={isOpen}
        popoverId={popoverId}
        anchorEl={anchorEl}
        renderConfirmationButtons={({
          informationStyles,
          optionStyles,
          popoverButtonStyles,
        }) => (
          <DeleteConfirmationButtons
            onDelete={onDeleteActionItem}
            informationStyles={informationStyles}
            optionStyles={optionStyles}
            popoverButtonStyles={popoverButtonStyles}
          />
        )}
      />
      <ActionMenu
        id="actionMenu"
        menuItems={menuOptions}
        anchorEle={anchorEle}
        {...actionMenuProps}
      />
    </div>
  );
};

export default HoverControls;
