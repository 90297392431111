import { css } from '@emotion/react';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';

import { withLineClamp } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';

const style = {
  container: css`
    display: flex;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 16px;
  `,
  content: css`
    display: flex;
    flex-direction: column;
  `,
  name: css`
    ${withLineClamp(1)}
    word-break: break-all;
    color: ${palette.neutrals.gray800};
    font-size: 1.125rem;
    font-weight: 600;
  `,
};

const NotesDrawerHeader = ({ user }) => (
  <div css={style.container}>
    <div css={style.content}>
      <div css={style.name}>{ `Private Notes for ${user.firstName}` }</div>
    </div>
  </div>
);

NotesDrawerHeader.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
};

NotesDrawerHeader.defaultProps = {};

export default NotesDrawerHeader;
