import { css } from '@emotion/react';
import { ComponentProps, Fragment } from 'react';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { NonLeadrUser } from '~Common/const/classes';
import { OverflowAvatarProps } from './OverflowAvatar';

const styles = {
  avatarSection: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

export function isNonLeadrUser(obj: PersonDisplayInformation | NonLeadrUser): obj is NonLeadrUser {
  // @ts-expect-error This error is expected since we are using this function to narrow down the type
  return obj?.isNonLeadrUser !== undefined;
}

interface RenderAvatarProps<UserType> {
  user: UserType,
  avatarHeight: number,
  avatarWidth: number,
}

interface ViewProps<UserType> extends ComponentProps<'div'>{
  usersToShow: (UserType)[],
  numberOfUsers: number,
  numberOfUsersToShow: number,
  avatarHeight?: number,
  avatarWidth?: number,
  renderAvatar: (avatarProps: RenderAvatarProps<UserType>) => JSX.Element,
  renderOverflowAvatar: (overflowAvatarProps: OverflowAvatarProps) => JSX.Element,
}

const View = <UserType extends PersonDisplayInformation | NonLeadrUser>({
  usersToShow,
  numberOfUsers,
  numberOfUsersToShow,
  avatarHeight = 40,
  avatarWidth = 40,
  renderAvatar,
  renderOverflowAvatar,
  ...props
}: ViewProps<UserType>): JSX.Element => (
  <div css={styles.avatarSection} {...props}>
    {usersToShow.map((user) => (
      <>
        {isNonLeadrUser(user) && (
          <Fragment key={user.email}>
            {renderAvatar({
              user,
              avatarHeight,
              avatarWidth,
            })}
          </Fragment>
        )}
        {!isNonLeadrUser(user) && (
          <Fragment key={user.id || user.userId || user.orgUserId || user.uid}>
            {renderAvatar({
              user,
              avatarHeight,
              avatarWidth,
            })}
          </Fragment>
        )}
      </>
    ))}
    {numberOfUsers > numberOfUsersToShow && (
      <>
        {renderOverflowAvatar({
          overflowNumber: (numberOfUsers + 1) - numberOfUsersToShow, // Adding 1 since the overflow card will take the spot that the last person would have had
          avatarHeight,
          avatarWidth,
        })}
      </>
    )}
  </div>
  );

interface AvatarMaybeMultipleMaybeNotProps<UserType> extends Omit<ViewProps<UserType>, 'usersToShow'> {
  usersInfo: UserType[],
}

const AvatarMaybeMultipleMaybeNot = <UserType extends PersonDisplayInformation | NonLeadrUser>({
  usersInfo,
  numberOfUsers,
  numberOfUsersToShow,
  ...props
}: AvatarMaybeMultipleMaybeNotProps<UserType>): JSX.Element => {
  const usersToShow = numberOfUsers <= numberOfUsersToShow
    ? [...usersInfo] : [...usersInfo.slice(0, numberOfUsersToShow - 1)];

  const hookProps = {
    usersToShow,
    numberOfUsers,
    numberOfUsersToShow,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default AvatarMaybeMultipleMaybeNot;
