import { useQuery } from '@tanstack/react-query';
import { getUserId } from '~Common/utils/localStorage';
import { HttpCallReturn, getApi } from '~Deprecated/services/HttpService';

export interface UserOrganizationsInformation {
  city: string,
  isDisabled: boolean,
  organizationId: string,
  organizationName: string,
  orgCreatedInMillis: string,
  state: string,
}

export function getUserOrganizations(userId: string): Promise<HttpCallReturn<UserOrganizationsInformation[]>> {
  const serverUrl = `/users/${userId}/organizations`;
  return getApi(serverUrl);
}

interface UseGetUserOrganizationListReturn {
  organizations: UserOrganizationsInformation[],
  isLoading: boolean,
}

export function useGetUserOrganizationList(): UseGetUserOrganizationListReturn {
  const userId = getUserId();

  const result = useQuery({
    queryKey: ['user', userId, 'organizations'],
    queryFn: // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getUserOrganizations(userId!),
    enabled: !!userId,
    staleTime: Infinity,
  });

  const filteredOrganizations = result?.data?.response?.filter((org) => !org.isDisabled) ?? [];

  return {
    organizations: filteredOrganizations,
    isLoading: result?.isLoading,
  };
}
