import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { faGrid2Plus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import ModuleControl from '~Home/components/Drawers/HomeModulesDrawer/ModuleControl';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { palette } from '~Common/styles/colors';
import { useHomeSettings } from '~Home/hooks/useHomeSettings';
import { HomeModule } from '~Home/const';
import {
  filterHomeModules,
  getHiddenModuleList,
  getVisibleModuleList,
} from '~Home/functions/utils';

const HIDABLE_MODULES = [
  HomeModule.Celebrate,
  HomeModule.Goals,
  HomeModule.Insights,
  HomeModule.Meetings,
  HomeModule.OrgClarity,
  HomeModule.People,
  HomeModule.Recognition,
  HomeModule.TeamClarity,
  HomeModule.WhatsDue,
];

const styles = {
  drawerBody: css({
    color: palette.neutrals.gray700,
    padding: '1.25rem 1rem 1.5rem 1rem',
  }),
  closeButton: css({
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: palette.neutrals.gray700,
  }),
  heading: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    marginBottom: '0.5rem',
  }),
  container: css({
    padding: '1.25rem',
  }),
  primaryLabel: css({
    marginBottom: '1.25rem',
    color: palette.neutrals.gray800,
  }),
  description: css({
    marginBottom: '1.25rem',
    color: palette.neutrals.gray700,
  }),
  sectionLabel: css({
    color: palette.neutrals.gray700,
  }),
  moduleContainer: css({
    marginTop: '0.625rem',
  }),
  emptyState: css({
    marginTop: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  emptyStateIcon: css({
    color: palette.neutrals.gray600,
    fontSize: '1.125rem',
    marginRight: '0.5rem',
  }),
  divider: css({
    margin: '1.5rem 0',
  }),
};

const homeModulesDrawerTemplate = {
  name: 'HOME_MODULES_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

interface ViewProps {
  closeDrawerClick: () => void,
  activeModules: HomeModule[],
  inactiveModules: HomeModule[],
}

const View = ({
  closeDrawerClick,
  activeModules,
  inactiveModules,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={() => (
          <button onClick={closeDrawerClick} css={styles.closeButton} title="Close drawer">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        title="Customize Leadr Home"
      />
    )}
    renderBody={() => (
      <div css={styles.container}>
        <h3 css={styles.primaryLabel}>
          Widgets
        </h3>

        <p css={styles.description}>
          Click to add / remove the widgets below on your Home screen.
        </p>

        <div>
          <h4 css={styles.sectionLabel}>
            Active
          </h4>

          { !activeModules.length && (
            <div css={styles.emptyState}>
              <FontAwesomeIcon
                css={styles.emptyStateIcon}
                icon={faGrid2Plus}
              />

              <span>
                You have no active widgets
              </span>
            </div>
          )}

          { !!activeModules.length && activeModules.map((module) => (
            <div
              css={styles.moduleContainer}
              key={module}
            >
              <ModuleControl
                type={module}
              />
            </div>
          ))}
        </div>

        <hr css={styles.divider} />

        <div>
          <h4 css={styles.sectionLabel}>
            Inactive
          </h4>

          { !inactiveModules.length && (
            <div css={styles.emptyState}>
              <FontAwesomeIcon
                css={styles.emptyStateIcon}
                icon={faGrid2Plus}
              />

              <span>
                You have no inactive widgets
              </span>
            </div>
          )}

          { inactiveModules.map((module) => (
            <div
              css={styles.moduleContainer}
              key={module}
            >
              <ModuleControl
                type={module}
              />
            </div>
          ))}
        </div>
      </div>
    )}
  />
);

const HomeModulesDrawer = ({ popDrawer }: DrawerProps<unknown>): JSX.Element => {
  const { data } = useHomeSettings();

  const activeModules = useMemo(() => {
    if (data?.response) {
      const homeModuleSettings = filterHomeModules(data.response);

      const visibleModuleList = getVisibleModuleList(homeModuleSettings);

      return visibleModuleList.filter((module) => (
        HIDABLE_MODULES.includes(module)
      ));
    }

    return [];
  }, [data?.response]);

  const inactiveModules = useMemo(() => {
    if (data?.response) {
      const homeModuleSettings = filterHomeModules(data.response);

      const hiddenModuleList = getHiddenModuleList(homeModuleSettings);

      return hiddenModuleList.filter((module) => (
        HIDABLE_MODULES.includes(module)
      ));
    }

    return [];
  }, [data?.response]);

  const closeDrawerClick = useCallback(() => {
    popDrawer({ popAll: true });
  }, [popDrawer]);

  const hookProps = {
    closeDrawerClick,
    activeModules,
    inactiveModules,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: homeModulesDrawerTemplate.name,
  component: HomeModulesDrawer,
});

export { View, homeModulesDrawerTemplate };
export default HomeModulesDrawer;
