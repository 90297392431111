import {
  DataGrid, GridSelectionModel, GridColumns, GridFilterModel, GridEventListener, GridValidRowModel, GridSlotsComponent,
} from '@mui/x-data-grid';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { Skeleton } from '@mui/material';

const tableHighlight = '#D9E0EF';

const styles = {
  skelly: css({
    marginBottom: '2.5rem',
  }),
  table: (checkboxSelection: boolean, hasData: boolean) => css({
    border: 'none',
    fontSize: '0.8125rem',
    marginTop: '1rem',
    marginBottom: '2.5rem',

    '& .MuiDataGrid-columnSeparator': {
      opacity: '0 !important',
    },
    '& .MuiCheckbox-root svg': {
      color: palette.neutrals.gray400,
    },
    '& .MuiCheckbox-root svg[data-testid="CheckBoxIcon"], & .MuiCheckbox-root svg[data-testid="IndeterminateCheckBoxIcon"]': {
      color: palette.brand.indigo,
    },
    '& .MuiDataGrid-columnHeaders': {
      background: palette.neutrals.gray100,
      lineHeight: 'unset !important',
      maxHeight: 'unset !important',
      minHeight: 'unset !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '3rem !important',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '600',
    },
    '& .MuiDataGrid-row': {
      background: palette.neutrals.white,
    },
    '& .MuiDataGrid-row:hover': {
      background: `${palette.neutrals.gray100}!important`,
    },
    '& .MuiDataGrid-row:nth-child(even)': {
      background: palette.neutrals.gray50,
    },
    '& .MuiTablePagination-selectLabel': {
      display: 'none !important',
    },
    '& .MuiInputBase-root-MuiTablePagination-select': {
      marginRight: '.625rem',
    },
    '& .MuiTablePagination-select': {
      display: 'none',
    },
    '.MuiDataGrid-row.Mui-selected': {
      backgroundColor: tableHighlight,
    },
    '& .MuiTablePagination-displayedRows': {
      color: palette.neutrals.gray500,
    },
    '& .MuiTablePagination-selectIcon': {
      display: 'none',
    },
    '& .MuiIconButton-root': {
      border: `1px solid ${palette.neutrals.gray400}`,
      padding: '.25rem',
    },
    '& .MuiIconButton-root:first-of-type': {
      borderRadius: '.5rem 0 0 .5rem',
    },
    '& .MuiIconButton-root:last-child': {
      borderRadius: '0 .5rem .5rem 0',
      marginLeft: '-1px',
    },
    '& .MuiDataGrid-menuIconButton, & .MuiIconButton-sizeSmall': {
      border: 'none !important',
    },
    '.MuiDataGrid-cell': {
      border: 'none',
    },
    '.MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within': {
      outline: 'none !important',
      outlineColor: 'transparent',

    },
    '.MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
  }, !checkboxSelection && {
    '& .MuiDataGrid-columnHeadersInner': {
      minHeight: '2.125rem',
    },
  }, !hasData && {
    '& .MuiDataGrid-virtualScrollerContent': {
      height: '400px !important',
    },
  }),
  container: css({

  }),
};

interface ViewProps<T extends GridValidRowModel> {
  isLoading: boolean,
  rows: Array<T>,
  columns: GridColumns<T>,
  selectionModel?: GridSelectionModel,
  setSelectionModel: (rowIds: GridSelectionModel) => void,
  filterModel?: GridFilterModel,
  setFilterModel?: (items: GridFilterModel) => void,
  pageSize: number,
  handleCellClickEvent: GridEventListener<'cellClick'>,
  rowHeight: number,
  components?: GridSlotsComponent | undefined,
  checkboxSelection: boolean,
  hideFooter: boolean,
  hasData: boolean,
}

function View<T extends GridValidRowModel>({
  isLoading,
  rows,
  columns,
  selectionModel,
  setSelectionModel,
  pageSize,
  filterModel,
  setFilterModel,
  handleCellClickEvent,
  rowHeight,
  components,
  checkboxSelection,
  hideFooter,
  hasData,
  ...props
}:ViewProps<T>): JSX.Element {
  return (
    <div
      css={styles.container}
      {...props}
    >
      {isLoading && (
        <Skeleton animation="wave" css={styles.skelly} variant="rectangular" height={350} />
      )}
      {!isLoading && (
        <DataGrid
          autoHeight
          hideFooter={hideFooter}
          checkboxSelection={checkboxSelection}
          columns={columns}
          css={styles.table(checkboxSelection, hasData)}
          rowHeight={rowHeight}
          disableColumnMenu
          disableSelectionOnClick
          hideFooterSelectedRowCount
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          components={components}
          onCellClick={handleCellClickEvent}
          pageSize={pageSize}
          rows={rows}
          selectionModel={selectionModel}
          sortingOrder={['desc', 'asc']}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'name',
                  sort: 'asc',
                },
                {
                  field: 'dateCreated',
                  sort: 'asc',
                },
                {
                  field: 'dateDue',
                  sort: 'asc',
                },
              ],
            },
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) => setFilterModel?.(newFilterModel)}
        />
      )}
    </div>
  );
}
interface DataGridTableProps<T extends GridValidRowModel> {
  isLoading?: boolean
  rows: Array<T>,
  columns: GridColumns<T>
  pageSize: number,
  selectionModel: GridSelectionModel,
  setSelectionModel: (rowIds: GridSelectionModel) => void,
  filterModel?: GridFilterModel,
  setFilterModel?: (items: GridFilterModel) => void,
  handleCellClickEvent: GridEventListener<'cellClick'>,
  rowHeight?: number,
  components?: GridSlotsComponent | undefined,
  checkboxSelection?: boolean,
  hideFooter?: boolean,
}

function DataGridTable<T extends GridValidRowModel>({
  isLoading = false,
  rows,
  columns,
  pageSize,
  selectionModel,
  setSelectionModel,
  filterModel,
  setFilterModel,
  handleCellClickEvent,
  rowHeight = 52,
  components,
  checkboxSelection = true,
  hideFooter = false,
  ...props
}: DataGridTableProps<T>): JSX.Element {
  const hasData = rows && rows.length > 0;
  const hookProps = {
    isLoading,
    rows,
    columns,
    selectionModel,
    setSelectionModel,
    pageSize,
    handleCellClickEvent,
    filterModel,
    setFilterModel,
    rowHeight,
    components,
    checkboxSelection,
    hideFooter,
    hasData,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
}

export { View };
export default DataGridTable;
