import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import {
  newLayoutBackgroundColor,
} from '~Deprecated/ui/styles/colors';
import { withPrintStyles } from '~Deprecated/ui/styles/print';
import { withoutDesktop, forDesktop } from '~Common/styles/mixins';
import ErrorBoundary from '~Common/components/ErrorBoundary';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import TrialBanner from '~SelfService/components/TrialBanner';

const drawerMargin = 16;
const drawerWidth = 300;
const drawerWideWidth = 332;

const contentPaneWrapper = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* background: ${newLayoutBackgroundColor}; */

  ${withPrintStyles(css`
    width: 100%;
    overflow: visible;
  `)}
`;

const contentPane = css`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  
  ${process.env.REACT_APP_CONTEXT === 'app' && (`
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
  `)};

  ${withPrintStyles(css`
    overflow: visible;
  `)}
`;

const contentActions = css`
  flex: 0 0 64px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const contentWrapper = css`
  height: 100%;
`;

const contentDrawer = (isWide) => css`
  min-width: ${isWide ? drawerWideWidth : drawerWidth}px;
  max-width: ${isWide ? drawerWideWidth : drawerWidth}px;
  height: calc(100% - ${drawerMargin * 2}px);
  margin: ${drawerMargin}px;
  position: fixed;
  right: 0%;
  overflow-y: scroll;
  background: ${newLayoutBackgroundColor};
  z-index:2;
  
  box-shadow: 0px 4px 16px -4px #7591AA;

  ${withoutDesktop(`
    display: none;
  `)}

  ${withPrintStyles(css`
    display: none;
  `)}
`;

const withDrawer = (isWide) => css`
  ${forDesktop(`
    padding-right: ${(isWide ? drawerWideWidth : drawerWidth) + (drawerMargin * 2)}px;
  `)}
`;

/*
  This will eventually live in a top level router as well, with each route file declaring actions, drawer, and content if needed.
*/
function ContentPane({
  renderActions,
  renderContent,
  renderSideDrawer,
  useWidePanel,
}) {
  const contentPaneCss = [contentPane];
  const { organizationInTrial } = useIsOrganizationInTrial();
  if (renderSideDrawer) {
    contentPaneCss.push(withDrawer(useWidePanel));
  }

  return (
    <ErrorBoundary>
      <div
        css={contentPaneWrapper}
        className="top-content-pane"
      >
        { organizationInTrial && (
          <TrialBanner />
        )}
        <div
          css={contentPaneCss}
        >
          {renderActions && (
            <div
              css={contentActions}
            >
              {renderActions()}
            </div>
          )}
          <div
            css={contentWrapper}
          >
            {renderContent()}
          </div>
        </div>
        {renderSideDrawer && (
          <div
            css={contentDrawer(useWidePanel)}
          >
            {renderSideDrawer()}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

ContentPane.propTypes = {
  renderActions: PropTypes.func,
  renderContent: PropTypes.func.isRequired,
  renderSideDrawer: PropTypes.func,
  useWidePanel: PropTypes.bool,
};

ContentPane.defaultProps = {
  renderActions: null,
  renderSideDrawer: null,
  useWidePanel: null,
};

export default ContentPane;
