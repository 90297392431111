import { createAction } from '@reduxjs/toolkit';
import { mutateState } from '~Deprecated/utils/reduxUtils';
import { registerAction, initialState } from '~Deprecated/reducers/drawers';
import {
  DRAWER_STATE,
  DRAWER_WIDTHS,
  Drawer,
  ManagedDrawer,
} from '~Common/const/drawers';
import { stackDrawers } from '~Deprecated/utils/drawers/stackDrawers';

interface PushDrawerActionPayload {
  drawer: Drawer,
}

function safeStackDrawers(
  order: ManagedDrawer[],
  newDrawerWidth: number,
  isDuplicate: boolean,
  isRemove: boolean,
): {
  stackedDrawers: ManagedDrawer[],
  offset: number,
} {
  return stackDrawers(order, newDrawerWidth, isDuplicate, isRemove);
}

const pushDrawerAction = createAction<PushDrawerActionPayload>('LEADR.PUSH_DRAWER');

function onPushDrawer(state: typeof initialState, { payload }: { payload: PushDrawerActionPayload }): unknown {
  return mutateState(state, (draftState: typeof initialState) => {
    const existingDrawerIndex = draftState.order?.findIndex((drawer) => drawer.name === payload.drawer.name);
    if (existingDrawerIndex >= 0) {
      draftState.order[existingDrawerIndex] = {
        ...payload.drawer,
        state: DRAWER_STATE.OPEN,
        width: payload.drawer.width || DRAWER_WIDTHS.BASE,
        offset: draftState.order[existingDrawerIndex].offset,
        isCollapsed: false,
      };
      for (let i = existingDrawerIndex + 1; i < draftState.order.length; i += 1) {
        draftState.order[i].state = DRAWER_STATE.CLOSED;
      }
      const { stackedDrawers } = safeStackDrawers(draftState.order, 0, true, false);
      draftState.order = stackedDrawers;
    } else {
      const { stackedDrawers, offset } = safeStackDrawers(draftState.order, payload.drawer.width || DRAWER_WIDTHS.BASE, false, false);
      draftState.order = stackedDrawers;
      draftState.order.push({
        ...payload.drawer,
        state: DRAWER_STATE.OPEN,
        width: payload.drawer.width || DRAWER_WIDTHS.BASE,
        offset,
        isCollapsed: false,
      });
    }
    return draftState;
  });
}

registerAction(pushDrawerAction, onPushDrawer);

export {
  pushDrawerAction,
  PushDrawerActionPayload,
};
