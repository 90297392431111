import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import SectionHeader, { SectionHeaderSkeleton } from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import { palette } from '~Common/styles/colors';
import { noop } from '~Deprecated/utils';

const styles = {
  listWrapper: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
    gridGap: '0.5rem',
  }),
  sectionHeader: css({
    gridColumn: '1/-1',
    background: palette.neutrals.gray50,
    width: '100%',
    borderBottom: `1px solid ${palette.neutrals.gray300}`,
    height: '2.1875rem',
    margin: 0,
    padding: 0,
    marginBottom: '2rem',
    '&:not(:first-of-type)': {
      marginTop: '2rem',
    },
    '& > h4': {
      color: palette.neutrals.gray700,
      fontWeight: 600,
      fontSize: '1rem',
    },
    '& > h6': {
      '-webkit-line-clamp': 1,
      overflow: 'hidden',
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box',
    },
  }),
};

const GroupedListView = ({
  listWrapperStyle, groupedIds, renderItem, requestType,
}) => (
  <div>
    { Object.keys(groupedIds).map((groupName, i) => (
      <React.Fragment key={`grouped-wrapper-${i + 1}`}>
        {requestType === 'REQUESTED' && groupName === 'Incomplete' && (
          <SectionHeader text={groupName} css={styles.sectionHeader} />
        )}
        {requestType === 'REQUESTED' && groupName === 'Complete' && (
          <SectionHeader text="Completed" css={styles.sectionHeader} />
        )}
        {requestType === 'RECEIVED' && groupName === 'Incomplete' && (
          <SectionHeader text={groupName} css={styles.sectionHeader} />
        )}
        {requestType === 'RECEIVED' && groupName === 'Complete' && (
          <SectionHeader text="Completed" css={styles.sectionHeader} />
        )}
        {!requestType && (
          <SectionHeader text={groupName} css={styles.sectionHeader} />
        )}
        <div css={[styles.listWrapper, listWrapperStyle]}>
          { groupedIds[groupName]?.map((groupItem, index) => renderItem(groupItem, index)) }
        </div>
      </React.Fragment>
    )) }
  </div>
);

GroupedListView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listWrapperStyle: PropTypes.object,
  groupedIds: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))).isRequired,
  renderItem: PropTypes.func.isRequired,
  requestType: PropTypes.string,
};

GroupedListView.defaultProps = {
  listWrapperStyle: {},
  requestType: '',
};

const SkeletonView = ({
  listWrapperStyle, numberOfGroups, numberOfSkeletonCards, renderSkeletonItem,
}) => (
  <div>
    { [...Array(parseInt(numberOfGroups, 10))].map((_, i) => (
      <React.Fragment key={`grouped-skeleton-${i + 1}`}>
        <SectionHeaderSkeleton css={styles.sectionHeader} />
        <div css={[styles.listWrapper, listWrapperStyle]}>
          {[...Array(parseInt(numberOfSkeletonCards, 10))].map((__, index) => (
            <React.Fragment key={`numberOfSkeletonCards-${index + 1}`}>
              {renderSkeletonItem()}
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    )) }
  </div>
);

SkeletonView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listWrapperStyle: PropTypes.object,
  numberOfGroups: PropTypes.number,
  numberOfSkeletonCards: PropTypes.number,
  renderSkeletonItem: PropTypes.func,
};

SkeletonView.defaultProps = {
  listWrapperStyle: {},
  numberOfGroups: 0,
  numberOfSkeletonCards: 0,
  renderSkeletonItem: noop,
};

export default GroupedListView;

export { SkeletonView as GroupedListViewSkeleton };
