import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import styles from './styles';

const PersonalityTypesList = ({
  label,
  onEdit,
  onPreview,
}) => (
  <div css={styles.dataWrapper} className="learning-assignment-item">
    <div css={[styles.rowData, styles.title]}>{ label }</div>
    <div css={[styles.rowData, styles.title]}>
      <FontAwesomeIcon size="1x" icon={faEdit} onClick={onEdit} />
    </div>
    <div css={[styles.rowData, styles.title]}>
      <span><i className="fa fa-eye fa-2x" onClick={onPreview} aria-hidden="true" /></span>
    </div>
  </div>
);

PersonalityTypesList.propTypes = {
  label: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
};

PersonalityTypesList.defaultProps = {
};

export default PersonalityTypesList;
