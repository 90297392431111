import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { produce } from 'immer';

import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { getHost } from '~Deprecated/services/config';
import { GetAgendaReturn, AgendaTopic, AgendaSection } from '~Meetings/hooks/v2/useGetAgendas';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { useAgendaPermissionsStore } from '../utils/useAgendaPermissions';
import { useShowAgendaEditor } from '../utils/useShowAgendaEditor';

export interface EditAgendaItemRequest {
  huddleId: string,
  agendaId: string,
  text: string,
  isRecurring: boolean,
  isPrivate: boolean,
  eventId?: string,
}

const editAgendaItem = async (editAgendaItemRequest: EditAgendaItemRequest): Promise<HttpCallReturn<void>> => {
  const url = {
    host: getHost('', '2'),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    uri: `/organizations/${getOrganizationId()!}/huddles/agendas`,
  };

  return patchApi(url, editAgendaItemRequest);
};

interface UseEditAgendaItemParams {
  agendaItemId: string,
  sectionId?: string,
}

export const useEditAgendaItem = ({
  agendaItemId,
  sectionId,
}: UseEditAgendaItemParams): UseMutationResult<HttpCallReturn<void>, unknown, EditAgendaItemRequest> => {
  const orgId = getOrganizationId();

  const {
    showAgendaEditor,
    hideAgendaEditor,
  } = useShowAgendaEditor();

  const {
    disableWorkflows,
    enableWorkflows,
    enableSectionWorkflows,
  } = useAgendaPermissionsStore((state) => ({
    enableSectionWorkflows: state.enableSectionWorkflows,
    disableWorkflows: state.disableWorkflows,
    enableWorkflows: state.enableWorkflows,
  }));

  return useMutation({
    mutationFn: editAgendaItem,
    // optimistically push the agenda item into the list so the ui feels snappy
    onMutate: async ({
      huddleId,
      agendaId,
      text,
      isRecurring,
      isPrivate,
    }) => {
      const previousAgendas = queryClient.getQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId));

      hideAgendaEditor({ identifier: agendaId });
      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      disableWorkflows(agendaItemId, sectionId);

      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId), (originalData) => {
        if (originalData) {
          if (sectionId) {
            // The agenda item is being edited in a section
            // Find the section and edit it there
            const sectionIndex = originalData.response.agendas.findIndex((agenda) => agenda.id === sectionId);
            if (sectionIndex !== -1) {
              return produce(originalData, (draft) => {
                const agendaIndex = draft.response.agendas[sectionIndex].children.findIndex((agenda) => agenda.id === agendaId);
                const agendaSection = draft.response.agendas[sectionIndex] as AgendaSection;

                if (agendaIndex !== -1) {
                  // If the agenda section is one time but the agenda item gets updated to recurring, then we remove it from the section and place it below
                  enableSectionWorkflows(sectionId);
                  if (agendaSection.isRecurring === false && isRecurring === true) {
                    const agendaItem = agendaSection.children[agendaIndex];
                    agendaSection.children.splice(agendaIndex, 1);
                    draft.response.agendas.splice(sectionIndex + 1, 0, {
                      ...agendaItem,
                      isRecurring,
                    });
                  } else {
                    draft.response.agendas[sectionIndex].children[agendaIndex] = {
                      ...draft.response.agendas[sectionIndex].children[agendaIndex],
                      text,
                      isRecurring,
                      isPrivate,
                    } as AgendaTopic;
                  }
                }
              });
            }
          } else {
            return produce(originalData, (draft) => {
              const agendaIndex = draft.response.agendas.findIndex((agenda) => agenda.id === agendaId);

              if (agendaIndex !== -1) {
                const oldAgendaTopic = draft.response.agendas[agendaIndex] as AgendaTopic;

                const newAgendaTopic = {
                  ...oldAgendaTopic,
                  text,
                  isRecurring,
                  isPrivate,
                };

                draft.response.agendas[agendaIndex] = newAgendaTopic;
              }
            });
          }
        }

        return originalData;
      });

      return { previousAgendas };
    },
    onError: (_, { huddleId, isRecurring }, snapshot) => {
      toast.error(<MeetingsErrorToast />, {
        autoClose: 1500,
      });
      queryClient.setQueryData(meetingKeys.agendaList(huddleId), snapshot?.previousAgendas);

      let editedAgendaTopic;
      if (sectionId) {
        const agendaSection = snapshot?.previousAgendas?.response.agendas.find((agenda) => agenda.id === sectionId) as AgendaSection;
        editedAgendaTopic = agendaSection.children.find((agenda) => agenda.id === agendaItemId);
      } else {
        editedAgendaTopic = snapshot?.previousAgendas?.response.agendas.find((agenda) => agenda.id === agendaItemId) as AgendaTopic;
      }

      /*
        The only time the editor is available is when you are editing the title of the topic
        We reuse this endpoint for editing multiple things in the topic
        This if statement makes it so that we only reopen the editor if the request fails to update the text of the topic
        If it fails to update the recurrence, then we don't want to reopen the editor
      */
      if (isRecurring === editedAgendaTopic?.isRecurring) {
        showAgendaEditor({
          identifier: agendaItemId,
          props: {
            initialAttachments: editedAgendaTopic?.attachments,
          },
        });
      }
    },
    onSettled: async (_, __, variables) => {
      const { huddleId } = variables;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(orgId!) });
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      enableWorkflows(agendaItemId, sectionId);
    },
  });
};
