import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import BaseToast from './BaseToast';

const SuccessToast = ({ text }) => (
  <BaseToast
    icon={faCheckCircle}
    text={text}
  />
);

SuccessToast.propTypes = {
  text: PropTypes.string.isRequired,
};

SuccessToast.defaultProps = {

};

export default SuccessToast;
