import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { homeModulesDrawerTemplate } from '~Home/components/Drawers/HomeModulesDrawer';
import ModuleTopbar, { ModuleTopbarProps } from '~Common/V3/components/ModuleTopbar';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faGrid2Plus } from '@fortawesome/pro-regular-svg-icons';

export interface ActionItemsHeaderViewProps extends ModuleTopbarProps {
  doCustomize: () => void,
}

const View = ({
  doCustomize,
  ...props
}: ActionItemsHeaderViewProps): JSX.Element => (
  <ModuleTopbar
    {...props}
    renderRightSection={() => (
      <LeadrButton
        data-test-id="customizeHome"
        type="button"
        onClick={doCustomize}
      >
        <LeadrButton.IconAndText
          icon={faGrid2Plus}
          text="Customize"
        />
      </LeadrButton>
    )}
  />
);

export type ActionItemsHeaderProps = ModuleTopbarProps;

const HomeHeader = ({
  ...props
}: ActionItemsHeaderProps): JSX.Element => {
  const dispatch = useDispatch();

  const doCustomize = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...homeModulesDrawerTemplate,
      },
    }));
  }, [dispatch]);

  const hookProps = {
    doCustomize,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default HomeHeader;
