/**
 * @deprecated Old UI Button consts, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
export const BUTTON_VARIANTS = {
  CONSTRUCTIVE: 'constructive',
  DESTRUCTIVE: 'destructive',
  NEUTRAL: 'neutral',
  CALL_TO_ACTION: 'cta',
  OUTLINE: 'outline',
  OUTLINE_DESTRUCTIVE: 'outline_destructive',
};
