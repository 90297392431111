import { useEffect, useState } from 'react';
import { getUserId, getOrganizationUserId } from '~Common/utils/localStorage';
import { ResourceType } from '~DevelopmentPlan/const/types';
import { useAddResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PDP_PATHNAME } from '~DevelopmentPlan/const/defaults';

const useSelectedAttendees = ({
  drawerState,
  setDrawerState,
  attendeeSelectCallback,
  selectAllCallback,
  limit = null,
  beforeSelection,
  selectedKey = 'selectedAttendees',
  allAttendees = [],
  allowSelf,
  disabledUsers,
  filteredAttendees: passedInFilteredAttendees,
  nonSelectedAttendees,
  removeSelectedAttendees,
  useOrgIds,
}) => {
  let selectedAttendees = [];
  const filteredAttendees = passedInFilteredAttendees || allAttendees;

  if (drawerState && selectedKey in drawerState) {
    selectedAttendees = drawerState[selectedKey];
  }

  const [isSelectingAll, setSelectingAll] = useState(false);
  useEffect(() => {
    if (filteredAttendees
        && selectedAttendees
        && selectedAttendees.length === filteredAttendees.filter((attendee) => !disabledUsers?.find((user) => user.user === attendee)).length
        && filteredAttendees.length > 0
    ) {
      setSelectingAll(true);
    } else {
      setSelectingAll(false);
    }
  }, [selectedAttendees, filteredAttendees]);

  const updateDrawerState = () => {
    const newState = { ...drawerState };
    if (!allowSelf) {
      const userId = useOrgIds ? getOrganizationUserId() : getUserId();
      selectedAttendees = selectedAttendees.filter((id) => id !== userId);
    }
    newState[selectedKey] = selectedAttendees;
    setDrawerState(newState);
  };

  const onAttendeeSelect = (id, isCurrentlySelected) => {
    if (limit && limit > 1 && selectedAttendees.length >= limit && !isCurrentlySelected) {
      return;
    }

    if (beforeSelection && !beforeSelection(id)) {
      return;
    }

    let newSelectedAttendees;

    if (isCurrentlySelected) {
      newSelectedAttendees = selectedAttendees.filter((aId) => aId !== id);
    } else {
      newSelectedAttendees = [...selectedAttendees, id];
    }

    if (limit && newSelectedAttendees.length > limit) {
      newSelectedAttendees.shift();
    }

    if (attendeeSelectCallback) {
      // The drawer state is a hack because the drawer isnt updated with new props, so it needs to pass context to the function
      // We can remove this when we update this to a hook. Make sure to update usages too
      attendeeSelectCallback({ id, isCurrentlySelected, drawerState });
    }

    selectedAttendees = newSelectedAttendees;
    updateDrawerState();
  };

  const toggleSelectAll = () => {
    const previouslySelectedAttendees = [...selectedAttendees];
    const attendeesToUse = removeSelectedAttendees ? nonSelectedAttendees.concat(previouslySelectedAttendees) : filteredAttendees;
    selectedAttendees = !isSelectingAll ? attendeesToUse?.filter((attendee) => !disabledUsers?.find((user) => user.user === attendee)) : [];

    const newlySelectedAttendees = attendeesToUse.filter((attendee) => !previouslySelectedAttendees.includes(attendee) && !disabledUsers?.find((user) => user.user === attendee));

    if (selectAllCallback) {
      selectAllCallback({
        isSelectAll: !isSelectingAll, attendees: attendeesToUse.filter((attendee) => !disabledUsers?.find((user) => user.user === attendee)), newlySelectedAttendees, onSelectDrawerState: drawerState,
      });
    }

    setSelectingAll(!isSelectingAll);

    updateDrawerState();
  };

  const selectAll = () => {
    const previouslySelectedAttendees = [...selectedAttendees];
    const filteredAttendeesWithoutDisabledUsers = filteredAttendees.filter((attendee) => !disabledUsers?.find((user) => user.user === attendee));
    selectedAttendees = [...new Set([...previouslySelectedAttendees, ...filteredAttendeesWithoutDisabledUsers])]; // This is done with a set because sets can't have duplicates, and its sonic speeds

    if (selectAllCallback) {
      selectAllCallback({
        isSelectAll: true, newlySelectedAttendees: selectedAttendees, onSelectDrawerState: drawerState,
      });
    }

    updateDrawerState();
  };

  const deselectAll = () => {
    const previouslySelectedAttendees = [...selectedAttendees];
    const attendeesToDeselect = filteredAttendees;
    selectedAttendees = previouslySelectedAttendees.filter((attendee) => !attendeesToDeselect.includes(attendee));

    if (selectAllCallback) {
      selectAllCallback({
        isSelectAll: false, newlySelectedAttendees: selectedAttendees, onSelectDrawerState: drawerState,
      });
    }

    updateDrawerState();
  };

  const {
    pdpOwnerId,
    pdpOwnerUserId,
    resourceId,
  } = useAddResourceModalStore((state) => ({
    pdpOwnerId: state.pdpOwnerId,
    pdpOwnerUserId: state.pdpOwnerUserId,
    resourceId: state.resourceId,
  }));

  const history = useHistory();
  const { location: { pathname } } = history;
  const inPDPs = pathname.includes(DEFAULT_PDP_PATHNAME);
  const isPDPCreation = Boolean(pdpOwnerId && pdpOwnerId !== '' && inPDPs);

  const isAttendeeSelected = (id) => {
    if (selectedAttendees && selectedAttendees.length > 0) {
      return selectedAttendees.includes(id);
    }

    return false;
  };

  useEffect(() => {
    if (isPDPCreation && selectedAttendees && !selectedAttendees.includes(pdpOwnerUserId) && resourceId !== ResourceType.Feedback) {
      selectedAttendees = [pdpOwnerUserId, ...selectedAttendees];
      updateDrawerState();
    }
  }, [selectedAttendees]);

  return {
    onAttendeeSelect,
    toggleSelectAll,
    isAttendeeSelected,
    isSelectingAll,
    selectAll,
    deselectAll,
    selectedAttendees,
  };
};

export default useSelectedAttendees;
