import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { GoalParticipants } from './GoalParticipants';
import LinkedGoals from './LinkedGoals';

const styles = {
  participantsContainer: (isTablet: boolean, isDrawer: boolean) => css({
    maxWidth: '25rem',
  }, isTablet && {
    minWidth: '15rem',
  }, isDrawer && {
    maxWidth: '100%',
  }),
  linkedGoals: css({
    marginTop: '1.5rem',
  }),
};

interface ViewProps {
  collaborators: Goals.GoalParticipant[] | undefined,
  viewers: Goals.GoalParticipant[] | undefined,
  showSkeleton: boolean,
  isTablet: boolean,
  canLinkGoal: boolean,
  showLinkedGoals: boolean,
  goal?: Goals.Goal,
  isDrawer?: boolean,
}

const View = ({
  collaborators,
  viewers,
  showSkeleton,
  isTablet,
  isDrawer = false,
  goal,
  canLinkGoal,
  showLinkedGoals,
}: ViewProps): JSX.Element => (
  <>
    <div
      css={styles.participantsContainer(isTablet, isDrawer)}
    >
      <GoalParticipants
        collaborators={collaborators}
        viewers={viewers}
        showSkeleton={showSkeleton}
        isTablet={isTablet}
        isDrawer={isDrawer}
      />
      {goal && showLinkedGoals && (
        <LinkedGoals
          css={styles.linkedGoals}
          goal={goal}
          canLinkGoal={canLinkGoal}
        />
      )}
    </div>
  </>
);

interface GoalDetailsOwnerAndParticipantsProps extends Pick<ViewProps, 'goal' | 'isTablet' | 'isDrawer'> {
  participants: Goals.GoalParticipant[] | undefined,
  showSkeleton: boolean,
  permissions: Goals.GoalPermission[] | undefined,
}

export const GoalDetailsOwnerAndParticipants = ({
  participants,
  showSkeleton,
  permissions,
  ...props
}: GoalDetailsOwnerAndParticipantsProps): JSX.Element => {
  // Leave this in place - this removes and solves any user that was hard deleted
  // This should not happen but it is at least in the lower environments
  const participantsToSort = participants?.filter((participant) => participant.firstName !== undefined);
  const collaborators = participantsToSort
    ?.filter((participant) => participant.role === Goals.GoalParticipantRole.Collaborator)
    .sort((a, b) => a.firstName.localeCompare(b.firstName));
  const viewers = participantsToSort
    ?.filter((participant) => participant.role === Goals.GoalParticipantRole.Viewer)
    .sort((a, b) => a.firstName.localeCompare(b.firstName));

  const {
    featureFlagEnableCascadingGoals,
    orgLevelEnableCascadingGoals,
  } = useEnableCascadingGoals();

  const showLinkedGoals = featureFlagEnableCascadingGoals && orgLevelEnableCascadingGoals;

  const canLinkGoal = !!permissions?.includes(Goals.GoalPermission.CanLinkGoal);

  const hookProps = {
    collaborators,
    viewers,
    showSkeleton,
    canLinkGoal,
    showLinkedGoals,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
