import { Person } from '~Common/const/interfaces';
import { MeetingTypeEnum, MeetingFrequency, NonLeadrAttendee } from '~Meetings/const/meetingsInterfaces';
import { ReviewCycleDetail } from '~Reviews/V2/Const/types';
import { Survey } from '~Surveys/const/Survey';
import { ActionItem } from '~ActionItems/const/interfaces';
import { ReceivedLearning } from '~Learning/const/interfaces';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Moment } from 'moment-timezone';
import { MouseEvent } from 'react';
import { Dispatch } from 'redux';
import { WhatsDueFilterOptions } from '.';

export interface MyScheduleDay {
  day: string,
  meetings: MyScheduleMeeting[],
}

export interface MeetingPerson {
  firstName: string,
  userId: string,
  orgUserId: string,
  lastName: string,
  jobTitle: string,
  profileImageUrl?: string,
}

export interface MyScheduleMeeting {
  title: string,
  location: string,
  frequency: MeetingFrequency,
  daysOfWeek?: number[],
  startTimeInMillis: number,
  endTimeInMillis: number,
  nylasEventId: string,
  meetingInstanceId: string,
  meetingFactoryId: string,
  organizer: MeetingPerson,
  attendeesExcludingOrganizer: MeetingPerson[],
  numberOfAttendeesExcludingOrganizer: number,
  numberOfAgendaItems: number,
  nonLeadrAttendees: NonLeadrAttendee[],
  meetingType: MeetingTypeEnum,
  isRecurringSeries: boolean,
  isOnlyInExternalCalendar: boolean,
  linkToUsersExternalCalendar: string,
  isIgnored: boolean,
  teamId?: string,
}

export enum InsightsFor {
  DirectReports = 'DIRECT_REPORTS',
  Everyone = 'EVERYONE',
  Downline = 'DOWNLINE',
  Recent = 'RECENT',
  Managers = 'MANAGERS',
}

export interface InsightsReport {
  activity: number,
  coaching: number,
  goal: number,
  feedback: number,
}
export interface InsightsPerson {
  department?: string,
  firstName: string,
  jobTitle?: string,
  lastName: string,
  orgUserId: string,
  profileImageUrl: string
  report: InsightsReport
  userId: string,
}

export enum SpecialDay {
  JoinDate = 'JOINING_DATE',
  Birthday = 'BIRTHDAY'
}

export enum AllDueItemTypes {
  AllDueActionItem = 'actionItem',
  Feedback = 'feedback',
  SurveyItem = 'survey',
  Learning = 'learning',
  ReviewCycleDetailType = 'review',
}

export interface SpecialDaysUser {
  month: number,
  day: number,
  year: number,
  type: SpecialDay,
  orgUserId: string
}

interface FeedbackQuestion {
  questionId: string,
  questionText: string,
}
export interface FeedbackItem {
  id: string,
  title: string,
  isCompleted: boolean,
  isCompletedByLoggedInUser: boolean,
  owner: Person,
  questions: FeedbackQuestion[],
  requestedDateInMillis: Date,
  dueDate?: Date | number | undefined,
}

interface AllDueActionItem extends ActionItem {
  type: AllDueItemTypes.AllDueActionItem,
}

interface AllDueFeedbackItem extends FeedbackItem {
  type: AllDueItemTypes.Feedback,
}

interface AllDueSurveyItem extends Survey {
  type: AllDueItemTypes.SurveyItem,
}

interface AllDueReceivedLearningItem extends ReceivedLearning {
  type: AllDueItemTypes.Learning,
}

interface AllDueReviewItem extends ReviewCycleDetail {
  type: AllDueItemTypes.ReviewCycleDetailType,
}
export interface AllDueItems {
  allDueItems: (AllDueActionItem | AllDueFeedbackItem | AllDueSurveyItem | AllDueReceivedLearningItem | AllDueReviewItem)[],
}

export interface AllDueItem {
  allDueItems: (AllDueActionItem | AllDueFeedbackItem | AllDueSurveyItem | AllDueReceivedLearningItem | AllDueReviewItem),
}

export interface WhatsDueOnClickHandlerParams {
  dispatch: Dispatch,
  id: string,
  event: MouseEvent<HTMLButtonElement>,
  learningStatus?: string,
  learningType?: string,
}
export interface WhatsDueItem {
  text: string,
  iconToRender: IconProp,
  dueDate: Moment | undefined,
  onItemClick?: (params: WhatsDueOnClickHandlerParams) => void,
  type: WhatsDueFilterOptions,
  id: string,
  href?: string,
  learningType?: string,
  status?: string,
}

export interface UseTransformItemsReturn {
  isLoading: boolean,
  data: WhatsDueItem[],
}
