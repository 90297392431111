import { faArrowAltRight } from '@fortawesome/pro-duotone-svg-icons/faArrowAltRight';
import Button, { BUTTON_COLOR } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const CancelNeutral = (props) => (
  <Button
    buttonColor={BUTTON_COLOR.DEFAULT}
    icon={faArrowAltRight}
    {...props}
  />
);

CancelNeutral.propTypes = {

};

CancelNeutral.defaultProps = {

};

export default CancelNeutral;
