import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { Goals } from '@leadr-hr/types';
import moment from 'moment';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { GOAL_DETAIL_STYLES, SKELETON_STYLES } from '~Goals/const/styles';
import { Skeleton } from '@mui/material';
import ClampLines from '~Common/components/ClampLines';
import { lineClamp } from '~Common/styles/mixins';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  ...GOAL_DETAIL_STYLES,
  ...SKELETON_STYLES,
  details: css({
    fontSize: '1rem',
    fontWeight: 500,
    color: palette.neutrals.gray700,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    maxWidth: '18.75rem',
  }),
  priorityTeamWrap: (isMobile: boolean) => css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
  }, isMobile && {
    gridTemplateColumns: '1fr',
  }),
  goalDetailsWrap: css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '1rem',
  }),
  goalTeamClamp: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    fontWeight: 500,

    '& button': {
      display: 'none',
    },
  }),
  externalLink: css({
    color: palette.brand.indigo,
    fontSize: '1rem',
    fontWeight: 500,
  }, lineClamp(2)),
};

interface ViewProps {
  timePeriod: string,
  externalLink: string | undefined,
  priority: string | undefined,
  team: string | undefined,
  goalTypeLabel: string,
  objectiveType: Goals.GoalCategory | undefined,
  canViewTableGroup: boolean,
  showSkeleton: boolean,
  isMobile: boolean,
}

const View = ({
  timePeriod,
  externalLink,
  priority,
  team,
  goalTypeLabel,
  objectiveType,
  canViewTableGroup,
  showSkeleton,
  isMobile,
}: ViewProps): JSX.Element => (
  <>
    <div
      css={styles.goalDetailsWrap}
    >
      <div css={styles.priorityTeamWrap(isMobile)}>
        {priority && (
          <div>
            <span css={styles.subHeading}>Priority</span>
            {showSkeleton && (
              <Skeleton
                variant="text"
                animation="wave"
                css={styles.allowProperHeight}
              />
            )}
            {!showSkeleton && (
              <span css={styles.details}>{priority}</span>
            )}
          </div>
        )}
        {team && (
          <div>
            <span css={styles.subHeading}>Team</span>
            {showSkeleton && (
              <Skeleton
                variant="text"
                animation="wave"
                css={styles.allowProperHeight}
              />
            )}
            {!showSkeleton && (
              <ClampLines
                text={team}
                id="goal-team"
                testId="goalTeam"
                lines={2}
                ellipsis="..."
                moreText="Read More"
                lessText="Show Less"
                emotionStyle={styles.goalTeamClamp}
              />
            )}
          </div>
        )}
        {!team && (
          <div>
            <span css={styles.subHeading}>Type</span>
            {showSkeleton && (
              <Skeleton
                variant="text"
                animation="wave"
                css={styles.allowProperHeight}
              />
            )}
            {!showSkeleton && (
              <span css={styles.details}>{goalTypeLabel}</span>
            )}
          </div>
        )}
      </div>
      {objectiveType && canViewTableGroup && (
        <div>
          <span css={styles.subHeading}>Objective Type</span>
          {showSkeleton && (
            <Skeleton
              variant="text"
              animation="wave"
              css={styles.allowProperHeight}
            />
          )}
          {!showSkeleton && (
            <span css={styles.details}>{objectiveType}</span>
          )}
        </div>
      )}
      <div>
        <span css={styles.subHeading}>Time Period</span>
        {showSkeleton && (
          <Skeleton
            variant="text"
            animation="wave"
            css={styles.allowProperHeight}
          />
        )}
        {!showSkeleton && (
          <span css={styles.details}>{timePeriod}</span>
        )}
      </div>
      {externalLink && (
        <div>
          <span css={styles.subHeading}>External Link</span>
          {showSkeleton && (
            <Skeleton
              variant="text"
              animation="wave"
              css={styles.allowProperHeight}
            />
          )}
          {!showSkeleton && (
          <a css={[styles.details, styles.externalLink]} target="_blank" href={externalLink} rel="noreferrer">
            {externalLink}
          </a>
          )}
        </div>
      )}
    </div>
  </>
);

interface GoalDetailsProps {
  goal: Goals.Responses.GetGoalByIdResponse['data'] | undefined,
  showSkeleton: boolean,
  isMobile: boolean,
}

export const GoalDetails = ({
  goal,
  showSkeleton,
  isMobile,
}: GoalDetailsProps): JSX.Element => {
  const timePeriod = ` ${moment(goal?.startTimeInMillis).format('MMM D, YYYY')} - ${moment(goal?.endTimeInMillis).format('MMM D, YYYY')}`;
  const link = /^https?:\/\/(.*)/.test(goal?.externalLink ?? '') ? goal?.externalLink : `https://${goal?.externalLink ?? ''}`;
  const externalLink = goal?.externalLink ? link : undefined;

  const priority = goal?.priority ? Goals.GoalPriority[goal?.priority] : undefined;

  const isTableGroupCobrandingEnabled = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const { enableTheTable } = useOrgDetailsContext();
  const team = goal?.context?.contextName;
  const canViewTableGroup = enableTheTable && isTableGroupCobrandingEnabled && team !== undefined;
  const goalType = goal?.context?.contextType;
  const goalTypeLabel = goalType === Goals.GoalContextType.Organization ? 'Organization Goal' : 'Personal Goal';

  const objectiveType = goal?.category;

  const hookProps = {
    timePeriod,
    externalLink,
    priority,
    team,
    goalTypeLabel,
    objectiveType,
    canViewTableGroup,
    showSkeleton,
    isMobile,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
