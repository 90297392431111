export const DAYS_IN_WEEK = 7;

/**
 * @deprecated Old Types, use FrequencyType in packages/leadr-frontend/src/meetings/const/meetingsInterfaces.ts instead
 */
export const MEETING_FREQUENCY = {
  ONE_TIME: 'ONE_TIME',
  WEEKLY: 'WEEKLY',
  BI_WEEKLY: 'BI_WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const STRENGTHS = [
  'Achiever',
  'Activator',
  'Adaptability',
  'Analytical',
  'Arranger',
  'Belief',
  'Command',
  'Communication',
  'Competition',
  'Connectedness',
  'Consistency',
  'Context',
  'Deliberative',
  'Developer',
  'Discipline',
  'Empathy',
  'Focus',
  'Futuristic',
  'Harmony',
  'Ideation',
  'Includer',
  'Individualization',
  'Input',
  'Intellection',
  'Learner',
  'Maximizer',
  'Positivity',
  'Relator',
  'Responsibility',
  'Restorative',
  'Self Assurance',
  'Significance',
  'Strategic',
  'Woo',
];

export const WORKING_GENIUS_TYPES_OPTIONS = [
  'Wonder',
  'Invention',
  'Discernment',
  'Galvanizing',
  'Enablement',
  'Tenacity',
];

export const workingGeniusTypes = WORKING_GENIUS_TYPES_OPTIONS.map((type) => ({
  title: type,
  value: type,
}));
