import { SerializedStyles, css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Goals } from '@leadr-hr/types';
import {
  GridRenderCellParams,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { GoalRow } from '~Goals/const/types';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faCaretDown, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { SyntheticEvent } from 'react';
import { MAX_CASCADING_GOALS_TABLE_DEPTH } from '~Goals/const';
import NameCell from './NameCell';

const styles = {
  customTreeCell: (depth: number) => css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: `${depth * 0.5}rem`,
    overflow: 'hidden',
    width: '100%',
  }),
  expandButton: (showExpand: boolean) => css({
    visibility: showExpand ? 'visible' : 'hidden',
    paddingLeft: '.5rem',
    paddingRight: '0.85rem',
  }),
  nameCell: css({
    flex: 1,
  }),
  icon: css({
    fontSize: '1rem',
  }),
};

interface ViewProps {
  subText: string | undefined,
  handleToggle: (event: SyntheticEvent<HTMLButtonElement>) => void,
  title: string,
  isPrivate: boolean | undefined,
  percentage: number | undefined,
  status: Goals.GoalStatus,
  isExpanded: boolean,
  depth: number,
  showExpand: boolean,
  totalChildGoals: number,
  showProgressBar: boolean,
  buttonStyles?: SerializedStyles,
}

const View = ({
  subText,
  handleToggle,
  title,
  isPrivate,
  percentage,
  status,
  isExpanded,
  depth,
  showExpand,
  totalChildGoals,
  buttonStyles,
  showProgressBar,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.customTreeCell(depth)} {...props}>
    <LeadrButton
      css={[styles.expandButton(showExpand), buttonStyles]}
      data-test-id="goalsExpandSubGoals"
      disabled={!showExpand}
      onClick={handleToggle}
      variant="icon"
    >
      {isExpanded && (
        <FontAwesomeIcon css={styles.icon} icon={faCaretDown} />
      )}
      {!isExpanded && (
        <FontAwesomeIcon css={styles.icon} icon={faCaretRight} />
      )}
    </LeadrButton>
    <NameCell
      css={styles.nameCell}
      title={title}
      isPrivate={isPrivate}
      totalChildGoals={totalChildGoals}
      subText={subText}
      percentage={percentage}
      status={status}
      showProgressBar={showProgressBar}
    />
  </div>
);

interface CustomTreeCellProps extends GridRenderCellParams<GoalRow>, Pick<ViewProps, 'buttonStyles'> {
  showProgressBar?: boolean,
}

const CustomTreeCell = ({
  row,
  id,
  rowNode,
  field,
  showProgressBar = true,
  ...props
}: CustomTreeCellProps): JSX.Element => {
  const isMobile = useIsMobileQuery();
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );

  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const isExpanded = rowNode.type === 'group' && !!rowNode.childrenExpanded;

  const handleToggle = (event: SyntheticEvent<HTMLButtonElement>): void => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const {
    isPrivate,
    subText,
    title,
    totalChildGoals,
  } = row;

  const { percentage, status } = row.progress;

  const hookProps = {
    handleToggle,
    subText,
    title,
    isPrivate,
    percentage,
    status,
    isExpanded,
    depth: rowNode.depth,
    showExpand: filteredDescendantCount > 0 && rowNode.depth < MAX_CASCADING_GOALS_TABLE_DEPTH,
    totalChildGoals: totalChildGoals ?? 0,
    showProgressBar: !!showProgressBar && isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CustomTreeCell;
