import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import DrawerHeader from '~Common/components/Drawers/Header';
import BioDrawerHeader from '~People/BaseballCard/Drawers/BioDrawerHeader';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import CloseDrawerButton from '~Common/components/Buttons/CloseDrawer';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import Loader from '~Common/components/Loader';
import { palette } from '~Common/styles/colors';
import { separator } from '~Common/styles/mixins';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';

export const bioDrawerTemplate = {
  name: 'BIO_DRAWER',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};

const styles = {
  header: css({
    alignItems: 'flex-start',
    paddingTop: '.5rem !important',
    paddingBottom: '.5rem !important',
    marginBlockStart: 'unset',
  }),
  body: css({
    padding: '1.5rem',
    paddingBlockStart: '.625rem',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    color: palette.neutrals.gray800,
  }),
  dates: css({
    padding: '.875rem 0',
    display: 'flex',
  }),
  title: css({
    fontSize: '.75rem',
    color: palette.neutrals.gray700,
  }),
  date: css({
    fontSize: '.875rem',
    color: palette.neutrals.gray800,
  }),
  birthday: css({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  }),
  workAnniversary: css({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  }),
  newHeader: css({
    padding: '0 !important',
    margin: '0 9px',
    alignItems: 'stretch',
    position: 'relative',

    ':after': {
      content: '""',
      borderBottom: `1px solid ${palette.neutrals.gray200}`,
      height: '1px',
      width: '100%',
      position: 'absolute',
      left: '0',
      top: '3.75rem',
      maxWidth: '22.3125rem',
    },
  }),
  newBody: css({
    padding: '0',
    margin: '0 .5625rem',
  }),
  bioDrawerContent: css({
    padding: '0',
    marginTop: '1rem !important',
    color: palette.neutrals.gray800,
  }),
};

const titleMapper = {
  BIRTHDAY: 'Birthday',
  JOINING_DATE: 'Work Anniversary',
};

const displayDate = (specialDay) => {
  const date = new Date(`${specialDay.month}/${specialDay.date}/${specialDay.year}`);
  return moment(date).format(`MMM Do ${specialDay.type !== 'BIRTHDAY' ? 'YYYY' : ''}`.trim());
};

const View = ({ user, doneButton }) => (
  <DrawerLayout
    css={styles.layout}
    renderHeader={() => (
      <DrawerHeader
        css={[styles.header, styles.newHeader]}
        renderCustomHeader={() => (<BioDrawerHeader user={user} css={styles.drawerHeader} />)}
        title={`${user?.firstName}'s Bio`}
        renderButtons={doneButton}
      />
    )}
    renderBody={() => (
      <>
        <div css={styles.newBody}>
          <>
            {user?.specialDays.length > 0 && (
            <>
              <div>
                <div css={separator('5px', '0', true)} />
              </div>
              <div css={styles.dates}>
                { user?.specialDays.map((specialDay) => (
                  <div css={styles.birthday}>
                    <div css={styles.title}>{ titleMapper[specialDay.type] }</div>
                    <div
                      css={styles.date}
                    >
                      { displayDate(specialDay) }
                    </div>
                  </div>
                )) }
              </div>
            </>
            )}
          </>
          <div>
            <div css={separator('0', '0', true)} />
          </div>
          <HTMLRenderer css={[styles.body, styles.userInput, styles.bioDrawerContent]} htmlText={user.aboutMe} />
        </div>
      </>
    )}
  />
);

View.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  doneButton: PropTypes.func.isRequired,
};

View.defaultProps = {
};

function BioDrawer({
  popDrawer, id,
}) {
  const dispatch = useDispatch();
  const { detail } = useUserProfile(id);
  const userDetails = detail.data?.response;

  if (!userDetails) {
    return <Loader />;
  }

  const onClose = () => {
    dispatch(popDrawer({
      drawerName: bioDrawerTemplate.name,
    }));
  };

  const hookProps = {
    doneButton: () => (
      <CloseDrawerButton onClick={onClose} />
    ),
    user: userDetails,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

BioDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  popDrawer: PropTypes.func.isRequired,
};

BioDrawer.defaultProps = {};

registerDrawer({
  templateName: bioDrawerTemplate.name,
  component: BioDrawer,
});

export { View };
export default BioDrawer;
