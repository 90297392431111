import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import ListLearningCard from '~Learning/components/Shared/LearningDashboardCards/ListLearningCard';
import { LearningStatus, ReceivedLearning } from '~Learning/const/interfaces';

const styles = {
  receivedListDashboard: css({
    display: 'grid',
    gridGap: '0.5rem',
    gridTemplateColumns: 'repeat(auto-fill)',
    width: '100%',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '6.25rem',
  }),
};

interface ReceivedListDashboardProps extends ComponentProps<'div'> {
  receivedLearnings: ReceivedLearning[] | undefined,
  onReceivedCardClick: (receivedLearning: ReceivedLearning) => void,
}

const ReceivedListDashboard = ({
  receivedLearnings,
  onReceivedCardClick,
  ...props
}: ReceivedListDashboardProps): JSX.Element => (
  <div css={styles.receivedListDashboard} {...props}>
    {receivedLearnings?.map((receivedLearning) => (
      <ListLearningCard
        key={receivedLearning.id}
        id={receivedLearning.id}
        data-test-id={`receivedLearningListItem-${receivedLearning.id}`}
        onCardClick={() => onReceivedCardClick(receivedLearning)}
        learningType={receivedLearning.learningType}
        title={receivedLearning.title}
        dueDate={receivedLearning.dueDate}
        assignedUsersInfo={[receivedLearning.assigner]}
        numberOfUsersAssigned={1}
        isComplete={receivedLearning.status === LearningStatus.COMPLETED}
      />
    ))}
  </div>
);

const SkeletonView = (): JSX.Element => (
  <MultipleSkeletonLoaders
    css={styles.receivedListDashboard}
    numberOfSkeletons={10}
    renderSkeletonItem={() => (
      <CardSkeleton css={styles.cardSkeleton} />
    )}
  />
);

export default ReceivedListDashboard;

export { SkeletonView as ReceivedListDashboardSkeleton };
