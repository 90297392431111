// https://www.figma.com/file/TTcMiLIjepvtP1Rg3mL5r3/Product-Guide?node-id=1507%3A1789
// Color Definitions - do not use these directly outside of this file

import { palette } from '~Common/styles/colors';

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const pallette = {
  // Neutrals
  gray1: '#4D6E8A',
  gray2: '#7591AA',
  gray3: '#AFC5DA',
  gray4: '#CEDCEA',
  gray5: '#F7F7FD',
  gray6: '#363638',
  gray7: '#99999d',
  white: '#FFFFFF',
  black: '#1C1d20',

  // Color Neutrals
  red: '#DD393E',
  yellow: '#FEAF02',
  green: '#038D59',
  blue: '#335AA8',
  purple: '#4545A4',
  pink: '#D25BB1',
  navy: '#385066',

  // Color Darks
  dark_red: '#AB615C',
  dark_yellow: '#FAB041',
  dark_green: '#5E8C5F',
  dark_blue: '#428AA1',
  dark_purple: '#786091',
  dark_pink: '#9E4485',

  // Color Lights
  light_red: '#FCD1CE',
  light_yellow: '#FDEBD1',
  light_green: '#CCE5CD',
  light_blue: '#BCE2EE',
  light_purple: '#DBCDE8',
  light_pink: '#ED8DE0',

};

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const hexToRGBA = (hex, alpha = 1.0) => {
  const r = hex.slice(1, 3);
  const g = hex.slice(3, 5);
  const b = hex.slice(5, 7);

  return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${alpha})`;
};

/*
  Color Usages - describe context of use so we can change color decisions on the fly
  from one location or change these to functions
*/

// Mixin Variables
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const heading6Color = pallette.gray2;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const shadowColor = pallette.navy;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const lightShadowColor = 'rgba(58, 80, 102, 0.4)';
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const rightPanelShadowColor = pallette.gray3;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const rootDefaultPColor = pallette.gray2;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const rootDefaultAColor = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const newLayoutBackgroundColor = palette.neutrals.gray50;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const loadingColor = pallette.gray2;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const scrollbarContentPaneColor = pallette.gray4;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const cardColor = palette.neutrals.white;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const cardTextColor = pallette.navy;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const cardActionColor = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const cardLabelColor = pallette.gray1;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const cardSubtextColor = pallette.gray2;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const cardDeckColor = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const cardHeaderTextColor = pallette.gray1;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonDefaultColor = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonLinkColor = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonIconColor = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonBaseTextColor = palette.neutrals.white;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonConstructiveColor = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonConstructiveColorHover = pallette.dark_blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonDestructiveColor = pallette.red;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonNeutralColor = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const buttonNeutralColorHover = pallette.gray4;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerButtonDarkText = pallette.gray2;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerButtonLightText = palette.neutrals.white;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerButtonCtaBg = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerButtonCtaBgHover = pallette.dark_blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerBackgroundColor = palette.neutrals.white;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const verticalButtonColor = pallette.gray1;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const verticalButtonHoverBg = pallette.gray4;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const iconDefaultFillColor = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const iconDefaultUnfilledColor = palette.neutrals.white;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const switchTrackColor = pallette.gray4;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const switchTrackActiveColor = pallette.green;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const switchThumbColor = palette.neutrals.white;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const tooltipTextColor = pallette.gray3;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const defaultBadgeBackground = pallette.gray2;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const defaultBadgeTextColor = palette.neutrals.white;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerInputText = pallette.navy;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerInputLabelText = pallette.gray2;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerInputBorder = pallette.gray4;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerInputBackground = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerInputDisabled = hexToRGBA(pallette.gray4, 0.4);
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerBackdrop = hexToRGBA(palette.neutrals.white, 0.6);
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerIcon = pallette.gray3;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerColor = palette.neutrals.white;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const datePickerDateColor = pallette.black;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerAvatarName = pallette.gray1;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerSectionBorderColor = pallette.gray4;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const drawerSectionHeaderColor = pallette.gray2;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const selectedAttendeeBackground = pallette.gray1;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const hoverAttendeeBackground = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const unselectedAttendeeBackground = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const selectedAttendeeText = palette.neutrals.white;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const hoverAttendeeText = palette.neutrals.white;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const unselectedAttendeeText = pallette.navy;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const baseUserTextColor = pallette.navy;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const COMPATTabActive = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const COMPATMeetingSwitch = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const COMPATCardShadow = pallette.gray2;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const COMPATAdminBadgeColor = pallette.gray2;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const COMPATErrorColor = pallette.red;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const COMPATStepperActiveColor = pallette.blue;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const emptyStateBoxBg = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const emptyStateColor = pallette.gray2;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const emptyStateBorder = pallette.gray3;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const tabsBg = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const tabsColor = pallette.gray1;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const activeTabBg = pallette.navy;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const activeTabColor = palette.neutrals.white;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const disabledTabColor = pallette.gray3;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const tileShadow = hexToRGBA(pallette.gray1, 0.2);
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const tileBackground = palette.neutrals.white;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const toastInfo = pallette.blue;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const toastSuccess = pallette.green;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const toastWarning = pallette.yellow;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const toastError = pallette.red;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const meetingIndicators = {
  green: pallette.green,
  gray: pallette.gray4,
  red: pallette.red,
};

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const timelineItemColor = pallette.gray1;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const timelineItemBg = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const timelineItemBorder = pallette.gray4;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const timelineItemActive = pallette.blue;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const searchBg = pallette.gray5;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const searchBorder = pallette.gray4;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const searchLeadingIconStrokeColor = pallette.gray3;

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const topicItemColor = pallette.gray1;
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const topicItemBg = pallette.gray5;
// Used by Material
/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const inputActiveColor = 'rgba(0,0,0,0.54)';

/**
 * @deprecated Old styles use packages/leadr-frontend/src/common/styles/colors.js instead
 */
export const questionDisplay = pallette.gray2;

function createGradient(light, dark) {
  return `linear-gradient(120deg, ${light} 50%, ${dark} 99%)`;
}

// These are old variants that are only used for charts and gradients right now, don't use elsewhere
export const variantPalette = {
  // indigos
  indigoDarker1: '#2e5296',
  indigoLighter2: '#5c7aba',
  indigoLightest: '#ebf0f9',
  // blues
  blueDarker1: '#0073c4',
  blueLighter2: '#3399e3',
  // sky blues
  skyDarker1: '#0099d9',
  skyLighter2: '#33bdf5',
  // greens
  greenDarker1: '#03ab4f',
  greenLighter2: '#36c97a',
  // purples
  purpleDarker1: '#3d3d94',
  purpleLighter2: '#6b6bb5',
  // reds
  redDarker1: '#c73338',
  redLighter2: '#e36166',
  // yellows
  yellowDarker1: '#e39e03',
  yellowLighter2: '#ffbf36',
  lightYellow: '#ffe8b5',
};

// Gradients
// These range from the Palette to 3 shades lighter than the Palette. See https://drive.google.com/file/d/1G_o2SKKsfHsNPPkkrwyp69RajoxTa8hj/view
export const gradients = {
  purple: createGradient(variantPalette.purpleLighter2, hexToRGBA(palette.brand.purple, 0.7)),
  indigo: createGradient(variantPalette.indigoLighter2, hexToRGBA(palette.brand.indigo, 0.7)),
  blue: createGradient(variantPalette.blueLighter2, hexToRGBA(palette.brand.blue, 0.7)),
  sky: createGradient(variantPalette.skyLighter2, hexToRGBA(palette.brand.sky, 0.7)),
  green: createGradient(variantPalette.greenLighter2, hexToRGBA(palette.brand.green, 0.7)),
  yellow: createGradient(variantPalette.yellowLighter2, hexToRGBA(palette.brand.orange, 0.7)),
  red: createGradient(variantPalette.redLighter2, hexToRGBA(palette.brand.red, 0.7)),
  dark: createGradient(hexToRGBA(palette.neutrals.black, 0.5), palette.neutrals.black),
  light: createGradient(palette.neutrals.white, palette.neutrals.gray200),
  lightGreen: createGradient(hexToRGBA(palette.brand.green, 0.15), hexToRGBA(palette.brand.green, 0.05)),
  lightGray: createGradient(hexToRGBA(palette.neutrals.gray400, 0.25), hexToRGBA(palette.neutrals.gray400, 0.1)),
  lightYellow: createGradient(hexToRGBA(variantPalette.yellowLighter2, 0.4), hexToRGBA(variantPalette.yellowLighter2, 0.25)),
};
