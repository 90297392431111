/*
  This file exists solely for Nexus compatability. It can be nuked once we re-imagine Nexus.
*/

import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import MuiTextField from '@mui/material/TextField';

import { validationShape, VALIDATION_DEFAULT } from '~Common/const/forms';
import { noop } from '~Deprecated/utils';

const style = css`

`;

/**
 * @deprecated Old UI
 */
const TextField = ({
  value, onChange, name, label, validation, ...props
}) => {
  const [currentValue, updateState] = useState(value);
  const doChange = (event) => {
    if (onChange) onChange(event);

    updateState(event.target.value);
  };

  return (
    <MuiTextField
      css={style}
      variant="standard"
      value={currentValue}
      onChange={doChange}
      label={label}
      name={name}
      inputProps={{
        ...validation,
      }}
      {...props}
    />
  );
};

TextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validation: validationShape,
};

TextField.defaultProps = {
  value: '',
  onChange: noop,
  label: '',
  validation: VALIDATION_DEFAULT,
};

export default TextField;
