import {
  faBook,
  faBookUser,
  faBriefcase,
  faBullseyeArrow,
  faChartBar,
  faChartNetwork,
  faClipboardUser,
  faCog,
  faCommentsAlt,
  faDraftingCompass,
  faFlag,
  faIdBadge,
  faIdCard,
  faNetworkWired,
  faPencilRuler,
  faPoll,
  faSignOut,
  faSkull,
  faTasks,
  faTerminal,
  faUserFriends,
  faUsers,
  faUsersCog,
  faUserSecret,
  faHouse,
  faPartyHorn,
  faHandHoldingSeedling,
} from '@fortawesome/pro-light-svg-icons';
import { UserGroup } from './userGroups';

export const BASE_ROUTE = '/';
export const ACTION_ITEMS_BASE_ROUTE = `${BASE_ROUTE}action-items/`;
export const TASKS_BASE_ROUTE = `${BASE_ROUTE}tasks/`;
export const ADMIN_BASE_ROUTE = `${BASE_ROUTE}admin/`;
export const COACHINGS_BASE_ROUTE = `${BASE_ROUTE}coachings/`;
export const MEETINGS_BASE_ROUTE = `${BASE_ROUTE}meetings/`;
export const FEEDBACK_BASE_ROUTE = `${BASE_ROUTE}feedback/`;
export const RECOGNITION_BASE_ROUTE = `${BASE_ROUTE}recognition/`;
export const SURVEYS_BASE_ROUTE = `${BASE_ROUTE}surveys/`;
export const CONTENT_BASE_ROUTE = `${BASE_ROUTE}content/`;
export const LEARNING_BASE_ROUTE = `${BASE_ROUTE}learning/`;
export const GOALS_BASE_ROUTE = `${BASE_ROUTE}goals/`;
export const NEXUS_BASE_ROUTE = `${BASE_ROUTE}nexus/`;
export const PRINT_BASE_ROUTE = `${BASE_ROUTE}print/`;
export const INSIGHTS_BASE_ROUTE = `${BASE_ROUTE}insights/`;
export const INSIGHTS_MARKETING_ROUTE = `${BASE_ROUTE}get-insights/`;
export const HOME_BASE_ROUTE = `${BASE_ROUTE}home/`;
export const DEVELOPMENT_PLAN_BASE_ROUTE = `${BASE_ROUTE}development-plans/`;

export const FORGOT_PASSWORD = `${BASE_ROUTE}forgot-password`;
export const REGISTER = `${BASE_ROUTE}register`;
export const LOGOUT = `${BASE_ROUTE}logout`;
export const MOBILE_APP_LANDING_PAGE = `${BASE_ROUTE}mobile-app-landing`;
export const POST_REGISTRATION_LANDING = `${BASE_ROUTE}post-register`;
export const SELECT_ORGANIZATION = `${BASE_ROUTE}select-organization`;
export const INVITED_USER_PROFILE = `${BASE_ROUTE}update-profile-image`;
export const ORG = `${BASE_ROUTE}org`;
export const PEOPLE = `${BASE_ROUTE}people`;
export const TEAMS = `${BASE_ROUTE}teams`;
export const REVIEWS = `${BASE_ROUTE}reviews`;
export const REVIEWS_BY_CYCLE = `${BASE_ROUTE}reviews/cycle/:cycleId`;
export const ADMIN_REVIEWS_BY_CYCLE = `${BASE_ROUTE}reviews/admin/cycle/:cycleId`;
export const REVIEWS_BY_ID = `${BASE_ROUTE}reviews/:cycleId/:reviewId`;
export const REVIEWS_BY_ID_V2 = `${BASE_ROUTE}reviews/:reviewId`;
export const REVIEWS_ADMIN_BY_ID_V2 = `${BASE_ROUTE}reviews/:reviewId/admin`;

export const REVIEWS_CREATE_CYCLE = `${BASE_ROUTE}reviews/admin/createCycle`;
export const REVIEWS_CONTINUE_CREATING_CYCLE = `${BASE_ROUTE}reviews/admin/createCycle/:cycleId`;

// Recognition routes
export const RECOGNITION = `${BASE_ROUTE}recognition`;
export const RECOGNITION_BY_ID = `${BASE_ROUTE}recognition/:recognitionId`;

// DevelopmentPlan routes
export const DEVELOPMENT_PLAN = `${BASE_ROUTE}development-plans`;
export const DEVELOPMENT_PLAN_BY_ID = `${BASE_ROUTE}development-plans/:pdpId`;
export const DEVELOPMENT_PLAN_CREATE = `${BASE_ROUTE}development-plans/createDevelopmentPlan`;

// Settings routes
export const SETTINGS = `${BASE_ROUTE}settings`;
export const ACCOUNT_SETTINGS = `${SETTINGS}/account`;
export const ADMIN_SETTINGS = `${SETTINGS}/admin`;
// export const LEARNING_SETTINGS = `${SETTINGS}/learning`;
// export const NOTIFICATION_SETTINGS = `${SETTINGS}/notifications`;

export const BENEFIT_FORM = `${BASE_ROUTE}benefitform`;

export const IMPERSONATE_LANDING_PAGE = `${BASE_ROUTE}impersonate`;

export const ALL_TEAMS = `${PEOPLE}/teams/all`;
export const MY_TEAMS = `${PEOPLE}/teams/my`;
export const TEAM_DETAILS = `${PEOPLE}/teams/:teamId`;
export const TEAM_DETAILS_USERS = `${PEOPLE}/teams/:teamId/users`;
export const TEAM_DETAILS_GOALS = `${PEOPLE}/teams/:teamId/goals`;
export const PEOPLE_ADMIN = `${PEOPLE}/admin`;
export const PEOPLE_ADMIN_IMPORT = `${PEOPLE}/admin/import`;

export const ADMIN_LIST = `${ADMIN_BASE_ROUTE}list/activated`;
export const ADMIN_PEOPLE = `${ADMIN_BASE_ROUTE}people`;
export const ADMIN_PEOPLE_NEW_IMPORT = `${ADMIN_BASE_ROUTE}people/import`;
export const ADMIN_USER_GROUPS = `${ADMIN_BASE_ROUTE}user-groups`;

export const ADMIN_REVIEWS_V2 = `${BASE_ROUTE}reviews/admin`;
export const ADMIN_REVIEWS_MYREVIEWS = `${ADMIN_BASE_ROUTE}myreviews`;
export const ADMIN_REVIEWS_REFLECTIONS = `${ADMIN_BASE_ROUTE}reflections`;
export const ADMIN_REVIEWS_REFLECTIONS_V2 = `${BASE_ROUTE}reviews/reflections`;

export const COACHING_ROUTE = `${BASE_ROUTE}coaching`;
export const COACHING_LIST_UPCOMING = `${COACHINGS_BASE_ROUTE}list/upcoming`;
export const COACHING_LIST_PAST = `${COACHINGS_BASE_ROUTE}list/past`;

export const MEETING_ROUTE = `${BASE_ROUTE}meetings`;
export const MEETING_LIST_UPCOMING = `${MEETINGS_BASE_ROUTE}list/upcoming`;
export const MEETING_LIST_PAST = `${MEETINGS_BASE_ROUTE}list/past`;
export const MEETING_LIST = `${MEETINGS_BASE_ROUTE}list`;

export const FEEDBACK_ROUTE = `${BASE_ROUTE}feedback`;
export const FEEDBACK_RECEIVED = `${FEEDBACK_BASE_ROUTE}received`;
export const FEEDBACK_SENT = `${FEEDBACK_BASE_ROUTE}sent`;

export const SURVEY_ROUTE = `${BASE_ROUTE}surveys`;

export const CONTENT_ROUTE = `${BASE_ROUTE}content`;
export const CONTENT_LIST_ROUTE = `${CONTENT_BASE_ROUTE}list/`;
export const CONTENT_LIST_RECIEVED = `${CONTENT_LIST_ROUTE}received`;
export const CONTENT_LIST_ASSIGNED = `${CONTENT_LIST_ROUTE}assigned`;
export const CONTENT_ADD = `${CONTENT_BASE_ROUTE}add`;

export const LEARNING_ROUTE = `${BASE_ROUTE}learning`;
export const LEARNING_LIST_RECEIVED = `${LEARNING_BASE_ROUTE}received`;
export const LEARNING_LIST_ASSIGNED = `${LEARNING_BASE_ROUTE}assigned`;

export const NEXUS_ORGANIZATIONS = `${NEXUS_BASE_ROUTE}organizations`;
export const PERSONALITY_TYPES = `${NEXUS_BASE_ROUTE}personality-types`;
export const IMPERSONATE = `${NEXUS_BASE_ROUTE}impersonate`;

export const COACHING_HOME = COACHING_LIST_UPCOMING;
export const MEETING_HOME = MEETING_LIST_UPCOMING;
export const GOALS_HOME = GOALS_BASE_ROUTE;
export const FEEDBACK_HOME = FEEDBACK_RECEIVED;
export const CONTENT_HOME = CONTENT_LIST_RECIEVED;

export const ACTION_ITEMS_HOME = ACTION_ITEMS_BASE_ROUTE;
export const ACTION_ITEMS_OPEN = `${ACTION_ITEMS_BASE_ROUTE}open`;
export const ACTION_ITEMS_COMPLETED = `${ACTION_ITEMS_BASE_ROUTE}completed`;

export const ADMIN_HOME = ADMIN_LIST;
export const NEXUS_HOME = NEXUS_ORGANIZATIONS;
export const HOME_PAGE_HOME = HOME_BASE_ROUTE;

export const PRINT_FEEDBACK_BY_QUESTION = `${PRINT_BASE_ROUTE}feedback/:feedbackId/byQuestion`;
export const PRINT_FEEDBACK_BY_PERSON = `${PRINT_BASE_ROUTE}feedback/:feedbackId/byPerson`;
export const PRINT_SURVEYS_BY_QUESTION = `${PRINT_BASE_ROUTE}surveys/:surveyId/byQuestion`;
export const PRINT_SURVEYS_BY_PERSON = `${PRINT_BASE_ROUTE}surveys/:surveyId/byPerson`;
export const PRINT_INSIGHTS = `${PRINT_BASE_ROUTE}insights`;
export const PRINT_AGENDA = `${PRINT_BASE_ROUTE}:type/:id`;
export const PRINT_REVIEWS_BY_ID_V2 = `${PRINT_BASE_ROUTE}reviews/:reviewId`;

export const GLOBAL_SEARCH = `${BASE_ROUTE}search`;

// These are only used for showing the Text in the mobile navbar
export const TABS = {
  HOME: {
    id: 'home',
    text: 'Home',
    url: HOME_PAGE_HOME,
    icon: faHouse,
    matcher: /.*\/home\/*/i,
  },
  MEETINGS: {
    id: 'meetings',
    text: 'Meetings',
    icon: faBriefcase,
    url: COACHING_HOME,
    matcher: /.*\/(coachings?|meetings?)\/*/i,
  },
  GOALS: {
    id: 'goals',
    text: 'Goals',
    icon: faBullseyeArrow,
    url: GOALS_BASE_ROUTE,
    matcher: /.*\/goals\/*/i,
  },
  FEEDBACK: {
    id: 'feedback',
    text: 'Feedback',
    icon: faCommentsAlt,
    url: FEEDBACK_RECEIVED,
    matcher: /.*\/feedback\/*/i,
  },
  SURVEYS: {
    id: 'surveys',
    text: 'Surveys',
    icon: faPoll,
    url: SURVEY_ROUTE,
    matcher: /.*\/surveys\/*/i,
  },
  INSIGHTS: {
    id: 'insights',
    text: 'Insights',
    icon: faChartBar,
    url: INSIGHTS_BASE_ROUTE,
    matcher: /.*\/insights\/*/i,
  },
  INSIGHTS_MARKETING: {
    id: 'insightsMarketing',
    text: 'Insights Preview',
    icon: faChartBar,
    url: INSIGHTS_MARKETING_ROUTE,
    matcher: /.*\/get-insights\/*/i,
  },
  LEARNING: {
    id: 'learning',
    text: 'Learning',
    icon: faBook,
    url: LEARNING_LIST_RECEIVED,
    matcher: /.*\/learning\/*/i,
  },
  ACTION_ITEMS: {
    id: 'actionItems',
    text: 'Action Items',
    icon: faTasks,
    url: ACTION_ITEMS_OPEN,
    matcher: /.*\/(action-items)\/*/i,
  },
  PEOPLE: {
    id: 'people',
    text: 'People',
    icon: faUserFriends,
    url: PEOPLE,
    matcher: /^\/(?!admin)people.*$/i,
  },
  RECOGNITION: {
    id: 'recognition',
    text: 'Recognition',
    icon: faPartyHorn,
    url: RECOGNITION,
    matcher: /.*\/recognition\/*/i,
  },
  DEVELOPMENTPLAN: {
    id: 'developmentPlan',
    text: 'Development Plans',
    icon: faHandHoldingSeedling,
    url: DEVELOPMENT_PLAN,
    matcher: /.*\/pdp\/*/i,
  },
  REVIEWS: {
    id: 'reviews',
    text: 'Reviews',
    icon: faClipboardUser,
    url: REVIEWS,
    matcher: /^\/(?!admin)reviews.*$/i,
  },
  ADMIN_REVIEWS_V2: {
    id: 'reviews',
    text: 'Reviews',
    icon: faClipboardUser,
    url: ADMIN_REVIEWS_V2,
    matcher: /^\/reviews\/admin.*$/i,
  },
  ADMIN_REVIEWS_REFLECTIONS_V2: {
    id: 'reviews',
    text: 'Reviews',
    icon: faClipboardUser,
    url: ADMIN_REVIEWS_REFLECTIONS_V2,
    matcher: /^\/reviews\/reflections.*$/i,
  },
  REVIEWS_CREATE_CYCLE: {
    id: 'reviews',
    text: 'Reviews',
    icon: faClipboardUser,
    url: REVIEWS_CREATE_CYCLE,
    matcher: /^\/reviews\/createCycle.*$/i,
  },
  REVIEWS_CONTINUE_CREATING_CYCLE: {
    id: 'reviews',
    text: 'Reviews',
    icon: faClipboardUser,
    url: REVIEWS_CONTINUE_CREATING_CYCLE,
    matcher: /^\/reviews\/createCycle.*$/i,
  },
  HRMODULE: {
    id: 'hrmodule',
    text: '',
    icon: faBookUser,
    url: '',
    matcher: null,
    isExternalLink: true,
  },
  NEXUS: {
    id: 'nexus',
    text: 'Nexus',
    icon: faPencilRuler,
    url: NEXUS_HOME,
    matcher: /.*\/nexus\/*/i,
  },
  ACCOUNT_SETTINGS: {
    id: '',
    text: 'Account Settings',
    url: ACCOUNT_SETTINGS,
    matcher: /.*\/account\/*/,
  },
  ADMIN_SETTINGS: {
    id: '',
    text: 'Admin Settings',
    icon: faUsersCog,
    url: ADMIN_SETTINGS,
    matcher: /^\/settings\/admin\/*/,
  },
  // NOTIFICATION_SETTINGS: {
  //   id: '',
  //   text: 'Notification Settings',
  //   icon: faUsersCog,
  //   url: ADMIN_SETTINGS,
  //   matcher: /^\/settings\/notification\/*/,
  // },
  // LEARNING_SETTINGS: {
  //   id: '',
  //   text: 'Learning Settings',
  //   icon: faUsersCog,
  //   url: ADMIN_SETTINGS,
  //   matcher: /^\/settings\/learning\/*/,
  // },
  ADMIN: {
    id: 'administration',
    text: 'Admin',
    icon: faUsersCog,
    url: ADMIN_BASE_ROUTE,
    matcher: /.*\/admin\/*/i,
  },
};

export const ADMIN_TABS = {
  PEOPLE: {
    id: 'admin_people',
    text: 'People',
    icon: faUsers,
    url: ADMIN_PEOPLE,
    matcher: /^(?=.*\badmin\b)(?=.*\bpeople\b).*$/i,
  },
};

export const PROFILE_TABS = {
  PROFILE: {
    id: 'profile',
    text: 'Profile',
    icon: faIdBadge,
    matcher: null,
  },
  SETTINGS: {
    id: 'settings',
    text: 'Settings',
    icon: faCog,
    matcher: null,
  },
  LOGOUT: {
    id: 'logout',
    text: 'Logout',
    icon: faSignOut,
    matcher: null,
  },
};

export const NEXUS_TABS = {
  ORGS: {
    id: 'orgs',
    text: 'Orgs',
    icon: faNetworkWired,
    url: NEXUS_ORGANIZATIONS,
    matcher: /.*\/organizations\/*/i,
  },
  PERSONALITY_TYPES: {
    id: 'personality_types',
    text: 'Personality Types',
    icon: faIdCard,
    url: PERSONALITY_TYPES,
    matcher: /.*\/personality-types\/*/i,
  },
  IMPERSONATE: {
    id: 'impersonate',
    text: 'Impersonate',
    icon: faUserSecret,
    url: IMPERSONATE,
    matcher: /.*\/impersonate\/*/i,
  },
};

export const DEV_TABS = {
  TEST_TOOLS: {
    id: 'flags',
    text: 'Flags',
    icon: faFlag,
    url: '',
    matcher: null,
  },
  SKELETON_LOADERS: {
    id: 'skeleton-loaders',
    text: 'Skeleton Loaders',
    icon: faSkull,
    url: '',
    matcher: null,
  },
  INSIGHTS_DEV_TOOLS: {
    id: 'insights-dev',
    text: 'Insights',
    icon: faChartBar,
    url: '',
    matcher: null,
  },
};

export const getNexusTabs = (
  { whiteHatImpersonator },
  {
    isImpersonator,
    isCustomerSuccessAdmin,
    isCustomerSuccessManager,
  },
) => {
  const tabs = [];

  if (isCustomerSuccessAdmin || isCustomerSuccessManager) {
    tabs.push(NEXUS_TABS.ORGS, NEXUS_TABS.PERSONALITY_TYPES);
  }
  if (whiteHatImpersonator && isImpersonator && !window.sessionStorage.getItem('sessionId')) {
    tabs.push(NEXUS_TABS.IMPERSONATE);
  }

  return tabs;
};

export const getAdminTabs = () => {
  const tabs = [];

  tabs.push(ADMIN_TABS.PEOPLE);

  return tabs;
};

export const getProfileTabs = () => {
  const tabs = [
    PROFILE_TABS.PROFILE,
    PROFILE_TABS.SETTINGS,
    PROFILE_TABS.LOGOUT,
  ];

  return tabs;
};

export const getDevTabs = () => {
  const tabs = [
    DEV_TABS.TEST_TOOLS,
    DEV_TABS.SKELETON_LOADERS,
    DEV_TABS.INSIGHTS_DEV_TOOLS,
  ];

  return tabs;
};

export const getTabs = ({
  isEnableHRModule, isISolvedEnabled, person, flags, permissions, isMobile, isEnableReviews, isInsightsEnabled, isRecognitionEnabled, hrLinkText, isInTrial,
}) => {
  const isAdminOrExec = person?.userGroupName === UserGroup.Admin || person?.userGroupName === UserGroup.Executive;
  const canSeeInsights = isAdminOrExec || permissions.isManager;
  const tabs = [
    ...(flags?.webClientUseLeadrHome ? [TABS.HOME] : []),
    TABS.MEETINGS,
    TABS.GOALS,
    TABS.FEEDBACK,
    TABS.DevelopmentPlan,
    ...(flags?.featureRecognition && isRecognitionEnabled ? [TABS.RECOGNITION] : []),
    ...(isInsightsEnabled && (flags?.frontendInsights || flags?.webClientSurveys) ? [TABS.SURVEYS] : []),
    ...(flags?.frontendInsights && isInsightsEnabled && canSeeInsights && !isInTrial ? [TABS.INSIGHTS] : []),
    ...(isInTrial ? [TABS.INSIGHTS_MARKETING] : []),
    TABS.LEARNING,
    TABS.ACTION_ITEMS,
    TABS.PEOPLE,
    ...(isEnableReviews ? [TABS.REVIEWS] : []),
  ];

  if (flags?.showLeadrNetworkLink || (flags?.showLeadrNetworkLinkForAdminsAndExecutives && isAdminOrExec)) {
    const gammaTribeUrl = 'https://leadrnetworkstaging.tribe.so/';
    const prodTribeUrl = 'https://community.leadr.com/';

    const isGamma = process.env.REACT_APP_ENV === 'GAMMA';
    const isProd = process.env.REACT_APP_ENV === 'PROD';

    let tribeUrl = '';

    if (isGamma) {
      tribeUrl = gammaTribeUrl;
    } else if (isProd) {
      tribeUrl = prodTribeUrl;
    }

    // If feature flag is enabled and a link is provided for the environment, add the navigation option.
    if (isGamma || isProd) {
      tabs.push({
        id: 'tribeModule',
        text: 'Community',
        icon: faChartNetwork,
        url: tribeUrl,
        matcher: null,
        isExternalLink: true,
      });
    }
  }

  const hrModuleLink = flags?.hrModuleLink;

  if (hrModuleLink
    && ((isEnableHRModule || isISolvedEnabled) || isAdminOrExec)) {
    tabs.push({ ...TABS.HRMODULE, text: hrLinkText });
  }

  if (person?.userGroupName === UserGroup.Admin && !isMobile) {
    tabs.push(
      {
        text: 'Admin',
        icon: faUsersCog,
        hasSubList: true,
        subList: getAdminTabs(),
        collapseKey: 'isAdminCollapseOpen',
      },
    );
  }

  const nexusSubList = getNexusTabs({ ...flags }, { ...permissions });

  if (nexusSubList.length > 0) {
    tabs.push(
      {
        text: 'Nexus',
        icon: faDraftingCompass,
        hasSubList: true,
        subList: nexusSubList,
        collapseKey: 'isNexusCollapseOpen',
      },
    );
  }

  if (flags?.showTestPage) {
    tabs.push({
      text: 'Dev Tools',
      icon: faTerminal,
      hasSubList: true,
      subList: getDevTabs(),
      collapseKey: 'isDevToolsCollapseOpen',
    });
  }

  return tabs;
};
