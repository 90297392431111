import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import MuiInput from '@mui/material/Input';
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import MuiFilledInput from '@mui/material/FilledInput';

import {
  inputActiveColor,
} from '~Deprecated/ui/styles/colors';
import TextField from './NexusTextField';
import Dropdown from './Dropdown';
import FilePicker from './FilePicker';

import Switch from './Switch';

const style = css` 
  width: 100%;

  & label {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
  }
  
  & .MuiFormLabel-root.Mui-focused {
    color: ${inputActiveColor};
  }

  & .MuiInputBase-input {
    box-shadow: none !important;
    height: 20px;
  }

  & .MuiOutlinedInput-input {
    padding: 15px;
  }
`;

// Add to this as we develop more usecases
const INPUT_TYPES = {
  SEARCH: 'search',
  TEXT: 'text',
  SELECT: 'select',
  FILE: 'file',
  HIDDEN: 'hidden',
  NUMBER: 'number',
};

const INPUT_VARIANTS = {
  NONE: undefined,
  FILLED: 'filled',
  OUTLINED: 'outlined',
};

const VARIANT_TO_BASE_INPUT = {
  filled: MuiFilledInput,
  outlined: MuiOutlinedInput,
};

const typeToInput = {
  text: TextField,
  select: Dropdown,
  file: FilePicker,
  switch: Switch,
  number: TextField,
};

/**
 * @deprecated Old UI
 */
const Input = ({
  type, variant, ...props
}) => {
  const TypeInput = typeToInput[type];
  const BaseInput = VARIANT_TO_BASE_INPUT[variant] || MuiInput;

  if (type === 'hidden') {
    return (
      <input
        type="hidden"
        {...props}
      />
    );
  }

  if (type === INPUT_TYPES.FILE) {
    return (
      <TypeInput
        BaseInput={BaseInput}
        css={style}
        {...(variant ? { variant } : {})}
        {...props}
      />
    );
  }
  if (type === INPUT_TYPES.NUMBER) {
    return (
      <TypeInput
        css={style}
        type={type}
        {...(variant ? { variant } : {})}
        {...props}
      />
    );
  }
  if (TypeInput) {
    return (
      <TypeInput
        css={style}
        {...(variant ? { variant } : {})}
        {...props}
      />
    );
  }

  return (
    <BaseInput
      css={style}
      type="text"
      {...props}
    />
  );
};

Input.propTypes = {
  variant: PropTypes.oneOf(Object.values(INPUT_VARIANTS)),
  type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
};

Input.defaultProps = {
  variant: INPUT_VARIANTS.NONE,
  type: INPUT_TYPES.TEXT,
};

export default Input;
export { INPUT_TYPES, INPUT_VARIANTS };
