import { useDebounce } from '@react-hook/debounce';
import { useEffect, useMemo } from 'react';
import { createQueryKey, setLocalStore, useLocalStore } from '~Common/hooks/useGenericStore';

export const useGenericSearch = ({
  module,
  workflow,
  defaultValue = '',
  wait = 500,
}) => {
  const searchKey = useMemo(() => createQueryKey({ module, workflow, feature: 'search' }), [module, workflow]);
  const storedSearch = useLocalStore({ key: searchKey, defaultValue });
  const [search, setDebouncedSearch] = useDebounce(storedSearch, wait);

  const clearSearch = () => setLocalStore({ key: searchKey, value: '' });

  useEffect(() => {
    setLocalStore({ key: searchKey, value: search });
  }, [search, searchKey]);

  return {
    search: storedSearch,
    setSearch: setDebouncedSearch,
    clearSearch,
  };
};
