import { Goals } from '@leadr-hr/types';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { buildQueryString } from '~Common/utils';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getHost } from '~Deprecated/services/config';
import { homeQueryKeys } from '../queryKeys';

type GetLeadrHomeGoalsResponse = Goals.Responses.GetLeadrHomeGoalsResponse['data'];

const getLeadrHomeGoals = async ({
  queryParameters,
  organizationId,
}: Goals.Requests.GetLeadrHomeGoalsRequest): Promise<HttpCallReturn<GetLeadrHomeGoalsResponse>> => {
  const queryParams = buildQueryString(queryParameters ?? {});

  const url = {
    host: getHost('', '3'),
    uri: `/organizations/${organizationId}/goals/home${queryParams}`,
  };

  return getApi<GetLeadrHomeGoalsResponse>(url);
};

interface UseGetLeadrHomeGoalsParams<T> extends Omit<UseQueryOptions<HttpCallReturn<GetLeadrHomeGoalsResponse>, Error, T>, 'queryKey' | 'queryFn'> {
  queryParameters?: Goals.Requests.GetLeadrHomeGoalsRequestQueryParameters,
}

export const useGetLeadrHomeGoals = <T = HttpCallReturn<GetLeadrHomeGoalsResponse>>({
  queryParameters,
  ...options
}: UseGetLeadrHomeGoalsParams<T> = {}): UseQueryResult<T, Error> => {
  const organizationId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: homeQueryKeys.homeGoals(organizationId, queryParameters),
    queryFn: () => getLeadrHomeGoals({ organizationId, queryParameters }),
    ...options,
  });
};
