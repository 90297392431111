import { css } from '@emotion/react';
import LeadrShieldLogo from '~Assets/images/leadrShieldIndigo.svg';
import { palette } from '~Common/styles/colors';
import { forMobileObject, withPrintStylesObject, withTruncate } from '~Common/styles/mixins';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';
import { HTMLString } from '~Common/types';
import PrintedDateText from './PrintedDateText';
import PrintButton from './PrintButton';

const styles = {
  basePrintHeader: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '1.25rem',
    borderBottom: `1px solid ${palette.neutrals.gray300}`,
  }),
  printButton: css({
    marginTop: '1.875rem',
  }),
  infoContainer: css({
    marginTop: '2.8125rem',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  }),
  logo: css({
    width: '2.1875rem',
    marginRight: '1.125rem',
  }),
  titleContainer: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  }),
  title: css({
    fontSize: '1.5rem',
    color: palette.neutrals.gray800,
    fontWeight: 600,
    maxWidth: '100%',
  }, withTruncate()),
  subTitle: css({
    color: palette.neutrals.gray700,
    fontWeight: 500,
    fontSize: '1rem',
  }, withTruncate()),
  rightSide: css({
    marginLeft: 'auto',
    flexShrink: 0,
    paddingLeft: '1rem',
  }, forMobileObject({
    display: 'none',
  }), withPrintStylesObject({
    display: 'block',
  })),
  skeletonLoader: css({
    maxWidth: '100%',
    height: '1.875rem',
    width: '12.5rem',
  }),
};

interface ViewProps {
  title: string,
  subTitle?: string | HTMLString,
  printButtonDataTestId: string,
  isSubTitleHtml: boolean,
}

const View = ({
  title,
  subTitle,
  printButtonDataTestId,
  isSubTitleHtml,
  ...props
}: ViewProps): JSX.Element => (
  <section
    css={styles.basePrintHeader}
    {...props}
  >
    <PrintButton
      css={styles.printButton}
      data-test-id={printButtonDataTestId}
    />
    <div css={styles.infoContainer}>
      <LeadrShieldLogo css={styles.logo} title="Leadr logo" data-test-id="leadrShieldLogo" />
      <div css={styles.titleContainer}>
        <h2 css={styles.title}>{title}</h2>
        {subTitle && (
          <>
            {isSubTitleHtml && (
              <HTMLRenderer css={styles.subTitle} htmlText={subTitle} />
            )}
            {!isSubTitleHtml && (
              <h3 css={styles.subTitle}>{subTitle}</h3>
            )}
          </>
        )}
      </div>
      <PrintedDateText css={styles.rightSide} />
    </div>
  </section>
);

type BasePrintHeaderProps = Omit<ViewProps, 'isSubTitleHtml'>;

const BasePrintHeader = ({
  subTitle,
  ...props
}: BasePrintHeaderProps): JSX.Element => {
  const isSubTitleHtml = !!subTitle && isHTMLText(subTitle);

  const hookProps = {
    subTitle,
    isSubTitleHtml,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

const BasePrintHeaderSkeleton = (): JSX.Element => (
  <section
    css={styles.basePrintHeader}
  >
    <PrintButton
      css={styles.printButton}
      data-test-id="loadingStatePrint"
      disabled
    />
    <div css={styles.infoContainer}>
      <LeadrShieldLogo css={styles.logo} title="Leadr logo" data-test-id="leadrShieldLogo" />
      <div css={styles.titleContainer}>
        <SkeletonLoader
          css={styles.skeletonLoader}
          variant="rectangular"
          renderComponent={() => (
            <></>
          )}
        />
      </div>
      <PrintedDateText css={styles.rightSide} />
    </div>
  </section>
);

export { BasePrintHeaderSkeleton };

export default BasePrintHeader;
