import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useState } from 'react';
import {
  drawerHeaderFontColor, drawerInputBackground, disabledInputColor, palette,
} from '~Common/styles/colors';
import { TEXT_MAX_LENGTH } from '~Common/const/inputs';

const style = css`
  background: ${drawerInputBackground};
  padding: 7px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  align-items: center;
  letter-spacing: 0.5px;
  height: auto;
  min-height: unset;

  &:focus {
    outline: none !important;
  }

  ::-webkit-input-placeholder {
    color: ${drawerHeaderFontColor} !important;
  }

  label {
    color: ${palette.neutrals.gray500};
    margin-bottom: 0;
  }

  textarea {
    border: unset;
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    color: ${drawerHeaderFontColor};
    height: auto;
    margin: 0;
    padding: 0.3rem 0;
    font-weight: 400;
    font-size: 16px;
    min-height: 7.5rem;
  }
`;

const disabledInput = css`
  background: ${disabledInputColor};
  border: unset;
`;

const widths = {
  halfRow: css`
    width: 49%;
    margin-right: 2%;
  `,
  halfRowNoMargin: css`
    width: 49%;
    margin-right: 0;
  `,
  fullRow: css`
    width: 100%; // Changed to 100%
  `,
};

export const SIZE_OPTIONS = {
  FULL_ROW: 'fullRow',
  HALF_ROW: 'halfRow',
  HALF_ROW_NO_MARGIN: 'halfRowNoMargin',
};

const DrawerTextArea = ({
  name,
  size,
  onChange,
  initialValue,
  label,
  disabled,
  className,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  const doChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };

  return (
    <div
      css={[
        style,
        widths[size],
        (disabled ? disabledInput : ''),
      ]}
      className={className}
    >
      <label htmlFor={name}>{label}</label>
      <textarea
        name={name}
        type="text"
        onChange={doChange}
        value={value}
        disabled={disabled}
        maxLength={TEXT_MAX_LENGTH}
        {...props}
      />
    </div>
  );
};

DrawerTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

DrawerTextArea.defaultProps = {
  placeholder: '',
  size: SIZE_OPTIONS.FULL_ROW,
  onChange: () => {},
  initialValue: '',
  label: '',
  className: '',
  disabled: false,
};

export default DrawerTextArea;
