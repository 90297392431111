import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  SCORE_NAME_SUMMARY_SHAPE, DEFAULT_SCORE_SUMMARY, SCORE_NAME_VALUES,
} from '~Common/const/executiveInsights';
import Tooltip from '~Common/components/Tooltip';
import InsightGraphSlice from '../InsightGraphSlice';
import ScoringTooltipContent from '../../ScoringTooltipContent';
import { scoringTooltipStyle } from '../../scoringTooltipStyle';

const style = css`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

const span = (percentage) => css({
  flexBasis: percentage,
});

const shouldShowSlice = (value) => value > 0;

function InsightGraph({ scoreSummaryForMetric, metric }) {
  const entries = Object.entries(scoreSummaryForMetric);
  // Total would go as the denominator in division (value / total) so can not be 0.
  const total = Math.max(entries.reduce((sum, val) => sum + val[1], 0), 1);

  const parentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!(parentRef && parentRef.current)) {
      return;
    }
    const rect = parentRef.current.getBoundingClientRect();
    setHeight(rect.height);
  }, [parentRef]);

  return (
    <div
      ref={parentRef}
      css={style}
    >
      {entries.map(([scoreName, value]) => {
        const percentage = `${Math.max((value * 100) / total, 5)}%`;
        return shouldShowSlice(value) && (
          <Tooltip
            key={scoreName}
            css={scoringTooltipStyle}
            interactive
            content={(
              <ScoringTooltipContent
                insightKey={metric}
                insightValue={SCORE_NAME_VALUES[scoreName]}
                isAggregate
              />
            )}
          >
            <span css={span(percentage)}>
              <InsightGraphSlice
                scoreName={scoreName}
                parentHeight={height}
              />
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
}

InsightGraph.propTypes = {
  metric: PropTypes.string.isRequired,
  scoreSummaryForMetric: SCORE_NAME_SUMMARY_SHAPE,
};

InsightGraph.defaultProps = {
  scoreSummaryForMetric: DEFAULT_SCORE_SUMMARY,
};

export default InsightGraph;
