import { css } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
} from 'react';
import {
  GetOrgUserPermissionsReturn,
  OrgUserPermission,
  UserPermission,
  useGetOrgUserPermissions,
} from '~Common/hooks/permissions/useGetOrgUserPermissions';
import { palette } from '~Common/styles/colors';
import { HttpCallReturn } from '~Deprecated/services/HttpService';

const styles = {
  loader: css({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  }),
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const OrgUserPermissionsContext = createContext<PermissionObject | null>(null);

export const useOrgUserPermissionsContext = (): PermissionObject => {
  const orgUserPermissions = useContext(OrgUserPermissionsContext);

  if (!orgUserPermissions) {
    throw new Error('OrgUserPermissionsContextProvider: No value provided ');
  }

  return orgUserPermissions;
};

interface OrgUserPermissionsContextProviderProps {
  children: ReactNode
}

type PermissionKey = keyof OrgUserPermission | keyof UserPermission;
type PermissionObject = Record<PermissionKey, boolean>;

export const OrgUserPermissionsContextProvider = ({
  children,
}: OrgUserPermissionsContextProviderProps): JSX.Element => {
  const data = useGetOrgUserPermissions({
    select: useCallback((tempData: HttpCallReturn<GetOrgUserPermissionsReturn>) => {
      const tempPermissions: PermissionObject = {} as PermissionObject;
      const { orgUser } = tempData.response;
      const { user } = tempData.response;
      const arrayPerms = [...orgUser, ...user];
      arrayPerms.forEach((permission) => {
        tempPermissions[permission as unknown as PermissionKey] = true;
      });
      return tempPermissions;
    }, []),
  });

  if (data.isLoading) {
    return (
      <div
        css={styles.loader}
      >
        <CircularProgress css={styles.circularProgress} />
      </div>
    );
  }

  if (data.isError) {
    return (<>Some error</>);
  }

  return (
    <OrgUserPermissionsContext.Provider value={data.data as PermissionObject}>
      {children}
    </OrgUserPermissionsContext.Provider>
  );
};
