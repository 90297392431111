import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Banner, { BannerProps } from '~Common/V3/components/Banner';
import IconContents from '~Common/V3/components/Banner/IconContents';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { hexToRGBA, palette } from '~Common/styles/colors';

const styles = {
  banner: css({
    backgroundColor: hexToRGBA(palette.brand.sky, 0.10),
    margin: '.625rem 0',
  }),
  icon: css({
    color: palette.brand.indigo,
  }),
  innerNoQuestionsText: css({
    color: palette.neutrals.gray800,
    fontWeight: 500,
  }),
};

const SelectTemplateBanner = ({
  ...props
}: Omit<BannerProps, 'renderContents'>): JSX.Element => (
  <Banner
    css={styles.banner}
    renderContents={() => (
      <IconContents
        renderIcon={() => (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="3x"
            css={styles.icon}
          />
        )}
        renderText={() => (
          <div css={styles.innerNoQuestionsText}>
            Select a template to add to the Learning Playlist
          </div>
        )}
      />
    )}
    {...props}
  />
);

export default SelectTemplateBanner;
