import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';
import {
  faCalendar,
  faCheckCircle,
  faCircleX,
  faPenToSquare,
} from '@fortawesome/pro-light-svg-icons';
import {
  DueDate,
  ItemStatus,
  LEADR_DUE_DATE_STATUS,
  SecondDate,
  useLeadrDueDate,
} from './useLeadrDueDate';

const styles = {
  leadrDueDate: (dueDateStatus: LEADR_DUE_DATE_STATUS) => css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    gap: '0.5rem',
  }, (dueDateStatus === LEADR_DUE_DATE_STATUS.PAST || dueDateStatus === LEADR_DUE_DATE_STATUS.YESTERDAY) && {
    color: palette.brand.red,
  }, dueDateStatus === LEADR_DUE_DATE_STATUS.TODAY_OR_TOMORROW && {
    color: palette.brand.indigo,
  }, (dueDateStatus === LEADR_DUE_DATE_STATUS.TODAY_OR_TOMORROW || dueDateStatus === LEADR_DUE_DATE_STATUS.YESTERDAY) && {
    fontWeight: 600,
  }),
  icon: (isComplete: boolean) => css(isComplete && ({
    color: palette.brand.green,
  })),
};

const iconStatusMap = {
  [LEADR_DUE_DATE_STATUS.FUTURE]: faCalendar,
  [LEADR_DUE_DATE_STATUS.TODAY_OR_TOMORROW]: faCalendar,
  [LEADR_DUE_DATE_STATUS.YESTERDAY]: faCalendar,
  [LEADR_DUE_DATE_STATUS.PAST]: faCalendar,
  [LEADR_DUE_DATE_STATUS.COMPLETE]: faCheckCircle,
  [LEADR_DUE_DATE_STATUS.DRAFT]: faPenToSquare,
  [LEADR_DUE_DATE_STATUS.CLOSED]: faCircleX,
  [LEADR_DUE_DATE_STATUS.NO_DUE_DATE]: faCalendar,
};

interface ViewProps {
  dueDateText: string,
  dueDateStatus: LEADR_DUE_DATE_STATUS,
  showIcon?: boolean,
}

const View = ({
  dueDateText,
  dueDateStatus,
  showIcon = true,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.leadrDueDate(dueDateStatus)} data-test-id="leadrDueDate" {...props}>
    {showIcon && (
      <FontAwesomeIcon css={styles.icon(dueDateStatus === LEADR_DUE_DATE_STATUS.COMPLETE)} icon={iconStatusMap[dueDateStatus]} />
    )}
    <div>{dueDateText}</div>
  </div>
);

interface LeadrDueDateProps extends Pick<ViewProps, 'showIcon'> {
  dueDate: DueDate,
  secondDate?: SecondDate,
  itemStatus?: ItemStatus,
}

const LeadrDueDate = ({
  dueDate,
  itemStatus = 'active',
  secondDate, // This is the completed/closed date, probably needs a better name, but didn't have time
  ...props
}: LeadrDueDateProps): JSX.Element => {
  // @ts-expect-error I can't figure out how to Typescript this right right now
  const { dueDateText, dueDateStatus } = useLeadrDueDate({ dueDate, itemStatus, secondDate });

  const hookProps = {
    dueDateText,
    dueDateStatus,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LeadrDueDate;
