import {
  Switch, Route,
} from 'react-router-dom';

import {
  FEEDBACK_RECEIVED, FEEDBACK_SENT,
} from '~Common/const/routes';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import FeedbackTopbar from '~Feedback/components/Topbars';
import FeedbackList from '~Feedback/components/index';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';

interface ViewProps {
  canRequestFeedback: boolean,
}

const View = ({
  canRequestFeedback,
}: ViewProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[
        FEEDBACK_RECEIVED,
      ]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<FeedbackTopbar initialRoute={FEEDBACK_RECEIVED} />}
            ChildComponent={<FeedbackList />}
          />
        </BaseLayout>
      )}
    />
    {canRequestFeedback && (
      <Route
        exact
        path={[
          FEEDBACK_SENT,
        ]}
        render={() => (
          <BaseLayout>
            <ModuleLandingPageWrapper
              TopComponent={<FeedbackTopbar initialRoute={FEEDBACK_SENT} />}
              ChildComponent={<FeedbackList />}
            />
          </BaseLayout>
        )}
      />
    )}
    <SmartRedirect from="*" to={FEEDBACK_RECEIVED} />
  </Switch>
);

const Feedback = (): JSX.Element => {
  const { canRequestFeedback } = useOrgUserPermissionsContext();

  const hookProps = {
    canRequestFeedback,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default Feedback;
