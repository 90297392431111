import { difference, startCase, toLower } from 'lodash';
import { getUserId } from '~Common/utils/localStorage';

export { emotionCache } from './emotion';

export const videoUrlRegex = '^((https?|ftp)://|(www|ftp)\\.)?[a-z0-9-]+(\\.[a-z0-9-]+)+([/?].*)?$';

export const baseUrlImages = process.env.NODE_ENV === 'production' ? 'https://d3ubevq7imeb7h.cloudfront.net/' : 'https://dms23a543d1ar.cloudfront.net/';

// @ts-expect-error Not gonna try and type this right now
export const buildQueryString = (queryParamsObj: Record<unknown, unknown>): string => `?${Object.entries(queryParamsObj)
  // @ts-expect-error Not gonna try and type this right now
  .filter(([, value]) => value !== undefined && value !== '' && (!(value?.length === 0)))
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  .map(([key, value]) => `${key}=${value}`)
  .join('&')}`;

// @ts-expect-error Not gonna try and type this right now
export const buildRequestBody = (queryParamsObj: Record<unknown, unknown>, allowBlanks = false): Record<string, unknown> => Object.entries(queryParamsObj)
  .filter(([, value]) => value !== undefined && (allowBlanks || value !== ''))
  .reduce((obj, [key, value]) => {
    // @ts-expect-error Not gonna try and type this right now
    // eslint-disable-next-line no-param-reassign
    obj[key] = value;
    return obj;
  }, {});

export const isCurrentUser = (userId: string): boolean => userId === getUserId();

export const isContentUrlLinkValid = (link: string): boolean => {
  if (link) {
    return !!link.match(videoUrlRegex);
  }
  return false;
};

export const toTitleCase = (string: string): string => startCase(toLower(string));

export const getArrayDifferenceAsNewArray = <T>(array1: T[], array2: T[]): T[] => difference(array1, array2);
