import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { CardSkeleton } from '~Common/V3/components/Card';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';

const styles = {
  learningContentTitleBox: css({
    marginTop: '2.5rem',
    marginBottom: '2.125rem',
  }),
  questionsSectionTitleText: css({
    color: palette.neutrals.gray500,
    fontSize: '.625rem',
    marginTop: '2.125rem',
    marginBottom: '.625rem',
  }),
  questionsBox: css({
    marginTop: '.625rem',
  }),
  afterQuestionSpace: css({
    marginBottom: '1.5rem',
  }),
  videoSkeleton: css({
    maxWidth: '100%',
    height: '18.25rem',
  }),
  titleBoxSkeleton: css({
    maxWidth: '100%',
    height: '6rem',
  }),
  questionSkeleton: css({
    maxWidth: '100%',
    height: '2rem',
  }),
};

export const SkeletonView = (): JSX.Element => (
  <div>
    <div>
      <SkeletonLoader
        variant="rectangular"
        css={styles.videoSkeleton}
        renderComponent={() => (
          <div
            css={styles.learningContentTitleBox}
          />
        )}
      />
    </div>
    <CardSkeleton css={[styles.titleBoxSkeleton, styles.learningContentTitleBox]} />
    <div css={styles.afterQuestionSpace}>
      <div css={styles.questionsSectionTitleText}>
        QUESTIONS
      </div>
      <MultipleSkeletonLoaders
        numberOfSkeletons={2}
        renderSkeletonItem={() => (
          <CardSkeleton css={[styles.questionsBox, styles.questionSkeleton]} />
        )}
      />
    </div>
  </div>
);
