import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';

const styles = {
  overflowStyle: (remHeight: number, remWidth: number) => css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${remHeight}rem`,
    width: `${remWidth}rem`,
    borderRadius: '0.3125rem',
    flexGrow: 0,
    textAlign: 'center',
    paddingInline: '0.5rem',
    color: palette.neutrals.white,
    backgroundColor: palette.neutrals.gray600,
  }),
  number: (remHeight: number, overflowNumber: number) => css({
    fontSize: `${remHeight / 2.25}rem`,
    lineHeight: `${remHeight / 2.25}rem`,
    marginTop: `${remHeight / 10}rem`,
  }, overflowNumber > 99 && {
    fontSize: `${remHeight / 2.5}rem`,
    lineHeight: `${remHeight / 2.5}rem`,
  }),
};

export interface OverflowAvatarProps {
  overflowNumber: number,
  avatarHeight?: number,
  avatarWidth?: number,
  onClick?: () => void,
}

/*
  This is not directly included in the AvatarMaybeMultipleMaybeNot component because:
  If you split it out like this and pass it into the renderOverflowAvatar,
  then you get direct access to passing css into there without having to pass prob objects to multiple things in the AvatarMaybeMultipleMaybeNot component
*/
const OverflowAvatar = ({
  overflowNumber,
  avatarHeight = 40,
  avatarWidth = 40,
  onClick,
  ...props
}: OverflowAvatarProps): JSX.Element => {
  const remHeight = avatarHeight / 16;
  const remWidth = avatarWidth / 16;

  return (
    <div
      css={styles.overflowStyle(remHeight, remWidth)}
      onClick={onClick}
      onKeyDown={onClick}
      role="presentation"
      {...props}
    >
      <div css={styles.number(remHeight, overflowNumber)}>{`+${overflowNumber}`}</div>
    </div>
  );
};

export default OverflowAvatar;
