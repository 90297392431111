import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { useDraft } from '~Common/hooks/useDraft';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { useShowCommentSection } from '~Meetings/hooks/useShowCommentSection';
import { meetingKeys } from '~Meetings/const/queryKeys';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { getLocalId } from '~Common/utils/uuid';
import { AgendaTopic, AgendaTopicComment, GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';
import { produce } from 'immer';
import moment from 'moment-timezone';
import { AgendaType } from '@leadr-hr/types';
import { useShowCommentEditor } from '~Meetings/hooks/useShowAgendaEditor';

interface CommentContext {
  id: string,
  meetingId: string,
  type: 'AGENDA_ITEM',
}

interface CommentItem {
  isPrivate: boolean,
  rank: number,
  text: string,
}

interface CreateCommentParams {
  context: CommentContext,
  eventId?: string,
  items: CommentItem[],
}

interface CreateCommentReturn {
  id: string,
  noteId: string,
}

const createComment = async ({
  ...comment
}: CreateCommentParams): Promise<HttpCallReturn<CreateCommentReturn>> => (
  postApi(`/organizations/${getOrganizationId() ?? ''}/notes`, comment, { 'realtime-version': '2' })
);

interface UseCreateCommentParams {
  agendaItemId: string,
  draftKey?: string[],
  sectionId?: string,
}

export const useCreateComment = ({
  agendaItemId,
  draftKey,
  sectionId,
}: UseCreateCommentParams): UseMutationResult<HttpCallReturn<CreateCommentReturn>, unknown, CreateCommentParams> => {
  const orgUserId = getOrganizationUserId() ?? '';
  const { person } = useGetPersonById({ orgUserId });
  const { setShowCommentEditor } = useShowCommentEditor(agendaItemId);
  const { setShowCommentSection } = useShowCommentSection(agendaItemId);
  const { removeDraft } = useDraft(draftKey);

  return useMutation({
    mutationFn: createComment,
    onMutate: async ({ context, items }) => {
      const { meetingId } = context ?? {};

      let isFirstNote = false;

      const previousAgendaTopics = queryClient.getQueryData(meetingKeys.agendaList(meetingId));

      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(meetingId) });

      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(meetingId), (originalData) => {
        if (originalData && person) {
          const newComment: AgendaTopicComment = {
            id: getLocalId(),
            type: AgendaType.Comment,
            text: items[0].text,
            creator: {
              firstName: person.firstName,
              lastName: person.lastName,
              orgUserId: person.orgUserId,
              profileImageUrl: person.profileImageUrl,
            },
            isPrivate: items[0].isPrivate,
            createdInMillis: moment().valueOf(),
            lastModifiedInMillis: 0,
            permissions: [],
          };

          if (sectionId) {
            // The comment is being added to an agenda item in a section
            // Find the section and push it there
            const sectionIndex = originalData.response.agendas.findIndex((agenda) => agenda.id === sectionId);
            const agendaTopicIndex = originalData.response.agendas[sectionIndex].children.findIndex((agenda) => agenda.id === agendaItemId);
            const agendaTopic = originalData.response.agendas[sectionIndex].children[agendaTopicIndex] as AgendaTopic;
            if (agendaTopic.children.length === 0) {
              isFirstNote = true;
            }
            if (sectionIndex !== -1 && agendaTopicIndex !== -1) {
              return produce(originalData, (draft) => {
                const tempAgendaTopic = draft.response.agendas[sectionIndex].children[agendaTopicIndex] as AgendaTopic;
                tempAgendaTopic.children.push(newComment);
              });
            }
          } else {
            const agendaTopicIndex = originalData.response.agendas.findIndex((agenda) => agenda.id === agendaItemId);
            if (originalData.response.agendas[agendaTopicIndex].children.length === 0) {
              isFirstNote = true;
            }
            return produce(originalData, (draft) => {
              const tempAgendaTopic = draft.response.agendas[agendaTopicIndex] as AgendaTopic;
              tempAgendaTopic.children.push(newComment);
            });
          }
        }

        return originalData;
      });

      setShowCommentEditor(false);

      if (isFirstNote) {
        setShowCommentSection(true);
      }

      return { previousAgendaTopics, isFirstNote };
    },
    onError: (_, { context }, snapshot) => {
      toast.error(<MeetingsErrorToast />, {
        autoClose: 1500,
      });

      const { meetingId } = context ?? {};

      queryClient.setQueryData(meetingKeys.agendaList(meetingId), snapshot?.previousAgendaTopics);

      setShowCommentSection(!snapshot?.isFirstNote);
    },
    onSuccess: () => {
      removeDraft();
    },
    onSettled: async (_, __, { context }) => {
      const { meetingId } = context ?? {};
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(meetingId) });
    },
  });
};
