import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { faArrowLeft, faTimes, faPaperPlaneAlt } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import moment from 'moment-timezone';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { DRAWER_STATE_SHAPE, SURVEY_SHAPE } from '~Common/const/proptypes';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
// eslint-disable-next-line import/no-cycle
import { createSurveyTemplate } from '~Surveys/Components/Drawers/Create/index';
import emptyParticipants from '~Assets/images/empty-views/emptyParticipants.png';
import { palette } from '~Common/styles/colors';
import AttendeeList from '~Common/V3/components/Attendees/AttendeesList';
import useSelectedAttendees from '~Deprecated/hooks/attendees/useSelectedAttendees';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import { RequestSendSurvey, useSendSurveyState } from '~Surveys/Hooks/useSendSurveyState';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { useCreateSurvey } from '../../../Hooks/useSurveysList';

export const createSurveyParticipants = {
  name: 'CREATE_SURVEY_PARTICIPANT_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  surveySnippet: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px 24px;
    box-shadow: 0px 4px 8px -2px rgba(28, 42, 55, 0.2);
    border-radius: 5px;
    color: ${palette.neutrals.gray700};
    font-size: .875rem;

    & > div{
        display:flex;
        width:100%;
        justify-content: space-between;
        flex-direction: column;

        & span{
            width:40%;
            display:block;
            text-transform: uppercase;
            font-weight: 400;
            font-size: .625rem;
            color: ${palette.neutrals.gray500}
        }
    }
  `,
  back: css`
    text-align: right;
    text-transform: uppercase;
    font-size: .625rem;
  `,
  surveyTitle: css`
    color: ${palette.neutrals.gray900};
    font-size: 1.25rem;
    font-weight: 500;
    margin: .625rem 0 0 0;
  `,
  emptyView: css`
    text-align: center;
    color: ${palette.brand.indigo};

    img{
        max-width:80%;
    }
    p{
        max-width: 65%;
        margin: -2rem auto 0 auto;
        font-size:.875rem;
    }
  `,
  surveyButton: css`
    border-radius: 3px;
    margin-top: 1.25rem;
    display: flex;
    align-content: center;
  `,
  saveIcon: css`
    color: ${palette.neutrals.white};

    & > div{
        width:18px !important;
        height:18px !important;
        transform: rotate(-45deg);

        & svg{
            width: inherit !important;
            height: inherit !important;
        }
    }
  `,
};

const View = ({
  onFormSubmit,
  closeButton,
  backButton,
  drawerTitle,
  drawerState,
  backDrawerClick,
  currentAttendees,
  createButton,
  isAttendeeSelected,
  onAttendeeSelect,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        title={drawerTitle}
        renderBackButton={backButton}
        renderCloseButton={closeButton}
      />
    )}
    renderBody={(defaultBodyStyles) => (
      <div css={defaultBodyStyles}>
        <div css={styles.surveySnippet}>
          <div>
            <span>Survey Content</span>
            <LeadrButton
              css={styles.back}
              onClick={backDrawerClick}
              variant="text"
              size="small"
              textButtonColor={palette.brand.blue}
              data-test-id="surveysParticipantsBackToSurvey"
            >
              Back to Survey
            </LeadrButton>
          </div>
          <h2 css={styles.surveyTitle}>{drawerState.survey?.title}</h2>
          {drawerState.survey?.objective && (
            <HTMLRenderer htmlText={drawerState.survey.objective} />
          )}
        </div>
        {typeof drawerState?.selectedAttendees === 'undefined' || drawerState.selectedAttendees?.length === 0 ? (
          <div css={styles.emptyView}>
            <img src={emptyParticipants} alt="You have no participants assigned." data-test-id="surveysEmptyParticipants" />
            <p>You have not added any participants yet. Please select the participants you would like to participate.</p>
          </div>
        ) : (
          <>
            <AttendeeList
              selectedAttendees={currentAttendees}
              isAttendeeSelected={isAttendeeSelected}
              isLoading={false}
              attendees={drawerState.selectedAttendees}
              onClick={onAttendeeSelect}
            />
            {createButton()}
          </>
        )}
      </div>
    )}
  />
);

View.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  closeButton: PropTypes.func.isRequired,
  backButton: PropTypes.func.isRequired,
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
  createButton: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  drawerTitle: PropTypes.string,
  backDrawerClick: PropTypes.func.isRequired,
  isAttendeeSelected: PropTypes.func.isRequired,
  onAttendeeSelect: PropTypes.func.isRequired,
};
View.defaultProps = {
  currentAttendees: [],
  drawerTitle: '',
};

const CreateSurveyParticipantDrawer = ({
  drawerState, setDrawerState, popDrawer, popAfter, pushDrawer, surveyContext, currentAttendees, ...props
}) => {
  const dispatch = useDispatch();

  const backDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...createSurveyTemplate,
      },
    }));
  };
  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const backButton = (closeButtonStyles) => (
    <IconButton onClick={backDrawerClick} tooltip="Back" type="button" icon={faArrowLeft} css={closeButtonStyles} />
  );

  const closeButton = (closeButtonStyles) => (
    <IconButton onClick={closeDrawerClick} tooltip="Close" type="button" icon={faTimes} css={closeButtonStyles} />
  );
  const [sendSurvey, setSendSurvey] = useSendSurveyState();

  const { createSurveyMutation, isLoading } = useCreateSurvey({ setSendSurvey });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createButton = useCallback(() => (
    <SpinnerButton
      type="submit"
      color="secondary"
      label={(
        <>
          <IconButton onClick={saveSurvey} type="button" css={styles.saveIcon} icon={faPaperPlaneAlt} size="small" tooltip={undefined} />
          Send Survey to Selected Participants
        </>
      )}
      loadingLabel="Sending Survey..."
      isLoading={isLoading}
      onClick={saveSurvey}
      disabled={sendSurvey === RequestSendSurvey.NOTALLOWED}
      css={styles.surveyButton}
    />
  ));

  const saveSurvey = () => {
    const newSurvey = drawerState;
    JSON.stringifyIfObject = function stringifyIfObject(obj) {
      if (typeof obj === 'object') {
        return JSON.stringify(obj);
      }
      return obj;
    };

    const mappedQuestions = newSurvey.surveyQuestions.map((question) => (
      { ...question, configuration: JSON.stringifyIfObject(question.configuration) }
    ));
    const survey = {
      title: newSurvey.survey.title,
      objective: newSurvey.survey.objective ?? '',
      ...(newSurvey.survey.due && { completeBy: moment(newSurvey.survey.due).format('L') }),
      isAnonymous: false, // Always going to be false for the time being, until phase 2 of surveys when we add in anonymous
      participants: newSurvey.selectedAttendees,
      questions: mappedQuestions,
    };
    createSurveyMutation(survey);
  };
  const {
    isAttendeeSelected,
    onAttendeeSelect,
  } = useSelectedAttendees({ drawerState, setDrawerState });

  const hookProps = {
    drawerName: createSurveyParticipants.name,
    drawerTitle: 'Survey Participants',
    closeButton,
    backButton,
    drawerState,
    createButton,
    backDrawerClick,
    isAttendeeSelected,
    onAttendeeSelect,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

CreateSurveyParticipantDrawer.propTypes = {
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  surveyContext: SURVEY_SHAPE,
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
  popDrawer: PropTypes.func.isRequired,
  popAfter: PropTypes.func.isRequired,
  pushDrawer: PropTypes.func.isRequired,
};

CreateSurveyParticipantDrawer.defaultProps = {
  surveyContext: null,
  currentAttendees: [],
};

registerDrawer({
  templateName: createSurveyParticipants.name,
  component: CreateSurveyParticipantDrawer,
});

export { View };
export default CreateSurveyParticipantDrawer;
