import { Fragment, useEffect, useState } from 'react';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert, useForm } from '../../../hooks';
import ContentListItem from './ContentListItem';
import Player from '../../Player';
import Alert from '../../Alert';
import {
  clearCreateContentResponse,
  clearMeetingSuccessResponse,
  sendResponseData,
} from '../../../redux/actions/contentActions';
import { isEditorEmpty } from '../../../redux/sagas/utils';
import { getUserId } from '~Common/utils/localStorage';
import contentImage from '~Assets/images/content.png';
import { getContentDetailsByIdDataService } from '~Deprecated/services/contentService';
import { contentStatusCompleted, contentStatusIncomplete } from '../Config';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { addToMeetingDrawerTemplate } from '~Learning/components/Shared/AddToMeetingDrawer';
import { isCurrentUser } from '~Common/utils';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';

// TODO: Rewrite this with new UI in meetings
/**
 * @deprecated Old UI
 */
const ContentItemComponent = (props) => {
  const { contentId, assigneeId } = props;
  const dispatch = useDispatch();
  const content = useSelector((response) => response.content);
  const { fields, handleChange, errors } = useForm();
  const { alertMessage, showAlertMessage } = useAlert();
  const [messageType, SetMessageType] = useState('');
  const [item, setContentDetails] = useState(null);

  const isCurrentUserAssignee = isCurrentUser(assigneeId);

  const onAddToNextMeetingClick = () => {
    dispatch(pushDrawerAction({
      drawer: {
        ...addToMeetingDrawerTemplate,
        args: {
          otherUserId: isCurrentUserAssignee ? item.owner.id : assigneeId,
          learningId: contentId,
        },
      },
    }));
  };

  useEffect(() => {
    dispatch(clearMeetingSuccessResponse());
    getContentDetailsByIdDataService({
      payload: { contentId, assigneeId },
    }).then((response) => {
      setContentDetails(response.response);
    });
  }, [contentId, assigneeId]);

  useEffect(() => {
    const { response } = content.assignContent;
    if (!response) {
      return;
    }
    const { success, error } = response;
    if (success) {
      SetMessageType('success');
      const { message } = success;
      if (message) {
        showAlertMessage(message);
      }
    } else if (error) {
      SetMessageType('error');
      const { message } = error;
      if (message) {
        showAlertMessage(message);
      }
    }
    if (success || error) {
      setTimeout(() => {
        dispatch(clearCreateContentResponse());
        showAlertMessage('');
      }, 3000);
    }
  }, [content.assignContent.response]);

  const getQuestionsByIdData = (data) => {
    const questionsAnswersArray = [];
    for (const key in data) {
      if (data[key]) {
        const obj = { questionId: key, answer: { text: data[key] } };
        questionsAnswersArray.push(obj);
      }
    }
    return questionsAnswersArray;
  };

  const onClickOfSendResponse = () => {
    if (Object.keys(fields).length !== 0 && Object.keys(errors).length === 0) {
      const payload = {};
      payload.contentId = item.id;
      payload.questions = fields;
      const questionsAnswersArray = getQuestionsByIdData(fields);
      dispatch(sendResponseData({
        answers: questionsAnswersArray,
        page: '',
      }));
      setContentDetails({
        ...item,
        status: contentStatusCompleted,
        questions: item.questions.map((i) => ({
          ...i,
          response: [questionsAnswersArray.find((ans) => ans.questionId === i.id).answer],
        })),
      });
    }
  };

  const validateResponse = () => {
    const keys = Object.keys(fields);
    // Looping through arrays created from Object.keys
    if (keys && keys.length !== 0 && Object.keys(errors).length === 0 && item.questions && item.questions.length === keys.length) {
      const data = [];
      for (const key of keys) {
        if (isEditorEmpty(fields[key])) {
          data.push(true);
        } else {
          data.push(false);
        }
      }
      const index = data.findIndex((d) => d === true);
      return index > -1;
    }
    return true;
  };

  const renderContent = (contentData) => {
    const { link } = contentData;
    if (link) {
      return (
        <div className="content-media">
          <div className="media-main">
            <Player
              link={link}
              type="content"
              title={contentData.name}
              layout={false}
              backgroundImage={contentImage}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const checkEditable = (libraryItem) => {
    if (libraryItem && libraryItem.assignee) {
      return ((libraryItem.assignee.id === getUserId()) && libraryItem.status === contentStatusIncomplete);
    }
    return false;
  };

  return (item && item.id) ? (
    <div className="content-list-wrapper content-card mb-3">
      <div className="meeting-with-content meeting-user-name-title content-header">
        <div className="meeting-title-ellipsis font-weight-bold content-title">{ item.title }</div>
        { item.dueDate && (
          <div className="due-font content-due-date-wrapper mt-1">
            <label className="content-due-label pr-1">Due</label>
            <span>{ moment(item.dueDate).format('MMM D') }</span>
          </div>
        ) }
      </div>
      {/* eslint-disable react/no-danger */ }
      { item.introduction && (
        <Fragment>
          <hr className="divider" />
          {isHTMLText(item.introduction) && (
            <HTMLRenderer htmlText={item.introduction} />
          )}
          {!isHTMLText && (
            <div>{ item.introduction }</div>
          )}
        </Fragment>
      ) }
      {/* eslint-enable react/no-danger */ }
      <hr className="divider" />
      <div className="ldr-scroller">
        <div className="awaiting-response-wrapper">
          <LeadrButton
            size="small"
            data-test-id="meetingContentAddToNextMeeting"
            onClick={onAddToNextMeetingClick}
          >
            <LeadrButton.IconAndText text="Add to Next Meeting" />
          </LeadrButton>
        </div>
        { alertMessage && <Alert type={messageType} message={alertMessage} /> }
        { renderContent(item) }
        <div className="pt-3 pl-3">Application Question</div>
        { item.questions
          ? item.questions.map((itemObj) => (
            <Fragment key={itemObj.id}>
              <ContentListItem
                page={props.page}
                queAnsData={itemObj}
                isEditable={
                  checkEditable(item)
                }
                handleChange={handleChange}
                ownerInfo={item.owner}
                assigneeInfo={item.assignee}
                answer=""
                isShowDate={!(item.assignee.id === getUserId() && item.status === contentStatusIncomplete)}
              />
              { item && item.owner && item.owner.id === getUserId() && item.assignee.id !== getUserId() && item.status === contentStatusIncomplete
                ? (
                  <div className="awaiting-response-wrapper">
                    <span
                      className="awaiting-response-msg"
                    >
                      Awaiting Response
                    </span>
                  </div>
                ) : null }
            </Fragment>
          ))
          : null }
        { ((item && item.assignee && item.assignee.id === getUserId()) && item.status === contentStatusIncomplete)
          ? (
            <div className="send-feedback-btn m-4 text-right">
              <button
                className="mat-button feedback-buttons btn btn-primary"
                disabled={validateResponse()}
                onClick={() => {
                  onClickOfSendResponse();
                }}
              >
                <FormattedMessage id="feedback.send" />
              </button>
            </div>
          ) : null }
      </div>
    </div>
  ) : null;
};
export default ContentItemComponent;
