import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ADMIN_PEOPLE } from '~Common/const/routes';
import { ANIMATION_TIME } from './BaseDrawer';

const style = {
  base: css`
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0);
    transition: backdrop-filter ${ANIMATION_TIME}ms ease-in;
  `,
  entering: css`
    backdrop-filter: blur(5px);
  `,
  entered: (isAdminPeople) => css`
    backdrop-filter: ${isAdminPeople ? 'blur(0)' : 'blur(5px)'};
  `,
  exiting: css`
    backdrop-filter: blur(0);
  `,
  exited: css`
    backdrop-filter: blur(0);
  `,
};

const View = ({
  className,
  closeAction,
  state,
  isAdminPeople,
}) => (
  <div
    css={[style.base, style[state](isAdminPeople)]}
    className={className}
    onClick={closeAction}
    onKeyDown={closeAction}
    tabIndex={0}
    role="button"
    aria-label="close overlay"
  />
);

View.propTypes = {
  className: PropTypes.string,
  closeAction: PropTypes.func.isRequired,
  state: PropTypes.string,
  isAdminPeople: PropTypes.bool,
};

View.defaultProps = {
  className: '',
  state: 'base',
  isAdminPeople: false,
};

const Backdrop = ({ className, closeAction, state }) => {
  const location = useLocation();
  const isAdminPeople = location.pathname === ADMIN_PEOPLE;

  const hookProps = {
    className,
    closeAction,
    state,
    isAdminPeople,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

Backdrop.propTypes = {
  className: PropTypes.string,
  closeAction: PropTypes.func.isRequired,
  state: PropTypes.string,
};

Backdrop.defaultProps = {
  className: '',
  state: 'base',
};

export { View };
export default Backdrop;
