import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { deleteApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { queryClient } from '~Common/const/queryClient';
import { GROUPS_LIST_QUERY_KEY } from './useGetGroups';

export const deleteUserGroup = (groupId) => {
  const URL = `/organizations/${getOrganizationId()}/ops/teams/${groupId}`;
  return deleteApi(URL);
};

export const useDeleteGroup = () => {
  const toastId = useRef(null);

  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: deleteUserGroup,
    onMutate: () => {
      toastId.current = toast.info('Deleting the group...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error deleting the group. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully deleted the group.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey: GROUPS_LIST_QUERY_KEY });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });
      dispatch(popDrawerAction({ popAll: true }));
    },
  });

  return mutation.mutate;
};
