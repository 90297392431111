interface ConditionalWrapperProps {
  renderWrapperCondition: boolean,
  renderWrapper: (renderContents: () => JSX.Element) => JSX.Element,
  renderContents: () => JSX.Element,
}

const ConditionalWrapper = ({
  renderWrapperCondition,
  renderWrapper,
  renderContents,
}: ConditionalWrapperProps): JSX.Element => (
  <>
    {renderWrapperCondition && renderWrapper(renderContents)}
    {!renderWrapperCondition && renderContents()}
  </>
);

export default ConditionalWrapper;
