import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useGenericStoreAndLocalStorage } from '~Common/hooks/useGenericStoreAndLocalStorage';
import { WhatsDueFilterOptions } from '~Home/const';

interface UseWhatsDueFilter {
  whatsDueFilter: WhatsDueFilterOptions;
  setWhatsDueFilter: (whatsDueFilter: WhatsDueFilterOptions) => void;
}
export function useWhatsDueFilter(): UseWhatsDueFilter {
  const useAllDueFilter = useFeatureFlag('webClientCombinedWhatsDueCard');

  const [whatsDueFilter, setWhatsDueFilter] = useGenericStoreAndLocalStorage<WhatsDueFilterOptions>({
    localStorageKey: 'whatsDueFilter',
    defaultValue: useAllDueFilter ? WhatsDueFilterOptions.AllDue : WhatsDueFilterOptions.ActionItems,
    genericStoreProps: {
      module: 'leadr',
      workflow: 'home',
      feature: 'whats-due-filter',
    },
  });

  return {
    whatsDueFilter,
    setWhatsDueFilter,
  };
}
