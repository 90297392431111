import { css } from '@emotion/react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SyntheticEvent } from 'react';
import { SelectOption, usePeopleTagPicker } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { UserGroup } from '~Common/const/userGroups';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { InlineSelect } from './InlineSelect';
import LeadrButton from './LeadrButtons/LeadrButton';

export type ParticipantWithRole<T> = {
  orgUserId: string,
  role: T,
}

const styles = {
  autocomplete: css({
    fieldset: {
      display: 'none',
    },
  }),
  autocompleteRow: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  }),
  autocompleteName: css({
    color: palette.neutrals.gray800,
    fontSize: '0.875rem',
    fontWeight: 500,
  }),
  autocompleteJobTitle: css({
    color: palette.neutrals.gray700,
    flex: 1,
    fontSize: '0.875rem',
    fontWeight: 300,
    textAlign: 'right',
  }),
  input: (isDisabled: boolean) => css({
    border: `1px solid ${palette.neutrals.gray300}`,
    borderRadius: '0.5rem',
    backgroundColor: palette.neutrals.white,
  }, isDisabled && {
    ':hover': {
      cursor: 'not-allowed',
    },
  }),
  label: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    fontWeight: 400,
  }),
  section: css({
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    padding: '0.5rem 0.75rem',
  }),
  participantList: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    padding: 0,
    margin: '0.5rem 0',
  }),
  participant: (isMobile: boolean) => css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',

    '& .removeButton': {
      color: palette.neutrals.gray600,
      paddingTop: '0.15rem',
      visibility: 'hidden',
    },

    '&:hover .removeButton': {
      visibility: 'visible',
    },
  }, isMobile && {
    '.removeButton': {
      visibility: 'visible',
    },
  }),
  participantName: css({
    color: palette.neutrals.gray800,
    fontSize: '0.875rem',
    fontWeight: 500,
  }),
  participantBuffer: css({
    flex: 1,
  }),
};

interface ViewProps<T extends string = string> {
  handleRemove: (orgUserId: string) => void,
  handleRoleChange: (orgUserId: string, newRole: T) => void,
  handleSelect: (event: SyntheticEvent, newValue: SelectOption) => void,
  isMobile: boolean,
  label: string,
  participantsWithPeopleData: Array<SelectOption & ParticipantWithRole<T>>,
  peopleList: SelectOption[],
  roles: Array<{
    value: T,
    label: string,
  }>,
  disableRoleSelection: boolean,
  disabled: boolean,
}

function View<T extends string = string>({
  handleRemove,
  handleRoleChange,
  handleSelect,
  isMobile,
  label,
  participantsWithPeopleData,
  peopleList,
  roles,
  disableRoleSelection,
  disabled,
}: ViewProps<T>): JSX.Element {
  return (
    <section css={styles.section}>
      <p css={styles.label}>
        {`${label} [${participantsWithPeopleData.length}]`}
      </p>
      <Autocomplete
        css={styles.autocomplete}
        disableClearable
        disabled={disabled}
        isOptionEqualToValue={() => false}
        onChange={handleSelect}
        options={peopleList}
        // @ts-expect-error : While this isn't type-correct, it's the right way to clear on selection.
        value={null}
        blurOnSelect
        popupIcon={<></>}
        getOptionDisabled={(option) => option.disabled === true}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              disableAnimation: true,
              shrink: true,
            }}
            placeholder="Add People"
            css={styles.input(disabled)}
          />
        )}
        renderOption={(optionProps, option) => (
          <li
            {...optionProps}
            key={option.value}
            css={styles.autocompleteRow}
          >
            <SquareAvatar
              // css={styles.tagsProfileImage}
              imgUrl={option.profileImageUrl}
              name={option.label}
              width={22}
              height={22}
            />
            <p css={styles.autocompleteName}>
              {option.label}
            </p>
            {!isMobile && (
              <p
                css={styles.autocompleteJobTitle}
              >
                {option.jobTitle}
              </p>
            )}
          </li>
        )}
      />
      {participantsWithPeopleData.length > 0 && (
        <ul css={styles.participantList}>
          {participantsWithPeopleData.map((participant) => (
            <li css={styles.participant(isMobile)}>
              <SquareAvatar
                imgUrl={participant.profileImageUrl}
                name={participant.label}
                width={22}
                height={22}
              />
              <p css={styles.participantName}>
                {participant.label}
              </p>
              <LeadrButton
                className="removeButton"
                variant="text"
                data-test-id={`participantAndRoleSelector-${participant.orgUserId}-remove`}
                onClick={() => handleRemove(participant.orgUserId)}
              >
                <FontAwesomeIcon icon={faXmarkCircle} />
              </LeadrButton>
              {!disableRoleSelection && (
                <>
                  <div css={styles.participantBuffer} />
                  <InlineSelect
                    onChange={(newValue) => handleRoleChange(participant.orgUserId, newValue)}
                    options={roles}
                    value={participant.role}
                  />
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}

interface ParticipantAndRoleSelectorProps<T extends string> {
  disabledIds?: string[],
  roles: Array<{
    value: T,
    label: string,
  }>,
  label: string,
  onChange: (newParticipants: ParticipantWithRole<T>[]) => void,
  participants?: ParticipantWithRole<T>[],
  disableRoleSelection?: boolean,
  disabled?: boolean,
  disableLimitedAccessUsers?: boolean,
}

// Formatting as a function because the const version of generics makes JSX sad.
export function ParticipantAndRoleSelector<T extends string = string>({
  disabledIds = [],
  label,
  roles = [],
  onChange,
  participants = [],
  disableRoleSelection = false,
  disabled = false,
  disableLimitedAccessUsers = false,
}: ParticipantAndRoleSelectorProps<T>): JSX.Element {
  const isMobile = useIsMobileQuery();
  const participantOrgUserIds = participants.map((participant) => participant.orgUserId);
  const allowedRoles = roles.map((role) => role.value as string);
  const filteredParticipants = participants.filter((participant) => allowedRoles.includes(participant.role as string));
  const accountTypesToInclude = disableLimitedAccessUsers
    ? [UserGroup.Admin, UserGroup.Member, UserGroup.Executive]
    : [UserGroup.Admin, UserGroup.Member, UserGroup.LimitedAccess, UserGroup.Executive];

  const {
    peopleList = [],
  } = usePeopleTagPicker({
    administrativeStatusesToInclude: ['ACTIVE', 'INVITED'],
    allowSelf: true,
    disabledIds: [
      ...participantOrgUserIds,
      ...disabledIds,
    ],
    accountTypesToInclude,
  });

  const participantsWithPeopleData = filteredParticipants.reduce((result, participant) => {
    const personData = peopleList.find((person) => person.value === participant.orgUserId);

    if (personData) {
      result.push({
        ...participant,
        ...personData,
      });
    }

    return result;
  }, [] as Array<SelectOption & ParticipantWithRole<T>>);
  participantsWithPeopleData.sort((a, b) => a.label.localeCompare(b.label));

  const handleSelect = (event: SyntheticEvent, newValue: SelectOption): void => {
    const orgUserId = newValue.value;
    participants.push({
      orgUserId,
      role: roles[0].value,
    });
    onChange([...participants]);
  };

  const handleRemove = (orgUserId: string): void => {
    const participantIndex = participants.findIndex((participant) => participant.orgUserId === orgUserId);
    if (participantIndex === -1) {
      return;
    }

    const newParticipants = [...participants];
    newParticipants.splice(participantIndex, 1);
    onChange(newParticipants);
  };

  const handleRoleChange = (orgUserId: string, newRole: T): void => {
    const participantIndex = participants.findIndex((participant) => participant.orgUserId === orgUserId);
    if (participantIndex === -1) {
      return;
    }

    const newParticipants = [...participants];
    newParticipants[participantIndex].role = newRole;
    onChange(newParticipants);
  };

  const hookProps = {
    allowedRoles,
    handleRemove,
    handleRoleChange,
    handleSelect,
    isMobile,
    label,
    participantsWithPeopleData,
    peopleList,
    roles,
    disableRoleSelection,
    disabled,
  };

  return (
    <View
      {...hookProps}
    />
  );
}
