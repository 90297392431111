import { useGetReceivedLearnings } from '~Learning/hooks/received/useGetReceivedLearnings';
import { WhatsDueFilterOptions } from '~Home/const';
import { faBook } from '@fortawesome/pro-regular-svg-icons';

import moment from 'moment-timezone';
import { UseTransformItemsReturn } from '~Home/const/interfaces';
import { onLearningsItemClickHandler } from '~Home/const/WhatsDueItemClickHandlers';
import { LearningStatus } from '~Learning/const/interfaces';

export const useTransformLearning = ():UseTransformItemsReturn => {
  const { isLoading, data: receivedLearnings } = useGetReceivedLearnings();
  const incompleteLearnings = receivedLearnings?.filter((learning) => learning.status !== LearningStatus.COMPLETED);
  const transformedLearning = incompleteLearnings?.map((learning) => ({
    type: WhatsDueFilterOptions.Learning,
    text: learning.title,
    iconToRender: faBook,
    dueDate: learning.dueDate ? moment(learning.dueDate) : undefined,
    onItemClick: onLearningsItemClickHandler,
    id: learning.id.toString(),
    learningType: learning.learningType,
    status: learning.status,
  }));
  return {
    isLoading,
    data: transformedLearning ?? [],
  };
};
