import { Types } from 'ably';
import { useCallback } from 'react';

import useRealtime from '~Common/hooks/useRealtime';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
// import useAgendaTopics, { optimisticAgendaTopicDelete, optimisticAgendaTopicUpdate } from '~Meetings/hooks/useAgendaTopics';
import { queryClient } from '~Common/const/queryClient';
import {
  AgendaRealTimeMessageType,
  AgendaCommentRealTimeMessageType,
  AgendaTopicRealTimeMessageType,
  MeetingRealTimeMessagePrimaryType,
  // RealTimeMeetingMessagePayloadMap,
  AgendaTopicCreatePayload,
  AgendaTopicUpdatePayload,
  AgendaCommentCreatePayload,
  AgendaCommentUpdatePayload,
  AgendaTopicMessage,
  AgendaCommentMessage,
  AgendaReorderPayload,
  // AgendaTopicCreateSuccessPayload,
  AgendaTopicUpdateMessage,
  AgendaTopicUpdateSuccessMessage,
  AgendaTopicUpdateCanceledMessage,
  AgendaTopicUpdateStatusSuccessMessage,
  AgendaTopicCreateMessage,
  AgendaTopicCreateCanceledMessage,
  AgendaTopicCreateSuccessMessage,
  AgendaReorderMessage,
  AgendaReorderSuccessMessage,
  AgendaReorderCanceledMessage,
  AgendaCommentUpdateMessage,
  AgendaCommentUpdateSuccessMessage,
  AgendaCommentUpdateCanceledMessage,
  AgendaCommentCreateMessage,
  AgendaCommentCreateSuccessMessage,
  AgendaCommentCreateCanceledMessage,
  // AgendaCommentCreateSuccessPayload,
  // AgendaCommentDeletedPayload,
  AgendaSectionMessage,
  AgendaSectionRealTimeMessageType,
  AgendaGenericMessage,
} from '~Meetings/const/realTimeMeetingMessages';
// import { Person } from '~Common/const/interfaces';
import {
  useMeetingRealTimeStore,
  useRealtimePresence,
  useRealtimeCreatingAgenda,
  useRealtimeUpdatingAgenda,
  useRealtimeReorderingAgenda,
} from '~Meetings/stores/useMeetingRealtimeStore';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { meetingKeys } from '~Meetings/const/queryKeys';

export interface HandleAgendaTopicCreateMessageProps {
  huddleId: string,
  isAdmin: boolean,
  message: AgendaTopicCreateMessage | AgendaTopicCreateSuccessMessage | AgendaTopicCreateCanceledMessage,
  addRealtimeCreatingAgenda: (payload: AgendaTopicCreatePayload) => void,
  removeRealtimeCreatingAgenda: (eventId: string, parentIds: string[]) => void,
}

export const handleAgendaTopicCreateMessage = async ({
  huddleId,
  // isAdmin,
  message,
  addRealtimeCreatingAgenda,
  removeRealtimeCreatingAgenda,
}: HandleAgendaTopicCreateMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaTopicRealTimeMessageType.Create:
      addRealtimeCreatingAgenda(message.data);
      break;
    case AgendaTopicRealTimeMessageType.CreateSuccess:
      if (!message.data.isPrivate) {
        // ToDo: Optimistic update
        // await optimisticAgendaTopicUpdate({
        //   huddleId,
        //   agendaTopic: payloadToAgendaTopic({
        //     payload: message.data,
        //     isAdmin,
        //   }),
        // });
      }
      removeRealtimeCreatingAgenda(message.data.eventId, message.data.parentIds);
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    case AgendaTopicRealTimeMessageType.CreateCanceled:
      removeRealtimeCreatingAgenda(message.data.eventId, message.data.parentIds);
      break;
    default:
      break;
  }
};

export interface HandleAgendaTopicUpdateMessageProps {
  huddleId: string,
  // agendaTopics: AgendaTopic[],
  message: AgendaTopicUpdateMessage | AgendaTopicUpdateSuccessMessage | AgendaTopicUpdateCanceledMessage | AgendaTopicUpdateStatusSuccessMessage,
  addRealtimeUpdatingAgenda: (payload: AgendaTopicUpdatePayload) => void,
  removeRealtimeUpdatingAgenda: (eventId: string, agendaTopicId: string, parentIds: string[]) => void,
}

export const handleAgendaTopicUpdateMessage = async ({
  huddleId,
  // agendaTopics,
  message,
  addRealtimeUpdatingAgenda,
  removeRealtimeUpdatingAgenda,
}: HandleAgendaTopicUpdateMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaTopicRealTimeMessageType.Update:
      addRealtimeUpdatingAgenda(message.data);
      break;
    case AgendaTopicRealTimeMessageType.UpdateSuccess:
      // ToDo: Optimistic update
      // await optimisticAgendaTopicUpdate({
      //   huddleId,
      //   agendaTopic: updateAgendaTopic({
      //     agendaTopics,
      //     agendaItemId: message.data.id,
      //     changes: {
      //       isPrivate: message.data.isPrivate,
      //       isRecurring: message.data.isRecurring,
      //       lastModifiedInMillis: message.data.lastModifiedInMillis,
      //       text: message.data.text,
      //     },
      //   }),
      // });

      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      removeRealtimeUpdatingAgenda(message.data.eventId, message.data.id, message.data.parentIds);
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    case AgendaTopicRealTimeMessageType.UpdateStatusSuccess:
      // ToDo: Optimistic update
      // await optimisticAgendaTopicUpdate({
      //   huddleId,
      //   agendaTopic: updateAgendaTopic({
      //     agendaTopics,
      //     agendaItemId: message.data.id,
      //     changes: {
      //       isComplete: message.data.isComplete,
      //     },
      //   }),
      // });
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    case AgendaTopicRealTimeMessageType.UpdateCanceled:
      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      removeRealtimeUpdatingAgenda(message.data.eventId, message.data.id, message.data.parentIds);
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    default:
      break;
  }
};

export interface HandleAgendaTopicMessageProps {
  huddleId: string,
  isAdmin: boolean,
  // agendaTopics: AgendaTopic[],
  message: AgendaTopicMessage,
  addRealtimeCreatingAgenda: (payload: AgendaCommentCreatePayload) => void,
  removeRealtimeCreatingAgenda: (eventId: string) => void,
  addRealtimeUpdatingAgenda: (payload: AgendaCommentUpdatePayload) => void,
  removeRealtimeUpdatingAgenda: (eventId: string, id: string, parentIds: string[]) => void,
  // addRealtimeReorderingAgenda: (payload: AgendaReorderPayload) => void,
  // removeRealtimeReorderingAgenda: (eventId: string, id: string, parentIds: string[]) => void,
}

export const handleAgendaTopicMessage = async ({
  huddleId,
  isAdmin,
  // agendaTopics,
  message,
  addRealtimeCreatingAgenda,
  removeRealtimeCreatingAgenda,
  addRealtimeUpdatingAgenda,
  removeRealtimeUpdatingAgenda,
}: HandleAgendaTopicMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaTopicRealTimeMessageType.Create:
    case AgendaTopicRealTimeMessageType.CreateSuccess:
    case AgendaTopicRealTimeMessageType.CreateCanceled:
      await handleAgendaTopicCreateMessage({
        huddleId,
        isAdmin,
        message,
        // @ts-expect-error ToDo: Fix
        addRealtimeCreatingAgenda,
        removeRealtimeCreatingAgenda,
      });
      break;
    case AgendaTopicRealTimeMessageType.Update:
    case AgendaTopicRealTimeMessageType.UpdateSuccess:
    case AgendaTopicRealTimeMessageType.UpdateStatusSuccess:
    case AgendaTopicRealTimeMessageType.UpdateCanceled:
      await handleAgendaTopicUpdateMessage({
        // agendaTopics,
        huddleId,
        message,
        // @ts-expect-error ToDo: Fix
        addRealtimeUpdatingAgenda,
        removeRealtimeUpdatingAgenda,
      });
      break;
    case AgendaTopicRealTimeMessageType.Deleted:
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      // ToDo: Optimistic update
      // await optimisticAgendaTopicDelete({
      //   huddleId,
      //   agendaTopicId: message.data.id,
      // });
      break;
    default:
      break;
  }
};

export interface HandleAgendaTopicCommentCreateMessageProps {
  // agendaTopics: AgendaTopic[],
  huddleId: string,
  message: AgendaCommentCreateMessage | AgendaCommentCreateSuccessMessage | AgendaCommentCreateCanceledMessage,
  addRealtimeCreatingAgenda: (payload: AgendaCommentCreatePayload) => void,
  removeRealtimeCreatingAgenda: (eventId: string, parentIds: string[]) => void,
}

export const handleAgendaTopicCommentCreateMessage = async ({
  // agendaTopics,
  huddleId,
  message,
  addRealtimeCreatingAgenda,
  removeRealtimeCreatingAgenda,
}: HandleAgendaTopicCommentCreateMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaCommentRealTimeMessageType.Create:
      addRealtimeCreatingAgenda(message.data);
      break;
    case AgendaCommentRealTimeMessageType.CreateSuccess:
      // ToDo: Optimistic update
      // await optimisticAgendaTopicUpdate({
      //   huddleId,
      //   agendaTopic: AddAgendaTopicNote({
      //     agendaTopics,
      //     payload: message.data,
      //   }),
      // });

      removeRealtimeCreatingAgenda(message.data.eventId, message.data.parentIds);
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    case AgendaCommentRealTimeMessageType.CreateCanceled:
      removeRealtimeCreatingAgenda(message.data.eventId, message.data.parentIds);
      break;
    default:
      break;
  }
};

export interface HandleAgendaTopicCommentUpdateMessageProps {
  // agendaTopics: AgendaTopic[],
  huddleId: string,
  message: AgendaCommentUpdateMessage | AgendaCommentUpdateSuccessMessage | AgendaCommentUpdateCanceledMessage,
  addRealtimeUpdatingAgenda: (payload: AgendaCommentUpdatePayload) => void,
  removeRealtimeUpdatingAgenda: (eventId: string, parentIds: string[]) => void,
}

export const handleAgendaTopicCommentUpdateMessage = async ({
  // agendaTopics,
  message,
  huddleId,
  addRealtimeUpdatingAgenda,
  removeRealtimeUpdatingAgenda,
}: HandleAgendaTopicCommentUpdateMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaCommentRealTimeMessageType.Update:
      addRealtimeUpdatingAgenda(message.data);
      break;
    case AgendaCommentRealTimeMessageType.UpdateSuccess:
      // ToDo: Optimistic update
      // await optimisticAgendaTopicUpdate({
      //   huddleId,
      //   agendaTopic: UpdateAgendaTopicNote({
      //     agendaTopics,
      //     agendaItemId: message.data.id,
      //     noteId: message.data.id,
      //     changes: {
      //       text: message.data.text,
      //       lastModifiedInMillis: message.data.lastModifiedInMillis,
      //     },
      //   }),
      // });

      removeRealtimeUpdatingAgenda(message.data.eventId, message.data.parentIds);
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    case AgendaCommentRealTimeMessageType.UpdateCanceled:
      removeRealtimeUpdatingAgenda(message.data.eventId, message.data.parentIds);
      break;
    default:
      break;
  }
};

export interface HandleAgendaCommentMessageProps {
  // agendaTopics: AgendaTopic[],
  huddleId: string,
  message: AgendaCommentMessage,
  addRealtimeCreatingAgenda: (payload: AgendaCommentCreatePayload) => void,
  removeRealtimeCreatingAgenda: (eventId: string) => void,
  addRealtimeUpdatingAgenda: (payload: AgendaCommentUpdatePayload) => void,
  removeRealtimeUpdatingAgenda: (eventId: string, parentIds: string[]) => void,
}

export const handleAgendaCommentMessage = async ({
  // agendaTopics,
  huddleId,
  message,
  addRealtimeCreatingAgenda,
  removeRealtimeCreatingAgenda,
  addRealtimeUpdatingAgenda,
  removeRealtimeUpdatingAgenda,
}: HandleAgendaCommentMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaCommentRealTimeMessageType.Create:
    case AgendaCommentRealTimeMessageType.CreateSuccess:
    case AgendaCommentRealTimeMessageType.CreateCanceled:
      await handleAgendaTopicCommentCreateMessage({
        // agendaTopics,
        huddleId,
        message,
        addRealtimeCreatingAgenda,
        removeRealtimeCreatingAgenda,
      });
      break;
    case AgendaCommentRealTimeMessageType.Update:
    case AgendaCommentRealTimeMessageType.UpdateSuccess:
    case AgendaCommentRealTimeMessageType.UpdateCanceled:
      await handleAgendaTopicCommentUpdateMessage({
        // agendaTopics,
        huddleId,
        message,
        addRealtimeUpdatingAgenda,
        removeRealtimeUpdatingAgenda,
      });
      break;
    case AgendaCommentRealTimeMessageType.Deleted:
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      // ToDo: Optimistic update
      // await optimisticAgendaTopicUpdate({
      //   huddleId,
      //   agendaTopic: RemoveAgendaTopicNote({
      //     agendaTopics,
      //     payload: message.data,
      //   }),
      // });
      break;
    default:
      break;
  }
};

export interface HandleAgendaSectionCreateMessageProps {
  huddleId: string,
  message: AgendaSectionMessage,
  addRealtimeCreatingAgenda: (payload: AgendaCommentCreatePayload) => void,
  removeRealtimeCreatingAgenda: (eventId: string) => void,
}

export const handleAgendaSectionCreateMessage = async ({
  huddleId,
  message,
  addRealtimeCreatingAgenda,
  removeRealtimeCreatingAgenda,
}: HandleAgendaSectionCreateMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaSectionRealTimeMessageType.Create:
      // @ts-expect-error ToDo: Fix
      addRealtimeCreatingAgenda(message.data);
      break;
    case AgendaSectionRealTimeMessageType.CreateSuccess:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      removeRealtimeCreatingAgenda(message.data.eventId);
      break;
    case AgendaSectionRealTimeMessageType.CreateCanceled:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      removeRealtimeCreatingAgenda(message.data.eventId);
      break;
    default:
      break;
  }
};

export interface HandleAgendaSectionUpdateMessageProps {
  huddleId: string,
  message: AgendaSectionMessage,
  addRealtimeUpdatingAgenda: (payload: AgendaCommentUpdatePayload) => void,
  removeRealtimeUpdatingAgenda: (eventId: string, id: string, parentIds: string[]) => void,
}

export const handleAgendaSectionUpdateMessage = async ({
  huddleId,
  message,
  addRealtimeUpdatingAgenda,
  removeRealtimeUpdatingAgenda,
}: HandleAgendaSectionUpdateMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaSectionRealTimeMessageType.Update:
      // @ts-expect-error ToDo: Fix
      addRealtimeUpdatingAgenda(message.data);
      break;
    case AgendaSectionRealTimeMessageType.UpdateSuccess:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      removeRealtimeUpdatingAgenda(message.data.eventId, message.data.id, []);
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    case AgendaSectionRealTimeMessageType.UpdateCanceled:
      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      removeRealtimeUpdatingAgenda(message.data.eventId, message.data.id, []);
      break;
    default:
      break;
  }
};

export interface HandleAgendaSectionMessageProps {
  huddleId: string,
  message: AgendaSectionMessage,
  addRealtimeCreatingAgenda: (payload: AgendaCommentCreatePayload) => void,
  removeRealtimeCreatingAgenda: (eventId: string) => void,
  addRealtimeUpdatingAgenda: (payload: AgendaCommentUpdatePayload) => void,
  removeRealtimeUpdatingAgenda: (eventId: string, id: string, parentIds: string[]) => void,
}

export const handleAgendaSectionMessage = async ({
  huddleId,
  message,
  addRealtimeCreatingAgenda,
  removeRealtimeCreatingAgenda,
  addRealtimeUpdatingAgenda,
  removeRealtimeUpdatingAgenda,
}: HandleAgendaSectionMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaSectionRealTimeMessageType.Create:
    case AgendaSectionRealTimeMessageType.CreateSuccess:
    case AgendaSectionRealTimeMessageType.CreateCanceled:
      await handleAgendaSectionCreateMessage({
        huddleId,
        message,
        addRealtimeCreatingAgenda,
        removeRealtimeCreatingAgenda,
      });
      break;
    case AgendaSectionRealTimeMessageType.Update:
    case AgendaSectionRealTimeMessageType.UpdateSuccess:
    case AgendaSectionRealTimeMessageType.UpdateCanceled:
      await handleAgendaSectionUpdateMessage({
        huddleId,
        message,
        addRealtimeUpdatingAgenda,
        removeRealtimeUpdatingAgenda,
      });
      break;
    case AgendaSectionRealTimeMessageType.Deleted:
      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      removeRealtimeUpdatingAgenda(message.data.eventId, message.data.id, []);
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    default:
      break;
  }
};

export interface HandleAgendaReorderMessageProps {
  huddleId: string,
  message: AgendaReorderMessage | AgendaReorderSuccessMessage | AgendaReorderCanceledMessage,
  addRealtimeReorderingAgenda: (payload: AgendaReorderPayload) => void,
  removeRealtimeReorderingAgenda: (eventId: string, agendaTopicId: string, parentIds: string[]) => void,
}

export const handleAgendaReorderMessage = async ({
  huddleId,
  message,
  addRealtimeReorderingAgenda,
  removeRealtimeReorderingAgenda,
}: HandleAgendaReorderMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaRealTimeMessageType.Reorder:
      addRealtimeReorderingAgenda(message.data);
      break;
    case AgendaRealTimeMessageType.ReorderSuccess:
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      removeRealtimeReorderingAgenda(message.data.eventId, message.data.id, message.data.parentIds);
      break;
    case AgendaRealTimeMessageType.ReorderCanceled:
      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      removeRealtimeReorderingAgenda(message.data.eventId, message.data.id, message.data.parentIds);
      break;
    default:
      break;
  }
};

export interface HandleAgendaGenericMessageProps {
  huddleId: string,
  message: AgendaGenericMessage,
  addRealtimeReorderingAgenda: (payload: AgendaReorderPayload) => void,
  removeRealtimeReorderingAgenda: (eventId: string, agendaTopicId: string, parentIds: string[]) => void,
}

export const handleAgendaGenericMessage = async ({
  huddleId,
  message,
  addRealtimeReorderingAgenda,
  removeRealtimeReorderingAgenda,
}: HandleAgendaGenericMessageProps): Promise<void> => {
  switch (message.name) {
    case AgendaRealTimeMessageType.Reorder:
    case AgendaRealTimeMessageType.ReorderSuccess:
    case AgendaRealTimeMessageType.ReorderCanceled:
      await handleAgendaReorderMessage({
        huddleId,
        message,
        addRealtimeReorderingAgenda,
        removeRealtimeReorderingAgenda,
      });
      break;
    case AgendaRealTimeMessageType.BulkCreated:
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      break;
    default:
      break;
  }
};

export interface UseMeetingRealtimeProps {
  huddleId: string,
  enabled?: boolean
}

const useMeetingRealtimeMessageHandler = ({
  huddleId,
  enabled = true,
}: UseMeetingRealtimeProps): void => {
  const organizationId = getOrganizationId();
  const orgUserId = getOrganizationUserId();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const channelName = `organizations:${organizationId!}:meetingInstances:${huddleId}`;
  const webClientRealTimeMeetings = useFeatureFlag('webClientRealTimeMeetings');
  const {
    isAdmin,
    isLoading: arePermissionsLoading,
  } = useUserPermissions();

  const resetMeetingRealtimeStore = useMeetingRealTimeStore((state) => (state.reset));
  const { addActiveUser, removeActiveUser } = useRealtimePresence(huddleId);
  const { addRealtimeCreatingAgenda, removeRealtimeCreatingAgenda } = useRealtimeCreatingAgenda(huddleId);
  const { addRealtimeUpdatingAgenda, removeRealtimeUpdatingAgenda } = useRealtimeUpdatingAgenda(huddleId);
  const { addRealtimeReorderingAgenda, removeRealtimeReorderingAgenda } = useRealtimeReorderingAgenda(huddleId);

  // const { data: agendaTopicsData } = useAgendaTopics({ huddleId });
  // const { data } = useGetAgendas({ huddleId, enabled });

  const onPresenceChange = useCallback((message: Types.PresenceMessage): void => {
    switch (message.action) {
      case 'present':
      case 'enter':
        addActiveUser(message.clientId);
        break;
      case 'leave':
        removeActiveUser(message.clientId);
        break;
      default:
        break;
    }
  }, [
    addActiveUser,
    removeActiveUser,
  ]);

  const listener = useCallback((message: Types.Message) => {
    const [primaryType] = message.name.split(':');

    /*
      ToDo: Eventually we should come up with a better way to filter out success messages
    */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!message.data.orgUserId || message.data.orgUserId !== orgUserId) {
      switch (primaryType) {
        case MeetingRealTimeMessagePrimaryType.AgendaTopic:
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          handleAgendaTopicMessage({
            isAdmin,
            // Data will always be defined as the channel isn't opened until we have the data
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            // agendaTopics: data!.response,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            huddleId,
            message: message as AgendaTopicMessage,
            addRealtimeCreatingAgenda,
            removeRealtimeCreatingAgenda,
            addRealtimeUpdatingAgenda,
            removeRealtimeUpdatingAgenda,
          }).catch(() => {
            // ToDo: Handle catch
          });
          break;
        case MeetingRealTimeMessagePrimaryType.AgendaComment:
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          handleAgendaCommentMessage({
            // Data will always be defined as the channel isn't opened until we have the data
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            // agendaTopics: agendaTopicsData!.response,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            huddleId,
            message: message as AgendaCommentMessage,
            addRealtimeCreatingAgenda,
            removeRealtimeCreatingAgenda,
            addRealtimeUpdatingAgenda,
            // @ts-expect-error TODO: fix
            removeRealtimeUpdatingAgenda,
          }).catch(() => {
            // ToDo: Handle catch
          });
          break;
        case MeetingRealTimeMessagePrimaryType.AgendaSection:
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          handleAgendaSectionMessage({
            huddleId,
            message: message as AgendaSectionMessage,
            addRealtimeCreatingAgenda,
            removeRealtimeCreatingAgenda,
            addRealtimeUpdatingAgenda,
            removeRealtimeUpdatingAgenda,
          }).catch(() => {
            // ToDo: Handle catch
          });
          break;
        default:
          // ToDo: Generic message handling
          handleAgendaGenericMessage({
            huddleId,
            message: message as AgendaGenericMessage,
            addRealtimeReorderingAgenda,
            removeRealtimeReorderingAgenda,
          }).catch(() => {
            // ToDo: Handle catch
          });
          break;
      }
    }
  }, [
    huddleId,
    orgUserId,
    isAdmin,
    addRealtimeCreatingAgenda,
    removeRealtimeCreatingAgenda,
    addRealtimeUpdatingAgenda,
    removeRealtimeUpdatingAgenda,
    addRealtimeReorderingAgenda,
    removeRealtimeReorderingAgenda,
    // agendaTopicsData,
  ]);

  const onChannelClose = useCallback(() => {
    resetMeetingRealtimeStore();
  }, [
    resetMeetingRealtimeStore,
  ]);

  useRealtime({
    channelName,
    onPresenceChange,
    listener,
    closeOnUnmount: true,
    onChannelClose,
    // Avoid improperly handled messages due to messages coming in before
    // we have the data to process them
    enabled: enabled && webClientRealTimeMeetings && !arePermissionsLoading,
  });
};

export default useMeetingRealtimeMessageHandler;
