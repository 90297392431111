import { learningQueryKeys } from '../queryKeys';

export const receivedLearningQueryKeys = {
  all: [...learningQueryKeys.all, 'received'] as const,
  list: () => [...receivedLearningQueryKeys.all, 'list'] as const,
  details: () => [...receivedLearningQueryKeys.list(), 'detail'] as const,
  singleContentDetails: () => [...receivedLearningQueryKeys.details(), 'single content'] as const,
  singleContentDetail: (learningId: string) => [...receivedLearningQueryKeys.singleContentDetails(), learningId] as const,
  playlistDetails: () => [...receivedLearningQueryKeys.details(), 'playlist'] as const,
  playlistDetail: (playlistId: number) => [...receivedLearningQueryKeys.playlistDetails(), playlistId.toString()] as const,
};
