import { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { nearestFutureMinutes } from '~Deprecated/utils/meeting';

export function useTime(baseTime, roundAmount = 0) {
  const mDateTime = baseTime ? nearestFutureMinutes(roundAmount, baseTime) : moment();

  const [dateTime, setDateTime] = useState(mDateTime.toDate());

  const setDate = useCallback((_date) => {
    setDateTime(nearestFutureMinutes(roundAmount, _date).toDate());
  }, []);

  return {
    date: dateTime,
    time: dateTime,
    setDate,
  };
}
