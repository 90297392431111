import { useGenericStoreAndLocalStorage } from '~Common/hooks/useGenericStoreAndLocalStorage';
import { useInsightPrivileges } from './useInsightPrivileges';

export enum UserPerspective {
  manager = 'manager',
  executive = 'executive',
}

interface UseInsightsUserPerspective {
  userPerspective: UserPerspective,
  setUserPerspective: (value: UserPerspective) => void
}

export function useInsightsUserPerspective(): UseInsightsUserPerspective {
  const { isAdmin, isExecutive } = useInsightPrivileges();
  const [userPerspective, setUserPerspective] = useGenericStoreAndLocalStorage<UserPerspective>({
    localStorageKey: 'insightsUserPerspective',
    defaultValue: isAdmin || isExecutive ? UserPerspective.executive : UserPerspective.manager,
    genericStoreProps: {
      module: 'leadr',
      workflow: 'insights',
      feature: 'insights-user-perspective',
    },
  });

  return {
    userPerspective,
    setUserPerspective,
  };
}
