import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import CardLabel from './CardLabel';

const style = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

/**
 * @deprecated Old Card UI, use packages/leadr-frontend/src/common/V3/components/Card.tsx instead
 */
function CardContentRow({ content }) {
  return (
    <div css={style}>
      {content.map(([contentItem, key], index) => {
        let alignment;

        if (index === 0) {
          alignment = 'left';
        } else if (index === content.length - 1) {
          alignment = 'right';
        } else {
          alignment = 'center';
        }

        const labelStyle = css`
          padding: 0 8px;
          flex: 1 1 0px;
          width: ${100 / content.length}%;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: ${alignment};
        `;

        return (
          <CardLabel
            css={labelStyle}
            key={key}
            content={contentItem}
          />
        );
      })}
    </div>
  );
}

CardContentRow.propTypes = {
  content: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

CardContentRow.defaultProps = {
  content: [['Plano, TX', 'Location'], ['25 People', 'Count']],
};

export default CardContentRow;
