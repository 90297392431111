import { AgendaType } from '@leadr-hr/types';
import { TreeItems } from 'dnd-kit-sortable-tree';
import { useCallback, useMemo } from 'react';
import { isLocalId } from '~Common/utils/uuid';
import { useShowAgendaSection } from '~Meetings/components/topic-suggestions/stores/useShowAgendaSection';
import { AgendaTreeItemData } from '~Meetings/const/dndKitSortableTreeTypes';
import { ParentType } from '../../v2/useReorderAgendaItem';
import { AgendaItem } from '../../v2/useGetAgendas';
import { useAgendaPermissions } from '../useAgendaPermissions';

interface DisableSortingParams {
  itemId: string,
  itemType: AgendaType,
  sectionId?: string,
}

export const useFormatAgendasToTreeItems = (
  agendas: AgendaItem[] | undefined,
  huddleId: string,
): TreeItems<AgendaTreeItemData> => {
  const { getSectionPermissions, getTopicPermissions } = useAgendaPermissions(huddleId);

  const { getIsAgendaSectionExpanded } = useShowAgendaSection((state) => ({
    getIsAgendaSectionExpanded: state.getIsAgendaSectionExpanded,
  }));

  const disableSorting = useCallback(({ itemId, itemType, sectionId }: DisableSortingParams) => {
    if (itemType === AgendaType.AgendaSection) {
      return !getSectionPermissions(itemId).canReorder;
    }

    return !getTopicPermissions(itemId, sectionId).canReorderAgenda;
  }, [getSectionPermissions, getTopicPermissions]);

  const disableWorkflows = useCallback(({ itemId, itemType, sectionId }: DisableSortingParams) => {
    if (itemType === AgendaType.AgendaSection) {
      return getSectionPermissions(itemId).areWorkflowsDisabled;
    }

    return getTopicPermissions(itemId, sectionId).areWorkflowsDisabled;
  }, [getSectionPermissions, getTopicPermissions]);

  const treeItems = useMemo<TreeItems<AgendaTreeItemData>>(() => agendas?.map((agendaItem: AgendaItem, index) => ({
    id: agendaItem.id,
    ...(agendaItem.type === AgendaType.AgendaSection && {
      children: agendaItem.children.map((child, childIndex) => ({
        id: child.id,
        value: child.text,
        canHaveChildren: false,
        item: child,
        parentType: ParentType.AgendaSection,
        initialParentId: agendaItem.id,
        index: childIndex,
        disableSorting: disableSorting({ itemId: child.id, itemType: child.type, sectionId: agendaItem.id }),
        disableWorkflows: disableWorkflows({ itemId: child.id, itemType: child.type, sectionId: agendaItem.id }),
        parentAgendaSection: agendaItem,
      })),
      collapsed: !getIsAgendaSectionExpanded(agendaItem.id),
    }),
    disableSorting: disableSorting({ itemId: agendaItem.id, itemType: agendaItem.type }),
    disableWorkflows: disableWorkflows({ itemId: agendaItem.id, itemType: agendaItem.type }),
    canHaveChildren: (dragItem) => {
      if (agendaItem.type === AgendaType.AgendaTopic) {
        return false;
      }

      // Agenda section created by optimistic update, this would break if we tried to reorder into
      if (isLocalId(agendaItem.id)) {
        return false;
      }

      if (dragItem.item.type === AgendaType.AgendaSection) {
        return false;
      }

      if (dragItem.item.isRecurring && !agendaItem.isRecurring) {
        return false;
      }

      const { canAddAgendaItem } = getSectionPermissions(agendaItem.id);

      if (!canAddAgendaItem) {
        return false;
      }

      return true;
    },
    value: agendaItem.text,
    item: agendaItem,
    parentType: ParentType.MeetingInstance,
    initialParentId: huddleId,
    index,
  })) || [], [agendas, disableSorting, disableWorkflows, getIsAgendaSectionExpanded, getSectionPermissions, huddleId]);

  return treeItems;
};
