import { css } from '@emotion/react';
import moment from 'moment';
import { ComponentProps } from 'react';

import { palette } from '~Common/styles/colors';
import { useGenerateFormattedDayString } from '~Home/hooks/utils/useGenerateFormattedDayString';

const styles = {
  dayTitle: css({
    color: palette.neutrals.gray700,
    fontWeight: 700,
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  formattedDayString: string,
}

const View = ({
  formattedDayString,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.dayTitle} {...props}>{formattedDayString}</div>
);

interface MyScheduleDayTextProps {
  dayString: string,
}

const MyScheduleDayText = ({
  dayString,
  ...props
}: MyScheduleDayTextProps): JSX.Element => {
  const { generateFormattedDayString } = useGenerateFormattedDayString();

  // We can remove the isValidMomentDayString conditional once the In Leadr and Ignored Meetings tabs in the calendar drawer return the updated data structure
  const isValidMomentDayString = moment(dayString).isValid();
  const formattedDayString = isValidMomentDayString ? generateFormattedDayString(dayString) : dayString;

  const hookProps = {
    formattedDayString,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MyScheduleDayText;
