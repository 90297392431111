import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { patchApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

interface recallSurveyProps {
  id: string,
  participants: string[],
}

const recallSurvey = ({ id, participants }: recallSurveyProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/surveys/${id}/recall`;

  return patchApi(serverUrl, participants);
};

export const useRecallSurvey = (): UseMutateFunction<HttpCallReturn<void>, unknown, recallSurveyProps, void> => {
  const mySurveysKey = [getOrganizationId(), 'surveys', 'mySurveys', 'list'];
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: recallSurvey,
    onMutate: () => {
      toastId.current = toast.info('Recalling the survey(s)...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error recalling the survey(s). Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully recalled the survey(s).',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: mySurveysKey });
    },
  });

  return mutation.mutate;
};
