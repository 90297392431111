import { css } from '@emotion/react';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { palette } from '~Common/styles/colors';
import LeadrCheckbox from '~Common/V3/components/LeadrCheckbox';
import { SelectOption } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import SelectedAdminCard from '../SelectedAdminCard';

const styles = {
  autocomplete: css({
    fieldset: {
      display: 'none',
    },
  }),
  autocompleteRow: css({
    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  leftSide: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  name: css({
    color: palette.neutrals.gray800,
    fontSize: '0.875rem',
    fontWeight: 500,
    flex: 1,
  }),
  jobTitle: css({
    color: palette.neutrals.gray700,
    fontSize: '0.875rem',
  }),
  input: (isDisabled: boolean) => css({
    border: `1px solid ${palette.neutrals.gray300}`,
    borderRadius: '0.5rem',
    backgroundColor: palette.neutrals.white,
  }, isDisabled && {
    ':hover': {
      cursor: 'not-allowed',
    },
  }),
  selectedAdminCardContainer: css({
    marginTop: '.375rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '0.25rem',
  }),
};

interface ViewProps {
  adminList: SelectOption[],
  handleSelect: (event: SyntheticEvent, newValue: SelectOption[]) => void,
  handleRemoveSelectedAdmin: (value: string) => void,
  selectedAdminsList: SelectOption[],
  isMobile: boolean,
  shouldDisableSelectedAdmin?: (selectedAdmin: SelectOption) => boolean,
  disabled?: boolean,
}

const View = ({
  adminList,
  handleSelect,
  selectedAdminsList,
  handleRemoveSelectedAdmin,
  isMobile,
  disabled = false,
  shouldDisableSelectedAdmin,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <Autocomplete
      css={styles.autocomplete}
      multiple
      disableClearable
      disabled={disabled}
      onChange={handleSelect}
      options={adminList}
      popupIcon={<></>}
      disableCloseOnSelect
      value={selectedAdminsList}
      renderTags={() => null}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionDisabled={(option) => option.disabled === true}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            disableAnimation: true,
            shrink: true,
          }}
          placeholder="Add People"
          css={styles.input(disabled)}
        />
      )}
      renderOption={(optionProps, option, { selected }) => (
        <li
          key={option.value}
          css={styles.autocompleteRow}
          {...optionProps}
        >
          <div css={styles.leftSide}>
            <LeadrCheckbox
              size={20}
              checked={selected}
              data-test-id="reviewsSelectAdmin"
            />
            <NewBaseAvatar
              profileImageUrl={option.profileImageUrl}
              name={option.label}
              avatarSize={22}
            />
            <div css={styles.name}>
              {option.label}
            </div>
          </div>
          {!isMobile && (
            <div
              css={styles.jobTitle}
            >
              {option.jobTitle}
            </div>
          )}
        </li>
      )}
    />
    <div css={styles.selectedAdminCardContainer}>
      {selectedAdminsList.map((selectedAdmin) => (
        <SelectedAdminCard
          disabled={disabled || shouldDisableSelectedAdmin?.(selectedAdmin)}
          key={selectedAdmin.value}
          name={selectedAdmin.label}
          profileImageUrl={selectedAdmin.profileImageUrl}
          onClick={() => handleRemoveSelectedAdmin(selectedAdmin.value)}
        />
      ))}
    </div>
  </div>
);

interface AdminSelectorProps extends Pick<ViewProps, 'adminList' | 'disabled' | 'shouldDisableSelectedAdmin'> {
  initialSelectedAdmins: SelectOption[],
  onChange?: (value: string[]) => void,
}

const AdminSelector = ({
  initialSelectedAdmins,
  onChange,
  ...props
}: AdminSelectorProps): JSX.Element => {
  const [selectedAdminsList, setSelectedAdminsList] = useState<SelectOption[]>(initialSelectedAdmins);
  const isMobile = useIsMobileQuery();

  const handleSelect = (event: SyntheticEvent, newValue: SelectOption[]): void => {
    setSelectedAdminsList(newValue);
  };

  const handleRemoveSelectedAdmin = (value: string): void => {
    const newSelectedAdminsList = selectedAdminsList.filter((selectedAdmin) => selectedAdmin.value !== value);
    setSelectedAdminsList(newSelectedAdminsList);
  };

  useEffect(() => {
    onChange?.(selectedAdminsList.map((selectedAdmin) => selectedAdmin.value));
  }, [onChange, selectedAdminsList]);

  const hookProps = {
    selectedAdminsList,
    handleSelect,
    handleRemoveSelectedAdmin,
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AdminSelector;
