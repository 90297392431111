import { css } from '@emotion/react';
import { useMemo } from 'react';

import { palette } from '~Common/styles/colors';
import { Review, ReviewStatusEnum } from '~Reviews/V2/Const/types';

const styles = {
  completedReviewCountText: css({
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
};

interface ViewProps {
  numberOfReviews: number,
  numberOfCompletedReviews: number,
}

const View = ({
  numberOfReviews,
  numberOfCompletedReviews,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.completedReviewCountText}
    {...props}
  >
    {`${numberOfCompletedReviews} of ${numberOfReviews} Completed`}
  </div>
);

interface CompletedReviewCountTextProps {
  reviews: Review[],
}

const CompletedReviewCountText = ({
  reviews,
  ...props
}: CompletedReviewCountTextProps): JSX.Element => {
  const numberOfReviews = reviews.length;
  const numberOfCompletedReviews = useMemo(
    () => reviews.filter((review) => review.participants.every((participant) => participant.statusEnum === ReviewStatusEnum.Finished)).length,
    [reviews],
  );

  const hookProps = {
    numberOfReviews,
    numberOfCompletedReviews,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CompletedReviewCountText;
