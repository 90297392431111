import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-light-svg-icons';
import { filterOptions } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import usePeopleFilterMenu from '~Common/hooks/filter-menu/usePeopleFilterMenu';
import SearchBox from '~Common/components/Inputs/SearchBox';
import { palette } from '~Common/styles/colors';

const styles = {
  searchContainer: css`
    width: 100%;
    display: flex;
    background: ${palette.neutrals.white};
    flex-flow: wrap row;
    align-items: flex-end;
    justify-content: space-between;
  `,
  searchHolder: (isFilterDisabled) => css`
    width: ${isFilterDisabled ? '100%' : '78%'};
  `,
  iconsStyles: css`
    height: 18px important!;
    width: 18px important!;
    color: ${palette.neutrals.gray500};
  `,
};

const View = ({
  onSearch, setSearchFilter, MenuComponent, ...props
}) => (
  <div css={styles.searchContainer}>
    <div css={styles.searchHolder(!MenuComponent)}>
      <SearchBox
        placeholder="Search..."
        onChange={onSearch}
        icon={faFilter}
        {...props}
      />
    </div>
    {MenuComponent}
  </div>
);

View.propTypes = {
  onSearch: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  MenuComponent: PropTypes.func.isRequired,
};

const PeopleSearch = ({
  onSearch,
  setSearchFilter,
  currentFilter,
  disableFilter,
  onFilterChange,
  ...props
}) => {
  const {
    Component: MenuComponent,
  } = usePeopleFilterMenu({
    menuAnchorText: (
      <FontAwesomeIcon css={styles.iconsStyles} icon={faFilter} />
    ),
    menuItems: filterOptions,
    onItemSelected: onFilterChange,
  });

  const hookProps = {
    onSearch,
    setSearchFilter,
    currentFilter,
    MenuComponent: disableFilter ? false : MenuComponent,
  };
  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

PeopleSearch.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  disableFilter: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.oneOfType([
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

PeopleSearch.defaultProps = {
  currentFilter: '',
};
export { View };
export default PeopleSearch;
