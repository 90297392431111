import { css } from '@emotion/react';

const styles = {
  text: css({
    marginLeft: '1rem',
  }),
};

interface IconContentsProps {
  renderIcon: () => JSX.Element,
  renderText: () => JSX.Element,
}

const IconContents = ({
  renderIcon,
  renderText,
}: IconContentsProps): JSX.Element => (
  <>
    {renderIcon()}
    <div css={styles.text}>{renderText()}</div>
  </>
);

export default IconContents;
