import { ReactText, useRef } from 'react';
import { useMutation, UseMutateFunction } from '@tanstack/react-query';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { cloneDeep } from 'lodash';
import { ReflectionListReturn, ReflectionsListQueryParams } from '~Reviews/V2/reflections/hooks/useGetReflectionsList';
import { reflectionQueryKeys } from '../const/queryKeys';
import { Reflection } from '../types';

interface favoriteReflectionProps {
  id: string;
}

const favoriteReflection = ({ id }: favoriteReflectionProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/reflections/${id}/favorite`;

  return patchApi(serverUrl, {});
};

export const useFavoriteReflection = ({
  search, reflectionType, favoriteStatus, index, limit, isInActiveReview, startDate, endDate,
}: ReflectionsListQueryParams): UseMutateFunction<HttpCallReturn<unknown>, unknown, favoriteReflectionProps> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: favoriteReflection,
    onMutate: async (updateReflections: favoriteReflectionProps) => {
      toastId.current = toast.info('Updating the reflection favorite status...', { autoClose: false });
      // cancel current querries
      await queryClient.cancelQueries({
        queryKey: reflectionQueryKeys.list({
          search, reflectionType, favoriteStatus, index, limit, isInActiveReview, startDate, endDate,
        }),
      });
      // get current state
      const previousReflections: Reflection[] | undefined = queryClient.getQueryData(reflectionQueryKeys.list({
        search, reflectionType, favoriteStatus, index, limit, isInActiveReview, startDate, endDate,
      }));

      const filters = {
        search, reflectionType, favoriteStatus, index, limit, isInActiveReview, startDate, endDate,
      };
      // update current state
      queryClient.setQueryData<HttpCallReturn<ReflectionListReturn>>(
        reflectionQueryKeys.list(filters),
        (oldReflectionsOrder) => {
          if (oldReflectionsOrder) {
            const newReflections = cloneDeep(oldReflectionsOrder);
            newReflections.response.items = newReflections?.response?.items?.map((reflection) => {
              if (updateReflections.id === reflection.id.toString()) {
                return {
                  ...reflection,
                  isFavorite: !reflection.isFavorite,
                };
              }
              return reflection;
            });
            return newReflections;
          }

          return oldReflectionsOrder;
        },
      );

      return {
        previousReflections,
      };
    },
    onError: (_, filters, snapshot) => {
      queryClient.setQueryData(reflectionQueryKeys.list({
        search, reflectionType, favoriteStatus, index, limit, isInActiveReview, startDate, endDate,
      }), snapshot?.previousReflections);
      toast.update(toastId.current, {
        render: 'Failed to update the reflection favorite status.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully updated the reflection favorite status.',
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
      });

      await queryClient.invalidateQueries({ queryKey: reflectionQueryKeys.lists() });
    },
  });

  return mutation.mutate;
};
