import { css } from '@emotion/react';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { ComponentProps, useState } from 'react';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { NonLeadrUser } from '~Common/const/classes';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import AvatarMaybeMultipleMaybeNot, { isNonLeadrUser } from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import { MY_SCHEDULE_MEETING_CARD_MAX_SHOWN_AVATAR_COUNT } from '~Home/const';
import { MeetingPerson, MyScheduleMeeting } from '~Home/const/interfaces';

const styles = {
  meetingCard: css({
    border: `1px solid ${palette.neutrals.gray400}`,
    padding: '.4375rem .75rem',
    borderRadius: '.25rem',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  avatarContainer: css({
    display: 'flex',
  }),
  organzierAvatar: css({
    borderRadius: '0.25rem',
  }),
  avatarDivider: css({
    margin: '0 0.25rem',
    color: palette.neutrals.gray400,
  }),
  title: css({
    color: palette.neutrals.gray700,
    fontWeight: 600,
    fontSize: '.8125rem',
    margin: '0.25rem 0',
    lineHeight: '1rem',
  }, withTruncate()),
  bottom: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  timeContainer: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    fontSize: '.625rem',
  }),
  recurringIcon: css({
    marginLeft: '.5rem',
  }),
  avatar: css({
    '&:not(:last-child)': {
      marginRight: '.125rem',
    },
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  organizer: MeetingPerson,
  attendees: (PersonDisplayInformation | NonLeadrUser)[],
  numberOfAttendeesExcludingOrganizer: number,
  title: string,
  isRecurringSeries: boolean,
  isHovering: boolean,
  setIsHovering: (isHovering: boolean) => void,
  formattedStartTime: string,
  formattedEndTime: string,
  renderRightHeader?: (isHovering: boolean) => JSX.Element,
  renderRightFooter: (isHovering: boolean) => JSX.Element,
}

const View = ({
  organizer,
  attendees,
  numberOfAttendeesExcludingOrganizer,
  title,
  isRecurringSeries,
  isHovering,
  setIsHovering,
  formattedStartTime,
  formattedEndTime,
  renderRightHeader,
  renderRightFooter,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.meetingCard}
    onMouseEnter={() => setIsHovering(true)}
    onMouseLeave={() => setIsHovering(false)}
    {...props}
  >
    <div css={styles.header}>
      <div css={styles.avatarContainer}>
        <NewBaseAvatar
          avatarSize={22}
          name={`${organizer.firstName} ${organizer.lastName}`}
          profileImageUrl={organizer.profileImageUrl}
        />
        <div css={styles.avatarDivider}>|</div>
        <AvatarMaybeMultipleMaybeNot
          usersInfo={attendees}
          numberOfUsers={numberOfAttendeesExcludingOrganizer}
          numberOfUsersToShow={MY_SCHEDULE_MEETING_CARD_MAX_SHOWN_AVATAR_COUNT}
          avatarHeight={22}
          avatarWidth={22}
          renderAvatar={({ user }) => (
            <>
              {isNonLeadrUser(user) && (
                <NewBaseAvatar
                  css={styles.avatar}
                  email={user.email}
                  avatarSize={22}
                  isNonLeadrUser
                />
              )}
              {!isNonLeadrUser(user) && (
                <NewBaseAvatar
                  css={styles.avatar}
                  name={`${user.firstName} ${user.lastName}`}
                  profileImageUrl={user.profileImageUrl}
                  avatarSize={22}
                />
              )}
            </>
          )}
          renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
            <OverflowAvatar
              css={styles.avatar}
              {...overflowAvatarProps}
            />
          )}
        />
      </div>
      {renderRightHeader?.(isHovering)}
    </div>
    <div css={styles.title}>
      {title}
    </div>
    <div css={styles.bottom}>
      <div>
        <div css={styles.timeContainer}>
          {`${formattedStartTime} - ${formattedEndTime}`}
          {isRecurringSeries && (
            <FontAwesomeIcon css={styles.recurringIcon} icon={faArrowsRepeat} />
          )}
        </div>
      </div>
      {renderRightFooter(isHovering)}
    </div>
  </div>
);

interface MeetingCardBaseInterface {
  meeting: MyScheduleMeeting,
  renderRightHeader?: (isHovering: boolean) => JSX.Element,
  renderRightFooter: (isHovering: boolean) => JSX.Element,
}

const MeetingCardBase = ({
  meeting,
  ...props
}: MeetingCardBaseInterface): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false);
  const formattedStartTime = moment(meeting.startTimeInMillis).format('h:mma');
  const formattedEndTime = moment(meeting.endTimeInMillis).format('h:mma');

  const nonLeadrAttendees = meeting.nonLeadrAttendees?.map((user) => new NonLeadrUser(user.email)) || [];

  const attendees = [...meeting.attendeesExcludingOrganizer, ...nonLeadrAttendees];

  const hookProps = {
    organizer: meeting.organizer,
    attendees,
    numberOfAttendeesExcludingOrganizer: meeting.numberOfAttendeesExcludingOrganizer,
    title: meeting.title,
    isRecurringSeries: meeting.isRecurringSeries,
    isHovering,
    setIsHovering,
    formattedStartTime,
    formattedEndTime,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default MeetingCardBase;
