import { createStore, useStore } from 'zustand';

interface ShowCreateEditTeamModalState {
  showModal: boolean,
  setShowModal: (nextValue: boolean) => void,
}

export const showCreateEditTeamModalStore = createStore<ShowCreateEditTeamModalState>()((set) => ({
  showModal: false,
  setShowModal: (nextValue: boolean) => set(() => ({ showModal: nextValue })),
}));

export function useShowCreateEditTeamModal<T>(
  selector: (state: ShowCreateEditTeamModalState) => T,
  equals?: (a: T, b: T) => boolean,
): T {
  return useStore(showCreateEditTeamModalStore, selector, equals);
}
