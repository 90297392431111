import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { useGenericStoreAndLocalStorage } from '~Common/hooks/useGenericStoreAndLocalStorage';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { enpsOptions, likertOptions, Question } from '~Insights/const/pulseSurveyQuestions';
import { EngagementCategory } from '~Insights/const/types';
import { Survey } from '~Surveys/const/Survey';
import { assignedSurveysKey, pendingSurveysKey } from '~Surveys/Hooks/useSurveysList';

export const exampleQuestionSet: Question[] = [
  {
    id: 13,
    text: 'I understand what is expected of me in my role',
    category: EngagementCategory.clarity,
    options: likertOptions,
  },
  {
    id: 43,
    text: 'My individual strengths are being best utilized',
    category: EngagementCategory.maximization,
    options: likertOptions,
  },
  {
    id: 45,
    text: 'I am comfortable giving honest feedback to my team',
    category: EngagementCategory.rapport,
    options: likertOptions,
  },
  {
    id: 95,
    text: 'How likely are you to recommend Leadr as a place to work?',
    options: enpsOptions,
  },
];

interface UsePulseSurvey {
  questions: Question[],
  currentUserHasPulseSurvey: boolean,
  setCurrentUserHasPulseSurvey: (value: boolean) => void,
}

export function usePulseSurvey(): UsePulseSurvey {
  const [currentUserHasPulseSurvey, setCurrentUserHasPulseSurvey] = useGenericStoreAndLocalStorage({
    localStorageKey: 'insightsCurrentUserHasPulseSurvey',
    defaultValue: false,
    genericStoreProps: {
      module: 'leadr',
      workflow: 'insights',
      feature: 'insights-devtools-current-user-has-pulse-survey',
    },
  });

  // Makes the API call one day.

  return {
    questions: currentUserHasPulseSurvey ? exampleQuestionSet : [],
    currentUserHasPulseSurvey,
    setCurrentUserHasPulseSurvey,
  };
}

function getActivePulseSurveyQueryKey(organizationId: string): string[] {
  return ['insights', organizationId, 'leadrPulse', 'current'];
}

function getActivePulseSurveyQuery(organizationId: string): Promise<HttpCallReturn<Survey>> {
  const serverUrl = `/organizations/${organizationId}/surveys/leadrPulse/current`;
  return getApi<Survey>(serverUrl);
}

interface GetActivePulseSurvey {
  isLoading: boolean,
  survey?: Survey,
}

export function useActivePulseSurvey() : GetActivePulseSurvey {
  const organizationId = getOrganizationId() ?? '';

  const result = useQuery({
    queryKey: getActivePulseSurveyQueryKey(organizationId),
    queryFn: () => getActivePulseSurveyQuery(organizationId),
  });

  return {
    isLoading: result.isLoading,
    survey: result?.data?.response,
  };
}

function triggerPulseSurveyMutation(organizationId: string): Promise<HttpCallReturn<void>> {
  const serverUrl = `/organizations/${organizationId}/surveys/leadrPulse/manual`;
  return postApi<void>(serverUrl, {
    organizationId,
  });
}

export function useTriggerPulseSurvey(): UseMutationResult<HttpCallReturn<void>, Error, void, unknown> {
  const organizationId = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: () => triggerPulseSurveyMutation(organizationId),
    onError: (error: Error) => {
      toast.error(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getActivePulseSurveyQueryKey(organizationId) });
    },
  });
}

interface SurveyAnswer {
  questionId: number,
  answer: string,
}

interface SubmitSurveyAnswersMutation {
  surveyId: number,
  surveyAnswers: {
    answers: SurveyAnswer[],
  },
}

export const submitSurveyAnswersMutation = ({
  surveyId,
  surveyAnswers,
}: SubmitSurveyAnswersMutation): Promise<HttpCallReturn<void>> => {
  const organizationId = getOrganizationId() ?? '';
  const URL = `/organizations/${organizationId}/surveys/${surveyId}/submitAnswers`;

  return postApi<void>(URL, surveyAnswers);
};

export function useSubmitPulseSurvey(): UseMutationResult<HttpCallReturn<void>, unknown, SubmitSurveyAnswersMutation, unknown> {
  const organizationId = getOrganizationId() ?? '';

  const mutation = useMutation({
    mutationFn: submitSurveyAnswersMutation,
    onError: () => {
      toast.error('There was an error submitting the survey. Please try again.');
    },
    onSuccess: async () => {
      toast.success('Successfully submitted pulse survey.');
      await queryClient.invalidateQueries({ queryKey: getActivePulseSurveyQueryKey(organizationId) });
      await queryClient.invalidateQueries({ queryKey: assignedSurveysKey });
      await queryClient.invalidateQueries({ queryKey: pendingSurveysKey });
    },
  });

  return mutation;
}
