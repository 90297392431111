import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import { Divider } from '@mui/material';

const styles = {
  leadrCard: css({
    cursor: 'pointer',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
    justifyContent: 'space-between',
    padding: '.5rem 1.125rem',
  }),
  leftSection: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  divider: css({
    color: palette.neutrals.gray200,
    margin: '0 2rem',
  }),
  titleContainer: css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  }),
  title: css({
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    color: palette.neutrals.gray700,
  }, withTruncate()),
  underTitleSection: css({
    marginTop: '.25rem',
  }),
  rightSection: css({
    marginLeft: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
};

export interface ListCardProps extends Omit<LeadrCardProps, 'children'> {
  renderLeftSection?: () => JSX.Element,
  title: string,
  renderUnderTitleSection?: () => JSX.Element,
  renderRightSection?: () => JSX.Element,
}

const ListCard = ({
  renderLeftSection,
  title,
  renderUnderTitleSection,
  renderRightSection,
  ...props
}: ListCardProps): JSX.Element => (
  <LeadrCard
    css={styles.leadrCard}
    {...props}
  >
    {renderLeftSection && (
      <div css={styles.leftSection}>
        {renderLeftSection()}
        <Divider orientation="vertical" css={styles.divider} />
      </div>
    )}
    <div css={styles.titleContainer}>
      <div css={styles.title}>{title}</div>
      {renderUnderTitleSection && (
        <div css={styles.underTitleSection}>
          {renderUnderTitleSection()}
        </div>
      )}
    </div>
    {renderRightSection && (
      <div css={styles.rightSection}>
        {renderRightSection()}
      </div>
    )}
  </LeadrCard>
);

export default ListCard;
