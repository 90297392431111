import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { Goals } from '@leadr-hr/types';
import { goalKeys } from '../const/queryKeys';

const getGoalById = ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<Goals.Responses.GetGoalByIdResponse['data']>> => {
  const [id] = queryKey.slice(-1);
  const serverUrl = {
    host: getHost(hosts.goals, '3'),
    uri: `/organizations/${getOrganizationId() ?? ''}/goals/${id}`,
  };

  return getApi<Goals.Responses.GetGoalByIdResponse['data']>(serverUrl, {});
};

interface UseGetGoalProps {
  queryKey?: string[],
  id: string,
}

interface UseGetGoalReturnProps {
  data: Goals.Responses.GetGoalByIdResponse['data'] | undefined,
  isLoading: boolean,
  isError: boolean,
}

export const useGetGoalById = ({ id }: UseGetGoalProps): UseGetGoalReturnProps => {
  const result = useQuery({
    queryKey: goalKeys.detail(id),
    queryFn: getGoalById,
  });

  return {
    isLoading: result?.isLoading,
    isError: result?.isError,
    data: result?.data?.response as Goals.Responses.GetGoalByIdResponse['data'],
  };
};
