import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import SelectButton from '~Common/components/Buttons/Select';
import CoachingCardHeader from '~Deprecated/ui/components/Card/Cards/MeetingCard/CoachingCardHeader';
import { drawerInputBackground } from '~Deprecated/ui/styles/colors';
import { useGetPersonById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { getUserId } from '~Common/utils/localStorage';
import { truncate } from '~Deprecated/ui/styles/mixins';
import { convertTimestampToDateTime } from '~Deprecated/utils/meeting';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { MEETING_SHAPE, ATTENDEE_SHAPE } from '~Common/const/proptypes';

const styles = {
  meeting: css`
    background: ${drawerInputBackground};
    margin-bottom: 24px;
    border-radius: 8px;
  `,
  meetingInfo: css`
    padding: 1.5rem 1rem;
    display: flex;
    justify-content: space-between;
  `,
  meetingDetails: css`
    overflow: hidden;
  `,
};

const View = ({
  title, selectMeeting, startTime, otherParticipant,
}) => (
  <div
    css={styles.meeting}
  >
    {otherParticipant && (
      <CoachingCardHeader avatarList={[otherParticipant]} />
    )}
    <div css={styles.meetingInfo}>
      <div css={styles.meetingDetails}>
        <h4 css={[styles.meetingTitle, truncate]}>{ title }</h4>
        <div css={[styles.dateTimeLabel, truncate]}>
          { startTime }
        </div>
      </div>
      <div>
        <SelectButton onClick={selectMeeting} tooltip="Select this coaching" />
      </div>
    </div>
  </div>
);

View.propTypes = {
  title: PropTypes.string.isRequired,
  selectMeeting: PropTypes.func.isRequired,
  startTime: PropTypes.string.isRequired,
  otherParticipant: ATTENDEE_SHAPE,
};

View.defaultProps = {
  otherParticipant: null,
};

const AddToMeetingCard = ({ meeting, onMeetingSelected, ...props }) => {
  const otherParticipant = useGetPersonById({ id: meeting?.attendees?.filter((attendee) => attendee.id !== getUserId())[0].id });
  const { timezone } = useTimezone();

  const hookProps = {
    title: meeting.title,
    startTime: convertTimestampToDateTime(meeting.startTimeInMillis, timezone),
    otherParticipant,
    selectMeeting: () => {
      onMeetingSelected({ meeting });
    },
  };

  return (
    <View {...hookProps} {...props} />
  );
};

AddToMeetingCard.propTypes = {
  meeting: MEETING_SHAPE.isRequired,
  onMeetingSelected: PropTypes.func.isRequired,
};

AddToMeetingCard.defaultProps = {

};

export default AddToMeetingCard;
