import { css } from '@emotion/react';
import {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { Goals } from '@leadr-hr/types';
import { usePagination } from '~Common/hooks/usePagination';
import { GOALS_PAGE_SIZE } from '~Goals/const/defaults';
import Pagination from '~Common/V3/components/Pagination';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import emptySearch from '~Assets/images/empty-views/emptySearch.png';
import emptyActionItems from '~ActionItems/assets/images/emptyActionItems.png';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { useHistory, useLocation } from 'react-router-dom';
import { useGoalsIndexSearch } from '~Goals/stores/useGoalsIndexSearch';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { ViewPerspective } from '~Goals/const/types';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { GridSortModel } from '@mui/x-data-grid';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { TeamsListScope } from '~People/components/Teams/const/types';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import LinkButton from '~Common/V3/components/LinkButton';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { TableFilters } from '~Goals/components/TableFilters';
import { TableLoader } from '~Goals/components/Shared/TableLoader';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import GoalsTable, { goalSortColumnField } from './GoalsTable';

const styles = {
  skeleton: css({
    width: '100%',
    maxWidth: '100%',
    height: '25rem',
    transform: 'initial',
  }),
  emptyStateImage: css({
    height: '20rem',
  }),
  emptyStateImageSmall: css({
    height: '10rem',
  }),
  emptyStateContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1.5rem',
  }),
};

interface ViewProps {
  activeTab: Goals.GoalContextType,
  areFiltersActive: boolean,
  canViewTableGroup: boolean,
  clearAllFilters: () => void,
  goals: Goals.Responses.GetGoalsResponse['data'] | undefined,
  hasTeams: boolean,
  isAdminorExecutive: boolean,
  isCompleted: boolean,
  isFetching: boolean,
  isMobile: boolean,
  isSearchedOrFiltered: boolean,
  numberOfPages: number,
  onNextClick: () => void,
  onPageChange: (event: SelectChangeEvent<number>) => void,
  onPreviousClick: () => void,
  onSortModelChange: (sortModel: GridSortModel) => void,
  page: number,
  showSkeleton: boolean,
  sortByField: Goals.GetGoalsSortBy,
  sortByOrder: Goals.GetGoalsSortOrder,
  viewPerspective: ViewPerspective,
}

const View = ({
  activeTab,
  areFiltersActive,
  canViewTableGroup,
  clearAllFilters,
  goals,
  hasTeams,
  isAdminorExecutive,
  isCompleted,
  isFetching,
  isMobile,
  isSearchedOrFiltered,
  numberOfPages,
  onNextClick,
  onPageChange,
  onPreviousClick,
  onSortModelChange,
  page,
  showSkeleton,
  sortByField,
  sortByOrder,
  viewPerspective,
}: ViewProps): JSX.Element => (
  <>
    <TableFilters
      activeTab={activeTab}
      canViewTableGroup={canViewTableGroup}
      viewPerspective={viewPerspective}
      areFiltersActive={areFiltersActive}
      clearAllFilters={clearAllFilters}
    />
    {showSkeleton && (
      <div css={styles.emptyStateContainer}>
        <TableLoader
          rowsNum={10}
          activeTab={activeTab}
          isMobile={isMobile}
        />
      </div>
    )}
    <div css={styles.emptyStateContainer}>
      {!showSkeleton && isSearchedOrFiltered && goals && goals.length === 0 && (
        <EmptyStateWithImage
          renderImage={() => (
            <img
              css={[styles.emptyStateImage, styles.emptyStateImageSmall]}
              src={emptySearch}
              alt="No Search Results"
              data-test-id="goalsNoSearchResults"
            />
          )}
          text="No goals found."
        />
      )}

      {!showSkeleton && !isSearchedOrFiltered && goals && goals.length === 0 && (
        <EmptyStateWithImage
          renderImage={() => (
            <img
              css={styles.emptyStateImage}
              src={emptyActionItems}
              alt="Empty Goals"
            />
          )}
          renderText={() => (
            <>
              {isCompleted && (
                <span>
                  {`You have no completed ${activeTab.toLocaleLowerCase()} goals.`}
                </span>
              )}
              {!isCompleted && (
                <span>
                  {`You have no open ${activeTab.toLocaleLowerCase()} goals.`}
                  {((activeTab === Goals.GoalContextType.Team && hasTeams)
                  || (activeTab === Goals.GoalContextType.Personal)
                  || (activeTab === Goals.GoalContextType.Organization && isAdminorExecutive)) && (
                    <LinkButton
                      to={GoalsRoutes.Create}
                      variant="text"
                      data-test-id="actionItemsEmptyStateCreateActionItem"
                      renderContents={() => <>Click here to create one.</>}
                    />
                  )}
                </span>
              )}
            </>
          )}
        />
      )}
    </div>
    {!showSkeleton && goals && goals.length > 0 && (
      <>
        <GoalsTable
          data={goals}
          canViewTableGroup={canViewTableGroup}
          onSortModelChange={onSortModelChange}
          sortByField={sortByField}
          sortByOrder={sortByOrder}
          isFetching={isFetching}
        />
        {numberOfPages > 1 && (
          <Pagination
            page={page}
            onPageChange={onPageChange}
            numberOfPages={numberOfPages}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
          />
        )}
      </>
    )}
  </>
);

interface IndexTableProps {
  activeTab: Goals.GoalContextType,
  viewPerspective: ViewPerspective,
}

export const IndexTable = ({
  activeTab,
  viewPerspective,
}: IndexTableProps): JSX.Element => {
  const isTeamView = activeTab === Goals.GoalContextType.Team;
  const isTableGroupCobrandingEnabled = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const { enableTheTable } = useOrgDetailsContext();
  const canViewTableGroup = (enableTheTable === undefined ? false : enableTheTable) && isTableGroupCobrandingEnabled && isTeamView;
  const isMobile = useIsMobileQuery();

  const {
    debouncedSearchText,
  } = useGoalsIndexSearch((state) => ({
    debouncedSearchText: state.debouncedSearchText,
    setSearchText: state.setSearchText,
  }));

  const orgUserId = getOrganizationUserId();

  const [statusFilter, setStatusFilter] = useQueryParamState<Goals.GoalStatus[]>('goals', 'status', [], true);
  const [priorityFilter, setPriorityFilter] = useQueryParamState<Goals.GoalPriority[]>('goals', 'priority', [], true);
  const [categoryFilter, setCategoryFilter] = useQueryParamState<Goals.GoalCategory[]>('goals', 'category', [], true);
  const [ownerFilter, setOwnerFilter] = useQueryParamState<string[]>('goals', 'owner', [], true);
  const [participantFilter, setParticipantFilter] = useQueryParamState<string[]>('goals', 'participant', orgUserId ? [orgUserId] : [], true);
  const [teamFilter, setTeamFilter] = useQueryParamState<string[]>('goals', 'team', [], true);

  const [page, setPage] = useState(1);

  const location = useLocation();
  const isCompleted = location.pathname === GoalsRoutes?.ListComplete;
  const [sortByField, setSortByField] = useState(Goals.GetGoalsSortBy.DueDate);
  const [sortByOrder, setSortByOrder] = useState(Goals.GetGoalsSortOrder.Ascending);

  const history = useHistory();

  // If the filters/searchText change, reset the page number so we don't look at page 2 of a 1 page searchText as an example.
  useEffect(() => {
    setPage(1);
  }, [history.location.search, debouncedSearchText]);

  const params: Goals.Requests.GetGoalsRequestQueryParameters = {
    skip: (page - 1) * GOALS_PAGE_SIZE,
    take: GOALS_PAGE_SIZE,
    contextType: activeTab,

    contextId: activeTab === Goals.GoalContextType.Team && teamFilter.length ? teamFilter.join(',') : '',
    searchText: debouncedSearchText,
    searchField: debouncedSearchText ? Goals.GetGoalsSearchField.Title : undefined,
    isCompleted,
    ownerIds: ownerFilter.join(','),
    participantIds: activeTab !== Goals.GoalContextType.Organization ? participantFilter.join(',') : undefined,

    priority: priorityFilter.length ? priorityFilter.toString() : undefined,
    status: statusFilter.length ? statusFilter.toString() : undefined,
    category: activeTab === Goals.GoalContextType.Team && categoryFilter.length ? categoryFilter.toString() as Goals.GoalCategory : undefined,
    sortBy: sortByField,
    sortOrder: sortByOrder,
  };

  const {
    data, isLoading: goalsAreLoading, isFetching,
  } = useGetGoals({ params });

  const goalsResponse = useMemo(() => data?.response ?? [] as Goals.Responses.GetGoalsResponse['data'], [data]);
  const goals = useMemo(() => goalsResponse.filter((goal) => goal.permissions.includes(Goals.GoalPermission.CanViewGoal)), [goalsResponse]);
  const [showSkeleton] = useSkeletonLoaders(goalsAreLoading);

  const usePaginationProps = usePagination({
    totalCount: data?.meta?.total ?? 1,
    pageSize: GOALS_PAGE_SIZE,
    page,
    setPage,
  });

  const areFiltersActive = statusFilter.length > 0
    || priorityFilter.length > 0
    || categoryFilter.length > 0
    || ownerFilter.length > 0
    || participantFilter.length > 0
    || teamFilter.length > 0;

  const isSearchActive = !!debouncedSearchText;

  const isSearchedOrFiltered = areFiltersActive || isSearchActive;

  const clearAllFilters = (): void => {
    setStatusFilter([]);
    setPriorityFilter([]);
    setCategoryFilter([]);
    setOwnerFilter([]);
    setParticipantFilter([]);
    setTeamFilter([]);
  };

  // Clear out the status filters when changing between Open and Closed, since they are mutually exclusive.
  useEffect(() => {
    setStatusFilter([]);
  }, [setStatusFilter, viewPerspective]);

  // Clear out the category and team filters if we're not on the team tab.
  useEffect(() => {
    if (activeTab !== Goals.GoalContextType.Team) {
      setCategoryFilter([]);
      setTeamFilter([]);
    }
  }, [activeTab, setCategoryFilter, setTeamFilter]);

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel.length) {
      setSortByField(goalSortColumnField[sortModel[0].field]);
      setSortByOrder(sortModel[0].sort === Goals.GetGoalsSortOrder.Ascending ? Goals.GetGoalsSortOrder.Ascending : Goals.GetGoalsSortOrder.Descending);
    }
  }, []);

  const {
    isAdmin,
    isExecutive,
  } = useUserPermissions();
  const isAdminorExecutive = isAdmin || isExecutive;
  const listScope = isAdmin ? TeamsListScope.AllTeams : TeamsListScope.OwnedTeams;
  const { data: teamsData } = useGetTeams({
    // we want to get the first page of teams
    page: 0,
    count: 1000, // TODO: Need to make this NOT a predefined number - Should update this to pull everything or change the select box to be a searchfilter or something
    listScope,
  });
  const teams = teamsData?.response.teams ?? [];
  const teamsList = teams.map((team) => ({
    label: team.name,
    value: team.teamId,
  }));
  const hasTeams = teamsList.length > 0;

  const hookProps = {
    activeTab,
    areFiltersActive,
    canViewTableGroup,
    clearAllFilters,
    goals,
    hasTeams,
    isAdminorExecutive,
    isCompleted,
    isFetching,
    isMobile,
    isSearchedOrFiltered,
    onSortModelChange,
    page,
    setTeamFilter,
    showSkeleton,
    sortByField,
    sortByOrder,
    teamFilter,
    viewPerspective,
    ...usePaginationProps,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
