import Wonder from '~Meetings/assets/images/workingGenius/wonder.svg';
import Invention from '~Meetings/assets/images/workingGenius/invention.svg';
import Discernment from '~Meetings/assets/images/workingGenius/discernment.svg';
import Galvanizing from '~Meetings/assets/images/workingGenius/galvanizing.svg';
import Enablement from '~Meetings/assets/images/workingGenius/enablement.svg';
import Tenacity from '~Meetings/assets/images/workingGenius/tenacity.svg';
import { WorkingGeniusType } from '~Meetings/const/meetingsInterfaces';
import { SvgReactComponentProps } from '~Common/types/svg';

interface WorkingGeniusImageMapProps extends Omit<SvgReactComponentProps, 'title'> {
  type: WorkingGeniusType,
}

const WorkingGeniusImageMap = ({
  type,
  ...props
}: WorkingGeniusImageMapProps): JSX.Element => (
  <>
    {type === WorkingGeniusType.Wonder && (
      <Wonder title="Wonder gear" {...props} />
    )}
    {type === WorkingGeniusType.Invention && (
      <Invention title="Invention gear" {...props} />
    )}
    {type === WorkingGeniusType.Discernment && (
      <Discernment title="Discernment gear" {...props} />
    )}
    {type === WorkingGeniusType.Galvanizing && (
      <Galvanizing title="Galvanizing gear" {...props} />
    )}
    {type === WorkingGeniusType.Enablement && (
      <Enablement title="Enablement gear" {...props} />
    )}
    {type === WorkingGeniusType.Tenacity && (
      <Tenacity title="Tenacity gear" {...props} />
    )}
  </>
);

export default WorkingGeniusImageMap;
