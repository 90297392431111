import { css } from '@emotion/react';
import { FormControlLabel } from '@mui/material';

import { palette } from '~Common/styles/colors';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import CheckboxLabel from '~Settings/components/Tabs/Admin/CheckboxLabel';
import { ComponentProps, useMemo } from 'react';
import { OrgSettings } from '~Common/const/Organization';

const styles = {
  descriptionText: css({
    color: palette.neutrals.gray700,
    marginBottom: '1rem',
  }),
  checkboxesContainer: css({
    display: 'flex',
  }),
  formControlLabel: css({
    display: 'flex',
  }),
};

const HOME_SETTING_TEXT_MAP = {
  enableCelebrate: 'Celebrate',
  enableGoals: 'Goals',
  enableInsights: 'Insights',
  enableMeetings: 'Meetings',
  enableOrgClarity: 'Organizational Clarity',
  enablePeople: 'People',
  enableRecognition: 'Recognition',
  enableTeamClarity: 'Team Clarity',
  enableWhatsDue: 'What\'s Due (Whole Section)',
  enableWhatsDueActionItems: 'What\'s Due: Action Items',
  enableWhatsDueFeedback: 'What\'s Due: Feedback',
  enableWhatsDueSurveys: 'What\'s Due: Surveys',
  enableWhatsDueLearning: 'What\'s Due: Learning',
  enableWhatsDueReviews: 'What\'s Due: Reviews',
};

interface HomeSettings {
  firstColumn: HomeSetting[],
  secondColumn: HomeSetting[],
}

interface HomeSetting {
  name: keyof typeof HOME_SETTING_TEXT_MAP,
  defaultChecked: boolean,
  text: string,
}

interface ViewProps extends ComponentProps<'div'> {
  homeSettings: HomeSettings,
}

const View = ({
  homeSettings,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <SettingsSectionHeader
      renderContents={() => (
        <>Home Widgets</>
      )}
    />

    <p css={styles.descriptionText}>
      Selecting the Home Widgets below to display for your organization.
      Unchecking will hide them for all members.
    </p>

    <div css={styles.checkboxesContainer}>
      <div>
        {homeSettings.firstColumn.map((homeSetting) => (
          <FormControlLabel
            css={styles.formControlLabel}
            control={(
              <CustomCheckbox
                name={homeSetting.name}
                defaultChecked={homeSetting.defaultChecked}
              />
            )}
            label={(
              <CheckboxLabel
                renderText={(checkboxLabelTextStyles) => (
                  <div css={checkboxLabelTextStyles}>{homeSetting.text}</div>
                )}
              />
            )}
          />
        ))}
      </div>
      <div>
        {homeSettings.secondColumn.map((homeSetting) => (
          <FormControlLabel
            css={styles.formControlLabel}
            control={(
              <CustomCheckbox
                name={homeSetting.name}
                defaultChecked={homeSetting.defaultChecked}
              />
            )}
            label={(
              <CheckboxLabel
                renderText={(checkboxLabelTextStyles) => (
                  <div css={checkboxLabelTextStyles}>{homeSetting.text}</div>
                )}
              />
            )}
          />
        ))}
      </div>
    </div>
  </div>
);

interface HidePersonalityTypeModelsProps extends ComponentProps<'div'> {
  orgSettings: OrgSettings,
}

const HomeWidgets = ({
  orgSettings,
  ...props
}: HidePersonalityTypeModelsProps): JSX.Element => {
  const homeSettings = useMemo(() => {
    const homeSettingsArray = Object.entries(orgSettings.homeSettings).sort() as [keyof typeof HOME_SETTING_TEXT_MAP, boolean][];

    return homeSettingsArray.reduce((acc, [setting, value]) => {
      const settingObject = {
        name: setting,
        defaultChecked: value,
        text: HOME_SETTING_TEXT_MAP[setting],
      };

      if (setting.includes('WhatsDue')) {
        acc.secondColumn.push(settingObject);
      } else {
        acc.firstColumn.push(settingObject);
      }

      return acc;
    }, { firstColumn: [] as HomeSetting[], secondColumn: [] as HomeSetting[] });
  }, [orgSettings.homeSettings]);

  const hookProps = {
    homeSettings,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default HomeWidgets;
