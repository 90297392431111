import { css } from '@emotion/react';
import { faEdit, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import LearningCompletedCount from '~Learning/components/Shared/LearningDashboardCards/LearningCompletedCount';
import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';
import LearningSectionsCard, { LearningSectionsCardProps } from '~Learning/components/Shared/LearningSectionsCard';
import { LearningStatus, LearningType } from '~Learning/const/interfaces';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';
import { editPlaylistDueDatesDrawerTemplate } from '../EditPlaylistDueDatesDrawer';

const styles = {
  body: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  title: css({
    color: palette.neutrals.gray700,
    fontSize: '1.25rem',
    fontWeight: 600,
  }, withTruncate()),
  description: css({
    marginTop: '1rem',
    color: palette.neutrals.gray700,
  }),
  completedLearningsText: css({
    color: palette.neutrals.gray500,
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  overflowMenuButton: css({
    marginLeft: '1.125rem',
    padding: '.3906rem .7594rem',
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    borderRadius: '.1875rem',
  }),
  icon: css({
    height: '1.125rem',
    color: palette.neutrals.gray500,
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

interface ViewProps extends Omit<LearningSectionsCardProps, 'renderHeader' | 'renderBody' | 'renderFooter'> {
  title: string,
  descriptionHTML: HTMLString,
  dueDate?: Date | string,
  status: LearningStatus,
  numberOfUsersComplete: number,
  numberOfUsersAssigned: number,
  overflowMenuItems: MenuItem[][],
}

const View = ({
  title,
  descriptionHTML,
  dueDate,
  status,
  numberOfUsersComplete,
  numberOfUsersAssigned,
  overflowMenuItems,
  ...props
}: ViewProps): JSX.Element => (
  <LearningSectionsCard
    data-test-id="playlistDetailsCard"
    renderHeader={() => (
      <div css={styles.header}>
        <LearningTypeInfo
          learningType={LearningType.PLAYLIST}
        />
        <OverflowMenu
          menuItems={overflowMenuItems}
          renderOverflowMenuButton={(doOpen) => (
            <LeadrButton
              onClick={doOpen}
              css={styles.overflowMenuButton}
              variant="text"
              data-test-id="learningPlaylistOverflowMenu"
            >
              <FontAwesomeIcon css={styles.icon} icon={faEllipsisVertical} />
            </LeadrButton>
          )}
        />
      </div>
    )}
    renderBody={() => (
      <div css={styles.body}>
        <div css={styles.title}>{title}</div>
        <HTMLRenderer css={styles.description} htmlText={descriptionHTML} />
      </div>
    )}
    renderFooter={() => (
      <div css={styles.footer}>
        <DueDateFromNow
          dueDate={dueDate}
          isComplete={status === LearningStatus.COMPLETED}
        />
        <LearningCompletedCount
          css={styles.completedLearningsText}
          numberOfUsersCompleted={numberOfUsersComplete}
          numberOfUsersAssigned={numberOfUsersAssigned}
        />
      </div>
    )}
    {...props}
  />
);

interface PlaylistDetailsCardProps extends Omit<ViewProps, 'overflowMenuItems'> {
  onRecallPlaylist: () => void,
  canRecallFromAllParticipants: boolean,
  status: LearningStatus,
  playlistId: number,
}

const PlaylistDetailsCard = ({
  onRecallPlaylist,
  canRecallFromAllParticipants,
  status,
  playlistId,
  ...props
}: PlaylistDetailsCardProps): JSX.Element => {
  const dispatch = useDispatch();

  const overflowMenuItems = useMemo(() => [[
    {
      text: 'Edit Due Dates',
      icon: faEdit,
      isDeactivated: status === LearningStatus.COMPLETED,
      onClick: () => {
        // Do some stuff
        if (status !== LearningStatus.COMPLETED) {
          // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
          dispatch(popDrawerAction({ popAll: true }));
          dispatch(pushDrawerAction({
            drawer: {
              ...editPlaylistDueDatesDrawerTemplate,
              args: {
                playlistId,
              },
            },
          }));
        }
      },
    },
    {
      text: 'Recall',
      icon: faTimes,
      isDeactivated: !canRecallFromAllParticipants,
      onClick: () => {
        if (canRecallFromAllParticipants) {
          onRecallPlaylist();
        }
      },
    },
  ]], [canRecallFromAllParticipants, dispatch, onRecallPlaylist, status, playlistId]);

  const hookProps = {
    overflowMenuItems,
    status,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export { View };

export default PlaylistDetailsCard;
