import { useCallback, useMemo } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { ReceivedLearning, LearningStatus } from '~Learning/const/interfaces';
import { useLearningSearch } from '../utils/useLearningSearch';
import { useSortLearningsAndPlaylists } from '../utils/useSortLearningsAndPlaylists';
import { useGetReceivedLearnings } from './useGetReceivedLearnings';

export interface ReceivedLearningSection {
  sectionHeader: string,
  learnings: ReceivedLearning[] | undefined,
}

type ReceivedLearningByStatus = Omit<UseQueryResult<ReceivedLearning[]>, 'data'> & { learningsByStatus: ReceivedLearningSection[] };

export const useGetReceivedLearningsByStatus = (): ReceivedLearningByStatus => {
  const { data: learnings, ...rest } = useGetReceivedLearnings();

  const [search] = useLearningSearch();

  const searchReceivedLearnings = useCallback((unsearchedLearnings: ReceivedLearning[] | undefined): ReceivedLearning[] => {
    if (unsearchedLearnings) {
      return [...unsearchedLearnings].filter((receivedLearning) => receivedLearning.title.toLowerCase().includes(search.toLowerCase())) ?? [];
    }

    return [];
  }, [search]);

  const searchedReceivedLearnings = useMemo(() => searchReceivedLearnings(learnings), [learnings, searchReceivedLearnings]);

  const incompleteSearchedLearnings = useMemo(
    () => searchedReceivedLearnings?.filter((learning) => learning.status === LearningStatus.INCOMPLETE),
    [searchedReceivedLearnings],
  );

  const completedSearchedLearnings = useMemo(
    () => searchedReceivedLearnings?.filter((learning) => learning.status === LearningStatus.COMPLETED),
    [searchedReceivedLearnings],
  );

  const sortLearningsAndPlaylists = useSortLearningsAndPlaylists();

  const sortedAndSearchedIncompleteLearnings = sortLearningsAndPlaylists({
    unsortedLearningsAndPlaylists: incompleteSearchedLearnings,
  }) as ReceivedLearning[];

  const sortedAndSearchedCompletedLearning = sortLearningsAndPlaylists({
    unsortedLearningsAndPlaylists: completedSearchedLearnings,
    shouldReverse: true,
  }) as ReceivedLearning[];

  const learningsByStatus = [
    ...(sortedAndSearchedIncompleteLearnings?.length
      ? [{ sectionHeader: 'Incomplete', learnings: sortedAndSearchedIncompleteLearnings }] : []),
    ...(sortedAndSearchedCompletedLearning?.length
      ? [{ sectionHeader: 'Completed', learnings: sortedAndSearchedCompletedLearning }] : []),
  ];

  return {
    learningsByStatus,
    ...rest,
  };
};
