import { useGetPendingReviewCount } from '~Reviews/V2/Hooks/useGetPendingReviewCount';

import { useQuery } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { useGetPendingSurveys } from '~Surveys/Hooks/useSurveysList';
import { NavigationIndicatorData } from '~Root/components/OldNavigationSidebar/types';

export interface UserPendingItems {
  pendingActionItemCount: number,
  pendingFeedbackCount: number,
  pendingLearningCount: number,
}

export const getUserItemsPending = async (): Promise<HttpCallReturn<UserPendingItems>> => getApi(
  `/organizations/${getOrganizationId() ?? ''}/users/items/pending`,
);

export const USER_PENDING_ITEMS_QUERY_KEY = [getOrganizationId(), 'user', 'pending', 'items'];

export function useNavigationIndicators(): NavigationIndicatorData {
  const detail = useQuery({
    queryKey: USER_PENDING_ITEMS_QUERY_KEY,
    queryFn: getUserItemsPending,
    // Shorten this so there is quicker feedback on these dots.
    staleTime: 60 * 1000 * 2,
  });

  const data = detail?.data?.response;
  const { surveys } = useGetPendingSurveys() as { surveys: number; };
  const { data: pendingReviews } = useGetPendingReviewCount();

  return {
    pendingActionItems: data?.pendingActionItemCount ?? 0,
    pendingFeedback: data?.pendingFeedbackCount ?? 0,
    pendingLearnings: data?.pendingLearningCount ?? 0,
    pendingReviews: pendingReviews ?? 0,
    pendingSurveys: surveys ?? 0,
  };
}
