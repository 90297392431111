import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import LeadrModal from '~Common/V3/components/LeadrModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  modalTitle: css({
    color: palette.brand.indigo,
  }),
  modalContent: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
  modalContentSpace: css({
    marginTop: '1rem',
  }),
  warningText: css({
    color: palette.brand.red,
    fontWeight: '600',
  }),
  spacer: css({
    margin: '2rem 0',
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  cancelButton: css({
    marginLeft: 'auto',
  }),
  boldText: css({
    fontWeight: '600',
  }),
};

interface DisplayReopenModalConfirmProps {
  open: boolean,
  handleModalDisplay: (value: boolean) => void,
  reOpenReviews: () => void,
  reOpenTypeTitle?: string,
}

const DisplayReopenModalConfirm = ({
  open,
  handleModalDisplay,
  reOpenReviews,
  reOpenTypeTitle = 'Are you sure you want to reopen these Reviews?',
}: DisplayReopenModalConfirmProps): JSX.Element => (
  <LeadrModal
    open={open}
    onClose={() => handleModalDisplay(false)}
  >
    <LeadrModal.Header>
      <LeadrModal.Title css={styles.modalTitle}>
        {reOpenTypeTitle}
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body>
      <p css={styles.modalContent}>
        This action will allow all participants on the review to edit their responses and require them to resubmit their reviews.
      </p>
      <p css={[styles.modalContent, styles.modalContentSpace]}>
        <span css={styles.boldText}>Please note</span>
        : If final comments were left, they will be deleted.
      </p>
    </LeadrModal.Body>
    <LeadrModal.Footer css={styles.footer}>
      <LeadrButton
        onClick={reOpenReviews}
        data-test-id="reviewCycleFinish"
        size="small"
      >
        Reopen Reviews
      </LeadrButton>
      <LeadrButton
        css={styles.cancelButton}
        onClick={() => handleModalDisplay(false)}
        data-test-id="reviewCycleCancelComplete"
        variant="ghost"
        size="small"
      >
        Cancel
      </LeadrButton>
    </LeadrModal.Footer>
  </LeadrModal>
);

export default DisplayReopenModalConfirm;
