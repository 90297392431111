import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE, BUTTON_STYLES } from '~Reviews/V2/Const/pageStyles';
import {
  CycleShape, ReviewCycleDetail, ViewCyclePerspective,
} from '~Reviews/V2/Const/types';
import { ADMIN_REVIEWS_V2, REVIEWS_CONTINUE_CREATING_CYCLE } from '~Common/const/routes';
import { useGetCycleById } from '~Reviews/V2/Hooks/useGetCycleById';
import { CreateCycleDashboard } from '~Reviews/V2/CreateCycle/Dashboard';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { palette } from '~Common/styles/colors';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { DEFAULT_CYCLE } from '../Const/defaults';

function validateInitialPerspectiveFromRouter(cycle: ReviewCycleDetail, perspective: ViewCyclePerspective): boolean {
  if (perspective === ViewCyclePerspective.Preview_Publish
    && !(cycle.reviews && cycle.reviews.length > 0)) {
    return false;
  }
  if (perspective !== ViewCyclePerspective.Setup && cycle.name === '') {
    return false;
  }

  return true;
}

function getInitialPerspective(cycle: ReviewCycleDetail): ViewCyclePerspective {
  if (cycle.reviews.length > 0) {
    return ViewCyclePerspective.Preview_Publish;
  }

  if (cycle?.name !== '') {
    return ViewCyclePerspective.Create_Questions;
  }

  return ViewCyclePerspective.Setup;
}

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
  ...BUTTON_STYLES,
};

interface ViewProps {
  cycle: CycleShape,
  cycleId: string,
  setViewCyclePerspective: (value: ViewCyclePerspective) => void,
  viewCyclePerspective: ViewCyclePerspective,
}

const View = ({
  cycle,
  cycleId,
  setViewCyclePerspective,
  viewCyclePerspective,
}: ViewProps): JSX.Element => (
  <>
    <div css={styles.container}>
      <div css={styles.topBar}>
        <div css={(styles.leftSide(false))}>
          <LeadrButton
            component={Link}
            to={ADMIN_REVIEWS_V2}
            variant="text"
            css={styles.textBackButton}
            textButtonColor={palette.neutrals.gray700}
            data-test-id="reviewsCycleBackToReviewsList"
          >
            Back to Reviews
          </LeadrButton>
        </div>
        <div id="contextButtons" />
      </div>
      <CreateCycleDashboard
        cycle={cycle}
        cycleId={cycleId}
        setViewCyclePerspective={setViewCyclePerspective}
        viewCyclePerspective={viewCyclePerspective}
      />
    </div>
  </>
);

interface CreateCycleLayoutProps {
  initialPerspectiveFromRouter?: ViewCyclePerspective,
}

const CreateCycleLayout = ({
  initialPerspectiveFromRouter,
}: CreateCycleLayoutProps): JSX.Element => {
  const { cycleId } = useParams<{ cycleId: string; }>();
  const history = useHistory();
  const { isAdmin } = useUserPermissions();
  const { data: cycle, isLoading: cycleIsLoading } = useGetCycleById({ id: cycleId, isAdmin });
  // Note: the typing here does not really represent the return type from the API, which is ReviewCycleDetail.
  // This was too much for me to fix at the moment, so let's call it a TODO.
  const [viewCyclePerspective, setViewCyclePerspective] = useState(ViewCyclePerspective.Setup);

  useEffect(() => {
    if (!cycle) return;
    if (initialPerspectiveFromRouter && validateInitialPerspectiveFromRouter(cycle as unknown as ReviewCycleDetail, initialPerspectiveFromRouter)) {
      setViewCyclePerspective(initialPerspectiveFromRouter);
    } else {
      const perspective = getInitialPerspective(cycle as unknown as ReviewCycleDetail);
      history.replace((`${REVIEWS_CONTINUE_CREATING_CYCLE}/${perspective}`).replace(':cycleId', cycleId));
    }
  }, [history, cycle, cycleId, initialPerspectiveFromRouter]);

  const [showSkeleton] = useSkeletonLoaders(cycleIsLoading);

  if (showSkeleton) {
    return (
      <MultipleSkeletonLoaders
        css={[styles.listSection, styles.listSectionSkeletonWidth]}
        numberOfSkeletons={1}
        renderSkeletonItem={() => (
          <CardSkeleton css={styles.cardSkeleton} />
        )}
      />
    );
  }

  const hookProps = {
    cycle: cycle as CycleShape ?? DEFAULT_CYCLE,
    cycleId,
    setViewCyclePerspective,
    viewCyclePerspective,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, CreateCycleLayout };
export default CreateCycleLayout;
