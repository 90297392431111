import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { getHost } from '~Deprecated/services/config';
import { useAgendaPermissionsStore } from '../utils/useAgendaPermissions';

interface CarryOverAgendaItemRequest {
  huddleId: string,
  agendaId: string,
}

interface CarryOverAgendaItemResponse {
  id: string,
}

export const createAgendaSection = (data: CarryOverAgendaItemRequest): Promise<HttpCallReturn<CarryOverAgendaItemResponse>> => {
  const url = {
    host: getHost('', '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/huddles/agendas/carryover`,
  };

  return postApi(url, data);
};

export const useCarryOverAgendaItem = (
  sectionId?: string,
): UseMutationResult<HttpCallReturn<CarryOverAgendaItemResponse>, unknown, CarryOverAgendaItemRequest> => {
  const {
    disableWorkflows,
    enableWorkflows,
  } = useAgendaPermissionsStore((state) => ({
    disableWorkflows: state.disableWorkflows,
    enableWorkflows: state.enableWorkflows,
  }));

  return useMutation({
    mutationFn: createAgendaSection,
    onMutate: ({ agendaId: agendaTopicId }) => {
      disableWorkflows(agendaTopicId, sectionId);
    },
    onError: () => {
      toast.error(<MeetingsErrorToast />, { autoClose: 1500 });
    },
    onSuccess: () => {
      toast.success('The topic was successfully carried over into the following meeting, including comments.', { autoClose: 3000 });
      void queryClient.invalidateQueries({ queryKey: meetingKeys.agendaLists() });
    },
    onSettled: (_, __, { agendaId: agendaTopicId }) => {
      enableWorkflows(agendaTopicId, sectionId);
    },
  });
};
