import { css } from '@emotion/react';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '~Admin/Importer/Const/pageStyles';
import LinkButton from '~Common/V3/components/LinkButton';
import { ADMIN_PEOPLE, PEOPLE_ADMIN } from '~Common/const/routes';
import ImporterDashboard from '~Admin/Importer/Pages/ImporterDashboard';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
  textBackButton: css({
    fontSize: '1rem',
  }),
};

interface ViewProps {
  backToAdminRoute: string,
}

const View = ({
  backToAdminRoute,
}: ViewProps): JSX.Element => (
  <>
    <div css={styles.container}>
      <div css={styles.topBar}>
        <div css={(styles.leftSide(false))}>
          <LinkButton
            css={styles.textBackButton}
            to={backToAdminRoute}
            variant="text"
            renderContents={() => (
              <>Back to People</>
            )}
          />
        </div>
        <div id="contextButtons" />
      </div>
      <ImporterDashboard />
    </div>
  </>
);

const AdminImportLayout = (): JSX.Element => {
  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');
  const backToAdminRoute = webAppTeamsV2 ? `${PEOPLE_ADMIN}?tab=imported` : `${ADMIN_PEOPLE}?tab=imported`;
  const hookProps = {
    backToAdminRoute,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, AdminImportLayout };
export default AdminImportLayout;
