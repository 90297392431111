import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { queryClient } from '~Common/const/queryClient';
import { AgendaTemplates } from '@leadr-hr/types';
import { getLocalId } from '~Common/utils/uuid';
import { produce } from 'immer';
import { toast } from '~Common/components/Toasts';
import { getIsIndividualQuestionsTemplate } from '~Meetings/components/topic-suggestions/utils/getIsIndividualTopicTemplate';
import {
  CreateIndividualAgendaTemplatePayload,
  CreateIndividualAgendaTemplateResponse,
  MeetingTemplateType,
  CreateIndividualAgendaTemplateDetails,
  CreateIndividualAgendaSectionTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { meetingTopicTemplatesQueryKeys } from '../queryKeys';
import { GetMeetingTemplatesResponse } from '../useGetCustomMeetingTemplates';

const createIndividualTopic = ({
  type,
  child,
}: CreateIndividualAgendaTemplatePayload): Promise<HttpCallReturn<CreateIndividualAgendaTemplateResponse>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates/individual`;

  return postApi<CreateIndividualAgendaTemplateResponse>(URL, { type, child });
};

function isChildAgendaSection(item: CreateIndividualAgendaTemplateDetails): item is CreateIndividualAgendaSectionTemplateDetails {
  return (item as CreateIndividualAgendaSectionTemplateDetails).children !== undefined;
}

interface UseCreateIndividualTopicParams {
  onMutateCallback?: () => void,
  meetingTemplateType: MeetingTemplateType,
}

export const useCreateIndividualTopic = ({
  onMutateCallback,
  meetingTemplateType,
}: UseCreateIndividualTopicParams): UseMutationResult<
    HttpCallReturn<CreateIndividualAgendaTemplateResponse>,
    unknown,
    CreateIndividualAgendaTemplatePayload
  > => {
  const orgId = getOrganizationId() ?? '';

  const queryKeyForList = meetingTopicTemplatesQueryKeys.customTemplatesList({
    orgId,
    meetingTemplateType,
  });

  return useMutation({
    mutationFn: createIndividualTopic,
    onMutate: async ({ child }) => {
      onMutateCallback?.();

      const previousMeetingTemplates = queryClient.getQueryData(queryKeyForList);
      await queryClient.cancelQueries({ queryKey: queryKeyForList });

      queryClient.setQueryData<HttpCallReturn<GetMeetingTemplatesResponse>>(queryKeyForList, (originalData) => {
        if (originalData) {
          if (isChildAgendaSection(child)) {
            const newIndividualTopic = {
              ...child,
              id: getLocalId(),
              permissions: [] as AgendaTemplates.AgendaTemplatePermission[],
              children: child.children.map((sectionChild) => ({
                ...sectionChild,
                id: getLocalId(),
                permissions: [] as AgendaTemplates.AgendaTemplatePermission[],
              })),
            };

            return produce(originalData, (draft) => {
              const individualQuestionsTemplate = draft.response.meetingTemplates.find(
                (meetingTemplate) => getIsIndividualQuestionsTemplate(meetingTemplate),
              );
              individualQuestionsTemplate?.children.push(newIndividualTopic);
            });
          }

          const newIndividualTopic = {
            ...child,
            id: getLocalId(),
            permissions: [] as AgendaTemplates.AgendaTemplatePermission[],
          };

          return produce(originalData, (draft) => {
            const individualQuestionsTemplate = draft.response.meetingTemplates.find(
              (meetingTemplate) => getIsIndividualQuestionsTemplate(meetingTemplate),
            );
            individualQuestionsTemplate?.children.push(newIndividualTopic);
          });
        }

        return originalData;
      });

      return { previousMeetingTemplates };
    },
    onError: (_, __, snapshot) => {
      toast.error('There was an error creating the topic. Please try again.', {
        autoClose: 1500,
      });

      queryClient.setQueryData(queryKeyForList, snapshot?.previousMeetingTemplates);
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: meetingTopicTemplatesQueryKeys.customTemplatesLists(getOrganizationId() ?? '') });
    },
  });
};
