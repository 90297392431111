import { ChangeEvent, useCallback, KeyboardEvent } from 'react';
import { useDraft } from '~Common/hooks/useDraft';
import { getOrganizationId } from '~Common/utils/localStorage';
import InputWithConfirmButtons from '~Common/components/InputWithConfirmButtons';
import { useShowItemEditor } from '../../stores/useShowItemEditor';

interface ViewProps {
  initialValue?: string,
  handleSectionTitleChange: (event: ChangeEvent<HTMLInputElement>) => void,
  handleSaveSectionTitle: () => void,
  handleCancelSectionTitle: () => void,
  editorText: string,
  handleKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void,
}

const View = ({
  initialValue,
  handleSectionTitleChange,
  handleSaveSectionTitle,
  handleCancelSectionTitle,
  editorText,
  handleKeyPress,
  ...props
}: ViewProps): JSX.Element => (
  <InputWithConfirmButtons {...props}>
    <InputWithConfirmButtons.NormalInput
      label="Section Title"
      initialValue={initialValue}
      onChange={handleSectionTitleChange}
      autoFocus
      onKeyPress={handleKeyPress}
    />
    <InputWithConfirmButtons.SaveConfirmButtons
      dataTestIdPrefix="meetingTopicSuggestionsSaveSection"
      handleSave={handleSaveSectionTitle}
      handleCancel={handleCancelSectionTitle}
      disableSaveButton={!editorText}
    />
  </InputWithConfirmButtons>
);

interface AgendaSectionTitleEditorProps {
  sectionLocalId: string,
  agendaSectionText?: string,
  onCancel?: () => void,
  onSave?: (sectionTitle: string) => void,
}

const AgendaSectionTitleEditor = ({
  sectionLocalId,
  agendaSectionText,
  onCancel,
  onSave,
  ...props
}: AgendaSectionTitleEditorProps): JSX.Element => {
  const organizationId = getOrganizationId();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const agendaSectionDraftKey = [organizationId!, 'templates', 'agendaSection', sectionLocalId];
  const { draft, setDraft, removeDraft } = useDraft(agendaSectionDraftKey);
  const editorText = draft.value as string;

  const hideItemEditor = useShowItemEditor((state) => state.hideItemEditor);

  const handleSectionTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDraft({
      ...draft,
      value: event.target.value,
    });
  }, [
    draft,
    setDraft,
  ]);

  const handleSaveSectionTitle = useCallback(() => {
    onSave?.(editorText);
    removeDraft();
    hideItemEditor(sectionLocalId);
  }, [editorText, hideItemEditor, onSave, removeDraft, sectionLocalId]);

  const handleCancelSectionTitle = useCallback(() => {
    onCancel?.();
    hideItemEditor(sectionLocalId);
  }, [hideItemEditor, onCancel, sectionLocalId]);

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      if (editorText?.length > 0) {
        handleSaveSectionTitle();
      }
    }
  }, [editorText?.length, handleSaveSectionTitle]);

  const hookProps = {
    initialValue: editorText !== undefined ? editorText : agendaSectionText,
    handleSectionTitleChange,
    handleSaveSectionTitle,
    handleCancelSectionTitle,
    editorText,
    handleKeyPress,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaSectionTitleEditor;
