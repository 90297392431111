import { UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { useCreateNote, CreateNoteData } from '~Common/hooks/useCreateNote';
import { GetNotesResponse } from '~Common/hooks/useGetNotes';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { Note } from '~Common/const/interfaces';
import moment from 'moment-timezone';
import { getLocalId } from '~Common/utils/uuid';
import { cloneDeep } from 'lodash';

export const useCreateActionItemComment = (): UseMutationResult<HttpCallReturn<null>, unknown, CreateNoteData> => {
  const orgId = getOrganizationId();
  const orgUserId = getOrganizationUserId();

  return useCreateNote({
    onMutate: async (variables) => {
      const { note, context } = variables;
      const [queryKey, queryData] = queryClient.getQueriesData({ queryKey: [orgId, 'notes', context.type, context.id] })[0];
      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<HttpCallReturn<GetNotesResponse>>(queryKey, (originalData) => {
        if (originalData) {
          const updatedData = cloneDeep(originalData);
          const newNote: Note = {
            id: getLocalId(),
            creator: orgUserId ?? '',
            rank: note.rank,
            text: note.text,
            createdInMillis: moment().valueOf(),
          };

          updatedData?.response.notes.push(newNote);

          return updatedData;
        }

        return originalData;
      });

      return { queryKey, queryData };
    },
    onSettled: async (_, __, variables) => {
      const { context } = variables;

      await queryClient.invalidateQueries({ queryKey: [orgId, 'notes', context.type, context.id] });
      await queryClient.invalidateQueries({ queryKey: [orgId, 'action-items'] });
      await queryClient.invalidateQueries({ queryKey: [orgUserId, 'goals'] });
    },
    onError: () => {
      toast.error('Action Item comment could not be created');
    },
  });
};
