import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { postApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import {
  FrontendPlaylistLearning, LearningStatus, LearningType, AssignedLearning, ReceivedLearning, AssignLearningOptions, LearningPersonDisplayInformation,
} from '~Learning/const/interfaces';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { UserPendingItems, USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';
import { OPTIMISTIC_ID } from '~Learning/const/index';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import {
  CreateLearningParticipantsDrawerState,
  createLearningParticipantsDrawerTemplate,
} from '~Learning/components/CreateLearningDrawer/CreateLearningParticipantsDrawerTemplate';
import { PartialDrawerState } from '~Common/const/drawers';
import { receivedLearningQueryKeys } from '../received/queryKeys';
import { assignedLearningQueryKeys } from './queryKeys';

interface CreateLearningPlaylistParams {
  title: string,
  description: string,
  dueDate: Date,
  assigneeUserIds: string[],
  learnings: FrontendPlaylistLearning[],
}

export interface CreateLearningPlaylistReturn {
  playlistId: string,
  organizationId: string,
  assigneeUserIds: string[],
}

const createLearningPlaylist = async (
  createLearningPlaylistRequest: CreateLearningPlaylistParams,
): Promise<HttpCallReturn<CreateLearningPlaylistReturn>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/create`;

  return postApi<CreateLearningPlaylistReturn>(serverUrl, createLearningPlaylistRequest);
};

export const useCreateLearningPlaylist = (
  setDrawerState: (
    callback: (prev: PartialDrawerState<CreateLearningParticipantsDrawerState>) => PartialDrawerState<CreateLearningParticipantsDrawerState>) => void,
): UseMutateFunction<HttpCallReturn<CreateLearningPlaylistReturn>, unknown, CreateLearningPlaylistParams> => {
  const dispatch = useDispatch();

  // TODO: Remove the casting when useNewPeople gets typed
  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, LearningPersonDisplayInformation>>;
  const currentUserId = getUserId() ?? '';

  const mutation = useMutation({
    mutationFn: createLearningPlaylist,
    onMutate: async (newPlaylist: CreateLearningPlaylistParams) => {
      toast.info('Creating your learning...', {
        autoClose: 5000,
      });

      // Cancel any existing outbound queries
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });

      const previousReceivedLearningList = queryClient.getQueryData(receivedLearningQueryKeys.list());
      const previousAssignedLearningList = queryClient.getQueryData(assignedLearningQueryKeys.list());
      const previousUserPendingItems = queryClient.getQueryData(USER_PENDING_ITEMS_QUERY_KEY);

      const commonPlaylistDetails = {
        id: OPTIMISTIC_ID,
        title: newPlaylist.title,
        createdDateTime: new Date().toISOString(),
        dueDate: newPlaylist.dueDate?.toISOString() || undefined,
        description: newPlaylist.description,
        learningType: LearningType.PLAYLIST,
        status: LearningStatus.INCOMPLETE,
      };

      // Get first name, last name, etc from the assigneeUserIds
      const assignedUsersInfo = newPlaylist.assigneeUserIds.map((assigneeUserId) => peopleData[assigneeUserId]);

      const newAssignedPlaylist = {
        ...commonPlaylistDetails,
        assignedUsersInfo,
        numberOfUsersCompleted: 0,
        numberOfUsersAssigned: newPlaylist.assigneeUserIds.length,
      };

      queryClient.setQueryData<AssignedLearning[]>(assignedLearningQueryKeys.list(), (oldAssignedLearningList) => {
        if (oldAssignedLearningList) {
          if (oldAssignedLearningList?.length) {
            return [...oldAssignedLearningList, newAssignedPlaylist].sort(
              (learning1, learning2) => (learning2.createdDateTime < learning1.createdDateTime ? -1 : 1),
            );
          }

          return [newAssignedPlaylist];
        }

        return oldAssignedLearningList;
      });

      // If the user assigned the playlist to themselves, update the received learning list with the new playlist
      if (newPlaylist.assigneeUserIds.includes(currentUserId)) {
        const assignerPersonInformation = peopleData[currentUserId];

        const newReceievedPlaylist = {
          ...commonPlaylistDetails,
          assigner: assignerPersonInformation,
          totalLearningsCount: newPlaylist.learnings.length,
          completedLearningsCount: 0,
        };

        queryClient.setQueryData(receivedLearningQueryKeys.list(), (oldReceivedLearningList?: ReceivedLearning[]) => {
          if (oldReceivedLearningList?.length) {
            return [...oldReceivedLearningList, newReceievedPlaylist].sort(
              (learning1, learning2) => (learning2.createdDateTime < learning1.createdDateTime ? -1 : 1),
            );
          }

          return [newReceievedPlaylist];
        });

        // Update the pendingLearningCount as well, since they have a new learning
        queryClient.setQueryData<HttpCallReturn<UserPendingItems>>(
          USER_PENDING_ITEMS_QUERY_KEY,
          (oldUserPendingItems) => oldUserPendingItems && ({
            ...oldUserPendingItems,
            response: {
              ...oldUserPendingItems.response,
              pendingLearningCount: oldUserPendingItems.response.pendingLearningCount + 1,
            },
          }),
        );
      }

      // Return a context object with the old snapshotted value
      return {
        previousReceivedLearningList,
        previousAssignedLearningList,
        previousUserPendingItems,
      };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: assignedLearningQueryKeys.list() });
      // Invalidate the learning notification dot and received learning list, since you can send a learning playlist to yourself :)
      await queryClient.invalidateQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
    },
    onError: (_, newPlaylist, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(receivedLearningQueryKeys.list(), snapshot?.previousReceivedLearningList);
      queryClient.setQueryData(assignedLearningQueryKeys.list(), snapshot?.previousAssignedLearningList);
      queryClient.setQueryData(USER_PENDING_ITEMS_QUERY_KEY, snapshot?.previousUserPendingItems);

      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));
      // Reopen the drawer
      dispatch(pushDrawerAction({
        drawer: createLearningParticipantsDrawerTemplate,
      }));

      // Fill drawer state with the playlist that was being created
      setDrawerState((prev) => ({
        ...prev,
        playlistTitle: newPlaylist.title,
        playlistDescription: newPlaylist.description,
        playlistDueDate: newPlaylist.dueDate,
        playlistContentList: newPlaylist.learnings,
        shouldConfirmDrawerClose: true,
        workflow: AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST,
        selectedAttendees: newPlaylist.assigneeUserIds,
      }));

      toast.error('There was an error sending the learning playlist. Please try again.', {
        autoClose: 1500,
      });
    },
  });

  return mutation.mutate;
};
