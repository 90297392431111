import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import {
  topicItemBg, topicItemColor,
} from '~Deprecated/ui/styles/colors';
import { noop } from '~Deprecated/utils';
import { DRAG_HANDLE_PROPS_SHAPE, SURVEY_MULTIPLE_CHOICE_SHAPE } from '~Common/const/proptypes';
import Tooltip from '~Common/components/Tooltip';
import { withTruncate } from '~Deprecated/ui/styles/mixins';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import EditMultipleChoiceOption from './EditMultipleChoiceOption';

const styles = {
  box: css`
    background-color: ${topicItemBg};
    border-radius: 8px;
    color: ${topicItemColor};
    display: flex;
    overflow: hidden;
    line-height: 1.65em;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 12px 0 12px 16px;
  `,
  disabled: css`
    cursor: unset;
  `,
  dragIcon: css`
    margin-right: 20px;
    color: ${palette.neutrals.gray700};
  `,
  surveyQuestionDetails: css`
    display: flex;
    flex-direction: column;
    ${withTruncate()};
  `,
  textBox: css`
    ${withTruncate()};
  `,
  container: css`
    display: flex;
    align-items: center;
    overflow: hidden;
  `,
  editIcon: css`
    padding: 0 8px;
    color: ${palette.neutrals.gray700};
  `,
  editOption: css`
    padding-right: 0px;
  `,
  rightIconsContainer: css`
    display: flex;
    flex-shrink: 0;
  `,
};

const View = ({
  option,
  isEditing,
  setIsEditing,
  onEditSave,
  onEditCancel,
  className,
  index,
  disabled,
  dragHandleProps,
  onDelete,
}) => (
  <>
    {isEditing && (
      <EditMultipleChoiceOption option={option} index={index} onEditSave={onEditSave} onEditCancel={onEditCancel} />
    )}
    {!isEditing && (
      <div
        css={disabled ? [styles.box, styles.disabled] : styles.box}
        className={className}
      >
        <div css={styles.container}>
          <div css={styles.dragIcon} {...dragHandleProps}>
            <FontAwesomeIcon size="lg" icon={faGripVertical} />
          </div>
          <div css={styles.surveyQuestionDetails}>
            <div css={styles.textBox}>
              {option.option}
            </div>
          </div>
        </div>
        <div css={styles.rightIconsContainer}>
          <Tooltip content="Edit">
            <div
              css={styles.editIcon}
              onClick={() => setIsEditing(true)}
              onKeyUp={() => setIsEditing(true)}
              role="button"
              tabIndex={0}
            >
              <FontAwesomeIcon icon={faEdit} />
            </div>
          </Tooltip>
          <DeleteButtonWithConfirmation
            renderDeleteButton={({ onClick }) => (
              <Tooltip
                content="Delete"
              >
                <div>
                  <LeadrButton
                    variant="text"
                    textButtonColor={palette.neutrals.gray700}
                    onClick={onClick}
                    data-test-id="surveysDeleteMultipleChoiceOption"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </LeadrButton>
                </div>
              </Tooltip>
            )}
            renderConfirmationButtons={({
              informationStyles,
              optionStyles,
              popoverButtonStyles,
            }) => (
              <DeleteConfirmationButtons
                onDelete={() => onDelete(index)}
                informationStyles={informationStyles}
                optionStyles={optionStyles}
                popoverButtonStyles={popoverButtonStyles}
              />
            )}
          />
        </div>
      </div>
    )}
  </>
);

View.propTypes = {
  option: SURVEY_MULTIPLE_CHOICE_SHAPE,
  className: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  dragHandleProps: DRAG_HANDLE_PROPS_SHAPE.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  onEditSave: PropTypes.func,
  onEditCancel: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
};

View.defaultProps = {
  option: {},
  className: '',
  index: 0,
  disabled: false,
  onEditSave: noop,
  onEditCancel: noop,
};

const SurveyQuestionCard = ({
  index, option, onUpdateOption, ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const hookProps = {
    isEditing,
    setIsEditing,
    onEditCancel: (editText) => {
      if (editText !== '') {
        setIsEditing(false);
      }
    },
    onEditSave: (editText) => {
      if (editText !== '') {
        onUpdateOption(editText, index);
        setIsEditing(false);
      }
    },
    index,
    option,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

SurveyQuestionCard.propTypes = {
  option: SURVEY_MULTIPLE_CHOICE_SHAPE,
  index: PropTypes.number,
  onUpdateOption: PropTypes.func,
};

SurveyQuestionCard.defaultProps = {
  option: {},
  index: 0,
  onUpdateOption: noop,
};

export default SurveyQuestionCard;
