import { Types } from 'ably';

let ably: Types.RealtimePromise;

export function GetAbly(): Types.RealtimePromise {
  return ably;
}

export function SetAbly(newValue: Types.RealtimePromise | null): void {
  // @ts-expect-error -- Making this correctly nullable will suck.
  ably = newValue;
}

export function logoutRealtime(): void {
  SetAbly(null);
}
