/* eslint-disable no-bitwise */
import {
  array,
  object,
  string,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const createRecognitionFormSchema = object({
  content: string().trim().required(),
  recipients: array(string()).required(),
});
export const createRecognitionFormResolver = yupResolver(createRecognitionFormSchema);

export type FormValues = InferType<typeof createRecognitionFormSchema>;

export interface CreateRecognitionDTO {
    content: string,
    recipients: string[],
}

export function conformToDto(data: FormValues): CreateRecognitionDTO {
  const result: CreateRecognitionDTO = {
    content: data.content ?? '',
    recipients: data.recipients as string[] ?? [],
  };
  return result;
}
