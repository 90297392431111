import { newQuestionTemplate } from '../Components/Drawers/NewQuestion';

export const useSurveyQuestions = ({
  setDrawerState,
  drawerName,
  pushDrawer,
  popAfter,
  drawerState,
  questionKey = 'surveyQuestions',
}) => {
  const onAddQuestion = () => {
    popAfter({ drawerName });
    pushDrawer({
      drawer: {
        ...newQuestionTemplate,
        args: {
          drawerName: newQuestionTemplate.name,
          drawerTitle: 'Add Question to Survey',
          questionKey,
          isEditing: false,
        },
      },
    });
  };

  const onEditQuestion = (question) => {
    popAfter({ drawerName });
    pushDrawer({
      drawer: {
        ...newQuestionTemplate,
        args: {
          drawerTitle: 'Edit Question',
          initialQuestion: question,
          questionKey,
          isEditing: true,
        },
      },
    });
  };

  const setSurveyQuestions = (questions) => {
    setDrawerState((prev) => ({
      ...prev,
      [questionKey]: questions,
    }));
  };

  const onRemoveQuestion = ({ isEditMode, question }) => {
    if (isEditMode) {
      // use confirmation drawer

    } else {
      // use drawer state
      const questions = drawerState[questionKey];
      const questionIndex = questions.findIndex((q) => question.rank === q.rank);

      questions.splice(questionIndex, 1);

      setDrawerState({
        ...drawerState,
        [questionKey]: questions,
      });
    }
  };

  return {
    onAddQuestion,
    onEditQuestion,
    onRemoveQuestion,
    questionKey,
    setSurveyQuestions,
  };
};
