import { getOrganizationName, getUserId } from '~Common/utils/localStorage';
import { APPLICATION_NAME, MetricType } from '~Common/const/loggingAndMetrics';
import { submitMetric } from './api';

interface CustomMetric {
  metricName: string,
  metricType: MetricType,
  data: number | string,
  additionalTags: string[],
}

interface UseMetricReturn {
  customMetric: (metric: CustomMetric) => Promise<void>,
}

export const useMetric = (): UseMetricReturn => {
  const customMetric = async ({
    metricName,
    metricType,
    data,
    additionalTags = [],
  }: CustomMetric): Promise<void> => {
    const metric = {
      applicationName: APPLICATION_NAME,
      metricName: `leadr.webClient.${metricName}`,
      metricType,
      data,
      tags: [
        `env:${process.env.REACT_APP_ENV?.toLowerCase() || ''}`,
        `source:${APPLICATION_NAME}`,
        `userId:${getUserId() || ''}`,
        `organization:${getOrganizationName() || ''}`,
        ...additionalTags,
      ],
    };

    await submitMetric(metric);
  };

  return {
    customMetric,
  };
};
