import { Route, Switch } from 'react-router-dom';

import {
  ALL_TEAMS,
  MY_TEAMS,
  PEOPLE,
  PEOPLE_ADMIN,
  PEOPLE_ADMIN_IMPORT,
  TEAMS,
  // TEAM_DETAILS_GOALS, TODO: Coming later
  TEAM_DETAILS_USERS,
} from '~Common/const/routes';
import PeopleTopbar from '~People/components/Topbars';
import PeoplePage from '~People/components';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import TeamsCard from '~People/components/Teams/components/TeamsCard';
import TeamDetailsCard from '~People/components/Teams/components/TeamDetails/TeamDetailsCard';
import TeamDetailsTopbar from '~People/components/Teams/components/TeamDetails/TeamDetailsTopbar';
import NewGetContent from '~Deprecated/ui/routes/layouts/NewGetContent';
import LayoutBridge from '~Deprecated/ui/routes/layouts/LayoutBridge';
import AdminImportLayout from '~Admin/routes/AdminImportLayout';
import AdminIndex from '~Admin/routes/AdminIndex';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';

interface ViewProps {
  webAppTeamsV2: boolean,
  showAdminPeoplePage: boolean,
}

const View = ({
  webAppTeamsV2,
  showAdminPeoplePage,
}: ViewProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[PEOPLE]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<PeopleTopbar initialRoute={PEOPLE} />}
            ChildComponent={<PeoplePage />}
          />
        </BaseLayout>
      )}
    />
    {webAppTeamsV2 && (
      <Route
        exact
        path={[TEAMS, MY_TEAMS, ALL_TEAMS]}
        render={() => (
          <BaseLayout>
            <ModuleLandingPageWrapper
              TopComponent={<PeopleTopbar initialRoute={TEAMS} />}
              ChildComponent={<TeamsCard />}
            />
          </BaseLayout>
        )}
      />
    )}
    {webAppTeamsV2 && (
      <Route
        exact
        path={[
          TEAM_DETAILS_USERS,
          // TEAM_DETAILS_GOALS, TODO: Coming later
        ]}
        render={() => (
          <BaseLayout>
            <ModuleLandingPageWrapper
              TopComponent={<TeamDetailsTopbar />}
              ChildComponent={<TeamDetailsCard />}
            />
          </BaseLayout>
        )}
      />
    )}
    {showAdminPeoplePage && (
      <Route
        exact
        path={[
          PEOPLE_ADMIN_IMPORT,
        ]}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={AdminImportLayout}
              />
            )}
          />
        )}
      />
    )}
    {showAdminPeoplePage && (
      <Route
        exact
        path={[
          PEOPLE_ADMIN,
        ]}
        render={() => (
          <BaseLayout>
            <ModuleLandingPageWrapper
              ChildComponent={<AdminIndex />}
            />
          </BaseLayout>
        )}
      />
    )}
    <SmartRedirect from="*" to={PEOPLE} />
  </Switch>
);

const People = (): JSX.Element => {
  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');
  const { isAdmin } = useUserPermissions();

  const showAdminPeoplePage = isAdmin && webAppTeamsV2;

  const hookProps = {
    webAppTeamsV2,
    showAdminPeoplePage,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default People;
