import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import {
  LearningPersonDisplayInformation,
  LearningStatus, QuestionResponse, ReceivedPlaylistLearning,
} from '~Learning/const/interfaces';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import ContentTab from '~Learning/components/ReceivedLearningDashboard/ReceivedLearningDrawer/ContentTab';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { useSubmitPlaylistLearningResponses } from '~Learning/hooks/received/useSubmitPlaylistLearningResponses';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  learningContentText: css({
    color: palette.neutrals.gray700,
    fontSize: '.625rem',
    letterSpacing: '0.2rem',
    paddingBottom: '1.5rem',
    borderBottom: `.0625rem solid ${palette.neutrals.gray200}`,
  }),
  learningTitle: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1.5rem',
    marginTop: '1.125rem',
  }),
  introduction: css({
    color: palette.neutrals.gray500,
    fontSize: '.8125rem',
    marginTop: '.625rem',
    marginBottom: '1.1563rem',
  }),
};

export const learningContentDrawerTemplate = {
  name: 'LEARNING_CONTENT_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

export interface LearningContentDrawerProps extends DrawerProps<unknown> {
  learning: ReceivedPlaylistLearning,
  learningIndex: number,
  totalLearningsCount: number,
  playlistId: number,
  assigner: LearningPersonDisplayInformation,
  clearSelectedLearning: () => void,
  setSelectedLearningId: (learningId: string) => void,
}

const LearningContentDrawer = ({
  learning,
  learningIndex,
  totalLearningsCount,
  playlistId,
  assigner,
  clearSelectedLearning,
  setSelectedLearningId,
  popDrawer,
}: LearningContentDrawerProps): JSX.Element => {
  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: learningContentDrawerTemplate.name });
  };

  const onCancel = (): void => {
    clearSelectedLearning();
    closeDrawerClick();
  };

  const submitResponses = useSubmitPlaylistLearningResponses({
    learningId: learning.id,
    learning,
    learningIndex,
    totalLearningsCount,
    playlistId,
    assigner,
    clearSelectedLearning,
    setSelectedLearningId,
  });

  const isReadOnly = learning.status === LearningStatus.COMPLETED;

  const hookProps = {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
      const formData = new FormData(event.currentTarget);

      const dataForApiCalls = Array.from(formData.entries()).map(([questionId, responseText]) => ({ questionId, responseText })) as QuestionResponse[];

      const learningData = {
        learningId: learning.id,
        responses: dataForApiCalls,
      };

      clearSelectedLearning();
      submitResponses({ learningId: learning.id, learningData });
    },
    renderHeader: () => (
      <DrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={onCancel}
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            tooltip="Close"
            size="large"
          />
        )}
        title="Learning Content"
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <div>
          <div css={styles.learningContentText}>{`LEARNING CONTENT ${learningIndex + 1}/${totalLearningsCount}`}</div>
          <div css={styles.learningTitle}>{learning.title}</div>
          {learning.introduction && (
            <HTMLRenderer css={styles.introduction} htmlText={learning.introduction} />
          )}
        </div>
        <ContentTab
          isReadOnly={isReadOnly}
          questions={learning.questions}
          contentUrl={learning.contentUrl}
          learningId={learning.id}
          assignerId={assigner.id}
          renderLeftButton={() => (
            <LeadrButton
              variant="ghost"
              onClick={onCancel}
              data-test-id="learningContentCancel"
            >
              Cancel
            </LeadrButton>
          )}
        />
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: learningContentDrawerTemplate.name,
  component: LearningContentDrawer,
});

export default LearningContentDrawer;
