import PropTypes from 'prop-types';
import { COLLEAGUE_TYPES } from '~Meetings/const/colleagueTypes';

export const ATTENDEE_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  profileImageUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const HIDDEN_VALUE_SHAPE = PropTypes.any;
export const DPICKER_PROPS_SHAPE = PropTypes.object;
export const BUTTON_PROPS_SHAPE = PropTypes.object;
export const FLAG_SHAPE = PropTypes.bool;
export const UNIQUE_ID = PropTypes.string;
export const FONT_AWESOME_ICON = PropTypes.object;
export const EMOTION_CSS_SHAPE = PropTypes.object;
export const BASEBALL_CARD_PERSONALITIES = PropTypes.object;
export const MATERIAL_STYLE_SHAPE = PropTypes.object;
export const RENDER_FUNCTION_SHAPE = PropTypes.func;
export const REF_SHAPE = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.object,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);
export const IMAGE_STREAM_DATA = PropTypes.any;
export const DRAWER_STATE_SHAPE = PropTypes.object;
export const SET_DRAWER_STATE_SHAPE = PropTypes.func;

export const RADIO_OPTIONS_SHAPE = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
}));

export const FEEDBACK_FILTER_OPTIONS_SHAPE = RADIO_OPTIONS_SHAPE;

export const QUESTION_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  text: PropTypes.string,
});

export const FEEDBACK_TEMPLATE_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  curatedTemplateId: PropTypes.string,
  owner: ATTENDEE_SHAPE,
  question: PropTypes.arrayOf(QUESTION_SHAPE),
  title: PropTypes.string,
  description: PropTypes.string,
});

export const DROPDOWN_ITEMS_SHAPE = PropTypes.arrayOf(PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  text: PropTypes.string,
}));

export const HISTORY_SHAPE = PropTypes.object;

export const ROUTE_PROPS_SHAPE = PropTypes.object;

export const FORM_FIELDS_SHAPE = PropTypes.array;

export const RIGHT_PANEL_SHAPE = PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.bool]);

export const INPUT_REF_SHAPE = PropTypes.func;

export const SCORING_TOOLTIP_CONTENT_SHAPE = PropTypes.shape({
  insightKey: PropTypes.string,
  insightValue: PropTypes.number,
  isAggregate: PropTypes.bool,
  tooltipWrapperCss: EMOTION_CSS_SHAPE,
  tooltipInnerWrapperCss: EMOTION_CSS_SHAPE,
});

export const MEETING_DETAILS_SHAPE = PropTypes.shape({
  attendees: PropTypes.arrayOf(ATTENDEE_SHAPE),
  attendeeUid: PropTypes.string,
  endtimeInMillis: PropTypes.number,
  startTimeInMillis: PropTypes.number,
  id: PropTypes.string,
  frequency: PropTypes.string,
  title: PropTypes.string,
});

export const DRAWER_TYPE_SHAPE = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  args: PropTypes.object, // It is unknown what will be passed in args, other than it being an object.
});

export const TOPIC_CONFIG_SHAPE = PropTypes.shape({
  pointScale: PropTypes.number,
  pointType: PropTypes.number,
});

export const TOPIC_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  topicText: PropTypes.string,
  type: PropTypes.number,
  topicConfig: TOPIC_CONFIG_SHAPE,
  text: PropTypes.string,
});

export const SLIDER_MARKS_SHAPE = PropTypes.arrayOf(PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.number,
}));

export const TIMELINE_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  user: ATTENDEE_SHAPE,
  title: PropTypes.string,
});

export const PERSONALITY_TYPE_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
});

export const PERSONALITY_DETAILS_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  commentary: PropTypes.string,
  label: PropTypes.string,
});

export const CREATOR_SHAPE = {
  ...ATTENDEE_SHAPE,
  // For some profile objects that have image url item in array
  personalInformation: PropTypes.arrayOf({
    profileImageUrl: PropTypes.string,
  }),
};

export const TOPIC_NOTE_ITEM_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  creator: PropTypes.arrayOf(CREATOR_SHAPE),
  text: PropTypes.string,
});

export const TASK_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  dueDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  creator: PropTypes.arrayOf(CREATOR_SHAPE),
  text: PropTypes.string,
  asignee: ATTENDEE_SHAPE,
  externalLink: PropTypes.string,
  onTrack: PropTypes.bool,
});

export const FEEDBACK_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  dueDate: PropTypes.number,
  title: PropTypes.string,
  objective: PropTypes.string,
});

export const SURVEY_SHAPE = PropTypes.shape({
  id: PropTypes.number,
  dueDate: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
});

export const SURVEY_QUESTION_SHAPE = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.number,
  text: PropTypes.string,
  description: PropTypes.string,
});

export const SURVEY_MULTIPLE_CHOICE_SHAPE = PropTypes.shape({
  id: PropTypes.string,
  text: PropTypes.string,
});

export const SURVEY_PARTICIPANT_SHAPE = PropTypes.shape({
  id: PropTypes.number,
  surveyId: PropTypes.number,
  participantUid: PropTypes.string,
  participant: PropTypes.shape({
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    emailAddress: PropTypes.string,
    profileImageUrl: PropTypes.string,
  }),
  completedOn: PropTypes.string,
  isDeleted: PropTypes.bool,
});

export const SURVEY_ANSWERS_SHAPE = PropTypes.arrayOf(PropTypes.shape({
  answer: PropTypes.shape({
    answer: PropTypes.string,
    id: PropTypes.number,
    participantId: PropTypes.number,
    questionId: PropTypes.number,
  }),
  participant: SURVEY_PARTICIPANT_SHAPE,
}));

export const SURVEY_QUESTIONS_AND_ANSWER_SHAPE = PropTypes.arrayOf(PropTypes.shape({
  answer: SURVEY_ANSWERS_SHAPE,
  question: SURVEY_QUESTION_SHAPE,
}));

export const SURVEY_BY_PARTICIPANT_ANSWERS_SHAPE = PropTypes.arrayOf(PropTypes.shape({
  participant: SURVEY_PARTICIPANT_SHAPE,
  questionsAndAnswers: SURVEY_QUESTIONS_AND_ANSWER_SHAPE,
}));

export const SURVEY_QUESTION_TYPES_SHAPE = PropTypes.arrayOf(PropTypes.shape({
  code: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
}));

export const SURVEY_CREATED_BY_SHAPE = PropTypes.shape({
  emailAddress: PropTypes.string,
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  jobTitle: PropTypes.string,
  lastName: PropTypes.string,
  profileImageUrl: PropTypes.string,
  uid: PropTypes.string,
});

export const ACTION_MENU_PROPS_SHAPE = PropTypes.shape({
  onClose: PropTypes.func.isRequired,
  anchorEle: PropTypes.elementType,
  doOpen: PropTypes.func.isRequired,
});

export const MENU_ITEMS_SHAPE = PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
})));

export const MEETING_TOPIC_SHAPE = PropTypes.shape({
  creator: PropTypes.arrayOf(CREATOR_SHAPE),
  id: PropTypes.string,
  isComplete: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isRecurring: PropTypes.bool,
  randomId: PropTypes.number,
  rank: PropTypes.number,
  text: PropTypes.string,
});

export const INDICATOR_SHAPE = PropTypes.shape({
  topics: PropTypes.string,
  frequency: PropTypes.string,
  location: PropTypes.string,
  tasks: PropTypes.string,
});

export const MEETING_SHAPE = PropTypes.shape({
  agendaItems: PropTypes.arrayOf(MEETING_TOPIC_SHAPE),
  attendee: PropTypes.arrayOf(ATTENDEE_SHAPE),
  endTimeInMillis: PropTypes.number,
  frequency: PropTypes.string,
  id: PropTypes.string,
  isDeletable: PropTypes.bool,
  isEditable: PropTypes.bool,
  location: PropTypes.string,
  meetingFactoryId: PropTypes.string,
  nextMeetingStartTimeInMillis: PropTypes.number,
  startTimeInMillis: PropTypes.number,
  title: PropTypes.string,
  upcomingMeetingId: PropTypes.string,
});

export const PERSON = PropTypes.shape({
  administrativeStatus: PropTypes.string,
  firstName: PropTypes.string,
  jobTitle: PropTypes.string,
  lastName: PropTypes.string,
  managerName: PropTypes.string,
  orgUserId: PropTypes.string,
  userId: PropTypes.string,
});

export const ORGANIZATION_SETTINGS_SHAPE = PropTypes.shape({
  customFavorite1Name: PropTypes.string,
  customFavorite2Name: PropTypes.string,
  customPersonality1Name: PropTypes.string,
  customPersonality1Url: PropTypes.string,
  customPersonality2Name: PropTypes.string,
  customPersonality2Url: PropTypes.string,
  enableBriggsMyers: PropTypes.bool,
  enableCustomFavorite1: PropTypes.bool,
  enableCustomFavorite2: PropTypes.bool,
  enableCustomPersonality1: PropTypes.bool,
  enableCustomPersonality2: PropTypes.bool,
  enableDisc: PropTypes.bool,
  enableEnneagram: PropTypes.bool,
  enableStrengthFinder: PropTypes.bool,
  orgSettingsId: PropTypes.string,
});

export const BASEBALL_CARD_CALLOUT_SHAPE = PropTypes.shape({
  type: PropTypes.string,
  message: PropTypes.string,
});

export const DRAG_HANDLE_PROPS_SHAPE = PropTypes.shape({
  'aria-describedby': PropTypes.string,
  'data-rbd-drag-handle-context-id': PropTypes.string,
  'data-rbd-drag-handle-draggable-id': PropTypes.string,
  draggable: PropTypes.bool,
  onDragStart: PropTypes.func,
  role: PropTypes.string,
});

export const ATTENDEE_SUGGESTION_DATA = PropTypes.shape({
  orgUserId: PropTypes.string.isRequired,
  factoryId: PropTypes.string,
});

export const ATTENDEE_SUGGESTIONS_SEARCH_DATA = PropTypes.shape({
  [COLLEAGUE_TYPES.DIRECT_REPORTS]: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.string), listData: ATTENDEE_SUGGESTION_DATA }),
  [COLLEAGUE_TYPES.LEADERS]: { list: PropTypes.arrayOf(PropTypes.string), listData: ATTENDEE_SUGGESTION_DATA },
  [COLLEAGUE_TYPES.PEERS]: { list: PropTypes.arrayOf(PropTypes.string), listData: ATTENDEE_SUGGESTION_DATA },
  [COLLEAGUE_TYPES.SKIP_LEVELS]: { list: PropTypes.arrayOf(PropTypes.string), listData: ATTENDEE_SUGGESTION_DATA },
  [COLLEAGUE_TYPES.OTHERS]: { list: PropTypes.arrayOf(PropTypes.string), listData: ATTENDEE_SUGGESTION_DATA },
});

export const FILTER_GROUP_MENU_ITEM_SHAPE = PropTypes.oneOfType(
  [
    PropTypes.shape({ text: PropTypes.string }),
    PropTypes.shape({
      text: PropTypes.string,
      showSubList: PropTypes.bool,
      subList: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
      })),
    }),
  ],
);

export const FILTER_GROUP_MENU_ITEMS_SHAPE = PropTypes.arrayOf(FILTER_GROUP_MENU_ITEM_SHAPE);

export const USER_GROUP_SHAPE = PropTypes.shape({
  teamId: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    orgUserId: PropTypes.string,
  })),
});

export const TEAM_DRAWER_TEAM_SHAPE = PropTypes.shape({
  name: PropTypes.string,
  teamName: PropTypes.string,
  teamId: PropTypes.string,
});
