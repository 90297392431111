import { getOrganizationUserId } from '~Common/utils/localStorage';
import { ParticipantTypeEnum, ReviewCycleDetail } from '~Reviews/V2/Const/types';

interface getParticipantReviewTypeProps {
  revieweeReviewId: string | null,
  isManager: boolean,
}

export function getParticipantReviewType(reviewCycle: ReviewCycleDetail): getParticipantReviewTypeProps {
  const { reviews } = reviewCycle;
  const orgId = getOrganizationUserId();
  let revieweeReviewId = null;
  let isManager = false;
  reviews.forEach((review) => {
    const myParticipantRecord = review.participants.find((participant: { orgUserId: string }) => participant.orgUserId === orgId);
    // This was put into place possibly before the BE was validating the reviewers and reviewees
    //    I'd like to keep this for now
    // TODO: Remove this if statement if the BE is validating the reviewers and reviewees
    // if (!myParticipantRecord) {
    //   return;
    // }
    if (myParticipantRecord?.participantTypeEnum !== ParticipantTypeEnum.Reviewee) {
      isManager = true;
      return;
    }
    revieweeReviewId = review.uid;
  });

  return {
    revieweeReviewId,
    isManager,
  };
}

interface useReturnRevieweeReviewIdProps {
  revieweeReviewId: string | null,
  isManager: boolean,
}

export const useReturnRevieweeReviewId = (fullReview: ReviewCycleDetail | undefined): useReturnRevieweeReviewIdProps => {
  const { revieweeReviewId, isManager } = getParticipantReviewType(fullReview as ReviewCycleDetail);

  return {
    revieweeReviewId,
    isManager,
  };
};
