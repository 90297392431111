import { css, SerializedStyles } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { withButtonStripper } from '~Common/styles/mixins';

const styles = {
  container: css({
    borderRadius: '0.5rem !important',
    display: 'flex',
    padding: '1rem',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray50,
  }, withButtonStripper()),
  leftAdornment: css({}),
  rightAdornment: css({}),
  textContainer: css({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    flexGrow: 1,
    margin: '0 1.25rem',
  }),
  contentText: css({
    fontSize: '1rem',
    fontWeight: 600,
    color: palette.neutrals.gray700,
  }),
  descriptionText: css({
    fontSize: '11px',
    color: palette.neutrals.gray700,
  }),
};

interface ListItemProps {
  content: string,
  description?: string,
  renderLeftAdornment: (leftAdornmentStyles: SerializedStyles) => JSX.Element,
  renderRightAdornment?: (rightAdornmentStyles: SerializedStyles) => JSX.Element,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

const ListItem = ({
  content,
  renderLeftAdornment,
  renderRightAdornment,
  onClick,
  description,
  ...props
}: ListItemProps): JSX.Element => (
  <button
    type="button"
    css={styles.container}
    onClick={onClick}
    {...props}
  >
    {renderLeftAdornment?.(styles.leftAdornment)}
    <div css={styles.textContainer}>
      <div css={styles.contentText}>{content}</div>
      {description && (
        <div css={styles.descriptionText}>{description}</div>
      )}
    </div>
    {renderRightAdornment?.(styles.rightAdornment)}
  </button>
);

export default ListItem;
