import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { EngagementCategory } from '~Insights/const/types';
import { getApi, HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { UserPerspective } from './useInsightsUserPerspective';

enum LeadrCoachApiUserType {
  Manager = 0,
  Executive = 1,
}

enum LeadrCoachApiCategory {
  Rapport = 0,
  Maximization = 1,
  Clarity = 2,
}

export interface LeadrCoachPrompt {
  id: number,
  orgUserId: string,
  prompt: {
    id: number,
    questionId: number,
    userType: LeadrCoachApiUserType,
    category: LeadrCoachApiCategory,
    prompt: string,
    action: {
        label: string,
        url: string,
    },
  },
  surveyId: number,
  questionId: number,
  created: Date,
  updated: Date | null,
  isCompleted: boolean,
  isArchived: boolean,
}

interface UseLeadrCoachPromptsReturn {
  data: LeadrCoachPrompt[] | undefined,
  isLoading: boolean,
}

export function translateLeadrCoachCategory(apiCategory: LeadrCoachApiCategory): EngagementCategory | null {
  switch (apiCategory) {
    case LeadrCoachApiCategory.Rapport:
      return EngagementCategory.rapport;
    case LeadrCoachApiCategory.Maximization:
      return EngagementCategory.maximization;
    case LeadrCoachApiCategory.Clarity:
      return EngagementCategory.clarity;
    default:
      return null;
  }
}

export function translateLeadrCoachUserType(userType: LeadrCoachApiUserType): UserPerspective {
  if (userType === LeadrCoachApiUserType.Executive) {
    return UserPerspective.executive;
  }

  return UserPerspective.manager;
}

function getLeadrCoachPromptsQueryKey(organizationId: string): string[] {
  return ['organization', organizationId, 'insights', 'leadrCoach', 'prompts'];
}

function getLeadrCoachPromptsQuery(organizationId: string): Promise<HttpCallReturn<LeadrCoachPrompt[]>> {
  const serverUrl = `/organizations/${organizationId}/surveys/leadrPulse/coachPrompts`;
  return getApi<LeadrCoachPrompt[]>(serverUrl);
}

export function useLeadrCoachPrompts(): UseLeadrCoachPromptsReturn {
  const organizationId = getOrganizationId() ?? '';
  const result = useQuery({
    queryKey: getLeadrCoachPromptsQueryKey(organizationId),
    queryFn: () => getLeadrCoachPromptsQuery(organizationId),
    staleTime: 12 * 60 * 60 * 1000,
  });

  return {
    data: result?.data?.response,
    isLoading: result.isLoading,
  };
}

interface CompleteLeadrCoachPromptMutationParams {
  organizationId: string,
  promptId: number,
}

function completeLeadrCoachPromptMutation({
  organizationId,
  promptId,
}: CompleteLeadrCoachPromptMutationParams): Promise<HttpCallReturn<void>> {
  const serverUrl = `/organizations/${organizationId}/surveys/leadrPulse/coachPrompts/complete/${promptId}`;
  return patchApi<void>(serverUrl, { organizationId, promptId });
}

export function useCompleteLeadrCoachPrompt(): UseMutationResult<HttpCallReturn<void>, unknown, CompleteLeadrCoachPromptMutationParams, unknown> {
  const organizationId = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: completeLeadrCoachPromptMutation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getLeadrCoachPromptsQueryKey(organizationId) });
    },
  });
}
