import { useMemo } from 'react';
import { css } from '@emotion/react';
import { faUserCheck } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import { PERSON } from '~Common/const/proptypes';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { ActionType } from '~Admin/const/defaults';
import DataGridTable from '~Common/components/Tables/DataGridTable';
import AdminActionMenu from './AdminActionMenu';
import { getFullName } from './ActiveUsers';

const styles = {
  avatar: css`
    margin-right: .5rem;
  `,
};

export const DeactivatedUsersTable = ({
  filteredUsers,
  pageSize,
  selectionModel,
  setSelectionModel,
  setActionType,
  activateUser,
  filterModel,
  setFilterModel,
}) => {
  const menuItems = (param) => [[{
    text: `Activate ${param.name}`,
    icon: faUserCheck,
    onClick: () => {
      const userData = filteredUsers.filter((user) => user.id === param.id);
      setActionType(ActionType.Activate);
      activateUser(userData);
    },
    dataTestId: 'actionMenuItem-activate',
  },
  ]];

  const tableColumnHeaders = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      maxWidth: 1750,
      renderCell: (cellValues) => (
        <>
          <SquareAvatar
            imgUrl={cellValues.row.name.image}
            name={cellValues.row.name.name}
            width={30}
            height={30}
            includeInitials
            css={styles.avatar}
          />
          <div className="MuiDataGrid-cellContent">{cellValues.row.name.name}</div>
        </>
      ),
      type: 'object',
      valueGetter: (params) => params.row.name.name,
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      maxWidth: 150,
      flex: 1,
      valueGetter: getFullName,
      hide: true,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      maxWidth: 750,
      flex: 1,
      hide: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      maxWidth: 750,
      flex: 1,
      hide: true,
    },
    {
      field: 'managerEmail',
      headerName: 'Manager Email',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      maxWidth: 100,
      align: 'right',
      headerAlign: 'right',
      renderCell: (cellValues) => (
        <AdminActionMenu menuItems={menuItems({ id: cellValues.row.id, name: cellValues.row.name.name })} />
      ),
    },
  ];

  const userRows = useMemo(() => filteredUsers.map((user) => ({
    id: user.id,
    name: { name: `${user.firstName} ${user.lastName}`, image: user.profileImageUrl },
    firstName: user.firstName,
    lastName: user.lastName,
    managerEmail: user.managerEmail,
    actions: user.id,
  })), [filteredUsers]);

  return (
    <DataGridTable
      rows={userRows}
      columns={tableColumnHeaders}
      pageSize={pageSize}
      selectionModel={selectionModel}
      setSelectionModel={setSelectionModel}
      filterModel={filterModel}
      setFilterModel={setFilterModel}
    />
  );
};

DeactivatedUsersTable.propTypes = {
  filteredUsers: PropTypes.arrayOf(PERSON).isRequired,
  pageSize: PropTypes.number.isRequired,
  setSelectionModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  selectionModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  setActionType: PropTypes.func,
  activateUser: PropTypes.func,
  setFilterModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  filterModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
};

DeactivatedUsersTable.defaultProps = {
  setActionType: '',
  activateUser: null,
};

export default DeactivatedUsersTable;
