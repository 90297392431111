import { useState } from 'react';
import { useIntl } from 'react-intl';
import authConstants from './constants';

const useAlert = (disableAutoHide) => {
  const [alertMessage, setAlertMessage] = useState(false);
  const { formatMessage } = useIntl();

  const showAlertMessage = (message, value, messageTimeout) => {
    if (message) {
      setAlertMessage(formatMessage({ id: message }, value));
    } else if (message === '') {
      setAlertMessage('');
    }
    return new Promise(((resolve, reject) => {
      if (!disableAutoHide) {
        setTimeout(() => {
          setAlertMessage('');
          resolve();
        }, messageTimeout || authConstants.alertTimeout);
      }
    }));
  };

  return {
    alertMessage,
    showAlertMessage,
  };
};

export default useAlert;
