import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';

export enum CloudAttachmentFileSource {
  GoogleDrive = 'GoogleDrive',
  OneDrive = 'OneDrive',
}

export interface RemoveAttachmentsProps {
  organizationId: string,
  huddleId: string,
  topicId: string,
  removeAttachmentIds: number[],
}

export const removeAttachments = async ({
  organizationId,
  huddleId,
  topicId,
  removeAttachmentIds,
}: RemoveAttachmentsProps): Promise<HttpCallReturn<null>> => {
  const url = {
    host: getHost(hosts.action, '2'),
    uri: `/organizations/${organizationId}/huddles/${huddleId}/agendas/${topicId}/removeAttachments`,
  };

  return patchApi(url, { removeAttachmentIds });
};

export interface RemoveAttachmentsRequest {
  huddleId: string,
  removeAttachmentIds: number[],
  topicId: string,
}

export const useRemoveAttachments = (options?: UseMutationOptions<HttpCallReturn<null>, unknown, RemoveAttachmentsRequest>)
  : UseMutationResult<HttpCallReturn<null>, unknown, RemoveAttachmentsRequest> => {
  const organizationId = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: ({
      huddleId,
      removeAttachmentIds,
      topicId,
    }: RemoveAttachmentsRequest) => removeAttachments({
      organizationId,
      huddleId,
      topicId,
      removeAttachmentIds,
    }),
    ...options,
  });
};
