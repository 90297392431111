import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Froala from '~Common/V3/components/Froala';
import { usePrintView } from '~Common/stores/usePrintView';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';
import { getParticipantTypeName } from '../Const/functions';
import { REVIEW_DISPLAY_STYLES } from '../Const/pageStyles';
import {
  AssignedReflection,
  linearTopicConfig,
  multiChoiceTopicConfig,
  PersonDisplayInformationReviews,
  ReviewAnswer,
  TopicShape,
  TopicTypeEnum,
} from '../Const/types';
import { DisplayReflection } from '../reflections/DisplayReflection';
import { ReadOnlyLinearScale } from './ReadOnlyLinearScale';

const styles = {
  ...REVIEW_DISPLAY_STYLES,
  infoMessage: css({
    fontStyle: 'italic',
    color: palette.neutrals.gray800,
    fontSize: '0.875rem',
    fontWeight: 400,
  }),
  explanation: css({
    marginTop: '2rem',
    padding: 0,
  }),
  noPadding: css({
    padding: 0,
  }),
  participantName: css({
    color: palette.neutrals.gray800,
    fontWeight: 500,
    fontSize: '1rem',
    marginBottom: '0.1875rem',
    pageBreakAfter: 'avoid',
    pageBreakBefore: 'avoid',
  }),
  participantType: css({
    marginBottom: '0.875rem',
    color: palette.neutrals.gray700,
    fontWeight: 400,
    pageBreakAfter: 'avoid',
    pageBreakBefore: 'avoid',
  }),
  reflection: css({
    backgroundColor: palette.neutrals.white,
  }),
  selectedOption: css({
    fontSize: '1rem',
    fontWeight: 600,
    marginTop: '1.5rem',
  }),
  shader: (isPrintView: boolean) => css({
    background: palette.neutrals.gray50,
    padding: '1.5rem',
    borderRadius: '.5rem',
    marginBottom: '1.125rem',

    '& label': {
      backgroundColor: `${palette.neutrals.gray300} !important`,
    },
  }, isPrintView && {
    background: 'none',
    border: `1px solid ${palette.neutrals.gray600}`,
  }),
  printDescription: css({
    lineHeight: '2rem',
    fontSize: '1rem',
    pageBreakBefore: 'avoid',
    pageBreakAfter: 'avoid',
  }),
};

interface ViewProps {
  isPrintView: boolean,
  linearChoiceOptions?: linearTopicConfig,
  numericResponse?: number,
  participantName: string,
  participantType: string,
  reflectionRecords?: AssignedReflection[],
  reviewUid: string,
  selectedOption: string,
  stringResponse?: HTMLString,
  topicTypeEnum: TopicTypeEnum,
}

const View = ({
  isPrintView,
  linearChoiceOptions,
  numericResponse,
  participantName,
  participantType,
  reflectionRecords,
  reviewUid,
  selectedOption,
  stringResponse,
  topicTypeEnum,
}: ViewProps): JSX.Element => (
  <div css={styles.shader(isPrintView)}>
    <div css={styles.participantName}>
      {participantName}
    </div>
    <div css={styles.participantType}>
      {participantType}
    </div>
    {topicTypeEnum === TopicTypeEnum.Freeform && (
      <div css={styles.answerDescription}>
        {isPrintView && stringResponse && (
          <HTMLRenderer css={styles.printDescription} htmlText={stringResponse} />
        )}
        {!isPrintView && (
          <Froala
            styleOverrides={styles.noPadding}
            enableEmojis
            label=""
            name="stringResponse"
            froalaConfigProps={{
              charCounterCount: true,
              charCounterMax: 2000,
            }}
            isReadOnly
            richTextEditorProps={{
              name: 'stringResponse',
              initialValue: stringResponse,
            }}
          />
        )}

      </div>
    )}

    {topicTypeEnum === TopicTypeEnum.MultipleChoice && selectedOption !== '' && (
      <div css={styles.selectedOption}>
        {`"${selectedOption}"`}
      </div>
    )}

    {topicTypeEnum === TopicTypeEnum.Linear && linearChoiceOptions && (
      <ReadOnlyLinearScale
        maxValue={linearChoiceOptions.numScale}
        minLabel={linearChoiceOptions.minLabel}
        maxLabel={linearChoiceOptions.maxLabel}
        value={numericResponse}
      />
    )}

    {topicTypeEnum !== TopicTypeEnum.Freeform && stringResponse && (
      <Froala
        styleOverrides={styles.explanation}
        enableEmojis
        isReadOnly
        label="Explanation"
        name="stringResponse"
        froalaConfigProps={{
          charCounterCount: true,
          charCounterMax: 2000,
        }}
        richTextEditorProps={{
          name: 'stringResponse',
          initialValue: stringResponse,
        }}
      />
    )}
    {reflectionRecords && reflectionRecords.length > 0 && (
    <>
      <div
        css={styles.reflectionWrapper}
      >
        {reflectionRecords.map((reflection) => (
          <DisplayReflection
            reflection={reflection}
            reviewUid={reviewUid}
            addedStyles={styles.reflection}
            showHoverMenu={false}
          />
        ))}
      </div>
    </>
    )}
  </div>
);

interface SingleReviewResponseProps {
  answerRecord?: ReviewAnswer,
  hasReviewerReleased: boolean,
  participant: PersonDisplayInformationReviews,
  reflectionRecords?: AssignedReflection[],
  reviewUid: string,
  topic: TopicShape,
}

export const SingleReviewResponse = ({
  answerRecord,
  hasReviewerReleased,
  participant,
  reflectionRecords,
  reviewUid,
  topic,
}: SingleReviewResponseProps): JSX.Element => {
  const isPrintView = usePrintView((state) => state.isPrintView);
  const { firstName, lastName, participantTypeEnum } = participant;
  const participantName = `${firstName} ${lastName}`;
  const participantType = getParticipantTypeName(participantTypeEnum);

  if (!answerRecord) {
    if (!hasReviewerReleased) {
      return (
        <>
          <div css={styles.participantName}>
            {participantName}
          </div>
          <div css={styles.participantType}>
            {participantType}
          </div>
          <div css={styles.infoMessage}>
            Waiting for reviewer to release review
          </div>
        </>
      );
    }

    return (
      <>
        <div css={styles.participantName}>
          {participantName}
        </div>
        <div css={styles.participantType}>
          {participantType}
        </div>
        <div css={styles.infoMessage}>
          Waiting for response
        </div>
      </>
    );
  }

  const { numericResponse, stringResponse } = answerRecord;

  const { typeEnum: topicTypeEnum, typeConfig } = topic;

  let multipleChoiceOptions: multiChoiceTopicConfig | undefined;
  let linearChoiceOptions;
  if (typeof typeConfig === 'string' && typeConfig) {
    multipleChoiceOptions = JSON.parse(typeConfig) as multiChoiceTopicConfig;
    linearChoiceOptions = JSON.parse(typeConfig) as linearTopicConfig;
  }

  let selectedOption = '';
  if (topicTypeEnum === TopicTypeEnum.MultipleChoice && numericResponse !== undefined && multipleChoiceOptions) {
    selectedOption = multipleChoiceOptions.multipleChoiceOptions?.[numericResponse - 1]?.text;
  }

  const hookProps = {
    isPrintView,
    linearChoiceOptions,
    numericResponse,
    participantName,
    participantType,
    reflectionRecords,
    reviewUid,
    selectedOption,
    stringResponse,
    topicTypeEnum,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
