import { useState } from 'react';
import { useIsMobileQuery } from './useMediaListener';

export interface HoverState {
  isHovering: boolean,
  handleMouseEnter: () => void,
  handleMouseLeave: () => void,
}

export const useHoverState = (): HoverState => {
  const isMobile = useIsMobileQuery();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (): void => {
    setIsHovering(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
  };

  return {
    isHovering: isHovering || isMobile, // Mobile doesn't have a hover, so we want these things to always be visible
    handleMouseEnter,
    handleMouseLeave,
  };
};
