import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { faArrowsRotate, faTimes } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { useRestoreMeeting } from '~Meetings/hooks/useRestoreMeeting';
import DateTimePicker from '~Meetings/components/shared/DateTimePicker';
import DrawerInput from '~Common/V3/components/DrawerInput';
import DrawerDropdown from '~Deprecated/ui/components/Inputs/DrawerDropdown';
import { WeekdaySelection } from '~Deprecated/ui/components/WeekdaySelection';
import useMeetingDateTimeFrequencyWeekdayTimezone from '~Meetings/hooks/useMeetingDateTimeFrequencyWeekdayTimezone';
import { MEETING_TYPES } from '~Meetings/const/meetingTypes';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { ONE_TIME_MEETING } from '~Common/const/constraints';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';

const styles = {
  container: css`
    padding: 0.5rem 1.5rem;
  `,
  detailsContainer: css`
    margin-block-start: 1rem;
  `,
  drawerInput: css`
    margin: 10px 0 !important;
  `,
  weekdaySelection: css`
    padding-inline: 0.5rem;
    margin-bottom: 25px;
  `,
};

export const restoreMeetingTemplate = {
  name: 'RESTORE_MEETING',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton,
  createButton,
  renderDrawerInstructions,
  onFormSubmit,
  renderForm,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={closeButton}
        title="Restore Meeting"
      />
    )}
    renderBody={() => (
      <div
        css={styles.container}
      >
        {renderDrawerInstructions()}
        {renderForm()}
        <div />
        {createButton()}
      </div>
    )}
  />
);

View.propTypes = {
  closeButton: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
  renderForm: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

View.defaultProps = {};

const RestoreMeeting = ({
  drawerState, factoryId, id, type, ...props
}) => {
  const dispatch = useDispatch();
  const doRestoreMeeting = useRestoreMeeting({ template: restoreMeetingTemplate });
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const { frequency } = meetingDetails ?? {};
  const initialDate = useMemo(() => moment(), []);

  // Moment day() uses Sunday = 0, so we need to convert it to our base (Monday = 0)
  const defaultSelectedDay = useMemo(() => (initialDate.day() + 6) % 7, [initialDate]);

  const {
    timezone, setSelectedDays, onDateChange, selectedFrequency, defaultWeekday, frequencyOptions,
    onFrequencyChange, showWeekdaySelection, timezoneFriendlyName, selectedDays,
  } = useMeetingDateTimeFrequencyWeekdayTimezone({
    defaultFrequency: frequency,
    showFrequency: true,
    isOneOnOne: type === MEETING_TYPES.COACHING,
    isAlreadyRecurring: frequency !== ONE_TIME_MEETING,
    initialSelectedDays: [defaultSelectedDay],
  });

  const renderDrawerInstructions = () => {
    const drawerText = 'Select a date to schedule the next occurrence of this meeting.';
    const drawerSubText = 'This will move this Meeting back into your upcoming meetings and continue scheduling as normal.';

    return (
      <div>
        <DrawerInstructions
          html={drawerText}
        />
        <br />
        <DrawerInstructions
          html={drawerSubText}
        />
      </div>
    );
  };

  const renderForm = useCallback(() => (
    <div css={styles.detailsContainer}>
      <DateTimePicker
        css={styles.drawerInput}
        initialDate={initialDate.valueOf()}
        onDateChange={onDateChange}
        nextMeeting={() => false}
        startTimeName="startTimeInMillis"
        endTimeName="endTimeInMillis"
        label="Start Date"
      />

      {timezone && (
        <>
          <DrawerInput
            disabled
            css={styles.drawerInput}
            label="Timezone"
            name="timezoneFriendlyName"
            value={timezoneFriendlyName || timezone}
          />
          <input
            readOnly
            type="hidden"
            name="timezone"
            value={timezone}
          />
        </>
      )}

      <DrawerDropdown
        fontAwesomeIcon={faArrowsRotate}
        name="frequency"
        items={frequencyOptions}
        label="Frequency"
        onChange={onFrequencyChange}
        value={selectedFrequency}
        css={styles.drawerInput}
      />
      {showWeekdaySelection && (
        <WeekdaySelection
          css={styles.weekdaySelection}
          keepSelected={defaultWeekday}
          selected={selectedDays}
          onSelectionChange={(days) => {
            setSelectedDays(days);
          }}
        />
      )}
    </div>
  ), [
    defaultWeekday,
    frequencyOptions,
    initialDate,
    onDateChange,
    onFrequencyChange,
    selectedFrequency,
    setSelectedDays,
    showWeekdaySelection,
    timezone,
    timezoneFriendlyName,
    selectedDays,
  ]);

  const createButton = useCallback(() => (
    <LeadrButton
      data-test-id="meetingRestore"
      type="submit"
    >
      Restore Meeting
    </LeadrButton>
  ), []);

  const onFormSubmit = useCallback((e) => {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target).entries());
    const restoreMeetingData = {
      factoryId,
      daysOfWeek: selectedDays,
      endTimeInMillis: parseInt(formData.endTimeInMillis, 10) || (parseInt(formData.startTimeInMillis, 10) + (1000 * 60 * 60)),
      startTimeInMillis: parseInt(formData.startTimeInMillis, 10),
      timezone,
      frequency,
    };

    doRestoreMeeting(restoreMeetingData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factoryId, selectedDays, doRestoreMeeting]);

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  // Need to set a date for the Frequency options to properly populate
  useEffect(() => {
    // Use moment to account for the user selected timezone if any
    onDateChange(moment().valueOf());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hookProps = {
    closeButton,
    createButton,
    renderDrawerInstructions,
    onFormSubmit,
    renderForm,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

RestoreMeeting.propTypes = {
  factoryId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  drawerState: PropTypes.func.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

RestoreMeeting.defaultProps = {};

registerDrawer({
  templateName: restoreMeetingTemplate.name,
  component: RestoreMeeting,
});

export { View };
export default RestoreMeeting;
