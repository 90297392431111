import { produce } from 'immer';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { toast } from '~Common/components/Toasts';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { queryClient } from '~Common/const/queryClient';
import { useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { getLocalId } from '~Common/utils/uuid';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';
import { AgendaType } from '@leadr-hr/types';

interface CreateSection {
  text: string
  isRecurring: boolean,
}

interface CreateAgendaSectionRequest {
  section: CreateSection,
  huddleId: string,
  eventId?: string,
}

interface CreateAgendaSectionResponse {
  id: string,
}

export const createAgendaSection = (data: CreateAgendaSectionRequest): Promise<HttpCallReturn<CreateAgendaSectionResponse>> => (
  postApi(`/organizations/${getOrganizationId() ?? ''}/huddles/agendas/sections`, data)
);

export const useCreateAgendaSection = (): UseMutationResult<HttpCallReturn<CreateAgendaSectionResponse>, unknown, CreateAgendaSectionRequest> => {
  const orgUserId = getOrganizationUserId();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { person } = useGetPersonById({ orgUserId: orgUserId! });

  return useMutation({
    mutationFn: createAgendaSection,
    onMutate: async (variables) => {
      const { section, huddleId } = variables;
      const previousAgendas = queryClient.getQueryData(meetingKeys.agendaList(huddleId));

      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId), (originalData) => {
        if (originalData && person) {
          return produce(originalData, (draft) => {
            draft.response.agendas.push({
              ...section,
              type: AgendaType.AgendaSection,
              id: getLocalId(),
              totalCompletedChildren: 0,
              createdInMillis: 0,
              lastModifiedInMillis: 0,
              children: [],
              permissions: [],
              creator: {
                orgUserId: person.orgUserId,
                firstName: person.firstName,
                lastName: person.lastName,
                profileImageUrl: person.profileImageUrl,
              },
            });
          });
        }

        return originalData;
      });

      return { previousAgendas };
    },
    onError: (_, variables, snapshot) => {
      const { huddleId: id } = variables;
      toast.error(<MeetingsErrorToast />, {
        autoClose: 1500,
      });

      queryClient.setQueryData(meetingKeys.agendaList(id), snapshot?.previousAgendas);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaLists() });
    },
  });
};
