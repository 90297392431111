import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import AttendeeList from '~Common/V3/components/Attendees/AttendeesList';
import { RENDER_FUNCTION_SHAPE, UNIQUE_ID } from '~Common/const/proptypes';
import DrawerHeader from '~Deprecated/ui/components/DrawerManager/DrawerHeader';
import DrawerButton from '~Deprecated/ui/components/Buttons/DrawerButton';
import { BUTTON_VARIANTS } from '~Common/const/buttonVariants';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';

const styles = {
  mainContainer: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
};

const AddAttendeeDrawer = ({
  selectedAttendees,
  onAttendeeSelect,
  closeDrawer,
  onSearch,
  attendees,
  isLoading,
  isAttendeeSelected,
  isSelectingAll,
  toggleSelectAll,
  limit,
  renderSearchBox,
  ...props
}) => {
  const doneButton = () => (
    <DrawerButton variant={BUTTON_VARIANTS.NEUTRAL} onClick={closeDrawer} text="Done" />
  );

  const toggleButton = () => (
    <DrawerButton variant={BUTTON_VARIANTS.NEUTRAL} onClick={toggleSelectAll} text={isSelectingAll ? 'Unselect All' : 'Select All'} />
  );

  return (
    <div
      css={styles.mainContainer}
    >
      <DrawerLayout
        renderHeader={() => (
          <>
            <DrawerHeader
              title="People"
              renderRightButton={doneButton}
              renderLeftButton={limit === null || (limit > 1 && limit > attendees.length) ? toggleButton : () => false}
            />
            {renderSearchBox()}
          </>
        )}
        renderBody={() => (
          <AttendeeList
            isAttendeeSelected={isAttendeeSelected}
            isLoading={isLoading}
            attendees={attendees}
            selectedAttendees={selectedAttendees}
            onClick={onAttendeeSelect}
            onSearch={onSearch}
            {...props}
          />
        )}
      />
    </div>
  );
};

AddAttendeeDrawer.propTypes = {
  selectedAttendees: PropTypes.arrayOf(UNIQUE_ID),
  onAttendeeSelect: PropTypes.func.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  attendees: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  isAttendeeSelected: PropTypes.func.isRequired,
  isSelectingAll: PropTypes.bool,
  toggleSelectAll: PropTypes.func.isRequired,
  limit: PropTypes.number,
  renderSearchBox: RENDER_FUNCTION_SHAPE,
};

AddAttendeeDrawer.defaultProps = {
  selectedAttendees: [],
  attendees: [],
  isLoading: true,
  isSelectingAll: false,
  limit: null,
  renderSearchBox: null,
};

export default AddAttendeeDrawer;
