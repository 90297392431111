import PropTypes from 'prop-types';

import { RIGHT_PANEL_SHAPE, ROUTE_PROPS_SHAPE } from '~Common/const/proptypes';

import NewParentView from '../../../views/ParentView/NewParentView';
import BaseLayout from './BaseLayout';
import ContentPane from './ContentPane';

/*
  This Component helps to bridge the gap between Layout 1.0 and 2.0

  It's a common interface into what Layout all our Routes use.

  Once we make the jump over to Layout 2.0, we need to refactor -where- the layout loads
  but for now lets just get stuff working.
*/
const View = ({
  routeProps,
  RightPanel,
  Component,
  ActionBar,
  useWidePanel,
}) => {
  const renderRightPanel = () => <RightPanel />;
  const renderActionBar = () => <ActionBar />;
  return (
    <BaseLayout>
      <ContentPane
        renderActions={ActionBar ? renderActionBar : null}
        renderContent={() => (

          <NewParentView
            routeProps={routeProps}
            Component={Component}
          />
        )}
        renderSideDrawer={RightPanel ? renderRightPanel : null}
        useWidePanel={useWidePanel}
      />
    </BaseLayout>
  );
};

View.propTypes = {
  routeProps: ROUTE_PROPS_SHAPE.isRequired,
  RightPanel: RIGHT_PANEL_SHAPE,
  ActionBar: PropTypes.element,
  Component: PropTypes.element.isRequired,
  useWidePanel: PropTypes.bool,
};

View.defaultProps = {
  useWidePanel: false,
  RightPanel: null,
  ActionBar: null,
};

const LayoutBridge = (props) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LayoutBridge;
