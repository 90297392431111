import { css } from '@emotion/react';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import LearningSectionsCard from '~Learning/components/Shared/LearningSectionsCard';
import { LearningType, LearningStatus } from '~Learning/const/interfaces';

import TitleAndIntroduction from '~Learning/components/Shared/TitleAndIntroduction';
import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';

const styles = {
  detailsTab: css({}),
};

interface DetailsTabProps {
  title: string,
  introduction: string,
  dueDate: string | undefined,
  status: LearningStatus,
}

const DetailsTab = ({
  title, introduction, dueDate, status,
}: DetailsTabProps): JSX.Element => (
  <div css={styles.detailsTab}>
    <LearningSectionsCard
      renderHeader={() => (
        <LearningTypeInfo
          learningType={LearningType.SINGLE_LEARNING}
        />
      )}
      renderBody={() => (
        <TitleAndIntroduction
          title={title}
          introductionHTML={introduction}
        />
      )}
      renderFooter={() => (
        <DueDateFromNow dueDate={dueDate} isComplete={status === LearningStatus.COMPLETED} />
      )}
    />
  </div>
);

export default DetailsTab;
