import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

import LeadrModal from '~Common/V3/components/LeadrModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { modalParamsProps } from '../Const/types';

const styles = {
  button: css({
    marginRight: '.5rem',
  }),
  title: css({
    color: palette.brand.red,
  }),
  description: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
  warningText: css({
    color: palette.brand.red,
    fontWeight: '600',
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
};

interface DisplayCycleModalsProps {
  open: boolean,
  handleModalDisplay: (value: boolean, params?: modalParamsProps) => void,
  deleteCycle: () => void,
  title: string,
}

const DisplayDeleteModal = ({
  open,
  handleModalDisplay,
  deleteCycle,
  title,
}: DisplayCycleModalsProps): JSX.Element => (
  <LeadrModal
    open={open}
    onClose={() => handleModalDisplay(false)}
  >
    <LeadrModal.Header>
      <LeadrModal.Title css={styles.title}>
        {`Are you sure you want to delete “${title}”?`}
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body>
      <p css={styles.description}>
        This action will delete the active review cycle, erase all progress, and cannot be undone.
      </p>
    </LeadrModal.Body>
    <LeadrModal.Footer css={styles.footer}>
      <LeadrButton
        color="danger"
        onClick={deleteCycle}
        data-test-id="deleteReviewCycle"
        size="small"
      >
        Delete Cycle
      </LeadrButton>
      <LeadrButton
        onClick={() => handleModalDisplay(false)}
        data-test-id="cancelDeleteReviewCycle"
        color="danger"
        variant="ghost"
        size="small"
      >
        Cancel
      </LeadrButton>
    </LeadrModal.Footer>
  </LeadrModal>
);

export default DisplayDeleteModal;
