import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { homeQueryKeys } from '../queryKeys';

interface GetTeamOptionsParams {
  orgId: string,
}

interface TeamOption {
  id: string,
  name: string,
}

interface GetTeamOptionsReturn {
  teams: TeamOption[],
  userHasTeams: boolean,
}

function getTeamOptions({
  orgId,
}: GetTeamOptionsParams): Promise<HttpCallReturn<GetTeamOptionsReturn>> {
  const serverUrl = {
    // Host doesn't matter, but need this format to use the V2 endpoint
    host: getHost(hosts.reviewCycles, '2'),
    uri: `/organizations/${orgId}/teams/home`,
  };

  return getApi<GetTeamOptionsReturn>(serverUrl);
}

export function useGetTeamOptions(): UseQueryResult<HttpCallReturn<GetTeamOptionsReturn>> {
  const orgId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: homeQueryKeys.homeTeamClarity(
      orgId,
    ),
    queryFn: () => getTeamOptions({
      orgId,
    }),
  });
}
