import { css } from '@emotion/react';
import { TabContext, TabList, TabListProps } from '@mui/lab';
import { Box } from '@mui/material';
import Tab, { TabProps, tabClasses } from '@mui/material/Tab';
import { ReactElement, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { palette } from '~Common/styles/colors';

const styles = {
  tabsContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
  }),
  tabList: css({
    '& .MuiTab-root': {
      minWidth: 'unset',
      paddingBottom: '.75rem',
      paddingLeft: '0',
      paddingRight: '0',
    },
    '& .MuiTabs-flexContainer': {
      columnGap: '1.875rem',
      rowGap: '0.5rem',
    },
  }),
  tabIndicator: css({
    height: '.1875rem',
    backgroundColor: palette.brand.indigo,
  }),
  tab: css({
    [`&.${tabClasses.textColorPrimary}`]: {
      color: palette.brand.indigo,
      fontSize: '.875rem',
      fontWeight: 600,
      textTransform: 'none',
      // For the link tabs, we want to remove the underline on hover
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
  rightItem: css({
    marginLeft: '0.5rem',
  }),
};

interface LeadrTabsProps<T> {
  value: T,
  handleChange: (event: SyntheticEvent, newValue: T) => void,
  tabListProps?: TabListProps,
  children: ReactElement[],
  renderRightItem?: () => ReactElement,
}

const LeadrTabs = <T extends string, >({
  value,
  handleChange,
  tabListProps,
  children,
  renderRightItem,
  ...props
}: LeadrTabsProps<T>): JSX.Element => (
  <TabContext value={value}>
    <Box
      // @ts-expect-error This works but MUI types are mad
      sx={styles.tabsContainer}
      {...props}
    >
      <TabList
        css={styles.tabList}
        TabIndicatorProps={{
          // @ts-expect-error This works but MUI types are mad
          sx: styles.tabIndicator,
        }}
        // Hiding these since they make the UI jank, going to rely on horizontal scroll for now
        scrollButtons={false}
        onChange={handleChange}
        variant="scrollable"
        {...tabListProps}
      >
        {children}
      </TabList>
      {renderRightItem && (
        <div css={styles.rightItem}>
          {renderRightItem?.()}
        </div>
      )}
    </Box>
  </TabContext>
  );

LeadrTabs.Tab = ({ ...props }: TabProps) => (
  <Tab css={styles.tab} {...props} />
);

LeadrTabs.LinkTab = ({ ...props }: Omit<TabProps<Link>, 'component'>) => (
  <Tab
    css={styles.tab}
    component={Link}
    {...props}
  />
);

export default LeadrTabs;
