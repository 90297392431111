import { css } from '@emotion/react';
import { PeoplePageScoreSummary, PeoplePageSummaryReport } from '~People/const/interfaces';

const styles = {
  condensedInsightPlot: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '1.125rem',
    rowGap: '.375rem',
  }),
  metricPlot: css({
    display: 'flex',
    alignItems: 'center',
    height: '.75rem',
    '& svg': {
      marginRight: '.25rem',
    },
  }),
};

interface RenderProps {
  metric: string,
  scoreSummary: PeoplePageScoreSummary,
}

type MetricEntry = [string, PeoplePageScoreSummary];

interface ViewProps {
  metricEntries: MetricEntry[],
  renderMetric: (props: RenderProps) => JSX.Element,
  renderGraph: (props: RenderProps) => JSX.Element,
}

const View = ({
  metricEntries,
  renderMetric,
  renderGraph,
}: ViewProps): JSX.Element => (
  <div css={styles.condensedInsightPlot}>
    { metricEntries.map(([metric, scoreSummary]) => (
      <div
        css={styles.metricPlot}
        key={metric}
      >
        {renderMetric({
          metric,
          scoreSummary,
        })}
        {renderGraph({
          metric,
          scoreSummary,
        })}
      </div>
    )) }
  </div>
);

interface CondensedInsightPlotProps extends Omit<ViewProps, 'metricEntries'> {
  metricSummaryReport: PeoplePageSummaryReport,
}

const CondensedInsightPlot = ({
  metricSummaryReport,
  ...props
}: CondensedInsightPlotProps): JSX.Element => {
  const metricEntries = Object.entries(metricSummaryReport) as unknown as MetricEntry[];

  const hookProps = {
    metricEntries,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default CondensedInsightPlot;
