import { css } from '@emotion/react';

const styles = {
  secondarySection: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.25rem',
    fontSize: '0.75rem',
    overflow: 'hidden',
    lineHeight: '.9375rem',
  }),
};

interface SecondarySectionProps {
  renderFirst?: () => JSX.Element,
  renderSecond: () => JSX.Element,
}

const SecondarySection = ({
  renderFirst,
  renderSecond,
  ...props
}: SecondarySectionProps): JSX.Element => (
  <div css={styles.secondarySection} {...props}>
    {renderFirst && (
      <>
        {renderFirst()}
        <span>&#x2022;</span>
      </>
    )}
    <span>{renderSecond()}</span>
  </div>
);

export default SecondarySection;
