export enum DRAWER_STATE {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum DRAWER_WIDTHS {
  BASE = 420,
  PRIMARY = 576,
  SECONDARY = 420,
  EXTENDED = 700,
  BASEBALL_CARD = 340,
  MEETING_NOTES = 360,
  PAGE = 1200,
}

export enum TRANSITION_STATE {
  EXITED = 'exited',
  ENTERED = 'entered',
  ENTERING = 'entering',
  EXITING = 'exiting',
}

export interface Drawer {
  name: string,
  width?: number,
  args?: Record<string, unknown>
}

export interface ManagedDrawer extends Drawer {
  state: DRAWER_STATE,
  offset: number
  isCollapsed?: boolean,
}

// The drawer object that you can use from DrawerProps, see below
interface DrawerPropsDrawer {
  name: string,
  width: number,
  state: DRAWER_STATE,
  offset: number,
  isCollapsed: boolean,
}

// These are props that you get in an object, whenever you have a drawer
export type PartialDrawerState<T> = T | Record<string, unknown>;
export interface DrawerProps<T> {
  drawer: DrawerPropsDrawer,
  drawerState: T,
  transitionState: TRANSITION_STATE,
  drawerIndex: number,
  setDrawerState: (callback: (prev: PartialDrawerState<T>) => PartialDrawerState<T>) => void,
  popDrawer: (drawer: Record<string, unknown>) => void,
  pushDrawer: (drawer: Record<string, unknown>) => void,
}

export enum FilterType {
  Everyone = 'none',
  Department = 'department',
  Manager = 'manager',
  UserGroup = 'usergroup',
  Account= 'accounttype',
}
export type FilterCountsType = {
  users: number,
  none: {
    [key: string]: number
  }
  accounttype: {
      [key: string]: number
  },
  department: {
      [key: string]: number
  },
  usergroup: {
      [key: string]: number
  },
  manager: {
      [key: string]: number
  },
}
