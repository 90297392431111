import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId, getOrganizationUserId, getUserId } from '~Common/utils/localStorage';
import { ReactText, useRef } from 'react';
import { homeQueryKeys } from '../queryKeys';

const selectTeam = (teamId: string): Promise<HttpCallReturn<void>> => {
  const orgId = getOrganizationId() ?? '';
  const userId = getOrganizationUserId() ?? '';
  const URL = {
    // Host doesn't matter, but need this format to use the V2 endpoint
    host: getHost(hosts.reviewCycles, '1'),
    uri: `/organizations/${orgId}/users/${userId}/settings/selectTeam`,
  };

  return patchApi(URL, { teamId });
};

export const useSelectTeam = (): UseMutationResult<HttpCallReturn<void>, unknown, string> => {
  const orgId = getOrganizationId() ?? '';
  const userId = getUserId() ?? '';
  const toastId = useRef<ReactText | number | null>(null);

  return useMutation({
    mutationFn: selectTeam,
    onMutate: () => {
      toastId.current = toast.info('Updating your team selection...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error updating your team selection. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully updated your team selection.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeSettings(orgId, userId) });
      await queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeTeamClarity(orgId) });
    },
  });
};
