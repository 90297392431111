/* eslint-disable react/button-has-type */

import { faArrowRight } from '@fortawesome/pro-duotone-svg-icons/faArrowRight';

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const NextButton = (props) => (
  <Button
    variant={BUTTON_VARIANT.ICON}
    icon={faArrowRight}
    buttonColor={BUTTON_COLOR.ADDITIVE}
    tooltip="Next"
    {...props}
  />
);

NextButton.propTypes = {};

NextButton.defaultProps = {};

export default NextButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
