import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { noop } from '~Deprecated/utils';
import { palette } from '~Common/styles/colors';
import CareCardAvatar from '~Common/V3/components/CareCardAvatar';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import OverflowAvatar from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { useRealtimePresence } from '~Meetings/stores/useMeetingRealtimeStore';

const styles = {
  attendeeList: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 30px)',
    gap: '0.5rem',
  }),
  button: css({
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
  }),
  avatar: (isActive) => css({
    border: isActive ? `0.125rem solid ${palette.brand.blue}` : 'initial',
  }),
};

const View = ({
  avatars,
  hideShowMore,
  onShowMore,
  overflowCount,
}) => (
  <div
    css={styles.attendeeList}
  >
    {avatars}
    {!hideShowMore && (
      <OverflowAvatar
        avatarHeight={30}
        avatarWidth={30}
        onClick={onShowMore}
        onKeyDown={noop}
        tabIndex={0}
        role="button"
        overflowNumber={overflowCount}
      />
    )}
  </div>
);

View.propTypes = {
  overflowCount: PropTypes.number,
  onShowMore: PropTypes.func,
  hideShowMore: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  avatars: PropTypes.array.isRequired,
};

View.defaultProps = {
  hideShowMore: true,
  onShowMore: noop,
  overflowCount: 0,
};

const DetailsAttendees = ({
  id,
  avatarList,
  displayOnPage,
  renderAvatar,
  onShowMore,
  ...props
}) => {
  const { activeUsers } = useRealtimePresence(id);

  const avatars = avatarList.filter((avatar) => !!avatar && avatar.administrativeStatus !== 'INACTIVE').slice(0, displayOnPage).map((avatar) => {
    const isActive = activeUsers.includes(avatar.orgUserId);

    if (renderAvatar && typeof renderAvatar === 'function') {
      return renderAvatar(avatar);
    }

    return (
      <>
        {avatar.isNonLeadrUser && (
          <NewBaseAvatar
            css={styles.avatar(isActive)}
            avatarSize={30}
            email={avatar.email}
            isNonLeadrUser
          />
        )}
        {!avatar.isNonLeadrUser && (
          <CareCardAvatar
            id={avatar.id || avatar.userId || avatar.orgUserId}
            noBackdrop
            containerStyle={styles.button}
            renderAvatar={(imageUrl, fullName, isDeactivated) => (
              <NewBaseAvatar
                css={styles.avatar(isActive)}
                profileImageUrl={imageUrl}
                name={fullName}
                isDeactivated={isDeactivated}
                avatarSize={30}
              />
            )}
            renderSkeletonLoader={() => (
              <SkeletonLoader
                width={30}
                height={30}
                variant="rect"
                renderComponent={() => <div />}
              />
            )}
          />
        )}
      </>
    );
  });

  const hookProps = {
    avatars,
    hideShowMore: avatarList.length <= displayOnPage,
    onShowMore,
    overflowCount: avatarList.length - displayOnPage,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

DetailsAttendees.propTypes = {
  id: PropTypes.string.isRequired,
  avatarList: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayOnPage: PropTypes.number.isRequired,
  renderAvatar: PropTypes.func,
  onShowMore: PropTypes.func,
};

DetailsAttendees.defaultProps = {
  // displayOnPage: 35,
  renderAvatar: null,
  onShowMore: noop,
};

export default DetailsAttendees;
