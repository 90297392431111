import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { useLearningSearch } from '~Learning/hooks/utils/useLearningSearch';
import { useLearningLibrary } from '~Learning/hooks/utils/useLearningLibrary';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { ChangeEvent } from 'react';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { css } from '@emotion/react';
import { forMobileTinyObject } from '~Common/styles/mixins';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import ConditionalWrapper from '~Common/components/ConditionalWrapper';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import LearningTabNavigation from './LearningTabNavigation';
import LearningViewToggle from './LearningViewToggle';
import { shareLearningDrawerTemplate } from '../ShareLearningDrawer';

const styles = {
  leadrButton: css(
    forMobileTinyObject({
      width: '50%',
    }),
  ),
  toggleTab: (isLimitedAccessUser: boolean) => css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    width: isLimitedAccessUser ? '100%' : '50%',
  })),
  tabNavigationToggleSkeleton: css({
    borderRadius: '0.5rem',
  }, forMobileTinyObject({
    maxWidth: '100%',
  })),
  rightSectionSkeleton: css({
    height: '2.8125rem',
    borderRadius: '0.1875rem',
  }, forMobileTinyObject({
    width: '100%',
    maxWidth: '100%',
  })),
};

interface ViewProps {
  searchText: string,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onViewLibraryClick: () => void,
  onCreateLearningClick: () => void,
  isLimitedAccessUser: boolean,
  isUserDataLoading: boolean,
  initialRoute: string,
}

const View = ({
  searchText,
  handleSearchTextChange,
  onViewLibraryClick,
  onCreateLearningClick,
  isLimitedAccessUser,
  isUserDataLoading,
  initialRoute,
}: ViewProps): JSX.Element => (
  <ModuleTopbar
    moduleTopbarLayout={isLimitedAccessUser ? 'defaultWithoutRightSection' : 'default'}
    renderTabNavigationToggle={() => (
      <ConditionalWrapper
        renderWrapperCondition={isUserDataLoading}
        renderWrapper={(renderChildren) => (
          <SkeletonLoader
            css={styles.tabNavigationToggleSkeleton}
            variant="rectangular"
            renderComponent={() => <>{renderChildren()}</>}
          />
        )}
        renderContents={() => (
          <LearningTabNavigation
            initialRoute={initialRoute}
            toggleTabStyles={styles.toggleTab(isLimitedAccessUser)}
          />
        )}
      />
    )}
    renderViewToggle={() => <LearningViewToggle />}
    renderSearchField={() => (
      <LeadrSearchField
        data-test-id="learningsSearchField"
        onChange={handleSearchTextChange}
        defaultValue={searchText}
      />
    )}
    renderRightSection={!isLimitedAccessUser ? () => (
      <>
        <ConditionalWrapper
          renderWrapperCondition={isUserDataLoading}
          renderWrapper={(renderChildren) => (
            <SkeletonLoader
              css={styles.rightSectionSkeleton}
              variant="rectangular"
              renderComponent={() => <>{renderChildren()}</>}
            />
          )}
          renderContents={() => (
            <>
              <LeadrButton
                onClick={onViewLibraryClick}
                data-test-id="learningTemplates"
                variant="ghost"
                css={styles.leadrButton}
              >
                Library
              </LeadrButton>
              <CreateButton
                itemName="Learning"
                onClick={onCreateLearningClick}
                data-test-id="learningCreateNew"
                css={styles.leadrButton}
              />
            </>
          )}
        />
      </>
    ) : undefined}
  />
);

interface LearningTopBarProps {
  initialRoute: string,
}

const LearningTopBar = ({ ...props }: LearningTopBarProps): JSX.Element => {
  const dispatch = useDispatch();

  const { isLimitedAccess: isLimitedAccessUser, isLoading } = useUserPermissions();
  const [isUserDataLoading] = useSkeletonLoaders(isLoading);

  const [searchText, setSearchText] = useLearningSearch();

  const { openLibraryWithAssignLearningTemplate } = useLearningLibrary();

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const hookProps = {
    searchText,
    handleSearchTextChange,
    onViewLibraryClick: openLibraryWithAssignLearningTemplate,
    onCreateLearningClick: () => dispatch(pushDrawerAction({
      drawer: shareLearningDrawerTemplate,
    })),
    isLimitedAccessUser,
    isUserDataLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

LearningTopBar.propTypes = {};

LearningTopBar.defaultProps = {};

export { View };
export default LearningTopBar;
