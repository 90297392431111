import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { faArrowsRotate, faTimes } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import { DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import { pageBackground } from '~Common/styles/colors';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { ONE_TIME_MEETING } from '~Common/const/constraints';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { useGetPeopleByList as usePeoplePickerByList } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { WeekdaySelection } from '~Deprecated/ui/components/WeekdaySelection';
import { useEditMeeting } from '~Meetings/hooks/useEditMeeting';
import { MEETING_FACTORY_TYPES, MEETING_TYPES } from '~Meetings/const/meetingTypes';
import { useRoundedTime } from '~Common/hooks/useRoundedTime';
import { useWindowWidth } from '~Common/hooks/useWindowWidth';
import { BREAKPOINTS } from '~Common/styles/variables';
import { withoutMobileObject } from '~Common/styles/mixins';
import { getOrganizationUserId } from '~Common/utils/localStorage';

import DateTimePicker from '~Meetings/components/shared/DateTimePicker';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerInput from '~Common/V3/components/DrawerInput';
import DrawerDropdown from '~Deprecated/ui/components/Inputs/DrawerDropdown';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import LoadingSpinner from '~Deprecated/components/LoadingSpinner';
import Tooltip from '~Common/components/Tooltip';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { selectParticipantsDrawerTemplate } from '~Common/components/Drawers/SelectParticipantsDrawer';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import useMeetingDateTimeFrequencyWeekdayTimezone from '~Meetings/hooks/useMeetingDateTimeFrequencyWeekdayTimezone';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';
import { participantDrawerTemplate } from './ParticipantDrawer';

const styles = {
  container: css`
    background-color: ${pageBackground};
  `,
  drawerInput: css`
    margin: 8px 0 !important;
  `,
  attendeeBox: css`
    width: 49%;
    margin: 10px 1% 10px 0;
  `,
  attendeeContainer: css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  `,
  weekdaySelection: css`
    margin: 20px 0;
    padding-inline: 0.5rem;
  `,
  timeContainer: css`
    margin: 10px 0;
    display: flex;
  `,
  avatarStyles: css`
    margin-right: 10px;
    margin-bottom: 10px;
  `,
  inviteButton: css({}, withoutMobileObject({
    display: 'none',
  })),
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
  leadrButton: css({
    width: '100%',
  }),
};

export const newEditMeetingTemplate = {
  name: 'EDIT_MEETING_OCCURENCE',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton,
  createButton,
  onDateChange,
  selectedFrequency,
  frequencyOptions,
  onFrequencyChange,
  defaultWeekday,
  setSelectedDays,
  selectedDays,
  meetingStart,
  meetingDuration,
  addPeopleClick,
  otherAttendees,
  onFormSubmit,
  location,
  title,
  canInviteParticipants,
  renderDrawerInstructions,
  showWeekdaySelection,
  showFrequency,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={closeButton}
        title="Edit Meeting"
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        {renderDrawerInstructions()}
        <DrawerInput
          name="title"
          css={styles.drawerInput}
          label="Meeting Title"
          required
          initialValue={title}
        />

        <DrawerInput
          name="location"
          css={styles.drawerInput}
          label="Location"
          maxLength={300}
          initialValue={location}
        />

        <DateTimePicker
          css={styles.drawerInput}
          onDateChange={onDateChange}
          label="Start Date"
          initialDate={meetingStart}
          defaultDuration={meetingDuration}
        />

        {showFrequency && (
        <>
          <DrawerDropdown
            fontAwesomeIcon={faArrowsRotate}
            name="frequency"
            items={frequencyOptions}
            label="Frequency"
            onChange={onFrequencyChange}
            value={selectedFrequency}
            css={styles.drawerInput}
          />
          {showWeekdaySelection && (
          <WeekdaySelection
            css={styles.weekdaySelection}
            keepSelected={defaultWeekday}
            selected={selectedDays}
            onSelectionChange={(days) => {
              setSelectedDays(days);
            }}
          />
          )}
        </>
        )}

        {canInviteParticipants && (
        <>
          <SectionHeader
            text="Participants"
            css={styles.header}
            renderButton={() => (
              <LeadrButton
                css={styles.inviteButton}
                data-test-id="meetingSelectParticipants"
                size="small"
                onClick={addPeopleClick}
              >
                Select
              </LeadrButton>
            )}
          />
          <div css={styles.attendeeContainer}>
            {otherAttendees.map((attendee) => (
              <div>
                <Tooltip content={`${attendee.firstName} ${attendee.lastName}`}>
                  <div className="menu-item" data-toggle="tooltip">
                    <div className="d-flex">
                      <SquareAvatar
                        imgUrl={attendee.profileImageUrl || attendee.imageUrl}
                        name={`${attendee.firstName} ${attendee.lastName}`}
                        width={25}
                        height={25}
                        includeInitials
                        css={styles.avatarStyles}
                      />
                    </div>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        </>
        )}
        {createButton()}
      </div>
    )}
  />
);

View.propTypes = {
  closeButton: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  selectedFrequency: PropTypes.string.isRequired,
  frequencyOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFrequencyChange: PropTypes.func.isRequired,
  defaultWeekday: PropTypes.number,
  setSelectedDays: PropTypes.func.isRequired,
  selectedDays: PropTypes.arrayOf(PropTypes.number).isRequired,
  meetingStart: PropTypes.instanceOf(Date).isRequired,
  meetingDuration: PropTypes.number.isRequired,
  addPeopleClick: PropTypes.func.isRequired,
  otherAttendees: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  location: PropTypes.string,
  title: PropTypes.string,
  canInviteParticipants: PropTypes.bool.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
  showWeekdaySelection: PropTypes.bool.isRequired,
  showFrequency: PropTypes.bool,
};

View.defaultProps = {
  location: '',
  title: '',
  showFrequency: false,
  defaultWeekday: null,
};

const EditMeeting = ({
  drawerState,
  transitionState,
  setDrawerState,
  limit,
  id,
  type,
  ...props
}) => {
  const dispatch = useDispatch();
  const frontendUserGroups = useFeatureFlag('frontendUserGroups');
  const { item: meetingDetails, isLoading: showSpinner } = useMeetingDetails({ id, type }) ?? {};
  const {
    location, title, startTimeInMillis, endTimeInMillis, factoryType, frequency, organizer, daysOfWeek,
  } = meetingDetails ?? {};
  const { date: meetingStart } = useRoundedTime(startTimeInMillis, 15);
  const selectedIds = useMemo(() => [organizer.orgUserId, ...(drawerState.selectedAttendees || [])], [drawerState.selectedAttendees, organizer.orgUserId]);
  const attendeeData = usePeoplePickerByList({ selectedIds });
  const otherAttendees = useMemo(() => attendeeData.filter((attId) => attId !== undefined && attId !== getOrganizationUserId()), [attendeeData]);
  const meetingDuration = endTimeInMillis - startTimeInMillis;

  const hasInvalidParticipantCount = drawerState?.selectedAttendees?.length === 0;
  const {
    editMeetingMutation,
    isLoading: isSubmittingEdit,
  } = useEditMeeting({ template: newEditMeetingTemplate });
  const shouldDisableSubmit = isSubmittingEdit || (
    factoryType === MEETING_FACTORY_TYPES.TEAM_MEETING && hasInvalidParticipantCount
  );
  const canInviteParticipants = factoryType === MEETING_FACTORY_TYPES.TEAM_MEETING;

  // TODO: This is an array of orgUserIds now, not an array of objects that use userId.
  const attendees = meetingDetails?.attendeeOrgUserIds;
  const { windowWidth } = useWindowWidth();
  const disableUsers = useMemo(() => [{
    user: organizer.orgUserId,
    tooltip: 'The organizer of a meeting cannot be removed from the list of attendees.',
  }], [organizer]);

  const peopleDrawerToUse = frontendUserGroups ? selectParticipantsDrawerTemplate : participantDrawerTemplate;

  const {
    setSelectedDays, onDateChange, selectedFrequency, defaultWeekday, frequencyOptions,
    onFrequencyChange, showWeekdaySelection, selectedDays, showFrequency,
  } = useMeetingDateTimeFrequencyWeekdayTimezone({
    defaultFrequency: frequency,
    showFrequency: frequency !== ONE_TIME_MEETING,
    isOneOnOne: type === MEETING_TYPES.COACHING,
    isAlreadyRecurring: frequency !== ONE_TIME_MEETING,
    initialSelectedDays: daysOfWeek,
  });

  useEffect(() => {
    const isMobile = windowWidth <= BREAKPOINTS.MAX.MOBILE;
    const hasEntered = transitionState === TRANSITION_STATE.ENTERED;
    const isOneOnOne = type === MEETING_TYPES.COACHING;

    if (!isMobile && !isOneOnOne && hasEntered) {
      dispatch(pushDrawerAction({
        drawer: {
          ...peopleDrawerToUse,
          args: {
            limit,
            useOrgIds: true,
            allowSelf: true,
            currentAttendees: attendees,
            disableUsers,
          },
        },
      }));
    }
  }, [windowWidth, limit, type, dispatch, transitionState, attendees, disableUsers, peopleDrawerToUse]);

  const addPeopleClick = () => {
    dispatch(pushDrawerAction({
      drawer: {
        ...peopleDrawerToUse,
        args: {
          limit,
          useOrgIds: true,
          allowSelf: true,
          currentAttendees: attendees,
          disableUsers,
        },
      },
    }));
  };

  const renderDrawerInstructions = () => {
    let drawerText;
    if (frequency === 'ONE_TIME') {
      drawerText = '<p>Make changes to this one time meeting here.</p>';
    } else {
      drawerText = '<p>Make changes to the meeting series here. All changes made here will be applied to all meetings moving forward.</p>';
    }
    return (
      <div>
        <DrawerInstructions
          html={drawerText}
        />
        <br />
      </div>
    );
  };

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  useEffect(() => {
    const meetingAttendees = meetingDetails?.attendeeOrgUserIds ?? [];
    setDrawerState({
      selectedAttendees: meetingAttendees,
    });
    return () => {
      setDrawerState({});
    };
  }, [setDrawerState, meetingDetails?.attendeeOrgUserIds]);

  useEffect(() => {
    onDateChange(meetingStart);
  }, [meetingStart, onDateChange]);

  const onFormSubmit = useCallback((e) => {
    const formData = new FormData(e.target);
    const updatedMeeting = Object.fromEntries(formData.entries());
    const meetingAttendees = drawerState.selectedAttendees;
    const editData = {
      type,
      lastState: meetingDetails,
      factoryId: meetingDetails?.factoryId,
      id,
      title: updatedMeeting.title,
      location: updatedMeeting.location,
      frequency: updatedMeeting.frequency ?? 'ONE_TIME',
      endTimeInMillis: parseInt(updatedMeeting.endTimeInMillis, 10) || (parseInt(updatedMeeting.startTimeInMillis, 10) + (1000 * 60 * 60)),
      startTimeInMillis: parseInt(updatedMeeting.startTimeInMillis, 10),
      attendeeIds: [organizer.orgUserId, ...meetingAttendees],
    };

    if (showWeekdaySelection) {
      editData.daysOfWeek = selectedDays;
    }

    editMeetingMutation(editData);
  }, [
    editMeetingMutation,
    id,
    selectedDays,
    drawerState.selectedAttendees,
    meetingDetails,
    showWeekdaySelection,
    type,
    organizer,
  ]);

  const createButton = useCallback(() => (
    <LeadrButton
      css={styles.leadrButton}
      type="submit"
      data-test-id="updateMeeting"
      disabled={shouldDisableSubmit}
    >
      Update Meeting
    </LeadrButton>
  ), [shouldDisableSubmit]);

  const hookProps = {
    closeButton,
    onDateChange,
    selectedFrequency,
    frequencyOptions,
    onFrequencyChange,
    defaultWeekday,
    setSelectedDays,
    meetingDetails,
    meetingStart,
    meetingDuration,
    createButton,
    addPeopleClick,
    otherAttendees,
    drawerState,
    attendees,
    onFormSubmit,
    selectedDays,
    location,
    title,
    canInviteParticipants,
    renderDrawerInstructions,
    showWeekdaySelection,
    showFrequency,
  };

  if (showSpinner) {
    return <LoadingSpinner />;
  }

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

EditMeeting.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  drawerState: PropTypes.func.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  transitionState: PropTypes.oneOf(Object.values(TRANSITION_STATE)).isRequired,
};

registerDrawer({
  templateName: newEditMeetingTemplate.name,
  component: EditMeeting,
});

export { View };
export default EditMeeting;
