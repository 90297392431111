import { RecognitionUserShape } from '~Recognition/const/types';

const getFirstAndLastNames = (recognitionUsersList: RecognitionUserShape[]): string[] => {
  const firstAndLastNames = recognitionUsersList.map((recipient) => {
    const { firstName, lastName } = recipient;
    return `${firstName} ${lastName}`;
  });
  return firstAndLastNames;
};

const calculateAverageNameValue = (names: string[]): number => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  let totalNameValue = 0;
  let numberOfNames = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < names.length; i++) {
    const name = names[i].toLowerCase().split(' ');
    const firstLetterValue = alphabet.indexOf(name[0].charAt(0)) + 1;
    const lastLetterValue = alphabet.indexOf(name[1].charAt(0)) + 1;

    if (firstLetterValue < 1 || lastLetterValue < 1) {
      // eslint-disable-next-line no-continue
      continue;
    }

    totalNameValue += firstLetterValue + lastLetterValue;
    // eslint-disable-next-line no-plusplus
    numberOfNames++;
  }

  const averageNameValue = totalNameValue / numberOfNames;

  return averageNameValue;
};

export const useGetAverageNameValue = (recognitionUsersList: RecognitionUserShape[], createdBy: RecognitionUserShape): number => {
  const combinedRecipients = [...recognitionUsersList, createdBy];

  const names = getFirstAndLastNames(combinedRecipients);
  const calculatedAverageNameValue = calculateAverageNameValue(names);

  return calculatedAverageNameValue;
};
