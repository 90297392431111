import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { ATTENDEE_SHAPE, ORGANIZATION_SETTINGS_SHAPE } from '~Common/const/proptypes';

import { withBaseShadow } from '~Common/styles/mixins';
import ImageUpload from '~Deprecated/ui/components/Pickers/ImageUpload';
import AboutMe from './AboutMe';
import BasicInfo from './BasicInfo';
import Favorites from './Favorites';
import PersonalityTypes from './PersonalityTypes';
import SocialMediaProfiles from './SocialMediaProfiles';

const styles = {
  container: css`
    button {
      margin-left: 0;
    }
  `,
};

const imagePreview = css`
  width: 150px;
  height: 150px;
  ${withBaseShadow()};
  margin: 1rem;
`;

const View = ({
  user, fileUploadProps,
}) => (
  <div css={styles.container}>
    <ImageUpload user={user} previewStyles={imagePreview} {...fileUploadProps} />
    <BasicInfo user={user} />
    <AboutMe user={user} />
    <PersonalityTypes user={user} />
    <Favorites user={user} />
    <SocialMediaProfiles user={user} />
  </div>
);

View.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  userThatIsEditing: ATTENDEE_SHAPE.isRequired,
  fileUploadProps: PropTypes.shape({
    uploadFile: PropTypes.func,
    resetUploadFile: PropTypes.func,
    status: PropTypes.string,
  }).isRequired,
};

View.defaultProps = {

};

const Profile = (props) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

Profile.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  settings: ORGANIZATION_SETTINGS_SHAPE.isRequired,
};

Profile.defaultProps = {};

export { View };
export default Profile;
