import { UiState } from 'instantsearch.js';
import { InstantSearchApi, useInstantSearch } from 'react-instantsearch-hooks-web';
import { useMemo } from 'react';

interface RenderComponentParams {
  result: InstantSearchApi<UiState>,
  hasResults: boolean,
  query: string,
}

interface ViewProps extends RenderComponentParams {
  renderComponent: (params: RenderComponentParams) => JSX.Element,
}

const View = ({
  renderComponent,
  result,
  hasResults,
  query,
}: ViewProps): JSX.Element => (
  <>
    {renderComponent({
      result,
      hasResults,
      query,
    })}
  </>
);

type InstantSearchHelperProps = Omit<ViewProps, 'result' | 'hasResults' | 'query' | 'isFetchingResults'>

const InstantSearchHelper = ({ ...props }: InstantSearchHelperProps): JSX.Element => {
  const result = useInstantSearch();

  const { scopedResults, indexUiState } = result;

  const hasResults = useMemo(() => scopedResults.some((scopedResult) => scopedResult.results.nbHits > 0), [scopedResults]);

  const { query = '' } = indexUiState;

  const hookProps = useMemo(() => ({
    result,
    hasResults,
    query,
  }), [result, hasResults, query]);

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default InstantSearchHelper;
