import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { Goals } from '@leadr-hr/types';
import { goalKeys } from '~Goals/const/queryKeys';
import { getHost, hosts } from '~Deprecated/services/config';

type GetLinkedGoalsByIdResponse = Goals.Responses.GetLinkedGoalsResponse['data'];

interface GetLinkedGoalsByIdParams {
  organizationId: string,
  goalId: string,
}

const getLinkedGoalsById = async ({ organizationId, goalId }: GetLinkedGoalsByIdParams): Promise<HttpCallReturn<GetLinkedGoalsByIdResponse>> => {
  const serverUrl = {
    host: getHost(hosts.goals, '3'),
    uri: `/organizations/${organizationId}/goals/${goalId}/link`,
  };

  return getApi<GetLinkedGoalsByIdResponse>(serverUrl);
};

interface UseGetLinkedGoalsByIdParams<T> extends Omit<
  UseQueryOptions<HttpCallReturn<GetLinkedGoalsByIdResponse>, Error, T>, 'queryKey' | 'queryFn'
> {
  goalId: string,
}

export const useGetLinkedGoalsById = <T = HttpCallReturn<GetLinkedGoalsByIdResponse>>({
  goalId,
  ...options
}: UseGetLinkedGoalsByIdParams<T>): UseQueryResult<T, Error> => {
  const organizationId = getOrganizationId() ?? '';

  return useQuery({
    ...getLinkedGoalsByIdParams({ goalId, organizationId }),
    enabled: !!goalId,
    ...options,
  });
};

interface GetLinkedGoalsByIdParams {
  goalId: string,
  organizationId: string,
}

interface QueryOptionsReturn {
  queryKey: readonly string[];
  queryFn: () => Promise<HttpCallReturn<GetLinkedGoalsByIdResponse>>;
}

export const getLinkedGoalsByIdParams = ({
  goalId,
  organizationId,
}: GetLinkedGoalsByIdParams): QueryOptionsReturn => ({
  queryKey: goalKeys.linkedGoals(goalId),
  queryFn: () => getLinkedGoalsById({ organizationId, goalId }),
});
