import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

const styles = {
  iconAndTextContainer: (flipIconOrder: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
    flexDirection: flipIconOrder ? 'row-reverse' : 'row',
  }),
  icon: css({
    height: '1rem',
    width: '1rem',
  }),
};

interface LeadrButtonIconAndTextProps extends ComponentProps<'div'> {
  icon: IconDefinition,
  text: string,
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>,
  flipIconOrder?: boolean,
}

/**
 * Spaced out for you, if you need different spacing, it needs to be a discussion with the design team
  @param icon - FontAwesomeIcon to display for the button, passed in as icon={faIconName}
  @param text - String to display for the button
*/
export const IconAndText = ({
  icon,
  text,
  iconProps,
  flipIconOrder = false,
  ...props
}: LeadrButtonIconAndTextProps): JSX.Element => (
  <div css={styles.iconAndTextContainer(flipIconOrder)} {...props}>
    <FontAwesomeIcon css={styles.icon} icon={icon} {...iconProps} />
    <span>{text}</span>
  </div>
);
