import { flatMapDeep, omit } from 'lodash';

interface DataItem {
  children?: DataItem[],
  [key: string]: unknown,
}

interface FlatMappedItem {
  [key: string]: unknown,
}

export const flattenChildren = (data: DataItem[]): FlatMappedItem[] => {
  const result = flatMapDeep<DataItem, FlatMappedItem>(data, (item) => [omit(item, 'children'), ...flattenChildren(item.children || [])]);

  return result;
};
