import { AgendaTemplates } from '@leadr-hr/types';
import { isLocalId } from '~Common/utils/uuid';
import {
  GetAgendaTemplateDetails,
  GetCuratedMeetingTemplateDetails,
  GetMeetingTemplateDetails,
  GetTemplateTopic,
} from '~Meetings/components/topic-suggestions/const/types';

type Template = GetMeetingTemplateDetails | GetCuratedMeetingTemplateDetails;

function isCustomTemplate(template: Template): template is GetMeetingTemplateDetails {
  return (template as GetMeetingTemplateDetails).permissions !== undefined;
}

function isCustomTemplateItem(item: GetTemplateTopic): item is GetAgendaTemplateDetails {
  return (item as GetAgendaTemplateDetails).permissions !== undefined;
}

interface GetCanEditTemplateOrItemParams {
  id: string,
  permissions?: AgendaTemplates.AgendaTemplatePermission[],
}

const getCanEditTemplateOrItem = ({
  id,
  permissions = [],
}: GetCanEditTemplateOrItemParams): boolean => !isLocalId(id) && permissions.includes(AgendaTemplates.AgendaTemplatePermission.EditTemplate);

export const getCanEditTemplate = (template: Template): boolean => {
  if (isCustomTemplate(template)) {
    return getCanEditTemplateOrItem({
      id: template.id,
      permissions: template.permissions,
    });
  }

  return false;
};

export const getCanEditTemplateItem = (templateItem: GetTemplateTopic): boolean => {
  if (isCustomTemplateItem(templateItem)) {
    return getCanEditTemplateOrItem({
      id: templateItem.id,
      permissions: templateItem.permissions,
    });
  }

  return false;
};
