import { css, SerializedStyles } from '@emotion/react';
import {
  faTimes,
  faArrowLeft,
  faLightbulb,
  faListCheck,
} from '@fortawesome/pro-light-svg-icons';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { palette } from '~Common/styles/colors';
import DrawerOptionSelectItem from '~Learning/components/Shared/DrawerOptionSelectItem/DrawerOptionSelectItem';
import { useLearningLibrary } from '~Learning/hooks/utils/useLearningLibrary';
import { createLearningTemplateDrawerTemplate } from '../CreateLearningTemplateDrawer';
import { createPlaylistTemplateDrawerTemplate } from '../CreatePlaylistTemplateDrawer';

const styles = {
  backButton: css({
    paddingLeft: 0,
  }),
  sectionHeader: css({
    color: palette.neutrals.gray500,
    fontSize: '.625rem',
    letterSpace: '0.2rem',
  }),
  drawerOptionContainer: css({
    marginTop: '.625rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.625rem',
  }),
};

export const selectLearningTypeDrawerTemplate = {
  name: 'SELECT_LEARNING_TYPE_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

interface SelectLearningTypeDrawerProps extends DrawerProps<unknown> {
  initialTemplateCategoryId: string,
  openTemplateLibrary: () => void,
}

const SelectLearningTypeDrawer = ({
  initialTemplateCategoryId,
  openTemplateLibrary,
  pushDrawer,
  popDrawer,
}: SelectLearningTypeDrawerProps): JSX.Element => {
  const onCloseDrawerClick = (): void => {
    popDrawer({ drawerName: selectLearningTypeDrawerTemplate.name });
  };

  const { openLibraryWithAssignLearningTemplate } = useLearningLibrary();

  const onBackClick = (): void => {
    // Go back to the learning template library
    onCloseDrawerClick();
    openLibraryWithAssignLearningTemplate();
  };

  const onCreateSingleLearning = (): void => {
    onCloseDrawerClick();

    pushDrawer({
      drawer: {
        ...createLearningTemplateDrawerTemplate,
        args: {
          initialTemplateCategoryId,
          saveTemplateCallback: openTemplateLibrary,
        },
      },
    });
  };

  const onCreateLearningPlaylist = (): void => {
    onCloseDrawerClick();

    pushDrawer({
      drawer: {
        ...createPlaylistTemplateDrawerTemplate,
        args: {
          initialTemplateCategoryId,
        },
      },
    });
  };

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title="Add New Template"
        renderDrawerInstructions={(drawerInstructionsStyles) => (
          <div css={drawerInstructionsStyles}>
            Would you like to create a Single Learning Template or Learning Playlist Template from scratch?
          </div>
        )}
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={onCloseDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
        renderBackButton={(backButtonStyles: SerializedStyles) => (
          <IconButton onClick={onBackClick} type="button" icon={faArrowLeft} css={[backButtonStyles, styles.backButton]} tooltip="Back" />
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <div css={styles.sectionHeader}>START FROM SCRATCH</div>
        <div css={styles.drawerOptionContainer}>
          <DrawerOptionSelectItem
            icon={faLightbulb}
            content="Create a Single Learning Template"
            onClick={onCreateSingleLearning}
            data-test-id="learningCreateASingleLearningTemplateButton"
          />
          <DrawerOptionSelectItem
            icon={faListCheck}
            content="Create a Learning Playlist Template"
            onClick={onCreateLearningPlaylist}
            data-test-id="learningCreateALearningPlaylistTemplateButton"
          />
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: selectLearningTypeDrawerTemplate.name,
  component: SelectLearningTypeDrawer,
});

export default SelectLearningTypeDrawer;
