import { css } from '@emotion/react';
import {
  ComponentProps, ForwardedRef, forwardRef,
} from 'react';

const styles = {
  flatButton: css({
    backgroundColor: 'transparent',
    border: '0',
  }),
};

interface FlatButtonProps extends ComponentProps<'button'> {
  renderButtonContents: () => JSX.Element,
}

const FlatButton = forwardRef(({ renderButtonContents, ...props }: FlatButtonProps, ref: ForwardedRef<HTMLButtonElement>) => (
  <button css={styles.flatButton} ref={ref} {...props}>
    {renderButtonContents()}
  </button>
));

export default FlatButton;
