import { css } from '@emotion/react';
import { REVIEW_SETUP_LAYOUT } from '~Reviews/V2/Const/pageStyles';
import { palette } from '~Common/styles/colors';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PersonalDevelopmentPlanSteps, ViewPersonalDevelopmentPlanPerspective } from '~DevelopmentPlan/const/types';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  stepsOverride: css({
    marginBottom: '1.125rem',

    '& .MuiStepLabel-label': {
      color: palette.neutrals.gray800,
      fontSize: '1rem',
      fontWeight: 500,
    },
    '& .MuiStepLabel-iconContainer svg': {
      color: `${palette.brand.sky} !important`,
      borderRadius: '100%',
    },
    '& .baseIcon svg': {
      color: ' transparent !important',
    },
    '& .QontoStepIcon-completedIcon svg, & .QontoStepIcon-active svg': {
      margin: '.3125rem 0 0 .25rem',
      color: `${palette.neutrals.white} !important`,
    },
    '& .MuiStepLabel-iconContainer text': {
      display: 'none',
    },
    '& .MuiStepConnector-root': {
      top: '1rem',
    },
    '& .MuiStepConnector-line': {
      borderColor: palette.brand.indigo,
      borderTopWidth: '.125rem',
    },
  }),
  iconStyles: css({
    width: '1.5rem',
    height: '1.5rem',
  }),
};

interface ViewProps {
  steps: string[],
  viewPlanPerspective: ViewPersonalDevelopmentPlanPerspective,
  QontoStepIcon: (props: StepIconProps) => JSX.Element,
}
const View = ({
  steps,
  viewPlanPerspective,
  QontoStepIcon,
}: ViewProps): JSX.Element => (
  <>
    <div css={[styles.setupStepsContainer, styles.boxShadow, styles.stepsOverride]}>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={PersonalDevelopmentPlanSteps[viewPlanPerspective as keyof typeof PersonalDevelopmentPlanSteps]} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  </>
);

interface SetupStepsProps {
  viewPlanPerspective: ViewPersonalDevelopmentPlanPerspective,
}
const SetupSteps = ({
  viewPlanPerspective,
}: SetupStepsProps): JSX.Element => {
  const steps = [
    'Set-up',
    'Create Plan',
    'Submit for Review',
  ];

  function QontoStepIcon(props: StepIconProps): JSX.Element {
    const { active, completed } = props;

    let status = 'notCompleted';
    if (completed) {
      status = 'completedIcon';
    } else if (active) {
      status = 'active';
    }

    const iconClass = `QontoStepIcon-${status}`;

    return (
      <QontoStepIconRoot className={status}>
        <div className={`baseIcon ${iconClass}`}>
          <FontAwesomeIcon css={styles.iconStyles} icon={faCheck} />
        </div>
      </QontoStepIconRoot>
    );
  }

  const QontoStepIconRoot = styled('div')(
    () => ({
      display: 'flex',
      height: 32,
      alignItems: 'center',

      '& .baseIcon': {
        height: 32,
        width: 32,
        borderRadius: '100%',
      },
      '& .QontoStepIcon-completedIcon': {
        background: palette.brand.indigo,
      },
      '& .QontoStepIcon-active': {
        background: palette.brand.sky,
      },
      '& .QontoStepIcon-notCompleted': {
        background: palette.neutrals.white,
        border: `2px solid ${palette.brand.indigo}`,
      },
    }),
  );

  const hookProps = {
    steps,
    viewPlanPerspective,
    QontoStepIcon,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, SetupSteps };
export default SetupSteps;
