import { Goals } from '@leadr-hr/types';
import flattenGoals, { FlattenedChildGoal } from '~Goals/utils/flattenLinkedGoals';

interface Progress {
  percentage: number,
  status: Goals.GoalStatus,
}

interface Owner {
  imgUrl: string,
  name: string,
}

export interface LeadrHomeCascadingGoalRow {
  id: string,
  title: string,
  progress: Progress,
  owner: Owner,
  path: string[],
  totalChildGoals: number,
  subText?: string,
  isPrivate?: boolean,
}

const formatGoal = (goal: FlattenedChildGoal): LeadrHomeCascadingGoalRow => {
  const owner = goal.participants.find((participant) => participant.role === Goals.GoalParticipantRole.Owner);
  const { completionPercentage, status } = goal.currentStatusUpdate;
  const isTeamGoal = goal.context.contextType === Goals.GoalContextType.Team;
  const teamName = goal.context.contextName;

  return {
    id: goal.goalId,
    title: goal.title,
    path: goal.path,
    isPrivate: goal.isPrivate,
    subText: isTeamGoal && teamName ? teamName : goal.context.contextType,
    progress: {
      percentage: completionPercentage ?? 0,
      status: status ?? '',
    },
    totalChildGoals: goal.totalChildGoals,
    owner: {
      imgUrl: owner?.profileImageUrl ?? '',
      name: `${owner?.firstName ?? ''} ${owner?.lastName ?? ''}`,
    },
  };
};

export const getLeadrHomeCascadingTableRows = (data?: Goals.LinkedGoal[]): LeadrHomeCascadingGoalRow[] => {
  if (!data) {
    return [];
  }

  const flattenedGoals = flattenGoals(data as unknown as Goals.GoalWithCascading[]);

  const tableRows: LeadrHomeCascadingGoalRow[] = flattenedGoals.map((goal) => {
    const formattedGoal = formatGoal(goal as unknown as FlattenedChildGoal);
    return formattedGoal;
  }) ?? [];

  return tableRows;
};
