import { UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { DeleteNoteData, useDeleteNote } from '~Common/hooks/useDeleteNote';
import { GetNotesResponse } from '~Common/hooks/useGetNotes';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { cloneDeep } from 'lodash';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';

export const useDeleteActionItemComment = (): UseMutationResult<HttpCallReturn<null>, unknown, DeleteNoteData> => {
  const orgId = getOrganizationId();
  const orgUserId = getOrganizationUserId();

  return useDeleteNote({
    onMutate: async (variables) => {
      const { id, context } = variables;
      const [queryKey, queryData] = queryClient.getQueriesData({ queryKey: [orgId, 'notes', context.type, context.id] })[0];
      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<HttpCallReturn<GetNotesResponse>>(queryKey, (originalData) => {
        if (originalData) {
          const updatedData = cloneDeep(originalData);
          updatedData.response.notes = updatedData?.response.notes.filter((note) => (note.id !== id));

          return updatedData;
        }

        return originalData;
      });

      return { queryKey, queryData };
    },
    onSettled: async (_, __, variables) => {
      const { context } = variables;

      await queryClient.invalidateQueries({ queryKey: ['organizations', 'details', orgId, context.id, context.type] });
      await queryClient.invalidateQueries({ queryKey: [orgId, 'action-items'] });
      await queryClient.invalidateQueries({ queryKey: [orgUserId, 'goals'] });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
    onError: () => {
      toast.error('Action Item comment could not be deleted');
    },
  });
};
