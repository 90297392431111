import { css } from '@emotion/react';
import {
  ChangeEvent, ComponentProps, useCallback,
} from 'react';
import { withoutDesktopObject } from '~Common/styles/mixins';
import { useDebounceDraftState } from '~Common/hooks/useDebounceDraftState';
import { getLocalId } from '~Common/utils/uuid';
import { useMarkAgendaItemComplete } from '~Meetings/hooks/v2/useMarkAgendaItemComplete';
import { useAgendaPermissions } from '~Meetings/hooks/utils/useAgendaPermissions';
import LeadrCheckbox from '~Common/V3/components/LeadrCheckbox';

const styles = {
  itemContainer: css({
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    alignSelf: 'flex-start',
    justifyContent: 'flex-start',
  }, withoutDesktopObject({
    justifyContent: 'start',
    marginRight: '0.5rem',
    paddingTop: '0.1rem',
  })),
};

interface LeftSideComponentViewProps extends ComponentProps<'div'> {
  isComplete: boolean,
  onAgendaItemCompleted: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  canComplete: boolean,
}

const View = ({
  isComplete,
  onAgendaItemCompleted,
  canComplete,
  ...props
}: LeftSideComponentViewProps): JSX.Element => (
  <div css={styles.itemContainer} {...props}>
    <LeadrCheckbox
      size={16}
      disabled={!canComplete}
      checked={isComplete}
      data-test-id="meetingsAgendaItemComplete"
      variant="circle"
      onChange={onAgendaItemCompleted}
    />
  </div>
);

interface LeftSideComponentProps {
  agendaId: string,
  huddleId: string,
  isComplete: boolean,
  sectionId?: string,
}

const LeftSideComponent = ({
  huddleId,
  agendaId,
  isComplete,
  sectionId,
  ...props
}: LeftSideComponentProps): JSX.Element => {
  const { getTopicPermissions } = useAgendaPermissions(huddleId);
  const { canUpdateAgendaStatus } = getTopicPermissions(agendaId, sectionId);

  const { mutate: markComplete } = useMarkAgendaItemComplete({
    sectionId,
  });

  const doMarkComplete = useCallback((isChecked: boolean) => {
    markComplete({
      huddleId,
      agendaId,
      isComplete: isChecked,
      // Needed for the realtime success message to be sent by the server
      eventId: getLocalId(),
    });
  }, [markComplete, huddleId, agendaId]);

  // ToDo: Update code to use returned draft once we have a good method of handling
  // optimistic updates in tandem with useDebounceDraftState
  const [, setIsChecked] = useDebounceDraftState(isComplete, doMarkComplete);

  const onAgendaItemCompleted = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setIsChecked(checked);
  };

  const hookProps = {
    isComplete,
    onAgendaItemCompleted,
    canComplete: canUpdateAgendaStatus,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LeftSideComponent;
