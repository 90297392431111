import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { deleteApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

interface deleteCycleProps {
  id: string,
}

const deleteCycle = ({ id }: deleteCycleProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${id}`,
  };

  return deleteApi(serverUrl, {});
};

export const useDeleteCycle = (): UseMutateFunction<HttpCallReturn<void>, unknown, deleteCycleProps, void> => {
  const queryKey = [getOrganizationId() ?? '', 'reviewCycles'];
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: deleteCycle,
    onMutate: async () => {
      toastId.current = toast.info('Deleting the review cycle...', { autoClose: false });

      // Since there is so much data, this was moved here to make it more clear to the user and have a better experience
      await queryClient.invalidateQueries({ queryKey });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), getOrganizationUserId(), 'reviewCycles'] });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error deleting the review cycle. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully deleted the review cycle.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });

  return mutation.mutate;
};
