import {
  getApi, patchApi,
} from '~Deprecated/services/HttpService';

/*
  WARNING: there is no eslint after the following line
  this will surpress the @typescript-eslint errors for now, and much more
  feel free to move stuff above this line as it gets typed with the goal of typing everything in this file eventually
*/
/* eslint-disable */

// @ts-expect-error TODO: Remove this when this function is typed
export const updateUserSettings = async ({ data, userId, orgId }) => {
  const URL = `/organizations/${orgId}/users/${userId}/settings`;

  return patchApi(URL, data);
};

export const getAuthSocialConnections = async () => {
  const URL = '/auth/social/connection';

  return getApi(URL);
};

// @ts-expect-error TODO: Remove this when this function is typed
export const authDisconnect = async (connection) => {
  const URL = `/auth/social/unlink/${connection}`;

  return patchApi(URL);
};

// @ts-expect-error TODO: Remove this when this function is typed
export const updateUserProfile = async ({ data, userId, orgId }) => {
  const URL = `/organizations/${orgId}/users/${userId}/profile`;

  return patchApi(URL, data);
};

export const loadPersonalities = () => {
  const serverUrl = '/referenceData/personalities';
  return getApi(serverUrl);
};
