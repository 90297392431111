import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  title: css({
    fontSize: '1.125rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
    paddingBottom: '.75rem',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
    pageBreakBefore: 'avoid',
  }),
};

interface TitleProps {
  title: string,
}

const Title = ({
  title,
  ...props
}: TitleProps): JSX.Element => (
  <h3 css={styles.title} {...props}>{title}</h3>
);

export default Title;
