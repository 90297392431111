import { useMemo } from 'react';
import { css } from '@emotion/react';
import { LayoutGroup, m } from 'framer-motion';
import { forMobile, withoutDesktop, withoutMobile } from '~Common/styles/mixins';
import { FRAMER_PROPS } from '~Insights/const/cardStyles';
import { CardDetail } from '~Insights/const/types';
import { useExpandingCards } from '~Insights/hooks/useExpandingCards';
import { useIsTabletQuery } from '~Common/hooks/useMediaListener';
import { useInsightPrivileges } from '~Insights/hooks/useInsightPrivileges';
import { useInsightsUserPerspective, UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import InsightCard from './cards/InsightCard';

const sectionGap = '1.25rem';

const styles = {
  flexContainer: css({
    display: 'flex',
    gap: sectionGap,
  }, forMobile(`
    flex-direction: column;
  `)),

  standardFlexContainer: (isTablet:boolean) => css({
    flexDirection: isTablet ? 'column' : 'row',
  }),
  expandedFlexContainer: css({
    flexDirection: 'column',
  }),
  leftColumn: css({
    display: 'flex',
    flexDirection: 'column',
    gap: sectionGap,
    flex: 1,
  }, withoutMobile(`
    max-width: 78%;
  `)),
  rightColumn: css({
    flexBasis: '20%',
    display: 'flex',
    flexDirection: 'column',
    gap: sectionGap,
  }),
  bottomRow: css({
    display: 'flex',
    flexDirection: 'row',
    gap: sectionGap,

    '& > div': {
      flexBasis: '100%',
    },
  }, withoutDesktop(`
    flex-direction: column;
  `)),
};

interface ViewProps {
  expandedCard?: CardDetail,
  largeCards: CardDetail[],
  smallCards: CardDetail[],
  tinyCards: CardDetail[],
  currentExpandedCard: string,
  isTablet:boolean,
}

const View = ({
  expandedCard,
  largeCards,
  smallCards,
  isTablet,
  currentExpandedCard,
  tinyCards,
}: ViewProps): JSX.Element => (
  <LayoutGroup id="insights_dashboard">
    {expandedCard ? (
      <m.main layoutId="main" css={[styles.flexContainer, styles.expandedFlexContainer]}>
        <m.section layoutId="left" {...FRAMER_PROPS}>
          <InsightCard
            card={expandedCard}
            size="expanded"
          />
        </m.section>
        <m.section layoutId="right" {...FRAMER_PROPS} css={styles.bottomRow}>
          {tinyCards
            .filter((card) => card.key !== currentExpandedCard)
            .map((card) => (
              <InsightCard
                key={card.key}
                card={card}
                size="tiny"
              />
            ))}
        </m.section>
      </m.main>
    ) : (
      <m.main layoutId="main" css={[styles.flexContainer, styles.standardFlexContainer(isTablet)]}>
        <m.section layoutId="left" {...FRAMER_PROPS} css={styles.leftColumn}>
          {largeCards.map((card) => (
            <InsightCard
              key={card.key}
              card={card}
              size="dashboard"
            />
          ))}
        </m.section>
        {smallCards.length > 0 && (
          <>
            <m.section layoutId="right" {...FRAMER_PROPS} css={styles.rightColumn}>
              {smallCards.map((card) => (
                <InsightCard
                  key={card.key}
                  card={card}
                  size="dashboard"
                />
              ))}
            </m.section>
          </>
        )}

      </m.main>
    )}
  </LayoutGroup>
);

const Dashboard = ({
  ...props
}): JSX.Element => {
  const { cards, currentExpandedCard } = useExpandingCards();
  const isTablet = useIsTabletQuery();
  const { canSeeInsights, hasDirectReports } = useInsightPrivileges();
  const expandedCard = useMemo(() => cards.find((card) => card.key === currentExpandedCard), [cards, currentExpandedCard]);
  const smallCards: CardDetail[] = [];
  const largeCards: CardDetail[] = [];
  const tinyCards: CardDetail[] = [];
  const { userPerspective } = useInsightsUserPerspective();

  cards.forEach((card) => {
    if (userPerspective === UserPerspective.manager) {
      if (card.requiresDirectReports && (!canSeeInsights || hasDirectReports < 2)) return;
    }
    if (card.dashboardSize === 'large') {
      largeCards.push(card);
    } else {
      smallCards.push(card);
    }
    if (card.tinyComponent) {
      tinyCards.push(card);
    }
  });

  const hookProps = {
    isTablet,
    expandedCard,
    largeCards,
    smallCards,
    tinyCards,
    currentExpandedCard,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default Dashboard;
