import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import CompletedLearningsProgressBar from '~Learning/components/Shared/LearningDashboardCards/CompletedLearningsProgressBar';
import GridLearningCard from '~Learning/components/Shared/LearningDashboardCards/GridLearningCard';
import { ReceivedLearning, LearningStatus, LearningType } from '~Learning/const/interfaces';

const styles = {
  receivedGridDashboard: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(20.9375rem, 1fr));',
    gridAutoRows: '1fr',
    width: '100%',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '8.75rem',
  }),
};

interface ReceivedGridDashboardProps extends ComponentProps<'div'> {
  receivedLearnings: ReceivedLearning[] | undefined,
  onReceivedCardClick: (receivedLearning: ReceivedLearning) => void,
}

const ReceivedGridDashboard = ({
  receivedLearnings,
  onReceivedCardClick,
  ...props
}: ReceivedGridDashboardProps): JSX.Element => (
  <div css={styles.receivedGridDashboard} {...props}>
    {receivedLearnings?.map((receivedLearning) => (
      <GridLearningCard
        key={receivedLearning.id}
        id={receivedLearning.id}
        data-test-id="receivedLearningCard"
        onCardClick={() => onReceivedCardClick(receivedLearning)}
        learningType={receivedLearning.learningType}
        title={receivedLearning.title}
        description={receivedLearning.description}
        dueDate={receivedLearning.dueDate}
        assignedUsersInfo={[receivedLearning.assigner]}
        numberOfUsersAssigned={1}
        isComplete={receivedLearning.status === LearningStatus.COMPLETED}
        renderCompletedSection={() => (
          <>
            {receivedLearning.learningType === LearningType.PLAYLIST && (
              <CompletedLearningsProgressBar
                totalLearningsCount={receivedLearning.totalLearningsCount}
                completedLearningsCount={receivedLearning.completedLearningsCount}
                renderPercentText={(value) => (
                  <>{`${value}%`}</>
                )}
              />
            )}
          </>
        )}
      />
    ))}
  </div>
);

const SkeletonView = (): JSX.Element => (
  <MultipleSkeletonLoaders
    css={styles.receivedGridDashboard}
    numberOfSkeletons={12}
    renderSkeletonItem={() => (
      <CardSkeleton css={styles.cardSkeleton} />
    )}
  />
);

export default ReceivedGridDashboard;

export { SkeletonView as ReceivedGridDashboardSkeleton };
