import { css } from '@emotion/react';
import { faMinimize } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hexToRGBA, palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';
import { DoShrink } from '~Insights/const/types';
import { useExpandingCards } from '~Insights/hooks/useExpandingCards';

const styles = {
  root: css({
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    borderRadius: '2px',
    color: palette.neutrals.gray500,
    fontSize: '1rem',
    lineHeight: '1rem',
    padding: '0.3rem',
  }),
};

interface ViewProps {
  doShrink: DoShrink
}

const View = ({
  doShrink,
  ...props
}: ViewProps): JSX.Element => (
  <Button
    css={styles.root}
    {...props}
    variant="text"
    onClick={doShrink}
    renderContents={() => (
      <FontAwesomeIcon icon={faMinimize} />
    )}
  />
);

const ShrinkButton = ({
  ...props
}): JSX.Element => {
  const { doShrink } = useExpandingCards();
  const hookProps = {
    doShrink,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ShrinkButton;
