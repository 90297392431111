import { create } from 'zustand';

export interface ReviewSaveState {
    reviewSaveStates: Record<string, boolean>
    updateReviewSaveState: (reviewId: string, isFullySaved: boolean) => void
}

export const useReviewSaveStateStore = create<ReviewSaveState>()((set) => ({
  reviewSaveStates: {},
  updateReviewSaveState: (reviewId: string, isFullySaved: boolean) => set((state: ReviewSaveState) => {
    const saveStates = { ...state.reviewSaveStates };

    saveStates[reviewId] = isFullySaved;

    return {
      reviewSaveStates: saveStates,
    };
  }),
}));
