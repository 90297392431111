import { css } from '@emotion/react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import LeadrCard from '~Common/V3/components/LeadrCard';
import { Skeleton } from '@mui/material';
import Tooltip from '~Common/components/Tooltip';
import { Goals } from '@leadr-hr/types';
import { palette } from '~Common/styles/colors';
import { useActionMenu } from '~Reviews/V2/Shared/ActionMenu';
import { useGoalStatusModalStore } from '~Goals/stores/useGoalsStatusModalStore';
import moment from 'moment';
import { SelectOption, usePeopleTagPicker } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import { UserGroup } from '~Common/const/userGroups';
import { DEFAULT_OWNER } from '~Goals/const/defaults';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';
import { GoalActionItems } from '../GoalDetails/GoalActionItems';
import { GoalDetails } from '../GoalDetails/GoalDetails';
import { GoalDetailsHistory } from '../GoalDetails/GoalDetailsHistory';
import { GoalDetailsOwnerAndParticipants } from '../GoalDetails/GoalDetailsOwnerAndParticipants';
import { GoalDescription } from '../GoalDetails/GoalDescription';
import { GoalDetailStatus } from '../GoalDetails/GoalDetailsStatus';
import { GoalUpdateStatusModal } from '../GoalUpdateStatusModal';
import { GoalStatusHistoryModal } from '../GoalStatusHistoryModal';
import { GoalActionMenu } from '../GoalActionMenu';

const styles = {
  leadrCard: (isMobile: boolean, isDrawer: boolean) => css({
    marginTop: '1.875rem',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    marginBottom: '2rem',
  }, isMobile && {
    gridTemplateColumns: '1fr',
  }, isDrawer && {
    margin: 0,
    padding: 0,
    boxShadow: 'none',
    overflow: 'unset',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  }),
  ownerAvatar: css({
    marginRight: '0.5rem',
  }),
  goalTitle: css({
    color: palette.neutrals.gray800,
  }),
  detailsTitle: (isMobile: boolean) => css({
    gridColumn: '1 / 4',
    fontSize: '1.125rem',
    fontWeight: 600,
    padding: '0.5rem 0 1rem 0',
    borderBottom: `1px solid ${palette.neutrals.gray300}`,
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  }, isMobile && {
    gridColumn: '1',
  }),
  detailsLeftSide: (isMobile: boolean) => css({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    borderRight: `1px solid ${palette.neutrals.gray300}`,
    paddingRight: '1.875rem',
  }, isMobile && {
    padding: 0,
    borderRight: 'none',
  }),
  detailsRightSide: (isMobile: boolean, isTablet: boolean) => css({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    paddingLeft: '1.875rem',
  }, isMobile && {
    padding: 0,
    marginTop: '1.5rem',
  }, isTablet && {

  }),
  allowProperHeight: css({
    transform: 'initial',
  }),
  lockIcon: css({
    marginLeft: '.5rem',
    color: palette.neutrals.gray700,
    fontSize: '.9375rem',
  }),
  heading: css({
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    fontWeight: 600,
  }),
  skellyLoader: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.25rem',
    marginTop: '.75rem',
    marginBottom: '1.5rem',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '3.125rem',
  }),
  goalActionMenu: css({
    '& .MuiMenu-list > div li': {
      fontWeight: '500',
    },
    '& .MuiMenu-list > div li:last-of-type': {
      color: palette.brand.red,
      position: 'relative',

      '& svg': {
        color: palette.brand.red,
      },
      '&:before': {
        borderTop: `1px solid ${palette.neutrals.gray200}`,
        width: '80%',
        display: 'block',
        height: '2px',
        content: '""',
        position: 'absolute',
        top: '0',
        transform: 'translate(0, -50%)',
      },
    },
  }),
  goalEllipsis: css({
    color: palette.neutrals.gray500,
    marginLeft: '.5rem',
    marginTop: '-6px !important',

    '& svg': {
      border: `1px solid ${palette.neutrals.gray200}`,
      borderRadius: '.1875rem',
      color: palette.neutrals.gray500,
    },
  }),
  titleSkelly: css({
    width: '50%',
  }),
  creatorDate: css({
    color: palette.neutrals.gray600,
    fontWeight: 500,
    fontSize: '.75rem',
    marginTop: '-1rem',
  }),
};

interface ViewProps {
  showSkeleton: boolean,
  goal: Goals.Goal | undefined,
  isMobile: boolean,
  isTablet: boolean,
  isDrawer?: boolean,
  openStatusUpdateModal: (goal: Goals.Goal) => void,
  isReadOnly: boolean,
  ownerOrgId: string | undefined,
  owner: Goals.GoalParticipant,
  validAssignees: SelectOption[],
  allParticipants: Goals.GoalParticipant[],
}

const View = ({
  showSkeleton,
  goal,
  isMobile,
  isTablet,
  isDrawer = false,
  openStatusUpdateModal,
  isReadOnly,
  ownerOrgId,
  owner,
  validAssignees,
  allParticipants,
}: ViewProps): JSX.Element => (
  <>
    <LeadrCard
      css={styles.leadrCard(isMobile, isDrawer)}
    >
      {!isDrawer && (
        <>
          <h1 css={styles.detailsTitle(isMobile)}>
            {showSkeleton && (
            <>
              <Skeleton
                variant="text"
                animation="wave"
                css={[styles.allowProperHeight, styles.titleSkelly]}
              />
            </>
            )}
            {!showSkeleton && goal && (
            <>
              <div css={styles.header}>
                <NewBaseAvatar
                  profileImageUrl={owner.profileImageUrl}
                  name={`${owner.firstName} ${owner.lastName}`}
                  avatarSize={30}
                  css={styles.ownerAvatar}
                />
                <TooltipOnTruncate css={styles.goalTitle} data-test-id="goalsTitle" text={goal?.title} />
                {goal?.isPrivate && (
                <Tooltip content="This is a private goal. It is only visible to you.">
                  <span>
                    <FontAwesomeIcon
                      css={styles.lockIcon}
                      icon={faLock}
                    />
                  </span>
                </Tooltip>
                )}
              </div>
              <GoalActionMenu goal={goal} />
            </>
            )}
          </h1>
        </>
      )}
      <div
        css={styles.detailsLeftSide(isMobile)}
      >
        {goal?.statusUpdates && (
          <GoalDetailStatus
            goal={goal}
            status={goal?.statusUpdates[0]}
            showSkeleton={showSkeleton}
            isMobile={isMobile}
            isDrawer={isDrawer}
            openStatusUpdateModal={openStatusUpdateModal}
            isReadOnly={isReadOnly}
          />
        )}
        {goal?.description && (
          <GoalDescription
            text={goal.description}
            showSkeleton={showSkeleton}
          />
        )}
        {goal?.goalId && !isMobile && (
          <GoalActionItems
            id={goal?.goalId}
            goalCreatorId={goal.creator.orgUserId}
            goalOwnerId={ownerOrgId}
            participants={allParticipants}
            validAssignees={validAssignees as unknown as Goals.GoalParticipant[]}
            showSkeleton={showSkeleton}
            isMobile={isMobile}
            canAddItems={goal.permissions.includes(Goals.GoalPermission.CanEditGoal) && !isReadOnly}
          />
        )}
        {!goal?.goalId && (
          <>
            <div>
              <span
                css={styles.heading}
              >
                Action Items
              </span>
              <MultipleSkeletonLoaders
                css={styles.skellyLoader}
                numberOfSkeletons={5}
                renderSkeletonItem={() => (
                  <CardSkeleton css={styles.cardSkeleton} />
                )}
              />
            </div>
          </>
        )}
      </div>
      <div
        css={styles.detailsRightSide(isMobile, isTablet)}
      >
        <GoalDetails
          goal={goal}
          showSkeleton={showSkeleton}
          isMobile={isMobile}
        />
        <GoalDetailsOwnerAndParticipants
          participants={allParticipants}
          showSkeleton={showSkeleton}
          isTablet={isTablet}
          isDrawer={isDrawer}
          goal={goal}
          permissions={goal?.permissions}
        />
        {!isDrawer && (
          <GoalDetailsHistory
            goalId={goal?.goalId ?? ''}
            statusUpdates={goal?.statusUpdates}
            showSkeleton={showSkeleton}
          />
        )}

        {goal?.creator?.firstName && !isDrawer && (
          <p
            css={styles.creatorDate}
          >
            {`Created by ${goal.creator.firstName} ${goal.creator.lastName} on ${moment(goal.createdInMillis).format('MMM DD, YYYY')}`}
          </p>
        )}
        {goal?.goalId && isMobile && (
          <GoalActionItems
            id={goal?.goalId}
            validAssignees={goal?.participants}
            participants={allParticipants}
            showSkeleton={showSkeleton}
            isMobile={isMobile}
            goalCreatorId={goal.creator.orgUserId}
            goalOwnerId={ownerOrgId}
            canAddItems={goal.permissions.includes(Goals.GoalPermission.CanEditGoal) && !isReadOnly}
          />
        )}
      </div>
    </LeadrCard>
    <GoalStatusHistoryModal />
    <GoalUpdateStatusModal />
  </>
);

interface ViewGoalDetailsProps {
  showSkeleton: boolean,
  goal: Goals.Goal | undefined,
  isMobile: boolean,
  isTablet: boolean,
  isDrawer?: boolean,
  isReadOnly?: boolean,
}

const ViewGoalDetails = ({
  showSkeleton,
  goal,
  isMobile,
  isTablet,
  isDrawer = false,
  isReadOnly = false,
}: ViewGoalDetailsProps): JSX.Element => {
  const { doOpen, ...actionMenuProps } = useActionMenu();

  const { openStatusUpdateModal } = useGoalStatusModalStore((state) => ({
    openStatusUpdateModal: state.openStatusUpdateModal,
  }));

  // eslint-disable-next-line max-len
  const ownerParticipant = goal?.participants?.find((participant) => participant.role === Goals.GoalParticipantRole.Owner && participant.firstName !== undefined);
  const ownerOrgId = ownerParticipant ? ownerParticipant.orgUserId : undefined;

  const ownerToUse = ownerParticipant ?? DEFAULT_OWNER;

  const accountTypesToInclude = [UserGroup.Admin, UserGroup.Member, UserGroup.Executive];

  const allParticipants = [
    ...(goal?.participants || []),
    ...(goal?.implicitParticipants || []).filter(
      (participant) => !(participant.firstName.toLowerCase() === 'leadr' && participant.lastName.toLowerCase() === 'coach'),
    ),
  ];

  const {
    peopleList: validAssignees,
  } = usePeopleTagPicker({
    accountTypesToInclude,
    administrativeStatusesToInclude: ['ACTIVE', 'INVITED'],
  });

  const hookProps = {
    showSkeleton,
    goal,
    isMobile,
    isTablet,
    isDrawer,
    actionMenuProps,
    doOpen,
    openStatusUpdateModal,
    isReadOnly,
    ownerOrgId,
    owner: ownerToUse,
    validAssignees,
    allParticipants,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default ViewGoalDetails;
