import { SerializedStyles, css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { decodeEntities } from '../../../deprecated/redux/sagas/utils';

const styles = {
  container: css`
    display: flex;
    align-items: center;
  `,
  icon: css`
    width: 1.5rem !important;
    height: 1.5rem;
    margin-right: 0.75rem;
  `,
};
interface BaseToastProps {
  icon: IconProp;
  text?: string;
  customIconStyle?: SerializedStyles;
  children?: React.ReactNode;
  spin?: boolean;
}
const BaseToast = ({
  icon, customIconStyle, text, children, spin = false,
}:BaseToastProps):JSX.Element => (
  <div
    css={[styles.container]}
  >
    <FontAwesomeIcon
      css={[styles.icon, customIconStyle]}
      icon={icon}
      spin={spin}
    />
    {!children && (<h5>{decodeEntities(text)}</h5>)}
    {children}
  </div>
);

export default BaseToast;
