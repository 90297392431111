import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';

const styles = {
  chip: css({
    borderRadius: '0.375rem',
    color: palette.neutrals.white,
    backgroundColor: palette.neutrals.gray600,
    padding: '0.125rem 0.25rem',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
  }),
};

export interface LeadrChip {
  label: string | number
}

// Simple placeholder component until we can implement a proper common Chip component
// ToDo: Actually build this thing out once we have time and details
const LeadrChip = ({
  label,
}: LeadrChip): JSX.Element => (
  <div css={styles.chip}>
    { label }
  </div>
);

export default LeadrChip;
