import { useCallback, useMemo, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useGlobalSearch } from '~Common/hooks/useGlobalSearch';
import { useGroupings } from '~Common/hooks/useGroupings';
import { flattenData } from '~Common/hooks/utils';
import { REQUEST_TYPE } from '~Deprecated/common/const/request-type';
import { getApi, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { RequestSendSurvey } from '~Surveys/Hooks/useSendSurveyState';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { useSurveysSearch } from './useSurveysSearch';
import { useSurveyRequestTypeFilter } from './useSurveyRequestType';

export const assignedSurveysKey = [getOrganizationId(), 'surveys', 'assignedSurveys', 'list'];
const mySurveysKey = [getOrganizationId(), 'surveys', 'mySurveys', 'list'];
export const pendingSurveysKey = [getOrganizationId() ?? '', 'surveys', 'assignedSurveys', 'notification'];

export const mySurveysQuery = async () => {
  const URL = `/organizations/${getOrganizationId()}/surveys/mySurveys?participants=true`;

  const { response } = await getApi(URL);

  const { ids, data } = flattenData({
    responseData: (response),
    onItemFlatten: (item) => ({
      ...item,
      surveyParticipants: item.participants,
      participants: item.participants.map((surveyParticipant) => surveyParticipant.participant),
    }),
  });

  return { ids, data };
};

const assignedSurveysQuery = async () => {
  const URL = `/organizations/${getOrganizationId()}/surveys/assignedSurveys?participants=true`;

  const { response } = await getApi(URL);

  const { ids, data } = flattenData({
    responseData: (response),
    onItemFlatten: (item) => ({
      ...item,
      surveyParticipant: item.participant,
      participant: item.participant.participant,
    }),
  });

  return { ids, data };
};

async function pendingSurveysQuery() {
  const organizationId = getOrganizationId() ?? '';
  const serverUrl = `/organizations/${organizationId}/surveys/pending`;
  return getApi(serverUrl);
}

export function useGetPendingSurveys() {
  const result = useQuery({
    queryKey: pendingSurveysKey,
    queryFn: pendingSurveysQuery,
  });

  return {
    surveys: result?.data?.response,
  };
}

export const queryPicker = async (isReceivedSurvey) => {
  const data = isReceivedSurvey ? await assignedSurveysQuery() : await mySurveysQuery();

  return data;
};

const useSurveysQuery = (isReceivedSurvey) => useQuery({
  queryKey: isReceivedSurvey ? assignedSurveysKey : mySurveysKey,
  queryFn: () => queryPicker(isReceivedSurvey),
});

export const useGetSurveys = () => {
  const [surveyType] = useSurveyRequestTypeFilter();
  const groupBy = 'groupBy.status';
  const { searchText } = useSurveysSearch();
  const isReceivedSurvey = surveyType === REQUEST_TYPE.RECEIVED;

  const result = useSurveysQuery(isReceivedSurvey);

  const { ids, data } = result?.data ?? { ids: [], data: {} };

  const buildRelationships = useCallback(() => (
    ids.map((id) => {
      const survey = data[id];
      const statusCheck = isReceivedSurvey ? survey?.surveyParticipant?.completedOn || survey?.completedOn : survey.completedOn;

      return {
        ...survey,
        groupBy: {
          status: statusCheck ? 'Complete' : 'Incomplete',
        },
      };
    })
  ), [ids, data, isReceivedSurvey]);

  const { ids: searchIds, data: searchData } = useGlobalSearch(ids, buildRelationships, searchText, 'id');

  const { groupedIds } = useGroupings({
    ids: searchIds,
    data: searchData,
    groupKey: groupBy !== 'none' ? groupBy : null,
    groupKeyOrder: {},
    comparator: (a) => (a === 'Incomplete' ? -1 : 1),
  });

  return {
    groupedIds,
    ids: searchIds,
    data: searchData,
    initialPageItemCount: result?.data?.ids?.length,
    result,
    isLoading: result?.isLoading,
    searchString: searchText,
  };
};

export const useGetSurveyById = ({ id }) => {
  const [surveyType] = useSurveyRequestTypeFilter();
  const isReceivedSurvey = surveyType === REQUEST_TYPE.RECEIVED;
  const { data } = useSurveysQuery(isReceivedSurvey);
  // TODO: this will need to be pulled out once pulse surveys are removed from the BE
  let survey = null;

  if (!data.data[id]?.surveySeriesId) {
    survey = data.data[id];
  }

  return {
    survey,
  };
};

export const createSurvey = (survey) => {
  const URL = `/organizations/${getOrganizationId()}/surveys`;

  return postApi(URL, survey, {});
};

export const useCreateSurvey = ({ setSendSurvey }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: createSurvey,
    onMutate: () => {
      toastId.current = toast.info('Creating the survey...', { autoClose: false });
      setSendSurvey(RequestSendSurvey.NOTALLOWED);
    },
    onError: (error) => {
      toast.update(toastId.current, {
        render: error.toString() ?? 'There was an error creating the survey. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
      setSendSurvey(RequestSendSurvey.ALLOWED);
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully created the survey.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      queryClient.invalidateQueries(mySurveysQuery);
      dispatch(popDrawerAction({ popAll: true }));
    },
  });

  return {
    createSurveyMutation: mutation.mutate,
    isLoading: mutation.isPending,
  };
};

export const surveyDetail = async ({ queryKey }) => {
  const [, , , surveyId, withQuestions, withAnswers, withParticipants] = queryKey;
  let URL = `/organizations/${getOrganizationId()}/surveys/${surveyId}`;

  let addAnd = false;

  if (withQuestions) {
    URL = `${URL}?questions=true`;

    addAnd = true;
  }

  if (withAnswers) {
    URL = addAnd ? `${URL}&answers=true` : `${URL}?answers=true`;

    addAnd = true;
  }

  if (withParticipants) {
    URL = addAnd ? `${URL}&participants=true` : `${URL}?participants=true`;
  }

  const { response } = await getApi(URL);

  const mappedResponse = {
    ...response,
    participants: response.participants.map((participant) => participant.participant),
    surveyParticipants: response.participants,
  };

  return { response: mappedResponse };
};

export const useSurveyDetail = ({
  surveyId, withQuestions, withAnswers, withParticipants,
}) => {
  const result = useQuery({
    queryKey: [getOrganizationId(), 'survey', 'assignedSurveys', surveyId, withQuestions, withAnswers, withParticipants],
    queryFn: surveyDetail,
  });

  const survey = result.data?.response;

  const participantIds = useMemo(() => survey?.participants.map((participant) => participant.uid), [survey]);

  return {
    isLoading: result.isLoading,
    survey,
    participantIds,
  };
};

export const submitSurveyAnswers = ({ surveyId, surveyAnswers }) => {
  const URL = `/organizations/${getOrganizationId()}/surveys/${surveyId}/submitAnswers`;

  return postApi(URL, surveyAnswers);
};

export const useSubmitSurveyAnswers = ({
  surveyId,
}) => {
  const toastId = useRef(null);

  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: submitSurveyAnswers,
    onMutate: () => {
      toastId.current = toast.info('Submitting the survey...', { autoClose: false });
    },
    onError: (error) => {
      toast.update(toastId.current, {
        render: error.message ?? 'There was an error submitting the survey. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully submitted the survey.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey: assignedSurveysKey });
      queryClient.invalidateQueries({ queryKey: pendingSurveysKey });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'survey', 'assignedSurveys', surveyId] });
      queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeSurvey(getOrganizationId()) });
      dispatch(popDrawerAction({ popAll: true }));
    },
  });

  return mutation.mutate;
};

export const surveyCSV = async ({
  surveyId,
  byQuestion,
  withNullAnswers,
  byParticipant,
}) => {
  let URL = `/organizations/${getOrganizationId()}/surveys/${surveyId}/export2CSV`;

  let addAnd = false;

  if (byQuestion) {
    URL = `${URL}?byQuestions=true`;

    addAnd = true;
  } else {
    URL = `${URL}?byQuestions=false`;

    addAnd = true;
  }

  if (withNullAnswers) {
    URL = addAnd ? `${URL}&includeNullAnswers=true` : `${URL}?includeNullAnswers=true`;

    addAnd = true;
  } else {
    URL = addAnd ? `${URL}&includeNullAnswers=false` : `${URL}?includeNullAnswers=false`;

    addAnd = true;
  }

  if (byParticipant) {
    URL = addAnd ? `${URL}&byParticipants=true` : `${URL}?byParticipants=true`;
  } else {
    URL = addAnd ? `${URL}&byParticipants=false` : `${URL}?byParticipants=false`;
  }

  // Response is string that contains comma separated numbers(bytes), message is used for the file name on download
  const { response, message } = await getApi(URL);

  let url;

  if (response) {
    // Turning the comma separated numbers into an array of numbers
    const arrayOfBytes = response.split(',').map((value) => parseInt(value, 10));
    // Converting the array to a Uint8Array
    const uint8ArrayOfBytes = Uint8Array.from(arrayOfBytes);
    // Create a blob so we can make a downloadable file
    const blob = new Blob([uint8ArrayOfBytes.buffer], { type: 'text/csv' });

    url = window.URL.createObjectURL(blob);
  }

  // Hack to create a button and click it to start the download of the CSV
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = message;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  return url;
};

export const useGetSurveyCSV = () => {
  const mutation = useMutation({
    mutationFn: surveyCSV,
  });

  return mutation.mutate;
};
