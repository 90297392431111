import { css } from '@emotion/react';
import AvatarMaybeMultipleMaybeNot from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';

const styles = {
  icon: css({
    width: '1.3125rem',
    height: '1.3125rem',
    color: palette.neutrals.gray700,
  }),
  avatar: css({
    '&:not(:last-of-type)': {
      marginRight: '.25rem',
    },
  }),
};

interface ParticipantAvatarsProps {
  usersInfo: PersonDisplayInformation[],
}

const ParticipantAvatars = ({
  usersInfo,
}: ParticipantAvatarsProps): JSX.Element => (
  <AvatarMaybeMultipleMaybeNot
    usersInfo={usersInfo}
    numberOfUsers={usersInfo.length}
    numberOfUsersToShow={3}
    avatarHeight={22}
    avatarWidth={22}
    renderAvatar={({ user, avatarHeight, avatarWidth }) => (
      <div css={styles.avatar}>
        <AvatarWithTooltip
          firstName={user?.firstName}
          lastName={user?.lastName}
          profileImageUrl={user?.profileImageUrl}
          height={avatarHeight}
          width={avatarWidth}
        />
      </div>
    )}
    renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
      <OverflowAvatar
        css={styles.avatar}
        {...overflowAvatarProps}
      />
    )}
  />
);

export default ParticipantAvatars;
