import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

export const scoringTooltipStyle = css({
  backgroundColor: palette.neutrals.white,
  boxShadow: '0px 4px 8px -2px #1C2A3733',

  "&[data-placement^='top'] > .tippy-arrow::before": {
    borderTopColor: `${palette.neutrals.white} !important`,
    boxShadow: '0px 4px 8px -2px #1C2A3733',
  },

  "&[data-placement^='bottom'] > .tippy-arrow::before": {
    borderBottomColor: `${palette.neutrals.white} !important`,
    boxShadow: '4px 0px 8px -2px #1C2A3733',
  },
});
