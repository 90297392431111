/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getBaseballCardUserNote } from './api';
import { useCreateBaseballCardNote, useUpdateBaseballCardNote } from './useMutators';

export const useBaseballCardNotes = (creatorId, userId, orgId) => {
  const detail = useQuery({
    queryKey: [orgId || getOrganizationId(), 'baseballCard_notes', creatorId, userId],
    queryFn: getBaseballCardUserNote,
    enabled: !!userId,
  });

  const note = detail?.data?.response?.notes?.[0] ?? {};

  return {
    detail,
    isLoading: detail?.isLoading,
    isFetching: detail?.isFetching,
    note,
    hasNote: !!note?.id,
  };
};

export const useMutateBaseballCardNotes = ({
  creatorId, userId,
}) => {
  const createdWith = useRef(null);
  const trailingUpdate = useRef(null);

  const { note, hasNote, isFetching: isFetchingNotes } = useBaseballCardNotes(creatorId, userId);
  const previousHasNote = usePrevious(hasNote);
  const { mutate: createNote, isPending: isCreatingNote } = useCreateBaseballCardNote();
  const { mutate: updateNote, isPending: isUpdatingNote } = useUpdateBaseballCardNote();

  const createNoteMutator = (mutatorArgs) => {
    // We need to make sure not to call this more than once.
    if (!isCreatingNote && !isFetchingNotes) {
      createNote({ userId, ...mutatorArgs });
      createdWith.current = mutatorArgs;
    } else if (mutatorArgs !== createdWith) {
      trailingUpdate.current = mutatorArgs;
    }
  };

  const updateNoteMutator = (mutatorArgs) => {
    updateNote({ userId, ...mutatorArgs });
  };

  useEffect(() => {
    if (previousHasNote !== null && hasNote && !previousHasNote) {
      // We just created a note, check if text has changed that needs patched
      if (trailingUpdate.current) {
        updateNoteMutator({ noteId: note?.noteId, ...trailingUpdate.current });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousHasNote]);

  // Only create if no note exists for the user.
  if (!hasNote) {
    return {
      isMutating: isCreatingNote,
      updateNote: createNoteMutator,
    };
  }

  return {
    isMutating: isUpdatingNote,
    updateNote: updateNoteMutator,
  };
};
