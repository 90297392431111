import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { palette } from '~Common/styles/colors';
import { useShowAgendaSection } from '../../stores/useShowAgendaSection';

const styles = {
  collapseButton: css({
    padding: 0,
    marginRight: '0.6125rem',
    color: palette.brand.indigo,
  }),
  collapseIcon: (isExpanded: boolean) => css({
    rotate: '0deg',
    transition: 'rotate 100ms ease',
  }, isExpanded && {
    rotate: '180deg',
  }),
};

interface ViewProps extends Omit<LeadrButtonProps<'button'>, 'data-test-id'> {
  handleCollapseClick: () => void,
  showAgendaSection: boolean,
}

const View = ({
  handleCollapseClick,
  showAgendaSection,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrButton
    css={styles.collapseButton}
    onClick={handleCollapseClick}
    variant="icon"
    data-test-id={showAgendaSection ? 'meetingsTemplateCollapseAgendaSection' : 'meetingsTemplateExpandAgendaSection'}
    {...props}
  >
    <FontAwesomeIcon
      icon={faChevronUp}
      css={styles.collapseIcon(showAgendaSection)}
    />
  </LeadrButton>
);

interface CollapseButtonProps extends Omit<ViewProps, 'handleCollapseClick' | 'showAgendaSection'> {
  sectionLocalId: string,
}

const CollapseButton = ({
  sectionLocalId,
  ...props
}: CollapseButtonProps): JSX.Element => {
  const {
    getIsAgendaSectionExpanded,
    expandAgendaSection,
    collapseAgendaSection,
  } = useShowAgendaSection((state) => ({
    getIsAgendaSectionExpanded: state.getIsAgendaSectionExpanded,
    expandAgendaSection: state.expandAgendaSection,
    collapseAgendaSection: state.collapseAgendaSection,
  }));

  const showAgendaSection = getIsAgendaSectionExpanded(sectionLocalId);

  const handleCollapseClick = (): void => {
    if (showAgendaSection) {
      collapseAgendaSection(sectionLocalId);
    } else {
      expandAgendaSection(sectionLocalId);
    }
  };

  const hookProps = {
    handleCollapseClick,
    showAgendaSection,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CollapseButton;
