/* eslint-disable react/no-unused-prop-types */
import moment from 'moment';
import { ViewPerspective, SetDisplay } from '~Insights/const/types';
import { useToggleMenuOptions } from '~Insights/hooks/useToggleMenuOptions';

import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { useInsightsUserPerspective, UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { ENPSDataForDate, useENPSDataForDateRange } from '~Insights/hooks/useENPSData';
import { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { defualtMonthsToSubtract } from '~Insights/const/defaults';
import { HookedExpanded } from './Expanded';
import HookedDashboard from './Dashboard';

export interface SharedProps {
  toggleMenuOptions: LeadrToggleTextTabProps[],
  changeToggleMenu: (value: ViewPerspective) => void,
  toggleMenuValue: ViewPerspective,
  data: ENPSDataForDate[],
  isLoading: boolean,
  isMobile: boolean,
  userPerspective: UserPerspective,
  setDisplay?: SetDisplay,
}

interface ENPSCardProps {
  size: 'dashboard' | 'expanded'
}

const ENPSCard = ({
  size,
  ...props
}: ENPSCardProps): JSX.Element => {
  const { userPerspective } = useInsightsUserPerspective();
  const { data, isLoading } = useENPSDataForDateRange({
    startDate: moment().subtract(defualtMonthsToSubtract, 'months').startOf('day').toDate(),
    endDate: moment().startOf('day').toDate(),
    userPerspective,
  });

  const {
    dashboardToggleMenuOptions, expandedToggleMenuOptions, toggleMenuValue, setToggleMenuValue,
  } = useToggleMenuOptions('enpsPerspective');
  const changeToggleMenu = (value: ViewPerspective): void => {
    if (value) {
      setToggleMenuValue(value);
    }
  };

  const [setDisplay] = useQueryParamState<SetDisplay>('insights', 'setDisplay', SetDisplay.Desktop);
  const checkMobileQuery = useIsMobileQuery();

  const hookProps = {
    isMobile: setDisplay === SetDisplay.Mobile || checkMobileQuery === true,
    setDisplay,
    data,
    isLoading,
    toggleMenuValue,
    changeToggleMenu,
    userPerspective,
  };

  if (size === 'expanded') {
    return (
      <HookedExpanded
        {...hookProps}
        toggleMenuOptions={expandedToggleMenuOptions}
        {...props}
      />
    );
  }

  if (size === 'dashboard') {
    return (
      <HookedDashboard
        {...hookProps}
        toggleMenuOptions={dashboardToggleMenuOptions}
        {...props}
      />
    );
  }

  return <></>;
};

export { ENPSCard };
export default ENPSCard;
