import * as types from './index';

export const showAddNewAgenda = (payload) => ({
  type: types.SHOW_ADD_NEW_AGENDA,
  payload,
});

/** Start Meeting Deatil Page */

export const getMeetingById = (payload) => ({
  type: types.GET_MEETING_BY_ID,
  payload,
});

/**
 * team meetings
 */

export const removeFeedbackQuestionFromCoaching = (payload) => ({
  type: types.REMOVE_FEEDBACK_QUESTION_FROM_COACHING,
  payload,
});
