import { SyntheticEvent } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { css } from '@emotion/react';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { DashboardTab } from '../../types';

const styles = {
  tabs: css({
    '& .MuiTab-root': {
      minWidth: 'unset',
      paddingBottom: '1.5rem',
      paddingLeft: '0',
      paddingRight: '0',
      textTransform: 'unset',
    },
    '& .MuiTabs-flexContainer': {
      columnGap: '1.875rem',
      rowGap: '0.5rem',
    },
  }),
};

interface ViewProps {
  changeTab: (event: SyntheticEvent, tab: DashboardTab) => void,
  expandedTab: DashboardTab,
  hasDirects: boolean,
}
const View = ({
  changeTab,
  expandedTab,
  hasDirects,
}: ViewProps): JSX.Element => (
  <>
    <TabContext value={expandedTab}>
      <TabList css={styles.tabs} onChange={changeTab} aria-label="User Categories">
        {Object.entries(DashboardTab).map(([label, value]) => {
          if (!hasDirects && value === DashboardTab.Manager) {
            return null; // Exclude the tab if hasDirects is false and the value is DashboardTab.Manager
          }
          return <Tab label={value} key={label} value={value} />;
        })}
      </TabList>
    </TabContext>
  </>
);

interface ReflectionsToggleTabsProps {
  changeTab: (event: SyntheticEvent, tab: DashboardTab) => void,
  expandedTab: DashboardTab,
}

const ReflectionsToggleTabs = ({
  changeTab,
  expandedTab,
}: ReflectionsToggleTabsProps): JSX.Element => {
  const { hasDirects } = useUserPermissions();

  const hookProps = {
    changeTab,
    expandedTab,
    hasDirects,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ReflectionsToggleTabs };
export default ReflectionsToggleTabs;
