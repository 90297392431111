import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { palette } from '~Common/styles/colors';
import { DASHBOARD_CARD_COMPONENTS, EXPANDED_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import Tooltip from '~Common/components/Tooltip';
import { ActivityRow } from './ActivityRow';
import { SubtabComponentProps } from '../ExpandedSubtab';

const styles = {
  ...DASHBOARD_CARD_COMPONENTS,
  ...EXPANDED_CARD_COMPONENTS,
  footerIcon: css({
    color: palette.neutrals.gray700,
  }),
  iconRow: css({
    alignItems: 'center',
    columnGap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
  }),
};

const ActivityGroup = ({
  pageSlice,
}: SubtabComponentProps): JSX.Element => (

  <>
    <TableContainer component={Paper}>
      <Table aria-label="Dependents" size="small">
        <TableHead>
          <TableRow css={styles.tableRowColor}>
            <TableCell css={styles.semiHeaderName}>Name</TableCell>
            <TableCell css={styles.semiHeader}>Last Login</TableCell>
            <TableCell css={styles.semiHeader}>
              <div css={styles.iconRow}>
                Action in Last 7 Days
                <Tooltip content={(
                  <>
                    <p>Actions here are defined as:</p>
                    <ul>
                      <li>Creating a Meeting</li>
                      <li>Creating Meeting agenda items</li>
                      <li>Creating Goals</li>
                      <li>Creating a Goal status</li>
                      <li>Creating Goal ownership for self</li>
                      <li>Creating Feedback request</li>
                      <li>Creating Learning content</li>
                      <li>Creating Question response</li>
                    </ul>
                  </>
                )}
                >
                  <div><FontAwesomeIcon css={styles.footerIcon} icon={faCircleInfo} /></div>
                </Tooltip>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody css={styles.RowBody}>
          {pageSlice?.map((user) => (
            <ActivityRow key={user.orgUserId} userData={user} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

export { ActivityGroup };
