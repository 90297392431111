import { createUseShowThingActions } from '~Common/stores/ShowThingStore/createShowThingStore';
import { CreateAgendaItemProps } from '~Meetings/components/details/agenda/CreateAgendaItem';

export type ShowAgendaEditorProps = Pick<CreateAgendaItemProps, 'initialAttachments' | 'isPrivate' | 'isRecurring'>;

const prefix = 'agenda-editor-';

export const useShowAgendaEditor = createUseShowThingActions<
  ShowAgendaEditorProps,
  'showAgendaEditor',
  'hideAgendaEditor',
  'cleanupAgendaEditor',
  'useGetAgendaEditorProps',
  'useIsAgendaEditorVisisble'
>({
  prefix,
  actionNames: {
    showActionName: 'showAgendaEditor',
    hideActionName: 'hideAgendaEditor',
    cleanupActionName: 'cleanupAgendaEditor',
    useGetThingPropsActionName: 'useGetAgendaEditorProps',
    useIsThingVisibleActionName: 'useIsAgendaEditorVisisble',
  },
});
