import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { useShowItemEditor } from '../../stores/useShowItemEditor';

const styles = {
  emptyState: css({
    color: palette.neutrals.gray700,
    backgroundColor: palette.neutrals.gray50,
    padding: '.5rem 1rem',
    borderRadius: '.3125rem',
  }),
  emptyStateText: css({
    fontStyle: 'italic',
  }),
};

interface ViewProps {
  handleAddTopicClick: () => void,
}

const View = ({
  handleAddTopicClick,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.emptyState} {...props}>
    <LeadrButton
      variant="text"
      textButtonColor={palette.brand.indigo}
      css={styles.emptyStateText}
      data-test-id="meetingsTemplatesAgendaSectionEmptyStateAddFirstTopic"
      onClick={handleAddTopicClick}
    >
      Add the first topic.
    </LeadrButton>
  </div>
);

interface EmptyStateProps {
  newTopicLocalId: string,
}

const EmptyState = ({
  newTopicLocalId,
  ...props
}: EmptyStateProps): JSX.Element => {
  const showItemEditor = useShowItemEditor((state) => state.showItemEditor);

  const handleAddTopicClick = (): void => {
    showItemEditor(newTopicLocalId);
  };

  const hookProps = {
    handleAddTopicClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default EmptyState;
