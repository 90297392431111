import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { HTMLString } from '~Common/types';
import Card from '~Common/V3/components/Card';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  learningOverviewCard: css({}),
  cardHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  cardHeaderText: css({
    marginBottom: '.625rem',
    color: palette.neutrals.gray500,
    fontSize: '.625rem',
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontSize: '1.25rem',
    fontWeight: 500,
    marginBottom: '.1875rem',
  }),
  introductionText: css({
    color: palette.neutrals.gray500,
  }),
};

interface ViewProps {
  title: string,
  introductionHTML?: HTMLString,
  renderRightHeaderItem?: () => JSX.Element,
  cardHeaderText?: string,
}

const LearningOverviewCard = ({
  title,
  introductionHTML,
  renderRightHeaderItem,
  cardHeaderText = 'LEARNING CONTENT',
  ...props
}: ViewProps): JSX.Element => (
  <Card
    css={styles.learningOverviewCard}
    renderContents={() => (
      <div>
        <div css={styles.cardHeader}>
          <div css={styles.cardHeaderText}>
            {cardHeaderText}
          </div>
          {renderRightHeaderItem?.()}
        </div>
        <div css={styles.title}>
          {title}
        </div>
        {introductionHTML && (
          <div>
            <HTMLRenderer css={styles.introductionText} htmlText={introductionHTML} />
          </div>
        )}
      </div>
    )}
    {...props}
  />
);

export default LearningOverviewCard;
