/* eslint-disable no-bitwise */
// import { ChangeEvent } from 'react';
import { REVIEW_SETUP_LAYOUT } from '~Reviews/V2/Const/pageStyles';
import { SetupSteps } from '~Reviews/V2/Shared/SetupSteps';
import FloatingActionMenu from '~Reviews/V2/Shared/FloatingActionMenu';

import { SetupSection } from '~Reviews/V2/CreateCycle/SetupSection';
import { QuestionsSection } from '~Reviews/V2/CreateCycle/QuestionsSection';
import { ParticipantsSection } from '~Reviews/V2/CreateCycle/ParticipantsSection';
import { PreviewSection } from '~Reviews/V2/CreateCycle/PreviewSection';

import {
  ViewCyclePerspective, CycleShape, ParticipantTypeEnum, ReviewCycleStatusEnum,
} from '~Reviews/V2/Const/types';
import { DEFAULT_CYCLE } from '../Const/defaults';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
};

interface ViewProps {
  cycle: CycleShape,
  cycleId: string,
  isMatrixOrg: boolean,
  setViewCyclePerspective: (value: ViewCyclePerspective) => void,
  viewCyclePerspective: ViewCyclePerspective,
}

const View = ({
  cycle,
  cycleId,
  isMatrixOrg,
  setViewCyclePerspective,
  viewCyclePerspective,
}: ViewProps): JSX.Element => (
  <>
    <div css={styles.setupMainContainer}>
      <SetupSteps
        viewCyclePerspective={viewCyclePerspective}
      />

      {viewCyclePerspective === ViewCyclePerspective.Setup && (
        <SetupSection
          cycle={cycle}
          cycleId={cycleId}
        />
      )}
      {viewCyclePerspective === ViewCyclePerspective.Create_Questions && (
        <QuestionsSection
          cycle={cycle}
          cycleId={cycleId}
          viewCyclePerspective={viewCyclePerspective}
          isMatrixOrg={isMatrixOrg}
        />
      )}
      {viewCyclePerspective === ViewCyclePerspective.Add_Participants && (
        <ParticipantsSection
          cycleId={cycleId}
        />
      )}
      {viewCyclePerspective === ViewCyclePerspective.Preview_Publish && (
        <PreviewSection
          viewCyclePerspective={viewCyclePerspective}
          cycleId={cycleId}
          cycle={cycle}
        />
      )}
      {/*
        Instead of doing prop drilling or creating another portal for this one off case
        I decided to pull this component in on the one view where it needs to be different.
        If this changes in the future and we need to do one more, we need to make this a portal
        The one off is located on /CreateCycle/ParticipantsSection.tsx
       */}
      {viewCyclePerspective !== ViewCyclePerspective.Add_Participants && (
        <div>
          <FloatingActionMenu
            viewCyclePerspective={viewCyclePerspective}
            cycleId={cycleId}
            setViewCyclePerspective={setViewCyclePerspective}
            cycleIsNotDraft={cycle.statusEnum !== ReviewCycleStatusEnum.Draft}
          />
        </div>
      )}
    </div>
  </>
);

interface CreateCycleDashboardProps {
  cycle: CycleShape,
  cycleId: string,
  setViewCyclePerspective: (value: ViewCyclePerspective) => void,
  viewCyclePerspective: ViewCyclePerspective,
}

const CreateCycleDashboard = ({
  cycle,
  cycleId,
  setViewCyclePerspective,
  viewCyclePerspective,
}: CreateCycleDashboardProps): JSX.Element => {
  const isMatrixOrg = (cycle.participationEnum & ParticipantTypeEnum.SecondaryReviewer) === ParticipantTypeEnum.SecondaryReviewer;

  const hookProps = {
    cycle: cycle ?? DEFAULT_CYCLE,
    cycleId,
    isMatrixOrg,
    setViewCyclePerspective,
    viewCyclePerspective,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, CreateCycleDashboard };
export default CreateCycleDashboard;
