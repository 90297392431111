import {
  Dispatch, SetStateAction, SyntheticEvent, useCallback, useMemo,
} from 'react';
import { css } from '@emotion/react';
import {
  faEdit, faEye, faEyeSlash, faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import { palette } from '~Common/styles/colors';
import { getUserId } from '~Common/utils/localStorage';
import { getLocalId } from '~Common/utils/uuid';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import { AgendaCommentRealTimeMessageType } from '~Meetings/const/realTimeMeetingMessages';
import useMeetingRealtime from '~Meetings/hooks/useMeetingRealtime';

import DeleteConfirmationPopover, { useDeleteConfirmationPopover } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import { useAgendaPermissions } from '~Meetings/hooks/utils/useAgendaPermissions';
import { useUpdateAgendaComment } from '~Meetings/hooks/v2/useUpdateAgendaComment';
import { useDeleteAgendaComment } from '~Meetings/hooks/v2/useDeleteAgendaComment';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';

const styles = {
  overflowMenuIcon: css({
    color: palette.neutrals.gray700,
  }),
};

interface ViewProps {
  anchorEl: HTMLElement | null,
  closeConfirmationPopover: (event: SyntheticEvent<HTMLElement>) => void,
  isConfirmationPopoverOpen: boolean,
  menuItems: MenuItem[],
  noteId: string,
  onNoteDeleteClick: () => void,
  popoverId?: string,
}

const View = ({
  anchorEl,
  closeConfirmationPopover,
  isConfirmationPopoverOpen,
  menuItems,
  noteId,
  onNoteDeleteClick,
  popoverId,
}: ViewProps): JSX.Element => (
  <>
    <OverflowMenu
      menuItems={[menuItems]}
      renderOverflowMenuButton={(doOpen) => (
        <LeadrButton
          onClick={doOpen}
          variant="text"
          data-test-id={`agendaNote-${noteId}-action-menu`}
        >
          <FontAwesomeIcon
            css={styles.overflowMenuIcon}
            icon={faEllipsisVertical}
          />
        </LeadrButton>
      )}
    />
    <DeleteConfirmationPopover
      closeConfirmationPopover={closeConfirmationPopover}
      open={isConfirmationPopoverOpen}
      popoverId={popoverId}
      anchorEl={anchorEl}
      renderConfirmationButtons={({
        informationStyles,
        optionStyles,
        popoverButtonStyles,
      }) => (
        <DeleteConfirmationButtons
          informationStyles={informationStyles}
          optionStyles={optionStyles}
          popoverButtonStyles={popoverButtonStyles}
          onDelete={onNoteDeleteClick}
        />
      )}
    />
  </>
);

interface AgendaNoteActionMenuProps {
  agendaItemId: string,
  huddleId: string,
  isPrivate: boolean,
  noteId: string,
  noteText: string,
  setAgendaTopicCommentUpdateEventId: Dispatch<SetStateAction<string>>,
  setShowCommentEditor: Dispatch<SetStateAction<boolean>>,
  sectionId?: string,
}

export const AgendaNoteActionMenu = ({
  agendaItemId,
  huddleId,
  isPrivate,
  noteId,
  noteText,
  setAgendaTopicCommentUpdateEventId,
  setShowCommentEditor,
  sectionId,
}: AgendaNoteActionMenuProps): JSX.Element => {
  const userId = getUserId();
  const { getCommentPermissions } = useAgendaPermissions(huddleId);
  const { sendMessage } = useMeetingRealtime({ huddleId });
  const { user } = useUserProfile(userId!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const {
    canChangePrivacy = false,
    canDelete = false,
    canEdit = false,
    hasMenuOptions,
  } = getCommentPermissions({
    topicId: agendaItemId,
    commentId: noteId,
    sectionId,
  });

  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen: isConfirmationPopoverOpen,
    popoverId,
  } = useDeleteConfirmationPopover('agendaNoteActionMenuDeleteNoteConfirmationPopover');

  const { mutate: doDeleteNote } = useDeleteAgendaComment({
    sectionId,
  });

  const { mutate: doEditComment } = useUpdateAgendaComment({
    sectionId,
    topicId: agendaItemId,
    commentId: noteId,
  });

  const doMarkingTopicPrivate = useCallback(
    (draftIsPrivate: boolean) => {
      doEditComment({
        commentId: noteId,
        context: {
          type: 'AGENDA_ITEM',
          id: agendaItemId,
          meetingId: huddleId,
        },
        item: {
          text: noteText,
          rank: 1.2,
          isPrivate: draftIsPrivate,
        },

        // EventId needed for backend to send realtime events.
        eventId: getLocalId(),
      });
    },
    [agendaItemId, doEditComment, huddleId, noteId, noteText],
  );

  const onNoteDeleteClick = (): void => {
    doDeleteNote({
      commentId: noteId,
      context: {
        type: 'AGENDA_ITEM',
        id: agendaItemId,
        meetingId: huddleId,
      },
    });
  };

  const onEditNoteClick = useCallback((): void => {
    const parentIds = [];
    const newEventId = getLocalId();

    setAgendaTopicCommentUpdateEventId(newEventId);

    if (sectionId) {
      parentIds.push(sectionId);
    }

    parentIds.push(agendaItemId);

    sendMessage(AgendaCommentRealTimeMessageType.Update, {
      id: noteId,
      eventId: newEventId,
      updater: {
        // We should always have the current user's profile
        orgUserId: user!.organizationUserId, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        firstName: user!.firstName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        lastName: user!.lastName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        profileImageUrl: user!.profileImageUrl, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      },
      parentIds,
    });

    setShowCommentEditor(true);
  }, [agendaItemId, noteId, sectionId, sendMessage, setAgendaTopicCommentUpdateEventId, setShowCommentEditor, user]);

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [];

    if (canChangePrivacy) {
      items.push({
        text: isPrivate ? 'Make Public' : 'Make Private',
        icon: isPrivate ? faEye : faEyeSlash,
        onClick: () => doMarkingTopicPrivate(!isPrivate),
      });
    }

    if (canEdit) {
      items.push({
        text: 'Edit Comment',
        icon: faEdit,
        onClick: onEditNoteClick,
      });
    }

    if (canDelete) {
      items.push({
        text: 'Delete Comment',
        icon: faTrashCan,
        onClick: openConfirmationPopover,
        styles: {
          iconColor: palette.brand.red,
          menuText: css({
            color: palette.brand.red,
          }),
        },
      });
    }

    return items;
  }, [canChangePrivacy, canDelete, canEdit, doMarkingTopicPrivate, isPrivate, onEditNoteClick, openConfirmationPopover]);

  if (!hasMenuOptions) {
    return <></>;
  }

  const hookProps = {
    anchorEl,
    closeConfirmationPopover,
    isConfirmationPopoverOpen,
    menuItems,
    noteId,
    onNoteDeleteClick,
    popoverId,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
