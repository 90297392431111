import { useMemo } from 'react';
import DataGridTable from '~Common/components/Tables/DataGridTable';
import { noop } from 'lodash';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import {
  AdminImporterTabValues, errorRowShape, ResponseDataShape, tableColumnHeadersShape,
} from '../Const/types';
import { convertToGroupedTableData } from '../Const/functions';
import { DEFAULT_ROWS_PER_PAGE, DEFAULT_ROW_HEIGHT } from '../Const/defaults';
import { TABLE_STYLES } from '../Const/pageStyles';

const styles = {
  ...TABLE_STYLES,
};

interface ViewProps {
  userRows: errorRowShape[],
  tableColumnHeaders: tableColumnHeadersShape[],
  rowsPerPage: number,
}

const View = ({
  tableColumnHeaders,
  userRows,
  rowsPerPage,
}: ViewProps): JSX.Element => (
  <>
    <DataGridTable
      css={[styles.table(DEFAULT_ROW_HEIGHT), styles.hideIdColumn]}
      rows={userRows}
      columns={tableColumnHeaders}
      pageSize={rowsPerPage}
      handleCellClickEvent={noop}
      selectionModel={[]}
      setSelectionModel={noop}
      isLoading={false}
      rowHeight={DEFAULT_ROW_HEIGHT}
    />
  </>
);

interface ErrorDataTableProps {
  filteredImportData: ResponseDataShape[],
}

const ErrorDataTable = ({
  filteredImportData,
}: ErrorDataTableProps): JSX.Element => {
  const groupedData = convertToGroupedTableData(filteredImportData, AdminImporterTabValues.Errors);
  const [rowsPerPage] = useQueryParamState<number>('admin', 'rowsPerPage', DEFAULT_ROWS_PER_PAGE);

  const tableColumnHeaders = [
    {
      field: 'CSVRows',
      headerName: 'CSV Row',
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'ColumnName',
      headerName: 'Column Name',
      maxWidth: 350,
      flex: 1,
    },
    {
      field: 'ErrorMessage',
      headerName: 'Error',
      maxWidth: 750,
      flex: 1,
    },
  ];
  const userRows = useMemo(() => groupedData.map((item, index) => ({
    id: index,
    CSVRows: item.CSVRows.join(', '),
    ColumnName: item.ColumnName,
    ErrorMessage: item.ErrorMessage,
  })), [groupedData]);

  const hookProps = {
    tableColumnHeaders,
    userRows,
    rowsPerPage,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default ErrorDataTable;
