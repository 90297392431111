/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';

const style = (disabled) => css({
  display: 'flex',
  minWidth: 'auto',
  height: 'auto',
  padding: '0.5rem 1rem',
  fontSize: '0.75rem',
  lineHeight: '1rem',
  alignItems: 'center',
  textAlign: 'center',
  letterSpacing: '0.0313rem',
  border: !disabled ? `1px solid ${palette.brand.indigo}` : `1px solid ${palette.neutrals.gray500}`,
  backgroundColor: palette.neutrals.white,
  color: !disabled ? palette.brand.indigo : palette.neutrals.gray500,
  borderRadius: '0.25rem',
  justifyContent: 'center',
  cursor: 'pointer',

  '&:focus': {
    outline: 'none',
  },

  '&:hover': {
    background: !disabled ? '' : `${palette.neutrals.gray300} !important`,
  },

  '& svg ': {
    marginRight: '0.3215rem',
    position: 'relative',
    top: '-1px',
  },
});
const icons = css`
  font-size: 12px;
`;

const AddItemButton = ({
  text, type, onClick, iconName, icon, disabled, ...props
}) => (
  <button
    css={[style(disabled)]}
    type={type}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {iconName && (
    <FontAwesomeIcon
      icon={iconName}
      css={icons}
    />
    )}
    {text}
  </button>
);

AddItemButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  disabled: PropTypes.bool,
};

AddItemButton.defaultProps = {
  type: 'button',
  onClick: () => { },
  iconName: '',
  icon: '',
  disabled: false,
};

export default AddItemButton;
