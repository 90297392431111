import { Hit } from 'instantsearch.js';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { useMeetingUrl } from '~Common/hooks/useMeetingUrl';
import { EntityType } from '~GlobalSearch/const/types';
import {
  BaseMeetingInstanceBody,
  MeetingAgendaTopicInstance,
  MeetingCommentInstance,
  MeetingInstance,
} from '~Meetings/const/meetingsSearchInterfaces';
import { useGetFormattedMeetingDateString } from '~Meetings/hooks/MeetingSearch/useGetFormattedMeetingDateString';
import MeetingTitleItem from './MeetingTitleItem';
import MeetingTopicItem from './MeetingTopicItem';
import MeetingCommentItem from './MeetingCommentItem';

function extractObjectId(hit: Hit<BaseMeetingInstanceBody>): string | undefined {
  if (hit.entityType === EntityType.MEETING_INSTANCE) {
    return undefined;
  }

  if (hit.entityType === EntityType.AGENDA_TOPIC) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, no-underscore-dangle
    return (hit as MeetingAgendaTopicInstance).agendaTopic?.topicId;
  }

  if (hit.entityType === EntityType.TOPIC_COMMENT) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, no-underscore-dangle
    return (hit as MeetingCommentInstance).comment?.commentId;
  }

  return undefined;
}

export interface RenderHitComponentsParams {
  hit: Hit<BaseMeetingInstanceBody>,
  creator: PersonDisplayInformation,
  usersInfo: PersonDisplayInformation[],
  meetingTitle: string,
  meetingStartTime: string,
  meetingUrl: string,
}

interface ViewProps {
  hit: Hit<BaseMeetingInstanceBody>,
  creator: PersonDisplayInformation,
  usersInfo: PersonDisplayInformation[],
  meetingTitle: string,
  meetingStartTime: string,
  meetingUrl: string,
}

const View = ({
  hit,
  creator,
  usersInfo,
  meetingTitle,
  meetingStartTime,
  meetingUrl,
}: ViewProps): JSX.Element => (
  <>
    {hit.entityType === EntityType.MEETING_INSTANCE && (
      <MeetingTitleItem
        hit={hit as MeetingInstance}
        meetingOrganizer={creator}
        usersInfo={usersInfo}
        meetingStartTime={meetingStartTime}
        link={meetingUrl}
      />
    )}
    {hit.entityType === EntityType.AGENDA_TOPIC && (
      <MeetingTopicItem
        hit={hit as MeetingAgendaTopicInstance}
        usersInfo={[creator]}
        meetingTitle={meetingTitle}
        meetingStartTime={meetingStartTime}
        link={meetingUrl}
      />
    )}
    {hit.entityType === EntityType.TOPIC_COMMENT && (
      <MeetingCommentItem
        hit={hit as MeetingCommentInstance}
        usersInfo={[creator]}
        meetingTitle={meetingTitle}
        meetingStartTime={meetingStartTime}
        link={meetingUrl}
      />
    )}
  </>
);

const defaultUser = { firstName: 'Leadr', lastName: 'User' };

interface BaseMeetingHitComponentProps {
  hit: Hit<BaseMeetingInstanceBody>,
  userMap: Map<string, PersonDisplayInformation>,
}

const BaseMeetingHitComponent = ({
  hit,
  userMap,
  ...props
}: BaseMeetingHitComponentProps): JSX.Element => {
  const { constructMeetingUrl } = useMeetingUrl();

  const searchedItem = extractObjectId(hit);

  const {
    navigatePath: meetingUrl,
  } = constructMeetingUrl({
    meetingType: hit.meeting.type,
    meetingInstanceId: hit.meeting.instanceId,
    searchedItem,
  });

  const participantIds = hit.participants?.map((participant) => participant.orgUserId) ?? [];

  const usersInfo = participantIds.map((participantOrgUserId) => userMap.get(participantOrgUserId) ?? defaultUser);
  const creator = userMap.get(hit.createdBy.orgUserId) ?? defaultUser;

  const meetingTitle = hit.meeting?.title ?? '';
  const getFormattedDayString = useGetFormattedMeetingDateString();
  const meetingStartTime = getFormattedDayString(hit.meeting.startTimeInMillis);

  const hookProps = {
    hit,
    creator,
    usersInfo,
    meetingTitle,
    meetingStartTime,
    meetingUrl,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default BaseMeetingHitComponent;
