import { css } from '@emotion/react';
import { Card, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { skeletonGlobalAnimation } from '~Common/const/skeletons';
import { noop } from '~Deprecated/utils';

const styles = {
  card: css`
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px -2px rgba(28, 42, 55, 0.2);

    @media print {
      -webkit-filter: drop-shadow(0px 4px 8px rgba(28, 42, 55, 0.2));
    }
  `,
};

/**
 * @deprecated Old Card UI, use packages/leadr-frontend/src/common/V3/components/Card.tsx instead
 */
const View = ({ renderBody, ...props }) => (
  <Card css={styles.card} {...props}>
    {renderBody()}
  </Card>
);

View.propTypes = {
  renderBody: PropTypes.func,
};

View.defaultProps = {
  renderBody: noop,
};

const SkeletonView = ({ ...props }) => (
  <Skeleton css={styles.card} animation={skeletonGlobalAnimation} variant="rectangular" {...props} />
);

const ViewCard = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ViewCard.propTypes = {};

ViewCard.defaultProps = {};

export { View, SkeletonView as ViewCardSkeleton };
export default ViewCard;
