import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';
import { withSpacedOutTitleStyles } from '~Common/styles/mixins';

const styles = {
  reviewTitle: css({
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  }),
  icon: css({
    color: palette.brand.indigo,
  }),
  text: css({
    whiteSpace: 'nowrap',
  }, withSpacedOutTitleStyles()),
};

interface ReviewTitleProps extends ComponentProps<'div'> {
  reviewTitle: string,
}

const ReviewTitle = ({
  reviewTitle,
  ...props
}: ReviewTitleProps): JSX.Element => (
  <div css={styles.reviewTitle} {...props}>
    <div css={styles.text}>
      {reviewTitle}
    </div>

  </div>
);

export default ReviewTitle;
