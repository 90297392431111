import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import Froala, { FROALA_SINGLE_LINE_HEIGHT } from '~Common/components/Fields/RichTextEditors/Froala';
import { SURVEY_ANSWERS_SHAPE, SURVEY_QUESTION_TYPES_SHAPE } from '~Common/const/proptypes';
import { NO_RESPONSE_TEXT } from '~Common/const/surveys';

import ViewSurveyQuestionCard from '../../Cards/ViewSurveyQuestionCard';
import SurveyMultipleChoice from '../Respond/SurveyMultipleChoice';
import SurveyRating from '../Respond/SurveyRating';

const styles = {
  container: css``,
};

const View = ({
  questionTypes, participantQuestionAndAnswer, disabled, ...props
}) => (
  <div
    css={styles.container}
    {...props}
  >
    <ViewSurveyQuestionCard
      css={styles.viewSurveyCard}
      question={participantQuestionAndAnswer.question}
      renderResponseContent={() => (
        <>
          {participantQuestionAndAnswer.question.type.key === questionTypes.CustomScale.id && (
            <SurveyRating
              question={participantQuestionAndAnswer.question}
              disabled={disabled}
              initialValue={participantQuestionAndAnswer.answer?.answer}
            />
          )}
          {participantQuestionAndAnswer.question.type.key === questionTypes.MultipleChoice.id && (
            <SurveyMultipleChoice
              question={participantQuestionAndAnswer.question}
              isReadOnly
              initialValue={participantQuestionAndAnswer.answer?.answer}
            />
          )}
          {participantQuestionAndAnswer.question.type.key === questionTypes.ShortAnswer.id && (
            <Froala heightMin={FROALA_SINGLE_LINE_HEIGHT} isReadOnly value={participantQuestionAndAnswer.answer?.answer || NO_RESPONSE_TEXT} />
          )}
        </>
      )}
    />
  </div>
);

View.propTypes = {
  questionTypes: SURVEY_QUESTION_TYPES_SHAPE.isRequired,
  participantQuestionAndAnswer: SURVEY_ANSWERS_SHAPE.isRequired,
  disabled: PropTypes.bool,
};

View.defaultProps = {
  disabled: false,
};

const ByPersonResult = ({ ...props }) => {
  const disabled = true;

  const hookProps = {
    disabled,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ByPersonResult.propTypes = {};

ByPersonResult.defaultProps = {};

export { View };
export default ByPersonResult;
