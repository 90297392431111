import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import EmptyReflectionsTable from '~Reviews/assets/images/emptyReflectionsTable.svg';
import { css } from '@emotion/react';

const styles = {
  // Please note that this sets the height but the overall height is set in the parent component <DataGridTable>
  emptyStateImage: css({
    height: '18.75rem',
  }),
};

const StyledGridOverlay = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));
export function CustomNoRowsOverlay(): JSX.Element {
  return (
    <StyledGridOverlay>
      <EmptyReflectionsTable css={styles.emptyStateImage} title="Empty reflections" />
      <Box sx={{ marginTop: '1rem' }}>No Reflections Found</Box>
    </StyledGridOverlay>
  );
}
