/* eslint-disable no-bitwise */
import moment from 'moment-timezone';

import {
  TopicShape, ParticipantTypeEnum, TopicTypeEnum,
} from './types';

export const REVIEWS_MAX_SHOWN_AVATARS_COUNT = 3;
export const defaultDate = moment().toDate();
export const DEFAULT_CYCLE_TYPE = 1;
export const DEFAULT_CYCLE = {
  name: '',
  description: '',
  assessmentPeriodStart: defaultDate,
  assessmentPeriodEnd: moment().add(1, 'day').toDate(),
  assessmentDueDate: defaultDate,
  signOffDueDate: defaultDate,
  status: 0,
  typeEnum: DEFAULT_CYCLE_TYPE,
  includeMentor: false,
  participationEnum: ParticipantTypeEnum.Reviewer | ParticipantTypeEnum.Reviewee,
  allowedAdminOrgUserIds: [],
};

export const DEFAULT_SCALE = {
  minLabel: 'Worst',
  maxLabel: 'Best',
  numScale: 5,
};
const DEFAULT_REFLECTION = {
  uid: '',
  createdAt: defaultDate,
  lastModifiedAt: defaultDate,
  reflectionTypeEnum: 1,
  participantUid: '',
  topicUid: '',
  subjectUid: '',
  title: '',
};

const DEFAULT_ANSWER = {
  uid: '',
  participantUid: '',
  topicUid: '',
  stringResponse: '',
  numericResponse: 0,
  createdAt: defaultDate,
  lastModifiedAt: defaultDate,
};

export const DEFAULT_MULTIPLE_CHOICE = { multipleChoiceOptions: [] };
export const DEFAULT_PARTICIPANT_ENUM = 3;
export const DEFAULT_TOPIC: TopicShape = {
  uid: '',
  rank: 0,
  text: '',
  topicTargetEnum: ParticipantTypeEnum.Reviewer | ParticipantTypeEnum.Reviewee, // NEW
  typeConfig: DEFAULT_SCALE,
  typeEnum: 1,
  isDeleted: false,
  reviewCycleUid: '',
  description: '',
  answers: [DEFAULT_ANSWER],
  reflections: [DEFAULT_REFLECTION],
};

export const CYCLE_TYPE = {
  Unknown: 0,
  Open: 1,
  Closed: 2,
};

export const QUESTION_OPTIONS_LABELS = {
  FreeForm: 'Free Form',
  Custom: 'Custom Rating/Scale',
  Multiple: 'Multiple Choice',
};

export const QUESTION_OPTIONS_SHAPE = [
  {
    label: QUESTION_OPTIONS_LABELS.FreeForm,
    value: TopicTypeEnum.Freeform,
    tooltip: 'Respondents fill in a text box with their own answers.',
  },
  {
    label: QUESTION_OPTIONS_LABELS.Custom,
    value: TopicTypeEnum.Linear,
    tooltip: 'Respondents select numeric values from a linear scale.',
  },
  {
    label: QUESTION_OPTIONS_LABELS.Multiple,
    value: TopicTypeEnum.MultipleChoice,
    tooltip: 'Respondents select a single option from a predefined list.',
  },
];

export const participantsFilterOptions = [
  {
    text: 'All Participants',
    value: '0',
  },
  {
    text: 'Manager',
    value: '1',
  },
];

export const FILTER_SEPARATOR = '^';

/**
 * More often than not, review cycles don't need to referesh every 5 minutes to stay up to date.
 * We invalidate cache anytime we update an object anyway so might as well let that handle it for the most part.
 * This will reduce the amount of times the app re-renders while users are inputting information.
 */
export const EXTENDED_QUERY_STALE_TIME = 60 * 1000 * 60; // 60 minutes
