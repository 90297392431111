import { css } from '@emotion/react';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { palette } from '~Common/styles/colors';
import { HTMLString } from '~Common/types';
import { isHTMLText } from '~Common/utils/isHTMLText';

const styles = {
  question: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '.75rem',
  }),
  answer: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    'ol, ul': {
      paddingInlineStart: '1.125rem',
    },
  }),
};

interface HomeTeamClarityQuestionDisplayProps {
  question: string,
  answerHTML: HTMLString,
}

const HomeTeamClarityQuestionDisplay = ({
  question,
  answerHTML,
  ...props
}: HomeTeamClarityQuestionDisplayProps): JSX.Element => (
  <div
    {...props}
  >
    <div
      css={styles.question}
      data-test-id="teamClarityQuestion"
    >
      {question}
    </div>
    {isHTMLText(answerHTML) && (
      <HTMLRenderer css={styles.answer} htmlText={answerHTML} data-test-id="teamClarityAnswer" />
    )}
    {!isHTMLText(answerHTML) && (
      <div css={styles.answer} data-test-id="teamClarityAnswer">
        {answerHTML}
      </div>
    )}
  </div>
);

export default HomeTeamClarityQuestionDisplay;
