import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { withLineClamp, withTruncate } from '~Common/styles/mixins';
import { HTMLString } from '~Common/types';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import { LearningStatus } from '~Learning/const/interfaces';

const styles = {
  contentListCard: css({
    display: 'grid',
    paddingBottom: '1.25rem',
    gridTemplateColumns: '30% 70%',
    columnGap: '1.125rem',
    alignItems: 'center',
  }),
  contentTitle: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    fontWeight: 600,
  }, withTruncate()),
  introduction: css({
    marginTop: '.625rem',
    color: palette.neutrals.gray500,
    fontWeight: 600,
  }, withLineClamp(2)),
  dueDateFromNow: css({
    marginTop: '.9375rem',
  }),
};

interface ContentListCardProps {
  contentTitle: string,
  contentUrl: string,
  dueDate?: Date | string,
  status: LearningStatus,
  introductionHTML?: HTMLString,
}

const ContentListCard = ({
  contentTitle,
  contentUrl = '',
  dueDate,
  status,
  introductionHTML = '',
}: ContentListCardProps): JSX.Element => (
  <div css={styles.contentListCard}>
    <PreviewWithExternalNavigation
      contentUrl={contentUrl}
    />
    <div>
      <div css={styles.contentTitle}>{contentTitle}</div>
      {introductionHTML && (
        <HTMLRenderer css={styles.introduction} htmlText={introductionHTML} />
      )}
      <DueDateFromNow
        css={styles.dueDateFromNow}
        dueDate={dueDate}
        isComplete={status === LearningStatus.COMPLETED}
      />
    </div>
  </div>
);

export default ContentListCard;
