import { Tab } from '@mui/material';
import styled from '@mui/system/styled';

import { palette, tabColors } from '~Common/styles/colors';

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx
 * with packages/leadr-frontend/src/common/V3/components/Drawers/TabDrawerHeader.tsx instead
 */
const TabItem = styled(Tab)(() => ({
  color: tabColors.tab.color,
  backgroundColor: palette.neutrals.white,
  borderRadius: '8px',
  fontWeight: 'bold',
  textAlign: 'center',
  fontStyle: 'normal',
  fontSize: '12px',
  zIndex: 1,
  minWidth: 'unset',

  '&:hover': {
    backgroundColor: tabColors.tab.hover.background,
    color: palette.neutrals.white,
  },
  '&.Mui-selected': {
    backgroundColor: tabColors.tab.selected.background,
    color: palette.neutrals.white,
    '&:hover': {
      backgroundColor: tabColors.tab.selected.background,
      color: palette.neutrals.white,
    },
  },
  '&.Mui-disabled': {
    backgroundColor: tabColors.tab.disabled.background,
    color: palette.neutrals.white,
  },
}));

TabItem.propTypes = {};

TabItem.defaultProps = {};

export default TabItem;
