import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import LabeledSwitch from '~Common/V3/components/LabeledSwitch';
import { useActivePulseSurvey, useTriggerPulseSurvey } from '~Insights/hooks/usePulseSurvey';

const styles = {
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
  closeButton: css`
    border: none;
    box-shadow: none;
    background-color: transparent;
  `,
  switch: css({
    margin: '1.25rem 0',
  }),
};

const insightsDevToolsTemplate = {
  name: 'INSIGHTS_DEV_TOOLS',
  width: DRAWER_WIDTHS.BASE,
};

interface ViewProps {
  closeDrawerClick: () => void,
  currentUserHasPulseSurvey: boolean,
  isLoading: boolean,
  mutationIsRunning: boolean,
  onTriggerPulseSurvey: () => void,
}

const View = ({
  closeDrawerClick,
  currentUserHasPulseSurvey,
  isLoading,
  mutationIsRunning,
  onTriggerPulseSurvey,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={() => (
          <button onClick={closeDrawerClick} css={styles.closeButton} title="Close drawer">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        title="Insights Dev Tools"
      />
    )}
    renderBody={() => (
      <div
        css={styles.drawerBody}
      >
        <>
          <p>
            This option will trigger a pulse survey for everyone in the current organization. They will receive
            email notifications. Please be judicious in its use.
          </p>
          <LabeledSwitch
            id="has_pulse_survey"
            checked={currentUserHasPulseSurvey}
            onChange={() => onTriggerPulseSurvey()}
            activeLabel="Pulse Survey Active"
            inactiveLabel="No Pulse Survey Active"
            isLoading={isLoading || mutationIsRunning}
            disabled={currentUserHasPulseSurvey}
            css={styles.switch}
          />
        </>
      </div>
    )}
  />
);

const InsightsDevTools = ({ ...props }): JSX.Element => {
  const dispatch = useDispatch();
  // const pulseSurveysEnabled = useFeatureFlag('frontendPulseSurvey');

  const { isLoading, survey } = useActivePulseSurvey();
  const { mutate: triggerMutation, isPending: mutationIsRunning } = useTriggerPulseSurvey();

  const onTriggerPulseSurvey = (): void => {
    triggerMutation();
  };

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
  };

  const hookProps = {
    closeDrawerClick,
    // pulseSurveysEnabled,
    currentUserHasPulseSurvey: !!survey,
    isLoading,
    mutationIsRunning,
    onTriggerPulseSurvey,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: insightsDevToolsTemplate.name,
  component: InsightsDevTools,
});

export { View, insightsDevToolsTemplate };
export default InsightsDevTools;
