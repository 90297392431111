import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { Hit, BaseHit } from 'instantsearch.js';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Link } from 'react-router-dom';
import { ComponentProps } from 'react';
import { Location } from 'history';
import { MODAL_RESULTS_PER_SECTION } from '~GlobalSearch/const/GlobalSearch';
import SearchHitsHelper from '~GlobalSearch/components/helpers/SearchHitsHelper';
import CustomHits from './CustomHits';

const styles = {
  header: css({
    fontWeight: 600,
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    marginBottom: '.25rem',
    padding: '0.25rem 1.5rem',
  }),
  showMoreButton: css({
    marginTop: '.5rem',
    display: 'inline-block',
    fontSize: '.75rem',
    padding: '0.25rem 1.5rem',
  }),
};

interface RenderHitComponentParams {
  hit: Hit<BaseHit>,
}

interface SectionProps extends ComponentProps<'div'> {
  headerText: string,
  renderHitComponent: ({ hit }: RenderHitComponentParams) => JSX.Element,
  showMoreButtonDataTestId: string,
  toObject: Partial<Location>,
}

const Section = ({
  headerText,
  renderHitComponent,
  showMoreButtonDataTestId,
  toObject,
  ...props
}: SectionProps): JSX.Element => (
  <SearchHitsHelper
    renderComponent={({ result, query, numberOfHits }) => (
      <>
        { /*
            I don't like this pattern of conditional rendering in the child component,
            but since we have to be one level deeper than the Index to get the hits from Algolia, this is how it has to be
          */
        }
        {result.hits.length > 0 && (
        <div {...props}>
          <h2 css={styles.header}>{headerText}</h2>
          <CustomHits
            renderHitComponent={renderHitComponent}
          />
          <LeadrButton
            to={{
              ...toObject,
              state: { search: query },
            }}
            data-test-id={showMoreButtonDataTestId}
            component={Link}
            variant="text"
            textButtonColor={palette.brand.indigo}
            css={styles.showMoreButton}
          >
            {`Show More ${numberOfHits - MODAL_RESULTS_PER_SECTION > 0 ? `(${numberOfHits - MODAL_RESULTS_PER_SECTION})` : ''}`}
          </LeadrButton>
        </div>
        )}
      </>
    )}
  />
);

export default Section;
