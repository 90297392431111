import { css } from '@emotion/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMegaphone, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ComponentProps,
} from 'react';
import { DueDateStatusOrNull, DUE_DATE_STATUS, useDueDateFromNow } from '~Common/hooks/dates/useDueDateFromNow';
import { palette } from '~Common/styles/colors';

const styles = {
  dueDate: (dueDateStatus: DueDateStatusOrNull) => css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray600,
  }, dueDateStatus === DUE_DATE_STATUS.PAST && {
    fontWeight: 600,
    color: palette.brand.red,
  }, dueDateStatus === DUE_DATE_STATUS.FUTURE && {
    color: palette.neutrals.gray600,
  }, dueDateStatus === DUE_DATE_STATUS.FUTURE_WITHIN_ONE_WEEK && {
    fontWeight: 600,
    color: palette.brand.indigo,
  }, dueDateStatus === DUE_DATE_STATUS.COMPLETED && {
    fontWeight: 600,
    color: palette.brand.green,
  }),
  icon: css({
    marginRight: '0.5rem',
  }),
  dueDateText: css({
    fontSize: '.75rem',
  }),
};

const DUE_DATE_STATUS_ICON = {
  [DUE_DATE_STATUS.PAST]: faTriangleExclamation,
  [DUE_DATE_STATUS.FUTURE_WITHIN_ONE_WEEK]: faMegaphone,
  [DUE_DATE_STATUS.FUTURE]: null,
  [DUE_DATE_STATUS.COMPLETED]: null,
};

interface ViewProps extends ComponentProps<'div'> {
  dueDateText: string,
  dueDateStatus: DueDateStatusOrNull,
  dueDateIcon: IconProp | null,
}

const View = ({
  dueDateText, dueDateStatus, dueDateIcon, ...props
}: ViewProps): JSX.Element => (
  <div css={styles.dueDate(dueDateStatus)} data-test-id="dueDateFromNow" {...props}>
    {dueDateIcon && (
      <FontAwesomeIcon css={styles.icon} icon={dueDateIcon} />
    )}
    <div css={styles.dueDateText}>{dueDateText}</div>
  </div>
);

interface DueDateFromNowProps extends ComponentProps<'div'> {
  dueDate: string | Date | number | undefined,
  completedDate?: string | Date | undefined,
  isComplete?: boolean,
  showIcon?: boolean,
}

const DueDateFromNow = ({
  dueDate,
  completedDate,
  isComplete,
  showIcon,
  ...props
}: DueDateFromNowProps): JSX.Element => {
  const { dueDateText, dueDateStatus } = useDueDateFromNow({ dueDate, isComplete, completedDate });

  const dueDateIcon = dueDateStatus ? DUE_DATE_STATUS_ICON[dueDateStatus] : null;

  const hookProps = {
    dueDateText,
    dueDateStatus,
    dueDateIcon,
    showIcon,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default DueDateFromNow;
