/* eslint-disable react/button-has-type */

import { faTrash } from '@fortawesome/pro-duotone-svg-icons';

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const DeleteButton = (props) => (
  <Button
    buttonColor={BUTTON_COLOR.DESTRUCTIVE}
    {...props}
    icon={faTrash}
  />
);

DeleteButton.propTypes = {};

DeleteButton.defaultProps = {};

export default DeleteButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
