import { css } from '@emotion/react';
import { usePeople } from '~Deprecated/hooks/people/usePeople';
import PeopleCard from '~People/components/PeopleCard';
import { slideFromBottom } from '~Common/styles/animations';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import emptyPeople from '~People/assets/images/emptyPeople.png';
import { PeoplePagePerson } from '~People/const/interfaces';
import InfiniteListView from '~Common/V3/components/InfiniteList';
import PeopleFilterBar from './PeopleFilterBar';

const styles = {
  emptyState: css({
    marginTop: '3.125rem',
    '& h6': {
      width: '80%',
    },
  }),
  emptyStateImage: css({
    height: '11.4375rem',
  }),
};

const renderImage = (): JSX.Element => (
  (<img css={styles.emptyStateImage} src={emptyPeople} alt="Empty People" />)
);

const People = (): JSX.Element => (
  <>
    <PeopleFilterBar />
    <InfiniteListView
      isMasonry
      listQuery={usePeople}
      columns={{ min: 1, tablet: 2, desktopLarge: 3 }}
      spacing={4}
      renderItem={(id: string, index: number, user: PeoplePagePerson) => (
        <PeopleCard
          css={slideFromBottom(index)}
          key={id}
          user={user}
        />
      )}
      renderEmptyState={() => (
        <EmptyStateWithImage css={styles.emptyState} renderImage={renderImage} text="No one in your organization goes by that name" />
      )}
      itemKeyName="id"
      loaderStyle={undefined}
      renderLoadingState={undefined}
      requestType={undefined}
      infiniteQueryProp={undefined}
      isMobile={false}
    />
  </>
);

export default People;
