import { css } from '@emotion/react';
import { PeoplePerspective } from '~Insights/const/types';
import { CARD_STYLES, DASHBOARD_CARD_COMPONENTS, EXPANDED_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import { PeopleInsightsData } from '~Insights/hooks/usePeopleInsightsData';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';

import ShrinkButton from '~Insights/components/buttons/ShrinkButton';
import InsightCardTitle from '~Insights/components/InsightCardTitle';
import Card from '~Common/V3/components/Card';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { forMobileObject } from '~Common/styles/mixins';
import { User } from '~Common/const/interfaces';
import { useGetAdminData } from '~Deprecated/hooks/admin/useGetUsersList';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { DonutSidebars } from './DonutSidebars';
import { SharedProps } from './index';
import ExpandedSubtab from './ExpandedSubtab';

const styles = {
  expanded: css({
    ...CARD_STYLES.expanded,
    padding: '0',
  }),
  ...DASHBOARD_CARD_COMPONENTS,
  ...EXPANDED_CARD_COMPONENTS,
  extraBasis: css({
    flexBasis: '25%',
  }, forMobileObject({
    flexBasis: '100%',
  })),
};

interface ExpandedViewProps extends ExpandedProps {
  allPeople: User[],
}

const ExpandedView = ({
  allPeople,
  toggleMenuOptions,
  changeToggleMenu,
  toggleMenuValue,
  insightsData,
  isLoading,
  userPerspective,
  isMobile,
}: ExpandedViewProps): JSX.Element => (
  <Card
    css={styles.expanded}
    renderContents={() => (
      <div css={styles.expandedContainer}>
        <div css={styles.header(false)}>
          <ShrinkButton css={styles.expandButton(false)} cardKey="people" />
          <div css={styles.titleAndSubtitle(false)}>
            <InsightCardTitle>People Metrics</InsightCardTitle>
            <p>View details related to 1:1&apos;s, goals, feedback, and activity in Leadr.</p>
          </div>
          {isMobile && (
            <Dropdown
              items={toggleMenuOptions as DropdownItem[]}
              onChange={(event) => changeToggleMenu(event.target.value as PeoplePerspective)}
              value={toggleMenuValue}
            />
          )}
          {!isMobile && (
            <LeadrToggleTabs
              value={toggleMenuValue}
              onChange={(e, newValue) => changeToggleMenu(newValue as PeoplePerspective)}
            >
              {toggleMenuOptions.map((toggleMenuOption) => (
                <LeadrToggleTabs.TextTab
                  data-test-id={toggleMenuOption['data-test-id']}
                  key={toggleMenuOption.value}
                  text={toggleMenuOption.text}
                  value={toggleMenuOption.value}
                />
              ))}
            </LeadrToggleTabs>
          )}
        </div>
        <div css={styles.expandedCushion} />
        {!insightsData.orgUserInsights?.length && (
          <div css={styles.emptyState}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <img src={insightsEmptyGraph} alt="Empty graph" />
            <p css={styles.emptyStateText}>
              No employees report directly to you.
            </p>
          </div>
        )}
        {insightsData.orgUserInsights?.length && (
          <>
            <div css={styles.horizontalDivider} />
            <div css={styles.expandedRow(false)}>
              <div css={styles.main}>
                <ExpandedSubtab
                  allPeople={allPeople}
                  peopleInsightData={insightsData.orgUserInsights}
                  selectedTab={toggleMenuValue}
                  userPerspective={userPerspective}
                />
              </div>
              <div css={styles.verticalDivider(false)} />
              <div css={[styles.sidebar, styles.expandedSidebar(false), styles.extraBasis]}>
                {isLoading && (
                  <SkeletonLoader
                    variant="rectangular"
                    renderComponent={() => <div />}
                  />
                )}
                {!isLoading && (
                  <DonutSidebars
                    toggleMenuValue={toggleMenuValue}
                    userPerspective={userPerspective}
                    isExpanded
                    isMobile={isMobile}
                    {...insightsData.collectiveInsights}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    )}
  />
);

interface ExpandedProps extends SharedProps {
  toggleMenuOptions: LeadrToggleTextTabProps[],
  changeToggleMenu: (value: PeoplePerspective) => void,
  toggleMenuValue: PeoplePerspective,
  insightsData: PeopleInsightsData,
  userPerspective: UserPerspective,
}

const Expanded = ({ isLoading, isMobile, ...props }: ExpandedProps): JSX.Element => {
  const { users: allPeople } = useGetAdminData({
    count: 10000,
    administrativeStatus: 'ACTIVE',
  }) as { users: User[]; };

  const hookProps = {
    allPeople: allPeople ?? [],
    isLoading: isLoading || !allPeople,
    isMobile,
  };

  return (
    <ExpandedView
      {...hookProps}
      {...props}
    />
  );
};

export { ExpandedView, Expanded };
