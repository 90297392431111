/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { getAvatarColor, palette } from '~Common/styles/colors';
import { makeInitials } from '~Deprecated/utils';
import Tooltip from '~Common/components/Tooltip';
import ConditionalWrapper from '~Common/components/ConditionalWrapper';
import { Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

type AvatarSize = 22 | 30 | 35 | 40 | 48;

const AVATAR_SIZE_TO_BORDER_RADIUS_MAP = {
  22: 4,
  30: 6,
  35: 6,
  40: 8,
  48: 8,
};

enum AVATAR_TYPE {
  Image = 'Image',
  Initials = 'Initials',
  Deactivated = 'Deactivated',
  NonLeadrUser = 'NonLeadrUser',
}

const styles = {
  baseAvatar: ({ avatarSize }: { avatarSize: AvatarSize }) => css({
    width: `${avatarSize}px`,
    height: `${avatarSize}px`,
    lineHeight: `${avatarSize}px`,
    fontSize: `${avatarSize / 2}px`,
    borderRadius: `${AVATAR_SIZE_TO_BORDER_RADIUS_MAP[avatarSize]}px`,
    objectFit: 'cover',
  }),
  nonLeadrUserAvatar: css({
    backgroundColor: palette.neutrals.gray600,
    color: palette.neutrals.white,
    textTransform: 'uppercase',
  }),
};

interface ViewProps {
  showTooltip?: boolean,
  tooltipText?: string,
  avatarSize: AvatarSize,
  avatarType: AVATAR_TYPE,
  profileImageUrl?: string,
  name?: string,
  email?: string,
}

const View = ({
  showTooltip = true,
  tooltipText,
  avatarSize,
  avatarType,
  profileImageUrl,
  name,
  email,
  ...props
}: ViewProps): JSX.Element => (
  <ConditionalWrapper
    renderWrapperCondition={showTooltip && !!tooltipText}
    renderWrapper={(renderChildren) => (
      <Tooltip content={tooltipText}>
        {renderChildren()}
      </Tooltip>
    )}
    renderContents={() => (
      <Avatar
        imgProps={{
          alt: `${name!} avatar`,
        }}
        css={[
          styles.baseAvatar({ avatarSize }),
          ...(avatarType === AVATAR_TYPE.Initials ? [getAvatarColor(makeInitials({ name }))] : []),
          ...(avatarType === AVATAR_TYPE.NonLeadrUser ? [styles.nonLeadrUserAvatar] : []),
        ]}
        src={profileImageUrl}
        {...props}
      >
        {avatarType === AVATAR_TYPE.Deactivated && (
          <FontAwesomeIcon icon={faUser} />
        )}
        {avatarType === AVATAR_TYPE.NonLeadrUser && (
          <>{email?.slice(0, 2)}</>
        )}
        {avatarType !== AVATAR_TYPE.Deactivated && avatarType !== AVATAR_TYPE.NonLeadrUser && (
          <>{makeInitials({ name })}</>
        )}
      </Avatar>
    )}
  />
);

export interface BaseAvatarProps extends Omit<ViewProps, 'avatarType' | 'tooltipText'> {
  isDeactivated?: boolean,
  isNonLeadrUser?: boolean,
}

const NewBaseAvatar = ({
  profileImageUrl,
  name,
  isDeactivated,
  isNonLeadrUser,
  email,
  ...props
}: BaseAvatarProps): JSX.Element => {
  const [isValid, setValid] = useState(true);

  useEffect(() => {
    if (profileImageUrl) {
      const image = new Image();
      image.onerror = () => {
        setValid(false);
      };
      image.src = profileImageUrl;
    }
  }, [profileImageUrl]);

  const { tooltipText, avatarType } = useMemo(() => {
    if (isNonLeadrUser) {
      return {
        tooltipText: `${email!} is not a Leadr user.`,
        avatarType: AVATAR_TYPE.NonLeadrUser,
      };
    } if (isDeactivated) {
      return {
        tooltipText: `${name!} (Deactivated)`,
        avatarType: AVATAR_TYPE.Deactivated,
      };
    } if (profileImageUrl && isValid) {
      return {
        tooltipText: name,
        avatarType: AVATAR_TYPE.Image,
      };
    }
    return {
      tooltipText: name,
      avatarType: AVATAR_TYPE.Initials,
    };
  }, [isNonLeadrUser, isDeactivated, profileImageUrl, isValid, name, email]);

  const hookProps = {
    tooltipText,
    avatarType,
    email,
    name,
    profileImageUrl,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default NewBaseAvatar;
