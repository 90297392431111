import { useCallback, useEffect, useState } from 'react';

import {
  desktopMediaQuery,
  mobileMediaQuery,
  mousePointerDeviceQuery,
  tabletMediaQuery,
} from '~Common/styles/variables';

interface UseMediaListenerParams {
  mediaQuery: string,
}

export const useMediaListener = ({ mediaQuery }: UseMediaListenerParams): boolean => {
  const [mediaListener] = useState(window.matchMedia(mediaQuery));
  const [isMatching, setIsMatching] = useState(mediaListener.matches);

  const doIsMatching = useCallback(() => {
    setIsMatching(mediaListener.matches);
  }, [mediaListener]);

  useEffect(() => {
    mediaListener.addEventListener('change', doIsMatching);

    return () => {
      mediaListener.removeEventListener('change', doIsMatching);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMatching;
};

// Feel free to add other variants if needed
export const useIsMobileQuery = (): boolean => useMediaListener({ mediaQuery: mobileMediaQuery });
export const useIsDesktopQuery = (): boolean => useMediaListener({ mediaQuery: desktopMediaQuery });
export const useIsTabletQuery = (): boolean => useMediaListener({ mediaQuery: tabletMediaQuery });
export const useIsMousePointerDevice = (): boolean => useMediaListener({ mediaQuery: mousePointerDeviceQuery });
