import { create } from 'zustand';

interface UseNexusOrgStoreState {
  searchKeyword: string,
  setSearchKeyword: (newSearchKeyword: string) => void,
  shouldFilterByFavorites: boolean,
  setShouldFilterByFavorites: (newShouldFilterByFavorites: boolean) => void,
  sortBy: number,
  setSortBy: (newSortBy: number) => void,
  page: number,
  setPage: (newPage: number) => void,
}

export const useNexusOrgStore = create<UseNexusOrgStoreState>((set) => ({
  searchKeyword: '',
  setSearchKeyword: (newSearchKeyword: string) => set(() => ({ searchKeyword: newSearchKeyword })),
  shouldFilterByFavorites: false,
  setShouldFilterByFavorites: (newShouldFilterByFavorites: boolean) => set(() => ({ shouldFilterByFavorites: newShouldFilterByFavorites })),
  sortBy: 0,
  setSortBy: (newSortBy: number) => set(() => ({ sortBy: newSortBy })),
  page: 1,
  setPage: (newPage: number) => set(() => ({ page: newPage })),
}));
