/* eslint-disable no-bitwise */
import React, { ComponentProps } from 'react';
import { REVIEW_SETUP_LAYOUT, REVIEW_DISPLAY_STYLES, BUTTON_STYLES } from '~Reviews/V2/Const/pageStyles';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useDeleteTopic } from '~Reviews/V2/Hooks/useDeleteTopic';
import Questions from '~Reviews/V2/Shared/Questions';
import {
  DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle,
} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { palette } from '~Common/styles/colors';
import {
  TopicShape, ModifyQuestionType, CycleShape, ParticipantTypeEnum,
} from '../Const/types'; import { FormValues } from '../schemata/CreateTopicForCycle';
import AddQuestion from './AddQuestion';
import { FormValuesMatrixOrg } from '../schemata/CreateTopicForCycleMatrixOrgs';
import { useReorderTopics } from '../Hooks/useReorderTopics';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...REVIEW_DISPLAY_STYLES,
  ...BUTTON_STYLES,
};
interface ViewProps {
  formContext: UseFormReturn<FormValues | FormValuesMatrixOrg>,
  topics: TopicShape[] | undefined,
  isQuestions: boolean,
  onModifyQuestion: (uid: string, action: ModifyQuestionType) => void,
  editQuestion: string,
  cycle?: CycleShape,
  onFormSubmit: SubmitHandler<FormValues>,
  updateQuestionView: (value: boolean) => void,
  onDragEnd: (result: DropResult) => void,
  getItemStyle: (isDragging: boolean, style: DraggingStyle | NotDraggingStyle) => React.CSSProperties,
}

const View = ({
  formContext,
  topics,
  isQuestions,
  onModifyQuestion,
  editQuestion,
  cycle,
  onFormSubmit,
  updateQuestionView,
  onDragEnd,
  getItemStyle,
}: ViewProps): JSX.Element => (
  <>
    {topics && (
    <p css={styles.sectionTitle}>
      Questions
    </p>
    )}
    {isQuestions && (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        { (droppableProvided) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {topics?.map((topic: TopicShape, index: number) => {
              if (editQuestion === topic.uid) {
                return (
                  <AddQuestion
                    participationEnum={cycle?.participationEnum ?? ParticipantTypeEnum.Everyone}
                    onFormSubmit={onFormSubmit}
                    updateQuestionView={updateQuestionView}
                    topic={topic}
                    editQuestion={editQuestion}
                    inDrawer={!cycle}
                  />
                );
              }
              if (editQuestion !== topic.uid) {
                return (
                  <>
                    <Draggable
                      draggableId={`draggable-${topic.uid}`}
                      index={index}
                      key={`${topic.uid}`}
                    >
                      {(provided, draggableSnapshot) => (
                        <div
                          css={styles.draggableWrapper}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(
                            draggableSnapshot.isDragging,
                            // @ts-expect-error Issue with syncing the properties between the view and main component
                            provided.draggableProps.style,
                          )}
                        >
                          <LeadrButton
                            variant="text"
                            css={styles.dragHandle}
                            textButtonColor={palette.neutrals.gray700}
                            {...provided.dragHandleProps}
                            data-test-id="reviewsQuestionPreviewDragHandle"
                          >
                            <FontAwesomeIcon size="lg" icon={faGripDotsVertical} />
                          </LeadrButton>
                          <Questions
                            isQuestions={isQuestions}
                            isLastQuestion={topics.length - 1 === index}
                            topic={topic}
                            onModifyQuestion={onModifyQuestion}
                            formContext={formContext}
                            reviewCycleUid={cycle?.uid ?? ''}
                          />
                        </div>
                      )}
                    </Draggable>
                  </>
                );
              }
              return (
                <></>
              );
            })}
            { droppableProvided.placeholder }
          </div>
        ) }
      </Droppable>
    </DragDropContext>
    )}
    {!isQuestions && (

    <div>
      {topics?.map((topic: TopicShape, index: number) => (
        <>
          <div>
            <Questions
              isQuestions={isQuestions}
              isLastQuestion={topics.length - 1 === index}
              topic={topic}
              onModifyQuestion={onModifyQuestion}
              formContext={formContext}
              reviewCycleUid={cycle?.uid ?? ''}
              isReadOnly
            />
          </div>
        </>
      ))}
    </div>

    )}
  </>
);

interface QuestionPreviewProps extends ComponentProps<'div'> {
  formContext: UseFormReturn<FormValues | FormValuesMatrixOrg>,
  topics: TopicShape[] | undefined,
  isQuestions: boolean,
  cycleId: string,
  cycle?: CycleShape,
  onFormSubmit: SubmitHandler<FormValues>,
  updateQuestionView: (value: boolean) => void,
  setEditQuestion: (topic: string) => void,
  editQuestion: string,
  onDeleteQuestion?: (props: { topicId: string }) => void,
}

const QuestionPreview = ({
  formContext,
  topics,
  isQuestions,
  cycleId,
  cycle,
  onFormSubmit,
  updateQuestionView,
  setEditQuestion,
  editQuestion,
  onDeleteQuestion,
}: QuestionPreviewProps): JSX.Element => {
  const deleteMutation = useDeleteTopic();

  const onModifyQuestion = (uid: string, action: ModifyQuestionType) :void => {
    if (action === ModifyQuestionType.Delete) {
      const deleteAction = onDeleteQuestion ?? deleteMutation;
      deleteAction({ id: cycleId, topicId: uid });
    }
    if (action === ModifyQuestionType.Edit) {
      setEditQuestion(uid);
    }
  };

  const reOrderTopicMutation = useReorderTopics(cycleId);
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const topicMoveFromIndex: number = result.source.index;
    const topicMoveToIndex: number = result.destination?.index ?? 0;
    const topicOrder = topics?.map((topic) => (
      topic.uid
    ));
    const newTopicOrder = shiftArrayItems(topicOrder ?? [], topicMoveFromIndex, topicMoveToIndex);
    if (newTopicOrder.length) {
      reOrderTopicMutation({ cycleId, topics: newTopicOrder }, { onSuccess });
    }
  };
  const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle): React.CSSProperties => ({
    background: palette.neutrals.white,
    boxShadow: isDragging ? '0px 4px 8px 2px #1c2a3733' : 'none',
    borderRadius: '.5rem',
    ...draggableStyle,
  });

  const onSuccess = async (): Promise<void> => {
    await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewTopics'] });
  };

  const shiftArrayItems = (arr: string[], fromIndex: number, toIndex: number): string[] => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);

    return arr;
  };

  const hookProps = {
    formContext,
    topics,
    isQuestions,
    onModifyQuestion,
    editQuestion,
    cycle,
    onFormSubmit,
    updateQuestionView,
    onDragEnd,
    getItemStyle,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default QuestionPreview;
