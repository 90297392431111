import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';

interface ViewProps extends Omit<LeadrButtonProps<'button'>, 'data-test-id'> {
  onDelete: () => void,
  isMobile: boolean,
}

const DeleteButton = ({
  onDelete,
  isMobile,
  ...props
}: ViewProps): JSX.Element => (
  <DeleteButtonWithConfirmation
    renderDeleteButton={({ onClick }) => (
      <LeadrButton
        data-test-id="meetingTopicSuggestionsDeleteTemplate"
        onClick={onClick}
        variant="ghost"
        color="danger"
        size={isMobile ? 'small' : 'standard'}
        {...props}
      >
        Delete
      </LeadrButton>
    )}
    renderConfirmationButtons={({
      informationStyles,
      optionStyles,
      popoverButtonStyles,
    }) => (
      <DeleteConfirmationButtons
        onDelete={onDelete}
        informationStyles={informationStyles}
        optionStyles={optionStyles}
        popoverButtonStyles={popoverButtonStyles}
      />
    )}
  />
);

export default DeleteButton;
