import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { calendarIntQueryKeys } from './queryKeys';

const disconnectExternalCalendar = async (): Promise<HttpCallReturn<boolean>> => {
  const orgUserId = getOrganizationUserId();

  const URL = {
    host: hosts.meeting,
    uri: `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/wipeData`,
  };

  return postApi(URL, orgUserId);
};

export const useDisconnectExternalCalendar = (): UseMutateFunction<HttpCallReturn<boolean>, unknown, void, void> => {
  const toastId = useRef<ReactText | null>(null);
  const mutation = useMutation({
    mutationFn: disconnectExternalCalendar,

    onMutate: () => {
      toastId.current = toast.info('Disconnecting your calendar...', { autoClose: false });
    },

    onError: () => {
      toast.update(toastId.current, {
        render: 'Error disconnecting your calendar.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },

    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully disconnected your calendar.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: calendarIntQueryKeys.isUserAuthenticated() });
    },
  });

  return mutation.mutate;
};
