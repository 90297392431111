import { ComponentProps, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import moment from 'moment-timezone';

import { lineClamp } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';
import { Goals } from '@leadr-hr/types';
import SkeletonLoader, { SkeletonLoaderProps } from '~Common/components/SkeletonLoader';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { viewGoalDetailsTemplate } from '~Goals/components/Drawers/ViewGoalDetailsDrawer';
import NewGoalStatus from '~Goals/components/Shared/NewGoalStatus';
import { useDispatch } from 'react-redux';
import ChildGoalCount from '~Goals/components/Shared/ChildGoalCount';

const styles = {
  container: css({
    minWidth: '11.5625rem',
    maxWidth: '11.5625rem',
    padding: '0.75rem 1.125rem',
    border: 0,
    backgroundColor: palette.neutrals.white,
    boxShadow: '0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.07)',
    borderRadius: '0.5rem',
    display: 'flex',
    flexWrap: 'wrap',

    '&:hover': {
      boxShadow: '0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.07)',
    },
  }),
  item: css({
    marginBottom: '0.5rem',
  }),
  dates: css({
    textTransform: 'uppercase',
    color: palette.neutrals.gray700,
    fontSize: '0.625rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  }),
  title: css({
    color: palette.neutrals.gray700,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginBottom: '0.5rem',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    minHeight: '2.625rem',
  }, lineClamp(2)),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  }),

  skeleton: css({
    transform: 'initial',
    minWidth: '11.5625rem',
    maxWidth: '11.5625rem',
  }),
};

export interface ViewProps extends ComponentProps<'button'> {
  dateString: string,
  onClick: () => void,
  title: string,
  status: Goals.GoalStatus,
  totalChildGoals: number,
  category?: Goals.GoalCategory,
}

export const View = ({
  dateString,
  onClick,
  title,
  status,
  category,
  key,
  totalChildGoals,
  ...props
}: ViewProps): JSX.Element => (
  <button
    css={styles.container}
    onClick={onClick}
    data-test-id={key}
    type="button"
    {...props}
  >
    {category === Goals.GoalCategory.DefiningObjectives && (
      <div css={styles.dates}>
        { dateString }
      </div>
    )}

    <div css={styles.title}>
      { title }
    </div>

    <div css={styles.footer}>
      <NewGoalStatus
        status={status}
      />
      {totalChildGoals > 0 && (
        <ChildGoalCount totalChildGoals={totalChildGoals} />
      )}
    </div>
  </button>
);

export type MeetingGoalCardSkeletonProps = Omit<SkeletonLoaderProps, 'renderComponent' | 'height' | 'width'>

export const MeetingGoalCardSkeleton = ({
  ...props
}: MeetingGoalCardSkeletonProps): JSX.Element => (
  <SkeletonLoader
    css={styles.skeleton}
    height="5.25rem"
    renderComponent={() => <></>}
    {...props}
  />
);

export interface MeetingGoalCardProps extends Pick<ViewProps, 'title' | 'status' | 'category' | 'totalChildGoals'> {
  canAccessGoal?: boolean,
  startTime: string | number,
  endTime: string | number,
  goalId: string,
}

const MeetingGoalCard = ({
  startTime,
  endTime,
  canAccessGoal = false,
  goalId,
  ...props
}: MeetingGoalCardProps): JSX.Element => {
  const dateString = useMemo(() => {
    const start = moment(startTime).format('MMM DD');
    const end = moment(endTime).format('MMM DD');

    return `${start} - ${end}`;
  }, [
    startTime,
    endTime,
  ]);

  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    if (canAccessGoal) {
      dispatch(pushDrawerAction({
        drawer: {
          ...viewGoalDetailsTemplate,
          args: {
            goalId,
            isReadOnly: !canAccessGoal,
          },
        },
      }));
    }
  }, [canAccessGoal, dispatch, goalId]);

  const hookProps = {
    onClick,
    dateString,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

MeetingGoalCard.Skeleton = MeetingGoalCardSkeleton;

export default MeetingGoalCard;
