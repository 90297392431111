import { useFeedbackTemplatesForOrg } from '~Deprecated/hooks/feedback/useFeedbackTemplates';
/* eslint-disable-next-line */
import Templates from './Templates';

const TemplatesByOrg = ({ ...props }) => {
  const { status, templates } = useFeedbackTemplatesForOrg({});

  const hookProps = {
    templates,
    status,
  };

  return (
    <Templates {...props} {...hookProps} />
  );
};

TemplatesByOrg.propTypes = {

};

TemplatesByOrg.defaultProps = {

};

export default TemplatesByOrg;
