import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { faArrowFromLeft } from '@fortawesome/pro-light-svg-icons';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { DRAWER_STATE_SHAPE, SET_DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { drawerCollapseIconColor, palette } from '~Common/styles/colors';
import { withoutMobile } from '~Common/styles/mixins';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import usePeoplePicker from '~Common/hooks/usePeoplePicker';
import useSelectedAttendees from '~Deprecated/hooks/attendees/useSelectedAttendees';
import AttendeeList from '~Common/V3/components/Attendees/AttendeesList';
import PeopleSearch from '~Common/components/search-boxes/PeopleSearch';
import IconButton from '~Common/V3/components/Buttons/IconButton';

export const participantDrawerTemplate = {
  name: 'PARTICIPANT_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

const styles = {
  collapseButton: (showCloseButtonOverride) => css`
    font-size: 1rem;
    color: ${drawerCollapseIconColor};
    
    ${withoutMobile(`
      display: none;
    `)}

    ${showCloseButtonOverride && `
      display: inline-block !important;
    `}
  `,
  search: css({
    position: 'sticky',
    top: 0,
    padding: '.5rem 1rem',
    margin: '-.5rem -1rem',
    background: palette.neutrals.white,
  }),
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

const View = ({
  isAttendeeSelected,
  isLoading,
  attendees,
  onAttendeeSelect,
  renderSearchBox,
  currentAttendees,
  onSearch,
  closeButton,
  title,
  ...props
}) => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title={title}
        renderCloseButton={closeButton}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <div css={styles.search}>
          {renderSearchBox()}
        </div>
        <div>
          <AttendeeList
            onSearch={onSearch}
            selectedAttendees={currentAttendees}
            isAttendeeSelected={isAttendeeSelected}
            isLoading={isLoading}
            attendees={attendees}
            onClick={onAttendeeSelect}
            {...props}
          />
        </div>
      </div>
    )}
  />
);

View.propTypes = {
  isAttendeeSelected: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  closeButton: PropTypes.func.isRequired,
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
  renderSearchBox: PropTypes.func.isRequired,
  attendees: PropTypes.arrayOf(PropTypes.string),
  onAttendeeSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

View.defaultProps = {
  currentAttendees: [],
  attendees: [],
};

const ParticipantDrawer = ({
  useOrgIds,
  allowSelf,
  limit,
  drawerState,
  setDrawerState,
  selectedKey,
  defaultFilter,
  disableFilter,
  customFilter,
  disableUsers,
  filterOptions,
  onSelect,
  selectAllCallback,
  removeSelectedAttendees,
  currentAttendees,
  showCloseButtonOverride,
  ...props
}) => {
  const {
    onSearch,
    isLoading,
    attendees,
    allAttendees,
    setSearchFilter,
    searchFilter,
  } = usePeoplePicker({ useOrgIds, allowSelf, customFilter });

  const dispatch = useDispatch();
  useEffect(() => {
    if (filterOptions) {
      setSearchFilter(filterOptions[0]);
    }
  }, [setSearchFilter, filterOptions]);

  if (defaultFilter && defaultFilter !== searchFilter) {
    setSearchFilter(defaultFilter);
  }

  let nonSelectedAttendees = [...attendees];

  if (removeSelectedAttendees) {
    nonSelectedAttendees = nonSelectedAttendees.filter((id) => !drawerState?.selectedAttendees?.includes(id));
  }

  const {
    isAttendeeSelected,
    onAttendeeSelect,
  } = useSelectedAttendees({
    useOrgIds,
    attendeeSelectCallback: onSelect,
    allAttendees,
    drawerState,
    setDrawerState,
    limit,
    selectedKey,
    allowSelf,
    disabledUsers: disableUsers,
    selectAllCallback,
    filteredAttendees: attendees,
    nonSelectedAttendees,
    removeSelectedAttendees,
  });

  const renderSearchBox = () => (
    <PeopleSearch
      onSearch={onSearch}
      setSearchFilter={setSearchFilter}
      currentFilter={searchFilter}
      disableFilter={disableFilter || customFilter?.length > 0}
      onFilterChange={setSearchFilter}
    />
  );

  const closeDrawerClick = () => {
    dispatch(popDrawer({ drawerName: participantDrawerTemplate.name }));
  };
  const closeButton = () => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faArrowFromLeft}
      css={styles.collapseButton(showCloseButtonOverride)}
      size="large"
    />
  );

  const hookProps = {
    isAttendeeSelected,
    isLoading,
    attendees,
    onAttendeeSelect,
    renderSearchBox,
    setSearchFilter,
    searchFilter,
    customFilter,
    disableFilter,
    disableUsers,
    onSearch,
    closeButton,
    title: 'Select Participants',
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
ParticipantDrawer.propTypes = {
  useOrgIds: PropTypes.bool,
  allowSelf: PropTypes.bool,
  limit: PropTypes.number,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: SET_DRAWER_STATE_SHAPE.isRequired,
  popDrawer: PropTypes.func.isRequired,
  selectedKey: PropTypes.string,
  disableFilter: PropTypes.bool,
  onSelect: PropTypes.func,
  defaultFilter: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  customFilter: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  disableUsers: PropTypes.arrayOf(PropTypes.object),
  filterOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  selectAllCallback: PropTypes.func,
  removeSelectedAttendees: PropTypes.bool,
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
  showCloseButtonOverride: PropTypes.bool,
};

ParticipantDrawer.defaultProps = {
  useOrgIds: true,
  allowSelf: true,
  limit: null,
  selectedKey: 'selectedAttendees',
  disableFilter: false,
  defaultFilter: null,
  customFilter: [],
  disableUsers: [],
  filterOptions: [],
  onSelect: null,
  selectAllCallback: null,
  removeSelectedAttendees: false,
  currentAttendees: [],
  showCloseButtonOverride: false,
};

registerDrawer({
  templateName: participantDrawerTemplate.name,
  component: ParticipantDrawer,
});

export { View };
export default ParticipantDrawer;
