import { css } from '@emotion/react';
import { forMobile } from '~Common/styles/mixins';

const styles = {
  leftSide: (isCentered: boolean) => css({
    alignItems: 'center',
    display: 'flex',
    maxHeight: '2.8125rem',
    width: '75%',
  }, isCentered && {
    justifyContent: 'space-between',
  }),
  topBar: css({
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    columnGap: '0.5rem',
  }, forMobile(`
    grid-template-columns: auto;
    row-gap: 0.5rem;
  `)),

};

interface InteriorTopBarProps {
  renderRightSide?: () => JSX.Element,
  renderLeftSide: () => JSX.Element,
}

export const InteriorTopBar = ({
  renderRightSide,
  renderLeftSide,
}: InteriorTopBarProps): JSX.Element => (
  <div css={styles.topBar}>
    <div css={(styles.leftSide(false))}>
      {renderLeftSide()}
    </div>
    <div>
      {renderRightSide?.()}
    </div>
  </div>
);
