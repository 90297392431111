import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { forMobileObject, withTruncate } from '~Common/styles/mixins';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { LearningTemplate, LearningTemplateFromSearch } from '~Learning/const/interfaces';

const styles = {
  contentCard: css({
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  }),
  contentContainer: css({
    borderRadius: '.625rem',
    marginRight: '1.125rem',
    overflow: 'hidden',
  }),
  contentDetails: css({}),
  contentTitle: css({
    fontWeight: 600,
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }, withTruncate()),
  contentAuthor: css({
    fontWeight: 600,
    color: palette.neutrals.gray500,
    fontSize: '.8125rem',
    marginTop: '.375rem',
  }, withTruncate()),
  buttonsContainer: css({
    marginTop: '.9375rem',
  }, forMobileObject({
    marginTop: '0.5rem',
  })),
  contentSkeleton: css({
    minWidth: '100%',
    height: '6.875rem',
  }),
  categoryNameChip: css({
    backgroundColor: palette.neutrals.gray300,
    borderRadius: '0.5rem',
    padding: '.25rem .625rem',
    color: palette.neutrals.gray900,
    width: 'fit-content',
    margin: '.875rem 0',
  }),
  learningType: css({
    marginTop: '.625rem',
    marginBottom: '1rem',
  }),
};

interface ViewProps {
  contentTitle: string,
  contentAuthor: string,
  templateId: string,
  onViewLearningTemplate: (templateId: string) => void,
  isMobile: boolean,
  categoryName?: string,
  renderPreview: () => JSX.Element,
  renderLearningType?: () => JSX.Element,
}

const View = ({
  contentTitle,
  contentAuthor,
  templateId,
  categoryName,
  onViewLearningTemplate,
  isMobile,
  renderPreview,
  renderLearningType,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.contentCard} {...props}>
    <div css={styles.contentContainer}>
      {renderPreview()}
    </div>
    <div css={styles.contentDetails}>
      <div css={styles.learningType}>
        {renderLearningType?.()}
      </div>
      <div css={styles.contentTitle}>{contentTitle}</div>
      {categoryName && (
        <div css={styles.categoryNameChip}>{categoryName}</div>
      )}
      {!isMobile && (
        <div css={styles.contentAuthor}>
          {`Created by ${contentAuthor}`}
        </div>
      )}
      <div css={styles.buttonsContainer}>
        <LeadrButton
          size="small"
          onClick={() => onViewLearningTemplate(templateId)}
          data-test-id="learningLibraryViewContent"
        >
          View
        </LeadrButton>
      </div>
    </div>
  </div>
);

interface ContentCardProps extends Omit<ViewProps, 'contentTitle' | 'contentAuthor' | 'templateId' | 'categoryName'> {
  learningTemplate: LearningTemplate | LearningTemplateFromSearch,
}

const ContentCard = ({ learningTemplate, ...props }: ContentCardProps): JSX.Element => {
  let categoryName = '';

  // Only if the learningTemplate has categoryName, so its type LearningTemplateFromSearch
  if ('categoryName' in (learningTemplate)) {
    categoryName = learningTemplate.categoryName;
  }

  const hookProps = {
    contentTitle: learningTemplate.contentTemplateTitle,
    contentAuthor: learningTemplate.ownedBy?.name,
    templateId: learningTemplate.uid,
    categoryName,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ContentCard;
