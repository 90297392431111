import { css } from '@emotion/react';
import {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Link, useHistory } from 'react-router-dom';
import { palette } from '~Common/styles/colors';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from '~Common/V3/components/uncontrolled';
import { useParams } from 'react-router';
import {
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { Goals } from '@leadr-hr/types';
import { toast } from '~Common/components/Toasts';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { InteriorTopBar } from '../components/GoalsTopBar/InteriorTopBar';
import { GoalsRoutes } from './GoalsRouter';
import { DEFAULT_GOAL, DEFAULT_TEAM_ID } from '../const/defaults';
import { CreateGoalForm } from '../components/Create';
import { GoalDetailsParams, GoalShape, ValidationErrors } from '../const/types';
import { useGetGoalById } from '../hooks/useGetGoalById';
import { setupPayLoads } from '../const/functions';
import { useEditGoal } from '../hooks/useEditGoal';
import { useEditGoalParticipants } from '../hooks/useEditGoalParticipants';
import { CreateGoalFormSkelly } from '../components/Create/CreateGoalFormSkelly';
import {
  FormValues, conformToDto, editGoalFormResolver, editGoalFormSchema,
} from '../schemata/EditGoalSchemata';

const styles = {
  container: css({
    width: '100%',
    margin: '1.875rem 1.875rem 0 1.875rem',
  }),
  textBackButton: css({
    fontSize: '1rem',
    fontWeight: 400,
    color: palette.neutrals.gray800,
  }),
  icon: css({
    marginRight: '0.5rem',
  }),
  buttonSpacing: css({
    marginRight: '.625rem',
  }),
  rightSide: (isMobile: boolean) => css({

  }, isMobile && {
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

interface ViewProps {
  runValidations: () => void,
  formContext: UseFormReturn<FormValues>,
  goal: GoalShape,
  showSkeleton: boolean,
  goalId: string,
  isMobile: boolean,
}

const View = ({
  runValidations,
  formContext,
  goal,
  showSkeleton,
  goalId,
  isMobile,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    {showSkeleton && (
      <CreateGoalFormSkelly
        showSkeleton={showSkeleton}
        isMobile={isMobile}
      />
    )}
    {!showSkeleton && (
      <Form
        formContext={formContext}
        onSubmit={() => null}
      >
        <InteriorTopBar
          renderLeftSide={() => (
            <>
              <LeadrButton
                component={Link}
                to={GoalsRoutes.ViewById.replace(':goalId', goalId)}
                variant="text"
                css={styles.textBackButton}
                textButtonColor={palette.neutrals.gray700}
                data-test-id="goalsBackToGoalsList"
              >
                <FontAwesomeIcon
                  css={styles.icon}
                  icon={faArrowLeft}
                />
                Back
              </LeadrButton>
            </>
          )}
          renderRightSide={() => (
            <>
              <div css={styles.rightSide(isMobile)}>
                <LeadrButton
                  data-test-id="goalsCancelCreateGoal"
                  variant="ghost"
                  css={styles.buttonSpacing}
                  component={Link}
                  to={GoalsRoutes?.Dashboard}
                >
                  Cancel
                </LeadrButton>
                <LeadrButton
                  type="submit"
                  data-test-id="goalsSaveCreateGoal"
                  onClick={runValidations}
                  disabled={formContext.formState.isSubmitting || showSkeleton}
                >
                  Save Goal
                </LeadrButton>
              </div>
            </>
          )}
        />
        <CreateGoalForm
          formContext={formContext}
          goal={goal}
          isEdit
        />
      </Form>
    )}
  </div>
);

const EditGoal = (): JSX.Element => {
  const { goalId } = useParams<GoalDetailsParams>();
  const { data: goalData, isLoading } = useGetGoalById({ id: goalId });
  const [showSkeleton] = useSkeletonLoaders(isLoading);
  const [successfulDetailsEdit, setSuccessfulDetailsEdit] = useState(false);
  const [successfulParticipantsEdit, setSuccessfulParticipantsEdit] = useState(false);
  const isMobile = useIsMobileQuery();

  const ownerOfGoal = goalData?.participants?.find((participant) => participant.role === Goals.GoalParticipantRole.Owner)?.orgUserId;
  const orgUserId = getOrganizationUserId();

  // Did this because the endpoint sends back a lot of extra data that we don't need
  const goalToUse = useMemo((): GoalShape => ({
    title: goalData?.title ?? DEFAULT_GOAL.title,
    description: goalData?.description ?? DEFAULT_GOAL.description,
    participants: goalData?.participants ?? DEFAULT_GOAL.participants,
    priority: goalData?.priority ?? DEFAULT_GOAL.priority,
    context: goalData?.context ?? DEFAULT_GOAL.context,
    category: goalData?.category ?? DEFAULT_GOAL.category,
    externalLink: goalData?.externalLink ?? DEFAULT_GOAL.externalLink,
    isPrivate: goalData?.isPrivate ?? DEFAULT_GOAL.isPrivate,
    startTimeInMillis: goalData?.startTimeInMillis ?? DEFAULT_GOAL.startTimeInMillis,
    endTimeInMillis: goalData?.endTimeInMillis ?? DEFAULT_GOAL.endTimeInMillis,
    owner: ownerOfGoal && ownerOfGoal.length > 0 ? [ownerOfGoal] : DEFAULT_GOAL.owner,
  }), [goalData, ownerOfGoal]);

  const defaultValues = useCallback((): FormValues => {
    const {
      title,
      description,
      participants,
      priority,
      context,
      category,
      externalLink,
      isPrivate,
      startTimeInMillis,
      endTimeInMillis,
    } = goalToUse ?? DEFAULT_GOAL;

    const team = context?.contextType === Goals.GoalContextType.Team ? context.contextId : DEFAULT_TEAM_ID;
    const owners = participants.filter((participant) => participant.role === Goals.GoalParticipantRole.Owner);

    return {
      title,
      description,
      participants,
      priority,
      context,
      category,
      externalLink,
      isPrivate,
      startTimeInMillis,
      endTimeInMillis,
      owner: owners?.[0]?.orgUserId ?? orgUserId,
      type: goalToUse.context.contextType,
      team,
    };
  }, [goalToUse, orgUserId]);

  const formContext = useForm<FormValues>({
    resolver: editGoalFormResolver,
  });
  // We've got to do it this way becuase on refresh or at random times on load, the form context will not be re-rendered
  // and we will not have all the form data
  useEffect(() => {
    formContext.reset(defaultValues());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalToUse]);

  useEffect(() => {
    if (successfulDetailsEdit && successfulParticipantsEdit) {
      onSuccess();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfulDetailsEdit, successfulParticipantsEdit]);
  const history = useHistory();
  const onSuccess = (): void => {
    history.push(GoalsRoutes.ViewById.replace(':goalId', goalId));
  };
  const resetMutationState = (): void => {
    setSuccessfulDetailsEdit(false);
    setSuccessfulParticipantsEdit(false);
  };

  const { mutate: editGoalDetailsMutation } = useEditGoal();
  const { mutate: editGoalParticipantsMutation } = useEditGoalParticipants();

  const runValidations = (): void => {
    const data = formContext.getValues();
    editGoalFormSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        submitFormData(data);
      })
      .catch((err: ValidationErrors) => {
        if (err && err.errors) {
          err.errors.forEach((error) => {
            toast.error(error);
          });
        } else {
          toast.error('There was an error submitting your form. Please try again.');
        }
      });
  };
  const submitFormData = (data: FormValues): void => {
    // We want to be sure we reset these just in case one of the mutations fails
    resetMutationState();
    const formData = conformToDto(data);
    const { editGoalPayload, participantsPayload } = setupPayLoads(formData);

    if (participantsPayload) {
      const filteredParticipants = participantsPayload.filter((participant) => !!participant.role);
      const participantsPayloadToSend = { participants: filteredParticipants };
      const shouldSendParticipants = participantsPayload.length > 0;
      // Should never come to this but just in case I wanted to have a fallback so we're not wiping out the participants
      if (shouldSendParticipants) {
        editGoalParticipantsMutation(
          { payload: participantsPayloadToSend, goalId },
          { onSuccess: () => setSuccessfulParticipantsEdit(true), onError: () => resetMutationState() },
        );
      } else {
        // need to set this to true so we can navigate away if the details edit was successful
        setSuccessfulParticipantsEdit(true);
      }
    }
    editGoalDetailsMutation(
      { payload: editGoalPayload, goalId },
      { onSuccess: () => setSuccessfulDetailsEdit(true), onError: () => resetMutationState() },
    );
  };

  const hookProps = {
    runValidations,
    formContext,
    goal: goalToUse,
    showSkeleton,
    goalId,
    isMobile,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };

export default EditGoal;
