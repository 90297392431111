import { useParams } from 'react-router';
import { CardSkeleton } from '~Common/V3/components/Card';
import { ListSectionSkeleton } from '~Common/V3/components/ListSection';
import { ListDashboardSkeleton } from '~Common/components/Loader/ListDashboard';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useHistory } from 'react-router-dom';
import { ReviewDetailsTopBar } from '../TopBar/ReviewDetailsTopBar';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '../Const/pageStyles';
import { useGetReviewById } from '../Hooks/useGetReviewById';
import { ReviewHistoryState, ReviewResponseDetails } from '../pages/ReviewResponseDetails';
import { ReviewCycleStatusEnum, ReviewShape } from '../Const/types';
import { returnReviewParticipantType } from '../Hooks/returnReviewParticipantType';
import { ReadOnlyReviewResponseDetails } from '../pages/ReadOnlyReviewResponseDetails';
import ReviewsPermissionDenied from '../pages/ReviewsPermissionDenied';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
};

interface ViewProps {
  showSkeleton: boolean,
  review: ReviewShape | undefined,
  isParticipant: boolean,
  isFinishedCycle: boolean,
  isPermissionDenied: boolean,
}
const View = ({
  showSkeleton,
  review,
  isParticipant,
  isFinishedCycle,
  isPermissionDenied,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    {isPermissionDenied && (
      <ReviewsPermissionDenied />
    )}
    {!isPermissionDenied && (
    <>
      {showSkeleton && (
        <>
          <div css={[styles.topBar]}>
            <div css={styles.leftSide(false)}>
              <MultipleSkeletonLoaders
                css={styles.topBarSkellyLoader}
                numberOfSkeletons={1}
                renderSkeletonItem={() => (
                  <CardSkeleton css={styles.cardSkeleton} />
                )}
              />
            </div>
          </div>
          <ListSectionSkeleton css={styles.listSectionSkeleton} />
          <ListDashboardSkeleton
            amountOfLoaders={2}
          />
        </>
      )}
      {!showSkeleton && (
        <>
          <ReviewDetailsTopBar />
          {isParticipant && !isFinishedCycle && review && (
            <>
              <ReviewResponseDetails
                review={review}
              />
            </>
          )}
          {(!isParticipant || isFinishedCycle) && review && (
          <>
            <ReadOnlyReviewResponseDetails
              review={review}
            />
          </>
          )}
        </>
      )}
    </>
    )}
  </div>
);

interface ReviewDetailsParams {
  reviewId: string,
  setDisplay?: string,
}

const ReviewResponseLayout = (): JSX.Element => {
  const { reviewId } = useParams<ReviewDetailsParams>();
  const history = useHistory();
  const routeState = history.location.state as ReviewHistoryState;
  const prevPage = routeState?.prevPage;
  const isComingFromAdminView = prevPage?.includes('reviews/admin/cycle/');

  const { data, isLoading, error } = useGetReviewById({
    id: reviewId,
    isAdminAccess: isComingFromAdminView ?? false,
    retry: (failureCount, httpError) => {
      if (httpError.status === 401) {
        return false;
      }

      return failureCount < 3;
    },
  });

  const isPermissionDenied = error?.status === 401;
  const review = data?.response;
  const { isManager, isSecondaryManager, isReviewee } = returnReviewParticipantType(review);
  const [showSkeleton] = useSkeletonLoaders(isLoading);
  const isFinishedCycle = review?.reviewCycle.statusEnum === ReviewCycleStatusEnum.Finished;

  const hookProps = {
    showSkeleton,
    review,
    isParticipant: isManager || isSecondaryManager || isReviewee,
    isFinishedCycle,
    isPermissionDenied,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ReviewResponseLayout };
export default ReviewResponseLayout;
