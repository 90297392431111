/* eslint-disable max-len */
import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';
import { ReviewCycleStatusEnum, ReviewCycleWithParticipants } from '../Const/types';
import { CENTER_CONTENTS } from '../Const/pageStyles';

const styles = {
  wrapper: css({
    background: palette.brand.indigo,
    padding: '.75rem 1.5rem',
    borderRadius: '.5rem',
    color: palette.neutrals.white,
    fontWeight: '400',
    fontSize: '1.125rem',
    lineHeight: '1.3125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1.375rem',
    maxWidth: '100% !important',
  }),
  boldText: css({
    fontWeight: '600',
    marginRight: '.625rem',
  }),
  icon: css({
    color: palette.neutrals.gray500,
  }),
  ...CENTER_CONTENTS,
};

interface ViewProps extends ComponentProps<'div'> {
  isFinished: boolean,
}

const View = ({
  isFinished,
}: ViewProps): JSX.Element => (
  <>
    {isFinished && (
      <div
        css={[styles.wrapper, styles.centeredContent]}
      >
        <div css={styles.stepper}>
          <span css={styles.boldText}>Review Cycle Finished</span>
          - This review cycle was ended by an admin
        </div>
      </div>
    )}
    {!isFinished && (<></>)}
  </>
);

interface ReviewCycleFinishedBannerProps {
    reviewCycle: ReviewCycleWithParticipants,
}
const ReviewCycleFinishedBanner = ({
  reviewCycle,
}: ReviewCycleFinishedBannerProps): JSX.Element => {
  const reviewCycleStatus = reviewCycle.statusEnum;
  const isFinished = reviewCycleStatus === ReviewCycleStatusEnum.Finished;

  const hookProps = {
    reviewCycleStatus,
    isFinished,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default ReviewCycleFinishedBanner;
