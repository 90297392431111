import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { css } from '@emotion/react';
import moment from 'moment';

import { LeadrCoachPrompt, translateLeadrCoachCategory, useCompleteLeadrCoachPrompt } from '~Insights/hooks/useLeadrCoachPrompts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { EngagementCategory } from '~Insights/const/types';

import { palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';
import { Spinner } from '~Common/V3/components/Spinner';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';

const styles = {
  checkbox: css({
    '& .MuiCheckbox-root': {
      borderRadius: '0.5rem',
      color: palette.neutrals.gray400,
      fontSize: '1.5rem',
    },
  }),
  container: (isMobile: boolean) => css({
    alignItems: isMobile ? 'flex-start' : 'center',
    columnGap: '1.125rem',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
  }),
  content: css({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    rowGap: '0.625rem',

    '& h4': {
      color: palette.brand.indigo,
      fontSize: '1.25rem',
      fontWeight: 600,
    },

    '& p': {
      color: palette.neutrals.gray600,
      fontSize: '1rem',
    },
  }),
  checkboxWrapper: (isMobile: boolean) => css({
    paddingLeft: isMobile ? '0' : '.375rem',

    '& span': {
      padding: isMobile ? '.5rem .75rem .5rem 0' : '.5rem .75rem',
    },
  }),
  date: css({
    color: palette.neutrals.gray800,
    fontSize: '0.825rem',
  }),
  readMore: (isMobile: boolean) => css({
    color: palette.brand.indigo,
    fontSize: '1.125rem',
    fontWeight: 600,
    padding: isMobile ? '1.5rem 1.5rem 0 0' : '0 1.5rem',
    whiteSpace: 'nowrap',
  }),
  spinner: css({
    height: '1.5rem',
    margin: '0.63rem',
    padding: '0.75rem',
    width: '1.5rem',
  }),
};

interface ViewProps {
  isMutating: boolean,
  prompt: LeadrCoachPrompt,
  isMobile: boolean,
  onComplete: MouseEventHandler<HTMLButtonElement>,
  translatedCategory: EngagementCategory | null,
}

const View = ({
  isMutating,
  onComplete,
  prompt,
  isMobile,
  translatedCategory,
}: ViewProps): JSX.Element => (
  <div css={styles.container(isMobile)}>
    <Button
      css={styles.checkboxWrapper(isMobile)}
      variant="text"
      renderContents={() => (isMutating ? (
        <Spinner
          css={styles.spinner}
          foregroundColor={palette.brand.indigo}
          backgroundColor={palette.neutrals.gray300}
        />
      ) : (
        <CustomCheckbox />
      ))}
      disabled={isMutating}
      onClick={onComplete}
      title="Mark prompt 'complete'."
    />
    <div css={styles.content}>
      {translatedCategory && (
        <h4>
          Engagement
          {' - '}
          {capitalize(translatedCategory)}
        </h4>
      )}
      <p>{prompt.prompt.prompt}</p>
      <div css={styles.date}>
        {moment(prompt.created).format('LL')}
      </div>
    </div>
    {prompt.prompt.action?.url && (
      <Link to={`/${prompt.prompt.action.url}`}>
        <Button
          variant="text"
          css={styles.readMore(isMobile)}
          renderContents={() => <>{prompt.prompt.action.label}</>}
        />
      </Link>
    )}
  </div>
);

interface LeadrCoachPromptProps {
  prompt: LeadrCoachPrompt,
  isMobile: boolean,
}

const LeadrCoachPromptRow = ({
  prompt,
  isMobile,
  ...props
}: LeadrCoachPromptProps): JSX.Element => {
  const { mutate: completePromptMutation, isPending: isMutating } = useCompleteLeadrCoachPrompt();
  const translatedCategory = translateLeadrCoachCategory(prompt.prompt.category);
  const organizationId = getOrganizationId() ?? '';

  const onComplete: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    completePromptMutation({ organizationId, promptId: prompt.id });
  };

  const hookProps = {
    isMutating,
    onComplete,
    prompt,
    translatedCategory,
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View, LeadrCoachPromptRow };
export default LeadrCoachPromptRow;
