import * as types from '../../actions';

const loaderState = (state = {}, action) => {
  if (action.type === types.ACTIVATE_LOADER) {
    const { name } = action.response;
    const targetIndex = state.findIndex((res) => res.name === name);
    if (targetIndex === -1) {
      state.push(action.response);
    } else {
      const oldData = state;
      oldData[targetIndex] = action.response;
      return [...oldData];
    }
    return [...state];
  }
  return state;
};

export default loaderState;
