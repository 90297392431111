import { useQuery } from '@tanstack/react-query';
import { LearningTemplateFromSearch } from '~Learning/const/interfaces';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';
import { learningCuratedTemplatesQueryKeys } from '../queryKeys';

interface GetLearningCuratedTemplateDetailParams {
  page: number,
  count: number,
  searchString: string,
}

interface GetLearningTemplateResponse {
  learningTemplates: LearningTemplateFromSearch[],
  total: number,
  count: number,
}

const getLearningCuratedTemplateSearch = async ({
  page,
  count,
  searchString,
}: GetLearningCuratedTemplateDetailParams): Promise<HttpCallReturn<GetLearningTemplateResponse>> => {
  const queryData = buildQueryString({
    page,
    count,
    searchString,
  });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/curatedByLeadr/search${queryData}`;

  return getApi<GetLearningTemplateResponse>(serverUrl);
};

interface UseLearningCuratedTemplateSearchParams {
  page: number,
  count: number,
  searchString: string,
  isValidSearch: boolean,
  enabled: boolean,
}

export interface UseLearningCuratedTemplateSearchReturn {
  learningTemplates: LearningTemplateFromSearch[],
  isLoading: boolean,
  total: number,
  count: number,
}

export const useLearningCuratedTemplateSearch = ({
  page,
  count,
  searchString,
  isValidSearch,
  enabled,
}: UseLearningCuratedTemplateSearchParams): UseLearningCuratedTemplateSearchReturn => {
  const result = useQuery({
    queryKey: learningCuratedTemplatesQueryKeys.search({
      page,
      count,
      searchString,
    }),
    queryFn: () => getLearningCuratedTemplateSearch({
      page,
      count,
      searchString,
    }),
    enabled: enabled && !!searchString && isValidSearch,
  });

  return {
    learningTemplates: result.data?.response?.learningTemplates ?? [],
    count: result.data?.response?.count ?? 0,
    isLoading: result.isLoading,
    total: result.data?.response?.total ?? 0,
  };
};
