import { css } from '@emotion/react';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import EmptyMeetings from '~Meetings/assets/images/emptyMeetings.svg';

const styles = {
  container: css({
    marginTop: '3.125rem',
  }),
  emptyState: css({
    h6: {
      width: '80%',
      paddingTop: '2.1875rem',
    },
  }),
  emptyStateImage: css({
    height: '11.4375rem',
  }),
};

const NoItemsText = (isPast: boolean): string => {
  if (isPast) {
    return `You have no past meetings.
    One time meetings are archived after they occur and recurring meetings are archived when the recurrence is ended.`;
  }
  return 'You have no meetings scheduled. When a meeting is scheduled, it’ll show up here.';
};

interface EmptyMeetingStateProps {
  isLoading: boolean,
  isPast: boolean,
}

const EmptyMeetingState = ({
  isLoading,
  isPast,
}: EmptyMeetingStateProps): JSX.Element => (
  <div
    css={styles.container}
  >
    <div>
      <EmptyStateWithImage
        css={styles.emptyState}
        renderImage={() => (
          <EmptyMeetings css={styles.emptyStateImage} title="Empty Meetings" />
        )}
        text={isLoading ? 'Getting your list of meetings' : NoItemsText(isPast)}
      />
    </div>
  </div>
);

export default EmptyMeetingState;
