import ReviewTitle from '~Reviews/V2/Shared/ReviewTitle';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import GridCard from '~Common/components/Cards/GridCard';
import { ReviewParticipant } from '../Const/types';

interface ViewProps {
  reviewTitle: string,
  title: string,
  description: string,
  dueDate: string | Date | undefined,
  numberOfUsersAssigned: number,
  usersInfo: PersonDisplayInformation[],
  renderCompletedSection?: () => JSX.Element,
  isComplete: boolean,
}

const View = ({
  reviewTitle,
  title,
  description,
  dueDate,
  numberOfUsersAssigned,
  usersInfo,
  renderCompletedSection,
  isComplete,
  ...props
}: ViewProps): JSX.Element => (
  <GridCard
    title={title}
    feature="reviews"
    description={description}
    assignedUsersInfo={usersInfo}
    numberOfUsersAssigned={numberOfUsersAssigned}
    renderHeader={() => (
      <ReviewTitle
        reviewTitle={reviewTitle}
      />
    )}
    renderFooter={() => (
      <>
        <DueDateFromNow
          dueDate={dueDate}
          isComplete={isComplete}
        />
        {renderCompletedSection?.()}
      </>
    )}
    {...props}
  />
);

interface ReviewCardProps extends Omit<ViewProps, 'usersInfo'> {
  assignedUsersInfo?: ReviewParticipant[],
}

const ReviewCard = ({
  assignedUsersInfo,
  ...props
}: ReviewCardProps): JSX.Element => {
  const usersInfo = assignedUsersInfo?.map((user) => ({
    firstName: user.firstName,
    id: user.uid,
    lastName: user.lastName,
    jobTitle: '',
    profileImageUrl: user.profileImageUrl,
  })) ?? [];

  const hookProps = {
    usersInfo,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default ReviewCard;
