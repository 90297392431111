import { connect } from 'react-redux';

import FavoriteButton from '~Deprecated/ui/components/Buttons/FavoriteButton';
import { toggleFavoriteOrgAction } from '~Deprecated/actions/organizationInsights/toggleFavoriteOrg';
import { isFavoriteOrg } from '~Deprecated/selectors/organizationInsights/isFavoriteOrg';

const mapStateToProps = (state, { id }) => ({
  isFavorite: isFavoriteOrg(state, { id }),
  orgId: id,
});

const mapDispatchToProps = {
  onClick: toggleFavoriteOrgAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteButton);
