import { MouseEvent } from 'react';
import Button from '../Button';
import { RenderConfirmationButtonsParams } from './DeleteConfirmationPopover';

interface DeleteConfirmationButtonsProps extends RenderConfirmationButtonsParams {
  onDelete: (mouseEvent: MouseEvent) => void,
}

/**
 * @deprecated Use new generic ConfirmationButtons component
 */
const DeleteConfirmationButtons = ({
  informationStyles,
  optionStyles,
  popoverButtonStyles,
  onDelete,
}: DeleteConfirmationButtonsProps): JSX.Element => (
  <>
    <Button
      variant="text"
      renderContents={() => <>Are You Sure?</>}
      css={[popoverButtonStyles, informationStyles]}
      disabled
    />
    <Button
      variant="text"
      data-test-id="confirmDeleteButton"
      renderContents={() => <>Yes, Delete</>}
      css={[popoverButtonStyles, optionStyles]}
      onClick={onDelete}
    />
    <Button
      variant="text"
      data-test-id="cancelDeleteButton"
      renderContents={() => <>No, Cancel</>}
      css={[popoverButtonStyles, optionStyles]}
    />
  </>
);

export default DeleteConfirmationButtons;
