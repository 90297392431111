import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { deleteApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

const deleteUser = async (userIds) => {
  const URL = `/organizations/${getOrganizationId()}/users/bulk`;
  return deleteApi(URL, userIds);
};

export const useDeleteUser = () => {
  const toastId = useRef(null);

  const mutation = useMutation({
    mutationFn: deleteUser,
    onMutate: () => {
      toastId.current = toast.info('Deleting the user(s)...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'The delete failed. Please try again...',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'The delete was successful!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'people'] });
    },
  });

  return mutation.mutate;
};
