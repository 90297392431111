import { buildRequestBody, buildQueryString } from '~Common/utils';
import { hosts } from './config';
import {
  getApi, postApi, patchApi, putApi, deleteApi,
} from './HttpService';

export const getOrganizations = ({
  sort = 'name', filterFavourites = false, page = 1, count = 20, searchKeyword = '',
}) => {
  const queryParams = {
    page,
    count,
    sort,
    isFavourite: filterFavourites,
  };

  if (searchKeyword) {
    queryParams.search = searchKeyword;
  }

  const serverUrl = {
    host: hosts.feedback,
    uri: `/organizations/insights${buildQueryString(queryParams)}`,
  };
  return getApi(serverUrl, {}, {});
};

export const getOrganization = ({
  sort = 'name', targetUserId = '', page = 1, count = 20, searchKeyword = '', onlySubtree = false, id: organizationId,
}) => {
  const queryParams = {
    page,
    count,
    sort,
  };
  if (searchKeyword) {
    queryParams.search = searchKeyword;
  }

  if (onlySubtree) {
    queryParams.onlyReporteeSubtree = onlySubtree;
  }

  if (targetUserId) {
    queryParams.targetUserId = targetUserId;
  }

  const serverUrl = {
    host: hosts.feedback,
    uri: `/organizations/${organizationId}/insights${buildQueryString(queryParams)}`,
  };
  return getApi(serverUrl, {}, {});
};

// Timezone isn't going to be relevant for Organizations moving forward, just hard coding this for now until we can remove that for validation reasons
// This timezone was chosen because it's valid for both old and new timezone implementations
export const createOrganization = ({
  organizationName,
  timezone = 'America/Indiana/Indianapolis',
  salesforceId,
  enableHRModule,
  easeUrl,
  enableISolvedModule,
  iSolvedUrl,
  enableInsights,
  enableReviews,
  salesForceCustomerAccountNumber,
}) => {
  const serverUrl = {
    host: hosts.feedback,
    uri: '/organizations',
  };
  return postApi(serverUrl, buildRequestBody({
    organizationName,
    timezone,
    salesforceId,
    salesForceCustomerAccountNumber,
    enableHRModule,
    easeUrl,
    enableISolvedModule,
    iSolvedUrl,
    enableInsights,
    enableReviews,
  }), {});
};

export const patchOrganization = ({
  organizationName,
  logo: customerLogo,
  salesforceId,
  salesForceCustomerAccountNumber,
  id,
  enableHRModule,
  easeUrl,
  enableISolvedModule,
  iSolvedUrl,
  enableInsights,
  enableReviews,
}) => {
  const serverUrl = {
    host: hosts.feedback,
    uri: `/organizations/${id}`,
  };
  // Not going to use buildRequestBody here, because we want to be able to send a blank string from time to time.
  return patchApi(serverUrl, buildRequestBody({
    organizationName,
    customerLogo,
    salesforceId,
    salesForceCustomerAccountNumber,
    enableHRModule,
    easeUrl,
    enableISolvedModule,
    iSolvedUrl,
    enableInsights,
    enableReviews,
  }, true), {});
};

export const createOrganizationAddress = ({
  street, city, state: stateId, zip, id,
}) => {
  const serverUrl = {
    host: hosts.feedback,
    uri: `/organizations/${id}/addresses`,
  };
  return postApi(serverUrl, buildRequestBody({
    street,
    city,
    stateId,
    zip,
  }), {});
};

export const disablePulseSurvey = (organizationId) => {
  const serverUrl = {
    host: hosts.insights,
    uri: `/organizations/${organizationId}/surveys/leadrPulse/disable`,
  };
  return postApi(serverUrl, buildRequestBody({
    organizationId,
  }), {});
};

export const updateOrganizationAddress = ({
  street, city, state: stateId, zip, id, addressId,
}) => {
  const serverUrl = {
    host: hosts.feedback,
    uri: `/organizations/${id}/addresses`,
  };
  return putApi(serverUrl, buildRequestBody({
    addressId,
    street,
    city,
    stateId,
    zip,
  }), {});
};

export const setFavoriteOrg = ({
  id,
}) => {
  const serverUrl = {
    host: hosts.feedback,
    uri: `/organizations/${id}/favourite`,
  };
  return putApi(serverUrl, {}, {});
};

export const resetFavoriteOrg = ({
  id,
}) => {
  const serverUrl = {
    host: hosts.feedback,
    uri: `/organizations/${id}/favourite`,
  };
  return deleteApi(serverUrl, {}, {});
};
