import { css } from '@emotion/react';
import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { faBookCircleArrowRight, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';

const styles = {
  saveIndicator: (errorSaving: boolean) => css({
    alignItems: 'center',
    color: errorSaving ? palette.brand.red : palette.brand.indigo,
    fontWeight: errorSaving ? 'bold' : 'normal',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75rem',
  }),
};

interface SaveIndicatorProps {
  isDirty: boolean,
  isSaving: boolean,
  isSaveRetrying?: boolean,
}

export const SaveIndicator = ({
  isDirty,
  isSaving,
  isSaveRetrying = false,
  ...props
}: SaveIndicatorProps): JSX.Element => {
  let message = 'All Changes Saved';
  let icon = faCheck;

  if (isSaving && isSaveRetrying) {
    message = 'Save failed, retrying...';
    icon = faLoader;
  } else if (isSaving) {
    message = 'Saving Changes';
    icon = faLoader;
  } else if (isDirty) {
    message = 'Waiting to Save';
    icon = faBookCircleArrowRight;
  }

  return (
    <div css={styles.saveIndicator(isSaving && isSaveRetrying)} {...props}>
      <FontAwesomeIcon icon={icon} />
      {message}
    </div>
  );
};
