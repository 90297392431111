/* eslint-disable react/button-has-type */

import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const SendButton = (props) => (
  <Button
    tooltip="Send"
    variant={BUTTON_VARIANT.ICON}
    icon={faPaperPlane}
    buttonColor={BUTTON_COLOR.ADDITIVE}
    {...props}
  />
);

SendButton.propTypes = {};

SendButton.defaultProps = {};

export default SendButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
