import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faFileExport, faPrint,
} from '@fortawesome/pro-light-svg-icons';
import { useState, useEffect } from 'react';
import {
  surveyChevronIcon, surveyQuestionTypeText, surveyChevronButtonGroupBorder, surveyActionMenu,
} from '~Common/styles/colors';

import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import FlatButton from '~Common/V3/components/FlatButton';
import Tooltip from '~Common/components/Tooltip';
import {
  MENU_ITEMS_SHAPE,
  SURVEY_BY_PARTICIPANT_ANSWERS_SHAPE, SURVEY_QUESTION_SHAPE, SURVEY_QUESTION_TYPES_SHAPE, SURVEY_SHAPE,
} from '~Common/const/proptypes';
import { buttonDefaultColor } from '~Deprecated/ui/styles/colors';
import { usePrintView } from '~Deprecated/hooks/usePrintView';
import ByPersonResult from './ByPersonResult';
import SurveyDetailsCard from '../../Cards/SurveyDetailsCard';
import SurveyActionMenu from './SurveyActionMenu';
import { useGetSurveyCSV } from '../../../Hooks/useSurveysList';

const styles = {
  personHeader: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  personInfoContainer: css`
    display: flex;
    align-items: center;
  `,
  name: css`
    font-size: 1.5rem; 
    font-weight: bold;
  `,
  jobTitle: css`
    font-size: .875rem;
    color: ${surveyQuestionTypeText};
  `,
  personInfo: css`
    margin-left: 1rem;
  `,
  icon: css`
    height: 1.125rem;
    color: ${surveyChevronIcon};
  `,
  buttonGroup: css`
    border: 1px solid ${surveyChevronButtonGroupBorder};
    border-radius: .5rem;
  `,
  button: css`
    padding: .75rem 1.25rem .75rem 1.25rem;

    &:not(:last-child) {
      border-right: 1px solid ${surveyChevronButtonGroupBorder};
    }
  `,
  viewSurveyCard: css`
    margin-top: 1.125rem;
  `,
  printButton: css`
    color: ${buttonDefaultColor};
    display: flex;
    align-items: center;
    font-weight: bold;
  `,
  printIcon: css`
    margin-right: 8px;
  `,
  contextMenuContainer: css`
    padding: 6.25px 12.15px;
    border: .0625rem solid ${surveyActionMenu.border};
    color: ${surveyActionMenu.button};
    border-radius: .1875rem;
    z-index: 2000000;
  `,
};

const View = ({
  survey,
  personIndex,
  onNextClick,
  onPreviousClick,
  questions,
  profileImageUrl,
  firstName,
  lastName,
  jobTitle,
  participantQuestionAndAnswers,
  questionTypes,
  menuItems,
  ...props
}) => (
  <div {...props}>
    <SurveyDetailsCard
      survey={survey}
      renderContextMenu={() => (
        <SurveyActionMenu menuItems={menuItems} />
      )}
    />
    <div>
      <SectionHeader text="Participant Results" css={styles.sectionHeader} />
      <div css={styles.personHeader}>
        <div css={styles.personInfoContainer}>
          <SquareAvatar
            imgUrl={profileImageUrl}
            name={`${firstName} ${lastName}`}
            width={50}
            height={50}
            includeInitials
          />
          <div css={styles.personInfo}>
            <div css={styles.name}>{`${firstName} ${lastName}`}</div>
            <div css={styles.jobTitle}>{jobTitle}</div>
          </div>
        </div>
        <div>
          <ButtonGroup css={styles.buttonGroup} disableElevation variant="contained">
            <FlatButton
              css={styles.button}
              onClick={onPreviousClick}
              renderButtonContents={() => (
                <Tooltip content="Previous">
                  <div><FontAwesomeIcon css={styles.icon} icon={faChevronLeft} /></div>
                </Tooltip>
              )}
            />
            <FlatButton
              css={styles.button}
              onClick={onNextClick}
              renderButtonContents={() => (
                <Tooltip content="Next">
                  <div><FontAwesomeIcon css={styles.icon} icon={faChevronRight} /></div>
                </Tooltip>
              )}
            />
          </ButtonGroup>
        </div>
      </div>
      <div css={styles.questionContainer}>
        {participantQuestionAndAnswers.map((participantQuestionAndAnswer) => (
          <ByPersonResult css={styles.viewSurveyCard} questionTypes={questionTypes} participantQuestionAndAnswer={participantQuestionAndAnswer} />
        ))}
      </div>
    </div>
  </div>
);

View.propTypes = {
  survey: SURVEY_SHAPE,
  personIndex: PropTypes.number.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(SURVEY_QUESTION_SHAPE),
  profileImageUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jobTitle: PropTypes.string,
  questionTypes: SURVEY_QUESTION_TYPES_SHAPE.isRequired,
  participantQuestionAndAnswers: SURVEY_BY_PARTICIPANT_ANSWERS_SHAPE.isRequired,
  menuItems: MENU_ITEMS_SHAPE.isRequired,
};

View.defaultProps = {
  survey: {},
  questions: [],
  profileImageUrl: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
};

const ByPerson = ({ survey, personIndex, ...props }) => {
  const people = survey.participants;
  const [person, setPerson] = useState(people[personIndex]);
  const [participantQuestionAndAnswers, setParticipantQuestionAndAnswers] = useState([]);
  const { onPrint } = usePrintView();
  const getSurevyCSV = useGetSurveyCSV();

  const menuItems = [[{
    text: 'Print',
    icon: faPrint,
    onClick: () => {
      onPrint({ location: `surveys/${survey.id}/byPerson` });
    },
  },
  {
    text: 'Export',
    icon: faFileExport,
    onClick: () => {
      getSurevyCSV({
        surveyId: survey.id,
        byQuestion: false,
        byParticipant: true,
        withNullAnswers: true,
      });
    },
  },
  ]];

  useEffect(() => {
    setPerson(people[personIndex]);
  }, [personIndex, people]);

  useEffect(() => {
    setParticipantQuestionAndAnswers(survey.byParticipants.find(
      (participantAndAnswers) => participantAndAnswers.participant.participant.uid === person.uid,
    ).questionsAndAnswers);
  // eslint-disable-next-line
  }, [person]);

  const hookProps = {
    survey,
    participantQuestionAndAnswers,
    menuItems,
    ...person,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ByPerson.propTypes = {
  survey: SURVEY_SHAPE,
  personIndex: PropTypes.number,
};

ByPerson.defaultProps = {
  survey: {},
  personIndex: '',
};

export { View };
export default ByPerson;
