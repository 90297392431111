import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  ReactNode,
  ComponentProps,
  useContext,
  useCallback,
  SyntheticEvent,
} from 'react';

import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';
import LeadrButton from '../LeadrButtons/LeadrButton';
import { LeadrModalContext } from '.';
import ButtonWithConfirmation from '../ButtonWithConfirmation';
import ConfirmationButtons from '../ButtonWithConfirmation/ConfirmationButtons';

const styles = {
  modalHeader: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    marginBottom: '0.5rem',
  }, IS_MOBILE_APP && {
    paddingTop: 'env(safe-area-inset-top)',
  }),
  closeButton: css({
    paddingLeft: '.375rem',
    paddingRight: '.375rem',
    // We want to keep the padding on the button, but not shift it over for it, so this counteracts the padding and keeps it in the right place
    marginRight: '-0.375rem',
    marginLeft: '.75rem',
  }),
  icon: css({
    color: palette.neutrals.gray600,
  }),
};

export interface ViewProps extends ComponentProps<'header'> {
  onClose: (e: SyntheticEvent<HTMLButtonElement>, openConfirmation: (e: SyntheticEvent<HTMLButtonElement>) => void) => void,
  onConfirm: () => void,
  confirmationQuestionText?: string,
  confirmationConfirmText?: string,
  confirmationCancelText?: string,
  children: ReactNode,
}

const View = ({
  onClose,
  onConfirm,
  confirmationQuestionText = 'Close without saving? Your work will be lost.',
  confirmationConfirmText = 'Yes, Close',
  confirmationCancelText = 'No, Cancel',
  children,
  ...props
}: ViewProps): JSX.Element => (
  <header
    css={styles.modalHeader}
    {...props}
  >
    {children}

    <ButtonWithConfirmation
      onClick={onClose}
      renderButton={({ onClick }) => (
        <LeadrButton
          css={styles.closeButton}
          data-test-id="closeModal"
          variant="text"
          textButtonColor={palette.neutrals.gray600}
          onClick={onClick}
        >
          <FontAwesomeIcon size="lg" icon={faTimes} />
        </LeadrButton>
      )}
      renderConfirmationButtons={({
        informationStyles,
        optionStyles,
        popoverButtonStyles,
      }) => (
        <ConfirmationButtons
          onConfirm={onConfirm}
          questionText={confirmationQuestionText}
          confirmText={confirmationConfirmText}
          cancelText={confirmationCancelText}
          informationStyles={informationStyles}
          optionStyles={optionStyles}
          popoverButtonStyles={popoverButtonStyles}
        />
      )}
    />
  </header>
);

interface ModalHeaderProps extends Omit<ViewProps, 'onClose' | 'onConfirm'> {
  shouldConfirmClose?: boolean,
}

const ModalHeader = ({
  shouldConfirmClose,
  ...props
}: ModalHeaderProps): JSX.Element => {
  const { onClose: handleCloseModal } = useContext(LeadrModalContext);

  const onClose = useCallback((e: SyntheticEvent<HTMLButtonElement>, openConfirmation: (e: SyntheticEvent<HTMLButtonElement>) => void) => {
    if (shouldConfirmClose) {
      openConfirmation(e);
    } else {
      handleCloseModal?.({});
    }
  }, [
    handleCloseModal,
    shouldConfirmClose,
  ]);

  const onConfirm = useCallback(() => {
    handleCloseModal?.({});
  }, [
    handleCloseModal,
  ]);

  const hookProps = {
    onClose,
    onConfirm,
    handleCloseModal,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default ModalHeader;
