import * as types from './index';

export const startParallelPollingAction = (method, data, caller) => ({
  type: types.POLLING.START_PARALLEL,
  method,
  data,
  caller,
});

export const stopParallelPollingAction = (caller) => ({
  type: types.POLLING.STOP_PARALLEL,
  caller,
});
