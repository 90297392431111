import { css } from '@emotion/react';

import Card from '~Common/V3/components/Card';
import '~Common/styles/transitions.css';
import PulseSurvey from '~Insights/components/cards/PulseSurveyCard/PulseSurvey';
import { useActivePulseSurvey } from '~Insights/hooks/usePulseSurvey';

const styles = {
  dashboard: css({
    order: 1,
  }),
};

export interface ViewProps {
  hasActiveSurvey: boolean,
}

const View = ({
  hasActiveSurvey,
}: ViewProps): JSX.Element => (
  <>
    {hasActiveSurvey && (
      <Card
        data-test-id="pulseSurveyCard"
        css={styles.dashboard}
        renderContents={() => (
          <PulseSurvey />
        )}
      />
    )}
  </>
);

const PulseSurveyCard = (): JSX.Element => {
  const { survey } = useActivePulseSurvey();

  const hookProps = {
    hasActiveSurvey: !!survey,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, PulseSurveyCard };
export default PulseSurveyCard;
