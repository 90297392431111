import { getApi } from '~Deprecated/services/HttpService';

export const getListAndFlatten = async ({
  url, headers, listFlattener, normalizer,
}) => {
  const response = await getApi(url, headers);

  const flattenedResponse = listFlattener(response, normalizer);

  return {
    ...response,
    ...flattenedResponse,
  };
};
