import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getPersonalityData } from '~Deprecated/selectors/personalities/getPersonalityData';
import PersonalityItem from '~Deprecated/ui/views/Nexus/PersonalityTypes/PersonalityItem';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { editPersonalityTypeDetailsTemplate } from '~Deprecated/ui/containers/DrawerManager/DrawerTemplates/PersonalityTypeDetailsDrawer';
import { personalityDetailsDrawerTemplate } from '~Deprecated/ui/containers/BaseballCard/PersonalityDetailsDrawer';

const PersonalityTypeDetailsComponent = ({ id }) => {
  const dispatch = useDispatch();
  const { label } = useSelector(getPersonalityData)[id];

  const onEdit = () => {
    dispatch(pushDrawerAction({
      drawer: {
        ...editPersonalityTypeDetailsTemplate,
        args: { id },
      },
    }));
  };
  const onPreview = () => {
    dispatch(pushDrawerAction({
      drawer: {
        ...personalityDetailsDrawerTemplate,
        args: {
          id,
        },
      },
    }));
  };

  return (
    <PersonalityItem
      label={label}
      onEdit={onEdit}
      onPreview={onPreview}
    />
  );
};

PersonalityTypeDetailsComponent.propTypes = {
  id: PropTypes.string.isRequired,
};

export default PersonalityTypeDetailsComponent;
