import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { USE_NEXT_MEETINGS_QUERY_KEY } from '~Deprecated/hooks/feedback/useNextMeetings';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { useRef, ReactText } from 'react';
import { CONVERT_MEETING_FACTORY_TYPES_TO_MEETING_TYPES, MeetingFactoryType, MeetingFrequency } from '~Meetings/const/meetingsInterfaces';
import { useMeetingUrl } from '~Common/hooks/useMeetingUrl';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { useIsSyncingACalendarMeeting } from '~Home/stores/useIsSyncingACalendarMeeting';
import { calendarIntQueryKeys } from '../CalendarIntegration/queryKeys';

interface HuddlePayload {
  // Required
  startTimeInMillis: number,
  endTimeInMillis: number,
  title: string,
  frequency: MeetingFrequency,
  meetingType: MeetingFactoryType,
  attendeeIds: string[], // Org User Ids, all attendees (including organizer)
  // Optional, although, Im not sure what happens for some of these if you don't provide them, and there are some cases where things should be required
  location?: string,
  timezone?: string,
  daysOfWeek?: number[],
  nylasEventId?: string,
}

interface CreateHuddleReturn {
  id: string, // meetingFactoryId
  instances: string[], //  its the next two instances of the meeting series
}

const createHuddle = async (huddlePayload: HuddlePayload): Promise<HttpCallReturn<CreateHuddleReturn>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/huddles/series`;

  return postApi<CreateHuddleReturn>(serverUrl, huddlePayload);
};

interface UseCreateHuddleParams {
  onMeetingCreationCallback?: (meetingFactoryId: string) => void,
}

interface UseCreateHuddleReturn {
  createHuddleMutation: UseMutateFunction<HttpCallReturn<CreateHuddleReturn>, unknown, HuddlePayload, void>
  isLoading: boolean,
}

export const useCreateHuddle = ({
  onMeetingCreationCallback,
}: UseCreateHuddleParams = {}): UseCreateHuddleReturn => {
  const toastId = useRef<ReactText | null>(null);
  const organizationId = getOrganizationId();
  const { navigateToMeeting } = useMeetingUrl();

  const {
    setIsSyncingACalendarMeeting,
  } = useIsSyncingACalendarMeeting((state) => ({
    setIsSyncingACalendarMeeting: state.setIsSyncingACalendarMeeting,
  }));

  const mutation = useMutation({
    mutationFn: createHuddle,
    onMutate: () => {
      toastId.current = toast.info('Creating your meeting...', { autoClose: false });
      setIsSyncingACalendarMeeting(true);
    },
    onError: () => {
      toast.update(toastId.current, {
        render: MeetingsErrorToast(),
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables) => {
      toast.update(toastId.current, {
        render: 'Your meeting has been created. Navigating to your meeting...',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles'] });
      void queryClient.invalidateQueries({ queryKey: [organizationId, 'timeline'] });
      void queryClient.invalidateQueries({ queryKey: USE_NEXT_MEETINGS_QUERY_KEY });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId() ?? '') });
      void queryClient.invalidateQueries({ queryKey: calendarIntQueryKeys.all });

      onMeetingCreationCallback?.(data?.response?.id);

      const soonestMeetingInstanceId = data?.response?.instances[0];

      navigateToMeeting({
        meetingType: CONVERT_MEETING_FACTORY_TYPES_TO_MEETING_TYPES[variables.meetingType],
        meetingInstanceId: soonestMeetingInstanceId,
      });
    },

    onSettled: () => {
      setIsSyncingACalendarMeeting(false);
    },
  });

  return {
    createHuddleMutation: mutation.mutate,
    isLoading: mutation.isPending,
  };
};
