import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Menu, MenuProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ActionMenuItem } from '~ActionItems/const/interfaces';
import { palette } from '~Common/styles/colors';

const styles = {
  menu: css({
    display: 'flex',
    '.MuiMenu-list': {
      width: '16rem',
      padding: '0',
    },
    '.MuiPaper-root': {
      width: '16.125rem',
    },
    '.MuiDivider-root': {
      margin: '0 !important',
    },
  }),
  menuContainer: css({
    width: '100%',
    alignSelf: 'center',
    margin: '0 auto',
  }),
  menuItem: css({
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    fontSize: '.875rem',
  }),
  menuText: (isDisabled: boolean | undefined) => css({
    color: isDisabled ? palette.neutrals.gray700 : palette.brand.indigo,
  }),
  menuIcon: css({
    color: palette.brand.indigo,
  }),
  divider: css({
    color: palette.neutrals.gray500,
  }),
};

interface ActionMenuProps extends Omit<MenuProps, 'open'> {
  menuItems: ActionMenuItem[],
  anchorEle: HTMLElement | null,
  onClose: () => void,
}

const ActionMenu = ({
  menuItems, anchorEle, onClose, ...props
}: ActionMenuProps): JSX.Element => (
  <Menu
    css={styles.menu}
    anchorEl={anchorEle}
    keepMounted
    onClose={onClose}
    open={!!anchorEle}
    {...props}
  >
    {menuItems.map((item, index) => (
      <div css={styles.menuContainer}>
        <MenuItem css={styles.menuItem} onClick={item.onClick} disabled={item.disabled}>
          <div css={styles.menuText(item.disabled)}>
            {item.text}
          </div>
          <div>
            <FontAwesomeIcon
              icon={item.icon}
              css={styles.menuIcon}
            />
          </div>
        </MenuItem>
        {index < menuItems.length - 1 && menuItems.length > 1 && (
          <Divider />
        )}
      </div>
    ))}
  </Menu>
);

export default ActionMenu;
