import { useMemo } from 'react';
import { flatten } from 'flat';

/**
 *
 * @param {{ id: {...}}} data
 * @param {''} groupKey - key used to group by. If nested use flat format 'level1.level2.level3.id`
 * @returns
 */
export const useGroupings = ({
  ids, data, groupKey, groupKeyOrder = [], comparator,
}) => (
  useMemo(() => {
    if (groupKey && ids?.length > 0) {
      const groupedIds = {};
      const groupedData = {};
      ids.forEach((id) => {
        const item = flatten(data[id]);

        if (!(`${item[groupKey]}` in groupedIds)) {
          groupedIds[item[groupKey]] = [];
          groupedData[item[groupKey]] = [];
        }

        groupedIds[item[groupKey]].push(id);
        groupedData[item[groupKey]][id] = item;
      });

      const sortedGroupIds = {};

      if (groupKeyOrder.length) {
        groupKeyOrder.forEach((gKey) => {
          if (groupedIds[gKey]?.length) {
            sortedGroupIds[gKey] = groupedIds[gKey];
          }
        });
      } else {
        let sortedKeys;
        if (comparator) {
          sortedKeys = Object.keys(groupedIds).sort(comparator);
        } else {
          sortedKeys = Object.keys(groupedIds).sort();
        }

        sortedKeys.forEach((k) => {
          sortedGroupIds[k] = groupedIds[k];
        });
      }

      return {
        groupedIds: sortedGroupIds,
        groupedData,
      };
    }

    return {
      groupedIds: null,
      groupedData: null,
    };
  }, [groupKey, ids, groupKeyOrder, data, comparator])
);
