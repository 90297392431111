import { ComponentProps, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
  faTimes,
} from '@fortawesome/pro-thin-svg-icons';

import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { lighten } from 'polished';
import { attachmentErrorTypeToString } from '~Meetings/utils/attachmentErrorTypeToString';

const styles = {
  attachmentError: css({
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: '0.5rem',
    padding: '0.75rem 0.625rem',
    marginBottom: '0.75rem',
    backgroundColor: lighten(0.425, palette.brand.red),
  }),
  text: css({
    color: palette.brand.red,
    lineHeight: '0.875rem',
    fontWeight: 600,
    flex: 1,
  }),
  icon: css({
    color: palette.brand.red,
    marginRight: '0.625rem',
  }),
  closeButton: css({
    padding: 0,
    fontSize: '1rem',
    lineHeight: '1rem',
  }),
};

export enum AttachmentErrorType {
  MaxAttachments = 'MaxFiles',
  MaxFileSize = 'MaxFileSize',
  FileName = 'FileName',
  FileType = 'FileType',
}

export interface ViewProps extends ComponentProps<'div'> {
  error: string,
  fileNames?: string[]
  onCloseClick: () => void,
}

const View = ({
  error,
  fileNames,
  onCloseClick,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.attachmentError}
    {...props}
  >
    <FontAwesomeIcon
      css={styles.icon}
      icon={faTriangleExclamation}
    />

    <div css={styles.text}>
      {error}

      {!!fileNames?.length && (
        <ul>
          {fileNames.map((fileName) => (
            <li>
              {`"${fileName}"`}
            </li>
          ))}
        </ul>
      )}

    </div>

    <LeadrButton
      css={styles.closeButton}
      textButtonColor={palette.brand.red}
      variant="text"
      onClick={onCloseClick}
      data-test-id="attachmentsErrorClose"
    >
      <FontAwesomeIcon
        icon={faTimes}
      />
    </LeadrButton>
  </div>
);

interface AttachmentErrorProps extends ComponentProps<'div'> {
  errorType: AttachmentErrorType,
  fileNames?: string[],
  onClose: (errorType: AttachmentErrorType) => void,
}

const AttachmentError = ({
  errorType,
  fileNames,
  onClose,
  ...props
}: AttachmentErrorProps): JSX.Element => {
  const onCloseClick = useCallback(() => {
    onClose(errorType);
  }, [
    onClose,
    errorType,
  ]);

  const error = useMemo(() => (
    attachmentErrorTypeToString(errorType)
  ), [
    errorType,
  ]);

  const hookProps = {
    error,
    fileNames,
    onCloseClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default AttachmentError;
