import { css } from '@emotion/react';
import { ActionItem as ActionItemType, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { palette } from '~Common/styles/colors';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import Footer from './Footer';

const styles = {
  actionItem: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  leftSide: css({
    display: 'flex',
    alignItems: 'center',
  }),
  completedCircle: css({
    cursor: 'not-allowed',
    alignSelf: 'flex-start',
  }),
  openIcon: css({
    color: palette.neutrals.gray400,
  }),
  completedIcon: css({
    color: palette.brand.indigo,
  }),
  textContainer: css({
    marginLeft: '.75rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  }),
  text: css({
    fontSize: '1rem',
    color: palette.neutrals.gray800,
  }),
  rightSide: css({}),
};

interface ViewProps {
  isComplete: boolean,
  text: string,
  creator: PersonDisplayInformation,
  status: NewActionItemStatus,
  commentCount: number,
  isRecurring: boolean,
  dueDateInMillis: number,
  completedInMillis?: number,
}

const View = ({
  isComplete,
  text,
  creator,
  status,
  commentCount,
  isRecurring,
  completedInMillis,
  dueDateInMillis,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.actionItem}
    {...props}
  >
    <div css={styles.leftSide}>
      <div css={styles.completedCircle}>
        {isComplete && (
          <FontAwesomeIcon
            icon={faCircleCheck}
            css={styles.completedIcon}
            size="lg"
          />
        )}
        {!isComplete && (
          <FontAwesomeIcon
            icon={faCircle}
            css={styles.openIcon}
            size="lg"
          />
        )}
      </div>
      <div css={styles.textContainer}>
        <span css={styles.text}>{text}</span>
        <Footer
          status={status}
          commentCount={commentCount}
          isRecurring={isRecurring}
          dueDateInMillis={dueDateInMillis}
          completedInMillis={completedInMillis}
        />
      </div>
    </div>
    <div css={styles.rightSide}>
      <NewBaseAvatar
        profileImageUrl={creator.profileImageUrl}
        name={`${creator.firstName} ${creator.lastName}`}
        avatarSize={30}
      />
    </div>
  </div>
);

interface ActionItemProps {
  actionItem: ActionItemType,
}

const ActionItem = ({
  actionItem,
  ...props
}: ActionItemProps): JSX.Element => {
  const { person: creator } = useGetPersonById({ orgUserId: actionItem.assigneeId });

  const hookProps = {
    isComplete: actionItem.status === NewActionItemStatus.Completed,
    text: actionItem.text,
    creator: creator as PersonDisplayInformation || {},
    status: actionItem.status,
    commentCount: actionItem.commentCount,
    isRecurring: actionItem.isRecurring,
    completedInMillis: actionItem.completedInMillis,
    dueDateInMillis: actionItem.dueDateInMillis,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItem;
