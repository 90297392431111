import { css } from '@emotion/react';
import { useCallback } from 'react';

import { palette } from '~Common/styles/colors';
import ActionMenu, { ActionMenuProps, MenuItem, useActionMenu } from '~Meetings/components/shared/ActionMenu';

const styles = {
  container: css({}),
  overflowMenuButton: css({
    marginLeft: '1.125rem',
    padding: '10px 18px',
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    borderRadius: '.1875rem',
  }),
  icon: css({
    height: '1.125rem',
    color: palette.neutrals.gray700,
  }),
  contextMenuContainer: css({
    border: `0.0625rem solid ${palette.neutrals.gray200}`,
    color: palette.neutrals.gray500,
    borderRadius: '.1875rem',
    width: '1.75rem',
    height: '1.75rem',
    fontSize: '1.125rem',
  }),
};

interface ViewProps {
  doOpen: (event: React.MouseEvent<HTMLElement>) => void,
  onClickCallback: (event: React.MouseEvent<HTMLElement>) => void,
  actionMenuProps: Omit<ActionMenuProps, 'menuItems'>,
  menuItems: MenuItem[][],
  renderOverflowMenuButton: (doOpen: (event: React.MouseEvent<HTMLElement>) => void) => JSX.Element,
}

const View = ({
  doOpen,
  menuItems,
  actionMenuProps,
  onClickCallback,
  renderOverflowMenuButton,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    {renderOverflowMenuButton(doOpen)}
    <ActionMenu
      menuItems={menuItems}
      onClick={onClickCallback}
      {...actionMenuProps}
    />
  </div>
);

interface OverflowMenuProps extends Omit<ViewProps, 'doOpen' | 'onClickCallback' | 'actionMenuProps'> {
  actionMenuProps?: Omit<ActionMenuProps, 'menuItems' | 'anchorEle' | 'onClose'>,
}

const OverflowMenu = ({ actionMenuProps, ...props }: OverflowMenuProps): JSX.Element => {
  const { doOpen, ...useActionMenuProps } = useActionMenu();

  const onClickCallback = useCallback((event: React.MouseEvent<HTMLElement>) => {
    // I dont think these will break anything
    event.stopPropagation();
    event.preventDefault();
    useActionMenuProps.onClose();
  }, [useActionMenuProps]);

  const hookProps = {
    doOpen,
    actionMenuProps: { ...useActionMenuProps, ...(actionMenuProps && { ...actionMenuProps }) },
    onClickCallback,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default OverflowMenu;
