import { useGenericStoreAndLocalStorage } from '~Common/hooks/useGenericStoreAndLocalStorage';

export const useLearningSearch = (): [string, (search: string) => void] => {
  const [value, setValue] = useGenericStoreAndLocalStorage<string>({
    localStorageKey: 'learningSearch',
    defaultValue: '',
    genericStoreProps: {
      module: 'leadr',
      workflow: 'learning',
      feature: 'learning-search',
    },
  });

  return [
    value,
    setValue,
  ];
};
