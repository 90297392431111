import { css } from '@emotion/react';
import { ButtonGroup, Menu, MenuItem } from '@mui/material';
import {
  ComponentProps,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from 'react';

import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { palette } from '~Common/styles/colors';
import { AgendaType } from '@leadr-hr/types';
import Tooltip from '~Common/components/Tooltip';

const styles = {
  mainButton: css({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }),
  dropdownButton: css({
    borderLeft: 'none',
    paddingLeft: '.375rem',
    paddingRight: '.3125rem',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }),
  menu: css({
    '.MuiList-root': {
      padding: 0,
    },
  }),
  menuItem: css({
    color: palette.neutrals.gray900,
    fontWeight: 500,
    fontSize: '.875rem',
    paddingTop: '0 1rem',
  }),
  buttonWrapper: css({
    display: 'flex',
  }),
};

interface ViewProps {
  menuButtonRef: MutableRefObject<HTMLButtonElement | null>,
  onMenuButtonClick: () => void,
  onCloseMenu: () => void,
  isMenuOpen: boolean,
  onClick: (agendaType: AgendaType) => void,
  hasMaxAgendaLimit?: boolean,
  hasMaxSections?: boolean,
  hasMaxTopics?: boolean,
  maxTopics: number,
  maxSections: number,
}

const View = ({
  menuButtonRef,
  onMenuButtonClick,
  onCloseMenu,
  isMenuOpen,
  onClick,
  hasMaxAgendaLimit = false,
  hasMaxSections = false,
  hasMaxTopics = false,
  maxTopics,
  maxSections,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <Tooltip
      content={hasMaxAgendaLimit ? `The maximum allowed number of topics (${maxTopics}) and sections (${maxSections}) has been reached.` : ''}
    >
      <ButtonGroup disabled={hasMaxAgendaLimit}>
        <Tooltip content={!hasMaxAgendaLimit && hasMaxTopics ? `The maximum allowed number of topics (${maxTopics}) has been reached.` : ''}>
          <div css={styles.buttonWrapper}>
            <LeadrButton
              variant="ghost"
              color="primary"
              onClick={() => onClick(AgendaType.AgendaTopic)}
              size="small"
              css={styles.mainButton}
              disabled={hasMaxTopics}
              data-test-id="meetingsAddAgendaTopic"
            >
              + Add Topic
            </LeadrButton>
          </div>
        </Tooltip>

        <Tooltip content={!hasMaxAgendaLimit && hasMaxSections ? `The maximum allowed number of sections (${maxSections}) has been reached.` : ''}>
          <div css={styles.buttonWrapper}>
            <LeadrButton
              data-test-id="meetingsShowMoreCreatOptions"
              variant="ghost"
              color="primary"
              size="small"
              ref={menuButtonRef}
              onClick={onMenuButtonClick}
              css={styles.dropdownButton}
              disabled={hasMaxSections}
            >
              <FontAwesomeIcon
                icon={faCaretDown}
              />
            </LeadrButton>
          </div>
        </Tooltip>
      </ButtonGroup>
    </Tooltip>

    <Menu
      anchorEl={menuButtonRef.current}
      open={isMenuOpen}
      onClose={onCloseMenu}
      css={styles.menu}
    >
      <MenuItem
        data-test-id="meetingsAddAgendaSection"
        onClick={() => onClick(AgendaType.AgendaSection)}
        value={AgendaType.AgendaSection}
        css={styles.menuItem}
        disabled={hasMaxSections}
      >
        Add Section
      </MenuItem>
    </Menu>
  </div>
);

export interface AddAgendaButtonProps extends Omit<ComponentProps<'div'>, 'onClick'> {
  onClick?: (agendaType: AgendaType) => void
  hasMaxSections?: boolean,
  hasMaxTopics?: boolean,
  maxTopics: number,
  maxSections: number,
}

const AddAgendaButton = ({
  onClick: onClickCallback,
  hasMaxSections,
  hasMaxTopics,
  ...props
}: AddAgendaButtonProps): JSX.Element => {
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const onClick = useCallback((agendaType: AgendaType) => {
    onClickCallback?.(agendaType);
    onCloseMenu();
  }, [onClickCallback, onCloseMenu]);

  const onMenuButtonClick = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const hookProps = {
    menuButtonRef,
    onMenuButtonClick,
    onCloseMenu,
    isMenuOpen,
    onClick,
    hasMaxAgendaLimit: hasMaxSections && hasMaxTopics,
    hasMaxSections,
    hasMaxTopics,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export { View };
export default AddAgendaButton;
