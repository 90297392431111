import { useQuery } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import {
  AllReviews, ReviewCycleDetail, ReviewCycleStatusEnum,
} from '~Reviews/V2/Const/types';

interface getCycleByIdProps {
  queryKey: string[],
}

const getOrgCycles = ({ queryKey }: getCycleByIdProps): Promise<HttpCallReturn<ReviewCycleDetail[]>> => {
  const [orgId, , version] = queryKey;

  const serverUrl = {
    host: getHost(hosts.reviewCycles, version),
    uri: `/organizations/${orgId}/reviews`,
  };

  return getApi<ReviewCycleDetail[]>(serverUrl, {});
};

interface useGetReviewsProps {
  queryKey?: string[],
}

export interface useGetReviewsReturnProps {
  data: ReviewCycleDetail[] | undefined,
  statusSortedReviews: AllReviews,
  isLoading: boolean,
}

export const useGetReviews = ({ queryKey = [getOrganizationId() ?? '', 'reviews'] }: useGetReviewsProps): useGetReviewsReturnProps => {
  const version = '2.5';
  const result = useQuery({
    queryKey: [...queryKey, version],
    queryFn: getOrgCycles,
  });

  const statusSortedReviews: AllReviews = {
    Active: [],
    Completed: [],
  };
  const reviews = result?.data?.response;
  if (reviews) {
    reviews.forEach((review: ReviewCycleDetail) => {
      // Some of the old reviews were able to be saved without titles? - This should stop this
      if (review.isDeleted !== true && review.name && review.name !== '') {
        if (review.statusEnum !== ReviewCycleStatusEnum.Finished) {
          statusSortedReviews.Active.push(review);
        } else if (review.statusEnum === ReviewCycleStatusEnum.Finished) {
          statusSortedReviews.Completed.push(review);
        }
      }
    });
  }

  return {
    isLoading: result?.isLoading,
    data: result?.data?.response as ReviewCycleDetail[],
    statusSortedReviews,
  };
};
