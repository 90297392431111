import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Tooltip from '~Common/components/Tooltip';
import { getAvatarColor } from '~Common/styles/colors';
import { makeInitials } from '~Deprecated/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

const style = (width, height) => css`
  text-align: center;
  width: ${width}px;
  height: ${height}px;
  object-fit: cover;

  & .initials {
    font-size: ${height / 2}px;
    width: 100%;
    height: ${height}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const styles = {
  defaultAvatar: ({ width, height }) => css({
    width: `${width}px`,
    height: `${height}px`,
  }),
};

const Avatar = ({
  imgUrl,
  name,
  width,
  height,
  className = '',
  avatarTooltip = '',
  isDeactivated = false,
  ...props
}) => {
  const [isValid, setValid] = useState(true);

  if (isDeactivated) {
    return (
      <Tooltip content={avatarTooltip}>
        <FontAwesomeIcon css={styles.defaultAvatar({ width, height })} icon={faUser} />
      </Tooltip>
    );
  }

  if (imgUrl && isValid) {
    const imgTester = new Image();
    imgTester.onerror = () => {
      setValid(false);
    };
    imgTester.src = imgUrl;

    return (
      <Tooltip content={avatarTooltip}>
        <img
          css={style(width, height)}
          src={imgUrl}
          alt={name}
          className={className}
          {...props}
        />
      </Tooltip>
    );
  }

  const initials = makeInitials({ name });

  return (
    <Tooltip content={avatarTooltip}>
      <div
        css={[
          style(width, height),
          getAvatarColor(initials),
        ]}
        className={className}
        {...props}
      >
        <div className="initials">
          { initials }
        </div>
      </div>
    </Tooltip>
  );
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  imgUrl: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
  avatarTooltip: PropTypes.string,
  isDeactivated: PropTypes.bool,
};

Avatar.defaultProps = {
  imgUrl: '',
  className: '',
  avatarTooltip: null,
  isDeactivated: false,
};

export const AVATAR_CONTEXT = {
  TINYPERSON: {
    height: 28,
    width: 28,
  },
  CARD: {
    height: 40,
    width: 40,
  },
  DRAWER: {
    height: 48,
    width: 48,
  },
  BASEBALL: {
    height: 240,
    width: 297,
  },
  PREVIEW: {
    height: 150,
    width: 150,
  },
};

export default Avatar;
