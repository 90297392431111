import { css } from '@emotion/react';
import Avatar, { AVATAR_CONTEXT } from './index';

const style = css`
  width: 100%;
`;

const BaseballCardAvatar = ({
  ...props
}) => (
  <Avatar
    css={style}
    {...props}
    {...AVATAR_CONTEXT.BASEBALL}
  />
);

export default BaseballCardAvatar;
