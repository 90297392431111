import moment from 'moment';

type DayString = string | number | Date;

export const getFormattedMeetingDateString = (dayString: DayString): string => {
  let formattedDayString = '';
  const day = moment(dayString);

  if (day.isSame(moment(), 'day')) {
    formattedDayString = 'Today';
  } else if (day.isSame(moment().add(1, 'day'), 'day')) {
    formattedDayString = 'Tomorrow';
  } else if (day.isSame(moment(), 'year')) {
    formattedDayString = day.format('MMM DD');
  } else {
    formattedDayString = day.format('MMM DD, yyyy');
  }

  return formattedDayString;
};

export const useGetFormattedMeetingDateString = (): (dayString: DayString) => string => getFormattedMeetingDateString;
