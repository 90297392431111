import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  container: css({
    backgroundColor: palette.neutrals.white,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    padding: '1.5rem 1rem',
  }),
  bar: css({
    backgroundColor: palette.neutrals.gray100,
    height: '0.625rem',
  }),
  bar1: css({
    width: '14.25rem',
  }),
  bar2: css({
    width: '8.5rem',
  }),
  bar3: css({
    width: '11.75rem',
  }),
};

export const HiddenMeetingNotes = (): JSX.Element => (
  <div css={styles.container}>
    <div css={[styles.bar, styles.bar1]} />
    <div css={[styles.bar, styles.bar2]} />
    <div css={[styles.bar, styles.bar3]} />
  </div>
);
