import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { ComponentProps } from 'react';
import { ConstructMeetingUrlParams, ConstructMeetingUrlReturn, useMeetingUrl } from '~Common/hooks/useMeetingUrl';
import { MyScheduleDay } from '~Home/const/interfaces';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { Link } from 'react-router-dom';
import ConditionalWrapper from '~Common/components/ConditionalWrapper';
import moment from 'moment';
import MyScheduleDayText from '~Meetings/components/drawers/ReviewUnsyncedMeetingsDrawer/tabs/ReviewUnsyncedMeetingsDrawerTabBody/MyScheduleDayText';
import MeetingCard from './MeetingCard';

const styles = {
  daySection: css({}),
  myScheduleDayText: css({
    marginBottom: '.4375rem',
  }),
  meetingCard: (isOnlyInExternalCalendar: boolean) => css({
    marginBottom: '0.5rem',
  }, isOnlyInExternalCalendar && ({
    backgroundColor: palette.neutrals.gray100,
    border: `1px solid ${palette.neutrals.gray300}`,
  })),
  noMeetingsText: css({
    color: palette.neutrals.gray700,
  }),
  link: css({
    textDecoration: 'none !important',
  }),
};

const skeletonStyles = {
  meetingCard: css({
    maxWidth: '100%',
    height: '5rem',
    borderRadius: '.25rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  myScheduleDay: MyScheduleDay,
  constructMeetingUrl: (constructMeetingUrlParams: ConstructMeetingUrlParams) => ConstructMeetingUrlReturn,
  hasTeams?: boolean,
}

const View = ({
  myScheduleDay,
  constructMeetingUrl,
  hasTeams,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.daySection} {...props}>
    <MyScheduleDayText css={styles.myScheduleDayText} dayString={myScheduleDay.day} />
    {myScheduleDay.meetings.length === 0 && (
      <div css={styles.noMeetingsText}>No meetings scheduled</div>
    )}
    {myScheduleDay.meetings.length > 0 && (
      <>
        {myScheduleDay.meetings.map((meeting) => (
          <ConditionalWrapper
            key={meeting.meetingInstanceId}
            renderWrapperCondition={!meeting.isOnlyInExternalCalendar}
            renderWrapper={(renderChildren) => (
              <Link
                css={styles.link}
                to={constructMeetingUrl({
                  meetingInstanceId: meeting.meetingInstanceId,
                  meetingType: meeting.meetingType,
                  teamId: meeting.teamId,
                }).navigatePath}
              >
                {renderChildren()}
              </Link>
            )}
            renderContents={() => (
              <MeetingCard
                meeting={meeting}
                hasTeams={hasTeams}
              />
            )}
          />
        ))}
      </>
    )}
  </div>
);

interface SkeletonProps extends ComponentProps<'div'> {
  mockDayString: string,
}

const SkeletonView = ({
  mockDayString,
  ...props
}: SkeletonProps): JSX.Element => (
  <div css={styles.daySection} {...props}>
    <MyScheduleDayText css={styles.myScheduleDayText} dayString={mockDayString} />
    <SkeletonLoader
      css={skeletonStyles.meetingCard}
      variant="rectangular"
      renderComponent={() => <></>}
    />
  </div>
);

const Skeleton = ({ ...props }): JSX.Element => {
  const today = moment().toLocaleString();
  const tomorrow = moment().add(1, 'days').toLocaleString();

  const skeletonLoaderDayStrings = [today, tomorrow];

  return (
    <>
      {skeletonLoaderDayStrings.map((dayString) => (
        <SkeletonView
          key={dayString}
          mockDayString={dayString}
          {...props}
        />
      ))}
    </>
  );
};

type DaySectionProps = Omit<ViewProps, 'getOverflowMenuItems' | 'formattedDayString' | 'constructMeetingUrl'>

const DaySection = ({
  myScheduleDay,
  ...props
}: DaySectionProps): JSX.Element => {
  const {
    constructMeetingUrl,
  } = useMeetingUrl();

  const hookProps = {
    myScheduleDay,
    constructMeetingUrl,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { Skeleton as DaySectionSkeleton };

export default DaySection;
