import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { BaseDataOptions, teamsQueryKeys } from './queryKeys';
import { TeamUserRow } from '../const/types';

interface GetTeamDetailsParams extends BaseDataOptions {
  orgId: string,
  teamId: string,
}

interface GetTeamUsersReturn {
  members: TeamUserRow[],
  totalMembers: number,
}

function getTeamUsers({
  orgId,
  teamId,
  page,
  count,
}: GetTeamDetailsParams): Promise<HttpCallReturn<GetTeamUsersReturn>> {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    count: count.toString(),
  });

  const serverUrl = {
    // Host doesn't matter, but need this format to use the V2 endpoint
    host: getHost(hosts.reviewCycles, '2'),
    uri: `/organizations/${orgId}/teams/${teamId}/members/?${queryParams.toString()}`,
  };

  return getApi<GetTeamUsersReturn>(serverUrl);
}

interface UseGetTeamDetailsParams extends BaseDataOptions {
  teamId: string,
}

export function useGetTeamUsers({
  teamId,
  page,
  count,
}: UseGetTeamDetailsParams): UseQueryResult<HttpCallReturn<GetTeamUsersReturn>> {
  const orgId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: teamsQueryKeys.memberList({
      orgId,
      teamId,
      dataOptions: {
        page,
        count,
      },
    }),
    queryFn: () => getTeamUsers({
      orgId,
      teamId,
      page,
      count,
    }),
  });
}
