import { Route, Switch } from 'react-router-dom';
import { BASE_ROUTE, NEXUS_BASE_ROUTE } from '~Common/const/routes';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { getUserId } from '~Common/utils/localStorage';
import DrawerManager from '~Deprecated/ui/views/DrawerManager';
import LaunchDarkly from '~Deprecated/ui/containers/Lib/LaunchDarkly';
import { useHasNexus } from '~Common/hooks/permissions/useHasNexus';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import { useEffect } from 'react';
import Org from '../Org';
import Nexus from '../Nexus';

interface ViewProps {
  hasUserInfo: boolean,
  hasNexus: boolean,
}

const View = ({
  hasUserInfo,
  hasNexus,
}: ViewProps): JSX.Element => (
  <>
    <LaunchDarkly />
    <DrawerManager />
    <Switch>
      {hasNexus && (
        <Route
          path={NEXUS_BASE_ROUTE}
          component={Nexus}
        />
      )}
      {hasUserInfo && (
        <Route
          path={BASE_ROUTE}
          component={Org}
        />
      )}
    </Switch>
  </>
);

const PermissionsChildren = (): JSX.Element => {
  const { user } = useUserProfile(getUserId() ?? '');
  const hasNexus = useHasNexus();
  const { hasTrialExpired } = useIsOrganizationInTrial();

  useEffect(() => {
    if (hasTrialExpired) {
      window.location.replace('https://insights.leadr.com/extend-leadr-trial ');
    }
  }, [hasTrialExpired]);

  const hookProps = {
    hasUserInfo: !!user?.organizationUserId,
    hasNexus,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default PermissionsChildren;
