import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { SCORE_NAMES_TO_COLORS, SCORE_NAMES, SCORE_NAME_PROPTYPES } from '~Common/const/executiveInsights';

const style = css`
  border-radius: 100px;
`;

function InsightGraphSlice({
  scoreName,
  parentHeight,
  percentage,
}) {
  const dynamicStyle = css`
    background-color: ${SCORE_NAMES_TO_COLORS[scoreName]};
    height: ${parentHeight * 0.6}px;
    flex-basis: ${percentage};
  `;

  return (
    <div
      css={[
        style,
        dynamicStyle,
      ]}
    />
  );
}

InsightGraphSlice.propTypes = {
  scoreName: SCORE_NAME_PROPTYPES,
  parentHeight: PropTypes.number.isRequired,
  percentage: PropTypes.string.isRequired,
};

InsightGraphSlice.defaultProps = {
  scoreName: SCORE_NAMES.GOOD,
};

export default InsightGraphSlice;
