import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { TopicShape } from '../Const/types';

interface updateTopicForCycleProps {
  cycleId: string,
  topic: Pick<TopicShape, 'text' | 'topicTargetEnum' | 'typeConfig' | 'typeEnum'>,
  topicId: string,
}

const updateTopicForCycle = ({ cycleId, topic, topicId }: updateTopicForCycleProps): Promise<HttpCallReturn<TopicShape>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${cycleId}/topics/${topicId}`,
  };

  return patchApi<TopicShape>(serverUrl, topic, {});
};

// eslint-disable-next-line max-len
export const useUpdateTopicForCycle = (cycleId: string): UseMutateFunction<HttpCallReturn<TopicShape>, unknown, updateTopicForCycleProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const queryKey = [getOrganizationId(), 'reviewCycles'];

  const mutation = useMutation({
    mutationFn: updateTopicForCycle,
    onMutate: () => {
      toastId.current = toast.info('Updating question...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error updating the question. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully updated the question.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: [...queryKey, cycleId] });
    },
  });

  return mutation.mutate;
};
