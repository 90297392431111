import { AgendaTemplates, AgendaType } from '@leadr-hr/types';
import { useCallback, useEffect } from 'react';
import { create } from 'zustand';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import useGetAgendas, { AgendaItem } from '~Meetings/hooks/v2/useGetAgendas';
import {
  AgendaTemplateType,
  GetTemplateTopic,
} from '~Meetings/components/topic-suggestions/const/types';

const convertAgendaTypeToTemplateType = (agendaType: AgendaType): AgendaTemplateType => {
  switch (agendaType) {
    case AgendaType.AgendaTopic:
      return AgendaTemplates.AgendaTemplateType.AgendaTopic;
    case AgendaType.AgendaSection:
      return AgendaTemplates.AgendaTemplateType.AgendaSection;
    default:
      return AgendaTemplates.AgendaTemplateType.AgendaTopic;
  }
};

interface GetIsAgendaTopicInMeetingParams {
  text: string,
  topicType: AgendaTemplateType,
  huddleId: string,
}
interface UseIsAgendaTopicAddedToMeetingStoreState {
  meetingAgendaTexts: Record<string, Record<string, { text: string, type: AgendaTemplateType }>>,
  getHasAgendaTextsByHuddleId: (huddleId: string) => boolean,
  fillAgendaTextsByHuddleId: (huddleId: string, agendas: AgendaItem[]) => void,
  getIsAgendaTopicInMeeting: (params: GetIsAgendaTopicInMeetingParams) => boolean,
}

const useIsAgendaTopicInMeeting = create<UseIsAgendaTopicAddedToMeetingStoreState>((set, get) => ({
  meetingAgendaTexts: {},
  getHasAgendaTextsByHuddleId: (huddleId: string) => !!get().meetingAgendaTexts[huddleId],
  fillAgendaTextsByHuddleId: (huddleId: string, agendas: AgendaItem[]) => {
    const tempAgendaItemTexts: Record<string, { text: string, type: AgendaTemplateType }> = {};

    agendas.forEach((agendaItem) => {
      if (agendaItem.type === AgendaType.AgendaSection) {
        agendaItem.children.forEach((child) => {
          tempAgendaItemTexts[child.text] = { text: child.text, type: convertAgendaTypeToTemplateType(child.type) };
        });
      }

      tempAgendaItemTexts[agendaItem.text] = { text: agendaItem.text, type: convertAgendaTypeToTemplateType(agendaItem.type) };
    });

    return set((state) => ({
      meetingAgendaTexts: {
        ...state.meetingAgendaTexts,
        [huddleId]: tempAgendaItemTexts,
      },
    }));
  },
  getIsAgendaTopicInMeeting: ({ text, topicType, huddleId }: GetIsAgendaTopicInMeetingParams) => {
    const agendaTexts = get().meetingAgendaTexts[huddleId];
    if (!agendaTexts) return false;
    return agendaTexts[text] && agendaTexts[text].type === topicType;
  },
}));

interface UseIsTopicAddedToMeetingReturn {
  getIsTopicInMeeting: (text: string, topicType: AgendaTemplateType) => boolean,
  getIsAnyTemplateTopicInMeeting: (templateTopics: GetTemplateTopic[]) => boolean,
}

export const useIsTopicAddedToMeeting = (huddleId: string): UseIsTopicAddedToMeetingReturn => {
  const { data } = useGetAgendas({ huddleId });

  const {
    getIsAgendaTopicInMeeting,
    getHasAgendaTextsByHuddleId,
    fillAgendaTextsByHuddleId,
  } = useIsAgendaTopicInMeeting((state) => ({
    getIsAgendaTopicInMeeting: state.getIsAgendaTopicInMeeting,
    getHasAgendaTextsByHuddleId: state.getHasAgendaTextsByHuddleId,
    fillAgendaTextsByHuddleId: state.fillAgendaTextsByHuddleId,
  }));

  const previousData = usePrevious(data);

  useEffect(() => {
    // Update the agenda texts if there is no current agenda texts for the huddle id or the agenda items in the huddle change
    if ((data && !getHasAgendaTextsByHuddleId(huddleId)) || data !== previousData) {
      fillAgendaTextsByHuddleId(huddleId, data.response.agendas);
    }
  }, [data, fillAgendaTextsByHuddleId, getHasAgendaTextsByHuddleId, huddleId, previousData]);

  const getIsTopicInMeeting = useCallback((text: string, topicType: AgendaTemplateType) => getIsAgendaTopicInMeeting({
    text,
    topicType,
    huddleId,
  }), [getIsAgendaTopicInMeeting, huddleId]);

  const getIsAnyTemplateTopicInMeeting = useCallback((templateTopics: GetTemplateTopic[]) => templateTopics.some(
    (templateTopic) => getIsTopicInMeeting(templateTopic.text, templateTopic.type),
  ), [getIsTopicInMeeting]);

  return {
    getIsTopicInMeeting,
    getIsAnyTemplateTopicInMeeting,
  };
};
