import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import DrawerButton from '~Deprecated/ui/components/Buttons/DrawerButton';
import { BUTTON_VARIANTS } from '~Common/const/buttonVariants';
import DrawerHeader from '~Deprecated/ui/components/DrawerManager/DrawerHeader';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';
import { noop } from '~Deprecated/utils';
import { SIZE_OPTIONS } from '~Deprecated/ui/components/Inputs/DrawerTextArea';
import DrawerInput from '~Deprecated/ui/components/Inputs/DrawerInput';
import { PERSONALITY_DETAILS_SHAPE } from '~Common/const/proptypes';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';

const EditPersonalityTypeDetailsDrawer = ({
  popDrawer,
  details,
  editPersonalityDetailsById,
  personalityId,
  hasUpdateCallPending,
  clearPersonalityDetails,
  loadPersonalitiesAction,
}) => {
  const [personalityTypeDetails, setpersonalityTypeDetails] = useState({});

  const styles = {
    sectionHeader: css`
      margin: 20px 0;
    `,

    inputField: css`
      margin-top: 10px;
      margin-bottom: 10px;
    `,

    form: css`
      position: relative;
      display: flex;
      flex-wrap: wrap;
    `,
    froalaEditor: css`
      width: 100%;
    `,
  };

  useEffect(() => {
    if (Object.keys(details).length) {
      setpersonalityTypeDetails(details);
    }
  }, [details]);

  const closeDrawer = useCallback(() => {
    popDrawer({ popAll: true });
  }, [popDrawer]);

  useEffect(() => {
    if (hasUpdateCallPending === 'Success') {
      closeDrawer();
      loadPersonalitiesAction();
      clearPersonalityDetails();
    }
  }, [hasUpdateCallPending, loadPersonalitiesAction, clearPersonalityDetails, closeDrawer]);

  const saveEnneagram = () => {
    if (hasUpdateCallPending === 'InProgress') {
      return;
    }
    const { commentary, label } = personalityTypeDetails;
    const data = {
      personalityId,
      commentary,
      label,
    };
    editPersonalityDetailsById(data);
  };

  const leftButton = () => (
    <DrawerButton variant={BUTTON_VARIANTS.NEUTRAL} text="Cancel" onClick={closeDrawer} />
  );

  const rightButton = () => (
    <DrawerButton variant={BUTTON_VARIANTS.CALL_TO_ACTION} text="Done" onClick={saveEnneagram} />
  );

  const onLabelChange = (e) => {
    setpersonalityTypeDetails({ ...personalityTypeDetails, label: e.target.value });
  };

  const onCommentaryChange = (e) => {
    const textToUse = e.value;
    if (!textToUse) {
      return;
    }
    setpersonalityTypeDetails({ ...personalityTypeDetails, commentary: textToUse });
  };

  return (
    <DrawerLayout
      renderHeader={() => (
        <DrawerHeader
          renderLeftButton={leftButton}
          title={`Edit ${personalityTypeDetails.system}`}
          renderRightButton={rightButton}
        />
      )}
      renderBody={() => (
        <div css={styles.form}>
          <DrawerInput
            name="name"
            size={SIZE_OPTIONS.FULL_ROW}
            onChange={onLabelChange}
            label="Display Name"
            css={styles.inputField}
            value={personalityTypeDetails.label}
          />
          <Froala
            value={personalityTypeDetails.commentary ?? ''}
            onChange={onCommentaryChange}
            placeholder="Commentary"
            styleOverrides={styles.froalaEditor}
          />
        </div>
      )}
    />
  );
};

EditPersonalityTypeDetailsDrawer.propTypes = {
  popDrawer: PropTypes.func,
  personalityId: PropTypes.string,
  details: PERSONALITY_DETAILS_SHAPE,
  editPersonalityDetailsById: PropTypes.func,
  hasUpdateCallPending: PropTypes.oneOf(['', 'InProgress', 'Success', 'Error']),
  clearPersonalityDetails: PropTypes.func,
  loadPersonalitiesAction: PropTypes.func,
};

EditPersonalityTypeDetailsDrawer.defaultProps = {
  popDrawer: noop,
  personalityId: '',
  details: {
    id: '',
    label: '',
    commentary: '',
  },
  editPersonalityDetailsById: noop,
  hasUpdateCallPending: '',
  clearPersonalityDetails: noop,
  loadPersonalitiesAction: noop,

};

export default EditPersonalityTypeDetailsDrawer;
