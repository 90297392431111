import { css } from '@emotion/react';
import { MouseEvent, useCallback } from 'react';
import Button from '~Common/V3/components/Button';

import { palette } from '~Common/styles/colors';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { useDispatch } from 'react-redux';
import { editProfileTemplate } from '../Drawers/EditProfile';

const styles = {
  editProfileButton: css({
    fontSize: '0.75rem',
    color: palette.brand.indigo,
    fontWeight: 500,
    padding: 0,
  }),
};

interface ViewProps {
  handleEditProfileClick: (event: MouseEvent<HTMLElement>) => void,
}

const View = ({
  handleEditProfileClick,
  ...props
}: ViewProps): JSX.Element => (
  <Button
    css={styles.editProfileButton}
    variant="text"
    onClick={handleEditProfileClick}
    renderContents={() => (
      <>Edit Profile</>
    )}
    {...props}
  />
);

interface EditProfileButtonProps {
  userId: string,
}

const EditProfileButton = ({
  userId,
  ...props
}: EditProfileButtonProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleEditProfileClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(pushDrawerAction({
      drawer: {
        ...editProfileTemplate,
        args: {
          userId,
        },
      },
    }));
  }, [dispatch, userId]);

  const hookProps = {
    handleEditProfileClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default EditProfileButton;
