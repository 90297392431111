import { ForwardedRef, forwardRef, useEffect } from 'react';
import { HTMLString } from '~Common/types';
import { isHTMLText } from '~Common/utils/isHTMLText';

interface ViewProps {
  htmlText: HTMLString,
}

const View = forwardRef(({ htmlText, ...props }: ViewProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => (
  <div
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: htmlText }}
    ref={ref}
    {...props}
  />
));

interface UnsanitizedHTMLRendererProps {
  htmlText: HTMLString,
  reason: string,
}

/**
 * @param {HTMLString} htmlText HTML text only, do not use this in a place that displays non-HTML.
 * Ideally the backend sanitizes the HTML for us, but as of 3/15/2024, it does not, so we sanitize here for now
 * @param {String} reason The reason why you are using the UnsanitizedHTMLRenderer instead of the sanitized HTMLRenderer.
 * This is to help us track down where we are using this component and why, it also forces the dev to be deliberate about having a reason to use this
 */
const UnsanitizedHTMLRenderer = forwardRef(({ htmlText, reason, ...props }: UnsanitizedHTMLRendererProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
  if (process.env.NODE_ENV === 'development' && htmlText !== '' && !isHTMLText(htmlText)) {
    throw new Error(`
      UnsanitizedHTMLRenderer: The text provided: "${htmlText}" is not HTML. Please use a different component.
      Also you can check the stack trace in the error tab of the console to find where this text is being passed in.
    `);
  }

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.warn('The UnsanitizedHTMLRenderer is being used for the following reason: ', reason);
  }, [reason]);

  const hookProps = {
    htmlText,
    ref,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
});

export { View };
export default UnsanitizedHTMLRenderer;
