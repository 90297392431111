import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { SerializedStyles } from '@emotion/react';
import { requestorFeedbackDetailTemplate } from '~Feedback/components/Drawers/RequestorDetail/RequestorDetailDrawer';
import { FeedbackType } from '~Feedback/const/interfaces';
import { receivedLearningDrawerTemplate } from '~Learning/components/ReceivedLearningDashboard/ReceivedLearningDrawer';
import { viewGoalDetailsTemplate } from '~Goals/components/Drawers/ViewGoalDetailsDrawer';
import { useShowRecognitionModal } from '~Recognition/hooks/useShowRecognitionModal';
import { useShowAccomplishmentModal } from '~DevelopmentPlan/hooks/utils/useShowAccomplishmentDetailModal';
import { ReflectionTypeEnum } from '../types';
import { topicsReflectionTemplate } from '../ReflectionAgendaItemDrawer';

interface ActionForReflectionProps {
  reflectionTypeEnum: ReflectionTypeEnum,
  subjectUid: string,
  renderBottomButton?: () => JSX.Element,
  revieweeId?: string,
  revieweeOrgID: string,
}

function drawerActionForReflection({
  reflectionTypeEnum,
  subjectUid,
  renderBottomButton,
  revieweeOrgID,
}: ActionForReflectionProps): Record<string, unknown> {
  switch (reflectionTypeEnum) {
    case ReflectionTypeEnum.Goal:
      return pushDrawerAction({
        drawer: {
          ...viewGoalDetailsTemplate,
          args: {
            goalId: subjectUid,
            isReadOnly: true,
            renderBottomButton,
          },
        },
      });

    case ReflectionTypeEnum.BookmarkedTopic:
      return pushDrawerAction({
        drawer: {
          ...topicsReflectionTemplate,
          args: {
            agendaItemUid: subjectUid,
            renderBottomButton,
          },
        },
      });

    case ReflectionTypeEnum.Feedback:
      return pushDrawerAction({
        drawer: {
          ...requestorFeedbackDetailTemplate,
          args: {
            id: subjectUid,
            type: FeedbackType.REQUESTED,
            assigneeId: revieweeOrgID,
            isReadOnly: true,
            renderBottomButton,
          },
        },
      });

    case ReflectionTypeEnum.Learning:
      return pushDrawerAction({
        drawer: {
          ...receivedLearningDrawerTemplate,
          args: {
            learningId: subjectUid,
            isReadOnly: true,
            showAddToMeetingButton: false,
            renderBottomButton,
            learningOwnerId: revieweeOrgID,
          },
        },
      });

    default:
      return {};
  }
}

interface OpenDrawerProps {
  reflectionTypeEnum: ReflectionTypeEnum,
  reviewUid?: string,
  subjectUid: string,
  renderBottomButton?: (bottomButtonStyles?: SerializedStyles) => JSX.Element,
  revieweeId: string,
  revieweeOrgID: string,
  reflectionId?: string | number,
}

interface UseReflectionDetailsDrawerReturn {
  openDrawer: (props: OpenDrawerProps) => void,
  closeDrawer: () => void,
}

export function useReflectionDetailsDrawer(): UseReflectionDetailsDrawerReturn {
  const dispatch = useDispatch();

  const { openModal: openRecognitionModal } = useShowRecognitionModal();
  const { openModal: openAccomplishmentModal } = useShowAccomplishmentModal();

  const openDrawer = ({
    reflectionTypeEnum,
    subjectUid,
    revieweeId,
    revieweeOrgID,
    renderBottomButton,
    reflectionId,
  }: OpenDrawerProps): void => {
    if (reflectionTypeEnum === ReflectionTypeEnum.Recognition) {
      openRecognitionModal({
        props: {
          recognitionId: Number(reflectionId),
          isReadOnly: true,
          renderBottomButton,
        },
      });
      return;
    }

    if (reflectionTypeEnum === ReflectionTypeEnum.Accomplishment) {
      openAccomplishmentModal({
        props: {
          accomplishmentId: reflectionId?.toString() ?? '',
          renderBottomButton,
        },
      });
      return;
    }

    dispatch(drawerActionForReflection({
      reflectionTypeEnum,
      subjectUid,
      revieweeId,
      renderBottomButton,
      revieweeOrgID,
    }));
  };

  const closeDrawer = (): void => {
    // @ts-expect-error : Fix when drawers are typed
    dispatch(popDrawerAction({
      popAll: true,
    }));
  };

  return {
    openDrawer,
    closeDrawer,
  };
}
