import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';

import { useGetPersonByIdWithLoading } from '~Deprecated/hooks/usePeople';

const styles = {
  container: css`
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  `,
};

const View = ({ person, isLoading, ...props }) => (
  <div
    css={styles.container}
    {...props}
  >
    {!isLoading && (
      <>
        {person?.firstName}
        {' '}
        {person?.lastName}
      </>
    )}
  </div>
);

View.propTypes = {
  person: ATTENDEE_SHAPE.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

View.defaultProps = {};

const PersonHeading = ({ userId, ...props }) => {
  const { person, isLoading } = useGetPersonByIdWithLoading({ id: userId });
  const hookProps = {
    person,
    isLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

PersonHeading.propTypes = {
  userId: PropTypes.string.isRequired,
};

PersonHeading.defaultProps = {};

export { View };
export default PersonHeading;
