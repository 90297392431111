import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { createLearningTemplate } from '~Learning/components/CreateLearningDrawer';
import { createLearningPlaylistDrawerTemplate } from '~Learning/components/CreateLearningPlaylistDrawer';
import { viewLearningLibraryDrawerTemplate } from '~Learning/components/LearningLibrary/ViewLearningLibraryDrawer';
import { OnUseTemplateParams } from '~Learning/const/interfaces';

interface UseLearningLibraryReturn {
  openLibraryWithAssignLearningTemplate: () => void,
}

export const useLearningLibrary = (): UseLearningLibraryReturn => {
  const dispatch = useDispatch();

  const defaultUseTemplate = useCallback(({ templateId, isPlaylist, createWorkflowCallback }: OnUseTemplateParams) => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    if (isPlaylist) {
      dispatch(pushDrawerAction({
        drawer: {
          ...createLearningPlaylistDrawerTemplate,
        },
      }));
      createWorkflowCallback?.();
    } else {
      dispatch(pushDrawerAction({
        drawer: {
          ...createLearningTemplate,
          args: {
            templateId,
          },
        },
      }));
    }
  }, [dispatch]);

  // When you use a template with this function, it goes to the assign single learning workflow
  const openLibraryWithAssignLearningTemplate = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...viewLearningLibraryDrawerTemplate,
        args: {
          onUseTemplate: defaultUseTemplate,
        },
      },
    }));
  }, [dispatch, defaultUseTemplate]);

  return {
    openLibraryWithAssignLearningTemplate,
  };
};
