import { create } from 'zustand';

interface ExpandedAgendaSections {
  [key: string]: boolean,
}
interface UseShowAgendaSection {
  expandedAgendaSections: ExpandedAgendaSections,
  expandAgendaSection: (agendaSectionId: string) => void,
  collapseAgendaSection: (agendaSectionId: string) => void,
  getIsAgendaSectionExpanded: (agendaSectionId: string) => boolean,
  getExpandedAgendaSections: () => ExpandedAgendaSections,
  removeAgendaSection: (agendaSectionId: string) => void,
}

export const useShowAgendaSection = create<UseShowAgendaSection>((set, get) => ({
  expandedAgendaSections: {},
  expandAgendaSection: (agendaSectionId: string) => set(() => ({
    expandedAgendaSections: {
      ...get().expandedAgendaSections,
      [agendaSectionId]: true,
    },
  })),
  collapseAgendaSection: (agendaSectionId: string) => set(() => ({
    expandedAgendaSections: {
      ...get().expandedAgendaSections,
      [agendaSectionId]: false,
    },
  })),
  getIsAgendaSectionExpanded: (agendaSectionId: string) => {
    const isExpanded = get().expandedAgendaSections[agendaSectionId];
    if (isExpanded !== undefined) {
      return isExpanded;
    }

    return true;
  },
  getExpandedAgendaSections: () => get().expandedAgendaSections,
  removeAgendaSection: (agendaSectionId: string) => set(() => {
    if (!get().expandedAgendaSections[agendaSectionId]) {
      const newExpandedAgendaSections = { ...get().expandedAgendaSections };
      delete newExpandedAgendaSections[agendaSectionId];
      return {
        expandedAgendaSections: newExpandedAgendaSections,
      };
    }

    return get().expandedAgendaSections;
  }),
}));
