import { HttpCallReturn, getApi } from '~Deprecated/services/HttpService';
import { useQuery } from '@tanstack/react-query';

interface State {
  name: string,
  stateId: string,
}

const getStates = (country: string): Promise<HttpCallReturn<State[]>> => {
  const serverUrl = `/countries/${country}/states`;

  return getApi<State[]>(serverUrl);
};

interface UseGetStatesReturn {
  states: State[] | undefined,
}

export const useGetStates = (country = 'USA'): UseGetStatesReturn => {
  const result = useQuery({
    queryKey: ['states', country],
    queryFn: () => getStates(country),
    staleTime: Infinity,
  });

  return {
    states: result.data?.response,
    ...result,
  };
};
