import { toast } from 'react-toastify';
import ErrorToast from '~Common/components/Toasts/Error';
import InfoLightToast from '~Common/components/Toasts/InfoLight';
import SuccessToast from '~Common/components/Toasts/Success';
import WarningToast from '~Common/components/Toasts/Warning';
import PromiseToast from '~Common/components/Toasts/Promise';

const TYPE_TO_TOAST = {
  [toast.TYPE.ERROR]: ErrorToast,
  [toast.TYPE.SUCCESS]: SuccessToast,
  [toast.TYPE.WARNING]: WarningToast,
  [toast.TYPE.INFO]: InfoLightToast,
};

const toastWrapper = {
  ...toast,
  success: (content, options) => toast.success(<SuccessToast text={content} />, options),
  error: (content, options) => toast.error(<ErrorToast text={content} />, options),
  warning: (content, options) => toast.warning(<WarningToast text={content} />, options),
  info: (content, options) => toast.info(<InfoLightToast text={content} />, options),
  promise: (content, options) => toast.info(<PromiseToast text={content} />, options),
  update: (ref, { render, type, ...args }) => {
    const ToastComponent = TYPE_TO_TOAST[type];

    toast.update(ref, {
      render: <ToastComponent text={render} />,
      type,
      ...args,
    });
  },

};

export { toastWrapper as toast };
