import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import {
  GetMeetingTemplatesRequestQueryParameters,
  GetMeetingTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { buildQueryString } from '~Common/utils';
import { meetingTopicTemplatesQueryKeys } from './queryKeys';

export interface GetMeetingTemplatesResponse {
  meetingTemplates: GetMeetingTemplateDetails[];
}

const getCustomMeetingTemplates = (params: GetMeetingTemplatesRequestQueryParameters): Promise<HttpCallReturn<GetMeetingTemplatesResponse>> => {
  const queryString = buildQueryString(params);
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates${queryString ?? ''}`;

  return getApi<GetMeetingTemplatesResponse>(URL);
};

type UseGetCustomMeetingTemplatesParams = GetMeetingTemplatesRequestQueryParameters;

const useGetCustomMeetingTemplates = ({
  meetingTemplateType,
  templateVisibility,
}: UseGetCustomMeetingTemplatesParams): UseQueryResult<HttpCallReturn<GetMeetingTemplatesResponse>> => {
  const orgId = getOrganizationId() ?? '';

  const queryKey = meetingTopicTemplatesQueryKeys.customTemplatesList({
    orgId,
    meetingTemplateType,
    templateVisibility,
  });

  return useQuery({
    queryKey,
    queryFn: () => getCustomMeetingTemplates({
      meetingTemplateType,
      templateVisibility,
    }),
  });
};

export default useGetCustomMeetingTemplates;
