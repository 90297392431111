import { css } from '@emotion/react';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Link } from 'react-router-dom';
import { ADMIN_SETTINGS } from '~Common/const/routes';
import { palette } from '~Common/styles/colors';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { OrgClarityQuestion } from '~Common/const/Organization';
import OrgQuestionDisplay from './OrgQuestionDisplay';

const styles = {
  header: css({
    marginBottom: '0.5rem',
  }),
  emptyText: css({
    color: palette.neutrals.gray700,
  }),
  orgQuestionDisplay: css({
    '&:not(:last-of-type)': {
      marginBottom: '0.75rem',
    },
  }),
};

interface ViewProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
  orgClarityQuestionList?: OrgClarityQuestion[] | undefined,
  isAdmin: boolean,
}

const View = ({
  dragHandleProps,
  orgClarityQuestionList,
  isAdmin,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <ModuleHeader
      css={styles.header}
      headerText="Organizational Clarity"
      {...dragHandleProps}
    />
    {orgClarityQuestionList && (
      <>
        {orgClarityQuestionList.map((orgClarityQuestion) => (
          <OrgQuestionDisplay
            key={orgClarityQuestion.id}
            css={styles.orgQuestionDisplay}
            orgClarityQuestion={orgClarityQuestion}
          />
        ))}
      </>
    )}
    {orgClarityQuestionList?.length === 0 && (
      <p css={styles.emptyText}>Answers to your organization&apos;s important alignment questions will be available shortly.</p>
    )}
    {isAdmin && (
      <ModuleFooter
        renderFooterButton={(params) => (
          <LeadrButton
            data-test-id="homeEditOrganizationalClarityQuestions"
            component={Link}
            to={{
              pathname: ADMIN_SETTINGS,
              state: { navigatedFromOrgClarity: true },
            }}
            variant="text"
            size="small"
            {...params}
          >
            Edit
          </LeadrButton>
        )}
      />
    )}
  </LeadrCard>
);

export interface OrgClarityProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const OrgClarity = ({
  dragHandleProps,
  ...props
}: OrgClarityProps): JSX.Element => {
  const { orgSettings } = useOrgDetailsContext();
  const allOrgClarityQuestions = orgSettings.orgClarityQuestionList;
  const { isAdmin } = useUserPermissions();

  let orgClarityQuestionList = allOrgClarityQuestions || [];

  if (isAdmin) {
    orgClarityQuestionList = allOrgClarityQuestions?.map((orgClarityQuestion) => ({
      ...orgClarityQuestion,
      // TODO: This is probably bad practice and we should update it
      answer: orgClarityQuestion.answer || 'Please provide an answer to this clarity question.',
    })) || [];
  } else {
    orgClarityQuestionList = allOrgClarityQuestions?.filter((orgClarityQuestion) => orgClarityQuestion.answer) || [];
  }

  const hookProps = {
    dragHandleProps,
    orgClarityQuestionList,
    isAdmin,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default OrgClarity;
