import { css } from '@emotion/react';
import { Transition } from 'framer-motion';
import { palette } from '~Common/styles/colors';
import {
  forMobile, forTablet, withoutDesktop,
} from '../../common/styles/mixins';

export const CARD_STYLES = {
  tiny: {
    flex: '1',
    overflow: 'visible',
    flexBasis: '100%',
  },
  dashboard: {
    order: 10,
    transition: 'all 200ms ease',
    // flexBasis: '100%',
  },
  expanded: {
    maxWidth: '100%',
    flex: '1 1 100%',
    transition: 'all 200ms ease',
  },
};

const SHARED_CARD_COMPONENTS = {
  emptyImageContainer: css({
    flexBasis: '30%',
  }),
  fauxToggleTab: css({
    color: palette.brand.indigo,
    fontWeight: 600,
  }),
  emptyStateSmall: css({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '.5rem',
    justifyContent: 'space-evenly',
    padding: '0.5rem',
    maxWidth: '25rem',
  }, forMobile(`
    flex-direction: column;
  `)),
  emptyStateText: css({
    color: palette.brand.indigo,
    fontSize: '1rem',
    fontWeight: 600,
    margin: '1rem 0',
    textAlign: 'center',
  }),
  emptyState: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'space-evenly',
    padding: '0.5rem',
  }, forMobile(`
    flex-direction: column;
  `)),
  explainer: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    fontWeight: 400,
    maxWidth: '175px',
    display: 'flex',

    svg: {
      marginRight: '1rem',
    },
  }, withoutDesktop(`
    flex-basis: 100%;
    max-width: 100%;
  `)),
  header: (isMobile: boolean) => css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    // flexBasis: '100%',
    gap: '1rem',
    height: 'auto',
    // maxHeight: isMobile ? 'auto' : 'auto',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
    width: '100%',

    p: {
      margin: '0',
    },
  }, withoutDesktop(`
    flex-wrap: wrap;
  `), forMobile(`
    flex-wrap: wrap;
  `)),
  smallHeader: css({
    maxHeight: '1.5rem',
  }),
  horizontalDivider: css({
    backgroundColor: palette.neutrals.gray200,
    height: '2px',
    flexBasis: '100%',
    marginTop: '-0.125rem',
  }),
  expandButton: (isMobile: boolean) => css({
    alignSelf: 'start',
    display: isMobile ? 'none' : 'inline-block',
    pointerEvents: isMobile ? 'none' : 'unset',
  }),
  main: css({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1.5rem',
    overflow: 'clip',
  }, forMobile(`
    overflow: auto;
  `)),
  noPadding: css({
    padding: 0,
  }),
  sidebarPadding: css({
    padding: '1.5rem',
  }),
  sidebar: css({
    color: palette.neutrals.gray700,
    display: 'flex',
    flexBasis: '15%',
    flexDirection: 'column',
    padding: '1.5rem',
    rowGap: '1.75rem',
    minWidth: '9rem',

    h3: {
      fontSize: '0.875rem',
      fontWeight: '600',
    },
    p: {
      margin: '0',
    },
  }, withoutDesktop(`
    flex-basis: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    --gap: 1.25rem;
    --columns: 3;
    gap: var(--gap);

    & h3{
      flex-basis: 100%;
    }
    & > div{
      width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
    }

  `), forMobile(`
    --columns: 2;
  `)),
  titleAndSubtitle: (isMobile: boolean) => css({
    color: palette.neutrals.gray700,
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    fontWeight: '400',
    size: '0.875rem',
    flexBasis: isMobile ? '100%' : 'auto',

    '& p': {
      display: isMobile ? 'none' : 'block',
    },

  }),
  verticalDivider: (isMobile: boolean) => css({
    backgroundColor: palette.neutrals.gray200,
    width: '2px',
    flexBasis: isMobile ? '100%' : 'auto',
    height: isMobile ? '1px' : 'auto',
    flexShrink: 0,
  }, withoutDesktop(`
      width:100%;
      height:2px;
  `)),
};

export const TINY_CARD_COMPONENTS = {
  ...SHARED_CARD_COMPONENTS,
  tinyContainer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    rowGap: '0.75rem',
  }),
  tinyBody: css({
    alignItems: 'center',
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    textalign: 'center',

    '.delta': {
      fontSize: '2rem',
    },
  }),
};

export const DASHBOARD_CARD_COMPONENTS = {
  ...SHARED_CARD_COMPONENTS,
  dashboardContainer: css({
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '40rem',
  }, withoutDesktop(`
    flex-wrap: wrap;
    max-height: none;
  `)),
  dashboardContainerMedium: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  dashboardSidebar: css({
    maxHeight: '100%',
    overflow: 'auto',
    flexShrink: 0,
  }),
  dashboardSkeleton: css({
    width: '100%',
    height: '8rem',
    order: '1',
    maxWidth: '100%',
    borderRadius: '5px',
  }),
  dashboardEngagement: css({
    order: '2',
  }),
  dashboardEnps: css({
    order: '3',
  }),
  dashboardPeople: css({
    order: '4',
  }),
  horizontalDividerPeople: css({
    backgroundColor: palette.neutrals.gray200,
    height: '2px',
    marginTop: '-0.125rem',
  }),
  tableRowColor: css({
    backgroundColor: palette.neutrals.gray100,
  }),

  searchBox: css({
    width: '50%',
  }),

  donutGraphSideBar: css({
    color: palette.neutrals.gray700,
    display: 'flex',
    flexBasis: '20%',
    flexDirection: 'column',
    rowGap: '1.75rem',
    minWidth: '15rem',

    h3: {
      fontSize: '0.875rem',
      fontWeight: '600',
    },
    p: {
      margin: '0',
    },
  }),

  RowBody: css`
  & tr:nth-of-type(odd) {
    & td:first-of-type > div > div{
      background-color: #fff;
      border: none;
      color:black;
      padding:none !important;
      &:hover {
        background: ${palette.brand.blue};
        .attendeeName {
          color: black !important;
        }
      }
    }
  }

  .MuiTableCell-root {
    font-family: ProximaNova !important;
  }
  `,
};

export const EXPANDED_CARD_COMPONENTS = {
  ...SHARED_CARD_COMPONENTS,
  main: css({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    gap: '1rem',
    padding: '3rem 1.5rem 1.5rem 1.5rem',
    overflowX: 'clip',
    maxWidth: '100%',
  }),
  avatarCell: css({
    alignItems: 'center',
    columnGap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 400,
  }),
  name: css({
    width: '200px',
  }),
  barChart: css({
    width: '100%',
  }),
  chart: css({
    position: 'relative',
    width: '100%',
    // This forces Chart.js to be responsive.
    '& canvas': {
      width: '100% !important',
    },
  }),
  deltaContainer: css({
    width: '100%',
    button: {
      width: '100%',
    },
    textAlign: 'left',
    '.delta': {
      fontSize: '1.125rem',
      fontWeight: 600,
      textAlign: 'left',
    },
    p: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  }),
  expandedLineGraph: css({
    overflowX: 'clip',
    minHeight: '36rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: {
      margin: '0',
    },
  }),
  expandedContainer: css({
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5rem',
    flexWrap: 'wrap',

    '& h2': {
      fontSize: '1.5rem',
    },
  }),
  expandedCushion: css({
    height: '2rem',
    flexBasis: '100%',
  }),
  expandedRow: (isMobile: boolean) => css({
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '100%',
    padding: '0',
    maxWidth: '100%',
    flexWrap: isMobile ? 'wrap' : 'unset',
    overflow: isMobile ? 'visible' : 'clip',
    maxHeight: isMobile ? 'unset' : '45rem',
  }, withoutDesktop(`
    flex-wrap: wrap;
  `)),

  expandedRowPeople: css({
    display: 'flex',
    flexDirection: 'row',
    padding: '0',
    marginTop: '1.5rem',
  }),

  expandedDonutSidebar: css({
    textAlign: 'center',
    rowGap: 'unset',
    button: {
      padding: '0.75rem',
    },

    '.delta': {
      fontSize: '2.5rem',
    },
  }, withoutDesktop(`
    flex-basis: 100%;
    flex-direction: row;
    --columns: 4;
    justify-content: space-between;
  `), forMobile(`
    --columns: 2;
  `)),
  expandedSidebar: (isMobile: boolean) => css({
    textAlign: 'center',
    rowGap: '0.75rem',
    padding: '3rem 0.75rem 1.5rem 0.75rem',
    flexDirection: isMobile ? 'row' : 'column',
    justifyContent: isMobile ? 'space-between' : 'flex-start',
    flexWrap: isMobile ? 'wrap' : 'initial',
    alignItems: 'center',

    button: {
      padding: '0.75rem',
      width: isMobile ? '33%' : '100%',
    },

    '.delta': {
      fontSize: '2.5rem',
    },
  }, withoutDesktop(`
    flex-basis: 100%;
    flex-direction: row;
    --columns: 4;
    justify-content: space-between;
  `), forMobile(`
    --columns: 2;
  `)),
  bigNumber: css({
    fontSize: '2.5rem',
    margin: '0',
    wordBreak: 'keep-all',
  }),
  subtitle: css({
    color: palette.brand.indigo,
    flex: '1',
    fontSize: '1.25rem',
  }),
  subtitlePeopleMetric: css({
    color: palette.brand.indigo,
    flex: '1',
    fontSize: '1.25rem',
    display: 'flex',
    alignItems: 'center',
  }),
  tabs: css({
    flexBasis: '100%',

    '& .MuiTab-root': {
      textTransform: 'unset',
    },
  }, forTablet(`
    flex-basis: 50%,
  `)),
  semiHeader: css({
    fontWeight: 600,
  }),
  semiHeaderName: css({
    fontWeight: 600,
    paddingLeft: '1.875rem',
  }),
  tableRow: css({
    '&:nth-of-type(even)': {
      backgroundColor: palette.neutrals.gray50,
    },

    '.MuiTableCell-body': {
      color: palette.neutrals.gray800,
    },
  }),
  metricsWrapper: css({
    width: '200px',
    maxWidth: '200px',
    overflow: 'hidden',
  }),
};

export interface FramerProps {
  animate?: boolean,
  layout?: 'position' | 'size' | boolean,
  transition?: Transition,
}

export const FRAMER_PROPS: FramerProps = {
  animate: true,
  layout: 'position',
  transition: {
    layout: {
      duration: 0,
      bounce: 0,
      type: 'spring',
      ease: 'linear',
      stiffness: 55,
      mass: 0.049,
    },
  },
};
