import { Route, Switch } from 'react-router';
import {
  ADMIN_PEOPLE, ADMIN_PEOPLE_NEW_IMPORT,
  ADMIN_USER_GROUPS,
} from '~Common/const/routes';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import UserGroups from '~UserGroups/components/UserGroupsPage';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import AdminIndex from './AdminIndex';
import AdminImportLayout from './AdminImportLayout';

export const AdminRouter = (): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[
        ADMIN_PEOPLE_NEW_IMPORT,
      ]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            ChildComponent={<AdminImportLayout />}
          />
        </BaseLayout>
      )}
    />
    <Route
      exact
      path={[
        ADMIN_PEOPLE,
      ]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            ChildComponent={<AdminIndex />}
          />
        </BaseLayout>
      )}
    />
    <Route
      exact
      path={[
        ADMIN_USER_GROUPS,
      ]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            ChildComponent={<UserGroups />}
          />
        </BaseLayout>
      )}
    />
    <SmartRedirect from="*" to={ADMIN_PEOPLE} />
  </Switch>
);

export default AdminRouter;
