import { css } from '@emotion/react';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  Dispatch as ReactDispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import SkeletonLoader from '~Common/components/SkeletonLoader';
import { Person } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';
import { getOrganizationName, getOrganizationUserId } from '~Common/utils/localStorage';
import Button from '~Common/V3/components/Button';
import { useGetPersonByIdWithLoading } from '~Deprecated/hooks/usePeople';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { profileItems } from '~Root/components/NavigationSidebar/navigationItemList';
import { ActionButton } from '~Root/components/NavigationSidebar/NavigationItems/ActionButton';
import { LinkButton } from '~Root/components/NavigationSidebar/NavigationItems/LinkButton';
import { SHARED_STYLES } from '~Root/components/NavigationSidebar/NavigationItems/styles';
import { useNavigationIndicators } from '~Root/components/NavigationSidebar/useNavigationIndicators';
import { useNavigationPermissions } from '~Root/components/NavigationSidebar/useNavigationPermissions';
import {
  IsVisibleProps,
  NAVBAR_HIGHLIGHT_COLOR,
  NAVBAR_LEFT_PADDING,
  NavigationIndicatorData,
  NavigationItem,
} from '~Root/components/NavigationSidebar/types';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';

const styles = {
  ...SHARED_STYLES,
  avatarSkeleton: css({
    height: '2.5rem',
    width: '2.5rem',
    maxWidth: '100%',
  }),
  chevron: (isDrawerExpanded: boolean) => css({
    rotate: '0deg',
    transition: 'rotate 100ms linear',
  }, isDrawerExpanded && {
    rotate: '180deg',
  }),
  mobileAppNavPadding: css(IS_MOBILE_APP && {
    paddingBottom: 'env(safe-area-inset-bottom)',
  }),
  names: css({
    color: palette.neutrals.gray600,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    textAlign: 'start',
    whiteSpace: 'nowrap',
  }),
  personName: css({
    color: palette.neutrals.gray700,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    fontWeight: 600,
  }),
  profileSection: (isCollapsed: boolean) => css({
    alignItems: 'center',
    backgroundColor: NAVBAR_HIGHLIGHT_COLOR,
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    height: '4.125rem',
    maxWidth: '100%',
    padding: '1rem',
    transition: '100ms background-color ease',

    ':hover, :focus': {
      backgroundColor: palette.neutrals.gray50,
    },
  }, IS_MOBILE_APP && isCollapsed && {
    height: 'calc(4.125rem + env(safe-area-inset-bottom))',
    paddingBottom: 'calc(1rem + env(safe-area-inset-bottom))',
  }),
  truncate: css({
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    fontWeight: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

const skeletonStyles = {
  row: (isCollapsed: boolean) => css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    gap: '0.5rem',
    padding: '1rem',
  }, isCollapsed && {
    paddingLeft: NAVBAR_LEFT_PADDING,
  }),
  skeleton: css({
    height: '1rem',
    width: '100%',
    maxWidth: '100%',
  }),
  column: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '0.25rem',
  }),
};

interface ViewSkeletonProps {
  compact: boolean,
}

const ViewSkeleton = ({
  compact,
}: ViewSkeletonProps): JSX.Element => (
  <div css={skeletonStyles.row(compact)}>
    <SkeletonLoader
      css={styles.avatarSkeleton}
      renderComponent={() => <></>}
    />
    {!compact && (
      <MultipleSkeletonLoaders
        css={skeletonStyles.column}
        numberOfSkeletons={3}
        renderSkeletonItem={() => <SkeletonLoader css={skeletonStyles.skeleton} renderComponent={() => <></>} />}
      />
    )}
  </div>
);

interface ViewProps extends ProfileCollapseProps {
  filteredItems: readonly NavigationItem[],
  isDrawerExpanded: boolean,
  isVisibleProps: IsVisibleProps,
  navigationIndicatorData: NavigationIndicatorData,
  organizationName: string,
  person: Person,
  setIsDrawerExpanded: ReactDispatch<SetStateAction<boolean>>,
}

const View = ({
  filteredItems,
  compact,
  isDrawerExpanded,
  isVisibleProps,
  navigationIndicatorData,
  organizationName,
  person,
  setIsDrawerExpanded,
}: ViewProps): JSX.Element => (
  <>
    <Button
      variant="text"
      css={styles.profileSection(!isDrawerExpanded)}
      onClick={() => setIsDrawerExpanded(!isDrawerExpanded)}
      renderContents={() => (
        <>
          <NewBaseAvatar
            profileImageUrl={person.profileImageUrl}
            name={`${person.firstName} ${person.lastName}`}
            avatarSize={40}
            showTooltip={false}
          />
          {!compact && (
            <>
              <div css={styles.names}>
                <p css={styles.personName}>{`${person.firstName} ${person.lastName}`}</p>
                <p css={styles.truncate}>{person.email}</p>
                <p css={styles.truncate}>{organizationName}</p>
              </div>

              <FontAwesomeIcon
                css={styles.chevron(isDrawerExpanded)}
                icon={faChevronUp}
              />
            </>
          )}
        </>
      )}
    />
    <Collapse in={isDrawerExpanded}>
      <section css={[styles.navContainer, styles.mobileAppNavPadding]}>
        {filteredItems.map((item) => (
          <>
            {!!item.href && (
              <LinkButton
                compact={compact}
                item={item}
                navigationIndicatorData={navigationIndicatorData}
              />
            )}
            {!item.href && !item.children && (
              <ActionButton
                compact={compact}
                isVisibleProps={isVisibleProps}
                item={item}
              />
            )}
          </>
        ))}
      </section>
    </Collapse>
  </>
);

interface ProfileCollapseProps {
  compact: boolean,
}

export const ProfileCollapse = ({
  compact,
}: ProfileCollapseProps): JSX.Element => {
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
  const dispatch = useDispatch();
  const isVisibleProps = useNavigationPermissions(compact);
  const navigationIndicatorData = useNavigationIndicators();
  const { person, isLoading: isProfileLoading } = useGetPersonByIdWithLoading({ id: getOrganizationUserId() }) as {
    person: Person,
    isLoading: boolean,
  };

  const organizationName = getOrganizationName() ?? '';

  const filteredItems = useMemo(() => (
    profileItems.filter((item) => (
      typeof item.isVisible === 'undefined'
      || (typeof item.isVisible === 'boolean' && item.isVisible)
      || item.isVisible(isVisibleProps)
    )) ?? []
  ), [isVisibleProps]);

  if (isProfileLoading) {
    return <ViewSkeleton compact={compact} />;
  }

  const hookProps = {
    dispatch,
    filteredItems,
    compact,
    isDrawerExpanded,
    isVisibleProps,
    navigationIndicatorData,
    organizationName,
    person,
    setIsDrawerExpanded,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
