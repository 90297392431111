/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useAlert, useForm } from '../../../hooks';
import { feedbackStatusCompleted, feedbackStatusIncomplete, stringsFeedback } from '../config';
import FeedbackListItem from './FeedbackListItem';
import {
  resetFeedbackDetails,
  resetFeedbackQuestionResponse,
} from '../../../redux/actions/feedbackActions';
import { sendResponseData } from '../../../redux/actions/contentActions';
import Alert from '../../Alert';
import { isEditorEmpty } from '../../../redux/sagas/utils';
import {
  getUserId,
} from '~Common/utils/localStorage';
import { getFeedbackDetailsByIdDataService } from '~Deprecated/services/feedbackService';
import AddItemButton from '~Meetings/components/buttons/AddItemButton';
import { palette } from '~Common/styles/colors';
import { withoutDesktop } from '~Common/styles/mixins';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { isCurrentUser } from '~Common/utils';
import { addToMeetingTemplate } from '~Feedback/components/Drawers/AddToMeeting/List';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  addButton: css`
    flex-shrink: 0;
    font-weight: 600 !important;
    height: 2rem !important;
    font-size: 0.75rem !important;
    ${withoutDesktop(`
      padding: 0.5rem !important;
    `)}
    &:hover, &:focus {
      background-color: ${palette.brand.indigo};
      color: ${palette.neutrals.white};
    }
  `,
};

// TODO: Rewrite this with new UI in meetings
const FeedbackItemComponent = (props) => {
  const dispatch = useDispatch();
  const [item, setFeedbackDetailsById] = useState(null);
  const { alertMessage, showAlertMessage } = useAlert();
  const isForMyTeamTabAction = useSelector((response) => response.feedback.feedbackList.isForMyTeamTabAction);
  const { fields, handleChange, errors } = useForm();
  const { feedbackId, assigneeId } = props;

  const isCurrentUserAssignee = isCurrentUser(assigneeId);

  const onAddToNextMeetingClick = (questions) => {
    const formattedQuestions = questions.map((question) => ({
      ...question,
      questionId: question.id,
    }));

    dispatch(pushDrawerAction({
      drawer: {
        ...addToMeetingTemplate,
        args: {
          otherUserId: isCurrentUserAssignee ? item?.owner?.[0]?.id : assigneeId,
          questions: formattedQuestions,
        },
      },
    }));
  };

  const fetchDetails = async () => {
    const requestData = { id: feedbackId, assigneeId };
    const response = await getFeedbackDetailsByIdDataService(requestData);
    setFeedbackDetailsById(response.response);
  };

  useEffect(() => {
    // Need same as it is used for both for me and for my team. Made respective changes in history.push
    if (feedbackId && assigneeId) {
      fetchDetails();
    }

    return (() => {
      dispatch(resetFeedbackDetails());
    });
  }, [feedbackId, assigneeId]);

  const getQuestionsByIdData = (data) => {
    const feedbackAnswersArray = [];
    for (const key in data) {
      if (data[key]) {
        const obj = { questionId: key, answer: { text: data[key] } };
        feedbackAnswersArray.push(obj);
      }
    }
    return feedbackAnswersArray;
  };

  const validateResponse = () => {
    const keys = Object.keys(fields);
    if (keys && keys.length !== 0 && Object.keys(errors).length === 0 && item.question && item.question.length === keys.length) { // Looping through arrays created from Object.keys
      const data = [];
      for (const key of keys) {
        // change as there is now editor
        if (isEditorEmpty(fields[key])) {
          data.push(true);
        } else {
          data.push(false);
        }
      }
      const index = data.findIndex((d) => d === true);
      return index > -1;
    }
    return true;
  };

  const onClickOfSendResponse = () => {
    if (Object.keys(fields).length !== 0 && Object.keys(errors).length === 0) {
      const payload = {};
      payload.feedbackId = item.id;
      payload.questions = fields;
      const feedbackAnswersArray = getQuestionsByIdData(fields);
      dispatch(sendResponseData({
        answers: feedbackAnswersArray,
        page: '',
      }));
      dispatch(resetFeedbackQuestionResponse());
      setFeedbackDetailsById({
        ...item,
        status: feedbackStatusCompleted,
        question: item.question.map((i) => ({
          ...i,
          response: [feedbackAnswersArray.find((ans) => ans.questionId === i.id).answer],
        })),
      });
    } else {
      showAlertMessage('All fields are manadatory');
    }
  };

  const checkEditable = (itm) => {
    if (itm && itm.owner) {
      return ((itm.owner[0].id !== getUserId()) && itm.status === feedbackStatusIncomplete);
    }
    return false;
  };

  if (!item) {
    return null;
  }

  return (
    <div className="feedback-list-wrapper content-card">
      { alertMessage && alertMessage !== '' && <Alert type="success" message={alertMessage} /> }

      <div className="meeting-with-content feedback-header">
        <div className="meeting-with-user-name feedback-title">
          { item.title }
        </div>
        {item.dueDate && (
        <div className="due-font feedback-due-date-wrapper">
          <label className="feedback-due-label pr-1"> Due </label>
          <span>{moment(item.dueDate).format('MMM D')}</span>
        </div>
        ) }
      </div>
      {item.introduction && (
      <React.Fragment>
        <hr className="divider" />
        <HTMLRenderer htmlText={item.introduction} />
      </React.Fragment>
      )}
      <hr className="divider" />
      <div className="ldr-scroller">
        <div className="add-to-next-meeting-button-wrapper">
          <AddItemButton
            onClick={() => onAddToNextMeetingClick(item.question)}
            text="Add All to Next Meeting"
            css={styles.addButton}
            iconName={faPlus}
          />
        </div>
        { item && item.question && item.question.length && item.question.map((itemObj, index) => (
          <div key={index}>
            <FeedbackListItem
              queAnsData={itemObj}
              page={props.page}
              isEditable={checkEditable(item)}
              handleChange={handleChange}
              index={index}
              user={item}
              isForMyTeamTabAction={isForMyTeamTabAction}
              isShowDate={!(item.owner[0].id === getUserId() && item.status === feedbackStatusIncomplete)}
            />
            <div className="add-to-next-meeting-button-wrapper">
              <AddItemButton
                onClick={() => onAddToNextMeetingClick([itemObj])}
                text="Add to Next Meeting"
                css={styles.addButton}
                iconName={faPlus}
              />
            </div>
            { item.owner[0].id === getUserId() && item.status === feedbackStatusIncomplete
              ? (
                <div className="awaiting-response-wrapper">
                  <span
                    className="awaiting-response-msg"
                  >
                    { stringsFeedback.awaitingResponse }
                  </span>
                </div>
              ) : null }

          </div>
        )) }
        { ((item.owner[0].id !== getUserId()) && item.status === feedbackStatusIncomplete) && (
          <div className="send-feedback-btn m-4 text-right">
            <button
              className="mat-button feedback-buttons btn btn-primary"
              disabled={validateResponse()}
              onClick={() => {
                onClickOfSendResponse();
              }}
            >
              <FormattedMessage id="feedback.send" />
            </button>
          </div>
        ) }
      </div>
    </div>
  );
};

export default FeedbackItemComponent;
