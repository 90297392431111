/* eslint-disable max-len */
import { css } from '@emotion/react';
import { forDesktop, forTablet } from '~Deprecated/ui/styles/mixins';

const sizes = {
  topbar: '69px',
  padding: {
    tablet: '1.25rem',
    desktop: '3rem',
  },
  pageTitleAndButtons: '31px',
  marginTop: '24px',
  tableHeader: '51px',
};
const styles = {
  tableWrapper: css`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    
    .table-body-wrapper {
      ${forDesktop(`
        max-height: calc((1vh * 100) - (${sizes.topbar} + ${sizes.padding.desktop} + ${sizes.pageTitleAndButtons} + ${sizes.marginTop} + ${sizes.tableHeader}));
        max-height: calc((var(--vh, 1vh) * 100) - (${sizes.topbar} + ${sizes.padding.desktop} + ${sizes.pageTitleAndButtons} + ${sizes.marginTop} + ${sizes.tableHeader}));
        overflow: scroll;
      `)}

      ${forTablet(`
        max-height: calc((1vh * 100) - (${sizes.topbar} + ${sizes.padding.tablet} + ${sizes.pageTitleAndButtons} + ${sizes.marginTop} + ${sizes.tableHeader}));
        max-height: calc((var(--vh, 1vh) * 100) - (${sizes.topbar} + ${sizes.padding.tablet} + ${sizes.pageTitleAndButtons} + ${sizes.marginTop} + ${sizes.tableHeader}));
        overflow: scroll;
      `)}
    }
  `,
  tableHeader: css`
    display: flex;
    padding: 1rem 1.5rem;
    flex: 1;
    flex-shrink: 0;
    margin-inline-end: 10px;
    
    @media only screen and (max-width: 576px) {
      display: none;
      padding: 1rem;
    }
  `,
  headerTitle: css`
    font-size: 12px;
    line-height: 12px;
    color: #7996B0;
    display: flex;
    align-items: center;
    margin-inline-end: 1rem;
  `,
  title: css`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (max-width: 576px) {
      width: 15%;
      padding-inline-end: unset;
    }
  `,
  rowData: css`
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-inline-end: 1rem;
    
    @media only screen and (max-width: 576px) {
      margin-block-start: 2.5px;
    }
  `,
  dataWrapper: css`
    display: flex;
    padding: 0.5rem 1.5rem;
    flex: 1;
    align-items: center;
    cursor: pointer;
    &:hover, &.expanded {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
    }
    &:not(:first-of-type) {
      margin-block-start: 8px;
    }
    &:last-child {
      margin-block-end: 1rem;
    }
    @media only screen and (max-width: 576px) {   
      height: unset;
      flex-wrap: wrap;
      padding: 0.5rem 1rem;
      background: white;
      &:not(:first-of-type) {
        margin-block-start: 4px;
      }
      &:first-of-type {
        margin-block-start: 4px;
      }
      &:hover {
        box-shadow: unset;
      }
      &:last-child {
        margin-block-end: unset;
      }
    }
  `,
  personalitesListWrapper: css`
        width: 60% !important;
        margin-right: auto !important;
        margin-left: auto !important;
  `,
};

export default styles;
