import { SyntheticEvent, useCallback } from 'react';
import ConfirmationPopover, {
  RenderConfirmationButtonsParams,
  useConfirmationPopover,
  UseConfirmationPopoverHookReturn,
} from '~Common/V3/components/ButtonWithConfirmation/ConfirmationPopover';

interface RenderButtonsParams {
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void,
}

interface ViewProps extends Omit<UseConfirmationPopoverHookReturn, 'openConfirmationPopover'> {
  renderButton: (params: RenderButtonsParams) => JSX.Element,
  renderConfirmationButtons: (params: RenderConfirmationButtonsParams) => JSX.Element,
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void,
}

const View = ({
  popoverId,
  isOpen,
  anchorEl,
  closeConfirmationPopover,
  renderButton,
  renderConfirmationButtons,
  onClick,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    { renderButton({ onClick })}
    <ConfirmationPopover
      closeConfirmationPopover={closeConfirmationPopover}
      open={isOpen}
      popoverId={popoverId}
      anchorEl={anchorEl}
      renderConfirmationButtons={renderConfirmationButtons}
    />
  </div>
);

interface ButtonWithConfirmationProps extends Omit<ViewProps, 'isOpen' | 'anchorEl' | 'popoverId' | 'closeConfirmationPopover' | 'onClick'> {
  onClick?: (e: SyntheticEvent<HTMLButtonElement>, openConfirmation: (e: SyntheticEvent<HTMLButtonElement>) => void) => void
}

const ButtonWithConfirmation = ({
  onClick: onClickProp,
  ...props
}: ButtonWithConfirmationProps): JSX.Element => {
  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useConfirmationPopover('buttonWithConfirmation');

  const onClick = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    if (onClickProp) {
      onClickProp(e, openConfirmationPopover);
    } else {
      openConfirmationPopover(e);
    }
  }, [
    onClickProp,
    openConfirmationPopover,
  ]);

  const hookProps = {
    isOpen,
    anchorEl,
    popoverId,
    closeConfirmationPopover,
    onClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ButtonWithConfirmation;
