import PropTypes from 'prop-types';

import { getDraft } from '~Deprecated/utils/dataManagement';
import { getUserId } from '~Common/utils/localStorage';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_STATE_SHAPE, FEEDBACK_SHAPE } from '~Common/const/proptypes';
import FormDrawer from '~Common/components/Drawers/Forms/Form';

import { DRAWER_WIDTHS } from '~Common/const/drawers';

import { useTabs } from '~Common/hooks/useTabs';
import { useFeedbackById } from '~Deprecated/hooks/feedback/useFeedback';
import { useSubmitFeedbackResponses } from '~Deprecated/hooks/feedback/useSubmitFeedbackResponses';
import { FeedbackType } from '~Feedback/const/interfaces';
import Details from '../Tabs/Details';
import RespondentResponses from '../Tabs/RespondentResponses';
import { addToMeetingTemplate } from '../AddToMeeting/List';

export const respondentFeedbackDetailTemplate = {
  name: 'RESPONDENT_FEEDBACK_DETAIL_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const RespondentFeedbackDetailDrawer = ({
  id,
  drawerState,
  setDrawerState,
  popDrawer,
  popAfter,
  pushDrawer,
  feedbackContext,
  isReadOnly,
  renderBottomButton,
  ...props
}) => {
  const submitResponsesMutation = useSubmitFeedbackResponses({ template: respondentFeedbackDetailTemplate });
  const { feedback } = useFeedbackById({ id, type: FeedbackType.RECEIVED });
  const getResponseDraftKey = (question) => `draft.${getUserId()}.feedback.${question?.questionId}.response`;

  const tabQueryConfig = {
    module: 'feedback',
    workflow: 'respondentDetail',
    defaultValue: 0,
  };

  const { activeTab } = useTabs(tabQueryConfig);

  const onSubmit = () => {
    if (!feedback) {
      return;
    }
    const responses = [];
    feedback.questions.forEach((question) => {
      responses.push({
        questionId: question?.questionId,
        responseText: getDraft(getResponseDraftKey(question)) ?? '',
      });
    });

    submitResponsesMutation({
      id: feedback.id,
      responses,
    });
  };

  const onAddToMeeting = ({ otherUserId, questions }) => {
    pushDrawer({
      drawer: {
        ...addToMeetingTemplate,
        args: {
          otherUserId,
          questions,
        },
      },
    });
  };

  const tabs = [
    ['Responses', <RespondentResponses
      feedback={feedback}
      onAddToMeeting={onAddToMeeting}
      getResponseDraftKey={getResponseDraftKey}
    />],
    ['Details', <Details
      feedback={feedback}
    />],
  ];

  const hookProps = {
    onSubmit,
    drawerName: respondentFeedbackDetailTemplate.name,
    drawerTitle: 'Feedback Details',
    isReadOnly: isReadOnly || feedback?.isCompletedByLoggedInUser || activeTab === 1,
    tabs,
    tabQueryConfig,
    formName: 'respondentDetail',
    showSendButton: true,
    renderBottomButtons: () => (
      <>{renderBottomButton?.()}</>
    ),
  };

  return (
    <FormDrawer
      {...hookProps}
      {...props}
    />
  );
};

RespondentFeedbackDetailDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  popDrawer: PropTypes.func.isRequired,
  popAfter: PropTypes.func.isRequired,
  pushDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  feedbackContext: FEEDBACK_SHAPE,
  isReadOnly: PropTypes.bool,
  renderBottomButton: PropTypes.func,
};

RespondentFeedbackDetailDrawer.defaultProps = {
  feedbackContext: null,
  isReadOnly: false,
  renderBottomButton: null,
};

registerDrawer({
  templateName: respondentFeedbackDetailTemplate.name,
  component: RespondentFeedbackDetailDrawer,
});

export default RespondentFeedbackDetailDrawer;
