import { css } from '@emotion/react';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { Person } from '~Common/const/interfaces';
import { useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { palette } from '~Common/styles/colors';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { SpecialDaysUser, SpecialDay } from '~Home/const/interfaces';

const styles = {
  container: css({
    width: '100%',
    padding: '0.5rem, 0',
    margin: '0.5rem 0',
    display: 'flex',
    alignItems: 'center',
  }),
  avatar: css({
    marginRight: '0.25rem',
    padding: 0,
    border: 0,
    background: 'transparent',
  }),
  avatarSkeleton: css({
    height: '30px',
    maxWidth: '30px',
    width: '100%',
    borderRadius: '5px',
    marginRight: '0.25rem',
  }),
  avatarBlock: css({
    maxWidth: '100%',
    width: '100%',
    height: '1rem',
    borderRadius: '5px',
  }),
  text: css({
    display: 'flex',
    marginLeft: '0 0.5rem',
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
  }),
  namePlate: css({
    padding: '0 0.25rem',

  }),
  name: css({
    fontWeight: '700',
    margin: '0 0.25rem',
  }),
};

interface CelebrateListItemViewInterface {
  id: string,
  emoji: string,
  person?: Person,
  celebrationString: string,
  onPersonClick: () => void,
}
const View = ({
  id, emoji, person, celebrationString, onPersonClick,
}: CelebrateListItemViewInterface):JSX.Element => (

  <div css={styles.container} key={id}>
    <button css={styles.avatar} onClick={onPersonClick}>
      <SquareAvatar
        height={30}
        width={30}
        imgUrl={person?.profileImageUrl}
        name={person ? `${person?.firstName} ${person?.lastName}` : 'Previous User'}
        includeInitials
      />
    </button>
    <div css={styles.text}>
      <div css={styles.namePlate}>
        <span>
          {emoji}
        </span>
        <span css={styles.name}>
          {person ? `${person?.firstName} ${person?.lastName}'s` : 'Previous User/s' }
          {}
        </span>
        <span>
          {`${celebrationString}`}
        </span>
      </div>
    </div>
  </div>
);
const SkeletonView = (): JSX.Element => (
  <div css={styles.container}>
    <SkeletonLoader
      css={styles.avatarSkeleton}
      renderComponent={() => <div />}
      variant="rectangular"
    />
    <SkeletonLoader
      css={styles.avatarBlock}
      renderComponent={() => <div />}
      variant="rectangular"
    />
  </div>
);
const specialDayTranslation = {
  [SpecialDay.Birthday]: 'Birthday',
  [SpecialDay.JoinDate]: 'Work Anniversary',
};

interface CelebrateListItemProps {
  coWorker: SpecialDaysUser,
}
const CelebrateListItem = ({ coWorker }: CelebrateListItemProps):JSX.Element => {
  const dispatch = useDispatch();
  const {
    orgUserId, month, day, year, type,
  } = coWorker;
  const emoji = type === SpecialDay.JoinDate ? '🎉' : '🎂';

  const celebrationString = useMemo(() => {
    let celebrationDate:string;
    const celebrationMomentDate = moment(`${month}/${day}`, 'MM/DD');
    const now = moment().startOf('day');

    if (celebrationMomentDate.valueOf() < now.valueOf()) {
      // If date this year is past, look to next year for next occurrence
      celebrationMomentDate.add(1, 'year');
    }

    const daysFromToday = celebrationMomentDate.diff(now, 'days');

    if (daysFromToday === 0) {
      celebrationDate = 'Today';
    } else if (daysFromToday <= 6) {
      celebrationDate = `on ${celebrationMomentDate.format('dddd')}`;
    } else {
      celebrationDate = `on ${celebrationMomentDate.format('MMM DD')}`;
    }

    if (type === SpecialDay.JoinDate) {
      const celebrationDateYear = celebrationMomentDate.year();
      const yearsAtWork = celebrationDateYear - year;

      if (yearsAtWork === 0) {
        if (daysFromToday === 0) {
          return 'first day is Today';
        }
        return `first day is ${celebrationDate}`;
      }

      return `${yearsAtWork} year ${specialDayTranslation[type]} is ${celebrationDate}`;
    }

    return `${specialDayTranslation[type]} is ${celebrationDate}`;
  }, [type, year, day, month]);

  const { isLoading, person } = useGetPersonById({ orgUserId });
  const [personIsLoading] = useSkeletonLoaders(isLoading);
  const onPersonClick = useCallback(() => {
    dispatch(
      pushDrawerAction({
        drawer: {
          ...baseballCardDrawerTemplate,
          args: {
            id: person?.orgUserId,
          },
        },
      }),
    );
  }, [dispatch, person]);
  const hookProps = {
    id: orgUserId,
    person,
    celebrationString,
    onPersonClick,
    emoji,
  };
  if (personIsLoading) {
    return (
      <SkeletonView />
    );
  }
  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default CelebrateListItem;
