import { css, SerializedStyles } from '@emotion/react';

import { drawerBackgroundColor } from '~Deprecated/ui/styles/colors';

const styles = {
  layout: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  header: css({
    background: drawerBackgroundColor,
    zIndex: 9001,
    flexShrink: 0,
    padding: 'env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left)',
  }),
  body: css({
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  defaultBodyPadding: css({
    paddingTop: '1.25rem',
    paddingRight: 'max(1.5rem, env(safe-area-inset-right))',
    paddingBottom: '1.5rem',
    paddingLeft: 'max(1.5rem, env(safe-area-inset-left))',
  }),
  footer: css({
    overflow: 'hidden',
    flexShrink: 0,
    padding: '0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
  }),
};
interface DrawerLayoutProps {
  renderHeader: () => JSX.Element,
  renderBody: (defaultBodyPadding: SerializedStyles) => JSX.Element,
  renderFooter?: () => JSX.Element,
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void,
  renderModal?: () => JSX.Element,
}

const DrawerLayout = ({
  renderHeader, renderBody, renderFooter, onSubmit, renderModal, ...props
}: DrawerLayoutProps): JSX.Element => {
  const doSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit(event);
    }
  };

  const renderDrawer = (): JSX.Element => (
    <>
      {renderHeader && <div css={styles.header}>{renderHeader()}</div>}
      {renderBody && <div css={styles.body}>{renderBody(styles.defaultBodyPadding)}</div>}
      {renderFooter && <div css={styles.footer}>{renderFooter()}</div>}
      {renderModal && renderModal()}
    </>
  );

  if (onSubmit) {
    return (
      <form css={styles.layout} onSubmit={doSubmit} {...props}>
        {renderDrawer()}
      </form>
    );
  }

  return (
    <div css={styles.layout} {...props}>
      {renderDrawer()}
    </div>
  );
};

export default DrawerLayout;
