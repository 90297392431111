import { css } from '@emotion/react';
import {
  ComponentProps,
  RefObject,
  useEffect,
  useRef,
} from 'react';

import ActionItemComment from '~ActionItems/components/Drawers/CreateEditActionItemDrawer/ActionItemComment';
import { Note, NoteContextType } from '~Common/const/interfaces';
import { useGetNotes } from '~Common/hooks/useGetNotes';
import { palette } from '~Common/styles/colors';
import { usePrevious } from '~Deprecated/hooks/usePrevious';

const styles = {
  label: css({
    fontSize: '1.1875rem',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
  }),
  comment: (isBeingEdited: boolean) => css({
    padding: '0.5rem 1.5rem',
    backgroundColor: isBeingEdited ? palette.neutrals.gray100 : 'initial',

    '&:hover': {
      backgroundColor: palette.neutrals.gray100,
    },
  }),
};

export interface CommentFormData {
  comment: string,
}

export interface ViewProps extends ComponentProps<'div'> {
  actionItemId: string,
  comments?: Note[],
  onEdit: (comment: Note) => void,
  scrollRef: RefObject<HTMLDivElement>,
  editedCommentId?: string,
}

const View = ({
  actionItemId,
  comments,
  onEdit,
  scrollRef,
  editedCommentId,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <h4 css={styles.label}>
      Comments
    </h4>

    <div>
      {comments?.map((comment) => (
        <ActionItemComment
          key={`ai-comment-${comment.id}`}
          actionItemId={actionItemId}
          css={styles.comment(comment.id === editedCommentId)}
          comment={comment}
          onEdit={onEdit}
        />
      ))}

      <div ref={scrollRef} />
    </div>
  </div>
);

export interface ActionItemComments extends ComponentProps<'div'> {
  actionItemId: string,
  onEdit: (comment: Note) => void,
  editedCommentId?: string
}

const ActionItemComments = ({
  actionItemId,
  onEdit,
  editedCommentId,
  ...props
}: ActionItemComments): JSX.Element => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { data } = useGetNotes({
    contextId: actionItemId,
    contextType: NoteContextType.ActionItem,
  });

  const notes = data?.response?.notes;
  const previousNotes = usePrevious(data?.response?.notes) as Note[] | undefined;

  useEffect(() => {
    if (previousNotes && notes && notes.length > previousNotes.length) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [notes, previousNotes]);

  const hookProps = {
    actionItemId,
    comments: notes,
    onEdit,
    scrollRef,
    editedCommentId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItemComments;
