/* eslint-disable no-bitwise */
import {
  array,
  boolean,
  date,
  number,
  object,
  string,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CycleTypeEnum, ParticipantTypeEnum } from '~Reviews/V2/Const/types';

const requiredMsg = 'This field is required.';

const createCycleFormSchema = object({
  name: string().trim().required(requiredMsg),
  description: string().trim(),
  assessmentPeriodStart: date().required(),
  assessmentPeriodEnd: date().required(),
  assessmentDueDate: date(),
  signOffDueDate: date(),
  typeEnum: number(),
  participationEnum: number(),
  includeMentor: boolean(),
  allowedAdminOrgUserIds: array()
    .of(string().required('Each ID must be a string'))
    .required('allowedAdminOrgUserIds is required'),
});
export const createCycleFormResolver = yupResolver(createCycleFormSchema);

export type FormValues = InferType<typeof createCycleFormSchema>;

export interface CreateCycleDTO {
  name: string,
  description: string,
  typeEnum: CycleTypeEnum,
  participationEnum: ParticipantTypeEnum,
  assessmentPeriodStart: string, // Date, but we have to serialize
  assessmentPeriodEnd: string, // same
  assessmentDueDate: string, // same
  signOffDueDate: string, // same
  allowedAdminOrgUserIds: string[],
}

export function conformToDto(data: FormValues): CreateCycleDTO {
  const result: CreateCycleDTO = {
    name: data.name,
    description: data.description ?? '',
    assessmentPeriodStart: data.assessmentPeriodStart?.toUTCString() ?? new Date().toUTCString(),
    assessmentPeriodEnd: data.assessmentPeriodEnd?.toUTCString() ?? new Date().toUTCString(),
    assessmentDueDate: data.assessmentDueDate?.toUTCString() ?? new Date().toUTCString(),
    signOffDueDate: data.signOffDueDate?.toUTCString() ?? new Date().toUTCString(),
    typeEnum: data.typeEnum ?? CycleTypeEnum.Closed,
    participationEnum: data.includeMentor
      ? ParticipantTypeEnum.Reviewee | ParticipantTypeEnum.Reviewer | ParticipantTypeEnum.SecondaryReviewer
      : ParticipantTypeEnum.Reviewee | ParticipantTypeEnum.Reviewer,
    allowedAdminOrgUserIds: data.allowedAdminOrgUserIds,
  };
  return result;
}
