import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';

export const readFile = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  // readAsDataURL always returns as a string
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});

enum PresignedUrlOperationType {
  BulkUserUpload = 'bulkUserUpload',
  OrganizationLogoUpload = 'organizationLogoUpload',
}

interface PresignedUrlPayload {
  operationType: PresignedUrlOperationType,
  extension: string,
}

interface GetPresignedUrlParams {
  orgId: string,
  payload: PresignedUrlPayload,
}

interface GetPresignedUrlResponse {
  resourceUrl: string,
  signedUrl: string,
}

export const getPresignedUrl = ({
  orgId,
  payload,
}: GetPresignedUrlParams): Promise<HttpCallReturn<GetPresignedUrlResponse>> => {
  const URL = {
    host: hosts.auth,
    uri: `/organizations/${orgId}/users/presigned`,
  };

  return postApi(URL, payload);
};

interface FileUploadRequestParams {
  url: string,
  file: Buffer,
  mimeType: string,
}

// This has to be different since it's calling external.
export const uploadFile = ({
  url,
  file,
  mimeType,
}: FileUploadRequestParams): Promise<Response> => {
  const headers = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, OPTION',
    'Content-Type': mimeType,
  });

  if (typeof file === 'string') {
    headers.set('Content-Encoding', 'base64');
  }

  return fetch(url, {
    method: 'PUT',
    body: file,
    headers,
  });
};

interface PresignAndUploadFileParams {
  orgId: string,
  file: File,
}

export const presignAndUploadFile = async ({
  orgId,
  file,
}: PresignAndUploadFileParams): Promise<HttpCallReturn<GetPresignedUrlResponse>> => {
  const parsedFile = await readFile(file);

  const extension = file.name.split('.').pop() as string;

  const presignedUrlResponse = await getPresignedUrl({
    orgId,
    payload: {
      operationType: PresignedUrlOperationType.OrganizationLogoUpload,
      extension,
    },
  });

  if (presignedUrlResponse.status !== 200) {
    throw presignedUrlResponse;
  }

  const uploadFileResponse = await uploadFile({
    url: presignedUrlResponse.response.signedUrl,
    file: Buffer.from(
      parsedFile.replace(/^data:image\/.*;base64,/, ''),
      'base64',
    ),
    mimeType: file.type,
  });

  if (!uploadFileResponse.ok) {
    throw uploadFileResponse;
  }

  return presignedUrlResponse;
};

export const useFileUpload = (
  options: Omit<UseMutationOptions<HttpCallReturn<GetPresignedUrlResponse>, unknown, PresignAndUploadFileParams, unknown>, 'mutationFn'>,
): UseMutationResult<HttpCallReturn<GetPresignedUrlResponse>, unknown, PresignAndUploadFileParams, unknown> => (
  useMutation({
    mutationFn: presignAndUploadFile,
    ...options,
  })
);
