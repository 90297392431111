import PropTypes from 'prop-types';

import { MEETING_SHAPE } from '~Common/const/proptypes';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import DashedBoxWithText from '~Deprecated/ui/components/EmptyStates/DashedBoxWithText';
import AddToMeetingCard from '../../Cards/Meetings';

const NextMeetings = ({ meetings, ...props }) => (
  <>
    <SectionHeader text="Select a meeting to add feedback to" />
    {meetings.length === 0 && (
      <DashedBoxWithText text="You don't have any coachings with person yet." />
    )}
    {meetings?.map((meeting) => (
      <AddToMeetingCard meeting={meeting} {...props} />
    ))}
  </>
);

NextMeetings.propTypes = {
  meetings: PropTypes.arrayOf(MEETING_SHAPE).isRequired,
  selectMeeting: PropTypes.func.isRequired,
};

NextMeetings.defaultProps = {

};

export default NextMeetings;
