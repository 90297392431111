import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import LeadrModal from '~Common/V3/components/LeadrModal';
import { palette } from '~Common/styles/colors';
import { useGoalStatusModalStore } from '~Goals/stores/useGoalsStatusModalStore';
import { Form, Select, TextField } from '~Common/V3/components/uncontrolled';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { FormValues, createStatusUpdateFormResolver, createStatusUpdateFormSchema } from '~Goals/schemata/CreateStatusUpdateSchema';
import MenuItem from '@mui/material/MenuItem';
import { menuItemStyles } from '~Common/V3/components/uncontrolled/styles';
import { PROGRESS_BAR_STYLES } from '~Goals/const/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Froala from '~Common/V3/components/Froala';
import { useCreateStatusUpdate } from '~Goals/hooks/useCreateStatusUpdate';
import { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import { queryClient } from '~Common/const/queryClient';
import { goalKeys } from '~Goals/const/queryKeys';
import { useDispatch } from 'react-redux';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { ClosedGoalStatuses, OpenGoalStatuses, ValidationErrors } from '~Goals/const/types';
import { toast } from '~Common/components/Toasts';
import RequiredIcon from '~Common/V3/components/RequiredIcon';
import GoalStatus from '../TableFilters/GoalStatus';

const styles = {
  ...PROGRESS_BAR_STYLES,
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'clip',
    rowGap: '1.25rem',
  }),
  footer: css({
    alignItems: 'center',
    columnGap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
  }),
  menuItem: menuItemStyles,
  modal: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  modalBody: css({
    flex: 1,
  }),
  percentageInput: css({
    width: '4rem',
  }),
  percentageRow: css({
    alignItems: 'center',
    columnGap: '0.25rem',
    display: 'flex',
    flexDirection: 'row',
  }),
  progressBarAdjustment: css({
    height: '1rem',
    marginLeft: '0.75rem',
    maxWidth: '14rem',

    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: palette.neutrals.gray200,
    },
  }),
  title: css({
    color: palette.brand.indigo,
  }),
  relative: css({
    position: 'relative',
  }),
  requiredIcon: css({
    position: 'absolute',
    top: '.75rem',
    right: '1rem',
    zIndex: 1,
  }),
};

interface ViewProps {
  closeStatusUpdateModal: () => void,
  enableSubmit: boolean,
  formContext: UseFormReturn<FormValues>,
  lastCompletionPercentage: number,
  lastStatus: Goals.GoalStatus,
  onSubmit: SubmitHandler<FormValues>,
  showStatusUpdateModal: boolean,
  canComplete: boolean,
  runValidations: () => void,
}

const View = ({
  closeStatusUpdateModal,
  enableSubmit,
  formContext,
  lastCompletionPercentage,
  lastStatus,
  onSubmit,
  showStatusUpdateModal,
  canComplete,
  runValidations,
}: ViewProps): JSX.Element => (
  <Form
    formContext={formContext}
    onSubmit={onSubmit}
  >
    <LeadrModal
      css={styles.modal}
      open={showStatusUpdateModal}
      onClose={closeStatusUpdateModal}
    >
      <LeadrModal.Header>
        <LeadrModal.Title css={styles.title}>
          Status Update
        </LeadrModal.Title>
      </LeadrModal.Header>
      <LeadrModal.Body css={styles.modalBody}>
        <div css={styles.container}>
          <Select
            name="status"
            defaultValue={lastStatus}
            required
          >
            <ListSubheader>Open</ListSubheader>
            {OpenGoalStatuses.map((statusOption) => (
              <MenuItem
                css={styles.menuItem}
                key={statusOption}
                value={statusOption}
              >
                <GoalStatus status={statusOption} />
              </MenuItem>
            ))}
            {canComplete && [
              <Divider />,
              <ListSubheader>Closed</ListSubheader>,
              ClosedGoalStatuses.map((statusOption) => (
                <MenuItem
                  css={styles.menuItem}
                  key={statusOption}
                  value={statusOption}
                >
                  <GoalStatus status={statusOption} />
                </MenuItem>
              )),
            ]}
          </Select>
          <div css={styles.percentageRow}>
            <TextField
              css={styles.percentageInput}
              name="completionPercentage"
              hideLabel
              defaultValue={lastCompletionPercentage}
              required
            />
            <p>%</p>
            <p>/</p>
            <p>100%</p>
            <LinearProgress
              css={[styles.progressBar(formContext.watch('status')), styles.progressBarAdjustment]}
              variant="determinate"
              value={formContext.watch('completionPercentage') ?? 0}
            />
          </div>
          <div css={styles.relative}>
            <Froala
              // styleOverrides={styles.froalaSpacing}
              enableEmojis
              label="Description"
              name="statusCommentary"
              froalaConfigProps={{
                charCounterCount: true,
                charCounterMax: 1500,
              }}
              richTextEditorProps={{
                name: 'statusCommentary',
                onChange: ({ value: newText }) => formContext.setValue('statusCommentary', newText, { shouldDirty: true }),
              }}
              required
            />
            <RequiredIcon css={styles.requiredIcon} />
          </div>
        </div>
      </LeadrModal.Body>
      <LeadrModal.Footer css={styles.footer}>
        <LeadrButton
          data-test-id="goalAddStatusModalSaveChanges"
          onClick={runValidations}
          size="small"
          type="submit"
          disabled={!enableSubmit}
        >
          Save Changes
        </LeadrButton>
        <LeadrButton
          data-test-id="goalAddStatusModalCancelChanges"
          onClick={closeStatusUpdateModal}
          size="small"
          variant="ghost"
        >
          Cancel
        </LeadrButton>
      </LeadrModal.Footer>
    </LeadrModal>
  </Form>
);

export const GoalUpdateStatusModal = (): JSX.Element => {
  const {
    closeStatusUpdateModal,
    goal,
    showStatusUpdateModal,
  } = useGoalStatusModalStore((state) => ({
    closeStatusUpdateModal: state.closeStatusUpdateModal,
    goal: state.displayedGoal,
    showStatusUpdateModal: state.showStatusUpdateModal,
  }));

  const { goalId = '' } = goal ?? {};
  const canComplete = goal?.permissions.includes(Goals.GoalPermission.CanCompleteGoal) ?? false;

  const { mutate: createGoalStatusMutation, isPending: isLoading } = useCreateStatusUpdate({ goalId });

  const lastStatusUpdate = goal?.statusUpdates?.[0];

  const {
    status: lastStatus = Goals.GoalStatus.OnTrack,
    completionPercentage: lastCompletionPercentage = 0,
  } = lastStatusUpdate ?? {};

  const defaultValues: FormValues = {
    completionPercentage: lastCompletionPercentage,
    status: lastStatus,
    statusCommentary: '',
  };

  const formContext = useForm<FormValues>({
    defaultValues,
    resolver: createStatusUpdateFormResolver,
  });

  useEffect(() => {
    formContext.reset(defaultValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStatusUpdate]);

  const runValidations = (): void => {
    const data = formContext.getValues();
    createStatusUpdateFormSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        createGoalStatusMutation({
          goalId,
          statusUpdate: data,
        }, { onSuccess });
      })
      .catch((err: ValidationErrors) => {
        err.errors.forEach((error) => {
          toast.error(error);
        });
      });
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    createGoalStatusMutation({
      goalId,
      statusUpdate: data,
    }, { onSuccess });
  };

  const dispatch = useDispatch();

  const onSuccess = async (): Promise<void> => {
    closeStatusUpdateModal();
    await queryClient.invalidateQueries({ queryKey: goalKeys.all });

    // @ts-expect-error Remove when redux is done
    dispatch(popDrawer({ popAll: true }));
  };

  if (!goal || !lastStatus) {
    return <></>;
  }

  const enableSubmit = !isLoading;

  const hookProps = {
    closeStatusUpdateModal,
    enableSubmit,
    formContext,
    lastCompletionPercentage,
    lastStatus,
    onSubmit,
    showStatusUpdateModal,
    canComplete,
    runValidations,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
