import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const styles = {
  container: css``,
  previewContainer: css`
    margin-top: 1.25rem;
  `,
};

const QuestionDetails = ({ renderOptions, renderPreview }) => (
  <div css={styles.container}>
    {renderOptions && (
      <div>
        {renderOptions()}
      </div>
    )}
    {renderPreview && (
      <div css={styles.previewContainer}>
        {renderPreview()}
      </div>
    )}
  </div>
);

QuestionDetails.propTypes = {
  renderOptions: PropTypes.func,
  renderPreview: PropTypes.func,
};

QuestionDetails.defaultProps = {
  renderOptions: null,
  renderPreview: null,
};

export default QuestionDetails;
