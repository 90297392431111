/* eslint-disable no-bitwise */
import { css } from '@emotion/react';
import {
  useMemo, useState,
} from 'react';
import { getFullName } from '~Admin/components/tables/ActiveUsers';
import AdminActionMenu from '~Admin/components/tables/AdminActionMenu';
import DataGridTable from '~Common/components/Tables/DataGridTable';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import {
  faClipboardUser, faPaperPlane, faTrash, faLock, faArrowRotateRight, faFileExport,
} from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';

import {
  GridColumns, GridEventListener, GridRenderCellParams, GridSelectionModel,
} from '@mui/x-data-grid';
import { nudgeReviewParticipantsProps } from '~Reviews/V2/Hooks/useNudgeParticipants';
import { navigateAction } from '~Deprecated/actions/navigate';
import {
  REVIEWS_BY_ID_V2, ADMIN_REVIEWS_V2,
} from '~Common/const/routes';
import { useDispatch } from 'react-redux';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useHistory } from 'react-router';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { UseMutateFunction } from '@tanstack/react-query';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import {
  ReviewCycleWithParticipants, ParticipantTypeEnum, ReviewCycleDetail,
  ReviewCycleStatusEnum, userRowsProps, modalParamsProps, ReviewShape,
  RevieweeReviewStatuses, inProgressConverstion, Review,
} from '../Const/types';
import { MenuItem } from './ActionMenu';
import { PAGE_STYLES, BUTTON_STYLES } from '../Const/pageStyles';
import {
  buildRadioOptions, getParticipantTypeName, getReopenReviewIds, getReviewIDs,
} from '../Const/functions';
import { returnReviewParticipantType } from '../Hooks/returnReviewParticipantType';
import StatusIndicator from './DataTable/StatusIndicator';
import FauxDropdown from './FauxDropdown';
import DisplayCycleModalsContainer from './DisplayCycleModalsContainer';
import { useDeleteCycle } from '../Hooks/useDeleteCycle';
import { ContextButtons } from './ContextButtons';
import { useDeleteReview } from '../Hooks/useDeleteReview';
import { useCompleteCycle } from '../Hooks/useCompleteCycle';
import { convertFinishedReviewParticipantsList } from '../Const/convertFinishedReviewParticipantsList';
import { convertDeletedReviewParticipantsList } from '../Const/convertDeletedReviewParticipantsList';
import { useReopenReviews } from '../Hooks/useReopenReviews';
import { useCreateCsvInBackground, useGetCSV } from '../Hooks/useGetCSV';
import { useReturnReviewCycleParticipantType } from '../Hooks/useReturnReviewCycleParticipantType';

const styles = {
  ...PAGE_STYLES,
  ...BUTTON_STYLES,
  tableCount: css({
    textAlign: 'right',
    color: palette.neutrals.gray500,
    fontSize: '.75rem',
    marginTop: '-1rem',
    marginBottom: '.5rem',
  }),
  icon: css({
    marginRight: '0.5rem',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '15.625rem',
  }),
  listSection: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridtemplateColumns: 'repeat(auto-fill)',
    width: '100%',
    padding: '1rem 0',
  }),
  Avatar: css({
    marginRight: '.75rem',
  }),
  tableLockIcon: css({
    color: palette.brand.indigo,
    padding: '8.5px',

    '&>*': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    svg: {
      width: '.75rem !important',
      height: '.75rem !important',
    },
  }),
};

interface ViewProps {
  activeReviewCycle: ReviewCycleWithParticipants,
  tableColumnHeaders: GridColumns<userRowsProps>,
  tableColumnHeadersCompleted: GridColumns<userRowsProps>,
  userRows: userRowsProps[],
  userRowsCompleted: userRowsProps[],
  selectionModel: GridSelectionModel,
  setSelectionModel: (rowIds: GridSelectionModel) => void,
  handleCellClickEvent: GridEventListener<'cellClick'>,
  isAdmin: boolean,
  bulkMenuItems: MenuItem[][],
  reOpenCycle: () => void,
  showManagerView: boolean,
  openDeleteModal: boolean,
  bulkActionModalParams: string[],
  removeMultipleReviews: () => void,
  handleModalDisplay: (value: boolean, params?: modalParamsProps) => void,
  setBulkActionModalParams:(value: string[]) => void,
  modalParams: modalParamsProps | undefined,
  removeSingleReview: () => void,
  setOpenCompleteModal: (value: boolean) => void,
  nudgeMultipleParticipants: (isFinishing: boolean) => void,
  deleteCycle: () => void,
  openDeleteCycleModal: boolean,
  openFinishModal: boolean,
  completeCycle: (value: boolean) => void,
  handleModalDisplayDeleteCycle: (value: boolean) => void,
  openReopenModal: boolean,
  filterParticipantsOnCycle: ReviewCycleDetail | undefined,
  setopenReopenModal: (value: boolean) => void,
  openReopenModalConfirm: boolean,
  setOpenReopenModalConfirm: (value: boolean) => void,
  reOpenReviews: () => void,
  isAnyManagerOnCycle: boolean,
}
const View = ({
  activeReviewCycle,
  tableColumnHeaders,
  tableColumnHeadersCompleted,
  userRows,
  userRowsCompleted,
  selectionModel,
  setSelectionModel,
  handleCellClickEvent,
  isAdmin,
  bulkMenuItems,
  openDeleteModal,
  bulkActionModalParams,
  removeMultipleReviews,
  handleModalDisplay,
  setBulkActionModalParams,
  modalParams,
  removeSingleReview,
  setOpenCompleteModal,
  nudgeMultipleParticipants,
  deleteCycle,
  openDeleteCycleModal,
  openFinishModal,
  completeCycle,
  handleModalDisplayDeleteCycle,
  reOpenCycle,
  showManagerView,
  openReopenModal,
  filterParticipantsOnCycle,
  setopenReopenModal,
  openReopenModalConfirm,
  setOpenReopenModalConfirm,
  reOpenReviews,
  isAnyManagerOnCycle,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="contextButtons"
      renderContents={() => (
        <>
          {activeReviewCycle.statusEnum !== ReviewCycleStatusEnum.Finished
            && isAdmin && !showManagerView && (
            <LeadrButton
              data-test-id="reviewsCompleteCycle"
              onClick={() => completeCycle(false)}
            >
              <LeadrButton.IconAndText icon={faClipboardUser} text="Finish Cycle" />
            </LeadrButton>
          )}
          {activeReviewCycle.statusEnum === ReviewCycleStatusEnum.Finished
            && isAdmin && !showManagerView && (
              <LeadrButton
                data-test-id="reviewsReopenCycle"
                onClick={reOpenCycle}
              >
                Reopen Cycle
              </LeadrButton>
          )}
        </>
      )}
    />
    {(isAdmin || isAnyManagerOnCycle) && (
      <>
        <FauxDropdown
          menuItems={bulkMenuItems}
          label="Bulk Action:"
          listEmptyText="Select Action"
          activeFilters=""
          disabled={selectionModel.length === 0}
          subTitle={`${selectionModel.length} Participants Selected`}
        />
      </>
    )}
    <p css={styles.tableCount}>
      {`${userRows?.length || 0} Active Reviewee${userRows?.length === 1 ? '' : 's'}`}
    </p>
    {activeReviewCycle?.statusEnum !== ReviewCycleStatusEnum.Finished && (
      <DataGridTable
        rows={userRows}
        columns={tableColumnHeaders}
        pageSize={25}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleCellClickEvent={handleCellClickEvent}
        isLoading={false}
      />
    )}
    {activeReviewCycle?.statusEnum === ReviewCycleStatusEnum.Finished && (
    <>
      <DataGridTable
        rows={userRowsCompleted}
        columns={tableColumnHeadersCompleted}
        pageSize={25}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        handleCellClickEvent={handleCellClickEvent}
        isLoading={false}
        checkboxSelection={false}
      />
    </>
    )}
    <DisplayCycleModalsContainer
      openDeleteModal={openDeleteModal}
      bulkActionModalParams={bulkActionModalParams}
      removeMultipleReviews={removeMultipleReviews}
      handleModalDisplay={handleModalDisplay}
      setBulkActionModalParams={setBulkActionModalParams}
      modalParams={modalParams}
      removeSingleReview={removeSingleReview}
      setOpenCompleteModal={setOpenCompleteModal}
      nudgeMultipleParticipants={nudgeMultipleParticipants}
      deleteCycle={deleteCycle}
      title={activeReviewCycle.name}
      openDeleteCycleModal={openDeleteCycleModal}
      openFinishModal={openFinishModal}
      completeCycle={completeCycle}
      handleModalDisplayDeleteCycle={handleModalDisplayDeleteCycle}
      openReopenModal={openReopenModal}
      filterParticipantsOnCycle={filterParticipantsOnCycle as ReviewCycleDetail}
      setopenReopenModal={setopenReopenModal}
      setOpenReopenModalConfirm={setOpenReopenModalConfirm}
      openReopenModalConfirm={openReopenModalConfirm}
      reOpenReviews={reOpenReviews}
    />
  </>
);

interface ReviewCycleTableProps {
  activeReviewCycle?: ReviewCycleWithParticipants | ReviewCycleDetail,
  setSingleReviewModel: (value: string[]) => void,
  filterParticipantsOnCycle: ReviewCycleDetail | undefined,
  showManagerView: boolean,
  selectionModel: GridSelectionModel,
  setSelectionModel: (rowIds: GridSelectionModel) => void,
  singleReviewModel: string[],
  handleModalDisplayDeleteCycle: (value: boolean) => void,
  openDeleteCycleModal: boolean,
  nudgeParticipantsMutation: UseMutateFunction<HttpCallReturn<void>, unknown, nudgeReviewParticipantsProps, void>,
}

const ReviewCycleTable = ({
  activeReviewCycle,
  setSingleReviewModel,
  filterParticipantsOnCycle,
  showManagerView,
  selectionModel,
  setSelectionModel,
  singleReviewModel,
  handleModalDisplayDeleteCycle,
  openDeleteCycleModal,
  nudgeParticipantsMutation,
}: ReviewCycleTableProps): JSX.Element => {
  const history = useHistory();
  const { isAdmin } = useUserPermissions();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [bulkActionModalParams, setBulkActionModalParams] = useState<string[]>([]);
  const [modalParams, setModalParams] = useState<modalParamsProps>();
  const [openFinishModal, setOpenCompleteModal] = useState(false);
  const [openReopenModal, setopenReopenModal] = useState(false);
  const [openReopenModalConfirm, setOpenReopenModalConfirm] = useState(false);

  const deleteReviewMutation = useDeleteReview();
  const completeCycleMutation = useCompleteCycle();
  const reOpenReviewsMutation = useReopenReviews();

  const removeMultipleReviews = (): void => {
    deleteReviewMutation({ cycleId: activeReviewCycle?.uid, reviewIDs: selectionModel as string[] }, { onSuccess });
  };
  const removeSingleReview = (): void => {
    deleteReviewMutation({ cycleId: activeReviewCycle?.uid, reviewIDs: singleReviewModel }, { onSuccess });
  };
  // TODO: Update all the modals to use this handler - Less props to pass
  const handleModalDisplay = (value: boolean, params?: modalParamsProps): void => {
    setOpenDeleteModal(value);
    if (params) {
      setModalParams(params);
    }
  };
  const handleModalDisplayMultipleUser = (value: boolean, params: GridSelectionModel): void => {
    setOpenDeleteModal(value);
    setModalParams(undefined);
    const participants = convertDeletedReviewParticipantsList(activeReviewCycle as unknown as ReviewCycleDetail, params);
    setBulkActionModalParams(participants);
  };
  const handleModalDisplayReopenReview = (value: boolean, params?: modalParamsProps): void => {
    setOpenReopenModalConfirm(value);
    setModalParams(params ?? undefined);
  };

  const deleteCycle = (): void => {
    deleteCycleMutation({ id: activeReviewCycle?.uid ?? '' }, { onSuccess: onSuccessCycleDelete });
  };
  const completeCycle = (confirmedFinish: boolean): void => {
    if (confirmedFinish) {
      completeCycleMutation({ cycleId: activeReviewCycle?.uid ?? '' }, { onSuccess });
    } else {
      const participants = convertFinishedReviewParticipantsList(activeReviewCycle as unknown as ReviewCycleDetail);
      setOpenCompleteModal(true);
      setBulkActionModalParams(participants);
    }
  };
  const nudgeMultipleParticipants = (isFinishing: boolean): void => {
    if (isFinishing) {
      const reviewIDs = getReviewIDs(activeReviewCycle as unknown as ReviewCycleDetail);
      if (reviewIDs) {
        nudgeParticipantsMutation({ cycleId: activeReviewCycle?.uid, reviewIDs });
      }
    } else {
      nudgeParticipantsMutation({ cycleId: activeReviewCycle?.uid, reviewIDs: selectionModel as string[] });
    }
  };

  const nudgeSingleParticipant = (id: string[]): void => {
    nudgeParticipantsMutation({ cycleId: activeReviewCycle?.uid, reviewIDs: id });
  };

  const reOpenCycle = (): void => {
    setopenReopenModal(true);
  };

  const reOpenReviews = (): void => {
    const reviewIDs = selectionModel as string[];
    if (reviewIDs.length > 0) {
      reOpenReviewsMutation({ reviewIDs }, { onSuccess: () => setOpenReopenModalConfirm(false) });
    } else {
      const reviewID = modalParams?.id as string;
      reOpenReviewsMutation({ reviewIDs: [reviewID] }, { onSuccess: () => setOpenReopenModalConfirm(false) });
    }
  };

  const deleteCycleMutation = useDeleteCycle();

  const dispatch = useDispatch();
  const onSuccessCycleDelete = (): void => {
    dispatch(navigateAction({
      pathname: ADMIN_REVIEWS_V2,
    }));
  };
  const onSuccess = (): void => {
    setOpenDeleteModal(false);
  };
  const getReviewsCSV = useGetCSV();
  const createReviewCSVInBackground = useCreateCsvInBackground();
  const enableReviewExportBackgroundJob = useFeatureFlag('reviewExportBackgroundJob');
  const getExportCSV = (orgUserIds?: string[] | undefined): void => {
    if (enableReviewExportBackgroundJob) {
      createReviewCSVInBackground({
        cycleId: activeReviewCycle?.uid ?? '',
        orgUserIds: orgUserIds ?? [],
      });
    } else if (orgUserIds) {
      getReviewsCSV({ cycleId: activeReviewCycle?.uid ?? '', orgUserIds });
    } else {
      getReviewsCSV({ cycleId: activeReviewCycle?.uid ?? '' });
    }
  };
  const { notStartedReviewIds, completeReviewIds } = getReopenReviewIds(activeReviewCycle as ReviewCycleDetail);

  const menuItems = (param: modalParamsProps): MenuItem[][] => {
    const items: MenuItem[][] = [];

    const firstRow: MenuItem[] = [
      {
        text: 'View Review',
        icon: faClipboardUser,
        onClick: () => {
          dispatch(
            navigateAction({
              pathname: REVIEWS_BY_ID_V2,
              params: {
                reviewId: param.id,
              },
            }),
          );
        },
        dataTestId: 'actionMenuItem-viewReview',
      },
    ];

    if (notStartedReviewIds.includes(param.id) && isAdmin) {
      firstRow.push({
        text: `Reopen ${param.name}'s Review`,
        icon: faArrowRotateRight,
        onClick: () => {
          handleModalDisplayReopenReview(true, param);
        },
        dataTestId: 'actionMenuItem-reopenReview',
      });
    }

    if (activeReviewCycle?.statusEnum !== ReviewCycleStatusEnum.Finished && isAdmin) {
      firstRow.push(
        {
          text: `Remind ${param.name} & Reviewer(s)`,
          icon: faPaperPlane,
          onClick: () => {
            nudgeSingleParticipant([param.id]);
          },
          dataTestId: 'actionMenuItem-remindReviewer',
        },
        {
          text: `Delete ${param.name}'s Review`,
          icon: faTrash,
          onClick: () => {
            handleModalDisplay(true, param);
            setSingleReviewModel([param.id]);
          },
          dataTestId: 'actionMenuItem-deleteReview',
        },
      );
    }

    items.push(firstRow);

    if (completeReviewIds.includes(param.id) || notStartedReviewIds.includes(param.id)) {
      const exportCSV = {
        text: 'Export CSV',
        icon: faFileExport,
        onClick: () => {
          const orgUserIds = [param.orgUserId];
          getExportCSV(orgUserIds as string[]);
        },
        dataTestId: 'actionMenuItem-exportCSV',
      };
      const index = items[0].length - 1;
      if (activeReviewCycle?.statusEnum !== ReviewCycleStatusEnum.Finished && isAdmin) {
        // Insert at the penultimate position
        items[0].splice(index, 0, exportCSV);
      } else {
        firstRow.push(exportCSV);
      }
    }

    return items;
  };

  const getBulkMenuItems = (hasAdminAccess: boolean): MenuItem[][] => {
    let bulkMenuItems: MenuItem[][] = [];
    const { someReviewsCompleted } = buildRadioOptions(activeReviewCycle as unknown as ReviewCycleDetail);
    if (hasAdminAccess) {
      bulkMenuItems = [[
        {
          text: 'Delete Review(s)',
          icon: faTrash,
          onClick: () => {
            handleModalDisplayMultipleUser(true, selectionModel);
          },
          dataTestId: 'actionMenuItem-deleteReview',
        },
      ]];

      if (activeReviewCycle?.statusEnum !== ReviewCycleStatusEnum.Finished) {
        bulkMenuItems[0].unshift(
          {
            text: 'Send Reminder(s)',
            icon: faPaperPlane,
            onClick: () => {
              nudgeMultipleParticipants(false);
            },
            dataTestId: 'actionMenuItem-sendReminder',
          },
        );
      }

      if (someReviewsCompleted || activeReviewCycle?.statusEnum === ReviewCycleStatusEnum.Finished) {
        const index = bulkMenuItems[0].length - 1;
        bulkMenuItems[0].splice(index, 0, {
          text: 'Reopen Review(s)',
          icon: faArrowRotateRight,
          onClick: () => {
            handleModalDisplayReopenReview(true);
          },
          dataTestId: 'actionMenuItem-reopenReview',
        });
      }
    }
    // No longer care if you're an admin or not for this one
    if ((activeReviewCycle?.statusEnum === ReviewCycleStatusEnum.Finished && someReviewsCompleted) || someReviewsCompleted) {
      const index = Array.isArray(bulkMenuItems[0]) ? bulkMenuItems[0].length - 1 : 0;
      const exportCSV = {
        text: 'Export CSV',
        icon: faFileExport,
        onClick: () => {
          const selectedReviews: Review[] = (activeReviewCycle as ReviewCycleDetail)?.reviews.filter((review) => selectionModel.includes(review.uid)) ?? [];
          const orgUserIds = selectedReviews.map((review): string | undefined => {
            const participant = review.participants.find((participants) => participants.participantTypeEnum === 1);
            return participant ? participant.orgUserId : undefined;
          });
          getExportCSV(orgUserIds as string[]);
        },
        dataTestId: 'actionMenuItem-exportCSV',
      };
      // If index is 0 it means we're not an admin and we need to wrap the exportCSV in an array because MenuItem[][] is the type
      if (index === 0) {
        bulkMenuItems.push([exportCSV]);
      } else {
        bulkMenuItems[0].splice(index, 0, exportCSV);
      }
    }

    return bulkMenuItems;
  };
  const { isAnyManagerOnCycle } = useReturnReviewCycleParticipantType(activeReviewCycle as ReviewCycleDetail);
  const navigateToReview = (id: string): void => {
    dispatch(navigateAction({
      pathname: REVIEWS_BY_ID_V2,
      params: {
        reviewId: id,
      },
    }));
  };

  const setTableStatus = (status: string): string => {
    if (!status) {
      return 'N/A';
    }

    if (inProgressConverstion.includes(status as RevieweeReviewStatuses)) {
      return 'In Progress';
    }
    return status;
  };

  const userRows = useMemo(() => filterParticipantsOnCycle?.reviews?.flatMap(
    (review) => review?.participants?.filter((currentValue) => (currentValue.participantTypeEnum === ParticipantTypeEnum.Reviewee))
      .map((currentValue) => {
        const { participantReviewTypeEnum: participantType, nextStep } = returnReviewParticipantType(review as unknown as ReviewShape);
        const { revieweeStatus, reviewerStatus, secondaryReviewerStatus } = review;
        return ({
          id: review.uid,
          name: { name: `${currentValue.firstName} ${currentValue.lastName}`, image: currentValue.profileImageUrl },
          firstName: currentValue.firstName,
          lastName: currentValue.lastName,
          orgUserId: currentValue.orgUserId,
          revieweeStatus: setTableStatus(revieweeStatus),
          reviewerStatus: setTableStatus(reviewerStatus),
          secondaryReviewerStatus: setTableStatus(secondaryReviewerStatus),
          nextStep,
          yourRole: getParticipantTypeName(participantType),
          actions: review.uid,
        });
      }),
  ), [filterParticipantsOnCycle]) as userRowsProps[];

  const userRowsCompleted = useMemo(() => filterParticipantsOnCycle?.reviews?.flatMap(
    (review) => review?.participants?.filter((currentValue) => (currentValue.participantTypeEnum === ParticipantTypeEnum.Reviewee))
      .map((currentValue) => {
        const { participantReviewTypeEnum: participantType, nextStep } = returnReviewParticipantType(review as unknown as ReviewShape);
        const { revieweeStatus, reviewerStatus, secondaryReviewerStatus } = review;

        return ({
          id: review.uid,
          lock: review.uid,
          name: { name: `${currentValue.firstName} ${currentValue.lastName}`, image: currentValue.profileImageUrl },
          firstName: currentValue.firstName,
          lastName: currentValue.lastName,
          orgUserId: currentValue.orgUserId,
          revieweeStatus: setTableStatus(revieweeStatus),
          reviewerStatus: setTableStatus(reviewerStatus),
          secondaryReviewerStatus: setTableStatus(secondaryReviewerStatus),
          nextStep,
          yourRole: getParticipantTypeName(participantType),
          actions: review.uid,
        });
      }),
  ), [filterParticipantsOnCycle]) as userRowsProps[];

  // eslint-disable-next-line max-len
  const isMatrixOrg = activeReviewCycle ? FlaggedEnum(activeReviewCycle.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer) : false;
  const tableColumnHeaders: GridColumns<userRowsProps> = [
    {
      field: 'name',
      headerName: 'Name',
      maxWidth: 750,
      flex: 1,
      renderCell: (cellValues: { row: { name: { image: string; name: string; }; }; }) => (
        <>
          <SquareAvatar
            imgUrl={cellValues.row.name.image}
            name={`${cellValues.row.name.name}`}
            width={30}
            height={30}
            includeInitials
            css={styles.Avatar}
          />
          <div className="MuiDataGrid-cellContent">
            {cellValues.row.name.name}
          </div>
        </>
      ),
      type: 'object',
      valueGetter: (params: { row: { name: { name: string; }; }; }) => params.row.name.name,
    },
    {
      field: 'orgUserId',
      headerName: 'Org User Id',
      maxWidth: 750,
      flex: 1,
      hide: true,
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      maxWidth: 750,
      flex: 1,
      valueGetter: getFullName,
      hide: true,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      maxWidth: 750,
      flex: 1,
      hide: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      maxWidth: 750,
      flex: 1,
      hide: true,
    },

    {
      field: 'revieweeStatus',
      headerName: showManagerView ? 'Reviewee Status' : 'Reviewee',
      maxWidth: 750,
      flex: 1,
    },
    {
      field: 'reviewerStatus',
      headerName: 'Reviewer',
      maxWidth: 750,
      flex: 1,
      hide: showManagerView,
    },
    {
      field: 'secondaryReviewerStatus',
      headerName: 'Secondary Reviewer',
      maxWidth: 750,
      flex: 1,
      hide: !isMatrixOrg || showManagerView,
    },
    {
      field: 'yourRole',
      headerName: 'Your Role',
      maxWidth: 750,
      flex: 1,
      hide: !showManagerView,
    },
    {
      field: 'nextStep',
      headerName: 'Your Next Step',
      maxWidth: 750,
      flex: 1,
      hide: !showManagerView,
      renderCell: (params: GridRenderCellParams<unknown, userRowsProps, unknown>) => (
        <>
          <StatusIndicator
            status={params.formattedValue}
          />
          <div className="MuiDataGrid-cellContent">
            <>{params.formattedValue}</>
          </div>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      maxWidth: 100,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (cellValues: { row: {firstName: string; id: string; orgUserId: string}; }) => (
        <AdminActionMenu menuItems={menuItems({ id: cellValues.row.id, name: cellValues.row.firstName, orgUserId: cellValues.row.orgUserId })} />
      ),
      flex: 1,
    },
  ];
  const tableColumnHeadersCompleted: GridColumns<userRowsProps> = [
    {
      field: 'lock',
      headerName: '',
      maxWidth: 50,
      flex: 1,
      renderCell: () => (
        <>
          <IconButton
            tooltip="Locked"
            type="button"
            icon={faLock}
            onClick={undefined}
            css={styles.tableLockIcon}
            size="small"
          />
        </>
      ),
      renderHeader: () => (
        <>
          <IconButton
            tooltip="Locked"
            type="button"
            icon={faLock}
            onClick={undefined}
            css={styles.tableLockIcon}
            size="small"
          />
        </>
      ),
      type: 'object',
    },
    {
      field: 'name',
      headerName: 'Name',
      maxWidth: 750,
      flex: 1,
      renderCell: (cellValues: { row: { name: { image: string; name: string; }; }; }) => (
        <>
          <SquareAvatar
            imgUrl={cellValues.row.name.image}
            name={`${cellValues.row.name.name}`}
            width={30}
            height={30}
            includeInitials
            css={styles.Avatar}
          />
          <div className="MuiDataGrid-cellContent">
            {cellValues.row.name.name}
          </div>
        </>
      ),
      type: 'object',
      valueGetter: (params: { row: { name: { name: string; }; }; }) => params.row.name.name,
    },
    {
      field: 'orgUserId',
      headerName: 'Org User Id',
      maxWidth: 750,
      flex: 1,
      hide: true,
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      maxWidth: 750,
      flex: 1,
      valueGetter: getFullName,
      hide: true,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      maxWidth: 750,
      flex: 1,
      hide: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      maxWidth: 750,
      flex: 1,
      hide: true,
    },
    {
      field: 'revieweeStatus',
      headerName: !showManagerView ? 'Reviewee' : 'Reviewee Status',
      maxWidth: 750,
      flex: 1,
    },
    {
      field: 'reviewerStatus',
      headerName: 'Reviewer',
      maxWidth: 750,
      flex: 1,
      hide: showManagerView,
    },
    {
      field: 'secondaryReviewerStatus',
      headerName: 'Secondary Reviewer',
      maxWidth: 750,
      flex: 1,
      hide: !isMatrixOrg || showManagerView,
    },
    {
      field: 'yourRole',
      headerName: 'Your Role',
      maxWidth: 750,
      flex: 1,
      hide: !showManagerView,
    },
    {
      field: 'nextStep',
      headerName: 'Your Next Step',
      maxWidth: 750,
      flex: 1,
      hide: !showManagerView,
      renderCell: (params: GridRenderCellParams<unknown, userRowsProps, unknown>) => (
        <>
          <StatusIndicator
            status={params.formattedValue}
          />
          <div className="MuiDataGrid-cellContent">
            <>{params.formattedValue}</>
          </div>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      maxWidth: 100,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (cellValues: { row: { id: string;}; }) => (
        <LeadrButton
          variant="text"
          data-test-id="reviewsReopenCycle"
          onClick={() => navigateToReview(cellValues.row.id)}
        >
          View Review
        </LeadrButton>
      ),
      flex: 1,
    },
  ];
  const handleCellClickEvent: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== 'actions' && params.field !== '__check__') {
      history.push(REVIEWS_BY_ID_V2.replace(':reviewId', params.id as string), { prevPage: history.location.pathname });
    }
  };

  const hookProps = {
    activeReviewCycle: activeReviewCycle as unknown as ReviewCycleWithParticipants,
    tableColumnHeaders,
    tableColumnHeadersCompleted,
    userRows,
    userRowsCompleted,
    selectionModel,
    setSelectionModel,
    handleCellClickEvent,
    isAdmin,
    bulkMenuItems: getBulkMenuItems(isAdmin),
    openDeleteModal,
    bulkActionModalParams,
    removeMultipleReviews,
    handleModalDisplay,
    setBulkActionModalParams,
    modalParams,
    removeSingleReview,
    setOpenCompleteModal,
    nudgeMultipleParticipants,
    deleteCycle,
    openDeleteCycleModal,
    openFinishModal,
    completeCycle,
    handleModalDisplayDeleteCycle,
    reOpenCycle,
    showManagerView,
    openReopenModal,
    filterParticipantsOnCycle,
    setopenReopenModal,
    openReopenModalConfirm,
    setOpenReopenModalConfirm,
    reOpenReviews,
    isAnyManagerOnCycle,
  };

  if (!activeReviewCycle) {
    return <></>;
  }
  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ReviewCycleTable };
export default ReviewCycleTable;
