import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useEffect } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import { useGetAssignedLearningDetail } from '~Learning/hooks/assigned/useGetAssignedLearningDetail';
import { CardSkeleton } from '~Common/V3/components/Card';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { RequestedLearningDrawerTab, useRequestedLearningDrawerTab } from '~Learning/hooks/utils/useRequestedLearningDrawerTab';
import { useRequestedLearningAssigneeResults } from '~Learning/hooks/utils/useRequestedLearningAssigneeResults';
import DetailsTab from './Tabs/DetailsTab';
import QuestionsTab from './Tabs/QuestionsTab';
import PersonTab from './Tabs/PersonTab';
import { LearningParticipantsDrawerState, learningParticipantsDrawerTemplate } from '../LearningParticipantsDrawer';

const styles = {
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
  cardSkeleton: css({
    minWidth: '100%',
    height: '12.5rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
};

export const requestedLearningDrawerTemplate = {
  name: 'REQUESTED_LEARNING_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface RequestedLearningDrawerProps extends DrawerProps<LearningParticipantsDrawerState> {
  learningId: string,
  transitionState: TRANSITION_STATE,
  learningOwnerId?: string,
  isReadOnly?: boolean,
}

const RequestedLearningDrawer = ({
  learningId,
  transitionState,
  pushDrawer,
  popDrawer,
  drawerState,
  learningOwnerId,
  isReadOnly,
}: RequestedLearningDrawerProps): JSX.Element => {
  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: requestedLearningDrawerTemplate.name });
  };
  const [, setPersonIndex] = useRequestedLearningAssigneeResults();
  const showViewParticipantsButton = !drawerState.isLearningParticipantsDrawerOpen;
  const { detail: learning, isLoading } = useGetAssignedLearningDetail({ learningId, isReadOnly, viewPerspectiveUid: learningOwnerId });

  const [activeTab, setActiveTab] = useRequestedLearningDrawerTab();

  const isMobile = useIsMobileQuery();

  useEffect(() => {
    const hasEntered = transitionState === TRANSITION_STATE.ENTERED;

    if (!isMobile && hasEntered && learning?.assignees) {
      pushDrawer({
        drawer: {
          ...learningParticipantsDrawerTemplate,
          args: {
            assignees: learning.assignees,
            learningId: learning.id,
          },
        },
      });
    }

    return () => {
      setActiveTab(RequestedLearningDrawerTab.DETAILS);
      setPersonIndex(0);
    };
  }, [isMobile, transitionState, learning?.assignees, learning?.id, setActiveTab, pushDrawer, setPersonIndex]);

  const hookProps = {
    renderHeader: () => (
      <TabDrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
        title="Learning Results"
        renderTabNav={(tabNavStyles: SerializedStyles) => (
          <div css={tabNavStyles}>
            <TabNavItem
              css={styles.tabNavItem}
              isActive={activeTab === RequestedLearningDrawerTab.DETAILS}
              onClick={() => setActiveTab(RequestedLearningDrawerTab.DETAILS)}
              renderNavItem={() => (
                <div>Details</div>
              )}
            />
            <TabNavItem
              isActive={activeTab === RequestedLearningDrawerTab.QUESTIONS}
              onClick={() => setActiveTab(RequestedLearningDrawerTab.QUESTIONS)}
              renderNavItem={() => (
                <div>By Question</div>
              )}
            />
            <TabNavItem
              isActive={activeTab === RequestedLearningDrawerTab.PERSON}
              onClick={() => setActiveTab(RequestedLearningDrawerTab.PERSON)}
              renderNavItem={() => (
                <div>By Person</div>
              )}
            />
          </div>
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {isLoading && (
          <MultipleSkeletonLoaders
            numberOfSkeletons={3}
            renderSkeletonItem={() => (
              <CardSkeleton css={styles.cardSkeleton} />
            )}
          />
        )}
        {(!isLoading && learning) && (
          <div>
            {activeTab === RequestedLearningDrawerTab.DETAILS && (
              <DetailsTab
                learning={learning}
                showViewParticipantsButton={showViewParticipantsButton}
              />
            )}
            {activeTab === RequestedLearningDrawerTab.QUESTIONS && (
              <QuestionsTab
                learning={learning}
                showViewParticipantsButton={showViewParticipantsButton}
              />
            )}
            {activeTab === RequestedLearningDrawerTab.PERSON && (
              <PersonTab
                learning={learning}
                showViewParticipantsButton={showViewParticipantsButton}
              />
            )}
          </div>
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: requestedLearningDrawerTemplate.name,
  component: RequestedLearningDrawer,
});

export default RequestedLearningDrawer;
