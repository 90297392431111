import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { calendarIntQueryKeys } from './queryKeys';

interface SelectExternalCalendarParams {
  email: string,
  calendarId: string,
}

const selectExternalCalendar = async ({ email, calendarId }: SelectExternalCalendarParams): Promise<HttpCallReturn<string>> => {
  const URL = {
    host: hosts.meeting,
    uri: `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/chooseWhichCalendarToLink`,
  };

  return postApi(URL, { email, calendarId, orgUserId: getOrganizationUserId() }, {});
};

export const useSelectExternalCalendar = (): UseMutateFunction<HttpCallReturn<string>, unknown, SelectExternalCalendarParams, void> => {
  const toastId = useRef<ReactText | null>(null);

  const mutation = useMutation({
    mutationFn: selectExternalCalendar,
    onMutate: () => {
      toastId.current = toast.info('Saving calendar selection...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'Error saving your calendar selection.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully saved your calendar selection',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: calendarIntQueryKeys.all });
    },
  });

  return mutation.mutate;
};
