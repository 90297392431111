import { css } from '@emotion/react';

import { useState } from 'react';
import { useGetPersonById as usePeoplePickerById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { palette } from '~Common/styles/colors';
import { withH4Font } from '~Deprecated/ui/styles/typography';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import Tooltip from '~Common/components/Tooltip';
import { withLineClamp } from '~Common/styles/mixins';
import { LearningPersonDisplayInformation } from '~Learning/const/interfaces';

const ATTENDEE_HEIGHT = 80;

const styles = {
  container: css({
    height: ATTENDEE_HEIGHT,
    background: palette.neutrals.gray50,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '1rem',
    border: `.0625rem solid ${palette.neutrals.gray50}`,
    justifyContent: 'space-between',

    '&:hover': {
      border: `.0625rem solid ${palette.brand.red}`,
    },
  }),
  borderRadius: css({
    borderRadius: '0.5rem',
  }),
  name: css({
    color: palette.neutrals.gray800,
  }, withLineClamp(1), withH4Font()),
  infoWrapper: css({
    flex: 1,
    marginLeft: '1rem',
  }),
  userInfo: css({
    width: '100',
    display: 'flex',
    alignItems: 'center',
  }),
  skeleton: css({
    borderRadius: '0.5rem',
  }),
};

const noResponseLearningAttendeeCardBorderRadius = styles.borderRadius;

interface ViewProps {
  id: string,
  name: string,
  renderRightItem?: (isHovering: boolean) => JSX.Element,
  tooltip?: string,
  profileImageUrl?: string,
  isHovering: boolean,
  setIsHovering: (isHovering: boolean) => void,
}

const View = ({
  profileImageUrl,
  name,
  tooltip,
  renderRightItem,
  isHovering,
  setIsHovering,
  ...props
}: ViewProps): JSX.Element => (
  <Tooltip interactive content={tooltip}>
    <div
      css={[styles.container, noResponseLearningAttendeeCardBorderRadius]}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...props}
    >
      <div css={styles.userInfo}>
        <SquareAvatar
          imgUrl={profileImageUrl}
          name={name}
          width={48}
          height={48}
          includeInitials
        />
        <div css={styles.infoWrapper}>
          <div
            className="attendeeName"
            css={styles.name}
          >
            {name}
          </div>
        </div>
      </div>
      {renderRightItem?.(isHovering)}
    </div>
  </Tooltip>
);

interface NoResponseLearningAttendeeCardProps {
  id: string,
  onClick: (id: string, isAttendeeSelected: boolean) => void,
  tooltip?: string,
  isAttendeeSelected: (id: string) => boolean,
  renderRightItem?: (isHovering: boolean) => JSX.Element,
}

const NoResponseLearningAttendeeCard = ({
  id,
  ...props
}: NoResponseLearningAttendeeCardProps): JSX.Element | null => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const attendee = usePeoplePickerById({ id }) as LearningPersonDisplayInformation;

  const [isHovering, setIsHovering] = useState(false);

  if (!attendee?.firstName) {
    return null;
  }

  const hookProps = {
    id,
    attendee,
    name: `${attendee?.firstName} ${attendee?.lastName}`,
    profileImageUrl: attendee?.profileImageUrl,
    isHovering,
    setIsHovering,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View, noResponseLearningAttendeeCardBorderRadius };
export default NoResponseLearningAttendeeCard;
