import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';
import { OrgClarityQuestion } from '~Common/const/Organization';

const styles = {
  question: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '.75rem',
  }),
  answer: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    'ol, ul': {
      paddingInlineStart: '1.125rem',
    },
  }),
};

interface OrgQuestionDisplayProps {
  orgClarityQuestion: OrgClarityQuestion,
}

const OrgQuestionDisplay = ({
  orgClarityQuestion,
  ...props
}: OrgQuestionDisplayProps): JSX.Element => (
  <div
    {...props}
  >
    <div
      css={styles.question}
      data-test-id="orgClarityQuestion"
    >
      {orgClarityQuestion.question}
    </div>
    {isHTMLText(orgClarityQuestion.answer) && (
      <HTMLRenderer css={styles.answer} htmlText={orgClarityQuestion.answer} data-test-id="orgClarityAnswer" />
    )}
    {!isHTMLText(orgClarityQuestion.answer) && (
      <div css={styles.answer} data-test-id="orgClarityAnswer">
        {orgClarityQuestion.answer}
      </div>
    )}
  </div>
);

export default OrgQuestionDisplay;
