import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { palette } from '~Common/styles/colors';
import { ForwardedRef, forwardRef } from 'react';

interface AgendaSectionMenuButtonProps extends LeadrButtonProps<'button'> {
  handleOpenMenuClick: () => void,
}

const AgendaSectionMenuButton = forwardRef(({
  handleOpenMenuClick,
  ...props
}: AgendaSectionMenuButtonProps, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => (
  <LeadrButton
    onClick={handleOpenMenuClick}
    variant="icon"
    size="small"
    textButtonColor={palette.neutrals.gray700}
    ref={ref}
    {...props}
  >
    <FontAwesomeIcon icon={faEllipsisVertical} />
  </LeadrButton>
));

export default AgendaSectionMenuButton;
