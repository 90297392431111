import { combineReducers } from 'redux';

import { SIGNOUT, INIT } from '../../actions';
import getInitialState from '../initialState';
import { clearStorage } from '../utils';
import { queryClient } from '~Common/const/queryClient';

let reducers = {};

let store;

function createRootReducer() {
  const appReducer = combineReducers({
    test: {},
    ...reducers,
  });

  return (state, action) => {
    if (action.type === SIGNOUT) {
      // Clear all query data rather than just invalidating queries
      // to prevent old data from showing for a newly logged in account
      // temporarily what fresh data is fetched.
      queryClient.clear();

      const {
        payload: {
          fullSignout = true,
        } = {},
      } = action;

      state = getInitialState();

      if (fullSignout) {
        if (window.pendo) {
          window.pendo.clearSession?.();
          window.pendo.stopSendingEvents?.();
        }
        clearStorage();
      }
    }
    // Compatability with legacy reducers.
    if (action.type === INIT) {
      state = getInitialState();
    }

    return appReducer(state, action);
  };
}

function initRootReducer(configuredStore, legacyReducers) {
  store = configuredStore;

  reducers = { ...reducers, ...legacyReducers };
  configuredStore.replaceReducer(createRootReducer());
  configuredStore.dispatch({ type: INIT });
}

function createReducer(name, initialState, onMutate = null) {
  const typeToHandler = {};

  function reducer(state = initialState, action) {
    const handler = typeToHandler[action.type];
    const nextState = handler ? handler(state, action, initialState) : state;

    if (onMutate) {
      onMutate(nextState);
    }

    return nextState;
  }

  function registerAction(type, handler) {
    typeToHandler[type] = handler;
  }

  reducers[name] = reducer;

  if (store) {
    store.replaceReducer(createRootReducer());
  }

  return {
    registerAction,
    reducer,
  };
}

export { createReducer, createRootReducer, initRootReducer };
