import { connect } from 'react-redux';

import InsightPlot from '~Deprecated/ui/components/Insights/InsightPlot/InsightPlot';
import { getOrganizationById } from '~Deprecated/selectors/organizationInsights/getOrganizationById';

const mapStateToProps = (state, { id }) => {
  const data = getOrganizationById(state, { id });

  return {
    metricSummaryReport: data.summaryReport,
  };
};

const mapDispatchToProps = ({

});

export default connect(mapStateToProps, mapDispatchToProps)(InsightPlot);
