import { useCallback } from 'react';
import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { DROPDOWN_STYLES } from '../../const/pageStyles';
import { ReflectionsFavoriteType } from '../../types';

const TaskForTranslation = {
  [ReflectionsFavoriteType.All]: ReflectionsFavoriteType.All,
  [ReflectionsFavoriteType.Favorited]: ReflectionsFavoriteType.Favorited,
  [ReflectionsFavoriteType.NotFavorited]: ReflectionsFavoriteType.NotFavorited,
};

const styles = {
  ...DROPDOWN_STYLES,
};

const View = ({
  onChange,
  items,
  value,
  renderValue,
  ...props
}: DropdownProps<ReflectionsFavoriteType[]>): JSX.Element => (
  <Dropdown
    displayEmpty
    css={styles.dropdown}
    value={value}
    renderItem={(item: DropdownItem<ReflectionsFavoriteType[]>) => (
      <div css={styles.dropdownItemBoy}>
        { item.text }
      </div>
    )}
    onChange={onChange}
    items={items}
    renderValue={renderValue}
    {...props}
  />
);

const ReflectionsFavoriteFilter = ({
  value,
  ...props
}: Omit<DropdownProps<ReflectionsFavoriteType[]>, 'items' | 'renderValue'>): JSX.Element => {
  const items = [
    {
      value: ReflectionsFavoriteType.All,
      text: ReflectionsFavoriteType.All,
      'data-test-id': 'reflectionsFavoriteTypeItem',
    },
    {
      value: ReflectionsFavoriteType.Favorited,
      text: ReflectionsFavoriteType.Favorited,
      'data-test-id': 'reflectionsFavoriteTypeItem',
    },
    {
      value: ReflectionsFavoriteType.NotFavorited,
      text: ReflectionsFavoriteType.NotFavorited,
      'data-test-id': 'reflectionsFavoriteTypeItem',
    },
  ];

  const renderValue = useCallback(() => {
    let selectedString;
    if (value?.length) {
      selectedString = value.map((filterValue) => TaskForTranslation[filterValue]).join(', ');
    } else {
      selectedString = ReflectionsFavoriteType.All;
    }

    return `${selectedString}`;
  }, [value]);

  const hookProps = {
    value,
    items,
    renderValue,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ReflectionsFavoriteFilter;
