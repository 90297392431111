import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { MeetingDetailsInterface, MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { getHost, hosts } from '~Deprecated/services/config';

export const getMeetingByIdService = async (
  organizationId: string | null | undefined,
  id: string,
  type: MeetingTypeEnum,
): Promise<HttpCallReturn<MeetingDetailsInterface>> => {
  const urlPath = type === MeetingTypeEnum.COACHING ? 'coachings' : 'meetings';
  const URL = {
    host: getHost(hosts.huddle, '2'),
    uri: `/organizations/${organizationId ?? ''}/huddles/${urlPath}/${id}`,
  };

  return getApi<MeetingDetailsInterface>(URL, {});
};

interface UseMeetingDetailsInterface extends Omit<UseQueryOptions<HttpCallReturn<MeetingDetailsInterface>, Error>, 'queryKey' | 'queryFn'> {
  id: string,
  type: MeetingTypeEnum,
}

interface UseMeetingDetailsReturnInterface {
  item?: MeetingDetailsInterface,
  isLoading: boolean,
}

const useMeetingDetails = ({
  id,
  type,
  ...options
}: UseMeetingDetailsInterface): UseMeetingDetailsReturnInterface => {
  const organizationId = getOrganizationId();
  const frontendPollingPollingFrequency: number = useFeatureFlag('frontendPollingPollingFrequency');

  const detail = useQuery({
    queryKey: [organizationId, 'huddles', id, type],
    queryFn: () => getMeetingByIdService(organizationId, id, type),
    staleTime: frontendPollingPollingFrequency || 1000 * 60,
    enabled: id !== undefined,
    ...options,
  });

  return {
    item: detail.data?.response,
    isLoading: detail.isLoading,
  };
};

export default useMeetingDetails;
