import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { reviewTemplatesListTemplate } from './ReviewTemplateDrawer';

interface UseReviewTemplateDrawerReturn {
  openDrawer: (reviewCycleUid?: string) => void,
}

export function useReviewTemplateDrawer(): UseReviewTemplateDrawerReturn {
  const dispatch = useDispatch();

  const openDrawer = (reviewCycleUid?: string): void => {
    // Open the review templates drawer
    dispatch(pushDrawerAction({
      drawer: {
        ...reviewTemplatesListTemplate,
        args: {
          reviewCycleUid,
        },
      },
    }));
  };

  return {
    openDrawer,
  };
}
