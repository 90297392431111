import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { ReactText, useRef } from 'react';
import { toast } from '~Common/components/Toasts';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { queryClient } from '~Common/const/queryClient';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import {
  BulkAddMeetingTemplatePayload,
  BulkAddMeetingTemplateResponse,
} from '~Meetings/components/topic-suggestions/const/types';

const addTemplateItemsToMeeting = ({
  huddleId,
  children,
}: BulkAddMeetingTemplatePayload): Promise<HttpCallReturn<BulkAddMeetingTemplateResponse>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates/bulk-add`;

  return postApi<BulkAddMeetingTemplateResponse>(URL, { huddleId, children });
};

export const useAddTemplateItemsToMeeting = (): UseMutationResult<HttpCallReturn<BulkAddMeetingTemplateResponse>, unknown, BulkAddMeetingTemplatePayload> => {
  const toastId = useRef<ReactText | number | null>(null);

  return useMutation({
    mutationFn: addTemplateItemsToMeeting,
    onMutate: () => {
      toastId.current = toast.info('Adding your topic(s) to the meeting...', { autoClose: false });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully added your topic(s) to the meeting!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error adding your topic(s) to the meeting. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSettled: (_, __, { huddleId }) => {
      void queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId() ?? '') });
    },
  });
};
