export const BREAKPOINTS = {
  MIN: {
    TABLET: 768,
    DESKTOP: 1025,
  },
  MAX: {
    TINY: 480,
    MOBILE: 767,
    TABLET: 1024,
  },
};

export const mobileMediaQuery = `only screen and (max-width: ${BREAKPOINTS.MAX.MOBILE}px)`;
export const mobileTinyMediaQuery = `only screen and (max-width: ${BREAKPOINTS.MAX.TINY}px)`;
export const tabletMediaQuery = `only screen and (min-width: ${BREAKPOINTS.MIN.TABLET}px) and (max-width: ${BREAKPOINTS.MAX.TABLET}px)`;
export const desktopMediaQuery = `only screen and (min-width: ${BREAKPOINTS.MIN.DESKTOP}px)`;
export const mousePointerDeviceQuery = '(pointer: fine)';
