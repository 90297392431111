import { css } from '@emotion/react';
import { pick } from 'lodash';
import {
  useCallback, MouseEvent, useRef, ReactText,
} from 'react';
import { REVIEW_SETUP_LAYOUT } from '~Reviews/V2/Const/pageStyles';
import {
  faFloppyDisk, faFolderOpen, faTrash, faPenToSquare,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';

import MuiMenuItem from '@mui/material/MenuItem';
import Menu, { MenuProps } from '@mui/material/Menu';
import Tooltip from '~Common/components/Tooltip';

import { useActionMenu, MenuItem } from '~Reviews/V2/Shared/ActionMenu';
import {
  CycleShape, TopicShape, ViewCyclePerspective,
} from '~Reviews/V2/Const/types';
import { toast } from '~Common/components/Toasts';
import { useDeleteCycle } from '~Reviews/V2/Hooks/useDeleteCycle';
import { navigateAction } from '~Deprecated/actions/navigate';
import { ADMIN_REVIEWS_V2 } from '~Common/const/routes';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { editReviewTemplatesTemplate } from '../templates/EditTemplateDrawer';
import { useReviewTemplateDrawer } from '../templates/useReviewTemplateDrawer';

const styles = {
  menu: css({
    display: 'flex',
    pointerEvents: 'none',
    width: '0',
    height: '0',
    right: '0',
    left: 'auto',
    zIndex: 1150, // Drawers are 1200.

    '.MuiMenu-list': {
      width: '16.8125rem',
      padding: '.625rem 0',
    },
    '.MuiPaper-root': {
      position: 'fixed',
      background: 'none',
      boxShadow: 'none',

      padding: ' .875rem 0 .875rem .875rem',
      transform: 'translate(-50%, -50%)',
      maxWidth: '250px',
      top: '50% !important',
      right: '-13.3rem !important',
      transition: 'all 1s ease',
      left: 'auto !important',

      '&:hover': {
        right: '-5rem',
      },

    },
  }),
  menuItem: css({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '.375rem 2rem .375rem .375rem',
    pointerEvents: 'all',
  }),
  menuIcon: (isDeactivated: boolean | undefined) => css({
    color: isDeactivated ? palette.neutrals.gray400 : palette.brand.indigo,
    width: '1rem',
    marginRight: '.5rem',
  }),
  menuText: css({
    fontSize: '.875rem',
  }),
  deactivatedStyle: css({
    color: palette.neutrals.gray400,
    cursor: 'not-allowed',
  }),
  ...REVIEW_SETUP_LAYOUT,
};

interface ViewProps extends Omit<MenuProps, 'open'> {
    menuItems: MenuItem[][],
    actionMenuProps: {
        onClose: () => void,
        anchorEle: HTMLElement | null,
        doOpen: (event: MouseEvent<HTMLElement>) => void,
    },
    viewCyclePerspective: ViewCyclePerspective,
    cycleId: string,
}

const View = ({
  menuItems,
  actionMenuProps,
  // viewCyclePerspective,
  cycleId,
}: ViewProps): JSX.Element => (
  <>
    {cycleId && (
    <div css={styles.floatingContainer}>
      <Menu
        css={styles.menu}
        keepMounted
        open
        disableEnforceFocus
        {...actionMenuProps}
      >
        <div css={styles.floatingContainer}>
          {menuItems.map((itemGroup, index) => (
            <div
            // disabling this because the top level is a grouping that is going to be static most of the time
            // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {itemGroup.map((item) => (
                <Tooltip
                  interactive={false}
                  content={item.tooltip}
                >
                  <MuiMenuItem
                    css={[styles.menuItem, (item.isDeactivated && styles.deactivatedStyle), item.styles?.menuItem]}
                    onClick={item.onClick}
                    key={item.text}
                  >
                    <div css={styles.menuIcon(item.isDeactivated)}>
                      <FontAwesomeIcon
                        icon={item.icon}
                        color={item.styles?.iconColor}
                      />
                    </div>
                    <div css={[styles.menuText, item.styles?.menuText]}>
                      {item.text}
                    </div>
                  </MuiMenuItem>
                </Tooltip>
              ))}
            </div>
          ))}
        </div>
      </Menu>
    </div>
    )}
  </>
);

interface FloatingActionMenuProps {
  viewCyclePerspective: ViewCyclePerspective,
  cycleId: string,
  cycleIsNotDraft: boolean,
  setViewCyclePerspective: (value: ViewCyclePerspective) => void,
  syncParticipants?: () => void | undefined,
}

const FloatingActionMenu = ({
  viewCyclePerspective,
  cycleId,
  cycleIsNotDraft,
  setViewCyclePerspective,
  syncParticipants,
}: FloatingActionMenuProps): JSX.Element => {
  const { ...actionMenuProps } = useActionMenu();
  const toastId = useRef<ReactText | number | null>(null);

  const fauxSave = ():void => {
    toastId.current = toast.info('Saving Review Cycle...', { autoClose: 3000 });
    toast.update(toastId.current, {
      render: 'Successfully saved the cycle!',
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      delay: 1000,
    });
  };

  const { openDrawer: openReviewTemplateDrawer } = useReviewTemplateDrawer();

  const deleteMutation = useDeleteCycle();

  const deleteCycle = ():void => {
    deleteMutation({ id: cycleId }, { onSuccess });
  };
  const dispatch = useDispatch();
  const onSuccess = (): void => {
    setViewCyclePerspective(ViewCyclePerspective.Setup);
    dispatch(navigateAction({
      pathname: ADMIN_REVIEWS_V2,
    }));
  };

  const triggerSaveAsTemplate = (): void => {
    const reviewCycle = queryClient.getQueryData<HttpCallReturn<CycleShape>>([getOrganizationId() ?? '', 'reviewCycles', cycleId, '2.5'])?.response;
    const reviewTopics = queryClient.getQueryData<HttpCallReturn<TopicShape[]>>([getOrganizationId() ?? '', 'reviewTopics', cycleId, '2.5'])?.response;

    const pickedCycle = pick(reviewCycle, ['name', 'description']);
    const pickedTopics = reviewTopics?.map((topic) => pick(topic, ['text', 'description', 'typeEnum', 'typeConfig', 'topicTargetEnum']));

    dispatch(pushDrawerAction({
      drawer: {
        ...editReviewTemplatesTemplate,
        args: {
          onBackArrow: undefined,
          initialTemplate: {
            ...pickedCycle,
            topics: pickedTopics,
          },
        },
      },
    }));
  };

  const onClickCallback = useCallback(() => {
    actionMenuProps.onClose();
  }, [actionMenuProps]);

  const menuItems = [[
    {
      text: 'Delete Review',
      icon: faTrash,
      onClick: () => {
        deleteCycle();
      },
    },
  ]];
  if (!cycleIsNotDraft) {
    menuItems[0].unshift({
      text: 'Save Draft',
      icon: faFloppyDisk,
      onClick: () => {
        if (viewCyclePerspective === ViewCyclePerspective.Add_Participants && syncParticipants) {
          syncParticipants();
        } else {
          fauxSave();
        }
      },
    });
  }
  if ((viewCyclePerspective === ViewCyclePerspective.Create_Questions || viewCyclePerspective === ViewCyclePerspective.Preview_Publish) && !cycleIsNotDraft) {
    menuItems[0].push({
      text: 'Use Template',
      icon: faFolderOpen,
      onClick: () => {
        openReviewTemplateDrawer(cycleId);
      },
    });
  }

  if (viewCyclePerspective === ViewCyclePerspective.Preview_Publish && !cycleIsNotDraft) {
    menuItems[0].push({
      text: 'Edit Questions',
      icon: faPenToSquare,
      onClick: () => {
        setViewCyclePerspective(ViewCyclePerspective.Create_Questions);
      },
    }, {
      text: 'Save as Template',
      icon: faFolderOpen,
      onClick: triggerSaveAsTemplate,
    });
  }

  const hookProps = {
    menuItems,
    onClickCallback,
    actionMenuProps,
    viewCyclePerspective,
    cycleId,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, FloatingActionMenu };
export default FloatingActionMenu;
