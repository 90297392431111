import { css } from '@emotion/react';

const styles = {
  workbench: css({
    margin: '30px',
    width: '335px',
  }),
};

interface ViewProps {
  taco: string,
}

const View = ({
  taco,
}: ViewProps): JSX.Element => (
  <div css={styles.workbench}>
    {taco}
  </div>
);

const Workbench = (): JSX.Element => {
  const taco = 'Taco';

  const hookProps = {
    taco,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default Workbench;
