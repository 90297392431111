import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { HomeModule, HomeModuleSettings } from '~Home/const';
import { GetNotesResponse } from '~Common/hooks/useGetNotes';
import { homeQueryKeys } from './queryKeys';

export interface HomeUpdateSetting {
  [HomeModule.Celebrate]: HomeModuleSettings
  [HomeModule.EngagementSurvey]: HomeModuleSettings
  [HomeModule.Goals]: HomeModuleSettings
  [HomeModule.Insights]: HomeModuleSettings
  [HomeModule.Meetings]: HomeModuleSettings
  [HomeModule.People]: HomeModuleSettings
  [HomeModule.Recognition]: HomeModuleSettings
  [HomeModule.TeamClarity]: HomeModuleSettings
  [HomeModule.WhatsDue]: HomeModuleSettings
}

export interface UpdateHomeSettingsParams {
  orgId: string
  userId: string,
  settings: HomeUpdateSetting,
}

const updateHomeSettings = async ({
  orgId,
  userId,
  settings,
}: UpdateHomeSettingsParams): Promise<HttpCallReturn<void>> => {
  const URL = {
    host: hosts.action,
    uri: `/organizations/${orgId}/users/${userId}/settings/home`,
  };

  return patchApi(URL, settings);
};

export const useUpdateHomeSettings = (): UseMutationResult<HttpCallReturn<void>, unknown, UpdateHomeSettingsParams> => (
  useMutation({
    mutationFn: updateHomeSettings,
    onMutate: async (variables) => {
      const { orgId, userId, settings } = variables;
      const [queryKey, queryData] = queryClient.getQueriesData({ queryKey: homeQueryKeys.homeSettings(orgId, userId) })[0];
      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<HttpCallReturn<GetNotesResponse>>(queryKey, (originalData) => {
        if (originalData) {
          const updatedData = cloneDeep(originalData);

          updatedData.response = {
            ...updatedData.response,
            ...settings,
          };

          return updatedData;
        }

        return originalData;
      });

      return { queryKey, queryData };
    },
    onError: () => {
      toast.error('Home settings could not be saved');
    },
    onSettled: async (_, __, variables) => {
      const { orgId, userId } = variables;

      await queryClient.invalidateQueries({ queryKey: [orgId, userId, 'settings', 'home'] });
    },
  })
);
