import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { deleteApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

interface deleteSurveyProps {
  id: string,
}

const deleteSurvey = ({ id }: deleteSurveyProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/surveys/${id}`;

  return deleteApi(serverUrl, {});
};

export const useDeleteSurvey = (): UseMutateFunction<HttpCallReturn<void>, unknown, deleteSurveyProps, void> => {
  const mySurveysKey = [getOrganizationId(), 'surveys', 'mySurveys', 'list'];
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: deleteSurvey,
    onMutate: () => {
      toastId.current = toast.info('Deleting the survey...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error deleting the survey. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully deleted the survey.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: mySurveysKey });
    },
  });

  return mutation.mutate;
};
