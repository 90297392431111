import { css } from '@emotion/react';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import styled from '@mui/system/styled';
import { palette } from '~Common/styles/colors';
import { drawerInputText } from '~Deprecated/ui/styles/colors';

const iconWrapper = css`  
  &.MuiListItemIcon-root {
    min-width: 1.625rem;
  }
`;

const filterDiv = css`
width: 56px;
height: 45px;
margin-right: 2%;
border-radius: 5px;
border: 1px solid ${palette.neutrals.gray300};
display: flex;
align-items: center;
justify-content: center;
background: ${palette.neutrals.white};

& svg{
  stroke: ${drawerInputText};
  fill: ${palette.neutrals.white};
}
`;

const filterButton = css`
  border: none;
  background: ${palette.neutrals.white};
`;

const listItemTextStyle = css`
  color: ${palette.neutrals.black};
  & span {
    font-size: 12px;
    font-weight: 500;
  }
`;

/**
 *
 * @param menuAnchorText #Text to be used for menu button
 * @param menuItems #Array of menu items. Each object must have a text property, icon is optional.
 * @param onItemSelected #function to call when an item is selected from the menu.
 * @param menuButtonColor #Button background color. If not provided, will default to default color.
 * @returns {{Component: JSX.Element, selectedOption: unknown}}
 */
const usePeopleFilterMenu = ({
  menuAnchorText,
  menuItems,
  onItemSelected,
  menuItemStyles = {},
  listItemIconStyles = {},
  listItemTextStyles = {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const MenuItem = styled(MuiMenuItem)(() => ({
    root: menuItemStyles,
  }));

  const ListItemIcon = styled(MuiListItemIcon)(() => ({
    root: listItemIconStyles,
  }));

  const ListItemText = styled(MuiListItemText)(() => ({
    root: listItemTextStyles,
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (menu) => {
    onItemSelected(menu);
    handleClose();
  };

  return {
    Component: (
      <div css={filterDiv}>
        <button
          css={filterButton}
          onClick={handleClick}
        >
          { menuAnchorText }
        </button>
        <Menu
          anchorEl={anchorEl}
          id="material-menu"
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          { menuItems.map((menu) => (
            <MenuItem key={menu.value} onClick={() => handleSelection(menu)} disableRipple>
              { menu.icon && (
                <ListItemIcon css={iconWrapper}>
                  { menu.icon }
                </ListItemIcon>
              ) }
              <ListItemText
                primary={menu.text}
                css={listItemTextStyle}
              />
            </MenuItem>
          )) }
        </Menu>
      </div>
    ),
  };
};

export default usePeopleFilterMenu;
