import PropTypes from 'prop-types';
import { REQUEST_TYPE } from '~Deprecated/common/const/request-type';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { SyntheticEvent } from 'react';
import { SerializedStyles } from '@emotion/react';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useSurveyRequestTypeFilter } from '../../Hooks/useSurveyRequestType';

interface ViewProps {
  surveyRequestTypeFilter: string,
  onChange: (event: SyntheticEvent, route: string) => void,
  tabItems: LeadrToggleTextTabProps[],
  toggleTabStyles: SerializedStyles,
}

const View = ({
  tabItems,
  surveyRequestTypeFilter,
  toggleTabStyles,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs value={surveyRequestTypeFilter} {...props}>
    {tabItems.map((tabItem) => (
      <LeadrToggleTabs.TextTab
        css={toggleTabStyles}
        data-test-id={tabItem['data-test-id']}
        key={tabItem.value}
        text={tabItem.text}
        value={tabItem.value}
      />
    ))}
  </LeadrToggleTabs>
);

View.propTypes = {
  tabItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  surveyRequestTypeFilter: PropTypes.string.isRequired,
  hasLimitedAccess: PropTypes.bool,
};

View.defaultProps = {
  hasLimitedAccess: false,
};

type SurveyTypeToggleProps = Omit<ViewProps, 'value' | 'tabItems' | 'onChange' | 'surveyRequestTypeFilter'>

const SurveyTypeToggle = ({ ...props }: SurveyTypeToggleProps): JSX.Element => {
  const { isLimitedAccess: isLimitedAccessUser } = useUserPermissions();

  const tabItems = [
    {
      text: 'Received',
      value: REQUEST_TYPE.RECEIVED,
      'data-test-id': 'surveysReceived',
    },
    ...(!isLimitedAccessUser ? [{
      text: 'Sent',
      value: REQUEST_TYPE.REQUESTED,
      'data-test-id': 'surveysAssigned',
    }] : []),
  ];

  // TODO: Update these to be different routes
  const [surveyRequestTypeFilter, setSurveyRequestTypeFilter] = useSurveyRequestTypeFilter() as [string, (value: string) => void];

  const onChange = (e: SyntheticEvent, newValue: string): void => {
    if (newValue) {
      setSurveyRequestTypeFilter(newValue);
    }
  };

  const hookProps = {
    tabItems,
    onChange,
    surveyRequestTypeFilter,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SurveyTypeToggle.propTypes = {};

SurveyTypeToggle.defaultProps = {};

export { View };
export default SurveyTypeToggle;
