import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import LeadrLogoIndigo from '~Assets/images/leadrLogoIndigo.svg';
import { UserOrganizationsInformation, useGetUserOrganizationList } from '~Root/hooks/useGetUserOrganizationList';
import {
  Dispatch, SetStateAction, useMemo, useState,
} from 'react';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import {
  getRedirectionUrl, setOrganizationCreatedDate, setOrganizationId, setOrganizationName,
} from '~Common/utils/localStorage';
import { useHistory } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { queryClient } from '~Common/const/queryClient';
import { getOrgUserPermissionsQuery } from '~Common/hooks/permissions/useGetOrgUserPermissions';

import { logoutFromAuth0 } from '~Root/functions/logoutFromAuth0';
import { getOrgDetailsQuery } from '~Common/hooks/useGetOrgDetails';
import { AuthLoader } from './AuthLoader';
import { authStyles } from './authStyles';

const styles = {
  ...authStyles,
  orgList: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  }),
  searchField: css({
    marginTop: '1.5rem',
    marginBottom: '1rem',
  }),
  button: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray200,
    borderColor: 'transparent',
    color: `${palette.neutrals.gray800} !important`,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.875rem',
    fontWeight: 600,
    padding: '0.5rem',
  }),
  orgLocation: css({
    fontSize: '0.625rem',
    fontWeight: 400,
  }),
  emptyState: css({
    flex: 1,
    fontWeight: 600,
    justifySelf: 'center',
    textAlign: 'center',
  }),
  goBack: css({
    alignItems: 'center',
    color: palette.neutrals.white,
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',

    svg: {
      size: '1.125rem',
    },

    '&:hover': {
      color: palette.neutrals.white,
    },
  }),
};

interface OnOrganizationSelectParams {
  index: number,
  organizationId: string,
  organizationName: string,
  organizationCreatedDate: string,
}

interface ViewProps {
  doLogout: () => void,
  isLoading: boolean,
  onOrganizationSelect: (selected: OnOrganizationSelectParams) => void,
  organizations: UserOrganizationsInformation[],
  filteredOrganizations: UserOrganizationsInformation[],
  setSearchText: Dispatch<SetStateAction<string>>,
}

export const View = ({
  doLogout,
  isLoading,
  onOrganizationSelect,
  organizations,
  filteredOrganizations,
  setSearchText,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.routeContainer}>
      <LeadrLogoIndigo css={styles.logo} title="Leadr logo" data-test-id="leadrLogoIndigo" />
      <div css={styles.innerContainer}>
        {isLoading && (
          <AuthLoader />
        )}
        {!isLoading && (
          <>
            {!organizations?.length && (
              <p css={styles.emptyState}>
                You do not participate in any active organizations.
                <br />
                Contact your organization admin to learn more.
              </p>
            )}
            {organizations?.length > 0 && (
              <>
                <LeadrSearchField
                  css={styles.searchField}
                  data-test-id="authOrganizationFilter"
                  onChange={(evt) => setSearchText(evt.target.value)}
                />
                <div css={styles.orgList}>
                  {filteredOrganizations.map(({
                    orgCreatedInMillis,
                    organizationId,
                    organizationName,
                    city,
                    state,
                  }, index) => (
                    <LeadrButton
                      css={styles.button}
                      variant="ghost"
                      data-test-id="authSelectOorganization"
                      key={`auth-select-organization-${organizationId}`}
                      onClick={() => onOrganizationSelect({
                        index,
                        organizationCreatedDate: orgCreatedInMillis,
                        organizationId,
                        organizationName,
                      })}
                    >
                      <p>{organizationName}</p>
                      <small css={styles.orgLocation}>
                        {`${city}, ${state}`}
                      </small>
                    </LeadrButton>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
    <LeadrButton
      css={styles.goBack}
      data-test-id="selectOrganizationLogout"
      onClick={doLogout}
      textButtonColor={palette.neutrals.white}
      variant="text"
    >
      <FontAwesomeIcon icon={faArrowLeft} />
      <p>Go Back</p>
    </LeadrButton>
  </div>
);

export const OrganizationSelect = (): JSX.Element => {
  const history = useHistory();

  const {
    isLoading,
    organizations,
  } = useGetUserOrganizationList();

  const [searchText, setSearchText] = useState('');

  const filteredOrganizations = useMemo(() => {
    const searchString = searchText.toLowerCase();
    return organizations.filter(
      (organization) => organization.organizationName.toLowerCase().includes(searchString),
    );
  }, [organizations, searchText]);

  const onOrganizationSelect = ({
    organizationCreatedDate,
    organizationId,
    organizationName,
  }: OnOrganizationSelectParams): void => {
    setOrganizationId(organizationId);
    setOrganizationName(organizationName);
    setOrganizationCreatedDate(organizationCreatedDate);

    void queryClient.prefetchQuery(getOrgUserPermissionsQuery(organizationId));
    void queryClient.prefetchQuery(getOrgDetailsQuery(organizationId));

    const redirectionURL = getRedirectionUrl();

    if (redirectionURL?.pathname && redirectionURL?.pathname !== '/') {
      history.push(redirectionURL.url);
    } else {
      history.push('/');
    }
  };

  if (organizations?.length === 1) {
    const {
      organizationId,
      orgCreatedInMillis,
      organizationName,
    } = organizations[0];

    onOrganizationSelect({
      index: 0,
      organizationCreatedDate: orgCreatedInMillis,
      organizationId,
      organizationName,
    });

    return <></>;
  }

  const doLogout = (): void => {
    logoutFromAuth0();
  };

  const hookProps = {
    doLogout,
    isLoading,
    onOrganizationSelect,
    organizations,
    filteredOrganizations,
    setSearchText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
