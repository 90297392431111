import { useQuery } from '@tanstack/react-query';
import { hosts } from '~Deprecated/services/config';
import { getApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { normalizeFeedbackTemplateFromServer } from '~Deprecated/utils/normalizers/feedbackTemplates';
import { flattenData } from '~Deprecated/utils/reduxUtils';

const templatesQuery = async ({ queryKey }) => {
  const [orgId, module, workflow, curated, count] = queryKey;
  const localUrl = {
    host: hosts.library,
    uri: `/organizations/${orgId}/templates/feedbacks?curated=${curated}&count=${count}`,
  };

  const { response } = await getApi(localUrl, {}, {});

  const { ids, data } = flattenData({
    responseData: (response && response.feedbacks),
    onItemFlatten: (item) => normalizeFeedbackTemplateFromServer({ data: item }),
  });

  return { ids, data };
};

const useTemplatesQuery = ({ queryKey }) => useQuery({ queryKey, queryFn: templatesQuery });

export const useCuratedFeedbackTemplates = ({ queryKey = [getOrganizationId(), 'feedback', 'templates', true, 10000] }) => {
  const result = useTemplatesQuery({ queryKey });

  return {
    status: result.status,
    templates: result.data?.ids.map((id) => result.data?.data[id]),
  };
};

export const useFeedbackTemplatesForOrg = ({ queryKey = [getOrganizationId(), 'feedback', 'templates', false, 10000] }) => {
  const result = useTemplatesQuery({ queryKey });

  return {
    status: result.status,
    templates: result.data?.ids.map((id) => result.data?.data[id]),
  };
};

export const useGetFeedbackTemplateById = ({ id, isCurated }) => {
  const queryKey = [getOrganizationId(), 'feedback', 'templates', isCurated, 1000];
  const result = useTemplatesQuery({ queryKey });
  return result.data?.data[id];
};
