import { BaseHit } from 'instantsearch.js';

export enum EntityType {
  MEETING_INSTANCE = 'MEETING_INSTANCE',
  AGENDA_TOPIC = 'AGENDA_TOPIC',
  TOPIC_COMMENT = 'TOPIC_COMMENT',
}

interface Participant {
  orgUserId: string,
}

export interface BaseInstanceBody extends BaseHit {
  entityType: EntityType,
  createdBy: Participant,
  createdDate: number,
  updatedDate: number,
  objectID: string,
  visibleToOrg: string,
  participants: Participant[],
}
