import { CreateGoalForm } from '~Goals/components/Create';
import { SerializedStyles, css } from '@emotion/react';
import {
  UseFormReturn,
} from 'react-hook-form';
import { Form } from '~Common/V3/components/uncontrolled';
import { FormValues } from '~Goals/schemata/CreateGoalSchemata';
import { GoalShape } from '~Goals/const/types';
import { ContextButtons } from '~Reviews/V2/Shared/ContextButtons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  goalOverride: css({
    maxWidth: '100%',
    margin: 0,
    padding: 0,

    // Can not think of another way to do this since it's also Emotion - open to ideas
    '> div:first-child': {
      display: 'none !important',
    },
  }),
};

interface ViewProps {
  formContext: UseFormReturn<FormValues>,
  goal: GoalShape,
  runGoalValidations: () => void,
  actionTextToUse: string,
  onCancel: () => void,
  overrideStyles?: SerializedStyles,
}

const View = ({
  formContext,
  goal,
  runGoalValidations,
  actionTextToUse,
  onCancel,
  overrideStyles,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="modalButtons"
      renderContents={() => (
        <>
          <LeadrButton
            data-test-id="addResourceModalSaveChanges"
            size="small"
            type="submit"
            onClick={runGoalValidations}
          >
            {`${actionTextToUse} Goal`}
          </LeadrButton>
          <LeadrButton
            data-test-id="addResourceModalCancelChanges"
            onClick={onCancel}
            size="small"
            variant="ghost"
          >
            Cancel
          </LeadrButton>
        </>
      )}
    />
    <Form
      formContext={formContext}
      onSubmit={() => null}
    >
      <CreateGoalForm
        formContext={formContext}
        goal={goal}
        overrideStyles={[styles.goalOverride, ...overrideStyles ? [overrideStyles] : []]}
        hideHeader
      />
    </Form>
  </>
);

type NewGoalProps = Pick<ViewProps, 'actionTextToUse' | 'onCancel' | 'formContext' | 'goal' | 'runGoalValidations' | 'overrideStyles'>;

export const NewGoal = ({
  ...props
}: NewGoalProps): JSX.Element => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
