import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { getMeetingById } from '~Deprecated/redux/actions/meetingActions';

const manageParticipants = ({
  id, attendeeIds, factoryId,
}) => {
  const serverUrl = `/organizations/${getOrganizationId()}/huddles/series/participants`;

  return patchApi(serverUrl, {
    huddleId: id,
    factoryId,
    attendeeIds,
  });
};

export const useManageParticipants = () => {
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: manageParticipants,
    onError: (_, variables) => {
      const {
        id,
      } = variables;

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', id] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
    },
    onSuccess: (_, variables) => {
      const {
        id,
      } = variables;

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', id] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });

      dispatch(getMeetingById({ contextType: 'TEAM_MEETING', data: id }));
    },
  });

  const doMutation = (args) => mutation.mutate(args);

  return doMutation;
};
