import { css } from '@emotion/react';
import { useUpcomingMeetingListItem } from '~Meetings/hooks/useUpcomingMeetings';
import { useArchivedMeetingListItem } from '~Meetings/hooks/useArchivedMeetings';
import { palette } from '~Common/styles/colors';
import { useFrequencyString } from '~Meetings/hooks/useFrequencyString';
import { Person, PersonDisplayInformation } from '~Common/const/interfaces';
import LeadrCard from '~Common/V3/components/LeadrCard';
import AvatarMaybeMultipleMaybeNot, { isNonLeadrUser } from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import { Divider } from '@mui/material';
import { lineClamp, withTruncate } from '~Common/styles/mixins';
import { ArchivedMeeting, MeetingFactoryType, UpcomingMeeting } from '~Meetings/const/meetingsInterfaces';
import { useGetPersonById, useGetPeople } from '~Common/hooks/people/useGetPeople';
import { useMemo } from 'react';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { NonLeadrUser } from '~Common/const/classes';

const styles = {
  leadrCard: css({
    cursor: 'pointer',
  }),
  avatarContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  divider: css({
    color: palette.neutrals.gray400,
    margin: '.3125rem .5rem',
  }),
  avatar: css({
    '&:not(:last-child)': {
      marginRight: '.25rem',
    },
  }),
  cardBody: css({
    marginTop: '0.75rem',
  }),
  title: css({
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    color: palette.neutrals.gray700,
    marginBottom: '.25rem',
  }, withTruncate()),
  description: css({
    fontSize: '.75rem',
    color: palette.neutrals.gray700,
  }, lineClamp(2)),
  skeletonCard: css({
    maxWidth: '100%',
    height: '7.5rem',
    borderRadius: '0.5rem',
  }),
};

const numberOfMeetingAttendeesToShow = 4;

interface ViewProps {
  title: string,
  avatarList: (PersonDisplayInformation | NonLeadrUser)[],
  owner: Person | undefined,
  meetingFrequency: string,
  doClick: () => void,
  numberOfMeetingAttendees: number,
  isLoading: boolean,
}

const View = ({
  title,
  avatarList,
  owner,
  meetingFrequency,
  doClick,
  numberOfMeetingAttendees,
  isLoading,
  ...props
}: ViewProps): JSX.Element => (
  <>
    {isLoading && (
      <SkeletonLoader
        css={styles.skeletonCard}
        variant="rectangular"
        renderComponent={() => <></>}
      />
    )}
    { /* At some point we gotta handle an undefined owner better, not sure what to do for now */ }
    {!isLoading && owner && (
      <LeadrCard
        css={styles.leadrCard}
        onClick={doClick}
        {...props}
      >
        <div css={styles.avatarContainer}>
          <NewBaseAvatar
            name={`${owner.firstName} ${owner.lastName}`}
            profileImageUrl={owner.profileImageUrl}
            avatarSize={35}
          />
          <Divider css={styles.divider} orientation="vertical" flexItem />
          <AvatarMaybeMultipleMaybeNot
            usersInfo={avatarList}
            numberOfUsers={numberOfMeetingAttendees}
            numberOfUsersToShow={numberOfMeetingAttendeesToShow}
            avatarHeight={35}
            avatarWidth={35}
            renderAvatar={({ user }) => (
              <>
                {isNonLeadrUser(user) && (
                  <NewBaseAvatar
                    css={styles.avatar}
                    email={user.email}
                    avatarSize={35}
                    isNonLeadrUser
                  />
                )}
                {!isNonLeadrUser(user) && (
                  <NewBaseAvatar
                    css={styles.avatar}
                    name={`${user.firstName} ${user.lastName}`}
                    profileImageUrl={user.profileImageUrl}
                    avatarSize={35}
                  />
                )}
              </>
            )}
            renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
              <OverflowAvatar
                css={styles.avatar}
                {...overflowAvatarProps}
              />
            )}
          />
        </div>
        <div css={styles.cardBody}>
          <div css={styles.title} data-test-id="meetingCardTitle">{title}</div>
          <div css={styles.description}>
            {meetingFrequency}
          </div>
        </div>
      </LeadrCard>
    )}
  </>
);

interface MeetingCardProps {
  id: string,
  onClick: (meetingDetails: UpcomingMeeting | ArchivedMeeting, isCoaching: boolean) => void,
}

const MeetingCard = ({
  id,
  onClick,
  ...props
}: MeetingCardProps): JSX.Element => {
  const upcomingItem = useUpcomingMeetingListItem(id) as UpcomingMeeting;
  const archivedItem = useArchivedMeetingListItem(id) as ArchivedMeeting;
  const item = upcomingItem.id ? upcomingItem : archivedItem;
  const meetingFrequency = useFrequencyString(item);
  const attendeeList = [...(item?.huddleAttendees ?? [])];
  const isCoaching = item.factoryType === MeetingFactoryType.COACHING;
  const { person: owner, isLoading: isOwnerDataLoading } = useGetPersonById({ orgUserId: item?.huddleOwner });
  const people = useGetPeople();
  const numberOfMeetingAttendees = attendeeList.length;

  // Only need to get the first 6 attendees to display their avatars
  const pickedAttendeeOrgUserIds = attendeeList.slice(0, numberOfMeetingAttendeesToShow);

  const avatarList = useMemo(
    // At least in alpha, there are some users that are undefined, so assigning them empty objects, so that the avatar list doesn't break
    () => pickedAttendeeOrgUserIds.map(
      (orgUserId) => people.data?.response.items.find((person) => person.orgUserId === orgUserId) || {} as Person,
    ),
    [pickedAttendeeOrgUserIds, people.data?.response.items],
  );

  const [isLoading] = useSkeletonLoaders(people.isLoading || isOwnerDataLoading);

  const doClick = (): void => {
    onClick(item, isCoaching);
  };

  const nonLeadrAttendees = item?.nonLeadrAttendees.map((user) => new NonLeadrUser(user.email)) ?? [];

  const hookProps = {
    title: item.title,
    avatarList: [...avatarList, ...nonLeadrAttendees],
    owner,
    meetingFrequency,
    doClick,
    numberOfMeetingAttendees,
    isLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingCard;
