import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useDispatch } from 'react-redux';

import DrawerHeader from '~Common/components/Drawers/Header';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import CloseDrawerButton from '~Common/components/Buttons/CloseDrawer';
import CancelButton from '~Common/components/Buttons/Cancel';
import NextButton from '~Common/components/Buttons/Next';
import { noop } from '~Deprecated/utils/index';
import SendButton from '~Common/components/Buttons/Send';
import { forMobile } from '~Common/styles/mixins';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import TabDrawer from './Tab';

const styles = {
  buttonContainer: css` 
    display: flex;
    button {
      ${forMobile(`
        margin: 0 5px;
      `)}
    }
  `,
  saveButton: css({
    marginRight: '10px',
  }),
};

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx
 * with packages/leadr-frontend/src/common/V3/components/Drawers/TabDrawerHeader.tsx instead
 */
const FormDrawer = ({
  drawerTitle, isReadOnly, showNextButton, drawerName, onNextClick, renderExtraHeaderButtons,
  showSendButton, drawerIntro, disableSaveButton, renderPersonalDevelopmentContent, ...props
}) => {
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    dispatch(popDrawerAction({
      drawerName,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, drawerName]);

  const rightButtons = ({ setSubmitter }) => {
    if (isReadOnly) {
      return (
        <CloseDrawerButton
          onClick={onCancel}
        />
      );
    }

    if (showNextButton) {
      return (
        <div css={styles.buttonContainer}>
          <CancelButton
            onClick={onCancel}
          />
          <NextButton
            onClick={() => setSubmitter(null)}
          />
        </div>
      );
    }

    if (showSendButton) {
      return (
        <div css={styles.buttonContainer}>
          <CancelButton
            onClick={onCancel}
          />
          <SendButton
            onClick={() => setSubmitter(null)}
          />
        </div>
      );
    }

    return (
      <div css={styles.buttonContainer}>
        { renderExtraHeaderButtons({ setSubmitter }) }
        <LeadrButton
          onClick={() => setSubmitter(null)}
          data-test-id="formDrawerSave"
          disabled={disableSaveButton}
          css={styles.saveButton}
        >
          Save
        </LeadrButton>
        <LeadrButton
          onClick={onCancel}
          data-test-id="formDrawerCancel"
          variant="ghost"
        >
          Cancel
        </LeadrButton>
      </div>
    );
  };

  const hookProps = {
    // eslint-disable-next-line react/prop-types
    renderHeader: ({ setSubmitter, form }) => (
      <DrawerHeader
        title={drawerTitle}
        introText={drawerIntro}
        renderButtons={rightButtons}
        setSubmitter={setSubmitter}
        form={form}
      />
    ),
    isReadOnly,
    showNextButton,
    renderPersonalDevelopmentContent,
  };

  return (
    <TabDrawer
      {...hookProps}
      {...props}
    />
  );
};

FormDrawer.propTypes = {
  drawerTitle: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  drawerName: PropTypes.string.isRequired,
  showNextButton: PropTypes.bool,
  onNextClick: PropTypes.func,
  renderExtraHeaderButtons: PropTypes.func,
  showSendButton: PropTypes.bool,
  drawerIntro: PropTypes.string,
  disableSaveButton: PropTypes.bool,
  renderPersonalDevelopmentContent: PropTypes.func,
};

FormDrawer.defaultProps = {
  isReadOnly: false,
  showNextButton: false,
  onNextClick: noop,
  renderExtraHeaderButtons: noop,
  showSendButton: false,
  drawerIntro: '',
  disableSaveButton: false,
  renderPersonalDevelopmentContent: () => undefined,
};

export default FormDrawer;
