import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { navigateAction } from '~Deprecated/actions/navigate';
import { REVIEWS_CONTINUE_CREATING_CYCLE } from '~Common/const/routes';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import {
  CreateCycle,
} from '~Reviews/V2/Const/types';

interface createCycleProps {
  cycle: CreateCycle,
}

const createCycle = ({ cycle }: createCycleProps): Promise<HttpCallReturn<string>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles`,
  };

  return postApi<string>(serverUrl, { ...cycle }, {});
};

export const useCreateCycle = (): UseMutationResult<HttpCallReturn<string>, unknown, createCycleProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: createCycle,
    onMutate: () => {
      toastId.current = toast.info('Creating the review cycle...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error creating the review cycle. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async (data) => {
      toast.update(toastId.current, {
        render: 'Successfully created the review cycle.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
      dispatch(navigateAction({
        pathname: REVIEWS_CONTINUE_CREATING_CYCLE,
        params: {
          cycleId: data.response,
        },
      }));
    },
  });

  return mutation;
};
