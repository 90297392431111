/* eslint-disable react/button-has-type */

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const SelectButton = (props) => (
  <Button
    variant={BUTTON_VARIANT.TEXT}
    buttonColor={BUTTON_COLOR.ADDITIVE}
    tooltip="Select"
    text="select"
    {...props}
  />
);

SelectButton.propTypes = {};

SelectButton.defaultProps = {};

export default SelectButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
