import { Route, Switch } from 'react-router-dom';
import {
  COACHING_HOME,
  COACHING_LIST_PAST,
  COACHING_LIST_UPCOMING,
  COACHING_ROUTE,
  MEETING_LIST_PAST,
  MEETING_LIST_UPCOMING,
  MEETING_ROUTE,
} from '~Common/const/routes';

import LayoutBridge from '~Deprecated/ui/routes/layouts/LayoutBridge';
import ArchivedMeetingsView from '~Meetings/components/ArchivedMeetings';
import UpcomingHuddlesView from '~Meetings/components/UpcomingMeetings';
import MeetingsTopBar from '~Meetings/components/MeetingsTopBar';
import HuddleDetails from '~Meetings/components/details/HuddleDetails';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import SmartRedirect from '../components/SmartRedirect';
import NewGetContent from './layouts/NewGetContent';
import BaseLayout from './layouts/BaseLayout';

const View = () => (
  <Switch>
    <Route
      exact
      path={[
        COACHING_LIST_UPCOMING,
        MEETING_LIST_UPCOMING,
      ]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<MeetingsTopBar initialRoute={COACHING_LIST_UPCOMING} />}
            ChildComponent={<UpcomingHuddlesView />}
          />
        </BaseLayout>
      )}
    />
    <Route
      exact
      path={[
        COACHING_LIST_PAST,
        MEETING_LIST_PAST,
      ]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<MeetingsTopBar initialRoute={COACHING_LIST_PAST} />}
            ChildComponent={<ArchivedMeetingsView />}
          />
        </BaseLayout>
      )}
    />
    <Route
      exact
      path={[COACHING_ROUTE, MEETING_ROUTE]}
      render={(routeProps) => (
        <LayoutBridge
          key="HuddleDetailsLayout"
          routeProps={routeProps}
          Component={(
            <NewGetContent
              key="HuddleDetails"
              ChildComponent={HuddleDetails}
              page="AGENDA"
            />
          )}
        />
      )}
    />
    <SmartRedirect exact from="*" to={COACHING_HOME} />
  </Switch>
);

View.propTypes = {

};

View.defaultProps = {

};

const Agenda = () => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
    />
  );
};

export default Agenda;
