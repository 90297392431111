import PropTypes from 'prop-types';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import FormDrawer from '~Common/components/Drawers/Forms/Form';
import { useGetFeedbackTemplateById } from '~Deprecated/hooks/feedback/useFeedbackTemplates';
import AddButton, { BUTTON_VARIANT } from '~Common/components/Buttons/AddButton';
import { getUserId } from '~Common/utils/localStorage';
import { useFeedbackQuestions } from '~Deprecated/hooks/feedback/useFeedbackQuestions';
import { useUpdateFeedbackTemplate } from '~Deprecated/hooks/feedback/useUpdateFeedbackTemplate';
import { DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import DeleteButton from '~Common/components/Buttons/Delete';
import { CONFIRM_TYPE } from '~Common/const/confirmType';
import { useDeleteFeedbackTemplate } from '~Deprecated/hooks/feedback/useDeleteFeedbackTemplate';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import TemplateDetails from '../Tabs/TemplateDetails';

export const templateDetailsTemplate = {
  name: 'FEEDBACK_TEMPLATE_DETAILS',
  width: DRAWER_WIDTHS.PRIMARY,
};

const TemplateDetailsDrawer = ({
  id, isCurated, setDrawerState, pushDrawer, popAfter, questionKey, drawerState, ...props
}) => {
  const template = useGetFeedbackTemplateById({ id, isCurated });
  const updateTemplateMutation = useUpdateFeedbackTemplate({ drawerTemplate: templateDetailsTemplate });
  const deleteFeedbackTemplate = useDeleteFeedbackTemplate({ drawerTemplate: templateDetailsTemplate });

  const { isAdmin } = useUserPermissions();

  const { onEditQuestion, onAddQuestion } = useFeedbackQuestions({
    existingQuestions: template?.question,
    setDrawerState,
    drawerState,
    drawerName: templateDetailsTemplate.name,
    pushDrawer,
    popAfter,
    questionKey: 'templateQuestions',
    templateId: template?.id,
  });

  const tabQueryConfig = {
    module: 'feedback',
    workflow: 'template_details',
    defaultValue: 0,
  };

  const isReadOnly = isCurated || (template?.owner.id !== getUserId() && !isAdmin);

  const onSubmit = (e) => {
    const formData = new FormData(e.target);
    const newTemplate = Object.fromEntries(formData.entries());

    updateTemplateMutation({
      template: newTemplate,
      id: template.id,
    });
  };

  const onDeleteFeebackTemplate = ({ onToggleConfirm }) => {
    const renderConfirmButton = () => (
      <DeleteButton
        onClick={() => deleteFeedbackTemplate({ template })}
        text="Delete Template"
      />
    );

    onToggleConfirm({
      show: true,
      type: CONFIRM_TYPE.DESTRUCTIVE,
      message: 'Are you sure you want to delete this feedback template?',
      drawerName: templateDetailsTemplate.name,
      renderConfirmButton,
    });
  };

  const hookProps = {
    onSubmit,
    drawerName: templateDetailsTemplate.name,
    isReadOnly,
    drawerTitle: 'Template Details',
    tabs: [
      ['Details', <TemplateDetails
        template={template}
        isReadOnly={isReadOnly}
        onEditQuestion={onEditQuestion}
      />],
    ],
    // eslint-disable-next-line react/prop-types
    renderBottomButtons: ({ onToggleConfirm }) => (
      !isReadOnly && (
        <>
          <AddButton
            onClick={onAddQuestion}
            variant={BUTTON_VARIANT.STACKED}
            text="Add Question"
          />
          <DeleteButton
            onClick={() => onDeleteFeebackTemplate({ onToggleConfirm })}
            variant={BUTTON_VARIANT.STACKED}
            text="Delete Template"
          />
        </>
      )
    ),
    tabQueryConfig,
  };

  return (
    <FormDrawer
      {...hookProps}
      {...props}
    />
  );
};

TemplateDetailsDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  isCurated: PropTypes.bool.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  pushDrawer: PropTypes.func.isRequired,
  popAfter: PropTypes.func.isRequired,
  questionKey: PropTypes.string,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
};

TemplateDetailsDrawer.defaultProps = {
  questionKey: 'questions',
};

registerDrawer({ templateName: templateDetailsTemplate.name, component: TemplateDetailsDrawer });

export default TemplateDetailsDrawer;
