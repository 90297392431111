import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { patchApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { Drawer } from '~Common/const/drawers';
import { LearningTemplateList } from '~Learning/const/interfaces';
import { useLearningLibrarySearchStore } from '~Learning/stores/useLearningLibrarySearchStore';
import { learningTemplatesQueryKeys } from '../queryKeys';
import { LearningTemplateSearchHookReturn } from './useLearningTemplateSearch';

interface LearningTemplateDeletionRequest {
  templateId: string,
  categoryId: string
}

const deleteLearningTemplate = async (
  learningTemplateDeletionRequest: LearningTemplateDeletionRequest,
): Promise<HttpCallReturn<boolean>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/delete`;

  return patchApi<boolean>(serverUrl, learningTemplateDeletionRequest);
};

interface UseDeleteLearningTemplateProps {
  templateToPop: Drawer,
  page: number,
  count: number,
  categoryId: string,
}

export const useDeleteLearningTemplate = ({
  templateToPop,
  page,
  count,
  categoryId,
}: UseDeleteLearningTemplateProps): UseMutateFunction<HttpCallReturn<boolean>, unknown, LearningTemplateDeletionRequest> => {
  const dispatch = useDispatch();
  const {
    searchText,
  } = useLearningLibrarySearchStore((state) => ({
    searchText: state.searchText,
  }));

  const mutation = useMutation({
    mutationFn: deleteLearningTemplate,
    onMutate: async (learningTemplateDeletionRequest: LearningTemplateDeletionRequest) => {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ drawerName: templateToPop.name }));
      await queryClient.cancelQueries({ queryKey: learningTemplatesQueryKeys.lists() });

      const previousLearningTemplates = queryClient.getQueryData(learningTemplatesQueryKeys.list(
        {
          page,
          count,
          curated: false,
          categoryId,
        },
      ));

      const allPreviousLearningTemplatePages = queryClient.getQueriesData({ queryKey: [...learningTemplatesQueryKeys.lists(), false, categoryId] });

      // #region Update the learning templates list
      queryClient.setQueryData<HttpCallReturn<LearningTemplateList>>(
        learningTemplatesQueryKeys.list({
          page,
          count,
          curated: false,
          categoryId,
        }),
        (oldLearningTemplatesReturn) => {
          if (oldLearningTemplatesReturn) {
            const newData = cloneDeep(oldLearningTemplatesReturn);
            // Sometimes the data for the list will not exist, like if you searched for the template and then deleted it
            if (newData) {
              newData.response.learningTemplates = newData.response.learningTemplates.filter(
                (learningTemplate) => learningTemplate.uid !== learningTemplateDeletionRequest.templateId,
              );
            }
            return newData;
          }

          return oldLearningTemplatesReturn;
        },
      );
      // #endregion

      // #region Update the learning templates search list
      if (searchText) {
        queryClient.setQueryData<HttpCallReturn<LearningTemplateSearchHookReturn>>(
          learningTemplatesQueryKeys.search({
            page,
            count,
            curated: false,
            searchString: searchText,
          }),
          (oldLearningTemplatesSearchReturn) => {
            if (oldLearningTemplatesSearchReturn) {
              const newData = cloneDeep(oldLearningTemplatesSearchReturn);
              if (newData) {
                newData.response.learningTemplates = newData.response.learningTemplates.filter(
                  (learningTemplate) => learningTemplate.uid !== learningTemplateDeletionRequest.templateId,
                );
              }
              return newData;
            }

            return oldLearningTemplatesSearchReturn;
          },
        );
      }
      // #endregion

      queryClient.setQueriesData<HttpCallReturn<LearningTemplateList>>({ queryKey: learningTemplatesQueryKeys.lists() }, (oldQueryData) => {
        if (oldQueryData) {
          const newData = cloneDeep(oldQueryData);
          if (newData) {
            newData.response.total -= 1;
          }
          return newData;
        }

        return oldQueryData;
      });

      return { previousLearningTemplates, allPreviousLearningTemplatePages };
    },
    onError: (_, learningTemplateDeletionRequest, context) => {
      dispatch(pushDrawerAction({
        drawer: {
          ...templateToPop,
          args: {
            templateId: learningTemplateDeletionRequest.templateId,
            page,
            count,
            curated: false,
          },
        },
      }));
      queryClient.setQueryData(
        learningTemplatesQueryKeys.list({
          page,
          count,
          curated: false,
          categoryId,
        }),
        context?.previousLearningTemplates,
      );
      context?.allPreviousLearningTemplatePages.forEach(([queryKey, previousData]) => {
        queryClient.setQueryData(queryKey, previousData);
      });

      toast.error('There was an error deleting the learning template. Please try again.', {
        autoClose: 1500,
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: learningTemplatesQueryKeys.lists() });
    },
  });

  return mutation.mutate;
};
