import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  useEffect, useMemo, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { faArrowFromLeft } from '@fortawesome/pro-light-svg-icons';
import { useDebounce } from '@react-hook/debounce';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { DRAWER_STATE_SHAPE, FILTER_GROUP_MENU_ITEMS_SHAPE, SET_DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { palette } from '~Common/styles/colors';
import { withoutMobile } from '~Common/styles/mixins';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import useSelectedAttendees from '~Deprecated/hooks/attendees/useSelectedAttendees';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import AttendeeList, { userListContainerStyles } from '~Meetings/components/attendees/AttendeesList';
import FilterGroupsButton from '~Common/components/FilterGroupsButton';
import { FILTER_TYPE, useFilterMenuItems, useSelectParticipants } from '~Common/hooks/useSelectParticipants';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import AttendeeItem, { attendeeItemBorderRadius } from '~Meetings/components/attendees/AttendeeItem';
import FlatButton from '~Common/V3/components/FlatButton';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import SelectAllButtons from './SelectAllButtons';
import SelectedFilterChips from './SelectedFilterChips';

export const selectParticipantsDrawerTemplate = {
  name: 'SELECT_PARTICIPANT_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

const styles = {
  collapseButton: (alwaysShowCloseButton) => css({
    fontSize: '1rem',
    color: palette.neutrals.gray400,
  }, !alwaysShowCloseButton && withoutMobile('display: none;')),
  searchBox: css`
    margin-right: .625rem;
  `,
  searchContainer: css`
    position: sticky;
    display: flex;
  `,
  filterChips: css`
    margin-top: 1.25rem;
  `,
  selectAllButtons: css`
    margin-top: .875rem;
  `,
  skeletonLoader: css`
    max-width: 100%;
    height: 5rem;
  `,
  selectAllButton: (isAvailable) => css`
    color: ${palette.neutrals.gray500};
    font-size: .625rem;

    ${isAvailable && `
      color: ${palette.brand.blue};
    `}
  `,
  deselectAllButton: (isAvailable) => css`
    color: ${palette.neutrals.gray500};
    font-size: .625rem;
    
    ${isAvailable && `
      color: ${palette.brand.blue};
    `}
  `,
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

const View = ({
  isAttendeeSelected,
  isLoading,
  attendees,
  onAttendeeSelect,
  renderSearchBox,
  currentAttendees,
  handleSearchTextChange,
  title,
  filterMenuItems,
  onFilterChange,
  selectedFilters,
  setSelectedFilters,
  closeDrawerClick,
  onSelectAll,
  onDeselectAll,
  hasSelectedAttendeesInShownList,
  allSelectedAttendeesInShownList,
  limit,
  alwaysShowCloseButton,
  canFilter,
  ...props
}) => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title={title}
        renderCloseButton={() => (
          <IconButton
            onClick={closeDrawerClick}
            tooltip="Close"
            type="button"
            icon={faArrowFromLeft}
            css={styles.collapseButton(alwaysShowCloseButton)}
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <div css={styles.searchContainer}>
          <LeadrSearchField
            css={styles.searchBox}
            onChange={handleSearchTextChange}
            data-test-id="select-participants-search-box"
          />

          {canFilter && (
            <FilterGroupsButton
              menuItems={filterMenuItems}
              onChangeCallback={onFilterChange}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          )}
        </div>
        {selectedFilters.length > 0 && (
          <SelectedFilterChips
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            css={styles.filterChips}
            onFilterChange={onFilterChange}
          />
        )}
        <div>
          {(!limit || (limit && limit > 1)) && (
            <SelectAllButtons
              onSelectAll={onSelectAll}
              onDeselectAll={onDeselectAll}
              css={styles.selectAllButtons}
              renderSelectAllButton={() => (
                <FlatButton
                  disabled={isLoading}
                  onClick={onSelectAll}
                  css={styles.selectAllButton(!allSelectedAttendeesInShownList)}
                  renderButtonContents={() => (
                    <div>SELECT ALL</div>
                  )}
                />
              )}
              renderDeselectAllButton={() => (
                <FlatButton
                  disabled={isLoading}
                  onClick={onDeselectAll}
                  css={styles.deselectAllButton(hasSelectedAttendeesInShownList)}
                  renderButtonContents={() => (
                    <div>DESELECT ALL</div>
                  )}
                />
              )}
            />
          )}
          {isLoading && (
            <MultipleSkeletonLoaders
              css={userListContainerStyles}
              numberOfSkeletons={10}
              renderSkeletonItem={() => (
                <SkeletonLoader
                  variant="rect"
                  css={[styles.skeletonLoader, attendeeItemBorderRadius]}
                  renderComponent={() => (
                    <AttendeeItem />
                  )}
                />
              )}
            />
          )}
          {!isLoading && (
            <AttendeeList
              selectedAttendees={currentAttendees}
              isAttendeeSelected={isAttendeeSelected}
              isLoading={false}
              attendees={attendees}
              onClick={onAttendeeSelect}
              {...props}
            />
          )}
        </div>
      </div>
    )}
  />
);

View.propTypes = {
  isAttendeeSelected: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  handleSearchTextChange: PropTypes.func.isRequired,
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
  renderSearchBox: PropTypes.func.isRequired,
  attendees: PropTypes.arrayOf(PropTypes.string),
  onAttendeeSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  filterMenuItems: FILTER_GROUP_MENU_ITEMS_SHAPE.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  closeDrawerClick: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
  hasSelectedAttendeesInShownList: PropTypes.bool,
  allSelectedAttendeesInShownList: PropTypes.bool,
  limit: PropTypes.number,
  alwaysShowCloseButton: PropTypes.bool,
  canFilter: PropTypes.bool,
};

View.defaultProps = {
  currentAttendees: [],
  attendees: [],
  hasSelectedAttendeesInShownList: false,
  allSelectedAttendeesInShownList: false,
  limit: null,
  // Defaulting to false because not every drawer has implemented a way to reopen this drawer
  alwaysShowCloseButton: false,
  canFilter: true,
};

const SelectParticipantsDrawer = ({
  useOrgIds,
  allowSelf,
  limit,
  drawerState,
  setDrawerState,
  selectedKey,
  defaultFilter,
  disableFilter,
  customFilter,
  disableUsers,
  filterOptions,
  onSelect,
  selectAllCallback,
  currentAttendees,
  filterIds,
  excludeGroupIds,
  title,
  ...props
}) => {
  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      isSelectParticipantsDrawerOpen: true,
    }));

    return () => {
      setDrawerState((prev) => ({
        ...prev,
        isSelectParticipantsDrawerOpen: false,
      }));
    };
  }, [setDrawerState]);

  const { filterMenuItems, selectedFilters, setSelectedFilters } = useFilterMenuItems({ excludeGroupIds });

  const {
    onSearch,
    isLoading,
    participants,
    fetchFilteredPeople,
    allParticipants,
    setDebouncedLoadingOverride,
  } = useSelectParticipants({
    useOrgIds, allowSelf, selectedFilters, filterIds,
  });

  const handleSearchTextChange = (event) => {
    onSearch(event.target.value);
  };

  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);

  const dispatch = useDispatch();

  const nonSelectedParticipants = [...participants];

  const {
    isAttendeeSelected,
    onAttendeeSelect,
    selectAll,
    deselectAll,
    selectedAttendees: selectedParticipants,
  } = useSelectedAttendees({
    useOrgIds,
    attendeeSelectCallback: onSelect,
    allAttendees: allParticipants,
    drawerState,
    setDrawerState,
    limit,
    selectedKey,
    allowSelf,
    disabledUsers: disableUsers,
    selectAllCallback,
    filteredAttendees: participants,
    nonSelectedAttendees: nonSelectedParticipants,
  });

  const hasSelectedAttendeesInShownList = useMemo(
    () => (participants.some((participant) => (selectedParticipants || []).includes(participant))),
    [selectedParticipants, participants],
  );

  const allSelectedAttendeesInShownList = useMemo(
    () => (participants.every((participant) => (selectedParticipants || []).includes(participant))),
    [selectedParticipants, participants],
  );

  const [filterPeopleObject, setFilterObject] = useDebounce({}, 1000);

  const onFilterChange = (newFilters) => {
    const filters = newFilters.filter((filter) => filter.filterType === FILTER_TYPE.FILTERS).map((filter) => filter.apiValue);
    const departments = newFilters.filter((filter) => filter.filterType === FILTER_TYPE.DEPARTMENT).map((filter) => filter.text);
    const teams = newFilters.filter((filter) => filter.filterType === FILTER_TYPE.GROUPS).map((filter) => filter.apiValue);

    const filterObject = {
      filters,
      departments,
      teams,
    };

    if (filters.length > 0 || departments.length > 0 || teams.length > 0) {
      setFilterObject(filterObject);
      setDebouncedLoadingOverride(true);
    }
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchFilteredPeople(filterPeopleObject);
    }
  }, [filterPeopleObject, fetchFilteredPeople]);

  const closeDrawerClick = () => {
    dispatch(popDrawer({ drawerName: selectParticipantsDrawerTemplate.name }));
  };

  const hookProps = {
    isAttendeeSelected,
    isLoading: showSkeletonLoaders,
    attendees: participants,
    onAttendeeSelect,
    customFilter,
    disableFilter,
    disableUsers,
    handleSearchTextChange,
    title,
    filterMenuItems,
    onFilterChange,
    selectedFilters,
    setSelectedFilters,
    closeDrawerClick,
    onSelectAll: selectAll,
    onDeselectAll: deselectAll,
    setSearchFilter: () => { },
    searchFilter: '',
    hasSelectedAttendeesInShownList,
    allSelectedAttendeesInShownList,
    limit,
    canFilter: !filterIds.length,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SelectParticipantsDrawer.propTypes = {
  useOrgIds: PropTypes.bool,
  filterIds: PropTypes.arrayOf(PropTypes.string),
  allowSelf: PropTypes.bool,
  limit: PropTypes.number,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: SET_DRAWER_STATE_SHAPE.isRequired,
  popDrawer: PropTypes.func.isRequired,
  selectedKey: PropTypes.string,
  disableFilter: PropTypes.bool,
  onSelect: PropTypes.func,
  defaultFilter: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  customFilter: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  disableUsers: PropTypes.arrayOf(PropTypes.object),
  filterOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  selectAllCallback: PropTypes.func,
  removeSelectedAttendees: PropTypes.bool,
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
  excludeGroupIds: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

SelectParticipantsDrawer.defaultProps = {
  useOrgIds: true,
  filterIds: [],
  allowSelf: true,
  limit: null,
  selectedKey: 'selectedAttendees',
  disableFilter: false,
  defaultFilter: null,
  customFilter: [],
  disableUsers: [],
  filterOptions: [],
  onSelect: null,
  selectAllCallback: null,
  removeSelectedAttendees: false,
  currentAttendees: [],
  excludeGroupIds: [],
  title: 'Select Participants',
};

registerDrawer({
  templateName: selectParticipantsDrawerTemplate.name,
  component: SelectParticipantsDrawer,
});

export { View };
export default SelectParticipantsDrawer;
