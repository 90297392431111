import { css } from '@emotion/react';
import { getEmail } from '~Common/utils/localStorage';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useResetPassword } from '~Deprecated/hooks/profile/useUserProfile';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';

const styles = {
  settingsSectionHeader: css({
    marginBottom: '1.5rem',
  }),
  buttonsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: 'fit-content',
  }),
};

interface ViewProps {
  handleResetPassword: () => void,
}

const View = ({
  handleResetPassword,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <SettingsSectionHeader
      css={styles.settingsSectionHeader}
      renderContents={() => (
        <>Logging In</>
      )}
    />
    <div css={styles.buttonsContainer}>
      <LeadrButton
        onClick={handleResetPassword}
        data-test-id="settingsResetPassword"
        size="small"
      >
        Reset Password
      </LeadrButton>
    </div>
  </div>
);

const LoggingInSettings = ({ ...props }): JSX.Element => {
  const currentUserEmail = getEmail() ?? '';
  const { resetPassword } = useResetPassword();

  const handleResetPassword = (): void => {
    resetPassword(currentUserEmail);
  };

  const hookProps = {
    handleResetPassword,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LoggingInSettings;
