import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import DrawerHeader from '~Common/components/Drawers/Header';
import PersonalityDrawerHeader from '~People/BaseballCard/Drawers/PersonalityDrawerHeader';
import CloseDrawerButton from '~Common/components/Buttons/CloseDrawer';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import Loader from '~Common/components/Loader';
import { bioDrawerColors, palette } from '~Common/styles/colors';
import { separator } from '~Common/styles/mixins';
import { useBaseballCardPersonalityTypeDetails } from '~People/hooks/baseballCard/useBaseballCardPersonalityTypeDetails';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';
import { WorkingGeniusType } from '~People/BaseballCard/types';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

export const personalityTypeDetailDrawerTemplate = {
  name: 'PERSONALITY_TYPE_DETAIL_DRAWER',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};

const styles = {
  header: css` 
    align-items: flex-start;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    margin-block-start: unset;
  `,
  layout: css`
    & > div {
      padding: unset;
    }
  `,
  body: css`
    padding: 1.5rem;
    padding-block-start: 10px;
    white-space: pre-wrap;
    word-break: break-word;
    color: ${bioDrawerColors.bioText};
  `,
  title: css({
    fontSize: '15px',
    fontWeight: '600',
    color: palette.neutrals.gray800,
  }),
  comma: css({
    marginRight: '5px',
  }),
  skill: css({
    padding: '1.5rem 1.5rem .5rem 1.5rem',
    paddingBlockStart: '10px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    color: palette.neutrals.gray800,
  }),
};

const View = ({
  doneButton, personalityDetails, personality,
}) => (
  <DrawerLayout
    css={styles.layout}
    renderHeader={() => (
      <DrawerHeader
        css={styles.header}
        renderCustomHeader={() => (<PersonalityDrawerHeader personality={personality} assessment={personalityDetails?.system} />)}
        renderButtons={doneButton}
      />
    )}
    renderBody={() => (
      <>
        <div css={styles.padding}>
          <div css={separator('5px', '0', true)} />
        </div>
        {personalityDetails.system === WorkingGeniusType.workingGenius && (
          <>
            <div css={styles.skill}>
              <h2 css={styles.title}>Working Genius</h2>
              {personalityDetails?.skills.map((genius, index) => (
                <>
                  <span key={genius}>
                    <span>{genius}</span>
                    {index !== personalityDetails.skills.length - 1 && (
                    <span css={styles.comma}>,</span>
                    )}
                  </span>
                </>
              ))}
            </div>
            {personalityDetails?.workingCompetencies.length !== 0 && (
              <>
                <div css={styles.skill}>
                  <h2 css={styles.title}>Working Competencies</h2>
                  {personalityDetails?.workingCompetencies.map((competencies, index) => (
                    <>
                      <span key={competencies}>
                        <span>{competencies}</span>
                        {index !== personalityDetails.workingCompetencies.length - 1 && (
                        <span css={styles.comma}>,</span>
                        )}
                      </span>
                    </>
                  ))}
                </div>
              </>
            )}
            {personalityDetails?.workingFrustrations.length !== 0 && (
              <>
                <div css={styles.skill}>
                  <h2 css={styles.title}>Working Frustrations</h2>
                  {personalityDetails?.workingFrustrations.map((frustration, index) => (
                    <>
                      <span key={frustration}>
                        <span>{frustration}</span>
                        {index !== personalityDetails.workingFrustrations.length - 1 && (
                        <span css={styles.comma}>,</span>
                        )}
                      </span>
                    </>
                  ))}
                </div>
              </>
            )}
            <div css={styles.skill}>
              <h2 css={styles.title}>Unique Pairing:</h2>
              <span>{personalityDetails.label}</span>
            </div>
            {personalityDetails?.commentary && (
              <HTMLRenderer css={styles.body} htmlText={personalityDetails?.commentary} />
            )}
          </>
        )}
        {personalityDetails.system !== WorkingGeniusType.workingGenius && (
          <>
            {personalityDetails?.commentary && (
              <HTMLRenderer css={styles.body} htmlText={personalityDetails?.commentary} />
            )}
          </>
        )}
      </>
    )}
  />
);

View.propTypes = {
  doneButton: PropTypes.func.isRequired,
  personalityDetails: PropTypes.string,
  personality: PropTypes.shape({
    personalityId: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string,
    workingFrustrations: PropTypes.string,
    workingCompetencies: PropTypes.string,
  }).isRequired,
};

View.defaultProps = {
  personalityDetails: '',
};

function PersonalityTypeDetailDrawer({
  popDrawer, personality,
}) {
  const dispatch = useDispatch();

  const { personalityId } = personality;
  const { personalityDetails: details } = useBaseballCardPersonalityTypeDetails(personalityId);

  const personalityDetails = {
    ...details,
    workingFrustrations: personality.workingFrustrations,
    workingCompetencies: personality.workingCompetencies,
  };

  if (!personalityId || !details) {
    return <Loader />;
  }

  const onClose = () => {
    dispatch(popDrawer({
      drawerName: personalityTypeDetailDrawerTemplate.name,
    }));
  };

  const hookProps = {
    doneButton: () => (
      <CloseDrawerButton onClick={onClose} />
    ),
    personalityDetails,
    personality,
  };

  return (
    <View
      {...hookProps}
    />
  );
}

PersonalityTypeDetailDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  popDrawer: PropTypes.func.isRequired,
  personality: PropTypes.shape({
    personalityId: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string,
    workingFrustrations: PropTypes.string,
    workingCompetencies: PropTypes.string,
  }).isRequired,
};

PersonalityTypeDetailDrawer.defaultProps = {};

registerDrawer({
  templateName: personalityTypeDetailDrawerTemplate.name,
  component: PersonalityTypeDetailDrawer,
});

export { View };
export default PersonalityTypeDetailDrawer;
