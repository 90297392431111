import { css } from '@emotion/react';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefObject, useEffect, useState } from 'react';
import { hexToRGBA, palette } from '~Common/styles/colors';

const styles = {
  scrollBox: (isVisible: boolean) => css({
    backgroundColor: palette.brand.indigo,
    borderRadius: '5px',
    bottom: '15%',
    color: palette.neutrals.white,
    fontSize: '0.75rem',
    left: '50%',
    opacity: isVisible ? 0.75 : 0,
    outline: 'none',
    padding: '0.5rem',
    pointerEvents: 'auto',
    position: 'absolute',
    transform: 'translate3d(-50%, -35%, 0)',
    transition: 'opacity .3s',
  }),
  sticky: css({
    height: 'calc(100% - 1px)',
    float: 'left',
    left: '0',
    marginRight: '-100%',
    pointerEvents: 'none',
    position: 'sticky',
    top: '0',
    width: 'calc(100% - 1px)',
    zIndex: 50,
  }),
  fade: (isVisible: boolean) => css({
    backgroundImage: `linear-gradient(to right, rgba(255, 0, 0, 0), ${hexToRGBA(palette.neutrals.gray300)})`,
    height: '100%',
    left: '100%',
    opacity: isVisible ? 1.0 : 0,
    outline: 'none',
    pointerEvents: 'auto',
    position: 'absolute',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    transition: 'opacity .3s',
    width: '4rem',
  }),
  arrow: css({
    marginLeft: '0.25rem',
  }),
};
interface ViewProps {
  isVisible: boolean,
}

export const View = ({
  isVisible,
}: ViewProps): JSX.Element => (
  <>
    <div css={styles.sticky}>
      <div css={styles.scrollBox(isVisible)}>
        Scroll to see more
        <FontAwesomeIcon icon={faArrowRight} css={styles.arrow} />
      </div>
      <div css={styles.fade(isVisible)} />
    </div>
  </>
);

function shouldDisplayIndicators(ref: HTMLDivElement | null): boolean {
  if (!ref) return false;

  return (ref.scrollLeft + ref.clientWidth) < (ref.scrollWidth * 0.9);
}
interface GraphScrollIndicatorsProps {
  wrapperRef: RefObject<HTMLDivElement>,
}

export const GraphScrollIndicators = ({
  wrapperRef,
  ...props
}: GraphScrollIndicatorsProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const ref = wrapperRef.current;
    function scrollSpy(): void {
      setIsVisible(shouldDisplayIndicators(ref));
    }

    scrollSpy();
    ref?.addEventListener('wheel', scrollSpy);
    return () => ref?.removeEventListener('wheel', scrollSpy);
  }, [wrapperRef]);

  const hookProps = {
    isVisible,
  };

  return (
    <View {...hookProps} {...props} />
  );
};
