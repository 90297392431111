import { useEffect } from 'react';
import { toast } from '~Common/components/Toasts';
import { createQuestionTemplate } from '~Feedback/components/Drawers/Questions/Create';

export const useFeedbackQuestions = ({
  setDrawerState,
  drawerName,
  existingQuestions,
  pushDrawer,
  popAfter,
  drawerState,
  questionKey = 'questions',
  templateId = null,
  addQuestionTemplate = createQuestionTemplate,
}) => {
  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      [questionKey]: existingQuestions ?? [],
    }));
  }, [existingQuestions]);

  const onAddQuestion = () => {
    if (drawerState[questionKey]?.length >= 25) {
      toast.error('Feedback requests are limited to 25 questions.');
      return;
    }

    popAfter({ drawerName });

    pushDrawer({
      drawer: {
        ...addQuestionTemplate,
        args: {
          drawerTitle: 'Add Question',
          context: {},
          questionKey,
          templateId,
          templateUsed: addQuestionTemplate,
        },
      },
    });
  };

  const onEditQuestion = (question) => {
    popAfter({ drawerName });

    pushDrawer({
      drawer: {
        ...addQuestionTemplate,
        args: {
          drawerTitle: 'Edit Question',
          context: {
            question,
          },
          questionKey,
          templateId,
          isEditing: true,
          templateUsed: addQuestionTemplate,
        },
      },
    });
  };

  const onRemoveQuestion = ({ isEditMode, question }) => {
    if (isEditMode) {
      // use confirmation drawer

    } else {
      // use drawer state
      const questions = drawerState[questionKey];
      const questionIndex = questions.findIndex((q) => question.rank === q.rank);

      questions.splice(questionIndex, 1);

      setDrawerState({
        ...drawerState,
        [questionKey]: questions,
      });
    }
  };

  return {
    onAddQuestion,
    onEditQuestion,
    onRemoveQuestion,
  };
};
