import { css } from '@emotion/react';
import { ChangeEvent, ChangeEventHandler, Fragment } from 'react';
import { RRule } from 'rrule';
import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';

const weekdays = [RRule.SU, RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA];

const styles = {
  row: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem',
  }, forMobileObject({
    width: '100%',
  })),
  label: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '2.25rem',
    color: palette.neutrals.black,
    cursor: 'pointer',
    display: 'flex',
    height: '2.5rem',
    justifyContent: 'center',
    margin: 0,
    width: '2.5rem',

    '&:focus': {
      border: `1px solid ${palette.brand.indigo}`,
    },

    'input[type="checkbox"]:checked + &': {
      backgroundColor: palette.brand.indigo,
      color: palette.neutrals.white,
    },

    'input[type="checkbox"]:disabled + &': {
      opacity: '0.4',
      cursor: 'no-drop',
    },
  }),
  checkbox: css({
    display: 'none',
  }),
};

interface ViewProps {
  handleCheck: ChangeEventHandler<HTMLInputElement>,
  selectedDays: number[],
  disabled?: boolean,
}

const View = ({
  selectedDays,
  handleCheck,
  disabled,
}: ViewProps): JSX.Element => (
  <div css={styles.row}>
    {weekdays.map((day) => {
      const inputName = `action-item-weekday-${day.toString()}`;
      const label = day.toString().substring(0, 1);
      const selected = selectedDays.indexOf(day.weekday) !== -1;

      return (
        <Fragment key={inputName}>
          <input
            id={inputName}
            type="checkbox"
            checked={selected}
            onChange={handleCheck}
            value={day.weekday}
            disabled={disabled}
            hidden
          />
          <label
            htmlFor={inputName}
            key={inputName}
            css={styles.label}
          >
            {label}
          </label>
        </Fragment>
      );
    })}
  </div>
);

interface ActionItemWeekdaySelectorProps {
  selectedDays: number[],
  onChange: (days: number[]) => void,
  disabled?: boolean,
}

export const ActionItemWeekdaySelector = ({
  selectedDays,
  onChange,
  disabled,
}: ActionItemWeekdaySelectorProps): JSX.Element => {
  const handleCheck = (event: ChangeEvent<HTMLInputElement>): void => {
    const dayNumber = parseInt(event.target.value, 10);

    const days = new Set(selectedDays);
    if (event.target.checked) {
      days.add(dayNumber);
    } else {
      days.delete(dayNumber);
    }

    onChange(Array.from(days));
  };

  const hookProps = {
    handleCheck,
    selectedDays,
    disabled,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
