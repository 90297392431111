import { css } from '@emotion/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent } from 'react';
import { Dispatch } from 'redux';
import Button from '~Common/V3/components/Button';
import Tooltip from '~Common/components/Tooltip';
import { IsVisibleProps, NavigationItem } from '~Root/components/OldNavigationSidebar/types';
import { useNavigationStore } from '~Root/components/OldNavigationSidebar/useNavigationStore';
import { SHARED_STYLES } from '~Root/components/OldNavigationSidebar/NavigationItems/styles';

const styles = {
  ...SHARED_STYLES,
  button: css({
    width: '100%',
  }),
};

interface ViewProps {
  isCollapsed: boolean,
  item: NavigationItem,
  onClick: (e: MouseEvent<HTMLButtonElement>) => void,
}

const View = ({
  isCollapsed,
  item,
  onClick,
}: ViewProps): JSX.Element => (
  <Tooltip
    key={item.label}
    content={isCollapsed ? item.label : null}
    placement="right"
  >
    <Button
      variant="text"
      css={[styles.button, styles.navItem(isCollapsed)]}
      onClick={onClick}
      data-label={item.label}
      renderContents={() => (
        <>
          <FontAwesomeIcon icon={item.icon} css={styles.icon} />
          {!isCollapsed && (
            <div css={styles.label}>
              {item.label}
            </div>
          )}
          {!isCollapsed && item.isExternalLink && (
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          )}
        </>
      )}
    />
  </Tooltip>
);

interface ActionButtonProps {
  dispatch: Dispatch,
  isCollapsed: boolean,
  isVisibleProps: IsVisibleProps,
  item: NavigationItem,
}

export const ActionButton = ({
  dispatch,
  isCollapsed,
  isVisibleProps,
  item,
}: ActionButtonProps): JSX.Element => {
  const closeMobileNavigation = useNavigationStore((state) => state.closeMobileNavigation);
  const onClick = (e: MouseEvent<HTMLButtonElement>): void => {
    closeMobileNavigation();
    item.onClick?.({ ...isVisibleProps, dispatch, e });
  };

  const hookProps = {
    isCollapsed,
    item,
    onClick,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
