import { connect } from 'react-redux';
import PersonalityTypesList from '~Deprecated/ui/views/Nexus/PersonalityTypes/PersonalityTypesList';
import { getEnneagram } from '~Deprecated/selectors/personalities/getEnneagram';
import { getMyersbriggs } from '~Deprecated/selectors/personalities/getMyersbriggs';
import { getDisc } from '~Deprecated/selectors/personalities/getDisc';
import { getWorkingGenius } from '~Deprecated/selectors/personalities/getWorkingGenius';
import { getPersonalityActiveTab } from '~Deprecated/selectors/personalities/getPersonalities';

const mapStateToProps = (state) => ({
  enneagramList: getEnneagram(state),
  myersBriggesList: getMyersbriggs(state),
  discList: getDisc(state),
  workingGeniusList: getWorkingGenius(state),
  activeTabIndex: getPersonalityActiveTab(state),
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalityTypesList);
