import PropTypes from 'prop-types';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import FormDrawer from '~Common/components/Drawers/Forms/Form';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { useNextMeetings } from '~Deprecated/hooks/feedback/useNextMeetings';
import { useAddToNextMeeting } from '~Deprecated/hooks/feedback/useAddToNextMeeting';
import NextMeetings from '../Tabs/NextMeetings';

export const addToMeetingTemplate = {
  name: 'ADD_FEEDBACK_TO_MEETING',
  width: DRAWER_WIDTHS.SECONDARY,
};

const AddToMeetingListDrawer = ({ otherUserId, questions, ...props }) => {
  const { nextMeetings } = useNextMeetings({ otherUserId });
  const addToMeetingMutation = useAddToNextMeeting({ template: addToMeetingTemplate });

  const tabQueryConfig = {
    module: 'feedback',
    workflow: 'add_to_meeting',
    defaultValue: 0,
  };

  const onMeetingSelected = ({ meeting }) => {
    addToMeetingMutation({
      meeting,
      questions: questions?.map((question) => ({ uid: question.questionId ?? question })),
      otherUserId,
    });
  };

  const hookProps = {
    drawerName: addToMeetingTemplate.name,
    drawerTitle: 'Available Coachings',
    isReadOnly: true,
    tabs: [
      ['Meetings', <NextMeetings meetings={nextMeetings} onMeetingSelected={onMeetingSelected} />],
    ],
    tabQueryConfig,
  };

  return (
    <FormDrawer
      {...props}
      {...hookProps}
    />
  );
};

AddToMeetingListDrawer.propTypes = {
  otherUserId: PropTypes.string.isRequired,
  questions: PropTypes.string.isRequired,
};

AddToMeetingListDrawer.defaultProps = {

};

registerDrawer({ templateName: addToMeetingTemplate.name, component: AddToMeetingListDrawer });

export default AddToMeetingListDrawer;
