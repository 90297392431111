import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { LIGHTBOX_FILE_EXTENSIONS } from '~Meetings/const/attachments';
import { Slide } from 'yet-another-react-lightbox';
import {
  AgendaSection,
  AgendaTopic,
  Attachment,
  GetAgendaReturn,
} from '../hooks/v2/useGetAgendas';

interface SelectLightboxSlidesParams {
  data: HttpCallReturn<GetAgendaReturn>,
  agendaItemId: string,
  sectionId?: string,
}

export const selectLightboxSlides = ({
  data,
  sectionId,
  agendaItemId,
}: SelectLightboxSlidesParams): Slide[] => {
  let lightboxSlides: Attachment[] = [];

  if (sectionId) {
    const agendaSection = data.response.agendas.find((agendaItem) => agendaItem.id === sectionId) as AgendaSection;
    const agendaTopic = agendaSection?.children.find((agendaItem) => agendaItem.id === agendaItemId);
    lightboxSlides = agendaTopic?.attachments || [];
  } else {
    const agendaTopic = data.response.agendas.find((agendaItem) => agendaItem.id === agendaItemId) as AgendaTopic;
    lightboxSlides = agendaTopic?.attachments;
  }

  lightboxSlides = lightboxSlides.filter((attachment) => LIGHTBOX_FILE_EXTENSIONS.includes(attachment.fileExtension.toLowerCase()));

  return lightboxSlides.map((lightboxSlide) => ({
    src: lightboxSlide.fileDownloadUrl!,
    title: lightboxSlide.fileName,
  }));
};
