import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { SURVEY_MULTIPLE_CHOICE_SHAPE } from '~Common/const/proptypes';
import { useDragAndDrop } from '~Deprecated/hooks/useDragAndDrop';
import DragAndDrop from '~Deprecated/ui/components/DragAndDrop/DragAndDrop';
import DrawerInput from '~Common/V3/components/DrawerInput';
import DrawerInfo from '~Common/components/Drawers/Info';
import { noop } from '~Deprecated/utils';
import DashedBoxWithText from '~Common/components/EmptyStates/DashedBoxWithText';
import { hexToRGBA, palette } from '~Common/styles/colors';
import MultipleChoiceOption from './MultipleChoiceOption';

const styles = {
  container: css``,
  options: css`
    margin: 10px 0;
  `,
  createOption: css`
    margin-bottom: 10px;
    background: ${hexToRGBA(palette.neutrals.gray50, 0.6)};
    border-radius: 8px;
    font-weight: 400;
    border: none;
    color: ${palette.neutrals.gray900}

    & input{
      font-size: .875rem;
      font-weight: 400;
    }
  `,
  addOptionText: css`
    margin-top: 16px;
    margin-bottom: 8px;
  `,
  content: css`
    display: flex;
    align-items: center;
    text-align: left;

    &:hover{
      cursor:pointer;
    }
  `,
  leftIcon: css`
    font-size:2.5em;
    font-weight:300;
    margin-right:.25em;
    color: ${palette.brand.indigo}
  `,
  sectionHeader: css`
    margin: 20px 0 0 0;
    text-transform: uppercase;
    font-size:.625rem;
  `,
  addOptions: css`
    margin:.75rem 0;
  `,
  optionButtons: css`
    margin-right:.5rem;
  `,
  dragDrop: css`
    flex-wrap: wrap;
    & > div {
      background: rgba(245, 245, 247, 0.6);
    }
  `,
};

const View = ({
  multipleChoiceOptionsToUse,
  setMultipleChoiceOptionsToUse,
  onDragEnd,
  renderItem,
  setQuestion,
  newOptionText,
  setNewOptionText,
  stringifiedMultipleChoiceOptions,
  inputEl,
  onAddOption,
  setShowAddOptions,
  handleAddOptions,
  showAddOptions,
  onOptionKeydown,
  onAddQuestion,
  handleFocus,
  cancelClick,
  ...props
}) => (
  <div
    css={styles.container}
    {...props}
  >
    <DrawerInfo
      css={styles.sectionHeader}
      info="Options - Drag To Reorder"
    />
    {multipleChoiceOptionsToUse.length === 0 && (
      <DashedBoxWithText
        text="Click '+ Add Question' to get started."
        onClick={handleFocus}
        renderBody={() => (
          <p css={styles.content}>
            <FontAwesomeIcon
              css={[
                styles.leftIcon,
              ]}
              icon={faTriangleExclamation}
            />
            No options in this survey yet. Click below to add your first question.
          </p>
        )}
      />
    )}
    <DragAndDrop
      css={styles.dragDrop}
      list={multipleChoiceOptionsToUse}
      renderItem={renderItem}
      onDragEnd={onDragEnd}
      dragEndProps={{
        initialArray: multipleChoiceOptionsToUse,
        movedItem: (draggableId) => multipleChoiceOptionsToUse.find((question) => question.rank.toString() === draggableId),
        endFunctions: (newArray) => {
          const tempNewArray = newArray.map((option, index) => ({ ...option, rank: index }));
          setMultipleChoiceOptionsToUse(tempNewArray);
          setQuestion((prevState) => ({ ...prevState, multipleChoiceOptions: tempNewArray }));
        },
      }}
      valueToUse="rank"
    />
    {!showAddOptions && (
      <LeadrButton
        css={styles.addOptions}
        onClick={handleAddOptions}
        type="submit"
        size="small"
        variant="ghost"
        data-test-id="surveysMultipleChoiceAddQuestionOptions"
      >
        + Add Options
      </LeadrButton>
    )}
    <input type="hidden" name="multipleChoiceOptions" value={stringifiedMultipleChoiceOptions} />
    {multipleChoiceOptionsToUse?.length < 10 && showAddOptions === true && (
      <>
        <div css={styles.addOptionText}>
          Add Option for Question
        </div>
        <DrawerInput
          css={styles.createOption}
          initialValue={newOptionText}
          onChange={(e) => setNewOptionText(e.target.value)}
          label="Option Content"
          inputRef={inputEl}
          onKeyDown={onOptionKeydown}
        />
        <LeadrButton
          css={styles.optionButtons}
          disabled={newOptionText === ''}
          onClick={onAddOption}
          size="small"
          data-test-id="surveysMultipleChoiceAddOption"
        >
          Add
        </LeadrButton>
        <LeadrButton
          css={styles.optionButtons}
          onClick={cancelClick}
          size="small"
          variant="ghost"
        >
          Cancel
        </LeadrButton>
      </>
    )}
  </div>
);

View.propTypes = {
  multipleChoiceOptionsToUse: PropTypes.arrayOf(
    SURVEY_MULTIPLE_CHOICE_SHAPE,
  ),
  setMultipleChoiceOptionsToUse: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  setQuestion: PropTypes.func,
  newOptionText: PropTypes.string.isRequired,
  setNewOptionText: PropTypes.func.isRequired,
  stringifiedMultipleChoiceOptions: PropTypes.string.isRequired,
  inputEl: PropTypes.instanceOf(DrawerInput).isRequired,
  onAddOption: PropTypes.func.isRequired,
  onOptionKeydown: PropTypes.func.isRequired,
  setShowAddOptions: PropTypes.bool,
  handleAddOptions: PropTypes.func,
  showAddOptions: PropTypes.func,
  onAddQuestion: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  cancelClick: PropTypes.func,
};

View.defaultProps = {
  multipleChoiceOptionsToUse: [],
  setQuestion: noop,
  handleAddOptions: noop,
  showAddOptions: noop,
  setShowAddOptions: false,
  cancelClick: noop,
};

const MultipleChoice = ({
  multipleChoiceOptions, setQuestion, ...props
}) => {
  const { onDragEnd } = useDragAndDrop();
  const [newOptionText, setNewOptionText] = useState('');

  const [multipleChoiceOptionsToUse, setMultipleChoiceOptionsToUse] = useState(multipleChoiceOptions);

  useEffect(() => {
    setMultipleChoiceOptionsToUse(multipleChoiceOptions);
  }, [multipleChoiceOptions]);

  const [stringifiedMultipleChoiceOptions, setStringifiedMultipleChoiceOptions] = useState('');

  useEffect(() => {
    if (multipleChoiceOptionsToUse) {
      setStringifiedMultipleChoiceOptions(JSON.stringify(multipleChoiceOptionsToUse));
    }
  }, [multipleChoiceOptionsToUse]);

  const [showAddOptions, setShowAddOptions] = useState(false);

  const handleAddOptions = () => {
    setShowAddOptions(true);
  };

  const inputEl = useRef(null);
  const handleFocus = () => {
    inputEl.current?.focus();
  };

  const cancelClick = () => {
    setShowAddOptions(false);
  };

  const renderItem = ({ id: option, index }, dragHandleProps) => (
    <MultipleChoiceOption
      dragHandleProps={dragHandleProps}
      css={styles.options}
      option={option}
      key={option.id}
      index={index}
      onUpdateOption={(newText) => {
        const tempMultipleChoiceOptions = [...multipleChoiceOptionsToUse];
        let optionToChange = multipleChoiceOptionsToUse[index];
        optionToChange = { ...optionToChange, option: newText };
        tempMultipleChoiceOptions[index] = optionToChange;
        setQuestion((prevState) => ({ ...prevState, multipleChoiceOptions: tempMultipleChoiceOptions }));
      }}
      onDelete={(indexToDelete) => {
        const tempMultipleChoiceOptions = [...multipleChoiceOptionsToUse];
        tempMultipleChoiceOptions.splice(indexToDelete, 1);
        setQuestion((prevState) => ({ ...prevState, multipleChoiceOptions: tempMultipleChoiceOptions }));
      }}
    />
  );

  const onOptionKeydown = (e) => {
    if (e.key === 'Enter' && newOptionText !== '') {
      e.preventDefault();
      onAddOption();
    }
  };

  const onAddOption = () => {
    if (newOptionText === '') return;

    const newOption = {
      option: newOptionText,
      rank: multipleChoiceOptionsToUse.length.toString(),
    };

    setQuestion((prevState) => ({
      ...prevState,
      multipleChoiceOptions: prevState.multipleChoiceOptions?.concat([newOption])
        ?? [newOption],
    }));
    setNewOptionText('');
    inputEl.current.focus();
  };

  const hookProps = {
    newOptionText,
    setNewOptionText,
    setQuestion,
    renderItem,
    onDragEnd,
    multipleChoiceOptionsToUse,
    setMultipleChoiceOptionsToUse,
    stringifiedMultipleChoiceOptions,
    inputEl,
    onAddOption,
    handleAddOptions,
    setShowAddOptions,
    showAddOptions,
    onOptionKeydown,
    handleFocus,
    cancelClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

MultipleChoice.propTypes = {
  multipleChoiceOptions: PropTypes.arrayOf(
    SURVEY_MULTIPLE_CHOICE_SHAPE,
  ),
  setQuestion: PropTypes.func,
  handleAddOptions: PropTypes.func.isRequired,
};

MultipleChoice.defaultProps = {
  multipleChoiceOptions: [],
  setQuestion: noop,
};

export { View };
export default MultipleChoice;
