import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import InsightMetric from '~Deprecated/ui/components/Insights/InsightMetric';
import InsightStatusIndicator, { INSIGHT_STATUS_SIZE } from '~Deprecated/ui/components/Insights/InsightDetails/InsightStatusIndicator';
import {
  METRIC_NAMES, SCORE_VALUE_NAMES,
} from '~Common/const/executiveInsights';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { palette } from '~Common/styles/colors';
import {
  activityTooltips, coachingTooltips, feedbackTooltips, goalsTooltips,
} from './scoringTooltips';

const TOOLTIP_HELP = {
  [METRIC_NAMES.COACHING]: coachingTooltips,
  [METRIC_NAMES.GOAL]: goalsTooltips,
  [METRIC_NAMES.FEEDBACK]: feedbackTooltips,
  [METRIC_NAMES.ACTIVITY]: activityTooltips,
};

const styles = {
  tooltipContainer: css({
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.75rem',
    gap: '0.75rem',
    padding: '1rem',
  }),
  status: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.375rem',
  }),
  metricType: css({
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '15%',
  }),
  link: css({
    color: palette.brand.indigo,
    textDecoration: 'underline',

    '&:hover': {
      color: palette.brand.indigo,
    },
  }),
};

const ScoringTooltipContent = ({
  insightKey,
  insightValue,
  isAggregate,
  user,
}) => {
  const displayFirstName = user && user.firstName;
  const isCurrentPerson = user && getOrganizationUserId() === user.orgUserId;

  const insightValueText = SCORE_VALUE_NAMES[insightValue];
  const display = TOOLTIP_HELP[insightKey][insightValueText];

  const content = () => {
    if (isAggregate) {
      return display.group;
    }
    if (isCurrentPerson) {
      return display.self;
    }

    return display.individual(displayFirstName !== null ? displayFirstName : 'this user');
  };

  return (
    <div css={styles.tooltipContainer}>
      <div css={styles.status}>
        <InsightMetric size="lg" metric={insightKey} />
        <div css={styles.metricType}>
          {startCase(insightKey)}
        </div>
        <InsightStatusIndicator
          scoreValue={insightValue}
          size={INSIGHT_STATUS_SIZE.SMALL}
        />
      </div>
      {content()}
      <a
        css={styles.link}
        href="https://leadr.helpscoutdocs.com/article/21-admin-people-metrics"
        rel="noreferrer"
        target="_blank"
      >
        Learn More
      </a>
    </div>
  );
};

ScoringTooltipContent.propTypes = {
  insightKey: PropTypes.string.isRequired,
  insightValue: PropTypes.number.isRequired,
  isAggregate: PropTypes.bool,
  firstName: PropTypes.string,
  orgUserId: PropTypes.string,
  user: ATTENDEE_SHAPE,
};

ScoringTooltipContent.defaultProps = {
  firstName: null,
  isAggregate: false,
  orgUserId: '',
  user: null,
};

export default ScoringTooltipContent;
