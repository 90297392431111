import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import MuiDrawer from '@mui/material/Drawer';

import { noop } from '~Deprecated/utils';

import {
  drawerBackdrop,
} from '~Deprecated/ui/styles/colors';

const drawerStyle = css`
  & .MuiBackdrop-root {
    background-color: ${drawerBackdrop};
    backdrop-filter: blur(5px);
  }

  & .MuiDrawer-paper {
    padding: 24px;
  }
  
  & .drawerRow {
    margin: 15px 0;
  }
`;

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
function Drawer({
  isOpen, onClose, className, renderBody, anchor, ...props
}) {
  return (
    <MuiDrawer
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
      css={drawerStyle}
      className={className}
      PaperProps={{ tabIndex: -1 }}
      {...props}
    >
      {renderBody({
        onClose,
      })}
    </MuiDrawer>
  );
}

Drawer.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  renderBody: PropTypes.func.isRequired,
  anchor: PropTypes.string,
};

Drawer.defaultProps = {
  className: '',
  onClose: noop,
  isOpen: false,
  anchor: 'right',
};

export default Drawer;
