import { css, keyframes } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const bounce = keyframes`
    to {
    opacity: 0.1;
    }
`;
const styles = {
  loaderWrapper: css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    minHeight: '38.625rem',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  }),
  loader: css({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '> div': {
      width: '1rem',
      height: '1rem',
      margin: '.1875rem .375rem',
      borderRadius: '50%',
      backgroundColor: palette.brand.indigo,
      opacity: '1',
      animation: `${bounce} 1s infinite alternate`,
    },
    '> div:nth-child(2)': {
      animationDelay: '0.2s',
    },
    '> div:nth-child(3)': {
      animationDelay: '0.4s',
    },
  }),
  content: css({
    flexBasis: '100%',
    textAlign: 'center',
    color: palette.brand.indigo,
    fontWeight: 500,
    fontSize: '1em',
    marginTop: '2rem',
  }),
};

interface ThreeDotLoaderProps {
  renderContents: () => JSX.Element,
}

const ThreeDotLoader = ({
  renderContents,
}: ThreeDotLoaderProps): JSX.Element => (
  <div css={styles.loaderWrapper}>
    <div css={styles.loader}>
      <div />
      <div />
      <div />
    </div>
    <div css={styles.content}>{renderContents()}</div>
  </div>
);

export default ThreeDotLoader;
