import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import cards from '~Insights/const/CardList';
import { CardDetail, DoExpand, DoShrink } from '~Insights/const/types';

interface RouteParams {
  expandedCard?: string
}

interface UseExpandingCards {
  cards: Readonly<CardDetail[]>
  currentExpandedCard: string
  doShrink: DoShrink
  doExpand: DoExpand
}

export function useExpandingCards(): UseExpandingCards {
  const { expandedCard } = useParams<RouteParams>();
  const history = useHistory();
  const [currentExpandedCard, setCurrentExpandedCard] = useState('');

  useEffect(() => {
    const routeExpandedCard = cards.find((card) => card.key === expandedCard);
    if (routeExpandedCard?.expandedComponent) {
      setCurrentExpandedCard(routeExpandedCard.key);
    } else {
      setCurrentExpandedCard('');
    }
  }, [expandedCard]);

  const doShrink: DoShrink = () => {
    history.push('/insights');
  };

  const doExpand: DoExpand = (key: string) => {
    history.push(`/insights/${key}`);
  };

  return {
    cards,
    currentExpandedCard,
    doShrink,
    doExpand,
  };
}
