import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { AgendaTopic as AgendaTopicType, Creator, AgendaTopicComment } from '~Meetings/hooks/v2/useGetAgendas';
import { useGetTopicTimeString } from '~Meetings/hooks/useGetTopicTimeString';
import { faEyeSlash, faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';
import AgendaComment from './AgendaComment';

const styles = {
  agendaTopic: css({
    borderRadius: '.3125rem',
    border: `1px solid ${palette.neutrals.gray200}`,
    padding: '.625rem .75rem',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
  }),
  icon: css({
    fontSize: '1rem',
    cursor: 'not-allowed',
  }),
  incompleteIcon: css({
    color: palette.neutrals.gray400,
  }),
  checkedIcon: css({
    color: palette.brand.indigo,
  }),
  textContainer: css({
    marginLeft: '.75rem',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '.625rem',
  }),
  text: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
  timeText: css({
    color: palette.neutrals.gray600,
    fontSize: '.75rem',
  }),
  rightSide: css({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  }),
  privateIcon: css({
    color: palette.neutrals.gray700,
    fontSize: '1.5625rem',
  }),
  menuIcon: css({
    color: palette.neutrals.gray700,
  }),
  bottomSection: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  topicIndicatorsContainer: css({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  }),
  recurringIcon: css({
    color: palette.brand.indigo,
    fontSize: '.625rem',
  }),
  commentsContainer: css({
    marginTop: '.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.375rem',
  }),
};

interface ViewProps {
  isComplete: boolean,
  textHTML: HTMLString,
  creator: Creator,
  timeSinceComment: string,
  timeSinceEdit: string,
  hasBeenEdited: boolean,
  agendaComments: AgendaTopicComment[],
  isPrivate: boolean,
  isRecurring: boolean,
  showTopicIndicators: boolean,
}

const View = ({
  isComplete,
  textHTML,
  creator,
  timeSinceComment,
  timeSinceEdit,
  hasBeenEdited,
  agendaComments,
  isPrivate,
  isRecurring,
  showTopicIndicators,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.agendaTopic}
    {...props}
  >
    <div css={styles.header}>
      {!isComplete && (
        <FontAwesomeIcon
          icon={faCircle}
          css={[styles.icon, styles.incompleteIcon]}
        />
      )}
      {isComplete && (
        <FontAwesomeIcon
          css={[styles.icon, styles.checkedIcon]}
          icon={faCircleCheck}
        />
      )}
      <div css={styles.textContainer}>
        <HTMLRenderer css={styles.text} htmlText={textHTML} />
        <div css={styles.timeText}>
          <span>{timeSinceComment}</span>
          {hasBeenEdited && (
            <span>{` (edited) ${timeSinceEdit}`}</span>
          )}
        </div>
      </div>
      <div css={styles.rightSide}>
        {isPrivate && (
          <FontAwesomeIcon css={styles.privateIcon} icon={faEyeSlash} />
        )}
        {!isPrivate && (
          <NewBaseAvatar
            avatarSize={30}
            name={`${creator.firstName} ${creator.lastName}`}
            profileImageUrl={creator.profileImageUrl}
          />
        )}
        <FontAwesomeIcon css={[styles.icon, styles.menuIcon]} icon={faEllipsisVertical} />
      </div>
    </div>
    <div css={styles.bottomSection}>
      {showTopicIndicators && (
        <div css={styles.topicIndicatorsContainer}>
          {isRecurring && (
            <FontAwesomeIcon css={styles.recurringIcon} icon={faArrowsRepeat} />
          )}
          { /* Other indicators if those ever exist */ }
        </div>
      )}
      <div css={styles.commentsContainer}>
        {agendaComments.map((agendaComment) => (
          <AgendaComment key={agendaComment.id} agendaComment={agendaComment} />
        ))}
      </div>
    </div>
  </div>
);

interface AgendaTopicProps {
  agendaTopic: AgendaTopicType,
}

const AgendaTopic = ({
  agendaTopic,
  ...props
}: AgendaTopicProps): JSX.Element | null => {
  const timeSinceComment = useGetTopicTimeString(agendaTopic.createdInMillis);
  const timeSinceEdit = useGetTopicTimeString(agendaTopic.lastModifiedInMillis);
  const showTopicIndicators = agendaTopic.isRecurring;
  const hasBeenEdited = agendaTopic.lastModifiedInMillis > agendaTopic.createdInMillis;

  // Invisible agenda topics are other people's private agenda topics
  if (agendaTopic.isInvisible) {
    return null;
  }

  const hookProps = {
    isComplete: agendaTopic.isComplete,
    textHTML: agendaTopic.text,
    creator: agendaTopic.creator,
    timeSinceComment,
    timeSinceEdit,
    hasBeenEdited,
    agendaComments: agendaTopic.children,
    isPrivate: agendaTopic.isPrivate,
    isRecurring: agendaTopic.isRecurring,
    showTopicIndicators,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaTopic;
