import { css } from '@emotion/react';
import { RecognitionUserShape } from '~Recognition/const/types';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import { getColorOrBackground } from '~Recognition/const/colors';
import { palette } from '~Common/styles/colors';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import { useGetAverageNameValue } from '~Recognition/hooks/useGetAverageNameValue';
import Tooltip from '~Common/components/Tooltip';
import CardAvatars from './CardAvatars';

const styles = {
  body: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '.75rem',
    justifyContent: 'space-between',
  }),
  infoContainer: css({
    marginRight: '.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: palette.neutrals.gray700,
    fontSize: '.875rem',
    flexWrap: 'wrap',
  }),
  dateWrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  // Keeping this for phase 2 logic
  blueDot: css({
    width: '.875rem',
    height: '.875rem',
    backgroundColor: palette.brand.indigo,
    display: 'inline-block',
    borderRadius: '.625rem',
    marginRight: '.625rem',
  }),
  bannerContainer: css({
    borderRadius: '.5rem',
    width: '100%',
    padding: '1.25rem',
    color: palette.neutrals.white,
    fontSize: '1.5rem',
    fontWeight: 600,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  }),
  avatar: (overflowView: boolean) => css({
    margin: '.3125rem .75rem .3125rem .3125rem',
    display: 'inline-block',
  }, overflowView && {
    marginRight: '.3125rem',
  }),
  overflowAvatar: css({
    fontSize: '.875rem',
    display: 'flex',
  }),
  dateEditArea: (cantSeeEdit: boolean) => css({
    display: 'flex',
  }, cantSeeEdit && {
    marginRight: '1.25rem',
  }),
};

interface ViewProps {
  recipients: RecognitionUserShape[],
  averageNameValue: number,
  showOverflowAvatarNumber: number,
}

const View = ({
  recipients,
  averageNameValue,
  showOverflowAvatarNumber,
}: ViewProps): JSX.Element => (
  <div css={styles.body}>
    <div
      css={[styles.bannerContainer, getColorOrBackground(averageNameValue), getColorOrBackground(averageNameValue, true)]}
    >
      <CardAvatars
        usersInfo={recipients}
        numberOfUsers={recipients.length}
        numberOfUsersToShow={3}
        showOverflowAvatarNumber={showOverflowAvatarNumber}
        avatarHeight={50}
        avatarWidth={50}
        renderAvatar={({ user, avatarHeight, avatarWidth }) => (
          // numberOfUsers > numberOfUsersToShow
          (
            <div css={styles.avatar(recipients.length > 3)}>
              <AvatarWithTooltip
                firstName={user.firstName}
                lastName={user.lastName}
                profileImageUrl={user.profileImageUrl}
                height={avatarHeight}
                width={avatarWidth}
              />
            </div>
          )
        )}
        renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
          <Tooltip
            content={`${recipients.slice(-overflowAvatarProps.overflowNumber).map((recipient) => `${recipient.firstName} ${recipient.lastName}`).join(', ')}`}
          >
            <div>
              <OverflowAvatar
                css={[styles.avatar(recipients.length > 3), styles.overflowAvatar]}
                {...overflowAvatarProps}
              />
            </div>
          </Tooltip>
        )}
      />
    </div>
  </div>
);

interface CardBannerProps {
  recipients: RecognitionUserShape[],
  createdBy: RecognitionUserShape,
  showOverflowAvatarNumber?: number,
}

const CardBanner = ({
  recipients,
  createdBy,
  showOverflowAvatarNumber = 18,
}: CardBannerProps): JSX.Element => {
  const averageNameValue = useGetAverageNameValue(recipients, createdBy);

  const hookProps = {
    recipients,
    averageNameValue,
    showOverflowAvatarNumber,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default CardBanner;
