import { AgendaType } from '@leadr-hr/types';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { produce } from 'immer';
import moment from 'moment-timezone';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { useDraft } from '~Common/hooks/useDraft';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { getHost } from '~Deprecated/services/config';
import {
  AgendaSection,
  AgendaTopic,
  Attachment,
  GetAgendaReturn,
} from '~Meetings/hooks/v2/useGetAgendas';
import { getLocalId } from '~Common/utils/uuid';
import { useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import { useAgendaPermissionsStore } from '../utils/useAgendaPermissions';
import { useShowAgendaEditor } from '../utils/useShowAgendaEditor';

export interface CreateAgendaItemRequest {
  huddleId: string
  text: string
  isRecurring: boolean,
  isPrivate: boolean,
  sectionId?: string,
  eventId?: string,
  attachments: Attachment[],
}

const createAgendaItem = async ({
  attachments,
  ...request
}: CreateAgendaItemRequest): Promise<HttpCallReturn<void>> => {
  const url = {
    host: getHost('', '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/huddles/agendas`,
  };

  return postApi(url, {
    ...request,
    uploadedAttachmentIds: attachments?.map((attachment) => (attachment.id)),
  });
};

export interface UseCreateAgendaItem {
  draftKey?: string[],
  agendaSectionId?: string,
}

export const useCreateAgendaItem = ({
  draftKey,
  agendaSectionId,
}: UseCreateAgendaItem): UseMutationResult<HttpCallReturn<void>, unknown, CreateAgendaItemRequest> => {
  const orgId = getOrganizationId() ?? '';
  const orgUserId = getOrganizationUserId() ?? '';

  const {
    showAgendaEditor,
    hideAgendaEditor,
    cleanupAgendaEditor,
  } = useShowAgendaEditor();

  const hideItemEditor = useShowItemEditor((state) => state.hideItemEditor);
  const { removeDraft } = useDraft(draftKey);

  const {
    disableSectionWorkflows,
    enableSectionWorkflows,
  } = useAgendaPermissionsStore((state) => ({
    disableSectionWorkflows: state.disableSectionWorkflows,
    enableSectionWorkflows: state.enableSectionWorkflows,
  }));

  const { person } = useGetPersonById({ orgUserId });

  return useMutation({
    mutationFn: createAgendaItem,
    // optimistically push the agenda item into the list so the ui feels snappy
    onMutate: async ({
      huddleId,
      text,
      isPrivate,
      isRecurring,
      sectionId,
      attachments,
    }) => {
      const previousAgendas = queryClient.getQueryData(meetingKeys.agendaList(huddleId));

      if (sectionId) {
        // Creating an agenda item in a section
        hideItemEditor(`${sectionId}-create`);
        disableSectionWorkflows(sectionId);
      } else {
        // Creating an agenda item at the top level
        hideAgendaEditor({ identifier: 'create' });
      }

      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId), (originalData) => {
        if (originalData && person) {
          const newAgendaTopic: AgendaTopic = {
            id: getLocalId(),
            type: AgendaType.AgendaTopic,
            text,
            isPrivate,
            isRecurring,
            createdInMillis: moment().valueOf(),
            lastModifiedInMillis: 0,
            creator: {
              firstName: person.firstName,
              lastName: person.lastName,
              orgUserId: person.orgUserId,
              profileImageUrl: person.profileImageUrl,
            },
            children: [],
            isBookmarked: false,
            isComplete: false,
            permissions: [],
            attachments,
          };
          if (sectionId) {
            // The agenda item is being added to a section
            // Find the section and push it there
            const sectionIndex = originalData.response.agendas.findIndex((agenda) => agenda.id === sectionId);
            if (sectionIndex !== -1) {
              return produce(originalData, (draft) => {
                const agendaSection = draft.response.agendas[sectionIndex] as AgendaSection;
                agendaSection.children.push(newAgendaTopic);
              });
            }
          } else {
            return produce(originalData, (draft) => {
              draft.response.agendas.push(newAgendaTopic);
            });
          }
        }

        return originalData;
      });

      return { previousAgendas };
    },
    // push failed for some reason, roll back and re-open the editor, rely on drafting to populate
    onError: (_, variables, snapshot) => {
      const { huddleId: id } = variables;
      toast.error(<MeetingsErrorToast />, {
        autoClose: 1500,
      });
      queryClient.setQueryData(meetingKeys.agendaList(id), snapshot?.previousAgendas);

      const { attachments = [], isPrivate, isRecurring } = variables;

      showAgendaEditor({
        identifier: 'create',
        props: {
          initialAttachments: attachments,
          isPrivate,
          isRecurring,
        },
      });
    },
    onSuccess: () => {
      removeDraft();
      cleanupAgendaEditor({ identifier: 'create' });
    },
    onSettled: async (_, __, { huddleId }) => {
      await queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(orgId) });
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      if (agendaSectionId) {
        enableSectionWorkflows(agendaSectionId);
      }
    },
  });
};
