import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { queryClient } from '~Common/const/queryClient';
import { GROUPS_LIST_QUERY_KEY } from './useGetGroups';
import { GROUP_DETAIL_QUERY_KEY } from './useGetGroupDetails';

export const editGroup = (group) => {
  const URL = `/organizations/${getOrganizationId()}/ops/teams`;
  return patchApi(URL, group);
};

export const useEditGroup = (teamId) => {
  const toastId = useRef(null);

  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: editGroup,
    onMutate: () => {
      toastId.current = toast.info('Updating the group...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error updating the group. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully updated the group.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey: [...GROUP_DETAIL_QUERY_KEY, teamId] });
      queryClient.invalidateQueries({ queryKey: GROUPS_LIST_QUERY_KEY });
      dispatch(popDrawerAction({ popAll: true }));
    },
  });

  return mutation.mutate;
};
