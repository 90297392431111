import { createUseShowThingActions } from './createShowThingStore';
import { CreateUseShowThingActionsReturn } from './types';

export const createShowModalStore = <T>(identifier: string, enableDebugging?: boolean): () => CreateUseShowThingActionsReturn<
  T,
  'openModal',
  'closeModal',
  'cleanupModal',
  'useGetModalProps',
  'useIsModalOpen'
> => {
  const useShowModal = createUseShowThingActions<
    T,
    'openModal',
    'closeModal',
    'cleanupModal',
    'useGetModalProps',
    'useIsModalOpen'
  >({
    prefix: 'modal',
    actionNames: {
      showActionName: 'openModal',
      hideActionName: 'closeModal',
      cleanupActionName: 'cleanupModal',
      useGetThingPropsActionName: 'useGetModalProps',
      useIsThingVisibleActionName: 'useIsModalOpen',
    },
    defaultIdentifier: identifier,
    enableDebugging,
  });

  return useShowModal;
};
