// https://drive.google.com/file/d/1G_o2SKKsfHsNPPkkrwyp69RajoxTa8hj/view
// Color Definitions - do not use these directly outside of this file
import { css } from '@emotion/react';

export const palette = {
  brand: {
    indigo: '#335AA8', // palette.brand.indigo
    blue: '#0079D2', // palette.brand.blue
    sky: '#00ACF1', // palette.brand.sky
    purple: '#4545A4', // palette.brand.purple
    green: '#03AB50', // palette.brand.green
    red: '#DD393E', // palette.brand.red
    orange: '#FEAF02', // palette.brand.orange
  },
  neutrals: {
    black: '#1D1D15', // palette.neutrals.black
    gray900: '#262523', // palette.neutrals.gray900
    gray800: '#414143', // palette.neutrals.gray800
    gray700: '#5D5E5F', // palette.neutrals.gray700
    gray600: '#7B7B7E', // palette.neutrals.gray600
    gray500: '#99999D', // palette.neutrals.gray500
    gray400: '#BABABD', // palette.neutrals.gray400
    gray300: '#D6D6D9', // palette.neutrals.gray300
    gray200: '#E7E8EB', // palette.neutrals.gray200
    gray100: '#EFEFF2', // palette.neutrals.gray100
    gray50: '#F5F5F7', // palette.neutrals.gray50,
    white: '#FFFFFF', // palette.neutrals.white
    cardBackground: '#F9F9FA', // palette.neutrals.cardBackground
  },
};

const themeUsages = {
  appBackground: palette.neutrals.gray50, // TODO - Colors: Replace all instances of appBackground - https://leadrhq.atlassian.net/browse/LW-5560
};

export const hexToRGBA = (hex, alpha = 1.0) => {
  const r = hex.slice(1, 3);
  const g = hex.slice(3, 5);
  const b = hex.slice(5, 7);

  return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${alpha})`;
};

// Initials
export const avatarInitialsColorRange = [
  hexToRGBA(palette.brand.indigo, 0.85),
  hexToRGBA(palette.brand.blue, 0.85),
  hexToRGBA(palette.brand.sky, 0.85),
  hexToRGBA(palette.brand.purple, 0.85),
  hexToRGBA(palette.brand.green, 0.85),
  hexToRGBA(palette.brand.red, 0.85),
  hexToRGBA(palette.brand.orange, 0.85),
];

export const disabledInputColor = hexToRGBA((palette.neutrals.gray50), 0.6);

// New Navigation
export const nestedListBackground = palette.neutrals.gray50;
export const activeNavigationItemBackground = hexToRGBA(palette.neutrals.gray50, 0.6);
export const navigationItemColor = palette.neutrals.gray600;
export const drawerCollapseIconColor = palette.neutrals.gray400;

// Navigation
export const backNavItemBackground = palette.brand.indigo;
export const scrollbarNavigationColor = palette.brand.purple;

export const navItemTextColor = palette.neutrals.gray200;
export const navItemIconColor = palette.neutrals.gray200;

// Global
export const pageBackground = themeUsages.appBackground;
export const baseShadow = hexToRGBA(palette.neutrals.black, 0.2);

// Cards
export const timelineCardBackground = palette.neutrals.gray50;

// Loader
export const spinnerColor = palette.brand.indigo;

// Buttons
export const additive = palette.brand.green;
export const flatAdditive = palette.brand.green;
export const destructive = palette.brand.red;
export const option = palette.brand.sky;
export const defaultInput = palette.brand.indigo;
export const linearProgressBackground = palette.neutrals.gray400;
export const destructiveOverlay = hexToRGBA(palette.brand.red, 0.7);
export const grayBtn = palette.neutrals.gray200;

export const insightMetricImprove = hexToRGBA(palette.brand.red, 0.7);
export const insightMetricOk = hexToRGBA(palette.brand.orange, 0.7);
export const insightMetricGood = hexToRGBA(palette.brand.green, 0.7);

// Radio
export const radioColor = palette.brand.indigo;

export const tabColors = {
  tab: {
    color: palette.neutrals.gray500,
    hover: {
      background: palette.brand.blue,
    },
    selected: {
      background: palette.brand.blue,
      hover: {
        background: palette.brand.blue,
      },
    },
    disabled: {
      background: palette.neutrals.gray400,
    },
  },
};

export const loginBackground = {
  primary: palette.brand.indigo,
  secondary: palette.brand.blue,
};

export const baseballCardColors = {
  userName: palette.neutrals.gray800,
  department: palette.neutrals.gray500,
  bio: palette.neutrals.gray700,
  personalityType: palette.neutrals.gray700,
  personalityTest: palette.neutrals.gray500,
  favoritesTitle: palette.neutrals.gray400,
  favoritesContent: palette.neutrals.gray700,
  buttonColor: palette.brand.indigo,
  notificationIcon: palette.brand.blue,
  notificationText: palette.brand.blue,
  notificationBackground: hexToRGBA(palette.brand.sky, 0.1),
};

export const bioDrawerColors = {
  dateTitle: palette.neutrals.gray400,
  dateText: palette.neutrals.gray700,
  bioText: palette.neutrals.gray700,
};

const numberOfColors = avatarInitialsColorRange.length;

export const getAvatarColor = (initials) => {
  const colorNumber = `${initials}`.split('').reduce((curr, next) => curr + next.charCodeAt(0), 0) % (numberOfColors - 1);

  const backgroundColor = avatarInitialsColorRange[colorNumber];

  return css`
    background-color: ${backgroundColor};
    color: ${palette.neutrals.white};
  `;
};

export const ideasLightbulbBackground = palette.brand.indigo;

export const drawerHeaderCloseButtonColor = palette.neutrals.gray400;
export const drawerHeaderFontColor = palette.neutrals.gray800;
export const drawerInputBackground = palette.neutrals.gray50;

export const actionMenuIconColor = palette.brand.indigo;

export const drawerInstructionColor = palette.neutrals.gray500;
export const detailsHeaders = palette.neutrals.gray400;

// Required Icon
export const requiredCircle = palette.brand.red;

// Title Bar
export const titleBarFontColor = palette.neutrals.gray800;
export const menuInnerBorder = palette.neutrals.gray200;

export const printColors = {
  subTitle: palette.neutrals.gray800,
  responseBorders: palette.neutrals.gray300,
  userName: palette.neutrals.gray700,
  jobtitle: palette.neutrals.gray500,
};

export const surveyQuestionCardColors = {
  type: palette.neutrals.gray500,
  description: palette.neutrals.gray600,
  multipleChoiceSave: palette.brand.green,
  multipleChoiceCancel: palette.brand.red,
};

export const surveyQuestionPreviewColors = {
  previewText: palette.neutrals.gray500,
  hidePreviewText: palette.brand.blue,
  slider: palette.neutrals.gray800,
};

export const cardDivider = palette.neutrals.gray200;

// Some of the colors below are lightened/darkened variants of the brand colors that are intended for bar charts, don't use them elsewhere without permission
export const barChartColors = [
  palette.brand.indigo,
  palette.brand.sky,
  palette.brand.green,
  '#5991FF',
  palette.brand.orange,
  '#FF878B',
  '#74D7FF',
  '#55DA93',
  '#aeaefc',
  '#fa464c',
];

export const singleBarChartColor = palette.brand.indigo;

export const freeformCardGray = palette.neutrals.gray200;

export const surveyQuestionTypeText = palette.neutrals.gray500;

export const surveyChevronIcon = palette.neutrals.gray500;

export const surveyChevronButtonGroupBorder = palette.neutrals.gray300;

export const surveyActionMenu = {
  border: palette.neutrals.gray200,
  button: palette.neutrals.gray500,
};

export const userGroupsCard = {
  brandTextColor: palette.brand.indigo,
  searchBorder: palette.neutrals.gray300,
  borderColor: palette.neutrals.gray200,
  tableHeaderGray: palette.neutrals.gray100,
  evenTableColor: palette.neutrals.gray200,
};

export const inputBackgrounds = {
  rgba: 'rgba(245, 245, 247, 0.6)',
  hex: '#F9F9FA',
};

export const reviewColors = {
  border: palette.neutrals.gray200,
  userType: palette.neutrals.gray500,
};
