import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import ViewCard from '~Common/components/Cards/ViewCard';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';
import FreeformResponse from './FreeformResponse';

const styles = {
  container: css`
    background-color: white;
  `,
  questionText: css`
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 1rem;
  `,
  card: css`
    margin-top: 1.125rem;
  `,
  response: css`
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  `,
};

const View = ({ groupByQuestions }) => (
  <div>
    { groupByQuestions?.map((question) => (
      <ViewCard
        key={question.questionId}
        css={styles.card}
        renderBody={() => (
          <div css={styles.cardBody}>
            {isHTMLText(question.questionText) && (
            <HTMLRenderer css={styles.questionText} htmlText={question.questionText} />
            )}
            {!isHTMLText(question.questionText) && (
            <div css={styles.questionText}>
              {question.questionText}
            </div>
            )}
            {question.responses?.map((response) => (
              <div css={styles.response} key={response.userId}>
                <FreeformResponse
                  userId={response.userId}
                  richText={response?.responseText || ''}
                  status={ANSWER_STATUS.COMPLETED}
                  key={response.userId}
                  tooltip="Add this response to the next meeting"
                />
              </div>
            ))}
            {question.assignees?.filter((assignee) => !assignee.hasCompletedFeedback).map((assignee) => (
              <div css={styles.response} key={assignee.userId}>
                <FreeformResponse
                  userId={assignee.userId}
                  richText=""
                  status={ANSWER_STATUS.INCOMPLETE}
                  key={assignee.userId}
                  tooltip="Add this response to the next meeting"
                />
              </div>
            ))}
          </div>
        )}
      />
    ))}
  </div>
);

View.propTypes = {
  groupByQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

View.defaultProps = {};

const ResultsByQuestion = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ResultsByQuestion.propTypes = {};

ResultsByQuestion.defaultProps = {};

export { View };
export default ResultsByQuestion;
