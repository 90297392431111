import { css } from '@emotion/react';
import { FC } from 'react';
import ErrorBoundary from '~Common/components/ErrorBoundary';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import { forMobileObject } from '~Common/styles/mixins';
import TrialBanner from '~SelfService/components/TrialBanner';

const styles = {
  landingPageWrapper: css({
    flexGrow: '1',
    overflowY: 'scroll',
  }),
  landingPageContentsContainer: css({
    // Margin bottom doesnt work since there isnt anything to space off of, so we use padding bottom instead (Case 2 in here: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing)
    margin: '1.875rem 1.875rem 0 1.875rem',
    paddingBottom: '3.875rem', // Doing this for the help ceter stuff to not cover the paging stuff
  }, forMobileObject({
    marginTop: '1.875rem',
    marginRight: 'max(1.25rem, env(safe-area-inset-right))',
    marginBottom: 0,
    marginLeft: 'max(1.25rem, env(safe-area-inset-left))',
    paddingBottom: 'env(safe-area-inset-bottom)',
  })),
  topBarContainer: css({
    marginBottom: '1.875rem',
  }),
};

interface ViewProps {
  ChildComponent: JSX.Element,
  TopComponent?: JSX.Element,
  organizationInTrial: boolean,
}

/**
 * The landing page for any module.
 * This is the page where the list of items is displayed.
 */
const View = ({
  ChildComponent,
  TopComponent,
  organizationInTrial,
}: ViewProps): JSX.Element => (
  // We can move this ErrorBoundary up, once we get everything off of using the ContentPane
  <ErrorBoundary>
    { /* The whole page outside of the navbar, needed for the scrollbar to appear in the right spot */}
    <div css={styles.landingPageWrapper}>
      { organizationInTrial && (
        <TrialBanner />
      )}
      { /* The actual contents of the page go inside this div */}
      <div css={styles.landingPageContentsContainer} data-test-id="landingPageContentsContainer">
        {TopComponent && (
          <div css={styles.topBarContainer}>
            {TopComponent}
          </div>
        )}
        {ChildComponent}
      </div>
    </div>
  </ErrorBoundary>
);

interface ModuleLandingPageWrapperSkeletonProps {
  ChildComponentSkeleton: FC,
  TopComponentSkeleton: FC,
}

const ModuleLandingPageWrapperSkeleton = ({
  ChildComponentSkeleton,
  TopComponentSkeleton,
}: ModuleLandingPageWrapperSkeletonProps): JSX.Element => (
  <div css={styles.landingPageWrapper}>
    { TopComponentSkeleton && (
      <div css={styles.topBarContainer}>
        <TopComponentSkeleton />
      </div>
    ) }
    <ChildComponentSkeleton />
  </div>
);

type ModuleLandingPageWrapperProps = Omit<ViewProps, 'organizationInTrial'>;

const ModuleLandingPageWrapper = ({
  ...props
}: ModuleLandingPageWrapperProps): JSX.Element => {
  const { organizationInTrial } = useIsOrganizationInTrial();

  const hookProps = {
    organizationInTrial,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default ModuleLandingPageWrapper;

export { ModuleLandingPageWrapperSkeleton, View };
