/*
  This file serves to create common language and expectations across all executive insight functionality
  so we can change it easily.
*/

import PropTypes from 'prop-types';

import {
  insightMetricImprove,
  insightMetricOk,
  insightMetricGood,
  palette,
  hexToRGBA,
} from '~Common/styles/colors';

export const METRIC_NAMES = {
  COACHING: 'coaching',
  GOAL: 'goal',
  FEEDBACK: 'feedback',
  ACTIVITY: 'activity',
};

export const SCORE_NAMES = {
  GOOD: 'good',
  OK: 'ok',
  IMPROVE: 'improve',
  GREY: 'grey',
};

export const SCORE_NAME_VALUES = {
  [SCORE_NAMES.GOOD]: 3,
  [SCORE_NAMES.OK]: 2,
  [SCORE_NAMES.IMPROVE]: 1,
  [SCORE_NAMES.GREY]: 0,
};

export const SCORE_VALUE_NAMES = {
  3: SCORE_NAMES.GOOD,
  2: SCORE_NAMES.OK,
  1: SCORE_NAMES.IMPROVE,
  0: SCORE_NAMES.GREY,
};

export const SCORE_NAMES_TO_COLORS = {
  [SCORE_NAMES.GOOD]: insightMetricGood,
  [SCORE_NAMES.OK]: insightMetricOk,
  [SCORE_NAMES.IMPROVE]: insightMetricImprove,
  [SCORE_NAMES.GREY]: hexToRGBA(palette.neutrals.gray500, 0.7),
};

export const METRIC_NAME_PROPTYPES = PropTypes.oneOf(Object.values(METRIC_NAMES));

export const SCORE_NAME_PROPTYPES = PropTypes.oneOf(Object.values(SCORE_NAMES));

export const SCORE_NAME_VALUE_PROPTYPES = PropTypes.oneOf(Object.values(SCORE_NAME_VALUES));

// Details the health for each metric for a singular entity
export const METRIC_HEALTH_REPORT_SHAPE = PropTypes.shape({
  [METRIC_NAMES.COACHING]: SCORE_NAME_VALUE_PROPTYPES,
  [METRIC_NAMES.GOAL]: SCORE_NAME_VALUE_PROPTYPES,
  [METRIC_NAMES.FEEDBACK]: SCORE_NAME_VALUE_PROPTYPES,
  [METRIC_NAMES.ACTIVITY]: SCORE_NAME_VALUE_PROPTYPES,
});

export const SCORE_NAME_SUMMARY_SHAPE = PropTypes.shape({
  [SCORE_NAMES.GOOD]: PropTypes.number,
  [SCORE_NAMES.OK]: PropTypes.number,
  [SCORE_NAMES.IMPROVE]: PropTypes.number,
  [SCORE_NAMES.GREY]: PropTypes.number,
});

// Details a summary report for a group
export const METRIC_SUMMARY_REPORT_SHAPE = PropTypes.shape({
  [METRIC_NAMES.COACHING]: SCORE_NAME_SUMMARY_SHAPE,
  [METRIC_NAMES.GOAL]: SCORE_NAME_SUMMARY_SHAPE,
  [METRIC_NAMES.FEEDBACK]: SCORE_NAME_SUMMARY_SHAPE,
  [METRIC_NAMES.ACTIVITY]: SCORE_NAME_SUMMARY_SHAPE,
});

export const DEFAULT_REPORT = {
  [METRIC_NAMES.COACHING]: 0,
  [METRIC_NAMES.GOAL]: 0,
  [METRIC_NAMES.FEEDBACK]: 0,
  [METRIC_NAMES.ACTIVITY]: 0,
};

export const DEFAULT_SCORE_SUMMARY = {
  [SCORE_NAMES.GOOD]: 0,
  [SCORE_NAMES.OK]: 0,
  [SCORE_NAMES.IMPROVE]: 0,
  [SCORE_NAMES.GREY]: 1,
};
export const DEFAULT_MAX_SUMMARY_COUNT = Object.values(DEFAULT_SCORE_SUMMARY).reduce((curr, next) => curr + next, 0);

export const DEFAULT_METRIC_REPORT = {
  [METRIC_NAMES.COACHING]: {
    ...DEFAULT_SCORE_SUMMARY,
  },
  [METRIC_NAMES.GOAL]: {
    ...DEFAULT_SCORE_SUMMARY,
  },
  [METRIC_NAMES.FEEDBACK]: {
    ...DEFAULT_SCORE_SUMMARY,
  },
  [METRIC_NAMES.ACTIVITY]: {
    ...DEFAULT_SCORE_SUMMARY,
  },
};

const PERSON_DATA_NAMES = {
  SUMMARY_REPORT: 'summaryReport',
  FIRST_NAME: 'firstName',
  USER_ID: 'orgUserId',
  MANAGER: 'manager',
};

export const ORG_PERSON_DATA_SHAPE = PropTypes.shape({
  [PERSON_DATA_NAMES.SUMMARY_REPORT]: SCORE_NAME_SUMMARY_SHAPE,
  [PERSON_DATA_NAMES.FIRST_NAME]: PropTypes.string,
  [PERSON_DATA_NAMES.USER_ID]: PropTypes.string,
  [PERSON_DATA_NAMES.MANAGER]: PropTypes.shape({
    orgUserId: PropTypes.string,
  }),
});
