import moment from 'moment-timezone';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useFeatureFlag } from './useFeatureFlag';

interface UseIsOrganizationInTrialReturn {
  organizationInTrial: boolean,
  hasTrialExpired: boolean,
  trialEndsAt?: Date,
  daysRemainingInTrial: number
}

const defaults = {
  organizationInTrial: false,
  hasTrialExpired: false,
  daysRemainingInTrial: 0,
};

function getDaysRemaining(trialEndsAt: Date): number {
  const endOfToday = moment().endOf('day');
  const endOfTrial = moment(trialEndsAt).endOf('day');

  return endOfTrial.diff(endOfToday, 'days');
}

export function useIsOrganizationInTrial(): UseIsOrganizationInTrialReturn {
  const { organizationInTrial, trialEndsAt: trialEndsAtAsString } = useOrgDetailsContext();

  // We can globally control the trial-related UX with this feature flag. With it disabled,
  // no org will experience gating or restrictions.
  const enableTrialLimitations = useFeatureFlag('enableTrialLimitations');

  if (!enableTrialLimitations) {
    return defaults;
  }

  if (!trialEndsAtAsString) {
    return {
      ...defaults,
      organizationInTrial,
    };
  }

  const trialEndsAt = moment(trialEndsAtAsString).endOf('day').toDate();
  const daysRemainingInTrial = getDaysRemaining(trialEndsAt);
  const hasTrialExpired = organizationInTrial && daysRemainingInTrial < 0;

  return {
    ...defaults,
    organizationInTrial,
    trialEndsAt,
    daysRemainingInTrial,
    hasTrialExpired,
  };
}
