import { css } from '@emotion/react';
import { ContextButtons } from '~Reviews/V2/Shared/ContextButtons';
import {
  ADMIN_REVIEWS_BY_CYCLE,
  REVIEWS, REVIEWS_BY_CYCLE,
} from '~Common/const/routes';
import { useHistory } from 'react-router';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faPrint, faFileExport } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '~Common/components/Tooltip';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import { usePrintView as usePrint } from '~Deprecated/hooks/usePrintView';
import { palette } from '~Common/styles/colors';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { usePrintView } from '~Common/stores/usePrintView';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import {
  ManagerReviewStatuses, ParticipantTypeEnum, PersonDisplayInformationReviews, ReviewShape, ReviewStatusEnum, TopicTypeEnum,
} from '../Const/types';
import ReviewHeader from '../Shared/ReviewHeader';
import ReviewProgressStepper from '../Shared/ReviewProgressStepper';
import { useReturnReviewee } from '../Hooks/useReturnReviewee';
import { returnReviewParticipantType } from '../Hooks/returnReviewParticipantType';
import { useSubmitReview } from '../Hooks/useSubmitReview';
import { useReturnLoggedInReviewStatus } from '../Hooks/useReturnLoggedInReviewStatus';
import { useReleaseReview } from '../Hooks/useReleaseReview';
import SignoffReview from '../Response/SignoffReview';
import SignoffComments from '../Response/SignoffComments';
import { useSubmitAllTopics } from '../Hooks/useSubmitAllTopics';
import { DisplayReviewResponses } from '../Response/DisplayReviewResponses';
import { BUTTON_STYLES } from '../Const/pageStyles';
import { useCreateCsvInBackground, useGetCSV } from '../Hooks/useGetCSV';
import { ReviewSaveState, useReviewSaveStateStore } from '../stores/useReviewSaveStateStore';

const styles = {
  ...BUTTON_STYLES,
  actionButton: css({
    marginLeft: '.75rem',
  }),
  saveDraft: css({
    background: 'none',
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  exportButton: css({
    marginRight: '.75rem',
  }),
};

interface ViewProps {
  review: ReviewShape,
  renderButtonText: () => string,
  submitReview: () => void,
  releaseReview: () => void,
  reviewee: PersonDisplayInformationReviews | Record<string, never> | undefined,
  reviewer: PersonDisplayInformationReviews | Record<string, never> | undefined,
  secondaryReviewer: PersonDisplayInformationReviews | Record<string, never> | undefined,
  onBackToReviews: () => void,
  isManager: boolean,
  isManagerOrSecondaryManager: boolean,
  loggedInUserReviewStatus: number,
  reviewerStatus: string,
  isPrintView?: boolean,
  loggedInStatus: () => string,
  canReleaseReview: boolean,
  allQuestionsAnswered: boolean,
  printReview: () => void,
  managerPermissions: boolean,
  exportReview: () => void,
  canExportReviews: boolean,
  isFullySaved: boolean,
}

export const View = ({
  review,
  renderButtonText,
  submitReview,
  releaseReview,
  reviewee,
  reviewer,
  secondaryReviewer,
  onBackToReviews,
  isManager,
  isManagerOrSecondaryManager,
  loggedInUserReviewStatus,
  reviewerStatus,
  isPrintView,
  loggedInStatus,
  canReleaseReview,
  allQuestionsAnswered,
  printReview,
  managerPermissions,
  exportReview,
  canExportReviews,
  isFullySaved,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="contextBackButton"
      renderContents={() => (
        <LeadrButton
          variant="text"
          textButtonColor={palette.neutrals.gray700}
          onClick={onBackToReviews}
          data-test-id="reviewsResponseDetailsBackToReviews"
        >
          {(isManagerOrSecondaryManager || managerPermissions) && (
          <>
            {`Back to '${review.reviewCycle.name}' details`}
          </>
          )}
          {!isManagerOrSecondaryManager && !managerPermissions && (
          <>
            Back to Reviews
          </>
          )}
        </LeadrButton>
      )}
    />
    <ContextButtons
      portalId="contextButtons"
      renderContents={() => (
        <div css={styles.buttonContainer}>
          {reviewee?.statusEnum !== undefined
              && reviewer?.statusEnum !== undefined
              && reviewee?.statusEnum >= ReviewStatusEnum.Submitted
              && reviewer?.statusEnum >= ReviewStatusEnum.Submitted
              && !isPrintView
              && (
                <>
                  {canExportReviews && (
                    <LeadrButton
                      css={styles.exportButton}
                      onClick={exportReview}
                      variant="ghost"
                      data-test-id="reviewsResponseDetailsExportReview"
                    >
                      <LeadrButton.IconAndText icon={faFileExport} text="Export CSV" />
                    </LeadrButton>
                  )}
                  <LeadrButton
                    onClick={printReview}
                    data-test-id="reviewsResponseDetailsPrintReview"
                  >
                    <LeadrButton.IconAndText icon={faPrint} text="Print Review" />
                  </LeadrButton>
                </>
              )}
          {(loggedInUserReviewStatus === ReviewStatusEnum.Active || loggedInUserReviewStatus === ReviewStatusEnum.Unknown) && (
            <LeadrButton
              css={styles.actionButton}
              onClick={submitReview}
              disabled={!allQuestionsAnswered || !isFullySaved}
              data-test-id="reviewsResponseDetailsSubmitReview"
            >
              {!allQuestionsAnswered && (
                <Tooltip interactive content="Please answer all of the review questions.">
                  <div>{renderButtonText()}</div>
                </Tooltip>
              )}
              {!isFullySaved && allQuestionsAnswered && (
                <Tooltip interactive content="Please wait for all answers to finish saving.">
                  <div>{renderButtonText()}</div>
                </Tooltip>
              )}
              {allQuestionsAnswered && isFullySaved && (
                <div>{renderButtonText()}</div>
              )}
            </LeadrButton>
          )}
          {reviewerStatus === ManagerReviewStatuses.Release_Review && isManager && (
            <LeadrButton
              css={styles.actionButton}
              disabled={!canReleaseReview}
              onClick={releaseReview}
              data-test-id="reviewsResponseDetailsReleaseReview"
            >
              {!canReleaseReview && (
                <Tooltip interactive content="Waiting on the reviewee to fill out their review.">
                  <div>Release Review</div>
                </Tooltip>
              )}
              {canReleaseReview && (
                <>Release Review</>
              )}
            </LeadrButton>
          )}
        </div>
      )}
    />
    <ReviewProgressStepper
      review={review}
    />
    {isManagerOrSecondaryManager && (
      <ReviewHeader
        review={review}
        reviewee={reviewee as PersonDisplayInformationReviews}
      />
    )}
    {!isManagerOrSecondaryManager && (
      <ReviewHeader
        review={review}
        reviewer={reviewer as PersonDisplayInformationReviews}
        secondaryReviewer={secondaryReviewer as PersonDisplayInformationReviews}
      />
    )}
    {(review.reviewerStatus === ManagerReviewStatuses.Complete || review.reviewerStatus === ManagerReviewStatuses.Signoff_Review) && (
      <SignoffComments
        review={review}
      />
    )}
    <DisplayReviewResponses
      review={review}
    />
    {loggedInStatus() === ManagerReviewStatuses.Signoff_Review && (
      <SignoffReview
        review={review}
      />
    )}
  </>
);

interface ReviewResponseDetailsProps {
  review: ReviewShape,
}

export interface ReviewHistoryState {
  prevPage: string,
}

export const ReviewResponseDetails = ({ review }: ReviewResponseDetailsProps): JSX.Element => {
  const isPrintView = usePrintView((state) => state.isPrintView);

  const enableReviewExportBackgroundJob = useFeatureFlag('reviewExportBackgroundJob');

  const reviewee = useReturnReviewee(review);
  const { participants } = review;
  const reviewer = participants.find((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.Reviewer));
  const secondaryReviewer = participants.find((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer));
  const isFullySaved = useReviewSaveStateStore((state: ReviewSaveState) => state.reviewSaveStates?.[review.uid]);

  const history = useHistory();

  const { isManager: managerPermissions } = useOrgUserPermissionsContext();
  const { isAdmin } = useUserPermissions();

  const {
    isManager, isSecondaryManager, isReviewee, participantUid, participantReviewTypeEnum,
  } = returnReviewParticipantType(review);
  const loggedInUserReviewStatus = useReturnLoggedInReviewStatus(review);
  const { reviewerStatus } = review;

  const loggedInStatus = (): string => {
    if (isManager) {
      return review.reviewerStatus;
    }
    if (isSecondaryManager) {
      return review.secondaryReviewerStatus;
    }
    return review.revieweeStatus;
  };

  const { mutate: submitReviewMutation } = useSubmitReview();
  const { submitAll } = useSubmitAllTopics();

  const submitReview = async (): Promise<void> => {
    await submitAll();
    submitReviewMutation({ reviewId: review?.uid });
  };
  const releaseReviewMutation = useReleaseReview();
  const releaseReview = (): void => {
    releaseReviewMutation({ reviewId: review?.uid });
  };

  const renderButtonText = (): string => {
    if (isManager || isSecondaryManager) {
      return `Submit ${reviewee.firstName}’s Review`;
    }
    if (isReviewee) {
      return 'Submit Self-Review';
    }
    return 'Submit Review';
  };

  const onBackToReviews = (): void => {
    const routeState = history.location.state as ReviewHistoryState;
    const prevPage = routeState?.prevPage;

    if (prevPage && (isManager || isSecondaryManager || managerPermissions || isAdmin)) {
      if (prevPage.includes('/reviews/admin/cycle')) {
        history.push(ADMIN_REVIEWS_BY_CYCLE.replace(':cycleId', review.reviewCycle.uid));
      } else if (prevPage.includes('/reviews/cycle/')) {
        history.push(REVIEWS_BY_CYCLE.replace(':cycleId', review.reviewCycle.uid));
      }
    } else {
      history.push(REVIEWS);
    }
  };

  const canReleaseReview = review.participants.every((participant) => participant.statusEnum === ReviewStatusEnum.Submitted);

  const availableTopicsToAnswer = review.topics.filter((topics) => FlaggedEnum(topics.topicTargetEnum).hasFlag(participantReviewTypeEnum));
  const allQuestionsAnswered = availableTopicsToAnswer.every((topic) => {
    const myAnswer = topic.answers.find((answer) => answer.participantUid === participantUid);
    if (myAnswer === undefined) {
      return false;
    }
    if (topic.typeEnum === TopicTypeEnum.Freeform) {
      return myAnswer?.stringResponse !== '';
    }

    return myAnswer?.numericResponse !== undefined;
  });

  const { onPrint } = usePrint();
  const printReview = (): void => {
    onPrint({ location: `reviews/${review.uid}` });
  };
  const getReviewsCSV = useGetCSV();
  const createReviewsCSVInBackground = useCreateCsvInBackground();
  const exportReview = (): void => {
    const { orgUserId } = reviewee;
    const cycleId = review.reviewCycle.uid;
    if (enableReviewExportBackgroundJob) {
      createReviewsCSVInBackground({ cycleId, orgUserIds: [orgUserId] });
    } else {
      getReviewsCSV({ cycleId, orgUserIds: [orgUserId] });
    }
  };

  const canExportReviews = useFeatureFlag('reviewsDataExport');

  const hookProps = {
    review,
    renderButtonText,
    submitReview,
    releaseReview,
    reviewee,
    reviewer,
    secondaryReviewer,
    onBackToReviews,
    isManager,
    isManagerOrSecondaryManager: isManager || isSecondaryManager,
    loggedInUserReviewStatus,
    reviewerStatus,
    isPrintView,
    loggedInStatus,
    canReleaseReview,
    allQuestionsAnswered,
    printReview,
    managerPermissions,
    exportReview,
    canExportReviews,
    isFullySaved,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
