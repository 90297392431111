import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  faAngleDoubleRight,
  faCalendarAlt,
  faComment,
  faEdit,
  faLink,
  faPeopleArrowsLeftRight,
  faPersonToDoor,
  faPrint,
  faScreenUsers,
  faSync,
  faTrashAlt,
  faTrashCanClock,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { newEditMeetingTemplate } from '~Meetings/components/drawers/EditMeeting';
import { rescheduleMeetingTemplate } from '~Meetings/components/drawers/RescheduleMeeting';
import { endOccurenceTemplate } from '~Meetings/components/drawers/EndOccurence';
import { resyncMeetingTemplate } from '~Meetings/components/drawers/ResyncMeetingDrawer';
import { skipMeetingTemplate } from '~Meetings/components/drawers/SkipMeeting';
import { useIsMobileQuery, useIsTabletQuery } from '~Common/hooks/useMediaListener';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { restoreMeetingTemplate } from '~Meetings/components/drawers/RestoreMeeting';
import { transferOwnershipTemplate } from '~Meetings/components/drawers/TransferOwnership';
import { usePrintView } from '~Deprecated/hooks/usePrintView';
import { useMeetingUrl } from '~Common/hooks/useMeetingUrl';
import { huddleActions } from '~Meetings/const/huddleActions';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { useMetric } from '~Common/hooks/metrics/useMetric';
import { leaveMeetingTemplate } from '~Meetings/components/drawers/LeaveMeeting';
import { MetricType } from '~Common/const/loggingAndMetrics';
import { useImpersonationStore } from '~Deprecated/hooks/useImpersonationStore';
import { MeetingFactoryType, MeetingFrequency } from '~Meetings/const/meetingsInterfaces';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { getOrganizationUserId, getUserId } from '~Common/utils/localStorage';
import { editLinkTeamDrawerTemplate } from '~Meetings/components/drawers/EditLinkTeamDrawer';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TeamsListScope } from '~People/components/Teams/const/types';
import { usePrivateMeetingNotesDrawer } from './usePrivateMeetingNotesDrawer';

const useMeetingDetailMenu = ({ id, type, onClickCallback }) => {
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const {
    factoryId,
    frequency,
    factoryType,
    kebabActions,
    calendarIntegration: {
      linkToUsersExternalCalendar,
    } = {},
    isSeriesActive,
    teamId,
    organizer,
  } = meetingDetails ?? {};

  const dispatch = useDispatch();
  const isMobile = useIsMobileQuery();
  const isTablet = useIsTabletQuery();
  const { onPrint } = usePrintView();
  const { isAdmin } = useUserPermissions();
  const { isImpersonating } = useImpersonationStore();
  const { openDrawer: openPrivateNotesDrawer } = usePrivateMeetingNotesDrawer();

  const { data } = useGetTeams({
    listScope: TeamsListScope.MyTeams,
    page: 0,
    count: 200,
  });

  const hasTeams = useMemo(() => (
    !!data?.response.totalTeamCount
  ), [
    data?.response,
  ]);

  const { customMetric } = useMetric();

  const { copyMeetingUrlToClipboard } = useMeetingUrl();

  const huddleActionsMap = useMemo(() => ({
    [huddleActions.EDIT_SERIES]: {
      text: frequency === MeetingFrequency.ONE_TIME ? 'Edit Meeting' : 'Edit Meeting Series',
      icon: faEdit,
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...newEditMeetingTemplate,
            args: {
              id,
              type,
            },
          },
        }));
        onClickCallback();
      },
    },
    [huddleActions.RESCHEDULE_INSTANCE]: {
      text: 'Reschedule this Meeting',
      icon: faCalendarAlt,
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...rescheduleMeetingTemplate,
            args: {
              id,
              type,
            },
          },
        }));
        onClickCallback();
      },
    },
    [huddleActions.SKIP_INSTANCE]: {
      text: 'Skip this Meeting',
      icon: faAngleDoubleRight,
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...skipMeetingTemplate,
            args: {
              id,
              type,
            },
          },
        }));
        onClickCallback();
      },
    },
    [huddleActions.ARCHIVE_SERIES]: {
      text: frequency === MeetingFrequency.ONE_TIME ? 'Cancel this Meeting' : 'End Recurrence',
      icon: faTrashAlt,
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...endOccurenceTemplate,
            args: {
              id,
              type,
            },
          },
        }));

        onClickCallback();
      },
    },
    [huddleActions.COPY_SHARE_LINK]: {
      text: 'Copy Share Link',
      icon: faLink,
      onClick: () => {
        copyMeetingUrlToClipboard({
          meetingInstanceId: id,
          meetingType: type,
          teamId,
          factoryId,
        });
        onClickCallback();
      },
    },
    [huddleActions.PRINT]: {
      text: 'Print',
      icon: faPrint,
      onClick: () => {
        onPrint({ location: `${type.toLowerCase()}/${id}` });
        onClickCallback();
      },
    },
    [huddleActions.TRANSFER_OWNERSHIP]: {
      text: 'Transfer Ownership',
      icon: faPeopleArrowsLeftRight,
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...transferOwnershipTemplate,
            args: {
              id,
              factoryId,
              type,
            },
          },
        }));
        onClickCallback();
      },
    },
    [huddleActions.LEAVE_MEETING]: {
      text: 'Leave Meeting',
      icon: faPersonToDoor,
      destructive: true,
      styles: {
        iconColor: palette.brand.red,
        menuText: css`
          color: ${palette.brand.red};
        `,
      },
      deactivatedStyles: {
        iconColor: hexToRGBA(palette.brand.red, 0.5),
        menuText: css`color: ${hexToRGBA(palette.brand.red, 0.5)};`,
      },
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...leaveMeetingTemplate,
            args: {
              id,
              type,
            },
          },
        }));
        onClickCallback();
      },
    },
    [huddleActions.RESTORE_SERIES]: {
      text: 'Restore Meeting',
      icon: faTrashCanClock,
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...restoreMeetingTemplate,
            args: {
              factoryId,
              type,
              id,
            },
          },
        }));
        onClickCallback();
      },
    },
    [huddleActions.RESYNC_SERIES]: {
      text: 'Re-sync Meeting Series',
      icon: faSync,
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...resyncMeetingTemplate,
            args: {
              id,
              type,
            },
          },
        }));
        onClickCallback();
      },
    },
    [huddleActions.VIEW_IN_CALENDAR]: {
      text: 'View External Calendar',
      icon: faArrowUpRightFromSquare,
      onClick: () => {
        window.open(linkToUsersExternalCalendar, '_blank');
        customMetric({
          metricName: 'meeting.viewExternalCalendar',
          metricType: MetricType.count,
          data: 1,
        });
        onClickCallback();
      },
    },
    [huddleActions.VIEW_PRIVATE_NOTES]: {
      text: 'View Private Notes',
      icon: faComment,
      onClick: () => {
        openPrivateNotesDrawer(factoryId);
        onClickCallback();
      },
    },
    [huddleActions.EDIT_TEAM_LINK]: {
      text: teamId ? 'Edit/Remove Team Link' : 'Link to a Team',
      icon: faScreenUsers,
      onClick: () => {
        dispatch(pushDrawerAction({
          drawer: {
            ...editLinkTeamDrawerTemplate,
            args: {
              initialTeamId: teamId,
              factoryId,
              meetingId: id,
            },
          },
        }));
        onClickCallback();
      },
    },
  }), [
    frequency,
    dispatch,
    id,
    type,
    onClickCallback,
    copyMeetingUrlToClipboard,
    teamId,
    onPrint,
    factoryId,
    linkToUsersExternalCalendar,
    customMetric,
    openPrivateNotesDrawer,
  ]);

  const impersonatorActions = useMemo(() => {
    const existingActions = new Set(kebabActions?.map((action) => action.actionName) ?? []);

    const actions = [];
    if (isSeriesActive && !existingActions.has(huddleActions.ARCHIVE_SERIES)) {
      actions.push({
        actionName: huddleActions.ARCHIVE_SERIES,
        isEnabled: true,
        section: 1,
        rank: 6,
      });
    }
    if (isSeriesActive && !existingActions.has(huddleActions.EDIT_SERIES)) {
      actions.push({
        actionName: huddleActions.EDIT_SERIES,
        isEnabled: true,
        section: 0,
        rank: 1,
      });
    }
    if (isSeriesActive && !existingActions.has(huddleActions.RESCHEDULE_INSTANCE)) {
      actions.push({
        actionName: huddleActions.RESCHEDULE_INSTANCE,
        isEnabled: true,
        section: 0,
        rank: 2,
      });
    }
    if (!isSeriesActive && !existingActions.has(huddleActions.RESTORE_SERIES)) {
      actions.push({
        actionName: huddleActions.RESTORE_SERIES,
        isEnabled: true,
        section: 2,
        rank: 9,
      });
    }
    if (isSeriesActive
      && frequency !== MeetingFrequency.ONE_TIME
      && !existingActions.has(huddleActions.SKIP_INSTANCE)) {
      actions.push({
        actionName: huddleActions.SKIP_INSTANCE,
        isEnabled: true,
        section: 1,
        rank: 5,
      });
    }
    if (isSeriesActive
      && factoryType !== MeetingFactoryType.COACHING
      && !existingActions.has(huddleActions.TRANSFER_OWNERSHIP)) {
      actions.push({
        actionName: huddleActions.TRANSFER_OWNERSHIP,
        isEnabled: true,
        section: 2,
        rank: 11,
      });
    }

    return actions;
  }, [kebabActions, frequency, factoryType, isSeriesActive]);

  const ffTeamMeetingNotes = useFeatureFlag('webClientPrivateTeamMeetingNotes');
  const webAppTgxMeetings = useFeatureFlag('webAppTgxMeetings');
  const shouldAddViewPrivateNotes = ffTeamMeetingNotes && (isTablet || isMobile) && factoryType === MeetingFactoryType.TEAM_MEETING;
  const shouldShowEditTeamLink = webAppTgxMeetings
    && factoryType === MeetingFactoryType.TEAM_MEETING
    && hasTeams
    && (isAdmin || (getUserId() === organizer.userId || getOrganizationUserId() === organizer.orgUserId));

  // TODO: Break this into smaller chunks.
  return useMemo(() => {
    const kebabActionsToUse = [
      ...(kebabActions || []), // This is temporary undefined if you navigate here through something like the calendar that starts with the meetingFactoryId route
      // Since the view private notes is specific to the frontend, we have to make our own object for that and conditionally push in
      ...(shouldAddViewPrivateNotes ? [{
        actionName: huddleActions.VIEW_PRIVATE_NOTES,
        isEnabled: true,
        rank: 9,
        section: 2,
      }] : []),
      ...(shouldShowEditTeamLink ? [{
        actionName: huddleActions.EDIT_TEAM_LINK,
        isEnabled: true,
        rank: 10,
        section: 2,
      }] : []),
      ...(isImpersonating ? impersonatorActions : []),
    ];

    /*
      Maps the backend kebabActions to frontend menu items
      Filters out sections of the menu that don't have any items
      Sorts each section by the rank of the menu item
    */
    return kebabActionsToUse.reduce((accumulator, currentValue) => {
      const frontendKebabAction = [huddleActionsMap[currentValue.actionName]][0];
      const stylesToUse = currentValue.isEnabled ? frontendKebabAction.styles : frontendKebabAction.deactivatedStyles;

      accumulator[currentValue.section].push({
        ...frontendKebabAction,
        rank: currentValue.rank,
        isDeactivated: !currentValue.isEnabled,
        tooltip: currentValue.disabledReason,
        styles: stylesToUse,
        onClick: currentValue.isEnabled ? frontendKebabAction.onClick : undefined,
      });

      return accumulator;
    }, [[], [], []]).filter((menuItem) => menuItem.length).map((menuItem) => menuItem.sort((a, b) => a.rank - b.rank));
  }, [kebabActions, shouldAddViewPrivateNotes, shouldShowEditTeamLink, isImpersonating, impersonatorActions, huddleActionsMap]);
};

useMeetingDetailMenu.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClickCallback: PropTypes.func,
};

useMeetingDetailMenu.defaultProps = {
  onClickCallback: () => {},
};

export default useMeetingDetailMenu;
