import { css } from '@emotion/react';
import { ChangeEvent } from 'react';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';

const styles = {
  moduleTopbar: css({
    display: 'grid',
    gridTemplateAreas: '"searchField rightSection"',
    gridTemplateColumns: 'auto auto',
  }),
};

interface ViewProps {
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  searchText: string,
  handleCreateModalDisplay: (value: boolean) => void,
}

const View = ({
  handleSearchTextChange,
  searchText,
  handleCreateModalDisplay,
}: ViewProps): JSX.Element => (
  <>
    <ModuleTopbar
      css={styles.moduleTopbar}
      moduleTopbarLayout="custom"
      renderSearchField={() => (
        <LeadrSearchField
          value={searchText}
          data-test-id="recognitionDashboardSearch"
          onChange={handleSearchTextChange}
          debounceTime={700}
          placeholder="Search Names..."
        />
      )}
      renderRightSection={() => (
        <CreateButton
          itemName="Recognition"
          onClick={() => handleCreateModalDisplay(true)}
          data-test-id="recognitionCreateNew"
        />
      )}
    />
  </>
);

interface RecognitionTopBarProps {
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  searchText: string,
  handleCreateModalDisplay: (value: boolean) => void,
}

const RecognitionTopBar = ({
  handleSearchTextChange,
  searchText,
  handleCreateModalDisplay,
}: RecognitionTopBarProps): JSX.Element => {
  const hookProps = {
    handleSearchTextChange,
    searchText,
    handleCreateModalDisplay,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default RecognitionTopBar;
