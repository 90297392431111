export const SURVEY_FILTER_NAMES = {
  CREATED_BY_YOU: 'Created by You',
  SENT_TO_YOU: 'Sent to You',
};

export const SURVEY_QUESTION_TYPES = {
  MULTIPLE_CHOICE: {
    value: 'MULTIPLE_CHOICE',
    text: 'Multiple Choice',
    key: 4,
  },
  RANKING: {
    value: 'RANKING',
    text: 'Ranking',
    key: 1,
  },
  FREEFORM: {
    value: 'FREEFORM',
    text: 'Freeform',
    key: 2,
  },
};

export const NO_RESPONSE_TEXT = 'Waiting for response...';
