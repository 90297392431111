export const LEARNING_LIBRARY_PAGE_SIZE = 5;

export const LEARNING_DEFAULT_PAGE_NUMBER = 1;

export const LEARNING_MAX_QUESTIONS = 25;

export const NEW_QUESTION_EDITOR_ID = 'newQuestionEditor';

export const LEARNING_MAX_SHOWN_AVATARS_COUNT = 2;

export const OPTIMISTIC_ID = 'OPTIMISTIC_ID';

export const LEARNING_MAX_CONTENT_IN_PLAYLIST = 50;
