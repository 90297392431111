import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

export const PAGE_STYLES = {
  dashboardSpace: css({
    marginBottom: '1.875rem !important',
  }),
  recognitionActionMenu: css({
    width: '175px',
    '& .MuiMenu-list': {
      width: 'auto',
    },
    '& .MuiMenu-list > div div li': {
      fontWeight: '500',
    },
    '& .MuiMenu-list > div div:last-of-type li': {
      borderTop: `1px solid ${palette.neutrals.gray200}`,
      color: palette.brand.red,

      '& svg': {
        color: palette.brand.red,
      },
    },
    '& .MuiMenu-list > div div:first-of-type li': {
      border: 'none',
    },
  }),
  recognitionEllipsis: (overide?: boolean) => css({
    marginRight: '.625rem',
    color: palette.neutrals.gray500,
    marginLeft: '.5rem',

    '& svg': {
      borderRadius: '.1875rem',
      color: palette.neutrals.gray500,
    },
  }, overide && {
    marginRight: '-1rem',
  }),
  removeLink: css({
    ':hover': {
      textDecoration: 'none',
    },
  }),
};
