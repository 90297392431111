import { ChangeEventHandler } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import MuiSwitch from '@mui/material/Switch';
import { v4 as uuid } from 'uuid';
import { hexToRGBA, palette } from '~Common/styles/colors';

interface SwitchStyleProps {
  activeTrackColor?: string
  labelLength: number
}

const makeStyles = ({
  activeTrackColor = palette.brand.indigo,
  labelLength,
}: SwitchStyleProps): Record<string, SerializedStyles> => {
  const trackWidth = Math.max(6.5, 3 + (0.4 * labelLength));
  const dimmedColor = hexToRGBA(activeTrackColor, 0.6);

  return {
    container: css({
      position: 'relative',
      width: 'fit-content',
    }),
    label: css({
      cursor: 'pointer',
      position: 'absolute',
      textAlign: 'right',
      left: '2.75rem',
      top: '.75rem',
      transition: 'all 100ms ease',
      color: palette.neutrals.black,
      fontSize: '.75rem',
      userSelect: 'none',

      '&.active': {
        textAlign: 'left',
        left: '.75rem',
        color: palette.neutrals.white,
      },
    }),
    switch: css({
      cursor: 'pointer',
      width: `${trackWidth}rem`,
      height: '2.5rem',
      padding: '0',

      '& .MuiSwitch-root': {
        borderRadius: '1.25rem',
        alignItems: 'center',
        cursor: 'pointer',
      },
      '& .MuiSwitch-switchBase': {
        padding: '.25rem .25rem',
        transition: 'all 100ms ease',
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        left: `${trackWidth - 3.75}rem`,
      },
      '& .MuiSwitch-thumb': {
        width: '2rem',
        height: '2rem',
        borderRadius: '1.25rem',
        backgroundColor: palette.neutrals.white,
      },
      '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
        placeSelf: 'flex-end',
      },
      '& .MuiSwitch-track': {
        borderRadius: '1.25rem',
        backgroundColor: palette.neutrals.gray400,
        border: 'none',
        display: 'block',
        opacity: 1,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: activeTrackColor,
        backgroundOpacity: 1,
        opacity: 1,
      },
      '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: dimmedColor,
      },
      '& .Mui-disabled': {
        cursor: 'not-allowed',
      },
    }),
    loading: css({
      '& .MuiSwitch-thumb': {
        border: `10px solid ${dimmedColor}`,
        borderTopColor: palette.neutrals.white,
        animation: 'loading 1s ease infinite',
        boxShadow: 'none',
      },

      '@keyframes loading': {
        to: {
          transform: 'rotate(1turn)',
        },
      },
    }),
  };
};

interface LabeledSwitchProps {
  checked: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  inactiveLabel: string
  activeLabel: string
  switchStyle?: SwitchStyleProps
  id?: string
  isLoading?: boolean,
  disabled?: boolean,
}

const LabeledSwitch = ({
  isLoading = false,
  disabled = false,
  checked,
  inactiveLabel,
  activeLabel,
  onChange,
  switchStyle,
  id = uuid(),
  ...props
}: LabeledSwitchProps): JSX.Element => {
  const styles = makeStyles({
    // "Updating..." is 11 characters. Adjust as needed.
    labelLength: Math.max(activeLabel.length, inactiveLabel.length, 11),
    ...switchStyle,
  });

  return (
    <div
      css={[styles.container, isLoading ? styles.loading : null]}
      {...props}
    >
      <MuiSwitch
        id={id}
        css={styles.switch}
        checked={checked}
        onChange={onChange}
        disabled={isLoading || disabled}
      />
      <label
        htmlFor={id}
        css={styles.label}
        className={checked ? 'active' : ''}
      >
        {isLoading && 'Updating...'}
        {!isLoading && (checked ? activeLabel : inactiveLabel)}
      </label>
    </div>
  );
};

export default LabeledSwitch;
