/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck - this whole this is borked because of the images
import { css, SerializedStyles } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import dots from '~Recognition/const/Assets/dots.png';
// import dots2 from '~Recognition/const/Assets/dots2.png';
// import dots3 from '~Recognition/const/Assets/dots3.png';
import dots4 from '~Recognition/const/Assets/dots4.png';

export const recognitionAvatarInitialsColorRange = [
  palette.brand.indigo,
  palette.brand.blue,
  palette.brand.purple,
  palette.brand.green,
  palette.brand.orange,
  palette.brand.red,
];
const recognitionNumberOfColors = recognitionAvatarInitialsColorRange.length;

export const recognitionAvatarInitialsBackgroundRange = [
  dots,
  // dots2,
  // dots3,
  dots4,
];
const recognitionNumberOfBackgrounds = recognitionAvatarInitialsBackgroundRange.length;

export const getAvatarColor = (initials: string): SerializedStyles => {
  const colorNumber = `${initials}`.split('').reduce((curr, next) => curr + next.charCodeAt(0), 0) % (recognitionNumberOfColors - 1);
  const backgroundColor = recognitionAvatarInitialsColorRange[colorNumber];

  return css({
    backgroundColor,
  });
};

export const getAvatarBackground = (initials: string): SerializedStyles => {
  const backgroundNumber = `${initials}`.split('').reduce((curr, next) => curr + next.charCodeAt(0), 0) % (recognitionNumberOfBackgrounds - 1);
  const backgroundImage = recognitionAvatarInitialsBackgroundRange[backgroundNumber];

  return css({
    background: `url(${backgroundImage})`,
    backgroundBlendMode: 'soft-light',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  });
};

export const getColorOrBackground = (averageNameValue: number, returnAsBackground?: boolean): string | SerializedStyles => {
  const searchList = returnAsBackground ? recognitionAvatarInitialsBackgroundRange : recognitionAvatarInitialsColorRange;
  const colorIndex = Math.floor(averageNameValue) % searchList.length;
  const searchListOption = searchList[colorIndex];

  if (returnAsBackground) {
    return css({
      background: `url(${searchListOption ?? searchList[0]})`,
      backgroundBlendMode: 'soft-light',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    });
  }
  return css({
    backgroundColor: `${searchListOption ?? searchList[5]} !important`,
  });
};
