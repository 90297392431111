import { css } from '@emotion/react';
import {
  faCircleCheck, faCircleMinus, faSquareCheck, faSquareMinus,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, CheckboxProps } from '@mui/material';
import { darken } from 'polished';
import { ForwardedRef, forwardRef } from 'react';
import { CommonComponentSharedProps } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';

type CheckboxSize = 16 | 20 | 24;

const CHECKBOX_SIZE_TO_BORDER_RADIUS_MAP = {
  16: 2,
  20: 4,
  24: 6,
};

type CheckboxVariant = 'square' | 'circle';

interface UncheckedParams {
  size: CheckboxSize,
  disabled: boolean,
  variant: CheckboxVariant,
}

const styles = {
  leadrCheckbox: (size: CheckboxSize) => css({
    width: `${size}px`,
    height: `${size}px`,
    padding: 0,
    '&:hover, &:focus-within': {
      '.uncheckedIcon': {
        borderColor: palette.brand.blue,
      },
      '.checkedIcon, .intermediateIcon': {
        color: darken(0.1, palette.brand.indigo),
      },
    },
  }),
  unchecked: ({ size, disabled, variant }: UncheckedParams) => css({
    // The -2px accounts for the border so that unchecked and checked are the same size, since checked doesnt have a border
    width: `${size - 2}px`,
    height: `${size - 2}px`,
    border: `1px solid ${palette.neutrals.gray400}`,
    backgroundColor: palette.neutrals.white,
  }, disabled && ({
    backgroundColor: palette.neutrals.gray300,
    borderColor: palette.neutrals.gray300,
  }), variant === 'circle' && ({
    borderRadius: '50%',
  })),
  checked: (disabled: boolean) => css({
    color: palette.brand.indigo,
  }, disabled && ({
    color: palette.neutrals.gray300,
  })),
  icon: (checkboxSize: CheckboxSize) => css({
    width: `${checkboxSize}px`,
    height: `${checkboxSize}px`,
    borderRadius: `${CHECKBOX_SIZE_TO_BORDER_RADIUS_MAP[checkboxSize]}px`,
  }),
};

export interface LeadrCheckboxProps extends Omit<CheckboxProps, 'checkedIcon' | 'size'>, CommonComponentSharedProps {
  size?: CheckboxSize,
  variant? : 'square' | 'circle',
}

const LeadrCheckbox = forwardRef(({
  size = 20,
  disabled,
  'data-test-id': dataTestId,
  variant = 'square',
  ...props
}: LeadrCheckboxProps, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => (
  <Checkbox
    css={styles.leadrCheckbox(size)}
    data-test-id={`${dataTestId}Checkbox`}
    checkedIcon={(
      <FontAwesomeIcon
        css={[styles.icon(size), styles.checked(!!disabled)]}
        icon={variant === 'square' ? faSquareCheck : faCircleCheck}
        className="checkedIcon"
      />
    )}
    indeterminateIcon={(
      <FontAwesomeIcon
        icon={variant === 'square' ? faSquareMinus : faCircleMinus}
        css={[styles.icon(size), styles.checked(!!disabled)]}
        className="intermediateIcon"
      />
    )}
    icon={(
      <div css={[styles.icon(size), styles.unchecked({ size, disabled: !!disabled, variant })]} className="uncheckedIcon" />
    )}
    disabled={disabled}
    ref={ref}
    {...props}
  />
));

export default LeadrCheckbox;
