import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { emptyStateBorder, emptyStateBoxBg, emptyStateColor } from '~Deprecated/ui/styles/colors';

const style = css`
  background-color: ${emptyStateBoxBg};
  color: ${emptyStateColor};
  border: 1px dashed ${emptyStateBorder};
  padding: 16px;
  text-align:center;
  border-radius: 8px;

  h5 {
    font-size: 12px;
    line-height: 2em;
  }
`;

const DashedBoxWithText = ({ text, renderBody, ...props }) => (
  <div
    css={style}
    {...props}
  >
    {renderBody ? renderBody() : (
      <h5>{text}</h5>
    )}
  </div>
);

DashedBoxWithText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  renderBody: PropTypes.func,
};

DashedBoxWithText.defaultProps = {
  text: null,
  className: '',
  renderBody: null,
};

export default DashedBoxWithText;
