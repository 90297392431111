import PropTypes from 'prop-types';

import Loader from '~Common/components/Loader';
import DashedBoxWithText from '~Deprecated/ui/components/EmptyStates/DashedBoxWithText';
/* eslint-disable-next-line */
import TemplateItem from '~Deprecated/common/Components/Templates/TemplateItem';
import { FEEDBACK_TEMPLATE_SHAPE } from '~Common/const/proptypes';
import { API_STATUS } from '~Common/const/apiStatus';

// TODO: Delete this after feedback UI rewrite
const Templates = ({ status, templates, ...props }) => (
  <div>
    {status !== API_STATUS.SUCCESS && (
      <Loader />
    )}
    {status === API_STATUS.SUCCESS && templates.length === 0 && (
      <DashedBoxWithText text="No templates are available to view." />
    )}
    {status === API_STATUS.SUCCESS && templates.length > 0 && (
      <div>
        {templates.map((template) => (
          <TemplateItem key={template.id} template={template} {...props} />
        ))}
      </div>
    )}
  </div>
);

Templates.propTypes = {
  status: PropTypes.number,
  templates: PropTypes.arrayOf(FEEDBACK_TEMPLATE_SHAPE),
};

Templates.defaultProps = {
  status: 0,
  templates: [],
};

export default Templates;
