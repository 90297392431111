import { Link } from 'react-router-dom';

import {
  ORG,
  PEOPLE,
  TEAMS,
} from '~Common/const/routes';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faNetworkWired } from '@fortawesome/pro-light-svg-icons';
import { css } from '@emotion/react';
import { usePeoplePageSearch } from '~People/stores/usePeoplePageSearch';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { forMobileTinyObject } from '~Common/styles/mixins';
import { useDispatch } from 'react-redux';
import { navigateAction } from '~Deprecated/actions/navigate';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import PeoplePageTabNavigation from './PeoplePageTabNavigation';
import { useShowCreateEditTeamModal } from '../Teams/stores/useShowCreateEditTeamModal';

const styles = {
  moduleTopbar: css({
    display: 'grid',
    gridTemplateAreas: '"searchField rightSection"',
    gridTemplateColumns: 'auto auto',
  }),
  moduleTopbarWithTeams: (showRightButton: boolean) => css({
    gridTemplateAreas: '"toggleTabs searchField"',
    gridTemplateColumns: 'auto 1fr',
  }, showRightButton && {
    gridTemplateAreas: '"toggleTabs searchField rightSection"',
    gridTemplateColumns: 'auto 1fr auto',
  }, forMobileTinyObject({
    gridTemplateAreas: `
      "toggleTabs toggleTabs"
      "searchField rightSection"
    `,
    gridTemplateColumns: '1fr auto',
  }), showRightButton && forMobileTinyObject({
    gridTemplateAreas: `
      "toggleTabs"
      "searchField"
      "rightSection"
    `,
  })),
  toggleTab: css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    width: '50%',
  })),
};

interface ViewProps {
  route: string,
  searchText: string,
  webAppTeamsV2: boolean,
  showCreateTeamButton: boolean,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  handleRouteChangeClick: (event: SyntheticEvent, newRoute: string) => void,
  handleCreateTeamClick: () => void,
}

const View = ({
  route,
  searchText,
  webAppTeamsV2,
  showCreateTeamButton,
  handleSearchTextChange,
  handleRouteChangeClick,
  handleCreateTeamClick,
}: ViewProps): JSX.Element => (
  <>
    {webAppTeamsV2 && (
      <ModuleTopbar
        css={styles.moduleTopbarWithTeams(route === PEOPLE || showCreateTeamButton)}
        moduleTopbarLayout="custom"
        renderTabNavigationToggle={() => (
          <PeoplePageTabNavigation
            toggleTabStyles={styles.toggleTab}
            value={route}
            handleRouteChangeClick={handleRouteChangeClick}
          />
        )}
        renderSearchField={route === PEOPLE ? () => (
          <LeadrSearchField
            data-test-id="peopleSearchField"
            onChange={handleSearchTextChange}
            defaultValue={searchText}
          />
        ) : undefined}
        renderRightSection={() => (
          <>
            {route === PEOPLE && (
              <LeadrButton
                component={Link}
                to={ORG}
                data-test-id="orgChart"
              >
                <LeadrButton.IconAndText icon={faNetworkWired} text="Org Chart" />
              </LeadrButton>
            )}
            {showCreateTeamButton && (
              <CreateButton
                itemName="Team"
                onClick={handleCreateTeamClick}
                data-test-id="teamsCreateNew"
              />
            )}
          </>
        )}
      />
    )}
    {!webAppTeamsV2 && (
      <ModuleTopbar
        css={styles.moduleTopbar}
        moduleTopbarLayout="custom"
        renderSearchField={() => (
          <LeadrSearchField
            data-test-id="peopleSearchField"
            onChange={handleSearchTextChange}
            defaultValue={searchText}
          />
        )}
        renderRightSection={() => (
          <LeadrButton
            component={Link}
            to={ORG}
            data-test-id="orgChart"
          >
            <LeadrButton.IconAndText icon={faNetworkWired} text="Org Chart" />
          </LeadrButton>
        )}
      />
    )}
  </>
);

interface PeopleTopbarProps {
  initialRoute: string,
}

const PeopleTopbar = ({
  initialRoute,
  ...props
}: PeopleTopbarProps): JSX.Element => {
  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');
  const dispatch = useDispatch();
  const { isLimitedAccess } = useUserPermissions();
  const [route, setRoute] = useState(initialRoute);

  useEffect(() => {
    setRoute(initialRoute);
  }, [initialRoute]);

  const {
    searchText,
    setSearchText,
  } = usePeoplePageSearch((state) => ({
    searchText: state.searchText,
    setSearchText: state.setSearchText,
  }));

  const {
    setShowModal,
  } = useShowCreateEditTeamModal((state) => ({
    setShowModal: state.setShowModal,
  }));

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const handleRouteChangeClick = (event: SyntheticEvent, newRoute: string): void => {
    if (newRoute) {
      dispatch(navigateAction({ pathname: newRoute }));
    }
  };

  const handleCreateTeamClick = (): void => {
    setShowModal(true);
  };

  const showCreateTeamButton = !isLimitedAccess && route === TEAMS;

  const hookProps = {
    route,
    searchText,
    webAppTeamsV2,
    handleSearchTextChange,
    handleRouteChangeClick,
    handleCreateTeamClick,
    showCreateTeamButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default PeopleTopbar;
