import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { pick } from 'lodash';
import { TopicShape } from '../Const/types';

interface createTopicForCycleProps {
  cycleId: string,
  topics: TopicShape[],
}

const createTopicForCycle = ({ cycleId, topics }: createTopicForCycleProps): Promise<HttpCallReturn<TopicShape>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${cycleId}/topics`,
  };

  const pickedTopics = topics.map((topic) => pick(topic, ['description', 'text', 'topicTargetEnum', 'typeConfig', 'typeEnum']));
  return postApi<TopicShape>(serverUrl, pickedTopics, {});
};

export const useCreateTopicForCycle = ():
  UseMutationResult<HttpCallReturn<TopicShape>, unknown, createTopicForCycleProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: createTopicForCycle,
    onMutate: () => {
      toastId.current = toast.info('Adding Question...', { autoClose: false });
    },
    onError: (error: string) => {
      toast.update(toastId.current, {
        render: error.toString() ?? 'There was an error adding the question to the review cycle. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully added the question.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewTopics'] });
    },
  });

  return mutation;
};
