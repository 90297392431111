import { css } from '@emotion/react';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewBaseAvatar, { BaseAvatarProps } from '~Common/components/Users/Avatars/NewBaseAvatar';

import { palette } from '~Common/styles/colors';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  selectedAdminCard: css({
    padding: '.25rem .375rem',
    border: `1px solid ${palette.neutrals.gray300}`,
    borderRadius: '.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    color: palette.neutrals.gray700,
    '&:disabled': {
      backgroundColor: palette.neutrals.gray300,
      color: palette.neutrals.white,
    },
  }),
};

type SelectedAdminCardProps = Pick<BaseAvatarProps, 'name' | 'profileImageUrl'> & Omit<LeadrButtonProps<'button'>, 'data-test-id'>;

const SelectedAdminCard = ({
  name,
  profileImageUrl,
  onClick,
  ...props
}: SelectedAdminCardProps): JSX.Element => (
  <LeadrButton
    css={styles.selectedAdminCard}
    data-test-id="reviewsRemoveSelectedAdmin"
    onClick={onClick}
    variant="text"
    {...props}
  >
    <NewBaseAvatar
      avatarSize={22}
      showTooltip={false}
      name={name}
      profileImageUrl={profileImageUrl}
    />
    <span>{name}</span>
    <FontAwesomeIcon icon={faTimesCircle} />
  </LeadrButton>
);

export default SelectedAdminCard;
