import { css } from '@emotion/react';
import { Link, useHistory } from 'react-router-dom';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import LeadrCard from '~Common/V3/components/LeadrCard';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import { palette } from '~Common/styles/colors';
import emptyActionsItems from '~ActionItems/assets/images/emptyActionItems.png';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { InteriorTopBar } from '../GoalsTopBar/InteriorTopBar';

const styles = {
  leadrCard: (isMobile: boolean, isDrawer: boolean) => css({
    marginTop: '1.875rem',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    marginBottom: '2rem',
  }, isMobile && {
    gridTemplateColumns: '1fr',
  }, isDrawer && {
    margin: 0,
    padding: 0,
    boxShadow: 'none',
    overflow: 'unset',
  }),
  detailsTitle: (isMobile: boolean) => css({
    gridColumn: '1 / 4',
    fontSize: '1.125rem',
    fontWeight: 600,
    paddingBottom: '1rem',
    borderBottom: `1px solid ${palette.neutrals.gray300}`,
    marginBottom: '1rem',
  }, isMobile && {
    gridColumn: '1',
  }),
  emptyStateImage: css({
    height: '20rem',
  }),
  container: css({
    width: '100%',
    margin: '1.875rem 1.875rem 0 1.875rem',
  }),
  textBackButton: css({
    fontSize: '1rem',
    fontWeight: 400,
    color: palette.neutrals.gray800,
  }),
  icon: css({
    marginRight: '0.5rem',
  }),
  emptyState: css({
    gridColumn: '1 / 4',
  }),
};

interface CanNotViewGoalDetailsProps {
  isDrawer?: boolean,
}

const CanNotViewGoalDetails = ({
  isDrawer = false,
}: CanNotViewGoalDetailsProps): JSX.Element => {
  const isMobile = useIsMobileQuery();
  const history = useHistory();
  const onClickViewGoals = (): void => {
    history.push(GoalsRoutes?.Dashboard);
  };
  return (
    <>
      <div css={styles.container}>
        <InteriorTopBar
          renderLeftSide={() => (
            <>
              <LeadrButton
                component={Link}
                to={GoalsRoutes?.Dashboard}
                variant="text"
                css={styles.textBackButton}
                textButtonColor={palette.neutrals.gray700}
                data-test-id="goalsBackToGoalsList"
              >
                <FontAwesomeIcon
                  css={styles.icon}
                  icon={faArrowLeft}
                />
                Back
              </LeadrButton>
            </>
          )}
          renderRightSide={() => (<></>)}
        />
        <LeadrCard
          css={styles.leadrCard(isMobile, isDrawer)}
        >
          <h1 css={styles.detailsTitle(isMobile)}>
            Permission Denied
          </h1>
          <div
            css={styles.emptyState}
          >
            <EmptyStateWithImage
              renderImage={() => (
                <img
                  css={styles.emptyStateImage}
                  src={emptyActionsItems}
                  alt="Empty Goals"
                />
              )}
              renderText={() => (
                <>
                  <span>
                    You do not have permission to view this goal.
                    <LeadrButton
                      variant="text"
                      textButtonColor={palette.brand.blue}
                      onClick={onClickViewGoals}
                      data-test-id="actionItemsEmptyStateCreateActionItem"
                    >
                      Click here to view your goals
                    </LeadrButton>
                  </span>
                </>
              )}
            />
          </div>
        </LeadrCard>
      </div>
    </>
  );
};

export default CanNotViewGoalDetails;
