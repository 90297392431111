import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { useDispatch } from 'react-redux';
import { isCurrentUser } from '~Common/utils';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { addToMeetingDrawerTemplate } from '~Learning/components/Shared/AddToMeetingDrawer';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import { ReceivedLearningQuestion } from '~Learning/const/interfaces';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ReceivedLearningQuestionCard from './ReceivedLearningQuestionCard';

const styles = {
  questionsContainer: css({}),
  buttonsContainer: css({
    marginTop: '1.125rem',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  submitLearningButton: css({
    marginRight: '.625rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  questions: ReceivedLearningQuestion[],
  learningId: string,
  isReadOnly: boolean,
  onAddToMeetingClick: () => void,
  shouldShowAddToMeetingButton: boolean,
  contentUrl: string,
  renderLeftButton?: () => JSX.Element,
}

const View = ({
  questions,
  contentUrl,
  learningId,
  isReadOnly,
  onAddToMeetingClick,
  shouldShowAddToMeetingButton,
  renderLeftButton,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <PreviewWithExternalNavigation
      contentUrl={contentUrl}
    />
    <div css={styles.questionsContainer}>
      {questions.map((question) => (
        <ReceivedLearningQuestionCard key={question.id} isReadOnly={isReadOnly} learningId={learningId} question={question} />
      ))}
      {(!isReadOnly || shouldShowAddToMeetingButton) && (
        <div css={styles.buttonsContainer}>
          <div>
            {!isReadOnly && (
              <LeadrButton
                type="submit"
                css={styles.submitLearningButton}
                data-test-id="learningSubmit"
              >
                Submit
              </LeadrButton>
            )}
            {renderLeftButton?.()}
          </div>
          {shouldShowAddToMeetingButton && (
            <LeadrButton
              variant="ghost"
              onClick={onAddToMeetingClick}
              data-test-id="learningAddToMeeting"
            >
              + Add to Meeting
            </LeadrButton>
          )}
        </div>
      )}
    </div>
  </div>
);

interface ContentTabProps extends ComponentProps<'div'> {
  questions: ReceivedLearningQuestion[],
  contentUrl: string,
  learningId: string,
  isReadOnly: boolean,
  assignerId: string,
  renderLeftButton?: () => JSX.Element,
  showAddToMeetingButton?: boolean,
}

const ContentTab = ({
  contentUrl,
  assignerId,
  learningId,
  showAddToMeetingButton,
  ...props
}: ContentTabProps): JSX.Element => {
  const dispatch = useDispatch();

  const shouldShowAddToMeetingButton = !!showAddToMeetingButton && !isCurrentUser(assignerId);

  const hookProps = {
    learningId,
    contentUrl,
    onAddToMeetingClick: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...addToMeetingDrawerTemplate,
          args: {
            otherUserId: assignerId,
            learningId,
          },
        },
      }));
    },
    shouldShowAddToMeetingButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default ContentTab;
