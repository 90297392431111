import { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { nearestFutureMinutes } from '~Deprecated/utils/meeting';
import { usePrevious } from '~Deprecated/hooks/usePrevious';

export function useRoundedTime(baseTime, roundAmount = 0) {
  const previousTime = usePrevious(baseTime);
  const mDateTime = baseTime ? nearestFutureMinutes(roundAmount, moment(baseTime)) : moment();

  const [dateTime, setDateTime] = useState(mDateTime.toDate());

  useEffect(() => {
    if (baseTime && previousTime && baseTime !== previousTime) {
      setDateTime(nearestFutureMinutes(roundAmount, moment(baseTime)).toDate());
    }
  }, [baseTime, previousTime, roundAmount]);

  const setDate = useCallback((_date) => {
    setDateTime(nearestFutureMinutes(roundAmount, moment(_date)).toDate());
  }, [roundAmount]);

  return {
    date: dateTime,
    time: dateTime,
    setDate,
  };
}
