import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { getUserId } from '~Common/utils/localStorage';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';

interface InsightPrivileges {
  hasDirectReports: number
  isExecutive: boolean
  isAdmin: boolean
  canSeeInsights: boolean,
  canSeeInsightsHomeModule: boolean,
}

export interface UserInfo {
  user: {
    orgDetails: {
      enableInsights: boolean,
    },
    countActiveDirects: number,
  }
}

export function useInsightPrivileges(): InsightPrivileges {
  const { isAdmin, isExecutive } = useUserPermissions();
  const { user } = useUserProfile(getUserId() ?? '') as UserInfo;
  const isInsightsEnabled = user?.orgDetails?.enableInsights;
  const { countActiveDirects = 0 } = user || {};

  // Seeing Insights in the navigation menu only requires a single direct report because People Metrics is not anonymized.
  // Seeing Insights in Leadr Home requires 2+ direct reports to have useful information.
  // Admin and Execs always see Insights.
  const canSeeInsights = isInsightsEnabled && (isExecutive || isAdmin || countActiveDirects >= 1);
  const canSeeInsightsHomeModule = isInsightsEnabled && (isExecutive || isAdmin || countActiveDirects > 1);

  return {
    hasDirectReports: countActiveDirects,
    isExecutive,
    isAdmin,
    canSeeInsights,
    canSeeInsightsHomeModule,
  };
}
