import { createReducer } from '../redux/reducers/index';

const initialState = {
  ids: [],
  data: {},
  totalCount: 0,
};

const { reducer, registerAction } = createReducer('organizationMembers', initialState);

export { reducer, registerAction, initialState };
