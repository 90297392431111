import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';

import Tags from '~Common/components/Autocomplete/Tags';
import ClearableDropdown from '~Common/components/Fields/ClearableDropdown';
import TextField from '~Common/components/Fields/TextField';
import { ATTENDEE_SHAPE, ORGANIZATION_SETTINGS_SHAPE } from '~Common/const/proptypes';
import { baseballCardColors, palette } from '~Common/styles/colors';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';

import { useLoadPersonalitites } from '~Deprecated/hooks/profile/useUserProfile';
import { STRENGTHS } from '~Common/const/defaults';
import { PERSONALITY_FIND_LINKS, PERSONALITY_INFO_LINKS } from '~Common/const/personalityLinks';
import Tooltip from '~Common/components/Tooltip';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useGetFormattedCustomPersonalityTypes } from '~Admin/hooks/settings/useGetFormattedCustomPersonalityTypes';

const styles = {
  container: css`
    margin-bottom: 25px;

    h4 {
      margin-bottom: 8px;
    }
  `,
  select: css`
    padding: 7px 16px !important;
    width: 100%;
    margin-bottom: 5px !important;
    background: ${palette.neutrals.gray100};
    color: ${palette.neutrals.gray700};
  `,
  field: css({
    marginBottom: '10px',
    background: palette.neutrals.gray100,
    color: palette.neutrals.gray700,
    border: 'none',

    '& label': {
      color: palette.neutrals.gray700,
      fontWeight: '400',
      textTransform: 'none',
    },
  }),
  customFieldDivider: css`
    margin-bottom: 20px;
  `,
  customPersonalities: {
    container: css`
      margin-top: 10px;
    `,
  },
  moreInfoButtonContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  moreInfo: css`
    &, &.MuiButtonBase-root {
      font-size: 11px;
      font-weight: 500;
      color: ${baseballCardColors.buttonColor};
      padding: 0.25rem;
    }
  `,
  linksContainer: css`
    display: flex;
    flex-direction: column;
  `,
  strengthFinder: css`
    margin-bottom: 10px;
  `,
  personalityTypeContainer: css`
    margin-bottom: 10px;
  `,
};

const View = ({
  personalityTypes,
  customPersonalities,
  settings,
  showPersonalityTypeHeader,
  strengths,
  initialStrengths,
  onStrengthFinderWhatLinkClick,
  onStrengthFinderFindLinkClick,
  workingGeniusOptions,
  initialFrustrations,
  initialGenius,
  onWorkingGeniusWhatLinkClick,
  onWorkingGeniusFindLinkClick,
  workingGeniusUrl,
  initialCompetencies,
  enableWorkingGenius,
}) => (
  <div css={styles.container}>
    {showPersonalityTypeHeader && (
    <>
      <SectionHeader text="Personality Types" />
      {enableWorkingGenius && (
        <>
          <div css={styles.strengthFinder}>
            <div css={styles.field}>
              <Tags
                limitTags={2}
                tags={workingGeniusOptions}
                initialValue={initialGenius}
                label="Working Genius"
                name="workingGeniuses"
                shouldDisableTagsFunction={(tags) => {
                  if (tags.length >= 2) {
                    return true;
                  }
                  return false;
                }}
              />
            </div>
          </div>
          <div css={styles.strengthFinder}>
            <div css={styles.field}>
              <Tags
                limitTags={2}
                tags={workingGeniusOptions}
                initialValue={initialCompetencies}
                label="Working Competencies"
                name="workingCompetencies"
                shouldDisableTagsFunction={(tags) => {
                  if (tags.length >= 2) {
                    return true;
                  }
                  return false;
                }}
              />
            </div>
          </div>
          <div css={styles.strengthFinder}>
            <div css={styles.field}>
              <Tags
                limitTags={2}
                tags={workingGeniusOptions}
                initialValue={initialFrustrations}
                label="Working Frustrations"
                name="workingFrustrations"
                shouldDisableTagsFunction={(tags) => {
                  if (tags.length >= 2) {
                    return true;
                  }
                  return false;
                }}
              />
            </div>
            <div css={styles.linksContainer}>
              <div css={styles.moreInfoButtonContainer}>
                <ButtonBase disableRipple onClick={onWorkingGeniusWhatLinkClick} css={styles.moreInfo}>
                  What is Working Genius?
                </ButtonBase>
                {workingGeniusUrl && (
                <ButtonBase disableRipple onClick={onWorkingGeniusFindLinkClick} css={styles.moreInfo}>
                  Find out your Working Genius
                </ButtonBase>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {personalityTypes.map((personalityType) => (
        <React.Fragment key={`pt-${personalityType.title}`}>
          <div css={styles.personalityTypeContainer}>
            <ClearableDropdown
              css={styles.select}
              label={personalityType.title}
              items={personalityType.options}
              initialValue={personalityType.initialValue}
              name={personalityType.name}
            />
            <div css={styles.linksContainer}>
              <div css={styles.moreInfoButtonContainer}>
                <Tooltip content={personalityType.tooltip} allowHTML>
                  <ButtonBase disableRipple onClick={personalityType.onWhatLinkClick} css={styles.moreInfo}>
                    {`What is ${personalityType.title}?`}
                  </ButtonBase>
                </Tooltip>
                <Tooltip content={personalityType.tooltip} allowHTML>
                  <ButtonBase disableRipple onClick={personalityType.onFindLinkClick} css={styles.moreInfo}>
                    {`Find out your ${personalityType.title} Type`}
                  </ButtonBase>
                </Tooltip>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
      {settings.enableStrengthFinder && (
      <div css={styles.strengthFinder}>
        <div css={styles.field}>
          <Tags
            tags={strengths}
            initialValue={initialStrengths}
            label="StrengthsFinder"
            name="strengths"
            shouldDisableTagsFunction={(tags) => {
              if (tags.length >= 5) {
                return true;
              }

              return false;
            }}
          />
        </div>
        <div css={styles.linksContainer}>
          <div css={styles.moreInfoButtonContainer}>
            <ButtonBase disableRipple onClick={onStrengthFinderWhatLinkClick} css={styles.moreInfo}>
              What is StrengthFinder?
            </ButtonBase>
            <ButtonBase disableRipple onClick={onStrengthFinderFindLinkClick} css={styles.moreInfo}>
              Find out your StrengthFinder strengths
            </ButtonBase>
          </div>
        </div>
      </div>
      )}
      {customPersonalities.map((personalityType, index) => (
        <React.Fragment key={`cpt-${personalityType.title}`}>
          <div css={styles.customPersonalities.container}>
            <TextField
              css={styles.field}
              label={`${personalityType.title} Type`}
              initialValue={personalityType.value}
              name={personalityType.valueName}
            />
            <TextField
              css={[styles.field, index !== customPersonalities.length - 1 ? styles.customFieldDivider : null]}
              label="Link to more information about your type"
              initialValue={personalityType.link}
              name={personalityType.urlValueName}
            />
          </div>
        </React.Fragment>
      ))}
    </>
    )}
  </div>
);

View.propTypes = {
  personalityTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  customPersonalities: PropTypes.arrayOf(PropTypes.string),
  settings: ORGANIZATION_SETTINGS_SHAPE.isRequired,
  strengths: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialStrengths: PropTypes.arrayOf(PropTypes.object).isRequired,
  onStrengthFinderWhatLinkClick: PropTypes.func.isRequired,
  onStrengthFinderFindLinkClick: PropTypes.func.isRequired,
  showPersonalityTypeHeader: PropTypes.bool.isRequired,
  workingGeniusOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialFrustrations: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialGenius: PropTypes.arrayOf(PropTypes.object).isRequired,
  onWorkingGeniusWhatLinkClick: PropTypes.func.isRequired,
  onWorkingGeniusFindLinkClick: PropTypes.func.isRequired,
  workingGeniusUrl: PropTypes.string,
  initialCompetencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  enableWorkingGenius: PropTypes.bool.isRequired,
};

View.defaultProps = {
  customPersonalities: [],
  workingGeniusUrl: '',
};

const PersonalityTypes = ({
  user, ...props
}) => {
  const { data, isLoading } = useLoadPersonalitites();

  const { orgSettings } = useOrgDetailsContext();
  const { workingGeniusUrl } = orgSettings;

  let ennegramOptions = [];
  let myersBriggsOptions = [];
  let discOptions = [];
  let workingGeniusOptions = [];
  const { formattedCustomPersonalities } = useGetFormattedCustomPersonalityTypes(user.userId);

  if (data) {
    ennegramOptions = data?.Enneagram.map((option) => (
      {
        text: option.label,
        value: option.id,
      }
    ));
    myersBriggsOptions = data['Myers Briggs'].map((option) => (
      {
        text: option.label,
        value: option.id,
      }
    ));
    discOptions = data.DiSC.map((option) => (
      {
        text: option.label,
        value: option.id,
      }
    ));
    workingGeniusOptions = data.workingGeniusSkills.map((option) => (
      {
        title: option,
        value: option,
      }
    ));
  }

  const strengths = STRENGTHS.map((strength) => ({
    title: strength,
    value: strength,
  }));

  const {
    strengths: userStrengths = [],
  } = user;

  const initialStrengths = userStrengths.map((strength) => ({
    title: strength,
    value: strength,
  }));
  const initialFrustrations = workingGeniusOptions.filter((type) => user.workingFrustrations?.includes(type.value));
  const initialGenius = workingGeniusOptions.filter((type) => user.workingGeniuses?.includes(type.value));
  const initialCompetencies = workingGeniusOptions.filter((type) => user.workingCompetencies?.includes(type.value));

  const hookProps = {
    enableWorkingGenius: orgSettings.enableWorkingGenius,
    workingGeniusOptions,
    initialFrustrations,
    initialGenius,
    workingGeniusUrl,
    initialCompetencies,
    personalityTypes: [{
      title: 'Enneagram',
      name: 'enneagram',
      options: ennegramOptions,
      onFindLinkClick: () => {
        window.open(PERSONALITY_FIND_LINKS.Enneagram);
      },
      onWhatLinkClick: () => {
        window.open(PERSONALITY_INFO_LINKS.Enneagram);
      },
      initialValue: user.personalities?.Enneagram?.personalityId || null,
      enabled: orgSettings.enableEnneagram,
    },
    {
      title: 'Myers Briggs',
      name: 'myersBriggs',
      options: myersBriggsOptions,
      onFindLinkClick: () => {
        window.open(PERSONALITY_FIND_LINKS['Myers Briggs']);
      },
      onWhatLinkClick: () => {
        window.open(PERSONALITY_INFO_LINKS['Myers Briggs']);
      },
      initialValue: user.personalities['Myers Briggs']?.personalityId || null,
      enabled: orgSettings.enableBriggsMyers,
    },
    {
      title: 'DiSC',
      name: 'disc',
      options: discOptions,
      onFindLinkClick: () => {
        window.open(PERSONALITY_FIND_LINKS.DiSC);
      },
      onWhatLinkClick: () => {
        window.open(PERSONALITY_INFO_LINKS.DiSC);
      },
      initialValue: user.personalities?.DiSC?.personalityId || null,
      enabled: orgSettings.enableDisc,
    }].filter((p) => p.enabled),
    settings: orgSettings,
    customPersonalities: formattedCustomPersonalities,
    strengths,
    initialStrengths,
    onStrengthFinderWhatLinkClick: () => { window.open(PERSONALITY_INFO_LINKS.StrengthFinder); },
    onStrengthFinderFindLinkClick: () => { window.open(PERSONALITY_FIND_LINKS.StrengthFinder); },
    onWorkingGeniusFindLinkClick: () => { window.open(workingGeniusUrl); },
    onWorkingGeniusWhatLinkClick: () => { window.open('https://www.workinggenius.com/about'); },
  };

  hookProps.showPersonalityTypeHeader = useMemo(() => [
    ...hookProps.customPersonalities,
    ...hookProps.personalityTypes,
    // Strengths finder is weird, so just inject it into here to keep it cleaned.
    { enabled: orgSettings.enableStrengthFinder },
    { enabled: orgSettings.enableWorkingGenius },
  ].reduce((curr, next) => {
    if (curr) return curr;
    return next.enabled;
  }, false), [orgSettings, hookProps.customPersonalities, hookProps.personalityTypes]);

  return (
    <>
      {!isLoading && (
        <View
          {...hookProps}
          {...props}
        />
      )}
    </>
  );
};

PersonalityTypes.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
};

PersonalityTypes.defaultProps = {};

export { View };
export default PersonalityTypes;
