import { AgendaType } from '@leadr-hr/types';
import { Types } from 'ably';

export enum MeetingRealTimeMessagePrimaryType {
  AgendaTopic = 'AGENDA_TOPIC',
  AgendaComment = 'AGENDA_TOPIC_COMMENT',
  AgendaSection = 'AGENDA_SECTION',
}

export const COMMENT_MESSAGE_VERSION = ':V2';

export enum AgendaRealTimeMessageType {
  Reorder = 'AGENDA:REORDERING',
  ReorderSuccess = 'AGENDA:REORDERED:V2',
  ReorderCanceled = 'AGENDA:CANCELED_REORDER',
  BulkCreated = 'AGENDA:BULK_CREATED',
}

export enum AgendaTopicRealTimeMessageType {
  Create = 'AGENDA_TOPIC:CREATING:V2',
  CreateSuccess = 'AGENDA_TOPIC:CREATED:V2',
  CreateCanceled = 'AGENDA_TOPIC:CANCELED_CREATE:V2',
  Update = 'AGENDA_TOPIC:UPDATING:V2',
  UpdateSuccess = 'AGENDA_TOPIC:UPDATED:V2',
  UpdateCanceled = 'AGENDA_TOPIC:CANCELED_UPDATE:V2',
  UpdateStatusSuccess = 'AGENDA_TOPIC:STATUS_UPDATED:V2',
  Deleted = 'AGENDA_TOPIC:DELETED:V2',
}

export enum AgendaSectionRealTimeMessageType {
  Create = 'AGENDA_SECTION:CREATING',
  CreateSuccess = 'AGENDA_SECTION:CREATED',
  CreateCanceled = 'AGENDA_SECTION:CANCELED_CREATE',
  Update = 'AGENDA_SECTION:UPDATING',
  UpdateSuccess = 'AGENDA_SECTION:UPDATED',
  UpdateCanceled = 'AGENDA_SECTION:CANCELED_UPDATE',
  UpdateStatusSuccess = 'AGENDA_SECTION:STATUS_UPDATED',
  Deleted = 'AGENDA_SECTION:DELETED',
}

export enum AgendaCommentRealTimeMessageType {
  Create = 'AGENDA_TOPIC_COMMENT:CREATING:V2',
  CreateSuccess = 'AGENDA_TOPIC_COMMENT:CREATED:V2',
  CreateCanceled = 'AGENDA_TOPIC_COMMENT:CANCELED_CREATE:V2',
  Update = 'AGENDA_TOPIC_COMMENT:UPDATING:V2',
  UpdateSuccess = 'AGENDA_TOPIC_COMMENT:UPDATED:V2',
  UpdateCanceled = 'AGENDA_TOPIC_COMMENT:CANCELED_UPDATE:V2',
  Deleted = 'AGENDA_TOPIC_COMMENT:DELETED:V2',
}

export interface BaseUpdatePayload {
  id: string,
  eventId: string,
  updater: {
    orgUserId: string,
    firstName: string,
    lastName: string,
    profileImageUrl: string,
  }
}

export interface BaseUpdateSuccessPayload {
  id: string,
  eventId: string,
  text: string,
  creator: {
    orgUserId: string,
    firstName: string,
    lastName: string,
    profileImageUrl: string,
  },
  createdInMillis: number,
  lastModifiedInMillis: number,
}

export interface BaseCreatePayload {
  type: AgendaType,
  eventId: string,
  creator: {
    orgUserId: string,
    firstName: string,
    lastName: string,
    profileImageUrl: string,
  },
}

export interface BaseCreateSuccessPayload {
  id: string,
  eventId: string,
  text: string,
  creator: {
    orgUserId: string,
    firstName: string,
    lastName: string,
    profileImageUrl: string,
  },
  createdInMillis: number,
}

export interface BaseCancelPayload {
  eventId: string,
  parentIds: string[],
}

export interface BaseDeletedPayload {
  id: string,
}

export interface AgendaTopicCreatePayload extends BaseCreatePayload {
  type: AgendaType.AgendaTopic,
  parentIds: string[],
}
export type AgendaTopicCreateCanceledPayload = BaseCancelPayload;
export interface AgendaTopicCreateSuccessPayload extends BaseCreateSuccessPayload {
  isRecurring: boolean,
  isPrivate: boolean,
  parentIds: string[],
}

export type AgendaTopicUpdatePayload = BaseUpdatePayload;
export type AgendaTopicUpdateCanceledPayload = BaseUpdateSuccessPayload;
export interface AgendaTopicUpdateStatusSuccessPayload extends BaseUpdateSuccessPayload {
  isComplete: boolean,
}
export interface AgendaTopicUpdateSuccessPayload extends BaseUpdateSuccessPayload {
  oldId?: string,
  isPrivate: boolean,
  isRecurring: boolean,
  parentIds: string,

  // Optional, only present if isPrivate changed
  isBookmarked?: boolean,
  isComplete?: boolean,
  children?: string[],
}

export type AgendaReorderPayload = BaseUpdatePayload;
export type AgendaReorderCanceledPayload = BaseUpdateSuccessPayload;
export interface AgendaReorderSuccessPayload {
  id: string,
  eventId: string,
  creator: {
    orgUserId: string,
    firstName: string,
    lastName: string,
    profileImageUrl: string,
  },
  parentIds: string[],
}

export type AgendaTopicDeletedPayload = BaseDeletedPayload;

export interface AgendaSectionCreatePayload extends BaseCreatePayload {
  type: AgendaType.AgendaSection,
}

export type AgendaSectionCreateCanceledPayload = BaseCancelPayload;
export interface AgendaSectionCreateSuccessPayload extends BaseCreateSuccessPayload {
  text: string,
  createdInMillis: number
  isRecurring: boolean,
}

export type AgendaSectionUpdatePayload = BaseUpdatePayload
export type AgendaSectionUpdateCanceledPayload = BaseCancelPayload;
export interface AgendaSectionUpdateSuccessPayload extends BaseUpdateSuccessPayload {
  oldId?: string,
  isRecurring: boolean,
}

export type AgendaSectionDeletedPayload = BaseDeletedPayload;

export interface AgendaCommentCreatePayload extends BaseCreatePayload {
  type: AgendaType.Comment,
  parentIds: string[]
}

export type AgendaCommentCreateCanceledPayload = BaseCancelPayload;
export interface AgendaCommentCreateSuccessPayload extends BaseCreateSuccessPayload {
  isPrivate: boolean,
  parentIds: string[],
}

export interface AgendaCommentUpdatePayload extends BaseUpdatePayload { parentIds: string[] }
export interface AgendaCommentUpdateCanceledPayload extends BaseCancelPayload { id: string, parentIds: string[] }
export interface AgendaCommentUpdateSuccessPayload extends BaseUpdateSuccessPayload {
  isPrivate: boolean,
  parentIds: string[],
}

export type AgendaCommentDeletedPayload = BaseDeletedPayload;

export type AgendaCreatePayload = AgendaTopicCreatePayload | AgendaSectionCreatePayload | AgendaSectionCreatePayload;
export type AgendaUpdatePayload = AgendaTopicUpdatePayload | AgendaSectionUpdatePayload | AgendaSectionUpdatePayload;

export type RealTimeMeetingMessagePayloadMap = {
  [AgendaRealTimeMessageType.Reorder]: AgendaReorderPayload,
  [AgendaRealTimeMessageType.ReorderSuccess]: AgendaReorderSuccessPayload,
  [AgendaRealTimeMessageType.ReorderCanceled]: AgendaReorderCanceledPayload,

  [AgendaTopicRealTimeMessageType.Create]: AgendaTopicCreatePayload,
  [AgendaTopicRealTimeMessageType.CreateSuccess]: AgendaTopicCreateSuccessPayload,
  [AgendaTopicRealTimeMessageType.CreateCanceled]: AgendaTopicCreateCanceledPayload,
  [AgendaTopicRealTimeMessageType.Update]: AgendaTopicUpdatePayload,
  [AgendaTopicRealTimeMessageType.UpdateSuccess]: AgendaTopicUpdateSuccessPayload,
  [AgendaTopicRealTimeMessageType.UpdateStatusSuccess]: AgendaTopicUpdateStatusSuccessPayload,
  [AgendaTopicRealTimeMessageType.UpdateCanceled]: AgendaTopicUpdateCanceledPayload,
  [AgendaTopicRealTimeMessageType.Deleted]: AgendaTopicDeletedPayload,

  [AgendaSectionRealTimeMessageType.Create]: AgendaSectionCreatePayload,
  [AgendaSectionRealTimeMessageType.CreateSuccess]: AgendaSectionCreateSuccessPayload,
  [AgendaSectionRealTimeMessageType.CreateCanceled]: AgendaSectionCreateCanceledPayload,
  [AgendaSectionRealTimeMessageType.Update]: AgendaSectionUpdatePayload,
  [AgendaSectionRealTimeMessageType.UpdateSuccess]: AgendaSectionUpdateSuccessPayload,
  [AgendaSectionRealTimeMessageType.UpdateCanceled]: AgendaSectionUpdateCanceledPayload,
  [AgendaSectionRealTimeMessageType.Deleted]: AgendaSectionDeletedPayload,

  [AgendaCommentRealTimeMessageType.Create]: AgendaCommentCreatePayload,
  [AgendaCommentRealTimeMessageType.CreateSuccess]: AgendaCommentCreateSuccessPayload,
  [AgendaCommentRealTimeMessageType.CreateCanceled]: AgendaCommentCreateCanceledPayload,
  [AgendaCommentRealTimeMessageType.Update]: AgendaCommentUpdatePayload,
  [AgendaCommentRealTimeMessageType.UpdateSuccess]: AgendaCommentUpdateSuccessPayload,
  [AgendaCommentRealTimeMessageType.UpdateCanceled]: AgendaCommentUpdateCanceledPayload,
  [AgendaCommentRealTimeMessageType.Deleted]: AgendaCommentDeletedPayload,
};

export interface AgendaReorderMessage extends Types.Message {
  name: AgendaRealTimeMessageType.Reorder,
  data: AgendaReorderPayload,
}
export interface AgendaReorderSuccessMessage extends Types.Message {
  name: AgendaRealTimeMessageType.ReorderSuccess,
  data: AgendaReorderSuccessPayload,
}
export interface AgendaReorderCanceledMessage extends Types.Message {
  name: AgendaRealTimeMessageType.ReorderCanceled,
  data: AgendaReorderCanceledPayload,
}

export interface AgendaBulkCreateMessage extends Types.Message {
  name: AgendaRealTimeMessageType.BulkCreated,
  data: unknown,
}

export interface AgendaTopicCreateMessage extends Types.Message {
  name: AgendaTopicRealTimeMessageType.Create,
  data: AgendaTopicCreatePayload
}
export interface AgendaTopicCreateSuccessMessage extends Types.Message {
  name: AgendaTopicRealTimeMessageType.CreateSuccess,
  data: AgendaTopicCreateSuccessPayload,
}
export interface AgendaTopicCreateCanceledMessage extends Types.Message {
  name: AgendaTopicRealTimeMessageType.CreateCanceled,
  data: AgendaTopicCreateCanceledPayload,
}
export interface AgendaTopicUpdateMessage extends Types.Message {
  name: AgendaTopicRealTimeMessageType.Update,
  data: AgendaTopicUpdatePayload,
}
export interface AgendaTopicUpdateSuccessMessage extends Types.Message {
  name: AgendaTopicRealTimeMessageType.UpdateSuccess,
  data: AgendaTopicUpdateSuccessPayload,
}
export interface AgendaTopicUpdateStatusSuccessMessage extends Types.Message {
  name: AgendaTopicRealTimeMessageType.UpdateStatusSuccess,
  data: AgendaTopicUpdateStatusSuccessPayload,
}
export interface AgendaTopicUpdateCanceledMessage extends Types.Message {
  name: AgendaTopicRealTimeMessageType.UpdateCanceled,
  data: AgendaTopicUpdateCanceledPayload,
}

export interface AgendaTopicDeletedMessage extends Types.Message {
  name: AgendaTopicRealTimeMessageType.Deleted,
  data: AgendaTopicDeletedPayload,
}

export interface AgendaSectionCreateMessage extends Types.Message {
  name: AgendaSectionRealTimeMessageType.Create,
  data: AgendaSectionCreatePayload
}
export interface AgendaSectionCreateSuccessMessage extends Types.Message {
  name: AgendaSectionRealTimeMessageType.CreateSuccess,
  data: AgendaSectionCreateSuccessPayload,
}
export interface AgendaSectionCreateCanceledMessage extends Types.Message {
  name: AgendaSectionRealTimeMessageType.CreateCanceled,
  data: AgendaSectionCreateCanceledPayload,
}
export interface AgendaSectionUpdateMessage extends Types.Message {
  name: AgendaSectionRealTimeMessageType.Update,
  data: AgendaSectionUpdatePayload,
}
export interface AgendaSectionUpdateSuccessMessage extends Types.Message {
  name: AgendaSectionRealTimeMessageType.UpdateSuccess,
  data: AgendaSectionUpdateSuccessPayload,
}

export interface AgendaSectionUpdateCanceledMessage extends Types.Message {
  name: AgendaSectionRealTimeMessageType.UpdateCanceled,
  data: AgendaSectionUpdateCanceledPayload,
}

export interface AgendaSectionDeletedMessage extends Types.Message {
  name: AgendaSectionRealTimeMessageType.Deleted,
  data: AgendaSectionDeletedPayload,
}

export interface AgendaCommentCreateMessage extends Types.Message {
  name: AgendaCommentRealTimeMessageType.Create,
  data: AgendaCommentCreatePayload,
}
export interface AgendaCommentCreateSuccessMessage extends Types.Message {
  name: AgendaCommentRealTimeMessageType.CreateSuccess,
  data: AgendaCommentCreateSuccessPayload,
}
export interface AgendaCommentCreateCanceledMessage extends Types.Message {
  name: AgendaCommentRealTimeMessageType.CreateCanceled,
  data: AgendaCommentCreateCanceledPayload,
}
export interface AgendaCommentUpdateMessage extends Types.Message {
  name: AgendaCommentRealTimeMessageType.Update,
  data: AgendaCommentUpdatePayload,
}
export interface AgendaCommentUpdateSuccessMessage extends Types.Message {
  name: AgendaCommentRealTimeMessageType.UpdateSuccess,
  data: AgendaCommentUpdateSuccessPayload,
}
export interface AgendaCommentUpdateCanceledMessage extends Types.Message {
  name: AgendaCommentRealTimeMessageType.UpdateCanceled,
  data: AgendaCommentUpdateCanceledPayload,
}
export interface AgendaCommentDeletedMessage extends Types.Message {
  name: AgendaCommentRealTimeMessageType.Deleted,
  data: AgendaCommentDeletedPayload,
}

export type AgendaGenericMessage =
| AgendaReorderMessage
| AgendaReorderSuccessMessage
| AgendaReorderCanceledMessage
| AgendaBulkCreateMessage;

export type AgendaTopicMessage =
| AgendaTopicCreateMessage
| AgendaTopicCreateSuccessMessage
| AgendaTopicCreateCanceledMessage
| AgendaTopicUpdateMessage
| AgendaTopicUpdateSuccessMessage
| AgendaTopicUpdateStatusSuccessMessage
| AgendaTopicUpdateCanceledMessage
| AgendaTopicDeletedMessage;

export type AgendaSectionMessage =
| AgendaSectionCreateMessage
| AgendaSectionCreateSuccessMessage
| AgendaSectionCreateCanceledMessage
| AgendaSectionUpdateMessage
| AgendaSectionUpdateSuccessMessage
| AgendaSectionUpdateCanceledMessage
| AgendaSectionDeletedMessage;

export type AgendaCommentMessage =
| AgendaCommentCreateMessage
| AgendaCommentCreateSuccessMessage
| AgendaCommentCreateCanceledMessage
| AgendaCommentUpdateMessage
| AgendaCommentUpdateSuccessMessage
| AgendaCommentUpdateCanceledMessage
| AgendaCommentDeletedMessage;
