import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import LoadingSpinner from '~Deprecated/components/LoadingSpinner';
import AttendeeItem from '~Meetings/components/attendees/AttendeeItem';
import { UNIQUE_ID } from '~Common/const/proptypes';

const styles = {
  userListContainer: css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin-block-start: 1.5rem;
  `,
};

const userListContainerStyles = styles.userListContainer;

const AttendeeList = ({
  attendees, isLoading, isAttendeeSelected, onClick, disableUsers, ...props
}) => (
  <div css={styles.userListContainer}>
    {isLoading && !attendees.length && (
      <LoadingSpinner />
    )}
    {attendees.map((aId) => (
      <AttendeeItem
        id={aId}
        key={aId}
        isAttendeeSelected={isAttendeeSelected}
        onClick={onClick}
        isDisabled={disableUsers.find((user) => user.user === aId) !== undefined}
        tooltip={disableUsers.find((user) => user.user === aId)?.tooltip}
        {...props}
      />
    ))}
  </div>
);

AttendeeList.propTypes = {
  attendees: PropTypes.arrayOf(UNIQUE_ID),
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  isAttendeeSelected: PropTypes.func.isRequired,
  disableUsers: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ),
};

AttendeeList.defaultProps = {
  attendees: [],
  onClick: null,
  disableUsers: [],
};

export default AttendeeList;

export { userListContainerStyles };
