import { css } from '@emotion/react';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import { REVIEW_DISPLAY_STYLES } from '../Const/pageStyles';
import { ParticipantTypeEnum } from '../Const/types';

const styles = {
  ...REVIEW_DISPLAY_STYLES,
  topPadding: css({
    paddingTop: '0.75rem',
  }),
};

interface UserAssignmentDisplayProps {
  participationEnum: ParticipantTypeEnum
}

export const UserAssignmentDisplay = ({
  participationEnum,
}: UserAssignmentDisplayProps): JSX.Element => (
  <div css={[styles.assignmentDisplay, styles.topPadding]}>
    <FontAwesomeIcon icon={faUser} />
    {FlaggedEnum(participationEnum).hasFlag(ParticipantTypeEnum.Reviewer) ? (
      <span>Reviewer</span>
    ) : null}
    {FlaggedEnum(participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer) ? (
      <span>Secondary Reviewer</span>
    ) : null}
    {FlaggedEnum(participationEnum).hasFlag(ParticipantTypeEnum.Reviewee) ? (
      <span>Reviewee</span>
    ) : null}
  </div>
);
