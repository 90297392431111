import { createShowModalStore } from '~Common/stores/ShowThingStore/createShowModalStore';
import { Attachment } from '../v2/useGetAgendas';

interface ModalProps {
  addAttachment: (attachment: Attachment) => void,
  attachments: Attachment[],
  huddleId: string,
  removeAttachment?: (attachment: Attachment) => void,
  topicId?: string,
}

export const useShowAttachmentsModal = createShowModalStore<ModalProps>('attachments');
