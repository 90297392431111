import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { _iframelyApiKey } from '~Deprecated/components/Player/config';
import { getOrganizationId } from '~Common/utils/localStorage';
import { IframelyContent } from '~Learning/const/interfaces';
import { buildQueryString } from '~Common/utils';

const getIframelyContent = async ({ queryKey }: QueryFunctionContext<string[]>): Promise<IframelyContent> => {
  const [,,, url] = queryKey;
  const queryParams = {
    url,
    api_key: _iframelyApiKey,
    omit_script: 1,
    click_to_play: true,
    omit_css: 1,
  };
  const queryData = buildQueryString(queryParams);

  const serverUrl = `https://iframe.ly/api/iframely${queryData}`;
  const response = await fetch(serverUrl);
  return response.json() as Promise<IframelyContent>;
};

interface IframelyContentHookParams {
  url: string,
}

interface IframelyContentHookReturn {
  data: IframelyContent | undefined,
  isLoading: boolean,
}

export const useGetIframelyContent = ({ url }: IframelyContentHookParams): IframelyContentHookReturn => {
  const result = useQuery({
    queryKey: [getOrganizationId() ?? '', 'learning', 'content', url],
    queryFn: getIframelyContent,
    enabled: !!url,
  });

  return {
    data: result.data,
    isLoading: result.isLoading,
  };
};

export const getIframelyContentWithoutReactQuery = async (url: string): Promise<IframelyContent> => {
  const queryParams = {
    url,
    api_key: _iframelyApiKey,
    omit_script: 1,
    click_to_play: true,
    omit_css: 1,
  };
  const queryData = buildQueryString(queryParams);
  const serverUrl = `https://iframe.ly/api/iframely${queryData}`;
  const response = await fetch(serverUrl);

  const myPromise = new Promise((resolve, reject) => {
    response.json().then((data) => {
      resolve(data);
    }).catch((error) => {
      reject(error);
    });
  });

  return myPromise as Promise<IframelyContent>;
};
