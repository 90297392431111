import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { getOrganizationId } from '~Common/utils/localStorage';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { toast } from '~Common/components/Toasts';
import { deleteApi } from '~Deprecated/services/HttpService';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';

const deleteTemplateMutation = ({ template }) => {
  const url = {
    host: hosts.library,
    uri: `/organizations/${getOrganizationId()}/templates/feedbacks/${template.id}`,
  };

  return deleteApi(url, {}, {});
};

export const useDeleteFeedbackTemplate = ({ queryKey = [getOrganizationId(), 'feedback', 'templates'], drawerTemplate }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: deleteTemplateMutation,
    onMutate: ({ template }) => {
      toastId.current = toast.info('Deleting the feeback template...', { autoClose: false });

      dispatch(
        popDrawerAction({ drawerName: drawerTemplate.name }),
      );
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an deleting the template. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (drawerTemplate) {
        dispatch(pushDrawerAction({ drawer: { ...drawerTemplate, args: variables } }));
      }
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Succesfully deleted the feedback template.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutate;
};
