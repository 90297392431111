import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ActionItem } from '~ActionItems/const/interfaces';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';

interface GetActionItemsParams {
  id: string,
}

const getActionItemDetails = async ({
  id,
}: GetActionItemsParams): Promise<HttpCallReturn<ActionItem>> => {
  const url = {
    host: getHost(hosts.action, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/action-items/${id}`,
  };

  return getApi<ActionItem>(url);
};

interface UseActionItemDetailsProps {
  id?: string,
}

export const useActionItemDetails = ({
  id,
}: UseActionItemDetailsProps): UseQueryResult<HttpCallReturn<ActionItem>> => (
  useQuery({
    queryKey: [getOrganizationId(), 'action-items', id],
    queryFn: () => getActionItemDetails({
      id: id ?? '',
    }),
    enabled: !!id,
  })
);
