import { css } from '@emotion/react';
import moment from 'moment';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { RecognitionUserShape } from '~Recognition/const/types';
import { palette } from '~Common/styles/colors';
import { OPTIMISTIC_ID } from '~Recognition/const/defaults';
import { ComponentProps } from 'react';
import RecognitionMessaging from '../RecognitionMessaging';

const styles = {
  body: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }),
  infoContainer: (isMobile: boolean) => css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: palette.neutrals.gray700,
    fontSize: '.875rem',
    flexWrap: 'wrap',
    alignItems: 'center',
  }, isMobile && {
    flexDirection: 'column',
    '& > div': {
      width: '100% !important',
      maxWidth: '100% !important',
    },
    '& > div:first-of-type': {
      order: 2,
    },
    '& > div:last-of-type': {
      order: 1,
      justifyContent: 'flex-end',
    },
  }),
  dateWrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  // Keeping this for phase 2 logic
  blueDot: css({
    width: '.875rem',
    height: '.875rem',
    backgroundColor: palette.brand.indigo,
    display: 'inline-block',
    borderRadius: '.625rem',
    marginRight: '.625rem',
  }),
  bannerContainer: css({
    borderRadius: '.5rem',
    width: '100%',
    padding: '1.25rem',
    color: palette.neutrals.white,
    fontSize: '1.5rem',
    fontWeight: 600,
    margin: '1.125rem 0',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  }),
  avatar: css({
    margin: '.3125rem',
    display: 'inline-block',
  }),
  dateEditArea: (cantSeeEdit: boolean) => css({
    display: 'flex',
    maxWidth: '13.375rem',
  }, cantSeeEdit && {
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  dateDisplay: () => JSX.Element,
  recipients: RecognitionUserShape[],
  totalRecipients: number,
  loggedInUserRecognized: boolean,
  createdBy: RecognitionUserShape,
  renderEdit?: () => JSX.Element,
  canSeeEdit: boolean,
  isMobile: boolean,
}

const View = ({
  dateDisplay,
  recipients,
  totalRecipients,
  loggedInUserRecognized,
  createdBy,
  renderEdit,
  canSeeEdit,
  isMobile,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.body} {...props}>
    <div css={styles.infoContainer(isMobile)}>
      <RecognitionMessaging
        totalRecipients={totalRecipients}
        loggedInUserRecognized={loggedInUserRecognized}
        recipients={recipients}
        createdBy={createdBy}
      />
      <div css={styles.dateEditArea(canSeeEdit)}>
        {dateDisplay()}
        {renderEdit && (
        <div
          data-test-id="edit"
        >
          {renderEdit?.()}
        </div>
        )}
      </div>
    </div>
  </div>
);

interface CardHeaderProps {
  recipients: RecognitionUserShape[],
  date: string,
  createdBy: RecognitionUserShape,
  renderEdit?: () => JSX.Element,
  canSeeEdit?: boolean,
  recognitionId: number,
  isMobile?: boolean,
}

const CardHeader = ({
  recipients,
  date,
  createdBy,
  renderEdit,
  canSeeEdit = false,
  recognitionId,
  isMobile = false,
  ...props
}: CardHeaderProps): JSX.Element => {
  const totalRecipients = recipients.length;
  const loggedInUserRecognized = recipients.some((recipient) => recipient.orgUserId === getOrganizationUserId());

  const dateDisplay = (): JSX.Element => {
    if (recognitionId === OPTIMISTIC_ID && date === OPTIMISTIC_ID.toLocaleString()) {
      return <div css={styles.dateWrapper}>Just Modified</div>;
    }
    const now = moment.now();
    const momentRecognition = moment(date);
    const isToday = momentRecognition.isSame(now, 'day');
    let momentString;
    if (isToday) {
      momentString = moment(date).format('LT');
    } else {
      momentString = moment(date).format('MMM Do YYYY');
    }
    if (isToday) {
      momentString = (
        <div css={styles.dateWrapper}>
          {`Today at ${momentString}`}
        </div>
      );
    } else {
      momentString = <div css={styles.dateWrapper}>{momentString}</div>;
    }
    return momentString;
  };

  const hookProps = {
    dateDisplay,
    recipients,
    totalRecipients,
    loggedInUserRecognized,
    createdBy,
    renderEdit,
    canSeeEdit,
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default CardHeader;
