import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  title: css({
    color: palette.brand.indigo,
    fontSize: '1.125rem',
    fontStyle: 'normal',
    fontWeight: '600',
  }),
};

interface InsightCardTitleProps {
  children: ReactNode,
}

const InsightCardTitle = ({ children }: InsightCardTitleProps): JSX.Element => (
  <h2 css={styles.title}>{children}</h2>
);

export default InsightCardTitle;
