import { css } from '@emotion/react';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

import { palette } from '~Common/styles/colors';
import { useShowGlobalSearchModal } from '~GlobalSearch/stores/useShowGlobalSearchModal';

const styles = {
  customSearchInputButton: css({
    border: `1px solid ${palette.neutrals.gray300}`,
    borderRadius: '0.5rem',
    padding: '.1875rem .875rem',
    whiteSpace: 'nowrap',
  }),
};

interface ViewProps {
  handleButtonClick: () => void,
}

const View = ({
  handleButtonClick,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrButton
    css={styles.customSearchInputButton}
    data-test-id="searchMeetings"
    variant="text"
    textButtonColor={palette.neutrals.gray500}
    onClick={handleButtonClick}
    {...props}
  >
    <LeadrButton.IconAndText icon={faSearch} text="Search Meetings..." />
  </LeadrButton>
);

type CustomSearchInputButtonProps = Omit<ViewProps, 'handleButtonClick'>;

const CustomSearchInputButton = ({ ...props }: CustomSearchInputButtonProps): JSX.Element => {
  const {
    setShowModal,
  } = useShowGlobalSearchModal((state) => ({
    setShowModal: state.setShowModal,
  }));

  const handleButtonClick = (): void => {
    setShowModal(true);
  };

  const hookProps = {
    handleButtonClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CustomSearchInputButton;
