import { css } from '@emotion/react';
import { ReactNode, ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  modalTitle: css({
    fontSize: '1.25rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
  }),
};

interface ModalTitleProps extends ComponentProps<'div'> {
  children: ReactNode,
}

const ModalTitle = ({
  children,
  ...props
}: ModalTitleProps): JSX.Element => (
  <h2
    css={styles.modalTitle}
    {...props}
  >
    {children}
  </h2>
);

export default ModalTitle;
