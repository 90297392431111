/* eslint-disable no-bitwise */
import {
  boolean,
  number,
  object,
  string,
  array,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  linearTopicConfig, multiChoiceTopicConfig, TopicTypeEnum, ParticipantTypeEnum,
} from '~Reviews/V2/Const/types';

const updateTopicFormSchema = object({
  text: string().trim().required(),
  description: string().trim(),
  topicIsForReviewees: boolean(),
  topicIsForReviewers: boolean(),
  linearConfig: object({ minLabel: string(), maxLabel: string(), numScale: number() }),
  multiChoiceConfig: array(object({ text: string().defined() })),
  typeEnum: number().transform((value) => Number(value)).required(),
  rank: number(),
});

export const createTopicFormResolver = yupResolver(updateTopicFormSchema);

export type FormValues = InferType<typeof updateTopicFormSchema>;

export interface UpdateTopicDTO {
  text: string,
  description?: string,
  typeEnum: number,
  typeConfig: linearTopicConfig | multiChoiceTopicConfig | string | Record<string, unknown>,
  topicTargetEnum: ParticipantTypeEnum,
  rank?: number | undefined,
}

export function conformToDto(data: FormValues): UpdateTopicDTO {
  let topicTargetEnum = 0;
  if (data.topicIsForReviewers) {
    topicTargetEnum |= ParticipantTypeEnum.Reviewer;
  }
  if (data.topicIsForReviewees) {
    topicTargetEnum |= ParticipantTypeEnum.Reviewee;
  }

  const result: UpdateTopicDTO = {
    text: data.text,
    description: data.description,
    typeEnum: data.typeEnum ?? TopicTypeEnum.Freeform,
    topicTargetEnum,
    typeConfig: '',
    rank: data.rank,
  };
  const trimmedMultiChoiceConfigOptions = data.multiChoiceConfig?.filter((item) => item.text.trim() !== '');

  if (data.typeEnum === TopicTypeEnum.MultipleChoice) {
    result.typeConfig = JSON.stringify({ multipleChoiceOptions: trimmedMultiChoiceConfigOptions });
  } else if (data.typeEnum === TopicTypeEnum.Linear) {
    result.typeConfig = JSON.stringify(data.linearConfig);
  }

  return result;
}
