import { css } from '@emotion/react';

const styles = {
  boldedText: css({
    fontWeight: 600,
  }),
};

interface GoalsTextProps {
  isReviewee: boolean,
  revieweeFullName: string,
}

const GoalsText = ({
  isReviewee,
  revieweeFullName,
  ...props
}: GoalsTextProps): JSX.Element => (
  <span {...props}>
    {'Goals '}
    {isReviewee && (
      <span>{'you own or have collaborated on '}</span>
    )}
    {!isReviewee && (
      <span>
        owned by
        <span css={styles.boldedText}>{` ${revieweeFullName} `}</span>
      </span>
    )}
    {'that fall '}
  </span>
);

export default GoalsText;
