import { SyntheticEvent } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { RenderConfirmationButtonsParams } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import { palette } from '~Common/styles/colors';

interface RecallConfirmationButtonsProps extends RenderConfirmationButtonsParams {
  onCloseDrawer: (event: SyntheticEvent<HTMLButtonElement>) => void,
}

const DrawerCloseConfirmationButtons = ({
  informationStyles,
  optionStyles,
  popoverButtonStyles,
  onCloseDrawer,
}: RecallConfirmationButtonsProps): JSX.Element => (
  <>
    <LeadrButton
      variant="text"
      data-test-id="learningPlayistConfirmCloseText"
      css={[popoverButtonStyles, informationStyles]}
      disabled
      textButtonColor={palette.neutrals.white}
    >
      Leave Page? Your Work Won&apos;t Be Saved
    </LeadrButton>
    <LeadrButton
      variant="text"
      css={[popoverButtonStyles, optionStyles]}
      onClick={onCloseDrawer}
      data-test-id="learningPlaylistConfirmClose"
      textButtonColor={palette.neutrals.white}
    >
      Yes, Leave
    </LeadrButton>
    <LeadrButton
      variant="text"
      css={[popoverButtonStyles, optionStyles]}
      data-test-id="learningPlaylistCancelClose"
      textButtonColor={palette.neutrals.white}
    >
      No, Cancel
    </LeadrButton>
  </>
);

export default DrawerCloseConfirmationButtons;
