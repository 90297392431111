import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

const addToMeeting = ({ meeting, questions }) => {
  const serverUrl = {
    host: hosts.coaching,
    uri: `/organizations/${getOrganizationId()}/huddles/assets`,
  };

  return patchApi(serverUrl, {
    huddleId: meeting.id,
    assetIds: questions.map((q) => q.uid),
  });
};

export const useAddToNextMeeting = ({ queryKey = [getOrganizationId(), getOrganizationUserId()], template }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: addToMeeting,
    onMutate: () => {
      toastId.current = toast.info('Adding feedback to your coaching...', { autoClose: false });

      if (template) {
        dispatch(popDrawerAction({ drawerName: template.name }));
      }
    },
    onError: (error, variables) => {
      toast.update(toastId.current, {
        render: 'There was an error adding the feedback to your coaching. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (template) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...template,
              args: {
                ...variables,
              },
            },
          }));
        });
      }
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Succesfully added the feedback to your coaching.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
    },
  });

  return mutation.mutate;
};
