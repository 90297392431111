interface PeoplePageManager {
  firstName: string,
  lastName: string,
  orgUserId: string,
}

export interface PeoplePageReport {
  coaching: number,
  goal: number,
  feedback: number,
  activity: number,
}

export interface PeoplePageScoreSummary {
  good: number,
  grey: number,
  improve: number,
  ok: number,
}

export interface PeoplePageSummaryReport {
  activity: PeoplePageScoreSummary,
  coaching: PeoplePageScoreSummary,
  feedback: PeoplePageScoreSummary,
  goal: PeoplePageScoreSummary,
}

export interface PeoplePagePerson {
  firstName: string,
  lastName: string,
  userId: string,
  orgUserId: string,
  manager: PeoplePageManager,
  mentors?: string[],
  profileImageUrl?: string,
  jobTitle?: string,
  department: string,
  countDirects: number,
  report?: PeoplePageReport,
  summaryReport?: PeoplePageSummaryReport;
  workingGeniuses: string[],
}

export enum PeoplePageFilterType {
  Department = 'department',
  UserGroup = 'teamId',
  Role = 'userGroupId',
  WorkingGenius = 'workingGenius',
}

export enum InsightType {
  Department = 'DEPARTMENT',
  UserGroup = 'TEAM',
  Role = 'USER_GROUP',
  WorkingGenius = 'WORKING_GENIUS',
}

// Org Chart
export interface OrgChartUser {
  department?: string;
  directsCount: number;
  firstName: string;
  jobTitle: string;
  lastName: string;
  profileImageUrl?: string;
  userId: string;
}

export interface OrgChart {
  current: OrgChartUser;
  directs: OrgChartUser[];
  manager: OrgChartUser | null;
}
