import PropTypes from 'prop-types';

import ErrorHandler from '../../../components/ErrorHandler';

const GetContent = ({
  ChildComponent, LeftComponent, TopComponent, page, className, wrapperClassName, hasModal, routeProps,
}) => (
  <div className={`coaching-content-wrapper ${wrapperClassName || ''}`}>
    { TopComponent && (
    <div className="page-top-bar">
      <TopComponent />
    </div>
    ) }
    <div className="coaching-details-wrapper page-wrapper">
      { LeftComponent && (
      <div className="title-col">
        <div className={`left-col ${hasModal && 'has-modal'}`}>
          <LeftComponent page={page} />
        </div>
      </div>
      ) }
      <div className={`middle-col ${className || ''}`}>
        <ChildComponent page={page} routeProps={routeProps} />
      </div>
      <ErrorHandler />
    </div>
  </div>
);

GetContent.propTypes = {
  wrapperClassName: PropTypes.string,
  hasModal: PropTypes.bool,
  /* eslint-disable */
  ChildComponent: PropTypes.any,
  LeftComponent: PropTypes.any,
  TopComponent: PropTypes.any,
  routeProps: PropTypes.object,
  /* eslint-enable */
  page: PropTypes.string,
  className: PropTypes.string,
};

GetContent.defaultProps = {
  className: '',
  wrapperClassName: '',
  hasModal: false,
  page: '',
};

export default GetContent;
