import { css } from '@emotion/react';
import Froala from '~Common/V3/components/Froala';

const styles = {
  questionInput: css({
    width: '100%',
  }),
};

interface QuestionInputProps {
  initialValue: string,
  onChange: (value: string) => void,
  renderButtons: () => JSX.Element,
}

const QuestionInput = ({
  initialValue,
  onChange,
  renderButtons,
}: QuestionInputProps): JSX.Element => (
  <div css={styles.questionInput}>
    <Froala
      name="question"
      label="Question"
      richTextEditorProps={{
        initialValue,
        onChange: ({ value }) => onChange(value),
      }}
      froalaEventsProps={{
        autoFocus: true,
      }}
      froalaConfigProps={{
        charCounterMax: 2000,
        charCounterCount: true,
      }}
    />
    {renderButtons?.()}
  </div>
);

export default QuestionInput;
