import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  status: css({
    borderRadius: '50%',
    display: 'inline-block',
    height: '0.625rem',
    marginRight: '0.625rem',
    width: '0.625rem',
  }),
  statusColor: (status: string) => {
    let background = '';
    switch (status) {
      case Statuses.Finished:
        background = palette.brand.green;
        break;
      case Statuses.Active:
        background = palette.brand.orange;
        break;
      case Statuses.AllSubmitted:
        background = palette.brand.orange;
        break;
      case Statuses.Draft:
        background = palette.brand.red;
        break;
      default:
        background = palette.neutrals.gray400;
    }
    return css`background: ${background}`;
  },
  avatar: css`
    margin-right: .5rem;
  `,
  progressTextWrap: css({
    alignItems: 'center',
    display: 'flex',
  }),
};

export enum Statuses {
  Draft = '1',
  Active = '2',
  AllSubmitted = '3',
  Finished = '4',
}

interface ViewProps {
  result: string,
  renderStatusText: () => string,
}

const View = ({
  result,
  renderStatusText,
}: ViewProps): JSX.Element => (
  <div css={styles.progressTextWrap}>
    <span css={[styles.status, styles.statusColor(result)]} />
    {renderStatusText()}
  </div>
);

interface CompletedProgressTextProps {
  status: number,
  renderStatusText: () => string,
}

const CompletedProgressText = ({
  status,
  renderStatusText,
}: CompletedProgressTextProps): JSX.Element => {
  const matchId = Object.entries(Statuses).find(([, value]) => value.toString() === status.toString());
  const match = matchId !== undefined ? matchId?.[1].toString() : 'na' as unknown as Statuses;
  // We want to override the dot color to green if the status is complete and the review is active
  // otherwise it will be orange and be confusing to the user
  const overrideDotColor = renderStatusText() === 'Complete' && match === Statuses.Active;

  const result = overrideDotColor ? Statuses.Finished : match;

  const hookProps = {
    result,
    renderStatusText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default CompletedProgressText;
