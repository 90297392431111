import { SyntheticEvent } from 'react';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { SerializedStyles } from '@emotion/react';
import { PEOPLE, PEOPLE_ADMIN, TEAMS } from '~Common/const/routes';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';

interface ViewProps {
  value: string,
  handleRouteChangeClick: (event: SyntheticEvent, route: string) => void,
  toggleTabStyles?: SerializedStyles,
  peoplePageTabs: LeadrToggleTextTabProps[],
}

const View = ({
  toggleTabStyles,
  handleRouteChangeClick,
  peoplePageTabs,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs
    onChange={handleRouteChangeClick}
    {...props}
  >
    {peoplePageTabs.map((peoplePageItemTab) => (
      <LeadrToggleTabs.TextTab
        css={toggleTabStyles}
        data-test-id={peoplePageItemTab['data-test-id']}
        key={peoplePageItemTab.value}
        text={peoplePageItemTab.text}
        value={peoplePageItemTab.value}
      />
    ))}
  </LeadrToggleTabs>
);

type PeoplePageTabNavigationProps = Omit<ViewProps, 'peoplePageTabs'>;

const PeoplePageTabNavigation = ({
  ...props
}: PeoplePageTabNavigationProps): JSX.Element => {
  const { isAdmin } = useUserPermissions();
  const peoplePageTabs = [
    {
      value: PEOPLE,
      text: 'People',
      'data-test-id': 'peoplePageNavPeople',
    },
    {
      value: TEAMS,
      text: 'Teams',
      'data-test-id': 'peoplePageNavTeams',
    },
    ...(isAdmin ? [{
      value: PEOPLE_ADMIN,
      text: 'Admin',
      'data-test-id': 'peoplePageNavAdmin',
    }] : []),
  ];

  const hookProps = {
    peoplePageTabs,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default PeoplePageTabNavigation;
