import { css } from '@emotion/react';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import EmptyReviews from '~Reviews/assets/images/emptyReviews.svg';
import { palette } from '~Common/styles/colors';
import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Link } from 'react-router-dom';
import { ADMIN_REVIEWS_V2 } from '~Common/const/routes';

const styles = {
  permissionDenied: css({}),
  deniedText: css({
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    fontWeight: 500,
    paddingBottom: '1rem',
    borderBottom: `1px solid ${palette.neutrals.gray300}`,
  }),
  emptyStateImage: css({
    height: '20rem',
  }),
};

const ReviewsPermissionDenied = ({
  ...props
}: Omit<LeadrCardProps, 'children'>): JSX.Element => (
  <LeadrCard {...props}>
    <div css={styles.deniedText}>Permission Denied</div>
    <EmptyStateWithImage
      renderImage={() => (
        <EmptyReviews
          css={styles.emptyStateImage}
          title="Permission Denied on Review"
          data-test-id="reviewsPermissionDeniedEmptyStateImage"
        />
      )}
      renderText={() => (
        <div>
          <span>You do not have permission to view this Review Cycle. </span>
          <LeadrButton
            variant="text"
            textButtonColor={palette.brand.blue}
            data-test-id="reviewsPermissionDeniedEmptyStateBack"
            component={Link}
            to={ADMIN_REVIEWS_V2}
          >
            Click here to view Review Cycles you have access to.
          </LeadrButton>
        </div>
      )}
    />
  </LeadrCard>
);

export default ReviewsPermissionDenied;
