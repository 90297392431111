import { css } from '@emotion/react';
import {
  MouseEventHandler, useState,
} from 'react';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import { stripHtml } from '~Common/utils/stripHtml';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { ReviewShape } from '~Reviews/V2/Const/types';
import { useGetRevieweePersonInformation } from '~Reviews/V2/Hooks/useGetRevieweePersonInformation';
import moment from 'moment-timezone';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';
import { Reflection, ReflectionType, ReflectionTypeEnum } from '../../types';
import { useReflectionDetailsDrawer } from '../../hooks/useReflectionDetailsDrawer';
import AddRemoveButtons from './AddRemoveButtons';

const styles = {
  reflectionCard: (isSelected: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    gap: '1.25rem',
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    border: `1px solid ${hexToRGBA(palette.neutrals.gray50, 0.6)}`,
    padding: '.75rem 1rem',
    borderRadius: '.3125rem',
    cursor: 'pointer',
    position: 'relative',
  }, isSelected && {
    border: `1px solid ${palette.brand.blue}`,
  }),
  textContainer: css({
    flex: '1',
    minWidth: 0,
    display: 'flex',
    alignItems: 'center',
  }),
  bookmarkIconContainer: css({
    marginRight: '.625rem',
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  }),
  description: css({
    color: palette.neutrals.gray500,
    fontSize: '.8125rem',
    fontWeight: 600,
    lineHeight: '1rem',
    marginTop: '.375rem',
  }),
  detailsButton: (isVisible: boolean) => css({
    visibility: isVisible ? 'unset' : 'hidden',
    fontSize: '.8125rem',
  }, forMobileObject({
    visibility: 'unset',
  })),
  reflectionWrap: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
};

interface ViewProps {
  openDetailsDrawer: MouseEventHandler,
  title: string,
  renderTopLeftItem?: () => JSX.Element,
  showDetailsLink: boolean,
  setShowDetailsLink: (show: boolean) => void,
  reflection: Reflection,
  isSelected: boolean,
  getSubText: () => string,
  reviewTopicUid: string,
  reviewUid: string,
}

export const View = ({
  openDetailsDrawer,
  title,
  renderTopLeftItem,
  showDetailsLink,
  setShowDetailsLink,
  isSelected,
  getSubText,
  reflection,
  reviewTopicUid,
  reviewUid,
}: ViewProps): JSX.Element => (
  (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <li
      key={title}
      css={styles.reflectionCard(isSelected)}
      onClick={openDetailsDrawer}
      onMouseEnter={() => setShowDetailsLink(true)}
      onMouseLeave={() => setShowDetailsLink(false)}
    >
      <div css={styles.textContainer}>
        <div
          css={styles.bookmarkIconContainer}
        >
          {renderTopLeftItem?.()}
        </div>
        <div css={styles.reflectionWrap}>
          <TooltipOnTruncate css={styles.title} text={title} />
          <p css={styles.description}>
            <span>
              {getSubText()}
            </span>
          </p>
        </div>
      </div>
      <LeadrButton
        data-test-id="viewReflection"
        variant="text"
        textButtonColor={palette.brand.indigo}
        css={styles.detailsButton(showDetailsLink)}
      >
        View
      </LeadrButton>
      <AddRemoveButtons
        isDrawerMode={false}
        passedIsSelected={isSelected}
        reflection={reflection}
        reviewTopicUid={reviewTopicUid}
        contentTitle={title}
        reviewUid={reviewUid}
      />
    </li>
  )
);

interface ReflectionCardProps extends
  Omit<ViewProps,
    'title' | 'description' | 'isMutating' | 'openDetailsDrawer' | 'handleAddReflection' | 'showDetailsLink' |
    'setShowDetailsLink' | 'handleRemoveReflection' | 'reflectionDateConverted' | 'getSubText'
  >
{
  reflection: Reflection,
  reviewTopicUid: string,
  reviewUid: string,
  review: ReviewShape,
}

export const ReflectionCard = ({
  reflection,
  reviewTopicUid,
  reviewUid,
  review,
  isSelected,
  ...props
}: ReflectionCardProps): JSX.Element => {
  const [showDetailsLink, setShowDetailsLink] = useState(false);
  const { person: reviewee } = useGetRevieweePersonInformation(review);

  const { contentTitle } = reflection;

  const { openDrawer: openReflectionDetailsDrawer } = useReflectionDetailsDrawer();

  // Let's make sure there's not any HTML in the title.
  const { contentTitle: dirtyTitle } = reflection;
  const title = stripHtml(dirtyTitle);

  const openDetailsDrawer: MouseEventHandler = () => {
    const { contentTypeId, contentId } = reflection;

    openReflectionDetailsDrawer({
      reflectionTypeEnum: contentTypeId as unknown as ReflectionTypeEnum,
      reviewUid,
      subjectUid: contentId,
      revieweeId: reviewee?.userId ?? '',
      revieweeOrgID: reviewee?.orgUserId ?? '',
      reflectionId: reflection.contentId,
      renderBottomButton: (bottomButtonStyles) => (
        <AddRemoveButtons
          css={bottomButtonStyles}
          isDrawerMode
          reviewTopicUid={reviewTopicUid}
          reviewUid={reviewUid}
          reflection={reflection}
          contentTitle={contentTitle}
        />
      ),
    });
  };
  const getSubText = (): string => {
    const {
      contentTypeId, metadata, contentCreatedDate, owner,
    } = reflection;

    const meetingTitleValue = metadata?.find((item) => item.metadataType.description === 'Meeting Title')?.value || '';
    const convertedDate = moment(contentCreatedDate).format('MMM D, YYYY');
    const ownerName = `${owner.firstName} ${owner.lastName}`;

    switch (contentTypeId) {
      case ReflectionType.AgendaItem:
        return `${meetingTitleValue} \u2022 ${convertedDate}`;
      case ReflectionType.Feedback:
        // May need to update this to be the creator of the feedback once it's in the metadata
        return `Received ${convertedDate}`;
      case ReflectionType.Goal:
        return `Owned by ${ownerName}`;
      case ReflectionType.Learning:
        // will need to update this to be the creator of the learning once it's in the metadata
        // return `Received from ${creatorName}`;
        return `Received ${convertedDate}`;
      default:
        return '';
    }
  };

  const hookProps = {
    openDetailsDrawer,
    title,
    showDetailsLink,
    setShowDetailsLink,
    getSubText,
    isSelected,
    reflection,
    reviewTopicUid,
    reviewUid,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
