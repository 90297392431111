import { ActionItem } from '~ActionItems/const/interfaces';
import { EditActionItemData } from '~ActionItems/hooks/useEditActionItem';

const normalizeActionItem = (actionItem: ActionItem):EditActionItemData => ({
  description: actionItem.description,
  assigneeId: actionItem.assigneeId,
  assigneeUserId: actionItem.assigneeUserId,
  dueDateInMillis: actionItem.dueDateInMillis ?? '',
  externalLink: actionItem.externalLink ?? '',
  status: actionItem.status,
  text: actionItem.text,
  isRecurring: actionItem.isRecurring,
  recurrenceRule: actionItem.recurrenceRule,
});

export const updatedValues = (ogActionItem: ActionItem, updatedActionItem: EditActionItemData):EditActionItemData => {
  const normalizedOgActionItem = normalizeActionItem(ogActionItem);
  const payload = Object.keys(updatedActionItem).reduce((partialPayload, key) => {
    const something = key as keyof EditActionItemData;

    const updatedPartialPayload = partialPayload;
    if (updatedActionItem[something] !== normalizedOgActionItem[something]) {
      // @ts-expect-error we're not sure what's going here
      updatedPartialPayload[something] = updatedActionItem[something];
    }

    return updatedPartialPayload;
  }, {} as Partial<EditActionItemData>);

  return payload as EditActionItemData;
};
