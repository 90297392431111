import * as types from './index';

export const signout = (payload) => ({
  type: types.SIGNOUT,
  payload,
});

export const clearGlobalAPiErrorData = (payload) => ({
  type: types.GLOBAL_API_ERROR,
  payload,
});
