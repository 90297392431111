import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';
import { faMegaphone, faWarning } from '@fortawesome/pro-light-svg-icons';
import { getDateString } from '~Common/utils/dateString';
import { useMemo } from 'react';
import { NewActionItemStatus } from '~ActionItems/const/interfaces';

const styles = {
  dueDate: (isComplete?: boolean, isPastDue?: boolean, isDueSoon?: boolean) => {
    let color = palette.neutrals.gray600;

    if (isComplete) {
      color = palette.brand.green;
    } else if (isPastDue) {
      color = palette.brand.red;
    } else if (isDueSoon) {
      color = palette.brand.indigo;
    }

    return css({
      display: 'flex',
      alignItems: 'center',
      color,
      fontSize: '0.75rem',
      fontWeight: (isComplete || isPastDue || isDueSoon) ? 600 : 400,
    });
  },
  dueDateIcon: (isPastDue?: boolean) => css({
    marginRight: '0.5rem',
    color: isPastDue ? palette.brand.red : palette.brand.indigo,
  }),
};

interface ViewProps {
  isComplete: boolean,
  isPastDue?: boolean,
  isDueSoon?: boolean,
  dateString: string,
}

const View = ({
  isComplete,
  isPastDue,
  isDueSoon,
  dateString,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.dueDate(isComplete, isPastDue, isDueSoon)} {...props}>
    {(!isComplete && (isPastDue || isDueSoon)) && (
      <FontAwesomeIcon
        icon={isPastDue ? faWarning : faMegaphone}
        size="lg"
        css={styles.dueDateIcon(isPastDue)}
      />
    )}
    <span>{dateString}</span>
  </div>
);

export interface DueDateProps {
  dueDateInMillis: number,
  status: NewActionItemStatus,
  completedInMillis?: number,
}

const DueDate = ({
  dueDateInMillis,
  completedInMillis,
  status,
  ...props
}: DueDateProps): JSX.Element => {
  const {
    isPast: isPastDue,
    isTomorrow: isDueTomorrow,
    isToday: isDueToday,
    dateString: dueDateString,
  } = getDateString({ timestamp: dueDateInMillis });
  const { dateString: completedDateString } = getDateString({ timestamp: completedInMillis });
  const hasDueDate = !!dueDateInMillis;
  const isComplete = status === NewActionItemStatus.Completed;

  const dateString = useMemo((): string => {
    if (isComplete) {
      return `Completed on: ${completedDateString ?? ''}`;
    }

    if (hasDueDate) {
      return `Due ${dueDateString ?? ''}`;
    }

    return 'No due date';
  }, [dueDateString, completedDateString, isComplete, hasDueDate]);

  const hookProps = {
    isComplete: false,
    isPastDue,
    isDueSoon: isDueTomorrow || isDueToday,
    dateString,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default DueDate;
