/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import DrawerHeader from '~Deprecated/ui/components/DrawerManager/DrawerHeader';
import DrawerButton from '~Deprecated/ui/components/Buttons/DrawerButton';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';
import { BUTTON_VARIANTS } from '~Common/const/buttonVariants';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  common: css`
      padding-block-start: 10px;
      white-space: pre-wrap;
      word-break: break-word;
    `,
  tabs: css`
    margin-top: 20px;
    `,
  tabStyles: css`
      width: 10%;
      min-width: 33% !important;
    `,
};

const PersonalityDrawer = ({
  details,
  onClose,
}) => {
  const doneButton = () => (
    <DrawerButton variant={BUTTON_VARIANTS.NEUTRAL} onClick={onClose} text="Done" />
  );

  return (
    <DrawerLayout
      renderHeader={() => (
        <DrawerHeader
          title="Personality Type"
          renderRightButton={doneButton}
        />
      )}
      renderBody={() => (
        <div css={styles.common}>
          {details && (
            <HTMLRenderer htmlText={details.commentary} />
          )}
        </div>
      )}
    />
  );
};

PersonalityDrawer.propTypes = {
  details: PropTypes.objectOf().isRequired,
  onClose: PropTypes.func.isRequired,
};

PersonalityDrawer.defaultProps = {

};

export default PersonalityDrawer;
