import { css, SerializedStyles } from '@emotion/react';
import {
  FORM_STYLES, BUTTON_STYLES, REVIEW_DISPLAY_STYLES, MULTIPLE_CHOICE,
} from '~Reviews/V2/Const/pageStyles';
import RankingPicker from '~Reviews/V2/Shared/RatingPicker';
import Froala from '~Common/V3/components/Froala';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { TextFieldArray } from '~Common/V3/components/uncontrolled/TextFieldArray';
import {
  Form,
  Checkbox,
  TextField,
  MultiRadio,
} from '~Common/V3/components/uncontrolled';
import { capitalize } from 'lodash';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import { QUESTION_OPTIONS_SHAPE } from '../Const/defaults';
import { ParticipantTypeEnum, TopicShape, TopicTypeEnum } from '../Const/types';
import { FormValues } from '../schemata/CreateTopicForCycle';
import { FormValuesMatrixOrg } from '../schemata/CreateTopicForCycleMatrixOrgs';
import { useTopicFormContext } from '../Hooks/useTopicFormContext';

const styles = {
  ...FORM_STYLES,
  ...BUTTON_STYLES,
  ...REVIEW_DISPLAY_STYLES,
  ...MULTIPLE_CHOICE,
  questionWrap: css({
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
  }),
  inputFieldSpace: css({
    marginBottom: '.5rem',
  }),
};

interface ViewProps {
    participationEnum: ParticipantTypeEnum,
    formContext: UseFormReturn<FormValues | FormValuesMatrixOrg>,
    onFormSubmit: SubmitHandler<FormValues>,
    updateQuestionView: (value: boolean) => void,
    topic: TopicShape,
    editQuestion: string,
  inDrawer: boolean,
  disableSave: boolean,
}

const View = ({
  participationEnum,
  formContext,
  onFormSubmit,
  updateQuestionView,
  topic,
  editQuestion,
  inDrawer,
  disableSave,
}: ViewProps): JSX.Element => (
  <>
    <Form
      formContext={formContext}
      onSubmit={() => null}
    >
      <MultiRadio
        name="typeEnum"
        defaultValue={editQuestion.length ? topic.typeEnum : TopicTypeEnum.Freeform}
        label="SELECT question type"
        radios={QUESTION_OPTIONS_SHAPE.map((shape) => ({
          name: (
            <p>
              <span>{shape.label}</span>
              {' '}
              <p>{shape.tooltip}</p>
            </p>
          ),
          value: shape.value,
        }))}
        vertical={inDrawer}
      />
      <TextField
        css={[styles.inputField, styles.fullInputField]}
        name="rank"
        label="Rank"
        hidden
      />
      <TextField
        css={[styles.inputField, styles.inputFieldSpace, styles.fullInputField]}
        name="text"
        label="Question Text"
        required
      />
      <Froala
        enableEmojis
        label="Description"
        name="description"
        froalaConfigProps={{
          charCounterCount: true,
          charCounterMax: 2000,
        }}
        richTextEditorProps={{
          name: 'description',
          onChange: ({ value: newText }) => formContext.setValue('description', newText, { shouldDirty: true }),
          initialValue: editQuestion.length ? topic?.description : '',
        }}
      />

      {Number(formContext.watch('typeEnum')) === TopicTypeEnum.MultipleChoice && (
      <>
        <div
          css={styles.labelTitle}
        >
          Options - Drag to reorder
        </div>
        <TextFieldArray
          name="multiChoiceConfig"
          inputLabel="New Option"
          renderAddButton={(onClick: () => void) => (
            <LeadrButton
              size="small"
              variant="ghost"
              onClick={onClick}
              data-test-id="reviewsMultipleChoiceAddOption"
            >
              + Add Option
            </LeadrButton>
          )}
          renderActionButtons={({ onClickSaveButton, onClickCancelButton, buttonRowStyles }: {
            onClickSaveButton: () => void,
            onClickCancelButton: () => void,
            buttonRowStyles: SerializedStyles
            }) => (
              <div css={buttonRowStyles}>
                <LeadrButton
                  size="small"
                  onClick={onClickSaveButton}
                  data-test-id="reviewsMultipleChoiceSaveOption"
                >
                  Save
                </LeadrButton>
                <LeadrButton
                  size="small"
                  variant="ghost"
                  onClick={onClickCancelButton}
                  data-test-id="reviewsMultipleChoiceCancelOption"
                >
                  Cancel
                </LeadrButton>
              </div>
          )}
        />
      </>
      )}
      {Number(formContext.watch('typeEnum')) === TopicTypeEnum.Linear && (
      <>
        <RankingPicker
          {...formContext.watch('linearConfig')}
          onMinLabelChange={(value: string) => {
            formContext.setValue('linearConfig.minLabel', value);
          }}
          onMaxLabelChange={(value: string) => {
            formContext.setValue('linearConfig.maxLabel', value);
          }}
          onNumScaleChange={(value: number) => {
            formContext.setValue('linearConfig.numScale', value);
          }}
        />
      </>
      )}
      <div
        css={[styles.checkboxWrap, styles.checkboxRevieweSelection]}
      >
        <div css={styles.checkboxLabel}>
          Respondents
        </div>
        <Checkbox
          name="topicIsForReviewers"
          label={capitalize('Reviewer')}
          defaultChecked={FlaggedEnum(topic.topicTargetEnum).hasFlag(ParticipantTypeEnum.Reviewer)}
          css={styles.checkbox}
          data-test-id="reviewsSelectTopicForReviewers"
          size={20}
        />
        {FlaggedEnum(participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer) ? (
          <Checkbox
            name="topicIsForMentors"
            label="Secondary Reviewer"
            defaultChecked={FlaggedEnum(topic.topicTargetEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer)}
            css={styles.checkbox}
            data-test-id="reviewsSelectTopicForSecondaryReviewers"
            size={20}
          />
        ) : null}
        <Checkbox
          name="topicIsForReviewees"
          label={capitalize('Reviewee')}
          defaultChecked={FlaggedEnum(topic.topicTargetEnum).hasFlag(ParticipantTypeEnum.Reviewee)}
          css={styles.checkbox}
          data-test-id="reviewsSelectTopicForReviewees"
          size={20}
        />
      </div>
      <LeadrButton
        onClick={formContext.handleSubmit(onFormSubmit)}
        css={styles.spacer}
        disabled={!disableSave}
        data-test-id="reviewsSaveQuestion"
      >
        Save Question
      </LeadrButton>
      <LeadrButton
        variant="ghost"
        onClick={() => updateQuestionView(false)}
        data-test-id="reviewsCancelQuestion"
      >
        Cancel
      </LeadrButton>
      {/*
        Uncomment this to see the YUP Errors
      {Object.keys(formContext.formState.errors).length
        && Object.values(formContext.formState.errors).map((error) => (<p>{error.message}</p>))} */}
    </Form>
  </>
);

interface AddQuestionProps {
    participationEnum: ParticipantTypeEnum,
    onFormSubmit: SubmitHandler<FormValues>,
    updateQuestionView: (value: boolean) => void,
    editQuestion: string,
  topic: TopicShape,
  inDrawer?: boolean,
}
const AddQuestion = ({
  participationEnum,
  onFormSubmit,
  updateQuestionView,
  topic,
  editQuestion,
  inDrawer = false,
}: AddQuestionProps): JSX.Element => {
  const { formContext } = useTopicFormContext({
    topic,
    isMatrixOrg: FlaggedEnum(participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer),
  });

  const multiChoiceConfigOptions = formContext.getValues('multiChoiceConfig');
  const trimmedMultiChoiceConfigOptions = multiChoiceConfigOptions?.filter((item) => item.text.trim() !== '');
  const hasTwoOptions = trimmedMultiChoiceConfigOptions ? trimmedMultiChoiceConfigOptions.length >= 2 : false;

  const hookProps = {
    participationEnum,
    formContext,
    onFormSubmit,
    updateQuestionView,
    topic,
    editQuestion,
    inDrawer,
    disableSave: Number(formContext.watch('typeEnum')) === TopicTypeEnum.MultipleChoice ? hasTwoOptions : true,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default AddQuestion;
