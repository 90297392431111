import { SerializedStyles, css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useCallback, useContext, useMemo } from 'react';
import { MeetingInfoContext } from '~Meetings/components/topic-suggestions/contexts/MeetingInfoContext';
import { AgendaTemplates } from '@leadr-hr/types';
import useGetCuratedMeetingTemplates from '~Meetings/hooks/templates/useGetCuratedMeetingTemplates';
import { useAddTemplateItemsToMeeting } from '~Meetings/hooks/templates/useAddTemplateItemsToMeeting';
import { getFormattedTopicsForBulkAdd } from '~Meetings/components/topic-suggestions/utils/getFormattedTopicsForBulkAdd';
import { getIsIndividualQuestionsTemplate } from '~Meetings/components/topic-suggestions/utils/getIsIndividualTopicTemplate';
import {
  GetCuratedAgendaTemplateDetails,
  GetAgendaTopicTemplateDetails,
  GetCuratedMeetingTemplateDetails,
  GetTemplateTopic,
} from '~Meetings/components/topic-suggestions/const/types';
import { CURATED_BY_TABLE_GROUP_TEXT } from '~Common/const/curated';
import MeetingTemplate, { TemplateSectionSkeleton } from '../MeetingTemplate';
import { useIsTopicAddedToMeeting } from '../../stores/useIsTopicAddedToMeeting';
import TopicSuggestionsBanner from '../../shared/TopicSuggestionsBanner';

const styles = {
  curatedByLeadrTab: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',
  }),
};

interface ViewProps {
  curatedTemplates: GetCuratedMeetingTemplateDetails[] | undefined,
  isLoading: boolean,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
  handleAddTopicsToMeeting: (agendaItems: GetCuratedAgendaTemplateDetails[]) => void,
  getDisableAddAllTopicsButton: (templateTopics: GetTemplateTopic[]) => boolean,
}

const View = ({
  curatedTemplates,
  addTopicToMeeting,
  handleAddTopicsToMeeting,
  isLoading,
  getDisableAddAllTopicsButton,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.curatedByLeadrTab}
    {...props}
  >
    <TopicSuggestionsBanner curatedByLeadr={false} />
    {isLoading && (
      <MultipleSkeletonLoaders
        css={styles.curatedByLeadrTab}
        renderSkeletonItem={() => (
          <TemplateSectionSkeleton />
        )}
        numberOfSkeletons={3}
      />
    )}
    {!isLoading && curatedTemplates && (
      <>
        {curatedTemplates.map((template) => (
          <MeetingTemplate
            key={template.id}
            addTopicToMeeting={addTopicToMeeting}
            creator={CURATED_BY_TABLE_GROUP_TEXT}
            template={template}
            renderRightButton={(rightButtonStyles: SerializedStyles) => (
              <LeadrButton
                css={rightButtonStyles}
                variant="text"
                textButtonColor={palette.brand.indigo}
                onClick={() => handleAddTopicsToMeeting(template.children)}
                data-test-id="meetingTopicSuggestionsAddAllTemplateTopics"
                disabled={getDisableAddAllTopicsButton(template.children)}
              >
                + Add All
              </LeadrButton>
            )}
          />
        ))}
      </>
    )}
  </div>
);

const CuratedByTableGroupTab = ({
  ...props
}): JSX.Element => {
  const { huddleId, meetingTemplateType } = useContext(MeetingInfoContext);
  const { data, isLoading } = useGetCuratedMeetingTemplates({ meetingTemplateType, curatedSource: AgendaTemplates.CuratedSource.TableGroup });

  const { mutate: addTemplateItemsToMeetingMutation } = useAddTemplateItemsToMeeting();

  const { getIsAnyTemplateTopicInMeeting } = useIsTopicAddedToMeeting(huddleId);

  const getDisableAddAllTopicsButton = useCallback((templateTopics: GetTemplateTopic[]) => {
    const isAnyTemplaleTopicInMeeting = getIsAnyTemplateTopicInMeeting(templateTopics);
    return isAnyTemplaleTopicInMeeting;
  }, [getIsAnyTemplateTopicInMeeting]);

  const handleAddTopicsToMeeting = (agendaTopics: GetCuratedAgendaTemplateDetails[]): void => {
    const children = getFormattedTopicsForBulkAdd(agendaTopics);

    addTemplateItemsToMeetingMutation({
      huddleId,
      children,
    });
  };

  const addTopicToMeeting = (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails): void => {
    handleAddTopicsToMeeting([agendaTopic]);
  };

  const curatedTemplates = useMemo(() => data?.response.meetingTemplates.filter((template) => !getIsIndividualQuestionsTemplate(template)), [data]);

  const [areTemplatesLoading] = useSkeletonLoaders(isLoading);

  const hookProps = {
    curatedTemplates,
    addTopicToMeeting,
    handleAddTopicsToMeeting,
    isLoading: areTemplatesLoading,
    getDisableAddAllTopicsButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CuratedByTableGroupTab;
