export const PERSONALITY_INFO_LINKS = {
  DiSC: 'https://www.truity.com/blog/page/about-disc-personality-test',
  Enneagram: 'https://enneagramuniverse.com/',
  'Myers Briggs': 'https://www.16personalities.com/',
  StrengthFinder: 'https://www.gallup.com/cliftonstrengths/en/253676/how-cliftonstrengths-works.aspx',
};

export const PERSONALITY_FIND_LINKS = {
  DiSC: 'https://www.truity.com/test/disc-personality-test',
  Enneagram: 'https://enneagramuniverse.com/enneagram/test/',
  'Myers Briggs': 'https://www.16personalities.com/free-personality-test',
  StrengthFinder: 'https://www.gallup.com/cliftonstrengths/en/253868/popular-cliftonstrengths-assessment-products.aspx',
};
