import { css } from '@emotion/react';
import Lightbox, { LightboxExternalProps } from 'yet-another-react-lightbox';
import Download from 'yet-another-react-lightbox/plugins/download';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import { hexToRGBA, palette } from '~Common/styles/colors';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine, faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  useLightbox,
} from '~Common/V3/components/Lightbox/useLightbox';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';

const styles = {
  attachmentsLightbox: css({
    '.yarl__container': {
      background: hexToRGBA(palette.neutrals.gray900, 0.85),
    },
    '.yarl__slide_title_container': {
      background: palette.neutrals.gray900,
    },
    zIndex: '19001 !important', // 1 higher than the pendo button
  }),
  icon: css({
    fontSize: '1.125rem',
    color: palette.neutrals.white,
  }),
};

interface ViewProps extends LightboxExternalProps {
  handleCloseLightbox: () => void,
}

const View = ({
  handleCloseLightbox,
  ...props
}: ViewProps): JSX.Element => (
  <Lightbox
    css={styles.attachmentsLightbox}
    render={{
      iconDownload: () => <FontAwesomeIcon css={styles.icon} icon={faArrowDownToLine} />,
      iconClose: () => <FontAwesomeIcon css={styles.icon} icon={faTimes} />,
    }}
    plugins={[Download, Captions]}
    close={handleCloseLightbox}
    controller={{
      closeOnBackdropClick: true,
    }}
    animation={{
      swipe: 0,
    }}
    {...props}
  />
);

const AttachmentsLightbox = ({
  ...props
}: Omit<LightboxExternalProps, 'slides'>): JSX.Element => {
  const {
    useIsLightboxOpen,
    useGetLightboxProps,
    closeLightbox,
  } = useLightbox();

  const handleCloseLightbox = useCallback((): void => closeLightbox(), [closeLightbox]);

  const open = useIsLightboxOpen();

  const {
    getSlideData: selectLightboxSlides,
    queryKeyToWatch,
    ...lightboxProps
  } = useGetLightboxProps();

  const data = useQuery({
    queryKey: queryKeyToWatch ?? [],
    enabled: !!selectLightboxSlides && !!queryKeyToWatch,
    select: selectLightboxSlides,
  });

  /*
    If the data is empty close the lightbox
    Example of how this can happen:
      User A opens the lightbox with attachments from a topic in a meeting
      While they have the lightbox open, User B deletes the topic
      The realtime event comes in and the topic is removed from the meeting
    In the above scenario, we want to close the lightbox, since the attachments no longer exist
  */
  useEffect(() => {
    if ((data.data === undefined || data.data?.length === 0) && open) {
      handleCloseLightbox();
    }
  }, [data.data, handleCloseLightbox, open]);

  const hookProps = {
    handleCloseLightbox,
    ...lightboxProps,
    open,
    slides: data.data ?? [],
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AttachmentsLightbox;
