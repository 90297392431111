import { css } from '@emotion/react';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';
import Banner, { BannerProps } from '~Common/V3/components/Banner';
import IconContents from '~Common/V3/components/Banner/IconContents';

const styles = {
  skippedMeetingBanner: css({
    backgroundColor: palette.brand.indigo,
    color: palette.neutrals.white,
    fontWeight: 500,
    marginBottom: '1.5rem',
  }),
};

type SkippedMeetingBannerProps = Omit<BannerProps, 'renderContents'>;

const SkippedMeetingBanner = ({ ...props }: SkippedMeetingBannerProps): JSX.Element => (
  <Banner
    css={styles.skippedMeetingBanner}
    renderContents={() => (
      <IconContents
        renderIcon={() => (
          <FontAwesomeIcon size="2x" icon={faExclamationTriangle} />
        )}
        renderText={() => (
          <>This meeting instance has been skipped.</>
        )}
      />
    )}
    {...props}
  />
);

export default SkippedMeetingBanner;
