import { css } from '@emotion/react';
import { NO_RESPONSE_TEXT } from '~Common/const/surveys';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import Card from '~Common/V3/components/Card';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { SCRequestedLearningDetail } from '~Learning/const/interfaces';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import RequestedLearningTabWrapper from '../RequestedLearningTabWrapper';

const styles = {
  questionCard: css({
    marginTop: '1.125rem',
  }),
  cardBackground: (hasDarkBackground: boolean) => css(
    {
      padding: '1rem',
      boxShadow: 'none',
      '&:not(:last-child)': {
        marginBottom: '.625rem',
      },
    },
    hasDarkBackground && {
      background: palette.neutrals.gray50,
    },
  ),
  freeformCard: css({
    display: 'flex',
  }),
  freeformInfo: css({
    marginLeft: '.875rem',
  }),
  responseText: css({
    fontSize: '1rem',
    color: palette.neutrals.gray800,
  }),
  freeformName: css({
    marginTop: '1rem',
  }, withTruncate()),
  title: css({
    fontSize: '1.125rem',
    marginBottom: '1rem',
    color: palette.neutrals.gray900,
  }),
};

interface QuestionsTabProps {
  learning: SCRequestedLearningDetail,
  showViewParticipantsButton: boolean,
}

const QuestionsTab = ({
  learning,
  showViewParticipantsButton,
}: QuestionsTabProps): JSX.Element => (
  <RequestedLearningTabWrapper
    title={learning.title}
    introduction={learning.introduction}
    assignees={learning.assignees}
    dueDate={learning.dueDate}
    learningId={learning.id}
    status={learning.status}
    showViewParticipantsButton={showViewParticipantsButton}
    renderDrawerContents={() => (
      <>
        {learning.questions.map((question) => (
          <Card
            css={styles.questionCard}
            renderContents={() => (
              <>
                <HTMLRenderer css={styles.title} htmlText={question.topic} />
                {question.responses?.map((response, index) => (
                  <Card
                    key={response.id}
                    css={styles.cardBackground(index % 2 === 0)}
                    renderContents={() => (
                      <div css={styles.freeformCard}>
                        <SquareAvatar
                          imgUrl={response.assignee.profileImageUrl}
                          name={`${response.assignee.firstName} ${response.assignee.lastName}`}
                          width={32}
                          height={32}
                          includeInitials
                        />
                        <div css={styles.freeformInfo}>
                          <div>
                            {response.text && (
                              <HTMLRenderer css={styles.responseText} htmlText={response.text} />
                            )}
                            {!response.text && (
                              <div>{NO_RESPONSE_TEXT}</div>
                            )}
                          </div>
                          <div css={styles.freeformName}>{`${response.assignee.firstName} ${response.assignee.lastName}`}</div>
                        </div>
                      </div>
                    )}
                  />
                ))}
              </>
            )}
          />
        ))}
      </>
    )}
  />
);

export default QuestionsTab;
