import { HTMLObject, HTMLString } from '~Common/types';
import { useMemo, forwardRef, ForwardedRef } from 'react';
import { stringToSanitizedHTMLObject } from '~Common/utils/stringToHTMLObject';
import { isHTMLText } from '~Common/utils/isHTMLText';

interface ViewProps {
  htmlObject: HTMLObject,
}

const View = forwardRef(({ htmlObject, ...props }: ViewProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => (
  <div
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={htmlObject}
    ref={ref}
    {...props}
  />
));

interface HTMLRendererProps {
  htmlText: HTMLString,
}

/**
 * @param {HTMLString} htmlText HTML text only, do not use this in a place that displays non-HTML.
 * Ideally the backend sanitizes the HTML for us, but as of 3/15/2024, it does not, so we sanitize here for now
 */

const HTMLRenderer = forwardRef(({ htmlText, ...props }: HTMLRendererProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
  const htmlObject = useMemo(() => {
    // Not entirely sure if we want to allow empty strings
    if (process.env.NODE_ENV === 'development' && htmlText !== '' && !isHTMLText(htmlText)) {
      throw new Error(`
        HTMLRenderer: The text provided: "${htmlText}" is not HTML. Please use a different component.
        Also you can check the stack trace in the error tab of the console to find where this text is being passed in.
      `);
    }

    return stringToSanitizedHTMLObject(htmlText);
  }, [htmlText]);

  const hookProps = {
    htmlObject,
    ref,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
});

export default HTMLRenderer;
