import { useEffect, useState, useRef } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import FroalaEditor from 'react-froala-wysiwyg';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { EMOTION_CSS_SHAPE } from '~Common/const/proptypes';
import { pallette } from '~Deprecated/ui/styles/colors';
import DisabledInputBox from '~Deprecated/ui/components/Inputs/DisabledInputBox';
import '~Common/components/Fields/RichTextEditors/FroalaImports';

export const FROALA_DEFAULT_HEIGHT_MIN = 200;
export const FROALA_SINGLE_LINE_HEIGHT = 22;

const styles = {
  container: css`
    .fr-box.fr-basic .fr-element.fr-view {
      font-family: ProximaNova;
    }

    .fr-toolbar,
    .fr-second-toolbar,
    .fr-box.fr-basic .fr-wrapper {
      background: ${pallette.gray5};
      border: 0;
      border-radius: 0;
    }

    .fr-box.fr-basic .fr-element {
      padding-bottom: 0;
      padding-top: 0;
      height: calc(100% - 40px);
    }

    .fr-wrapper {
      border-bottom: 1px solid ${pallette.gray4} !important;
    }

    .fr-box .fr-counter {
      margin-bottom: 0px !important;
    }

    .fr-toolbar .fr-newline {
      display: none;
    }

    .fr-second-toolbar,
    .fr-toolbar.fr-bottom {
      border-radius: 0 0 0.5rem 0.5rem;
    }

    .fr-wrapper.show-placeholder .fr-placeholder {
      white-space: unset;
    }

    .fr-popup {
      box-shadow: none !important;
      padding-top: 3.5rem;
      position: initial !important;
    }

    p {
      white-space: normal;
    }

    border: 1px solid ${pallette.gray4};
    border-radius: 0.5rem;
  `,
  invisibleInput: css`
    display: block;
    line-height: 0;
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    box-shadow: none;
    opacity: 0;
  `,
  labelContainer: css`
    background: ${pallette.gray5};
    border-radius: 0.5rem 0.5rem 0 0;
    min-height: 10px;
  `,
  label: css`
    color: ${pallette.gray2};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 7px 7px 12px 14px;
    margin: 0;
  `,
};

const View = ({
  value,
  doChange,
  styleOverrides,
  name,
  label,
  config,
  required,
  isReadOnly,
  editorRef,
  labelStyles,
  renderLabel,
}) => (
  <>
    <>
      {isReadOnly && (
        <DisabledInputBox
          label={label}
          htmlValue={value}
          css={
            Array.isArray(styleOverrides) ? styleOverrides : [styleOverrides]
          }
        />
      )}
      {!isReadOnly && (
        <>
          <div
            css={[
              styles.container,
              ...(Array.isArray(styleOverrides)
                ? styleOverrides
                : [styleOverrides]),
            ]}
          >
            <div css={styles.labelContainer}>
              {label && (
                <label htmlFor={name} css={[styles.label, labelStyles]}>
                  {label}
                </label>
              )}
              {renderLabel && renderLabel(name, styles.label)}
            </div>
            <FroalaEditor
              ref={editorRef}
              model={value}
              name={name}
              onModelChange={doChange}
              config={config}
              tag="textarea"
            />
          </div>
          <input
            css={styles.invisibleInput}
            name={name}
            value={value}
            required={required}
            tabIndex={-1}
          />
        </>
      )}
    </>
  </>
);

View.propTypes = {
  styleOverrides: PropTypes.oneOfType([
    PropTypes.arrayOf(EMOTION_CSS_SHAPE),
    EMOTION_CSS_SHAPE,
  ]),
  value: PropTypes.string.isRequired,
  doChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  config: PropTypes.shape({
    placeholderText: PropTypes.string,
    toolbarButtons: PropTypes.arrayOf(PropTypes.array),
    tabSpaces: PropTypes.number,
    heightMin: PropTypes.number,
    toolbarBottom: PropTypes.bool,
    events: PropTypes.shape({
      initialized: PropTypes.func,
    }),
  }).isRequired,
  required: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  editorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  labelStyles: EMOTION_CSS_SHAPE,
  renderLabel: PropTypes.func,
};

View.defaultProps = {
  styleOverrides: [],
  name: '',
  label: '',
  required: false,
  isReadOnly: false,
  editorRef: null,
  labelStyles: null,
  renderLabel: null,
};

const useRichTextEditor = ({
  initialValue,
  onChange,
  name,
  valueTrackedInsideFroala = true, // If the state value is tracked outside of Froala, then this needs to be false, or else you can get too many set states being called (which crashes the app)
}) => {
  const [value, setValue] = useState(initialValue);
  const previousValue = usePrevious(initialValue);

  useEffect(() => {
    // This is a hack for meetings, as is the valueTrackedInsideFroala workflow. We can remove this when Meetings goes away.
    if (
      !valueTrackedInsideFroala
      && previousValue !== initialValue
      && initialValue === ''
    ) {
      setValue('');
    }
    if (
      previousValue === initialValue
      || (value && !valueTrackedInsideFroala)
    ) {
      return;
    }

    setValue(initialValue);
  }, [previousValue, initialValue, valueTrackedInsideFroala, value]);

  const doChange = (a) => {
    const text = a || '';
    setValue(text);
    if (onChange) {
      onChange({ value: text, name });
    }
  };

  const onBlur = () => {
    const trimmedValue = value.trim();

    if (trimmedValue !== value) {
      setValue(trimmedValue);
    }
  };

  return {
    doChange,
    onBlur,
    value,
    setValue,
  };
};

const useFroalaConfig = ({
  placeholder,
  heightMin = FROALA_DEFAULT_HEIGHT_MIN,
  heightMax = null,
  height = null,
  enableEmojis,
  zIndex,
  charCounterMax = -1,
}) => ({
  placeholderText: placeholder,
  toolbarButtons: {
    moreText: {
      buttons: ['bold', 'italic', 'underline', '|', 'formatOL', 'formatUL', '|', 'insertLink', ...(enableEmojis ? ['emoticons'] : [])],
      buttonsVisible: 9,
    },
  },
  heightMin,
  heightMax,
  height,
  toolbarBottom: true,
  listAdvancedTypes: false,
  emoticonsUseImage: false,
  zIndex,
  charCounterMax,
  charCounterCount: true,
  key: process.env.REACT_APP_FROALA_KEY,
  toolbarSticky: false,
  quickInsertEnabled: false,
  pasteAllowedStyleProps: [],
  linkAlwaysBlank: true,
  linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
  pasteDeniedTags: ['pre'],
});

const useFroalaEvents = ({ defaultBold, autoFocus }) => {
  const editorRef = useRef();
  const focusFroala = () => {
    if (editorRef?.current?.editor.charCounter.count() > 0) {
      editorRef?.current?.editor.selection.setAtEnd(
        editorRef?.current?.editor.$el.get(0),
      );
      editorRef?.current?.editor.selection.restore();
      // Has to default to bold at the end of the selection, this does create a new strong tag
      if (defaultBold) {
        editorRef?.current?.editor?.commands?.bold();
      }
    } else {
      // Separate workflow for focusing if there is no text in the editor, since selecting at the end was causing a new line to happen
      editorRef?.current?.editor.events.focus();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (autoFocus) {
        // Sets the autofocus to the end of the input
        focusFroala();
      }

      if (defaultBold) {
        editorRef?.current?.editor?.commands?.bold();
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { editorRef, focusFroala };
};

/**
 * @deprecated Old Froala UI, use packages/leadr-frontend/src/common/V3/components/Froala.tsx instead
 */
const Froala = ({ enableEmojis, ...props }) => {
  const { editorRef } = useFroalaEvents(props);

  const {
    doChange, onBlur, value, setValue,
  } = useRichTextEditor({ ...props });

  const config = useFroalaConfig({
    ...props,
    enableEmojis,
  });

  const hookProps = {
    doChange,
    onBlur,
    value,
    setValue,
    enableEmojis,
    config,
    editorRef,
  };

  return <View {...hookProps} {...props} />;
};

Froala.propTypes = {
  enableEmojis: PropTypes.bool,
};

Froala.defaultProps = {
  enableEmojis: true,
};

export {
  View, useRichTextEditor, useFroalaConfig, useFroalaEvents,
};
export default Froala;
