import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { SerializedStyles, css } from '@emotion/react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { palette } from '~Common/styles/colors';
import { Goals } from '@leadr-hr/types';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { DEFAULT_OWNER } from '~Goals/const/defaults';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';
import ViewGoalDetails from '../Shared/ViewGoalDetails';
import { useGetGoalById } from '../../hooks/useGetGoalById';
import { GoalActionMenu } from '../GoalActionMenu';

export const viewGoalDetailsTemplate = {
  name: 'EDIT_GOAL_STATUS_DRAWER',
  type: templateType.PRIMARY,
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  drawerBodyContainer: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
    flex: 1,
  }),
  footer: (shouldBeSticky: boolean) => css({
    padding: '1.5rem',
    backgroundColor: palette.neutrals.gray50,
  }, shouldBeSticky && {
    position: 'sticky',
    bottom: 0,
  }),
  actionMenu: css({
    display: 'flex',
    alignItems: 'center',
  }),
  titleWrap: css({
    display: 'grid',
    gridTemplateColumns: '95% 5%',
    marginBottom: '1.25rem',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
  }),
  subHeading: css({
    fontSize: '0.75rem',
    fontWeight: 400,
    color: palette.neutrals.gray700,
  }),
  titleContainer: css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }),
  title: css({
    fontSize: '1rem',
    fontWeight: 500,
    color: palette.neutrals.gray800,
  }),
  ownerAvatar: css({
    marginRight: '0.5rem',
  }),
};

export enum FormSubmittedByButton {
  UPDATE_ACTION_ITEM_BUTTON = 'UPDATE_ACTION_ITEM_BUTTON',
  COMPLETE_ACTION_ITEM_BUTTON = 'COMPLETE_ACTION_ITEM_BUTTON'
}

export interface CreateActionItemViewProps{
  isLoading: boolean,
  goal: Goals.Goal,
  closeDrawerClick: () => void,
  isReadOnly: boolean,
  owner: Goals.GoalParticipant,
}

const View = ({
  isLoading,
  goal,
  closeDrawerClick,
  isReadOnly,
  owner,
}:CreateActionItemViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title="Goal"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            tooltip="Close"
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBodyContainer}>
        <div css={styles.drawerBody}>
          <div css={styles.titleWrap}>
            <div css={styles.header}>
              <NewBaseAvatar
                profileImageUrl={owner.profileImageUrl}
                name={`${owner.firstName} ${owner.lastName}`}
                avatarSize={30}
                css={styles.ownerAvatar}
              />
              <div css={styles.titleContainer}>
                <div css={styles.subHeading}>Goal Title</div>
                <TooltipOnTruncate css={styles.title} text={goal?.title ?? 'Loading your goal...'} />
              </div>
            </div>
            <div css={styles.actionMenu}>
              {Object.keys(goal).length !== 0 && (
                <GoalActionMenu goal={goal} showExpandedActionMenu />
              )}
            </div>
          </div>
          {goal && (
            <ViewGoalDetails
              goal={goal}
              showSkeleton={isLoading}
              isMobile
              isTablet={false}
              isDrawer
              isReadOnly={isReadOnly}
            />
          )}
        </div>
      </div>
    )}
  />
);

interface CreateActionItemDrawerState {
  selectedAttendees?: string[],
  dueDate: Date,
}
interface ViewGoalDetailsDrawerProps extends DrawerProps<CreateActionItemDrawerState> {
  goalId: string,
  isReadOnly?: boolean,
}

const ViewGoalDetailsDrawer = ({
  popDrawer,
  goalId,
  isReadOnly = false,
}: ViewGoalDetailsDrawerProps): JSX.Element => {
  const { data: goal, isLoading } = useGetGoalById({ id: goalId });

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: viewGoalDetailsTemplate.name });
  };

  let sortedGoal = {} as Goals.Goal;
  if (!isLoading && goal) {
    sortedGoal = {
      ...goal,
      statusUpdates: [...goal.statusUpdates].sort((a, b) => b.createdInMillis - a.createdInMillis),
    };
  }

  const ownerParticipant = goal?.participants?.find(
    (participant) => participant.role === Goals.GoalParticipantRole.Owner && participant.firstName !== undefined,
  );

  const ownerToUse = ownerParticipant ?? DEFAULT_OWNER;

  const hookProps = {
    isLoading,
    goal: sortedGoal,
    closeDrawerClick,
    isReadOnly,
    owner: ownerToUse,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: viewGoalDetailsTemplate.name,
  component: ViewGoalDetailsDrawer,
});

export { View };
export default ViewGoalDetailsDrawer;
