import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  partcipantListWrapper: css({
    maxHeight: '14.0625rem',
    overflowY: 'auto',
    margin: '2rem 0',
    border: `1px solid ${palette.neutrals.gray200}`,
  }),
  partcipantList: css({
    listStyle: 'none',
    padding: '.75rem',
    margin: '0',

    '& li': {
      margin: '.75rem 0',
    },
    '& li:first-of-type': {
      margin: '0',
    },
  }),
};

interface ViewProps {
    participants: string[],
}

export const View = ({
  participants,
}: ViewProps): JSX.Element => (
  <div
    css={styles.partcipantListWrapper}
  >
    <ol
      css={styles.partcipantList}
    >
      { participants.map((name) => (
        <li
          key={name}
        >
          { name }
        </li>
      )) }
    </ol>
  </div>
);

interface ParticipantListProps {
    participants: string[],
}

export const ParticipantList = ({
  participants,
}: ParticipantListProps): JSX.Element => {
  const hookProps = {
    participants,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
