import { css } from '@emotion/react';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { palette } from '~Common/styles/colors';
import BaseAutoCompleteTags, { BaseAutoCompleteTagsProps } from '~Common/V3/components/uncontrolled/AutocompleteTags/BaseAutocompleteTags';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { UserGroup } from '~Common/const/userGroups';
import NewBaseAvatar from '../Users/Avatars/NewBaseAvatar';
import { SelectOption, usePeopleTagPicker } from './usePeopleTagPicker';
import SkeletonLoader from '../SkeletonLoader';

const styles = {
  autocompleteOption: css({
    display: 'flex',
    alignItems: 'center',
  }),
  jobTitle: css({
    marginLeft: 'auto',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
  baseAvatar: css({
    marginRight: '0.5rem',
  }),
  skeletonLoader: css({
    maxWidth: '100%',
    height: '5rem',
  }),
};

interface ViewProps extends Omit<BaseAutoCompleteTagsProps<SelectOption, true>, 'multiple' | 'options' | 'defaults'> {
  peopleList: SelectOption[],
  initialSelectedPeopleList: SelectOption[],
  isLoadingPeopleData: boolean,
}

const View = ({
  peopleList,
  initialSelectedPeopleList,
  isLoadingPeopleData,
  ...props
}: ViewProps): JSX.Element => (
  <>
    {(isLoadingPeopleData || peopleList.length === 0) && (
      <SkeletonLoader
        css={styles.skeletonLoader}
        renderComponent={() => <></>}
      />
    )}
    {!isLoadingPeopleData && peopleList.length > 0 && (
      <BaseAutoCompleteTags
        multiple
        options={peopleList}
        defaultValue={initialSelectedPeopleList || []}
        getOptionDisabled={({ disabled = false }) => disabled}
        renderOption={(optionProps, option: SelectOption, { selected }) => (
          <li
            css={styles.autocompleteOption}
            key={option.value}
            {...optionProps}
          >
            <CustomCheckbox
              checked={selected}
            />
            <NewBaseAvatar
              profileImageUrl={option.profileImageUrl}
              name={option.label}
              avatarSize={22}
              css={styles.baseAvatar}
            />
            {option.label}
            <span
              css={styles.jobTitle}
            >
              {option.jobTitle}
            </span>
          </li>
        )}
        {...props}
      />
    )}
  </>
);

interface PeopleTagPicker extends Omit<ViewProps, 'peopleList' | 'initialSelectedPeopleList' | 'isLoadingPeopleData'> {
  initialSelectedIds?: string[], // OrgUserIds or userIds, just match the useOrgIds param
  useOrgIds?: boolean,
  allowSelf?: boolean,
  disabledIds?: string[], // Ids to filter out
  accountTypesToInclude?: UserGroup[],
}

const PeopleTagPicker = ({
  initialSelectedIds,
  useOrgIds,
  allowSelf,
  disabledIds,
  accountTypesToInclude,
  ...props
}: PeopleTagPicker): JSX.Element => {
  const {
    peopleList,
    initialSelectedPeopleList,
    isLoadingPeopleData: isLoading,
  } = usePeopleTagPicker({
    initialSelectedIds,
    useOrgIds,
    allowSelf,
    disabledIds,
    accountTypesToInclude,
  });

  const [isLoadingPeopleData] = useSkeletonLoaders(isLoading);

  const hookProps = {
    peopleList,
    initialSelectedPeopleList,
    isLoadingPeopleData,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default PeopleTagPicker;
