import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';

import { FONT_AWESOME_ICON } from '~Common/const/proptypes';
import Tooltip from '~Common/components/Tooltip';

const styles = {
  button: css`
    border: 0;
    background: transparent;
  `,
  icon: css`
    width: 24px !important;
    height: 24px !important;
  `,
};

const Button = ({
  onClick, icon, type, tooltip, ...props
}) => {
  const WrapperComponent = (tooltip.length > 0) ? Tooltip : Fragment;

  const wrapperProps = {};

  if (tooltip.length > 0) {
    wrapperProps.content = tooltip;
    wrapperProps.placement = 'top-start';
  }

  return (
    <WrapperComponent {...wrapperProps}>
      <button
        css={styles.button}
        onClick={onClick}
        type={type}
        {...props}
      >
        <div css={styles.icon}>
          <FontAwesomeIcon
            css={styles.icon}
            icon={icon}
          />
        </div>
      </button>
    </WrapperComponent>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  icon: FONT_AWESOME_ICON.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  tooltip: PropTypes.string,
};

Button.defaultProps = {
  onClick: null,
  type: 'button',
  tooltip: '',
};

export default Button;
