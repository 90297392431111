import { useMemo } from 'react';
import { css } from '@emotion/react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import HomeGoalsCard from '~Home/components/Module/Goals/HomeGoalsCard';
import HomeInsightsCard from '~Home/components/Module/Insights/HomeInsightsCard';
import HomePeopleCard from '~Home/components/Module/People/HomePeopleCard';
import WhatsDueCard from '~Home/components/Module/WhatsDue/WhatsDueCard';
import Meetings from '~Home/components/Module/Meetings';
import HomeEngagementSurveyCard from '~Home/components/Module/EngagmentSurvey/HomeEngagementSurveyCard';
import HomeCelebrateCard from '~Home/components/Module/Celebrate/HomeCelebrateCard';
import HomeRecognitionCard from '~Home/components/Module/Recognition/HomeRecognitionCard';
import { HomeModule } from '~Home/const';
import { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import OldHomeGoalsCard from '~Goals/OldGoals/OldGoalsHome/OldHomeGoalsCard';
import OrgClarity from './OrgClarity';
import TeamClarity from './TeamClarity';

const styles = {
  module: css({
    transitionDuration: '150ms !important',
    '&:hover': {
      boxShadow: '0 4px 8px -2px rgba(28, 42, 55, 0.6); !important',
    },
  }),
};

interface ViewProps extends Omit<LeadrCardProps, 'children'> {
  Module?: (
    typeof HomeCelebrateCard |
    typeof HomeEngagementSurveyCard |
    typeof HomeGoalsCard |
    typeof HomeInsightsCard |
    typeof Meetings |
    typeof OrgClarity |
    typeof HomePeopleCard |
    typeof HomeRecognitionCard |
    typeof TeamClarity |
    typeof WhatsDueCard
  ),
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const View = ({
  Module,
  dragHandleProps,
  ...props
}: ViewProps): JSX.Element => (
  <>
    { Module && (
      <Module
        css={styles.module}
        dragHandleProps={dragHandleProps}
        {...props}
      />
    )}
  </>
);

interface MorphicModuleProps extends Omit<LeadrCardProps, 'children'> {
  type: HomeModule,
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const MorphicModule = ({
  type,
  dragHandleProps,
  ...props
}: MorphicModuleProps): JSX.Element => {
  const cascadingGoals = useFeatureFlag('cascadingGoals');

  const Module = useMemo(() => {
    switch (type) {
      case HomeModule.Celebrate:
        return HomeCelebrateCard;
      case HomeModule.EngagementSurvey:
        return HomeEngagementSurveyCard;
      case HomeModule.Goals:
        if (cascadingGoals) {
          return HomeGoalsCard;
        }
        return OldHomeGoalsCard;
      case HomeModule.Insights:
        return HomeInsightsCard;
      case HomeModule.Meetings:
        return Meetings;
      case HomeModule.OrgClarity:
        return OrgClarity;
      case HomeModule.People:
        return HomePeopleCard;
      case HomeModule.Recognition:
        return HomeRecognitionCard;
      case HomeModule.TeamClarity:
        return TeamClarity;
      case HomeModule.WhatsDue:
        return WhatsDueCard;
      default:
        return undefined;
    }
  }, [cascadingGoals, type]);

  const hookProps = {
    Module,
    dragHandleProps,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MorphicModule;
