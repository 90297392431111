import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';
import ConditionalWrapper from '~Common/components/ConditionalWrapper';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { surveyTypePickerTemplate } from '~Surveys/Components/Drawers/Create/SurveyTypePicker';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { forMobileTinyObject } from '~Common/styles/mixins';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { ChangeEvent } from 'react';
import { useSurveysSearch } from '../../Hooks/useSurveysSearch';
import SurveyViewToggle from '../Buttons/surveyViewToggle';
import SurveyTypeToggle from '../Buttons/surveyTypeToggle';
import { surveyTemplatesTemplate } from '../Drawers/templates/SurveyTemplateDrawer';

const styles = {
  leadrButton: css(
    forMobileTinyObject({
      width: '50%',
    }),
  ),
  toggleTab: (isLimitedAccessUser: boolean) => css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    width: isLimitedAccessUser ? '100%' : '50%',
  })),
  tabNavigationToggleSkeleton: css({
    borderRadius: '0.5rem',
  }, forMobileTinyObject({
    maxWidth: '100%',
  })),
  rightSectionSkeleton: css({
    height: '2.8125rem',
    borderRadius: '0.1875rem',
  }, forMobileTinyObject({
    width: '100%',
    maxWidth: '100%',
  })),
};

interface ViewProps {
  searchText: string,
  onTemplates: () => void,
  onCreateSurvey: () => void,
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
  isLimitedAccessUser: boolean,
  isInformationLoading: boolean,
}

const View = ({
  onCreateSurvey,
  onTemplates,
  isInformationLoading,
  searchText,
  onSearch,
  isLimitedAccessUser,
}: ViewProps): JSX.Element => (
  <ModuleTopbar
    moduleTopbarLayout={isLimitedAccessUser ? 'defaultWithoutRightSection' : 'default'}
    renderTabNavigationToggle={() => (
      <ConditionalWrapper
        renderWrapperCondition={isInformationLoading}
        renderWrapper={(renderChildren) => (
          <SkeletonLoader
            css={styles.tabNavigationToggleSkeleton}
            variant="rectangular"
            renderComponent={() => <>{renderChildren()}</>}
          />
        )}
        renderContents={() => (
          <SurveyTypeToggle toggleTabStyles={styles.toggleTab(isLimitedAccessUser)} />
        )}
      />
    )}
    renderViewToggle={() => <SurveyViewToggle />}
    renderSearchField={() => (
      <LeadrSearchField
        data-test-id="surveysSearchField"
        defaultValue={searchText}
        onChange={onSearch}
      />
    )}
    renderRightSection={!isLimitedAccessUser ? () => (
      <>
        <ConditionalWrapper
          renderWrapperCondition={isInformationLoading}
          renderWrapper={(renderChildren) => (
            <SkeletonLoader
              css={styles.rightSectionSkeleton}
              variant="rectangular"
              renderComponent={() => <>{renderChildren()}</>}
            />
          )}
          renderContents={() => (
            <>
              <LeadrButton
                onClick={onTemplates}
                data-test-id="surveysTemplates"
                variant="ghost"
                css={styles.leadrButton}
              >
                Templates
              </LeadrButton>
              <CreateButton
                itemName="Survey"
                onClick={onCreateSurvey}
                data-test-id="surveysCreateNew"
                css={styles.leadrButton}
              />
            </>
          )}
        />
      </>
    ) : undefined}
  />
);

const SurveysTopbar = (): JSX.Element => {
  const dispatch = useDispatch();
  const { searchText, setSearch } = useSurveysSearch() as { searchText: string, setSearch: (searchText: string) => void };

  const { isLimitedAccess: isLimitedAccessUser, isLoading: isUserDataLoading } = useUserPermissions();
  const [isInformationLoading] = useSkeletonLoaders(isUserDataLoading);

  const hookProps = {
    isInformationLoading,
    isLimitedAccessUser,
    searchText,
    onSearch: (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    onCreateSurvey: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...surveyTypePickerTemplate,
        },
      }));
    },
    onTemplates: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...surveyTemplatesTemplate,
          args: {
            onBackArrow: undefined,
          },
        },
      }));
    },
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default SurveysTopbar;
