import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useFileUploadMutation } from '../fileUpload/mutators';
import { toast } from '~Common/components/Toasts';

const uploadCSV = async ({ csvInformation }) => {
  const URL = `/organizations/${getOrganizationId()}/users/presigned`;

  return postApi(URL, csvInformation);
};

export const useUploadCSV = () => {
  const toastId = useRef(null);

  const { fileUploadMutation } = useFileUploadMutation({ queryKey: [getOrganizationId(), 'admin'] });
  const mutation = useMutation({
    mutationFn: uploadCSV,
    onMutate: () => {
      toastId.current = toast.info('Uploading the CSV...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'The CSV failed to upload. Please try again...',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, { file, onSuccessCallback }) => {
      fileUploadMutation({ url: data.response?.signedUrl, file });
      onSuccessCallback?.();

      toast.update(toastId.current, {
        render: 'The CSV was uploaded successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });
  return { uploadCSVMutation: mutation.mutate, isLoading: mutation.isPending };
};
