import { css, SerializedStyles } from '@emotion/react';
import {
  useState,
  useEffect,
  ChangeEvent,
  RefObject,
} from 'react';

import {
  drawerHeaderFontColor, drawerInputBackground, disabledInputColor, palette,
} from '~Common/styles/colors';
import { TEXT_MAX_LENGTH } from '~Common/const/inputs';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import RequiredIcon from './RequiredIcon';

const style = css`
  background: ${drawerInputBackground};
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  height: auto;
  min-height: unset;

  &:focus {
    outline: none !important;
  }

  ::-webkit-input-placeholder {
    color: ${drawerHeaderFontColor} !important;
  }

  label {
    color: ${palette.neutrals.gray700};
    font-weight: 400;
    margin-bottom: 0;
  }

  input {
    border: unset;
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    color: ${drawerHeaderFontColor};
    height: auto;
    margin: 0;
    padding: 0.3rem 0;
    font-weight: 700;
    font-size: 16px;
    min-height: 30px;
    text-overflow: ellipsis !important;
    width: 100%;
    &:focus {
      box-shadow: unset;
    }
  }
`;

const inputContainer = css`
  display: flex;
  
  align-items: center;
  & svg {
    position: relative;
    margin-right: 10px;
    stroke: ${drawerHeaderFontColor};
  }

`;

const disabledInput = css`
  background: ${disabledInputColor};
  opacity: 0.7;
  border: unset;
`;

const container = css({
  width: '100%',
});

const widths = {
  quarterRow: css`
    width: 20%;
  `,
  halfRow: css`
    width: 49%;
    margin-right: 1%;
  `,
  halfRowNoMargin: css`
    width: 49%;
    margin-right: 0;
  `,
  fullRow: css`
    width: 100%; // Changed to 100%
  `,
};

const labelContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const SIZE_OPTIONS = {
  FULL_ROW: 'fullRow',
  HALF_ROW: 'halfRow',
  HALF_ROW_NO_MARGIN: 'halfRowNoMargin',
  QUARTER_ROW: 'quarterRow',
};

export enum SizeOptions
{
  FULL_ROW = 'fullRow',
  HALF_ROW = 'halfRow',
  HALF_ROW_NO_MARGIN = 'halfRowNoMargin',
  QUARTER_ROW = 'quarterRow',
}

// Omitting size, because apparently size is also a property on the input, but we are never going to use that, and Im not sure how to better do this
export interface DrawerInputProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'>{
  name?: string,
  size?: SizeOptions,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  initialValue?: string | number,
  label?: string,
  disabled?: boolean,
  className?: string,
  inputRef?: RefObject<HTMLInputElement>,
  rightIconType?: () => JSX.Element,
  leftIconType?: () => JSX.Element,
  inputStyles?: SerializedStyles,
  renderLabel?: () => JSX.Element,
  showRequiredIndicator?: boolean,
}

const DrawerInput = ({
  name,
  size = SizeOptions.FULL_ROW,
  onChange,
  initialValue = '',
  label = '',
  disabled = false,
  className = '',
  inputRef,
  rightIconType,
  leftIconType,
  inputStyles,
  renderLabel,
  required,
  showRequiredIndicator = true,
  type = 'text',
  ...props
}: DrawerInputProps): JSX.Element => {
  const [value, setValue] = useState(initialValue);

  const previousInitialValue = usePrevious(initialValue);

  useEffect(() => {
    if (initialValue !== previousInitialValue) {
      setValue(initialValue);
    }
  }, [initialValue, previousInitialValue]);

  const doChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let text = event.target.value;
    if (event.target.value.trim().length === 0) {
      text = event.target.value.trim();
      /* eslint-disable-next-line no-param-reassign */
      event.target.value = event.target.value.trim();
    }
    setValue(text);
    event.persist();
    onChange?.(event);
  };
  return (
    <div
      css={[
        style,
        widths[size],
        inputStyles,
      ]}
      className={className}
    >
      <div css={[container, (disabled ? disabledInput : '')]}>
        <div css={labelContainer}>
          {renderLabel?.()}

          {!renderLabel && label && (
            <label htmlFor={name}>{label}</label>
          )}

          {required && showRequiredIndicator && (
            <RequiredIcon />
          )}
        </div>

        <div
          css={inputContainer}
        >
          {leftIconType?.()}

          <input
            ref={inputRef}
            name={name}
            type={type}
            onChange={doChange}
            value={value}
            disabled={disabled}
            maxLength={TEXT_MAX_LENGTH}
            required={required}
            {...props}
          />
        </div>
      </div>

      {rightIconType?.()}
    </div>
  );
};

export default DrawerInput;
