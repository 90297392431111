import {
  QueryCache,
  QueryClient,
  QueryKey,
  useQuery,
} from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import { DEFAULT_QUERY_OPTIONS } from '~Common/hooks/utils';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      /*
        You can define an errorMessage in the meta object on a query and then this will trigger onError
        Inspiration for this pattern: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
      */
      if (query?.meta?.errorMessage) {
        toast.error(query.meta.errorMessage);
      }
    },
  }),
  defaultOptions: {
    queries: {
      /*
        TODO: Remove this and use the default networkMode if we have time to test and validate it
        https://tanstack.com/query/v4/docs/framework/react/guides/migrating-to-react-query-4#queries-and-mutations-per-default-need-network-connection-to-run
      */
      networkMode: 'offlineFirst',
      ...DEFAULT_QUERY_OPTIONS,
    },
    mutations: {
      /*
        TODO: Remove this and use the default networkMode if we have time to test and validate it
        https://tanstack.com/query/v4/docs/framework/react/guides/migrating-to-react-query-4#queries-and-mutations-per-default-need-network-connection-to-run
      */
      networkMode: 'offlineFirst',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useQueryCache = (queryKey: QueryKey) => useQuery({
  queryKey,
  queryFn: () => queryClient.getQueryData(queryKey),
  staleTime: Infinity,
}).data;
