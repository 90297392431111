import { cloneDeep } from 'lodash';
import { HomeModule, HomeModuleSettings } from '~Home/const';
import { GetHomeSettingsResponse } from '~Home/hooks/useHomeSettings';

export const filterHomeModules = (homeSettingsResponse: GetHomeSettingsResponse): Record<HomeModule, HomeModuleSettings> => {
  const moduleList = Object.values(HomeModule) as string[];
  const newHomeSettingsResponse = cloneDeep(homeSettingsResponse);
  const hasWhatsDueOptions = Object.values(homeSettingsResponse.whatsDue).some((isEnabled: boolean) => isEnabled);

  return Object.entries(newHomeSettingsResponse).filter(([key]) => (
    moduleList.includes(key) && (key !== HomeModule.WhatsDue || hasWhatsDueOptions)
  )).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value as HomeModuleSettings,
  }), {} as Record<HomeModule, HomeModuleSettings>);
};

export const getVisibleModuleList = (moduleSettings: Record<HomeModule, HomeModuleSettings>, columnNumber?: number): HomeModule[] => {
  const visibleModuleList = Object.keys(moduleSettings).filter((key) => (moduleSettings[key as HomeModule].isVisible)).sort() as HomeModule[];

  // Need to allow 0
  if (typeof columnNumber === 'number') {
    return visibleModuleList.filter((key) => (moduleSettings[key].column === columnNumber));
  }

  return visibleModuleList;
};

export const getHiddenModuleList = (moduleSettings: Record<HomeModule, HomeModuleSettings>, columnNumber?: number): HomeModule[] => {
  const hiddenModuleList = Object.keys(moduleSettings).filter((key) => (!moduleSettings[key as HomeModule].isVisible)).sort() as HomeModule[];

  // Need to allow 0
  if (typeof columnNumber === 'number') {
    return hiddenModuleList.filter((key) => (moduleSettings[key].column === columnNumber));
  }

  return hiddenModuleList;
};
