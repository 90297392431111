import { useGenericStore } from '~Common/hooks/useGenericStore';

export const useRequestedLearningAssigneeResults = (): [number, (index: number) => void] => {
  // TODO: Update this when useGenericStore is typed
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value: selectedAssigneeIndex, setValue: setSelectedAssigneeIndex } = useGenericStore({
    module: 'leadr',
    workflow: 'learning',
    feature: 'requested-results',
    defaultValue: 0,
  });

  // TODO: Update this when useGenericStore is typed
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [
    selectedAssigneeIndex,
    setSelectedAssigneeIndex,
  ];
};
