import { css } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import { palette } from '~Common/styles/colors';

const styles = {
  loaderStyles: css({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  }),
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

/**
 * @deprecated Old Loader, we use packages/leadr-frontend/src/common/components/SkeletonLoader.tsx now
 */
const Loader = () => (
  <div css={styles.loaderStyles}>
    <CircularProgress css={styles.circularProgress} />
  </div>
);

Loader.propTypes = {

};

Loader.defaultProps = {

};

export default Loader;
