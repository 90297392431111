import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '~Common/components/Tooltip';

interface CollapseSectionHeaderIconProps {
  isContentVisible: boolean,
}

const CollapseSectionHeaderIcon = ({
  isContentVisible,
}: CollapseSectionHeaderIconProps): JSX.Element => (
  <Tooltip content={isContentVisible ? 'Hide' : 'Show'}>
    <div>
      <FontAwesomeIcon icon={isContentVisible ? faMinus : faPlus} />
    </div>
  </Tooltip>
);

export default CollapseSectionHeaderIcon;
