import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import {
  drawerInputBorder,
  drawerInputDisabled,
  drawerInputText,
  drawerInputBackground,
} from '~Deprecated/ui/styles/colors';
import { palette } from '~Common/styles/colors';
import { EMOTION_CSS_SHAPE, REF_SHAPE } from '~Common/const/proptypes';
import { TEXT_MAX_LENGTH } from '~Common/const/inputs';
import { noop } from '~Deprecated/utils/';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const style = css`
  background: ${drawerInputBackground};
  border: 1px solid ${drawerInputBorder};
  padding: 7px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  align-items: center;
  letter-spacing: 0.5px;
  height: auto;
  min-height: unset;

  &:focus {
    outline: none !important;
  }

  ::-webkit-input-placeholder {
    color: ${drawerInputText} !important;
  }

  label {
    color: ${palette.neutrals.gray500};
    margin-bottom: 0;
  }

  input {
    border: unset;
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    color: ${drawerInputText};
    height: auto;
    margin: 0;
    padding: 0.3rem 0;
    font-weight: 700;
    font-size: 16px;
    min-height: 30px;
    width: 100%;
    
    &:focus {
      box-shadow: unset;
    }
  }
`;

const inputContainer = css`
  display: flex;
  & svg {
    position: relative;
    top: 6px;
    margin-right: 10px;
    stroke: ${drawerInputText};
  }
`;

const disabledInput = css`
  background: ${drawerInputDisabled};
  border: unset;
`;

const widths = {
  quarterRow: css`
    width: 20%;
  `,
  halfRow: css`
    width: 49%;
    margin-right: 1%;
  `,
  halfRowNoMargin: css`
    width: 49%;
    margin-right: 0;
  `,
  fullRow: css`
    width: 100%; // Changed to 100%
  `,
};

export const SIZE_OPTIONS = {
  FULL_ROW: 'fullRow',
  HALF_ROW: 'halfRow',
  HALF_ROW_NO_MARGIN: 'halfRowNoMargin',
  QUARTER_ROW: 'quarterRow',
};

/**
 * @deprecated Old UI
 */
const DrawerInput = ({
  name,
  size,
  onChange,
  initialValue,
  label,
  disabled,
  className,
  inputRef,
  iconType,
  rightIconType,
  inputStyles,
  renderLabel,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  const previousInitialValue = usePrevious(initialValue);

  useEffect(() => {
    if (initialValue !== previousInitialValue) {
      setValue(initialValue);
    }
  }, [initialValue, previousInitialValue]);

  const doChange = (event) => {
    let text = event.target.value;
    if (event.target.value.trim().length === 0) {
      text = event.target.value.trim();
      /* eslint-disable-next-line no-param-reassign */
      event.target.value = event.target.value.trim();
    }
    setValue(text);
    event.persist();
    onChange(event);
  };
  return (
    <div
      css={[
        style,
        widths[size],
        inputStyles,
        (disabled ? disabledInput : ''),
      ]}
      className={className}
    >
      {renderLabel && renderLabel()}
      {!renderLabel && label && (
        <label htmlFor={name}>{label}</label>
      )}
      <div
        css={inputContainer}
      >
        {iconType && (
          <FontAwesomeIcon icon={iconType} size="1x" />
        )}
        <input
          ref={inputRef}
          name={name}
          type="text"
          onChange={doChange}
          value={value}
          disabled={disabled}
          maxLength={TEXT_MAX_LENGTH}
          {...props}
        />
        {rightIconType()}
      </div>
    </div>
  );
};

DrawerInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputRef: REF_SHAPE,
  iconType: PropTypes.string,
  rightIconType: PropTypes.func,
  inputStyles: EMOTION_CSS_SHAPE,
  renderLabel: PropTypes.func,
};

DrawerInput.defaultProps = {
  placeholder: '',
  size: SIZE_OPTIONS.FULL_ROW,
  onChange: () => {
  },
  initialValue: '',
  label: '',
  className: '',
  disabled: false,
  inputRef: null,
  iconType: null,
  rightIconType: noop,
  inputStyles: null,
  renderLabel: null,
};

export default DrawerInput;
