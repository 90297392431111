/* eslint-disable no-bitwise */
import { css } from '@emotion/react';
import {
  SyntheticEvent, useMemo, useState, useCallback, useEffect,
} from 'react';
import {
  faPaperPlane, faTrash, faUserPen, IconDefinition, faArrowRotateRight, faPencil, faUsers, faFileExport,
} from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';

import { GridSelectionModel } from '@mui/x-data-grid';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import Card, { CardSkeleton } from '~Common/V3/components/Card';
import { uniq } from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { nudgeReviewParticipantsProps, useNudgeParticipants } from '~Reviews/V2/Hooks/useNudgeParticipants';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { navigateAction } from '~Deprecated/actions/navigate';
import { REVIEWS_CONTINUE_CREATING_CYCLE } from '~Common/const/routes';
import { useDispatch } from 'react-redux';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { UseMutateFunction } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import {
  ReviewCycleWithParticipants, ReviewDetailsTabs, ParticipantTypeEnum, ReviewCycleDetail, FilterType, FilterTypeTranslations,
  ViewCyclePerspective, ReviewCycleStatusEnum, ReviewCycleStatusEnumConversion,
} from '../Const/types';
import CycleHeader from '../Shared/CycleHeader';
import { MenuItem, useActionMenu } from '../Shared/ActionMenu';
import { useFilterParticipantOnCycle } from '../Hooks/useFilterParticipantOnCycle';
import DataTableFilterBar from '../Shared/DataTable/DataTableFilterBar';
import { FILTER_SEPARATOR } from '../Const/defaults';
import { PAGE_STYLES, BUTTON_STYLES } from '../Const/pageStyles';
import { useUpdateReviewers } from '../Hooks/useUpdateReviewers';
import { buildRadioOptions, convertDataToMenuItemShape, getReopenReviewIds } from '../Const/functions';
import ReviewCycleFinishedBanner from '../Shared/ReviewCycleFinishedBanner';
import ReviewCycleTable from '../Shared/ReviewCycleTable';
import DisplayReopenModal from '../Shared/DisplayReopenModal';
import DisplayReopenModalConfirm from '../Shared/DisplayReopenModalConfirm';
import { useReopenReviews } from '../Hooks/useReopenReviews';
import { useConvertToBarGraphData } from '../Hooks/useConvertToBarGraphData';
import { BarChart, BarGraphData } from '../Charts/BarChart';
import { useCreateCsvInBackground, useGetCSV } from '../Hooks/useGetCSV';

const styles = {
  ...PAGE_STYLES,
  ...BUTTON_STYLES,
  tableCount: css({
    textAlign: 'right',
    color: palette.neutrals.gray500,
    fontSize: '.75rem',
    marginTop: '-1rem',
    marginBottom: '.5rem',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '15.625rem',
  }),
  listSection: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridtemplateColumns: 'repeat(auto-fill)',
    width: '100%',
    padding: '1rem 0',
  }),
  Avatar: css({
    marginRight: '.75rem',
  }),
};

interface ViewProps {
  activeReviewCycle: ReviewCycleWithParticipants,
  changeTab: (event: SyntheticEvent, tab: ReviewDetailsTabs) => void,
  expandedTab: ReviewDetailsTabs,
  reviewsAreLoading: boolean,
  selectionModel: GridSelectionModel,
  setSelectionModel: (rowIds: GridSelectionModel) => void,
  statusMenuItems: MenuItem[][],
  managerMenuItems: MenuItem[][],
  filters: string[],
  doOpen: (event: React.MouseEvent<HTMLElement>) => void,
  onClickCallback: () => void,
  actionMenuProps: {
    anchorEle: HTMLElement | null;
    onClose: () => void;
  },
  cycleMenuItems: MenuItem[][],
  isAdmin: boolean,
  showManagerView: boolean,
  setSingleReviewModel: (value: string[]) => void,
  filterParticipantsOnCycle: ReviewCycleDetail | undefined,
  singleReviewModel: string[],
  handleModalDisplayDeleteCycle: (value: boolean) => void,
  openDeleteCycleModal: boolean,
  nudgeParticipantsMutation: UseMutateFunction<HttpCallReturn<void>, unknown, nudgeReviewParticipantsProps, void>,
  openReopenModal: boolean,
  setopenReopenModal: (value: boolean) => void,
  setOpenReopenModalConfirm: (value: boolean) => void,
  openReopenModalConfirm: boolean,
  reOpenReviews: () => void,
  reOpenTypeTitle: string,
  barGraphData: BarGraphData,
  isAdminCycleDetails: boolean,
  hasExportCSV: boolean,
  hasDeleteCycle: boolean,
}
const View = ({
  activeReviewCycle,
  changeTab,
  showManagerView,
  isAdmin,
  doOpen,
  expandedTab,
  onClickCallback,
  actionMenuProps,
  cycleMenuItems,
  statusMenuItems,
  managerMenuItems,
  reviewsAreLoading,
  filters,
  setSingleReviewModel,
  filterParticipantsOnCycle,
  selectionModel,
  setSelectionModel,
  singleReviewModel,
  handleModalDisplayDeleteCycle,
  openDeleteCycleModal,
  nudgeParticipantsMutation,
  openReopenModal,
  setopenReopenModal,
  setOpenReopenModalConfirm,
  openReopenModalConfirm,
  reOpenReviews,
  reOpenTypeTitle,
  barGraphData,
  isAdminCycleDetails,
  hasExportCSV,
  hasDeleteCycle,
}: ViewProps): JSX.Element => (
  <>
    <ReviewCycleFinishedBanner
      reviewCycle={activeReviewCycle}
    />
    <Card
      css={styles.baseCard(false)}
      renderContents={() => (
        <>
          <CycleHeader
            cycleTitle={activeReviewCycle?.name}
            cycleDecriptionHTML={activeReviewCycle?.description}
            assesmentPeriodEnd={activeReviewCycle?.assessmentPeriodEnd}
            assesmentPeriodStart={activeReviewCycle?.assessmentPeriodStart}
            reviewDueBy={activeReviewCycle?.assessmentDueDate}
            signOffDueBy={activeReviewCycle?.signOffDueDate}
            type={activeReviewCycle?.typeEnum}
            totalCount={activeReviewCycle?.participants?.length ?? 0}
            completedCount={Math.round((activeReviewCycle?.participants?.length ?? 0 * (activeReviewCycle?.completionPct ?? 0 / 100)))}
            completionPct={activeReviewCycle?.completionPct ?? 0}
            doOpen={doOpen}
            onClickCallback={onClickCallback}
            actionMenuProps={actionMenuProps}
            menuItems={cycleMenuItems}
            viewCyclePerspective={ViewCyclePerspective.Publish}
            removeCardStyles
            cycleStatus={ReviewCycleStatusEnum[activeReviewCycle.statusEnum] as keyof typeof ReviewCycleStatusEnumConversion}
            showProgressBar={!showManagerView}
            hasExportCSV={hasExportCSV}
            hasDeleteCycle={hasDeleteCycle}
          />
          <div>
            <DataTableFilterBar
              expandedTab={expandedTab}
              changeTab={changeTab}
              statusMenuItems={statusMenuItems}
              managerMenuItems={managerMenuItems}
              isLoading={reviewsAreLoading}
              filters={filters}
              isAdmin={isAdmin}
            />
            {expandedTab === ReviewDetailsTabs.Reviewees && (
            <>
              {reviewsAreLoading && (
              <MultipleSkeletonLoaders
                css={styles.listSection}
                numberOfSkeletons={1}
                renderSkeletonItem={() => (
                  <CardSkeleton css={styles.cardSkeleton} />
                )}
              />
              )}
              {!reviewsAreLoading && (
                <ReviewCycleTable
                  activeReviewCycle={activeReviewCycle}
                  setSingleReviewModel={setSingleReviewModel}
                  filterParticipantsOnCycle={filterParticipantsOnCycle}
                  showManagerView={showManagerView}
                  selectionModel={selectionModel}
                  setSelectionModel={setSelectionModel}
                  singleReviewModel={singleReviewModel}
                  handleModalDisplayDeleteCycle={handleModalDisplayDeleteCycle}
                  openDeleteCycleModal={openDeleteCycleModal}
                  nudgeParticipantsMutation={nudgeParticipantsMutation}
                />
              )}
            </>
            )}
            {expandedTab === ReviewDetailsTabs.Progress_Chart && isAdmin && isAdminCycleDetails && (
            <BarChart
              data={barGraphData}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    max: 100,
                    title: {
                      text: 'Progress Percentage',
                      display: true,
                    },
                  },
                },
              }}
              tooltipOptions={{
                callbacks: {
                  // @ts-expect-error : There's something funky about the typing here.
                  footer: (ctx: { formattedValue: string; raw: { participantInfo: string; noParticipantInfo: string; }; }[]) => {
                    if (ctx[0]?.formattedValue === '0') {
                      return (
                        ctx[0]?.raw?.noParticipantInfo
                      );
                    }
                    return (
                      ctx[0]?.raw?.participantInfo ?? '');
                  },
                  // @ts-expect-error - Need to hide this
                  title: () => null,
                  label(context) {
                    return `${Math.round(context.parsed.y)}%`;
                  },
                },
                footerMarginTop: 8,
              }}
            />
            )}
            <DisplayReopenModal
              open={openReopenModal}
              handleModalDisplay={setopenReopenModal}
              filterParticipantsOnCycle={filterParticipantsOnCycle as unknown as ReviewCycleDetail}
            />
            <DisplayReopenModalConfirm
              open={openReopenModalConfirm}
              handleModalDisplay={setOpenReopenModalConfirm}
              reOpenReviews={reOpenReviews}
              reOpenTypeTitle={reOpenTypeTitle}
            />
          </div>
        </>
      )}
    />

  </>
);
export interface menuItemsProps {
  text: string,
  icon: IconDefinition,
  onClick: () => void,
}
interface ReviewCycleLayoutProps {
  activeReviewCycle?: ReviewCycleDetail,
  searchText: string,
  showManagerView?: boolean,
}

const ReviewCycleLayout = ({
  activeReviewCycle,
  searchText,
  showManagerView = false,
}: ReviewCycleLayoutProps): JSX.Element => {
  const [expandedTab, setExpandedTab] = useQueryParamState<ReviewDetailsTabs>('reviews', 'tab', ReviewDetailsTabs.Reviewees);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [singleReviewModel, setSingleReviewModel] = useState<string[]>([]);
  const [openFinishModal, setOpenCompleteModal] = useState(false);
  const [openDeleteCycleModal, setOpenDeleteCycleModal] = useState(false);
  const nudgeParticipantsMutation = useNudgeParticipants();
  const [openReopenModal, setopenReopenModal] = useState(false);
  const { isAdmin } = useUserPermissions();
  const [openReopenModalConfirm, setOpenReopenModalConfirm] = useState(false);
  const reOpenReviewsMutation = useReopenReviews();
  const barGraphData = useConvertToBarGraphData(activeReviewCycle as ReviewCycleDetail);
  const history = useHistory();
  const isAdminCycleDetails = history.location.pathname.includes('reviews/admin/cycle/');

  const { doOpen, ...actionMenuProps } = useActionMenu();

  const onClickCallback = useCallback(() => {
    actionMenuProps.onClose();
  }, [actionMenuProps]);

  const changeTab = (evt: SyntheticEvent, newTab: ReviewDetailsTabs): void => {
    setExpandedTab(newTab);
  };

  const handleModalDisplayDeleteCycle = (value: boolean): void => {
    setOpenDeleteCycleModal(value);
  };

  useEffect(() => {
    // This will need to be updated when we add more tabs, potentially
    if (!isAdminCycleDetails || !window.location.search) {
      setExpandedTab(ReviewDetailsTabs.Reviewees);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminCycleDetails]);
  const [filters, setFilters] = useQueryParamState<string[]>('reviews', 'filters', []);
  const [filterType, filterValue] = filters.reduce<[FilterType[], string[]]>(([type, value], joined) => {
    const [newType, newValue] = joined.split(FILTER_SEPARATOR);
    type.push(newType as FilterType);
    value.push(newValue);
    return [uniq(type), value];
  }, [[], []]);

  const handleClickFilter = useCallback((clickedFilterType: FilterType, clickedFilterValue: string) => {
    if (clickedFilterType === FilterType.All) {
      const tempFilters = filters;
      const filtersToRemove = tempFilters.filter((filter) => (filter.startsWith(clickedFilterValue)));
      filtersToRemove.forEach((filter) => {
        const indexFilter = tempFilters.indexOf(filter.toString());
        tempFilters.splice(indexFilter, 1);
      });

      setFilters([...tempFilters]);
      return;
    }

    let resultFilters: string[] = [];

    if (typeof filters === 'string') {
      resultFilters = [filters];
    } else {
      resultFilters = Array.from(filters);
    }
    const clickedFilter = `${clickedFilterType}${FILTER_SEPARATOR}${clickedFilterValue}`;
    const indexFilter = resultFilters.indexOf(clickedFilter);

    if (indexFilter === -1) {
      resultFilters.push(clickedFilter);
    } else {
      resultFilters.splice(indexFilter, 1);
    }
    setFilters([...resultFilters]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const filterParticipantsOnCycle = useFilterParticipantOnCycle(activeReviewCycle, searchText, filterType, filterValue);

  const getOnlyParticipantManagers = useMemo(() => filterParticipantsOnCycle?.reviews?.flatMap(
    (review) => review?.participants?.filter((currentValue) => (currentValue.participantTypeEnum === ParticipantTypeEnum.Reviewer
      || currentValue.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer))
      .map((currentValue) => ({
        text: `${currentValue.firstName} ${currentValue.lastName}`,
        icon: '',
        onClick: () => {
          handleClickFilter(FilterType.Managers, `${currentValue.firstName} ${currentValue.lastName}`);
        },
      })),
  ), [filterParticipantsOnCycle?.reviews, handleClickFilter]) as unknown as MenuItem[];

  const getOnlyParticipantStatuses = useMemo(() => {
    const statuses = filterParticipantsOnCycle?.reviews.flatMap((review) => [review.reviewerStatus, review.secondaryReviewerStatus, review.revieweeStatus]);

    const statusMenuItems = statuses?.map((status) => {
      const tempMenuItem: MenuItem = {
        text: status,
        icon: '' as IconProp,
        onClick: () => {
          handleClickFilter(FilterType.Status, status);
        },
      };

      return tempMenuItem;
    });
    return statusMenuItems;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParticipantsOnCycle?.reviews, handleClickFilter]) as unknown as MenuItem[];

  const addMenuReset = (data: MenuItem[], text: keyof typeof FilterTypeTranslations): MenuItem[] => {
    const paramMenuItems = data ?? [];
    const resetMenu = {
      text: `All ${text}`,
      icon: '' as IconProp,
      onClick: () => {
        handleClickFilter(FilterType.All, FilterTypeTranslations[text]);
      },
    };
    paramMenuItems.unshift(resetMenu);

    return paramMenuItems;
  };

  // Time to add in the reset filter
  const participantManagersMenuItems = addMenuReset(getOnlyParticipantManagers, 'Managers');
  const participantStatusesMenuItems = addMenuReset(getOnlyParticipantStatuses, 'Statuses');
  // Now that we have the menu set - let's convert to what we need
  const managerMenuItems = convertDataToMenuItemShape(participantManagersMenuItems);
  const statusMenuItems = convertDataToMenuItemShape(participantStatusesMenuItems);

  const updateReviewerseMutation = useUpdateReviewers();

  const [showSkeleton] = useSkeletonLoaders(!activeReviewCycle);
  const dispatch = useDispatch();

  const getReviewsCSV = useGetCSV();
  const createReviewsCSVInBackground = useCreateCsvInBackground();
  const cycleIsFinished = activeReviewCycle?.statusEnum === ReviewCycleStatusEnum.Finished;
  const reOpenTypeTitle = cycleIsFinished ? 'Are you sure you want to reopen this Review Cycle?' : 'Are you sure you want to reopen these Reviews?';
  const { someReviewsCompleted } = buildRadioOptions(activeReviewCycle as unknown as ReviewCycleDetail);

  const menuItems = [];

  if (isAdmin) {
    menuItems.unshift({
      text: 'Delete Review Cycle',
      icon: faTrash,
      onClick: () => {
        handleModalDisplayDeleteCycle(true);
      },
    });

    if (someReviewsCompleted && activeReviewCycle?.statusEnum !== ReviewCycleStatusEnum.Finished) {
      menuItems.unshift({
        text: 'Reopen Review(s)',
        icon: faArrowRotateRight,
        onClick: () => {
          setopenReopenModal(true);
        },
      });
    }
    if (activeReviewCycle?.statusEnum === ReviewCycleStatusEnum.Finished) {
      menuItems.unshift({
        text: 'Reopen Cycle',
        icon: faArrowRotateRight,
        onClick: () => {
          if (someReviewsCompleted) {
            setopenReopenModal(true);
          } else {
            setOpenReopenModalConfirm(true);
          }
        },
      });
    }
    if (activeReviewCycle?.statusEnum !== ReviewCycleStatusEnum.Finished) {
      menuItems.unshift(
        {
          text: 'Edit Cycle Details',
          icon: faPencil,
          onClick: () => {
            dispatch(navigateAction({
              pathname: `${REVIEWS_CONTINUE_CREATING_CYCLE}/setup`,
              params: {
                cycleId: activeReviewCycle?.uid ?? '',
              },
            }));
          },
        },
        {
          text: 'Edit Participants',
          icon: faUserPen,
          onClick: () => {
            dispatch(navigateAction({
              pathname: `${REVIEWS_CONTINUE_CREATING_CYCLE}/participants`,
              params: {
                cycleId: activeReviewCycle?.uid ?? '',
              },
            }));
          },
        },
        {
          text: 'Update Reviewers',
          icon: faUsers,
          onClick: () => {
            updateReviewerseMutation({ cycleId: activeReviewCycle?.uid ?? '' });
          },
        },
        {
          text: 'Send Reminder',
          icon: faPaperPlane,
          onClick: () => {
            nudgeParticipantsMutation({ cycleId: activeReviewCycle?.uid, reviewIDs: [] });
          },
        },
      );
    }
  }

  const isReviewCycleFinished = activeReviewCycle?.statusEnum === ReviewCycleStatusEnum.Finished;
  const enableReviewExportBackgroundJob = useFeatureFlag('reviewExportBackgroundJob');
  if (isReviewCycleFinished || someReviewsCompleted) {
    const index = menuItems.length - 1;
    menuItems.splice(index, 0, {
      text: 'Export CSV',
      icon: faFileExport,
      onClick: () => {
        if (enableReviewExportBackgroundJob) {
          createReviewsCSVInBackground({ cycleId: activeReviewCycle?.uid ?? '' });
        } else {
          getReviewsCSV({ cycleId: activeReviewCycle?.uid ?? '' });
        }
      },
    });
  }
  const hasExportCSV = menuItems.some((item) => item.text === 'Export CSV');
  const hasDeleteCycle = menuItems.some((item) => item.text === 'Delete Review Cycle');

  const cycleMenuItems = [menuItems];

  const reOpenReviews = (): void => {
    const { allReviewIDs } = getReopenReviewIds(activeReviewCycle as ReviewCycleDetail);
    reOpenReviewsMutation({ reviewIDs: allReviewIDs }, { onSuccess: () => setOpenReopenModalConfirm(false) });
  };

  const hookProps = {
    activeReviewCycle: activeReviewCycle as unknown as ReviewCycleWithParticipants,
    changeTab,
    expandedTab,
    reviewsAreLoading: showSkeleton,
    openFinishModal,
    selectionModel,
    setSelectionModel,
    statusMenuItems,
    managerMenuItems,
    filters,
    doOpen,
    onClickCallback,
    actionMenuProps,
    setOpenCompleteModal,
    isAdmin,
    showManagerView,
    setSingleReviewModel,
    filterParticipantsOnCycle,
    singleReviewModel,
    handleModalDisplayDeleteCycle,
    openDeleteCycleModal,
    nudgeParticipantsMutation,
    cycleMenuItems,
    openReopenModal,
    setopenReopenModal,
    setOpenReopenModalConfirm,
    openReopenModalConfirm,
    reOpenReviews,
    reOpenTypeTitle,
    barGraphData,
    isAdminCycleDetails,
    hasExportCSV,
    hasDeleteCycle,
  };

  if (!activeReviewCycle) {
    return <></>;
  }
  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ReviewCycleLayout };
export default ReviewCycleLayout;
