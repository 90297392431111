/* eslint-disable react/no-unused-prop-types */
import { css } from '@emotion/react';
import {
  faUsers, faBullseyeArrow, faCommentsAlt, faWavePulse,
} from '@fortawesome/pro-light-svg-icons';
import { PeoplePerspective, SetDisplay } from '~Insights/const/types';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { DASHBOARD_CARD_COMPONENTS, CARD_STYLES } from '~Insights/const/cardStyles';

import Card from '~Common/V3/components/Card';
import { MetricsForGroup, usePeopleMetrics } from '~Insights/hooks/usePeopleInsightsData';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useInsightsUserPerspective, UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { DonutSidebars } from './DonutSidebars';
import { Expanded } from './Expanded';
import InsightCardTitle from '../../InsightCardTitle';
import ExpandButton from '../../buttons/ExpandButton';

export const dashboardToggleMenuOptionsPeopleMetric = [
  {
    icon: faUsers,
    text: '1:1\'s',
    value: PeoplePerspective.OneOnOne,
    'data-test-id': 'insightsPeopleOneOnOne',
  },
  {
    icon: faBullseyeArrow,
    text: 'Goals',
    value: PeoplePerspective.Goals,
    'data-test-id': 'insightsPeopleGoals',
  },
  {
    icon: faCommentsAlt,
    text: 'Feedback',
    value: PeoplePerspective.Feedback,
    'data-test-id': 'insightsPeopleFeedback',
  },
  {
    icon: faWavePulse,
    text: 'Activity',
    value: PeoplePerspective.Activity,
    'data-test-id': 'insightsPeopleActivity',
  },
];

const styles = {
  dashboard: css({
    ...CARD_STYLES.dashboard,
    padding: '0',
  }),

  box: css({
    display: 'flex',
  }),

  ...DASHBOARD_CARD_COMPONENTS,
};
export interface SharedProps {
  toggleMenuOptions: LeadrToggleTextTabProps[],
  changeToggleMenu: (value: PeoplePerspective) => void,
  toggleMenuValue: PeoplePerspective,
  isLoading: boolean,
  isMobile:boolean,
}

interface DashboardProps extends SharedProps {
  employeeCount: number,
  collectiveInsights: MetricsForGroup,
  userPerspective: UserPerspective,
}

const DashboardView = ({
  toggleMenuOptions,
  changeToggleMenu,
  toggleMenuValue,
  employeeCount,
  collectiveInsights,
  isMobile,
  userPerspective,
}: DashboardProps): JSX.Element => (
  <Card
    css={[styles.dashboard, styles.dashboardPeople]}
    renderContents={() => (
      <div css={styles.dashboardContainer}>
        <div css={styles.main}>
          <div css={styles.header(false)}>
            <ExpandButton css={styles.expandButton(isMobile)} cardKey="people" />
            <div css={styles.titleAndSubtitle(isMobile)}>
              <InsightCardTitle>People Metrics</InsightCardTitle>
              {employeeCount > 0 && (
                <p>
                  {employeeCount}
                  &nbsp;Employees
                </p>
              )}
            </div>
            {isMobile && (
              <Dropdown
                items={toggleMenuOptions as DropdownItem[]}
                onChange={(event) => changeToggleMenu(event.target.value as PeoplePerspective)}
                value={toggleMenuValue}
              />
            )}
            {!isMobile && (
              <LeadrToggleTabs
                value={toggleMenuValue}
                onChange={(e, newValue) => changeToggleMenu(newValue as PeoplePerspective)}
              >
                {toggleMenuOptions.map((toggleMenuOption) => (
                  <LeadrToggleTabs.TextTab
                    data-test-id={toggleMenuOption['data-test-id']}
                    key={toggleMenuOption.value}
                    text={toggleMenuOption.text}
                    value={toggleMenuOption.value}
                  />
                ))}
              </LeadrToggleTabs>
            )}
          </div>
          <div>
            {collectiveInsights && (
              <DonutSidebars
                toggleMenuValue={toggleMenuValue}
                userPerspective={userPerspective}
                isMobile={isMobile}
                {...collectiveInsights}
              />
            )}
            {!collectiveInsights && (
              <p
                css={styles.emptyStateText}
              >
                No employees report directly to you.
              </p>
            )}
          </div>
        </div>
      </div>
    )}
  />
);

interface PeopleCardProps {
  size: 'dashboard' | 'expanded',
}

const PeopleCard = ({
  size,
  ...props
}: PeopleCardProps): JSX.Element => {
  const [toggleMenuValue, setToggleMenuValue] = useQueryParamState<PeoplePerspective>('insights', 'people', PeoplePerspective.OneOnOne);
  const { userPerspective } = useInsightsUserPerspective();
  const { insightsData, isLoading: queryLoading } = usePeopleMetrics();
  const [isLoading] = useSkeletonLoaders(queryLoading);

  const changeToggleMenu = (value: PeoplePerspective): void => {
    if (value) {
      setToggleMenuValue(value);
    }
  };

  const [setDisplay] = useQueryParamState<SetDisplay>('insights', 'setDisplay', SetDisplay.Desktop);
  const checkMobileQuery = useIsMobileQuery();

  if (!insightsData) {
    return (
      <SkeletonLoader
        variant="rectangular"
        css={[styles.dashboardSkeleton, styles.dashboardPeople]}
        renderComponent={() => <div />}
      />
    );
  }

  const hookProps = {
    toggleMenuValue,
    changeToggleMenu,
    isLoading,
    isMobile: setDisplay === SetDisplay.Mobile || checkMobileQuery === true,
  };

  if (size === 'expanded') {
    return (
      <Expanded
        {...hookProps}
        toggleMenuOptions={dashboardToggleMenuOptionsPeopleMetric}
        userPerspective={userPerspective}
        insightsData={insightsData}
        {...props}
      />
    );
  }

  if (size === 'dashboard') {
    return isLoading ? (
      <SkeletonLoader
        variant="rectangular"
        css={[styles.dashboardSkeleton, styles.dashboardPeople]}
        renderComponent={() => <div />}
      />
    ) : (
      <DashboardView
        {...hookProps}
        css={styles.dashboardPeople}
        employeeCount={insightsData?.orgUserInsights?.length ?? 0}
        toggleMenuOptions={dashboardToggleMenuOptionsPeopleMetric}
        collectiveInsights={insightsData.collectiveInsights}
        userPerspective={userPerspective}
        {...props}
      />
    );
  }

  return <></>;
};

export { DashboardView };
export default PeopleCard;
