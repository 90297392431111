import { createAction } from '@reduxjs/toolkit';
import { takeLatest, call } from 'redux-saga/effects';

import { callApi } from '~Deprecated/sagas/callApi';
import { updatePersonalityDetailsById } from '~Deprecated/services/personalityService';
import { sagaMiddleware } from '~Deprecated/store';
import { registerAction } from '~Deprecated/reducers/personalityDetalis';
import { mutateState } from '~Deprecated/utils/reduxUtils';

const editPersonalityDetailsById = createAction('LEADR.EDIT_PERSONALITY_DETAILS_BY_ID');
const editPersonalityDetailsByIdSuccessAction = createAction('LEADR.EDIT_PERSONALITY_DETAILS_BY_ID_SUCCESS');
const editPersonalityDetailsByIdFailureAction = createAction('LEADR.EDIT_PERSONALITY_DETAILS_BY_ID_FAILURE');

function* onEditPersonalityDetailsById(action) {
  yield call(callApi, {
    api: updatePersonalityDetailsById,
    payload: action.payload,
    successAction: editPersonalityDetailsByIdSuccessAction,
    failureAction: editPersonalityDetailsByIdFailureAction,
  });
}

const onEditPersonalityDetailsByIdSuccess = (state) => mutateState(state, (draftState) => {
  draftState.hasUpdateCallPending = 'Success';
  return draftState;
});

const onEditLoadPersonalityDetailsByIdFailure = (state) => mutateState(state, (draftState) => {
  draftState.hasUpdateCallPending = 'Error';
  return draftState;
});

const onEditPersonalityDetailsBegin = (state) => mutateState(state, (draftState) => {
  draftState.hasUpdateCallPending = 'InProgress';
  return draftState;
});

function* personalityDetailsWatcher() {
  yield takeLatest(editPersonalityDetailsById, onEditPersonalityDetailsById);
}

registerAction(editPersonalityDetailsByIdSuccessAction, onEditPersonalityDetailsByIdSuccess);
registerAction(editPersonalityDetailsByIdFailureAction, onEditLoadPersonalityDetailsByIdFailure);
registerAction(editPersonalityDetailsById, onEditPersonalityDetailsBegin);
sagaMiddleware.run(personalityDetailsWatcher);

export {
  editPersonalityDetailsById,
};
