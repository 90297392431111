import { css } from '@emotion/react';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  dragHandle: css({
    display: 'flex',
    justifyContent: 'center',
    justifySelf: 'center',
    color: palette.neutrals.gray700,
    padding: '4px',
    '&:hover': {
      cursor: 'grab',
    },
    '&:focus': {
      border: `1px solid ${palette.brand.sky}`,
      borderRadius: '.3125rem',
    },
  }),
};

interface DragHandleProps extends ComponentProps<'div'> {
  dragHandleProps: Record<string, unknown>,
}

const DragHandle = ({
  dragHandleProps,
  ...props
}: DragHandleProps): JSX.Element => (
  <div
    css={styles.dragHandle}
    {...dragHandleProps}
    {...props}
  >
    <FontAwesomeIcon icon={faGripDotsVertical} />
  </div>
);

export default DragHandle;
