import { useMemo } from 'react';

import { useList, useQueryConfig } from '~Common/queries';
import { buildFlattener, buildInfiniteFlattener } from '~Common/hooks/utils';
import { COLLEAGUE_TYPES } from '~Meetings/const/colleagueTypes';

const SEARCH_THRESHOLD = 3;
const infiniteFlatteners = buildInfiniteFlattener([...Object.values(COLLEAGUE_TYPES), 'NONE']);

const listConfig = {
  buildApi: ({ currentUserOrgId }) => `/organizations/${currentUserOrgId}/huddles/suggestions`,
  buildQueryKey: ({ currentUserOrgId }) => [currentUserOrgId, 'huddles', 'suggestions'],
};

const byTypeFlattener = (result) => {
  const needsCoachingItems = result.response.items;
  const coachingItems = result.response.coachings;

  const { list: needsCoachingList, listData: needsCoachingListData } = needsCoachingItems.reduce((acc, item) => {
    acc.list.push(item);
    // eslint-disable-next-line no-param-reassign
    acc.listData[item] = {
      orgUserId: item,
      recommendedDuration: result.response.recommendedDuration,
      recommendedFrequency: result.response.recommendedFrequency,
      colleagueType: result.response.colleagueType,
    };

    return acc;
  }, { list: [], listData: {} });

  const { list: hasCoachingList, listData: hasCoachingListData } = coachingItems.reduce((acc, item) => {
    acc.list.push(item.orgUserId);
    // eslint-disable-next-line no-param-reassign
    acc.listData[item.orgUserId] = {
      ...item,
      recommendedDuration: result.response.recommendedDuration,
      recommendedFrequency: result.response.recommendedFrequency,
      colleagueType: result.response.colleagueType,
    };

    return acc;
  }, { list: [], listData: {} });

  return {
    list: [...needsCoachingList, ...hasCoachingList],
    listData: { ...needsCoachingListData, ...hasCoachingListData },
  };
};

const useCoachingSuggestionsByType = ({ colleagueType, enabled = true }) => {
  const queryConfig = useQueryConfig({
    ...listConfig,
    listFlattener: byTypeFlattener,
    fetchParams: { colleagueType },
    infiniteFlattener: infiniteFlatteners[colleagueType ?? 'NONE'],
  });

  return useList({
    queryConfig,
    queryOverride: { enabled },
  });
};

const useCoachingSuggestionsSearch = ({ searchText }) => {
  // The user search API requires the search string to be at least 3 characters long
  const shouldSearch = searchText.length >= SEARCH_THRESHOLD;

  const queryConfig = useQueryConfig({
    buildApi: ({ currentUserOrgId }) => `/organizations/${currentUserOrgId}/huddles/user-search`,
    buildQueryKey: ({ currentUserOrgId }) => [currentUserOrgId, 'huddles', 'user-search'],
    listFlattener: buildFlattener({ idName: 'orgUserId' }),
    fetchParams: { searchText, includeColleagueType: true },
    infiniteFlattener: infiniteFlatteners.NONE,
  });

  const { list, listData, isLoading } = useList({
    queryConfig,
    queryOverride: { enabled: shouldSearch },
  });

  const searchData = useMemo(() => (
    list.reduce((acc, id) => {
      const { colleagueType } = listData[id];

      acc[colleagueType].list.push(id);
      // eslint-disable-next-line no-param-reassign
      acc[colleagueType].listData[id] = {
        ...listData[id],
      };
      // eslint-disable-next-line no-param-reassign
      acc.count += 1;

      return acc;
    }, {
      [COLLEAGUE_TYPES.DIRECT_REPORTS]: { list: [], listData: {} },
      [COLLEAGUE_TYPES.LEADERS]: { list: [], listData: {} },
      [COLLEAGUE_TYPES.PEERS]: { list: [], listData: {} },
      [COLLEAGUE_TYPES.SKIP_LEVELS]: { list: [], listData: {} },
      [COLLEAGUE_TYPES.OTHERS]: { list: [], listData: {} },
      count: 0,
    })
  ), [list, listData]);

  return {
    isLoading,
    searchData,
    isSearching: shouldSearch,
  };
};

const useHasUnfulfilledDirectReport = () => {
  const { listData } = useCoachingSuggestionsByType({ colleagueType: COLLEAGUE_TYPES.DIRECT_REPORTS });

  return useMemo(() => (
    Object.values(listData).some((data) => !data.factoryId)
  ), [listData]);
};

export {
  useHasUnfulfilledDirectReport,
  useCoachingSuggestionsByType,
  useCoachingSuggestionsSearch,
};
