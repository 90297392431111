import { useSelector } from 'react-redux';
import { getUserId } from '~Common/utils/localStorage';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { getFeatureFlags } from '~Deprecated/selectors/featureFlags/getFeatureFlags';

export function useRecognitionPrivileges(): boolean {
  const { user } = useUserProfile(getUserId() ?? '');
  const isRecognitionEnabled = user?.orgDetails?.enableRecognition;
  const flags = useSelector(getFeatureFlags);
  const featureRecognition = flags?.featureRecognition;

  const canSeeRecognition = isRecognitionEnabled && featureRecognition;

  return canSeeRecognition as boolean;
}
