import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';

/**
 * @deprecated Old UI
 */
const AnimatedModal = ({
  children, isOpen, onClose,
}) => (
  <Modal
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={!!isOpen}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 300,
    }}
  >
    <Fade in={isOpen}>
      <div>
        {children}
      </div>
    </Fade>
  </Modal>
);

AnimatedModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AnimatedModal.defaultProps = {

};

export default AnimatedModal;
