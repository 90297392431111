import { css, SerializedStyles } from '@emotion/react';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  settingsSection: css({
    maxWidth: '31.25rem',
  }),
  divider: css({
    borderColor: palette.neutrals.gray200,
  }),
};

interface SettingsSectionProps extends ComponentProps<'section'> {
  renderSection: (settingsSectionStyles: SerializedStyles) => JSX.Element,
}

const SettingsSection = forwardRef(({
  renderSection,
  ...props
}: SettingsSectionProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => (
  <section ref={ref} {...props}>
    {renderSection(styles.settingsSection)}
  </section>
));

const Skeleton = ({ ...props }): JSX.Element => (
  <div {...props}>
    <SkeletonLoader
      renderComponent={() => (
        <div>Header text</div>
      )}
    />
    <SkeletonLoader
      renderComponent={() => (
        <div>Some descriptive text that goes under the heading section</div>
      )}
    />
    <SkeletonLoader
      renderComponent={() => (
        <LeadrButton
          data-test-id="settingsSkeletonLoader"
        >
          Button text
        </LeadrButton>
      )}
    />
    <SkeletonLoader
      renderComponent={() => (
        <LeadrButton
          data-test-id="settingsSkeletonLoader"
        >
          Button text
        </LeadrButton>
      )}
    />
  </div>
);

export { Skeleton as SettingsSectionSkeleton };

export default SettingsSection;
