import Button from '../Button';
import { RenderConfirmationButtonsParams } from './DeleteConfirmationPopover';

interface DeleteConfirmationButtonsProps extends RenderConfirmationButtonsParams {
  onDelete: () => void,
}

/**
 * @deprecated Use new generic ConfirmationButtons component
 */
const DeactivateConfirmationButtons = ({
  informationStyles,
  optionStyles,
  popoverButtonStyles,
  onDelete,
}: DeleteConfirmationButtonsProps): JSX.Element => (
  <>
    <Button
      variant="text"
      renderContents={() => <>Are You Sure?</>}
      css={[popoverButtonStyles, informationStyles]}
      disabled
    />
    <Button
      variant="text"
      renderContents={() => <>Yes, Deactivate</>}
      css={[popoverButtonStyles, optionStyles]}
      onClick={onDelete}
    />
    <Button
      variant="text"
      renderContents={() => <>No, Cancel</>}
      css={[popoverButtonStyles, optionStyles]}
    />
  </>
);

export default DeactivateConfirmationButtons;
