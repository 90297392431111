import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getStorageItem } from '~Common/utils/localStorage';
import { destructive, palette } from '~Common/styles/colors';
import { useImpersonationStore } from '~Deprecated/hooks/useImpersonationStore';

const styles = {
  container: css`
    padding: 0.5rem;
    background: orange;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    transition: transform 300ms ease-in;
    z-index: 99999;
    
    @media print {
      display: none;
    }
  `,
  flex: css`
    flex: 1;
  `,
  btnContainer: css`
    padding: 0.5rem;
    background: orange;
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 300ms ease-in;
    z-index: 99999;
  `,
  endSessionButton: css`
    &, &.MuiButton-root {
      text-transform: unset;
      background: ${destructive};
      color: ${palette.neutrals.white};
      //padding: 0 0.25rem;
      margin-inline-start: 1rem;
      border-radius: 0.25rem;
      min-width: unset;
      border: none;
      padding: 0.25rem;
    }
  `,
  toggleBtn: css`
    justify-self: flex-end;
    margin-inline-start: 0.5rem;
  `,
  iconButton: css`
    &, &.MuiButton-root {
      min-width: 36px;
      border: none;
      background: transparent;
    }
  `,
  icon: css`
    width: 22px !important;
    height: 22px !important;
  `,
  open: css`
    transform: translateY(0);
  `,
  closed: css`
    transform: translateY(calc(-100% + 8px));
    
    &:hover {
      transform: translateY(0);
    }
  `,
  iconClosed: css`
    transform: translateY(-100%);
  `,
};

const View = ({
  name, email, onEndImpersonation, onToggleBanner, isOpen,
}) => (
  <>
    <div css={[styles.container, (isOpen ? styles.open : styles.closed)]}>
      <div css={styles.flex} />
      <span>
        { `You are currently impersonating ${name} (${email})` }
      </span>
      <button css={styles.endSessionButton} onClick={onEndImpersonation}>Stop Impersonating</button>
      <div css={styles.flex} />
      <button css={[styles.toggleBtn, styles.iconButton]} onClick={onToggleBanner}>
        <FontAwesomeIcon
          css={styles.icon}
          icon={faChevronUp}
        />
      </button>
    </div>
    <div css={[styles.btnContainer, (isOpen ? styles.iconClosed : styles.collapsedOpen)]}>
      <button css={styles.iconButton} onClick={onToggleBanner}>
        <FontAwesomeIcon
          css={styles.icon}
          icon={faChevronDown}
        />
      </button>
    </div>
  </>
);

View.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onEndImpersonation: PropTypes.func.isRequired,
  onToggleBanner: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

View.defaultProps = {
  isOpen: true,
};

const ImpersonationNotificationBanner = (props) => {
  const [isOpen, setOpen] = useState(true);

  const onToggleBanner = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const onEndImpersonation = useCallback(() => {
    window.sessionStorage.clear();
    window.close();
  }, []);

  const { isImpersonating } = useImpersonationStore();

  if (!isImpersonating) {
    return null;
  }

  const firstName = getStorageItem('firstName');
  const lastName = getStorageItem('lastName');
  const email = getStorageItem('email');

  const hookProps = {
    name: `${firstName} ${lastName}`,
    email,
    onEndImpersonation,
    onToggleBanner,
    isOpen,
  };
  return <View {...hookProps} {...props} />;
};

ImpersonationNotificationBanner.propTypes = {};

ImpersonationNotificationBanner.defaultProps = {};

export default ImpersonationNotificationBanner;
