import { css } from '@emotion/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';

const styles = {
  icon: css({
    width: '1.3125rem',
    height: '1.3125rem',
    color: palette.neutrals.gray700,
  }),
};

interface SectionItemIcon {
  icon: IconProp,
}

const Icon = ({
  icon,
}: SectionItemIcon): JSX.Element => (
  <FontAwesomeIcon css={styles.icon} icon={icon} />
);

export default Icon;
