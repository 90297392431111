import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { GridOverlay } from '@mui/x-data-grid';
import { TeamsListScope } from '../../const/types';
import { useShowCreateEditTeamModal } from '../../stores/useShowCreateEditTeamModal';

const styles = {
  emptyState: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.neutrals.gray800,
  }),
  createTeamButton: css({
    // Need a slightly higher zIndex than the table's overlay, so you can click the button, MUI is fun
    zIndex: 5,
  }),
};

interface ViewProps {
  listScope: TeamsListScope,
  handleCreateTeamClick: () => void,
}

const View = ({
  listScope,
  handleCreateTeamClick,
  ...props
}: ViewProps): JSX.Element => (
  <GridOverlay
    css={styles.emptyState}
    {...props}
  >
    {listScope === TeamsListScope.MyTeams && (
      <>You are not currently on any teams.</>
    )}
    {listScope === TeamsListScope.AllTeams && (
      <>
        No teams have been set up.
        <LeadrButton
          css={styles.createTeamButton}
          variant="text"
          onClick={handleCreateTeamClick}
          data-test-id="peopleTeamsEmptyStateCreateTeam"
        >
          Create a team.
        </LeadrButton>
      </>
    )}
  </GridOverlay>
);

interface EmptyStateProps {
  listScope: TeamsListScope,
}

const EmptyState = ({
  ...props
}: EmptyStateProps): JSX.Element => {
  const {
    setShowModal,
  } = useShowCreateEditTeamModal((state) => ({
    setShowModal: state.setShowModal,
  }));

  const handleCreateTeamClick = (): void => {
    setShowModal(true);
  };

  const hookProps = {
    handleCreateTeamClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default EmptyState;
