import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import { withH4Font, withH5Font } from '~Common/styles/typography';
import { withLineClamp } from '~Common/styles/mixins';
import { baseballCardColors } from '~Common/styles/colors';
import FlatExternalLinkButton from '~Common/components/Buttons/FlatExternalLinkButton';
import { WorkingGeniusType } from '~People/BaseballCard/types';

const style = {
  container: css`
    display: flex;
    width: 100%;
    padding-top: 8px;
  `,
  content: css`
    margin-inline-start: 0.5rem;
    display: flex;
    flex-direction: column;
  `,
  name: css`
    ${withH4Font()}
  `,
  assessmentTitleContainer: css`
    display: flex;
  `,
  assessmentTitle: css`
    ${withH5Font()}
    ${withLineClamp(1)}
    color: ${baseballCardColors.buttonColor};
    cursor: pointer;
  `,
  assessment: css`
    padding-right: 5px;
  `,
};

const View = ({
  assessment, personality,
}) => (
  <div css={style.container}>
    <div css={style.content}>
      {assessment !== WorkingGeniusType.workingGenius && (
        <div css={style.name}>{assessment}</div>
      )}
      {assessment === WorkingGeniusType.workingGenius && (
        <div css={style.name}>Take your assessment</div>
      )}
      <FlatExternalLinkButton link={personality.link} text={`${assessment} Assessment`} />
    </div>
  </div>
);

View.propTypes = {
  assessment: PropTypes.string.isRequired,
  personality: PropTypes.shape({
    personalityId: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

View.defaultProps = {};

const PersonalityDrawerHeader = ({
  personality, ...props
}) => {
  const hookProps = {
    personality,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

PersonalityDrawerHeader.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  assessment: PropTypes.string.isRequired,
  personality: PropTypes.shape({
    personalityId: PropTypes.string,
    link: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

PersonalityDrawerHeader.defaultProps = {};

export default PersonalityDrawerHeader;
