import { withTruncate } from '~Common/styles/mixins';
import { css } from '@emotion/react';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';
import { isHTMLText } from '~Common/utils/isHTMLText';
import SecondarySection from './SecondarySection';

const styles = {
  firstText: css(withTruncate()),
  secondText: css({
    whiteSpace: 'nowrap',
  }),
};

interface SecondaryTextProps {
  firstText?: HTMLString,
  secondText: string,
}

const SecondaryText = ({
  firstText,
  secondText,
  ...props
}: SecondaryTextProps): JSX.Element => (
  <SecondarySection
    renderFirst={firstText ? () => (
      <>
        {isHTMLText(firstText) && (
          <HTMLRenderer css={styles.firstText} htmlText={firstText} />
        )}
        {!isHTMLText(firstText) && (
          <span css={styles.firstText}>{firstText}</span>
        )}
      </>
    ) : undefined}
    renderSecond={() => (
      <span css={styles.secondText}>{secondText}</span>
    )}
    {...props}
  />
);

export default SecondaryText;
