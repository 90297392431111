import _ from 'lodash';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { UserPerspective } from './useInsightsUserPerspective';

export interface ENPSStats {
  score: number, // -100 to 100, % promoters - % detractors
  detractors: number, // number of detractors (enps score < 6)
  passives: number, // number of passives (enps score 7-8)
  promoters: number, // number of promoters (enps score 9-10)
}

export interface ENPSDataForDate {
  surveyDate: string,
  surveyId: number,
  surveysRequested: number,
  surveysAnswered: number,
  scores: ENPSStats,
}

export interface ExecutiveENPSDataForDate extends ENPSDataForDate {
  departments: Array<{
    name: string,
    surveysRequested: number,
    surveysAnswered: number,
    scores: ENPSStats,
  }>,
  managers: Array<{
    uid: string,
    name: string,
    surveysRequested: number,
    surveysAnswered: number,
    scores: ENPSStats,
  }>
  userGroups: Array<{
    uid: string,
    name: string,
    surveysRequested: number,
    surveysAnswered: number,
    scores: ENPSStats,
  }>,
}

interface UseENPSDataForDateRange {
  startDate: Date,
  endDate: Date,
  userPerspective: UserPerspective,
}

interface GetExecutiveENPSDataQueryParams {
  organizationId: string,
  userPerspective: UserPerspective,
  from?: string,
  to?: string,
}

function getENPSDataQuery<T extends ENPSDataForDate>({
  organizationId,
  userPerspective,
  ...options
}: GetExecutiveENPSDataQueryParams): Promise<HttpCallReturn<T[]>> {
  const serverUrl = `/organizations/${organizationId}/reports/insights/enps/${userPerspective}`;
  const queryParams = new URLSearchParams({
    ...options,
  });
  return getApi<T[]>(`${serverUrl}?${queryParams.toString()}`);
}

interface ENPSDataReturn<T> {
  data: T,
  isLoading: boolean,
}

export function useENPSDataForDateRange<T extends ENPSDataForDate>({
  startDate,
  endDate,
  userPerspective,
}: UseENPSDataForDateRange): ENPSDataReturn<T[]> {
  const organizationId = getOrganizationId() ?? '';
  const queryKey = [organizationId, 'insights', 'enps', userPerspective, startDate, endDate];

  const result = useQuery({
    queryKey,
    queryFn: () => getENPSDataQuery({
      organizationId,
      userPerspective,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
    }),
    staleTime: 12 * 60 * 60 * 1000,
  });

  const data = _(result?.data?.response)
    .orderBy(['surveyDate', 'surveyId'], ['asc', 'asc'])
    .value() as T[];

  return {
    data,
    isLoading: result.isLoading,
  };
}
