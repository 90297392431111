import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { getApi, HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { adminSettingsTemplate } from '~Insights/drawers/AdminSettings';
import { toast } from '~Common/components/Toasts';

interface UseAdminSettingsDrawer {
  openAdminSettingsDrawer: () => void
}

export function leadrPulseStateQueryKey(organizationId: string): string[] {
  return ['insights', organizationId, 'leadrPulse', 'state'];
}

export interface PulseSurveysApiGetState {
  pulseEnabled: boolean,
  eNPSEnabled: boolean,
  eNPSFrequency: number,

  pulseFrequency: {
    startOn: string, // Date
    dayOfMonth: {
      key: number,
      value: string,
    }
    dayOfWeek: {
      key: number,
      value: string,
    }
  }
}

function getInsightsStateQuery(organizationId: string): Promise<HttpCallReturn<PulseSurveysApiGetState>> {
  const serverUrl = `/organizations/${organizationId}/surveys/leadrPulse/state`;
  return getApi<PulseSurveysApiGetState>(serverUrl, {});
}

interface UseAdminSettingsStateReturn {
  insightSettings?: PulseSurveysApiGetState,
  isLoading: boolean,
}

export function useAdminSettingsState(): UseAdminSettingsStateReturn {
  const organizationId = getOrganizationId() ?? '';

  const result = useQuery({
    queryKey: leadrPulseStateQueryKey(organizationId),
    queryFn: () => getInsightsStateQuery(organizationId),
  });

  return {
    insightSettings: result?.data?.response,
    isLoading: result.isLoading,
  };
}

export function disablePulseSurveyMutation(organizationId: string): Promise<HttpCallReturn<void>> {
  const serverUrl = `/organizations/${organizationId}/surveys/leadrPulse/disable`;

  return postApi<void>(serverUrl, {
    organizationId,
  });
}

export function useDisablePulseSurvey(): UseMutationResult<HttpCallReturn<void>, Error, void, void> {
  const organizationId = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: () => disablePulseSurveyMutation(organizationId),
    onError: (error: Error) => {
      toast.error(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: leadrPulseStateQueryKey(organizationId) });
    },
  });
}

interface AdjustPulseSurveyFrequencyMutationProps {
  startOn: string, // YYYY-MM-DD
  dayOfMonth: string,
  dayOfWeek: string,
  enableENPS?: boolean,
}

async function adjustPulseSurveyFrequencyMutation({
  startOn,
  dayOfMonth,
  dayOfWeek,
  enableENPS,
}: AdjustPulseSurveyFrequencyMutationProps): Promise<HttpCallReturn<void>> {
  const organizationId = getOrganizationId() ?? '';

  const serverUrl = `/organizations/${organizationId}/surveys/leadrPulse/enable`;
  const mutation = await postApi<void>(serverUrl, {
    organizationId,
    startOn,
    dayOfMonth,
    dayOfWeek,
  });

  if (typeof enableENPS === 'boolean') {
    const value = enableENPS ? 'enable' : 'disable';
    await postApi<void>(
      `/organizations/${organizationId}/surveys/leadrPulse/enps/${value}`,
      { organizationId },
    );
  }

  return mutation;
}

export function useAdjustPulseSurveyFrequency(): UseMutationResult<HttpCallReturn<void>, Error, AdjustPulseSurveyFrequencyMutationProps, unknown> {
  const organizationId = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: adjustPulseSurveyFrequencyMutation,
    onError: (error: Error) => {
      toast.error(error.message);
    },
    onSuccess: async () => {
      toast.success('Successfully updated admin settings.');
      await queryClient.invalidateQueries({ queryKey: leadrPulseStateQueryKey(organizationId) });
    },
  });
}

export function useAdminSettingsDrawer(): UseAdminSettingsDrawer {
  const dispatch = useDispatch();

  const openAdminSettingsDrawer = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...adminSettingsTemplate,
        args: {},
      },
    }));
  };

  return { openAdminSettingsDrawer };
}
