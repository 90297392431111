import PropTypes from 'prop-types';
import { Global } from '@emotion/react';

import { withCompat } from '../styles/compat';

const StyleCompat = ({ loadNewStyles }) => {
  const style = withCompat(loadNewStyles);

  return (
    <Global
      styles={style}
    />
  );
};

StyleCompat.propTypes = {
  loadNewStyles: PropTypes.bool,
};

StyleCompat.defaultProps = {
  loadNewStyles: true,
};

export default StyleCompat;
