import { useRef } from 'react';

type HandlerRegistry = Record<string, () => void>;

interface UseSubmitAllTopicsReturn {
  registerSubmitHandler: (reviewTopicUid: string, handler: () => void) => void,
  submitAll: () => Promise<void>,
}

export function useSubmitAllTopics(): UseSubmitAllTopicsReturn {
  const handlers = useRef<HandlerRegistry>({});

  const registerSubmitHandler = (reviewTopicUid: string, handler: () => void): void => {
    handlers.current = {
      ...handlers.current,
      [reviewTopicUid]: handler,
    };
  };

  const submitAll = async (): Promise<void> => {
    const handlerFunctions = Object.values(handlers.current) ?? [];
    await Promise.allSettled(handlerFunctions);
  };

  return {
    registerSubmitHandler,
    submitAll,
  };
}
