import { css } from '@emotion/react';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@mui/material/Collapse';
import { useCallback, useState } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import AttachmentChip from '~Meetings/components/details/AttachmentChip';
import { Attachment } from '~Meetings/hooks/v2/useGetAgendas';

const styles = {
  attachmentsContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.3125rem',
  }),
  attachment: css({
    minWidth: 0,
  }),
  filesButton: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    color: palette.neutrals.gray600,
    padding: 0,

    '&:hover,&:active,&:focus': {
      color: palette.neutrals.gray600,

      '& svg': {
        color: palette.neutrals.gray600,
      },
    },
  }),
  filesButtonText: css({
    marginRight: '0.25rem',
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
  }),
};

interface ViewProps extends AttachmentsListProps {
  onFilesSectionClick: () => void,
  showFiles: boolean,
}

const View = ({
  allowCollapse,
  attachments,
  handleAttachmentClick,
  isWhiteBackground,
  onFilesSectionClick,
  onRemoveAttachment,
  pendingRemovedAttachments,
  showFiles,
}: ViewProps): JSX.Element => (
  <div>
    {allowCollapse && (
      <LeadrButton
        css={styles.filesButton}
        onClick={onFilesSectionClick}
        variant="text"
        data-test-id="toggleExpandFilesSection"
      >
        <span css={styles.filesButtonText}>
          {`${attachments.length} file${attachments.length === 1 ? '' : 's'}`}
        </span>
        <FontAwesomeIcon icon={showFiles ? faCaretDown : faCaretUp} />
      </LeadrButton>
    )}

    <Collapse in={showFiles || !allowCollapse}>
      <div css={styles.attachmentsContainer}>
        {attachments?.map((attachment) => (
          <AttachmentChip
            css={styles.attachment}
            key={attachment.id}
            attachment={attachment}
            dark={isWhiteBackground}
            onClick={handleAttachmentClick ? () => handleAttachmentClick(attachment) : undefined}
            onRemove={onRemoveAttachment}
            pendingRemove={pendingRemovedAttachments?.includes(attachment.id)}
          />
        ))}
      </div>
    </Collapse>
  </div>
);

interface AttachmentsListProps {
  allowCollapse?: boolean
  attachments: Attachment[],
  handleAttachmentClick?: (attachment: Attachment) => void,
  isWhiteBackground?: boolean
  onRemoveAttachment?: (attachment: Attachment) => void,
  pendingRemovedAttachments?: number[],
}

export const AttachmentsList = ({
  allowCollapse = true,
  attachments,
  handleAttachmentClick,
  isWhiteBackground = false,
  onRemoveAttachment,
  pendingRemovedAttachments,
}: AttachmentsListProps): JSX.Element => {
  const [showFiles, setShowFiles] = useState(true);

  const onFilesSectionClick = useCallback(() => {
    setShowFiles(!showFiles);
  }, [
    showFiles,
  ]);

  const viewProps = {
    allowCollapse,
    attachments,
    handleAttachmentClick,
    isWhiteBackground,
    onFilesSectionClick,
    onRemoveAttachment,
    pendingRemovedAttachments,
    showFiles,
  };

  return (
    <View
      {...viewProps}
    />
  );
};
