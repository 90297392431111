import { css } from '@emotion/react';
import { useCallback, useEffect } from 'react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useDebounceDraftState } from '~Common/hooks/useDebounceDraftState';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import useFroala from '~Common/hooks/useFroala';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { palette } from '~Common/styles/colors';
import { useGetPrivateMeetingNotes } from '~Meetings/hooks/useGetPrivateMeetingNotes';
import { useUpsertPrivateMeetingNotes } from '~Meetings/hooks/useUpsertPrivateMeetingNotes';
import { useLocation } from 'react-router';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { HiddenMeetingNotes } from './HiddenMeetingNotes';

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  froala: (inDrawer: boolean) => css({
    '.fr-box, .fr-toolbar, .fr-second-toolbar': {
      background: `${palette.neutrals.white} !important`,
      borderRadius: '10px', // Keeping PX to match the Froala styles
    },

    '.fr-btn-grp': {
      paddingLeft: '0.25rem !important',
      paddingRight: '0 !important',
      margin: '0 !important',

      button: {
        padding: '0.5rem 0.25rem !important',
      },
    },

    '.fr-view': {
      padding: '0 0.75rem !important',
    },

    '.fr-wrapper': {
      backgroundColor: `${palette.neutrals.white} !important`,
      minHeight: '3.5rem',
      padding: '0.25rem 0.5rem 0 !important',
    },

    '.fr-placeholder': {
      fontSize: '0.875rem !important',
      lineHeight: 'unset',
      padding: '0.25rem 1.25rem !important',
    },

    '.label-container': {
      display: 'none',
    },
  }, !inDrawer && {
    maxHeight: '32rem',
  }, inDrawer && {
    '#froalaWrapper, .fr-element': {
      maxHeight: '100% !important',
      marginBottom: '3rem',
    },
  }),
  froalaLabel: css({
    display: 'none',
  }),
  skeletonContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  skeleton: css({
    height: '1rem',
    width: '100%',
  }),
};

interface ViewProps {
  showSkeleton: boolean,
  FroalaEditor: JSX.Element,
}

const View = ({
  showSkeleton,
  FroalaEditor,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    {showSkeleton && (
      <MultipleSkeletonLoaders
        css={styles.skeletonContainer}
        numberOfSkeletons={3}
        renderSkeletonItem={() => (
          <SkeletonLoader
            width="100%"
            variant="rectangular"
            css={styles.skeleton}
            renderComponent={() => <div />}
          />
        )}
      />
    )}
    {!showSkeleton && FroalaEditor}
  </div>
);

interface PrivateMeetingNotesProps {
  inDrawer?: boolean,
  isHidden?: boolean,
  meetingFactoryId: string,
}

export const PrivateMeetingNotes = ({
  inDrawer = false,
  isHidden = false,
  meetingFactoryId,
}: PrivateMeetingNotesProps): JSX.Element => {
  const { notes: [note], isLoading } = useGetPrivateMeetingNotes(meetingFactoryId);
  const { mutate: upsertPrivateNotes } = useUpsertPrivateMeetingNotes();

  const autoSaveDebounceTime = useFeatureFlag<number>('autoSaveDebounceTime');
  const [showSkeleton] = useSkeletonLoaders(isLoading || !meetingFactoryId);

  const saveResponse = useCallback((noteText: string): void => {
    if (isLoading) {
      return;
    }

    upsertPrivateNotes({
      meetingFactoryId,
      noteText,
    });
  }, [isLoading, upsertPrivateNotes, meetingFactoryId]);

  const initialNoteValue = note?.text ?? '';

  const [, setNotes] = useDebounceDraftState(initialNoteValue, saveResponse, autoSaveDebounceTime);

  // @ts-expect-error Needs fixed once this is typed
  const { Froala: FroalaEditor } = useFroala({
    name: 'notes',
    froalaConfigProps: {
      placeholderText: 'Anything you write in here will only be visible to you.',
    },
    richTextEditorProps: {
      name: 'notes',
      onChange: ({ value: newText }: Record<string, string>) => {
        setNotes(newText);
      },
      initialValue: initialNoteValue,
    },
    labelStyleOverrides: styles.froalaLabel,
    styleOverrides: styles.froala(inDrawer),
    'data-test-id': 'meetingsPrivateNotesEditor',
  });

  const location = useLocation();
  const organizationId = getOrganizationId();
  useEffect(() => {
    void queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', meetingFactoryId, 'privateNotes'] });
  }, [location, meetingFactoryId, organizationId]);

  if (isHidden) {
    return (
      <HiddenMeetingNotes />
    );
  }

  const hookProps = {
    FroalaEditor,
    showSkeleton,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
