import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import PersonalityDrawer from '~Deprecated/ui/components/BaseballCard/PersonalityDrawer';
import {
  getPersonalityDetails,
} from '~Deprecated/selectors/personalities/getPersonalitesById';
import { loadPersonalityDetailsById } from '~Deprecated/actions/personalities/loadPersonalityDetails';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import Loader from '~Deprecated/ui/components/Misc/Loader';
import { DRAWER_WIDTHS } from '~Common/const/drawers';

export const personalityDetailsDrawerTemplate = {
  name: 'BASEBALL_CARD_PERSONALITY_DETAILS',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};
function PersonalityDetailsDrawer({ id }) {
  const dispatch = useDispatch();
  const details = useSelector((state) => getPersonalityDetails(state, { id }));
  const onClose = () => {
    dispatch(popDrawerAction({
      drawerName: personalityDetailsDrawerTemplate.name,
    }));
  };

  useEffect(() => {
    dispatch(loadPersonalityDetailsById(id));
  }, [dispatch, id]);

  if (!details) {
    return (
      <Loader />
    );
  }

  return (
    <PersonalityDrawer
      details={details}
      onClose={onClose}
    />
  );
}

PersonalityDetailsDrawer.propTypes = {
  id: PropTypes.string.isRequired,
};

registerDrawer({
  templateName: personalityDetailsDrawerTemplate.name,
  component: PersonalityDetailsDrawer,
});

export default PersonalityDetailsDrawer;
