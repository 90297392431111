import { css } from '@emotion/react';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';

import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';

const styles = {
  userDisplay: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  name: css({
    color: palette.neutrals.gray800,
  }, withTruncate()),
};

interface UserDisplayProps {
  firstName: string,
  lastName: string,
  profileImageUrl?: string,
}

const UserDisplay = ({
  firstName,
  lastName,
  profileImageUrl,
  ...props
}: UserDisplayProps): JSX.Element => (
  <div
    css={styles.userDisplay}
    {...props}
  >
    <NewBaseAvatar
      name={`${firstName} ${lastName}`}
      profileImageUrl={profileImageUrl}
      avatarSize={22}
    />
    <div css={styles.name}>{`${firstName} ${lastName[0]}.`}</div>
  </div>
);

export default UserDisplay;
