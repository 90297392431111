import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { getOrganizationId } from '~Common/utils/localStorage';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { toast } from '~Common/components/Toasts';
import { postApi } from '~Deprecated/services/HttpService';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';

const submitFeedbackResponsesMutation = ({ id, responses }) => { // Responses should have this shape: [{ questionId, responseText }]
  const url = {
    host: hosts.questions,
    uri: `/organizations/${getOrganizationId()}/questions/responses`,
  };

  return postApi(url, responses, {});
};

export const useSubmitFeedbackResponses = ({ queryKey = [getOrganizationId(), 'feedback'], template }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: submitFeedbackResponsesMutation,
    onMutate: () => {
      toastId.current = toast.info('Submitting feedback responses...', { autoClose: false });

      dispatch(
        popDrawerAction({ drawerName: template.name }),
      );
    },
    onError: (_, variables) => {
      toast.update(toastId.current, {
        render: 'There was an error submitting feedback responses. Please try again or contact support.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (template) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...template,
              args: {
                id: variables.id,
              },
            },
          }));
        });
      }
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully submitted feedback responses.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeFeedback(getOrganizationId()) });

      queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
      queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
  });

  return mutation.mutate;
};
