import { css } from '@emotion/react';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';

import { NavigationHeader } from '~Root/components/NavigationSidebar/NavigationHeader';
import { NavigationItems } from '~Root/components/NavigationSidebar/NavigationItems';
import { ProfileCollapse } from '~Root/components/NavigationSidebar/ProfileCollapse';
import { useNavigationStore } from '~Root/components/NavigationSidebar/useNavigationStore';

const collapsedSidebarWidth = '4.5rem';
const expandedSidebarWidth = '13.75rem';

const styles = {
  collapseIcon: (compact: boolean) => css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.white,
    border: `1px solid ${palette.neutrals.gray500}`,
    borderRadius: '100%',
    color: palette.neutrals.gray500,
    display: 'flex',
    height: '1.375rem',
    justifyContent: 'center',
    fontSize: '0.75rem',
    fontWeight: 600,
    left: `calc(${compact ? collapsedSidebarWidth : expandedSidebarWidth} - 1rem)`,
    padding: 0,
    position: 'absolute',
    rotate: '0deg',
    top: '2.825rem',
    transition: 'rotate 100ms ease',
    zIndex: 1150,
    width: '1.375rem',

    '&.collapse-icon': {
      opacity: 0,
      transition: 'opacity 150ms ease',

      '@media (pointer:coarse)': {
        opacity: 100,
      },
    },
  }, compact && {
    rotate: '180deg',
  }),
  container: (compact: boolean, isMobile: boolean) => css({
    backgroundColor: palette.neutrals.white,
    borderRight: `1px solid ${palette.neutrals.gray200}`,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: '100%',
    maxHeight: '100vh',
    overflow: 'clip',
    width: compact ? collapsedSidebarWidth : expandedSidebarWidth,

    ':hover': {
      '& .collapse-icon': {
        opacity: 100,
      },
    },
  }, isMobile && {
    width: '100%',
    maxWidth: '100%',
  }),
};

export interface ViewProps {
  allowCollapse: boolean,
  compact: boolean,
  isMobile: boolean,
  toggleCollapsed: () => void,
}

const View = ({
  allowCollapse,
  compact,
  isMobile,
  toggleCollapsed,
}: ViewProps): JSX.Element => (
  <>
    <nav
      css={styles.container(compact, isMobile)}
    >
      <NavigationHeader
        compact={compact}
        isMobile={isMobile}
      />

      <NavigationItems
        compact={compact}
        isMobile={isMobile}
      />

      <ProfileCollapse
        compact={compact}
      />

      {allowCollapse && (
        <button
          className="collapse-icon"
          css={styles.collapseIcon(compact)}
          onClick={toggleCollapsed}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
          />
        </button>
      )}
    </nav>
  </>
);

interface NavigationSidebarProps {
  allowCollapse?: boolean,
  isMobile?: boolean,
}

export const NavigationSidebar = ({
  allowCollapse = true,
  isMobile = false,
}: NavigationSidebarProps): JSX.Element => {
  const compact = useNavigationStore((state) => state.isNavigationCollapsed);
  const toggleCollapsed = useNavigationStore((state) => state.toggleCollapsed);

  const hookProps = {
    allowCollapse,
    compact: compact && allowCollapse,
    isMobile,
    toggleCollapsed,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
