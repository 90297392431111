import { ChangeEvent, ChangeEventHandler } from 'react';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { capitalize } from 'lodash';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { palette } from '~Common/styles/colors';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';

const styles = {
  drawerBody: css({
    color: palette.neutrals.gray700,
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
  closeButton: css`
    border: none;
    box-shadow: none;
    background-color: transparent;
  `,
  heading: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    marginBottom: '0.5rem',
  }),
  label: css({
    fontSize: '0.875rem',
    marginBottom: '0.25rem',
    width: '100%',

    '& .MuiCheckbox-root': {
      paddingLeft: '0',
    },
  }),
  switch: css({
    margin: '1.25rem 0',
  }),
};

const leadrCoachFiltersTemplate = {
  name: 'INSIGHTS_LEADR_COACH_FILTERS',
  width: DRAWER_WIDTHS.BASE,
};

interface ViewProps {
  closeDrawerClick: () => void,
  selectedCategories: string[],
  handleCheck: ChangeEventHandler<HTMLInputElement>,
}

const View = ({
  closeDrawerClick,
  selectedCategories,
  handleCheck,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={() => (
          <button onClick={closeDrawerClick} css={styles.closeButton} title="Close drawer">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        title="Filters"
      />
    )}
    renderBody={() => (
      <div
        css={styles.drawerBody}
      >
        <h2 css={styles.heading}>Engagement Categories</h2>
        {['clarity', 'maximization', 'rapport'].map((label) => (
          <label
            key={label}
            css={styles.label}
            htmlFor={`leadrCoach-filter-${label}`}
          >
            <CustomCheckbox
              id={`leadrCoach-filter-${label}`}
              value={label}
              checked={selectedCategories.includes(label)}
              onChange={handleCheck}
            />
            {capitalize(label)}
          </label>
        ))}
      </div>
    )}
  />
);

const FilterDrawer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useQueryParamState<string[]>('insights', 'categories', []);

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>): void => {
    const filterValue = e.target.value;
    const result = Array.from(selectedCategories);
    const index = result.indexOf(filterValue);

    if (index === -1) {
      result.push(filterValue);
    } else {
      result.splice(index, 1);
    }

    setSelectedCategories([...result]);
  };

  const hookProps = {
    closeDrawerClick,
    handleCheck,
    selectedCategories,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: leadrCoachFiltersTemplate.name,
  component: FilterDrawer,
});

export { View, leadrCoachFiltersTemplate };
export default FilterDrawer;
