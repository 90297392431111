import { css } from '@emotion/react';
import { SelectChangeEvent } from '@mui/material';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';

const styles = {
  dropdown: css({
    fontSize: '0.75rem',
    padding: '0.25rem',
    paddingLeft: '0.75rem',
    width: 'auto',

    '.MuiInput-root': {
      fontSize: '0.75rem',
      marginTop: 0,
    },
  }),
};

interface TeamOptionsDropdownProps {
  teamOptions: DropdownItem[],
  handleSelectedTeamChange: (event: SelectChangeEvent<string>) => void,
  selectedTeamId: string,
}

const TeamOptionsDropdown = ({
  teamOptions,
  handleSelectedTeamChange,
  selectedTeamId,
  ...props
}: TeamOptionsDropdownProps): JSX.Element => (
  <Dropdown
    css={styles.dropdown}
    data-test-id="homeSelectTeamForClarity"
    items={teamOptions}
    onChange={handleSelectedTeamChange}
    defaultValue={selectedTeamId}
    {...props}
  />
);

export default TeamOptionsDropdown;
