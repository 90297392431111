import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { useSelectedAdminPeople } from './useSelectedAdminPeople';
import { getHost } from '~Deprecated/services/config';

const sendInvitesToImportedUsers = async (importedUsers) => {
  const organizationId = getOrganizationId() ?? '';
  const host = getHost('', 2);
  const URL = {
    host,
    uri: `/organizations/${organizationId}/auth/inviteImportedUsers`,
  };

  return postApi(URL, importedUsers);
};

export const useSendInvitesToImportedUsers = () => {
  const toastId = useRef(null);
  const { setSelectedOrgUserIds } = useSelectedAdminPeople('CREATED');

  const mutation = useMutation({
    mutationFn: sendInvitesToImportedUsers,
    onMutate: () => {
      toastId.current = toast.info('Sending the invite(s)...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'The invite(s) failed to send. Please try again...',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'The invite(s) were sent successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      setSelectedOrgUserIds([]);
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });
    },
  });

  return mutation.mutate;
};
