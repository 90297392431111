import { useQuery } from '@tanstack/react-query';
import { NoteContextType } from '~Common/const/interfaces';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, getApi } from '~Deprecated/services/HttpService';
import { PrivateMeetingNote } from '~Meetings/const/meetingsInterfaces';

interface GetPrivateMeetingNotesParams {
  meetingFactoryId: string,
  organizationId: string,
}

interface GetPrivateMeetingNotesApiResponse {
  notes: PrivateMeetingNote[],
}

function getPrivateMeetingNotes({
  meetingFactoryId,
  organizationId,
}: GetPrivateMeetingNotesParams): Promise<HttpCallReturn<GetPrivateMeetingNotesApiResponse>> {
  const queryParams = new URLSearchParams({
    type: NoteContextType.TeamMeeting,
    id: meetingFactoryId,
  });

  const serverUrl = `/organizations/${organizationId}/notes?${queryParams.toString()}`;
  return getApi<GetPrivateMeetingNotesApiResponse>(serverUrl);
}

interface UseGetPrivateMeetingNotesReturn {
  notes: PrivateMeetingNote[],
  isLoading: boolean,
}

export function useGetPrivateMeetingNotes(meetingFactoryId: string): UseGetPrivateMeetingNotesReturn {
  const organizationId = getOrganizationId() ?? '';

  const result = useQuery({
    queryKey: [organizationId, 'huddles', meetingFactoryId, 'privateNotes'],
    queryFn: () => getPrivateMeetingNotes({
      meetingFactoryId,
      organizationId,
    }),
    enabled: !!meetingFactoryId,
  });

  return {
    notes: result.data?.response?.notes ?? [],
    isLoading: result.isLoading,
  };
}
