import { SurveyQuestion } from '~Surveys/Hooks/useSurveyTemplates';
import { useGetSurveyTypes } from '~Surveys/Hooks/useSurveyTypes';
import SurveyFreeform from '../Drawers/Respond/SurveyFreeform';
import SurveyMultipleChoice from '../Drawers/Respond/SurveyMultipleChoice';
import SurveyRating from '../Drawers/Respond/SurveyRating';
import ViewSurveyQuestionCard from './ViewSurveyQuestionCard';

interface SurveyQuestionPreviewProps {
  question: SurveyQuestion,
}

type SurveyTypeEnum = Record<string, { id: number, description: string; }>;

export const SurveyQuestionPreview = ({
  question,
}: SurveyQuestionPreviewProps): JSX.Element => {
  const { QuestionTypes } = useGetSurveyTypes({}) as { QuestionTypes: SurveyTypeEnum; };

  return (
    <ViewSurveyQuestionCard
      question={question}
      renderResponseContent={() => (
        <>
          {question.type.key === QuestionTypes.CustomScale.id && (
            <SurveyRating initialValue={null} question={question} disabled />
          )}
          {question.type.key === QuestionTypes.MultipleChoice.id && (
            <SurveyMultipleChoice initialValue={null} question={question} isReadOnly />
          )}
          {question.type.key === QuestionTypes.ShortAnswer.id && (
            <SurveyFreeform
              question={question}
              value="This is an example of how the answer will appear."
              isReadOnly
            />
          )}
        </>
      )}
    />
  );
};
