import { useDispatch } from 'react-redux';
import { User } from '~Common/const/interfaces';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { editUserDrawerTemplate } from '~Admin/drawers/EditUserDrawer';

export interface UseEditUserReturn {
  editUser: (props: EditUserProps) => void,
}

export interface EditUserProps {
  isInviteUser?: boolean,
  user: User | undefined,
  allUsers: User[],
}

export function useEditUser(): UseEditUserReturn {
  const dispatch = useDispatch();

  return {
    editUser({ isInviteUser, user, allUsers }: EditUserProps) {
      dispatch(pushDrawerAction({
        drawer: {
          ...editUserDrawerTemplate,
          args: {
            isInviteUser,
            user,
            allUsers,
          },
        },
      }));
    },
  };
}
