import PropTypes from 'prop-types';

import { noop } from '~Deprecated/utils';
import DrawerRow from '../DrawerRow';

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
const BaseBody = ({
  onClose, header, renderHeader, renderBody, className,
}) => (
  <div
    className={className}
  >
    {renderHeader({
      onCancel: onClose,
    })}
    {header && (
      <DrawerRow>
        <h2>{header}</h2>
      </DrawerRow>
    )}
    <DrawerRow>
      {renderBody()}
    </DrawerRow>
  </div>
);

BaseBody.propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  renderHeader: PropTypes.func,
  renderBody: PropTypes.func,
  className: PropTypes.string,
};

BaseBody.defaultProps = {
  header: '',
  renderHeader: noop,
  renderBody: noop,
  className: '',
};

export default BaseBody;
