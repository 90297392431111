import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

import { palette } from '~Common/styles/colors';

const styles = {
  emptyState: css({
    color: palette.neutrals.gray700,
    backgroundColor: palette.neutrals.gray50,
    padding: '.5rem 1rem',
    borderRadius: '.3125rem',
    flex: '1',
  }),
  emptyStateText: css({
    fontStyle: 'italic',
  }),
};

interface EmptyStateProps {
  onAddTopicClick: () => void,
  disableInitialAddTopicButton: boolean,
}

const EmptyState = ({
  onAddTopicClick,
  disableInitialAddTopicButton,
  ...props
}: EmptyStateProps): JSX.Element => (
  <div css={styles.emptyState} {...props}>
    <LeadrButton
      css={styles.emptyStateText}
      variant="text"
      textButtonColor={palette.brand.indigo}
      data-test-id="agendaSectionEmptyStateAddTopic"
      onClick={onAddTopicClick}
      disabled={disableInitialAddTopicButton}
    >
      Add the first topic.
    </LeadrButton>
  </div>
);

export default EmptyState;
