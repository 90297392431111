import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Loader from '~Common/components/Loader';
import { FEEDBACK_SHAPE } from '~Common/const/proptypes';
import { useFeedbackDetails } from '~Deprecated/hooks/feedback/useFeedback';
import { loadingColor } from '~Deprecated/ui/styles/colors';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import PrintWrapper from '~Common/V3/components/Print/PrintWrapper';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import ResultsByPerson from './ResultsByPerson';
import ResultsByQuestion from './ResultsByQuestion';

const View = ({
  feedback,
  byPerson,
  byQuestion,
  title,
  subTitle,
  isLoading,
  ...props
}) => (
  <PrintWrapper {...props}>
    {isLoading && (
      <PrintWrapper.BasePrintHeaderSkeleton />
    )}
    {!isLoading && (
      <PrintWrapper.BasePrintHeader
        title={title}
        subTitle={subTitle}
      />
    )}
    <PrintWrapper.BasePrintBody>
      {isLoading && (
        <div>Loading...</div>
      )}
      {!isLoading && (
        <>
          {byQuestion && (
            <ResultsByQuestion
              groupByQuestions={feedback.groupByQuestions}
            />
          )}
          {byPerson && (
            <ResultsByPerson
              groupByPeople={feedback.groupByPeople}
            />
          )}
        </>
      )}
    </PrintWrapper.BasePrintBody>
  </PrintWrapper>
);

View.propTypes = {
  feedback: FEEDBACK_SHAPE.isRequired,
  byPerson: PropTypes.bool,
  byQuestion: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

View.defaultProps = {
  byPerson: false,
  byQuestion: false,
};

const FeedbackPrint = ({
  byPerson, byQuestion, ...props
}) => {
  const currentOrgUserId = getOrganizationUserId();

  const { feedbackId } = useParams();

  const { feedback, isLoading: areFeedbackDetailsLoading } = useFeedbackDetails({
    feedbackId,
    assigneeId: currentOrgUserId,
    isReadOnly: false,
  });

  const [isLoading] = useSkeletonLoaders(areFeedbackDetailsLoading);

  const hookProps = {
    feedback,
    byPerson,
    byQuestion,
    title: `Feedback - ${feedback?.title}`,
    subTitle: byPerson ? 'Responses by Person' : 'Responses by Question',
    isLoading,
  };

  if (feedback === undefined) {
    return (
      <Loader
        color={loadingColor}
      />
    );
  }

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

FeedbackPrint.propTypes = {
  byPerson: PropTypes.bool,
  byQuestion: PropTypes.bool,
};

FeedbackPrint.defaultProps = {
  byPerson: false,
  byQuestion: false,
};

export { View };
export default FeedbackPrint;
