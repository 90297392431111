import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LOGOUT, SELECT_ORGANIZATION } from '~Common/const/routes';

import Personalities from '~Deprecated/ui/containers/DataLoaders/Personalities';
import ImpersonationNotificationBanner from '~Deprecated/ui/components/ImpersonationNotificationBanner';
import {
  getOrganizationId,
  getUserId,
  setEmail,
  setFirstName,
  setLastName,
  setOrganizationUserId,
} from '~Common/utils/localStorage';
import { useSyncTimezone, useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import SearchModal from '~GlobalSearch/components/SearchModal';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

import { OrganizationSelect } from '~Root/components/auth/OrganizationSelect';
import { Logout } from '~Root/components/auth/Logout';
import ErrorBoundary from '~Common/components/ErrorBoundary';
import { OrgUserPermissionsContextProvider } from '~Common/V3/components/OrgUserPermissionsContext';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { palette } from '~Common/styles/colors';
import { OrgDetailsContextProvider } from '~Common/V3/components/OrgDetailsContext';
import PermissionsChildren from './PermissionsChildren';

const styles = {
  loader: css({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  }),
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const View = ({
  hasUserInfo,
  hasOrgSelected,
  useGlobalSearch,
}) => (
  <ErrorBoundary>
    <ImpersonationNotificationBanner />
    <Personalities />
    {useGlobalSearch && (
    <SearchModal
      verticalPosition="top"
    />
    )}
    {!hasUserInfo && hasOrgSelected && (
      <div
        css={styles.loader}
      >
        <CircularProgress css={styles.circularProgress} />
      </div>
    )}
    <Switch>
      <Route
        exact
        path={LOGOUT}
        component={Logout}
      />
      {!hasOrgSelected && (
        <Route
          exact
          path={SELECT_ORGANIZATION}
          component={OrganizationSelect}
        />
      )}
      {hasOrgSelected && hasUserInfo && (
        <OrgDetailsContextProvider>
          <OrgUserPermissionsContextProvider>
            <PermissionsChildren />
          </OrgUserPermissionsContextProvider>
        </OrgDetailsContextProvider>
      )}
      {!hasOrgSelected && (
        <Redirect from="*" to={SELECT_ORGANIZATION} />
      )}
    </Switch>
  </ErrorBoundary>
);

View.propTypes = {
  hasUserInfo: PropTypes.bool.isRequired,
  hasOrgSelected: PropTypes.bool.isRequired,
  useGlobalSearch: PropTypes.bool.isRequired,
};

const Private = () => {
  const { user, isSuccess } = useUserProfile(getUserId());
  // This is the first time we call this, so we are setting the user info here
  useEffect(() => {
    if (isSuccess && user) {
      setLastName(user.lastName);
      setFirstName(user.firstName);
      setOrganizationUserId(user.organizationUserId);
      setEmail(user.email);
    }
  }, [user?.firstName, user?.lastName, user?.organizationUserId, user?.email, isSuccess, user]);

  useSyncTimezone();
  const hasUserInfo = !!user?.organizationUserId && !!getUserId();
  const hasOrgSelected = !!getOrganizationId();

  const useGlobalSearch = useFeatureFlag('useGlobalSearch');

  const hookProps = {
    hasUserInfo,
    hasOrgSelected,
    useGlobalSearch,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

Private.propTypes = {};

Private.defaultProps = {};

export { View };
export default Private;
