import { getHost } from '~Deprecated/services/config';
import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';

export const impersonateUser = async (email) => {
  const organizationId = getOrganizationId() ?? '';
  const URL = {
    host: getHost('', '2'),
    uri: `/organizations/${organizationId}/auth/impersonate`,
  };

  return postApi(URL, {
    email,
  });
};
