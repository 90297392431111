import { css } from '@emotion/react';
import { hexToRGBA, palette } from '~Common/styles/colors';

export const tabStyles = {
  tabNav: css({
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    display: 'flex',
    overflowX: 'auto',
    height: '3.125rem',
    alignItems: 'flex-end',
    padding: '0 1.5rem 0 1.5rem',
  }),
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
};
