import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  LearningStatus, LearningType, ReceivedPlaylistDetail, ReceivedPlaylistLearning,
} from '~Learning/const/interfaces';
import LearningSectionsCard from '~Learning/components/Shared/LearningSectionsCard';
import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';
import TitleAndIntroduction from '~Learning/components/Shared/TitleAndIntroduction';
import CompletedLearningsProgressBar from '~Learning/components/Shared/LearningDashboardCards/CompletedLearningsProgressBar';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import LearningCard from './LearningCard';

const styles = {
  contentsTab: css({}),
  completedLearningsProgressBar: css({
    marginTop: '1.875rem',
    height: '1.875rem',
  }),
  learningContentHeader: css({
    margin: '1.5rem 0',
    color: palette.neutrals.gray800,
    fontWeight: 500,
    fontSize: '1.25rem',
  }),
  learningCard: css({
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    columnGap: '1.125rem',
    alignItems: 'center',

    '&:not(:last-child)': {
      marginBottom: '1.125rem',
    },
  }),
  learningTitle: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1rem',
  }),
  circleCheckIcon: css({
    color: palette.brand.indigo,
    marginLeft: '1.125rem',
  }),
};

interface ContentProps {
  playlist: ReceivedPlaylistDetail,
  onLearningClick: (learning: ReceivedPlaylistLearning, index: number) => void,
  isLearningSelected: (learningId: string) => boolean,
}

const Content = ({
  playlist,
  onLearningClick,
  isLearningSelected,
}: ContentProps): JSX.Element => (
  <div css={styles.contentsTab}>
    <LearningSectionsCard
      renderHeader={() => (
        <LearningTypeInfo
          learningType={LearningType.PLAYLIST}
        />
      )}
      renderBody={() => (
        <>
          <TitleAndIntroduction
            title={playlist.title}
            introductionHTML={playlist.description}
          />
          <CompletedLearningsProgressBar
            css={styles.completedLearningsProgressBar}
            totalLearningsCount={playlist.totalLearningsCount}
            completedLearningsCount={playlist.completedLearningsCount}
            renderPercentText={(value: number) => (
              <>{`${value}% Completed`}</>
            )}
          />
        </>
      )}
    />
    <div>
      <div css={styles.learningContentHeader}>
        {`Learning Content (${playlist.totalLearningsCount})`}
      </div>
      {playlist.learnings.map((learning, index) => (
        <LearningCard
          learning={learning}
          onLearningClick={() => onLearningClick(learning, index)}
          isLearningSelected={isLearningSelected(learning.id)}
          renderRightItem={learning.status === LearningStatus.COMPLETED ? () => (
            <Tooltip content="Complete">
              <div css={styles.circleCheckIcon}>
                <FontAwesomeIcon icon={faCircleCheck} size="2x" />
              </div>
            </Tooltip>
          ) : undefined}
        />
      ))}
    </div>
  </div>
);

export default Content;
