import { AgendaTemplates } from '@leadr-hr/types';
import { MeetingFactoryType } from '~Meetings/const/meetingsInterfaces';

type MeetingTemplateType = AgendaTemplates.MeetingTemplateType;

interface CustomTemplatesParams {
  orgId: string,
  meetingTemplateType: MeetingTemplateType,
  templateVisibility?: string,
  page?: number,
  count?: number,
}

interface CuratedTemplatesParams {
  orgId: string,
  meetingTemplateType: MeetingTemplateType,
  curatedSource: AgendaTemplates.CuratedSource,
}

// TODO: Delete this when the curated by leadr templates are returned via the newer API
interface OldCuratedTemplatesParams {
  orgId: string,
  meetingTemplateType: MeetingFactoryType,
}

export const meetingTopicTemplatesQueryKeys = {
  all: (orgId: string) => [orgId, 'meeting topic templates'] as const,
  customTemplatesLists: (orgId: string) => [...meetingTopicTemplatesQueryKeys.all(orgId), 'custom'] as const,
  curatedTemplatesLists: (orgId: string) => [...meetingTopicTemplatesQueryKeys.all(orgId), 'curated'] as const,
  customTemplatesList: ({
    orgId,
    meetingTemplateType,
    templateVisibility,
    page,
    count,
  }: CustomTemplatesParams) => [...meetingTopicTemplatesQueryKeys.customTemplatesLists(orgId), meetingTemplateType, templateVisibility, page, count] as const,
  curatedTemplatesList: ({
    orgId,
    meetingTemplateType,
    curatedSource,
  }: CuratedTemplatesParams) => [...meetingTopicTemplatesQueryKeys.curatedTemplatesLists(orgId), meetingTemplateType, curatedSource] as const,
  oldCuratedTemplatesList: ({
    orgId,
    meetingTemplateType,
  }: OldCuratedTemplatesParams) => [...meetingTopicTemplatesQueryKeys.curatedTemplatesLists(orgId), 'old', meetingTemplateType] as const,
};
