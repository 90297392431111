import LearningPreview, { LearningPreviewProps } from './LearningPreview';
import PlaylistOverlay from './Overlays/PlaylistOverlay';

interface PreviewWithContentCountProps extends LearningPreviewProps {
  contentUrl: string,
  numberOfContents: number,
}

const PreviewWithContentCount = ({
  contentUrl,
  numberOfContents,
  ...props
}: PreviewWithContentCountProps): JSX.Element => (
  <LearningPreview
    viewOption="Thumbnail"
    contentUrl={contentUrl}
    renderOverlay={() => (
      <PlaylistOverlay numberOfContents={numberOfContents} />
    )}
    {...props}
  />
);

export default PreviewWithContentCount;
