import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useCallback } from 'react';
import {
  buttonConstructiveColor, buttonDestructiveColor, topicItemBg, topicItemColor,
} from '~Deprecated/ui/styles/colors';
import { noop } from '~Deprecated/utils';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  box: css`
    background-color: ${topicItemBg};
    border-radius: 8px;
    color: ${topicItemColor};
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
    overflow: hidden;
    line-height: 1.65em;
    justify-content: space-between;
  `,
  disabled: css`
    cursor: unset;
  `,
  textBox: css`
    width: calc(100% - 20px);
    word-break: break-word;
    flex: 1;
    margin-inline-end: 0.5rem;
  `,
  icon: css`
    font-weight: 700;
    margin-right: 15px;
  `,
  removeButton: css`
    color: ${buttonDestructiveColor};
    font-weight: bold;
    align-self: center;
    padding: 0.75rem 0.5rem;
    margin: -0.5rem;
  `,
  addButton: css`
    color: ${buttonConstructiveColor};
    font-weight: bold;
    align-self: center;
    padding: 0.75rem 0.5rem;
    margin: -0.5rem;
  `,
};

const FeedbackQuestion = ({
  question, className, onEditQuestion, tabIndex, disabled, onAddQuestion, showAddQuestionBtn,
}) => {
  const editQuestion = useCallback(() => {
    if (disabled) {
      return;
    }
    onEditQuestion(question);
  }, [disabled, onEditQuestion, question]);

  const addButton = useCallback(() => (
    <div
      onClick={(event) => onAddQuestion(event, question)}
      onKeyUp={(event) => onAddQuestion(event, question)}
      role="button"
      tabIndex={tabIndex}
      css={styles.addButton}
    >
      Add
    </div>
  ), [onAddQuestion, tabIndex, question]);

  /* eslint-disable react/no-danger */
  return (
    <div
      css={disabled ? [styles.box, styles.disabled] : styles.box}
      className={className}
      onClick={editQuestion}
      onKeyUp={editQuestion}
      role="button"
      tabIndex={tabIndex}
    >
      <HTMLRenderer css={styles.textBox} htmlText={question.text} />
      { showAddQuestionBtn && addButton() }
    </div>
  );
  /* eslint-enable react/no-danger */
};

FeedbackQuestion.propTypes = {
  question: PropTypes.shape({
    text: PropTypes.string,
  }),
  className: PropTypes.string,
  onEditQuestion: PropTypes.func,
  onAddQuestion: PropTypes.func,
  showAddQuestionBtn: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
};

FeedbackQuestion.defaultProps = {
  question: {
    text: '',
  },
  className: '',
  onEditQuestion: noop,
  onAddQuestion: noop,
  showAddQuestionBtn: false,
  tabIndex: 0,
  disabled: false,
};

export default FeedbackQuestion;
