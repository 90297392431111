import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  noAgendaText: css({
    color: palette.brand.red,
  }),
  topicsText: css({
    color: palette.brand.indigo,
  }),
  text: css({
    fontSize: '.625rem',
  }),
};

interface TopicCountTextProps extends ComponentProps<'div'> {
  numberOfAgendaItems: number,
}

const TopicCountText = ({
  numberOfAgendaItems,
  ...props
}: TopicCountTextProps): JSX.Element => (
  <div {...props}>
    {numberOfAgendaItems === 0 && (
      <div css={[styles.text, styles.noAgendaText]}>No Agenda</div>
    )}
    {numberOfAgendaItems > 0 && (
      <div css={[styles.text, styles.topicsText]}>{`${numberOfAgendaItems} Topic${numberOfAgendaItems > 1 ? 's' : ''}`}</div>
    )}
  </div>
);

export default TopicCountText;
