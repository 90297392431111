import { createReducer } from '../redux/reducers/index';

const initialState = {
  isIdle: false,
  timeout: 20 * 60 * 1000,
};

const { reducer, registerAction } = createReducer('idle', initialState);

export { reducer, registerAction, initialState };
