import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ViewCard from '~Common/components/Cards/ViewCard';

import {
  palette, userGroupsCard,
} from '~Common/styles/colors';
import SearchBox from '~Common/components/Inputs/SearchBox';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { USER_GROUP_SHAPE } from '~Common/const/proptypes';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useUserGroupSearch } from '~Common/hooks/useUserGroupSearch';
import { noop } from '~Deprecated/utils';
import { PAGE_STYLES } from '~Admin/const/pageStyles';
import Button from '~Common/V3/components/Button';
import { createUserGroupDrawerTemplate } from '../Drawers/CreateUserGroupDrawer';
import { useGetGroups } from '../../hooks/useGetGroups';
import UserGroupsTable, { UserGroupsTableSkeleton } from './UserGroupsTable';
import UserGroupsPagination from './UserGroupsPagination';
import NumberOfRowSelect from './NumberOfRowSelect';
import EmptyTableState from './EmptyTableState';
import AdminPeopleTabNavigation from '../AdminPeopleTabNavigation';

const styles = {
  ...PAGE_STYLES,
  filterSection: css`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
    border-bottom: .0625rem solid ${userGroupsCard.borderColor};
    margin-bottom: 1.5rem;
    align-items: center;
    
    .MuiSelect-icon {
      color: ${palette.brand.indigo};
    }
  `,
};

const View = ({
  onTabClick,
  numberOfRows,
  onNumberOfRowsChange,
  onCreateGroup,
  onRowClick,
  groups,
  isLoading,
  onSearch,
  searchString,
  numberOfPages,
  onPageChange,
  onPreviousClick,
  onNextClick,
  page,
  ...props
}) => (
  <div css={styles.container} {...props}>
    <div css={styles.pageHeader}>
      <AdminPeopleTabNavigation css={styles.tabNavigation} />
      <Button disabled={isLoading} onClick={onCreateGroup} css={styles.createButton} renderContents={() => <div>Create New Group</div>} />
    </div>
    <ViewCard
      css={styles.viewCard}
      renderBody={() => (
        <div>
          <div css={styles.header}>
            <div css={styles.information}>
              <div css={styles.titleText}>Groups</div>
              <div css={styles.description}>
                Create groups that you can easily access in different parts of Leadr, whether that be for creating meetings, or sending feedback requests.
              </div>
            </div>
            <div>
              <SearchBox initialValue={searchString} css={styles.searchBox} placeholder="Search..." onChange={onSearch} />
            </div>
          </div>
          {isLoading && (
            <UserGroupsTableSkeleton />
          )}
          {!isLoading && (
            <>
              {groups.length > 0 && (
                <>
                  <div css={styles.filterSection}>
                    Showing:
                    <NumberOfRowSelect
                      numberOfRows={numberOfRows}
                      onNumberOfRowsChange={onNumberOfRowsChange}
                    />
                  </div>
                  <UserGroupsTable groups={groups} numberOfRows={numberOfRows} page={page} />
                  {numberOfPages > 1 && (
                    <UserGroupsPagination
                      page={page}
                      onPageChange={onPageChange}
                      numberOfPages={numberOfPages}
                      onPreviousClick={onPreviousClick}
                      onNextClick={onNextClick}
                    />
                  )}
                </>
              )}
              {groups.length === 0 && (
                <EmptyTableState />
              )}
            </>
          )}
        </div>
      )}
    />
  </div>
);

View.propTypes = {
  onTabClick: PropTypes.func.isRequired,
  numberOfRows: PropTypes.number.isRequired,
  onNumberOfRowsChange: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fakeRowData: PropTypes.any.isRequired,
  groups: PropTypes.arrayOf(USER_GROUP_SHAPE),
  isLoading: PropTypes.bool,
  onSearch: PropTypes.func,
  searchString: PropTypes.string,
  numberOfPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  webAppTeamsV2: PropTypes.bool.isRequired,
};

View.defaultProps = {
  groups: [],
  searchString: '',
  isLoading: false,
  onSearch: noop,
};

const UserGroups = ({ ...props }) => {
  const [numberOfRows, setNumberOfRows] = useState(10);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const { search: searchString, setSearch } = useUserGroupSearch();

  const { groups, isLoading } = useGetGroups();

  const numberOfPages = Math.ceil(groups.length / numberOfRows);
  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);

  useEffect(() => {
    setPage(1);
  }, [numberOfRows]);

  const hookProps = {
    onSearch: setSearch,
    searchString,
    groups,
    isLoading: showSkeletonLoaders,
    onCreateGroup: () => dispatch(pushDrawerAction({
      drawer: createUserGroupDrawerTemplate,
    })),
    numberOfRows,
    onNumberOfRowsChange: (event) => setNumberOfRows(event.target.value),
    onPageChange: (event) => setPage(event.target.value),
    onPreviousClick: () => setPage(page - 1),
    onNextClick: () => setPage(page + 1),
    numberOfPages,
    page,
    setPage,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

UserGroups.propTypes = {};

UserGroups.defaultProps = {};

export { View };
export default UserGroups;
