import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { produce } from 'immer';
import { toast } from '~Common/components/Toasts';
import {
  DeleteIndividualAgendaTemplateResponse,
  MeetingTemplateType,
} from '~Meetings/components/topic-suggestions/const/types';
import { getIsIndividualQuestionsTemplate } from '~Meetings/components/topic-suggestions/utils/getIsIndividualTopicTemplate';
import { meetingTopicTemplatesQueryKeys } from '../queryKeys';
import { GetMeetingTemplatesResponse } from '../useGetCustomMeetingTemplates';

interface DeleteIndividualTopicParams {
  id: string,
}

const deleteIndividualTopic = ({
  id,
}: DeleteIndividualTopicParams): Promise<HttpCallReturn<DeleteIndividualAgendaTemplateResponse>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates/individual/${id}`;

  return deleteApi<DeleteIndividualAgendaTemplateResponse>(URL);
};

interface UseDeleteMeetingTemplateParams {
  meetingTemplateType: MeetingTemplateType,
  onMutateCallback?: () => void,
}

export const useDeleteIndividualTopic = ({
  meetingTemplateType,
  onMutateCallback,
}: UseDeleteMeetingTemplateParams): UseMutationResult<HttpCallReturn<DeleteIndividualAgendaTemplateResponse>, unknown, DeleteIndividualTopicParams> => {
  const orgId = getOrganizationId() ?? '';

  const queryKeyForList = meetingTopicTemplatesQueryKeys.customTemplatesList({
    orgId,
    meetingTemplateType,
  });

  return useMutation({
    mutationFn: deleteIndividualTopic,
    onMutate: async ({ id }) => {
      onMutateCallback?.();

      const previousMeetingTemplates = queryClient.getQueryData(queryKeyForList);
      await queryClient.cancelQueries({ queryKey: queryKeyForList });

      queryClient.setQueryData<HttpCallReturn<GetMeetingTemplatesResponse>>(queryKeyForList, (originalData) => {
        if (originalData) {
          return produce(originalData, (draft) => {
            const individualQuestionsTemplate = draft.response.meetingTemplates.find(
              (meetingTemplate) => getIsIndividualQuestionsTemplate(meetingTemplate),
            );
            if (individualQuestionsTemplate) {
              const indiviualTopicIndex = individualQuestionsTemplate.children.findIndex((child) => child.id === id);

              if (indiviualTopicIndex !== -1) {
                individualQuestionsTemplate.children.splice(indiviualTopicIndex, 1);
              }
            }
          });
        }

        return originalData;
      });

      return { previousMeetingTemplates };
    },
    onError: (_, __, snapshot) => {
      toast.error('There was an error deleting the topic. Please try again.', {
        autoClose: 1500,
      });

      queryClient.setQueryData(queryKeyForList, snapshot?.previousMeetingTemplates);
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: meetingTopicTemplatesQueryKeys.customTemplatesLists(getOrganizationId() ?? '') });
    },
  });
};
