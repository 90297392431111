import PropTypes from 'prop-types';
import { useState } from 'react';
import { css } from '@emotion/react';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';
import DrawerHeader from '~Deprecated/ui/components/DrawerManager/DrawerHeader';
import AttendeeItem from '~Surveys/Components/Drawers/SurveyParticipants/AttendeeItem';
import { BUTTON_VARIANTS } from '~Deprecated/ui/components/Buttons/Button';
import DrawerButton from '~Deprecated/ui/components/Buttons/DrawerButton';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useRecallSurvey } from '~Surveys/Hooks/useRecallSurvey';
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { useDispatch } from 'react-redux';
import { useSurveyDetail } from '../../../Hooks/useSurveysList';

export const surveyParticipantsDrawerTemplate = {
  name: 'SURVEY_PARTICIPANTS_DRAWER',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};

const styles = {
  mainContainer: css({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
  }),
  participantCard: css({
    marginTop: '5px',
  }),
  respondedHeader: css({
    marginTop: '12px',
  }),
  sectionHeader: css({
    marginTop: '42px',
  }),
  footerButtons: css({
    marginTop: '1.5rem',
  }),
};

const SurveyParticipantsDrawer = ({
  popDrawer,
  defaultFilter,
  disableFilter,
  customFilter,
  filterOptions,
  getParticipantsIndex,
  surveyId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { survey } = useSurveyDetail({
    surveyId,
    withAnswers: true,
    withParticipants: true,
    withQuestions: true,
  });

  const { surveyParticipants: currentAttendees } = survey;
  const [currentAttendeesState, setCurrentAttendeesState] = useState(currentAttendees);
  const completedParticipants = currentAttendeesState.filter((participant) => !!participant.completedOn).map((participant) => (participant.participant));
  const incompleteParticipants = currentAttendeesState.filter((participant) => !participant.completedOn).map((participant) => (participant.participant));

  const closeCurrentDrawer = () => popDrawer({
    drawerName: surveyParticipantsDrawerTemplate.name,
  });

  const doneButton = () => (
    <DrawerButton variant={BUTTON_VARIANTS.NEUTRAL} onClick={closeCurrentDrawer} text="Done" />
  );

  const recallSurveyMutation = useRecallSurvey();
  const recallAll = () => {
    const participants = currentAttendees.map((participant) => (participant.id));
    recallSurveyMutation({ id: surveyId, participants }, { onSuccess: onRecallAllSuccess });
  };

  const onRecallAllSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'survey', 'assignedSurveys', surveyId] });
    dispatch(popDrawerAction({ popAll: true }));
  };

  const recallAttendee = (attendeeId) => {
    const targetIndex = currentAttendees.findIndex((item) => item.participantUid === attendeeId);
    const participantId = currentAttendees[targetIndex].id;
    recallSurveyMutation({ id: surveyId, participants: [participantId] }, { onSuccess: () => onRecallAttendeeSuccess(participantId) });
  };

  const onRecallAttendeeSuccess = (idToRemove) => {
    // Doing this to clear the data and get the new data from the server
    queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'survey', 'assignedSurveys', surveyId] });

    // doing this because we're using drawer state here and it's not updating because it sucks
    const filteredAttendees = currentAttendees.filter((participant) => participant.id !== idToRemove);
    setCurrentAttendeesState(filteredAttendees);
  };

  return (
    <div
      css={styles.mainContainer}
    >
      <DrawerLayout
        renderHeader={() => (
          <DrawerHeader
            title="Participants"
            renderRightButton={() => doneButton()}
          />
        )}
        renderBody={() => (
          <>
            <div css={styles.respondedHeader}>
              <h4>
                Responded
              </h4>
              {completedParticipants.map((participant) => (
                <AttendeeItem
                  id={participant.uid}
                  key={participant.uid}
                  isAttendeeSelected={() => false}
                  onClick={() => getParticipantsIndex(participant)}
                  css={styles.participantCard}
                  {...props}
                />
              ))}
              {completedParticipants.length === 0 && (
                <div>No participants have completed the survey.</div>
              )}
            </div>
            <div css={styles.sectionHeader}>
              <h4>
                No Response
              </h4>
              {incompleteParticipants.map((participant) => (
                <AttendeeItem
                  id={participant.uid}
                  key={participant.uid}
                  isAttendeeSelected={() => false}
                  onClick={() => getParticipantsIndex(participant)}
                  css={styles.participantCard}
                  surveyId={surveyId}
                  recallAttendee={recallAttendee}
                  hideHover
                  canRecall={survey?.completedOn === null}
                  {...props}
                />

              ))}
              {incompleteParticipants.length === 0 && (
                <div>All participants have completed the survey.</div>
              )}
            </div>
            {completedParticipants.length === 0 && incompleteParticipants.length !== 0 && survey?.completedOn === null && (
              <div css={styles.footerButtons}>
                <LeadrButton
                  css={styles.saveButton}
                  data-test-id="surveysRecall"
                  size="small"
                  color="primary"
                  variant="ghost"
                  onClick={() => recallAll()}
                >
                  <LeadrButton.IconAndText icon={faRotateRight} text="Recall All" />
                </LeadrButton>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

SurveyParticipantsDrawer.propTypes = {
  popDrawer: PropTypes.func.isRequired,
  getParticipantsIndex: PropTypes.func.isRequired,
  disableFilter: PropTypes.bool,
  defaultFilter: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  customFilter: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  filterOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  surveyId: PropTypes.string.isRequired,
};

SurveyParticipantsDrawer.defaultProps = {
  disableFilter: false,
  defaultFilter: null,
  customFilter: [],
  filterOptions: [],
};

registerDrawer({ templateName: surveyParticipantsDrawerTemplate.name, component: SurveyParticipantsDrawer });

export default SurveyParticipantsDrawer;
