import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { useGetPendingSurveys } from '~Surveys/Hooks/useSurveysList';

export interface UserPendingItems {
  pendingActionItemCount: number,
  pendingFeedbackCount: number,
  pendingLearningCount: number,
}

export const getUserItemsPending = async (): Promise<HttpCallReturn<UserPendingItems>> => getApi(
  `/organizations/${getOrganizationId() ?? ''}/users/items/pending`,
);

interface UseUserPendingItemsProps {
  detail: UseQueryResult<HttpCallReturn<UserPendingItems>, unknown>,
  isLoading: boolean,
  data: UserPendingItems | undefined,
  hasIncompleteFeedback: boolean,
  hasIncompleteLearning: boolean,
  hasIncompleteActionItems: boolean,
  hasIncompleteSurveys: boolean,
}

export const USER_PENDING_ITEMS_QUERY_KEY = [getOrganizationId(), 'user', 'pending', 'items'];

export const useUserPendingItems = (): UseUserPendingItemsProps => {
  const detail = useQuery({
    queryKey: USER_PENDING_ITEMS_QUERY_KEY,
    queryFn: getUserItemsPending,
    // Shorten this so there is quicker feedback on these dots.
    staleTime: 60 * 1000 * 2,
  });

  const data = detail?.data?.response;

  const { surveys } = useGetPendingSurveys() as { surveys: number };

  return {
    detail,
    isLoading: detail?.isLoading,
    data,
    hasIncompleteFeedback: !!data?.pendingFeedbackCount && data?.pendingFeedbackCount > 0,
    hasIncompleteActionItems: !!data?.pendingActionItemCount && data?.pendingActionItemCount > 0,
    hasIncompleteLearning: !!data?.pendingLearningCount && data?.pendingLearningCount > 0,
    hasIncompleteSurveys: !!surveys,
  };
};
