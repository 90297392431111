import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';
import { useEffect } from 'react';
import { SCRequestedLearningDetail } from '../../const/interfaces';
import { assignedLearningQueryKeys } from './queryKeys';

interface GetAssignedLearningDetailParams {
  learningId: string,
  isReadOnly?: boolean,
  viewPerspectiveUid?: string,
}

const getAssignedLearningDetail = ({
  learningId,
  isReadOnly,
  viewPerspectiveUid,
}: GetAssignedLearningDetailParams): Promise<HttpCallReturn<SCRequestedLearningDetail>> => {
  const queryString = buildQueryString({
    isReadOnly,
    viewPerspectiveUid,
  });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/requested/${learningId}${queryString}`;

  return getApi<SCRequestedLearningDetail>(serverUrl);
};

interface RequestedLearningDetailHookParams {
  learningId: string,
  isReadOnly?: boolean,
  viewPerspectiveUid?: string,
}

interface RequestedLearningDetailHookReturn {
  detail: SCRequestedLearningDetail | undefined,
  isLoading: boolean,
}

export const useGetAssignedLearningDetail = ({
  learningId,
  isReadOnly,
  viewPerspectiveUid,
}: RequestedLearningDetailHookParams): RequestedLearningDetailHookReturn => {
  const dispatch = useDispatch();

  const result = useQuery({
    queryKey: assignedLearningQueryKeys.singleContentDetail(learningId),
    queryFn: () => getAssignedLearningDetail({
      learningId,
      isReadOnly,
      viewPerspectiveUid,
    }),
    meta: {
      errorMessage: 'There was an error getting the learning information. Please try again.',
    },
  });

  useEffect(() => {
    if (result.isError) {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));
    }
  }, [dispatch, result.isError]);

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
