import { FormEvent, useCallback, useMemo } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { MyScheduleMeeting } from '~Home/const/interfaces';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TeamsListScope } from '~People/components/Teams/const/types';
import { useCreateHuddle } from '~Meetings/hooks/CreateMeeting/useCreateHuddle';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { MeetingFactoryType } from '~Meetings/const/meetingsInterfaces';
import { palette } from '~Common/styles/colors';

const styles = {
  description: css({
    color: palette.neutrals.gray700,
    marginBottom: '1.25rem',
  }),

  dropdown: css({
    padding: '0.5rem 0.75rem',
    marginBottom: '1.25rem',

    '.MuiInput-root': {
      display: 'flex',
      alignItems: 'center',
    },

    '.MuiSelect-select': {
      lineHeight: '20px',
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 0,
    },
  }),

  createMeetingButton: css({
    width: '100%',
  }),
};

export const linkTeamDrawerTemplate = {
  name: 'LINK_TEAM_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

interface ViewProps {
  items: DropdownItem[]
  closeDrawerClick: () => void,
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
  initialTeamId?: string,
}

const View = ({
  items,
  closeDrawerClick,
  onSubmit,
  initialTeamId,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            size="large"
            tooltip="Close"
          />
        )}
        title={initialTeamId !== undefined ? 'Edit Team Link' : 'Import Meeting'}
      />
    )}
    renderBody={(defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <form onSubmit={onSubmit}>
          <div css={styles.description}>
            Link a team to this meeting to display related team Goals and Tools.
          </div>

          <Dropdown
            css={styles.dropdown}
            name="teamId"
            showEmptyOption
            value={initialTeamId ?? ''}
            items={items}
            label="Link Team (Optional)"
            emptyOptionText={initialTeamId !== undefined ? 'Ø - No Team' : undefined}
          />

          <LeadrButton
            css={styles.createMeetingButton}
            type="submit"
            data-test-id="meetingsLinkTeam"
          >
            {initialTeamId !== undefined ? 'Save Changes' : 'Import Meeting'}
          </LeadrButton>
        </form>
      </div>
    )}
  />
);

export interface LinkTeamFormData {
  teamId: string,
}

interface LinkTeamDrawerProps extends DrawerProps<Record<string, unknown>> {
  meeting: MyScheduleMeeting
}

const LinkTeamDrawer = ({
  meeting,
  popDrawer,
}: LinkTeamDrawerProps): JSX.Element => {
  const { createHuddleMutation } = useCreateHuddle();
  const { timezone } = useTimezone();

  const { data } = useGetTeams({
    listScope: TeamsListScope.MyTeams,
    page: 0,
    count: 200,
  });

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const linkTeamFormData = Object.fromEntries(formData.entries()) as unknown as LinkTeamFormData;
    const { teamId } = linkTeamFormData;

    const attendeesExcludingOrganizerOrgUserIds = meeting.attendeesExcludingOrganizer.map((attendee) => attendee.orgUserId);
    const allAttendeeOrgUserIds = [...attendeesExcludingOrganizerOrgUserIds, meeting.organizer.orgUserId];

    const huddlePayload = {
      startTimeInMillis: meeting.startTimeInMillis,
      endTimeInMillis: meeting.endTimeInMillis,
      title: meeting.title,
      frequency: meeting.frequency,
      meetingType: MeetingFactoryType.TEAM_MEETING,
      attendeeIds: allAttendeeOrgUserIds,
      ...(meeting.location && { location: meeting.location }),
      ...(timezone && { timezone }),
      ...(meeting.daysOfWeek?.length && { daysOfWeek: meeting.daysOfWeek }),
      ...(teamId && { teamId }),
      nylasEventId: meeting.nylasEventId,
      nonLeadrAttendees: meeting.nonLeadrAttendees,
    };

    createHuddleMutation(huddlePayload);
  }, [
    meeting,
    timezone,
    createHuddleMutation,
  ]);

  const closeDrawerClick = (): void => {
    popDrawer({ popAll: true });
  };

  const items = useMemo(() => (
    data?.response.teams.map((team) => ({
      value: team.teamId,
      text: team.name,
    })) as DropdownItem[]
  ), [
    data?.response,
  ]);

  const hookProps = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    items,
    closeDrawerClick,
    onSubmit,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: linkTeamDrawerTemplate.name,
  component: LinkTeamDrawer,
});

export default LinkTeamDrawer;

export { View as LinkTeamDrawerView };
