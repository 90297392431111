import { AgendaTemplates } from '@leadr-hr/types';
import { createContext } from 'react';

interface MeetingInfoContext {
  huddleId: string,
  meetingTemplateType: AgendaTemplates.MeetingTemplateType,
}

export const MeetingInfoContext = createContext<MeetingInfoContext>({
  huddleId: '',
  meetingTemplateType: AgendaTemplates.MeetingTemplateType.Coaching,
});
