import { useCallback } from 'react';
import { Person } from '~Common/const/interfaces';
import { getUserId } from '~Common/utils/localStorage';
import { MyScheduleDay, MyScheduleMeeting } from '~Home/const/interfaces';
import { useGenerateFormattedDayString } from '~Home/hooks/utils/useGenerateFormattedDayString';
import { MeetingFrequency, MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useNewPeople } from '../../../deprecated/hooks/peoplePicker/useNewPeople';
import { UnsyncedLeadrMeeting } from './useGetUnsyncedLeadrMeetings';
import { UnsyncedCalendarMeeting } from './useGetIgnoredUnsyncedCalendarMeetings';

interface UseConvertToMyScheduleMeetingsReturn {
  convertUnsyncedCalendarMeetingToMyScheduleMeeting: (calendarEvent: UnsyncedCalendarMeeting, areIgnoredMeetings: boolean) => MyScheduleMeeting,
  convertUnsyncedLeadrMeetingToMyScheduleMeeting: (unsyncedLeadrMeeting: UnsyncedLeadrMeeting) => MyScheduleMeeting,
  groupMyScheudleMeetingsByDate: (meetings: MyScheduleMeeting[]) => MyScheduleDay[],
}

export const useConvertToMyScheduleMeetings = (): UseConvertToMyScheduleMeetingsReturn => {
  const { peopleData } = useNewPeople({}) as { peopleData: Record<string, Person>};
  const { generateFormattedDayString } = useGenerateFormattedDayString();

  const convertPersonToMeetingPerson = useCallback((person: Person) => ({
    firstName: person.firstName,
    userId: person.userId,
    orgUserId: person.orgUserId,
    lastName: person.lastName,
    jobTitle: person.jobTitle,
    profileImageUrl: person.profileImageUrl,
  }), []);

  const convertUnsyncedCalendarMeetingToMyScheduleMeeting = useCallback((unsyncedCalendarMeeting: UnsyncedCalendarMeeting, areIgnoredMeetings: boolean) => {
    const ownerOrgUserId = unsyncedCalendarMeeting.orgUserId;
    const attendeeIdsExcludingOrganizer = unsyncedCalendarMeeting.attendeeId.filter((attendeeId) => attendeeId !== ownerOrgUserId);

    const peopleDataWeCareAbout = attendeeIdsExcludingOrganizer.map((attendeeId) => peopleData[attendeeId]);
    // Filter peopleDataArray to remove duplicates
    const uniquePeopleDataWeCareAbout = peopleDataWeCareAbout.filter(
      (person, index, people) => people.findIndex((uniquePerson) => uniquePerson.orgUserId === person.orgUserId) === index,
    );
    const ownerAsPerson = peopleData[ownerOrgUserId];
    const organizerAsMeetingPerson = convertPersonToMeetingPerson(ownerAsPerson);

    const attendeesExcludingOrganizer = uniquePeopleDataWeCareAbout.map((person) => convertPersonToMeetingPerson(person));

    return {
      title: unsyncedCalendarMeeting.title,
      location: unsyncedCalendarMeeting.location,
      frequency: unsyncedCalendarMeeting.frequency,
      daysOfWeek: unsyncedCalendarMeeting.daysOfWeek,
      startTimeInMillis: unsyncedCalendarMeeting.startTimeInMillis,
      endTimeInMillis: unsyncedCalendarMeeting.endTimeInMillis,
      nylasEventId: unsyncedCalendarMeeting.eventId,
      meetingInstanceId: '', // Not defined since the meeting hasn't been synced yet
      meetingFactoryId: unsyncedCalendarMeeting.dgraphMeetingFactoryId,
      organizer: organizerAsMeetingPerson,
      attendeesExcludingOrganizer,
      numberOfAttendeesExcludingOrganizer: attendeesExcludingOrganizer.length + unsyncedCalendarMeeting.nonLeadrAttendees.length,
      numberOfAgendaItems: 0, // Not defined since the meeting hasn't been synced yet
      meetingType: null as unknown as MeetingTypeEnum, // Not defined since the meeting hasn't been synced yet
      isRecurringSeries: unsyncedCalendarMeeting.frequency !== MeetingFrequency.ONE_TIME,
      isOnlyInExternalCalendar: true,
      linkToUsersExternalCalendar: '', // Not defined since the meeting hasn't been synced yet
      isIgnored: areIgnoredMeetings,
      nonLeadrAttendees: unsyncedCalendarMeeting.nonLeadrAttendees,
    };
  }, [peopleData, convertPersonToMeetingPerson]);

  const convertUnsyncedLeadrMeetingToMyScheduleMeeting = useCallback((unsyncedLeadrMeeting: UnsyncedLeadrMeeting) => {
    const ownerUserId = getUserId() ?? '';
    const attendeeIdsExcludingOrganizer = unsyncedLeadrMeeting.attendeeIds.filter((attendeeId) => attendeeId !== ownerUserId);

    const peopleDataWeCareAbout = attendeeIdsExcludingOrganizer.map((attendeeId) => peopleData[attendeeId]);
    // Filter peopleDataArray to remove duplicates
    const uniquePeopleDataWeCareAbout = peopleDataWeCareAbout.filter(
      (person, index, people) => people.findIndex((uniquePerson) => uniquePerson.orgUserId === person.orgUserId) === index,
    );

    const ownerAsPerson = peopleData[ownerUserId];
    const organizerAsMeetingPerson = convertPersonToMeetingPerson(ownerAsPerson);

    const attendeesExcludingOrganizer = uniquePeopleDataWeCareAbout.map((person) => convertPersonToMeetingPerson(person));

    return {
      title: unsyncedLeadrMeeting.title,
      location: unsyncedLeadrMeeting.location,
      frequency: unsyncedLeadrMeeting.frequency,
      daysOfWeek: undefined,
      startTimeInMillis: unsyncedLeadrMeeting.startTimeInMillis,
      endTimeInMillis: unsyncedLeadrMeeting.endTimeInMillis,
      nylasEventId: '', // No Nylas event since this is an unsynced Leadr Meeting
      meetingInstanceId: '', // Not defined since the meeting hasn't been synced yet
      meetingFactoryId: unsyncedLeadrMeeting.meetingFactoryId,
      organizer: organizerAsMeetingPerson,
      attendeesExcludingOrganizer,
      numberOfAttendeesExcludingOrganizer: attendeesExcludingOrganizer.length,
      numberOfAgendaItems: 0, // Not sent from the backend
      meetingType: null as unknown as MeetingTypeEnum, // Not sent from the backend
      isRecurringSeries: unsyncedLeadrMeeting.frequency !== MeetingFrequency.ONE_TIME,
      isOnlyInExternalCalendar: true,
      linkToUsersExternalCalendar: '', // Not defined since the meeting hasn't been synced yet
      isIgnored: false,
      nonLeadrAttendees: unsyncedLeadrMeeting.nonLeadrAttendees,
    };
  }, [peopleData, convertPersonToMeetingPerson]);

  const groupMyScheudleMeetingsByDate = useCallback((myScheduleMeetings: MyScheduleMeeting[]) => {
    const myScheduleDays: MyScheduleDay[] = [];

    myScheduleMeetings.forEach((myScheduleMeeting) => {
      const dayString = generateFormattedDayString(myScheduleMeeting.startTimeInMillis);

      const findDayToPutMeetingIndex = myScheduleDays.findIndex((day) => day.day === dayString);

      if (findDayToPutMeetingIndex === -1) {
        myScheduleDays.push({
          day: dayString,
          meetings: [myScheduleMeeting],
        });
      } else {
        myScheduleDays[findDayToPutMeetingIndex].meetings.push(myScheduleMeeting);
      }
    });

    return myScheduleDays;
  }, [generateFormattedDayString]);

  return {
    convertUnsyncedCalendarMeetingToMyScheduleMeeting,
    convertUnsyncedLeadrMeetingToMyScheduleMeeting,
    groupMyScheudleMeetingsByDate,
  };
};
