import { faPenToSquare, faTrash } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import {
  RECOGNITION_DEFAULT_DEPARTMENT, RECOGNITION_DEFAULT_END_DATE, RECOGNITION_DEFAULT_START_DATE, RECOGNITION_DEFAULT_USER_GROUP,
} from './defaults';
import {
  ActionMenuActions, InfiniteQueryParamsProps, RecognitionShape, RecognitionUserShape,
} from './types';

export const getRecognizedMessage = (loggedInUserRecognized: boolean, recipients: RecognitionUserShape[]): string => {
  // if the logged in person is within the recipients list
  if (loggedInUserRecognized) {
    const message = 'You';
    return message;
  }

  // if we have only one person being recognized and it is not the logged in person
  const message = `${recipients[0].firstName} ${recipients[0].lastName}`;
  return message;
};

export const getCreatedByMessage = (createdBy: RecognitionUserShape): string => {
  if (getOrganizationUserId() === createdBy.orgUserId) {
    return 'You recognized';
  }
  return `${createdBy.firstName} ${createdBy.lastName} recognized`;
};

export const setRecognitionMenuItems = (
  recognition: RecognitionShape,
  handleActionmenuClick: (type: ActionMenuActions, id: number) => void,
): MenuItem[][] => {
  const { canDelete, canEdit } = recognition;

  const items: MenuItem[][] = [];

  if (canEdit) {
    items.push([{
      text: 'Edit Post',
      icon: faPenToSquare,
      onClick: () => handleActionmenuClick(ActionMenuActions.Edit, recognition.recognitionId),
    },
    ]);
  }

  if (canDelete) {
    items.push([{
      text: 'Delete',
      icon: faTrash,
      onClick: () => handleActionmenuClick(ActionMenuActions.Delete, recognition.recognitionId),
    },
    ]);
  }

  return items;
};

interface GetStartAndEndDatesProps {
  returnedStartDate: string | undefined;
  returnedEndDate: string | undefined;
}

export const getStartAndEndDates = (startDate: string | undefined, endDate: string | undefined): GetStartAndEndDatesProps => {
  const convertedStartDate = moment(startDate);
  const convertedEndDate = moment(endDate);

  // If the dates are the same we want to add a day to the end date because of how the BE is handling the dates
  // If the start date is greater that the end date we want to set the end date to the start date + 1 day
  // Lastly if the start date and end date are the default dates we want to set them to undefined
  if (convertedStartDate >= convertedEndDate && startDate !== RECOGNITION_DEFAULT_START_DATE && endDate !== RECOGNITION_DEFAULT_END_DATE
   && (startDate !== undefined && endDate !== undefined)) {
    return {
      returnedStartDate: convertedStartDate.format('MM/DD/YYYY'),
      returnedEndDate: convertedStartDate.add(1, 'd').format('MM/DD/YYYY'),
    };
  }

  if (startDate !== RECOGNITION_DEFAULT_START_DATE || endDate !== RECOGNITION_DEFAULT_END_DATE) {
    return {
      returnedStartDate: startDate,
      returnedEndDate: endDate,
    };
  }
  return {
    returnedStartDate: undefined,
    returnedEndDate: undefined,
  };
};

export const getIsFiltering = (params: InfiniteQueryParamsProps): boolean => {
  const lookupTable: Record<keyof InfiniteQueryParamsProps, string> = {
    department: RECOGNITION_DEFAULT_DEPARTMENT.value,
    search: '',
    userGroup: RECOGNITION_DEFAULT_USER_GROUP.value,
    startDate: RECOGNITION_DEFAULT_START_DATE,
    endDate: RECOGNITION_DEFAULT_END_DATE,
  };

  return Object.keys(lookupTable).some((prop: keyof InfiniteQueryParamsProps) => params[prop] !== lookupTable[prop]);
};
