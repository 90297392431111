import {
  object,
  string,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const requiredMsg = {
  signoffComment: 'You must have a comment to sign-off.',
};

export const reviewSignoffFormSchema = object({
  signoffComment: string().trim().required(requiredMsg.signoffComment),
});

export const reviewSignoff = yupResolver(reviewSignoffFormSchema);

export type FormValues = InferType<typeof reviewSignoffFormSchema>;

export interface ReviewSignoffDTO {
    signoffComment: string,
  }

export function conformToDto(data: FormValues): ReviewSignoffDTO {
  const { signoffComment } = data;
  return {
    signoffComment,
  };
}
