import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import {
  topicItemBg, topicItemColor,
} from '~Deprecated/ui/styles/colors';
import { noop } from '~Deprecated/utils';
import { palette, surveyQuestionCardColors } from '~Common/styles/colors';
import { DRAG_HANDLE_PROPS_SHAPE, SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';
import { withTruncate } from '~Common/styles/mixins';
import { useGetSurveyTypes } from '../../Hooks/useSurveyTypes';

const styles = {
  box: css({
    backgroundColor: topicItemBg,
    borderRadius: '.5rem',
    color: topicItemColor,
    display: 'flex',
    overflow: 'hidden',
    lineHeight: '1.65em',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: '.75rem 0 .75rem 1rem',
  }),
  disabled: css({
    cursor: 'unset',
  }),
  questionType: css({
    fontSize: '0.75rem',
    color: surveyQuestionCardColors.type,
  }),
  dragIcon: (isDragDisabled) => css({
    marginRight: '1.25rem',
    color: palette.neutrals.gray700,
  }, isDragDisabled && {
    display: 'none',
  }),
  surveyQuestionDetails: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  container: css({
    display: 'flex',
    alignItems: 'center',
    width: '90%',
  }),
  editIcon: css({
    padding: '0 1rem',
    color: palette.neutrals.gray700,
  }),
  textBox: css({
    maxWidth: '90%',
  }, withTruncate()),
};

const SurveyQuestionCard = ({
  question, className, onEditQuestion, tabIndex, disabled, dragHandleProps, isDragDisabled = false,
}) => {
  const editQuestion = useCallback(() => {
    if (disabled) {
      return;
    }
    onEditQuestion(question);
  }, [disabled, onEditQuestion, question]);

  const { QuestionTypes } = useGetSurveyTypes({});

  // This is a hack to get around the fact that surveys mutate the survey question shape at some point.
  const questionTypeText = (typeof question.type === 'number')
    ? Object.values(QuestionTypes).find((type) => type.id === question.type)?.description ?? ''
    : Object.values(QuestionTypes).find((type) => type.id === question.type.key)?.description ?? '';

  /* eslint-disable react/no-danger */
  return (
    <div
      css={disabled ? [styles.box, styles.disabled] : styles.box}
      className={className}
      onClick={editQuestion}
      onKeyUp={editQuestion}
      role="button"
      tabIndex={tabIndex}
    >
      <div css={styles.container}>
        <div css={styles.dragIcon(isDragDisabled)} {...dragHandleProps}>
          <FontAwesomeIcon size="lg" icon={faGripVertical} />
        </div>
        <div css={styles.surveyQuestionDetails}>
          <div css={styles.textBox}>{question.question}</div>
          <div css={styles.questionType}>{questionTypeText}</div>
        </div>
      </div>
      <div css={styles.editIcon}>
        <FontAwesomeIcon icon={faEdit} />
      </div>
    </div>
  );
};

SurveyQuestionCard.propTypes = {
  question: SURVEY_QUESTION_SHAPE,
  className: PropTypes.string,
  onEditQuestion: PropTypes.func,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  dragHandleProps: DRAG_HANDLE_PROPS_SHAPE.isRequired,
  isDragDisabled: PropTypes.bool,
};

SurveyQuestionCard.defaultProps = {
  question: null,
  className: '',
  onEditQuestion: noop,
  tabIndex: 0,
  disabled: false,
  isDragDisabled: false,
};

export default SurveyQuestionCard;
