import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { noop } from '~Deprecated/utils';
import { VALIDATION_DEFAULT, validationShape } from '~Common/const/forms';
import { drawerInputDisabled, drawerInputLabelText } from '~Deprecated/ui/styles/colors';
import { drawerHeaderFontColor, drawerInputBackground, palette } from '~Common/styles/colors';

import { withBaseFont } from '~Deprecated/ui/styles/typography';
import { EMOTION_CSS_SHAPE, FONT_AWESOME_ICON } from '~Common/const/proptypes';
import { withTruncate } from '~Common/styles/mixins';

const styles = css`
  background-color: ${drawerInputBackground};
  border-radius: 8px;
  width: 100% !important;
  padding: 7px 16px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;

  & .MuiInputBase-root {
    width: 100%;
  }

  & .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before {
    display: none;
    border: 0;
  }

  & .MuiFocused {
    color: ${drawerInputLabelText};
  }

  & .MuiFormLabel-root {
    color: ${palette.neutrals.gray500};
    font-size: 12px;
    font-weight: bold;
    line-height: 1em;
    position: static;
    transform: unset !important;
    padding-top: 2px;
    margin: 0;

    &.Mui-focused {
      color: ${drawerInputLabelText} !important;
    }
  }

  & .MuiInput-root {
    margin: 0;
    color: ${drawerHeaderFontColor};
    font-weight: bold;
  }

  & .MuiSelect-icon {
    top: calc(50% - 13px);
    color: ${palette.brand.indigo};
  }

  & .MuiSelect-select:focus {
    background: ${drawerInputBackground};
  }
`;

const wrapperStyles = css`
  width: 100%; // Changed to 100%
  position: relative;

  & .MuiButtonBase-root {
    color: ${palette.neutrals.gray900};
  }

  & > div {
    width: 100%; // Changed to 100%
  }

  label {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
`;

const inputWrapperStyles = css`
  display: flex;
`;

const disabledInput = css`
  background: ${drawerInputDisabled};
  border: unset !important;
`;

const iconStyles = css`
  margin-right: 10px;
  top: 6px;
  position: relative;
  color: ${palette.brand.indigo};
`;

const menuStyles = css`
  ${withBaseFont()}
  font-weight: 700 !important;
`;

const itemText = css({
  color: palette.neutrals.gray800,
}, withTruncate());

const emptyOptionText = css({
  color: palette.neutrals.gray500,
});

/**
 * @deprecated Old Dropdown UI, use  packages/leadr-frontend/src/common/V3/components/Dropdown.tsx instead
 */
const DrawerDropdown = ({
  label,
  onChange,
  items,
  value,
  className,
  variant,
  name,
  size,
  validation,
  disabled,
  fontAwesomeIcon,
  labelStyles,
  getItems,
  showEmptyOption,
  ...props
}) => {
  const [currentValue, updateState] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const doChange = (event) => {
    if (onChange) onChange(event);
    updateState(event.target.value);
  };
  const itemsToUse = getItems ? (getItems(isOpen)) : (items);

  const onOpen = () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 6);
    clearTimeout();
  };
  const onClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    updateState(value);
  }, [value]);

  return (
    <FormControl
      css={[
        styles,
        (disabled ? disabledInput : ''),
      ]}
      className={className}
      variant={variant}
    >
      <div css={wrapperStyles}>
        {label && (
          <InputLabel css={labelStyles}>
            {label}
          </InputLabel>
        )}
        <div
          css={inputWrapperStyles}
        >
          {fontAwesomeIcon && (
            <FontAwesomeIcon
              css={iconStyles}
              icon={fontAwesomeIcon}
            />
          )}
          <Select
            value={currentValue}
            onChange={doChange}
            label={label}
            name={name}
            disabled={disabled}
            inputProps={{
              ...validation,
            }}
            onOpen={getItems ? (onOpen) : (null)}
            onClose={getItems ? (onClose) : (null)}
            displayEmpty
            {...props}
          >
            {showEmptyOption && (
              <MenuItem
                value=""
                size={size}
                css={menuStyles}
              >
                <div css={[itemText, emptyOptionText]}>
                  Select
                </div>
              </MenuItem>
            )}

            {itemsToUse.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                size={size}
                css={menuStyles}
              >
                <div css={itemText}>
                  {item.text}
                </div>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </FormControl>
  );
};

DrawerDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    text: PropTypes.string,
  })),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  getItems: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard']),
  name: PropTypes.string.isRequired,
  validation: validationShape,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  fontAwesomeIcon: FONT_AWESOME_ICON,
  labelStyles: EMOTION_CSS_SHAPE,
  showEmptyOption: PropTypes.bool,
};

DrawerDropdown.defaultProps = {
  items: [],
  onChange: noop,
  value: 0,
  label: '',
  className: '',
  variant: 'standard',
  validation: VALIDATION_DEFAULT,
  size: undefined,
  disabled: false,
  fontAwesomeIcon: null,
  labelStyles: null,
  getItems: null,
  showEmptyOption: false,
};

export default DrawerDropdown;
