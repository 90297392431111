import { getOrganizationId } from '~Common/utils/localStorage';

export const recognitionMainKey = {
  all: [getOrganizationId() ?? '', 'recognition'] as const,
};

interface RecognitionParams {
  startDate: string | undefined;
  endDate: string | undefined;
  department: string | undefined;
  search: string | undefined;
  userGroup: string | undefined;
  take?: number | undefined;
  recipientOrgUserIds?: string[] | undefined;
}

export const recognitionKeys = {
  all: [...recognitionMainKey.all, 'templates'] as const,
  lists: () => [...recognitionKeys.all, 'lists'] as const, // Basically the landing page
  list: (filters: RecognitionParams) => [...recognitionKeys.lists(), 'list', filters] as const,
  details: () => [...recognitionKeys.all, 'details'] as const,
  detail: (id: number) => [...recognitionKeys.details(), id?.toLocaleString()] as const,
};
