import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { getOrganizationId } from '~Common/utils/localStorage';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { toast } from '~Common/components/Toasts';
import { postApi } from '~Deprecated/services/HttpService';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';

const createFeedbackMutation = ({ feedback, requestedTo }) => {
  const url = {
    host: hosts.feedback,
    uri: `/organizations/${getOrganizationId()}/feedbacks`,
  };

  return postApi(url, { ...feedback, requestedTo }, {});
};

const createFeedbackQuestionMutation = ({ questions, id, requestedTo }) => {
  const url = {
    host: hosts.feedback,
    uri: `/organizations/${getOrganizationId()}/questions`,
  };

  return postApi(url, { questions, assigneeIds: requestedTo, context: { type: 'FEEDBACK', id } }, {});
};

export const useCreateFeedback = ({ queryKey = [getOrganizationId(), 'feedback'], template, nextMutation }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: createFeedbackMutation,
    onMutate: ({ feedback }) => {
      toastId.current = toast.info(`Creating ${feedback.feedbackTitle}...`, { autoClose: false });
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error creating the feedback request. Please check that all fields have been filled out and try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (template) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...template,
              args: {
                ...variables,
              },
            },
          }));
        });
      }
    },
    onSuccess: (data, variables, context) => {
      nextMutation({
        ...variables,
        id: data.response.feedbackId,
        queryKey,
        toastId,
      });

      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
    },
  });

  return mutation.mutate;
};

export const useAddQuestionsToFeedback = ({ queryKey = [getOrganizationId(), 'feedback'], template }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  let feedbackTitle = '';

  const mutation = useMutation({
    mutationFn: createFeedbackQuestionMutation,
    onMutate: (variables) => {
      toastId.current = variables.toastId.current;
      feedbackTitle = variables.feedback.feedbackTitle;
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error creating the feedback request. Please check that all fields have been filled out and try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (template) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...template,
              args: {
                feedbackContext: { ...variables },
              },
            },
          }));
        });
      }
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: `Successfully Created ${feedbackTitle}`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
    },

  });

  return mutation.mutate;
};
