import { css } from '@emotion/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TooltipOptions,
  ChartOptions,
  CoreChartOptions,
} from 'chart.js';
import { merge } from 'lodash';
import { Line } from 'react-chartjs-2';
import { palette } from '~Common/styles/colors';
import { forMobile } from '~Deprecated/ui/styles/mixins';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { GraphDataset } from '~Insights/const/dataUtils';
import { RefObject, useRef } from 'react';
import { variantPalette } from '~Deprecated/ui/styles/colors';
import { GraphScrollIndicators } from './GraphScrollIndicators';

ChartJS.defaults.font.family = 'ProximaNova';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const colorArray = [
  variantPalette.indigoDarker1,
  variantPalette.skyDarker1,
  variantPalette.blueDarker1,
  variantPalette.greenDarker1,
  variantPalette.purpleDarker1,
  variantPalette.yellowDarker1,
  variantPalette.redDarker1,
  palette.brand.indigo,
  palette.brand.sky,
  palette.brand.blue,
  palette.brand.green,
  palette.brand.purple,
  palette.brand.orange,
  palette.brand.red,
  variantPalette.indigoLighter2,
  variantPalette.blueLighter2,
  variantPalette.skyLighter2,
  variantPalette.greenLighter2,
  variantPalette.purpleLighter2,
  variantPalette.yellowLighter2,
];

const DATAPOINT_WIDTH = 5;

const styles = {
  flexLabelContainer: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    rowGap: '0.625rem',
    columnGap: '3rem',
    justifyItems: 'flex-start',
  }, forMobile(`
    padding-left: 0px;
  `)),

  gridLabelContainer: css({
    paddingLeft: 0,
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '0.5rem',
    justifyContent: 'flex-start',
    maxHeight: '6rem',
    overflowY: 'auto',
    width: '100%',
  }),

  labelName: css(
    forMobile(`
      font-size:0.625rem;
    `),
  ),
  colors: (color: string) => css`
    flex-shrink: 0;
    height: 0.9375rem;
    border-radius: 50%;
    display: inline-block;
    background-color: ${color};
    font-size: 0.75rem;
    width: 0.9375rem;
  `,

  label: css`
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    font-size: 0.75rem;
  `,

  wrapper: (isMobile: boolean) => css({
    position: 'relative',
    overflowX: isMobile ? 'visible' : 'auto',
    height: '100%',
    maxWidth: '100%',
    width: '100%',
    marginBottom: '2rem',
  }),
  chart: (numDatapoints: number, height?: string, isMobile = false) => css({
    width: isMobile ? '100%' : `${numDatapoints * DATAPOINT_WIDTH}rem`,
    minWidth: '100%',
    height: isMobile ? '100%' : height ?? '100%',
    position: 'relative',
  }),
};

interface ViewProps {
  height?: string,
  data: {
    datasets: {
      backgroundColor: string;
      borderColor: string;
      pointBorderWidth: number;
      pointRadius: number;
      label: string;
      data: {
        participantInfo?: string | undefined;
        x: string;
        y: number;
      }[];
    }[];
    labels: string[];
  },
  maxDatasetLength: number,
  options: ChartOptions,
  wrapperRef: RefObject<HTMLDivElement>,
  isMobile: boolean,
}

const View = ({
  height,
  options,
  data,
  maxDatasetLength,
  wrapperRef,
  isMobile,
  ...props
}: ViewProps): JSX.Element => (
  <>
    <div css={styles.wrapper(isMobile)} ref={wrapperRef}>
      <GraphScrollIndicators wrapperRef={wrapperRef} />
      <div css={styles.chart(maxDatasetLength, height, isMobile)}>
        <Line options={options as CoreChartOptions<'line'>} data={data} {...props} />
      </div>
    </div>
    <ul css={data.datasets.length > 6 ? styles.gridLabelContainer : styles.flexLabelContainer}>
      {data.datasets.map((item) => (
        <div key={item.label} css={styles.label}>
          {item.label && (
          <>
            <span css={styles.colors(item.backgroundColor)} />
            <span css={styles.labelName}>{item.label}</span>
          </>
          )}
        </div>
      ))}
    </ul>
  </>
);

export const LineGraphChart = ({
  options,
  tooltipOptions,
  datasets,
  ...props
}: LineGraphChartProps): JSX.Element => {
  const [setDisplay] = useQueryParamState<string>('common', 'setDisplay', 'desktop');
  const checkMobileQuery = useIsMobileQuery();
  const isMobile = setDisplay === 'mobile' || checkMobileQuery === true;

  const wrapperRef = useRef<HTMLDivElement>(null);

  let maxDatasetLength = 0;
  const dataObjects = datasets.map((dataset, index) => {
    const colorIndex = index > colorArray.length - 1 ? index % colorArray.length : index;
    maxDatasetLength = Math.max(maxDatasetLength, dataset.data.length);

    return {
      ...dataset,
      backgroundColor: colorArray[colorIndex],
      borderColor: colorArray[colorIndex],
      pointBorderWidth: 2,
      pointRadius: 1,
    };
  });

  // These get merged with prop inputs
  const defaultOptions: ChartOptions = {
    responsive: true,
    layout: {
      padding: 0,
    },
    interaction: {
      mode: 'nearest',
      axis: 'xy',
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: true,
        ...tooltipOptions,
      },
      legend: {
        display: false,
        fullSize: true,
        align: 'center',
        position: 'bottom',
        labels: {
          boxWidth: 40,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
        },
        grid: {
          display: false,
          lineWidth: 2,
        },
        ticks: {
          padding: isMobile ? 0 : 20,
          labelOffset: isMobile ? 0 : -5,
        },
      },
      x: {
        min: 0,
        beginAtZero: true,
        title: {
          display: true,
        },
        grid: {
          display: false,
          drawTicks: false,
          drawOnChartArea: false,
          lineWidth: 85,
        },
        ticks: {
          autoSkip: false,
          maxRotation: isMobile ? 30 : 0,
          minRotation: 0,
          align: 'start',
          font: {
            size: 10,
          },
        },
      },
    },
  };

  const data = {
    datasets: dataObjects,
    labels: [],
  };

  const hookProps = {
    isMobile,
    maxDatasetLength,
    options: merge(defaultOptions, options),
    data,
    wrapperRef,
  };

  return <View {...hookProps} {...props} />;
};

interface LineGraphChartProps {
  height?: string,
  datasets: GraphDataset[],
  options: ChartOptions,
  tooltipOptions: Partial<TooltipOptions>
}
