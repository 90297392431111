import {
  getApi, postApi, patchApi,
} from '~Deprecated/services/HttpService';
import { hosts } from '~Deprecated/services/config';

export const getBaseballCardUserNote = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [orgId, , creatorId, userId] = queryKey;

  const serverUrl = {
    host: hosts.notes,
    uri: `/organizations/${orgId}/notes?id=${userId}&type=USER`,
  };

  return getApi(serverUrl, {});
};

export const getPersonalityDetailsById = ({ queryKey }) => {
  const [, personalityId] = queryKey;

  const serverUrl = {
    host: hosts.auth,
    uri: `/referenceData/personalities/${personalityId}`,
  };

  return getApi(serverUrl, {});
};

export const createBaseballCardUserNote = async ({
  type, userId, text, rank, orgId,
}) => {
  const data = {
    context: {
      type,
      id: userId,
    },
    items: [{
      text,
      rank,
    }],
  };

  const serverUrl = {
    host: hosts.notes,
    uri: `/organizations/${orgId}/notes`,
  };
  return postApi(serverUrl, data);
};

export const updateBaseballCardUserNote = async ({
  type, userId, text, rank, noteId, orgId,
}) => {
  const data = {
    context: {
      type,
      id: userId,
    },
    item: {
      text,
      rank,
    },
  };

  const serverUrl = {
    host: hosts.notes,
    uri: `/organizations/${orgId}/notes/${noteId}`,
  };
  return patchApi(serverUrl, data);
};
