import { AgendaTemplates } from '@leadr-hr/types';
import {
  CreateEditTemplateTopic,
  CreateAgendaSectionTemplateDetailsWithLocalID,
  EditAgendaSectionTemplateDetailsWithLocalID,
  CreateAgendaTopicTemplateDetailsWithLocalID,
  EditAgendaTopicTemplateDetailsWithLocalID,
  AgendaTemplateType,
} from '~Meetings/components/topic-suggestions/const/types';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';

export interface DisableMoveUpParams {
  agendaType: AgendaTemplateType,
  localId: string,
  sectionLocalId?: string,
}

export interface DisableMoveDownParams {
  agendaType: AgendaTemplateType,
  localId: string,
  sectionLocalId?: string,
}

export enum MoveDirection {
  Up = 'up',
  Down = 'down',
}

export interface MoveTemplateAgendaItemOnePositionParams {
  moveDirection: MoveDirection,
  agendaType: AgendaTemplateType,
  localId: string,
  sectionLocalId?: string,
}

export interface UseMoveTemplateAgendaItemReturn {
  disableMoveUp: (props: DisableMoveUpParams) => boolean,
  disableMoveDown: (props: DisableMoveDownParams) => boolean,
  moveTemplateAgendaItemOnePosition: (props: MoveTemplateAgendaItemOnePositionParams) => CreateEditTemplateTopic[],
}

export const useMoveTemplateAgendaItem = (templateItems: CreateEditTemplateTopic[]): UseMoveTemplateAgendaItemReturn => {
  const disableMoveUp = useCallback(({
    agendaType,
    localId,
    sectionLocalId,
  }: DisableMoveUpParams): boolean => {
    if (agendaType === AgendaTemplates.AgendaTemplateType.AgendaTopic) {
      if (sectionLocalId) {
        return false;
      }
      const agendaItemIndex = templateItems.findIndex((tempAgendaItem) => tempAgendaItem.localId === localId);
      if (agendaItemIndex === 0) {
        return true;
      }
    } else if (agendaType === AgendaTemplates.AgendaTemplateType.AgendaSection) {
      const agendaItemIndex = templateItems.findIndex((tempAgendaItem) => tempAgendaItem.localId === localId);
      if (agendaItemIndex === 0) {
        return true;
      }
    }

    return false;
  }, [templateItems]);

  const disableMoveDown = useCallback(({
    agendaType,
    localId,
    sectionLocalId,
  }: DisableMoveDownParams): boolean => {
    if (agendaType === AgendaTemplates.AgendaTemplateType.AgendaTopic) {
      if (sectionLocalId) {
        return false;
      }
      const agendaItemIndex = templateItems.findIndex((tempAgendaItem) => tempAgendaItem.localId === localId);
      if (agendaItemIndex !== -1 && agendaItemIndex === templateItems.length - 1) {
        return true;
      }
    } else if (agendaType === AgendaTemplates.AgendaTemplateType.AgendaSection) {
      const agendaItemIndex = templateItems.findIndex((tempAgendaItem) => tempAgendaItem.localId === localId);
      if (agendaItemIndex !== -1 && agendaItemIndex === templateItems.length - 1) {
        return true;
      }
    }

    return false;
  }, [templateItems]);

  const moveTemplateAgendaItemOnePosition = useCallback(({
    moveDirection,
    agendaType,
    localId,
    sectionLocalId,
  }: MoveTemplateAgendaItemOnePositionParams): CreateEditTemplateTopic[] => {
    let agendaItemIndex;
    const tempTemplateItems = cloneDeep(templateItems);

    if (agendaType === AgendaTemplates.AgendaTemplateType.AgendaTopic) {
      if (sectionLocalId) {
        const agendaSectionIndex = tempTemplateItems.findIndex((tempAgendaItem) => tempAgendaItem.localId === sectionLocalId);
        // eslint-disable-next-line max-len
        const agendaSection = tempTemplateItems[agendaSectionIndex] as CreateAgendaSectionTemplateDetailsWithLocalID| EditAgendaSectionTemplateDetailsWithLocalID;
        agendaItemIndex = agendaSection.children.findIndex((tempAgendaItem) => tempAgendaItem.localId === localId);

        if (moveDirection === MoveDirection.Down) {
          if (agendaItemIndex + 1 < agendaSection.children.length) {
            // Move down within the section
            const itemToMove = agendaSection.children[agendaItemIndex];
            agendaSection.children.splice(agendaItemIndex, 1);
            agendaSection.children.splice(agendaItemIndex + 1, 0, itemToMove);
          } else {
            // Move out of section to the next index (agendaSectionIndex + 1)
            const itemToMove = agendaSection.children[agendaItemIndex];
            agendaSection.children.splice(agendaItemIndex, 1);
            tempTemplateItems.splice(agendaSectionIndex + 1, 0, itemToMove);
          }
        } else if (moveDirection === MoveDirection.Up) {
          if (agendaItemIndex - 1 >= 0) {
            // Move up within the section
            const itemToMove = agendaSection.children[agendaItemIndex];
            agendaSection.children.splice(agendaItemIndex, 1);
            agendaSection.children.splice(agendaItemIndex - 1, 0, itemToMove);
          } else {
            // Move out of section to the previous index (agendaSectionIndex)
            const itemToMove = agendaSection.children[agendaItemIndex];
            agendaSection.children.splice(agendaItemIndex, 1);
            tempTemplateItems.splice(agendaSectionIndex, 0, itemToMove);
          }
        }
      } else {
        agendaItemIndex = tempTemplateItems.findIndex((tempAgendaItem) => tempAgendaItem.localId === localId);

        if (moveDirection === MoveDirection.Down) {
          const itemAtNewIndex = tempTemplateItems[agendaItemIndex + 1];
          if (itemAtNewIndex.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
            // Move item down into the agenda section at index 0
            const itemToMove = tempTemplateItems[agendaItemIndex] as CreateAgendaTopicTemplateDetailsWithLocalID | EditAgendaTopicTemplateDetailsWithLocalID;
            tempTemplateItems.splice(agendaItemIndex, 1);
            itemAtNewIndex.children.splice(0, 0, itemToMove);
          } else {
            /*
              Covers 2 cases:
              1. Move item down and skip the agenda section (agendaSectionIndex) because the section is full
              2. Move item down because the item below isn't an agenda section
            */
            const itemToMove = tempTemplateItems[agendaItemIndex];
            tempTemplateItems.splice(agendaItemIndex, 1);
            tempTemplateItems.splice(agendaItemIndex + 1, 0, itemToMove);
          }
        } else if (moveDirection === MoveDirection.Up) {
          const itemAtNewIndex = tempTemplateItems[agendaItemIndex - 1];
          if (itemAtNewIndex.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
            // Move item up into the agenda section at index itemAtNewIndex.children.length
            const itemToMove = tempTemplateItems[agendaItemIndex] as CreateAgendaTopicTemplateDetailsWithLocalID | EditAgendaTopicTemplateDetailsWithLocalID;
            tempTemplateItems.splice(agendaItemIndex, 1);
            itemAtNewIndex.children.splice(itemAtNewIndex.children.length, 0, itemToMove);
          } else {
            /*
              Covers 2 cases:
              1. Move item up and skip the agenda section (agendaSectionIndex) because the section is full
              2. Move item up because the item above isn't an agenda section
            */
            const itemToMove = tempTemplateItems[agendaItemIndex];
            tempTemplateItems.splice(agendaItemIndex, 1);
            tempTemplateItems.splice(agendaItemIndex - 1, 0, itemToMove);
          }
        }
      }
    } else if (agendaType === AgendaTemplates.AgendaTemplateType.AgendaSection) {
      agendaItemIndex = tempTemplateItems.findIndex((tempAgendaItem) => tempAgendaItem.localId === localId);

      if (agendaItemIndex !== -1) {
        if (moveDirection === MoveDirection.Down) {
          const itemToMove = tempTemplateItems[agendaItemIndex];
          tempTemplateItems.splice(agendaItemIndex, 1);
          tempTemplateItems.splice(agendaItemIndex + 1, 0, itemToMove);
        } else if (moveDirection === MoveDirection.Up) {
          const itemToMove = tempTemplateItems[agendaItemIndex];
          tempTemplateItems.splice(agendaItemIndex, 1);
          tempTemplateItems.splice(agendaItemIndex - 1, 0, itemToMove);
        }
      }
    }

    return tempTemplateItems;
  }, [templateItems]);

  return {
    disableMoveDown,
    disableMoveUp,
    moveTemplateAgendaItemOnePosition,
  };
};
