import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { NoteContextType } from '~Common/const/interfaces';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';

interface UpsertPrivateMeetingNotesParams {
  meetingFactoryId: string,
  noteText: string,
}

function upsertPrivateMeetingNotes({
  meetingFactoryId,
  noteText,
}: UpsertPrivateMeetingNotesParams): Promise<HttpCallReturn<void>> {
  const organizationId = getOrganizationId() ?? '';
  const serverUrl = `/organizations/${organizationId}/notes/upsert`;

  const body = {
    item: {
      text: noteText,
      lastModifiedInMillis: new Date().getTime(),
    },
    context: {
      type: NoteContextType.TeamMeeting,
      id: meetingFactoryId,
    },
  };

  return postApi(serverUrl, body);
}

export function useUpsertPrivateMeetingNotes(): UseMutationResult<HttpCallReturn<void>, unknown, UpsertPrivateMeetingNotesParams, unknown> {
  return useMutation({ mutationFn: upsertPrivateMeetingNotes });
}
