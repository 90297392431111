import { useMemo } from 'react';
import { AllReviews } from '~Reviews/V2/Const/types';
import { FilterType } from '~Common/const/drawers';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';

function makeFilteredOrgUserIds(cycles: AllReviews, filterType: FilterType[], filterValue: string[], searchText: string): AllReviews {
  const filteredReviews = cycles;

  if (searchText !== '') {
    Object.keys(filteredReviews).forEach((key) => {
      // eslint-disable-next-line max-len
      filteredReviews[key as keyof AllReviews] = filteredReviews[key as keyof AllReviews].filter((review) => review.name.toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  return filteredReviews;
}

export const useFilterReviews = (cycles: AllReviews, searchText: string): AllReviews => {
  const [filterType] = useQueryParamState<FilterType[]>('reviews', 'filterType', []);
  const [filterValue] = useQueryParamState<string[]>('reviews', 'filterValue', []);

  const filteredReviews = useMemo(
    () => makeFilteredOrgUserIds(cycles, filterType, filterValue, searchText),
    [cycles, filterType, filterValue, searchText],
  );
  return filteredReviews;
};
