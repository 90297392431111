import moment from 'moment-timezone';

export const buildPayloadForPubSub = ({ timelineDate, timelineData, huddle }) => {
  const date = moment(timelineDate).format('yyyyMMDD');
  const data = {
    date,
  };

  if (timelineData.feedback.length) {
    data.FEEDBACK = timelineData.feedback;
  }

  if (timelineData.content.length) {
    data.CONTENT = timelineData.content;
  }

  if (timelineData.huddles[0].category === 'COACHING') {
    data.COACHING = [huddle];
  } else {
    data.TEAM_MEETING = [huddle];
  }

  return {
    data: {
      data,
    },
    date,
    for: 'coaching', // <-- this is always coaching for some reason, don't know why
    initialObjectId: '', // <-- this is always empty for some reason, don't know why
  };
};
