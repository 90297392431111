import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { queryClient } from '~Common/const/queryClient';
import { PEOPLE_FILTERS_QUERY_KEY } from '~Common/hooks/useGetPeopleFilters';
import { GROUPS_LIST_QUERY_KEY } from './useGetGroups';

export const createUserGroup = (group) => {
  const URL = `/organizations/${getOrganizationId()}/ops/teams`;
  return postApi(URL, group, {});
};

export const useCreateGroup = () => {
  const toastId = useRef(null);

  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: createUserGroup,
    onMutate: () => {
      toastId.current = toast.info('Creating the group...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error creating the group. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully created the group.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey: GROUPS_LIST_QUERY_KEY });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });
      await queryClient.invalidateQueries({ queryKey: PEOPLE_FILTERS_QUERY_KEY });
      dispatch(popDrawerAction({ popAll: true }));
    },
  });

  return mutation.mutate;
};
