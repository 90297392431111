import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { toast } from '~Common/components/Toasts';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { useDraft } from '~Common/hooks/useDraft';
import { USER_PENDING_ITEMS_QUERY_KEY, UserPendingItems } from '~Common/hooks/user/useUserPendingItems';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import {
  LearningStatus,
  SCReceivedLearningDetail,
  ReceivedLearning,
  AssignedLearning,
  SCRequestedLearningDetail,
  LearningPersonDisplayInformation,
  CompleteLearningData,
  QuestionResponse,
} from '~Learning/const/interfaces';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { receivedLearningDrawerTemplate } from '~Learning/components/ReceivedLearningDashboard/ReceivedLearningDrawer';
import { OPTIMISTIC_ID } from '~Learning/const';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { assignedLearningQueryKeys } from '../assigned/queryKeys';
import { receivedLearningQueryKeys } from './queryKeys';

interface SubmitResponsesEndpointProps {
  learningId: string,
  responses?: QuestionResponse[],
  learningData?: CompleteLearningData,
}

const submitLearningResponses = ({ learningData }: SubmitResponsesEndpointProps): Promise<HttpCallReturn<boolean>> => {
  const url = {
    host: hosts.questions,
    uri: `/organizations/${getOrganizationId() ?? ''}/learning/complete`,
  };

  return postApi<boolean>(url, learningData);
};

interface UseSubmitLearningResponsesParams {
  learningId: string,
}

export const useSubmitSingleLearningResponses = ({
  learningId,
}: UseSubmitLearningResponsesParams): UseMutateFunction<HttpCallReturn<boolean>, unknown, SubmitResponsesEndpointProps> => {
  const dispatch = useDispatch();
  const currentUserId = getUserId() ?? ''; // User id of the user that is signed in
  // TODO: Remove the casting when useNewPeople gets typed
  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, LearningPersonDisplayInformation>>;
  const { buildDraftKey, removeDraftKeyFromLocalStorage } = useDraft();

  const mutation = useMutation({
    mutationFn: submitLearningResponses,
    onMutate: async (submitResponsesProps: SubmitResponsesEndpointProps) => {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));

      // #region Cancel Queries
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      // #endregion

      // #region Get Previous Data
      const previousReceivedLearningDetail = queryClient.getQueryData(receivedLearningQueryKeys.singleContentDetail(learningId));
      const previousAssignedLearningDetail = queryClient.getQueryData<HttpCallReturn<SCRequestedLearningDetail>>(
        assignedLearningQueryKeys.singleContentDetail(learningId),
      );
      const previousReceivedLearningList = queryClient.getQueryData(receivedLearningQueryKeys.list());
      const previousAssignedLearningList = queryClient.getQueryData(assignedLearningQueryKeys.list());
      const previousUserPendingItems = queryClient.getQueryData(USER_PENDING_ITEMS_QUERY_KEY);
      // #endregion

      // #region Update Single Learning Details for Learning that was just completed
      queryClient.setQueryData<HttpCallReturn<SCReceivedLearningDetail>>(
        receivedLearningQueryKeys.singleContentDetail(learningId),
        (oldReceivedLearningDetail) => {
          if (oldReceivedLearningDetail) {
            const newReceivedLearningDetail = cloneDeep(oldReceivedLearningDetail);
            newReceivedLearningDetail.response.status = LearningStatus.COMPLETED;
            newReceivedLearningDetail.response.questions = newReceivedLearningDetail?.response.questions.map((question) => ({
              ...question,
              response: {
                text: submitResponsesProps?.responses?.find((response) => response.questionId === question.id)?.responseText || '',
                id: OPTIMISTIC_ID,
              },
            }));

            return newReceivedLearningDetail;
          }

          return oldReceivedLearningDetail;
        },
      );
      // #endregion

      // #region Update Received List to have that Learning completed
      queryClient.setQueryData<ReceivedLearning[]>(receivedLearningQueryKeys.list(), (oldReceivedLearningList) => {
        if (oldReceivedLearningList) {
          const newReceivedLearningList = [...oldReceivedLearningList];

          const receivedLearningToUpdateIndex = newReceivedLearningList.findIndex((receivedLearning) => receivedLearning.id === learningId);

          const newReceivedLearning = cloneDeep(newReceivedLearningList[receivedLearningToUpdateIndex]);

          newReceivedLearning.status = LearningStatus.COMPLETED;

          newReceivedLearningList.splice(receivedLearningToUpdateIndex, 1, newReceivedLearning);

          return newReceivedLearningList;
        }

        return [];
      });
      // #endregion

      // #region Update Notification Dot in the Navbar

      queryClient.setQueryData<HttpCallReturn<UserPendingItems>>(USER_PENDING_ITEMS_QUERY_KEY, (oldUserPendingItems) => {
        if (oldUserPendingItems) {
          const newUserPendingItems = cloneDeep(oldUserPendingItems);
          newUserPendingItems.response.pendingLearningCount -= 1;
          return newUserPendingItems;
        }

        return oldUserPendingItems;
      });
      // #endregion

      // #region If the user sent the learning to themselves
      if (previousAssignedLearningDetail?.response.assignees.some((assignee) => assignee.assignee.id === currentUserId)) {
        // Requested Learning List
        queryClient.setQueryData<AssignedLearning[]>(
          assignedLearningQueryKeys.list(),
          (oldAssignedLearningList) => {
            if (oldAssignedLearningList) {
              const newAssignedLearningList = cloneDeep(oldAssignedLearningList);

              const indexOfAssignedLearningToUpdate = newAssignedLearningList?.findIndex(
                (assignedLearning) => assignedLearning.id === learningId,
              );

              const updatedAssignedLearning = cloneDeep(newAssignedLearningList[indexOfAssignedLearningToUpdate]);

              updatedAssignedLearning.numberOfUsersCompleted += 1;

              // Handle a case where the person who sent the Learning is the last user to complete it
              if (updatedAssignedLearning.numberOfUsersCompleted === updatedAssignedLearning.numberOfUsersAssigned) {
                updatedAssignedLearning.status = LearningStatus.COMPLETED;
              }

              newAssignedLearningList.splice(indexOfAssignedLearningToUpdate, 1, updatedAssignedLearning);

              return newAssignedLearningList;
            }

            return oldAssignedLearningList;
          },
        );

        // Requested learning details
        queryClient.setQueryData<HttpCallReturn<SCRequestedLearningDetail>>(
          assignedLearningQueryKeys.singleContentDetail(learningId),
          (oldAssignedLearningDetail) => {
            if (oldAssignedLearningDetail) {
              const newAssignedLearningDetail = cloneDeep(oldAssignedLearningDetail);

              newAssignedLearningDetail.response.assignees = newAssignedLearningDetail.response.assignees.map((assignee) => {
                if (assignee.assignee.id === currentUserId) {
                  return {
                    ...assignee,
                    status: LearningStatus.COMPLETED,
                  };
                }

                return assignee;
              });

              newAssignedLearningDetail.response.questions = newAssignedLearningDetail.response.questions.map((question) => {
                const currentUserInformation = peopleData[currentUserId];

                const newResponse = {
                  text: submitResponsesProps?.responses?.find((response) => response.questionId === question.id)?.responseText || '',
                  id: OPTIMISTIC_ID,
                  assignee: {
                    id: currentUserId,
                    firstName: currentUserInformation.firstName,
                    lastName: currentUserInformation.lastName,
                    profileImageUrl: currentUserInformation.profileImageUrl,
                    jobTitle: currentUserInformation.jobTitle,
                  },
                };

                return {
                  ...question,
                  responses: [...question.responses, newResponse],
                };
              });

              if (newAssignedLearningDetail.response.assignees.every((assignee) => assignee.status === LearningStatus.COMPLETED)) {
                newAssignedLearningDetail.response.status = LearningStatus.COMPLETED;
              }

              return newAssignedLearningDetail;
            }

            return oldAssignedLearningDetail;
          },
        );
      }
      // #endregion

      // Return a context object with the old snapshotted values
      return {
        previousReceivedLearningDetail,
        previousAssignedLearningDetail,
        previousReceivedLearningList,
        previousAssignedLearningList,
        previousUserPendingItems,
      };
    },
    onError: (_, __, snapshot) => {
      toast.error('There was an error submitting learning responses. Please try again or contact support.', {
        autoClose: 1500,
      });

      // Open back up the View Learning drawer
      dispatch(pushDrawerAction({
        drawer: {
          ...receivedLearningDrawerTemplate,
          args: {
            learningId,
          },
        },
      }));

      // Revert the data to the previous data
      queryClient.setQueryData(receivedLearningQueryKeys.singleContentDetail(learningId), snapshot?.previousReceivedLearningDetail);
      queryClient.setQueryData(assignedLearningQueryKeys.singleContentDetail(learningId), snapshot?.previousAssignedLearningDetail);
      queryClient.setQueryData(receivedLearningQueryKeys.list(), snapshot?.previousReceivedLearningList);
      queryClient.setQueryData(assignedLearningQueryKeys.list(), snapshot?.previousAssignedLearningList);
      queryClient.setQueryData(USER_PENDING_ITEMS_QUERY_KEY, snapshot?.previousUserPendingItems);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeLearning(getOrganizationId() ?? '') });
      // Invalidate the learning notification dot
      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
    },
    onSuccess: (_, variables) => {
      // Clear the local storage of the draft logic
      variables?.responses?.forEach((questionResponse: { questionId: string; }) => {
        const draftKey = buildDraftKey([getOrganizationId() ?? '', 'learning', learningId, 'question', questionResponse.questionId]);
        removeDraftKeyFromLocalStorage(draftKey);
      });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
  });

  return mutation.mutate;
};
