import { css } from '@emotion/react';
import { LinearProgress } from '@mui/material';
import Tooltip from '~Common/components/Tooltip';
import { Goals } from '@leadr-hr/types';
import { PROGRESS_BAR_STYLES } from '~Goals/const/styles';

const styles = {
  ...PROGRESS_BAR_STYLES,
  progressWrapper: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  progress: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '.3125rem',
    maxWidth: '4.0625rem',
  }),
  progressBarWrap: css({
    flexBasis: '80%',
  }),
  percent: css({
    flexBasis: '15%',
  }),
  progressBarAdjustment: css({
    height: '.25rem',
  }),
};

interface ProgressBarWithTextProps {
  status: Goals.GoalStatus,
  percentage?: number,
  renderText?: () => JSX.Element,
}

const ProgressBarWithText = ({
  status,
  percentage,
  renderText,
  ...props
}: ProgressBarWithTextProps): JSX.Element => (
  <div css={styles.progressWrapper} {...props}>
    <div css={styles.progress}>
      <div css={styles.progressBarWrap}>
        <Tooltip content={`${percentage ?? 0}%`}>
          <LinearProgress
            css={[styles.progressBar(status ?? Goals.GoalStatus.OnTrack), styles.progressBarAdjustment]}
            value={percentage ?? 0}
            variant="determinate"
          />
        </Tooltip>
      </div>
      <div css={styles.percent}>
        {`${percentage || 0}`}
        %
      </div>
    </div>
    {renderText?.()}
  </div>
);

export default ProgressBarWithText;
