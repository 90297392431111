import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

import LeadrModal from '~Common/V3/components/LeadrModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { ParticipantList } from './ParticipantList';
import { modalParamsProps } from '../Const/types';

const styles = {
  title: css({
    color: palette.brand.red,
  }),
  warningText: css({
    color: palette.brand.red,
    fontWeight: '600',
  }),
  description: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    lineHeight: '1.375rem',
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
};

interface DisplayCycleModalsProps {
  open: boolean,
  bulkActionModalParams: string[],
  removeMultipleReviews: () => void,
  handleModalDisplay: (value: boolean, params?: modalParamsProps) => void,
  setBulkActionModalParams:(value: string[]) => void,
  modalParams?: modalParamsProps,
  removeSingleReview: () => void,
}

const DisplayCycleModals = ({
  open,
  bulkActionModalParams,
  removeMultipleReviews,
  handleModalDisplay,
  setBulkActionModalParams,
  modalParams,
  removeSingleReview,
}: DisplayCycleModalsProps): JSX.Element => (
  <LeadrModal
    onClose={() => { handleModalDisplay(false); setBulkActionModalParams([]); }}
    open={open}
  >
    <LeadrModal.Header>
      <LeadrModal.Title css={styles.title}>
        {bulkActionModalParams?.length > 0 && (
          <>Are you sure you want to delete these Reviews?</>
        )}
        {modalParams && (
          <>{`Are you sure you want to delete ${modalParams?.name ?? ''}’s Review?`}</>
        )}
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body>
      {bulkActionModalParams?.length > 0 && (
        <>
          <p css={styles.description}>
            The reviews for the following
            <span css={styles.warningText}>{` ${bulkActionModalParams?.length ?? ''} participants `}</span>
            will be deleted:
          </p>
          <ParticipantList
            participants={bulkActionModalParams}
          />
        </>
      )}
      {modalParams && (
        <p css={styles.description}>
          {`This action will remove ${modalParams?.name ?? ''}’s review and erase all of their progress and manager’s progress.`}
        </p>
      )}
    </LeadrModal.Body>
    <LeadrModal.Footer css={styles.footer}>
      <LeadrButton
        onClick={(bulkActionModalParams?.length > 0 && removeMultipleReviews) || (modalParams && removeSingleReview)}
        data-test-id="reviewCycleDeleteParticipants"
        color="danger"
        size="small"
      >
        Delete Participants
      </LeadrButton>
      <LeadrButton
        onClick={() => { handleModalDisplay(false); setBulkActionModalParams([]); }}
        data-test-id="reviewCycleCancelDeleteParticipants"
        color="danger"
        variant="ghost"
        size="small"
      >
        Cancel
      </LeadrButton>
    </LeadrModal.Footer>
  </LeadrModal>
);

export default DisplayCycleModals;
