import { ComponentProps, useCallback } from 'react';
import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import AvatarMaybeMultipleMaybeNot from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import { Goals } from '@leadr-hr/types';
import { GoalProgressBar } from '~Goals/components/Shared/GoalProgressBar';
import NewGoalStatus from '~Goals/components/Shared/NewGoalStatus';
import { useHistory } from 'react-router-dom';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';

const styles = {
  goalItem: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  row: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  }),
  avatarRow: css({
    marginBottom: '0.25rem',
  }),
  participants: css({
    display: 'flex',
    flex: 1,
  }),
  avatar: css({
    margin: '0 0.25rem',
  }),
  progress: css({
    flexShrink: 0,
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  progressBar: css({
    width: '50%',
  }),
  progressBarBackground: css({
    '.MuiLinearProgress-colorPrimary': {
      backgroundColor: palette.neutrals.gray200,
    },
    '.MuiLinearProgress-root-progressBarBackground': {
      backgroundColor: palette.neutrals.gray200,
    },
  }),
  percent: css({
    width: '40%',
  }),
  title: css({
    flex: '1',
    minWidth: 0,
  }, withTruncate()),
  goalStatusIndicator: css({
    width: '30%',
  }),
  barWrapper: css({
    width: '100px',
  }),
};
interface GoalListItemViewProps extends ComponentProps<'div'> {
  title: string,
  participants: Goals.GoalParticipant[],
  maxPeopleDisplay: number,
  onGoalClick: (id: string) => void,
  statusUpdate: Goals.GoalStatusUpdate,
  goalId: string,
}
const View = ({
  title,
  participants,
  maxPeopleDisplay,
  onGoalClick,
  statusUpdate,
  goalId,
  ...props
}: GoalListItemViewProps):JSX.Element => (
  <div
    tabIndex={0}
    role="button"
    css={styles.goalItem}
    onClick={() => onGoalClick(goalId)}
    onKeyDown={() => onGoalClick(goalId)}
    {...props}
  >
    <div css={[styles.row, styles.avatarRow]}>
      <div css={styles.participants}>
        <AvatarMaybeMultipleMaybeNot
          usersInfo={participants}
          numberOfUsers={participants.length}
          numberOfUsersToShow={maxPeopleDisplay}
          avatarHeight={40}
          avatarWidth={40}
          renderAvatar={({ user }) => (
            // Due to how the BaseAvatar child component of AvatarWithTooltip is implemented
            // passing css causes the styles to be doubled when displaying initials
            // Given this component is used all over the place the changes to fix this
            // issue could break some styles, so for now I'm just moving the style up to
            // a wrapping div
            // ToDo: Move this css back down to AvatarWithTooltip once this issue is fixed
            <div css={styles.avatar}>
              <AvatarWithTooltip
                firstName={user.firstName}
                lastName={user.lastName}
                profileImageUrl={user.profileImageUrl}
              />
            </div>
          )}
          renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
            <OverflowAvatar
              css={styles.avatar}
              {...overflowAvatarProps}
            />
          )}
        />
      </div>
      <div
        css={styles.barWrapper}
      >
        <GoalProgressBar
          status={statusUpdate}
          isMobile={false}
          smallBar
        />
      </div>
    </div>
    <div css={styles.row}>
      <div css={styles.title}>
        {title}
      </div>
      <NewGoalStatus
        status={statusUpdate?.status}
      />
    </div>
  </div>
);

const skeletonStyles = {
  item: css({
    maxWidth: '100%',
    height: '2.5rem',
    borderRadius: '0.25rem',
    marginBottom: '0.5rem',
  }),
};

const SkeletonView = ():JSX.Element => (
  <div>
    <SkeletonLoader
      css={skeletonStyles.item}
      variant="rectangular"
      renderComponent={() => <div />}
    />
    <SkeletonLoader
      css={skeletonStyles.item}
      variant="rectangular"
      renderComponent={() => <div />}
    />
    <SkeletonLoader
      css={skeletonStyles.item}
      variant="rectangular"
      renderComponent={() => <div />}
    />
  </div>

);

interface GoalListItemProps extends ComponentProps<'div'> {
  goal: Goals.Goal,
}

const GoalListItem = ({ goal, ...props }: GoalListItemProps):JSX.Element => {
  const maxPeopleDisplay = 4;
  const {
    goalId, participants, title,
  } = goal ?? {};

  const sortedGoal = {
    ...goal,
    statusUpdates: [...goal.statusUpdates].sort((a, b) => b.createdInMillis - a.createdInMillis),
  };

  const statusUpdate = sortedGoal.statusUpdates[0];

  const history = useHistory();
  const onGoalClick = useCallback((id: string) => {
    history.push(GoalsRoutes.ViewById.replace(':goalId', id));
  }, [history]);

  const hookProps = {
    participants,
    maxPeopleDisplay,
    title,
    statusUpdate,
    onGoalClick,
    goalId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { SkeletonView as GoalListItemSkeleton };
export default GoalListItem;
