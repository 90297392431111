import { css, SerializedStyles } from '@emotion/react';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FrontendPlaylistLearning, OnUseTemplateParams } from '~Learning/const/interfaces';

import DrawerInput from '~Common/V3/components/DrawerInput';
import Froala from '~Common/V3/components/Froala';
import RequiredIcon from '~Common/V3/components/RequiredIcon';
import { useCallback, useEffect, useState } from 'react';
import { useRankedListHelpers } from '~Learning/hooks/utils/useRankedListHelpers';
import { useDragAndDrop } from '~Deprecated/hooks/useDragAndDrop';
import DrawerCloseButtonWithConfirmation from '~Learning/components/Shared/ConfirmationButtons/DrawerCloseButtonWithConfirmation';
import EditablePlaylistLearningCard from '~Learning/components/CreateLearningPlaylistDrawer/EditablePlaylistLearningCard';
import DragAndDrop from '~Deprecated/ui/components/DragAndDrop/DragAndDrop';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import PlaylistContentBanner from '~Learning/components/Shared/PlaylistContentBanner';
import { palette } from '~Common/styles/colors';
import { toast } from '~Common/components/Toasts';
import Details from '~Learning/components/CreateLearningPlaylistDrawer/EditablePlaylistLearningCard/Details';
import { useLearningLibrary } from '~Learning/hooks/utils/useLearningLibrary';
import {
  LearningPlaylistTemplateCreationRequest,
  useCreateLearningPlaylistTemplate,
} from '~Learning/hooks/templates/playlists/useCreateLearningPlaylistTemplate';
import ContentListCount from '~Learning/components/Shared/ContentListLength';
import { LEARNING_MAX_CONTENT_IN_PLAYLIST } from '~Learning/const/index';
import { LearningLibraryRenderBackButtonParams, viewLearningLibraryDrawerTemplate } from '../ViewLearningLibraryDrawer';
import LearningTemplateCategoryButtons from '../LearningTemplateCategoryButtons';
import { addNewTemplateContentDrawerTemplate } from './AddNewContentDrawer';
import { selectLearningTypeDrawerTemplate } from '../SelectLearningTypeDrawer';
import SelectTemplateBanner from './SelectTemplateBanner';

const styles = {
  detailsContainer: css({}),
  contentHeader: css({
    marginTop: '1.125rem',
  }),
  learningPlaylistContentCard: css({
    margin: '.25rem',
    marginBottom: '.625rem',
  }),
  contentButtons: css({
    marginTop: '.625rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  }),
  addContentButton: css({
    marginRight: '.625rem',
  }),
  footerButtons: css({
    marginTop: '2.875rem',
    display: 'flex',
  }),
  saveButton: css({
    marginRight: '.625rem',
  }),
  titleInput: css({
    marginBottom: '0.5rem',
  }),
  dragAndDrop: css({
    color: palette.neutrals.gray700,
  }),
  backButton: css({
    paddingLeft: '0px',
  }),
  categorySection: css({
    marginTop: '0.5rem',
  }),
  buttonsContainer: css({
    marginTop: '1.125rem',
    display: 'flex',
  }),
};

export const createPlaylistTemplateDrawerTemplate = {
  name: 'CREATE_PLAYLIST_TEMPLATE_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface CreatePlaylistTemplateDrawerState {
  playlistTitle: string,
  playlistDescription: string,
  playlistContentList: FrontendPlaylistLearning[],
  shouldConfirmDrawerClose?: boolean,
  categoryId: string,
}

enum FormSubmittedByButton {
  ADD_NEW_CONTENT = 'ADD_NEW_CONTENT',
  ADD_FROM_TEMPLATE = 'ADD_FROM_TEMPLATE',
  SAVE_AS_NEW_LEARNING = 'SAVE_AS_NEW_LEARNING',
  EDIT_CONTENT_PIECE = 'EDIT_CONTENT_PIECE',
}

interface CreatePlaylistTemplateDrawerProps extends DrawerProps<CreatePlaylistTemplateDrawerState> {
  initialTemplateCategoryId?: string,
  saveTemplateCallback?: () => void,
}

const CreatePlaylistTemplateDrawer = ({
  initialTemplateCategoryId = '',
  popDrawer,
  pushDrawer,
  drawerState,
  setDrawerState,
}: CreatePlaylistTemplateDrawerProps): JSX.Element => {
  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      shouldConfirmDrawerClose: true,
    }));
  }, [setDrawerState]);

  const openThisDrawer = useCallback(() => {
    pushDrawer({
      drawer: {
        ...createPlaylistTemplateDrawerTemplate,
        args: {
          initialTemplateCategoryId: drawerState?.categoryId || initialTemplateCategoryId,
        },
      },
    });
  }, [drawerState?.categoryId, initialTemplateCategoryId, pushDrawer]);

  const { openLibraryWithAssignLearningTemplate } = useLearningLibrary();

  const createLearningPlaylistTemplateMutation = useCreateLearningPlaylistTemplate({
    onSuccessCallback: openLibraryWithAssignLearningTemplate,
    onErrorCallback: (playlistToCreate: LearningPlaylistTemplateCreationRequest) => {
      // Restore the information that was in the drawer before the error
      setDrawerState((prev) => ({
        ...prev,
        playlistContentList: playlistToCreate.learnings,
        playlistTitle: playlistToCreate.title,
        playlistDescription: playlistToCreate.description,
        categoryId: playlistToCreate.categoryId,
      }));
      openThisDrawer();
    },
  });

  const [contentList, setContentList] = useState<FrontendPlaylistLearning[]>(drawerState.playlistContentList || []);

  const [formSubmittedByButton, setFormSubmittedByButton] = useState<FormSubmittedByButton | null>();
  const [contentIndexToEdit, setContentIndexToEdit] = useState<number | null>(null);

  const { getArrayWithoutItem } = useRankedListHelpers(contentList);

  const { onDragEnd } = useDragAndDrop();

  const onCloseDrawer = (): void => {
    popDrawer({ drawerName: createPlaylistTemplateDrawerTemplate.name });
  };

  const backDrawerClick = (): void => {
    onCloseDrawer();
    pushDrawer({ drawer: selectLearningTypeDrawerTemplate });
  };

  const onEditContent = (contentIndex: number): void => {
    setContentIndexToEdit(contentIndex);
    setFormSubmittedByButton(FormSubmittedByButton.EDIT_CONTENT_PIECE);
  };

  const onDeleteContent = (contentRank: number): void => {
    const newContentList = getArrayWithoutItem(contentRank);
    setContentList(newContentList);
  };

  const onAddNewContent = (): void => {
    setFormSubmittedByButton(FormSubmittedByButton.ADD_NEW_CONTENT);
  };

  const hasValidation = formSubmittedByButton === FormSubmittedByButton.SAVE_AS_NEW_LEARNING;

  const onUseTemplate = ({ templateId, isPlaylist, playlistContentList }: OnUseTemplateParams): void => {
    popDrawer({ popAll: true });

    if (isPlaylist && playlistContentList) {
      const currentPlaylistContentList = contentList || [];
      const unrankedPlaylistContentList = [...currentPlaylistContentList, ...playlistContentList];

      const rankedPlaylistContentList = unrankedPlaylistContentList.map((learning, index) => ({
        ...learning,
        rank: index,
      }));

      setDrawerState((prev) => ({
        ...prev,
        playlistContentList: rankedPlaylistContentList,
      }));

      pushDrawer({
        drawer: {
          ...createPlaylistTemplateDrawerTemplate,
        },
      });
    } else {
      pushDrawer({
        drawer: {
          ...addNewTemplateContentDrawerTemplate,
          args: {
            templateId,
            onDrawerClose: openThisDrawer,
          },
        },
      });
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (hasValidation) {
      if (contentList.length < 2) {
        toast.error('You need at least two pieces of content.');
        return;
      }
      if (contentList.length > LEARNING_MAX_CONTENT_IN_PLAYLIST) {
        toast.error(`You can have a max of ${LEARNING_MAX_CONTENT_IN_PLAYLIST} content pieces in a playlist.`);
        return;
      }
    }

    const formData = new FormData(event.currentTarget);
    const playlistTitle = formData.get('title') as string;
    const playlistDescription = formData.get('description') as string;
    const categoryId = formData.get('categoryId') as string;

    // Store all the information in drawer state, as temporarily closing this drawer.
    setDrawerState((prev) => ({
      ...prev,
      playlistContentList: contentList,
      playlistTitle,
      playlistDescription,
      categoryId,
    }));

    popDrawer({ popAll: true });

    if (formSubmittedByButton === FormSubmittedByButton.ADD_NEW_CONTENT) {
      // Open the AddNewContentDrawer to add new content
      pushDrawer({
        drawer: {
          ...addNewTemplateContentDrawerTemplate,
          args: {
            onDrawerClose: openThisDrawer,
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.ADD_FROM_TEMPLATE) {
      // Open the LearningLibrary Drawer
      const onBackClick = (closeLearningLibrary: () => void): void => {
        closeLearningLibrary();
        openThisDrawer();
      };

      pushDrawer({
        drawer: {
          ...viewLearningLibraryDrawerTemplate,
          args: {
            onUseTemplate,
            renderBanner: () => (
              <SelectTemplateBanner />
            ),
            renderBackButton: ({ backButtonStyles, closeLearningLibrary }: LearningLibraryRenderBackButtonParams) => (
              <IconButton
                onClick={() => onBackClick(closeLearningLibrary)}
                type="button"
                icon={faArrowLeft}
                css={[backButtonStyles, styles.backButton]}
                tooltip="Back"
              />
            ),
            canCreateLearningTemplate: false,
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.EDIT_CONTENT_PIECE) {
      // Open the AddNewContentDrawer to edit content
      if (contentIndexToEdit !== null) {
        setDrawerState((prev) => ({
          ...prev,
          contentTitle: contentList[contentIndexToEdit].title,
          contentLink: contentList[contentIndexToEdit].contentURL,
          introduction: contentList[contentIndexToEdit].introduction,
          questions: contentList[contentIndexToEdit].questions,
          dueDate: contentList[contentIndexToEdit].dueDate,
        }));
      }

      pushDrawer({
        drawer: {
          ...addNewTemplateContentDrawerTemplate,
          args: {
            contentIndexToEdit,
            onDrawerClose: openThisDrawer,
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.SAVE_AS_NEW_LEARNING) {
      // Submit to API
      const apiObject = {
        title: playlistTitle,
        description: playlistDescription,
        categoryId,
        learnings: contentList,
      };
      createLearningPlaylistTemplateMutation({
        ...apiObject,
      });
    }
  };

  const hookProps = {
    noValidate: !hasValidation,
    onSubmit,
    renderHeader: () => (
      <DrawerHeader
        title="Create Learning Playlist Template"
        renderBackButton={(backButtonStyles: SerializedStyles) => (
          <IconButton onClick={backDrawerClick} type="button" icon={faArrowLeft} css={backButtonStyles} tooltip="Back" />
        )}
      />
    ),
    renderBody: (defaultDrawerPadding: SerializedStyles) => (
      <div css={defaultDrawerPadding}>
        <div>
          <div css={styles.detailsContainer}>
            <DrawerInput
              name="title"
              label="Learning Playlist Title"
              css={styles.titleInput}
              maxLength={250}
              required
              initialValue={drawerState.playlistTitle}
            />
            <Froala
              name="description"
              label="Description"
              required
              richTextEditorProps={{
                initialValue: drawerState.playlistDescription,
              }}
              froalaConfigProps={{
                charCounterMax: 1000,
                charCounterCount: true,
              }}
              renderRightIcon={() => (
                <RequiredIcon />
              )}
            />
            <LearningTemplateCategoryButtons
              css={styles.categorySection}
              showHeader={false}
              categoryId={drawerState?.categoryId || initialTemplateCategoryId}
            />
          </div>
          <ContentListCount
            css={styles.contentHeader}
            contentListCount={contentList.length}
          />
          {contentList.length < 2 && (
            <PlaylistContentBanner
              contentListLength={contentList.length}
            />
          )}
          <DragAndDrop
            css={styles.dragAndDrop}
            list={contentList}
            renderItem={({ id: content }: { id: FrontendPlaylistLearning }, dragHandleProps: DraggableProvidedDragHandleProps) => (
              <EditablePlaylistLearningCard
                key={`${content.contentURL}-${content.title}`}
                css={styles.learningPlaylistContentCard}
                contentUrl={content.contentURL}
                onEditContent={() => onEditContent(content.rank)}
                onDeleteContent={() => onDeleteContent(content.rank)}
                dragHandleProps={dragHandleProps}
                renderDetails={() => (
                  <Details
                    title={content.title}
                    introduction={content.introduction}
                  />
                )}
              />
            )}
            onDragEnd={onDragEnd}
            dragEndProps={{
              initialArray: contentList,
              movedItem: (rank: string) => contentList.find((content) => content.rank.toString() === rank),
              // @ts-expect-error TODO: Remove once DragAndDrop is typed
              endFunctions: (newArray: FrontendPlaylistLearning[]) => {
                const updatedContentList = newArray.map((content, index) => ({ ...content, rank: index }));
                setContentList(updatedContentList);
              },
            }}
            valueToUse="rank"
          />
          {contentList.length < LEARNING_MAX_CONTENT_IN_PLAYLIST && (
            <div css={styles.contentButtons}>
              <LeadrButton
                css={styles.addContentButton}
                variant="ghost"
                size="small"
                type="submit"
                onClick={onAddNewContent}
                data-test-id="learningCreatePlaylistAddNewContent"
              >
                + Add New Content
              </LeadrButton>
              <LeadrButton
                variant="ghost"
                size="small"
                type="submit"
                onClick={() => setFormSubmittedByButton(FormSubmittedByButton.ADD_FROM_TEMPLATE)}
                data-test-id="learningCreatePlaylistAddFromTemplate"
              >
                + Add From Template
              </LeadrButton>
            </div>
          )}
        </div>
        <div css={styles.footerButtons}>
          <LeadrButton
            css={styles.saveButton}
            type="submit"
            onClick={() => setFormSubmittedByButton(FormSubmittedByButton.SAVE_AS_NEW_LEARNING)}
            data-test-id="learningCreatePlaylistTemplateSave"
          >
            Save Template
          </LeadrButton>
          <DrawerCloseButtonWithConfirmation
            renderDrawerCloseButton={({ onClick }) => (
              <LeadrButton
                variant="ghost"
                data-test-id="learningCreatePlaylistTemplateCancel"
                onClick={onClick}
              >
                Cancel
              </LeadrButton>
            )}
            onCloseDrawer={onCloseDrawer}
            shouldConfirmClose={drawerState.shouldConfirmDrawerClose}
          />
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: createPlaylistTemplateDrawerTemplate.name,
  component: CreatePlaylistTemplateDrawer,
});

export default CreatePlaylistTemplateDrawer;
