import { css } from '@emotion/react';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import AlertInformationBanner from '~Common/components/Alert/AlertInformationBanner';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import {
  AdminImporterTabValues,
  ErrorAndWarningRecordShape, ResponseDataShape,
} from '../Const/types';
import DataTableDisplay from '../Shared/DataTableDisplay';

const styles = {
  alertIcon: css({
    marginRight: '.5rem',
  }),
  summaryText: css({
    fontWeight: 600,
  }),
};

interface ViewProps {
  totalWarningRecords: number,
  totalErrorRecords: number,
  handleCloseBanner: (bannerToHide: string) => void,
  hideWarningBanner: boolean,
  hideErrorBanner: boolean,
  importTab: AdminImporterTabValues,
  filteredImportData: ResponseDataShape[],
  originalDraftData: ResponseDataShape[],
  handleBannerNavigation: (table: AdminImporterTabValues) => void,
}

const View = ({
  totalWarningRecords,
  totalErrorRecords,
  handleCloseBanner,
  hideWarningBanner,
  hideErrorBanner,
  importTab,
  filteredImportData,
  originalDraftData,
  handleBannerNavigation,
}: ViewProps): JSX.Element => (
  <>
    {!hideErrorBanner && importTab !== AdminImporterTabValues.Warnings && (
    <AlertInformationBanner
      onClick={() => handleBannerNavigation(AdminImporterTabValues.Errors)}
      renderContents={() => (
        <span>
          {totalErrorRecords}
          {' '}
          errors found during upload. Rows with errors will not be uploaded. After reviewing and correcting errors listed below, please re-upload CSV.
          {' '}
          <span css={styles.summaryText}>Click to view summary</span>
        </span>
      )}
      renderButton={() => (
        <FontAwesomeIcon
          icon={faClose}
          size="2x"
          css={styles.alertIcon}
          onClick={() => handleCloseBanner(AdminImporterTabValues.Errors)}
        />
      )}
      color="danger"
    />
    )}
    {!hideWarningBanner && importTab !== AdminImporterTabValues.Errors && (
    <AlertInformationBanner
      onClick={() => handleBannerNavigation(AdminImporterTabValues.Warnings)}
      renderContents={() => (
        <span>
          {totalWarningRecords}
          {' '}
          Warnings found during upload. Rows with warnings will still be imported. Please review below.
          {' '}
          <span css={styles.summaryText}>Click to view summary</span>
        </span>
      )}
      renderButton={() => (
        <FontAwesomeIcon
          icon={faClose}
          size="2x"
          css={styles.alertIcon}
          onClick={() => handleCloseBanner(AdminImporterTabValues.Warnings)}
        />
      )}
      color="warning"
    />
    )}
    <DataTableDisplay
      filteredImportData={filteredImportData}
      originalDraftData={originalDraftData}
    />
  </>
);

interface AdminImporterDataDisplayProps {
  warningRecords: ErrorAndWarningRecordShape[],
  errorRecords: ErrorAndWarningRecordShape[],
  filteredImportData: ResponseDataShape[],
  originalDraftData: ResponseDataShape[],
}

const AdminImporterDataDisplay = ({
  warningRecords,
  errorRecords,
  filteredImportData,
  originalDraftData,
}: AdminImporterDataDisplayProps): JSX.Element => {
  const [importTab, setImportTab] = useQueryParamState<AdminImporterTabValues>('admin', 'tabView', AdminImporterTabValues.Successful);

  const hasWarningRecords = warningRecords.length > 0;
  const hasErrorRecords = errorRecords.length > 0;
  const [hideWarningBanner, setHideWarningBanner] = useState(!hasWarningRecords);
  const [hideErrorBanner, setHideErrorBanner] = useState(!hasErrorRecords);

  const totalWarningRecords = warningRecords.length;
  const totalErrorRecords = errorRecords.length;

  const handleCloseBanner = (bannerToHide: string): void => {
    if (bannerToHide === AdminImporterTabValues.Warnings) {
      setHideWarningBanner(true);
    }
    if (bannerToHide === AdminImporterTabValues.Errors) {
      setHideErrorBanner(true);
    }
  };
  const handleBannerNavigation = (table: AdminImporterTabValues): void => {
    setImportTab(table);
  };

  const hookProps = {
    importTab,
    totalWarningRecords,
    totalErrorRecords,
    handleCloseBanner,
    hideWarningBanner,
    hideErrorBanner,
    filteredImportData,
    originalDraftData,
    handleBannerNavigation,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default AdminImporterDataDisplay;
