import { createAction } from '@reduxjs/toolkit';
import { registerAction } from '~Deprecated/reducers/loading';

const isLoadingAction = createAction('LEADR.IS_LOADING');

function isLoading(state, action) {
  let currentCount = state.loadingCount;

  if (action.payload) {
    currentCount += 1;
  } else if (currentCount > 0) {
    currentCount -= 1;
  }

  return {
    loadingCount: currentCount,
  };
}

registerAction(isLoadingAction.toString(), isLoading);

export {
  isLoadingAction,
};
