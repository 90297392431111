import { css } from '@emotion/react';
import curatedByLeadrBg from '~Meetings/assets/images/curatedByLeadr.png';
import curatedByPatrickBg from '~Meetings/assets/images/curatedByTG.png';

const styles = {
  bannerImg: css({
    width: '100%',
    objectFit: 'contain',
    borderRadius: '7px',
  }),
};

interface ViewProps {
  curatedByLeadr: boolean;
}

const TopicSuggestionsBanner = ({
  curatedByLeadr,
}: ViewProps): JSX.Element => (
  <div>
    {curatedByLeadr && (
      <img alt="Curated by Leadr desk background" src={curatedByLeadrBg} css={styles.bannerImg} />
    )}
    {!curatedByLeadr && (
      <img alt="Curated by Patrick Lencioni background" src={curatedByPatrickBg} css={styles.bannerImg} />
    )}
  </div>
);

export default TopicSuggestionsBanner;
