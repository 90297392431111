import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  commentControls: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    border: 0,
    background: 'transparent',
  }),
  lines: css({
    height: '.0625rem',
    flex: 1,
    backgroundColor: palette.neutrals.gray200,
  }),
  text: css({
    margin: '0 10px',
    color: palette.neutrals.gray700,
    textAlign: 'center',
    fontSize: '0.625rem',
    letterSpacing: '0.125rem',
  }),
};
interface ShowCommentsComponentProps {
  setShowComments: (showComments: boolean) => void;
}
const ShowCommentsComponent = ({
  setShowComments,
  ...props
}: ShowCommentsComponentProps): JSX.Element => (
  <button
    css={styles.commentControls}
    onClick={() => setShowComments(false)}
    {...props}
  >
    <div css={styles.lines} />
    <div css={styles.text}>SHOW LESS</div>
    <div css={styles.lines} />
  </button>
);

export default ShowCommentsComponent;
