import { useMemo } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { SpecialDaysUser } from '~Home/const/interfaces';
import { homeQueryKeys } from './queryKeys';

interface GetOrgSpecialDaysParamsInterface {
  orgId: string,
  take?: number,
  daysAhead?: number
}
const getOrgSpecialDays = async ({ orgId, daysAhead, take }:GetOrgSpecialDaysParamsInterface):Promise<HttpCallReturn<SpecialDaysUser[]>> => {
  const url = `/organizations/${orgId}/users/celebrations${buildQueryString({ take, daysAhead })}`;
  return getApi(url, {});
};

interface UseCelebrateModuleProps {
  take?: number,
  daysAhead?: number,
}
export const useCelebrateModule = ({ take = 10, daysAhead = 14 }:UseCelebrateModuleProps):UseQueryResult<HttpCallReturn<SpecialDaysUser[]>> => {
  const orgId = getOrganizationId() as string;
  const params = useMemo(() => ({
    daysAhead,
    take,
  }), [daysAhead, take]);
  return useQuery({
    queryKey: homeQueryKeys.celebrateDetails(getOrganizationId() ?? '', params),
    queryFn: () => getOrgSpecialDays({
      orgId,
      daysAhead,
      take,
    }),
  });
};
