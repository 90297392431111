import { useMemo } from 'react';
import { AllCycles } from '~Reviews/V2/Const/types';
import { FilterType } from '~Common/const/drawers';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';

function makeFilteredOrgUserIds(cycles: AllCycles, filterType: FilterType[], filterValue: string[], searchText: string): AllCycles {
  const filteredCycles = cycles;
  if (searchText !== '') {
    Object.keys(filteredCycles).forEach((key) => {
      filteredCycles[key as keyof AllCycles] = filteredCycles[key as keyof AllCycles]
        .filter((cycleStatus: { name: string; }) => (cycleStatus.name)?.toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  return filteredCycles;
}

export const useFilterCycles = (cycles: AllCycles, searchText: string): AllCycles => {
  const [filterType] = useQueryParamState<FilterType[]>('reviews', 'filterType', []);
  const [filterValue] = useQueryParamState<string[]>('reviews', 'filterValue', []);

  const filteredCycles = useMemo(
    () => makeFilteredOrgUserIds(cycles, filterType, filterValue, searchText),
    [cycles, filterType, filterValue, searchText],
  );

  return filteredCycles;
};
