import { useCallback, useMemo } from 'react';
import { create } from 'zustand';
import { produce } from 'immer';
import {
  set,
  unset,
  at,
  concat,
} from 'lodash';

import {
  AgendaReorderPayload,
  AgendaTopicCreatePayload,
  AgendaTopicUpdatePayload,
  AgendaSectionCreatePayload,
  AgendaSectionUpdatePayload,
  AgendaCommentCreatePayload,
  AgendaCommentUpdatePayload,
  AgendaCreatePayload,
  AgendaUpdatePayload,
} from '~Meetings/const/realTimeMeetingMessages';

export type RecursiveObject<T> = Record<string, T | Record<string, T>>;

export interface MeetingRealtimeState {
  activeUsers: Record<string, string[]>,
  reorderedAgenda: Record<string, Record<string, AgendaReorderPayload>>,
  newAgenda: Record<string, Record<string, AgendaTopicCreatePayload | AgendaCommentCreatePayload | AgendaSectionCreatePayload>>,
  editedAgenda: Record<string, Record<string, AgendaTopicUpdatePayload | AgendaCommentUpdatePayload | AgendaSectionUpdatePayload>>,
}

export interface MeetingRealtimeActions {
  setActiveUsers: (nextValue: Record<string, string[]>) => void,
  setReorderedAgenda: (nextValue: Record<string, Record<string, AgendaReorderPayload>>) => void,
  setNewAgenda: (nextValue: Record<string, Record<string, AgendaTopicCreatePayload | AgendaCommentCreatePayload | AgendaSectionCreatePayload>>) => void,
  setEditedAgenda: (nextValue: Record<string, Record<string, AgendaTopicUpdatePayload | AgendaCommentUpdatePayload | AgendaSectionUpdatePayload>>) => void,
  reset: () => void,
}

export const initialState = {
  activeUsers: {},
  newAgenda: {},
  editedAgenda: {},
  reorderedAgenda: {},
};

export const useMeetingRealTimeStore = create<MeetingRealtimeState & MeetingRealtimeActions>((setValue) => ({
  ...initialState,
  setActiveUsers: (nextValue) => setValue(() => ({ activeUsers: nextValue })),
  setNewAgenda: (nextValue) => setValue(() => ({ newAgenda: nextValue })),
  setEditedAgenda: (nextValue) => setValue(() => ({ editedAgenda: nextValue })),
  setReorderedAgenda: (nextValue) => setValue(() => ({ reorderedAgenda: nextValue })),
  reset: () => setValue(initialState),
}));

interface UseRealtimePresenceReturn {
  activeUsers: string[],
  addActiveUser: (orgUserId: string) => void,
  removeActiveUser: (orgUserId: string) => void,
}

export const useRealtimePresence = (huddleId: string): UseRealtimePresenceReturn => {
  const { activeUsers: storeActiveUsers } = useMeetingRealTimeStore((state) => (state));

  const activeUsers = useMemo(() => (
    storeActiveUsers[huddleId] ?? []
  ), [storeActiveUsers, huddleId]);

  const addActiveUser = useCallback((orgUserId: string) => {
    const {
      activeUsers: currentActiveUsers,
      setActiveUsers,
    } = useMeetingRealTimeStore.getState();

    setActiveUsers(produce(currentActiveUsers, (draft) => {
      if (!draft[huddleId]) {
        draft[huddleId] = [];
      }

      draft[huddleId].push(orgUserId);
    }));
  }, [huddleId]);

  const removeActiveUser = useCallback((orgUserId: string) => {
    const {
      activeUsers: currentActiveUsers,
      setActiveUsers,
      newAgenda,
      editedAgenda,
      reorderedAgenda,
      setNewAgenda,
      setEditedAgenda,
      setReorderedAgenda,
    } = useMeetingRealTimeStore.getState();

    setActiveUsers(produce(currentActiveUsers, (draft) => {
      if (draft[huddleId]) {
        draft[huddleId] = draft[huddleId].filter((activeUser) => (activeUser !== orgUserId));
      }
    }));

    setNewAgenda(produce(newAgenda, (draft) => {
      // ToDo: Remove all events tied to user
      if (draft[huddleId]) {
        draft[huddleId] = Object.fromEntries(
          Object.entries(draft[huddleId]).filter((entry) => (entry[1].creator.orgUserId !== orgUserId)),
        );
      }
    }));

    setEditedAgenda(produce(editedAgenda, (draft) => {
      // ToDo: Remove all events tied to user
      if (draft[huddleId]) {
        draft[huddleId] = Object.fromEntries(
          Object.entries(draft[huddleId]).filter((entry) => (entry[1].updater.orgUserId !== orgUserId)),
        );
      }
    }));

    setReorderedAgenda(produce(reorderedAgenda, (draft) => {
      // ToDo: Remove all events tied to user
      if (draft[huddleId]) {
        draft[huddleId] = Object.fromEntries(
          Object.entries(draft[huddleId]).filter((entry) => (entry[1].updater.orgUserId !== orgUserId)),
        );
      }
    }));
  }, [huddleId]);

  return {
    activeUsers,
    addActiveUser,
    removeActiveUser,
  };
};

interface UseRealtimeCreatingAgendaReturn {
  realtimeCreatingAgenda: RecursiveObject<AgendaCreatePayload[]>,
  addRealtimeCreatingAgenda: (payload: AgendaTopicCreatePayload | AgendaSectionCreatePayload | AgendaCommentCreatePayload) => void,
  removeRealtimeCreatingAgenda: (eventId: string) => void,
}

export const useRealtimeCreatingAgenda = (huddleId: string): UseRealtimeCreatingAgendaReturn => {
  const { newAgenda } = useMeetingRealTimeStore((state) => (state));

  const addRealtimeCreatingAgenda = useCallback((payload: AgendaTopicCreatePayload | AgendaSectionCreatePayload | AgendaCommentCreatePayload) => {
    const {
      newAgenda: currentNewAgenda,
      setNewAgenda,
    } = useMeetingRealTimeStore.getState();

    setNewAgenda(produce(currentNewAgenda, (draft) => {
      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      set(draft, `${huddleId}.${[...(payload.parentIds ?? []), payload.eventId].join(':')}`, payload);
    }));
  }, [huddleId]);

  const removeRealtimeCreatingAgenda = useCallback((eventId: string, parentIds?: string[]) => {
    const {
      newAgenda: currentNewAgenda,
      setNewAgenda,
    } = useMeetingRealTimeStore.getState();

    setNewAgenda(produce(currentNewAgenda, (draft) => {
      unset(draft, `${huddleId}.${[...(parentIds ?? []), eventId].join(':')}`);
    }));
  }, [huddleId]);

  const realtimeCreatingAgenda = useMemo(() => (
    // @ts-expect-error ToDo: Fix
    (hydratePayloads(newAgenda[huddleId]))
  ), [
    newAgenda,
    huddleId,
  ]);

  return {
    // @ts-expect-error ToDo: Fix
    realtimeCreatingAgenda,
    addRealtimeCreatingAgenda,
    removeRealtimeCreatingAgenda,
  };
};

interface UseRealtimeUpdatingAgendaReturn {
  realtimeUpdatingAgenda: RecursiveObject<AgendaUpdatePayload[]>,
  addRealtimeUpdatingAgenda: (payload: AgendaTopicUpdatePayload | AgendaSectionUpdatePayload | AgendaCommentUpdatePayload) => void,
  removeRealtimeUpdatingAgenda: (eventId: string, id: string, parentIds: string[]) => void
}
export const useRealtimeUpdatingAgenda = (huddleId: string): UseRealtimeUpdatingAgendaReturn => {
  const { editedAgenda } = useMeetingRealTimeStore((state) => (state));

  const addRealtimeUpdatingAgenda = useCallback((payload: AgendaTopicUpdatePayload | AgendaSectionUpdatePayload | AgendaCommentUpdatePayload) => {
    const {
      editedAgenda: currentEditedAgenda,
      setEditedAgenda,
    } = useMeetingRealTimeStore.getState();

    setEditedAgenda(produce(currentEditedAgenda, (draft) => {
      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      set(draft, `${huddleId}.${[...(payload.parentIds ?? []), payload.id, payload.eventId].join(':')}`, payload);
    }));
  }, [huddleId]);

  const removeRealtimeUpdatingAgenda = useCallback((eventId: string, id: string, parentIds: string[], oldId?: string) => {
    const {
      editedAgenda: currentEditedAgenda,
      setEditedAgenda,
    } = useMeetingRealTimeStore.getState();

    // ToDo: need to account for oldId/id change

    setEditedAgenda(produce(currentEditedAgenda, (draft) => {
      unset(draft, `${huddleId}.${[...(parentIds ?? []), oldId ?? id, eventId].join(':')}`);
    }));
  }, [huddleId]);

  const realtimeUpdatingAgenda = useMemo(() => (
    hydratePayloads(editedAgenda[huddleId])
  ), [
    editedAgenda,
    huddleId,
  ]);

  return {
    realtimeUpdatingAgenda,
    addRealtimeUpdatingAgenda,
    removeRealtimeUpdatingAgenda,
  };
};

interface UseRealtimeReorderingAgendaReturn {
  realtimeReorderingAgenda: RecursiveObject<AgendaReorderPayload[]>,
  addRealtimeReorderingAgenda: (payload: AgendaReorderPayload) => void,
  removeRealtimeReorderingAgenda: (eventId: string, id: string, parentIds: string[]) => void
}

export const useRealtimeReorderingAgenda = (huddleId: string): UseRealtimeReorderingAgendaReturn => {
  const { reorderedAgenda } = useMeetingRealTimeStore((state) => (state));

  const addRealtimeReorderingAgenda = useCallback((payload: AgendaReorderPayload) => {
    const {
      reorderedAgenda: currentReorderedAgenda,
      setReorderedAgenda,
    } = useMeetingRealTimeStore.getState();

    setReorderedAgenda(produce(currentReorderedAgenda, (draft) => {
      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      set(draft, `${huddleId}.${[...(payload.parentIds ?? []), payload.id, payload.eventId].join(':')}`, payload);
    }));
  }, [huddleId]);

  const removeRealtimeReorderingAgenda = useCallback((eventId: string, id: string, parentIds: string[]) => {
    const {
      reorderedAgenda: currentReorderedAgenda,
      setReorderedAgenda,
    } = useMeetingRealTimeStore.getState();

    setReorderedAgenda(produce(currentReorderedAgenda, (draft) => {
      unset(draft, `${huddleId}.${[...(parentIds ?? []), id, eventId].join(':')}`);
    }));
  }, [huddleId]);

  const realtimeReorderingAgenda = useMemo(() => (
    hydratePayloads(reorderedAgenda[huddleId])
  ), [
    huddleId,
    reorderedAgenda,
  ]);

  return {
    realtimeReorderingAgenda,
    addRealtimeReorderingAgenda,
    removeRealtimeReorderingAgenda,
  };
};

export const hydratePayloads = <T extends AgendaCreatePayload | AgendaUpdatePayload | AgendaReorderPayload>(foo: Record<string, T>): RecursiveObject<T[]> => {
  const bar: RecursiveObject<T[]> = {};

  if (foo) {
    Object.values(foo).forEach((payload) => {
      const keypath = [];

      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (payload.parentIds?.length) {
        // @ts-expect-error ToDo: Fix
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        keypath.push(...payload.parentIds);
      }

      // @ts-expect-error ToDo: Fix
      if (payload.id) {
        // @ts-expect-error ToDo: Fix
        keypath.push(payload.id);
      }

      keypath.push('data');

      // const superTempPayloads = at(bar, keypath.join('.'));
      const tempPayloads = at(bar, keypath.join('.'))?.[0] ?? [];
      // @ts-expect-error ToDo: Fix
      const updatedPayloads = concat(tempPayloads, payload);

      set(bar, keypath.join('.'), updatedPayloads);
    });
  }

  return bar;
};
