import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { faArrowLeft, faArrowsRotate, faTimes } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment-timezone';
import { noop } from '~Deprecated/utils';

import IconButton from '~Common/V3/components/Buttons/IconButton';

import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { useNewPeople, useGetPeopleByList as usePeoplePickerByList } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import { MEETING_TITLES } from '~Meetings/const/meetingTypes';
import { ATTENDEE_SHAPE, DRAWER_STATE_SHAPE, SET_DRAWER_STATE_SHAPE } from '~Common/const/proptypes';

import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import DrawerInput from '~Common/V3/components/DrawerInput';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerDropdown from '~Deprecated/ui/components/Inputs/DrawerDropdown';
import DateTimePicker from '~Meetings/components/shared/DateTimePicker';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import Tooltip from '~Common/components/Tooltip';
import { WeekdaySelection } from '~Deprecated/ui/components/WeekdaySelection';
import { participantDrawerTemplate } from '~Meetings/components/drawers/ParticipantDrawer';
import { coachingSuggestionsDrawerTemplate } from '~Meetings/components/drawers/CoachingSuggestionsDrawer';
import { getUserId } from '~Common/utils/localStorage';
import { withoutMobileObject } from '~Common/styles/mixins';
import { useCreateMeeting, useCreateHuddle } from '~Meetings/hooks/useCreateMeeting';
import { selectParticipantsDrawerTemplate } from '~Common/components/Drawers/SelectParticipantsDrawer';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import useMeetingDateTimeFrequencyWeekdayTimezone from '~Meetings/hooks/useMeetingDateTimeFrequencyWeekdayTimezone';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TeamsListScope } from '~People/components/Teams/const/types';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import SkeletonLoader from '~Common/components/SkeletonLoader';

export const createMeetingTemplate = {
  name: 'NEW_CREATE_MEETING',
  width: DRAWER_WIDTHS.BASE,
};

const styles = {
  drawerInput: css`
    margin: 10px 0 !important;
  `,
  attendeeBox: css`
    width: 49%;
    margin: 10px 1% 10px 0;
  `,
  attendeeContainer: css`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  `,
  weekdaySelection: css`
    padding-inline: 0.5rem;
    margin-bottom: 25px;
  `,
  timeContainer: css`
    margin: 10px 0;
    display: flex;
  `,
  inviteButton: css({}, withoutMobileObject({
    display: 'none',
  })),
  avatarStyles: css`
    margin-right: 10px;
    margin-bottom: 10px;
  `,
  leadrButton: css({
    width: '100%',
  }),
  participantSkeleton: css({
    borderRadius: '5px',
  }),
  participantSkeletonContainer: css({
    display: 'contents',
  }),
};

const View = ({
  onFormSubmit,
  createButton,
  selectedFrequency,
  setSelectedDays,
  frequencyOptions,
  onFrequencyChange,
  addPeopleClick,
  defaultWeekday,
  closeDrawerClick,
  onBackButtonClick,
  timezone,
  timezoneFriendlyName,
  otherAttendees,
  onDateChange,
  initialTitle,
  showWeekdaySelection,
  title,
  showFrequency,
  selectedDays,
  teamOptions,
  shouldShowTeamsDropdown,
  onTeamChange,
  pendingParticipantCount,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        title={`Create a ${title}`}
        renderBackButton={(backButtonStyles) => (
          <IconButton
            onClick={onBackButtonClick}
            tooltip="Go Back"
            type="button"
            icon={faArrowLeft}
            css={backButtonStyles}
            size="large"
          />
        )}
        renderCloseButton={(closeButtonStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            tooltip="Close"
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            size="large"
          />
        )}
      />
    )}
    renderBody={(defaultBodyPadding) => (
      <div css={defaultBodyPadding}>
        <DrawerInput
          name="title"
          css={styles.drawerInput}
          initialValue={initialTitle}
          label="Meeting Title"
          required
        />

        <DrawerInput
          name="location"
          css={styles.drawerInput}
          label="Location"
          maxLength={300}
        />

        <DateTimePicker
          css={styles.drawerInput}
          onDateChange={onDateChange}
          label="Start Date"
        />

        {timezone && (
          <>
            <DrawerInput
              disabled
              css={styles.drawerInput}
              label="Timezone"
              value={timezoneFriendlyName || timezone}
            />
            <input
              readOnly
              type="hidden"
              name="timezone"
              value={timezone}
            />
          </>
        )}

        {showFrequency && (
          <>
            <DrawerDropdown
              fontAwesomeIcon={faArrowsRotate}
              name="frequency"
              items={frequencyOptions}
              label="Frequency"
              onChange={onFrequencyChange}
              value={selectedFrequency}
              css={styles.drawerInput}
            />
            {showWeekdaySelection && (
              <WeekdaySelection
                css={styles.weekdaySelection}
                keepSelected={defaultWeekday}
                selected={selectedDays}
                onSelectionChange={(days) => {
                  setSelectedDays(days);
                }}
              />
            )}
          </>
        )}

        { shouldShowTeamsDropdown && (
          <DrawerDropdown
            name="teamId"
            items={teamOptions}
            label="Link Team"
            css={styles.drawerInput}
            value=""
            onChange={onTeamChange}
            showEmptyOption
          />
        )}

        <SectionHeader
          text="Participants"
          css={styles.header}
          renderButton={() => (
            <LeadrButton
              css={styles.inviteButton}
              data-test-id="meetingSelectParticipants"
              size="small"
              onClick={addPeopleClick}
            >
              Select
            </LeadrButton>
          )}
        />

        <div css={styles.attendeeContainer}>
          {otherAttendees.map((attendee) => (
            <div>
              <Tooltip content={`${attendee.firstName} ${attendee.lastName}`}>
                <div className="menu-item" data-toggle="tooltip">
                  <div className="d-flex">
                    <SquareAvatar
                      imgUrl={attendee.profileImageUrl || attendee.imageUrl}
                      name={`${attendee.firstName} ${attendee.lastName}`}
                      width={25}
                      height={25}
                      includeInitials
                      css={styles.avatarStyles}
                    />
                  </div>
                </div>
              </Tooltip>
            </div>
          ))}

          { !!pendingParticipantCount && (
            <MultipleSkeletonLoaders
              css={styles.participantSkeletonContainer}
              numberOfSkeletons={pendingParticipantCount}
              renderSkeletonItem={() => (
                <SkeletonLoader
                  css={[styles.avatarStyles, styles.participantSkeleton]}
                  width={25}
                  height={25}
                  variant="rectangular"
                  renderComponent={() => <></>}
                />
              )}
            />
          )}
        </div>

        {createButton()}

      </div>
    )}
  />
);

View.propTypes = {
  selectedFrequency: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  closeDrawerClick: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  setSelectedDays: PropTypes.func.isRequired,
  onFrequencyChange: PropTypes.func.isRequired,
  addPeopleClick: PropTypes.func.isRequired,
  otherAttendees: PropTypes.arrayOf(ATTENDEE_SHAPE),
  frequencyOptions: PropTypes.arrayOf(PropTypes.string),
  defaultWeekday: PropTypes.number,
  timezone: PropTypes.string.isRequired,
  timezoneFriendlyName: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  initialTitle: PropTypes.string,
  showWeekdaySelection: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  showFrequency: PropTypes.bool,
  selectedDays: PropTypes.arrayOf(PropTypes.number).isRequired,
  shouldShowTeamsDropdown: PropTypes.bool.isRequired,
  teamOptions: PropTypes.arrayOf().isRequired,
  onTeamChange: PropTypes.func.isRequired,
  pendingParticipantCount: PropTypes.number,
};

View.defaultProps = {
  otherAttendees: [],
  frequencyOptions: [],
  defaultWeekday: null,
  timezoneFriendlyName: null,
  initialTitle: '',
  showFrequency: false,
  pendingParticipantCount: 0,
};

const CreateMeetingDrawer = ({
  drawerState,
  transitionState,
  setDrawerState,
  attendees,
  defaultAttendeeId,
  defaultFrequency,
  limit,
  type,
  title,
  onMeetingCreationCallback,
  openMeetingTypePickerDrawer,
  popDrawer,
  pushDrawer,
  ...props
}) => {
  const userId = getUserId();
  const frontendUserGroups = useFeatureFlag('frontendUserGroups');
  const shouldUseCreateHuddle = useFeatureFlag('webClientUseCreateHuddle');
  const areTeamMeetingsEnabled = useFeatureFlag('webAppTgxMeetings');

  const [initialTitle, setInitialTitle] = useState('');
  const [teamId, setTeamId] = useState('');
  const [pendingParticipantCount, setPendingParticipantCount] = useState(0);
  const isMobile = useIsMobileQuery();

  const { data: teamDetailsData, isLoading: isTeamDetailsLoading } = useGetTeamDetails({ teamId });

  const { data: getTeamsData } = useGetTeams({
    listScope: TeamsListScope.MyTeams,
    page: 0,
    count: 200,
  });

  const teamOptions = useMemo(() => (
    getTeamsData?.response.teams.map((team) => ({
      text: team.name,
      value: team.teamId,
    }))
  ), [getTeamsData?.response]);

  const shouldShowTeamsDropdown = useMemo(() => (
    areTeamMeetingsEnabled && type === MeetingTypeEnum.MEETING && !!getTeamsData?.response.teams.length
  ), [
    type,
    areTeamMeetingsEnabled,
    getTeamsData?.response,
  ]);

  const selectedIds = useMemo(() => [userId, ...(drawerState.selectedAttendees || [])], [drawerState.selectedAttendees, userId]);
  const attendeeData = usePeoplePickerByList({ selectedIds });
  const otherAttendees = useMemo(() => attendeeData.filter((attId) => attId !== undefined), [attendeeData]);
  const {
    peopleData,
  } = useNewPeople();

  const [clickingBack, setClickingBack] = useState();
  const peopleDrawerToUse = frontendUserGroups && title !== MEETING_TITLES.ONE_TIME_ONE_ON_ONE ? selectParticipantsDrawerTemplate : participantDrawerTemplate;

  // Moment day() uses Sunday = 0, so we need to convert it to our base (Monday = 0)
  const defaultSelectedDay = useMemo(() => (moment().day() + 6) % 7, []);

  const {
    timezone, setSelectedDays, onDateChange, selectedFrequency, defaultWeekday, frequencyOptions,
    onFrequencyChange, showWeekdaySelection, timezoneFriendlyName, selectedDays, showFrequency,
  } = useMeetingDateTimeFrequencyWeekdayTimezone({
    defaultFrequency,
    showFrequency: title !== MEETING_TITLES.ONE_TIME_ONE_ON_ONE,
    isOneOnOne: title !== MEETING_TITLES.TEAM_MEETING,
    initialSelectedDays: [defaultSelectedDay],
  });

  const { isLoading: isCreateMeetingLoading, execute: doCreateMeeting } = useCreateMeeting(selectedFrequency, onMeetingCreationCallback);
  const { isLoading: isCreateHuddleLoading, mutate: doCreateHuddle } = useCreateHuddle(onMeetingCreationCallback);
  const shouldDisableSubmit = useMemo(() => (
    isCreateMeetingLoading || isCreateHuddleLoading || isTeamDetailsLoading
  ), [
    isCreateMeetingLoading,
    isCreateHuddleLoading,
    isTeamDetailsLoading,
  ]);

  useEffect(() => {
    const hasEntered = transitionState === TRANSITION_STATE.ENTERED;

    if (!isMobile && hasEntered) {
      const isOneOnOne = title === MEETING_TITLES.ONE_ON_ONE_MEETING;
      const drawerTemplate = isOneOnOne ? coachingSuggestionsDrawerTemplate : peopleDrawerToUse;

      pushDrawer({
        drawer: {
          ...drawerTemplate,
          args: {
            limit,
            useOrgIds: false,
            allowSelf: false,
          },
        },
      });
    }
  }, [limit, title, transitionState, peopleDrawerToUse, pushDrawer, isMobile]);

  useEffect(() => {
    const hasExited = transitionState === TRANSITION_STATE.EXITED;

    if (hasExited && clickingBack) {
      openMeetingTypePickerDrawer();
    }
  }, [clickingBack, transitionState, openMeetingTypePickerDrawer]);

  const addPeopleClick = () => {
    const isOneOnOne = title === MEETING_TITLES.ONE_ON_ONE_MEETING;
    const drawerTemplate = isOneOnOne ? coachingSuggestionsDrawerTemplate : peopleDrawerToUse;

    pushDrawer({
      drawer: {
        ...drawerTemplate,
        args: {
          limit,
          useOrgIds: false,
          allowSelf: false,
        },
      },
    });
  };

  useEffect(() => {
    setDrawerState({
      selectedAttendees: [],
      topics: [],
    });

    return () => {
      setDrawerState({});
    };
  }, [setDrawerState]);

  useEffect(() => {
    // Use moment to account for the user selected timezone if any
    onDateChange(moment().valueOf());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (title === MEETING_TITLES.ONE_ON_ONE_MEETING && otherAttendees?.length === 2) {
      setInitialTitle(`1:1 | ${otherAttendees[1].firstName} & ${otherAttendees[0].firstName}`);
    }

    if (title === MEETING_TITLES.ONE_TIME_ONE_ON_ONE && otherAttendees?.length === 2) {
      setInitialTitle(`One-time 1:1 Meeting | ${otherAttendees[1].firstName} & ${otherAttendees[0].firstName}`);
    }
  }, [title, otherAttendees]);

  useEffect(() => {
    if (defaultAttendeeId) {
      setDrawerState({ ...drawerState, selectedAttendees: [defaultAttendeeId] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = useCallback((e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const newMeeting = Object.fromEntries(formData.entries());

    const createData = {
      ...newMeeting,
      attendees: drawerState.selectedAttendees || [],
      topics: drawerState.topics || [],
      type,
    };

    if (showWeekdaySelection) {
      createData.daysOfWeek = selectedDays;
    }

    if (shouldUseCreateHuddle) {
      doCreateHuddle(createData);
    } else {
      doCreateMeeting(createData);
    }
  }, [
    selectedDays,
    drawerState.topics,
    drawerState.selectedAttendees,
    showWeekdaySelection,
    shouldUseCreateHuddle,
    doCreateMeeting,
    doCreateHuddle,
    type,
  ]);

  const createButton = useCallback(() => (
    <LeadrButton
      css={styles.leadrButton}
      type="submit"
      data-test-id="createMeeting"
      disabled={shouldDisableSubmit || drawerState?.selectedAttendees?.length === 0}
    >
      Create Meeting
    </LeadrButton>
  ), [drawerState, shouldDisableSubmit]);

  const closeDrawerClick = () => {
    popDrawer({ popAll: true });
  };

  const onBackButtonClick = useCallback(() => {
    popDrawer({ popAll: true });
    setClickingBack(true);
  }, [popDrawer, setClickingBack]);

  const onTeamChange = useCallback((e) => {
    const selectedTeam = getTeamsData?.response.teams.find((team) => (
      team.teamId === e.target.value
    ));

    if (selectedTeam) {
      setTeamId(e.target.value);

      setDrawerState({ ...drawerState, selectedAttendees: [] });

      // The current user is already always present in the participant list
      // so we need to remove them from the total count to be added.
      setPendingParticipantCount(selectedTeam.memberCount - 1);
    }
  }, [
    drawerState,
    setDrawerState,
    getTeamsData?.response,
  ]);

  useEffect(() => {
    if (teamDetailsData?.response) {
      const {
        memberOrgUserIds,
        ownerOrgUserIds,
      } = teamDetailsData.response;

      const orgUserIds = [
        ...memberOrgUserIds,
        ...ownerOrgUserIds,
      ];

      const userIds = orgUserIds.map((orgUserId) => (peopleData[orgUserId]?.userId)).filter((teamMemberUserId) => (teamMemberUserId !== userId));

      setPendingParticipantCount(0);

      setDrawerState({
        ...drawerState,
        selectedAttendees: userIds,
      });
    }
    // drawerState and setDrawerState excluded as we only want
    // to set the selected participants to change when a new team
    // has been selected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // drawerState,
    // setDrawerState,
    teamDetailsData?.response,
  ]);

  const hookProps = {
    onFormSubmit,
    createButton,
    onDateChange,
    selectedFrequency,
    frequencyOptions,
    onFrequencyChange,
    otherAttendees,
    addPeopleClick,
    setSelectedDays,
    selectedDays,
    defaultWeekday,
    closeDrawerClick,
    onBackButtonClick,
    timezone,
    timezoneFriendlyName,
    initialTitle,
    showWeekdaySelection,
    title,
    showFrequency,
    teamOptions,
    shouldShowTeamsDropdown,
    onTeamChange,
    pendingParticipantCount,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

CreateMeetingDrawer.propTypes = {
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: SET_DRAWER_STATE_SHAPE.isRequired,
  type: PropTypes.string.isRequired,
  attendees: ATTENDEE_SHAPE,
  defaultFrequency: PropTypes.string,
  defaultAttendeeId: PropTypes.string,
  limit: PropTypes.number,
  pushDrawer: PropTypes.func.isRequired,
  popDrawer: PropTypes.func.isRequired,
  popAfter: PropTypes.func.isRequired,
  transitionState: PropTypes.oneOf(Object.values(TRANSITION_STATE)).isRequired,
  title: PropTypes.string.isRequired,
  onMeetingCreationCallback: PropTypes.func,
  openMeetingTypePickerDrawer: PropTypes.func.isRequired,
};

CreateMeetingDrawer.defaultProps = {
  attendees: ATTENDEE_SHAPE,
  defaultFrequency: '',
  defaultAttendeeId: '',
  limit: null,
  onMeetingCreationCallback: noop,
};

registerDrawer({
  templateName: createMeetingTemplate.name,
  component: CreateMeetingDrawer,
});

export { View };
export default CreateMeetingDrawer;
