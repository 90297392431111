import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { useContext } from 'react';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';
import { MeetingInfoContext } from '../../../providers/MeetingInfoContext';
import Avatars from './Avatars';
import RecurrenceText from './RecurrenceText';
import LocationText from './LocationText';
import StartTimeText from './StartTimeText';
import TeamNameText from './TeamNameText';

const styles = {
  header: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem 1.25rem',
    border: `1px solid ${palette.neutrals.gray500}`,
    borderRadius: '.5rem',
  }),
  topSection: css({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5rem',
    marginBottom: '0.5rem',
    borderBottom: `1px solid ${palette.neutrals.gray100}`,
  }),
  textContainer: css({
    overflow: 'hidden',
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1.5rem',
    marginRight: '0.5rem',
  }),
  menuIcon: css({
    fontSize: '1.25rem',
  }),
  avatars: css({
    marginTop: '0.5rem',
    marginBottom: '0.75rem',
  }),
  textsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.25rem',
  }),
  bottomSection: css({
    display: 'flex',
    flexDirection: 'column',
  }),
};

interface ViewProps {
  title: string,
  location?: string,
}

const View = ({
  title,
  location,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.header}
    {...props}
  >
    <div css={styles.topSection}>
      <div css={styles.textContainer}>
        <TooltipOnTruncate css={styles.title} text={title} />
      </div>
      <Avatars css={styles.avatars} />
      <div css={styles.textsContainer}>
        <RecurrenceText />
        <StartTimeText />
        {location && (
          <LocationText location={location} />
        )}
      </div>
    </div>
    <div css={styles.bottomSection}>
      <TeamNameText />
    </div>
  </div>
);

const Header = ({ ...props }): JSX.Element => {
  const {
    title,
    location,
  } = useContext(MeetingInfoContext);

  const hookProps = {
    title,
    location,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default Header;
