import { css } from '@emotion/react';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import { useState } from 'react';
import { CardSkeleton } from '~Common/V3/components/Card';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { palette } from '~Common/styles/colors';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { stripHtml } from '~Common/utils/stripHtml';
import EmptyPDPResources from '~DevelopmentPlan/assets/images/emptyPDPResources.svg';
import { DEFAULT_DATE, DEFAULT_RESOURCE_TITLE, RECOGNITION_PAGE_SIZE } from '~DevelopmentPlan/const/defaults';
import { modalExistingItemStyles } from '~DevelopmentPlan/const/pageStyles';
import { CompetencyResourceStatusEnum, ResourceType } from '~DevelopmentPlan/const/types';
import { useAddResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { RecognitionShape } from '~Recognition/const/types';
import { useGetRecognition } from '~Recognition/hooks/useGetRecognition';
import { ContextButtons } from '~Reviews/V2/Shared/ContextButtons';

const styles = {
  ...modalExistingItemStyles,
  searchedText: css({
    color: palette.brand.indigo,
    fontWeight: 600,
  }),
  emptyStateImage: css({
    height: '7.5rem',
    marginBottom: '.875rem',
  }),
  textContainer: css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 500,
    marginTop: '2rem',
    color: palette.neutrals.gray800,
  }),
};

interface ViewProps {
  onResourceClick: (resourceId: string, resourceContentTitle: string, resourceContentDueDate: Date) => void,
  resourceIdSelected: string[],
  actionTextToUse: string,
  resourceTypeTitle: string,
  runAddResourceValidations: (resourceIdClicked: ResourceType, contentId: string | number) => void,
  closeAddResourceModal: () => void,
  isRecognitionsLoading: boolean,
  isFetching: boolean,
  recognitionData: RecognitionShape[],
  searchText: string,
}

const View = ({
  onResourceClick,
  resourceIdSelected,
  actionTextToUse,
  resourceTypeTitle,
  runAddResourceValidations,
  closeAddResourceModal,
  isRecognitionsLoading,
  isFetching,
  recognitionData,
  searchText,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="modalButtons"
      renderContents={() => (
        <>
          <LeadrButton
            data-test-id="addResourceModalSaveChanges"
            size="small"
            type="submit"
            onClick={() => runAddResourceValidations(ResourceType.Recognition, resourceIdSelected[0])}
            disabled={resourceIdSelected.length === 0}
          >
            {`${actionTextToUse} ${resourceTypeTitle}`}
          </LeadrButton>
          <LeadrButton
            data-test-id="addResourceModalCancelChanges"
            onClick={closeAddResourceModal}
            size="small"
            variant="ghost"
          >
            Cancel
          </LeadrButton>
        </>
      )}
    />
    {isRecognitionsLoading && (
    <MultipleSkeletonLoaders
      css={styles.skeletonWrapper}
      numberOfSkeletons={6}
      renderSkeletonItem={() => (
        <CardSkeleton css={styles.cardSkeleton} />
      )}
    />
    )}
    {isFetching && !isRecognitionsLoading && (
    <div css={styles.isFetchingBar}>
      <LinearProgress
        color="inherit"
        variant="indeterminate"
      />
    </div>
    )}
    {!isRecognitionsLoading && (
    <>
      {recognitionData && recognitionData.length === 0 && (
      <div
        css={styles.textContainer}
      >
        <EmptyPDPResources css={styles.emptyStateImage} title="No Recognitions" />
        {searchText !== '' && (
          <p>
            <span
              css={styles.searchedText}
            >
              {`"${searchText}" `}
            </span>
            not found in Recognitions. Try clearing search and trying again.
          </p>
        )}
        {searchText === '' && (
        <p>
          No Recognitions found.
        </p>
        )}
      </div>
      )}
      {recognitionData.map((recognition) => {
        const {
          createdBy,
          content,
          createdOn,
        } = recognition;

        const createdByName = `${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`;
        const isSelected = resourceIdSelected.includes(recognition.recognitionId.toLocaleString());
        const dateToUse = new Date(createdOn);
        const strippedContent = stripHtml(content);
        const idToUse = recognition.recognitionId.toLocaleString();

        return (
          <div
            css={styles.resource(isSelected)}
            key={recognition.recognitionId}
          >
            <div>
              <div css={styles.title(isSelected)}>{strippedContent}</div>
              <div css={styles.subText(isSelected)}>{`Recognized by ${createdByName}`}</div>
            </div>
            {isSelected && (
            <LeadrButton
              css={styles.button(isSelected)}
              variant="ghost"
              color="danger"
              onClick={() => onResourceClick(idToUse, strippedContent, dateToUse)}
              size="small"
              data-test-id="removeResource"
            >
              <LeadrButton.IconAndText
                icon={faClose}
                text=""
              />
            </LeadrButton>
            )}
            {!isSelected && (
            <LeadrButton
              css={styles.button(isSelected)}
              onClick={() => onResourceClick(idToUse, strippedContent, dateToUse)}
              size="small"
              data-test-id="selectResource"
            >
              Select
            </LeadrButton>
            )}
          </div>
        );
      })}
    </>
    )}
  </>
);

interface AddRecognitionProps {
  runAddResourceValidations: (resourceIdClicked: ResourceType, contentId: string | number) => void,
  actionTextToUse: string,
  resourceTypeTitle: string,
}

export const AddRecognition = ({
  runAddResourceValidations,
  actionTextToUse,
  resourceTypeTitle,
}: AddRecognitionProps): JSX.Element => {
  const [resourceIdSelected, setResourceIdSelected] = useState<string[]>([]);

  const {
    closeAddResourceModal,
    setResourceContentTitle,
    setResourceContentDueDate,
    setResourceContentStatus,
  } = useAddResourceModalStore((state) => ({
    closeAddResourceModal: state.closeAddResourceModal,
    setResourceContentTitle: state.setResourceContentTitle,
    setResourceContentDueDate: state.setResourceContentDueDate,
    setResourceContentStatus: state.setResourceContentStatus,
  }));
  const onResourceClick = (resourceId: string, resourceContentTitle: string, resourceContentDueDate: Date): void => {
    setResourceIdSelected((prevState) => {
      const isAlreadySelected = prevState.includes(resourceId);

      if (isAlreadySelected) {
        setResourceContentTitle(DEFAULT_RESOURCE_TITLE);
        setResourceContentDueDate(DEFAULT_DATE);
        setResourceContentStatus(CompetencyResourceStatusEnum.NotStarted);
        // If present, remove it
        return prevState.filter((id) => id !== resourceId);
      }
      setResourceContentTitle(resourceContentTitle);
      setResourceContentDueDate(resourceContentDueDate);
      setResourceContentStatus(CompetencyResourceStatusEnum.Complete);
      return [resourceId];
    });
  };

  const {
    searchText,
    planStartDate,
    planDueDate,
  } = useAddResourceModalStore((state) => ({
    searchText: state.searchText,
    planStartDate: state.planStartDate,
    planDueDate: state.planDueDate,
  }));
  const { dataValues: recognitionData, isLoading: isRecognitionsLoading, isFetching } = useGetRecognition({
    take: RECOGNITION_PAGE_SIZE,
    search: searchText ?? undefined,
    startDate: moment(planStartDate).format('L'),
    endDate: moment(planDueDate).format('L'),
    recipientOrgUserIds: [getOrganizationUserId() ?? ''],
  });

  const hookProps = {
    onResourceClick,
    resourceIdSelected,
    actionTextToUse,
    resourceTypeTitle,
    runAddResourceValidations,
    closeAddResourceModal,
    isRecognitionsLoading,
    isFetching,
    recognitionData,
    searchText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
