import { css } from '@emotion/react';
import { useCallback, useEffect, useState } from 'react';
import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import useDidUpdate from '@rooks/use-did-update';
import { nestedListBackground, palette } from '~Common/styles/colors';
import { noop } from '~Deprecated/utils';
import { usePrevious } from '~Deprecated/hooks/usePrevious';

const dayNames = [
  'S', 'M', 'T', 'W', 'T', 'F', 'S',
];

// Mapping 0 = MONDAY, etc on the backend
const weekdayNumbers = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
};

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,
  repeatOnText: css`
    color: ${palette.neutrals.gray500};
    font-weight: bold;
    padding: 0.5em 0;
  `,
  dayWrapper: css`
    margin-block-start: 0.5rem;
    display: grid;
    grid-template-columns: repeat(7, 2.25rem);
    grid-gap: 0.5rem;
  `,
  dayStyle: (isSelected) => css`
    &.MuiButtonBase-root {
      border-radius: 2.25rem;
      width: 2.25rem;
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${isSelected ? palette.neutrals.white : palette.neutrals.black};
      background-color: ${isSelected ? palette.brand.indigo : `${nestedListBackground}`};
    }
  `,
};

export const WeekdaySelection = ({
  className, onSelectionChange, selected, keepSelected,
}) => {
  const previousKeepSelected = usePrevious(keepSelected);
  const [selectedDays, setSelectedDays] = useState({
    0: selected.indexOf(weekdayNumbers.SUNDAY) > -1 || keepSelected === 0,
    1: selected.indexOf(weekdayNumbers.MONDAY) > -1 || keepSelected === 1,
    2: selected.indexOf(weekdayNumbers.TUESDAY) > -1 || keepSelected === 2,
    3: selected.indexOf(weekdayNumbers.WEDNESDAY) > -1 || keepSelected === 3,
    4: selected.indexOf(weekdayNumbers.THURSDAY) > -1 || keepSelected === 4,
    5: selected.indexOf(weekdayNumbers.FRIDAY) > -1 || keepSelected === 5,
    6: selected.indexOf(weekdayNumbers.SATURDAY) > -1 || keepSelected === 6,
  });

  const selectionChangeCb = useCallback((value) => {
    if (onSelectionChange && typeof onSelectionChange === 'function') {
      const days = [];
      Object.values(value).forEach((isSelected, index) => {
        if (isSelected) {
          // 0 = Monday on the backend
          days.push((index + 6) % 7);
        }
      });
      onSelectionChange(days);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDaySelection = useCallback((dayIndex, isSelected, days) => {
    if (isSelected && dayIndex === keepSelected) {
      return;
    }
    setSelectedDays({
      ...days,
      [dayIndex]: !isSelected,
    });
  }, [setSelectedDays, keepSelected]);

  const isValidDay = useCallback((dayIndex) => [0, 1, 2, 3, 4, 5, 6].indexOf(dayIndex) > -1, []);

  useEffect(() => {
    selectionChangeCb(selectedDays);
  }, [selectedDays, selectionChangeCb]);

  useDidUpdate(() => {
    // Need to check if previousKeepSelected is valid as EditMeeting, CreateMeetingDrawer, and RestoreMeeting
    // components pull their data via the useMeetingDateTimeFrequencyWeekdayTimezone hook and call onDateChange
    // after the fact to set the meeting start date once it is returned by useMeetingDetails. When called
    // it sets defaultWeekday which is what is passed into keepSelected. This is being seen as a change in
    // keepSelected, which was knocking out the selected days. See: LW-6146
    //
    // There are better solutions to this issue. One being preventing this component from rendering at all until
    // we have the meeting data returned by useMeetingDetails so it won't require an out-of-phase update,
    // but that is a bit outside the scope of this fix. This should resolve the issue for now.
    //
    // ToDo: Remove the previousKeepSelected check once we update the code to not cause phantom keepSelected updates.
    if (isValidDay(previousKeepSelected) && isValidDay(keepSelected)) {
      setSelectedDays({
        0: keepSelected === 0,
        1: keepSelected === 1,
        2: keepSelected === 2,
        3: keepSelected === 3,
        4: keepSelected === 4,
        5: keepSelected === 5,
        6: keepSelected === 6,
      });
    }
  }, [keepSelected]);

  return (
    <div css={styles.wrapper} className={className}>
      <span css={styles.repeatOnText}>Repeat On</span>
      <div css={styles.dayWrapper}>
        { Object.values(selectedDays).map((isSelected, dayIndex) => (
          <ButtonBase
            css={styles.dayStyle(isSelected)}
            data-test-is-selected={isSelected}
            onClick={() => toggleDaySelection(dayIndex, isSelected, selectedDays)}
            key={`weekday-${dayIndex + 1}`}
          >
            { dayNames[dayIndex] }
          </ButtonBase>
        )) }
      </div>
    </div>
  );
};

WeekdaySelection.propTypes = {
  className: PropTypes.string,
  onSelectionChange: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.number),
  keepSelected: PropTypes.number,
};

WeekdaySelection.defaultProps = {
  className: '',
  onSelectionChange: noop,
  selected: [],
  keepSelected: null,
};
