import { css } from '@emotion/react';
import { MouseEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { hexToRGBA, palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { reviewTemplateDetailsTemplate } from '../TemplateDetailsDrawer';
import { ReviewTemplate } from '../useReviewTemplates';

const styles = {
  chevron: css({
    color: palette.neutrals.gray700,
  }),
  description: css({
    color: palette.neutrals.gray600,
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
  }),
  templateRow: css({
    alignItems: 'center',
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    display: 'flex',
    flexDirection: 'row',
    gap: '3rem',
    padding: '1rem 1.25rem 1rem 0.75rem',
    width: '100%',
  }),
  textContainer: css({
    flex: '1',
    overflow: 'clip',
    textAlign: 'left',
  }),
  name: css({
    color: palette.neutrals.gray800,
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
  }),
};

interface ViewProps {
  createdBy: string,
  openDetailsDrawer: MouseEventHandler,
  name: string,
}

export const View = ({
  createdBy,
  openDetailsDrawer,
  name,
}: ViewProps): JSX.Element => (
  <li
    css={styles.templateRow}
  >
    <div css={styles.textContainer}>
      <p css={styles.name}>{name}</p>
      <p css={styles.description}>
        {`Created by ${createdBy}`}
      </p>
    </div>
    <LeadrButton
      size="small"
      onClick={openDetailsDrawer}
      data-test-id="reviewsTemplateView"
    >
      View
    </LeadrButton>
  </li>
);

interface TemplateRowProps {
  reviewCycleUid?: string,
  template: ReviewTemplate,
}

export const TemplateRow = ({
  reviewCycleUid,
  template,
  ...props
}: TemplateRowProps): JSX.Element => {
  const dispatch = useDispatch();

  const openDetailsDrawer: MouseEventHandler = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...reviewTemplateDetailsTemplate,
        args: {
          reviewCycleUid,
          template,
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const firstName = template?.createdBy?.firstName ?? 'Leadr';
  const lastName = template?.createdBy?.lastName ?? 'User';

  const hookProps = {
    createdBy: template.organizationUid ? `${firstName} ${lastName}` : 'Leadr',
    description: template.description,
    openDetailsDrawer,
    name: template.name,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
