import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { postApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { cloneDeep } from 'lodash';
import { reviewCycleKeys } from './queryKeys';
import { ReviewShape, ReviewStatusEnum } from '../Const/types';

interface OnMutateReturn {
  previousReview: unknown
}

const optimisticUpdate = async (reviewUid: string): Promise<OnMutateReturn> => {
  const reviewKey = reviewCycleKeys.reviewByIdAndVersion(reviewUid, '2.5');
  // cancel current queries
  await queryClient.cancelQueries({ queryKey: reviewKey });

  // get current value
  const previousReview = queryClient.getQueryData(reviewKey);

  // update current value
  queryClient.setQueryData<HttpCallReturn<ReviewShape>>(
    reviewKey,
    (review) => {
      if (review) {
        const reviewWithNewStatus = cloneDeep(review);

        const { participants } = reviewWithNewStatus.response;
        const currentParticipantIndex = participants.findIndex((participant) => participant.orgUserId === getOrganizationUserId());

        if (currentParticipantIndex === -1) {
          return reviewWithNewStatus;
        }

        reviewWithNewStatus.response.participants[currentParticipantIndex].statusEnum = ReviewStatusEnum.Submitted;

        return reviewWithNewStatus;
      }

      return review;
    },
  );

  // return snapshot
  return {
    previousReview,
  };
};
interface submitReviewProps {
    reviewId: string | undefined,
  }

const submitReview = ({ reviewId }: submitReviewProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviews/${reviewId ?? ''}/submit`,
  };

  return postApi(serverUrl, {});
};

interface useSubmitReveiwProps{
  mutate: UseMutateFunction<HttpCallReturn<void>, Error, submitReviewProps, OnMutateReturn>,
  isLoading: boolean,
}

export const useSubmitReview = (): useSubmitReveiwProps => {
  const toastId = useRef<ReactText | number | null>(null);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: submitReview,
    async onMutate({ reviewId }) {
      toastId.current = toast.info('Submitting the review...', { autoClose: false });
      return optimisticUpdate(reviewId as string);
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error submitting the review. Please ensure all topics are answered and try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully submitted the review.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviews'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), getOrganizationUserId(), 'reviewCycles'] });
    },
  });

  return {
    mutate,
    isLoading,
  };
};
