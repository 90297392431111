import { ComponentProps } from 'react';
import {
  FormProvider,
  SubmitHandler,
  UseFormReturn,
  FieldValues,
} from 'react-hook-form';

interface FormProps<T extends FieldValues> extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formContext: UseFormReturn<T>,
  onSubmit: SubmitHandler<T>,
}

export function Form<T extends FieldValues>({
  formContext,
  onSubmit,
  children,
  ...props
}: FormProps<T>): JSX.Element {
  return (
    <FormProvider {...formContext}>
      <form noValidate onSubmit={formContext.handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}
