import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { teamsQueryKeys } from './queryKeys';
import { TeamDetails } from '../const/types';

interface GetTeamDetailsParams {
  orgId: string,
  teamId: string,
  huddleId?: string,
}

function getTeamDetails({
  orgId,
  teamId,
  huddleId,
}: GetTeamDetailsParams): Promise<HttpCallReturn<TeamDetails>> {
  const queryParams = huddleId ? '?contextType=MEETING_INSTANCE' : '';
  const contextId = huddleId || teamId;

  const serverUrl = {
    // Host doesn't matter, but need this format to use the V2 endpoint
    host: getHost(hosts.reviewCycles, '2'),
    uri: `/organizations/${orgId}/teams/${contextId}${queryParams}`,
  };

  return getApi<TeamDetails>(serverUrl);
}

interface UseGetTeamDetailsParams {
  teamId?: string,
  huddleId?: string,
  enabled?: boolean,
}

export function useGetTeamDetails({
  teamId,
  huddleId,
  enabled = true,
}: UseGetTeamDetailsParams): UseQueryResult<HttpCallReturn<TeamDetails>> {
  const orgId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: teamsQueryKeys.detail({
      orgId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      teamId: teamId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      huddleId: huddleId!,
    }),
    queryFn: () => getTeamDetails({
      orgId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      teamId: teamId!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      huddleId: huddleId!,
    }),
    enabled: !!teamId && enabled,
  });
}
