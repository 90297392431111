import { css } from '@emotion/react';
import Froala from '~Common/V3/components/Froala';
import { ORG_CLARITY_ANSWER_MAX_LENGTH, ORG_CLARITY_QUESTION_MAX_LENGTH } from '~Settings/const';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useState } from 'react';
import HiddenField from '~Common/components/Fields/HiddenField';
import InputWithConfirmButtons from '~Common/components/InputWithConfirmButtons';
import { palette } from '~Common/styles/colors';
import { OrgClarityQuestion } from '~Common/const/Organization';

const styles = {
  orgClarityInputs: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  questionContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  questionText: css({
    color: palette.neutrals.gray700,
    fontWeight: 500,
  }),
};

interface ViewProps {
  orgClarityQuestion: OrgClarityQuestion,
  handleEditOrgClarityQuestion: () => void,
  showQuestionEditor: boolean,
  questionText: string,
  handleQuestionTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleSaveQuestionEdit: () => void,
  handleCancelQuestionEdit: () => void,
}

const View = ({
  orgClarityQuestion,
  handleEditOrgClarityQuestion,
  showQuestionEditor,
  questionText,
  handleQuestionTextChange,
  handleSaveQuestionEdit,
  handleCancelQuestionEdit,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.orgClarityInputs}
    {...props}
  >
    {showQuestionEditor && (
      <InputWithConfirmButtons>
        <InputWithConfirmButtons.NormalInput
          label="Question"
          initialValue={questionText}
          maxLength={ORG_CLARITY_QUESTION_MAX_LENGTH}
          onChange={handleQuestionTextChange}
          required
        />
        <InputWithConfirmButtons.SaveConfirmButtons
          dataTestIdPrefix="orgSettingsEditClarityQuestion"
          handleSave={handleSaveQuestionEdit}
          handleCancel={handleCancelQuestionEdit}
          disableSaveButton={!questionText}
        />
      </InputWithConfirmButtons>
    )}
    {!showQuestionEditor && (
      <div css={styles.questionContainer}>
        <div css={styles.questionText}>{questionText}</div>
        <LeadrButton
          variant="text"
          data-test-id="orgSettingsEditOrgClarityQuestion"
          onClick={handleEditOrgClarityQuestion}
        >
          Edit
        </LeadrButton>
      </div>
    )}
    <HiddenField
      name={`${orgClarityQuestion.rank}-question`}
      value={questionText}
    />
    <Froala
      label="Answer"
      froalaConfigProps={{
        charCounterCount: true,
        charCounterMax: ORG_CLARITY_ANSWER_MAX_LENGTH,
        toolbarButtons: {
          moreText: {
            buttons: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
            buttonsVisible: 5,
          },
        },
        pasteDeniedTags: ['script', 'style', 'img', 'table', 'tr', 'td', 'th', 'tbody', 'thead', 'tfoot', 'iframe'],
        pastePlain: true,
      }}
      richTextEditorProps={{
        initialValue: orgClarityQuestion.answer,
      }}
      name={`${orgClarityQuestion.rank}-answer`}
    />
  </div>
);

interface OrgClarityInputsProps {
  orgClarityQuestion: OrgClarityQuestion,
}

const OrgClarityInputs = ({
  orgClarityQuestion,
  ...props
}: OrgClarityInputsProps): JSX.Element => {
  const [showQuestionEditor, setShowQuestionEditor] = useState(false);
  const [questionText, setQuestionText] = useState(orgClarityQuestion.question);

  const handleEditOrgClarityQuestion = (): void => {
    setShowQuestionEditor(true);
  };

  const handleSaveQuestionEdit = (): void => {
    setShowQuestionEditor(false);
  };

  const handleCancelQuestionEdit = (): void => {
    setShowQuestionEditor(false);
    setQuestionText(orgClarityQuestion.question);
  };

  const handleQuestionTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuestionText(event.target.value);
  };

  const hookProps = {
    orgClarityQuestion,
    showQuestionEditor,
    handleEditOrgClarityQuestion,
    questionText,
    handleQuestionTextChange,
    handleSaveQuestionEdit,
    handleCancelQuestionEdit,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default OrgClarityInputs;
