import { useQuery } from '@tanstack/react-query';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { toast } from '~Common/components/Toasts';
import { useEffect } from 'react';
import { calendarIntQueryKeys } from './queryKeys';

const getSelectedCalendarId = async (): Promise<HttpCallReturn<string>> => {
  const URL = {
    host: hosts.meeting,
    uri: `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/getLinkedCalendarId`,
  };

  return getApi(URL);
};

interface UseGetSelectedCalendarIdParams {
  availableCalendarsListCount: number,
}

interface UseGetSelectedCalendarIdReturn {
  isLoading: boolean,
  selectedCalendarId: string | undefined,
}

export const useGetSelectedCalendarId = ({
  availableCalendarsListCount,
}: UseGetSelectedCalendarIdParams): UseGetSelectedCalendarIdReturn => {
  const { data: authStatus } = useGetCalendarAuthStatus();

  const response = useQuery({
    queryKey: calendarIntQueryKeys.calendarLinkedId(),
    queryFn: getSelectedCalendarId,
    enabled: authStatus === AUTH_STATUS.LINKED && availableCalendarsListCount > 0,
  });

  useEffect(() => {
    /*
      if there is no selected calendar, it'll return null
    */
    if (response.isSuccess && (response.data?.response === null || response.data?.response === '')) {
      toast.warning('Please select a calendar to complete your calendar integration setup.');
    }
  }, [response.isSuccess, response.data?.response]);

  return {
    isLoading: response.isLoading,
    selectedCalendarId: response.data?.response,
  };
};
