/* eslint-disable react/button-has-type */

import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { BUTTON_VARIANTS } from '~Common/const/buttonVariants';
import { buttonStyleMap } from '~Deprecated/ui/styles/buttonStyleMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const style = css`
  min-width: 6rem;
  height: auto;
  padding: 0.75rem 1rem;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  border-radius: 8px;

  &:focus {
    outline: none;
  }

  & svg {
    margin-right: 5px;
    position: relative;
    top: -1px;
  }
`;

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const DrawerButton = ({
  text, type = 'button', variant, onClick, iconName = '', ...props
}) => (
  <button
    css={[style, buttonStyleMap[variant]]}
    type={type}
    onClick={onClick}
    {...props}
  >
    {iconName && (
      <FontAwesomeIcon icon={iconName} />
    )}
    {text}
  </button>
);

DrawerButton.propTypes = {
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
};

DrawerButton.defaultProps = {
  variant: BUTTON_VARIANTS.NEUTRAL,
  type: 'button',
  onClick: () => { },
  iconName: '',
};

export default DrawerButton;
