import { Goals } from '@leadr-hr/types';
import { createStore, useStore } from 'zustand';

interface GoalStatusModalState {
  displayedGoal?: Goals.Goal,
  displayedStatusId?: string,

  showStatusHistoryModal: boolean,
  openStatusHistoryModal: (goal: Goals.Goal, statusId?: string) => void,
  closeStatusHistoryModal: () => void,

  showStatusUpdateModal: boolean,
  openStatusUpdateModal: (goalId: Goals.Goal) => void,
  closeStatusUpdateModal: () => void,

  transitionToUpdateModal: () => void,
  returnToHistoryModal: boolean,
}

export const goalStatusModalStore = createStore<GoalStatusModalState>()((set, get) => ({
  showStatusHistoryModal: false,
  openStatusHistoryModal: (goal: Goals.Goal, statusId?: string) => {
    set(() => ({
      showStatusHistoryModal: true,
      showStatusUpdateModal: false,
      displayedGoal: goal,
      displayedGoalId: goal.goalId,
      displayedStatusId: statusId,
    }));
  },
  closeStatusHistoryModal: () => {
    set(() => ({ showStatusHistoryModal: false, displayedGoal: undefined }));
  },

  showStatusUpdateModal: false,
  openStatusUpdateModal: (goal: Goals.Goal) => {
    set(() => ({
      showStatusUpdateModal: true,
      displayedGoal: goal,
    }));
  },

  closeStatusUpdateModal: () => {
    set(() => ({
      showStatusUpdateModal: false,
      ...(get().returnToHistoryModal && {
        showStatusHistoryModal: true,
        returnToHistoryModal: false,
      }),
    }));
  },

  returnToHistoryModal: false,
  transitionToUpdateModal: () => {
    set(() => ({
      showStatusUpdateModal: true,
      showStatusHistoryModal: false,
      returnToHistoryModal: true,
    }));
  },
}));

export function useGoalStatusModalStore<T>(
  selector: (state: GoalStatusModalState) => T,
  equals?: (a: T, b: T) => boolean,
): T {
  return useStore(goalStatusModalStore, selector, equals);
}
