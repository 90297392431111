import { useRef, useEffect } from 'react';

export function useLastExistedValue<T>(value: T | undefined): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    if (value !== undefined && value !== null) {
      ref.current = value;
    }
  }, [value]);

  return value ?? ref.current;
}
