import { css } from '@emotion/react';
import React, {
  ComponentProps,
  useEffect,
  useRef,
  useState,
} from 'react';
import Tooltip from '~Common/components/Tooltip';
import { useDebouncedWindowResize } from '~Common/hooks/useDebouncedWindowResize';
import { withTruncate } from '~Common/styles/mixins';

const styles = {
  text: css(withTruncate()),
};

interface ViewProps extends ComponentProps<'div'> {
  childTextRef: React.MutableRefObject<HTMLDivElement | null>,
  showTooltip: boolean,
  text: string,
}

const View = ({
  childTextRef,
  showTooltip,
  text,
  ...props
}: ViewProps): JSX.Element => (
  <Tooltip content={showTooltip ? text : ''}>
    <div
      css={styles.text}
      ref={childTextRef}
      {...props}
    >
      {text}
    </div>
  </Tooltip>
);

type TooltipOnTruncateProps = Omit<ViewProps, 'childTextRef' | 'showTooltip'>;

const TooltipOnTruncate = ({
  ...props
}: TooltipOnTruncateProps): JSX.Element => {
  const childTextRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const debouncedWindowWidth = useDebouncedWindowResize(250);

  useEffect(() => {
    if (childTextRef.current && childTextRef.current.offsetWidth < childTextRef.current.scrollWidth) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [debouncedWindowWidth]);

  const hookProps = {
    showTooltip,
    childTextRef,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TooltipOnTruncate;
