import { css } from '@emotion/react';
import {
  useMemo,
  useState,
  SyntheticEvent,
} from 'react';
import { Goals } from '@leadr-hr/types';
import LeadrTabs from '~Common/V3/components/LeadrTabs';
import { PARTICIPANT_TABS } from '~Goals/const/defaults';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';
import { SKELETON_STYLES } from '~Goals/const/styles';
import { Skeleton } from '@mui/material';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { ParticipantAvatar } from './ParticipantAvatar';

const styles = {
  ...SKELETON_STYLES,
  avatars: (isTablet: boolean) => css({
    marginTop: '.5rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridGap: '.5rem',
    minWidth: '22.8125rem',
  }, isTablet && {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    minWidth: '11.375rem',
  }),
  avatar: css({
    '&:not(:last-of-type)': {
      marginRight: '.25rem',
    },
  }),
  defaultMessage: css({
    color: palette.neutrals.gray700,
    fontSize: '.875rem',
    marginTop: '.5rem',
  }),
  avatarSkelly: css({
    height: '2.1875rem',
    width: '2.1875rem',
  }),
  participantsContainer: css({
    position: 'relative',
  }),
};

interface TabRecord {
    label: string,
    value: string,
    ['data-test-id']: string,
  }

interface ViewProps {
  collaborators: Goals.GoalParticipant[] | undefined,
  viewers: Goals.GoalParticipant[] | undefined,
  tabs: TabRecord[],
  activeTab: Goals.GoalParticipantRole,
  handleTabChange: (event: SyntheticEvent, newTab: Goals.GoalParticipantRole) => void,
  collaboratorsCount: number | undefined,
  viewersCount: number | undefined,
  showSkeleton: boolean,
  isTablet: boolean,
  isDrawer: boolean,
  onPersonClick: (orgUserId: string) => void,
}

const View = ({
  collaborators,
  viewers,
  tabs,
  activeTab,
  handleTabChange,
  collaboratorsCount,
  viewersCount,
  showSkeleton,
  isTablet,
  isDrawer,
  onPersonClick,
}: ViewProps): JSX.Element => (
  <>
    <div
      css={styles.participantsContainer}
    >
      <LeadrTabs
        value={activeTab}
        handleChange={handleTabChange}
      >
        {tabs.map((tab) => (
          <LeadrTabs.Tab
            label={`${tab.label}s (${tab.label === Goals.GoalParticipantRole.Collaborator ? collaboratorsCount ?? 0 : viewersCount ?? 0})`}
            value={tab.value}
            key={tab.value}
            data-test-id={tab['data-test-id']}
          />
        ))}
      </LeadrTabs>
      {showSkeleton && (
        <MultipleSkeletonLoaders
          css={styles.avatars(isTablet)}
          numberOfSkeletons={24}
          renderSkeletonItem={() => (
            <Skeleton
              variant="text"
              animation="wave"
              css={[styles.allowProperHeight, styles.avatarSkelly]}
            />
          )}
        />
      )}
      {!showSkeleton && activeTab === Goals.GoalParticipantRole.Collaborator && (
        <>
          {(!collaboratorsCount && collaboratorsCount === 0) && (
            <p css={styles.defaultMessage}>No collaborators have been added to this goal.</p>
          )}
          <ParticipantAvatar
            isTablet={isTablet}
            isDrawer={isDrawer}
            usersInfo={collaborators as PersonDisplayInformation[]}
            usersCount={collaboratorsCount ?? 0}
            numberOfUsersToShow={isDrawer ? 33 : 24}
            onPersonClick={onPersonClick}
          />
        </>
      )}
      {!showSkeleton && activeTab === Goals.GoalParticipantRole.Viewer && (
        <>
          {(!viewersCount || viewersCount === 0) && (
            <p css={styles.defaultMessage}>No viewers have been added to this goal.</p>
          )}
          <ParticipantAvatar
            isTablet={isTablet}
            isDrawer={isDrawer}
            usersInfo={viewers as PersonDisplayInformation[]}
            usersCount={viewersCount ?? 0}
            numberOfUsersToShow={isDrawer ? 22 : 24}
            onPersonClick={onPersonClick}
          />
        </>
      )}
    </div>
  </>
);

interface GoalParticipantsProps {
  collaborators: Goals.GoalParticipant[] | undefined,
  viewers: Goals.GoalParticipant[] | undefined,
  showSkeleton: boolean,
  isTablet: boolean,
  isDrawer?: boolean,
}

export const GoalParticipants = ({
  collaborators,
  viewers,
  showSkeleton,
  isTablet,
  isDrawer = false,
}: GoalParticipantsProps): JSX.Element => {
  const collaboratorsCount = collaborators?.length;
  const viewersCount = viewers?.length;

  const setDefaultTab = collaboratorsCount === 0 && viewersCount !== 0 ? Goals.GoalParticipantRole.Viewer : Goals.GoalParticipantRole.Collaborator;
  const [activeTab, setActiveTab] = useState<Goals.GoalParticipantRole>(setDefaultTab);
  const handleTabChange = (event: SyntheticEvent, newTab: Goals.GoalParticipantRole): void => {
    setActiveTab(newTab);
  };
  const tabs = useMemo(() => PARTICIPANT_TABS.map((tab) => ({
    ...tab,
  })), []);

  const dispatch = useDispatch();
  const onPersonClick = (orgUserId: string): void => {
    dispatch(
      pushDrawerAction({
        drawer: {
          ...baseballCardDrawerTemplate,
          args: {
            id: orgUserId,
          },
        },
      }),
    );
  };

  const hookProps = {
    collaborators,
    viewers,
    tabs,
    activeTab,
    handleTabChange,
    collaboratorsCount,
    viewersCount,
    showSkeleton,
    isTablet,
    isDrawer,
    onPersonClick,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
