import { connect } from 'react-redux';
import { getFlag } from '~Deprecated/selectors/featureFlags/getFlag';

import StyleCompat from '~Deprecated/ui/components/StyleCompat';

const mapStateToProps = (state) => ({
  loadNewStyles: getFlag(state, { flag: 'useNewLayout' }),
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(StyleCompat);
