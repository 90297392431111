import LeadrModal from '~Common/V3/components/LeadrModal';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { ComponentProps, useCallback, useEffect } from 'react';
import {
  ActionMenuActions, EditPostShape, InfiniteQueryParamsProps, RecognitionShape,
} from '~Recognition/const/types';
import Card from '~Common/V3/components/Card';
import { css } from '@emotion/react';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import ActionMenu, { MenuItem, useActionMenu } from '~Meetings/components/shared/ActionMenu';
import { setRecognitionMenuItems } from '~Recognition/const/functions';
import { PAGE_STYLES } from '~Recognition/const/pageStyles';
import { useDeleteRecognition } from '~Recognition/hooks/useDeleteRecognition';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useGetRecognitionById } from '~Recognition/hooks/useGetRecognitionById';
import { DEFAULT_RECOGNITION } from '~Recognition/const/defaults';
import { toast } from '~Common/components/Toasts';
import { withoutDesktopObject } from '~Common/styles/mixins';
import { useIsMobileQuery, useIsTabletQuery } from '~Common/hooks/useMediaListener';
import { useShowRecognitionModal } from '~Recognition/hooks/useShowRecognitionModal';
import CardHeader from './Cards/CardHeader';
import CardBanner from './Cards/CardBanner';
import CardBody from './Cards/CardBody';

const styles = {
  removeSpace: css({
    padding: 0,
    boxShadow: 'none',
  }),
  cardHeader: css({
    flex: 1,
  }),
  skellyHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',

    '& > *': {
      width: '15%',
    },
    '& > :first-of-type': {
      width: '30%',
    },

  }),
  leadrModal: css({
    width: '80%',
    maxWidth: '59.375rem',
  }, withoutDesktopObject({
    width: '95%',
  })),
  ...PAGE_STYLES,
};

interface ViewProps extends ComponentProps<'div'> {
  open: boolean,
  doCloseModal: () => void,
  recognitionDetails: RecognitionShape | undefined,
  menuItems: MenuItem[][],
  onClickCallback: () => void,
  actionMenuProps: {
    anchorEle: HTMLElement | null;
    onClose: () => void;
  },
  doOpen: (event: React.MouseEvent<HTMLElement>) => void,
  canDeletePost: boolean,
  canEditPost: boolean,
  hasBeenEdited: boolean,
  showSkeleton: boolean,
  infiniteQueryParams: InfiniteQueryParamsProps,
  overFlowAvatarNumber: number,
  isMobile: boolean,
  isReadOnly: boolean,
  renderBottomButton?: () => JSX.Element,
}

const View = ({
  open,
  doCloseModal,
  recognitionDetails,
  onClickCallback,
  menuItems,
  actionMenuProps,
  doOpen,
  canDeletePost,
  canEditPost,
  hasBeenEdited,
  showSkeleton,
  infiniteQueryParams,
  overFlowAvatarNumber,
  isMobile,
  isReadOnly,
  renderBottomButton,
}: ViewProps): JSX.Element => (
  <LeadrModal
    open={open}
    onClose={doCloseModal}
  >
    {showSkeleton && (
      <Stack spacing={1}>
        <div css={styles.skellyHeader}>
          <Skeleton variant="rectangular" height={32} />
          <Skeleton variant="rectangular" height={32} />
        </div>
        <Skeleton
          variant="rectangular"
          height={200}
        />
      </Stack>
    )}
    {!showSkeleton && recognitionDetails && (
      <>
        <LeadrModal.Header>
          <CardHeader
            css={styles.cardHeader}
            recipients={recognitionDetails.recipients}
            date={recognitionDetails.createdOn}
            createdBy={recognitionDetails.createdBy}
            canSeeEdit={canDeletePost || canEditPost}
            recognitionId={recognitionDetails.recognitionId}
            isMobile={isMobile}
            renderEdit={() => (
              <>
                {(canDeletePost || canEditPost) && !isReadOnly && (
                <>
                  <IconButton
                    onClick={doOpen}
                    data-test-id="recognitionActionMenu"
                    tooltip="Recognition Options"
                    type="button"
                    icon={faEllipsisV}
                    css={styles.recognitionEllipsis()}
                    size="large"
                  />
                  <ActionMenu
                    css={styles.recognitionActionMenu}
                    menuItems={menuItems}
                    onClick={onClickCallback}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    {...actionMenuProps}
                  />
                </>
                )}
              </>
            )}
          />
        </LeadrModal.Header>
        <LeadrModal.Body>
          <Card
            key={recognitionDetails.recognitionId}
            css={styles.removeSpace}
            renderContents={() => (
              <>
                <CardBanner
                  recipients={recognitionDetails.recipients}
                  createdBy={recognitionDetails.createdBy}
                    // eslint-disable-next-line no-nested-ternary
                  showOverflowAvatarNumber={overFlowAvatarNumber}
                />
                <CardBody
                  descriptionHTML={recognitionDetails.content}
                  hasBeenEdited={hasBeenEdited}
                  showExpandedView
                  recognitionId={recognitionDetails.recognitionId}
                  filters={infiniteQueryParams}
                  modifiedOn={recognitionDetails.modifiedOn}
                />
              </>
            )}
          />
        </LeadrModal.Body>
        {renderBottomButton && (
          <LeadrModal.Footer>
            {renderBottomButton()}
          </LeadrModal.Footer>
        )}
      </>
    )}
  </LeadrModal>
);

export interface modalParamsProps {
  id: string,
  name: string,
}

export interface RecognitionModalDetailsProps {
  setEditPost: (value: EditPostShape) => void,
  recognitionId: number,
  infiniteQueryParams:InfiniteQueryParamsProps,
  onCloseModal?: () => void,
  isReadOnly?: boolean,
  renderBottomButton?: () => JSX.Element,
}

const RecognitionModalDetails = ({
  setEditPost,
  recognitionId,
  infiniteQueryParams,
  onCloseModal,
  isReadOnly = false,
  ...props
}: RecognitionModalDetailsProps): JSX.Element => {
  const {
    closeModal,
    useIsModalOpen,
  } = useShowRecognitionModal();

  const doCloseModal = useCallback((): void => {
    closeModal();
    onCloseModal?.();
  }, [closeModal, onCloseModal]);

  const open = useIsModalOpen();

  const { doOpen, ...actionMenuProps } = useActionMenu();
  const recognitionDetailsReturn = useGetRecognitionById({ id: recognitionId });
  const { data: recognitionDetails, isLoading: detailsAreLoading, isError: errorLoadingDetails } = recognitionDetailsReturn;

  const onClickCallback = useCallback(() => {
    actionMenuProps.onClose();
  }, [actionMenuProps]);
  const { canDelete, canEdit } = recognitionDetails ?? DEFAULT_RECOGNITION;
  const deleteMutation = useDeleteRecognition({ infiniteQueryParams });
  const hasBeenEdited = recognitionDetails?.modifiedOn !== null;

  useEffect(() => {
    if (errorLoadingDetails) {
      toast.error('There was an error loading the recognition details', 5000);
      doCloseModal();
    }
  }, [errorLoadingDetails, doCloseModal]);

  const handleActionmenuClick = (type: ActionMenuActions, id: number): void => {
    if (type === ActionMenuActions.Edit) {
      setEditPost({ editPost: true, postId: id });
    } else if (type === ActionMenuActions.Delete) {
      deleteMutation({ recognitionId }, { onSettled: () => doCloseModal() });
    }
  };

  const menuItems:MenuItem[][] = setRecognitionMenuItems(recognitionDetails ?? DEFAULT_RECOGNITION as unknown as RecognitionShape, handleActionmenuClick);
  const [showSkeleton] = useSkeletonLoaders(detailsAreLoading);

  const isTablet = useIsTabletQuery();
  const isMobile = useIsMobileQuery();
  const getOverFlowAvatarNumber = (tabletQueryReturn: boolean, mobileQueryReturn: boolean): number => {
    if (tabletQueryReturn) {
      return 12;
    }
    if (mobileQueryReturn) {
      return 4;
    }
    return 22;
  };
  const overFlowAvatarNumber = getOverFlowAvatarNumber(isTablet, isMobile);

  const hookProps = {
    open,
    doCloseModal,
    recognitionDetails,
    onClickCallback,
    doOpen,
    menuItems,
    actionMenuProps,
    canDeletePost: canDelete ?? false,
    canEditPost: canEdit ?? false,
    hasBeenEdited,
    showSkeleton,
    infiniteQueryParams,
    overFlowAvatarNumber,
    isMobile,
    isReadOnly,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default RecognitionModalDetails;
