import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import {
  getApi, HttpCallReturn,
} from '~Deprecated/services/HttpService';
import { hosts } from '~Deprecated/services/config';
import { Coaching, MeetingsTimeline } from '~Meetings/const/meetingsInterfaces';

interface NextMeetingsQueryResponse {
  timeline: MeetingsTimeline,
}

const getNextMeetingsQuery = async ({ queryKey }: QueryFunctionContext<string[]>): Promise<HttpCallReturn<NextMeetingsQueryResponse>> => {
  const userId = queryKey[queryKey.length - 1];
  const serverUrl = {
    host: hosts.timelines,
    uri: `/organizations/${getOrganizationId() ?? ''}/timelines?page=1&count=100&filter=COACHING&relatedType=USER&relatedId=${userId}`,
  };
  return getApi<NextMeetingsQueryResponse>(serverUrl, {});
};

interface NextMeetingsParams {
  queryKey?: string[],
  otherUserId: string,
}

interface NextMeetingsReturn {
  nextMeetings: Coaching[],
  isLoading: boolean,
}

export const USE_NEXT_MEETINGS_QUERY_KEY = [getOrganizationId() ?? '', getOrganizationUserId() ?? '', 'meetings', 'upcoming'];

export const useNextMeetings = ({
  queryKey = USE_NEXT_MEETINGS_QUERY_KEY,
  otherUserId,
}: NextMeetingsParams): NextMeetingsReturn => {
  const result = useQuery({
    queryKey: [...queryKey, otherUserId],
    queryFn: getNextMeetingsQuery,
  });
  let coachings = result?.data?.response?.timeline?.COACHING ?? [];

  const nextMeetings = [];

  if (coachings?.length > 0) {
    coachings.sort((a, b) => a.startTimeInMillis - b.startTimeInMillis);
    coachings = coachings.filter((c) => moment.utc(c.startTimeInMillis).isAfter(moment()));

    if (coachings[0]) nextMeetings.push(coachings[0]);
    if (coachings[1]) nextMeetings.push(coachings[1]);
  }

  return {
    nextMeetings,
    isLoading: result.isLoading,
  };
};
