import { ContextPortal } from '~Common/V3/components/LeadrPortals';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import RecognitionList from '~Recognition/components/RecognitionList';
import RecognitionFilters from '~Recognition/components/RecognitionFilters';
import CreateRecognitionModal from '~Recognition/components/CreateRecognitionModal';
import {
  ChangeEvent,
} from 'react';
import { EditPostShape, InfiniteQueryParamsProps } from '~Recognition/const/types';
import RecognitionModalDetails from '~Recognition/components/RecognitionModalDetails';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

interface RecognitionDashboardProps {
  handleCreateModalDisplay: (value: boolean) => void,
  openCreateModal: boolean,
  searchText: string,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  infiniteQueryParams: InfiniteQueryParamsProps,
  setDepartment: (value: string) => void,
  department: string,
  userGroup: string,
  setUserGroup: (value: string) => void,
  setEditPost: (value: EditPostShape) => void,
  editPost: EditPostShape,
  filters: InfiniteQueryParamsProps,
  recognitionId?: string,
  setStartDate: (startDate: string) => void,
  startDate: string,
  setEndDate: (endDate: string) => void,
  endDate: string,
  setSearchText: (value: string) => void,
  onCloseRecognitionDetailsModal: () => void,
}

const RecognitionDashboard = ({
  handleCreateModalDisplay,
  openCreateModal,
  searchText,
  handleSearchTextChange,
  infiniteQueryParams,
  setDepartment,
  department,
  userGroup,
  setUserGroup,
  setEditPost,
  editPost,
  filters,
  recognitionId,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  setSearchText,
  onCloseRecognitionDetailsModal,
}: RecognitionDashboardProps): JSX.Element => (
  <>
    <ContextPortal
      portalId="leftSideArea"
      renderContents={() => (
        <LeadrSearchField
          defaultValue={searchText}
          data-test-id="recognitionDashboardSearch"
          onChange={handleSearchTextChange}
          debounceTime={700}
          placeholder="Search Names..."
        />
      )}
    />
    <ContextPortal
      portalId="contextButtons"
      renderContents={() => (
        <LeadrButton
          data-test-id="recognitionDashboardCreate"
          size="standard"
          variant="default"
          onClick={() => handleCreateModalDisplay(true)}
        >
          <>
            <FontAwesomeIcon
              icon={faPlus}
            />
            {' New Recognition'}
          </>
        </LeadrButton>
      )}
    />
    <RecognitionFilters
      setDepartment={setDepartment}
      department={department}
      userGroup={userGroup}
      setUserGroup={setUserGroup}
      setStartDate={setStartDate}
      startDate={startDate}
      setEndDate={setEndDate}
      endDate={endDate}

    />
    <RecognitionList
      infiniteQueryParams={infiniteQueryParams}
      setEditPost={setEditPost}
      filters={filters}
      handleModalDisplay={handleCreateModalDisplay}
      setDepartment={setDepartment}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      setSearchText={setSearchText}
      setUserGroup={setUserGroup}
    />
    <CreateRecognitionModal
      open={openCreateModal}
      handleModalDisplay={handleCreateModalDisplay}
      editPost={editPost}
      setEditPost={setEditPost}
      infiniteQueryParams={infiniteQueryParams}
    />
    {recognitionId && (
      <RecognitionModalDetails
        infiniteQueryParams={infiniteQueryParams}
        recognitionId={parseInt(recognitionId, 10)}
        setEditPost={setEditPost}
        onCloseModal={onCloseRecognitionDetailsModal}
      />
    )}
  </>
);

export default RecognitionDashboard;
