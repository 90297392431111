import { css } from '@emotion/react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { noop } from '~Deprecated/utils';
import { INPUT_REF_SHAPE } from '~Common/const/proptypes';
import DrawerInput from '~Deprecated/ui/components/Inputs/DrawerInput';
import { hexToRGBA } from '~Deprecated/ui/styles/colors';
import { palette } from '~Common/styles/colors';

const styles = css`
  input {
    cursor: pointer !important;

    &:disabled {
      // Mimics MUI disabled style.
      color: ${hexToRGBA(palette.neutrals.black, 0.38)};
    }
  }
`;

/**
 * @deprecated Old UI
 */
const PickerInput = ({
  label: inputLabel, inputRef, className, onInputClick, date, name, required, format, iconType, rightIconType, size, disabled, ...props
}) => (
  <DrawerInput
    value={date ? moment(date).format(format) : ''}
    css={styles}
    onClick={onInputClick}
    className={className}
    label={inputLabel}
    inputRef={inputRef}
    name={name}
    required={required}
    iconType={iconType}
    rightIconType={rightIconType}
    size={size}
    disabled={disabled}
    {...props}
  />
);

PickerInput.propTypes = {
  label: PropTypes.string.isRequired,
  inputRef: INPUT_REF_SHAPE,
  className: PropTypes.string,
  onInputClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]),
  format: PropTypes.string.isRequired,
  required: PropTypes.bool,
  iconType: PropTypes.string,
  rightIconType: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

PickerInput.defaultProps = {
  className: '',
  onInputClick: noop,
  date: null,
  inputRef: null,
  required: false,
  iconType: null,
  rightIconType: noop,
  size: undefined,
  disabled: false,
};

export default PickerInput;
