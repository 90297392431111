import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { UserPendingItems, USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import {
  AssignedLearning, LearningStatus, ReceivedLearning, SCRequestedLearningDetail,
} from '~Learning/const/interfaces';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { requestedLearningDrawerTemplate } from '~Learning/components/AssignedLearningDashboard/RequestedLearningDrawer';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { receivedLearningQueryKeys } from '../received/queryKeys';
import { assignedLearningQueryKeys } from './queryKeys';

interface RecallLearningParams {
  userIds: string[],
  learningId: string,
  isRecallingFromAll: boolean,
}

const recallLearning = async ({ userIds, learningId }: RecallLearningParams): Promise<HttpCallReturn<boolean>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/recall/${learningId}`;

  return patchApi<boolean>(serverUrl, { userIds });
};

export const useRecallLearning = (): UseMutateFunction<HttpCallReturn<boolean>, unknown, RecallLearningParams> => {
  const dispatch = useDispatch();
  const currentUserId = getUserId() ?? ''; // User id of the user that is signed in

  const mutation = useMutation({
    mutationFn: recallLearning,
    onMutate: async (learningToRecall: RecallLearningParams) => {
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.singleContentDetail(learningToRecall.learningId) });

      const previousReceivedLearningList = queryClient.getQueryData(receivedLearningQueryKeys.list());
      const previousAssignedLearningList = queryClient.getQueryData(assignedLearningQueryKeys.list());
      const previousUserPendingItems = queryClient.getQueryData(USER_PENDING_ITEMS_QUERY_KEY);
      const previousAssignedLearningDetail = queryClient.getQueryData(assignedLearningQueryKeys.singleContentDetail(learningToRecall.learningId));

      // Recall All or Recall Last Person individually workflow
      if (learningToRecall.isRecallingFromAll) {
        // Close all of the open drawers
        // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
        dispatch(popDrawerAction({ popAll: true }));

        // Remove the learning from the assigned learning list
        queryClient.setQueryData(
          assignedLearningQueryKeys.list(),

          (oldAssignedLearningList?: AssignedLearning[]) => oldAssignedLearningList?.filter(
            (assignedLearning) => assignedLearning.id !== learningToRecall.learningId,
          ),
        );
      } else { // Recall from individual workflow
        queryClient.setQueryData<HttpCallReturn<SCRequestedLearningDetail>>(
          assignedLearningQueryKeys.singleContentDetail(learningToRecall.learningId),
          (oldAssignedLearningDetail) => {
            if (oldAssignedLearningDetail) {
              const newAssignedLearningDetail = cloneDeep(oldAssignedLearningDetail);
              newAssignedLearningDetail.response.assignees = newAssignedLearningDetail.response.assignees.filter(
                (assignee) => !learningToRecall.userIds.includes(assignee.assignee.id),
              );
              // Handle a case where you recalled the learning from the last INCOMPLETE participant
              if (newAssignedLearningDetail.response.assignees.every((assignee) => assignee.status === LearningStatus.COMPLETED)) {
                newAssignedLearningDetail.response.status = LearningStatus.COMPLETED;
              }
              return newAssignedLearningDetail;
            }

            return oldAssignedLearningDetail;
          },
        );

        // Update the learning card in the assigned learning list
        queryClient.setQueryData<AssignedLearning[]>(
          assignedLearningQueryKeys.list(),
          (oldAssignedLearningList) => {
            if (oldAssignedLearningList) {
              const newAssignedLearningList = cloneDeep(oldAssignedLearningList);

              const indexOfAssignedLearningToUpdate = newAssignedLearningList?.findIndex(
                (assignedLearning) => assignedLearning.id === learningToRecall.learningId,
              );

              const updatedAssignedLearning = cloneDeep(newAssignedLearningList[indexOfAssignedLearningToUpdate]);
              updatedAssignedLearning.assignedUsersInfo = updatedAssignedLearning.assignedUsersInfo.filter(
                (assignedUser) => !learningToRecall.userIds.includes(assignedUser.id),
              );

              updatedAssignedLearning.numberOfUsersAssigned -= 1;

              // Handle a case where you recalled the learning from the last INCOMPLETE participant
              if (updatedAssignedLearning.numberOfUsersAssigned === updatedAssignedLearning.numberOfUsersCompleted) {
                updatedAssignedLearning.status = LearningStatus.COMPLETED;
              }

              newAssignedLearningList.splice(indexOfAssignedLearningToUpdate, 1, updatedAssignedLearning);

              return newAssignedLearningList;
            }

            return oldAssignedLearningList;
          },
        );
      }

      // If the current signed in user is in the list of userIds to recall from, remove the learning from the received learning list and decrement the pendingUserItems for learning
      if (learningToRecall.userIds.includes(currentUserId)) {
        queryClient.setQueryData<ReceivedLearning[]>(
          receivedLearningQueryKeys.list(),
          (oldReceivedLearningList) => oldReceivedLearningList?.filter(
            (receivedLearning) => receivedLearning.id !== learningToRecall.learningId,
          ),
        );

        queryClient.setQueryData<HttpCallReturn<UserPendingItems>>(USER_PENDING_ITEMS_QUERY_KEY, (oldUserPendingItems) => {
          if (oldUserPendingItems) {
            const newUserPendingItems = cloneDeep(oldUserPendingItems);

            newUserPendingItems.response.pendingLearningCount -= 1;

            return newUserPendingItems;
          }

          return oldUserPendingItems;
        });
      }

      // Return a context object with the old snapshotted values
      return {
        previousReceivedLearningList,
        previousAssignedLearningList,
        previousUserPendingItems,
        previousAssignedLearningDetail,
      };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: assignedLearningQueryKeys.list() });
      // Invalidate the learning notification dot and received learning list, since you can send a learning to yourself :)
      await queryClient.invalidateQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
    onError: (_, learningToRecall, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(receivedLearningQueryKeys.list(), snapshot?.previousReceivedLearningList);
      queryClient.setQueryData(assignedLearningQueryKeys.list(), snapshot?.previousAssignedLearningList);
      queryClient.setQueryData(USER_PENDING_ITEMS_QUERY_KEY, snapshot?.previousUserPendingItems);
      queryClient.setQueryData(assignedLearningQueryKeys.singleContentDetail(learningToRecall.learningId), snapshot?.previousAssignedLearningDetail);

      // Recall All or Recall Last person individually workflow
      if (learningToRecall.isRecallingFromAll) {
      // Reopen the assigned learning detail drawer
        dispatch(pushDrawerAction({
          drawer: {
            ...requestedLearningDrawerTemplate,
            args: {
              learningId: learningToRecall.learningId,
            },
          },
        }));
      }

      toast.error('There was an error recalling the learning. Please try again.', {
        autoClose: 1500,
      });
    },
  });

  return mutation.mutate;
};
