import { css, SerializedStyles } from '@emotion/react';
import { ButtonGroup, Popover } from '@mui/material';
import { SyntheticEvent, useState } from 'react';

import { palette } from '~Common/styles/colors';

const styles = {
  popover: css({
    '.MuiPopover-paper': {
      maxWidth: '33.9375rem',
      borderRadius: '0.5rem',
    },
  }),
  popoverContents: css({}),
  buttonGroup: css({
    border: '1px solid #CB2626', // TODO: Colors - Update this to be a brand color or some augment of a brand color
    borderRadius: '0.5rem',
    overflow: 'hidden',
  }),
  informationDelete: css({
    background: '#CB2626', // TODO: Colors - Update this to be a brand color or some augment of a brand color
    opacity: 'unset !important',
  }),
  popoverButton: css({
    margin: 0,
    borderRadius: 0,
    color: palette.neutrals.white,
    padding: '.375rem .75rem',
    fontWeight: 600,

    '&:not(:last-child)': {
      borderRight: '1px solid #CB2626', // TODO: Colors - Update this to be a brand color or some augment of a brand color
    },
  }),
  optionButton: css({
    background: palette.brand.red,
    borderColor: '#CB2626',
  }),
};

export interface RenderConfirmationButtonsParams {
  informationStyles: SerializedStyles,
  optionStyles: SerializedStyles,
  popoverButtonStyles: SerializedStyles,
}

interface DeleteConfirmationPopoverProps {
  popoverId: string | undefined,
  open: boolean,
  anchorEl: HTMLElement | null,
  closeConfirmationPopover: (event: SyntheticEvent<HTMLElement>) => void,
  renderConfirmationButtons: (params: RenderConfirmationButtonsParams) => JSX.Element,
}

/**
 * @deprecated Use new generic ConfirmationPopover component
 */
const DeleteConfirmationPopover = ({
  popoverId,
  open,
  anchorEl,
  closeConfirmationPopover,
  renderConfirmationButtons,
  ...props
}: DeleteConfirmationPopoverProps): JSX.Element => (
  <Popover
    id={popoverId}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    css={styles.popover}
    onClick={closeConfirmationPopover}
    {...props}
  >
    <div css={styles.popoverContents}>
      <ButtonGroup css={styles.buttonGroup} variant="contained" color="primary">
        {renderConfirmationButtons({
          informationStyles: styles.informationDelete,
          optionStyles: styles.optionButton,
          popoverButtonStyles: styles.popoverButton,
        })}
      </ButtonGroup>
    </div>
  </Popover>
);

export interface UseDeleteConfirmationPopoverHookReturn {
  anchorEl: HTMLElement | null,
  isOpen: boolean,
  closeConfirmationPopover: (event: SyntheticEvent<HTMLElement>) => void,
  openConfirmationPopover: (event: SyntheticEvent<HTMLElement>) => void,
  popoverId: string | undefined,
}

const useDeleteConfirmationPopover = (popoverId = 'popover'): UseDeleteConfirmationPopoverHookReturn => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openConfirmationPopover = (event: SyntheticEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeConfirmationPopover = (event: SyntheticEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const popoverIdToUse = isOpen ? popoverId : undefined;

  return {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId: popoverIdToUse,
  };
};

export { useDeleteConfirmationPopover };
export default DeleteConfirmationPopover;
