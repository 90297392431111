import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { goalKeys } from '../const/queryKeys';

interface DeleteGoalProps {
  goalId: string,
}

const deleteGoal = ({ goalId }: DeleteGoalProps): Promise<HttpCallReturn<string>> => {
  const serverUrl = {
    host: getHost(hosts.goals, '3'),
    uri: `/organizations/${getOrganizationId() ?? ''}/goals/${goalId}`,
  };

  return deleteApi<string>(serverUrl);
};

export const useDeleteGoal = (): UseMutationResult<HttpCallReturn<string>, unknown, DeleteGoalProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const mutation = useMutation({
    mutationFn: deleteGoal,

    onMutate: () => {
      toastId.current = toast.info('Deleting your goal...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error deleting your goal. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully deleted your goal.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: goalKeys.lists() });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeGoals(getOrganizationId() ?? '') });
    },
  });

  return mutation;
};
