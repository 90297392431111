import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { REFLECTIONS_DEFAULT_PAGE_SIZE } from '../../const/defaults';
import { DROPDOWN_STYLES } from '../../const/pageStyles';
import { ReflectionsItem } from '../../types';

const styles = {
  ...DROPDOWN_STYLES,
};

export interface ViewProps {
  tablePageSize: string;
  items: DropdownItem<ReflectionsItem[] | string[]>[];
  setTablePageSize: (value: string) => void;
}

const View = ({
  tablePageSize,
  items,
  setTablePageSize,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.perPageWrap}
  >
    Showing:
    <Dropdown
      css={[styles.dropdown, styles.dropdownSmall]}
      displayEmpty
      value={tablePageSize as unknown as string[]}
      renderItem={(item: DropdownItem<string[]>) => (
        <div css={styles.dropdownItemBoy}>
          { item.text }
        </div>
      )}
      onChange={(event) => setTablePageSize(event.target.value as unknown as string)}
      items={items as DropdownItem<string[]>[]}
      {...props}
    />
  </div>
);

interface ReflectionsPerPageFilterProps {
  tablePageSize: string;
  setTablePageSize: (value: string) => void;
}

const ReflectionsPerPageFilter = ({
  tablePageSize,
  setTablePageSize,
  ...props
}: ReflectionsPerPageFilterProps): JSX.Element => {
  const items = [
    {
      value: REFLECTIONS_DEFAULT_PAGE_SIZE.toLocaleString(),
      text: `${REFLECTIONS_DEFAULT_PAGE_SIZE}`,
      'data-test-id': 'pageSizeFilterItem',
    },
    {
      value: '50',
      text: '50',
      'data-test-id': 'pageSizeFilterItem',
    },
    {
      value: '75',
      text: '75',
      'data-test-id': 'pageSizeFilterItem',
    },
    {
      value: '100',
      text: '100',
      'data-test-id': 'pageSizeFilterItem',
    },
  ];

  const hookProps = {
    tablePageSize,
    items,
    setTablePageSize,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ReflectionsPerPageFilter;
