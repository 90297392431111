import { ComponentProps, useCallback } from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { Upload, UploadStatus } from '~Meetings/components/details/agenda/AttachmentsModal';
import { forMobileObject, withTruncate } from '~Common/styles/mixins';
import { extractFileExtension, fileExtensionToIcon } from '~Meetings/utils/fileExtensionToIcon';
import { AcceptedExtension } from '~Meetings/const/attachments';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';

const styles = {
  progressBar: css({
    position: 'relative',

    '&:hover': {
      '.removeButton': {
        display: 'block',
      },
      '.percentage': {
        display: 'none',
      },
    },
  }, forMobileObject({
    '.removeButton': {
      display: 'block',
    },
    '.percentage': {
      display: 'none',
    },
  })),
  progressTrack: (progress: number) => css({
    position: 'absolute',
    height: '100%',
    width: `${progress}%`,
    zIndex: -1,
    borderRadius: '0.3125rem',
    backgroundColor: progress < 100 ? transparentize(0.85, palette.brand.indigo) : palette.neutrals.gray50,
    transition: 'width 1s ease-out, color 1s ease-out',
  }),
  progressDataContainer: css({
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
  }),
  fileInfo: css({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  }),
  removeButton: css({
    display: 'none',
  }),
  fileIcon: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    marginRight: '0.25rem',
  }),
  fileName: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    fontWeight: 500,
  }, withTruncate()),
  percentage: css({
    color: palette.brand.indigo,
    fontWeight: 500,
  }),
  spinner: css({
    color: palette.brand.indigo,
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  upload: Upload,
  fileIcon: IconDefinition,
  onRemoveClick: () => void,
  showSpinner?: boolean,
}

const View = ({
  upload,
  fileIcon,
  onRemoveClick,
  showSpinner,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.progressBar}
    {...props}
  >
    <div css={styles.progressTrack(upload.progress)} />

    <div css={styles.progressDataContainer}>
      <div css={styles.fileInfo}>
        <FontAwesomeIcon
          css={styles.fileIcon}
          icon={fileIcon}
        />

        <div css={styles.fileName}>
          {upload.fileName}
        </div>
      </div>

      <div>
        {showSpinner && (
          <FontAwesomeIcon css={styles.spinner} icon={faCircleNotch} spin />
        )}
        {!showSpinner && (
          <>
            <span
              css={styles.percentage}
              className="percentage"
            >
              {`${upload.progress}%`}
            </span>

            <LeadrButton
              css={styles.removeButton}
              className="removeButton"
              color="danger"
              variant="text"
              onClick={onRemoveClick}
              data-test-id="attachmentsRemove"
            >
              {upload.status === UploadStatus.Complete ? 'Remove' : 'Cancel Upload'}
            </LeadrButton>
          </>
        )}
      </div>
    </div>
  </div>
);

interface ProgressBarProps extends ComponentProps<'div'> {
  upload: Upload
  onRemove: (uploadId: string) => void,
  showSpinner?: boolean,
}

const ProgressBar = ({
  upload,
  onRemove,
  showSpinner,
  ...props
}: ProgressBarProps): JSX.Element => {
  const { fileName = '' } = upload;
  const [, fileExtension] = extractFileExtension(fileName);
  const fileIcon = fileExtensionToIcon(fileExtension as AcceptedExtension);

  const onRemoveClick = useCallback(() => {
    onRemove(upload.uploadId);
  }, [
    onRemove,
    upload.uploadId,
  ]);

  const hookProps = {
    upload,
    fileIcon,
    onRemoveClick,
    showSpinner,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default ProgressBar;
