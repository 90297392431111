import AlgoliaCustomSearchField from '~GlobalSearch/components/SearchModal/AlgoliaCustomSearchField';

interface TopbarProps {
  initialSearchText: string,
}

const Topbar = ({ initialSearchText }: TopbarProps): JSX.Element => (
  <AlgoliaCustomSearchField
    data-test-id="fullSearchResultsPageSearchField"
    initialSearchText={initialSearchText}
  />
);

export default Topbar;
