import { css } from '@emotion/react';
import { InputBase, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaginationButton from '~Common/V3/components/Pagination/PaginationButton';
import Tooltip from '~Common/components/Tooltip';

import {
  hexToRGBA, palette, surveyChevronButtonGroupBorder,
} from '~Common/styles/colors';
import FlatButton from '~Common/V3/components/FlatButton';
import { noop } from '~Deprecated/utils';

const styles = {
  pageSelectionContainer: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  `,
  pageSelection: css`
    margin-right: 1rem;
    display: flex;
    align-items: center;
    color: ${palette.neutrals.gray500};
    .MuiSelect-icon {
      color: ${palette.brand.indigo};
    }
  `,
  pageSelect: css`
    background-color: ${hexToRGBA(palette.neutrals.gray50, 0.6)};
    padding: .125rem .5rem;
    border-radius: 0.5rem;
    margin-left: .3125rem;

    .MuiSelect-select:focus {
      background-color: ${hexToRGBA(palette.neutrals.gray50, 0.6)};
    }

  `,
  paginationButton: css`
    padding: 0.5rem .6563rem;
    color: ${palette.brand.indigo};

    &:not(:last-child) {
      border-right: 1px solid ${surveyChevronButtonGroupBorder};
    }

    &:disabled {
      cursor: not-allowed;
      color: ${palette.neutrals.gray500};
    }
  `,
  spacer: css`
    margin: 0 .4375rem;
  `,
};

const View = ({
  page,
  onPageChange,
  numberOfPages,
  onPreviousClick,
  onNextClick,
  ...props
}) => (
  <div css={styles.pageSelectionContainer} {...props}>
    <div css={styles.pageSelection}>
      <Select
        labelId="page-select"
        id="page-select"
        value={page}
        onChange={onPageChange}
        input={
          <InputBase css={styles.pageSelect} />
        }
      >
        {[...Array(parseInt(numberOfPages, 10)).keys()].map((number) => number + 1).map((number) => (
          <MenuItem key={number} value={number}>{number}</MenuItem>
        ))}
      </Select>
      <div css={styles.spacer}>of</div>
      {numberOfPages}
    </div>
    <PaginationButton
      renderPreviousButton={() => (
        <div>
          <FlatButton
            css={styles.paginationButton}
            disabled={page === 1}
            onClick={onPreviousClick}
            renderButtonContents={() => (
              <Tooltip content="Previous">
                <div><FontAwesomeIcon icon={faChevronLeft} /></div>
              </Tooltip>
            )}
          />
          <FlatButton
            css={styles.paginationButton}
            disabled={page === numberOfPages}
            onClick={onNextClick}
            renderButtonContents={() => (
              <Tooltip content="Next">
                <div><FontAwesomeIcon icon={faChevronRight} /></div>
              </Tooltip>
            )}
          />
        </div>
      )}
    />
  </div>
);

View.propTypes = {
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  numberOfPages: PropTypes.number.isRequired,
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

View.defaultProps = {
  onPageChange: noop,
  onPreviousClick: noop,
  onNextClick: noop,
};

const UserGroupsPagination = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

UserGroupsPagination.propTypes = {};

UserGroupsPagination.defaultProps = {};

export { View };
export default UserGroupsPagination;
