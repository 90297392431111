import { LightboxExternalProps, Slide } from 'yet-another-react-lightbox';
import { createUseShowThingActions } from '~Common/stores/ShowThingStore/createShowThingStore';
import { HttpCallReturn } from '~Deprecated/services/HttpService';

type SelectSlideDataFunction<T> = (data: HttpCallReturn<T>) => Slide[];

// Slides are coming from the getSlideData function
export type LightboxProps = Omit<LightboxExternalProps, 'slides'> & {
  // I tried to generic all the things, but I couldn't get it to work, leaving a single any in here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSlideData: SelectSlideDataFunction<any> | undefined
  queryKeyToWatch: readonly string[] | undefined,
};

export const useLightbox = createUseShowThingActions<
  LightboxProps,
  'openLightbox',
  'closeLightbox',
  'cleanupLightbox',
  'useGetLightboxProps',
  'useIsLightboxOpen'
>({
  prefix: 'lightbox-',
  actionNames: {
    showActionName: 'openLightbox',
    hideActionName: 'closeLightbox',
    cleanupActionName: 'cleanupLightbox',
    useGetThingPropsActionName: 'useGetLightboxProps',
    useIsThingVisibleActionName: 'useIsLightboxOpen',
  },
  defaultIdentifier: 'attachments',
});
