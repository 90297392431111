import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createEditActionItemTemplate } from '~ActionItems/components/Drawers/CreateEditActionItemDrawer';
import { ActionItemContextType } from '~ActionItems/const/interfaces';
import { stripHtml } from '~Common/utils/stripHtml';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';

interface UseConvertToActionItemParams {
  agendaItemText: string,
  huddleId: string,
  meetingType: MeetingTypeEnum,
}

interface UseConvertToActionItemReturn {
  onConvertToActionItem: () => void,
}

export function useConvertToActionItem({
  agendaItemText,
  huddleId,
  meetingType,
}: UseConvertToActionItemParams): UseConvertToActionItemReturn {
  const dispatch = useDispatch();
  const { item: meeting } = useMeetingDetails({
    id: huddleId,
    type: meetingType,
  });

  const onConvertToActionItem = useCallback(() => {
    const strippedText = stripHtml(agendaItemText);
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
        args: {
          text: strippedText,
          description: agendaItemText,
          context: {
            type: ActionItemContextType.Meeting,
            id: meeting?.factoryId,
          },
        },
      },
    }));
  }, [agendaItemText, dispatch, meeting?.factoryId]);

  return {
    onConvertToActionItem,
  };
}
