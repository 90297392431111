import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { drawerBackgroundColor } from '~Deprecated/ui/styles/colors';
import { formToPayload } from '~Deprecated/utils/reduxUtils';
import { RENDER_FUNCTION_SHAPE } from '~Common/const/proptypes';

const styles = {
  header: css`
    padding: 15px 15px 0;
    position: sticky;
    top: 0;
    background: ${drawerBackgroundColor};
    z-index: 9001;
  `,
  body: css`
    overflow-y: auto;
    padding: 0 15px 15px;
  `,
  formDrawer: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),
};

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
function DrawerLayout({
  renderHeader,
  renderBody,
  onSubmit,
  className,
}) {
  const doSubmit = (e) => {
    if (onSubmit) {
      onSubmit(e, formToPayload(e.currentTarget.elements));
    }
    e.preventDefault();
  };

  return (
    <form
      css={styles.formDrawer}
      className={className}
      onSubmit={doSubmit}
    >
      {renderHeader && (
        <div
          css={styles.header}
        >
          { renderHeader() }
        </div>
      )}
      <div
        css={styles.body}
      >
        { renderBody() }
      </div>
    </form>
  );
}

DrawerLayout.propTypes = {
  renderHeader: RENDER_FUNCTION_SHAPE,
  renderBody: RENDER_FUNCTION_SHAPE.isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
};

DrawerLayout.defaultProps = {
  onSubmit: null,
  className: '',
  renderHeader: null,
};

export default DrawerLayout;
