import { css, CSSObject, SerializedStyles } from '@emotion/react';

import {
  BREAKPOINTS,
  desktopMediaQuery,
  mobileMediaQuery,
  mobileTinyMediaQuery,
  tabletMediaQuery,
} from './variables';
import { baseShadow, palette } from './colors';

export const withSpacedOutTitleStyles = (color = palette.neutrals.gray700): SerializedStyles => css({
  fontSize: '.625rem',
  letterSpacing: '.0938rem',
  color,
});

export const withWordWrap = (): string => `
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
`;

export const withTruncate = (): string => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // required for flex items to ellipse properly
  min-width: 0;
`;

export const truncate = css`
  ${withTruncate()}
`;

export const withLineClamp = (lines: number): string => `
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;  
  overflow: hidden;
  word-break: break-word;
`;

export const lineClamp = (lines: number): SerializedStyles => css`
  ${withLineClamp(lines)}
`;

export const withBaseShadow = (): string => `
  box-shadow: 0px 6px 12px -2px ${baseShadow};
`;

/**
 * @deprecated Use {@link forMobileObject} instead
 */
export const forMobile = (styles: string): string => `
  @media ${mobileMediaQuery} {
    ${styles}
  }
`;

export const forMobileObject = (args: CSSObject): CSSObject => ({
  [`@media ${mobileMediaQuery}`]: args,
});

/**
 * Use {@link forMobileObject} unless explicitly needed
 */
export const forMobileTinyObject = (args: CSSObject): CSSObject => ({
  [`@media ${mobileTinyMediaQuery}`]: args,
});

/**
 * @deprecated Use {@link forTabletObject} instead
 */
export const forTablet = (styles: string): string => `
  @media ${tabletMediaQuery} {
    ${styles}
  }
`;

export const forTabletObject = (args: CSSObject): CSSObject => ({
  [`@media ${tabletMediaQuery}`]: args,
});

/**
 * @deprecated Use {@link forDesktopObject} instead
 */
export const forDesktop = (styles: string): string => `
  @media ${desktopMediaQuery} {
    ${styles}
  }
`;

export const forDesktopObject = (args: CSSObject): CSSObject => ({
  [`@media ${desktopMediaQuery}`]: args,
});

/**
 * @deprecated Use {@link withoutMobileObject} instead
 */
export const withoutMobile = (styles: string): string => `
  @media only screen and (min-width: ${BREAKPOINTS.MIN.TABLET}px) {
    ${styles}
  }
`;

export const withoutMobileObject = (args: CSSObject): CSSObject => ({
  [`@media only screen and (min-width: ${BREAKPOINTS.MIN.TABLET}px)`]: args,
});

export const withoutDesktopMQ = `@media only screen and (max-width: ${BREAKPOINTS.MAX.TABLET}px)`;

/**
 * @deprecated Use {@link withoutDesktopObject} instead
 */
export const withoutDesktop = (styles: string): string => `
  ${withoutDesktopMQ} {
    ${styles}
  }
`;

export const withoutDesktopObject = (args: CSSObject): CSSObject => ({
  [withoutDesktopMQ]: args,
});

export const withVisibleOnHover = (isHovering: boolean): SerializedStyles => css({
  visibility: isHovering ? 'visible' : 'hidden',
}, forMobileObject({
  visibility: 'visible',
}));

export const separator = (start = '0.5rem', end = '0.5rem', solid = false): SerializedStyles => css`
  margin-block: ${start} ${end};
  ${solid ? (
    `border-bottom: 1px solid ${palette.neutrals.gray200};`
  ) : (
    `border-bottom: 1px dashed ${palette.neutrals.gray400};`
  )}
  width: 100%;
  height: 1px;
`;

export const withButtonStripper = (): string => `
  border-radius: unset;
  border: unset;
  margin: unset;
  width: 100%;
`;

export const withHiddenInput = {
  display: 'block',
  lineHeight: 0,
  height: '.0625rem !important',
  overflow: 'hidden',
  padding: '0 !important',
  boxShadow: 'none',
  opacity: 0,
};

export const hideForPrint = (): SerializedStyles => css({
  '@media print': {
    display: 'none',
  },
});

export const withPrintStylesObject = (styles: CSSObject): SerializedStyles => css({
  '@media print': {
    ...styles,
  },
});
