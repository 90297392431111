import PropTypes from 'prop-types';

import ScoringTooltipContent from '~Deprecated/ui/containers/Common/ScoringTooltipContent';

import InsightStatusIndicator from '~Deprecated/ui/components/Insights/InsightDetails/InsightStatusIndicator';
import { SCORING_TOOLTIP_CONTENT_SHAPE } from '~Common/const/proptypes';
import Tooltip from '~Common/components/Tooltip';
import { scoringTooltipStyle } from '~Deprecated/ui/components/Insights/scoringTooltipStyle';

const OrganizationDetailMemberTooltip = ({
  tooltipContent, size, scoreValue, rowId,
}) => (
  <Tooltip
    css={scoringTooltipStyle}
    interactive
    content={(
      <ScoringTooltipContent
        id={rowId}
        {...tooltipContent}
      />
    )}
  >
    <span>
      <InsightStatusIndicator
        size={size}
        scoreValue={scoreValue}
      />
    </span>
  </Tooltip>
);

OrganizationDetailMemberTooltip.propTypes = {
  tooltipContent: SCORING_TOOLTIP_CONTENT_SHAPE.isRequired,
  size: PropTypes.number.isRequired,
  scoreValue: PropTypes.number.isRequired,
  rowId: PropTypes.string.isRequired,
};

OrganizationDetailMemberTooltip.defaultProps = {

};

export default OrganizationDetailMemberTooltip;
