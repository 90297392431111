import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { forMobileTinyObject } from '~Common/styles/mixins';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { meetingTypePickerTemplate, useGetMeetingTypeSelectionOptions } from '~Common/components/MeetingTypePicker';
import { createMeetingTemplate } from '~Meetings/components/drawers/CreateMeetingDrawer';
import { reviewUnsyncedMeetingsDrawerTemplate } from '~Meetings/components/drawers/ReviewUnsyncedMeetingsDrawer';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import { useIsReadOnlyCalendarIntegration } from '~Meetings/hooks/CalendarIntegration/useIsReadOnlyCalendarIntegration';
import MeetingsTabNavigation from './MeetingsTabNavigation';

const styles = {
  moduleTopbar: css({}),
  toggleTab: css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    width: '50%',
  })),
  leadrButton: (showCalendarButton: boolean) => css(
    forMobileTinyObject({
      width: showCalendarButton ? '50%' : '100%',
    }),
  ),
};

interface MeetingsTopBarProps {
  onCalendarEventsClick: () => void,
  onScheduleNewMeeting: () => void,
  showCalendarButton: boolean,
  canCreateMeeting: boolean,
  initialRoute: string,
}

const View = ({
  onCalendarEventsClick,
  onScheduleNewMeeting,
  showCalendarButton,
  canCreateMeeting,
  initialRoute,
}: MeetingsTopBarProps): JSX.Element => (
  <ModuleTopbar
    css={styles.moduleTopbar}
    moduleTopbarLayout="toggleTabsAndButtons"
    renderTabNavigationToggle={() => <MeetingsTabNavigation initialRoute={initialRoute} toggleTabStyles={styles.toggleTab} />}
    renderRightSection={(showCalendarButton || canCreateMeeting) ? () => (
      <>
        {showCalendarButton && (
          <LeadrButton
            onClick={onCalendarEventsClick}
            data-test-id="meetingsCalendar"
            variant="ghost"
            css={styles.leadrButton(showCalendarButton)}
          >
            Calendar
          </LeadrButton>
        )}
        {canCreateMeeting && (
          <CreateButton
            itemName="Meeting"
            onClick={onScheduleNewMeeting}
            data-test-id="meetingsCreateNew"
            css={styles.leadrButton(showCalendarButton)}
          />
        )}
      </>
    ) : undefined}
  />
);

interface MeetingTopBarProps {
  initialRoute: string,
}

const MeetingTopBar = ({ ...props }: MeetingTopBarProps): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isUpcoming = location.pathname.includes('list/upcoming');
  const isPast = location.pathname.includes('list/past');
  const {
    canUpdateCoaching,
    canUpdateTeamMeeting,
  } = useOrgUserPermissionsContext();
  const showCalendarEventList = useFeatureFlag('nylas');
  const { data: authStatus } = useGetCalendarAuthStatus();
  const { getMeetingTypeSelectionOptions } = useGetMeetingTypeSelectionOptions();
  const { isLimitedAccess: isLimitedAccessUser } = useUserPermissions();
  const isReadOnlyCalendarIntegration = useIsReadOnlyCalendarIntegration();

  const showCalendarButton = showCalendarEventList && authStatus === AUTH_STATUS.LINKED && !isLimitedAccessUser;
  const canCreateMeeting = !isReadOnlyCalendarIntegration && canUpdateCoaching && canUpdateTeamMeeting;

  const onCalendarEventsClick = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...reviewUnsyncedMeetingsDrawerTemplate,
      },
    }));
  };

  const onScheduleNewMeeting = (): void => {
    const meetingTypeSelectionOptions = getMeetingTypeSelectionOptions();
    dispatch(pushDrawerAction({
      drawer: {
        ...meetingTypePickerTemplate,
        args: {
          meetingTypeSelectionOptions,
          onOptionClickCallback: (drawerArgs: Record<string, unknown>) => {
            dispatch(pushDrawerAction({
              drawer: {
                ...createMeetingTemplate,
                args: {
                  ...drawerArgs,
                },
              },
            }));
          },
        },
      },
    }));
  };

  const hookProps = {
    isUpcoming,
    isPast,
    canUpdateCoaching,
    canUpdateTeamMeeting,
    onCalendarEventsClick,
    onScheduleNewMeeting,
    showCalendarButton,
    canCreateMeeting,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingTopBar;
