import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { selectParticipantsDrawerTemplate } from '~Common/components/Drawers/SelectParticipantsDrawer';
import { DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import { BREAKPOINTS } from '~Common/styles/variables';
import { useWindowWidth } from '~Common/hooks/useWindowWidth';
import { noop } from '~Deprecated/utils';
import { useEditGroup } from '../../../hooks/useEditGroup';
import { useDeleteGroup } from '../../../hooks/useDeleteGroup';
import { useGetGroupDetails } from '../../../hooks/useGetGroupDetails';
import Body from './Body';

export const editUserGroupDrawerTemplate = {
  name: 'EDIT_USER_GROUP_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

const EditUserGroupDrawer = ({
  popDrawer, drawerState, setDrawerState, transitionState, teamId,
}) => {
  const { group, isLoading } = useGetGroupDetails(teamId);
  const dispatch = useDispatch();
  const { windowWidth } = useWindowWidth();
  const deleteGroupMutation = useDeleteGroup();
  const editGroup = useEditGroup(teamId);

  const closeDrawerClick = () => {
    popDrawer({ popAll: true });
  };

  const selectedIds = useMemo(() => [...(drawerState.selectedAttendees || [])], [drawerState.selectedAttendees]);

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const newGroup = {
      teamId,
      name: data.title,
      description: data.description,
      members: selectedIds,
    };

    editGroup(newGroup);
  };

  const onDeleteClick = () => {
    deleteGroupMutation(teamId);
  };

  useEffect(() => {
    const isMobile = windowWidth <= BREAKPOINTS.MAX.MOBILE;
    const hasEntered = transitionState === TRANSITION_STATE.ENTERED;

    if (!isMobile && hasEntered) {
      dispatch(pushDrawerAction({
        drawer: {
          ...selectParticipantsDrawerTemplate,
          args: {
            excludeGroupIds: [teamId],
          },
        },
      }));
    }
  }, [windowWidth, dispatch, transitionState, teamId]);

  return (
    <>
      <DrawerLayout
        onSubmit={onSubmit}
        renderHeader={() => (
          <DrawerHeader
            renderCloseButton={(closeButtonStyles) => (
              <IconButton
                onClick={closeDrawerClick}
                icon={faTimes}
                css={closeButtonStyles}
                size="large"
              />
            )}
            title="Edit Group"
          />
        )}
        renderBody={() => (
          <div css={styles.drawerBody}>
            {!isLoading && (
            <Body
              group={group}
              onDeleteClick={onDeleteClick}
              closeDrawerClick={closeDrawerClick}
              selectedIds={selectedIds}
              drawerState={drawerState}
              setDrawerState={setDrawerState}
            />
            )}
          </div>
        )}
      />
    </>
  );
};

EditUserGroupDrawer.propTypes = {
  popDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  transitionState: PropTypes.oneOf(Object.values(TRANSITION_STATE)).isRequired,
  setDrawerState: PropTypes.func,
  teamId: PropTypes.string,
};

EditUserGroupDrawer.defaultProps = {
  setDrawerState: noop,
  teamId: '',
};

registerDrawer({
  templateName: editUserGroupDrawerTemplate.name,
  component: EditUserGroupDrawer,
});

export default EditUserGroupDrawer;
