import { UserGroup } from '~Common/const/userGroups';
import { getUserId } from '~Common/utils/localStorage';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';

interface UseUserPermissionsReturn {
  isAdmin: boolean,
  isExecutive: boolean,
  isMember: boolean,
  isLimitedAccess: boolean,
  hasDirects: boolean,
  hasWorkingGenius: boolean,
  isLoading: boolean,
}

export const useUserPermissions = (): UseUserPermissionsReturn => {
  const { user, isLoading } = useUserProfile(getUserId() ?? '');

  return {
    isAdmin: user?.userGroupName === UserGroup.Admin,
    isExecutive: user?.userGroupName === UserGroup.Executive,
    isMember: user?.userGroupName === UserGroup.Member,
    isLimitedAccess: user?.userGroupName === UserGroup.LimitedAccess,
    hasDirects: !!user?.hasDirects,
    hasWorkingGenius: user?.orgDetails?.enableTheTable ?? false,
    isLoading,
  };
};
