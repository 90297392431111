import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import { ReactText, useRef } from 'react';
import { getHost, hosts } from '~Deprecated/services/config';
import { teamsQueryKeys } from './queryKeys';

const deleteTeam = async (teamId: string): Promise<HttpCallReturn<unknown>> => {
  const serverUrl = {
    // Host doesn't matter, but need this format to use the V2 endpoint
    host: getHost(hosts.reviewCycles, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/teams/${teamId}`,
  };

  return deleteApi<unknown>(serverUrl);
};

export const useDeleteTeam = (): UseMutationResult<HttpCallReturn<unknown>, unknown, string> => {
  const orgId = getOrganizationId() ?? '';
  const toastId = useRef<ReactText | number | null>(null);

  // TODO: Optimistic Updates
  return useMutation({
    mutationFn: deleteTeam,
    onMutate: () => {
      toastId.current = toast.info('Deleting Team...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error deleting your team. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully deleted your team!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: teamsQueryKeys.lists(orgId) });
    },
  });
};
