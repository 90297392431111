export enum AdminTab {
  Active = 'active',
  Invited = 'invited',
  Imported = 'imported',
  Deactivated = 'deactivated',
}

export enum ActionType {
  Delete = 'delete',
  Deactivate = 'deactivate',
  Invite = 'invite',
  ResendInvite = 'resendinvite',
  Activate = 'activate',
  ImportUI = 'importUI'
}
export enum MapResultTotals {
  active = 'total',
  invited = 'invitedTotal',
  imported = 'importedTotal',
  deactivated = 'deactivatedTotal',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Invited = 'INVITED',
  Imported = 'CREATED',
  Deactivated = 'INACTIVE',
}

export const rowsPerPageOptions = [
  {
    text: '25 Rows',
    value: '25',
  },
  {
    text: '50 Rows',
    value: '50',
  },
  {
    text: '100 Rows',
    value: '100',
  },
];
