import { css } from '@emotion/react';

import { withBaseShadow } from '~Common/styles/mixins';
import { baseShadow } from '~Common/styles/colors';

export const slideFromBottom = (index) => {
  const cappedIndex = index % 100;

  return css`
  @keyframes animatebottom {
    from {
      bottom: ${-50 - (10 * cappedIndex)}px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  position: relative;
  animation-name: animatebottom;
  animation-duration: ${500 + (cappedIndex * 20)}ms;
`;
};

export const elevate = css`
  box-shadow: 0 2px 4px -2px ${baseShadow};
  transition: box-shadow 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s;

  &:hover {
    ${withBaseShadow()};
  }
`;
