import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import PersonContainer from '~Home/components/Module/People/PersonContainer';
import EmptyState from '~Home/components/common/EmptyState';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import { InsightsPeer, useInsightsPeers } from '~Home/hooks/useInsightsPeers';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { peoplePreview } from '~Home/const/HomeEmptyState';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { PEOPLE } from '~Common/const/routes';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  header: css({
    marginBottom: '0.5rem',
  }),
  personContainer: css({
    marginBottom: '0.75rem',
  }),
};

interface PeopleCardProps extends Omit<LeadrCardProps, 'children'> {
  arePeopleLoading: boolean,
  insightsPeers?: InsightsPeer[],
  cardTitle: string,
  dragHandleProps?: DraggableProvidedDragHandleProps,
  isMeetingsEnabled?: boolean,
  isGoalsEnabled?: boolean,
  isFeedbackEnabled?: boolean,
}

const View = ({
  arePeopleLoading,
  insightsPeers,
  cardTitle,
  dragHandleProps,
  isMeetingsEnabled,
  isGoalsEnabled,
  isFeedbackEnabled,
  ...props
}: PeopleCardProps):JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <ModuleHeader
      css={styles.header}
      headerText={cardTitle}
      {...dragHandleProps}
    />

    {!arePeopleLoading && insightsPeers?.length === 0 && (
      <EmptyState
        image={peoplePreview}
        renderIcon={(iconStyles: SerializedStyles) => (
          <FontAwesomeIcon
            css={iconStyles}
            icon={faUsers}
            size="5x"
          />
        )}
        renderAction={(actionStyles: SerializedStyles) => (
          <LeadrButton
            css={actionStyles}
            component={Link}
            to={PEOPLE}
            variant="text"
            data-test-id="inviteYourTeam"
          >
            Invite your team
          </LeadrButton>
        )}
        text="for development opportunities"
        altText="Invite your team for development opportunities"
      />
    )}

    {arePeopleLoading && (
      <HomeSkeleton
        numberOfSkeletons={8}
        height={3}
      />
    )}

    {!arePeopleLoading && insightsPeers?.map((insightsPeer) => (
      <PersonContainer
        css={styles.personContainer}
        key={insightsPeer?.orgUserId}
        insightsPeer={insightsPeer}
        isMeetingsDisabled={!isMeetingsEnabled}
        isGoalsDisabled={!isGoalsEnabled}
        isFeedbackDisabled={!isFeedbackEnabled}
      />
    ))}

    <ModuleFooter
      renderFooterButton={(params) => (
        <LeadrButton
          data-test-id="viewAllPeople"
          component={Link}
          to={PEOPLE}
          variant="text"
          size="small"
          {...params}
        >
          View All
        </LeadrButton>
      )}
    />
  </LeadrCard>
);

export interface HomePeopleCardProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const HomePeopleCard = ({
  dragHandleProps,
  ...props
}: HomePeopleCardProps): JSX.Element => {
  const { isLoading: isPeersLoading, data } = useInsightsPeers();
  const { orgSettings } = useOrgDetailsContext();
  const [arePeopleLoading] = useSkeletonLoaders(isPeersLoading);

  const homeSettings = useMemo(() => (orgSettings.homeSettings), [orgSettings]);
  const isMeetingsEnabled = homeSettings?.enableMeetings;
  const isGoalsEnabled = homeSettings?.enableGoals;
  const isFeedbackEnabled = homeSettings?.enableWhatsDueFeedback;

  const insightsPeers = useMemo(() => {
    const combinedList = [];
    const response = data?.response;

    if (response) {
      combinedList.push(response.currentUser);
      combinedList.push(response.manager);

      if (response.directReports?.length) {
        combinedList.push(...response.directReports);
      } else {
        combinedList.push(...response.peers);
      }
    }
    return combinedList.filter((insightsPeer) => insightsPeer.orgUserId !== undefined);
  }, [data?.response]);

  const hookProps = {
    insightsPeers,
    cardTitle: 'People',
    arePeopleLoading,
    dragHandleProps,
    isMeetingsEnabled,
    isGoalsEnabled,
    isFeedbackEnabled,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default HomePeopleCard;
