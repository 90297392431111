import { css } from '@emotion/react';
import Collapse from '@mui/material/Collapse';
import { useContext, useMemo } from 'react';
import { TemplateItemActionsContext } from '~Meetings/components/topic-suggestions/contexts/TemplateItemsActionContext';
import { CreateAgendaTopicTemplateDetailsWithLocalID, EditAgendaTopicTemplateDetailsWithLocalID } from '~Meetings/components/topic-suggestions/const/types';
import { useShowAgendaSection } from '../../stores/useShowAgendaSection';
import AgendaTopicTextEditor from '../AgendaTopic/AgendaTopicTextEditor';
import EmptyState from './EmptyState';
import { useShowItemEditor } from '../../stores/useShowItemEditor';
import DraggableAgendaTopic from '../AgendaTopic/DraggableAgendaTopic';

const styles = {
  sectionCollapseContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.375rem',
    paddingLeft: '.5rem',
  }),
};

interface ViewProps {
  isAgendaSectionExpanded: boolean,
  showNewAgendaTopicEditor: boolean,
  newTopicLocalId: string,
  showEmptyState: boolean,
  agendaTopics: CreateAgendaTopicTemplateDetailsWithLocalID[] | EditAgendaTopicTemplateDetailsWithLocalID[],
  handleSaveNewAgendaTopic: (topicText: string) => void,
  sectionLocalId: string,
}

const View = ({
  isAgendaSectionExpanded,
  showNewAgendaTopicEditor,
  newTopicLocalId,
  showEmptyState,
  agendaTopics,
  handleSaveNewAgendaTopic,
  sectionLocalId,
  ...props
}: ViewProps): JSX.Element => (
  <Collapse in={isAgendaSectionExpanded} {...props}>
    <div css={styles.sectionCollapseContainer}>
      {agendaTopics.map((agendaTopic, index) => (
        <DraggableAgendaTopic
          index={index}
          key={agendaTopic.localId}
          topicLocalId={agendaTopic.localId}
          agendaTopic={agendaTopic}
          sectionLocalId={sectionLocalId}
        />
      ))}
      {showNewAgendaTopicEditor && (
        <AgendaTopicTextEditor
          onSave={handleSaveNewAgendaTopic}
          topicLocalId={newTopicLocalId}
        />
      )}
      {showEmptyState && (
        <EmptyState
          newTopicLocalId={newTopicLocalId}
        />
      )}
    </div>
  </Collapse>
);

interface CollapseContentProps {
  sectionLocalId: string,
  agendaTopics: CreateAgendaTopicTemplateDetailsWithLocalID[] | EditAgendaTopicTemplateDetailsWithLocalID[],
  newTopicLocalId: string,
  hasMaxTopics: boolean,
}

const CollapseContent = ({
  sectionLocalId,
  agendaTopics,
  newTopicLocalId,
  hasMaxTopics,
  ...props
}: CollapseContentProps): JSX.Element => {
  const {
    getIsAgendaSectionExpanded,
  } = useShowAgendaSection((state) => ({
    getIsAgendaSectionExpanded: state.getIsAgendaSectionExpanded,
  }));

  const isAgendaSectionExpanded = getIsAgendaSectionExpanded(sectionLocalId);

  const {
    getIsItemEditorVisible,
  } = useShowItemEditor((state) => ({ getIsItemEditorVisible: state.getIsItemEditorVisible }));
  const showNewAgendaTopicEditor = getIsItemEditorVisible(newTopicLocalId);

  const showEmptyState = useMemo(() => (
    agendaTopics.length === 0 && !showNewAgendaTopicEditor && !hasMaxTopics
  ), [agendaTopics.length, showNewAgendaTopicEditor, hasMaxTopics]);

  const { addTopicToSection } = useContext(TemplateItemActionsContext);

  const handleSaveNewAgendaTopic = (topicText: string): void => {
    addTopicToSection(sectionLocalId, topicText);
  };

  const hookProps = {
    isAgendaSectionExpanded,
    showNewAgendaTopicEditor,
    newTopicLocalId,
    showEmptyState,
    agendaTopics,
    handleSaveNewAgendaTopic,
    sectionLocalId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CollapseContent;
