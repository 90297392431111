import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import moment from 'moment';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { SKELETON_STYLES } from '~Goals/const/styles';
import { Skeleton } from '@mui/material';
import { useCallback } from 'react';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { useDispatch } from 'react-redux';
import { viewGoalHistoryTemplate } from '~Goals/components/Drawers/GoalHistory';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import NewGoalStatus from '../Shared/NewGoalStatus';
import { GoalProgressBar } from '../Shared/GoalProgressBar';

const styles = {
  ...SKELETON_STYLES,
  statusContainer: (isMobile: boolean) => css({
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    borderRadius: '5px',
    padding: '10px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr .5fr',
    gap: '.625rem',
    alignItems: 'center',
  }, isMobile && {
    gridTemplateColumns: '1fr 1fr',
    '> div:nth-of-type(2)': {
      order: 3,
      gridColumn: 'span 2',
    },
  }),
  heading: css({
    color: palette.neutrals.gray700,
    fontSize: '12px',
    fontWeight: 400,
  }),
  editButton: css({
    justifySelf: 'end',
    maxHeight: '3.0625rem',
  }),
  statusSkelly: css({
    height: '1.5rem',
  }),
  fauxButtonSkelly: css({
    width: '6.25rem',
    justifySelf: 'end',
  }),
  viewGoalHistory: css({
    fontSize: '.75rem',
    fontWeight: 400,
    marginTop: '1rem',
  }),
};

interface ViewProps {
  status: Goals.GoalStatusUpdate | undefined,
  date: string,
  onUpdateStatus: () => void,
  showSkeleton: boolean,
  isMobile: boolean,
  isDrawer?: boolean,
  onHistoryClick: () => void,
  goal: Goals.Goal,
  isReadOnly: boolean,
  isOwner: boolean,
}

const View = ({
  status,
  date,
  onUpdateStatus,
  showSkeleton,
  isMobile,
  isDrawer = false,
  onHistoryClick,
  goal,
  isReadOnly,
  isOwner,
}: ViewProps): JSX.Element => (
  <>
    {showSkeleton && (
    <>
      <div
        css={styles.statusContainer(isMobile)}
      >
        <div>
          <span
            css={styles.heading}
          >
            {`Status as of ${date ?? ''}`}
          </span>
          <Skeleton
            variant="text"
            animation="wave"
            css={[styles.allowProperHeight, styles.statusSkelly]}
          />
        </div>
        <div>
          <span
            css={styles.heading}
          >
            Progress
          </span>
          <Skeleton
            variant="text"
            animation="wave"
            css={[styles.allowProperHeight, styles.statusSkelly]}
          />
        </div>
        <Skeleton
          variant="text"
          animation="wave"
          css={[styles.fauxButtonSkelly, styles.allowProperHeight]}
        />
      </div>
    </>
    )}
    {!showSkeleton && (
    <div
      css={styles.statusContainer(isMobile)}
    >
      <div>
        <span
          css={styles.heading}
        >
          {`Status as of ${date}`}
        </span>
        <NewGoalStatus
          status={status?.status as Goals.GoalStatus}
          largerDisplay
        />
      </div>
      <div>
        <span
          css={styles.heading}
        >
          Progress
        </span>
        <GoalProgressBar
          status={status}
          isMobile={isMobile}
        />
        {isDrawer && (
          <LeadrButton
            variant="text"
            size="small"
            css={styles.viewGoalHistory}
            data-test-id="viewGoalHistory"
            onClick={onHistoryClick}
          >
            View Goal History
          </LeadrButton>
        )}
      </div>
      {goal.permissions.includes(Goals.GoalPermission.CanEditGoalStatus) && !isReadOnly && (
        <LeadrButton
          variant="ghost"
          size="small"
          css={styles.editButton}
          onClick={onUpdateStatus}
          data-test-id="goalUpdateStatus"
        >
          {isOwner ? 'Update / Complete' : 'Update'}
        </LeadrButton>
      )}
    </div>
    )}
  </>
);

interface GoalDetailStatusProps {
  goal: Goals.Goal,
  status: Goals.GoalStatusUpdate | undefined,
  showSkeleton: boolean,
  isMobile: boolean,
  isDrawer?: boolean,
  openStatusUpdateModal: (goal: Goals.Goal) => void,
  isReadOnly?: boolean,
}

export const GoalDetailStatus = ({
  goal,
  status,
  showSkeleton,
  isMobile,
  isDrawer = false,
  openStatusUpdateModal,
  isReadOnly = false,
}: GoalDetailStatusProps): JSX.Element => {
  const date = moment(status?.createdInMillis).format('MMM Do YYYY');

  const onUpdateStatus = (): void => {
    openStatusUpdateModal(goal);
  };

  const owner = goal.participants?.find((participant) => participant.role === Goals.GoalParticipantRole.Owner && participant.firstName !== undefined);
  const isOwner = owner?.orgUserId === getOrganizationUserId();
  const dispatch = useDispatch();
  const onHistoryClick = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...viewGoalHistoryTemplate,
        args: {
          goal,
          isReadOnly,
        },
      },
    }));
  }, [dispatch, goal, isReadOnly]);

  const hookProps = {
    status,
    date,
    onUpdateStatus,
    showSkeleton,
    isMobile,
    isDrawer,
    onHistoryClick,
    goal,
    isReadOnly,
    isOwner,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
