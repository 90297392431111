import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Dispatch } from 'redux';
import Tooltip from '~Common/components/Tooltip';
import { palette } from '~Common/styles/colors';
import {
  IsVisibleProps,
  NavigationIndicatorData,
  NavigationItem,
} from '~Root/components/OldNavigationSidebar/types';
import { ActionButton } from '~Root/components/OldNavigationSidebar/NavigationItems/ActionButton';
import { LinkButton } from '~Root/components/OldNavigationSidebar/NavigationItems/LinkButton';
import { SHARED_STYLES } from '~Root/components/OldNavigationSidebar/NavigationItems/styles';

const styles = {
  ...SHARED_STYLES,
  subList: (isCollapsed: boolean) => css({
    backgroundColor: palette.neutrals.gray50,
  }, !isCollapsed && {
    paddingLeft: '0.75rem',
  }),
  skeleton: css({
    height: '2rem',
  }),
};

interface ViewProps {
  isCollapsed: boolean,
  dispatch: Dispatch,
  filteredSublist: NavigationItem[],
  isVisibleProps: IsVisibleProps,
  navigationIndicatorData: NavigationIndicatorData,
}

const View = ({
  dispatch,
  filteredSublist,
  isCollapsed,
  isVisibleProps,
  navigationIndicatorData,
}: ViewProps): JSX.Element => (
  <div css={[styles.navContainer, styles.subList(isCollapsed)]}>
    {filteredSublist.map((item) => (
      <Tooltip
        key={item.label}
        content={isCollapsed ? item.label : null}
        placement="right"
      >
        <div>
          {!!item.href && (
            <LinkButton
              isCollapsed={isCollapsed}
              item={item}
              navigationIndicatorData={navigationIndicatorData}
            />
          )}
          {!item.href && !item.children && (
            <ActionButton
              dispatch={dispatch}
              isCollapsed={isCollapsed}
              isVisibleProps={isVisibleProps}
              item={item}
            />
          )}
        </div>
      </Tooltip>
    ))}
  </div>
);

interface SublistProps {
  dispatch: Dispatch,
  isCollapsed: boolean,
  isVisibleProps: IsVisibleProps,
  item: NavigationItem,
  navigationIndicatorData: NavigationIndicatorData,
}

export const Sublist = ({
  dispatch,
  isCollapsed,
  isVisibleProps,
  item,
  navigationIndicatorData,
}: SublistProps): JSX.Element => {
  const filteredSublist = useMemo(() => (
    item.children?.filter((subItem) => (
      typeof subItem.isVisible === 'undefined'
      || (typeof subItem.isVisible === 'boolean' && subItem.isVisible)
      || subItem.isVisible(isVisibleProps)
    )) ?? []
  ), [item, isVisibleProps]);

  const hookProps = {
    dispatch,
    filteredSublist,
    isCollapsed,
    isVisibleProps,
    navigationIndicatorData,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
