export enum ReflectionTypeEnum {
  Unknown = 0,
  Goal = 1,
  Learning = 2,
  Feedback = 3,
  BookmarkedTopic = 4,
  Recognition = 5,
  Accomplishment = 6,
}

export enum ReflectionType {
  All = 0,
  Accomplishment = 6,
  AgendaItem = 4,
  Feedback = 3,
  Goal = 1,
  Learning = 2,
  Recognition = 5,
}

export interface ReflectionCandidate {
  description?: string,
  reflectionTypeEnum: ReflectionTypeEnum,
  subjectUid: string,
  title: string,
  isFavorite: boolean, // This is temporarily added so that ReflectionCard isn't mad, but this will all be changing when we switch endpoints
}

export interface UserDto {
  firstName: string,
  lastName: string,
  orgUserId: string,
  profileImageUrl: string,
}

export interface ReflectionAgendaItemMeeting {
  title: string,
  collaborators: UserDto[],
}

export interface ReflectionAgendaItemNote {
  createdInMillis: number,
  creator: UserDto,
  id: string,
  isPrivate: boolean,
  lastModifiedInMillis: number,
  rank: number,
  text: string,
  firstName: string,
  lastName: string,
}

export interface ReflectionAgendaItemDetail {
  text: string,
  createdInMillis: number,
  notes?: ReflectionAgendaItemNote[],
  coaching: ReflectionAgendaItemCoaching,
  creator: UserDto,
  isPrivate: boolean,
  isRecurring: boolean,
  lastModifiedInMillis: number,
  id: string,
}

interface ReflectionAgendaItemCoaching {
  meetingTitle: string,
}

export interface ReflectionFeedbackResponse {
  response: string,
  completedBy: UserDto[],
}

export interface ReflectionFeedbackQuestion {
  topic: string,
  responses?: ReflectionFeedbackResponse[],
}

export interface ReflectionFeedbackDetail {
  uid: string,
  title: string,
  introduction?: string,
  dueByDate: string,
  requestedTo: UserDto[],
  questions: ReflectionFeedbackQuestion[],
}

export interface ReflectionsUserRowsProps{
  id: number,
  ownedBy: {
    name: string,
    image: string
  },
  content: string,
  contentTypeId: number,
  dateCreated: string,
  isFavorite: boolean,
  isInActiveReview?: boolean,
}

interface MetaData {
  id: number;
  value: string;
  metadataType: MetadataType;
}
interface MetadataType {
  id: number;
  description: string;
}

export interface Reflection {
  id: number,
  orgId: string,
  owner: UserDto,
  contentId: string,
  contentTitle: string,
  contentCreatedDate: string,
  contentDueDate: string,
  contentTypeId: ReflectionType,
  isFavorite: boolean,
  createdDate: string,
  modifiedDate: string,
  isInActiveReview: boolean,
  metadata?: MetaData[],
}

export interface DirectReportReflection {
  id: number,
  orgId: string,
  owner: UserDto,
  contentId: string,
  contentTitle: string,
  contentCreatedDate: string,
  contentDueDate: string,
  contentTypeId: ReflectionType,
  isFavorite: boolean,
  createdDate: string,
  modifiedDate: string,
  isInActiveReview: boolean,
}

export enum DashboardTab {
  Member = 'My Reflections',
  Manager = 'Direct Report Reflections',
}

export enum ReflectionsFavoriteType {
  All = 'All Reflections',
  Favorited = 'Favorited',
  NotFavorited = 'Not Favorited',
}
export enum ReflectionsIsActiveType {
  All = 'All Reviews',
  Active = 'Active Review',
  NotActive = 'Not Active',
}

export type ReflectionsDirectReports = Record<string, string>;

export interface PersonDisplayInformation {
  profileImageUrl: string,
  firstName: string,
  lastName: string,
  orgUserId: string,
}
export interface ReflectionsItem {
    value: string;
    text: string;
    'data-test-id': string;
}
