import { css, keyframes } from '@emotion/react';
import {
  palette, userGroupsCard, requiredCircle, hexToRGBA,
} from '~Common/styles/colors';
import { forMobile } from '~Common/styles/mixins';
import { withTruncate } from '~Deprecated/ui/styles/mixins';
import {
  topicItemBg, topicItemColor,
} from '~Deprecated/ui/styles/colors';

const collapse = keyframes`
from, 1%, to {
  right: -13.5rem;
}
50% {
  right: -6rem;
},
100% {
  right: -13.5rem;
}
`;

export const PAGE_STYLES = {
  baseCard: (removeCardStyles: boolean) => css({
    marginTop: '1.25rem',
  }, removeCardStyles && {
    boxShadow: 'unset',
    padding: '0',
    margin: '0',
  }),
  container: css({
    width: '100%',
    margin: '2rem 1.875rem 0 1.875rem',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '75rem',
  }),
  topBarBackTextButton: css({
    whiteSpace: 'nowrap',
    fontSize: '1rem',
    marginRight: '0.5rem',
  }),
  description: css({
    marginTop: '.625rem',
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  }, forMobile(`
    flex-direction: column;
  `)),
  information: css({
    maxWidth: '60%',
  }),
  pageHeader: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }, forMobile(`
    flex-direction: column !important;
  `)),
  titleText: css({
    fontSize: '1.5rem',
    color: userGroupsCard.brandTextColor,
  }),
  viewCard: css({
    borderRadius: '0.5rem',
  }),
  dropdown: css({
    fontSize: '0.75rem',
    marginLeft: '-0.5rem',
    padding: '0.25rem',
    paddingLeft: '0.75rem',
    width: 'auto',

    '.MuiInput-root': {
      fontSize: '0.75rem',
      marginTop: 0,
    },
  }),
  horizontalDivider: css({
    backgroundColor: palette.neutrals.gray200,
    height: '2px',
    marginTop: '-0.125rem',
  }),
  tabContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }, forMobile(`
    flex-direction: column-reverse;
  `)),
  tableControls: css({
    alignItems: 'center',
    color: palette.neutrals.gray500,
    columnGap: '1.25rem',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.75rem',
  }),
  tabs: css({
    '& .MuiTab-root': {
      minWidth: 'unset',
      paddingBottom: '1.5rem',
      paddingLeft: '0',
      paddingRight: '0',
      textTransform: 'unset',
    },
    '& .MuiTabs-flexContainer': {
      columnGap: '1.875rem',
      rowGap: '0.5rem',
    },
  }),
  adminWrapperButton: css({
    marginLeft: '.875rem',
    padding: '.6875rem 1.25rem',
  }, forMobile(`
    margin: 0 .875rem .875rem 0;
`)),
  filtersWrapper: css({
    display: 'flex',
  }),
  noSearchResultsImage: css({
    maxHeight: '23.4375rem',
  }),
  viewFiltersButton: css({
    fontSize: '1.125rem',
    color: palette.brand.indigo,
  }),
  emptySearchOrFilters: css({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: palette.neutrals.gray600,
    padding: '2rem 0',
  }),
  filterButton: css({
    borderColor: palette.neutrals.gray300,
    borderRadius: '5px',
    color: palette.brand.indigo,
    marginLeft: '1.5rem',

    '&:disabled': {
      opacity: '.3',
      backgroundColor: palette.neutrals.gray300,
      color: palette.neutrals.gray400,
    },
  }),
  buttonLoadingContainer: css({
    alignItems: 'center',
    columnGap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '5.625rem',
  }),
  cardSkeletonDashboard: css({
    height: '80vh !important',
    maxHeight: '80vh !important',
  }),
  listSection: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    padding: '1rem 0',
  }),
  listSectionSkeletonWidth: css({
    width: '95%',
  }),
  listSectionSkeleton: css({
    margin: '2rem 0',
  }),

};

export const PAGE_STYLES_OVERRIDE = {
  icon: css({
    marginRight: '0.5rem',
  }),
  topBar: css({
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    columnGap: '0.5rem',
  }, forMobile(`
          grid-template-columns: auto;
          row-gap: 0.5rem;
        `)),
  topBarSkellyLoader: css({
    width: '100%',

    '& span': {
      maxHeight: '2.8125rem',
      height: '2.8125rem !important',
    },
  }),
  leftSide: (isCentered: boolean) => css({
    alignItems: 'center',
    display: 'flex',
    maxHeight: '2.8125rem',
    width: '75%',
  }, isCentered && {
    justifyContent: 'space-between',
  }),
  buttonsContainer: css({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifySelf: 'end',
  }, forMobile(`
          justify-self: start;
        `)),
  layoutToggle: css({
    minHeight: '2.8125rem',
  }),
  filterButton: (showFiltersActiveState: boolean) => css({
    padding: '.75rem 1rem',
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    borderRadius: '.5rem',
    marginLeft: '1.5rem',
    marginRight: '0.5rem',
    color: palette.neutrals.gray400,
    fontSize: '1.125rem',

    '&:hover': ({
      color: palette.brand.indigo,
      background: palette.neutrals.white,
      '& svg': {
        color: palette.brand.indigo,
      },
    }),
  }, showFiltersActiveState && ({
    color: palette.brand.indigo,
    background: palette.neutrals.white,
    '& svg': {
      color: palette.brand.indigo,
    },
  })),
};

export const BUTTON_STYLES = {
  outlineButton: css({
    marginRight: '.625rem',
  }),
  outlineButtonTransparent: css({
    background: 'transparent',
  }),
  addQuestionButton: css({
    marginTop: '1rem',
  }),
  textBackButton: css({
    fontSize: '1rem',
  }),
};
export const REVIEW_SETUP_LAYOUT = {
  setupMainContainer: css({
    width: '100%',
    marginTop: '1.875rem',
    marginBottom: '1.5rem',
    maxWidth: '75rem',
    alignSelf: 'center',
  }),
  boxShadow: css({
    boxShadow: '0px 4px 8px -2px rgba(28, 42, 55, 0.2);',
  }),
  overflowAttendees: css({
    overflowY: 'auto',
    maxHeight: '50rem',
    overflowX: 'hidden',
  }),
  setupStepsContainer: css({
    background: palette.neutrals.white,
    padding: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: '8px',
  }),
  cycleHeaderSkelly: css({
    marginTop: '1.125rem !important',
  }),
  setupContainer: css({
    marginTop: '1.125rem',
    padding: '1.5rem',
    background: palette.neutrals.white,
    borderRadius: '8px',
  }),
  floatingContainer: css({
    position: 'fixed',
    background: palette.neutrals.white,
    padding: ' .625rem 0 .625rem 1rem',
    transform: 'translate(-50%, -50%)',
    maxWidth: '250px',
    top: '50%',
    right: '-13.5rem',
    transition: 'all 1s ease',
    border: `1px solid ${palette.neutrals.gray200}`,
    borderRadius: '.3125rem',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.07)',
    animation: `${collapse} 4s ease`,
    '& > div': {
      maxWidth: '9.8125rem',
      width: '9.8125rem',
    },
    '&:hover': {
      right: '-6rem',
    },
  }),
  sectionHeader: css({
    fontSize: '1.188rem',
    lineHeight: '1.5rem',
    marginBottom: '1rem',
  }),
};

export const FORM_STYLES = {
  inputField: css({
    marginTop: '.5rem',
    fontSize: '1rem',
    color: palette.neutrals.gray900,
    borderRadius: '.5rem',
    border: 'none',
    position: 'relative',
    width: '49%',

    '& input': {
      fontWeight: '600 !important',
      fontSize: '.875rem',
      minHeight: 'auto',
      lineHeight: '1.5rem',
    },
    '& textarea': {
      fontSize: '.875rem',
    },
    '& label': {
      color: palette.neutrals.gray700,
      width: '100%',
      borderRadius: '.5rem',
      fontWeight: '400',
    },
  }),
  hideFauxInput: css({
    display: 'none',
  }),
  descriptionField: css({
    '& input': {
      minHeight: '5rem',
      paddingTop: '0 !important',
    },
  }),
  removeMargin: css({
    margin: '0',
  }),
  checkboxWrap: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: '.625rem',

    '& label': {
      margin: '0 .5rem 0 0',
    },
  }),
  checkboxLabel: css({
    fontSize: '1rem',
    color: palette.neutrals.gray800,
    fontWeight: '500',
  }),
  checkbox: css({
    marginLeft: '.5rem',
  }),
  checkboxRevieweSelection: css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '2.5rem',
    marginBottom: '4.25rem',
    gap: '.5rem',

    '& .MuiFormControlLabel-root': {
      marginBottom: '0',
      marginLeft: '-.1875rem',
    },
  }),
  icon: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '-0.9375rem',
    pointerEvents: 'none',
    position: 'absolute',
    right: '0',
  }),
  neutralIcon: css({
    fontSize: '1.25rem',
    color: palette.neutrals.gray400,
  }),
  requiredIcon: css({
    fontSize: '.3125rem',

    '&.fa-circle': {
      color: requiredCircle,
    },
  }),
  formControl: css({
    width: '100%',

    // '.MuiFormGroup-root': {
    //   justifyContent: 'space-between',
    // },
  }),
  radioLabels: css({
    width: '49.5%',
    border: `2px solid ${palette.neutrals.gray100}`,
    borderRadius: '.5rem',
    padding: '1rem',
    margin: '0',
    background: hexToRGBA(palette.neutrals.gray50, 0.6),
  }),
  '.MuiFormControlLabel-label': {

    '& span': {
      fontSize: '1.5rem',
      display: 'block',
    },
  },
  labelContainer: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray100,
  }),
  labelTitle: css({
    textTransform: 'uppercase',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
    marginTop: '.875rem',
    width: '100%',
    fontWeight: '400',
    lineHeight: '1.5rem',
    fontFamily: 'ProximaNova !important',
    letterSpacing: '.2rem',
  }),
  labelText: css({
    marginRight: '.5rem',
    textTransform: 'none',
    marginTop: '.875rem',
  }),
  columnWrap: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }),
  description: css({
    width: '100%',
    color: palette.neutrals.gray500,
    fontSize: '.875rem',
    margin: '.875rem 0',
  }),
  datePickerLabel: css({
    fontWeight: '400',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
    marginBottom: '-0.65rem',
    paddingTop: '.25rem',

    '& svg': {
      marginTop: '0.5rem',
      fontSize: '1.125rem',
    },
    '& input': {
      marginTop: '-2rem !important',
    },
  }),
  datePicker: css({
    '& input': {
      marginTop: '-1rem',
      paddingLeft: '1.5rem',
    },
  }),
  fullInputField: css({
    width: '100%',

    '& label': {
      width: '150%',
    },
  }),
  froalaDescriptionField: css({
    width: '100%',
    marginTop: '.5rem',
  }),
  radioButton: css`
    justify-content: space-between;
    width: 100%;

    & > div{
      border:2px solid ${palette.neutrals.gray100}; 
      border-radius: .5rem;
      background: rgba(245, 245, 247, 0.6);
      padding-right: .5625rem;
      margin-right:.5rem;
      padding-top:.5rem;
      width: 32%;

      &[data-checked]{
        background:#fff;
      }
      & label{
        color: ${palette.neutrals.gray800};
        font-size: .875rem;

        &:hover{
          cursor: pointer;
        }
      }
      ${forMobile(`
        margin-bottom:.5rem;
        width: calc(50% - .25rem);
        margin-right: 0;
      `)}
    }
  `,
  spacer: css({
    marginRight: '.5rem',
  }),
  attendeeList: css({
    maxHeight: '50rem',
  }),
};
export const CENTER_CONTENTS = {
  centeredContent: css({}),
  stepper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  fullWidth: css({
    maxWidth: '100%',
  }),
};

export const REVIEW_DISPLAY_STYLES = {
  reviewDetailsWrap: css({
    display: 'flex',
    justifyContent: 'space-between',
    pageBreakBefore: 'avoid',
    pageBreakAfter: 'avoid',

    '& > div': {
      minWidth: '15%',
      marginRight: '0 !important',
      marginTop: '0',
    },
  }),
  reviewBaseDetails: css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '50%',
  }),
  ReviewTitle: css({
    fontSize: '2rem',
    lineHeight: '2rem',
    color: palette.brand.indigo,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  }),
  reviewDescription: css({
    fontWeight: 400,
    color: palette.neutrals.gray700,
    margin: '.5rem 0 0 0',

    '& button': {
      border: 'none',
      background: 'none',
      color: palette.brand.indigo,
      fontWeight: 500,
      padding: '0',
      float: 'right',
    },
    '& .readonlyInputStyles': {
      background: 'none !important',
    },
  }),
  signoffDescription: css({
    color: palette.neutrals.gray700,
    fontWeight: 400,
    fontSize: '1rem',
  }),
  reviewResponseAvatarWrapper: css({
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '.625rem',
  }),
  avatarIcon: (isCondensed: boolean) => css({
    marginRight: '1.0625rem',
  }, isCondensed && {
    marginRight: '.5rem',
  }),
  revieweeAvatarAssets: css({
    '& span': {
      display: 'block',
    },
  }),
  revieweeAvatarAssetsName: (isCondensed: boolean) => css({
    fontWeight: '600',
    fontSize: '1.5rem',
    color: palette.neutrals.gray900,
    lineHeight: '1.8125rem',
  }, isCondensed && {
    fontSize: '.875rem',
    lineHeight: '1.0625rem',
  }),
  revieweeAvatarAssetsTitle: css({
    color: palette.neutrals.gray500,
    fontSize: '.875rem',
    lineHeight: '1.0625rem',
  }),
  cycleEllipsis: css({
    color: palette.neutrals.gray500,
    marginLeft: '.5rem',
    marginTop: '-6px !important',

    '& svg': {
      border: `1px solid ${palette.neutrals.gray200}`,
      borderRadius: '.1875rem',
      color: palette.neutrals.gray500,
    },
  }),
  cycleActionMenu: (hasExportCSV: boolean, hasDeleteCycle: boolean) => css({
    '& .MuiMenu-list > div li': {
      fontWeight: '500',
    },
    '& .MuiMenu-list > div li:last-of-type': {
      color: palette.brand.red,
      position: 'relative',

      '& svg': {
        color: palette.brand.red,
      },
      '&:before': {
        borderTop: `1px solid ${palette.neutrals.gray200}`,
        width: '80%',
        display: 'block',
        height: '2px',
        content: '""',
        position: 'absolute',
        top: '0',
        transform: 'translate(0, -50%)',
      },
    },
  }, hasExportCSV && {
    '& .MuiMenu-list > div li:last-of-type': {
      paddingTop: '0',
      '&:before': {
        borderTop: 'none',
      },
    },
  }, hasExportCSV && hasDeleteCycle && {
    '& .MuiMenu-list > div li:nth-last-child(2)': {
      marginTop: '.625rem',
      marginBottom: '-.625rem',
      paddingBottom: '0',
      '&:before': {
        borderTop: `1px solid ${palette.neutrals.gray200}`,
        width: '80%',
        display: 'block',
        height: '2px',
        content: '""',
        position: 'absolute',
        top: '0',
        transform: 'translate(0, -50%)',
      },
    },
  }, hasExportCSV && !hasDeleteCycle && {
    '& .MuiMenu-list > div li:last-of-type': {
      color: palette.neutrals.gray900,
      '& svg': {
        color: palette.brand.indigo,
      },
    },
  }),
  cycleDetails: (viewCreateCycle: boolean) => css({
    display: 'flex',
    marginTop: '1.875rem',
    flexDirection: 'row',
    width: '100%',

    '& div': {
      flexDirection: 'column',
      flexWrap: 'wrap',
      display: 'flex',
    },
  }, viewCreateCycle && {
    flexDirection: 'column',
    width: '40%',
    maxWidth: '25rem',
    '& div': {
      flexDirection: 'row',

      '& span:first-of-type': {
        marginRight: '1rem',
        width: '50%',
        textAlign: 'right',
      },
    },
  }, !viewCreateCycle && {
    '& div': {
      marginRight: '3.4375rem',
    },
  }),
  reviewDetails: css({
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '1.875rem',
    flexDirection: 'row',
    width: '100%',

    '& div': {
      flexDirection: 'column',
      flexWrap: 'wrap',
      display: 'flex',
      marginRight: '2.625rem',
    },
  }),
  cycleDetailsTitle: css({
    color: palette.brand.indigo,
    fontWeight: 600,
  }),
  sectionTitle: css({
    color: palette.brand.indigo,
    fontWeight: 600,
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
    pageBreakAfter: 'avoid',
    pageBreakBefore: 'avoid',
  }),
  draggableWrapper: css({
    position: 'relative',
    marginBottom: '.5rem',

    '&:hover, &:focus': {
      boxShadow: '0px 4px 8px 2px #1C2A3733 !important',
      borderRadius: '.75rem',
      background: palette.neutrals.white,
    },
  }),
  dragHandle: css({
    position: 'absolute',
    top: '50%',
    left: '-3.5rem',
    transform: 'translate(-50%, -50%)',

    '& svg': {
      color: palette.neutrals.gray700,
    },
  }),
  questionWrap: (isQuestions: boolean) => css({
    padding: '1rem !important',
    borderBottom: `1px solid ${palette.neutrals.gray400}`,
    '&:first-of-type': {
      paddingTop: '0',
    },
    '&:last-of-type': {
      paddingTop: '0',
    },
    '&:hover svg': {
      display: 'inline-block',
    },
  }, isQuestions && {
    paddingBottom: 0,
    paddingTop: 0,
    position: 'relative',
    border: 'none',
  }, !isQuestions && {
    paddingTop: '4.375rem',
    paddingBottom: '4.375rem',
    position: 'static',
  }),
  questionWrapBorder: (isLastQuestion: boolean) => css({
  }, isLastQuestion && {
    border: 'none',
  }),
  answerTitle: css({
    color: palette.neutrals.gray900,
    fontSize: '1rem',
    fontWeight: '500',
    width: 'calc(100% - 50px)',
    pageBreakBefore: 'avoid',
    pageBreakAfter: 'avoid',
  }),
  pageBreak: (isSafari: boolean) => css({
  }, isSafari && ({
    pageBreakBefore: 'always',
    pageBreakAfter: 'always',
    breakAfter: 'page',
  })),
  answerDescription: css({
    color: palette.neutrals.gray700,
    fontSize: '.875rem',
    fontWeight: '300',
    marginBottom: '1.25rem',
    pageBreakBefore: 'avoid',
    pageBreakAfter: 'avoid',
  }),
  modifyQuestionWrapper: css({
    position: 'absolute',
    top: '1rem',
    right: '0',
    maxWidth: '5.125rem',
    display: 'flex',

    '& svg': {
      display: 'none',
    },
  }),
  assignmentDisplay: css({
    color: palette.neutrals.gray600,
    fontWeight: 440,
    textAlign: 'right',
    fontSize: '.75rem',
    marginBottom: '1.5rem',

    '& span': {
      marginRight: '.5rem',
    },
    '& span:after': {
      content: '","',
    },
    '& span:first-of-type': {
      marginLeft: '.5rem',
    },
    '& span:first-of-type:before': {
      content: '"Respondents:"',
      marginRight: '.5rem',
    },
    '& span:last-child:after': {
      content: '""',
    },
  }),
  reflectionWrapper: css({
    marginTop: '1rem',
    columnGap: '1rem',
    rowGap: '0.5rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  }),
};
export const MULTIPLE_CHOICE = {
  box: css({
    backgroundColor: topicItemBg,
    borderRadius: '.5rem',
    color: topicItemColor,
    display: 'flex',
    overflow: 'hidden',
    lineHeight: '1.65em',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: '.75rem 0 .75rem 1rem',
  }),
  disabled: css({
    cursor: 'unset',
  }),
  dragIcon: css({
    marginRight: '1.25rem',
    color: palette.neutrals.gray700,
  }),
  surveyQuestionDetails: css({
    display: 'flex',
    flexDirection: 'column',
  }, withTruncate()),
  textBox: css({}, withTruncate()),
  container: css({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  }),
  editIcon: css({
    padding: '0 .5rem',
    color: palette.neutrals.gray700,

    '& svg': {
      fontSize: '1rem',
      width: '1rem !important',
      height: '1rem !important',
    },
  }),
  editOption: css({
    paddingRight: '0',
  }),
  rightIconsContainer: css({
    display: 'flex',
    flexShrink: '0',
  }),
  removeIcon: css({
    color: palette.neutrals.gray700,
  }),
};
