import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { palette } from '~Common/styles/colors';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { forMobileObject } from '~Common/styles/mixins';
import { TopicShape } from '~Reviews/V2/Const/types';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { ReviewTemplateSummaryCard } from './ReviewTemplateSummaryCard';
import { editReviewTemplatesTemplate } from '../EditTemplateDrawer';
import { ReviewTemplate, useDeleteReviewTemplate } from '../useReviewTemplates';
import { ReviewTemplateQuestionPreview } from '../ReviewTemplateQuestionPreview';
import { AddAllTopicsButton } from './AddAllTopicsButton';

export const reviewTemplateDetailsTemplate = {
  name: 'REVIEW_TEMPLATES_DETAILS',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  button: css({
    div: {
      justifyContent: 'center',
    },
  }, forMobileObject({
    width: '100%',
  })),
  buttonContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75rem',
    padding: '0.5rem 1.75rem 0.5rem 1rem',
  }),
  cushion: css({
    flex: 1,
  }, forMobileObject({
    display: 'none',
  })),
  drawerBody: css({
    padding: '1rem',
    paddingRight: '1.5rem',
  }),
  heading: css({
    color: palette.neutrals.gray500,
    fontSize: '0.625rem',
    letterSpacing: '0.2em',
    marginBottom: '1.25rem',
    marginTop: '1.75rem',
    paddingTop: '0.6rem',
    textTransform: 'uppercase',
  }),
  questionList: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
  }),
};

interface ViewProps extends ReviewTemplateDetailsDrawerProps {
  canDeleteTemplate: boolean,
  closeDrawerClick: () => void,
  createdBy: string,
  isTemplateDeleting: boolean,
  onClickDelete: () => void,
  onClickEdit: () => void,
  reviewCycleUid?: string,
}

export const View = ({
  canDeleteTemplate,
  closeDrawerClick,
  createdBy,
  isTemplateDeleting,
  onClickDelete,
  onClickEdit,
  reviewCycleUid,
  template,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title="Review Template"
        renderCloseButton={(closeButtonStyles) => (
          <IconButton
            css={closeButtonStyles}
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            tooltip="Close"
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <ReviewTemplateSummaryCard
          template={template}
          createdBy={createdBy}
        />
        <p css={styles.heading}>Questions</p>
        <div css={styles.questionList}>
          {template.topics.map((topic) => (
            <ReviewTemplateQuestionPreview
              key={topic.uid}
              topic={topic}
              reviewCycleUid={reviewCycleUid}
            />
          ))}
        </div>
      </div>
    )}
    renderFooter={() => (
      <div css={styles.buttonContainer}>
        {reviewCycleUid && (
          <AddAllTopicsButton
            reviewCycleUid={reviewCycleUid}
            topics={template.topics as TopicShape[]}
            template={template}
          />
        )}
        <div css={styles.cushion} />
        {canDeleteTemplate && (
          <>
            <LeadrButton
              css={styles.button}
              variant="ghost"
              onClick={onClickEdit}
              data-test-id="reviewsEditTemplate"
            >
              Edit
            </LeadrButton>
            <DeleteButtonWithConfirmation
              renderDeleteButton={({ onClick }) => (
                <SpinnerButton
                  css={styles.button}
                  variant="outline"
                  color="danger"
                  label="Delete"
                  isLoading={isTemplateDeleting}
                  loadingLabel="Deleting"
                  onClick={onClick}
                />
              )}
              renderConfirmationButtons={({
                informationStyles,
                optionStyles,
                popoverButtonStyles,
              }) => (
                <DeleteConfirmationButtons
                  onDelete={onClickDelete}
                  informationStyles={informationStyles}
                  optionStyles={optionStyles}
                  popoverButtonStyles={popoverButtonStyles}
                />
              )}
            />
          </>
        )}
      </div>
    )}
  />
);

interface ReviewTemplateDetailsDrawerProps {
  template: ReviewTemplate,
  reviewCycleUid?: string, // Did we invoke this drawer from a create/edit cycle view?
}

export const ReviewTemplateDetailsDrawer = ({
  template,
  reviewCycleUid,
  ...props
}: ReviewTemplateDetailsDrawerProps): JSX.Element => {
  const dispatch = useDispatch();
  const organizationId = getOrganizationId() ?? '';
  const orgUserId = getOrganizationUserId() ?? '';
  const { isAdmin } = useUserPermissions();

  const canDeleteTemplate = template.organizationUid === organizationId && (isAdmin || orgUserId === template.createdBy?.orgUserId);

  const { mutate: deleteTemplateMutation, isPending: isTemplateDeleting } = useDeleteReviewTemplate();

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ drawerName: reviewTemplateDetailsTemplate.name }));
  };

  const onClickEdit = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...editReviewTemplatesTemplate,
        args: {
          initialTemplate: template,
          isEditing: true,
        },
      },
    }));
  };

  const onClickDelete = (): void => {
    deleteTemplateMutation(template.uid, {
      onSuccess: () => {
        // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
        dispatch(popDrawerAction({ drawerName: reviewTemplateDetailsTemplate.name }));
      },
    });
  };

  const firstName = template?.createdBy?.firstName ?? 'Leadr';
  const lastName = template?.createdBy?.lastName ?? 'User';

  const hookProps = {
    canDeleteTemplate,
    closeDrawerClick,
    createdBy: template.organizationUid ? `${firstName} ${lastName}` : 'Leadr',
    isTemplateDeleting,
    onClickDelete,
    onClickEdit,
    reviewCycleUid,
    template,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: reviewTemplateDetailsTemplate.name,
  component: ReviewTemplateDetailsDrawer,
});
