import { useEffect } from 'react';
import { ViewPerspective } from '~Insights/const/types';
import { useInsightPrivileges } from '~Insights/hooks/useInsightPrivileges';
import { UserPerspective, useInsightsUserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';

interface UseToggleMenuOptions {
  dashboardToggleMenuOptions: LeadrToggleTextTabProps[],
  expandedToggleMenuOptions: LeadrToggleTextTabProps[],
  toggleMenuValue: ViewPerspective,
  setToggleMenuValue: (val: ViewPerspective) => void,
}

export const managerToggleMenuOptions = (): LeadrToggleTextTabProps[] => [
  {
    text: 'Direct Reports',
    value: ViewPerspective.DirectReports,
    'data-test-id': 'insightsManagerDirectReports',
  },
];

export const executiveDashboardToggleMenuOptions = (): LeadrToggleTextTabProps[] => [
  {
    text: 'Your Org',
    value: ViewPerspective.Company,
    'data-test-id': 'insightsExecutiveDashboardCompany',
  },
  {
    text: 'Departments',
    value: ViewPerspective.Departments,
    'data-test-id': 'insightsExecutiveDashboardDepartments',
  },
];

export const executiveExpandedToggleMenuOptions = (): LeadrToggleTextTabProps[] => [
  {
    text: 'Your Org',
    value: ViewPerspective.Company,
    'data-test-id': 'insightsExecutiveExpandedCompany',
  },
  {
    text: 'Manager Direct Reports',
    value: ViewPerspective.ManagerReports,
    'data-test-id': 'insightsExecutiveExpandedManagerReports',
  },
  {
    text: 'Departments',
    value: ViewPerspective.Departments,
    'data-test-id': 'insightsExecutiveExpandedDepartments',
  },
  {
    text: 'Teams',
    value: ViewPerspective.UserGroups,
    'data-test-id': 'insightsExecutiveExpandedUserGroups',
  },
];

export function useToggleMenuOptions(paramName: string): UseToggleMenuOptions {
  const { isExecutive, isAdmin } = useInsightPrivileges();
  const { userPerspective } = useInsightsUserPerspective();

  const [toggleMenuValue, setToggleMenuValue] = useQueryParamState<ViewPerspective>(
    'insights',
    paramName,
    userPerspective === UserPerspective.manager ? ViewPerspective.DirectReports : ViewPerspective.Company,
  );

  useEffect(() => {
    if (userPerspective === UserPerspective.manager && toggleMenuValue !== ViewPerspective.DirectReports) {
      setToggleMenuValue(ViewPerspective.DirectReports);
    }

    if (userPerspective === UserPerspective.executive
      && toggleMenuValue === ViewPerspective.DirectReports
    ) {
      setToggleMenuValue(ViewPerspective.Company);
    }
  // Better UX to reset to the 'default' when changing Manager / Executive views.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPerspective]);

  if (userPerspective === UserPerspective.executive && (isExecutive || isAdmin)) {
    return {
      dashboardToggleMenuOptions: executiveDashboardToggleMenuOptions(),
      expandedToggleMenuOptions: executiveExpandedToggleMenuOptions(),
      toggleMenuValue,
      setToggleMenuValue,
    };
  }

  return {
    dashboardToggleMenuOptions: managerToggleMenuOptions(),
    expandedToggleMenuOptions: managerToggleMenuOptions(),
    toggleMenuValue,
    setToggleMenuValue,
  };
}
