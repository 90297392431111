import { css } from '@emotion/react';
import { pageBackground } from '~Common/styles/colors';
import Dashboard from './Dashboard';

const sectionGap = '1.25rem';

const styles = {
  container: css({
    backgroundColor: pageBackground,
    display: 'flex',
    flexDirection: 'column',
    rowGap: sectionGap,
    width: '100%',

    '.MuiPaper-root': {
      transition: 'all 100ms ease',
    },
  }),
};

const View = ({
  ...props
}): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    <Dashboard />
  </div>
);

interface InsightLayoutProps {
  isLoading: boolean
  expandedCard?: string
}

const InsightLayout = ({
  isLoading,
  ...props
}: InsightLayoutProps): JSX.Element => {
  const hookProps = {
    isLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default InsightLayout;
