import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import { ReactText, useRef } from 'react';
import { getHost, hosts } from '~Deprecated/services/config';
import { teamsQueryKeys } from './queryKeys';
import { CreateEditTeamPayload } from '../const/types';

interface EditTeamParams {
  teamId: string,
  editTeamPaylod: CreateEditTeamPayload,
}

const editTeam = ({ teamId, editTeamPaylod }: EditTeamParams): Promise<HttpCallReturn<unknown>> => {
  const serverUrl = {
    // Host doesn't matter, but need this format to use the V2 endpoint
    host: getHost(hosts.reviewCycles, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/teams/${teamId}`,
  };

  return patchApi<unknown>(serverUrl, editTeamPaylod);
};

interface UseEditTeamParams {
  handleCompletion?: (data: HttpCallReturn<unknown>, varaibles: EditTeamParams) => void,
}

export const useEditTeam = ({
  handleCompletion,
}: UseEditTeamParams = {}): UseMutationResult<HttpCallReturn<unknown>, unknown, EditTeamParams> => {
  const orgId = getOrganizationId() ?? '';
  const toastId = useRef<ReactText | number | null>(null);

  // TODO: Optimistic Updates
  return useMutation({
    mutationFn: editTeam,
    onMutate: () => {
      toastId.current = toast.info('Editing Team...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error editing your team. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables) => {
      toast.update(toastId.current, {
        render: 'Successfully edited your team!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      handleCompletion?.(data, variables);

      void queryClient.invalidateQueries({ queryKey: teamsQueryKeys.all(orgId) });
    },
  });
};
