export const byPassAuth = [
  '/v1/auth/register/email',
  '/v1/auth/login/email',
  '/v1/auth/forgot-password',
  '/v1/auth/accessToken',
  'v1/auth/email/domain',
  'v2/auth/redirect',
];

const appDomain = `${process.env.REACT_APP_API_DOMAIN}`;
const environment = `${process.env.REACT_APP_API_ENV}`; // LOCAL, REMOTE, MOCK
const appEnvironment = `${process.env.REACT_APP_ENV?.toLowerCase()}`; // alpha, beta, gamma, tau, app

export const getHost = (uri, version = '1') => {
  switch (environment) {
    case 'LOCAL':
      return `${uri}/v${version}`;
    case 'REMOTE':
      return `${appDomain}/v${version}`;
    case 'MOCK':
      return 'http://localhost:3001';
    default:
      return `${uri}/v${version}`;
  }
};

export const hosts = {
  action: getHost(''),
  agenda: getHost(''),
  coaching: getHost(''),
  meeting: getHost(''),
  huddle: getHost(''),
  notes: getHost(''),
  feedback: getHost(''),
  auth: getHost(''),
  org: getHost(''),
  library: getHost(''),
  content: getHost(''),
  timelines: getHost(''),
  questions: getHost(''),
  goals: getHost(''),
  reviewCycles: getHost(''),
  reflections: getHost(''),
  insights: getHost(''),
  recognition: getHost(''),
  developmentplans: getHost(''),
  exports: `https://exports.${appEnvironment === 'prod' ? 'app' : appEnvironment}.leadr.${appEnvironment === 'prod' ? 'com' : 'dev'}/${appEnvironment}/api`,
};
