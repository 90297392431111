import { getApi } from '~Deprecated/services/HttpService';

export const getDetailAndNormalize = async ({ url, headers, normalizer }) => {
  const response = await getApi(url, headers);

  const item = normalizer(response.response);

  return {
    ...response,
    item,
  };
};
