import { AgendaType } from '@leadr-hr/types';
import { useMemo } from 'react';
import OnePersonCreatingItem from '~Meetings/components/details/agenda/RealtimeCreationCard/OnePersonCreatingItem';
import TwoPeopleCreatingItems from '~Meetings/components/details/agenda/RealtimeCreationCard/TwoPeopleCreatingItems';
import SeveralPeopleCreatingSameItem from '~Meetings/components/details/agenda/RealtimeCreationCard/SeveralPeopleCreatingSameItem';
import SeveralPeopleCreatingDifferentItems from '~Meetings/components/details/agenda/RealtimeCreationCard/SeveralPeopleCreatingDifferentItems';
import { AgendaCreatePayload } from '~Meetings/const/realTimeMeetingMessages';

interface ViewProps {
  peopleCreatingItemsInRealTime: AgendaCreatePayload[],
  numberOfAgendaTypes: number,
}

const View = ({
  peopleCreatingItemsInRealTime,
  numberOfAgendaTypes,
  ...props
}: ViewProps): JSX.Element => (
  <>
    {numberOfAgendaTypes === 1 && (
      <>
        {peopleCreatingItemsInRealTime.length === 1 && (
          <OnePersonCreatingItem
            inline
            showAvatar={false}
            person={peopleCreatingItemsInRealTime[0].creator}
            agendaType={peopleCreatingItemsInRealTime[0].type}
            {...props}
          />
        )}
        {peopleCreatingItemsInRealTime.length === 2 && (
          <TwoPeopleCreatingItems
            inline
            person1={peopleCreatingItemsInRealTime[0].creator}
            person2={peopleCreatingItemsInRealTime[1].creator}
            agendaType={peopleCreatingItemsInRealTime[0].type}
            {...props}
          />
        )}
        {peopleCreatingItemsInRealTime.length > 2 && (
          <SeveralPeopleCreatingSameItem
            inline
            agendaType={peopleCreatingItemsInRealTime[0].type}
            {...props}
          />
        )}
      </>
    )}
    {numberOfAgendaTypes > 1 && (
      <SeveralPeopleCreatingDifferentItems inline {...props} />
    )}
  </>
);

interface RealtimeInlineTextProps {
  peopleCreatingItemsInRealTime: AgendaCreatePayload[],
}

const RealtimeInlineText = ({
  peopleCreatingItemsInRealTime,
  ...props
}: RealtimeInlineTextProps): JSX.Element => {
  const numberOfAgendaTypes = useMemo(() => {
    const agendaTypesCount = peopleCreatingItemsInRealTime?.reduce((count, personCreatingItemInRealTime) => {
      if (!count.agendaTypes.includes(personCreatingItemInRealTime.type)) {
        count.agendaTypes.push(personCreatingItemInRealTime.type);
      }

      return count;
    }, { agendaTypes: [] as AgendaType[] }).agendaTypes.length;

    return agendaTypesCount;
  }, [peopleCreatingItemsInRealTime]);

  const hookProps = {
    peopleCreatingItemsInRealTime,
    numberOfAgendaTypes,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default RealtimeInlineText;
