import { ComponentProps } from 'react';
import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

const styles = {
  icon: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'flex-start',
  }),
  requiredIcon: css({
    fontSize: '.4375rem',
    color: palette.brand.red,
  }),
};

const RequiredIcon = ({
  ...props
}: ComponentProps<'div'>): JSX.Element => (
  <Tooltip content="Required Field">
    <div css={styles.icon} {...props}>
      <FontAwesomeIcon
        icon={faCircle}
        css={styles.requiredIcon}
      />
    </div>
  </Tooltip>
);

export default RequiredIcon;
