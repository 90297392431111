import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useDebounce } from '@react-hook/debounce';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import Switch from '~Deprecated/ui/components/Inputs/Switch';
import { useLocalStorage } from '~Common/hooks/useLocalStorage';

const styles = {
  override: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    justify-items: left;
    margin-top: 25px;
  `,
  searchContainer: css`
    margin-top: 25px;
  `,
  switchContainer: css`
    width: 100%;
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    h5 {
      word-break: break-word;
    }
  `,
  container: css`
    text-align: center;
  `,
};

const View = ({
  flags, isOverriding, setIsOverriding, setFlag,
}) => {
  const [search, setSearch] = useDebounce('', 350);
  const doOverrideChange = () => {
    setIsOverriding(!isOverriding);
    setSearch('');
  };

  const doFlagChange = (flagName, flagValue) => {
    setFlag({
      key: flagName,
      value: !flagValue,
    });
  };

  const doFlagSearch = (e) => {
    setSearch(e.target.value);
  };

  const searchedFlags = useMemo(() => Object.entries(flags).filter(([flagName, flagValue]) => {
    const value = `${flagName.toLowerCase()} ${flagValue}`;

    if (value.includes('audit')) return false;

    if (!search) return true;

    return value.includes(search.toLowerCase());
  }), [
    flags, search,
  ]);

  return (
    <div css={[styles.container]}>
      <div>
        <h5>Enabling Override will cause you to no longer sync with LaunchDarkly until it is re-enabled.</h5>
        <h5>Disable it to instantly be back in sync.</h5>
        <h5>Note that if the server requires a flag on your account, it will still fail. This is local only.</h5>
        <h5>Also don&apos;t disable showTestPage unless you want to be kicked off this page.</h5>
        <br />
        <br />
        <Switch
          label="Enable Feature Flag Override"
          onChange={doOverrideChange}
          checked={isOverriding}
        />
      </div>
      { isOverriding && (
        <>
          <div
            css={styles.searchContainer}
          >
            <input placeholder="Search flag name or value (true/false)" onChange={doFlagSearch} />
          </div>
          <div
            css={styles.override}
          >
            {searchedFlags.map(([flagName, flagValue]) => (
              <div
                css={styles.switchContainer}
                key={flagName}
              >
                <Switch
                  checked={flagValue}
                  label={flagName}
                  onChange={() => doFlagChange(flagName, flagValue)}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

View.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  flags: PropTypes.object.isRequired,
  isOverriding: PropTypes.bool.isRequired,
  setIsOverriding: PropTypes.func.isRequired,
  setFlag: PropTypes.func.isRequired,
};

const FlagEditor = ({
  flags, ...props
}) => {
  const [isOverriding, setIsOverriding] = useLocalStorage('isOverriding', false);
  const [flagsToUse, setFlagsToUse] = useLocalStorage('flags', flags);

  const hookProps = {
    flags: flagsToUse,
    isOverriding,
    setFlag: (args) => {
      const tempFlags = { ...flagsToUse };
      tempFlags[args.key] = args.value;
      setFlagsToUse(tempFlags);
    },
    setIsOverriding: (overriding) => {
      setIsOverriding(overriding);

      if (!overriding) {
        localStorage.removeItem('flags');
      }
    },
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

FlagEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  flags: PropTypes.object.isRequired,
};

FlagEditor.defaultProps = {};

export { View };
export default withLDConsumer()(FlagEditor);
