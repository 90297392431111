import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from '~Common/components/Toasts';
import { COACHING_ROUTE, MEETING_ROUTE } from '~Common/const/routes';
import { buildQueryString } from '~Common/utils';
import { getOrganizationId } from '~Common/utils/localStorage';
import { navigateAction } from '~Deprecated/actions/navigate';
import { MeetingFactoryType, MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';

interface UseMeetingUrlReturn {
  constructMeetingUrl: (params: ConstructMeetingUrlParams) => ConstructMeetingUrlReturn,
  navigateToMeeting: (params: NavigateToMeetingParams) => void,
  copyMeetingUrlToClipboard: (params: CopyMeetingUrlToClipboardParams) => void,
}

export interface ConstructMeetingUrlParams {
  meetingType: MeetingTypeEnum | MeetingFactoryType,
  meetingInstanceId: string,
  factoryId?: string,
  searchedItem?: string,
  teamId?: string,
}

export interface ConstructMeetingUrlReturn {
  fullPath: string,
  queryParams: Record<string, string>,
  pathname: string,
  navigatePath: string,
}

interface NavigateToMeetingParams extends ConstructMeetingUrlParams {
  extraQueryParams?: Record<string, unknown>,
}

type CopyMeetingUrlToClipboardParams = ConstructMeetingUrlParams;

export const useMeetingUrl = (): UseMeetingUrlReturn => {
  const dispatch = useDispatch();

  const constructMeetingUrl = useCallback(({
    meetingType,
    meetingInstanceId,
    factoryId,
    searchedItem,
    teamId,
  }: ConstructMeetingUrlParams): ConstructMeetingUrlReturn => {
    const isCoaching = meetingType === MeetingTypeEnum.COACHING;

    let pathname = '';
    let meetingIdName = '';
    if (isCoaching) {
      pathname = COACHING_ROUTE;
      meetingIdName = 'coachingId';
    } else {
      pathname = MEETING_ROUTE;
      meetingIdName = 'meetingId';
    }

    const queryParams = {
      orgId: getOrganizationId() || '',
      [meetingIdName]: meetingInstanceId,
      ...(!!factoryId && { factoryId }),
      ...(!!searchedItem && { searchedItem }),
      ...(!!teamId && { teamId }),
    };

    const search = buildQueryString(queryParams);

    return {
      fullPath: `${window.location.origin}${pathname}${search}`,
      queryParams,
      pathname,
      navigatePath: `${pathname}${search}`,
    };
  }, []);

  const navigateToMeeting = useCallback(({
    meetingType,
    meetingInstanceId,
    factoryId,
    teamId,
    extraQueryParams,
  }: NavigateToMeetingParams): void => {
    const { pathname, queryParams } = constructMeetingUrl({
      meetingType,
      meetingInstanceId,
      factoryId,
      teamId,
    });

    dispatch(navigateAction({
      pathname,
      search: {
        ...queryParams,
        ...extraQueryParams,
      },
    }));
  }, [constructMeetingUrl, dispatch]);

  const copyMeetingUrlToClipboard = useCallback(({
    meetingType,
    meetingInstanceId,
    teamId,
    factoryId,
  }: CopyMeetingUrlToClipboardParams): void => {
    // Copy Share Link
    const { fullPath } = constructMeetingUrl({
      meetingType,
      meetingInstanceId,
      teamId,
      factoryId,
    });
    const copySuccessful = copy(fullPath);
    if (copySuccessful) {
      toast.success('Copy Successful, feel free to paste this link to whomever needs it. 😃');
    }
  }, [constructMeetingUrl]);

  return {
    constructMeetingUrl,
    navigateToMeeting,
    copyMeetingUrlToClipboard,
  };
};
