import { call, put } from 'redux-saga/effects';

import * as types from '../actions';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';

export const RTECharacterLimit = 50000;

export const decodeEntities = (() => {
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  function decodeHTMLEntities(str) {
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }

    return str;
  }

  return decodeHTMLEntities;
})();

/**
 *
 * @param {String} error used to store error message
 */
export function* GlobalAPIErrorHandler(error, type = 'error') {
  if (!error || error === 'Unexpected end of JSON input') {
    error = 'errors.default_error_message';
  }

  yield put({ type: types.GLOBAL_API_ERROR, payload: { error: decodeEntities(error), type } });
}

export function* hasError(response) {
  // On error of meeting creation, return error code to frontend.
  if (response && response.status !== 200) {
    if (!response.type) {
      yield call(GlobalAPIErrorHandler, response.message);
      return true;
    }
    const errorDetails = {
      success: false,
      data: response.data,
      error: response.error,
    };
    yield put({
      type: response.type,
      payload: errorDetails,
    });
    return true;
  }
  return false;
}

// check space bar and empty space in editor
export const isEditorEmpty = (text) => {
  let status = false;
  if (text) {
    if (text.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      status = true;
    }
  } else {
    // for udefined text or empty
    status = true;
  }
  return status;
};

/**
 * Used to check loader status
 * @param {string} name Store loader name.
 * @param {Array} state Store loader state array.
 */
export const checkLoader = (name, state) => {
  if (state && state.length) {
    const index = state.findIndex((res) => res.name === name);
    if (index !== -1) {
      return state[index].isLoading;
    }
  }
  return false;
};

export function* setLoader(name, state = true) {
  yield put({
    type: types.ACTIVATE_LOADER,
    response: {
      name,
      isLoading: state,
    },
  });
}
