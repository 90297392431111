import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { Select } from '~Common/V3/components/uncontrolled';
import MenuItem from '@mui/material/MenuItem';
import {
  DEFAULT_TEAM_ID, GoalOptionTypes,
} from '~Goals/const/defaults';
import { FORM_STYLES } from '~Goals/const/styles';
import { palette } from '~Common/styles/colors';

import { SelectOption } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { UseFormReturn } from 'react-hook-form';
import { FormValues } from '~Goals/schemata/CreateGoalSchemata';
import { Skeleton } from '@mui/material';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useHistory } from 'react-router';
import { MY_TEAMS } from '~Common/const/routes';
import { forMobileObject } from '~Common/styles/mixins';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import OldGoalTypeSelectionInfo from '~Goals/OldGoals/OldGoalTypeSelectionInfo';
import ObjectiveTypeSelect from './ObjectiveTypeSelect';
import PrivateGoalToggle from './PrivateGoalToggle';

const styles = {
  ...FORM_STYLES,
  goalTypeContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  selectsContainer: (isTeamView: boolean, canViewTableGroup: boolean) => css({
    display: 'flex',
    gap: '1.5rem',
    '.MuiFormControl-root': {
      width: '100%',
    },
  }, isTeamView && ({
    display: 'grid',
    gridTemplateColumns: canViewTableGroup ? '1fr 1fr 1fr' : '1fr 1fr',
  }), forMobileObject({
    display: 'flex',
    flexDirection: 'column',
    gap: '.75rem',
  })),
  goalType: css({
    flex: 1,
  }),
  teamContainer: css({
    flex: 1,
  }),
  teamList: (noTeamSelected: boolean, noTeams?: boolean) => css({
  }, noTeamSelected && css({
    '.MuiInputBase-input': {
      color: palette.neutrals.gray600,
    },
  }), noTeams && css({
    svg: {
      display: 'none',
    },
  }), forMobileObject({
    '.MuiInputBase-input': {
      maxWidth: 'unset',
    },
  })),
  icon: css({
    marginLeft: '.5rem',
  }),
  teamNote: css({
    marginTop: '.5rem',
  }),
  menuItem: css({
    maxWidth: '26rem !important',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  }),
  additionalInfo: css({
    gap: '1.5rem',
  }),
  objectiveTypeSelect: css({
    flex: 1,
  }),
};

interface GoalTypeSelectionProps {
  goal: Goals.Requests.CreateGoalRequestPayload,
  isEdit: boolean,
  areTeamsLoading: boolean,
  canViewTableGroup: boolean,
  teamsList: SelectOption[],
  teamIdSelected: string | undefined,
  formContext: UseFormReturn<FormValues>,
  allowedContextTypes?: Goals.GoalContextType[],
}

export function GoalTypeSelection({
  goal,
  isEdit,
  areTeamsLoading,
  canViewTableGroup,
  teamsList,
  teamIdSelected,
  formContext,
  allowedContextTypes = [Goals.GoalContextType.Personal, Goals.GoalContextType.Team, Goals.GoalContextType.Organization],
}: GoalTypeSelectionProps): JSX.Element {
  const {
    isAdmin,
    isExecutive,
    isLimitedAccess,
  } = useUserPermissions();
  const isAdminOrExecutive = isAdmin || isExecutive;
  const hasTeams = teamsList.length > 0;

  const goalTypeSelected = formContext.watch('type') as string;

  const history = useHistory();
  const onClickCreateTeam = (): void => {
    history.push(MY_TEAMS);
  };

  const formattedGoalOptionTypes = GoalOptionTypes.map((goalOptionType) => {
    let disabled = false;
    if (!isAdminOrExecutive && goalOptionType.value === Goals.GoalContextType.Organization) {
      disabled = true;
    }
    if (isLimitedAccess && goalOptionType.value !== Goals.GoalContextType.Personal) {
      disabled = true;
    }
    if (!hasTeams && !isAdminOrExecutive && goalOptionType.value === Goals.GoalContextType.Team) {
      disabled = true;
    }

    if (!allowedContextTypes.includes(goalOptionType.value)) {
      disabled = true;
    }

    return {
      ...goalOptionType,
      disabled,
    };
  });

  const { featureFlagEnableCascadingGoals } = useEnableCascadingGoals();

  return (
    <>
      <div
        css={styles.goalTypeContainer}
      >
        <div css={styles.selectsContainer(goalTypeSelected === Goals.GoalContextType.Team, canViewTableGroup)}>
          {!goalTypeSelected && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height={80}
              css={styles.goalType}
            />
          )}
          {goalTypeSelected && (
            <Select
              id="type"
              name="type"
              defaultValue={goalTypeSelected}
              label="Goal Type"
              required
              css={styles.goalType}
            >
              {formattedGoalOptionTypes.map((goalOptionType) => (
                <MenuItem
                  key={goalOptionType.value}
                  value={goalOptionType.value}
                  disabled={goalOptionType.disabled}
                >
                  {goalOptionType.text}
                </MenuItem>
              ))}
            </Select>
          )}
          {!areTeamsLoading && goalTypeSelected === Goals.GoalContextType.Team && (
            <div css={styles.teamContainer}>
              <Select
                css={styles.teamList(teamIdSelected === DEFAULT_TEAM_ID, teamsList.length === 0)}
                id="team"
                name="team"
                defaultValue={teamIdSelected}
                label="Team"
                required
                showRequiredIcon
                disabled={teamsList.length === 0}
              >
                <MenuItem
                  key={DEFAULT_TEAM_ID}
                  value={DEFAULT_TEAM_ID}
                >
                  Select Team
                </MenuItem>
                {teamsList.map((team) => (
                  <MenuItem
                    css={styles.menuItem}
                    key={team.value}
                    value={team.value}
                  >
                    {team.label}
                  </MenuItem>
                ))}
              </Select>
              {!isEdit && teamsList.length === 0 && (
                <div
                  css={styles.additionalInfo}
                >
                  <p
                    css={styles.teamNote}
                  >
                    You don&apos;t lead any teams.
                    <LeadrButton
                      variant="text"
                      textButtonColor={palette.neutrals.gray600}
                      onClick={onClickCreateTeam}
                      data-test-id="actionItemsEmptyStateCreateActionItem"
                    >
                      Click here to create one.
                    </LeadrButton>
                  </p>
                </div>
              )}
            </div>
          )}
          {canViewTableGroup && goalTypeSelected === Goals.GoalContextType.Team && (
            <ObjectiveTypeSelect
              css={styles.objectiveTypeSelect}
              defaultValue={goal.category}
            />
          )}
        </div>
        {featureFlagEnableCascadingGoals && (
          <PrivateGoalToggle
            isPrivate={goal.isPrivate}
            goalTypeSelected={goalTypeSelected}
          />
        )}
        {!featureFlagEnableCascadingGoals && (
          <OldGoalTypeSelectionInfo
            isPrivate={goal.isPrivate}
            goalTypeSelected={goalTypeSelected}
          />
        )}
      </div>
    </>
  );
}
