import { css } from '@emotion/react';
import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from '~Deprecated/utils';

const styles = {
  card: css`
    padding: 16px;
    border-radius: 5px;
    box-shadow: none;
  `,
};

const View = ({ renderBody, ...props }) => (
  <Card css={styles.card} {...props}>
    {renderBody()}
  </Card>
);

View.propTypes = {
  renderBody: PropTypes.func,
};

View.defaultProps = {
  renderBody: noop,
};

const FreeformAnswerCard = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

FreeformAnswerCard.propTypes = {};

FreeformAnswerCard.defaultProps = {};

export { View };
export default FreeformAnswerCard;
