import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';

const store = {};

window.genericStore = store;

const LOCAL_KEY = '__LOCAL__';

const setReference = (key, newValue) => {
  store[key] = newValue;
};

queryClient.getQueryCache().subscribe(({ query, type }) => {
  if (query && query.queryKey?.[0] === LOCAL_KEY) {
    const { queryHash, status } = query;

    if (type === 'queryAdded') {
      setReference(queryHash, status?.data);
    }
  }
});

export const createQueryKey = ({ module, workflow, feature }) => {
  const queryKey = [LOCAL_KEY, getOrganizationId(), getOrganizationUserId()];
  if (module) queryKey.push(module);
  if (workflow) queryKey.push(workflow);
  if (feature) queryKey.push(feature);

  return queryKey;
};

export const getLocalStore = ({ key }) => queryClient.getQueryState(key);

export const setLocalStore = ({ key, value }) => {
  setReference(key, value);
  queryClient.setQueryData(key, value);
};

export const useLocalStore = ({ key, defaultValue }) => {
  const result = useQuery({
    queryKey: key,
    queryFn: () => new Promise((resolve) => {
      resolve(store[key] ?? defaultValue ?? null);
    }),
  });
  return result.data ?? defaultValue;
};

export const useGenericStore = ({
  module, workflow, feature, defaultValue,
}) => {
  const queryKey = useMemo(() => createQueryKey({ module, workflow, feature }), [module, workflow, feature]);

  const setValue = useCallback((value) => {
    setLocalStore({ key: queryKey, value });
  }, [queryKey]);

  return {
    setValue,
    value: useLocalStore({ key: queryKey, defaultValue }),
    queryKey,
  };
};
