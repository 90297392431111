import {
  Paper, Table, TableContainer,
} from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from '~Deprecated/utils';

const View = ({ renderHeader, renderBody, ...props }) => (
  <TableContainer component={Paper} {...props}>
    <Table>
      {renderHeader()}
      {renderBody()}
    </Table>
  </TableContainer>
);

View.propTypes = {
  renderHeader: PropTypes.func,
  renderBody: PropTypes.func,
};

View.defaultProps = {
  renderHeader: noop,
  renderBody: noop,
};

const GenericTable = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

GenericTable.propTypes = {};

GenericTable.defaultProps = {};

export { View };
export default GenericTable;
