import { css } from '@emotion/react';
import { ActionItem, ActionItemContextType, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { useGoalActionItems } from '~ActionItems/hooks/useGoalActionItems';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import { palette } from '~Common/styles/colors';
import emptyGoalsV3 from '~Goals/assets/images/emptyGoalsV3.png';
import { createEditActionItemTemplate } from '~ActionItems/components/Drawers/CreateEditActionItemDrawer';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Checkbox, Skeleton } from '@mui/material';
import { Goals } from '@leadr-hr/types';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { Person } from '~Common/const/interfaces';
import { SKELETON_STYLES } from '~Goals/const/styles';
import GoalDetailsActionItemsTable from './GoalDetailsActionItemsTable';

const styles = {
  ...SKELETON_STYLES,
  heading: css({
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    fontWeight: 600,
  }),
  emptyStateContainer: css({
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1.5rem',

    h6: {
      textTransform: 'unset',
    },
  }),
  emptyStateImage: (isMobile: boolean) => css({
    height: '15rem',
  }, isMobile && {
    height: '7rem',
  }),
  addActionItemButton: css({
    marginRight: '.1875rem',
    fontWeight: 600,
  }),
  checkboxWrap: css({
    fontSize: '.875rem',
    fontWeight: 500,
    color: palette.neutrals.gray700,

    '.MuiCheckbox-root': {
      padding: '0 .75rem',
    },
    'svg[data-testid="CheckBoxOutlineBlankIcon"]': {
      color: palette.neutrals.gray400,
    },
    'svg[data-testid="CheckBoxIcon"]': {
      color: palette.brand.indigo,
    },
  }),
  addButtonSkelly: css({
    maxWidth: '6.25rem',
    height: '2.5rem',
  }),
};

interface ViewProps {
  actionItems: ActionItem[] | undefined,
  onCreateActionItem: () => void,
  hideCompleted: boolean,
  handleClick: () => void,
  showSkeleton: boolean,
  page: number,
  setPage: (page: number) => void,
  isMobile: boolean,
  canAddItems: boolean,
  goalCreatorId: string,
  goalOwnerId: string | undefined,
  actionItemsResponse: ActionItem[] | undefined,
  participants: Goals.GoalParticipant[] | undefined,
}

const View = ({
  actionItems,
  onCreateActionItem,
  hideCompleted,
  handleClick,
  showSkeleton,
  participants,
  page,
  setPage,
  isMobile,
  canAddItems,
  goalCreatorId,
  goalOwnerId,
  actionItemsResponse,
}: ViewProps): JSX.Element => (
  <>
    <div>
      <span
        css={styles.heading}
      >
        Action Items
        {actionItemsResponse?.length !== 0 && !showSkeleton && (
          <span css={styles.checkboxWrap}>
            <Checkbox
              checked={hideCompleted}
              onChange={handleClick}
            />
            Hide completed
          </span>
        )}
      </span>
      {!showSkeleton && actionItems?.length === 0 && (
        <EmptyStateWithImage
          css={styles.emptyStateContainer}
          renderImage={() => (
            <img
              css={styles.emptyStateImage(isMobile)}
              src={emptyGoalsV3}
              alt="Empty Action Items"
              data-tet-id="goalsEmptyActionItems"
            />
          )}
          renderText={() => (
            <>
              {actionItemsResponse?.length === 0 && (
              <>
                {!canAddItems && (
                <p>There are no action items on this goal.</p>
                )}
                {canAddItems && (
                <>
                  <LeadrButton
                    variant="text"
                    textButtonColor={palette.brand.indigo}
                    onClick={onCreateActionItem}
                    data-test-id="actionItemsEmptyStateCreateActionItem"
                  >
                    Add the first Action Item
                  </LeadrButton>
                  to this Goal
                </>
                )}
              </>
              )}
            </>
          )}
        />
      )}
      <GoalDetailsActionItemsTable
        actionItems={actionItems}
        isLoading={actionItems === undefined || showSkeleton}
        page={page}
        setPage={setPage}
        goalCreatorId={goalCreatorId}
        goalOwnerId={goalOwnerId}
        participants={participants}
      />
      {showSkeleton && (
        <Skeleton
          variant="text"
          animation="wave"
          css={[styles.addButtonSkelly, styles.allowProperHeight]}
        />
      )}
      {!showSkeleton && canAddItems && (
        <LeadrButton
          name="actionItemCreateButton"
          data-test-id="actionItemCreateButton"
          type="submit"
          size="small"
          variant="ghost"
          onClick={onCreateActionItem}
          color="primary"
        >
          <LeadrButton.IconAndText
            icon={faPlus}
            text="Action Item"
          />
        </LeadrButton>
      )}
    </div>
  </>
);

interface GoalActionItemsProps {
  id: string,
  validAssignees?: Goals.GoalParticipant[],
  participants?: Goals.GoalParticipant[],
  showSkeleton: boolean,
  isMobile: boolean,
  canAddItems: boolean,
  goalCreatorId: string,
  goalOwnerId: string | undefined,
}

export const GoalActionItems = ({
  id,
  participants,
  validAssignees,
  showSkeleton,
  isMobile,
  canAddItems,
  goalCreatorId,
  goalOwnerId,
}: GoalActionItemsProps): JSX.Element => {
  const { data: actionItemData } = useGoalActionItems({ goalId: id });
  const [hideCompleted, setHideCompleted] = useState(true);
  const [page, setPage] = useState(1);
  const handleClick = (): void => {
    setHideCompleted(!hideCompleted);
  };
  const actionItemsResponse = actionItemData?.response;
  const actionItems = actionItemsResponse?.sort((a, b) => {
    if (a.dueDateInMillis !== null && b.dueDateInMillis !== null) {
      return a.dueDateInMillis - b.dueDateInMillis;
    } if (a.dueDateInMillis === null && b.dueDateInMillis !== null) {
      return 1;
    } if (a.dueDateInMillis !== null && b.dueDateInMillis === null) {
      return -1;
    }
    return a.createdInMillis - b.createdInMillis;
  });

  const notCompletedActionItems = useMemo(() => (
    actionItems?.filter((actionItem) => actionItem.status !== NewActionItemStatus.Completed)
  ), [actionItems]);
  const dispatch = useDispatch();

  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, Person>>;

  const filteredValidAssigneesOrgIds = useMemo(() => (
    validAssignees?.map((assignee) => assignee.orgUserId)
  ), [validAssignees]);

  const getUserIdForInputArray = (orgIds: string[] | undefined, data: Record<string, Person>): string[] => {
    const userIds = [] as string[];
    if (!data) {
      return []; // Return an empty array if data is undefined or null
    }
    if (orgIds) {
      orgIds.forEach((inputString) => {
        if (Object.prototype.hasOwnProperty.call(data, inputString)) {
          userIds.push(data[inputString].userId);
        }
      });

      return userIds;
    }
    return [];
  };

  const ids = getUserIdForInputArray(filteredValidAssigneesOrgIds, peopleData);
  const onCreateActionItem = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
        args: {
          description: '',
          alwaysShowCloseButton: true,
          allowSelf: false,
          validAssignees: ids ?? [],
          context: {
            type: ActionItemContextType.Goal,
            id,
          },
        },
      },
    }));
  };

  useEffect(
    () => {
      setPage(1);
    },
    [hideCompleted],
  );

  useEffect(() => {
    if (notCompletedActionItems && notCompletedActionItems?.length === 0) {
      setHideCompleted(false);
    } else {
      setHideCompleted(true);
    }
  }, [notCompletedActionItems]);

  const hookProps = {
    actionItems: hideCompleted ? notCompletedActionItems : actionItems,
    onCreateActionItem,
    participants,
    hideCompleted,
    handleClick,
    showSkeleton,
    page,
    setPage,
    isMobile,
    canAddItems,
    goalCreatorId,
    goalOwnerId,
    actionItemsResponse,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
