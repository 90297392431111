import { useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { LearningTemplate, LearningTemplateList } from '~Learning/const/interfaces';
import { useLastExistedValue } from '~Deprecated/hooks/useLastExistedValue';
import { buildQueryString } from '~Common/utils';
import { learningTableGroupTemplatesQueryKeys } from '../queryKeys';

interface GetLearningTableGroupTemplateListParams {
  page: number,
  count: number,
  categoryId: string,
}

const getLearningTableGroupTemplateList = async ({
  categoryId,
  page,
  count,
}: GetLearningTableGroupTemplateListParams): Promise<HttpCallReturn<LearningTemplateList>> => {
  const queryData = buildQueryString({
    page,
    count,
    categoryId,
  });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/curatedByTableGroup/list${queryData}`;

  return getApi<LearningTemplateList>(serverUrl);
};

interface LearningTemplateQueryParams {
  page: number,
  count: number,
  categoryId: string,
  enabled: boolean,
}

interface UseGetLearningTableGroupTemplateListReturn {
  learningTemplates: LearningTemplate[],
  isLoading: boolean,
  learningTemplatesTotalCount: number,
}

export const useGetLearningTableGroupTemplateList = (
  learningTemplateQueryParameters: LearningTemplateQueryParams,
): UseGetLearningTableGroupTemplateListReturn => {
  const result = useQuery({
    queryKey: learningTableGroupTemplatesQueryKeys.list({
      page: learningTemplateQueryParameters.page,
      count: learningTemplateQueryParameters.count,
      categoryId: learningTemplateQueryParameters.categoryId ?? '',
    }),
    queryFn: () => getLearningTableGroupTemplateList({
      page: learningTemplateQueryParameters.page,
      count: learningTemplateQueryParameters.count,
      categoryId: learningTemplateQueryParameters.categoryId ?? '',
    }),
    enabled: learningTemplateQueryParameters.enabled && !!learningTemplateQueryParameters.categoryId,
  });

  const learningTemplatesTotalCount = useLastExistedValue<number>(result.data?.response?.total) ?? 0;

  return {
    learningTemplates: result.data?.response?.learningTemplates ?? [],
    isLoading: result.isLoading,
    learningTemplatesTotalCount,
  };
};
