import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import { NavigationIndicatorData, NavigationItem } from '~Root/components/NavigationSidebar/types';
import { useNavigationStore } from '~Root/components/NavigationSidebar/useNavigationStore';
import { SHARED_STYLES } from '~Root/components/NavigationSidebar/NavigationItems/styles';
import LeadrChip from '~Common/V3/components/LeadrChip';
import { useMemo } from 'react';

const styles = {
  ...SHARED_STYLES,
  compactIndicator: css({
    color: palette.brand.indigo,
    width: '0.4rem',
    position: 'absolute',
    right: '0.6rem',
  }),
};

interface ViewProps extends Omit<NavLinkProps, 'onClick' | 'to' | 'activeClassName'> {
  compact: boolean,
  item: NavigationItem,
  onClick: () => void,
  notificationLabel?: string,
}

const View = ({
  compact,
  item,
  onClick,
  notificationLabel,
  ...props
}: ViewProps): JSX.Element => (
  <>
    {!!item.href && (
      <Tooltip
        key={item.label}
        content={compact ? item.label : null}
        placement="right"
      >
        <NavLink
          {...props}
          to={item.href}
          css={styles.navItem(compact)}
          activeClassName="active"
          onClick={onClick}
          data-href={item.href}
          data-label={item.label}
          data-test-id={item.testId}
        >
          <FontAwesomeIcon icon={item.icon} css={styles.icon} />

          {!compact && (
            <div css={styles.labelContainer}>
              {item.label}
            </div>
          )}
          {!!notificationLabel && (
            <>
              {compact && (
                <FontAwesomeIcon
                  icon={faCircle}
                  css={styles.compactIndicator}
                />
              )}

              {!compact && (
                <LeadrChip
                  label={notificationLabel}
                />
              )}
            </>
          )}
        </NavLink>
      </Tooltip>
    )}
  </>
);

export interface LinkButtonProps extends Omit<NavLinkProps, 'onClick' | 'to' | 'activeClassName'> {
  compact: boolean,
  item: NavigationItem,
  navigationIndicatorData: NavigationIndicatorData,
}

export const LinkButton = ({
  compact,
  item,
  navigationIndicatorData,
  ...props
}: LinkButtonProps): JSX.Element => {
  const closeMobileNavigation = useNavigationStore((state) => state.closeMobileNavigation);
  const onClick = (): void => {
    closeMobileNavigation();
  };

  const notificationLabel = useMemo(() => {
    const notificationTotal = item.getNotificationTotal?.(navigationIndicatorData);
    let label;

    if (notificationTotal) {
      label = notificationTotal > 99 ? '99+' : `${notificationTotal}`;
    }

    return label;
  }, [
    item,
    navigationIndicatorData,
  ]);

  const hookProps = {
    compact,
    item,
    navigationIndicatorData,
    onClick,
    notificationLabel,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};
