import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

import { hexToRGBA, palette } from '~Common/styles/colors';
import ListItem from '../../../meetings/components/shared/listItems/ListItem';

const styles = {
  calledOutBackground: css({
    backgroundColor: hexToRGBA(palette.brand.orange, 0.10),
  }),
  calledOutIcon: css({
    color: palette.brand.orange,
  }),
  leftIcon: css({
    color: palette.brand.indigo,
    width: '2.125rem !important',
    height: '2.125rem !important',
  }),
  rightIcon: css({
    width: '1.3125rem !important',
    height: '1.3125rem !important',
  }),
  listItem: css({
    color: palette.neutrals.gray700,
  }),
};

interface MeetingListItemInterface {
  isCalledOut: boolean,
  icon: IconDefinition,
  content: string,
  description: string,
  onClick: () => void,
}

const MeetingListItem = ({
  isCalledOut,
  icon,
  ...props
}: MeetingListItemInterface): JSX.Element => (
  <ListItem
    css={[styles.listItem, isCalledOut && styles.calledOutBackground]}
    renderLeftAdornment={() => (
      <div>
        <FontAwesomeIcon
          css={[
            styles.leftIcon,
            (isCalledOut && styles.calledOutIcon),
          ]}
          icon={icon}
        />
      </div>
    )}
    renderRightAdornment={() => (
      <>
        {isCalledOut && (
          <div>
            <FontAwesomeIcon
              css={[
                styles.rightIcon,
                styles.calledOutIcon,
              ]}
              icon={faBell}
            />
          </div>
        )}
      </>
    )}
    {...props}
  />
);

export default MeetingListItem;
