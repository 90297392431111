import { css } from '@emotion/react';
import { useMemo, useContext } from 'react';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import Divider from '@mui/material/Divider';
import { palette } from '~Common/styles/colors';
import AvatarMaybeMultipleMaybeNot, { isNonLeadrUser } from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import { useGetPeople } from '~Common/hooks/people/useGetPeople';
import { MeetingInfoContext } from '../../../providers/MeetingInfoContext';

const styles = {
  avatars: css({
    display: 'flex',
    alignItems: 'center',
  }),
  divider: css({
    color: palette.neutrals.gray400,
    margin: '.3125rem .5rem',
  }),
  avatar: css({
    '&:not(:last-child)': {
      marginRight: '.25rem',
    },
  }),
};

interface ViewProps {
  organizer: PersonDisplayInformation | undefined,
  attendees: PersonDisplayInformation[],
}

const View = ({
  organizer,
  attendees,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.avatars}
    {...props}
  >
    {organizer && (
      <NewBaseAvatar
        avatarSize={30}
        name={`${organizer.firstName} ${organizer.lastName}`}
        profileImageUrl={organizer.profileImageUrl}
      />
    )}
    <Divider css={styles.divider} orientation="vertical" flexItem />
    {attendees && (
      <AvatarMaybeMultipleMaybeNot
        usersInfo={attendees}
        numberOfUsers={attendees.length}
        numberOfUsersToShow={7}
        avatarHeight={30}
        avatarWidth={30}
        renderAvatar={({ user }) => (
          <>
            {isNonLeadrUser(user) && (
              <NewBaseAvatar
                css={styles.avatar}
                email={user.email}
                avatarSize={30}
                isNonLeadrUser
              />
            )}
            {!isNonLeadrUser(user) && (
              <NewBaseAvatar
                css={styles.avatar}
                name={`${user.firstName} ${user.lastName}`}
                profileImageUrl={user.profileImageUrl}
                avatarSize={30}
              />
            )}
          </>
        )}
        renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
          <OverflowAvatar
            css={styles.avatar}
            {...overflowAvatarProps}
          />
        )}
      />
    )}
  </div>
);

const Avatars = ({
  ...props
}): JSX.Element => {
  const { data } = useGetPeople();
  const {
    organizer: organizerOrgUser,
    attendeeOrgUserIds: attendeesOrgUserIds,
  } = useContext(MeetingInfoContext);

  const organizer = useMemo(
    () => data?.response.items.find((person) => person.orgUserId === organizerOrgUser.orgUserId),
    [organizerOrgUser.orgUserId, data?.response.items],
  );

  const attendees = useMemo(() => {
    if (data?.response.items) {
      const tempAttendees = attendeesOrgUserIds?.map((orgUserId) => data.response.items.find((person) => person.orgUserId === orgUserId)).filter(Boolean);
      return tempAttendees as PersonDisplayInformation[];
    }

    return [];
  }, [attendeesOrgUserIds, data?.response.items]);

  const hookProps = {
    organizer,
    attendees,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default Avatars;
