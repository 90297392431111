import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { hosts, getHost } from '~Deprecated/services/config';
import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

const publishCycle = ({ id }) => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId()}/reviewCycles/${id}/publish`,
  };

  return postApi(serverUrl, {}, {});
};

export const usePublishCycle = ({ queryKey = [getOrganizationId(), 'reviewCycles'], cycleId }) => {
  const toastId = useRef(null);

  const mutation = useMutation({
    mutationFn: publishCycle,
    onMutate: () => {
      toastId.current = toast.info('Publishing the review cycle...', { autoClose: false });
    },
    onError: (error) => {
      toast.update(toastId.current, {
        render: error.toString() ?? 'There was an error publishing the review cycle. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Review cycle published successfully.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey });
      await queryClient.invalidateQueries({ queryKey: [...queryKey, cycleId] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), getOrganizationUserId(), 'reviews', 'list', 'reviewCycles'] });
    },
  });

  return mutation.mutate;
};
