import { css } from '@emotion/react';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';
import LeadrSwitch from '~Common/V3/components/LeadrSwitch';
import { InputLabel } from '@mui/material';
import {
  useCallback, ChangeEventHandler, ChangeEvent, ComponentProps,
} from 'react';
import { palette } from '~Common/styles/colors';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { Profile, SpecialDaySummaryEmailFilter } from '~Common/const/interfaces';
import { useUpdateUserSettings } from '~Deprecated/hooks/profile/useUserProfile';
import { useDebounceDraftState } from '~Common/hooks/useDebounceDraftState';
import { SelectChangeEvent } from '@mui/material/Select';

const styles = {
  settingsSectionHeader: css({
    marginBottom: '1.5rem',
  }),
  subHeadingText: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    marginBottom: '1.5rem',
  }),
  anniversarySettings: css({
    marginTop: '1.5rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  enableSummaryEmail: boolean,
  initialReminderFilter: SpecialDaySummaryEmailFilter,
  reminderFilterOptions: DropdownItem<string>[],
  handleReminderFilterChange: (event: SelectChangeEvent<string>) => void,
  handleEnableSummaryEmailChange: ChangeEventHandler<HTMLInputElement>,
}

const View = ({
  enableSummaryEmail,
  initialReminderFilter,
  reminderFilterOptions,
  handleReminderFilterChange,
  handleEnableSummaryEmailChange,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <SettingsSectionHeader
      css={styles.settingsSectionHeader}
      renderContents={() => (
        <>Email Notifications</>
      )}
    />
    <LeadrSwitch data-test-id="settingsEnableUpcomingSummary">
      <LeadrSwitch.Label label="Receive an &quot;Upcoming Week&quot; summary on Fridays.">
        <LeadrSwitch.Switch
          onChange={handleEnableSummaryEmailChange}
          defaultChecked={enableSummaryEmail}
        />
      </LeadrSwitch.Label>
    </LeadrSwitch>
    <SettingsSectionHeader
      css={styles.anniversarySettings}
      renderContents={() => (
        <>Birthdays and Work Anniversaries</>
      )}
    />
    <p css={styles.subHeadingText}>Whose birthdays and work anniversaries would you like to be reminded about?</p>
    <Dropdown
      items={reminderFilterOptions}
      onChange={handleReminderFilterChange}
      value={initialReminderFilter}
      renderLabel={() => (
        <InputLabel>
          Reminder Filter
        </InputLabel>
      )}
    />
  </div>
);

const reminderFilterOptions = [{
  text: 'Everyone',
  value: SpecialDaySummaryEmailFilter.Everyone,
},
{
  text: 'Your Department',
  value: SpecialDaySummaryEmailFilter.Department,
},
{
  text: 'Your Direct Reports',
  value: SpecialDaySummaryEmailFilter.Directs,
},
{
  text: 'People You Interact With',
  value: SpecialDaySummaryEmailFilter.Downline,
},
{
  text: 'No-one',
  value: SpecialDaySummaryEmailFilter.None,
}];

interface EmailNotificationsSettingsProps extends ComponentProps<'div'> {
  userProfile: Profile,
}

const EmailNotificationsSettings = ({
  userProfile,
  ...props
}: EmailNotificationsSettingsProps): JSX.Element => {
  const { updateUserSettings } = useUpdateUserSettings({ silent: false });
  const { enableSummaryEmail: initialEnableSummaryEmail } = userProfile.orgUserSettings;

  const updateEnableSummaryEmail = useCallback((newValue: boolean) => {
    updateUserSettings({ enableSummaryEmail: newValue });
  }, [updateUserSettings]);

  const [enableSummaryEmail, setEnableSummaryEnable] = useDebounceDraftState(initialEnableSummaryEmail, updateEnableSummaryEmail, 500);

  const handleEnableSummaryEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEnableSummaryEnable(event.target.checked);
  };

  const initialReminderFilter = userProfile.orgUserSettings.includeSpecialDaysInSummaryEmailFor || reminderFilterOptions[0].value;

  const handleReminderFilterChange = (event: SelectChangeEvent<string>): void => {
    const { orgUserSettings } = userProfile;
    const newOrgUserSettings = { ...orgUserSettings, includeSpecialDaysInSummaryEmailFor: event.target.value };
    updateUserSettings(newOrgUserSettings);
  };

  const hookProps = {
    enableSummaryEmail,
    handleReminderFilterChange,
    handleEnableSummaryEmailChange,
    initialReminderFilter,
    reminderFilterOptions,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default EmailNotificationsSettings;
