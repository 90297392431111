import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';
import { DataGridPro, DataGridProProps, GridCellParams } from '@mui/x-data-grid-pro';
import { palette } from '~Common/styles/colors';

const StyledDataGrid = styled(DataGridPro)({
  color: palette.neutrals.gray800,
  border: 0,

  '.leadrGridRow': {
    height: '4.375rem',
  },
  '.leadrGridCell': {
    height: '100%',
    lineHeight: '1.5rem',
    display: 'flex',
    alignItems: 'center',
  },
  '.leadrGridTitleCell': {
    paddingLeft: 0,
  },
  '.leadrGridCellDepth1': {
    backgroundColor: palette.neutrals.cardBackground,
  },
  '.leadrGridCellDepth2': {
    backgroundColor: palette.neutrals.gray100,
  },
  '.leadrGridCellDepth3': {
    backgroundColor: palette.neutrals.gray200,
  },

  '& .MuiDataGrid-columnHeader': {
    backgroundColor: palette.neutrals.gray100,
    border: 0,
    borderRadius: 0,
  },
  '&.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '&.MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
  '&.linearProgress': {
    backgroundColor: palette.brand.indigo,
    '&.MuiLinearProgress-bar1': {
      backgroundColor: palette.brand.indigo,
    },
    '&.MuiLinearProgress-bar2': {
      backgroundColor: palette.brand.indigo,
    },
  },
});

const getCellClassName = ({ rowNode }: GridCellParams): string => {
  const className = `leadrGridCell leadrGridCellDepth${rowNode.depth}`;
  return className;
};
const getRowClassName = (): string => 'leadrGridRow';

const StyledGoalsTable = ({ ...props }: DataGridProProps): JSX.Element => (
  <StyledDataGrid
    getCellClassName={getCellClassName}
    getRowClassName={getRowClassName}
    autoHeight
    columnHeaderHeight={38}
    rowHeight={70}
    hideFooter
    sx={{
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
    }}
    slots={{
      loadingOverlay: () => <LinearProgress />,
    }}
    {...props}
  />
);

export default StyledGoalsTable;
