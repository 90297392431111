import { css } from '@emotion/react';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import { Moment } from 'moment-timezone';
import { ComponentProps } from 'react';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { palette } from '~Common/styles/colors';

const styles = {
  button: css({
    borderRadius: '0.5rem',
    height: '3rem',
    width: '3rem',
    fontSize: '0.75rem',
    padding: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0.5rem',
  }),
  disabledButton: css({
    color: palette.neutrals.gray400,
    border: `1px solid ${palette.neutrals.gray400}`,
  }),
  recurrenceIcon: (showRecurrence: boolean) => css({
    color: showRecurrence ? palette.brand.indigo : palette.neutrals.gray600,
    border: `1px solid ${palette.neutrals.gray600}`,
    borderRadius: '0.5rem',
    height: '3rem',
    width: '3rem',
    fontSize: '0.75rem',
    padding: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0.5rem',
  }, showRecurrence && ({
    color: palette.brand.indigo,
    border: `1px solid ${palette.brand.indigo}`,
  })),

};

// ToDo: Update to use LeadrButton
interface RecurrenceToggleComponentProps extends ComponentProps<'button'> {
  dueDate?: Moment | Date | null;
  showRecurrence: boolean;
  onClick: () => void;
}

const RecurrenceToggleComponent = ({
  showRecurrence,
  dueDate,
  onClick,
  ...props
}: RecurrenceToggleComponentProps):JSX.Element => (
  <div>
    { !dueDate && (
      <IconButton
        data-test-id="recurrence-toggle-disabled"
        tooltip="Select a due date to make this a recurring action item"
        type="button"
        icon={faArrowsRepeat}
        onClick={null}
        css={[styles.button, styles.disabledButton]}
        {...props}
      />
    )}
    { dueDate && (
      <IconButton
        data-test-id="recurrence-toggle-active"
        tooltip={showRecurrence ? 'This will be a recurring action item' : 'Make this a recurring action item'}
        type="button"
        css={[styles.button, styles.recurrenceIcon(showRecurrence)]}
        icon={faArrowsRepeat}
        onClick={onClick}
        {...props}
      />
    )}
  </div>
);

export default RecurrenceToggleComponent;
