import {
  Dispatch, SetStateAction, useState,
} from 'react';
import { css } from '@emotion/react';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '~Admin/Importer/Const/pageStyles';
import { ImportStepper } from '~Admin/Importer/Components/AdminStepper';
import {
  AdminImporterTabValues, CSVPerspective, ResponseDataShape,
} from '~Admin/Importer/Const/types';
import Card from '~Common/V3/components/Card';
import ThreeDotLoader from '~Common/V3/components/ThreeDotLoader';
import { useLocalStorage } from '~Common/hooks/useLocalStorage';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import CSVFileUploadInterface from '../Components/CSVFileImporterInterface';
import { defaultDraftData } from '../Const/defaults';
import CSVFilePreviewInterface from '../Components/CSVFilePreviewInterface';
import { useFilterImportData } from '../Hooks/useFilterImportData';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
  textBackButton: css({
    fontSize: '1rem',
  }),
};

interface ViewProps {
  importPerspective: CSVPerspective,
  isFileUploading: boolean,
  setIsFileUploading: Dispatch<SetStateAction<boolean>>,
  setImportPerspective: (value: CSVPerspective) => void,
  hasDraftData: boolean,
  filteredImportData: ResponseDataShape | ResponseDataShape[],
  originalDraftData: ResponseDataShape | ResponseDataShape[],
  setData: (newState: ResponseDataShape) => void,
  setDataResponseView: (response: ResponseDataShape[], fileUploading: boolean, perspective: CSVPerspective) => void,
}

const View = ({
  importPerspective,
  isFileUploading,
  setIsFileUploading,
  setImportPerspective,
  hasDraftData,
  filteredImportData,
  originalDraftData,
  setData,
  setDataResponseView,
}: ViewProps): JSX.Element => (
  <>
    <div css={styles.setupMainContainer}>
      <Card
        css={styles.baseCard(true)}
        renderContents={() => (
          <ImportStepper
            importPerspective={importPerspective}
          />
        )}
      />
      <Card
        css={styles.baseCard(false)}
        renderContents={() => (
          <>
            {!isFileUploading && (
              <>
                {importPerspective === CSVPerspective.Upload && (
                  <CSVFileUploadInterface
                    setIsFileUploading={setIsFileUploading}
                    setDataResponseView={setDataResponseView}
                  />
                )}
                {importPerspective === CSVPerspective.Preview && hasDraftData && (
                <CSVFilePreviewInterface
                  filteredImportData={filteredImportData as ResponseDataShape[]}
                  originalDraftData={originalDraftData as ResponseDataShape[]}
                  setData={setData}
                  setImportPerspective={setImportPerspective}
                />
                )}
              </>
            )}
            {isFileUploading && (
              <ThreeDotLoader
                renderContents={() => (
                  <>Uploading File...</>
                )}
              />
            )}
          </>
        )}
      />
    </div>
  </>
);

const ImporterDashboard = (): JSX.Element => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const buildDraftKey = (draftKey: string): string => `draft-${draftKey}`;
  const stringKey = buildDraftKey('adminImporter');
  const [draft, setData] = useLocalStorage<ResponseDataShape | ResponseDataShape[]>(stringKey, defaultDraftData);
  const hasDraftData = Array.isArray(draft);
  const [importPerspective, setImportPerspective] = useState(hasDraftData ? CSVPerspective.Preview : CSVPerspective.Upload);
  const storeResponseData = (data: ResponseDataShape | ResponseDataShape[]): void => {
    setData(data);
  };
  const setDataResponseView = (response: ResponseDataShape[], fileUploading: boolean, perspective: CSVPerspective): void => {
    storeResponseData(response);
    setIsFileUploading(fileUploading);
    setImportPerspective(perspective);
  };

  const [importTab] = useQueryParamState('admin', 'tabView', AdminImporterTabValues.Successful);
  const getFilteredImportData = useFilterImportData(hasDraftData ? draft : [], importTab);

  const hookProps = {
    importPerspective,
    isFileUploading,
    setIsFileUploading,
    setImportPerspective,
    hasDraftData,
    originalDraftData: draft,
    filteredImportData: getFilteredImportData,
    setData,
    setDataResponseView,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ImporterDashboard };
export default ImporterDashboard;
