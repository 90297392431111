import { useCallback } from 'react';
import { useGenericStore } from '~Common/hooks/useGenericStore';
import { useLocalStorage } from '~Common/hooks/useLocalStorage';

// This is a hook for getting the reactiveness of generic store, but getting the persistance of local storage

interface GenericStorageAndLocalStorageProps <StateType>{
  localStorageKey: string,
  defaultValue: StateType,
  genericStoreProps: {
    module: string,
    workflow: string,
    feature: string,
  }
}

export const useGenericStoreAndLocalStorage = <StateType>({
  localStorageKey,
  defaultValue,
  genericStoreProps,
}: GenericStorageAndLocalStorageProps<StateType>): [StateType, (value: StateType) => void] => {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage<StateType>(localStorageKey, defaultValue);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { setValue: setGenericStoreValue, value: genericStoreValue } = useGenericStore({
    ...genericStoreProps,
    defaultValue: localStorageValue,
  });

  const setValue = useCallback((value: StateType): void => {
    setLocalStorageValue(value);
    setGenericStoreValue(value);
  }, [setGenericStoreValue, setLocalStorageValue]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [
    genericStoreValue,
    setValue,
  ];
};
