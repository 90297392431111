import moment from 'moment-timezone';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import EngagementBreakdownCard from '~Insights/components/cards/EngagementBreakdownCard';
import ENPSSmallCard from '~Insights/components/cards/ENPSSmallCard';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { useEngagementDataForDateRange } from '~Insights/hooks/useEngagementData';
import { useInsightsUserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { INSIGHTS_BASE_ROUTE } from '~Common/const/routes';
import { defualtMonthsToSubtract } from '~Insights/const/defaults';

const styles = {
  header: css({
    marginBottom: '0.5rem',
  }),
  engagement: css({
    marginBottom: '0.5rem',
  }),
  cardOverride: css({
    boxShadow: 'none !important',
    padding: 0,
  }),
};

interface HomeInsightsCardViewProps extends Omit<LeadrCardProps, 'children'> {
  insightsLoading: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const View = ({
  insightsLoading,
  dragHandleProps,
  ...props
}: HomeInsightsCardViewProps): JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <ModuleHeader
      css={styles.header}
      headerText="Insights"
      {...dragHandleProps}
    />

    {insightsLoading && (
      <HomeSkeleton
        numberOfSkeletons={2}
        height={10}
      />
    )}

    {!insightsLoading && (
      <>
        <EngagementBreakdownCard
          size="dashboard"
          css={styles.cardOverride}
        />
        <ENPSSmallCard
          size="dashboard"
          css={styles.cardOverride}
        />
      </>
    )}

    <ModuleFooter
      renderFooterButton={(params) => (
        <LeadrButton
          data-test-id="viewAllInsights"
          component={Link}
          to={INSIGHTS_BASE_ROUTE}
          variant="text"
          size="small"
          {...params}
        >
          View Insights
        </LeadrButton>
      )}
    />
  </LeadrCard>
);

export interface HomeInsightsCardProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const HomeInsightsCard = ({
  dragHandleProps,
  ...props
}: HomeInsightsCardProps): JSX.Element => {
  const { userPerspective } = useInsightsUserPerspective();
  const { isLoading } = useEngagementDataForDateRange({
    startDate: moment().subtract(defualtMonthsToSubtract, 'months').startOf('day').toDate(),
    endDate: moment().startOf('day').toDate(),
    userPerspective,
  });
  const [insightsLoading] = useSkeletonLoaders(isLoading);

  const hookProps = {
    insightsLoading,
    dragHandleProps,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default HomeInsightsCard;
