import { css } from '@emotion/react';
import { Profile } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';
import { getUserId } from '~Common/utils/localStorage';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import CalendarSettings from './CalendarSettings';
import EmailNotificationsSettings from './EmailNotificationsSettings';
import LoggingInSettings from './LoggingInSettings';
import TimezoneSettings from './TimezoneSettings';
import SettingsSection from '../Admin/SettingsSection';

const styles = {
  accountSettings: css({
  }),
  settingsSection: css({
    marginTop: '1.5rem',
    '&:not(:last-of-type)': {
      paddingBottom: '1.5rem',
      borderBottom: `1px solid ${palette.neutrals.gray200}`,
    },
  }),
};

interface ViewProps {
  userProfile: Profile | undefined,
  isLimitedAccessUser: boolean,
}

const View = ({
  userProfile,
  isLimitedAccessUser,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.accountSettings}
    {...props}
  >
    {userProfile && (
      <>
        {!isLimitedAccessUser && (
          <SettingsSection
            css={styles.settingsSection}
            renderSection={(settingsSectionStyles) => (
              <CalendarSettings css={settingsSectionStyles} />
            )}
          />
        )}
        <SettingsSection
          css={styles.settingsSection}
          renderSection={(settingsSectionStyles) => (
            <LoggingInSettings css={settingsSectionStyles} />
          )}
        />
        <SettingsSection
          css={styles.settingsSection}
          renderSection={(settingsSectionStyles) => (
            <EmailNotificationsSettings css={settingsSectionStyles} userProfile={userProfile} />
          )}
        />
        <SettingsSection
          css={styles.settingsSection}
          renderSection={(settingsSectionStyles) => (
            <TimezoneSettings css={settingsSectionStyles} userProfile={userProfile} />
          )}
        />
      </>
    )}
  </div>
);

const AccountSettings = (): JSX.Element => {
  const currentUserId = getUserId() ?? '';
  const { isLimitedAccess: isLimitedAccessUser } = useUserPermissions();
  const { user: userProfile } = useUserProfile(currentUserId);

  const hookProps = {
    userProfile,
    isLimitedAccessUser,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default AccountSettings;
