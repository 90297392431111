import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-light-svg-icons';

import { MEETING_TYPES } from '~Meetings/const/meetingTypes';
import DetailsAttendees from '~Meetings/components/attendees/DetailsAttendees';
import { useGetPeopleByList, useGetPersonById as usePeoplePickerById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { MAX_MEETING_ATTENDEE_COUNT } from '~Common/const/constraints';
import {
  palette,
} from '~Common/styles/colors';
import BaseballCard from '~People/BaseballCard';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { participantViewerTemplate } from '~Meetings/components/drawers/ParticipantViewer';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useImpersonationStore } from '~Deprecated/hooks/useImpersonationStore';
import { meetingRightPanelHeader, meetingRightPanelHeaderButton } from '~Meetings/const/sharedStyles';
import { NonLeadrUser } from '~Common/const/classes';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { withTruncate } from '~Common/styles/mixins';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import AuditTrail from './AuditTrail';
import { newEditMeetingTemplate } from '../drawers/EditMeeting';
import { DesktopMeetingNotesSection } from './meetingNotes/DesktopMeetingNotesSection';
import { MeetingHostItem } from '../attendees/MeetingHostItem';
import WorkingGeniusMap from './WorkingGeniusMap';

const styles = {
  container: css`
    width: 300px;
  `,
  teamMeetingContainer: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  `,
  baseballCard: css`
    background-color: ${palette.neutrals.white};
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);
    margin-bottom: 1.5rem;
  `,
  attendeeListSkeleton: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  attendeeSkeleton: css`
    margin-right: 10px;
    margin-bottom: 5px;
  `,
  noteButton: css`
    width: 100%;
    font-size: 10px;
    margin-left: 0px !important;
    margin-bottom: 20px;
    color: ${palette.neutrals.gray500};
    background-color: ${palette.neutrals.white};
    text-transform: uppercase;
    justify-content: center;
    &:focus {
      background-color: ${palette.neutrals.gray200};
    }
    &:hover {
      box-shadow: none !important;
      background-color: ${palette.neutrals.gray200};
    }
  `,
  moreButton: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '.75rem',
  }),
  owner: css`
    background-color: ${palette.neutrals.white};
    color: ${palette.neutrals.gray800} !important;
    box-shadow: 0px 4px 8px -2px rgba(28, 42, 55, 0.2);
    &:focus {
      background-color: ${palette.neutrals.gray200};
    }
    &:hover {
      background-color: ${palette.neutrals.gray200};
      border: none;
      .attendeeName {
          color: ${palette.neutrals.black} !important;
        }
    }
  `,
  editButton: css({
    color: palette.brand.indigo,
  }),

  teamIcon: css({
    marginRight: '0.75rem',
    fontSize: '1.5rem',
    color: palette.neutrals.gray800,
  }),

  teamNameContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),

  teamName: css({
    fontWeight: 600,
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }, withTruncate()),
};

const View = ({
  id,
  type,
  isCoaching,
  avatarList,
  showAllAttendeeClick,
  onOwnerClick,
  ownerId,
  displayOnPage,
  isLoading,
  shouldShowAuditTrail,
  onEditClick,
  isEditOptionAvailable,
  meetingFactoryId,
  showPrivateMeetingNotes,
  teamName,
  shouldShowWorkGeniusMap,
  ...props
}) => (
  <div
    css={styles.container}
    {...props}
  >
    {type !== MEETING_TYPES.COACHING && (
      <div
        css={styles.teamMeetingContainer}
      >
        {showPrivateMeetingNotes && (
          <DesktopMeetingNotesSection
            meetingFactoryId={meetingFactoryId}
          />
        )}

        { teamName && (
          <div>
            <header css={meetingRightPanelHeader}>
              <p>Team</p>
            </header>

            <div css={styles.teamNameContainer}>
              <FontAwesomeIcon
                css={styles.teamIcon}
                icon={faUsers}
              />

              <span css={styles.teamName}>
                { teamName }
              </span>
            </div>
          </div>
        )}

        <div>
          <header css={meetingRightPanelHeader}>
            <p>Meeting Host</p>
          </header>
          <MeetingHostItem
            huddleId={id}
            orgUserId={ownerId}
            onClick={onOwnerClick}
          />
        </div>

        <div>
          <header css={meetingRightPanelHeader}>
            <p>
              {`Meeting Participants [${avatarList.length}]`}
            </p>

            {avatarList.length > displayOnPage && (
              <LeadrButton
                css={meetingRightPanelHeaderButton}
                onClick={showAllAttendeeClick}
                variant="text"
              >
                VIEW ALL
              </LeadrButton>
            )}
          </header>

          {isLoading && (
            <MultipleSkeletonLoaders
              numberOfSkeletons={21}
              css={styles.attendeeListSkeleton}
              renderSkeletonItem={() => (
                <SkeletonLoader
                  variant="rectangular"
                  width={30}
                  height={30}
                  css={styles.attendeeSkeleton}
                  renderComponent={() => (
                    <div />
                  )}
                />
              )}
            />
          )}

          {!isLoading && (
            <DetailsAttendees
              id={id}
              avatarList={avatarList}
              displayOnPage={displayOnPage}
              onShowMore={showAllAttendeeClick}
            />
          )}
        </div>

        { shouldShowAuditTrail && (
          <AuditTrail id={id} type={type} />
        )}

        {shouldShowWorkGeniusMap && (
          <WorkingGeniusMap />
        )}
      </div>
    )}

    {type === MEETING_TYPES.COACHING && (
      <>
        <div
          css={styles.baseballCard}
        >
          <BaseballCard
            huddleId={id}
            huddleType={isCoaching ? MEETING_TYPES.COACHING : MEETING_TYPES.MEETING}
            css={styles.baseballCard}
          />
        </div>

        { shouldShowAuditTrail && (
          <AuditTrail id={id} type={type} />
        )}
      </>
    )}
  </div>
);

View.propTypes = {
  id: PropTypes.string.isRequired,
  avatarList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCoaching: PropTypes.bool.isRequired,
  showAllAttendeeClick: PropTypes.func.isRequired,
  onOwnerClick: PropTypes.func.isRequired,
  ownerId: PropTypes.string,
  type: PropTypes.oneOf(MEETING_TYPES).isRequired,
  displayOnPage: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  shouldShowAuditTrail: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  isEditOptionAvailable: PropTypes.bool,
  meetingFactoryId: PropTypes.string,
  showPrivateMeetingNotes: PropTypes.bool.isRequired,
  teamName: PropTypes.string,
  shouldShowWorkGeniusMap: PropTypes.bool.isRequired,
};

View.defaultProps = {
  isLoading: true,
  shouldShowAuditTrail: false,
  isEditOptionAvailable: true,
  teamName: '',
  meetingFactoryId: '',
  ownerId: '',
};

const MeetingRightPanel = ({
  id,
  type,
  limit,
  teamId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isLoading, item } = useMeetingDetails({ id, type }) ?? {};
  const { data: teamDetailsData, isLoading: areTeamDetailsLoading } = useGetTeamDetails({ teamId, huddleId: id });

  const teamName = useMemo(() => (
    teamDetailsData?.response.name
  ), [teamDetailsData?.response.name]);

  const { organizer, attendeeOrgUserIds = [] } = item ?? {};
  const { isLoading: isLoadingPeople } = usePeoplePickerById({ id: organizer });
  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading || isLoadingPeople || areTeamDetailsLoading);
  const isMeetingAuditFlagEnabled = useFeatureFlag('webClientEnableConnectedMeetingAuditing');
  const { isImpersonating } = useImpersonationStore();
  const shouldShowAuditTrail = isMeetingAuditFlagEnabled || isImpersonating;

  const isCoaching = type === MEETING_TYPES.COACHING;
  const showPrivateMeetingNotes = useFeatureFlag('webClientPrivateTeamMeetingNotes');

  const avatarList = useGetPeopleByList({
    selectedIds: attendeeOrgUserIds,
  });

  const { enableTheTable } = useOrgDetailsContext();
  const tgxWorkingGeniusMap = useFeatureFlag('tgxWorkingGeniusMap');

  const shouldShowWorkGeniusMap = enableTheTable && tgxWorkingGeniusMap && !isCoaching;

  const showAllAttendeeClick = () => {
    dispatch(pushDrawerAction({
      drawer: {
        ...participantViewerTemplate,
        args: {
          onSelect: (participantId) => {
            dispatch(pushDrawerAction({
              drawer: {
                ...baseballCardDrawerTemplate,
                args: {
                  id: participantId,
                  backdropStyles: css({
                    backdropFilter: 'none',
                    position: 'unset',
                    width: 0,
                    height: 0,
                  }),
                },
              },
            }));
          },
          useOrgIds: true,
          allowSelf: true,
          customFilter: attendeeOrgUserIds,
        },
      },
    }));
  };

  const onOwnerClick = () => {
    dispatch(pushDrawerAction({
      drawer: {
        ...baseballCardDrawerTemplate,
        args: {
          id: organizer?.orgUserId,
          backdropStyles: css({
            backdropFilter: 'none',
            position: 'unset',
            width: 0,
            height: 0,
          }),
        },
      },
    }));
  };

  const onEditClick = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...newEditMeetingTemplate,
        args: {
          id,
          type,
        },
      },
    }));
  }, [dispatch, id, type]);

  const nonLeadrAttendees = item?.nonLeadrAttendees?.map((user) => new NonLeadrUser(user.email)) ?? [];

  const hookProps = {
    ownerId: organizer?.orgUserId,
    onOwnerClick,
    showAllAttendeeClick,
    id,
    avatarList: [...avatarList, ...nonLeadrAttendees],
    isCoaching,
    isLoading: showSkeletonLoaders,
    type,
    shouldShowAuditTrail,
    onEditClick,
    meetingFactoryId: item?.factoryId,
    showPrivateMeetingNotes,
    teamName,
    shouldShowWorkGeniusMap,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

MeetingRightPanel.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  avatarList: PropTypes.arrayOf(PropTypes.object).isRequired,
  limit: PropTypes.shape({ MAX_MEETING_ATTENDEE_COUNT }),
  displayOnPage: PropTypes.number,
  teamId: PropTypes.string,
};

MeetingRightPanel.defaultProps = {
  limit: null,
  displayOnPage: 23,
  teamId: undefined,
};

export { View };
export default MeetingRightPanel;
