import { useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@mui/material/Divider';
import Menu, { MenuProps } from '@mui/material/Menu';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import { withTruncate } from '~Common/styles/mixins';
import { camelCase } from 'lodash';

const styles = {
  menu: css({
    display: 'flex',
    '.MuiMenu-list': {
      width: '16.8125rem',
      padding: '.625rem 0',
    },
    '.MuiPaper-root': {
      width: '16.8125rem',
    },
  }),
  menuItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '.375rem 1.5rem',
    minHeight: '12px !important',
    fontWeight: '500',
  }),
  menuIcon: (isDeactivated: boolean | undefined) => css({
    color: isDeactivated ? palette.neutrals.gray400 : palette.brand.indigo,
    width: '1rem',
  }),
  menuText: css({
    fontSize: '.875rem',
  }, withTruncate()),
  deactivatedStyle: css({
    color: palette.neutrals.gray400,
    cursor: 'not-allowed',
  }),
  subTitle: css({
    color: palette.neutrals.gray500,
    fontSize: '.875rem',
    fontWeight: 400,
    fontStyle: 'italic',
    padding: '0 1.5rem',
    margin: '.5rem 0',
  }),
};

export interface MenuItemStyles {
  menuItem: SerializedStyles,
  menuText: SerializedStyles,
  iconColor: string,
}

export interface MenuItem {
  text: string,
  icon: IconProp,
  onClick: () => void,
  tooltip?: string,
  isDeactivated?: boolean,
  styles?: MenuItemStyles,
  dataTestId?: string,
}

export interface ActionMenuProps extends Omit<MenuProps, 'open'> {
  menuItems: MenuItem[][],
  anchorEle: HTMLElement | null,
  onClose: () => void,
  subTitle?: string,
}

const ActionMenu = ({
  menuItems, anchorEle, onClose, subTitle, ...props
}: ActionMenuProps): JSX.Element => (
  <Menu
    css={styles.menu}
    anchorEl={anchorEle}
    keepMounted
    onClose={onClose}
    open={!!anchorEle}
    {...props}
  >
    {menuItems.map((itemGroup, index) => (
      <div
        // disabling this because the top level is a grouping that is going to be static most of the time
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        {subTitle && (
          <p css={styles.subTitle}>{subTitle}</p>
        )}
        {itemGroup.map((item) => (
          <Tooltip
            interactive={false}
            content={item.tooltip}
          >
            <MenuItem
              css={[styles.menuItem, (item.isDeactivated && styles.deactivatedStyle), item.styles?.menuItem]}
              onClick={item.onClick}
              key={item.text}
              data-test-id={item.dataTestId ?? `actionMenuItem-${camelCase(item.text)}`}
            >
              <div css={[styles.menuText, item.styles?.menuText]}>
                {item.text}
              </div>
              <div css={styles.menuIcon(item.isDeactivated)}>
                <FontAwesomeIcon
                  icon={item.icon}
                  color={item.styles?.iconColor}
                />
              </div>
            </MenuItem>
          </Tooltip>
        ))}
        {index < menuItems.length - 1 && menuItems.length > 1 && (
          <Divider />
        )}
      </div>
    ))}
  </Menu>
);

interface UseActionMenuReturn {
  anchorEle: HTMLElement | null,
  doOpen: (event: React.MouseEvent<HTMLElement>) => void,
  onClose: () => void,
}

const useActionMenu = (): UseActionMenuReturn => {
  const [anchorEle, setAnchorEle] = useState<HTMLElement | null>(null);

  const doOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEle(event.currentTarget);
  };

  const onClose = (): void => {
    setAnchorEle(null);
  };

  return {
    anchorEle,
    doOpen,
    onClose,
  };
};

export { useActionMenu };
export default ActionMenu;
