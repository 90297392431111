import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { palette, hexToRGBA } from '~Common/styles/colors';
import { withLineClamp } from '~Common/styles/mixins';
import { BASEBALL_CARD_CALLOUT_SHAPE } from '~Common/const/proptypes';
import { faPartyHorn, faBirthdayCake } from '@fortawesome/pro-light-svg-icons';

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    padding: '.75rem 1.25rem',
    background: hexToRGBA(palette.brand.sky, 0.1),
  }),
  callout: css({
    color: palette.brand.indigo,
    marginInlineStart: '.625rem',
    fontSize: '.75rem',
  }, withLineClamp(2)),
  icon: css({
    width: '1.25rem !important',
    height: '1.25rem !important',
    color: palette.brand.indigo,
  }),
};

const typeToIcon = {
  birthday: faBirthdayCake,
  anniversary: faPartyHorn,
};

const NotificationBanner = ({ callout, className }) => (
  <div css={styles.container} className={className}>
    <FontAwesomeIcon
      icon={typeToIcon[callout.type]}
      css={styles.icon}
    />
    <span css={styles.callout}>{ callout.message }</span>
  </div>
);

NotificationBanner.propTypes = {
  callout: BASEBALL_CARD_CALLOUT_SHAPE.isRequired,
  className: PropTypes.string,
};

NotificationBanner.defaultProps = {
  className: '',
};

export default NotificationBanner;
