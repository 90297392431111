import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import moment from 'moment-timezone';

import TextField from '~Common/components/Fields/TextField';
import { FEEDBACK_SHAPE } from '~Common/const/proptypes';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import { useRecallIndividualFeedback } from '~Deprecated/hooks/feedback/useRecallIndividualFeedback';
// eslint-disable-next-line import/no-cycle
import UndoButton, { BUTTON_COLOR, BUTTON_VARIANT } from '~Common/components/Buttons/Undo';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';
import { useHistory } from 'react-router-dom';
import { ADMIN_REVIEWS_REFLECTIONS_V2 } from '~Common/const/routes';
import { requestorFeedbackDetailTemplate } from '../RequestorDetail/RequestorDetailDrawer';
import Requestor from '../../Cards/Requestor';
import PersonDisplay from './PersonDisplay';

const styles = {
  drawerInput: css`
    padding: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
  `,
  disabledRichText: css`
    font-size: 16px;
  `,
  person: css`
    margin: 8px 0;
  `,
};

const View = ({
  feedback, isRequestor, renderRightButton, renderEditor,
}) => (
  <>
    <TextField
      name="title"
      disabled
      css={styles.drawerInput}
      label="Title"
      initialValue={feedback?.title || ''}
      rows="1"
    />
    {feedback?.introduction && (
      <>
        {renderEditor()}
      </>
    )}
    <TextField
      name="createdDate"
      disabled
      css={styles.drawerInput}
      label="Requested On"
      initialValue={moment(feedback?.requestedDateInMillis).format('MMM D, YYYY') || ''}
      rows="1"
    />
    {feedback?.dueDate && (
      <TextField
        name="dueDate"
        disabled
        css={styles.drawerInput}
        label="Requested By"
        initialValue={moment(feedback.dueDate).format('MMM D, YYYY') || ''}
      />
    )}
    {feedback?.assignees && isRequestor && (
      <>
        <SectionHeader text="Respondents" />
        {feedback?.assignees.map((assignee) => (
          <PersonDisplay
            key={assignee.id}
            userId={assignee.id}
            status={assignee.hasCompletedFeedback ? ANSWER_STATUS.COMPLETED : ANSWER_STATUS.INCOMPLETE}
            css={styles.person}
            renderRightButton={() => renderRightButton(assignee)}
          />
        ))}
      </>
    )}
    {feedback && !isRequestor && <Requestor requestor={feedback.owner} />}
  </>
);

View.propTypes = {
  feedback: FEEDBACK_SHAPE.isRequired,
  isRequestor: PropTypes.bool,
  renderRightButton: PropTypes.func.isRequired,
  renderEditor: PropTypes.func.isRequired,
};

View.defaultProps = {
  isRequestor: false,
};

const Details = ({ feedback, ...props }) => {
  const renderEditor = () => (
    <Froala
      label="Objective"
      name="introduction"
      initialValue={feedback?.introduction || ''}
      isReadOnly
      styleOverrides={[styles.drawerInput, styles.disabledRichText]}
    />
  );

  const recallIndividualFeedback = useRecallIndividualFeedback({ template: requestorFeedbackDetailTemplate });
  const recallButtonClick = (personId) => { recallIndividualFeedback({ feedback, recallForUsers: [personId] }); };
  const history = useHistory();
  const isReflectionsArea = history.location.pathname === ADMIN_REVIEWS_REFLECTIONS_V2;

  const renderRightButton = (assignee) => {
    if (assignee?.hasCompletedFeedback || feedback?.assignees?.length <= 1 || isReflectionsArea) {
      return null;
    }

    return (
      <UndoButton
        buttonColor={BUTTON_COLOR.DEFAULT}
        variant={BUTTON_VARIANT.ICON}
        css={styles.recallButton}
        tooltip="Recall"
        onClick={() => recallButtonClick(assignee.id)}
      />
    );
  };

  const hookProps = {
    renderRightButton,
    feedback,
    renderEditor,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

Details.propTypes = {
  feedback: FEEDBACK_SHAPE,
};

Details.defaultProps = {
  feedback: null,
};

export { View };
export default Details;
