import { createAction } from '@reduxjs/toolkit';
import { mutateState } from '~Deprecated/utils/reduxUtils';
import { registerAction } from '~Deprecated/reducers/drawers';
import { DRAWER_STATE } from '~Common/const/drawers';
import { stackDrawers } from '~Deprecated/utils/drawers/stackDrawers';

const popAfterAction = createAction('LEADR.POP_AFTER');

function onPopAfter(state, { payload }) {
  return mutateState(state, (draftState) => {
    const existingDrawerIndex = draftState.order?.findIndex((drawer) => drawer.name === payload.drawerName);
    if (existingDrawerIndex >= 0) {
      for (let i = existingDrawerIndex + 1; i < draftState.order.length; i += 1) {
        draftState.order[i].state = DRAWER_STATE.CLOSED;
      }
      const { stackedDrawers } = stackDrawers(draftState.order, 0);
      draftState.order = stackedDrawers;
    }
    return draftState;
  });
}

registerAction(popAfterAction, onPopAfter);

export {
  popAfterAction,
};
