import { Profile } from '~Common/const/interfaces';
import { OrgSettings } from '~Common/const/Organization';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';

const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

type CustomPersonalityKey = 'customPersonality1Name' | 'customPersonality2Name';

const getCustomPersonalities = (orgSettingEntries: [keyof OrgSettings, unknown]): [CustomPersonalityKey, string][] => {
  const customPersonalitySettings = orgSettingEntries.filter(
    // @ts-expect-error TS Mess
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    (customField) => customField[0].startsWith('customPersonality'),
  ) as [CustomPersonalityKey, string][];

  customPersonalitySettings.sort(
    ([personality1Key], [personality2Key]) => parseInt(personality1Key.replace(/[^0-9]/g, ''), 10) - parseInt(personality2Key.replace(/[^0-9]/g, ''), 10),
  );
  return customPersonalitySettings;
};

interface GetFormattedCustomFavoritesParams {
  user: Profile,
  customPersonalities: [CustomPersonalityKey, string][],
  orgSettings: OrgSettings,
}

interface FormattedCustomPersonality {
  title: string,
  valueName: string,
  urlValueName: string,
  value: string,
  link: string,
  enabled: boolean,
}

const getFormattedCustomPersonalities = ({
  user,
  customPersonalities,
  orgSettings,
}: GetFormattedCustomFavoritesParams): FormattedCustomPersonality[] => customPersonalities.map(([key, value]) => ({
  title: value,
  valueName: key.replace('Name', 'Value'),
  urlValueName: key.replace('Name', 'UrlValue'),
  value: user.orgUserProfile[key.replace('Name', 'Value') as 'customPersonality1Value'],
  // @ts-expect-error TODO: This is some cursed code that I don't have time to rewrite or TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  link: user.orgUserProfile[key.replace('Name', 'UrlValue')],
  // @ts-expect-error TODO: This is some cursed code that I don't have time to rewrite or TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  enabled: orgSettings[`enable${capitalizeFirstLetter(key).replace('Name', '')}`],
})).filter((p) => !!p.enabled);

interface UseGetFormattedCustomPersonalitiesReturn {
  isLoading: boolean,
  formattedCustomPersonalities: FormattedCustomPersonality[],
}

export const useGetFormattedCustomPersonalityTypes = (userId: string): UseGetFormattedCustomPersonalitiesReturn => {
  const { orgSettings } = useOrgDetailsContext();
  const orgSettingsEntries = Object.entries(orgSettings) as [keyof OrgSettings, unknown];
  const { detail, isLoading } = useUserProfile(userId);
  const user = detail.data?.response;
  if (!user) {
    return {
      isLoading,
      formattedCustomPersonalities: [],
    };
  }
  const customPersonalities = getCustomPersonalities(orgSettingsEntries);
  const formattedCustomPersonalities = getFormattedCustomPersonalities({
    user,
    customPersonalities,
    orgSettings,
  });

  return {
    isLoading,
    formattedCustomPersonalities,
  };
};
