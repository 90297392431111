import { useSelector } from 'react-redux';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import { getFeatureFlags } from '~Deprecated/selectors/featureFlags/getFeatureFlags';
import { useInsightPrivileges } from '~Insights/hooks/useInsightPrivileges';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useIsDesktopQuery } from '~Common/hooks/useMediaListener';
import { useGetUserOrganizationList } from '~Root/hooks/useGetUserOrganizationList';
import { useShowGlobalSearchModal } from '~GlobalSearch/stores/useShowGlobalSearchModal';
import { useHasCSPermissions } from '~Common/hooks/permissions/useHasNexus';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { FeatureFlags, IsVisibleProps } from './types';

export function useNavigationPermissions(
  compact = false,
): IsVisibleProps {
  const {
    setShowModal,
  } = useShowGlobalSearchModal((state) => ({
    setShowModal: state.setShowModal,
  }));
  // TODO: figure out how to store feature flags outside of redux
  const flags = useSelector(getFeatureFlags) as FeatureFlags;
  const { isAdmin } = useUserPermissions();
  const organization = useOrgDetailsContext();
  const { canSeeInsights } = useInsightPrivileges();
  const { organizationInTrial } = useIsOrganizationInTrial();
  const { organizations } = useGetUserOrganizationList();

  const hasMultipleOrganizations = organizations?.length > 1;
  const { isImpersonator } = useOrgUserPermissionsContext();
  const canImpersonate = !!flags.whiteHatImpersonator && isImpersonator && !window.sessionStorage.getItem('sessionId');
  const canSeeNexus = useHasCSPermissions();
  const isMobile = !useIsDesktopQuery();

  return {
    isAdmin,
    compact,
    isMobile,
    canSeeInsights,
    canSeeNexus,
    canImpersonate,
    flags,
    hasMultipleOrganizations,
    organization,
    organizationInTrial,
    setShowSearchModal: setShowModal,
  };
}
