import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import {
  Competency, CompetencyResourceStatusType, PDPRoleType, ResourceType,
} from '~DevelopmentPlan/const/types';
import { pdpPlanKeys } from '../const/queryKeys';

interface GetCompetencyListProps {
  id: string,
}

const getCompetencyList = ({ id }: GetCompetencyListProps): Promise<HttpCallReturn<Competency[]>> => {
  const url = `/organizations/${getOrganizationId() ?? ''}/developmentplans/${id}/competencies`;

  return getApi<Competency[]>(url);
};

interface useGetCompentencyProps {
  queryKey?: string[],
  id: string,
}

interface useGetCompentencyReturnProps {
  data: HttpCallReturn<Competency[]> | undefined,
  isLoading: boolean,
  isError: boolean,
  isFetching: boolean;
}

export const useGetCompetencyList = ({ id }: useGetCompentencyProps): useGetCompentencyReturnProps => {
  const result = useQuery({
    queryKey: pdpPlanKeys.competencies(id),
    queryFn: () => getCompetencyList({ id }),
    placeholderData: keepPreviousData,

  });

  return {
    isLoading: result?.isLoading,
    isError: result?.isError,
    data: result?.data,
    isFetching: result.isFetching,
  };
};

export const testCompentencyList = [
  {
    createdBy: {
      orgUserId: '0xCO42AM3',
      firstName: 'Elbert',
      lastName: 'Little',
      profileImageUrl: 'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/720.jpg',
      jobTitle: 'Human Accountability Manager',
    },
    createdDate: new Date(),
    description: 'This skill would help me keep designs consistent across projects I work on.',
    id: 1523118867,
    isDeleted: false,
    modifiedDate: new Date(),
    name: 'Practical Fresh Salad',
    resources: [
      {
        id: 124981542,
        createdDate: new Date(),
        modifiedDate: new Date(),
        isDeleted: true,
        createdBy: {
          orgUserId: '0x9YFJ24G',
          firstName: 'Rodney',
          lastName: 'Gibson',
          profileImageUrl: 'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/710.jpg',
          jobTitle: 'Internal Configuration Strategist',
        },
        contentType: {
          id: 4,
          description: ResourceType[ResourceType.Feedback] as keyof ResourceType,
        },
        contentId: '345eb6f8-6667-a09f-d72e-859730884388',
        contentTitle: 'Voluptatem et tempora ea voluptatem quis eum mollitia.',
        contentDueDate: new Date(),
        status: {
          id: 3,
          description: CompetencyResourceStatusType.InProgress,
        },
        competencyId: 714076891,
        competencyName: 'Awesome Steel Shirt',
        role: {
          id: 0,
          description: PDPRoleType.Viewer,
        },
      },
      {
        id: 124981542,
        createdDate: new Date(),
        modifiedDate: new Date(),
        isDeleted: true,
        createdBy: {
          orgUserId: '0x9YFJ24G',
          firstName: 'Rodney',
          lastName: 'Gibson',
          profileImageUrl: 'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/710.jpg',
          jobTitle: 'Internal Configuration Strategist',
        },
        contentType: {
          id: 2,
          description: ResourceType[ResourceType.Goal] as keyof ResourceType,
        },
        contentId: '345eb6f8-6667-a09f-d72e-859730884388',
        contentTitle: 'Voluptatem et tempora ea voluptatem quis eum mollitia.',
        contentDueDate: new Date(),
        status: {
          id: 3,
          description: CompetencyResourceStatusType.InProgress,
        },
        competencyId: 714076891,
        competencyName: 'Awesome Steel Shirt',
        role: {
          id: 0,
          description: PDPRoleType.Mentor,
        },
      },
    ],
  },

];
