import { css } from '@emotion/react';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { palette } from '~Common/styles/colors';

const styles = {
  childGoalCount: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    gap: '0.25rem',
  }),
};

interface ChildGoalCountProps {
  totalChildGoals: number,
}

const ChildGoalCount = ({
  totalChildGoals,
  ...props
}: ChildGoalCountProps): JSX.Element => (
  <div
    css={styles.childGoalCount}
    {...props}
  >
    <span>{totalChildGoals}</span>
    <FontAwesomeIcon icon={faLayerGroup} />
  </div>
);

export default ChildGoalCount;
