import { css } from '@emotion/react';
import { stringify } from 'query-string';
import {
  ComponentProps, Dispatch, SetStateAction, useState,
} from 'react';
import { Link } from 'react-router-dom';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import ClampLines from '~Common/components/ClampLines';
import { BASE_ROUTE } from '~Common/const/routes';

import { palette } from '~Common/styles/colors';
import { lineClamp } from '~Common/styles/mixins';
import { HTMLString } from '~Common/types';
import { isHTMLText } from '~Common/utils/isHTMLText';
import { OPTIMISTIC_ID } from '~Recognition/const/defaults';
import { PAGE_STYLES } from '~Recognition/const/pageStyles';
import { InfiniteQueryParamsProps } from '~Recognition/const/types';

const styles = {
  body: css({
    display: 'flex',
    justifyContent: 'space-between',

  }),
  title: css({
    fontWeight: 600,
    fontSize: '1.125rem',
    color: palette.neutrals.gray700,
    marginBottom: '.5rem',
    minHeight: '3.4375rem',
  }, lineClamp(2)),
  infoContainer: css({
    marginRight: '.25rem',
    overflowY: 'auto',
    maxHeight: '18.75rem',
    height: '100%',
    width: '100%',
  }),
  recognitionDescription: (hasBeenEdited: boolean) => css({
    fontWeight: 400,
    fontSize: '1rem',
    color: palette.neutrals.gray900,
    margin: '.5rem 0 0 0',

    '& button': {
      border: 'none',
      background: 'none',
      color: palette.brand.indigo,
      fontWeight: 500,
      padding: '0',
      float: 'right',
    },
  }, hasBeenEdited && {
    '&:after': {
      content: '"(edited)"',
      color: palette.neutrals.gray700,
      fontSize: '12px',
      fontWeight: 400,
      fontStyle: 'italic',
    },
  }),
  ...PAGE_STYLES,
};

interface ViewProps extends ComponentProps<'div'> {
  descriptionHTML: HTMLString,
  showExpandedView?: boolean,
  hasBeenEdited: boolean,
  filters: InfiniteQueryParamsProps,
  recognitionId: number,
  setIsClamped: Dispatch<SetStateAction<boolean>>,
  isClamped: boolean,
}

const View = ({
  descriptionHTML,
  showExpandedView = false,
  hasBeenEdited,
  filters,
  recognitionId,
  setIsClamped,
  isClamped,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.body} {...props}>
    <div css={styles.infoContainer}>
      {(showExpandedView || isClamped || recognitionId === OPTIMISTIC_ID) && (
        <>
          {isHTMLText(descriptionHTML) && (
            <HTMLRenderer
              css={styles.recognitionDescription(hasBeenEdited)}
              htmlText={descriptionHTML}
              data-test-id="recognition-description"
            />
          )}
          {!isHTMLText(descriptionHTML) && (
            <p css={styles.recognitionDescription(hasBeenEdited)} data-test-id="recognition-description">
              {descriptionHTML}
            </p>
          )}
        </>
      )}
      {!showExpandedView && !isClamped && recognitionId !== OPTIMISTIC_ID && (
        <>
          <Link
            key={recognitionId}
            css={styles.removeLink}
            to={`${BASE_ROUTE}recognition/${recognitionId}/?${stringify(filters)}`}
          >
            <ClampLines
              text={descriptionHTML}
              id="recognition-description"
              testId="recognition-description"
              lines={4}
              ellipsis="..."
              moreText="Expand"
              lessText="Shrink"
              link
              setIsClamped={setIsClamped}
              css={styles.recognitionDescription(hasBeenEdited)}
            />
          </Link>
        </>
      )}

    </div>
  </div>
);

interface CardBodyProps extends ComponentProps<'div'> {
  showExpandedView?: boolean,
  descriptionHTML: HTMLString,
  hasBeenEdited?: boolean,
  recognitionId: number,
  filters: InfiniteQueryParamsProps,
  modifiedOn?: string | null,
}

const CardBody = ({
  showExpandedView = false,
  descriptionHTML,
  recognitionId,
  filters,
  modifiedOn = null,
}: CardBodyProps): JSX.Element => {
  const [isClamped, setIsClamped] = useState(false);
  const hasBeenEdited = modifiedOn !== null;

  const hookProps = {
    showExpandedView,
    descriptionHTML,
    hasBeenEdited,
    recognitionId,
    filters,
    isClamped,
    setIsClamped,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default CardBody;
