import { Collapse, SelectChangeEvent } from '@mui/material';
import { css } from '@emotion/react';
import { participantsFilterOptions } from '~Reviews/V2/Const/defaults';
import { Manager } from '~Reviews/V2/Hooks/useGetPeopleManagers';
import emptyParticipants from '~Assets/images/empty-views/emptyParticipants.png';
import { BUTTON_STYLES, FORM_STYLES, REVIEW_SETUP_LAYOUT } from '~Reviews/V2/Const/pageStyles';
import Dropdown from '~Common/V3/components/Dropdown';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { ParticipantsFilterType } from '~Reviews/V2/Const/types';
import AttendeeItem from '~Reviews/V2/Shared/AttendeeItem';
import { palette } from '~Common/styles/colors';
import { Person } from '~Common/const/interfaces';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...BUTTON_STYLES,
  ...FORM_STYLES,
  fauxDrawerTitle: css({
    fontWeight: '600',
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    paddingBottom: '1.25rem',
    marginBottom: '1rem',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
  }),
  emptyView: css({
    textAlign: 'center',
    color: palette.brand.indigo,

    '& img': {
      maxWidth: '80%',
    },
    '& p': {
      maxWidth: '65%',
      margin: '-2rem auto 0 auto',
      fontSize: '.875rem',
    },
  }),
  userListContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginBottom: '0.75rem',
  }),
  removeAllButton: css({
    margin: '1.25rem 0 .875rem 0',
    letterSpacing: '.2rem',
    fontSize: '0.625rem',
  }),
  managerHeading: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
    fontWeight: 500,
    gap: '0.75rem',
    textAlign: 'left',
  }),
  collapseIcon: (isCollapsed: boolean) => css({
    rotate: isCollapsed ? '-90deg' : '0deg',
    transition: 'all 100ms',
  }),
  skeletonLoader: css({
    maxWidth: '100%',
    height: '5rem',
  }),
  borderRadius: css({
    borderRadius: '0.5rem',
  }),
};

const attendeeItemBorderRadius = styles.borderRadius;

export interface ViewProps extends SelectedParticipantsProps {
  changeParticipantFilterType: (event: SelectChangeEvent) => void,
  collapsedManagers: string[],
  managerGrouping: Record<string, string[]>,
  participantFilterType: number,
  toggleCollapsedManager: (managerId: string) => void,
  isLoading: boolean,
}

export const View = ({
  changeParticipantFilterType,
  collapsedManagers,
  disabledUsers,
  isAttendeeSelected,
  managerGrouping,
  normalizedManagers,
  onSelectParticipant,
  onRemoveAll,
  participantFilterType,
  peopleData,
  selectedParticipants,
  toggleCollapsedManager,
  isLoading,
}: ViewProps): JSX.Element => (
  <div css={[styles.boxShadow, styles.overflowAttendees]}>
    <p css={styles.fauxDrawerTitle}>
      Selected
    </p>
    {isLoading && (
      <MultipleSkeletonLoaders
        css={styles.userListContainer}
        numberOfSkeletons={10}
        renderSkeletonItem={() => (
          <SkeletonLoader
            variant="rectangular"
            css={[styles.skeletonLoader, attendeeItemBorderRadius]}
            renderComponent={() => <></>}
          />
        )}
      />
    )}
    {!isLoading && (
      <>
        <p css={styles.labelTitle}>
          Viewing By
        </p>

        <Dropdown
          data-id="participantsFilterOptions"
          items={participantsFilterOptions}
          onChange={changeParticipantFilterType}
          value={participantFilterType.toString()}
        />
        <LeadrButton
          variant="text"
          disabled={selectedParticipants.length === 0}
          onClick={onRemoveAll}
          textButtonColor={selectedParticipants.length === 0 ? palette.neutrals.gray700 : palette.brand.blue}
          css={styles.removeAllButton}
          data-test-id="reviewsCycleRemoveAllParticipants"
        >
          Remove All
        </LeadrButton>
        {selectedParticipants.length === 0 && (
        <>
          <div css={styles.emptyView}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <img src={emptyParticipants} alt="You have no participants assigned." data-test-id="reviewsEmptyParticipants" />
            <p>You have not added any participants yet. Please select the participants you would like to participate.</p>
          </div>
        </>
        )}
        {participantFilterType === ParticipantsFilterType.All_Participants && (
        <>
          <div css={styles.userListContainer}>
            {normalizedManagers && selectedParticipants && (
            <>
              {selectedParticipants.map((aId) => (
                <AttendeeItem
                  managers={normalizedManagers[aId]}
                  peopleData={peopleData}
                  id={aId}
                  key={aId}
                  isAttendeeSelected={isAttendeeSelected(aId)}
                  onClick={onSelectParticipant}
              // isDisabled={undefined}
                  tooltip={disabledUsers.find((u) => u.user === aId)?.tooltip}
                />
              ))}
            </>
            )}
          </div>
        </>
        )}
        {participantFilterType === ParticipantsFilterType.Manager && Object.entries(managerGrouping).map(([managerId, participants]) => (
          <div css={styles.userListContainer}>
            <LeadrButton
              variant="text"
              textButtonColor={palette.neutrals.gray800}
              css={styles.managerHeading}
              onClick={() => toggleCollapsedManager(managerId)}
              data-test-id="reviewsCycleToggleManagers"
            >
              {`${peopleData[managerId].firstName} ${peopleData[managerId].lastName} is reviewing`}
              <FontAwesomeIcon
                css={styles.collapseIcon(collapsedManagers.includes(managerId))}
                icon={faCaretDown}
              />
            </LeadrButton>
            <Collapse
              in={!collapsedManagers.includes(managerId)}
            >
              <div css={styles.userListContainer}>
                {participants.map((aId) => (
                  <AttendeeItem
                    managers={normalizedManagers[aId]}
                    peopleData={peopleData}
                    id={aId}
                    key={aId + managerId}
                    isAttendeeSelected={isAttendeeSelected(aId)}
                    onClick={onSelectParticipant}
                    tooltip={disabledUsers.find((u) => u.user === aId)?.tooltip}
                  />
                ))}
              </div>
            </Collapse>
          </div>
        ))}
      </>
    )}
  </div>
);

interface SelectedParticipantsProps {
  disabledUsers: {
    user: string,
    tooltip: string,
  }[],
  isAttendeeSelected: (id: string) => boolean,
  normalizedManagers: Record<string, Manager[]>,
  onRemoveAll: () => void,
  onSelectParticipant: (params: string) => void,
  peopleData: Record<string, Person>,
  selectedParticipants: string[],
  isLoading: boolean,
}

export const SelectedParticipants = ({
  normalizedManagers,
  selectedParticipants,
  isLoading,
  ...props
}: SelectedParticipantsProps): JSX.Element => {
  const [collapsedManagers, setCollapsedManagers] = useState<string[]>([]);
  const [participantFilterType, setParticipantFilterType] = useQueryParamState<ParticipantsFilterType>(
    'insights',
    'participantFilterType',
    ParticipantsFilterType.All_Participants,
  );
  const changeParticipantFilterType = (event: SelectChangeEvent): void => {
    setParticipantFilterType(Number(event.target.value));
  };

  const toggleCollapsedManager = (managerId: string): void => {
    const index = collapsedManagers.indexOf(managerId);
    if (index === -1) {
      setCollapsedManagers((current) => ([...current, managerId]));
    } else {
      setCollapsedManagers((current) => ([
        ...current.slice(0, index),
        ...current.slice(index + 1),
      ]));
    }
  };

  const managerGrouping = useMemo(
    () => {
      const managerGroups: Record<string, string[]> = {};
      selectedParticipants?.forEach((participantOrgUserId) => {
        normalizedManagers[participantOrgUserId]?.forEach((manager) => {
          managerGroups[manager.id] ??= [];
          managerGroups[manager.id].push(participantOrgUserId);
        });
      });

      return managerGroups;
    },
    [selectedParticipants, normalizedManagers],
  );

  const hookProps = {
    changeParticipantFilterType,
    collapsedManagers,
    managerGrouping,
    normalizedManagers,
    participantFilterType,
    selectedParticipants,
    toggleCollapsedManager,
    isLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
