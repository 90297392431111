import { Hit } from 'instantsearch.js';
import { MEETING_ENTITY_TYPES } from '~GlobalSearch/const/GlobalSearch';
import { BaseInstanceBody } from '~GlobalSearch/const/types';
import CustomHits from '~GlobalSearch/components/SearchModal/Section/CustomHits';
import BaseMeetingHitComponent from '~GlobalSearch/components/SearchModal/Section/MeetingSectionItems/BaseMeetingHitComponent';
import { BaseMeetingInstanceBody } from '~Meetings/const/meetingsSearchInterfaces';
import { PersonDisplayInformation } from '~Common/const/interfaces';

interface HitsRouterProps {
  userMap: Map<string, PersonDisplayInformation>,
}

const HitsRouter = ({ userMap, ...props }: HitsRouterProps): JSX.Element => (
  <CustomHits
    renderHitComponent={({ hit }: { hit: Hit<BaseInstanceBody> }) => (
      <>
        {MEETING_ENTITY_TYPES.includes(hit.entityType) && (
          <BaseMeetingHitComponent
            userMap={userMap}
            hit={hit as Hit<BaseMeetingInstanceBody>}
          />
        )}
        { /* Put other modules routing in here */ }
      </>
    )}
    {...props}
  />
);

export default HitsRouter;
