import { css } from '@emotion/react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';

const styles = {
  gridDashboard: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(21.25rem, 1fr));',
    width: '100%',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '11.875rem',
  }),
  gridDashboardSingle: css({
    gridTemplateColumns: '100%',
  }),
};

interface SkeletonViewProps {
    amountOfLoaders: number,
  }

const SkeletonView = ({
  amountOfLoaders,
}: SkeletonViewProps): JSX.Element => (
  <MultipleSkeletonLoaders
    css={[styles.gridDashboard, amountOfLoaders <= 1 ?? styles.gridDashboardSingle]}
    numberOfSkeletons={amountOfLoaders}
    renderSkeletonItem={() => (
      <CardSkeleton css={styles.cardSkeleton} />
    )}
  />
);

export { SkeletonView as GridDashboardSkeleton };
