import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';

const styles = {
  realtimeReorderingIndicator: css({
    backgroundColor: palette.brand.indigo,
    color: palette.neutrals.white,
    borderRadius: '0.25rem',
    padding: '0 0.25rem',
    position: 'absolute',
    top: 0,
    transform: 'translate(0, -20%)',
    fontSize: '0.8125rem',
    fontStyle: 'italic',
    fontWeight: 600,
  }),
};

interface RealtimeReorderingIndicatorProps {
  reorderName: string,
}

const RealtimeReorderingIndicator = ({
  reorderName,
  ...props
}: RealtimeReorderingIndicatorProps): JSX.Element => (
  <div
    css={styles.realtimeReorderingIndicator}
    {...props}
  >
    {reorderName}
  </div>
);

export default RealtimeReorderingIndicator;
