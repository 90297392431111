import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { palette } from '~Common/styles/colors';
import { useCelebrateModule } from '~Home/hooks/useCelebrateModule';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SpecialDaysUser } from '~Home/const/interfaces';
import DrawerInfo from '~Common/components/Drawers/Info';
import CelebrateListItem from '../Module/Celebrate/CelebrateListItem';

const styles = {
  drawerBody: css({
    color: palette.neutrals.gray700,
    padding: '1.25rem 1rem 1.5rem 1rem',
  }),
  closeButton: css`
    border: none;
    box-shadow: none;
    background-color: transparent;
  `,
  heading: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    marginBottom: '0.5rem',
  }),
  container: css({
    padding: '1.25rem',
  }),
};

const celebrateDrawerTemplate = {
  name: 'CELEBRATE_DRAWER_FULL_LIST',
  width: DRAWER_WIDTHS.BASE,
};

interface ViewProps {
  coWorkers?: SpecialDaysUser[],
  closeDrawerClick: () => void,
}

const View = ({
  coWorkers,
  closeDrawerClick,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={() => (
          <button onClick={closeDrawerClick} css={styles.closeButton} title="Close drawer">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        title="Upcoming Celebrations"
      />
    )}
    renderBody={() => (
      <div css={styles.container}>
        <div>
          <DrawerInfo
            infoTitle=""
            info="Congratulations to the following employees who have upcoming birthdays and anniversaries!"
          />
        </div>
        {coWorkers?.map((coWorker) => (
          <CelebrateListItem
            coWorker={coWorker}
          />
        ))}
      </div>
    )}
  />
);

const CelebrateDrawer = ({ popDrawer }: DrawerProps<unknown>): JSX.Element => {
  const dispatch = useDispatch();
  const { data } = useCelebrateModule({ take: 1000, daysAhead: 30 });

  const closeDrawerClick = useCallback(() => {
    dispatch(popDrawer({ popAll: true }));
  }, [dispatch, popDrawer]);

  const hookProps = {
    closeDrawerClick,
    coWorkers: data?.response,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: celebrateDrawerTemplate.name,
  component: CelebrateDrawer,
});

export { View, celebrateDrawerTemplate };
export default CelebrateDrawer;
