import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

ChartJS.register(ArcElement, Legend);
const styles = {
  container: (isSmall, isDefault) => css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    margin: '0 auto',
    position: 'relative',
    width: isSmall ? '75%' : '60%',
    filter: isDefault ? 'grayscale(100%)' : 'grayscale(0)',
  }),
  NumberContainer: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    color: ${palette.neutrals.gray600};
    margin-top: 1rem;
  `,
  OneHundred: (isSmall) => css`
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: ${isSmall ? '0.75rem' : '1.25rem'};
  `,
  chart: (isSmall) => css({
    margin: isSmall ? '-1.25rem auto' : '-4.25rem 0',
    position: 'relative',
    width: '100%',
    minWidth: '97px !important',
    minHeight: '97px !important',
  }),
};

const View = ({
  overallScore,
  options,
  data,
  isSmall,
  isDefault,
  ...props
}) => (
  <div css={[styles.container(isSmall, isDefault)]}>
    <Doughnut css={styles.chart(isSmall)} options={options} data={data} {...props} />
    <div css={styles.NumberContainer}>
      <div css={styles.OneHundred(isSmall)}> -100 </div>
      <div css={styles.OneHundred(isSmall)}> 100 </div>
    </div>
  </div>
);

View.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  options: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  overallScore: PropTypes.number,
  isSmall: PropTypes.bool,
  isDefault: PropTypes.bool,
};
View.defaultProps = {
  overallScore: 0,
  isSmall: false,
  isDefault: false,
};

const color = (value) => {
  if (value >= 0) {
    return palette.brand.green;
  }
  if (value < 0 && value >= -50) {
    return palette.brand.orange;
  }
  if (Math.sign(value) === 1) {
    return palette.brand.green;
  }
  return palette.brand.red;
};

export const GaugeChart = ({
  value, isSmall = false, isDefault = false, ...props
}) => {
  const gaugeNeedle = {
    id: 'gaugeNeedle',
    afterDatasetDraw(chart) {
      const {
        ctx,
        data,
        chartArea: { width },
      } = chart;
      const { needleValue } = data.datasets[0];
      const cx = width / 2;
      /* eslint no-underscore-dangle: 0 */
      const cy = chart._metasets[0].data[0].y;
      let angle = 0;

      if (needleValue === 0) {
        angle = -Math.PI / 2;
      } else {
        const degree = needleValue - 100;
        angle = (degree / 200) * Math.PI;
      }

      // needle
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(cx + cx * 0.01, 0);
      ctx.lineTo(0, 2);
      ctx.fillStyle = `${palette.neutrals.gray600}`;
      ctx.fill();

      // Dot
      ctx.rotate(-angle);
      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, Math.PI * 2);
      ctx.fill();
    },

  };

  const hookProps = {
    isDefault,
    overallScore: value,
    options: {
      borderWidth: 0,
      clip: 0,
      cutout: '75%',
      circumference: 180,
      rotation: 270,
      responsive: true,
      borderRadius: 0,
      hover: { mode: null },
      plugins: {
        tooltip: { enabled: false },
      },
    },
    plugins: [gaugeNeedle],
    data: {
      datasets: [
        {
          labels: [],
          backgroundColor: [
            color(value),
            Math.sign(value) === 1
              ? palette.brand.green
              : palette.neutrals.gray400,
            palette.neutrals.gray400,
          ],
          data: [Math.sign(value) ? 100 + value : 100 - value, 0, 100 - value],
          needleValue: value,
          spacing: 1,
          datalabels: {
            display: false,
          },
        },
      ],
    },
    isSmall,
  };

  return <View {...hookProps} {...props} />;
};

GaugeChart.propTypes = {
  value: PropTypes.number.isRequired,
  isSmall: PropTypes.bool,
  isDefault: PropTypes.bool,
};

GaugeChart.defaultProps = {
  isSmall: false,
  isDefault: false,
};
