import { css } from '@emotion/react';
import LeadrModal, { LeadrModalProps } from '~Common/V3/components/LeadrModal';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { palette } from '~Common/styles/colors';
import { useShowGlobalSearchModal } from '~GlobalSearch/stores/useShowGlobalSearchModal';
import { Configure, Index } from 'react-instantsearch-hooks-web';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { Hit } from 'instantsearch.js';
import {
  BaseMeetingInstanceBody,
} from '~Meetings/const/meetingsSearchInterfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '~Common/components/Tooltip';
import { GLOBAL_SEARCH_TABS, MODAL_RESULTS_PER_SECTION } from '~GlobalSearch/const/GlobalSearch';
import AlgoliaInstantSearch from '~GlobalSearch/components/AlgoliaInstantSearch';
import InstantSearchHelper from '~GlobalSearch/components/helpers/InstantSearchHelper';
import { useUserMap } from '~GlobalSearch/hooks/useUserMap';
import AlgoliaCustomSearchField from './AlgoliaCustomSearchField';
import BaseMeetingHitComponent from './Section/MeetingSectionItems/BaseMeetingHitComponent';
import EmptyState from './Section/CustomHits/EmptyState';
import Section from './Section';

const styles = {
  leadrModal: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  modalBody: css({
    minHeight: '2rem',
    padding: '0 !important',
  }),
  section: css({
    '&:not(:last-of-type)': {
      paddingBottom: '.5rem',
      borderBottom: `1px solid ${palette.neutrals.gray200}`,
    },
    '&:not(:first-of-type)': {
      paddingTop: '.5rem',
    },
  }),
  icon: css({
    width: '1.3125rem',
    height: '1.3125rem',
    color: palette.neutrals.gray700,
  }),
  helpText: css({
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: palette.neutrals.gray700,
    display: 'flex',
    fontSize: '0.6875rem',
    fontWeight: 600,
    gap: '.25rem',
    lineHeight: '0.6875rem',
    marginTop: '1rem',
    paddingRight: '1.5rem',
  }),
};

interface ViewProps extends Omit<LeadrModalProps, 'children' | 'open'> {
  showModal: boolean,
  getPeopleInformation: (userIds: string[]) => PersonDisplayInformation[],
  userMap: Map<string, PersonDisplayInformation>,
}

const View = ({
  showModal,
  userMap,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrModal
    open={showModal}
    css={styles.leadrModal}
    {...props}
  >
    <AlgoliaInstantSearch>
      <Configure
        hitsPerPage={MODAL_RESULTS_PER_SECTION}
      />
      <LeadrModal.Header>
        <AlgoliaCustomSearchField data-test-id="modalSearchResultsSearchField" />
      </LeadrModal.Header>
      <LeadrModal.Body css={styles.modalBody}>
        <InstantSearchHelper
          renderComponent={({ hasResults, query }) => (
            <>
              <div>
                {GLOBAL_SEARCH_TABS.map((tab) => (
                  <Index indexName={tab.indexName}>
                    {hasResults && (
                      <Section
                        css={styles.section}
                        key={tab.value}
                        headerText={tab.label}
                        toObject={tab.toObject}
                        showMoreButtonDataTestId={`showMore${tab.label}`}
                        renderHitComponent={({ hit }) => (
                          <BaseMeetingHitComponent
                            // TODO: Ideally figure out how to do generic types for this
                            hit={hit as Hit<BaseMeetingInstanceBody>}
                            userMap={userMap}
                          />
                        )}
                      />
                    )}
                  </Index>
                ))}
              </div>
              {!hasResults && query && (
                <EmptyState query={query} />
              )}
            </>
          )}
        />
        <Tooltip
          placement="bottom"
          content="Searching for something besides meetings? Try visiting the respective page for a localized search."
        >
          <div css={styles.helpText} data-test-id="searchModalCantFindHelpText">
            <FontAwesomeIcon icon={faInfoCircle} />
            Can&apos;t find something?
          </div>
        </Tooltip>
      </LeadrModal.Body>
    </AlgoliaInstantSearch>
  </LeadrModal>
);

const SearchModal = ({ ...props }: ViewProps): JSX.Element => {
  const {
    showModal,
    setShowModal,
  } = useShowGlobalSearchModal((state) => ({
    showModal: state.showModal,
    setShowModal: state.setShowModal,
  }));

  // When we click on an item or link in the modal, we are navigating away and this will catch that and close the modal
  const location = useLocation();
  useEffect(() => {
    setShowModal(false);
  }, [location, setShowModal]);

  const onClose = (): void => {
    setShowModal(false);
  };

  const { userMap } = useUserMap();

  const hookProps = {
    showModal,
    onClose,
    userMap,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default SearchModal;
