import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { useNextMeetings } from '~Deprecated/hooks/feedback/useNextMeetings';
import { ReceivedLearningQuestion } from '~Learning/const/interfaces';
import { useAddToNextMeeting } from '~Learning/hooks/useAddToNextMeeting';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { createMeetingTemplate } from '~Meetings/components/drawers/CreateMeetingDrawer';
import { ONE_ON_ONE_DEFAULT } from '~Meetings/const/meetingTypes';
import { useGetPersonById } from '~Deprecated/hooks/usePeople';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useIsReadOnlyCalendarIntegration } from '~Meetings/hooks/CalendarIntegration/useIsReadOnlyCalendarIntegration';
import MeetingCard from './MeetingCard';

const styles = {
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
  meetingCard: css({
    '&:not(:last-child)': {
      marginBottom: '1.125rem',
    },
  }),
  skeletonCard: css({
    maxWidth: '100%',
  }),
  emptyStateText: css({
    color: palette.neutrals.gray800,
    marginBottom: '1rem',
  }),
};

export const addToMeetingDrawerTemplate = {
  name: 'ADD_TO_MEETING_DRAWER',
  width: DRAWER_WIDTHS.SECONDARY,
};

interface AddToMeetingDrawerProps extends DrawerProps<unknown> {
  otherUserId: string,
  questions: ReceivedLearningQuestion[],
  learningId: string,
}

const AddToMeetingDrawer = ({
  otherUserId,
  learningId,
  popDrawer,
  pushDrawer,
  ...props
}: AddToMeetingDrawerProps): JSX.Element => {
  const webClientUseCreateHuddle = useFeatureFlag('webClientUseCreateHuddle');

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: addToMeetingDrawerTemplate.name });
  };

  const { orgUserId, id: userId } = useGetPersonById({ id: otherUserId }) as { orgUserId: string, id: string };
  // Get the next meetings

  const { nextMeetings, isLoading } = useNextMeetings({ otherUserId: userId });

  const idToUseForCreatingMeeting = webClientUseCreateHuddle ? orgUserId : userId;

  const addToMeetingMutation = useAddToNextMeeting({ template: addToMeetingDrawerTemplate });

  const onAddToMeeting = (meetingId: string): void => {
    addToMeetingMutation({
      meetingId,
      learningId,
      otherUserId: idToUseForCreatingMeeting,
    });
  };

  const { isLimitedAccess: isLimitedAccessUser } = useUserPermissions();
  const isReadOnlyCalendarIntegration = useIsReadOnlyCalendarIntegration();
  const canCreateMeetings = !isLimitedAccessUser && !isReadOnlyCalendarIntegration;

  const onScheduleMeeting = (): void => {
    popDrawer({ popAll: true });
    pushDrawer({
      drawer: {
        ...createMeetingTemplate,
        args: {
          ...ONE_ON_ONE_DEFAULT,
          defaultAttendeeId: idToUseForCreatingMeeting,
          onMeetingCreationCallback: onAddToMeeting,
        },
      },
    });
  };

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
        title="Available 1:1s"
      />
    ),
    renderBody: () => (
      <div css={styles.drawerBody}>
        {isLoading && (
          <MultipleSkeletonLoaders
            numberOfSkeletons={2}
            renderSkeletonItem={() => (
              <CardSkeleton css={[styles.meetingCard, styles.skeletonCard]} />
            )}
          />
        )}
        {!isLoading && (
          <>
            {nextMeetings.map((meeting) => (
              <MeetingCard key={meeting.id} css={styles.meetingCard} meeting={meeting} onAddToMeeting={() => onAddToMeeting(meeting.id)} />
            ))}
            {nextMeetings.length === 0 && (
              <div>
                <div css={styles.emptyStateText}>
                  You do not have any upcoming 1:1s scheduled yet with this person.
                </div>
                {canCreateMeetings && (
                  <LeadrButton
                    size="small"
                    onClick={onScheduleMeeting}
                    data-test-id="learningSchedule1:1Meeting"
                  >
                    Schedule 1:1
                  </LeadrButton>
                )}
              </div>
            )}
          </>
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: addToMeetingDrawerTemplate.name,
  component: AddToMeetingDrawer,
});

export default AddToMeetingDrawer;
