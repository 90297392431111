import { css } from '@emotion/react';
import {
  InputBase, MenuItem, Select, Skeleton,
} from '@mui/material';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import PaginationButton from '~Common/V3/components/Pagination/PaginationButton';
import Tooltip from '~Common/components/Tooltip';
import { ComponentProps, useState } from 'react';
import { palette } from '~Common/styles/colors';
import LeadrButton from '../LeadrButtons/LeadrButton';

const styles = {
  pageSelectionContainer: css({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  pageSelection: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray500,
    fontSize: '.8125rem',
    '.MuiSelect-icon': {
      color: palette.brand.indigo,
    },
  }),
  select: css({
    '.MuiSelect-select': {
      padding: 0,
      fontSize: '0.75rem',
    },
  }),
  pageSelect: (hasFocus: boolean) => css({
    backgroundColor: palette.neutrals.gray50,
    padding: '.0938rem .4375rem',
    borderRadius: '.375rem',
    border: `1px solid ${palette.neutrals.gray50}`,
    '.MuiSelect-select': {
      paddingRight: '0.625rem',
    },
  }, hasFocus && ({
    border: `1px solid ${palette.brand.sky}`,
  })),
  paginationButton: css({
    marginLeft: '1rem',
  }),
  pageButton: css({
    padding: '.0938rem .7813rem',
    color: palette.neutrals.gray500,

    '&:not(:last-child)': {
      borderRight: `0.0625rem solid ${palette.neutrals.gray300}`,
    },

    ':first-child': {
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem',
    },

    ':last-child': {
      borderTopRightRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: palette.neutrals.gray50,
    },

    // Temporary, we should probably figure out a better solution
    '&:not(:disabled):hover, &:not(:disabled):focus': {
      background: palette.brand.indigo,
      color: palette.neutrals.white,
      borderRadius: '0 0.5rem 0.5rem 0',
    },
    '&:first-child:not(:disabled):hover, &:first-child:not(:disabled):focus': {
      borderRadius: '0.5rem 0 0 0.5rem ',
    },
  }),
  spacer: css({
    margin: '0 0.4375rem',
  }),
  menuItem: css({
    color: palette.neutrals.gray900,
  }),
  icon: css({
    height: '.75rem',
    width: '.75rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  page: number,
  numberOfPages: number,
  onPageChange: (event: SelectChangeEvent<number>) => void,
  onPreviousClick: () => void,
  onNextClick: () => void,
  isLoading?: boolean,
  hasFocus: boolean,
  setHasFocus: (hasFocus: boolean) => void,
}

const View = ({
  page,
  onPageChange,
  numberOfPages,
  onPreviousClick,
  onNextClick,
  isLoading,
  hasFocus,
  setHasFocus,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.pageSelectionContainer} {...props}>

    {isLoading && (
      <Skeleton animation="wave" variant="rectangular" height={30} width={200} />
    )}
    {!isLoading && (
      <>
        <div css={styles.pageSelection}>
          { /* TODO: This needs updated to use our common dropdown component */ }
          <Select
            css={styles.select}
            labelId="page-select"
            id="page-select"
            value={page}
            onChange={onPageChange}
            input={
              <InputBase css={styles.pageSelect(hasFocus)} />

        }
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
          >
            {Array(numberOfPages).fill(0).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem css={styles.menuItem} key={index} value={index + 1}>{index + 1}</MenuItem>
            ))}
          </Select>
          <div css={styles.spacer}>of</div>
          {numberOfPages}
        </div>
        {numberOfPages > 1 && (
        <PaginationButton
          css={styles.paginationButton}
          renderPreviousButton={() => (
            <Tooltip interactive content="Previous">
              <LeadrButton
                onClick={onPreviousClick}
                css={styles.pageButton}
                disabled={page === 1}
                variant="text"
                data-test-id="previousPage"
              >
                <FontAwesomeIcon css={styles.icon} icon={faAngleLeft} />
              </LeadrButton>
            </Tooltip>
          )}
          renderNextButton={() => (
            <Tooltip interactive content="Next">
              <LeadrButton
                onClick={onNextClick}
                css={styles.pageButton}
                disabled={page === numberOfPages}
                variant="text"
                data-test-id="nextPage"
              >
                <FontAwesomeIcon css={styles.icon} icon={faAngleRight} />
              </LeadrButton>
            </Tooltip>
          )}
        />
        )}
      </>
    )}
  </div>
);

export type PaginationProps = Omit<ViewProps, 'hasFocus' | 'setHasFocus'>;

const Pagination = ({
  ...props
}: PaginationProps): JSX.Element => {
  const [hasFocus, setHasFocus] = useState(false);

  const hookProps = {
    hasFocus,
    setHasFocus,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default Pagination;
