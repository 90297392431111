import { getOrganizationId } from '~Common/utils/localStorage';
import { DirectReportReflectionsListQueryParams } from '~Reviews/V2/reflections/hooks/useGetDirectReportReflectionsList';
import { ReflectionsListQueryParams } from '~Reviews/V2/reflections/hooks/useGetReflectionsList';

export const reflectionQueryKeys = {
  all: [getOrganizationId() ?? '', 'reflections'] as const,
  lists: () => [...reflectionQueryKeys.all, 'lists'] as const, // Basically the landing page
  list: (filters: ReflectionsListQueryParams) => [...reflectionQueryKeys.lists(), 'list', filters] as const,
  directReportLists: () => [...reflectionQueryKeys.all, 'directReportsLists'] as const,
  directReportList: (filters: DirectReportReflectionsListQueryParams) => [...reflectionQueryKeys.directReportLists(), 'list', filters] as const,
  details: () => [...reflectionQueryKeys.all, 'details'] as const,
  detail: (id: string) => [...reflectionQueryKeys.details(), id] as const,
};
