import { intersection, without } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

interface UseSelectedAttendeesProps {
  eligibleParticipants: string[],
  existingParticipants?: string[],
  filteredParticipants?: string[],
  selectedLimit?: number,
}

interface UseSelectedAttendeesReturn {
  canDeselectAll: boolean,
  isAttendeeSelected: (id: string) => boolean,
  onDeselectAll: () => void,
  onRemoveAll: () => void,
  onSelectAll: () => void,
  onSelectParticipant: (id: string) => void,
  selectedParticipants: string[],
}

export const useSelectedParticipants = ({
  eligibleParticipants,
  existingParticipants = [],
  filteredParticipants = eligibleParticipants,
  selectedLimit,
}: UseSelectedAttendeesProps): UseSelectedAttendeesReturn => {
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>([]);

  useEffect(() => {
    setSelectedParticipants([...existingParticipants]);
  }, [existingParticipants]);

  const onSelectParticipant = (id: string): void => {
    const currentIndex = selectedParticipants.indexOf(id);
    if (currentIndex === -1) {
      if (!eligibleParticipants.includes(id)) {
        return;
      }

      setSelectedParticipants([...selectedParticipants, id]);
    } else {
      if (selectedLimit && selectedLimit > 1 && selectedParticipants.length >= selectedLimit) {
        return;
      }

      setSelectedParticipants([
        ...selectedParticipants.slice(0, currentIndex),
        ...selectedParticipants.slice(currentIndex + 1),
      ]);
    }
  };

  const onSelectAll = (): void => {
    // This is done with a set because sets can't have duplicates, and its sonic speeds
    setSelectedParticipants(Array.from(new Set([...selectedParticipants, ...filteredParticipants])));
  };

  const onDeselectAll = (): void => {
    setSelectedParticipants(without(selectedParticipants, ...filteredParticipants));
  };

  const onRemoveAll = (): void => {
    setSelectedParticipants([]);
  };

  const canDeselectAll = useMemo(
    () => intersection(filteredParticipants, selectedParticipants).length > 0,
    [selectedParticipants, filteredParticipants],
  );

  const isAttendeeSelected = (id: string): boolean => selectedParticipants?.includes(id);

  return {
    canDeselectAll,
    isAttendeeSelected,
    onDeselectAll,
    onRemoveAll,
    onSelectAll,
    onSelectParticipant,
    selectedParticipants,
  };
};
