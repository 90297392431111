import { css, SerializedStyles } from '@emotion/react';

import {
  palette,
} from '~Common/styles/colors';

const styles = {
  cancelButton: css`
    border:1px solid ${palette.neutrals.gray600};
    background: none;
    border-radius: 3px;
    color:${palette.neutrals.gray600};
    display:block;
    min-width:auto;
    padding: 1.25rem 1.5rem;
    margin-top:0;

    &:hover{
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  `,
  deleteButton: css`
    border:1px solid ${palette.brand.red};
    background: none;
    border-radius: 3px;
    color:${palette.brand.red};
    display:block;
    min-width:auto;
    padding: 1.25rem 1.5rem;
    &:hover{
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  `,
  saveButton: css`
    flex-shrink: 0;
    margin-right: 0.5rem;
    color: ${palette.brand.sky};
    min-width:auto;
    padding: 1.25rem 1.5rem;
    margin-top:0;
  `,
  // Updating the margin because otherwise the delete button is too close to the bottom and intercom covers it
  footerFlexContainer: css`
    display: flex;
    margin: .5rem 1.5rem 4.5rem 1.5rem;
    justify-content:space-between;
  `,
};

interface DrawerFooterProps {
  renderCancelButton: (tabDrawerFooterStyles: SerializedStyles) => JSX.Element,
  renderSaveButton?: (saveButtonStyles: SerializedStyles) => JSX.Element,
  renderDeleteButton?: (saveButtonStyles: SerializedStyles) => JSX.Element,
}

const DrawerFooter = ({
  renderCancelButton,
  renderSaveButton,
  renderDeleteButton,
}: DrawerFooterProps): JSX.Element => (
  <div css={styles.footerFlexContainer}>
    <div>
      {renderSaveButton?.(styles.saveButton)}
      {renderCancelButton?.(styles.cancelButton)}
    </div>
    <div>
      {renderDeleteButton?.(styles.deleteButton)}
    </div>
  </div>
);

export default DrawerFooter;
