import { css } from '@emotion/react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';

const styles = {
  privateIcon: css({
    height: '1.875rem',
    width: '1.875rem',
    color: palette.neutrals.gray700,
  }),
};

const PrivateIcon = ({ ...props }: Omit<FontAwesomeIconProps, 'icon'>): JSX.Element => (
  <FontAwesomeIcon
    icon={faEyeSlash}
    css={styles.privateIcon}
    {...props}
  />
);

export default PrivateIcon;
