import { useCallback } from 'react';
import { AssignedLearning, LearningSort, ReceivedLearning } from '~Learning/const/interfaces';

type Learning = AssignedLearning | ReceivedLearning;

interface UseCallbackParams {
  unsortedLearningsAndPlaylists: Learning[],
  sort?: LearningSort,
  shouldReverse?: boolean,
}

const sortMap: Record<LearningSort, keyof Learning> = {
  [LearningSort.DUE_DATE]: 'dueDate',
  [LearningSort.DATE_CREATED]: 'createdDateTime',
};

export const useSortLearningsAndPlaylists = (): ({
  unsortedLearningsAndPlaylists,
  sort,
}: UseCallbackParams) => Learning[] => useCallback(({
  unsortedLearningsAndPlaylists,
  // We removed the frontend functionality for the user to sort, so defaulting to sorting by due date
  sort = LearningSort.DUE_DATE,
  shouldReverse = false,
}: UseCallbackParams): Learning[] => {
  if (unsortedLearningsAndPlaylists) {
    const sortedLearnings = [...unsortedLearningsAndPlaylists].sort((learning1, learning2) => {
      const learning2Sort = learning2[sortMap[sort]];
      const learning1Sort = learning1[sortMap[sort]];

      // Null date for Learning 1
      // If shouldReverse, then we want nulls at the end still
      if (!learning1Sort) {
        return shouldReverse ? -1 : 1;
      }

      // Null date for Learning 2
      // If shouldReverse, then we want nulls at the end still
      if (!learning2Sort) {
        return shouldReverse ? 1 : -1;
      }

      // Dates are the same
      if (learning1Sort === learning2Sort) {
        return 0;
      }

      // Earliest dates are first
      return learning1Sort < learning2Sort ? -1 : 1;
    });

    if (shouldReverse) {
      return [...sortedLearnings].reverse();
    }

    return sortedLearnings;
  }

  return [];
}, []);
