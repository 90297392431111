import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { OrgDetails } from '~Common/const/Organization';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';

interface GetOrgDetailsParams {
  orgId: string,
}

const getOrgDetails = ({ orgId }: GetOrgDetailsParams): Promise<HttpCallReturn<OrgDetails>> => {
  const url = `/organizations/${orgId}`;

  return getApi<OrgDetails>(url);
};

interface GetOrgDetailsQueryReturn {
  queryKey: QueryKey,
  queryFn: () => Promise<HttpCallReturn<OrgDetails>>,
}

export const getOrgDetailsQuery = (orgId: string): GetOrgDetailsQueryReturn => ({
  queryKey: ['organizations', 'details', orgId],
  queryFn: () => getOrgDetails({ orgId }),
});
interface UseGetOrgDetailsParams<T> extends Omit<UseQueryOptions<HttpCallReturn<OrgDetails>, Error, T>, 'queryKey' | 'queryFn'> {
  orgId: string,
}

/*
  If you need the data from the current organization, use OrgDetailsContext instead
*/
export const useGetOrgDetails = <T = HttpCallReturn<OrgDetails>>({
  orgId,
  ...options
}: UseGetOrgDetailsParams<T>): UseQueryResult<T, Error> => useQuery({
    ...getOrgDetailsQuery(orgId),
    enabled: !!orgId,
    ...options,
  });
