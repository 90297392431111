import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import { palette } from '~Common/styles/colors';
import SkeletonLoader, { SkeletonLoaderProps } from '~Common/components/SkeletonLoader';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import RequiredIcon from './RequiredIcon';

const borderRadius = '0.5rem';

const styles = {
  formControl: (hasFocus: boolean) => css({
    backgroundColor: palette.neutrals.gray50,
    width: '100%',
    border: `1px solid ${palette.neutrals.gray50}`,
    borderRadius,
    padding: '.75rem 1rem',

    '& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before': {
      display: 'none',
      border: 0,
    },

    '& .MuiInputBase-root': {
      width: '100%',
    },

    '& .MuiFormLabel-root': {
      color: palette.neutrals.gray500,
      fontSize: '.875rem',
      margin: '0.75rem 0 0 1rem',
      lineHeight: '1rem',

      '&.Mui-focused': {
        color: palette.neutrals.gray500,
      },
    },

    '& .MuiInput-root': {
      margin: 0,
      color: palette.neutrals.gray800,
      fontSize: '1rem',
      fontWeight: 500,
    },

    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },

    '& .MuiSelect-icon': {
      color: palette.brand.indigo,
    },

    '& label': {
      color: palette.neutrals.gray700,
      fontWeight: 400,
      marginBottom: 0,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
  }, hasFocus && ({
    border: `1px solid ${palette.brand.sky}`,
  })),
  dropdownContainer: (hasLabel: boolean) => css({
  }, hasLabel && {
    marginTop: '.25rem',
  }),
  menuItem: css({
    color: palette.neutrals.gray900,
  }),
  dropdownSkeleton: css({
    borderRadius,
    height: '3.75rem',
    maxWidth: '100%',
  }),
  dropdownIcon: css({
    color: palette.brand.indigo,
    top: 'calc(50% - 0.6rem)',
  }),
  labelContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  emptyOptionText: css({
    color: palette.neutrals.gray500,
  }),
};

export interface DropdownItem<T = string> {
  value: T extends Array<infer U> ? U : T,
  text: string,
  ['data-test-id']?: string,
  disabled?: boolean,
}

export interface ViewProps<T> extends SelectProps<T> {
  items: DropdownItem<T>[],
  variant?: 'standard' | 'outlined' | 'filled',
  name?: string,
  disabled?: boolean,
  currentValue: T | undefined,
  doChange: (event: SelectChangeEvent<T>) => void,
  label?: string,
  renderLabel?: () => JSX.Element,
  renderLeftSideItem?: () => JSX.Element,
  className?: string,
  renderItem?: (item: DropdownItem<T>) => JSX.Element,
  renderIcon: (fontAwesomeIconProps: FontAwesomeIconProps) => JSX.Element,
  hasFocus: boolean,
  setHasFocus: (hasFocus: boolean) => void,
  showEmptyOption?: boolean,
  emptyOptionText?: string,
}

const View = <T extends string | number | Array<string | number>, > ({
  items,
  variant = 'standard',
  name,
  disabled = false,
  currentValue,
  doChange,
  label,
  renderLabel,
  renderLeftSideItem,
  className,
  renderItem,
  renderIcon,
  hasFocus,
  setHasFocus,
  required,
  displayEmpty,
  showEmptyOption,
  emptyOptionText = 'Select',
  ...props
}: ViewProps<T>): JSX.Element => (
  <FormControl
    css={styles.formControl(hasFocus)}
    className={className}
    variant={variant}
  >
    <div>
      <div css={styles.labelContainer}>
        {renderLabel?.()}

        {!renderLabel && label && (
          <label htmlFor={name}>{label}</label>
        )}

        {required && (
          <RequiredIcon />
        )}
      </div>

      { /* Not a big fan of adding the margins this way, but MUI is super wierd with their label placement, and I think this would require a little overhaul and digging */ }
      <div css={styles.dropdownContainer(!!renderLabel)}>
        {renderLeftSideItem?.()}
        <Select
          value={currentValue}
          onChange={doChange}
          name={name}
          disabled={disabled}
          IconComponent={renderIcon}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          displayEmpty={displayEmpty || showEmptyOption}
          {...props}
        >
          {showEmptyOption && (
            <MenuItem
              value=""
              css={styles.menuItem}
            >
              <div css={styles.emptyOptionText}>
                {emptyOptionText}
              </div>
            </MenuItem>
          )}

          {items.map((item) => (
            <MenuItem
              value={item.value as string}
              key={item.value as string}
              data-test-id={item['data-test-id']}
              css={styles.menuItem}
              disabled={item.disabled}
            >
              {renderItem && renderItem(item)}
              {!renderItem && item.text}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  </FormControl>
); // eslint-disable-line indent

export interface DropdownProps<T> extends Omit<ViewProps<T>, 'currentValue' | 'doChange' | 'renderIcon' | 'hasFocus' | 'setHasFocus'> {
  value?: T,
  onChange?: (event: SelectChangeEvent<T>) => void,
}

const Dropdown = <T extends string | number | Array<string> | Array<number> = string, >({ value, onChange, ...props }: DropdownProps<T>): JSX.Element => {
  const [currentValue, updateState] = useState(value);
  const [hasFocus, setHasFocus] = useState(false);
  const doChange = (event: SelectChangeEvent<T>): void => {
    if (onChange) {
      onChange(event);
    }

    updateState(event.target.value as T);
  };

  useEffect(() => {
    updateState(value);
  }, [value]);

  const renderIcon = useCallback(({ className }: FontAwesomeIconProps) => (
    <FontAwesomeIcon
      css={styles.dropdownIcon}
      className={className}
      icon={faCaretDown}
    />
  ), []);

  const hookProps = {
    currentValue,
    doChange,
    renderIcon,
    hasFocus,
    setHasFocus,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

const SkeletonView = ({ ...props }: Omit<SkeletonLoaderProps, 'renderComponent'>): JSX.Element => (
  <SkeletonLoader
    css={styles.dropdownSkeleton}
    variant="rectangular"
    renderComponent={() => <></>}
    {...props}
  />
);

export { View, SkeletonView as DropdownSkeleton };

export default Dropdown;
