import { create } from 'zustand';
import { DEFAULT_TEAM_ROW_DISPLAY_COUNT } from '../const';

interface TeamDetailsRowDisplayCountState {
  count: number,
  setCount: (nextValue: number) => void,
}

export const useTeamDetailsRowDisplayCount = create<TeamDetailsRowDisplayCountState>()((set) => ({
  count: DEFAULT_TEAM_ROW_DISPLAY_COUNT,
  setCount: (nextValue: number) => set(() => ({ count: nextValue })),
}));
