import { Route, Switch } from 'react-router-dom';
import {
  RECOGNITION,
  RECOGNITION_BASE_ROUTE, RECOGNITION_BY_ID,
} from '~Common/const/routes';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import RecognitionLayout from '../pages/RecognitionLayout';

const View = (): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[RECOGNITION, RECOGNITION_BY_ID]}
      render={() => (
        <RecognitionLayout />
      )}
    />
    <SmartRedirect from="*" to={RECOGNITION_BASE_ROUTE} />
  </Switch>
);

const RecognitionRouter = (): JSX.Element => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
    />
  );
};

export default RecognitionRouter;
