import {
  array,
  object,
  string,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEPARTMENT_LENGTH, JOB_TITLE_LENGTH, PROFILE_NAME_MAX_LENGTH } from '~Common/const/constraints';
import { getOrganizationId } from '~Common/utils/localStorage';
import { User } from '~Common/const/interfaces';
import { UserGroup } from '~Common/const/userGroups';

export const employmentTypes = ['full-time', 'part-time', 'other'] as const;
const requiredMsg = 'This field is required.';

const editUserFormSchema = object({
  firstName: string()
    .required(requiredMsg)
    .max(PROFILE_NAME_MAX_LENGTH, `This field is limited to ${PROFILE_NAME_MAX_LENGTH} characters.`),
  lastName: string()
    .required(requiredMsg)
    .max(PROFILE_NAME_MAX_LENGTH, `This field is limited to ${PROFILE_NAME_MAX_LENGTH} characters.`),
  email: string()
    .required(requiredMsg)
    .email('This is not a valid email address.'),
  jobTitle: string()
    .ensure()
    .max(JOB_TITLE_LENGTH, `This field is limited to ${JOB_TITLE_LENGTH} characters.`),
  managerId: string().ensure(),
  secondaryManagerId: string().ensure(),
  department: string()
    .ensure()
    .max(DEPARTMENT_LENGTH, `This field is limited to ${DEPARTMENT_LENGTH} characters.`),
  accountType: string().required(requiredMsg),
  userGroups: array(string().ensure()).default([]),
});

export const blankUser: User = {
  administrativeStatus: 'ACTIVE',
  id: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  applicationGroupName: UserGroup.Member,
  department: '',
  directsCount: 0,
  eligibleForBenefits: false,
  email: '',
  importedAtInMillis: 0,
  invitedAtInMillis: 0,
  isDisabled: false,
  isExecutive: false,
  isVerified: false,
  lastActivityInMillis: 0,
  managerEmail: '',
  managerId: '',
  mentors: [],
  orgUserId: '',
  teams: [],
  userGroupId: '',
  userGroupName: UserGroup.Member,
};

export const editUserFormResolver = yupResolver(editUserFormSchema);

export type FormValues = InferType<typeof editUserFormSchema>;

export interface EditUserDTO {
    email: string,
    firstName: string,
    lastName: string,
    managerId?: string | null,
    mentors?: string[],
    jobTitle: string,
    department: string,
    employmentType?: string | null,
    userGroupIds: string[],
    organizationId?: string | null,
    teams?: string[],
    eligibleForBenefits?: boolean | null,
  }

  interface conformToInviteDtoOptions {
    enableMatrixOrgs: boolean,
  }

export function conformToInviteDto(data: FormValues, {
  enableMatrixOrgs,
}: conformToInviteDtoOptions): EditUserDTO {
  const result: EditUserDTO = {
    department: data.department,
    email: data.email,
    firstName: data.firstName,
    jobTitle: data.jobTitle,
    lastName: data.lastName,
    organizationId: getOrganizationId(),
    teams: data.userGroups,
    userGroupIds: [data.accountType],
    managerId: data.managerId !== '' ? data.managerId : null,
  };
  if (enableMatrixOrgs) {
    result.mentors = data.secondaryManagerId ? [data.secondaryManagerId] : [];
  }

  return result;
}
