import { css } from '@emotion/react';
import {
  useState, Fragment, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  useFieldArray, useFormContext, useWatch,
} from 'react-hook-form';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormHelperText } from '@mui/material';
import { faPenToSquare, faTimes } from '@fortawesome/pro-light-svg-icons';
import { toast } from '~Common/components/Toasts';
import Button from '../Button';
import { TextField } from './TextField';

const styles = {
  buttonRowStyles: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'start',
  }),
  dragHandle: css({
    color: palette.neutrals.gray700,
    cursor: 'grab !important',
    marginRight: '0.5rem',
  }),
  list: css({
    listStyle: 'none',
    margin: '0',
    padding: '0',
    gap: '0.5rem',
    marginBottom: '0.5rem',
    flexDirection: 'column',
    display: 'flex',
  }),
  listItem: css({
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '5px',
    color: palette.neutrals.gray900,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.875rem',
    fontWeight: 400,
    padding: '0.75rem',

    p: {
      flex: '1',
    },
  }),
};

function ListItem({
  id,
  name,
  index,
  control,
  onEdit,
  onDelete,
}) {
  const data = useWatch({
    control,
    name: `${name}.${index}`,
  });

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          css={styles.listItem}
        >
          <Button
            css={styles.dragHandle}
            {...provided.dragHandleProps}
            variant="text"
            renderContents={() => (
              <FontAwesomeIcon size="lg" icon={faGripDotsVertical} />
            )}
          />
          <p>
            {data.text}
          </p>
          <Button
            variant="text"
            size="small"
            onClick={() => onEdit(index)}
            renderContents={() => <FontAwesomeIcon icon={faPenToSquare} />}
          />
          <Button
            variant="text"
            size="small"
            onClick={() => onDelete(index)}
            renderContents={() => <FontAwesomeIcon icon={faTimes} />}
          />
        </li>
      )}
    </Draggable>
  );
}

ListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export function TextFieldArray({
  name,
  inputLabel,
  renderAddButton,
  renderActionButtons,
}) {
  const {
    control, formState: { errors }, getValues, setValue,
  } = useFormContext();
  const error = errors[name];

  const {
    fields, append, remove, move,
  } = useFieldArray({
    control,
    name,
  });
  const [editedRowIndex, setEditedRowIndex] = useState(-1);
  const [capturedValue, setCapturedValue] = useState('');

  const fieldValue = (index) => `${name}.${index}.text`;

  const onClickAddButton = () => {
    append({ text: '' });
    setEditedRowIndex(fields.length);
    setCapturedValue('');
  };
  const onClickCancelButton = () => {
    if (capturedValue !== '') {
      setValue(fieldValue(editedRowIndex), capturedValue);
      setCapturedValue('');
    } else {
      remove(editedRowIndex);
    }

    setEditedRowIndex(-1);
  };
  const toastId = useRef(null);

  const onClickSaveButton = () => {
    const currentValue = getValues(fieldValue(editedRowIndex));
    // For some reason we can't combine this line and the line above?
    const trimmedValue = currentValue.trim();
    toastId.current = toast.info('Saving option', { autoClose: 1000 });
    if (trimmedValue.length > 0) {
      setCapturedValue('');
      setEditedRowIndex(-1);

      if (editedRowIndex === fields.length - 1) {
        toast.update(toastId.current, {
          render: 'Successfully added your option',
          type: toast.TYPE.SUCCESS,
          autoClose: 2500,
        });
        onClickAddButton();
      }
    } else {
      toast.update(toastId.current, {
        render: 'Option must contain at least 1 character to be added',
        type: toast.TYPE.ERROR,
        autoClose: 3500,
      });
    }
  };

  const handleKeyUpInInput = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onClickSaveButton();
    }
  };

  const onEdit = (index) => {
    const currentValue = getValues(fieldValue(index));
    setCapturedValue(currentValue);
    setEditedRowIndex(index);
  };

  const onDelete = useCallback((index) => {
    remove(index);
  }, [remove]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    move(result.source.index, result.destination.index);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={name}>
          {(provided) => (
            <ol
              css={styles.list}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  {editedRowIndex === index && (
                    <TextField
                      name={fieldValue(index)}
                      label={inputLabel}
                      onKeyPress={handleKeyUpInInput}
                    />
                  )}
                  {editedRowIndex !== index && (
                    <ListItem
                      id={field.id}
                      name={name}
                      index={index}
                      control={control}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  )}
                </Fragment>
              ))}
              {provided.placeholder}
            </ol>
          )}
        </Droppable>
      </DragDropContext>
      {error && (
        <FormHelperText error css={styles.errorMessage}>
          {error.message}
        </FormHelperText>
      )}
      {editedRowIndex === -1 && (
        renderAddButton(onClickAddButton)
      )}
      {editedRowIndex !== -1 && (
        renderActionButtons({
          onClickCancelButton,
          onClickSaveButton,
          buttonRowStyles: styles.buttonRowStyles,
        })
      )}
    </>
  );
}

TextFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  renderAddButton: PropTypes.func.isRequired,
  renderActionButtons: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
};
