import Box from '@mui/material/Box';
import Modal, { ModalProps } from '@mui/material/Modal';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import {
  ReactNode,
  createContext,
  useMemo,
} from 'react';
import { forMobileObject } from '~Common/styles/mixins';
import { noop } from '~Deprecated/utils';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';

type VerticalPosition = 'top' | 'center' | 'bottom';

const styles = {
  modal: (verticalPosition: VerticalPosition) => css({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.neutrals.white,
    boxShadow: '0px 4px 8px -2px rgba(28, 42, 55, 0.2)',
    borderRadius: '.5rem',
    padding: '1.5rem',
    maxHeight: '100%',
    maxWidth: '50rem',
    width: '100%',
    left: '50%',
  }, verticalPosition === 'top' && {
    transform: 'translate(-50%)',
    top: '5%',
    bottom: 'auto',
    maxHeight: '90%',
  }, verticalPosition === 'center' && {
    transform: 'translate(-50%, -50%)',
    top: '50%',
    maxHeight: '90%',
  }, verticalPosition === 'bottom' && {
    transform: 'translate(-50%)',
    bottom: '5%',
    top: 'auto',
    maxHeight: '90%',
  }, forMobileObject({
    borderRadius: 0,
    height: '100vh',
    left: 0,
    maxHeight: '100vh',
    padding: '1.25rem',
    top: 0,
    transform: 'translate(0)',
  })),
};

export type OnCloseModal = ((event: Record<string, unknown>, reason?: 'backdropClick' | 'escapeKeyDown') => void) | undefined;

interface LeadrModalContextProps {
  onClose: OnCloseModal,
}

export const LeadrModalContext = createContext<LeadrModalContextProps>({
  onClose: noop,
});

interface ViewProps extends Omit<ModalProps, 'children' | 'onClose'> {
  className?: string,
  verticalPosition?: 'top' | 'center' | 'bottom',
  children: ReactNode,
  leadrModalContextObject: LeadrModalContextProps,
  onClose: OnCloseModal
}

const View = ({
  className,
  verticalPosition = 'center',
  children,
  onClose,
  leadrModalContextObject,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrModalContext.Provider value={leadrModalContextObject}>
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={onClose}
      {...props}
    >
      <Box css={styles.modal(verticalPosition)} className={className}>
        {children}
      </Box>
    </Modal>
  </LeadrModalContext.Provider>
);

export type LeadrModalProps = Omit<ViewProps, 'leadrModalContextObject'>;

const LeadrModal = ({
  onClose,
  ...props
}: LeadrModalProps): JSX.Element => {
  const leadrModalContextObject = useMemo(() => ({
    onClose,
  }), [
    onClose,
  ]);

  const hookProps = {
    leadrModalContextObject,
    onClose,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

LeadrModal.Header = ModalHeader;
LeadrModal.Title = ModalTitle;
LeadrModal.Body = ModalBody;
LeadrModal.Footer = ModalFooter;

export default LeadrModal;
