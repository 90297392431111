import { SerializedStyles, css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { Fragment } from 'react';
import { AgendaTemplates } from '@leadr-hr/types';
import {
  GetCuratedAgendaTemplateDetails,
  GetAgendaTopicTemplateDetails,
  GetCuratedMeetingTemplateDetails,
  GetAgendaTemplateDetails,
  GetMeetingTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { createTopicTemplateDrawerTemplate } from '../CreateTopicTemplateDrawer';
import AgendaTopic from './AgendaTopic';
import AgendaSection from './AgendaSection';
import { getCanEditTemplate } from '../utils/getCanEditTemplateOrItem';

const styles = {
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '.625rem',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
  }),
  headerInfo: css({
    overflow: 'hidden',
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1.125rem',
  }, withTruncate()),
  creatorInfo: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.75rem',
    fontSize: '.625rem',
  }),
  templateCreator: css({
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
  boldText: css({
    fontWeight: 'bold',
  }),
  rightButton: css({
    whiteSpace: 'nowrap',
    marginLeft: '1rem',
  }),
  topicContainer: css({
    marginTop: '.625rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.375rem',
  }),
  headerSkeleton: css({
    maxWidth: '100%',
    width: '100%',
  }),
  topicCardSkeleton: css({
    height: '2.5rem',
    maxWidth: '100%',
  }),
  agendaSection: (applyMargin: boolean) => css({
    marginTop: applyMargin ? '1rem' : '',
  }),
};

interface ViewProps {
  title: string,
  renderRightButton?: (rightButtonStyles: SerializedStyles) => JSX.Element,
  agendaItems: GetAgendaTemplateDetails[] | GetCuratedAgendaTemplateDetails[],
  canEdit: boolean,
  creator: string,
  isManagerOnly: boolean,
  isPrivate?: boolean,
  handleEditTemplate: () => void,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
  isIndividualQuestionsTemplate: boolean,
  renderEmptyState?: () => JSX.Element,
  renderBody?: (agendaItems: GetAgendaTemplateDetails[] | GetCuratedAgendaTemplateDetails[]) => JSX.Element,
}

const View = ({
  title,
  creator,
  renderRightButton,
  agendaItems,
  canEdit,
  isManagerOnly,
  isPrivate,
  handleEditTemplate,
  renderBody,
  addTopicToMeeting,
  isIndividualQuestionsTemplate,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <div css={styles.header}>
      <div css={styles.headerInfo}>
        <h3 css={styles.title}>{title}</h3>
        {(creator || canEdit) && (
          <div css={styles.creatorInfo}>
            {isIndividualQuestionsTemplate && (
              <h4 css={styles.templateCreator}>
                Topics created here are
                {isPrivate && (
                  <span>
                    <span css={styles.boldText}> private </span>
                    to you.
                  </span>
                )}
                {!isPrivate && (
                  <span>
                    <span css={styles.boldText}> public </span>
                    to your organization.
                  </span>
                )}
              </h4>
            )}
            {creator && !isIndividualQuestionsTemplate && (
              <h4 css={styles.templateCreator}>
                {isManagerOnly && (
                  <span>
                    Manager Topic
                    <span> &#x2022; </span>
                  </span>
                )}
                {`Created by ${creator}`}
              </h4>
            )}
            {canEdit && (
              <LeadrButton
                variant="text"
                textButtonColor={palette.brand.indigo}
                onClick={handleEditTemplate}
                data-test-id="meetingTopicSuggestionsEditTemplate"
              >
                Edit Template
              </LeadrButton>
            )}
          </div>
        )}
      </div>
      {renderRightButton?.(styles.rightButton)}
    </div>
    <div css={styles.topicContainer}>
      {renderBody?.(agendaItems)}
      {!renderBody && (
        <>
          {agendaItems.map((agendaItem, index) => (
            <Fragment key={agendaItem.id}>
              {agendaItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection && (
                <AgendaSection
                  css={styles.agendaSection(index !== 0)}
                  addTopicToMeeting={addTopicToMeeting}
                  agendaSection={agendaItem}
                />
              )}
              {agendaItem.type === AgendaTemplates.AgendaTemplateType.AgendaTopic && (
                <AgendaTopic
                  css={styles.agendaSection(index > 0 && agendaItems[index - 1].type === AgendaTemplates.AgendaTemplateType.AgendaSection)}
                  addTopicToMeeting={addTopicToMeeting}
                  agendaTopic={agendaItem}
                />
              )}
            </Fragment>
          ))}
        </>
      )}
    </div>
  </div>
);

const SkeletonView = (): JSX.Element => (
  <div>
    <div css={styles.header}>
      <SkeletonLoader css={styles.headerSkeleton} renderComponent={() => <></>} />
    </div>
    <MultipleSkeletonLoaders
      css={styles.topicContainer}
      renderSkeletonItem={() => (
        <SkeletonLoader
          css={styles.topicCardSkeleton}
          renderComponent={() => (
            <></>
          )}
        />
      )}
      numberOfSkeletons={3}
    />
  </div>
);

interface MeetingTemplateProps extends Omit<ViewProps,
    'handleEditTemplate' | 'title' | 'agendaItems' | 'canEdit' | 'isIndividualQuestionsTemplate' | 'isManagerOnly'> {
  template: GetMeetingTemplateDetails | GetCuratedMeetingTemplateDetails,
  maxTopics?: number,
  maxSections?: number,
  isPrivate?: boolean,
  closeDrawer?: () => void,
  openTopicSuggestionsDrawer?: () => void,
}

const MeetingTemplate = ({
  closeDrawer,
  openTopicSuggestionsDrawer,
  template,
  maxTopics,
  maxSections,
  isPrivate,
  ...props
}: MeetingTemplateProps): JSX.Element => {
  const {
    id,
    text: title,
    children: agendaItems,
  } = template;
  const isManagerOnly = 'isManagerOnly' in template ? template.isManagerOnly : false;
  const creatorName = 'creator' in template ? `${template.creator?.firstName || ''} ${template.creator?.lastName || ''}` : null;

  const canEdit = getCanEditTemplate(template);

  const dispatch = useDispatch();

  const handleEditTemplate = (): void => {
    closeDrawer?.();

    dispatch(pushDrawerAction({
      drawer: {
        ...createTopicTemplateDrawerTemplate,
        args: {
          initialTitle: title,
          initialTopics: agendaItems,
          initialIsManager: isManagerOnly,
          creatorName,
          isEditMode: true,
          templateId: id,
          openTopicSuggestionsDrawer,
          maxTopics,
          maxSections,
          isPrivate,
        },
      },
    }));
  };

  const hookProps = {
    handleEditTemplate,
    title,
    agendaItems,
    canEdit,
    isManagerOnly,
    isPrivate,
    isIndividualQuestionsTemplate: template.isIndividualQuestionsTemplate,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View, SkeletonView as TemplateSectionSkeleton };
export default MeetingTemplate;
