import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { ReceivedPlaylistDetail } from '~Learning/const/interfaces';
import { useEffect } from 'react';
import { buildQueryString } from '~Common/utils';
import { receivedLearningQueryKeys } from './queryKeys';

const getReceivedPlaylistDetails = async ({
  playlistId,
  asReadOnly,
  viewPerspectiveUid,
}: ReceivedPlaylistDetailHookParams): Promise<HttpCallReturn<ReceivedPlaylistDetail>> => {
  const queryString = buildQueryString({
    asReadOnly,
    viewPerspectiveUid,
  });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/received/${playlistId}${queryString}`;

  return getApi<ReceivedPlaylistDetail>(serverUrl);
};

interface ReceivedPlaylistDetailHookParams {
  playlistId: number,
  asReadOnly?: boolean,
  viewPerspectiveUid?: string,
}

interface GetReceivedPlaylistDetailHookReturn {
  detail: ReceivedPlaylistDetail | undefined,
  isLoading: boolean,
}

export const useGetReceivedPlaylistDetails = ({
  playlistId,
  asReadOnly,
  viewPerspectiveUid,
}: ReceivedPlaylistDetailHookParams): GetReceivedPlaylistDetailHookReturn => {
  const dispatch = useDispatch();

  const result = useQuery({
    queryKey: receivedLearningQueryKeys.playlistDetail(playlistId),
    queryFn: () => getReceivedPlaylistDetails({
      playlistId,
      asReadOnly,
      viewPerspectiveUid,
    }),
    meta: {
      errorMessage: 'There was an error getting the learning playlist information. Please try again.',
    },
  });

  useEffect(() => {
    if (result.isError) {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));
    }
  }, [dispatch, result.isError]);

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
