import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-duotone-svg-icons';

import { pallette } from '~Deprecated/ui/styles/colors';
import SearchBox from '~Deprecated/ui/components/Inputs/SearchBox';
import useMenu from '~Deprecated/hooks/useMenu';

const styles = {
  container: css`
    height: 3.5rem;
    background: ${pallette.gray5};
    border: 1px solid ${pallette.gray4};
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    padding: 1rem;
    margin-top: 1rem;

    button {
      height: 100% !important;
    }

    div:last-child{
      margin: -15px -15px -15px 0;
    }
  `,
  dropdownIcon: css`
    font-size: 2rem;
  `,
};

/**
 * @deprecated Old UI
 */
const SearchWithDropdown = ({
  placeholder,
  onSearchChange,
  onFilterChange,
  filterOptions,
  disableDropdown,
}) => {
  const {
    Component: MenuComponent,
  } = useMenu({
    menuAnchorText: (
      <FontAwesomeIcon icon={faCaretDown} css={styles.dropdownIcon} />
    ),
    menuItems: filterOptions,
    onItemSelected: onFilterChange,
  });

  return (
    <div css={styles.container}>
      <SearchBox
        onChange={onSearchChange}
        placeholder={placeholder}
      />
      {!disableDropdown && MenuComponent}
    </div>
  );
};

SearchWithDropdown.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  filterOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  disableDropdown: PropTypes.bool,
};

SearchWithDropdown.defaultProps = {
  placeholder: 'Search',
  filterOptions: [],
  disableDropdown: false,
};

export default SearchWithDropdown;
