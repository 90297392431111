import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const GoalPermissionsLearnMore = ({ ...props }): JSX.Element => (
  <LeadrButton
    variant="text"
    component="a"
    href="https://leadr.helpscoutdocs.com/article/25-create-a-goal#Goal-Permissions-1Zc51"
    target="_blank"
    data-test-id="goalsPermissionsLearnMore"
    {...props}
  >
    Learn More.
  </LeadrButton>
);

export default GoalPermissionsLearnMore;
