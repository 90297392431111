export const PROFILE_NAME_MAX_LENGTH = 50;
export const JOB_TITLE_LENGTH = 100;
export const DEPARTMENT_LENGTH = 50;
export const MAX_FEEDBACK_ATTENDEE_COUNT = 100;
export const MAX_TITLE_LIMIT = 250;
export const MAX_AGENDA_COUNT = 100;
export const MAX_ATTENDEE_COUNT = 50;
export const MAX_CONTENT_ATTENDEE_COUNT = 100;
export const MAX_NOTE_COUNT = 25;
export const MAX_DAYS_MEETING_EDIT_ALLOWED = 14;
export const MAX_MEETING_ATTENDEE_COUNT = 100;

// Frequencies
/**
 * @deprecated Old Types, use FrequencyType in packages/leadr-frontend/src/meetings/const/meetingsInterfaces.ts instead
 */
export const ONE_TIME_MEETING = 'ONE_TIME';
/**
 * @deprecated Old Types, use FrequencyType in packages/leadr-frontend/src/meetings/const/meetingsInterfaces.ts instead
 */
export const WEEKLY_MEETING = 'WEEKLY';
/**
 * @deprecated Old Types, use FrequencyType in packages/leadr-frontend/src/meetings/const/meetingsInterfaces.ts instead
 */
export const BI_WEEKLY_MEETING = 'BI_WEEKLY';
/**
 * @deprecated Old Types, use FrequencyType in packages/leadr-frontend/src/meetings/const/meetingsInterfaces.ts instead
 */
export const MONTHLY_MEETING = 'MONTHLY';
/**
 * @deprecated Old Types, use FrequencyType in packages/leadr-frontend/src/meetings/const/meetingsInterfaces.ts instead
 */
export const MONTHLY_LAST_MEETING = 'MONTHLY_LAST';
