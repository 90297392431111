import { connect } from 'react-redux';
import PersonalityTypesTopBar from '~Deprecated/ui/views/Nexus/PersonalityTypes/PersonalityTypesTopBar';
import { setActiveTabForPersonalityTest } from '~Deprecated/actions/personalities/loadPersonalities';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  setActiveTab: setActiveTabForPersonalityTest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalityTypesTopBar);
