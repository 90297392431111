import { Browser } from '@capacitor/browser';
import { uuid } from '~Common/utils/uuid';
import Cookies from 'js-cookie';
import { AUTH0_OAUTH_STATE_KEY } from '~Root/const';
import { IS_MOBILE_APP } from './isMobileApp';

export async function authRedirect(url: string): Promise<void> {
  if (IS_MOBILE_APP) {
    await Browser.open({
      presentationStyle: 'popover',
      url,
    });
  } else {
    window.location.replace(url);
  }
}

export function generateOAuthState(): string {
  const storedSlug = getOAuthState();
  if (storedSlug) {
    return storedSlug;
  }

  const oAuthSlug = uuid();
  Cookies.set(AUTH0_OAUTH_STATE_KEY, oAuthSlug);
  return oAuthSlug;
}

export function getOAuthState(): string | undefined {
  return Cookies.get(AUTH0_OAUTH_STATE_KEY);
}

export function redirectToAuth0(): void {
  const auth0Domain = process.env.AUTH0_URL ?? 'leadrapp.auth0.com';
  const redirectUri = IS_MOBILE_APP ? 'com.leadr.app:///' : window.location.origin;
  const baseUrl = `https://${auth0Domain}/authorize`;
  const state = generateOAuthState();

  const params = new URLSearchParams({
    prompt: 'login-id',
    response_type: 'code',
    client_id: process.env.AUTH0_CLIENT_ID ?? '',
    redirect_uri: redirectUri,
    scope: 'openid email',
    state,
  });

  const fullUrl = `${baseUrl}/?${params.toString()}`;
  void authRedirect(fullUrl);
}
