import { css } from '@emotion/react';
import moment from 'moment-timezone';
import { ComponentProps, useCallback, useMemo } from 'react';

import { Note, Person, UserStatus } from '~Common/const/interfaces';
import { useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { palette } from '~Common/styles/colors';
import { emotionCache } from '~Common/utils';
import CommentControls from '~ActionItems/components/Drawers/CreateEditActionItemDrawer/CommentControls';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';

const commentControlsContainer = css({
  display: 'none',
  position: 'absolute',
  top: 0,
  right: '1.5rem',
  zIndex: 500,
  transform: 'translate(0, -50%)',
});

const styles = {
  comment: (isPersisted: boolean) => css({
    display: 'flex',
    position: 'relative',
  }, isPersisted && {
    '&:hover': {
      [`.${emotionCache.key}-${commentControlsContainer.name}`]: {
        display: 'block !important',
      },
    },
  }),
  avatar: css({
    marginRight: '1rem',
  }),
  name: css({
    marginRight: '0.25rem',
    color: palette.neutrals.gray700,
    fontWeight: 700,
  }),
  bullet: css({
    marginRight: '0.25rem',
    color: palette.neutrals.gray700,
  }),
  created: css({
    color: palette.neutrals.gray500,
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  actionItemId: string,
  commentId: string,
  name: string,
  person?: Person,
  createdString: string,
  canEdit: boolean,
  shouldShowControls: boolean,
  isUserDeactivated: boolean,
  handleEdit: () => void,
  commentTextHTML: HTMLString,
}

const View = ({
  actionItemId,
  commentId,
  name,
  person,
  createdString,
  canEdit,
  shouldShowControls,
  isUserDeactivated,
  handleEdit,
  commentTextHTML,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.comment(!!commentId)} {...props}>
    <AvatarWithTooltip
      css={styles.avatar}
      firstName={person?.firstName ?? ''}
      lastName={person?.lastName ?? ''}
      profileImageUrl={person?.profileImageUrl}
      height={30}
      width={30}
      isDeactivated={isUserDeactivated}
    />

    <div>
      <div>
        <span css={styles.name}>
          { name }
        </span>

        <span css={styles.bullet}>
          •
        </span>

        <span css={styles.created}>
          { createdString }
        </span>
      </div>
      <HTMLRenderer htmlText={commentTextHTML} />
    </div>

    {shouldShowControls && (
      <div css={commentControlsContainer}>
        <CommentControls
          commentId={commentId}
          actionItemId={actionItemId}
          onEdit={handleEdit}
          canEdit={canEdit}
        />
      </div>
    )}
  </div>
);

export interface ActionItemComment extends ComponentProps<'div'> {
  actionItemId: string,
  comment: Note,
  onEdit: (comment: Note) => void,
}

const ActionItemComment = ({
  actionItemId,
  comment,
  onEdit,
  ...props
}: ActionItemComment): JSX.Element => {
  const currentOrgUserId = getOrganizationUserId();
  const { person } = useGetPersonById({ orgUserId: comment.creator });

  const canEdit = useMemo(() => (
    comment.creator === currentOrgUserId
  ), [comment, currentOrgUserId]);

  const { isAdmin, isExecutive } = useUserPermissions();
  const shouldShowControls = canEdit || isAdmin || isExecutive;

  const handleEdit = useCallback(() => {
    onEdit(comment);
  }, [onEdit, comment]);

  const isUserDeactivated = useMemo(() => (
    person?.administrativeStatus === UserStatus.inactive
  ), [person?.administrativeStatus]);

  const name = useMemo(() => {
    if (person) {
      const displayName = `${person?.firstName} ${person?.lastName.slice(0, 1)}.`;

      if (isUserDeactivated) {
        return `${displayName} (Deactivated)`;
      }

      return displayName;
    }

    return '';
  }, [person, isUserDeactivated]);

  const createdString = useMemo(() => {
    const { createdInMillis, lastModifiedInMillis } = comment;
    const friendlyDateString = moment(createdInMillis).fromNow();

    if (createdInMillis && lastModifiedInMillis && lastModifiedInMillis !== createdInMillis) {
      const editedString = moment(comment.lastModifiedInMillis).fromNow();
      return `${friendlyDateString} (Edited ${editedString})`;
    }

    return friendlyDateString;
  }, [comment]);

  const hookProps = {
    actionItemId,
    commentId: comment.id,
    commentTextHTML: comment.text,
    person,
    name,
    createdString,
    handleEdit,
    canEdit,
    shouldShowControls,
    isUserDeactivated,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItemComment;
