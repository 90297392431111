import { css } from '@emotion/react';
import { SelectChangeEvent } from '@mui/material';
import { useGetPeopleFilters } from '~Common/hooks/useGetPeopleFilters';
import { defaultPeoplePageFilterValue, usePeoplePageFilter } from '~People/stores/usePeoplePageFilter';
import { PeoplePageFilterType } from '~People/const/interfaces';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import ConditionalWrapper from '~Common/components/ConditionalWrapper';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import FilterBarCard from '~Common/components/Cards/FilterBarCard';
import ClearFiltersButton from '~Common/components/Cards/FilterBarCard/ClearFiltersButton';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import DepartmentDropdown from './DepartmentDropdown';
import UserGroupsDropdown from './UserGroupsDropdown';
import RolesDropdown from './RolesDropdown';
import RelativeDropdown from './RelativeDropdown';
import WorkingGeniusDropdown from './WorkingGeniusDropdown';

const styles = {
  filterBarCard: css({
    marginBottom: '1.875rem',
  }),
  filterDropdown: css({
    width: 'fit-content',
    padding: '0 .75rem',
    fontSize: '0.75rem',
  }),
  skeletonLoader: css({
    borderRadius: '0.25rem',
  }),
};

interface HandleFilterChangeParams {
  event: SelectChangeEvent<string | string[]>,
  filterType: PeoplePageFilterType | null,
}

interface ViewProps {
  filterValue: string,
  handleFilterChange: (params: HandleFilterChangeParams) => void,
  handleResetFilters: () => void,
  arePeopleFiltersLoading: boolean,
  workingGeniusValue: string[],
  hasWorkingGenius: boolean,
}

const View = ({
  filterValue,
  handleFilterChange,
  handleResetFilters,
  arePeopleFiltersLoading,
  workingGeniusValue,
  hasWorkingGenius,
}: ViewProps): JSX.Element => (
  <FilterBarCard css={styles.filterBarCard} minWidth="50rem">
    <ConditionalWrapper
      renderWrapperCondition={arePeopleFiltersLoading}
      renderWrapper={(renderChildren) => (
        <SkeletonLoader
          css={styles.skeletonLoader}
          variant="rectangular"
          renderComponent={() => <>{renderChildren()}</>}
        />
      )}
      renderContents={() => (
        <>
          <RelativeDropdown
            css={styles.filterDropdown}
            value={filterValue}
            handleFilterChange={(event) => handleFilterChange({
              event,
              filterType: null,
            })}
          />
          <RolesDropdown
            css={styles.filterDropdown}
            value={filterValue}
            handleFilterChange={(event) => handleFilterChange({
              event,
              filterType: PeoplePageFilterType.Role,
            })}
          />
          <DepartmentDropdown
            css={styles.filterDropdown}
            value={filterValue}
            handleFilterChange={(event) => handleFilterChange({
              event,
              filterType: PeoplePageFilterType.Department,
            })}
          />
          <UserGroupsDropdown
            css={styles.filterDropdown}
            value={filterValue}
            handleFilterChange={(event) => handleFilterChange({
              event,
              filterType: PeoplePageFilterType.UserGroup,
            })}
          />
          {hasWorkingGenius && (
            <WorkingGeniusDropdown
              css={styles.filterDropdown}
              value={workingGeniusValue}
              handleFilterChange={(event) => handleFilterChange({
                event,
                filterType: PeoplePageFilterType.WorkingGenius,
              })}
              otherFilterSelected={filterValue !== defaultPeoplePageFilterValue}
            />
          )}
          <ClearFiltersButton
            onClick={handleResetFilters}
            hasFilters={filterValue !== defaultPeoplePageFilterValue}
          />
        </>
      )}
    />
  </FilterBarCard>
);

const PeopleFilterBar = ({
  ...props
}): JSX.Element => {
  const { hasWorkingGenius } = useUserPermissions();
  const { isLoading } = useGetPeopleFilters();

  const [arePeopleFiltersLoading] = useSkeletonLoaders(isLoading);

  const {
    filterValue,
    setFilterValue,
    setFilterType,
    setWorkingGeniusValue,
    workingGeniusValue,
  } = usePeoplePageFilter((state) => ({
    filterValue: state.filterValue,
    setFilterValue: state.setFilterValue,
    filterType: state.filterType,
    setFilterType: state.setFilterType,
    workingGeniusValue: state.workingGeniusValue,
    setWorkingGeniusValue: state.setWorkingGeniusValue,
  }));

  const handleFilterChange = ({
    event,
    filterType: newFilterType,
  }: HandleFilterChangeParams): void => {
    if (newFilterType === PeoplePageFilterType.WorkingGenius) {
      setWorkingGeniusValue(event.target.value as string[]);

      if ((event.target.value as string[]).length === 0) {
        setFilterValue(defaultPeoplePageFilterValue);
      } else {
        setFilterValue((event.target.value as string[]).join(','));
      }
    } else {
      setFilterValue(event.target.value as string);
    }
    setFilterType(newFilterType);
  };

  const handleResetFilters = (): void => {
    setFilterValue(defaultPeoplePageFilterValue);
    setFilterType(null);
    setWorkingGeniusValue([]);
  };

  const hookProps = {
    filterValue,
    handleFilterChange,
    handleResetFilters,
    arePeopleFiltersLoading,
    workingGeniusValue,
    hasWorkingGenius,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default PeopleFilterBar;
