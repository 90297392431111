import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  SCORE_VALUE_NAMES, SCORE_NAMES_TO_COLORS, SCORE_NAME_VALUE_PROPTYPES, SCORE_NAMES,
} from '~Common/const/executiveInsights';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle, faMinus, faSquare, faTriangle,
} from '@fortawesome/pro-solid-svg-icons';

export const INSIGHT_STATUS_SIZE = {
  SMALL: 0,
  LARGE: 1,
  XSMALL: 2,
};

const SCORE_NAMES_TO_ICONS = {
  [SCORE_NAMES.GOOD]: faCircle,
  [SCORE_NAMES.OK]: faTriangle,
  [SCORE_NAMES.IMPROVE]: faSquare,
  [SCORE_NAMES.GREY]: faMinus,
};

const InsightStatusIndicator = ({ scoreValue, size, className }) => {
  const scoreName = SCORE_VALUE_NAMES[scoreValue];
  const color = SCORE_NAMES_TO_COLORS[scoreName];
  const dimension = useMemo(() => {
    if (size === INSIGHT_STATUS_SIZE.SMALL) return 16;
    if (size === INSIGHT_STATUS_SIZE.LARGE) return 32;
    return 12;
  }, [size]);

  const dynamicStyle = css`
    width: ${dimension}px;
    height: ${dimension}px;
    color: ${color};
    
    & svg {
      position: relative;
      width: ${dimension}px;
      height: ${dimension}px;
    }
    `;

  return (
    <div
      css={dynamicStyle}
      className={`insightStatusIndicator ${className}`}
    >
      <FontAwesomeIcon
        size={dimension}
        icon={SCORE_NAMES_TO_ICONS[scoreName]}
        color={color}
      />
    </div>
  );
};

InsightStatusIndicator.propTypes = {
  scoreValue: SCORE_NAME_VALUE_PROPTYPES.isRequired,
  size: PropTypes.oneOf([
    INSIGHT_STATUS_SIZE.SMALL,
    INSIGHT_STATUS_SIZE.LARGE,
  ]),
  className: PropTypes.string,
};

InsightStatusIndicator.defaultProps = {
  size: INSIGHT_STATUS_SIZE.SMALL,
  className: '',
};

export default InsightStatusIndicator;
