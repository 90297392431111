import { call, put } from 'redux-saga/effects';
import {
  forgotPasswordService,
} from '~Deprecated/services/authenticationService';
import * as types from '../actions';
import { isLoadingAction } from '~Deprecated/actions/loading/isLoading';

export function* forgotPasswordSaga(payload) {
  try {
    yield put(isLoadingAction(true));

    const responseObj = yield call(forgotPasswordService, payload);
    const { message, response, status } = responseObj;
    const result = {};
    if (status === 200 && response) {
      result.success = message;
      yield put({
        type: types.FORGOT_PASSWORD_SUCCESS,
        response: result,
      });
    } else {
      if (response) {
        result.error = message;
      }
      yield put({
        type: types.FORGOT_PASSWORD_ERROR,
        response: result,
      });
    }
  } catch (error) {
    yield put({
      type: types.FORGOT_PASSWORD_ERROR,
      error,
    });
  } finally {
    yield put(isLoadingAction(false));
  }
}
