import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { palette, hexToRGBA } from '~Common/styles/colors';

const style = css`
  background-color: ${hexToRGBA(palette.brand.sky, 0.1)};
  color: ${palette.neutrals.gray800};
  padding: 16px;
  text-align:center;

  h5 {
    font-size: 12px;
    line-height: 2em;
  }
`;

const DashedBoxWithText = ({ text, renderBody, ...props }) => (
  <div
    css={style}
    {...props}
  >
    {renderBody ? renderBody() : (
      <p>{text}</p>
    )}
  </div>
);

DashedBoxWithText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  renderBody: PropTypes.func,
};

DashedBoxWithText.defaultProps = {
  text: null,
  className: '',
  renderBody: null,
};

export default DashedBoxWithText;
