import { getOrganizationId } from '~Common/utils/localStorage';

export const meetingKeys = {
  all: [getOrganizationId() ?? '', 'huddles'] as const,
  details: () => [...meetingKeys.all, 'detail'] as const,
  detail: (huddleId: string) => [...meetingKeys.details(), huddleId] as const,
  agendaLists: () => [...meetingKeys.details(), 'agendaList'] as const,
  agendaList: (huddleId: string) => [...meetingKeys.agendaLists(), huddleId] as const,
  attachmentPreSignedDownloadUrl: (huddleId: string, attachmentId: number) => [...meetingKeys.agendaList(huddleId), 'attachments', attachmentId],
};
