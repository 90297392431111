import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { sortByItems } from '~Meetings/const/goals';
import { SelectChangeEvent } from '@mui/material';
import { Goals } from '@leadr-hr/types';
import SortByDropdown from './SortByDropdown';

const styles = {
  dropdown: css({
    width: 'min-content',
  }),
  label: css({
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
};

interface ViewProps {
  isMobile: boolean,
  sortBy: Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title,
  onSortByChange: (event: SelectChangeEvent<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>) => void,
}

const View = ({
  isMobile,
  sortBy,
  onSortByChange,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {isMobile && (
      <div>
        <p css={styles.label}>Sort by</p>
        <SortByDropdown
          css={styles.dropdown}
          data-test-id="meetingsGoalsSortByDropdown"
          items={sortByItems}
          sortBy={sortBy}
          onSortByChange={onSortByChange}
          renderValue={(value) => (<span>{sortByItems.find((item) => item.value === value)?.text}</span>)}
        />
      </div>
    )}
    {!isMobile && (
      <SortByDropdown
        css={styles.dropdown}
        data-test-id="meetingsGoalsSortByDropdown"
        items={sortByItems}
        sortBy={sortBy}
        onSortByChange={onSortByChange}
        renderValue={(value) => (<span>{`Sort by: ${sortByItems.find((item) => item.value === value)?.text ?? ''}`}</span>)}
      />
    )}
  </div>
);

type ReactiveSortByDropdownProps = Pick<ViewProps, 'sortBy' | 'onSortByChange'>;

const ReactiveSortByDropdown = ({ ...props }: ReactiveSortByDropdownProps): JSX.Element => {
  const isMobile = useIsMobileQuery();

  const hookProps = {
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ReactiveSortByDropdown;
