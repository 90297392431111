import { Dispatch, SetStateAction, useState } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { meetingRightPanelHeader, meetingRightPanelHeaderButton } from '~Meetings/const/sharedStyles';
import { css } from '@emotion/react';
import { PrivateMeetingNotes } from './PrivateMeetingNotes';

const styles = {
  transition: css({
    minHeight: '3.5rem',
    transition: 'height 100ms ease',
  }),
};

interface ViewProps {
  meetingFactoryId: string,
  showMeetingNotes: boolean,
  setShowMeetingNotes: Dispatch<SetStateAction<boolean>>,
}

const View = ({
  meetingFactoryId,
  showMeetingNotes,
  setShowMeetingNotes,
}: ViewProps): JSX.Element => (
  <div>
    <header css={meetingRightPanelHeader}>
      <p>
        Private Meeting Notes
      </p>
      <LeadrButton
        css={meetingRightPanelHeaderButton}
        variant="text"
        data-test-id="toggleShowPrivateMeetingNotes"
        onClick={() => setShowMeetingNotes(!showMeetingNotes)}
      >
        {showMeetingNotes ? 'HIDE' : 'SHOW'}
      </LeadrButton>
    </header>
    <div css={styles.transition}>
      <PrivateMeetingNotes
        meetingFactoryId={meetingFactoryId}
        isHidden={!showMeetingNotes}
      />
    </div>
  </div>
);

interface DesktopMeetingNotesSectionProps {
  meetingFactoryId: string,
}

export const DesktopMeetingNotesSection = ({
  meetingFactoryId,
}: DesktopMeetingNotesSectionProps): JSX.Element => {
  const [showMeetingNotes, setShowMeetingNotes] = useState(true);

  const hookProps = {
    meetingFactoryId,
    showMeetingNotes,
    setShowMeetingNotes,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
