import { IconDefinition, faCircle, faSquare } from '@fortawesome/pro-solid-svg-icons';
import { Goals } from '@leadr-hr/types';
import { palette } from '~Common/styles/colors';

export interface GoalDetailsParams {
  goalId: string,
}

export enum ViewPerspective {
  Open = 'open',
  Completed = 'completed',
  Create = 'create',
}
export interface GoalTypeOption<T extends string | number> {
  value: T,
  text: string,
}

export const GoalStatusLabel: Record<Goals.GoalStatus, string> = {
  [Goals.GoalStatus.OnTrack]: 'On Track',
  [Goals.GoalStatus.Caution]: 'At Risk',
  [Goals.GoalStatus.Behind]: 'Off Track',
  [Goals.GoalStatus.Blocked]: 'Blocked',

  [Goals.GoalStatus.Completed]: 'Completed',
  [Goals.GoalStatus.PartiallyCompleted]: 'Partially Completed',
  [Goals.GoalStatus.Missed]: 'Missed',
  [Goals.GoalStatus.Canceled]: 'Canceled',
} as const;

export function getGoalStatusIcon(status: Goals.GoalStatus): IconDefinition {
  if (Goals.isGoalStatusComplete(status)) {
    return faSquare;
  }

  return faCircle;
}

export const GoalStatusColor: Record<Goals.GoalStatus, string> = {
  [Goals.GoalStatus.OnTrack]: palette.brand.green,
  [Goals.GoalStatus.Caution]: palette.brand.orange,
  [Goals.GoalStatus.Behind]: palette.brand.red,
  [Goals.GoalStatus.Blocked]: palette.neutrals.gray600,

  [Goals.GoalStatus.Completed]: palette.brand.green,
  [Goals.GoalStatus.PartiallyCompleted]: palette.brand.orange,
  [Goals.GoalStatus.Missed]: palette.brand.red,
  [Goals.GoalStatus.Canceled]: palette.neutrals.gray600,
};

export const OpenGoalStatuses: Goals.GoalStatus[] = [
  Goals.GoalStatus.OnTrack,
  Goals.GoalStatus.Caution,
  Goals.GoalStatus.Behind,
];

export const ClosedGoalStatuses: Goals.GoalStatus[] = [
  Goals.GoalStatus.Completed,
  Goals.GoalStatus.PartiallyCompleted,
  Goals.GoalStatus.Missed,
  Goals.GoalStatus.Canceled,
];

export enum FilterType {
  Status = 'status',
}
export type DefaultFilters = Record<string, string>;

export type SelectOption = {
  label: string,
  value: string,
  profileImage: string | undefined,
  jobTitle: string | undefined,
};

export interface GoalsUserShape {
  profileImageUrl: string;
  firstName: string;
  lastName: string;
  orgUserId: string,
  email?: string,
}

export enum HistoryFilter {
  search = 'search',
  status = 'status',
  owner = 'owner',
  participant = 'participant',
  priority = 'priority',
  category = 'category',
  team = 'team',
}

interface GoalParticipantShape {
  role: Goals.GoalParticipantRole,
  orgUserId: string;
  firstName: string;
  lastName: string;
  profileImageUrl: string;
  jobTitle: string;
}
export interface EditPayloads {
  editGoalPayload: Goals.Requests.UpdateGoalRequestPayload,
  participantsPayload: Goals.Requests.UpdateGoalParticipantsRequestPayload['participants'] | undefined,
}
export interface GoalShape {
  title: string,
  description: string,
  participants: GoalParticipantShape[],
  priority: Goals.GoalPriority,
  context: Goals.GoalContext,
  category: Goals.GoalCategory,
  externalLink: string,
  isPrivate: boolean,
  startTimeInMillis: number,
  endTimeInMillis: number,
  owner: string[],
}

export type ValidationErrors = {
  errors: string[];
};

export interface GoalAssignee {
  imgUrl?: string,
  name: string,
}
export interface GoalProgress {
  percentage?: number,
  status: Goals.GoalStatus,
}

export interface GoalRow {
  id: string,
  title: string,
  startDate: string,
  endDate: string,
  role: string,
  priority: string,
  progress: GoalProgress,
  owner: GoalAssignee,
  objectiveType?: Goals.GoalCategory,
  subText?: string,
  isPrivate: boolean,
  totalChildGoals?: number,
}

export interface CascadingGoalRow extends GoalRow {
  path: string[],
  totalChildGoals: number,
}

export enum LinkedGoalType {
  Parent = 'Parent',
  Supporting = 'Supporting',
}
