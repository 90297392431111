import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { RecognitionShape } from '~Recognition/const/types';
import { recognitionKeys } from '~Recognition/const/queryKeys';
import { DEFAULT_QUERY_OPTIONS_RECOGNITION } from '~Common/hooks/utils';
import { DEFAULT_EDIT_POST } from '~Recognition/const/defaults';

const getRecognitionById = ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<RecognitionShape>> => {
  const [id] = queryKey.slice(-1);
  const idWithoutCommas = id.replace(/,/g, '');
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/recognition/${idWithoutCommas}`;

  return getApi<RecognitionShape>(serverUrl, {});
};

interface useGetRecognitionProps {
  queryKey?: string[],
  id: number,
}

interface useGetRecognitionReturnProps {
  data: RecognitionShape | undefined,
  isLoading: boolean,
  isError: boolean,
}

export const useGetRecognitionById = ({ id }: useGetRecognitionProps): useGetRecognitionReturnProps => {
  const result = useQuery({
    queryKey: recognitionKeys.detail(id),
    queryFn: getRecognitionById,
    ...DEFAULT_QUERY_OPTIONS_RECOGNITION,
    enabled: id !== undefined && id !== DEFAULT_EDIT_POST.postId,
  });

  return {
    isLoading: result?.isLoading,
    isError: result?.isError,
    data: result?.data?.response as RecognitionShape,
  };
};
