import { css } from '@emotion/react';
import { faLightbulb, faListCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';
import { withSpacedOutTitleStyles } from '~Common/styles/mixins';
import { LearningType } from '~Learning/const/interfaces';

const styles = {
  learningTypeInfo: css({
    display: 'flex',
    alignItems: 'center',
  }),
  icon: css({
    color: palette.brand.indigo,
  }),
  text: css({
    marginLeft: '.625rem',
    whiteSpace: 'nowrap',
  }, withSpacedOutTitleStyles()),
};

interface LearningTypeInfoProps extends ComponentProps<'div'> {
  learningType: LearningType,
}

const LearningTypeInfo = ({
  learningType,
  ...props
}: LearningTypeInfoProps): JSX.Element => (
  <div css={styles.learningTypeInfo} {...props}>
    {learningType === LearningType.SINGLE_LEARNING && (
      <>
        <FontAwesomeIcon css={styles.icon} icon={faLightbulb} />
        <div css={styles.text}>
          SINGLE LEARNING
        </div>
      </>
    )}
    {learningType === LearningType.PLAYLIST && (
      <>
        <FontAwesomeIcon css={styles.icon} icon={faListCheck} />
        <div css={styles.text}>
          LEARNING PLAYLIST
        </div>
      </>
    )}
  </div>
);

export default LearningTypeInfo;
