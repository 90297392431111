import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import CollapseButton from '~Meetings/components/topic-suggestions/shared/AgendaSection/CollapseButton';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { HoverState, useHoverState } from '~Common/hooks/useHoverState';
import { useContext } from 'react';
import {
  GetAgendaSectionTemplateDetails,
  GetCuratedAgendaSectionTemplateDetails,
  GetAgendaTopicTemplateDetails,
  GetCuratedAgendaTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import CollapseContent from './CollapseContent';
import { useIsTopicAddedToMeeting } from '../../stores/useIsTopicAddedToMeeting';
import { MeetingInfoContext } from '../../contexts/MeetingInfoContext';

const styles = {
  agendaSection: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  sectionTopContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  text: css({
    color: palette.neutrals.gray900,
    fontWeight: 600,
  }),
  addSectionToMeetingButton: (isHovering: boolean) => css({
    marginLeft: 'auto',
    visiblity: isHovering ? 'visible' : 'hidden',
  }),
};

interface ViewProps extends HoverState {
  text: string,
  disableCollapseButton: boolean,
  isAddedToMeeting: boolean,
  handleAddSectionToMeeting: () => void,
  id: string,
  agendaTopics: GetAgendaTopicTemplateDetails[] | GetCuratedAgendaTemplateDetails[],
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
}

const View = ({
  text,
  disableCollapseButton,
  isAddedToMeeting,
  handleAddSectionToMeeting,
  id,
  isHovering,
  handleMouseEnter,
  handleMouseLeave,
  agendaTopics,
  addTopicToMeeting,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.agendaSection}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    {...props}
  >
    <div css={styles.sectionTopContainer}>
      <CollapseButton
        sectionLocalId={id}
        disabled={disableCollapseButton}
      />
      <span css={styles.text}>
        {text}
      </span>
      <LeadrButton
        css={styles.addSectionToMeetingButton(isHovering)}
        size="mini"
        variant="text"
        textButtonColor={palette.brand.indigo}
        data-test-id="meetingTemplatesAddSectionToMeeting"
        disabled={isAddedToMeeting}
        onClick={handleAddSectionToMeeting}
      >
        {isAddedToMeeting ? 'Section Added' : '+ Add Section'}
      </LeadrButton>
    </div>
    <CollapseContent
      sectionId={id}
      agendaTopics={agendaTopics}
      addTopicToMeeting={addTopicToMeeting}
    />
  </div>
);

interface AgendaSectionProps {
  agendaSection: GetAgendaSectionTemplateDetails | GetCuratedAgendaSectionTemplateDetails,
  isFirstInList?: boolean,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
}

const AgendaSection = ({
  agendaSection,
  addTopicToMeeting,
  isFirstInList = false,
  ...props
}: AgendaSectionProps): JSX.Element => {
  const disableCollapseButton = agendaSection.children.length === 0;
  const isMobile = useIsMobileQuery();

  const { huddleId } = useContext(MeetingInfoContext);

  const { getIsTopicInMeeting } = useIsTopicAddedToMeeting(huddleId);
  const isAddedToMeeting = getIsTopicInMeeting(agendaSection.text, agendaSection.type);

  const {
    isHovering,
    handleMouseEnter,
    handleMouseLeave,
  } = useHoverState();

  const handleAddSectionToMeeting = (): void => {
    addTopicToMeeting(agendaSection);
  };

  const hookProps = {
    text: agendaSection.text,
    disableCollapseButton,
    isMobile,
    id: agendaSection.id,
    handleAddSectionToMeeting,
    isAddedToMeeting,
    isFirstInList,
    isHovering,
    handleMouseEnter,
    handleMouseLeave,
    agendaTopics: agendaSection.children,
    addTopicToMeeting,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaSection;
