import { useGenericStoreAndLocalStorage } from '~Common/hooks/useGenericStoreAndLocalStorage';
import { REQUEST_TYPE } from '~Deprecated/common/const/request-type';

export const useSurveyRequestTypeFilter = () => {
  const [value, setValue] = useGenericStoreAndLocalStorage({
    localStorageKey: 'surveyRequestTypeFilter',
    defaultValue: REQUEST_TYPE.RECEIVED,
    genericStoreProps: {
      module: 'leadr',
      workflow: 'surveys',
      feature: 'surveys-request-type-filter',
    },
  });

  return [
    value,
    setValue,
  ];
};
