import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { InsightsReport } from '~Home/const/interfaces';
import { homeQueryKeys } from '~Home/hooks/queryKeys';

interface GetInsightsPeersInterface {
  orgId: string,
}

export interface InsightsPeer {
  orgUserId: string,
  report?: InsightsReport
}

export interface GetPeopleInsightsResponseInterface {
  currentUser: InsightsPeer,
  manager: InsightsPeer,
  peers: InsightsPeer[],
  directReports?: InsightsPeer[],
}

const getInsightsPeers = async ({ orgId }: GetInsightsPeersInterface):Promise<HttpCallReturn<GetPeopleInsightsResponseInterface>> => {
  const URL = {
    host: hosts.action,
    uri: `/organizations/${orgId}/insights/peers`,
  };

  return getApi(URL, {});
};

export const useInsightsPeers = (): UseQueryResult<HttpCallReturn<GetPeopleInsightsResponseInterface>> => {
  const orgId = getOrganizationId() as string;

  return useQuery({
    queryKey: homeQueryKeys.insightsPeers(orgId),
    queryFn: () => getInsightsPeers({
      orgId,
    }),
  });
};
