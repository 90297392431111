import { SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';
import { DropdownItem } from '~Common/V3/components/Dropdown';
import { useGetPeopleFilters } from '~Common/hooks/useGetPeopleFilters';
import FilterDropdownView from './DropdownView';

interface ViewProps {
  roleOptions: DropdownItem[],
  value: string,
  handleFilterChange: (event: SelectChangeEvent) => void,
}

const View = ({
  roleOptions,
  value,
  handleFilterChange,
  ...props
}: ViewProps): JSX.Element => (
  <FilterDropdownView
    options={roleOptions}
    value={value}
    handleFilterChange={handleFilterChange}
    labelForNoValue="Role"
    data-test-id="peopleRoleDropdown"
    {...props}
  />
);

type RolesDropdownProps = Omit<ViewProps, 'roleOptions' | 'isInvalidDropdownValue'>;

const RolesDropdown = ({
  value,
  ...props
}: RolesDropdownProps): JSX.Element => {
  const { accountTypes } = useGetPeopleFilters();

  const roleOptions = useMemo(() => accountTypes.map((role) => ({
    text: role.name,
    value: role.typeId,
    'data-test-id': `${role.name}Option`,
  })), [accountTypes]);

  const hookProps = {
    roleOptions,
    value,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default RolesDropdown;
