import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import DrawerInfo from '~Common/components/Drawers/Info';
import { TOPIC_SHAPE } from '~Common/const/proptypes';
import DashedBoxWithText from '~Deprecated/ui/components/EmptyStates/DashedBoxWithText';
import FeedbackQuestion from '~Deprecated/ui/components/Feedback/FeedbackQuestion';

const styles = {
  sectionHeader: css`
    margin: 20px 0;
  `,
  questions: css`
    margin: 10px 0;
  `,
};

const RequestQuestions = ({
  questions,
  isReadOnly,
  onEditQuestion,
  onRemoveQuestion,
}) => {
  const renderContent = () => (
    <h4>
      Click Templates or Add Question to get started with requesting feedback from others.
    </h4>
  );

  return (
    <>
      <DrawerInfo
        css={styles.sectionHeader}
        info="Add questions to your request here."
      />
      {questions.length === 0 && !isReadOnly && (
        <DashedBoxWithText
          text="Click ‘Add Question’ to get started."
          renderBody={renderContent}
        />
      )}
      { questions.map((question, index) => (
        <FeedbackQuestion
          css={styles.questions}
          question={question}
          key={question.rank ?? question.id}
          onEditQuestion={onEditQuestion}
          onRemoveQuestion={onRemoveQuestion}
          tabIndex={index}
          disabled={isReadOnly}
        />
      )) }
    </>
  );
};

RequestQuestions.propTypes = {
  questions: PropTypes.arrayOf(TOPIC_SHAPE),
  isReadOnly: PropTypes.bool,
  onEditQuestion: PropTypes.func,
  onRemoveQuestion: PropTypes.func,
};

RequestQuestions.defaultProps = {
  questions: [],
  isReadOnly: true,
  onEditQuestion: () => {},
  onRemoveQuestion: () => {},
};

export default RequestQuestions;
