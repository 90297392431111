import { css } from '@emotion/react';
import ButtonGroup from '@mui/material/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo } from 'react';
import { faChevronLeft, faChevronRight, faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { NO_RESPONSE_TEXT } from '~Common/const/surveys';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import FlatButton from '~Common/V3/components/FlatButton';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import {
  SCRequestedLearningDetail, FrontendQuestionResponse,
} from '~Learning/const/interfaces';
import Tooltip from '~Common/components/Tooltip';
import { useRequestedLearningAssigneeResults } from '~Learning/hooks/utils/useRequestedLearningAssigneeResults';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { addToMeetingDrawerTemplate } from '~Learning/components/Shared/AddToMeetingDrawer';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import { isCurrentUser } from '~Common/utils';
import { withTruncate } from '~Common/styles/mixins';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import RequestedLearningTabWrapper from '../RequestedLearningTabWrapper';

const styles = {
  questionCard: css({
    marginTop: '1.125rem',
  }),
  title: css({
    fontSize: '1.125rem',
    fontWeight: 500,
    color: palette.neutrals.gray900,
    marginBottom: '.625rem',
  }),
  responseText: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
  participantResultsText: css({
    margin: '.875rem 0',
    fontWeight: 600,
    color: palette.neutrals.gray400,
    fontSize: '.6875rem',
  }),
  personHeader: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 0,
  }),
  personInfoContainer: css({
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
  }),
  name: css({
    fontSize: '1.5rem',
    fontWeight: 700,
    color: palette.neutrals.gray900,
  }, withTruncate()),
  jobTitle: css({
    fontSize: '.875rem',
    color: palette.neutrals.gray500,
  }, withTruncate()),
  personInfo: css({
    marginLeft: '1rem',
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    marginRight: '0.5rem',
  }),
  buttonGroup: css({
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    borderRadius: '0.5rem',
  }),
  button: css({
    padding: '.50rem 1.15rem',
    '&:not(:last-child)': {
      borderRight: `.0625rem solid ${palette.neutrals.gray300}`,
    },
  }),
  buttonIconContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  icon: css({
    height: '1.125rem',
    color: palette.neutrals.gray500,
  }),
  rightButtonsContainer: css({
    display: 'flex',
    maxHeight: '2.625rem',
  }),
  overflowMenuButton: css({
    marginLeft: '1.125rem',
    padding: '.625rem 1.125rem',
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    borderRadius: '.1875rem',
  }),
};
interface ViewProps {
  learning: SCRequestedLearningDetail,
  profileImageUrl?: string,
  firstName: string,
  lastName: string,
  jobTitle: string,
  onNextClick: () => void,
  onPreviousClick: () => void,
  questionsWithResponse: FrontendQuestionResponse[],
  overflowMenuItems: MenuItem[][],
  isMobile: boolean,
  showViewParticipantsButton: boolean,
}

const View = ({
  learning,
  profileImageUrl,
  firstName,
  lastName,
  jobTitle,
  onNextClick,
  onPreviousClick,
  questionsWithResponse,
  overflowMenuItems,
  isMobile,
  showViewParticipantsButton,
}: ViewProps): JSX.Element => (
  <RequestedLearningTabWrapper
    title={learning.title}
    introduction={learning.introduction}
    assignees={learning.assignees}
    dueDate={learning.dueDate}
    learningId={learning.id}
    status={learning.status}
    showViewParticipantsButton={showViewParticipantsButton}
    renderDrawerContents={() => (
      <>
        <div css={styles.participantResultsText}>PARTICIPANT RESULTS</div>
        <div css={styles.personHeader}>
          <div css={styles.personInfoContainer}>
            <Tooltip content={`${firstName} ${lastName}`}>
              <div>
                <SquareAvatar
                  imgUrl={profileImageUrl}
                  name={`${firstName} ${lastName}`}
                  width={50}
                  height={50}
                  includeInitials
                />
              </div>
            </Tooltip>
            {!isMobile && (
              <div css={styles.personInfo}>
                <div css={styles.name}>{`${firstName} ${lastName}`}</div>
                <div css={styles.jobTitle}>{jobTitle}</div>
              </div>
            )}
          </div>
          <div css={styles.rightButtonsContainer}>
            <ButtonGroup css={styles.buttonGroup} disableElevation variant="contained">
              <FlatButton
                css={styles.button}
                onClick={onPreviousClick}
                renderButtonContents={() => (
                  <Tooltip interactive content="Previous">
                    <div css={styles.buttonIconContainer}><FontAwesomeIcon css={styles.icon} icon={faChevronLeft} /></div>
                  </Tooltip>
                )}
              />
              <FlatButton
                css={styles.button}
                onClick={onNextClick}
                renderButtonContents={() => (
                  <Tooltip interactive content="Next">
                    <div css={styles.buttonIconContainer}><FontAwesomeIcon css={styles.icon} icon={faChevronRight} /></div>
                  </Tooltip>
                )}
              />
            </ButtonGroup>
            <OverflowMenu
              menuItems={overflowMenuItems}
              renderOverflowMenuButton={(doOpen) => (
                <LeadrButton
                  onClick={doOpen}
                  css={styles.overflowMenuButton}
                  variant="text"
                  data-test-id="learningOverflowMenu"
                >
                  <FontAwesomeIcon css={styles.icon} icon={faEllipsisVertical} />
                </LeadrButton>
              )}
            />
          </div>
        </div>
        {questionsWithResponse?.map((questionWithResponse) => (
          <Card
            css={styles.questionCard}
            renderContents={() => (
              <div>
                <HTMLRenderer css={styles.title} htmlText={questionWithResponse.topic} />
                {questionWithResponse.responses?.text && (
                  <HTMLRenderer css={styles.responseText} htmlText={questionWithResponse.responses?.text ?? NO_RESPONSE_TEXT} />
                )}
                {!questionWithResponse.responses?.text && (
                  <div css={styles.responseText}>{NO_RESPONSE_TEXT}</div>
                )}
              </div>
            )}
          />
        ))}
      </>
    )}
  />
);

interface PersonTabProps {
  learning: SCRequestedLearningDetail,
  showViewParticipantsButton: boolean,
}

const PersonTab = ({
  learning, ...props
}: PersonTabProps): JSX.Element => {
  const dispatch = useDispatch();
  const [personIndex, setPersonIndex] = useRequestedLearningAssigneeResults();

  const isMobile = useIsMobileQuery();

  // Handles an edge case where you are viewing the participant in the PersonTab and you recall that participant
  useEffect(() => {
    if (!learning.assignees?.[personIndex]?.assignee) {
      setPersonIndex(0);
    }
  }, [learning.assignees, personIndex, setPersonIndex]);

  const person = useMemo(() => learning.assignees?.[personIndex]?.assignee || learning.assignees[0].assignee, [personIndex, learning.assignees]);

  const canAddToMeeting = isCurrentUser(person?.id);

  const overflowMenuItems = useMemo(() => [[
    {
      text: 'Add to Meeting',
      icon: faPlus,
      isDeactivated: canAddToMeeting,
      tooltip: canAddToMeeting ? 'You cannot add a learning to a meeting with yourself.' : '',
      onClick: () => {
        if (!canAddToMeeting) {
          dispatch(pushDrawerAction({
            drawer: {
              ...addToMeetingDrawerTemplate,
              args: {
                otherUserId: person.id,
                learningId: learning.id,
              },
            },
          }));
        }
      },
    },
  ]], [dispatch, canAddToMeeting, learning.id, person.id]);

  const questionsWithResponse = useMemo(() => learning.questions.map((question) => (
    { ...question, responses: question.responses.filter((response) => response.assignee.id === person.id)[0] }
  )), [person, learning.questions]);

  const onNextClick = (): void => {
    if (personIndex + 1 < learning.assignees.length) {
      setPersonIndex(personIndex + 1);
    } else {
      setPersonIndex(0);
    }
  };

  const onPreviousClick = (): void => {
    if (personIndex - 1 >= 0) {
      setPersonIndex(personIndex - 1);
    }
  };

  const hookProps = {
    onNextClick,
    onPreviousClick,
    ...person,
    questionsWithResponse,
    learning,
    overflowMenuItems,
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default PersonTab;
