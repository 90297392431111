import { css, SerializedStyles } from '@emotion/react';
import { hexToRGBA, palette } from '~Common/styles/colors';

interface SwitchColorStyles {
  trackBackgroundColor: string,
  checkedTrackBackgroundColor: string,
  thumbBackgroundColor: string,
  textColor: string,
  disabledTrackBackgroundColor: string,
  disabledTextColor: string,
}

// Colors
const primarySwitchColors: SwitchColorStyles = {
  trackBackgroundColor: palette.neutrals.gray400,
  checkedTrackBackgroundColor: palette.brand.indigo,
  thumbBackgroundColor: palette.neutrals.white,
  textColor: palette.neutrals.white,
  disabledTrackBackgroundColor: hexToRGBA(palette.brand.indigo, 0.1),
  disabledTextColor: hexToRGBA(palette.brand.indigo, 0.3),
};

const commonSwitchStyles = (switchColorStyles: SwitchColorStyles): SwitchCommonStyles => ({
  switchRoot: css({
    display: 'inline-block',
    position: 'relative',
  }),
  switchTrack: (checked: boolean, disabled: boolean) => css({
    backgroundColor: switchColorStyles.trackBackgroundColor,
    display: 'block',
    height: '100%',
    width: '100%',
  }, checked && {
    backgroundColor: switchColorStyles.checkedTrackBackgroundColor,
  }, disabled && {
    backgroundColor: switchColorStyles.disabledTrackBackgroundColor,
  }),
  switchThumb: css({
    position: 'absolute',
    display: 'block',
    backgroundColor: switchColorStyles.thumbBackgroundColor,
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  }),
  text: (disabled: boolean) => css({
    color: switchColorStyles.textColor,
    fontWeight: 600,
    fontSize: '0.75rem',
    position: 'absolute',
  }, disabled && {
    color: switchColorStyles.disabledTextColor,
  }),
  switchInput: (disabled: boolean) => css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    zIndex: 1,
    margin: 0,
    cursor: 'pointer',
  }, disabled && {
    cursor: 'not-allowed',
  }),
});

const standardSwitchStyles = {
  switchRoot: css({
    width: '3.5rem',
    height: '1.5rem',
  }),
  switchTrack: css({
    borderRadius: '1.25rem',
  }),
  switchThumb: (checked: boolean) => css({
    width: '1rem',
    height: '1rem',
    borderRadius: '1.25rem',
    top: '.25rem',
    left: '.25rem',
  }, checked && {
    transform: 'translateX(2rem)',
  }),
  checkedText: css({
    top: '.25rem',
    left: '.75rem',
  }),
  uncheckedText: css({
    top: '.25rem',
    right: '.75rem',
  }),
};

// Currently there is no need for these switches, but in the future it might be useful, so leaving these here
const largeSwitchStyles = {
  switchRoot: css({
    width: '5rem',
    height: '2.5rem',
  }),
  switchTrack: css({
    borderRadius: '1.25rem',
  }),
  switchThumb: (checked: boolean) => css({
    width: '2rem',
    height: '2rem',
    borderRadius: '1.25rem',
    top: '.25rem',
    left: '.25rem',
  }, checked && {
    transform: 'translateX(2.5625rem)',
  }),
  checkedText: css({
    top: '.75rem',
    left: '.75rem',
  }),
  uncheckedText: css({
    top: '.75rem',
    right: '.75rem',
  }),
};
const smallSwitchStyles = {
  switchRoot: css({}),
  switchTrack: css({}),
  switchThumb: (checked: boolean) => css({
  }, checked && {
  }),
  checkedText: css({}),
  uncheckedText: css({}),
};

// Types
/*
  Right now we only need the primary indigo switch, if we want more feel free to define these
*/
/* 'secondary', 'sky', 'warning', 'danger', 'dark', 'light' */
const switchColorArray = ['primary'] as const;
export type SwitchColor = typeof switchColorArray[number];

const switchSizeArray = ['standard', 'small', 'large'] as const;
export type SwitchSize = typeof switchSizeArray[number];

const sizeMap = {
  small: smallSwitchStyles,
  standard: standardSwitchStyles,
  large: largeSwitchStyles,
};

const colorMap = {
  primary: primarySwitchColors,
  secondary: palette.brand.indigo,
  sky: palette.brand.sky,
  warning: palette.brand.orange,
  danger: palette.brand.red,
  dark: palette.neutrals.gray900,
  light: palette.neutrals.gray200,
};

/*
  These are here because Typescript does not actually force you to pass the right string in
  So, if someone passes something in that is not a proper value, which would be easy to do in a file that is not Typescript,
  we still want to show the default button, and not break the app
*/
function isOfTypeSwitchColor(color: SwitchColor): boolean {
  return switchColorArray.includes(color);
}

function isOfTypeSwitchSize(size: SwitchSize): boolean {
  return switchSizeArray.includes(size);
}

export interface SwitchStyleProps {
  color?: SwitchColor,
  size?: SwitchSize,
}

export interface SwitchCommonStyles {
  switchRoot: SerializedStyles,
  switchTrack: (checked: boolean, disabled: boolean) => SerializedStyles,
  switchThumb: SerializedStyles,
  switchInput: (disabled: boolean) => SerializedStyles,
  text: (disabled: boolean) => SerializedStyles,
}

export interface SwitchSizeStyles {
  switchRoot: SerializedStyles,
  switchTrack: SerializedStyles,
  switchThumb: (checked: boolean) => SerializedStyles,
  checkedText: SerializedStyles,
  uncheckedText: SerializedStyles,
}

interface GetSwitchStylesReturn {
  switchCommonStyles: SwitchCommonStyles,
  switchSizeStyles: SwitchSizeStyles,
}

export const getSwitchStyles = (
  { color = 'primary', size = 'standard' }: SwitchStyleProps = {},
): GetSwitchStylesReturn => {
  let switchColorStyles = colorMap.primary;
  let switchSizeStyles = sizeMap.standard;

  if (isOfTypeSwitchColor(color)) {
    switchColorStyles = colorMap[color];
  }

  if (isOfTypeSwitchSize(size)) {
    switchSizeStyles = sizeMap[size];
  }

  return {
    switchCommonStyles: commonSwitchStyles(switchColorStyles),
    switchSizeStyles,
  };
};
