import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { useEffect } from 'react';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';

export function useMobileAppUpdate(): void {
  const version = useFeatureFlag<string>('webClientCurrentBuildVersion');

  useEffect(() => {
    const updateBundle = async (): Promise<void> => {
      if (IS_MOBILE_APP && version && version !== 'OFF') {
        const currentBundleVersion = (await CapacitorUpdater.current())?.bundle?.version;

        if (currentBundleVersion !== version) {
          const bundleUrl = `${process.env.MOBILE_APP_BUCKET_URL ?? ''}/mobile_bundle_${version}.zip`;
          const newBundle = await CapacitorUpdater.download({
            url: bundleUrl,
            version,
          });

          if (newBundle) {
            await CapacitorUpdater.set(newBundle);
          }
        }
      }
    };

    void updateBundle();
  }, [version]);
}
