import { useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from '~Common/hooks/useLocalStorage';
import { getStorageItem, removeStorageItem } from '~Common/utils/localStorage';

export interface Draft {
  lastAccessed?: number;
  expiry?: {
    time: number;
    absolute: boolean;
  };
  [key: string]: unknown;
}

export interface DraftData {
  draft: Draft,
  setDraft: (data: Draft) => void
  removeDraft: () => void;
  buildDraftKey: (draftKey: string[]) => string;
  removeDraftKeyFromLocalStorage: (draftKey: string) => void;
}

export interface UseDraftOptions {
  updateLastAccessedTime?: boolean;
  expiry?: {
    time: number;
    absolute: boolean;
  }
}

const defaultExpiryTime = 15 * 24 * 60 * 60 * 1000; // 15 days

export const useDraft = (key: string[] = [], options: UseDraftOptions = {
  updateLastAccessedTime: true,
  expiry: {
    time: defaultExpiryTime,
    absolute: false,
  },
}): DraftData => {
  const buildDraftKey = useCallback((draftKey: string[]): string => `draft-${draftKey.join('#')}`, []);

  // Non-hook way to remove a draft key for looping
  const removeDraftKeyFromLocalStorage = useCallback((draftKey: string): void => {
    removeStorageItem(draftKey);
  }, []);

  const stringKey = buildDraftKey(key);
  const fallbackValue = useMemo(() => ({}), []);
  const [draft, setData, removeDraft] = useLocalStorage<Draft>(stringKey, fallbackValue);

  const setDraft = useCallback((d: {[key: string]: unknown}) => {
    if (!key?.length) {
      return;
    }
    const storedState = JSON.parse(getStorageItem(stringKey) ?? '{}') as Draft;
    if (!storedState.expiresAt) {
      storedState.expiry = options.expiry;
    }
    setData({
      ...storedState,
      ...d,
      lastAccessed: Date.now(),
    } as Draft);
  }, [setData, key.length, options.expiry, stringKey]);

  useEffect(() => {
    if (!options.updateLastAccessedTime) {
      return;
    }
    setDraft({
      lastAccessed: Date.now(),
    });
    // Don't want this to happen on every render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    draft,
    setDraft,
    removeDraft,
    buildDraftKey,
    removeDraftKeyFromLocalStorage,
  };
};
