import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import MuiSwitch from '@mui/material/Switch';

import { useState, useEffect } from 'react';
import { noop } from '~Deprecated/utils';
import { validationShape, VALIDATION_DEFAULT } from '~Common/const/forms';

import {
  switchTrackActiveColor,
  switchTrackColor,
  switchThumbColor,
} from '~Deprecated/ui/styles/colors';

const style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  
  & .MuiSwitch-root {
    width: 42px;
    height: 26px;
    padding: 0;
    margin-right: 10px;
  }

  & .MuiButtonBase-root {
    padding: 1px;
  }

  & .Mui-checked {
    transform: translateX(16px);
    color: white;
  }

  & .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    color: ${switchThumbColor};
  }

  & .MuiSwitch-track {
    border-radius: 13px;
    border: 1px solid gray;
    background-color: ${switchTrackColor};
    opacity: 1;
    border: none;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  & .Mui-checked+.MuiSwitch-track {
    background-color: ${switchTrackActiveColor} !important;
    opacity: 1 !important;
  }
`;

/**
 * @deprecated Old Switch UI, use packages/leadr-frontend/src/common/V3/components/uncontrolled/Switch.tsx instead
 */
const Switch = ({
  className, checked, onChange, onClick, label, validation, ...props
}) => {
  const [currentValue, updateState] = useState(checked);

  useEffect(() => {
    updateState(checked);
  }, [checked]);

  const doChange = () => {
    const value = !currentValue;

    updateState(value);
    if (onChange) {
      setTimeout(() => {
        onChange(value);
      });
    }
  };

  const doClick = () => {
    const value = !currentValue;

    updateState(value);
    if (onClick) {
      setTimeout(() => {
        onClick(value);
      });
    }
  };

  return (
    <div
      css={style}
      className={className}
    >
      <MuiSwitch
        checked={currentValue}
        value={currentValue}
        onChange={doChange}
        onClick={doClick}
        inputProps={{
          ...validation,
        }}
        {...props}
      />
      <h5>{label}</h5>
    </div>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  validation: validationShape,
  onClick: PropTypes.func,
};

Switch.defaultProps = {
  className: '',
  checked: false,
  onChange: noop,
  label: 'Is Awesome?',
  validation: VALIDATION_DEFAULT,
  onClick: noop,
};

export default Switch;
