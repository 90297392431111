import { flatten } from 'flat';
import { useMemo } from 'react';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';

/**
 * Build Cached Object w/ Relationships -> entries --> flatten --> Stringify value --> filter --> return item[0] if item[1] contains value
 * @param {[string]} ids
 * @param {{}} peopleData
 * @param {''} searchTerm
 * @param {string} idName
 * @param {useCallback fn()} buildRelationships
 * @returns [string] of ids
 */
const globalSearch = (ids, peopleData, searchTerm, buildRelationships, idName) => {
  if (peopleData && ids?.length > 0) {
    let itemsWithPeople = [];

    if (buildRelationships) {
      itemsWithPeople = buildRelationships(peopleData);
    }

    itemsWithPeople = itemsWithPeople.map((item) => (
      [
        item[idName],
        item,
        Object.values(flatten(item)).join(','),
      ]
    ));

    if (searchTerm && searchTerm !== '') {
      itemsWithPeople = itemsWithPeople.filter(([, , value]) => value.toLowerCase().includes(searchTerm.trim().toLowerCase()));
    }

    const returnIds = [];
    const returnData = {};

    itemsWithPeople.forEach(([id, item]) => {
      returnIds.push(id);
      returnData[id] = item;
    });

    return {
      ids: returnIds,
      data: returnData,
    };
  }

  return {
    ids: [],
    data: null,
  };
};

/**
 * @param {[string]} ids
 * @param {useCallback fn()} buildRelationships
 * @param {''} searchTerm
 * @param {string} idName
 * @returns memoized list of ids
 */
export const useGlobalSearch = (ids, buildRelationships = null, searchTerm = '', idName = 'id') => {
  const { data: peopleData } = usePeopleQuery();

  return useMemo(() => globalSearch(ids, peopleData, searchTerm, buildRelationships, idName), [ids, peopleData, searchTerm, buildRelationships, idName]);
};
