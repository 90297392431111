import { Goals } from '@leadr-hr/types';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FORM_STYLES } from '~Goals/const/styles';
import { UseFormReturn } from 'react-hook-form';
import { FormValues } from '~Goals/schemata/CreateGoalSchemata';
import DatePicker from '~Common/V3/components/DatePicker';
import moment from 'moment';

const styles = {
  ...FORM_STYLES,
};

interface GoalDateSelectionProps {
  formContext: UseFormReturn<FormValues>,
  goal: Goals.Requests.CreateGoalRequestPayload,
  endDate: Date,
  startTimeInMillis: number | undefined,
  isMobile: boolean,
}

export function GoalDateSelection({
  formContext,
  goal,
  endDate,
  startTimeInMillis,
  isMobile,
}: GoalDateSelectionProps): JSX.Element {
  return (
    <>
      <DatePicker
        required
        initialDate={goal?.startTimeInMillis as unknown as Date}
        onDateSelected={({ date: newDate }) => formContext.setValue('startTimeInMillis', newDate?.valueOf() ?? new Date().getTime())}
        renderLabel={() => (
          <div css={styles.datePickerLabel}>
            <div>Start Date</div>
            <FontAwesomeIcon icon={faCalendar} />
          </div>
        )}
        name="startTimeInMillis"
        disablePast={false}
        css={[styles.inputField(isMobile), styles.datePicker, styles.removeMargin(isMobile)]}
      />
      <DatePicker
        required
        initialDate={endDate}
        // @ts-expect-error Typing is weird here - This date picker needs to be fixed
        minDate={moment(startTimeInMillis)}
        onDateSelected={({ date: newDate }) => formContext.setValue('endTimeInMillis', newDate?.valueOf() ?? new Date().getTime())}
        renderLabel={() => (
          <div css={styles.datePickerLabel}>
            <div>End Date</div>
            <FontAwesomeIcon icon={faCalendar} />
          </div>
        )}
        name="endTimeInMillis"
        disablePast={false}
        css={[styles.inputField(isMobile), styles.datePicker, styles.removeMargin(isMobile)]}
      />
    </>
  );
}
