import { css } from '@emotion/react';
import { faDash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'react';

import { palette } from '~Common/styles/colors';

const styles = {
  indeterminateIconContainer: css({
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '.5rem',
    backgroundColor: palette.brand.indigo,
    color: palette.neutrals.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  checkedIcon: css({
    width: '60%',
  }),
};

const IndeterminateIcon = ({ ...props }: ComponentProps<'div'>): JSX.Element => (
  <div css={styles.indeterminateIconContainer} {...props}>
    <FontAwesomeIcon icon={faDash} css={styles.checkedIcon} />
  </div>
);

export default IndeterminateIcon;
