import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import RadioButtons from '~Common/components/Drawers/RadioButton';
import { SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';
import { useGetFormattedMultipleChoiceMemoized } from '~Surveys/Hooks/useGetFormattedMultipleChoice';
import { withTruncate } from '~Common/styles/mixins';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import QuestionPreview from './index';

const styles = {
  questionText: css`
    font-size: 1.125rem;
    font-weight: bold;
    ${withTruncate()};
  `,
  radioButtons: css`
    h4 {
      word-break: break-all;
    }
  `,
};

const View = ({
  question, description, formattedMultipleChoiceOptions, ...props
}) => (
  <QuestionPreview
    renderBody={() => (
      <div {...props}>
        <div css={styles.questionText}>{question}</div>
        {description && (
          <HTMLRenderer htmlText={description} />
        )}
        <RadioButtons disabled css={styles.radioButtons} name="radioPreview" options={formattedMultipleChoiceOptions} />
      </div>
    )}
  />
);

View.propTypes = {
  question: PropTypes.string,
  description: PropTypes.string,
  formattedMultipleChoiceOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

View.defaultProps = {
  question: '',
  description: '',
};

const MultipleChoicePreview = ({ question, ...props }) => {
  const formattedMultipleChoiceOptions = useGetFormattedMultipleChoiceMemoized(question.multipleChoiceOptions);

  const hookProps = {
    ...question,
    formattedMultipleChoiceOptions,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

MultipleChoicePreview.propTypes = {
  question: SURVEY_QUESTION_SHAPE,
};

MultipleChoicePreview.defaultProps = {
  question: {},
};

export { View };
export default MultipleChoicePreview;
