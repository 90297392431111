import { CommonComponentSharedProps } from '~Common/const/interfaces';
import { ButtonProps } from '@mui/material';
import { ButtonUnstyled } from '@mui/base';
import { ForwardedRef, forwardRef } from 'react';
import { buildLeadrButtonStyles, LeadrButtonStyleProps } from './styles';
import { IconAndText } from '../ButtonHelpers/IconAndText';

export type LeadrButtonProps<C extends React.ElementType> =
  Omit<ButtonProps<C, {component?: C}>, 'color' | 'size' | 'variant'>
  & LeadrButtonStyleProps
  & CommonComponentSharedProps & {
    textButtonColor?: string,
  };

/**
 * To check out examples, see the examples.md file in the directory of this component
  @param data-test-id - Used for automated tests, 'Button' will get appended automatically. Example format "feature(buttonResponsibility)", example "learningCreateNew" becomes "learningCreateNewButton"
  @param css - Passed in as css={styles.whateverStyles}, should only be used for positioning the component, not modifying the look
  @param color - The color of the button, defaults to 'primary'
  @param variant - The variant of the button, defaults to 'default'
  @param size - The size of the button, defaults to 'standard'
  @param component - The root level component used for the button, defaults to 'button'
*/
const Button = forwardRef(<C extends React.ElementType> ({
  color,
  variant,
  size,
  disabled,
  children,
  textButtonColor, // Only if the variant is text
  'data-test-id': dataTestId,
  ...props
}: LeadrButtonProps<C>, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => (
  <ButtonUnstyled
    ref={ref}
    css={buildLeadrButtonStyles({
      color,
      size,
      variant,
      textButtonColor,
    })}
    disabled={disabled}
    aria-disabled={disabled}
    data-test-id={`${dataTestId}Button`}
    {...props}
  >
    {children}
  </ButtonUnstyled>
  ));

interface LeadrButtonComponent<
  C extends React.ElementType
> extends React.ForwardRefExoticComponent<LeadrButtonProps<C> & React.RefAttributes<HTMLButtonElement>> {
  IconAndText: typeof IconAndText;
}

const LeadrButton: LeadrButtonComponent<React.ElementType> = Button as unknown as LeadrButtonComponent<React.ElementType>;
LeadrButton.IconAndText = IconAndText;

export default LeadrButton;
