const useList = ({
  queryConfig,
  queryOverride,
}) => {
  const {
    useQueryList,
  } = queryConfig;

  const result = useQueryList(queryOverride);

  result.initialPageItemCount = result?.data?.pages?.[0]?.response?.items?.length;

  return result;
};

export default useList;
