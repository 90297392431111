import { Route, Switch } from 'react-router-dom';

import { BASE_ROUTE, MOBILE_APP_LANDING_PAGE, POST_REGISTRATION_LANDING } from '~Common/const/routes';
import { AuthLandingPage } from '~Root/components/auth/AuthLandingPage';
import { MobileAppLandingPage } from '~Root/components/auth/MobileAppLandingPage';

const Public = () => (
  <Switch>
    <Route
      path={POST_REGISTRATION_LANDING}
      component={() => <AuthLandingPage validateState={false} />}
    />
    <Route
      path={MOBILE_APP_LANDING_PAGE}
      exact
      component={MobileAppLandingPage}
    />
    <Route
      path={BASE_ROUTE}
      component={AuthLandingPage}
    />
  </Switch>
);

export default Public;
