import { css } from '@emotion/react';

export const slideFromBottom = (index) => css`
  @keyframes animatebottom { 
    from { 
      bottom: ${-50 - (10 * index)}px; 
      opacity: 0;
    } 
    to { 
      bottom: 0; 
      opacity: 1;
    }
  }

  position: relative;
  animation-name: animatebottom;
  animation-duration: ${500 + (index * 20)}ms;
`;
