import { createAction } from '@reduxjs/toolkit';

import { registerAction } from '~Deprecated/reducers/storage';
import { setRefreshToken, setToken } from '~Deprecated/utils/cookies';
import { mutateState } from '~Deprecated/utils/reduxUtils';

const setAccessTokenAction = createAction('LEADR.SET_ACCESS_TOKEN');

function setAccessToken(state, { payload }) {
  const { jsonWebToken, jsonRefreshToken } = payload;
  return mutateState(state, (draftState) => {
    draftState.jsonWebToken = jsonWebToken;
    setToken(jsonWebToken, jsonRefreshToken);

    draftState.jsonRefreshToken = jsonRefreshToken;
    setRefreshToken(jsonRefreshToken);
    return draftState;
  });
}

registerAction(setAccessTokenAction, setAccessToken);

export {
  setAccessTokenAction,
};
