import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { toast } from '~Common/components/Toasts';
import {
  HttpCallReturn,
  patchApi,
  putApi,
} from '~Deprecated/services/HttpService';
import { hosts } from '~Deprecated/services/config';
import { presignAndUploadFile } from '~Common/hooks/useFileUpload';
import { createOrganizationAddress } from '~Nexus/hooks/useCreateOrganization';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { disablePulseSurveyMutation } from '~Insights/hooks/useAdminSettingsDrawer';
import { buildRequestBody } from '~Common/utils';
import { loadOrganizationInsightsAction } from '~Deprecated/actions/organizationInsights/loadOrganizationInsights';
import { useNexusOrgStore } from '~Deprecated/ui/views/Nexus/useNexusOrgStore';
import { nexusSortOptions } from '~Deprecated/ui/views/Nexus/NexusOrganizations';
import { queryClient } from '~Common/const/queryClient';
import { getOrgDetailsQuery } from '~Common/hooks/useGetOrgDetails';

interface EditOrganizationRequestData {
  organizationName: string,
  salesForceCustomerAccountNumber: number,
  salesforceId: number,
  enableISolvedModule: boolean,
  enableHRModule: boolean,
  easeUrl?: boolean,
  enableReviews: boolean,
  enableInsights: boolean,
  organizationInTrial: boolean,
  trialEndsAt?: number,
  enableOneWayCalendarIntegration: boolean,
  customerLogo?: string,
}

interface EditOrganizationResponse {
  organizationId: string,
  organizationUserId: string,
}

export const editOrganization = async (
  id: string,
  requestData: EditOrganizationRequestData,
): Promise<HttpCallReturn<EditOrganizationResponse>> => {
  const url = {
    host: hosts.org,
    uri: `/organizations/${id}`,
  };

  return patchApi(url, buildRequestBody(requestData, true));
};

interface EditOrganizationAddressRequestData {
  id: string
  addressId: string,
  street: string,
  city: string,
  stateId: string,
  zip: number,
}

interface EditOrganizationAddressResponse {
  addressId: string,
}

export const editOrganizationAddress = async ({
  id,
  ...requestData
}: EditOrganizationAddressRequestData): Promise<HttpCallReturn<EditOrganizationAddressResponse>> => {
  const url = {
    host: hosts.org,
    uri: `/organizations/${id}/addresses`,
  };

  return putApi(url, buildRequestBody(requestData));
};

interface EditOrganizationProps {
  id: string,
  addressId?: string,
  organizationName: string,
  street: string,
  city: string,
  stateId: string,
  zip: number,
  salesForceCustomerAccountNumber: number,
  salesforceId: number,
  logo: File,
  enableISolvedModule: boolean,
  enableHRModule: boolean,
  easeUrl?: boolean,
  enableReviews: boolean,
  enableInsights: boolean,
  organizationInTrial: boolean,
  trialEndsAt?: number,
  enableOneWayCalendarIntegration: boolean,
}

export const combinedEditOrganization = async ({
  id,
  addressId,
  logo,
  street,
  city,
  stateId,
  zip,
  enableInsights,
  ...other
}: EditOrganizationProps): Promise<HttpCallReturn<EditOrganizationResponse>> => {
  let presignAndUploadFileResponse;

  if (!enableInsights) {
    // Insights is disabled - Now let make sure pulse is turned off
    const disablePulseSurveyResponse = await disablePulseSurveyMutation(id);

    if (disablePulseSurveyResponse.status !== 200) {
      throw disablePulseSurveyResponse;
    }
  }

  if (addressId) {
    const editOrganizationAddressResponse = await editOrganizationAddress({
      id,
      addressId,
      street,
      city,
      stateId,
      zip,
    });

    if (editOrganizationAddressResponse.status !== 200) {
      throw editOrganizationAddressResponse;
    }
  } else {
    const createOrganizationAddressResponse = await createOrganizationAddress({
      id,
      street,
      city,
      stateId,
      zip,
    });

    if (createOrganizationAddressResponse.status !== 200) {
      throw createOrganizationAddressResponse;
    }
  }

  if (logo?.size) {
    presignAndUploadFileResponse = await presignAndUploadFile({
      orgId: id,
      file: logo,
    });

    if (presignAndUploadFileResponse.status !== 200) {
      throw presignAndUploadFileResponse;
    }
  }

  const editOrganizationResponse = await editOrganization(
    id,
    {
      enableInsights,
      customerLogo: presignAndUploadFileResponse?.response?.resourceUrl,
      ...other,
    },
  );

  if (editOrganizationResponse.status !== 200) {
    throw editOrganizationResponse;
  }

  return editOrganizationResponse;
};

interface UseEditOrganizationProps {
  drawerName?: string,
}

export const useEditOrganization = ({
  drawerName,
}: UseEditOrganizationProps): UseMutationResult<HttpCallReturn<EditOrganizationResponse>, unknown, EditOrganizationProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const dispatch = useDispatch();

  const {
    searchKeyword,
    shouldFilterByFavorites,
    sortBy,
    page,
  } = useNexusOrgStore((state) => ({
    searchKeyword: state.searchKeyword,
    shouldFilterByFavorites: state.shouldFilterByFavorites,
    sortBy: state.sortBy,
    page: state.page,
  }));

  return useMutation({
    mutationFn: combinedEditOrganization,

    onMutate: () => {
      toastId.current = toast.info('Editing organization...', { autoClose: false });
    },

    onError: () => {
      toast.update(toastId.current, {
        render: 'The organization update failed due to a technical issue.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },

    onSuccess: (data, { id }) => {
      if (drawerName) {
        // @ts-expect-error Removed once we type or replace Redux
        dispatch(popDrawerAction({ drawerName }));
      }

      // This is here to refetch the organizations list. This should be updated to react-query, once that API is on react-query
      // @ts-expect-error Removed once we type or replace Redux
      dispatch(loadOrganizationInsightsAction({
        // @ts-expect-error Remove once we type NexusSortOptions
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        sort: nexusSortOptions[sortBy].data,
        filterFavourites: shouldFilterByFavorites,
        page,
        searchKeyword,
      }));

      void queryClient.invalidateQueries({ queryKey: getOrgDetailsQuery(id).queryKey });

      toast.update(toastId.current, {
        render: 'The organization was successfully updated.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });
};
