import { css } from '@emotion/react';
import { Spinner } from '~Common/V3/components/Spinner';
import { palette } from '~Common/styles/colors';

const styles = {
  spinnerContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '2rem 0',
    width: '100%',
  }),
};

export const AuthLoader = (): JSX.Element => (
  <div css={styles.spinnerContainer}>
    <Spinner
      foregroundColor={palette.brand.indigo}
      size="6rem"
      lineSize="0.5rem"
    />
  </div>
);
