import { css } from '@emotion/react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';

const styles = {
  select: css({
    border: 0,
    color: palette.neutrals.gray700,
    fontFamily: 'ProximaNova',
    fontSize: '0.875rem',
    fontWeight: 500,
    fieldset: {
      display: 'none',
    },

    '& .MuiSelect-select': {
      padding: 0,
      paddingRight: '1.5rem !important',
    },
  }),
  menuItem: css({
    color: palette.neutrals.gray900,
    fontFamily: 'ProximaNova',
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: '0.5rem 1rem',
  }),
};

interface InlineSelectProps<T extends string = string> {
  onChange: (newValue: T) => void,
  options: Array<{
    label: string,
    value: T,
  }>,
  value: T,
}

// Formatting as a function because generic consts make JSX sad.
export function InlineSelect<T extends string = string>({
  onChange,
  options,
  value,
}: InlineSelectProps<T>): JSX.Element {
  return (
    <Select
      css={styles.select}
      value={value}
      onChange={(event) => onChange(event.target.value as T)}
      IconComponent={(props) => <FontAwesomeIcon icon={faAngleDown} {...props} />}
    >
      {options.map((option) => (
        <MenuItem
          css={styles.menuItem}
          key={option.label}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}
