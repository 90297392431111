import { useState } from 'react';
import { css } from '@emotion/react';
import { Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-duotone-svg-icons';
import { palette } from '~Common/styles/colors';
import { noop } from '~Deprecated/utils/';
import { gradients } from '~Deprecated/ui/styles/colors';

const style = css`
  background: ${palette.neutrals.gray100};
  padding: 7px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  align-items: center;
  letter-spacing: 0.5px;
  height: auto;
  min-height: unset;

  &:focus {
    outline: none !important;
  }

  ::-webkit-input-placeholder {
    color: ${palette.neutrals.gray800} !important;
  }

  label {
    color: ${palette.neutrals.gray700};
    font-weight: '400';
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }

  input {
    border: unset;
    background: ${palette.neutrals.gray100};
    box-shadow: unset;
    border-radius: unset;
    color: ${palette.neutrals.gray800};
    height: auto;
    margin: 0;
    padding: 0.3rem 0;
    font-weight: 700;
    font-size: 16px;
    min-height: 26px;

    &:focus {
      box-shadow: unset;
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${palette.neutrals.gray800};
    }
  }  
`;

const muiStyle = css({
  '.MuiAutocomplete-tag': {
    backgroundColor: palette.neutrals.gray300,
    '& .MuiChip-label': {
      color: palette.neutrals.gray700,
      fontWeight: '600',
    },
    '& .MuiChip-deleteIcon': {
      color: palette.neutrals.gray700,
    },
  },
  '.MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 12px)',
  },
});

const dropdownIconStyle = css`
  font-size: 1rem;
`;

const View = ({
  tags, initialValue, label, name, selectedTags, onChange, value, shouldDisableTagsFunction, ...props
}) => (
  <>
    <Autocomplete
      {...props}
      css={[style, muiStyle]}
      background={gradients.purple}
      multiple
      id="tags-outlined"
      options={tags}
      getOptionLabel={(option) => option.title}
      value={value}
      filterSelectedOptions
      fullWidth
      getOptionDisabled={() => (shouldDisableTagsFunction(value))}
      onChange={onChange}
      disableClearable
      popupIcon={(<FontAwesomeIcon icon={faCaretDown} css={dropdownIconStyle} />)}
      renderInput={(params) => (
        <TextField
          variant="standard"
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          label={(<label>{label}</label>)}
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            disableUnderline: true,
            autoComplete: 'off',
            ...params.InputProps,
          }}
        />
      )}
    />
    <input type="hidden" name={name} value={selectedTags} />
  </>
);

View.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
  initialValue: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  name: PropTypes.string,
  selectedTags: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  shouldDisableTagsFunction: PropTypes.func,
};

View.defaultProps = {
  tags: [],
  initialValue: [],
  label: '',
  name: '',
  shouldDisableTagsFunction: noop,
};

const Tags = ({
  initialValue, setCount, ...props
}) => {
  const initialValues = initialValue.map((value) => value.value);
  const initialSelectedTags = JSON.stringify(initialValues);
  const [selectedTags, setSelectedTags] = useState(initialSelectedTags);
  const [value, setValue] = useState(initialValue);

  const hookProps = {
    selectedTags,
    onChange: ((event, newValue) => {
      const newValues = newValue.map((tempValue) => tempValue.value);
      setSelectedTags(JSON.stringify(newValues));
      setValue(newValue);

      if (setCount) {
        setCount(newValues.length);
      }
    }),
    initialValue,
    value,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

Tags.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.object),
  initialStrengths: PropTypes.arrayOf(PropTypes.object),
  setCount: PropTypes.func,
};

Tags.defaultProps = {
  initialValue: [],
  initialStrengths: [],
  setCount: noop,
};

export { View };
export default Tags;
