import { create } from 'zustand';

interface PrintViewStoreState {
  isPrintView: boolean,
  setPrintView: (isPrintView: boolean) => void,
}

export const usePrintView = create<PrintViewStoreState>((set) => ({
  isPrintView: false,
  setPrintView: (isPrintView: boolean) => set(() => ({ isPrintView })),
}));
