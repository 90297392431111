import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

const activateUser = async (userId) => {
  const URL = `/organizations/${getOrganizationId()}/users/${userId}/activate`;

  return patchApi(URL);
};

export const useActivateUser = () => {
  const toastId = useRef(null);

  const mutation = useMutation({
    mutationFn: activateUser,
    onMutate: () => {
      toastId.current = toast.info('Activating the user...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'The activation failed. Please try again...',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'The activation was successful!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'people'] });
    },
  });

  return mutation.mutate;
};
