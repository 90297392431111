import { css } from '@emotion/react';
import { Autocomplete, TextField } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import RequiredIcon from '~Common/V3/components/RequiredIcon';
import { SelectOption, usePeopleTagPicker } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { UserGroup } from '~Common/const/userGroups';
import { palette } from '~Common/styles/colors';

const styles = {
  autocomplete: css({
    fieldset: {
      display: 'none',
    },
  }),
  autocompleteRow: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  }),
  autocompleteName: css({
    color: palette.neutrals.gray800,
    fontSize: '0.875rem',
    fontWeight: 500,
  }),
  autocompleteJobTitle: css({
    color: palette.neutrals.gray700,
    flex: 1,
    fontSize: '0.875rem',
    fontWeight: 300,
    textAlign: 'right',
  }),
  input: css({
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    paddingTop: '0.7rem',

    label: {
      color: palette.neutrals.gray700,
      fontWeight: 400,
      margin: '1rem 0 0 -0.1rem !important',
    },
  }),
  userButton: css({
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    padding: '0.5rem 0.75rem',
    width: '100%',
  }),
  label: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    fontWeight: 400,
  }),
  requiredIcon: css({
    position: 'absolute',
    top: '0.75rem',
    right: '1rem',
    zIndex: 1,
  }),
  userRow: css({
    alignItems: 'center',
    color: palette.neutrals.gray800,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
    fontWeight: 500,
    gap: '0.5rem',
    minHeight: '1.5rem',
  }),
  wrapper: css({
    position: 'relative',
  }),
};

interface ViewProps {
  isMobile: boolean,
  isOpen: boolean,
  label: string,
  name: string,
  onChange: (newValue: string) => void,
  peopleList: SelectOption[],
  profileImageUrl?: string,
  required: boolean,
  selectedUser: SelectOption | undefined,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
}

const View = ({
  isMobile,
  isOpen,
  label,
  name,
  onChange,
  peopleList,
  profileImageUrl,
  required,
  selectedUser,
  setIsOpen,
}: ViewProps): JSX.Element => (
  <div css={styles.wrapper}>
    {required && (
      <RequiredIcon css={styles.requiredIcon} />
    )}
    {isOpen && (
      <Autocomplete
        // css={[styles.formInput(false), styles.formSpacingTop, styles.autoComplete]}
        css={styles.autocomplete}
        options={peopleList}
        value={selectedUser}
        selectOnFocus
        open={isOpen}
        onBlur={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        onChange={(_, newValue) => {
          if (newValue) {
            onChange(newValue.value);
            setIsOpen(false);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              disableAnimation: true,
              shrink: true,
            }}
            autoFocus
            label={label}
            css={styles.input}
          />
        )}
        getOptionDisabled={(option) => option.disabled === true}
        renderOption={(optionProps, option) => (
          <li
            {...optionProps}
            key={option.value}
            css={styles.autocompleteRow}
          >
            <SquareAvatar
              // css={styles.tagsProfileImage}
              imgUrl={option.profileImageUrl}
              name={option.label}
              width={22}
              height={22}
            />
            <p css={styles.autocompleteName}>
              {option.label}
            </p>
            {!isMobile && (
            <p
              css={styles.autocompleteJobTitle}
            >
              {option.jobTitle}
            </p>
            )}
          </li>
        )}
      />
    )}
    {!isOpen && (
      <LeadrButton
        variant="text"
        css={styles.userButton}
        data-test-id={`${name}ActivateUserPicker`}
        onClick={() => setIsOpen(true)}
      >
        <div css={styles.label}>{label}</div>
        <div css={styles.userRow}>
          {selectedUser && (
            <>
              <SquareAvatar
                imgUrl={profileImageUrl}
                name={name}
                width={22}
                height={22}
              />
              <div>{name}</div>
            </>
          )}
        </div>
      </LeadrButton>
    )}
  </div>
);

interface SingleParticipantSelectProps {
  isMobile?: boolean,
  label: string,
  onChange: (newValue: string) => void,
  required?: boolean,
  value?: string, // orgUserId
  disabledIds?: string[],
  disableLimitedAccessUsers?: boolean,
}

export const SingleParticipantSelect = ({
  isMobile = false,
  label,
  required = false,
  onChange,
  value,
  disabledIds = [],
  disableLimitedAccessUsers = false,
}: SingleParticipantSelectProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const accountTypesToInclude = disableLimitedAccessUsers
    ? [UserGroup.Admin, UserGroup.Member, UserGroup.Executive]
    : [UserGroup.Admin, UserGroup.Member, UserGroup.LimitedAccess, UserGroup.Executive];

  const {
    peopleList,
  } = usePeopleTagPicker({
    administrativeStatusesToInclude: ['ACTIVE', 'INVITED'],
    allowSelf: false,
    disabledIds: [
      ...disabledIds,
    ],
    accountTypesToInclude,
  });

  const selectedUser = peopleList.find((person) => person.value === value) ?? {
    label: 'Leadr User',
  } as SelectOption;

  const {
    label: name = 'Leadr User',
    profileImageUrl,
  } = selectedUser;

  if (!peopleList?.length) {
    return (
      <SkeletonLoader renderComponent={() => <div css={styles.userButton} />} />
    );
  }

  const hookProps = {
    isMobile,
    isOpen,
    label,
    name,
    onChange,
    peopleList,
    profileImageUrl,
    required,
    selectedUser: selectedUser.label !== 'Leadr User' ? selectedUser : undefined,
    setIsOpen,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
