import { useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { impersonateUser } from './api';
import { setStorageItem } from '~Common/utils/localStorage';

export const useImpersonation = () => useMutation({
  mutationFn: impersonateUser,
  onMutate: (variables) => {

  },
  onError: (error, variables, context) => {

  },
  onSuccess: (data, variables, context) => {
    const key = uuidv4();
    setStorageItem(key, JSON.stringify({
      ...data.response,
      email: variables,
    }));
    window.open(`${window.location.origin}/impersonate?i=${key}`);
  },
});
