import { useEffect, useRef, useState } from 'react';
import '../styles.scss';
import { anime } from 'react-anime';
import { Portal } from 'react-portal';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const styles = {
  closeBtn: css`
    background: transparent;
    border: none;
    position: absolute;
    padding: 0;
    cursor: pointer;
    top: 12px;
    right: 12px;
    z-index: 2;
  `,
  closeBtnWithHeader: css`
    top: 16px;
    right: 16px;
  `,
};

/**
 * Used component as modal.
 * @param {*} props used to store all props.
 * All possible props are as follows.
 * * = mandatory props
 * @props *show: true | false.
 * @props *close: callback function to hide modal.
 * @props *title: to save title of modal.
 * @props *body: body of modal either HTML or any component.
 * @props *submit: call back function called on save | submit | ok button.
 * @props *close: call back function called on close | cancel button.
 * @props footer: true | false By default footer is hidden.
 * @props saveLabel: label for save button. By default label is Save.
 * @props cancelLabel: label for cancel button. By degault label is Close.
 * @props size: lg | sm.
 * @props position: centered  By default shown at top middle of screen.
 * @props isBackDropDisabled: Allow to close modal on backdrop click.
 */
/**
 * @deprecated Old UI, can be deleted after meetings templates is rewritten
 */
const LDRModal = (props) => {
  const childRef = useRef(null);
  /**
   * Used: if you set isBackDropDisabled  = true when calling <LDRModal>  then , it will not close on backdrop click.
   */
  const isBackDropDisabled = !!props.isBackDropDisabled;
  const [animationStatus, setAnimationStatus] = useState('incomplete');

  const onBackDrop = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (isBackDropDisabled) {
      return;
    }
    if (document.getSelection().type === 'Range') {
      return;
    }
    props.close && props.close(e);
  };

  function escapeKeyHandler(e) {
    if (e.key === 'Escape') {
      onBackDrop(e);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', escapeKeyHandler);
    return () => {
      window.removeEventListener('keydown', escapeKeyHandler);
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (props.show && animationStatus === 'incomplete' && childRef && childRef.current) {
      setAnimationStatus('animating');
      const animation = anime({
        targets: childRef.current,
        translateY: [-15, 0],
        opacity: [0, 1],
        duration: 250,
        easing: 'easeInOutQuad',
      });

      animation.finished.then(() => {
        if (mounted) setAnimationStatus('completed');
      });
    }

    return () => {
      mounted = false;
    };
  }, [props.show]);

  return (
    <Portal node={document && document.getElementById('modal-root')}>
      <div
        onClick={onBackDrop}
        className={`modal ${props.userInitiatedModal ? 'modal-main' : ''}`}
        tabIndex="-1"
        role="dialog"
        style={{ zIndex: `${props.zIndex}`, display: 'flex', opacity: animationStatus === 'completed' ? 1 : 0 }}
        ref={childRef}
      >
        <div
          className={`modal-dialog ${props.className}`}
          role="document"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="modal-content">
            {props.showHeader !== false && props.title && (
              <div className={`modal-header ${props.headerClass ? props.headerClass : ''}`}>
                {props.title}
              </div>
            )}
            {props.showClosebutton && (
              <button
                type="button"
                css={[styles.closeBtn, props.showHeader ? styles.closeBtnWithHeader : '']}
                aria-label="Close"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  props.close(e);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
            <div className={`modal-body ${props.bodyClass ? props.bodyClass : ''}`}>
              {props.body}
            </div>
            {props.footer && (
              <div
                className={`modal-footer ${props.className} ${props.isPostitonRight && 'justifyContentRight'}`}
              >
                {props.children}
              </div>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default LDRModal;
