import { css } from '@emotion/react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  forwardRef,
  useCallback, useEffect, useMemo,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { useUrlQuery } from '~Common/hooks/useUrlQuery';
import { TIMELINE_SHAPE, MEETING_DETAILS_SHAPE } from '~Common/const/proptypes';
import { palette } from '~Common/styles/colors';
import { noop } from '~Deprecated/utils';

const styles = {
  tileSharedStyles: css({
    borderRadius: '.3125rem',
    margin: '0 .75rem 0 0',
  }),
  tile: (isToday, isSkipped) => css({
    width: '3.125rem',
    height: '4.125rem',
    padding: '.5rem .75rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isSkipped ? palette.neutrals.gray300 : palette.neutrals.white,
    border: isToday ? `1px solid ${palette.brand.sky}` : 0,
    boxShadow: '0rem .3125rem .9375rem rgba(0, 0, 0, 0.07)',
    order: 0,
    cursor: 'pointer',
    flexShrink: 0,
  }),
  month: css({
    color: palette.neutrals.gray500,
    fontSize: '.6875rem',
  }),
  date: css({
    color: palette.neutrals.gray800,
    fontSize: '1.3125rem',
    fontWeight: 600,
  }),
  dotHolder: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '.3125rem',
  }),
  dot: (hue) => css({
    height: '.3125rem',
    width: '.3125rem',
    color: hue,
  }),
  icon: css({
    color: palette.brand.orange,
  }),
  skippedText: css({
    color: palette.neutrals.gray600,
    fontWeight: 600,
    fontSize: '0.5rem',
  }),
};

const { tileSharedStyles } = styles;

const View = forwardRef(({
  month, date, onClick, renderFeedBack, renderContent, renderHuddles, isSelected, isSkipped,
}, ref) => (
  <div ref={ref} role="button" onKeyDown={onClick} tabIndex="0" onClick={onClick} css={[styles.tile(isSelected, isSkipped), styles.tileSharedStyles]}>
    <div css={styles.month}>{month}</div>
    <div css={styles.date}>{date}</div>
    {isSkipped && (
      <div css={styles.skippedText}>SKIPPED</div>
    )}
    {!isSkipped && (
      <div css={styles.dotHolder}>
        {renderFeedBack()}
        {renderHuddles()}
        {renderContent()}
      </div>
    )}
  </div>
));

View.propTypes = {
  month: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  renderFeedBack: PropTypes.func,
  renderContent: PropTypes.func,
  renderHuddles: PropTypes.func,
  isSkipped: PropTypes.bool,
};

View.defaultProps = {
  renderFeedBack: noop,
  renderContent: noop,
  renderHuddles: noop,
  isSkipped: false,
};

const TimelineTile = forwardRef(({
  item, huddle, onClick, isSelected, ...props
}, ref) => {
  const [timelineDate, timelineData] = item;
  const { content, feedback, huddles } = timelineData;
  const { feedbackId, contentId } = useUrlQuery();

  // eslint-disable-next-line react/prop-types
  const { id, isSkipped } = huddle;

  const { month, date } = useMemo(() => {
    const itemDate = moment(timelineDate);
    return {
      month: itemDate.format('MMM'),
      date: itemDate.format('DD'),
    };
  }, [timelineDate]);

  const doClick = useCallback(() => {
    onClick({ timelineData, huddle });
  }, [onClick, timelineData, huddle]);

  const renderFeedBack = () => {
    if (feedback.length > 0) {
      return (
        <div>
          <FontAwesomeIcon
            icon={faCircle}
            size="xs"
            css={styles.dot(palette.brand.green)}
          />
        </div>
      );
    }
    return (
      <div>
        <FontAwesomeIcon
          icon={faCircle}
          size="xs"
          css={styles.dot(palette.neutrals.gray300)}
        />
      </div>
    );
  };
  const renderHuddles = () => {
    if (huddles.length > 0) {
      return (
        <div>
          <FontAwesomeIcon
            icon={faCircle}
            size="xs"
            css={styles.dot(palette.brand.blue)}
          />
        </div>
      );
    }
    return (
      <div>
        <FontAwesomeIcon
          icon={faCircle}
          size="xs"
          css={styles.dot(palette.neutrals.gray300)}
        />
      </div>
    );
  };
  const renderContent = () => {
    if (content.length > 0) {
      return (
        <div>
          <FontAwesomeIcon
            icon={faCircle}
            size="xs"
            css={styles.dot(palette.brand.red)}
          />
        </div>
      );
    }
    return (
      <div>
        <FontAwesomeIcon
          icon={faCircle}
          size="xs"
          css={styles.dot(palette.neutrals.gray300)}
        />
      </div>
    );
  };

  useEffect(() => {
    if (isSelected) {
      let shouldNavigate = false;

      if (timelineData.feedback.length > 0 && !feedbackId) {
        shouldNavigate = true;
      }

      if (timelineData.content.length > 0 && !contentId) {
        shouldNavigate = true;
      }

      if (shouldNavigate) {
        setTimeout(() => {
          onClick({ timelineDate, timelineData, huddle });
        }, 250);
      }
    }
  }, [id, contentId, feedbackId, timelineData, huddle, timelineDate, isSelected, onClick]);

  const hookProps = {
    month,
    date,
    onClick: doClick,
    renderFeedBack,
    renderContent,
    renderHuddles,
    isSelected,
    ref,
    isSkipped,
  };
  return (
    <View
      {...hookProps}
      {...props}
    />
  );
});

TimelineTile.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  item: PropTypes.shape({ TIMELINE_SHAPE }),
  huddle: PropTypes.shape({ MEETING_DETAILS_SHAPE }),
  id: PropTypes.string,
};

TimelineTile.defaultProps = {
  huddle: {},
  id: '',
  item: [],
};

export { View, tileSharedStyles as TimelineTileSkeletonStyles };
export default TimelineTile;
