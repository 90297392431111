import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import LeadrModal from '~Common/V3/components/LeadrModal';
import { palette } from '~Common/styles/colors';

const styles = {
  modal: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  modalBody: css({
    flex: 1,
  }),
  title: (displayTextInBlue: boolean) => css({
    color: displayTextInBlue ? palette.brand.indigo : palette.brand.red,
  }),
  buttonSpacer: css({
    marginRight: '.5rem',
  }),
};

interface ViewProps {
  show: boolean,
  close: () => void,
  handleConfirmation: () => void,
  title: string,
  bodyText: string,
  buttonText: string,
  displayTextInBlue: boolean,
}

const View = ({
  show,
  close,
  handleConfirmation,
  title,
  bodyText,
  buttonText,
  displayTextInBlue,
}: ViewProps): JSX.Element => (
  <>
    <LeadrModal
      css={styles.modal}
      open={show}
      onClose={close}
    >
      <LeadrModal.Header>
        <LeadrModal.Title css={styles.title(displayTextInBlue)}>
          {title}
        </LeadrModal.Title>
      </LeadrModal.Header>
      <LeadrModal.Body css={styles.modalBody}>
        <p>{bodyText}</p>
      </LeadrModal.Body>
      <LeadrModal.Footer>
        <LeadrButton
          data-test-id="confirmationModalAccept"
          onClick={handleConfirmation}
          size="small"
          color={displayTextInBlue ? 'primary' : 'danger'}
          css={styles.buttonSpacer}
        >
          {buttonText}
        </LeadrButton>
        <LeadrButton
          data-test-id="confirmationModalCancel"
          onClick={close}
          size="small"
          variant="ghost"
          color={displayTextInBlue ? 'primary' : 'danger'}
        >
          Cancel
        </LeadrButton>
      </LeadrModal.Footer>
    </LeadrModal>
  </>
);

interface ConfirmationPlanModalProps {
  handleConfirmation: () => void,
  title: string,
  bodyText: string,
  buttonText: string,
  show: boolean,
  close: () => void,
  displayTextInBlue?: boolean,
}

export const ConfirmationPlanModal = ({
  handleConfirmation,
  title,
  bodyText,
  buttonText,
  show,
  close,
  displayTextInBlue = false,
}: ConfirmationPlanModalProps): JSX.Element => {
  const hookProps = {
    show,
    close,
    handleConfirmation,
    title,
    bodyText,
    buttonText,
    displayTextInBlue,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
