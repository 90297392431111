import PropTypes from 'prop-types';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

// TODO: Delete in and use the HTMLRenderer from packages/leadr-frontend/src/common/V3/components/HTML/HTMLRenderer
/**
 * @deprecated Old Froala UI, use packages/leadr-frontend/src/common/V3/components/Froala.tsx instead
 */
const RichText = ({ className, text, ...props }) => (
  <HTMLRenderer className={`${className} custom-ml`} htmlText={text} {...props} />
);

export default RichText;

RichText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};
