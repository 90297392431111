import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { calendarIntQueryKeys } from '~Meetings/hooks/CalendarIntegration/queryKeys';
import { toast } from '~Common/components/Toasts';
import { useHistory } from 'react-router-dom';

interface SaveAuthParams {
  queryString: string,
}

const saveAuth = async ({ queryString }: SaveAuthParams): Promise<boolean> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/saveOauthInfo`;

  const result = await postApi<boolean>(URL, `${queryString}`);

  return result.response;
};

interface UseNylasAuthenticationReturn {
  authMutation: UseMutateFunction<boolean, unknown, SaveAuthParams, unknown>,
  isLoading: boolean,
}

export const useNylasAuthentication = (): UseNylasAuthenticationReturn => {
  const history = useHistory();
  const mutation = useMutation({
    mutationFn: saveAuth,
    onSuccess: () => {
      // Removes the query string from the URL since that is not needed after this succeeds, this is super important for some loading states, so don't remove this without investigating the whole workflow
      history.replace({ search: '' });
      void queryClient.invalidateQueries({ queryKey: calendarIntQueryKeys.all });
    },
    onError: () => {
      toast.error('No calendar was detected, please reconnect your calendar or contact support.');
    },
  });

  return {
    authMutation: mutation.mutate,
    isLoading: mutation.isPending,
  };
};
