import { css, SerializedStyles } from '@emotion/react';
import { useCallback, useMemo } from 'react';

import Tooltip from '~Common/components/Tooltip';
import { useGetPersonById as usePeoplePickerById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { useCareCard } from '~Meetings/hooks/useCareCard';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { PeoplePickerById, UserStatus } from '~Common/const/interfaces';
import { usePrintView } from '~Common/stores/usePrintView';

const styles = {
  backdropStyles: css({
    backdropFilter: 'none',
    position: 'unset',
    width: 0,
    height: 0,
  }),
  tooltip: css({
    textAlign: 'center',
  }),
};

interface CareCardAvatarViewProps {
  renderAvatar: (imageUrl: string | undefined, fullName: string, isDeactivated: boolean, email?: string) => JSX.Element,
  onAvatarClick: () => void,
  imageUrl?: string,
  fullName: string,
  containerStyle?: SerializedStyles,
  isDeactivated: boolean,
  secondaryTooltip?: string,
  email?: string,
}

const View = ({
  renderAvatar,
  onAvatarClick,
  imageUrl,
  fullName,
  containerStyle,
  isDeactivated,
  secondaryTooltip,
  email,
}: CareCardAvatarViewProps): JSX.Element => (
  <Tooltip
    interactive
    content={(
      <div css={styles.tooltip}>
        <div>
          {isDeactivated ? `${fullName} (Deactivated)` : fullName}
        </div>

        <div>
          {secondaryTooltip}
        </div>
      </div>
    )}
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
    <div
      onClick={onAvatarClick}
      css={containerStyle}
      role="button"
    >
      {renderAvatar(imageUrl, fullName, isDeactivated, email)}
    </div>
  </Tooltip>
);

interface CareCardAvatarProps {
  orgUserId?: string,
  userId?: string,
  id?: string,
  renderAvatar: (imageUrl: string | undefined, fullName: string, isDeactivated: boolean) => JSX.Element,
  containerStyle?: SerializedStyles,
  noBackdrop: boolean,
  renderSkeletonLoader: () => JSX.Element,
  secondaryTooltip?: string,
}

const CardCareAvatar = ({
  orgUserId,
  userId,
  id,
  renderAvatar,
  containerStyle,
  noBackdrop,
  renderSkeletonLoader,
  secondaryTooltip,
}: CareCardAvatarProps): JSX.Element => {
  const { isLoading, ...avatar } = usePeoplePickerById({ id: orgUserId || userId || id, defaultIfNull: true }) as PeoplePickerById;
  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);
  const isPrintView = usePrintView((state) => state.isPrintView);

  const drawerArgs = useMemo(() => ({
    backdropStyles: noBackdrop ? styles.backdropStyles : '',
  }), [noBackdrop]);

  const onClickOnAvatar = useCareCard(drawerArgs);

  const onAvatarClick = useCallback(() => {
    if (isPrintView) {
      return;
    }
    // @ts-expect-error TODO: Remove when typed
    onClickOnAvatar(avatar);
  }, [avatar, isPrintView, onClickOnAvatar]);

  const fullName = useMemo(() => (
    `${avatar.firstName} ${avatar.lastName}`
  ), [avatar.firstName, avatar.lastName]);

  const hookProps = {
    renderAvatar,
    containerStyle,
    fullName,
    imageUrl: avatar.profileImageUrl,
    onAvatarClick,
    isDeactivated: avatar?.administrativeStatus === UserStatus.inactive,
    secondaryTooltip,
    email: avatar.email,
  };

  if (showSkeletonLoaders) {
    return (
      <>
        {renderSkeletonLoader()}
      </>
    );
  }

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default CardCareAvatar;
