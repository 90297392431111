import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { SerializedStyles, css } from '@emotion/react';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import BodyComponent from '~Meetings/components/details/agenda/AgendaTopic/BodyComponent';
import AgendaItem, { AgendaTopicWrapperSkeleton } from '~Meetings/components/details/agenda/AgendaTopic/AgendaTopicWrapper';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import moment from 'moment';
import { noop } from 'lodash';
import { View as HuddleAgendaNote } from '~Meetings/components/details/agenda/HuddleAgendaNote';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { Profile } from '~Common/const/interfaces';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { ReflectionAgendaItemDetail } from '../types';
import MeetingDetailsCard, { MeetingDetailsCardSkeleton } from './MeetingDetailsCard';
import { useGetAgendaTopic } from '../hooks/useGetAgendaTopic';

const styles = {
  agendaItem: css({
    marginTop: '1.125rem',
  }),
  bottomButton: css({
    marginTop: '1.125rem',
  }),
};

export const topicsReflectionTemplate = {
  name: 'TOPICS_REFLECTION_V2',
  type: templateType.PRIMARY,
  width: DRAWER_WIDTHS.PRIMARY,
};

interface ViewProps {
  closeDrawer: () => void,
  renderBottomButton?: (bottomButtonStyles: SerializedStyles) => JSX.Element,
  isReflectionLoading: boolean,
  agendaItemUid: string,
  reflection: ReflectionAgendaItemDetail | undefined,
  creatorUserData: Profile | undefined,
}

const View = ({
  closeDrawer,
  renderBottomButton,
  isReflectionLoading,
  agendaItemUid,
  reflection,
  creatorUserData,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawer}
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            tooltip="Close"
            size="large"
          />
        )}
        title="Agenda Topic"
      />
    )}
    renderBody={(defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {isReflectionLoading && (
          <>
            <MeetingDetailsCardSkeleton />
            <AgendaTopicWrapperSkeleton css={styles.agendaItem} />
          </>
        )}
        {!isReflectionLoading && creatorUserData && (
          <>
            <MeetingDetailsCard
              // TODO: Add the date when the API sends it
              title={reflection?.coaching.meetingTitle ?? ''}
              date={moment(reflection?.createdInMillis).format('MMM Do YYYY')}
            />
            <AgendaItem
              css={styles.agendaItem}
              renderBodyComponent={(bodyStyles) => (
                <BodyComponent
                  css={bodyStyles}
                  // topicCreator={`${creatorUserData?.firstName} ${creatorUserData?.lastName}`} // Ideally this is from the actual topic creator, but we don't have that data yet
                  textHTML={reflection?.text ?? ''}
                  hasBeenEdited={false} // I don't think this will be relevant for this display
                  timeSinceEdit="" // I don't think this will be relevant for this display
                  timeSinceComment="" // We probably want to show this, but we don't have any data from this endpoint, hopefully that is fixed with the new endpoint
                  huddleId="" // I don't think this will be relevant for this display
                  agendaItemId="" // I don't think this will be relevant for this display
                />
              )}
              renderRightSideComponent={(rightSideStyles) => (
                <div css={rightSideStyles}>
                  { /* TODO: Update to NewBaseAvatar */ }
                  <AvatarWithTooltip
                    firstName={creatorUserData?.firstName ?? ''}
                    lastName={creatorUserData?.lastName ?? ''}
                    profileImageUrl={creatorUserData?.profileImageUrl}
                  />
                </div>
              )}
              renderFooterComponent={(footerStyles) => (
                <div css={footerStyles}>
                  {reflection?.notes?.map((note) => (
                    <>
                      <HuddleAgendaNote
                        text={note.text}
                        agendaItemId={agendaItemUid}
                        showAddCommentEditor={false}
                        onCancelEditor={noop}
                        noteId={note.id}
                        // This shouldn't be needed, unless we allow editing and such - We're not currently but keep that in mind
                        huddleId=""
                        // Have to do this because it thinks it's a number but everything I see is a string
                        createdAt={moment(note.createdInMillis).fromNow() as unknown as number}
                        isPrivate={false}
                        // ownerName={`${note.creator.firstName} ${note.creator.lastName}`}
                        creatorId={note.creator.orgUserId}
                        hasBeenEdited={false}
                        timeSinceEdit={moment(note.createdInMillis).fromNow()}
                        scrollRef={{}}
                        isHighlighted={false}
                        setAgendaTopicCommentUpdateEventId={noop}
                        setShowCommentEditor={noop}
                        onSave={noop}
                        draftKey={[]}
                      />
                    </>
                  ))}
                </div>
              )}

            />
            {renderBottomButton?.(styles.bottomButton)}
          </>
        )}
      </div>
    )}
  />
);

interface TopicsDrawerProps extends DrawerProps<never> {
  agendaItemUid: string,
}

const TopicsDrawer = ({
  agendaItemUid,
  popDrawer,
  ...props
}: TopicsDrawerProps): JSX.Element => {
  const { reflection, isLoading } = useGetAgendaTopic({
    agendaTopicId: agendaItemUid,
  });

  const [isReflectionLoading] = useSkeletonLoaders(isLoading);

  const closeDrawer = (): void => {
    popDrawer({
      drawerName: topicsReflectionTemplate.name,
    });
  };

  const creatorId = reflection?.creator.orgUserId;
  const isCreator = creatorId === getOrganizationUserId();
  const { peopleData } = useNewPeople({});
  // @ts-expect-error Can remove this once the useNewPeople is typed
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const creatorUserId = creatorId ? peopleData[creatorId]?.userId as string : '';
  const { user: creatorUserData } = useUserProfile(creatorUserId);

  const hookProps = {
    closeDrawer,
    isReflectionLoading,
    agendaItemUid,
    reflection,
    creatorUserData,
    isCreator,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: topicsReflectionTemplate.name,
  component: TopicsDrawer,
});

export default TopicsDrawer;
