import { getOrganizationUserId } from '~Common/utils/localStorage';
import { ParticipantTypeEnum, ReviewShape } from '~Reviews/V2/Const/types';

interface GetParticipantReviewType {
  participantTypeEnum: ParticipantTypeEnum,
  participantUid: string,
  reviewerUid: string,
  secondaryReviewerUid: string,
  revieweeUid: string,
}

export function getParticipantReviewType(review?: ReviewShape): GetParticipantReviewType {
  if (!review) {
    return {
      participantTypeEnum: ParticipantTypeEnum.Unknown,
      participantUid: '',
      reviewerUid: '',
      secondaryReviewerUid: '',
      revieweeUid: '',
    };
  }

  const { participants } = review;
  const orgUserId = getOrganizationUserId();

  const findCurrentParticipant = participants.find((participant) => (participant.orgUserId === orgUserId));
  const findReviewer = participants.find((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.Reviewer));
  const findReviewee = participants.find((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.Reviewee));
  const findsecondaryReviewer = participants.find((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer));

  return {
    participantTypeEnum: findCurrentParticipant?.participantTypeEnum ?? ParticipantTypeEnum.Unknown,
    participantUid: findCurrentParticipant?.uid ?? '',
    revieweeUid: findReviewee?.uid ?? '',
    reviewerUid: findReviewer?.uid ?? '',
    secondaryReviewerUid: findsecondaryReviewer?.uid ?? '',
  };
}

enum nextStepStatusConversion {
  Reviewee = 'revieweeStatus',
  Reviewer = 'reviewerStatus',
  SecondaryReviewer = 'secondaryReviewerStatus',
  NA = 'N/A'
}

export const getNextStepStatus = (isManager: boolean, isSecondaryManager: boolean, isReviewee: boolean): nextStepStatusConversion => {
  let participantionType = nextStepStatusConversion.NA;
  if (isSecondaryManager) {
    participantionType = nextStepStatusConversion.SecondaryReviewer;
  }
  if (isManager) {
    participantionType = nextStepStatusConversion.Reviewer;
  }
  if (isReviewee) {
    participantionType = nextStepStatusConversion.Reviewee;
  }

  return participantionType;
};

export const setNextStepStatusText = (fullReview: ReviewShape | undefined, participantionType: nextStepStatusConversion): string => {
  let participantionTypeText = nextStepStatusConversion.NA as string;
  if (participantionType === nextStepStatusConversion.Reviewer) {
    participantionTypeText = fullReview?.[participantionType] ?? '';
  } else if (participantionType === nextStepStatusConversion.SecondaryReviewer) {
    participantionTypeText = fullReview?.[participantionType] ?? '';
  } else if (participantionType === nextStepStatusConversion.Reviewee) {
    participantionTypeText = fullReview?.[participantionType] ?? '';
  }

  return participantionTypeText;
};

interface returnReviewParticipantTypeReturnProps {
  participantReviewTypeEnum: ParticipantTypeEnum,
  participantUid: string,
  reviewerUid: string,
  revieweeUid: string,
  secondaryReviewerUid: string,
  isManager: boolean,
  isSecondaryManager: boolean,
  isReviewee: boolean,
  nextStep: string,
}

export const returnReviewParticipantType = (fullReview: ReviewShape | undefined): returnReviewParticipantTypeReturnProps => {
  const participantReviewType = getParticipantReviewType(fullReview);

  const {
    participantTypeEnum, participantUid, reviewerUid, secondaryReviewerUid, revieweeUid,
  } = participantReviewType;

  const isSecondaryManager = participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer;
  const isReviewee = participantTypeEnum === ParticipantTypeEnum.Reviewee;
  const isManager = participantTypeEnum === ParticipantTypeEnum.Reviewer;
  const nextStepStatus = getNextStepStatus(isManager, isSecondaryManager, isReviewee);
  const nextStep = setNextStepStatusText(fullReview, nextStepStatus);

  return {
    participantReviewTypeEnum: participantTypeEnum,
    participantUid,
    reviewerUid,
    revieweeUid,
    secondaryReviewerUid,
    isManager,
    isSecondaryManager,
    isReviewee,
    nextStep,
  };
};
