import { Person } from '~Common/const/interfaces';
import { HTMLString } from '~Common/types';

export interface RecognitionUserShape {
  profileImageUrl: string,
  firstName: string,
  lastName: string,
  orgUserId: string,
  email?: string,
}

export interface RecognitionShape {
  recognitionId: number,
  content: HTMLString,
  createdOn: string,
  createdBy: RecognitionUserShape,
  recipients: RecognitionUserShape[],
  canEdit: boolean,
  canDelete: boolean,
  modifiedOn: string | null,
}

export interface CreateRecognitionShape {
  content: string,
  recipients: string[],
  canDelete?: boolean,
  canEdit?: boolean,
}

export const FILTER_SEPARATOR = '^';
export enum FilterType {
  Department = 'department',
  User_Group = 'userGroup',
  Clear = 'clear',
}

export enum ActionMenuActions {
  Delete = 'delete',
  Edit = 'edit',
}

export interface InfiniteQueryParamsProps {
  department: string,
  search: string,
  userGroup: string,
  take?: number,
  startDate: string,
  endDate: string,
  [key: string]: string | number | undefined,
}

export interface EditPostShape {
  editPost: boolean,
  postId: number,
}

export interface ErrorShape {
  status: {
    messages: {
      message: string,
    }[],
    success: boolean,
  },
}

export interface PeopleDataReturn extends Partial<Person> {
  userid?: string,
  orgUserId: string,
  firstName: string,
  lastName: string,
  administrativeStatus: 'ACTIVE' | 'CREATED' | 'INVITED' | 'INACTIVE',
  managername: string,
}
