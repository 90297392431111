import { LearningTemplate } from '~Learning/const/interfaces';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useGetLearningTemplateList } from '../templates/singleContent/useGetLearningTemplateList';
import { useGetLearningTableGroupTemplateList } from '../templates/curatedByTheTableGroup/useGetLearningTableGroupTemplateList';
import { useGetLearningCuratedTemplateList } from '../templates/curatedByLeadr/useGetLearningCuratedTemplateList';

interface CombinedLearningTemplateSearchQueryParameters {
  page: number,
  count: number,
  categoryId: string,
  isCuratedByLeadr: boolean,
  isCuratedByTableGroup: boolean,
}

interface CombinedLearningTemplateSearchHookReturn {
  learningTemplates: LearningTemplate[],
  isLoading: boolean,
  total: number,
}

export const useGetCombinedLearningTemplateList = ({
  page,
  count,
  categoryId,
  isCuratedByLeadr,
  isCuratedByTableGroup,
}: CombinedLearningTemplateSearchQueryParameters): CombinedLearningTemplateSearchHookReturn => {
  const useDynamicCuratedByLeadrLibrary = useFeatureFlag('learningCuratedByLeadrInApp') && isCuratedByLeadr;

  const {
    learningTemplates: orgLearningTemplates,
    isLoading: areOrgLearningTemplatesLoading,
    learningTemplatesTotalCount: orgLearningTemplatesTotalCount,
  } = useGetLearningTemplateList({
    page,
    count,
    curated: isCuratedByLeadr && !useDynamicCuratedByLeadrLibrary,
    categoryId,
    enabled: !isCuratedByTableGroup,
  });

  const {
    learningTemplates: curatedByLeadrLearningTemplates,
    isLoading: areCuratedByLeadrLearningTemplatesLoading,
    learningTemplatesTotalCount: curatedByLeadrLearningTemplatesTotalCount,
  } = useGetLearningCuratedTemplateList({
    page,
    count,
    categoryId,
    enabled: useDynamicCuratedByLeadrLibrary && !isCuratedByTableGroup,
  });

  const {
    learningTemplates: tableGroupLearningTemplates,
    isLoading: areTableGroupLearningTemplatesLoading,
    learningTemplatesTotalCount: tableGroupLearningTemplatesTotalCount,
  } = useGetLearningTableGroupTemplateList({
    page,
    count,
    categoryId,
    enabled: isCuratedByTableGroup,
  });

  const leadrLearningTemplates = useDynamicCuratedByLeadrLibrary
    ? curatedByLeadrLearningTemplates
    : orgLearningTemplates;
  const areLeadrLearningTemplatesLoading = useDynamicCuratedByLeadrLibrary
    ? areCuratedByLeadrLearningTemplatesLoading
    : areOrgLearningTemplatesLoading;
  const leadrLearningTemplatesTotalCount = useDynamicCuratedByLeadrLibrary
    ? curatedByLeadrLearningTemplatesTotalCount
    : orgLearningTemplatesTotalCount;

  const learningTemplates = isCuratedByTableGroup ? tableGroupLearningTemplates : leadrLearningTemplates;
  const isLoading = isCuratedByTableGroup ? areTableGroupLearningTemplatesLoading : areLeadrLearningTemplatesLoading;
  const total = isCuratedByTableGroup ? tableGroupLearningTemplatesTotalCount : leadrLearningTemplatesTotalCount;

  return {
    learningTemplates,
    isLoading,
    total,
  };
};
