import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import ErrorHandler from '../../../components/ErrorHandler';

const styles = {
  container: css`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
  `,
};

const NewGetContent = (props) => {
  const {
    ChildComponent, TopComponent, wrapperClassName,
  } = props;
  return (
    <div className={`coaching-content-wrapper ${wrapperClassName || ''}`}>
      {TopComponent && (
        <div css={[styles.container, styles.topBar]} className="page-top-bar">
          {typeof (TopComponent) === 'function' ? <TopComponent /> : TopComponent}
        </div>
      )}
      <div className="coaching-details-wrapper page-wrapper">
        <div css={styles.container}>
          {typeof (ChildComponent) === 'function' ? <ChildComponent /> : ChildComponent}
        </div>
        <ErrorHandler />
      </div>
    </div>
  );
};

NewGetContent.propTypes = {
  wrapperClassName: PropTypes.string,
  /* eslint-disable */
  ChildComponent: PropTypes.any,
  TopComponent: PropTypes.any,
  /* eslint-enable */
};

NewGetContent.defaultProps = {
  wrapperClassName: '',
};

const NewGetContentSkeleton = (props) => {
  const {
    ChildComponentSkeleton, TopComponentSkeleton, wrapperClassName,
  } = props;
  return (
    <div className={`coaching-content-wrapper ${wrapperClassName || ''}`}>
      { TopComponentSkeleton && (
        <div css={[styles.container, styles.topBar]} className="page-top-bar">
          <TopComponentSkeleton />
        </div>
      ) }
      <div className="coaching-details-wrapper page-wrapper">
        <div css={styles.container}>
          <ChildComponentSkeleton />
        </div>
      </div>
    </div>
  );
};

NewGetContentSkeleton.propTypes = {
  wrapperClassName: PropTypes.string,
  /* eslint-disable */
  TopComponentSkeleton: PropTypes.any,
  ChildComponentSkeleton: PropTypes.any,
  /* eslint-enable */
};

NewGetContentSkeleton.defaultProps = {
  wrapperClassName: '',
};

export default NewGetContent;

export { NewGetContentSkeleton };
