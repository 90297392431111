import { css } from '@emotion/react';
import { ReactNode } from 'react';

const styles = {
  modalFooter: css({
    position: 'sticky',
    marginTop: '1.125rem',
  }),
};

interface ModalFooterProps {
  children: ReactNode,
}

const ModalFooter = ({
  children,
  ...props
}: ModalFooterProps): JSX.Element => (
  <section
    css={styles.modalFooter}
    {...props}
  >
    {children}
  </section>
);

export default ModalFooter;
