import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import BaseToast from '~Common/components/Toasts/BaseToast';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLightbulbOn } from '@fortawesome/pro-light-svg-icons';

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    marginLeft: '0.5rem',
  }),
  iconSize: css({
    height: '1.6rem !important',
    width: '2rem !important',
  }),
  textContainer: css({
    flex: 1,
  }),
  refreshButton: css({
    color: palette.neutrals.white,
    border: 'none',
    padding: 0,
    textDecoration: 'underline !important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  customClose: css({
    border: 'none',
    padding: 0,
    paddingRight: '0.25rem',
    color: palette.neutrals.white,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
};

interface AppUpdateToastProps {
  onButtonClick: () => void;
}
const AppUpdateToast = ({ onButtonClick }: AppUpdateToastProps): JSX.Element => (
  <BaseToast
    customIconStyle={styles.iconSize}
    icon={faLightbulbOn}
  >
    <div css={styles.container}>
      <div css={styles.textContainer}>
        A new version of Leadr is available!&nbsp;
        <LeadrButton
          onClick={onButtonClick}
          data-test-id="refresh-button"
          css={styles.refreshButton}
          variant="ghost"
          type="button"
        >
          Refresh to view.
        </LeadrButton>
      </div>
      <div>
        <LeadrButton
          onClick={() => toast.dismiss()}
          css={styles.customClose}
          variant="ghost"
          type="button"
          data-test-id="toast-close-button"
        >
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
          />
        </LeadrButton>
      </div>
    </div>
  </BaseToast>
);

export default AppUpdateToast;
