import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { SURVEY_SHAPE } from '~Common/const/proptypes';
import { ViewCardSkeleton } from '~Common/components/Cards/ViewCard';
import SurveyDetailsCard from '../../Cards/SurveyDetailsCard';

const styles = {
  container: css``,
  dueDateBody: css`
    display: flex;
    justify-content: space-between;
  `,
  dueDateCard: css`
    margin-top: 24px;
  `,
};

const View = ({
  survey,
  isLoading,
  ...props
}) => (
  <div
    css={styles.container}
    {...props}
  >
    {isLoading && (
      <ViewCardSkeleton height={140} />
    )}
    {!isLoading && (
      <SurveyDetailsCard survey={survey} />
    )}
  </div>
);

View.propTypes = {
  survey: SURVEY_SHAPE,
  isLoading: PropTypes.bool.isRequired,
};

View.defaultProps = {
  survey: {},
};

const Details = ({ survey, ...props }) => {
  const hookProps = {
    survey,
    ...props,
  };
  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

Details.propTypes = {
  survey: SURVEY_SHAPE.isRequired,
};

Details.defaultProps = {};

export { View };
export default Details;
