import {
  faBullseyeArrow, faBriefcase,
} from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

import { ActionItemContextType } from '~ActionItems/const/interfaces';

export interface AssociationData {
  icon: IconDefinition,
  text: string,
}

export const actionItemAssociationData: Record<ActionItemContextType, AssociationData> = {
  [ActionItemContextType.Goal]: {
    icon: faBullseyeArrow,
    text: 'Goals',
  },
  [ActionItemContextType.Meeting]: {
    icon: faBriefcase,
    text: 'Meetings',
  },
};

export enum RecurrenceDropdownOptions {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

export const recurrenceOptionsTranslation = {
  [RecurrenceDropdownOptions.Weekly]: 'Weekly',
  [RecurrenceDropdownOptions.Monthly]: 'Monthly',
};

export enum MonthlyRecurrenceDropdownOptions {
  DayOfMonth = 'DAY_OF_MONTH',
  WeekOfMonth = 'WEEK_OF_MONTH',
}

export const monthlyRecurrenceOptionsTranslation = {
  [MonthlyRecurrenceDropdownOptions.DayOfMonth]: 'Day of Month',
  [MonthlyRecurrenceDropdownOptions.WeekOfMonth]: 'Day of Week',
};
