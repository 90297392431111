import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { produce } from 'immer';

import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { getHost } from '~Deprecated/services/config';
import { AgendaTopic, GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { useAgendaPermissionsStore } from '../utils/useAgendaPermissions';

export interface DeleteAgendaItemProps {
  huddleId: string,
  agendaItemId: string,
  eventId?: string,
}

const deleteAgendaItem = async ({
  huddleId,
  agendaItemId,
}: DeleteAgendaItemProps): Promise<HttpCallReturn<void>> => {
  const url = {
    host: getHost('', '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/huddles/${huddleId}/agendas/${agendaItemId}`,
  };

  return deleteApi(url);
};

interface UseDeleteAgendaItemParams {
  sectionId?: string,
}

export const useDeleteAgendaItem = ({
  sectionId,
}: UseDeleteAgendaItemParams = {}): UseMutationResult<HttpCallReturn<void>, unknown, DeleteAgendaItemProps> => {
  const orgId = getOrganizationId();

  const {
    disableWorkflows,
    enableWorkflows,
  } = useAgendaPermissionsStore((state) => ({
    disableWorkflows: state.disableWorkflows,
    enableWorkflows: state.enableWorkflows,
  }));

  return useMutation({
    mutationFn: deleteAgendaItem,
    // optimistically push the agenda item into the list so the ui feels snappy
    onMutate: async (variables) => {
      const { huddleId, agendaItemId } = variables;
      const previousAgendas = queryClient.getQueryData(meetingKeys.agendaList(huddleId));

      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      disableWorkflows(agendaItemId, sectionId);

      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId), (originalData) => {
        if (originalData) {
          if (sectionId) {
            // The agenda item is being delete from a section
            // Find the section and remove it there
            const sectionIndex = originalData.response.agendas.findIndex((agenda) => agenda.id === sectionId);
            if (sectionIndex !== -1) {
              return produce(originalData, (draft) => {
                const children = draft.response.agendas[sectionIndex].children as AgendaTopic[];
                const filteredChildren = children.filter((agenda) => agenda.id !== agendaItemId);
                draft.response.agendas[sectionIndex].children = filteredChildren;
              });
            }
          } else {
            return produce(originalData, (draft) => {
              draft.response.agendas = draft.response.agendas.filter((agenda) => agenda.id !== agendaItemId);
            });
          }
        }

        return originalData;
      });

      return { previousAgendas };
    },
    onError: (_, variables, snapshot) => {
      const { huddleId: id } = variables;
      toast.error(<MeetingsErrorToast />, {
        autoClose: 1500,
      });

      queryClient.setQueryData(meetingKeys.agendaList(id), snapshot?.previousAgendas);
    },
    onSettled: async (_, __, variables) => {
      const { huddleId, agendaItemId } = variables;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(orgId!) });
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      enableWorkflows(agendaItemId, sectionId);
    },
  });
};
