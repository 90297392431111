import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { TopicShape } from '../Const/types';
import { ReviewTemplateForCycle } from '../templates/useReviewTemplates';

interface createTopicForTemplateCycleProps {
  template: ReviewTemplateForCycle;
}

const createTopicForTemplateCycle = ({ template }: createTopicForTemplateCycleProps): Promise<HttpCallReturn<TopicShape>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/createFromTemplate`,
  };

  return postApi<TopicShape>(serverUrl, template, {});
};

export const useCreateTopicForTemplateCycle = ():
  UseMutationResult<HttpCallReturn<TopicShape>, unknown, createTopicForTemplateCycleProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: createTopicForTemplateCycle,

    onMutate: () => {
      toastId.current = toast.info('Creating a review based on a pre-existing template...', { autoClose: false });
    },

    onError: (error: string) => {
      toast.update(toastId.current, {
        render: error.toString() ?? 'There was an error adding the template to the review cycle. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },

    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully created the review cycle.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
    },
  });

  return mutation;
};
