import { createContext } from 'react';
import { DisableMoveDownParams, DisableMoveUpParams, MoveTemplateAgendaItemOnePositionParams } from '~Meetings/hooks/utils/useMoveTemplateAgendaItem';

interface MoveTemplateAgendaItemContext {
  disableMoveUp: (params: DisableMoveUpParams) => boolean,
  disableMoveDown: (params: DisableMoveDownParams) => boolean,
  moveTemplateAgendaItemOnePosition: (params: MoveTemplateAgendaItemOnePositionParams) => void,
}

export const MoveTemplateAgendaItemContext = createContext<MoveTemplateAgendaItemContext>({
  disableMoveUp: () => false,
  disableMoveDown: () => false,
  moveTemplateAgendaItemOnePosition: () => [],
});
