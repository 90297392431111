import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import CreateEditTeamModal, { EditTeamInitialData } from '~People/components/Teams/components/CreateEditTeamModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import { useShowCreateEditTeamModal } from '~People/components/Teams/stores/useShowCreateEditTeamModal';
import { TeamClarityQuestion } from '~People/components/Teams/const/types';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { useGetTeamClarityQuestions } from '~People/components/Teams/hooks/utils/useGetTeamClarityQuestions';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import HomeTeamClarityQuestionDisplay from './HomeTeamClarityQuestionDisplay';

const styles = {
  header: css({
    marginBottom: '0.5rem',
    whiteSpace: 'nowrap',
  }),
  emptyText: css({
    color: palette.neutrals.gray700,
  }),
  orgQuestionDisplay: css({
    '&:not(:last-of-type)': {
      marginBottom: '0.75rem',
    },
  }),
  cardBody: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.75rem',
  }),
};

interface ViewProps {
  selectedTeamId: string,
  teamClarityQuestionList: TeamClarityQuestion[],
  canEdit: boolean,
  showModal: boolean,
  initialData?: EditTeamInitialData,
  handleEditTeamClarityQuestions: () => void,
  isLoading: boolean,
}

const View = ({
  teamClarityQuestionList,
  canEdit,
  showModal,
  initialData,
  handleEditTeamClarityQuestions,
  isLoading,
}: ViewProps): JSX.Element => (
  <>
    {isLoading && (
      <HomeSkeleton
        numberOfSkeletons={2}
        height={2}
      />
    )}
    {!isLoading && teamClarityQuestionList && (
      <div css={styles.cardBody}>
        {teamClarityQuestionList.map((teamClarityQuestion) => (
          <HomeTeamClarityQuestionDisplay
            key={teamClarityQuestion.rank}
            css={styles.orgQuestionDisplay}
            question={teamClarityQuestion.question}
            answerHTML={teamClarityQuestion.answer}
          />
        ))}
      </div>
    )}
    {canEdit && (
      <>
        <ModuleFooter
          renderFooterButton={(params) => (
            <LeadrButton
              data-test-id="homeEditTeamClarityQuestions"
              onClick={handleEditTeamClarityQuestions}
              variant="text"
              size="small"
              {...params}
            >
              Edit
            </LeadrButton>
          )}
        />
        { /* Need this condition here to reset the internal state when the modal is closed */ }
        {showModal && (
          <CreateEditTeamModal
            initialData={initialData}
            isEditMode
          />
        )}
      </>
    )}
  </>
);

interface TeamClarityCardBodyProps {
  selectedTeamId: string,
}

const TeamClarityCardBody = ({
  selectedTeamId,
  ...props
}: TeamClarityCardBodyProps): JSX.Element => {
  const {
    data: teamDetails,
    isLoading: areTeamDetailsLoading,
  } = useGetTeamDetails({ teamId: selectedTeamId });

  const {
    teamClarityQuestionList,
    teamClarityQuestionListEdit,
    isLoading: areTeamClarityQuestionsLoading,
  } = useGetTeamClarityQuestions({ teamId: selectedTeamId });

  const [isLoading] = useSkeletonLoaders(areTeamDetailsLoading || areTeamClarityQuestionsLoading);

  const {
    showModal,
    setShowModal,
  } = useShowCreateEditTeamModal((state) => ({
    showModal: state.showModal,
    setShowModal: state.setShowModal,
  }));

  const handleEditTeamClarityQuestions = (): void => {
    setShowModal(true);
  };

  let initialData;

  if (teamDetails?.response) {
    initialData = {
      ...teamDetails.response,
      teamId: selectedTeamId,
      teamClarityQuestionList: teamClarityQuestionListEdit,
    };
  }

  const hookProps = {
    teamClarityQuestionList,
    canEdit: teamDetails?.response?.canEdit || false,
    isLoading,
    showModal,
    initialData,
    handleEditTeamClarityQuestions,
    selectedTeamId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TeamClarityCardBody;
