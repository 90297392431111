import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  BASE_ROUTE,
  IMPERSONATE_LANDING_PAGE,
  MOBILE_APP_LANDING_PAGE,
  POST_REGISTRATION_LANDING,
} from '~Common/const/routes';

import { getUserId } from '~Common/utils/localStorage';
import ImpersonateLandingPage from '~Deprecated/ui/containers/Nexus/Impersonate/ImpersonateLandingPage';

import { getRefreshToken } from '~Deprecated/utils/cookies';
import { useEffect } from 'react';
import Public from './Public';
import SmartRedirect from '../components/SmartRedirect';
import Private from './Private';

const View = ({
  isAuthenticated,
}) => (
  <Switch>
    <Route
      exact
      path={[
        IMPERSONATE_LANDING_PAGE,
      ]}
      component={ImpersonateLandingPage}
    />
    {!isAuthenticated && (
      <Route
        exact
        path={[POST_REGISTRATION_LANDING, MOBILE_APP_LANDING_PAGE, BASE_ROUTE]}
        component={Public}
      />
    )}
    {isAuthenticated && (
      <Route
        path="*"
        component={Private}
      />
    )}
    <SmartRedirect exact from="*" to={BASE_ROUTE} />
  </Switch>
);

View.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const LeadrRouter = () => {
  const refreshToken = getRefreshToken();
  const userId = getUserId();

  const isAuthenticated = !!refreshToken && !!userId;

  useEffect(() => {
    const pollAuthenticationData = () => {
      if (userId !== getUserId() || refreshToken !== getRefreshToken()) {
        window.location.reload();
      }
    };

    const pollingInterval = setInterval(pollAuthenticationData, 500);
    return () => {
      clearInterval(pollingInterval);
    };
  }, [refreshToken, userId]);

  const hookProps = {
    isAuthenticated,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default LeadrRouter;
