/* eslint-disable max-len */
import { css } from '@emotion/react';
import {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@mui/material';
import {
  ViewPerspective, CycleType, ReviewCycleDetail, ReviewType, ReviewCycleWithParticipants,
} from '~Reviews/V2/Const/types';
import ListSection from '~Common/V3/components/ListSection';
import { palette } from '~Common/styles/colors';

import ReviewCards from '~Reviews/V2/Cards/Cards';
import EmptyReviews from '~Reviews/assets/images/emptyReviews.svg';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  sectionHeader: css({
    color: palette.neutrals.gray700,
    fontWeight: 500,
    fontSize: '1rem',

  }),
  sectionHeaderToggle: css({
    width: '100%',
    textAlign: 'left',
    padding: 0,
    marginBottom: '2rem',
    '&:not(:first-of-type)': {
      marginTop: '2rem',
    },
  }),
  caretToggleicon: (isActive: boolean) => css({
    transition: 'transform 300ms ease-in-out',
    transform: 'rotate(180deg)',
  }, isActive && {
    transform: 'rotate(0deg)',
  }),
  emptyStateImageWrapper: css({
    textAlign: 'center',
  }),
  emptyStateImage: css({
    width: '12.5rem',
  }),
};
interface ViewProps {
  expandedCycleTypes: CycleType[],
  expandedReviewTypes: ReviewType[],
  handleClickHeading: (clickedReviewType: keyof typeof ReviewType | keyof typeof CycleType) => void,
  reviews: Record<keyof typeof CycleType, ReviewCycleWithParticipants[]> | Record<keyof typeof ReviewType, ReviewCycleDetail[]>,
  viewPerspective: ViewPerspective,
  emptyState: boolean,
}

const View = ({
  expandedCycleTypes,
  expandedReviewTypes,
  handleClickHeading,
  reviews,
  viewPerspective,
  emptyState,
}: ViewProps): JSX.Element => (
  <>
    {Object.entries<unknown[]>(reviews).map(([key, value]) => (
      <Fragment key={key}>
        <LeadrButton
          css={styles.sectionHeaderToggle}
          variant="text"
          onClick={() => handleClickHeading(key as keyof typeof ReviewType | keyof typeof CycleType)}
          data-test-id="reviewsDashboardSectionHeader"
        >
          {viewPerspective === ViewPerspective.Admin && (
            <>
              {value.length >= 1 && (
                <ListSection
                  css={styles.sectionHeader}
                  renderContents={() => (
                    <div>
                      {key}
                      {' '}
                      <FontAwesomeIcon css={styles.caretToggleicon(expandedCycleTypes.includes(CycleType[key as keyof typeof CycleType]))} icon={faCaretDown} size="lg" />
                    </div>
                  )}
                />
              )}
            </>
          )}
          {viewPerspective === ViewPerspective.MyReviews && (
            <>
              {value.length >= 1 && (
                <ListSection
                  css={styles.sectionHeader}
                  renderContents={() => (
                    <div>
                      {key}
                      {' '}
                      <FontAwesomeIcon css={styles.caretToggleicon(expandedReviewTypes.includes(ReviewType[key as keyof typeof ReviewType]))} icon={faCaretDown} size="lg" />
                    </div>
                  )}
                />
              )}
            </>
          )}
        </LeadrButton>
        {viewPerspective === ViewPerspective.Admin && (
          <>
            {value.length >= 1 && (
              <Collapse
                in={expandedCycleTypes.includes(CycleType[key as keyof typeof CycleType])}
              >
                <ReviewCards
                  reviewCycles={value as ReviewCycleWithParticipants[]}
                  viewPerspective={viewPerspective}
                />
              </Collapse>
            )}
          </>
        )}
        {viewPerspective === ViewPerspective.MyReviews && (
          <>
            {value.length >= 1 && (
              <Collapse
                in={expandedReviewTypes.includes(ReviewType[key as keyof typeof ReviewType])}
              >

                <ReviewCards
                  reviewCycles={value as ReviewCycleDetail[]}
                  viewPerspective={viewPerspective}
                />
              </Collapse>
            )}
          </>
        )}
      </Fragment>
    ))}
    {emptyState && (
      <>
        <div
          css={styles.emptyStateImageWrapper}
        >
          <EmptyReviews css={styles.emptyStateImage} title="Empty Reviews" data-test-id="emptyReviews" />
          <div>
            There are no reviews to respond to at this time.
          </div>
        </div>
      </>
    )}
  </>
);

interface ReviewDashboardProps{
  reviews: Record<keyof typeof CycleType, ReviewCycleWithParticipants[]> | Record<keyof typeof ReviewType, ReviewCycleDetail[]>,
  viewPerspective: ViewPerspective,
  searchText: string,
}

const ReviewDashboard = ({
  reviews,
  viewPerspective,
  searchText,
}: ReviewDashboardProps): JSX.Element => {
  const [expandedReviewTypes, setExpandedReviewTypes] = useState<ReviewType[]>(Object.keys(ReviewType) as ReviewType[]);
  const [expandedCycleTypes, setExpandedCycleTypes] = useState<CycleType[]>(Object.keys(CycleType) as CycleType[]);
  const [emptyState, setEmptyState] = useState(true);

  useEffect(() => {
    setEmptyState(true);
    if (searchText !== '') {
      let result: Array<keyof typeof CycleType | keyof typeof ReviewType> = [];
      // @ts-expect-error honestly not sure why this is erroring out
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const keys = Object.keys(reviews).filter((key) => reviews[key as keyof CycleType | keyof ReviewType].length);
      if (viewPerspective === ViewPerspective.Admin) {
        result = Array.from(expandedCycleTypes);
      } else {
        result = Array.from(expandedReviewTypes);
      }

      keys.forEach((key) => {
        const index = result.indexOf(key as keyof typeof CycleType | keyof typeof ReviewType);
        if (index === -1) {
          result.push(key as keyof typeof CycleType | keyof typeof ReviewType);
        }
      });
      if (viewPerspective === ViewPerspective.Admin) {
        setExpandedCycleTypes([...result as CycleType[]]);
      } else {
        setExpandedReviewTypes([...result as ReviewType[]]);
      }
    }
    Object.entries<unknown[]>(reviews).forEach(([, value]): void => {
      if (value.length >= 1) {
        setEmptyState(false);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, reviews, viewPerspective]);

  const handleClickHeading = useCallback((clickedReviewType: keyof typeof CycleType | keyof typeof ReviewType) => {
    let value: keyof typeof CycleType | keyof typeof ReviewType;
    let result: Array<keyof typeof CycleType | keyof typeof ReviewType> = [];

    if (viewPerspective === ViewPerspective.Admin) {
      value = CycleType[clickedReviewType];
      result = Array.from(expandedCycleTypes);
    } else {
      value = ReviewType[clickedReviewType as keyof typeof ReviewType];
      result = Array.from(expandedReviewTypes);
    }
    const index = result.indexOf(value);

    if (index === -1) {
      result.push(value);
    } else {
      result.splice(index, 1);
    }

    if (viewPerspective === ViewPerspective.Admin) {
      setExpandedCycleTypes([...result as CycleType[]]);
    } else {
      setExpandedReviewTypes([...result as ReviewType[]]);
    }
  }, [viewPerspective, expandedCycleTypes, expandedReviewTypes]);

  const hookProps = {
    reviews,
    viewPerspective,
    handleClickHeading,
    expandedReviewTypes,
    expandedCycleTypes,
    emptyState,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ReviewDashboard };
export default ReviewDashboard;
