import { useGetReviews } from '~Reviews/V2/Hooks/useGetReviews';
import { useFilterReviews } from '~Reviews/V2/Hooks/useFilterReviews';
import { useDebouncedSearch } from '~Reviews/V2/Hooks/useDebouncedSearch';
import { UseTransformItemsReturn, WhatsDueItem } from '~Home/const/interfaces';
import { WhatsDueFilterOptions } from '~Home/const';
import { faClipboardUser } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment-timezone';
import { ReviewCycleDetail } from '~Reviews/V2/Const/types';
import { getParticipantReviewType } from '~Reviews/V2/Hooks/useReturnRevieweeReviewId';
import { BASE_ROUTE } from '~Common/const/routes';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useReturnUnfinishedReviews } from './useReturnUnfinishedReviews';

const buildReviewHref = (reviewCycle: ReviewCycleDetail, isAdmin:boolean):string => {
  const { revieweeReviewId, isManager } = getParticipantReviewType(reviewCycle);
  const myReviewRoute = `${BASE_ROUTE}reviews/${revieweeReviewId ?? ''}`;
  const myReviewManagerRoute = `${BASE_ROUTE}reviews/cycle/${reviewCycle.uid ?? ''}`;
  const adminRoute = `${BASE_ROUTE}reviews/cycle/${reviewCycle.uid}`;
  const managerOnly = isManager && !isAdmin;
  // eslint-disable-next-line no-nested-ternary
  const route = managerOnly ? myReviewManagerRoute : isAdmin ? adminRoute : myReviewRoute;
  return route;
};

export const useTransformReviews = ():UseTransformItemsReturn => {
  const { isAdmin } = useUserPermissions();
  const { isLoading, statusSortedReviews: myCycles } = useGetReviews({});
  const { searchText } = useDebouncedSearch();
  const filteredReviews = useFilterReviews(myCycles, searchText);
  const { Active } = filteredReviews;
  const unfinishedReviews = useReturnUnfinishedReviews(Active);
  const transformedReviews = unfinishedReviews?.map<WhatsDueItem>((review) => ({
    type: WhatsDueFilterOptions.Reviews,
    text: review.name,
    iconToRender: faClipboardUser,
    dueDate: review.signOffDueDate ? moment(review.signOffDueDate) : undefined,
    href: buildReviewHref(review, isAdmin),
    id: review.uid,
  }));

  return {
    isLoading,
    data: transformedReviews ?? [],
  };
};
