import { connect } from 'react-redux';

import CardContentRow from '~Deprecated/ui/components/Card/CardContentRow';
import { makeGetOrganizationContent } from '~Deprecated/selectors/organizationInsights/makeGetOrganizationContent';

const makeMapStateToProps = () => {
  const getOrganizationContent = makeGetOrganizationContent();
  const mapStateToProps = (state, ownProps) => ({
    content: getOrganizationContent(state, { id: ownProps.id }),
  });

  return mapStateToProps;
};

const mapDispatchToProps = {

};

export default connect(makeMapStateToProps, mapDispatchToProps)(CardContentRow);
