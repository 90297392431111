import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { drawerSectionBorderColor } from '~Deprecated/ui/styles/colors';
import { withPrintStyles } from '~Deprecated/ui/styles/print';
import { palette } from '~Common/styles/colors';

const style = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${drawerSectionBorderColor};
  position: sticky;

  ${withPrintStyles(css`
    display: none;
  `)}

  h6 {
    text-transform: unset;
    font-weight: 600;
    font-size: 1rem;
    color: ${palette.neutrals.gray800};
  }
`;

const emptyDiv = css`
  width: 116px;
`;

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
const DrawerHeader = ({ title, renderLeftButton, renderRightButton }) => (
  <div css={style}>
    <h6>{ title }</h6>
    { renderLeftButton() || <div css={emptyDiv} /> }

    { renderRightButton() || <div css={emptyDiv} /> }
  </div>
);

DrawerHeader.propTypes = {
  title: PropTypes.string,
  renderLeftButton: PropTypes.func,
  renderRightButton: PropTypes.func,
};

DrawerHeader.defaultProps = {
  title: '',
  renderLeftButton: () => {
  },
  renderRightButton: () => {
  },
};

export default DrawerHeader;
