import { useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { learningCuratedTemplatesQueryKeys } from '../queryKeys';
import { LearningTemplateDetail } from '../singleContent/useGetLearningTemplate';

interface GetLearningCuratedTemplateDetailParams {
  templateId: string,
}

const getLearningCuratedTemplateDetail = async ({
  templateId,
}: GetLearningCuratedTemplateDetailParams): Promise<HttpCallReturn<LearningTemplateDetail>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/curatedByLeadr/${templateId}`;

  return getApi<LearningTemplateDetail>(serverUrl);
};

interface UseGetLearningCuratedTemplateDetailParams {
  templateId: string,
  enabled?: boolean,
}

interface UseGetLearningCuratedTemplateDetailReturn {
  detail: LearningTemplateDetail | undefined,
  isLoading: boolean,
}

export const useGetLearningCuratedTemplateDetail = ({
  templateId,
  enabled = true,
}: UseGetLearningCuratedTemplateDetailParams): UseGetLearningCuratedTemplateDetailReturn => {
  const result = useQuery({
    queryKey: learningCuratedTemplatesQueryKeys.singleContentDetail(templateId),
    queryFn: () => getLearningCuratedTemplateDetail({
      templateId,
    }),
    enabled: enabled && !!templateId,
  });

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
