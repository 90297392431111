import { keepPreviousData, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { Note, NoteContextType } from '~Common/const/interfaces';

export interface GetNotesParams {
  organizationId: string,
  contextId: string,
  contextType: NoteContextType,
}

export interface GetNotesResponse {
  notes: Note[],
}

export function getNotes({
  organizationId,
  contextId,
  contextType,
}: GetNotesParams): Promise<HttpCallReturn<GetNotesResponse>> {
  const searchParams = new URLSearchParams({
    id: contextId,
    type: contextType,
  });

  const url = `/organizations/${organizationId}/notes?${searchParams.toString()}`;

  return getApi<GetNotesResponse>(url);
}

interface UseGetNotesParams {
  contextId?: string,
  contextType: NoteContextType,
}

export function useGetNotes({
  contextId,
  contextType,
}: UseGetNotesParams): UseQueryResult<HttpCallReturn<GetNotesResponse>> {
  const organizationId = getOrganizationId();
  const queryKey = [organizationId, 'notes', contextType, contextId];

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useQuery({
    queryKey,
    queryFn: () => getNotes({
      organizationId: organizationId ?? '',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      contextId: contextId!,
      contextType,
    }),
    enabled: !!contextId,
    placeholderData: keepPreviousData,
  });
}
