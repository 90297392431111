import { PersonDisplayInformation } from '~Common/const/interfaces';
import { AgendaType } from '@leadr-hr/types';
import { css } from '@emotion/react';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { palette } from '~Common/styles/colors';
import { agendaItemNameMap } from './index';

const styles = {
  onePersonCreatingItem: (inline: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    flex: inline ? 'unset' : 1,
  }),
  text: css({
    color: palette.neutrals.gray700,
    fontStyle: 'italic',
    span: {
      fontStyle: 'italic',
    },
  }),
  bold: css({
    fontWeight: 600,
  }),
  rightSide: css({
    marginLeft: 'auto',
  }),
};

interface OnePersonCreatingItemProps {
  person: PersonDisplayInformation,
  agendaType: Exclude<AgendaType, AgendaType.Attachment>,
  showAvatar?: boolean,
  inline?: boolean,
}

const OnePersonCreatingItem = ({
  person,
  agendaType,
  showAvatar = true,
  inline = false,
  ...props
}: OnePersonCreatingItemProps): JSX.Element => (
  <div css={styles.onePersonCreatingItem(inline)}>
    {inline && (
      <span css={styles.text} {...props}>
        <span css={styles.bold}>
          {`${person.firstName} ${person.lastName}`}
        </span>
        <span>
          {` is adding a ${agendaItemNameMap[agendaType]}`}
        </span>
      </span>
    )}
    {!inline && (
      <span css={styles.text} {...props}>
        {`[${person.firstName} ${person.lastName} is adding a ${agendaItemNameMap[agendaType]}]`}
      </span>
    )}
    {showAvatar && (
      <div css={styles.rightSide}>
        <NewBaseAvatar
          name={`${person.firstName} ${person.lastName}`}
          avatarSize={30}
          profileImageUrl={person.profileImageUrl}
        />
      </div>
    )}
  </div>
);

export default OnePersonCreatingItem;
