import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

export const inputStyles = css({
  fieldset: {
    border: 0,
  },
  '& .Mui-error': {
    '& label': {
      color: palette.brand.red,
    },
    '& .MuiInputBase-input': {
      borderColor: palette.brand.red,
    },
  },
  '& label': {
    color: palette.neutrals.gray700,
    fontWeight: 400,
    left: '0.1rem',
    letterSpacing: '0.5px',
    position: 'absolute',
    top: '1.325rem',
    width: '150%',
  },
  '& .MuiInputBase-input': {
    border: '1px solid transparent',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    fontWeight: 600,
    height: 'auto',
    padding: '1.825rem 1rem 0.75rem 1rem',
  },
  '& .MuiInputLabel-asterisk': {
    backgroundColor: palette.brand.red,
    borderRadius: '100%',
    color: 'transparent',
    height: '0.5rem',
    right: '0.75rem',
    position: 'absolute',
    width: '0.5rem',
  },
  '.Mui-focused': {
    color: palette.neutrals.gray700,
  },
});

export const menuItemStyles = css({
  fontFamily: 'ProximaNova',
});

export const FORM_STYLES = {
  inputContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.625rem',
  }),
  subheading: css({
    color: palette.neutrals.gray500,
    fontSize: '0.625rem',
    fontWeight: 400,
    marginTop: '0.25rem',
    marginBottom: '0.5rem',
    textTransform: 'uppercase',
  }),
} as const;
