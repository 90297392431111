import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { faBolt } from '@fortawesome/pro-regular-svg-icons';
import { AgendaType, RootAgendaPermission } from '@leadr-hr/types';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import CreateAgendaItem, { OnSaveProps } from '~Meetings/components/details/agenda/CreateAgendaItem';
import FeedbackList from '~Deprecated/components/Meetings/CreateAgenda/FeedbackList';
import ContentList from '~Deprecated/components/Meetings/CreateAgenda/ContentList';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DetailsHeader from '~Meetings/components/shared/DetailsHeader';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import useMeetingRealtime from '~Meetings/hooks/useMeetingRealtime';
import useGetAgendas from '~Meetings/hooks/v2/useGetAgendas';
import { palette } from '~Common/styles/colors';
import { withoutDesktopObject } from '~Common/styles/mixins';
import { getLocalId } from '~Common/utils/uuid';
import { AgendaTopicRealTimeMessageType } from '~Meetings/const/realTimeMeetingMessages';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { useUrlQuery } from '~Common/hooks/useUrlQuery';
import { topicSuggestionsDrawerTemplate } from '~Meetings/components/topic-suggestions/TopicSuggestionsDrawer';
import AddAgendaButton from '~Meetings/components/details/AddAgendaButton';
import { useDraft } from '~Common/hooks/useDraft';
import { useCreateAgendaSection } from '~Meetings/hooks/sections/useCreateAgendaSection';
import { useCreateAgendaItem } from '~Meetings/hooks/v2/useCreateAgendaItem';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { ShowAgendaEditorProps, useShowAgendaEditor } from '~Meetings/hooks/utils/useShowAgendaEditor';
import { DNDKitTreeAgendaList } from './agenda/DragAndDrop/DNDKitTreeAgendaList';
import AttachmentsModal from './agenda/AttachmentsModal';
import { ConfirmDeleteAttachmentModal } from './agenda/ConfirmDeleteAttachmentModal';

const styles = {
  container: css({
    backgroundColor: palette.neutrals.white,
    padding: '1rem 1.75rem',
    boxShadow: '0 0.3125rem 0.9375rem rgba(0,0,0,.07)',
    borderRadius: '0.5rem',
  }, withoutDesktopObject({
    boxShadow: 'none',
    padding: '1rem',
  })),
  agendaSection: css({
    background: palette.neutrals.white,
  }),
  sectionTitle: css({
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '1rem',
    color: palette.neutrals.gray800,
  }),
  hr: css({
    height: '0.5rem',
    margin: '0.625rem 0 0',
    padding: 0,
  }),
  commentsButton: css({
    fontSize: '0.75rem',
  }),
  addAgendaButton: css({
    marginTop: '.75rem',
  }),
  newAgendaItem: css({
    marginTop: '1.25rem',
  }),
};

interface ViewProps {
  updatedFeedbackItems: unknown[], // Change unknown to match the actual payload later
  updatedContentItems: unknown[], // Change unknown to match the actual payload later
  huddleId: string,
  type: MeetingTypeEnum,
  showAgendaEditor: boolean,
  onCancelNewAgendaItem: () => void,
  showAddTopicButton: boolean,
  handleTopicSuggestionClick: () => void,
  isLoading: boolean,
  onChange: ({ value }: { value: string }) => void,
  onSave: (onSaveProps: OnSaveProps) => void,
  onCreateNewAgenda: (agendaType: AgendaType) => void,
  currentAgendaType: AgendaType,
  isShowingAllCommentSections: boolean,
  handleMakePrivate: ({ isPrivate }: { isPrivate: boolean }) => void,
  canAddRecurringAgendaItem: boolean,
  setIsShowingAllCommentSections: (isShowingAllCommentSections: boolean) => void,
  draftKey: string[],
  searchedItemId?: string,
  agendaEditorProps: ShowAgendaEditorProps,
  canAddAttachment: boolean,
  hasMaxSections?: boolean,
  hasMaxTopics?: boolean,
  maxSections?: number,
  maxTopics?: number,
  maxAttachmentsPerTopic?: number,
}

const View = ({
  huddleId,
  updatedFeedbackItems,
  updatedContentItems,
  type,
  showAgendaEditor,
  onCancelNewAgendaItem,
  showAddTopicButton,
  handleTopicSuggestionClick,
  isLoading,
  onChange,
  onSave,
  onCreateNewAgenda,
  currentAgendaType,
  isShowingAllCommentSections,
  handleMakePrivate,
  canAddRecurringAgendaItem,
  setIsShowingAllCommentSections,
  draftKey,
  searchedItemId,
  agendaEditorProps,
  canAddAttachment,
  hasMaxSections,
  hasMaxTopics,
  maxSections,
  maxTopics,
  maxAttachmentsPerTopic,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <div css={styles.container}>
      <div css={styles.agendaSection}>
        <DetailsHeader
          title="Agenda"
          renderSecondButton={() => (
            <LeadrButton
              size="small"
              variant="ghost"
              data-test-id="meetingTopicSuggestions"
              onClick={handleTopicSuggestionClick}
              disabled={isLoading}
            >
              <LeadrButton.IconAndText text="Topic Suggestions" icon={faBolt} />
            </LeadrButton>
          )}
          renderAncillaryButton={() => (
            <LeadrButton
              css={styles.commentsButton}
              variant="text"
              buttonTextColor={palette.brand.indigo}
              data-test-id={isShowingAllCommentSections ? 'hideAllComments' : 'showAllComments'}
              onClick={() => setIsShowingAllCommentSections(!isShowingAllCommentSections)}
            >
              {isShowingAllCommentSections ? 'Hide All Comments' : 'Show All Comments'}
            </LeadrButton>
          )}
        />

        <div>
          <DNDKitTreeAgendaList
            huddleId={huddleId}
            meetingType={type}
            isShowingAllCommentSections={isShowingAllCommentSections}
            onCreateNewAgenda={onCreateNewAgenda}
            searchedItemId={searchedItemId}
            hasMaxTopics={hasMaxTopics!}
          />
        </div>
        {showAgendaEditor && (
          <CreateAgendaItem
            huddleId={huddleId}
            draftKey={draftKey}
            onCancel={onCancelNewAgendaItem}
            onChange={onChange}
            onSave={onSave}
            simplifiedView={currentAgendaType === AgendaType.AgendaSection}
            showPrivateButton={currentAgendaType === AgendaType.AgendaTopic}
            showRecurringButton={canAddRecurringAgendaItem}
            showAddAttachmentsButton={canAddAttachment && currentAgendaType === AgendaType.AgendaTopic}
            maxAttachmentsPerTopic={maxAttachmentsPerTopic}
            onMakePrivate={handleMakePrivate}
            css={styles.newAgendaItem}
            {...agendaEditorProps}
          />
        )}

        {!!updatedFeedbackItems.length && (
          <div>
            <div css={styles.sectionTitle}>
              Feedback
            </div>
            <FeedbackList
              isEditable
              questions={updatedFeedbackItems}
              meetingId={huddleId}
            />
          </div>
        )}

        {!!updatedContentItems.length && (
          <div>
            <div css={styles.sectionTitle}>
              Learning Content
            </div>
            <hr css={styles.hr} />
            <ContentList items={updatedContentItems} />
          </div>
        )}
      </div>

      {showAddTopicButton && (
        <AddAgendaButton
          css={styles.addAgendaButton}
          onClick={onCreateNewAgenda}
          hasMaxSections={hasMaxSections}
          hasMaxTopics={hasMaxTopics}
          maxSections={maxSections!}
          maxTopics={maxTopics!}
        />
      )}
    </div>

    <AttachmentsModal />
    <ConfirmDeleteAttachmentModal />
  </div>
);

type Permissions = {
  canAddOneTimeTopics: boolean,
  canAddRecurringTopic: boolean,
  canAddRecurringSection: boolean,
  canAddAttachment: boolean,
};

interface MeetingAgendaProps {
  huddleId: string,
  type: MeetingTypeEnum,
}

const MeetingAgenda = ({
  huddleId,
  type,
  ...props
}: MeetingAgendaProps): JSX.Element => {
  const orgId = getOrganizationId() ?? '';
  const userId = getUserId() ?? '';
  const { user } = useUserProfile(userId);
  const [agendaCreateEventId, setAgendaCreateEventId] = useState('');
  const { item: meetingDetails, isLoading } = useMeetingDetails({ id: huddleId, type }) ?? {};
  const { sendMessage } = useMeetingRealtime({ huddleId });
  const dispatch = useDispatch();

  const { data } = useGetAgendas({ huddleId });

  const permissions = data?.response?.permissions || [];

  const permissionsMap = {
    canAddOneTimeTopics: RootAgendaPermission.CanAddOneTimeTopics,
    canAddRecurringTopic: RootAgendaPermission.CanAddRecurringTopics,
    canAddRecurringSection: RootAgendaPermission.CanAddRecurringSections,
    canAddAttachment: RootAgendaPermission.CanAddAttachment,
  };

  const {
    canAddRecurringTopic,
    canAddRecurringSection,
    canAddAttachment,
  } = Object.keys(permissionsMap).reduce((acc, key) => ({
    ...acc,
    [key]: permissions.includes(permissionsMap[key as keyof Permissions]),
  }), {} as Permissions);

  const canAddRecurringAgendaItem = canAddRecurringTopic || canAddRecurringSection;

  const maxTopics = data?.response?.maxTopics;
  const maxSections = data?.response?.maxSections;

  const {
    questions: feedbackItems = [],
    contents: contentItems = [],
    attendeeOrgUserIds,
    organizer,
  } = meetingDetails ?? {};

  const otherAttendeeId = attendeeOrgUserIds?.find((attendeeId) => attendeeId !== organizer?.orgUserId);

  const { searchedItem: searchedItemId } = useUrlQuery() as { searchedItem?: string };

  const {
    showAgendaEditor,
    hideAgendaEditor,
    useGetAgendaEditorProps,
    useIsAgendaEditorVisisble,
  } = useShowAgendaEditor();

  useEffect(() => () => hideAgendaEditor({ identifier: 'create' }), [hideAgendaEditor, huddleId]);

  const showNewAgendaEditor = useIsAgendaEditorVisisble({ identifier: 'create' });

  const agendaEditorProps = useGetAgendaEditorProps({ identifier: 'create' });

  const [newAgendaInstance, setNewAgendaInstance] = useState(0);
  const [isShowingAllCommentSections, setIsShowingAllCommentSections] = useState(!!searchedItemId);
  const [currentAgendaType, setCurrentAgendaType] = useState<AgendaType>(AgendaType.AgendaTopic);

  // The content questions from meetingDetails contains responses from ALL user's who responded so we need to filter them to just the two user's in the 1:1
  const updatedContentItems = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (contentItems?.map((contentItem) => ({
      // @ts-expect-error TODO: Type this
      ...contentItem,
      // @ts-expect-error TODO: Type this
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
      questions: contentItem.questions?.map((question) => ({
        ...question,
        // @ts-expect-error TODO: Type this
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
        response: question.response?.filter((res) => res.respondedBy[0].orgUserId === otherAttendeeId || res.respondedBy[0].orgUserId === organizer),
      })),
    })))
  ), [contentItems, organizer, otherAttendeeId]);

  // The questions from meetingDetails contains responses from ALL user's who responded so we need to filter them to just the two user's in the 1:1
  const updatedFeedbackItems = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (feedbackItems?.map((feedbackItem) => ({
      // @ts-expect-error TODO: Type this
      ...feedbackItem,
      // @ts-expect-error TODO: Type this
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      response: feedbackItem.response?.filter((res) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (res.respondedBy[0].orgUserId === otherAttendeeId || res.respondedBy[0].orgUserId === organizer)
      )),
    })))
  ), [feedbackItems, otherAttendeeId, organizer]);

  const onCancelNewAgendaItem = (): void => {
    sendMessage(AgendaTopicRealTimeMessageType.CreateCanceled, {
      eventId: agendaCreateEventId,
    });

    setAgendaCreateEventId('');
    hideAgendaEditor({ identifier: 'create' });
    setNewAgendaInstance(newAgendaInstance - 1);
  };

  const handleTopicSuggestionClick = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...topicSuggestionsDrawerTemplate,
        args: {
          meetingId: huddleId,
          huddleId,
          maxTopics,
          maxSections,
        },
      },
    }));
  };

  const draftKey = [orgId, huddleId, 'agenda', 'create', newAgendaInstance.toString()];

  const { draft, setDraft } = useDraft();

  const { mutate: doCreateAgendaItem } = useCreateAgendaItem({ draftKey });
  const { mutate: doCreateAgendaSection } = useCreateAgendaSection();

  const onChange = useCallback(({ value }: { value: string }) => {
    setDraft({
      ...draft,
      value,
    });
  }, [
    draft,
    setDraft,
  ]);

  const onSave = useCallback(({
    text,
    isPrivate,
    isRecurring,
    attachments,
  }: OnSaveProps) => {
    hideAgendaEditor({ identifier: 'create' });
    if (currentAgendaType === AgendaType.AgendaSection) {
      doCreateAgendaSection({
        huddleId,
        section: {
          text,
          isRecurring,
        },
        eventId: agendaCreateEventId,
      });
    } else {
      doCreateAgendaItem({
        huddleId,
        text,
        isRecurring,
        isPrivate,
        eventId: agendaCreateEventId,
        attachments,
      });
    }
  }, [hideAgendaEditor, currentAgendaType, doCreateAgendaSection, huddleId, agendaCreateEventId, doCreateAgendaItem]);

  const sendCreateAgendaItemRealTimeEvent = useCallback((agendaType: AgendaType, newEventId: string) => {
    sendMessage(AgendaTopicRealTimeMessageType.Create, {
      type: agendaType,
      eventId: newEventId,
      creator: {
        orgUserId: user?.organizationUserId ?? '',
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        profileImageUrl: user?.profileImageUrl ?? '',
      },
      parentIds: [],
    });
  }, [sendMessage, user]);

  const sendCancelAgendaItemRealTimeEvent = useCallback((newEventId: string) => {
    sendMessage(AgendaTopicRealTimeMessageType.CreateCanceled, {
      eventId: newEventId,
    });
  }, [sendMessage]);

  const onCreateNewAgenda = useCallback((agendaType: AgendaType) => {
    const newEventId = getLocalId();
    sendCreateAgendaItemRealTimeEvent(agendaType, newEventId);

    showAgendaEditor({ identifier: 'create' });
    setAgendaCreateEventId(newEventId);
    setCurrentAgendaType(agendaType);
    setNewAgendaInstance(newAgendaInstance + 1);
  }, [showAgendaEditor, sendCreateAgendaItemRealTimeEvent, newAgendaInstance]);

  const handleMakePrivate = ({ isPrivate }: { isPrivate: boolean}): void => {
    if (isPrivate) {
      sendCancelAgendaItemRealTimeEvent(agendaCreateEventId);
    } else {
      sendCreateAgendaItemRealTimeEvent(currentAgendaType, agendaCreateEventId);
    }
  };

  const hookProps = {
    handleTopicSuggestionClick,
    updatedFeedbackItems,
    updatedContentItems,
    huddleId,
    showAgendaEditor: showNewAgendaEditor,
    onCancelNewAgendaItem,
    type,
    showAddTopicButton: !showNewAgendaEditor,
    isLoading,
    onChange,
    onSave,
    onCreateNewAgenda,
    currentAgendaType,
    isShowingAllCommentSections,
    handleMakePrivate,
    canAddRecurringAgendaItem: !!canAddRecurringAgendaItem,
    setIsShowingAllCommentSections,
    draftKey,
    searchedItemId,
    agendaEditorProps,
    canAddAttachment,
    maxAttachmentsPerTopic: data?.response?.maxAttachmentsPerTopic,
    hasMaxSections: data?.response?.hasMaxSections,
    hasMaxTopics: data?.response?.hasMaxTopics,
    maxSections,
    maxTopics,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingAgenda;
