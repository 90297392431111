import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useDebouncedWindowResize = (delay: number, callback?: (windowWidth: number) => void): number => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  const handleResize = (): void => {
    setWindowWidth(window.innerWidth);
  };

  // Debounced version of the resize handler
  const debouncedResize = debounce(handleResize, delay);

  useEffect(() => {
    // Attach debounced resize handler to window resize event
    window.addEventListener('resize', debouncedResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', debouncedResize);
      // Cancel any pending debounced calls to avoid memory leaks
      debouncedResize.cancel();
    };
  }, [debouncedResize]);

  // Execute the callback when window width changes
  useEffect(() => {
    callback?.(windowWidth);
  }, [callback, windowWidth]);

  return windowWidth;
};
