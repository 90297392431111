import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { parseQueryString } from '~Deprecated/utils';
import { navigateAction } from '~Deprecated/actions/navigate';
import { SELECT_ORGANIZATION } from '~Common/const/routes';
import { getStorageItem } from '~Common/utils/localStorage';
import { setAccessTokenAction } from '~Deprecated/actions/storage/setAccessToken';
import { useImpersonationStore } from '~Deprecated/hooks/useImpersonationStore';

const ImpersonateLandingPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { i: key } = parseQueryString(search);

  const { setIsImpersonating } = useImpersonationStore();

  useEffect(() => {
    if (key && !window.sessionStorage.getItem('sessionId')) {
      let data = getStorageItem(key);
      if (data) {
        data = JSON.parse(data);
        window.sessionStorage.setItem('sessionId', key);
        window.sessionStorage.setItem('organizationId', null);
        window.sessionStorage.setItem('organizationName', null);
        window.sessionStorage.setItem('impersonationAccessToken', data.subject.accessToken);
        window.sessionStorage.setItem('impersonationRefreshToken', data.subject.refreshToken);
        window.sessionStorage.setItem('firstName', data.subject.firstName);
        window.sessionStorage.setItem('lastName', data.subject.lastName);
        window.sessionStorage.setItem('email', data.email);
        window.sessionStorage.setItem('userId', data.subject.userId);

        dispatch(setAccessTokenAction({
          jsonWebToken: data.subject.accessToken,
          jsonRefreshToken: data.subject.refreshToken,
        }));

        setIsImpersonating(true);

        localStorage.removeItem(key);
      }
    }
    dispatch(navigateAction({
      pathname: SELECT_ORGANIZATION,
    }));
  }, [dispatch, key, setIsImpersonating]);

  return (
    <div>
      Please wait.
    </div>
  );
};

ImpersonateLandingPage.propTypes = {};

ImpersonateLandingPage.defaultProps = {};

export default ImpersonateLandingPage;
