import { css, SerializedStyles } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  AriaAttributes,
} from 'react';

import {
  additive,
  defaultInput,
  destructive,
  grayBtn,
  option,
  hexToRGBA,
  flatAdditive,
} from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import { forMobile } from '~Common/styles/mixins';
import { gradients } from '~Deprecated/ui/styles/colors';

export enum BUTTON_COLOR {
  ADDITIVE = 'additive',
  DESTRUCTIVE = 'destructive',
  OPTION = 'option',
  DEFAULT = 'default',
  GRAY = 'gray',
  FLAT_ADD = 'flatAdditive',
}

export enum BUTTON_VARIANT {
  STACKED = 'stacked',
  ICON = 'icon',
  TEXT = 'text',
  DEFAULT = 'default',
}

const buttonStyleMap = {
  [BUTTON_COLOR.ADDITIVE]: additive,
  [BUTTON_COLOR.DESTRUCTIVE]: destructive,
  [BUTTON_COLOR.OPTION]: option,
  [BUTTON_COLOR.DEFAULT]: defaultInput,
  [BUTTON_COLOR.GRAY]: grayBtn,
  [BUTTON_COLOR.FLAT_ADD]: flatAdditive,
};

const buttonGradientMap = {
  [BUTTON_COLOR.ADDITIVE]: gradients.green,
  [BUTTON_COLOR.DESTRUCTIVE]: gradients.red,
  [BUTTON_COLOR.OPTION]: gradients.sky,
  [BUTTON_COLOR.DEFAULT]: gradients.purple,
  [BUTTON_COLOR.GRAY]: grayBtn,
  [BUTTON_COLOR.FLAT_ADD]: flatAdditive,
};

const styles = {
  variantStyles: (variant: BUTTON_VARIANT) => css`
    margin: 0 12px;
    padding: 8px;
    border: unset;
    cursor: pointer;
    display: flex;
    flex-direction: ${variant === BUTTON_VARIANT.DEFAULT ? 'row' : 'column'};
    font-size: 12px;
    align-items: center;
    min-height: ${variant === BUTTON_VARIANT.STACKED ? '64px' : 'unset'};
    min-width: ${variant !== BUTTON_VARIANT.ICON ? '72px' : 'unset'};
    transition: all 0.4s ease-in-out 0s;

    &:disabled {
      opacity: 0.3;
      cursor: unset;
    }

    ${forMobile(`
      display: grid;
      place-content: center;
      min-height: unset;
      margin: unset;
    `)}
  `,
};

const ButtonBorderRadius = css`
  border-radius: 8px;
`;

const iconStyle = css`
  width: 24px !important;
  height: 24px !important;
`;

const textWrapper = (variant: BUTTON_VARIANT): SerializedStyles => css`
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-block-start: ${variant === BUTTON_VARIANT.STACKED ? '4px' : '0'};
  margin-inline-start: ${variant === BUTTON_VARIANT.DEFAULT ? '8px' : '0'};

  ${variant !== BUTTON_VARIANT.TEXT && (
    forMobile(`
      margin-block-start: unset;
      margin-inline-start: unset;
      display: none;
    `)
  )}
`;

const colorStyle = (buttonColor: BUTTON_COLOR): SerializedStyles => css`
  color: white;
  background: ${buttonGradientMap[buttonColor]};
  box-shadow: 4px 4px 8px rgba(60, 60, 76, 0.2);

  &:hover {
    box-shadow: 0 0 0 8px ${hexToRGBA(buttonStyleMap[buttonColor], 0.4)};
  }
`;

// Support all of the standard React and HTMl button properties on top of what we are explicitly adding
export interface DefaultButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, AriaAttributes {
  icon: IconProp,
  buttonColor?: BUTTON_COLOR,
  variant?: BUTTON_VARIANT,
  text?: string,
  tooltip?: string,
}

// Special case for text buttons so icon is not required
export interface TextButtonProps extends Omit<DefaultButtonProps, 'icon'> {
  variant: BUTTON_VARIANT.TEXT
  icon?: never,
}

// Interface unions need to be type rather than an interface
export type ButtonProps = DefaultButtonProps | TextButtonProps;

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const Button = ({
  onClick,
  icon,
  text,
  type = 'button',
  buttonColor = BUTTON_COLOR.DEFAULT,
  variant = BUTTON_VARIANT.STACKED,
  tooltip = '',
  ...props
}: DefaultButtonProps): JSX.Element => {
  const renderButton = (): JSX.Element => (
    <button
      css={[styles.variantStyles(variant), ButtonBorderRadius, colorStyle(buttonColor)]}
      onClick={onClick}
      type={type}
      {...props}
    >
      {variant !== BUTTON_VARIANT.TEXT && (
        <div css={iconStyle}>
          {/* The FontAwesomeIcon component will complain if icon could potentially be undefined even if the only case */}
          {/* in which it would be undefined (per our interfaces) is when it would not be rendered at all. */}
          {/* So use nullish coalescing to remove the error rather than default it in our props above so we get better */}
          {/* type checking (IDE will yell at you if you don't include an icon for any BUTTON_VARIANT -other- than TEXT. */}
          {/* If it were optional instead it'd never yell even if not included for other variants) */}
          <FontAwesomeIcon
            css={iconStyle}
            icon={icon ?? 'question'}
          />
        </div>
      )}

      {variant !== BUTTON_VARIANT.ICON && text && (
        <span css={textWrapper(variant)}>{text}</span>
      )}
    </button>
  );

  if (tooltip.length > 0) {
    return (
      <Tooltip interactive content={tooltip} placement="top-start">
        { renderButton() }
      </Tooltip>
    );
  }

  return renderButton();
};

export default Button;

export { ButtonBorderRadius as ButtonSkeletonStyles };
