import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { DASHBOARD_CARD_COMPONENTS, EXPANDED_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import { GoalGroupRow } from './GoalGroupRow';
import { SubtabComponentProps } from '../ExpandedSubtab';

const styles = {
  ...DASHBOARD_CARD_COMPONENTS,
  ...EXPANDED_CARD_COMPONENTS,
};

const GoalGroup = ({
  pageSlice,
}: SubtabComponentProps): JSX.Element => (
  <>
    <TableContainer component={Paper}>
      <Table aria-label="Dependents" size="small">
        <TableHead>
          <TableRow css={styles.tableRowColor}>
            <TableCell css={styles.semiHeaderName}>Name</TableCell>
            <TableCell css={styles.semiHeader}>Active Goals</TableCell>
            <TableCell css={styles.semiHeader}>Owner updated in the last 30 days?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody css={styles.RowBody}>
          {pageSlice?.map((user) => (
            <GoalGroupRow key={user.orgUserId} userData={user} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

export { GoalGroup };
