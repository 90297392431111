import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

const styles = {
  tooltipWrapper: css`
    position: relative;
    opacity: 1;
    display: flex;
  `,
  tooltipBubble: css`
    position: absolute;
    z-index: 9999;
  `,
  tooltipMessage: css`
    position: absolute;
    background: #5a5a5a;
    border-radius: 4px;
    color: #FFFFFF;
    padding: 4px 8px;
    text-align: center;
    width: max-content;
    width: -webkit-max-content;
    display: inline-table;
    font-size: 0.65rem;
    white-space: normal;
    pointer-events: none;
  `,
  tooltipTrigger: css`
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline-grid;
  `,
};

const Tooltip = (props) => {
  const {
    showTooltip,
    message,
    children,
  } = props;

  const [tooltip, setTooltip] = useState(false);
  const wrapperRef = useRef(null);
  const childRef = useRef(null);

  const hide = () => {
    setTooltip(false);
  };

  const show = () => {
    showTooltip && setTooltip(true);
  };

  const padding = 16;
  // Vertical gap between tooltip and element
  const vGap = 4;

  const getTextWidth = (text, font) => {
    // re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const messageWidth = getTextWidth(message, 'normal 0.65rem ProximaNova');

  useEffect(() => {
    if (wrapperRef && childRef && wrapperRef.current && childRef.current) {
      const parentRect = wrapperRef.current.getBoundingClientRect();
      const { top, left, height } = parentRect;

      const { px, tw, ww } = {
        px: parentRect.x,
        tw: messageWidth + padding,
        ww: window.innerWidth,
      };

      /**
       * @var tx Tooltip X
       * Add padding to have a little gap on the right side.
       */
      const tx = Math.max(0, px + tw - ww + padding);

      const element = childRef.current;
      element.style.width = 'auto';
      element.style.position = 'absolute';
      element.style.top = `${top + height + vGap}px`;
      element.style['max-height'] = '30rem';
      element.style.left = `${left - tx}px`;
      element.style.background = 'white';
      element.style['border-radius'] = '4px';
      element.style['z-index'] = '9998';
      element.style['box-shadow'] = '0px 20px 32px rgba(0, 0, 0, 0.24)';
    }
  }, [tooltip]);

  return (
    <div css={styles.tooltipWrapper} ref={wrapperRef} onMouseLeave={hide}>
      <CSSTransition
        in={tooltip}
        timeout={tooltip ? 600 : 300}
        classNames="leadr-tooltip"
        unmountOnExit
      >
        <Portal node={document && document.getElementById('modal-root')}>
          <div ref={childRef} css={styles.tooltipBubble}>
            <span css={styles.tooltipMessage}>{ message }</span>
          </div>
        </Portal>
      </CSSTransition>
      <div css={styles.tooltipTrigger} onMouseOver={show}>
        { children }
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  showTooltip: PropTypes.bool,
  message: PropTypes.any,
};

Tooltip.defaultProps = {
  showTooltip: true,
  message: '',
};

export default Tooltip;
