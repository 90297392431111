import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { SelectChangeEvent } from '@mui/material';
import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';

const styles = {
  dropdown: css({
    border: '1px solid transparent',
    height: '2rem',
    padding: 0,

    '.MuiSelect-select': {
      fontSize: '0.75rem',
      paddingLeft: '0.75rem',
    },

    '.MuiSelect-icon': {
      right: '0.75rem',
    },
  }),
  dropdownItem: css({
    color: palette.neutrals.gray700,
  }),
};

export interface GoalContextTypeDropdownProps extends Omit<DropdownProps<Goals.GoalContextType | string>, 'renderItem' | 'value' | 'onChange'> {
  items: DropdownItem<Goals.GoalContextType | string>[],
  selectedContextType: Goals.GoalContextType | string,
  onContextTypeChange: (event: SelectChangeEvent<Goals.GoalContextType | string>) => void,
}

const GoalContextTypeDropdown = ({
  items,
  selectedContextType,
  onContextTypeChange,
  ...props
}: GoalContextTypeDropdownProps): JSX.Element => (
  <Dropdown
    displayEmpty
    css={styles.dropdown}
    value={selectedContextType}
    onChange={onContextTypeChange}
    renderItem={(item: DropdownItem<Goals.GoalContextType | string>) => (
      <div css={styles.dropdownItem}>
        {item.text}
      </div>
    )}
    items={items}
    {...props}
  />
);

export default GoalContextTypeDropdown;
