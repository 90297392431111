import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { REQUEST_TYPE } from '~Deprecated/common/const/request-type';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { SURVEY_SHAPE } from '~Common/const/proptypes';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import CompletedCountText from '~Common/V3/components/TextUtils/CompletedCountText';
import { ViewType } from '~Common/const/interfaces';
import GridCreatedSurveyCard, { GridCreatedSurveyCardSkeletonStyles } from './GridCreatedSurveyCard';
import { useGetSurveyById } from '../../Hooks/useSurveysList';
import GridReceivedSurveyCard, { GridReceivedSurveyCardSkeletonStyles } from './GridReceivedSurveyCard';
import { respondSurveyTemplate } from '../Drawers/Respond';
import { createdSurveyTemplate } from '../Drawers/Created';
import ListSurveyCard, { ListSurveyCardSkeletonStyles } from './ListSurveyCard';

const View = ({
  surveyRequestTypeFilter,
  surveyViewTypeFilter,
  onRespond,
  onViewCreated,
  survey,
  numberOfCompletedSurveys,
  numberOfSurveys,
  ...props
}) => (
  <>
    {/*
      TODO: this will need to be pulled out once pulse surveys are removed from the BE
      Specifically the addition of the && survey
    */}
    {(surveyViewTypeFilter === ViewType.GRID && survey) && (
      <>
        {surveyRequestTypeFilter === REQUEST_TYPE.RECEIVED && (
          <GridReceivedSurveyCard onSurveyClick={onRespond} survey={survey} {...props} />
        )}
        {surveyRequestTypeFilter === REQUEST_TYPE.REQUESTED && (
          <GridCreatedSurveyCard onSurveyClick={onViewCreated} survey={survey} {...props} />
        )}
      </>
    )}
    {/*
      TODO: this will need to be pulled out once pulse surveys are removed from the BE
      Specifically the addition of the && survey
    */}
    {(surveyViewTypeFilter === ViewType.LIST && survey) && (
      <>
        {surveyRequestTypeFilter === REQUEST_TYPE.RECEIVED && (
          <ListSurveyCard
            title={survey.title}
            dueDate={survey.completeBy}
            isComplete={!!survey.surveyParticipant.completedOn}
            onCardClick={onRespond}
            numberOfUsersAssigned={1}
            assignedUsersInfo={[survey.createdBy]}
          />
        )}
        {surveyRequestTypeFilter === REQUEST_TYPE.REQUESTED && (
          <ListSurveyCard
            title={survey.title}
            dueDate={survey.completeBy}
            isComplete={!!survey.completedOn}
            onCardClick={onViewCreated}
            numberOfUsersAssigned={survey.participants.length}
            assignedUsersInfo={survey.participants}
            renderCompletedSection={() => (
              <CompletedCountText completedCount={numberOfCompletedSurveys} totalCount={numberOfSurveys} />
            )}
          />
        )}
      </>
    )}
  </>
);

View.propTypes = {
  surveyViewTypeFilter: PropTypes.oneOf(Object.values(ViewType)).isRequired,
  surveyRequestTypeFilter: PropTypes.oneOf(Object.values(REQUEST_TYPE)).isRequired,
  onRespond: PropTypes.func.isRequired,
  onViewCreated: PropTypes.func.isRequired,
  survey: SURVEY_SHAPE,
  numberOfCompletedSurveys: PropTypes.number,
  numberOfSurveys: PropTypes.number,
};

View.defaultProps = {
  survey: null,
  numberOfCompletedSurveys: 0,
  numberOfSurveys: 0,
};

const SkeletonView = ({ surveyViewTypeFilter, surveyRequestTypeFilter }) => (
  <>
    {surveyViewTypeFilter === ViewType.GRID && (
      <>
        {surveyRequestTypeFilter === REQUEST_TYPE.RECEIVED && (
          <SkeletonLoader
            variant="rectangular"
            css={GridReceivedSurveyCardSkeletonStyles}
            renderComponent={() => (
              <></>
            )}
          />
        )}
        {surveyRequestTypeFilter === REQUEST_TYPE.REQUESTED && (
          <SkeletonLoader
            variant="rectangular"
            css={GridCreatedSurveyCardSkeletonStyles}
            renderComponent={() => (
              <></>
            )}
          />
        )}
      </>
    )}
    {surveyViewTypeFilter === ViewType.LIST && (
      <SkeletonLoader
        variant="rectangular"
        css={ListSurveyCardSkeletonStyles}
        renderComponent={() => (
          <></>
        )}
      />
    )}
  </>
);

SkeletonView.propTypes = {
  surveyViewTypeFilter: PropTypes.oneOf(Object.values(ViewType)).isRequired,
  surveyRequestTypeFilter: PropTypes.oneOf(Object.values(REQUEST_TYPE)).isRequired,
};

SkeletonView.defaultProps = {
};

const SurveyCard = ({ id, surveyRequestTypeFilter, ...props }) => {
  const dispatch = useDispatch();

  const { survey } = useGetSurveyById({ id });

  let numberOfCompletedSurveys = 0;
  let numberOfSurveys = 0;

  if (surveyRequestTypeFilter === REQUEST_TYPE.REQUESTED) {
    numberOfCompletedSurveys = survey.surveyParticipants.filter((person) => person.completedOn).length;
    numberOfSurveys = survey.participants.length;
  }
  const hookProps = {
    survey,
    onRespond: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...respondSurveyTemplate,
          args: {
            surveyId: id,
          },
        },
      }));
    },
    onViewCreated: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...createdSurveyTemplate,
          args: {
            surveyId: id,
          },
        },
      }));
    },
    surveyRequestTypeFilter,
    numberOfCompletedSurveys,
    numberOfSurveys,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

SurveyCard.propTypes = {
  id: PropTypes.number.isRequired,
  surveyRequestTypeFilter: PropTypes.oneOf(Object.values(REQUEST_TYPE)).isRequired,
};

SurveyCard.defaultProps = {};

export default SurveyCard;

export { View, SkeletonView as SurveyCardSkeleton };
