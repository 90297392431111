import { useMemo } from 'react';

import {
  useListItem, useList, useQueryConfig,
} from '~Common/queries';
import { buildFlattener, buildInfiniteFlattener } from '~Common/hooks/utils';
import {
  convertTimestampToDate, isToday, isTomorrow, convertTimestampToDayOfWeek,
} from '~Deprecated/utils/meeting';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';

// No statuses to key off for this API
export const UPCOMING_MEETING_STATUS = {
  NONE: 'NONE',
};

const infiniteFlatteners = buildInfiniteFlattener(UPCOMING_MEETING_STATUS);

const listConfig = {
  buildApi: ({ currentUserOrgId }) => ['/organizations/', currentUserOrgId, '/huddles/upcoming'].join(''),
  buildQueryKey: ({ currentUserOrgId }) => [currentUserOrgId, 'huddles', 'upcoming'],
  listFlattener: buildFlattener({}),
  normalizeItemFromServer: ({ huddleAttendees, ...result }) => ({
    huddleAttendees: result.factoryType === 'COACHING' ? huddleAttendees.filter((attendee) => attendee !== result.huddleOwner) : huddleAttendees,
    ...result,
  }),
};

const useFetchParams = () => ({});

const useUpcomingMeetings = () => {
  const fetchParams = useFetchParams();

  const queryConfig = useQueryConfig({
    ...listConfig,
    fetchParams,
    infiniteFlattener: infiniteFlatteners[UPCOMING_MEETING_STATUS.NONE],
  });

  return useList({
    queryConfig,
  });
};

const useUpcomingMeetingListItem = (id) => {
  const fetchParams = useFetchParams();

  const queryConfig = useQueryConfig({
    ...listConfig,
    fetchParams,
    infiniteFlattener: infiniteFlatteners[UPCOMING_MEETING_STATUS.NONE],
  });

  const { item } = useListItem({
    queryConfig,
    id,
  });
  return item;
};

const useUpcomingMeetingsByDate = () => {
  const { list, listData, ...result } = useUpcomingMeetings();
  const { timezone } = useTimezone();

  const { meetingDates, meetingListByDate } = useMemo(() => {
    const sortedMeetings = [...list].sort((a, b) => listData[a].startTimeInMillis - listData[b].startTimeInMillis);
    const meetingCategories = {};

    sortedMeetings.forEach((meetingId) => {
      const meeting = listData[meetingId];

      if (isToday(meeting.startTimeInMillis, timezone)) {
        if (!meetingCategories.Today || !Array.isArray(meetingCategories.Today)) {
          meetingCategories.Today = [];
        }

        meetingCategories.Today.push(meeting.id);
      } else if (isTomorrow(meeting.startTimeInMillis, timezone)) {
        if (!meetingCategories.Tomorrow || !Array.isArray(meetingCategories.Tomorrow)) {
          meetingCategories.Tomorrow = [];
        }

        meetingCategories.Tomorrow.push(meeting.id);
      } else {
        const monthDay = `${convertTimestampToDayOfWeek(meeting.startTimeInMillis, timezone)} - ${convertTimestampToDate(meeting.startTimeInMillis, timezone)}`;

        if (!meetingCategories[monthDay] || !Array.isArray(meetingCategories[monthDay])) {
          meetingCategories[monthDay] = [];
        }

        meetingCategories[monthDay].push(meeting.id);
      }
    });

    return {
      meetingDates: Object.keys(meetingCategories),
      meetingListByDate: meetingCategories,
    };
  }, [list, listData, timezone]);

  return {
    ...result,
    list,
    listData,
    meetingDates,
    meetingListByDate,
  };
};

export {
  useUpcomingMeetings, useUpcomingMeetingListItem, useUpcomingMeetingsByDate,
};
