import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Link, useHistory } from 'react-router-dom';
import { palette } from '~Common/styles/colors';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from '~Common/V3/components/uncontrolled';
import {
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { toast } from '~Common/components/Toasts';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { Goals } from '@leadr-hr/types';
import { InteriorTopBar } from '../components/GoalsTopBar/InteriorTopBar';
import { GoalsRoutes } from './GoalsRouter';
import {
  FormValues, conformToDto, createGoalFormResolver, createGoalFormSchema,
} from '../schemata/CreateGoalSchemata';
import { DEFAULT_GOAL, DEFAULT_TEAM_ID } from '../const/defaults';
import { CreateGoalForm } from '../components/Create';
import { GoalShape, ValidationErrors } from '../const/types';
import { useCreateGoal } from '../hooks/useCreateGoal';

const styles = {
  container: (isMobile: boolean) => css({
    width: '100%',
    margin: '1.875rem 1.875rem 0 1.875rem',
  }, isMobile && {
    margin: '.875rem .875rem 0 .875rem',
    overflow: 'hidden',
  }),
  textBackButton: css({
    fontSize: '1rem',
    fontWeight: 400,
    color: palette.neutrals.gray800,
  }),
  icon: css({
    marginRight: '0.5rem',
  }),
  buttonSpacing: css({
    marginRight: '.625rem',
  }),
  rightSide: (isMobile: boolean) => css({

  }, isMobile && {
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

interface ViewProps {
  formContext: UseFormReturn<FormValues>,
  goal: GoalShape,
  runValidations: () => void,
  isMobile: boolean,
  allowedContextTypes?: Goals.GoalContextType[],
}

const View = ({
  formContext,
  goal,
  runValidations,
  isMobile,
  allowedContextTypes,
}: ViewProps): JSX.Element => (
  <div css={styles.container(isMobile)}>
    <Form
      formContext={formContext}
      onSubmit={() => null}
    >
      <InteriorTopBar
        renderLeftSide={() => (
          <>
            <LeadrButton
              component={Link}
              to={GoalsRoutes?.Dashboard}
              variant="text"
              css={styles.textBackButton}
              textButtonColor={palette.neutrals.gray700}
              data-test-id="goalsBackToGoalsList"
            >
              <FontAwesomeIcon
                css={styles.icon}
                icon={faArrowLeft}
              />
              Back
            </LeadrButton>
          </>
        )}
        renderRightSide={() => (
          <>
            <div css={styles.rightSide(isMobile)}>
              <LeadrButton
                data-test-id="goalsCancelCreateGoal"
                variant="ghost"
                css={styles.buttonSpacing}
                component={Link}
                to={GoalsRoutes?.Dashboard}
              >
                Cancel
              </LeadrButton>
              <LeadrButton
                type="submit"
                data-test-id="goalsSaveCreateGoal"
                onClick={runValidations}
              >
                Save Goal
              </LeadrButton>
            </div>
          </>
        )}
      />
      <CreateGoalForm
        formContext={formContext}
        goal={goal}
        allowedContextTypes={allowedContextTypes}
      />
    </Form>
  </div>
);

const CreateGoal = (): JSX.Element => {
  const goal = DEFAULT_GOAL;
  const isMobile = useIsMobileQuery();
  const orgUserId = getOrganizationUserId() ?? '';

  function defaultValues(): FormValues {
    const {
      title,
      description,
      participants,
      priority,
      context,
      category,
      externalLink,
      isPrivate,
      startTimeInMillis,
      endTimeInMillis,
    } = goal;

    return {
      title,
      description,
      participants,
      priority,
      context,
      category,
      externalLink,
      isPrivate,
      startTimeInMillis,
      endTimeInMillis,
      owner: orgUserId,
      type: goal.context.contextType,
      team: DEFAULT_TEAM_ID,
    };
  }

  const formContext = useForm<FormValues>({
    defaultValues: defaultValues(),
    resolver: createGoalFormResolver,
  });

  const history = useHistory();
  const onSuccess = (): void => {
    history.push(GoalsRoutes.Dashboard);
  };

  const { mutate: createGoalMutation } = useCreateGoal();

  const runValidations = (): void => {
    const data = formContext.getValues();
    createGoalFormSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        const formData = conformToDto(data);
        createGoalMutation({ goal: formData }, { onSuccess });
      })
      .catch((err: ValidationErrors) => {
        err.errors.forEach((error) => {
          toast.error(error);
        });
      });
  };

  const hookProps = {
    formContext,
    goal,
    runValidations,
    isMobile,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };

export default CreateGoal;
