import { getOrganizationId } from '~Common/utils/localStorage';
import {
  getApi,
  HttpCallReturn,
} from '~Deprecated/services/HttpService';
import { buildQueryString } from '~Common/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { reflectionQueryKeys } from '../const/queryKeys';
import {
  DirectReportReflection, ReflectionsFavoriteType, ReflectionsIsActiveType, ReflectionType,
} from '../types';
import { favoriteStatusLookupTable, isActiveLookupTable, ReflectionsListQueryParams } from './useGetReflectionsList';
import { defaultDirectReportFilter } from '../const/defaults';

export interface DirectReportReflectionsListQueryParams extends ReflectionsListQueryParams {
  startDate: string,
  endDate: string,
  ownerId: string,
  reviewPeriod: string,
}

async function getDirectReportReflectionsList({
  search,
  reflectionType,
  favoriteStatus,
  isInActiveReview,
  index,
  limit,
  startDate,
  endDate,
  ownerId,
  reviewPeriod,
}: DirectReportReflectionsListQueryParams): Promise<HttpCallReturn<DirectReportReflectionsListReturn>> {
  const queryString = buildQueryString({
    search,
    favoriteStatus: favoriteStatusLookupTable[favoriteStatus as unknown as ReflectionsFavoriteType] === null ? undefined
      : favoriteStatusLookupTable[favoriteStatus as unknown as ReflectionsFavoriteType],
    isInActiveReview: isActiveLookupTable[isInActiveReview as unknown as ReflectionsIsActiveType] === null ? undefined
      : isActiveLookupTable[isInActiveReview as unknown as ReflectionsIsActiveType],
    index,
    limit,
    reflectionType: reflectionType[0] === ReflectionType.All ? undefined : reflectionType,
    startDate,
    endDate,
    ownerId: ownerId === defaultDirectReportFilter.value ? undefined : ownerId,
    reviewPeriod,
  });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/reflections/manager${queryString}`;

  return getApi<DirectReportReflectionsListReturn>(serverUrl);
}

type UseGetDirectReportReflectionsListParams = DirectReportReflectionsListQueryParams & { enabled?: boolean };

export interface DirectReportReflectionsListReturn {
  currentPage: number,
  hasNext: boolean,
  hasPrevious: boolean,
  items?: DirectReportReflection[],
  pageSize: number,
  totalCount: number,
  totalPages: number,
}
interface UseGetDirectReportReflectionsListReturn {
  isLoading: boolean,
  directReportReflections?: DirectReportReflection[],
  totalPages: number,
}

export function useGetDirectReportReflectionsList({
  search,
  reflectionType,
  favoriteStatus,
  isInActiveReview,
  index,
  limit,
  startDate,
  endDate,
  ownerId,
  reviewPeriod,
  enabled,
}: UseGetDirectReportReflectionsListParams): UseGetDirectReportReflectionsListReturn {
  const result = useQuery({
    queryKey: reflectionQueryKeys.directReportList({
      search,
      reflectionType,
      favoriteStatus,
      isInActiveReview,
      index,
      limit,
      startDate,
      endDate,
      ownerId,
      reviewPeriod,
    }),
    queryFn: () => getDirectReportReflectionsList({
      search,
      reflectionType,
      favoriteStatus,
      isInActiveReview,
      index,
      limit,
      startDate,
      endDate,
      ownerId,
      reviewPeriod,
    }),
    enabled,
    placeholderData: keepPreviousData,
  });

  return {
    isLoading: result.isLoading,
    directReportReflections: result.data?.response?.items,
    totalPages: result?.data?.response?.totalPages ?? 0,
  };
}
