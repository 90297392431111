import { createAction } from '@reduxjs/toolkit';
import { call, debounce } from 'redux-saga/effects';
import uniq from 'lodash/uniq';

import { sagaMiddleware } from '~Deprecated/store';
import { registerAction as insightsRegister } from '~Deprecated/reducers/organizationInsights';
import { registerAction as membersRegister } from '~Deprecated/reducers/organizationMembers';
import { getOrganization } from '~Deprecated/services/organizationInsights';
import { callApi } from '~Deprecated/sagas/callApi';
import { flattenData, mutateState } from '~Deprecated/utils/reduxUtils';

const loadOrganizationInsightDetailsAction = createAction('LEADR.LOAD_ORGANIZATION_DETAILS');
const loadOrganizationInsightDetailsSuccessAction = createAction('LEADR.LOAD_ORGANIZATION_DETAILS_SUCCESS');
const loadOrganizationInsightDetailsFailureAction = createAction('LEADR.LOAD_ORGANIZATION_DETAILS_FAILURE');

function* onloadOrganizationInsightDetails(action) {
  yield call(callApi, {
    api: getOrganization,
    payload: action.payload,
    successAction: loadOrganizationInsightDetailsSuccessAction,
    failureAction: loadOrganizationInsightDetailsFailureAction,
  });
}

function* loadOrganizationInsightDetailsWatcher() {
  yield debounce(750, loadOrganizationInsightDetailsAction, onloadOrganizationInsightDetails);
}

function onSuccessForInsights(state, action) {
  const {
    response,
    id,
  } = action.payload;

  const { ids } = flattenData({
    responseData: response.list[0].users,
    idName: 'userId',
  });

  // This action can be dispatched from both Nexus and Org. By checking for valid data here, we can determine which:
  // We check for this because this handler is specifically for Nexus. Org should skip it.
  const isFromNexus = !!state.data[id];

  if (isFromNexus) {
    const currentMembers = state.data[id].members || [];

    return mutateState(state, (draftState) => {
      draftState.data[id].members = uniq([...currentMembers, ...ids]);

      return draftState;
    });
  }

  return state;
}

function onSuccessForMembers(state, action) {
  const {
    response,
  } = action.payload;

  const { data, ids } = flattenData({
    responseData: response.list[0].users,
    idName: 'userId',
  });

  const currentData = state.data || {};

  return mutateState(state, (draftState) => {
    draftState.ids = ids;
    draftState.data = { ...currentData, ...data };

    return draftState;
  });
}

function loadOrganizationInsightDetailsFailure(state, action) {
  return mutateState(state, (draftState) => {
    draftState.error = action.payload.error;

    return draftState;
  });
}

insightsRegister(loadOrganizationInsightDetailsSuccessAction, onSuccessForInsights);
insightsRegister(loadOrganizationInsightDetailsFailureAction, loadOrganizationInsightDetailsFailure);
membersRegister(loadOrganizationInsightDetailsSuccessAction, onSuccessForMembers);

sagaMiddleware.run(loadOrganizationInsightDetailsWatcher);

export {
  loadOrganizationInsightDetailsAction,
};
