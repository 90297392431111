import { Route, Switch } from 'react-router-dom';

import Home from '~Home/components/Home';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import HomeHeader from '~Home/components/HomeHeader';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import { HOME_PAGE_HOME } from '~Common/const/routes';

const View = (): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[HOME_PAGE_HOME]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<HomeHeader />}
            ChildComponent={<Home />}
          />
        </BaseLayout>
      )}
    />
    <SmartRedirect from="*" to={HOME_PAGE_HOME} />
  </Switch>
);

const HomeRoutes = (): JSX.Element => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
    />
  );
};

export default HomeRoutes;
