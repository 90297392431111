import { css } from '@emotion/react';
import { produce } from 'immer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { debounce } from 'lodash';
import {
  useState,
  useCallback,
  ChangeEvent,
  DragEvent as ReactDragEvent,
  useEffect,
  useRef,
  MutableRefObject,
  useMemo,
  RefObject,
} from 'react';

import LeadrModal, { LeadrModalProps } from '~Common/V3/components/LeadrModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { useUploadFile } from '~Meetings/hooks/useUploadFile';
import { getLocalId } from '~Common/utils/uuid';
import ProgressBar from '~Meetings/components/details/agenda/AttachmentsModal/ProgressBar';
import Tooltip from '~Common/components/Tooltip';
import { Attachment } from '~Meetings/hooks/v2/useGetAgendas';
import { ConfirmAttachmentsRequest, useConfirmAttachments } from '~Meetings/hooks/attachments/useConfirmAttachments';
import { useRemoveAttachments } from '~Meetings/hooks/attachments/useRemoveAttachments';
import { toast } from '~Common/components/Toasts';
import { useShowAttachmentsModal } from '~Meetings/hooks/utils/useShowAttachmentsModal';
import { queryClient } from '~Common/const/queryClient';
import { meetingKeys } from '~Meetings/const/queryKeys';
import AttachmentError, { AttachmentErrorType } from './attachmentError';

const styles = {
  title: css({
    color: palette.brand.indigo,
  }),
  dropContainer: (isDraggingOver: boolean) => css({
    position: 'relative',
  }, isDraggingOver && {
    '& .dropOverlay': {
      display: 'flex',
    },
  }),
  dropOverlay: () => css({
    display: 'none',
    height: '100%',
    width: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    border: `0.125rem dashed ${palette.neutrals.gray400}`,
    backgroundColor: palette.neutrals.white,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '0.5rem',
  }),
  buttonContainer: css({
    display: 'flex',

    label: {
      margin: 0,
    },
  }),
  doneButton: css({
    marginRight: '0.5rem',
  }),
  uploadsContainer: css({
    marginBottom: '1rem',
  }),
  detailsContainer: css({
    display: 'flex',
    flexDirection: 'column',

    label: {
      margin: 0,
    },
  }),
  uploadIcon: css({
    fontSize: '1.5rem',
    color: palette.neutrals.gray700,
    marginBottom: '0.625rem',
  }),
  or: css({
    marginBottom: '1rem',
    fontSize: '1rem',
    color: palette.neutrals.gray800,
    fontWeight: 600,
  }),
  details: css({
    fontSize: '1rem',
    marginBottom: '1rem',
    textAlign: 'center',
  }),
  fileDropText: css({
    color: palette.neutrals.gray700,
    fontStyle: 'italic',
  }),
  progressBar: css({
    marginBottom: '0.25rem',
  }),
  fileTypes: css({
    fontSize: '0.75rem',
  }),
};

export const ALLOWED_FILE_EXTENSIONS = [
  '.png',
  '.gif',
  '.bmp',
  '.jpg',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.rtf',
  '.txt',
  '.mp3',
  '.wav',
  '.flac',
  '.mp4',
  '.mov',
  '.avi',
  '.wmv',
  '.mkv',
  '.webm',
  '.m4a',
];

export const MAX_ATTACHMENTS = 10;
export const MAX_FILE_SIZE = 20000000;
export const MAX_FILENAME_LENGTH = 255;

export enum UploadStatus {
  Initializing = 'INITIALIZING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Error = 'ERROR',
}

export interface Upload {
  uploadId: string,
  fileName: string,
  progress: number,
  abortController: AbortController,
  status: UploadStatus,
  attachmentId?: number,
}

export interface ViewProps extends Omit<LeadrModalProps, 'children' | 'handleModalDisplay' | 'onDragEnter' | 'onClose'> {
  open: boolean,
  uploads: Upload[],
  onFilesSelected: (e: ChangeEvent<HTMLInputElement>) => void,
  onRemoveUpload: (uploadId: string) => void,
  onDrop: (e: ReactDragEvent<HTMLDivElement>) => void,
  showMaxFilesError: boolean,
  fileSizeErrors: string[],
  fileNameErrors: string[],
  fileTypeErrors: string[],
  isDraggingOver: boolean,
  onDragEnter: () => void,
  onDragLeave: (e: ReactDragEvent<HTMLDivElement>) => void,
  dropContainerRef: MutableRefObject<HTMLDivElement | null>,
  onErrorClose: (errorType: AttachmentErrorType) => void,
  onClose: (_?: object, reason?: string) => void,
  onDoneClick: () => void,
  hasPendingUploads: boolean,
  canAddAttachments: boolean,
  fileInputRef: RefObject<HTMLInputElement>,
  showProgressBarSpinner: (attachmentId?: number) => boolean,
  setShowMaxFilesError: (value: boolean) => void,
}

const View = ({
  open,
  uploads,
  onFilesSelected,
  onRemoveUpload,
  onDrop,
  showMaxFilesError,
  fileSizeErrors,
  fileNameErrors,
  fileTypeErrors,
  isDraggingOver,
  onDragEnter,
  onDragLeave,
  dropContainerRef,
  onErrorClose,
  onClose,
  onDoneClick,
  hasPendingUploads,
  canAddAttachments,
  fileInputRef,
  showProgressBarSpinner,
  setShowMaxFilesError,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrModal
    open={open}
    onClose={onClose}
    {...props}
  >
    <LeadrModal.Header
      shouldConfirmClose={hasPendingUploads}
      confirmationQuestionText="Cancel unfinished uploads?"
      confirmationConfirmText="Yes"
      confirmationCancelText="No"
    >
      <LeadrModal.Title css={styles.title}>
        Attach File
      </LeadrModal.Title>
    </LeadrModal.Header>

    <LeadrModal.Body>
      <div
        css={styles.dropContainer(isDraggingOver)}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        ref={dropContainerRef}
      >
        <div
          css={styles.dropOverlay}
          className="dropOverlay"
        >
          <FontAwesomeIcon
            css={styles.uploadIcon}
            icon={faFileArrowUp}
          />

          <span css={styles.fileDropText}>
            Drop File
          </span>
        </div>

        <input
          ref={fileInputRef}
          id="fileSelectInput"
          type="file"
          onChange={onFilesSelected}
          accept={ALLOWED_FILE_EXTENSIONS.map((fileExtension) => (`${fileExtension},`)).join('')}
          disabled={!canAddAttachments}
          hidden
          multiple
        />

        {showMaxFilesError && (
          <AttachmentError
            errorType={AttachmentErrorType.MaxAttachments}
            onClose={onErrorClose}
          />
        )}

        {!!fileSizeErrors.length && (
          <AttachmentError
            errorType={AttachmentErrorType.MaxFileSize}
            fileNames={fileSizeErrors}
            onClose={onErrorClose}
          />
        )}

        {!!fileNameErrors.length && (
          <AttachmentError
            errorType={AttachmentErrorType.FileName}
            fileNames={fileNameErrors}
            onClose={onErrorClose}
          />
        )}

        {!!fileTypeErrors.length && (
          <AttachmentError
            errorType={AttachmentErrorType.FileType}
            fileNames={fileTypeErrors}
            onClose={onErrorClose}
          />
        )}

        {!uploads.length && (
          <div css={styles.detailsContainer}>
            <FontAwesomeIcon
              css={styles.uploadIcon}
              icon={faFileArrowUp}
            />

            <div css={styles.details}>
              <div>
                {/* Label needed here to allow opening the file picker on click */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label
                  htmlFor="fileSelectInput"
                >
                  <LeadrButton
                    component="div"
                    variant="text"
                    color="primary"
                    data-test-id="attachmentsModalSelectAttachments"
                  >
                    Select
                  </LeadrButton>
                </label>

                <span>
                  &nbsp;or drag and drop files here
                </span>
              </div>

              <div css={styles.fileTypes}>
                View acceptable&nbsp;
                <a
                  href="https://leadr.helpscoutdocs.com/article/90-attachments"
                  target="_blank"
                  rel="noreferrer"
                >
                  file types
                </a>
              </div>
            </div>

            <div>
              {/* Label needed here to allow opening the file picker on click */}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="fileSelectInput"
              >
                <LeadrButton
                  component="div"
                  color="primary"
                  size="small"
                  data-test-id="attachmentsModalSecondarySelectAttachments"
                >
                  Select
                </LeadrButton>
              </label>
            </div>
          </div>
        )}

        {!!uploads.length && (
          <div>
            <div css={styles.uploadsContainer}>
              {uploads.map((upload) => (
                <ProgressBar
                  css={styles.progressBar}
                  key={upload.uploadId}
                  upload={upload}
                  onRemove={onRemoveUpload}
                  showSpinner={showProgressBarSpinner(upload.attachmentId)}
                />
              ))}
            </div>

            <div css={styles.buttonContainer}>
              <Tooltip
                content={hasPendingUploads ? 'Upload in progress' : ''}
              >
                <div>
                  <LeadrButton
                    css={styles.doneButton}
                    size="small"
                    onClick={onDoneClick}
                    disabled={hasPendingUploads}
                    data-test-id="attachmentsModalDone"
                  >
                    Done
                  </LeadrButton>
                </div>
              </Tooltip>

              <Tooltip
                content="Maximum attachments reached"
                disabled={canAddAttachments}
              >
                {/* Label needed here to allow opening the file picker on click */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="fileSelectInput">
                  <LeadrButton
                    component="div"
                    variant="ghost"
                    size="small"
                    // Doing this so we can show the max files reached error component
                    onClick={() => {
                      if (!canAddAttachments) {
                        setShowMaxFilesError(true);
                      }
                    }}
                    data-test-id="attachmentsModalAddMoreAttachments"
                  >
                    Add More
                  </LeadrButton>
                </label>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </LeadrModal.Body>
  </LeadrModal>
);

interface AttachmentsModalProps {
  modalIdentifier?: string,
}

const AttachmentsModal = ({
  modalIdentifier,
  ...props
}: AttachmentsModalProps): JSX.Element => {
  const {
    closeModal,
    useIsModalOpen,
    useGetModalProps,
  } = useShowAttachmentsModal();
  const open = useIsModalOpen({ identifier: modalIdentifier });

  const {
    huddleId,
    topicId,
    attachments = [],
    addAttachment,
    removeAttachment,
  } = useGetModalProps({ identifier: modalIdentifier });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploads, setUploads] = useState<Upload[]>([]);

  const [attachmentsPendingDeletion, setAttachmentsPendingDeletion] = useState<number[]>([]);
  const [filesToAdd, setFilesToAdd] = useState<Attachment[]>([]);
  const [showMaxFilesError, setShowMaxFilesError] = useState(false);

  const { mutate: doRemoveAttachments, isPending: isMutationPending } = useRemoveAttachments({
    onMutate: ({ removeAttachmentIds }) => {
      const pastUploads: Upload[] = [...uploads];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setUploads(uploads.filter((upload) => !removeAttachmentIds.includes(upload.attachmentId!)));
      setShowMaxFilesError(false);
      return { pastUploads };
    },
    onError: (_, __, snapshot) => {
      toast.error('Failed to remove attachment. Please try again.');
      // @ts-expect-error Its there bro
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setUploads(snapshot?.pastUploads ?? []);
      const attachmentsLength = attachments.length;

      setShowMaxFilesError(attachmentsLength >= MAX_ATTACHMENTS);
    },
    onSuccess: (_, { removeAttachmentIds }) => {
      setAttachmentsPendingDeletion((currentAttachmentsPendingDeletion) => (
        currentAttachmentsPendingDeletion.filter((pendingAttachmentId) => !removeAttachmentIds.includes(pendingAttachmentId))
      ));

      const attachmentsToRemove = attachments.filter((currentAttachment) => removeAttachmentIds.includes(currentAttachment.id));
      attachmentsToRemove.forEach((attachment) => {
        removeAttachment?.(attachment);
      });
      setShowMaxFilesError(false);
      const queryKey = meetingKeys.agendaList(huddleId);

      void queryClient.refetchQueries({ queryKey });
    },
  });

  const [fileSizeErrors, setFileSizeErrors] = useState<string[]>([]);
  const [fileNameErrors, setFileNameErrors] = useState<string[]>([]);
  const [fileTypeErrors, setFileTypeErrors] = useState<string[]>([]);

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const dropContainerRef = useRef<HTMLDivElement>(null);

  const hasPendingUploads = useMemo(() => (
    uploads.some((upload) => (upload.status === UploadStatus.Initializing || upload.status === UploadStatus.InProgress))
  ), [
    uploads,
  ]);

  const cancelRemainingUploads = useCallback(() => {
    uploads.forEach((upload) => {
      if (upload.status === UploadStatus.Initializing || upload.status === UploadStatus.InProgress) {
        upload.abortController.abort();
      }
    });
  }, [
    uploads,
  ]);

  const onProgress = useCallback((e: ProgressEvent, uploadId: string) => {
    setUploads((currentUploads) => {
      const uploadIndex = currentUploads.findIndex((upload) => (upload.uploadId === uploadId));

      if (uploadIndex !== -1) {
        return produce(currentUploads, (draft) => {
          draft[uploadIndex].status = UploadStatus.InProgress;

          // Limit progress to 94% so the bar won't completely fill until we finish attachment confirmation if needed
          draft[uploadIndex].progress = Math.min(Math.floor((e.loaded / e.total) * 100), 94);
        });
      }

      return currentUploads;
    });
  }, []);

  const markUploadsAsComplete = useCallback((attachmentIds: number[]) => {
    setUploads((currentUploads) => (

      produce(currentUploads, (draft) => {
        draft.forEach((upload, index) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          if (attachmentIds.includes(upload.attachmentId!)) {
            draft[index].status = UploadStatus.Complete;
            draft[index].progress = 100;
          }
        });
      })
    ));
  }, []);

  const handleConfirmAttachmentsSuccess = useCallback((_: unknown, variables: ConfirmAttachmentsRequest) => {
    const { uploadedAttachmentIds } = variables;

    if (uploadedAttachmentIds?.length) {
      markUploadsAsComplete(uploadedAttachmentIds);
      // setAttachmentsPendingConfirmation((currentAttachmentsPendingConfirmation) => (
      //   currentAttachmentsPendingConfirmation.filter((pendingAttachmentId) => (!uploadedAttachmentIds.includes(pendingAttachmentId)))
      // ));
      filesToAdd.forEach((file) => {
        addAttachment(file);
      });
      setFilesToAdd([]);
    }
  }, [addAttachment, filesToAdd, markUploadsAsComplete]);

  const { mutate: confirmAttachments } = useConfirmAttachments({
    huddleId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    topicId: topicId!,
    onSuccess: handleConfirmAttachmentsSuccess,
  });

  const onLoadEnd = useCallback((uploadId: string, attachmentId: number, file: File) => {
    const extension = file.name.split('.').at(-1);

    setUploads((currentUploads) => {
      const uploadIndex = currentUploads.findIndex((upload) => (upload.uploadId === uploadId));

      if (uploadIndex !== -1) {
        return produce(currentUploads, (draft) => {
          draft[uploadIndex].attachmentId = attachmentId;
        });
      }

      return currentUploads;
    });

    if (topicId) {
      confirmAttachments({ uploadedAttachmentIds: [attachmentId] });
      setFilesToAdd((currentFilesToAdd) => [...currentFilesToAdd, {
        id: attachmentId,
        fileName: file.name,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fileExtension: extension!,
        fileSize: file.size,
      }]);
    } else {
      markUploadsAsComplete([attachmentId]);
      addAttachment({
        id: attachmentId,
        fileName: file.name,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        fileExtension: extension!,
        fileSize: file.size,
      });
    }
  }, [topicId, confirmAttachments, markUploadsAsComplete, addAttachment]);

  const onRemoveUpload = useCallback((uploadId: string) => {
    const uploadIndex = uploads.findIndex((upload) => (upload.uploadId === uploadId));
    if (uploadIndex !== -1) {
      if (uploads[uploadIndex].status === UploadStatus.Complete) {
        if (topicId) {
          setAttachmentsPendingDeletion((currentAttachmentsPendingDeletion) => (
            produce(currentAttachmentsPendingDeletion, (draft) => {
              // @ts-expect-error attachmentId will exist if status is complete
              draft.push(uploads[uploadIndex].attachmentId);
            })
          ));
          // Since we've removed one - we can not possibly have max files
          setShowMaxFilesError(false);
        } else {
          // @ts-expect-error attachmentId will exist if status is complete
          removeAttachment(uploads[uploadIndex].attachmentId);
          setUploads(produce(uploads, (draft) => {
            draft.splice(uploadIndex, 1);
          }));
        }
      } else {
        uploads[uploadIndex].abortController.abort();
        setUploads(produce(uploads, (draft) => {
          draft.splice(uploadIndex, 1);
        }));
      }
    }
  }, [uploads, topicId, removeAttachment]);

  const debouncedDoRemoveAttachments = debounce(doRemoveAttachments, 2000);

  const onErrorCallback = (uploadId: string): void => {
    setUploads((currentUploads) => {
      const uploadIndex = currentUploads.findIndex((upload) => (upload.uploadId === uploadId));

      if (uploadIndex !== -1) {
        return produce(currentUploads, (draft) => {
          draft[uploadIndex].status = UploadStatus.Error;
        });
      }

      return currentUploads;
    });
  };

  const { mutate: uploadFile } = useUploadFile({
    huddleId,
    onProgress,
    onLoadEnd,
    onErrorCallback,
  });

  const totalActiveFiles = useMemo(() => {
    const attachmentIds = attachments.map((attachment) => (attachment.id));

    // @ts-expect-error This code works fine if attachmentId is undefined
    return attachments.length + uploads.filter((upload) => (!attachmentIds.includes(upload.attachmentId))).length;
  }, [
    attachments,
    uploads,
  ]);

  const canAddAttachments = totalActiveFiles < MAX_ATTACHMENTS;

  const validateFiles = useCallback((files: File[]) => {
    const newFileNameErrors: string[] = [];
    const newFileSizeErrors: string[] = [];
    const newFileTypeErrors: string[] = [];

    let newShowMaxFilesError = false;
    let valid = true;

    files.forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        newFileSizeErrors.push(file.name);
        valid = false;
      }

      if (file.name.length > MAX_FILENAME_LENGTH) {
        newFileNameErrors.push(file.name);
        valid = false;
      }

      if (!ALLOWED_FILE_EXTENSIONS.some((fileExtension) => (file.name.toLowerCase().endsWith(fileExtension)))) {
        newFileTypeErrors.push(file.name);
        valid = false;
      }
    });

    if (totalActiveFiles + files.length > MAX_ATTACHMENTS) {
      newShowMaxFilesError = true;
      valid = false;
    }

    setShowMaxFilesError(newShowMaxFilesError);
    setFileNameErrors(newFileNameErrors);
    setFileSizeErrors(newFileSizeErrors);
    setFileTypeErrors(newFileTypeErrors);

    return valid;
  }, [
    totalActiveFiles,
  ]);

  const uploadFiles = useCallback((files: File[]) => {
    const valid = validateFiles(files);
    if (valid) {
      const newUploads: Upload[] = files.map((file) => {
        const abortController = new AbortController();

        return {
          uploadId: getLocalId(),
          fileName: file.name,
          progress: 0,
          status: UploadStatus.Initializing,
          abortController,
        };
      });

      setUploads((currentUploads) => ([
        ...currentUploads,
        ...newUploads,
      ]));

      files.forEach((file, index) => {
        uploadFile({
          file,
          uploadId: newUploads[index].uploadId,
          abortSignal: newUploads[index].abortController.signal,
        });
      });
    }
  }, [
    validateFiles,
    uploadFile,
  ]);

  const onDrop = useCallback((e: ReactDragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);

    if (e.dataTransfer.files) {
      uploadFiles(Array.from(e.dataTransfer.files));
    }
  }, [
    uploadFiles,
  ]);

  const onFilesSelected = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      uploadFiles(Array.from(e.target.files));

      // @ts-expect-error If the event has files current will exist
      fileInputRef.current.value = '';
    }
  }, [
    uploadFiles,
  ]);

  const onDragEnter = useCallback(() => {
    setIsDraggingOver(true);
  }, []);

  const onDragLeave = useCallback((e: ReactDragEvent<HTMLDivElement>) => {
    // @ts-expect-error TS is wrong here. This code works just
    if (!dropContainerRef.current?.contains(e.relatedTarget)) {
      setIsDraggingOver(false);
    }
  }, []);

  const handleWindowDrop = useCallback((e: DragEvent) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    window.addEventListener('dragover', handleWindowDrop);

    return () => {
      window.removeEventListener('dragover', handleWindowDrop);
    };
  }, [
    handleWindowDrop,
  ]);

  const onErrorClose = useCallback((errorType: AttachmentErrorType) => {
    switch (errorType) {
      case AttachmentErrorType.MaxAttachments:
        setShowMaxFilesError(false);
        break;
      case AttachmentErrorType.FileName:
        setFileNameErrors([]);
        break;
      case AttachmentErrorType.FileType:
        setFileTypeErrors([]);
        break;
      case AttachmentErrorType.MaxFileSize:
        setFileSizeErrors([]);
        break;
      default:
        break;
    }
  }, []);

  const onClose = useCallback((_?: object, reason?: string) => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      if (hasPendingUploads) {
        cancelRemainingUploads();
      }

      closeModal({ identifier: modalIdentifier });
    }
  }, [hasPendingUploads, closeModal, modalIdentifier, cancelRemainingUploads]);

  const onDoneClick = useCallback(() => {
    onClose();
  }, [
    onClose,
  ]);

  useEffect(() => {
    if (open) {
      setShowMaxFilesError(false);
      setFileSizeErrors([]);
      setFileNameErrors([]);
      setFileTypeErrors([]);
      setUploads([]);
    }
  }, [
    open,
  ]);

  useEffect(() => {
    if (!isMutationPending && attachmentsPendingDeletion.length) {
      debouncedDoRemoveAttachments({
        huddleId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        topicId: topicId!,
        removeAttachmentIds: attachmentsPendingDeletion,
      });
    } else {
      debouncedDoRemoveAttachments.cancel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsPendingDeletion, huddleId, isMutationPending, topicId]);

  const showProgressBarSpinner = useCallback((attachmentId?: number) => {
    if (attachmentId) {
      return attachmentsPendingDeletion.includes(attachmentId);
    }

    return false;
  }, [attachmentsPendingDeletion]);

  const hookProps = {
    open,
    uploads,
    onFilesSelected,
    onRemoveUpload,
    onDrop,
    showMaxFilesError,
    fileSizeErrors,
    fileNameErrors,
    fileTypeErrors,
    isDraggingOver,
    onDragEnter,
    onDragLeave,
    dropContainerRef,
    onErrorClose,
    onClose,
    onDoneClick,
    hasPendingUploads,
    canAddAttachments,
    fileInputRef,
    showProgressBarSpinner,
    setShowMaxFilesError,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default AttachmentsModal;
