import { css } from '@emotion/react';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';
import { NavigationHeader } from '~Root/components/OldNavigationSidebar/NavigationHeader';
import { NavigationItems } from '~Root/components/OldNavigationSidebar/NavigationItems';
import { ProfileCollapse } from '~Root/components/OldNavigationSidebar/ProfileCollapse';
import { useNavigationStore } from '~Root/components/OldNavigationSidebar/useNavigationStore';

const collapsedSidebarWidth = '4.75rem';
const expandedSidebarWidth = '13.75rem';

const styles = {
  collapseIcon: (isCollapsed: boolean) => css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.white,
    border: `1px solid ${palette.neutrals.gray500}`,
    borderRadius: '100%',
    color: palette.neutrals.gray500,
    display: 'flex',
    height: '1.375rem',
    justifyContent: 'center',
    fontSize: '0.75rem',
    fontWeight: 600,
    left: `calc(${isCollapsed ? collapsedSidebarWidth : expandedSidebarWidth} - 1rem)`,
    padding: 0,
    position: 'absolute',
    rotate: '0deg',
    top: '2.825rem',
    transition: 'rotate 100ms ease',
    zIndex: 1150,
    width: '1.375rem',

    '&.collapse-icon': {
      opacity: 0,
      transition: 'opacity 150ms ease',

      '@media (pointer:coarse)': {
        opacity: 100,
      },
    },
  }, isCollapsed && {
    rotate: '180deg',
  }),
  container: (isCollapsed: boolean, isMobile: boolean) => css({
    backgroundColor: palette.neutrals.white,
    borderRight: `1px solid ${palette.neutrals.gray200}`,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: '100%',
    maxHeight: '100vh',
    overflow: 'clip',
    width: isCollapsed ? collapsedSidebarWidth : expandedSidebarWidth,

    ':hover': {
      '& .collapse-icon': {
        opacity: 100,
      },
    },
  }, isMobile && {
    width: '100%',
    maxWidth: '100%',
  }),
};

interface ViewProps {
  allowCollapse: boolean,
  isCollapsed: boolean,
  isMobile: boolean,
  toggleCollapsed: () => void,
}

const View = ({
  allowCollapse,
  isCollapsed,
  isMobile,
  toggleCollapsed,
}: ViewProps): JSX.Element => (
  <>
    <nav
      css={styles.container(isCollapsed, isMobile)}
    >
      <NavigationHeader
        isCollapsed={isCollapsed}
        isMobile={isMobile}
      />
      <NavigationItems
        isCollapsed={isCollapsed}
      />
      <ProfileCollapse
        isCollapsed={isCollapsed}
      />

      {allowCollapse && (
        <button
          className="collapse-icon"
          css={styles.collapseIcon(isCollapsed)}
          onClick={toggleCollapsed}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
          />
        </button>
      )}
    </nav>
  </>
);

interface NavigationSidebarProps {
  allowCollapse?: boolean,
  isMobile?: boolean,
}

export const OldNavigationSidebar = ({
  allowCollapse = true,
  isMobile = false,
}: NavigationSidebarProps): JSX.Element => {
  const isCollapsed = useNavigationStore((state) => state.isNavigationCollapsed);
  const toggleCollapsed = useNavigationStore((state) => state.toggleCollapsed);

  const hookProps = {
    allowCollapse,
    isCollapsed: isCollapsed && allowCollapse,
    isMobile,
    toggleCollapsed,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
