import { css } from '@emotion/react';
import { Link, LinkProps } from 'react-router-dom';
import {
  buttonVariant,
  buttonSize,
  buttonColor,
  buildButtonStyles,
} from '~Common/V3/styles/Button';

interface LinkButtonProps extends LinkProps {
  renderContents: () => JSX.Element,
  variant?: buttonVariant,
  size?: buttonSize,
  color?: buttonColor,
}

const styles = {
  linkButton: css({
    ':hover': {
      textDecoration: 'none',
    },
  }),
};

const LinkButton = ({
  renderContents, variant, size, color, ...props
}: LinkButtonProps): JSX.Element => (
  <Link css={[styles.linkButton, buildButtonStyles({ color, size, variant })]} {...props}>
    {renderContents()}
  </Link>
);

export default LinkButton;
