import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { queryClient } from '~Common/const/queryClient';
import { deleteApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { navigateAction } from '~Deprecated/actions/navigate';
import { COACHINGS_BASE_ROUTE, COACHING_LIST_PAST } from '~Common/const/routes';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { homeQueryKeys } from '~Home/hooks/queryKeys';

const endMeeting = ({ factoryId }) => {
  const serverUrl = `/organizations/${getOrganizationId()}/huddles/series/${factoryId}`;

  return deleteApi(serverUrl, {}, {});
};

export const useEndMeeting = ({ template, frequency }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const mutateText = frequency === 'ONE_TIME' ? 'Canceling this meeting...' : 'Ending all future instances of this meeting...';
  const recurringSuccessText = 'Past instances of this meeting and their notes have been archived, future recurrences have been deleted';
  const oneTimeSuccessText = 'This meeting has been successfully canceled';
  const successText = frequency === 'ONE_TIME' ? oneTimeSuccessText : recurringSuccessText;
  const mutation = useMutation({
    mutationFn: endMeeting,
    onMutate: () => {
      toastId.current = toast.info(`${mutateText}`, { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: <MeetingsErrorToast />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables) => {
      toast.update(toastId.current, {
        render: successText,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const {
        id,
      } = variables;

      dispatch(
        popDrawerAction({ drawerName: template.name }),
      );

      const organizationId = getOrganizationId();

      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', id] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'archived'] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'suggestions'] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'user-search'] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'timeline'] });
      queryClient.invalidateQueries(homeQueryKeys.upcomingWeekLists(getOrganizationId()));

      if (frequency !== 'ONE_TIME') {
        dispatch(
          navigateAction({
            pathname: COACHING_LIST_PAST,
          }),
        );
      } else {
        dispatch(
          navigateAction({
            pathname: COACHINGS_BASE_ROUTE,
          }),
        );
      }
    },
  });

  return mutation.mutate;
};
