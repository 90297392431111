import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';

interface TitleAndIntroductionProps extends Omit<LeadrButtonProps<'button'>, 'onClick' | 'variant' | 'data-test-id'> {
  onEdit: () => void,
}

const EditDueDateButton = ({
  onEdit,
  ...props
}: TitleAndIntroductionProps): JSX.Element => (
  <LeadrButton
    onClick={onEdit}
    textButtonColor={palette.brand.indigo}
    variant="text"
    data-test-id="learningEditDueDate"
    {...props}
  >
    <Tooltip content="Edit Due Date">
      <div>
        <FontAwesomeIcon icon={faEdit} />
      </div>
    </Tooltip>
  </LeadrButton>
);

export default EditDueDateButton;
