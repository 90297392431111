import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getDetailAndNormalize } from '~Common/functions/api/getDetailAndNormalize';
import { getListAndFlatten } from '~Common/functions/api/getListAndFlatten';

import { DEFAULT_PAGE_SIZE, memoFlattener } from '~Common/hooks/utils';
import { buildQueryString } from '~Common/utils';
import { getOrganizationId, getOrganizationUserId, getUserId } from '~Common/utils/localStorage';

/**
 * @param {object} paramInterface - An object containing params for this function call.
 * @param {function} paramInterface.buildApi
 * @param {function} paramInterface.buildQueryKey
 * @param {object} paramInterface.fetchParams
 * @param {object} paramInterface.headers
 * @param {object} paramInterface.queryOptions
 * @param {function} paramInterface.listFlattener
 * @param {function} paramInterface.infiniteFlattener
 * @param {function} paramInterface.normalizeItemFromServer
 *
 * @param buildApi - A function that takes in currentUserOrgId, currentUserUserId, currentUserOrgUserId, as well as fetchParams, and returns a string that should be the API endpoint you want to hit.
 * @param buildQueryKey - Same as buildApi, but this should return an array describing a queryKey. Note that fetchParams, item ids, etc will be appended onto this as needed and do not need to be part of this array.
 * @param fetchParams - An object describing query params that will be used with list calls. This is only used in list functions.
 * @param headers - An object describing headers to add to the API call.
 * @param queryOptions - Test5
 * @param listFlattener - Test6
 * @param infiniteFlattener - Test7
 * @param normalizeItemFromServer - Test8
 *
 * @returns {object} More stuff
 */
const useQueryConfig = ({
  buildApi,
  buildQueryKey,
  fetchParams,
  headers = {
    'endpoint-version': '2',
  },
  queryOptions,
  listFlattener,
  infiniteFlattener,
  normalizeItemFromServer,
  ...args
}) => {
  const currentUserOrgId = getOrganizationId();
  const currentUserUserId = getUserId();
  const currentUserOrgUserId = getOrganizationUserId();

  const buildParams = {
    currentUserOrgId, currentUserUserId, currentUserOrgUserId, ...fetchParams,
  };

  const baseUrl = buildApi(buildParams);
  const queryKey = buildQueryKey(buildParams);

  const getListForQuery = ({ queryKey: fullQueryKey, pageParam }) => {
    // These current variables don't get used, they are more for targeting invalidations
    const apiParams = fullQueryKey[fullQueryKey.length - 1];

    let queryParams;

    // Legacy API Support
    if (fetchParams.page || fetchParams.count) {
      queryParams = {
        page: fetchParams.page,
        count: fetchParams.count,
        ...apiParams,
      };
    } else {
      queryParams = {
        skip: pageParam || 0,
        take: DEFAULT_PAGE_SIZE,
        ...apiParams,
      };
    }

    const queryData = buildQueryString(queryParams);

    return getListAndFlatten({
      url: `${baseUrl}${queryData}`, headers, listFlattener, normalizer: normalizeItemFromServer,
    });
  };

  const useQueryList = (queryOverrides = {}) => {
    const infiniteList = useInfiniteQuery({
      queryKey: [...queryKey, 'list', fetchParams],
      initialPageParam: 0,
      queryFn: getListForQuery,
      ...queryOptions,
      ...queryOverrides,
    });

    const { list, listData } = infiniteFlattener ? infiniteFlattener(infiniteList) : memoFlattener(infiniteList);

    return {
      ...infiniteList,
      list,
      listData,
    };
  };

  const getDetailForQuery = ({ queryKey: fullQueryKey }) => {
    const id = fullQueryKey[fullQueryKey.length - 1];

    return getDetailAndNormalize({
      url: `${baseUrl}/${id}`, headers, normalizer: normalizeItemFromServer,
    });
  };

  const useQueryDetail = (id, queryOverrides = {}) => useQuery({
    queryKey: [...queryKey, id],
    queryFn: getDetailForQuery,
    ...queryOptions,
    ...queryOverrides,
  });

  return {
    useQueryList,
    useQueryDetail,
    queryKey,
    ...args,
  };
};

export default useQueryConfig;
