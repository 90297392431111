import {
  GetAgendaSectionTemplateDetails,
  GetCuratedAgendaSectionTemplateDetails,
  BulkAddAgendaTemplateDetails,
  GetTemplateTopic,
} from '~Meetings/components/topic-suggestions/const/types';

type AgendaSection = GetAgendaSectionTemplateDetails | GetCuratedAgendaSectionTemplateDetails;

type Topics = GetTemplateTopic[];

function isAgendaSectionTopic(topic: GetTemplateTopic): topic is AgendaSection {
  return (topic as AgendaSection).children !== undefined;
}

export const getFormattedTopicsForBulkAdd = (topics: Topics): BulkAddAgendaTemplateDetails[] => topics.map((topic) => {
  if (isAgendaSectionTopic(topic)) {
    return {
      id: topic.id,
      type: topic.type,
      children: topic.children.map((child) => ({
        id: child.id,
        type: child.type,
      })),
    };
  }

  return {
    id: topic.id,
    type: topic.type,
  };
});
