import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';

const styles = {
  header: css({
    paddingBottom: '1.25rem',
    borderBottom: `.0625rem solid ${palette.neutrals.gray200}`,
    marginBottom: '.875rem',
  }),
  body: css({}),
  footer: css({
    paddingTop: '1.25rem',
    borderTop: `.0625rem solid ${palette.neutrals.gray200}`,
    marginTop: '.875rem',
  }),
};

export interface LearningSectionsCardProps {
  renderHeader?: () => JSX.Element,
  renderBody?: () => JSX.Element,
  renderFooter?: () => JSX.Element,
}

const LearningSectionsCard = ({
  renderHeader,
  renderBody,
  renderFooter,
  ...props
}: LearningSectionsCardProps): JSX.Element => (
  <Card
    data-test-id="learningSectionsCard"
    renderContents={() => (
      <div>
        {renderHeader && (
          <div css={styles.header} data-test-id="header">
            {renderHeader?.()}
          </div>
        )}
        {renderBody && (
          <div css={styles.body} data-test-id="body">
            {renderBody?.()}
          </div>
        )}
        {renderFooter && (
          <div css={styles.footer} data-test-id="footer">
            {renderFooter?.()}
          </div>
        )}
      </div>
    )}
    {...props}
  />
);

export default LearningSectionsCard;
