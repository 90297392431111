import moment from 'moment';
import ClampLines from '~Common/components/ClampLines';
import Card from '~Common/V3/components/Card';
import { usePrintView } from '~Common/stores/usePrintView';
import { PAGE_STYLES, REVIEW_DISPLAY_STYLES, CENTER_CONTENTS } from '../Const/pageStyles';
import { CycleTypeEnum, PersonDisplayInformationReviews, ReviewShape } from '../Const/types';
import PersonDisplayAvatarInformation from './PersonDisplayAvatarInformation';

const styles = {
  ...PAGE_STYLES,
  ...REVIEW_DISPLAY_STYLES,
  ...CENTER_CONTENTS,
};

interface ViewProps {
  review: ReviewShape,
  reviewee: PersonDisplayInformationReviews | Record<string, never> | undefined,
  reviewer: PersonDisplayInformationReviews | Record<string, never> | undefined,
  secondaryReviewer: PersonDisplayInformationReviews | Record<string, never> | undefined,
  assessmentDueDate: string,
  signOffDueDate: string,
  assessmentPeriodStart: string,
  assessmentPeriodEnd: string,
  typeEnum: number,
  isPrintView: boolean,
}

const View = ({
  review,
  reviewee,
  reviewer,
  secondaryReviewer,
  assessmentDueDate,
  signOffDueDate,
  assessmentPeriodStart,
  assessmentPeriodEnd,
  typeEnum,
  isPrintView,
}: ViewProps): JSX.Element => (
  <Card
    css={isPrintView ? [styles.baseCard(false), styles.fullWidth] : [styles.baseCard(false), styles.centeredContent]}
    renderContents={() => (
      <>
        <div
          css={styles.reviewDetailsWrap}
        >
          <div>
            <div
              css={styles.ReviewTitle}
            >
              {review?.reviewCycle?.name}
            </div>
            <ClampLines
              text={review?.reviewCycle?.description}
              id="really-unique-id"
              lines={4}
              ellipsis="..."
              moreText="Read More"
              lessText="Show Less"
              emotionStyle={styles.reviewDescription}
            />
          </div>
          <div>
            {reviewee && (
            <PersonDisplayAvatarInformation
              personToDisplay={reviewee as PersonDisplayInformationReviews}
              showCondensed
            />
            )}
            {reviewer && (
            <PersonDisplayAvatarInformation
              personToDisplay={reviewer as PersonDisplayInformationReviews}
              showCondensed
            />
            )}
            {secondaryReviewer && (
            <PersonDisplayAvatarInformation
              personToDisplay={secondaryReviewer as PersonDisplayInformationReviews}
              showCondensed
            />
            )}
          </div>
        </div>
        <div css={styles.reviewDetails}>
          <div>
            <span css={styles.cycleDetailsTitle}>Review Period</span>
            <span>
              {assessmentPeriodStart}
              {' - '}
              {assessmentPeriodEnd}
            </span>
          </div>
          <div>
            <span css={styles.cycleDetailsTitle}>Assessment Due Date</span>
            <span>{assessmentDueDate}</span>
          </div>
          <div>
            <span css={styles.cycleDetailsTitle}>Review Sign-off Due Date</span>
            <span>{signOffDueDate}</span>
          </div>
          <div>
            <span css={styles.cycleDetailsTitle}>Cycle Type</span>
            <span>{Object.keys(CycleTypeEnum)[Object.values(CycleTypeEnum).indexOf(typeEnum)]}</span>
          </div>
        </div>
      </>
    )}
  />
);

interface ReviewHeaderProps {
  review: ReviewShape,
  reviewee?: PersonDisplayInformationReviews | Record<string, never> | undefined,
  reviewer?: PersonDisplayInformationReviews | Record<string, never> | undefined,
  secondaryReviewer?: PersonDisplayInformationReviews | Record<string, never> | undefined,
}

const ReviewHeader = ({
  review,
  reviewee,
  reviewer,
  secondaryReviewer,
}: ReviewHeaderProps): JSX.Element => {
  const isPrintView = usePrintView((state) => state.isPrintView);

  const assessmentPeriodStart = review?.reviewCycle?.assessmentPeriodStart
    ? moment(review?.reviewCycle?.assessmentPeriodStart).format('MMM Do')
    : 'No Due Date';
  const assessmentPeriodEnd = review?.reviewCycle?.assessmentPeriodEnd
    ? moment(review?.reviewCycle?.assessmentPeriodEnd).format('MMM Do YYYY')
    : 'No Due Date';
  const assessmentDueDate = review?.reviewCycle?.assessmentDueDate
    ? moment(review?.reviewCycle?.assessmentDueDate).format('MMM Do YYYY')
    : 'No Due Date';
  const signOffDueDate = review?.reviewCycle?.signOffDueDate
    ? moment(review?.reviewCycle?.signOffDueDate).format('MMM Do YYYY')
    : 'No Due Date';

  const typeEnum = review?.reviewCycle?.typeEnum;

  const hookProps = {
    review,
    reviewee,
    reviewer,
    secondaryReviewer,
    assessmentDueDate,
    signOffDueDate,
    assessmentPeriodStart,
    assessmentPeriodEnd,
    typeEnum,
    isPrintView,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default ReviewHeader;
