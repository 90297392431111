import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { NormalizedCreatedSurvey } from '~Surveys/const/Survey';
import GridSurveyCard from '../GridSurveyCard';

const styles = {
  gridSurveyCard: css({
    width: '100%',
  }),
  gridSurveyCardSkeleton: css({
    minWidth: '100%',
    borderRadius: '0.5rem',
    height: '8.75rem',
    boxShadow: '0px 4px 8px -2px rgba(28, 42, 55, 0.2)',
  }),
  numberCompletedText: css({
    color: palette.neutrals.gray600,
    fontSize: '0.75rem',
  }),
};

const GridCreatedSurveyCardSkeletonStyles = styles.gridSurveyCardSkeleton;

interface ViewProps {
  survey: NormalizedCreatedSurvey,
  onSurveyClick: () => void,
  numberOfCompletedSurveys: number,
}

const View = ({
  survey,
  onSurveyClick,
  numberOfCompletedSurveys,
}: ViewProps): JSX.Element => (
  <GridSurveyCard
    css={styles.gridSurveyCard}
    title={survey.title}
    description={survey.objective}
    isComplete={!!survey.completedOn}
    onCardClick={onSurveyClick}
    dueDate={survey.completeBy}
    numberOfUsersAssigned={survey.participants.length}
    assignedUsersInfo={survey.participants}
    renderRightSection={() => (
      <div css={styles.numberCompletedText}>{`${numberOfCompletedSurveys} of ${survey.participants.length} completed`}</div>
    )}
    status={survey.status.value}
  />
);

interface GridCreatedSurveyCardProps extends Omit<ViewProps, 'numberOfCompletedSurveys'> {
  survey: NormalizedCreatedSurvey,
}

const GridCreatedSurveyCard = ({
  survey,
  ...props
}: GridCreatedSurveyCardProps): JSX.Element => {
  const numberOfCompletedSurveys = survey.surveyParticipants.filter((person) => person.completedOn).length;

  const hookProps = {
    survey,
    numberOfCompletedSurveys,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export { GridCreatedSurveyCardSkeletonStyles };

export default GridCreatedSurveyCard;
