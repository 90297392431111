// It is necessary for this to be a class component.
import { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import LeadrLogoIndigo from '~Assets/images/leadrLogoIndigo.svg';
import Button from '~Common/V3/components/Button';

const styles = {
  backdrop: css({
    backgroundColor: palette.neutrals.gray50,
    height: '100vh',
    width: '100%',
  }),
  button: css({
    color: palette.brand.blue,
    fontWeight: 800,
    textDecoration: 'underline',
  }),
  container: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    justifyContent: 'center',
    maxWidth: '40%',
    minWidth: '25rem',
    textAlign: 'center',
    margin: 'auto auto',
    height: '100vh',

    h1: {
      fontSize: '5rem',
      color: palette.brand.orange,
      fontWeight: 800,
    },
    h2: {
      fontSize: '3rem',
      color: palette.brand.indigo,
    },
  }),
  logo: css({
    height: '7rem',
    marginBottom: '1.5rem',
  }),
  reference: css({
    marginTop: '1rem',
    fontSize: '0.75rem',
  }),
};

// This one can't be converted to use the hook abstraction for obvious reasons.
function handleClick() {
  const beacon = window?.Beacon;
  if (!beacon) return;

  beacon('prefill', {
    text: 'I just encountered an error!',
  });
  beacon('navigate', '/ask/chat/');
  beacon('open');
}
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div css={styles.backdrop}>
          <div css={styles.container}>
            <LeadrLogoIndigo css={styles.logo} title="Leadr logo" data-test-id="leadrLogoIndigo" />
            <h3>Sorry, there&apos;s been an error.</h3>
            <p>
              We try to minimize errors in our platform, but they do happen sometimes. If this issue persists,
              <Button
                variant="text"
                css={styles.button}
                onClick={handleClick}
                renderContents={() => <>click here</>}
              />
              to connect with our Customer Support team.
            </p>
            {/* Hiding this until the logging service is ready.
            <p css={styles.reference}>
              Log reference:&nbsp;
              <Button
                variant="text"
                css={styles.button}
                onClick={handleClick}
                renderContents={() => <>{errorReference}</>}
              />
            </p> */}
          </div>
        </div>
      );
    }

    return <>{children}</>;
  }
}

ErrorBoundary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};
