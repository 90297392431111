import { put, call, all } from 'redux-saga/effects';
import * as types from '../actions';
import {
  sendResponseToFeedbackQuestionsService,
} from '~Deprecated/services/feedbackService';
import { hasError, GlobalAPIErrorHandler } from './utils';

export function* sendResponseToFeedbackQuestions(payload) {
  try {
    const sendResponseToFeedbackQuestion = payload.payload
      .map((item) => call(sendResponseToFeedbackQuestionsService, item));
    const result = yield all(sendResponseToFeedbackQuestion);
    // errror handling for parallel calls
    if (result && result.length > 0) {
      const resultData = result.map((errorObj) => hasError(errorObj));
      yield all(resultData);
    }
  } catch (error) {
    error.success = false;
    if (error) {
      yield call(GlobalAPIErrorHandler, error.message);
    } else {
      yield call(GlobalAPIErrorHandler, '');
    }
    yield put({ type: types.SEND_RESPONSE_TO_QUESTIONS_ERROR, response: error });
  }
}
