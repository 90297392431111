import { useInsightPrivileges } from '~Insights/hooks/useInsightPrivileges';
import { UserPerspective, useInsightsUserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { SyntheticEvent } from 'react';
import { SerializedStyles } from '@emotion/react';

interface ViewProps {
  userPerspective: string,
  onChange: (event: SyntheticEvent, newValue: UserPerspective) => void
  tabItems: LeadrToggleTextTabProps[],
  toggleTabStyles: SerializedStyles,
}

const View = ({
  tabItems,
  userPerspective,
  toggleTabStyles,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs value={userPerspective} {...props}>
    {tabItems.map((tabItem) => (
      <LeadrToggleTabs.TextTab
        css={toggleTabStyles}
        data-test-id={tabItem['data-test-id']}
        key={tabItem.value}
        text={tabItem.text}
        value={tabItem.value}
      />
    ))}
  </LeadrToggleTabs>
);

type ManagerToggleProps = Omit<ViewProps, 'userPerspective' | 'onChange' | 'tabItems'>;

const ManagerToggle = ({ ...props }: ManagerToggleProps): JSX.Element => {
  const { isAdmin, isExecutive, hasDirectReports } = useInsightPrivileges();

  const tabItems = [
    {
      text: 'Executive',
      value: UserPerspective.executive,
      'data-test-id': 'insightsExecutive',
    },
    ...(hasDirectReports ? [{
      text: 'Manager',
      value: UserPerspective.manager,
      'data-test-id': 'insightsManager',
    }] : []),
  ];

  const { userPerspective, setUserPerspective } = useInsightsUserPerspective();
  if (!isExecutive && !isAdmin) return <></>;

  const onChange = (event: SyntheticEvent, newValue: UserPerspective): void => {
    if (newValue) {
      setUserPerspective(newValue);
    }
  };

  const hookProps = {
    tabItems,
    onChange,
    userPerspective,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ManagerToggle;
