import { create } from 'zustand';

interface HidePrivateAgendaStoreState {
  isHidden: boolean,
  setIsHidden: (isHidden: boolean) => void,
}

export const useHidePrivateAgenda = create<HidePrivateAgendaStoreState>((set) => ({
  isHidden: false,
  setIsHidden: (isHidden: boolean) => set(() => ({ isHidden })),
}));
