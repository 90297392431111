import { all, call, put } from 'redux-saga/effects';
import * as types from '../actions';
import {
  sendResponseToContentQuestionDataService,
} from '~Deprecated/services/contentService';
import { GlobalAPIErrorHandler, hasError, setLoader } from './utils';
import { isLoadingAction } from '~Deprecated/actions/loading/isLoading';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { clearDraft } from '~Deprecated/utils/dataManagement';
import { queryClient } from '~Common/const/queryClient';

export function* sendResponseData({ payload }) {
  try {
    yield put(isLoadingAction(true));
    yield call(setLoader, 'isRepliesInProcess');
    const sendResponseToContentQuestion = payload.answers.map((item) => call(sendResponseToContentQuestionDataService, item));
    const result = yield all(sendResponseToContentQuestion);
    // error handling for parallel calls
    if (result && result.length > 0) {
      const resultData = result.map((errorObj) => hasError(errorObj));
      (payload.questionIds || []).map((questionId) => {
        clearDraft(`draft.${getUserId()}.learning.${payload.learningId}.${questionId}`);
      });
      yield all(resultData);
      yield call(setLoader, 'isRepliesInProcess', 'error');
    }
  } catch (error) {
    error.success = false;
    if (error) {
      yield call(GlobalAPIErrorHandler, error.messageKey);
    } else {
      yield call(GlobalAPIErrorHandler, '');
    }
    yield put({
      type: types.SEND_RESPONSE_CONTENT_FOR_ME_LIST_ERROR,
      response: error,
    });
    yield call(setLoader, 'isRepliesInProcess', 'error');
  } finally {
    yield put(isLoadingAction(false));
    yield call(setLoader, 'isRepliesInProcess', false);
    queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'user', 'pending', 'items'] });
  }
}
