import { useCallback } from 'react';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { SelectChangeEvent } from '@mui/material';
import { ReflectionsItem } from '../../types';
import { DROPDOWN_STYLES } from '../../const/pageStyles';

const styles = {
  ...DROPDOWN_STYLES,
};

interface ViewProps {
  onChange: (event: SelectChangeEvent<string[]>) => void;
  items: DropdownItem<string[] | ReflectionsItem[]>[];
  value: string[];
  renderValue: () => JSX.Element;
}

const View = ({
  onChange,
  items,
  value,
  renderValue,
  ...props
}: ViewProps): JSX.Element => (
  <Dropdown
    displayEmpty
    css={styles.dropdown}
    value={value}
    renderItem={(item: DropdownItem<string[]>) => (
      <div css={styles.dropdownItemBoy}>
        { item.text }
      </div>
    )}
    onChange={onChange}
    items={items as DropdownItem<string[]>[]}
    renderValue={renderValue}
    {...props}
  />
);

interface ReflectionsDirectReportsFilterProps {
 value: string[];
 onChange: (event: SelectChangeEvent<string[]>) => void;
 directReportsFilterItems: DropdownItem<ReflectionsItem[] | string[]>[];
}

const ReflectionsDirectReportsFilter = ({
  value,
  onChange,
  directReportsFilterItems,
  ...props
}: ReflectionsDirectReportsFilterProps): JSX.Element => {
  const items = directReportsFilterItems;
  const renderValue = useCallback(() => {
    let selectedString;
    const hasValue = value.includes('');
    if (hasValue) {
      selectedString = 'Direct Reports';
    } else {
      selectedString = value.map((filterValue) => directReportsFilterItems.find((report) => report.value === filterValue)?.text);
    }

    return (<>{selectedString}</>);
  }, [directReportsFilterItems, value]);

  const hookProps = {
    value,
    items,
    renderValue,
    onChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ReflectionsDirectReportsFilter;
