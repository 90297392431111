import { css } from '@emotion/react';
import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import { useHorizontalScroll } from '~Common/hooks/useHorizontalScroll';
import { RefObject } from 'react';

const styles = {
  filterBarCard: css({
    padding: '.75rem 1.5rem',
    overflowX: 'auto',
  }),
  cardBody: (minWidth: string) => css({
    display: 'flex',
    gap: '1.125rem',
    minWidth,
  }),
};

interface ViewProps extends LeadrCardProps {
  scroller: RefObject<HTMLDivElement>,
  minWidth?: string,
}

const View = ({
  children,
  scroller,
  minWidth = '39.0625rem',
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard css={styles.filterBarCard} ref={scroller} {...props}>
    <div css={styles.cardBody(minWidth)}>
      {children}
    </div>
  </LeadrCard>
);

type FilterBarCardProps = Omit<ViewProps, 'scroller'>

const FilterBarCard = ({
  ...props
}: FilterBarCardProps): JSX.Element => {
  const scroller = useHorizontalScroll();

  const hookProps = {
    scroller,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default FilterBarCard;
