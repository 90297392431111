import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { NoteContext, NoteData } from '~Common/const/interfaces';

import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';

export interface CreateNoteData {
  context: NoteContext,
  note: NoteData,
}

export const createNote = async ({
  context,
  note,
}: CreateNoteData): Promise<HttpCallReturn<null>> => {
  const url = `/organizations/${getOrganizationId() ?? ''}/notes`;

  const requestData = {
    context,
    items: [note],
  };

  return postApi(url, requestData);
};

export const useCreateNote = (
  options?: Omit<UseMutationOptions<HttpCallReturn<null>, unknown, CreateNoteData>, 'mutationFn'>,
): UseMutationResult<HttpCallReturn<null>, unknown, CreateNoteData> => (
  useMutation({
    mutationFn: createNote,
    ...options,
  })
);
