import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PrintWrapper from '~Common/V3/components/Print/PrintWrapper';
import { SURVEY_ANSWERS_SHAPE, SURVEY_BY_PARTICIPANT_ANSWERS_SHAPE, SURVEY_QUESTION_TYPES_SHAPE } from '~Common/const/proptypes';
import { useSurveyDetail } from '../../Hooks/useSurveysList';
import ByQuestionResults from './ByQuestionResults';
import ByPersonResults from './ByPersonResults';
import { useGetSurveyTypes } from '../../Hooks/useSurveyTypes';

const View = ({
  byPerson,
  byQuestion,
  byQuestions,
  byParticipants,
  isLoading,
  questionTypes,
  title,
  subTitle,
  ...props
}) => (
  <PrintWrapper {...props}>
    {isLoading && (
      <PrintWrapper.BasePrintHeaderSkeleton />
    )}
    {!isLoading && (
      <PrintWrapper.BasePrintHeader
        title={title}
        subTitle={subTitle}
      />
    )}
    <PrintWrapper.BasePrintBody>
      {isLoading && (
        <div>Loading...</div>
      )}
      {!isLoading && (
        <>
          {byQuestion && (
            <ByQuestionResults byQuestions={byQuestions} questionTypes={questionTypes} />
          )}
          {byPerson && (
            <ByPersonResults byParticipants={byParticipants} questionTypes={questionTypes} />
          )}
        </>
      )}
    </PrintWrapper.BasePrintBody>
  </PrintWrapper>
);

View.propTypes = {
  byPerson: PropTypes.bool,
  byQuestion: PropTypes.bool,
  byQuestions: SURVEY_ANSWERS_SHAPE.isRequired,
  isLoading: PropTypes.bool.isRequired,
  questionTypes: SURVEY_QUESTION_TYPES_SHAPE.isRequired,
  byParticipants: SURVEY_BY_PARTICIPANT_ANSWERS_SHAPE.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
};

View.defaultProps = {
  byPerson: false,
  byQuestion: false,
};

const SurveysPrint = ({
  byPerson, byQuestion, ...props
}) => {
  const { surveyId } = useParams();

  const { QuestionTypes, isLoading: areQuestionTypesLoading } = useGetSurveyTypes({});

  const { survey, isLoading: isSurveyLoading } = useSurveyDetail({
    surveyId,
    withAnswers: true,
    withParticipants: true,
    withQuestions: true,
  });

  const isLoading = areQuestionTypesLoading || isSurveyLoading;

  const hookProps = {
    byPerson,
    byQuestion,
    isLoading,
    questionTypes: QuestionTypes,
    title: `Surveys - ${survey?.title ?? ''}`,
    subTitle: byPerson ? 'Analysis by Person' : 'Analysis by Question',
    byQuestions: survey?.byQuestions,
    byParticipants: survey?.byParticipants,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SurveysPrint.propTypes = {
  byPerson: PropTypes.bool,
  byQuestion: PropTypes.bool,
};

SurveysPrint.defaultProps = {
  byPerson: false,
  byQuestion: false,
};

export { View };
export default SurveysPrint;
