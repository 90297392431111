import {
  Dispatch as ReactDispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { css } from '@emotion/react';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import Tooltip from '~Common/components/Tooltip';
import { Person } from '~Common/const/interfaces';
import { palette } from '~Common/styles/colors';
import { getOrganizationName, getOrganizationUserId } from '~Common/utils/localStorage';
import Button from '~Common/V3/components/Button';
import { useGetPersonByIdWithLoading } from '~Deprecated/hooks/usePeople';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { profileItems } from '~Root/components/OldNavigationSidebar/navigationItemList';
import { ActionButton } from '~Root/components/OldNavigationSidebar/NavigationItems/ActionButton';
import { LinkButton } from '~Root/components/OldNavigationSidebar/NavigationItems/LinkButton';
import { SHARED_STYLES } from '~Root/components/OldNavigationSidebar/NavigationItems/styles';
import {
  IsVisibleProps,
  NAVBAR_HIGHLIGHT_COLOR,
  NAVBAR_LEFT_PADDING,
  NavigationIndicatorData,
  NavigationItem,
} from '~Root/components/OldNavigationSidebar/types';
import { useNavigationIndicators } from '~Root/components/OldNavigationSidebar/useNavigationIndicators';
import { useNavigationPermissions } from '~Root/components/OldNavigationSidebar/useNavigationPermissions';

const styles = {
  ...SHARED_STYLES,
  avatarSkeleton: css({
    height: '2.5rem',
    width: '2.5rem',
    maxWidth: '100%',
  }),
  chevron: (isDrawerExpanded: boolean) => css({
    rotate: '0deg',
    transition: 'rotate 100ms linear',
  }, isDrawerExpanded && {
    rotate: '180deg',
  }),
  names: css({
    color: palette.neutrals.gray600,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    textAlign: 'start',
    whiteSpace: 'nowrap',
  }),
  personName: css({
    color: palette.neutrals.gray700,
    fontSize: '0.8rem',
    fontWeight: 600,
  }),
  profileSection: css({
    alignItems: 'center',
    backgroundColor: NAVBAR_HIGHLIGHT_COLOR,
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    height: '4.125rem',
    maxWidth: '100%',
    padding: '0.5rem',
    transition: '100ms background-color ease',

    ':hover, :focus': {
      backgroundColor: palette.neutrals.gray50,
    },
  }),
  truncate: css({
    fontSize: '0.625rem',
    fontWeight: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};

const skeletonStyles = {
  row: (isCollapsed: boolean) => css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    gap: '0.5rem',
    padding: '0.5rem',
  }, isCollapsed && {
    paddingLeft: NAVBAR_LEFT_PADDING,
  }),
  skeleton: css({
    height: '1rem',
    width: '100%',
    maxWidth: '100%',
  }),
  column: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '0.25rem',
  }),
};

interface ViewSkeletonProps {
  isCollapsed: boolean,
}

const ViewSkeleton = ({
  isCollapsed,
}: ViewSkeletonProps): JSX.Element => (
  <div css={skeletonStyles.row(isCollapsed)}>
    <SkeletonLoader
      css={styles.avatarSkeleton}
      renderComponent={() => <></>}
    />
    {!isCollapsed && (
      <MultipleSkeletonLoaders
        css={skeletonStyles.column}
        numberOfSkeletons={3}
        renderSkeletonItem={() => <SkeletonLoader css={skeletonStyles.skeleton} renderComponent={() => <></>} />}
      />
    )}
  </div>
);

interface ViewProps extends ProfileCollapseProps {
  dispatch: Dispatch,
  filteredItems: readonly NavigationItem[],
  isDrawerExpanded: boolean,
  isVisibleProps: IsVisibleProps,
  navigationIndicatorData: NavigationIndicatorData,
  organizationName: string,
  person: Person,
  setIsDrawerExpanded: ReactDispatch<SetStateAction<boolean>>,
}

const View = ({
  dispatch,
  filteredItems,
  isCollapsed,
  isDrawerExpanded,
  isVisibleProps,
  navigationIndicatorData,
  organizationName,
  person,
  setIsDrawerExpanded,
}: ViewProps): JSX.Element => (
  <>
    <Button
      variant="text"
      css={styles.profileSection}
      onClick={() => setIsDrawerExpanded(!isDrawerExpanded)}
      renderContents={() => (
        <>
          <NewBaseAvatar
            profileImageUrl={person.profileImageUrl}
            name={`${person.firstName} ${person.lastName}`}
            avatarSize={40}
            showTooltip={false}
          />
          {!isCollapsed && (
            <div css={styles.names}>
              <p css={styles.personName}>{`${person.firstName} ${person.lastName}`}</p>
              <p css={styles.truncate}>{person.email}</p>
              <p css={styles.truncate}>{organizationName}</p>
            </div>
          )}
          <FontAwesomeIcon
            css={styles.chevron(isDrawerExpanded)}
            icon={faChevronUp}
          />
        </>
      )}
    />
    <Collapse in={isDrawerExpanded}>
      <section css={styles.navContainer}>
        {filteredItems.map((item) => (
          <Tooltip
            key={item.label}
            content={isCollapsed ? item.label : null}
            placement="right"
          >
            <>
              {!!item.href && (
                <LinkButton
                  isCollapsed={isCollapsed}
                  item={item}
                  navigationIndicatorData={navigationIndicatorData}
                />
              )}
              {!item.href && !item.children && (
                <ActionButton
                  dispatch={dispatch}
                  isCollapsed={isCollapsed}
                  isVisibleProps={isVisibleProps}
                  item={item}
                />
              )}
            </>
          </Tooltip>
        ))}
      </section>
    </Collapse>
  </>
);

interface ProfileCollapseProps {
  isCollapsed: boolean,
}

export const ProfileCollapse = ({
  isCollapsed,
}: ProfileCollapseProps): JSX.Element => {
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
  const dispatch = useDispatch();
  const isVisibleProps = useNavigationPermissions(isCollapsed);
  const navigationIndicatorData = useNavigationIndicators();
  const { person, isLoading: isProfileLoading } = useGetPersonByIdWithLoading({ id: getOrganizationUserId() }) as {
    person: Person,
    isLoading: boolean,
  };

  const organizationName = getOrganizationName() ?? '';

  const filteredItems = useMemo(() => (
    profileItems.filter((item) => (
      typeof item.isVisible === 'undefined'
      || (typeof item.isVisible === 'boolean' && item.isVisible)
      || item.isVisible(isVisibleProps)
    )) ?? []
  ), [isVisibleProps]);

  if (isProfileLoading) {
    return <ViewSkeleton isCollapsed={isCollapsed} />;
  }

  const hookProps = {
    dispatch,
    filteredItems,
    isCollapsed,
    isDrawerExpanded,
    isVisibleProps,
    navigationIndicatorData,
    organizationName,
    person,
    setIsDrawerExpanded,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
