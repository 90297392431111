import { useCallback, useMemo } from 'react';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';

export interface ProfileEditRestrictions {
  enableProfileNameEdits: boolean,
  enableProfileStatusEdits: boolean,
  enableProfileEmailEdits: boolean,
  enableProfileJobTitleEdits: boolean,
  enableProfileDepartmentEdits: boolean,
  enableProfileManagerEdits: boolean,
}

const defaults = {
  enableProfileNameEdits: true,
  enableProfileStatusEdits: true,
  enableProfileEmailEdits: true,
  enableProfileJobTitleEdits: true,
  enableProfileDepartmentEdits: true,
  enableProfileManagerEdits: true,
};

const fieldMap: Record<string, keyof ProfileEditRestrictions> = {
  name: 'enableProfileNameEdits',
  status: 'enableProfileStatusEdits',
  email: 'enableProfileEmailEdits',
  jobTitle: 'enableProfileJobTitleEdits',
  department: 'enableProfileDepartmentEdits',
  manager: 'enableProfileManagerEdits',
};

interface UseProfileEditRestrictionsReturn {
  enableEnterpriseSSO: boolean,
  isFieldDisabled: Record<keyof typeof fieldMap, boolean>,
}

export function useProfileEditRestrictions(): UseProfileEditRestrictionsReturn {
  const { isCustomerSuccessAdmin } = useOrgUserPermissionsContext();

  const { orgSettings } = useOrgDetailsContext();
  const enableEnterpriseSSO = orgSettings?.enableEnterpriseSSO ?? false;
  const profileEditRestrictions = orgSettings?.profileEditRestrictions ?? defaults;

  const checkIsFieldDisabled = useCallback((field: keyof typeof fieldMap): boolean => {
    if (!enableEnterpriseSSO) {
      return false;
    }

    if (field === 'status' && isCustomerSuccessAdmin) {
      return false;
    }

    return !profileEditRestrictions[fieldMap[field]];
  }, [enableEnterpriseSSO, isCustomerSuccessAdmin, profileEditRestrictions]);

  const isFieldDisabled = useMemo(() => {
    const fieldNames = Object.keys(fieldMap);
    return Object.fromEntries(
      fieldNames.map((fieldName) => ([
        fieldName,
        checkIsFieldDisabled(fieldName),
      ])),
    );
  }, [checkIsFieldDisabled]);

  return { enableEnterpriseSSO, isFieldDisabled };
}
