import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import InputAdornment from '@mui/material/InputAdornment';
import debounce from 'lodash/debounce';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch';
import { withH5Font } from '~Deprecated/ui/styles/typography';
import Input, { INPUT_VARIANTS } from './Input';

const style = css`
  & .MuiInputBase-input {
    &::placeholder {
      ${withH5Font()}
      height: 25px;
    }

    box-shadow: none !important;
    padding-left: 5px;
    padding-top: 14px;
  }

  & .MuiInputBase-inputAdornedStart {
    ${withH5Font()}
    padding-left: 5px;
  }

  &:before, &:after, &:hover:before, &:hover:after {
    border: 0 !important;
  }
`;

const iconStyle = css`
  width: 24px !important;
  height: 24px !important;
`;

/**
 * @deprecated Old Dropdown UI, use packages/leadr-frontend/src/common/V3/components/SearchBox.tsx instead
 */
const SearchBox = ({
  onChange, placeholder, delay, className, ...props
}) => {
  const debouncedSearch = debounce(onChange, delay);

  const doChange = (event) => {
    debouncedSearch(event.target.value);
  };

  return (
    <Input
      type="search"
      variant={INPUT_VARIANTS.NONE}
      startAdornment={(
        <InputAdornment position="start">
          <FontAwesomeIcon
            css={iconStyle}
            icon={faSearch}
          />
        </InputAdornment>
      )}
      className={className}
      css={style}
      placeholder={placeholder}
      onChange={doChange}
      {...props}
    />
  );
};

SearchBox.propTypes = {
  onChange: PropTypes.func,
  delay: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchBox.defaultProps = {
  delay: 250,
  onChange: () => {
  },
  className: '',
  placeholder: 'Search',
};

export default SearchBox;
