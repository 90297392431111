import { useAlert } from '../../hooks';
import Alert from '../../components/Alert';

function NewParentView({ Component: activeComponent }) {
  const { alertMessage } = useAlert();

  return (
    <>
      <div className="coaching-view">
        {activeComponent}
      </div>
      { alertMessage && <Alert message={alertMessage} type="success" /> }
      <div id="menu-panel" />
    </>
  );
}

export default NewParentView;
