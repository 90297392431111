import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

const OrgCardLite = (props) => {
  const {
    current,
    setSelectedUser,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const handleDropdown = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
    setSelectedUser(current.userId);
  };

  return (
    <p className="org-card-lite">
      { `${current.firstName} ${current.lastName}` }
      <button
        className={`org-card-lite-dropdown${current.directsCount > 0 ? '' : ' no-child'}`}
        onClick={handleDropdown}
      >
        <span className="org-card-lite-btn-wrapper">
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronUp} />
        </span>
      </button>
    </p>
  );
};

export default OrgCardLite;
