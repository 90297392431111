import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import { useLocation } from 'react-router-dom';
import Topbar from './components/Topbar';
import SearchCard from './components/SearchCard';
import AlgoliaInstantSearch from './components/AlgoliaInstantSearch';

interface ViewProps {
  initialSearchText: string,
}

const View = ({
  initialSearchText,
}: ViewProps): JSX.Element => (
  <BaseLayout>
    <AlgoliaInstantSearch>
      <ModuleLandingPageWrapper
        TopComponent={<Topbar initialSearchText={initialSearchText} />}
        ChildComponent={<SearchCard />}
      />
    </AlgoliaInstantSearch>
  </BaseLayout>
);

const GlobalSearch = (): JSX.Element => {
  const { state } = useLocation<{ search?: string }>();
  const initialSearchText = state?.search ?? '';

  const hookProps = {
    initialSearchText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default GlobalSearch;
