import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { palette } from '~Common/styles/colors';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import AttendeeItem, { attendeeItemBorderRadius } from '~Meetings/components/attendees/AttendeeItem';
import { userListContainerStyles } from '~Meetings/components/attendees/AttendeesList';

const styles = {
  Avatar: css`
    padding: 0.625rem;
    width: 100%;
  `,
  groupName: css`
    padding: 0.625rem 0rem 0rem 0.625rem;
    color: ${palette.neutrals.gray500};
  `,
  attendeeItem: css`
    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  `,
  skeletonLoaderWrap: css`
    width: 100%;
  `,
  skeletonLoader: css`
    max-width: 100%;
    height: 5rem;
    width:100%;
  `,
};

const TeamUserSection = ({
  sectionName, orgUserIdList, isReadOnly, isLoading,
}) => (
  <>
    <div css={styles.groupName}>
      {sectionName}
    </div>
    {isLoading && (
      <>
        <MultipleSkeletonLoaders
          css={[userListContainerStyles, styles.skeletonLoaderWrap]}
          numberOfSkeletons={3}
          renderSkeletonItem={() => (
            <SkeletonLoader
              variant="rect"
              css={[styles.skeletonLoader, attendeeItemBorderRadius]}
              renderComponent={() => (
                <AttendeeItem />
              )}
            />
          )}
        />
      </>
    )}
    {!isLoading && (
      <>
          {orgUserIdList?.map((orgUserId) => (
            <div css={styles.Avatar}>
              <AttendeeItem
                hideHover={isReadOnly}
                css={styles.attendeeItem}
                id={orgUserId}
              />
            </div>
          ))}
      </>
    )}
  </>
);

TeamUserSection.propTypes = {
  sectionName: PropTypes.string.isRequired,
  orgUserIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

TeamUserSection.defaultProps = {
};

export default TeamUserSection;
