import moment from 'moment';
import { css } from '@emotion/react';

import { CARD_STYLES, TINY_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import { EngagementDataForDate, useEngagementDataForDateRange } from '~Insights/hooks/useEngagementData';
import { useToggleMenuOptions } from '~Insights/hooks/useToggleMenuOptions';
import { ViewPerspective, SetDisplay } from '~Insights/const/types';

import Card from '~Common/V3/components/Card';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { forMobile } from '~Common/styles/mixins';
import { useInsightsUserPerspective, UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { makeDelta } from '~Insights/const/dataUtils';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { defualtMonthsToSubtract } from '~Insights/const/defaults';
import HookedDashboard from './Dashboard';
import { HookedExpanded, SubtabPerspective } from './Expanded';
import ExpandButton from '../../buttons/ExpandButton';
import InsightsDelta from '../../InsightsDelta';
import InsightCardTitle from '../../InsightCardTitle';

const styles = {
  tiny: css({
    ...CARD_STYLES.tiny,
  }),
  ...TINY_CARD_COMPONENTS,

  container: css`
  ${forMobile(`
    padding-right: 3.125rem;
  `)}
`,
};

// eslint-disable-next-line
export interface SharedProps {
  changeToggleMenu: (value: ViewPerspective) => void,
  data: EngagementDataForDate[],
  isLoading: boolean,
  isMobile: boolean,
  toggleMenuOptions: LeadrToggleTextTabProps[],
  toggleMenuValue: ViewPerspective,
  userPerspective: UserPerspective,
  setDisplay?: SetDisplay,
}

interface TinyViewProps {
  threeMonthDelta?: number,
}

const TinyView = ({
  threeMonthDelta,
}: TinyViewProps): JSX.Element => (
  <Card
    css={styles.tiny}
    renderContents={() => (
      <div css={styles.tinyContainer}>
        <div css={[styles.header(false), styles.smallHeader]}>
          <ExpandButton css={styles.expandButton(false)} cardKey="engagement" />
          <InsightCardTitle>Engagement</InsightCardTitle>
        </div>
        <div css={styles.tinyBody}>
          {threeMonthDelta !== undefined && (
            <>
              <InsightsDelta value={threeMonthDelta} />
              <p>past three months</p>
            </>
          )}
          {threeMonthDelta === undefined && (
            <div css={styles.emptyStateSmall}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <img src={insightsEmptyGraph} alt="Empty graph" />
              <p css={styles.emptyStateText}>
                Data will show here as your team answers the engagement survey.
              </p>
            </div>
          )}
        </div>
      </div>
    )}
  />
);
interface EngagementCardProps {
  size: 'tiny' | 'dashboard' | 'expanded'
}

const EngagementCard = ({
  size,
  ...props
}: EngagementCardProps): JSX.Element => {
  const { userPerspective } = useInsightsUserPerspective();
  const { data, isLoading } = useEngagementDataForDateRange({
    startDate: moment().subtract(defualtMonthsToSubtract, 'months').startOf('day').toDate(),
    endDate: moment().startOf('day').toDate(),
    userPerspective,
  });

  const [setDisplay] = useQueryParamState<SetDisplay>('insights', 'setDisplay', SetDisplay.Desktop);
  const checkMobileQuery = useIsMobileQuery();
  const [, setSubtabPerspective] = useQueryParamState<string>('insights', 'subtabPerspective', SubtabPerspective.OverallScore);

  const {
    dashboardToggleMenuOptions, expandedToggleMenuOptions, toggleMenuValue, setToggleMenuValue,
  } = useToggleMenuOptions('engagementPerspective');

  const changeToggleMenu = (value: ViewPerspective): void => {
    if (value) {
      setToggleMenuValue(value);
      if (value === ViewPerspective.Company) {
        setSubtabPerspective(SubtabPerspective.OverallScore);
      }
    }
  };

  const hookProps = {
    changeToggleMenu,
    data,
    isLoading,
    isMobile: setDisplay === SetDisplay.Mobile || checkMobileQuery === true,
    setDisplay,
    toggleMenuValue,
    userPerspective,
  };

  if (size === 'tiny') {
    const threeMonthSlice = data.slice(-3);
    const threeMonthDelta = threeMonthSlice.length ? makeDelta(data[0].averages.overall, data[data.length - 1].averages.overall) : undefined;
    return (
      <TinyView
        {...hookProps}
        threeMonthDelta={threeMonthDelta}
        {...props}
      />
    );
  }

  if (size === 'expanded') {
    return (
      <HookedExpanded
        {...hookProps}
        data={data}
        toggleMenuOptions={expandedToggleMenuOptions}
        {...props}
      />
    );
  }

  if (size === 'dashboard') {
    return (
      <HookedDashboard
        {...hookProps}
        toggleMenuOptions={dashboardToggleMenuOptions}
        {...props}
      />
    );
  }

  return <></>;
};

export { TinyView };
export default EngagementCard;
