import PropTypes from 'prop-types';
import { useState } from 'react';
import { css } from '@emotion/react';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Button, Collapse } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import emptyParticipants from '~Assets/images/empty-views/emptyParticipants.png';
import AttendeeItem, { AttendeeItemSkeleton } from '~Meetings/components/attendees/AttendeeItem';
import { drawerHeaderFontColor, palette } from '~Common/styles/colors';
import { useCoachingSuggestionsByType } from '~Meetings/hooks/useCoachingSuggestions';
import { ATTENDEE_SUGGESTION_DATA, UNIQUE_ID } from '~Common/const/proptypes';

const styles = {
  collapseButton: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-transform: none;
    font-size: 1rem;
    font-weight: 600;
    color: ${drawerHeaderFontColor};

    &:disabled {
      color: ${drawerHeaderFontColor};
    }
  `,
  userListContainer: css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.3125rem;
  `,
  expandIcon: css`
    color: ${palette.brand.indigo};
  `,
  additionalInfo: css`
    font-size: 0.75rem;
    color: ${palette.neutrals.gray400};
  `,
  listIcon: css`
    font-size: 1.5rem;
    color: ${palette.brand.sky};
  `,
  emptyMessage: css`
    padding: 0.5rem;
    color: ${palette.neutrals.gray400};
    text-align: center;
  `,
  emptyStateImage: css`
    width: 100%;
  `,
};

const View = ({
  title,
  attendees,
  attendeesData,
  isExpanded,
  isLoading,
  isSelected,
  onExpandClick,
  onAttendeeClick,
  isOverridden,
  ...props
}) => (
  <div>
    <Button
      css={styles.collapseButton}
      onClick={onExpandClick}
      disabled={isOverridden}
      {...props}
    >
      <span>{title}</span>

      {!isOverridden && (
      <FontAwesomeIcon css={styles.expandIcon} icon={isExpanded ? faMinus : faPlus} />
      )}
    </Button>

    {isOverridden && (
      <div css={styles.userListContainer}>
        {attendees.map((id) => (
          <AttendeeItem
            id={id}
            key={id}
            onClick={onAttendeeClick}
            isAttendeeSelected={isSelected}
          />
        ))}
      </div>
    )}

    {!isOverridden && (
    <Collapse in={isExpanded}>
      <div css={styles.userListContainer}>
        {isLoading && (
        <>
          <AttendeeItemSkeleton />
          <AttendeeItemSkeleton />
          <AttendeeItemSkeleton />
        </>
        )}

        {!isLoading && attendees.map((id) => (
          <AttendeeItem
            id={id}
            key={id}
            onClick={onAttendeeClick}
            isAttendeeSelected={isSelected}
          />
        ))}

        {!isLoading && !isOverridden && !attendees.length && (

        <span css={styles.emptyMessage}>
          <img src={emptyParticipants} alt="You have no participants." css={styles.emptyStateImage} />
          You have no
          {' '}
          {title.replace('Your', '')}
          {' '}
          to show.
        </span>
        )}
      </div>
    </Collapse>
    )}
  </div>
);

View.propTypes = {
  title: PropTypes.string.isRequired,
  attendees: PropTypes.arrayOf(UNIQUE_ID),
  attendeesData: PropTypes.arrayOf(),
  isExpanded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSelected: PropTypes.func.isRequired,
  onExpandClick: PropTypes.func.isRequired,
  onAttendeeClick: PropTypes.func.isRequired,
  isOverridden: PropTypes.bool.isRequired,
};

View.defaultProps = {
  attendees: [],
  attendeesData: [],
};

const SuggestedAttendeeList = ({
  title,
  colleagueType,
  isSelected: isAttendeeSelected,
  onSelect,
  overrideAttendees,
  overrideAttendeesData,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    list: attendees,
    listData: attendeesData,
    isLoading,
  } = useCoachingSuggestionsByType({ colleagueType, enabled: isExpanded && !overrideAttendees });

  const onExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const onAttendeeClick = (id, isSelected) => {
    onSelect(id, isSelected);
  };

  const hookProps = {
    title,
    attendees: overrideAttendees ?? attendees,
    attendeesData: overrideAttendeesData ?? attendeesData,
    isExpanded,
    isLoading,
    isSelected: isAttendeeSelected,
    onExpandClick,
    onAttendeeClick,
    isOverridden: !!overrideAttendees,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SuggestedAttendeeList.propTypes = {
  title: PropTypes.string.isRequired,
  colleagueType: PropTypes.string.isRequired,
  isSelected: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  overrideAttendees: PropTypes.arrayOf(PropTypes.string),
  overrideAttendeesData: PropTypes.arrayOf(ATTENDEE_SUGGESTION_DATA),
};

SuggestedAttendeeList.defaultProps = {
  overrideAttendees: null,
  overrideAttendeesData: null,
};

export { View };
export default SuggestedAttendeeList;
