import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { deleteApi, HttpCallReturn } from '~Deprecated/services/HttpService';

interface RemoveReflectionProps {
  reviewUid: string,
  reviewTopicUid: string,
  subjectUid: string,
}

const removeReflection = ({
  reviewUid,
  reviewTopicUid,
  subjectUid,
}: RemoveReflectionProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviews/${reviewUid}/reflections`,
  };

  return deleteApi<void>(serverUrl, {
    reviewTopicUid,
    subjectUid,
  });
};

interface UseRemoveReflectionProps {
  reviewUid: string,
  onSuccessCallback?: () => void,
}

export function useRemoveReflection({
  reviewUid,
  onSuccessCallback,
}: UseRemoveReflectionProps): UseMutationResult<HttpCallReturn<void>, unknown, RemoveReflectionProps, unknown> {
  const mutation = useMutation({
    mutationFn: removeReflection,
    onSuccess: async () => {
      onSuccessCallback?.();
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId() ?? '', 'reviews', reviewUid, '2.5'] });
    },
  });

  return mutation;
}
