import { Location } from 'history';
import { SyntheticEvent, useMemo } from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import LeadrTabs from '~Common/V3/components/LeadrTabs';
import { GLOBAL_SEARCH_TABS } from '~GlobalSearch/const/GlobalSearch';

interface TabRecord {
  label: string,
  value: string,
  count: number,
  toObject: Partial<Location>,
  ['data-test-id']: string,
}

interface ViewProps {
  activeTab: string,
  tabs: TabRecord[],
  handleTabChange: (event: SyntheticEvent, tab: string) => void,
  renderRightItem?: () => JSX.Element,
}

const View = ({
  activeTab,
  tabs,
  handleTabChange,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrTabs
    value={activeTab}
    handleChange={handleTabChange}
    {...props}
  >
    {tabs.map((tab) => (
      <LeadrTabs.LinkTab
        label={`${tab.label} (${tab.count})`}
        value={tab.value}
        to={tab.toObject}
        key={tab.value}
        data-test-id={tab['data-test-id']}
      />
    ))}
  </LeadrTabs>
);

interface TabsProps extends Omit<ViewProps, 'tabs' | 'handleTabChange'> {
  setActiveTab: (tab: string) => void,
}

const Tabs = ({
  setActiveTab,
  ...props
}: TabsProps): JSX.Element => {
  const { scopedResults } = useInstantSearch();

  const tabsToUse = useMemo(() => GLOBAL_SEARCH_TABS.map((tab) => ({
    ...tab,
    count: scopedResults.find((scopedResult) => scopedResult.indexId === tab.indexName)?.results?.nbHits ?? 0,
  })), [scopedResults]);

  const handleTabChange = (event: SyntheticEvent, newTab: string): void => {
    setActiveTab(newTab);
  };
  const hookProps = {
    tabs: tabsToUse,
    handleTabChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default Tabs;
