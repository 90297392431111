import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { useMemo } from 'react';
import moment from 'moment-timezone';
import { palette } from '~Common/styles/colors';
import NewGoalStatus from '~Goals/components/Shared/NewGoalStatus';
import { lineClamp, withSpacedOutTitleStyles } from '~Common/styles/mixins';

const styles = {
  definingObjective: css({
    padding: '.75rem 1.125rem',
    border: `1px solid ${palette.neutrals.gray400}`,
    borderRadius: '0.5rem',
  }),
  dateString: css({
    textTransform: 'uppercase',
  }, withSpacedOutTitleStyles()),
  title: css({
    color: palette.neutrals.gray700,
    fontWeight: 600,
    marginTop: '.375rem',
    marginBottom: '.5625rem',
  }, lineClamp(2)),
};

interface ViewProps {
  dateString: string,
  title: string,
  status: Goals.GoalStatus,
}

const View = ({
  dateString,
  title,
  status,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.definingObjective}
    {...props}
  >
    <span css={styles.dateString}>{dateString}</span>
    <div css={styles.title}>{title}</div>
    <NewGoalStatus status={status} />
  </div>
);

interface DefiningObjectiveProps {
  definingObjective: Goals.Goal,
}

const DefiningObjective = ({
  definingObjective,
  ...props
}: DefiningObjectiveProps): JSX.Element => {
  const dateString = useMemo(() => {
    const start = moment(definingObjective.startTimeInMillis).format('MMM DD');
    const end = moment(definingObjective.endTimeInMillis).format('MMM DD');

    return `${start} - ${end}`;
  }, [
    definingObjective,
  ]);

  const { status } = definingObjective.statusUpdates[definingObjective.statusUpdates.length - 1];

  const hookProps = {
    dateString,
    title: definingObjective.title,
    status,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default DefiningObjective;
