import PropTypes from 'prop-types';

import InputMask from 'react-input-mask';
import { useState } from 'react';
import TextField from './TextField';

const phoneStripper = (value) => value.toString().replace(/\D/g, '');

const View = ({
  name,
  phoneNumber,
  setPhoneNumber,
  ...props
}) => (
  <>
    <InputMask
      {...props}
      mask="+9 999 999 9999"
      onChange={(e) => {
        const value = phoneStripper(e.target.value);
        setPhoneNumber(value);
      }}
      onBlur={(e) => {
        // 1. Strip everything except numbers.
        let value = phoneStripper(e.target.value);

        // 2. If the length is 10, and doesn't start with a 1, prefix the value with 1.
        if (value.length === 10 && value.charAt(0) !== '1') {
          value = `1${value}`;
        }

        // 3. Add + prefix to the value, as we stripped that out in step 1.
        setPhoneNumber(`+${value}`);
      }}
      value={phoneNumber}
    >
      {(inputProps) => <TextField {...inputProps} label="Phone Number" />}
    </InputMask>
    <input type="hidden" value={phoneNumber} name={name} />
  </>
);

View.propTypes = {
  name: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.string.isRequired,
};

View.defaultProps = {
  name: 'phoneNumber',
};

const PhoneNumberField = ({
  initialValue,
  ...props
}) => {
  let tempInitialValue = initialValue;
  if (phoneStripper(initialValue).length === 10) {
    tempInitialValue = '1'.concat(initialValue);
  }
  const [phoneNumber, setPhoneNumber] = useState(tempInitialValue);

  const hookProps = {
    phoneNumber,
    setPhoneNumber,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

PhoneNumberField.propTypes = {
  initialValue: PropTypes.string,
};

PhoneNumberField.defaultProps = {
  initialValue: '',
};

export { View };
export default PhoneNumberField;
