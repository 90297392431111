import { createAction } from '@reduxjs/toolkit';
import { StrictEffect, takeEvery } from 'redux-saga/effects';

import { sagaMiddleware } from '~Deprecated/store';
import { navigate } from '~Deprecated/sagas/navigate';

interface NavigatePayload {
  pathname: string,
  search?: Record<string, unknown>,
  popAll?: boolean,
  doPop?: boolean,
  params?: Record<string, unknown>,
}

const navigateAction = createAction<NavigatePayload>('LEADR.NAVIGATE');

function* navigateWatcher(): Generator<StrictEffect, void, unknown> {
  yield takeEvery(navigateAction, navigate);
}

sagaMiddleware.run(navigateWatcher);

export {
  navigateAction,
};
