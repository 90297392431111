import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDebounce } from '@react-hook/debounce';

import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { ContextButtons } from '~Reviews/V2/Shared/ContextButtons';
import {
  REVIEW_SETUP_LAYOUT, BUTTON_STYLES,
  FORM_STYLES,
} from '~Reviews/V2/Const/pageStyles';
import { css } from '@emotion/react';
import {
  palette,
} from '~Common/styles/colors';

import AttendeeList from '~Common/V3/components/Attendees/AttendeesList';
import FilterGroupsButton from '~Common/components/FilterGroupsButton';
import { FILTER_TYPE, useFilterMenuItems, useSelectParticipants } from '~Common/hooks/useSelectParticipants';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import SelectAllButtons from '~Common/components/Drawers/SelectParticipantsDrawer/SelectAllButtons';
import SelectedFilterChips from '~Common/components/Drawers/SelectParticipantsDrawer/SelectedFilterChips';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';

import { usePeopleWithInvitedManagers } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { Person } from '~Common/const/interfaces';

import { useDispatch } from 'react-redux';
import { BASE_ROUTE, REVIEWS_CONTINUE_CREATING_CYCLE } from '~Common/const/routes';
import { navigateAction } from '~Deprecated/actions/navigate';
import { noop, without } from 'lodash';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import { ManagerType } from '~Admin/hooks/useGetPeopleManagers';
import FloatingActionMenu from '~Reviews/V2/Shared/FloatingActionMenu';
import { withSpacedOutTitleStyles } from '~Common/styles/mixins';
import { attendeeItemBorderRadius } from '../../Shared/AttendeeItem';
import {
  ViewCyclePerspective, ParticipantTypeEnum, CreateReviewDto, ReviewCycleStatusEnum,
} from '../../Const/types';
import { Manager, useGetEmployeeManagers } from '../../Hooks/useGetPeopleManagers';
import { useSelectedParticipants } from '../../Hooks/useSelectedParticipants';
import { useAddParticipant } from '../../Hooks/useSyncParticipant';
import { useGetReviewCycleDetail } from '../../Hooks/useGetReviewCycleDetail';
import { SelectedParticipants } from './SelectedParticipants';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...BUTTON_STYLES,
  ...FORM_STYLES,

  addParticipantsWrap: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& > div': {
      width: '48.5%',
      background: palette.neutrals.white,
      borderRadius: '.5rem',
      marginTop: '1.375rem',
      padding: '2rem 1.5rem',
    },
  }),
  fauxDrawerTitle: css({
    fontWeight: '600',
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    paddingBottom: '1.25rem',
    marginBottom: '1rem',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,

  }),
  userListContainer: css({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '0.5rem',
    marginBlockStart: '.875rem',
  }),
  searchBox: css({
    marginRight: '.625rem',
    flex: 1,
  }),
  searchContainer: css({
    position: 'sticky',
    display: 'flex',
  }),
  filterChips: css({
    marginTop: '1.25rem',
  }),
  selectAllButtons: css({
    marginTop: '.875rem',
  }),
  skeletonLoader: css({
    maxWidth: '100%',
    height: '5rem',
  }),
  selectAllButton: (isAvailable: boolean) => css(withSpacedOutTitleStyles(isAvailable ? palette.brand.blue : palette.neutrals.gray700)),
};

interface FilterShape {
  departments: string[],
  filters: string[],
  teams: string[],
}

interface DisabledUserProps {
  user: string,
  tooltip: string,
}

interface FilterChangeProps {
  text: string,
  filterType: string | undefined,
  apiValue: string,
  id: string,
  total?: number,
}

interface PeopleQueryProps {
  limitedAccessUserIds: string[],
  data: Record<string, Person>,
  orgUserIds: string[],
}

interface SelectParticipantsProps{
  allParticipants: string[],
  fetchFilteredPeople: (params: FilterShape) => void,
  isLoading: boolean,
  onSearch: (searchText: string) => void,
  participants: string[],
  setDebouncedLoadingOverride: Dispatch<SetStateAction<boolean>>,
}

interface ViewProps {
  canDeselectAll: boolean,
  canFilter: boolean,
  disabledUsers: DisabledUserProps[],
  filteredParticipants: string[],
  filterMenuItems: FilterChangeProps[],
  handleClick: (action: ViewCyclePerspective) => void,
  isAttendeeSelected: (id: string) => boolean,
  isLoading: boolean,
  normalizedManagers: Record<string, Manager[]>,
  onDeselectAll: () => void,
  onFilterChange: (newFilters: FilterChangeProps[]) => void,
  onRemoveAll: () => void,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onSelectAll: () => void,
  onSelectParticipant: (params: string) => void,
  peopleData: Record<string, Person>,
  selectedFilters: string[],
  selectedParticipants: string[],
  setSelectedFilters: Dispatch<SetStateAction<never[]>>,
  syncParticipants: (isActionMenu?: boolean) => void,
  cycleIsNotDraft: boolean,
  normalizedManagersIsLoading: boolean,
  cycleId: string,
}

const View = ({
  canDeselectAll,
  canFilter,
  disabledUsers,
  filteredParticipants,
  filterMenuItems,
  handleClick,
  isAttendeeSelected,
  isLoading,
  normalizedManagers,
  onDeselectAll,
  onFilterChange,
  onRemoveAll,
  handleSearchTextChange,
  onSelectAll,
  onSelectParticipant,
  peopleData,
  selectedFilters,
  selectedParticipants,
  setSelectedFilters,
  syncParticipants,
  cycleIsNotDraft,
  normalizedManagersIsLoading,
  cycleId,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="contextButtons"
      renderContents={() => (
        <>
          {cycleIsNotDraft && (
            <LeadrButton
              onClick={() => syncParticipants()}
              disabled={isLoading}
              data-test-id="reviewsCycleUpdateParticipants"
            >
              Update Participants
            </LeadrButton>
          )}
          {!cycleIsNotDraft && (
          <>
            <LeadrButton
              css={styles.outlineButton}
              variant="ghost"
              onClick={() => { handleClick(ViewCyclePerspective.Create_Questions); }}
              data-test-id="reviewsBackToCreateQuestions"
            >
              Previous Step
            </LeadrButton>
            <LeadrButton
              onClick={() => syncParticipants()}
              data-test-id="reviewsNextToPreviewAndPublish"
            >
              Next Step
            </LeadrButton>
          </>
          )}
        </>
      )}
    />
    <div css={styles.addParticipantsWrap}>
      <div css={[styles.boxShadow, styles.overflowAttendees]}>
        <div>
          <p css={styles.fauxDrawerTitle}>
            Select Reviewees
          </p>
          <div css={styles.searchContainer}>
            <LeadrSearchField data-test-id="reviewsParticipantsSectionSearchField" css={styles.searchBox} onChange={handleSearchTextChange} />

            {canFilter && (
              <FilterGroupsButton
                menuItems={filterMenuItems}
                onChangeCallback={onFilterChange}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            )}
          </div>
          {selectedFilters.length > 0 && (
            <SelectedFilterChips
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              css={styles.filterChips}
              onFilterChange={onFilterChange}
            />
          )}
          <div>
            <SelectAllButtons
              css={styles.selectAllButtons}
              renderSelectAllButton={() => (
                <LeadrButton
                  textButtonColor={palette.brand.blue}
                  disabled={isLoading}
                  onClick={onSelectAll}
                  css={styles.selectAllButton(true)}
                  variant="text"
                  data-test-id="reviewsCycleSelectAllParticipants"
                >
                  SELECT ALL
                </LeadrButton>
              )}
              renderDeselectAllButton={() => (
                <LeadrButton
                  textButtonColor={canDeselectAll ? palette.brand.blue : palette.neutrals.gray700}
                  disabled={isLoading || !canDeselectAll}
                  onClick={onDeselectAll}
                  css={styles.selectAllButton(canDeselectAll)}
                  variant="text"
                  data-test-id="reviewsCycleDeselectAllParticipants"
                >
                  DESELECT ALL
                </LeadrButton>
              )}
            />
            {isLoading && (
              <MultipleSkeletonLoaders
                css={styles.userListContainer}
                numberOfSkeletons={10}
                renderSkeletonItem={() => (
                  <SkeletonLoader
                    variant="rectangular"
                    css={[styles.skeletonLoader, attendeeItemBorderRadius]}
                    renderComponent={() => <></>}
                  />
                )}
              />
            )}
            {!isLoading && (
              <AttendeeList
                selectedAttendees={selectedParticipants}
                isAttendeeSelected={isAttendeeSelected}
                isLoading={false}
                attendees={filteredParticipants}
                onClick={onSelectParticipant}
                disableUsers={disabledUsers}
                css={styles.attendeeList}
              />
            )}
          </div>
        </div>
      </div>
      <SelectedParticipants
        disabledUsers={disabledUsers}
        isAttendeeSelected={isAttendeeSelected}
        normalizedManagers={normalizedManagers}
        onRemoveAll={onRemoveAll}
        onSelectParticipant={onSelectParticipant}
        peopleData={peopleData}
        selectedParticipants={selectedParticipants}
        isLoading={normalizedManagersIsLoading}
      />
    </div>
    {/*
        Instead of doing prop drilling or creating another portal for this one off case
        I decided to pull this component into this component as a one off.
        If this changes in the future and we need to do one more, we need to make this a portal
        The main component is used on /CreateCyle/Dashboard.tsx
       */}
    <div>
      <FloatingActionMenu
        viewCyclePerspective={ViewCyclePerspective.Add_Participants}
        cycleId={cycleId}
        setViewCyclePerspective={noop}
        cycleIsNotDraft={cycleIsNotDraft}
        syncParticipants={() => syncParticipants(true)}
      />
    </div>
  </>
);

interface ParticipantsSectionProps {
  cycleId: string,
}

const ParticipantsSection = ({
  cycleId,
}: ParticipantsSectionProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleClick = (perspective: ViewCyclePerspective): void => {
    dispatch(navigateAction({
      pathname: `${REVIEWS_CONTINUE_CREATING_CYCLE}/${perspective}`,
      params: {
        cycleId,
      },
    }));
  };

  const { data: reviewCycle, participants: existingParticipants, isLoading: isCycleLoading } = useGetReviewCycleDetail({ id: cycleId });
  const syncParticipantMutation = useAddParticipant({ id: cycleId });

  const filterIds: string[] = [];

  const { filterMenuItems, selectedFilters, setSelectedFilters } = useFilterMenuItems();

  const {
    onSearch,
    isLoading: areParticipantsLoading,
    participants: filteredParticipants,
    fetchFilteredPeople,
    allParticipants,
    setDebouncedLoadingOverride,
  }: SelectParticipantsProps = useSelectParticipants({
    useOrgIds: true,
    allowSelf: true,
    selectedFilters,
    filterIds: [],
  }) as SelectParticipantsProps;

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onSearch(event.target.value);
  };

  const { limitedAccessUserIds, data: peopleData } = usePeopleWithInvitedManagers() as PeopleQueryProps;
  const [filterPeopleObject, setFilterObject] = useDebounce({} as FilterShape, 1000);

  const isMatrixReview = reviewCycle ? FlaggedEnum(reviewCycle.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer) : false;

  const { normalizedManagers: normalizedManagersBeforeFilter, isLoading: normalizedManagersIsLoading } = useGetEmployeeManagers();
  const normalizedManagers = useMemo(() => Object.fromEntries(
    Object.entries(normalizedManagersBeforeFilter).map(
      ([orgUserId, managers]) => [orgUserId, managers.filter((manager) => manager.type !== ManagerType.Mentor || isMatrixReview)],
    ),
  ), [isMatrixReview, normalizedManagersBeforeFilter]);

  const [showSkeletonLoaders] = useSkeletonLoaders(areParticipantsLoading || isCycleLoading || !peopleData);

  const noManagers: DisabledUserProps[] = Object.values(peopleData ?? {}).filter((person) => !person.managerId)?.map((person) => (
    { user: person.orgUserId, tooltip: 'This user does not have a manager and cannot participate in a review cycle.' }
  )) ?? [];

  const limitedAccessUsers: DisabledUserProps[] = limitedAccessUserIds.map((limitedAccessUserId) => (
    { user: limitedAccessUserId, tooltip: 'This user is a limited access user and cannot participate in a review cycle.' }
  ));

  const disabledUsers = [
    ...noManagers, ...limitedAccessUsers,
  ];

  const disabledOrgUserIds = disabledUsers.map((user) => user.user);

  const eligibleFilteredParticipants = without(filteredParticipants, ...disabledOrgUserIds);
  const eligibleParticipants = without(allParticipants, ...disabledOrgUserIds);
  const {
    canDeselectAll,
    onDeselectAll,
    isAttendeeSelected,
    onRemoveAll,
    onSelectParticipant,
    onSelectAll,
    selectedParticipants,
  } = useSelectedParticipants({
    eligibleParticipants,
    existingParticipants,
    filteredParticipants: eligibleFilteredParticipants,
  });

  const onFilterChange = (newFilters: FilterChangeProps[]): void => {
    const filters = newFilters.filter((filter) => filter.filterType === FILTER_TYPE.FILTERS).map((filter) => filter.apiValue);
    const departments = newFilters.filter((filter) => filter.filterType === FILTER_TYPE.DEPARTMENT).map((filter) => filter.text);
    const teams = newFilters.filter((filter) => filter.filterType === FILTER_TYPE.GROUPS).map((filter) => filter.apiValue);
    const filterObject: FilterShape = {
      filters,
      departments,
      teams,
    };

    if (filters.length > 0 || departments.length > 0 || teams.length > 0) {
      setFilterObject(filterObject);
      setDebouncedLoadingOverride(true);
    }
  };

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchFilteredPeople(filterPeopleObject);
    }
  }, [filterPeopleObject, fetchFilteredPeople]);

  const syncParticipants = (isActionMenu = false): void => {
    const participantsToSend: CreateReviewDto[] = [];
    selectedParticipants.forEach((orgUserId) => {
      const tempManagers = normalizedManagers[orgUserId];
      const reviewDto: CreateReviewDto = {
        participants: [{
          orgUserId,
          participantType: ParticipantTypeEnum.Reviewee,
        }],
      };
      tempManagers?.forEach((manager) => {
        if (manager.type === ManagerType.Mentor) {
          if (isMatrixReview) {
            reviewDto.participants.push({
              orgUserId: manager.id,
              participantType: ParticipantTypeEnum.SecondaryReviewer,
            });
          }
        } else {
          reviewDto.participants.push({
            orgUserId: manager.id,
            participantType: ParticipantTypeEnum.Reviewer,
          });
        }
      });
      participantsToSend.push(reviewDto);
    });
    if (cycleIsNotDraft && !isActionMenu) {
      syncParticipantMutation({ cycleId, participants: participantsToSend }, { onSuccess });
    } else if (!cycleIsNotDraft && !isActionMenu) {
      syncParticipantMutation({ cycleId, participants: participantsToSend }, { onSuccess });
    } else {
      syncParticipantMutation({ cycleId, participants: participantsToSend });
    }
  };
  const cycleIsNotDraft = reviewCycle?.statusEnum !== ReviewCycleStatusEnum.Draft;
  const onSuccess = (): void => {
    if (cycleIsNotDraft) {
      dispatch(navigateAction({
        pathname: `${BASE_ROUTE}reviews/admin/cycle/${cycleId}`,
        params: {
          cycleId,
        },
      }));
    } else {
      dispatch(navigateAction({
        pathname: `${REVIEWS_CONTINUE_CREATING_CYCLE}/${ViewCyclePerspective.Preview_Publish}`,
        params: {
          cycleId,
        },
      }));
    }
  };

  const hookProps = {
    canDeselectAll,
    canFilter: !filterIds.length,
    disabledUsers,
    filteredParticipants,
    filterMenuItems: filterMenuItems as FilterChangeProps[],
    handleClick,
    isAttendeeSelected,
    isLoading: showSkeletonLoaders,
    normalizedManagers,
    onDeselectAll,
    onFilterChange,
    onRemoveAll,
    handleSearchTextChange,
    onSelectAll,
    onSelectParticipant,
    peopleData,
    selectedFilters,
    setSelectedFilters,
    selectedParticipants,
    syncParticipants,
    cycleIsNotDraft,
    normalizedManagersIsLoading,
    cycleId,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ParticipantsSection };
export default ParticipantsSection;
