import { css } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import { OrgDetails } from '~Common/const/Organization';
import { useGetOrgDetails } from '~Common/hooks/useGetOrgDetails';
import { palette } from '~Common/styles/colors';
import { getOrganizationId } from '~Common/utils/localStorage';

const styles = {
  loader: css({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  }),
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const OrgDetailsContext = createContext<OrgDetails | null>(null);

export const useOrgDetailsContext = (): OrgDetails => {
  const orgSettings = useContext(OrgDetailsContext);

  if (!orgSettings) {
    throw new Error('OrgDetailsContext: No value provided ');
  }

  return orgSettings;
};

interface OrgSettingsContextProps {
  children: ReactNode,
}

export const OrgDetailsContextProvider = ({
  children,
}: OrgSettingsContextProps): JSX.Element => {
  const orgId = getOrganizationId() ?? '';
  const data = useGetOrgDetails({ orgId });

  if (data.isLoading) {
    return (
      <div
        css={styles.loader}
      >
        <CircularProgress css={styles.circularProgress} />
      </div>
    );
  }

  if (data.isError) {
    return (<>Some error</>);
  }

  return (
    <OrgDetailsContext.Provider value={data.data?.response as OrgDetails}>
      {children}
    </OrgDetailsContext.Provider>
  );
};
