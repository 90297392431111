import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDebounce } from '@react-hook/debounce';
import { useEffect } from 'react';

import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { useMutateBaseballCardNotes, useBaseballCardNotes } from '~People/hooks/baseballCard/useBaseballCardNotes';
import Loader from '~Common/components/Loader';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import NotesDrawerHeader from '~People/BaseballCard/Drawers/NotesDrawerHeader';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import CloseDrawerButton from '~Common/components/Buttons/CloseDrawer';
import DrawerHeader from '~Common/components/Drawers/Header';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';
import { queryClient } from '~Common/const/queryClient';
import Froala from '~Common/V3/components/Froala';
import { palette } from '~Common/styles/colors';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';

const styles = {
  header: css` 
    align-items: flex-start;
    padding: 8px 24px 4px 24px;
    margin-block-start: unset;
  `,
  layout: css`
    & > div {
      padding: unset;
    }
  `,
  rte: css`
    margin: 0 24px 12px 24px;
    padding-top: 16px;
    border-top: 1px solid ${palette.neutrals.gray200};
  `,
  info: css`
   font-size: 12px;
   font-style: italic;
   color: ${palette.neutrals.gray700}
   font-weight: 400;
   margin-top: 12px;
  `,
  froala: css({

    '.fr-element': {
      minHeight: '200px !important',
    },
  }),
};

export const notesDrawerTemplate = {
  name: 'BASEBALL_CARD_NOTES_DRAWER',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};

function View({
  user, renderButtons, renderEditor,
}) {
  return (
    <DrawerLayout
      css={styles.layout}
      renderHeader={() => (
        <DrawerHeader
          css={styles.header}
          renderCustomHeader={() => (<NotesDrawerHeader user={user} />)}
          renderButtons={renderButtons}
        />
      )}
      renderBody={() => (
        <div css={styles.rte}>
          {renderEditor()}
          <div css={styles.info}>
            Notes can only be seen by you
          </div>
        </div>
      )}
    />
  );
}

View.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  renderEditor: PropTypes.func.isRequired,
  renderButtons: PropTypes.func.isRequired,
};

View.defaultProps = {};

const NotesDrawer = ({
  userId, popDrawer, ...props
}) => {
  const dispatch = useDispatch();
  const creatorId = getUserId();

  const { note, isLoading, isFetching } = useBaseballCardNotes(creatorId, userId);
  const { detail } = useUserProfile(userId);
  const userDetails = detail.data?.response;
  const { isMutating, updateNote } = useMutateBaseballCardNotes({ creatorId, userId });

  const [noteText, setNoteText] = useDebounce(note, 250);
  const previousNoteText = usePrevious(noteText);
  // create workflow
  useEffect(() => {
    if (!note.id && !isMutating && noteText !== '' && previousNoteText !== undefined && noteText !== previousNoteText) {
      updateNote({
        type: 'USER',
        userId,
        text: noteText,
        rank: 0,
        orgId: getOrganizationId(),
      });
    }
  }, [note, isMutating, noteText, previousNoteText, updateNote, userId]);

  // update workflow
  useEffect(() => {
    if (note && note.id && previousNoteText !== undefined && previousNoteText !== noteText && !isMutating) {
      updateNote({
        type: 'USER',
        userId,
        text: noteText,
        rank: 0,
        noteId: note?.id,
        orgId: getOrganizationId(),
      });
    }
  }, [noteText, note, updateNote, userId, isMutating, previousNoteText]);

  useEffect(() => () => {
    queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'baseballCard_notes', creatorId, userId] });
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (isLoading || isFetching) {
    return (
      <Loader />
    );
  }

  const onClose = () => {
    dispatch(popDrawer({
      drawerName: notesDrawerTemplate.name,
    }));
  };

  const initialNoteText = note?.text ?? '';

  const hookProps = {
    user: userDetails,
    onClose,
    renderButtons: () => (
      <CloseDrawerButton onClick={onClose} />
    ),
    renderEditor: () => (
      <Froala
        styleOverrides={styles.froala}
        label="Private Notes"
        richTextEditorProps={{
          initialValue: initialNoteText,
          onChange: ({ value: newText }) => setNoteText(newText),
        }}
      />
    ),
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

NotesDrawer.propTypes = {
  userId: PropTypes.string.isRequired,
  popDrawer: PropTypes.func.isRequired,
};

NotesDrawer.defaultProps = {};

registerDrawer({
  templateName: notesDrawerTemplate.name,
  component: NotesDrawer,
});

export { View };
export default NotesDrawer;
