import { useMemo } from 'react';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { useGetAdminData } from '~Deprecated/hooks/admin/useGetUsersList';

interface UseUserMapReturn {
  userMap: Map<string, PersonDisplayInformation>,
}

export function useUserMap(): UseUserMapReturn {
  const { users } = useGetAdminData({
    count: 10000,
    administrativeStatus: 'ACTIVE,CREATED,INVITED,INACTIVE',
  }) as { users: PersonDisplayInformation[]; };

  const userMap = useMemo(
    () => new Map(users.map((user) => ([user.orgUserId ?? '', user]))),
    [users],
  );

  return { userMap };
}
