import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { postApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { ReviewSignoffDTO } from '../schemata/ReviewSignoff';

interface submitReviewProps {
  reviewId: string | undefined,
  signoffComment: ReviewSignoffDTO,
}

const submitReview = ({ reviewId, signoffComment }: submitReviewProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviews/${reviewId ?? ''}/signoff`,
  };

  return postApi(serverUrl, signoffComment, {});
};

export const useSignoffReview = (): UseMutateFunction<HttpCallReturn<void>, unknown, submitReviewProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: submitReview,
    onMutate: () => {
      toastId.current = toast.info('Signing off the review...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error signing off the review. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully signed off the review.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviews'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), getOrganizationUserId(), 'reviewCycles'] });
      await queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeReviews(getOrganizationId() ?? '') });
    },
  });

  return mutation.mutate;
};
