/* eslint-disable react/button-has-type */

import PropTypes from 'prop-types';

import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons';

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Default';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const Templates = ({ text, ...props }) => (
  <Button
    text={text}
    icon={faFolderOpen}
    {...props}
  />
);

Templates.propTypes = {
  text: PropTypes.string,
};

Templates.defaultProps = {
  text: 'Templates',
};

export default Templates;
export { BUTTON_COLOR, BUTTON_VARIANT };
