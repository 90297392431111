import { css } from '@emotion/react';

import {
  SCORE_NAME_VALUES,
} from '~Common/const/executiveInsights';
import InsightStatusIndicator, { INSIGHT_STATUS_SIZE } from '../InsightStatusIndicator';

const style = css`
  display: flex;
  justify-content: space-between;

  > span {
    flex: 1;
    justify-content: center;
  }
`;

const InsightSummaryHeader = () => (
  <div css={style}>
    <span
      className="insightSummaryHeaderScore"
    >
      &nbsp;
    </span>
    {Object.values(SCORE_NAME_VALUES).map((scoreName) => (
      <span
        className="insightSummaryHeaderScore"
        key={scoreName}
      >
        <InsightStatusIndicator
          scoreValue={scoreName}
          size={INSIGHT_STATUS_SIZE.SMALL}
        />
      </span>
    ))}
  </div>
);

InsightSummaryHeader.propTypes = {};

InsightSummaryHeader.defaultProps = {};

export default InsightSummaryHeader;
