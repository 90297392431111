import { AgendaTemplates } from '@leadr-hr/types';
import { produce } from 'immer';
import { unset } from 'lodash';
import { useCallback } from 'react';
import { getLocalId } from '~Common/utils/uuid';
import {
  CreateEditTemplateTopic,
  EditIndividualAgendaTemplateDetails,
  CreateAgendaTemplateDetails,
  EditAgendaTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';

interface UseTemplateItemLocalIds {
  fillLocalIds: (templateItems: EditAgendaTemplateDetails[]) => CreateEditTemplateTopic[],
  removeLocalIdsAndPermissions: (
    templateItems: CreateEditTemplateTopic[] | EditIndividualAgendaTemplateDetails[]
  ) => CreateAgendaTemplateDetails[] | EditAgendaTemplateDetails[] | EditIndividualAgendaTemplateDetails[],
}

export const useTemplateItemLocalIds = (): UseTemplateItemLocalIds => {
  const fillLocalIds = useCallback((
    templateItems: EditAgendaTemplateDetails[],
  ): CreateEditTemplateTopic[] => templateItems.map((templateItem) => {
    if (templateItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
      return {
        ...templateItem,
        localId: getLocalId(),
        children: templateItem.children.map((child) => ({
          ...child,
          localId: getLocalId(),
        })),
      };
    }

    return {
      ...templateItem,
      localId: getLocalId(),
    };
  }), []);

  const removeLocalIdsAndPermissions = useCallback((
    templateItems: CreateEditTemplateTopic[] | EditIndividualAgendaTemplateDetails[],
  ): CreateAgendaTemplateDetails[] | EditAgendaTemplateDetails[] | EditIndividualAgendaTemplateDetails[] => (
    produce(templateItems, (draft) => {
      draft.forEach((templateItem) => {
        unset(templateItem, 'localId');
        unset(templateItem, 'permissions');
        if (templateItem.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
          templateItem.children.forEach((child) => {
            unset(child, 'localId');
            unset(child, 'permissions');
          });
        }
      });
    })
  ), []);

  return {
    fillLocalIds,
    removeLocalIdsAndPermissions,
  };
};
