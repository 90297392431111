import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { LinearProgress } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import Tooltip from '~Common/components/Tooltip';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import NewGoalStatus from '~Goals/components/Shared/NewGoalStatus';
import { GoalRow } from '~Goals/const/types';
import { PROGRESS_BAR_STYLES } from '~Goals/const/styles';
import { forMobileObject, withoutDesktopObject } from '~Common/styles/mixins';
import NameCell from '~Goals/components/Tables/GoalsTable/NameCell';

const styles = {
  ...PROGRESS_BAR_STYLES,
  progressWrapper: css({
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
  }, forMobileObject({
    width: '100%',
    flexDirection: 'row',
    gap: '.5rem',
    alignItems: 'center',
  })),
  progress: (short?: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: '.3125rem',
  }, short && {
    maxWidth: '4.0625rem',
  }),
  progressBarWrap: css({
    flexBasis: '80%',
  }),
  percent: css({
    flexBasis: '15%',
  }),
  goalDates: css({
    whiteSpace: 'normal',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.25rem',
  }, withoutDesktopObject({
    lineHeight: '1rem',
  })),
  progressBarAdjustment: css({
    height: '.25rem',
  }),
};

const useGetTableColumns = (enableCascadingGoals: boolean): GridColDef<GoalRow>[] => {
  const isMobile = useIsMobileQuery();

  const tableColumnHeaders: GridColDef<GoalRow>[] = useMemo(() => [
    ...!enableCascadingGoals ? [{
      field: 'title',
      headerName: 'Name',
      flex: isMobile ? 2 : 3,
      renderCell: (params: GridRenderCellParams<GoalRow>) => {
        const {
          title,
          isPrivate,
          subText,
          progress,
        } = params.row;

        return (
          <NameCell
            title={title}
            isPrivate={isPrivate}
            subText={subText}
            percentage={progress.percentage}
            status={progress.status}
            totalChildGoals={0} // Non-cascading view, so no child goals
          />
        );
      },
    }] : [],
    {
      field: 'progress',
      sortable: false,
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<GoalRow>) => {
        const { progress } = params.row;

        return (
          <div css={styles.progressWrapper} data-test-id="goalsTableProgress">
            <div css={styles.progress(true)}>
              <div css={styles.progressBarWrap}>
                <Tooltip content={`${progress.percentage ?? 0}%`}>
                  <LinearProgress
                    css={[styles.progressBar(progress.status ?? Goals.GoalStatus.OnTrack), styles.progressBarAdjustment]}
                    value={progress.percentage ?? 0}
                    variant="determinate"
                  />
                </Tooltip>
              </div>
              <div css={styles.percent}>
                {`${progress.percentage || 0}`}
                %
              </div>
            </div>
            <div>
              <NewGoalStatus
                status={progress.status}
                isMobile
              />
            </div>
          </div>
        );
      },
    },
    {
      field: 'dueDate',
      headerName: 'Dates',
      flex: 1,
      maxWidth: isMobile ? 225 : 175,
      renderCell: (params: GridRenderCellParams<GoalRow>) => (
        <div css={styles.goalDates} data-test-id="goalsTableDueDates">
          <span>{`${params.row.startDate} - `}</span>
          <span>{params.row.endDate}</span>
        </div>
      ),
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 1,
      maxWidth: 125,
    },
    {
      field: 'role',
      sortable: false,
      headerName: 'My Role',
      flex: 1,
      maxWidth: 150,
    },
    {
      field: 'objectiveType',
      sortable: false,
      headerName: 'Objective Type',
      flex: 2,
      maxWidth: 225,
    },
    {
      field: 'owner',
      sortable: false,
      headerName: 'Owner',
      maxWidth: 70,
      renderCell: (params: GridRenderCellParams<GoalRow>) => {
        const { owner } = params.row;

        const {
          imgUrl,
          name,
        } = owner;

        return (
          <NewBaseAvatar
            data-test-id="goalsTableOwnerAvatar"
            name={name}
            profileImageUrl={imgUrl}
            avatarSize={30}
          />
        );
      },
    },
  ], [enableCascadingGoals, isMobile]);

  return tableColumnHeaders;
};

export default useGetTableColumns;
