import { SelectChangeEvent, css } from '@mui/material';
import { useMemo } from 'react';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';
import { defaultPeoplePageFilterValue } from '~People/stores/usePeoplePageFilter';

const styles = {
  filterDropdownView: (hasValidDropdownValue: boolean) => css({
  }, hasValidDropdownValue && ({
    border: `1px solid ${palette.brand.sky}`,
  })),
};

interface ViewProps<T> {
  options: DropdownItem<T>[],
  value: T,
  handleFilterChange: (event: SelectChangeEvent<T>) => void,
  renderValue: string | undefined,
  optionsContainValue: boolean,
  multiple?: boolean,
  disabled?: boolean,
  renderItem?: ((item: DropdownItem<T>) => JSX.Element) | undefined,
}

const View = <T extends string | number | string[] | number[] = string, >({
  options,
  value,
  handleFilterChange,
  renderValue,
  optionsContainValue,
  disabled,
  renderItem,
  ...props
}: ViewProps<T>): JSX.Element => (
  <Dropdown<T>
    css={styles.filterDropdownView(optionsContainValue)}
    disabled={disabled}
    items={options}
    onChange={handleFilterChange}
    value={value}
    renderValue={() => renderValue}
    renderItem={renderItem}
    {...props}
  />
  );

interface FilterDropdownViewProps<T> extends Omit<ViewProps<T>, 'optionsContainValue' | 'renderValue'> {
  labelForNoValue: string,
  displayEmpty?: boolean,
  value: T,
}

const FilterDropdownView = <T extends string | number | string[] | number[] = string, >({
  options,
  value,
  labelForNoValue,
  renderItem = undefined,
  disabled = false,
  ...props
}: FilterDropdownViewProps<T>): JSX.Element => {
  const optionsContainValue = useMemo(
    () => options.some((option) => option.value === value)
          || options.some((option) => (value as string[]).includes(option.value as string)),
    [options, value],
  );

  const renderSingleValue = (): string => options.find((option) => option.value === value)?.text as string;
  const renderMultipleValues = (): string => (value as string[]).map((val) => options.find((option) => option.value === val)?.text).join(', ');

  let renderValue = optionsContainValue ? renderSingleValue() : labelForNoValue;
  if (Array.isArray(value)) {
    renderValue = optionsContainValue ? renderMultipleValues() : labelForNoValue;
  }

  let itemToRender = renderItem;
  if (renderItem === undefined) {
    itemToRender = (item): JSX.Element => (
      <>
        {item.text}
      </>
    );
  }

  const hookProps = {
    options,
    value,
    renderValue,
    optionsContainValue,
    disabled: disabled || (!optionsContainValue && value !== defaultPeoplePageFilterValue && !Array.isArray(value)),
    renderItem: itemToRender,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default FilterDropdownView;
