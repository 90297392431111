import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { DEFAULT_METRIC_REPORT, METRIC_SUMMARY_REPORT_SHAPE } from '~Common/const/executiveInsights';

const style = css`
  width: 100%;
  height: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 10px;
  }
`;

const InsightPlot = ({ metricSummaryReport, renderMetric, renderGraph }) => {
  const entries = Object.entries(metricSummaryReport);
  return (
    <>
      { entries.map(([metric, scoreSummary]) => (
        <div
          css={style}
          key={metric}
        >
          {renderMetric({
            metric,
            scoreSummary,
          })}
          {renderGraph({
            metric,
            scoreSummary,
          })}
        </div>
      )) }
    </>
  );
};

InsightPlot.propTypes = {
  metricSummaryReport: METRIC_SUMMARY_REPORT_SHAPE,
  renderMetric: PropTypes.func.isRequired,
  renderGraph: PropTypes.func.isRequired,
};

InsightPlot.defaultProps = {
  metricSummaryReport: DEFAULT_METRIC_REPORT,
};

export default InsightPlot;
