/* eslint-disable no-bitwise */
import {
  object,
  string,
  array,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const updateTopicFormSchema = object({
  reOpenReveiwOptions: string(),
  reviewsIds: array(string()),
});

export const reOpenReviewFormResolver = yupResolver(updateTopicFormSchema);

export type FormValues = InferType<typeof updateTopicFormSchema>;

export interface UpdateTopicDTO {
    reOpenReveiwOptions: string,
    reviewsIds?: string[],
  }

export function conformToDto(data: FormValues): UpdateTopicDTO {
  const result: UpdateTopicDTO = {
    reOpenReveiwOptions: data.reOpenReveiwOptions as string,
    reviewsIds: data.reviewsIds as string[],
  };

  return result;
}
