import { Realtime, Types } from 'ably';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GetAbly, SetAbly } from '~Common/functions/ablyRealtime';

import { getOrganizationId } from '~Common/utils/localStorage';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { postApi } from '~Deprecated/services/HttpService';
import { getHost } from '~Deprecated/services/config';

export interface AuthenticateProps {
  organizationId: string,
}

const authenticate = ({
  organizationId,
}: AuthenticateProps): void => {
  if (!GetAbly()) {
    SetAbly(new Realtime.Promise({
      echoMessages: false,
      authCallback: async (tokenParams, callback) => {
        try {
          const url = {
            host: getHost('', '2'),
            uri: `/organizations/${organizationId}/auth/rtcAccessToken`,
          };
          const accessTokenResponse = await postApi<Types.TokenDetails>(url, {});

          callback(null, accessTokenResponse.response);
        } catch (error) {
          // @ts-expect-error Ably doesn't seem to export their ErrorInfo type
          // Leaving this type error ignored for now
          callback(error, null);
        }
      },
    }));
  }
};

export interface OpenChannelProps {
  channelName: string,
  onPresenceChange?: (message: Types.PresenceMessage) => void,
}

const openChannel = async ({
  channelName,
  onPresenceChange,
}: OpenChannelProps): Promise<Types.RealtimeChannelPromise> => {
  const channel = GetAbly().channels.get(channelName);

  if (onPresenceChange) {
    await channel.presence.subscribe(['enter', 'leave', 'present'], onPresenceChange);
    await channel.presence.enter();
  }

  return channel;
};

export interface InitializeChannelListenerProps {
  channelName: string,
  messageTypesFilter?: string[],
  listener?: (message: Types.Message) => void,
}

const initializeChannelListener = async ({
  channelName,
  messageTypesFilter,
  listener,
}: InitializeChannelListenerProps): Promise<void> => {
  const channel = GetAbly().channels.get(channelName);

  if (listener) {
    if (messageTypesFilter) {
      await channel.subscribe(messageTypesFilter, listener);
    } else {
      await channel.subscribe(listener);
    }
  }
};

export interface CloseChannelProps {
  channelName: string,
  onChannelClose?: () => void,
}

const closeChannel = async ({
  channelName,
  onChannelClose,
}: CloseChannelProps): Promise<void> => {
  const channel = GetAbly().channels.get(channelName);

  channel.unsubscribe();
  await channel.presence.leave();
  await channel.detach();
  onChannelClose?.();
};

export interface HandleChannelChangesProps extends InitializeChannelListenerProps, OpenChannelProps, CloseChannelProps {
  didChannelNameChange: boolean,
  didListenerChange: boolean,
  didEnabledChange: boolean,
  channel?: Types.RealtimeChannelPromise,
  previousChannelName?: string,
  previousListener?: (message: Types.Message) => void,
  onChannelClose?: () => void,
  enabled: boolean,
}

const handleChannelChanges = async ({
  didChannelNameChange,
  didListenerChange,
  didEnabledChange,
  channel,
  channelName,
  previousChannelName,
  messageTypesFilter,
  onPresenceChange,
  listener,
  previousListener,
  onChannelClose,
  enabled,
}: HandleChannelChangesProps): Promise<Types.RealtimeChannelPromise | void> => {
  let newChannel;

  if (enabled) {
    if (didChannelNameChange || (!channel && didEnabledChange)) {
      if (channel) {
        await closeChannel({
          channelName: previousChannelName ?? channelName,
          onChannelClose,
        });
      }

      newChannel = await openChannel({
        channelName,
        onPresenceChange,
      });
    }

    if (didChannelNameChange || didListenerChange || didEnabledChange) {
      if (channel && didListenerChange && previousListener) {
        channel.unsubscribe(previousListener);
      }

      await initializeChannelListener({
        channelName,
        messageTypesFilter,
        listener,
      });
    }
  } else if (channel && !enabled && didEnabledChange) {
    await closeChannel({
      channelName,
      onChannelClose,
    });
  }

  return newChannel;
};

export interface UseRealtimeProps {
  channelName: string,
  messageTypesFilter?: string[],
  listener?: (message: Types.Message) => void,
  onPresenceChange?: (message: Types.PresenceMessage) => void,
  onChannelClose?: () => void,
  closeOnUnmount?: boolean
  enabled?: boolean
}

export interface useRealtimeReturn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ably uses any
  send: (messageType: string, payload: Record<string, any>) => void
  connectedUsers: string[],
}

const useRealtime = ({
  channelName,
  messageTypesFilter,
  onPresenceChange,
  onChannelClose,
  closeOnUnmount,
  listener,
  enabled = true,
}: UseRealtimeProps): useRealtimeReturn => {
  const organizationId = getOrganizationId();
  const [channel, setChannel] = useState<Types.RealtimeChannelPromise>();

  // We need to use ref to store the list of active users rather than useState
  // as the updates can come in too fast for setState to update fully
  // (useState is not synchronous)
  const connectedUsersRef = useRef<string[]>([]);

  const previousChannelName = usePrevious(channelName);
  const previousListener = usePrevious(listener);
  const previousEnabled = usePrevious(enabled);

  const enabledAndOrgSelected = enabled && !!organizationId;

  useEffect(() => {
    if (enabledAndOrgSelected) {
      authenticate({
        organizationId,
      });
    }
  }, [organizationId, enabledAndOrgSelected]);

  useEffect(() => {
    const didChannelNameChange = channelName !== previousChannelName;
    const didListenerChange = listener !== previousListener;
    const didEnabledChange = enabled !== previousEnabled;

    handleChannelChanges({
      didChannelNameChange,
      didListenerChange,
      didEnabledChange,
      channel,
      channelName,
      previousChannelName,
      messageTypesFilter,
      onPresenceChange,
      onChannelClose,
      listener,
      previousListener,
      enabled,
    }).then((newChannel) => {
      if (newChannel) {
        setChannel(newChannel);
      }
    }).catch(() => {
      // ToDo: Handle Error
    });

    // channel, messageTypesFilter, previousChannelName, previousEnabled, and previousListener
    // excluded from deps as we only want to handle channel changes when channelName
    // or listener changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // channel,
    // messageTypesFilter,
    // previousChannelName,
    // previousListener
    enabled,
    listener,
    channelName,
  ]);

  useEffect(() => (
    () => {
      if (closeOnUnmount) {
        closeChannel({
          channelName,
          onChannelClose,
        }).catch(() => {
          // ToDo: Error handling
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps -- This page intentionally left blank
  ), []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ably uses any
  const send = useCallback((messageType: string, payload: Record<string, any>) => {
    if (!channel) {
      throw new Error('Attempted to send message before a channel was opened');
    }

    return channel.publish(messageType, payload);
  }, [channel]);

  return {
    send,
    connectedUsers: connectedUsersRef.current,
  };
};

export default useRealtime;
