import {
  call, race, take, takeLatest,
} from 'redux-saga/effects';
import {
  forgotPasswordSaga,
} from './authenticationSaga';
import * as meeting from './meetingSaga';
import * as feedback from './feedbackSaga';
import * as types from '../actions';
import * as content from './contentSaga';
import * as polling from './pollingSaga';

export function* watchUserAuthentication() {
  yield takeLatest(types.FORGOT_PASSWORD, forgotPasswordSaga);
}

export function* watchMeeting() {
  yield takeLatest(types.GET_MEETING_BY_ID, meeting.getMeetingById);
  yield takeLatest(types.REMOVE_FEEDBACK_QUESTION_FROM_COACHING, meeting.removeFeedbackQuestionFromCoaching);
}

export function* watchFeedback() {
  yield takeLatest(types.SEND_RESPONSE_TO_QUESTIONS, feedback.sendResponseToFeedbackQuestions);
}

export function* watchContent() {
  yield takeLatest(types.SEND_RESPONSE_CONTENT_FOR_ME_LIST, content.sendResponseData);
}

// Un comment this block after removing the user API calls from watchUserAuthentication
// export function* watchUser() {
//     yield takeLatest(types.GET_USER_INFO, loginSaga);
// }

export function* watchParallelPollingTasks() {
  while (true) {
    try {
      const action = yield take(types.POLLING.START_PARALLEL);
      switch (action.caller) {
        case 'MEETING_DETAILS':
          yield race([
            call(polling.meetingDetailsPollingTask, action),
            take(types.POLLING.STOP_PARALLEL),
          ]);
          continue;
      }
    } catch (e) {
      // console.log(e)
    }
  }
}
