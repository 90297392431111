import { normalizeFeedbackTemplateFromServer } from './feedbackTemplates';

export const normalizeFeedbackFromServer = ({ data }) => {
  const normalizedFeedback = { ...data };

  if (normalizedFeedback.assignee) {
    normalizedFeedback.assignees = [normalizedFeedback.assignee];
  }

  if (normalizedFeedback.owner && !normalizedFeedback.assignee) {
    normalizedFeedback.assignees = [normalizedFeedback.owner];
  }

  if (normalizedFeedback.owner?.status) {
    normalizedFeedback.status = normalizedFeedback.owner.status;
  }

  return normalizedFeedback;
};
