import { createAction } from '@reduxjs/toolkit';

import { registerAction } from '~Deprecated/reducers/idle';
import { mutateState } from '~Deprecated/utils/reduxUtils';

const setIdleTimeoutAction = createAction('LEADR.SET_IDLE_TIMEOUT');

function setIdleTimeout(state, { payload }) {
  return mutateState(state, (draftState) => {
    draftState.timeout = payload.timeout;
    return draftState;
  });
}

registerAction(setIdleTimeoutAction, setIdleTimeout);

export {
  setIdleTimeoutAction,
};
