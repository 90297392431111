import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { useGetReceivedPlaylistDetails } from '~Learning/hooks/received/useGetReceivedPlaylistDetail';
import { ReceivedLearningWorkflow, ReceivedPlaylistLearning } from '~Learning/const/interfaces';
import { learningContentDrawerTemplate } from './Tabs/Content/LearningContentDrawer';
import Content from './Tabs/Content';
import Details from './Tabs/Details';

const styles = {
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
  cardSkeleton: css({
    minWidth: '100%',
    height: '12.5rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
};

export const receivedPlaylistDrawerTemplate = {
  name: 'RECEIVED_PLAYLIST_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface ReceivedPlaylistDetailDrawerState {
  workflow: ReceivedLearningWorkflow,
}

interface ReceivedPlaylistDrawerProps extends DrawerProps<ReceivedPlaylistDetailDrawerState> {
  playlistId: number,
  learningOwnerId?: string,
  isReadOnly: boolean,
}

const ReceivedPlaylistDetailDrawer = ({
  playlistId,
  learningOwnerId,
  isReadOnly = false,
  popDrawer,
  pushDrawer,
  setDrawerState,
}: ReceivedPlaylistDrawerProps): JSX.Element => {
  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      workflow: ReceivedLearningWorkflow.PLAYLIST,
    }));
  }, [setDrawerState]);

  const { detail: playlist, isLoading } = useGetReceivedPlaylistDetails({
    playlistId,
    asReadOnly: isReadOnly,
    viewPerspectiveUid: learningOwnerId,
  });

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: receivedPlaylistDrawerTemplate.name });
  };

  const [activeTab, setActiveTab] = useState(0);

  const [selectedLearningId, setSelectedLearningId] = useState('');

  const isLearningSelected = (learningId: string): boolean => learningId === selectedLearningId;

  const onLearningClick = (learning: ReceivedPlaylistLearning, index: number): void => {
    setSelectedLearningId(learning.id);

    pushDrawer({
      drawer: {
        ...learningContentDrawerTemplate,
        args: {
          learning,
          learningIndex: index,
          totalLearningsCount: playlist?.totalLearningsCount,
          playlistId,
          assigner: playlist?.assigner,
          clearSelectedLearning: () => {
            setSelectedLearningId('');
          },
          setSelectedLearningId,
        },
      },
    });
  };

  const hookProps = {
    renderHeader: () => (
      <TabDrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            tooltip="Close"
            size="large"
          />
        )}
        title="View Learning Playlist"
        renderTabNav={(tabNavStyles: SerializedStyles) => (
          <div css={tabNavStyles}>
            <TabNavItem
              css={styles.tabNavItem}
              onClick={() => setActiveTab(0)}
              isActive={activeTab === 0}
              renderNavItem={() => (
                <div>Content</div>
              )}
            />
            <TabNavItem
              isActive={activeTab === 1}
              onClick={() => setActiveTab(1)}
              renderNavItem={() => (
                <>Details</>
              )}
            />
          </div>
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {isLoading && (
          <MultipleSkeletonLoaders
            numberOfSkeletons={3}
            renderSkeletonItem={() => (
              <CardSkeleton css={styles.cardSkeleton} />
            )}
          />
        )}
        {(!isLoading && playlist) && (
          <>
            {activeTab === 0 && (
              <Content
                playlist={playlist}
                onLearningClick={onLearningClick}
                isLearningSelected={isLearningSelected}
              />
            )}
            {activeTab === 1 && (
              <Details
                playlist={playlist}
              />
            )}
          </>
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: receivedPlaylistDrawerTemplate.name,
  component: ReceivedPlaylistDetailDrawer,
});

export default ReceivedPlaylistDetailDrawer;
