import { useUrlQuery } from '~Common/hooks/useUrlQuery';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useMeetingDetails from './useMeetingDetails';

interface UseUpcomingHuddleByFactoryIdReturn {
  coachingId?: string;
  meetingId?: string;
  factoryId?: string;
  teamId?: string;
}

/**
 * meetingFactoryId query param causes a redirect removing the factoryID from the URL
 * factoryId is the same value, but will remain in the url after the refresh.
 * This was done rather than just removing the redirect in case that redirect had a purpose
 * beyond being used just to grab the meeting Id and orgId, but there had to be a reason why it was removed in the first place.
 * @date 2024-05-13
 */
export const useUpcomingHuddleByFactoryId = (): UseUpcomingHuddleByFactoryIdReturn => {
  const history = useHistory();
  const location = useLocation();

  const {
    meetingId,
    coachingId,
    meetingFactoryId,
    factoryId,
    teamId,
  }: {
    meetingId?: string;
    coachingId?: string;
    meetingFactoryId?: string;
    factoryId?: string;
    teamId?: string;
  } = useUrlQuery();

  const factoryIdToUse = meetingFactoryId || factoryId;

  const { item } = useMeetingDetails({
    id: meetingId || coachingId || '',
    type: meetingId ? MeetingTypeEnum.MEETING : MeetingTypeEnum.COACHING,
    enabled: (!!meetingId || !!coachingId) && !factoryIdToUse,
  });

  /*
    If the factoryId is not currently in the URL, we want to fetch it and append it to the URL
    Having the factoryId in the URL allows us to run more API calls in parallel when the page is refreshed or navigated to directly
  */
  useEffect(() => {
    if (!meetingFactoryId && !factoryId && item?.factoryId) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('factoryId', item.factoryId);

      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      history.replace(newUrl);
    }
  }, [meetingFactoryId, factoryId, factoryIdToUse, history, location.pathname, location.search, item?.factoryId]);

  return {
    coachingId,
    meetingId,
    factoryId: factoryIdToUse,
    teamId,
  };
};
