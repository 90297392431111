import { createContext } from 'react';
import { noop } from '~Deprecated/utils';
import {
  CreateAgendaTemplateDetailsWithLocalID,
  EditAgendaTemplateDetailsWithLocalID,
  AgendaTemplateType,
} from '~Meetings/components/topic-suggestions/const/types';

export interface EditTemplateItemParams {
  localId: string,
  item: CreateAgendaTemplateDetailsWithLocalID | EditAgendaTemplateDetailsWithLocalID,
  sectionLocalId?: string,
}

export interface TemplateItemActions {
  createTemplateItem: (agendaTemplateType: AgendaTemplateType, text: string) => void,
  deleteTemplateItem: (localId: string, sectionLocalId?: string) => void,
  editTemplateItem: ({ localId, item, sectionLocalId }: EditTemplateItemParams) => void,
  addTopicToSection: (sectionLocalId: string, text: string) => void,
  ungroupSection: (sectionLocalId: string) => void,
}

export const TemplateItemActionsContext = createContext<TemplateItemActions>({
  createTemplateItem: noop,
  deleteTemplateItem: noop,
  editTemplateItem: noop,
  addTopicToSection: noop,
  ungroupSection: noop,
});
