import {
  countBy, Dictionary, groupBy, intersection, truncate,
} from 'lodash';
import moment from 'moment-timezone';
import { EngagementDataForDate, EngagementScores, ExecutiveEngagementDataForDate } from '~Insights/hooks/useEngagementData';
import { ENPSDataForDate, ENPSStats, ExecutiveENPSDataForDate } from '~Insights/hooks/useENPSData';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { EngagementCategory, ViewPerspective } from './types';

// Holding onto this for now - may be deleted at a later date once these numbers are confirmed.
// export function makeDeltaENPS(initialValue: number, finalValue: number): number {
//   if (initialValue === 0) {
//     return (finalValue - initialValue);
//   }
//   // Find the absolute change between the two values
//   const absoluteChange = Math.abs(finalValue - initialValue);

//   // Find the percent change as a percentage of the initial value
//   const percentChange = (absoluteChange / Math.abs(initialValue)) * 100;

//   // Return the percent change
//   return percentChange;
// }

export function makeDelta(initialValue: number, finalValue: number): number {
  if (initialValue === 0) {
    return (finalValue - initialValue);
  }
  // Find the absolute change between the two values
  const absoluteChange = finalValue - initialValue;

  // Find the percent change as a percentage of the initial value
  const percentChange = (absoluteChange / Math.abs(initialValue)) * 100;

  // Return the percent change as a string
  return percentChange;
}

export type DataGrouping = 'departments' | 'userGroups' | 'managers';

export function makeTwelveMonthDelta(dataToSlice: { scores: ENPSStats }[]): number|null {
  if (!dataToSlice.length) return null;

  const twelveMonthSlice = dataToSlice.length > 12 ? dataToSlice.slice(-12)[0] : dataToSlice[0];
  const todaySlice = dataToSlice.slice(-1)[0];
  return makeDelta(twelveMonthSlice.scores.score, todaySlice.scores.score);
}

export function translateViewPerspectiveToGrouping(toggleMenuValue: ViewPerspective): DataGrouping {
  if (toggleMenuValue === ViewPerspective.Departments) {
    return 'departments';
  }
  if (toggleMenuValue === ViewPerspective.UserGroups) {
    return 'userGroups';
  }

  return 'managers';
}

function formattedSurveyDate(
  slice: EngagementDataForDate | GroupedEngagementData | ENPSDataForDate | GroupedENPSData,
  surveyDateCounts: Dictionary<number>,
): string {
  const date = slice.surveyDate;
  if (date in surveyDateCounts && surveyDateCounts[date] > 1) {
    return `${moment(date).format('MMM D YY')} (${slice.surveyId})`;
  }

  return moment(date).format('MMM D YY');
}

export interface GroupedDelta {
  label: string,
  value: number,
}

export interface GroupedENPSData {
  surveyDate: string,
  surveyId: number,
  name: string,
  scores: ENPSStats,
  surveysRequested: number,
  surveysAnswered: number,
}

export function makeENPSGroupings(data: ENPSDataForDate[], viewPerspective: ViewPerspective): Record<string, GroupedENPSData[]> {
  if (!data.length) return {};
  const groupType = translateViewPerspectiveToGrouping(viewPerspective);

  const executiveData = data as ExecutiveENPSDataForDate[];
  const mergedData = executiveData.flatMap((slice) => (
    slice[groupType].filter(({ name }) => name !== '').map((grouping) => ({
      surveyDate: slice.surveyDate,
      surveyId: slice.surveyId,
      name: grouping.name,
      scores: grouping.scores,
      surveysRequested: grouping.surveysRequested,
      surveysAnswered: grouping.surveysAnswered,
    }))
  ));

  if (!mergedData.length) return {};
  return groupBy(mergedData, 'name');
}

export interface GroupedEngagementData {
  surveyDate: string,
  surveyId: number,
  name: string,
  averages: EngagementScores,
  surveysRequested: number,
  surveysAnswered: number,
}

export function makeEngagementGroupings(data: ExecutiveEngagementDataForDate[], viewPerspective: ViewPerspective): Record<string, GroupedEngagementData[]> {
  if (!data.length) return {};
  const groupType = translateViewPerspectiveToGrouping(viewPerspective);

  const mergedData = data.flatMap((slice) => (
    slice[groupType].filter(({ name }) => name !== '').map((grouping) => ({
      surveyDate: slice.surveyDate,
      surveyId: slice.surveyId,
      name: grouping.name,
      averages: grouping.averages,
      surveysRequested: grouping.surveysRequested,
      surveysAnswered: grouping.surveysAnswered,
    }))
  ));

  if (!mergedData.length) return {};
  return groupBy(mergedData, 'name');
}

interface MakeENPSGraphDataParams {
  dataSlice: ExecutiveENPSDataForDate[],
  groupedData: Record<string, GroupedENPSData[]>,
  includedCategories?: string[],
  userPerspective: UserPerspective,
  viewPerspective: ViewPerspective,
}

export function makeENPSGraphData({
  dataSlice,
  groupedData = {},
  includedCategories,
  userPerspective,
  viewPerspective,
}: MakeENPSGraphDataParams): GraphDataset[] {
  const surveyDateCounts = countBy(dataSlice, 'surveyDate');

  if (userPerspective === UserPerspective.manager) {
    return [{
      label: 'Your Direct Reports',
      data: dataSlice.map((slice) => ({
        x: formattedSurveyDate(slice, surveyDateCounts),
        y: slice.scores.score,
        participantInfo: `${slice.surveysAnswered}/${slice.surveysRequested} Participants`,
      })),
    }];
  }

  if (viewPerspective === ViewPerspective.Company) {
    return [{
      label: 'eNPS',
      data: dataSlice.map((slice) => ({
        x: formattedSurveyDate(slice, surveyDateCounts),
        y: slice.scores.score,
        participantInfo: `${slice.surveysAnswered}/${slice.surveysRequested} Participants`,
      })),
    }];
  }

  if (viewPerspective === ViewPerspective.Departments
    || viewPerspective === ViewPerspective.ManagerReports
    || viewPerspective === ViewPerspective.UserGroups) {
    const keysToInclude = includedCategories ? intersection(includedCategories, Object.keys(groupedData)) : Object.keys(groupedData);

    return keysToInclude.map((key) => {
      const group = groupedData[key];

      return {
        label: key + (viewPerspective === ViewPerspective.ManagerReports ? '\'s Direct Reports' : ''),
        data: group.map((slice) => ({
          x: formattedSurveyDate(slice, surveyDateCounts),
          y: slice.scores.score,
          participantInfo: `${slice.surveysAnswered}/${slice.surveysRequested} Participants`,
        })),
      };
    });
  }

  return [{ label: 'Undefined', data: [] }];
}

interface MakeEngagementGraphDataParams {
  dataSlice: ExecutiveEngagementDataForDate[],
  groupedData?: Record<string, GroupedEngagementData[]>,
  includedCategories?: string[],
  engagementCategory?: keyof EngagementScores,
  userPerspective: UserPerspective,
  viewPerspective: ViewPerspective,
}

export function makeEngagementGraphData({
  dataSlice,
  groupedData = {},
  includedCategories,
  engagementCategory,
  userPerspective,
  viewPerspective,
}: MakeEngagementGraphDataParams): GraphDataset[] {
  const engagementCategoriesToShow = engagementCategory ? [engagementCategory] : Object.keys(EngagementCategory);
  const surveyDateCounts = countBy(dataSlice, 'surveyDate');

  if (userPerspective === UserPerspective.manager || viewPerspective === ViewPerspective.Company) {
    return engagementCategoriesToShow.map((cat) => ({
      label: EngagementCategory[cat as keyof typeof EngagementCategory],
      data: dataSlice.map((slice) => ({
        x: formattedSurveyDate(slice, surveyDateCounts),
        y: slice.averages[cat as keyof EngagementScores],
        participantInfo: `${slice.surveysAnswered}/${slice.surveysRequested} Participants`,
      })),
    }));
  }

  if (viewPerspective === ViewPerspective.Departments
    || viewPerspective === ViewPerspective.ManagerReports
    || viewPerspective === ViewPerspective.UserGroups) {
    const keysToInclude = includedCategories?.length ? intersection(includedCategories, Object.keys(groupedData)) : Object.keys(groupedData);

    return keysToInclude.map((key) => {
      const group = groupedData[key];

      return {
        label: truncate(key + (viewPerspective === ViewPerspective.ManagerReports ? '\'s Direct Reports' : '')),
        data: group.map((slice) => ({
          x: formattedSurveyDate(slice, surveyDateCounts),
          y: slice.averages[engagementCategory ?? 'overall'] ?? 0,
          participantInfo: `${slice.surveysAnswered}/${slice.surveysRequested} Participants`,
        })),
      };
    });
  }

  return [{ label: 'Undefined', data: [] }];
}

export function makeGroupedDeltas(mergedData: Record<string, number[]>): GroupedDelta[] {
  return Object.entries(mergedData).map(([label, values]) => ({
    label,
    value: makeDelta(values[0], values[values.length - 1]),
  }));
}

export interface GraphDataset {
  label: string,
  data: Array<{
    participantInfo?: string | undefined;
    x: string,
    y: number,
  }>,
}
