import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { learningTemplatesQueryKeys } from '../queryKeys';

export interface LearningTemplateDetail {
  categoryId: string,
  categoryName: string,
  templateId: string,
  contentURL: string
  ownerId: string,
  title: string,
  isDeleted: boolean,
  questions: LearningTemplateQuestion[],
  canSetSourceTitle?: boolean,
  sourceTitle?: string,
}

interface LearningTemplateQuestion {
  questionId: string,
  rank: string,
  questionText: string
}

const getLearningTemplate = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<LearningTemplateDetail>> => {
  const [templateId] = queryKey.slice(-1);

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/${templateId}`;

  return getApi<LearningTemplateDetail>(serverUrl);
};

interface UseGetLearningTemplateParams {
  templateId: string,
  enabled?: boolean,
}

interface LearningTemplateHookReturn {
  detail: LearningTemplateDetail | undefined,
  isLoading: boolean,
}

export const useGetLearningTemplate = ({
  templateId,
  enabled = true,
}: UseGetLearningTemplateParams): LearningTemplateHookReturn => {
  const result = useQuery({
    queryKey: learningTemplatesQueryKeys.singleContentDetail(templateId),
    queryFn: getLearningTemplate,
    enabled: enabled && !!templateId,
  });

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
