import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';

interface ViewProps extends Omit<LeadrButtonProps<'button'>, 'data-test-id'> {
  isEdit: boolean,
  isMobile: boolean,
}

const CancelButton = ({
  isEdit,
  isMobile,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrButton
    data-test-id={isEdit ? 'meetingTopicSuggestionsCancelEditTopicTemplate' : 'meetingTopicSuggestionsCancelNewTopicTemplate'}
    variant="ghost"
    size={isMobile ? 'small' : 'standard'}
    {...props}
  >
    Cancel
  </LeadrButton>
);

export default CancelButton;
