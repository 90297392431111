import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useDispatch } from 'react-redux';
import { meetingRightPanelHeader, meetingRightPanelHeaderButton } from '~Meetings/const/sharedStyles';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { teamMapDrawerTemplate } from '~Meetings/components/details/WorkingGeniusMap/TeamMapDrawer';
import { useUrlQuery } from '~Common/hooks/useUrlQuery';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useGetWorkingGeniusMap } from '~Meetings/hooks/useGetWorkingGeniusMap';
import Wonder from '~Meetings/assets/images/workingGenius/wonder.svg';
import Invention from '~Meetings/assets/images/workingGenius/invention.svg';
import Discernment from '~Meetings/assets/images/workingGenius/discernment.svg';
import Galvanizing from '~Meetings/assets/images/workingGenius/galvanizing.svg';
import Enablement from '~Meetings/assets/images/workingGenius/enablement.svg';
import Tenacity from '~Meetings/assets/images/workingGenius/tenacity.svg';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useMemo } from 'react';
import WorkingGeniusGear from './WorkingGeniusGear';

const styles = {
  workingGeniusMap: css({}),
  imagesContainer: css({
    position: 'relative',
    height: '4rem',
  }),
  wonder: css({
    left: '0',
    top: '0',
  }),
  invention: css({
    left: '2rem',
    bottom: '0',
  }),
  discernment: css({
    left: '4.125rem',
    top: '0',
  }),
  galvanizing: css({
    left: '6.125rem',
    bottom: '0',
  }),
  enablement: css({
    left: '8.25rem',
    top: '0',
  }),
  tenacity: css({
    left: '10.25rem',
    bottom: '0',
  }),
  skeletonLoader: css({
    maxWidth: '100%',
    height: '4rem',
  }),
};

interface GeniusCounts {
  wonder: number,
  invention: number,
  discernment: number,
  galvanizing: number,
  enablement: number,
  tenacity: number,
}

interface ViewProps {
  handleViewAllClick: () => void,
  geniusCounts: GeniusCounts,
  areWorkingGeniusSectionsLoading: boolean,
}

const View = ({
  handleViewAllClick,
  geniusCounts,
  areWorkingGeniusSectionsLoading,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.workingGeniusMap}
    data-test-id="meetingsDetailsWorkingGeniusMap"
    {...props}
  >
    <div css={meetingRightPanelHeader}>
      <p>
        Team Working Genius
      </p>
      <LeadrButton
        css={meetingRightPanelHeaderButton}
        variant="text"
        data-test-id="meetingsDetailsWorkingGeniusMap"
        onClick={handleViewAllClick}
      >
        View all
      </LeadrButton>
    </div>
    <div css={styles.imagesContainer}>
      {areWorkingGeniusSectionsLoading && (
        <SkeletonLoader
          css={styles.skeletonLoader}
          renderComponent={() => <></>}
        />
      )}
      {!areWorkingGeniusSectionsLoading && (
        <>
          <WorkingGeniusGear
            geniusCount={geniusCounts.wonder}
            css={styles.wonder}
            renderImage={(imageStyles) => (
              <Wonder
                css={imageStyles}
                title="Wonder gear"
                data-test-id="meetingsDetailsWorkingGeniusMapWonder"
              />
            )}
            geniusName="Wonder"
          />
          <WorkingGeniusGear
            geniusCount={geniusCounts.invention}
            css={styles.invention}
            renderImage={(imageStyles) => (
              <Invention
                css={imageStyles}
                title="Invention gear"
                data-test-id="meetingsDetailsWorkingGeniusMapInvention"
              />
            )}
            geniusName="Invention"
          />
          <WorkingGeniusGear
            geniusCount={geniusCounts.discernment}
            css={styles.discernment}
            renderImage={(imageStyles) => (
              <Discernment
                css={imageStyles}
                title="Discernment gear"
                data-test-id="meetingsDetailsWorkingGeniusMapDiscernment"
              />
            )}
            geniusName="Discernment"
          />
          <WorkingGeniusGear
            geniusCount={geniusCounts.galvanizing}
            css={styles.galvanizing}
            renderImage={(imageStyles) => (
              <Galvanizing
                css={imageStyles}
                title="Galvanizing gear"
                data-test-id="meetingsDetailsWorkingGeniusMapGalvanizing"
              />
            )}
            geniusName="Galvanizing"
          />
          <WorkingGeniusGear
            geniusCount={geniusCounts.enablement}
            css={styles.enablement}
            renderImage={(imageStyles) => (
              <Enablement
                css={imageStyles}
                title="Enablement gear"
                data-test-id="meetingsDetailsWorkingGeniusMapEnablement"
              />
            )}
            geniusName="Enablement"
          />
          <WorkingGeniusGear
            geniusCount={geniusCounts.tenacity}
            css={styles.tenacity}
            renderImage={(imageStyles) => (
              <Tenacity
                css={imageStyles}
                title="Tenacity gear"
                data-test-id="meetingsDetailsWorkingGeniusMapTenacity"
              />
            )}
            geniusName="Tenacity"
          />
        </>
      )}
    </div>
  </div>
);

const WorkingGeniusMap = ({ ...props }): JSX.Element => {
  const dispatch = useDispatch();

  const location = useUrlQuery();
  const { meetingId } = location ?? {};

  // @ts-expect-error id is there
  const { item, isLoading: areMeetingDetailsLoading } = useMeetingDetails({ id: meetingId, type: MeetingTypeEnum.MEETING }) ?? {};

  const meetingOrgUserIdList = useMemo(() => {
    if (item) {
      return [...item.attendeeOrgUserIds, item.organizer.orgUserId];
    }

    return [];
  }, [item]);

  const { data, isLoading } = useGetWorkingGeniusMap({ meetingOrgUserIdList });

  const [areWorkingGeniusSectionsLoading] = useSkeletonLoaders(isLoading || areMeetingDetailsLoading);

  const workingGeniusMapData = data?.response;

  const geniusCounts = {
    wonder: workingGeniusMapData?.Wonder.geniusOrgUserIdList.length || 0,
    invention: workingGeniusMapData?.Invention.geniusOrgUserIdList.length || 0,
    discernment: workingGeniusMapData?.Discernment.geniusOrgUserIdList.length || 0,
    galvanizing: workingGeniusMapData?.Galvanizing.geniusOrgUserIdList.length || 0,
    enablement: workingGeniusMapData?.Enablement.geniusOrgUserIdList.length || 0,
    tenacity: workingGeniusMapData?.Tenacity.geniusOrgUserIdList.length || 0,
  };

  const handleViewAllClick = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...teamMapDrawerTemplate,
      },
    }));
  };

  const hookProps = {
    handleViewAllClick,
    geniusCounts,
    areWorkingGeniusSectionsLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default WorkingGeniusMap;
