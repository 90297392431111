import { Draft } from '~Common/hooks/useDraft';
import { getStorageItem, removeStorageItem } from '~Common/utils/localStorage';

const isDraftExpired = (draft: Draft): boolean => {
  let expiryTime = draft.expiry?.time;

  if (!draft.expiry?.absolute && expiryTime && draft.lastAccessed) {
    expiryTime = draft.lastAccessed + expiryTime;
  }

  return !!expiryTime && expiryTime < Date.now();
};

const removeUnusedDraftItem = (key: string): void => {
  const draft = JSON.parse(getStorageItem(key) ?? '{}') as Draft;

  try {
    if (isDraftExpired(draft)) {
      removeStorageItem(key);
    }
  } catch (e) {
    // eslint-disable-next-line no-console -- Added by Mike to diagnose crash.
    console.log(e);
  }
};

const useRemoveUnusedDrafts = (): void => {
  Object.keys(localStorage)
    // Filters out the storage keys that starts with 'draft-'
    .filter((key) => key.includes('draft-')).forEach((draftKey) => {
      removeUnusedDraftItem(draftKey);
    });
};

export default useRemoveUnusedDrafts;
