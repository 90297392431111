import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { AssignedLearning } from '~Learning/const/interfaces';
import { assignedLearningQueryKeys } from './queryKeys';

const getAssignedLearnings = async (): Promise<AssignedLearning[]> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/requested/list`;

  const result = await getApi<{learnings: AssignedLearning[]}>(serverUrl);

  return result.response.learnings;
};

export const useGetAssignedLearnings = (): UseQueryResult<AssignedLearning[]> => useQuery({
  queryKey: assignedLearningQueryKeys.list(),
  queryFn: getAssignedLearnings,
});
