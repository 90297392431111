import {
  useCallback,
  KeyboardEvent,
} from 'react';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useDraft } from '~Common/hooks/useDraft';
import InputWithConfirmButtons from '~Common/components/InputWithConfirmButtons';
import Froala from '~Common/V3/components/Froala';
import { css } from '@emotion/react';
import { useShowItemEditor } from '../../stores/useShowItemEditor';

const styles = {
  labelOverrides: css({
    display: 'none',
  }),
  sizeOverrides: css({
    borderRadius: '0.5rem',
    '.fr-toolbar': {
      padding: '0.5rem',
      borderRadius: '0.5rem 0.5rem 0 0',

      '& .fr-btn-grp': {
        margin: '0',
      },
    },
    '.show-placeholder': {
      paddingTop: '0.5rem',
    },
    '.fr-placeholder': {
      display: 'none !important',
    },
  }),
};

interface ViewProps {
  handleSaveAgendaTopic: () => void,
  handleAgendaTopicTextChange: (value: string) => void
  handleCancelAgendaTopic: () => void,
  initialValue: string,
  editorText: string,
}

const View = ({
  handleSaveAgendaTopic,
  handleAgendaTopicTextChange,
  handleCancelAgendaTopic,
  initialValue,
  editorText,
  ...props
}: ViewProps): JSX.Element => (
  <InputWithConfirmButtons {...props}>
    <Froala
      labelStyleOverrides={styles.labelOverrides}
      styleOverrides={styles.sizeOverrides}
      froalaConfigProps={{
        heightMin: '3.125rem',
      }}
      enableEmojis
      name="Agenda Topic"
      froalaEventsProps={{
        autoFocus: true,
      }}
      richTextEditorProps={{
        name: 'text',
        onChange: ({ value: newText }) => handleAgendaTopicTextChange(newText),
        initialValue,
      }}
    />
    <InputWithConfirmButtons.SaveConfirmButtons
      dataTestIdPrefix="meetingTopicSuggestionsCreateTopic"
      handleSave={handleSaveAgendaTopic}
      handleCancel={handleCancelAgendaTopic}
      disableSaveButton={!editorText}
    />
  </InputWithConfirmButtons>
);

interface AgendaTopicTextEditorProps {
  topicLocalId: string,
  agendaTopicText?: string,
  onCancel?: () => void,
  onSave?: (topicText: string) => void,
}

const AgendaTopicTextEditor = ({
  topicLocalId,
  agendaTopicText,
  onCancel,
  onSave,
  ...props
}: AgendaTopicTextEditorProps): JSX.Element => {
  const organizationId = getOrganizationId();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const agendaTopicDraftKey = [organizationId!, 'templates', 'agendaItem', topicLocalId];
  const { draft, setDraft, removeDraft } = useDraft(agendaTopicDraftKey);

  const hideItemEditor = useShowItemEditor((state) => state.hideItemEditor);

  const handleSaveAgendaTopic = useCallback(() => {
    onSave?.(draft.value as string);
    hideItemEditor(topicLocalId);
    removeDraft();
  }, [draft.value, hideItemEditor, onSave, removeDraft, topicLocalId]);

  const handleAgendaTopicTextChange = useCallback((value: string) => {
    setDraft({
      ...draft,
      value,
    });
  }, [
    draft,
    setDraft,
  ]);

  const handleCancelAgendaTopic = useCallback(() => {
    onCancel?.();
    hideItemEditor(topicLocalId);
  }, [hideItemEditor, onCancel, topicLocalId]);

  const editorText = draft.value as string;

  const handleKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      if (editorText?.length > 0) {
        handleSaveAgendaTopic();
      }
    }
  }, [editorText?.length, handleSaveAgendaTopic]);

  const hookProps = {
    handleSaveAgendaTopic,
    handleAgendaTopicTextChange,
    handleCancelAgendaTopic,
    initialValue: editorText !== undefined ? editorText : agendaTopicText || '',
    editorText,
    handleKeyPress,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaTopicTextEditor;
