import { Route, Switch } from 'react-router-dom';

import { ACTION_ITEMS_COMPLETED, ACTION_ITEMS_OPEN } from '~Common/const/routes';
import ActionItems from '~ActionItems/components/ActionItems';
import ActionItemsHeader from '~ActionItems/components/ActionItemsHeader';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';

const ActionItemsRoutes = (): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[ACTION_ITEMS_OPEN]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<ActionItemsHeader initialRoute={ACTION_ITEMS_OPEN} />}
            ChildComponent={<ActionItems />}
          />
        </BaseLayout>
      )}
    />
    <Route
      exact
      path={[ACTION_ITEMS_COMPLETED]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<ActionItemsHeader initialRoute={ACTION_ITEMS_COMPLETED} />}
            ChildComponent={<ActionItems />}
          />
        </BaseLayout>
      )}
    />
    <SmartRedirect from="*" to={ACTION_ITEMS_OPEN} />
  </Switch>
);

export default ActionItemsRoutes;
