import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { MouseEvent } from 'react';
import { Dispatch } from 'redux';
import { OrgDetails } from '~Common/const/Organization';
import { hexToRGBA, palette } from '~Common/styles/colors';

export const NAVBAR_LEFT_PADDING = '1rem';
export const NAVBAR_HIGHLIGHT_COLOR = hexToRGBA(palette.brand.indigo, 0.05);

export interface NavigationItem {
  label: string,
  href?: string,
  icon: IconDefinition,
  children?: readonly NavigationItem[],

  isVisible?: (props: IsVisibleProps) => boolean, // default true
  isBeta?: boolean, // default false
  isExternalLink?: boolean, // default false
  hasIndicator?: (pendingData: NavigationIndicatorData) => boolean // default false
  onClick?: (props: OnClickProps) => void, // return value determines whether we preventDefault
}

export interface UserPermissions {
  isImpersonator: boolean,
  isCustomerSuccessManager: boolean,
  isCustomerSuccessAdmin: boolean,
}

export interface NavigationIndicatorData {
  pendingActionItems: number,
  pendingFeedback: number,
  pendingLearnings: number,
  pendingSurveys: number,
  pendingReviews: number,
}

export interface IsVisibleProps {
  isAdmin: boolean,
  isCollapsed: boolean,
  isMobile: boolean,
  canSeeInsights: boolean,
  canSeeNexus: boolean,
  canImpersonate: boolean,
  hasMultipleOrganizations: boolean,
  flags: FeatureFlags,
  organization: OrgDetails,
  organizationInTrial: boolean,
}

interface OnClickProps extends IsVisibleProps {
  dispatch: Dispatch,
  e: MouseEvent<HTMLButtonElement>,
}

// TODO: replace this when flags are typed
export type FeatureFlags = Record<string, number | boolean | string>;
