import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const wrapper = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const leftWrapper = css`
`;

const rightWrapper = css`
  display: flex;
  justify-content: flex-end;

  & button {
    margin-left: 50px;
  }
`;

/**
 * @deprecated Old Card UI, use packages/leadr-frontend/src/common/V3/components/Card.tsx instead
 */
function CardActionFooter({ leftAction, rightActions }) {
  return (
    <div
      css={wrapper}
    >
      <div
        css={leftWrapper}
      >
        {leftAction}
      </div>
      <div css={rightWrapper}>
        {rightActions}
      </div>
    </div>
  );
}

CardActionFooter.propTypes = {
  leftAction: PropTypes.element,
  rightActions: PropTypes.arrayOf(PropTypes.element),
};

CardActionFooter.defaultProps = {
  leftAction: (<div css={css`width: 40px;`}> Left </div>),
  rightActions: [(<div css={css`width: 40px;`}> Right1 </div>), (<div css={css`width: 40px;`}> Right2 </div>)],
};

export default CardActionFooter;
