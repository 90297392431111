import { produce } from 'immer';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';
import { queryClient } from '~Common/const/queryClient';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { toast } from '~Common/components/Toasts';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { AgendaSection, AgendaTopic, GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';

interface Context {
  id: string,
  meetingId: string,
  type: 'AGENDA_ITEM',
}

interface DeleteAgendaCommentParams {
  context: Context,
  commentId: string,
}

const deleteAgendaComment = ({
  commentId,
  context,
}: DeleteAgendaCommentParams): Promise<HttpCallReturn<void>> => {
  const url = `/organizations/${getOrganizationId() ?? ''}/notes/${commentId}`;

  return deleteApi(url, { context }, { 'realtime-version': '2' });
};

interface UseDeleteAgendaCommentParams {
  sectionId?: string,
}

export const useDeleteAgendaComment = ({
  sectionId,
}: UseDeleteAgendaCommentParams): UseMutationResult<HttpCallReturn<void>, unknown, DeleteAgendaCommentParams> => useMutation({
  mutationFn: deleteAgendaComment,
  onMutate: async ({ commentId, context }) => {
    const { meetingId, id: topicId } = context;
    const previousAgendas = queryClient.getQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(meetingId));

    await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(meetingId) });

    queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(meetingId), (originalData) => {
      if (originalData) {
        if (sectionId) {
          const sectionIndex = originalData.response.agendas.findIndex((agenda) => agenda.id === sectionId);
          if (sectionIndex !== -1) {
            return produce(originalData, (draft) => {
              const agendaSection = draft.response.agendas[sectionIndex] as AgendaSection;
              const agendaTopicIndex = agendaSection.children.findIndex((topic) => topic.id === topicId);

              if (agendaTopicIndex !== -1) {
                const agendaTopic = agendaSection.children[agendaTopicIndex];
                const agendaCommentIndex = agendaTopic.children.findIndex((comment) => comment.id === commentId);

                if (agendaCommentIndex !== -1) {
                  agendaTopic.children.splice(agendaCommentIndex, 1);
                }
              }
            });
          }
        } else {
          const agendaTopicIndex = originalData.response.agendas.findIndex((topic) => topic.id === topicId);
          if (agendaTopicIndex !== -1) {
            return produce(originalData, (draft) => {
              const agendaTopic = draft.response.agendas[agendaTopicIndex] as AgendaTopic;

              const agendaCommentIndex = agendaTopic.children.findIndex((comment) => comment.id === commentId);

              if (agendaCommentIndex !== -1) {
                agendaTopic.children.splice(agendaCommentIndex, 1);
              }
            });
          }
        }
      }

      return originalData;
    });

    return { previousAgendas };
  },
  onError: (_, { context }, snapshot) => {
    toast.error(<MeetingsErrorToast />, {
      autoClose: 1500,
    });

    queryClient.setQueryData(meetingKeys.agendaList(context.meetingId), snapshot?.previousAgendas);
  },
  onSettled: (_, __, { context }) => {
    void queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(context.meetingId) });
  },
});
