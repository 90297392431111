import PropTypes from 'prop-types';

import { useEffect } from 'react';
import { DRAWER_STATE_SHAPE, SET_DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import usePeoplePicker from '~Common/hooks/usePeoplePicker';
import useSelectedAttendees from '~Deprecated/hooks/attendees/useSelectedAttendees';
import AddAttendeeDrawer from '~Deprecated/ui/components/DrawerManager/DrawerTemplates/AddAttendee/AddAttendeeDrawer/AddAttendeeDrawer';
import PeoplePickerDropdown from '~Deprecated/ui/components/PeoplePicker/PeoplePickerDropdown';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';

// TODO: Delete after feedback rewrite
/**
 * @deprecated Old People Drawer, use packages/leadr-frontend/src/common/components/Drawers/SelectParticipantsDrawer/index.jsx instead
 */
export const peopleDrawerTemplate = {
  name: 'ADD_PEOPLE_DRAWER',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};

const PeopleDrawer = ({
  useOrgIds,
  allowSelf,
  limit,
  drawerState,
  setDrawerState,
  popDrawer,
  selectedKey,
  defaultFilter,
  disableFilter,
  customFilter,
  disableUsers,
  filterOptions,
  onSelect,
  selectAllCallback,
  removeSelectedAttendees,
  currentAttendees,
  ...props
}) => {
  const {
    onSearch,
    isLoading,
    attendees,
    allAttendees,
    setSearchFilter,
    searchFilter,
  } = usePeoplePicker({ useOrgIds, allowSelf, customFilter });

  useEffect(() => {
    if (filterOptions) {
      setSearchFilter(filterOptions[0]);
    }
  }, [setSearchFilter, filterOptions]);

  if (defaultFilter && defaultFilter !== searchFilter) {
    setSearchFilter(defaultFilter);
  }

  let nonSelectedAttendees = [...attendees];

  if (removeSelectedAttendees) {
    nonSelectedAttendees = nonSelectedAttendees.filter((id) => !drawerState?.selectedAttendees?.includes(id));
  }

  const {
    isAttendeeSelected,
    onAttendeeSelect,
    toggleSelectAll,
    isSelectingAll,
  } = useSelectedAttendees({
    attendeeSelectCallback: onSelect,
    allAttendees,
    drawerState,
    setDrawerState,
    limit,
    selectedKey,
    allowSelf,
    disabledUsers: disableUsers,
    selectAllCallback,
    filteredAttendees: attendees,
    nonSelectedAttendees,
    removeSelectedAttendees,
  });

  const renderSearchBox = () => (
    <PeoplePickerDropdown
      onSearch={onSearch}
      setSearchFilter={setSearchFilter}
      currentFilter={searchFilter}
      disableFilter={disableFilter || customFilter?.length > 0}
    />
  );
  const closeCurrentDrawer = () => popDrawer({
    drawerName: peopleDrawerTemplate.name,
  });

  return (
    <AddAttendeeDrawer
      selectedAttendees={currentAttendees}
      onSearch={onSearch}
      isLoading={isLoading}
      attendees={nonSelectedAttendees}
      isAttendeeSelected={isAttendeeSelected}
      onAttendeeSelect={onAttendeeSelect}
      toggleSelectAll={toggleSelectAll}
      isSelectingAll={isSelectingAll}
      closeDrawer={closeCurrentDrawer}
      limit={limit}
      renderSearchBox={renderSearchBox}
      disableUsers={disableUsers}
      {...props}
    />
  );
};

PeopleDrawer.propTypes = {
  useOrgIds: PropTypes.bool,
  allowSelf: PropTypes.bool,
  limit: PropTypes.number,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: SET_DRAWER_STATE_SHAPE.isRequired,
  popDrawer: PropTypes.func.isRequired,
  selectedKey: PropTypes.string,
  disableFilter: PropTypes.bool,
  onSelect: PropTypes.func,
  defaultFilter: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  customFilter: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  disableUsers: PropTypes.arrayOf(PropTypes.object),
  filterOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  selectAllCallback: PropTypes.func,
  removeSelectedAttendees: PropTypes.bool,
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
};

PeopleDrawer.defaultProps = {
  useOrgIds: true,
  allowSelf: true,
  limit: null,
  selectedKey: 'selectedAttendees',
  disableFilter: false,
  defaultFilter: null,
  customFilter: [],
  disableUsers: [],
  filterOptions: [],
  onSelect: null,
  selectAllCallback: null,
  removeSelectedAttendees: false,
  currentAttendees: [],
};

registerDrawer({ templateName: peopleDrawerTemplate.name, component: PeopleDrawer });

export default PeopleDrawer;
