import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerInput from '~Common/V3/components/DrawerInput';
import { SURVEY_MULTIPLE_CHOICE_SHAPE } from '~Common/const/proptypes';
import { hexToRGBA, palette } from '~Common/styles/colors';

const styles = {
  editOption: css`
    padding-right: 0px;
  `,
  iconButton: css`
    padding: 0 8px;

    svg {
      margin-right: 0;
    }
  `,
  cancelButton: css`
  `,
  saveButton: css`
  `,
  createOption: css`
  margin-bottom: 10px;
  background: ${hexToRGBA(palette.neutrals.gray50, 0.6)};
  border-radius: 8px;
  font-weight: 400;
  border: none;
  color: ${palette.neutrals.gray900}

  & input{
    font-size: .875rem;
    font-weight: 400;
  }
`,
  optionButtons: css`
    margin-right:.5rem;
  `,
};

const View = ({
  onEditSave, onEditCancel, editText, setEditText, index, ...props
}) => (
  <>
    <DrawerInput
      {...props}
      css={styles.createOption}
      initialValue={editText}
      onChange={(e) => setEditText(e.target.value)}
      label="Option Content"
    />
    <LeadrButton
      onClick={() => onEditSave(editText)}
      size="small"
      data-test-id="surveysEditMultipleChoiceOptionSave"
    >
      Save
    </LeadrButton>
    <LeadrButton
      css={styles.optionButtons}
      size="small"
      variant="ghost"
      onClick={onEditCancel}
    >
      Cancel
    </LeadrButton>
  </>
);

View.propTypes = {
  onEditSave: PropTypes.func.isRequired,
  onEditCancel: PropTypes.func.isRequired,
  editText: PropTypes.string.isRequired,
  setEditText: PropTypes.string.isRequired,
  index: PropTypes.number,
};

View.defaultProps = {
  index: 0,
};

const EditMultipleChoiceOption = ({ option, ...props }) => {
  const [editText, setEditText] = useState(option.option);

  const hookProps = {
    editText,
    setEditText,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

EditMultipleChoiceOption.propTypes = {
  option: SURVEY_MULTIPLE_CHOICE_SHAPE.isRequired,
};

EditMultipleChoiceOption.defaultProps = {};

export { View };
export default EditMultipleChoiceOption;
