import { css } from '@emotion/react';
import {
  DataGrid,
  GridColumns,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import { palette } from '~Common/styles/colors';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import Pagination from '~Common/V3/components/Pagination';
import { useCallback, useEffect, useState } from 'react';
import { UsePaginationReturn, usePagination } from '~Common/hooks/usePagination';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useDispatch } from 'react-redux';
import { navigateAction } from '~Deprecated/actions/navigate';
import { TEAM_DETAILS_USERS } from '~Common/const/routes';
import { toTitleCase } from '~Common/utils';
import { TeamRow, TeamRowAllowedAction, TeamsListScope } from '../../const/types';
import NameCell from './NameCell';
import ActionMenu from './ActionMenu';
import { useGetTeams } from '../../hooks/useGetTeams';
import { useTeamRowDisplayCount } from '../../stores/useTeamRowDisplayCount';
import EmptyState from './EmptyState';

const styles = {
  table: css({
    color: palette.neutrals.gray800,
    border: 0,

    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '.MuiDataGrid-columnHeaders': {
      backgroundColor: palette.neutrals.gray100,
      fontWeight: 600,
      border: 0,
      borderRadius: 0,
    },
  }),
  skeletonLoader: css({
    maxWidth: '100%',
    height: '15rem',
    transform: 'initial',
  }),
  pagination: css({
    marginTop: '1.5rem',
  }),
};

interface ViewProps extends UsePaginationReturn {
  pageSize: number,
  handleCellClick: GridEventListener<'cellClick'>,
  columns: GridColumns<TeamRow>,
  rows: TeamRow[] | undefined,
  areTeamsLoading: boolean,
  page: number,
  listScope: TeamsListScope,
}

const View = ({
  pageSize,
  handleCellClick,
  columns,
  rows,
  areTeamsLoading,
  page,
  numberOfPages,
  onPageChange,
  onPreviousClick,
  onNextClick,
  listScope,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    {areTeamsLoading && (
      <SkeletonLoader
        css={styles.skeletonLoader}
        renderComponent={() => <></>}
      />
    )}
    {!areTeamsLoading && rows && (
      <>
        <DataGrid
          css={styles.table}
          autoHeight
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          sortingMode="server"
          headerHeight={30}
          rowHeight={46}
          hideFooter
          pageSize={pageSize}
          rows={rows}
          onCellClick={handleCellClick}
          getRowId={(row) => row.teamId}
          components={{
            NoRowsOverlay: () => <EmptyState listScope={listScope} />,
          }}
        />
        {rows.length > 0 && (
          <Pagination
            css={styles.pagination}
            page={page}
            onPageChange={onPageChange}
            numberOfPages={numberOfPages}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
          />
        )}
      </>
    )}
  </div>
);

interface TeamsTableProps {
  listScope: TeamsListScope,
}

const TeamsTable = ({
  listScope,
  ...props
}: TeamsTableProps): JSX.Element => {
  const isMobile = useIsMobileQuery();
  const [page, setPage] = useState(1);
  const pageSize = useTeamRowDisplayCount((state) => state.count);
  const dispatch = useDispatch();
  const { data, isLoading } = useGetTeams({
    // The display page number and index page number are differed by 1
    page: page - 1,
    count: pageSize,
    listScope,
  });

  // If page size changes, set them back to first page, since changing page size can result in an empty page
  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  // If list scope changes, set them back to the first page, since we don't know how many pages the new scope has
  useEffect(() => {
    setPage(1);
  }, [listScope]);

  const {
    numberOfPages,
    onPageChange,
    onPreviousClick,
    onNextClick,
  } = usePagination({
    totalCount: data?.response?.totalTeamCount ?? 0,
    pageSize,
    page,
    setPage,
  });

  const [areTeamsLoading] = useSkeletonLoaders(isLoading);

  const teams = data?.response.teams;

  const navigateToTeamDetails = useCallback((teamId: string): void => {
    dispatch(navigateAction({
      pathname: TEAM_DETAILS_USERS,
      params: {
        teamId,
      },
    }));
  }, [dispatch]);

  const handleCellClick: GridEventListener<'cellClick'> = (params): void => {
    if (params.field !== 'allowedActions') {
      navigateToTeamDetails(params.id.toString());
    }
  };

  const columns: GridColumns<TeamRow> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: isMobile ? 1 : 0.5,
      sortable: false,
      renderCell: (params: GridRenderCellParams<TeamRowAllowedAction[], TeamRow>) => {
        const { hasOwner, name } = params.row;

        return (
          <NameCell
            hasTeamOwner={hasOwner}
            name={name}
          />
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      hide: isMobile,
      sortable: false,
    },
    {
      field: 'isOwner',
      headerName: 'Role',
      minWidth: 25,
      sortable: false,
      hide: listScope === TeamsListScope.AllTeams,
      renderCell: (params: GridRenderCellParams<TeamRowAllowedAction[], TeamRow>) => {
        const { isOwner } = params.row;

        const userRoleString = isOwner ? 'Owner' : 'Member';

        return (
          <div>
            {userRoleString}
          </div>
        );
      },
    },
    {
      field: 'memberCount',
      headerName: 'Size',
      minWidth: 25,
      hide: listScope === TeamsListScope.MyTeams && isMobile,
      sortable: false,
    },
    {
      field: 'visibilityType',
      headerName: 'Type',
      minWidth: 25,
      sortable: false,
      hide: isMobile,
      renderCell: (params: GridRenderCellParams<TeamRowAllowedAction[], TeamRow>) => {
        const { visibilityType } = params.row;

        // Convert to Proper case (example: 'Private' or 'Public')
        const visibilityString = toTitleCase(visibilityType);

        return (
          <div>
            {visibilityString}
          </div>
        );
      },
    },
    {
      field: 'allowedActions',
      headerName: '',
      maxWidth: 32,
      renderCell: (params: GridRenderCellParams<TeamRowAllowedAction[], TeamRow>) => (
        <ActionMenu
          allowedActions={params.row.allowedActions}
          teamId={params.row.teamId}
          navigateToTeamDetails={navigateToTeamDetails}
        />
      ),
    },
  ];

  const rows = teams;

  const hookProps = {
    pageSize,
    handleCellClick,
    columns,
    rows,
    areTeamsLoading,
    page,
    numberOfPages,
    onPageChange,
    onPreviousClick,
    onNextClick,
    listScope,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TeamsTable;
