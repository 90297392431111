import { SerializedStyles, css } from '@emotion/react';
import ListSection from '~Common/V3/components/ListSection';
import { TextField } from '~Common/V3/components/uncontrolled';
import Froala from '~Common/V3/components/Froala';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { FormValues } from '~Goals/schemata/CreateGoalSchemata';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { DEFAULT_STARTDATE_ENDDATE_SPREAD } from '~Goals/const/defaults';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FORM_STYLES } from '~Goals/const/styles';
import { Goals } from '@leadr-hr/types';
import { SelectOption } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TeamsListScope } from '~People/components/Teams/const/types';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { palette } from '~Common/styles/colors';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { SingleParticipantSelect } from '~Common/V3/components/SingleParticipantSelect';
import { ParticipantAndRoleSelector } from '~Common/V3/components/ParticipantAndRoleSelector';
import { GoalShape } from '~Goals/const/types';
import { useAddResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { GoalTypeSelection } from './GoalTypeSelection';
import { GoalDateSelection } from './GoalDateSelection';
import { PrioritySelection } from './PrioritySelection';

const styles = {
  ...FORM_STYLES,
  namePriorityContainer: (isMobile: boolean) => css({
    display: 'flex',
    gap: '1.5rem',
    flexDirection: 'row',
    marginBottom: '.75rem',
    '.MuiFormLabel-filled': {
      color: `${palette.neutrals.gray700} !important`,
      fontWeight: 400,
    },
  }, isMobile && {
    flexDirection: 'column',
    gap: '0.75rem',
  }),
  title: css({
    flex: 1,
    marginBottom: 0,
    minWidth: '20rem',
    '.MuiInputBase-input': {
      padding: '1.225rem 1rem 0.5rem 1rem',
    },
    label: {
      top: '1rem',
    },
  }),
  requiredIcon: css({
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
  }),
};

type GoalParticipant = {
  orgUserId: string,
  role: string,
};

interface ViewProps {
  areTeamsLoading: boolean,
  canViewTableGroup: boolean,
  endDate: Date,
  formContext: UseFormReturn<FormValues>,
  goal: Goals.Requests.CreateGoalRequestPayload,
  handleOwnerChange: (newOrgUserId: string) => void,
  isEdit: boolean,
  isMobile: boolean,
  showExternalLink: boolean,
  startTimeInMillis: number | undefined,
  teamIdSelected: string | undefined,
  teamsList: SelectOption[],
  toggleExternalLink: () => void,
  overrideStyles?: SerializedStyles | SerializedStyles[],
  hideHeader: boolean,
  allowedContextTypes?: Goals.GoalContextType[],
}

const View = ({
  areTeamsLoading,
  canViewTableGroup,
  endDate,
  formContext,
  goal,
  handleOwnerChange,
  isEdit,
  isMobile,
  showExternalLink,
  startTimeInMillis,
  teamIdSelected,
  teamsList,
  toggleExternalLink,
  overrideStyles,
  hideHeader,
  allowedContextTypes,
}: ViewProps): JSX.Element => (
  <div css={[styles.setupContainer(isMobile), styles.boxShadow, overrideStyles]}>
    <ListSection
      css={styles.sectionHeader(hideHeader)}
      renderContents={() => (
        <div>
          {isEdit ? 'Edit Goal' : 'Create Goal'}
        </div>
      )}
    />
    <p css={styles.subTitle}>What is a goal or key result you want to accomplish?</p>
    <div
      css={styles.namePriorityContainer(isMobile)}
    >
      <TextField
        name="title"
        label="Goal Title"
        css={[styles.fullWidth, styles.formSpacing, styles.title]}
        data-test-id="goalsTitle"
        placeholder="Enter goal name"
        required
      />
      <PrioritySelection
        goal={goal}
      />
    </div>
    <Froala
      styleOverrides={styles.froalaSpacing}
      enableEmojis={!isMobile}
      label="Description"
      name="description"
      froalaConfigProps={{
        charCounterCount: true,
        charCounterMax: 5000,
        placeholderText: !isMobile ? 'Add context to this goal. Why is it important? How will you define success?' : '',
      }}
      richTextEditorProps={{
        name: 'description',
        onChange: ({ value: newText }) => formContext.setValue('description', newText, { shouldDirty: true }),
        initialValue: goal.description,
      }}
    />
    <div
      css={[styles.formBorderSpacer, styles.formSpacing]}
    >
      {!showExternalLink && (
        <LeadrButton
          data-test-id="goalsAddExternalLink"
          variant="text"
          onClick={toggleExternalLink}
        >
          + add external link
        </LeadrButton>
      )}
      <TextField
        name="externalLink"
        label="External Link"
        placeholder="https://app.leadr.com/"
        css={[styles.fullWidth, styles.formSpacing, styles.formInput(!showExternalLink)]}
      />
    </div>
    <div
      css={[styles.formSpacing, styles.formBorderSpacer, styles.formDoubleLayout(isMobile)]}
    >
      <GoalDateSelection
        formContext={formContext}
        goal={goal}
        endDate={endDate}
        startTimeInMillis={startTimeInMillis}
        isMobile={isMobile}
      />
    </div>
    <div
      css={[styles.formSpacing, styles.formBorderSpacer, styles.fullWidth]}
    >
      <GoalTypeSelection
        isEdit={isEdit}
        goal={goal}
        areTeamsLoading={areTeamsLoading}
        canViewTableGroup={canViewTableGroup}
        teamsList={teamsList}
        teamIdSelected={teamIdSelected}
        formContext={formContext}
        allowedContextTypes={allowedContextTypes}
      />
    </div>
    <div css={styles.formSpacing}>
      <SingleParticipantSelect
        label="Goal Owner"
        onChange={handleOwnerChange}
        value={formContext.watch('owner')}
      />
    </div>
    <ParticipantAndRoleSelector
      disabledIds={[formContext.watch('owner')]}
      label="Participants"
      onChange={(newValues: GoalParticipant[]) => formContext.setValue('participants', newValues)}
      participants={formContext.watch('participants')}
      roles={[
        {
          label: 'Collaborator',
          value: Goals.GoalParticipantRole.Collaborator,
        },
        {
          label: 'Viewer',
          value: Goals.GoalParticipantRole.Viewer,
        },
      ]}
    />
  </div>
);

interface CreateGoalFormProps extends Pick<ViewProps, 'allowedContextTypes'> {
  formContext: UseFormReturn<FormValues>,
  goal: GoalShape,
  isEdit?: boolean,
  overrideStyles?: SerializedStyles | SerializedStyles[],
  hideHeader?: boolean,
}

export const CreateGoalForm = ({
  formContext,
  goal,
  isEdit = false,
  overrideStyles,
  hideHeader = false,
  allowedContextTypes = [Goals.GoalContextType.Personal, Goals.GoalContextType.Team, Goals.GoalContextType.Organization],
  ...props
}: CreateGoalFormProps): JSX.Element => {
  const isMobile = useIsMobileQuery();
  const [showExternalLink, setShowExternalLink] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const isTableGroupCobrandingEnabled = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const { enableTheTable } = useOrgDetailsContext();
  const canViewTableGroup = !!enableTheTable && isTableGroupCobrandingEnabled;

  const {
    pdpOwnerId,
    showAddResourceModal,
  } = useAddResourceModalStore((state) => ({
    pdpOwnerId: state.pdpOwnerId,
    showAddResourceModal: state.showAddResourceModal,
  }));

  const isPDPCreation = showAddResourceModal && pdpOwnerId !== '';
  const [endDate, setEndDate] = useState(goal?.endTimeInMillis
    ? goal.endTimeInMillis as unknown as Date
    : moment().add(DEFAULT_STARTDATE_ENDDATE_SPREAD, 'day') as unknown as Date);

  const toggleExternalLink = (): void => {
    if (showExternalLink && !initialLoad) {
      formContext.resetField('externalLink');
    }
    setShowExternalLink(!showExternalLink);
  };

  const watchedData = useWatch({
    control: formContext.control,
  });
  const {
    endTimeInMillis,
    startTimeInMillis,
    team: teamIdSelected,
  } = watchedData;

  useEffect(() => {
    if (startTimeInMillis && moment(endDate) <= moment(startTimeInMillis)) {
      setEndDate(moment(startTimeInMillis).valueOf() as unknown as Date);
      formContext.setValue('endTimeInMillis', startTimeInMillis);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeInMillis]);

  useEffect(() => {
    if (startTimeInMillis && endTimeInMillis && endTimeInMillis < startTimeInMillis) {
      formContext.setValue('endTimeInMillis', moment(startTimeInMillis).valueOf());
      setEndDate(moment(startTimeInMillis) as unknown as Date);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTimeInMillis]);

  const {
    isAdmin,
  } = useUserPermissions();

  const listScope = isAdmin ? TeamsListScope.AllTeams : TeamsListScope.MyTeams;
  const { data: teamsData, isLoading } = useGetTeams({
    // we want to get the first page of teams
    page: 0,
    count: 1000, // TODO: Need to make this NOT a predefined number - Should update this to pull everything or change the select box to be a searchfilter or something
    listScope,
  });
  const [areTeamsLoading] = useSkeletonLoaders(isLoading);
  const teams = teamsData?.response.teams ?? [];
  const teamsList = teams.map((team) => ({
    label: team.name,
    value: team.teamId,
  }));

  useEffect(() => {
    if (goal.externalLink && goal.externalLink !== '' && initialLoad) {
      setShowExternalLink(true);
    }
    if (!areTeamsLoading) {
      setInitialLoad(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal, areTeamsLoading]);

  const handleOwnerChange = (newOrgUserId: string): void => {
    const participants = formContext.getValues('participants') ?? [];
    const ownerIndex = participants?.findIndex((participant) => participant.orgUserId === newOrgUserId);

    if (ownerIndex !== -1) {
      const newParticipants = [...participants];
      newParticipants.splice(ownerIndex, 1);
      formContext.setValue('participants', newParticipants);
    }

    formContext.setValue('owner', newOrgUserId);
  };

  useEffect(() => {
    if (isPDPCreation) {
      formContext.setValue('owner', pdpOwnerId ?? '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPDPCreation]);

  const hookProps = {
    areTeamsLoading,
    canViewTableGroup,
    endDate,
    formContext,
    goal,
    handleOwnerChange,
    isEdit,
    isMobile,
    showExternalLink,
    startTimeInMillis,
    teamIdSelected,
    teamsList,
    toggleExternalLink,
    overrideStyles,
    hideHeader,
    allowedContextTypes,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
