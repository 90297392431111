import { useGenericStore } from '~Common/hooks/useGenericStore';

export enum ReviewUnsyncedMeetingsDrawerTab {
  In_Calendar = 'IN_CALENDAR',
  In_Leadr = 'IN_LEADR',
  Previously_Ignored = 'PREVIOUSLY_IGNORED',
}

export const useReviewUnsyncedMeetingsDrawerTab = (): [
  ReviewUnsyncedMeetingsDrawerTab,
  (reviewUnsyncedMeetingsTab: ReviewUnsyncedMeetingsDrawerTab) => void
] => {
  // TODO: Update this when zustand
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value: selectedTab, setValue: setSelectedTab } = useGenericStore({
    module: 'leadr',
    workflow: 'meetings',
    feature: 'review-unsynced-meetings',
    defaultValue: ReviewUnsyncedMeetingsDrawerTab.In_Calendar,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return [
    selectedTab,
    setSelectedTab,
  ];
};
