import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import HomeListItem from '~Home/components/common/HomeListItem';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import WhatsDueDropdown from '~Home/components/Module/WhatsDue/WhatsDueDropdown';
import WhatsDueEmptyState from '~Home/components/Module/WhatsDue/WhatsDueEmptyState';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { WhatsDueFilterOptions } from '~Home/const/index';
import { useWhatsDueFilter } from '~Home/hooks/useWhatsDueFilter';
import { useGetAllDueItems } from '~Home/hooks/useGetAllDueItems';
import { WhatsDueItem } from '~Home/const/interfaces';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import {
  ACTION_ITEMS_BASE_ROUTE,
  FEEDBACK_BASE_ROUTE,
  LEARNING_BASE_ROUTE,
  SURVEYS_BASE_ROUTE,
  REVIEWS,
} from '~Common/const/routes';

const styles = {
  dropDownContainer: css({
    width: '45%',
    '.MuiFormControl-root': {
      padding: '0 1rem',
    },
  }),
  itemsContainer: css({
    marginTop: '1rem',
  }),
};
interface WhatsDueViewInterface extends Omit<LeadrCardProps, 'children'> {
  headerText: string;
  filterValue: WhatsDueFilterOptions,
  routeToUse: string,
  allDueItems: WhatsDueItem[],
  itemsLoading: boolean,
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const View = ({
  headerText,
  filterValue,
  routeToUse,
  allDueItems,
  itemsLoading,
  dragHandleProps,
  ...props
}: WhatsDueViewInterface):JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <>
      <ModuleHeader
        {...dragHandleProps}
        headerText={headerText}
        renderRightSection={() => (
          <div css={styles.dropDownContainer}>
            <WhatsDueDropdown
              value={filterValue}
            />
          </div>
        )}
      />
      {itemsLoading && (
      <HomeSkeleton
        numberOfSkeletons={5}
        height={2.5}
      />
      )}
      {!itemsLoading && (
      <>
        { allDueItems.length === 0 && (
        <WhatsDueEmptyState
          type={filterValue}
        />
        )}
        <div css={styles.itemsContainer}>
          {allDueItems.map((item, index) => (
            <HomeListItem
              lastItem={index ? index === allDueItems.length - 1 : false}
              type={item.type}
              key={item.id}
              id={item.id}
              text={item.text}
              iconToRender={item.iconToRender}
              dueDate={item.dueDate}
              onItemClick={item.onItemClick}
              href={item.href}
              learningStatus={item.status}
              learningType={item.learningType}
            />
          ))}
        </div>
      </>
      )}
      {filterValue !== WhatsDueFilterOptions.AllDue && (
      <ModuleFooter
        renderFooterButton={(params) => (
          <LeadrButton
            data-test-id="viewAllWhatsDue"
            component={Link}
            to={routeToUse}
            variant="text"
            size="small"
            {...params}
          >
            View All
          </LeadrButton>
        )}
      />
      )}
    </>
  </LeadrCard>
);

export interface WhatsDueCardProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const WhatsDueCard = ({
  dragHandleProps,
  ...props
}: WhatsDueCardProps): JSX.Element => {
  const { whatsDueFilter } = useWhatsDueFilter();
  const { loading, allDueItems } = useGetAllDueItems();
  const [itemsLoading] = useSkeletonLoaders(loading);
  const filteredDueItems = useMemo(():WhatsDueItem[] => {
    if (whatsDueFilter === WhatsDueFilterOptions.AllDue) {
      return allDueItems;
    }
    return allDueItems.filter((item) => item.type === whatsDueFilter);
  }, [allDueItems, whatsDueFilter]);

  const routeToUse = useMemo(() => {
    switch (whatsDueFilter) {
      case WhatsDueFilterOptions.ActionItems:
        return ACTION_ITEMS_BASE_ROUTE;
      case WhatsDueFilterOptions.Feedback:
        return FEEDBACK_BASE_ROUTE;
      case WhatsDueFilterOptions.Surveys:
        return SURVEYS_BASE_ROUTE;
      case WhatsDueFilterOptions.Learning:
        return LEARNING_BASE_ROUTE;
      case WhatsDueFilterOptions.Reviews:
        return REVIEWS;
      default:
        return '';
    }
  }, [whatsDueFilter]);

  const hookProps = {
    headerText: 'What\'s Due',
    filterValue: whatsDueFilter,
    routeToUse,
    allDueItems: filteredDueItems.slice(0, 10),
    itemsLoading,
    dragHandleProps,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default WhatsDueCard;
