import { css } from '@emotion/react';
import { useCallback } from 'react';
import { faUserSecret } from '@fortawesome/pro-duotone-svg-icons';
import PropTypes from 'prop-types';
import { useImpersonation } from '~Deprecated/hooks/nexus/useImpersonation';
import { useImpersonationStore } from '~Deprecated/hooks/useImpersonationStore';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { forMobile } from '~Common/styles/mixins';
import { hexToRGBA, palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';

const footerButton = css`
  display: flex;
  justify-content: center;
  padding: 20px 0;
  z-index: 1200;

  position: sticky;
  bottom: 0;
  backdrop-filter: blur(3px);
  background: ${hexToRGBA(palette.neutrals.white, 0.7)};

  & button {
    margin: 0 18px;

    ${forMobile(`
      margin: 0 5px;
    `)}
  }
`;

/**
 * This component can be used anywhere as long as you pass in the email address.
 * By default the button will have an icon from fontawesome - https://fontawesome.com/v5.15/icons/user-secret?style=duotone
 * Child parameter is optional - You can pass this to have a component other than the default icon.
 * Other props will be passed down to the button component.
 *
 * @param email
 * @param child Optional
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 */
/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const ImpersonateButton = ({ email }) => {
  const { isImpersonating } = useImpersonationStore();
  const canImpersonate = useFeatureFlag('whiteHatImpersonator');
  const { isImpersonator } = useOrgUserPermissionsContext();
  const { mutate: initiateImpersonation, isPending: isLoading } = useImpersonation();

  const impersonate = useCallback(() => {
    initiateImpersonation(email.trim());
  }, [initiateImpersonation, email]);

  if (!email || !canImpersonate || !isImpersonator || isImpersonating) {
    return null;
  }

  return (
    <div css={footerButton}>
      <LeadrButton
        data-test-id="actionItemCompleteButton"
        onClick={impersonate}
        disabled={isLoading}
      >
        <LeadrButton.IconAndText
          icon={faUserSecret}
          text="Impersonate"
        />
      </LeadrButton>
    </div>
  );
};

ImpersonateButton.propTypes = {
  email: PropTypes.string.isRequired,
};

ImpersonateButton.defaultProps = {
};

export default ImpersonateButton;
