import { getOrganizationId } from '~Common/utils/localStorage';
import {
  getApi,
  HttpCallReturn,
} from '~Deprecated/services/HttpService';
import { buildQueryString } from '~Common/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { reflectionQueryKeys } from '../const/queryKeys';
import {
  Reflection, ReflectionsFavoriteType, ReflectionsIsActiveType, ReflectionType,
} from '../types';

export interface ReflectionsListQueryParams {
  search: string,
  reflectionType: ReflectionType[],
  favoriteStatus: ReflectionsFavoriteType[],
  isInActiveReview: ReflectionsIsActiveType[],
  index: number,
  limit: number,
  startDate: string,
  endDate: string,
}

export const favoriteStatusLookupTable = {
  [ReflectionsFavoriteType.All]: null,
  [ReflectionsFavoriteType.Favorited]: true,
  [ReflectionsFavoriteType.NotFavorited]: false,
};

export const isActiveLookupTable = {
  [ReflectionsIsActiveType.All]: null,
  [ReflectionsIsActiveType.Active]: true,
  [ReflectionsIsActiveType.NotActive]: false,
};

async function getReflectionsList({
  search,
  reflectionType,
  favoriteStatus,
  isInActiveReview,
  index,
  limit,
  startDate,
  endDate,
}: ReflectionsListQueryParams): Promise<HttpCallReturn<ReflectionListReturn>> {
  const queryString = buildQueryString({
    search,
    favoriteStatus: favoriteStatusLookupTable[favoriteStatus as unknown as ReflectionsFavoriteType] === null ? undefined
      : favoriteStatusLookupTable[favoriteStatus as unknown as ReflectionsFavoriteType],
    isInActiveReview: isActiveLookupTable[isInActiveReview as unknown as ReflectionsIsActiveType] === null ? undefined
      : isActiveLookupTable[isInActiveReview as unknown as ReflectionsIsActiveType],
    index,
    limit,
    startDate,
    endDate,
    reflectionType: reflectionType[0] === ReflectionType.All ? undefined : reflectionType,
  });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/reflections${queryString}`;

  return getApi<ReflectionListReturn>(serverUrl);
}

type UseGetReflectionsListParams = ReflectionsListQueryParams & { enabled?: boolean };

export interface ReflectionListReturn {
  currentPage: number,
  hasNext: boolean,
  hasPrevious: boolean,
  items?: Reflection[],
  pageSize: number,
  totalCount: number,
  totalPages: number,
}

interface UseGetReflectionsListReturn {
  isLoading: boolean,
  reflections?: Reflection[],
  totalPages: number,
}

export function useGetReflectionsList({
  search,
  reflectionType,
  favoriteStatus,
  isInActiveReview,
  index,
  limit,
  enabled,
  startDate,
  endDate,
}: UseGetReflectionsListParams): UseGetReflectionsListReturn {
  const result = useQuery({
    queryKey: reflectionQueryKeys.list({
      search,
      reflectionType,
      favoriteStatus,
      isInActiveReview,
      index,
      limit,
      startDate,
      endDate,
    }),
    queryFn: () => getReflectionsList({
      search,
      reflectionType,
      favoriteStatus,
      isInActiveReview,
      index,
      limit,
      startDate,
      endDate,
    }),
    enabled,
    placeholderData: keepPreviousData,
  });

  return {
    isLoading: result.isLoading,
    reflections: result?.data?.response?.items,
    totalPages: result?.data?.response?.totalPages ?? 0,
  };
}
