import { css } from '@emotion/react';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import { PersonDisplayInformation } from '~Common/const/interfaces';

import { palette } from '~Common/styles/colors';
import { withLineClamp, withTruncate } from '~Common/styles/mixins';
import { HTMLString } from '~Common/types';
import { isHTMLText } from '~Common/utils/isHTMLText';
import AvatarMaybeMultipleMaybeNot from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';

const styles = {
  leadrCard: css({
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    marginBottom: '.375rem',
  }),
  cardBody: (shouldShowAvatars: boolean) => css({
    display: 'grid',
    flex: 1,
    gridTemplateAreas: `
      "title title"
      "description avatars"
    `,
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'auto 1fr',
    columnGap: '.75rem',
  }, !shouldShowAvatars && {
    gridTemplateAreas: `
      "title"
      "description"
    `,
    gridTemplateColumns: '1fr',
  }),
  title: css({
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.1875rem',
    color: palette.neutrals.gray700,
    marginBottom: '.25rem',
    gridArea: 'title',
  }, withTruncate()),
  description: css({
    fontSize: '.75rem',
    gridArea: 'description',
    color: palette.neutrals.gray700,
    // These 2 lines are just for Safari because it has a hard time with line-clamp (https://stackoverflow.com/questions/70679732/css-line-clamp-does-not-work-in-safari-on-inner-block-level-elements/70683084#70683084)
    lineHeight: '1rem',
    maxHeight: '2rem',
  }, withLineClamp(2)),
  avatars: css({
    gridArea: 'avatars',
    justifySelf: 'end',
  }),
  leadrCardFooter: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '.625rem',
  }),
  avatar: css({
    '&:not(:last-child)': {
      marginRight: '.25rem',
    },
  }),
};

interface ViewProps extends Omit<LeadrCardProps, 'children'> {
  renderHeader?: () => JSX.Element,
  title: string,
  shouldShowAvatars: boolean,
  assignedUsersInfo?: PersonDisplayInformation[],
  numberOfUsersAssigned?: number,
  renderFooter: () => JSX.Element,
  feature: string,
  description: HTMLString,
  showDescription: boolean,
}

const View = ({
  renderHeader,
  title,
  shouldShowAvatars,
  assignedUsersInfo,
  numberOfUsersAssigned,
  renderFooter,
  feature, // Used for data-test-ids, right now only for title, but they can be added for any other things needed as well
  description,
  showDescription,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard
    css={styles.leadrCard}
    {...props}
  >
    {renderHeader && (
      <div css={styles.header}>{renderHeader()}</div>
    )}
    <div css={styles.cardBody(shouldShowAvatars)}>
      <div css={styles.title} data-test-id={`${feature}CardTitle`}>{title}</div>
      {showDescription && (
        <>
          {isHTMLText(description) && (
          <HTMLRenderer css={styles.description} htmlText={description} />
          )}
          {!isHTMLText(description) && (
          <div css={styles.description}>{description}</div>
          )}
        </>
      )}

      {shouldShowAvatars && (
        <AvatarMaybeMultipleMaybeNot
          css={styles.avatars}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          usersInfo={assignedUsersInfo!}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          numberOfUsers={numberOfUsersAssigned!}
          numberOfUsersToShow={3}
          avatarHeight={35}
          avatarWidth={35}
          renderAvatar={({ user, avatarHeight, avatarWidth }) => (
            <div css={styles.avatar}>
              <AvatarWithTooltip
                firstName={user.firstName}
                lastName={user.lastName}
                profileImageUrl={user.profileImageUrl}
                height={avatarHeight}
                width={avatarWidth}
              />
            </div>
          )}
          renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
            <OverflowAvatar
              css={styles.avatar}
              {...overflowAvatarProps}
            />
          )}
        />
      )}
    </div>
    <LeadrCard.Footer css={styles.leadrCardFooter}>
      {renderFooter()}
    </LeadrCard.Footer>
  </LeadrCard>
);

interface GridCardProps extends Omit<ViewProps, 'shouldShowAvatars' | 'descriptionHTML' | 'showDescription'> {
  description: string,
}

const GridCard = ({
  assignedUsersInfo,
  numberOfUsersAssigned,
  description,
  ...props
}: GridCardProps): JSX.Element => {
  const shouldShowAvatars = !!assignedUsersInfo && !!numberOfUsersAssigned;

  const hookProps = {
    shouldShowAvatars,
    assignedUsersInfo,
    numberOfUsersAssigned,
    description,
    showDescription: !!description,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default GridCard;
