import { css } from '@emotion/react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, MouseEvent } from 'react';

import ActionMenu, { MenuItem, useActionMenu } from '~Reviews/V2/Shared/ActionMenu';
import FlatButton from '~Common/V3/components/FlatButton';
import { palette } from '~Common/styles/colors';

const styles = {
  display: (display: boolean) => css({
    margin: '.5rem 0 -1.5rem 0',
  }, display && ({
    visibility: 'hidden',
  })),
  contextMenuContainer: css({
    color: palette.neutrals.gray500,
    borderRadius: '.1875rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& span': {
      fontSize: '.75rem',
      color: palette.neutrals.gray800,
      whiteSpace: 'nowrap',
      fontWeight: '400',
    },
    '& div': {
      background: '#F5F5F799',
      borderRadius: '.5rem',
      padding: '.375rem .75rem !important',
      marginLeft: '.5rem',
      '& span': {
        fontWeight: '500',
      },
    },
  }),
  icon: css({
    color: palette.brand.indigo,
  }),
};

interface ViewProps {
  doOpen: (event: React.MouseEvent<HTMLElement>) => void,
  menuItems: MenuItem[][],
  actionMenuProps: {
    anchorEle: HTMLElement | null;
    onClose: () => void;
    close?: () => void;
  },
  onClickCallback: () => void,
  label?: string,
  listEmptyText: string,
  activeFilters: string,
  disabled: boolean,
  subTitle?: string,
}

const View = ({
  doOpen,
  menuItems,
  actionMenuProps,
  label,
  listEmptyText,
  onClickCallback,
  activeFilters,
  disabled,
  subTitle,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <FlatButton
      css={styles.display(disabled)}
      onClick={doOpen}
      renderButtonContents={() => (
        <>
          <div css={styles.contextMenuContainer}>
            <span>{label}</span>
            <div>
              <span>{activeFilters.length ? activeFilters : listEmptyText}</span>
              {' '}
              <FontAwesomeIcon css={styles.icon} icon={faCaretDown} size="lg" />
            </div>
          </div>
        </>
      )}
    />
    <ActionMenu
      menuItems={menuItems}
      onClick={onClickCallback}
      subTitle={subTitle}
      {...actionMenuProps}
    />
  </div>
);

interface FauxDropdownProps {
  menuItems: MenuItem[][],
  label?: string,
  listEmptyText: string,
  activeFilters: string,
  disabled?: boolean,
  subTitle?: string,
}

const FauxDropdown = ({
  menuItems,
  label,
  listEmptyText,
  activeFilters,
  disabled = false,
  subTitle,
  ...props
}: FauxDropdownProps): JSX.Element => {
  const { doOpen, ...actionMenuProps } = useActionMenu();

  const onClickCallback = useCallback(() => {
    actionMenuProps.onClose();
  }, [actionMenuProps]);
  const hookProps = {
    doOpen,
    actionMenuProps,
    onClickCallback,
    menuItems,
    label,
    listEmptyText,
    activeFilters,
    disabled,
    subTitle,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default FauxDropdown;
