import { Goals } from '@leadr-hr/types';

export const allTeamMeetingGoalContextTypesValue = `${Goals.GoalContextType.Team},${Goals.GoalContextType.Organization}`;
export const allCoachingGoalContextTypesValue = `${Goals.GoalContextType.Personal},${Goals.GoalContextType.Team},${Goals.GoalContextType.Organization}`;

export const teamMeetingGoalContextTypes = [
  {
    value: allTeamMeetingGoalContextTypesValue,
    text: 'All Goals',
  },
  {
    value: Goals.GoalContextType.Team,
    text: 'Team Goals',
  },
  {
    value: Goals.GoalContextType.Organization,
    text: 'Organization Goals',
  },
];

export const coachingGoalContextTypes = [
  {
    value: allCoachingGoalContextTypesValue,
    text: 'All Goals',
  },
  {
    value: Goals.GoalContextType.Personal,
    text: 'Personal Goals',
  },
  {
    value: Goals.GoalContextType.Team,
    text: 'Team Goals',
  },
  {
    value: Goals.GoalContextType.Organization,
    text: 'Organization Goals',
  },
];

export const sortByItems: {
  value: Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title,
  text: string,
}[] = [
  {
    value: Goals.GetGoalsSortBy.DueDate,
    text: 'Due Date',
  },
  {
    value: Goals.GetGoalsSortBy.Title,
    text: 'Title',
  },
];

export const MEETING_MAX_GOALS_TO_SHOW = 10;

export const MEETING_GOAL_STATUSES_TO_GET = Object.values(Goals.GoalStatus).filter((status) => (
  status !== Goals.GoalStatus.Completed && status !== Goals.GoalStatus.Canceled
)).join(',');

export const INITIAL_STATIC_DEFINING_OBJECTIVE_GOALS_PARAMS: Goals.Requests.GetGoalsRequestQueryParameters = {
  contextType: Goals.GoalContextType.Team,
  take: MEETING_MAX_GOALS_TO_SHOW,
  sortBy: Goals.GetGoalsSortBy.DueDate,
  sortOrder: Goals.GetGoalsSortOrder.Ascending,
  status: MEETING_GOAL_STATUSES_TO_GET,
  category: Goals.GoalCategory.DefiningObjectives,
  isPrivate: false,
};

export const INITIAL_STATIC_OPERATING_OBJECTIVE_GOALS_PARAMS: Goals.Requests.GetGoalsRequestQueryParameters = {
  contextType: Goals.GoalContextType.Team,
  sortBy: Goals.GetGoalsSortBy.DueDate,
  sortOrder: Goals.GetGoalsSortOrder.Ascending,
  take: MEETING_MAX_GOALS_TO_SHOW,
  category: Goals.GoalCategory.StandardOperatingObjectives,
  isPrivate: false,
};

export const INITIAL_STATIC_TEAM_GOALS_PARAMS: Goals.Requests.GetGoalsRequestQueryParameters = {
  contextType: Goals.GoalContextType.Team,
  take: MEETING_MAX_GOALS_TO_SHOW,
  sortBy: Goals.GetGoalsSortBy.DueDate,
  sortOrder: Goals.GetGoalsSortOrder.Ascending,
  status: MEETING_GOAL_STATUSES_TO_GET,
  isPrivate: false,
};
