import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { AssignedPlaylistDetails, AssignedPlaylistLearning } from '~Learning/const/interfaces';
import Card, { CardSkeleton } from '~Common/V3/components/Card';
import ContentListCard from '~Learning/components/AssignedLearningDashboard/AssignedPlaylistDetailDrawer/Cards/ContentListCard';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { useRecallPlaylist } from '~Learning/hooks/assigned/useRecallPlaylist';
import { playlistParticipantsDrawerTemplate } from '../../PlaylistParticipantsDrawer';
import PlaylistDetailsCard from '../Cards/PlaylistDetailsCard';

const styles = {
  contentContainer: css({
    backgroundColor: palette.neutrals.white,
    margin: '1rem 0',
  }),
  contentSkeleton: css({
    minWidth: '100%',
    height: '12.5rem',
  }),
  cardSkeleton: css({
    minWidth: '100%',
    height: '6.875rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
  learningPlayingCards: css({}),
  participantsButton: css({
    marginRight: '.625rem',
  }),
  cardHeader: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  }),
  cardHeaderText: css({
    marginBottom: '.625rem',
    color: palette.neutrals.gray900,
    fontSize: '1.25rem',
    fontWeight: '600',
  }),
  button: css({
    display: 'flex',
    flexDirection: 'row',
    border: 0,
    background: 'transparent',
    color: palette.brand.indigo,
  }),
  buttonsContainer: css({
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
  }),
  buttonText: css({}),
  learningContentCard: css({}),
};

interface ViewProps {
  learningPlaylist: AssignedPlaylistDetails,
  areLearningPlaylistsLoading: boolean,
  onViewParticipantsClick: () => void,
  onCloseParticipantsClick: () => void,
  setAreLearningsHidden: (areLearningsHidden: boolean) => void,
  areLearningsHidden: boolean,
  theFirstFourLearnings: AssignedPlaylistLearning[],
  allLearnings: AssignedPlaylistLearning[],
  showViewParticipantsButton: boolean,
  onRecallPlaylist: () => void,
  canRecallFromAllParticipants: boolean,
}

const View = ({
  learningPlaylist,
  areLearningPlaylistsLoading,
  onViewParticipantsClick,
  onCloseParticipantsClick,
  setAreLearningsHidden,
  areLearningsHidden,
  theFirstFourLearnings,
  allLearnings,
  showViewParticipantsButton,
  onRecallPlaylist,
  canRecallFromAllParticipants,
}: ViewProps): JSX.Element => (
  <>
    <PlaylistDetailsCard
      title={learningPlaylist.title}
      descriptionHTML={learningPlaylist.description}
      status={learningPlaylist.status}
      numberOfUsersComplete={learningPlaylist.numberOfUsersComplete}
      numberOfUsersAssigned={learningPlaylist.numberOfUsersAssigned}
      dueDate={learningPlaylist.dueDate}
      onRecallPlaylist={onRecallPlaylist}
      canRecallFromAllParticipants={canRecallFromAllParticipants}
      playlistId={learningPlaylist.id}
    />
    <div css={styles.contentContainer}>
      {areLearningPlaylistsLoading && (
        <MultipleSkeletonLoaders
          numberOfSkeletons={4}
          renderSkeletonItem={() => (
            <CardSkeleton css={styles.cardSkeleton} />
          )}
        />
      )}
      {!areLearningPlaylistsLoading && (
        <Card
          css={styles.learningContentCard}
          renderContents={() => (
            <>
              <div css={styles.cardHeader}>
                <header css={styles.cardHeaderText}>
                  {`Playlist Content (${learningPlaylist.numberOfLearnings})`}
                </header>
                {learningPlaylist.numberOfLearnings > 4 && (
                  <button css={styles.button} onClick={() => setAreLearningsHidden(!areLearningsHidden)}>
                    <div css={styles.buttonText}>{areLearningsHidden ? 'Show More' : 'Show Less'}</div>
                  </button>
                )}
              </div>
              {areLearningsHidden && (
                <>
                  {theFirstFourLearnings.map((learning) => (
                    <ContentListCard
                      key={learning.id}
                      css={styles.learningPlayingCards}
                      contentTitle={learning.title}
                      contentUrl={learning.contentUrl}
                      introductionHTML={learning.introduction}
                      dueDate={learning.dueDate}
                      status={learning.status}
                    />
                  ))}
                </>
              )}
              {!areLearningsHidden && (
                <>
                  {allLearnings.map((learning) => (
                    <ContentListCard
                      key={learning.id}
                      css={styles.learningPlayingCards}
                      contentTitle={learning.title}
                      contentUrl={learning.contentUrl}
                      introductionHTML={learning.introduction}
                      dueDate={learning.dueDate}
                      status={learning.status}
                    />
                  ))}
                </>
              )}
            </>
          )}
        />
      )}
    </div>
    <div css={styles.buttonsContainer}>
      {showViewParticipantsButton && (
        <LeadrButton
          css={styles.participantsButton}
          onClick={onViewParticipantsClick}
          data-test-id="learningViewParticipants"
        >
          Open Participants
        </LeadrButton>
      )}
      {!showViewParticipantsButton && (
        <LeadrButton
          css={styles.participantsButton}
          onClick={onCloseParticipantsClick}
          data-test-id="learningCloseParticipants"
        >
          Close Participants
        </LeadrButton>
      )}
    </div>
  </>
);

interface DetailsTabProps {
  learningPlaylist: AssignedPlaylistDetails,
  showViewParticipantsButton: boolean,
}

const DetailsTab = ({
  learningPlaylist,
  showViewParticipantsButton,
  ...props
}: DetailsTabProps): JSX.Element => {
  const dispatch = useDispatch();

  const [areLearningsHidden, setAreLearningsHidden] = useState(learningPlaylist.numberOfLearnings > 4);

  const allLearnings = learningPlaylist.learnings;
  const theFirstFourLearnings = allLearnings.slice(0, 4);

  const { canRecallFromAllParticipants } = learningPlaylist;

  const recallPlaylistMutation = useRecallPlaylist();

  const onRecallPlaylist = useCallback(() => {
    recallPlaylistMutation(
      {
        userIds: learningPlaylist.assignedUsersInfo.map((learningAssignee) => learningAssignee.assignee.id),
        playlistId: learningPlaylist.id,
        isRecallingFromAll: true,
      },
    );
  }, [learningPlaylist.assignedUsersInfo, learningPlaylist.id, recallPlaylistMutation]);

  const hookProps = {
    areLearningsHidden,
    setAreLearningsHidden,
    theFirstFourLearnings,
    allLearnings,
    learningPlaylist,
    areLearningPlaylistsLoading: false,
    showViewParticipantsButton,
    onViewParticipantsClick: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...playlistParticipantsDrawerTemplate,
          args: {
            assignees: learningPlaylist.assignedUsersInfo,
            playlistId: learningPlaylist.id,
          },
        },
      }));
    },
    onCloseParticipantsClick: () => {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ drawerName: playlistParticipantsDrawerTemplate.name }));
    },
    onRecallPlaylist,
    canRecallFromAllParticipants,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default DetailsTab;
