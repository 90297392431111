/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';
import moment from 'moment-timezone';
import { camelCase } from 'lodash';

import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { useImpersonationStore } from '~Deprecated/hooks/useImpersonationStore';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { OrganizationUserTotalsByRole } from '~Common/const/Organization';

export interface PendoVisitorData {
  id: string,
  email: string,
  full_name: string,
  phone: string,
  org: string,
  orgId: string,
  role: string,
  isManager: boolean,
  countActiveDirects: number,
  environment: string,
  trialUser: boolean,
  trialExpirationDate?: string,
  isCalendarIntegrated: boolean,
}

export interface PendoAccountData {
  id: string,
  name: string,
  hasInsights: boolean
  hasReviews: boolean,
  hasHR: boolean,
  trialExpirationDate?: string,
  salesforceId: string,
  hasOneWayCalendar: boolean,
  isInTrial: boolean,
  wasTrialOrg: boolean,
  hasTableGroupExperience: boolean,
  totalManagers: number,
  // User counts - assuming 'totalAdminUsers', 'totalExecutiveUsers', etc.
  [userCountKey: string]: unknown,
}

export interface PendoData {
  visitor: PendoVisitorData,
  account: PendoAccountData,
}

function formatUserCounts(totalsByRole: OrganizationUserTotalsByRole[]): Record<string, number> {
  const userCounts: Record<string, number> = {};

  totalsByRole.forEach((record) => {
    const key = camelCase(`Total ${record.userGroupName} users`);
    userCounts[key] = record.total;
  });

  return userCounts;
}

export const usePendo = (): void => {
  const userId = getUserId();
  const { detail: profileDetail } = useUserProfile(userId!);
  const {
    organizationInTrial,
    trialStartedAt,
    trialEndsAt,
    organizationName,
    totalsByRole = [],
    totalPrimaryManagers: totalManagers = 0,
    enableInsights,
    enableReviews,
    enableHRModule,
    enableISolvedModule,
    salesforceId,
    enableOneWayCalendarIntegration,
  } = useOrgDetailsContext();
  const { data: calendarAuthenticationStatus } = useGetCalendarAuthStatus();
  const { enableTheTable } = useOrgDetailsContext();

  const userTotals = formatUserCounts(totalsByRole);

  const profileResult = profileDetail.data;
  const isCalendarIntegrated = calendarAuthenticationStatus === AUTH_STATUS.LINKED;

  const { isImpersonating } = useImpersonationStore();
  useEffect(() => {
    if (profileResult && !isImpersonating) {
      const profileResponse = profileResult.response;

      const pendoData: PendoData = {
        // orgUser
        visitor: {
          id: profileResponse.organizationUserId, // Required if using Pendo Feedback, default uses the value 'VISITOR-UNIQUE-ID'
          email: profileResponse.email,
          full_name: `${profileResponse.firstName} ${profileResponse.lastName}`,
          phone: profileResponse.phoneNumber,
          org: organizationName,
          orgId: getOrganizationId()!,
          role: profileResponse.userGroupName,
          isManager: !!(profileResponse.countActiveDirects || 0),
          countActiveDirects: profileResponse.countActiveDirects ?? 0,
          environment: process.env.REACT_APP_ENV!,
          trialUser: organizationInTrial,
          isCalendarIntegrated,
          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
        // org
        account: {
          id: getOrganizationId()!, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          name: organizationName,
          hasInsights: enableInsights,
          hasReviews: enableReviews,
          hasHR: enableHRModule || enableISolvedModule,
          salesforceId,
          hasOneWayCalendar: enableOneWayCalendarIntegration,
          isInTrial: organizationInTrial,
          wasTrialOrg: !!trialStartedAt,
          hasTableGroupExperience: enableTheTable,
          ...userTotals,
          totalManagers,

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      };

      if (organizationInTrial) {
        const endDateString = moment(trialEndsAt).endOf('day').format('YYYY-MM-DD');
        pendoData.visitor.trialExpirationDate = endDateString;
        pendoData.account.trialExpirationDate = endDateString;
      }

      // We call pendo.stopSendingEvents on sign out so we need to call
      // pendo.startSendingEvents to start sending events again if this is the
      // second+ time through this saga.
      //
      // NOTE: It is -very important- that startSendingEvents is called before
      // pendo.initialize/pendo.identify. Not only to ensure that the identify event
      // is properly sent to pendo in the case of a new login, but also because when
      // pendo.startSendingEvents is call Pendo clears their internal events buffer.
      // So while calling startSendingEvents is not required the first time through this
      // saga as pendo.initialize will cause Pendo to start sending events itself, if you
      // call pendo.startSendingEvents immediately after pendo.initialize the metadata
      // event is very likely to get eaten before it can be sent to Pendo and you will spend
      // a very long time confused as to why only a tiny fraction of the Visitors in pendo have
      // metadata. Not that something like that would ever happen to me. A super genius.

      // @ts-expect-error pendo exists on window even though TS doesn't seem to think so
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      window.pendo?.startSendingEvents?.();

      // pendo.initialize can only be called once
      // On sign out the user's Pendo metadata is cleared so we need to call
      // pendo.identify the second+ time through this saga to properly set
      // the new user's metadata

      // @ts-expect-error pendo exists on window even though TS doesn't seem to think so
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (!window.pendo?.isReady?.()) {
        // @ts-expect-error pendo exists on window even though TS doesn't seem to think so
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        window.pendo.initialize(pendoData);
      } else {
        // @ts-expect-error pendo exists on window even though TS doesn't seem to think so
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        window.pendo.identify(pendoData);
      }
    }
  }, [enableHRModule,
    enableISolvedModule,
    enableInsights,
    enableOneWayCalendarIntegration,
    enableReviews,
    enableTheTable,
    isCalendarIntegrated,
    isImpersonating,
    organizationInTrial,
    organizationName,
    profileResult,
    salesforceId,
    totalManagers,
    trialEndsAt,
    trialStartedAt,
    userTotals,
  ]);
};
