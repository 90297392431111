import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getOrganizationId } from '~Common/utils/localStorage';
import { createInfiniteFlattener } from '~Common/hooks/utils';
import { getPeople } from '~Deprecated/hooks/people/api';
import { usePeoplePageSearch } from '~People/stores/usePeoplePageSearch';
import { defaultPeoplePageFilterValue, usePeoplePageFilter } from '~People/stores/usePeoplePageFilter';
import { InsightType, PeoplePageFilterType } from '~People/const/interfaces';

const peopleFlattener = createInfiniteFlattener({
  idName: 'userId',
  listSelector: (item) => item.response?.items?.[0]?.users,
});

const insightTypeMap = {
  [PeoplePageFilterType.Department]: InsightType.Department,
  [PeoplePageFilterType.UserGroup]: InsightType.UserGroup,
  [PeoplePageFilterType.Role]: InsightType.Role,
  [PeoplePageFilterType.WorkingGenius]: defaultPeoplePageFilterValue,
};

export const usePeople = ({ targetUserId = '', insightsFor = null, searchFor = null }) => {
  const {
    filterValue,
    filterType,
  } = usePeoplePageFilter((state) => ({
    filterValue: state.filterValue,
    filterType: state.filterType,
  }));

  const insightType = insightTypeMap[filterType] || filterValue;

  const {
    debouncedSearchText,
  } = usePeoplePageSearch((state) => ({
    debouncedSearchText: state.debouncedSearchText,
  }));

  const result = useInfiniteQuery({
    queryKey: [
      getOrganizationId(),
      'peopleList',
      'name',
      targetUserId,
      searchFor ?? debouncedSearchText,
      insightsFor ?? insightType,
      filterType,
      filterValue,
    ],
    queryFn: getPeople,
    initialPageParam: 0,
  });

  const { ids, data } = useMemo(() => peopleFlattener(result), [result.data]);

  const initialPageItemCount = result?.data?.pages?.[0]?.response?.items?.[0]?.users?.length ?? 0;

  return {
    result,
    ids,
    data,
    initialPageItemCount,
  };
};
