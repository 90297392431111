import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

import {
  buttonConstructiveColor,
  buttonConstructiveColorHover,
  buttonDestructiveColor,
  buttonNeutralColor,
  buttonNeutralColorHover,
  drawerButtonCtaBg,
  drawerButtonCtaBgHover,
  drawerButtonDarkText,
  drawerButtonLightText,
  drawerColor,
} from '~Deprecated/ui/styles/colors';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
export const buttonStyleMap = {
  constructive: css`
    transition: color 300ms, background-color 300ms, opacity 300ms;
    background-color: ${buttonConstructiveColor};
    color: ${palette.neutrals.white};

    & svg {
      stroke: ${drawerButtonLightText};
    }

    &:hover, &:focus, &:disabled {
      background-color: ${buttonConstructiveColorHover};
    }

    &:disabled {
      opacity: 0.2;
    }
  `,
  destructive: css`
    transition: color 300ms, background-color 300ms, opacity 300ms;
    background-color: ${buttonDestructiveColor};
    color: ${drawerButtonLightText};

    & svg {
      stroke: ${drawerButtonLightText};
    }
  `,
  neutral: css`
    transition: color 300ms, background-color 300ms, opacity 300ms;
    background-color: ${buttonNeutralColor};
    color: ${drawerButtonDarkText};

    &:hover, &:focus, &:disabled {
      background-color: ${buttonNeutralColorHover};
    }

    &:disabled {
      opacity: 0.2;
    }
  `,
  outline: css`
    transition: color 300ms, background-color 300ms, opacity 300ms;
    background-color: transparent;
    color: ${palette.neutrals.gray600};
    border:1px solid ${palette.neutrals.gray600};
    border-radius: 3px;
    min-width:auto;
    margin-left: .5rem;

    &:disabled {
      opacity: 0.2;
    }
    &:hover {
      color: ${palette.brand.sky};
      border-color: ${palette.brand.sky};
    }
  `,
  outline_destructive: css`
    transition: color 300ms, background-color 300ms, opacity 300ms;
    background-color: transparent;
    color: ${palette.brand.red};
    border:1px solid ${palette.brand.red};
    border-radius: 3px;
    min-width:auto;
    margin-left: .5rem;

    &:disabled {
      opacity: 0.2;
    }
    &:hover {
      color: ${palette.neutrals.white};
      background-color: ${palette.brand.red};
    }
  `,
  cta: css`
    transition: color 300ms, background-color 300ms, opacity 300ms;
    background-color: ${drawerButtonCtaBg};
    color: ${drawerColor};

    & svg {
      stroke: ${drawerColor};
    }

    &:hover, &:focus, &:disabled {
      background-color: ${drawerButtonCtaBgHover};
    }

    &:disabled {
      opacity: 0.2;
    }
  `,
};
