import { css } from '@emotion/react';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Goals } from '@leadr-hr/types';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Tooltip from '~Common/components/Tooltip';
import { palette } from '~Common/styles/colors';
import { LinkedGoalType } from '~Goals/const/types';
import { useShowLinkGoalModal } from '~Goals/hooks/utils/useShowLinkGoalModal';
import { useLinkGoalWithExistingGoalLinks } from '~Goals/hooks/utils/useLinkGoalWithExistingGoalLinks';
import LinkedGoalCard from '~Goals/components/GoalDetails/LinkedGoals/LinkedGoalCard';

const styles = {
  parentGoal: css({}),
  parentGoalText: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    marginBottom: '0.25rem',
  }),
};

interface ViewProps {
  parentGoal: Goals.LinkedGoal | undefined,
  handleConnectParentGoal: () => void,
  canLinkGoal: boolean,
  handleUnlink: () => void,
}

const View = ({
  parentGoal,
  handleConnectParentGoal,
  canLinkGoal,
  handleUnlink,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.parentGoal}
    {...props}
  >
    <div css={styles.parentGoalText}>Parent Goal</div>
    {parentGoal && (
      <LinkedGoalCard
        goal={parentGoal}
        linkedGoalType={LinkedGoalType.Parent}
        handleUnlink={handleUnlink}
        canLinkGoal={canLinkGoal}
      />
    )}
    {!parentGoal && (
      <Tooltip content={canLinkGoal ? '' : 'You do not have permission to connect a goal.'}>
        <div>
          <LeadrButton
            size="mini"
            variant="ghost"
            data-test-id="goalsConnectParentGoal"
            onClick={handleConnectParentGoal}
            disabled={!canLinkGoal}
          >
            <LeadrButton.IconAndText icon={faPlus} text="Connect Parent Goal" />
          </LeadrButton>
        </div>
      </Tooltip>
    )}
  </div>
);

interface ParentGoalProps extends Pick<ViewProps, 'parentGoal' | 'canLinkGoal'> {
  goalId: string,
}

const ParentGoal = ({
  goalId,
  ...props
}: ParentGoalProps): JSX.Element => {
  const { openModal } = useShowLinkGoalModal();
  const { unlinkParentGoal } = useLinkGoalWithExistingGoalLinks({ goalId });

  const handleUnlink = (): void => {
    unlinkParentGoal();
  };

  const handleConnectParentGoal = (): void => {
    openModal({
      props: {
        linkedGoalType: LinkedGoalType.Parent,
      },
    });
  };

  const hookProps = {
    handleConnectParentGoal,
    handleUnlink,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ParentGoal;
