import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { TOPIC_NOTE_ITEM_SHAPE, ATTENDEE_SHAPE } from '~Common/const/proptypes';
import { useTruncatedAvatarList } from '~Meetings/hooks/useTruncatedAvatarList';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';

import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { useGetTopicTimeString } from '~Meetings/hooks/useGetTopicTimeString';

const styles = {
  commentControls: css({
    display: 'flex',
    alignItems: 'center',
  }, forMobileObject({
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  })),
  button: css({
    border: 0,
    padding: 0,
    background: 'transparent',
    fontSize: '10px',
    fontWeight: 400,
    letterSpacing: '0.2em',
    color: palette.neutrals.gray600,
  }),
  addCommentButton: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.brand.indigo,
    marginRight: '1rem',
    padding: '0 !important',
  }, forMobileObject({
    marginRight: '0',
  })),
  avatarList: css({
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.625rem',
  }),
  avatar: css({
    marginRight: '0.1875rem',
  }),
  showComments: css({
    fontSize: '.75rem',
  }, forMobileObject({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  })),
  noteCommentText: css({
    fontWeight: 600,
  }),
  lastReply: css({
    color: palette.neutrals.gray700,
    paddingLeft: '0.375rem',
  }, forMobileObject({
    paddingLeft: '0',
  })),
};

const View = ({
  creators,
  notes,
  setShowComments,
  commentText,
  isMobile,
  timeSinceLastComment,
}) => (
  <div css={styles.commentControls}>
    <button css={[styles.button, styles.addCommentButton]} onClick={() => setShowComments(true)}>
      {!isMobile && (
        <div css={styles.avatarList}>
          {creators?.map((creator) => (
            <NewBaseAvatar
              key={creator.id}
              css={styles.avatar}
              profileImageUrl={creator.profileImageUrl}
              name={`${creator.firstName} ${creator.lastName}`}
              avatarSize={22}
            />
          ))}
        </div>
      )}

      <div css={styles.showComments}>
        <span css={styles.noteCommentText}>{`${notes?.length} ${commentText}`}</span>
        {!isMobile && (
          <span css={styles.lastReply}>
            {`${timeSinceLastComment ? ` Last reply ${timeSinceLastComment}` : ''}`}
          </span>
        )}
      </div>
    </button>
  </div>
);

View.propTypes = {
  notes: PropTypes.arrayOf(TOPIC_NOTE_ITEM_SHAPE),
  creators: PropTypes.arrayOf(ATTENDEE_SHAPE),
  setShowComments: PropTypes.func.isRequired,
  commentText: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  timeSinceLastComment: PropTypes.string.isRequired,
};

View.defaultProps = {
  creators: [],
  notes: [],
};

const ShowCommentsComponent = ({
  notes,
  huddleId,
  agendaItemId,
  type,
  ...props
}) => {
  const creators = useTruncatedAvatarList(notes.map((note) => note.creator.orgUserId ?? note.creator));
  const commentText = notes.length > 1 ? 'Comments' : 'Comment';
  const isMobile = useIsMobileQuery();

  const timeSinceLastComment = useGetTopicTimeString(notes[notes.length - 1]?.createdInMillis);

  const hookProps = {
    notes,
    creators,
    commentText,
    isMobile,
    timeSinceLastComment,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ShowCommentsComponent.propTypes = {
  notes: PropTypes.arrayOf(TOPIC_NOTE_ITEM_SHAPE),
  huddleId: PropTypes.string.isRequired,
  agendaItemId: PropTypes.string,
  type: PropTypes.string.isRequired,
};
ShowCommentsComponent.defaultProps = {
  notes: [],
  agendaItemId: '',
};

export { View };
export default ShowCommentsComponent;
