import { useParams } from 'react-router';
import { ListDashboardSkeleton } from '~Common/components/Loader/ListDashboard';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { ListSectionSkeleton } from '~Common/V3/components/ListSection';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '../Const/pageStyles';
import { ReviewCycleDetail, ReviewCycleWithParticipants } from '../Const/types';
import { useDebouncedSearch } from '../Hooks/useDebouncedSearch';
import { useGetCycleById } from '../Hooks/useGetCycleById';
import ReviewCycleLayout from '../Layouts/ReviewCycle';
import { CycleDetailsTopBar } from '../TopBar/CycleDetailsTopBar';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
};

interface ViewProps {
  showSkeleton: boolean,
  onSearch: (newValue: string) => void,
  reviewCycle?: ReviewCycleWithParticipants,
  searchText: string,
}

export const View = ({
  showSkeleton,
  onSearch,
  reviewCycle,
  searchText,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    {showSkeleton && (
      <>
        <div css={[styles.topBar]}>
          <div css={styles.leftSide(true)}>
            <MultipleSkeletonLoaders
              css={styles.topBarSkellyLoader}
              numberOfSkeletons={1}
              renderSkeletonItem={() => (
                <CardSkeleton css={styles.cardSkeleton} />
              )}
            />
          </div>
        </div>
        <ListSectionSkeleton css={styles.listSectionSkeleton} />
        <ListDashboardSkeleton
          amountOfLoaders={12}
        />
      </>
    )}
    {!showSkeleton && reviewCycle && (
      <>
        <CycleDetailsTopBar
          onSearch={onSearch}
          searchString={searchText}
        />
        <ReviewCycleLayout
          activeReviewCycle={reviewCycle as unknown as ReviewCycleDetail}
          searchText={searchText}
        />
      </>
    )}
  </div>
);

export interface ReviewCycleDetailsParams {
  cycleId: string,
}

export const ReviewCycleDetails = (): JSX.Element => {
  const { cycleId } = useParams<ReviewCycleDetailsParams>();
  const { isAdmin } = useUserPermissions();
  const { data: reviewCycle, isLoading } = useGetCycleById({ id: cycleId, isAdmin });
  const { searchText, onSearch } = useDebouncedSearch();
  const [showSkeleton] = useSkeletonLoaders(isLoading);

  const hookProps = {
    showSkeleton,
    onSearch,
    reviewCycle,
    searchText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
