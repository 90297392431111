import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { LearningCategoryName, LearningTemplateCategories } from '~Learning/const/interfaces';
import { LearningLibraryDrawerTabs } from '~Learning/components/LearningLibrary/ViewLearningLibraryDrawer';
import { learningTemplatesQueryKeys } from '../queryKeys';

const ORG_CATEGORY_NAMES_MAP = {
  [LearningCategoryName.Teamwork]: 'Teamwork',
  [LearningCategoryName.PersonalGrowth]: 'Personal Growth',
  [LearningCategoryName.Communication]: 'Communication',
  [LearningCategoryName.Leadership]: 'Leadership',
  [LearningCategoryName.Culture]: 'Culture',
  [LearningCategoryName.LeadrResources]: 'Resources',
};

const CURATED_BY_LEADR_CATEGORY_NAMES_MAP = {
  [LearningCategoryName.Teamwork]: 'Teamwork',
  [LearningCategoryName.PersonalGrowth]: 'Personal Growth',
  [LearningCategoryName.Communication]: 'Communication',
  [LearningCategoryName.Leadership]: 'Leadership',
  [LearningCategoryName.Culture]: 'Culture',
  [LearningCategoryName.LeadrResources]: 'Leadr Resources',
};

const CURATED_BY_TABLE_GROUP_CATEGORY_NAMES_MAP = {
  [LearningCategoryName.Teamwork]: 'Organizational Health',
  [LearningCategoryName.PersonalGrowth]: 'Meetings',
  [LearningCategoryName.Communication]: 'Employee Engagement',
  [LearningCategoryName.Leadership]: 'Train the Trainer',
  [LearningCategoryName.Culture]: 'Working Genius',
  [LearningCategoryName.LeadrResources]: 'Resources',
};

const CATEGORY_NAME_ORG_MAP = {
  [LearningCategoryName.Teamwork]: 0,
  [LearningCategoryName.PersonalGrowth]: 1,
  [LearningCategoryName.Communication]: 2,
  [LearningCategoryName.Leadership]: 3,
  [LearningCategoryName.Culture]: 4,
  [LearningCategoryName.LeadrResources]: 5,
};

const getLearningTemplate = async (): Promise<HttpCallReturn<LearningTemplateCategories>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/categories`;

  return getApi<LearningTemplateCategories>(serverUrl);
};

interface LearningTemplateHookReturn {
  learningTemplateCategories: { categoryId: string, categoryName: string }[],
  isLoading: boolean,
}

export const useGetLearningTemplateCategories = (tab: LearningLibraryDrawerTabs): LearningTemplateHookReturn => {
  const result = useQuery({
    queryKey: learningTemplatesQueryKeys.categories(),
    queryFn: getLearningTemplate,
  });

  const learningTemplateCategoryItems = useMemo(() => result.data?.response?.categories || [], [result.data]);

  const categoryMapToUse = useMemo(() => {
    if (tab === LearningLibraryDrawerTabs.ORGANIZATION) {
      return ORG_CATEGORY_NAMES_MAP;
    }

    if (tab === LearningLibraryDrawerTabs.CURATED_BY_LEADR) {
      return CURATED_BY_LEADR_CATEGORY_NAMES_MAP;
    }

    if (tab === LearningLibraryDrawerTabs.CURATED_BY_TABLE_GROUP) {
      return CURATED_BY_TABLE_GROUP_CATEGORY_NAMES_MAP;
    }

    // Right now this is the default return from the API
    return CURATED_BY_LEADR_CATEGORY_NAMES_MAP;
  }, [tab]);

  const learningTemplateCategories = useMemo(() => {
    if (learningTemplateCategoryItems) {
      const sortedLearningTemplateCategories = [...learningTemplateCategoryItems].sort(
        (category1, category2) => CATEGORY_NAME_ORG_MAP[category1.categoryName] - CATEGORY_NAME_ORG_MAP[category2.categoryName],
      );

      const sortedNamedLearningTemplateCategories = sortedLearningTemplateCategories.map((category) => ({
        ...category,
        categoryName: categoryMapToUse[category.categoryName],
      }));

      return sortedNamedLearningTemplateCategories;
    }

    return [];
  }, [categoryMapToUse, learningTemplateCategoryItems]);

  return {
    learningTemplateCategories,
    isLoading: result.isLoading,
  };
};
