import { Tabs, Tab } from '@mui/material';
import styled from '@mui/system/styled';

import {
  activeTabBg,
  activeTabColor,
  disabledTabColor,
  tabsBg,
  tabsColor,
} from '~Deprecated/ui/styles/colors';

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
const DrawerTabs = styled(Tabs)(() => ({
  backgroundColor: tabsBg,
  borderRadius: '.5rem',
  width: '100%',
  '.MuiTabs-indicator': {
    display: 'none',
  },
}));

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
const DrawerTab = styled(Tab)(() => ({
  color: tabsColor,
  borderRadius: '.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'uppercase',
  '&.Mui-selected': {
    backgroundColor: activeTabBg,
    color: activeTabColor,
  },
  '&.Mui-disabled': {
    color: disabledTabColor,
  },
}));

Tabs.propTypes = {

};

Tabs.defaultProps = {

};

export { DrawerTabs, DrawerTab };
