import { css } from '@emotion/react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { palette } from '~Common/styles/colors';
import { AgendaTemplates } from '@leadr-hr/types';
import { useContext, useMemo, useState } from 'react';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import MoveAgendaItemButtons, { MoveAgendaItemButtonsProps } from '~Meetings/components/details/agenda/MoveAgendaItemButtons';
import { MoveDirection } from '~Meetings/hooks/utils/useMoveTemplateAgendaItem';
import {
  CreateAgendaTemplateDetails,
  EditAgendaTemplateDetails,
  CreateAgendaSectionTemplateDetailsWithLocalID,
  EditAgendaSectionTemplateDetailsWithLocalID,
} from '~Meetings/components/topic-suggestions/const/types';
import AgendaSection from '.';
import { useShowItemEditor } from '../../stores/useShowItemEditor';
import { MoveTemplateAgendaItemContext } from '../../contexts/MoveTemplateAgendaItemContext';

const styles = {
  agendaSection: (isDraggingOver: boolean) => css({
    borderRadius: '0.5rem',
  }, isDraggingOver && {
    border: `0.125rem dotted ${palette.brand.indigo}`,
  }),
  dragHandle: css({
    position: 'absolute',
    left: '-0.875rem',
  }),
};

interface ViewProps extends MoveAgendaItemButtonsProps {
  sectionLocalId: string,
  index: number,
  handleMouseEnter: () => void,
  handleMouseLeave: () => void,
  canDropItem: boolean,
  agendaSection: CreateAgendaSectionTemplateDetailsWithLocalID | EditAgendaSectionTemplateDetailsWithLocalID,
  isMobile: boolean,
  hasMaxTopics: boolean,
}

const View = ({
  sectionLocalId,
  index,
  handleMouseEnter,
  handleMouseLeave,
  canDropItem,
  agendaSection,
  isMobile,
  shouldDisableMoveDown,
  shouldDisableMoveUp,
  handleMoveAgendaTopicDown,
  handleMoveAgendaTopicUp,
  hasMaxTopics,
  ...props
}: ViewProps): JSX.Element => (
  <Draggable
    draggableId={`agendaSectionDraggable${sectionLocalId}`}
    index={index}
  >
    {(dragProvided) => (
      <div
        ref={dragProvided.innerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...dragProvided.draggableProps}
        {...props}
      >
        <Droppable
          droppableId={`agendaSectionDroppable${sectionLocalId}`}
          isDropDisabled={!canDropItem}
        >
          {(dropProvided, dropSnapshot) => (
            <div
              css={styles.agendaSection(dropSnapshot.isDraggingOver)}
              ref={dropProvided.innerRef}
              {...dropProvided.droppableProps}
            >
              <AgendaSection
                agendaSection={agendaSection}
                sectionLocalId={sectionLocalId}
                hasMaxTopics={hasMaxTopics}
                renderDragHandle={() => (
                  <>
                    {isMobile && (
                      <MoveAgendaItemButtons
                        css={styles.dragHandle}
                        shouldDisableMoveDown={shouldDisableMoveDown}
                        shouldDisableMoveUp={shouldDisableMoveUp}
                        handleMoveAgendaTopicDown={handleMoveAgendaTopicDown}
                        handleMoveAgendaTopicUp={handleMoveAgendaTopicUp}
                      />
                    )}
                    {!isMobile && (
                      <div
                        css={styles.dragHandle}
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        {...dragProvided.dragHandleProps}
                      >
                        <FontAwesomeIcon
                          icon={faGripDotsVertical}
                        />
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          )}
        </Droppable>
      </div>
    )}
  </Draggable>
);

interface DNDAgendaSectionProps extends Omit<
  ViewProps, 'handleMouseEnter' | 'handleMouseLeave' | 'canDropItem' | 'isMobile'
    | 'shouldDisableMoveDown' | 'shouldDisableMoveUp' | 'handleMoveAgendaTopicDown' | 'handleMoveAgendaTopicUp'
> {
  itemBeingDragged: CreateAgendaTemplateDetails | EditAgendaTemplateDetails | undefined,
}

const DNDAgendaSection = ({
  agendaSection,
  itemBeingDragged,
  sectionLocalId,
  ...props
}: DNDAgendaSectionProps): JSX.Element => {
  const [isHoveringOver, setIsHoveringOver] = useState(false);
  const isMobile = useIsMobileQuery();

  const {
    getIsItemEditorVisible,
  } = useShowItemEditor((state) => ({
    getIsItemEditorVisible: state.getIsItemEditorVisible,
  }));
  const showAgendaSectionTextEditor = getIsItemEditorVisible(sectionLocalId);

  const handleMouseEnter = (): void => {
    setIsHoveringOver(true);
  };

  const handleMouseLeave = (): void => {
    setIsHoveringOver(false);
  };

  const canDropItem = useMemo(() => {
    if (!isMobile && !isHoveringOver) {
      return false;
    }

    if (showAgendaSectionTextEditor) {
      return false;
    }

    // Cannot drag a section into a section
    if (itemBeingDragged?.type === AgendaTemplates.AgendaTemplateType.AgendaSection) {
      return false;
    }

    return true;
  }, [isMobile, isHoveringOver, showAgendaSectionTextEditor, itemBeingDragged?.type]);

  const {
    disableMoveDown,
    disableMoveUp,
    moveTemplateAgendaItemOnePosition,
  } = useContext(MoveTemplateAgendaItemContext);

  const shouldDisableMoveDown = disableMoveDown({
    agendaType: AgendaTemplates.AgendaTemplateType.AgendaSection,
    localId: sectionLocalId,
  });

  const shouldDisableMoveUp = disableMoveUp({
    agendaType: AgendaTemplates.AgendaTemplateType.AgendaSection,
    localId: sectionLocalId,
  });

  const handleMoveAgendaTopicDown = (): void => {
    moveTemplateAgendaItemOnePosition({
      moveDirection: MoveDirection.Down,
      agendaType: AgendaTemplates.AgendaTemplateType.AgendaSection,
      localId: sectionLocalId,
    });
  };

  const handleMoveAgendaTopicUp = (): void => {
    moveTemplateAgendaItemOnePosition({
      moveDirection: MoveDirection.Up,
      agendaType: AgendaTemplates.AgendaTemplateType.AgendaSection,
      localId: sectionLocalId,
    });
  };

  const hookProps = {
    handleMouseEnter,
    handleMouseLeave,
    canDropItem,
    itemBeingDragged,
    agendaSection,
    sectionLocalId,
    isMobile,
    shouldDisableMoveDown,
    shouldDisableMoveUp,
    handleMoveAgendaTopicDown,
    handleMoveAgendaTopicUp,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default DNDAgendaSection;
