import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import InfiniteListView from '~Common/V3/components/InfiniteList';
import { useFeedback } from '~Deprecated/hooks/feedback/useFeedback';

import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import EmptyFeedback from '~Feedback/assets/images/emptyFeedback.svg';
import { forMobile } from '~Common/styles/mixins';
import { slideFromBottom } from '~Common/styles/animations';
import { useLocation } from 'react-router-dom';
import { ViewType } from '~Common/const/interfaces';
import { FEEDBACK_RECEIVED, FEEDBACK_SENT } from '~Common/const/routes';
import { useFeedbackViewType } from '~Feedback/stores/useFeedbackViewType';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import FeedbackCard from './Cards/Feedback';
import { respondentFeedbackDetailTemplate } from './Drawers/RespondentDetail/RespondentDetailDrawer';
import { requestorFeedbackDetailTemplate } from './Drawers/RequestorDetail/RequestorDetailDrawer';

const styles = {
  pageStyle: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  listWrapper: {
    [ViewType.LIST]: css`
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0.5rem;
    `,
    [ViewType.GRID]: css`
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
      width: 100%;
      grid-gap: 1rem;
      place-items: center;
      grid-auto-rows: 1fr;

      ${forMobile(`
        grid-gap: 0.5rem;
        grid-template-columns: 1fr;
      `)}
    `,
  },
  emptyState: css({
    marginTop: '3.125rem',
    '& h6': {
      width: '80%',
    },
  }),
  emptyStateImage: css({
    height: '11.4375rem',
  }),
};

interface ViewProps {
  isReceivedFeedback: boolean,
  viewType: ViewType,
  onItemClick: (id: string) => void,
  emptyStateText: string,
}

const View = ({
  onItemClick,
  emptyStateText,
  isReceivedFeedback,
  viewType,
}: ViewProps): JSX.Element => (
  <div css={styles.pageStyle}>
    <InfiniteListView
      listWrapperStyle={styles.listWrapper[viewType]}
      listQuery={useFeedback}
      renderItem={(id: string, index: number) => (
        <FeedbackCard
          onItemClick={onItemClick}
          id={id}
          isReceivedFeedback={isReceivedFeedback}
          viewType={viewType}
          css={slideFromBottom(index)}
          key={`feedback-item-${index}`}
        />
      )}
      itemKeyName="id"
      renderEmptyState={() => (
        <EmptyStateWithImage
          css={styles.emptyState}
          renderImage={() => (
            <EmptyFeedback css={styles.emptyStateImage} title="Empty feedback" />
          )}
          text={emptyStateText}
        />
      )}
      loaderStyle={undefined}
      renderLoadingState={undefined}
      requestType={undefined}
      infiniteQueryProp={undefined}
    />
  </div>
);

const Feedback = ({ ...props }): JSX.Element => {
  const dispatch = useDispatch();
  const {
    viewType,
  } = useFeedbackViewType((state) => ({
    viewType: state.viewType,
  }));

  const location = useLocation();
  const currentTab = location.pathname === FEEDBACK_RECEIVED ? FEEDBACK_RECEIVED : FEEDBACK_SENT;

  const isReceivedFeedback = currentTab === FEEDBACK_RECEIVED;

  const onForYou = (id: string): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...respondentFeedbackDetailTemplate,
        args: {
          id,
        },
      },
    }));
  };

  const onByYou = (id: string): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...requestorFeedbackDetailTemplate,
        args: {
          id,
          assigneeId: getOrganizationUserId(),
        },
      },
    }));
  };

  let emptyStateText;
  if (isReceivedFeedback) {
    emptyStateText = `There is no feedback requested from you. 
    Select the 'Requested By You' filter to view feedback you requested of others.`;
  } else {
    emptyStateText = `There is no feedback requested by you.
    Select the 'Requested From You' filter to view feedback others requested from you.`;
  }
  emptyStateText = `${emptyStateText} To Request another person's feedback click 'Request Feedback' or, for a quick start, click 'Templates'.`;

  return (
    <View
      viewType={viewType}
      onItemClick={isReceivedFeedback ? onForYou : onByYou}
      emptyStateText={emptyStateText}
      isReceivedFeedback={isReceivedFeedback}
      {...props}
    />
  );
};

export default Feedback;
