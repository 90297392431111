import { useCallback } from 'react';
import { useMeetingUrl } from '~Common/hooks/useMeetingUrl';
import {
  Coaching, MeetingFactoryType, MeetingTypeEnum, TimelineData,
} from '~Meetings/const/meetingsInterfaces';
import useMeetingDetails from './useMeetingDetails';

export interface OnTimelineTileClickParams {
  timelineData: TimelineData,
  huddle: Coaching,
}

export type OnTimelineTileClick = (params: OnTimelineTileClickParams) => void;

interface UseTimelineClickParams {
  id: string,
  type: MeetingTypeEnum,
  meetingFactoryId: string,
}

interface UseTimelineClickReturn {
  onTimelineTileClick: (params: { timelineData: TimelineData, huddle: Coaching }) => void,
  isMeetingDetailsLoading: boolean,
}

const useTimelineClick = ({ id, type, meetingFactoryId }: UseTimelineClickParams): UseTimelineClickReturn => {
  const { isLoading: isMeetingDetailsLoading } = useMeetingDetails({ id, type }) ?? {};
  const { navigateToMeeting } = useMeetingUrl();

  const onTimelineTileClick = useCallback(({ timelineData, huddle }: OnTimelineTileClickParams) => {
    const isCoaching = huddle.category === MeetingFactoryType.COACHING;

    navigateToMeeting({
      meetingType: huddle.category,
      meetingInstanceId: huddle.id,
      teamId: huddle.teamId,
      extraQueryParams: {
        feedbackId: isCoaching ? timelineData.feedback[0]?.id : undefined,
        contentId: isCoaching ? timelineData.content[0]?.id : undefined,
      },
      factoryId: meetingFactoryId,
    });
  }, [meetingFactoryId, navigateToMeeting]);

  return {
    onTimelineTileClick,
    isMeetingDetailsLoading,
  };
};

export default useTimelineClick;
