import { SyntheticEvent, useState } from 'react';
import { ContextButtons } from '~Reviews/V2/Shared/ContextButtons';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Dropdown from '~Common/V3/components/Dropdown';
import { SelectChangeEvent } from '@mui/material';
import {
  ADMIN_PEOPLE, PEOPLE_ADMIN,
} from '~Common/const/routes';
import { navigateAction } from '~Deprecated/actions/navigate';
import { useDispatch } from 'react-redux';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import {
  AdminImporterTabValues, CSVPerspective, ErrorAndWarningRecordShape, ResponseDataShape,
} from '../Const/types';
import AdminImporterTabs from '../Shared/AdminImporterTabs';
import { defaultDraftData, DEFAULT_ROWS_PER_PAGE, rowsPerPageOptions } from '../Const/defaults';
import AdminImporterDataDisplay from '../Pages/AdminImporterDataDisplay';
import { useFinishImport } from '../Hooks/useFinishImport';
import FinishModal from './FinishModal';

const styles = {
  title: css({
    color: palette.brand.indigo,
    fontWeight: 600,
    fonstSize: '1.5rm',
    marginBottom: '.625rem',
  }),
  content: css({
    color: palette.neutrals.gray700,
    fontSize: '.875rem',
    marginBottom: '1.9375rem',
  }),
  contextButtonSpace: css({
    marginLeft: '.625rem',
  }),
  dropdown: css({
    fontSize: '0.75rem',
    marginLeft: '-0.5rem',
    padding: '0.25rem',
    paddingLeft: '0.75rem',
    width: 'auto',

    '.MuiInput-root': {
      fontSize: '0.75rem',
      marginTop: 0,
    },
  }),
  tabContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
    marginBottom: '2rem',
  }),
  tableControls: css({
    alignItems: 'center',
    color: palette.neutrals.gray500,
    columnGap: '1.25rem',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.75rem',
  }),
};

interface ViewProps {
  resetImportData: () => void,
  importTab: AdminImporterTabValues,
  changeTab: (event: SyntheticEvent, tab: AdminImporterTabValues) => void,
  totalWarningRecords: number,
  totalErrorRecords: number,
  totalSuccessfulRecords: number,
  totalRecords: number,
  warningRecords: ErrorAndWarningRecordShape[],
  errorRecords: ErrorAndWarningRecordShape[],
  filteredImportData: ResponseDataShape[],
  originalDraftData: ResponseDataShape[],
  changeRowsPerPage: (event: SelectChangeEvent) => void,
  rowsPerPage: number,
  hasDraftData: boolean,
  finishImport: () => void,
  openFinishModal: boolean,
  handleFinishModalDisplay: (value: boolean) => void,
}

const View = ({
  resetImportData,
  importTab,
  changeTab,
  totalWarningRecords,
  totalErrorRecords,
  totalSuccessfulRecords,
  totalRecords,
  warningRecords,
  errorRecords,
  filteredImportData,
  originalDraftData,
  changeRowsPerPage,
  rowsPerPage,
  hasDraftData,
  finishImport,
  openFinishModal,
  handleFinishModalDisplay,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="contextButtons"
      renderContents={() => (
        <>
          <LeadrButton
            data-test-id="restartImportButton"
            size="standard"
            variant="ghost"
            onClick={resetImportData}
            disabled={!hasDraftData}
          >
            <>
              Restart Import
            </>
          </LeadrButton>
          <LeadrButton
            data-test-id="finishImportButton"
            size="standard"
            variant="default"
            onClick={() => handleFinishModalDisplay(true)}
            css={styles.contextButtonSpace}
          >
            <>
              Finish Import
            </>
          </LeadrButton>
        </>
      )}
    />
    <h2
      css={styles.title}
    >
      Review Errors & Warnings
    </h2>
    <p
      css={styles.content}
    >
      <p>{`${totalSuccessfulRecords}/${totalRecords} users will be imported successfully. Please correct errors and warnings below and re-upload CSV.`}</p>
    </p>
    <div css={styles.tabContainer}>
      <AdminImporterTabs
        expandedTab={importTab}
        changeTab={changeTab}
        isLoading={false}
        totalWarningRecords={totalWarningRecords}
        totalErrorRecords={totalErrorRecords}
        totalSuccessfulRecords={totalSuccessfulRecords}
        totalRecords={totalRecords}
      />
      <div css={styles.tableControls}>
        Showing:
        <Dropdown
          data-test-id="rowsDisplayed"
          css={styles.dropdown}
          items={rowsPerPageOptions}
          onChange={changeRowsPerPage}
          value={rowsPerPage.toString()}
        />
      </div>
    </div>

    <AdminImporterDataDisplay
      warningRecords={warningRecords}
      errorRecords={errorRecords}
      filteredImportData={filteredImportData}
      originalDraftData={originalDraftData}
    />
    <FinishModal
      open={openFinishModal}
      handleModalDisplay={handleFinishModalDisplay}
      finishImport={finishImport}
    />
  </>
);

interface CSVFilePreviewInterfaceProps {
  filteredImportData: ResponseDataShape[],
  originalDraftData: ResponseDataShape[],
  setData: (newState: ResponseDataShape) => void,
  setImportPerspective: (value: CSVPerspective) => void,
}

const CSVFilePreviewInterface = ({
  filteredImportData,
  originalDraftData,
  setData,
  setImportPerspective,
}: CSVFilePreviewInterfaceProps): JSX.Element => {
  const [importTab, setImportTab] = useQueryParamState<AdminImporterTabValues>('admin', 'tabView', AdminImporterTabValues.Successful);
  const hasDraftData = Array.isArray(originalDraftData);
  const totalRecords = hasDraftData ? originalDraftData.length : 0;
  const errorRecords = hasDraftData ? originalDraftData.flatMap((record) => record.errors.filter((error) => error.errorCode !== '')) : [];
  const warningRecords = hasDraftData ? originalDraftData.flatMap((record) => record.warnings.filter((warning) => warning.errorCode !== '')) : [];
  const successfulRecords = hasDraftData ? originalDraftData.filter((record) => record.errors.length === 0) : [];
  const [rowsPerPage, setRowsPerPage] = useQueryParamState<number>('admin', 'rowsPerPage', DEFAULT_ROWS_PER_PAGE);

  const changeTab = (evt: SyntheticEvent, newTab: AdminImporterTabValues): void => {
    setImportTab(newTab);
  };

  const changeRowsPerPage = (event: SelectChangeEvent): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const resetImportData = (): void => {
    setData(defaultDraftData);
    setImportPerspective(CSVPerspective.Upload);
    setImportTab(AdminImporterTabValues.Successful);
  };
  const {
    mutate: finishImportMutation,
  } = useFinishImport();
  const finishImport = (): void => {
    finishImportMutation({ data: originalDraftData }, { onSuccess });
  };
  const dispatch = useDispatch();
  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');
  const backToAdminRoute = webAppTeamsV2 ? `${PEOPLE_ADMIN}` : `${ADMIN_PEOPLE}?tab=imported`;
  const onSuccess = (): void => {
    setData(defaultDraftData);
    dispatch(navigateAction({ pathname: backToAdminRoute }));
  };

  const [openFinishModal, setOpenFinishModal] = useState(false);
  const handleFinishModalDisplay = (value: boolean): void => {
    setOpenFinishModal(value);
  };

  const hookProps = {
    resetImportData,
    importTab,
    changeTab,
    totalWarningRecords: warningRecords.length,
    totalErrorRecords: errorRecords.length,
    totalSuccessfulRecords: successfulRecords.length,
    totalRecords,
    warningRecords,
    errorRecords,
    filteredImportData,
    originalDraftData,
    changeRowsPerPage,
    rowsPerPage,
    hasDraftData,
    finishImport,
    openFinishModal,
    handleFinishModalDisplay,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, CSVFilePreviewInterface };
export default CSVFilePreviewInterface;
