import { css } from '@emotion/react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  completedLearningsProgressBar: css({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    height: '.9375rem',
  }),
  progressBar: css({
    height: '100%',
    flexGrow: 1,
    borderRadius: '0.5rem',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: palette.neutrals.gray50,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: palette.brand.indigo,
    },
  }),
  percentText: css({
    color: palette.neutrals.gray800,
    fontWeight: 500,
    marginLeft: '.4375rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  value: number,
  renderPercentText: (value: number) => JSX.Element,
}

const View = ({
  value,
  renderPercentText,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.completedLearningsProgressBar} {...props}>
    <LinearProgress
      css={styles.progressBar}
      variant="determinate"
      value={value}
    />
    <div css={styles.percentText}>
      {renderPercentText(value)}
    </div>
  </div>
);

interface CompletedLearningsProgressBarProps extends Omit<ViewProps, 'value'> {
  totalLearningsCount: number,
  completedLearningsCount: number,
}

const CompletedLearningsProgressBar = ({
  totalLearningsCount,
  completedLearningsCount,
  ...props
}: CompletedLearningsProgressBarProps): JSX.Element => {
  const value = Math.round((completedLearningsCount / totalLearningsCount) * 100);

  const hookProps = {
    value,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default CompletedLearningsProgressBar;
