import { css } from '@emotion/react';
import { faCircleInfo, faEdit, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Card from '~Common/V3/components/Card';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import Tooltip from '~Common/components/Tooltip';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';

const styles = {
  editablePlaylistLearningCard: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  }),
  contentDetails: css({
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
  }),
  dragButton: css({
    cursor: 'grab !important',
  }),
  learningPreview: css({
    margin: '0 1.125rem',
    width: '7.8125rem',
    height: '4.375rem',
    flexShrink: 0,
  }, forMobileObject({
    margin: '0 .625rem',
  })),
  buttonsContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1.125rem',
  }),
  editButton: css({
    marginRight: '1.125rem',
  }, forMobileObject({
    marginRight: '.625rem',
  })),
  deleteButton: css({
    '&:hover': {
      color: palette.brand.red,
    },
  }),
  mobileInfoTooltip: css({
    whiteSpace: 'pre-line',
  }),
  infoIcon: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.brand.indigo,
  }),
  tooltipContainer: css({
    div: {
      color: `${palette.neutrals.white} !important`,
    },
  }),
};

interface ViewProps {
  contentUrl: string,
  onEditContent: () => void,
  onDeleteContent: () => void,
  dragHandleProps: DraggableProvidedDragHandleProps,
  isMobile: boolean,
  renderDetails: () => JSX.Element,
}

const View = ({
  contentUrl,
  onEditContent,
  onDeleteContent,
  dragHandleProps,
  isMobile,
  renderDetails,
  ...props
}: ViewProps): JSX.Element => (
  <Card
    css={styles.editablePlaylistLearningCard}
    {...props}
    renderContents={() => (
      <>
        <div css={styles.contentDetails}>
          <LeadrButton
            css={styles.dragButton}
            textButtonColor={palette.neutrals.gray700}
            variant="text"
            data-test-id="learningPlaylistDragLearning"
            {...dragHandleProps}
          >
            <FontAwesomeIcon size="lg" icon={faGripDotsVertical} />
          </LeadrButton>
          <PreviewWithExternalNavigation css={styles.learningPreview} contentUrl={contentUrl} viewOption="Thumbnail" />
          {isMobile && (
            <Tooltip
              css={styles.mobileInfoTooltip}
              content={(
                <div css={styles.tooltipContainer}>
                  {renderDetails()}
                </div>
              )}
            >
              <div css={styles.infoIcon}>
                <FontAwesomeIcon size="lg" icon={faCircleInfo} />
              </div>
            </Tooltip>
          )}
          {!isMobile && (
            <>
              {renderDetails()}
            </>
          )}
        </div>
        <div css={styles.buttonsContainer}>
          <LeadrButton
            onClick={onEditContent}
            textButtonColor={palette.neutrals.gray700}
            variant="text"
            type="submit"
            data-test-id="learningPlaylistEditLearning"
          >
            <FontAwesomeIcon size="lg" icon={faEdit} />
          </LeadrButton>
          <DeleteButtonWithConfirmation
            renderConfirmationButtons={({
              informationStyles,
              optionStyles,
              popoverButtonStyles,
            }) => (
              <DeleteConfirmationButtons
                informationStyles={informationStyles}
                optionStyles={optionStyles}
                popoverButtonStyles={popoverButtonStyles}
                onDelete={onDeleteContent}
              />
            )}
            renderDeleteButton={({ onClick }) => (
              <LeadrButton
                onClick={onClick}
                css={styles.deleteButton}
                textButtonColor={palette.neutrals.gray700}
                variant="text"
                data-test-id="learningPlaylistDeleteLearning"
              >
                <FontAwesomeIcon size="lg" icon={faTimes} />
              </LeadrButton>
            )}
          />
        </div>
      </>
    )}
  />
);

type EditablePLaylistLearningCardProps = Omit<ViewProps, 'isMobile'>;

const EditablePlaylistLearningCard = ({ ...props }: EditablePLaylistLearningCardProps): JSX.Element => {
  const isMobile = useIsMobileQuery();

  const hookProps = {
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default EditablePlaylistLearningCard;
