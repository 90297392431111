import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import LeadrModal from '~Common/V3/components/LeadrModal';
import { palette } from '~Common/styles/colors';
import { useShowLinkGoalModal } from '~Goals/hooks/utils/useShowLinkGoalModal';
import { LinkedGoalType } from '~Goals/const/types';
import { useGetLinkedGoalIds } from '~Goals/hooks/utils/useGetLinkedGoalIds';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import useUserLinkedGoalIds, { UseUserLinkedGoalIdsReturn } from '~Goals/stores/useUserLinkedGoalIds';
import { UseLinkGoalWithExistingGoalLinksReturn } from '~Goals/hooks/utils/useLinkGoalWithExistingGoalLinks';
import LinkNewGoal from './LinkNewGoal';
import LinkExistingGoal from './LinkExistingGoal';

const styles = {
  modalBody: css({
    overflowX: 'hidden',
  }),
  tabWrapper: css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray100,
    // We want the tabs to expand past the padding of the modal body and take up full with of the modal
    margin: '0 -1.5rem 1rem -1.5rem',
    padding: '0.5rem 1.5rem 0 1.5rem',
  }),
  infoText: css({
    marginBottom: '.75rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  }),
  modalButtons: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
};

enum Tab {
  Existing = 'Existing Goals',
  New = 'New Goal',
}

interface ViewProps extends Omit<UseUserLinkedGoalIdsReturn, 'hasUnsavedLinkedGoals'>,
Pick<UseLinkGoalWithExistingGoalLinksReturn, 'linkParentGoal' | 'linkSupportingGoals'> {
  showModal: boolean,
  activeTab: Tab,
  linkedGoalType: LinkedGoalType,
  setActiveTab: (tab: Tab) => void,
  linkedGoalIds: string[],
  goalId: string,
  requireCloseConfirmation: boolean,
  handleClose: (_?: object, reason?: string) => void,
}

const View = ({
  showModal,
  handleClose,
  activeTab,
  linkedGoalType,
  setActiveTab,
  goalId,
  requireCloseConfirmation,
  userLinkedGoalIds,
  addLinkedGoalIds,
  removeLinkedGoalIds,
  replaceLinkedGoalIds,
  linkParentGoal,
  linkSupportingGoals,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrModal
    open={showModal}
    onClose={handleClose}
    {...props}
  >
    <LeadrModal.Header
      shouldConfirmClose={requireCloseConfirmation}
      confirmationQuestionText="Leave without saving?"
      confirmationConfirmText="Yes"
      confirmationCancelText="No"
    >
      <LeadrModal.Title>
        {linkedGoalType === LinkedGoalType.Parent ? 'Connect Parent Goal' : 'Connect Supporting Goal'}
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body css={styles.modalBody}>
      <div css={styles.tabWrapper}>
        {Object.values(Tab).map((tab) => (
          <TabNavItem
            key={tab}
            isActive={activeTab === tab}
            onClick={() => setActiveTab(tab as Tab)}
            renderNavItem={() => (
              <div>{tab}</div>
            )}
          />
        ))}
      </div>
      {activeTab === Tab.Existing && (
        <LinkExistingGoal
          linkedGoalType={linkedGoalType}
          requireCloseConfirmation={requireCloseConfirmation}
          userLinkedGoalIds={userLinkedGoalIds}
          addLinkedGoalIds={addLinkedGoalIds}
          removeLinkedGoalIds={removeLinkedGoalIds}
          replaceLinkedGoalIds={replaceLinkedGoalIds}
          linkParentGoal={linkParentGoal}
          linkSupportingGoals={linkSupportingGoals}
          goalId={goalId}
        />
      )}
      {activeTab === Tab.New && (
        <LinkNewGoal
          goalId={goalId}
          linkedGoalType={linkedGoalType}
          linkParentGoal={linkParentGoal}
          linkSupportingGoals={linkSupportingGoals}
        />
      )}
    </LeadrModal.Body>
    <LeadrModal.Footer>
      <div css={styles.infoText}>
        <span>Linking this goal may make it visible to other users.</span>
        <LeadrButton
          variant="text"
          component="a"
          href="https://leadr.helpscoutdocs.com/article/25-create-a-goal#Goal-Permissions-1Zc51"
          target="_blank"
          data-test-id="goalsLinkNewGoalLearnMore"
        >
          Learn More.
        </LeadrButton>
      </div>
      <div css={styles.modalButtons} id="modalButtons" />
    </LeadrModal.Footer>
  </LeadrModal>
);

export interface LinkedGoalModalProps extends Pick<ViewProps, 'showModal' | 'linkParentGoal' | 'linkSupportingGoals'> {
  linkedGoalType?: LinkedGoalType,
  goalId: string,
}

const LinkedGoalModal = ({
  linkedGoalType = LinkedGoalType.Parent,
  goalId,
  ...props
}: LinkedGoalModalProps): JSX.Element => {
  const { closeModal } = useShowLinkGoalModal();
  const [activeTab, setActiveTab] = useState(Tab.Existing);
  const { linkedGoalIds } = useGetLinkedGoalIds({ goalId, linkedGoalType });

  const {
    userLinkedGoalIds,
    addLinkedGoalIds,
    removeLinkedGoalIds,
    replaceLinkedGoalIds,
    hasUnsavedLinkedGoals,
  } = useUserLinkedGoalIds(linkedGoalIds);

  const requireCloseConfirmation = useMemo(() => {
    if (activeTab === Tab.Existing) {
      return hasUnsavedLinkedGoals;
    }

    return false;
  }, [activeTab, hasUnsavedLinkedGoals]);

  const handleClose = (_?: object, reason?: string): void => {
    if (reason !== 'escapeKeyDown' && reason !== 'backdropClick') {
      closeModal();
    }
  };

  const hookProps = {
    closeModal,
    activeTab,
    linkedGoalType,
    setActiveTab,
    linkedGoalIds,
    goalId,
    userLinkedGoalIds,
    addLinkedGoalIds,
    removeLinkedGoalIds,
    replaceLinkedGoalIds,
    requireCloseConfirmation,
    handleClose,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LinkedGoalModal;
