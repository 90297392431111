/* eslint-disable no-bitwise */
import { useQuery } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useMemo } from 'react';
import {
  ParticipantTypeEnum, ReviewCycleDetail,
} from '../Const/types';

interface getCycleByIdProps {
    queryKey: string[],
  }

const getCycleById = async ({ queryKey }: getCycleByIdProps): Promise<HttpCallReturn<ReviewCycleDetail>> => {
  const version = '2.5';
  const [orgId, , cycleId] = queryKey;

  const serverUrl = {
    host: getHost(hosts.reviewCycles, version),
    uri: `/organizations/${orgId}/reviewCycles/${cycleId}`,
  };
  return getApi<ReviewCycleDetail>(serverUrl, {});
};

interface useGetReviewCycleDetailProps {
  queryKey?: string[],
  id: string,
}

interface useGetReviewCycleDetailReturnProps {
  data: ReviewCycleDetail | undefined,
  status: string,
  isLoading: boolean,
  participants: string[],
}

export const useGetReviewCycleDetail = (
  { queryKey = [getOrganizationId() ?? '', 'reviewCyclesParticipants'], id }:useGetReviewCycleDetailProps,
): useGetReviewCycleDetailReturnProps => {
  const cycleKey = [...queryKey, id];

  const result = useQuery({
    queryKey: cycleKey,
    queryFn: getCycleById,
    enabled: !!id,
  });

  const data = result?.data?.response;
  const reviews = result.data?.response.reviews;

  const participantIdsToSend = useMemo(() => reviews?.map((review) => review.participants).flat().reduce<string[]>((accumulator, currentValue) => {
    if (currentValue.participantTypeEnum & ParticipantTypeEnum.Reviewee) {
      accumulator.push(currentValue.orgUserId);
    }
    return accumulator;
  }, []), [reviews]);

  return {
    data,
    status: result?.status,
    isLoading: result?.isLoading,
    participants: participantIdsToSend ?? [],
  };
};
