import { useMemo } from 'react';
import useList from './useList';

const useListItem = ({
  queryConfig,
  id,
}) => {
  const {
    useQueryDetail,
  } = queryConfig;

  const detailResult = useQueryDetail(id, {
    enabled: false,
  });

  const listResult = useList({
    queryConfig,
  });

  // Overwrite the most fresh data with the least fresh data
  listResult.item = useMemo(
    () => {
      if (listResult?.dataUpdatedAt > detailResult?.dataUpdatedAt) {
        return ({
          ...(detailResult?.data?.item ?? {}),
          ...(listResult?.listData?.[id] ?? {}),
        });
      }

      return ({
        ...(listResult?.listData?.[id] ?? {}),
        ...(detailResult?.data?.item ?? {}),
      });
    },
    [listResult?.dataUpdatedAt, listResult?.listData, detailResult?.dataUpdatedAt, detailResult?.data, id],
  );

  return listResult;
};

export default useListItem;
