import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import GridLearningCard from '~Learning/components/Shared/LearningDashboardCards/GridLearningCard';
import LearningCompletedCount from '~Learning/components/Shared/LearningDashboardCards/LearningCompletedCount';
import { AssignedLearning, LearningStatus } from '~Learning/const/interfaces';

const styles = {
  assignedGridDashboard: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(20.9375rem, 1fr));',
    gridAutoRows: '1fr',
    width: '100%',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '8.75rem',
  }),
};

interface AssignedGridDashboardProps extends ComponentProps<'div'> {
  assignedLearnings: AssignedLearning[] | undefined,
  onAssignedCardClick: (assignedLearning: AssignedLearning) => void,
}

const AssignedGridDashboard = ({
  assignedLearnings,
  onAssignedCardClick,
  ...props
}: AssignedGridDashboardProps): JSX.Element => (
  <div css={styles.assignedGridDashboard} {...props}>
    {assignedLearnings?.map((assignedLearning) => (
      <GridLearningCard
        key={assignedLearning.id}
        id={assignedLearning.id}
        data-test-id={`assignedLearningCard-${assignedLearning.id}`}
        onCardClick={() => onAssignedCardClick(assignedLearning)}
        learningType={assignedLearning.learningType}
        title={assignedLearning.title}
        description={assignedLearning.description}
        dueDate={assignedLearning.dueDate}
        assignedUsersInfo={assignedLearning.assignedUsersInfo}
        numberOfUsersAssigned={assignedLearning.numberOfUsersAssigned}
        isComplete={assignedLearning.status === LearningStatus.COMPLETED}
        renderCompletedSection={() => (
          <LearningCompletedCount
            numberOfUsersAssigned={assignedLearning.numberOfUsersAssigned}
            numberOfUsersCompleted={assignedLearning.numberOfUsersCompleted}
          />
        )}
      />
    ))}
  </div>
);

const SkeletonView = (): JSX.Element => (
  <MultipleSkeletonLoaders
    css={styles.assignedGridDashboard}
    numberOfSkeletons={12}
    renderSkeletonItem={() => (
      <CardSkeleton css={styles.cardSkeleton} />
    )}
  />
);

export default AssignedGridDashboard;

export { SkeletonView as AssignedGridDashboardSkeleton };
