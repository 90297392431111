import { css } from '@emotion/react';
import { useState, useMemo } from 'react';
import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { DropdownItem, DropdownSkeleton } from '~Common/V3/components/Dropdown';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useGetTeamOptions } from '~Home/hooks/TeamClarity/useGetTeamOptions';
import { useHomeSettings } from '~Home/hooks/useHomeSettings';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import { useSelectTeam } from '~Home/hooks/TeamClarity/useSelectTeam';
import { SelectChangeEvent } from '@mui/material';
import { palette } from '~Common/styles/colors';
import TeamClarityCardBody from './TeamClarityCardBody';
import TeamOptionsDropdown from './TeamOptionsDropdown';

const styles = {
  header: css({
    marginBottom: '0.5rem',
  }),
  teamOptionsDropdown: css({
    marginLeft: '0.5rem',
  }),
  emptyState: css({
    color: palette.neutrals.gray700,
  }),
  skeletonDropdown: css({
    minWidth: '50%',
    height: '1.25rem',
  }),
};

interface ViewProps extends Omit<LeadrCardProps, 'children'> {
  isLoading: boolean,
  dragHandleProps?: DraggableProvidedDragHandleProps,
  initialSelectedTeamId: string | undefined,
  selectedTeamId: string | undefined,
  teamOptions: DropdownItem[],
  showTeamsDropdown: boolean,
  handleSelectedTeamChange: (event: SelectChangeEvent<string>) => void,
  showNoTeamsEmptyState: boolean,
  showNoTeamClarityEmptyState: boolean,
}

const View = ({
  isLoading,
  dragHandleProps,
  initialSelectedTeamId,
  teamOptions,
  showTeamsDropdown,
  handleSelectedTeamChange,
  selectedTeamId,
  showNoTeamsEmptyState,
  showNoTeamClarityEmptyState,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <ModuleHeader
      css={styles.header}
      headerText="Team Clarity"
      renderRightSection={() => (
        <>
          {isLoading && (
            <DropdownSkeleton css={styles.skeletonDropdown} />
          )}
          {showTeamsDropdown && initialSelectedTeamId && (
            <TeamOptionsDropdown
              css={styles.teamOptionsDropdown}
              teamOptions={teamOptions}
              handleSelectedTeamChange={handleSelectedTeamChange}
              selectedTeamId={initialSelectedTeamId}
            />
          )}
        </>
      )}
      {...dragHandleProps}
    />
    {isLoading && (
      <HomeSkeleton
        numberOfSkeletons={2}
        height={2}
      />
    )}
    {!isLoading && (
      <>
        {showNoTeamsEmptyState && (
          <p css={styles.emptyState}>You are not currently assigned to a team in Leadr.</p>
        )}
        {showNoTeamClarityEmptyState && (
          <p css={styles.emptyState}>Answers to your teams&apos;s important alignment questions will be available shortly.</p>
        )}
        {(selectedTeamId || initialSelectedTeamId) && (
          <TeamClarityCardBody
            // @ts-expect-error TS is stupid!!!!!!!!!!!!!
            selectedTeamId={selectedTeamId || initialSelectedTeamId}
          />
        )}
      </>
    )}
  </LeadrCard>
);

export interface OrgClarityProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const TeamClarity = ({
  dragHandleProps,
  ...props
}: OrgClarityProps): JSX.Element => {
  const {
    data,
    isLoading: areTeamOptionsLoading,
  } = useGetTeamOptions();
  const { data: homeSettings } = useHomeSettings();
  const { mutate: selectTeamMutation } = useSelectTeam();
  const [selectedTeamId, setSelectedTeamId] = useState<string>();

  const teams = data?.response.teams;

  const initialSelectedTeamId = homeSettings?.response.selectedTeamId || teams?.[0]?.id;

  const handleSelectedTeamChange = (event: SelectChangeEvent<string>): void => {
    setSelectedTeamId(event.target.value);
    selectTeamMutation(event.target.value);
  };

  const teamOptions = useMemo(() => (
    teams?.map((team) => ({
      value: team.id,
      text: team.name,
    })) || []), [teams]);

  const [isLoading] = useSkeletonLoaders(areTeamOptionsLoading);

  const userHasTeams = !!data?.response.userHasTeams;

  const hookProps = {
    isLoading,
    dragHandleProps,
    initialSelectedTeamId,
    teamOptions,
    handleSelectedTeamChange,
    selectedTeamId,
    showTeamsDropdown: !isLoading && teamOptions.length > 0 && userHasTeams && !!initialSelectedTeamId,
    showNoTeamsEmptyState: !userHasTeams,
    showNoTeamClarityEmptyState: userHasTeams && teamOptions.length === 0,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default TeamClarity;
