/* eslint-disable react/button-has-type */
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import Button, { BUTTON_COLOR, BUTTON_VARIANT, ButtonProps } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const CreateButton = (props: Omit<ButtonProps, 'icon' | 'buttonColor'>): JSX.Element => (
  <Button
    buttonColor={BUTTON_COLOR.ADDITIVE}
    icon={faPlus}
    {...props}
  />
);

CreateButton.propTypes = {};

CreateButton.defaultProps = {};

export default CreateButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
