import Cookies from 'js-cookie';
import { logoutRealtime } from '~Common/functions/ablyRealtime';
import {
  setStorageItem,
} from '~Common/utils/localStorage';
import { AUTH0_OAUTH_STATE_KEY } from '~Root/const';

export const extractDraftFromLocalStorage = () => Object.entries(localStorage)
  .filter((x) => x[0].startsWith('draft.'));

export const clearStorage = () => {
  const draftKeys = extractDraftFromLocalStorage();
  Object.keys(Cookies.get()).forEach((e) => {
    if (e === AUTH0_OAUTH_STATE_KEY) {
      return;
    }

    Cookies.remove(e, { path: '/' });
  });
  window.localStorage.clear();
  window.sessionStorage.clear();
  logoutRealtime();

  draftKeys.forEach((draftDetails) => {
    setStorageItem(draftDetails[0], draftDetails[1]);
  });
};

export const setUserInfo = (state, data) => {
  state.userId = data.userId;
  state.profileImageUrl = data.profileImageUrl;
  state.hFirstName = data.firstName;
  state.hLastName = data.lastName;
  state.firstName = data.firstName;
  state.lastName = data.lastName;
  state.email = data.email;
  state.birthday = data.birthdayInMillis;
  state.joiningDateInMillis = data.joiningDateInMillis;
  state.phoneNumber = data.phoneNumber;
  state.jobTitle = data.jobTitle;
  state.department = data.department;
  state.aboutMe = data.aboutMe;
  state.food = data.food;
  state.snack = data.snacks;
  state.drink = data.drink;
  state.strengths = data.strengths;
  state.instagram = data.instagram;
  state.linkedIn = data.linkedIn;
  state.twitter = data.twitter;
  state.applicationGroupType = data.applicationGroupType;
  state.applicationGroupName = data.applicationGroupName;
  state.hasDirects = data.hasDirects;
  state.organizationUserId = data.organizationUserId;
  state.eligibleForBenefits = data.eligibleForBenefits;
  const orgDetails = data.orgDetails || {};
  state.enableHRModule = orgDetails ? orgDetails.enableHRModule : false;
  state.easeUrl = (orgDetails && orgDetails.easeUrl) || '';
  const personalityDetails = data.personalities;
  if (personalityDetails) {
    const enneagramDetails = personalityDetails.Enneagram;
    if (enneagramDetails) {
      state.enneagram = enneagramDetails.label;
    }
    const discDetails = personalityDetails.DiSC;
    if (discDetails) {
      state.disc = discDetails.label;
    }
    const myersBriggsDetails = personalityDetails['Myers Briggs'];
    if (myersBriggsDetails) {
      state.myersBriggs = myersBriggsDetails.label;
    }
  }
};
