import { css } from '@emotion/react';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

import { palette } from '~Common/styles/colors';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';

const styles = {
  mobileTeamName: css({
    color: palette.neutrals.gray700,
    display: 'flex',
    alignItems: 'center',
    gap: '.375rem',
    fontSize: '.75rem',
  }),
  icon: css({
    width: '.75rem',
    height: '.75rem',
  }),
};

interface ViewProps {
  teamName: string,
}

const View = ({
  teamName,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.mobileTeamName}
    {...props}
  >
    <FontAwesomeIcon css={styles.icon} icon={faUsers} />
    <span>{teamName}</span>
  </div>
);

interface MobileTeamNameProps {
  teamId: string,
  huddleId: string,
}

const MobileTeamName = ({
  teamId,
  huddleId,
  ...props
}: MobileTeamNameProps): JSX.Element => {
  const { data: teamDetailsData, isLoading } = useGetTeamDetails({ teamId, huddleId });

  const teamName = useMemo(() => (
    teamDetailsData?.response.name
  ), [teamDetailsData?.response.name]);

  if (isLoading || !teamName) {
    return <></>;
  }

  const hookProps = {
    teamName,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MobileTeamName;
