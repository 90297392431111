import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';

import withRoot from '../styles/root';

const style = withRoot();

const LeadrStyleRoot = () => (
  <>
    <Global
      styles={style}
    />
    <CssBaseline />
  </>
);

export default LeadrStyleRoot;
