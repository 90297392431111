import { createAction } from '@reduxjs/toolkit';

import { registerAction } from '~Deprecated/reducers/idle';
import { mutateState } from '~Deprecated/utils/reduxUtils';

const setIsIdleAction = createAction('LEADR.SET_IS_IDLE');

function setIsIdle(state, { payload }) {
  return mutateState(state, (draftState) => {
    draftState.isIdle = payload.isIdle;
    return draftState;
  });
}

registerAction(setIsIdleAction, setIsIdle);

export {
  setIsIdleAction,
};
