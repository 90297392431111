import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { METRIC_HEALTH_REPORT_SHAPE } from '~Common/const/executiveInsights';
import CircleAvatar from '~Deprecated/ui/components/Users/Avatars/CircleAvatar';
import UserInsightsReport from '~Deprecated/ui/components/Insights/InsightDetails/UserInsightsReport';
import CardHeader from '../../Card/CardHeader';

const style = css`
  width: 100%;

  & .cardHeader {
    height: 56px;
  }
`;

const headerStyle = css`
  & h3 {
    max-width: 180px;
  }
`;

const InsightDetailRow = ({
  imgUrl, name, metricHealthReport, renderTooltip, size, className, managerId, onClick, id,
}) => {
  const doClick = () => {
    if (!onClick) return;
    onClick(id);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
    <div
      css={style}
      className={className}
      onClick={doClick}
    >
      <CardHeader
        css={headerStyle}
        name={name}
        renderAvatar={(avatarProps) => (
          <CircleAvatar
            {...avatarProps}
            size={size}
            imgUrl={imgUrl}
          />
        )}
        renderMeta={() => {
          if (!managerId) {
            return false;
          }

          if (!metricHealthReport) {
            return false;
          }

          return (
            <UserInsightsReport
              metricHealthReport={metricHealthReport}
              renderTooltip={renderTooltip}
            />
          );
        }}
      />
    </div>
  );
};

InsightDetailRow.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string,
  metricHealthReport: METRIC_HEALTH_REPORT_SHAPE,
  renderTooltip: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
  managerId: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
};

InsightDetailRow.defaultProps = {
  imgUrl: '',
  name: 'Sir Meowkawitz',
  metricHealthReport: null,
  renderTooltip: null,
  size: 24,
  className: '',
  managerId: '',
  id: '',
  onClick: null,
};

export default InsightDetailRow;
