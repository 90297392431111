import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import { intl } from '../../locale';

/**
 * flattenMessages helps to flatted the nested json used for react-intl.
 * As latest versions of react-intl does not support nested json, added a function to flatten the nested json.
 *
 * @param {*} nestedMessages react-intl text in nested json format
 * @param {*} prefix, For flattened intl text
 */
const flattenMessages = ((nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }

  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }
    return messages;
  }, {});
});

const locale = 'en';
const message = intl[locale];

const Intl = (props) => {
  const {
    children,
  } = props;

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={flattenMessages(message)}
      defaultLocale="en"
    >
      {children}
    </IntlProvider>
  );
};

Intl.propTypes = {
  children: PropTypes.element,
};

export default Intl;
