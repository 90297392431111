import {
  ComponentProps,
  useCallback,
  useMemo,
  useState,
  useEffect,
  memo,
} from 'react';
import { css } from '@emotion/react';
import CreateAgendaItem, { OnSaveProps } from '~Meetings/components/details/agenda/CreateAgendaItem';
import AgendaCommentSection from '~Meetings/components/details/agenda/AgendaCommentSection';
import RightSideComponent from '~Meetings/components/details/agenda/AgendaTopic/RightSideComponent';
import LeftSideComponent from '~Meetings/components/details/agenda/AgendaTopic/LeftSideAgendaComponent';
import BodyComponent from '~Meetings/components/details/agenda/AgendaTopic/BodyComponent';
import AgendaItem from '~Meetings/components/details/agenda/AgendaTopic/AgendaTopicWrapper';
import { useShowCommentSection } from '~Meetings/hooks/useShowCommentSection';
import { getOrganizationId, getOrganizationUserId, getUserId } from '~Common/utils/localStorage';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import useMeetingRealtime from '~Meetings/hooks/useMeetingRealtime';
import { getLocalId } from '~Common/utils/uuid';
import {
  AgendaCreatePayload,
  AgendaUpdatePayload,
  AgendaReorderPayload,
  AgendaTopicRealTimeMessageType,
} from '~Meetings/const/realTimeMeetingMessages';
import { useGetTopicTimeString } from '~Meetings/hooks/useGetTopicTimeString';
import { AgendaTopic as AgendaItemType } from '~Meetings/hooks/v2/useGetAgendas';
import { useEditAgendaItem } from '~Meetings/hooks/v2/useEditAgendaItem';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { RecursiveObject } from '~Meetings/stores/useMeetingRealtimeStore';
import { useShowAgendaEditor } from '~Meetings/hooks/utils/useShowAgendaEditor';
import { AgendaTopicPermission } from '@leadr-hr/types';

const styles = {
  agendaItemContainer: css({
    width: '100%',
  }),
  editorContainer: css({
    width: '100%',
  }),
};

export interface ViewProps extends Omit<ComponentProps<'div'>, 'onChange'> {
  agendaItem: AgendaItemType,
  activeReorderers?: string[],
  hasBeenEdited: boolean,
  huddleId: string,
  onAgendaEditClick: () => void,
  onCancelEditor: () => void,
  showEditAgendaEditor: boolean,
  timeSinceComment: string,
  timeSinceEdit: string,
  type: MeetingTypeEnum,
  onSave: (props: OnSaveProps) => void,
  draftKey: string[],
  isHighlighted: boolean,
  showAddAttachmentsButton: boolean,
  sectionId?: string,
  highlightedItem?: string,
  realtimeCreatingAgenda?: AgendaCreatePayload[] | RecursiveObject<AgendaCreatePayload[]>,
}

const View = ({
  agendaItem,
  activeReorderers,
  hasBeenEdited,
  highlightedItem,
  huddleId,
  isHighlighted,
  onAgendaEditClick,
  onCancelEditor,
  realtimeCreatingAgenda,
  showEditAgendaEditor,
  timeSinceComment,
  timeSinceEdit,
  type,
  onSave,
  sectionId,
  draftKey,
  showAddAttachmentsButton,
}: ViewProps): JSX.Element => (
  <div css={styles.agendaItemContainer}>
    {showEditAgendaEditor && (
      <div css={styles.editorContainer}>
        <CreateAgendaItem
          huddleId={huddleId}
          itemId={agendaItem.id}
          initialValue={agendaItem.text}
          onCancel={onCancelEditor}
          onSave={onSave}
          isPrivate={agendaItem.isPrivate}
          isRecurring={agendaItem.isRecurring}
          draftKey={draftKey}
          showAddAttachmentsButton={showAddAttachmentsButton}
          preferDraftValue
          initialAttachments={agendaItem.attachments}
        />
      </div>
    )}

    {!showEditAgendaEditor && (
      <AgendaItem
        activeReorderers={activeReorderers}
        isHighlighted={isHighlighted}
        renderLeftSideComponent={(leftSideStyles) => (
          <LeftSideComponent
            css={leftSideStyles}
            huddleId={huddleId}
            agendaId={agendaItem.id}
            isComplete={agendaItem.isComplete}
            sectionId={sectionId}
          />
        )}
        renderBodyComponent={(bodyStyles) => (
          <BodyComponent
            css={bodyStyles}
            hasBeenEdited={hasBeenEdited}
            timeSinceComment={timeSinceComment}
            timeSinceEdit={timeSinceEdit}
            attachments={agendaItem.attachments}
            textHTML={agendaItem.text}
            huddleId={huddleId}
            sectionId={sectionId}
            agendaItemId={agendaItem.id}
          />
        )}
        renderRightSideComponent={(rightSideStyles) => (
          <RightSideComponent
            css={rightSideStyles}
            agendaItem={agendaItem}
            creatorOrgUserId={agendaItem.creator?.orgUserId}
            huddleId={huddleId}
            onAgendaEditClick={onAgendaEditClick}
            type={type}
            sectionId={sectionId}
          />
        )}
        renderFooterComponent={(footerStyles) => (
          <AgendaCommentSection
            css={footerStyles}
            agendaItem={agendaItem}
            type={type}
            huddleId={huddleId}
            notes={agendaItem.children}
            editAgendaTopic={onAgendaEditClick}
            // @ts-expect-error Fix later
            realtimeCreatingAgenda={realtimeCreatingAgenda}
            highlightedItem={highlightedItem}
            sectionId={sectionId}
          />
        )}
      />
    )}
  </div>
);

export interface HuddleAgendaItemProps extends Omit<ComponentProps<'div'>, 'onChange'> {
  agendaItem: AgendaItemType,
  huddleId: string,
  type: MeetingTypeEnum,
  sectionId?: string,
  highlightedItem?: string,
  realtimeCreatingAgenda?: AgendaCreatePayload[] | RecursiveObject<AgendaCreatePayload[]>,
  realtimeUpdatingAgenda?: AgendaUpdatePayload[] | RecursiveObject<AgendaUpdatePayload[]>,
  realtimeReorderingAgenda?: AgendaReorderPayload[] | RecursiveObject<AgendaReorderPayload[]>,
}

const HuddleAgendaItem = ({
  agendaItem,
  highlightedItem,
  huddleId,
  realtimeCreatingAgenda,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  realtimeUpdatingAgenda,
  realtimeReorderingAgenda,
  type,
  sectionId,
  ...props
}: HuddleAgendaItemProps): JSX.Element => {
  const [agendaTopicUpdateEventId, setAgendaTopicUpdateEventId] = useState('');
  const orgId = getOrganizationId();
  const userId = getUserId();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const draftKey = useMemo(() => [orgId!, huddleId, 'agenda', agendaItem.id], [orgId, huddleId, agendaItem.id]);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user } = useUserProfile(userId!);

  const { mutate: doEditAgendaItem } = useEditAgendaItem({
    agendaItemId: agendaItem.id,
    sectionId,
  });

  const isOwner = agendaItem.creator?.orgUserId === getOrganizationUserId();

  const { sendMessage } = useMeetingRealtime({ huddleId });

  const {
    showAgendaEditor,
    hideAgendaEditor,
    useIsAgendaEditorVisisble,
  } = useShowAgendaEditor();

  const showEditAgendaEditor = useIsAgendaEditorVisisble({ identifier: agendaItem.id });

  const { showCommentSection } = useShowCommentSection(agendaItem.id);
  const hasBeenEdited = !!agendaItem.lastModifiedInMillis && agendaItem.lastModifiedInMillis > agendaItem.createdInMillis;
  const timeSinceEdit = useGetTopicTimeString(agendaItem.lastModifiedInMillis);
  const timeSinceComment = useGetTopicTimeString(agendaItem.createdInMillis);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const activeReorderers = useMemo(() => (
    // @ts-expect-error ToDo: Fix
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    realtimeReorderingAgenda?.data?.map((payload) => (payload.updater.orgUserId))
  ), [realtimeReorderingAgenda]);

  const [isHighlighted, setIsHighlighted] = useState(false);

  useEffect(() => {
    if (highlightedItem === agendaItem.id) {
      setIsHighlighted(true);

      // Set a timeout to reset the highlight after 3500ms
      const timeoutId = setTimeout(() => {
        setIsHighlighted(false);
      }, 3500);

      // Cleanup function to clear the timeout if the component unmounts
      return () => {
        clearTimeout(timeoutId);
      };
    }

    return undefined;
  }, [highlightedItem, agendaItem.id]);

  const onAgendaEditClick = useCallback(() => {
    const eventId = getLocalId();
    setAgendaTopicUpdateEventId(eventId);
    showAgendaEditor({ identifier: agendaItem.id });

    sendMessage(
      AgendaTopicRealTimeMessageType.Update,
      {
        id: agendaItem.id,
        eventId,
        updater: {
          // We should always have the current user's profile
          orgUserId: user!.organizationUserId, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          firstName: user!.firstName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          lastName: user!.lastName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          profileImageUrl: user!.profileImageUrl, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        },
      },
    );
  }, [showAgendaEditor, agendaItem.id, sendMessage, user]);

  const onCancelEditor = useCallback(() => {
    hideAgendaEditor({ identifier: agendaItem.id });

    sendMessage(
      AgendaTopicRealTimeMessageType.UpdateCanceled,
      {
        id: agendaItem.id,
        eventId: agendaTopicUpdateEventId,
      },
    );
  }, [agendaItem.id, agendaTopicUpdateEventId, hideAgendaEditor, sendMessage]);

  const onSave = useCallback(({
    text,
    isRecurring,
    isPrivate,
  }: OnSaveProps) => {
    doEditAgendaItem({
      agendaId: agendaItem.id,
      huddleId,
      text,
      isPrivate,
      isRecurring,
      eventId: agendaTopicUpdateEventId,
    });
  }, [
    agendaItem.id,
    huddleId,
    doEditAgendaItem,
    agendaTopicUpdateEventId,
  ]);

  const showAddAttachmentsButton = agendaItem.permissions.includes(AgendaTopicPermission.CanAddAttachment);

  const hookProps = {
    agendaItem,
    hasBeenEdited,
    highlightedItem,
    huddleId,
    isHighlighted,
    isOwner,
    onAgendaEditClick,
    onCancelEditor,
    showEditAgendaEditor,
    showCommentSection,
    timeSinceComment,
    timeSinceEdit,
    type,
    onSave,
    sectionId,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    activeReorderers,
    draftKey,
    realtimeCreatingAgenda,
    showAddAttachmentsButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default memo(HuddleAgendaItem, (prevProps, nextProps) => {
  if (
    prevProps.realtimeReorderingAgenda !== nextProps.realtimeReorderingAgenda
    || prevProps.realtimeCreatingAgenda !== nextProps.realtimeCreatingAgenda
    || prevProps.realtimeUpdatingAgenda !== nextProps.realtimeUpdatingAgenda
    || prevProps.highlightedItem !== nextProps.highlightedItem
    || prevProps.agendaItem !== nextProps.agendaItem
    || prevProps.sectionId !== nextProps.sectionId
  ) {
    return false;
  }

  return true;
});
