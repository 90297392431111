import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { AgendaTemplates } from '@leadr-hr/types';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { produce } from 'immer';
import { toast } from '~Common/components/Toasts';
import { getLocalId } from '~Common/utils/uuid';
import {
  EditMeetingTemplateResponse,
  EditMeetingTemplatePayload,
  MeetingTemplateType,
  EditAgendaTemplateDetails,
  EditAgendaSectionTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { createTopicTemplateDrawerTemplate } from '~Meetings/components/topic-suggestions/CreateTopicTemplateDrawer';
import { useDispatch } from 'react-redux';
import { meetingTopicTemplatesQueryKeys } from './queryKeys';
import { GetMeetingTemplatesResponse } from './useGetCustomMeetingTemplates';

const editMeetingTemplate = ({
  id,
  text,
  isManagerOnly,
  isPrivate,
  children,
}: EditMeetingTemplatePayload): Promise<HttpCallReturn<EditMeetingTemplateResponse>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates`;

  return patchApi<EditMeetingTemplateResponse>(URL, {
    id,
    text,
    isManagerOnly,
    isPrivate,
    children,
  });
};

function isTemplateAgendaSection(item: EditAgendaTemplateDetails): item is EditAgendaSectionTemplateDetails {
  return (item as EditAgendaSectionTemplateDetails).children !== undefined;
}

interface UseEditMeetingTemplateParams {
  meetingTemplateType: MeetingTemplateType,
  templateVisibility: string,
  openTopicSuggestionsDrawer: () => void,
  maxTopics: number,
  maxSections: number,
  onMutateCallback?: () => void,
}

export const useEditMeetingTemplate = ({
  meetingTemplateType,
  templateVisibility,
  onMutateCallback,
  openTopicSuggestionsDrawer,
  maxTopics,
  maxSections,
}: UseEditMeetingTemplateParams): UseMutationResult<HttpCallReturn<EditMeetingTemplateResponse>, unknown, EditMeetingTemplatePayload> => {
  const orgId = getOrganizationId() ?? '';
  const dispatch = useDispatch();

  const queryKeyForList = meetingTopicTemplatesQueryKeys.customTemplatesList({
    orgId,
    meetingTemplateType,
    templateVisibility,
  });

  return useMutation({
    mutationFn: editMeetingTemplate,
    onMutate: async ({
      id,
      isManagerOnly,
      isPrivate,
      text,
      children,
    }) => {
      onMutateCallback?.();

      const previousMeetingTemplates = queryClient.getQueryData(queryKeyForList);
      await queryClient.cancelQueries({ queryKey: queryKeyForList });

      queryClient.setQueryData<HttpCallReturn<GetMeetingTemplatesResponse>>(queryKeyForList, (originalData) => {
        if (originalData) {
          const templateIndex = originalData.response.meetingTemplates.findIndex((template) => template.id === id);

          if (templateIndex !== -1) {
            const originalTemplate = originalData.response.meetingTemplates[templateIndex];

            const formattedTemplateAgendaItems = children.map((templateAgendaItem) => {
              if (isTemplateAgendaSection(templateAgendaItem)) {
                const templateAgendaSection = {
                  ...templateAgendaItem,
                  id: templateAgendaItem.id || getLocalId(),
                  permissions: [AgendaTemplates.AgendaTemplatePermission.EditTemplate],
                  children: templateAgendaItem.children.map((templateAgendaTopic) => ({
                    ...templateAgendaTopic,
                    id: templateAgendaTopic.id || getLocalId(),
                    permissions: [AgendaTemplates.AgendaTemplatePermission.EditTemplate],
                  })),
                };

                return templateAgendaSection;
              }

              return {
                ...templateAgendaItem,
                id: templateAgendaItem.id || getLocalId(),
                permissions: [AgendaTemplates.AgendaTemplatePermission.EditTemplate],
              };
            });

            const newTemplate = {
              ...originalTemplate,
              text,
              isManagerOnly: isManagerOnly as boolean,
              isPrivate: isPrivate as boolean,
              children: formattedTemplateAgendaItems,
            };

            return produce(originalData, (draft) => {
              draft.response.meetingTemplates.splice(templateIndex, 1, newTemplate);
            });
          }
        }

        return originalData;
      });

      return { previousMeetingTemplates };
    },
    onError: (_, {
      id,
      text,
      children,
      isManagerOnly,
      isPrivate,
    }, snapshot) => {
      toast.error('There was an error editing the template. Please try again.', {
        autoClose: 1500,
      });

      queryClient.setQueryData(queryKeyForList, snapshot?.previousMeetingTemplates);

      dispatch(pushDrawerAction({
        drawer: {
          ...createTopicTemplateDrawerTemplate,
          args: {
            initialTitle: text,
            initialTopics: children,
            isEditMode: true,
            isPrivate,
            initialIsManager: isManagerOnly,
            templateId: id,
            openTopicSuggestionsDrawer,
            maxTopics,
            maxSections,
          },
        },
      }));
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: meetingTopicTemplatesQueryKeys.customTemplatesLists(getOrganizationId() ?? '') });
    },
  });
};
