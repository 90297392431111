import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
  ChartOptions,
  TooltipOptions,
  CoreChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { merge } from 'lodash';
import { css, SerializedStyles } from '@emotion/react';
import { RefObject, useRef } from 'react';
import { GraphScrollIndicators } from './GraphScrollIndicators';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface BarGraphData {
  labels: string[] | string[][];
  datasets: {
    data: {
      x: number | string,
      y: number,
    }[] | number[],
    grouped?: boolean,
    backgroundColor?: string[],
  }[];
}

function splitLabel(label: string | string[]): string[] {
  if (Array.isArray(label)) return label;
  return label.split(' ');
}

const DATAPOINT_WIDTH = 10;
const PRINT_DATAPOINT_HEIGHT = 7;

const styles = {
  wrapper: (isMobile: boolean) => css({
    position: 'relative',
    overflowX: isMobile ? 'unset' : 'auto',
    height: '100%',
    maxWidth: '100%',
    width: '100%',
    marginBottom: '2rem',
  }),
  chart: (numDatapoints: number, height?: string, isMobile = false) => css({
    width: `${numDatapoints * DATAPOINT_WIDTH}rem`,
    minWidth: '100%',
    height: height ?? '100%',
    position: 'relative',
  }, {
    ...isMobile ? {
      height: `${numDatapoints * PRINT_DATAPOINT_HEIGHT}rem`,
      width: '100%',
    }
      : { },
  }),
};

interface ViewProps {
  height?: string,
  data: BarGraphData,
  maxDatasetLength: number,
  options: ChartOptions,
  wrapperRef: RefObject<HTMLDivElement>
  chartStyleOverrides?: SerializedStyles,
  isMobile?: boolean,
}

const View = ({
  options,
  data,
  height = '20rem',
  maxDatasetLength,
  wrapperRef,
  isMobile = false,
  chartStyleOverrides,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.wrapper(isMobile)} ref={wrapperRef}>
    <GraphScrollIndicators wrapperRef={wrapperRef} />
    <div css={[styles.chart(maxDatasetLength, height, isMobile), chartStyleOverrides]}>
      <Bar options={options as CoreChartOptions<'bar'>} data={data as ChartData<'bar'>} {...props} />
    </div>
  </div>
);

interface BarChartProps extends Pick<ViewProps, 'chartStyleOverrides'> {
  height?: string,
  data: BarGraphData
  options: ChartOptions,
  tooltipOptions: Partial<TooltipOptions>
  isMobile?: boolean,
}

export const BarChart = ({
  data,
  options,
  tooltipOptions,
  ...props
}: BarChartProps): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const maxDatasetLength = Math.max(...data.datasets.map((item) => item.data?.length ?? 0));

  const defaultOptions: ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...tooltipOptions,
      },
    },
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const remappedLabels = data.labels.map((label) => splitLabel(label));

  const hookProps = {
    data: {
      ...data,
      labels: remappedLabels,
    },
    maxDatasetLength,
    options: merge(defaultOptions, options),
    wrapperRef,
  };

  return (
    <View {...hookProps} {...props} />
  );
};
