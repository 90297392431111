import { css } from '@emotion/react';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { palette } from '~Common/styles/colors';
import { ComponentProps } from 'react';

const styles = {
  listSection: css`
    width: 100%;
    padding-bottom: 1rem;
    border-bottom: .0625rem solid ${palette.neutrals.gray200};
  `,
  skeleton: css`
    max-width: 100%;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 1rem;
  `,
};

interface ListSectionProps extends ComponentProps<'div'> {
  renderContents: () => JSX.Element,
}

const ListSection = ({ renderContents, ...props }: ListSectionProps): JSX.Element => (
  <div css={styles.listSection} {...props}>{renderContents()}</div>
);

const SkeletonView = ({ ...props }): JSX.Element => (
  <SkeletonLoader css={styles.skeleton} renderComponent={() => (<></>)} {...props} />
);

export default ListSection;

export { SkeletonView as ListSectionSkeleton };
