import { toast } from 'react-toastify';
import { ReactText, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { getFeatureVersion } from '~Deprecated/selectors/featureFlags/getFeatureFlags';
import AppUpdateToast from '~Common/components/Toasts/AppUpdateToast';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';
import { useMobileAppUpdate } from '~Root/hooks/useMobileAppUpdate';

const AppUpdated = (): boolean => {
  const version = useSelector(getFeatureVersion);
  const previousVersion = usePrevious(version);
  const toastId = useRef<ReactText | number | null>(null);
  const refreshPage = (): void => {
    window.location.reload();
    toast.dismiss();
  };
  useEffect(() => {
    if (!IS_MOBILE_APP && version && previousVersion && version !== previousVersion) {
      if (version === 'OFF') {
        if (toastId.current) {
          toast.dismiss(toastId.current);
          toastId.current = null;
        }
      } else {
        toastId.current = toast(<AppUpdateToast onButtonClick={refreshPage} />, {
          autoClose: false,
          position: 'bottom-center',
        });
      }
    }
  }, [previousVersion, version]);

  useMobileAppUpdate();

  return false;
};

export default AppUpdated;
