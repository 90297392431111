// Note: This was initially Typescript but I admitted defeat in handling mui's
// AutocompleteProps generic. There are open issues for some of their typing choices
// on Github but in the meantime, JSX.

// Note: This component does not allow the user to create new options - if you do need or want that use the AutoCompleteFreeForm component
import { startCase } from 'lodash';
import * as PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import MuiAutocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { uuid } from '~Common/utils/uuid';
import { autoCompleteStyles } from '~Common/V3/styles/AutoComplete';

const styles = {
  ...autoCompleteStyles,
};
export function Autocomplete({
  name,
  label = startCase(name),
  required = false,
  disablePortal = false,
  defaultValue,
  ...props
}) {
  const { control, formState: { errors } } = useFormContext();
  const error = errors[name];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <>
          <MuiAutocomplete
            css={[styles.autocomplete, (error ? styles.error : null)]}
            disablePortal={disablePortal}
            error={error}
            onChange={(_, data) => (typeof data === 'object' ? onChange(data?.value) : onChange(data))}
            renderOption={(optionProps, option) => (
              <li {...optionProps} key={typeof option === 'object' ? option.value : uuid()}>
                {typeof option === 'object' ? option.label : option}
              </li>
            )}
            {...props}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                label={label}
                required={required}
              />
            )}
            defaultValue={defaultValue}
          />
          {error && (
            <FormHelperText error css={styles.errorMessage}>{error.message}</FormHelperText>
          )}
        </>
      )}
    />
  );
}

Autocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOf([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  required: PropTypes.bool,
  disablePortal: PropTypes.bool,
};

Autocomplete.defaultProps = {
  defaultValue: undefined,
  label: undefined,
  required: false,
  disablePortal: false,
};
