import { css, SerializedStyles } from '@emotion/react';
import { withTruncate } from '~Common/styles/mixins';

import {
  drawerHeaderCloseButtonColor,
  drawerHeaderFontColor,
  palette,
} from '~Common/styles/colors';

const styles = {
  header: (hideBorder: boolean) => css({
    background: palette.neutrals.white,
    color: drawerHeaderFontColor,
    fontSize: '1.125rem',
    marginTop: '1.5rem',
    marginRight: 'max(1.5rem, env(safe-area-inset-right))',
    marginBottom: 0,
    marginLeft: 'max(1.5rem, env(safe-area-inset-left))',
    paddingBottom: hideBorder ? '0' : '1.25rem',
    borderBottom: hideBorder ? 'none' : `1px solid ${palette.neutrals.gray200}`,
  }),
  headerFlexContainer: css({
    display: 'flex',
  }),
  closeButton: css`
    flex-shrink: 0;
    color: ${drawerHeaderCloseButtonColor};
  `,
  backButton: css`
    flex-shrink: 0;
    margin-right: 0.5rem;
    color: ${drawerHeaderCloseButtonColor};
  `,
  title: css`
    flex: 1;
    font-weight: 600;
    
    ${withTruncate()};
  `,
  instructions: css`
    margin-top: 0.5rem;
    font-size: .875rem;
    color: ${palette.neutrals.gray500};
  `,
  hr: css``,
};

interface DrawerHeaderProps {
  title: string,
  renderCloseButton?: (closeButtonStyles: SerializedStyles) => JSX.Element,
  renderBackButton?: (backButtonStyles: SerializedStyles) => JSX.Element,
  renderDrawerInstructions?: (drawerInstructionsStyles: SerializedStyles) => JSX.Element,
  hideBorder?: boolean,
}

const DrawerHeader = ({
  title,
  renderCloseButton,
  renderBackButton,
  renderDrawerInstructions,
  hideBorder = false,
}: DrawerHeaderProps): JSX.Element => (
  <div css={styles.header(hideBorder)}>
    <div css={styles.headerFlexContainer}>
      {renderBackButton?.(styles.backButton)}
      <div css={styles.title}>{title}</div>
      {renderCloseButton?.(styles.closeButton)}
    </div>
    <div>
      {renderDrawerInstructions?.(styles.instructions)}
    </div>
  </div>
);

export default DrawerHeader;
