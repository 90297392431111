import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import { ReactText, useRef } from 'react';
import { getHost, hosts } from '~Deprecated/services/config';
import { ErrorShape } from '~Admin/Importer/Const/types';
import { teamsQueryKeys } from './queryKeys';
import { CreateEditTeamPayload } from '../const/types';

interface CreateTeamReturn {
  id: string,
}

const createTeam = (
  createTeamPayload: CreateEditTeamPayload,
): Promise<HttpCallReturn<CreateTeamReturn>> => {
  const serverUrl = {
    // Host doesn't matter, but need this format to use the V2 endpoint
    host: getHost(hosts.reviewCycles, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/teams`,
  };

  return postApi<CreateTeamReturn>(serverUrl, createTeamPayload);
};

interface UseCreateTeamParams {
  handleCompletion?: () => void,
}

export const useCreateTeam = ({
  handleCompletion,
}: UseCreateTeamParams = {}): UseMutationResult<HttpCallReturn<CreateTeamReturn>, unknown, CreateEditTeamPayload> => {
  const orgId = getOrganizationId() ?? '';
  const toastId = useRef<ReactText | number | null>(null);

  // TODO: Optimistic Updates
  return useMutation({
    mutationFn: createTeam,
    onMutate: () => {
      toastId.current = toast.info('Creating Team...', { autoClose: false });
    },
    onError: (error: ErrorShape) => {
      if (error.message) {
        toast.update(toastId.current, {
          render: error.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: 'There was an error creating your team. Please try again.',
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      }
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully created your team!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      handleCompletion?.();

      void queryClient.invalidateQueries({ queryKey: teamsQueryKeys.lists(orgId) });
      void queryClient.invalidateQueries({ queryKey: [orgId, 'admin', 'ACTIVE,CREATED,INVITED,INACTIVE'] });
    },
  });
};
