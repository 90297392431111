import { SCORE_NAMES } from '~Common/const/executiveInsights';

interface TooltipTargets {
    self: JSX.Element
    individual: (name: string) => JSX.Element,
    group: JSX.Element,
}

type StoplightTooltips = Record<typeof SCORE_NAMES[keyof typeof SCORE_NAMES], TooltipTargets>;

export const coachingTooltips: StoplightTooltips = {
  [SCORE_NAMES.GOOD]: {
    self: (
      <p>
        You and your manager have had a 1:1 in the last 30 days.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} and their manager have had a 1:1 in the last 30 days.`}
      </p>
    ),
    group: (
      <p>
        These users have had 1:1s with their managers in the last 30 days.
      </p>
    ),
  },
  [SCORE_NAMES.OK]: {
    self: (
      <p>
        You have had a 1:1 with your manager in the last 30 days, but there weren&apos;t any new topics or comments.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has had a 1:1 with their manager in the last 30 days, but there weren't any new topics or comments.`}
      </p>
    ),
    group: (
      <p>
        These users have had 1:1s with their managers in the last 30 days, but there weren&apos;t any new topics or comments.
      </p>
    ),
  },
  [SCORE_NAMES.IMPROVE]: {
    self: (
      <p>
        It&apos;s been more than 30 days since you last had a 1:1 with your manager.
      </p>
    ),
    individual: (name) => (
      <p>
        {`It's been more than 30 days since ${name} last had a 1:1 with their manager.`}
      </p>
    ),
    group: (
      <p>
        It&apos;s been more than 30 days since these users last had 1:1s with their managers.
      </p>
    ),
  },
  [SCORE_NAMES.GREY]: {
    self: (
      <p>
        You have not yet had a 1:1 with your manager.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has not yet had a 1:1 with their manager.`}
      </p>
    ),
    group: (
      <p>
        These users do not have managers or have not yet had 1:1s with their managers.
      </p>
    ),
  },
};

export const goalsTooltips: StoplightTooltips = {
  [SCORE_NAMES.GOOD]: {
    self: (
      <p>
        You are setting personal goals and keeping them up-to-date.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} is setting personal goals and keeping them up-to-date.`}
      </p>
    ),
    group: (
      <p>
        These users are setting personal goals and keeping them up-to-date.
      </p>
    ),
  },
  [SCORE_NAMES.OK]: {
    self: (
      <p>
        You have active personal goals in Leadr, but you have not updated any goals in more than 30 days.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has active personal goals in Leadr, but they have not updated any goals in more than 30 days.`}
      </p>
    ),
    group: (
      <p>
        These users have active personal goals in Leadr, but they have not updated any goals in more than 30 days.
      </p>
    ),
  },
  [SCORE_NAMES.IMPROVE]: {
    self: (
      <p>
        Either you do not have active personal goals in Leadr, or you have not updated any goals in more than 45 days.
      </p>
    ),
    individual: (name) => (
      <p>
        {`Either ${name} does not have active personal goals in Leadr or they have not updated any goals in more than 45 days.`}
      </p>
    ),
    group: (
      <p>
        These users either do not have active personal goals in Leadr or they have not updated any goals in more than 45 days.
      </p>
    ),
  },
  [SCORE_NAMES.GREY]: {
    self: (
      <p>
        You have not yet set a personal goal in Leadr.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has not yet set a personal goal in Leadr.`}
      </p>
    ),
    group: (
      <p>
        These users have not yet set personal goals in Leadr.
      </p>
    ),
  },
};

export const feedbackTooltips: StoplightTooltips = {
  [SCORE_NAMES.GOOD]: {
    self: (
      <p>
        You have requested feedback recently.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has requested feedback recently.`}
      </p>
    ),
    group: (
      <p>
        These users have requested feedback recently.
      </p>
    ),
  },
  [SCORE_NAMES.OK]: {
    self: (
      <p>
        You have not requested feedback in more than 45 days.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has not requested feedback in more than 45 days.`}
      </p>
    ),
    group: (
      <p>
        These users have not requested feedback in more than 45 days.
      </p>
    ),
  },
  [SCORE_NAMES.IMPROVE]: {
    self: (
      <p>
        You have not requested feedback in more than 60 days.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has not requested feedback in more than 60 days.`}
      </p>
    ),
    group: (
      <p>
        These users have not requested feedback in more than 60 days.
      </p>
    ),
  },
  [SCORE_NAMES.GREY]: {
    self: (
      <p>
        You have not yet requested feedback in Leadr.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has not yet requsted feedback in Leadr.`}
      </p>
    ),
    group: (
      <p>
        These users have not yet requested feedback in Leadr.
      </p>
    ),
  },
};

export const activityTooltips: StoplightTooltips = {
  [SCORE_NAMES.GOOD]: {
    self: (
      <p>
        You are active in Leadr more than once per week.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} is active in Leadr more than once per week.`}
      </p>
    ),
    group: (
      <p>
        These users are active in Leadr more than once per week.
      </p>
    ),
  },
  [SCORE_NAMES.OK]: {
    self: (
      <p>
        You have only been active in Leadr one day in the past week.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has only been active in Leadr one day in the past week. `}
      </p>
    ),
    group: (
      <p>
        These users have only been active in Leadr one day in the past week.
      </p>
    ),
  },
  [SCORE_NAMES.IMPROVE]: {
    self: (
      <p>
        You have not had qualifying activity in Leadr in the past week.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has not had qualifying activity in Leadr in the past week.`}
      </p>
    ),
    group: (
      <p>
        These users have not had qualifying activity in Leadr in the past week.
      </p>
    ),
  },
  [SCORE_NAMES.GREY]: {
    self: (
      <p>
        {/* How would they ever see this? */}
        You have not yet logged in to Leadr.
      </p>
    ),
    individual: (name) => (
      <p>
        {`${name} has not yet logged in to Leadr.`}
      </p>
    ),
    group: (
      <p>
        These users have not yet logged in to Leadr.
      </p>
    ),
  },
};
