import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

const addParticipant = ({ cycleId, participants }) => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId()}/reviewCycles/${cycleId}/reviews`,
  };

  return patchApi(serverUrl, participants, {});
};

export const useAddParticipant = ({ queryKey = [getOrganizationId(), 'reviewCyclesParticipants'], id }) => {
  const toastId = useRef(null);

  const mutation = useMutation({
    mutationFn: addParticipant,
    onMutate: () => {
      toastId.current = toast.info('Updating participant(s)...', { autoClose: 3000 });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error updating the participant(s). Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully updated the participant(s)!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: [...queryKey, id] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviews'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), getOrganizationUserId(), 'reviewCycles'] });
    },
  });

  return mutation.mutate;
};
