// This is used to update lists to force card view for small screen sizes
import { useState } from 'react';

/**
 * @deprecated Use packages/leadr-frontend/src/common/hooks/useMediaListener.js instead
 */
export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
  });

  return {
    windowWidth,
    setWindowWidth,
  };
};
