import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { ChangeEvent } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useHidePrivateAgenda } from '../../../stores/useHidePrivateAgenda';

const styles = {
  hidePrivateItemsToggle: css({
    '@media print': {
      display: 'none',
    },
  }),
  label: css({
    padding: 0,
    marginBottom: 0,

    '& .MuiFormControlLabel-label': {
      color: palette.neutrals.gray700,
      fontSize: '0.875rem',
    },
  }),
  checkbox: css({
    marginTop: '0 !important', // Overriding MUI default margin
  }),
};

interface ViewProps {
  isHidden: boolean,
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
}

const View = ({
  isHidden,
  handleChange,
  ...props
}: ViewProps): JSX.Element => (
  <FormControlLabel
    css={styles.label}
    label="Hide private agenda topics and comments"
    {...props}
    control={(
      <CustomCheckbox
        css={styles.checkbox}
        checked={isHidden}
        onChange={handleChange}
      />
    )}
  />
);

const HidePrivateItemsToggle = ({ ...props }): JSX.Element => {
  const {
    isHidden,
    setIsHidden,
  } = useHidePrivateAgenda((state) => ({
    isHidden: state.isHidden,
    setIsHidden: state.setIsHidden,
  }));

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsHidden(event.target.checked);
  };

  const hookProps = {
    isHidden,
    handleChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default HidePrivateItemsToggle;
