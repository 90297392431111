import { useGenericStore } from '~Common/hooks/useGenericStore';

export const useSelectedAdminPeople = (administrativeStatus) => {
  const { setValue: setSelectedOrgUserIds, value: selectedOrgUserIds } = useGenericStore({
    module: 'leadr',
    workflow: 'admin',
    feature: `selected-people-${administrativeStatus}`,
    defaultValue: [],
  });

  const selectAllUsers = (users) => {
    const orgUserIds = users.map((user) => user.orgUserId);
    setSelectedOrgUserIds([...orgUserIds]);
  };

  const selectOneUser = (user) => {
    setSelectedOrgUserIds([...selectedOrgUserIds, user.orgUserId]);
  };

  const deselectAllUsers = (users) => {
    const tempArray = [...selectedOrgUserIds];
    users.forEach((user) => {
      const orgUserIdToRemoveIndex = tempArray.indexOf(user.orgUserId);
      if (orgUserIdToRemoveIndex !== -1) {
        tempArray.splice(orgUserIdToRemoveIndex, 1);
      }
    });
    setSelectedOrgUserIds(tempArray);
  };

  const deselectOneUser = (user) => {
    const tempArray = [...selectedOrgUserIds];
    const orgUserIdToRemoveIndex = tempArray.indexOf(user.orgUserId);
    if (orgUserIdToRemoveIndex > -1) {
      tempArray.splice(orgUserIdToRemoveIndex, 1);
      setSelectedOrgUserIds(tempArray);
    }
  };

  const isUserSelected = (user) => selectedOrgUserIds.includes(user.orgUserId);

  const areAllUsersSelected = (users) => users.every((user) => isUserSelected(user));

  return {
    selectedOrgUserIds,
    setSelectedOrgUserIds,
    selectAllUsers,
    selectOneUser,
    deselectAllUsers,
    deselectOneUser,
    isUserSelected,
    areAllUsersSelected,
  };
};
