import { createAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
import { callApi } from '~Deprecated/sagas/callApi';
import { mutateState, deleteRecordFromState } from '~Deprecated/utils/reduxUtils';
import { registerAction } from '~Deprecated/reducers/organizationInsights';
import { sagaMiddleware } from '~Deprecated/store';
import { resetFavoriteOrg, setFavoriteOrg } from '~Deprecated/services/organizationInsights';

const toggleFavoriteOrgAction = createAction('LEADR.NEXUS.ORG_INSIGHTS.TOGGLE_FAVORITE');
const toggleFavoriteOrgSuccessAction = createAction('LEADR.NEXUS.ORG_INSIGHTS.TOGGLE_FAVORITE_SUCCESS');
const toggleFavoriteOrgFailureAction = createAction('LEADR.NEXUS.ORG_INSIGHTS.TOGGLE_FAVORITE_FAILURE');

function* onToggleFavoriteOrg(action) {
  yield call(callApi, {
    api: action.payload.isFavorite ? setFavoriteOrg : resetFavoriteOrg,
    payload: action.payload,
    successAction: toggleFavoriteOrgSuccessAction,
    failureAction: toggleFavoriteOrgFailureAction,
  });
}
function* toggleFavoriteOrgWatcher() {
  yield takeLatest(toggleFavoriteOrgAction, onToggleFavoriteOrg);
}

function toggleFavoriteOrgSuccessHandler(state, action) {
  if (state.isFilteredByFavourites) {
    return mutateState(state, (draftState) => {
      const mutatedState = deleteRecordFromState(draftState, action.payload.id);
      mutatedState.totalCount -= 1;

      return mutatedState;
    });
  }

  return mutateState(state, (draftState) => {
    draftState.data[action.payload.id].isFavourite = action.payload.isFavorite;
    return draftState;
  });
}

function toggleFavoriteOrgFailureHandler(state, action) {
  return mutateState(state, (draftState) => {
    draftState.error = action.payload;
    return draftState;
  });
}

registerAction(toggleFavoriteOrgAction, toggleFavoriteOrgSuccessHandler);
registerAction(toggleFavoriteOrgFailureAction, toggleFavoriteOrgFailureHandler);

sagaMiddleware.run(toggleFavoriteOrgWatcher);

export {
  toggleFavoriteOrgAction,
};
