import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { SelectChangeEvent } from '@mui/material';
import { ResourceType } from '~DevelopmentPlan/const/types';
import { PersonalDevelopmentResourceTypeLabels, PersonalDevelopmentResourceTypes } from '~DevelopmentPlan/const/defaults';
import { PERSONAL_DEVELOPMENT_FILTER_STYLES } from '~DevelopmentPlan/const/pageStyles';

const renderValue = (value: ResourceType[]): string => {
  if (value?.length) {
    const priorityLabels = value.map((statusValue) => PersonalDevelopmentResourceTypeLabels[statusValue]);
    return priorityLabels.join(', ');
  }

  return 'Type';
};

const styles = {
  ...PERSONAL_DEVELOPMENT_FILTER_STYLES,
};

interface ViewProps extends DropdownProps<ResourceType[]> {
  isMobileView: boolean;
}

const View = ({
  onChange,
  items,
  value,
  isMobileView,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.filterWrapper(isMobileView)}
  >
    <Dropdown
      multiple
      displayEmpty
      css={styles.dropdown(!!value?.length, isMobileView)}
      value={value}
      renderItem={(item: DropdownItem<ResourceType[]>) => (
        <div css={styles.dropdownItemBoy}>
          <CustomCheckbox checked={value?.includes(item.value)} />
          {item.text}
        </div>
      )}
      onChange={onChange}
      items={items}
      renderValue={renderValue}
      {...props}
    />
  </div>
);

interface TypeFilterProps {
  isAccomplishments?: boolean;
  isMobileView: boolean;
}

const TypeFilter = ({
  isAccomplishments = false,
  isMobileView,
}: TypeFilterProps): JSX.Element => {
  const [value, setStatusValue] = useQueryParamState<ResourceType[]>('personalDevelopment', 'type', [], true);

  // @ts-expect-error : The interop between queryParamState and internal MUI state change was causing some issues.
  const stepsTableTypeItems: DropdownItem<ResourceType[]>[] = PersonalDevelopmentResourceTypes
    .filter((priority) => priority.value !== ResourceType.Recognition && priority.value !== ResourceType.Accomplishment)
    .map((priority) => ({
      ...priority,
      value: priority.value.toString(),
    }));
  // @ts-expect-error : The interop between queryParamState and internal MUI state change was causing some issues.
  const accomplishmentTableTypeItems: DropdownItem<ResourceType[]>[] = PersonalDevelopmentResourceTypes
    .filter((priority) => priority.value === ResourceType.Recognition || priority.value === ResourceType.Accomplishment)
    .map((priority) => ({
      ...priority,
      value: priority.value.toString(),
    }));
  const onChange = (event: SelectChangeEvent<ResourceType[]>): void => {
    setStatusValue(event.target.value as ResourceType[]);
  };

  const hookProps = {
    value,
    items: isAccomplishments ? accomplishmentTableTypeItems : stepsTableTypeItems,
    onChange,
    renderValue,
    isMobileView,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default TypeFilter;
