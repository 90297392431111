import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch';
import TextField from '~Common/components/Fields/TextField';
import { palette } from '~Common/styles/colors';

const SearchBox = ({
  onChange,
  placeholder = 'Search',
  delay = 500,
  iconProps = {},
  ...props
}) => {
  const iconStyle = css`
    width: 16px !important;
    height: 16px !important;
    margin-top: 1px;
    color: ${palette.neutrals.gray500};
`;
  const debouncedSearch = debounce(onChange, delay);

  const doChange = (event) => {
    debouncedSearch(event.target.value);
  };

  const renderAdornment = () => {
    const { style: iconStyleOverrides } = iconProps;

    return (
      <FontAwesomeIcon
        css={[iconStyle, iconStyleOverrides]}
        icon={faSearch}
      />
    );
  };

  return (
    <TextField
      placeholder={placeholder}
      onChange={doChange}
      renderIcon={renderAdornment}
      size="fullRow"
      {...props}
    />
  );
};

SearchBox.propTypes = {
  onChange: PropTypes.func,
  delay: PropTypes.number,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  iconProps: PropTypes.any,
};

SearchBox.defaultProps = {
  delay: 250,
  onChange: () => {
  },
  placeholder: 'Search',
  iconProps: {},
};

export default SearchBox;
