import { css } from '@emotion/react';
import { ComponentProps, ReactNode } from 'react';

import { palette } from '~Common/styles/colors';
import FloatingActionMenuButton from './FloatingActionMenuButton';

const styles = {
  floatingActionMenu: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '.5rem',
    background: palette.neutrals.white,
    position: 'absolute',
    zIndex: 500,
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.07)',
    paddingLeft: '.375rem',
    paddingRight: '.375rem',
  }),
};

interface FloatActionMenuProps extends ComponentProps<'div'> {
  children: ReactNode,
}

const FloatingActionMenu = ({
  children,
  ...props
}: FloatActionMenuProps): JSX.Element => (
  <div
    css={styles.floatingActionMenu}
    {...props}
  >
    {children}
  </div>
);

FloatingActionMenu.TooltipButton = FloatingActionMenuButton;

export default FloatingActionMenu;
