import { css } from '@emotion/react';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '~Common/components/Tooltip';

import { palette } from '~Common/styles/colors';

const styles = {
  headerText: css({
    color: palette.brand.indigo,
    fontSize: '1.5rem',
    fontWeight: 600,
  }),
  warningIcon: css({
    color: palette.brand.orange,
  }),
};

interface NameHeaderProps {
  hasTeamOwner: boolean,
  name: string,
}

const NameHeader = ({
  hasTeamOwner,
  name,
}: NameHeaderProps): JSX.Element => (
  <>
    {hasTeamOwner && (
      <h2 css={styles.headerText}>{name}</h2>
    )}
    {!hasTeamOwner && (
      <Tooltip content="No team owner">
        <h2 css={styles.headerText}>
          <FontAwesomeIcon css={styles.warningIcon} icon={faTriangleExclamation} />
          {' '}
          {name}
        </h2>
      </Tooltip>
    )}
  </>
);

export default NameHeader;
