import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import DrawerLayout from '~Deprecated/ui/components/DrawerManager/DrawerLayout';
import DrawerHeader from '~Deprecated/ui/components/DrawerManager/DrawerHeader';
import Froala from '~Common/V3/components/Froala';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';

export const editBioTemplate = {
  name: 'EDIT_BIO',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};

const styles = css`
  margin-top: 20px;
`;

const fauxCloseButton = css({
  fontSize: '1.5rem',
  width: '1.5rem',
  height: '1.5rem',
  color: palette.neutrals.gray700,
});

function View({
  firstName, doneButton, bioText, setBioText,
}) {
  return (
    <DrawerLayout
      renderHeader={() => (
        <DrawerHeader
          title={`${firstName}'s Bio`}
          renderRightButton={doneButton}
        />
      )}
      renderBody={() => (
        <div css={styles}>
          <Froala
            richTextEditorProps={{
              initialValue: bioText,
              onChange: ({ value: newText }) => setBioText(newText),
            }}
          />
        </div>
      )}
    />
  );
}

View.propTypes = {
  firstName: PropTypes.string.isRequired,
  doneButton: PropTypes.func.isRequired,
  bioText: PropTypes.string.isRequired,
  setBioText: PropTypes.func.isRequired,
};

const EditBioDrawer = ({
  user, ...props
}) => {
  const dispatch = useDispatch();
  const { firstName, aboutMe } = user;

  const onClose = () => {
    dispatch(popDrawerAction({
      drawerName: editBioTemplate.name,
    }));
  };

  const hookProps = {
    doneButton: () => (
      <FontAwesomeIcon
        css={fauxCloseButton}
        icon={faTimes}
        onClick={onClose}
        data-test-id="aboutMeDrawerCloseButton"
      />
    ),
    firstName,
    aboutMe,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: editBioTemplate.name,
  component: EditBioDrawer,
});

EditBioDrawer.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
};

EditBioDrawer.defaultProps = {};

export default EditBioDrawer;
