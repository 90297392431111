import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import BaseAvatar from '~Common/components/Users/Avatars/BaseAvatar';

const style = (size) => css`
  border-radius: 50px;

  & .initials {
    border-radius: 50px;
    font-size: ${size / 2}px;
  }
`;
/**
 * @deprecated Old UI
 */
const CircleAvatar = ({
  size, ...props
}) => (
  <BaseAvatar
    width={size}
    height={size}
    css={style(size)}
    {...props}
  />
);

CircleAvatar.propTypes = {
  size: PropTypes.number,
};

CircleAvatar.defaultProps = {
  size: 32,
};

export default CircleAvatar;
