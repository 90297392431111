import { createAction } from '@reduxjs/toolkit';
import { takeLatest, call } from 'redux-saga/effects';

import { callApi } from '~Deprecated/sagas/callApi';
import { getPersonalityDetailsById } from '~Deprecated/services/personalityService';
import { sagaMiddleware } from '~Deprecated/store';
import { registerAction } from '~Deprecated/reducers/personalityDetalis';
import { registerAction as registerActionPersonalities } from '~Deprecated/reducers/personalities';
import { flattenData, mutateState } from '~Deprecated/utils/reduxUtils';

const loadPersonalityDetailsById = createAction('LEADR.LOAD_PERSONALITY_DETAILS_BY_ID');
const loadPersonalityDetailsByIdSuccessAction = createAction('LEADR.LOAD_PERSONALITY_DETAILS_BY_ID_SUCCESS');
const loadPersonalityDetailsByIdFailureAction = createAction('LEADR.LOAD_PERSONALITY_DETAILS_BY_ID_FAILURE');

function* onLoadPersonalityDetailsById(action) {
  yield call(callApi, {
    api: getPersonalityDetailsById,
    payload: action.payload,
    successAction: loadPersonalityDetailsByIdSuccessAction,
    failureAction: loadPersonalityDetailsByIdFailureAction,
  });
}

const onLoadPersonalityDetailsByIdSuccess = (state, action) => {
  const { data, ids } = flattenData({
    responseData: action.payload.response,
    onItemFlatten: ({ label, commentary, system }) => ({ label, commentary, system }),
    idName: 'personalityId',
  });
  return mutateState(state, (draftState) => {
    draftState.data = data;
    const [personalityId] = ids;
    draftState.personalityId = personalityId;
    draftState.hasPendingCall = false;
    return draftState;
  });
};

const onLoadPersonalityDetailsByIdForUserSuccess = (state, action) => {
  const { personalityId, ...data } = action.payload.response[0];

  return mutateState(state, (draftState) => {
    draftState.data[personalityId] = data;
  });
};

const onLoadPersonalityDetailsByIdFailure = (state) => mutateState(state, (draftState) => {
  draftState.data = [];
  draftState.hasPendingCall = false;
  return draftState;
});

const onLoadPersonalityDetailsByIdBegin = (state) => mutateState(state, (draftState) => {
  draftState.hasPendingCall = true;
  return draftState;
});

function* personalityDetailsWatcher() {
  yield takeLatest(loadPersonalityDetailsById, onLoadPersonalityDetailsById);
}

registerAction(loadPersonalityDetailsByIdSuccessAction, onLoadPersonalityDetailsByIdSuccess);
registerAction(loadPersonalityDetailsByIdFailureAction, onLoadPersonalityDetailsByIdFailure);
registerAction(loadPersonalityDetailsById, onLoadPersonalityDetailsByIdBegin);

registerActionPersonalities(loadPersonalityDetailsByIdSuccessAction, onLoadPersonalityDetailsByIdForUserSuccess);

sagaMiddleware.run(personalityDetailsWatcher);

export {
  loadPersonalityDetailsById,
};
