import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { GridOverlay } from '@mui/x-data-grid';
import { useShowCreateEditTeamModal } from '~People/components/Teams/stores/useShowCreateEditTeamModal';

const styles = {
  emptyState: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.neutrals.gray800,
  }),
  addUserButton: css({
    // Need a slightly higher zIndex than the table's overlay, so you can click the button, MUI is fun
    zIndex: 5,
  }),
};

interface ViewProps {
  handleAddUserClick: () => void,
}

const View = ({
  handleAddUserClick,
  ...props
}: ViewProps): JSX.Element => (
  <GridOverlay
    css={styles.emptyState}
    {...props}
  >
    There are no users in this team.
    <LeadrButton
      css={styles.addUserButton}
      variant="text"
      onClick={handleAddUserClick}
      data-test-id="peopleTeamsTeamDetailsAddUsers"
    >
      Add users.
    </LeadrButton>
  </GridOverlay>
);

const EmptyState = ({
  ...props
}): JSX.Element => {
  const {
    setShowModal,
  } = useShowCreateEditTeamModal((state) => ({
    setShowModal: state.setShowModal,
  }));

  const handleAddUserClick = (): void => {
    setShowModal(true);
  };

  const hookProps = {
    handleAddUserClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default EmptyState;
