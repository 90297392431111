import { call, put, select } from 'redux-saga/effects';
import { toast } from '~Common/components/Toasts';

import { isLoadingAction } from '~Deprecated/actions/loading/isLoading';
import { getFlag } from '~Deprecated/selectors/featureFlags/getFlag';
import { GlobalAPIErrorHandler } from '../redux/sagas/utils';

function* processError({
  error,
  payload,
  showToast = false,
  failureAction,
}) {
  const showErrorToasts = yield select(getFlag, { flag: 'showErrorToasts' });
  if (showToast && showErrorToasts) {
    yield call(toast.error, error);
  }
  if (failureAction) {
    yield put(failureAction({ error: error.toString(), ...payload }));
    if (!showToast && showErrorToasts) {
      yield call(GlobalAPIErrorHandler, error.messageKey);
    }
  }

  return {
    error: error.toString(),
    success: false,
    ...payload,
  };
}

export function* callApi({
  api, payload = {}, successAction, failureAction,
}) {
  let returnValue;
  try {
    yield put(isLoadingAction(true));
    const response = yield call(api, payload);
    /*
      important note:
      if (!response.success) will return true if the key is undefined, OR if it is set to false.
      Since the success key does not exist in older apis, we have to check if the key exists AND it is equal to false
      otherwise it will be trigged by old apis and we don't want that.
    */
    if (response.errorCode || ('success' in response && !response.success)) {
      throw new Error(`${response.errorCode}: ${response.message}`);
    }

    returnValue = { ...payload, ...response };

    if (successAction) {
      yield put(successAction({ ...returnValue }));
    }
  } catch (error) {
    returnValue = yield call(processError, {
      error: error.toString(),
      payload,
      failureAction,
      showToast: error.toString().match(/([A-Za-z0-9]{6}:)/g),
    });
  } finally {
    yield put(isLoadingAction(false));
  }
  return returnValue;
}
