import { css } from '@emotion/react';
import { ComponentProps, useState } from 'react';
import isURL from 'validator/es/lib/isURL';
import DrawerInput from '~Common/V3/components/DrawerInput';
import { IframelyContent, IframelyErrorMessage } from '~Learning/const/interfaces';
import { getIframelyContentWithoutReactQuery } from '~Learning/hooks/useIframely';
import { isContentUrlLinkValid } from '~Common/utils';
import { toast } from '~Common/components/Toasts';

const styles = {
  contentLinkStyle: css({
    margin: '.5rem 0',
  }),
};

interface ViewProps {
  contentTitle?: string,
  contentLink?: string,
  onBlurOfContentLink: (event: React.FocusEvent<HTMLInputElement>) => Promise<void>;
}

const View = ({
  contentTitle = '',
  contentLink = '',
  onBlurOfContentLink,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <DrawerInput
      name="contentTitle"
      label="Content Title"
      required
      maxLength={250}
      initialValue={contentTitle}
    />
    <DrawerInput
      name="contentLink"
      label="Content Link"
      initialValue={contentLink}
      required
      maxLength={5000}
      onBlur={onBlurOfContentLink}
      inputStyles={styles.contentLinkStyle}
    />
  </div>
);

interface TitleAndLinkProps extends Omit<ViewProps, 'onBlurOfContentLink'>, ComponentProps<'div'> {
  setContentTitle: (contentTitle: string) => void,
  setContentLinkError: (message: IframelyErrorMessage) => void,
}

const TitleAndLink = ({ setContentTitle, setContentLinkError, ...props }: TitleAndLinkProps): JSX.Element => {
  // This is used to make sure that we aren't spamming APIs if we already have already made the API call for that content
  const [previousContentLink, setPreviousContentLink] = useState('');

  const onBlurOfContentLink = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
    const url = event.target.value;
    if (url && url !== previousContentLink) {
      setPreviousContentLink(event.target.value);
      const isValid = isContentUrlLinkValid(url);
      if (isValid && isURL(url, { require_protocol: true, protocols: ['https', 'ftp'] })) {
        const response = getIframelyContentWithoutReactQuery(url);
        await response.then((iframelyData: IframelyContent) => {
          setContentTitle(iframelyData?.meta?.title || '');
          setContentLinkError(IframelyErrorMessage.VALID);
        });
      } else {
        toast.error(IframelyErrorMessage.ERROR_VALIDATING);
        setContentLinkError(IframelyErrorMessage.ERROR_VALIDATING);
      }
    }
  };

  const hookProps = {
    onBlurOfContentLink,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TitleAndLink;
