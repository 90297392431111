import { createReducer } from '../redux/reducers/index';

const initialState = {
  data: {},
  isOverriding: false,
  isFullyInitialized: false,
};

const { reducer, registerAction } = createReducer('featureFlags', initialState);

export { reducer, registerAction, initialState };
