import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  TabsListUnstyled,
  TabsUnstyled,
  TabUnstyled,
  tabUnstyledClasses,
  TabsUnstyledOwnProps,
  TabUnstyledOwnProps,
} from '@mui/base';
import { ReactElement, SyntheticEvent } from 'react';
import { CommonComponentSharedProps } from '~Common/const/interfaces';

import { palette } from '~Common/styles/colors';

const styles = {
  leadrToggleTabs: css({
    height: '2.8125rem',
  }),
  tabsList: css({
    height: '100%',
    display: 'flex',
  }),
  toggleTab: css({
    backgroundColor: palette.neutrals.gray50,
    color: palette.neutrals.gray700,
    border: `1px solid ${palette.neutrals.gray300}`,
    height: '100%',
    fontSize: '.875rem',
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    // Only here in case we have some jank where text wraps, but ideally that doesn't happen
    lineHeight: 1,
    '&:first-of-type': {
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem',
    },
    '&:last-of-type': {
      borderTopRightRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
    },
    '&:not(:first-of-type)': {
      // This is a placeholder spot for the focus state, so the UI doesn't jump when the focus state is applied
      borderLeftColor: 'transparent',
      paddingLeft: '.9375rem',
    },

    '&:hover, &:focus': {
      backgroundColor: palette.neutrals.gray200,
    },

    '&:focus': {
      border: `1px solid ${palette.brand.sky}`,
    },

    [`&.${tabUnstyledClasses.selected}`]: {
      color: palette.brand.indigo,
      backgroundColor: palette.neutrals.white,
    },
  }),
  icon: css({
    height: '1.25rem',
    width: '1.25rem',
  }),
  textTabText: css({
    marginLeft: '.625rem',
  }),
};

interface LeadrToggleTabs<ValueType> extends Omit<TabsUnstyledOwnProps, 'children' | 'onChange'> {
  children: ReactElement[],
  onChange?: (event: SyntheticEvent, value: ValueType) => void,
}

/**
 * To check out examples, see the examples.md file in the directory of this component
 * There are different variations of Tabs in this file to choose from, TextTab, IconTab, and IconTextTab
  @param onChange - The onChange event handler for the toggle tabs
  @param css - Passed in as css={styles.whateverStyles}, should only be used for positioning the component, not modifying the look
  @param value - If this is passed in, the component is controlled. Use createToggleTabsStore, there is an example in the examples.md file
*/
const LeadrToggleTabs = <ValueType extends string, >({ children, ...props }: LeadrToggleTabs<ValueType>): JSX.Element => (
  // @ts-expect-error TabsUnstyled does not handle generic types, so this component is setup to and then we ignore the TS error
  <TabsUnstyled css={styles.leadrToggleTabs} {...props}>
    <TabsListUnstyled css={styles.tabsList}>
      {children}
    </TabsListUnstyled>
  </TabsUnstyled>
);

interface TabProps extends TabUnstyledOwnProps, CommonComponentSharedProps {}

export interface LeadrToggleTextTabProps extends TabProps {
  text: string,
}

/**
 * To check out examples, see the examples.md file in the directory of this component
  @param data-test-id - Used for automated tests, toggleTab will get appended automatically. Example format "feature(buttonResponsibility)", example "learningRequested" becomes "learningRequestedToggleTab"
  @param text - String to display for the tab
  @param value - The value to use for when this tab is selected
*/
LeadrToggleTabs.TextTab = ({
  text,
  'data-test-id': dataTestId,
  ...props
}: LeadrToggleTextTabProps): JSX.Element => (
  <TabUnstyled css={styles.toggleTab} data-test-id={`${dataTestId}ToggleTab`} {...props}>{text}</TabUnstyled>
);

export interface LeadrToggleIconTabProps extends TabProps {
  icon: IconDefinition,
}

/**
 * To check out examples, see the examples.md file in the directory of this component
  @param data-test-id - Used for automated tests, toggleTab will get appended automatically. Example format "feature(buttonResponsibility)", example "learningRequested" becomes "learningRequestedToggleTab"
  @param icon - FontAwesomeIcon to display for the tab, passed in as icon={faIconName}
  @param value - The value to use for when this tab is selected
*/
LeadrToggleTabs.IconTab = ({
  icon,
  'data-test-id': dataTestId,
  ...props
}: LeadrToggleIconTabProps): JSX.Element => (
  <TabUnstyled css={styles.toggleTab} data-test-id={`${dataTestId}ToggleTab`} {...props}>
    <FontAwesomeIcon css={styles.icon} icon={icon} />
  </TabUnstyled>
);

interface LeadrToggleIconTextTabProps extends LeadrToggleTextTabProps, LeadrToggleIconTabProps {}

/**
 * To check out examples, see the examples.md file in the directory of this component
  @param data-test-id - Used for automated tests, toggleTab will get appended automatically. Example format "feature(buttonResponsibility)", example "learningRequested" becomes "learningRequestedToggleTab"
  @param icon - FontAwesomeIcon to display for the tab, passed in as icon={faIconName}
  @param text - String to display for the tab
  @param value - The value to use for when this tab is selected
*/
LeadrToggleTabs.IconTextTab = ({
  text,
  icon,
  'data-test-id': dataTestId,
  ...props
}: LeadrToggleIconTextTabProps): JSX.Element => (
  <TabUnstyled css={styles.toggleTab} data-test-id={`${dataTestId}ToggleTab`} {...props}>
    <FontAwesomeIcon css={styles.icon} icon={icon} />
    <span css={styles.textTabText}>{text}</span>
  </TabUnstyled>
);

export default LeadrToggleTabs;
