import * as types from '../../../actions';

const assignContent = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR_CREATE_CONTENT_RESPONSE:
      return { ...state, response: {} };
    case types.CLEAR_MEETING_SUCCESS_RESPONSE:
      state.addContentToMeeting_msg = '';
      return { ...state };
    default:
      return state;
  }
};

export default assignContent;
