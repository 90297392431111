import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import moment from 'moment';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

interface DateRange {
  label: string,
  value?: string,
  startDate: Date,
}

const styles = {
  container: css({
    alignItems: 'center',
    columnGap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',

    p: {
      fontSize: '0.75rem',
      margin: '0',
    },
  }),
  root: css({
    border: '0',
    fontFamily: 'ProximaNova',
    '& .MuiSelect-select': {
      backgroundColor: palette.neutrals.gray50,
      border: '0',
      borderRadius: '0.5rem',
      fontFamily: 'ProximaNova',
      fontSize: '0.75rem',
      padding: '0.5rem 0.75rem',
    },
    '& .MuiSelect-icon': {
      color: palette.brand.indigo,
    },
    fieldset: {
      display: 'none',
    },
  }),
  menuItem: css({
    fontFamily: 'ProximaNova',
    fontSize: '0.75rem',
  }),
};

const monthRanges = [3, 6, 12, 18];
const PRESET_RANGES: DateRange[] = monthRanges.map((months) => ({
  label: `Last ${months} months`,
  value: `PRESET_${months}`,
  startDate: moment().subtract(months, 'months').startOf('day').toDate(),
}));

interface ViewProps {
  value: string,
  additionalDates: DateRange[],
  onChange: (date: string) => void,
}

const View = ({
  value,
  onChange,
  additionalDates,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    <p>Date:</p>
    <Select css={styles.root} value={value} onChange={(event) => onChange(event.target.value)}>
      <ListSubheader css={styles.menuItem}>Date Ranges</ListSubheader>
      {PRESET_RANGES.map((range) => (
        <MenuItem css={styles.menuItem} key={range.label} value={range.value}>
          {range.label}
        </MenuItem>
      ))}
      {additionalDates && <ListSubheader css={styles.menuItem}>Survey Dates</ListSubheader>}
      {additionalDates.map((range) => (
        <MenuItem
          css={styles.menuItem}
          key={range.label}
          value={range.startDate?.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
        >
          {range.label}
        </MenuItem>
      ))}
    </Select>
  </div>
);

interface DateRangeSelectProps {
  initialValue?: string,
  defaultMonthsBack?: typeof monthRanges[number],
  dates?: Date[],
  onChange: (dates: Date[], pickerValue: string) => void,
}

// I would like for this to go away by API time.
function uniqueDates(dates: Date[]): Date[] {
  const times = dates.map((date) => date.getTime());
  const unique = Array.from(new Set(times));
  return unique.map((time) => new Date(time));
}

const DateRangeSelect = ({
  defaultMonthsBack = 3,
  initialValue = '',
  dates = [],
  onChange,
  ...props
}: DateRangeSelectProps): JSX.Element => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (value) {
      translateOnChange(value);
    }
    // Just making sure the event triggers successfully on first render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // array.includes() doesn't work on date objects.
    if (value.substring(0, 7) !== 'PRESET_' && !dates.find((date) => date.getTime() === new Date(value).getTime())) {
      translateOnChange('PRESET_12');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want to change when 'dates' does.
  }, [dates]);

  const translateOnChange = (newValue: string): void => {
    let newStartDate;
    let newEndDate;

    if (newValue.substring(0, 7) === 'PRESET_') {
      const preset = PRESET_RANGES.find((range) => range.value === newValue);
      if (!preset) return;

      newStartDate = preset.startDate;
      newEndDate = moment().startOf('day').toDate();
    } else {
      newStartDate = new Date(newValue);
      newEndDate = newStartDate;
    }

    setValue(newValue);
    onChange([newStartDate, newEndDate], newValue);
  };

  if (value === '') {
    const preset = PRESET_RANGES.find((range) => range.value === `PRESET_${defaultMonthsBack}`);
    if (preset?.value) {
      translateOnChange(preset.value);
    }
  }

  const hookProps = {
    value,
    additionalDates: uniqueDates(dates).map((date) => ({
      label: date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
      startDate: date,
    })),
    onChange: translateOnChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View, DateRangeSelect };
export default DateRangeSelect;
