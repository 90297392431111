import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPin } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import LocationLink from '~Deprecated/ui/components/LocationLink';

const styles = {
  locationText: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
  icon: css({
    marginRight: '.375rem',
    width: '.75rem',
    height: '.75rem',
  }),
};

interface LocationTextProps {
  location: string,
}

const LocationText = ({
  location,
  ...props
}: LocationTextProps): JSX.Element => (
  <div
    css={styles.locationText}
    {...props}
  >
    <FontAwesomeIcon
      css={styles.icon}
      icon={faLocationPin}
    />
    <LocationLink meetingLocation={location} />
  </div>
);

export default LocationText;
