import createSearchStore, { SearchStoreState } from '~Common/V3/components/LeadrSearchField/util/createSearchStore';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { UseBoundStore, StoreApi } from 'zustand';
import { useMemo } from 'react';

export const useGlobalSearch = (): UseBoundStore<StoreApi<SearchStoreState>> => {
  const searchDebounceInMs = useFeatureFlag<number>('searchDebounceInMs');
  const searchStore = useMemo(() => createSearchStore(searchDebounceInMs), [searchDebounceInMs]);

  return searchStore;
};
