import * as types from '../../actions';
import { setUserInfo } from '../utils';

const profile = (state = {}, action) => {
  switch (action.type) {
    case types.GET_USER_INFO_SUCCESS:
      setUserInfo(state, action.response.data);
      return { ...state };
    default:
      return state;
  }
};

export default profile;
