import moment, { Moment } from 'moment-timezone';
import { css, SerializedStyles } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare, faListCheck } from '@fortawesome/pro-light-svg-icons';
import { Alert } from '@mui/material';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isURL from 'validator/es/lib/isURL';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerInput from '~Common/V3/components/DrawerInput';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DatePicker from '~Common/V3/components/DatePicker';
import Tooltip from '~Common/components/Tooltip';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import ActionItemRecurrence from '~ActionItems/components/recurrence/ActionItemRecurrence';
import RecurrenceToggleComponent from '~ActionItems/components/recurrence/RecurrenceToggleComponent';
import ActionItemStatusDropdown from '~ActionItems/components/Drawers/ActionItemStatusDropdown';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import Froala from '~Common/V3/components/Froala';
import ActionItemComments from '~ActionItems/components/Drawers/CreateEditActionItemDrawer/ActionItemComments';
import AddEditComment from '~ActionItems/components/Drawers/CreateEditActionItemDrawer/AddEditComment';
import { Note, Person } from '~Common/const/interfaces';
import { ActionItem, ActionItemContext, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import { getOrganizationUserId, getUserId } from '~Common/utils/localStorage';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { selectParticipantsDrawerTemplate } from '~Common/components/Drawers/SelectParticipantsDrawer';
import { drawerInputBackground, palette } from '~Common/styles/colors';
import { useNewPeople, useGetPeopleByList as usePeoplePickerByList } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { ActionItemData as CreateActionItemData, useCreateActionItems } from '~ActionItems/hooks/useCreateActionItems';
import { useActionItemDetails } from '~ActionItems/hooks/useActionDetails';
import { EditActionItemData, useEditActionItem } from '~ActionItems/hooks/useEditActionItem';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { getAssociationData, AssociationData } from '~ActionItems/functions/utils';
import { useDeleteActionItem } from '~ActionItems/hooks/useDeleteActionItem';
import { updatedValues } from '~ActionItems/functions/updatedValues';
import { useUpdateEffect } from '~Common/hooks/useUpdateEffect';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';

export const createEditActionItemTemplate = {
  name: 'CREATE_EDIT_ACTION_ITEM_DRAWER',
  type: templateType.PRIMARY,
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  drawerInput: css({
    marginBottom: '0.5rem !important',
    padding: '0.5rem 0.75rem',

    '& input': {
      fontWeight: '400 !important',
      padding: 0,
      minHeight: 0,
    },
  }),
  description: css({
    marginBottom: '0.5rem !important',

    '.fr-element': {
      padding: '0 0.75rem !important',
    },
    '.fr-btn-grp': {
      margin: '0 0.75rem !important',
    },
  }),
  descriptionLabel: css({
    padding: '0.5rem 0.75rem',
  }),
  attendeeBox: css({
    width: '49%',
    margin: '10px 1% 10px 0',
  }),
  attendeeContainer: css({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  }),
  dateContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  }),
  assigneeContainer: css({
    marginBottom: '0.5rem',
    background: drawerInputBackground,
    width: '100%',
    padding: '0.5rem 0.75rem',
    borderRadius: '8px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    alignItems: 'flex-start',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    border: 0,
    cursor: 'pointer',

    '&:disabled': {
      opacity: '0.4',
      cursor: 'no-drop',
    },
  }),
  label: css({
    flex: 0,
    color: palette.neutrals.gray700,
    marginBottom: '0.25rem',
    fontWeight: 400,
  }),
  assigneeButton: css({
    padding: 0,
    marginBottom: '0.3125rem',
    background: drawerInputBackground,
    color: palette.neutrals.gray400,
    fontWeight: 600,
    fontSize: '0.875rem',
  }),
  avatarStyles: css({
    marginRight: '10px',
  }),
  icon: css({
    display: 'flex',
    alignItems: 'center',
  }),
  avatarContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  assigneeName: css({
    fontSize: '1rem',
    color: palette.neutrals.gray800,
  }),
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
    flex: 1,
  }),
  buttonContainer: (isEdit: boolean) => css({
    marginTop: '1.125rem',
    display: 'flex',
    alignItems: 'center',
  }, isEdit && {
    justifyContent: 'space-between',
  }),
  leftButtonContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  saveButton: css({
    marginRight: '0.625rem',
  }),
  contextContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  createdOn: css({
    alignSelf: 'flex-end',
    color: palette.neutrals.gray600,
    fontSize: '0.75rem',
  }),
  datePicker: css({
    padding: '0.5rem 0.75rem',
    marginBottom: '0.5rem',
    flex: 1,

    '& input': {
      fontWeight: 400,
      padding: 0,
      minHeight: 0,
    },
  }),
  datePickerLabel: css({
    display: 'flex',
    gap: '0.25rem',
    color: palette.neutrals.gray700,
    fontWeight: 400,
  }),
  caretIcon: css({
    color: palette.brand.indigo,
    fontSize: '1rem',
  }),
  statusOverrides: css({
    marginBottom: '0.5rem',
    padding: '0.5rem 0.75rem',

    '& div > div > span': css({
      fontWeight: 400,
    }),
  }),
  bulkCreateNotification: css({
    marginTop: '0.5rem',
  }),
  bulkCreateNotificationBody: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray800,
    fontWeight: 400,
  }),
  bulkCreateNotificationIcon: css({
    marginRight: '1rem',
    fontSize: '2.25rem',
    color: palette.brand.indigo,
  }),
  contextString: css({
    color: palette.neutrals.gray600,
    fontSize: '0.75rem',
  }),
  footer: (shouldBeSticky: boolean) => css({
    padding: '1.5rem',
    backgroundColor: palette.neutrals.gray50,
  }, shouldBeSticky && {
    position: 'sticky',
    bottom: 0,
  }),
  comments: css({
    backgroundColor: palette.neutrals.gray50,
  }),
  drawerBodyContainer: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
};

export enum FormSubmittedByButton {
  UPDATE_ACTION_ITEM_BUTTON = 'UPDATE_ACTION_ITEM_BUTTON',
  COMPLETE_ACTION_ITEM_BUTTON = 'COMPLETE_ACTION_ITEM_BUTTON'
}

export interface ActionItemFormData {
  text: string,
  description?: string,
  dueDate?: string,
  status: NewActionItemStatus,
  externalLink?: string,
  recurrenceRule?: string,
}

export interface CreateActionItemViewProps {
  description?: string,
  areCommentsEnabled: boolean,
  canCommentOnActionItems: boolean,
  onDeleteActionItem: () => void,
  renderAssociationIcon: () => JSX.Element
  creatorString?: string,
  completedString?: string,
  associationData?: AssociationData,
  hasLimitedAccess: boolean,
  isReadOnly: boolean,
  actionItemId?: string,
  actionItem?: ActionItem,
  onSubmit: (e: FormEvent<HTMLFormElement>) => void,
  addPeopleClick: () => void,
  assignees: Person[],
  dueDate?: Moment | null,
  setDueDate: (newDate: Moment | null) => void,
  text?: string,
  closeDrawerClick: () => void,
  isLoading: boolean,
  onRecurrenceClick: () => void,
  showRecurrence: boolean,
  useRecurrence: boolean,
  recurrenceIsValid: boolean,
  setRecurrenceIsValid: Dispatch<SetStateAction<boolean>>,
  editedComment?: Note,
  onEditComment: (comment: Note) => void,
  onCancelEdit: () => void,
  shouldStickyComment: boolean,
  onIsCommentFieldActiveChange: (isActive: boolean) => void,
  isMobile: boolean,
  isEdit: boolean,
  overRideDeletePermissions: boolean,
}

const getDrawerTitle = ({
  actionItemId,
  isReadOnly,
}: Pick<CreateActionItemViewProps, 'actionItemId' | 'isReadOnly'>): string => {
  if (isReadOnly) {
    return 'View Action Item';
  }

  if (actionItemId && !isReadOnly) {
    return 'Edit Action Item';
  }

  return 'Create New Action Item';
};

const View = ({
  description,
  areCommentsEnabled,
  canCommentOnActionItems,
  onDeleteActionItem,
  renderAssociationIcon,
  creatorString,
  completedString,
  associationData,
  hasLimitedAccess,
  isReadOnly,
  actionItemId,
  actionItem,
  addPeopleClick,
  assignees,
  onSubmit,
  dueDate,
  setDueDate,
  text,
  closeDrawerClick,
  isLoading,
  onRecurrenceClick,
  showRecurrence,
  useRecurrence,
  recurrenceIsValid,
  setRecurrenceIsValid,
  editedComment,
  onEditComment,
  onCancelEdit,
  shouldStickyComment,
  onIsCommentFieldActiveChange,
  isMobile,
  isEdit,
  overRideDeletePermissions,
}:CreateActionItemViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title={getDrawerTitle({ actionItemId, isReadOnly })}
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            tooltip="Close"
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBodyContainer}>
        <div css={styles.drawerBody}>
          <form onSubmit={onSubmit}>

            <DrawerInput
              name="text"
              // Max length will not truncate strings set via value so we need to do so ourselves
              initialValue={text?.substring(0, 150) || actionItem?.text?.substring(0, 150) || ''}
              label="Title"
              maxLength={150}
              css={styles.drawerInput}
              data-test-id="actionItemTitle"
              disabled={isReadOnly}
              required
            />

            {areCommentsEnabled && (
              <Froala
                name="description"
                label="Description"
                styleOverrides={styles.description}
                richTextEditorProps={{
                  initialValue: description || actionItem?.description,
                }}
                froalaConfigProps={{
                  charCounterMax: 5000,
                  charCounterCount: true,
                }}
                labelStyleOverrides={styles.descriptionLabel}
                isDisabled={isReadOnly}
              />
            )}

            <div css={styles.dateContainer}>
              <DatePicker
                clearable
                initialDate={dueDate}
                onDateSelected={({ date: newDate }) => setDueDate(newDate)}
                label="Due By"
                name="dueDate"
                disablePast={false}
                css={styles.datePicker}
                disabled={isReadOnly}
                rightIconType={() => (
                  <div css={styles.icon}>
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      size="xs"
                      css={styles.caretIcon}
                    />
                  </div>
                )}
              />

              {useRecurrence && (
                <RecurrenceToggleComponent
                  dueDate={dueDate}
                  showRecurrence={showRecurrence}
                  onClick={onRecurrenceClick}
                  disabled={isReadOnly}
                />
              )}
            </div>

            {useRecurrence && showRecurrence && dueDate && (
              <ActionItemRecurrence
                dueDate={dueDate}
                initialRecurrenceRule={actionItem?.recurrenceRule}
                onValidityChange={(newValidity) => setRecurrenceIsValid(newValidity)}
                disabled={isReadOnly}
              />
            )}

            <ActionItemStatusDropdown
              css={styles.statusOverrides}
              name="status"
              data-test-id="actionItemStatus"
              value={actionItem?.status}
              disabled={isReadOnly}
            />

            <DrawerInput
              name="externalLink"
              initialValue={actionItem?.externalLink ?? ''}
              label="External Link"
              css={styles.drawerInput}
              data-test-id="actionItemExternalLink"
              disabled={isReadOnly}
              rightIconType={actionItem?.externalLink && isURL(actionItem.externalLink) ? () => {
                const link = /^https?:\/\/(.*)/.test(actionItem.externalLink) ? actionItem.externalLink : `https://${actionItem.externalLink}`;

                return (
                  <a href={link} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon css={styles.icon} icon={faArrowUpRightFromSquare} />
                  </a>
                );
              } : undefined}
            />

            <button
              type="button"
              css={styles.assigneeContainer}
              onClick={addPeopleClick}
              data-test-id="actionItemEditAssigneeButton"
              disabled={isReadOnly}
            >
              <div css={styles.label}>
                Assignees
              </div>

              {(assignees.length < 1) && (
                <div>
                  <div css={styles.assigneeButton}>
                    Select
                  </div>
                </div>
              )}

              <div css={styles.attendeeContainer}>
                {assignees.map((assignee) => (
                  <div key={`createai-${assignee.userId}`}>
                    <Tooltip content={`${assignee.firstName} ${assignee.lastName}`}>
                      <div className="menu-item" data-toggle="tooltip">
                        <div css={styles.avatarContainer}>
                          <SquareAvatar
                            imgUrl={assignee.profileImageUrl}
                            name={`${assignee.firstName} ${assignee.lastName}`}
                            width={40}
                            height={40}
                            includeInitials
                            css={styles.avatarStyles}
                          />

                          {assignees.length === 1 && (
                            <div css={styles.assigneeName}>
                              {`${assignee.firstName} ${assignee.lastName}`}
                            </div>
                          )}
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </button>

            {assignees.length > 1 && (
              <Alert
                css={styles.bulkCreateNotification}
                severity="info"
                icon={false}
              >
                <div css={styles.bulkCreateNotificationBody}>
                  <FontAwesomeIcon
                    css={styles.bulkCreateNotificationIcon}
                    icon={faListCheck}
                  />

                  <div>
                    Selecting multiple assignees will create an independent action item for each person.
                  </div>
                </div>
              </Alert>
            )}

            {actionItemId && (
              <>
                <DrawerInput
                  label="Associated With"
                  name="associatedWith"
                  initialValue={associationData ? associationData.text : ''}
                  css={styles.drawerInput}
                  leftIconType={renderAssociationIcon}
                  data-test-id="actionItemAssociatedWith"
                  disabled
                />

                <div css={styles.contextContainer}>
                  <div data-test-id="actionItemCreatorString" css={styles.contextString}>
                    {creatorString}
                  </div>

                  <div data-test-id="actionItemCompletedString" css={styles.contextString}>
                    {completedString}
                  </div>
                </div>
              </>
            )}

            <div css={styles.buttonContainer(isEdit)}>
              <div css={styles.leftButtonContainer}>
                <LeadrButton
                  css={styles.saveButton}
                  data-test-id="actionItemsSaveButton"
                  size={isMobile ? 'small' : 'standard'}
                  disabled={(showRecurrence && !recurrenceIsValid) || isLoading || isReadOnly}
                  type="submit"
                >
                  { actionItem ? 'Update' : 'Save' }
                </LeadrButton>

                {actionItemId && (
                  <LeadrButton
                    name={FormSubmittedByButton.COMPLETE_ACTION_ITEM_BUTTON}
                    disabled={isReadOnly || isLoading}
                    data-test-id="actionItemCompleteButton"
                    size={isMobile ? 'small' : 'standard'}
                    type="submit"
                  >
                    <LeadrButton.IconAndText
                      icon={faCheck}
                      text={actionItem?.status === NewActionItemStatus.Completed ? 'Mark Incomplete' : 'Mark Complete'}
                    />
                  </LeadrButton>
                )}
              </div>
              {actionItemId && (
                <Tooltip content={!hasLimitedAccess ? 'Delete this action item' : 'Limited Access users cannot delete action items'}>
                  <div>
                    <DeleteButtonWithConfirmation
                      renderDeleteButton={({ onClick }) => (
                        <LeadrButton
                          data-test-id="deleteActionItemButton"
                          size={isMobile ? 'small' : 'standard'}
                          variant="ghost"
                          color="danger"
                          onClick={onClick}
                          disabled={(hasLimitedAccess || isReadOnly) && !overRideDeletePermissions}
                        >
                          Delete
                        </LeadrButton>
                      )}
                      renderConfirmationButtons={({
                        informationStyles,
                        optionStyles,
                        popoverButtonStyles,
                      }) => (
                        <DeleteConfirmationButtons
                          onDelete={onDeleteActionItem}
                          informationStyles={informationStyles}
                          optionStyles={optionStyles}
                          popoverButtonStyles={popoverButtonStyles}
                        />
                      )}
                    />
                  </div>
                </Tooltip>
              )}

              {!actionItemId && (
                <LeadrButton
                  onClick={closeDrawerClick}
                  variant="ghost"
                  size={isMobile ? 'small' : 'standard'}
                  data-test-id="actionItemsCancel"
                >
                  Cancel
                </LeadrButton>
              )}
            </div>
          </form>
        </div>

        {areCommentsEnabled && actionItemId && (
          <>
            <ActionItemComments
              css={styles.comments}
              actionItemId={actionItemId}
              onEdit={onEditComment}
              editedCommentId={editedComment?.id}
            />
            <footer css={styles.footer(shouldStickyComment)}>
              {canCommentOnActionItems && (
                <AddEditComment
                  actionItemId={actionItemId}
                  comment={editedComment}
                  onCancelEdit={onCancelEdit}
                  onActiveChange={onIsCommentFieldActiveChange}
                />
              )}
            </footer>
          </>
        )}
      </div>
    )}
  />
);

interface CreateActionItemDrawerState {
  selectedAttendees?: string[],
  dueDate: Date,
}
interface CreateEditActionItemDrawerProps extends DrawerProps<CreateActionItemDrawerState> {
  id?: string,
  context?: ActionItemContext,
  validAssignees?: string[],
  text?: string,
  description?: string,
  hasAdminPermission?: boolean,
  canDeleteActionItemsOnly?: boolean,
  canComment?: boolean,
}

const CreateEditActionItemDrawer = ({
  id,
  drawerState,
  setDrawerState,
  context,
  validAssignees,
  text,
  description,
  pushDrawer,
  popDrawer,
  hasAdminPermission = false,
  canDeleteActionItemsOnly = false,
  canComment = true,
}: CreateEditActionItemDrawerProps): JSX.Element => {
  const [editedComment, setEditedComment] = useState<Note>();
  const [dueDate, setDueDate] = useState<Moment | null>();
  const [showRecurrence, setShowRecurrence] = useState(false);
  const [recurrenceIsValid, setRecurrenceIsValid] = useState(false);
  const [isCommentFieldActive, setIsCommentFieldActive] = useState(false);
  const { isLimitedAccess: hasLimitedAccess } = useUserPermissions();
  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, Person>>;
  const isMobile = useIsMobileQuery();
  const areCommentsEnabled = useFeatureFlag('webClientUseActionItemComments');
  const canCommentOnActionItems = areCommentsEnabled && canComment;

  const doDeleteActionItem = useDeleteActionItem();

  const { mutate: doCreateActionItem, isPending: isCreatingActionItem } = useCreateActionItems({ drawerName: createEditActionItemTemplate.name });
  const { mutate: doEditActionItem, isPending: isEditingActionItem } = useEditActionItem({ drawerName: createEditActionItemTemplate.name });

  const currentUserOrgId = getOrganizationUserId();
  const currentUserId = getUserId();
  const useRecurrence = useFeatureFlag('webClientRecurringActionItems') ?? false;
  const { data: actionItemData } = useActionItemDetails({ id });
  const actionItem = actionItemData?.response;
  const isEdit = useMemo(() => (!!id), [id]);

  const selectedIds = useMemo(() => (drawerState.selectedAttendees), [drawerState.selectedAttendees]);
  const assigneesData = usePeoplePickerByList({ selectedIds }) as Person[];

  const creator = actionItem?.creatorId ? peopleData?.[actionItem.creatorId] : undefined;
  const createdOnDate = moment(actionItem?.createdInMillis).format('MMM D, YYYY');

  const creatorString = useMemo(() => (
    `Created by ${creator?.firstName ?? ''} ${creator?.lastName ?? ''} on ${createdOnDate}`
  ), [creator, createdOnDate]);

  const completedString = useMemo(() => {
    const completer = actionItem?.completedById ? peopleData?.[actionItem.completedById] : undefined;
    const completedOnDate = moment(actionItem?.completedInMillis).format('MMM D, YYYY');

    if (actionItem?.completedInMillis) {
      return `Completed by ${completer?.firstName ?? ''} ${completer?.lastName ?? ''} on ${completedOnDate}`;
    }

    return '';
  }, [actionItem, peopleData]);

  const isReadOnly = isEdit && !(currentUserOrgId === actionItem?.creatorId || currentUserOrgId === actionItem?.assigneeId) && !hasAdminPermission;

  const filteredAssignees = useMemo(() => (assigneesData.filter((attId) => attId !== undefined)), [assigneesData]);
  const assignees = useMemo(() => (
    filteredAssignees.map((assigneeData) => ({
      userId: assigneeData.userId,
      orgUserId: assigneeData.orgUserId,
    }))
  ), [filteredAssignees]);

  useEffect(() => {
    if (actionItem) {
      if (actionItem.dueDateInMillis) {
        setDueDate(moment(actionItem.dueDateInMillis));
      }

      setShowRecurrence(!!actionItem.isRecurring);
    }
  }, [actionItem]);

  const associationData = useMemo(() => (
    getAssociationData(actionItem?.context, true)
  ), [actionItem]);

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: createEditActionItemTemplate.name });
  };

  const onRecurrenceClick = (): void => {
    setShowRecurrence(!showRecurrence);
  };

  const onDeleteActionItem = (): void => {
    if (id) {
      doDeleteActionItem({ id });
    }
  };

  const renderAssociationIcon = useCallback(() => (
    <>
      {associationData && (
        <FontAwesomeIcon
          icon={associationData.icon}
        />
      )}
    </>
  ), [associationData]);

  const closeButton = (closeButtonStyles: SerializedStyles): JSX.Element => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const createActionItem = useCallback((createActionItemData: CreateActionItemData): void => {
    doCreateActionItem({
      actionItems: [
        createActionItemData,
      ],
      context,
    });
  }, [doCreateActionItem, context]);

  const editActionItem = useCallback((editActionItemData: EditActionItemData, shouldToggleCompleted = false) => {
    if (actionItem) {
      const isCompleted = actionItem?.status === NewActionItemStatus.Completed;

      const updatedActionItem = updatedValues(actionItem, editActionItemData);

      if (shouldToggleCompleted) {
        doEditActionItem({
          id: actionItem.id,
          actionItem: {
            ...updatedActionItem,
            status: isCompleted ? NewActionItemStatus.ToDo : NewActionItemStatus.Completed,
          },
          context: actionItem.context,
        });
      } else {
        doEditActionItem({
          id: actionItem.id,
          actionItem: updatedActionItem,
          context: actionItem.context,
        });
      }
    }
  }, [
    actionItem,
    doEditActionItem,
  ]);

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const actionItemFormData = Object.fromEntries(formData.entries()) as unknown as ActionItemFormData;
    let dueDateInMillis;

    if (actionItemFormData.dueDate) {
      dueDateInMillis = moment(actionItemFormData.dueDate, 'MM/DD/YYYY').valueOf();
    }

    const newActionItemData = {
      description: actionItemFormData?.description,
      dueDateInMillis,
      externalLink: actionItemFormData.externalLink,
      text: actionItemFormData.text,
      status: actionItemFormData.status,
      ...useRecurrence && {
        isRecurring: showRecurrence,
        recurrenceRule: actionItemFormData.recurrenceRule,
      },
    };

    if (id) {
      editActionItem({
        assigneeId: assignees?.[0]?.orgUserId,
        assigneeUserId: assignees?.[0]?.userId,
        ...newActionItemData,
        // @ts-expect-error - nativeEvent is not defined in the type definition
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      }, e.nativeEvent.submitter.name === FormSubmittedByButton.COMPLETE_ACTION_ITEM_BUTTON);
    } else {
      createActionItem({
        assignees,
        ...newActionItemData,
      });
    }
  }, [createActionItem, editActionItem, assignees, id, showRecurrence, useRecurrence]);

  const addPeopleClick = ():void => {
    pushDrawer({
      drawer: {
        ...selectParticipantsDrawerTemplate,
        args: {
          useOrgIds: false,
          allowSelf: true,
          filterIds: validAssignees,
          alwaysShowCloseButton: true,
          title: 'Select Assignees',
          ...(isEdit && { limit: 1 }),
        },
      },
    });
  };

  // Changing this to update effect because it's causing a weird condition between loading the edited action item.
  useUpdateEffect(() => {
    if (!dueDate) {
      setShowRecurrence(false);
    }
  }, [dueDate]);

  const onCancelEdit = useCallback(() => {
    setEditedComment(undefined);
  }, []);

  // Add the current user to the selectedAttendees drawerState so they are actually selected
  // in the Add Participants drawer
  useEffect(() => {
    if (!id) {
      setDrawerState((prev) => ({
        ...prev,
        selectedAttendees: [currentUserId],
      }));
    } else if (actionItem) {
      setDrawerState((prev) => ({
        ...prev,
        selectedAttendees: [actionItem.assigneeUserId],
      }));
    }
  }, [setDrawerState, currentUserId, id, actionItem]);

  const overRideDeletePermissions = canDeleteActionItemsOnly || hasAdminPermission;

  const hookProps = {
    description,
    areCommentsEnabled,
    canCommentOnActionItems,
    creatorString,
    completedString,
    onDeleteActionItem,
    renderAssociationIcon,
    associationData,
    hasLimitedAccess,
    actionItemId: id,
    isReadOnly,
    actionItem,
    addPeopleClick,
    assignees: filteredAssignees,
    onSubmit,
    dueDate,
    setDueDate,
    isLoading: isCreatingActionItem || isEditingActionItem,
    text,
    closeDrawerClick,
    showRecurrence,
    onRecurrenceClick,
    closeButton,
    useRecurrence,
    recurrenceIsValid,
    setRecurrenceIsValid,
    editedComment,
    onEditComment: setEditedComment,
    onCancelEdit,
    shouldStickyComment: isCommentFieldActive,
    onIsCommentFieldActiveChange: setIsCommentFieldActive,
    isMobile,
    isEdit,
    overRideDeletePermissions,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: createEditActionItemTemplate.name,
  component: CreateEditActionItemDrawer,
});

export { View };
export default CreateEditActionItemDrawer;
