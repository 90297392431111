import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { DrawerTab, DrawerTabs } from '~Deprecated/ui/components/DrawerManager/DrawerTabs';
import { forMobile } from '~Common/styles/mixins';

const styles = {
  tabs: css({
    marginTop: '1.25rem',
    width: '50% !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',

    '& .MuiTabs-flexContainer': {
      flexWrap: 'wrap',
    },
  }),
  tabStyles: css({
    width: '10%',
    minWidth: '25% !important',
  }, forMobile(`
    width: 100%;
    margin-bottom: .5rem;
`)),
};

const PersonalityTypesTopBar = ({
  setActiveTab,
}) => {
  const [activeTab, updateActiveTab] = useState(0);

  const changeTabs = (event, value) => {
    updateActiveTab(value);
    setActiveTab(value);
  };

  return (
    <DrawerTabs value={activeTab} onChange={changeTabs} css={styles.tabs}>
      <DrawerTab
        label="Enneagram"
        css={styles.tabStyles}
      />
      <DrawerTab
        label="Myers Briggs"
        css={styles.tabStyles}
      />
      <DrawerTab
        label="DiSC"
        css={styles.tabStyles}
      />
      <DrawerTab
        label="Working Genius"
        css={styles.tabStyles}
      />
    </DrawerTabs>
  );
};

PersonalityTypesTopBar.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
};

PersonalityTypesTopBar.defaultProps = {

};

export default PersonalityTypesTopBar;
