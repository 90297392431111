import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import {
  BASE_FONT_SIZE,
  withBaseFont,
  withH1Font,
  withH2Font,
  withH3Font,
  withH4Font,
  withH5Font,
  withH6Font,
} from './typography';

import { rootDefaultAColor } from './colors';

import ProximaNova from '../../fonts/ProximaNova-Reg.otf';
import ProximaNovaMedium from '../../fonts/ProximaNova-Medium.otf';
import ProximaNovaSemiBold from '../../fonts/ProximaNova-Semibold.ttf';
import ProximaNovaBold from '../../fonts/ProximaNova-Bold.ttf';

const withRoot = () => css`
  div#root {
    width: 100%;
  }

  .BeaconFabButtonFrame {
    right: 0.6rem !important;
    bottom: 0.6rem !important;
    transform: scale(0.67) !important;
    z-index: 9999 !important;
  }

  .BeaconContainer {
    z-index: 9999 !important;
  }

  ._pendo-badge {
    inset: auto 65px 15px auto !important;
    transform: scale(0.8) !important;
  }

  #pendo-resource-center-bubble-animation {
    display: none;
  }

  @font-face {
    font-family: "ProximaNova";
    src: url(${ProximaNova});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova";
    src: url(${ProximaNovaMedium});
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova";
    src: url(${ProximaNovaSemiBold});
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "ProximaNova";
    src: url(${ProximaNovaBold});
    font-weight: bold;
    font-style: normal;
  }

  html, h1, h2, h3, h4, h5, h6, p, pre, span, div, button, label {
    ${withBaseFont()}
  }

  button {
    &:focus {
      outline: unset;
    }
  }

  @media print {
    body {
      background-color: white !important;
    }

    .loader-wrapper + a, .BeaconFabButtonFrame, .ReactQueryDevtools, ._pendo-badge {
      display: none !important;
    }
  }

  html {
    font-size: ${BASE_FONT_SIZE}px;
  }

  & h1 {
    ${withH1Font()}
  }

  & h2 {
    ${withH2Font()}
  }

  & h3 {
    ${withH3Font()}
  }

  & h4 {
    ${withH4Font()}
  }

  & h5 {
    ${withH5Font()}
  }

  & h6 {
    ${withH6Font()}
  }

  & a {
    color: ${rootDefaultAColor};

    &:hover {
      color: ${rootDefaultAColor};
    }
  }

  & h1, & h2, & h3, & h4, & h5, & h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  .meeting-scheduleShow .meeting-scheduleDiv .menu-content .assigned-to-details {
    margin-right: 0;
  }

  .modal-root {
    overflow-x: hidden;
  }

  .MuiDrawer-root {
    z-index: 1100 !important;
  }

  .MuiDrawer-paper {
    z-index: 1100 !important;
  }

  .fr-popup {
    z-index: 1201 !important;
  }

  p {
    margin-bottom: 0;
  }

  // Custom styling for Pendo guides.
  .leadr-pendo-guide {
    .bb-button {
      position: absolute;
      right: 0 !important;
    }
    .pendo-mock-flexbox-element {
      display: block !important;
    }
    // These should be primarily radio button groups but I'm trying for specific CSS
    // selectors where practical.
    ._pendo-multi-choice-poll-select-border {
      &:after {
        height: 3rem !important;
      }
      div[role=group] {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
        gap: 1rem;

        span {
          align-items: center;
          border-radius: 1rem;
          border: 1px solid ${palette.neutrals.gray300};
          cursor: pointer;
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          padding: 0.25rem 0.5rem;

          .pendo-radio {
            accent-color: ${palette.brand.indigo};
            align-self: unset !important;
            cursor: pointer;
            display: flex !important;
            height: 1.25rem;
            margin: 0 !important;
            width: 1.25rem;
          }

          .pendo-radio + label {
            cursor: pointer;
            font-size: 0.875rem !important;
            margin: 0 !important;
          }
        }
      }
    }
  }
`;

export default withRoot;
