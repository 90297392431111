import {
  faFilePdf,
  faImage,
  faFileDoc,
  faFile,
  faFilePpt,
  faFileXls,
  IconDefinition,
  faFileVideo,
  faFileAudio,
} from '@fortawesome/pro-regular-svg-icons';
import { AcceptedExtension } from '~Meetings/const/attachments';

export function extractFileExtension(fileName: string): [string, string] {
  const lastPeriodIndex = fileName.lastIndexOf('.');

  if (lastPeriodIndex !== -1 && lastPeriodIndex !== fileName.length - 1) {
    return [fileName.substring(0, lastPeriodIndex), fileName.substring(lastPeriodIndex + 1)];
  }

  // Handle cases where there is no period or the period is the last character
  return [fileName, ''];
}

export const fileExtensionToIcon = (extension?: AcceptedExtension): IconDefinition => {
  let icon: IconDefinition;

  switch (extension?.toLowerCase()) {
    case 'png':
    case 'gif':
    case 'bmp':
    case 'jpg':
      icon = faImage;
      break;
    case 'pdf':
      icon = faFilePdf;
      break;
    case 'doc':
    case 'docx':
      icon = faFileDoc;
      break;
    case 'xls':
    case 'xlsx':
      icon = faFileXls;
      break;
    case 'ppt':
    case 'pptx':
      icon = faFilePpt;
      break;
    case 'mp3':
    case 'wav':
    case 'flac':
    case 'webm':
      icon = faFileAudio;
      break;
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'wmv':
    case 'mkv':
    case 'm4a':
      icon = faFileVideo;
      break;
    default:
      icon = faFile;
      break;
  }

  return icon;
};
