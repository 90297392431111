import { ComponentProps, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { Link } from 'react-router-dom';

import { palette } from '~Common/styles/colors';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { forDesktopObject, withoutDesktopObject } from '~Common/styles/mixins';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import MeetingGoalCard from '~Meetings/components/details/goals/MeetingGoalCard';
import OldMeetingGoalCard from '~Goals/OldGoals/OldMeetingGoalCard';
import { SelectChangeEvent } from '@mui/material';
import {
  INITIAL_STATIC_DEFINING_OBJECTIVE_GOALS_PARAMS, teamMeetingGoalContextTypes,
} from '~Meetings/const/goals';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import ReactiveSortByDropdown from '~Meetings/components/details/goals/filters/ReactiveSortByDropdown';
import ReactiveGoalContextTypeDropdown from '~Meetings/components/details/goals/filters/ReactiveGoalContextTypeDropdown';

const styles = {
  container: css({
    padding: '1rem 1.75rem',
  }, withoutDesktopObject({
    boxShadow: 'none',
    padding: '1rem',
  }), forDesktopObject({
    backgroundColor: palette.neutrals.white,
    boxShadow: '0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.07)',
    borderRadius: '0.5rem',
  })),

  header: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.75rem',
  }),

  title: css({
    fontSize: '1.125rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  }),
  addNewButton: css({
    fontSize: '.75rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  }),
  addNewButtonIcon: css({
    gap: '.25rem',
    svg: {
      fontSize: '.75rem',
      width: '.75rem',
      height: '.75rem',
    },
  }),
  divider: css({
    margin: '0 0 0.75rem 0',
  }),
  cardContainer: css({
    display: 'flex',
    overflowX: 'auto',

    // Needed so the box shadow of the goal cards isn't cut off
    padding: '0.5rem',
  }),
  goalCard: css({
    marginRight: '0.625rem',
  }),
  emptyStateButton: css({
    padding: 0,
    fontStyle: 'italic',
  }),
  emptyStateMessage: css({
    fontStyle: 'italic',
    color: palette.neutrals.gray700,
  }),
  dropdown: css({
    width: 'min-content',
  }),
  label: css({
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
  rightSide: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  definingObjectives?: Goals.Goal[] | Goals.GoalWithCascading[],
  isLoading: boolean,
  shouldShowActionableEmptyState?: boolean,
  cascadingGoals: boolean,
  selectedContextType: Goals.GoalContextType | string,
  onContextTypeChange: (event: SelectChangeEvent<Goals.GoalContextType | string>) => void,
  sortBy: Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title,
  onSortByChange: (event: SelectChangeEvent<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>) => void,
  isMobile: boolean,
  isUserOnTeam?: boolean,
  isAdmin?: boolean,
  isExecutive?: boolean,
}

export const View = ({
  isLoading,
  definingObjectives = [],
  shouldShowActionableEmptyState,
  isUserOnTeam,
  isAdmin,
  isExecutive,
  cascadingGoals,
  selectedContextType,
  onContextTypeChange,
  sortBy,
  onSortByChange,
  isMobile,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    <header css={styles.header}>
      <div css={styles.title}>
        <div>
          <span data-test-id="meetingDetailsDefiningObjectives">
            {`Defining Objectives ${definingObjectives.length ? `(${definingObjectives.length})` : ''}`}
          </span>
        </div>
        <div css={styles.rightSide}>
          {cascadingGoals && (
            <>
              <ReactiveSortByDropdown
                sortBy={sortBy}
                onSortByChange={onSortByChange}
              />
              <ReactiveGoalContextTypeDropdown
                items={teamMeetingGoalContextTypes}
                selectedContextType={selectedContextType}
                onContextTypeChange={onContextTypeChange}
              />
            </>
          )}
          { !!definingObjectives?.length && shouldShowActionableEmptyState && (
            <LeadrButton
              component={Link}
              to={GoalsRoutes.Create}
              variant="text"
              data-test-id="definingObjectivesAddNew"
              css={styles.addNewButton}
              textButtonColor={palette.brand.indigo}
            >
              <LeadrButton.IconAndText
                icon={faPlus}
                text={isMobile ? '' : 'Add New'}
                css={styles.addNewButtonIcon}
              />
            </LeadrButton>
          )}
        </div>
      </div>
    </header>

    <hr css={styles.divider} />

    <div>
      { isLoading && (
        <div css={styles.cardContainer}>
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
        </div>
      )}

      {!isLoading && (
        <>
            {!!definingObjectives.length && (
              <div css={styles.cardContainer}>
                {cascadingGoals && (
                  <>
                    { definingObjectives.map((definingObjective) => {
                      if ('totalChildGoals' in definingObjective) {
                        return (
                          <MeetingGoalCard
                            key={definingObjective.goalId}
                            css={styles.goalCard}
                            canAccessGoal={isUserOnTeam || isAdmin || isExecutive}
                            goalId={definingObjective.goalId}
                            title={definingObjective.title}
                            status={definingObjective.statusUpdates[definingObjective.statusUpdates.length - 1].status}
                            category={definingObjective.category}
                            startTime={definingObjective.startTimeInMillis}
                            endTime={definingObjective.endTimeInMillis}
                            totalChildGoals={definingObjective.totalChildGoals}
                          />
                        );
                      }
                      return (
                        <MeetingGoalCard
                          key={definingObjective.goalId}
                          css={styles.goalCard}
                          canAccessGoal={isUserOnTeam || isAdmin || isExecutive}
                          goalId={definingObjective.goalId}
                          title={definingObjective.title}
                          status={definingObjective.statusUpdates[definingObjective.statusUpdates.length - 1].status}
                          category={definingObjective.category}
                          startTime={definingObjective.startTimeInMillis}
                          endTime={definingObjective.endTimeInMillis}
                          totalChildGoals={0}
                        />
                      );
                    })}
                  </>
                )}
                {!cascadingGoals && (
                  <>
                    {definingObjectives.map((definingObjective) => (
                      <OldMeetingGoalCard
                        key={definingObjective.goalId}
                        css={styles.goalCard}
                        goal={definingObjective}
                        canAccessGoal={isUserOnTeam || isAdmin || isExecutive}
                      />
                    ))}
                  </>
                )}
              </div>
            )}

          {!definingObjectives.length && (
            <>
              {shouldShowActionableEmptyState && (
                <div>
                  <LeadrButton
                    data-test-id="definingObjectivesEmptyStateButton"
                    css={styles.emptyStateButton}
                    variant="text"
                    component={Link}
                    to={GoalsRoutes.Create}
                  >
                    Add Objectives
                  </LeadrButton>

                  <span css={styles.emptyStateMessage}>
                    &nbsp;for this team
                  </span>
                </div>
              )}

              {!shouldShowActionableEmptyState && (
                <span css={styles.emptyStateMessage}>
                  Defining Objectives not created
                </span>
              )}
            </>
          )}
        </>
      )}
    </div>
  </div>
);

export interface DefiningObjectivesProps extends ComponentProps<'div'> {
  teamId: string,
  huddleId: string,
}

const DefiningObjectives = ({
  teamId,
  huddleId,
  ...props
}: DefiningObjectivesProps): JSX.Element => {
  const orgUserId = getOrganizationUserId();
  const cascadingGoals = useFeatureFlag('cascadingGoals');
  const { isAdmin, isExecutive } = useUserPermissions();
  const [selectedContextType, setSelectedContextType] = useState<Goals.GoalContextType | string>(Goals.GoalContextType.Team);
  const [sortBy, setSortBy] = useState<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>(Goals.GetGoalsSortBy.DueDate);
  const { data: teamDetailsData, isLoading: isTeamDetailsLoading } = useGetTeamDetails({ teamId, huddleId });
  const { orgLevelEnableCascadingGoals } = useEnableCascadingGoals();

  const onContextTypeChange = (event: SelectChangeEvent<Goals.GoalContextType | string>): void => {
    setSelectedContextType(event.target.value);
  };

  const onSortByChange = (event: SelectChangeEvent<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>): void => {
    setSortBy(event.target.value as Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title);
  };

  const params = useMemo(() => {
    const paramsToUse = {
      ...INITIAL_STATIC_DEFINING_OBJECTIVE_GOALS_PARAMS,
      ...(selectedContextType === Goals.GoalContextType.Team ? { contextId: teamId } : {}),
      contextType: selectedContextType,
      enableCascading: orgLevelEnableCascadingGoals,
      sortBy,
    };

    // Doing this this way because we want to include the category as part of the initial params and then remove it if it's not needed
    if (selectedContextType === Goals.GoalContextType.Organization) {
      delete paramsToUse.category;
    }

    return paramsToUse;
  }, [selectedContextType, teamId, orgLevelEnableCascadingGoals, sortBy]);

  const { data, isLoading: isGoalsLoading } = useGetGoals({ params, enabled: !isTeamDetailsLoading });

  const shouldShowActionableEmptyState = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    isAdmin || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    isAdmin,
    orgUserId,
    teamDetailsData?.response.ownerOrgUserIds,
  ]);

  const [isLoading] = useSkeletonLoaders(isTeamDetailsLoading || isGoalsLoading);

  const isUserOnTeam = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    teamDetailsData?.response.memberOrgUserIds.includes(orgUserId!)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    orgUserId,
    teamDetailsData?.response,
  ]);

  const isMobile = useIsMobileQuery();

  const hookProps = {
    definingObjectives: data?.response ?? [],
    isLoading,
    shouldShowActionableEmptyState,
    isUserOnTeam,
    isAdmin,
    isExecutive,
    cascadingGoals,
    selectedContextType,
    onContextTypeChange,
    sortBy,
    onSortByChange,
    isMobile,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default DefiningObjectives;
