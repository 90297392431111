import { css } from '@emotion/react';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import { palette } from '~Common/styles/colors';

import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useUpdateOrganizationSettings } from '~Admin/hooks/settings/useUpdateOrganizationSettings';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { OrgSettings } from '~Common/const/Organization';
import CustomFavoritesFields from './CustomFavoritesFields';
import CustomPersonalityTypeModels from './CustomPersonalityTypeModels';
import EnterpriseSSO from './EnterpriseSSO';
import HidePersonalityTypeModels from './HidePersonalityTypeModels';
import SettingsSection from './SettingsSection';
import HomeWidgets from './HomeWidgets';
import OrgClarity from './OrgClarity';
import WorkingGeniusFields from './WorkingGenius';
import Attachments from './Attachments';
import FeatureSettings from './FeatureSettings';

const styles = {
  adminSettings: css({}),
  settingsSection: css({
    marginTop: '1.5rem',
    paddingBottom: '1.5rem',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${palette.neutrals.gray200}`,
    },
  }),
};

interface ViewProps {
  orgSettings: OrgSettings,
  showFeatureSettings: boolean,
  webClientUseMatrixOrgs: boolean,
  cascadingGoals: boolean,
  shouldShowEnterpriseSSO: boolean,
  handleAdminSettingsSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
  isUpdatingOrgSettings: boolean,
  navigatedFromOrgClarity: boolean | undefined,
  scrollRefProps: {
    ref: MutableRefObject<HTMLDivElement | null>,
  },
  isTableGroup: boolean,
  webAppAttachmentsOnAgendaTopics: boolean,
}

const View = ({
  orgSettings,
  showFeatureSettings,
  webClientUseMatrixOrgs,
  cascadingGoals,
  shouldShowEnterpriseSSO,
  handleAdminSettingsSubmit,
  isUpdatingOrgSettings,
  scrollRefProps,
  navigatedFromOrgClarity,
  isTableGroup,
  webAppAttachmentsOnAgendaTopics,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.adminSettings}
    {...props}
  >
    <form onSubmit={handleAdminSettingsSubmit}>
      {webAppAttachmentsOnAgendaTopics && (
      <SettingsSection
        css={styles.settingsSection}
        renderSection={() => (
          <Attachments
            attachmentSettings={orgSettings.attachmentSettings}
          />
        )}
      />
      )}
      <SettingsSection
        css={styles.settingsSection}
        renderSection={(settingsSectionStyles) => (
          <HidePersonalityTypeModels
            css={settingsSectionStyles}
            orgSettings={orgSettings}
          />
        )}
      />
      <SettingsSection
        css={styles.settingsSection}
        renderSection={(settingsSectionStyles) => (
          <HomeWidgets
            css={settingsSectionStyles}
            orgSettings={orgSettings}
          />
        )}
      />

      {isTableGroup && (
      <SettingsSection
        css={styles.settingsSection}
        renderSection={(settingsSectionStyles) => (
          <OrgClarity orgSettings={orgSettings} css={settingsSectionStyles} />
        )}
        {...(navigatedFromOrgClarity && scrollRefProps)}
      />
      )}

      <SettingsSection
        css={styles.settingsSection}
        renderSection={(settingsSectionStyles) => (
          <CustomFavoritesFields orgSettings={orgSettings} css={settingsSectionStyles} />
        )}
      />
      <SettingsSection
        css={styles.settingsSection}
        renderSection={(settingsSectionStyles) => (
          <WorkingGeniusFields orgSettings={orgSettings} css={settingsSectionStyles} />
        )}
      />
      <SettingsSection
        css={styles.settingsSection}
        renderSection={(settingsSectionStyles) => (
          <CustomPersonalityTypeModels orgSettings={orgSettings} css={settingsSectionStyles} />
        )}
      />

      {showFeatureSettings && (
      <SettingsSection
        css={styles.settingsSection}
        renderSection={(settingsSectionStyles) => (
          <FeatureSettings
            css={settingsSectionStyles}
            orgSettings={orgSettings}
            cascadingGoals={cascadingGoals}
            webClientUseMatrixOrgs={webClientUseMatrixOrgs}
          />
        )}
      />
      )}

      {shouldShowEnterpriseSSO && (
      <SettingsSection
        css={styles.settingsSection}
        renderSection={(settingsSectionStyles) => (
          <EnterpriseSSO orgSettings={orgSettings} css={settingsSectionStyles} />
        )}
      />
      )}
      <LeadrButton
        type="submit"
        disabled={isUpdatingOrgSettings}
        data-test-id="settingsSaveAdminSettings"
      >
        Save Settings
      </LeadrButton>
    </form>
  </div>
);

const AdminSettings = ({ ...props }): JSX.Element => {
  const { isPending: isUpdatingOrgSettings, mutate: updateOrganizationSettingsMutation } = useUpdateOrganizationSettings();
  const webClientUseMatrixOrgs = useFeatureFlag('webClientUseMatrixOrgs');
  const { featureFlagEnableCascadingGoals: cascadingGoals } = useEnableCascadingGoals();
  const showFeatureSettings = webClientUseMatrixOrgs || cascadingGoals;
  const webAppTgCobrandingAndOrgClarity = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const webAppAttachmentsOnAgendaTopics = useFeatureFlag('webAppAttachmentsOnAgendaTopics');
  const { organizationInTrial } = useIsOrganizationInTrial();
  const { orgSettings, enableTheTable } = useOrgDetailsContext();
  const {
    orgSettingsId,
    createdInMillis,
    lastModifiedInMillis,
  } = orgSettings;
  const { isCustomerSuccessAdmin, isCustomerSuccessManager } = useOrgUserPermissionsContext();

  const shouldShowEnterpriseSSO = !organizationInTrial && (!!isCustomerSuccessAdmin || !!isCustomerSuccessManager);
  const isTableGroup = enableTheTable && webAppTgCobrandingAndOrgClarity;

  const { state: { navigatedFromOrgClarity } = {} } = useLocation<{ navigatedFromOrgClarity?: boolean }>();

  // Can use this to scroll to any specific section on the page
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollRefProps = {
    ref: scrollRef,
  };
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleAdminSettingsSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    /*
      Pulled from the node validation (currently: patchOrgSettingsSchema):
        These fields have some conditions:
          customFavorite1Name
          customFavorite2Name
          customPersonality1Name
          customPersonality2Name
        Conditions:
          Cannot be an empty string
          Required if their respective enable field is turned on (example: enableCustomFavorite1 === true)
    */

    const newHomeSettings = {
      enableCelebrate: !!formData.get('enableCelebrate'),
      enableGoals: !!formData.get('enableGoals'),
      enableInsights: !!formData.get('enableInsights'),
      enableMeetings: !!formData.get('enableMeetings'),
      enableOrgClarity: !!formData.get('enableOrgClarity'),
      enablePeople: !!formData.get('enablePeople'),
      enableRecognition: !!formData.get('enableRecognition'),
      enableTeamClarity: !!formData.get('enableTeamClarity'),
      enableWhatsDue: !!formData.get('enableWhatsDue'),
      enableWhatsDueActionItems: !!formData.get('enableWhatsDueActionItems'),
      enableWhatsDueFeedback: !!formData.get('enableWhatsDueFeedback'),
      enableWhatsDueSurveys: !!formData.get('enableWhatsDueSurveys'),
      enableWhatsDueLearning: !!formData.get('enableWhatsDueLearning'),
      enableWhatsDueReviews: !!formData.get('enableWhatsDueReviews'),
    };

    const orgClarityQuestionList = orgSettings.orgClarityQuestionList?.map((orgClarityQuestion) => {
      const question = formData.get(`${orgClarityQuestion.rank}-question`) as string;
      const answer = formData.get(`${orgClarityQuestion.rank}-answer`) as string;

      return {
        ...orgClarityQuestion,
        question,
        answer,
      };
    }) || [];

    const newProfileEditRestrictions = {
      enableProfileNameEdits: !!formData.get('enableProfileNameEdits'),
      enableProfileStatusEdits: !!formData.get('enableProfileStatusEdits'),
      enableProfileJobTitleEdits: !!formData.get('enableProfileJobTitleEdits'),
      enableProfileDepartmentEdits: !!formData.get('enableProfileDepartmentEdits'),
      enableProfileManagerEdits: !!formData.get('enableProfileManagerEdits'),
    };

    const newAttachmentSettings = {
      enableAttachmentLocalUpload: formData.get('enableAttachmentLocalUpload') === 'on',
    };

    const enableEnterpriseSSO = formData.get('enableEnterpriseSSO') === 'on';
    const rawPrimaryEmailDomains = formData.get('primaryEmailDomains')?.toString() ?? '';
    const primaryEmailDomains = rawPrimaryEmailDomains.replace(/\s/g, '').split(',') ?? [];

    const newOrgSettings = {
      orgSettingsId,
      createdInMillis,
      // Not sure if we want to update the lastModifiedInMillis here, but it's not in the old rendition of this workflow
      lastModifiedInMillis,
      // Checkboxes in forms get on or off as a value, so we need to convert them to booleans
      enableEnneagram: formData.get('enableEnneagram') === 'on',
      enableBriggsMyers: formData.get('enableBriggsMyers') === 'on',
      enableDisc: formData.get('enableDisc') === 'on',
      enableStrengthFinder: formData.get('enableStrengthFinder') === 'on',
      enableWorkingGenius: formData.get('enableWorkingGenius') === 'on',
      enableCustomFavorite1: formData.get('enableCustomFavorite1') === 'on',
      customFavorite1Name: formData.get('customFavorite1Name') as string || null,
      enableCustomFavorite2: formData.get('enableCustomFavorite2') === 'on',
      customFavorite2Name: formData.get('customFavorite2Name') as string || null,
      enableCustomPersonality1: formData.get('enableCustomPersonality1') === 'on',
      customPersonality1Name: formData.get('customPersonality1Name') as string || null,
      enableCustomPersonality2: formData.get('enableCustomPersonality2') === 'on',
      customPersonality2Name: formData.get('customPersonality2Name') as string || null,
      workingGeniusUrl: formData.get('workingGeniusUrl') as string || null,
      enableMatrixOrganization: formData.get('enableMatrixOrganization') === 'on',
      enableCascadingGoals: formData.get('enableCascadingGoals') === 'on',

      // In existing implementation, users who couldn't change Enterprise SSO settings were overwriting them
      ...(shouldShowEnterpriseSSO && {
        enableEnterpriseSSO,
        ...(enableEnterpriseSSO && {
          primaryEmailDomains,
          auth0EnterpriseConnectionName: formData.get('auth0EnterpriseConnectionName') as string || '',
          profileEditRestrictions: newProfileEditRestrictions,
        }),
      }),
      homeSettings: newHomeSettings,
      ...(webAppAttachmentsOnAgendaTopics && newAttachmentSettings),
      orgClarityQuestionList,
    };

    updateOrganizationSettingsMutation(newOrgSettings);
  };

  const hookProps = {
    orgSettings,
    showFeatureSettings,
    webClientUseMatrixOrgs,
    cascadingGoals,
    shouldShowEnterpriseSSO,
    handleAdminSettingsSubmit,
    isUpdatingOrgSettings,
    scrollRefProps,
    navigatedFromOrgClarity,
    isTableGroup,
    webAppAttachmentsOnAgendaTopics,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AdminSettings;
