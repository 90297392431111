import { useQuery } from '@tanstack/react-query';
import { getUserTimezone, getOrganizationId } from '~Common/utils/localStorage';

import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { MeetingFrequency, NonLeadrAttendee } from '~Meetings/const/meetingsInterfaces';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { calendarIntQueryKeys } from './queryKeys';

export interface UnsyncedLeadrMeeting {
  meetingFactoryId: string,
  title: string,
  startTimeInMillis: number,
  endTimeInMillis: number,
  location: string,
  frequency: MeetingFrequency,
  timezone: string,
  attendeeIds: string[],
  nonLeadrAttendees: NonLeadrAttendee[],
}

const getUnsyncedLeadrMeetings = async (): Promise<HttpCallReturn<UnsyncedLeadrMeeting[]>> => {
  const timezone = getUserTimezone();
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/outgoingReconciliation?timezone=${timezone ?? ''}`;

  return getApi<UnsyncedLeadrMeeting[]>(serverUrl);
};

interface UseGetUnsyncedLeadrMeetingsReturn {
  unsyncedLeadrMeetings?: UnsyncedLeadrMeeting[] | undefined,
  isLoading: boolean,
}

export const useGetUnsyncedLeadrMeetings = (): UseGetUnsyncedLeadrMeetingsReturn => {
  const { data: authStatus } = useGetCalendarAuthStatus();
  const result = useQuery({
    queryKey: calendarIntQueryKeys.unsyncedLeadrMeetings(),
    queryFn: getUnsyncedLeadrMeetings,
    enabled: authStatus === AUTH_STATUS.LINKED,
  });

  return {
    unsyncedLeadrMeetings: result.data?.response,
    isLoading: result.isLoading,
  };
};
