import { css } from '@emotion/react';

import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import Banner from '~Common/V3/components/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hexToRGBA, palette } from '~Common/styles/colors';
import IconContents from '~Common/V3/components/Banner/IconContents';
import Button from '~Common/V3/components/Button';
import { reviewUnsyncedMeetingsDrawerTemplate } from '../drawers/ReviewUnsyncedMeetingsDrawer';

const styles = {
  banner: css({
    backgroundColor: hexToRGBA(palette.brand.sky, 0.10),
    color: palette.neutrals.gray800,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  icon: css({
    color: palette.brand.indigo,
  }),
  iconContentsContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  reviewMeetingsButton: css({
    color: palette.brand.indigo,
    marginRight: '0.5rem',
  }),
  dismissButton: css({
    color: palette.neutrals.gray800,
  }),
};

interface ViewProps {
  handleReviewMeetingsClick: () => void,
  handleDismissBanner: () => void,
  numberOfMeetingsToReview: number,
}

const View = ({
  handleDismissBanner,
  numberOfMeetingsToReview,
  handleReviewMeetingsClick,
  ...props
}: ViewProps): JSX.Element => (
  <Banner
    css={styles.banner}
    renderContents={() => (
      <>
        <div css={styles.iconContentsContainer}>
          <IconContents
            renderIcon={() => (
              <FontAwesomeIcon css={styles.icon} size="3x" icon={faCalendarAlt} />
            )}
            renderText={() => (
              // eslint-disable-next-line max-len
              <>{`We found ${numberOfMeetingsToReview} meeting${numberOfMeetingsToReview > 1 ? 's' : ''} that need${numberOfMeetingsToReview === 1 ? 's' : ''} your review.`}</>
            )}
          />
        </div>
        <div>
          <Button
            css={styles.reviewMeetingsButton}
            variant="text"
            renderContents={() => <>Review Meetings</>}
            onClick={handleReviewMeetingsClick}
          />
          <Button
            css={styles.dismissButton}
            variant="text"
            renderContents={() => <>Dismiss</>}
            onClick={handleDismissBanner}
          />
        </div>
      </>
    )}
    {...props}
  />
);

interface ReviewUnsyncedMeetingsBannerProps {
  numberOfMeetingsToReview: number,
  dismissReviewUnsyncedMeetingsBanner: () => void,
}

const ReviewUnsyncedMeetingsBanner = ({
  numberOfMeetingsToReview,
  dismissReviewUnsyncedMeetingsBanner,
  ...props
}: ReviewUnsyncedMeetingsBannerProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleReviewMeetingsClick = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...reviewUnsyncedMeetingsDrawerTemplate,
      },
    }));
  };

  const handleDismissBanner = (): void => {
    dismissReviewUnsyncedMeetingsBanner();
  };

  const hookProps = {
    handleReviewMeetingsClick,
    handleDismissBanner,
    numberOfMeetingsToReview,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export { View };
export default ReviewUnsyncedMeetingsBanner;
