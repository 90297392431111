import { useState } from 'react';
import LearningPreview, { LearningPreviewProps } from './LearningPreview';
import ExternalNavigationOverlay from './Overlays/ExternalNavigationOverlay';

interface ViewProps extends LearningPreviewProps {
  contentUrl: string,
  isNavigateButtonVisible: boolean,
  showNavigateButton: () => void,
  hideNavigateButton: () => void,
}

const View = ({
  contentUrl,
  isNavigateButtonVisible,
  showNavigateButton,
  hideNavigateButton,
  ...props
}: ViewProps): JSX.Element => (
  <LearningPreview
    onMouseEnter={showNavigateButton}
    onMouseLeave={hideNavigateButton}
    contentUrl={contentUrl}
    renderOverlay={isNavigateButtonVisible ? () => (
      <ExternalNavigationOverlay
        contentUrl={contentUrl}
      />
    ) : undefined}
    {...props}
  />
);

type PreviewWithExternalNavigationProps = Omit<ViewProps, 'isNavigateButtonVisible' | 'showNavigateButton' | 'hideNavigateButton'>;

const PreviewWithExternalNavigation = ({ ...props }: PreviewWithExternalNavigationProps): JSX.Element => {
  const [isNavigateButtonVisible, setIsNavigateButtonVisible] = useState(false);

  const showNavigateButton = (): void => {
    setIsNavigateButtonVisible(true);
  };

  const hideNavigateButton = (): void => {
    setIsNavigateButtonVisible(false);
  };

  const hookProps = {
    isNavigateButtonVisible,
    showNavigateButton,
    hideNavigateButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default PreviewWithExternalNavigation;
