import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu as MUIMenu, MenuItem } from '@mui/material';
import Divider from '@mui/material/Divider';
import {
  faArrowsRepeat,
  faClone,
  faEdit,
  faPlus,
  faSquareDashed,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import { useAgendaPermissions } from '~Meetings/hooks/utils/useAgendaPermissions';
import { useCallback } from 'react';
import { useDeleteAgendaSection } from '~Meetings/hooks/sections/useDeleteAgendaSection';
import { getLocalId } from '~Common/utils/uuid';
import { useCarryOverAgendaSection } from '~Meetings/hooks/sections/useCarryOverAgendaSection';
import { useEditAgendaSection } from '~Meetings/hooks/sections/useEditAgendaSection';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';

const styles = {
  menu: css({
    '.MuiList-root': {
      padding: 0,
    },
  }),
  menuItem: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: palette.neutrals.gray900,
    fontSize: '.875rem',
    fontWeight: 500,
  }),
  menuIcon: css({
    color: palette.brand.indigo,
    width: '.875rem',
    height: '.875rem',
    marginLeft: '.75rem',
  }),
  hr: css({
    margin: '0 1rem',
  }),
  deleteMenuItem: css({
    color: palette.brand.red,
  }),
  deleteMenuIcon: css({
    color: palette.brand.red,
  }),
};

interface ViewProps {
  anchorEl: HTMLElement | null,
  isMenuOpen: boolean,
  isRecurring: boolean,
  handleCloseMenuClick: () => void,
  handleAddTopicClick: () => void,
  handleEditSectionTitle: () => void,
  handleUpdateRecurrence: (isRecurring: boolean) => void,
  handleCarryOverClick: () => void,
  handleDeleteClick: () => void,
  canAddAgendaItem: boolean,
  canCarryOver: boolean,
  canEdit: boolean,
  canUpdateRecurrence: boolean,
  canDelete: boolean,
  numberOfAgendaItems: number,
}

const View = ({
  anchorEl,
  isMenuOpen,
  isRecurring,
  handleUpdateRecurrence,
  handleCloseMenuClick,
  handleAddTopicClick,
  handleEditSectionTitle,
  handleCarryOverClick,
  handleDeleteClick,
  canAddAgendaItem,
  canCarryOver,
  canEdit,
  canUpdateRecurrence,
  canDelete,
  numberOfAgendaItems,
  ...props
}: ViewProps): JSX.Element => (
  <MUIMenu
    anchorEl={anchorEl}
    open={isMenuOpen}
    onClose={handleCloseMenuClick}
    onClick={handleCloseMenuClick}
    css={styles.menu}
    {...props}
  >
    {canEdit && (
      <MenuItem
        data-test-id="meetingsMenuEditSectionTitle"
        css={styles.menuItem}
        onClick={handleEditSectionTitle}
      >
        <span>Edit Section Title</span>
        <FontAwesomeIcon css={styles.menuIcon} icon={faEdit} />
      </MenuItem>
    )}
    {canAddAgendaItem && (
      <MenuItem
        data-test-id="meetingsMenuAddTopic"
        css={styles.menuItem}
        onClick={handleAddTopicClick}
      >
        <span>Add Topic</span>
        <FontAwesomeIcon css={styles.menuIcon} icon={faPlus} />
      </MenuItem>
    )}
    {canUpdateRecurrence && (
      <MenuItem
        data-test-id="meetingsMenuUpdateRecurrence"
        css={styles.menuItem}
        onClick={() => handleUpdateRecurrence(!isRecurring)}
      >
        <span>{isRecurring ? 'Remove Recurrence' : 'Make Recurring'}</span>
        <FontAwesomeIcon css={styles.menuIcon} icon={faArrowsRepeat} />
      </MenuItem>
    )}
    {canCarryOver && (
      <MenuItem
        data-test-id="meetingsMenuCarryOver"
        css={styles.menuItem}
        onClick={handleCarryOverClick}
      >
        <span>Carry over to Next Meeting</span>
        <FontAwesomeIcon css={styles.menuIcon} icon={faClone} />
      </MenuItem>
    )}
    { /* The following two conditions are split up to avoid a MUI error about no fragments in a Menu */ }
    {canDelete && (
      <Divider css={styles.hr} />
    )}
    {canDelete && (
      <MenuItem
        data-test-id={numberOfAgendaItems > 0 ? 'meetingsMenuUngroupSection' : 'meetingsMenuDeleteSection'}
        css={[styles.menuItem, styles.deleteMenuItem]}
        onClick={handleDeleteClick}
      >
        {numberOfAgendaItems > 0 && (
          <>
            <span>Ungroup Section</span>
            <FontAwesomeIcon css={[styles.menuIcon, styles.deleteMenuIcon]} icon={faSquareDashed} />
          </>
        )}
        {numberOfAgendaItems === 0 && (
          <>
            <span>Delete Section</span>
            <FontAwesomeIcon css={[styles.menuIcon, styles.deleteMenuIcon]} icon={faTrash} />
          </>
        )}
      </MenuItem>
    )}
  </MUIMenu>
);

interface MenuProps {
  anchorEl: HTMLElement | null,
  isMenuOpen: boolean,
  isRecurring: boolean,
  handleCloseMenuClick: () => void,
  handleAddTopicClick: () => void,
  numberOfAgendaItems: number,
  huddleId: string,
  sectionId: string,
  text: string,
}

const Menu = ({
  huddleId,
  sectionId,
  text,
  ...props
}: MenuProps): JSX.Element => {
  const { getSectionPermissions } = useAgendaPermissions(huddleId);
  const { mutate: carryOverAgendaSection } = useCarryOverAgendaSection();

  const showItemEditor = useShowItemEditor((state) => state.showItemEditor);

  const organizationId = getOrganizationId();
  const agendaSectionDraftKey = [organizationId!, huddleId, 'agendaSection', sectionId];
  const { mutate: doEdit } = useEditAgendaSection({
    huddleId,
    sectionId,
    draftKey: agendaSectionDraftKey,
  });

  const {
    canAddAgendaItem,
    canCarryOver,
    canEdit,
    canUpdateRecurrence,
    canDelete,
    hasMenuOptions,
  } = getSectionPermissions(sectionId);

  const { mutate: doDelete } = useDeleteAgendaSection(huddleId);

  const handleDeleteClick = useCallback(() => {
    doDelete({
      huddleId,
      sectionId,
    });
  }, [doDelete, huddleId, sectionId]);

  const handleEditSectionTitle = useCallback(() => {
    showItemEditor(sectionId);
  }, [showItemEditor, sectionId]);

  const handleCarryOverClick = useCallback(() => {
    carryOverAgendaSection({
      huddleId,
      sectionId,
    });
  }, [sectionId, carryOverAgendaSection, huddleId]);

  const handleUpdateRecurrence = useCallback((isRecurring: boolean) => {
    const newEventId = getLocalId();
    doEdit({
      sectionId,
      data: {
        section: {
          text,
          isRecurring,
        },
        huddleId,
        eventId: newEventId,
      },
    });
  }, [doEdit, sectionId, text, huddleId]);

  const hookProps = {
    canAddAgendaItem,
    canCarryOver,
    canEdit,
    canUpdateRecurrence,
    canDelete,
    handleDeleteClick,
    handleEditSectionTitle,
    handleCarryOverClick,
    handleUpdateRecurrence,
  };

  if (!hasMenuOptions) {
    return <></>;
  }

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default Menu;
