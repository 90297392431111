import { Moment } from 'moment-timezone';

const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);
const getOrdinalSuffix = (n: number):string => {
  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });
  const rule = pr.select(n);
  const suffix = suffixes.get(rule) as string;
  return `${n}${suffix}`;
};

export const getMonthlyWeekdayFrequencyString = (mDate: Moment):string => {
  const monthWeekNumber = Math.ceil(mDate.date() / 7);
  const isLastWeek = monthWeekNumber > 3;

  if (isLastWeek) {
    return `Last ${mDate.format('dddd')} of month`;
  }

  return `${getOrdinalSuffix(monthWeekNumber)} ${mDate.format('dddd')} of month`;
};

export const getMonthlyDayFrequencyString = (mDate: Moment):string => {
  const isEndOfMonthBound = mDate.date() >= 29;
  if (isEndOfMonthBound) {
    return `Day ${mDate.date()}/Last Day`;
  }

  return `Day ${mDate.date()}`;
};
