import { css } from '@emotion/react';
import { transparentize } from 'polished';
import {
  ComponentProps,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { AgendaTopic, AgendaSection as AgendaSectionType } from '~Meetings/hooks/v2/useGetAgendas';
import CreateAgendaItem, { OnSaveProps } from '~Meetings/components/details/agenda/CreateAgendaItem';
import { useDraft } from '~Common/hooks/useDraft';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { useEditAgendaSection } from '~Meetings/hooks/sections/useEditAgendaSection';
import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import { useShowAgendaSection } from '~Meetings/components/topic-suggestions/stores/useShowAgendaSection';
import { getLocalId, isLocalId } from '~Common/utils/uuid';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';
import AgendaSectionMenu from '~Meetings/components/details/agenda/AgendaSection/AgendaSectionMenu';
import {
  AgendaCreatePayload,
  AgendaReorderPayload,
  AgendaTopicRealTimeMessageType,
  AgendaUpdatePayload,
} from '~Meetings/const/realTimeMeetingMessages';
import { RecursiveObject } from '~Meetings/stores/useMeetingRealtimeStore';
import RealtimeInlineText from '~Meetings/components/details/agenda/RealtimeCreationCard/RealtimeInlineText';
import { useAgendaSectionCreateTopicRealtimeEvents } from '~Meetings/hooks/utils/useAgendaSectionCreateTopicRealtimeEvents';
import useMeetingRealtime from '~Meetings/hooks/useMeetingRealtime';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { AgendaType } from '@leadr-hr/types';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import RealtimeReorderingIndicator from '../RealtimeReorderingIndicator';
import EmptyState from './EmptyState';
import CollapseButton from './CollapseButton';
import RecurringIcon from './RecurringIcon';

const styles = {
  agendaSection: (isHighlighted: boolean) => css({
    position: 'relative',
    borderRadius: '.3125rem',
    border: '1px solid transparent',
  }, isHighlighted && {
    borderColor: palette.brand.indigo,
  }),
  topLevelSection: (isDraggingOver: boolean, shouldShowRecurrenceSafetyRail?: boolean) => css({
    position: 'relative',
    borderRadius: '0.5rem',
  }, isDraggingOver && !shouldShowRecurrenceSafetyRail && {
    border: `0.125rem dotted ${palette.brand.indigo}`,
  }, isDraggingOver && shouldShowRecurrenceSafetyRail && {
    '#recurrenceOverlay': {
      display: 'flex',
    },
  }),
  recurrenceSafetyRailOverlay: css({
    display: 'none',
    position: 'absolute',
    borderRadius: '0.3125rem',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    padding: '0.5rem',
    backgroundColor: transparentize(0.15, palette.neutrals.gray200),
    color: palette.neutrals.gray700,
    fontWeight: 600,
    zIndex: 2,
    cursor: 'not-allowed',
  }),
  agendaSectionInfo: css({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateAreas: `
      "expand text icons"
      "bottomSection bottomSection bottomSection"
    `,
    position: 'relative',
    alignItems: 'center',
  }),
  agendaTitleContainer: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  textContainer: css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    gridArea: 'text',
  }),
  text: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    fontWeight: 600,
  }),
  collapseButton: css({
    gridArea: 'expand',
  }),
  iconContainer: css({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gridArea: 'icons',
  }),
  bottomSection: css({
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '0.75rem',
    gridArea: 'bottomSection',
    lineHeight: 1,
    justifyContent: 'space-between',
  }, forMobileObject({
    flexDirection: 'column',
  })),
  expandSection: css({
    display: 'flex',
    alignItems: 'center',
  }),
  expandButton: css({
    marginLeft: '-0.375rem',
    paddingTop: 0,
    paddingBottom: 0,
  }),
  talkingPointsText: css({
    color: palette.neutrals.gray700,
    lineHeight: '0.5rem',
  }),
  realTimeReorderingIndicator: css({
    right: '4rem',
  }),
};

export interface ViewProps extends Omit<ComponentProps<'div'>, 'onChange'> {
  text: string,
  agendaItems: AgendaTopic[],
  showAgendaSection: boolean,
  onCollapseClick: () => void,
  huddleId: string,
  showSectionEditor: boolean,
  onCancel: () => void,
  onChange: ({ value }: { value: string }) => void,
  onSave: (props: OnSaveProps) => void,
  isRecurring: boolean,
  initialValue: string,
  showEmptyState: boolean,
  sectionId: string,
  totalCompletedChildren: number,
  agendaItemsToCountLength: number,
  disableInitialAddTopicButton: boolean,
  reorderName: string,
  handleAddTopicClick: () => void,
  realtimeCreatingAgenda?: AgendaCreatePayload[] | RecursiveObject<AgendaCreatePayload[]>,
  shouldShowRecurrenceSafetyRail?: boolean
}

export const View = ({
  text,
  agendaItems,
  showAgendaSection,
  onCollapseClick,
  huddleId,
  showSectionEditor,
  onCancel,
  onChange,
  onSave,
  isRecurring,
  initialValue,
  showEmptyState,
  sectionId,
  totalCompletedChildren,
  agendaItemsToCountLength,
  realtimeCreatingAgenda,
  disableInitialAddTopicButton,
  reorderName,
  shouldShowRecurrenceSafetyRail,
  handleAddTopicClick,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.agendaSection(!!reorderName)}
    {...props}
  >
    {reorderName && (
      <RealtimeReorderingIndicator css={styles.realTimeReorderingIndicator} reorderName={reorderName} />
    )}
    {showSectionEditor && (
      <CreateAgendaItem
        initialValue={initialValue}
        onCancel={onCancel}
        isRecurring={isRecurring}
        onChange={onChange}
        onSave={onSave}
        simplifiedView
        saveButtonText="Save Changes"
        huddleId={huddleId}
      />
    )}
    {!showSectionEditor && (
      <div
        css={styles.topLevelSection(false, shouldShowRecurrenceSafetyRail)}
      >
        {shouldShowRecurrenceSafetyRail && (
          <div
            id="recurrenceOverlay"
            css={styles.recurrenceSafetyRailOverlay}
          >
            <span>
              Can’t add recurring topic into non-recurring section
            </span>
          </div>
        )}

        <div css={styles.agendaSectionInfo}>
          <CollapseButton
            css={styles.collapseButton}
            isSectionExpanded={showAgendaSection}
            onCollapseClick={onCollapseClick}
            disabled={agendaItemsToCountLength === 0}
          />
          <div css={styles.textContainer}>
            <TooltipOnTruncate css={styles.text} text={text} />
          </div>
          <div css={styles.iconContainer}>
            {isRecurring && (
              <RecurringIcon />
            )}
            <AgendaSectionMenu
              sectionId={sectionId}
              huddleId={huddleId}
              text={text}
              numberOfAgendaItems={agendaItems.length}
              isRecurring={isRecurring}
              handleAddTopicClick={handleAddTopicClick}
            />
          </div>
          <div css={styles.bottomSection}>
            {showAgendaSection && showEmptyState && (
              <EmptyState
                onAddTopicClick={handleAddTopicClick}
                disableInitialAddTopicButton={disableInitialAddTopicButton}
              />
            )}
            {!showAgendaSection && (
              <>
                <div css={styles.expandSection}>
                  <LeadrButton
                    css={styles.expandButton}
                    onClick={onCollapseClick}
                    variant="text"
                    textButtonColor={palette.brand.indigo}
                    data-test-id="agendaSectionExpandButton"
                  >
                    Expand
                  </LeadrButton>
                  <div css={styles.talkingPointsText}>{`${totalCompletedChildren}/${agendaItemsToCountLength} topics`}</div>
                </div>
                <RealtimeInlineText
                  // @ts-expect-error ToDo: Fix
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  peopleCreatingItemsInRealTime={realtimeCreatingAgenda?.data}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )}
  </div>
);

export interface AgendaSectionProps extends Omit<ComponentProps<'div'>, 'onChange'> {
  agendaSection: AgendaSectionType,
  huddleId: string,
  hasMaxTopics: boolean,
  realtimeCreatingAgenda?: AgendaCreatePayload[] | RecursiveObject<AgendaCreatePayload[]>,
  realtimeUpdatingAgenda?: AgendaUpdatePayload[] | RecursiveObject<AgendaUpdatePayload[]>,
  realtimeReorderingAgenda?: AgendaReorderPayload[] | RecursiveObject<AgendaReorderPayload[]>,
}

const AgendaSection = ({
  agendaSection,
  huddleId,
  realtimeCreatingAgenda,
  realtimeReorderingAgenda,
  hasMaxTopics,
  ...props
}: AgendaSectionProps): JSX.Element => {
  const sectionId = agendaSection.id;
  const organizationId = getOrganizationId();
  const agendaSectionDraftKey = [organizationId!, huddleId, 'agendaSection', sectionId];
  const { draft, setDraft } = useDraft(agendaSectionDraftKey);
  const [sectionEventId, setSectionEventId] = useState('');

  const {
    expandAgendaSection,
    collapseAgendaSection,
    getIsAgendaSectionExpanded,
  } = useShowAgendaSection((state) => ({
    expandAgendaSection: state.expandAgendaSection,
    collapseAgendaSection: state.collapseAgendaSection,
    getIsAgendaSectionExpanded: state.getIsAgendaSectionExpanded,
  }));

  const {
    removeAgendaSection,
    setRealtimeEventId,
  } = useAgendaSectionCreateTopicRealtimeEvents((state) => ({
    setRealtimeEventId: state.setRealtimeEventId,
    removeAgendaSection: state.removeAgendaSection,
  }));

  // Remove the section from the store when the component unmounts, so we don't have lingering realtime events
  useEffect(() => () => removeAgendaSection(sectionId), [removeAgendaSection, sectionId]);

  const showAgendaSection = getIsAgendaSectionExpanded(sectionId);

  const { sendMessage } = useMeetingRealtime({ huddleId });
  const userId = getUserId();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { user } = useUserProfile(userId!);

  const isOptimisticSection = useMemo(() => isLocalId(agendaSection.id), [agendaSection.id]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const reorderName = useMemo(() => {
    // @ts-expect-error ToDo: Fix
    if (realtimeReorderingAgenda?.data) {
      // @ts-expect-error ToDo: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (realtimeReorderingAgenda.data.length > 1) {
        return 'Several People';
      }

      // @ts-expect-error ToDo: Error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-template-expressions
      const names = realtimeReorderingAgenda?.data?.map((payload) => (`${payload.updater.firstName} ${payload.updater.lastName}`));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return names.join(', ');
    }

    return '';
  }, [
    realtimeReorderingAgenda,
  ]);
  const {
    showItemEditor,
    hideItemEditor,
    getIsItemEditorVisible,
  } = useShowItemEditor((state) => ({
    showItemEditor: state.showItemEditor,
    hideItemEditor: state.hideItemEditor,
    getIsItemEditorVisible: state.getIsItemEditorVisible,
  }));

  const newAgendaTopicEditorKey = `${sectionId}-create`;

  const showNewAgendaTopicEditor = getIsItemEditorVisible(newAgendaTopicEditorKey);
  const showSectionEditor = getIsItemEditorVisible(sectionId);

  const { mutate: doEdit } = useEditAgendaSection({
    huddleId,
    sectionId,
    draftKey: agendaSectionDraftKey,
  });

  const toggleCollapse = useCallback(() => {
    if (showAgendaSection) {
      collapseAgendaSection(sectionId);
    } else {
      expandAgendaSection(sectionId);
    }
  }, [collapseAgendaSection, expandAgendaSection, sectionId, showAgendaSection]);

  const onCancel = useCallback(() => {
    sendMessage(AgendaTopicRealTimeMessageType.CreateCanceled, {
      eventId: sectionEventId,
      parentIds: [
        sectionId,
      ],
    });

    setSectionEventId('');
    hideItemEditor(sectionId);
  }, [hideItemEditor, sectionEventId, sectionId, sendMessage]);

  const onChange = useCallback(({ value }: { value: string }) => {
    setDraft({
      ...draft,
      value,
    });
  }, [
    draft,
    setDraft,
  ]);

  const onSave = useCallback(({
    text,
    isRecurring,
  }: OnSaveProps) => {
    doEdit({
      sectionId,
      data: {
        section: {
          text,
          isRecurring,
        },
        huddleId,
        eventId: sectionEventId,
      },
    });
  }, [
    doEdit,
    sectionId,
    huddleId,
    sectionEventId,
  ]);

  const handleAddTopicClick = useCallback(() => {
    const newEventId = getLocalId();
    setRealtimeEventId(sectionId, newEventId);

    expandAgendaSection(sectionId);
    showItemEditor(newAgendaTopicEditorKey);

    sendMessage(AgendaTopicRealTimeMessageType.Create, {
      eventId: newEventId,
      creator: {
        // Current user's profile should always be available
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        orgUserId: user!.organizationUserId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        firstName: user!.firstName,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        lastName: user!.lastName,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        profileImageUrl: user!.profileImageUrl,
      },
      parentIds: [
        sectionId,
      ],
      type: AgendaType.AgendaTopic,
    });
  }, [setRealtimeEventId, sectionId, expandAgendaSection, showItemEditor, newAgendaTopicEditorKey, sendMessage, user]);

  const agendaItemsToCountLength = useMemo(() => agendaSection.children.filter((agendaItem) => !agendaItem.isInvisible).length, [agendaSection.children]);

  const showEmptyState = useMemo(() => (
    agendaItemsToCountLength === 0 && !showNewAgendaTopicEditor && !hasMaxTopics
  ), [agendaItemsToCountLength, showNewAgendaTopicEditor, hasMaxTopics]);
  const { isRecurring } = agendaSection;

  const hookProps = {
    text: agendaSection.text,
    agendaItems: agendaSection.children,
    showAgendaSection,
    onCollapseClick: toggleCollapse,
    huddleId,
    showSectionEditor,
    onCancel,
    onChange,
    onSave,
    isRecurring,
    initialValue: draft.value !== undefined ? draft.value as string : agendaSection.text,
    showEmptyState,
    sectionId,
    totalCompletedChildren: agendaSection.totalCompletedChildren,
    agendaItemsToCountLength,
    realtimeCreatingAgenda,
    disableInitialAddTopicButton: isOptimisticSection,
    reorderName: reorderName as string,
    handleAddTopicClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default AgendaSection;
