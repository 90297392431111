import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  learningCompletedCount: css({
    color: palette.neutrals.gray600,
    fontSize: '0.75rem',
  }),
};

interface LearningCompletedCountProps extends ComponentProps<'div'> {
  numberOfUsersCompleted: number,
  numberOfUsersAssigned: number,
}

// TODO: Update all instances of this component to use the common CompltedCountText
const LearningCompletedCount = ({
  numberOfUsersCompleted,
  numberOfUsersAssigned,
  ...props
}: LearningCompletedCountProps): JSX.Element => (
  <div css={styles.learningCompletedCount} data-test-id="learningCompletedCount" {...props}>
    {`${numberOfUsersCompleted} of ${numberOfUsersAssigned} Completed`}
  </div>
);

export default LearningCompletedCount;
