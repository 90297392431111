import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';

import {
  BI_WEEKLY_MEETING,
  MONTHLY_LAST_MEETING,
  MONTHLY_MEETING,
  ONE_TIME_MEETING,
  WEEKLY_MEETING,
} from '~Common/const/constraints';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';

const useFrequencyString = ({
  frequency,
  daysOfWeek,
  startTimeInMillis,
  includeTime = true,
} = {}) => {
  const { timezone } = useTimezone();

  const weekDayNumberCalculator = useCallback((mDate, weekNumber) => {
    if (mDate.month() !== mDate.clone().subtract(1, 'week').month()) {
      return weekNumber;
    }
    return weekDayNumberCalculator(mDate.clone().subtract(1, 'week'), weekNumber + 1);
  }, []);

  const getDateInfo = useCallback((value) => {
    const mDate = moment.tz(value, timezone);
    const weekDayNumber = weekDayNumberCalculator(mDate.clone(), 1);
    const isLastWeekDay = mDate.month() !== mDate.clone().add(1, 'week').month();
    const weekDay = mDate.format('dddd');
    const weekDayShort = mDate.format('ddd');

    return {
      weekDayNumber,
      isLastWeekDay,
      weekDay,
      weekDayShort,
    };
  }, [weekDayNumberCalculator, timezone]);

  const getOrdinalSuffix = useCallback((i) => {
    const j = i % 10;
    const
      k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }, []);

  const getOneTimeMeetingFrequencyString = useCallback(() => 'One time', []);

  // This string can get long enough to wrap, so always use the shortened week day name
  const getMonthlyLastFrequencyString = useCallback(({ weekDayShort }) => `Monthly on the last ${weekDayShort}`, []);

  const getMonthlyFrequencyString = useCallback(({
    weekDayNumber,
    weekDay,
    weekDayShort,
  }) => {
    if (weekDayNumber <= 4) {
      return `Monthly on ${getOrdinalSuffix(weekDayNumber)} ${weekDay}`;
    }

    return getMonthlyLastFrequencyString({ weekDayShort });
  }, [getOrdinalSuffix, getMonthlyLastFrequencyString]);

  const getBiweeklyFrequencyString = useCallback(({
    startTime,
    numberOfWeekDays,
    repeatingDays,
  }) => {
    // It's possible there are a few meetings floating around with a weekly frequency but no daysOfWeek due to a previous fixed bug.
    // In that case we infer the day based on the start time.
    if (numberOfWeekDays === 0) {
      const dayString = moment.tz(startTime, timezone).format('dddd');

      return `Every ${dayString}`;
    }

    if (numberOfWeekDays === 7) {
      return 'Daily every other week';
    }

    return `Every other ${repeatingDays}`;
  }, [timezone]);

  const getWeeklyFrequencyString = useCallback(({
    startTime,
    numberOfWeekDays,
    repeatingDays,
  }) => {
    // It's possible there are a few meetings floating around with a weekly frequency but no daysOfWeek due to a previous fixed bug.
    // In that case we infer the day based on the start time.
    if (numberOfWeekDays === 0) {
      const dayString = moment.tz(startTime, timezone).format('dddd');

      return `Every ${dayString}`;
    }

    if (numberOfWeekDays === 7) {
      return 'Daily';
    }

    return `Every ${repeatingDays}`;
  }, [timezone]);

  return useMemo(() => {
    let frequencyString;
    const { weekDayNumber, weekDay, weekDayShort } = getDateInfo(startTimeInMillis);
    const timeString = moment.tz(startTimeInMillis, timezone).format('h:mm A');
    const days = daysOfWeek ?? [];
    const shiftedDays = days.map((d) => (d + 1) % 7);
    const shiftedSortedDays = shiftedDays.sort();

    const format = days?.length > 4 ? 'weekdaysMin' : 'weekdaysShort';

    const repeatingDaysString = shiftedSortedDays.map((day) => moment[format](day)).join(', ');

    // TODO: Remove this conditional once we are properly contextualizing daysOfWeek as it should no longer be needed.
    const repeatingDays = days.length > 1 ? repeatingDaysString : weekDay;

    switch (frequency) {
      case WEEKLY_MEETING:
        frequencyString = getWeeklyFrequencyString({
          startTime: startTimeInMillis,
          numberOfWeekDays: days.length,
          repeatingDays,
        });
        break;
      case BI_WEEKLY_MEETING:
        frequencyString = getBiweeklyFrequencyString({
          startTime: startTimeInMillis,
          numberOfWeekDays: days.length,
          repeatingDays,
        });
        break;
      case MONTHLY_MEETING:
        frequencyString = getMonthlyFrequencyString({
          weekDayNumber,
          weekDay,
          weekDayShort,
        });
        break;
      case MONTHLY_LAST_MEETING:
        frequencyString = getMonthlyLastFrequencyString({ weekDayShort });
        break;
      case ONE_TIME_MEETING:
        frequencyString = getOneTimeMeetingFrequencyString();
        break;
      default:
        frequencyString = 'Frequency unknown';
        break;
    }

    if (includeTime) {
      frequencyString = `${frequencyString} @ ${timeString}`;
    }

    return frequencyString;
  }, [
    timezone,
    daysOfWeek,
    frequency,
    startTimeInMillis,
    getDateInfo,
    getBiweeklyFrequencyString,
    getMonthlyFrequencyString,
    getMonthlyLastFrequencyString,
    getOneTimeMeetingFrequencyString,
    getWeeklyFrequencyString,
    includeTime,
  ]);
};

export {
  useFrequencyString,
};
