import { MeetingFrequency, MeetingTitle, MeetingTypeEnum } from './meetingsInterfaces';

/**
 * @deprecated Use the typescript interfaces in meetingsInterfaces.ts instead
 */
export const MEETING_TYPES = {
  COACHING: 'COACHING',
  MEETING: 'MEETING',
};

/**
 * @deprecated Use the typescript interfaces in meetingsInterfaces.ts instead
 */
export const MEETING_FACTORY_TYPES = {
  COACHING: 'COACHING',
  TEAM_MEETING: 'TEAM_MEETING',
};

/**
 * @deprecated Use the typescript interfaces in meetingsInterfaces.ts instead
 */
export const MEETING_TITLES = {
  ONE_ON_ONE_MEETING: '1:1 Meeting',
  ONE_TIME_ONE_ON_ONE: 'One-time 1:1 Meeting',
  TEAM_MEETING: 'Team Meeting',
};

export const ONE_ON_ONE_DEFAULT = {
  defaultFrequency: MeetingFrequency.WEEKLY,
  limit: 1,
  type: MeetingTypeEnum.COACHING,
  title: MeetingTitle.ONE_ON_ONE_MEETING,
};

export const PRIVATE_ONE_TIME_MEETING_DEFAULTS = {
  defaultFrequency: MeetingFrequency.ONE_TIME,
  limit: 1,
  type: MeetingTypeEnum.COACHING,
  title: MeetingTitle.ONE_TIME_ONE_ON_ONE,
};

export const TEAM_MEETING_DEFAULTS = {
  defaultFrequency: MeetingFrequency.WEEKLY,
  type: MeetingTypeEnum.MEETING,
  title: MeetingTitle.TEAM_MEETING,
};

// This option was done away with in LW-4884 but I am leaving it here in case we go back to it in the future.
// export const ONE_TIME_MEETING_DEFAULTS = {
//   defaultFrequency: ONE_TIME_MEETING,
//   type: MEETING_TYPES.MEETING,
//   title: 'One-Time Meeting',
// };
