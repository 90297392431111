import { AttachmentErrorType } from '~Meetings/components/details/agenda/AttachmentsModal/attachmentError';

export const attachmentErrorTypeToString = (errorType: AttachmentErrorType): string => {
  let text: string;

  switch (errorType) {
    case AttachmentErrorType.FileName:
      text = 'File name too long (Max File name is 255 characters):';
      break;
    case AttachmentErrorType.FileType:
      text = 'File type not supported:';
      break;
    case AttachmentErrorType.MaxAttachments:
      text = 'Max number of attached files is 10';
      break;
    case AttachmentErrorType.MaxFileSize:
      text = 'Files too large (Max File size is 20mb):';
      break;
    default:
      text = '';
      break;
  }

  return text;
};
