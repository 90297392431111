import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { queryClient } from '~Common/const/queryClient';
import { AgendaTemplates } from '@leadr-hr/types';
import { getLocalId } from '~Common/utils/uuid';
import { produce } from 'immer';
import { toast } from '~Common/components/Toasts';
import { getIsIndividualQuestionsTemplate } from '~Meetings/components/topic-suggestions/utils/getIsIndividualTopicTemplate';
import {
  EditIndividualAgendaTemplatePayload,
  EditIndividualAgendaTemplateResponse,
  MeetingTemplateType,
  EditIndividualAgendaTemplateDetails,
  EditIndividualAgendaSectionTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { meetingTopicTemplatesQueryKeys } from '../queryKeys';
import { GetMeetingTemplatesResponse } from '../useGetCustomMeetingTemplates';

const editIndividualTopic = ({
  type,
  child,
}: EditIndividualAgendaTemplatePayload): Promise<HttpCallReturn<EditIndividualAgendaTemplateResponse>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates/individual`;

  return patchApi<EditIndividualAgendaTemplateResponse>(URL, { type, child });
};

function isChildAgendaSection(item: EditIndividualAgendaTemplateDetails): item is EditIndividualAgendaSectionTemplateDetails {
  return (item as EditIndividualAgendaSectionTemplateDetails).children !== undefined;
}

interface UseEditIndividualTopicParams {
  onMutateCallback?: () => void,
  meetingTemplateType: MeetingTemplateType,
}

export const useEditIndividualTopic = ({
  onMutateCallback,
  meetingTemplateType,
}: UseEditIndividualTopicParams): UseMutationResult<
    HttpCallReturn<EditIndividualAgendaTemplateResponse>,
    unknown,
    EditIndividualAgendaTemplatePayload
  > => {
  const orgId = getOrganizationId() ?? '';

  const queryKeyForList = meetingTopicTemplatesQueryKeys.customTemplatesList({
    orgId,
    meetingTemplateType,
  });

  return useMutation({
    mutationFn: editIndividualTopic,
    onMutate: async ({ child }) => {
      onMutateCallback?.();

      const previousMeetingTemplates = queryClient.getQueryData(queryKeyForList);
      await queryClient.cancelQueries({ queryKey: queryKeyForList });

      queryClient.setQueryData<HttpCallReturn<GetMeetingTemplatesResponse>>(queryKeyForList, (originalData) => {
        if (originalData) {
          if (isChildAgendaSection(child)) {
            const newIndividualTopic = {
              ...child,
              permissions: [] as AgendaTemplates.AgendaTemplatePermission[],
              children: child.children.map((sectionChild) => ({
                ...sectionChild,
                id: sectionChild.id || getLocalId(),
                permissions: [] as AgendaTemplates.AgendaTemplatePermission[],
              })),
            };

            return produce(originalData, (draft) => {
              const individualQuestionsTemplate = draft.response.meetingTemplates.find(
                (meetingTemplate) => getIsIndividualQuestionsTemplate(meetingTemplate),
              );
              const editedItemIndex = individualQuestionsTemplate?.children.findIndex((item) => item.id === child.id);
              if (editedItemIndex === -1) {
                individualQuestionsTemplate?.children.splice(editedItemIndex, 1, newIndividualTopic);
              }
            });
          }

          const newIndividualTopic = {
            ...child,
            permissions: [] as AgendaTemplates.AgendaTemplatePermission[],
          };

          return produce(originalData, (draft) => {
            const individualQuestionsTemplate = draft.response.meetingTemplates.find(
              (meetingTemplate) => getIsIndividualQuestionsTemplate(meetingTemplate),
            );
            const editedItemIndex = individualQuestionsTemplate?.children.findIndex((item) => item.id === child.id);
            if (editedItemIndex && editedItemIndex !== -1) {
              individualQuestionsTemplate?.children.splice(editedItemIndex, 1, newIndividualTopic);
            }
          });
        }

        return originalData;
      });

      return { previousMeetingTemplates };
    },
    onError: (_, __, snapshot) => {
      toast.error('There was an error editing the topic. Please try again.', {
        autoClose: 1500,
      });

      queryClient.setQueryData(queryKeyForList, snapshot?.previousMeetingTemplates);
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: meetingTopicTemplatesQueryKeys.customTemplatesLists(getOrganizationId() ?? '') });
    },
  });
};
