import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import DrawerInput from '~Common/V3/components/DrawerInput';
import DrawerTextArea from '~Common/components/Inputs/DrawerTextArea';
import { palette } from '~Common/styles/colors';
import emptyParticipants from '~Assets/images/empty-views/emptyParticipants.png';
import { noop } from '~Deprecated/utils';
import { USER_GROUP_SHAPE, DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import DeleteConfirmationPopover, { useDeleteConfirmationPopover } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import AttendeeList from '~Common/V3/components/Attendees/AttendeesList';
import useSelectedAttendees from '~Deprecated/hooks/attendees/useSelectedAttendees';
import Button from '../../../../common/V3/components/Button';

const styles = {
  titleInput: css`
    margin-bottom: .5rem;
  `,
  descriptionInput: css`
    margin-bottom: 1.125rem;
  `,
  participantsHeader: css`
    color: ${palette.neutrals.gray500};
    font-size: .625rem;
  `,
  participantsContainer: css`
    margin-top: .625rem;
    margin-bottom: 1.875rem;
  `,
  emptyStateImage: css`
    width: 100%;
  `,
  emptyStateText: css`
    color: ${palette.brand.indigo};
    text-align: center;
    margin: 0 15%;
    margin-bottom: 1.75rem;
  `,
  buttonsContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  saveGroupButton: css`
    margin-right: .625rem;
  `,
  attendeeItem: css`
    &:not(:last-child) {
      margin-bottom: .625rem;
    }
  `,

  containerSticky: css`
    position: sticky;
    bottom: 0px;
    background: white;
    padding: 0.625rem;
  `,
};

const View = ({
  initialTitle,
  initialDescription,
  selectedIds,
  onDeleteClick,
  closeDrawerClick,
  openConfirmationPopover,
  closeConfirmationPopover,
  attendeeClick,
  isOpen,
  popoverId,
  anchorEl,
  currentAttendees,
  isAttendeeSelected,
  drawerState,
  onAttendeeSelect,
  ...props
}) => (
  <div
    css={styles.container}
    {...props}
  >
    <DrawerInput
      name="title"
      css={styles.titleInput}
      initialValue={initialTitle}
      label="Title"
      required
    />
    <DrawerTextArea
      name="description"
      css={styles.descriptionInput}
      initialValue={initialDescription}
      label="Description"
    />
    <div css={styles.participantsHeader}>PARTICIPANTS</div>
    <div css={styles.participantsContainer}>
      {selectedIds.length === 0 && (
      <div>
        <img src={emptyParticipants} alt="You have no participants." css={styles.emptyStateImage} />
        <div css={styles.emptyStateText}>You have not added any group members yet. Please select the group members you would like to add.</div>
      </div>
      )}
      {selectedIds.length > 0 && (
        <div>
          <AttendeeList
            selectedAttendees={currentAttendees}
            isAttendeeSelected={isAttendeeSelected}
            isLoading={false}
            attendees={drawerState.selectedAttendees}
            onClick={onAttendeeSelect}
          />
        </div>
      )}
    </div>
    <div css={styles.containerSticky}>
      <div css={styles.buttonsContainer}>
        <div>
          <Button
            css={styles.saveGroupButton}
            color="secondary"
            type="submit"
            renderContents={() => (
              <div>Save Group</div>
            )}
          />
          <Button
            color="light"
            variant="outline"
            onClick={closeDrawerClick}
            renderContents={() => (
              <div>Cancel</div>
            )}
          />
        </div>
        <Button
          color="danger"
          variant="outline"
          onClick={openConfirmationPopover}
          renderContents={() => (
            <div>Delete</div>
          )}
        />
        <DeleteConfirmationPopover
          closeConfirmationPopover={closeConfirmationPopover}
          open={isOpen}
          popoverId={popoverId}
          anchorEl={anchorEl}
          renderConfirmationButtons={({
            informationStyles,
            optionStyles,
            popoverButtonStyles,
          }) => (
            <DeleteConfirmationButtons
              informationStyles={informationStyles}
              optionStyles={optionStyles}
              popoverButtonStyles={popoverButtonStyles}
              onDelete={onDeleteClick}
            />
          )}
        />
      </div>
    </div>
  </div>
);

View.propTypes = {
  initialTitle: PropTypes.string,
  initialDescription: PropTypes.string,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  onDeleteClick: PropTypes.func,
  closeDrawerClick: PropTypes.func,
  openConfirmationPopover: PropTypes.func.isRequired,
  closeConfirmationPopover: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  popoverId: PropTypes.string,
  anchorEl: PropTypes.instanceOf(Element),
  attendeeClick: PropTypes.func.isRequired,
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
  isAttendeeSelected: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  onAttendeeSelect: PropTypes.func.isRequired,
};

View.defaultProps = {
  initialTitle: '',
  initialDescription: '',
  selectedIds: [],
  onDeleteClick: noop,
  closeDrawerClick: noop,
  isOpen: false,
  popoverId: '',
  anchorEl: null,
  currentAttendees: [],
};

const Body = ({
  group, selectedIds, setDrawerState, drawerState, ...props
}) => {
  const [initialTitle] = useState(group.name);
  const [initialDescription] = useState(group.description ?? '');

  const initialGroupIds = group.members?.map((member) => member.orgUserId);

  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('userGroupsDeleteConfirmationPopover');

  const {
    isAttendeeSelected,
    onAttendeeSelect,
  } = useSelectedAttendees({ drawerState, setDrawerState });

  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      selectedAttendees: initialGroupIds || [],
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hookProps = {
    initialTitle,
    initialDescription,
    selectedIds,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
    anchorEl,
    drawerState,
    isAttendeeSelected,
    onAttendeeSelect,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

Body.propTypes = {
  group: USER_GROUP_SHAPE,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  setDrawerState: PropTypes.func,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
};

Body.defaultProps = {
  group: {},
  selectedIds: [],
  setDrawerState: noop,
};

export { View };
export default Body;
