// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AgendaTemplates } from '@leadr-hr/types';
import { Draggable } from 'react-beautiful-dnd';
import { useDraggableInPortal } from '~Meetings/hooks/utils/useDraggableInPortal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { css } from '@emotion/react';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { MoveTemplateAgendaItemContext } from '~Meetings/components/topic-suggestions/contexts/MoveTemplateAgendaItemContext';
import MoveAgendaItemButtons, { MoveAgendaItemButtonsProps } from '~Meetings/components/details/agenda/MoveAgendaItemButtons';
import { MoveDirection } from '~Meetings/hooks/utils/useMoveTemplateAgendaItem';
import { useContext } from 'react';
import {
  CreateAgendaTopicTemplateDetails,
  EditAgendaTopicTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import AgendaTopic from '.';

/*
  WARNING: Tread carefully in this file. I Had to disable a lot of TS stuff.
  The drag and drop library we are using has a visual bug that can be found here: https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-1010053365
  I couldnt figure out how to type the hook that I took from that Github issue: useDraggableInPortal
*/

const styles = {
  dragHandle: css({
    marginRight: '1rem',
  }),
};

interface ViewProps extends MoveAgendaItemButtonsProps {
  agendaTopic: CreateAgendaTopicTemplateDetails | EditAgendaTopicTemplateDetails,
  topicLocalId: string,
  index: number,
  isMobile: boolean,
  sectionLocalId?: string,
}

const View = ({
  renderDraggable,
  topicLocalId,
  index,
  agendaTopic,
  sectionLocalId,
  isMobile,
  shouldDisableMoveDown,
  shouldDisableMoveUp,
  handleMoveAgendaTopicDown,
  handleMoveAgendaTopicUp,
  ...props
}: ViewProps): JSX.Element => (
  <Draggable
    draggableId={`agendaTopic${topicLocalId}`}
    index={index}
    {...props}
  >
    { /* eslint-disable-next-line @typescript-eslint/no-unsafe-call */ }
    {renderDraggable((dragProvided) => (
      <div
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        ref={dragProvided.innerRef}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        {...dragProvided.draggableProps}
      >
        <AgendaTopic
          agendaTopic={agendaTopic}
          topicLocalId={topicLocalId}
          sectionLocalId={sectionLocalId}
          renderDragHandle={() => (
            <>
              {isMobile && (
                <MoveAgendaItemButtons
                  css={styles.dragHandle}
                  shouldDisableMoveDown={shouldDisableMoveDown}
                  shouldDisableMoveUp={shouldDisableMoveUp}
                  handleMoveAgendaTopicDown={handleMoveAgendaTopicDown}
                  handleMoveAgendaTopicUp={handleMoveAgendaTopicUp}
                />
              )}
              {!isMobile && (
                <div
                  css={styles.dragHandle}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  {...dragProvided.dragHandleProps}
                >
                  <FontAwesomeIcon
                    icon={faGripDotsVertical}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>
    ))}
  </Draggable>
);

type DraggableAgendaTopicProps = Omit<
  ViewProps, 'renderDraggable' | 'isMobile' | 'shouldDisableMoveDown' | 'shouldDisableMoveUp' | 'handleMoveAgendaTopicDown' | 'handleMoveAgendaTopicUp'
>;

const DraggableAgendaTopic = ({
  topicLocalId,
  sectionLocalId,
  ...props
}: DraggableAgendaTopicProps): JSX.Element => {
  const renderDraggable = useDraggableInPortal();
  const isMobile = useIsMobileQuery();

  const {
    disableMoveDown,
    disableMoveUp,
    moveTemplateAgendaItemOnePosition,
  } = useContext(MoveTemplateAgendaItemContext);

  const shouldDisableMoveDown = disableMoveDown({
    agendaType: AgendaTemplates.AgendaTemplateType.AgendaTopic,
    localId: topicLocalId,
    sectionLocalId,
  });

  const shouldDisableMoveUp = disableMoveUp({
    agendaType: AgendaTemplates.AgendaTemplateType.AgendaTopic,
    localId: topicLocalId,
    sectionLocalId,
  });

  const handleMoveAgendaTopicDown = (): void => {
    moveTemplateAgendaItemOnePosition({
      moveDirection: MoveDirection.Down,
      agendaType: AgendaTemplates.AgendaTemplateType.AgendaTopic,
      localId: topicLocalId,
      sectionLocalId,
    });
  };

  const handleMoveAgendaTopicUp = (): void => {
    moveTemplateAgendaItemOnePosition({
      moveDirection: MoveDirection.Up,
      agendaType: AgendaTemplates.AgendaTemplateType.AgendaTopic,
      localId: topicLocalId,
      sectionLocalId,
    });
  };

  const hookProps = {
    renderDraggable,
    isMobile,
    topicLocalId,
    sectionLocalId,
    shouldDisableMoveDown,
    shouldDisableMoveUp,
    handleMoveAgendaTopicDown,
    handleMoveAgendaTopicUp,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default DraggableAgendaTopic;
