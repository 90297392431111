import React, {
  ComponentProps, ReactElement, ReactNode, useMemo,
} from 'react';
import { useSwitch, UseSwitchInputSlotProps, UseSwitchParameters } from '@mui/base/SwitchUnstyled';
import { css, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { palette } from '~Common/styles/colors';
import { CommonComponentSharedProps } from '~Common/const/interfaces';
import {
  SwitchCommonStyles, getSwitchStyles, SwitchSizeStyles, SwitchStyleProps,
} from './styles';

interface ViewProps extends ComponentProps<'span'> {
  checked: boolean,
  disabled: boolean,
  switchInputProps: UseSwitchInputSlotProps | ComponentProps<'input'>,
  switchCommonStyles: SwitchCommonStyles,
  switchSizeStyles: SwitchSizeStyles,
  showCheckedLabels?: boolean,
}

const View = ({
  checked,
  disabled,
  switchInputProps,
  switchCommonStyles,
  switchSizeStyles,
  showCheckedLabels = true,
  className,
}: ViewProps): JSX.Element => (
  <span className={className} css={[switchCommonStyles.switchRoot, switchSizeStyles.switchRoot]}>
    <span css={[switchCommonStyles.switchTrack(checked, disabled), switchSizeStyles.switchTrack]}>
      {showCheckedLabels && checked && (
        <span css={[switchCommonStyles.text(disabled), switchSizeStyles.checkedText]}>On</span>
      )}
      <span css={[switchCommonStyles.switchThumb, switchSizeStyles.switchThumb(checked)]} />
      {showCheckedLabels && !checked && (
        <span css={[switchCommonStyles.text(disabled), switchSizeStyles.uncheckedText]}>Off</span>
      )}
    </span>
    <input css={[switchCommonStyles.switchInput(disabled)]} {...switchInputProps} />
  </span>
);

interface SwitchProps extends SwitchStyleProps, UseSwitchParameters {
  showCheckedLabels?: boolean,
  inputProps?: ComponentProps<'input'>,
}

/**
  @param inputProps - Props to pass to the input element, like name, be careful that you don't pass things that the useSwitch hook needs
*/
const Switch = ({
  size,
  color,
  inputProps,
  ...props
}: SwitchProps): JSX.Element => {
  // Disabled because MUI doesnt know what its talking about
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const {
    checked,
    disabled,
    getInputProps,
  } = useSwitch(props);

  const switchInputProps = { ...inputProps, ...getInputProps() };

  const { switchCommonStyles, switchSizeStyles } = useMemo(() => getSwitchStyles({
    size,
    color,
  }), [color, size]);

  const hookProps = {
    switchInputProps,
    checked,
    disabled,
    switchCommonStyles,
    switchSizeStyles,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

interface LeadrSwitchProps extends ComponentProps<'div'>, CommonComponentSharedProps {
  children: ReactNode,
}

/**
 * Components: {@link LeadrSwitch.Label Label}, {@link Switch}
 * @example
 * Using built in label:
 * <LeadrSwitch>
 *   <LeadrSwitch.Label label="Example Label">
 *    <LeadrSwitch.Switch />
 *   <LeadrSwitch.Label>
 * </LeadrSwitch>
 * @example
 * Just the switch:
 * <LeadrSwitch>
 *  <LeadrSwitch.Switch />
 * </LeadrSwitch>
 */
const LeadrSwitch = ({
  children,
  'data-test-id': dataTestId,
  ...props
}: LeadrSwitchProps): JSX.Element => (
  <div data-test-id={`${dataTestId}Switch`} {...props}>
    {children}
  </div>
);

const labelStyles = {
  formControlLabel: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    gap: '1.25rem',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  }),
  switchInput: css({
    flexShrink: 0,
  }),
};

interface LabelProps extends Omit<FormControlLabelProps, 'control'> {
  children: ReactElement,
  label: string,
}

/**
 * @param  {ReactElement} children - Only use the {@link Switch} component here with no other children
 */
LeadrSwitch.Label = ({ children, ...props }: LabelProps): JSX.Element => (
  <FormControlLabel
    css={labelStyles.formControlLabel}
    control={(
      <>
        {React.Children.map(children, (child) => React.cloneElement(child, { css: labelStyles.switchInput }))}
      </>
    )}
    {...props}
  />
);

LeadrSwitch.Switch = Switch;

export { View };
export default LeadrSwitch;
