import { css } from '@emotion/react';
import { FormControlLabel } from '@mui/material';

import { palette } from '~Common/styles/colors';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { OrgSettings } from '~Common/const/Organization';
import CheckboxLabel from '../CheckboxLabel';

const styles = {
  hidePersonalityTypeModels: css({}),
  descriptionText: css({
    color: palette.neutrals.gray700,
  }),
  additionalDescriptionText: css({
    marginTop: '1rem',
  }),
  checkboxesContainer: css({
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  }),
};

interface ViewProps {
  enableEnneagram: boolean,
  enableBriggsMyers: boolean,
  enableDisc: boolean,
  enableStrengthFinder: boolean,
  enableWorkingGenius: boolean,
}

const View = ({
  enableEnneagram,
  enableBriggsMyers,
  enableDisc,
  enableStrengthFinder,
  enableWorkingGenius,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <SettingsSectionHeader
      renderContents={() => (
        <>Hide Personality Type Models</>
      )}
    />
    <p css={styles.descriptionText}>
      You can disable the support for Personality Type Models below,
      which will hide it from profiles for all members of your Organization.
    </p>

    <div css={styles.checkboxesContainer}>
      <FormControlLabel
        control={(
          <CustomCheckbox
            name="enableEnneagram"
            defaultChecked={enableEnneagram}
          />
        )}
        label={(
          <CheckboxLabel
            renderText={(checkboxLabelTextStyles) => (
              <div css={checkboxLabelTextStyles}>Enneagram</div>
            )}
          />
        )}
      />
      <FormControlLabel
        control={(
          <CustomCheckbox
            name="enableBriggsMyers"
            defaultChecked={enableBriggsMyers}
          />
        )}
        label={(
          <CheckboxLabel
            renderText={(checkboxLabelTextStyles) => (
              <div css={checkboxLabelTextStyles}>Myers Briggs</div>
            )}
          />
        )}
      />
      <FormControlLabel
        control={(
          <CustomCheckbox
            name="enableDisc"
            defaultChecked={enableDisc}
          />
        )}
        label={(
          <CheckboxLabel
            renderText={(checkboxLabelTextStyles) => (
              <div css={checkboxLabelTextStyles}>DiSC</div>
            )}
          />
        )}
      />
      <FormControlLabel
        control={(
          <CustomCheckbox
            name="enableStrengthFinder"
            defaultChecked={enableStrengthFinder}
          />
        )}
        label={(
          <CheckboxLabel
            renderText={(checkboxLabelTextStyles) => (
              <div css={checkboxLabelTextStyles}>StrengthsFinder</div>
            )}
          />
        )}
      />
      <FormControlLabel
        control={(
          <CustomCheckbox
            name="enableWorkingGenius"
            defaultChecked={enableWorkingGenius}
          />
        )}
        label={(
          <CheckboxLabel
            renderText={(checkboxLabelTextStyles) => (
              <>
                <div css={checkboxLabelTextStyles}>Working Genius</div>
              </>
            )}
          />
        )}
      />
    </div>
  </div>
);

interface HidePersonalityTypeModelsProps {
  orgSettings: OrgSettings,
}

const HidePersonalityTypeModels = ({
  orgSettings,
  ...props
}: HidePersonalityTypeModelsProps): JSX.Element => {
  const {
    enableEnneagram,
    enableBriggsMyers,
    enableDisc,
    enableStrengthFinder,
    enableWorkingGenius,
  } = orgSettings;

  const hookProps = {
    enableEnneagram,
    enableBriggsMyers,
    enableDisc,
    enableStrengthFinder,
    enableWorkingGenius,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default HidePersonalityTypeModels;
