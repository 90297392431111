import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

export const autoCompleteStyles = {
  autocomplete: css({
    '.MuiFormControl-root': {
      border: '1px solid transparent',
      borderRadius: '0.5rem',
    },
    fieldset: {
      border: 0,
    },
    '.MuiInputLabel-root.Mui-error': {
      color: palette.brand.red,
    },
    '.MuiFormLabel-filled': {
      color: palette.neutrals.gray500,
      fontWeight: 400,
      left: '0.1rem',
      letterSpacing: '0.5px',
      position: 'absolute',
      top: '1.25rem',
      width: '150%',
    },
    '.MuiAutocomplete-popupIndicator': {
      color: palette.brand.indigo,
    },
    'label:not(.MuiFormLabel-filled)': {
      color: palette.neutrals.gray500,
      fontSize: '1rem',
      marginTop: '0.25rem',

      '&[data-shrink=true]': {
        color: palette.neutrals.gray700,
        fontWeight: 400,
        left: '0.1rem',
        letterSpacing: '0.5px',
        position: 'absolute',
        top: '1.5rem',
      },
    },
    input: {
      border: '1px solid transparent',
      height: 'auto',
      color: palette.neutrals.gray800,
      fontSize: '1rem',
      fontWeight: 600,
      marginTop: '0.75rem',
    },
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: palette.neutrals.gray50,
      height: '4rem',
    },
    '.MuiAutocomplete-endAdornment': {
      fontFamily: 'ProximaNova',
    },
    '& .MuiInputLabel-asterisk': {
      backgroundColor: palette.brand.red,
      borderRadius: '100%',
      color: 'transparent',
      height: '0.5rem',
      right: '0.75rem',
      position: 'absolute',
      width: '0.5rem',
      transition: 'none',
    },
  }),
};
