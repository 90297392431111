import { debounce } from 'lodash';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useUpdateEffect } from './useUpdateEffect';

/**
 * Wrapper for useState that debounces and calls the save method whenever the value changes
 * @param defaultValue — Imperative function that can return a cleanup function
 * @param save — If present, effect will only activate if the values in the list change.
 * @see useState
 */
export const useDebounceDraftState = <TValue>(
  defaultValue: TValue | (() => TValue),
  save: (value: TValue) => void,
  debounceTime = 200,
): [TValue, (newDraft: TValue) => void] => {
  const [draft, setDraft] = useState(defaultValue);

  // lodash's debounce method -still- doesn't have a pending() method
  // so we need to keep track of that ourselves
  // see: https://github.com/lodash/lodash/issues/4322
  const [isPending, setIsPending] = useState(false);

  const doSave = useCallback((value: TValue) => {
    setIsPending(false);
    save(value);
  }, [save]);

  const [debouncedSave, setDebouncedSave] = useState(() => debounce(doSave, debounceTime));

  // This useEffect needs done before the one handling doSave changes
  // to ensure the correct draft value is passed to the new debouncedSave
  // if isPending is true and doSave changes at the same time defaultValue
  // changes
  useUpdateEffect(() => {
    if (defaultValue !== draft) {
      setDraft(defaultValue);
    }

    // draft not included to avoid value resetting whenever draft changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);
  useUpdateEffect(() => {
    const newDebouncedSave = debounce(doSave, debounceTime);

    // Need to cancel the old debouncedSave, if any.
    // Otherwise it could still fire off after it is replaced
    // and cause all sorts of weirdness
    if (isPending) {
      debouncedSave.cancel();
      newDebouncedSave(draft);
    }

    setDebouncedSave(() => newDebouncedSave);

    // debouncedSave not included to prevent infinite loop
    // isPending not included to prevent unnecessary re-setting of debouncedSave
    // draft not included to prevent unnecessary re-setting of debouncedSave
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doSave]);

  const doUpdate = useCallback((newDraft: TValue) => {
    setDraft(newDraft);
    setIsPending(true);
    debouncedSave(newDraft);
  }, [debouncedSave]);

  // Immediately trigger the save on component unmount
  useEffect(() => (() => debouncedSave.flush()), [debouncedSave]);

  return [
    draft,
    doUpdate,
  ];
};
