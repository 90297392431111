import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faStar as farStarSolid } from '@fortawesome/pro-solid-svg-icons';
import Button, { BUTTON_VARIANTS } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const FavoriteButton = ({
  isFavorite, onClick, id, orgId, ...props
}) => {
  const doClick = () => {
    onClick({ isFavorite: !isFavorite, id, orgId });
  };

  return (
    <Button
      variant={BUTTON_VARIANTS.ICON}
      onClick={doClick}
      {...props}
    >
      <FontAwesomeIcon icon={isFavorite ? farStarSolid : faStarLight} />
    </Button>
  );
};

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default FavoriteButton;
