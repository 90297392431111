import { LearningTemplateFromSearch } from '~Learning/const/interfaces';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useLearningTemplateSearch } from '../templates/singleContent/useLearningTemplateSearch';
import { useLearningTableGroupTemplateSearch } from '../templates/curatedByTheTableGroup/useLearningTableGroupTemplateSearch';
import { useLearningCuratedTemplateSearch } from '../templates/curatedByLeadr/useLearningCuratedTemplateSearch';

interface CombinedLearningTemplateSearchQueryParameters {
  page: number,
  count: number,
  searchString: string,
  isValidSearch: boolean,
  isCuratedByLeadr: boolean,
  isCuratedByTableGroup: boolean,
}

interface CombinedLearningTemplateSearchHookReturn {
  learningTemplates: LearningTemplateFromSearch[],
  isLoading: boolean,
  total: number,
}

export const useCombinedLearningTemplateSearch = ({
  page,
  count,
  searchString,
  isValidSearch,
  isCuratedByLeadr,
  isCuratedByTableGroup,
}: CombinedLearningTemplateSearchQueryParameters): CombinedLearningTemplateSearchHookReturn => {
  const useDynamicCuratedByLeadrLibrary = useFeatureFlag('learningCuratedByLeadrInApp') && isCuratedByLeadr;

  const {
    learningTemplates: orgLearningTemplatesFromSearch,
    isLoading: areOrgLearningTemplatesFromSearchLoading,
    total: orgLearningTemplatesFromSearchTotal,
  } = useLearningTemplateSearch({
    page,
    count,
    curated: isCuratedByLeadr,
    searchString,
    isValidSearch,
    enabled: !useDynamicCuratedByLeadrLibrary && !isCuratedByTableGroup,
  });

  const {
    learningTemplates: curatedByLeadrLearningTemplatesFromSearch,
    isLoading: areCuratedByLeadrLearningTemplatesFromSearchLoading,
    total: curatedByLeadrLearningTemplatesFromSearchTotal,
  } = useLearningCuratedTemplateSearch({
    page,
    count,
    searchString,
    isValidSearch,
    enabled: useDynamicCuratedByLeadrLibrary && !isCuratedByTableGroup,
  });

  const {
    learningTemplates: tableGroupLearningTemplatesFromSearch,
    isLoading: areTableGroupLearningTemplatesFromSearchLoading,
    total: tableGroupLearningTemplatesFromSearchTotal,
  } = useLearningTableGroupTemplateSearch({
    page,
    count,
    searchString,
    isValidSearch,
    enabled: isCuratedByTableGroup,
  });

  const leadrLearningTemplatesFromSearch = useDynamicCuratedByLeadrLibrary
    ? curatedByLeadrLearningTemplatesFromSearch
    : orgLearningTemplatesFromSearch;
  const areLeadrLearningTemplatesFromSearchLoading = useDynamicCuratedByLeadrLibrary
    ? areCuratedByLeadrLearningTemplatesFromSearchLoading
    : areOrgLearningTemplatesFromSearchLoading;
  const learningTemplatesFromSearchTotal = useDynamicCuratedByLeadrLibrary
    ? curatedByLeadrLearningTemplatesFromSearchTotal
    : orgLearningTemplatesFromSearchTotal;

  const learningTemplates = isCuratedByTableGroup ? tableGroupLearningTemplatesFromSearch : leadrLearningTemplatesFromSearch;
  const isLoading = isCuratedByTableGroup ? areTableGroupLearningTemplatesFromSearchLoading : areLeadrLearningTemplatesFromSearchLoading;
  const total = isCuratedByTableGroup ? tableGroupLearningTemplatesFromSearchTotal : learningTemplatesFromSearchTotal;

  return {
    learningTemplates,
    isLoading,
    total,
  };
};
