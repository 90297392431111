import { cloneDeep } from 'lodash';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import { stripHtml } from '~Common/utils/stripHtml';
import { DEFAULT_MULTIPLE_CHOICE, DEFAULT_SCALE, DEFAULT_TOPIC } from '../Const/defaults';
import {
  linearTopicConfig, multiChoiceTopicConfig, ParticipantTypeEnum, TopicShape,
} from '../Const/types';
import { createTopicFormResolver, FormValues } from '../schemata/CreateTopicForCycle';
import { createTopicMatrixOrgsFormResolver, FormValuesMatrixOrg } from '../schemata/CreateTopicForCycleMatrixOrgs';

interface UseTopicFormContext {
  topic?: TopicShape,
  isMatrixOrg?: boolean,
}

interface UseTopicFormContextReturn {
  formContext: UseFormReturn<FormValues | FormValuesMatrixOrg>,
}

function makeTopicLinearConfig(typeConfig?: string | Record<string, unknown> | linearTopicConfig | multiChoiceTopicConfig): linearTopicConfig {
  if (!typeConfig || typeConfig === 'null') {
    return DEFAULT_SCALE;
  }

  return (typeof typeConfig === 'string' ? JSON.parse(typeConfig) : typeConfig) as linearTopicConfig;
}

function makeTopicMultiChoiceConfig(typeConfig?: string | Record<string, unknown> | linearTopicConfig | multiChoiceTopicConfig): multiChoiceTopicConfig {
  if (!typeConfig || typeConfig === 'null') {
    return DEFAULT_MULTIPLE_CHOICE;
  }

  return (typeof typeConfig === 'string' ? JSON.parse(typeConfig) : typeConfig) as multiChoiceTopicConfig;
}
export function useTopicFormContext({
  topic,
  isMatrixOrg = true,
}: UseTopicFormContext): UseTopicFormContextReturn {
  const formValues = (): FormValues => {
    const {
      text,
      typeEnum,
      rank,
      description,
      topicTargetEnum,
    } = topic as TopicShape ?? DEFAULT_TOPIC;

    const title = stripHtml(text);
    const typeConfig = topic?.typeConfig;
    const topicLinearConfig = makeTopicLinearConfig(typeConfig);
    const topicMultiChoiceConfig = makeTopicMultiChoiceConfig(typeConfig);

    return {
      text: title,
      rank,
      typeEnum,
      topicIsForReviewers: FlaggedEnum(topicTargetEnum).hasFlag(ParticipantTypeEnum.Reviewer),
      topicIsForReviewees: FlaggedEnum(topicTargetEnum).hasFlag(ParticipantTypeEnum.Reviewee),
      description,
      multiChoiceConfig: topicMultiChoiceConfig.multipleChoiceOptions,
      linearConfig: topicLinearConfig,
    };
  };

  const formValuesMatrixOrg = (): FormValuesMatrixOrg => {
    const {
      text,
      typeEnum,
      rank,
      description,
      topicTargetEnum,
    } = topic as TopicShape ?? DEFAULT_TOPIC;

    const title = stripHtml(text);
    const typeConfig = topic?.typeConfig;
    const topicLinearConfig = makeTopicLinearConfig(typeConfig);
    const topicMultiChoiceConfig = makeTopicMultiChoiceConfig(typeConfig);

    return {
      text: title,
      rank,
      typeEnum,
      topicIsForReviewers: FlaggedEnum(topicTargetEnum).hasFlag(ParticipantTypeEnum.Reviewer),
      topicIsForReviewees: FlaggedEnum(topicTargetEnum).hasFlag(ParticipantTypeEnum.Reviewee),
      topicIsForMentors: FlaggedEnum(topicTargetEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer),
      description,
      multiChoiceConfig: topicMultiChoiceConfig.multipleChoiceOptions,
      linearConfig: topicLinearConfig,
    };
  };

  const formContext = useForm<FormValues>({

    defaultValues: cloneDeep(isMatrixOrg ? formValuesMatrixOrg() : formValues()),
    resolver: isMatrixOrg
      ? createTopicMatrixOrgsFormResolver : createTopicFormResolver,
  });

  return {
    formContext,
  };
}
