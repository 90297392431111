import PropTypes from 'prop-types';
import {
  Redirect,
} from 'react-router-dom';

import {
  setRedirectionUrl,
  getRedirectionUrl,
} from '~Common/utils/localStorage';

// Handle routings
const SmartRedirect = (props) => {
  // destructure component and remaining params from routeParams.
  const {
    location, from, to,
  } = props;

  const redirectionUrl = getRedirectionUrl();

  // If it exists, re-set it. Helps with workflows that redirect multiple times
  if (redirectionUrl) {
    setRedirectionUrl(redirectionUrl.pathname, redirectionUrl.search);
  } else if (location) {
    setRedirectionUrl(location.pathname, location.search);
  }

  return <Redirect exact from={from} to={to} />;
};

SmartRedirect.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

SmartRedirect.defaultProps = {
  location: { pathname: '', search: '' },
};

export default SmartRedirect;
