import { css, SerializedStyles } from '@emotion/react';
import { LegacyRef } from 'react';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  ReviewUnsyncedMeetingsDrawerTab,
  useReviewUnsyncedMeetingsDrawerTab,
} from '~Meetings/components/drawers/ReviewUnsyncedMeetingsDrawer/useReviewUnsyncedMeetingsDrawerTab';
import { useIsReadOnlyCalendarIntegration } from '~Meetings/hooks/CalendarIntegration/useIsReadOnlyCalendarIntegration';
import MeetingsInCalendar from './tabs/MeetingsInCalendar';
import MeetingsInLeadr from './tabs/MeetingsInLeadr';
import PreviouslyIgnoredMeetings from './tabs/PreviouslyIgnoredMeetings';

export const reviewUnsyncedMeetingsDrawerTemplate = {
  name: 'REVIEW_UNSYNCED_MEETINGS_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

const styles = {
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

const ReviewUnsyncedMeetingsDrawer = ({
  popDrawer,
  ...props
}: DrawerProps<unknown>): JSX.Element => {
  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: reviewUnsyncedMeetingsDrawerTemplate.name });
  };

  const [activeTab, setActiveTab] = useReviewUnsyncedMeetingsDrawerTab();
  const isReadOnlyCalendarIntegration = useIsReadOnlyCalendarIntegration();

  const hookProps = {
    renderHeader: () => (
      <TabDrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip={undefined} />
        )}
        renderDrawerInstructions={(drawerInstructionsStyles) => (
          <div css={drawerInstructionsStyles}>Leadr found the following meetings that are not linked and need your review.</div>
        )}
        title="Review Meetings"
        renderTabNav={(tabNavStyles: SerializedStyles, scroller: unknown) => (
          <div css={tabNavStyles} ref={scroller as LegacyRef<HTMLDivElement>}>
            <TabNavItem
              css={styles.tabNavItem}
              isActive={activeTab === ReviewUnsyncedMeetingsDrawerTab.In_Calendar}
              onClick={() => setActiveTab(ReviewUnsyncedMeetingsDrawerTab.In_Calendar)}
              renderNavItem={() => (
                <div>In Calendar</div>
              )}
            />
            {!isReadOnlyCalendarIntegration && (
              <TabNavItem
                isActive={activeTab === ReviewUnsyncedMeetingsDrawerTab.In_Leadr}
                onClick={() => setActiveTab(ReviewUnsyncedMeetingsDrawerTab.In_Leadr)}
                renderNavItem={() => (
                  <div>In Leadr</div>
                )}
              />
            )}
            <TabNavItem
              isActive={activeTab === ReviewUnsyncedMeetingsDrawerTab.Previously_Ignored}
              onClick={() => setActiveTab(ReviewUnsyncedMeetingsDrawerTab.Previously_Ignored)}
              renderNavItem={() => (
                <div>Ignored</div>
              )}
            />
          </div>
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {activeTab === ReviewUnsyncedMeetingsDrawerTab.In_Calendar && (
          <MeetingsInCalendar />
        )}
        {!isReadOnlyCalendarIntegration && activeTab === ReviewUnsyncedMeetingsDrawerTab.In_Leadr && (
          <MeetingsInLeadr />
        )}
        {activeTab === ReviewUnsyncedMeetingsDrawerTab.Previously_Ignored && (
          <PreviouslyIgnoredMeetings />
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({ templateName: reviewUnsyncedMeetingsDrawerTemplate.name, component: ReviewUnsyncedMeetingsDrawer });

export default ReviewUnsyncedMeetingsDrawer;
