import { useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { learningTemplatesQueryKeys } from '../queryKeys';
import { LearningPlaylistTemplateDetail } from '../playlists/useGetLearningPlaylistTemplateDetail';

interface GetLearningTableGroupPlaylistDetailParams {
  playlistTemplateId: number,
}

const getLearningTableGroupPlaylistTemplateDetail = async ({
  playlistTemplateId,
}: GetLearningTableGroupPlaylistDetailParams): Promise<HttpCallReturn<LearningPlaylistTemplateDetail>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/templates/curatedByTableGroup/${playlistTemplateId}`;

  return getApi<LearningPlaylistTemplateDetail>(serverUrl);
};

interface UseGetLearningPlaylistTemplateDetailParams {
  playlistTemplateId: number,
  enabled?: boolean,
}

interface UseGetLearningTableGroupPlaylistTemplateDetailReturn {
  detail: LearningPlaylistTemplateDetail | undefined,
  isLoading: boolean,
}

export const useGetLearningTableGroupPlaylistTemplateDetail = ({
  playlistTemplateId,
  enabled = true,
}: UseGetLearningPlaylistTemplateDetailParams): UseGetLearningTableGroupPlaylistTemplateDetailReturn => {
  const result = useQuery({
    queryKey: learningTemplatesQueryKeys.playlistDetail(playlistTemplateId),
    queryFn: () => getLearningTableGroupPlaylistTemplateDetail({
      playlistTemplateId,
    }),
    enabled: enabled && !!playlistTemplateId,
  });

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
