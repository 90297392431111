import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import NewActionItemDetails from '~Meetings/components/details/action/NewActionItemDetails';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import Dropdown from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';
import { withoutDesktop, withoutMobileObject } from '~Common/styles/mixins';
import emptyActionItemsNoBackground from '~Meetings/assets/images/emptyActionItemsNoBackground.png';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { ActionItemStatus, MeetingFactoryType } from '~Meetings/const/meetingsInterfaces';
import { DROPDOWN_ITEMS_SHAPE, EMOTION_CSS_SHAPE, TASK_SHAPE } from '~Common/const/proptypes';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { createEditActionItemTemplate } from '~ActionItems/components/Drawers/CreateEditActionItemDrawer';
import { ActionItemContextType, NewActionItemStatus } from '~ActionItems/const/interfaces';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { useMeetingActionItems } from '~ActionItems/hooks/useMeetingActionItems';
import { ActionItemSortField } from '~ActionItems/hooks/useActionItems';
import { SortDirection } from '~Common/const/interfaces';
import Tooltip from '~Common/components/Tooltip';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';

const styles = {
  header: css({
    marginBottom: '1rem',
  }),
  titleContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  }),
  title: css({
    fontSize: '1.125rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
  }),
  divider: css({
    flex: 1,
    margin: 0,
  }),
  foo: css({
    flexShrink: 0,
    width: '1rem',
    margin: 0,
  }),
  container: css`
    background-color: ${palette.neutrals.white};
    padding: 1rem;
    box-shadow: 0 .3125rem .9375rem rgba(0,0,0,.07);
    border-radius: .5rem;
    ${withoutDesktop(`
      box-shadow: none;
      padding: 0 1rem;
      padding-top: 2.25rem;
    `)}
  `,
  content: css`
    padding: 0 .5rem;

    ${withoutDesktop(`
      padding: 0;
    `)}
  `,
  actionItemDetails: css({
    padding: 0,
  }, withoutMobileObject({
    padding: '0 1.5rem',
  })),
  editBtn: css`
    border: none;
    background-color: ${palette.neutrals.gray50};
    color: ${palette.neutrals.gray600};
    font-weight: 25rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  `,
  skeleton: css`
    height: 15.625rem;
    width: 100%;
    max-width: 100%;
  `,
  controls: css({
    display: 'flex',
    justifyContent: 'space-between',

    // Overlap the <hr />
    marginBottom: '-1px',
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'flex-end',
  }),
  tabButton: (isActive) => css({
    color: palette.brand.indigo,
    fontWeight: 'bold',
    margin: 0,
    border: 0,
    padding: 0,
    borderStyle: 'solid',
    background: 'transparent',
    marginRight: '1rem',

    // Use a fixed button height and flex rather than padding so that
    // changing bottom border height doesn't cause the button height to change
    height: '2.5rem',
    display: 'flex',
    alignItems: 'flex-start',

    '&:hover, &:focus': {
      borderBottom: `3px solid ${palette.brand.indigo}`,
    },
  }, isActive && {
    borderBottom: `3px solid ${palette.brand.indigo}`,
  }),
  dropdownContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  }),
  dropdown: css({
    padding: '0 0.75rem',
    width: '9rem',

    // ToDo: These change should probably be moved into the Dropdown component itself
    // If these are the styles we are using going forward
    '& .MuiInput-root': {
      marginTop: 0,
      fontSize: '0.75rem',
    },
  }),
  dropdownLabel: css({
    marginRight: '0.5rem',
    color: palette.neutrals.gray600,
  }),
  createActionItemLinkButton: css({
    color: palette.brand.indigo,
    fontWeight: 600,
    padding: 0,
  }),
  emptyStateContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    h6: {
      textTransform: 'unset',
    },
  }),
  emptyStateImage: css({
    height: '7.875rem',
  }),
  emptyStateText: css({
    color: palette.neutrals.gray800,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
    textTransform: 'none',
    marginBottom: '1.5rem',
  }),
};

const renderImage = () => (
  <img css={styles.emptyStateImage} src={emptyActionItemsNoBackground} alt="Empty Action Items" data-test-id="meetingsEmptyActionItems" />
);

const NewSortValues = {
  DueDate: {
    sortField: ActionItemSortField.DueDate,
    sortDirection: SortDirection.Ascending,
  },
  CompletedDate: {
    sortField: ActionItemSortField.CompletedDate,
    sortDirection: SortDirection.Descending,
  },
  Status: {
    sortField: ActionItemSortField.Status,
    sortDirection: SortDirection.Ascending,
  },
  Assignee: {
    sortField: ActionItemSortField.Assignee,
    sortDirection: SortDirection.Ascending,
  },
  Title: {
    sortField: ActionItemSortField.Text,
    sortDirection: SortDirection.Ascending,
  },
};

const View = ({
  containerStyle,
  actionItems,
  id,
  type,
  isLoading,
  showSkeletonLoaders,
  onTabClick,
  openAddActionItem,
  actionItemStatus,
  actionItemSort,
  sortOptions,
  onSortChange,
  onActionItemCreate,
  hasLimitedAccess,
  renderEmptyStateText,
  isMeetingLoading,
  ...props
}) => (
  <div
    css={containerStyle}
    {...props}
  >
    <div
      css={styles.container}
    >
      <div
        css={styles.content}
      >
        {/* The additions to the Action Items header seemed specific enough to it */}
        {/* that it was not worth the complexity cost of trying to build it into */}
        {/* the existing DetailsHeader component. So this is a one-off implementation */}
        {/* If this style of sorting/tab controls end up being used elsewhere this */}
        {/* should be commonalize into the DetailsHeader component */}
        <div css={styles.header}>
          <div css={styles.titleContainer}>
            <div css={styles.title}>
              Action Items
            </div>

            <Tooltip
              content={!hasLimitedAccess ? 'Create a new action item for this meeting' : 'Limited Access User cannot create new action items'}
            >
              <LeadrButton
                onClick={openAddActionItem}
                data-test-id="meetingAddActionItemInput"
                // Need to disallow creating action items before the meeting data has finished
                // loading so we actually have a factoryId to pass to the Action Item drawer
                disabled={isMeetingLoading || hasLimitedAccess}
                size="small"
                variant="ghost"
              >
                <LeadrButton.IconAndText icon={faPlus} text="Add Item" />
              </LeadrButton>
            </Tooltip>
          </div>

          <div css={styles.controls}>
            <div css={styles.buttonContainer}>
              <button
                css={styles.tabButton(actionItemStatus === ActionItemStatus.INCOMPLETE)}
                type="button"
                onClick={() => onTabClick(ActionItemStatus.INCOMPLETE)}
              >
                Open
              </button>

              <button
                css={styles.tabButton(actionItemStatus === ActionItemStatus.COMPLETED)}
                type="button"
                onClick={() => onTabClick(ActionItemStatus.COMPLETED)}
              >
                Completed
              </button>
            </div>

            <div css={styles.dropdownContainer}>
              <span
                css={styles.dropdownLabel}
              >
                Sort By:
              </span>

              <Dropdown
                css={styles.dropdown}
                value={actionItemSort}
                items={sortOptions}
                onChange={onSortChange}
              />
            </div>
          </div>

          <hr css={styles.divider} />
        </div>

        <div
          css={styles.actionItemDetails}
        >
          { actionItems?.length > 0 && actionItems?.map((item) => (
            <NewActionItemDetails
              key={item.id}
              meetingId={id}
              meetingType={type}
              actionItem={item}
            />
          ))}

          {actionItems?.length === 0 && !showSkeletonLoaders && (
            <EmptyStateWithImage
              css={styles.emptyState}
              renderImage={renderImage}
              renderText={renderEmptyStateText}
            />
          )}

          {showSkeletonLoaders && (
            <SkeletonLoader
              variant="rect"
              css={styles.skeleton}
              renderComponent={() => <div />}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

View.propTypes = {
  openAddActionItem: PropTypes.func.isRequired,
  containerStyle: PropTypes.shape({ EMOTION_CSS_SHAPE }),
  actionItems: PropTypes.arrayOf(TASK_SHAPE),
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showSkeletonLoaders: PropTypes.bool.isRequired,
  onTabClick: PropTypes.func.isRequired,
  actionItemStatus: PropTypes.oneOf(Object.values(ActionItemStatus)).isRequired,
  actionItemSort: PropTypes.shape({
    sortField: PropTypes.oneOf(Object.values(ActionItemSortField)).isRequired,
    sortDirection: PropTypes.oneOf(Object.values(SortDirection)).isRequired,
  }).isRequired,
  sortOptions: PropTypes.arrayOf(DROPDOWN_ITEMS_SHAPE).isRequired,
  onSortChange: PropTypes.func.isRequired,
  onActionItemCreate: PropTypes.func.isRequired,
  hasLimitedAccess: PropTypes.bool,
  renderEmptyStateText: PropTypes.func.isRequired,
  isMeetingLoading: PropTypes.bool.isRequired,
};

View.defaultProps = {
  containerStyle: {},
  hasLimitedAccess: false,
  actionItems: [],
};

const MeetingActionItems = ({ id, type, ...props }) => {
  const dispatch = useDispatch();
  const [actionItemStatus, setActionItemStatus] = useState(ActionItemStatus.INCOMPLETE);
  const [actionItemSort, setActionItemSort] = useState('DueDate');
  const { item: meeting, isLoading: isMeetingLoading } = useMeetingDetails({ id, type });
  const { peopleData } = useNewPeople({});

  const attendees = useMemo(() => {
    let newAttendees = [];

    if (meeting && peopleData) {
      const meetingAttendees = [...meeting.attendeeOrgUserIds, meeting.organizer.orgUserId];

      newAttendees = meetingAttendees.map((orgUserId) => peopleData[orgUserId]?.userId);
    }

    return newAttendees;
  }, [meeting, peopleData]);

  const { data: actionItemsData, isLoading } = useMeetingActionItems({
    factoryId: meeting?.factoryId,
    sortByField: NewSortValues[actionItemSort]?.sortField,
    sortByOrder: NewSortValues[actionItemSort]?.sortDirection,
    status: actionItemStatus === ActionItemStatus.INCOMPLETE ? [
      NewActionItemStatus.ToDo,
      NewActionItemStatus.InProgress,
      NewActionItemStatus.Blocked,
    ] : NewActionItemStatus.Completed,
  });

  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);
  const { isLimitedAccess: hasLimitedAccess } = useUserPermissions();

  const renderEmptyStateText = useCallback(() => (
    <p css={styles.emptyStateText}>
      Press &ldquo;
      <LeadrButton
        data-test-id="meetingsPageEmptyStateAddActionItem"
        css={styles.createActionItemLinkButton}
        variant="text"
        onClick={openAddActionItem}
      >
        + Add Item
      </LeadrButton>
      &rdquo; to assign a new Action Item.
    </p>
  ), [openAddActionItem]);

  const sortOptions = useMemo(() => {
    const newSortOptions = [
      {
        text: 'Title',
        value: 'Title',
      },
    ];

    if (actionItemStatus === ActionItemStatus.INCOMPLETE) {
      newSortOptions.unshift({
        text: 'Due Date',
        value: 'DueDate',
      });

      newSortOptions.push({
        text: 'Status',
        value: 'Status',
      });
    } else {
      newSortOptions.unshift({
        text: 'Completed Date',
        value: 'CompletedDate',
      });
    }
    return newSortOptions;
  }, [actionItemStatus]);

  useEffect(() => {
    if (actionItemStatus === ActionItemStatus.INCOMPLETE) {
      setActionItemSort('DueDate');
    } else {
      setActionItemSort('CompletedDate');
    }
  }, [actionItemStatus]);

  const onActionItemCreate = useCallback(() => {
    setActionItemStatus(ActionItemStatus.INCOMPLETE);
  }, []);

  const onTabClick = useCallback((status) => {
    setActionItemStatus(status);
  }, []);

  const onSortChange = useCallback((e) => {
    setActionItemSort(e.target.value);
  }, []);

  const openAddActionItem = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
        args: {
          validAssignees: type === MeetingFactoryType.COACHING ? attendees : undefined,
          context: {
            type: ActionItemContextType.Meeting,
            id: meeting?.factoryId,
          },
        },
      },
    }));
  }, [attendees, dispatch, meeting, type]);

  const hookProps = {
    openAddActionItem,
    actionItems: actionItemsData?.response,
    id,
    type,
    isLoading,
    showSkeletonLoaders,
    onTabClick,
    actionItemStatus,
    actionItemSort,
    sortOptions,
    onSortChange,
    onActionItemCreate,
    hasLimitedAccess,
    renderEmptyStateText,
    isMeetingLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

MeetingActionItems.propTypes = {
  actionItems: PropTypes.arrayOf(TASK_SHAPE).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

MeetingActionItems.defaultProps = {
};

export { View };
export default MeetingActionItems;
