import { css } from '@emotion/react';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@mui/material/Collapse';
import { palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';
import Tooltip from '~Common/components/Tooltip';
import { NavigationItem } from '~Root/components/OldNavigationSidebar/types';
import { useNavigationStore } from '~Root/components/OldNavigationSidebar/useNavigationStore';
import { SHARED_STYLES } from '~Root/components/OldNavigationSidebar/NavigationItems/styles';

const styles = {
  ...SHARED_STYLES,
  button: css({
    width: '100%',
  }),
  collapseIcon: (isSectionExpanded: boolean) => css({
    rotate: '0deg',
    transition: 'rotate 100ms ease',
  }, isSectionExpanded && {
    rotate: '180deg',
  }),
  highlight: css({
    color: palette.brand.indigo,
  }),
};

interface ViewProps extends CollapsingItemProps {
  isSectionExpanded: boolean,
  toggleIsSectionExpanded: (section: string) => void,
}

const View = ({
  isCollapsed,
  isSectionExpanded,
  item,
  renderChildren,
  toggleIsSectionExpanded,
}: ViewProps): JSX.Element => (
  <Tooltip
    content={isCollapsed && !isSectionExpanded ? item.label : null}
    placement="right"
  >
    <div>
      <Button
        variant="text"
        css={[styles.button, styles.navItem(isCollapsed), isSectionExpanded && styles.highlight]}
        onClick={() => toggleIsSectionExpanded(item.label)}
        data-label={item.label}
        renderContents={() => (
          <>
            <FontAwesomeIcon icon={item.icon} css={styles.icon} />
            {!isCollapsed && (
              <div css={styles.label}>
                {item.label}
              </div>
            )}
            {!isCollapsed && (
              <FontAwesomeIcon
                icon={faChevronDown}
                css={styles.collapseIcon(isSectionExpanded)}
              />
            )}
          </>
        )}
      />
      {item.children && (
        <Collapse in={isSectionExpanded}>
          {renderChildren()}
        </Collapse>
      )}
    </div>
  </Tooltip>
);

interface CollapsingItemProps {
  isCollapsed: boolean,
  item: NavigationItem,
  renderChildren: () => JSX.Element,
}

export const CollapsingItem = ({
  isCollapsed,
  item,
  renderChildren,
}: CollapsingItemProps): JSX.Element => {
  const expandedSections = useNavigationStore((state) => state.expandedSections);
  const toggleIsSectionExpanded = useNavigationStore((state) => state.toggleExpandedSection);
  const isSectionExpanded = (item.label in expandedSections) && expandedSections[item.label];

  const hookProps = {
    isCollapsed,
    isSectionExpanded,
    item,
    renderChildren,
    toggleIsSectionExpanded,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
