import { css } from '@emotion/react';
import {
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import LeadrCard from '~Common/V3/components/LeadrCard';
// import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import LeadrTabs from '~Common/V3/components/LeadrTabs';
import { MY_TEAMS, ALL_TEAMS } from '~Common/const/routes';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { rowsPerPageOptions } from '~Admin/const/defaults';
import Dropdown from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';
import { SelectChangeEvent } from '@mui/material';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import TeamsRouter from '../routes/TeamsRouter';
import CreateEditTeamModal from './CreateEditTeamModal';
import { useTeamRowDisplayCount } from '../stores/useTeamRowDisplayCount';
import { useShowCreateEditTeamModal } from '../stores/useShowCreateEditTeamModal';

const styles = {
  leadrCard: css({
    paddingBottom: '1.875rem',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  infoContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.625rem',
  }),
  headerText: css({
    color: palette.brand.indigo,
    fontSize: '1.5rem',
    fontWeight: 600,
  }),
  description: css({
    color: palette.neutrals.gray600,
    fontSize: '.875rem',
  }),
  leadrSearchField: css({
    alignSelf: 'end',
  }),
  leadrTabs: css({
    marginTop: '1.5rem',
  }),
  teamsBody: css({
    marginTop: '1.5rem',
  }),
  paginationContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.375rem',
  }),
  dropdown: css({
    fontSize: '0.75rem',
    padding: '0.25rem',
    paddingLeft: '0.75rem',
    width: 'auto',

    '.MuiInput-root': {
      fontSize: '0.75rem',
      marginTop: 0,
    },
  }),
};

interface ViewProps {
  teamsTabs: TeamsTab[],
  activeTab: string,
  handleTabChange: (event: SyntheticEvent, tab: string) => void,
  rowsPerPage: number,
  handleRowsPerPageChange: (event: SelectChangeEvent<string>) => void,
  requiredTeamOwnerOrgUserIds: string[],
  showModal: boolean,
  isMobile: boolean,
}

const View = ({
  teamsTabs,
  activeTab,
  handleTabChange,
  rowsPerPage,
  handleRowsPerPageChange,
  requiredTeamOwnerOrgUserIds,
  showModal,
  isMobile,
}: ViewProps): JSX.Element => (
  <LeadrCard css={styles.leadrCard}>
    <div css={styles.header}>
      <div css={styles.infoContainer}>
        <h2 css={styles.headerText}>Teams</h2>
        <h3 css={styles.description}>Subsets of people in your organization who collaborate on projects with each other.</h3>
      </div>
      { /* TODO: Search, not MVP */ }
      {/* <LeadrSearchField
        css={styles.leadrSearchField}
        data-test-id="teamsSearchField"
      /> */}
    </div>
    <LeadrTabs
      css={styles.leadrTabs}
      value={activeTab}
      handleChange={handleTabChange}
      renderRightItem={isMobile ? undefined : () => (
        <div css={styles.paginationContainer}>
          <span>
            Showing:
          </span>
          <Dropdown
            css={styles.dropdown}
            data-test-id="teamsRowsDisplayed"
            items={rowsPerPageOptions}
            onChange={handleRowsPerPageChange}
            value={rowsPerPage.toString()}
          />
        </div>
      )}
    >
      {teamsTabs.map((teamsTab) => (
        <LeadrTabs.LinkTab
          label={teamsTab.label}
          to={teamsTab.link}
          key={teamsTab.link}
          value={teamsTab.link}
          data-test-id={teamsTab['data-test-id']}
        />
      ))}
    </LeadrTabs>
    <div css={styles.teamsBody}>
      <TeamsRouter />
      { /* Need this condition here to reset the internal state when the modal is closed */ }
      {showModal && (
        <CreateEditTeamModal
          requiredTeamOwnerOrgUserIds={requiredTeamOwnerOrgUserIds}
        />
      )}
    </div>
  </LeadrCard>
);

interface TeamsTab {
  label: string,
  link: string,
  'data-test-id': string,
}

const TeamsCard = (): JSX.Element => {
  const { isAdmin } = useUserPermissions();
  const { pathname } = useLocation();
  const isMobile = useIsMobileQuery();

  const {
    count: rowsPerPage,
    setCount: setRowsPerPage,
  } = useTeamRowDisplayCount((state) => ({
    count: state.count,
    setCount: state.setCount,
  }));
  const showModal = useShowCreateEditTeamModal((state) => state.showModal);

  const teamsTabs = useMemo<TeamsTab[]>(() => {
    const tabs = [
      {
        label: 'My Teams',
        link: MY_TEAMS,
        'data-test-id': 'teamsMyTeamTab',
      },
    ];

    if (isAdmin) {
      tabs.unshift({
        label: 'All Teams',
        link: ALL_TEAMS,
        'data-test-id': 'teamsAllTeamsTab',
      });
    }

    return tabs;
  }, [isAdmin]);

  const requiredTeamOwnerOrgUserIds = useMemo(() => {
    if (isAdmin) {
      return [];
    }

    return [getOrganizationUserId() ?? ''];
  }, [isAdmin]);

  const defaultTab = teamsTabs[0];

  const currentTeamsTab = useMemo(() => {
    const matchedSettingsTabValue = teamsTabs.find((teamsTab) => pathname.includes(teamsTab.link));
    return matchedSettingsTabValue || defaultTab;
  }, [defaultTab, pathname, teamsTabs]);

  const [activeTab, setActiveTab] = useState(currentTeamsTab.link);

  const handleTabChange = (event: SyntheticEvent, newTab: string): void => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    // Ensure that the proper tab is selected, when navigating from the modal or directly to the page
    if (activeTab !== currentTeamsTab.link) {
      setActiveTab(currentTeamsTab.link);
    }
  }, [activeTab, setActiveTab, currentTeamsTab]);

  const handleRowsPerPageChange = (event: SelectChangeEvent<string>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const hookProps = {
    teamsTabs,
    activeTab,
    handleTabChange,
    rowsPerPage,
    handleRowsPerPageChange,
    requiredTeamOwnerOrgUserIds,
    showModal,
    isMobile,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default TeamsCard;
