import { TeamsListScope } from '../const/types';

export interface BaseDataOptions {
  page: number,
  count: number,
}

export interface TeamListDataOptions extends BaseDataOptions {
  // Keeping this in the DataOptions for now, if we need to invalidate All and My teams separately, then we can split it out into a queryKey
  listScope: TeamsListScope,
}

interface TeamListParams {
  orgId: string,
  dataOptions: TeamListDataOptions,
}

interface TeamDetailParams {
  orgId: string,
  teamId: string,
  huddleId?: string,
}

interface MemberDetailParams {
    orgId: string,
    teamId: string,
}

interface MemberListParams extends MemberDetailParams {
  dataOptions: BaseDataOptions,
}

export const teamsQueryKeys = {
  all: (orgId: string) => [orgId, 'teams'] as const,
  lists: (orgId: string) => [...teamsQueryKeys.all(orgId), 'lists'] as const,
  list: ({ orgId, dataOptions }: TeamListParams) => [...teamsQueryKeys.lists(orgId), 'list', dataOptions] as const,
  details: (orgId: string) => [...teamsQueryKeys.all(orgId), 'details'] as const,
  detail: ({ orgId, teamId, huddleId }: TeamDetailParams) => [...teamsQueryKeys.details(orgId), teamId, huddleId] as const,
  memberLists: ({ orgId, teamId }: MemberDetailParams) => [...teamsQueryKeys.detail({ orgId, teamId }), 'memberLists'] as const,
  memberList: ({ orgId, teamId, dataOptions }: MemberListParams) => [...teamsQueryKeys.memberLists({ orgId, teamId }), dataOptions] as const,
};
