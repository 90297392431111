import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  DEFAULT_METRIC_REPORT, DEFAULT_MAX_SUMMARY_COUNT, METRIC_SUMMARY_REPORT_SHAPE,
} from '~Common/const/executiveInsights';
import InsightSummaryHeader from './InsightSummaryHeader';
import InsightSummaryRow from './InsightSummaryRow';

const style = css`
  & .insightStatusIndicator {
    margin-left: 5px;
  }
`;

const InsightDetailSummary = ({ summary, total, className }) => (
  <div
    css={style}
    className={`insightDetailSummary ${className}`}
  >
    <InsightSummaryHeader />
    <div>
      {Object.keys(summary).map((metric) => {
        const report = summary[metric];

        return (
          <InsightSummaryRow
            key={metric}
            metric={metric}
            report={report}
            total={total}
          />
        );
      })}
    </div>
  </div>
);

InsightDetailSummary.propTypes = {
  summary: METRIC_SUMMARY_REPORT_SHAPE,
  total: PropTypes.number,
  className: PropTypes.string,
};

InsightDetailSummary.defaultProps = {
  summary: DEFAULT_METRIC_REPORT,
  total: DEFAULT_MAX_SUMMARY_COUNT,
  className: '',
};

export default InsightDetailSummary;
