import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { LearningTemplate, LearningTemplateList } from '~Learning/const/interfaces';
import { useLastExistedValue } from '~Deprecated/hooks/useLastExistedValue';
import { buildQueryString } from '~Common/utils';
import { learningTemplatesQueryKeys } from '../queryKeys';

const getLearningTemplateList = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<LearningTemplateList>> => {
  const [, , , , curated, categoryId, page, count] = queryKey;

  const queryParams = {
    page,
    count,
    curated,
    categoryId,
  };

  const queryData = buildQueryString(queryParams);

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/list${queryData}`;

  return getApi<LearningTemplateList>(serverUrl);
};

interface LearningTemplateQueryParameters {
  page: number,
  count: number,
  curated: boolean,
  categoryId: string,
  enabled: boolean,
}

interface LearningTemplateListHookReturn {
  learningTemplates: LearningTemplate[],
  isLoading: boolean,
  learningTemplatesTotalCount: number,
}

export const useGetLearningTemplateList = (learningTemplateQueryParameters: LearningTemplateQueryParameters): LearningTemplateListHookReturn => {
  const result = useQuery({
    queryKey: learningTemplatesQueryKeys.list({
      page: learningTemplateQueryParameters.page,
      count: learningTemplateQueryParameters.count,
      curated: learningTemplateQueryParameters.curated,
      categoryId: learningTemplateQueryParameters.categoryId ?? '',
    }),
    queryFn: getLearningTemplateList,
    enabled: learningTemplateQueryParameters.enabled && !!learningTemplateQueryParameters.categoryId,
  });

  const learningTemplatesTotalCount = useLastExistedValue<number>(result.data?.response?.total) ?? 0;

  return {
    learningTemplates: result.data?.response?.learningTemplates ?? [],
    isLoading: result.isLoading,
    learningTemplatesTotalCount,
  };
};
