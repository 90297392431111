import PropTypes from 'prop-types';
import { useState } from 'react';
import { css } from '@emotion/react';
import { getAvatarColor } from '~Common/styles/colors';
import { makeInitials } from '~Deprecated/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

const styles = {
  baseAvatar: ({ remWidth, remHeight, margin }) => css({
    textAlign: 'center',
    width: `${remWidth}rem`,
    height: `${remHeight}rem`,
    marginRight: margin,
    objectFit: 'cover',
    flexShrink: 0,
  }),
  initialAvatar: css({
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
  }),
  initials: ({ remHeight }) => css({
    fontSize: `${remHeight / 2.25}rem`,
    letterSpacing: 'unset',
    marginTop: '0.1rem',
    lineHeight: `${remHeight / 2.25}rem`,
  }),
  defaultAvatar: ({ width, height }) => css({
    width: `${width}px`,
    height: `${height}px`,
  }),
};

const BaseAvatar = ({
  imgUrl,
  name,
  width,
  height,
  className,
  includeInitials,
  icon,
  margin,
  isDeactivated,
}) => {
  const [isValid, setValid] = useState(true);
  const remWidth = width / 16;
  const remHeight = height / 16;

  if (!isDeactivated && imgUrl && isValid) {
    const imgTester = new Image();
    imgTester.onerror = () => {
      setValid(false);
    };
    imgTester.src = imgUrl;

    return (
      <img
        css={styles.baseAvatar({ remWidth, remHeight, margin })}
        src={imgUrl}
        alt={name}
        className={className}
      />
    );
  }

  if (isDeactivated || icon || !name) {
    const iconToUse = icon || faUser;

    return (
      <div
        css={styles.baseAvatar({ remWidth, remHeight, margin })}
      >
        <span
          className="noImage"
        >
          <FontAwesomeIcon css={styles.defaultAvatar({ width, height })} icon={iconToUse} />
        </span>
      </div>
    );
  }

  const initials = makeInitials({ name });
  return (
    <div
      css={[
        styles.baseAvatar({ remWidth, remHeight, margin }),
        styles.initialAvatar,
        getAvatarColor(initials),
      ]}
      className={className}
    >
      { includeInitials && (
        <div
          css={styles.initials({ remHeight })}
        >
          { initials }
        </div>
      ) }
    </div>
  );
};

BaseAvatar.propTypes = {
  name: PropTypes.string,
  imgUrl: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  includeInitials: PropTypes.bool,
  icon: PropTypes.string,
  margin: PropTypes.number,
  isDeactivated: PropTypes.bool,
};

BaseAvatar.defaultProps = {
  name: null,
  imgUrl: '',
  width: 40,
  height: 40,
  className: '',
  includeInitials: true,
  icon: '',
  margin: 0,
  isDeactivated: false,
};

export default BaseAvatar;
