import { css } from '@emotion/react';
import {
  faTimes, faPenToSquare, faFolderOpen,
} from '@fortawesome/pro-light-svg-icons';

import { useDispatch } from 'react-redux';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';
import SurveyListItem from '~Surveys/Components/Shared/ListItems/SurveyListItem';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { popDrawerAction as popDrawer, popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { SURVEY_TITLES } from '~Surveys/const/surveyTypes';
import { createSurveyTemplate } from '~Surveys/Components/Drawers/Create/index';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { surveyTemplatesTemplate } from '../templates/SurveyTemplateDrawer';

const styles = {
  listItem: css`
    margin: 5px 0;
    &[data-hide]{
      display:none;
    }
  `,
  instructions: css`
    margin: 0 0 15px;
  `,
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

export const surveyTypePickerTemplate = {
  name: 'SURVEY_TYPE_PICKER_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

const options = [
  { content: SURVEY_TITLES.SCRATCH, icon: faPenToSquare, drawer: createSurveyTemplate },
  { content: SURVEY_TITLES.FROM_TEMPLATE, icon: faFolderOpen, drawer: surveyTemplatesTemplate },
];

const SurveyTypePickerDrawer = () => {
  const webClientSurveysTemplates = useFeatureFlag('webClientSurveysTemplates');

  const dispatch = useDispatch();
  const onListItemClick = (drawer) => {
    dispatch(popDrawerAction({ popAll: true }));
    // TODO: Fix should NOT be using this. Need a better way to handle transitions
    setTimeout(() => {
      dispatch(pushDrawerAction({
        drawer: {
          ...drawer,
        },
      }));
    }, 300);
  };

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} />
  );
  return (
    <DrawerLayout
      renderHeader={() => (
        <DrawerHeader
          renderCloseButton={closeButton}
          title="Create Survey"
        />
      )}
      renderBody={() => (
        <div css={styles.drawerBody}>
          <div>
            <DrawerInstructions css={styles.instructions} text="Begin creating your survey" />
          </div>
          <div>
            {options.map((option) => (
              <SurveyListItem
                onClick={() => onListItemClick(option.drawer)}
                key={option.text}
                css={styles.listItem}
                icon={option.icon}
                content={option.content}
                // Hiding the Templates until we're ready for that
                {...(option.content === 'Start with a Template' && !webClientSurveysTemplates ? { 'data-hide': true } : {})}
              />
            ))}
          </div>
        </div>
      )}
    />
  );
};

registerDrawer({
  templateName: surveyTypePickerTemplate.name,
  component: SurveyTypePickerDrawer,
});

export default SurveyTypePickerDrawer;
