import { ReactText, useRef } from 'react';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { getOrgDetailsQuery } from '~Common/hooks/useGetOrgDetails';

export interface UpdateOrgSettingsData {
  orgSettingsId?: string,
  createdInMillis?: number,
  lastModifiedInMillis?: number,
  enableEnneagram: boolean,
  enableBriggsMyers: boolean,
  enableDisc: boolean,
  enableStrengthFinder: boolean,
  enableCustomFavorite1: boolean,
  customFavorite1Name: string | null,
  enableCustomFavorite2: boolean,
  customFavorite2Name: string | null,
  enableCustomPersonality1: boolean,
  customPersonality1Name: string | null,
  enableCustomPersonality2: boolean,
  customPersonality2Name: string | null,
  enableMatrixOrganization: boolean,
  enableEnterpriseSSO?: boolean,
}

export const updateOrganizationSettings = (data: UpdateOrgSettingsData): Promise<HttpCallReturn<unknown>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/settings`;

  return patchApi<unknown>(serverUrl, data);
};

export const useUpdateOrganizationSettings = (): UseMutationResult<HttpCallReturn<unknown>, unknown, UpdateOrgSettingsData> => {
  const orgId = getOrganizationId() ?? '';
  const toastId = useRef<ReactText | number | null>(null);

  return useMutation({
    mutationFn: updateOrganizationSettings,
    onMutate: () => {
      toastId.current = toast.info('Saving organization settings...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error saving your organization settings. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully saved organization settings!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const userId = getUserId() ?? '';

      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeSettings(orgId, userId) });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.insightsPeers(orgId) });
      void queryClient.invalidateQueries({ queryKey: getOrgDetailsQuery(orgId).queryKey });
      // Invalidate the agenda lists, since permissions can be altered from the admin settings (for example: allowing attachments or not)
      void queryClient.invalidateQueries({ queryKey: meetingKeys.agendaLists() });
    },
  });
};
