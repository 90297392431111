import { useQuery } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';

function getPendingReviewCountQuery(): Promise<HttpCallReturn<number>> {
  const organizationUid = getOrganizationId() ?? '';

  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${organizationUid}/reviews/pending`,
  };

  return getApi<number>(serverUrl);
}

interface UseGetPendingReviewCountReturn {
  data?: number,
  isLoading: boolean,
}

export function useGetPendingReviewCount(): UseGetPendingReviewCountReturn {
  const result = useQuery({
    queryKey: ['reviews', 'pending'],
    queryFn: getPendingReviewCountQuery,
  });

  const data = result?.data?.response;

  return {
    data,
    isLoading: result?.isLoading,
  };
}
