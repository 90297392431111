import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { PropsWithChildren } from 'react';
import Title from './Title';

const styles = {
  section: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 1.5rem',
    border: `1px solid ${palette.neutrals.gray500}`,
    borderRadius: '.25rem',
    pageBreakBefore: 'avoid',
  }),
  agendaItemsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.125rem',
  }),
};

const SectionBase = ({ children, ...props }: PropsWithChildren<unknown>): JSX.Element => (
  <div
    css={styles.section}
    {...props}
  >
    {children}
  </div>
);

// Way to add children as something you can use in this component, has to be done with Object.assign to get around weird TS error
const Section = Object.assign(SectionBase, { Title });

export default Section;
