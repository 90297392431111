import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import ProgressBarWithText from '~Goals/components/Shared/ProgressBarWithText';
import { LinkedGoalType } from '~Goals/const/types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import ChildGoalCount from '~Goals/components/Shared/ChildGoalCount';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import MenuButton from './MenuButton';
import PrivateIcon from './PrivateIcon';

const styles = {
  linkedGoalCard: (manualGrid = false, disabled: boolean) => css({
    gridColumn: '1/5',
    display: 'grid',
    gridTemplateColumns: manualGrid ? 'subGrid' : '1fr auto auto auto',
  }, !manualGrid && ({
    gap: '0.5rem',
  }), disabled && ({
    cursor: 'not-allowed',
  })),
  leadrButton: (disabled: boolean) => css({
    backgroundColor: palette.neutrals.white,
    border: 0,
    width: '100%',
    padding: 0,
    color: palette.neutrals.gray800,
    gridColumn: '1/5',
    display: 'grid',
    gridTemplateColumns: 'subgrid',
    alignItems: 'center',
    fontWeight: 400,
  }, disabled && ({
    pointerEvents: 'none',
  })),
  leftSection: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'hidden',
    gridColumn: '1',
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    textAlign: 'start',
  }, withTruncate()),
  info: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
  }),
  dateText: css({
    color: palette.neutrals.gray800,
    fontSize: '0.75rem',
  }),
  rightSection: css({
    gridColumn: '2/5',
    display: 'grid',
    gridTemplateColumns: 'subgrid',
  }),
  progressBarWithText: css({
    gridColumn: '2',
  }),
  avatar: css({
    gridColumn: '3',
  }),
  menuButton: (visibile: boolean) => css({
    visibility: visibile ? 'visible' : 'hidden',
    gridColumn: '4',
  }),
  contextType: css({
    display: 'inline-block',
  }, withTruncate()),
};

interface ViewProps {
  title: string,
  totalChildGoals: number,
  contextType: Goals.GoalContextType,
  status: Goals.GoalStatus,
  percentage: number,
  creator: Goals.GoalParticipant,
  goalId: string,
  linkedGoalType: LinkedGoalType,
  handleUnlink: () => void,
  canViewGoal: boolean,
  canLinkGoal: boolean,
  formattedStartDate?: string,
  formattedEndDate?: string,
  disabled?: boolean,
  manualGrid?: boolean,
  isTeamGoal: boolean,
  teamName: string | undefined,
}

const View = ({
  title,
  totalChildGoals,
  contextType,
  status,
  percentage,
  formattedStartDate,
  formattedEndDate,
  creator,
  linkedGoalType,
  handleUnlink,
  canViewGoal,
  canLinkGoal,
  disabled,
  goalId,
  manualGrid,
  isTeamGoal,
  teamName,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.linkedGoalCard(manualGrid, !!disabled)}
    {...props}
  >
    <LeadrButton
      css={styles.leadrButton(!!disabled)}
      component={Link}
      to={GoalsRoutes.ViewById.replace(':goalId', goalId)}
      data-test-id="goalsLinkedGoalCard"
      variant="text"
    >
      <div css={styles.leftSection}>
        <div css={styles.title}>{canViewGoal ? title : 'Private Goal'}</div>
        {canViewGoal && (
          <div css={styles.info}>
            {totalChildGoals > 0 && (
              <ChildGoalCount totalChildGoals={totalChildGoals} />
            )}
            <span css={styles.contextType}>{`${isTeamGoal && teamName ? teamName : contextType} Goal`}</span>
          </div>
        )}
      </div>
      {canViewGoal && (
        <>
          <ProgressBarWithText
            css={styles.progressBarWithText}
            status={status}
            percentage={percentage}
            renderText={() => (
              <>
                {formattedStartDate && formattedEndDate && (
                  <div css={styles.dateText}>{`${formattedStartDate} - ${formattedEndDate}`}</div>
                )}
              </>
            )}
          />
          <NewBaseAvatar
            css={styles.avatar}
            name={`${creator.firstName} ${creator.lastName}`}
            profileImageUrl={creator.profileImageUrl}
            avatarSize={30}
          />
        </>
      )}
      {!canViewGoal && (
        <PrivateIcon css={styles.avatar} />
      )}
      <MenuButton
        css={styles.menuButton(canLinkGoal)}
        disabled={!canLinkGoal}
        linkedGoalType={linkedGoalType}
        handleUnlink={handleUnlink}
      />
    </LeadrButton>
  </div>
);

interface LinkedGoalCardProps extends Pick<ViewProps, 'linkedGoalType' | 'handleUnlink' | 'canLinkGoal' | 'manualGrid'>{
  goal: Goals.LinkedGoal,
}

const LinkedGoalCard = ({
  goal,
  ...props
}: LinkedGoalCardProps): JSX.Element => {
  const {
    title,
    totalChildGoals,
    currentStatusUpdate,
    startTimeInMillis,
    endTimeInMillis,
    context,
    participants,
  } = goal;

  const creator = participants.find((participant) => participant.role === Goals.GoalParticipantRole.Owner);
  const isTeamGoal = context.contextType === Goals.GoalContextType.Team;
  const teamName = context.contextName;
  const formattedStartDate = moment(startTimeInMillis).format('MMM D');
  const formattedEndDate = moment(endTimeInMillis).format('MMM D');

  const permissions = goal.permissions ?? [];
  const canViewGoal = !!permissions.includes(Goals.GoalPermission.CanViewGoal);

  const hookProps = {
    title,
    totalChildGoals,
    status: currentStatusUpdate.status,
    percentage: currentStatusUpdate.completionPercentage,
    formattedStartDate,
    formattedEndDate,
    contextType: context.contextType,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    creator: creator!,
    goalId: goal.goalId,
    canViewGoal,
    disabled: !canViewGoal,
    isTeamGoal,
    teamName,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LinkedGoalCard;
