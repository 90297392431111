/* eslint-disable react/button-has-type */

import { css } from '@emotion/react';
import { faCalendarPlus } from '@fortawesome/pro-duotone-svg-icons';

import { forMobile } from '~Common/styles/mixins';
import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Create';

const buttonStyle = css`
  ${forMobile(`
    display: flex;

    & span {
      display: inline;
      padding-left: 8px;
    }
  `)}
`;

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const AssignDueDateButton = (props) => (
  <Button
    {...props}
    icon={faCalendarPlus}
    variant={BUTTON_VARIANT.DEFAULT}
    css={buttonStyle}
  />
);

AssignDueDateButton.propTypes = {};

AssignDueDateButton.defaultProps = {};

export default AssignDueDateButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
