import { css } from '@emotion/react';
import { FormControlLabel } from '@mui/material';

import { palette } from '~Common/styles/colors';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '~Common/components/Tooltip';
import { OrgSettings } from '~Common/const/Organization';
import CheckboxLabel from '../CheckboxLabel';

const styles = {
  featureSettings: css({}),
  descriptionText: css({
    color: palette.neutrals.gray700,
    marginBottom: '0.5rem',
  }),
  enableMatrixOrgs: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  }),
};

interface ViewProps {
  enableMatrixOrganization: boolean,
  webClientUseMatrixOrgs: boolean,
  enableCascadingGoals: boolean,
  cascadingGoals: boolean,
}

const View = ({
  enableMatrixOrganization,
  webClientUseMatrixOrgs,
  enableCascadingGoals,
  cascadingGoals,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.featureSettings}
    {...props}
  >
    <SettingsSectionHeader
      renderContents={() => (
        <>Feature Settings</>
      )}
    />
    <p css={styles.descriptionText}>
      You can enable/disable features below.
    </p>
    {webClientUseMatrixOrgs && (
      <FormControlLabel
        control={(
          <CustomCheckbox
            name="enableMatrixOrganization"
            defaultChecked={enableMatrixOrganization}
          />
        )}
        label={(
          <CheckboxLabel
            renderText={(checkboxLabelTextStyles) => (
              <div css={[checkboxLabelTextStyles, styles.enableMatrixOrgs]}>
                <span>Matrix Organization</span>
                <Tooltip content="Features for matrix organizations (secondary managers)">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </div>
            )}
          />
        )}
      />
    )}
    {cascadingGoals && (
      <FormControlLabel
        control={(
          <CustomCheckbox
            name="enableCascadingGoals"
            defaultChecked={enableCascadingGoals}
          />
        )}
        label={(
          <CheckboxLabel
            renderText={(checkboxLabelTextStyles) => (
              <div css={checkboxLabelTextStyles}>Cascading Goals</div>
            )}
          />
        )}
      />
    )}
  </div>
);

interface FeatureSettingsProps extends Pick<ViewProps, 'cascadingGoals' | 'webClientUseMatrixOrgs'> {
  orgSettings: OrgSettings,
}

const FeatureSettings = ({
  orgSettings,
  ...props
}: FeatureSettingsProps): JSX.Element => {
  const { enableMatrixOrganization, enableCascadingGoals } = orgSettings;

  const hookProps = {
    enableMatrixOrganization,
    enableCascadingGoals,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default FeatureSettings;
