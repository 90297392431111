import { useState } from 'react';
import { useIntl } from 'react-intl';
import isEmail from 'validator/es/lib/isEmail';
import isURL from 'validator/es/lib/isURL';

import authConstants from './constants';

const useForm = () => {
  const { formatMessage } = useIntl();
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  const resetForm = () => {
    setFields({});
    setErrors({});
    setIsDisabled(true);
  };

  const validateTextMaxLength = (fieldType, errorMessage) => {
    let status = false;
    if (fields[fieldType].length > authConstants.maxTextLength) {
      errors[fieldType] = errorMessage;
    } else {
      delete errors[fieldType];
      status = true;
    }
    return status;
  };

  const validateLength = (fieldType, errorMessage) => {
    let status = false;
    if (fields[fieldType].length < authConstants.minTextLength) {
      errors[fieldType] = errorMessage;
    } else {
      delete errors[fieldType];
      status = true;
    }
    return status;
  };

  const validateText = (fieldType, ErrorMessage) => {
    let status = false;
    const fieldValue = fields[fieldType];
    if (typeof fieldValue !== 'undefined') {
      if (fieldType === 'password' && !fieldValue.match(authConstants.password.regx)) {
        errors[fieldType] = ErrorMessage;
      } else {
        delete errors[fieldType];
        status = true;
      }
    }
    return status;
  };

  const validateEmailText = (fieldType, ErrorMessage) => {
    let status = false;
    const fieldValue = fields[fieldType];
    if (typeof fieldValue !== 'undefined') {
      if (!isEmail(fieldValue)) {
        errors[fieldType] = ErrorMessage;
      } else {
        delete errors[fieldType];
        status = true;
      }
    }
    return status;
  };

  const validateEmptyField = (fieldType, ErrorMessage) => {
    let status = false;
    if (!fields[fieldType]) {
      errors[fieldType] = ErrorMessage;
    } else {
      delete errors[fieldType];
      status = true;
    }
    return status;
  };

  const validatePhoneNumber = (fieldType, ErrorMessage) => {
    // const regex = new RegExp(/^([a-zA-Z0-9]+)$/, 'g');
    const regex = /^[+\d]?(?:[\d-.\s()]*)$/g;
    let status = false;
    if (fields[fieldType]) {
      if (!fields[fieldType].match(regex)) {
        errors[fieldType] = ErrorMessage;
      } else {
        delete errors[fieldType];
        status = true;
      }
    }
    return status;
  };

  const validateUrl = (fieldType, ErrorMessage) => {
    let status = false;
    if (fields[fieldType]) {
      if (!isURL(fields[fieldType])) {
        errors[fieldType] = ErrorMessage;
      } else {
        delete errors[fieldType];
        status = true;
      }
    }
    return status;
  };

  const validateTwitterUrl = (fieldType, ErrorMessage) => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/(#!\/)?[a-zA-Z0-9_]+$/g;
    let status = false;
    if (fields[fieldType]) {
      if (!fields[fieldType].match(regex)) {
        errors[fieldType] = ErrorMessage;
      } else {
        delete errors[fieldType];
        status = true;
      }
    }
    return status;
  };

  function validateEnneagramText(fieldType, ErrorMessage) {
    const regex = /^[1-9]([wW][1-9])?$/g;
    let status = false;
    if (fields[fieldType]) {
      if (!fields[fieldType].match(regex)) {
        errors[fieldType] = ErrorMessage;
      } else {
        delete errors[fieldType];
        status = true;
      }
    }
    return status;
  }

  const validate = (e, formName) => {
    switch (e.target.name) {
      case 'meetingName':
        validateEmptyField('meetingName', formatMessage({ id: 'meetings.meetingName.required' }));
        break;
      case 'goalName':
        validateEmptyField('goalName', 'Goal name is required');
        break;
      case 'goalDescription':
        validateEmptyField('goalDescription', 'Goal description is required');
        break;
      case 'orgName':
        validateEmptyField('orgName', formatMessage({ id: 'auth.validate.organization.required' }));
        break;
      case 'firstName':
        if (validateEmptyField('firstName', formatMessage({ id: 'auth.validate.firstName.required' }))) {
          (validateTextMaxLength('firstName', formatMessage({ id: 'auth.validate.firstName.tooLong' })));
        }
        break;
      case 'lastName':
        if (validateEmptyField('lastName', formatMessage({ id: 'auth.validate.lastName.required' }))) {
          (validateTextMaxLength('lastName', formatMessage({ id: 'auth.validate.lastName.tooLong' })));
        }
        break;
      case 'email':
        if (validateEmptyField('email', formatMessage({ id: 'auth.validate.email.required' }))) {
          if (validateEmailText('email', formatMessage({ id: 'auth.validate.email.valid' }))) {
            validateTextMaxLength('email', formatMessage({ id: 'auth.validate.email.tooLong' }));
          }
        }
        break;
      case 'enneagram':
        validateEnneagramText('enneagram', formatMessage({ id: 'auth.validate.enneagram.valid' }));
        break;
      case 'password':
        if (formName && formName === 'login') {
          if (validateEmptyField('password', formatMessage({ id: 'auth.validate.password.required' }))) {
            validateTextMaxLength('password', formatMessage({ id: 'auth.validate.password.tooLong' }));
          }
        } else if (formName && formName === 'register') {
          if (validateEmptyField('password', formatMessage({ id: 'auth.validate.password.required' }))) {
            if (validateLength('password', formatMessage({ id: 'auth.validate.password.tooShort' }))) {
              if (validateText('password', formatMessage({ id: 'auth.validate.password.valid' }))) {
                validateTextMaxLength('password', formatMessage({ id: 'auth.validate.password.tooLong' }));
              }
            }
          }
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors });
  };

  const validateDynamicFields = (formName, value) => {
    validateEmptyField(value, `error${value}`);
    setErrors({ ...errors });
  };

  const handleChange = (field, fromFieldsLength, formName, required, event, type) => {
    let fieldValue;
    if (formName === 'sendResponsAndViewFeedback' || formName === 'sendResponsAndViewContent') {
      fieldValue = event;
    } else {
      fieldValue = event.target.value;
    }
    if (fieldValue !== undefined) {
      fields[field] = fieldValue?.trim();
      setFields(fields);
      // if (required) {
      // for handling dynamic fields
      if (formName === 'sendResponsAndViewFeedback' || formName === 'sendResponsAndViewContent') {
        validateDynamicFields(formName, field);
      } else {
        validate(event, formName);
      }
      // }
      // Check if all fields are present and no errors are present
      if (Object.keys(errors).length === 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(true);
    }
  };
    // On item select for dropdown component
  const onItemSelect = (field, fromFieldsLength, selectedItem) => {
    const fieldValue = selectedItem || '';
    if (fieldValue !== undefined) {
      fields[field] = fieldValue;
      setFields(fields);
      // Check if all fields are present and no errors are present
      if (Object.keys(fields).length === fromFieldsLength && Object.keys(errors).length === 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(true);
    }
  };

  return {
    handleChange,
    onItemSelect,
    fields,
    setFields,
    errors,
    isDisabled,
    resetForm,
  };
};

export default useForm;
