import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetPersonById as usePeoplePickerById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { noop } from '~Deprecated/utils/';
import { nestedListBackground, palette } from '~Common/styles/colors';
import { withH4Font } from '~Deprecated/ui/styles/typography';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import Tooltip from '~Common/components/Tooltip';
import { withLineClamp } from '~Common/styles/mixins';
import { skeletonGlobalAnimation } from '~Common/const/skeletons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const ATTENDEE_HEIGHT = 80;

const styles = {
  container: (isSelected, isDisabled, hideHover) => css`
    height: ${ATTENDEE_HEIGHT}px;
    background: ${nestedListBackground};
    border: 1px solid;
    border-color: ${isSelected ? palette.brand.blue : nestedListBackground};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    overflow: hidden;
    opacity: ${isDisabled ? '0.6' : '1'};
    padding: 1rem;

    ${isDisabled || hideHover ? (`
      cursor: default;
    `) : (`
      &:hover {
        background: ${isSelected ? nestedListBackground : palette.brand.blue};
        border-color: ${isSelected ? palette.brand.red : nestedListBackground};
        .attendeeName {
          color: ${isSelected ? palette.brand.red : palette.neutrals.white} !important;
        }

        .iconMagic {
          color: ${isSelected ? palette.brand.red : nestedListBackground};
        }
      }

    `)}
  `,
  borderRadius: css`
    border-radius: 0.5rem;
  `,
  name: (isSelected) => css`
    ${withH4Font()}
    letter-spacing: 0.5px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${isSelected ? palette.brand.blue : palette.neutrals.gray800};
    ${withLineClamp(1)};
  `,
  infoWrapper: css({
    flex: '1',
    marginLeft: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
  }),
  userInfo: css`
    width: 100%;
    display: flex;
    align-items: center;
  `,
  deleteButton: css`
    padding: 10px 15px;

    &:hover, &:active, &:focus {
      color: ${palette.brand.red};
    }
  `,
  popover: css`
    .MuiPopover-paper {
      max-width: 543px;
      padding: 20px;
    }
  `,
  deleteConfirmationButton: css`
    margin: 8px 0 0 0;
  `,
  skeleton: css`
    border-radius: 0.5rem;
  `,
  removeIcon: () => css`
    color: ${nestedListBackground};
  `,
};

const attendeeItemBorderRadius = styles.borderRadius;

const View = ({
  id,
  avatar,
  name,
  isSelected,
  onClick,
  isAttendeeSelected,
  isDisabled,
  tooltip,
  doClick,
  renderRemoveIcon,
  removeIcon,
  hideHover,
  recallAttendee,
  surveyId,
  canRecall,
  ...props
}) => (
  <Tooltip content={tooltip}>
    <div
      css={[styles.container(isSelected, isDisabled, hideHover), attendeeItemBorderRadius]}
      data-id={id}
      onClick={doClick}
      onKeyDown={noop}
      tabIndex={0}
      role="button"
      {...props}
    >
      <div css={styles.userInfo}>
        <SquareAvatar
          imgUrl={avatar}
          name={name}
          width={48}
          height={48}
          includeInitials
        />

        <div css={styles.infoWrapper}>
          <div
            className="attendeeName"
            css={styles.name(isSelected)}
          >
            {name}
          </div>
          {surveyId && canRecall && (
          <LeadrButton
            css={styles.saveButton}
            data-test-id="surveysRecall"
            size="mini"
            color="primary"
            variant="ghost"
            onClick={() => recallAttendee(id)}
          >
            Recall
          </LeadrButton>
          )}
        </div>
        <div
          className="iconMagic"
          css={styles.removeIcon(isSelected)}
        >
          {(isSelected ? renderRemoveIcon() : null)}
        </div>
      </div>
    </div>
  </Tooltip>
);

View.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  doClick: PropTypes.func.isRequired,
  avatar: PropTypes.string,
  isSelected: PropTypes.bool,
  isAttendeeSelected: PropTypes.func,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.string,
  renderRemoveIcon: PropTypes.func.isRequired,
  removeIcon: PropTypes.bool,
  hideHover: PropTypes.bool,
  recallAttendee: PropTypes.func.isRequired,
  surveyId: PropTypes.string,
  canRecall: PropTypes.bool,
};

View.defaultProps = {
  avatar: '',
  isSelected: false,
  onClick: null,
  isAttendeeSelected: null,
  isDisabled: false,
  removeIcon: false,
  tooltip: null,
  hideHover: false,
  surveyId: null,
  canRecall: true,
};

const AttendeeItem = ({
  id,
  isAttendeeSelected,
  onClick,
  isDisabled = false,
  tooltip = null,
  surveyId = null,
  recallAttendee = noop,
  canRecall = true,
  ...props
}) => {
  const attendee = usePeoplePickerById({ id });

  const doClick = () => {
    if (onClick && !isDisabled) {
      onClick(id, isAttendeeSelected?.(id));
    }
  };
  const renderRemoveIcon = () => (
    <div>
      <FontAwesomeIcon
        type="icon"
        icon={faTimes}
      />
    </div>
  );

  const isSelected = isAttendeeSelected && isAttendeeSelected(id);

  // If a tooltip is passed from disableUsers or some other means, its likely a priority case and should take precedence. Otherwise fallback on generic Remove behavior
  // Remove behavior should itself be a special case ideally but meh
  const selectedTooltip = isSelected ? 'Remove Participant' : null;
  const tooltipToUse = tooltip || selectedTooltip;

  if (!attendee?.firstName) {
    return null;
  }

  const hookProps = {
    id,
    attendee,
    name: `${attendee?.firstName} ${attendee?.lastName}`,
    avatar: attendee?.profileImageUrl,
    isSelected,
    isDisabled,
    tooltip: tooltipToUse,
    doClick,
    renderRemoveIcon,
    recallAttendee,
    surveyId,
    canRecall,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

const AttendeeItemSkeleton = () => (
  <Skeleton
    variant="rectangular"
    animation={skeletonGlobalAnimation}
    css={styles.skeleton}
    height={ATTENDEE_HEIGHT}
  />
);

AttendeeItem.propTypes = {
  id: PropTypes.string.isRequired,
  isAttendeeSelected: PropTypes.func,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  tooltip: PropTypes.string,
  surveyId: PropTypes.string,
  recallAttendee: PropTypes.func,
  canRecall: PropTypes.bool,
};

AttendeeItem.defaultProps = {
  onClick: null,
  isAttendeeSelected: null,
  isDisabled: false,
  tooltip: null,
  surveyId: null,
  recallAttendee: noop,
  canRecall: true,
};

export { View, AttendeeItemSkeleton, attendeeItemBorderRadius };
export default AttendeeItem;
