import {
  UseFormReturn,
} from 'react-hook-form';
import { RECOGNITION_RECIPIENT_LIMIT } from '~Recognition/const/defaults';
import { RecognitionShape } from '~Recognition/const/types';
import {
  FormValues,
} from '~Recognition/schemata/CreateRecognitionSchemata';
import Froala from '~Common/V3/components/Froala';
import { AutoCompleteTags } from '~Common/V3/components/uncontrolled/AutocompleteTags';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import RequiredIcon from '~Common/V3/components/RequiredIcon';
import { useEffect, useMemo } from 'react';
import { useGetPeopleByList } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { useSelectParticipants } from '~Common/hooks/useSelectParticipants';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';

const styles = {
  formInput: css({
    marginBottom: '.625rem',
  }),
  tagsProfileImage: css({
    marginRight: '.5rem',
  }),
  autocompleteOptionText: css({
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  }),
  jobtitle: css({
    marginLeft: 'auto',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
};

type SelectOption = {
  label: string,
  value: string,
  profileImage: string | undefined,
  jobTitle: string | undefined,
};

interface ViewProps {
  formContext: UseFormReturn<FormValues>,
  recipientList: SelectOption[],
  recognitionDescription?: string,
  initialSelectedRecipientsList?: SelectOption[],
  recipientsSelected: (string | undefined)[] | undefined,
}

const View = ({
  formContext,
  recipientList,
  recognitionDescription,
  initialSelectedRecipientsList,
  recipientsSelected,
}: ViewProps): JSX.Element => (
  <>
    <AutoCompleteTags
      css={styles.formInput}
      multiple
      name="recipients"
      options={recipientList}
      label="Select People"
      defaults={initialSelectedRecipientsList ?? []} // This will pull in the recipients from a created recognition
      getOptionDisabled={(option: SelectOption) => {
        if ((recipientsSelected?.length ?? 0) >= RECOGNITION_RECIPIENT_LIMIT && !recipientsSelected?.some((recipient) => recipient === option.value)) {
          return true;
        }
        return false;
      }}
          // @ts-expect-error MUI is stupid
      renderOption={(optionProps: Record<string, unknown>, option: SelectOption, { selected }) => (
        <li
          {...optionProps}
          key={option.value}
        >
          <div
            css={styles.autocompleteOptionText}
          >
            <CustomCheckbox
              checked={selected as boolean}
            />
            <NewBaseAvatar
              css={styles.tagsProfileImage}
              avatarSize={22}
              name={option.label}
              profileImageUrl={option.profileImage}
            />
            {option.label}
            <span
              css={styles.jobtitle}
            >
              {option.jobTitle}
            </span>
          </div>
        </li>
      )}
    />
    <Froala
      enableEmojis
      required
      label="Message"
      name="content"
      froalaConfigProps={{
        charCounterCount: true,
        charCounterMax: 5000,
      }}
      richTextEditorProps={{
        name: 'content',
        onChange: ({ value: newText }) => formContext.setValue('content', newText, { shouldDirty: true }),
        initialValue: recognitionDescription ?? '',
      }}
      renderRightIcon={() => (
        <RequiredIcon />
      )}
    />
  </>
);

interface CreateRecognitionFormProps {
  editRecognitionDetails: RecognitionShape | undefined,
  formContext: UseFormReturn<FormValues>,
  recipientsSelected: (string | undefined)[] | undefined,
}

const CreateRecognitionForm = ({
  editRecognitionDetails,
  formContext,
  ...props
}: CreateRecognitionFormProps): JSX.Element => {
  const {
    content: recognitionDescription,
    recipients: recipientIds,
  } = editRecognitionDetails ?? {
    content: '',
    recipients: [],
  };

  const {
    allParticipants,
  } = useSelectParticipants({
    useOrgIds: true,
    allowSelf: false,
    selectedFilters: [],
    filterIds: [],
  }) as { allParticipants: string[], isLoading: boolean};

  const peopleInfo = useGetPeopleByList({
    selectedIds: allParticipants,
  }) as PersonDisplayInformation[];

  const recipientList = useMemo(() => peopleInfo.map((person) => ({
    label: `${person?.firstName} ${person?.lastName}`,
    value: person?.orgUserId,
    profileImage: person?.profileImageUrl,
    jobTitle: person?.jobTitle,
  })), [peopleInfo]) as SelectOption[];

  const initialSelectedRecipientsList = useMemo(() => recipientIds.map((recipient) => ({
    label: `${recipient.firstName} ${recipient.lastName}`,
    value: recipient.orgUserId,
    profileImage: recipient.profileImageUrl,
    jobTitle: '',
  })), [recipientIds]);

  // Because forala is dumb and the async nature of the form, we need to set the value of the form if we have it from the API
  // We're setting it to dirty so the user has to interact with the form to save it
  useEffect(() => {
    formContext.setValue('content', recognitionDescription ?? '', { shouldDirty: false });
  }, [formContext, recognitionDescription]);

  const hookProps = {
    formContext,
    recipientList,
    recognitionDescription,
    initialSelectedRecipientsList,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default CreateRecognitionForm;
