import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { useDispatch } from 'react-redux';
import { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { RecognitionUserShape } from '~Recognition/const/types';

const styles = {
  avatarSection: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexBasis: '90%',
  }),
  avatarWrap: (lessThanTwoPeople: boolean) => css({
    display: 'flex',
    alignItems: 'center',
  }, !lessThanTwoPeople && {
    ':nth-child(-n+2)': {
      paddingRight: '1.5rem',
    },
  }),
};

interface ViewProps extends ComponentProps<'div'>{
  usersInfo: RecognitionUserShape[],
  numberOfUsers: number,
  numberOfUsersToShow: number,
  avatarHeight?: number,
  avatarWidth?: number,
  showOverflowAvatarNumber: number,
  renderAvatar: (avatarProps: { user: RecognitionUserShape, avatarHeight: number, avatarWidth: number }) => JSX.Element,
  renderOverflowAvatar: (overflowAvatarProps: OverflowAvatarProps) => JSX.Element,
  onPersonClick: (orgUserId: string) => void,
}

const View = ({
  usersInfo,
  numberOfUsers,
  numberOfUsersToShow,
  avatarHeight = 40,
  avatarWidth = 40,
  renderAvatar,
  renderOverflowAvatar,
  showOverflowAvatarNumber,
  onPersonClick,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.avatarSection} {...props}>
    {usersInfo.map((user, index) => (
      <>
        <div
          key={user.orgUserId}
          css={styles.avatarWrap(numberOfUsers > 3)}
          onClick={() => onPersonClick(user.orgUserId)}
          onKeyDown={() => onPersonClick(user.orgUserId)}
          tabIndex={index}
          role="button"
        >
          {/* Shows the avatar, first name, and last name */}
          {numberOfUsers <= 2 && (
            <>
              {renderAvatar({
                user,
                avatarHeight,
                avatarWidth,
              })}
              {`${user.firstName} ${user.lastName}`}
            </>
          )}
          {/* Shows the avatar, first name, and last initial */}
          {numberOfUsers === numberOfUsersToShow && (
            <>
              {renderAvatar({
                user,
                avatarHeight,
                avatarWidth,
              })}
              {`${user.firstName} ${user.lastName.charAt(0)}.`}
            </>
          )}
          {/* Shows the avatar and the overage avatar to save space */}
          {numberOfUsers > numberOfUsersToShow && index <= showOverflowAvatarNumber && (
            <>
              {renderAvatar({
                user,
                avatarHeight,
                avatarWidth,
              })}
            </>
          )}
        </div>
      </>
    ))}
    {numberOfUsers > showOverflowAvatarNumber && (
    <>
      {renderOverflowAvatar({
        overflowNumber: numberOfUsers - (showOverflowAvatarNumber + 1), // Adding 1 since the overflow card will take the spot that the last person would have had
        avatarHeight,
        avatarWidth,
      })}
    </>
    )}
  </div>
);

interface CardAvatarsProps extends Omit<ViewProps, 'usersToShow' | 'onPersonClick'> {
  usersInfo: RecognitionUserShape[],
  showOverflowAvatarNumber: number,
}

const CardAvatars = ({
  usersInfo,
  numberOfUsers,
  numberOfUsersToShow,
  showOverflowAvatarNumber,
  ...props
}: CardAvatarsProps): JSX.Element => {
  const dispatch = useDispatch();
  const onPersonClick = (orgUserId: string): void => {
    dispatch(
      pushDrawerAction({
        drawer: {
          ...baseballCardDrawerTemplate,
          args: {
            id: orgUserId,
          },
        },
      }),
    );
  };

  const hookProps = {
    usersInfo,
    numberOfUsers,
    numberOfUsersToShow,
    showOverflowAvatarNumber,
    onPersonClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default CardAvatars;
