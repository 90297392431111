import { Dispatch, SetStateAction, useState } from 'react';
import { css } from '@emotion/react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { inputBackgrounds, palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';

import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { Form, TextField } from '~Common/V3/components/uncontrolled';
import Froala from '~Common/V3/components/Froala';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import QuestionPreview from '~Reviews/V2/Shared/QuestionPreview';
import { conformToDtoMatrixOrg, FormValuesMatrixOrg } from '~Reviews/V2/schemata/CreateTopicForCycleMatrixOrgs';
import { FormValues as EmbeddedFormValues } from '~Reviews/V2/schemata/CreateTopicForCycle';
import AddQuestion from '~Reviews/V2/Shared/AddQuestion';
import { ParticipantTypeEnum, TopicShape } from '~Reviews/V2/Const/types';
import { DEFAULT_TOPIC } from '~Reviews/V2/Const/defaults';
import { useTopicFormContext } from '~Reviews/V2/Hooks/useTopicFormContext';
import {
  CreateReviewTemplateDTO, ReviewTemplate, ReviewTemplateQuestion, useCreateReviewTemplate, useUpdateReviewTemplate,
} from '../useReviewTemplates';
import { reviewTemplatesListTemplate } from '../ReviewTemplateDrawer';
import { editReviewTemplateFormResolver, FormValues } from './EditReviewTemplateFormSchema';

export const editReviewTemplatesTemplate = {
  name: 'REVIEW_TEMPLATES_EDIT',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  addQuestionButton: css({
    marginTop: '1rem',
  }),
  button: css(forMobileObject({
    width: '100%',
  })),
  buttonContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75rem',
    padding: '0.5rem 1.5rem 0.5rem 1rem',
  }, forMobileObject({
    flexDirection: 'column',
  })),
  dragDrop: css({
    flexWrap: 'wrap',
    '& > div': {
      backgroundColor: inputBackgrounds.rgba,
    },
  }),
  drawerBody: css({
    padding: '1rem',
    paddingRight: '1.5rem',
  }),
  heading: css({
    color: palette.neutrals.gray500,
    fontSize: '0.625rem',
    letterSpacing: '0.2em',
    marginBottom: '0.625rem',
    marginTop: '1.125rem',
    textTransform: 'uppercase',
  }),
  input: css({
    marginBottom: '0.5rem',
    width: '100%',
  }),
  removePadding: css({
    padding: 0,
  }),
  questionList: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.5rem',
  }),
};

function defaultValues(initialTemplate?: ReviewTemplate): FormValues | Record<string, never> {
  if (!initialTemplate) return {};

  const {
    name,
    description,
    topics,
  } = initialTemplate;

  return {
    name,
    description,
    topics: topics.map((question, index) => ({
      ...question,
      rank: question.rank ?? index,
      typeEnum: question.typeEnum,
    })),
  };
}

interface ViewProps {
  addingQuestion: boolean,
  closeDrawerClick: () => void,
  editedQuestion: string,
  embeddedFormContext: UseFormReturn<EmbeddedFormValues | FormValuesMatrixOrg>,
  formContext: UseFormReturn<FormValues>,
  isEditing: boolean,
  isMutationLoading: boolean,
  onAddQuestion: SubmitHandler<FormValuesMatrixOrg>,
  onDeleteQuestion: (props: { topicId: string }) => void,
  onSave: SubmitHandler<FormValues>,
  setAddingQuestion: Dispatch<SetStateAction<boolean>>
  setEditedQuestion: Dispatch<SetStateAction<string>>,
  submitEditedQuestion: SubmitHandler<EmbeddedFormValues | FormValuesMatrixOrg>,
}

export const View = ({
  addingQuestion,
  closeDrawerClick,
  editedQuestion,
  embeddedFormContext,
  formContext,
  isEditing,
  isMutationLoading,
  onAddQuestion,
  onDeleteQuestion,
  onSave,
  setAddingQuestion,
  setEditedQuestion,
  submitEditedQuestion,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title={isEditing ? 'Edit Review Template' : 'Create Review Template'}
        renderCloseButton={(closeButtonStyles) => (
          <IconButton
            css={closeButtonStyles}
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            tooltip="Close"
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <Form
          formContext={formContext}
          onSubmit={() => null}
        >
          <TextField
            css={styles.input}
            name="name"
            required
          />
          <Froala
            enableEmojis
            label="Description"
            name="description"
            richTextEditorProps={{
              name: 'description',
              initialValue: formContext.getValues().description,
              onChange: ({ value: newValue }) => formContext.setValue('description', newValue),
            }}
          />
          <QuestionPreview
            formContext={embeddedFormContext}
            topics={formContext.watch('topics')}
            isQuestions
            cycleId=""
            onFormSubmit={submitEditedQuestion}
            updateQuestionView={() => setEditedQuestion('')}
            setEditQuestion={setEditedQuestion}
            editQuestion={editedQuestion}
            onDeleteQuestion={onDeleteQuestion}
          />
          {addingQuestion && (
            <AddQuestion
              participationEnum={ParticipantTypeEnum.Everyone}
              onFormSubmit={(data) => { onAddQuestion(data as FormValuesMatrixOrg); }}
              updateQuestionView={setAddingQuestion}
              topic={DEFAULT_TOPIC}
              editQuestion=""
              inDrawer
            />
          )}
          {!addingQuestion && editedQuestion === '' && (
            <LeadrButton
              variant="ghost"
              size="small"
              css={styles.addQuestionButton}
              onClick={() => { embeddedFormContext.reset(); setAddingQuestion(true); }}
              data-test-id="reviewsAddTemplateQuestion"
            >
              + Add Question
            </LeadrButton>
          )}
        </Form>
      </div>
    )}
    renderFooter={() => (addingQuestion || editedQuestion ? <></> : (
      <div css={styles.buttonContainer}>
        <SpinnerButton
          css={styles.button}
          color="secondary"
          isLoading={isMutationLoading}
          disabled={!formContext.watch('topics')?.length}
          label="Save Changes"
          loadingLabel="Saving..."
          onClick={formContext.handleSubmit(onSave)}
        />
        <LeadrButton
          css={styles.button}
          variant="ghost"
          onClick={closeDrawerClick}
          data-test-id="reviewsCancelTemplate"
        >
          Cancel
        </LeadrButton>
      </div>
    ))}
  />
);

interface EditReviewTemplateDrawerProps {
  initialTemplate?: ReviewTemplate,
  isEditing?: boolean,
}

export const EditReviewTemplateDrawer = ({
  initialTemplate,
  isEditing = false,
}: EditReviewTemplateDrawerProps): JSX.Element => {
  const formContext = useForm<FormValues>({
    defaultValues: defaultValues(initialTemplate),
    resolver: editReviewTemplateFormResolver,
  });

  const dispatch = useDispatch();

  const { mutateAsync: updateTemplateMutation, isPending: isUpdateMutationLoading } = useUpdateReviewTemplate();
  const { mutateAsync: createTemplateMutation, isPending: isCreateMutationLoading } = useCreateReviewTemplate();

  const isMutationLoading = isUpdateMutationLoading || isCreateMutationLoading;

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...reviewTemplatesListTemplate,
      },
    }));
  };

  const onSave: SubmitHandler<FormValues> = async (data) => {
    if (initialTemplate?.uid) {
      await updateTemplateMutation({ ...initialTemplate, ...data });
    } else {
      await createTemplateMutation(data as CreateReviewTemplateDTO);
    }
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...reviewTemplatesListTemplate,
      },
    }));
  };

  const [addingQuestion, setAddingQuestion] = useState(false);
  const onAddQuestion = (data: EmbeddedFormValues | FormValuesMatrixOrg): void => {
    const topics = (formContext.getValues().topics ?? []) as ReviewTemplateQuestion[];

    const newQuestion = {
      ...conformToDtoMatrixOrg(data as FormValuesMatrixOrg),
      uid: `_:REVIEW_TOPIC_${topics.length + 1}`,
    };

    formContext.setValue('topics', [...topics, newQuestion]);
    setAddingQuestion(false);
  };

  const onDeleteQuestion = ({ topicId }: { topicId: string; }): void => {
    const { topics } = formContext.getValues();
    formContext.setValue('topics', (topics as ReviewTemplateQuestion[]).filter((topic) => topic.uid !== topicId));
  };

  const [editedQuestion, setEditedQuestion] = useState<string>('');
  const { formContext: embeddedFormContext } = useTopicFormContext({
    topic: (formContext.getValues('topics') as TopicShape[])?.find((topic) => topic.uid === editedQuestion),
  });

  const submitEditedQuestion: SubmitHandler<EmbeddedFormValues | FormValuesMatrixOrg> = (data): void => {
    const topics = (formContext.getValues().topics ?? []) as ReviewTemplateQuestion[];
    const updatedQuestion = {
      uid: editedQuestion,
      ...conformToDtoMatrixOrg(data as FormValuesMatrixOrg),
    };
    const previousIndex = topics.findIndex((topic) => topic.uid === editedQuestion);
    setEditedQuestion('');

    if (previousIndex === -1) {
      return;
    }

    topics[previousIndex] = updatedQuestion as ReviewTemplateQuestion;
    formContext.setValue('topics', topics);
  };

  const hookProps = {
    addingQuestion,
    closeDrawerClick,
    editedQuestion,
    embeddedFormContext,
    formContext,
    isEditing,
    isMutationLoading,
    onAddQuestion,
    onDeleteQuestion,
    onSave,
    setAddingQuestion,
    setEditedQuestion,
    submitEditedQuestion,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: editReviewTemplatesTemplate.name,
  component: EditReviewTemplateDrawer,
});
