import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { ReflectionAgendaItemDetail } from '../types';

const getAgendaTopic = ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<ReflectionAgendaItemDetail>> => {
  const [agendaTopicId] = queryKey.slice(-1);

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/huddles/agendas/${agendaTopicId}`;

  return getApi<ReflectionAgendaItemDetail>(serverUrl);
};

interface UseGetAgendaItemReflectionDetailsReturn {
  isLoading: boolean,
  reflection?: ReflectionAgendaItemDetail,
}

interface UseGetAgendaTopicProps {
  agendaTopicId: string,
}

export function useGetAgendaTopic({
  agendaTopicId,
}: UseGetAgendaTopicProps): UseGetAgendaItemReflectionDetailsReturn {
  const organizationUid = getOrganizationId() ?? '';
  const queryKey = [organizationUid, 'meetings', 'agenda', agendaTopicId];

  const result = useQuery({
    queryKey,
    queryFn: getAgendaTopic,
  });

  return {
    isLoading: result?.isLoading,
    reflection: result?.data?.response,
  };
}
