import { WhatsDueItem } from '~Home/const/interfaces';
import { useTransformSurveys } from '~Home/hooks/useTransformSurveys';
import { useTransformReviews } from '~Home/hooks/useTransformReviews';
import { useTransformFeedbackItems } from '~Home/hooks/useTransformFeedbackItems';
import { useTransformActionItems } from '~Home/hooks/useTransformActionItems';
import { useTransformLearning } from './useTransformLearning';
import { useHomeSettings } from './useHomeSettings';

interface UseGetAllDueItemsReturn {
  allDueItems: WhatsDueItem[],
  loading: boolean,
}

export const useGetAllDueItems = ():UseGetAllDueItemsReturn => {
  // action items
  const allDueItems:WhatsDueItem[] = [];
  const allLoadingItems:boolean[] = [];

  const { isLoading: areHomeSettingsLoading, data: homeSettingsData } = useHomeSettings();
  const { isLoading: areActionItemsLoading, data: transformedActionItems } = useTransformActionItems();
  const { isLoading: areFeedbacksLoading, data: transformedFeedback } = useTransformFeedbackItems();
  const { isLoading: areSurveysLoading, data: transformedSurveys } = useTransformSurveys();
  const { isLoading: areLearningsLoading, data: transformedLearning } = useTransformLearning();
  const { isLoading: areReviewsLoading, data: transformedReviews } = useTransformReviews();

  allLoadingItems.push(areHomeSettingsLoading);
  allLoadingItems.push(areActionItemsLoading);
  allLoadingItems.push(areFeedbacksLoading);
  allLoadingItems.push(areLearningsLoading);
  allLoadingItems.push(areReviewsLoading);

  if (homeSettingsData?.response) {
    const { whatsDue } = homeSettingsData.response;

    if (whatsDue.actionItems) {
      allDueItems.push(...(transformedActionItems ?? []));
    }

    if (whatsDue.feedback) {
      allDueItems.push(...(transformedFeedback ?? []));
    }

    if (whatsDue.surveys) {
      allDueItems.push(...(transformedSurveys ?? []));
      allLoadingItems.push(areSurveysLoading);
    }

    if (whatsDue.learning) {
      allDueItems.push(...(transformedLearning ?? []));
    }

    if (whatsDue.reviews) {
      allDueItems.push(...(transformedReviews ?? []));
    }
  }

  const sortedAllDueItems = [...allDueItems];

  sortedAllDueItems.sort((allDueItemOne, allDueItemTwo) => {
    const dateOneProperty = allDueItemOne.dueDate;
    const dateTwoProperty = allDueItemTwo.dueDate;

    if (!dateOneProperty) {
      return 1;
    }

    // Null date for Learning 2
    if (!dateTwoProperty) {
      return -1;
    }

    // Dates are the same
    if (dateOneProperty === dateTwoProperty) {
      return 0;
    }

    // Earliest dates are first
    return dateOneProperty.valueOf() < dateTwoProperty.valueOf() ? -1 : 1;
  });
  return {
    loading: allLoadingItems.some((item) => item),
    allDueItems: sortedAllDueItems,
  };
};
