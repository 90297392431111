import { css } from '@emotion/react';
import ClampLines from '~Common/components/ClampLines';
import { palette } from '~Common/styles/colors';
import { SKELETON_STYLES } from '~Goals/const/styles';
import { Skeleton } from '@mui/material';

const styles = {
  ...SKELETON_STYLES,
  descriptionTitle: css({
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
    fontWeight: 400,
  }),
  descriptionText: css({
    fontWeight: 500,
    fontSize: '1rem',
    color: palette.neutrals.gray700,
  }),
  goalDescriptionClamp: css({
    fontWeight: 500,
    color: palette.neutrals.gray800,
    margin: '.5rem 0',
    fontSize: '1rem',

    '& button': {
      border: 'none',
      background: 'none',
      color: palette.brand.indigo,
      fontWeight: 500,
      padding: '0',
      float: 'right',
      fontSize: '.875rem',
    },
    '& .readonlyInputStyles': {
      background: 'none !important',
    },
  }),
  descriptionSkelly: css({
    height: '9.375rem',
  }),
};

interface ViewProps {
  text: string,
  showSkeleton: boolean,
}

const View = ({
  text,
  showSkeleton,
}: ViewProps): JSX.Element => (
  <>
    <div>
      <span css={styles.descriptionTitle}>Description</span>
      {showSkeleton && (
        <Skeleton
          variant="text"
          animation="wave"
          css={[styles.descriptionSkelly, styles.allowProperHeight]}
        />
      )}
      {!showSkeleton && (
        <ClampLines
          text={text}
          id="goal-description"
          testId="goal-description"
          lines={4}
          ellipsis="..."
          moreText="Show More"
          lessText="Show Less"
          emotionStyle={styles.goalDescriptionClamp}
        />
      )}
    </div>
  </>
);

interface GoalDescriptionProps {
  text: string,
  showSkeleton: boolean,
}

export const GoalDescription = ({
  text,
  showSkeleton,
}: GoalDescriptionProps): JSX.Element => {
  const hookProps = {
    text,
    showSkeleton,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
