import {
  ReviewCycleDetail, RevieweeReviewStatuses, ManagerReviewStatuses, SecondaryManagerReviewStatuses, ParticipantTypeEnumConversion,
} from '~Reviews/V2/Const/types';
import {
  checkReviewCycleForIsManager,
  returnParticipantTypeEnum,
} from '~Reviews/V2/Hooks/useReturnReviewCycleParticipantType';

export const useReturnUnfinishedReviews = (reviewCycles: ReviewCycleDetail[]): ReviewCycleDetail[] => {
  const unfinishedReviewsCycles = reviewCycles.reduce((relevantCycles: ReviewCycleDetail[], cycle: ReviewCycleDetail) => {
    const { isManager, isSecondaryManager } = checkReviewCycleForIsManager(cycle);
    const participantTypeEnum = returnParticipantTypeEnum(isSecondaryManager, isManager);
    const incompleteReviewsForACycle = cycle.reviews.filter((review) => {
      if (participantTypeEnum === ParticipantTypeEnumConversion.Reviewee) {
        return review.revieweeStatus !== RevieweeReviewStatuses.Complete;
      } if (participantTypeEnum === ParticipantTypeEnumConversion.Reviewer) {
        return review.reviewerStatus !== ManagerReviewStatuses.Complete;
      } if (participantTypeEnum === ParticipantTypeEnumConversion.SecondaryReviewer) {
        return review.secondaryReviewerStatus !== SecondaryManagerReviewStatuses.Complete;
      }
      return false;
    });
    if (incompleteReviewsForACycle.length > 0) {
      relevantCycles.push(cycle);
    }
    return relevantCycles;
  }, []);
  return unfinishedReviewsCycles;
};
