import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { postApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

export interface nudgeReviewParticipantsProps {
  cycleId: string | undefined,
  reviewIDs: string[],
}

const nudgeReviewParticipantsProps = ({ cycleId, reviewIDs }: nudgeReviewParticipantsProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${cycleId ?? ''}/nudge`,
  };

  return postApi(serverUrl, reviewIDs, {});
};

export const useNudgeParticipants = (): UseMutateFunction<HttpCallReturn<void>, unknown, nudgeReviewParticipantsProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: nudgeReviewParticipantsProps,
    onMutate: () => {
      toastId.current = toast.info('Nudging the participant(s)...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error nudging the participant(s). Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully nudged the participant(s).',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviews'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), getOrganizationUserId(), 'reviewCycles'] });
    },
  });

  return mutation.mutate;
};
