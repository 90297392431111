import { ResourceType, TabItem } from '~DevelopmentPlan/const/types';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { ChangeEvent } from 'react';
import { useAddResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import {
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import {
  FormValues, conformToDto, createGoalFormResolver, createGoalFormSchema,
} from '~Goals/schemata/CreateGoalSchemata';
import { DEFAULT_GOAL, DEFAULT_TEAM_ID } from '~Goals/const/defaults';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { CreateGoalResponse, useCreateGoal } from '~Goals/hooks/useCreateGoal';
import { toast } from 'react-toastify';
import { GoalShape, ValidationErrors } from '~Goals/const/types';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { existingResourceStyles } from '~DevelopmentPlan/const/pageStyles';
import moment from 'moment';
import { NewGoal } from './NewGoal';
import { ExistingGoal } from './ExistingGoal';

const styles = {
  ...existingResourceStyles,
};

interface ViewProps {
  showNewView: boolean,
  searchText: string,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  actionTextToUse: string,
  formContext: UseFormReturn<FormValues>,
  goal: GoalShape,
  runGoalValidations: () => void,
  runAddResourceValidations: (resourceIdClicked: ResourceType, contentId: string | number) => void,
  planDateString: string,
  closeAddResourceModal: () => void,
}

const View = ({
  showNewView,
  searchText,
  handleSearchTextChange,
  actionTextToUse,
  formContext,
  goal,
  runGoalValidations,
  runAddResourceValidations,
  planDateString,
  closeAddResourceModal,
}: ViewProps): JSX.Element => (
  <>
    {!showNewView && (
    <>
      {/* The goal here is to use this for all the modals - I'd like to update this to pass in the components below into this file if this works out */}
      <LeadrSearchField
        data-test-id="addResourceSearchField"
        defaultValue={searchText}
        onChange={handleSearchTextChange}
        css={styles.searchField}
      />
      <div
        css={styles.planDateString}
      >
        {planDateString}
      </div>
    </>
    )}
    <div css={styles.resourceWrapper}>
      {showNewView && (
      <NewGoal
        formContext={formContext}
        goal={goal}
        runGoalValidations={runGoalValidations}
        actionTextToUse={actionTextToUse}
        onCancel={closeAddResourceModal}
      />
      )}
      {!showNewView && (
      <ExistingGoal
        runAddResourceValidations={runAddResourceValidations}
        actionTextToUse={actionTextToUse}
      />
      )}
    </div>
  </>
);

interface GoalModalBodyProps {
  activeTab: number,
  tabsToUse: Record<string, TabItem>,
  runAddResourceValidations: (resourceIdClicked: ResourceType, contentId: string | number) => void,
  actionTextToUse: string,
}

export const GoalModalBody = ({
  activeTab,
  tabsToUse,
  runAddResourceValidations,
  actionTextToUse,
}: GoalModalBodyProps): JSX.Element => {
  const foundKey = Object.keys(tabsToUse).find((key) => tabsToUse[key].value === activeTab);
  const showNewView = foundKey?.toLocaleLowerCase() === 'new';

  const {
    searchText,
    setSearchText,
    planStartDate,
    planDueDate,
    setResourceContentDueDate,
    setResourceContentTitle,
    closeAddResourceModal,
  } = useAddResourceModalStore((state) => ({
    searchText: state.searchText,
    setSearchText: state.setSearchText,
    planStartDate: state.planStartDate,
    planDueDate: state.planDueDate,
    setResourceContentDueDate: state.setResourceContentDueDate,
    setResourceContentTitle: state.setResourceContentTitle,
    closeAddResourceModal: state.closeAddResourceModal,
  }));

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const planDateString = `Goals between ${moment(planStartDate).format('MMM D')} - ${moment(planDueDate).format('MMM D')}`;

  const goal = DEFAULT_GOAL;
  const orgUserId = getOrganizationUserId() ?? '';
  function defaultValues(): FormValues {
    const {
      title,
      description,
      participants,
      priority,
      context,
      category,
      externalLink,
      isPrivate,
      startTimeInMillis,
      endTimeInMillis,
    } = goal;

    return {
      title,
      description,
      participants,
      priority,
      context,
      category,
      externalLink,
      isPrivate,
      startTimeInMillis,
      endTimeInMillis,
      owner: orgUserId,
      type: goal.context.contextType,
      team: DEFAULT_TEAM_ID,
    };
  }

  const formContext = useForm<FormValues>({
    defaultValues: defaultValues(),
    resolver: createGoalFormResolver,
  });

  const { mutate: createGoalMutation } = useCreateGoal();

  const setupAddResourceValidations = (data: HttpCallReturn<CreateGoalResponse>): void => {
    const { goalId } = data.response;
    const resourceId = ResourceType.Goal;
    const contentId = goalId;

    runAddResourceValidations(resourceId, contentId);
  };

  const runGoalValidations = (): void => {
    const data = formContext.getValues();
    createGoalFormSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        const formData = conformToDto(data);

        const { endTimeInMillis } = formData;
        setResourceContentDueDate(moment(endTimeInMillis).toDate());
        setResourceContentTitle(formData.title);
        createGoalMutation({ goal: formData }, { onSuccess: setupAddResourceValidations });
      })
      .catch((err: ValidationErrors) => {
        err.errors.forEach((error) => {
          toast.error(error);
        });
      });
  };

  const hookProps = {
    showNewView,
    handleSearchTextChange,
    searchText,
    actionTextToUse,
    formContext,
    goal,
    runGoalValidations,
    runAddResourceValidations,
    planDateString,
    closeAddResourceModal,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default GoalModalBody;
