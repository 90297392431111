import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from '@emotion/react';
import {
  faBooks,
  faBriefcase,
  faBullseyeArrow,
  faQuestion,
  faBookmark,
  faCircleInfo,
  faPartyHorn,
  faStar,
  IconDefinition,
  faCommentsAlt,
} from '@fortawesome/pro-light-svg-icons';
import { faBookmark as faSolidBookMark, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { GridColumns, GridComparatorFn } from '@mui/x-data-grid';
import moment from 'moment';
import { ReflectionTypeEnum, ReflectionsUserRowsProps } from '../types';

const styles = {
  tableIcon: css({
    marginRight: '0.5rem',
  }),
  checkboxIcon: css({
    color: `${palette.neutrals.gray700} !important`,
    fontSize: '1rem',
  }),
  checkboxIconSolid: css({
    color: `${palette.brand.indigo} !important`,
  }),
  circleIconActive: css({
    color: `${palette.brand.green} !important`,
  }),
  circleIconInActive: css({
    color: `${palette.neutrals.gray600} !important`,
  }),
  infoIcon: css({
    color: `${palette.neutrals.gray600} !important`,
    margin: '0 0.5rem',
  }),
  ownedByWrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  tableHeader: css({
    fontWeight: 600,
  }),
};

export function getActiveReviewDisplay(params: { row: { isInActiveReview?: boolean }; }): JSX.Element {
  const { isInActiveReview } = params.row;

  if (isInActiveReview) {
    return (
      <>
        <FontAwesomeIcon
          icon={faCircle}
          css={[styles.tableIcon, styles.circleIconActive]}
        />
        Active Review
      </>
    );
  }
  return (
    <>
      <FontAwesomeIcon
        icon={faCircle}
        css={[styles.tableIcon, styles.circleIconInActive]}
      />
      Not Active
    </>
  );
}

export function getReflectionIcon(type: ReflectionTypeEnum): IconDefinition {
  switch (type) {
    case ReflectionTypeEnum.Accomplishment:
      return faStar;
    case ReflectionTypeEnum.BookmarkedTopic:
      return faBriefcase;
    case ReflectionTypeEnum.Feedback:
      return faCommentsAlt;
    case ReflectionTypeEnum.Goal:
      return faBullseyeArrow;
    case ReflectionTypeEnum.Learning:
      return faBooks;
    case ReflectionTypeEnum.Recognition:
      return faPartyHorn;
    default:
      return faQuestion;
  }
}

export function getReflectionText(type: ReflectionTypeEnum): string {
  switch (type) {
    case ReflectionTypeEnum.Accomplishment:
      return 'Accomplishment';
    case ReflectionTypeEnum.BookmarkedTopic:
      return 'Agenda Topic';
    case ReflectionTypeEnum.Feedback:
      return 'Feedback';
    case ReflectionTypeEnum.Goal:
      return 'Goal';
    case ReflectionTypeEnum.Learning:
      return 'Learning';
    case ReflectionTypeEnum.Recognition:
      return 'Recognition';
    default:
      return 'Reflection';
  }
}

export const getFullName = (params: { row: { ownedBy: { name: string } } }): string => `${params.row.ownedBy.name || ''}`;
export const getContentTypeDisplay = (params: { row: { contentTypeId: number } }): JSX.Element => {
  const { contentTypeId } = params.row;
  const icon = getReflectionIcon(contentTypeId as ReflectionTypeEnum);
  const text = getReflectionText(contentTypeId as ReflectionTypeEnum);

  return (
    <>
      <FontAwesomeIcon
        icon={icon}
        css={styles.tableIcon}
      />
      {text}
    </>
  );
};

export const renderIsFavorite = (params: { row: { isFavorite: boolean }; }): JSX.Element => {
  const { isFavorite } = params.row;
  if (isFavorite) {
    return (
      <FontAwesomeIcon
        icon={faSolidBookMark}
        css={[styles.checkboxIcon, styles.checkboxIconSolid]}
      />
    );
  }
  return (
    <FontAwesomeIcon
      icon={faBookmark}
      css={styles.checkboxIcon}
    />
  );
};

export const dateComparator: GridComparatorFn<Date> = (v1, v2) => {
  let date1;
  let date2;

  if (typeof v1 === 'string') {
    date1 = moment(v1, 'MMMM Do, YYYY')?.toDate();
  } else {
    date1 = v1 instanceof Date ? v1 : undefined;
  }

  if (typeof v2 === 'string') {
    date2 = moment(v2, 'MMMM Do, YYYY')?.toDate();
  } else {
    date2 = v2 instanceof Date ? v2 : undefined;
  }

  return (date1?.getTime() || 0) - (date2?.getTime() || 0);
};

export const tableColumnHeadersMembers: GridColumns<ReflectionsUserRowsProps> = [
  {
    field: 'isFavorite',
    headerName: '',
    maxWidth: 30,
    flex: 1,
    renderCell: (cellValues) => (
      renderIsFavorite(cellValues)
    ),
  },
  {
    field: 'content',
    headerName: 'Content',
    maxWidth: 2850,
    flex: 1,
  },
  {
    field: 'contentType',
    headerName: 'Type',
    maxWidth: 150,
    renderCell: (cellValues) => (
      getContentTypeDisplay(cellValues)
    ),
    flex: 1,
  },
  {
    field: 'dateCreated',
    headerName: 'Date Created',
    maxWidth: 200,
    flex: 1,
    sortComparator: dateComparator,
  },
  {
    field: 'isInActiveReview',
    maxWidth: 250,
    flex: 1,
    renderCell: (cellValues) => (
      getActiveReviewDisplay(cellValues)
    ),
    renderHeader: () => (
      <Tooltip
        content="The Active Review column indicates if content was automatically loaded into active review cycle."
      >
        <div>
          <span
            css={styles.tableHeader}
          >
            Active Review
          </span>
          <FontAwesomeIcon
            icon={faCircleInfo}
            css={styles.infoIcon}
          />
        </div>
      </Tooltip>
    ),
  },
  {
    field: 'ownedBy',
    headerName: 'Owned By',
    maxWidth: 100,
    flex: 1,
    renderCell: (cellValues: { row: { ownedBy: { image: string; name: string; }; }; }) => (
      <>
        <Tooltip
          content={`${cellValues.row.ownedBy.name}`}
        >
          <div>
            <SquareAvatar
              imgUrl={cellValues.row.ownedBy.image}
              name={`${cellValues.row.ownedBy.name}`}
              width={30}
              height={30}
              includeInitials
            />
          </div>
        </Tooltip>
      </>
    ),
    type: 'object',
    valueGetter: (params: { row: { ownedBy: { name: string; }; }; }) => params.row.ownedBy.name,
  },
  {
    field: 'fullName',
    headerName: 'Full Name',
    maxWidth: 750,
    flex: 1,
    valueGetter: getFullName,
    hide: true,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    maxWidth: 750,
    flex: 1,
    hide: true,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    maxWidth: 750,
    flex: 1,
    hide: true,
  },
];

export const tableColumnHeadersManager: GridColumns<ReflectionsUserRowsProps> = [
  {
    field: 'isFavorite',
    headerName: '',
    maxWidth: 30,
    flex: 1,
    renderCell: (cellValues) => (
      renderIsFavorite(cellValues)
    ),
  },
  {
    field: 'ownedBy',
    headerName: 'Direct Report',
    maxWidth: 225,
    flex: 1,
    renderCell: (cellValues: { row: { ownedBy: { image: string; name: string; }; }; }) => (
      <>
        <Tooltip
          content={`${cellValues.row.ownedBy.name}`}
        >
          <div css={styles.ownedByWrapper}>
            <SquareAvatar
              imgUrl={cellValues.row.ownedBy.image}
              name={`${cellValues.row.ownedBy.name}`}
              width={30}
              height={30}
              margin={12}
              includeInitials
            />
            <div>{cellValues.row.ownedBy.name}</div>
          </div>
        </Tooltip>
      </>
    ),
    type: 'object',
    valueGetter: (params: { row: { ownedBy: { name: string; }; }; }) => params.row.ownedBy.name,
  },
  {
    field: 'content',
    headerName: 'Content',
    maxWidth: 2850,
    flex: 2,
  },
  {
    field: 'contentType',
    headerName: 'Type',
    maxWidth: 150,
    renderCell: (cellValues) => (
      getContentTypeDisplay(cellValues)
    ),
    flex: 1,
  },
  {
    field: 'dateCreated',
    headerName: 'Date Created',
    maxWidth: 200,
    flex: 1,
    sortComparator: dateComparator,
  },
  {
    field: 'fullName',
    headerName: 'Full Name',
    maxWidth: 750,
    flex: 1,
    valueGetter: getFullName,
    hide: true,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    maxWidth: 750,
    flex: 1,
    hide: true,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    maxWidth: 750,
    flex: 1,
    hide: true,
  },
  {
    field: 'isInActiveReview',
    maxWidth: 175,
    flex: 1,
    renderCell: (cellValues) => (
      getActiveReviewDisplay(cellValues)
    ),
    renderHeader: () => (
      <Tooltip
        content="The Active Review column indicates if content was automatically loaded into active review cycle."
      >
        <div>
          <span
            css={styles.tableHeader}
          >
            Active Review
          </span>
          <FontAwesomeIcon
            icon={faCircleInfo}
            css={styles.infoIcon}
          />
        </div>
      </Tooltip>
    ),
  },
];
