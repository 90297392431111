import { Route, Switch } from 'react-router-dom';

import Workbench from '~Workbench/components';

const WorkbenchRouter = () => (
  <Switch>
    {process.env.NODE_ENV === 'development' ? (
      <Route
        exact
        path="/workbench"
        component={Workbench}
      />
    ) : false}
  </Switch>
);

WorkbenchRouter.propTypes = {};

WorkbenchRouter.defaultProps = {};

export default WorkbenchRouter;
