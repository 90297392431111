import { css } from '@emotion/react';
import { Person } from '~Common/const/interfaces';

import Avatar from '~Deprecated/common/Components/People/Avatar';
import { useGetPersonById } from '~Deprecated/hooks/peoplePicker/useNewPeople';

const styles = {
  avatar: css`
    border-radius: .5rem;
  `,
};

interface ViewProps {
  person: Person,
}

const View = ({ person, ...props }: ViewProps): JSX.Element => (
  <Avatar
    width={40}
    height={40}
    imgUrl={person?.profileImageUrl}
    name={`${person?.firstName} ${person?.lastName}`}
    css={styles.avatar}
    {...props}
  />
);

interface LearningAvatarProps {
  userId: string,
}

const LearningAvatar = ({ userId, ...props }: LearningAvatarProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const person: Person = useGetPersonById({ id: userId });

  const hookProps = {
    person,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LearningAvatar;
