import { create } from 'zustand';
import { ViewType } from '~Common/const/interfaces';

interface FeedbackViewTypeStoreState {
  viewType: ViewType,
  setViewType: (nextValue: ViewType) => void,
}

export const useFeedbackViewType = create<FeedbackViewTypeStoreState>((set) => ({
  viewType: ViewType.GRID,
  setViewType: (nextValue: ViewType) => set(() => ({ viewType: nextValue })),
}));
