import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  completedCountText: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
  }),
};

interface CompletedCountTextProps extends ComponentProps<'div'> {
  completedCount: number,
  totalCount: number,
}

const CompletedCountText = ({
  completedCount,
  totalCount,
  ...props
}: CompletedCountTextProps): JSX.Element => (
  <div css={styles.completedCountText} {...props}>
    {`${completedCount} of ${totalCount} Completed`}
  </div>
);

export default CompletedCountText;
