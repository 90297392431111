import { BaseHit } from 'instantsearch.js';
import { HitsRenderState } from 'instantsearch.js/es/connectors/hits/connectHits';
import { useHits } from 'react-instantsearch-hooks-web';

interface RenderComponentParams {
  result: HitsRenderState<BaseHit>,
  hasResults: boolean,
  query: string,
  numberOfHits: number,
}

interface ViewProps extends RenderComponentParams {
  renderComponent: (params: RenderComponentParams) => JSX.Element,
}

const View = ({
  renderComponent,
  result,
  hasResults,
  query,
  numberOfHits,
}: ViewProps): JSX.Element => (
  <>
    {renderComponent?.({
      result,
      hasResults,
      query,
      numberOfHits,
    })}
  </>
);

type SearchHitsHelperProps = Omit<ViewProps, 'result' | 'hasResults' | 'query' | 'numberOfHits'>

const SearchHitsHelper = ({ ...props }: SearchHitsHelperProps): JSX.Element => {
  const result = useHits({
    escapeHTML: false,
  });

  const hasResults = !!result.results?.nbHits && result.results?.nbHits > 0;

  const query = result.results?.query ?? '';

  const numberOfHits = result.results?.nbHits ?? 0;

  const hookProps = {
    result,
    hasResults,
    query,
    numberOfHits,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default SearchHitsHelper;
