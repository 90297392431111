import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { getHost } from '~Deprecated/services/config';
import {
  AgendaSectionPermission,
  AgendaTopicPermission,
  AgendaCommentPermission,
  AgendaType,
  RootAgendaPermission,
} from '@leadr-hr/types';
import { HTMLString } from '~Common/types';

export interface Creator {
  firstName: string,
  lastName: string,
  orgUserId: string,
  profileImageUrl?: string,
}

export interface BaseAgendaItem {
  id: string,
  text: HTMLString,
  type: AgendaType,
  createdInMillis: number,
  lastModifiedInMillis: number,
  creator: Creator,
}

export interface AgendaTopicComment extends BaseAgendaItem {
  type: AgendaType.Comment,
  isPrivate: boolean,
  permissions: AgendaCommentPermission[],
}

export interface Attachment {
  id: number,
  fileName: string,
  fileExtension: string,
  fileDownloadUrl?: string,
  fileSize?: number,
  fileUri?: string,
  fileSource?: string,
}

export interface AgendaTopic extends BaseAgendaItem {
  type: AgendaType.AgendaTopic,
  isPrivate: boolean,
  isRecurring: boolean,
  children: AgendaTopicComment[],
  isBookmarked: boolean,
  isComplete: boolean,
  permissions: AgendaTopicPermission[],
  attachments: Attachment[],

  // Optional
  isInvisible?: boolean,
}

export interface AgendaSection extends BaseAgendaItem {
  type: AgendaType.AgendaSection,
  isRecurring: boolean,
  totalCompletedChildren: number,
  children: AgendaTopic[],
  permissions: AgendaSectionPermission[],
}

export type AgendaItem = AgendaTopic | AgendaSection;

export interface GetAgendaReturn {
  agendas: AgendaItem[],
  maxSections: number,
  hasMaxSections: boolean,
  maxTopics: number,
  hasMaxTopics: boolean,
  maxAttachmentsPerTopic: number,
  permissions: RootAgendaPermission[],
}

const getAgendas = async (
  organizationId: string | null | undefined,
  huddleId: string,
): Promise<HttpCallReturn<GetAgendaReturn>> => {
  const Url = {
    host: getHost('', '2'),
    uri: `/organizations/${organizationId ?? ''}/huddles/${huddleId}/agendas`,
  };

  return getApi<GetAgendaReturn>(Url, {});
};

interface UseAgendaTopicParams {
  huddleId: string,
  enabled?: boolean,
}

const useGetAgendas = ({
  huddleId,
  enabled = true,
}: UseAgendaTopicParams): UseQueryResult<HttpCallReturn<GetAgendaReturn>> => {
  const organizationId = getOrganizationId();

  return useQuery({
    queryKey: meetingKeys.agendaList(huddleId),
    queryFn: () => getAgendas(organizationId, huddleId),
    enabled: enabled && !!huddleId,
  });
};

export default useGetAgendas;
