import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';

import { EngagementScores, ExecutiveEngagementDataForDate } from '~Insights/hooks/useEngagementData';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { CARD_STYLES, DASHBOARD_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import { EngagementCategory, ViewPerspective, SetDisplay } from '~Insights/const/types';
import {
  GraphDataset, GroupedEngagementData, makeDelta, makeEngagementGraphData, makeEngagementGroupings,
} from '~Insights/const/dataUtils';
import { palette } from '~Common/styles/colors';

import { LineGraphChart } from '~Common/components/Charts/LineGraphChart';
import Card from '~Common/V3/components/Card';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';
import { BarChart } from '~Common/components/Charts/BarChart';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import ExpandButton from '../../buttons/ExpandButton';
import InsightCardTitle from '../../InsightCardTitle';
import InsightsDelta from '../../InsightsDelta';
import { SharedProps } from './index';

const styles = {
  dashboard: css({
    ...CARD_STYLES.dashboard,
    padding: '0',
  }),
  ...DASHBOARD_CARD_COMPONENTS,
};

interface DashboardProps extends SharedProps {
  graphData: GraphDataset[],
  groupedData: Record<string, GroupedEngagementData[]>,
  twelveMonthDeltas: Record<keyof EngagementScores, number>,
  surveyDates: string[],
}

const DashboardView = ({
  changeToggleMenu,
  graphData,
  groupedData,
  isMobile,
  setDisplay,
  surveyDates,
  toggleMenuOptions,
  toggleMenuValue,
  twelveMonthDeltas,
  userPerspective,
}: DashboardProps): JSX.Element => (
  <Card
    css={[styles.dashboard, styles.dashboardEngagement]}
    renderContents={() => (
      <>
        {setDisplay !== SetDisplay.Mobile && !isMobile && (
        <div css={styles.dashboardContainer}>
          <div css={styles.main}>
            <div css={styles.header(false)}>
              {!isMobile && (
              <ExpandButton css={styles.expandButton(isMobile)} cardKey="engagement" />
              )}
              <div css={styles.titleAndSubtitle(isMobile)}>
                <InsightCardTitle>Engagement Analysis</InsightCardTitle>
                <p>Hover over data for more information.</p>
              </div>
              {toggleMenuValue !== ViewPerspective.DirectReports && (
                <LeadrToggleTabs
                  value={toggleMenuValue}
                  onChange={(e, newValue) => changeToggleMenu(newValue as ViewPerspective)}
                >
                  {toggleMenuOptions.map((toggleMenuOption) => (
                    <LeadrToggleTabs.TextTab
                      data-test-id={toggleMenuOption['data-test-id']}
                      key={toggleMenuOption.value}
                      text={toggleMenuOption.text}
                      value={toggleMenuOption.value}
                    />
                  ))}
                </LeadrToggleTabs>
              )}
              {toggleMenuValue === ViewPerspective.DirectReports && (
                <span
                  css={styles.fauxToggleTab}
                >
                  Direct Reports
                </span>
              )}
            </div>
            {surveyDates.length === 1 && (
              <BarChart
                data={{
                  labels: graphData.map((slice) => slice.label),
                  datasets: [{
                    data: graphData.map((slice) => slice.data[slice.data.length - 1]?.y),
                    backgroundColor: Object.values(palette.brand),
                  }],
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      max: 5,
                      title: {
                        text: 'Average Score',
                        display: true,
                      },
                    },
                  },
                }}
                tooltipOptions={{
                  callbacks: {
                    // @ts-expect-error : There's something funky about the typing here.
                    footer: (ctx: {
                      dataIndex: number; dataset: { participantInfo: string }
                    }[]) => (graphData[ctx[0].dataIndex].data[0].participantInfo ?? ''),
                  },
                  footerMarginTop: 8,
                }}
              />
            )}
            {surveyDates.length > 1 && (
              <LineGraphChart
                height="20rem"
                datasets={graphData}
                options={{
                  maintainAspectRatio: false,
                  layout: {
                    padding: 0,
                  },
                  scales: {
                    y: {
                      min: 0,
                      max: 5,
                    },
                    x: {
                      ticks: {
                        autoSkip: false,
                      },
                    },
                  },
                }}
                tooltipOptions={{
                  callbacks: {
                    // @ts-expect-error : There's something funky about the typing here.
                    footer: (ctx: { raw: { participantInfo: string }}[]) => ctx[0]?.raw?.participantInfo ?? '',
                  },
                  footerMarginTop: 8,
                }}
              />
            )}
            {!surveyDates.length && (
            <div css={styles.emptyState}>
              <div css={styles.emptyImageContainer}>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <img src={insightsEmptyGraph} alt="Empty graph" />
              </div>
              <p css={styles.emptyStateText}>
                Data will show here as your team answers the engagement survey.
              </p>
            </div>
            )}
          </div>
          <div css={styles.verticalDivider(isMobile)} />
          <div css={[styles.sidebar, styles.dashboardSidebar]}>
            <h3>Last 12 months</h3>
            <p css={styles.explainer}>Percentages represent change over time.</p>
            {(userPerspective === UserPerspective.manager || toggleMenuValue === ViewPerspective.Company || Object.keys(groupedData).length === 0) && (
            <>
              <div>
                <p>Engagement</p>
                <InsightsDelta value={twelveMonthDeltas.overall} />
              </div>
              <div>
                <p>Clarity</p>
                <InsightsDelta value={twelveMonthDeltas.clarity} />
              </div>
              <div>
                <p>Maximization</p>
                <InsightsDelta value={twelveMonthDeltas.maximization} />
              </div>
              <div>
                <p>Rapport</p>
                <InsightsDelta value={twelveMonthDeltas.rapport} />
              </div>
            </>
            )}
            {userPerspective === UserPerspective.executive
            && toggleMenuValue !== ViewPerspective.Company
            && Object.entries(groupedData).map(([name, surveys]) => (
              <div key={name}>
                <p>{name}</p>
                <InsightsDelta
                  value={makeDelta(surveys[0].averages.overall, surveys[surveys.length - 1].averages.overall)}
                />
              </div>
            ))}
          </div>
        </div>

        )}
      </>
    )}
  />
);

const HookedDashboard = ({
  changeToggleMenu,
  data,
  toggleMenuValue,
  userPerspective,
  ...props
}: SharedProps): JSX.Element => {
  const twelveMonthSlice = data.slice(-12) as ExecutiveEngagementDataForDate[];
  const surveyDates = twelveMonthSlice.map((slice) => slice.surveyDate);

  useEffect(() => {
    if (userPerspective === UserPerspective.executive
      && toggleMenuValue !== ViewPerspective.Company
      && toggleMenuValue !== ViewPerspective.Departments) {
      changeToggleMenu(ViewPerspective.Company);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps -- Only want this on first render.
  }, []);

  const groupedData = useMemo(
    () => (userPerspective === UserPerspective.executive ? makeEngagementGroupings(twelveMonthSlice, toggleMenuValue) : {}),
    [twelveMonthSlice, userPerspective, toggleMenuValue],
  );

  const graphData = useMemo(
    () => makeEngagementGraphData({
      dataSlice: twelveMonthSlice,
      userPerspective,
      viewPerspective: toggleMenuValue,
      groupedData,
    }),
    [twelveMonthSlice, toggleMenuValue, userPerspective, groupedData],
  );

  const twelveMonthDeltas = useMemo(
    () => (twelveMonthSlice.length ? Object.fromEntries(
      Object.keys(EngagementCategory).map((cat) => ([
        cat,
        makeDelta(
          twelveMonthSlice[0].averages[cat as keyof EngagementScores],
          twelveMonthSlice[twelveMonthSlice.length - 1].averages[cat as keyof EngagementScores],
        ),
      ])),
    ) : {}) as Record<keyof EngagementScores, number>,
    [twelveMonthSlice],
  );

  const hookProps = {
    changeToggleMenu,
    data,
    toggleMenuValue,
    userPerspective,
    graphData,
    groupedData,
    twelveMonthDeltas,
    surveyDates,
  };

  return (
    <DashboardView
      {...hookProps}
      {...props}
    />
  );
};

export { DashboardView, HookedDashboard };
export default HookedDashboard;
