import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { AddReflectionDrawerProps, addReflectionDrawerTemplate } from '../AddReflectionDrawer';

interface UseAddReflectionDrawerReturn {
  openDrawer: (props: AddReflectionDrawerProps) => void,
  closeDrawer: () => void,
}

export function useAddReflectionDrawer(): UseAddReflectionDrawerReturn {
  const dispatch = useDispatch();

  const openDrawer = ({
    reviewUid,
    reviewTopicUid,
  }: AddReflectionDrawerProps): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...addReflectionDrawerTemplate,
        args: {
          reviewUid,
          reviewTopicUid,
        },
      },
    }));
  };

  const closeDrawer = (): void => {
    // @ts-expect-error : Fix when drawers are typed
    dispatch(popDrawerAction({
      popAll: true,
    }));
  };

  return {
    openDrawer,
    closeDrawer,
  };
}
