import { palette } from '~Common/styles/colors';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-light-svg-icons';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import { MouseEvent } from 'react';
import { css } from '@emotion/react';
import { AgendaTemplates } from '@leadr-hr/types';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import {
  GetAgendaTemplateDetails,
  GetCuratedAgendaTemplateDetails,
  GetAgendaTopicTemplateDetails,
  EditIndividualAgendaTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import AgendaTopicTextEditor from '../../CreateTopicTemplateDrawer/AgendaTopic/AgendaTopicTextEditor';
import AgendaTopic from '../AgendaTopic';
import AgendaSection from '../AgendaSection';
import { getCanEditTemplateItem } from '../../utils/getCanEditTemplateOrItem';

const styles = {
  overflowMenuButton: (canEditTemplate: boolean) => css({
    marginLeft: '1rem',
    visibility: canEditTemplate ? 'visible' : 'hidden',
  }),
};

interface ViewProps {
  topic: GetAgendaTemplateDetails | GetCuratedAgendaTemplateDetails,
  overflowMenuItems: MenuItem[][],
  editIndividualTopic: (newText: string) => void,
  canEdit: boolean,
  showAgendaTopicEditor: boolean,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
}

const View = ({
  topic,
  overflowMenuItems,
  editIndividualTopic,
  canEdit,
  showAgendaTopicEditor,
  addTopicToMeeting,
  ...props
}: ViewProps): JSX.Element => (
  <>
    {showAgendaTopicEditor && (
      <AgendaTopicTextEditor
        topicLocalId={topic.id}
        agendaTopicText={topic.text}
        onSave={(topicText) => editIndividualTopic(topicText)}
      />
    )}
    {!showAgendaTopicEditor && (
      <>
        {topic.type === AgendaTemplates.AgendaTemplateType.AgendaSection && (
          <AgendaSection
            addTopicToMeeting={addTopicToMeeting}
            agendaSection={topic}
            {...props}
          />
        )}
        {topic.type === AgendaTemplates.AgendaTemplateType.AgendaTopic && (
          <AgendaTopic
            agendaTopic={topic}
            addTopicToMeeting={addTopicToMeeting}
            renderRightItem={() => (
              <OverflowMenu
                menuItems={overflowMenuItems}
                renderOverflowMenuButton={(doOpen) => (
                  <LeadrButton
                    css={styles.overflowMenuButton(canEdit)}
                    onClick={(event: MouseEvent<HTMLElement>) => {
                      doOpen(event);
                    }}
                    variant="text"
                    data-test-id="meetingTopicSuggestionsIndividualTopicOverflowMenu"
                    textButtonColor={palette.neutrals.gray700}
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </LeadrButton>
                )}
              />
            )}
            {...props}
          />
        )}
      </>
    )}
  </>
);

interface IndividualTopicCardProps extends Omit<ViewProps, 'overflowMenuItems' | 'canEdit' | 'showAgendaTopicEditor' | 'editIndividualTopic'> {
  handleDeleteIndividualTopic: (topicId: string) => void,
  handleEditIndividualTopic: (newChild: EditIndividualAgendaTemplateDetails) => void,
}

const IndividualTopicCard = ({
  topic,
  handleDeleteIndividualTopic,
  handleEditIndividualTopic,
  ...props
}: IndividualTopicCardProps): JSX.Element => {
  const {
    getIsItemEditorVisible,
    showItemEditor,
  } = useShowItemEditor((state) => ({
    getIsItemEditorVisible: state.getIsItemEditorVisible,
    showItemEditor: state.showItemEditor,
  }));

  const showAgendaTopicEditor = getIsItemEditorVisible(topic.id);

  const canEdit = getCanEditTemplateItem(topic);

  const overflowMenuItems = canEdit ? [[
    {
      text: 'Edit',
      icon: faEdit,
      isDeactivated: false,
      onClick: () => {
        showItemEditor(topic.id);
      },
    },
    {
      text: 'Delete',
      icon: faTrash,
      isDeactivated: false,
      onClick: () => {
        handleDeleteIndividualTopic(topic.id);
      },
    },
  ]] : [[]];

  const editIndividualTopic = (newText: string): void => {
    handleEditIndividualTopic({
      ...topic,
      text: newText,
    });
  };

  const hookProps = {
    overflowMenuItems,
    topic,
    showAgendaTopicEditor,
    editIndividualTopic,
    canEdit,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default IndividualTopicCard;
