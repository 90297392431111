import { getUserTimezone } from '~Common/utils/localStorage';
import * as types from '../../actions';

export const sendMeetingReminderValue = 24;
export const sendMeetingReminderType = 'HOURS';
export const sendActionItemReminderValue = 1;
export const sendActionItemReminderType = 'DAYS';

const settings = (state = {}, action) => {
  switch (action.type) {
    case types.GET_USER_INFO_SUCCESS:
      state.sendActionItemReminderType = action.response.data.sendActionItemReminderType || sendActionItemReminderType;
      state.sendActionItemReminderValue = action.response.data.sendActionItemReminderValue || sendActionItemReminderValue;
      state.sendMeetingReminderType = action.response.data.sendMeetingReminderType || sendMeetingReminderType;
      state.sendMeetingReminderValue = action.response.data.sendMeetingReminderValue || sendMeetingReminderValue;
      state.shouldSendActionItemReminder = action.response.data.shouldSendActionItemReminder === '' ? true : action.response.data.shouldSendActionItemReminder;
      state.shouldSendAssignedActionItemNotification = action.response.data.shouldSendAssignedActionItemNotification === '' ? true : action.response.data.shouldSendAssignedActionItemNotification;
      state.shouldSendMeetingReminder = action.response.data.shouldSendMeetingReminder === '' ? true : action.response.data.shouldSendMeetingReminder;
      state.timezone = action.response.data.timezone ? action.response.data.timezone : getUserTimezone();
      state.shouldSendTimezoneTrackerNotification = action.response.data.shouldSendTimezoneTrackerNotification;
      if (action.response.data.orgUserSetting) {
        state.orgUserSettings = action.response.data?.orgUserSetting;
      }
      return { ...state };
    default:
      return state;
  }
};

export default settings;
