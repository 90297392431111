import { create } from 'zustand';

interface NavigationState {
  isMobileNavigationOpen: boolean,
  isNavigationCollapsed: boolean,
  expandedSections: Record<string, boolean>,
  openMobileNavigation: () => void,
  closeMobileNavigation: () => void,
  toggleCollapsed: () => void,
  toggleExpandedSection: (section: string) => void,
}

export const useNavigationStore = create<NavigationState>((set) => ({
  isMobileNavigationOpen: false,
  isNavigationCollapsed: false,
  expandedSections: { },
  openMobileNavigation: () => set(() => ({ isMobileNavigationOpen: true })),
  closeMobileNavigation: () => set(() => ({ isMobileNavigationOpen: false })),
  toggleCollapsed: () => set((state) => ({ isNavigationCollapsed: !state.isNavigationCollapsed })),
  toggleExpandedSection: (section: string) => set((state) => {
    let currentValue = false;
    if (section in state.expandedSections) {
      currentValue = state.expandedSections[section];
    }

    return {
      expandedSections: {
        ...state.expandedSections,
        [section]: !currentValue,
      },
    };
  }),
}));
