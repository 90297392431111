import { css } from '@emotion/react';
import { forMobileObject } from '~Common/styles/mixins';
import { hexToRGBA, palette } from '~Common/styles/colors';

export const PAGE_STYLES = {
  container: css({
    width: '100%',
    margin: '1.875rem',
    display: 'flex',
    flexDirection: 'column',
  }),
  setupMainContainer: css({
    margin: '1.25rem auto',
  }),
  baseCard: (removeCardStyles: boolean) => css({
    marginTop: '1.25rem',
    padding: '1.5rem',
  }, removeCardStyles && {
    margin: '0',
  }),
};

export const PAGE_STYLES_OVERRIDE = {
  topBar: css({
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    columnGap: '0.5rem',
  }, forMobileObject({
    gridTemplateColumns: 'auto',
    rowGap: '0.5rem',
  })),
  leftSide: (isCentered: boolean) => css({
    alignItems: 'center',
    display: 'flex',
    maxHeight: '2.8125rem',
    width: '75%',
  }, isCentered && {
    justifyContent: 'space-between',
  }),
};

export const TABLE_STYLES = {
  table: (rowHeight: number) => css({
    '.MuiDataGrid-cellContent': {
      whiteSpace: 'normal',
      overflowY: 'auto',
    },

  }, rowHeight && {
    '.MuiDataGrid-cellContent': {
      maxHeight: rowHeight,
      padding: '.3125rem 0',
    },
  }),
  hideIdColumn: css({
    '.MuiDataGrid-row, .MuiDataGrid-virtualScrollerRenderZone': {
      width: '100% !important',
    },
    '.MuiDataGrid-columnHeader': {
      padding: '1rem .625rem !important',
    },
    '.MuiDataGrid-cellCheckbox, .MuiDataGrid-columnHeaderCheckbox': {
      display: 'none !important',
    },
  }),
  tippyContent: css({
    marginTop: '.625rem',

    '&:nth-child(1)': {
      marginTop: '0',
    },
  }),
  issuesIconHeader: css({
    color: palette.neutrals.gray800,
    fontSize: '1.25rem',
  }),
  issuesIcon: (hasWarnings: boolean) => css({
    color: palette.brand.orange,
    fontSize: '1.25rem',
    marginRight: '.5rem',
  }, hasWarnings && {
    color: palette.brand.red,
  }),
  informationBlob: css({
    borderRadius: '.5rem',
    background: hexToRGBA(palette.brand.red, 0.3),
    color: palette.brand.red,
    padding: '.75rem .625rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.75rem',
  }),
  informationBlobIcon: css({
    marginRight: '.5rem',
  }),
  informationBlobContent: css({
    flex: '1',
  }),
};
