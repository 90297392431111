import { useCuratedFeedbackTemplates } from '~Deprecated/hooks/feedback/useFeedbackTemplates';
/* eslint-disable-next-line */
import Templates from './Templates';

const TemplatesByLeadr = ({ ...props }) => {
  const { status, templates } = useCuratedFeedbackTemplates({});

  const hookProps = {
    status,
    templates,
  };

  return (
    <Templates {...props} {...hookProps} />
  );
};

TemplatesByLeadr.propTypes = {

};

TemplatesByLeadr.defaultProps = {

};

export default TemplatesByLeadr;
