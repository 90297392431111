import { css } from '@emotion/react';

import {
  SCORE_NAMES, METRIC_NAMES, METRIC_NAME_PROPTYPES, SCORE_NAME_SUMMARY_SHAPE, DEFAULT_SCORE_SUMMARY,
} from '~Common/const/executiveInsights';
import InsightMetric from '../../InsightMetric';

const style = css`
  display: flex;
  justify-content: space-between;

  > span {
    flex: 1;
    justify-content: center;
  }
`;

const InsightSummaryRow = ({
  metric, report,
}) => {
  const entries = Object.entries(report);
  const totalCount = Math.max(entries.reduce((sum, val) => sum + val[1], 0), 1);
  return (
    <div
      css={style}
    >
      <span>
        <InsightMetric
          metric={metric}
        />
      </span>

      {Object.entries(SCORE_NAMES).map(([key, scoreName]) => (
        <span
          key={key}
        >
          {`${((report[scoreName] / totalCount) * 100).toFixed(0)}%`}
        </span>
      ))}
    </div>
  );
};

InsightSummaryRow.propTypes = {
  metric: METRIC_NAME_PROPTYPES,
  report: SCORE_NAME_SUMMARY_SHAPE,
};

InsightSummaryRow.defaultProps = {
  metric: METRIC_NAMES.COACHING,
  report: DEFAULT_SCORE_SUMMARY,
};

export default InsightSummaryRow;
