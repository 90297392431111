import { ComponentProps, useMemo } from 'react';
import { Goals } from '@leadr-hr/types';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useGetGoalsForCoaching } from '~Goals/hooks/useGetGoalsForCoaching';
import MeetingGoalsLoader from '~Meetings/components/details/goals/CoachingGoals/MeetingGoalsLoader';
import OldMeetingGoalsData from './OldMeetingGoalsData';

export interface ViewProps extends ComponentProps<'div'> {
  goals?: Goals.Goal[],
  isLoading: boolean,
  meetingDetailsLoading: boolean,
}

export const View = ({
  isLoading,
  goals,
  meetingDetailsLoading,
  ...props
}: ViewProps): JSX.Element => (
  <>
    { (isLoading || meetingDetailsLoading) && (
      <MeetingGoalsLoader
        {...props}
      />
    )}
    { !isLoading && !meetingDetailsLoading && !!goals?.length && (
    <OldMeetingGoalsData
      goals={goals}
      {...props}
    />
    )}
  </>
);

export interface MeetingGoalsProps extends ComponentProps<'div'> {
  id: string,
  type: MeetingTypeEnum,
}

const OldCoachingGoals = ({
  id,
  type,
  ...props
}: MeetingGoalsProps): JSX.Element => {
  const {
    item: {
      factoryId: meetingFactoryId = '',
    } = {},
    isLoading: meetingDetailsLoading,
  } = useMeetingDetails({ id, type }) ?? {};

  const { data, isLoading } = useGetGoalsForCoaching({ meetingFactoryId });

  /*
    This is temporary for handling the data structure difference between the old and new goals.
    It will get deleted when the cascadingGoals feature flag is permanently turned on
  */
  const goals = useMemo(() => data?.response.map((goal) => ({
    goalId: goal.goalId,
    title: goal.title,
    statusUpdates: [{
      status: goal.currentStatusUpdate.status,
      completionPercentage: goal.currentStatusUpdate.completionPercentage,
    }],
    startTimeInMillis: goal.startTimeInMillis,
    endTimeInMillis: goal.endTimeInMillis,
    category: goal.category,
  })) ?? [], [data?.response]) as Goals.Goal[];

  const hookProps = {
    goals,
    isLoading,
    meetingDetailsLoading,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default OldCoachingGoals;
