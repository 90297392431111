import { useQuery } from '@tanstack/react-query';
import { hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { MeetingFactoryType, MeetingFrequency, NonLeadrAttendee } from '~Meetings/const/meetingsInterfaces';
import { calendarIntQueryKeys } from './queryKeys';

export interface UnsyncedCalendarMeeting {
  eventId: string,
  status: number,
  dgraphMeetingFactoryId: string,
  ownerEmailAddress: string,
  attendeeId: string[],
  daysOfWeek: number[],
  endTimeInMillis: number,
  frequency: MeetingFrequency,
  location: string,
  startTimeInMillis: number,
  timeZone: string,
  title: string,
  orgUserId: string,
  huddleOptions: MeetingFactoryType[],
  nonLeadrAttendees: NonLeadrAttendee[],
}

const getIgnoredUnsyncedCalendarMeetings = async (): Promise<HttpCallReturn<UnsyncedCalendarMeeting[]>> => {
  const URL = {
    host: hosts.meeting,
    uri: `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/previouslyIgnored`,
  };

  return getApi<UnsyncedCalendarMeeting[]>(URL);
};

interface UseGetIgnoredUnsyncedCalendarMeetingsReturn {
  ignoredUnsyncedCalendarMeetings?: UnsyncedCalendarMeeting[] | undefined,
  isLoading: boolean,
}

export const useGetIgnoredUnsyncedCalendarMeetings = (): UseGetIgnoredUnsyncedCalendarMeetingsReturn => {
  const { data: authStatus } = useGetCalendarAuthStatus();
  const result = useQuery({
    queryKey: calendarIntQueryKeys.ignoredUnsyncedCalendarMeetings(),
    queryFn: getIgnoredUnsyncedCalendarMeetings,
    enabled: authStatus === AUTH_STATUS.LINKED,
  });

  return {
    ignoredUnsyncedCalendarMeetings: result.data?.response,
    isLoading: result.isLoading,
  };
};
