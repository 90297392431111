import { css } from '@emotion/react';
import {
  faBuilding,
  faCompass,
  faLeaf,
  faCompassDrafting,
  faTerminal,
} from '@fortawesome/pro-light-svg-icons';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useIsDesktopQuery } from '~Common/hooks/useMediaListener';
import { useNavigationIndicators } from '~Root/components/NavigationSidebar/useNavigationIndicators';
import { useNavigationPermissions } from '~Root/components/NavigationSidebar/useNavigationPermissions';
import { ActionButton } from '~Root/components/NavigationSidebar/NavigationItems/ActionButton';
import { LinkButton } from '~Root/components/NavigationSidebar/NavigationItems/LinkButton';
import { SHARED_STYLES } from '~Root/components/NavigationSidebar/NavigationItems/styles';
import CustomSearchInputButton from '~Root/components/NavigationSidebar/NavigationItems/CustomSearchInputButton';
import NavigationSection from '~Root/components/NavigationSidebar/NavigationItems/NavigationSection';
import { IsVisibleProps, NavigationIndicatorData } from '~Root/components/NavigationSidebar/types';
import { palette } from '~Common/styles/colors';
import {
  baseNavigationItems,
  organizationNavigationItems,
  alignmentNavigationItems,
  growthNavigationItems,
  nexusNavigationItems,
  devToolNavigationItems,
  hrNavigationItem,
  searchNavigationItem,
} from '~Root/components/NavigationSidebar/navigationItemList';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  ...SHARED_STYLES,
  skeleton: css({
    height: '2rem',
  }),
  margin: css({
    paddingTop: '0.5rem',
  }),
  overflow: css({
    overflow: 'auto',
  }),
  customSearchInputButton: css({
    margin: '0.25rem 1rem',
  }),
  primaryNavigationContainer: (isMobile: boolean) => css(!isMobile && {
    flex: 1,
  }),
  hrButton: (isMobile: boolean) => css({
    background: palette.neutrals.white,
  }, isMobile && {
    bottom: 0,
    position: 'sticky',
  }),
};

interface ViewProps extends NavigationItemsProps {
  isVisibleProps: IsVisibleProps,
  navigationIndicatorData: NavigationIndicatorData,
  useGlobalSearch: boolean,
  isDesktop: boolean,
  shouldShowDevTools: boolean,
  shouldShowHr?: boolean,
  shouldShowNexus: boolean,
  isMobile: boolean,
}

const View = ({
  compact,
  isVisibleProps,
  navigationIndicatorData,
  useGlobalSearch,
  isDesktop,
  shouldShowDevTools,
  shouldShowHr = false,
  shouldShowNexus,
  isMobile,
}: ViewProps): JSX.Element => (
  <div css={[styles.navContainer, styles.margin, styles.overflow]}>
    {useGlobalSearch && isDesktop && (
      <>
        {compact && (
          <ActionButton
            compact={compact}
            isVisibleProps={isVisibleProps}
            item={searchNavigationItem}
          />
        )}

        {!compact && (
          <CustomSearchInputButton css={styles.customSearchInputButton} />
        )}
      </>
    )}

    <div css={styles.primaryNavigationContainer(isMobile)}>
      {baseNavigationItems.map((item) => (
        <LinkButton
          key={item.label}
          compact={compact}
          item={item}
          navigationIndicatorData={navigationIndicatorData}
        />
      ))}

      <NavigationSection
        label="Organization"
        icon={faBuilding}
        items={organizationNavigationItems}
        compact={compact}
      />

      <NavigationSection
        label="Alignment"
        icon={faCompass}
        items={alignmentNavigationItems}
        compact={compact}
      />

      <NavigationSection
        label="Growth"
        icon={faLeaf}
        items={growthNavigationItems}
        compact={compact}
      />

      {shouldShowNexus && (
        <NavigationSection
          label="Nexus"
          icon={faCompassDrafting}
          items={nexusNavigationItems}
          compact={compact}
        />
      )}

      {shouldShowDevTools && (
        <NavigationSection
          label="Dev Tools"
          icon={faTerminal}
          items={devToolNavigationItems}
          compact={compact}
        />
      )}
    </div>

    {shouldShowHr && (
      <ActionButton
        css={styles.hrButton(isMobile)}
        compact={compact}
        isVisibleProps={isVisibleProps}
        item={hrNavigationItem}
      />
    )}
  </div>
);

interface NavigationItemsProps {
  compact: boolean,
  isMobile: boolean,
}

export const NavigationItems = ({
  compact,
  isMobile,
}: NavigationItemsProps): JSX.Element => {
  const shouldShowDevTools = useFeatureFlag('showTestPage');
  const isHrEnabled = useFeatureFlag('hrModuleLink');
  const isVisibleProps = useNavigationPermissions(compact);
  const navigationIndicatorData = useNavigationIndicators();
  const isDesktop = useIsDesktopQuery();
  const { enableHRModule, enableISolvedModule } = useOrgDetailsContext();

  const useGlobalSearch = useFeatureFlag('useGlobalSearch');

  const shouldShowHr = isHrEnabled && (enableHRModule || enableISolvedModule);
  const shouldShowNexus = isVisibleProps.canSeeNexus || isVisibleProps.canImpersonate;

  const hookProps = {
    compact,
    isVisibleProps,
    navigationIndicatorData,
    useGlobalSearch,
    isDesktop,
    shouldShowDevTools,
    shouldShowHr,
    shouldShowNexus,
    isMobile,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
