import MuiDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MomentUtils from '@mui/lab/AdapterMoment';

import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons';
import PickerInput from '~Common/components/Fields/OptionalDateField/DatePickerField';
import { usePrevious } from '~Deprecated/hooks/usePrevious';

/**
 * @deprecated Old Date Picker UI, use packages/leadr-frontend/src/common/V3/components/DatePicker/index.tsx instead
 */
const DatePicker = ({
  label,
  selectedDate,
  onDateSelected,
  name,
  className,
  size,
  disablePast,
  disabled,
  onClear,
  required,
  format,
  ...props
}) => {
  const wrapperRef = useRef(null);
  const [date, setDate] = useState(selectedDate);
  const [isPickerOpen, togglePicker] = useState(false);
  const previousSelectedDate = usePrevious(selectedDate);

  const onInputClick = () => {
    togglePicker(true);
  };

  const closePicker = () => {
    togglePicker(false);
  };

  const onSelect = (d) => {
    setDate(d.toDate());
    if (onDateSelected) {
      onDateSelected({ date: d.toDate(), name });
    }
  };

  const doClear = () => {
    onClear(null);
    onDateSelected({ date: null, name });
  };

  const renderInput = (inputProps) => (
    <PickerInput
      {...inputProps}
      openPicker={onInputClick}
      size={size}
      className={className}
      onClick={onInputClick}
      name={name}
      date={date}
      format={format}
      required={required}
      renderAdornment={onClear && date ? () => (
        <FontAwesomeIcon
          size="lg"
          icon={faTimes}
          onClick={doClear}
          className="pointer"
        />
      ) : null}
    />
  );

  useEffect(() => {
    if (selectedDate && selectedDate !== previousSelectedDate) {
      setDate(moment(selectedDate).toDate());
    }
  }, [selectedDate, previousSelectedDate]);

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <MuiDatePicker
        ref={wrapperRef}
        disablePast={disablePast}
        label={label}
        value={date}
        allowSameDateSelection
        open={isPickerOpen}
        onClose={closePicker}
        onChange={() => {}}
        onAccept={onSelect}
        renderInput={renderInput}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        clearable
        disabled={disabled}
        {...props}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onDateSelected: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  onClear: PropTypes.func,
  size: PropTypes.string,
  disablePast: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  format: PropTypes.string,
};

DatePicker.defaultProps = {
  label: 'Date Picker',
  selectedDate: undefined,
  onDateSelected: () => {},
  className: '',
  name: '',
  onClear: null,
  size: undefined,
  disablePast: false,
  disabled: false,
  required: false,
  format: 'MM/DD/YYYY',
};

export default DatePicker;
