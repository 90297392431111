import { css } from '@emotion/react';

import {
  COMPATTabActive, COMPATCardShadow,
  COMPATAdminBadgeColor,
  COMPATErrorColor,
} from '~Deprecated/ui/styles/colors';

import {
  insightMetricOk, insightMetricGood,
} from '~Common/styles/colors';

import { forTablet, withoutDesktop, forDesktop } from './mixins';

/**
 * @deprecated Old UI
 */
export const withCompat = (loadNewStyles) => {
  const cardShadow = `0px 4px 16px -4px ${COMPATCardShadow}`;
  if (loadNewStyles) {
    return css`
      body {
        background: #e3e7eb;
        overflow: hidden;
      }

      .add-new-agenda-item .additem-action .actionbutton.mat-flat-button {
        margin-right: 8px;
      }

      .coaching-details-wrapper .middle-col {
        overflow-y: visible;
      }

      .page-right-panel {
        height: 100%;
      }

      .tabs-hack-fix {
        position: relative;
      }

      .page-wrapper {
        .tabs-header {
          position: absolute;
          left: -24px;
        }
      }

      .coaching-content-wrapper { 
        .coaching-contents .course-content {
          box-shadow: 0 4px 8px -4px rgba(56, 80, 102, 0.4);
        }

        .coaching-details-wrapper {
          .tabs-wrapper {
            ${forTablet(`
              padding: 0 !important;

              .nav-tabs {
                overflow-x: unset !important;
              }
            `)}
          }

          .title-col {
            @media only screen and (max-width: 576px) {
              height: 50px !important;
            }
            @media only screen and (min-width: 1024px) {
              height: 70px !important;
            }
          }

          .middle-col {
            padding-top: 10px;

            .tabs-header {
              @media only screen and (max-width: 576px) {
                transform: translate(-50%, -60px) !important;
              }
            }
          }
        }

        .tabs-wrapper {
          ${forTablet(`
            padding: 16px 0 !important;

            .nav-tabs {
              overflow-x: hidden !important;
            }
          `)}
        }
      }

      .learning-assignment-item {
        margin: 15px 5px;
        
        @media only screen and (max-width: 576px) {
          box-shadow: ${cardShadow};
        }
      }

      .page-top-bar .tabs-wrapper.d-flex.flex-column {
        padding: 16px 24px 16px 12px;
        border-bottom: none;
      }

      .tabs-wrapper .nav-tabs {
        .nav-item.active {
          color: ${COMPATTabActive};
        }

        .nav-item .nav-link:hover, .nav-item .nav-link:focus {
          color: ${COMPATTabActive} !important;
        }

        .nav-item > .nav-link-bottom-border {
          background: ${COMPATTabActive} !important;
        }
      }

      .btn-primary {
        background: ${COMPATTabActive} !important;

        &.mat-stroked-button {
          color: ${COMPATTabActive} !important;
          border-color: ${COMPATTabActive} !important;
        }

        .mat-button.btn-create-action-item {
          background: ${COMPATTabActive} !important;
          border-color: ${COMPATTabActive} !important;
          color: white !important;
        }
      }

      .btn-member {
        background: ${COMPATTabActive} !important;
        border-color: ${COMPATTabActive} !important;
        color: white !important;
      }

      .btn-member[disabled] {
        background-color: #e3e7eb !important;
      }

      .additem-action .actionbutton.mat-flat-button {
        border-color: ${COMPATTabActive} !important;
        color: ${COMPATTabActive} !important;

        &.btn-primary {
          color: white !important;
        }
      }

      .page-right-panel {
        border-radius: 8px;
        border: none !important;
      }

      .agenda-horizontal-wrapper {
        .agenda-view-date {
          &.selected {
            background: ${COMPATTabActive};
            border: 1px solid ${COMPATTabActive};
          }
        }
      }

      .action-menu-wrapper {
        font-size: 12px;
      }
      .middle-col.profile-page-content {
        overflow-y: visible !important;
        max-height: unset;
        height: auto;
        margin-bottom: 50px;
      }

      .org-view {
        .org-card-container {
          box-shadow: ${cardShadow};
        }

        .back-button-container {
          box-shadow: ${cardShadow};

          a {
            background-color: white;
          }
        }
      }

      .people-wrapper {
        .title-col {
          position: fixed;
          left: 0;
        }
      }
      
      .settings-page-content {
        overflow: visible !important;
        height: auto;
        max-height: unset;
      }

      .profile-settings-wrapper {
        overflow: visible;
        max-height: unset;
        height: auto;
      }

      .goal-card {
        box-shadow: 0 0.125rem 0.0625rem -0.0625rem rgba(0, 0, 0, 0.2), 0 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.14),
          0 0.0625rem 0.625rem 0 rgba(0, 0, 0, 0.12) !important;
      }

      .meeting-with-user-wrapper {
        .goal-card {
          box-shadow: unset !important;
        }
      }

      .goals-LDRPagination .active {
        background-color: ${COMPATTabActive} !important;
        border-color: ${COMPATTabActive} !important;
      }

      .rangeslider__fill {
        background-color: ${insightMetricGood} !important;
      }

      .rangeslider__fill_yellow .rangeslider__fill {
        background-color: ${insightMetricOk} !important;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
          color: #fff;
          border-color: ${COMPATTabActive} !important;
          background-color: ${COMPATTabActive} !important;
      }

      .mat-button.btn-create-action-item {
        background-color: ${COMPATTabActive} !important;
      }

      .plus-circle {
        color: ${COMPATTabActive} !important;
      }

      .content-card {
        ${forDesktop('box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);')}
      }

      .create-new-coaching {
        .agenda-list-wrapper {
          box-shadow: none;
        }
      }

      .agenda-list-wrapper {
        ${forDesktop('box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);')}
        
        .meeting-list-wrapper {
          background-color: white;

          .add-agenda {
            border: 1px solid ${COMPATTabActive};
            color: ${COMPATTabActive} !important;
            border-color: ${COMPATTabActive} !important;
            background: white !important;
          }

          .dismiss-agenda {
            border: 1px solid ${COMPATErrorColor};
            color: ${COMPATErrorColor} !important;
            border-color: ${COMPATErrorColor} !important;
            background: white !important;
          }
        }
      }

      .agenda-horizontal-wrapper {
        .agenda-view {
          box-shadow: ${cardShadow};
        }
      }

      .schedule-meeting-wrapper {
        .agenda-title-h1 {
          text-align: center;
        }
      }

      .user-data {
        .align-items-center {
          word-break: break-all;
        }
      }

      .upcoming-team-meetings .text-break {
        overflow: hidden;
        padding-left: 30px;
      }
      
      .administration-list-container .coaching-details-wrapper {
        .title-col {
          display: none;
        }

        .middle-col {
          padding-top: 84px !important;
          padding-bottom: 20px;
          max-height: unset;
          height: 100%;

          .tabs-wrapper {
            ${forTablet(`
              padding: 0 !important;

              .nav-tabs {
                overflow-x: unset !important;
              }
            `)}

            .tabs-header {
              transform: translateY(-79px) !important;
            }
          }
        }
      }

      .nav-link > span {
        background-color: ${COMPATAdminBadgeColor};
        color: white;
      }

      .agenda-suggestion-wrapper {
        .suggestions-section-btn {
          color: ${COMPATTabActive} !important;
        }

        .section-wrapper .align-middle {
          color: ${COMPATTabActive} !important;
        }
      }

      .page-top-bar {
        .agenda-horizontal-wrapper {      
          &.meeting-top-panel, &.coaching-top-panel {
            padding: 0 12px;
            width: unset !important;
            max-width: unset !important;

            @media only screen and (min-width: 1201px) {
              padding: 0 24px;
            }
          }
        } 
      }

      .createagenda-list-wrapper {
        .action-wrapper {
          input[type="checkbox"] {
            display: inline;
          }

          .custom-control-label {
            padding-bottom: .3rem;
          }
        }

        .meeting-list-wrapper .modal .agenda-suggestion-wrapper .tabs-wrapper .tabs-header {
          transform: unset !important;
        }

        .meeting-list-wrapper .action-items-wrapper .meeting-title-team {
          padding-bottom: 10px;
        }
      }

      .people-wrapper {
        .admin-buttons {
          ${withoutDesktop(`
            display: none !important;
          `)}
        }
      }

      .ql-editor {
        margin-top: 1px;
        margin-left: 10px;
      }

      .create-goal-modal {
        .ql-snow:not(.ql-disabled) {
          .ql-editor {
            min-height: 100px;
          }
        }
      }

      .assigned-to-details {
        margin-right: 10px;
      }

      .meeting-type-title, .goal-type-title {
        color: ${COMPATTabActive} !important;
      }

      .administration-list-container {
        .content-card, .invitedUsers, .importedUsers {
          box-shadow: ${cardShadow} !important;
        }

        .adminImportPeople {
          border-color: ${COMPATTabActive} !important;
          color: white !important;
          background: ${COMPATTabActive} !important;
        }
  
        .adminHeader, .admin-type-title {
          color: ${COMPATTabActive} !important;
        }
      }

      .add-feedback-question-wrapper > button,
      .add-action-item,
      .select-from-library-button,
      .btn-add-next-meeting,
      .content-list-wrapper .awaiting-response-wrapper button,
      .content-library-modal > button {
          border: 1px solid ${COMPATTabActive} !important;
          background: white !important;
          color: ${COMPATTabActive} !important;
      }

      .add-note {
        color: ${COMPATTabActive} !important;
        padding: 3px !important;

        &:hover {
          box-shadow: unset !important;
          color: white !important;
          background: ${COMPATTabActive} !important;

          svg {
            stroke: white !important;
          }
        }

        svg {
          stroke: ${COMPATTabActive} !important;
        }
      }

      .meeting-cards-view .meeting-card-flex { 
        .next-meeting-wrapper {
          cursor: pointer !important;
        }
      }

      .meeting-chip.selected {
        background: ${COMPATTabActive} !important;
      }

      .coaching-view .coaching-content-wrapper .coaching-details-wrapper {
        .content-assignment-for-me, .feedback-requests-for-me {
          overflow: unset !important;
          .content-wrapper, .feedback-wrapper {
            overflow: unset !important;
            .tabs-wrapper {
              overflow: unset !important;
            }
          }
        }
      } 
    `;
  }

  return css`
    .agenda-horizontal-wrapper {
      background: white;

      &.coaching-top-panel {
        width: calc(100vw - 240px - 289px + 0px);
        max-width: calc(100vw - 240px - 289px + 0px);
      } 
    }

    .side-menu-wrapper > .tab-content {
      width: calc(100vw - 240px - 289px);
    }

    .coaching-details-wrapper {
      overflow-y: hidden;
    }

    .coaching-details-wrapper .middle-col {
      overflow-y: scroll;
    }

    .coaching-view {
      overflow: hidden;
    }

    .page-right-panel {
      max-height: calc((1vh * 100) - 68px);
      max-height: calc((var(--vh, 1vh) * 100) - 68px);
    
      @include breakpoint-mobile() {
        height: calc((1vh * 100) - 62.5px);
        height: calc((var(--vh, 1vh) * 100) - 62.5px);
      }
    
      .tab-content {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    
      .user-profile-info-modal-wrapper-side {
        .tab-content {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }

    .page-right-panel {
      height: calc((1vh * 100) - calc(40px + 2 * 0.865rem));
      height: calc((var(--vh, 1vh) * 100) - calc(40px + 2 * 0.865rem));
    }

    .page-wrapper {
      .tabs-header {
        position: fixed;
      }

      .title-col {
        background: white;
      }
    }

    .tabs-wrapper {
      .nav-tabs {
        background-color: white;
      }
    }

    html,
    body,
    #root {
      overflow: hidden;
    }
  `;
};

/**
 * @deprecated Old UI
 */
export const COMPAT = {
  primaryButton: css`
    background-color: ${COMPATTabActive} !important;
    border-color: ${COMPATTabActive} !important;
  `,
  buttonWithBorder: css`
    color: ${COMPATTabActive} !important;
    border-color: ${COMPATTabActive} !important;
  `,
};
