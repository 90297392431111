import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { FormControlLabel } from '@mui/material';
import { palette } from '~Common/styles/colors';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';
import DrawerInput from '~Common/V3/components/DrawerInput';
import { ProfileEditRestrictions } from '~Common/hooks/people/useProfileEditRestrictions';
import Tooltip from '~Common/components/Tooltip';
import { OrgSettings } from '~Common/const/Organization';
import CheckboxLabel from '../CheckboxLabel';

const profileRestrictionFields: Array<{ label: string, name: keyof ProfileEditRestrictions }> = [
  {
    label: 'Name',
    name: 'enableProfileNameEdits',
  },
  {
    label: 'Active/Inactive Status',
    name: 'enableProfileStatusEdits',
  },
  {
    label: 'Job Title',
    name: 'enableProfileJobTitleEdits',
  },
  {
    label: 'Department',
    name: 'enableProfileDepartmentEdits',
  },
  {
    label: 'Manager',
    name: 'enableProfileManagerEdits',
  },
];

const styles = {
  descriptionText: css({
    color: palette.neutrals.gray700,
    marginBottom: '0.5rem',
  }),
  ssoFieldsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
  }),
};

interface ViewProps {
  enableEnterpriseSSO: boolean,
  onSSOToggle: (event: React.ChangeEvent<HTMLInputElement>) => void,
  ssoEnabled: boolean,
  primaryEmailDomain?: string,
  connectionName?: string,
  profileEditRestrictions: ProfileEditRestrictions,
}

const View = ({
  enableEnterpriseSSO,
  onSSOToggle,
  ssoEnabled,
  primaryEmailDomain,
  connectionName,
  profileEditRestrictions,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <SettingsSectionHeader
      renderContents={() => (
        <>Enterprise SSO</>
      )}
    />
    <p css={styles.descriptionText}>
      You can enable/disable features for enterprise SSO.
    </p>
    <FormControlLabel
      control={(
        <CustomCheckbox
          onChange={onSSOToggle}
          name="enableEnterpriseSSO"
          defaultChecked={enableEnterpriseSSO}
        />
      )}
      label={(
        <CheckboxLabel
          renderText={(checkboxLabelTextStyles) => (
            <div css={checkboxLabelTextStyles}>Enable Enterprise SSO Features</div>
          )}
        />
      )}
    />
    {ssoEnabled && (
      <div css={styles.ssoFieldsContainer}>
        <div>
          <DrawerInput
            label="Primary Email Domains (comma separated)"
            name="primaryEmailDomains"
            placeholder="example.com"
            initialValue={primaryEmailDomain || ''}
            required={ssoEnabled}
          />
        </div>
        <div>
          <DrawerInput
            label="Connection Name"
            name="auth0EnterpriseConnectionName"
            initialValue={connectionName || ''}
            required={ssoEnabled}
          />
        </div>
        <SettingsSectionHeader
          renderContents={() => (
            <Tooltip content="You should uncheck any fields that are managed through the organization Identity Provider.">
              <span>
                Allow Editing Of...
              </span>
            </Tooltip>
          )}
        />
        {profileRestrictionFields.map(({ name, label }) => (
          <FormControlLabel
            key={name}
            control={(
              <CustomCheckbox
                name={name}
                defaultChecked={profileEditRestrictions[name]}
              />
            )}
            label={(
              <CheckboxLabel
                renderText={(checkboxLabelTextStyles) => (
                  <div css={checkboxLabelTextStyles}>
                    {label}
                  </div>
                )}
              />
            )}
          />
        ))}
      </div>
    )}
  </div>
);

interface EnterpriseSSOProps {
  orgSettings: OrgSettings,
}

const EnterpriseSSO = ({
  orgSettings,
  ...props
}: EnterpriseSSOProps): JSX.Element => {
  const {
    enableEnterpriseSSO,
    primaryEmailDomains = [],
    auth0EnterpriseConnectionName: connectionName,
    profileEditRestrictions,
  } = orgSettings;

  const primaryEmailDomain = primaryEmailDomains?.join(', ');

  const [ssoEnabled, setSsoEnabled] = useState(enableEnterpriseSSO);

  const onSSOToggle = useCallback(():void => {
    setSsoEnabled(!ssoEnabled);
  }, [setSsoEnabled, ssoEnabled]);

  const hookProps = {
    enableEnterpriseSSO,
    onSSOToggle,
    ssoEnabled,
    primaryEmailDomain,
    connectionName,
    profileEditRestrictions,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default EnterpriseSSO;
