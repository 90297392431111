import { css } from '@emotion/react';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { hideForPrint } from '~Common/styles/mixins';

const styles = {
  printButton: css({}, hideForPrint()),
};

interface ViewProps extends LeadrButtonProps<'button'> {
  handlePrintClick: () => void,
}

const View = ({
  handlePrintClick,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrButton
    css={styles.printButton}
    onClick={handlePrintClick}
    {...props}
  >
    <LeadrButton.IconAndText icon={faPrint} text="Print" />
  </LeadrButton>
);

type PrintButtonProps = Omit<ViewProps, 'handlePrintClick'>;

const PrintButton = ({ ...props }: PrintButtonProps): JSX.Element => {
  const handlePrintClick = (): void => {
    window.print();
  };

  const hookProps = {
    handlePrintClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default PrintButton;
