import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@mui/material';

import { palette } from '~Common/styles/colors';
import { noop } from '~Deprecated/utils';

const styles = {
  buttonGroup: css({
    border: `1px solid ${palette.neutrals.gray300}`,
    borderRadius: '.5rem',
  }),
};

const View = ({ renderPreviousButton, renderNextButton, ...props }) => (
  <ButtonGroup css={styles.buttonGroup} disableElevation variant="contained" {...props}>
    {renderPreviousButton()}
    {renderNextButton()}
  </ButtonGroup>
);

View.propTypes = {
  renderPreviousButton: PropTypes.func,
  renderNextButton: PropTypes.func,
};

View.defaultProps = {
  renderPreviousButton: noop,
  renderNextButton: noop,
};

const PaginationButton = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

PaginationButton.propTypes = {};

PaginationButton.defaultProps = {};

export { View };
export default PaginationButton;
