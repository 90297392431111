import { useQuery } from '@tanstack/react-query';

import { getApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId, getUserId } from '~Common/utils/localStorage';
import { flattenData } from '~Deprecated/utils/reduxUtils';
import { hosts } from '~Deprecated/services/config';
import { useMemo } from 'react';

const getPeople = async ({ queryKey }) => {
  const [organizationId, _, count, skip, administrativeStatusesToInclude] = queryKey;
  const administrativeStatusesToIncludeString = administrativeStatusesToInclude.toString();
  const URL = {
    host: hosts.auth,
    uri: `/organizations/${organizationId}/users?count=${count}&skip=${skip}&administrativeStatus=${administrativeStatusesToIncludeString}`,
  };
  const apiData = await getApi(URL, {}, {});

  const orgUserObjects = {};
  const orgUserIds = [];

  const updateOrgUserIds = (user) => {
    orgUserIds.push(user.orgUserId);
    orgUserObjects[user.orgUserId] = user;
    return user;
  };

  const { ids, data } = flattenData({
    responseData: (apiData.response && apiData.response.users),
    onItemFlatten: updateOrgUserIds,
  });

  const combinedData = { ...orgUserObjects, ...data };

  [...ids, ...orgUserIds].map((userId) => {
    const manager = combinedData[combinedData[userId].managerId];
    let managerName = null;
    if (manager) {
      managerName = `${manager.firstName} ${manager.lastName}`;
    }

    combinedData[userId].managerName = managerName;
  });

  return {
    ids,
    data: combinedData,
    orgUserIds,
  };
};

const usePeopleQuery = ({ count = 1000, skip = 0, administrativeStatusesToInclude = ['ACTIVE'] } = {}) => {
  const result = useQuery({
    queryKey: [getOrganizationId(), 'people', count, skip, administrativeStatusesToInclude],
    queryFn: getPeople,
  });

  const data = result.data?.data;

  const peopleDataArray = Object.values(data || {});

  return {
    ids: result.data && result.data.ids,
    data,
    orgUserIds: result.data && result.data.orgUserIds,
    status: result && result.status,
    isLoading: result.isLoading,
    peopleDataArray,
  };
};

const usePeople = ({
  search,
  useOrgIds,
  allowSelf = true,
  administrativeStatusesToInclude = ['ACTIVE'],
} = {}) => {
  const result = usePeopleQuery({ administrativeStatusesToInclude });
  if (result) {
    const {
      ids,
      data,
      orgUserIds,
      status,
      isLoading,
    } = result;

    let filterableIds = useOrgIds ? orgUserIds : ids;

    const currentUser = getUserId();
    const currentOrgUser = getOrganizationUserId();

    if (data && !allowSelf) {
      filterableIds = filterableIds.filter((uId) => uId !== currentUser && uId !== currentOrgUser);
    }

    let allData = [];
    if (filterableIds && filterableIds.length > 0) {
      allData = [...filterableIds];

      if (data && search && search.length) {
        return {
          status,
          allData,
          data: filterableIds.filter((uId) => {
            const user = data[uId];
            return `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(search.toLowerCase()) > -1;
          }),
        };
      }
    }

    // Makes unique array of people
    const peopleDataArray = useMemo(() => Object.values(data ?? {}).reduce((acc, currentPerson) => {
      if (!acc.some((person) => person.id === currentPerson.id)) {
        acc.push(currentPerson);
      }
      return acc;
    }, []), [data]);

    return {
      status,
      data: filterableIds,
      allData,
      isLoading,
      peopleDataArray,
    };
  }

  return {
    status: result.status,
    data: [],
    allData: [],
  };
};

const useGetPersonById = ({ id }) => {
  const result = usePeopleQuery({});
  return result.data && result.data[id];
};

const useGetPersonByIdWithLoading = ({ id }) => {
  const { data, isLoading } = usePeopleQuery({});
  return { person: data && data[id], isLoading };
};

const useGetPeopleByList = ({ selectedIds }) => {
  const { data } = usePeopleQuery({});
  return data && selectedIds && selectedIds.map((pId) => data[pId]).filter((p) => p !== undefined);
};

export {
  usePeople,
  usePeopleQuery,
  useGetPersonById,
  useGetPeopleByList,
  useGetPersonByIdWithLoading,
};
