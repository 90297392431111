import { css, SerializedStyles } from '@emotion/react';
import { hexToRgb } from '@mui/material';
import { forMobileTinyObject, withTruncate } from '~Common/styles/mixins';

import {
  drawerHeaderCloseButtonColor,
  palette,
} from '~Common/styles/colors';
import { useHorizontalScroll } from '~Common/hooks/useHorizontalScroll';
import { RefObject } from 'react';

const styles = {
  tabDrawerHeader: css`
  `,
  headerContainer: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem 1.5rem 1.25rem 1.5rem',
  }),
  titleContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  drawerInstructions: css({
    color: palette.neutrals.gray500,
    fontSize: '0.875rem',
    fontWeight: 400,
  }),
  closeButton: css`
    flex-shrink: 0;
    color: ${drawerHeaderCloseButtonColor};
  `,
  backButton: css`
    flex-shrink: 0;
    margin-right: 0.5rem;
    color: ${drawerHeaderCloseButtonColor};
  `,
  title: css`
    flex: 1;
    font-weight: 600;
    font-size: 18px;
    ${withTruncate()};
    color: ${palette.neutrals.gray800};
  `,
  tabNav: css({
    backgroundColor: hexToRgb(palette.neutrals.gray50),
    display: 'flex',
    overflowX: 'auto',
    height: '3.125rem',
    alignItems: 'flex-end',
    padding: '0 1.5rem',
  }, forMobileTinyObject({
    padding: '0 .7rem',
  })),
};

interface ViewProps {
  title: string,
  renderCloseButton?: (tabDrawerHeaderStyles: SerializedStyles) => JSX.Element,
  renderBackButton?: (backButtonStyles: SerializedStyles) => JSX.Element | undefined,
  renderDrawerInstructions?: (drawerInstructionStyles: SerializedStyles) => JSX.Element,
  renderTabNav: (tabNavStyles: SerializedStyles, scroller: RefObject<HTMLDivElement>) => JSX.Element,
  scroller: RefObject<HTMLDivElement>,
}

const View = ({
  title,
  renderCloseButton,
  renderBackButton,
  renderDrawerInstructions,
  renderTabNav,
  scroller,
}: ViewProps): JSX.Element => (
  <div css={styles.tabDrawerHeader}>
    <div css={styles.headerContainer}>
      <div css={styles.titleContainer}>
        {renderBackButton?.(styles.backButton)}
        <div css={styles.title}>{title}</div>
        {renderCloseButton?.(styles.closeButton)}
      </div>
      {renderDrawerInstructions?.(styles.drawerInstructions)}
    </div>
    {renderTabNav?.(styles.tabNav, scroller)}
  </div>
);

const TabDrawerHeader = ({ ...props }: Omit<ViewProps, 'scroller'>): JSX.Element => {
  const scroller = useHorizontalScroll();

  const hookProps = {
    scroller,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default TabDrawerHeader;
