import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { PAGE_STYLES, REVIEW_DISPLAY_STYLES, CENTER_CONTENTS } from '../Const/pageStyles';
import { ParticipantTypeEnum, PersonDisplayInformationReviews } from '../Const/types';

const styles = {
  ...PAGE_STYLES,
  ...REVIEW_DISPLAY_STYLES,
  ...CENTER_CONTENTS,
};

interface ViewProps {
  personToDisplay: PersonDisplayInformationReviews | Record<string, never>,
  showCondensed: boolean,
}

const View = ({
  personToDisplay,
  showCondensed,
}: ViewProps): JSX.Element => (
  <>
    {personToDisplay && (
    <div
      css={styles.reviewResponseAvatarWrapper}
    >
        {!showCondensed && (
        <>
          <SquareAvatar
            css={styles.avatarIcon(showCondensed)}
            imgUrl={personToDisplay?.profileImageUrl}
            name={`${personToDisplay?.firstName} ${personToDisplay?.lastName}`}
            width={48}
            height={48}
            includeInitials
          />
          <div
            css={styles.revieweeAvatarAssets}
          >
            <span
              css={styles.revieweeAvatarAssetsName(showCondensed)}
            >
              {`${personToDisplay?.firstName} ${personToDisplay?.lastName}`}
            </span>
            <span
              css={styles.revieweeAvatarAssetsTitle}
            >
              {personToDisplay?.jobTitle}
            </span>
          </div>
        </>
        )}
        {showCondensed && (
        <>
          <SquareAvatar
            css={styles.avatarIcon(showCondensed)}
            imgUrl={personToDisplay?.profileImageUrl}
            name={`${personToDisplay?.firstName} ${personToDisplay?.lastName}`}
            width={36}
            height={36}
            includeInitials
          />
          <div
            css={styles.revieweeAvatarAssets}
          >
            <span
              css={styles.revieweeAvatarAssetsName(showCondensed)}
            >
              {`${personToDisplay?.firstName} ${personToDisplay?.lastName}`}
            </span>
            <span
              css={styles.revieweeAvatarAssetsTitle}
            >
              {personToDisplay.participantTypeEnum === ParticipantTypeEnum.Reviewer ? 'Reviewer' : ''}
              {personToDisplay.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer ? 'Secondary Reviewer' : ''}
              {personToDisplay.participantTypeEnum === ParticipantTypeEnum.Reviewee ? 'Reviewee' : ''}
            </span>
          </div>
        </>
        )}
    </div>
    )}
  </>
);

interface PersonDisplayAvatarInformationProps {
    personToDisplay: PersonDisplayInformationReviews | Record<string, never>,
    showCondensed?: boolean,
}

const PersonDisplayAvatarInformation = ({
  personToDisplay,
  showCondensed = false,

}: PersonDisplayAvatarInformationProps): JSX.Element => {
  const hookProps = {
    personToDisplay,
    showCondensed,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default PersonDisplayAvatarInformation;
