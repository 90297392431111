import { uniq } from 'lodash';
import { AdminImporterTypes, ResponseDataShape } from './types';

const getRanges = (array: number[]): string[] => {
  const ranges = []; let rstart; let
    rend;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    rstart = array[i];
    rend = rstart;
    while (array[i + 1] - array[i] === 1) {
      // increment the index if the numbers sequential
      rend = array[i + 1];
      // eslint-disable-next-line no-plusplus
      i++;
    }
    ranges.push(rstart === rend ? `${rstart}` : `${rstart}-${rend}`);
  }
  return ranges;
};

interface ResponseDataProps {
    ErrorCode: string,
    CSVRow: number,
    ColumnName: string,
    ErrorMessage: string,
}
interface GroupedDataProps {
    ErrorCode: string,
    CSVRows: number[],
    ColumnName: string,
    ErrorMessage: string,
}

interface ReturnedGroupedDataTableProps {
    CSVRows: string[],
    ColumnName: string,
    ErrorMessage: string,
}

interface ErrorCodeShape {
  CSVRow: number,
  ColumnName: string,
  ErrorMessage: string,
  ErrorCode: string,
}

export const convertToGroupedTableData = (data: ResponseDataShape[], type: AdminImporterTypes): ReturnedGroupedDataTableProps[] => {
  const records = data.flatMap((record) => record[type].filter((error) => error.errorCode !== ''));
  const recordsArray = records.map((record) => record.errorCode);
  const uniqueErrors = uniq(recordsArray);

  const groupedData = data.map((item) => {
    let errorObject: ResponseDataProps = {
      ErrorCode: '',
      CSVRow: 0,
      ColumnName: '',
      ErrorMessage: '',
    };
    const errorCodes: ErrorCodeShape[] = [];
    uniqueErrors.forEach((uniqueError) => {
      item[type].forEach((error) => {
        if (error.errorCode === uniqueError) {
          errorObject = {
            ErrorCode: uniqueError,
            CSVRow: item.spreadsheetRowNumber,
            ColumnName: error.columnName,
            ErrorMessage: error.message,
          };
          errorCodes.push(errorObject);
        }
      });
    });
    return errorCodes;
  });

  const condenseData = groupedData.flatMap((item) => item).sort((a, b) => ((a.CSVRow > b.CSVRow) ? 1 : -1));

  const conformForDataTable = condenseData.reduce((groups, item) => {
    if (!groups[item.ErrorCode]) {
      // eslint-disable-next-line no-param-reassign
      groups[item.ErrorCode] = {
        ErrorMessage: item.ErrorMessage,
        ColumnName: item.ColumnName,
        CSVRows: [],
      };
    }
    groups[item.ErrorCode].CSVRows.push(item.CSVRow);

    return groups;
  }, {} as Record<string, Omit<GroupedDataProps, 'ErrorCode'>>);

  const createDataAsArray = Object.values(conformForDataTable);
  const convertedForDataTable = createDataAsArray.map((item) => ({ ...item, CSVRows: getRanges(item.CSVRows) }));

  return convertedForDataTable;
};
