import React, {
  useEffect,
  useState,
} from 'react';
import { ContextButtons } from '~Reviews/V2/Shared/ContextButtons';
import {
  REVIEW_SETUP_LAYOUT, FORM_STYLES, BUTTON_STYLES,
} from '~Reviews/V2/Const/pageStyles';
import { queryClient } from '~Common/const/queryClient';

import {
  UseFormReturn,
  SubmitHandler,
} from 'react-hook-form';
import CycleHeader from '~Reviews/V2/Shared/CycleHeader';
import { getOrganizationId } from '~Common/utils/localStorage';

import { useGetTopicForCycle } from '~Reviews/V2/Hooks/useGetTopics';
import Tooltip from '~Common/components/Tooltip';
import { useDispatch } from 'react-redux';
import { navigateAction } from '~Deprecated/actions/navigate';
import { BASE_ROUTE, REVIEWS_CONTINUE_CREATING_CYCLE } from '~Common/const/routes';
import { FlaggedEnum } from '~Common/utils/FlaggedEnum';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useCreateTopicForCycle } from '../Hooks/useCreateTopicForCycle';

import {
  CycleShape, ViewCyclePerspective, TopicReturnById, TopicShape, ParticipantTypeEnum, ReviewCycleStatusEnum,
} from '../Const/types';
import {
  FormValues,
  conformToDto,
} from '../schemata/CreateTopicForCycle';
import {
  FormValuesMatrixOrg,
  conformToDtoMatrixOrg,
} from '../schemata/CreateTopicForCycleMatrixOrgs';

import QuestionPreview from '../Shared/QuestionPreview';
import AddQuestion from '../Shared/AddQuestion';
import {
  DEFAULT_TOPIC,
} from '../Const/defaults';
import { useUpdateTopicForCycle } from '../Hooks/useUpdateTopicForCycle';
import { useTopicFormContext } from '../Hooks/useTopicFormContext';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...FORM_STYLES,
  ...BUTTON_STYLES,
};

interface ViewProps {
  cycle: CycleShape,
  formContext: UseFormReturn<FormValues>,
  onFormSubmit: SubmitHandler<FormValues>,
  handleClick: (action: ViewCyclePerspective) => void,
  backToReviewCycleDetails: () => void,
  addQuestion: boolean,
  updateQuestionView: (value: boolean) => void,
  viewCyclePerspective: ViewCyclePerspective,
  topics: TopicShape[] | undefined,
  isDisabled: boolean,
  cycleId: string,
  setEditQuestion: (topic: string) => void,
  editQuestion: string,
  isMatrixOrg: boolean,
  cycleIsNotDraft: boolean,
}

const View = ({
  cycle,
  formContext,
  onFormSubmit,
  handleClick,
  backToReviewCycleDetails,
  addQuestion,
  updateQuestionView,
  viewCyclePerspective,
  topics,
  isDisabled,
  cycleId,
  setEditQuestion,
  editQuestion,
  isMatrixOrg,
  cycleIsNotDraft,
}: ViewProps): JSX.Element => (
  <>
    <ContextButtons
      portalId="contextButtons"
      renderContents={() => (
        <>
          {cycleIsNotDraft && (
            <LeadrButton
              onClick={backToReviewCycleDetails}
              data-test-id="reviewsUpdateCycle"
            >
              Update Cycle
            </LeadrButton>
          )}
          {!cycleIsNotDraft && (
            <>
              <LeadrButton
                css={styles.outlineButton}
                variant="ghost"
                onClick={() => { handleClick(ViewCyclePerspective.Setup); }}
                data-test-id="reviewsBackToSetup"
              >
                Previous Step
              </LeadrButton>
              <LeadrButton
                disabled={isDisabled ?? true}
                onClick={() => { handleClick(ViewCyclePerspective.Add_Participants); }}
                data-test-id="reviewsNextToParticipants"
              >
                {isDisabled && (
                  <>
                    {isMatrixOrg && (
                    <Tooltip interactive content="You must have at least 1 question for a Reviewee, Reviewer, and Secondary Reviewer to continue.">
                      <div>Next Step</div>
                    </Tooltip>
                    )}
                    {!isMatrixOrg && (
                    <Tooltip interactive content="You must have at least 1 question for a Reviewee and Reviewer to continue.">
                      <div>Next Step</div>
                    </Tooltip>
                    )}
                  </>
                )}
                {!isDisabled && (
                  <div>Next Step</div>
                )}
              </LeadrButton>
            </>
          )}
        </>
      )}
    />
    <CycleHeader
      cycleTitle={cycle?.name}
      cycleDecriptionHTML={cycle?.description}
      assesmentPeriodEnd={cycle?.assessmentPeriodEnd}
      assesmentPeriodStart={cycle?.assessmentPeriodStart}
      reviewDueBy={cycle?.assessmentDueDate}
      signOffDueBy={cycle?.signOffDueDate}
      type={cycle?.typeEnum}
      viewCyclePerspective={viewCyclePerspective}
    />
    <div css={[styles.setupContainer, styles.boxShadow]}>
      <QuestionPreview
        formContext={formContext}
        topics={topics}
        isQuestions
        cycleId={cycleId}
        cycle={cycle}
        onFormSubmit={onFormSubmit}
        updateQuestionView={updateQuestionView}
        setEditQuestion={setEditQuestion}
        editQuestion={editQuestion}
      />
      {!addQuestion && !cycleIsNotDraft && (
        <LeadrButton
          css={styles.addQuestionButton}
          onClick={() => { updateQuestionView(true); }}
          data-test-id="reviewsAddQuestion"
        >
          + Add New Question
        </LeadrButton>
      )}

      {addQuestion && (
        <AddQuestion
          participationEnum={cycle.participationEnum}
          onFormSubmit={onFormSubmit}
          updateQuestionView={updateQuestionView}
          topic={DEFAULT_TOPIC}
          editQuestion={editQuestion}
        />
      )}
    </div>
  </>
);

interface QuestionsSectionProps {
  cycle: CycleShape,
  cycleId: string,
  viewCyclePerspective: ViewCyclePerspective,
  isMatrixOrg: boolean,
}
const QuestionsSection = ({
  cycle,
  cycleId,
  viewCyclePerspective,
  isMatrixOrg,
}: QuestionsSectionProps): JSX.Element => {
  const [editQuestion, setEditQuestion] = useState('');
  const { data: topics }: TopicReturnById = useGetTopicForCycle({ cycleId });
  const [addQuestion, setAddQuestion] = useState(topics?.length === 0 ?? true);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const cycleIsNotDraft = cycle.statusEnum !== ReviewCycleStatusEnum.Draft;

  const topicToEdit = topics?.find((thisTopic) => thisTopic.uid === editQuestion);
  const { formContext } = useTopicFormContext({
    topic: topicToEdit,
    isMatrixOrg,
  });

  // Check to see if there are topics targeting all relevant participant types.
  useEffect(() => {
    let hasReviewer = false;
    let hasReviewee = false;
    let hasSecondaryReviewer = false;

    topics?.forEach((topic) => {
      const topicFlags = FlaggedEnum(topic.topicTargetEnum);
      hasReviewee ||= topicFlags.hasFlag(ParticipantTypeEnum.Reviewee);
      hasReviewer ||= topicFlags.hasFlag(ParticipantTypeEnum.Reviewer);
      hasSecondaryReviewer ||= topicFlags.hasFlag(ParticipantTypeEnum.SecondaryReviewer);
    });

    if (isMatrixOrg && hasReviewee && hasReviewer && hasSecondaryReviewer) {
      setIsDisabled(false);
    } else if (!isMatrixOrg && hasReviewee && hasReviewer) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isMatrixOrg, topics]);

  const onSuccess = async (): Promise<void> => {
    setEditQuestion('');
    await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewTopics'] });
    setAddQuestion(false);
    formContext.reset();
  };

  const { mutate: createTopicForCycle } = useCreateTopicForCycle();
  const updateTopicForCycleMutation = useUpdateTopicForCycle(cycleId);

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    const formData = FlaggedEnum(cycle.participationEnum).hasFlag(ParticipantTypeEnum.SecondaryReviewer)
      ? conformToDtoMatrixOrg(data as FormValuesMatrixOrg) : conformToDto(data);
    if (editQuestion.length) {
      updateTopicForCycleMutation({ topic: formData, cycleId, topicId: editQuestion }, { onSuccess });
    } else {
      createTopicForCycle({ cycleId, topics: [formData as TopicShape] }, { onSuccess });
    }
  };

  const handleClick = (perspective: ViewCyclePerspective): void => {
    dispatch(navigateAction({
      pathname: `${REVIEWS_CONTINUE_CREATING_CYCLE}/${perspective}`,
      params: {
        cycleId,
      },
    }));
  };
  const backToReviewCycleDetails = (): void => {
    dispatch(navigateAction({
      pathname: `${BASE_ROUTE}reviews/admin/cycle/${cycleId}`,
      params: {
        cycleId,
      },
    }));
  };

  const updateQuestionView = async (value: boolean): Promise<void> => {
    setAddQuestion(value);
    setEditQuestion('');
    await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewTopics'] });
    formContext.reset();
  };

  const hookProps = {
    cycle,
    formContext,
    onFormSubmit,
    handleClick,
    backToReviewCycleDetails,
    addQuestion,
    updateQuestionView,
    viewCyclePerspective,
    topics,
    isDisabled,
    cycleId,
    setEditQuestion,
    editQuestion,
    isMatrixOrg,
    cycleIsNotDraft,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, QuestionsSection };
export default QuestionsSection;
