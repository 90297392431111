import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import {
  AssignedLearning, ReceivedLearning, SCReceivedLearningDetail, SCRequestedLearningDetail,
} from '~Learning/const/interfaces';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { assignedLearningQueryKeys } from './queryKeys';
import { receivedLearningQueryKeys } from '../received/queryKeys';

interface UpdateSingleLearningDueDateParams {
  learningId: string,
  dueDate: string,
}

const updateSingleLearningDueDate = async (
  updateSingleLearningDueDateRequest: UpdateSingleLearningDueDateParams,
): Promise<HttpCallReturn<unknown>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/update/${updateSingleLearningDueDateRequest.learningId}`;

  // If null, send an empty string, this allows the user to clear the dueDate
  return patchApi<unknown>(serverUrl, { dueDate: updateSingleLearningDueDateRequest.dueDate || null });
};

export const useUpdateSingleLearningDueDate = (): UseMutateFunction<HttpCallReturn<unknown>, unknown, UpdateSingleLearningDueDateParams> => {
  const mutation = useMutation({
    mutationFn: updateSingleLearningDueDate,
    onMutate: async (singleLearningUpdate: UpdateSingleLearningDueDateParams) => {
      // #region Cancel Queries
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.singleContentDetail(singleLearningUpdate.learningId) });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.singleContentDetail(singleLearningUpdate.learningId) });
      // #endregion

      // #region Get Previous Data
      const previousReceivedLearningList = queryClient.getQueryData(receivedLearningQueryKeys.list());
      const previousAssignedLearningList = queryClient.getQueryData(assignedLearningQueryKeys.list());
      const previousReceivedLearningDetail = queryClient.getQueryData(receivedLearningQueryKeys.singleContentDetail(singleLearningUpdate.learningId));
      const previousAssignedLearningDetail = queryClient.getQueryData(assignedLearningQueryKeys.singleContentDetail(singleLearningUpdate.learningId));
      // #endregion

      // #region Update Received Single Learning Details for Learning that was just updated
      queryClient.setQueryData<HttpCallReturn<SCReceivedLearningDetail>>(
        receivedLearningQueryKeys.singleContentDetail(singleLearningUpdate.learningId),
        (oldReceivedLearningDetail) => {
          if (oldReceivedLearningDetail) {
            const newReceivedLearningDetail = cloneDeep(oldReceivedLearningDetail);
            if (newReceivedLearningDetail) {
              newReceivedLearningDetail.response.dueDate = singleLearningUpdate.dueDate;
            }

            return newReceivedLearningDetail;
          }
          return oldReceivedLearningDetail;
        },
      );
      // #endregion

      // #region Update Received List to have the Learning update
      queryClient.setQueryData<ReceivedLearning[]>(receivedLearningQueryKeys.list(), (oldReceivedLearningList) => {
        if (oldReceivedLearningList) {
          const newReceivedLearningList = [...oldReceivedLearningList];

          const receivedLearningToUpdateIndex = newReceivedLearningList.findIndex(
            (receivedLearning) => receivedLearning.id === singleLearningUpdate.learningId,
          );

          if (receivedLearningToUpdateIndex !== -1) {
            const newReceivedLearning = cloneDeep(newReceivedLearningList[receivedLearningToUpdateIndex]);

            newReceivedLearning.dueDate = singleLearningUpdate.dueDate;

            newReceivedLearningList.splice(receivedLearningToUpdateIndex, 1, newReceivedLearning);
          }

          return newReceivedLearningList;
        }

        return [];
      });
      // #endregion

      // #region Update Assigned Single Learning Details for Learning that was just updated
      queryClient.setQueryData<HttpCallReturn<SCRequestedLearningDetail>>(
        assignedLearningQueryKeys.singleContentDetail(singleLearningUpdate.learningId),
        (oldAssignedLearningDetail) => {
          if (oldAssignedLearningDetail) {
            const newAssignedLearningDetail = cloneDeep(oldAssignedLearningDetail);
            newAssignedLearningDetail.response.dueDate = singleLearningUpdate.dueDate;

            return newAssignedLearningDetail;
          }

          return oldAssignedLearningDetail;
        },
      );
      // #endregion

      // #region Update Assigned List to have the Learning update
      queryClient.setQueryData<AssignedLearning[]>(assignedLearningQueryKeys.list(), (oldAssignedLearningList) => {
        if (oldAssignedLearningList) {
          const newAssignedLearningList = [...oldAssignedLearningList];

          const assignedLearningToUpdateIndex = newAssignedLearningList.findIndex(
            (assignedLearning) => assignedLearning.id === singleLearningUpdate.learningId,
          );

          const newAssignedLearning = cloneDeep(newAssignedLearningList[assignedLearningToUpdateIndex]);

          newAssignedLearning.dueDate = singleLearningUpdate.dueDate;

          newAssignedLearningList.splice(assignedLearningToUpdateIndex, 1, newAssignedLearning);

          return newAssignedLearningList;
        }

        return [];
      });
      // #endregion

      // Return a context object with the old snapshotted values
      return {
        previousReceivedLearningList,
        previousAssignedLearningList,
        previousReceivedLearningDetail,
        previousAssignedLearningDetail,
      };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: receivedLearningQueryKeys.list() });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
    onError: (_, singleLearningUpdate, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(receivedLearningQueryKeys.list(), snapshot?.previousReceivedLearningList);
      queryClient.setQueryData(assignedLearningQueryKeys.list(), snapshot?.previousAssignedLearningList);
      queryClient.setQueryData(receivedLearningQueryKeys.singleContentDetail(singleLearningUpdate.learningId), snapshot?.previousReceivedLearningDetail);
      queryClient.setQueryData(assignedLearningQueryKeys.singleContentDetail(singleLearningUpdate.learningId), snapshot?.previousAssignedLearningDetail);

      toast.error('There was an error updating the learning due date. Please try again.', {
        autoClose: 1500,
      });
    },
  });

  return mutation.mutate;
};
