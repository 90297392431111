import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ButtonBase } from '@mui/material';
import { Fragment, useCallback } from 'react';
import BaseballCardAvatar from '~Deprecated/common/Components/People/Avatar/BaseballCardAvatar';
import { baseballCardColors, palette } from '~Common/styles/colors';
import {
  separator, withLineClamp, withWordWrap, withTruncate,
} from '~Common/styles/mixins';
import FlatButton from '~Common/V3/components/FlatButton';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { personalityTypeDetailDrawerTemplate } from '~People/BaseballCard/Drawers/PersonalityTypeDetailDrawer';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import NotificationBanner from '~People/BaseballCard/Drawers/NotificationBanner';
import Tooltip from '~Common/components/Tooltip';
import {
  getOrganizationUserId,
  getUserId,
} from '~Common/utils/localStorage';
import { useBaseballCardNotes } from '~People/hooks/baseballCard/useBaseballCardNotes';
import { PERSONALITY_INFO_LINKS } from '~Common/const/personalityLinks';
import ImpersonateButton from '~People/BaseballCard/Impersonate/ImpersonateButton';
import LocationLink from '~Deprecated/ui/components/LocationLink';
import { MEETING_TYPES } from '~Meetings/const/meetingTypes';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { useGetPersonById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import {
  Profile, ProfilePersonalities, Team, UserStatus, WorkingGeniusPersonality,
  CustomPersonalities, Personality,
} from '~Common/const/interfaces';
import { WorkingGeniusType } from '~People/BaseballCard/types';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faNoteSticky } from '@fortawesome/pro-light-svg-icons';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useGetFormattedCustomFavorites } from '~Admin/hooks/settings/useGetFormattedCustomFavorites';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { useGetFormattedCustomPersonalityTypes } from '~Admin/hooks/settings/useGetFormattedCustomPersonalityTypes';
import { BaseballCardPerson } from '~People/types';
import { teamDetailDrawerTemplate } from './Drawers/TeamDetailDrawer';
import { notesDrawerTemplate } from './Drawers/NotesDrawer';
import { bioDrawerTemplate } from './Drawers/BioDrawer';

const styles = {
  baseballCard: css({
    height: 'unset',
    minHeight: '100%',
  }),
  skeleton: (isDrawer: boolean) => css({
    height: isDrawer ? '100%' : '31.25rem',
  }),
  details: css({
    padding: '1rem',
  }),
  personalInfoWrapper: css({
    display: 'flex',
  }),
  personalInfo: css({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    marginInlineEnd: '0.25rem',
  }),
  userName: css({
    fontSize: '1.3125rem',
    fontWeight: '500',
    color: palette.neutrals.gray800,
    wordBreak: 'break-all',
  }, withLineClamp(1)),
  department: css({
    fontSize: '.875rem',
    color: palette.neutrals.gray700,
  }),
  notesBtn: css({
    justifyContent: 'center',
    backgroundColor: palette.neutrals.gray100,
    color: palette.brand.indigo,
    fontSize: '0.6875rem',
    textTransform: 'uppercase',
    flexDirection: 'column',
    display: 'flex',
    borderRadius: '.3125rem',
  }),
  notesIcon: css({
    flexDirection: 'column',
    color: palette.brand.indigo,

    svg: {
      fontSize: '1.5rem',
      width: '1.5rem',
      height: '1.5rem',
    },
  }),
  bioWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem 0',
  }),
  bioOverview: css({
    marginBlockEnd: '0.25rem',
    fontSize: '.875rem',
    fontWeight: '400',
    color: baseballCardColors.bio,
    maxHeight: '4.0625rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }, withLineClamp(3)),
  moreInfo: css({
    '&, &.MuiButtonBase-root': {
      fontSize: '.75rem',
      fontWeight: '500',
      color: palette.brand.indigo,
      display: 'flex !important',
      justifyContent: 'flex-start',
      padding: '0.25rem',
    },
  }, withLineClamp(1)),
  personalityTypeWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.75rem',
  }),
  personalityTypeRow: css({
    display: 'flex',
    marginBottom: '.75rem',

    '&:first-of-type': {
      marginBlockStart: '0',
    },

    '&:last-of-type': {
      marginBlockEnd: '0',

      '& > div': {
        marginBlockEnd: '0',
      },
    },
  }),
  personalityTypeInfo: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    marginBlockEnd: '0.25rem',
    marginInlineEnd: '0.25rem',

    '& > div:first-of-type': {
      marginBlockStart: '0',
    },

    '& > div:last-of-type': {
      marginBlockEnd: '0',
    },
  }),
  personalityType: css({
    fontSize: '.75rem',
    fontWeight: '400',
    color: palette.neutrals.gray700,
  }),
  personalityTest: css({
    fontSize: '.875rem',
    fontWeight: '500',
    color: palette.neutrals.gray800,
  }),
  personalityTypeDetails: css({
    '&, &.MuiButtonBase-root': {
      marginInlineStart: '0.25rem',
      fontSize: '.6875rem',
      fontWeight: '500',
      color: baseballCardColors.buttonColor,
      display: 'flex',
      alignItems: 'flex-start',
      padding: '0.25rem',
    },
  }),
  favoritesWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '.75rem',
  }),
  favoritesTitle: css({
    fontSize: '.75rem',
    fontWeight: '400',
    marginBlock: '-.1rem',
    color: palette.neutrals.gray700,
  }),

  favoritesContent: css({
    wordBreak: 'break-word',
    fontSize: '.875rem',
    fontWeight: '500',
    color: palette.neutrals.gray800,
    width: '100%',
  }),
  capitalize: css({
    textTransform: 'uppercase',
  }),
  strengthTypeWrapper: css({
    display: 'flex',
  }, withWordWrap()),
  groupContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  detailsButton: css({
    color: palette.brand.indigo,
    fontSize: '.875rem',
    fontWeight: '500',
    display: 'table-cell',
    alignItems: 'center',
  }),

  teamDisplay: css({
    display: 'flex',
    width: '90%',
  }),

  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),

  teamName: css({}, withTruncate()),

  groupsButton: css({
    padding: '0',
    marginBottom: '.25rem',
    overflow: 'hidden',
    width: '100%',
  }, withTruncate()),
  personalitiesButton: css({
    margin: '0',
    padding: '0',
    fontWeight: '500',
    fontSize: '.875rem',
  }),
  spacer: css({
    marginTop: '1rem',
  }),
  genericWrapper: css({
    marginBottom: '.75rem',
  }),
  workingGeniusTitle: css({
    fontSize: '.75rem',
    fontWeight: '400',
    color: palette.neutrals.gray700,
  }),
  workingGeniusContent: css({
    fontSize: '.875rem',
    fontWeight: '500',
    color: palette.brand.indigo,
  }),
};
interface CustomSetting {
  title: string;
  valueName?: string;
  value?: string;
  enabled: boolean;
  link?: string;
  urlValueName?: string;
}

interface ViewProps {
  user: Profile,
  hasPersonalityAssessmentEnabled: boolean,
  onMoreInfoClick: () => void,
  onPersonalityDetailsClick: (personality?: Personality) => void,
  onNoteClick: () => void,
  strengths?: string,
  personalities: Partial<ProfilePersonalities>,
  customPersonalities: CustomPersonalities[],
  customFavorites: {
    enabled: boolean;
    title: string;
    value: string;
  }[],
  canImpersonate: boolean,
  frontendUserGroups: boolean,
  onTeamClick: (team: Team) => void,
  workingGeniusPersonality: WorkingGeniusPersonality,
  enableWorkingGenius?: boolean,
  webAppTeamsV2: boolean,
}

function View({
  user,
  hasPersonalityAssessmentEnabled,
  onMoreInfoClick,
  onPersonalityDetailsClick,
  onNoteClick,
  strengths,
  personalities,
  customPersonalities,
  customFavorites,
  canImpersonate,
  frontendUserGroups,
  onTeamClick,
  workingGeniusPersonality,
  enableWorkingGenius,
  webAppTeamsV2,
}: ViewProps): JSX.Element {
  const hasValue = (customSetting: CustomSetting[]): boolean => customSetting.some((item) => item.value !== undefined);
  const shouldShowSeperator = strengths || hasValue(customPersonalities) || user.food || user.snacks || user.drink || hasValue(customFavorites);
  return (
    <div css={styles.baseballCard}>
      <BaseballCardAvatar
        imgUrl={user.profileImageUrl}
        name={`${user.firstName} ${user.lastName}`}
      />
      {user?.personalizedCallouts?.length > 0 && (
        <>
          {user.personalizedCallouts.map((callout) => (
            <NotificationBanner callout={callout} />
          ))}
        </>
      )}
      <div css={styles.details}>
        <div css={styles.personalInfoWrapper}>
          <div css={styles.personalInfo}>
            <div
              css={styles.userName}
            >
              {`${user.firstName} ${user.lastName}`}
            </div>
            <Tooltip content={user.jobTitle}>
              <div css={[styles.department]}>{user.jobTitle}</div>
            </Tooltip>
            <Tooltip content={user.department}>
              <div css={[styles.department]}>{user.department}</div>
            </Tooltip>
          </div>
          <LeadrButton
            css={styles.notesBtn}
            data-test-id="privateNotes"
            type="submit"
            size="small"
            color="light"
            onClick={onNoteClick}
          >
            <LeadrButton.IconAndText
              icon={faNoteSticky}
              text="Notes"
              css={styles.notesIcon}
            />
          </LeadrButton>
        </div>
        <div css={separator('.75rem', '.75rem', true)} />
        <div css={styles.bioWrapper}>
          <HTMLRenderer css={styles.bioOverview} htmlText={user.aboutMe} />
          <ButtonBase
            disableRipple
            onClick={onMoreInfoClick}
            css={styles.moreInfo}
          >
            {`More about ${user.firstName}`}
          </ButtonBase>
        </div>

        {enableWorkingGenius && workingGeniusPersonality
          && Object.keys(workingGeniusPersonality).length > 0 && (
            <Fragment key={workingGeniusPersonality.personalityId}>
              <div css={separator('.75rem', '.75rem', true)} />
              <div css={[styles.personalityTypeWrapper, styles.spacer]}>
                <div css={styles.personalityTypeRow}>
                  <div css={styles.personalityTypeInfo}>
                    <div css={styles.workingGeniusTitle}>Working Genius</div>
                    <div css={styles.workingGeniusContent}>
                      <LeadrButton
                        onClick={() => onPersonalityDetailsClick(workingGeniusPersonality)}
                        data-test-id="workingGeniusDetails"
                        variant="text"
                        size="small"
                        css={styles.personalitiesButton}
                      >
                        {workingGeniusPersonality.skills}
                      </LeadrButton>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
        )}
        {hasPersonalityAssessmentEnabled
          && personalities
          && Object.keys(personalities).length > 0 && (
            <>
              {enableWorkingGenius && workingGeniusPersonality
                && Object.keys(workingGeniusPersonality).length === 0 && (
                <div css={separator('.75rem', '.75rem', true)} />
              )}
              <div css={styles.personalityTypeWrapper}>
                {(Object.keys(personalities) as Array<keyof typeof personalities>).map((p) => (
                  <Fragment key={p}>
                    <div css={styles.personalityTypeRow}>
                      <div css={styles.personalityTypeInfo}>
                        <div css={[styles.workingGeniusTitle]}>{p}</div>
                        <div css={styles.workingGeniusContent}>
                          <LeadrButton
                            onClick={() => onPersonalityDetailsClick(personalities[p])}
                            data-test-id={`${p}Details`}
                            variant="text"
                            size="small"
                            css={styles.personalitiesButton}
                          >
                            {personalities[p]?.label}
                          </LeadrButton>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </>
        )}
        {shouldShowSeperator && (
          <div css={separator('.75rem', '.75rem', true)} />
        )}
        {strengths && (
          <>
            <div css={styles.genericWrapper}>
              <div css={styles.personalityType}>Strengths</div>
              <div css={styles.strengthTypeWrapper}>
                <div css={[styles.personalityTest]}>{strengths}</div>
              </div>
            </div>
          </>
        )}
        {customPersonalities.map(
          (customPersonality) => customPersonality.enabled && (
            <Fragment key={customPersonality.title}>
              {customPersonality.value && (
              <div
                css={styles.genericWrapper}
                key={customPersonality.title}
              >
                <div css={styles.personalityType}>
                  {customPersonality.title}
                </div>
                <div>
                  <div css={[styles.personalityTest]}>
                    {customPersonality.link && (
                    <LocationLink
                      meetingLocation={customPersonality.link}
                      text={customPersonality.value}
                    />
                    )}
                    {!customPersonality.link && (
                    <span>{customPersonality.value}</span>
                    )}
                  </div>
                </div>
              </div>
              )}
            </Fragment>
          ),
        )}
        {user.food && (
          <>
            <div css={styles.favoritesWrapper}>
              <div css={styles.favoritesTitle}>
                Favorite Foods
              </div>
              <div css={[styles.favoritesContent]}>{user.food}</div>
            </div>
          </>
        )}
        {user.snacks && (
          <>
            <div css={styles.favoritesWrapper}>
              <div css={styles.favoritesTitle}>
                Favorite Snacks
              </div>
              <div css={[styles.favoritesContent]}>{user.snacks}</div>
            </div>
          </>
        )}
        {user.drink && (
          <>
            <div css={styles.favoritesWrapper}>
              <div css={styles.favoritesTitle}>
                Favorite Drink
              </div>
              <div css={[styles.favoritesContent]}>{user.drink}</div>
            </div>
          </>
        )}
        {customFavorites.map(
          (customFavorite) => customFavorite.enabled && (
            <Fragment key={customFavorite.title}>
              {customFavorite.value && (
              <div css={styles.favoritesWrapper}>
                <div css={styles.favoritesTitle}>
                  {customFavorite.title}
                </div>
                <div css={[styles.favoritesContent]}>
                  {customFavorite.value}
                </div>
              </div>
              )}
            </Fragment>
          ),
        )}

        {frontendUserGroups && (
          <>
            <div css={separator('.75rem', '.75rem', true)} />
            <div css={styles.favoritesWrapper}>
              <div css={styles.favoritesTitle}>{webAppTeamsV2 ? 'Teams' : 'Groups'}</div>
              <div css={styles.groupContainer}>
                <div css={[styles.favoritesContent]}>
                  {user.teams.map((team) => (
                    <FlatButton
                      key={team?.teamId}
                      onClick={() => onTeamClick(team)}
                      css={styles.groupsButton}
                      renderButtonContents={() => (
                        <div css={styles.teamDisplay}>
                          <Tooltip interactive={false} content={team.name}>
                            <div css={[styles.teamName, styles.detailsButton]}>{team.name}</div>
                          </Tooltip>
                        </div>
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {canImpersonate && <ImpersonateButton email={user.email} />}
    </div>
  );
}

interface BaseballCardProps {
  id: string;
  huddleId: string;
  orgId: string;
  isDrawer: boolean;
}
const BaseballCard = ({
  id,
  huddleId,
  orgId,
  isDrawer,
  ...props
}: BaseballCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const { item: meetingDetails } = useMeetingDetails({ id: huddleId, type: MEETING_TYPES.COACHING as MeetingTypeEnum }) ?? {};
  const { attendeeOrgUserIds = [], organizer } = meetingDetails ?? {};
  const { orgSettings } = useOrgDetailsContext();
  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');
  const frontendUserGroups = useFeatureFlag('frontendUserGroups');
  const hasPersonalityAssessmentEnabled = useFeatureFlag('enablePersonalityAssessment');

  const orgUserId = [organizer?.orgUserId, ...attendeeOrgUserIds].filter(
    (attendeeOrgUserId) => attendeeOrgUserId !== getOrganizationUserId(),
  )[0];
  const person = useGetPersonById({ id: orgUserId || id }) as BaseballCardPerson;

  const idToUse = person.userId;
  const creatorId = getUserId();

  const { detail } = useUserProfile(idToUse);
  const baseballCard = detail.data?.response;
  const { formattedCustomFavorites } = useGetFormattedCustomFavorites(idToUse);
  const { formattedCustomPersonalities } = useGetFormattedCustomPersonalityTypes(idToUse);
  useBaseballCardNotes(creatorId, idToUse, orgId);

  const personalities: Record<string, Personality> = {};
  let workingGeniusPersonality: WorkingGeniusPersonality = {} as WorkingGeniusPersonality;
  const [showSkeletonLoaders] = useSkeletonLoaders(!baseballCard);

  const { enableWorkingGenius, workingGeniusUrl } = orgSettings;
  if (baseballCard?.personalities) {
    const personalitiesKeys = Object.keys(baseballCard.personalities) as (keyof ProfilePersonalities)[];

    personalitiesKeys.forEach((key) => {
      const stringKey = key as string;
      if (stringKey === WorkingGeniusType.workingGenius) {
        const skillsString = baseballCard.personalities[WorkingGeniusType.workingGenius].skills.join(' & ');
        workingGeniusPersonality = {
          ...(baseballCard.personalities[key]),
          link: workingGeniusUrl ?? '',
          workingFrustrations: baseballCard.workingFrustrations,
          workingCompetencies: baseballCard.workingCompetencies,
          skills: skillsString as unknown as string[],
        };
      } else {
        personalities[key] = {
          link: PERSONALITY_INFO_LINKS[key as keyof typeof PERSONALITY_INFO_LINKS],
          ...(baseballCard.personalities[key]),
        };
      }
    });
  }

  const onPersonalityDetailsClick = useCallback(
    (personality?: Personality) => {
      if (!personality) return;
      dispatch(
        pushDrawerAction({
          drawer: {
            ...personalityTypeDetailDrawerTemplate,
            args: {
              personality,
              userId: idToUse,
            },
          },
        }),
      );
    },
    [dispatch, idToUse],
  );

  const onMoreInfoClick = useCallback(() => {
    dispatch(
      pushDrawerAction({
        drawer: {
          ...bioDrawerTemplate,
          args: {
            id: idToUse,
          },
        },
      }),
    );
  }, [dispatch, idToUse]);

  const onNoteClick = useCallback(() => {
    dispatch(
      pushDrawerAction({
        drawer: {
          ...notesDrawerTemplate,
          args: {
            userId: idToUse,
            backdropStyles: css({
              backdropFilter: 'none',
              position: 'unset',
              width: 0,
              height: 0,
            }),
          },
        },
      }),
    );
  }, [dispatch, idToUse]);

  const onTeamClick = useCallback((team: Team) => {
    const teamParam = team;
    dispatch(
      pushDrawerAction({
        drawer: {
          ...teamDetailDrawerTemplate,
          args: {
            team: teamParam,
            backdropStyles: css({
              backdropFilter: 'none',
              position: 'unset',
              width: 0,
              height: 0,
            }),
          },
        },
      }),
    );
  }, [dispatch]);

  if (showSkeletonLoaders) {
    return (
      <SkeletonLoader
        width={300}
        variant="rectangular"
        css={styles.skeleton(isDrawer)}
        renderComponent={() => <div />}
      />
    );
  }

  const formattedStrengths = baseballCard?.strengths?.join(', ');

  const hookProps = {
    id: idToUse,
    user: baseballCard!,
    onNoteClick,
    onMoreInfoClick,
    onPersonalityDetailsClick,
    onTeamClick,
    hasPersonalityAssessmentEnabled,
    strengths: formattedStrengths,
    personalities,
    customFavorites: formattedCustomFavorites,
    customPersonalities: formattedCustomPersonalities,
    canImpersonate: creatorId !== baseballCard?.userId && person.administrativeStatus === UserStatus.active,
    frontendUserGroups,
    workingGeniusPersonality,
    enableWorkingGenius,
    webAppTeamsV2,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

BaseballCard.propTypes = {
  id: PropTypes.string,
  orgId: PropTypes.string,
  huddleId: PropTypes.string.isRequired,
  isDrawer: PropTypes.bool,
};

BaseballCard.defaultProps = {
  orgId: null,
  id: '',
  isDrawer: false,
};

export { View };
export default BaseballCard;
