import { useCallback, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { queryClient } from '~Common/const/queryClient';
import { COACHING_ROUTE, MEETING_ROUTE } from '~Common/const/routes';
import { normalizeMeetingToServer } from '~Deprecated/utils/normalizers/meeting';
import { navigateAction } from '~Deprecated/actions/navigate';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { ONE_TIME_MEETING } from '~Common/const/constraints';
import { USE_NEXT_MEETINGS_QUERY_KEY } from '~Deprecated/hooks/feedback/useNextMeetings';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { MeetingFrequency } from '~Meetings/const/meetingsInterfaces';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { calendarIntQueryKeys } from './CalendarIntegration/queryKeys';

export const createCoachingApi = async ({
  organizationId,
  ...data
}) => postApi(`/organizations/${organizationId}/coachings`, data, {});

export const createMeetingApi = async ({
  organizationId,
  ...data
}) => postApi(`/organizations/${organizationId}/meetings`, data, {});

export const createHuddleApi = async ({
  organizationId,
  ...data
}) => postApi(`/organizations/${organizationId}/huddles/series`, data, {});

export const useCreateHuddle = (onMeetingCreationCallback) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const usingNylas = useFeatureFlag('nylas');
  const organizationId = getOrganizationId();
  const orgUserId = getOrganizationUserId();
  const { data: peopleData } = usePeopleQuery();
  const { timezone } = useTimezone();

  const createHuddle = useCallback((meetingData) => {
    const isCalendarEvent = !!meetingData.eventId;
    const { isCoaching, ...meeting } = normalizeMeetingToServer({
      ...meetingData,
      timezone: meetingData.timezone || timezone,
      usingNylas,
      isCreateHuddle: true,
    });

    // The old create meeting/coaching APIs implicitly included the current user
    // The new create huddle API does not, so we need to add them to the list
    // ToDo: The create meeting drawer should be updated to handle this once
    // the old create meeting/coaching workflows have been removed.
    meeting.attendeeIds.push(orgUserId);

    meeting.attendeeIds = meeting.attendeeIds.filter((id) => !!id);

    if (isCalendarEvent || !isCoaching || meeting.frequency === MeetingFrequency.ONE_TIME) {
      // While the coaching suggestions drawer returns orgUserIds the original
      // participants drawer (and Calendar Events) returns userIds (Also used for one-time 1:1 meetings)
      // ToDo: Update the drawer to return orgUserIds when the old create meeting/coaching
      // workflow has been removed.
      meeting.attendeeIds = meeting.attendeeIds.map((id) => peopleData[id]?.orgUserId);
    }

    return createHuddleApi({
      organizationId,
      ...meeting,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usingNylas, organizationId, orgUserId, peopleData]);

  return useMutation({
    mutationFn: createHuddle,
    onMutate: () => {
      toastId.current = toast.info('Creating your meeting…', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: <MeetingsErrorToast />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables) => {
      toast.update(toastId.current, {
        render: 'Your meeting has been created. Have a great day.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const nextMeetingInstanceId = data?.response?.instances[0];
      onMeetingCreationCallback?.(nextMeetingInstanceId);

      let pathname;
      const search = {
        orgId: organizationId,
      };

      if (variables.type === 'COACHING') {
        pathname = COACHING_ROUTE;
        search.coachingId = nextMeetingInstanceId;
        // eslint-disable-next-line prefer-destructuring
      } else {
        search.meetingId = nextMeetingInstanceId;
        pathname = MEETING_ROUTE;
      }

      if (variables.teamId) {
        search.teamId = variables.teamId;
      }

      dispatch(navigateAction({
        pathname,
        search,
      }));

      if (usingNylas) {
        queryClient.invalidateQueries({ queryKey: [organizationId, orgUserId, 'nylas'] });
      }

      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'suggestions'] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'timeline'] });
      queryClient.invalidateQueries({ queryKey: USE_NEXT_MEETINGS_QUERY_KEY });
      queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId()) });
    },
  });
};

export const useCreateMeeting = (selectedFrequency, onMeetingCreationCallback) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const { data: peopleData } = usePeopleQuery({});

  const usingNylas = useFeatureFlag('nylas');
  const organizationId = getOrganizationId();
  const { timezone } = useTimezone();

  const oneTimeCreate = 'Creating this One Time Meeting... Hope you are having a good day.';
  const recurringCreate = 'Creating this Recurring Meeting... Hope you are having a good day.';

  const mutateText = selectedFrequency === ONE_TIME_MEETING ? oneTimeCreate : recurringCreate;

  const oneTimeSuccess = 'Your One Time Meeting has been created. Have a great day.';
  const coachingSuccess = 'Your Recurring Meeting has been created. Have a great day.';

  const successText = selectedFrequency === ONE_TIME_MEETING ? oneTimeSuccess : coachingSuccess;

  const createCoachingMutation = useMutation({
    mutationFn: createCoachingApi,
    onMutate: () => {
      toastId.current = toast.info(`${mutateText}`, { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: <MeetingsErrorToast />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data) => {
      toast.update(toastId.current, {
        render: successText,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const id = data?.response?.coachingIds[0]?.coachingId;
      onMeetingCreationCallback?.(id);

      dispatch(navigateAction({
        pathname: COACHING_ROUTE,
        search: {
          orgId: organizationId,
          coachingId: id,
        },
      }));

      if (usingNylas) {
        queryClient.invalidateQueries({ queryKey: calendarIntQueryKeys.all });
      }

      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'suggestions'] });
      queryClient.invalidateQueries({ queryKey: [organizationId, 'timeline'] });
      queryClient.invalidateQueries({ queryKey: USE_NEXT_MEETINGS_QUERY_KEY });
      queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId()) });
    },
  });

  const createTeamMeetingMutation = useMutation({
    mutationFn: createMeetingApi,
    onMutate: () => {
      toastId.current = toast.info('Creating this meeting... hope someone brings donuts.', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'The create failed, but you are not a failure.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data) => {
      toast.update(toastId.current, {
        render: 'Your meeting has been created. Have a great day.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const id = data?.response[0]?.meetingId;

      dispatch(navigateAction({
        pathname: MEETING_ROUTE,
        search: {
          orgId: organizationId,
          meetingId: id,
        },
      }));

      if (usingNylas) {
        queryClient.invalidateQueries({ queryKey: calendarIntQueryKeys.all });
      }

      queryClient.invalidateQueries({ queryKey: [organizationId, 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId()) });
    },
  });

  const execute = (data) => {
    const { isCoaching, ...meeting } = normalizeMeetingToServer({
      ...data,
      timezone: data.timezone || timezone,
      usingNylas,
    });

    // Coaching suggestions uses orgUserIds, create API expects userIds
    if (isCoaching) {
      meeting.attendeeId = [peopleData[meeting.attendeeId[0]].userId];
    }

    if (isCoaching) {
      return createCoachingMutation.mutate({
        ...meeting,
        organizationId,
      });
    }

    return createTeamMeetingMutation.mutate({
      ...meeting,
      organizationId,
    });
  };

  return {
    isLoading: createCoachingMutation.isPending || createTeamMeetingMutation.isPending,
    execute,
  };
};
