import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { getDraft, setDraft } from '~Deprecated/utils/dataManagement';
import QuestionDisplay from '~Common/components/Drawers/QuestionDisplay';
import AnswerDisplay from '~Common/components/Drawers/AnswerDisplay';
import { FEEDBACK_SHAPE } from '~Common/const/proptypes';
import { noop } from '~Deprecated/utils/';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import { getUserId } from '~Common/utils/localStorage';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';

const styles = {
  questions: css`
    margin-top: 20px;
    margin-bottom: 8px;
  `,
  responses: css`
    margin-top: 8px;
    margin-bottom: 8px;
  `,
};

const View = ({
  feedback,
  onAddToMeeting,
  getResponseDraftKey,
  renderIntroductionEditor,
}) => (
  <>
    {feedback?.introduction && (
      <>
        {renderIntroductionEditor()}
      </>
    )}
    {feedback?.questions?.map((question) => (
      <div key={question?.questionId}>
        <div css={styles.questions}>
          <QuestionDisplay
            userId={getUserId()}
            richText={question?.questionText || ''}
            css={styles.questions}
          />
        </div>
        { !feedback?.isCompletedByLoggedInUser && (
          <div css={styles.responses}>
            <Froala
              name="response"
              initialValue={getDraft(getResponseDraftKey(question)) ?? ''}
              onChange={({ value }) => setDraft(getResponseDraftKey(question), value)}
              required
            />
          </div>
        )}
        { feedback?.isCompletedByLoggedInUser && (
          <div css={styles.responses}>
            <AnswerDisplay
              userId={question?.responses?.[0]?.userId}
              richText={question?.responses?.[0]?.responseText || ''}
              status={ANSWER_STATUS.COMPLETED}
              tooltip="Add this response to the next meeting"
              onClick={() => onAddToMeeting({ otherUserId: feedback?.owner.id, questions: [question] })}
            />
          </div>
        )}
      </div>
    ))}
  </>
);

View.propTypes = {
  feedback: FEEDBACK_SHAPE,
  onAddToMeeting: PropTypes.func,
  getResponseDraftKey: PropTypes.func,
  renderIntroductionEditor: PropTypes.func.isRequired,
};

View.defaultProps = {
  feedback: null,
  onAddToMeeting: noop,
  getResponseDraftKey: noop,
};

const RespondentResponses = ({ feedback, ...props }) => {
  const renderIntroductionEditor = () => (
    <Froala
      label="Objective"
      name="introduction"
      isReadOnly
      initialValue={feedback?.introduction || ''}
    />
  );

  const hookProps = {
    renderIntroductionEditor,
    feedback,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

RespondentResponses.propTypes = {
  feedback: FEEDBACK_SHAPE,
};

RespondentResponses.defaultProps = {
  feedback: null,
};

export default RespondentResponses;
