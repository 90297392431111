import { useCallback } from 'react';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

import useRealtime from '~Common/hooks/useRealtime';
import { getOrganizationId } from '~Common/utils/localStorage';
import {
  AgendaCommentRealTimeMessageType,
  AgendaTopicRealTimeMessageType,
  AgendaSectionRealTimeMessageType,
  AgendaRealTimeMessageType,
  RealTimeMeetingMessagePayloadMap,
} from '~Meetings/const/realTimeMeetingMessages';

export interface UseMeetingRealtimeProps {
  huddleId: string,
  enabled?: boolean,
}

export interface UseMeetingRealtimeReturn {
  sendMessage: (
    type: AgendaRealTimeMessageType | AgendaTopicRealTimeMessageType | AgendaSectionRealTimeMessageType | AgendaCommentRealTimeMessageType,
    // @ts-expect-error ToDo: Fix
    payload: RealTimeMeetingMessagePayloadMap[typeof type]) => void,
}

const useMeetingRealtime = ({
  huddleId,
  enabled = true,
}: UseMeetingRealtimeProps): UseMeetingRealtimeReturn => {
  const organizationId = getOrganizationId();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const channelName = `organizations:${organizationId!}:meetingInstances:${huddleId}`;
  const webClientRealTimeMeetings = useFeatureFlag('webClientRealTimeMeetings');

  const {
    send,
  } = useRealtime({
    channelName,
    enabled: enabled && webClientRealTimeMeetings,
  });

  const sendMessage = useCallback(
    <T extends AgendaRealTimeMessageType |AgendaTopicRealTimeMessageType | AgendaSectionRealTimeMessageType | AgendaCommentRealTimeMessageType>(
      type: T,
      // @ts-expect-error ToDo: Fix
      payload: RealTimeMeetingMessagePayloadMap[T],
    ) => {
      if (webClientRealTimeMeetings) {
        // @ts-expect-error ToDo: Fix
        send(type, payload);
      }
    },
    [send, webClientRealTimeMeetings],
  );

  return {
    sendMessage,
  };
};

export default useMeetingRealtime;
