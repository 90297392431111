import {
  Switch, Route,
} from 'react-router-dom';

import { LEARNING_LIST_ASSIGNED, LEARNING_LIST_RECEIVED } from '~Common/const/routes';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import LearningTopBar from '~Learning/components/LearningTopBar';
import AssignedLearningDashboard from '~Learning/components/AssignedLearningDashboard';
import ReceivedLearningDashboard from '~Learning/components/ReceivedLearningDashboard';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';

const Learning = (): JSX.Element => {
  const { isLimitedAccess: isLimitedAccessUser } = useUserPermissions();
  return (
    <Switch>
      { !isLimitedAccessUser && (
        <Route
          exact
          path={[
            LEARNING_LIST_ASSIGNED,
          ]}
          render={() => (
            <BaseLayout>
              <ModuleLandingPageWrapper
                TopComponent={<LearningTopBar initialRoute={LEARNING_LIST_ASSIGNED} />}
                ChildComponent={<AssignedLearningDashboard />}
              />
            </BaseLayout>
          )}
        />
      )}
      <Route
        exact
        path={[
          LEARNING_LIST_RECEIVED,
        ]}
        render={() => (
          <BaseLayout>
            <ModuleLandingPageWrapper
              TopComponent={<LearningTopBar initialRoute={LEARNING_LIST_RECEIVED} />}
              ChildComponent={<ReceivedLearningDashboard />}
            />
          </BaseLayout>
        )}
      />
      <SmartRedirect from="*" to={LEARNING_LIST_RECEIVED} />
    </Switch>
  );
};

export default Learning;
