import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import HuddleCard from '~Meetings/components/MeetingCard';
import { slideFromBottom } from '~Deprecated/ui/styles/animations';
import {
  palette,
} from '~Common/styles/colors';
import ListSection from '~Common/V3/components/ListSection';

const styles = {
  listView: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  `,
  cardContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    '&:not(:first-of-type)': {
      marginTop: '2rem',
    },
  }),
  dateHeader: css({
    color: palette.neutrals.gray700,
    fontWeight: 500,
    fontSize: '1rem',
    marginBottom: '1rem',
  }),
  cardSection: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(20.9375rem, 1fr))',
    width: '100%',
  }),
};

const View = ({
  meetings,
  meetingDates,
  onCardClick,
}) => (
  <div css={styles.listView}>
    {meetingDates.map((date, i) => (
      <div
        css={[
          styles.cardContainer,
          slideFromBottom(i),
        ]}
        key={date}
      >
        <ListSection
          css={styles.dateHeader}
          renderContents={() => (
            <>{date}</>
          )}
        />
        <div css={styles.cardSection}>
          {meetings[date].map((meetingId, index) => (
            <HuddleCard
              onClick={onCardClick}
              css={slideFromBottom(index)}
              id={meetingId}
              key={meetingId}
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);

View.propTypes = {
  meetings: PropTypes.objectOf(PropTypes.array),
  meetingDates: PropTypes.arrayOf(PropTypes.string),
  onCardClick: PropTypes.func.isRequired,
};

View.defaultProps = {
  meetings: [],
  meetingDates: [],
};

const MeetingsList = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

MeetingsList.propTypes = {};

MeetingsList.defaultProps = {};

export { View };
export default MeetingsList;
