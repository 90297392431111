import { Masonry, MasonryProps } from '@mui/lab';
import { ThemeProvider } from '@mui/material/styles';
import appTheme from '~Common/const/appTheme';
import { css } from '@emotion/react';

const styles = {
  masonryListView: css({
    width: 'auto', // Fixes an issue with MUI having extra spacing on the right side of the Masonry
  }),
};

interface MasonryListViewProps extends MasonryProps {
  ids: string[],
  data: Record<string, unknown>,
  renderItem: (id: string, index: number, data: Record<string, unknown>) => JSX.Element,
}

const MasonryListView = ({
  ids,
  data,
  renderItem,
  ...props
}: MasonryListViewProps): JSX.Element => (
  <ThemeProvider theme={appTheme}>
    <Masonry
      css={styles.masonryListView}
      {...props}
    >
      { /* @ts-expect-error TODO: Properly type this with generics */ }
      { ids?.map((id, index) => renderItem(id, index, data?.[id])) }
    </Masonry>
  </ThemeProvider>
);

export default MasonryListView;
