import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import BaseToast from './BaseToast';

const ErrorToast = ({ text }) => (
  <BaseToast
    icon={faXmarkCircle}
    text={text}
  />
);

ErrorToast.propTypes = {
  text: PropTypes.string.isRequired,
};

ErrorToast.defaultProps = {

};

export default ErrorToast;
