import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRepeat, faPaperclipVertical } from '@fortawesome/pro-light-svg-icons';
import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { palette } from '~Common/styles/colors';
import Froala from '~Common/V3/components/Froala';
import Tooltip from '~Common/components/Tooltip';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerInput from '~Common/V3/components/DrawerInput';
import { lighten } from 'polished';
import { SECTION_TITLE_MAX_LENGTH } from '~Meetings/const';
import AttachmentsModal, { MAX_ATTACHMENTS } from '~Meetings/components/details/agenda/AttachmentsModal';
import { Attachment } from '~Meetings/hooks/v2/useGetAgendas';
import { useShowAttachmentsModal } from '~Meetings/hooks/utils/useShowAttachmentsModal';
import { useConfirmAttachmentDeletionModal } from '~Meetings/hooks/utils/useConfirmAttachmentDeletionModal';
import { AttachmentsList } from './AttachmentsList/AttachmentsList';

const styles = {
  attachmentList: css({
    marginTop: '0.25rem',
  }),
  editorContainer: css({
    width: '100%',
  }),
  buttonContainer: css({
    width: 'max-content',
    marginBlockStart: '0.5rem',
    display: 'flex',
    gap: '0.625rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  buttons: css({
    borderRadius: '.3125rem',
    padding: '.375rem .75rem',
  }),
  iconButton: (isActive: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    border: 0,
    padding: '.375rem',
    color: palette.neutrals.gray700,
    '&:hover': {
      backgroundColor: palette.neutrals.gray300,
    },
  }, isActive && ({
    color: palette.brand.indigo,
    backgroundColor: lighten(0.50, palette.brand.indigo),
    '&:hover': {
      backgroundColor: lighten(0.45, palette.brand.indigo),
    },
  })),
  icon: css({
    height: '1.25rem',
  }),
  labelOverrides: css({
    display: 'none',
  }),
  sizeOverrides: css({
    borderRadius: '0.5rem',
    '.fr-toolbar': {
      padding: '0.5rem',
      borderRadius: '0.5rem 0.5rem 0 0',

      '& .fr-btn-grp': {
        margin: '0',
      },
    },
    '.show-placeholder': {
      paddingTop: '0.5rem',
    },
    '.fr-placeholder': {
      display: 'none !important',
    },
  }),
  attachmentButton: (canAddAttachments: boolean) => css({
    background: canAddAttachments ? 'transparent' : palette.neutrals.gray100,
    svg: {
      color: canAddAttachments ? palette.neutrals.gray600 : palette.neutrals.gray700,
    },
  }),
  maxAttachmentsNotification: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    fontStyle: 'italic',
    fontWeight: 400,
    width: '100%',
  }),
};

export interface ViewProps {
  attachments: Attachment[],
  canAddAttachments: boolean,
  defaultBold?: boolean,
  draftKey?: string[],
  initialValue?: string,
  isPrivate: boolean,
  isRecurring: boolean,
  maxAttachmentsPerTopic: number,
  modalIdentifier: string,
  onAttachFilesClick: () => void,
  onCancel: () => void,
  onChange?: ({ value }: { value: string }) => void,
  onMakePrivateClick: () => void,
  onMakeRecurringClick: () => void,
  onSubmit: (e: FormEvent<HTMLFormElement>) => void,
  preferDraftValue?: boolean,
  recurrenceDataTestId?: string,
  removeAttachmentFromChip: (attachment: Attachment) => void,
  saveButtonText?: string,
  setShouldDisableAddTopic: (shouldDisableAddTopic: boolean) => void,
  shouldDisableAddTopic: boolean,
  shouldShowPrivateButton?: boolean,
  shouldShowRecurrenceButton?: boolean,
  shouldUseSimplifiedView?: boolean,
  showAddAttachments: boolean,
  showAttachments: boolean,
}

const View = ({
  attachments,
  canAddAttachments,
  defaultBold = true,
  draftKey,
  initialValue,
  isPrivate,
  isRecurring,
  maxAttachmentsPerTopic,
  modalIdentifier,
  onAttachFilesClick,
  onCancel,
  onChange,
  onMakePrivateClick,
  onMakeRecurringClick,
  onSubmit,
  preferDraftValue = false,
  recurrenceDataTestId,
  removeAttachmentFromChip,
  saveButtonText = 'Save',
  setShouldDisableAddTopic,
  shouldDisableAddTopic,
  shouldShowPrivateButton,
  shouldShowRecurrenceButton,
  shouldUseSimplifiedView,
  showAddAttachments,
  showAttachments,
  ...props
}: ViewProps): JSX.Element => (
  <>
    <form onSubmit={onSubmit} css={styles.editorContainer} {...props}>
      {shouldUseSimplifiedView && (
        <DrawerInput
          label="Section Header"
          name="text"
          maxLength={SECTION_TITLE_MAX_LENGTH}
          initialValue={initialValue}
          onChange={(e) => { onChange?.({ value: e.target.value }); }}
          autoFocus
        />
      )}

      {!shouldUseSimplifiedView && (
        <Froala
          labelStyleOverrides={styles.labelOverrides}
          styleOverrides={styles.sizeOverrides}
          froalaConfigProps={{
            heightMin: '3.125rem',
          }}
          enableEmojis
          name="text"
          froalaEventsProps={{
            defaultBold,
            autoFocus: true,
          }}
          richTextEditorProps={{
            name: 'text',
            initialValue,
            onChange: ({ value: newText }) => {
              setShouldDisableAddTopic(newText.length === 0);
              return onChange?.({ value: newText });
            },
            draftKey,
            preferDraftValue,
          }}
        />
      )}

      {showAttachments && (
        <div css={styles.attachmentList}>
          <AttachmentsList
            allowCollapse={false}
            attachments={attachments}
            isWhiteBackground
            onRemoveAttachment={showAddAttachments ? removeAttachmentFromChip : undefined}
          />
          {!canAddAttachments && (
            <p css={styles.maxAttachmentsNotification}>{`Max Attachments reached (${maxAttachmentsPerTopic})`}</p>
          )}
        </div>
      )}

      <div css={styles.buttonContainer}>
        <Tooltip
          content="Please add content to your agenda topic before saving"
          disabled={!shouldDisableAddTopic}
        >
          <div>
            <LeadrButton
              size="small"
              type="submit"
              data-test-id={shouldUseSimplifiedView ? 'meetingsSimplifiedSaveAgenda' : 'meetingsSaveAgenda'}
              disabled={shouldDisableAddTopic}
            >
              {saveButtonText}
            </LeadrButton>
          </div>
        </Tooltip>
        <LeadrButton
          variant="ghost"
          size="small"
          onClick={onCancel}
          data-test-id={shouldUseSimplifiedView ? 'meetingsSimplifiedCancelAgenda' : 'meetingsCancelAgenda'}
        >
          Cancel
        </LeadrButton>
        {(showAttachments && (showAddAttachments || !canAddAttachments)) && (
        <Tooltip content={canAddAttachments ? 'Attach Files' : `Max Attachments reached (${maxAttachmentsPerTopic})`}>
          <div>
            <button
              type="button"
              css={[styles.buttons, styles.iconButton(false), styles.attachmentButton(canAddAttachments)]}
              onClick={onAttachFilesClick}
              data-test-id={shouldUseSimplifiedView ? 'meetingsSimplifiedAttachFiles' : 'meetingsAttachFiles'}
              disabled={!canAddAttachments}
            >
              <FontAwesomeIcon
                icon={faPaperclipVertical}
                css={styles.icon}
              />
            </button>
          </div>
        </Tooltip>
        )}

        {shouldShowRecurrenceButton && (
          <Tooltip content={isRecurring ? 'Remove Recurrence' : 'Make Recurring'}>
            <button
              type="button"
              css={[styles.buttons, styles.iconButton(isRecurring)]}
              onClick={onMakeRecurringClick}
              data-test-id={recurrenceDataTestId}
            >
              <FontAwesomeIcon
                icon={faArrowsRepeat}
                css={styles.icon}
              />
            </button>
          </Tooltip>
        )}

        {shouldShowPrivateButton && (
          <Tooltip content={isPrivate ? 'Make Public' : 'Make Private'}>
            <button
              type="button"
              css={[styles.buttons, styles.iconButton(isPrivate)]}
              onClick={onMakePrivateClick}
              data-test-id={isPrivate ? 'meetingsMakeItemPublic' : 'meetingsMakeItemPrivate'}
            >
              <FontAwesomeIcon
                icon={faEyeSlash}
                css={styles.icon}
              />
            </button>
          </Tooltip>
        )}
      </div>
    </form>

    <AttachmentsModal
      modalIdentifier={modalIdentifier}
    />
  </>
);

export interface OnSaveProps {
  text: string,
  isPrivate: boolean,
  isRecurring: boolean,
  attachments: Attachment[],
}

export interface OnMakePrivateProps {
  isPrivate: boolean,
}

export interface CreateAgendaItemProps {
  defaultBold?: boolean,
  draftKey?: string[],
  huddleId: string,
  initialAttachments?: Attachment[],
  initialValue?: string,
  isPrivate?: boolean,
  isRecurring?: boolean,
  itemId?: string,
  maxAttachmentsPerTopic?: number,
  onCancel: () => void,
  onChange?: ({ value }: { value: string }) => void,
  onMakePrivate?: (props: OnMakePrivateProps) => void,
  onMakeRecurring?: () => void,
  onSave: (props: OnSaveProps) => void,
  preferDraftValue?: boolean,
  saveButtonText?: string,
  showAddAttachmentsButton?: boolean,
  showPrivateButton?: boolean,
  showRecurringButton?: boolean,
  simplifiedView?: boolean,
}

const CreateAgendaItem = ({
  huddleId,
  initialAttachments = [],
  initialValue,
  isPrivate,
  isRecurring,
  itemId,
  maxAttachmentsPerTopic = MAX_ATTACHMENTS,
  onCancel,
  onChange,
  onMakePrivate,
  onSave,
  saveButtonText,
  showAddAttachmentsButton = false,
  showPrivateButton,
  showRecurringButton,
  simplifiedView,
  ...props
}: CreateAgendaItemProps): JSX.Element => {
  const [attachments, setAttachments] = useState<Attachment[]>(initialAttachments);
  const [privateItem, setPrivateItem] = useState<boolean>(!!isPrivate);
  const [recurringItem, setRecurringItem] = useState<boolean>(!!isRecurring);
  const areAttachmentsEnabled = useFeatureFlag('webAppAttachmentsOnAgendaTopics');
  const [shouldDisableAddTopic, setShouldDisableAddTopic] = useState(false);

  const showAttachments = areAttachmentsEnabled && !itemId;
  const showAddAttachments = areAttachmentsEnabled && showAddAttachmentsButton;

  useEffect(() => {
    setPrivateItem(!!isPrivate);
  }, [isPrivate]);

  useEffect(() => {
    setRecurringItem(!!isRecurring);
  }, [isRecurring]);

  const doCancel = useCallback(() => {
    onCancel();
  }, [
    onCancel,
  ]);

  const doMakeItemPrivate = useCallback(() => {
    setPrivateItem(!privateItem);

    onMakePrivate?.({
      isPrivate: !privateItem,
    });
  }, [
    privateItem,
    onMakePrivate,
  ]);

  const doMakeItemRecurring = useCallback(() => {
    setRecurringItem(!recurringItem);
  }, [recurringItem]);

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const { text } = Object.fromEntries(formData);

    onSave({
      text: text as string,
      isPrivate: privateItem,
      isRecurring: recurringItem,
      attachments,
    });
  }, [
    privateItem,
    recurringItem,
    onSave,
    attachments,
  ]);

  const addAttachment = useCallback((attachment: Attachment) => {
    setAttachments((currentAttachments) => ([
      ...currentAttachments,
      attachment,
    ]));
  }, []);

  const {
    openModal: openAttachmentDeletionModal,
  } = useConfirmAttachmentDeletionModal();

  const removeAttachmentFromModal = useCallback((attachment: Attachment) => {
    if (!itemId) {
      setAttachments((currentAttachments) => (
        currentAttachments.filter((currentAttachment) => (currentAttachment.id !== attachment.id))
      ));
    }
  }, [itemId]);

  const removeAttachmentFromChip = useCallback((attachment: Attachment) => {
    openAttachmentDeletionModal({
      props: {
        attachment,
        huddleId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        topicId: itemId!,
        onConfirm: (attachmentId: number) => {
          setAttachments((currentAttachments) => (
            currentAttachments.filter((currentAttachment) => (currentAttachment.id !== attachmentId))
          ));
        },
      },
    });
  }, [huddleId, itemId, openAttachmentDeletionModal]);

  const recurrenceDataTestId = useMemo(() => {
    if (recurringItem) {
      return `meetings${simplifiedView ? 'Simplified' : ''}RemoveRecurrence`;
    }

    return `meetings${simplifiedView ? 'Simplified' : ''}MakeRecurring`;
  }, [recurringItem, simplifiedView]);

  const canAddAttachments = useMemo(() => (
    attachments.length < maxAttachmentsPerTopic
  ), [attachments.length, maxAttachmentsPerTopic]);

  const {
    openModal,
  } = useShowAttachmentsModal();

  const modalIdentifier = `attachments-${itemId ?? 'new'}`;

  const onAttachFilesClick = useCallback(() => {
    openModal({
      identifier: modalIdentifier,
      props: {
        addAttachment,
        attachments,
        huddleId,
        removeAttachment: removeAttachmentFromModal,
        topicId: itemId,
      },
    });
  }, [addAttachment, attachments, huddleId, itemId, modalIdentifier, openModal, removeAttachmentFromModal]);

  const hookProps = {
    attachments,
    canAddAttachments,
    initialValue,
    isPrivate: privateItem,
    isRecurring: recurringItem,
    maxAttachmentsPerTopic,
    modalIdentifier,
    onAttachFilesClick,
    onCancel: doCancel,
    onChange,
    onMakePrivateClick: doMakeItemPrivate,
    onMakeRecurringClick: doMakeItemRecurring,
    onSubmit,
    recurrenceDataTestId,
    removeAttachmentFromChip,
    saveButtonText,
    setShouldDisableAddTopic,
    shouldDisableAddTopic,
    shouldShowPrivateButton: showPrivateButton,
    shouldShowRecurrenceButton: showRecurringButton,
    shouldUseSimplifiedView: simplifiedView,
    showAddAttachments,
    showAttachments,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CreateAgendaItem;
