import { Route, Switch } from 'react-router-dom';

import {
  REVIEWS, ADMIN_REVIEWS_V2, REVIEWS_CREATE_CYCLE,
  REVIEWS_CONTINUE_CREATING_CYCLE,
  REVIEWS_BY_CYCLE, ADMIN_REVIEWS_BY_CYCLE, REVIEWS_BY_ID_V2, ADMIN_REVIEWS_REFLECTIONS_V2,
  REVIEWS_ADMIN_BY_ID_V2,
} from '~Common/const/routes';

import LayoutBridge from '~Deprecated/ui/routes/layouts/LayoutBridge';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import NewGetContent from '~Deprecated/ui/routes/layouts/NewGetContent';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import { ShowRecognitionModalProps, useShowRecognitionModal } from '~Recognition/hooks/useShowRecognitionModal';
import RecognitionModalDetails from '~Recognition/components/RecognitionModalDetails';
import { noop } from '~Deprecated/utils';
import { InfiniteQueryParamsProps } from '~Recognition/const/types';
import { ShowAccomplishmentDetailModalProps, useShowAccomplishmentModal } from '~DevelopmentPlan/hooks/utils/useShowAccomplishmentDetailModal';
import AccomplishmentDetailModal from '~DevelopmentPlan/components/Modals/Accomplishment/AccomplishmentDetailModal';
import ReviewsDashboard from './pages/ReviewsDashboard';
import { ViewCyclePerspective, ViewPerspective } from './Const/types';
import { ReviewCycleDetails } from './pages/ReviewCycleDetails';
import CreateCycleLayout from './Layouts/CreateCycleLayout';
import ReviewResponseLayout from './Layouts/ReviewResponse';
import { ReviewCycleManagerDetails } from './pages/ReviewCycleManagerDetails';
import TopBarLayout from './TopBar/TopBarLayout';
import { ReflectionsDashboard } from './reflections/Pages/ReflectionsDashboard';

interface ViewProps {
  showRecognitionModalProps: ShowRecognitionModalProps,
  showAccomplishmentModal: boolean,
  showAccomplishmentModalProps: ShowAccomplishmentDetailModalProps,
}

const View = ({
  showRecognitionModalProps,
  showAccomplishmentModal,
  showAccomplishmentModalProps,
}: ViewProps): JSX.Element => (
  <>
    <RecognitionModalDetails
      setEditPost={noop}
      infiniteQueryParams={{} as InfiniteQueryParamsProps}
      {...showRecognitionModalProps}
    />
    {showAccomplishmentModal && (
      <AccomplishmentDetailModal
        {...showAccomplishmentModalProps}
      />
    )}
    <Switch>
      <Route
        exact
        path={[ADMIN_REVIEWS_V2]}
        render={() => (
          <BaseLayout>
            <ModuleLandingPageWrapper
              TopComponent={<TopBarLayout viewPerspective={ViewPerspective.Admin} />}
              ChildComponent={<ReviewsDashboard viewPerspective={ViewPerspective.Admin} />}
            />
          </BaseLayout>
        )}
      />
      <Route
        exact
        path={[REVIEWS]}
        render={() => (
          <BaseLayout>
            <ModuleLandingPageWrapper
              TopComponent={<TopBarLayout viewPerspective={ViewPerspective.MyReviews} />}
              ChildComponent={<ReviewsDashboard viewPerspective={ViewPerspective.MyReviews} />}
            />
          </BaseLayout>
        )}
      />
      <Route
        exact
        path={[ADMIN_REVIEWS_REFLECTIONS_V2]}
        render={() => (
          <BaseLayout>
            <ModuleLandingPageWrapper
              TopComponent={<TopBarLayout viewPerspective={ViewPerspective.Reflections} />}
              ChildComponent={<ReflectionsDashboard viewPerspective={ViewPerspective.Reflections} />}
            />
          </BaseLayout>
        )}
      />
      <Route
        exact
        path={[REVIEWS_BY_ID_V2, REVIEWS_ADMIN_BY_ID_V2]}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={ReviewResponseLayout}
              />
        )}
          />
        )}
      />
      <Route
        exact
        path={[REVIEWS_CONTINUE_CREATING_CYCLE, REVIEWS_CREATE_CYCLE]}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={CreateCycleLayout}
              />
        )}
          />
        )}
      />
      <Route
        exact
        path={REVIEWS_BY_CYCLE}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={ReviewCycleManagerDetails}
              />
          )}
          />
        )}
      />
      <Route
        exact
        path={ADMIN_REVIEWS_BY_CYCLE}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={ReviewCycleDetails}
              />
          )}
          />
        )}
      />
      <Route
        exact
        path={`${REVIEWS_CONTINUE_CREATING_CYCLE}/setup`}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={<CreateCycleLayout initialPerspectiveFromRouter={ViewCyclePerspective.Setup} />}
              />
        )}
          />
        )}
      />
      <Route
        exact
        path={`${REVIEWS_CONTINUE_CREATING_CYCLE}/preview`}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={<CreateCycleLayout initialPerspectiveFromRouter={ViewCyclePerspective.Preview_Publish} />}
              />
        )}
          />
        )}
      />
      <Route
        exact
        path={`${REVIEWS_CONTINUE_CREATING_CYCLE}/participants`}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={<CreateCycleLayout initialPerspectiveFromRouter={ViewCyclePerspective.Add_Participants} />}
              />
        )}
          />
        )}
      />
      <Route
        exact
        path={`${REVIEWS_CONTINUE_CREATING_CYCLE}/questions`}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <NewGetContent
                ChildComponent={<CreateCycleLayout initialPerspectiveFromRouter={ViewCyclePerspective.Create_Questions} />}
              />
        )}
          />
        )}
      />
      <SmartRedirect from="*" to={REVIEWS} />
    </Switch>
  </>
);

const ReviewsRouter = (): JSX.Element => {
  const { useGetModalProps } = useShowRecognitionModal();
  const { useIsModalOpen: useIsAccomplishmentModalOpen, useGetModalProps: useGetAccomplishmentModalProps } = useShowAccomplishmentModal();

  const showRecognitionModalProps = useGetModalProps();
  const showAccomplishmentModal = useIsAccomplishmentModalOpen();
  const showAccomplishmentModalProps = useGetAccomplishmentModalProps();

  const hookProps = {
    showRecognitionModalProps,
    showAccomplishmentModal,
    showAccomplishmentModalProps,
  };

  return (
    <View {...hookProps} />
  );
};

export default ReviewsRouter;
