import { useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useGenericSearch } from '~Common/hooks/useGenericSearch';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';

export const GROUPS_LIST_QUERY_KEY = [getOrganizationId() ?? '', 'groups', 'list'];

export interface UserGroup {
  teamId: string,
  name: string,
  description: string,
  members: string[],
  totalMembers: number,
  total?: number,
}

interface GetUserGroupsApiReturn {
  items: UserGroup[],
}

export const getUserGroups = (): Promise<HttpCallReturn<GetUserGroupsApiReturn>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/ops/teams`;
  return getApi<GetUserGroupsApiReturn>(URL);
};

interface GetUserGroupsReturn {
  unfilteredGroups: UserGroup[],
  groups: UserGroup[],
  isLoading: boolean,
}

export const useGetGroups = (): GetUserGroupsReturn => {
  const result = useQuery({
    queryKey: GROUPS_LIST_QUERY_KEY,
    queryFn: getUserGroups,
  });
  const { search } = useGenericSearch({ module: 'user-groups', workflow: 'table-search', defaultValue: '' }) as { search: string; };
  const groups = result.data?.response?.items;

  const { data: peopleData } = usePeopleQuery() as { data: Record<string, string>; };

  let groupsWithPeople: UserGroup[] = [];

  if (peopleData && groups) {
    groupsWithPeople = groups.map((group) => ({
      ...group, members: group.members?.map((member) => peopleData[member]),
    }));
  }

  const filteredGroups = groupsWithPeople?.filter((group) => group.name.toLowerCase().includes(search.toLowerCase()));

  return {
    unfilteredGroups: groupsWithPeople,
    groups: filteredGroups,
    isLoading: result.isLoading,
  };
};
