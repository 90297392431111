import { css } from '@emotion/react';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import PulseSurvey from '~Insights/components/cards/PulseSurveyCard/PulseSurvey';
import HomeSkeleton from '~Home/components/common/HomeSkeleton';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { useActivePulseSurvey } from '~Insights/hooks/usePulseSurvey';
import { palette } from '~Common/styles/colors';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

const styles = {
  header: css({
    marginBottom: '1.25rem',
  }),
  emptyText: css({
    fontSize: '1rem',
    color: palette.neutrals.gray800,
  }),
};
interface ViewProps extends Omit<LeadrCardProps, 'children'> {
  surveyIsLoading: boolean,
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const View = ({
  surveyIsLoading,
  dragHandleProps,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard
    css={cardAuxStyles}
    {...props}
  >
    <ModuleHeader
      css={styles.header}
      headerText="Engagement Survey"
      {...dragHandleProps}
    />

    {surveyIsLoading && (
      <HomeSkeleton
        numberOfSkeletons={2}
        height={10}
      />
    )}

    {!surveyIsLoading && (<PulseSurvey forceMobile />)}
  </LeadrCard>
);

export interface HomeEngagementSurveyCardProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const HomeEngagementSurveyCard = ({
  dragHandleProps,
  ...props
}: HomeEngagementSurveyCardProps): JSX.Element => {
  const { isLoading } = useActivePulseSurvey();
  const [surveyIsLoading] = useSkeletonLoaders(isLoading);

  const hookProps = {
    surveyIsLoading,
    dragHandleProps,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default HomeEngagementSurveyCard;
