import { create } from 'zustand';

interface UseIsSyncingACalendarMeetingStoreState {
  isSyncingACalendarMeeting: boolean,
  setIsSyncingACalendarMeeting: (isSyncingACalendarMeeting: boolean) => void,
}

export const useIsSyncingACalendarMeeting = create<UseIsSyncingACalendarMeetingStoreState>((set) => ({
  isSyncingACalendarMeeting: false,
  setIsSyncingACalendarMeeting: (isSyncingACalendarMeeting: boolean) => set(() => ({ isSyncingACalendarMeeting })),
}));
