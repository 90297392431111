import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';
import Section from '../Section';

const styles = {
  thematicGoal: css({}),
  text: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
  }),
  title: css({
    marginBottom: '1.125rem',
  }),
};

interface ThematicGoalProps {
  thematicGoalHTML: HTMLString,
}

const ThematicGoal = ({
  thematicGoalHTML,
  ...props
}: ThematicGoalProps): JSX.Element => (
  <Section
    css={styles.thematicGoal}
    {...props}
  >
    <Section.Title css={styles.title} title="Thematic Goal" />
    <HTMLRenderer css={styles.text} htmlText={thematicGoalHTML} />
  </Section>
);

export default ThematicGoal;
