import { createReducer } from '../redux/reducers/index';

const initialState = {
  ids: [],
  data: {},
  totalCount: 0,
  isFilteredByFavorites: false,
  hasPendingCall: false,
  error: null,
  success: null,
};

const { reducer, registerAction } = createReducer('organizationInsights', initialState);

export { reducer, registerAction, initialState };
