import { css } from '@emotion/react';
import { PropsWithChildren } from 'react';

const styles = {
  basePrintBody: css({
    width: '100%',
  }),
};

const BasePrintBody = ({
  children,
  ...props
}: PropsWithChildren<unknown>): JSX.Element => (
  <section
    css={styles.basePrintBody}
    {...props}
  >
    {children}
  </section>
);

export default BasePrintBody;
