import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { leadrPubSub } from '~Deprecated/services/EventService';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import ContentItemComponent from '~Deprecated/components/Content/ContentList/content-item.component';
import { parseQueryString } from '~Deprecated/utils';

const View = ({
  page,
  contentList,
}) => (
  <>
    { contentList?.map((c, key) => (
      <ContentItemComponent
        key={`content-item-${key}`}
        page={page}
        userId={getUserId()}
        orgId={getOrganizationId()}
        contentId={c.id}
        assigneeId={c.assigneeId}
      />
    ))}
  </>
);

// TODO: Rewrite this with new UI in meetings
/**
 * @deprecated Old UI
 */
const MeetingContent = ({ page }) => {
  const history = useHistory();

  const [contentList, setContentList] = useState();

  useEffect(() => {
    const subscription = leadrPubSub.subject('timeline_date_selection').subscribe((d) => {
      setContentList(d.data?.data?.CONTENT);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const hookProps = {
    page,
    contentList,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default MeetingContent;
