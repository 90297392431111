import { v4, validate as uuidValidate } from 'uuid';

export const localIdIndetifier = 'LOCAL-';

export const isLocalId = (id: string): boolean => id.startsWith && id.startsWith(localIdIndetifier);

export const getLocalId = (): string => `${localIdIndetifier}${v4()}`;

export const uuid = v4;
export const validate = uuidValidate;
