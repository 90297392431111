import { css } from '@emotion/react';
import { ComponentProps } from 'react';

import { palette } from '~Common/styles/colors';

const styles = {
  uncheckedIcon: (disabled: boolean) => css({
    width: '1.5rem',
    height: '1.5rem',
    border: `.0625rem solid ${palette.neutrals.gray400}`,
    borderRadius: '.5rem',
  }, disabled && {
    borderColor: palette.neutrals.gray300,
  }),
};

interface UncheckedIconProps extends ComponentProps<'div'> {
  disabled?: boolean,
}

const UncheckedIcon = ({ disabled = false, ...props }: UncheckedIconProps): JSX.Element => (
  <div css={styles.uncheckedIcon(disabled)} {...props} />
);

export default UncheckedIcon;
