import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  emptyStateWithImage: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    justifyContent: 'center',
    textAlign: 'center',
  }),
  imageContainer: css({
    width: '100%',
  }),
  textContainer: css({
    display: 'flex',
    justifyContent: 'center',
    color: palette.neutrals.gray800,
    alignItems: 'center',
  }),
};

export interface EmptyStateWithImageProps extends ComponentProps<'div'> {
  renderImage?: () => JSX.Element,
  text?: string,
  renderText?: () => JSX.Element,
}

const EmptyStateWithImage = ({
  renderImage = () => (<></>),
  text = '',
  renderText,
  ...props
}: EmptyStateWithImageProps): JSX.Element => (
  <div css={styles.emptyStateWithImage} {...props}>
    <div css={styles.imageContainer}>
      {renderImage()}
    </div>
    <div css={styles.textContainer}>
      {renderText && renderText()}
      {!renderText && text}
    </div>
  </div>
);

export default EmptyStateWithImage;
