import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { IconDefinition, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@mui/material/Collapse';
import {
  Fragment,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import { LinkButton } from '~Root/components/NavigationSidebar/NavigationItems/LinkButton';
import { ActionButton } from '~Root/components/NavigationSidebar/NavigationItems/ActionButton';
import { IsVisibleProps, NavigationIndicatorData, NavigationItem } from '~Root/components/NavigationSidebar/types';
import { SHARED_STYLES } from '~Root/components/NavigationSidebar/NavigationItems/styles';
import { useNavigationIndicators } from '~Root/components/NavigationSidebar/useNavigationIndicators';
import { useNavigationPermissions } from '~Root/components/NavigationSidebar/useNavigationPermissions';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  ...SHARED_STYLES,
  button: css({
    width: '100%',
  }),
  collapseIcon: (isSectionExpanded: boolean) => css({
    rotate: '0deg',
    transition: 'rotate 100ms ease',
  }, isSectionExpanded && {
    rotate: '180deg',
  }),
  highlight: css({
    color: palette.brand.indigo,
  }),
  subItem: css({
    background: palette.neutrals.gray50,
  }),
  indicator: css({
    color: palette.brand.indigo,
    width: '0.4rem',
  }),
  compactIndicator: css({
    position: 'absolute',
    right: '0.6rem',
  }),
  label: css({
    marginRight: '0.5rem',
  }),
  labelAndNotificationContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

export interface ViewProps {
  label: string,
  icon: IconDefinition
  compact?: boolean,
  isExpanded: boolean,
  shouldShowIndicator: boolean,
  filteredItems: readonly NavigationItem[],
  onClick: () => void,
  navigationIndicatorData: NavigationIndicatorData,
  isVisibleProps: IsVisibleProps,
}

const View = ({
  label,
  icon,
  isExpanded,
  compact = false,
  filteredItems,
  onClick,
  navigationIndicatorData,
  isVisibleProps,
  shouldShowIndicator,
  ...props
}: ViewProps): JSX.Element => (
  <>
    {filteredItems.length === 1 && (
      <>
        {!!filteredItems[0].href && (
          <LinkButton
            compact={compact}
            item={filteredItems[0]}
            navigationIndicatorData={navigationIndicatorData}
          />
        )}

        {!filteredItems[0].href && (
          <ActionButton
            compact={compact}
            isVisibleProps={isVisibleProps}
            item={filteredItems[0]}
          />
        )}
      </>
    )}

    {filteredItems.length > 1 && (
      <Tooltip
        content={compact && !isExpanded ? label : null}
        placement="right"
      >
        <div>
          <LeadrButton
            {...props}
            variant="text"
            css={[styles.button, styles.navItem(compact), isExpanded && styles.highlight]}
            onClick={onClick}
            data-label={label}
            data-test-id={`navigationSection-${label}`}
          >
            <FontAwesomeIcon icon={icon} css={styles.icon} />

            {shouldShowIndicator && compact && (
              <FontAwesomeIcon
                icon={faCircle}
                css={[styles.indicator, styles.compactIndicator]}
              />
            )}

            {!compact && (
              <>
                <div css={[styles.labelContainer, styles.labelAndNotificationContainer]}>
                  <span css={styles.label}>
                    {label}
                  </span>

                  {shouldShowIndicator && (
                    <FontAwesomeIcon
                      icon={faCircle}
                      css={styles.indicator}
                    />
                  )}
                </div>

                <FontAwesomeIcon
                  icon={faChevronDown}
                  css={styles.collapseIcon(isExpanded)}
                />
              </>
            )}
          </LeadrButton>

          <Collapse in={isExpanded}>
            {filteredItems.map((item) => (
              <Fragment key={item.label}>
                {!!item.href && (
                  <LinkButton
                    css={styles.subItem}
                    compact={compact}
                    item={item}
                    navigationIndicatorData={navigationIndicatorData}
                  />
                )}
                {!item.href && (
                  <ActionButton
                    css={styles.subItem}
                    compact={compact}
                    isVisibleProps={isVisibleProps}
                    item={item}
                  />
                )}
              </Fragment>
            ))}
          </Collapse>
        </div>
      </Tooltip>
    )}
  </>
);

export interface NavigationSection {
  label: string,
  icon: IconDefinition
  compact?: boolean,
  items: readonly NavigationItem[],
}

const NavigationSection = ({
  items,
  compact,
  ...props
}: NavigationSection): JSX.Element => {
  const location = useLocation();
  const navigationIndicatorData = useNavigationIndicators();
  const isVisibleProps = useNavigationPermissions(compact);

  const isDefaultExpanded = useMemo(() => (
    // @ts-expect-error Ignoring. Undefined works just fine here.
    (items.some((item) => (location.pathname.includes(item.href))))

    // We don't need to recheck this every time location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    // location.pathname,
    items,
  ]);

  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

  const filteredItems = useMemo(() => (
    items.filter((item) => (!item.isVisible || item.isVisible(isVisibleProps)))
  ), [
    items,
    isVisibleProps,
  ]);

  const hasSubIndicators = useMemo(() => (
    items.some((item) => ((!item.isVisible || item.isVisible(isVisibleProps)) && item.getNotificationTotal?.(navigationIndicatorData)))
  ), [
    items,
    isVisibleProps,
    navigationIndicatorData,
  ]);

  const onClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const hookProps = {
    isExpanded,
    onClick,
    filteredItems,
    compact,
    shouldShowIndicator: hasSubIndicators,
    isVisibleProps,
    navigationIndicatorData,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default NavigationSection;
