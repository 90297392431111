import { useDispatch } from 'react-redux';

import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import { css } from '@emotion/react';
import { forMobileTinyObject } from '~Common/styles/mixins';
import { ChangeEvent } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';
import { useFeedbackSearch } from '~Feedback/stores/useFeedbackSearch';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import FeedbackViewToggle from './FeedbackViewToggle';
import { templateListTemplate } from '../Drawers/Templates/List';
import { createIncompleteFeedbackTemplate } from '../Drawers/Request/Create';
import FeedbackTabNavigation from './FeedbackTabNavigation';

const styles = {
  leadrButton: css(
    forMobileTinyObject({
      width: '50%',
    }),
  ),
  toggleTab: (canRequestFeedback: boolean) => css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    width: canRequestFeedback ? '50%' : '100%',
  })),
};

interface ViewProps {
  onTemplatesClick: () => void,
  onNewFeedbackClick: () => void,
  canRequestFeedback: boolean,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  initialRoute: string,
}

const View = ({
  onTemplatesClick,
  onNewFeedbackClick,
  canRequestFeedback,
  handleSearchTextChange,
  initialRoute,
}: ViewProps): JSX.Element => (
  <ModuleTopbar
    moduleTopbarLayout={canRequestFeedback ? 'default' : 'defaultWithoutRightSection'}
    renderTabNavigationToggle={() => (
      <FeedbackTabNavigation
        initialRoute={initialRoute}
        toggleTabStyles={styles.toggleTab(canRequestFeedback)}
      />
    )}
    renderViewToggle={() => <FeedbackViewToggle />}
    renderSearchField={() => (
      <LeadrSearchField
        data-test-id="feedbackSearchField"
        onChange={handleSearchTextChange}
      />
    )}
    renderRightSection={canRequestFeedback ? () => (
      <>
        <LeadrButton
          onClick={onTemplatesClick}
          data-test-id="feedbackTemplates"
          variant="ghost"
          css={styles.leadrButton}
        >
          Templates
        </LeadrButton>
        <CreateButton
          itemName="Feedback"
          onClick={onNewFeedbackClick}
          data-test-id="feedbackCreateNew"
          css={styles.leadrButton}
        />
      </>
    ) : undefined}
  />
);

interface FeedbackTopbarProps {
  initialRoute: string,
}

const FeedbackTopbar = ({
  ...props
}: FeedbackTopbarProps): JSX.Element => {
  const dispatch = useDispatch();
  const { canRequestFeedback } = useOrgUserPermissionsContext();

  const {
    setSearchText,
  } = useFeedbackSearch((state) => ({
    setSearchText: state.setSearchText,
  }));

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const hookProps = {
    onNewFeedbackClick: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...createIncompleteFeedbackTemplate,
        },
      }));
    },
    onTemplatesClick: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...templateListTemplate,
        },
      }));
    },
    canRequestFeedback,
    handleSearchTextChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default FeedbackTopbar;
