import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { ActionItem } from '~ActionItems/const/interfaces';
import { getHost, hosts } from '~Deprecated/services/config';

export interface GetGoalActionItemsParams {
  goalId: string,
}

const getGoalActionItems = async ({
  goalId,
}: GetGoalActionItemsParams): Promise<HttpCallReturn<ActionItem[]>> => {
  const url = {
    host: getHost(hosts.action, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/action-items/goal/${goalId}`,
  };

  return getApi<ActionItem[]>(url);
};

export interface UseGoalActionItemsProps {
  goalId: string,
  enabled?: boolean
}

export const useGoalActionItems = ({
  goalId,
}: UseGoalActionItemsProps): UseQueryResult<HttpCallReturn<ActionItem[]>> => (
  useQuery({
    queryKey: [getOrganizationId(), 'action-items', 'goal', goalId],
    queryFn: () => getGoalActionItems({
      goalId,
    }),
  })
);
