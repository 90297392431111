import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import { ButtonBase } from '@mui/material';
import { DRAWER_WIDTHS, TRANSITION_STATE } from '~Common/const/drawers';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { useTransferOwnership } from '~Meetings/hooks/useTransferOwnership';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { participantDrawerTemplate } from '~Meetings/components/drawers/ParticipantDrawer';
import { useGetPeopleByList } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { useDisableLAUsers } from '~Meetings/hooks/useDisableLAUsers';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';

const styles = {
  container: css({
    padding: '1.5rem',
  }),
};

export const transferOwnershipTemplate = {
  name: 'TRANSFER_OWNERSHIP',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton,
  createButton,
  renderDrawerInstructions,
  renderNewOwner,
  onFormSubmit,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={closeButton}
        title="Transfer Ownership"
      />
    )}
    renderBody={() => (
      <div
        css={styles.container}
      >
        {renderDrawerInstructions()}
        <br />
        {renderNewOwner()}
        <div />
        {createButton()}
      </div>
    )}
  />
);

View.propTypes = {
  closeButton: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
  renderNewOwner: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

View.defaultProps = {};

const TransferOwnership = ({
  id, type, factoryId, drawerState, transitionState, ...props
}) => {
  const dispatch = useDispatch();
  const hasEntered = useMemo(() => transitionState === TRANSITION_STATE.ENTERED, [transitionState]);
  const { selectedPersonForOwnershipTransfer = [] } = drawerState;
  const newOwner = useGetPeopleByList({ selectedIds: selectedPersonForOwnershipTransfer });
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const {
    attendeeOrgUserIds,
  } = meetingDetails ?? {};
  const limitedAccessUsers = useDisableLAUsers(attendeeOrgUserIds);

  const openPeoplePicker = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...participantDrawerTemplate,
        args: {
          limit: 1,
          title: 'Select a Person for Ownership Transfer',
          selectedKey: 'selectedPersonForOwnershipTransfer',
          useOrgIds: true,
          allowSelf: true,
          customFilter: attendeeOrgUserIds,
          disableUsers: limitedAccessUsers,
        },
      },
    }));
  }, [dispatch, attendeeOrgUserIds, limitedAccessUsers]);

  useEffect(() => {
    if (hasEntered) {
      setTimeout(openPeoplePicker, 200);
    }
  }, [openPeoplePicker, hasEntered]);

  const doTransferOwnership = useTransferOwnership({ template: transferOwnershipTemplate });

  const createButton = useCallback(() => (
    <LeadrButton
      data-test-id="meetingTransferOwnership"
      type="submit"
      disabled={!selectedPersonForOwnershipTransfer?.length}
    >
      Transfer Ownership
    </LeadrButton>
  ), [selectedPersonForOwnershipTransfer]);

  const renderNewOwner = useCallback(() => {
    if (!newOwner?.length) {
      return (
        <DrawerInstructions text={(
          <ButtonBase onClick={openPeoplePicker}>
            <strong>Choose a person for ownership transfer</strong>
          </ButtonBase>
        )}
        />
      );
    }

    return (
      <DrawerInstructions text={(
        <>
          Ownership will be transferred to
          {' '}
          <ButtonBase onClick={openPeoplePicker}>
            <strong>
              {newOwner[0]?.firstName}
              {' '}
              {newOwner[0]?.lastName}
            </strong>
          </ButtonBase>
        </>
      )}
      />
    );
  }, [newOwner, openPeoplePicker]);

  const onFormSubmit = useCallback((e) => {
    e.preventDefault();
    const transferOwnershipData = {
      factoryId,
      huddleId: id,
      newOrganizerId: selectedPersonForOwnershipTransfer[0],
    };

    doTransferOwnership(transferOwnershipData);
  }, [id, factoryId, doTransferOwnership, selectedPersonForOwnershipTransfer]);

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const renderDrawerInstructions = () => {
    const drawerText = '<p>Select a person to transfer ownership of this meeting.</p>';
    /* eslint-disable-next-line max-len */
    const drawerSubText = '<p>This will change the owner of this Meeting for upcoming occurrences and will continue scheduling as normal with the new owner.</p>';

    return (
      <div>
        <DrawerInstructions
          html={drawerText}
        />
        <br />
        <DrawerInstructions
          html={drawerSubText}
        />
      </div>
    );
  };

  const hookProps = {
    closeButton,
    createButton,
    renderDrawerInstructions,
    renderNewOwner,
    onFormSubmit,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

TransferOwnership.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  factoryId: PropTypes.string.isRequired,
  drawerState: PropTypes.func.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  transitionState: PropTypes.oneOf(Object.values(TRANSITION_STATE)).isRequired,
};

TransferOwnership.defaultProps = {};

registerDrawer({
  templateName: transferOwnershipTemplate.name,
  component: TransferOwnership,
});

export { View };
export default TransferOwnership;
