import { css } from '@emotion/react';
import { Card as MaterialUICard, CardProps as MaterialUICardProps } from '@mui/material';
import { ForwardRefComponent, HTMLMotionProps } from 'framer-motion';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { FramerProps } from '~Insights/const/cardStyles';

const styles = {
  card: css`
    padding: 1.5rem;
    border-radius: .3125rem;
    box-shadow: 0px 4px 8px -2px rgba(28, 42, 55, 0.2); // Idk what this color is or what to do with it

    @media print {
      -webkit-filter: drop-shadow(0px 4px 8px rgba(28, 42, 55, 0.2)); // Box-shadows do not work for print views :)
    }
  `,
};

type MotionDiv = ForwardRefComponent<HTMLDivElement, HTMLMotionProps<'div'>>;
export interface CardProps extends MaterialUICardProps<'div'>, FramerProps {
  component?: MotionDiv,
  layoutId?: string,
  renderContents: () => JSX.Element,
  onClick?: () => void,
}

/**
 * @deprecated use LeadrCard instead
 */
const Card = ({ renderContents, ...props }: CardProps): JSX.Element => (
  <MaterialUICard css={styles.card} {...props}>
    {renderContents()}
  </MaterialUICard>
);

const SkeletonView = ({ ...props }): JSX.Element => (
  <SkeletonLoader
    css={styles.card}
    variant="rectangular"
    {...props}
    renderComponent={() => (
      <Card renderContents={() => (<></>)} />
    )}
  />
);

export default Card;

export { SkeletonView as CardSkeleton };
