import { useMemo } from 'react';
import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { DaySectionSkeleton } from '~Home/components/Module/Meetings/DaySection';
import MeetingCard from '~Home/components/Module/Meetings/MeetingCard';
import { useGetUnsyncedCalendarMeetings } from '~Meetings/hooks/CalendarIntegration/useGetUnsyncedCalendarMeetings';
import { MyScheduleDay } from '~Home/const/interfaces';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TeamsListScope } from '~People/components/Teams/const/types';
import ReviewUnsyncedMeetingsDrawerTabBody from '../ReviewUnsyncedMeetingsDrawerTabBody';

const styles = {
  daySection: css({
    '&:not(:first-of-type)': {
      paddingTop: '1.125rem',
      borderTop: `1px solid ${palette.neutrals.gray400}`,
    },
    '&:not(:last-of-type)': {
      paddingBottom: '1.125rem',
    },
  }),
};

interface ViewProps {
  unsyncedCalendarMeetings: MyScheduleDay[] | undefined,
  areUnsyncedCalendarMeetingsLoading: boolean,
  hasTeams: boolean,
}

const View = ({
  unsyncedCalendarMeetings,
  areUnsyncedCalendarMeetingsLoading,
  hasTeams,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {areUnsyncedCalendarMeetingsLoading && (
      <DaySectionSkeleton css={styles.daySection} />
    )}
    {!areUnsyncedCalendarMeetingsLoading && (
      <>
        {(!unsyncedCalendarMeetings || unsyncedCalendarMeetings.length === 0) && (
          <div>No unsynced calendar meetings</div>
        )}
        {unsyncedCalendarMeetings && unsyncedCalendarMeetings.length > 0 && (
          <ReviewUnsyncedMeetingsDrawerTabBody
            unsyncedMeetings={unsyncedCalendarMeetings}
            renderMeetingCard={(meeting) => (
              <MeetingCard
                meeting={meeting}
                hasTeams={hasTeams}
                key={meeting.nylasEventId}
              />
            )}
          />
        )}
      </>
    )}
  </div>
);

const MeetingsInCalendar = ({ ...props }): JSX.Element => {
  const { unsyncedCalendarMeetings, isLoading: isCalendarMeetingsLoading } = useGetUnsyncedCalendarMeetings();

  const { data, isLoading: areTeamsLoading } = useGetTeams({
    listScope: TeamsListScope.MyTeams,
    page: 0,
    count: 200,
  });

  const [areUnsyncedCalendarMeetingsLoading] = useSkeletonLoaders(isCalendarMeetingsLoading || areTeamsLoading);

  const hasTeams = useMemo(() => (
    !!data?.response.totalTeamCount
  ), [
    data?.response,
  ]);

  const hookProps = {
    unsyncedCalendarMeetings,
    areUnsyncedCalendarMeetingsLoading,
    hasTeams,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingsInCalendar;
