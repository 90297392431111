import { css, SerializedStyles } from '@emotion/react';
import { faArrowLeft, faCircleInfo, faTimes } from '@fortawesome/pro-light-svg-icons';
import moment, { Moment } from 'moment';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerInput from '~Common/V3/components/DrawerInput';
import Froala from '~Common/V3/components/Froala';
import Tooltip from '~Common/components/Tooltip';
import { useGetAssignedPlaylistDetails } from '~Learning/hooks/assigned/useGetAssignedPlaylistDetails';
import LearningDueDatePicker from '~Learning/components/Shared/LearningDueDatePicker';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { withLineClamp } from '~Common/styles/mixins';
import { useUpdatePlaylistDueDates } from '~Learning/hooks/assigned/useUpdatePlaylistDueDates';
import { LearningStatus } from '~Learning/const/interfaces';
import { assignedPlaylistDetailDrawerTemplate } from '..';

const styles = {
  playlistTitle: css({
    marginBottom: '0.5rem',
  }),
  playlistDueDate: css({
    marginTop: '0.5rem',
  }),
  datePickerLabel: css({
    display: 'flex',
    gap: '0.25rem',
    color: palette.neutrals.gray600,
    fontWeight: 500,
  }),
  learningsSection: css({
    marginTop: '2.5rem',
  }),
  learningsSectionHeaderContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  learningsSectionHeader: css({
    color: palette.neutrals.gray800,
    fontSize: '1.0625rem',
    fontWeight: 500,
  }),
  learningCard: css({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '25% 43% 32%',
    ':not(:first-of-type)': {
      marginTop: '.625rem',
    },
  }),
  learningTitle: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1rem',
    margin: '0 .5rem',
  }, withLineClamp(2)),
  buttonsContainer: css({
    marginTop: '4.375rem',
    display: 'flex',
    gap: '.625rem',
  }),
};

export const editPlaylistDueDatesDrawerTemplate = {
  name: 'EDIT_PLAYLIST_DUE_DATES_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface EditPlaylistDueDatesDrawerProps extends DrawerProps<unknown> {
  playlistId: number,
}

const EditPlaylistDueDatesDrawer = ({
  popDrawer,
  pushDrawer,
  playlistId,
}: EditPlaylistDueDatesDrawerProps): JSX.Element => {
  const { detail: learningPlaylist } = useGetAssignedPlaylistDetails({ playlistId });

  const updatePlaylistDueDatesMutation = useUpdatePlaylistDueDates();

  const [playlistDueDate, setPlaylistDueDate] = useState(learningPlaylist?.dueDate ? moment(learningPlaylist.dueDate) : undefined);

  const [learningDueDates, setLearningDueDates] = useState<{dueDate?: Date | null, learningId: string}[]>(
    learningPlaylist?.learnings.map((learning) => ({
      learningId: learning.id,
      dueDate: learning.dueDate ? new Date(learning.dueDate) : null,
    })) ?? [],
  );

  const earliestLearningDueDate = useMemo(() => moment(
    new Date(Math.max.apply(null, learningDueDates?.map((learningDueDate) => moment(learningDueDate.dueDate)?.valueOf() || -Infinity) || [-Infinity])),
  ), [learningDueDates]);

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: editPlaylistDueDatesDrawerTemplate.name });
  };

  const onGoBack = (): void => {
    closeDrawerClick();
    pushDrawer({
      drawer: {
        ...assignedPlaylistDetailDrawerTemplate,
        args: {
          playlistId,
        },
      },
    });
  };

  const setLearningDueDate = ({
    dueDate,
    learningId,
  }: { dueDate: Moment | null, learningId: string }): void => {
    let newLearningDueDates = [] as {dueDate?: Date | null, learningId: string}[];
    if (learningDueDates) {
      newLearningDueDates = [...learningDueDates];
    }
    const learningDueDateIndex = newLearningDueDates.findIndex((learningDueDate) => learningDueDate.learningId === learningId);
    const newLearningDueDate = newLearningDueDates[learningDueDateIndex];
    newLearningDueDate.dueDate = dueDate?.toDate() || null;

    newLearningDueDates.splice(learningDueDateIndex, 1, newLearningDueDate);

    setLearningDueDates(newLearningDueDates);
  };

  const hookProps = {
    onSubmit: () => {
      // Fill empty learning due dates with null for backend
      const filledLearningDueDates = learningDueDates.map((learningDueDate) => ({
        ...learningDueDate,
        dueDate: learningDueDate.dueDate,
      }));
      updatePlaylistDueDatesMutation({
        playlistId,
        dueDate: moment(playlistDueDate).isValid() ? moment(playlistDueDate).toISOString() : null,
        learnings: filledLearningDueDates,
      });

      closeDrawerClick();

      pushDrawer({
        drawer: {
          ...assignedPlaylistDetailDrawerTemplate,
          args: {
            playlistId,
          },
        },
      });
    },
    renderHeader: () => (
      <DrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip={undefined} />
        )}
        renderBackButton={(backButtonStyles: SerializedStyles) => (
          <IconButton onClick={onGoBack} type="button" icon={faArrowLeft} css={backButtonStyles} tooltip="Back" />
        )}
        title="Edit Due Dates"
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <DrawerInput
          name="title"
          label="Learning Playlist Title"
          initialValue={learningPlaylist?.title}
          disabled
          css={styles.playlistTitle}
        />
        <Froala
          name="description"
          label="Description"
          isDisabled
          richTextEditorProps={{
            initialValue: learningPlaylist?.description,
          }}
        />
        <LearningDueDatePicker
          name="playlistDueDate"
          onDateSelected={({ date: newDate }) => setPlaylistDueDate(moment(newDate))}
          minDate={earliestLearningDueDate as unknown as Date}
          initialDate={playlistDueDate as unknown as Date}
          renderLabel={() => (
            <div css={styles.datePickerLabel}>
              <div>Due By</div>
              <Tooltip
                content="Please note that the Learning Playlist due date must come after the individual Learning Content due dates."
              >
                <div><FontAwesomeIcon icon={faCircleInfo} /></div>
              </Tooltip>
            </div>
          )}
          css={styles.playlistDueDate}
        />
        <div css={styles.learningsSection}>
          <div css={styles.learningsSectionHeaderContainer}>
            <div css={styles.learningsSectionHeader}>{`Learning Content (${learningPlaylist?.numberOfLearnings || 0})`}</div>
          </div>
          {learningPlaylist?.learnings.map((learning) => (
            <Card
              css={styles.learningCard}
              renderContents={() => (
                <>
                  <PreviewWithExternalNavigation
                    viewOption="Thumbnail"
                    contentUrl={learning.contentUrl}
                  />
                  <div css={styles.learningTitle}>
                    {learning.title}
                  </div>
                  {learning.status === LearningStatus.INCOMPLETE && (
                    <LearningDueDatePicker
                      label=""
                      initialDate={learning.dueDate}
                      maxDate={playlistDueDate as unknown as Date}
                      name={`learningDueDate-${learning.id}`}
                      onDateSelected={({ date: newDate }: {date: Moment | null}) => setLearningDueDate({
                        dueDate: newDate,
                        learningId: learning.id,
                      })}
                    />
                  )}
                </>
              )}
            />
          ))}
        </div>
        <div css={styles.buttonsContainer}>
          <LeadrButton
            type="submit"
            data-test-id="learningPlaylistEditDueDatesSave"
          >
            Save
          </LeadrButton>
          <LeadrButton
            variant="ghost"
            data-test-id="learningPlaylistEditDueDatesCancel"
            onClick={onGoBack}
          >
            Cancel
          </LeadrButton>
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: editPlaylistDueDatesDrawerTemplate.name,
  component: EditPlaylistDueDatesDrawer,
});

export default EditPlaylistDueDatesDrawer;
