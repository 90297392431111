import moment from 'moment';
import { useCallback } from 'react';

interface UseGenerateFormattedDayStringReturn {
  generateFormattedDayString: (date: string | number | Date) => string,
}

export const useGenerateFormattedDayString = (): UseGenerateFormattedDayStringReturn => {
  const generateFormattedDayString = useCallback((dayString: string | number | Date): string => {
    let formattedDayString = '';
    const day = moment(dayString);
    const formattedDay = day.format('ddd, MMM Do');

    if (day.isSame(moment(), 'day')) {
      formattedDayString = `Today - ${formattedDay}`;
    } else if (day.isSame(moment().add(1, 'day'), 'day')) {
      formattedDayString = `Tomorrow - ${formattedDay}`;
    } else {
      formattedDayString = formattedDay;
    }

    return formattedDayString;
  }, []);

  return { generateFormattedDayString };
};
