import { keepPreviousData, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';
import { SortDirection } from '~Common/const/interfaces';
import { ActionItem, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { getHost, hosts } from '~Deprecated/services/config';
import { useMemo } from 'react';

export enum ActionItemSearchField {
  Text = 'TEXT',
  Assignee = 'ASSIGNEE',
  Creator = 'CREATOR',
}

export enum ActionItemSortField {
  DueDate = 'DUE_DATE',
  CompletedDate = 'COMPLETED_DATE',
  CreatedDate = 'CREATED_ON',
  Text = 'TEXT',
  Assignee = 'ASSIGNEE',
  Creator = 'CREATOR',
  Status = 'STATUS',
  TaskContext = 'TASK_CONTEXT',
  CommentCount = 'COMMENT_COUNT',
}

export enum ActionItemAssigneeType {
  Me = 'ME',
  Others = 'OTHERS'
}

export interface GetActionItemsParams {
  skip?: number,
  take?: number,
  status?: NewActionItemStatus[],
  taskContext?: string,
  assigneeType?: ActionItemAssigneeType[],
  search?: string,
  sortByField: ActionItemSortField,
  sortByOrder: SortDirection,
  startDate?: Date | string,
  endDate?: Date | string,
}

export interface GetActionItemsResponse {
  items: ActionItem[],
  total: number,
}

export interface ActionItemsQueryParams extends GetActionItemsParams {
  searchOn?: ActionItemSearchField[],
}

const getActionItems = async ({
  skip,
  take,
  status,
  taskContext,
  assigneeType,
  search,
  sortByField,
  sortByOrder,
  startDate,
  endDate,
}: GetActionItemsParams): Promise<HttpCallReturn<GetActionItemsResponse>> => {
  const actionItemsQueryParams: ActionItemsQueryParams = {
    skip,
    take,
    status,
    taskContext,
    assigneeType,
    search: encodeURIComponent(search ?? ''),
    sortByField,
    sortByOrder,
    startDate,
    endDate,
  };

  if (search) {
    actionItemsQueryParams.searchOn = [ActionItemSearchField.Text, ActionItemSearchField.Assignee];
  }

  const queryString = buildQueryString(actionItemsQueryParams);

  const url = {
    host: getHost(hosts.action, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/action-items${queryString}`,
  };

  return getApi<GetActionItemsResponse>(url);
};

export interface UseActionItemsProps {
  skip?: number,
  take?: number,
  status?: NewActionItemStatus[],
  taskContext?: string,
  assigneeType?: ActionItemAssigneeType[],
  search?: string,
  sortByField?: ActionItemSortField,
  sortByOrder?: SortDirection,
  startDate?: Date | string,
  endDate?: Date | string,
}

export const useActionItems = ({
  skip = 0,
  take = 100,
  status,
  taskContext,
  assigneeType,
  search,
  sortByField = ActionItemSortField.DueDate,
  sortByOrder = SortDirection.Ascending,
  startDate,
  endDate,
}: UseActionItemsProps): UseQueryResult<HttpCallReturn<GetActionItemsResponse>> => {
  const params = useMemo(() => ({
    skip,
    take,
    status,
    taskContext,
    assigneeType,
    search,
    sortByField,
    sortByOrder,
    startDate,
    endDate,
  }), [
    skip,
    take,
    status,
    taskContext,
    assigneeType,
    search,
    sortByField,
    sortByOrder,
    startDate,
    endDate,
  ]);

  return useQuery({
    queryKey: [getOrganizationId(), 'action-items', params],
    queryFn: () => getActionItems({
      skip,
      take,
      status,
      taskContext,
      assigneeType,
      search,
      sortByField,
      sortByOrder,
      startDate,
      endDate,
    }),
    placeholderData: keepPreviousData,
  });
};
