interface UseCascadeListReturn<T> {
  getArrayWithoutItem: (itemToDeleteRank: number) => T[],
  getNewItemRank: () => number,
}

export const useRankedListHelpers = <T extends {rank: number}>(itemList: T[]): UseCascadeListReturn<T> => {
  const getNewItemRank = (): number => (itemList.length ? itemList[itemList.length - 1].rank + 1 : 0);

  // Removes an item from the list and cascades the ranks of the remaining items
  const getArrayWithoutItem = (itemToDeleteRank: number): T[] => {
    const questionToDeleteIndex = itemList.findIndex((item) => item.rank === itemToDeleteRank);
    const unaffectedItems = itemList.slice(0, questionToDeleteIndex);

    const affectedItems = itemList.slice(questionToDeleteIndex + 1).map((item) => ({
      ...item,
      rank: item.rank - 1,
    }));

    return [...unaffectedItems, ...affectedItems];
  };

  return {
    getArrayWithoutItem,
    getNewItemRank,
  };
};
