import { css } from '@emotion/react';

import {
  navItemTextColor,
} from '~Common/styles/colors';
import {
  heading6Color,
  rootDefaultAColor,
  rootDefaultPColor,
} from './colors';

/**
 * @deprecated Old weights, see packages/leadr-frontend/src/deprecated/ui/styles/root.js
 */
export const WEIGHTS = {
  REGULAR: 400,
  MEDIUM: 600,
  BOLD: 800,
};

/**
 * @deprecated Old font sizes
 */
export const FONT_SIZE = {
  h1: 2.5,
  h2: 2,
  h3: 1.5,
  h4: 1,
  h5: 0.75,
  h6: 0.75,
};

export const BASE_FONT_SIZE = 16;

export const withBaseFont = () => css`
  font-family: ProximaNova !important;
  letter-spacing: 0.5px;
  font-style: normal;
`;

export const withH1Font = () => css`
  font-size: ${FONT_SIZE.h1}rem;
  font-weight: ${WEIGHTS.REGULAR};
`;

export const withH2Font = () => css`
  font-size: ${FONT_SIZE.h2}rem;
  font-weight: ${WEIGHTS.REGULAR};
`;

export const withH3Font = () => css`
  font-size: ${FONT_SIZE.h3}rem;
  font-weight: ${WEIGHTS.MEDIUM};
`;

export const withH4Font = () => css`
  font-size: ${FONT_SIZE.h4}rem;
  font-weight: ${WEIGHTS.MEDIUM};
`;

export const withH5Font = () => css`
  font-size: ${FONT_SIZE.h5}rem;
  font-weight: ${WEIGHTS.MEDIUM};
`;

export const withH6Font = () => css`
  font-size: ${FONT_SIZE.h6}rem;
  font-weight: ${WEIGHTS.BOLD};
  text-transform: uppercase;
  color: ${heading6Color}
`;

export const withTextFont = () => css`
  font-size: ${FONT_SIZE.h6}rem;
  font-weight: ${WEIGHTS.REGULAR};
  color: ${rootDefaultPColor};
`;

export const withLinkFont = () => css`
  font-size: ${FONT_SIZE.h6}rem;
  font-weight: ${WEIGHTS.REGULAR};
  text-transform: uppercase;
  color: ${rootDefaultAColor};
`;

export const withNavItemFont = () => css`
  letter-spacing: 0.5px;
  font-style: normal;
  font-feature-settings: 'tnum' on, 'lnum' on;
  font-weight: ${WEIGHTS.BOLD};
  font-size: ${FONT_SIZE.h6}rem;
  color: ${navItemTextColor};
`;
