import { css } from '@emotion/react';
import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { RecurrenceDropdownOptions } from '~ActionItems/const/constants';
import { forMobileObject } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';

const styles = {
  dropdown: css({
    flex: 1,
    marginLeft: '0.5rem',
    '.MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: '1rem',
      color: palette.neutrals.gray800,
      fontWeight: 500,
    },
  }, forMobileObject({
    flexShrink: 0,
  })),
};

type ViewProps = Omit<DropdownProps<RecurrenceDropdownOptions>, 'renderItem'>

const View = ({
  items,
  value,
  onChange,
  ...props
}: ViewProps): JSX.Element => (
  <Dropdown
    css={styles.dropdown}
    items={items}
    value={value}
    onChange={onChange}
    renderItem={(item: DropdownItem<RecurrenceDropdownOptions>) => (
      <div>
        {item.text}
      </div>
    )}
    {...props}
  />
);

interface RecurrenceFrequencyDropdownProps extends Omit<ViewProps, 'items'> {
  interval?: number,
}
const RecurrenceFrequencyDropdown = ({ interval, ...props }: RecurrenceFrequencyDropdownProps): JSX.Element => {
  const isPlural = interval !== 1;

  const recurrenceOptions = [
    { value: RecurrenceDropdownOptions.Weekly, text: isPlural ? 'weeks' : 'week' },
    { value: RecurrenceDropdownOptions.Monthly, text: isPlural ? 'months' : 'month' },
  ];
  const hookProps = {
    items: recurrenceOptions,
  };
  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default RecurrenceFrequencyDropdown;
