import { create } from 'zustand';

interface ShownItemEditors {
  [key: string]: boolean,
}
interface UseShowItemEditor {
  shownItemEditors: ShownItemEditors,
  showItemEditor: (itemId: string) => void,
  hideItemEditor: (itemId: string) => void,
  getIsItemEditorVisible: (itemId: string) => boolean,
  removeItemEditor: (itemId: string) => void,
  hideAllItemEditors: () => void,
}

export const useShowItemEditor = create<UseShowItemEditor>((set, get) => ({
  shownItemEditors: {},
  showItemEditor: (itemId: string) => set(() => ({
    shownItemEditors: {
      ...get().shownItemEditors,
      [itemId]: true,
    },
  })),
  hideItemEditor: (itemId: string) => set(() => ({
    shownItemEditors: {
      ...get().shownItemEditors,
      [itemId]: false,
    },
  })),
  getIsItemEditorVisible: (itemId: string) => get().shownItemEditors[itemId] || false,
  removeItemEditor: (itemId: string) => set(() => {
    if (!get().shownItemEditors[itemId]) {
      const newShownItemEditors = { ...get().shownItemEditors };
      delete newShownItemEditors[itemId];
      return {
        shownItemEditors: newShownItemEditors,
      };
    }

    return get().shownItemEditors;
  }),
  hideAllItemEditors: () => set(() => ({
    shownItemEditors: {},
  })),
}));
