import { useMemo } from 'react';

// TODO: Update this to use the common useGetMarksMemoized instead
export const useGetMarksMemoized = ({ maxValue = 3 }) => useMemo(() => (
  // Filling in an array of marks that go from 1 to the max value
  ([...Array(parseInt(maxValue, 10)).keys()].map((number) => number + 1).map((value) => ({
    value,
    label: String(value),
  })))
), [maxValue]);
