import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { MEETING_FREQUENCY } from '~Common/const/defaults';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { useEndMeeting } from '~Meetings/hooks/useEndMeetingRecurrence';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';

const styles = {
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

export const endOccurenceTemplate = {
  name: 'END_MEETING_OCCURENCE',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton, createButton, renderDrawerInstructions, type, onFormSubmit, frequency,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        title={frequency === MEETING_FREQUENCY.ONE_TIME ? 'Cancel This Meeting' : 'Archive Meeting Recurrence'}
        renderCloseButton={closeButton}
      />
    )}
    renderBody={() => (
      <div
        css={styles.drawerBody}
      >
        {renderDrawerInstructions(type)}
        <div />
        {createButton()}
      </div>
    )}
  />
);

View.propTypes = {
  closeButton: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  frequency: PropTypes.string.isRequired,
};

View.defaultProps = {};

const EndOccurence = ({ id, type, ...props }) => {
  const dispatch = useDispatch();
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const { frequency, title } = meetingDetails ?? {};
  const doArchiveMeeting = useEndMeeting({ template: endOccurenceTemplate, frequency });
  const renderDrawerInstructions = () => {
    let startText;
    let endText;

    if (frequency === MEETING_FREQUENCY.ONE_TIME) {
      startText = 'Cancel';
    } else {
      startText = 'Archive';
    }

    if (frequency === MEETING_FREQUENCY.ONE_TIME) {
      endText = '?';
    } else {
      endText = 'and all past meetings?';
    }
    const drawerText = `${startText} your ${title} meeting ${endText}`;
    const recurringSubText = 'Meeting agendas and notes from past meetings will still be available for you in the meeting archives if you need them later.';
    const oneTimeSubText = 'Canceling this meeting will delete its occurrence on all participants calendars.';
    return (
      <div>
        <DrawerInstructions
          html={drawerText}
        />
        <br />
        <DrawerInstructions
          html={frequency === 'ONE_TIME' ? oneTimeSubText : recurringSubText}
        />
      </div>
    );
  };
  const createButton = useCallback(() => (
    <LeadrButton
      type="submit"
      color="danger"
      data-test-id="meetingEndOccurence"
    >
      {frequency === 'ONE_TIME' ? 'Cancel Meeting' : 'End Recurrence'}
    </LeadrButton>
  ), [frequency]);

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const onFormSubmit = useCallback((e) => {
    e.preventDefault();
    doArchiveMeeting({
      type,
      ...meetingDetails,
    });
  }, [doArchiveMeeting, type, meetingDetails]);

  const hookProps = {
    createButton,
    closeButton,
    renderDrawerInstructions,
    id,
    type,
    onFormSubmit,
    frequency,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

EndOccurence.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

EndOccurence.defaultProps = {};

registerDrawer({
  templateName: endOccurenceTemplate.name,
  component: EndOccurence,
});

export { View };
export default EndOccurence;
