import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { AgendaType } from '@leadr-hr/types';
import OnePersonCreatingItem from '~Meetings/components/details/agenda/RealtimeCreationCard/OnePersonCreatingItem';
import TwoPeopleCreatingItems from '~Meetings/components/details/agenda/RealtimeCreationCard/TwoPeopleCreatingItems';
import SeveralPeopleCreatingSameItem from '~Meetings/components/details/agenda/RealtimeCreationCard/SeveralPeopleCreatingSameItem';
import SeveralPeopleCreatingDifferentItems from '~Meetings/components/details/agenda/RealtimeCreationCard/SeveralPeopleCreatingDifferentItems';

const styles = {
  realtimeCreationCard: css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: hexToRGBA(palette.brand.indigo, 0.2),
    borderRadius: '.3125rem',
    padding: '.75rem',
    color: palette.neutrals.gray700,
    fontStyle: 'italic',
  }),
};

export const agendaItemNameMap = {
  [AgendaType.AgendaSection]: 'section',
  [AgendaType.AgendaTopic]: 'topic',
  [AgendaType.Comment]: 'comment',
  [AgendaType.Attachment]: 'attachment',
};

interface RealtimeCreationCardProps {
  children: ReactNode,
}

const Card = ({
  children,
  ...props
}: RealtimeCreationCardProps): JSX.Element => (
  <div css={styles.realtimeCreationCard} {...props}>
    {children}
  </div>
);

const RealtimeCreationCard = Object.assign(Card, {
  OnePersonCreatingItem,
  TwoPeopleCreatingItems,
  SeveralPeopleCreatingSameItem,
  SeveralPeopleCreatingDifferentItems,
});

export default RealtimeCreationCard;
