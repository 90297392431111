import { css } from '@emotion/react';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { ACTION_MENU_PROPS_SHAPE, MENU_ITEMS_SHAPE } from '~Common/const/proptypes';

import { surveyActionMenu } from '~Common/styles/colors';
import ActionMenu, { useActionMenu } from '~Meetings/components/shared/ActionMenu';
import FlatButton from '~Common/V3/components/FlatButton';

const styles = {
  container: css``,
  contextMenuContainer: css`
    border: .0625rem solid ${surveyActionMenu.border};
    color: ${surveyActionMenu.button};
    border-radius: .1875rem;
    width: 1.75rem;
    height: 1.75rem;
    font-size: 1.125rem;
  `,
};

const View = ({
  doOpen, menuItems, actionMenuProps, onClickCallback, ...props
}) => (
  <div
    css={styles.container}
    {...props}
  >
    <FlatButton
      onClick={doOpen}
      renderButtonContents={() => (
        <div css={styles.contextMenuContainer}>
          <FontAwesomeIcon icon={faEllipsisV} size="lg" />
        </div>
      )}
    />
    <ActionMenu
      menuItems={menuItems}
      onClick={onClickCallback}
      {...actionMenuProps}
    />
  </div>
);

View.propTypes = {
  doOpen: PropTypes.func.isRequired,
  menuItems: MENU_ITEMS_SHAPE,
  actionMenuProps: ACTION_MENU_PROPS_SHAPE.isRequired,
  onClickCallback: PropTypes.func.isRequired,
};

View.defaultProps = {
  menuItems: [[]],
};

const SurveyActionMenu = ({ ...props }) => {
  const { doOpen, ...actionMenuProps } = useActionMenu();

  const onClickCallback = useCallback(() => {
    actionMenuProps.onClose();
  }, [actionMenuProps]);

  const hookProps = {
    doOpen,
    actionMenuProps,
    onClickCallback,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SurveyActionMenu.propTypes = {};

SurveyActionMenu.defaultProps = {};

export { View };
export default SurveyActionMenu;
