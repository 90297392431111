import { css } from '@emotion/react';
import { useMemo } from 'react';
import { palette } from '~Common/styles/colors';
import { MyScheduleMeeting } from '~Home/const/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt, faEdit, faEyeSlash, faLink,
} from '@fortawesome/pro-light-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { useMeetingUrl } from '~Common/hooks/useMeetingUrl';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import { withVisibleOnHover } from '~Common/styles/mixins';
import Button from '~Common/V3/components/Button';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import ExternalCalendarMeetingButtons from './ExternalCalendarMeetingButtons';
import TopicCountText from './TopicCountText';
import MeetingCardBase from './MeetingCardBase';

const styles = {
  meetingCard: (isOnlyInExternalCalendar: boolean) => css({
    marginBottom: '0.5rem',
  }, isOnlyInExternalCalendar && ({
    backgroundColor: palette.neutrals.gray100,
    border: `1px solid ${palette.neutrals.gray300}`,
  })),
  eventDetectedTextContainer: css({
    color: palette.neutrals.gray700,
    fontSize: '0.5rem',
    marginLeft: '1rem',
    textAlign: 'right',
    lineHeight: 1,
  }),
  eventDetectedText: css({
    fontStyle: 'italic',
  }),
  ignoredMeetingIcon: css({
    color: palette.neutrals.gray700,
  }),
  visibleOnHover: (isHovering: boolean) => css({
    display: 'flex',
  }, withVisibleOnHover(isHovering)),
  overflowMenuIcon: css({
    color: palette.neutrals.gray500,
  }),
};

interface ViewProps {
  meeting: MyScheduleMeeting,
  overflowMenuItems: MenuItem[][],
  hasTeams?: boolean,
}

const View = ({
  meeting,
  overflowMenuItems,
  hasTeams,
  ...props
}: ViewProps): JSX.Element => (
  <MeetingCardBase
    css={styles.meetingCard(meeting.isOnlyInExternalCalendar)}
    meeting={meeting}
    renderRightHeader={(isHovering: boolean) => (
      <>
        {meeting.isOnlyInExternalCalendar && (
          <>
            {meeting.isIgnored && (
              <FontAwesomeIcon css={styles.ignoredMeetingIcon} icon={faEyeSlash} />
            )}
            {!meeting.isIgnored && (
              <div css={styles.eventDetectedTextContainer}>
                <div css={styles.eventDetectedText}>event detected in</div>
                <div css={styles.eventDetectedText}>your calendar</div>
              </div>
            )}
          </>
        )}
        {!meeting.isOnlyInExternalCalendar && (
          <OverflowMenu
            css={styles.visibleOnHover(isHovering)}
            actionMenuProps={{
              onBackdropClick: (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                event.preventDefault();
              },
            }}
            menuItems={overflowMenuItems}
            renderOverflowMenuButton={(doOpen) => (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  doOpen(event);
                }}
                variant="text"
                renderContents={() => (
                  <FontAwesomeIcon css={styles.overflowMenuIcon} icon={faEllipsisVertical} />
                )}
              />
            )}
          />
        )}
      </>
    )}
    renderRightFooter={(isHovering: boolean) => (
      <>
        {meeting.isOnlyInExternalCalendar && (
          <ExternalCalendarMeetingButtons
            css={styles.visibleOnHover(isHovering)}
            meeting={meeting}
            hasTeams={hasTeams}
          />
        )}
        {!meeting.isOnlyInExternalCalendar && (
          <TopicCountText numberOfAgendaItems={meeting.numberOfAgendaItems} />
        )}
      </>
    )}
    {...props}
  />
);

type MeetingCardProps = Omit<ViewProps, 'overflowMenuItems'>

const MeetingCard = ({
  meeting,
  ...props
}: MeetingCardProps): JSX.Element => {
  const {
    navigateToMeeting,
    copyMeetingUrlToClipboard,
  } = useMeetingUrl();

  const {
    nylasEventId,
    linkToUsersExternalCalendar,
    meetingType,
    meetingInstanceId,
    teamId,
    meetingFactoryId,
  } = meeting;

  const overflowMenuItems = useMemo(() => (
    [[
      ...(nylasEventId && linkToUsersExternalCalendar ? [{
        text: 'View in Calendar',
        icon: faCalendarAlt,
        isDeactivated: false,
        onClick: () => {
          window.open(linkToUsersExternalCalendar, '_blank');
        },
      }] : []),
      {
        text: 'Edit Topics',
        icon: faEdit,
        isDeactivated: false,
        onClick: () => {
          navigateToMeeting({
            meetingType,
            meetingInstanceId,
          });
        },
      },
      {
        text: 'Copy Share Link',
        icon: faLink,
        isDeactivated: false,
        onClick: () => {
          copyMeetingUrlToClipboard({
            meetingType,
            meetingInstanceId,
            teamId,
            factoryId: meetingFactoryId,
          });
        },
      },
    ]]
  ), [
    copyMeetingUrlToClipboard,
    linkToUsersExternalCalendar,
    meetingFactoryId,
    meetingInstanceId,
    meetingType,
    navigateToMeeting,
    nylasEventId,
    teamId,
  ]);

  const hookProps = {
    overflowMenuItems,
    meeting,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingCard;
