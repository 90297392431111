import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { cardLabelColor } from '~Deprecated/ui/styles/colors';
import { truncate } from '~Deprecated/ui/styles/mixins';
import CornerAvatar from '~Deprecated/ui/components/Users/Avatars/CornerAvatar';

const styles = (hideName) => css`
  display: flex;
  align-items: center;
  border-radius: 8px 8px 0 0;
  overflow: hidden;

  & > h4 {
    color: ${cardLabelColor};
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 24px;

    ${!hideName && (`
      padding-left: 24px;
    `)}
  }
`;

const CoachingCardHeader = ({
  avatarList,
  renderHeader,
  hideName,
  width,
  height,
  ...props
}) => (
  <div
    css={styles(hideName)}
    {...props}
  >
    <CornerAvatar
      imgUrl={avatarList?.[0]?.profileImageUrl}
      name={`${avatarList?.[0]?.firstName} ${avatarList?.[0]?.lastName}`}
      width={width}
      height={height}
    />
    { !renderHeader && (
      <>
        {!hideName && (
          <h4
            css={truncate}
          >
            { `${avatarList?.[0]?.firstName} ${avatarList?.[0]?.lastName}` }
          </h4>
        )}
      </>
    ) }
    { renderHeader && renderHeader(avatarList) }
  </div>
);

CoachingCardHeader.propTypes = {
  avatarList: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderHeader: PropTypes.func,
  hideName: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

CoachingCardHeader.defaultProps = {
  hideName: false,
  renderHeader: null,
  width: 80,
  height: 48,
};

export default CoachingCardHeader;
