import { RefObject, WheelEvent, useCallback } from 'react';
import { useHookWithRefCallback } from '~Common/hooks/useHookWithRefCallback';

export const useHorizontalScroll = (): RefObject<HTMLDivElement> => {
  const hijackScroll = useCallback((event: WheelEvent<HTMLDivElement>) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
  }, []);

  const [horizontalScrollRef] = useHookWithRefCallback({
    cleanupFunction: useCallback((element: HTMLDivElement) => {
      // @ts-expect-error I dont know how to type this :(
      element.removeEventListener('wheel', (event: WheelEvent<HTMLDivElement>) => hijackScroll(event));
    }, [hijackScroll]),
    afterNodeMountFunction: useCallback((node: HTMLDivElement) => {
      // @ts-expect-error I dont know how to type this :(
      node.addEventListener('wheel', (event: EventListenerOrEventListenerObject) => hijackScroll(event));
    }, [hijackScroll]),
  });

  // TODO: Fix this type when useHookWithRefCallback is typed
  return horizontalScrollRef as unknown as RefObject<HTMLDivElement>;
};
