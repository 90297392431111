import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import TextField from '~Common/components/Fields/TextField';
import { FEEDBACK_SHAPE } from '~Common/const/proptypes';
import OptionalDateField from '~Common/components/Fields/OptionalDateField';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';

const styles = {
  titleInput: css`
  
  & input {
    font-weight: bold !important;
  }
  &:not(:first-of-type) {
    margin-block-start: 0.5rem;

  }
`,
  drawerInput: css`
    &:not(:first-of-type) {
      margin-block-start: 0.5rem;
    }
  `,
  btnAssignDueDate: css`
    margin-block-start: 1rem;
    margin-inline: unset;
  `,
};

const View = ({
  feedback,
  isReadOnly,
  showDate,
  renderEditor,
  forceDate,
}) => (
  <>
    <TextField
      name="feedbackTitle"
      disabled={isReadOnly}
      css={styles.titleInput}
      label="Title"
      initialValue={feedback?.text || ''}
      required
    />
    {renderEditor()}
    {showDate && (
      <OptionalDateField
        name="dueDate"
        disabled={isReadOnly}
        css={styles.drawerInput}
        initialValue={feedback?.dueDateInMillis}
        buttonProps={{
          text: 'Request By Date (optional)',
        }}
        dPickerProps={{
          disablePast: true,
          label: 'Request By Date',
        }}
        showClear={!isReadOnly || !forceDate}
        forceDate={forceDate}
      />
    )}
  </>
);

View.propTypes = {
  feedback: FEEDBACK_SHAPE,
  isReadOnly: PropTypes.bool,
  showDate: PropTypes.bool,
  renderEditor: PropTypes.func.isRequired,
  forceDate: PropTypes.bool,
};

View.defaultProps = {
  feedback: null,
  isReadOnly: false,
  showDate: true,
  forceDate: false,
};

const RequestDetails = ({
  feedback,
  isReadOnly,
  pdpId,
  ...props
}) => {
  const renderEditor = () => (
    <Froala
      label="Objective"
      name="introduction"
      isReadOnly={isReadOnly}
      initialValue={feedback?.introduction || ''}
      styleOverrides={styles.drawerInput}
    />
  );

  const forceDate = pdpId !== undefined;
  const hookProps = {
    feedback,
    isReadOnly,
    renderEditor,
    forceDate,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

RequestDetails.propTypes = {
  feedback: FEEDBACK_SHAPE,
  isReadOnly: PropTypes.bool,
  pdpId: PropTypes.string,
};

RequestDetails.defaultProps = {
  feedback: null,
  isReadOnly: false,
  pdpId: undefined,
};

export { View };
export default RequestDetails;
