import { useMutation } from '@tanstack/react-query';
import { fileUploadService } from '~Deprecated/hooks/fileUpload/api';
import { queryClient } from '~Common/const/queryClient';

export const useFileUploadMutation = ({ queryKey }) => {
  const mutation = useMutation({
    mutationFn: fileUploadService,
    mutationKey: [...queryKey, 'upload'],
    onMutate: (variables) => {

    },
    onError: (error, variables, context) => {

    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  return {
    fileUploadMutation: mutation.mutate,
    isLoading: mutation.isPending,
    status: mutation.status,
    resetUploadFile: mutation.reset,
  };
};
