import { Fragment } from 'react';
import { EngagementCategory, ViewPerspective } from '~Insights/const/types';
import { EngagementScores } from '~Insights/hooks/useEngagementData';
import InsightsDelta from '~Insights/components/InsightsDelta';
import { ExpandedProps, styles, SubtabPerspective } from './Expanded';

type ExpandedSidebarProps = Pick<ExpandedProps,
  | 'data'
  | 'deltas'
  | 'endDate'
  | 'expandedTab'
  | 'startDate'
  | 'subtabPerspective'
  | 'toggleMenuValue'
>

export const ExpandedSidebar = ({
  data,
  deltas,
  endDate,
  expandedTab,
  startDate,
  subtabPerspective,
  toggleMenuValue,
}: ExpandedSidebarProps): JSX.Element => (
  <>
    {toggleMenuValue === ViewPerspective.Company && expandedTab === 'overall' && (
      <p css={styles.explainer}>Percentages represent change over time.</p>
    )}
    {toggleMenuValue === ViewPerspective.Company && expandedTab === 'overall'
      && Object.entries(EngagementCategory).map(([key, value]) => (
        <Fragment key={key}>
          {startDate === endDate && (
            <div>
              {typeof data[data.length - 1]?.averages[key as keyof EngagementScores] === 'number' ? (
                <>
                  <p css={styles.bigNumber}>
                    {Math.round(data[data.length - 1]?.averages[key as keyof EngagementScores])}
                    /5
                  </p>
                  <p>{value}</p>
                </>
              ) : (
                <>
                  <p css={styles.bigNumber}>
                    N/A
                  </p>
                  <p>{value}</p>
                </>
              )}
            </div>
          )}
          {startDate !== endDate && (
            <div key={key}>
              <InsightsDelta value={deltas[key as keyof EngagementScores]} />
              <p>{value}</p>
            </div>
          )}
        </Fragment>
      ))}
    {(toggleMenuValue === ViewPerspective.Company
      || toggleMenuValue === ViewPerspective.DirectReports)
      && expandedTab !== 'overall'
      && subtabPerspective === SubtabPerspective.OverallScore
      && (
        <>
          {startDate !== endDate && (
            <div key={`delta-${expandedTab}`}>
              <InsightsDelta value={deltas[expandedTab as keyof EngagementScores]} />
              <p>Change Over Time</p>
            </div>
          )}
          <div key={`average-${expandedTab}`}>
            {typeof data[data.length - 1]?.averages[expandedTab as keyof EngagementScores] === 'number' ? (
              <>
                <p css={styles.bigNumber}>
                  {Math.round(data[data.length - 1]?.averages[expandedTab as keyof EngagementScores])}
                  /5
                </p>
                <p>Average Score</p>
              </>
            ) : (
              <>
                <p css={styles.bigNumber}>
                  N/A
                </p>
                <p>Average Score</p>
              </>
            )}
          </div>
        </>
      )}
    {toggleMenuValue === ViewPerspective.DirectReports
      && subtabPerspective === SubtabPerspective.OverallScore
      && expandedTab === 'overall'
      && (
        <>
          {startDate === endDate && (
            <>
              {Object.entries(data[data.length - 1]?.averages).map(([cat, score]) => (
                <div key={cat}>
                  <InsightsDelta
                    value={score as number}
                  />
                  <p>{EngagementCategory[cat as keyof typeof EngagementCategory]}</p>
                </div>
              ))}
            </>
          )}
          {startDate !== endDate && (
            <>
              {Object.entries(deltas).map(([cat, score]) => (
                <div key={cat}>
                  <InsightsDelta
                    value={score}
                  />
                  <p>{EngagementCategory[cat as keyof typeof EngagementCategory]}</p>
                </div>
              ))}
            </>
          )}
        </>
      )}
  </>
);
