import { UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { useEditNote, EditNoteData } from '~Common/hooks/useEditNote';
import { GetNotesResponse } from '~Common/hooks/useGetNotes';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';

export const useEditActionItemComment = (): UseMutationResult<HttpCallReturn<null>, unknown, EditNoteData> => {
  const orgId = getOrganizationId();

  return useEditNote({
    onMutate: async (variables) => {
      const { id, context, note } = variables;
      const [queryKey, queryData] = queryClient.getQueriesData({ queryKey: [orgId, 'notes', context.type, context.id] })[0];

      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<HttpCallReturn<GetNotesResponse>>(queryKey, (originalData) => {
        if (originalData) {
          const updatedData = cloneDeep(originalData);
          const noteIndex = updatedData.response.notes.findIndex((originalNote) => (originalNote.id === id));
          const originalNote = updatedData.response.notes[noteIndex];

          updatedData.response.notes[noteIndex] = {
            ...originalNote,
            text: note.text,
            rank: note.rank,
            lastModifiedInMillis: moment().valueOf(),
          };

          updatedData.response.notes.sort((a, b) => (a.rank - b.rank));

          return updatedData;
        }

        return originalData;
      });

      return { queryKey, queryData };
    },
    onSettled: async (_, __, variables) => {
      const { context } = variables;

      await queryClient.invalidateQueries({ queryKey: [orgId, 'notes', context.type, context.id] });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
    onError: () => {
      toast.error('Action Item comment could not be edited');
    },
  });
};
