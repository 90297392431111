import GridCard from '~Common/components/Cards/GridCard';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import { SurveyStatusValue } from '~Surveys/const/Survey';

interface GridSurveyCardProps {
  renderRightSection?: () => JSX.Element,
  onCardClick: () => void,
  title: string,
  description: string,
  assignedUsersInfo: PersonDisplayInformation[],
  numberOfUsersAssigned: number,
  dueDate: string | null,
  isComplete: boolean,
  status: SurveyStatusValue,
}

const GridSurveyCard = ({
  renderRightSection,
  onCardClick,
  title,
  description,
  assignedUsersInfo,
  numberOfUsersAssigned,
  dueDate,
  isComplete,
  status,
  ...props
}: GridSurveyCardProps): JSX.Element => (
  <GridCard
    onClick={onCardClick}
    title={title}
    feature="surveys"
    description={description}
    assignedUsersInfo={assignedUsersInfo}
    numberOfUsersAssigned={numberOfUsersAssigned}
    renderFooter={() => (
      <>
        <DueDateFromNow
          dueDate={dueDate ?? ''}
          isComplete={isComplete || status === SurveyStatusValue.Closed}
        />
        {renderRightSection?.()}
      </>
    )}
    {...props}
  />
);

export default GridSurveyCard;
