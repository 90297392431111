/* eslint-disable react/button-has-type */

import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons';

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */

// TODO: Delete this after feedback UI rewrite
const AddPersonButton = (props) => (
  <Button
    buttonColor={BUTTON_COLOR.OPTION}
    {...props}
    icon={faUserPlus}
  />
);

AddPersonButton.propTypes = {};

AddPersonButton.defaultProps = {};

export default AddPersonButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
