import {
  ComponentProps,
  FormEvent,
  useCallback,
  useState,
  ChangeEvent,
  useMemo,
} from 'react';

import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerInput from '~Common/V3/components/DrawerInput';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { CreateOrganizationProps, useCreateOrganization } from '~Nexus/hooks/useCreateOrganization';
import { useEditOrganization } from '~Nexus/hooks/useEditOrganization';
import { useDispatch, useSelector } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { getOrganizationById } from '~Deprecated/selectors/organizationInsights/getOrganizationById';
import LeadrSwitch from '~Common/V3/components/LeadrSwitch';
import { css } from '@emotion/react';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { FormHelperText } from '@mui/material';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import Button from '~Common/V3/components/Button';
import DatePicker from '~Common/V3/components/DatePicker';
import moment from 'moment-timezone';
import { useGetStates } from '~Nexus/hooks/useGetStates';
import { NexusOrganization } from '~Nexus/types';

export const createEditOrganizationDrawerTemplate = {
  name: 'CREATE_EDIT_ORGANIZATION_DRAWER',
  type: templateType.PRIMARY,
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  drawerBody: css({
    padding: '1rem',
  }),
  drawerControl: css({
    marginBottom: '0.75rem',
  }),
  dropdown: css({
    '.MuiSelect-select': {
      fontWeight: 700,
    },
  }),
  buttonContainer: css({
    width: '100%',
    display: 'flex',
  }),
  submitButton: css({
    marginRight: '0.625rem',
  }),
  helpText: css({
    marginBottom: '0.5rem',
  }),
  salesforceContainer: css({
    marginBottom: '1rem',
  }),
};

export interface CreateActionItemViewProps extends Omit<ComponentProps<'div'>, 'onSubmit'> {
  onClose: () => void,
  onSubmit: (e: FormEvent<HTMLFormElement>) => void,
  onEnableHrModuleChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onOrganizationInTrialChange: (e: ChangeEvent<HTMLInputElement>) => void,
  organization: NexusOrganization,
  stateDropdownItems: DropdownItem[],
  isEdit: boolean,
  isInsightsEnabled: boolean,
  isRecognitionEnabled: boolean,
  isIsolvedEnabled: boolean,
  isTrialEnabled: boolean,
  isSimplifiedNavigationEnabled: boolean,
  shouldShowEaseUrl?: boolean,
  defaultEnableOneWayCalendarIntegration: boolean,
  shouldShowTrialFields?: boolean,
  trialEndDate?: Date,
  enableTheTable: boolean,
}

const View = ({
  onClose,
  onSubmit,
  onEnableHrModuleChange,
  onOrganizationInTrialChange,
  organization,
  stateDropdownItems,
  isEdit,
  isInsightsEnabled,
  isRecognitionEnabled,
  isIsolvedEnabled,
  isTrialEnabled,
  isSimplifiedNavigationEnabled,
  shouldShowEaseUrl,
  defaultEnableOneWayCalendarIntegration,
  shouldShowTrialFields,
  trialEndDate,
  enableTheTable,
  ...props
}: CreateActionItemViewProps): JSX.Element => (
  <DrawerLayout
    onSubmit={onSubmit}
    renderHeader={() => (
      <DrawerHeader
        title={isEdit ? 'Edit Organization' : 'Create Organization'}
        renderCloseButton={() => (
          <IconButton
            onClick={onClose}
            tooltip="Close"
            type="button"
            icon={faTimes}
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div
        css={styles.drawerBody}
        {...props}
      >
        <DrawerInput
          css={styles.drawerControl}
          name="logo"
          label="Upload Logo"
          type="file"
        />

        <DrawerInput
          css={styles.drawerControl}
          name="organizationName"
          label="Name"
          initialValue={organization?.name}
          maxLength={200}
          required
        />

        <DrawerInput
          css={styles.drawerControl}
          name="street"
          label="Street"
          initialValue={organization?.street}
          maxLength={100}
        />

        <DrawerInput
          css={styles.drawerControl}
          name="city"
          label="City"
          initialValue={organization?.city}
          maxLength={100}
          required
        />

        {/* ToDo: Fix the sizing on this dropdown to match the other fields */}
        <Dropdown
          value={organization?.stateId}
          css={[styles.drawerControl, styles.dropdown]}
          name="stateId"
          items={stateDropdownItems}
          label="State"
          required
        />

        <DrawerInput
          css={styles.drawerControl}
          name="zip"
          label="Zip"
          initialValue={organization?.zip}
          maxLength={9}
        />

        {isIsolvedEnabled && (
          <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableIsolved">
            <LeadrSwitch.Label
              label="Enable iSolved HR"
            >
              <LeadrSwitch.Switch
                inputProps={{ name: 'enableISolvedModule' }}
                defaultChecked={organization?.enableISolvedModule}
              />
            </LeadrSwitch.Label>
          </LeadrSwitch>
        )}

        <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableHR">
          <LeadrSwitch.Label
            label="Enable HR Module"
          >
            <LeadrSwitch.Switch
              onChange={onEnableHrModuleChange}
              inputProps={{ name: 'enableHRModule' }}
              defaultChecked={organization?.enableHRModule}
            />
          </LeadrSwitch.Label>
        </LeadrSwitch>

        {shouldShowEaseUrl && (
          <DrawerInput
            css={styles.drawerControl}
            name="easeUrl"
            label="Ease URL"
            initialValue={organization?.easeUrl}
            required
          />
        )}

        <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableReviews">
          <LeadrSwitch.Label
            label="Enable Reviews"
          >
            <LeadrSwitch.Switch
              inputProps={{ name: 'enableReviews' }}
              defaultChecked={organization?.enableReviews}
            />
          </LeadrSwitch.Label>
        </LeadrSwitch>

        <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnablePDPS">
          <LeadrSwitch.Label
            label="Enable PDPs"
          >
            <LeadrSwitch.Switch
              inputProps={{ name: 'enablePdps' }}
              defaultChecked={organization?.enablePdps}
            />
          </LeadrSwitch.Label>
        </LeadrSwitch>

        {isInsightsEnabled && (
          <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableInsights">
            <LeadrSwitch.Label
              label="Enable Leadr Insights"
            >
              <LeadrSwitch.Switch
                inputProps={{ name: 'enableInsights' }}
                defaultChecked={organization?.enableInsights}
              />
            </LeadrSwitch.Label>
          </LeadrSwitch>
        )}

        {isRecognitionEnabled && (
          <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableRecognition">
            <LeadrSwitch.Label
              label="Enable Recognition"
            >
              <LeadrSwitch.Switch
                inputProps={{ name: 'enableRecognition' }}
                defaultChecked={organization?.enableRecognition}
              />
            </LeadrSwitch.Label>
          </LeadrSwitch>
        )}

        {isTrialEnabled && (
          <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableTrialOrg">
            <LeadrSwitch.Label
              label="Trial Org"
            >
              <LeadrSwitch.Switch
                onChange={onOrganizationInTrialChange}
                inputProps={{ name: 'organizationInTrial' }}
                defaultChecked={organization?.organizationInTrial}
              />
            </LeadrSwitch.Label>
          </LeadrSwitch>
        )}

        <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableOneWayCalendarIntegration">
          <LeadrSwitch.Label
            label="Enable One Way Calendar Integration"
          >
            <LeadrSwitch.Switch
              inputProps={{ name: 'enableOneWayCalendarIntegration' }}
              defaultChecked={defaultEnableOneWayCalendarIntegration}
            />
          </LeadrSwitch.Label>
        </LeadrSwitch>

        <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableTableGroup">
          <LeadrSwitch.Label
            label="Enable The Table Group"
          >
            <LeadrSwitch.Switch
              inputProps={{ name: 'enableTheTable' }}
              defaultChecked={enableTheTable}
            />
          </LeadrSwitch.Label>
        </LeadrSwitch>

        <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableUnsupportedCalendarProviders">
          <LeadrSwitch.Label
            label="Enable Unsupported Calendar Providers"
          >
            <LeadrSwitch.Switch
              inputProps={{ name: 'enableUnsupportedCalendarProviders' }}
              defaultChecked={organization?.enableUnsupportedCalendarProviders}
            />
          </LeadrSwitch.Label>
        </LeadrSwitch>

        {isSimplifiedNavigationEnabled && (
          <>
            <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableActionItems">
              <LeadrSwitch.Label
                label="Enable Action Items"
              >
                <LeadrSwitch.Switch
                  inputProps={{ name: 'enableActionItems' }}
                  defaultChecked={organization?.enableActionItems}
                />
              </LeadrSwitch.Label>
            </LeadrSwitch>

            <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableFeedback">
              <LeadrSwitch.Label
                label="Enable Feedback"
              >
                <LeadrSwitch.Switch
                  inputProps={{ name: 'enableFeedback' }}
                  defaultChecked={organization?.enableFeedback}
                />
              </LeadrSwitch.Label>
            </LeadrSwitch>

            <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableGoals">
              <LeadrSwitch.Label
                label="Enable Goals"
              >
                <LeadrSwitch.Switch
                  inputProps={{ name: 'enableGoals' }}
                  defaultChecked={organization?.enableGoals}
                />
              </LeadrSwitch.Label>
            </LeadrSwitch>

            <LeadrSwitch css={styles.drawerControl} data-test-id="nexusEnableLearning">
              <LeadrSwitch.Label
                label="Enable Learning"
              >
                <LeadrSwitch.Switch
                  inputProps={{ name: 'enableLearning' }}
                  defaultChecked={organization?.enableLearning}
                />
              </LeadrSwitch.Label>
            </LeadrSwitch>
          </>
        )}

        {isTrialEnabled && shouldShowTrialFields && (
          <DatePicker
            initialDate={trialEndDate}
            label="Trial End Date"
            name="trialEndsAt"
            disablePast={false}
            css={styles.drawerControl}
            required
          />
        )}

        {!shouldShowTrialFields && (
          <div>
            <hr />

            <div css={styles.salesforceContainer}>
              <FormHelperText css={styles.helpText}>
                The Salesforce Customer Account Number is a 6-digit value that links the account
                &nbsp;in Leadr to the account in Salesforce and other systems used by Leadr.
              </FormHelperText>

              <DrawerInput
                css={styles.drawerControl}
                name="salesForceCustomerAccountNumber"
                label="Salesforce Customer Account Number"
                initialValue={organization?.salesForceCustomerAccountNumber}
                maxLength={6}
              />

              <FormHelperText css={styles.helpText}>
                The Salesforce ID is a 15 or 18 alphanumeric value that links the account in Leadr to the account in Salesforce.
              </FormHelperText>

              <DrawerInput
                css={styles.drawerControl}
                name="salesforceId"
                label="Salesforce ID"
                initialValue={organization?.salesforceId}
                maxLength={18}
                required
              />
            </div>
          </div>
        )}

        <div css={styles.buttonContainer}>
          <Button
            css={styles.submitButton}
            type="submit"
            color="secondary"
            renderContents={() => <>{organization ? 'Save' : 'Create'}</>}
          />

          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            color="secondary"
            renderContents={() => <>Cancel</>}
          />
        </div>
      </div>
    )}
  />
);

interface OrganizationFormData {
  organizationName: string,
  street: string,
  city: string,
  stateId: string,
  zip: number,
  salesForceCustomerAccountNumber: number,
  salesforceId: number,
  logo: File,
  enableISolvedModule: string,
  enableHRModule: string,
  easeUrl?: boolean,
  organizationInTrial: string,
  trialEndsAt?: string,
  enableReviews: string,
  enableInsights: string,
  enableRecognition?: string,
  enableOneWayCalendarIntegration: boolean,
  enableTheTable: boolean,
  enableUnsupportedCalendarProviders: boolean,
  enableActionItems: boolean,
  enableFeedback: boolean,
  enableGoals: boolean,
  enableLearning: boolean,
  enablePdps: boolean,
}

interface CreateEditOrganizationDrawer extends Omit<ComponentProps<'div'>, 'onSubmit'> {
  id?: string,
}

const CreateEditOrganizationDrawer = ({
  id,
  ...props
}: CreateEditOrganizationDrawer): JSX.Element => {
  const dispatch = useDispatch();
  const { mutate: doCreateOrganization } = useCreateOrganization({ drawerName: createEditOrganizationDrawerTemplate.name });
  const { mutate: doEditOrganization } = useEditOrganization({ drawerName: createEditOrganizationDrawerTemplate.name });
  const isInsightsEnabled = useFeatureFlag('frontendInsights');
  const isRecognitionEnabled = useFeatureFlag('featureRecognition');
  const isIsolvedEnabled = useFeatureFlag('webClientEnableIsolvedSso');
  const isTrialEnabled = useFeatureFlag('enableTrialLimitations');
  const isSimplifiedNavigationEnabled = useFeatureFlag('webAppSimplifiedNavigation');

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const organization = useSelector((state) => getOrganizationById(state, { id })) as NexusOrganization;
  console.log(organization);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const { states } = useGetStates();

  const stateDropdownItems = useMemo(() => states?.map((state) => ({
    text: state.name,
    value: state.stateId,
  })) || [], [states]);

  const [shouldShowEaseUrl, setShouldShowEaseUrl] = useState(organization?.enableHRModule);
  const [shouldShowTrialFields, setShouldShowTrialFields] = useState(organization?.organizationInTrial);

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const {
      enableISolvedModule,
      enableHRModule,
      enableReviews,
      enableInsights,
      enableRecognition,
      organizationInTrial,
      trialEndsAt,
      enableOneWayCalendarIntegration,
      enableTheTable,
      enableUnsupportedCalendarProviders,
      enableActionItems,
      enableFeedback,
      enableGoals,
      enableLearning,
      enablePdps,
      ...orgFields
    } = Object.fromEntries(formData.entries()) as unknown as OrganizationFormData;

    const payload: CreateOrganizationProps = {
      ...orgFields,
      enableISolvedModule: !!enableISolvedModule,
      enableHRModule: !!enableHRModule,
      enableReviews: !!enableReviews,
      enableInsights: !!enableInsights,
      organizationInTrial: !!organizationInTrial,
      enableOneWayCalendarIntegration: !!enableOneWayCalendarIntegration,
      enableTheTable: !!enableTheTable,
      enableUnsupportedCalendarProviders: !!enableUnsupportedCalendarProviders,
      enableActionItems: !!enableActionItems,
      enableFeedback: !!enableFeedback,
      enableGoals: !!enableGoals,
      enableLearning: !!enableLearning,
      enablePdps: !!enablePdps,
    };

    if (trialEndsAt) {
      // Setting all trial end times to midnight central time for now
      payload.trialEndsAt = moment.tz(trialEndsAt, 'MM/DD/YYYY', 'America/Chicago').valueOf();
    }

    if (isRecognitionEnabled) {
      payload.enableRecognition = !!enableRecognition;
    }

    if (id) {
      doEditOrganization({
        id,
        addressId: organization.addressId,
        ...payload,
      });
    } else {
      doCreateOrganization(payload);
    }
  }, [id, organization?.addressId, isRecognitionEnabled, doCreateOrganization, doEditOrganization]);

  const onClose = useCallback(() => {
    // @ts-expect-error remove once redux is fully typed or removed.
    dispatch(popDrawerAction({ popAll: true }));
  }, [dispatch]);

  const onEnableHrModuleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShouldShowEaseUrl(e.target.checked);
  }, []);

  const onOrganizationInTrialChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShouldShowTrialFields(e.target.checked);
  }, []);

  const trialEndDate = useMemo(() => {
    if (organization?.trialEndsAt) {
      return new Date(organization.trialEndsAt);
    }

    return moment.tz(undefined, 'America/Chicago').add(6, 'days').endOf('day').toDate();
  }, [organization?.trialEndsAt]);

  const isEdit = !!id;
  const defaultEnableOneWayCalendarIntegration = isEdit ? organization?.enableOneWayCalendarIntegration : true;

  const hookProps = {
    onClose,
    onSubmit,
    onEnableHrModuleChange,
    onOrganizationInTrialChange,
    organization,
    stateDropdownItems,
    isEdit,
    isInsightsEnabled,
    isRecognitionEnabled,
    isIsolvedEnabled,
    isTrialEnabled,
    shouldShowEaseUrl,
    defaultEnableOneWayCalendarIntegration,
    shouldShowTrialFields,
    trialEndDate,
    enableTheTable: organization?.enableTheTable,
    isSimplifiedNavigationEnabled,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: createEditOrganizationDrawerTemplate.name,
  component: CreateEditOrganizationDrawer,
});

export { View };
export default CreateEditOrganizationDrawer;
