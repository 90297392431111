import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { faComments } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';

import { useCallback } from 'react';
import { questionDisplay } from '~Deprecated/ui/styles/colors';
import { useGetPersonById } from '~Deprecated/hooks/peoplePicker/useNewPeople';

const styles = {
  drawerItem: css`
    width: 100%;
    padding: 1rem;
    background: ${questionDisplay};
    border-radius: 0.5rem;
    display: flex;
    justify-content: flex-start;
    color: white;
    gap: 1rem;
    align-items: center;
    font-size: 16px;
  `,
  itemInfo: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: transparent;
    font-size: 16px;
    color: white;

    & ol, ul, dl {
      margin-bottom: 0;
    }
  `,
  iconStyle: css`
    width: 48px !important;
    height: 48px !important;
  `,
  questionIcon: css`
    justify-content: flex-start;
  `,
};

const View = ({ richText, replaceTokens, ...props }) => (
  <div css={styles.drawerItem} {...props}>
    <div css={[styles.itemInfo, styles.questionIcon]}>
      <FontAwesomeIcon
        css={styles.iconStyle}
        icon={faComments}
      />
    </div>
    {isHTMLText(richText) && (
    <HTMLRenderer css={styles.questionText} htmlText={richText} />
    )}
    {!isHTMLText(richText) && (
    <div css={styles.questionText}>
      {richText}
    </div>
    )}
  </div>
);

View.propTypes = {
  richText: PropTypes.string.isRequired,
  replaceTokens: PropTypes.func.isRequired,
};

// TODO: Delete this after feedback UI rewrite
const QuestionDisplay = ({
  userId,
  ...props
}) => {
  const person = useGetPersonById({ id: userId });

  const replaceTokens = useCallback((text) => {
    if (person) {
      return text
        .replace('[Firstname]', person.firstName)
        .replace('[Lastname]', person.lastName)
        .replace('[FIRST_LAST]', `${person.firstName} ${person.lastName}`);
    }

    return text;
  }, [person]);

  const hookProps = {
    replaceTokens,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

QuestionDisplay.propTypes = {
  userId: PropTypes.string,
};

QuestionDisplay.defaultProps = {
  userId: null,
};

export default QuestionDisplay;
