const SocialLinkStaticURLs = {
  twitter: 'https://twitter.com/',
  instagram: 'https://instagram.com/',
  linkedIn: 'https://linkedin.com/in/',
};

const stripUrl = (text, type) => {
  if (text?.includes(SocialLinkStaticURLs[type])) {
    return text.replace(SocialLinkStaticURLs[type], '');
  }
  return text;
};

export default SocialLinkStaticURLs;
export {
  stripUrl,
};
