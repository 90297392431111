import { css } from '@emotion/react';
import LeadrCard from '~Common/V3/components/LeadrCard';
import {
  Index,
  useInstantSearch,
} from 'react-instantsearch-hooks-web';
import { palette } from '~Common/styles/colors';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { GLOBAL_SEARCH_TABS } from '~GlobalSearch/const/GlobalSearch';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useUserMap } from '~GlobalSearch/hooks/useUserMap';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import Tabs from './Tabs';
import HitsRouter from './HitsRouter';
import AlgoliaCustomPagination from '../Pagination/AlgoliaCustomPagination';
import AlgoliaCustomHitsPerPage from '../Pagination/AlgoliaCustomHitsPerPage';

const styles = {
  searchCard: css({
    padding: '1.5rem',
    marginBottom: '3rem',
  }),
  headerText: css({
    color: palette.brand.indigo,
    fontWeight: 600,
    fontSize: '1.5rem',
  }),
  tabs: css({
    marginTop: '1rem',
  }),
  body: css({
    marginTop: '.75rem',
  }),
  algoliaCustomPagination: css({
    marginTop: '1.125rem',
  }),
};

interface ViewProps {
  searchText: string,
  activeTab: string,
  setActiveTab: (tab: string) => void,
  indexNameToShow: string,
  userMap: Map<string, PersonDisplayInformation>,
}

const View = ({
  searchText,
  activeTab,
  setActiveTab,
  indexNameToShow,
  userMap,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard css={styles.searchCard} {...props}>
    <h2 css={styles.headerText}>
      <>
        {searchText && (
          <>{`Search results for "${searchText}"`}</>
        )}
        {!searchText && (
          <>Search Meetings</>
        )}
      </>
    </h2>
    <Tabs
      css={styles.tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      renderRightItem={() => (
        <AlgoliaCustomHitsPerPage />
      )}
    />
    <div css={styles.body}>
      <Index indexName={indexNameToShow}>
        <HitsRouter userMap={userMap} />
        <AlgoliaCustomPagination css={styles.algoliaCustomPagination} />
      </Index>
      { /* We need all of the indexes to be present in order to get the count in the tabs */ }
      {GLOBAL_SEARCH_TABS.map((tab) => (
        <Index indexName={tab.indexName} key={tab.indexName} />
      ))}
    </div>
  </LeadrCard>
);

const SearchCard = ({ ...props }): JSX.Element => {
  const { search } = useLocation();
  const { indexUiState } = useInstantSearch();
  const activeTabObject = useMemo(() => GLOBAL_SEARCH_TABS.find((tab) => search.includes(tab.value)) ?? GLOBAL_SEARCH_TABS[0], [search]);
  const [activeTab, setActiveTab] = useQueryParamState<string>(
    'search',
    'tab',
    activeTabObject.value ?? GLOBAL_SEARCH_TABS[0].value,
  );

  const indexNameToShow = activeTabObject.indexName;

  const { userMap } = useUserMap();

  useEffect(() => {
    // Ensure that the proper tab is selected, when navigating from the modal or directly to the page
    if (activeTab !== activeTabObject?.value) {
      setActiveTab(activeTabObject?.value ?? GLOBAL_SEARCH_TABS[0].value);
    }
  }, [activeTab, activeTabObject, search, setActiveTab]);

  const hookProps = {
    searchText: indexUiState.query ?? '',
    activeTab,
    setActiveTab,
    indexNameToShow,
    userMap,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default SearchCard;
