import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { createEditActionItemTemplate } from '~ActionItems/components/Drawers/CreateEditActionItemDrawer';
import { LearningStatus, LearningType } from '~Learning/const/interfaces';
import { receivedLearningDrawerTemplate } from '~Learning/components/ReceivedLearningDashboard/ReceivedLearningDrawer';
import { respondSurveyTemplate } from '~Surveys/Components/Drawers/Respond';
import { respondentFeedbackDetailTemplate } from '~Feedback/components/Drawers/RespondentDetail/RespondentDetailDrawer';
import { receivedPlaylistDrawerTemplate } from '~Learning/components/ReceivedLearningDashboard/ReceivedPlaylistDrawer';
import { WhatsDueOnClickHandlerParams } from '~Home/const/interfaces';

export const onActionItemClickHandler = ({ id, dispatch }:WhatsDueOnClickHandlerParams):void => {
  dispatch(pushDrawerAction({
    drawer: {
      ...createEditActionItemTemplate,
      args: {
        id,
      },
    },
  }));
};

export const onSurveyItemClickHandler = ({ id, dispatch }:WhatsDueOnClickHandlerParams):void => {
  dispatch(pushDrawerAction({
    drawer: {
      ...respondSurveyTemplate,
      args: {
        surveyId: id,
      },
    },
  }));
};

export const onFeedbackItemClickHandler = ({ id, dispatch }:WhatsDueOnClickHandlerParams):void => {
  dispatch(pushDrawerAction({
    drawer: {
      ...respondentFeedbackDetailTemplate,
      args: {
        id,
      },
    },
  }));
};

export const onLearningsItemClickHandler = ({
  id, dispatch, learningType, learningStatus,
}: WhatsDueOnClickHandlerParams):void => {
  if (learningType === LearningType.SINGLE_LEARNING) {
    dispatch(pushDrawerAction({
      drawer: {
        ...receivedLearningDrawerTemplate,
        args: {
          learningId: id,
          isReadOnly: learningStatus === LearningStatus.COMPLETED,
        },
      },
    }));
  } else {
    dispatch(pushDrawerAction({
      drawer: {
        ...receivedPlaylistDrawerTemplate,
        args: {
          playlistId: id,
        },
      },
    }));
  }
};
