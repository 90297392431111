import {
  METRIC_NAMES, METRIC_NAME_PROPTYPES,
} from '~Common/const/executiveInsights';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullseyeArrow, faCommentsAlt, faUserGroup, faWavePulse,
} from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../../../components/Tooltip';

const METRIC_TO_ICONS = {
  [METRIC_NAMES.COACHING]: faUserGroup,
  [METRIC_NAMES.GOAL]: faBullseyeArrow,
  [METRIC_NAMES.FEEDBACK]: faCommentsAlt,
  [METRIC_NAMES.ACTIVITY]: faWavePulse,
};

function InsightMetric({ metric, size }) {
  const icon = METRIC_TO_ICONS[metric];

  return (
    <Tooltip message={metric.toUpperCase()}>
      <FontAwesomeIcon
        size={size || 16}
        icon={icon}
      />
    </Tooltip>
  );
}

InsightMetric.propTypes = {
  metric: METRIC_NAME_PROPTYPES,
  size: PropTypes.number,
};

InsightMetric.defaultProps = {
  metric: METRIC_NAMES.COACHING,
  size: 16,
};

export default InsightMetric;
