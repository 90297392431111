import { useQuery } from '@tanstack/react-query';
import { getApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';

export const GROUP_DETAIL_QUERY_KEY = [getOrganizationId(), 'groups'];

export const getUserGroupDetail = ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, __, teamId] = queryKey;
  const URL = `/organizations/${getOrganizationId()}/ops/teams/${teamId}`;
  return getApi(URL);
};

export const useGetGroupDetails = (teamId) => {
  const result = useQuery({
    queryKey: [...GROUP_DETAIL_QUERY_KEY, teamId],
    queryFn: getUserGroupDetail,
  });

  const { data: peopleData } = usePeopleQuery({});

  const group = result?.data?.response;

  let groupWithPeople = {};

  if (peopleData && group) {
    groupWithPeople = { ...group, members: group.members?.map((member) => peopleData[member]) };
  }

  return {
    group: groupWithPeople,
    isLoading: result.isLoading,
  };
};
