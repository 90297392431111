import { css, SerializedStyles } from '@emotion/react';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  checkboxLabel: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  checkboxLabelText: css({
    color: palette.neutrals.gray700,
    fontWeight: 500,
    fontSize: '1rem',
  }),
  checkboxLabelRightItem: css({
    color: palette.neutrals.gray700,
    width: '1.25rem',
    height: '1.25rem',
  }),
};

interface CheckboxLabelProps extends ComponentProps<'div'> {
  renderText: (checkboxLabelTextStyles: SerializedStyles) => JSX.Element,
  renderRightItem?: (checkboxLabelRightItemStyles: SerializedStyles) => JSX.Element,
}

const CheckboxLabel = ({
  renderText,
  renderRightItem,
  ...props
}: CheckboxLabelProps): JSX.Element => (
  <div css={styles.checkboxLabel} {...props}>
    {renderText(styles.checkboxLabelText)}
    {renderRightItem?.(styles.checkboxLabelRightItem)}
  </div>
);

export default CheckboxLabel;
