import { css } from '@emotion/react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { isLocalId } from '~Common/utils/uuid';

const dropContextStyles = (isDraggingOver) => css({
  // ToDo: Can it be safely removed?
  // overflow: 'auto',
  '.leadr-drag-item': {
    overflow: 'hidden',
  },
}, isDraggingOver && ({
  pointerEvents: 'none',
}));

const itemWrapper = (isDraggingOver) => css({
  display: 'flex',
  // ToDo: Can it be safely removed?
  // overflow: 'auto',
  background: 'white',
  transition: 'padding 300ms, box-shadow 300ms, height 300ms',
  '&:hover': {
    // ToDo: Can it be safely removed?
    // overflow: 'auto',
    '> .moveIcon': {
      opacity: 1,
      transform: 'translateX(-1.25rem)',
    },
  },
}, isDraggingOver && ({
  // ToDo: Can it be safely removed?
  // overflow: 'auto',
  '> .moveIcon': {
    opacity: 1,
    transform: 'translateX(-1.25rem)',
  },
}));

const DragAndDrop = ({
  list,
  renderItem,
  onDragEnd,
  onDragStart,
  className,
  valueToUse,
  dragEndProps,
  isDragDisabled,
  isDropDisabled,
  wrapperStyles,
}) => (
  <DragDropContext
    onDragStart={onDragStart}
    onDragEnd={(result) => onDragEnd(result, { ...dragEndProps })}
  >
    <Droppable droppableId="droppable" isDropDisabled={isDropDisabled}>
      {(dropProvided, dropSnapshot) => (
        <div
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
          css={[dropContextStyles(dropSnapshot.isDraggingOver), wrapperStyles]}
        >
          {list.map((id, index) => (
            <Draggable
              key={id[valueToUse] ?? id}
              draggableId={id[valueToUse].toString() ?? id.toString()}
              index={index}
              isDragDisabled={id.isDragDisabled || list.length < 2 || isDragDisabled || isLocalId(id[valueToUse] ?? id)}
            >
              {(dragProvided, dragSnapshot) => (
                <div
                  css={itemWrapper(dragSnapshot.isDraggingOver)}
                  className={className}
                  ref={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                >
                  {renderItem({ id, index }, dragProvided.dragHandleProps)}
                </div>
              )}
            </Draggable>
          ))}
          <div className="leadr-drag-item">
            {dropProvided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

DragAndDrop.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  renderItem: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  className: PropTypes.string,
  valueToUse: PropTypes.string,
  dragEndProps: PropTypes.shape({
    initialArray: PropTypes.arrayOf(
      PropTypes.object,
    ),
    movedItem: PropTypes.func,
    setState: PropTypes.func,
  }).isRequired,
  isDragDisabled: PropTypes.bool,
  isDropDisabled: PropTypes.bool,
  wrapperStyles: PropTypes.shape({}),
};

DragAndDrop.defaultProps = {
  list: [],
  renderItem: () => {},
  onDragStart: () => {},
  onDragEnd: () => {},
  className: '',
  valueToUse: '',
  isDragDisabled: false,
  isDropDisabled: false,
  wrapperStyles: {},
};

export default DragAndDrop;
