import PropTypes from 'prop-types';

import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import BaseToast from './BaseToast';

const WarningToast = ({ text }) => (
  <BaseToast
    icon={faExclamationTriangle}
    text={text}
  />
);

WarningToast.propTypes = {
  text: PropTypes.string.isRequired,
};

WarningToast.defaultProps = {

};

export default WarningToast;
