import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { FrontendPlaylistLearning, LearningTemplateList } from '~Learning/const/interfaces';
import { OPTIMISTIC_ID } from '~Learning/const';
import { learningTemplatesQueryKeys } from '../queryKeys';
import { LearningPlaylistTemplateDetail } from './useGetLearningPlaylistTemplateDetail';

interface LearningPlaylistTemplateUpdateRequest {
  id: number,
  title: string,
  description: string,
  categoryId: string,
  learnings: FrontendPlaylistLearning[],
  idsOfLearningsToDelete: string[],
}

interface LearningPlaylistTemplateUpdateResponse {
  id: number,
}

const updateLearningPlaylistTemplate = async (
  learningTemplatePlaylistUpdateRequest: LearningPlaylistTemplateUpdateRequest,
): Promise<HttpCallReturn<LearningPlaylistTemplateUpdateResponse>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/templates/update`;

  return patchApi<LearningPlaylistTemplateUpdateResponse>(serverUrl, learningTemplatePlaylistUpdateRequest, {});
};

interface useUpdateLearningPlaylistTemplateParams {
  openEditPlaylistTemplateDrawer: () => void,
  onMutateCallback?: () => void,
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void,
  onSettledCallback?: () => void,
  page: number,
  count: number,
  categoryId: string,
}

interface UseUpdateLearningPlaylistResponse {
  mutation: UseMutateFunction<HttpCallReturn<LearningPlaylistTemplateUpdateResponse>, unknown, LearningPlaylistTemplateUpdateRequest>,
}

export const useUpdateLearningPlaylistTemplate = ({
  openEditPlaylistTemplateDrawer,
  onMutateCallback,
  onSuccessCallback,
  onErrorCallback,
  onSettledCallback,
  page,
  count,
  categoryId,
}: useUpdateLearningPlaylistTemplateParams): UseUpdateLearningPlaylistResponse => {
  const mutation = useMutation({
    mutationFn: updateLearningPlaylistTemplate,
    onMutate: async (learningPlaylistTemplateUpdateRequest: LearningPlaylistTemplateUpdateRequest) => {
      onMutateCallback?.();

      // #region Cancel Queries
      await queryClient.cancelQueries({
        queryKey: learningTemplatesQueryKeys.list({
          page,
          count,
          curated: false,
          categoryId,
        }),
      });
      await queryClient.cancelQueries({ queryKey: learningTemplatesQueryKeys.playlistDetail(learningPlaylistTemplateUpdateRequest.id) });
      // #endregion

      // #region Get Previous Data
      const previousLearningTemplatesList = queryClient.getQueryData<HttpCallReturn<LearningTemplateList>>(learningTemplatesQueryKeys.list({
        page,
        count,
        curated: false,
        categoryId,
      }));
      const previousLearningPlaylistTemplateDetail = queryClient.getQueryData<HttpCallReturn<LearningPlaylistTemplateDetail>>(
        learningTemplatesQueryKeys.playlistDetail(learningPlaylistTemplateUpdateRequest.id),
      );
      // #endregion

      // #region Update the learning templates list
      queryClient.setQueryData<HttpCallReturn<LearningTemplateList>>(
        learningTemplatesQueryKeys.list({
          page,
          count,
          curated: false,
          categoryId,
        }),
        (oldLearningTemplatesList) => {
          if (oldLearningTemplatesList) {
            const newLearningTemplatesList = cloneDeep(oldLearningTemplatesList);
            if (newLearningTemplatesList) {
              const templateToUpdateIndex = newLearningTemplatesList.response.learningTemplates.findIndex(
                (template) => template.uid === learningPlaylistTemplateUpdateRequest.id.toString(),
              );

              if (learningPlaylistTemplateUpdateRequest.categoryId === categoryId) {
                const newTemplate = cloneDeep(newLearningTemplatesList.response.learningTemplates[templateToUpdateIndex]);

                if (newTemplate) { // Sometimes the data for the list will not exist, like if you searched for the template and then updated it
                  newTemplate.contentTemplateTitle = learningPlaylistTemplateUpdateRequest.title;
                  newTemplate.numberOfContents = learningPlaylistTemplateUpdateRequest.learnings.length;
                  newTemplate.link = learningPlaylistTemplateUpdateRequest.learnings[0].contentURL;
                  newLearningTemplatesList.response.learningTemplates.splice(templateToUpdateIndex, 1, newTemplate);
                }
              } else {
                /*
                  If the categoryId was changed, then we remove it from the currently shown list
                  Letting the new API data fetch handle showing that in the new category section, as that is a micro optimization that is a lot of work
                */
                newLearningTemplatesList.response.learningTemplates.splice(templateToUpdateIndex, 1);
              }
            }
            return newLearningTemplatesList;
          }
          return oldLearningTemplatesList;
        },
      );
      // #endregion

      // #region Update the learning playlist template detail
      queryClient.setQueryData<HttpCallReturn<LearningPlaylistTemplateDetail>>(
        learningTemplatesQueryKeys.playlistDetail(learningPlaylistTemplateUpdateRequest.id),
        (oldLearningTemplateDetail) => {
          if (oldLearningTemplateDetail) {
            const newLearningTemplateDetail = cloneDeep(oldLearningTemplateDetail);

            if (newLearningTemplateDetail) {
              newLearningTemplateDetail.response.title = learningPlaylistTemplateUpdateRequest.title;
              newLearningTemplateDetail.response.description = learningPlaylistTemplateUpdateRequest.description;
              newLearningTemplateDetail.response.categoryId = learningPlaylistTemplateUpdateRequest.categoryId;
              newLearningTemplateDetail.response.numberOfLearnings = learningPlaylistTemplateUpdateRequest.learnings.length;
              newLearningTemplateDetail.response.learnings = learningPlaylistTemplateUpdateRequest.learnings.map((learning) => ({
                ...learning,
                id: learning.id || OPTIMISTIC_ID,
                questions: learning.questions.map((question) => ({
                  questionText: question.text,
                  questionId: question.questionId || OPTIMISTIC_ID,
                  rank: question.rank,
                })),
              }));
            }

            return newLearningTemplateDetail;
          }

          return oldLearningTemplateDetail;
        },
      );
      // #endregion

      // Return a context object with the old snapshotted values
      return {
        previousLearningTemplatesList,
        previousLearningPlaylistTemplateDetail,
      };
    },
    onError: (
      _,
      learningTemplateUpdateRequest,
      snapshot,
    ) => {
      toast.error('There was an error updating the learning playlist template. Please try again.', {
        autoClose: 1500,
      });

      // Open back up the Edit Learning Playlist drawer
      openEditPlaylistTemplateDrawer();

      // Revert the data to the previous data
      queryClient.setQueryData(learningTemplatesQueryKeys.list({
        page,
        count,
        curated: false,
        categoryId,
      }), snapshot?.previousLearningTemplatesList);
      queryClient.setQueryData(
        learningTemplatesQueryKeys.playlistDetail(learningTemplateUpdateRequest.id),
        snapshot?.previousLearningPlaylistTemplateDetail,
      );

      onErrorCallback?.();
    },
    onSettled: async () => {
      onSettledCallback?.();
      await queryClient.invalidateQueries({ queryKey: learningTemplatesQueryKeys.lists() });
    },
    onSuccess: () => {
      onSuccessCallback?.();
    },
  });

  return {
    mutation: mutation.mutate,
  };
};
