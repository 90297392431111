import { SyntheticEvent, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { css } from '@emotion/react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ViewPerspective, SetDisplay } from '~Insights/const/types';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { usePrintView } from '~Deprecated/hooks/usePrintView';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { CARD_STYLES, EXPANDED_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import { palette } from '~Common/styles/colors';

import Button from '~Common/V3/components/Button';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';
import Card from '~Common/V3/components/Card';
import ShrinkButton from '~Insights/components/buttons/ShrinkButton';
import InsightCardTitle from '~Insights/components/InsightCardTitle';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { HookedENPSBreakdown as ENPSBreakdownSection } from './ENPSBreakdownSection';
import { HookedENPSSection as ENPSSection } from './ENPSSection';
import { SharedProps } from './index';

export const styles = {
  expanded: css({
    ...CARD_STYLES.expanded,
    padding: '0',
  }),
  statement: css({
    color: palette.neutrals.gray800,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
    fontSize: '0.825rem',
    rowGap: '0.5rem',
    textAlign: 'left',

    '.delta': {
      fontSize: '1.125rem',
      fontWeight: 600,
    },

    h3: {
      color: palette.brand.indigo,
      fontSize: '1.125rem',
      fontWeight: 500,
    },
  }),
  overallScore: css({
    fontSize: '2.5rem',
    lineHeight: '2.5rem',
    userSelect: 'none',
  }),
  label: css({
    color: palette.neutrals.gray600,
    fontSize: '1.25rem',
    userSelect: 'none',
  }),
  chartContainer: css({
    margin: '0 auto',
    width: '75%',
  }),
  ...EXPANDED_CARD_COMPONENTS,
  horizontalCushion: css({
    flex: '1',
  }),
  print: (isMobile: boolean) => css({
    color: palette.brand.indigo,
    display: isMobile ? 'none' : 'inline-block',
    flexGrow: '1',
    textAlign: 'right',
  }),
};

enum ExpandedTab {
  enps = 'enps',
  enpsBreakDown = 'enps_breakdown',
}

export interface ExpandedProps extends SharedProps {
  changeExpandedTab: (evt: SyntheticEvent, value: ExpandedTab) => void,
  expandedTab: ExpandedTab,
  onClickPrint: () => void,
  onSetDateRange: (dates: Date[], newPickerValue: string) => void,
  pickerValue: string,
  surveyDates: Date[],
}

const ExpandedView = ({
  changeExpandedTab,
  changeToggleMenu,
  data,
  expandedTab,
  isMobile = false,
  onClickPrint,
  onSetDateRange,
  pickerValue,
  surveyDates,
  toggleMenuOptions,
  toggleMenuValue,
  userPerspective,
}: ExpandedProps): JSX.Element => (
  <Card
    css={styles.expanded}
    renderContents={() => (
      <div css={styles.expandedContainer}>
        <div css={styles.header(isMobile)}>
          <ShrinkButton css={styles.expandButton(isMobile)} cardKey="enps" />
          <div css={styles.titleAndSubtitle(isMobile)}>
            <InsightCardTitle>eNPS</InsightCardTitle>
            <p>These charts show quarterly eNPS scores.</p>
          </div>
          {isMobile && (
            <Dropdown
              items={toggleMenuOptions as DropdownItem[]}
              onChange={(event) => changeToggleMenu(event.target.value as ViewPerspective)}
              value={toggleMenuValue}
            />
          )}
          {!isMobile && toggleMenuValue !== ViewPerspective.DirectReports && (
            <LeadrToggleTabs
              value={toggleMenuValue}
              onChange={(e, newValue) => changeToggleMenu(newValue as ViewPerspective)}
            >
              {toggleMenuOptions.map((toggleMenuOption) => (
                <LeadrToggleTabs.TextTab
                  data-test-id={toggleMenuOption['data-test-id']}
                  key={toggleMenuOption.value}
                  text={toggleMenuOption.text}
                  value={toggleMenuOption.value}
                />
              ))}
            </LeadrToggleTabs>
          )}
          {!isMobile && toggleMenuValue === ViewPerspective.DirectReports && (
            <span
              css={styles.fauxToggleTab}
            >
              Direct Reports
            </span>
          )}
        </div>
        <div css={styles.expandedCushion} />
        <TabContext value={expandedTab}>
          <TabList css={styles.tabs} onChange={changeExpandedTab} aria-label="Detail Section">
            <Tab label="eNPS" value={ExpandedTab.enps} />
            {toggleMenuValue === ViewPerspective.Company && (
              <Tab label="eNPS Breakdown" value={ExpandedTab.enpsBreakDown} />
            )}
            <div css={styles.horizontalCushion} />
            <Button
              variant="text"
              css={styles.print(isMobile)}
              onClick={onClickPrint}
              renderContents={() => (
                <>
                  <FontAwesomeIcon
                    icon={faPrint}
                    color={palette.brand.indigo}
                  />
                  {' '}
                  Print
                </>
              )}
            />
          </TabList>
          <div css={styles.horizontalDivider} />
          <>
            <TabPanel css={styles.expandedRow(isMobile)} value={ExpandedTab.enps}>
              <ENPSSection
                data={data}
                pickerValue={pickerValue}
                onSetDateRange={onSetDateRange}
                surveyDates={surveyDates}
                toggleMenuValue={toggleMenuValue}
                userPerspective={userPerspective}
                isMobile={isMobile}
              />
            </TabPanel>
            <TabPanel css={styles.expandedRow(isMobile)} value={ExpandedTab.enpsBreakDown}>
              <ENPSBreakdownSection
                onSetDateRange={onSetDateRange}
                pickerValue={pickerValue}
                data={data}
                surveyDates={surveyDates}
                isMobile={isMobile}
              />
            </TabPanel>
          </>
        </TabContext>
      </div>
    )}
  />
);

const HookedExpanded = ({
  data,
  toggleMenuValue,
  userPerspective,
  ...props
}: SharedProps): JSX.Element => {
  const [expandedTab, setExpandedTab] = useQueryParamState<ExpandedTab>('insights', 'enpsView', ExpandedTab.enps);
  const changeExpandedTab = (evt: SyntheticEvent, value: ExpandedTab): void => {
    setExpandedTab(value);
  };

  // eNPS Breakdown is only available at the Company view.
  useEffect(() => {
    if (userPerspective === UserPerspective.executive
      && toggleMenuValue !== ViewPerspective.Company
      && expandedTab !== ExpandedTab.enps) {
      setExpandedTab(ExpandedTab.enps);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleMenuValue]);

  const [pickerValue, setPickerValue] = useQueryParamState<string>('insights', 'dateRange', 'PRESET_12');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onSetDateRange = (dates: Date[], newPickerValue: string): void => {
    const [newStartDate, newEndDate] = dates;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPickerValue(newPickerValue);
  };

  const surveyDates = data.map((slice) => new Date(slice.surveyDate)).reverse();
  // @ts-expect-error | Moment typing is screwed up.
  const filteredDataByDate = data.filter((slice) => moment(slice.surveyDate).isBetween(startDate, endDate, 'day', true));

  const { onPrint } = usePrintView();
  const onClickPrint = (): void => {
    // eslint-disable-next-line max-len
    onPrint({ location: `insights?dateRange=${pickerValue}&enpsView=${expandedTab}&perspective=${toggleMenuValue}&setDisplay=mobile&setCard=enps&userPerspective=${userPerspective}` });
  };

  const [setDisplay] = useQueryParamState<SetDisplay>('insights', 'setDisplay', SetDisplay.Desktop);
  const checkMobileQuery = useIsMobileQuery();

  const hookProps = {
    changeExpandedTab,
    data: filteredDataByDate,
    expandedTab,
    isMobile: setDisplay === SetDisplay.Mobile || checkMobileQuery === true,
    onClickPrint,
    onSetDateRange,
    pickerValue,
    surveyDates,
    toggleMenuValue,
    userPerspective,
  };

  return (
    <ExpandedView
      {...hookProps}
      {...props}
    />
  );
};

export { HookedExpanded, ExpandedView };
