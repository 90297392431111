import { SerializedStyles, css } from '@emotion/react';
import Tooltip from '~Common/components/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { faEdit, faTimes } from '@fortawesome/pro-light-svg-icons';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import LeadrButton from '../LeadrButtons/LeadrButton';
import DeleteButtonWithConfirmation from '../DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '../DeleteConfirmation/DeleteConfirmationButtons';

const styles = {
  questionCard: css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    padding: '.5rem 1rem',
    borderRadius: '.3125rem',
    width: '100%',
  }),
  dragHandle: css({
    alignSelf: 'center',
    justifyContent: 'flex-start',
    marginRight: '1.25rem',
    color: palette.neutrals.gray700,
    hover: {
      cursor: 'grab',
    },
  }),
  questionText: css({
    flex: 1,
  }),
  deleteButton: css({
    '&: hover': {
      svg: {
        color: palette.brand.red,
      },
    },
  }),
  buttonsContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  }),
  cancelAddQuestionButton: css({
    marginLeft: '.625rem',
  }),
};

export interface QuestionCardProps {
  handleEditClick: () => void,
  handleDeleteClick: () => void,
  dragHandleProps: DraggableProvidedDragHandleProps,
  renderText: (questionTextStyles: SerializedStyles) => JSX.Element,
}

const QuestionCard = ({
  handleEditClick,
  handleDeleteClick,
  dragHandleProps,
  renderText,
  ...props
}: QuestionCardProps): JSX.Element => (
  <div
    css={styles.questionCard}
    {...props}
  >
    <div
      css={styles.dragHandle}
      {...dragHandleProps}
    >
      <Tooltip
        content="Click and drag to change order of topic"
      >
        <div>
          <FontAwesomeIcon
            icon={faGripDotsVertical}
            size="lg"
          />
        </div>
      </Tooltip>
    </div>
    {renderText(styles.questionText)}
    <div css={styles.buttonsContainer}>
      <LeadrButton
        onClick={handleEditClick}
        variant="text"
        textButtonColor={palette.neutrals.gray700}
        data-test-id="questionEdit"
      >
        <Tooltip content="Edit">
          <div>
            <FontAwesomeIcon
              icon={faEdit}
              size="sm"
            />
          </div>
        </Tooltip>
      </LeadrButton>
      <DeleteButtonWithConfirmation
        renderDeleteButton={({ onClick }) => (
          <LeadrButton
            onClick={onClick}
            css={styles.deleteButton}
            textButtonColor={palette.neutrals.gray700}
            variant="text"
            data-test-id="questionDelete"
          >
            <Tooltip content="Delete">
              <div>
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                />
              </div>
            </Tooltip>
          </LeadrButton>
        )}
        renderConfirmationButtons={({
          informationStyles,
          optionStyles,
          popoverButtonStyles,
        }) => (
          <DeleteConfirmationButtons
            onDelete={handleDeleteClick}
            informationStyles={informationStyles}
            optionStyles={optionStyles}
            popoverButtonStyles={popoverButtonStyles}
          />
        )}
      />
    </div>
  </div>
);

export default QuestionCard;
