import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { meetingTypePickerTemplate, useGetMeetingTypeSelectionOptions } from '~Common/components/MeetingTypePicker';
import { palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { MyScheduleMeeting } from '~Home/const/interfaces';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { MeetingDefaults, CONVERT_MEETING_TYPES_TO_MEETING_FACTORY_TYPES, MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { TEAM_MEETING_DEFAULTS } from '~Meetings/const/meetingTypes';
import { useCreateHuddle } from '~Meetings/hooks/CreateMeeting/useCreateHuddle';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { useIgnoreMeeting } from '~Meetings/hooks/CalendarIntegration/useIgnoreMeeting';
import { useIsSyncingACalendarMeeting } from '~Home/stores/useIsSyncingACalendarMeeting';
import { linkTeamDrawerTemplate } from '~Meetings/components/drawers/LinkTeamDrawer';
import { useCallback } from 'react';

const styles = {
  addButton: css({
    color: palette.brand.blue,
  }),
  ignoreButton: css({
    color: palette.neutrals.gray700,
  }),
  button: css({
    fontSize: '.625rem',
    '&:last-of-type': {
      paddingRight: 0,
    },
  }),
  syncingText: css({
    color: palette.neutrals.gray700,
    fontSize: '.625rem',
  }),
};

interface ViewProps {
  onAddAgenda: () => void,
  onIgnoreMeeting: () => void,
  isIgnoredMeeting: boolean,
  isSyncingACalendarMeeting: boolean,
}

const View = ({
  onAddAgenda,
  onIgnoreMeeting,
  isIgnoredMeeting,
  isSyncingACalendarMeeting,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {isSyncingACalendarMeeting && (
      <div css={styles.syncingText}>Syncing a meeting...</div>
    )}
    {!isSyncingACalendarMeeting && (
      <>
        <Button
          css={[styles.button, styles.addButton]}
          variant="text"
          onClick={onAddAgenda}
          renderContents={() => (
            <>Add Agenda</>
          )}
          data-test-id="calendarMeetingAddAgendaButton"
        />
        {!isIgnoredMeeting && (
          <Button
            css={[styles.button, styles.ignoreButton]}
            variant="text"
            onClick={onIgnoreMeeting}
            renderContents={() => (
              <>Ignore</>
            )}
            data-test-id="calendarMeetingIgnoreButton"
          />
        )}
      </>
    )}
  </div>
);

interface ExternalCalendarMeetingButtonsProps {
  meeting: MyScheduleMeeting,
  hasTeams?: boolean;
}

const ExternalCalendarMeetingButtons = ({
  meeting,
  hasTeams,
  ...props
}: ExternalCalendarMeetingButtonsProps): JSX.Element => {
  const dispatch = useDispatch();
  const { createHuddleMutation } = useCreateHuddle();
  const ignoreMeetingMutation = useIgnoreMeeting();
  const { getMeetingTypeSelectionOptions } = useGetMeetingTypeSelectionOptions();
  const { timezone } = useTimezone();

  const {
    isSyncingACalendarMeeting,
  } = useIsSyncingACalendarMeeting((state) => ({
    isSyncingACalendarMeeting: state.isSyncingACalendarMeeting,
  }));

  const onCreateMeeting = useCallback(({
    type,
  }: MeetingDefaults): void => {
    const attendeesExcludingOrganizerOrgUserIds = meeting.attendeesExcludingOrganizer.map((attendee) => attendee.orgUserId);
    const allAttendeeOrgUserIds = [...attendeesExcludingOrganizerOrgUserIds, meeting.organizer.orgUserId];

    const huddlePayload = {
      startTimeInMillis: meeting.startTimeInMillis,
      endTimeInMillis: meeting.endTimeInMillis,
      title: meeting.title,
      frequency: meeting.frequency,
      meetingType: CONVERT_MEETING_TYPES_TO_MEETING_FACTORY_TYPES[type],
      attendeeIds: allAttendeeOrgUserIds,
      ...(meeting.location && { location: meeting.location }),
      ...(timezone && { timezone }),
      ...(meeting.daysOfWeek?.length && { daysOfWeek: meeting.daysOfWeek }),
      nylasEventId: meeting.nylasEventId,
      nonLeadrAttendees: meeting.nonLeadrAttendees,
    };

    createHuddleMutation(huddlePayload);
  }, [
    meeting,
    timezone,
    createHuddleMutation,
  ]);

  const onMeetingTypeSelect = useCallback(({
    type,
    ...defaults
  }: MeetingDefaults) => {
    if (hasTeams && type === MeetingTypeEnum.MEETING) {
      dispatch(pushDrawerAction({
        drawer: {
          ...linkTeamDrawerTemplate,
          args: {
            meeting,
          },
        },
      }));
    } else {
      onCreateMeeting({
        type,
        ...defaults,
      });
    }
  }, [
    dispatch,
    meeting,
    hasTeams,
    onCreateMeeting,
  ]);

  const onAddAgenda = (): void => {
    const meetingTypeSelectionOptions = getMeetingTypeSelectionOptions({
      includeOneTimeOneOnOneMeeting: meeting.attendeesExcludingOrganizer.length === 1 && !meeting.isRecurringSeries,
      includeOneOnOneMeeting: meeting.attendeesExcludingOrganizer.length === 1 && meeting.isRecurringSeries,
    });

    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));

    if (meetingTypeSelectionOptions.length === 1) {
      // The only option is team meeting, so we will create it without showing the meeting type picker
      if (hasTeams) {
        dispatch(pushDrawerAction({
          drawer: {
            ...linkTeamDrawerTemplate,
            args: {
              meeting,
            },
          },
        }));
      } else {
        onCreateMeeting(TEAM_MEETING_DEFAULTS);
      }
    } else {
      dispatch(pushDrawerAction({
        drawer: {
          ...meetingTypePickerTemplate,
          args: {
            meetingTypeSelectionOptions,
            onOptionClickCallback: onMeetingTypeSelect,
          },
        },
      }));
    }
  };

  const onIgnoreMeeting = (): void => {
    ignoreMeetingMutation({ nylasEventId: meeting.nylasEventId });
  };

  const hookProps = {
    onAddAgenda,
    onIgnoreMeeting,
    isIgnoredMeeting: meeting.isIgnored,
    isSyncingACalendarMeeting,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default ExternalCalendarMeetingButtons;
