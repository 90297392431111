import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import { useConvertToMyScheduleMeetings } from '~Meetings/hooks/CalendarIntegration/useConvertToMyScheduleMeetings';
import { MyScheduleDay, MyScheduleMeeting } from '~Home/const/interfaces';
import { UnsyncedLeadrMeeting } from '~Meetings/hooks/CalendarIntegration/useGetUnsyncedLeadrMeetings';
import { UnsyncedCalendarMeeting } from '~Meetings/hooks/CalendarIntegration/useGetIgnoredUnsyncedCalendarMeetings';
import MyScheduleDayText from './MyScheduleDayText';

const styles = {
  daySection: css({
    '&:not(:first-of-type)': {
      paddingTop: '1.125rem',
      borderTop: `1px solid ${palette.neutrals.gray400}`,
    },
    '&:not(:last-of-type)': {
      paddingBottom: '1.125rem',
    },
  }),
  myScheduleDayText: css({
    marginBottom: '.4375rem',
  }),
  meetingCard: css({
    marginBottom: '0.5rem',
  }),
};

interface ViewProps {
  myScheduleDays: MyScheduleDay[],
  renderMeetingCard: (meeting: MyScheduleMeeting) => JSX.Element,
}

const View = ({
  myScheduleDays,
  renderMeetingCard,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {myScheduleDays.map((myScheduleDay) => (
      <div css={styles.daySection}>
        <MyScheduleDayText css={styles.myScheduleDayText} dayString={myScheduleDay.day} />
        {myScheduleDay.meetings.map((meeting) => (
          <>
            {renderMeetingCard(meeting)}
          </>
        ))}
      </div>
    ))}
  </div>
);

interface MyScheduleDaysAbstractionProps extends Omit<ViewProps, 'myScheduleDays'> {
  unsyncedMeetings: MyScheduleDay[] | UnsyncedLeadrMeeting[] | UnsyncedCalendarMeeting[],
}

const isMyScheduleDay = (
  unsyncedCalendarMeeting: MyScheduleDay[] | UnsyncedLeadrMeeting[] | UnsyncedCalendarMeeting[],
): unsyncedCalendarMeeting is MyScheduleDay[] => !!(unsyncedCalendarMeeting as MyScheduleDay[])[0].day;

const isCalendarMeeting = (
  unsyncedCalendarMeeting: UnsyncedCalendarMeeting[] | UnsyncedLeadrMeeting[] | UnsyncedCalendarMeeting[],
): unsyncedCalendarMeeting is UnsyncedCalendarMeeting[] => !!(unsyncedCalendarMeeting as UnsyncedCalendarMeeting[])[0].eventId;

const isLeadrMeeting = (
  unsyncedCalendarMeeting: MyScheduleDay[] | UnsyncedLeadrMeeting[] | UnsyncedCalendarMeeting[],
): unsyncedCalendarMeeting is UnsyncedLeadrMeeting[] => !!(unsyncedCalendarMeeting as UnsyncedLeadrMeeting[])[0].meetingFactoryId;

const ReviewUnsyncedMeetingsDrawerTabBody = ({
  unsyncedMeetings,
  ...props
}: MyScheduleDaysAbstractionProps): JSX.Element => {
  const {
    convertUnsyncedCalendarMeetingToMyScheduleMeeting,
    convertUnsyncedLeadrMeetingToMyScheduleMeeting,
    groupMyScheudleMeetingsByDate,
  } = useConvertToMyScheduleMeetings();

  let myScheduleDays: MyScheduleDay[] = [];

  if (isMyScheduleDay(unsyncedMeetings)) {
    myScheduleDays = unsyncedMeetings;
  } else if (isCalendarMeeting(unsyncedMeetings)) {
    const myScheduleMeetings = unsyncedMeetings.map((unsyncedCalendarMeeting) => (
      convertUnsyncedCalendarMeetingToMyScheduleMeeting(unsyncedCalendarMeeting, false)
    ));
    myScheduleDays = groupMyScheudleMeetingsByDate(myScheduleMeetings);
  } else if (isLeadrMeeting(unsyncedMeetings)) {
    const myScheduleMeetings = unsyncedMeetings.map((unsyncedLeadrMeeting) => (
      convertUnsyncedLeadrMeetingToMyScheduleMeeting(unsyncedLeadrMeeting)
    ));
    myScheduleDays = groupMyScheudleMeetingsByDate(myScheduleMeetings);
  }

  const hookProps = {
    myScheduleDays,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ReviewUnsyncedMeetingsDrawerTabBody;
