import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { MyScheduleDay } from '~Home/const/interfaces';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { useMemo } from 'react';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { buildQueryString } from '~Common/utils';
import { calendarIntQueryKeys } from './queryKeys';

const getUnsyncedCalendarMeetings = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<MyScheduleDay[]>> => {
  const [timezone] = queryKey.slice(-1);
  const queryString = buildQueryString({ timezone });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/calendarReconciliation${queryString}`;

  return getApi<MyScheduleDay[]>(serverUrl);
};

interface UseGetUnsyncedCalendarMeetingsReturn {
  unsyncedCalendarMeetings?: MyScheduleDay[] | undefined,
  unsyncedCalendarMeetingsCount: number,
  isLoading: boolean,
}

export const useGetUnsyncedCalendarMeetings = (): UseGetUnsyncedCalendarMeetingsReturn => {
  const { timezone } = useTimezone();

  const { data: authStatus } = useGetCalendarAuthStatus();
  const result = useQuery({
    queryKey: calendarIntQueryKeys.unsyncedCalendarMeetings(timezone),
    queryFn: getUnsyncedCalendarMeetings,
    enabled: authStatus === AUTH_STATUS.LINKED,
  });

  const unsyncedCalendarMeetingsCount = useMemo(() => {
    const response = result.data?.response;
    const meetingCount = response?.reduce((total, day) => total + day.meetings.length, 0) ?? 0;
    return meetingCount;
  }, [result.data?.response]);

  return {
    unsyncedCalendarMeetings: result.data?.response,
    unsyncedCalendarMeetingsCount,
    isLoading: result.isLoading,
  };
};
