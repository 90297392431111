import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorHandllerModel } from './ErrorHandler.model';
import Alert from '../Alert';
import { useAlert } from '../../hooks';
import { clearGlobalAPiErrorData } from '../../redux/actions/common';
/**
 * Used to handle errors from API
 */
const ErrorHandler = (props) => {
  /**
     * Used to get error details from store.
     */
  const errorDetails = useSelector((response) => response.error);

  /**
     * Used to update error message.
     */
  const { alertMessage, showAlertMessage } = useAlert();

  const [errorList, updateErrorList] = useState();

  const [errorType, updateErrorType] = useState();

  const dispatch = useDispatch();

  /**
     * Used to extract error details.
     * @param {*} errorDetails Used to store error details.
     */
  const extractErrorDetails = (error) => {
    if (!error.message) {
      return;
    }
    updateErrorType(error.type);
    const errorObject = new ErrorHandllerModel(error);
    updateErrorList(errorObject);
    showAlertMessage(errorObject.message);
    setTimeout(() => {
      dispatch(clearGlobalAPiErrorData({ error: '' }));
    }, 2000);
  };

  /**
     * Used to watch on error updates.
     */
  useEffect(() => {
    if (errorDetails) {
      extractErrorDetails(errorDetails);
    }
  }, [errorDetails]);

  const renderAlertMessage = () => (
    <Alert
      style={{ zIndex: 99999999 }}
      type={errorType === 'error' ? 'danger' : 'success'}
      message={alertMessage}
    />
  );

  return (
    <React.Fragment>
      { renderAlertMessage() }
    </React.Fragment>
  );
};

export default ErrorHandler;
