import { css } from '@emotion/react';
import { useContext, useMemo } from 'react';
import { palette } from '~Common/styles/colors';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import emptyConversationBubbles from '~Meetings/assets/images/emptyConversationBubbles.png';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useCreateIndividualTopic } from '~Meetings/hooks/templates/individualTopics/useCreateIndividualTopic';
import { MeetingInfoContext } from '~Meetings/components/topic-suggestions/contexts/MeetingInfoContext';
import { AgendaTemplates } from '@leadr-hr/types';
import { useEditIndividualTopic } from '~Meetings/hooks/templates/individualTopics/useEditIndividualTopic';
import { useDeleteIndividualTopic } from '~Meetings/hooks/templates/individualTopics/useDeleteIndividualTopic';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import { getLocalId } from '~Common/utils/uuid';
import { getCanEditTemplate } from '~Meetings/components/topic-suggestions/utils/getCanEditTemplateOrItem';
import { getCreator } from '~Meetings/components/topic-suggestions/utils/getCreator';
import {
  EditIndividualAgendaTemplateDetails,
  GetAgendaTopicTemplateDetails,
  GetCuratedAgendaTemplateDetails,
  GetMeetingTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { useTemplateItemLocalIds } from '~Meetings/hooks/utils/useTemplateItemLocalIds';
import IndividualTopicCard from './IndividualTopicCard';
import MeetingTemplate from '../MeetingTemplate';
import AgendaTopicTextEditor from '../../CreateTopicTemplateDrawer/AgendaTopic/AgendaTopicTextEditor';

const styles = {
  individualTopicsBody: css({
    backgroundColor: palette.neutrals.gray500,
  }),
  noTopicTemplatesImage: css({
    height: '6.5rem',
  }),
};

interface ViewProps {
  individualTopicsTemplate: GetMeetingTemplateDetails,
  handleNewIndividualTopic: () => void,
  handleCreateIndividualTopic: (text: string) => void,
  handleDeleteIndividualTopic: (topicId: string) => void,
  handleEditIndividualTopic: (newChild: EditIndividualAgendaTemplateDetails) => void,
  canCreateNewTopic: boolean,
  showNewAgendaTopicEditor: boolean,
  newTopicLocalId: string,
  showEmptyState: boolean,
  creator: string,
  isPrivate: boolean,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
}

const View = ({
  individualTopicsTemplate,
  handleNewIndividualTopic,
  handleCreateIndividualTopic,
  handleDeleteIndividualTopic,
  handleEditIndividualTopic,
  canCreateNewTopic,
  showNewAgendaTopicEditor,
  newTopicLocalId,
  showEmptyState,
  creator,
  isPrivate,
  addTopicToMeeting,
  ...props
}: ViewProps): JSX.Element => (
  <MeetingTemplate
    {...props}
    addTopicToMeeting={addTopicToMeeting}
    creator={creator}
    template={individualTopicsTemplate}
    isPrivate={isPrivate}
    renderBody={(topics) => (
      <>
        {showNewAgendaTopicEditor && (
          <AgendaTopicTextEditor
            topicLocalId={newTopicLocalId}
            onSave={(topicText: string) => handleCreateIndividualTopic(topicText)}
          />
        )}
        {showEmptyState && (
          <EmptyStateWithImage
            renderImage={() => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              (<img css={styles.noTopicTemplatesImage} src={emptyConversationBubbles} alt="No topics" />)
            )}
            renderText={() => (
              <div>
                {canCreateNewTopic && (
                  <>
                    <LeadrButton
                      variant="text"
                      textButtonColor={palette.brand.indigo}
                      onClick={handleNewIndividualTopic}
                      data-test-id="meetingTopicSuggestionsEmptyStateNewIndividualTopic"
                    >
                      Add the first
                    </LeadrButton>
                    Topic Suggestion.
                  </>
                )}
                {!canCreateNewTopic && (
                  <>There are currently no individual topics.</>
                )}
              </div>
            )}
          />
        )}
        {topics.map((topic) => (
          <IndividualTopicCard
            key={topic.id}
            topic={topic}
            addTopicToMeeting={addTopicToMeeting}
            handleDeleteIndividualTopic={handleDeleteIndividualTopic}
            handleEditIndividualTopic={handleEditIndividualTopic}
          />
        ))}
      </>
    )}
    renderRightButton={canCreateNewTopic ? () => (
      <LeadrButton
        size="mini"
        data-test-id="meetingTopicSuggestionsNewIndividualTopic"
        onClick={handleNewIndividualTopic}
      >
        + New Topic
      </LeadrButton>
    ) : undefined}
  />
);

interface IndividualTopicsSectionProps {
  isPrivate: boolean;
  individualTopicsTemplate: GetMeetingTemplateDetails,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
}

const IndividualTopicsSection = ({
  individualTopicsTemplate,
  isPrivate,
  ...props
}: IndividualTopicsSectionProps): JSX.Element => {
  const { isLimitedAccess, isAdmin } = useUserPermissions();
  const { meetingTemplateType } = useContext(MeetingInfoContext);
  const { mutate: createIndividualTopic } = useCreateIndividualTopic({
    meetingTemplateType,
  });
  const { mutate: editIndividualTopic } = useEditIndividualTopic({
    meetingTemplateType,
  });
  const { mutate: deleteIndividualTopic } = useDeleteIndividualTopic({
    meetingTemplateType,
  });

  const newTopicLocalId = useMemo(() => getLocalId(), []);
  const {
    getIsItemEditorVisible,
    showItemEditor,
  } = useShowItemEditor((state) => ({
    getIsItemEditorVisible: state.getIsItemEditorVisible,
    showItemEditor: state.showItemEditor,
  }));

  const showNewAgendaTopicEditor = getIsItemEditorVisible(newTopicLocalId);

  const handleNewIndividualTopic = (): void => {
    showItemEditor(newTopicLocalId);
  };

  const handleCreateIndividualTopic = (text: string): void => {
    createIndividualTopic({
      type: meetingTemplateType,
      child: {
        // Currently we only have functionality to create individual agenda topics
        type: AgendaTemplates.AgendaTemplateType.AgendaTopic,
        isPrivate,
        text,
      },
    });
  };

  const {
    removeLocalIdsAndPermissions,
  } = useTemplateItemLocalIds();

  const handleEditIndividualTopic = (newChild: EditIndividualAgendaTemplateDetails): void => {
    const [child] = removeLocalIdsAndPermissions([newChild]);

    editIndividualTopic({
      type: meetingTemplateType,
      child: child as EditIndividualAgendaTemplateDetails,
    });
  };

  const handleDeleteIndividualTopic = (topicId: string): void => {
    deleteIndividualTopic({
      id: topicId,
    });
  };

  const showEmptyState = individualTopicsTemplate.children.length === 0 && !showNewAgendaTopicEditor;
  const canCreateNewTopic = !isLimitedAccess && ((!isPrivate && isAdmin) || isPrivate);

  const creator = getCreator(individualTopicsTemplate.creator);

  const canEdit = getCanEditTemplate(individualTopicsTemplate);

  const hookProps = {
    handleNewIndividualTopic,
    handleCreateIndividualTopic,
    handleDeleteIndividualTopic,
    individualTopicsTemplate,
    handleEditIndividualTopic,
    canCreateNewTopic,
    showNewAgendaTopicEditor,
    newTopicLocalId,
    showEmptyState,
    creator,
    canEdit,
    isPrivate,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default IndividualTopicsSection;
