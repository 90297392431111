import { UseTransformItemsReturn, WhatsDueItem } from '~Home/const/interfaces';
import { faSquarePollVertical } from '@fortawesome/pro-regular-svg-icons';
import { Survey } from '~Surveys/const/Survey';
import { WhatsDueFilterOptions } from '~Home/const';
import moment from 'moment-timezone';
import { onSurveyItemClickHandler } from '~Home/const/WhatsDueItemClickHandlers';
import { useHomeSurveys } from './useHomeSurveys';

const parseSurveys = (surveys: Survey[]):Survey[] => {
  const filteredSurveys = surveys.filter((survey) => survey.participant.completedOn === null);
  return filteredSurveys;
};

export const useTransformSurveys = ():UseTransformItemsReturn => {
  const { isLoading, data: surveys } = useHomeSurveys();
  const receivedSurveys = surveys?.response || [];
  const parsedSurveys = parseSurveys(receivedSurveys);
  const transformedSurveys = parsedSurveys.map<WhatsDueItem>((survey) => ({
    type: WhatsDueFilterOptions.Surveys,
    text: survey.title,
    dueDate: survey.completeBy ? moment(survey.completeBy) : undefined,
    id: survey.id.toString(),
    iconToRender: faSquarePollVertical,
    onItemClick: onSurveyItemClickHandler,
  }));
  return {
    isLoading,
    data: transformedSurveys,
  };
};
