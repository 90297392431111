import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

export const DROPDOWN_STYLES = {
  dropdown: css({
    marginLeft: '.75rem',
    width: '9rem',
    padding: '0',
    maxWidth: '9.0625rem',

    '.MuiSelect-select': {
      minWidth: '90px !important',
      maxWidth: '90px !important',
      textOverflow: 'ellipsis',
      fontSize: '0.75rem',
      paddingLeft: '0.75rem',
    },

    // ToDo: Fix the placement of the dropdown arrow inside the common Dropdown component
    '.MuiSelect-icon': {
      right: '0.75rem',
    },
  }),
  dropdownItemBoy: css({
    color: palette.neutrals.gray700,
  }),
  dropdownSmall: css({
    width: '6rem',
    marginLeft: '.375rem',
  }),
  perPageWrap: css({
    marginLeft: '.75rem',
    display: 'inline-flex',
    alignItems: 'center',
    color: palette.neutrals.gray800,
  }),
};
