import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import PersonalityItem from '~Deprecated/ui/containers/Nexus/PersonalityTypes/PersonalityItem';
import styles from './styles';

const PersonalityTypesList = ({
  enneagramList,
  myersBriggesList,
  discList,
  workingGeniusList,
  activeTabIndex,
}) => {
  const [list, updateList] = useState([]);

  useEffect(() => {
    switch (activeTabIndex) {
      case 0:
        updateList(enneagramList);
        break;
      case 1:
        updateList(myersBriggesList);
        break;
      case 2:
        updateList(discList);
        break;
      case 3:
        updateList(workingGeniusList);
        break;
      default:
        updateList([]);
        break;
    }
  }, [activeTabIndex, enneagramList, myersBriggesList, discList, workingGeniusList]);

  if (!list.length) {
    return null;
  }

  return (
    <div className="content-card mt-5" css={styles.personalitesListWrapper}>
      <div css={styles.tableWrapper}>
        <div css={styles.tableHeader}>
          <div css={[styles.headerTitle, styles.title]}>Label</div>
          <div css={[styles.headerTitle, styles.title]}>Action</div>
          <div css={[styles.headerTitle, styles.title]}>Preview</div>
        </div>
        <div className="table-body-wrapper">
          {list.map((id) => (
            <PersonalityItem
              id={id}
              key={id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

PersonalityTypesList.propTypes = {
  enneagramList: PropTypes.arrayOf(PropTypes.string).isRequired,
  myersBriggesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  discList: PropTypes.arrayOf(PropTypes.string).isRequired,
  workingGeniusList: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTabIndex: PropTypes.number.isRequired,
};

PersonalityTypesList.defaultProps = {
};

export default PersonalityTypesList;
