// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DraggableProvided, DraggingStyle } from 'react-beautiful-dnd';
import {
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';

/*
  WARNING: Tread carefully in this file. I Had to disable a lot of TS stuff.
  The drag and drop library we are using has a visual bug that can be found here: https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-1010053365
  I couldnt figure out how to type this hook that I took from the Github issue
*/

export const useDraggableInPortal = (): void => { // The void type is not accurate, see comment above
  const element = useRef<HTMLDivElement>(document.createElement('div')).current;

  useEffect(() => {
    if (element) {
      element.style.pointerEvents = 'none';
      element.style.position = 'absolute';
      element.style.height = '100%';
      element.style.width = '100%';
      element.style.top = '0';

      document.body.appendChild(element);

      return () => {
        document.body.removeChild(element);
      };
    }

    return () => {
      // Empty cleanup
    };
  }, [element]);

  return (render: (provided: DraggableProvided) => ReactElement) => (provided: DraggableProvided) => {
    const result = render(provided);
    const style = provided.draggableProps.style as DraggingStyle;
    if (style.position === 'fixed') {
      return createPortal(<>{result}</>, element);
    }
    return result;
  };
};
