import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import BaseAvatar from '~Common/components/Users/Avatars/BaseAvatar';

const styles = (height) => css`
  border-radius: 0 0 ${height * 0.8}px;

  & .initials {
    font-size: ${height / 2}px;
  }
`;

/**
 * @deprecated Old UI
 */
const CornerAvatar = ({
  height, ...props
}) => (
  <BaseAvatar
    height={height}
    css={styles(height)}
    {...props}
  />
);

CornerAvatar.propTypes = {
  height: PropTypes.number,
};

CornerAvatar.defaultProps = {
  height: 48,
};

export default CornerAvatar;
