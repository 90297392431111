import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '../Const/pageStyles';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
};

export const View = (): JSX.Element => (
  <div css={styles.topBar}>
    <div css={styles.leftSide(true)}>
      <div id="contextBackButton" />
    </div>
    <div id="contextButtons" />
  </div>
);

export const ReviewDetailsTopBar = (): JSX.Element => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
    />
  );
};
