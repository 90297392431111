import { SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';
import { DropdownItem } from '~Common/V3/components/Dropdown';
import { useGetPeopleFilters } from '~Common/hooks/useGetPeopleFilters';
import FilterDropdownView from './DropdownView';

interface ViewProps {
  departmentOptions: DropdownItem[],
  value: string,
  handleFilterChange: (event: SelectChangeEvent) => void,
}

const View = ({
  departmentOptions,
  value,
  handleFilterChange,
  ...props
}: ViewProps): JSX.Element => (
  <FilterDropdownView
    options={departmentOptions}
    value={value}
    handleFilterChange={handleFilterChange}
    labelForNoValue="Department"
    data-test-id="peopleDepartmentDropdown"
    {...props}
  />
);

type DepartmentDropdownProps = Omit<ViewProps, 'departmentOptions' | 'isInvalidDropdownValue'>;

const DepartmentDropdown = ({
  value,
  ...props
}: DepartmentDropdownProps): JSX.Element => {
  const { departments } = useGetPeopleFilters();

  const departmentOptions = useMemo(() => departments.map((department) => ({
    text: department.value,
    value: department.value,
    'data-test-id': `${department.value}Option`,
  })), [departments]);

  const hookProps = {
    departmentOptions,
    value,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default DepartmentDropdown;
