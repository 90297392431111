import { useForm, UseFormReturn } from 'react-hook-form';
import Card from '~Common/V3/components/Card';
import { css } from '@emotion/react';
import { ReviewTemplateQuestion } from './useReviewTemplates';
import Questions from '../Shared/Questions';
import { FormValues } from '../schemata/CreateTopicForCycle';

const styles = {
  removePadding: css({
    padding: 0,
  }),
};

interface ViewProps {
  reviewCycleUid?: string,
  topic: ReviewTemplateQuestion,
  formContext: UseFormReturn<FormValues>,
}

export const View = ({
  reviewCycleUid,
  topic,
  formContext,
}: ViewProps): JSX.Element => (
  <Card
    css={styles.removePadding}
    renderContents={() => (
      <Questions
        topic={topic}
        onModifyQuestion={() => null}
        formContext={formContext}
        isQuestions
        isReadOnly
        isTemplateView
        reviewCycleUid={reviewCycleUid}
      />
    )}
  />
);

interface ReviewTemplateQuestionPreviewProps {
  reviewCycleUid?: string,
  topic: ReviewTemplateQuestion
}

export const ReviewTemplateQuestionPreview = ({
  topic,
  reviewCycleUid,
}: ReviewTemplateQuestionPreviewProps): JSX.Element => {
  const formContext = useForm<FormValues>();

  const hookProps = {
    reviewCycleUid,
    topic,
    formContext,
  };

  return topic.text ? (
    <View
      {...hookProps}
    />
  ) : <></>;
};
