import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { Portal } from 'react-portal';
import UserInfo from '../UserInfo';
import { MEETINGS_BASE_ROUTE } from '~Common/const/routes';

/**
 * @deprecated Old UI, needs some stuff to be rewritten and other stuff to be deleted before we can remove this
 */
const MenuPanel = (props) => {
  const userList = props.users;
  const history = useHistory();
  const childRef = useRef(null);

  useEffect(() => {
    if (props.parentRef && childRef) {
      if (props.parentRef.current && childRef.current) {
        const currentHeight = props.parentRef.current.getBoundingClientRect();
        const { top, left } = currentHeight;
        let height = 0;

        if (userList.length >= 7) {
          if ((window.innerHeight / 1.7) - 298 > top) {
            height = Math.trunc(top);
          } else {
            height = (window.innerHeight / 1.7) - Math.trunc(top);
          }
          if (height < 0) {
            height *= -1;
          }
        } else if (userList.length > 3 && userList.length < 7) {
          height = Math.trunc(top) - 70;
        } else {
          height = Math.trunc(top);
        }
        const element = childRef.current;
        element.style.width = '16rem';
        element.style.position = 'absolute';
        element.style.top = `${height}px`;
        element.style['max-height'] = '30rem';
        element.style.left = `${Math.trunc(left) - 30}px`;
        element.style['overflow-y'] = 'auto';
        element.style.background = 'white';
        element.style['border-radius'] = '4px';
        element.style['z-index'] = '9999';
        element.style['box-shadow'] = '0px 20px 32px rgba(0, 0, 0, 0.24)';
      }
    }
  }, [props.showMenuPanel]);

  const getHeader = () => {
    if (userList && userList.length > 0 && !props.isFloat && history.location.pathname.startsWith(MEETINGS_BASE_ROUTE)) {
      return <h5 className="px-4">Attendees</h5>;
    }
    return null;
  };

  const getMenuPanel = () => {
    let users = userList;
    if (props.isFloat) {
      users = userList.slice(2);
    }

    return (
      <div className="menu-panel-wrapper" ref={childRef}>
        {/* <div className={props.showMenuPanel ? `menu-panel menu-visible ${(props.isFloat) ? 'float-menu-panel':'' }` : 'menu-panel menu-invisible '} >    */}
        <div className="menu-panel">
          {getHeader()}
          {users ? users.map((user, index) => (
            <div className="menu-content" key={`${user.name}_${index}`}>
              <div className="menu-item" data-toggle="tooltip">
                <div className="d-flex">
                  <div className="user-image">
                    <UserInfo users={[user]} showTooltip={false} />
                  </div>
                  <div className="user-name">{`${user.firstName} ${user.lastName}`}</div>
                </div>
              </div>
            </div>
          )) : null}
        </div>
      </div>
    );
  };

  if (!props.isFloat) {
    return getMenuPanel();
  }
  return props.showMenuPanel ? (
    <Portal node={document && document.getElementById('modal-root')}>
      {getMenuPanel()}
      <div onClick={() => props.setShowMenuPanel(false)} className={props.showMenuPanel ? 'portal-overlay' : 'display-none'} />
    </Portal>
  ) : null;
};
export default MenuPanel;
