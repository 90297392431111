import { useGenericStore } from '~Common/hooks/useGenericStore';

interface UseShowCommentEditorResponse {
  showAddCommentEditor: boolean,
  setShowCommentEditor: (showAgendaEditor: boolean) => void,
}

export const useShowCommentEditor = (agendaItemId: string, noteId?: string): UseShowCommentEditorResponse => {
  const { setValue: setShowCommentEditor, value: showAddCommentEditor } = useGenericStore({
    module: 'leadr',
    workflow: 'meetings',
    feature: `note-editor-${agendaItemId}-${noteId ?? 'create'}`,
    defaultValue: false,
  }) as { value: boolean, setValue: (value: boolean) => void };

  return {
    setShowCommentEditor,
    showAddCommentEditor,
  };
};
