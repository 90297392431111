import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { ReviewTemplate } from '../useReviewTemplates';

const styles = {
  name: css({
    color: palette.neutrals.gray900,
    fontSize: '1.25rem',
    fontWeight: 600,
  }),
  byline: css({
    color: palette.neutrals.gray500,
    fontSize: '0.8125rem',
    fontWeight: 600,
    marginTop: '0.75rem',
  }),
  description: css({
    color: palette.neutrals.gray600,
    fontSize: '1rem',
    fontWeight: 400,
    marginTop: '1.25rem',
  }),
};

interface ReviewTemplateSummaryCardProps {
  createdBy: string,
  template: ReviewTemplate,
}

export const ReviewTemplateSummaryCard = ({
  createdBy,
  template,
}: ReviewTemplateSummaryCardProps): JSX.Element => (
  <Card
    renderContents={() => (
      <>
        <h2 css={styles.name}>
          {template.name}
        </h2>
        <p css={styles.byline}>
          {`Created by ${createdBy}`}
        </p>
        <HTMLRenderer css={styles.description} htmlText={template.description} />
      </>
    )}
  />
);
