import { decode } from 'jsonwebtoken';

import { getRefreshToken, getToken } from '~Deprecated/utils/cookies';
import { createReducer } from '../redux/reducers/index';
import { clearStorage } from '../redux/reducers/utils';
import { SIGNOUT } from '../redux/actions';

export const determineInitialState = () => {
  const token = getToken();
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    clearStorage();
    return {
      jsonWebToken: null,
      jsonRefreshToken: null,
    };
  }

  const { exp: refreshTokenExp } = decode(refreshToken) || { exp: 0 };
  const tokenExpirationInMilliseconds = refreshTokenExp * 1000;

  if (Date.now() > tokenExpirationInMilliseconds) {
    clearStorage();
    return {
      jsonWebToken: null,
      jsonRefreshToken: null,
    };
  }

  return {
    jsonWebToken: token,
    jsonRefreshToken: refreshToken,
  };
};

const initialState = determineInitialState();

const { reducer, registerAction } = createReducer('storage', initialState);

registerAction(SIGNOUT, () => ({}));

export { reducer, registerAction, initialState };
