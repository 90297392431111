import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import sagaMiddleware from '../configureSagaMiddleware';
import { createRootReducer } from '../../redux/reducers';

const store = createStore(
  createRootReducer(),
  composeWithDevTools(
    applyMiddleware(
      sagaMiddleware,
    ),
  ),
);

window.store = store;

export { store, sagaMiddleware };
