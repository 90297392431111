import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import DrawerInfo from '~Common/components/Drawers/Info';
import DashedBoxWithText from '~Deprecated/ui/components/EmptyStates/DashedBoxWithText';
import ClickableDrawerPerson from '~Common/components/Fields/ClickableDrawerPerson';
import { noop } from '~Deprecated/utils/';

const styles = {
  sectionHeader: css`
    margin: 20px 0;
  `,
  collaborator: css`
    margin: 1rem 0;
  `,
};

const RequestRespondents = ({
  isReadOnly,
  respondents,
}) => {
  const renderContent = () => (
    <h4>
      Click Add Respondent to request feedback from one or more people.
    </h4>
  );

  return (
    <>
      <DrawerInfo
        css={styles.sectionHeader}
        info="These are the people who you are asking to respond."
      />
      {respondents.length > 0 && (
        <>
          {respondents.map((collab) => (
            <ClickableDrawerPerson
              key={collab.userId}
              onClick={noop}
              person={collab}
              disabled
              css={styles.collaborator}
            />
          ))}
        </>
      )}
      {respondents.length === 0 && !isReadOnly && (
        <DashedBoxWithText
          text="Click ‘Add Respondents’ to add someone!"
          renderBody={renderContent}
        />
      )}
    </>
  );
};

RequestRespondents.propTypes = {
  isReadOnly: PropTypes.bool,
  respondents: PropTypes.arrayOf(ATTENDEE_SHAPE),
};

RequestRespondents.defaultProps = {
  isReadOnly: false,
  respondents: [],
};

export default RequestRespondents;
