import { createAction } from '@reduxjs/toolkit';

import { registerAction } from '~Deprecated/reducers/featureFlags';
import { mutateState } from '~Deprecated/utils/reduxUtils';

export const setFlagsAction = createAction('LEADR.SET_FLAGS');

const setFlags = (state, action) => mutateState(state, (draftState) => {
  draftState.data = action.payload;
  draftState.version = action.payload.webClientCurrentBuildVersion;

  return draftState;
});

registerAction(setFlagsAction.toString(), setFlags);
