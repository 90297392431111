import { css } from '@emotion/react';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { Goals } from '@leadr-hr/types';
import { useHistory } from 'react-router-dom';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import LeadrModal from '~Common/V3/components/LeadrModal';
import { palette } from '~Common/styles/colors';
import { useGoalStatusModalStore } from '~Goals/stores/useGoalsStatusModalStore';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { LeftPanel } from './LeftPanel';
import { RightPanel } from './RightPanel';
import { GoalHistoryDrawerDetails } from '../Shared/GoalHistoryDrawerDetails';

const styles = {
  container: css({
    border: `1px solid ${palette.neutrals.gray200}`,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    minHeight: 0,
    overflow: 'clip',
  }),
  leftPanel: css({
    flexBasis: '25%',
    overflowY: 'auto',
  }),
  rightPanel: css({
    borderLeft: `1px solid ${palette.neutrals.gray200}`,
    flex: 1,
  }),
  modal: css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50vh',
    minWidth: '50vw',
  }),
  modalBody: css({
    flex: 1,
  }),
  title: css({
    color: palette.brand.indigo,
  }),
};

interface ViewProps {
  displayedStatus: Goals.GoalStatusUpdate,
  onClose: () => void,
  showStatusHistoryModal: boolean,
  statusId: string,
  statusUpdates: Goals.GoalStatusUpdate[],
  transitionToUpdateModal: () => void,
  isMobile: boolean,
  canEditGoal: boolean,
}

const View = ({
  displayedStatus,
  onClose,
  showStatusHistoryModal,
  statusId,
  statusUpdates,
  transitionToUpdateModal,
  isMobile,
  canEditGoal,
}: ViewProps): JSX.Element => (
  <>
    <LeadrModal
      css={styles.modal}
      open={showStatusHistoryModal}
      onClose={onClose}
    >
      <LeadrModal.Header>
        <LeadrModal.Title css={styles.title}>
          Goal History
        </LeadrModal.Title>
      </LeadrModal.Header>
      <LeadrModal.Body css={styles.modalBody}>
        {isMobile && (
          <GoalHistoryDrawerDetails
            statusUpdates={statusUpdates}
          />
        )}
        {!isMobile && (
          <div css={styles.container}>
            <div css={styles.leftPanel}>
              <LeftPanel
                statusId={statusId}
                statusUpdates={statusUpdates}
              />
            </div>
            <div css={styles.rightPanel}>
              <RightPanel status={displayedStatus} />
            </div>
          </div>
        )}
      </LeadrModal.Body>
      <LeadrModal.Footer>
        {canEditGoal && (
          <LeadrButton
            data-test-id="goalHistoryModalAddUpdate"
            onClick={transitionToUpdateModal}
            size="small"
          >
            <LeadrButton.IconAndText
              icon={faPlus}
              text="Add Update"
            />
          </LeadrButton>
        )}
      </LeadrModal.Footer>
    </LeadrModal>
  </>
);

export const GoalStatusHistoryModal = (): JSX.Element => {
  const history = useHistory();
  const isMobile = useIsMobileQuery();

  const {
    closeStatusHistoryModal,
    goal,
    displayedStatusId,
    showStatusHistoryModal,
    transitionToUpdateModal,
  } = useGoalStatusModalStore((state) => ({
    closeStatusHistoryModal: state.closeStatusHistoryModal,
    goal: state.displayedGoal,
    displayedStatusId: state.displayedStatusId,
    showStatusHistoryModal: state.showStatusHistoryModal,
    transitionToUpdateModal: state.transitionToUpdateModal,
  }));

  const statusUpdates = goal?.statusUpdates ?? [];

  const onClose = (): void => {
    closeStatusHistoryModal();
    const backUrl = GoalsRoutes.ViewById.replace(':goalId', goal?.goalId ?? '');
    history.push(backUrl);
  };

  const statusId = displayedStatusId ?? statusUpdates?.[0]?.statusId;
  const displayedStatus = statusUpdates.find((status) => status?.statusId === statusId);

  if (!goal || !displayedStatus) {
    return <></>;
  }

  const canEditGoal = goal.permissions.includes(Goals.GoalPermission.CanEditGoal);

  const hookProps = {
    displayedStatus,
    onClose,
    showStatusHistoryModal,
    statusId,
    statusUpdates,
    transitionToUpdateModal,
    isMobile,
    canEditGoal,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
