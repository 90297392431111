import { SyntheticEvent, useEffect, useState } from 'react';
import {
  FEEDBACK_RECEIVED,
  FEEDBACK_SENT,
} from '~Common/const/routes';

import { navigateAction } from '~Deprecated/actions/navigate';
import LeadrToggleTabs, { LeadrToggleTextTabProps } from '~Common/V3/components/LeadrToggleTabs';
import { SerializedStyles } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';

interface ViewProps {
  value: string,
  onChange: (event: SyntheticEvent, route: string) => void,
  tabItems: LeadrToggleTextTabProps[],
  toggleTabStyles: SerializedStyles,
}

const View = ({
  tabItems, toggleTabStyles, ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs {...props}>
    {tabItems.map((tabItem) => (
      <LeadrToggleTabs.TextTab
        css={toggleTabStyles}
        data-test-id={tabItem['data-test-id']}
        key={tabItem.value}
        text={tabItem.text}
        value={tabItem.value}
      />
    ))}
  </LeadrToggleTabs>
);

interface FeedbackTabNavigationProps extends Omit<ViewProps, 'value' | 'tabItems' | 'onChange'> {
  initialRoute: string,
}

const FeedbackTabNavigation = ({ initialRoute, ...props }: FeedbackTabNavigationProps): JSX.Element => {
  const { canRequestFeedback } = useOrgUserPermissionsContext();
  const dispatch = useDispatch();

  const tabItems = [
    {
      text: 'Received',
      value: FEEDBACK_RECEIVED,
      'data-test-id': 'feedbackReceived',
    },
    ...(canRequestFeedback ? [{
      text: 'Sent',
      value: FEEDBACK_SENT,
      'data-test-id': 'feedbackSent',
    }] : []),
  ];

  const [value, setValue] = useState(initialRoute);

  useEffect(() => {
    setValue(initialRoute);
  }, [initialRoute]);

  const onChange = (event: SyntheticEvent, route: string): void => {
    if (route) {
      dispatch(navigateAction({ pathname: route }));
    }
  };

  const hookProps = {
    value,
    tabItems,
    onChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default FeedbackTabNavigation;
