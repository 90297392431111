import { useEffect, useState } from 'react';
/**
 * @deprecated Old Checkbox UI, use packages/leadr-frontend/src/common/V3/components/Form/CustomCheckbox.tsx instead
 */
const Checkbox = (props) => {
  const [checked, setChecked] = useState(false);

  const onChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const onClick = (e) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  useEffect(() => {
    setChecked(!!props.checked);
  }, [props.checked]);

  return (
    <div
      className={`d-flex custom-checkbox checkbox-wrapper float-left h-100 ${props.className}`}
      onClick={(e) => onClick(e)}
    >
      <input
        type="checkbox"
        checked={!!checked || !!props.defaultChecked}
        onChange={(e) => onChange(e)}
        className="custom-control-input"
        name={props.name}
        id={props.id}
        disabled={!!props.disabled}
      />
      <label htmlFor={props.id} className="custom-control-checkbox" />
      <label className="custom-control-label font-medium text-break">
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
