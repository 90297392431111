import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as EmptyCircle } from '@fortawesome/pro-regular-svg-icons';
import { CircularProgress } from '@mui/material';
import {
  ComponentProps,
  useCallback,
  useState,
} from 'react';

import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import CareCardAvatar from '~Common/V3/components/CareCardAvatar';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import HoverControls from '~Common/components/HoverControls/HoverControls';
import ActionItemFooter from '~Meetings/components/details/action/ActionItemFooter';
import { useIsMutating } from '@tanstack/react-query';
import { palette } from '~Common/styles/colors';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useDebounceDraftState } from '~Common/hooks/useDebounceDraftState';
import { ActionItem, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useEditActionItem } from '~ActionItems/hooks/useEditActionItem';
import { useNewActionItemPermissions } from '~Meetings/hooks/useNewActionItemPermissions';

const styles = {
  actionItem: (showControls: boolean) => css({
    borderRadius: '.5rem',
    padding: '1rem',
    marginBottom: '0.5rem',
    backgroundColor: palette.neutrals.gray50,
    position: 'relative',
    display: 'flex',

    '.actionItemControls': {
      display: showControls ? 'block' : 'none',
    },

    '&:hover': {
      '.actionItemControls': {
        display: 'block',
      },
    },
  }),

  actionItemBody: css({
    width: '100%',

    // Needed so the action item text properly wraps when extremely long
    minWidth: 0,
  }),

  progressIndicator: css({
    minWidth: '20px',
    minHeight: '20px',
  }),

  checkBox: css({
    background: palette.neutrals.white,
    marginRight: '1rem',
    borderRadius: '50%',
    border: '0 transparent',
    cursor: 'pointer',
    alignSelf: 'start',
    justifySelf: 'center',
    width: '1.3125rem',
  }),

  textContainer: css({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  }),

  text: css({
    overflow: 'hidden',
    overflowWrap: 'break-word',
    fontSize: '1rem',
    color: palette.neutrals.gray800,
    marginRight: '0.5rem',
  }),

  openIcon: css({
    color: palette.neutrals.gray400,
  }),

  completedIcon: css({
    color: palette.brand.indigo,
  }),

  avatar: css({
    alignSelf: 'flex-start',
  }),
  actionItemFooter: css({
    marginTop: '.5rem',
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  actionItem: ActionItem
  text: string,
  isChecked: boolean,
  onActionItemComplete: () => void,
  canComplete: boolean,
  renderAvatar: () => JSX.Element,
  showLoader: boolean,
  renderControls: () => JSX.Element,
  showControls: boolean,
}

const View = ({
  actionItem,
  text,
  isChecked,
  onActionItemComplete,
  canComplete,
  renderAvatar,
  showLoader,
  renderControls,
  showControls,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <div css={styles.actionItem(showControls)}>
      <div className="actionItemControls">{renderControls()}</div>

      { showLoader && (
        <CircularProgress
          css={styles.progressIndicator}
          size={20}
        />
      )}

      { !showLoader && (
        <div
          tabIndex={0}
          aria-checked={!!isChecked}
          role="checkbox"
          css={styles.checkBox}
          onKeyDown={onActionItemComplete}
          onClick={onActionItemComplete}
        >
          {canComplete && (
            <>
              {!isChecked && (
                <FontAwesomeIcon
                  icon={EmptyCircle}
                  css={styles.openIcon}
                  size="xl"
                />
              )}

              {isChecked && (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  css={styles.completedIcon}
                  size="xl"
                />
              )}
            </>
          )}
        </div>
      )}

      <div css={styles.actionItemBody}>
        <div css={styles.textContainer}>
          <div css={styles.text}>
            {text}
          </div>

          {renderAvatar()}
        </div>

        <ActionItemFooter
          css={styles.actionItemFooter}
          actionItem={actionItem}
        />
      </div>
    </div>
  </div>

);

interface ActionItemDetailsProps extends ComponentProps<'div'> {
  actionItem: ActionItem,
  meetingId: string,
  meetingType: MeetingTypeEnum,
}

const NewActionItemDetails = ({
  actionItem,
  meetingId,
  meetingType,
  ...props
}: ActionItemDetailsProps): JSX.Element => {
  const {
    text,
    assigneeId,
    status,
    id,
  } = actionItem;

  const [showControls, setShowControls] = useState(false);
  const { mutate: doEditActionItem } = useEditActionItem({});

  const isChecked = status === NewActionItemStatus.Completed;

  const queryKey = [getOrganizationId(), 'tasks', id];

  const isCompletingOrUpdating = useIsMutating({
    mutationKey: queryKey,
  });
  const actionItemPermissions = useNewActionItemPermissions({
    meetingId,
    meetingType,
    actionItem,
  });

  const { canDelete, canEdit, canComplete } = actionItemPermissions ?? {};

  const renderControls = (): JSX.Element => (
    <HoverControls
      actionItemId={actionItem?.id}
      meetingId={meetingId}
      meetingType={meetingType}
      setShowControls={setShowControls}
      canDelete={canDelete}
      canEdit={canEdit}
      isComplete={isChecked}
    />
  );

  const saveCompleteTask = useCallback((isComplete: boolean) => {
    doEditActionItem({
      id,
      actionItem: {
        status: isComplete ? NewActionItemStatus.Completed : NewActionItemStatus.ToDo,
      },
    });
  }, [doEditActionItem, id]);

  // ToDo: Update code to use returned draft once we have a good method of handling
  // optimistic updates in tandem with useDebounceDraftState
  const [, setIsChecked] = useDebounceDraftState(isChecked, saveCompleteTask);

  const onActionItemComplete = (): void => {
    setIsChecked(status !== NewActionItemStatus.Completed);
  };

  const isLoading = isCompletingOrUpdating > 0;

  const renderAvatar = (): JSX.Element => (
    <CareCardAvatar
      id={assigneeId}
      noBackdrop
      containerStyle={styles.avatar}
      secondaryTooltip="Assignee"
      renderAvatar={(imageUrl, fullName, isDeactivated) => (
        <SquareAvatar
          imgUrl={imageUrl}
          name={fullName}
          isDeactivated={isDeactivated}
          width={48}
          height={48}
          includeInitials
        />
      )}
      renderSkeletonLoader={() => (
        <SkeletonLoader
          width={48}
          height={48}
          variant="rectangular"
          renderComponent={() => <div />}
        />
      )}
    />
  );

  const hookProps = {
    actionItem,
    text,
    isChecked,
    showLoader: isLoading,
    onActionItemComplete,
    canComplete: !isChecked && isLoading ? false : canComplete,
    id,
    meetingId,
    meetingType,
    renderAvatar,
    renderControls,
    setShowControls,
    showControls,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default NewActionItemDetails;
