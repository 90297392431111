import { css } from '@emotion/react';
import { ComponentProps, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faBullseyeArrow,
  faChartLine,
  faPartyHorn,
  faPlus,
  faQuestion,
  faSquareCheck,
  faUsers,
  faFaceParty,
  faBriefcase,
  faBuilding,
  faCompass,
} from '@fortawesome/pro-light-svg-icons';

import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { HomeModule } from '~Home/const';
import { useHomeSettings } from '~Home/hooks/useHomeSettings';
import { useUpdateHomeSettings } from '~Home/hooks/useUpdateHomeSettings';
import { palette } from '~Common/styles/colors';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { filterHomeModules } from '~Home/functions/utils';

const styles = {
  moduleControl: (isVisible: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 1.5rem',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    border: `1px solid ${palette.neutrals.gray50}`,
    fontWeight: 600,
    color: palette.neutrals.gray700,
    marginBottom: '0.625rem',
  }, isVisible && {
    borderColor: palette.brand.blue,

    '& .moduleVisibilityButton': {
      // The button adds height to the component, so use visibility rather than
      // display to keep it from changing size on hover
      visibility: 'hidden',
    },

    '&:hover': {
      borderColor: palette.brand.red,
      color: palette.brand.red,

      '& .moduleIcon': {
        color: palette.brand.red,
      },

      '& .moduleVisibilityButton': {
        visibility: 'visible',
      },
    },
  }),
  moduleLabelContainer: css({
    display: 'grid',
    gridTemplateColumns: '1.5rem 1fr',
    gap: '1rem',
  }),
  icon: css({
    color: palette.brand.indigo,
    width: '1.5rem',
    height: '1.5rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  isVisible: boolean,
  onClick: () => void,
  displayString: string,
  icon: IconDefinition,
}

const View = ({
  isVisible,
  onClick,
  displayString,
  icon,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.moduleControl(isVisible)}
    {...props}
  >
    <div css={styles.moduleLabelContainer}>
      <FontAwesomeIcon
        css={styles.icon}
        className="moduleIcon"
        icon={icon}
      />

      <span>
        {displayString}
      </span>
    </div>

    <LeadrButton
      className="moduleVisibilityButton"
      onClick={onClick}
      color={isVisible ? 'danger' : 'primary'}
      variant="ghost"
      size="small"
      data-test-id="addRemoveHomeModule"
    >
      {isVisible && (
        'Remove'
      )}

      {!isVisible && (
        <LeadrButton.IconAndText
          icon={faPlus}
          text="Add"
        />
      )}
    </LeadrButton>
  </div>
);

interface ModuleControlProps {
  type: HomeModule,
}

const ModuleControl = ({
  type,
}: ModuleControlProps): JSX.Element => {
  const { data } = useHomeSettings();
  const { mutate: doUpdateHomeSettings } = useUpdateHomeSettings();
  const userId = getUserId();
  const orgId = getOrganizationId();

  const displayString = useMemo(() => {
    switch (type) {
      case HomeModule.Celebrate:
        return 'Celebrate';
      case HomeModule.Goals:
        return 'Goals';
      case HomeModule.Insights:
        return 'Insights';
      case HomeModule.Meetings:
        return 'Meetings';
      case HomeModule.OrgClarity:
        return 'Organizational Clarity';
      case HomeModule.People:
        return 'People';
      case HomeModule.Recognition:
        return 'Recognition';
      case HomeModule.TeamClarity:
        return 'Team Clarity';
      case HomeModule.WhatsDue:
        return 'What\'s Due';
      default:
        return 'Unknown Module';
    }
  }, [type]);

  const icon = useMemo(() => {
    switch (type) {
      case HomeModule.Celebrate:
        return faFaceParty;
      case HomeModule.Goals:
        return faBullseyeArrow;
      case HomeModule.Insights:
        return faChartLine;
      case HomeModule.Meetings:
        return faBriefcase;
      case HomeModule.OrgClarity:
        return faBuilding;
      case HomeModule.People:
        return faUsers;
      case HomeModule.Recognition:
        return faPartyHorn;
      case HomeModule.TeamClarity:
        return faCompass;
      case HomeModule.WhatsDue:
        return faSquareCheck;
      default:
        return faQuestion;
    }
  }, [type]);

  const isVisible = useMemo(() => (
    data?.response?.[type].isVisible || false
  ), [data?.response, type]);

  const onClick = useCallback(() => {
    if (data?.response) {
      const updatedSettings = filterHomeModules(data.response);
      const newColumn = updatedSettings[type].column ?? 2;

      updatedSettings[type].isVisible = !isVisible;

      if (!isVisible) {
        const highestRank = Object.values(updatedSettings).filter((module) => (
          module.isVisible && module.column === newColumn
        )).reduce((acc, module) => (
          module.rank > acc ? module.rank : acc
        ), 0);

        updatedSettings[type].column = newColumn;
        updatedSettings[type].rank = highestRank + 1;
      }

      doUpdateHomeSettings({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        orgId: orgId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userId: userId!,
        settings: updatedSettings,
      });
    }
  }, [
    orgId,
    userId,
    data?.response,
    type,
    isVisible,
    doUpdateHomeSettings,
  ]);

  const hookProps = {
    isVisible,
    onClick,
    icon,
    displayString,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default ModuleControl;
