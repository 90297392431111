import { css } from '@emotion/react';
import { useState, ChangeEvent } from 'react';
import { OrgSettings } from '~Common/const/Organization';

import { palette } from '~Common/styles/colors';
import DrawerInput from '~Common/V3/components/DrawerInput';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';

const styles = {
  customFavoritesFields: css({}),
  descriptionText: css({
    color: palette.neutrals.gray700,
    marginBottom: '0.5rem',
  }),
  customFavoriteFieldsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  customFavoriteField: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
  }),
};

interface ViewProps {
  enableCustomFavorite1: boolean | undefined,
  customFavorite1Name: string | undefined,
  enableCustomFavorite2: boolean | undefined,
  customFavorite2Name: string | undefined,
  handleEnableCustomFavorite1Change: (event: ChangeEvent<HTMLInputElement>) => void,
  handleEnableCustomFavorite2Change: (event: ChangeEvent<HTMLInputElement>) => void,
}

const View = ({
  enableCustomFavorite1,
  handleEnableCustomFavorite1Change,
  customFavorite1Name,
  enableCustomFavorite2,
  handleEnableCustomFavorite2Change,
  customFavorite2Name,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.customFavoritesFields}
    {...props}
  >
    <SettingsSectionHeader
      renderContents={() => (
        <>Custom Favorites Fields</>
      )}
    />
    <p css={styles.descriptionText}>
      Leadr profiles have 3 favorite fields built in (Food, Drink, and Snack).
      You may add up to 2 additional custom fields for members of your Organization below (Max length of 50 characters).
    </p>
    <div css={styles.customFavoriteFieldsContainer}>
      <div css={styles.customFavoriteField}>
        <CustomCheckbox
          name="enableCustomFavorite1"
          checked={enableCustomFavorite1}
          onChange={handleEnableCustomFavorite1Change}
        />
        <DrawerInput
          maxLength={50}
          name="customFavorite1Name"
          label="Custom Field 1"
          required={enableCustomFavorite1}
          initialValue={customFavorite1Name}
        />
      </div>
      <div css={styles.customFavoriteField}>
        <CustomCheckbox
          name="enableCustomFavorite2"
          checked={enableCustomFavorite2}
          onChange={handleEnableCustomFavorite2Change}
        />
        <DrawerInput
          maxLength={50}
          name="customFavorite2Name"
          label="Custom Field 2"
          required={enableCustomFavorite2}
          initialValue={customFavorite2Name}
        />
      </div>
    </div>
  </div>
);

interface CustomFavoritesFieldsProps {
  orgSettings: OrgSettings,
}

const CustomFavoritesFields = ({
  orgSettings,
  ...props
}: CustomFavoritesFieldsProps): JSX.Element => {
  const {
    enableCustomFavorite1: initialEnableCustomFavorite1,
    customFavorite1Name,
    enableCustomFavorite2: initialEnableCustomFavorite2,
    customFavorite2Name,
  } = orgSettings;

  const [enableCustomFavorite1, setEnableCustomFavorite1] = useState(initialEnableCustomFavorite1);
  const [enableCustomFavorite2, setEnableCustomFavorite2] = useState(initialEnableCustomFavorite2);

  const handleEnableCustomFavorite1Change = (event: ChangeEvent<HTMLInputElement>): void => {
    setEnableCustomFavorite1(event.target.checked);
  };

  const handleEnableCustomFavorite2Change = (event: ChangeEvent<HTMLInputElement>): void => {
    setEnableCustomFavorite2(event.target.checked);
  };

  const hookProps = {
    enableCustomFavorite1,
    handleEnableCustomFavorite1Change,
    customFavorite1Name,
    enableCustomFavorite2,
    handleEnableCustomFavorite2Change,
    customFavorite2Name,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CustomFavoritesFields;
