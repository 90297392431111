import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import Froala from '~Common/V3/components/Froala';
import { SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';

const styles = {
  editor: css`

  & .fr-element.fr-view p{
    font-size: .875rem !important;
  }
  `,
};

const View = ({
  id, stringifiedAnswer, value, onDescriptionChange, isReadOnly = false,
}) => (
  <>
    <Froala
      enableEmojis
      styleOverrides={styles.editor}
      label=""
      name="description"
      richTextEditorProps={{
        name: 'description',
        initialValue: value,
        onChange: onDescriptionChange,
      }}
      isReadOnly={isReadOnly}
    />
    <input type="hidden" name={id} value={stringifiedAnswer} />
  </>

);

View.propTypes = {
  id: PropTypes.number.isRequired,
  stringifiedAnswer: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

View.defaultProps = {
  isReadOnly: false,
};

const SurveyFreeform = ({ question, ...props }) => {
  const [value, setValue] = useState();
  const [stringifiedAnswer, setStringifiedAnswer] = useState();

  useEffect(() => {
    setStringifiedAnswer(JSON.stringify({
      id: question.id,
      response: value,
    }));
  }, [value, question]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDescriptionChange = ({ value }) => {
    setValue(value);
  };

  const hookProps = {
    stringifiedAnswer,
    value,
    setValue,
    onDescriptionChange,
    ...question,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SurveyFreeform.propTypes = {
  question: SURVEY_QUESTION_SHAPE,
};

SurveyFreeform.defaultProps = {
  question: {},
};

export { View };
export default SurveyFreeform;
