import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { Skeleton } from '@mui/material';
import { skeletonGlobalAnimation } from '~Common/const/skeletons';
import { palette } from '~Common/styles/colors';

const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    color: ${palette.neutrals.gray800};
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  `,
};

const SectionHeader = ({
  text,
  renderText = null,
  renderButton = () => { },
  className = '',
  ...props
}) => (
  <div css={styles.container} className={className} {...props}>
    <h4
      css={styles.header}
    >
      {renderText && (
        <>
          {renderText()}
        </>
      )}
      {!renderText && (
        <>
          { text }
        </>
      )}
    </h4>
    { renderButton() }
  </div>
);

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
  renderButton: PropTypes.func,
  className: PropTypes.string,
  renderText: PropTypes.func,
};

SectionHeader.defaultProps = {
  className: '',
  renderButton: () => {
  },
  renderText: null,
};

const SectionHeaderSkeleton = ({ ...props }) => (
  <div css={styles.container} {...props}>
    <h4
      css={styles.header}
    >
      <Skeleton variant="text" animation={skeletonGlobalAnimation} width={100} height={20} />
    </h4>
  </div>
);

export { SectionHeaderSkeleton };

export default SectionHeader;
