import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const styles = {
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
  `,
};

/**
 * @deprecated Old Card UI, use packages/leadr-frontend/src/common/V3/components/Card.tsx instead
 */
function CardRow({ children, className }) {
  return (
    <div
      css={styles.wrapper}
      className={`cardRow ${className}`}
    >
      {children}
    </div>
  );
}

CardRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
};

CardRow.defaultProps = {
  className: '',
  children: (<div> Row </div>),
};

export default CardRow;
