import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { ReflectionTypeEnum } from '../types';

interface AddReflectionMutationProps {
  organizationId: string,
  reflectionTypeEnum: ReflectionTypeEnum,
  reviewUid: string,
  reviewTopicUid: string,
  subjectUid: string
  contentTitle: string,
}

const addReflectionMutation = ({
  organizationId,
  reflectionTypeEnum,
  reviewUid,
  reviewTopicUid,
  subjectUid,
  contentTitle,
}: AddReflectionMutationProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${organizationId}/reviews/${reviewUid}/reflections`,
  };

  return postApi<void>(serverUrl, {
    reflectionTypeEnum,
    reviewTopicUid,
    subjectUid,
    contentTitle,
  });
};

type SuppliedAddReflectionMutationProps = Omit<AddReflectionMutationProps, 'organizationId' | 'reviewUid'>

export function useAddReflection(reviewUid: string): UseMutationResult<HttpCallReturn<void>, unknown, SuppliedAddReflectionMutationProps, unknown> {
  const organizationId = getOrganizationId() ?? '';

  const mutation = useMutation({
    mutationFn: (props: SuppliedAddReflectionMutationProps) => addReflectionMutation({ ...props, reviewUid, organizationId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [organizationId, 'reviews', reviewUid, '2.5'] });
    },
    onError: () => {
      toast.error('Failed to add reflection. Please try again.');
    },
  });

  return mutation;
}
