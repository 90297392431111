import { SerializedStyles, css } from '@emotion/react';
import {
  ComponentProps,
  RefObject,
  useMemo,
  useRef,
} from 'react';

import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useGetPeople } from '~Common/hooks/people/useGetPeople';
import { palette } from '~Common/styles/colors';
import { withoutDesktopObject } from '~Common/styles/mixins';
import RealtimeReorderingIndicator from '../RealtimeReorderingIndicator';

const AgendaTopicWrapperBoxStyles = css({
  padding: '0.75rem 0.75rem 0.25rem 0.75rem',
  borderRadius: '.3125rem',
});

const styles = {
  AgendaTopicWrapper: (isHighlighted: boolean) => css({
    width: '100%',
    background: palette.neutrals.gray50,
    border: '1px solid transparent',
    transition: 'border-color 250ms ease',
  }, withoutDesktopObject({
    padding: '.625rem',
  }), AgendaTopicWrapperBoxStyles, isHighlighted && {
    borderColor: palette.brand.indigo,
  }),
  itemContainer: css({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr min-content',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      "left body right"
      "left footer footer"
    `,
    columnGap: '1rem',
  }),
  left: css({
    gridArea: 'left',
  }),
  body: css({
    gridArea: 'body',
    minWidth: 0,
  }),
  right: css({
    gridArea: 'right',
  }),
  footer: css({
    gridArea: 'footer',
  }),
  AgendaTopicWrapperSkeleton: css({
    maxWidth: '100%',
    height: '6.5rem',
  }),
  realtimeReorderingIndicator: css({
    right: '1.75rem',
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  scrollRef: RefObject<HTMLDivElement>,
  reorderName?: string,
  renderLeftSideComponent?: (styles: SerializedStyles) => JSX.Element,
  renderBodyComponent?: (styles: SerializedStyles) => JSX.Element,
  renderRightSideComponent?: (styles: SerializedStyles) => JSX.Element,
  renderFooterComponent?: (styles: SerializedStyles) => JSX.Element,
  isHighlighted?: boolean,
}

export const View = ({
  reorderName,
  renderLeftSideComponent,
  renderBodyComponent,
  renderRightSideComponent,
  renderFooterComponent,
  isHighlighted = false,
  scrollRef,
  ...props
}: ViewProps): JSX.Element => (
  <div
    ref={scrollRef}
    css={styles.AgendaTopicWrapper(isHighlighted || Boolean(reorderName))}
    {...props}
  >
    {reorderName && (
      <RealtimeReorderingIndicator css={styles.realtimeReorderingIndicator} reorderName={reorderName} />
    )}

    <div css={styles.itemContainer}>
      {renderLeftSideComponent?.(styles.left)}
      {renderBodyComponent?.(styles.body)}
      {renderRightSideComponent?.(styles.right)}
      {renderFooterComponent?.(styles.footer)}
    </div>
  </div>
);

export interface AgendaTopicWrapperProps extends Omit<ViewProps, 'editorString' | 'scrollRef'> {
  activeReorderers?: string[],
}

const AgendaTopicWrapper = ({
  activeReorderers,
  renderLeftSideComponent,
  renderBodyComponent,
  renderRightSideComponent,
  isHighlighted = false,
  ...props
}: AgendaTopicWrapperProps): JSX.Element => {
  const { data: peopleData } = useGetPeople();
  const scrollRef = useRef<HTMLDivElement>(null);

  if (isHighlighted) {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  const reorderName = useMemo(() => {
    if (activeReorderers?.length) {
      if (activeReorderers.length > 1) {
        return 'Several People';
      }

      const fullNames = activeReorderers.map((reorderer) => {
        const personData = peopleData?.response.items?.find((person) => person.orgUserId === reorderer);

        if (personData) {
          return `${personData?.firstName} ${personData?.lastName}`;
        }

        return '';
      });

      return fullNames.join(', ');
    }

    return undefined;
  }, [peopleData, activeReorderers]);

  const hookProps = {
    reorderName,
    renderLeftSideComponent,
    renderBodyComponent,
    renderRightSideComponent,
    scrollRef,
    isHighlighted,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

const SkeletonView = ({
  ...props
}): JSX.Element => (
  <SkeletonLoader
    css={[AgendaTopicWrapperBoxStyles, styles.AgendaTopicWrapperSkeleton]}
    variant="rectangular"
    renderComponent={() => <></>}
    {...props}
  />
);

export { SkeletonView as AgendaTopicWrapperSkeleton };

export default AgendaTopicWrapper;
