import { toast } from '~Common/components/Toasts';
import { store } from '~Deprecated/store';
import { setAccessTokenAction } from '~Deprecated/actions/storage/setAccessToken';
import {
  setToken,
  getToken,
  getRefreshToken,
} from '~Deprecated/utils/cookies';
import { decode } from 'jsonwebtoken';
import { ReactText } from 'react';
import { signout } from '../redux/actions/common';
import { byPassAuth, getHost } from './config';
import { getUrl } from './HttpService';

let updateTokenAt: number;
let deferredCall: Promise<void> | undefined;
let currentToast: ReactText;

interface RefreshBody {
  accessToken: string;
}

interface ResponseBody {
  data: RefreshBody
}

const logoutMessage = 'Your log in session expired. Please log in again to continue.';

function forceLogout(showToast = true): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  store.dispatch(signout());

  if (!showToast) {
    return;
  }

  if (!currentToast) {
    currentToast = toast.info(logoutMessage);
  } else {
    toast.update(currentToast, {
      render: logoutMessage,
      type: toast.TYPE.INFO,
      autoClose: 5000,
    });
  }
}

function setTokenUpdateTime(token: string): void {
  const { exp = 0 } = decode(token, { json: true }) ?? {};
  updateTokenAt = exp * 1000;
}

// This is the existing way we allow certain public endpoints, just extracted into a function.
// TODO: make this more robust.
function isEndpointAuthenticated(url: string): boolean {
  return !byPassAuth.some((path) => url.includes(path));
}

async function doRefreshToken(): Promise<void> {
  const url = getUrl({
    host: getHost('', '1'),
    uri: '/auth/accessToken',
  });
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    forceLogout();
    return;
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
    });

    const parsedResponse = await response.json() as ResponseBody;
    const { data: { accessToken } } = parsedResponse;

    if (!accessToken) {
      forceLogout();
      return;
    }

    // @ts-expect-error Update this once setAccessTokenAction is typed.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    store.dispatch(setAccessTokenAction({
      jsonWebToken: accessToken,
      jsonRefreshToken: refreshToken,
    }));
    setToken(accessToken, refreshToken);
    setTokenUpdateTime(accessToken);
  } catch (e) {
    forceLogout();
  } finally {
    deferredCall = undefined;
  }
}

function startDeferringRequests(): void {
  if (deferredCall) {
    return;
  }

  deferredCall = doRefreshToken();
}

function mustRefreshToken(token: string): boolean {
  if (!updateTokenAt) {
    setTokenUpdateTime(token);
  }

  return Date.now() > updateTokenAt;
}

async function deferredFetch(
  url: string,
  options: RequestInit = {},
): Promise<void | Response> {
  const headers = options?.headers ? new Headers(options.headers) : new Headers();

  // We only need to defer calls that require bearer tokens
  if (!isEndpointAuthenticated(url)) {
    return fetch(url, options);
  }

  const accessToken = getToken();

  // This is an authenticated endpoint but we don't have a cookie, or our cookie is about to expire.
  if (!accessToken || mustRefreshToken(accessToken)) {
    startDeferringRequests();
  }

  let tokenToUse = accessToken;
  // If we are already deferring, so let's wait for the refresh to finish
  if (deferredCall) {
    await deferredCall;
    tokenToUse = getToken();
  }

  // If they made it this far and tokenToUse is still not defined, the refresh token flow has failed, the user was logged out,
  // AND the network call persists for some reason. Right now, the culprit is Ably. This is an extra safeguard against that.
  if (!tokenToUse) {
    throw new Error('Failure to refresh authentication token');
  }

  headers.set('Authorization', `Bearer ${tokenToUse}`);
  const updatedOptions = {
    ...options,
    headers,
  };

  return fetch(url, updatedOptions);
}

export default deferredFetch;
