import useGetCustomMeetingTemplates from '~Meetings/hooks/templates/useGetCustomMeetingTemplates';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { isLocalId } from '~Common/utils/uuid';
import { useAddTemplateItemsToMeeting } from '~Meetings/hooks/templates/useAddTemplateItemsToMeeting';
import { MeetingInfoContext } from '~Meetings/components/topic-suggestions/contexts/MeetingInfoContext';
import { useCallback, useContext, useMemo } from 'react';
import { getIsIndividualQuestionsTemplate } from '~Meetings/components/topic-suggestions/utils/getIsIndividualTopicTemplate';
import { getFormattedTopicsForBulkAdd } from '~Meetings/components/topic-suggestions/utils/getFormattedTopicsForBulkAdd';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import {
  GetAgendaTemplateDetails,
  GetAgendaTopicTemplateDetails,
  GetCuratedAgendaTemplateDetails,
  GetMeetingTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { SerializedStyles, css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import emptyConversationBubbles from '~Meetings/assets/images/emptyConversationBubbles.png';
import { getCreator } from '~Meetings/components/topic-suggestions/utils/getCreator';
import { AgendaTemplates } from '@leadr-hr/types';
import { useIsTopicAddedToMeeting } from '../../stores/useIsTopicAddedToMeeting';
import MeetingTemplate, { TemplateSectionSkeleton } from '../MeetingTemplate';
import IndividualTopicsSection from '../IndividualTopicsSection';

const styles = {
  yourOrgAndMyTopicsTab: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',
  }),
  noTopicTemplatesImage: css({
    height: '6.5rem',
  }),
};

interface ViewProps {
  customTemplates: GetMeetingTemplateDetails[] | undefined,
  individualTopicsTemplate: GetMeetingTemplateDetails | undefined,
  isPrivate: boolean,
  isLoading: boolean,
  handleNewTemplateClick: () => void,
  closeDrawer: () => void,
  openTopicSuggestionsDrawer: () => void,
  addAgendaTopicsToMeeting: (agendaItems: GetAgendaTemplateDetails[]) => void,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
  showEmptyState: boolean,
  getDisableAddAllTopicsButton: (template: GetMeetingTemplateDetails) => boolean,
  maxTopics: number,
  maxSections: number,
}

const View = ({
  customTemplates,
  individualTopicsTemplate,
  isPrivate,
  isLoading,
  handleNewTemplateClick,
  closeDrawer,
  openTopicSuggestionsDrawer,
  addAgendaTopicsToMeeting,
  showEmptyState,
  addTopicToMeeting,
  getDisableAddAllTopicsButton,
  maxTopics,
  maxSections,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.yourOrgAndMyTopicsTab}
    {...props}
  >
    {isLoading && (
      <MultipleSkeletonLoaders
        css={styles.yourOrgAndMyTopicsTab}
        renderSkeletonItem={() => (
          <TemplateSectionSkeleton />
        )}
        numberOfSkeletons={3}
      />
    )}
    {!isLoading && customTemplates && (
      <>
        {showEmptyState && (
          <EmptyStateWithImage
            renderImage={() => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              (<img css={styles.noTopicTemplatesImage} src={emptyConversationBubbles} alt="No topic templates" />)
            )}
            renderText={() => (
              <div>
                Be the first to
                <LeadrButton
                  variant="text"
                  data-test-id="meetingTopicSuggestionsEmptyStateCreateNewTemplate"
                  onClick={handleNewTemplateClick}
                >
                  Create a New Template.
                </LeadrButton>
              </div>
            )}
          />
        )}
        {customTemplates?.map((template) => (
          <MeetingTemplate
            key={template.id}
            addTopicToMeeting={addTopicToMeeting}
            creator={getCreator(template.creator)}
            template={template}
            closeDrawer={closeDrawer}
            maxTopics={maxTopics}
            maxSections={maxSections}
            isPrivate={isPrivate}
            renderRightButton={(rightButtonStyles: SerializedStyles) => (
              <LeadrButton
                css={rightButtonStyles}
                variant="text"
                textButtonColor={palette.brand.indigo}
                onClick={() => addAgendaTopicsToMeeting(template.children)}
                data-test-id="meetingTopicSuggestionsAddAllTemplateTopics"
                disabled={getDisableAddAllTopicsButton(template)}
              >
                + Add All
              </LeadrButton>
            )}
            openTopicSuggestionsDrawer={openTopicSuggestionsDrawer}
          />
        ))}
        {individualTopicsTemplate && (
          <IndividualTopicsSection
            isPrivate={isPrivate}
            addTopicToMeeting={addTopicToMeeting}
            individualTopicsTemplate={individualTopicsTemplate}
          />
        )}
      </>
    )}
  </div>
);

interface YourOrgAndMyTopicsTab {
  handleNewTemplateClick: () => void,
  closeDrawer: () => void,
  openTopicSuggestionsDrawer: () => void,
  maxTopics: number,
  maxSections: number,
  isPrivate: boolean,
}

const YourOrgAndMyTopicsTab = ({
  isPrivate,
  ...props
}: YourOrgAndMyTopicsTab): JSX.Element => {
  const { huddleId, meetingTemplateType } = useContext(MeetingInfoContext);
  const { data, isLoading } = useGetCustomMeetingTemplates({
    meetingTemplateType,
    templateVisibility: isPrivate ? AgendaTemplates.TemplateVisibility.Private : AgendaTemplates.TemplateVisibility.Public,
  });
  const { mutate: addTemplateItemsToMeetingMutation } = useAddTemplateItemsToMeeting();

  const { isLimitedAccess } = useUserPermissions();

  const { getIsAnyTemplateTopicInMeeting } = useIsTopicAddedToMeeting(huddleId);

  const getDisableAddAllTopicsButton = useCallback((template: GetMeetingTemplateDetails) => {
    const isAnyTemplaleTopicInMeeting = getIsAnyTemplateTopicInMeeting(template.children);
    const isOptimisticTemplate = isLocalId(template.id);
    return isOptimisticTemplate || isAnyTemplaleTopicInMeeting;
  }, [getIsAnyTemplateTopicInMeeting]);

  const addAgendaTopicsToMeeting = useCallback((agendaTopics: GetAgendaTemplateDetails[] | GetCuratedAgendaTemplateDetails[]): void => {
    const children = getFormattedTopicsForBulkAdd(agendaTopics);

    addTemplateItemsToMeetingMutation({
      huddleId,
      children,
    });
  }, [addTemplateItemsToMeetingMutation, huddleId]);

  const addTopicToMeeting = useCallback((agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails): void => {
    addAgendaTopicsToMeeting([agendaTopic]);
  }, [addAgendaTopicsToMeeting]);

  const [areTemplatesLoading] = useSkeletonLoaders(isLoading);

  const individualTopicsTemplate = useMemo(() => data?.response.meetingTemplates.find((template) => getIsIndividualQuestionsTemplate(template)), [data]);
  const meetingTemplates = useMemo(() => data?.response.meetingTemplates.filter((template) => !getIsIndividualQuestionsTemplate(template)), [data]);

  const showEmptyState = meetingTemplates?.length === 0 && !isLimitedAccess;

  const hookProps = {
    isPrivate,
    individualTopicsTemplate,
    customTemplates: meetingTemplates,
    isLoading: areTemplatesLoading,
    addAgendaTopicsToMeeting,
    showEmptyState,
    addTopicToMeeting,
    getDisableAddAllTopicsButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default YourOrgAndMyTopicsTab;
