import { connect } from 'react-redux';
import PersonalityTypes from '~Deprecated/ui/views/Nexus/PersonalityTypes';
import { loadPersonalitiesAction } from '~Deprecated/actions/personalities/loadPersonalities';

const mapStateToProps = (state) => ({
  hasPendingCall: state.personalities.hasPendingCall,
});
const mapDispatchToProps = {
  loadPersonalitiesAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(PersonalityTypes);
