import { useCallback } from 'react';
import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { DROPDOWN_STYLES } from '../../const/pageStyles';
import { ReflectionType } from '../../types';

const TaskForTranslation = {
  [ReflectionType.All]: 'All Types',
  [ReflectionType.Accomplishment]: 'Accomplishment',
  [ReflectionType.AgendaItem]: 'Agenda Topic',
  [ReflectionType.Feedback]: 'Feedback',
  [ReflectionType.Goal]: 'Goal',
  [ReflectionType.Learning]: 'Learning',
  [ReflectionType.Recognition]: 'Recognition',
};

const items = [
  {
    value: ReflectionType.All,
    text: TaskForTranslation[ReflectionType.All],
    'data-test-id': 'reflectionsTypeItem',
  },
  {
    value: ReflectionType.Accomplishment,
    text: TaskForTranslation[ReflectionType.Accomplishment],
    'data-test-id': 'reflectionsTypeItem',
  },
  {
    value: ReflectionType.AgendaItem,
    text: TaskForTranslation[ReflectionType.AgendaItem],
    'data-test-id': 'reflectionsTypeItem',
  },
  {
    value: ReflectionType.Feedback,
    text: TaskForTranslation[ReflectionType.Feedback],
    'data-test-id': 'reflectionsTypeItem',
  },
  {
    value: ReflectionType.Goal,
    text: TaskForTranslation[ReflectionType.Goal],
    'data-test-id': 'reflectionsTypeItem',
  },
  {
    value: ReflectionType.Learning,
    text: TaskForTranslation[ReflectionType.Learning],
    'data-test-id': 'reflectionsTypeItem',
  },
  {
    value: ReflectionType.Recognition,
    text: TaskForTranslation[ReflectionType.Recognition],
    'data-test-id': 'reflectionsTypeItem',
  },
];

const styles = {
  ...DROPDOWN_STYLES,
};

const View = ({
  onChange,
  value,
  renderValue,
  ...props
}: Omit<DropdownProps<ReflectionType[]>, 'items'>): JSX.Element => (
  <Dropdown
    displayEmpty
    css={styles.dropdown}
    value={value}
    renderItem={(item: DropdownItem<ReflectionType[]>) => (
      <div css={styles.dropdownItemBoy}>
        { item.text }
      </div>
    )}
    onChange={onChange}
    items={items}
    renderValue={renderValue}
    {...props}
  />
);

const ReflectionsTypeFilter = ({
  value,
  ...props
}: Omit<DropdownProps<ReflectionType[]>, 'items' | 'renderValue'>): JSX.Element => {
  const renderValue = useCallback(() => {
    let selectedString;
    if (value?.length) {
      selectedString = value.map((filterValue) => TaskForTranslation[filterValue]).join(', ');
    } else {
      selectedString = ReflectionType.All;
    }

    return `${selectedString}`;
  }, [value]);

  const hookProps = {
    value,
    renderValue,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ReflectionsTypeFilter;
