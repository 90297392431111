import { connect } from 'react-redux';

import ScoringTooltipContent from '~Deprecated/ui/components/Insights/ScoringTooltipContent';
import {
  getOrganizationPersonById,
} from '~Deprecated/selectors/organizationInsights/getOrganizationPeople';
import { getOrganizationUserId } from '~Common/utils/localStorage';

const mapStateToProps = (state, { isAggregate = false, id }) => {
  let orgPersonData;
  let orgUserId;

  if (id && !isAggregate) {
    orgPersonData = getOrganizationPersonById(state, { id });
    orgUserId = getOrganizationUserId();
  }

  return {
    firstName: orgPersonData && orgPersonData.firstName,
    isManager: orgPersonData && orgPersonData.manager && orgPersonData.manager.orgUserId === orgUserId,
    isCurrentPerson: orgPersonData && orgUserId === orgPersonData.orgUserId,
    id,
    user: orgPersonData,
  };
};

const mapDispatchToProps = ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ScoringTooltipContent);
