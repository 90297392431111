import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';

const styles = {
  collapseButton: css({
    padding: 0,
    marginRight: '0.6125rem',
  }),
  collapseIcon: (isSectionExpanded: boolean) => css({
    rotate: '0deg',
    transition: 'rotate 100ms ease',
  }, isSectionExpanded && {
    rotate: '180deg',
  }),
};

interface CollapseButtonProps extends Omit<LeadrButtonProps<'button'>, 'data-test-id'> {
  onCollapseClick: () => void,
  isSectionExpanded: boolean,
}

const CollapseButton = ({
  onCollapseClick,
  isSectionExpanded,
  ...props
}: CollapseButtonProps): JSX.Element => (
  <LeadrButton
    css={styles.collapseButton}
    onClick={onCollapseClick}
    variant="icon"
    data-test-id="agendaSectionCollapseButton"
    {...props}
  >
    <FontAwesomeIcon
      icon={faChevronUp}
      css={styles.collapseIcon(isSectionExpanded)}
    />
  </LeadrButton>
);

export default CollapseButton;
