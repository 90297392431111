/* eslint-disable react/button-has-type */

import { faUndo } from '@fortawesome/pro-duotone-svg-icons';

import Button, { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const UndoButton = (props) => (
  <Button
    text="Undo"
    icon={faUndo}
    buttonColor={BUTTON_COLOR.ADDITIVE}
    {...props}
  />
);

UndoButton.propTypes = {};

UndoButton.defaultProps = {};

export default UndoButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
