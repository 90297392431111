const authConstants = {
  maxTextLength: 64,
  minTextLength: 6,
  alertTimeout: 4000,
  password: {
    regx: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
  },
};

export default authConstants;
