import { ReactText, useRef } from 'react';
import { useMutation, UseMutateFunction, QueryFunctionContext } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { Drawer } from '~Common/const/drawers';

interface AddToNextMeetingProps {
  meetingId: string,
  learningId: string,
  otherUserId: string,
}

const addToNextMeeting = ({ meetingId, learningId }: AddToNextMeetingProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: hosts.coaching,
    uri: `/organizations/${getOrganizationId() ?? ''}/coachings/${meetingId}`,
  };

  return patchApi<void>(serverUrl, {
    contents: [{ uid: learningId }],
    reflectInFutureInstances: false,
  });
};

interface AddToNextMeetingHookProps extends Partial<QueryFunctionContext<string[]>> {
  template: Drawer,
}

interface Error {
  message: string,
  messageKey: string,
  success: boolean,
}

export const useAddToNextMeeting = ({
  queryKey = [getOrganizationId() ?? '', getOrganizationUserId() ?? ''],
  template,
}: AddToNextMeetingHookProps): UseMutateFunction<HttpCallReturn<void>, Error, AddToNextMeetingProps> => {
  const toastId = useRef<ReactText | number | null>(null);
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: addToNextMeeting,
    onMutate: () => {
      toastId.current = toast.info('Adding learning to your 1:1...', { autoClose: false });

      if (template) {
        // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
        dispatch(popDrawerAction({ drawerName: template.name }));
      }
    },
    onError: (error: Error, variables) => {
      if (error.messageKey === 'errors.content_already_added_in_this_meeting') {
        toast.update(toastId.current, {
          render: 'This learning is already added to your 1:1.',
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: 'There was an error adding the learning to your 1:1. Please try again.',
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      }

      if (template) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...template,
              args: {
                ...variables,
              },
            },
          }));
        });
      }
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully added the learning to your 1:1.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
    },
  }).mutate;
};
