import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { toast } from '~Common/components/Toasts';

import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { postApi } from '~Deprecated/services/HttpService';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';

export const resyncMeetingService = async ({ factoryId, organizationId }) => {
  const URL = `/organizations/${organizationId ?? ''}/calendarIntegration/resync`;
  return postApi(URL, factoryId);
};

export const useResyncMeeting = ({ template }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: resyncMeetingService,
    onMutate: () => {
      toastId.current = toast.info('Re-syncing your calendar... It will be a few minutes before your calendar and Leadr are in sync', { autoClose: true });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: <MeetingsErrorToast />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Re-sync underway. Please check your calendar in a few minutes to ensure your calendar is in sync with Leadr.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      dispatch(popDrawerAction({ drawerName: template.name }));
    },
  });
  return mutation;
};
