import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from '~Common/components/Toasts';
import {
  authDisconnect, updateUserProfile, updateUserSettings,
} from './api';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { getHost } from '~Deprecated/services/config';
import { postApi } from '~Deprecated/services/HttpService';

export const usePatchUserSettings = ({ queryKey, silent }) => {
  const toastId = React.useRef(null);

  const mutation = useMutation({
    mutationFn: updateUserSettings,
    onMutate: (variables) => {
      if (!silent) {
        toastId.current = toast.info('Saving user settings...', { autoClose: false });
      }
    },
    onError: (error, variables, context) => {
      if (!silent) {
        toast.update(toastId.current, {
          render: 'There was an error saving your user settings. Please try again.',
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      }
    },
    onSuccess: (data, variables, context) => {
      if (!silent) {
        toast.update(toastId.current, {
          render: 'Successfully saved user settings!',
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
      }

      queryClient.invalidateQueries(queryKey);
    },
  });

  return mutation;
};

const resetPassword = async (email) => {
  const organizationId = getOrganizationId() ?? '';
  const url = {
    host: getHost('', '2'),
    uri: `/organizations/${organizationId}/auth/resetPassword`,
  };

  return postApi(url, email);
};

export const useResetPasswordMutator = () => {
  const toastId = React.useRef(null);

  const mutation = useMutation({
    mutationFn: resetPassword,
    onMutate: (variables) => {
      toastId.current = toast.info('Sending email to reset your password...', { autoClose: false });
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error resetting your password. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables, context) => {
      toast.update(toastId.current, {
        render: 'Successfully sent the email to reset your password!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });

  return mutation;
};

export const useAuthDisconnectMutator = (queryKey) => {
  const toastId = React.useRef(null);

  const mutation = useMutation({
    mutationFn: authDisconnect,
    onMutate: (variables) => {
      toastId.current = toast.info('Disconnecting your social authentication...', { autoClose: false });
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error disconnecting your social authentication. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables, context) => {
      toast.update(toastId.current, {
        render: 'Successfully disconnected your social authentication!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries(queryKey);
    },
  });

  return mutation;
};

export const useUpdateUserProfileMutator = () => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: updateUserProfile,
    onMutate: (variables) => {
      toastId.current = toast.info('Updating your profile...', { autoClose: false });

      if (variables.template) {
        dispatch(popDrawerAction({
          drawerName: variables.template.name,
        }));
      }
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error updating your profile. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (variables.template) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...variables.template,
              args: {
                userId: variables.userId,
              },
            },
          }));
        }, 0);
      }
    },
    onSuccess: (data, { userId }, context) => {
      const orgId = getOrganizationId();

      toast.update(toastId.current, {
        render: 'Successfully updated your profile!',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries([orgId, 'profile', userId]);
      queryClient.invalidateQueries([orgId, 'peopleList']);
      queryClient.invalidateQueries([orgId, 'people']);
      queryClient.invalidateQueries([orgId, 'personalityDetails']);
    },
  });

  return mutation;
};
