import { MouseEvent } from 'react';
import Button from '../Button';
import { RenderConfirmationButtonsParams } from './ConfirmationPopover';

interface ConfirmationButtonsProps extends RenderConfirmationButtonsParams {
  onConfirm: (mouseEvent?: MouseEvent) => void,
  questionText?: string,
  confirmText?: string,
  cancelText?: string,
}

const ConfirmationButtons = ({
  informationStyles,
  optionStyles,
  popoverButtonStyles,
  onConfirm,
  questionText = 'Are You Sure?',
  confirmText = 'Yes, Confirm',
  cancelText = 'No, Cancel',
}: ConfirmationButtonsProps): JSX.Element => (
  <>
    <Button
      variant="text"
      renderContents={() => <>{questionText}</>}
      css={[popoverButtonStyles, informationStyles]}
      disabled
    />
    <Button
      variant="text"
      data-test-id="confirmButton"
      renderContents={() => <>{confirmText}</>}
      css={[popoverButtonStyles, optionStyles]}
      onClick={onConfirm}
    />
    <Button
      variant="text"
      data-test-id="cancelButton"
      renderContents={() => <>{cancelText}</>}
      css={[popoverButtonStyles, optionStyles]}
    />
  </>
);

export default ConfirmationButtons;
