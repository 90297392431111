import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useEffect } from 'react';
import { AssignedPlaylistDetails } from '~Learning/const/interfaces';
import { assignedLearningQueryKeys } from './queryKeys';

const getAssignedPlaylistDetails = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<AssignedPlaylistDetails>> => {
  const [playlistId] = queryKey.slice(-1);

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/requested/${playlistId}`;

  return getApi<AssignedPlaylistDetails>(serverUrl);
};

interface AssignedPlaylistDetailHookParams {
  playlistId: number,
}

interface GetAssignedPlaylistDetailHookReturn {
  detail: AssignedPlaylistDetails | undefined,
  isLoading: boolean,
}

export const useGetAssignedPlaylistDetails = ({ playlistId }: AssignedPlaylistDetailHookParams): GetAssignedPlaylistDetailHookReturn => {
  const dispatch = useDispatch();

  const result = useQuery({
    queryKey: assignedLearningQueryKeys.playlistDetail(playlistId),
    queryFn: getAssignedPlaylistDetails,
    meta: {
      errorMessage: 'There was an error getting the learning playlist information. Please try again.',
    },
  });

  useEffect(() => {
    if (result.isError) {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));
    }
  }, [dispatch, result.isError]);

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
