import { css } from '@emotion/react';
import { useSupportChat } from '~Common/hooks/useSupportChat';
import { palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';

const styles = {
  button: css({
    color: palette.brand.blue,
    fontWeight: 800,
    textDecoration: 'underline',
  }),
};

const MeetingsErrorToast = () => {
  const { openChat } = useSupportChat();

  const handleClick = () => {
    openChat('I just encountered an error while trying to update a meeting.');
  };

  return (

    <div>
      <p>
        The update has failed. Please try your request again in a few minutes. If you continue to have trouble
        <Button
          variant="text"
          css={styles.button}
          onClick={handleClick}
          renderContents={() => <>click here</>}
        />
        to contact our customer success team
      </p>
    </div>
  );
};

export default MeetingsErrorToast;
