import * as types from './index';

export const sendResponseData = (payload) => ({
  type: types.SEND_RESPONSE_TO_QUESTIONS,
  payload,
});
export const resetFeedbackQuestionResponse = (payload) => ({
  type: types.RESET_FEEDBACK_QUESTIONS_RESPONSE,
  payload,
});
export const resetFeedbackDetails = (payload) => ({
  type: types.RESET_FEEDBACK_DETAILS,
  payload,
});
