import { css } from '@emotion/react';
import { faCircleInfo, faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Goals } from '@leadr-hr/types';
import { Checkbox } from '~Common/V3/components/uncontrolled';
import Tooltip from '~Common/components/Tooltip';

import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import { getOrganizationName } from '~Common/utils/localStorage';

const styles = {
  checkboxContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    label: {
      marginBottom: '0 !important',
    },
  }),
  lockIcon: css({
    color: palette.neutrals.gray400,
  }, forMobileObject({
    display: 'none',
  })),
  teamNote: css({
    color: palette.neutrals.gray500,
    marginTop: '.5rem',
    display: 'flex',
    alignContent: 'center',

    '.svg-inline--fa': {
      verticalAlign: 'middle',
    },
  }),
  icon: css({
    marginLeft: '.5rem',
  }),
};

interface OldGoalTypeSelectionInfoProps {
  goalTypeSelected: string,
  isPrivate?: boolean,
}

const OldGoalTypeSelectionInfo = ({
  goalTypeSelected,
  isPrivate,
  ...props
}: OldGoalTypeSelectionInfoProps): JSX.Element => (
  <div
    {...props}
  >
    {goalTypeSelected === Goals.GoalContextType.Personal && (
      <div
        css={styles.checkboxContainer}
      >
        <Checkbox
          name="isPrivate"
          label="Make this a private goal"
          defaultChecked={isPrivate}
          data-test-id="goalsCreateGoalPrivateCheckbox"
          size={20}
        />
        <FontAwesomeIcon
          icon={faLock}
          css={styles.lockIcon}
        />
      </div>
    )}
    {goalTypeSelected === Goals.GoalContextType.Team && (
      <p
        css={styles.teamNote}
      >
        Team goals are visible to all members of this team
        <Tooltip
          interactive
          content="All team members can view this goal. Add collaborators to give them editing permissions."
        >
          <span>
            <FontAwesomeIcon
              css={styles.icon}
              icon={faCircleInfo}
            />
          </span>
        </Tooltip>
      </p>
    )}
    {goalTypeSelected === Goals.GoalContextType.Organization && (
      <p
        css={styles.teamNote}
      >
        {`Organization goals are visible to everyone in ${getOrganizationName() ?? 'your organization'}.`}
        <Tooltip
          interactive
          content={`Everyone in ${getOrganizationName() ?? 'your organization'} can view this goal. Add collaborators to give them editing permissions.`}
        >
          <span>
            <FontAwesomeIcon
              css={styles.icon}
              icon={faCircleInfo}
            />
          </span>
        </Tooltip>
      </p>
    )}
  </div>
);

export default OldGoalTypeSelectionInfo;
