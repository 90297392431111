export const feedbackStatusCompleted = 'COMPLETED';
export const feedbackStatusIncomplete = 'INCOMPLETE';
export const MDYFormat = 'MMMM Do, YYYY';
export const stringsFeedback = {
  addAllToNextMeeting: 'Add All to next Meeting',
  createTemplate: 'Create Template',
  feedbackQuestions: 'Feedback Questions',
  saveTemplate: 'Save Template',
  feedbackTitle: 'Feedback Title',
  selectTeammate: 'Select Teammate',
  feedbackType: 'Feedback Type',
  sendRequest: 'Send Request',
  addItem: 'Add Item',
  cancel: 'Cancel',
  deleteConfirmationMsg: 'Are you sure you want to delete?',
  confirm: 'Confirm',
  library: 'Library',
  createNew: 'Create New',
  selectFromLibrary: 'Select from Library',
  leaderTagline: 'This is a great place to ask for real-time, specific feedback depending that can be flexible to fit your needs.',
  awaitingResponse: 'Awaiting Response',
};
