import { css } from '@emotion/react';
import {
  MouseEvent, KeyboardEvent, SyntheticEvent, useState,
} from 'react';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { palette } from '~Common/styles/colors';
import { DEFAULT_OPACITY, OPTIMISTIC_ID } from '~DevelopmentPlan/const/defaults';
import { getPersonalDevelopmentTypeIcon, openInNewTab } from '~DevelopmentPlan/const/functions';
import { CompetencyResource, ResourceType } from '~DevelopmentPlan/const/types';
import DeleteConfirmationPopover, {
  useDeleteConfirmationPopover,
} from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import { HoverState, useHoverStateById } from '~Common/hooks/useHoverStateById';
import { useDeleteResource } from '~DevelopmentPlan/hooks/useDeleteResource';
import { useAddResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { useGetPlanById } from '~DevelopmentPlan/hooks/useGetPlanById';
import { useResourceDetailsDrawer } from '../Drawers/Resource/ResourceDetails';
import UnlinkConfirmationButtons from './UnlinkConfirmationButtons';

const styles = {
  resourcesContainer: (weAreEditing: boolean) => css({
    display: 'flex',
    gap: '.375rem',
    flexDirection: 'column',
    marginTop: '.375rem',
    maxWidth: '40.625rem',
  }, weAreEditing && {
    opacity: DEFAULT_OPACITY,
  }),
  resourceWrap: css({
    padding: '0.5rem 0.75rem',
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '.5rem',
    fontSize: '.75rem',
    color: palette.neutrals.gray800,
    flex: 1,
  }),
  icon: css({
    marginRight: '0.5rem',
    verticalAlign: 0,
    color: palette.neutrals.gray700,
  }),
  resourceTitle: css({
    flexGrow: 1,
  }),
  dueDate: css({
    alignSelf: 'flex-end',
    color: palette.neutrals.gray700,
    width: '6.5rem',
  }),
  resourceActionWrap: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '.375rem',

    ':hover': {
      cursor: 'pointer',
    },
  }),
  closeIcon: (isHovering: boolean) => css({
    visibility: isHovering ? 'visible' : 'hidden',
    cursor: 'pointer',
    color: palette.brand.red,
  }),
};

interface ViewProps extends HoverState {
  resources: CompetencyResource[] | undefined,
  weAreEditing: boolean,
  anchorEl: HTMLElement | null,
  openConfirmationPopover: (event: SyntheticEvent<HTMLElement, Event>) => void,
  closeConfirmationPopover: (event: SyntheticEvent<HTMLElement, Event>) => void
  isOpen: boolean,
  popoverId: string | undefined,
  handleConfirmDeletion: () => void,
  setResourceToBeDeleted: (resourceId: number) => void,
  handleResourceClick: (resource: CompetencyResource) => void,
}

const View = ({
  resources,
  weAreEditing,
  isHovering,
  handleMouseEnter,
  handleMouseLeave,
  isHoveringId,
  anchorEl,
  openConfirmationPopover,
  closeConfirmationPopover,
  isOpen,
  popoverId,
  handleConfirmDeletion,
  setResourceToBeDeleted,
  handleResourceClick,
}: ViewProps): JSX.Element => (
  <>
    <div
      css={styles.resourcesContainer(weAreEditing)}
    >
      {resources?.map((resource) => {
        const {
          id,
          contentType: {
            id: contentTypeId,
          },
          contentDueDate: dueDate,
        } = resource;
        const icon = getPersonalDevelopmentTypeIcon(contentTypeId);
        const dueDateString = dueDate !== null ? `Due - ${moment(dueDate).format('MMM Do')}` : 'No Due Date';
        return (
          <div
            onMouseEnter={() => handleMouseEnter(id.toString())}
            onMouseLeave={handleMouseLeave}
            css={styles.resourceActionWrap}
            onClick={() => handleResourceClick(resource)}
            onKeyDown={() => handleResourceClick(resource)}
            role="button"
            tabIndex={0}
          >
            <div
              key={id}
              css={styles.resourceWrap}

            >
              <FontAwesomeIcon
                icon={icon}
                css={styles.icon}
              />
              <div
                css={styles.resourceTitle}
              >
                {resource.contentTitle}
              </div>
              <div
                css={styles.dueDate}
              >
                {dueDateString}
              </div>
            </div>
            <div
              role="button"
              tabIndex={0} // Adding tabIndex to make it focusable
              onClick={(event: MouseEvent<HTMLElement>) => {
                openConfirmationPopover(event);
                setResourceToBeDeleted(id);
              }}
              onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
                openConfirmationPopover(event);
                setResourceToBeDeleted(id);
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                css={[styles.icon, styles.closeIcon(isHovering && isHoveringId === id.toString())]}
              />
            </div>
          </div>
        );
      })}
      <DeleteConfirmationPopover
        closeConfirmationPopover={closeConfirmationPopover}
        open={isOpen}
        popoverId={popoverId}
        anchorEl={anchorEl}
        renderConfirmationButtons={({
          informationStyles,
          optionStyles,
          popoverButtonStyles,
        }) => (
          <UnlinkConfirmationButtons
            informationStyles={informationStyles}
            optionStyles={optionStyles}
            popoverButtonStyles={popoverButtonStyles}
            onDelete={handleConfirmDeletion}
          />
        )}
      />
    </div>
  </>
);

interface CompetencyResourcesProps {
  resources: CompetencyResource[] | undefined,
  weAreEditing?: boolean,
  competencyId: number,
  pdpId: string,
}

const CompetencyResources = ({
  resources,
  weAreEditing = false,
  competencyId,
  pdpId,
}: CompetencyResourcesProps): JSX.Element => {
  const [resourceToBeDeleted, setResourceToBeDeleted] = useState<number>(OPTIMISTIC_ID);
  const {
    isHovering,
    handleMouseEnter,
    handleMouseLeave,
    isHoveringId,
  } = useHoverStateById();

  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('competencyResourceDeleteConfirmationPopover');

  const {
    mutate: deleteResourceMutation,
  } = useDeleteResource();

  const handleConfirmDeletion = (): void => {
    deleteResourceMutation({ id: pdpId, competencyId, resourceId: resourceToBeDeleted });
  };

  const { data: plan } = useGetPlanById({ id: pdpId });
  const { owner } = plan ?? {};
  const { openDrawer: openResourceDetailsDrawer } = useResourceDetailsDrawer();
  const {
    openViewResourceModal,
  } = useAddResourceModalStore((state) => ({
    openViewResourceModal: state.openViewResourceModal,
  }));

  const handleResourceClick = (resource: CompetencyResource): void => {
    const {
      contentType: { id: contentTypeId },
      contentId,
    } = resource;
    switch (contentTypeId) {
      case ResourceType.Meeting:
        openInNewTab(`/meetings?orgId=0x2f7fbc&meetingId=${contentId}`);
        break;
      case ResourceType.Recognition:
        openInNewTab(`/recognition/${contentId}`);
        // openViewResourceModal(contentTypeId, competencyId, contentId, true);
        break;
      case ResourceType.Accomplishment:
        openViewResourceModal(contentTypeId, competencyId, contentId, true);
        break;
      default:
        openResourceDetailsDrawer({
          resourceType: contentTypeId,
          subjectUid: contentId,
          ownerId: owner?.userId as string ?? '',
          ownerOrgID: owner?.orgUserId ?? '',
        });
    }
  };

  const hookProps = {
    resources,
    weAreEditing,
    isHovering,
    handleMouseEnter,
    handleMouseLeave,
    isHoveringId,
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
    handleConfirmDeletion,
    setResourceToBeDeleted,
    handleResourceClick,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, CompetencyResources };
export default CompetencyResources;
