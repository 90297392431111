import { useQuery } from '@tanstack/react-query';
import { hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { calendarIntQueryKeys } from './queryKeys';

const getNylasClientId = async (): Promise<HttpCallReturn<string>> => {
  const URL = {
    host: hosts.meeting,
    uri: `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/clientId`,
  };

  return getApi(URL);
};

// TODO: Update these to use the new calendar int hook format when https://github.com/Leadr-HR/leadr-web/pull/3411 is merged in
export const useGetNylasClientId = (): string | undefined => {
  const nylasEnabled = useFeatureFlag('nylas');
  const result = useQuery({
    queryKey: calendarIntQueryKeys.nylasClientId(),
    queryFn: getNylasClientId,
    enabled: !!nylasEnabled,
  });

  return result.data?.response;
};
