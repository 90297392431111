import { css } from '@emotion/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Goals } from '@leadr-hr/types';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import { GoalStatusColor, GoalStatusLabel, getGoalStatusIcon } from '~Goals/const/types';

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
  }),
  goalDescription: (largerDisplay: boolean, largeFont: boolean) => css({
    fontSize: '0.6875rem',
  }, largerDisplay && {
    fontSize: '1rem',
    color: palette.neutrals.gray800,
    fontWeight: 500,

  }, largeFont && {
    fontSize: '.875rem',
  }, withTruncate()),
  icon: (color: string) => css({
    marginRight: '0.5rem',
    verticalAlign: 0,
    color,
  }),
};

interface NewGoalStatusViewProps {
  showStatusIcon: boolean,
  goalStatusDescription?: string,
  largerDisplay: boolean,
  largeFont: boolean,
  goalColor: string,
  icon: IconProp,
}

const View = ({
  showStatusIcon,
  goalStatusDescription,
  largerDisplay,
  largeFont,
  icon,
  goalColor,
  ...props
}: NewGoalStatusViewProps): JSX.Element => (
  <div css={styles.container} {...props}>
    {showStatusIcon && (
      <FontAwesomeIcon
        icon={icon}
        css={styles.icon(goalColor)}
      />
    )}

    <div css={styles.goalDescription(largerDisplay, largeFont)}>
      {goalStatusDescription}
    </div>
  </div>
);
interface GoalStatusProps {
  status: Goals.GoalStatus,
  largerDisplay?: boolean,
  isMobile?: boolean,
  largeFont?: boolean,
}

const NewGoalStatus = ({
  largerDisplay = false,
  status,
  isMobile = false,
  largeFont = false,
  ...props
}: GoalStatusProps): JSX.Element => {
  const goalColor = GoalStatusColor[status];
  const icon = getGoalStatusIcon(status);
  const goalStatusDescription = GoalStatusLabel[status];
  const showStatusIcon = !isMobile;

  const hookProps = {
    goalColor,
    icon,
    goalStatusDescription,
    largerDisplay,
    largeFont,
    showStatusIcon,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default NewGoalStatus;
