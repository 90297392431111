import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ListItem from '~Meetings/components/shared/listItems/ListItem';
import { palette } from '~Common/styles/colors';

const styles = {
  leftIcon: css({
    color: palette.brand.indigo,
    width: '1.9375rem',
    height: '1.9375rem',
    marginRight: '1.3438rem',
  }),
  listItem: css({
    color: palette.neutrals.gray700,
    marginButton: '.625rem',
    fontWeight: 600,
  }),
};

interface DrawerOptionSelectItemProps {
  icon: IconProp,
  onClick(event: React.MouseEvent<HTMLButtonElement>): void,
  content: string,
}

const DrawerOptionSelectItem = ({ icon, ...props }: DrawerOptionSelectItemProps): JSX.Element => (
  <ListItem
    css={styles.listItem}
    renderLeftAdornment={() => (
      <FontAwesomeIcon css={styles.leftIcon} icon={icon} />
    )}
    {...props}
  />
);

export default DrawerOptionSelectItem;
