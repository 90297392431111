import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { HoverState, useHoverState } from '~Common/hooks/useHoverState';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { useContext } from 'react';
import {
  GetAgendaTopicTemplateDetails,
  GetCuratedAgendaTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';
import { useIsTopicAddedToMeeting } from '../../stores/useIsTopicAddedToMeeting';
import { MeetingInfoContext } from '../../contexts/MeetingInfoContext';

const styles = {
  agendaTopic: css({
    display: 'flex',
    color: palette.neutrals.gray900,
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    padding: '.5rem 1rem',
  }),
  text: css({
    color: palette.neutrals.gray900,
    wordBreak: 'break-word',
  }),
  rightSide: css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  }),
  addTopicToMeetingButton: (isHovering: boolean) => css({
    marginLeft: '1rem',
    visibility: isHovering ? 'visible' : 'hidden',
  }),
};

interface ViewProps extends HoverState {
  agendaText: string,
  isAgendaTextHtml: boolean,
  isAddedToMeeting: boolean,
  handleAddTopicToMeeting: () => void,
  renderRightItem?: () => JSX.Element,
}

const View = ({
  isAddedToMeeting,
  handleAddTopicToMeeting,
  agendaText,
  isAgendaTextHtml,
  isHovering,
  handleMouseEnter,
  handleMouseLeave,
  renderRightItem,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.agendaTopic}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    {...props}
  >
    <span css={styles.text}>
      {isAgendaTextHtml && (
      <HTMLRenderer htmlText={agendaText} />
      )}
      {!isAgendaTextHtml && (
        agendaText
      )}
    </span>
    <div css={styles.rightSide}>
      <LeadrButton
        css={styles.addTopicToMeetingButton(isHovering)}
        disabled={isAddedToMeeting}
        onClick={handleAddTopicToMeeting}
        data-test-id="meetingTemplatesAddTopicToMeeting"
        size="mini"
      >
        {isAddedToMeeting && (
          <span>Added</span>
        )}
        {!isAddedToMeeting && (
          <span>Add</span>
        )}
      </LeadrButton>
      {renderRightItem?.()}
    </div>
  </div>
);

interface AgendaTopicProps {
  agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails,
  addTopicToMeeting: (agendaTopic: GetAgendaTopicTemplateDetails | GetCuratedAgendaTemplateDetails) => void,
  followsAgendaSection?: boolean,
  renderRightItem?: () => JSX.Element,
}

const AgendaTopic = ({
  agendaTopic,
  addTopicToMeeting,
  followsAgendaSection = false,
  ...props
}: AgendaTopicProps): JSX.Element => {
  const { huddleId } = useContext(MeetingInfoContext);

  const { getIsTopicInMeeting } = useIsTopicAddedToMeeting(huddleId);
  const isAddedToMeeting = getIsTopicInMeeting(agendaTopic.text, agendaTopic.type);

  const {
    isHovering,
    handleMouseEnter,
    handleMouseLeave,
  } = useHoverState();

  const handleAddTopicToMeeting = (): void => {
    addTopicToMeeting(agendaTopic);
  };

  const agendaText = agendaTopic.text;
  const isAgendaTextHtml = !!agendaText && isHTMLText(agendaText);

  const hookProps = {
    agendaText,
    isAgendaTextHtml,
    isAddedToMeeting,
    isHovering,
    followsAgendaSection,
    handleMouseEnter,
    handleMouseLeave,
    handleAddTopicToMeeting,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaTopic;
