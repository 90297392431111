import {
  Switch, Route,
} from 'react-router-dom';
import {
  ALL_TEAMS,
  MY_TEAMS,
} from '~Common/const/routes';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import TeamsTable from '../components/TeamsTable';
import { TeamsListScope } from '../const/types';

interface ViewProps {
  showAllTeams: boolean,
}

const View = ({
  showAllTeams,
}: ViewProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[MY_TEAMS]}
      render={() => (
        <TeamsTable
          listScope={TeamsListScope.MyTeams}
        />
      )}
    />
    {showAllTeams && (
      <Route
        exact
        path={[ALL_TEAMS]}
        render={() => (
          <TeamsTable
            listScope={TeamsListScope.AllTeams}
          />
        )}
      />
    )}
    <SmartRedirect from="*" to={showAllTeams ? ALL_TEAMS : MY_TEAMS} />
  </Switch>
);

const TeamsRouter = ({
  ...props
}): JSX.Element => {
  const { isAdmin } = useUserPermissions();

  const showAllTeams = isAdmin;

  const hookProps = {
    showAllTeams,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default TeamsRouter;
