type FlaggedEnumValue = number;

/* eslint-disable no-bitwise */
class FlaggedEnumWrapper {
  private thisEnum: FlaggedEnumValue;

  constructor(thisEnum: FlaggedEnumValue) {
    this.thisEnum = thisEnum;
  }

  hasFlag(flag: FlaggedEnumValue): boolean {
    return (this.thisEnum & flag) === flag;
  }

  addFlag(flag: FlaggedEnumValue): FlaggedEnumWrapper {
    return new FlaggedEnumWrapper(this.thisEnum | flag);
  }

  removeFlag(flag: FlaggedEnumValue): FlaggedEnumWrapper {
    return new FlaggedEnumWrapper(this.thisEnum & ~flag);
  }
}

export function FlaggedEnum(thisEnumValue: FlaggedEnumValue): FlaggedEnumWrapper {
  return new FlaggedEnumWrapper(thisEnumValue);
}
