import { css, SerializedStyles } from '@emotion/react';
import {
  faAward, faTimes, faUsers, faUser, IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';
import MeetingListItem from '~Common/components/MeetingTypePicker/MeetingListItem';

import {
  ONE_ON_ONE_DEFAULT, TEAM_MEETING_DEFAULTS, PRIVATE_ONE_TIME_MEETING_DEFAULTS, MEETING_TITLES,
} from '~Meetings/const/meetingTypes';
import { useHasUnfulfilledDirectReport } from '~Meetings/hooks/useCoachingSuggestions';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

const styles = {
  listItem: css({
    margin: '.3125rem 0',
  }),
  instructions: css({
    margin: '0 0 .9375rem',
  }),
};

export const meetingTypePickerTemplate = {
  name: 'MEETING_TYPE_PICKER_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

interface ViewProps {
  openThisDrawer: () => void,
  closeDrawerClick: () => void,
  meetingTypeSelectionOptions: MeetingTypeSelectionOption[],
  hasUnfulfilledDirectReport: boolean,
  onOptionClickCallback: () => void,
}

const View = ({
  openThisDrawer,
  closeDrawerClick,
  meetingTypeSelectionOptions,
  hasUnfulfilledDirectReport,
  onOptionClickCallback,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            size="large"
            tooltip="Close"
          />
        )}
        title="Create a New Meeting"
      />
    )}
    renderBody={(defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <div>
          <DrawerInstructions css={styles.instructions} text="Choose the meeting type that best matches your needs." />
        </div>
        <div>
          {meetingTypeSelectionOptions.map((option) => (
            <MeetingListItem
              onClick={() => option.onClick(openThisDrawer, onOptionClickCallback)}
              key={option.content}
              css={styles.listItem}
              icon={option.icon}
              content={option.content}
              description={option.description}
              isCalledOut={hasUnfulfilledDirectReport && option.content === MEETING_TITLES.ONE_ON_ONE_MEETING}
            />
          ))}
        </div>
      </div>
    )}
  />
);

interface MeetingTypePickerDrawerParams extends DrawerProps<Record<string, unknown>> {
  meetingTypeSelectionOptions: MeetingTypeSelectionOption[],
  onOptionClickCallback: () => void,
}

const MeetingTypePickerDrawer = ({
  meetingTypeSelectionOptions,
  onOptionClickCallback,
  popDrawer,
  pushDrawer,
}: MeetingTypePickerDrawerParams): JSX.Element => {
  const hasUnfulfilledDirectReport = useHasUnfulfilledDirectReport();

  const closeDrawerClick = (): void => {
    popDrawer({ popAll: true });
  };

  const openThisDrawer = (): void => {
    pushDrawer({
      drawer: {
        ...meetingTypePickerTemplate,
        args: {
          onOptionClickCallback,
          meetingTypeSelectionOptions,
        },
      },
    });
  };

  const hookProps = {
    onOptionClickCallback,
    hasUnfulfilledDirectReport,
    closeDrawerClick,
    meetingTypeSelectionOptions,
    openThisDrawer,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

interface MeetingTypeSelectionOption {
  content: string,
  description: string,
  icon: IconDefinition,
  onClick: (openMeetingTypePickerDrawer: () => void, onOptionClickCallback: (drawerArgs: Record<string, unknown>) => void) => void,
}

interface UseGetMeetingTypeSelectionOptionsReturn {
  getMeetingTypeSelectionOptions: (includeOptions?: GetMeetingTypeSelectionOptionsParams) => MeetingTypeSelectionOption[],
}

interface GetMeetingTypeSelectionOptionsParams {
  includeOneOnOneMeeting?: boolean,
  includeOneTimeOneOnOneMeeting?: boolean,
  includeTeamMeeting?: boolean,
}

interface OnOptionClickParams {
  defaults: Record<string, unknown>,
  openMeetingTypePickerDrawer: () => void,
  onOptionClickCallback: (drawerArgs: Record<string, unknown>) => void,
}

export const useGetMeetingTypeSelectionOptions = (): UseGetMeetingTypeSelectionOptionsReturn => {
  const dispatch = useDispatch();

  const onOptionClick = useCallback(({
    defaults,
    openMeetingTypePickerDrawer,
    onOptionClickCallback,
  }: OnOptionClickParams): void => {
    onOptionClickCallback({
      ...defaults,
      openMeetingTypePickerDrawer,
    });
  }, [dispatch]);

  const oneOnOneOption = useMemo(() => ({
    content: MEETING_TITLES.ONE_ON_ONE_MEETING,
    description: 'Best for individual development',
    icon: faAward,
    onClick: (openMeetingTypePickerDrawer: () => void, onOptionClickCallback: (drawerArgs: Record<string, unknown>) => void) => onOptionClick({
      defaults: ONE_ON_ONE_DEFAULT,
      openMeetingTypePickerDrawer,
      onOptionClickCallback,
    }),
  }), [onOptionClick]);

  const oneTimeOneOnOneOption = useMemo(() => ({
    content: MEETING_TITLES.ONE_TIME_ONE_ON_ONE,
    description: 'Best for a single conversation',
    icon: faUser,
    onClick: (openMeetingTypePickerDrawer: () => void, onOptionClickCallback: (drawerArgs: Record<string, unknown>) => void) => onOptionClick({
      defaults: PRIVATE_ONE_TIME_MEETING_DEFAULTS,
      openMeetingTypePickerDrawer,
      onOptionClickCallback,
    }),
  }), [onOptionClick]);

  const teamMeetingOption = useMemo(() => ({
    content: MEETING_TITLES.TEAM_MEETING,
    description: 'Best for group collaboration',
    icon: faUsers,
    onClick: (openMeetingTypePickerDrawer: () => void, onOptionClickCallback: (drawerArgs: Record<string, unknown>) => void) => onOptionClick({
      defaults: TEAM_MEETING_DEFAULTS,
      openMeetingTypePickerDrawer,
      onOptionClickCallback,
    }),
  }), [onOptionClick]);

  const getMeetingTypeSelectionOptions = useCallback(({
    includeOneOnOneMeeting = true,
    includeOneTimeOneOnOneMeeting = true,
    includeTeamMeeting = true,
  }: GetMeetingTypeSelectionOptionsParams = {}) => {
    const meetingTypeSelectionOptions = [];

    if (includeOneOnOneMeeting) {
      meetingTypeSelectionOptions.push(oneOnOneOption);
    }

    if (includeOneTimeOneOnOneMeeting) {
      meetingTypeSelectionOptions.push(oneTimeOneOnOneOption);
    }

    if (includeTeamMeeting) {
      meetingTypeSelectionOptions.push(teamMeetingOption);
    }

    return meetingTypeSelectionOptions;
  }, [oneOnOneOption, oneTimeOneOnOneOption, teamMeetingOption]);

  return {
    getMeetingTypeSelectionOptions,
  };
};

registerDrawer({
  templateName: meetingTypePickerTemplate.name,
  component: MeetingTypePickerDrawer,
});

export default MeetingTypePickerDrawer;
