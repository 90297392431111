export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
// SCHEDULE MEETING
export const REMOVE_FEEDBACK_QUESTION_FROM_ARRAY = 'REMOVE_FEEDBACK_QUESTION_FROM_ARRAY';
export const SHOW_ADD_NEW_AGENDA = 'SHOW_ADD_NEW_AGENDA';
export const RESET_FEEDBACK_DETAILS = 'RESET_FEEDBACK_DETAILS';
// feedback
export const CLEAR_REQUEST_FEEDBACK_QUESTIONS = 'CLEAR_REQUEST_FEEDBACK_QUESTIONS';
// send response
export const SEND_RESPONSE_TO_QUESTIONS = 'SEND_RESPONSE_TO_QUESTIONS';
export const SEND_RESPONSE_TO_QUESTIONS_ERROR = 'SEND_RESPONSE_TO_QUESTIONS_ERROR';
// CREATE FEEDBACK
export const ADD_FEEDBACK_TO_MEETING_SUCCESS = 'ADD_FEEDBACK_TO_MEETING_SUCCESS';
// meeting details by ID
export const GET_MEETING_BY_ID = 'GET_MEETING_BY_ID';
export const GET_MEETING_BY_ID_SUCCESS = 'GET_MEETING_BY_ID_SUCCESS';
export const GET_MEETING_BY_ID_ERROR = 'GET_MEETING_BY_ID_ERROR';
// send response
export const SEND_RESPONSE_CONTENT_FOR_ME_LIST = 'SEND_RESPONSE_CONTENT_FOR_ME_LIST';
export const SEND_RESPONSE_CONTENT_FOR_ME_LIST_ERROR = 'SEND_RESPONSE_CONTENT_FOR_ME_LIST_ERROR';
// send response
export const RESET_FEEDBACK_QUESTIONS_RESPONSE = 'RESET_FEEDBACK_QUESTIONS_RESPONSE';
export const CLEAR_MEETING_SUCCESS_RESPONSE = 'CLEAR_MEETING_SUCCESS_RESPONSE';
export const CLEAR_CREATE_CONTENT_RESPONSE = 'CLEAR_CREATE_CONTENT_RESPONSE';
// Incomplete indicator count
export const GET_INCOMPLETE_FEEDBACK_COUNT_ERROR = 'GET_INCOMPLETE_FEEDBACK_COUNT_ERROR';
export const GET_INCOMPLETE_FEEDBACK_COUNT_SUCCESS = 'GET_INCOMPLETE_FEEDBACK_COUNT_SUCCESS';
export const GET_INCOMPLETE_CONTENT_COUNT_ERROR = 'GET_INCOMPLETE_CONTENT_COUNT_ERROR';
export const GET_INCOMPLETE_CONTENT_COUNT_SUCCESS = 'GET_INCOMPLETE_CONTENT_COUNT_SUCCESS';
export const SHOW_ADD_ACTION_ITEMS = 'SHOW_ADD_ACTION_ITEMS';
export const SIGNOUT = 'SIGNOUT';
export const INIT = 'LEADR.LEGACY_INIT';
export const AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED';
// content template
export const REMOVE_FEEDBACK_QUESTION_FROM_COACHING = 'REMOVE_FEEDBACK_QUESTION_FROM_COACHING';
// User
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';

// Schedule Meeting
export const GLOBAL_API_ERROR = 'GLOBAL_API_ERROR';
// for me get list
export const ACTIVATE_LOADER = 'ACTIVATE_LOADER';

export const POLLING = {
  START_PARALLEL: 'START_PARALLEL',
  STOP_PARALLEL: 'STOP_PARALLEL',
};
