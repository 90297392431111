import { css } from '@emotion/react';
import { Divider } from '@mui/material';
import { ComponentProps } from 'react';
import { palette } from '~Common/styles/colors';

const styles = {
  cardHeader: css({
    fontSize: '1.0rem',
    color: palette.brand.indigo,
    fontWeight: '600',
    paddingTop: '1.25rem',
  }),
  headerText: css({
    // Need to prevent OS X from opening Look Up when long pressing on the text
    // if a user is hovering over the text when attempting to click and drag
    // the module
    pointerEvents: 'none',
  }),
  container: css({
    marginBottom: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
};

interface ModuleHeaderProps extends ComponentProps<'header'> {
  headerText: string,
  renderRightSection?: () => JSX.Element,
}
const ModuleHeader = ({
  headerText,
  renderRightSection,
  ...props
}: ModuleHeaderProps):JSX.Element => (
  <header
    css={styles.cardHeader}
    {...props}
  >
    <div css={styles.container}>
      <div css={styles.headerText}>
        {headerText}
      </div>
      {renderRightSection?.()}
    </div>
    <Divider />
  </header>
);

export default ModuleHeader;
