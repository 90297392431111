import { css } from '@emotion/react';
import { useIsDesktopQuery } from '~Common/hooks/useMediaListener';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import React from 'react';

const styles = {
  iconAndText: css({
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  }),
};

type ViewProps<C extends React.ElementType> = Omit<LeadrButtonProps<C>, 'children'> & {
  isDesktop: boolean,
  itemName: string,
};

const View = <C extends React.ElementType> ({
  isDesktop,
  itemName,
  'data-test-id': dataTestId,
  ...props
}: ViewProps<C>): JSX.Element => (
  <LeadrButton
    data-test-id={dataTestId}
    {...props}
  >
    <LeadrButton.IconAndText css={styles.iconAndText} icon={faPlus} text={isDesktop ? `New ${itemName}` : 'New'} />
  </LeadrButton>
  );

type CreateButtonProps<C extends React.ElementType> = Omit<ViewProps<C>, 'isDesktop'> & {
  itemName: string,
}

const CreateButton = <C extends React.ElementType>({ itemName, ...props }: CreateButtonProps<C>): JSX.Element => {
  const isDesktop = useIsDesktopQuery();

  const hookProps = {
    isDesktop,
    itemName,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CreateButton;
