import { useMemo } from 'react';
import { ActionItem } from '~ActionItems/const/interfaces';

import { getOrganizationUserId } from '~Common/utils/localStorage';
import { isLocalId } from '~Common/utils/uuid';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import useMeetingDetails from './useMeetingDetails';

export interface UseNewActionItemPermissionsProps {
  meetingId: string,
  meetingType: MeetingTypeEnum,
  actionItem: ActionItem,
}

export interface UseNewActionItemPermissionsReturn {
  canComplete: boolean,
  canEdit: boolean,
  canDelete: boolean,
  canRemoveFromMeeting: boolean,
}

export const useNewActionItemPermissions = ({
  meetingId,
  meetingType,
  actionItem,
}: UseNewActionItemPermissionsProps): UseNewActionItemPermissionsReturn => {
  const { item: meeting } = useMeetingDetails({ id: meetingId, type: meetingType });
  const {
    creatorId, assigneeId, status, id,
  } = actionItem;

  const currentUser = getOrganizationUserId();
  const isServerAction = !isLocalId(id);

  return useMemo(() => {
    const canEdit = isServerAction && (creatorId === currentUser || assigneeId === currentUser) && status !== 'COMPLETED';
    const canComplete = isServerAction && (creatorId === currentUser || assigneeId === currentUser);
    const canDelete = isServerAction && (creatorId === currentUser || assigneeId === currentUser) && status !== 'COMPLETED';
    const canRemoveFromMeeting = isServerAction && (creatorId === currentUser || meeting?.organizer.orgUserId === currentUser);

    return {
      canComplete,
      canEdit,
      canDelete,
      canRemoveFromMeeting,
    };
  }, [assigneeId, creatorId, currentUser, meeting?.organizer, isServerAction, status]);
};
