import * as types from '../../../actions';

const feedbackList = (state = {}, action) => {
  switch (action.type) {
    case types.RESET_FEEDBACK_QUESTIONS_RESPONSE:
      state.feedbackDetailsById = {};
      return { ...state };
    case types.RESET_FEEDBACK_DETAILS:
      state.feedbackDetailsById = [];
      return { ...state };
    default:
      return state;
  }
};

export default feedbackList;
