import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import ActionItemStatus from '~ActionItems/components/ActionItemStatus';
import { ActionItem, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { ComponentProps, useMemo } from 'react';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { Person, UserStatus } from '~Common/const/interfaces';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { forMobileObject } from '~Common/styles/mixins';
import DueDate from './DueDate';
import Comments from './Comments';

const styles = {
  footerContainer: css({
    display: 'flex',
  }),
  contextContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '1.125rem',
  }, forMobileObject({
    gap: '.5rem',
  })),
  status: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
  }),
  recurrenceIcon: css({
    color: palette.neutrals.gray500,
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  areCommentsEnabled: boolean,
  isRecurring: boolean,
  status: NewActionItemStatus,
  commentCount: number,
  dueDateInMillis: number,
  completedInMillis?: number,
}

const View = ({
  areCommentsEnabled,
  isRecurring,
  status,
  commentCount,
  dueDateInMillis,
  completedInMillis,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.footerContainer}
    {...props}
  >
    <div css={styles.contextContainer}>
      <DueDate
        status={status}
        dueDateInMillis={dueDateInMillis}
        completedInMillis={completedInMillis}
      />
      <ActionItemStatus
        css={styles.status}
        status={status}
      />
      {areCommentsEnabled && !!commentCount && (
        <Comments commentCount={commentCount} />
      )}
      {isRecurring && (
        <FontAwesomeIcon css={styles.recurrenceIcon} icon={faArrowsRepeat} flip="horizontal" />
      )}
    </div>
  </div>
);

interface ActionItemFooterProps extends ComponentProps<'div'> {
  actionItem: ActionItem,
}

const ActionItemFooter = ({
  actionItem,
  ...props
}: ActionItemFooterProps): JSX.Element => {
  const areCommentsEnabled = useFeatureFlag('webClientUseActionItemComments');
  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, Person>>;

  const assignee = peopleData?.[actionItem.assigneeId];

  const assigneeName = useMemo(() => {
    const name = `${assignee?.firstName} ${assignee?.lastName?.slice(0, 1)}.`;
    const isDeactivated = assignee?.administrativeStatus === UserStatus.inactive;

    return isDeactivated ? `${name} (Deactivated)` : name;
  }, [assignee?.firstName, assignee?.lastName, assignee?.administrativeStatus]);

  const isRecurring = actionItem.isRecurring ?? false;

  const hookProps = {
    areCommentsEnabled,
    isRecurring,
    assigneeName,
    status: actionItem.status,
    commentCount: actionItem.commentCount,
    dueDateInMillis: actionItem.dueDateInMillis,
    completedInMillis: actionItem.completedInMillis,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItemFooter;
