import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import { NewActionItemStatus } from '~ActionItems/const/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { ComponentProps } from 'react';

const styles = {
  status: css({
    display: 'flex',
    alignItems: 'center',
  }),
  label: css({
    fontWeight: 400,
  }),
  statusIcon: (status: NewActionItemStatus) => {
    let color;

    switch (status) {
      case NewActionItemStatus.InProgress:
        color = palette.brand.orange;
        break;
      case NewActionItemStatus.Completed:
        color = palette.brand.green;
        break;
      case NewActionItemStatus.Blocked:
        color = palette.brand.red;
        break;
      default:
        color = palette.neutrals.gray400;
    }

    return css({
      fontSize: '0.75rem',
      marginRight: '0.375rem',
      color,
    });
  },
};

export const actionItemStatusTranslation = {
  [NewActionItemStatus.ToDo]: 'To Do',
  [NewActionItemStatus.InProgress]: 'In Progress',
  [NewActionItemStatus.Completed]: 'Completed',
  [NewActionItemStatus.Blocked]: 'Blocked',
};

export interface ViewProps extends ComponentProps<'div'> {
  status: NewActionItemStatus
}

const View = ({
  status,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.status}
    {...props}
  >
    <FontAwesomeIcon
      icon={faCircle}
      css={styles.statusIcon(status)}
    />
    <span css={styles.label}>
      { actionItemStatusTranslation[status] ?? actionItemStatusTranslation[NewActionItemStatus.ToDo] }
    </span>
  </div>
);

export interface ActionItemStatusDropdownProps extends ComponentProps<'div'> {
  status: NewActionItemStatus,
}

const ActionItemStatus = ({
  status,
  ...props
}: ActionItemStatusDropdownProps): JSX.Element => {
  const hookProps = {
    status,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItemStatus;
