import { useMergedHuddleTimelineByStartTime } from '~Meetings/hooks/useHuddleTimeline';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import useGetAgendas from '~Meetings/hooks/v2/useGetAgendas';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { Goals } from '@leadr-hr/types';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { useGetTeamClarityQuestions } from '~People/components/Teams/hooks/utils/useGetTeamClarityQuestions';
import { useMeetingActionItems } from '~ActionItems/hooks/useMeetingActionItems';
import { NewActionItemStatus } from '~ActionItems/const/interfaces';
import { useGetPrivateMeetingNotes } from '~Meetings/hooks/useGetPrivateMeetingNotes';
import { ActionItemSortField } from '~ActionItems/hooks/useActionItems';
import { SortDirection } from '~Common/const/interfaces';
import {
  INITIAL_STATIC_DEFINING_OBJECTIVE_GOALS_PARAMS,
  INITIAL_STATIC_OPERATING_OBJECTIVE_GOALS_PARAMS,
  INITIAL_STATIC_TEAM_GOALS_PARAMS,
} from '~Meetings/const/goals';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

interface HuddleDataLoaderProps {
  id: string;
  type: string;
  factoryId: string;
  teamId: string;
  otherUserInMeetingId: string;
}
export const useHuddleDataLoader = ({
  id,
  type,
  factoryId,
  teamId,
}: HuddleDataLoaderProps): void => {
  const { orgLevelEnableCascadingGoals } = useEnableCascadingGoals();
  const { enableTheTable } = useOrgDetailsContext();
  useMergedHuddleTimelineByStartTime({ id, type });
  useUserPermissions();
  useGetAgendas({ huddleId: id });
  useGetPrivateMeetingNotes(factoryId);

  useMeetingActionItems({
    factoryId,
    status: [
      NewActionItemStatus.ToDo,
      NewActionItemStatus.InProgress,
      NewActionItemStatus.Blocked,
    ],
    sortByField: ActionItemSortField.DueDate,
    sortByOrder: SortDirection.Ascending,
  });

  useGetTeamDetails({ teamId, huddleId: id, enabled: !!teamId && !!id });

  useGetTeamClarityQuestions({ teamId });

  const definingObjectiveGoalsParams: Goals.Requests.GetGoalsRequestQueryParameters = {
    ...INITIAL_STATIC_DEFINING_OBJECTIVE_GOALS_PARAMS,
    contextId: teamId,
    enableCascading: orgLevelEnableCascadingGoals,
  };
  useGetGoals({ params: definingObjectiveGoalsParams, enabled: enableTheTable && !!teamId });

  const operatingObjectiveGoalParams: Goals.Requests.GetGoalsRequestQueryParameters = {
    ...INITIAL_STATIC_OPERATING_OBJECTIVE_GOALS_PARAMS,
    contextId: teamId,
    enableCascading: orgLevelEnableCascadingGoals,
  };
  useGetGoals({ params: operatingObjectiveGoalParams, enabled: enableTheTable && !!teamId });

  const teamGoalsParams: Goals.Requests.GetGoalsRequestQueryParameters = {
    ...INITIAL_STATIC_TEAM_GOALS_PARAMS,
    contextId: teamId,
    enableCascading: orgLevelEnableCascadingGoals,
  };
  useGetGoals({ params: teamGoalsParams, enabled: !enableTheTable && !!teamId });
};
