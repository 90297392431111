import { useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { learningTableGroupTemplatesQueryKeys } from '../queryKeys';
import { LearningTemplateDetail } from '../singleContent/useGetLearningTemplate';

interface GetLearningTableGroupTemplateDetailParams {
  templateId: string,
}

const getLearningTableGroupTemplateDetail = async ({
  templateId,
}: GetLearningTableGroupTemplateDetailParams): Promise<HttpCallReturn<LearningTemplateDetail>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/curatedByTableGroup/${templateId}`;

  return getApi<LearningTemplateDetail>(serverUrl);
};

interface UseGetLearningTableGroupTemplateDetailParams {
  templateId: string,
  enabled?: boolean,
}

interface UseGetLearningTableGroupTemplateDetailReturn {
  detail: LearningTemplateDetail | undefined,
  isLoading: boolean,
}

export const useGetLearningTableGroupTemplateDetail = ({
  templateId,
  enabled = true,
}: UseGetLearningTableGroupTemplateDetailParams): UseGetLearningTableGroupTemplateDetailReturn => {
  const result = useQuery({
    queryKey: learningTableGroupTemplatesQueryKeys.singleContentDetail(templateId),
    queryFn: () => getLearningTableGroupTemplateDetail({
      templateId,
    }),
    enabled: enabled && !!templateId,
  });

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
