export enum ViewPerspective {
  Company = 'company',
  Departments = 'departments',
  ManagerReports = 'manager_reports',
  UserGroups = 'user_groups',
  DirectReports = 'direct_reports',
}

export enum PeoplePerspective {
  OneOnOne = '1on1',
  Goals = 'goals',
  Feedback = 'feedback',
  Activity = 'activity',
}

export enum EngagementCategory {
  overall = 'Total Engagement',
  clarity = 'Clarity',
  maximization = 'Maximization',
  rapport = 'Rapport',
}

export enum EnpsCategory {
  promoters = 'Promoters',
  passives = 'Passives',
  detractors = 'Detractors',
}

export interface ENPSGraphData {
    [key: string]: number | string,
}

export interface CardDetail {
  key: string
  dashboardSize: string
  dashboardComponent: JSX.Element
  expandedComponent?: JSX.Element
  tinyComponent?: JSX.Element
  requiresDirectReports: boolean,
}

export enum SetDisplay {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export type DoExpand = (key: string) => void;
export type DoShrink = React.MouseEventHandler<HTMLButtonElement>;

export interface PulseSurveysApiPostEnableBody {
  frequency?: unknown, // currently ignored
  organizationId: string,
}

export interface PulseSurveysApiPostDisableBody {
  organizationId: string,
}
