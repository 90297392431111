import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { LearningPlaylistTemplateLearning } from '~Learning/const/interfaces';
import { learningTemplatesQueryKeys } from '../queryKeys';

export interface LearningPlaylistTemplateDetail {
  id: number,
  title: string,
  categoryName: string,
  categoryId: string,
  description: string,
  owner: TemplateOwnerInfo,
  numberOfLearnings: number,
  learnings: LearningPlaylistTemplateLearning[]
}

interface TemplateOwnerInfo {
  name: string,
  ownerId?: string,
}

const getLearningPlaylistTemplateDetail = async ({ queryKey }: QueryFunctionContext<readonly string[]>):
  Promise<HttpCallReturn<LearningPlaylistTemplateDetail>> => {
  const [playlistTemplateId] = queryKey.slice(-1);

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/templates/${playlistTemplateId}`;

  return getApi<LearningPlaylistTemplateDetail>(serverUrl);
};

interface UseGetLearningPlaylistTemplateDetailParams {
  playlistTemplateId: number,
  enabled?: boolean,
}

interface LearningTemplateHookReturn {
  detail: LearningPlaylistTemplateDetail | undefined,
  isLoading: boolean,
}

export const useGetLearningPlaylistTemplateDetail = ({
  playlistTemplateId,
  enabled = true,
}: UseGetLearningPlaylistTemplateDetailParams): LearningTemplateHookReturn => {
  const result = useQuery({
    queryKey: learningTemplatesQueryKeys.playlistDetail(playlistTemplateId),
    queryFn: getLearningPlaylistTemplateDetail,
    enabled: enabled && !!playlistTemplateId,
  });

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
