import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import {
  AdminImporterTabValues, ResponseDataShape,
} from '../Const/types';
import WarningDataTable from '../Components/WarningDataTable';
import ErrorDataTable from '../Components/ErrorDataTable';
import SuccessfulDataTable from '../Components/SuccessfulDataTable';
// import EditDataTable from '../Components/EditDataTable';

interface ViewProps {
  importTab: AdminImporterTabValues,
  filteredImportData: ResponseDataShape[],
  // originalDraftData: ResponseDataShape[],
}

const View = ({
  importTab,
  filteredImportData,
  // originalDraftData,
}: ViewProps): JSX.Element => (
  <>
    {importTab === AdminImporterTabValues.Successful && (
    <SuccessfulDataTable
      filteredImportData={filteredImportData}
    />
    )}
    {importTab === AdminImporterTabValues.Warnings && (
    <WarningDataTable
      filteredImportData={filteredImportData}
    />
    )}
    {importTab === AdminImporterTabValues.Errors && (
    <ErrorDataTable
      filteredImportData={filteredImportData}
    />
    )}
    {/* Saving this for later - will be used in a later iteration of the importer */}
    {/* {importTab === AdminImporterTabValues.Edit && (
    <EditDataTable
      originalDraftData={originalDraftData}
    />
    )} */}
  </>
);

interface DataTableDisplayProps {
  filteredImportData: ResponseDataShape[],
  originalDraftData: ResponseDataShape[],
}

const DataTableDisplay = ({
  filteredImportData,
  originalDraftData,
}: DataTableDisplayProps): JSX.Element => {
  const [importTab] = useQueryParamState<AdminImporterTabValues>('admin', 'tabView', AdminImporterTabValues.Successful);

  const hookProps = {
    importTab,
    filteredImportData,
    originalDraftData,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default DataTableDisplay;
