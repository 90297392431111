import { css } from '@emotion/react';

import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import ConnectCalendarBanner from '~Meetings/components/banners/ConnectCalendarBanner';
import MeetingsList from './MeetingsList';
import ReviewUnsyncedMeetingsBanner from '../../../../meetings/components/banners/ReviewUnsyncedMeetingsBanner';
import EmptyMeetingState from './EmptyMeetingState';

const styles = {
  page: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  banner: css({
    marginBottom: '1rem',
  }),
};

interface MeetingsViewProps {
  isLoading: boolean,
  // TODO: Type this when useUpcomingMeetingsByDate is typed
  meetings: Record<string, unknown[]>,
  meetingDates: string[],
  // TODO: Type this when useUpcomingMeetingsByDate is typed
  onCardClick: (meetingDetails: Record<string, unknown>, isCoaching: boolean) => void,
  isAuthenticated: AUTH_STATUS,
  showEmptyMeetingState: boolean,
  numberOfMeetingsToReview: number,
  showConnectCalendarBanner: boolean,
  showReviewUnsyncedMeetingsBanner: boolean,
  dismissReviewUnsyncedMeetingsBanner: () => void,
  dismissConnectCalendarBanner: () => void,
  isPast: boolean,
}

const MeetingsView = ({
  isLoading,
  meetings,
  meetingDates,
  onCardClick,
  isAuthenticated,
  showEmptyMeetingState,
  numberOfMeetingsToReview,
  showConnectCalendarBanner,
  showReviewUnsyncedMeetingsBanner,
  dismissConnectCalendarBanner,
  dismissReviewUnsyncedMeetingsBanner,
  isPast,
}: MeetingsViewProps): JSX.Element => (
  <div css={styles.page}>
    {showConnectCalendarBanner && (
      <ConnectCalendarBanner
        css={styles.banner}
        calendarAuthStatus={isAuthenticated}
        dismissBanner={dismissConnectCalendarBanner}
      />
    )}
    {showReviewUnsyncedMeetingsBanner && (
      <ReviewUnsyncedMeetingsBanner
        css={styles.banner}
        dismissReviewUnsyncedMeetingsBanner={dismissReviewUnsyncedMeetingsBanner}
        numberOfMeetingsToReview={numberOfMeetingsToReview}
      />
    )}
    {showEmptyMeetingState && (
      <EmptyMeetingState
        isLoading={isLoading}
        isPast={isPast}
      />
    )}
    {!showEmptyMeetingState && (
      <MeetingsList
        meetings={meetings}
        meetingDates={meetingDates}
        onCardClick={onCardClick}
      />
    )}
  </div>
);

export default MeetingsView;
