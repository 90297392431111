import { ReactText, useRef } from 'react';
import {
  InfiniteData,
  UseMutateFunction,
  useMutation,
} from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { deleteApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { recognitionKeys } from '~Recognition/const/queryKeys';
import {
  ErrorShape, InfiniteQueryParamsProps,
} from '~Recognition/const/types';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { produce } from 'immer';
import { RecognitionListResponse } from './useGetRecognition';

interface deleteRecognitionProps {
  recognitionId: number | undefined,
}

const deleteRecognition = ({ recognitionId }: deleteRecognitionProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/recognition/${recognitionId ?? ''}`;

  return deleteApi(serverUrl, {});
};
interface UseDeleteRecognitionProps {
  infiniteQueryParams: InfiniteQueryParamsProps,
}
interface RecognitionDeleteParams {
  recognitionId: number,
}

export const useDeleteRecognition = ({
  infiniteQueryParams,
}: UseDeleteRecognitionProps): UseMutateFunction<HttpCallReturn<void>, ErrorShape, RecognitionDeleteParams, { previousRecognitionListQuery: unknown; }> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: deleteRecognition,

    onMutate: async (recognitionToDelete: RecognitionDeleteParams) => {
      // Cancel any existing outbound queries
      await queryClient.cancelQueries({ queryKey: recognitionKeys.lists() });
      await queryClient.cancelQueries({ queryKey: recognitionKeys.detail(recognitionToDelete.recognitionId) });
      const previousRecognitionListQuery = queryClient.getQueryData(recognitionKeys.list({ ...infiniteQueryParams }));

      // eslint-disable-next-line max-len
      queryClient.setQueryData<InfiniteData<HttpCallReturn<RecognitionListResponse>, unknown>>(recognitionKeys.list({ ...infiniteQueryParams }), (oldData) => {
        if (oldData && oldData.pages.length > 0) {
          return produce(oldData, (draft) => {
            let recognitionIndex;
            const pageIndex = draft.pages.findIndex((page) => page.response.items.some((item, index) => {
              if (item.recognitionId === recognitionToDelete.recognitionId) {
                recognitionIndex = index;
                return true;
              }
              return false;
            }));
            if (recognitionIndex !== undefined && pageIndex !== undefined) {
              draft.pages[pageIndex].response.items.splice(recognitionIndex, 1);
            }
          });
        }

        return oldData;
      });
      toastId.current = toast.info('Deleting the recognition...', { autoClose: false });
      return {
        previousRecognitionListQuery,
      };
    },
    onError: (error: ErrorShape, _, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(recognitionKeys.list({ ...infiniteQueryParams }), snapshot?.previousRecognitionListQuery);

      const errorMessage = error?.status?.messages[0]?.message ?? 'There was an error deleting your recognition. Please try again.';
      toast.update(toastId.current, {
        render: errorMessage,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully deleted the recognition.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      void queryClient.invalidateQueries({ queryKey: recognitionKeys.all });
    },
  });

  return mutation.mutate;
};
