import { css } from '@emotion/react';
import LeadrCard from '~Common/V3/components/LeadrCard';

import { palette } from '~Common/styles/colors';
import {
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import LeadrTabs from '~Common/V3/components/LeadrTabs';
import Dropdown from '~Common/V3/components/Dropdown';
import {
  PEOPLE,
  // TEAM_DETAILS_GOALS, TODO: Coming later
} from '~Common/const/routes';
import { useLocation, useParams } from 'react-router-dom';
import { useTeamDetailsRowDisplayCount } from '~People/components/Teams/stores/useTeamDetailsRowDisplayCount';
import { rowsPerPageOptions } from '~Admin/const/defaults';
import { SelectChangeEvent } from '@mui/material';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useGetTeamClarityQuestions } from '~People/components/Teams/hooks/utils/useGetTeamClarityQuestions';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import TeamDetailsRouter from '~People/components/Teams/routes/TeamDetailsRouter';
import { useShowCreateEditTeamModal } from '~People/components/Teams/stores/useShowCreateEditTeamModal';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { TeamClarityQuestion } from '../../../const/types';
import TeamClarityQuestionDisplay from './TeamClarityQuestionDisplay';
import CreateEditTeamModal, { EditTeamInitialData } from '../../CreateEditTeamModal';
import NameHeader from './NameHeader';

const styles = {
  leadrCard: css({
    paddingBottom: '1.875rem',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  infoContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.625rem',
  }),
  infoInnerContainer: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  headerText: css({
    color: palette.brand.indigo,
    fontSize: '1.5rem',
    fontWeight: 600,
  }),
  editButton: css({
    display: 'flex',
  }),
  editIcon: css({
    fontSize: '.625rem',
  }),
  description: css({
    color: palette.neutrals.gray600,
    fontSize: '.875rem',
  }),
  teamClarityQuestions: css({
    marginTop: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.875rem',
  }),
  leadrTabs: css({
    marginTop: '1.5rem',
  }),
  paginationContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.375rem',
  }),
  dropdown: css({
    fontSize: '0.75rem',
    padding: '0.25rem',
    paddingLeft: '0.75rem',
    width: 'auto',

    '.MuiInput-root': {
      fontSize: '0.75rem',
      marginTop: 0,
    },
  }),
  teamsBody: css({
    marginTop: '1.5rem',
  }),
  skeletonLoader: css({
    minWidth: '25%',
    height: '1rem',
  }),
};

interface ViewProps {
  name: string,
  description: string,
  teamClarityQuestionList: TeamClarityQuestion[],
  hasTeamOwner: boolean,
  teamDetailsTabs: TeamDetailsTab[],
  activeTab: string,
  handleTabChange: (event: SyntheticEvent, tab: string) => void,
  rowsPerPage: number,
  handleRowsPerPageChange: (event: SelectChangeEvent<string>) => void,
  areTeamDetailsLoading: boolean,
  teamId: string,
  initialData?: EditTeamInitialData,
  showModal: boolean,
  isMobile: boolean,
}

const View = ({
  name,
  description,
  teamClarityQuestionList,
  hasTeamOwner,
  teamDetailsTabs,
  activeTab,
  handleTabChange,
  rowsPerPage,
  handleRowsPerPageChange,
  areTeamDetailsLoading,
  teamId,
  initialData,
  showModal,
  isMobile,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard css={styles.leadrCard} {...props}>
    {areTeamDetailsLoading && (
      <div>
        <MultipleSkeletonLoaders
          renderSkeletonItem={() => (
            <SkeletonLoader
              css={styles.skeletonLoader}
              renderComponent={() => <></>}
            />
          )}
          numberOfSkeletons={3}
        />
      </div>
    )}
    {!areTeamDetailsLoading && (
      <>
        <div css={styles.header}>
          <div css={styles.infoContainer}>
            <div css={styles.infoInnerContainer}>
              <NameHeader
                hasTeamOwner={hasTeamOwner}
                name={name}
              />
            </div>
            <div css={styles.infoInnerContainer}>
              <h3 css={styles.description}>{description}</h3>
            </div>
          </div>
          { /* TODO: Search, not MVP */ }
          {/* <LeadrSearchField
            css={styles.leadrSearchField}
            data-test-id="peopleTeamDetailsSearchField"
          /> */}
        </div>
        <div css={styles.teamClarityQuestions}>
          {teamClarityQuestionList.map((question) => (
            <TeamClarityQuestionDisplay
              key={question.rank}
              question={question.question}
              answer={question.answer}
            />
          ))}
        </div>
        <LeadrTabs
          css={styles.leadrTabs}
          value={activeTab}
          handleChange={handleTabChange}
          renderRightItem={isMobile ? undefined : () => (
            <div css={styles.paginationContainer}>
              <span>
                Showing:
              </span>
              <Dropdown
                css={styles.dropdown}
                data-test-id="teamsRowsDisplayed"
                items={rowsPerPageOptions}
                onChange={handleRowsPerPageChange}
                value={rowsPerPage.toString()}
              />
            </div>
          )}
        >
          {teamDetailsTabs.map((teamDetailTab) => (
            <LeadrTabs.LinkTab
              label={teamDetailTab.label}
              to={teamDetailTab.link}
              key={teamDetailTab.link}
              value={teamDetailTab.link}
              data-test-id={teamDetailTab['data-test-id']}
            />
          ))}
        </LeadrTabs>
      </>
    )}

    <div css={styles.teamsBody}>
      <TeamDetailsRouter teamId={teamId} />
      { /* Need this condition here to reset the internal state when the modal is closed */ }
      {showModal && (
        <CreateEditTeamModal
          initialData={initialData}
          isEditMode
        />
      )}
    </div>
  </LeadrCard>
);

interface TeamDetailsTab {
  label: string,
  link: string,
  'data-test-id': string,
}

const TeamDetailsCard = ({ ...props }): JSX.Element => {
  const { pathname } = useLocation();
  const { teamId } = useParams<{ teamId: string }>();
  const isMobile = useIsMobileQuery();

  const { data: teamDetails, isLoading } = useGetTeamDetails({ teamId });
  const { teamClarityQuestionListEdit, teamClarityQuestionList } = useGetTeamClarityQuestions({ teamId });
  const showModal = useShowCreateEditTeamModal((state) => state.showModal);

  let initialData;

  if (teamDetails?.response) {
    initialData = {
      ...teamDetails.response,
      teamId,
      teamClarityQuestionList: teamClarityQuestionListEdit,
    };
  }

  const name = teamDetails?.response.name ?? '';
  const description = teamDetails?.response.description ?? '';

  const ownerOrgUserIds = teamDetails?.response.ownerOrgUserIds ?? [];
  const hasTeamOwner = ownerOrgUserIds.length > 0;

  const [areTeamDetailsLoading] = useSkeletonLoaders(isLoading);

  const {
    count: rowsPerPage,
    setCount: setRowsPerPage,
  } = useTeamDetailsRowDisplayCount((state) => ({
    count: state.count,
    setCount: state.setCount,
  }));

  const handleRowsPerPageChange = (event: SelectChangeEvent<string>): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const teamDetailsTabs = useMemo(() => [
    {
      label: 'Users',
      // TODO: Properly inject teamId into TEAM_DETAILS_USERS
      link: `${PEOPLE}/teams/${teamId}/users`,
      'data-test-id': 'teamDetailsUsersTab',
    },
    // TODO: Coming later
    // {
    //   label: 'Goals',
    //   link: TEAM_DETAILS_GOALS,
    //   'data-test-id': 'teamDetailsGoalsTab',
    // },
  ], [teamId]);

  const defaultTab = teamDetailsTabs[0];

  const currentTeamDetailsTab = useMemo(() => {
    const matchedSettingsTabValue = teamDetailsTabs.find((teamDetailTab) => pathname.includes(teamDetailTab.link));
    return matchedSettingsTabValue || defaultTab;
  }, [defaultTab, pathname, teamDetailsTabs]);

  const [activeTab, setActiveTab] = useState(currentTeamDetailsTab.link);

  const handleTabChange = (event: SyntheticEvent, newTab: string): void => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    // Ensure that the proper tab is selected, when navigating from the modal or directly to the page
    if (activeTab !== currentTeamDetailsTab.link) {
      setActiveTab(currentTeamDetailsTab.link);
    }
  }, [activeTab, setActiveTab, currentTeamDetailsTab]);

  const hookProps = {
    name,
    description,
    teamClarityQuestionList,
    hasTeamOwner,
    teamDetailsTabs,
    activeTab,
    handleTabChange,
    rowsPerPage,
    handleRowsPerPageChange,
    areTeamDetailsLoading,
    teamId,
    initialData,
    showModal,
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TeamDetailsCard;
