import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import EmptySearch from '~Common/components/EmptyStates/EmptySearch';

const styles = {
  queryText: css({
    color: palette.brand.indigo,
    fontWeight: 600,
  }),
};

interface EmptyStateProps {
  query: string,
}

const EmptyState = ({
  query,
  ...props
}: EmptyStateProps): JSX.Element => (
  <EmptySearch
    renderText={() => (
      <>
        {query && (
          <>
            No results for&nbsp;
            <span css={styles.queryText}>{`"${query}"`}</span>
            .&nbsp;Please try a different search term.
          </>
        )}
        {!query && (
          <>Search Meetings</>
        )}
      </>
    )}
    {...props}
  />
);

export default EmptyState;
