import { css } from '@emotion/react';
import {
  ComponentProps,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCreateActionItemComment } from '~ActionItems/hooks/useCreateActionItemComment';
import { useEditActionItemComment } from '~ActionItems/hooks/useEditActionItemComment';

import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Note, NoteContextType } from '~Common/const/interfaces';
import { useDraft } from '~Common/hooks/useDraft';
import useFroala from '~Common/hooks/useFroala';
import { useUpdateEffect } from '~Common/hooks/useUpdateEffect';
import { palette } from '~Common/styles/colors';
import { getOrganizationId, getOrganizationUserId, removeStorageItem } from '~Common/utils/localStorage';

const styles = {
  editor: css({
    marginBottom: '1.5rem',

    '.fr-box.fr-basic .fr-wrapper': {
      background: `${palette.neutrals.white} !important`,
    },
    '.fr-toolbar, .fr-second-toolbar': {
      background: `${palette.neutrals.white} !important`,
    },
    '.fr-element': {
      padding: '0 0.75rem !important',
    },
    '.fr-btn-grp': {
      margin: '0 0.75rem !important',
    },
  }),
  editorLabel: css({
    background: `${palette.neutrals.white} !important`,
    padding: '0.5rem 0.75rem',
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  saveButton: css({
    marginRight: '0.5rem',
  }),
};

export interface CommentFormData {
  comment: string,
}

export interface ViewProps extends Omit<ComponentProps<'div'>, 'onSubmit'> {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void,
  FroalaEditor: JSX.Element,
  isEdit: boolean,
  shouldShowCancel: boolean,
  shouldDisableSave: boolean,
  onCancel: () => void,
}

const View = ({
  onSubmit,
  FroalaEditor,
  isEdit,
  onCancel,
  shouldShowCancel,
  shouldDisableSave,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <form onSubmit={onSubmit}>
      {FroalaEditor}

      <div css={styles.buttonContainer}>
        <LeadrButton
          css={styles.saveButton}
          data-test-id="actionItemSubmitComment"
          type="submit"
          variant={isEdit ? 'default' : 'ghost'}
          size="small"
          color="primary"
          disabled={shouldDisableSave}
        >
          {isEdit ? 'Save Changes' : 'Add Comment'}
        </LeadrButton>

        {shouldShowCancel && (
          <LeadrButton
            data-test-id="actionItemCancelComment"
            type="button"
            onClick={onCancel}
            variant="ghost"
            size="small"
            color="primary"
          >
            Cancel
          </LeadrButton>
        )}
      </div>
    </form>
  </div>
);

export interface ActionItemComments extends Omit<ComponentProps<'div'>, 'onSubmit'> {
  actionItemId: string,
  comment?: Note,
  onCancelEdit: () => void,
  onActiveChange?: (isActive: boolean) => void;
}

const AddEditComment = ({
  actionItemId,
  comment,
  onCancelEdit,
  onActiveChange,
  ...props
}: ActionItemComments): JSX.Element => {
  const orgId = getOrganizationId();
  const orgUserId = getOrganizationUserId();
  const { buildDraftKey } = useDraft();
  const [hasFocus, setHasFocus] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const draftKey = useMemo(() => ([orgId!, orgUserId!, actionItemId, 'comment']), [
    orgId,
    orgUserId,
    actionItemId,
  ]);

  const { mutate: doAddComment } = useCreateActionItemComment();
  const { mutate: doEditComment } = useEditActionItemComment();

  const onFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setHasFocus(false);
  }, []);

  const {
    value: froalaValue,
    setValue: setFroalaValue,
    Froala: FroalaEditor,
    removeDraft,
    // @ts-expect-error Needs fixed once this is typed
  } = useFroala({
    name: 'comment',
    froalaConfigProps: {
      charCounterCount: true,
      charCounterMax: 5000,
      onFocus,
      onBlur,
    },
    richTextEditorProps: {
      name: 'comment',
      draftKey: comment ? undefined : draftKey,
      initialValue: comment?.text,
    },
    styleOverrides: styles.editor,
    labelStyleOverrides: styles.editorLabel,
  });

  // Need this to not run on initial mount, or we might lose a local draft value
  useUpdateEffect(() => {
    if (comment) {
      // Since we pass draftKey as undefined when a comment is being edited
      // We need to clear the draft from local storage directly as removeDraft
      // will no longer "point" to the draft in question
      const draftKeyString = buildDraftKey(draftKey);
      removeStorageItem(draftKeyString);
      setFroalaValue(comment.text);
    } else {
      setFroalaValue('');
    }
  }, [setFroalaValue, comment, removeDraft, draftKey, buildDraftKey]);

  useEffect(() => {
    const hasValue = !!froalaValue;
    const isEdit = !!comment;

    onActiveChange?.(hasFocus || hasValue || isEdit);
  }, [hasFocus, froalaValue, comment, onActiveChange]);

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const commentFormData = Object.fromEntries(formData.entries()) as unknown as CommentFormData;

    if (comment) {
      setFroalaValue('');
      onCancelEdit();
      doEditComment({
        id: comment.id,
        context: { id: actionItemId, type: NoteContextType.ActionItem },
        note: { text: commentFormData.comment, rank: comment.rank },
      });
    } else {
      removeDraft();
      setFroalaValue('');
      doAddComment({
        context: { id: actionItemId, type: NoteContextType.ActionItem },
        note: { text: commentFormData.comment, rank: 0 },
      });
    }
  }, [
    doAddComment,
    doEditComment,
    comment,
    actionItemId,
    removeDraft,
    setFroalaValue,
    onCancelEdit,
  ]);

  const isEdit = useMemo(() => (!!comment), [comment]);

  const shouldShowCancel = useMemo(() => (
    !!froalaValue || isEdit
  ), [froalaValue, isEdit]);

  const onCancel = useCallback(() => {
    if (isEdit) {
      onCancelEdit();
    } else {
      removeDraft();
      setFroalaValue('');
    }
  }, [isEdit, onCancelEdit, removeDraft, setFroalaValue]);

  const hookProps = {
    onSubmit,
    FroalaEditor,
    isEdit,
    shouldShowCancel,
    shouldDisableSave: !froalaValue,
    onCancel,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AddEditComment;
