import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import { forDesktopObject, withoutDesktopObject } from '~Common/styles/mixins';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Link } from 'react-router-dom';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { Goals } from '@leadr-hr/types';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { useMemo, useState } from 'react';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import {
  teamMeetingGoalContextTypes, INITIAL_STATIC_TEAM_GOALS_PARAMS,
} from '~Meetings/const/goals';
import { SelectChangeEvent } from '@mui/material';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import ReactiveSortByDropdown from '~Meetings/components/details/goals/filters/ReactiveSortByDropdown';
import ReactiveGoalContextTypeDropdown from '~Meetings/components/details/goals/filters/ReactiveGoalContextTypeDropdown';
import MeetingGoalCard from './MeetingGoalCard';

const styles = {
  teamGoals: css({
    padding: '1rem 1.75rem',
  }, withoutDesktopObject({
    boxShadow: 'none',
    padding: '1rem',
  }), forDesktopObject({
    backgroundColor: palette.neutrals.white,
    boxShadow: '0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.07)',
    borderRadius: '0.5rem',
  })),

  header: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.75rem',
    justifyContent: 'space-between',
  }),

  title: css({
    flex: 1,
    fontSize: '1.125rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
  }),

  divider: css({
    margin: '0 0 0.75rem 0',
  }),

  cardContainer: css({
    display: 'flex',
    overflowX: 'auto',

    // Needed so the box shadow of the goal cards isn't cut off
    padding: '0.5rem',
  }),

  goalCard: css({
    marginRight: '0.625rem',
  }),

  emptyStateButton: css({
    padding: 0,
    fontStyle: 'italic',
  }),

  emptyStateMessage: css({
    fontStyle: 'italic',
    color: palette.neutrals.gray700,
  }),
  filters: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
  }),
  dropdown: css({
    width: 'min-content',
  }),
};

interface ViewProps {
  goals?: Goals.Goal[] | Goals.GoalWithCascading[],
  isLoading: boolean,
  selectedContextType: Goals.GoalContextType | string,
  onContextTypeChange: (event: SelectChangeEvent<Goals.GoalContextType | string>) => void,
  sortBy: Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title,
  onSortByChange: (event: SelectChangeEvent<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>) => void,
  cascadingGoals: boolean,
  shouldShowActionableEmptyState?: boolean,
  isUserOnTeam?: boolean,
}

const View = ({
  isLoading,
  goals = [],
  shouldShowActionableEmptyState,
  isUserOnTeam,
  selectedContextType,
  onContextTypeChange,
  sortBy,
  onSortByChange,
  cascadingGoals,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.teamGoals}
    {...props}
  >
    <header css={styles.header}>
      <div css={styles.title}>
        {`Goals ${goals.length ? `(${goals.length})` : ''}`}
      </div>
      {cascadingGoals && (
        <div css={styles.filters}>
          <ReactiveSortByDropdown
            sortBy={sortBy}
            onSortByChange={onSortByChange}
          />
          <ReactiveGoalContextTypeDropdown
            items={teamMeetingGoalContextTypes}
            selectedContextType={selectedContextType}
            onContextTypeChange={onContextTypeChange}
          />
        </div>
      )}
    </header>

    <hr css={styles.divider} />

    <div>
      { isLoading && (
        <div css={styles.cardContainer}>
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
          <MeetingGoalCard.Skeleton css={styles.goalCard} />
        </div>
      )}

      {!isLoading && (
        <>
          {!!goals.length && (
            <div css={styles.cardContainer}>
              { goals.map((goal) => {
                if ('totalChildGoals' in goal) {
                  return (
                    <MeetingGoalCard
                      key={goal.goalId}
                      css={styles.goalCard}
                      canAccessGoal={isUserOnTeam}
                      goalId={goal.goalId}
                      title={goal.title}
                      status={goal.statusUpdates[goal.statusUpdates.length - 1].status}
                      category={goal.category}
                      startTime={goal.startTimeInMillis}
                      endTime={goal.endTimeInMillis}
                      totalChildGoals={goal.totalChildGoals}
                    />
                  );
                }
                return (
                  <MeetingGoalCard
                    key={goal.goalId}
                    css={styles.goalCard}
                    canAccessGoal={isUserOnTeam}
                    goalId={goal.goalId}
                    title={goal.title}
                    status={goal.statusUpdates[goal.statusUpdates.length - 1].status}
                    category={goal.category}
                    startTime={goal.startTimeInMillis}
                    endTime={goal.endTimeInMillis}
                    totalChildGoals={0}
                  />
                );
              })}
            </div>
          )}

          {!goals.length && (
            <>
              {shouldShowActionableEmptyState && (
                <div>
                  <LeadrButton
                    data-test-id="teamGoalsEmptyState"
                    css={styles.emptyStateButton}
                    variant="text"
                    component={Link}
                    to={GoalsRoutes.Create}
                  >
                    Add Team Goals
                  </LeadrButton>

                  <span css={styles.emptyStateMessage}>
                    &nbsp;for this team
                  </span>
                </div>
              )}

              {!shouldShowActionableEmptyState && (
                <span css={styles.emptyStateMessage}>
                  Team Goals not created
                </span>
              )}
            </>
          )}
        </>
      )}
    </div>
  </div>
);

interface TeamGoalsProps {
  teamId: string,
  huddleId: string,
}

const TeamGoals = ({
  teamId,
  huddleId,
  ...props
}: TeamGoalsProps): JSX.Element => {
  const orgUserId = getOrganizationUserId();
  const { isAdmin } = useUserPermissions();
  const [selectedContextType, setSelectedContextType] = useState<Goals.GoalContextType | string>(Goals.GoalContextType.Team);
  const [sortBy, setSortBy] = useState<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>(Goals.GetGoalsSortBy.DueDate);
  const { data: teamDetailsData, isLoading: isTeamDetailsLoading } = useGetTeamDetails({ teamId, huddleId });
  const { orgLevelEnableCascadingGoals } = useEnableCascadingGoals();
  const cascadingGoals = useFeatureFlag('cascadingGoals');

  const onContextTypeChange = (event: SelectChangeEvent<Goals.GoalContextType | string>): void => {
    setSelectedContextType(event.target.value);
  };

  const onSortByChange = (event: SelectChangeEvent<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>): void => {
    setSortBy(event.target.value as Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title);
  };

  const params: Goals.Requests.GetGoalsRequestQueryParameters = {
    ...INITIAL_STATIC_TEAM_GOALS_PARAMS,
    ...(selectedContextType === Goals.GoalContextType.Team ? { contextId: teamId } : {}),
    contextType: selectedContextType,
    enableCascading: orgLevelEnableCascadingGoals,
    sortBy,
  };

  const { data, isLoading: isGoalsLoading } = useGetGoals({ params });

  const shouldShowActionableEmptyState = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    isAdmin || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    isAdmin,
    orgUserId,
    teamDetailsData?.response.ownerOrgUserIds,
  ]);

  const [isLoading] = useSkeletonLoaders(isTeamDetailsLoading || isGoalsLoading);

  const isUserOnTeam = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    teamDetailsData?.response.memberOrgUserIds.includes(orgUserId!)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    orgUserId,
    teamDetailsData?.response,
  ]);

  const goals = data?.response ?? [];

  const hookProps = {
    goals,
    isLoading,
    shouldShowActionableEmptyState,
    isUserOnTeam,
    selectedContextType,
    onContextTypeChange,
    sortBy,
    onSortByChange,
    cascadingGoals,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TeamGoals;
