import { useMemo } from 'react';
import { ParticipantTypeEnum, PersonDisplayInformationReviews, ReviewShape } from '~Reviews/V2/Const/types';

function getParticipantReviewType(review: ReviewShape): PersonDisplayInformationReviews | Record<string, never> {
  const { participants } = review;

  const findRevieweeParticipant = participants.find((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.Reviewee));

  return findRevieweeParticipant ?? {};
}

export const useReturnReviewee = (fullReview: ReviewShape | undefined): PersonDisplayInformationReviews | Record<string, never> => {
  const participantReviewType = useMemo(
    () => (fullReview ? getParticipantReviewType(fullReview) : {}),
    [fullReview],
  );

  return participantReviewType;
};
