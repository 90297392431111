import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

export enum AuditEventDirection {
  Outbound = 'Outbound',
  Inbound = 'Inbound',
}

export enum AuditEventWorkflow {
  Create = 'MeetingOrEventCreate',
  Update = 'MeetingOrEventUpdate',
  JobSuccess = 'JobSucceeded',
  Delete = 'MeetingOrEventDelete'
}

export interface AuditEvent {
  id: number,
  auditEventDate: Date,
  dgraphMeetingFactoryId: string,
  nylasEventId: string,
  direction: AuditEventDirection,
  workflow: AuditEventWorkflow,
  success: boolean,
  errorMessage?: string,
  urlUsed: string,
  rawJson: string
}

export interface AuditTrail {
  data: AuditEvent[],
}

const getAuditTrail = async (
  organizationId: string | null | undefined,
  factoryId: string,
  count: number,
): Promise<HttpCallReturn<AuditEvent[]>> => {
  const URL = `/organizations/${organizationId ?? ''}/calendarIntegration/auditTrail/${factoryId}/${count}`;

  return getApi<AuditEvent[]>(URL, {});
};

interface UseAuditTrailInterface {
  factoryId?: string,
  count?: number,
}

const useAuditTrail = ({ factoryId = '', count = 20 }: UseAuditTrailInterface): UseQueryResult<HttpCallReturn<AuditEvent[]>> => {
  const organizationId = getOrganizationId();
  const frontendPollingPollingFrequency = useFeatureFlag<number>('frontendPollingPollingFrequency');

  return useQuery({
    queryKey: [organizationId, 'auditTrail', factoryId, count],
    queryFn: () => getAuditTrail(organizationId, factoryId, count),
    staleTime: frontendPollingPollingFrequency || 1000 * 60,
    enabled: !!factoryId,
  });
};

export default useAuditTrail;
