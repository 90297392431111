import { css } from '@emotion/react';
import { useGetPDPForMeetings } from '~DevelopmentPlan/hooks/useGetPDPForMeetings';
import { BasicPdp } from '~DevelopmentPlan/const/types';
import LeadrCard from '~Common/V3/components/LeadrCard';
import { palette } from '~Common/styles/colors';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import MeetingPdpTimeline from './MeetingPdpTimeline';
import PdpDropdown from './PdpDropdown';

const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1rem',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
  }),
  title: css({
    fontSize: '1.125rem',
    fontWeight: '600',
    color: palette.neutrals.gray800,
    width: '80%',
  }),
  dropdownWrap: css({
    flex: '1 0 18.75rem',
  }),
  leadrCard: css({
    marginBottom: '1.5rem',
  }),
  listSection: css({
    width: '100%',
    marginBottom: '1.5rem',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
  }),
};

interface ViewProps {
  isLoading: boolean,
  pdps: BasicPdp[] | undefined,
}

const View = ({
  pdps,
  isLoading,
}: ViewProps): JSX.Element => (
  <>
    {isLoading && (
    <MultipleSkeletonLoaders
      css={styles.listSection}
      numberOfSkeletons={1}
      renderSkeletonItem={() => (
        <CardSkeleton css={styles.cardSkeleton} />
      )}
    />
    )}
    {!isLoading && pdps && pdps?.length > 0 && (
    <>
      <LeadrCard
        css={styles.leadrCard}
      >
        <div css={styles.wrapper}>
          <div
            css={styles.title}
          >
            Development Plan
          </div>

          <div css={styles.dropdownWrap}>
            <PdpDropdown
              name="meetingPdpIdSelect"
              pdps={pdps}
            />
          </div>

        </div>
        <MeetingPdpTimeline />
      </LeadrCard>
    </>
    )}
  </>
);

interface MeetingPDPProps {
  otherUserInMeeting: string,
}

export const MeetingPDP = ({
  otherUserInMeeting,
}: MeetingPDPProps): JSX.Element => {
  const { data: pdps, isLoading } = useGetPDPForMeetings({ id: otherUserInMeeting });

  const hookProps = {
    pdps,
    isLoading,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
