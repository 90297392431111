import { useGenericStore } from '~Common/hooks/useGenericStore';

export const useSurveysSearch = () => {
  const { setValue: setSearch, value: searchText } = useGenericStore({
    module: 'leadr',
    workflow: 'surveys',
    feature: 'surveys-search',
    defaultValue: '',
  });

  return {
    setSearch,
    searchText,
  };
};
