import { css } from '@emotion/react';
import DrawerInput from '~Common/V3/components/DrawerInput';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import LearningTemplateCategoryButtons from '~Learning/components/LearningLibrary/LearningTemplateCategoryButtons';
import QuestionsList from '~Learning/components/Shared/QuestionList';
import TitleAndLink from '~Learning/components/Shared/TitleAndLink';
import { IframelyErrorMessage, FrontendRankedQuestion } from '~Learning/const/interfaces';
import { LearningTemplateDetail } from '~Learning/hooks/templates/singleContent/useGetLearningTemplate';
import { useUpdateLearningTemplate } from '~Learning/hooks/templates/singleContent/useUpdateLearningTemplate';
import { useCreateContentDetails } from '~Learning/hooks/utils/useCreateContentDetails';

const styles = {
  categorySection: css({
    marginTop: '2.375rem',
  }),
  buttonsContainer: css({
    marginTop: '1.125rem',
  }),
  saveButton: css({
    marginRight: '.625rem',
  }),
};

interface ViewProps {
  contentTitle?: string,
  setContentTitle: (title: string) => void,
  contentLink?: string,
  setContentLinkError: (error: IframelyErrorMessage) => void,
  questions?: FrontendRankedQuestion[],
  setQuestions: (questions: FrontendRankedQuestion[]) => void,
  onCancelEdit: () => void,
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
  categoryId: string,
  sourceTitle?: string,
  canSetSourceTitle: boolean,
}

const View = ({
  contentTitle = '',
  contentLink = '',
  questions = [],
  setContentTitle,
  setContentLinkError,
  setQuestions,
  onCancelEdit,
  onSubmit,
  categoryId,
  sourceTitle,
  canSetSourceTitle,
}: ViewProps): JSX.Element => (
  <form onSubmit={onSubmit}>
    <TitleAndLink
      contentTitle={contentTitle}
      contentLink={contentLink}
      setContentTitle={setContentTitle}
      setContentLinkError={setContentLinkError}
    />
    {canSetSourceTitle && (
      <DrawerInput
        name="sourceTitle"
        label="Source Title"
        required
        maxLength={75}
        initialValue={sourceTitle}
      />
    )}
    <QuestionsList
      questions={questions}
      setQuestions={setQuestions}
    />
    <LearningTemplateCategoryButtons
      css={styles.categorySection}
      categoryId={categoryId}
    />
    <div css={styles.buttonsContainer}>
      <LeadrButton
        type="submit"
        css={styles.saveButton}
        data-test-id="learningLibraryEditTemplateSave"
      >
        Save
      </LeadrButton>
      <LeadrButton
        variant="ghost"
        onClick={onCancelEdit}
        data-test-id="learningLibraryEditTemplateCancel"
      >
        Cancel
      </LeadrButton>
    </div>
  </form>
);

interface EditProps {
  learningTemplate: LearningTemplateDetail,
  onCancelEdit: () => void,
  page: number
  count: number,
  curated: boolean,
}

const EditView = ({
  learningTemplate,
  onCancelEdit,
  page,
  count,
  curated,
  ...props
}: EditProps): JSX.Element => {
  const updateLearningTemplateMutation = useUpdateLearningTemplate({
    onMutateCallback: () => {
      onCancelEdit();
    },
    page,
    count,
    curated,
    categoryId: learningTemplate.categoryId,
  });

  const {
    canSetSourceTitle = false,
    sourceTitle = '',
  } = learningTemplate;

  const {
    onSubmit,
    questions,
    setQuestions,
    contentTitle,
    setContentTitle,
    contentLink,
    setContentLinkError,
    categoryId,
    setCategoryId,
  } = useCreateContentDetails({
    initialQuestions: learningTemplate?.questions?.map((question) => (
      { rank: parseInt(question.rank, 10), text: question.questionText, questionId: question.questionId }
    )),
    initialContentLink: learningTemplate?.contentURL,
    initialContentTitle: learningTemplate?.title,
    initialTemplateCategoryId: learningTemplate?.categoryId,
    afterSubmitValidationCallback: ({
      contentTitle: contentTitleFromForm,
      contentLink: contentLinkFromForm,
      categoryId: categoryIdFromForm,
      sourceTitle: sourceTitleFromForm,
    }) => {
      const editedLearningTemplate = {
        templateId: learningTemplate.templateId,
        contentURL: contentLinkFromForm,
        title: contentTitleFromForm,
        categoryId: categoryIdFromForm,
        ...(canSetSourceTitle && sourceTitleFromForm && { sourceTitle: sourceTitleFromForm }),
        questions,
      };

      updateLearningTemplateMutation(editedLearningTemplate);
    },
  });

  const hookProps = {
    contentTitle,
    contentLink,
    questions,
    onSubmit,
    setContentTitle,
    setContentLinkError,
    setQuestions,
    onCancelEdit,
    categoryId,
    setCategoryId,
    canSetSourceTitle,
    sourceTitle,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };

export default EditView;
