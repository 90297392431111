import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'react';
import { faListCheck } from '@fortawesome/pro-light-svg-icons';
import { palette, hexToRGBA } from '~Common/styles/colors';

const styles = {
  playlistOverlay: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '40%',
    color: palette.neutrals.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius: '.625rem',
    borderBottomRightRadius: '.625rem',
    backgroundColor: hexToRGBA(palette.brand.indigo, 0.9),
  }),
  contentCountText: css({
    fontWeight: 'bold',
    fontSize: '1.25rem',
  }),
};

interface PlaylistOverlayProps extends ComponentProps<'div'> {
  numberOfContents: number,
}

const PlaylistOverlay = ({
  numberOfContents,
  ...props
}: PlaylistOverlayProps): JSX.Element => (
  <div css={styles.playlistOverlay} {...props}>
    <div css={styles.contentCountText}>
      {numberOfContents}
    </div>
    <FontAwesomeIcon icon={faListCheck} size="2x" />
  </div>
);

export default PlaylistOverlay;
