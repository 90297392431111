import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import { useGetReceivedLearningDetail } from '~Learning/hooks/received/useGetReceivedLearningDetail';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { QuestionResponse, ReceivedLearningWorkflow } from '~Learning/const/interfaces';
import { useSubmitSingleLearningResponses } from '~Learning/hooks/received/useSubmitSingleLearningResponses';
import ContentTab from './ContentTab';
import DetailsTab from './DetailsTab';

const styles = {
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
  drawerBody: css({}),
  cardSkeleton: css({
    minWidth: '100%',
    height: '12.5rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
  bottomButton: css({
    marginTop: '1.125rem',
  }),
};

export const receivedLearningDrawerTemplate = {
  name: 'RECEIVED_LEARNING_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

interface ReceivedLearningDrawerState {
  workflow: ReceivedLearningWorkflow,
}

interface ReceivedLearningDrawerProps extends DrawerProps<ReceivedLearningDrawerState> {
  learningId: string,
  isReadOnly: boolean,
  showAddToMeetingButton?: boolean,
  learningOwnerId?: string,
  renderBottomButton?: (bottomButtonStyles: SerializedStyles) => JSX.Element,
}

const ReceivedLearningDrawer = ({
  learningId,
  isReadOnly,
  showAddToMeetingButton = true,
  learningOwnerId,
  renderBottomButton,
  popDrawer,
  setDrawerState,
}: ReceivedLearningDrawerProps): JSX.Element => {
  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      workflow: ReceivedLearningWorkflow.SINGLE_CONTENT,
    }));
  }, [setDrawerState]);

  const { detail: learning, isLoading } = useGetReceivedLearningDetail({
    learningId,
    asReadOnly: isReadOnly,
    viewPerspectiveUid: learningOwnerId,
  });

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: receivedLearningDrawerTemplate.name });
  };

  const [activeTab, setActiveTab] = useState(0);

  const submitResponses = useSubmitSingleLearningResponses({ learningId });

  const hookProps = {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
      const formData = new FormData(event.currentTarget);

      const dataForApiCalls = Array.from(formData.entries()).map(([questionId, responseText]) => ({ questionId, responseText })) as QuestionResponse[];

      const learningData = {
        learningId,
        responses: dataForApiCalls,
      };

      submitResponses({ learningId, learningData });
    },
    renderHeader: () => (
      <TabDrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            css={closeButtonStyles}
            tooltip="Close"
            size="large"
          />
        )}
        title="View Learning"
        renderTabNav={(tabNavStyles: SerializedStyles) => (
          <div css={tabNavStyles}>
            <TabNavItem
              css={styles.tabNavItem}
              onClick={() => setActiveTab(0)}
              isActive={activeTab === 0}
              renderNavItem={() => (
                <div>Content</div>
              )}
            />
            <TabNavItem
              isActive={activeTab === 1}
              onClick={() => setActiveTab(1)}
              renderNavItem={() => (
                <>Details</>
              )}
            />
          </div>
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {isLoading && (
        <MultipleSkeletonLoaders
          numberOfSkeletons={3}
          renderSkeletonItem={() => (
            <CardSkeleton css={styles.cardSkeleton} />
          )}
        />
        )}
        {(!isLoading && learning) && (
          <div css={styles.drawerBody}>
            {activeTab === 0 && (
              <ContentTab
                isReadOnly={isReadOnly}
                questions={learning.questions}
                contentUrl={learning.contentUrl}
                learningId={learningId}
                assignerId={learning.assigner.id}
                showAddToMeetingButton={showAddToMeetingButton}
              />
            )}
            {activeTab === 1 && (
              <DetailsTab
                title={learning.title}
                introduction={learning.introduction}
                dueDate={learning.dueDate}
                status={learning.status}
              />
            )}
            {renderBottomButton?.(styles.bottomButton)}
          </div>
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: receivedLearningDrawerTemplate.name,
  component: ReceivedLearningDrawer,
});

export default ReceivedLearningDrawer;
