import { css } from '@emotion/react';
import { RecursiveObject } from '~Meetings/stores/useMeetingRealtimeStore';
import { AgendaCreatePayload, AgendaTopicRealTimeMessageType } from '~Meetings/const/realTimeMeetingMessages';
import { useShowAgendaSection } from '~Meetings/components/topic-suggestions/stores/useShowAgendaSection';
import { useCallback } from 'react';
import useMeetingRealtime from '~Meetings/hooks/useMeetingRealtime';
import { useCreateAgendaItem } from '~Meetings/hooks/v2/useCreateAgendaItem';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useAgendaSectionCreateTopicRealtimeEvents } from '~Meetings/hooks/utils/useAgendaSectionCreateTopicRealtimeEvents';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import RealtimeCreationCard from '../RealtimeCreationCard';
import OnePersonCreatingItem from '../RealtimeCreationCard/OnePersonCreatingItem';
import CreateAgendaItem, { OnSaveProps } from '../CreateAgendaItem';

const styles = {
  realtimeCreationCard: css({
    margin: '.375rem 0',
  }),
  temp: css({
    marginBottom: '1.25rem',
  }),
};

interface ViewProps {
  realtimeCreatingAgenda: AgendaCreatePayload[] | RecursiveObject<AgendaCreatePayload[]>,
  showNewAgendaTopicEditor: boolean,
  onSaveNewAgendaTopic: (props: OnSaveProps) => void,
  onCancelNewAgendaTopic: () => void,
  allowRecurringTopic: boolean,
  agendaItemDraftKey: string[],
  huddleId: string,
  canAddAttachments: boolean,
}

const View = ({
  realtimeCreatingAgenda,
  showNewAgendaTopicEditor,
  onSaveNewAgendaTopic,
  onCancelNewAgendaTopic,
  allowRecurringTopic,
  agendaItemDraftKey,
  huddleId,
  canAddAttachments,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    {/* @ts-expect-error ToDo: Fix */}
    {realtimeCreatingAgenda?.data?.map(({ creator, type: agendaType }) => ( // eslint-disable-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      <RealtimeCreationCard css={styles.realtimeCreationCard} key={creator.firstName}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <OnePersonCreatingItem person={creator} agendaType={agendaType} />
      </RealtimeCreationCard>
    ))}
    {showNewAgendaTopicEditor && (
      <CreateAgendaItem
        onCancel={onCancelNewAgendaTopic}
        onSave={onSaveNewAgendaTopic}
        showRecurringButton={allowRecurringTopic}
        showPrivateButton
        draftKey={agendaItemDraftKey}
        huddleId={huddleId}
        showAddAttachmentsButton={canAddAttachments}
      />
    )}
  </div>
);

interface AgendaSectionFooterProps {
  sectionId: string,
  huddleId: string,
  realtimeCreatingAgenda: AgendaCreatePayload[] | RecursiveObject<AgendaCreatePayload[]>,
  allowRecurringTopic: boolean,
  canAddAttachments: boolean,
}

const AgendaSectionFooter = ({
  sectionId,
  huddleId,
  canAddAttachments,
  ...props
}: AgendaSectionFooterProps): JSX.Element => {
  const organizationId = getOrganizationId();
  const agendaItemDraftKey = [organizationId!, huddleId, 'agendaSection', sectionId, 'agendaItem', 'create'];
  const {
    getAgendaSectionRealtimeEventId,
    removeAgendaSection,
  } = useAgendaSectionCreateTopicRealtimeEvents((state) => ({
    getAgendaSectionRealtimeEventId: state.getAgendaSectionRealtimeEventId,
    removeAgendaSection: state.removeAgendaSection,
  }));

  const eventId = getAgendaSectionRealtimeEventId(sectionId);

  const { sendMessage } = useMeetingRealtime({ huddleId });

  const {
    hideItemEditor,
    getIsItemEditorVisible,
  } = useShowItemEditor((state) => ({
    hideItemEditor: state.hideItemEditor,
    getIsItemEditorVisible: state.getIsItemEditorVisible,
  }));

  const newAgendaTopicEditorKey = `${sectionId}-create`;
  const showNewAgendaTopicEditor = getIsItemEditorVisible(newAgendaTopicEditorKey);

  const {
    getIsAgendaSectionExpanded,
  } = useShowAgendaSection((state) => ({
    getIsAgendaSectionExpanded: state.getIsAgendaSectionExpanded,
  }));

  const showAgendaSectionFooter = getIsAgendaSectionExpanded(sectionId);
  const { mutate: createAgendaItem } = useCreateAgendaItem({ draftKey: agendaItemDraftKey, agendaSectionId: sectionId });

  const onSaveNewAgendaTopic = useCallback(({
    text,
    isPrivate,
    isRecurring,
    attachments,
  }: OnSaveProps) => {
    createAgendaItem({
      huddleId,
      text,
      isPrivate,
      isRecurring,
      sectionId,
      eventId,
      attachments,
    });
  }, [createAgendaItem, huddleId, sectionId, eventId]);

  const onCancelNewAgendaTopic = useCallback(() => {
    sendMessage(AgendaTopicRealTimeMessageType.CreateCanceled, {
      eventId,
      parentIds: [
        sectionId,
      ],
    });

    hideItemEditor(newAgendaTopicEditorKey);
    removeAgendaSection(sectionId);
  }, [eventId, hideItemEditor, newAgendaTopicEditorKey, removeAgendaSection, sectionId, sendMessage]);

  if (!showAgendaSectionFooter) {
    return <div css={styles.temp} />;
  }

  const hookProps = {
    showNewAgendaTopicEditor,
    onSaveNewAgendaTopic,
    onCancelNewAgendaTopic,
    agendaItemDraftKey,
    huddleId,
    canAddAttachments,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaSectionFooter;
