import {
  array, InferType, object, string,
} from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const editReviewTemplateFormSchema = object({
  name: string().required().trim(),
  description: string().ensure(),
  topics: array().required(),
});

export const editReviewTemplateFormResolver = yupResolver(editReviewTemplateFormSchema);

export type FormValues = InferType<typeof editReviewTemplateFormSchema>;
