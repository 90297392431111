import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import {
  deleteApi,
} from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';

const recallIndividualFeedback = ({ feedback, recallForUsers }) => {
  const URL = {
    host: hosts.feedback,
    uri: `/organizations/${getOrganizationId()}/feedbacks/${feedback.id}`,
  };
  return deleteApi(URL, { recallForUsers }, {});
};

export const useRecallIndividualFeedback = ({ queryKey = [getOrganizationId(), 'feedback'] }) => {
  const toastId = useRef(null);

  const mutation = useMutation({
    mutationFn: recallIndividualFeedback,
    onMutate: () => {
      toastId.current = toast.info("Recalling the user's feedback request...", { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: "There was an error recalling the user's feedback request. Please try again",
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: "Succesfully recalled the user's feedback request.",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
      queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
  });

  return mutation.mutate;
};
