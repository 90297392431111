import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { produce } from 'immer';
import { toast } from '~Common/components/Toasts';
import {
  DeleteMeetingTemplateResponse,
  MeetingTemplateType,
} from '~Meetings/components/topic-suggestions/const/types';
import { meetingTopicTemplatesQueryKeys } from './queryKeys';
import { GetMeetingTemplatesResponse } from './useGetCustomMeetingTemplates';

interface DeleteMeetingTemplateParams {
  id: string,
}

const deleteMeetingTemplate = ({
  id,
}: DeleteMeetingTemplateParams): Promise<HttpCallReturn<DeleteMeetingTemplateResponse>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates/${id}`;

  return deleteApi<DeleteMeetingTemplateResponse>(URL);
};

interface UseDeleteMeetingTemplateParams {
  meetingTemplateType: MeetingTemplateType,
  onMutateCallback?: () => void,
}

export const useDeleteMeetingTemplate = ({
  meetingTemplateType,
  onMutateCallback,
}: UseDeleteMeetingTemplateParams): UseMutationResult<HttpCallReturn<DeleteMeetingTemplateResponse>, unknown, DeleteMeetingTemplateParams> => {
  const orgId = getOrganizationId() ?? '';

  const queryKeyForList = meetingTopicTemplatesQueryKeys.customTemplatesList({
    orgId,
    meetingTemplateType,
  });

  return useMutation({
    mutationFn: deleteMeetingTemplate,
    onMutate: async ({ id }) => {
      onMutateCallback?.();

      const previousMeetingTemplates = queryClient.getQueryData(queryKeyForList);
      await queryClient.cancelQueries({ queryKey: queryKeyForList });

      queryClient.setQueryData<HttpCallReturn<GetMeetingTemplatesResponse>>(queryKeyForList, (originalData) => {
        if (originalData) {
          const templateIndex = originalData.response.meetingTemplates.findIndex((template) => template.id === id);

          if (templateIndex !== -1) {
            return produce(originalData, (draft) => {
              draft.response.meetingTemplates.splice(templateIndex, 1);
            });
          }
        }

        return originalData;
      });

      return { previousMeetingTemplates };
    },
    onError: (_, __, snapshot) => {
      toast.error('There was an error deleting the template. Please try again.', {
        autoClose: 1500,
      });

      queryClient.setQueryData(queryKeyForList, snapshot?.previousMeetingTemplates);

      // TODO: Reopen the drawer?
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: meetingTopicTemplatesQueryKeys.customTemplatesLists(getOrganizationId() ?? '') });
    },
  });
};
