import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getFeatureFlags } from '~Deprecated/selectors/featureFlags/getFeatureFlags';

/**
 * @deprecated Old UI
 */
const Alert = (props) => {
  const showAlert = useSelector(getFeatureFlags).showErrorToasts;

  const alertType = `alert-${props.type || 'danger'}`;
  const isRounded = props.rounded ? 'rounded' : '';
  let zIndex;
  if (props.style) {
    zIndex = props.style.zIndex ? props.style.zIndex : 999;
  }
  if (!props.message) {
    return null;
  }
  if (alertType === 'alert-danger' && !showAlert) {
    return null;
  }
  return (
    <div
      style={{ zIndex, display: 'inline-table' }}
      className={`alert alert-dismissible bottom-alert ${alertType} ${isRounded} ${props.className}`}
    >
      <FormattedMessage id={props.message} />
      {props.children}
    </div>
  );
};

export default Alert;
