import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import DrawerLayout from '~Common/components/Drawers/BaseLayout';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import CloseDrawerButton from '~Common/components/Buttons/CloseDrawer';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import InfiniteListView from '~Common/components/InfiniteList';
import { usePeople } from '~Deprecated/hooks/people/usePeople';
import { slideFromBottom } from '~Common/styles/animations';
import DashedBoxWithText from '~Deprecated/ui/components/EmptyStates/DashedBoxWithText';
import MetricsDrawerPersonCard from '~People/components/Drawers/MetricsDrawerPersonCard';
import { pageBackground } from '~Common/styles/colors';
import InsightStatusIndicatorTooltip from '~Deprecated/ui/views/Old/People/PeopleList/InsightStatusIndicatorTooltip';
import UserInsightsReport from '~Deprecated/ui/components/Insights/InsightDetails/UserInsightsReport';
import { withLineClamp } from '~Common/styles/mixins';

export const peopleMetricsTemplate = {
  name: 'PEOPLE_METRICS',
  type: templateType.SECONDARY,
  width: DRAWER_WIDTHS.SECONDARY,
};

const styles = {
  header: css`
    padding: unset;
  `,
  drawerBody: css`
    padding: unset;
  `,
  drawerHeader: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem;
  `,
  title: css`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `,
  details: css`
    display: flex;
    width: 100%;
    margin-block-start: 0.5rem;
  `,
  userDetails: css`
    flex: 1;
    display: flex;
    flex-direction: column;
  `,
  directReportsCount: css`
    margin-block-start: 0.5rem;
    ${withLineClamp(1)}
  `,
  userInsights: css`
    display: flex;
    flex-direction: column;
    margin-inline-end: 1rem;
  `,
  headerUserInsights: css`
    flex: 0 0 50px;
  `,
  jobTitle: css`
    ${withLineClamp(1)}
  `,
  department: css`
    ${withLineClamp(1)}
  `,
  reportsTo: css`
    ${withLineClamp(1)}
  `,
  listWrapper: css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    padding: 0.5rem;
    background: ${pageBackground};
  `,
  itemWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `,
};

const View = ({
  onCancel,
  firstName,
  lastName,
  jobTitle,
  department,
  directReportsCount,
  userInsights,
  managerName,
  peopleList,
}) => {
  const rightButton = () => (
    <CloseDrawerButton
      onClick={onCancel}
      css={styles.headerButtonRight}
    />
  );

  return (
    <DrawerLayout
      renderHeader={() => (
        <div css={styles.drawerHeader}>
          <div css={styles.title}>
            <h2>{ `${firstName} ${lastName}` }</h2>
            { rightButton() }
          </div>
          <div css={styles.details}>
            { managerName && (
              <div css={styles.userInsights}>
                { userInsights }
                <div css={styles.reportsTo}>
                  Reports to
                  { ' ' }
                  { managerName }
                </div>
              </div>
            ) }
            <div css={styles.userDetails}>
              <div css={styles.jobTitle}>{ jobTitle }</div>
              <div css={styles.department}>{ department }</div>
            </div>
          </div>
          <div css={styles.directReportsCount}>
            { directReportsCount }
            { ' ' }
            Direct Reports
          </div>
        </div>
      )}
      bodyClass={styles.drawerBody}
      renderBody={() => (
        <div css={styles.bodyWrapper}>
          { peopleList }
        </div>
      )}
    />
  );
};

View.propTypes = {
  onCancel: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  department: PropTypes.string,
  managerName: PropTypes.string,
  directReportsCount: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  userInsights: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  peopleList: PropTypes.object.isRequired,
};

View.defaultProps = {
  jobTitle: '',
  department: '',
  managerName: '',
  directReportsCount: 0,
};

const PeopleMetrics = ({
  popDrawer, userId, report, ...props
}) => {
  const dispatch = useDispatch();
  const peopleMetricsListQuery = usePeople;
  const { ids } = usePeople({
    insightsFor: 'DIRECT_REPORTS',
    searchFor: '',
    targetUserId: userId,
  });
  const directReportsCount = ids?.filter((i) => i !== userId)?.length ?? 0;

  const hookProps = {
    onCancel: () => {
      dispatch(popDrawer({ drawerName: peopleMetricsTemplate.name }));
    },
    directReportsCount,
    userInsights: report ? (
      <UserInsightsReport
        css={styles.headerUserInsights}
        metricHealthReport={report}
        renderTooltip={(tooltipProps) => (
          <InsightStatusIndicatorTooltip
            {...tooltipProps}
            id={userId}
          />
        )}
      />
    ) : null,
    peopleList: (
      <InfiniteListView
        listWrapperStyle={styles.listWrapper}
        loaderStyle={styles.loaderStyle}
        listQuery={() => peopleMetricsListQuery({
          insightsFor: 'DIRECT_REPORTS',
          searchFor: '',
          targetUserId: userId,
        })}
        renderItem={(id, index, user) => {
          // We do not want to render the item for the currently opened user.
          if (id === userId) {
            return null;
          }
          return (
            <MetricsDrawerPersonCard
              id={id}
              targetUserId={userId}
              css={[styles.itemWrapper, slideFromBottom(index)]}
              key={`people-metrics-${index}`}
              user={user}
            />
          );
        }}
        renderEmptyState={() => (
          <DashedBoxWithText text="There are no people to view." />
        )}
        itemKeyName="id"
      />
    ),
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

PeopleMetrics.propTypes = {
  popDrawer: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  report: PropTypes.object,
};

PeopleMetrics.defaultProps = {
  report: null,
};

registerDrawer({ templateName: peopleMetricsTemplate.name, component: PeopleMetrics });

export { View };
export default PeopleMetrics;
