import { css } from '@emotion/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buttonVariant } from '~Common/V3/styles/Button';
import Button, { ButtonProps } from '../Button';
import { Spinner } from '../Spinner';

const styles = {
  disabled: css({
    cursor: 'not-allowed',
  }),
  row: css({
    alignItems: 'center',
    columnGap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }),
};

interface SpinnerButtonProps extends Omit<ButtonProps, 'renderContents' | 'disabled'> {
  disabled?: boolean,
  icon?: IconDefinition,
  label: string,
  loadingLabel?: string,
  isLoading: boolean,
  variant?: buttonVariant,
}

const SpinnerButton = ({
  disabled = false,
  icon,
  isLoading = false,
  label,
  loadingLabel = 'Loading...',
  variant,
  ...props
}: SpinnerButtonProps): JSX.Element => (
  <Button
    css={isLoading || disabled ? styles.disabled : null}
    disabled={isLoading || disabled}
    variant={variant}
    {...props}
    renderContents={() => (
      <div css={styles.row}>
        {isLoading && (
          <Spinner
            foregroundColor={variant === 'text' ? 'currentColor' : undefined}
          />
        )}
        {!isLoading && icon && <FontAwesomeIcon icon={icon} />}
        {isLoading ? loadingLabel : label}
      </div>
    )}
  />
);

export default SpinnerButton;
