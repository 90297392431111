import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';

const styles = {
  settingsSectionHeader: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1rem',
  }),
};

interface SettingsSectionHeaderProps {
  renderContents: () => JSX.Element,
}

const SettingsSectionHeader = ({
  renderContents,
  ...props
}: SettingsSectionHeaderProps): JSX.Element => (
  <div
    css={styles.settingsSectionHeader}
    {...props}
  >
    {renderContents()}
  </div>
);

export default SettingsSectionHeader;
