import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { css } from '@emotion/react';
import { alertColor, buildAlertStyles } from './alertStyles';

const styles = {
  alertWrapper: css({
    borderRadius: '.5rem',
    padding: '.75rem .625rem',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.75rem',
  }),
  alertContent: css({
    flex: '1',
  }),
  alertIcon: css({
    marginRight: '.5rem',
  }),
};

interface ViewProps {
    renderContents: () => JSX.Element,
    color: alertColor,
    onClick?: () => void,
    renderButton?: () => JSX.Element,
}

const View = ({
  renderContents,
  color,
  onClick,
  renderButton,
}: ViewProps): JSX.Element => (
  <div
    role="button"
    css={[styles.alertWrapper, buildAlertStyles({ color })]}
    onClick={onClick}
    onKeyUp={onClick}
    tabIndex={0}
  >
    <FontAwesomeIcon
      icon={faTriangleExclamation}
      css={styles.alertIcon}
    />
    <p
      css={styles.alertContent}
    >
      {renderContents()}
    </p>
    {renderButton?.()}
  </div>
);

interface AlertInformationBannerProps {
    renderContents: () => JSX.Element,
    color: alertColor,
    onClick?: () => void,
    renderButton?: () => JSX.Element,
}

const AlertInformationBanner = ({
  renderContents,
  color,
  onClick,
  renderButton,
}: AlertInformationBannerProps): JSX.Element => {
  const hookProps = {
    renderContents,
    color,
    onClick,
    renderButton,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default AlertInformationBanner;
