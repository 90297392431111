import { css } from '@emotion/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { palette } from '~Common/styles/colors';

const styles = {
  legend: css`
  width: 300px;
  display: flex;
  justify-self: flex-end;
  justify-content: space-evenly;    
`,
  legendItem: css`
  display: flex;
  align-items: center;
  flex-shrink: 1;
`,
  legendIcon: (hue) => css`
  margin-right: 5px;
  height: 10px;
  width: 10px;
  color: ${hue};
`,
};

const contentTypes = [
  { type: 'Meeting', fill: `${palette.brand.blue}` },
  { type: 'Feedback', fill: `${palette.brand.green}` },
  { type: 'Learning', fill: `${palette.brand.red}` },
];

const TimelineLegend = () => (
  <div css={styles.legend}>
    {contentTypes.map((cont) => (
      <div key={cont.type} css={styles.legendItem}>
        <FontAwesomeIcon
          icon={faCircle}
          size="sm"
          css={styles.legendIcon(cont.fill)}
        />
        <div>{cont.type}</div>
      </div>
    ))}
  </div>
);

TimelineLegend.propTypes = {};
TimelineLegend.defaultProps = {};
export default TimelineLegend;
