import { css } from '@emotion/react';
import NewBaseAvatar from '~Common/components/Users/Avatars/NewBaseAvatar';
import { palette } from '~Common/styles/colors';
import { AgendaTopicComment, Creator } from '~Meetings/hooks/v2/useGetAgendas';
import { useGetTopicTimeString } from '~Meetings/hooks/useGetTopicTimeString';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';

const styles = {
  agendaComment: css({
    padding: '.5rem .75rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '.25rem',
    border: `1px solid ${palette.neutrals.gray200}`,
  }),
  privateIcon: css({
    color: palette.neutrals.gray700,
    fontSize: '1.5625rem',
  }),
  textContainer: css({
    marginLeft: '.75rem',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '.625rem',
  }),
  text: css({
    color: palette.neutrals.gray800,
  }),
  timeText: css({
    color: palette.neutrals.gray600,
    fontSize: '.75rem',
  }),
  rightSide: css({
    marginLeft: 'auto',
  }),
  menuIcon: css({
    color: palette.neutrals.gray700,
    cursor: 'not-allowed',
  }),
};

interface ViewProps {
  creator: Creator,
  textHTML: HTMLString,
  timeSinceComment: string,
  timeSinceEdit: string,
  hasBeenEdited: boolean,
  isPrivate: boolean,
}

const View = ({
  creator,
  textHTML,
  timeSinceComment,
  timeSinceEdit,
  hasBeenEdited,
  isPrivate,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.agendaComment}
    {...props}
  >
    {isPrivate && (
      <FontAwesomeIcon css={styles.privateIcon} icon={faEyeSlash} />
    )}
    {!isPrivate && (
      <NewBaseAvatar
        avatarSize={30}
        name={`${creator.firstName} ${creator.lastName}`}
        profileImageUrl={creator.profileImageUrl}
      />
    )}
    <div css={styles.textContainer}>
      <HTMLRenderer css={styles.text} htmlText={textHTML} />
      <div css={styles.timeText}>
        <span>{timeSinceComment}</span>
        {hasBeenEdited && (
          <span>{` (edited) ${timeSinceEdit}`}</span>
        )}
      </div>
    </div>
    <div css={styles.rightSide}>
      <FontAwesomeIcon css={styles.menuIcon} icon={faEllipsisVertical} />
    </div>
  </div>
);

interface AgendaCommentProps {
  agendaComment: AgendaTopicComment,
}

const AgendaComment = ({
  agendaComment,
  ...props
}: AgendaCommentProps): JSX.Element => {
  const timeSinceComment = useGetTopicTimeString(agendaComment.createdInMillis);
  const timeSinceEdit = useGetTopicTimeString(agendaComment.lastModifiedInMillis);
  const hasBeenEdited = agendaComment.lastModifiedInMillis > agendaComment.createdInMillis;

  const hookProps = {
    creator: agendaComment.creator,
    textHTML: agendaComment.text,
    timeSinceComment,
    timeSinceEdit,
    hasBeenEdited,
    isPrivate: agendaComment.isPrivate,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaComment;
