import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { palette } from '~Common/styles/colors';

const styles = {
  moveAgendaItemButtons: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  moveItemButton: css({
    color: palette.neutrals.gray400,
    padding: 0,
    '&:disabled': {
      color: palette.neutrals.gray200,
    },
    '&:active:not(:disabled), &:hover:not(:disabled)': {
      color: palette.neutrals.gray400,
    },
  }),
};

export interface MoveAgendaItemButtonsProps {
  shouldDisableMoveDown: boolean,
  shouldDisableMoveUp: boolean,
  handleMoveAgendaTopicDown: () => void,
  handleMoveAgendaTopicUp: () => void,
}

const MoveAgendaItemButtons = ({
  shouldDisableMoveDown,
  shouldDisableMoveUp,
  handleMoveAgendaTopicDown,
  handleMoveAgendaTopicUp,
  ...props
}: MoveAgendaItemButtonsProps): JSX.Element => (
  <div css={styles.moveAgendaItemButtons} {...props}>
    <LeadrButton
      variant="icon"
      disabled={shouldDisableMoveUp}
      onClick={handleMoveAgendaTopicUp}
      css={styles.moveItemButton}
      data-test-id="meetingsMoveAgendaItemUp"
    >
      <FontAwesomeIcon size="2x" icon={faCaretUp} />
    </LeadrButton>
    <LeadrButton
      variant="icon"
      disabled={shouldDisableMoveDown}
      onClick={handleMoveAgendaTopicDown}
      css={styles.moveItemButton}
      data-test-id="meetingsMoveAgendaItemDown"
    >
      <FontAwesomeIcon size="2x" icon={faCaretDown} />
    </LeadrButton>
  </div>
);

export default MoveAgendaItemButtons;
