import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { MEETING_TYPES } from '~Meetings/const/meetingTypes';
import { useIsMobileQuery, useIsTabletQuery } from '~Common/hooks/useMediaListener';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import Desktop from '~Meetings/components/details/HuddleDetails.desktop';
import Mobile from '~Meetings/components/details/HuddleDetails.mobile';
import { useUpcomingHuddleByFactoryId } from '~Meetings/hooks/useHuddleFactoryForInstance';
import { COACHING_LIST_UPCOMING } from '~Common/const/routes';
import { useMergedHuddleTimelineByStartTime } from '~Meetings/hooks/useHuddleTimeline';
import { leadrPubSub } from '~Deprecated/services/EventService';
import { huddleActions } from '~Meetings/const/huddleActions';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import useMeetingRealtimeMessageHandler from '~Meetings/hooks/useMeetingRealtimeMessageHandler';
import { buildPayloadForPubSub } from '~Meetings/utils/buildPayloadForPubSub';

const styles = {
  container: css({
    width: '100%',
    overflowX: 'hidden',
    height: '100%',
  }),
};

const View = ({
  isLoading,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobileQuery();
  const isTablet = useIsTabletQuery();
  const onExpand = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      css={styles.container}
      {...props}
    >
      {!isMobile && !isTablet ? (
        <Desktop
          onExpand={onExpand}
          isOpen={isOpen}
          isLoading={isLoading}
          {...props}
        />
      ) : (
        <Mobile
          {...props}
        />
      )}
    </div>
  );
};

View.propTypes = {
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isEditOptionAvailable: PropTypes.bool,
};

View.defaultProps = {
  isLoading: true,
  isEditOptionAvailable: true,
};

const HuddleDetails = ({ ...props }) => {
  const {
    meetingId,
    coachingId,
    factoryId,
    teamId,
  } = useUpcomingHuddleByFactoryId();

  const id = meetingId || coachingId;
  const type = meetingId ? MEETING_TYPES.MEETING : MEETING_TYPES.COACHING;
  const { isLoading, item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const { isLimitedAccess } = useUserPermissions();

  useMeetingRealtimeMessageHandler({ huddleId: id });

  const isEditOptionAvailable = meetingDetails?.kebabActions.some((kebabAction) => kebabAction.actionName === huddleActions.EDIT_SERIES) && !isLimitedAccess;

  const { frequency } = meetingDetails ?? {};
  const history = useHistory();

  // Invalid URL, kick them back to upcoming meetings
  if (!factoryId && !meetingId && !coachingId) {
    history.replace({
      pathname: COACHING_LIST_UPCOMING,
    });
  }

  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobileQuery();

  const { timeline } = useMergedHuddleTimelineByStartTime({ id, type });

  // Doing the sub after the timeline has loaded so that the page will show learnings after refreshes/and when navigating from learnings
  useEffect(() => {
    // Need to look through all huddles for a given timeline item. Otherwise there can be issues with multiple 1:1 meetings on the same date
    // See: LW-5947
    const selectedTimelineItem = Object.values(timeline).find((timelineItem) => timelineItem[1].huddles?.some((huddle) => huddle.id === id));

    if (selectedTimelineItem) {
      const [timelineDate, timelineData] = selectedTimelineItem;

      const { huddles } = timelineData;
      const huddle = huddles.find((timelineHuddle) => timelineHuddle.id === id);
      const payload = buildPayloadForPubSub({ timelineData, timelineDate, huddle });

      leadrPubSub.publish({
        type: 'timeline_date_selection',
        ...payload,
      });
    }
  }, [timeline, id, isMobile]);

  const hookProps = {
    id,
    type,
    factoryId,
    teamId,
    frequency,
    isLoading,
    onExpand: () => {
      setIsOpen(!isOpen);
    },
    isMobile,
    isOpen,
    isEditOptionAvailable,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

HuddleDetails.propTypes = {};

HuddleDetails.defaultProps = {};

export { View };
export default HuddleDetails;
