export enum WhatsDueFilterOptions {
  AllDue = 'ALL_DUE',
  ActionItems = 'ACTION_ITEMS',
  Feedback = 'FEEDBACK',
  Surveys = 'SURVEYS',
  Learning = 'LEARNING',
  Reviews = 'REVIEWS',
}
export const MY_SCHEDULE_MEETING_CARD_MAX_SHOWN_AVATAR_COUNT = 4;

export const NUMBER_OF_MEETING_TO_GET_IN_MY_SCHEDULE = 100;

export const whatsDueOptionsTranslation = {
  [WhatsDueFilterOptions.AllDue]: 'All Due',
  [WhatsDueFilterOptions.ActionItems]: 'Action Items',
  [WhatsDueFilterOptions.Feedback]: 'Feedback',
  [WhatsDueFilterOptions.Surveys]: 'Surveys',
  [WhatsDueFilterOptions.Learning]: 'Learning',
  [WhatsDueFilterOptions.Reviews]: 'Reviews',
};

export enum HomeModule {
  Celebrate = 'CELEBRATE',
  EngagementSurvey = 'ENGAGEMENT_SURVEY',
  Goals = 'GOALS',
  Insights = 'INSIGHTS',
  Meetings = 'MEETINGS',
  OrgClarity = 'ORG_CLARITY',
  People = 'PEOPLE',
  Recognition = 'RECOGNITION',
  TeamClarity = 'TEAM_CLARITY',
  WhatsDue = 'WHATS_DUE',
}

export interface WhatsDueOptions {
  actionItems: boolean,
  feedback: boolean,
  surveys: boolean,
  learning: boolean,
  reviews: boolean,
}

export interface HomeModuleSettings {
  isVisible: boolean,
  rank: number,
  column: number,
}
