import { useQuery } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { TopicShape } from '../Const/types';
import { reviewCycleKeys } from './queryKeys';
import { EXTENDED_QUERY_STALE_TIME } from '../Const/defaults';

interface getTopicForCycleProps {
  queryKey: string[],
}

const getTopicForCycle = ({ queryKey }: getTopicForCycleProps): Promise<HttpCallReturn<TopicShape[]>> => {
  const [orgId, , cycleId, version] = queryKey;

  const serverUrl = {
    host: getHost(hosts.reviewCycles, version),
    uri: `/organizations/${orgId}/reviewCycles/${cycleId}/topics`,
  };

  return getApi<TopicShape[]>(serverUrl, {});
};

interface useGetTopicForCycleProps {
  queryKey?: string[],
  cycleId: string,
}

interface useGetTopicForCycleReturnProps {
  data: TopicShape[] | undefined,
  status: string,
  isLoading: boolean,
}

// eslint-disable-next-line max-len
export const useGetTopicForCycle = ({ cycleId }: useGetTopicForCycleProps): useGetTopicForCycleReturnProps => {
  const version = '2.5';
  const topicKey = [...reviewCycleKeys.topicsForCycleWithVersion(cycleId, version)];

  const result = useQuery({
    queryKey: topicKey,
    queryFn: getTopicForCycle,
    staleTime: EXTENDED_QUERY_STALE_TIME,
    enabled: !!cycleId,
  });

  const data = result?.data?.response.sort(
    (a, b) => {
      if (a.rank > b.rank) return 1;
      if (a.rank < b.rank) return -1;
      return 0;
    },
  );
  return {
    data,
    status: result?.status,
    isLoading: result?.isLoading,
  };
};
