import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { useAgendaPermissionsStore } from '../utils/useAgendaPermissions';

interface CarryOverAgendaSectionRequest {
  huddleId: string,
  sectionId: string,
}

interface CarryOverAgendaSectionResponse {
  id: string,
}

export const createAgendaSection = (data: CarryOverAgendaSectionRequest): Promise<HttpCallReturn<CarryOverAgendaSectionResponse>> => (
  postApi(`/organizations/${getOrganizationId() ?? ''}/huddles/agendas/sections/carryover`, data)
);

export const useCarryOverAgendaSection = ():
UseMutationResult<HttpCallReturn<CarryOverAgendaSectionResponse>, unknown, CarryOverAgendaSectionRequest> => {
  const {
    disableSectionWorkflows,
    enableSectionWorkflows,
  } = useAgendaPermissionsStore((state) => ({
    disableSectionWorkflows: state.disableSectionWorkflows,
    enableSectionWorkflows: state.enableSectionWorkflows,
  }));

  return useMutation({
    mutationFn: createAgendaSection,
    onMutate: ({ sectionId }) => {
      disableSectionWorkflows(sectionId);
    },
    onError: () => {
      toast.error(<MeetingsErrorToast />, { autoClose: 1500 });
    },
    onSuccess: () => {
      toast.success('The section was successfully carried over into the following meeting, including topics and comments!', { autoClose: 3000 });
      void queryClient.invalidateQueries({ queryKey: meetingKeys.agendaLists() });
    },
    onSettled: (_, __, { sectionId }) => {
      enableSectionWorkflows(sectionId);
    },
  });
};
