import { css } from '@emotion/react';
import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { MouseEventHandler, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { REVIEWS_CREATE_CYCLE } from '~Common/const/routes';
import { palette } from '~Common/styles/colors';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import { TopicShape } from '~Reviews/V2/Const/types';
import { useCreateTopicForCycle } from '~Reviews/V2/Hooks/useCreateTopicForCycle';

const styles = {
  button: css({
    flexShrink: 0,
  }),
  alreadyAddedButton: css({
    color: palette.brand.green,
  }),
  notAddedButton: css({
    color: palette.brand.indigo,
  }),
};

interface ViewProps {
  isAlreadyAdded: boolean,
  isLoading: boolean,
  onClick: MouseEventHandler,
  allowAddingSingleQuestion: boolean,
}

export const View = ({
  isAlreadyAdded,
  isLoading,
  onClick,
  allowAddingSingleQuestion,
  ...props
}: ViewProps): JSX.Element => (
  <>
    {allowAddingSingleQuestion && (
    <SpinnerButton
      css={[styles.button, isAlreadyAdded ? styles.alreadyAddedButton : styles.notAddedButton]}
      icon={isAlreadyAdded ? faCheck : faPlus}
      isLoading={isLoading}
      label={isAlreadyAdded ? 'Added' : 'Add to Review'}
      loadingLabel="Adding..."
      onClick={onClick}
      size="small"
      variant="text"
      {...props}
    />
    )}
  </>
);

interface AddTopicButtonProps {
  topic: TopicShape,
  reviewCycleUid: string,
}

export const AddTopicButton = ({
  topic,
  reviewCycleUid,
  ...props
}: AddTopicButtonProps): JSX.Element => {
  const [isAlreadyAdded, setIsAlreadyAdded] = useState(false);
  const { isPending: isLoading, mutate } = useCreateTopicForCycle();

  const onClick: MouseEventHandler = () => {
    mutate({ cycleId: reviewCycleUid, topics: [topic] }, {
      onSuccess() {
        setIsAlreadyAdded(true);
      },
    });
  };

  const history = useHistory();
  const allowAddingSingleQuestion = history.location.pathname.includes(REVIEWS_CREATE_CYCLE);

  const hookProps = {
    isAlreadyAdded,
    isLoading,
    onClick,
    allowAddingSingleQuestion,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
