import { Goals } from '@leadr-hr/types';
import { meetingGoals } from '~Meetings/const/sharedStyles';
import OldMeetingGoalCard from './OldMeetingGoalCard';

const styles = {
  ...meetingGoals,
};

interface OldMeetingGoalsDataProps {
  goals: Goals.Goal[],
}

export const OldMeetingGoalsData = ({
  goals,
  ...props
}: OldMeetingGoalsDataProps): JSX.Element => (
  <>
    <div
      css={styles.container}
      {...props}
    >
      <header css={styles.header}>
        <div css={styles.title}>
          <span>
            Goals
          </span>

          { !!goals?.length && (
            <span>
            &nbsp;(
              {goals.length}
              )
            </span>
          )}
        </div>
      </header>
      <hr css={styles.divider} />
      <div css={styles.body}>
        {goals.map((goal) => (
          <OldMeetingGoalCard
            key={goal.goalId}
            css={styles.goalCard}
            goal={goal}
            canAccessGoal
          />
        ))}
      </div>
    </div>
  </>
);

export default OldMeetingGoalsData;
