import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { forMobileObject } from '~Common/styles/mixins';

const styles = {
  modalBody: css({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    // maxHeight: '37.5rem',
    padding: '0 1.5rem',
    // The negative margins are here to move the content outside of the modals padding so that the scrollbar shows up at the edge
    margin: '0 -1.5rem',
  }, forMobileObject({
    padding: '0 1.25rem',
    margin: '0 -1.25rem',
  })),
};

interface ModalBodyProps {
  children: ReactNode,
}

const ModalBody = ({
  children,
  ...props
}: ModalBodyProps): JSX.Element => (
  <section
    css={styles.modalBody}
    {...props}
  >
    {children}
  </section>
);

export default ModalBody;
