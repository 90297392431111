import {
  AgendaSection, AgendaTopic, AgendaTopicComment,
} from '~Meetings/hooks/v2/useGetAgendas';
import { AgendaType } from '@leadr-hr/types';

type AgendaItemType = AgendaTopicComment | AgendaTopic | AgendaSection;

export const filterOutPrivateAgendaItems = <T extends AgendaItemType>(agendaItems: T[]): T[] => agendaItems.map((agendaItem) => {
  if (agendaItem.type === AgendaType.AgendaSection) {
    return {
      ...agendaItem,
      children: filterOutPrivateAgendaItems(agendaItem.children),
    };
  } if (agendaItem.type === AgendaType.AgendaTopic) {
    if (agendaItem.isPrivate) {
      return null;
    }
    return {
      ...agendaItem,
      children: filterOutPrivateAgendaItems(agendaItem.children),
    };
  }

  if (agendaItem.isPrivate) {
    return null;
  }

  return agendaItem;
}).filter(Boolean) as T[]; // Removes all the nulls
