import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { palette } from '~Common/styles/colors';
import NewGoalStatus from '~Goals/components/Shared/NewGoalStatus';
import { lineClamp } from '~Common/styles/mixins';

const styles = {
  definingObjective: css({
    padding: '.75rem 1.125rem',
    border: `1px solid ${palette.neutrals.gray400}`,
    borderRadius: '0.5rem',
  }),
  title: css({
    color: palette.neutrals.gray700,
    fontWeight: 600,
    marginTop: '.375rem',
    marginBottom: '.5625rem',
  }, lineClamp(2)),
};

interface ViewProps {
  title: string,
  status: Goals.GoalStatus,
}

const View = ({
  title,
  status,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.definingObjective}
    {...props}
  >
    <div css={styles.title}>{title}</div>
    <NewGoalStatus status={status} />
  </div>
);

interface StandardOperatingObjectiveProps {
  standardOperationgObjective: Goals.Goal,
}

const StandardOperatingObjective = ({
  standardOperationgObjective,
  ...props
}: StandardOperatingObjectiveProps): JSX.Element => {
  const { status } = standardOperationgObjective.statusUpdates[standardOperationgObjective.statusUpdates.length - 1];

  const hookProps = {
    title: standardOperationgObjective.title,
    status,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default StandardOperatingObjective;
