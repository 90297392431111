import { AgendaType, RootAgendaPermission } from '@leadr-hr/types';
import { SortableTree, TreeItems } from 'dnd-kit-sortable-tree';
import {
  useCallback, useMemo, useState,
} from 'react';
import useGetAgendas from '~Meetings/hooks/v2/useGetAgendas';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import {
  Announcements,
  KeyboardSensor,
  MouseSensor,
  SensorDescriptor,
  SensorOptions,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  AgendaItemPosition,
  ParentType,
  useReorderAgendaItem,
} from '~Meetings/hooks/v2/useReorderAgendaItem';
import { useFormatAgendasToTreeItems } from '~Meetings/hooks/utils/DragAndDrop/useFormatAgendasToTreeItems';
import {
  RecursiveObject, useRealtimeCreatingAgenda, useRealtimeReorderingAgenda, useRealtimeUpdatingAgenda,
} from '~Meetings/stores/useMeetingRealtimeStore';
import useMeetingRealtime from '~Meetings/hooks/useMeetingRealtime';
import { getUserId } from '~Common/utils/localStorage';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { css } from '@emotion/react';
import { useShowComments } from '~Meetings/hooks/useShowComments';
import {
  AgendaCreatePayload, AgendaRealTimeMessageType, AgendaReorderPayload, AgendaUpdatePayload,
} from '~Meetings/const/realTimeMeetingMessages';
import { getLocalId } from '~Common/utils/uuid';
import { flattenChildren } from '~Meetings/utils/flattenChildren';
import { AgendaTreeItemData, ItemChangedReason } from '~Meetings/const/dndKitSortableTreeTypes';
import { useAgendaSectionCreateTopicRealtimeEvents } from '~Meetings/hooks/utils/useAgendaSectionCreateTopicRealtimeEvents';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import { AGENDA_SECTION_CHILD_INDENTATION_WIDTH_DESKTOP, AGENDA_SECTION_CHILD_INDENTATION_WIDTH_MOBILE } from '~Meetings/const';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useShowAgendaEditor } from '~Meetings/hooks/utils/useShowAgendaEditor';
import EmptyState from './EmptyState';
import TreeItemComponent from './TreeItemComponent';
import RealtimeCreationCard from '../../RealtimeCreationCard';
import OnePersonCreatingItem from '../../RealtimeCreationCard/OnePersonCreatingItem';

const styles = {
  skeleton: css({
    height: '15.625rem',
    width: '100%',
    maxWidth: '100%',
  }),
  list: css({
    margin: 0,
    marginBlock: 0,
    paddingInline: 0,
  }),
  emptyState: css({
    margin: '0 0 .875rem auto',
  }),
  realTimeCreatingSection: css({
    '&:not(:last-of-type)': {
      marginBottom: '.75rem',
    },
  }),
};

interface ViewProps {
  huddleId: string,
  treeItems: TreeItems<AgendaTreeItemData>,
  handleDragEnd: (
    newItems: TreeItems<AgendaTreeItemData>,
    reason: ItemChangedReason<AgendaTreeItemData>
  ) => void,
  sensors: SensorDescriptor<SensorOptions>[],
  meetingType: MeetingTypeEnum,
  showSkeletonLoaders: boolean,
  onCreateNewAgenda: (agendaType: AgendaType) => void,
  realtimeCreatingAgenda: RecursiveObject<AgendaCreatePayload[]>,
  realtimeUpdatingAgenda: RecursiveObject<AgendaUpdatePayload[]>,
  realtimeReorderingAgenda: RecursiveObject<AgendaReorderPayload[]>,
  announcements: Announcements,
  canAddRecurringTopic: boolean,
  canAddAttachments: boolean,
  indentationWidth: number,
  showEmptyState: boolean,
  hasMaxTopics: boolean,
  searchedItemId?: string,
}

const View = ({
  huddleId,
  treeItems,
  handleDragEnd,
  sensors,
  meetingType,
  showSkeletonLoaders,
  onCreateNewAgenda,
  realtimeCreatingAgenda,
  realtimeUpdatingAgenda,
  realtimeReorderingAgenda,
  announcements,
  canAddRecurringTopic,
  canAddAttachments,
  searchedItemId,
  indentationWidth,
  showEmptyState,
  hasMaxTopics,
}: ViewProps): JSX.Element => (
  <div>
    {showSkeletonLoaders && (
      <SkeletonLoader
        css={styles.skeleton}
        variant="rectangular"
        renderComponent={() => <div />}
      />
    )}
    {!showSkeletonLoaders && (
      <div css={styles.list}>
        {treeItems && (
          <SortableTree
            dndContextProps={{
              modifiers: [restrictToFirstScrollableAncestor],
              sensors,
              accessibility: { announcements },
            }}
            dropAnimation={null}
            items={treeItems}
            onItemsChanged={handleDragEnd}
            sortableProps={{ animateLayoutChanges: () => false }}
            indentationWidth={indentationWidth}
            // @ts-expect-error These extra props are passed in below, since that's how dnd-kit-sortable-tree works
            TreeItemComponent={TreeItemComponent}
            huddleId={huddleId}
            meetingType={meetingType}
            realtimeCreatingAgenda={realtimeCreatingAgenda}
            realtimeUpdatingAgenda={realtimeUpdatingAgenda}
            realtimeReorderingAgenda={realtimeReorderingAgenda}
            canAddRecurringTopic={canAddRecurringTopic}
            canAddAttachments={canAddAttachments}
            searchedItemId={searchedItemId}
            hasMaxTopics={hasMaxTopics}
          />
        )}
        {showEmptyState && (
          <EmptyState
            css={styles.emptyState}
            onClick={() => onCreateNewAgenda(AgendaType.AgendaTopic)}
          />
        )}
        {/* @ts-expect-error TODO: Fix later */}
        {realtimeCreatingAgenda?.data?.map(
          // @ts-expect-error TODO: Fix later
          ({ creator, type: agendaType, eventId }) => (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <RealtimeCreationCard key={eventId} css={styles.realTimeCreatingSection}>
              <OnePersonCreatingItem
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                person={creator}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                agendaType={agendaType}
              />
            </RealtimeCreationCard>
          ),
        )}
      </div>
    )}
  </div>
);

interface DNDKitAgendaListProps {
  huddleId: string,
  meetingType: MeetingTypeEnum,
  onCreateNewAgenda: (agendaType: AgendaType) => void,
  isShowingAllCommentSections: boolean,
  hasMaxTopics: boolean,
  searchedItemId?: string,
}

export const DNDKitTreeAgendaList = ({
  huddleId,
  isShowingAllCommentSections,
  ...props
}: DNDKitAgendaListProps): JSX.Element => {
  const userId = getUserId();
  const { data, isLoading: isAgendaLoading } = useGetAgendas({ huddleId });
  const { mutate: reorderAgendaItem } = useReorderAgendaItem();
  const { realtimeCreatingAgenda } = useRealtimeCreatingAgenda(huddleId);
  const { realtimeUpdatingAgenda } = useRealtimeUpdatingAgenda(huddleId);
  const { realtimeReorderingAgenda } = useRealtimeReorderingAgenda(huddleId);
  const { sendMessage } = useMeetingRealtime({ huddleId });
  const hideAllItemEditors = useShowItemEditor((state) => state.hideAllItemEditors);
  const isMobile = useIsMobileQuery();

  // We should always have the userId for the current user
  const { user } = useUserProfile(userId!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const [reorderEventId, setReorderEventId] = useState('');

  useShowComments({
    agendas: data?.response.agendas || [],
    isShowingAllCommentSections,
    isAgendaLoading,
  });

  const [showSkeletonLoaders] = useSkeletonLoaders(isAgendaLoading);
  const { useIsAgendaEditorVisisble } = useShowAgendaEditor();
  const showNewAgendaEditor = useIsAgendaEditorVisisble({ identifier: 'create' });

  const removeAgendaSection = useAgendaSectionCreateTopicRealtimeEvents((state) => state.removeRealtimeEventId);

  const cancelAgendaReoder = useCallback((agendaItemId: string, sectionId?: string) => {
    sendMessage(AgendaRealTimeMessageType.ReorderCanceled, {
      id: agendaItemId,
      eventId: reorderEventId,
      parentIds: sectionId ? [sectionId] : [],
    });
    if (sectionId) {
      removeAgendaSection(sectionId);
    }
  }, [removeAgendaSection, reorderEventId, sendMessage]);

  const handleDragEnd = useCallback(
    (
      newItems: TreeItems<AgendaTreeItemData>,
      reason: ItemChangedReason<AgendaTreeItemData>,
    ): void => {
      if (reason.type === 'dropped' && reason.draggedItem) {
        const itemBeingMovedId = reason.draggedItem.item.id;

        const before: AgendaItemPosition = {
          parentId: reason.draggedItem.initialParentId,
          parentType: reason.draggedItem.parentType,
          index: reason.draggedItem.index,
        };

        let after: AgendaItemPosition | undefined;

        newItems.forEach((agendaItem, index) => {
          if (agendaItem.item.id === itemBeingMovedId) {
            after = {
              index,
              parentId: huddleId,
              parentType: ParentType.MeetingInstance,
            };
          } else if (agendaItem.item.type === AgendaType.AgendaSection && agendaItem.children) {
            agendaItem.children.forEach((child, childIndex) => {
              if (child.item.id === itemBeingMovedId) {
                after = {
                  index: childIndex,
                  // @ts-expect-error Yes, it does have a parentId
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  parentId: reason.draggedItem.parentId,
                  parentType: ParentType.AgendaSection,
                };
              }
            });
          }
        });

        const sectionId = reason.draggedItem.parentType === ParentType.AgendaSection ? reason.draggedItem.initialParentId : undefined;

        if (
          before && after
          // Verify that at least one thing changed before reordering
          && (before.parentId !== after?.parentId
            || before.parentType !== after?.parentType
            || before.index !== after?.index)
        ) {
          reorderAgendaItem({
            huddleId,
            reorderAgendaItemRequest: {
              id: reason.draggedItem.id.toString(),
              type: reason.draggedItem.item.type,
              before,
              after,
              eventId: reorderEventId,
            },
          });
        } else {
          cancelAgendaReoder(reason.draggedItem.id.toString(), sectionId);
        }
      }
    },
    [cancelAgendaReoder, huddleId, reorderAgendaItem, reorderEventId],
  );

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
  );

  const treeItems = useFormatAgendasToTreeItems(data?.response?.agendas, huddleId);

  const showEmptyState = useMemo(() => treeItems.filter((agendaItem) => (
    agendaItem.item.type === AgendaType.AgendaSection
    || (agendaItem.item.type === AgendaType.AgendaTopic && !agendaItem.item.isInvisible)
  )).length === 0 && !showNewAgendaEditor, [treeItems, showNewAgendaEditor]);

  /*
    This is not ideal. We are utilizing the accessibility annoucements to do real time events.
    Currently, dnd-kit-sortable-tree doesn't expose the drag event handlers, without overriding them, which breaks the abstraction in the library.
    I left a Github issue on their library, asking if they could expose them: https://github.com/Shaddix/dnd-kit-sortable-tree/issues/37
  */
  const announcements: Announcements = useMemo(
    () => ({
      onDragStart({ active }) {
        hideAllItemEditors();
        const flattenedTreeItems = flattenChildren(treeItems) as Omit<TreeItems<AgendaTreeItemData>, 'children'>;
        const newDraggingEventId = getLocalId();
        const parentIds = [];
        const treeItemBeingMoved = flattenedTreeItems.find((treeItem) => treeItem.item.id === active.id);

        if (treeItemBeingMoved && treeItemBeingMoved.parentType === ParentType.AgendaSection) {
          parentIds.push(treeItemBeingMoved.initialParentId);
        }

        sendMessage(AgendaRealTimeMessageType.Reorder, {
          id: active.id,
          eventId: newDraggingEventId,
          updater: {
            // We should always have the profile for the current user
            orgUserId: user!.organizationUserId, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            firstName: user!.firstName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            lastName: user!.lastName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            profileImageUrl: user!.profileImageUrl, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          },
          parentIds,
        });

        setReorderEventId(newDraggingEventId);
        return `Picked up ${active.id}.`;
      },
      onDragMove() {
        return '';
      },
      onDragOver() {
        return '';
      },
      onDragEnd() {
        return '';
      },
      onDragCancel({ active }) {
        cancelAgendaReoder(active.id.toString());
        return `Moving was cancelled. ${active.id} was dropped in its original position.`;
      },
    }),
    [cancelAgendaReoder, hideAllItemEditors, sendMessage, treeItems, user],
  );

  const indentationWidth = isMobile ? AGENDA_SECTION_CHILD_INDENTATION_WIDTH_MOBILE : AGENDA_SECTION_CHILD_INDENTATION_WIDTH_DESKTOP;

  const hookProps = {
    huddleId,
    treeItems,
    handleDragEnd,
    sensors,
    showSkeletonLoaders,
    realtimeCreatingAgenda,
    realtimeUpdatingAgenda,
    realtimeReorderingAgenda,
    announcements,
    canAddRecurringTopic: !!data?.response?.permissions?.includes(
      RootAgendaPermission.CanAddRecurringTopics,
    ),
    canAddAttachments: !!data?.response?.permissions?.includes(
      RootAgendaPermission.CanAddAttachment,
    ),
    indentationWidth,
    showEmptyState,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
