import { SyntheticEvent } from 'react';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { ViewType } from '~Common/const/interfaces';
import { useLearningViewTypeFilter } from '~Learning/hooks/utils/useLearningViewTypeFilter';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';

interface ViewProps {
  selectedViewType: ViewType,
  onChange: (event: SyntheticEvent, option: ViewType) => void,
}

const viewTypes = [
  {
    value: ViewType.GRID,
    icon: faGripHorizontal,
    'data-test-id': 'learningGrid',
  },
  {
    value: ViewType.LIST,
    icon: faList,
    'data-test-id': 'learningList',
  },
];

const View = ({
  selectedViewType, ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs value={selectedViewType} {...props}>
    {viewTypes.map((viewType) => (
      <LeadrToggleTabs.IconTab data-test-id={viewType['data-test-id']} key={viewType.value} icon={viewType.icon} value={viewType.value} />
    ))}
  </LeadrToggleTabs>
);

const LearningViewToggle = ({ ...props }): JSX.Element => {
  const [selectedViewType, setSelectedViewType] = useLearningViewTypeFilter();

  const onChange = (e: SyntheticEvent, newViewType: ViewType): void => {
    if (newViewType) {
      setSelectedViewType(newViewType);
    }
  };

  const hookProps = {
    selectedViewType,
    onChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LearningViewToggle;
