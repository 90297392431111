import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import { navigateAction } from '~Deprecated/actions/navigate';
import { COACHING_LIST_UPCOMING } from '~Common/const/routes';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';

const restoreMeeting = (data) => {
  const serverUrl = `/organizations/${getOrganizationId()}/huddles/series/unarchive`;
  return patchApi(serverUrl, data);
};

export const useRestoreMeeting = ({ template }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: restoreMeeting,
    onMutate: () => {
      toastId.current = toast.info('Restoring this meeting series.', { autoClose: true });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: <MeetingsErrorToast />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (_, variables) => {
      toast.update(toastId.current, {
        render: 'You have restored the meeting. Enjoy the rest of your day.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      dispatch(
        popDrawerAction({ drawerName: template.name }),
      );

      const { type } = variables;

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', type] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', 'archived'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });

      dispatch(navigateAction({
        pathname: COACHING_LIST_UPCOMING,
      }));
    },
  });

  return mutation.mutate;
};
