import { create } from 'zustand';
import { isLocalId } from '~Common/utils/uuid';
import { AgendaCommentPermission, AgendaSectionPermission, AgendaTopicPermission } from '@leadr-hr/types';
import useGetAgendas, { AgendaItem, AgendaSection, AgendaTopic } from '../v2/useGetAgendas';

interface GetAgendaTopicPermissionsParams {
  agendaTopicId: string,
  agendas: AgendaItem[],
  sectionId?: string,
}

interface GetAgendaSectionPermissionsParams {
  sectionId: string,
  agendas: AgendaItem[],
}

interface GetAgendaCommentPermissionsParams {
  sectionId?: string,
  agendaTopicId: string,
  commentId: string,
  agendas: AgendaItem[],
}

interface GetAgendaTopicPermissionsReturn {
  canAddNote: boolean,
  canReorderAgenda: boolean,
  canUpdateAgendaStatus: boolean,
  canCarryOverAgenda: boolean,
  canUpdateAgendaRecurrence: boolean,
  canUpdateAgendaVisibility: boolean,
  canDeleteAgenda: boolean,
  canEditAgenda: boolean,
  canMakeActionItem: boolean,
  canBookmark: boolean,
  hasMenuOptions: boolean,
  areWorkflowsDisabled: boolean,
  canAddAttachment: boolean,
}

interface GetAgendaSectionPermissionsReturn {
  canAddAgendaItem: boolean,
  canCarryOver: boolean,
  canEdit: boolean,
  canUpdateRecurrence: boolean,
  canDelete: boolean,
  canReorder: boolean,
  hasMenuOptions: boolean,
  areWorkflowsDisabled: boolean,
}

interface GetAgendaCommentPermissionsReturn {
  canEdit: boolean,
  canDelete: boolean,
  canChangePrivacy: boolean,
  hasMenuOptions: boolean,
}

interface AgendaPermissionsStoreState {
  disabledSectionIds: string[],
  getIsSectionDisabled: (sectionId: string) => boolean,
  disableSectionWorkflows: (sectionId: string) => void,
  enableSectionWorkflows: (sectionId: string) => void,
  disabledTopicIds: string[],
  getIsTopicDisabled: (topicId: string) => boolean,
  disableTopicWorkflows: (agendaTopicId: string) => void,
  enableTopicWorkflows: (agendaTopicId: string) => void,
  disableWorkflows: (agendaTopicId: string, sectionId?: string) => void,
  enableWorkflows: (agendaTopicId: string, sectionId?: string) => void,
  getAgendaTopicPermissions: (params: GetAgendaTopicPermissionsParams) => GetAgendaTopicPermissionsReturn,
  getAgendaSectionPermissions: (params: GetAgendaSectionPermissionsParams) => GetAgendaSectionPermissionsReturn,
  getAgendaCommentPermissions: (params: GetAgendaCommentPermissionsParams) => GetAgendaCommentPermissionsReturn,
}

export const useAgendaPermissionsStore = create<AgendaPermissionsStoreState>((set, get) => ({
  disabledSectionIds: [],
  getIsSectionDisabled: (sectionId: string) => get().disabledSectionIds.includes(sectionId),
  disableSectionWorkflows: (sectionId: string) => set((state) => ({
    disabledSectionIds: [...state.disabledSectionIds, sectionId],
  })),
  enableSectionWorkflows: (sectionId: string) => set((state) => ({
    disabledSectionIds: state.disabledSectionIds.filter((id) => id !== sectionId),
  })),
  disabledTopicIds: [],
  getIsTopicDisabled: (topicId: string) => get().disabledTopicIds.includes(topicId),
  disableTopicWorkflows: (agendaTopicId: string) => set((state) => ({
    disabledTopicIds: [...state.disabledTopicIds, agendaTopicId],
  })),
  enableTopicWorkflows: (agendaTopicId: string) => set((state) => ({
    disabledTopicIds: state.disabledTopicIds.filter((id) => id !== agendaTopicId),
  })),
  disableWorkflows: (agendaTopicId: string, sectionId?: string) => set((state) => ({
    disabledTopicIds: [...state.disabledTopicIds, agendaTopicId],
    disabledSectionIds: sectionId ? [...state.disabledSectionIds, sectionId] : state.disabledSectionIds,
  })),
  enableWorkflows: (agendaTopicId: string, sectionId?: string) => set((state) => ({
    disabledTopicIds: state.disabledTopicIds.filter((id) => id !== agendaTopicId),
    disabledSectionIds: sectionId ? state.disabledSectionIds.filter((id) => id !== sectionId) : state.disabledSectionIds,
  })),
  getAgendaTopicPermissions: ({ agendaTopicId, agendas, sectionId }: GetAgendaTopicPermissionsParams) => {
    let agendaTopic;

    if (sectionId) {
      const agendaSection = agendas.find((agenda) => agenda.id === sectionId) as AgendaSection;
      agendaTopic = agendaSection.children?.find((agenda) => agenda.id === agendaTopicId);
    } else {
      agendaTopic = agendas.find((agenda) => agenda.id === agendaTopicId) as AgendaTopic | undefined;
    }

    const permissions = agendaTopic?.permissions ?? [];
    const isServerTopic = !isLocalId(agendaTopicId);
    const isSectionDisabled = sectionId ? get().getIsSectionDisabled(sectionId ?? '') : false;
    const isTopicDisabled = get().getIsTopicDisabled(agendaTopicId);
    const areWorkflowsDisabled = isSectionDisabled || isTopicDisabled;

    const canAddNote = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanAddNote);
    const canReorderAgenda = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanReorderAgenda);
    const canUpdateAgendaStatus = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanUpdateAgendaStatus);
    const canCarryOverAgenda = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanCarryOverAgenda);
    const canUpdateAgendaRecurrence = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanUpdateAgendaRecurrence);
    const canUpdateAgendaVisibility = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanUpdateAgendaVisibility);
    const canDeleteAgenda = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanDeleteAgenda);
    const canEditAgenda = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanEditAgenda);
    const canMakeActionItem = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanMakeActionItem);
    const canBookmark = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanBookmark);
    const canAddAttachment = !areWorkflowsDisabled && permissions.includes(AgendaTopicPermission.CanAddAttachment);

    const hasMenuOptions = (
      canAddNote
      || canReorderAgenda
      || canUpdateAgendaStatus
      || canCarryOverAgenda
      || canUpdateAgendaRecurrence
      || canUpdateAgendaVisibility
      || canDeleteAgenda
      || canEditAgenda
      || canMakeActionItem
      || canBookmark
      || canAddAttachment);

    return {
      canAddNote,
      canReorderAgenda,
      canUpdateAgendaStatus,
      canCarryOverAgenda,
      canUpdateAgendaRecurrence,
      canUpdateAgendaVisibility,
      canDeleteAgenda,
      canEditAgenda,
      hasMenuOptions: isServerTopic && hasMenuOptions,
      canMakeActionItem,
      canBookmark,
      areWorkflowsDisabled,
      canAddAttachment,
    };
  },
  getAgendaSectionPermissions: ({
    sectionId,
    agendas,
  }: GetAgendaSectionPermissionsParams) => {
    const agendaSection = agendas.find((agenda) => agenda.id === sectionId) as AgendaSection;
    const permissions = agendaSection?.permissions ?? [];
    const isServerSection = !isLocalId(sectionId);
    const isSectionDisabled = get().getIsSectionDisabled(sectionId);

    const areWorkflowsDisabled = isSectionDisabled;

    const canAddAgendaItem = !areWorkflowsDisabled && permissions.includes(AgendaSectionPermission.CanAddAgendaTopic);
    const canCarryOver = !areWorkflowsDisabled && permissions.includes(AgendaSectionPermission.CanCarryOver);
    const canEdit = !areWorkflowsDisabled && permissions.includes(AgendaSectionPermission.CanEdit);
    const canUpdateRecurrence = !areWorkflowsDisabled && permissions.includes(AgendaSectionPermission.CanUpdateRecurrence);
    const canDelete = !areWorkflowsDisabled && permissions.includes(AgendaSectionPermission.CanDelete);
    const canReorder = !areWorkflowsDisabled && permissions.includes(AgendaSectionPermission.CanReorder);

    const hasMenuOptions = (
      canAddAgendaItem
      || canCarryOver
      || canEdit
      || canUpdateRecurrence
      || canDelete
      || canReorder);

    return {
      canAddAgendaItem,
      canCarryOver,
      canEdit,
      canUpdateRecurrence,
      canDelete,
      canReorder,
      hasMenuOptions: isServerSection && hasMenuOptions,
      areWorkflowsDisabled,
    };
  },
  getAgendaCommentPermissions: ({
    sectionId,
    agendaTopicId,
    commentId,
    agendas,
  }: GetAgendaCommentPermissionsParams) => {
    let agendaTopic;

    if (sectionId) {
      const agendaSection = agendas.find((agenda) => agenda.id === sectionId) as AgendaSection;
      agendaTopic = agendaSection.children?.find((agenda) => agenda.id === agendaTopicId);
    } else {
      agendaTopic = agendas.find((agenda) => agenda.id === agendaTopicId) as AgendaTopic | undefined;
    }

    const comment = agendaTopic?.children?.find((agenda) => agenda.id === commentId);
    const isServerComment = !isLocalId(commentId);

    const permissions = comment?.permissions ?? [];

    const canEdit = permissions.includes(AgendaCommentPermission.CanEdit);
    const canDelete = permissions.includes(AgendaCommentPermission.CanDelete);
    const canChangePrivacy = permissions.includes(AgendaCommentPermission.CanChangePrivacy);

    const hasMenuOptions = canEdit || canDelete || canChangePrivacy;

    return {
      canEdit,
      canDelete,
      canChangePrivacy,
      hasMenuOptions: isServerComment && hasMenuOptions,
    };
  },
}));

interface GetCommentPermissionsParams {
  topicId: string,
  commentId: string,
  sectionId?: string,
}
interface UseAgendaPermissionsReturn {
  getTopicPermissions: (topicId: string, sectionId?: string) => GetAgendaTopicPermissionsReturn,
  getSectionPermissions: (sectionId: string) => GetAgendaSectionPermissionsReturn,
  getCommentPermissions: ({
    topicId,
    commentId,
    sectionId,
  }: GetCommentPermissionsParams) => GetAgendaCommentPermissionsReturn,
}

export const useAgendaPermissions = (huddleId: string): UseAgendaPermissionsReturn => {
  const { data } = useGetAgendas({ huddleId });

  const {
    getAgendaTopicPermissions,
    getAgendaSectionPermissions,
    getAgendaCommentPermissions,
  } = useAgendaPermissionsStore((state) => ({
    getIsSectionDisabled: state.getIsSectionDisabled,
    getAgendaTopicPermissions: state.getAgendaTopicPermissions,
    getAgendaSectionPermissions: state.getAgendaSectionPermissions,
    getAgendaCommentPermissions: state.getAgendaCommentPermissions,
  }));

  return {
    getTopicPermissions: (topicId: string, sectionId?: string) => getAgendaTopicPermissions({
      agendaTopicId: topicId,
      agendas: data?.response.agendas ?? [],
      sectionId,
    }),
    getSectionPermissions: (sectionId: string) => getAgendaSectionPermissions({
      sectionId,
      agendas: data?.response.agendas ?? [],
    }),
    getCommentPermissions: ({ topicId, commentId, sectionId }) => getAgendaCommentPermissions({
      agendaTopicId: topicId,
      commentId,
      agendas: data?.response.agendas ?? [],
      sectionId,
    }),
  };
};
