import { useQuery } from '@tanstack/react-query';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { toast } from '~Common/components/Toasts';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { calendarIntQueryKeys } from './queryKeys';

export const getAvailableCalendarsListLatencyErrorText = 'Your calendar is still loading, please check back shortly.';

const getAvailableCalendarsList = async (): Promise<HttpCallReturn<Record<string, string>>> => {
  const URL = {
    host: hosts.meeting,
    uri: `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/getAvailableCalendars`,
  };

  return getApi(URL);
};

interface useGetAvailableCalendarsListReturn {
  isLoading: boolean,
  availableCalendarsList: Record<string, string> | undefined,
  availableCalendarsListCount: number,
  hasNylasLatency: boolean,
}

export const useGetAvailableCalendarsList = (): useGetAvailableCalendarsListReturn => {
  const { data: authStatus } = useGetCalendarAuthStatus();

  const response = useQuery({
    queryKey: calendarIntQueryKeys.availableCalendars(),
    queryFn: getAvailableCalendarsList,
    enabled: authStatus === AUTH_STATUS.LINKED,
  });

  useEffect(() => {
    if (response.isSuccess && response.data?.response) {
      /*
        If there is no response from nylas, it'll return null
        If there is a response from nylas, but no available calendars, it'll be an empty object
      */
      if (response.data.response === null || isEmpty(response.data.response)) {
        toast.error('No calendar was detected, please reconnect your calendar or contact support.');
      }
    }
  }, [response.isSuccess, response.data?.response]);

  useEffect(() => {
    if (response.isError) {
      // There is a Nylas latency issue where on first time connection, it can take a couple minutes for the calendars to be returned from Nylas
      if (response.error?.message === 'No relevant calendars returned from Nylas.') {
        toast.warn(getAvailableCalendarsListLatencyErrorText);
      }
    }
  }, [response.isError, response.error?.message]);

  const availableCalendarsList = response.data?.response;
  const hasNylasLatency = response.error?.message === 'No relevant calendars returned from Nylas.';

  return {
    isLoading: response.isLoading,
    availableCalendarsList,
    availableCalendarsListCount: availableCalendarsList ? Object.entries(availableCalendarsList).length : 0,
    hasNylasLatency,
  };
};
