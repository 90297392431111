import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { postApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { CompetencyResource } from '~DevelopmentPlan/const/types';
import { ReactText, useRef } from 'react';
import { toast } from '~Common/components/Toasts';
import { CreateResourceDTO } from '~DevelopmentPlan/schemata/addResourceSchemata';
import { DEFAULT_ID, linkingResourceText, PersonalDevelopmentResourceTypeLabels } from '~DevelopmentPlan/const/defaults';
import { queryClient } from '~Common/const/queryClient';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';

interface createResourceProps {
  id: string,
  resource: CreateResourceDTO,
}

const createResource = ({ id, resource }: createResourceProps): Promise<HttpCallReturn<CompetencyResource>> => {
  const { competencyId } = resource;
  const url = `/organizations/${getOrganizationId() ?? ''}/developmentplans/${id}/competencies/${competencyId ?? DEFAULT_ID}/competencyResources`;

  return postApi(url, resource, {});
};

export const useCreateResource = (): UseMutationResult<HttpCallReturn<CompetencyResource>, unknown, createResourceProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const mutation = useMutation({
    mutationFn: createResource,
    onMutate: (variables) => {
      const resourceTypeId = variables.resource.contentTypeId;
      // @ts-expect-error yea, this is silly
      const resourceTypeName = PersonalDevelopmentResourceTypeLabels[resourceTypeId] as string;
      toastId.current = toast.info(`Linking your ${resourceTypeName}...`, { autoClose: false });
    },
    onError: (_, variables) => {
      const resourceTypeId = variables.resource.contentTypeId;
      toast.update(toastId.current, {
        render: linkingResourceText[resourceTypeId].error,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (_, variables) => {
      const resourceTypeId = variables.resource.contentTypeId;
      toast.update(toastId.current, {
        render: `${linkingResourceText[resourceTypeId].success}`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.competencyResources(variables.id) });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.progressBar(variables.id) });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.competencies(variables.id) });
    },
  });

  return mutation;
};
