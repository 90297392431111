import { AgendaType } from '@leadr-hr/types';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { agendaItemNameMap } from './index';

const styles = {
  text: css({
    color: palette.neutrals.gray700,
    fontStyle: 'italic',
    span: {
      fontStyle: 'italic',
    },
  }),
  bold: css({
    fontWeight: 600,
  }),
};

interface SeveralPeopleCreatingSameItemProps {
  agendaType: Exclude<AgendaType, AgendaType.Attachment>,
  inline?: boolean,
}

const SeveralPeopleCreatingSameItem = ({
  agendaType,
  inline = false,
  ...props
}: SeveralPeopleCreatingSameItemProps): JSX.Element => (
  <span css={styles.text} {...props}>
    {inline && (
      <>
        <span css={styles.bold}>Several people</span>
        <span>{` are adding ${agendaItemNameMap[agendaType]}s`}</span>
      </>
    )}
    {!inline && (
      <span>{`[Several people are adding ${agendaItemNameMap[agendaType]}s]`}</span>
    )}
  </span>
);

export default SeveralPeopleCreatingSameItem;
