import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from './Tooltip';

interface InfoCircleTooltipProps {
  content: string,
}

const InfoCircleTooltip = ({
  content,
  ...props
}: InfoCircleTooltipProps): JSX.Element => (
  <Tooltip content={content}>
    <div {...props}>
      <FontAwesomeIcon icon={faInfoCircle} />
    </div>
  </Tooltip>
);

export default InfoCircleTooltip;
