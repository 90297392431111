import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import LeadrLogoIndigo from '~Assets/images/leadrLogoIndigo.svg';
import { palette } from '~Common/styles/colors';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';
import { useMobileAppUpdate } from '~Root/hooks/useMobileAppUpdate';
import { redirectToAuth0 } from '../../functions/authRedirect';

const paddingX = '1.25rem';
const paddingY = '2.5rem';

const styles = {
  container: css({
    backgroundColor: palette.neutrals.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: `${paddingY} ${paddingX}`,
    width: '100%',
  }, IS_MOBILE_APP && {
    paddingTop: `calc(${paddingY} + env(safe-area-inset-top))`,
    paddingRight: `calc(${paddingX} + env(safe-area-inset-right))`,
    paddingBottom: `calc(${paddingY} + env(safe-area-inset-bottom))`,
    paddingLeft: `calc(${paddingX} + env(safe-area-inset-left))`,
  }),
  logo: css({
    height: '3rem',
    marginBottom: '1.5rem',
  }),
  stretch: css({
    alignItems: 'center',
    alignContent: 'center',
    color: palette.neutrals.gray800,
    flex: 1,
    fontSize: '0.75rem',
    justifyContent: 'center',
    padding: '0 3.25rem',
    textAlign: 'center',
  }),
  subContainer: css({
    alignContent: 'center',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    flexShrink: 0,
    justifyItems: 'center',
  }),
};

export const MobileAppLandingPage = (): JSX.Element => {
  useMobileAppUpdate();

  return (
    <div css={styles.container}>
      <div css={styles.subContainer}>
        <div css={styles.stretch}>
          <LeadrLogoIndigo
            css={styles.logo}
            title="Leadr logo"
            data-test-id="mobileAppLandingLeadrLogo"
          />
          <p>
            Better Meetings.
            <br />
            Clear Objectives.
            <br />
            Aligned Teams.
          </p>
        </div>
      </div>
      <LeadrButton
        onClick={redirectToAuth0}
        data-test-id="mobileAppBeginLoginFlow"
      >
        Get Started
      </LeadrButton>
    </div>
  );
};
