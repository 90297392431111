import { BarChart } from '~Common/components/Charts/BarChart';
import { LineGraphChart } from '~Common/components/Charts/LineGraphChart';
import { palette } from '~Common/styles/colors';
import { GraphDataset } from '~Insights/const/dataUtils';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import { styles as importedStyles } from './Expanded';

const styles = {
  ...importedStyles,
};

interface TotalEngagementGraphProps {
  startDate: Date,
  endDate: Date,
  graphData: GraphDataset[],
  isMobile: boolean,
}

export const TotalEngagementGraph = ({
  endDate,
  graphData,
  startDate,
  isMobile,
}: TotalEngagementGraphProps): JSX.Element => {
  if (!graphData.length) {
    return (
      <div css={styles.emptyState}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <img src={insightsEmptyGraph} alt="Empty graph" />
        <p css={styles.emptyStateText}>
          Data will show here as your team answers the engagement survey.
        </p>
      </div>
    );
  }
  if (startDate === endDate || graphData[0].data.length === 1) {
    const barGraphData = {
      labels: graphData.map((slice) => slice.label),
      datasets: [{
        data: graphData.map((slice) => slice.data[slice.data.length - 1]?.y),
        backgroundColor: [palette.brand.sky],
      }],
    };
    return (
      <BarChart
        height="32rem"
        isMobile={isMobile}
        data={barGraphData}
        options={{
          indexAxis: isMobile ? 'y' : 'x',
          maintainAspectRatio: false,
          scales: {
            [isMobile ? 'x' : 'y']: {
              max: 5,
              title: {
                text: 'Average Score',
                display: true,
              },
              ticks: {
                autoSkip: false,
              },
              ...(
                isMobile ? {
                  grid: {
                    display: true,
                  },
                }
                  : {}
              ),
            },
            ...(
              isMobile ? {
                y: {
                  ticks: {
                    crossAlign: 'far',
                  },
                },
              }
                : {}
            ),
          },
        }}
        tooltipOptions={{
          callbacks: {
            title(tooltipItems) {
              // Have to do this since ChartJS adds commas to the labels for some reason.
              return tooltipItems[0].label.replace(/,/g, ' ');
            },
            // @ts-expect-error : There's something funky about the typing here.
            footer: (ctx: {
              dataIndex: number; dataset: { participantInfo: string }
            }[]) => (graphData[ctx[0].dataIndex].data[0].participantInfo ?? ''),
          },
          footerMarginTop: 8,
        }}
      />
    );
  }

  return (
    <LineGraphChart
      datasets={graphData}
      isMobile={isMobile}
      options={{
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              autoSkip: isMobile,
            },
          },
          y: {
            min: 0,
            max: 5,
          },
        },
      }}
      tooltipOptions={{
        callbacks: {
          // @ts-expect-error : There's something funky about the typing here.
          footer: (ctx: { raw: { participantInfo: string }}[]) => (ctx[0]?.raw?.participantInfo ?? ''),
        },
        footerMarginTop: 8,
      }}
    />
  );
};
