import { css } from '@emotion/react';
import { Fragment, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useIsDesktopQuery } from '~Common/hooks/useMediaListener';
import { navigationItemList } from '~Root/components/OldNavigationSidebar/navigationItemList';
import {
  IsVisibleProps,
  NavigationIndicatorData,
  NavigationItem,
} from '~Root/components/OldNavigationSidebar/types';
import { useNavigationIndicators } from '~Root/components/OldNavigationSidebar/useNavigationIndicators';
import { useNavigationPermissions } from '~Root/components/OldNavigationSidebar/useNavigationPermissions';
import { ActionButton } from '~Root/components/OldNavigationSidebar/NavigationItems/ActionButton';
import { CollapsingItem } from '~Root/components/OldNavigationSidebar/NavigationItems/CollapsingItem';
import { LinkButton } from '~Root/components/OldNavigationSidebar/NavigationItems/LinkButton';
import { SHARED_STYLES } from '~Root/components/OldNavigationSidebar/NavigationItems/styles';
import { Sublist } from '~Root/components/OldNavigationSidebar/NavigationItems/Sublist';
import CustomSearchInputButton from '~Root/components/OldNavigationSidebar/NavigationItems/CustomSearchInputButton';

const styles = {
  ...SHARED_STYLES,
  skeleton: css({
    height: '2rem',
  }),
  margin: css({
    paddingTop: '0.5rem',
  }),
  overflow: css({
    overflow: 'auto',
  }),
  customSearchInputButton: css({
    margin: '.25rem 1rem',
  }),
};

interface ViewProps extends NavigationItemsProps {
  dispatch: Dispatch,
  filteredNavigationItems: NavigationItem[],
  isVisibleProps: IsVisibleProps,
  navigationIndicatorData: NavigationIndicatorData,
  useGlobalSearch: boolean,
  isDesktop: boolean,
}

const View = ({
  dispatch,
  filteredNavigationItems,
  isCollapsed,
  isVisibleProps,
  navigationIndicatorData,
  useGlobalSearch,
  isDesktop,
}: ViewProps): JSX.Element => (
  <div css={[styles.navContainer, styles.margin, styles.overflow]}>
    {useGlobalSearch && !isCollapsed && isDesktop && (
      <CustomSearchInputButton css={styles.customSearchInputButton} />
    )}
    {filteredNavigationItems.map((item) => (
      <Fragment key={item.label}>
        {!!item.href && (
          <LinkButton
            isCollapsed={isCollapsed}
            item={item}
            navigationIndicatorData={navigationIndicatorData}
          />
        )}
        {!item.href && !item.children && (
          <ActionButton
            dispatch={dispatch}
            isCollapsed={isCollapsed}
            isVisibleProps={isVisibleProps}
            item={item}
          />
        )}
        {!item.href && !!item.children && (
          <CollapsingItem
            isCollapsed={isCollapsed}
            item={item}
            renderChildren={() => (
              <Sublist
                dispatch={dispatch}
                isCollapsed={isCollapsed}
                isVisibleProps={isVisibleProps}
                item={item}
                navigationIndicatorData={navigationIndicatorData}
              />
            )}
          />
        )}
      </Fragment>
    ))}
  </div>
);

interface NavigationItemsProps {
  isCollapsed: boolean,
}

export const NavigationItems = ({
  isCollapsed,
}: NavigationItemsProps): JSX.Element => {
  const isVisibleProps = useNavigationPermissions(isCollapsed);
  const navigationIndicatorData = useNavigationIndicators();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktopQuery();

  const useGlobalSearch = useFeatureFlag('useGlobalSearch');

  const filteredNavigationItems = useMemo(() => (
    navigationItemList.filter((item) => (
      typeof item.isVisible === 'undefined'
      || (typeof item.isVisible === 'boolean' && item.isVisible)
      || item.isVisible(isVisibleProps)
    ))
  ), [isVisibleProps]);

  const hookProps = {
    dispatch,
    filteredNavigationItems,
    isCollapsed,
    isVisibleProps,
    navigationIndicatorData,
    useGlobalSearch,
    isDesktop,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
