import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { pick } from 'lodash';

import { SurveyTemplate, useDeleteSurveyTemplate } from '~Surveys/Hooks/useSurveyTemplates';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { palette } from '~Common/styles/colors';
import { SurveyQuestionPreview } from '~Surveys/Components/Cards/SurveyQuestionPreview';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { forMobileObject } from '~Common/styles/mixins';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { createSurveyTemplate } from '../../Create';
import { SurveySummaryCard } from './SurveySummaryCard';
import { editSurveyTemplatesTemplate } from '../EditTemplateDrawer';

export const surveyTemplateDetailsTemplate = {
  name: 'SURVEY_TEMPLATES_DETAILS',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  button: css({
    div: {
      justifyContent: 'center',
    },
  }, forMobileObject({
    width: '100%',
  })),
  buttonContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75rem',
    padding: '0.5rem 1.5rem 0.5rem 1rem',
  }, forMobileObject({
    flexDirection: 'column',
  })),
  cushion: css({
    flex: 1,
  }, forMobileObject({
    display: 'none',
  })),
  drawerBody: css({
    padding: '1rem',
    paddingRight: '1.5rem',
  }),
  heading: css({
    color: palette.neutrals.gray500,
    fontSize: '0.625rem',
    letterSpacing: '0.2em',
    marginBottom: '1.25rem',
    marginTop: '1.75rem',
    paddingTop: '0.6rem',
    textTransform: 'uppercase',
  }),
  questionList: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
  }),
};

interface ViewProps extends SurveyTemplateDetailsDrawerProps {
  canDeleteTemplate: boolean,
  closeDrawerClick: () => void,
  createdBy: string,
  isTemplateDeleting: boolean,
  newSurveyFromTemplate: () => void,
  onClickDelete: () => void,
  onClickEdit: () => void,
}

export const View = ({
  canDeleteTemplate,
  closeDrawerClick,
  createdBy,
  isTemplateDeleting,
  newSurveyFromTemplate,
  onClickDelete,
  onClickEdit,
  template,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title="Survey Template"
        renderCloseButton={(closeButtonStyles) => (
          <IconButton
            css={closeButtonStyles}
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            tooltip="Close"
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <SurveySummaryCard
          template={template}
          createdBy={createdBy}
        />
        <p css={styles.heading}>Questions</p>
        <div css={styles.questionList}>
          {template.questions.map((question) => (
            <SurveyQuestionPreview
              key={question.id}
              question={question}
            />
          ))}
        </div>
      </div>
    )}
    renderFooter={() => (
      <div css={styles.buttonContainer}>
        <LeadrButton
          css={styles.button}
          onClick={newSurveyFromTemplate}
          data-test-id="surveysTemplatesStartWithThisTemplate"
        >
          Start With This Template
        </LeadrButton>
        {canDeleteTemplate && (
          <LeadrButton
            css={styles.button}
            variant="ghost"
            onClick={onClickEdit}
            data-test-id="surveysTemplatesEditTemplate"
          >
            Edit
          </LeadrButton>
        )}
        <div css={styles.cushion} />
        {canDeleteTemplate && (
          <DeleteButtonWithConfirmation
            renderDeleteButton={({ onClick }) => (
              <SpinnerButton
                css={styles.button}
                variant="outline"
                color="danger"
                label="Delete"
                isLoading={isTemplateDeleting}
                loadingLabel="Deleting"
                onClick={onClick}
              />
            )}
            renderConfirmationButtons={({
              informationStyles,
              optionStyles,
              popoverButtonStyles,
            }) => (
              <DeleteConfirmationButtons
                onDelete={onClickDelete}
                informationStyles={informationStyles}
                optionStyles={optionStyles}
                popoverButtonStyles={popoverButtonStyles}
              />
            )}
          />
        )}
      </div>
    )}
  />
);

interface SurveyTemplateDetailsDrawerProps {
  template: SurveyTemplate,
}

export const SurveyTemplateDetailsDrawer = ({
  template,
  ...props
}: SurveyTemplateDetailsDrawerProps): JSX.Element => {
  const dispatch = useDispatch();
  const organizationId = getOrganizationId() ?? '';
  const orgUserId = getOrganizationUserId() ?? '';
  const { isAdmin } = useUserPermissions();

  const canDeleteTemplate = template.organizationUid === organizationId && (isAdmin || orgUserId === template.createdByUid);

  const { mutate: deleteTemplateMutation, isPending: isTemplateDeleting } = useDeleteSurveyTemplate({
    onSuccess: () => {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ drawerName: surveyTemplateDetailsTemplate.name }));
    },
  });

  const newSurveyFromTemplate = (): void => {
    const survey = pick(template, ['title', 'objective']);
    const surveyQuestions = template.questions.map((question, index) => ({
      ...pick(question, ['description', 'question']),
      configuration: typeof question.configuration === 'string' && question.configuration !== ''
        ? JSON.parse(question.configuration) as Record<string, unknown>
        : question.configuration,
      type: question.type.key,
      rank: index,
    }));

    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...createSurveyTemplate,
        args: {
          injectedState: {
            survey,
            surveyQuestions,
          },
        },
      },
    }));
  };

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ drawerName: surveyTemplateDetailsTemplate.name }));
  };

  const onClickEdit = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...editSurveyTemplatesTemplate,
        args: {
          initialTemplate: template,
          isEditing: true,
        },
      },
    }));
  };

  const onClickDelete = (): void => {
    deleteTemplateMutation(template.id);
  };

  const firstName = template?.createdBy?.firstName ?? 'Leadr';
  const lastName = template?.createdBy?.lastName ?? 'User';

  const hookProps = {
    canDeleteTemplate,
    closeDrawerClick,
    createdBy: template.organizationUid ? `${firstName} ${lastName}` : 'Leadr',
    isTemplateDeleting,
    newSurveyFromTemplate,
    onClickDelete,
    onClickEdit,
    template,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

registerDrawer({
  templateName: surveyTemplateDetailsTemplate.name,
  component: SurveyTemplateDetailsDrawer,
});
