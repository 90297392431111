import { startCase } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { SerializedStyles, css } from '@emotion/react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { FormControl, FormHelperText } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { palette, drawerInputBackground } from '~Common/styles/colors';

const styles = {
  checkboxLabel: css({
    fontSize: '.75rem',
    textTransform: 'uppercase',
    marginTop: '1rem',
    color: palette.neutrals.gray600,
    fontWeight: 400,
    letterSpacing: '.2rem',

    '&.Mui-focused': {
      color: palette.neutrals.gray700,
    },
  }),
  radioFormControl: (vertical:boolean) => css({
    width: '100%',

    '& .MuiFormGroup-root': {
      width: '100%',
      flexDirection: vertical ? 'column' : 'row',
      justifyContent: 'space-between',
      gap: '0.5rem',

    },
  }),

  '.MuiFormGroup-root': {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  '.MuiFormControlLabel-root': {
    flex: '1',
  },
  radioLabel: css({
    background: drawerInputBackground,
    flex: 1,
    border: `2px solid ${palette.neutrals.gray100}`,
    borderRadius: '.5rem',
    padding: '1rem',
    margin: '0 0 .5rem 0',
    alignContent: 'center',

    '&:has(.Mui-checked)': {
      background: 'none !important',
    },

    '& .MuiRadio-root': {
      padding: '0 .75rem 0 0',
    },
    '& svg': {
      // color: palette.brand.indigo,
      color: palette.neutrals.gray300,
    },
    '& .Mui-disabled svg': {
      color: palette.neutrals.gray400,
    },
    '& .MuiTypography-root': {
      fontSize: '.875rem',
      color: palette.neutrals.gray700,
      fontWeight: 500,

      '& span': {
        color: palette.neutrals.gray800,
      },
    },
    '& .Mui-checked': {
      // background: `${palette.neutrals.white} !important`,

      '& svg': {
        color: palette.brand.indigo,
      },
    },
  }),
};

interface MultiRadioProps extends Omit<MuiRadioProps, 'onChange'> {
  label?: string,
  name: string,
  defaultValue?: number | string,
  radios: {
    name: string | JSX.Element,
    value: number | string,
  }[],
  vertical?: boolean,
  disabled?: boolean,
  radioStyles?: SerializedStyles,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void,
}

export function MultiRadio({
  name,
  label = startCase(name),
  defaultValue,
  radios,
  disabled = false,
  vertical = false,
  radioStyles,
  onChange,
  ...props
}: MultiRadioProps): JSX.Element {
  // have to do this becuase there is an issue with destructoring since I'm pulling this in on a none submitted form
  const formContext = useFormContext();
  const { formState: { errors } } = useFormContext();
  const register = formContext?.register;

  const error = errors[name] as { message: string; };
  return (
    <>
      <FormControl
        error={!!error}
        css={[styles.radioFormControl(vertical), radioStyles]}
      >
        <FormLabel
          css={styles.checkboxLabel}
        >
          {label}
        </FormLabel>
        <RadioGroup
          defaultValue={defaultValue}
          name={`radio-buttons-group-${label}`}
          onChange={onChange}
        >
          {radios.map((radio) => (
            <FormControlLabel
              css={styles.radioLabel}
              key={radio.value}
              value={radio.value}
              label={radio.name}
              disabled={disabled}
              control={<MuiRadio {...props} {...register(name)} />}
            />
          ))}
        </RadioGroup>
        {error && <FormHelperText error>{error.message}</FormHelperText>}
      </FormControl>
    </>
  );
}
