import MuiDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import MomentUtils from '@mui/lab/AdapterMoment';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import PickerInput from '~Deprecated/ui/components/Pickers/PickerInput';
import { usePrevious } from '~Deprecated/hooks/usePrevious';

const DatePicker = ({
  label,
  selectedDate,
  onDateSelected,
  name,
  className,
  iconType,
  size,
  disablePast,
  disabled,
  ...props
}) => {
  const wrapperRef = useRef(null);
  const [date, setDate] = useState(selectedDate);
  const [isPickerOpen, togglePicker] = useState(false);
  const previousSelectedDate = usePrevious(selectedDate);

  const onInputClick = () => {
    togglePicker(true);
  };

  const closePicker = () => {
    togglePicker(false);
  };

  const onSelect = (d) => {
    setDate(d?.toDate());
    onDateSelected({ date: d?.toDate(), name });
    closePicker();
  };

  const renderInput = (inputProps) => (
    <PickerInput
      {...inputProps}
      size={size}
      className={className}
      onInputClick={onInputClick}
      name={name}
      date={date}
      format="MM/DD/YYYY"
      iconType={iconType}
      {...props}
    />
  );

  useEffect(() => {
    if (selectedDate && selectedDate !== previousSelectedDate) {
      setDate(moment(selectedDate)?.toDate());
    }
  }, [selectedDate, previousSelectedDate]);

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <MuiDatePicker
        ref={wrapperRef}
        disableToolbar
        disablePast={disablePast}
        label={label}
        value={date}
        open={isPickerOpen}
        onClose={closePicker}
        onChange={onSelect}
        renderInput={renderInput}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        clearable
        disabled={disabled}
        {...props}
      />
    </LocalizationProvider>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  selectedDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onDateSelected: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  iconType: PropTypes.string,
  size: PropTypes.string,
  disablePast: PropTypes.bool,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  label: 'Date Picker',
  selectedDate: undefined,
  onDateSelected: () => {
  },
  className: '',
  name: '',
  iconType: null,
  size: undefined,
  disablePast: false,
  disabled: false,
};

export default DatePicker;
