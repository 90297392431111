import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';

export const MobileDeepLinkHandler = (): JSX.Element => {
  const history = useHistory();
  useEffect(() => {
    void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const { url } = event;
      const split = url.split('//');
      const slug = split.pop();

      if (slug) {
        history.push(slug);
        void Browser.close();
      }

      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, [history]);

  return <></>;
};
