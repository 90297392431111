import { useEffect } from 'react';
import { PRINT_BASE_ROUTE } from '~Common/const/routes';

export const usePrintView = () => ({
  onPrint: ({ location }) => window.open(`${PRINT_BASE_ROUTE}${location}`, '_blank'),
});

export const usePrintPage = (printCallback) => {
  useEffect(() => {
    const printCapture = (event) => {
      if (/Mac OS X/.test(navigator.userAgent)) {
        if (event.metaKey && (event.key === 'p' || event.key === 'P')) {
          event.preventDefault();
          event.stopPropagation();
          printCallback();
        }
      } else if (event.ctrlKey && (event.key === 'p' || event.key === 'P')) {
        event.preventDefault();
        event.stopPropagation();
        printCallback();
      }
    };

    document.addEventListener('keydown', printCapture);

    return () => document.removeEventListener('keydown', printCapture);
  }, [printCallback]);
};
