import { PersonDisplayInformation } from '~Common/const/interfaces';
import { AgendaType } from '@leadr-hr/types';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { agendaItemNameMap } from './index';

const styles = {
  text: css({
    color: palette.neutrals.gray700,
    fontStyle: 'italic',
    span: {
      fontStyle: 'italic',
    },
  }),
  bold: css({
    fontWeight: 600,
    fontStyle: 'inherit',
  }),
};

interface TwoPeopleCreatingItemProps {
  person1: PersonDisplayInformation,
  person2: PersonDisplayInformation,
  agendaType: Exclude<AgendaType, AgendaType.Attachment>,
  inline?: boolean,
}

const TwoPeopleCreatingItems = ({
  person1,
  person2,
  agendaType,
  inline = false,
  ...props
}: TwoPeopleCreatingItemProps): JSX.Element => (
  <span css={styles.text} {...props}>
    {inline && (
      <>
        <span css={styles.bold}>{`${person1.firstName} ${person1.lastName}`}</span>
        <span> and </span>
        <span css={styles.bold}>{`${person2.firstName} ${person2.lastName}`}</span>
        <span>{` are adding ${agendaItemNameMap[agendaType]}s`}</span>
      </>
    )}
    {!inline && (
      <>
        {`[${person1.firstName} ${person2.lastName} and ${person2.firstName} ${person2.lastName} are adding ${agendaItemNameMap[agendaType]}s]`}
      </>
    )}
  </span>
);

export default TwoPeopleCreatingItems;
