import { useQuery } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import {
  AllCycles, ReviewCycleWithParticipants, ReviewCycleStatusEnum,
} from '../Const/types';
import { EXTENDED_QUERY_STALE_TIME } from '../Const/defaults';

interface getCycleByIdProps {
    queryKey: string[],
  }

const getOrgCycles = ({ queryKey }: getCycleByIdProps): Promise<HttpCallReturn<ReviewCycleWithParticipants[]>> => {
  const [orgId, , version] = queryKey;

  const serverUrl = {
    host: getHost(hosts.reviewCycles, version),
    uri: `/organizations/${orgId}/reviewCycles`,
  };

  return getApi<ReviewCycleWithParticipants[]>(serverUrl, {});
};

interface useGetOrgCyclesProps {
  queryKey?: string[],
}

interface useGetOrgCyclesReturnProps {
  data: ReviewCycleWithParticipants[] | undefined,
  statusSortedCycles: AllCycles,
  isLoading: boolean,
}

export const useGetOrgCycles = ({ queryKey = [getOrganizationId() ?? '', 'reviewCycles'] }: useGetOrgCyclesProps): useGetOrgCyclesReturnProps => {
  const version = '2.5';
  const { isAdmin } = useUserPermissions();
  const result = useQuery({
    queryKey: [...queryKey, version],
    queryFn: getOrgCycles,
    staleTime: EXTENDED_QUERY_STALE_TIME,
    enabled: isAdmin,
  });

  const statusSortedCycles: AllCycles = {
    Active: [],
    Draft: [],
    Completed: [],
  };
  const cycles = result?.data?.response;

  if (cycles) {
    cycles.forEach((cycle: ReviewCycleWithParticipants) => {
      if (cycle.statusEnum === ReviewCycleStatusEnum.Draft || cycle.statusEnum === ReviewCycleStatusEnum.Unknown) {
        statusSortedCycles.Draft.push(cycle);
      } else if (cycle.statusEnum === ReviewCycleStatusEnum.Active || cycle.statusEnum === ReviewCycleStatusEnum.AllSubmitted) {
        statusSortedCycles.Active.push(cycle);
      } else if (cycle.statusEnum === ReviewCycleStatusEnum.Finished) {
        statusSortedCycles.Completed.push(cycle);
      }
    });
  }

  return {
    isLoading: result?.isLoading,
    data: result?.data?.response as ReviewCycleWithParticipants[],
    statusSortedCycles,
  };
};
