import { HTMLString } from '~Common/types';

export enum VisibilityType {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export interface TeamClarityQuestionBase {
  question: string,
  rank: number,
}

export interface TeamClarityAnswer {
  rank: number,
  answer: string,
}

export interface TeamClarityQuestion extends TeamClarityQuestionBase {
  answer: HTMLString,
}

export interface CreateEditTeamPayload {
  name: string,
  description: string,
  ownerOrgUserIds: string[],
  memberOrgUserIds: string[],
  teamClarityAnswerList: TeamClarityAnswer[],
  visibilityType: VisibilityType,
}

export enum TeamsListScope {
  MyTeams = 'MY_TEAMS',
  AllTeams = 'ALL_TEAMS',
  OwnedTeams = 'OWNED_TEAMS',
}

export enum TeamRowAction {
  ViewTeam = 'VIEW_TEAM',
  DeleteTeam = 'DELETE_TEAM',
}

export interface TeamRowAllowedAction {
  actionName: TeamRowAction,
  rank: number,
}

export interface TeamRow {
  teamId: string,
  name: string,
  description: string,
  memberCount: number,
  hasOwner: boolean,
  visibilityType: VisibilityType,
  allowedActions: TeamRowAllowedAction[],
  isOwner: boolean,
}

export enum TeamRole {
  Owner = 'OWNER',
  Member = 'MEMBER',
}

export enum TeamUserRowAction {
  ViewUser = 'VIEW_USER',
  ChangeToOwner = 'CHANGE_TO_OWNER',
  ChangeToMember = 'CHANGE_TO_MEMBER',
  RemoveFromTeam = 'REMOVE_TEAM_MEMBER',
}

export interface TeamUserRowAllowedAction {
  actionName: TeamUserRowAction,
  rank: number,
}

export interface TeamDetails {
  id: string,
  name: string,
  description: string,
  canEdit: boolean,
  memberOrgUserIds: string[],
  ownerOrgUserIds: string[],
  teamClarityAnswerList: TeamClarityAnswer[],
  visibilityType: VisibilityType,
  thematicGoalQuestionRank: number,
}

export interface TeamUserRow {
  orgUserId: string,
  firstName: string,
  lastName: string,
  profileImageUrl: string,
  department: string,
  jobTitle: string,
  teamRole: TeamRole,
  allowedActions: TeamUserRowAllowedAction[],
}
