import { useMemo } from 'react';
import { css } from '@emotion/react';
import moment from 'moment';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { SetDisplay } from '~Insights/const/types';

import { useInsightsUserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { LeadrCoachPrompt, translateLeadrCoachUserType, useLeadrCoachPrompts } from '~Insights/hooks/useLeadrCoachPrompts';
import {
  CARD_STYLES,
  DASHBOARD_CARD_COMPONENTS,
  TINY_CARD_COMPONENTS,
} from '~Insights/const/cardStyles';
import { palette } from '~Common/styles/colors';

import Card from '~Common/V3/components/Card';
import yellowQuestionMark from '~Assets/images/empty-views/yellowQuestionMark.png';
import ExpandButton from '../../buttons/ExpandButton';
import InsightCardTitle from '../../InsightCardTitle';
import { HookedExpanded } from './Expanded';

const styles = {
  tiny: css({
    ...CARD_STYLES.tiny,
  }),
  dashboard: css({
    ...CARD_STYLES.dashboard,
  }),
  ...TINY_CARD_COMPONENTS,
  ...DASHBOARD_CARD_COMPONENTS,
  dashboardUl: css({
    color: palette.neutrals.gray700,
    fontSize: '0.875rem',
    fontWeight: 300,

    '& li': {
      marginTop: '1.25rem',
    },
  }),
  emptyImage: css({
    height: '100%',
    maxHeight: '16rem',
    maxWidth: '16rem',
    width: '100%',
  }),
  tinyNumber: css({
    fontSize: '2rem',
    fontWeight: 600,
  }),
};

export interface SharedProps {
  prompts: LeadrCoachPrompt[],
  isMobile: boolean,
}

export interface ViewProps {
  prompts: LeadrCoachPrompt[],
}

const TinyView = ({
  prompts,
}: ViewProps): JSX.Element => (
  <Card
    css={styles.tiny}
    renderContents={() => (
      <div css={styles.tinyContainer}>
        <div css={[styles.header(false), styles.smallHeader]}>
          <ExpandButton css={styles.expandButton(false)} cardKey="coach" />
          <InsightCardTitle>Leadr Coach</InsightCardTitle>
        </div>
        <div css={styles.tinyBody}>
          <p css={styles.tinyNumber}>
            {prompts.length ? prompts.length : 'no'}
          </p>
          <p>prompts available</p>
        </div>
      </div>
    )}
  />
);

const DashboardView = ({
  prompts,
}: ViewProps): JSX.Element => (
  <Card
    css={styles.dashboard}
    renderContents={() => (
      <div>
        <div css={styles.header(false)}>
          <ExpandButton css={styles.expandButton(false)} cardKey="coach" />
          <InsightCardTitle>Leadr Coach</InsightCardTitle>
        </div>
        {prompts.length > 0 && (
          <ul css={styles.dashboardUl}>
            {prompts.slice(0, 2).map((prompt) => (
              <li key={prompt.created.toString()}>
                {moment(prompt.created).format('LL')}
                {' - '}
                {prompt.prompt.prompt}
              </li>
            ))}
          </ul>
        )}
        {!prompts.length && (
          <div css={styles.emptyState}>
            {/* eslint-disable @typescript-eslint/no-unsafe-assignment */}
            <img
              src={yellowQuestionMark}
              css={styles.emptyImage}
              alt="Empty box with yellow question mark"
            />
            {/* eslint-enable @typescript-eslint/no-unsafe-assignment */}
          </div>
        )}
      </div>
    )}
  />
);

interface LeadrCoachCardProps {
  size: 'tiny' | 'dashboard' | 'expanded'
}

const LeadrCoachCard = ({
  size,
  ...props
}: LeadrCoachCardProps): JSX.Element => {
  const { userPerspective } = useInsightsUserPerspective();
  const { data } = useLeadrCoachPrompts();

  const prompts = useMemo(
    () => data?.filter((prompt) => translateLeadrCoachUserType(prompt.prompt.userType) === userPerspective) ?? [],
    [userPerspective, data],
  );

  const [setDisplay] = useQueryParamState<SetDisplay>('insights', 'setDisplay', SetDisplay.Desktop);
  const checkMobileQuery = useIsMobileQuery();

  const hookProps = {
    prompts,
    isMobile: setDisplay === SetDisplay.Mobile || checkMobileQuery === true,
  };

  if (size === 'tiny') {
    return (
      <TinyView
        {...hookProps}
        {...props}
      />
    );
  }

  if (size === 'expanded') {
    return (
      <HookedExpanded
        {...hookProps}
      />
    );
  }

  return (
    <DashboardView
      {...hookProps}
      {...props}
    />
  );
};

export { TinyView, DashboardView };
export default LeadrCoachCard;
