interface UseDueDateFromNowReturn {
  getShortFromNowString: (fromNowString: string) => string,
}

export const useShortenFromNowString = (): UseDueDateFromNowReturn => {
  const getShortFromNowString = (fromNowString: string): string => (
    fromNowString
      .replace('seconds', 'sec')
      .replace('second', 'sec')
      .replace('minutes', 'min')
      .replace('minute', 'min')
      .replace('hours', 'hrs')
      .replace('hour', 'hr')
      .replace('years', 'yrs')
      .replace('year', 'yr')
      .replace('an', '1')
  );

  return {
    getShortFromNowString,
  };
};
