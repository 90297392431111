import LayoutBridge from '~Deprecated/ui/routes/layouts/LayoutBridge';
import NewGetContent from '~Deprecated/ui/routes/layouts/NewGetContent';
import SettingsCard from '~Settings/components/SettingsCard';

const Settings = (): JSX.Element => (
  <LayoutBridge
    Component={(
      <NewGetContent
        ChildComponent={SettingsCard}
      />
    )}
  />
);

export default Settings;
