import { faPenToSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { css } from '@emotion/react';
import { useShowItemEditor } from '../../stores/useShowItemEditor';

const styles = {
  editButton: css({
    padding: 0,
  }),
};

interface ViewProps extends Omit<LeadrButtonProps<'button'>, 'data-test-id'> {
  handleEditAgendaTopic: () => void,
}

const View = ({
  handleEditAgendaTopic,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrButton
    css={styles.editButton}
    data-test-id="meetingsTemplatesEditAgendaTopic"
    onClick={handleEditAgendaTopic}
    variant="icon"
    size="small"
    textButtonColor={palette.neutrals.gray700}
    {...props}
  >
    <FontAwesomeIcon icon={faPenToSquare} />
  </LeadrButton>
);

interface EditButtonProps {
  topicLocalId: string,
}

const EditButton = ({
  topicLocalId,
  ...props
}: EditButtonProps): JSX.Element => {
  const showItemEditor = useShowItemEditor((state) => state.showItemEditor);

  const handleEditAgendaTopic = (): void => {
    showItemEditor(topicLocalId);
  };

  const hookProps = {
    handleEditAgendaTopic,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default EditButton;
