import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import { PersonDisplayInformation } from '~Common/const/interfaces';
import { BASE_ROUTE } from '~Common/const/routes';
import { palette } from '~Common/styles/colors';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import AvatarMaybeMultipleMaybeNot from '~Common/V3/AvatarMaybeMultipleMaybeNot';
import OverflowAvatar, { OverflowAvatarProps } from '~Common/V3/AvatarMaybeMultipleMaybeNot/OverflowAvatar';
import RecognitionMessaging from '~Recognition/components/RecognitionMessaging';
import { PAGE_STYLES } from '~Recognition/const/pageStyles';
import { RecognitionShape, RecognitionUserShape } from '~Recognition/const/types';

const styles = {
  ...PAGE_STYLES,
  container: css({
    padding: '0.5rem, 0',
    margin: '0.5rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  avatar: css({
    marginRight: '0.25rem',
    padding: 0,
    border: 0,
    background: 'transparent',
  }),
  overflowAvatar: css({
    background: palette.neutrals.gray600,
    fontSize: '10px',
  }),
  column: css({
    display: 'flex',
    flexdirection: 'column',
    flexBasis: '100%',
    gap: '1.875rem',
  }),
  text: css({
    display: 'flex',
    marginLeft: '0 0.5rem',
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
    flex: 2,
  }),
  avatars: css({
    flex: 1,
    justifyContent: 'flex-end',
  }),
};

interface RecognitionListItemViewInterface {
  recognition: RecognitionShape,
  recipients: RecognitionUserShape[],
  totalRecipients: number,
  loggedInUserRecognized: boolean,
  createdBy: RecognitionUserShape,
}
const View = ({
  recognition,
  recipients,
  totalRecipients,
  loggedInUserRecognized,
  createdBy,
}: RecognitionListItemViewInterface):JSX.Element => (
  <>
    <Link
      key={recognition.recognitionId}
      css={styles.removeLink}
      to={`${BASE_ROUTE}recognition/${recognition.recognitionId}`}
    >
      <div css={styles.container} key={recognition.recognitionId}>
        <div css={[styles.column, styles.text]}>
          <RecognitionMessaging
            totalRecipients={totalRecipients}
            loggedInUserRecognized={loggedInUserRecognized}
            recipients={recipients}
            createdBy={createdBy}
            bold
            isHome
          />
        </div>
        <div css={[styles.column, styles.avatars]}>
          <AvatarMaybeMultipleMaybeNot
            usersInfo={recipients as unknown as PersonDisplayInformation[]}
            numberOfUsers={recipients.length}
            numberOfUsersToShow={4}
            avatarHeight={25}
            avatarWidth={25}
            renderAvatar={({ user, avatarHeight, avatarWidth }) => (
              <div css={styles.avatar}>
                <AvatarWithTooltip
                  firstName={user.firstName}
                  lastName={user.lastName}
                  profileImageUrl={user.profileImageUrl}
                  height={avatarHeight}
                  width={avatarWidth}
                />
              </div>
            )}
            renderOverflowAvatar={(overflowAvatarProps: OverflowAvatarProps) => (
              <OverflowAvatar
                css={[styles.avatar, styles.overflowAvatar]}
                {...overflowAvatarProps}
              />
            )}
          />
        </div>
      </div>
    </Link>
  </>
);

interface RecognitionListItemProps {
  recognition: RecognitionShape,
}
const RecognitionListItem = ({ recognition }: RecognitionListItemProps):JSX.Element => {
  const loggedInOrgId = getOrganizationUserId();
  const totalRecipients = recognition.recipients.length;
  const loggedInUserRecognized = recognition.recipients.some((recipient) => recipient.orgUserId === loggedInOrgId);
  const { recipients, createdBy } = recognition;

  const hookProps = {
    recognition,
    recipients,
    totalRecipients,
    createdBy,
    loggedInUserRecognized,
  };
  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default RecognitionListItem;
