import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from '~Common/components/Tooltip';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import { IsVisibleProps, NavigationItem } from '../types';
import { useNavigationStore } from '../useNavigationStore';
import { SHARED_STYLES } from './styles';

const styles = {
  ...SHARED_STYLES,
  button: css({
    width: '100%',
  }),
};

interface ViewProps extends Omit<LeadrButtonProps<any>, 'renderContents'> {
  compact: boolean,
  item: NavigationItem,
  onClick: (e: MouseEvent<HTMLButtonElement>) => void,
}

const View = ({
  compact,
  item,
  onClick,
  ...props
}: ViewProps): JSX.Element => (
  <Tooltip
    key={item.label}
    content={compact ? item.label : null}
    placement="right"
  >
    <LeadrButton
      {...props}
      variant="text"
      css={[styles.button, styles.navItem(compact)]}
      onClick={onClick}
      data-label={item.label}
      data-test-id={item.testId}
    >
      <FontAwesomeIcon icon={item.icon} css={styles.icon} />

      {!compact && (
        <div css={styles.labelContainer}>
          {item.label}
        </div>
      )}

      {!compact && item.isExternalLink && (
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      )}
    </LeadrButton>
  </Tooltip>
);

interface ActionButtonProps extends Omit<LeadrButtonProps<any>, 'onClick'> {
  compact: boolean,
  isVisibleProps: IsVisibleProps,
  item: NavigationItem,
}

export const ActionButton = ({
  compact,
  isVisibleProps,
  item,
  ...props
}: ActionButtonProps): JSX.Element => {
  const dispatch = useDispatch();
  const closeMobileNavigation = useNavigationStore((state) => state.closeMobileNavigation);
  const onClick = (e: MouseEvent<HTMLButtonElement>): void => {
    closeMobileNavigation();
    item.onClick?.({ ...isVisibleProps, dispatch, e });
  };

  const hookProps = {
    compact,
    item,
    onClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
