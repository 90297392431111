import { css, SerializedStyles } from '@emotion/react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken } from 'polished';
import Button from '~Common/V3/components/Button';
import {
  useInput,
  UseInputInputSlotProps,
  UseInputParameters,
  UseInputRootSlotProps,
} from '@mui/base';
import { ChangeEvent, ComponentProps, useRef } from 'react';
import { palette } from '~Common/styles/colors';
import { CommonComponentSharedProps } from '~Common/const/interfaces';
import debounce from 'lodash/debounce';
import { DEFAULT_SEARCH_FIELD_DEBOUNCE_TIME } from './const';

const styles = {
  leadrSearchField: (hasFocus: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.neutrals.white,
    border: `1px solid ${palette.neutrals.gray300}`,
    padding: '.6563rem 1rem',
    borderRadius: '0.5rem',
    height: '2.8125rem',
  }, hasFocus && {
    border: `1px solid ${palette.brand.sky}`,
  }),
  leftIcon: css({
    color: palette.neutrals.gray500,
    width: '1.25rem',
    height: '1.25rem',
  }),
  clearButton: (showClearButton: boolean) => css({
    padding: 0,
    marginLeft: '1rem',
    color: palette.neutrals.gray400,
    display: 'flex',

    '&:hover, &:focus': {
      color: darken(0.1, palette.neutrals.gray400),
    },
  }, !showClearButton && {
    visibility: 'hidden',
  }),
  clearIcon: css({
    width: '1rem',
    height: '1rem',
  }),
  input: css({
    marginLeft: '1rem',
    padding: 0,
    border: 0,
    fontSize: '1rem',
    height: '1.5rem !important',
    lineHeight: '1.5rem',
    color: palette.neutrals.gray800,
    fontWeight: 500,
    background: 'transparent',
    width: '100%',
    '::placeholder': { /* Most modern browsers support this now. */
      color: palette.neutrals.gray500,
    },
    '::-webkit-input-placeholder': { /* WebKit, Blink, Edge */
      color: palette.neutrals.gray500,
    },
    ':-moz-placeholder': { /* Mozilla Firefox 4 to 18 */
      color: palette.neutrals.gray500,
    },
    '::-moz-placeholder': { /* Mozilla Firefox 19+ */
      color: palette.neutrals.gray500,
    },
    '::-ms-input-placeholder': { /* Microsoft Edge */
      color: palette.neutrals.gray500,
    },
  }),
};

interface ViewProps extends CommonComponentSharedProps {
  hasFocus: boolean,
  rootProps: UseInputRootSlotProps,
  searchInputProps: UseInputInputSlotProps | ComponentProps<'input'>,
  className?: string,
  showClearButton: boolean,
  handleClearSearch: () => void,
  placeholder?: string,
  renderLeftIcon?: (iconStyles: SerializedStyles) => JSX.Element,
}

const View = ({
  hasFocus,
  rootProps,
  className,
  showClearButton = false,
  handleClearSearch,
  searchInputProps,
  placeholder = 'Search...',
  'data-test-id': dataTestId,
  renderLeftIcon = () => <FontAwesomeIcon css={styles.leftIcon} icon={faSearch} />,
}: ViewProps): JSX.Element => (
  <div data-test-id={dataTestId} css={styles.leadrSearchField(hasFocus)} className={className} {...rootProps}>
    {renderLeftIcon(styles.leftIcon)}
    <input css={styles.input} placeholder={placeholder} {...searchInputProps} />
    <Button
      css={styles.clearButton(showClearButton)}
      variant="text"
      onClick={handleClearSearch}
      data-test-id={`${dataTestId}-clearButton`}
      renderContents={() => <FontAwesomeIcon css={styles.clearIcon} icon={faCircleXmark} />}
    />
  </div>
);

interface LeadrSearchFieldProps extends UseInputParameters,
  Omit<ViewProps, 'hasFocus' | 'inputProps' | 'rootProps' | 'showClearButton' | 'handleClearSearch' | 'searchInputProps'> {
  debounceTime?: number,
  inputProps?: ComponentProps<'input'>,
}

/**
 * To check out examples, see the examples.md file in the directory of this component
  @param onChange - The onChange event handler for the search field
  @param debounceTime - The amount of time to wait before triggering the onChange event handler after the user stops typing
  @param data-test-id - Used for automated tests, example format "feature(subFeature)SearchField", example "reviewsReflectionsSearchField"
  @param placeholder - Defaults to "Search...", don't change without explicit permission from design
  @param className - Passed in as css={styles.whateverStyles}, should only be used for positioning the component, not modifying the look
  @param value - If this is passed in, the component is controlled. Use createSearchStore, there is an example in the examples.md file
  @param inputProps - Props to pass to the input element, like maxLength, be careful that you don't pass things that the useInput hook needs
*/
const LeadrSearchField = ({
  onChange,
  debounceTime = DEFAULT_SEARCH_FIELD_DEBOUNCE_TIME,
  value,
  inputProps,
  ...props
}: LeadrSearchFieldProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange?.(event);
  };

  const debouncedHandleSearchTextChange = debounce(handleSearchTextChange, debounceTime);

  const {
    getRootProps,
    getInputProps,
    focused: hasFocus,
  } = useInput({
    // If the parent passes in a value, then this is a controlled component and they are responsible for debouncing
    onChange: value === undefined ? debouncedHandleSearchTextChange : handleSearchTextChange,
    inputRef,
    value,
    ...props,
  });

  const showClearButton = !!inputRef.current?.value;

  const searchInputProps = { ...getInputProps(), ...inputProps };
  const rootProps = getRootProps();

  const handleClearSearch = (): void => {
    // This is for controlled search boxes that implement the value prop, that way when they clear the search box, it will clear the value
    handleSearchTextChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);

    if (inputRef.current) {
      // This is for uncontrolled search boxes that only implement the on change prop, that way when they clear the search box, it will clear the value in the UI
      inputRef.current.value = '';
      inputRef.current?.focus();
    }
  };

  const hookProps = {
    hasFocus,
    inputProps,
    rootProps,
    showClearButton,
    handleClearSearch,
    searchInputProps,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LeadrSearchField;
