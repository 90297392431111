import { pick } from 'lodash';
import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { HTMLString } from '~Common/types';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import {
  deleteApi,
  getApi,
  HttpCallReturn,
  patchApi,
  postApi,
} from '~Deprecated/services/HttpService';

export interface ReviewTemplateQuestionFromApi {
  uid: string,
  rank?: number,
  // templateId: number,
  typeEnum: number,
  typeConfig: string,
  description: string,
  text: string,
}

export interface ReviewTemplateQuestion {
  uid: string,
  rank?: number,
  // templateId: number,
  typeEnum: number,
  typeConfig: Record<string, unknown>,
  description: string,
  text: string,
  topicTargetEnum: number,
}

export interface ReviewTemplate {
  uid: string,
  createdBy: {
    firstName: string,
    lastName: string,
    orgUserId: string,
    profileImageUrl: string,
  }
  organizationUid: string,
  name: string,
  description: HTMLString,
  topics: ReviewTemplateQuestion[],
}

export interface ReviewTemplateForCycle {
  uid: string,
  name: string,
  description: string,
  topics: ReviewTemplateQuestion[],
}
interface GetReviewTemplatesQueryParams {
  organizationUid: string,
}

function getReviewTemplatesQueryKey({
  organizationUid,
}: GetReviewTemplatesQueryParams): string[] {
  return ['organization', organizationUid, 'reviews', 'templates'];
}

export function getReviewTemplatesQuery({
  organizationUid: organizationId,
}: GetReviewTemplatesQueryParams): Promise<HttpCallReturn<ReviewTemplate[]>> {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${organizationId}/reviewTemplates`,
  };

  return getApi<ReviewTemplate[]>(serverUrl);
}

interface UseReviewTemplateListReturn {
  templates: ReviewTemplate[],
  isLoading: boolean,
}

export function useReviewTemplateList(): UseReviewTemplateListReturn {
  const organizationUid = getOrganizationId() ?? '';
  const result = useQuery({
    queryKey: getReviewTemplatesQueryKey({ organizationUid }),
    queryFn: () => getReviewTemplatesQuery({ organizationUid }),
  });

  const templates = result.data?.response ?? [];

  return {
    templates,
    isLoading: result.isLoading,
  };
}

export type CreateReviewTemplateDTO = Pick<ReviewTemplate,
  | 'description'
  | 'topics'
  | 'name'
>;

function createReviewTemplateMutation(organizationId: string, review: CreateReviewTemplateDTO): Promise<HttpCallReturn<void>> {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${organizationId}/reviewTemplates`,
  };

  const pickedReview = pick(review, ['description', 'topics', 'name']);

  const conformedQuestions: ReviewTemplateQuestionFromApi[] = pickedReview.topics.map((question, index) => ({
    ...question,
    rank: index,
    typeConfig: typeof question.typeConfig === 'string' ? question.typeConfig : JSON.stringify(question.typeConfig),
  }));

  return postApi<void>(serverUrl, {
    ...pickedReview,
    questions: conformedQuestions,
  });
}

export function useCreateReviewTemplate(): UseMutationResult<HttpCallReturn<void>, unknown, CreateReviewTemplateDTO, unknown> {
  const organizationUid = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: (review: CreateReviewTemplateDTO) => createReviewTemplateMutation(organizationUid, review),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getReviewTemplatesQueryKey({ organizationUid }) });
    },
  });
}

function updateReviewTemplateMutation(organizationId: string, template: ReviewTemplate): Promise<HttpCallReturn<void>> {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${organizationId}/reviewTemplates/${template.uid}`,
  };

  const pickedTemplate = pick(template, ['description', 'topics', 'name']);

  const conformedQuestions: ReviewTemplateQuestionFromApi[] = template.topics.map((question, index) => ({
    ...question,
    rank: index,
    typeConfig: typeof question.typeConfig === 'string'
      ? question.typeConfig
      : JSON.stringify(question.typeConfig),
  }));

  return patchApi<void>(serverUrl, {
    ...pickedTemplate,
    topics: conformedQuestions,
  });
}

export function useUpdateReviewTemplate(): UseMutationResult<HttpCallReturn<void>, unknown, ReviewTemplate, unknown> {
  const organizationUid = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: (updatedTemplate: ReviewTemplate) => updateReviewTemplateMutation(organizationUid, updatedTemplate),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: getReviewTemplatesQueryKey({ organizationUid }) });
    },
  });
}

function deleteReviewTemplateMutation(organizationId: string, templateUid: string): Promise<HttpCallReturn<void>> {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${organizationId}/reviewTemplates/${templateUid}`,
  };

  return deleteApi<void>(serverUrl, {});
}

export function useDeleteReviewTemplate(): UseMutationResult<HttpCallReturn<void>, unknown, string, unknown> {
  const organizationUid = getOrganizationId() ?? '';

  return useMutation({
    mutationFn: (templateUid: string) => deleteReviewTemplateMutation(organizationUid, templateUid),
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: getReviewTemplatesQueryKey({ organizationUid }) });
    },
  });
}
