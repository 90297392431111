import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FILTER_GROUP_MENU_ITEM_SHAPE } from '~Common/const/proptypes';
import { noop } from '~Deprecated/utils';

const View = ({
  showCollapse,
  onToggleCollapse,
  menuItem,
  renderMenuItem,
  ...props
}) => (
  <div {...props}>
    {renderMenuItem({
      itemInfo: menuItem, isSubItem: false, onToggleCollapse, showCollapse,
    })}
    <Collapse in={showCollapse} timeout="auto">
      {menuItem.subList.map((subMenuItem) => (
        <React.Fragment key={subMenuItem.text}>
          {renderMenuItem({ itemInfo: subMenuItem, isSubItem: true })}
        </React.Fragment>
      ))}
    </Collapse>
  </div>
);

View.propTypes = {
  showCollapse: PropTypes.bool.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
  menuItem: FILTER_GROUP_MENU_ITEM_SHAPE,
  renderMenuItem: PropTypes.func,
};

View.defaultProps = {
  menuItem: {},
  renderMenuItem: noop.apply,
};

const SectionCollapse = ({ menuItem, ...props }) => {
  const [showCollapse, setShowCollapse] = useState(menuItem.showSubList);

  const hookProps = {
    menuItem,
    showCollapse,
    onToggleCollapse: () => setShowCollapse(!showCollapse),
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SectionCollapse.propTypes = {
  menuItem: FILTER_GROUP_MENU_ITEM_SHAPE,
};

SectionCollapse.defaultProps = {
  menuItem: {},
};

export { View };
export default SectionCollapse;
