import { css } from '@emotion/react';
import React, { Fragment, useEffect, useState } from 'react';
import './styles.scss';
import { _iframelyApiKey, actionActionMenuItems } from './config';
import contentImage from '~Assets/images/content.png';
import feedbackImage from '~Assets/images/feedback.png';
import AgendaActionMenu from '../ActionMenu/AgendaActionMenu';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import UnsanitizedHTMLRenderer from '~Common/V3/components/HTML/UnsanitizedHTMLRenderer';

const styles = {
  iframelyContent: css({
    flex: 1,
    overflow: 'hidden',
  }),
};

const contentCache = {};
/**
 * @deprecated Old UI, needs some stuff to be rewritten and other stuff to be deleted before we can remove this
 */
const Player = (props) => {
  const [templateHtml, setTemplateHtml] = useState(null);
  const [canonical, setCanonical] = useState(null);

  const setState = (result) => {
    if (result.meta && result.meta.canonical) {
      // Honestly not sure why this was originally added. I guess don't remove it lol.
      setCanonical(result.meta.canonical);
    }
    if (result.html) {
      setTemplateHtml(result.html);
    } else {
      setTemplateHtml(null);
    }
  };

  /**
   * Fetches and caches the content.
   */
  const fetchContent = (link) => {
    const url = `https://iframe.ly/api/iframely/?url=${link}&api_key=${_iframelyApiKey}&omit_script=1&click_to_play=true`;
    try {
      if (contentCache[link]) {
        // Content is cached, retrieving existing values.
        setState(contentCache[link]);
        return;
      }

      // Don't use async/await. This shouldn't be blocking.
      fetch(url, { cache: 'force-cache' }).then((response) => {
        response.json().then((json) => {
          contentCache[link] = json;
          setState(json);
        });
      });
    } catch (e) {
    }
  };

  useEffect(() => {
    if (props.link) {
      fetchContent(props.link);
    }
  }, [props.link]);

  const playerWindow = () => (
    <div className="player">
      <div
        className={props.type === 'content' ? 'cursor-pointer' : ''}
        onClick={templateHtml ? () => {
          if (props.type === 'content') {
            window.open(canonical, '_blank');
          }
        } : () => {}}
      >
        { templateHtml ? (
          <UnsanitizedHTMLRenderer
            css={styles.iframelyContent}
            htmlText={templateHtml}
            reason="Using the HTMLRenderer strips out iframes. Learning uses iframely to display learning content"
          />
        ) : props.type === 'content'
          ? (
            <a
              onClick={() => window.open(canonical || props.link, '_blank')}
              className={props.type === 'content' ? 'cursor-pointer' : ''}
            >
              <div
                className="image"
                style={{ backgroundImage: `url(${props.backgroundImage ? props.backgroundImage : contentImage})` }}
              />
            </a>
          )
          : (
            <div
              className="image"
              style={{ backgroundImage: `url(${props.backgroundImage ? props.backgroundImage : feedbackImage})` }}
            />
          )}
      </div>
    </div>
  );

  const playerUi = () => (
    <div>
      <div className="organization-name">
        <span className="organization-name-box">{ props.title }</span>
      </div>

      { props.showActionMenu && props.actionActionMenuItems && props.actionActionMenuItems.length ? (
        <AgendaActionMenu
          staticContents={
              props.actionActionMenuItems || actionActionMenuItems
            }
          selectedItem={(e, actionMenu) => props.selectedItem(e, actionMenu)}
        />
      ) : null }
      <div className="organization-name-created">
        { props.user && props.user.firstName }
        {' '}
        { props.user && props.user.lastName }
        {' '}
        { props.user && props.user.name }
      </div>
      <div
        className={`${
          props.type === 'content'
            ? 'content-data'
            : 'feedback-data'
        }`}
      >
        <div className="image">
          { props.type === 'content' ? templateHtml && playerWindow() : playerWindow() }
        </div>
      </div>
      <div className="py-3 libraryactionbox">
        { props.type === 'content' ? (
          <button
            className="btn button-with-bg"
            onClick={props.onAssignClick}
          >
            Assign
          </button>
        ) : (
          <React.Fragment>
            <button
              className="btn button-with-bg"
              onClick={props.onUseTemplateClick}
            >
              Use Template
            </button>
            { ' ' }
            <button
              className="btn button-with-bg"
              onClick={props.onLearnMoreClick}
            >
              Learn More
            </button>
            { ' ' }
          </React.Fragment>
        ) }
      </div>
    </div>
  );
  return (
    <React.Fragment>
      { !props.layout ? (
        playerWindow()
      ) : (
        <div className="library-item content-library-item col-4" style={props.style}>
          { playerUi() }
        </div>
      ) }
    </React.Fragment>
  );
};

export default Player;
