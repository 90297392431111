import { useMemo } from 'react';
import { Person } from '~Common/const/interfaces';
import { UserGroup } from '~Common/const/userGroups';
import { usePeople } from '~Deprecated/hooks/usePeople';

export interface BaseOption {
  label: string,
  value: string,
  disabled?: boolean,
}
export interface SelectOption extends BaseOption {
  profileImageUrl?: string,
  jobTitle?: string,
}

interface UsePeopleTagPickerParams {
  initialSelectedIds?: string[], // OrgUserIds or userIds, just match the useOrgIds param
  useOrgIds?: boolean,
  allowSelf?: boolean,
  allowLeadrCoach?: boolean,
  disabledIds?: string[], // Ids to show as disabled
  filteredOutIds?: string[], // Ids to filter out
  accountTypesToInclude?: UserGroup[], // Account types to filter out
  administrativeStatusesToInclude?: string[], // Administrative statuses to filter out
  shouldShowUser?: (person: Person) => boolean, // Custom filter function
  shouldDisableUser?: (person: Person) => boolean, // Custom disable function
}

interface UsePeopleTagPickerReturn {
  peopleList: SelectOption[],
  initialSelectedPeopleList: SelectOption[],
  isLoadingPeopleData: boolean,
}

export const usePeopleTagPicker = ({
  initialSelectedIds,
  useOrgIds = true,
  allowSelf = false,
  allowLeadrCoach = true,
  disabledIds,
  accountTypesToInclude = [UserGroup.Admin, UserGroup.Member, UserGroup.LimitedAccess, UserGroup.Executive],
  administrativeStatusesToInclude = ['ACTIVE'],
  shouldShowUser,
  shouldDisableUser,
}: UsePeopleTagPickerParams): UsePeopleTagPickerReturn => {
  const idValueToUse = useOrgIds ? 'orgUserId' : 'userId';

  const { peopleDataArray, isLoading: isLoadingPeopleData } = usePeople({
    useOrgIds,
    allowSelf,
    search: '',
    administrativeStatusesToInclude,
  }) as unknown as { peopleDataArray: Person[], isLoading: boolean };

  const peopleList = useMemo(() => {
    const tempPeopleList = peopleDataArray || [];

    const mappedPeopleList = tempPeopleList.reduce((acc: SelectOption[], person) => {
      // TODO: At some point we will want a backend update to have a better way of knowing if it's Leadr Coach
      const isLeadrCoach = person.firstName === 'Leadr' && person.lastName === 'Coach';
      let isDisabled = false;
      if (shouldDisableUser) {
        isDisabled = shouldDisableUser(person);
      } else {
        isDisabled = disabledIds?.includes(person[idValueToUse]) || !accountTypesToInclude.includes(person.userGroupName);
      }
      const isVisible = shouldShowUser?.(person) ?? true;
      if ((isVisible && ((allowLeadrCoach && isLeadrCoach) || !isLeadrCoach))) {
        acc.push({
          label: `${person.firstName} ${person.lastName}`,
          value: person[idValueToUse] ?? '',
          profileImageUrl: person.profileImageUrl,
          jobTitle: person.jobTitle,
          disabled: isDisabled,
        });
      }
      return acc;
    }, []);

    return mappedPeopleList;
  }, [accountTypesToInclude, allowLeadrCoach, disabledIds, idValueToUse, peopleDataArray, shouldDisableUser, shouldShowUser]);

  const initialSelectedPeopleList = useMemo(() => initialSelectedIds?.map((id) => {
    const tempPerson = peopleDataArray.find((person) => person[idValueToUse] === id);

    if (tempPerson) {
      return {
        label: `${tempPerson.firstName} ${tempPerson.lastName}`,
        value: tempPerson[idValueToUse],
        profileImageUrl: tempPerson.profileImageUrl,
        jobTitle: tempPerson.jobTitle,
      };
    }

    return {
      label: '',
      value: '',
      profileImageUrl: '',
      jobTitle: '',
    };
  }) || [], [idValueToUse, initialSelectedIds, peopleDataArray]);

  return {
    peopleList,
    initialSelectedPeopleList,
    isLoadingPeopleData,
  };
};
