import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { SelectChangeEvent } from '@mui/material';
import { GOAL_FILTER_STYLES } from '~Goals/const/styles';

const styles = {
  ...GOAL_FILTER_STYLES,
  dropdown: (hasValue: boolean) => css({
    border: '1px solid transparent',
    width: '11rem',
    padding: 0,

    '.MuiSelect-select': {
      fontSize: '0.75rem',
      paddingLeft: '0.75rem',
    },

    // ToDo: Fix the placement of the dropdown arrow inside the common Dropdown component
    '.MuiSelect-icon': {
      right: '0.75rem',
    },
  }, hasValue && {
    borderColor: palette.brand.indigo,
  }),
  dropdownItemBoy: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
  }),
};

const renderValue = (value: Goals.GoalCategory[]): string => {
  if (value?.length) {
    return value.join(', ');
  }

  return 'All Categories';
};

const View = ({
  items,
  onChange,
  value,
  ...props
}: DropdownProps<Goals.GoalCategory[]>): JSX.Element => (
  <div>
    <p css={styles.filterLabel(!!value?.length)}>Objective Category</p>
    <Dropdown
      multiple
      displayEmpty
      css={styles.dropdown(!!value?.length)}
      value={value}
      renderItem={(item: DropdownItem<Goals.GoalCategory[]>) => (
        <div css={styles.dropdownItemBoy}>
          <CustomCheckbox checked={value?.includes(item.value)} />
          {item.value}
        </div>
      )}
      onChange={onChange}
      items={items}
      renderValue={renderValue}
      {...props}
    />
  </div>
);

const CategoryFilter = (): JSX.Element => {
  const [value, setCategoryValue] = useQueryParamState<Goals.GoalCategory[]>('goals', 'category', [], true);

  const items = [
    {
      value: Goals.GoalCategory.DefiningObjectives,
      text: '',
      'data-test-id': 'goalsFilterDefiningObjectives',
      disabled: value?.length === 1 && value[0] === Goals.GoalCategory.StandardOperatingObjectives,
    },
    {
      value: Goals.GoalCategory.StandardOperatingObjectives,
      text: '',
      'data-test-id': 'goalsFilterStandardOperatingObjectives',
      disabled: value?.length === 1 && value[0] === Goals.GoalCategory.DefiningObjectives,
    },
  ];

  const onChange = (event: SelectChangeEvent<Goals.GoalCategory[]>): void => {
    setCategoryValue(event.target.value as Goals.GoalCategory[]);
  };

  const hookProps = {
    items,
    onChange,
    value,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default CategoryFilter;
