import { Moment } from 'moment-timezone';
import {
  RRule, Options, Weekday, WeekdayStr,
} from 'rrule';
import { RecurrenceDropdownOptions } from '~ActionItems/const/constants';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';

export enum MonthlyRecurrence {
  ByDay = 'MONTHLY_BY_DAY',
  ByWeekday = 'MONTHLY_BY_WEEKDAY',
}

interface UseActionItemRecurrenceFrequencyReturn {
  getActionItemRecurrenceFrequency: (params: ActionItemRecurrenceFrequencyParams) => RRule;
}

export function getRRuleDayFromDate(dueDate: Moment): Weekday {
  const weekdayName = dueDate.format('dd').toLocaleUpperCase();
  const weekday = Weekday.fromStr(weekdayName as WeekdayStr);
  return weekday.weekday === -1 ? RRule.MO : weekday;
}

export interface ActionItemRecurrenceFrequencyParams {
  dueDate: Moment
  selectedDays: number[]
  recurrenceInterval?: number
  selectedRecurrence: RecurrenceDropdownOptions
  monthlyRecurrence: MonthlyRecurrence
}

export const useActionItemRecurrenceFrequency = (): UseActionItemRecurrenceFrequencyReturn => {
  const { timezone } = useTimezone();

  const getActionItemRecurrenceFrequency = ({
    dueDate,
    recurrenceInterval,
    selectedDays,
    selectedRecurrence,
    monthlyRecurrence,
  }: ActionItemRecurrenceFrequencyParams): RRule => {
    const rule: Partial<Options> = {
      freq: selectedRecurrence === RecurrenceDropdownOptions.Weekly ? RRule.WEEKLY : RRule.MONTHLY,
      // user's timezone
      tzid: timezone,
      // rrule requires a UTC dates
      dtstart: dueDate.utc(true).toDate(),
      interval: recurrenceInterval,
    };

    if (selectedRecurrence === RecurrenceDropdownOptions.Monthly) {
      if (monthlyRecurrence === MonthlyRecurrence.ByDay) {
        rule.bymonthday = dueDate.utc().date();
      } else {
        const weekday = getRRuleDayFromDate(dueDate);
        const monthWeekNumber = Math.ceil(dueDate.date() / 7);

        if (monthWeekNumber > 3) {
          rule.byweekday = weekday.nth(-1);
        } else if (monthWeekNumber) {
          rule.byweekday = weekday.nth(monthWeekNumber);
        }
      }
    } else if (selectedRecurrence === RecurrenceDropdownOptions.Weekly) {
      rule.byweekday = selectedDays ?? [];
    }

    return new RRule(rule);
  };

  return {
    getActionItemRecurrenceFrequency,
  };
};
