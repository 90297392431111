import { Route, Switch } from 'react-router-dom';
import { memo } from 'react';
import { isEqual } from 'lodash';
import {
  INSIGHTS_BASE_ROUTE,
} from '~Common/const/routes';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import InsightsHeader from '~Insights/components/InsightsHeader';
import InsightsLayout from '~Insights/components/InsightsLayout';

interface ViewProps {
  isLoading: boolean,
}

const View = ({
  isLoading,
}: ViewProps): JSX.Element => (
  <Switch>
    <Route
      path={`${INSIGHTS_BASE_ROUTE}:expandedCard?`}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<InsightsHeader />}
            ChildComponent={<InsightsLayout isLoading={isLoading} />}
          />
        </BaseLayout>
      )}
    />
    <SmartRedirect from="*" to={INSIGHTS_BASE_ROUTE} />
  </Switch>
);

const InsightsRouter = (): JSX.Element => {
  const hookProps = {
    isLoading: false,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default memo(InsightsRouter, (prev, next) => isEqual(prev, next));
