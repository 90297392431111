import LeadrModal from '~Common/V3/components/LeadrModal';
import { ComponentProps } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { css } from '@emotion/react';

const styles = {
  title: css({
    color: palette.brand.indigo,
  }),
  content: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
};

interface FinishModalProps extends ComponentProps<'div'> {
  open: boolean,
  handleModalDisplay: (value: boolean) => void,
  finishImport: () => void,
}

const FinishModal = ({
  open,
  handleModalDisplay,
  finishImport,
}: FinishModalProps): JSX.Element => (
  <LeadrModal
    open={open}
    onClose={() => handleModalDisplay(false)}
  >
    <LeadrModal.Header>
      <LeadrModal.Title css={styles.title}>
        Would you like to proceed with import?
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body>
      <p css={styles.content}>Users with errors will not be imported, while users with warnings will be imported and can be edited later.</p>
      <br />
      <p css={styles.content}>
        Successful imports will appear under the imported tab and can then be invited to join Leadr.
      </p>
      <br />
      <p css={styles.content}>
        <strong>You will be notified via email once the import is complete.</strong>
      </p>
    </LeadrModal.Body>
    <LeadrModal.Footer css={styles.footer}>
      <LeadrButton
        data-test-id="adminFinishImport"
        variant="default"
        onClick={finishImport}
        size="small"
      >
        Import
      </LeadrButton>
      <LeadrButton
        data-test-id="adminCancelFinishImport"
        variant="ghost"
        onClick={() => handleModalDisplay(false)}
        size="small"
      >
        Cancel
      </LeadrButton>
    </LeadrModal.Footer>
  </LeadrModal>
);

export default FinishModal;
