import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { css } from '@emotion/react';
import { noop } from '~Deprecated/utils/';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_STATE_SHAPE, FEEDBACK_SHAPE } from '~Common/const/proptypes';
import FormDrawer from '~Common/components/Drawers/Forms/Form';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { useFeedbackDetails } from '~Deprecated/hooks/feedback/useFeedback';

import { useTabs } from '~Common/hooks/useTabs';
// eslint-disable-next-line import/no-cycle
import { CONFIRM_TYPE } from '~Common/const/confirmType';
import { useRecallFeedback } from '~Deprecated/hooks/feedback/useRecallFeedback';
import UndoButton, { BUTTON_COLOR } from '~Common/components/Buttons/Undo';
import Details from '../Tabs/Details';
import ResponsesByQuestion from '../Tabs/ResponsesByQuestion';
import ResponsesByPerson from '../Tabs/ResponsesByPerson';
import { addToMeetingTemplate } from '../AddToMeeting/List';

const styles = {
  CardSkeleton: css({
    marginBottom: '1rem',
    maxWidth: '100%',
    width: '100%',
  }),
};

export const requestorFeedbackDetailTemplate = {
  name: 'REQUESTOR_FEEDBACK_DETAIL_DRAWER',
  width: DRAWER_WIDTHS.EXTENDED,
};

const RequestorFeedbackDetailDrawer = ({
  id, drawerState, setDrawerState, popDrawer, popAfter, pushDrawer, feedbackContext, isReadOnly, renderBottomButton, assigneeId, ...props
}) => {
  const { feedback, isLoading } = useFeedbackDetails({ feedbackId: id, assigneeId, isReadOnly: false });
  const recallFeedback = useRecallFeedback({ template: requestorFeedbackDetailTemplate });
  const [allowRecall, setAllowRecall] = useState(false);

  useEffect(() => {
    if (feedback?.assignees) {
      const completedFeedbacks = feedback.assignees.filter((assignee) => assignee.hasCompletedFeedback);
      setAllowRecall(completedFeedbacks.length === 0);
    }
  }, [feedback]);

  const tabQueryConfig = {
    module: 'feedback',
    workflow: 'requestorDetail',
    defaultValue: 0,
  };

  const { activeTab } = useTabs(tabQueryConfig);

  const onRecall = ({ onToggleConfirm }) => {
    const renderConfirmButton = () => (
      <UndoButton
        text="Recall All"
        onClick={() => recallFeedback({ feedback })}
        buttonColor={BUTTON_COLOR.DEFAULT}
      />
    );

    onToggleConfirm({
      show: true,
      type: CONFIRM_TYPE.DESTRUCTIVE,
      message: 'Are you sure you want to recall this feedback request?',
      drawerName: requestorFeedbackDetailTemplate.name,
      renderConfirmButton,
    });
  };

  const onAddToMeeting = ({ otherUserId, questions }) => {
    pushDrawer({
      drawer: {
        ...addToMeetingTemplate,
        args: {
          otherUserId,
          questions,
        },
      },
    });
  };

  const tabstoUse = [
    ['By Question', <ResponsesByQuestion
      feedback={feedback}
      onAddToMeeting={onAddToMeeting}
    />],
    ['By Person', <ResponsesByPerson
      feedback={feedback}
      onAddToMeeting={onAddToMeeting}
    />],
    ['Details', <Details
      feedback={feedback}
      isRequestor
    />],
  ];
  const isLoadingTab = [
    ['By Question', <MultipleSkeletonLoaders
      numberOfSkeletons={5}
      renderSkeletonItem={() => (
        <CardSkeleton css={styles.CardSkeleton} />
      )}
    />],
  ];

  const tabs = isLoading ? isLoadingTab : tabstoUse;

  const hookProps = {
    onSubmit: noop,
    drawerName: requestorFeedbackDetailTemplate.name,
    isReadOnly: true,
    drawerTitle: 'Feedback Details',
    tabs,
    // eslint-disable-next-line react/prop-types
    renderBottomButtons: ({ onToggleConfirm }) => (
      <>
        {activeTab === 2 && allowRecall && !isReadOnly && (
          <UndoButton
            text="Recall All"
            onClick={() => onRecall({ onToggleConfirm })}
            buttonColor={BUTTON_COLOR.DEFAULT}
          />
        )}
        {isReadOnly && (
          <>
            {renderBottomButton?.()}
          </>
        )}
      </>
    ),

    tabQueryConfig,
    formName: 'requestorDetail',
  };

  return (
    <FormDrawer
      {...hookProps}
      {...props}
    />
  );
};

RequestorFeedbackDetailDrawer.propTypes = {
  id: PropTypes.string.isRequired,
  popDrawer: PropTypes.func.isRequired,
  popAfter: PropTypes.func.isRequired,
  pushDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  feedbackContext: FEEDBACK_SHAPE,
  isReadOnly: PropTypes.bool,
  renderBottomButton: PropTypes.func,
  assigneeId: PropTypes.string,
};

RequestorFeedbackDetailDrawer.defaultProps = {
  feedbackContext: null,
  isReadOnly: false,
  renderBottomButton: null,
  assigneeId: null,
};

registerDrawer({
  templateName: requestorFeedbackDetailTemplate.name,
  component: RequestorFeedbackDetailDrawer,
});

export default RequestorFeedbackDetailDrawer;
