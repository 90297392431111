import { css } from '@emotion/react';
import LeadrSwitch from '~Common/V3/components/LeadrSwitch';
import { OrgAttachmentSettings } from '~Common/const/Organization';

import { palette } from '~Common/styles/colors';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';

const styles = {
  attachments: css({}),
  descriptionText: css({
    color: palette.neutrals.gray700,
    marginBottom: '0.5rem',
  }),
  details: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  gbUsed: css({
    color: palette.neutrals.gray700,
    fontWeight: 500,
    fontSize: '1rem',
  }),
};

interface ViewProps {
  currentGbUsed: number,
  totalAllocatedGb: number,
  enableAttachmentLocalUpload: boolean,
}

const View = ({
  currentGbUsed,
  totalAllocatedGb,
  enableAttachmentLocalUpload,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.attachments}
    {...props}
  >
    <SettingsSectionHeader
      renderContents={() => (
        <>File Attachments Settings</>
      )}
    />
    <p css={styles.descriptionText}>
      Enable and disable local file upload.
    </p>
    <div css={styles.details}>
      <LeadrSwitch data-test-id="settingsAdminEnableLocalUpload">
        <LeadrSwitch.Label
          label="Local Upload Enabled"
        >
          <LeadrSwitch.Switch
            inputProps={{ name: 'enableAttachmentLocalUpload' }}
            defaultChecked={enableAttachmentLocalUpload}
          />
        </LeadrSwitch.Label>
      </LeadrSwitch>
      <div css={styles.gbUsed}>
        {`${currentGbUsed}/${totalAllocatedGb} GB used`}
      </div>
    </div>
  </div>
);

interface AttachmentsProps {
  attachmentSettings: OrgAttachmentSettings,
}

const Attachments = ({
  attachmentSettings,
  ...props
}: AttachmentsProps): JSX.Element => {
  const {
    currentGbUsed,
    totalAllocatedGb,
    enableAttachmentLocalUpload,
  } = attachmentSettings;

  const hookProps = {
    currentGbUsed,
    totalAllocatedGb,
    enableAttachmentLocalUpload,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default Attachments;
