import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { newLayoutBackgroundColor } from '~Deprecated/ui/styles/colors';

const wrapper = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const listStyle = css`
  width: 100%;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
`;

const controlStyle = css`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #e3e7eb;
`;

const footerStyle = css`
  width: 100%;
`;

/**
 * @deprecated Old UI
 */
function CardListWithControls({
  list, controls, useNewLayout, footer,
}) {
  return (
    <div
      css={wrapper}
    >
      <div
        css={[
          controlStyle,
          useNewLayout ? css`background: ${newLayoutBackgroundColor} !important;` : '',
        ]}
        className="controlStyle"
      >
        {controls}
      </div>
      <div
        css={listStyle}
      >
        {list}
      </div>
      <div css={[footerStyle]}>
        {footer}
      </div>
    </div>
  );
}

CardListWithControls.propTypes = {
  list: PropTypes.element.isRequired,
  controls: PropTypes.element.isRequired,
  useNewLayout: PropTypes.bool,
  footer: PropTypes.element,
};

CardListWithControls.defaultProps = {
  useNewLayout: false,
  footer: null,
};

export default CardListWithControls;
