import { HTMLObject } from '~Common/types';
import DOMPurify from 'dompurify';

// Add a hook to make all links open a new window (documentation :https://github.com/cure53/DOMPurify/blob/main/demos/README.md#hook-to-open-all-links-in-a-new-window-link)
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
  }
  // set non-HTML/MathML links to xlink:show=new
  if (
    !node.hasAttribute('target')
    && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
  ) {
    node.setAttribute('xlink:show', 'new');
  }
});

export const sanitizeHTML = (htmlText: string): string => DOMPurify.sanitize(htmlText);

export const stringToSanitizedHTMLObject = (htmlText: string): HTMLObject => ({
  __html: sanitizeHTML(htmlText),
});
