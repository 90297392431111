import { ComponentProps, useMemo } from 'react';
import { css } from '@emotion/react';

import InsightMetric from '~Deprecated/ui/components/Insights/InsightMetric';
import { HealthReportMetricType, InsightsStatusSize } from '~Common/const/interfaces';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  metricHealthReport: css({
    flex: '0 0 100px',
    marginLeft: '0.5rem',
    justifyContent: 'flex-end',
    display: 'flex',
  }),
  healthMetric: css({
    marginLeft: '0.5rem',
    float: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '.insightStatusIndicator': {
      marginBottom: '0.5rem',
    },
  }),
};

export interface MetricHealthReport {
  [HealthReportMetricType.Activity]: number,
  [HealthReportMetricType.Coaching]: number,
  [HealthReportMetricType.Feedback]: number,
  [HealthReportMetricType.Goal]: number,
}

export interface InsightsRenderTooltipProps {
  tooltipContent: {
    insightKey: HealthReportMetricType,
    insightValue: number,
  },
  size: number,
  scoreValue: number,
}

export interface ViewProps extends ComponentProps<'div'> {
  renderTooltip?: (renderTooltipProps: InsightsRenderTooltipProps) => JSX.Element,
  metricsList: Array<[healthMetricType: HealthReportMetricType, value: number]>,
  size: InsightsStatusSize,
}

const View = ({
  renderTooltip,
  metricsList,
  size,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.metricHealthReport}
    {...props}
  >
    { metricsList.map(([healthMetricType, value]) => (
      <div
        key={healthMetricType}
        css={styles.healthMetric}
      >
        {renderTooltip && renderTooltip({
          tooltipContent: {
            insightKey: healthMetricType,
            insightValue: value,
          },
          scoreValue: value,
          size,
        })}

        <InsightMetric
          {...props}
          metric={healthMetricType}
        />
      </div>
    )) }
  </div>
);

export interface UserInsightsReportProps extends ComponentProps<'div'> {
  metricHealthReport: MetricHealthReport,
  renderTooltip?: (renderTooltipProps: InsightsRenderTooltipProps) => JSX.Element,
  size?: InsightsStatusSize,
  hiddenMetrics?: HealthReportMetricType[],
  hasManager?: boolean,
}

const UserInsightsReport = ({
  metricHealthReport,
  renderTooltip,
  size = InsightsStatusSize.Small,
  hiddenMetrics: explicitlyHiddenMetrics = [],
  hasManager = true,
  ...props
}: UserInsightsReportProps): JSX.Element | null => {
  const {
    enableGoals,
    enableFeedback,
  } = useOrgDetailsContext();

  const metricsList = useMemo(() => {
    const metricsFromReport = new Map(Object.entries(metricHealthReport));
    const hiddenMetrics = new Set(explicitlyHiddenMetrics);

    if (!enableGoals) {
      hiddenMetrics.add(HealthReportMetricType.Goal);
    }

    if (!enableFeedback) {
      hiddenMetrics.add(HealthReportMetricType.Feedback);
    }

    if (!hasManager) {
      hiddenMetrics.add(HealthReportMetricType.Coaching);
    }

    hiddenMetrics.forEach((metricType) => {
      metricsFromReport.delete(metricType);
    });

    const result = Array.from(metricsFromReport.entries()).map(([metric, value]) => ([metric as HealthReportMetricType, value as number]));

    return result;
  }, [metricHealthReport, explicitlyHiddenMetrics, enableGoals, enableFeedback, hasManager]) as Array<[metric: HealthReportMetricType, value: number]>;

  const hookProps = {
    renderTooltip,
    metricsList,
    size,
  };

  if (!metricsList) {
    return null;
  }

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default UserInsightsReport;
export { View };
