import { createAction } from '@reduxjs/toolkit';

import { stackDrawers } from '~Deprecated/utils/drawers/stackDrawers';
import { mutateState } from '~Deprecated/utils/reduxUtils';
import { registerAction } from '~Deprecated/reducers/drawers';

const removeDrawerAction = createAction('LEADR.REMOVE_DRAWER');

function onRemoveDrawer(state, { payload }) {
  return mutateState(state, (draftState) => {
    const { drawerName } = payload;
    draftState.order.splice(draftState.order.findIndex((item) => item.name === drawerName), 1);

    const { stackedDrawers } = stackDrawers(draftState.order, 0, false, true);
    draftState.order = stackedDrawers;

    return draftState;
  });
}

registerAction(removeDrawerAction, onRemoveDrawer);

export {
  removeDrawerAction,
};
