import { css } from '@emotion/react';
import { forwardRef, useMemo } from 'react';
import {
  SimpleTreeItemWrapper,
  TreeItemComponentProps,
} from 'dnd-kit-sortable-tree';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { AgendaType } from '@leadr-hr/types';
import { ParentType } from '~Meetings/hooks/v2/useReorderAgendaItem';
import { RecursiveObject } from '~Meetings/stores/useMeetingRealtimeStore';
import { AgendaCreatePayload, AgendaReorderPayload, AgendaUpdatePayload } from '~Meetings/const/realTimeMeetingMessages';
import { CircularProgress } from '@mui/material';
import { AgendaTreeItemData } from '~Meetings/const/dndKitSortableTreeTypes';
import AgendaTopic from '~Meetings/components/details/agenda/AgendaTopic';
import DragHandle from '~Meetings/components/details/agenda/DragAndDrop/DragHandle';
import { forMobileObject } from '~Common/styles/mixins';
import { AGENDA_SECTION_CHILD_INDENTATION_WIDTH_DESKTOP, AGENDA_SECTION_CHILD_INDENTATION_WIDTH_MOBILE } from '~Meetings/const';
import AgendaSection from '../../AgendaSection';
import AgendaSectionFooter from '../../AgendaSection/AgendaSectionFooter';

const styles = {
  treeItemComponent: (agendaType: AgendaType, collapsed: boolean, hasChildren: boolean) => css({
    '.treeItemContent': {
      border: 0,
      padding: 0,
    },
    '.dragHandle': {
      visibility: 'hidden',
    },
    '&:hover, &:focus-within': {
      '.dragHandle': {
        visibility: 'visible',
      },
    },
  }, agendaType === AgendaType.AgendaTopic && ({
    marginBottom: '.375rem',
  }), agendaType === AgendaType.AgendaSection && ({
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
  }), agendaType === AgendaType.AgendaSection && hasChildren && ({
    marginBottom: '0.25rem',
  }), agendaType === AgendaType.AgendaSection && collapsed && ({
    marginBottom: '1rem',
  }), forMobileObject({
    '.dragHandle': {
      visibility: 'visible',
    },
  })),
  agendaSection: css({
    flex: 1,
  }),
  leftContainer: css({
    alignSelf: 'start',
    marginRight: '0.5rem',
    marginLeft: `-${AGENDA_SECTION_CHILD_INDENTATION_WIDTH_DESKTOP}px`,
  }, forMobileObject({
    marginLeft: `-${AGENDA_SECTION_CHILD_INDENTATION_WIDTH_MOBILE}px`,
  })),
  agendaSectionThings: css({
    marginLeft: '1.25rem',
    marginBottom: '1rem',
  }),
};

interface ExtraProps {
  huddleId: string,
  meetingType: MeetingTypeEnum,
  realtimeCreatingAgenda: RecursiveObject<AgendaCreatePayload[]>,
  realtimeUpdatingAgenda: RecursiveObject<AgendaUpdatePayload[]>,
  realtimeReorderingAgenda: RecursiveObject<AgendaReorderPayload[]>,
  canAddRecurringTopic: boolean,
  canAddAttachments: boolean,
  hasMaxTopics: boolean,
  searchedItemId?: string,
}

type TreeItemProps = TreeItemComponentProps<AgendaTreeItemData> & ExtraProps;

const TreeItemComponent = forwardRef<
  HTMLDivElement,
  TreeItemProps
>(({
  item,
  handleProps,
  huddleId,
  meetingType,
  realtimeCreatingAgenda,
  realtimeUpdatingAgenda,
  realtimeReorderingAgenda,
  canAddRecurringTopic,
  canAddAttachments,
  isLast,
  searchedItemId,
  hasMaxTopics,
  ...props
}, ref) => {
  const realtimeCreatingAgendaForItem = useMemo<RecursiveObject<AgendaCreatePayload[]>>(() => {
    if (item.parentType === ParentType.AgendaSection) {
      // @ts-expect-error TODO: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return realtimeCreatingAgenda[item.initialParentId]?.[item.id];
    }

    return realtimeCreatingAgenda[item.id];
  }, [item.id, item.initialParentId, item.parentType, realtimeCreatingAgenda]);

  const realtimeUpdatingAgendaForItem = useMemo<RecursiveObject<AgendaUpdatePayload[]>>(() => {
    if (item.parentType === ParentType.AgendaSection) {
      // @ts-expect-error TODO: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return realtimeUpdatingAgenda[item.initialParentId]?.[item.id];
    }

    return realtimeUpdatingAgenda[item.id];
  }, [item.id, item.initialParentId, item.parentType, realtimeUpdatingAgenda]);

  const realtimeReorderingAgendaForItem = useMemo<RecursiveObject<AgendaReorderPayload[]>>(() => {
    if (item.parentType === ParentType.AgendaSection) {
      // @ts-expect-error TODO: Fix
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return realtimeReorderingAgenda[item.initialParentId]?.[item.id];
    }

    return realtimeReorderingAgenda[item.id];
  }, [item.id, item.initialParentId, item.parentType, realtimeReorderingAgenda]);

  const sectionId = item.parentType === ParentType.AgendaSection ? item.initialParentId : item.id.toString();
  const isSectionRecurring = (item.parentType === ParentType.AgendaSection && item.parentAgendaSection?.isRecurring) || item.item.isRecurring;
  const allowRecurringTopic = canAddRecurringTopic && isSectionRecurring;

  const dataTestId = item.item.type === AgendaType.AgendaSection ? `agendaSection-${item.id}` : `agendaTopic-${item.id}`;
  const dataParentSectionId = item.parentType === ParentType.AgendaSection ? item.initialParentId : undefined;

  return (
    <>
      { /* Private topics have isInvisible as true and we shouldn't render them */ }
      {(item.item.type === AgendaType.AgendaSection || (item.item.type === AgendaType.AgendaTopic && !item.item.isInvisible)) && (
      <SimpleTreeItemWrapper
        item={item}
        ref={ref}
        manualDrag
        showDragHandle={false}
        css={styles.treeItemComponent(item.item.type, item.collapsed || false, !!item.children?.length)}
        contentClassName="treeItemContent"
        disableCollapseOnItemClick
        isLast={isLast}
        data-test-id={dataTestId}
        data-parent-section-id={dataParentSectionId}
        {...props}
      >
        {(!item.disableSorting || item.disableWorkflows) && (
          <div css={styles.leftContainer}>
            {!item.disableSorting && (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <DragHandle className="dragHandle" dragHandleProps={handleProps} />
            )}
            {item.disableWorkflows && (
              <CircularProgress
                size={15}
              />
            )}
          </div>
        )}
        {item.item.type === AgendaType.AgendaSection && (
          <AgendaSection
            css={styles.agendaSection}
            agendaSection={item.item}
            huddleId={huddleId}
            hasMaxTopics={hasMaxTopics}
            realtimeCreatingAgenda={realtimeCreatingAgendaForItem}
            realtimeUpdatingAgenda={realtimeUpdatingAgendaForItem}
            realtimeReorderingAgenda={realtimeReorderingAgendaForItem}
          />
        )}
        {item.item.type === AgendaType.AgendaTopic && (
          <AgendaTopic
            agendaItem={item.item}
            huddleId={huddleId}
            type={meetingType}
            highlightedItem={searchedItemId}
            sectionId={item.parentType === ParentType.AgendaSection ? item.initialParentId : undefined}
            realtimeCreatingAgenda={realtimeCreatingAgendaForItem}
            realtimeUpdatingAgenda={realtimeUpdatingAgendaForItem}
            realtimeReorderingAgenda={realtimeReorderingAgendaForItem}
          />
        )}
      </SimpleTreeItemWrapper>
      )}
      {
        /*
          We need to show creating new agenda items and realtime info at the bottom of the agenda section.
          However, since dnd-kit-sortable-tree flattens out the data structure, we can't render that directly in the agenda section
          So, we render it here, either after the last item, if there is one, or after the section itself if it's empty
        */
      }
      {((item.item.type === AgendaType.AgendaTopic && item.parentType === ParentType.AgendaSection && isLast)
      || (item.item.type === AgendaType.AgendaSection && item.item.children.length === 0)) && (
        <AgendaSectionFooter
          css={styles.agendaSectionThings}
          realtimeCreatingAgenda={realtimeCreatingAgenda[sectionId]}
          sectionId={sectionId}
          huddleId={huddleId}
          allowRecurringTopic={allowRecurringTopic}
          canAddAttachments={canAddAttachments}
        />
      )}
    </>
  );
});

export default TreeItemComponent;
