import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import Loader from '~Common/components/Loader';

const styles = {
  loader: css`
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  `,
};

const LoadMoreObserver = ({ isLoading, onObserve }) => {
  const obsEle = useRef(null);

  useEffect(() => {
    let ref;

    const observer = new IntersectionObserver(([entry]) => {
      if (!isLoading && entry && entry.isIntersecting) {
        onObserve();
      }
    }, {
      root: null, // window by default
      rootMargin: '0px',
    });

    if (obsEle && obsEle.current) {
      ref = obsEle.current;
      observer.observe(obsEle.current);
    }

    return () => {
      observer.unobserve(ref);
    };
  }, [obsEle, isLoading, onObserve]);

  return (
    <Loader
      ref={obsEle}
      css={styles.loader}
    />
  );
};

LoadMoreObserver.propTypes = {
  onObserve: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

LoadMoreObserver.defaultProps = {

};

export default LoadMoreObserver;
