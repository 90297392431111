import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { noop } from '~Deprecated/utils';
import { validationShape, VALIDATION_DEFAULT } from '~Common/const/forms';

const styles = css`
  min-width: 150px !important;
  margin-top: 8px !important;

  & .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before, & .Mui-focused {
    border: 0;
  }

  & .MuiFormLabel-root {
    padding-top: 2px;
  }
`;

/**
 * @deprecated Old Dropdown UI, use  packages/leadr-frontend/src/common/V3/components/Dropdown.tsx instead
 */
const Dropdown = ({
  label, onChange, items, value, className, variant, name, validation, ...props
}) => {
  const [currentValue, updateState] = useState(value);
  const doChange = (event) => {
    if (event.target.value !== '') {
      if (onChange) onChange(event);
      updateState(event.target.value);
    }
  };

  return (
    <FormControl
      css={styles}
      className={className}
      variant={variant}
    >
      {label && (
        <InputLabel>
          {label}
        </InputLabel>
      )}
      <Select
        value={currentValue}
        onChange={doChange}
        label={label}
        name={name}
        inputProps={{
          ...validation,
        }}
        {...props}
      >
        {items.map((item) => (
          <MenuItem
            value={item.value}
            key={item.value}
          >
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    text: PropTypes.string,
  })),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard']),
  name: PropTypes.string.isRequired,
  validation: validationShape,
};

Dropdown.defaultProps = {
  items: [],
  onChange: noop,
  value: 0,
  label: '',
  className: '',
  variant: 'standard',
  validation: VALIDATION_DEFAULT,
};

export default Dropdown;
