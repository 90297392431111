import { createAction } from '@reduxjs/toolkit';
import {
  takeLatest, call,
} from 'redux-saga/effects';

import { sagaMiddleware } from '~Deprecated/store';
import { setOrganizationId, setOrganizationName } from '~Common/utils/localStorage';
import { hardRedirect } from '~Deprecated/utils/reduxUtils';
import { PEOPLE } from '~Common/const/routes';

const logIntoOrganizationAction = createAction('LEADR.LOG_INTO_ORGANIZATION');

function* onCreateOrganization(action) {
  const { id, orgName } = action.payload;

  yield call(setOrganizationId, id);
  yield call(setOrganizationName, orgName);
  yield call(hardRedirect, PEOPLE);
}

function* logIntoOrganizationWatcher() {
  yield takeLatest(logIntoOrganizationAction, onCreateOrganization);
}

sagaMiddleware.run(logIntoOrganizationWatcher);

export {
  logIntoOrganizationAction,
};
