import { css } from '@emotion/react';
import { hexToRGBA, palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import moment from 'moment';
import { usePrintView } from '~Common/stores/usePrintView';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import {
  PAGE_STYLES, REVIEW_DISPLAY_STYLES, CENTER_CONTENTS, FORM_STYLES,
} from '../Const/pageStyles';
import { ParticipantTypeEnum, PersonDisplayInformationReviews, ReviewShape } from '../Const/types';

const styles = {
  ...PAGE_STYLES,
  ...REVIEW_DISPLAY_STYLES,
  ...CENTER_CONTENTS,
  ...FORM_STYLES,
  signOffButton: css({
    background: palette.neutrals.white,
    color: palette.neutrals.gray800,
    padding: '.5rem 1rem',
    fontSize: '.8125rem',
  }),
  signoffCommentsCard: css({
    background: palette.brand.indigo,
    color: palette.neutrals.white,
  }),
  finalCommentsTitle: css({
    fontWeight: 600,
    fontSize: '1.5rem',
    marginBottom: '2rem',
  }),
  signoffCard: (isBlankComment: boolean) => css({
    background: palette.neutrals.white,
    color: palette.neutrals.gray700,
    padding: '1.5rem',
    marginBottom: '1.125rem',
    borderRadius: '.5rem',

    '&:last-of-type': {
      marginBottom: 0,
    },
  }, isBlankComment && {
    background: hexToRGBA(palette.neutrals.white, 0.9),
  }),
  participantComment: css({
    fontSize: '1rem',
    color: palette.neutrals.gray700,
  }),
  participantName: css({
    color: palette.neutrals.gray800,
    fontSize: '.875rem',
    fontWeight: 500,
    marginTop: '.75rem',
    marginBottom: '-.1875rem',
  }),
  participantType: css({
    color: palette.neutrals.gray700,
    fontSize: '.875rem',
  }),
  signedOffDate: css({
    fontSize: '.875rem',
    color: palette.neutrals.gray700,
    fontStyle: 'italic',
    marginTop: '.5rem',
  }),
};

interface ViewProps {
  participants: PersonDisplayInformationReviews[],
  isPrintView: boolean,
}

const View = ({
  participants,
  isPrintView,
}: ViewProps): JSX.Element => (
  <>
    <Card
      css={
        isPrintView
          ? [styles.baseCard(false), styles.signoffCommentsCard, styles.fullWidth]
          : [styles.baseCard(false), styles.centeredContent, styles.signoffCommentsCard]
      }
      renderContents={() => (
        <>
          <div
            css={styles.finalCommentsTitle}
          >
            Final Comments
          </div>
          {participants.map((participant) => (
            <div
              css={styles.signoffCard(participant.signOffComment === '' || participant.signOffComment === null)}
              key={participant.orgUserId}
            >
              {participant.signOffComment && (
                <HTMLRenderer css={styles.participantComment} htmlText={participant.signOffComment} />
              )}
              {(participant.signOffComment === '' || participant.signOffComment === null) && (
              <>
                <div
                  css={styles.participantComment}
                >
                  {`Waiting for ${participant.firstName} ${participant.lastName} to Signoff`}
                </div>
              </>
              )}
              {participant.signOffComment !== '' && participant.signOffComment !== null && (
                <>
                  <div
                    css={[styles.participantName]}
                  >
                    {`${participant.firstName} ${participant.lastName} `}
                  </div>
                  <div
                    css={styles.participantType}
                  >
                    {participant.participantTypeEnum === ParticipantTypeEnum.Reviewer ? (
                      <span>Reviewer</span>
                    ) : null}
                    {participant.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer ? (
                      <span>Secondary Reviewer</span>
                    ) : null}
                    {participant.participantTypeEnum === ParticipantTypeEnum.Reviewee ? (
                      <span>Reviewee</span>
                    ) : null}
                  </div>
                </>
              )}
              <div
                css={styles.signedOffDate}
              >
                {participant.signedOffAt && (
                  <>
                    {`Signed off
                  ${moment(participant.signedOffAt).format('MMMM Do YYYY')}
                  at
                  ${moment(participant.signedOffAt).format('h:mm:ss a')}
                  `}
                  </>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    />
  </>
);

interface SignoffCommentsProps {
  review: ReviewShape,
}

const SignoffComments = ({
  review,
}: SignoffCommentsProps): JSX.Element => {
  const isPrintView = usePrintView((state) => state.isPrintView);

  const { participants } = review;

  const hookProps = {
    participants,
    isPrintView,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default SignoffComments;
