import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const style = css`
  & .card {
    margin: 0 auto;
    :not(:last-child) {
      margin-bottom: 30px;
    }
  }
`;

/**
 * @deprecated Old Component
 */
function List({
  ids, renderTemplate, className,
}) {
  return (
    <div
      css={style}
      className={className}
    >
      {ids.map((id, index) => renderTemplate({ id, index }))}
    </div>
  );
}

List.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderTemplate: PropTypes.func.isRequired,
  className: PropTypes.string,
};

List.defaultProps = {
  className: '',
};

export default List;
