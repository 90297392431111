import { create, UseBoundStore, StoreApi } from 'zustand';
import { debounce } from 'lodash';
import { DEFAULT_SEARCH_FIELD_DEBOUNCE_TIME } from '../const';

export interface SearchStoreState {
  searchText: string,
  debouncedSearchText: string,
  setSearchText: (nextValue: string) => void,
  clearSearchText: () => void,
}

const createSearchStore = (debounceTime = DEFAULT_SEARCH_FIELD_DEBOUNCE_TIME): UseBoundStore<StoreApi<SearchStoreState>> => create<SearchStoreState>((set) => {
  const debouncedSet = debounce(set, debounceTime);

  return {
    // Reference this for UI
    searchText: '',
    // Reference this for API calls
    debouncedSearchText: '',
    setSearchText: (newSearchText: string) => {
      set(() => ({ searchText: newSearchText }));
      debouncedSet(() => ({ debouncedSearchText: newSearchText }));
    },
    clearSearchText: () => set(() => ({ searchText: '', debouncedSearchText: '' })),
  };
});

export default createSearchStore;
