import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';

export type ValidValue = string | number | Date;

const styles = {
  heading: css({
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    fontWeight: 500,
    textAlign: 'left',
  }),
  explanation: css({
    color: palette.neutrals.gray700,
    fontSize: '0.875rem',
    fontWeight: 400,
    textAlign: 'left',
  }),
  scroll: css({
    overflow: 'auto',
  }),
  section: css({
    width: '100%',
    '&:hover': {
      backgroundColor: palette.neutrals.gray50,
      cursor: 'pointer',
    },
  }),
  checked: css({
    backgroundColor: palette.neutrals.gray50,
  }),
  deselectButton: css({
    color: palette.brand.blue,
    marginTop: '0.75rem',
    padding: '0 !important',
    textAlign: 'left',

    '&:disabled': {
      color: palette.neutrals.gray600,
    },
  }),
  divider: css({
    backgroundColor: palette.neutrals.gray200,
    height: '2px',
    width: '100%',
    margin: '0.75rem 0.25rem',
  }),
};

export type SidebarFilterOptions = {
  value: ValidValue,
  renderContents: (index: number) => JSX.Element,
}[];

interface ViewProps {
  deselectAll: () => void,
  selectedValues: ValidValue[],
  options: SidebarFilterOptions,
  onClick: (value: ValidValue) => void,
}

const View = ({
  deselectAll,
  options,
  selectedValues,
  onClick,
}: ViewProps): JSX.Element => (
  <>
    <h4 css={styles.heading}>Filter</h4>
    <p css={styles.explanation}>
      Click items below to filter the graph. Percentages represent change over time.
    </p>
    <Button
      css={styles.deselectButton}
      variant="text"
      disabled={selectedValues.length === 0}
      onClick={deselectAll}
      renderContents={() => <>Deselect All</>}
    />
    <div css={styles.divider} />
    <div css={styles.scroll}>
      {options.map((option, index) => (
        <Button
          key={option.value.toString()}
          variant="text"
          css={[styles.section, selectedValues.includes(option.value) ? styles.checked : null]}
          onClick={() => onClick(option.value)}
          renderContents={() => option.renderContents(index)}
        />
      ))}
    </div>
  </>
);

interface SidebarFiltersProps {
  selectedValues: ValidValue[],
  options: {
    value: ValidValue,
    renderContents: (index: number) => JSX.Element,
  }[],
  onChange: (selectedValues: ValidValue[]) => void,
}

const SidebarFilters = ({
  selectedValues,
  options,
  onChange,
  ...props
}: SidebarFiltersProps): JSX.Element => {
  const onClick = (clickedValue: ValidValue): void => {
    const index = selectedValues.indexOf(clickedValue);
    if (index !== -1) {
      onChange([
        ...selectedValues.slice(0, index),
        ...selectedValues.slice(index + 1),
      ]);
    } else {
      onChange([
        ...selectedValues,
        clickedValue,
      ]);
    }
  };

  const deselectAll = (): void => {
    onChange([]);
  };

  const hookProps = {
    deselectAll,
    onClick,
    selectedValues,
    options,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View, SidebarFilters };
export default SidebarFilters;
