import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { postApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { FrontendPlaylistLearning } from '~Learning/const/interfaces';
import { learningTemplatesQueryKeys } from '../queryKeys';

export interface LearningPlaylistTemplateCreationRequest {
  title: string,
  description: string,
  categoryId: string,
  learnings: FrontendPlaylistLearning[]
}

interface LearningTemplateCreationResponse {
  playlistTemplateId: string,
  userId: string,
  organizationId: string,
  learningIds: string[]
}

const createLearningPlaylistTemplate = async (
  learningTemplateCreationRequest: LearningPlaylistTemplateCreationRequest,
):
Promise<HttpCallReturn<LearningTemplateCreationResponse>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/templates/create`;

  return postApi<LearningTemplateCreationResponse>(serverUrl, learningTemplateCreationRequest);
};

interface UseCreateLearningPlaylistTemplateProps {
  onSuccessCallback?: () => void
  onErrorCallback?: (playlistToCreate: LearningPlaylistTemplateCreationRequest) => void,
}

export const useCreateLearningPlaylistTemplate = ({
  onSuccessCallback,
  onErrorCallback,
}: UseCreateLearningPlaylistTemplateProps):
  UseMutateFunction<HttpCallReturn<LearningTemplateCreationResponse>, unknown, LearningPlaylistTemplateCreationRequest> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: createLearningPlaylistTemplate,
    onMutate: () => {
      toastId.current = toast.info('Creating the playlist template...', { autoClose: false });
    },
    onError: (_, playlistToCreate) => {
      toast.update(toastId.current, {
        render: 'There was an error creating the playlist template. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      onErrorCallback?.({
        ...playlistToCreate,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Successfully created the playlist template.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey: learningTemplatesQueryKeys.lists() });

      onSuccessCallback?.();
    },
  });

  return mutation.mutate;
};
