import { css } from '@emotion/react';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';
import { palette } from '~Common/styles/colors';
import { getLocalId } from '~Common/utils/uuid';
import { useMemo, useContext } from 'react';
import { TemplateItemActionsContext } from '~Meetings/components/topic-suggestions/contexts/TemplateItemsActionContext';
import {
  CreateAgendaSectionTemplateDetailsWithLocalID,
  CreateAgendaTopicTemplateDetailsWithLocalID,
  EditAgendaSectionTemplateDetailsWithLocalID,
  EditAgendaTopicTemplateDetailsWithLocalID,
} from '~Meetings/components/topic-suggestions/const/types';
import CollapseButton from '~Meetings/components/topic-suggestions/shared/AgendaSection/CollapseButton';
import { useShowItemEditor } from '../../stores/useShowItemEditor';
import AgendaSectionTitleEditor from './AgendaSectionTitleEditor';
import AgendaSectionMenu from './AgendaSectionMenu';
import CollapseContent from './CollapseContent';

const styles = {
  agendaSection: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  infoContainer: css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  }),
  textContainer: css({
    overflow: 'hidden',
  }),
  text: css({
    color: palette.neutrals.gray900,
    fontWeight: 600,
  }),
  agendaSectionMenu: css({
    marginLeft: 'auto',
  }),
};

interface ViewProps {
  text: string,
  disableCollapseButton: boolean,
  numberOfAgendaItems: number,
  sectionLocalId: string,
  showAgendaSectionTextEditor: boolean,
  agendaTopics: CreateAgendaTopicTemplateDetailsWithLocalID[] | EditAgendaTopicTemplateDetailsWithLocalID[],
  newTopicLocalId: string,
  handleSaveSectionTitleEdit: (sectionTitle: string) => void,
  hasMaxTopics: boolean,
  renderDragHandle?: () => JSX.Element,
}

const View = ({
  text,
  disableCollapseButton,
  numberOfAgendaItems,
  sectionLocalId,
  showAgendaSectionTextEditor,
  agendaTopics,
  newTopicLocalId,
  handleSaveSectionTitleEdit,
  renderDragHandle,
  hasMaxTopics,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.agendaSection}
    {...props}
  >
    {showAgendaSectionTextEditor && (
      <AgendaSectionTitleEditor
        sectionLocalId={sectionLocalId}
        agendaSectionText={text}
        onSave={handleSaveSectionTitleEdit}
      />
    )}
    {!showAgendaSectionTextEditor && (
      <>
        <div css={styles.infoContainer}>
          {renderDragHandle?.()}
          <CollapseButton
            sectionLocalId={sectionLocalId}
            disabled={disableCollapseButton}
          />
          <div css={styles.textContainer}>
            <TooltipOnTruncate css={styles.text} text={text} />
          </div>
          <AgendaSectionMenu
            css={styles.agendaSectionMenu}
            sectionLocalId={sectionLocalId}
            numberOfAgendaItems={numberOfAgendaItems}
            newTopicLocalId={newTopicLocalId}
            hasMaxTopics={hasMaxTopics}
          />
        </div>
        <CollapseContent
          sectionLocalId={sectionLocalId}
          agendaTopics={agendaTopics}
          newTopicLocalId={newTopicLocalId}
          hasMaxTopics={hasMaxTopics}
        />
      </>
    )}
  </div>
);

interface AgendaSectionProps {
  agendaSection: CreateAgendaSectionTemplateDetailsWithLocalID | EditAgendaSectionTemplateDetailsWithLocalID,
  sectionLocalId: string,
  hasMaxTopics: boolean,
  renderDragHandle?: () => JSX.Element,
}

const AgendaSection = ({
  agendaSection,
  sectionLocalId,
  ...props
}: AgendaSectionProps): JSX.Element => {
  const newTopicLocalId = useMemo(() => getLocalId(), []);
  const disableCollapseButton = agendaSection.children.length === 0;

  const {
    getIsItemEditorVisible,
  } = useShowItemEditor((state) => ({
    getIsItemEditorVisible: state.getIsItemEditorVisible,
  }));
  const showAgendaSectionTextEditor = getIsItemEditorVisible(sectionLocalId);

  const { editTemplateItem } = useContext(TemplateItemActionsContext);

  const handleSaveSectionTitleEdit = (sectionTitle: string): void => {
    editTemplateItem({
      localId: sectionLocalId,
      item: {
        ...agendaSection,
        text: sectionTitle,
      },
    });
  };

  const hookProps = {
    text: agendaSection.text,
    disableCollapseButton,
    numberOfAgendaItems: agendaSection.children.length,
    sectionLocalId,
    showAgendaSectionTextEditor,
    agendaTopics: agendaSection.children,
    newTopicLocalId,
    handleSaveSectionTitleEdit,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaSection;
