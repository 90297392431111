export const defaultDraftData = {
  firstName: '',
  lastName: '',
  email: '',
  managerEmail: '',
  mentorEmail: '',
  userGroupName: '',
  jobTitle: '',
  department: '',
  warnings: [],
  errors: [],
  spreadsheetRowNumber: 1,
  columnName: 'Email',
};
export const DEFAULT_ROWS_PER_PAGE = 25;
export const DEFAULT_ROW_HEIGHT = 75;
export const rowsPerPageOptions = [
  {
    text: '25 Rows',
    value: '25',
  },
  {
    text: '50 Rows',
    value: '50',
  },
  {
    text: '100 Rows',
    value: '100',
  },
];
