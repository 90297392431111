import { css } from '@emotion/react';
import {
  ManagerReviewStatuses, RevieweeReviewStatuses, ReviewShape, ReviewStatusColor,
} from '../Const/types';
import { returnReviewParticipantType } from '../Hooks/returnReviewParticipantType';
import { useReturnReviewee } from '../Hooks/useReturnReviewee';
import ReviewNextStepStepper from './ReviewNextStepStepper';
import { CENTER_CONTENTS } from '../Const/pageStyles';

const styles = {
  reviewNextStepStepper: css({
    marginTop: '2.5rem',
  }),
  ...CENTER_CONTENTS,
};

interface ViewProps {
  reviewStatusColor: string,
  renderButton: () => JSX.Element,
  renderMessageText: () => string,
}

const View = ({
  reviewStatusColor,
  renderButton,
  renderMessageText,
}: ViewProps): JSX.Element => (
  <ReviewNextStepStepper
    css={styles.reviewNextStepStepper}
    statusColor={reviewStatusColor}
    renderStatusText={renderMessageText()}
    renderButton={renderButton}
  />
);

interface ReviewProgressStepperProps {
  review: ReviewShape,
}

const ReviewProgressStepper = ({
  review,
}: ReviewProgressStepperProps): JSX.Element => {
  const { isManager, isSecondaryManager, isReviewee } = returnReviewParticipantType(review);

  const setReviewStatus = (fullReview: ReviewShape): string => {
    let reviewStatus = '';

    if (isManager) {
      reviewStatus = fullReview.reviewerStatus;
    }
    if (isSecondaryManager) {
      reviewStatus = fullReview.secondaryReviewerStatus;
    }
    if (isReviewee) {
      reviewStatus = fullReview.revieweeStatus;
    }

    return reviewStatus;
  };
  const reviewStatus = setReviewStatus(review);
  const setReviewStatusColor = (reviewStatusText: string): string => {
    if (reviewStatusText === ManagerReviewStatuses.Complete) {
      return ReviewStatusColor.Green;
    }
    if (reviewStatusText === ManagerReviewStatuses.Closed) {
      return ReviewStatusColor.Gray;
    }
    return ReviewStatusColor.Orange;
  };

  const reviewee = useReturnReviewee(review);

  const renderButton = ():JSX.Element => {
    if (reviewStatus === ManagerReviewStatuses.Signoff_Review) {
      return (
        <div id="signoffButton" />
      );
    }
    return (<></>);
  };

  const renderMangersText = (): string => {
    switch (reviewStatus) {
      case ManagerReviewStatuses.Complete:
        return reviewStatus;
      case ManagerReviewStatuses.Closed:
        return reviewStatus;
      case ManagerReviewStatuses.Start_Review:
        return `Next Step - Complete ${reviewee.firstName} ${reviewee.lastName}’s Review`;
      default:
        return `Next Step - ${reviewStatus}`;
    }
  };
  const renderRevieweeText = (): string => {
    switch (reviewStatus) {
      case RevieweeReviewStatuses.Complete:
        return 'Review Completed';
      case RevieweeReviewStatuses.Closed:
        return reviewStatus;
      default:
        return `Next Step - ${reviewStatus}`;
    }
  };
  const hookProps = {
    reviewStatusColor: setReviewStatusColor(setReviewStatus(review)),
    renderButton,
    renderMessageText: isReviewee ? renderRevieweeText : renderMangersText,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default ReviewProgressStepper;
