import { css } from '@emotion/react';
import { ComponentProps, useMemo } from 'react';
import { palette } from '~Common/styles/colors';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { useGetLearningTemplateCategories } from '~Learning/hooks/templates/singleContent/useGetLearningTemplateCategories';
import { LearningLibraryDrawerTabs } from './ViewLearningLibraryDrawer';

const styles = {
  learningTemplateCategory: css({}),
  titleHeader: css({
    fontSize: '.625rem',
    color: palette.neutrals.gray500,
    marginBottom: '.625rem',
  }),
  radioGroup: css({
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  }),
  radioLabelText: css({
    '.MuiFormControlLabel-label': {
      color: palette.neutrals.gray700,
      fontWeight: 500,
    },
  }),
  inputLabel: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  categoryId: string,
  learningTemplateCategoryItems: DropdownItem[],
  showHeader?: boolean,
}

const View = ({
  categoryId,
  learningTemplateCategoryItems,
  showHeader = true,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.learningTemplateCategory} {...props}>
    {showHeader && (
      <div css={styles.titleHeader}>ADD TO CATEGORY</div>
    )}
    <Dropdown
      items={learningTemplateCategoryItems}
      name="categoryId"
      value={categoryId}
      label="Category"
    />
  </div>
);

type LearningTemplateCategoryButtonsProps = Omit<ViewProps, 'learningTemplateCategoryItems'>

const LearningTemplateCategoryButtons = ({ ...props }: LearningTemplateCategoryButtonsProps): JSX.Element => {
  // These are only used for non-curated views, IE: creating a Learning
  const { learningTemplateCategories } = useGetLearningTemplateCategories(LearningLibraryDrawerTabs.ORGANIZATION);

  const learningTemplateCategoryItems = useMemo(() => learningTemplateCategories?.map((category) => ({
    text: category.categoryName,
    value: category.categoryId,
  })), [learningTemplateCategories]);

  const hookProps = {
    learningTemplateCategoryItems,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };

export default LearningTemplateCategoryButtons;
