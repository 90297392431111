import { css } from '@emotion/react';
import { Select } from '~Common/V3/components/uncontrolled';
import { palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import { GoalCategoryTypes } from '~Goals/const/defaults';
import MenuItem from '@mui/material/MenuItem';

const styles = {
  objectiveTypeSelect: css({
    backgroundColor: palette.neutrals.gray500,
  }),
  select: css({
    flex: 1,
  }, forMobileObject({
    '.MuiInputBase-input': {
      maxWidth: 'unset',
    },
  })),
};

interface ObjectiveTypeSelectProps {
  defaultValue?: string,
}

const ObjectiveTypeSelect = ({
  defaultValue,
  ...props
}: ObjectiveTypeSelectProps): JSX.Element => (
  <div {...props}>
    <Select
      css={styles.select}
      id="category"
      name="category"
      defaultValue={defaultValue}
      label="Objective Type"
    >
      {GoalCategoryTypes.map((category) => (
        <MenuItem
          key={category.value}
          value={category.value}
        >
          {category.text}
        </MenuItem>
      ))}
    </Select>
  </div>
);

export default ObjectiveTypeSelect;
