import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { buildQueryString } from '~Common/utils';
import { homeQueryKeys } from './queryKeys';
import { MyScheduleDay } from '../const/interfaces';

const getUpcomingWeek = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<MyScheduleDay[]>> => {
  const [count] = queryKey.slice(-1);
  const [timezone] = queryKey.slice(-2);
  const [includeIgnoredMeetings] = queryKey.slice(-3);

  const queryString = buildQueryString({ timezone, count, includeIgnoredMeetings });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/calendarIntegration/getUpcomingWeek${queryString}`;
  return getApi<MyScheduleDay[]>(serverUrl);
};

interface UseGetUpcomingWeekParams {
  maxNumberOfMeetingsToGet: number,
  includeIgnoredMeetings: boolean,
}
interface UseGetUpcomingWeekReturn {
  meetingsWithDays: MyScheduleDay[] | undefined,
  isLoading: boolean,
  refetch: () => void,
}

export const useGetUpcomingMeetings = ({
  maxNumberOfMeetingsToGet,
  includeIgnoredMeetings,
}: UseGetUpcomingWeekParams): UseGetUpcomingWeekReturn => {
  const { timezone, isLoading: isTimezoneLoading } = useTimezone();

  const result = useQuery({
    queryKey: homeQueryKeys.upcomingWeekList(getOrganizationId() ?? '', {
      includeIgnoredMeetings,
      timezone,
      count: maxNumberOfMeetingsToGet,
    }),
    queryFn: getUpcomingWeek,
    enabled: !!timezone,
    staleTime: 60 * 1000 * 1, // 1 Minute, this needs to be faster since incoming calendar changes don't trigger a refetch
  });

  return {
    meetingsWithDays: result.data?.response,
    isLoading: result.isLoading || isTimezoneLoading,
    refetch: result.refetch,
  };
};
