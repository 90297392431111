import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';

export const calendarIntQueryKeys = {
  all: [getOrganizationId() ?? '', getOrganizationUserId() ?? '', 'nylas'] as const,
  unsyncedLeadrMeetings: () => [...calendarIntQueryKeys.all, 'leadrEvents'] as const,
  unsyncedCalendarMeetings: (timezone: string) => [...calendarIntQueryKeys.all, 'calendarEvents', timezone] as const,
  ignoredUnsyncedCalendarMeetings: () => [...calendarIntQueryKeys.all, 'ignoredEvents'] as const,
  availableCalendars: () => [...calendarIntQueryKeys.all, 'availableCalendars'] as const,
  nylasClientId: () => [...calendarIntQueryKeys.all, 'nylasClientId'] as const,
  calendarLinkedId: () => [...calendarIntQueryKeys.all, 'calendarLinkedId'] as const,
  isUserAuthenticated: () => [...calendarIntQueryKeys.all, 'isUserAuthenticated'] as const,
};
