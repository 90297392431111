import { HTMLString } from '~Common/types';

// Workflows
export enum AssignLearningOptions {
  START_FROM_SCRATCH = 'Start From Scratch',
  START_WITH_A_TEMPLATE = 'Start with a Template',
  CREATE_A_SINGLE_LEARNING_CONTENT = 'Create a Single Learning',
  CREATE_A_LEARNING_PLAYLIST = 'Create a Learning Playlist',
  SINGLE_LEARNING_CONTENT = 'Single Learning',
  LEARNING_PLAYLIST = 'Learning Playlist',
}

export enum ReceivedLearningWorkflow {
  SINGLE_CONTENT = 'SINGLE_CONTENT',
  PLAYLIST = 'PLAYLIST',
}

// Common Learning Interfaces and Enums
export enum LearningStatus {
  INCOMPLETE = 'INCOMPLETE',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}

export enum LearningSort {
  DATE_CREATED = 'DATE_CREATED',
  DUE_DATE = 'DUE_DATE',
}

export enum LearningType {
  SINGLE_LEARNING = 'SINGLE_LEARNING',
  PLAYLIST = 'PLAYLIST',
}

export interface LearningPersonDisplayInformation {
  firstName: string,
  lastName: string,
  id: string,
  jobTitle: string,
  profileImageUrl?: string,
}
export interface LearningAssignee {
  assignee: LearningPersonDisplayInformation,
  status: LearningStatus,
}

export interface FrontendRankedQuestion {
  text: HTMLString,
  rank: number,
  questionId?: string,
}

// Assigned Learning interfaces
export interface AssignedLearning {
  id: string,
  title: string,
  createdDateTime: string,
  dueDate?: string,
  description: string,
  assignedUsersInfo: LearningPersonDisplayInformation[],
  numberOfUsersCompleted: number,
  numberOfUsersAssigned: number,
  learningType: LearningType,
  status: LearningStatus,
}

interface LearningQuestionResponse {
  id: string,
  text: HTMLString,
}

export interface FrontendQuestionResponse {
  id: string,
  rank: number,
  topic: HTMLString,
  responses: RequestedLearningQuestionResponse,
}
export interface RequestedLearningQuestionResponse extends LearningQuestionResponse {
  assignee: LearningPersonDisplayInformation,
}

export interface RequestedLearningQuestion {
  id: string,
  rank: number,
  topic: HTMLString,
  responses: RequestedLearningQuestionResponse[],
}

export interface SCRequestedLearningDetail {
  id: string,
  title: string,
  contentUrl: string,
  dueDate?: string,
  introduction: string,
  receivedDateTime: string,
  questions: RequestedLearningQuestion[],
  assignees: LearningAssignee[],
  status: LearningStatus,
}

export interface AssignedPlaylistLearning {
  contentUrl: string,
  id: string,
  introduction: HTMLString,
  title: string,
  rank: number,
  questions: RequestedLearningQuestion[],
  status: LearningStatus,
  dueDate?: string,
}

export interface AssignedPlaylistDetails {
  id: number,
  title: string,
  description: string,
  dueDate?: string,
  createdDate: string,
  learnings: AssignedPlaylistLearning[],
  numberOfUsersComplete: number,
  numberOfUsersAssigned: number,
  status: LearningStatus,
  canRecallFromAllParticipants: boolean,
  numberOfLearnings: number,
  assignedUsersInfo: LearningAssignee[],
}

// Received Learning Interfaces
export interface ReceivedLearning {
  id: string | number, // This is also used for Playlists and they are stored in MS SQL, which uses numbers for ids
  title: string,
  createdDateTime: string,
  dueDate?: string,
  description: string,
  assigner: LearningPersonDisplayInformation,
  completedLearningsCount: number,
  totalLearningsCount: number,
  learningType: LearningType,
  status: LearningStatus,
}

export interface ReceivedLearningQuestionResponse {
  id: string,
  text: string,
}

export interface ReceivedLearningQuestion {
  id: string,
  rank: number,
  topic: HTMLString,
  response: ReceivedLearningQuestionResponse,
}

export interface SCReceivedLearningDetail {
  id: string,
  title: string,
  contentUrl: string,
  dueDate?: string,
  introduction: string,
  receivedDateTime: string,
  questions: ReceivedLearningQuestion[],
  assigner: LearningPersonDisplayInformation,
  assignee: LearningPersonDisplayInformation,
  status: LearningStatus,
}

export interface ReceivedPlaylistLearning {
  contentUrl: string,
  id: string,
  introduction: HTMLString,
  title: string,
  rank: number,
  questions: ReceivedLearningQuestion[],
  status: LearningStatus,
  dueDate?: string,
}

export interface ReceivedPlaylistDetail {
  id: string,
  title: string,
  description: string,
  dueDate?: string,
  completedLearningsCount: number,
  totalLearningsCount: number,
  status: LearningStatus,
  learnings: ReceivedPlaylistLearning[],
  assigner: LearningPersonDisplayInformation,
}

export interface CompleteLearningData {
  learningId: string,
  responses: QuestionResponse[],
}
export interface QuestionResponse {
  questionId: string,
  responseText: string,
}

// Learning templates
export interface UserInfoForResponses {
  name: string,
  ownerId?: string,
}

export interface LearningTemplate {
  uid: string,
  contentTemplateTitle: string,
  ownedBy: UserInfoForResponses,
  link: string,
  learningType: LearningType,
  numberOfContents: number,
}
export interface LearningTemplateList {
  learningTemplates: LearningTemplate[]
  total: number,
  count: number,
}

export enum LearningCategoryName {
  Teamwork = 'Teamwork',
  PersonalGrowth = 'Personal Growth',
  Communication = 'Communication',
  Leadership = 'Leadership',
  Culture = 'Culture',
  LeadrResources = 'Leadr Resources',
}

export interface LearningTemplateCategory {
  categoryId: string,
  categoryName: LearningCategoryName,
}
export interface LearningTemplateCategories {
  categories: LearningTemplateCategory[],
}
export interface LearningTemplateFromSearch extends LearningTemplate, LearningTemplateCategory { }

export interface LearningPlaylistTemplateLearningsQuestion {
  questionId: string,
  questionText: string
  rank: number,
}
export interface LearningPlaylistTemplateLearning {
  id: string,
  title: string,
  introduction: string,
  contentURL: string,
  questions: LearningPlaylistTemplateLearningsQuestion[],
  rank: number,
}

// Iframely
interface IframelyMetaData {
  title: string,
  date: string,
  author: string,
  description: string,
  duration: number,
  likes: string,
  views: string,
  site: string,
  canonical: string,
  author_url: string,
  medium: string,
}

interface IframelyIconMedia {
  height: number,
  width: number,
}

interface IframelyIcon {
  href: string,
  media?: IframelyIconMedia,
  rel: string[],
  type: string,
}

interface IframelyPlayerMedia {
  'aspect-ratio': number,
  scrolling: string,
}

interface IFramelyPlayerOption {
  label: string,
  value: string,
  placeholder?: string,
}

interface IframelyPlayerOptions {
  '_start': IFramelyPlayerOption,
  '_end': IFramelyPlayerOption,
  '_cc_load_policy': IFramelyPlayerOption,
}

interface IframelyPlayer {
  href: string,
  html: string,
  rel: string[],
  type: string,
  media: IframelyPlayerMedia,
  options: IframelyPlayerOptions,
}

interface IframelyThumbnailMedia {
  width: number,
  height: number,
}

interface IframelyThumbnail {
  'content_length': number,
  href: string,
  rel: string[],
  type: string,
  media: IframelyThumbnailMedia,
}

interface IframelyLinks {
  icon: IframelyIcon[],
  player?: IframelyPlayer[],
  thumbnail: IframelyThumbnail[],
}

export interface IframelyContent {
  url: string,
  html: HTMLString,
  rel: string[],
  meta?: IframelyMetaData,
  links: IframelyLinks,
  error?: string,
}

// This is our error messaging, it has nothing to do with Iframely apis
export enum IframelyErrorMessage {
  VALID = 'VALID',
  ERROR_FETCHING = 'There was an error fetching the content with the provided url. You can still proceed, but we cannot generate a preview.',
  ERROR_VALIDATING = 'Please enter a valid link that begins with https.',
}

// Learning Template Library
export interface OnUseTemplateParams {
  templateId: string,
  isPlaylist?: boolean,
  playlistContentList?: FrontendPlaylistLearning[],
  createWorkflowCallback?: () => void,
}

// Learning Playlists

export interface FrontendPlaylistLearning {
  contentURL: string,
  introduction: string,
  title: string,
  rank: number,
  questions: FrontendRankedQuestion[],
  dueDate?: Date,
  id?: string,
}
