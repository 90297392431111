import { css } from '@emotion/react';
import { ButtonBase } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { elevate } from '~Common/styles/animations';
import CircleAvatar from '~Deprecated/ui/components/Users/Avatars/CircleAvatar';
import UserInsightsReport from '~Deprecated/ui/components/Insights/InsightDetails/UserInsightsReport';
import InsightStatusIndicatorTooltip from '~Deprecated/ui/views/Old/People/PeopleList/InsightStatusIndicatorTooltip';
import { palette } from '~Common/styles/colors';
import { withLineClamp } from '~Common/styles/mixins';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { PERSON } from '~Common/const/proptypes';

const styles = {
  container: css`
    &, &.MuiButtonBase-root {
      display: flex;
      flex-direction: column;
      background-color: ${palette.neutrals.white};
      border-radius: 0.5rem;
      padding: 1rem;
      width: 100%;
      min-width: 250px;
      align-items: flex-start;
      text-align: start;
    }
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `,
  user: css`
    display: flex;
    align-items: center;
  `,
  image: css`
    border-radius: 50px;
  `,
  username: css`
    margin-inline-start: 0.5rem;
    flex: 1;
    min-width: 0;
    ${withLineClamp(1)}
  `,
  insights: css`
    min-width: 104px;
  `,
};

const View = ({
  orgUserId,
  profileImageUrl,
  firstName,
  lastName,
  report,
  onPersonClick,
  hasManager,
}) => (
  <ButtonBase css={[styles.container, elevate]} onClick={onPersonClick}>
    <div css={styles.header}>
      <div css={styles.user}>
        <CircleAvatar
          size={24}
          css={styles.image}
          avatarTooltip={`${firstName} ${lastName}`}
          imgUrl={profileImageUrl}
          name={`${firstName} ${lastName}`}
        />
        <div css={styles.username}>{ `${firstName} ${lastName}` }</div>
      </div>
      { report && (
      <div css={styles.insights}>
        <UserInsightsReport
          hasManager={hasManager}
          metricHealthReport={report}
          renderTooltip={(tooltipProps) => (
            <InsightStatusIndicatorTooltip
              {...tooltipProps}
              id={orgUserId}
            />
          )}
        />
      </div>
      ) }
    </div>
  </ButtonBase>
);

View.propTypes = {
  orgUserId: PropTypes.string,
  profileImageUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  report: PropTypes.object,
  onPersonClick: PropTypes.func.isRequired,
  hasManager: PropTypes.bool.isRequired,
};

View.defaultProps = {
  orgUserId: '',
  profileImageUrl: '',
  firstName: '',
  lastName: '',
  report: null,
};

const MetricsDrawerPersonCard = ({
  id, user, targetUserId, ...props
}) => {
  const dispatch = useDispatch();

  const userId = user?.userId;
  const orgUserId = user?.orgUserId;
  const profileImageUrl = user?.profileImageUrl;
  const firstName = user?.firstName?.trim();
  const lastName = user?.lastName?.trim();
  const hasManager = !!user?.manager?.orgUserId;
  const report = user?.report;

  const onPersonClick = useCallback(() => {
    dispatch(
      pushDrawerAction({
        drawer: {
          ...baseballCardDrawerTemplate,
          args: {
            id: userId,
          },
        },
      }),
    );
  }, [dispatch, userId]);

  const hookProps = {
    orgUserId,
    profileImageUrl,
    firstName,
    lastName,
    report,
    hasManager,
    onPersonClick,
  };

  if (!user) {
    return null;
  }

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

MetricsDrawerPersonCard.propTypes = {
  id: PropTypes.string.isRequired,
  targetUserId: PropTypes.string.isRequired,
  user: PERSON.isRequired,
};

MetricsDrawerPersonCard.defaultProps = {};

export default MetricsDrawerPersonCard;
