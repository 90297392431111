import { useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { cloneDeep } from 'lodash';
import { AnswerShape, ReviewAnswer, ReviewShape } from '../Const/types';
import { reviewCycleKeys } from './queryKeys';

interface answerTopicForReviewProps {
  answers: AnswerShape,
  reviewUid: string,
}

const version = '2.5';

const maxRetryCount = 3;

// I don't like that this is called "answers" when it is a singular object.
// need to come back and update
// TODO: use more accurate variable name
const answerTopicForReview = ({ answers, reviewUid }: answerTopicForReviewProps): Promise<HttpCallReturn<unknown>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, version),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviews/${reviewUid}/answers`,
  };

  return postApi<unknown>(serverUrl, answers, {});
};

interface OnMutateReturn {
  previousAnswer: unknown
}

const onMutate = async (answerToUpdate: answerTopicForReviewProps): Promise<OnMutateReturn> => {
  const reviewKey = reviewCycleKeys.reviewByIdAndVersion(answerToUpdate.reviewUid, version);
  // cancel current queries
  await queryClient.cancelQueries({ queryKey: reviewKey });

  // get current value
  const previousAnswer = queryClient.getQueryData(reviewKey);

  // update current value
  queryClient.setQueryData<HttpCallReturn<ReviewShape>>(
    reviewKey,
    (review) => {
      if (review) {
        const reviewWithNewAnswer = cloneDeep(review);

        const { participants } = reviewWithNewAnswer.response;
        const participantUid = participants.find((participant) => participant.orgUserId === getOrganizationUserId())?.uid;

        if (!participantUid) {
          return reviewWithNewAnswer;
        }

        const topicIndex = reviewWithNewAnswer.response.topics.findIndex((topic) => topic.uid === answerToUpdate.answers.reviewTopicUid);
        const topic = reviewWithNewAnswer.response.topics[topicIndex];

        const answerIndex = topic.answers.findIndex((answer) => answer.participantUid === participantUid);

        let answer: ReviewAnswer;
        if (answerIndex !== -1) {
          answer = topic.answers[answerIndex];
        } else {
          answer = {
            uid: '-1',
            participantUid,
            topicUid: topic.uid,
            numericResponse: undefined,
            stringResponse: undefined,
            createdAt: new Date(),
            lastModifiedAt: new Date(),
          };
        }

        // need to drill down to the specific answer that needs updated
        answer.stringResponse = answerToUpdate.answers.stringResponse;
        answer.numericResponse = answerToUpdate.answers.numericResponse;

        // set the new answer
        if (answerIndex !== -1) {
          reviewWithNewAnswer.response.topics[topicIndex].answers[answerIndex] = answer;
        } else {
          reviewWithNewAnswer.response.topics[topicIndex].answers[topic.answers.length] = answer;
        }

        return reviewWithNewAnswer;
      }

      return review;
    },
  );

  // return snapshot
  return {
    previousAnswer,
  };
};

export const useAnswerTopic = (
  reviewUid: string,
  setSaveRetrying: (isRetrying: boolean) => void,
): UseMutationResult<HttpCallReturn<unknown>, unknown, answerTopicForReviewProps> => {
  const queryKey = [getOrganizationId(), 'reviewCycles'];
  const activeMutations = useRef(0);

  const mutation = useMutation({
    mutationFn: answerTopicForReview,
    async onMutate(answerToUpdate: answerTopicForReviewProps) {
      activeMutations.current += 1;
      return onMutate(answerToUpdate);
    },
    onError: () => {
      toast.error('There was an error answering. Please try again.', {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSettled: async () => {
      activeMutations.current -= 1;

      setSaveRetrying(false);

      if (activeMutations.current === 0) {
        await queryClient.invalidateQueries({ queryKey: [...queryKey, reviewUid] });
      }
    },
    retry: (failureCount: number): boolean => {
      setSaveRetrying(true);
      return failureCount < maxRetryCount;
    },
  });

  return mutation;
};
