import { css } from '@emotion/react';
import { ComponentProps } from 'react';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { palette } from '~Common/styles/colors';
import { HTMLString } from '~Common/types';

const styles = {
  title: css({
    display: 'flex',
    color: palette.neutrals.gray900,
    fontWeight: '600',
    fontSize: '1.25rem',
  }),
  body: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  introduction: css({
    color: palette.neutrals.gray700,
  }),
  avatar: css({
  }),
};

interface TitleAndIntroductionProps extends ComponentProps<'div'> {
  title: string,
  introductionHTML: HTMLString,
  renderAvatar?: () => JSX.Element,
}

const TitleAndIntroduction = ({
  title,
  introductionHTML,
  renderAvatar,
  ...props
}: TitleAndIntroductionProps): JSX.Element => (
  <div {...props}>
    <div css={styles.title}>
      {title}
    </div>
    <div css={styles.body}>
      <HTMLRenderer css={styles.introduction} htmlText={introductionHTML} />
      <div css={styles.avatar}>{renderAvatar?.()}</div>
    </div>
  </div>
);

export default TitleAndIntroduction;
