import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { ViewPerspective } from '~Goals/const/types';
import { palette } from '~Common/styles/colors';
import { ChangeEvent, SyntheticEvent } from 'react';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { COMPLETED_GOAL_TABS, GOAL_TABS_LABELS, OPEN_GOAL_TABS } from '~Goals/const/defaults';
import LeadrTabs from '~Common/V3/components/LeadrTabs';
import LeadrSwitch from '~Common/V3/components/LeadrSwitch';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { useSetUserLevelEnableCascadingGoals } from '~Goals/stores/useUserLevelEnableCascadingGoals';

const styles = {
  tabHeader: css({}),
  goalTypeTabs: css({
    color: `${palette.neutrals.gray700} !important`,
    '&.Mui-selected': {
      color: `${palette.brand.indigo} !important`,
    },
  }),
};

interface TabRecord {
  label: string,
  value: string,
  toObject: Partial<Location>,
  ['data-test-id']: string,
}

interface ViewProps {
  activeTab: Goals.GoalContextType,
  tabs: TabRecord[],
  handleTabChange: (event: SyntheticEvent, tab: Goals.GoalContextType) => void,
  isMobile: boolean,
  handleCascadeViewChange: (event: ChangeEvent<HTMLInputElement>) => void,
  enableCascadingGoals: boolean,
  showCascadingGoalsToggle: boolean,
}

const View = ({
  activeTab,
  tabs,
  handleTabChange,
  isMobile = false,
  handleCascadeViewChange,
  enableCascadingGoals,
  showCascadingGoalsToggle,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.tabHeader}
    {...props}
  >
    <LeadrTabs
      value={activeTab}
      handleChange={handleTabChange}
      renderRightItem={showCascadingGoalsToggle ? () => (
        <LeadrSwitch data-test-id="goalsTableToggleCascade">
          <LeadrSwitch.Label label="Cascade View" labelPlacement="start">
            <LeadrSwitch.Switch
              onChange={handleCascadeViewChange}
              defaultChecked={enableCascadingGoals}
            />
          </LeadrSwitch.Label>
        </LeadrSwitch>
      ) : undefined}
    >
      {tabs.map((tab) => (
        <LeadrTabs.Tab
          label={tab.label === GOAL_TABS_LABELS.Organization.Label && isMobile ? 'Org Goals ' : tab.label}
          value={tab.value}
          key={tab.value}
          data-test-id={tab['data-test-id']}
          css={styles.goalTypeTabs}
        />
      ))}
    </LeadrTabs>
  </div>
);

interface TabHeaderProps extends Pick<ViewProps, 'activeTab'> {
  setActiveTab: (tab: Goals.GoalContextType) => void,
  viewPerspective: ViewPerspective,
}

const TabHeader = ({
  setActiveTab,
  viewPerspective,
  ...props
}: TabHeaderProps): JSX.Element => {
  const isCompleted = viewPerspective === ViewPerspective.Completed;
  const { orgLevelEnableCascadingGoals, userLevelEnableCascadingGoals } = useEnableCascadingGoals();
  const showCascadingGoalsToggle = orgLevelEnableCascadingGoals;
  const setEnable = useSetUserLevelEnableCascadingGoals();

  const handleTabChange = (event: SyntheticEvent, newTab: Goals.GoalContextType): void => {
    setActiveTab(newTab);
  };

  const handleCascadeViewChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEnable(event.target.checked);
  };

  const isMobile = useIsMobileQuery();

  const hookProps = {
    tabs: isCompleted ? COMPLETED_GOAL_TABS : OPEN_GOAL_TABS,
    handleTabChange,
    isMobile,
    handleCascadeViewChange,
    enableCascadingGoals: userLevelEnableCascadingGoals,
    showCascadingGoalsToggle,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TabHeader;
