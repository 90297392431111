import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserId } from '~Common/utils/localStorage';
import { PEOPLE } from '~Common/const/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { useGetOrgChart } from '~People/hooks/useGetOrgChart';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import OrgCard from '../../../deprecated/components/OrgChart/OrgCard';
import OrgCardLite from '../../../deprecated/components/OrgChart/OrgCardLite';
import './style.scss';

const OrgView = (): JSX.Element => {
  // React Redux Hooks
  const currentUserId = getUserId() ?? '';
  const history = useHistory();
  const { orgSettings } = useOrgDetailsContext();
  const isMatrixOrg = orgSettings.enableMatrixOrganization;

  // State Hooks
  const [selectedUser, setSelectedUser] = useState(currentUserId);
  const [expanded, setExpanded] = useState(true);

  const { orgChart, isLoading } = useGetOrgChart({
    selectedUserId: selectedUser,
    includeSecondaryManagers: isMatrixOrg,
  });

  const backToTeamList = (): void => {
    history.push(PEOPLE);
  };

  // If response does not exist, then do not do anything.
  if (isLoading || !orgChart) {
    return <></>;
  }

  return (
    <div className="org-view">
      <div className="back-button-container position-absolute">
        { /* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a className="btn d-flex justify-content-center align-items-center" onClick={backToTeamList}>
          <FontAwesomeIcon size="1x" icon={faArrowLeft} />
          <span className="pl-1">Team List</span>
        </a>
      </div>
      <div className="org-view-layout">
        <div className="org-view-centered">
          <div>
            <div className="org-view-wrapper">
              <div className="org-view-container">
                {
                orgChart.manager && (
                <div className="org-view-main-parent">
                  <OrgCardLite
                    current={orgChart.manager}
                    setSelectedUser={setSelectedUser}
                  />
                </div>
                )
              }
                <div
                  className={`org-view-parent${(expanded && orgChart.current.directsCount) ? ' show-children' : ''}`}
                >
                  <OrgCard
                    // @ts-expect-error TODO: Type OrgCard or rewrite
                    current={orgChart.current}
                    isCurrent={currentUserId === selectedUser}
                    setExpanded={setExpanded}
                    expanded={expanded}
                  />
                </div>
                {
                expanded && (
                <div className="org-view-children">
                  {
                    orgChart.directs.map((childEmp) => (
                      <div className="org-view-child" key={childEmp.userId}>
                        <OrgCard
                          // @ts-expect-error TODO: Type OrgCard or rewrite
                          current={childEmp}
                          setSelectedUser={setSelectedUser}
                          isCurrent={currentUserId === childEmp.userId}
                        />
                      </div>
                    ))
                  }
                </div>
                )
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgView;
