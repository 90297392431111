import { css } from '@emotion/react';

import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import Banner from '~Common/V3/components/Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hexToRGBA, palette } from '~Common/styles/colors';
import IconContents from '~Common/V3/components/Banner/IconContents';
import Button from '~Common/V3/components/Button';
import ConnectYourCalendarButton from '~Home/components/Module/Meetings/ConnectYourCalendarButton';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';

const styles = {
  banner: css({
    backgroundColor: hexToRGBA(palette.brand.sky, 0.10),
    color: palette.neutrals.gray800,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  icon: css({
    color: palette.brand.indigo,
  }),
  iconContentsContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  connectCalendarButton: css({
    color: palette.brand.indigo,
    marginRight: '0.5rem',
  }),
  dismissButton: css({
    color: palette.neutrals.gray800,
  }),
};

interface ViewProps {
  handleDismissBanner: () => void,
  connectCalendarButtonText: string,
  connectCalendarDescriptionText: string,
}

const View = ({
  handleDismissBanner,
  connectCalendarButtonText,
  connectCalendarDescriptionText,
  ...props
}: ViewProps): JSX.Element => (
  <Banner
    css={styles.banner}
    renderContents={() => (
      <>
        <div css={styles.iconContentsContainer}>
          <IconContents
            renderIcon={() => (
              <FontAwesomeIcon css={styles.icon} size="3x" icon={faCalendarAlt} />
            )}
            renderText={() => (
              <>{connectCalendarDescriptionText}</>
            )}
          />
        </div>
        <div>
          <ConnectYourCalendarButton
            css={styles.connectCalendarButton}
            buttonText={connectCalendarButtonText}
          />
          <Button
            css={styles.dismissButton}
            variant="text"
            renderContents={() => <>Dismiss</>}
            onClick={handleDismissBanner}
          />
        </div>
      </>
    )}
    {...props}
  />
);

interface ReviewUnsyncedMeetingsBannerProps {
  dismissBanner: () => void,
  calendarAuthStatus: AUTH_STATUS,
}

const ConnectCalendarBanner = ({
  dismissBanner,
  calendarAuthStatus,
  ...props
}: ReviewUnsyncedMeetingsBannerProps): JSX.Element => {
  let connectCalendarDescriptionText = '';
  let connectCalendarButtonText = '';

  if (calendarAuthStatus === AUTH_STATUS.NEEDS_RELINK) {
    connectCalendarDescriptionText = 'Reconnect Leadr with your calendar to keep your events in sync.';
    connectCalendarButtonText = 'Reconnect Calendar';
  } else if (calendarAuthStatus === AUTH_STATUS.UNLINKED) {
    connectCalendarDescriptionText = 'Connect Leadr with your calendar to link your events.';
    connectCalendarButtonText = 'Connect Calendar';
  }

  const handleDismissBanner = (): void => {
    dismissBanner();
  };

  const hookProps = {
    handleDismissBanner,
    connectCalendarButtonText,
    connectCalendarDescriptionText,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export { View };
export default ConnectCalendarBanner;
