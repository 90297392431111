import { useDispatch } from 'react-redux';
import type { UseMutationResult } from '@tanstack/react-query';
import { css } from '@emotion/react';
import {
  useForm,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import { useState } from 'react';
import { intersectionWith } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';

import type { User } from '~Common/const/interfaces';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useGetPeopleFilters } from '~Common/hooks/useGetPeopleFilters';
import { useEditUser } from '~Deprecated/hooks/admin/useEditUser';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { ApplicationGroupType } from '~Common/const/interfaces';
import Tooltip from '~Common/components/Tooltip';

import { FORM_STYLES, menuItemStyles } from '~Common/V3/components/uncontrolled/styles';
import {
  Autocomplete,
  Form,
  Select,
  TextField,
} from '~Common/V3/components/uncontrolled';
import { AutoCompleteFreeForm } from '~Common/V3/components/uncontrolled/AutoCompleteFreeForm';
import { AutoCompleteTags } from '~Common/V3/components/uncontrolled/AutocompleteTags';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import { useInviteUser } from '~Deprecated/hooks/admin/useInviteUser';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { UserStatus } from '~Admin/const/defaults';
import { useProfileEditRestrictions } from '~Common/hooks/people/useProfileEditRestrictions';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { conformToInviteDto } from '../schemata/InviteUserFormSchema';
import {
  editUserFormResolver,
  FormValues,
  conformToDto,
} from '../schemata/EditUserFormSchema';

const styles = {
  ...FORM_STYLES,
  menuItem: menuItemStyles,
  checkboxContainer: css({
    display: 'flex',
    flexDirection: 'column',
    columnGap: '0.25rem',
  }),
  closeButton: css({
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  }),
  drawerBody: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
    rowGap: '1.375rem',
  }),
  skeleton: css({
    margin: '0rem 1rem',
    maxWidth: '100%',
  }),
  autocompleteOverride: css({
    'label:not(.MuiFormLabel-filled)': {
      width: '100% !important',
    },
    'label:not(.MuiFormLabel-filled) .MuiInputLabel-asterisk': {
      right: '0',
      height: '.375rem',
      width: '.375rem',
    },
    '.Mui-error .MuiAutocomplete-endAdornment svg': {
      color: palette.brand.red,
    },
  }),
  updateMUIIcons: css({
    '& svg': {
      color: palette.brand.indigo,
    },
    '&.Mui-error svg': {
      color: palette.brand.red,
    },
  }),
  tooltipAnchor: css({
    div: {
      width: '100%',
    },
  }),
};

const editUserDrawerTemplate = {
  name: 'ADMIN_PEOPLE_EDIT_USER',
  width: DRAWER_WIDTHS.BASE,
};

const Skeleton = (): JSX.Element => (
  <>
    <SkeletonLoader
      height="3rem"
      css={styles.skeleton}
      renderComponent={() => <></>}
    />
    <SkeletonLoader
      height="1rem"
      css={styles.skeleton}
      renderComponent={() => <></>}
    />
    <SkeletonLoader
      height="1rem"
      css={styles.skeleton}
      renderComponent={() => <></>}
    />
  </>
);

type SelectOption = {
  label: string,
  value: string,
  email?: string,
};
interface ViewProps {
  accountTypes: {
    name: string,
    total: number,
    typeId: string,
  }[],
  managerAutocomplete: SelectOption[],
  secondaryManagerAutocomplete: SelectOption[],
  closeDrawerClick: () => void,
  departmentsAutocomplete: string[],
  formContext: UseFormReturn<FormValues>,
  isInviteUser: boolean,
  managerUser?: SelectOption,
  onFormSubmit: SubmitHandler<FormValues>,
  person: Partial<User>,
  secondaryManagerUser?: SelectOption,
  showMatrixOrgs: boolean,
  showSkeleton: boolean,
  submitIsLoading: boolean,
  userGroupsAutocomplete: SelectOption[],
  userGroupDefaults: SelectOption[],
  handleChange: () => void,
  checked: boolean,
  disableName: boolean,
  disableEmail: boolean,
  disableJobTitle: boolean,
  disableDepartment: boolean,
  disableManager: boolean,
  hideGroupsSection: boolean,
}

const View = ({
  closeDrawerClick,
  accountTypes,
  managerAutocomplete,
  secondaryManagerAutocomplete,
  departmentsAutocomplete,
  formContext,
  isInviteUser,
  managerUser,
  onFormSubmit,
  person,
  secondaryManagerUser,
  showMatrixOrgs,
  showSkeleton,
  submitIsLoading,
  userGroupsAutocomplete,
  userGroupDefaults,
  disableName,
  disableEmail,
  disableJobTitle,
  disableDepartment,
  disableManager,
  hideGroupsSection,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={() => (
          <button onClick={closeDrawerClick} css={styles.closeButton} title="Close drawer">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        title={isInviteUser ? 'Invite User' : 'Edit User'}
      />
    )}
    renderBody={showSkeleton ? () => <Skeleton /> : () => (
      <Form
        css={styles.drawerBody}
        formContext={formContext}
        // onSubmit={onFormSubmit}
        onSubmit={() => null}
      >
        <div css={styles.inputContainer}>
          <Tooltip content="This can only be changed by your IT administrator." disabled={!disableName}>
            <span css={styles.tooltipAnchor}>
              <TextField
                name="firstName"
                required
                disabled={disableName}
              />
            </span>
          </Tooltip>
          <Tooltip content="This can only be changed by your IT administrator." disabled={!disableName}>
            <span css={styles.tooltipAnchor}>
              <TextField
                name="lastName"
                required
                disabled={disableName}
              />
            </span>
          </Tooltip>
          <Tooltip content="This can only be changed by your IT administrator." disabled={!disableEmail}>
            <span css={styles.tooltipAnchor}>
              <TextField
                type="email"
                name="email"
                required
                disabled={disableEmail}
              />
            </span>
          </Tooltip>
          <Tooltip content="This can only be changed by your IT administrator." disabled={!disableJobTitle}>
            <span css={styles.tooltipAnchor}>
              <TextField
                name="jobTitle"
                disabled={disableJobTitle}
              />
            </span>
          </Tooltip>
          <Tooltip content="This can only be changed by your IT administrator." disabled={!disableManager}>
            <span>
              <Autocomplete
                css={styles.autocompleteOverride}
                disablePortal
                name="managerId"
                label="Manager"
                options={managerAutocomplete}
                getOptionDisabled={(option: SelectOption) => option.email === secondaryManagerUser?.email}
                defaultValue={managerUser}
                isOptionEqualToValue={(option: SelectOption, value: SelectOption) => option.value === value.value}
                readOnly={disableManager}
              />
            </span>
          </Tooltip>
          {showMatrixOrgs && (
            <>
              <Autocomplete
                name="secondaryManagerId"
                label="Secondary Manager"
                disablePortal
                options={secondaryManagerAutocomplete}
                defaultValue={secondaryManagerUser}
                getOptionDisabled={(option: SelectOption) => option.email === managerUser?.email}
                isOptionEqualToValue={(option: SelectOption, value: SelectOption) => option.value === value.value}
              />
            </>
          )}
          {!showMatrixOrgs && secondaryManagerUser && (
            <Tooltip content="To make changes to this field, you need to enable matrix organizations through your organization's admin settings.">
              <div>
                <Autocomplete
                  name="secondaryManagerId"
                  label="Secondary Manager"
                  disablePortal
                  options={secondaryManagerAutocomplete}
                  defaultValue={secondaryManagerUser}
                  getOptionDisabled={(option: SelectOption) => option.email === managerUser?.email}
                  isOptionEqualToValue={(option: SelectOption, value: SelectOption) => option.value === value.value}
                  disabled
                />
              </div>
            </Tooltip>
          )}
          <Tooltip content="This can only be changed by your IT administrator." disabled={!disableDepartment}>
            <span>
              <AutoCompleteFreeForm
                css={styles.autocompleteOverride}
                handleHomeEndKeys
                selectOnFocus
                freeSolo
                name="department"
                defaultValue={person.department || ''}
                options={departmentsAutocomplete}
                label="Department"
                disablePortal
                disabled={disableDepartment}
              />
            </span>
          </Tooltip>
          <Select
            id="edit_user_select_account_type"
            name="accountType"
            defaultValue={person.userGroupId}
            css={styles.updateMUIIcons}
            required
          >
            {accountTypes.map((accountType) => (
              <MenuItem
                css={styles.menuItem}
                key={accountType.typeId}
                value={accountType.typeId}
              >
                {accountType.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        {!hideGroupsSection && (
          <div css={styles.checkboxContainer}>
            <AutoCompleteTags
              multiple
              name="userGroups"
              options={userGroupsAutocomplete}
              label="Groups"
              defaults={userGroupDefaults}
              getOptionSelected={(option: SelectOption, optionValue: string) => option.value === optionValue}
              renderOption={(optionProps: Record<string, unknown>, option: SelectOption) => (
                <li {...optionProps} key={option.value}>
                  {option.label}
                </li>
              )}
              // getOptionLabel={(option) => option.label}
            />
          </div>
        )}
        <div>
          <SpinnerButton
            data-test-id={isInviteUser ? 'invite-user-submit-button' : 'edit-user-submit-button'}
            type="submit"
            color="secondary"
            label={isInviteUser ? 'Invite User' : 'Save Changes'}
            loadingLabel={isInviteUser ? 'Inviting...' : 'Saving...'}
            isLoading={submitIsLoading}
            onClick={formContext.handleSubmit(onFormSubmit)}
            disabled={submitIsLoading}
          />
        </div>
      </Form>
    )}
  />
);

interface EditUserDrawerProps {
  isInviteUser: boolean,
  user: User,
  allUsers: User[],
}

const EditUserDrawer = ({
  isInviteUser,
  user,
  allUsers = [],
}: EditUserDrawerProps): JSX.Element => {
  const {
    accountTypes,
    departments,
    groups: allUserGroups,
    isLoading: isFilterListLoading,
  } = useGetPeopleFilters();

  const flagUseMatrixOrgs = useFeatureFlag('webClientUseMatrixOrgs');
  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');
  const { orgSettings } = useOrgDetailsContext();
  const showMatrixOrgs = flagUseMatrixOrgs && (orgSettings.enableMatrixOrganization ?? false);
  const { mutate: editUserMutation, isPending: editUserIsLoading } = useEditUser() as UseMutationResult;
  const { mutate: inviteUserMutation, isPending: inviteUserIsLoading } = useInviteUser() as UseMutationResult;

  const hideGroupsSection = webAppTeamsV2;

  const {
    isFieldDisabled: {
      name: disableName,
      email: disableEmail,
      jobTitle: disableJobTitle,
      department: disableDepartment,
      manager: disableManager,
    },
  } = useProfileEditRestrictions();

  const [showSkeleton] = useSkeletonLoaders(isFilterListLoading);
  const dispatch = useDispatch();
  const managerAutocomplete = allUsers
    .filter((thisUser) => thisUser.id !== user.id
    && thisUser.email !== secondaryManagerUser?.email
    && thisUser.administrativeStatus !== UserStatus.Deactivated
    && thisUser.applicationGroupName // Ensure applicationGroupName exists
    && thisUser.applicationGroupName.toLowerCase() !== ApplicationGroupType.LIMITED_ACCESS.toLowerCase())
    .map((thisUser) => ({
      label: `${thisUser.firstName} ${thisUser.lastName}`,
      value: thisUser.id,
      email: thisUser.email,
    }));
  const secondaryManagerAutocomplete = allUsers
    .filter((thisUser) => thisUser.id !== user.id
    && thisUser.email !== managerUser?.email
    && thisUser.administrativeStatus !== UserStatus.Deactivated
    && thisUser.applicationGroupName // Ensure applicationGroupName exists
    && thisUser.applicationGroupName.toLowerCase() !== ApplicationGroupType.LIMITED_ACCESS.toLowerCase())
    .map((thisUser) => ({
      label: `${thisUser.firstName} ${thisUser.lastName}`,
      value: thisUser.orgUserId,
      email: thisUser.email,
    }));

  const managerUser: SelectOption = managerAutocomplete.find((thisUser: SelectOption) => (thisUser.value === user.managerId)) as SelectOption;
  const secondaryManagerUser: SelectOption = secondaryManagerAutocomplete.find((thisUser) => (thisUser.value === user.mentors?.[0])) as SelectOption;

  const departmentsAutocomplete = departments.map((department) => department.value);

  const joinedUserGroups = user.teams.map((team) => team.teamId);
  const userGroupsAutocomplete = allUserGroups.map((group) => ({
    label: group.name,
    value: group.teamId,
  }));

  const userGroupDefaults: SelectOption[] = intersectionWith(
    userGroupsAutocomplete,
    joinedUserGroups,
    (group, teamId) => group.value === teamId,
  );

  function defaultValues(): FormValues {
    const {
      firstName,
      lastName,
      email,
      department,
      userGroupId,
      jobTitle,
    } = user;
    return {
      firstName,
      lastName,
      email,
      department,
      jobTitle,
      accountType: userGroupId,
      managerId: managerUser?.value ?? '',
      secondaryManagerId: secondaryManagerUser?.value ?? '',
      userGroups: Array.from(joinedUserGroups),
    };
  }
  const formContext = useForm<FormValues>({
    defaultValues: isInviteUser ? {} : defaultValues(),
    resolver: editUserFormResolver,
  });
  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
  };

  const [checked, setChecked] = useState(user.eligibleForBenefits);

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    const formData = isInviteUser ? conformToInviteDto(data, { enableMatrixOrgs: flagUseMatrixOrgs })
      : conformToDto(data, { enableMatrixOrgs: flagUseMatrixOrgs });

    const onSuccess = async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'user-managers'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'insightsPeers'] });
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));
    };
    if (isInviteUser) {
      inviteUserMutation({ userInformation: formData }, { onSuccess });
    } else {
      editUserMutation({ userId: user.id, updatedUserInfo: formData }, { onSuccess });
    }
  };

  const hookProps = {
    accountTypes,
    managerAutocomplete,
    secondaryManagerAutocomplete,
    closeDrawerClick,
    departmentsAutocomplete,
    formContext,
    isInviteUser,
    managerUser,
    onFormSubmit,
    person: user,
    secondaryManagerUser,
    showMatrixOrgs,
    showSkeleton,
    submitIsLoading: editUserIsLoading || inviteUserIsLoading,
    userGroupsAutocomplete,
    userGroupDefaults,
    handleChange: () => {
      setChecked(!checked);
    },
    checked,
    disableName: disableName && !isInviteUser,
    disableEmail: disableEmail && !isInviteUser,
    disableJobTitle: disableJobTitle && !isInviteUser,
    disableDepartment: disableDepartment && !isInviteUser,
    disableManager: disableManager && !isInviteUser,
    hideGroupsSection,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: editUserDrawerTemplate.name,
  component: EditUserDrawer,
});

export { View, EditUserDrawer, editUserDrawerTemplate };
export default EditUserDrawer;
