import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import {
  HomeModule,
  HomeModuleSettings,
  WhatsDueOptions,
} from '~Home/const';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { cloneDeep } from 'lodash';
import { homeQueryKeys } from './queryKeys';

export interface GetHomeSettingsResponse {
  orgUserId: string,
  selectedTeamId: string,
  whatsDue: WhatsDueOptions,
  [HomeModule.Celebrate]: HomeModuleSettings,
  [HomeModule.EngagementSurvey]: HomeModuleSettings,
  [HomeModule.Goals]: HomeModuleSettings,
  [HomeModule.Insights]: HomeModuleSettings,
  [HomeModule.Meetings]: HomeModuleSettings,
  [HomeModule.OrgClarity]: HomeModuleSettings,
  [HomeModule.People]: HomeModuleSettings,
  [HomeModule.Recognition]: HomeModuleSettings,
  [HomeModule.TeamClarity]: HomeModuleSettings,
  [HomeModule.WhatsDue]: HomeModuleSettings,
}

export interface GetHomeSettingsParams {
  orgId: string,
  userId: string,
}

const getHomeSettings = async ({
  orgId,
  userId,
}: GetHomeSettingsParams): Promise<HttpCallReturn<GetHomeSettingsResponse>> => {
  const URL = {
    host: hosts.action,
    uri: `/organizations/${orgId}/users/${userId}/settings/home`,
  };

  return getApi(URL, {});
};

export const useHomeSettings = (
  enabled = true,
): UseQueryResult<HttpCallReturn<GetHomeSettingsResponse>> => {
  const orgId = getOrganizationId() as string;
  const userId = getUserId() as string;
  const webAppTgCobrandingAndOrgClarity = useFeatureFlag('webAppTgCobrandingAndOrgClarity');

  return useQuery({
    queryKey: homeQueryKeys.homeSettings(orgId, userId),
    queryFn: () => getHomeSettings({
      orgId,
      userId,
    }),
    select: (data) => {
      let tempData = data;
      tempData = cloneDeep(data);
      if (webAppTgCobrandingAndOrgClarity === false) {
        // @ts-expect-error This will be fixed when the feature flag is removed, hard to type this
        delete (tempData.response)?.ORG_CLARITY;
      }
      return tempData;
    },
    enabled,
  });
};
