import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare, faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';

import LeadrCard, { LeadrCardProps } from '~Common/V3/components/LeadrCard';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import DaySection, { DaySectionSkeleton } from '~Home/components/Module/Meetings/DaySection';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import ModuleHeader from '~Home/components/common/ModuleHeader';
import ModuleFooter from '~Home/components/common/ModuleFooter';
import ConditionalWrapper from '~Common/components/ConditionalWrapper';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import { useLocalStorage } from '~Common/hooks/useLocalStorage';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { meetingTypePickerTemplate, useGetMeetingTypeSelectionOptions } from '~Common/components/MeetingTypePicker';
import { COACHING_HOME } from '~Common/const/routes';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { palette } from '~Common/styles/colors';
import { createMeetingTemplate } from '~Meetings/components/drawers/CreateMeetingDrawer';
import { cardAuxStyles } from '~Home/const/cardAuxStyles';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useGetUpcomingMeetings } from '~Home/hooks/useGetUpcomingWeek';
import { MyScheduleDay } from '~Home/const/interfaces';
import { NUMBER_OF_MEETING_TO_GET_IN_MY_SCHEDULE } from '~Home/const';
import { useSupportChat } from '~Common/hooks/useSupportChat';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TeamsListScope } from '~People/components/Teams/const/types';
import { useIsReadOnlyCalendarIntegration } from '~Meetings/hooks/CalendarIntegration/useIsReadOnlyCalendarIntegration';

const styles = {
  container: css({}),
  header: css({
    marginBottom: '0.5rem',
  }),
  rightSection: css({
    display: 'flex',
    alignItems: 'center',
  }),
  addButton: css({
    fontSize: '.75rem',
    fontWeight: 600,
    color: palette.brand.indigo,
    marginRight: '1.25rem',
  }),
  overflowMenuIcon: css({
    color: palette.neutrals.gray500,
  }),
  refreshMeetingsContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  refreshMeetingsText: css({
    color: palette.neutrals.gray700,
  }),
  textButton: css({
    color: palette.brand.indigo,
    fontWeight: 600,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  emptyStateIcon: css({
    color: palette.brand.sky,
  }),
  daySection: css({
    '&:not(:first-of-type)': {
      paddingTop: '1.125rem',
      borderTop: `1px solid ${palette.neutrals.gray400}`,
    },
    '&:not(:last-of-type)': {
      paddingBottom: '1.125rem',
    },
  }),
  addNewSkeletonButton: css({
    maxWidth: '3.125rem',
    width: '3.125rem',
  }),
};

interface ViewProps extends Omit<LeadrCardProps, 'children'> {
  overflowMenuItems: MenuItem[][],
  onAddNewMeeting: () => void,
  meetingsWithDays: MyScheduleDay[] | undefined,
  isLoading: boolean,
  refetchUpcomingMeetings: () => void,
  onContactSupport: () => void,
  canCreateMeetings: boolean,
  isPersonInfoLoading: boolean,
  dragHandleProps?: DraggableProvidedDragHandleProps,
  hasTeams?: boolean,
}

const View = ({
  overflowMenuItems,
  onAddNewMeeting,
  meetingsWithDays,
  isLoading,
  refetchUpcomingMeetings,
  onContactSupport,
  canCreateMeetings,
  isPersonInfoLoading,
  dragHandleProps,
  hasTeams,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard
    css={[cardAuxStyles, styles.container]}
    {...props}
  >
    <ModuleHeader
      {...dragHandleProps}
      css={styles.header}
      headerText="Meetings"
      renderRightSection={() => (
        <div css={styles.rightSection}>
          <ConditionalWrapper
            renderWrapperCondition={isPersonInfoLoading}
            renderWrapper={(children) => (
              <SkeletonLoader
                css={styles.addNewSkeletonButton}
                renderComponent={() => (<>{children}</>)}
              />
            )}
            renderContents={() => (
              <>
                {canCreateMeetings && (
                  <LeadrButton
                    css={styles.addButton}
                    variant="text"
                    onClick={onAddNewMeeting}
                    data-test-id="addMeeting"
                  >
                    + Add New
                  </LeadrButton>
                )}
              </>
            )}
          />

          <OverflowMenu
            menuItems={overflowMenuItems}
            renderOverflowMenuButton={(doOpen) => (
              <LeadrButton
                onClick={doOpen}
                variant="text"
                data-test-id="meetingOverflowMenu"
              >
                <FontAwesomeIcon
                  css={styles.overflowMenuIcon}
                  icon={faEllipsisVertical}
                />
              </LeadrButton>
            )}
          />
        </div>
      )}
    />

    {isLoading && (
      <DaySectionSkeleton css={styles.daySection} />
    )}

    {!isLoading && (
      <>
        {!meetingsWithDays && (
          <div css={styles.refreshMeetingsContainer}>
            <div css={styles.refreshMeetingsText}>
              {'Meetings could not be loaded at this time. '}
              <LeadrButton
                onClick={refetchUpcomingMeetings}
                variant="text"
                css={styles.textButton}
                data-test-id="refreshCalendarView"
              >
                Refresh Calendar View
              </LeadrButton>
              {' to try again. If the issue persists, please '}
              <LeadrButton
                onClick={onContactSupport}
                variant="text"
                css={styles.textButton}
                data-test-id="contactSupport"
              >
                contact support
              </LeadrButton>
              .
            </div>
          </div>
        )}

        {meetingsWithDays && (
          <>
            {meetingsWithDays.map((myScheduleDay) => (
              <DaySection
                key={myScheduleDay.day}
                css={styles.daySection}
                myScheduleDay={myScheduleDay}
                hasTeams={hasTeams}
              />
            ))}
          </>
        )}
      </>
    )}

    <ModuleFooter
      renderFooterButton={(params) => (
        <LeadrButton
          data-test-id="viewAllCoachings"
          component={Link}
          to={COACHING_HOME}
          variant="text"
          size="small"
          {...params}
        >
          View All
        </LeadrButton>
      )}
    />
  </LeadrCard>
);

export interface MeetingsProps extends Omit<LeadrCardProps, 'children'> {
  dragHandleProps?: DraggableProvidedDragHandleProps,
}

const Meetings = ({
  dragHandleProps,
  ...props
}: MeetingsProps): JSX.Element => {
  const dispatch = useDispatch();
  const [includeIgnoredMeetings, setIncludeIgnoredMeetings] = useLocalStorage('showIgnoredMeetingsInLeadrHome', false);

  const { data, isLoading: areTeamsLoading } = useGetTeams({
    listScope: TeamsListScope.MyTeams,
    page: 0,
    count: 200,
  });

  const hasTeams = useMemo(() => (
    !!data?.response.totalTeamCount
  ), [
    data?.response,
  ]);

  const { getMeetingTypeSelectionOptions } = useGetMeetingTypeSelectionOptions();

  const { isLimitedAccess: isLimitedAccessUser, isLoading: isPersonInfoLoading } = useUserPermissions();
  const isReadOnlyCalendarIntegration = useIsReadOnlyCalendarIntegration();
  const canCreateMeetings = !isLimitedAccessUser && !isReadOnlyCalendarIntegration;

  const { meetingsWithDays, isLoading: areMeetingsWithDaysLoading, refetch: refetchUpcomingMeetings } = useGetUpcomingMeetings({
    maxNumberOfMeetingsToGet: NUMBER_OF_MEETING_TO_GET_IN_MY_SCHEDULE,
    includeIgnoredMeetings,
  });

  const [isLoading] = useSkeletonLoaders(areMeetingsWithDaysLoading || isPersonInfoLoading || areTeamsLoading);
  const { openChat } = useSupportChat();

  const onContactSupport = (): void => {
    openChat('I just encountered an error while trying to load my meetings in Leadr Home.');
  };

  const overflowMenuItems = useMemo(() => (
    [[
      {
        text: 'Show Ignored Meetings',
        icon: includeIgnoredMeetings ? faSquareCheck : faSquare,
        isDeactivated: false,
        onClick: () => {
          setIncludeIgnoredMeetings(!includeIgnoredMeetings);
        },
      },
    ]]
  ), [includeIgnoredMeetings, setIncludeIgnoredMeetings]);

  const onAddNewMeeting = (): void => {
    const meetingTypeSelectionOptions = getMeetingTypeSelectionOptions();
    dispatch(pushDrawerAction({
      drawer: {
        ...meetingTypePickerTemplate,
        args: {
          meetingTypeSelectionOptions,
          onOptionClickCallback: (drawerArgs: Record<string, unknown>) => {
            dispatch(pushDrawerAction({
              drawer: {
                ...createMeetingTemplate,
                args: {
                  ...drawerArgs,
                },
              },
            }));
          },
        },
      },
    }));
  };

  const hookProps = {
    overflowMenuItems,
    onAddNewMeeting,
    meetingsWithDays,
    isLoading,
    refetchUpcomingMeetings,
    onContactSupport,
    canCreateMeetings,
    isPersonInfoLoading,
    dragHandleProps,
    hasTeams,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default Meetings;
