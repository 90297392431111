import { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ADMIN_PEOPLE, ADMIN_USER_GROUPS } from '~Common/const/routes';

import { navigateAction } from '~Deprecated/actions/navigate';
import { noop } from '~Deprecated/utils';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';

const View = ({
  tabItems, ...props
}) => (
  <LeadrToggleTabs {...props}>
    {tabItems.map((tabItem) => (
      <LeadrToggleTabs.TextTab data-test-id={tabItem['data-test-id']} key={tabItem.value} text={tabItem.text} value={tabItem.value} />
    ))}
  </LeadrToggleTabs>
);

View.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  tabItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    route: PropTypes.string,
    'data-test-id': PropTypes.string,
  })).isRequired,
};

View.defaultProps = {
  value: '',
  onChange: noop,
};

const AdminPeopleTabNavigation = ({ ...props }) => {
  const dispatch = useDispatch();

  const defaultRoute = ADMIN_PEOPLE;

  const tabItems = [
    {
      text: 'People',
      value: defaultRoute,
      'data-test-id': 'adminPeople',
    },
    {
      text: 'Groups',
      value: ADMIN_USER_GROUPS,
      'data-test-id': 'adminUserGroups',
    },
  ];
  const location = useLocation();
  const initialValue = location.pathname === defaultRoute ? defaultRoute : ADMIN_USER_GROUPS;

  const [value, setValue] = useState(initialValue);

  const onChange = (e, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
      dispatch(navigateAction({ pathname: newValue }));
    }
  };

  const hookProps = {
    value,
    tabItems,
    onChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

AdminPeopleTabNavigation.propTypes = {};

AdminPeopleTabNavigation.defaultProps = {};

export { View };
export default AdminPeopleTabNavigation;
