import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { palette } from '~Common/styles/colors';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
  loaderStyles: css({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
    overflow: 'auto',
  }),
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const Loader = forwardRef((props, ref) => (
  <div
    css={styles.loaderStyles}
    ref={ref}
    {...props}
  >
    <CircularProgress css={styles.circularProgress} />
  </div>
));

Loader.propTypes = {

};

Loader.defaultProps = {

};

export default Loader;
