import moment from 'moment-timezone';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { palette } from '~Common/styles/colors';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';

const styles = {
  printedDateText: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
};

interface ViewProps {
  printedDateText: string,
}

const View = ({
  printedDateText,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.printedDateText}
    {...props}
  >
    <span>{`Printed ${printedDateText}`}</span>
  </div>
);

const PrintedDateText = ({ ...props }): JSX.Element => {
  const { timezone } = useTimezone();

  const printedDateText = useMemo(() => (
    moment.tz(moment.now(), timezone).format('MMMM Do, YYYY')
  ), [timezone]);

  const hookProps = {
    printedDateText,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default PrintedDateText;
