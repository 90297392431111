import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import QuestionDisplay from '~Common/components/Drawers/QuestionDisplay';
import AnswerDisplay from '~Common/components/Drawers/AnswerDisplay';
import { FEEDBACK_SHAPE } from '~Common/const/proptypes';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import { noop } from '~Deprecated/utils/';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';
import DrawerButton from '~Deprecated/ui/components/Buttons/DrawerButton';
import { BUTTON_VARIANTS } from '~Common/const/buttonVariants';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { usePrintView } from '~Deprecated/hooks/usePrintView';
import { useHistory } from 'react-router-dom';
import { REVIEWS } from '~Common/const/routes';

const styles = {
  questions: css`
    margin-top: 20px;
    margin-bottom: 20px;
  `,
  responses: css`
    margin-top: 8px;
    margin-bottom: 8px;
  `,
  printButton: css`
    margin-bottom: 1.125rem;
  `,

};

const View = ({
  feedback, onAddToMeeting, renderEditor, onPrintFeedback, printFeedbackFlag, isReviewArea,
}) => (
  <div>
    {printFeedbackFlag && !isReviewArea && (
    <DrawerButton
      css={styles.printButton}
      text="Print Feedback"
      iconName={faPrint}
      onClick={onPrintFeedback}
      variant={BUTTON_VARIANTS.NEUTRAL}
    />
    )}
    {feedback?.introduction && (
    <>
      {renderEditor()}
    </>
    )}
    { feedback.groupByQuestions?.map((question) => (
      <React.Fragment key={question.questionId}>
        <div css={styles.questions}>
          <QuestionDisplay
            richText={question?.questionText || ''}
            css={styles.view}
          />
        </div>
        {question.responses?.map((response) => (
          <div key={response.userId}>
            <div css={styles.responses}>
              <AnswerDisplay
                userId={response.userId}
                richText={response?.responseText || ''}
                status={ANSWER_STATUS.COMPLETED}
                css={styles.view}
                key={response.userId}
                onClick={() => onAddToMeeting({ otherUserId: response.userId, questions: [question.questionId] })}
                tooltip="Add this response to the next meeting"
              />
            </div>
          </div>
        ))}
        {question.assignees?.filter((assignee) => !assignee.hasCompletedFeedback).map((assignee) => (
          <div key={assignee.userId}>
            <div css={styles.responses}>
              <AnswerDisplay
                userId={assignee.userId}
                richText=""
                status={ANSWER_STATUS.INCOMPLETE}
                css={styles.view}
                key={assignee.userId}
                tooltip="Add this response to the next meeting"
              />
            </div>
          </div>
        ))}
      </React.Fragment>
    ))}
  </div>
);

View.propTypes = {
  feedback: FEEDBACK_SHAPE,
  onAddToMeeting: PropTypes.func,
  renderEditor: PropTypes.func.isRequired,
  onPrintFeedback: PropTypes.func.isRequired,
  printFeedbackFlag: PropTypes.bool.isRequired,
  isReviewArea: PropTypes.bool,
};

View.defaultProps = {
  feedback: null,
  onAddToMeeting: noop,
  isReviewArea: false,
};

const ResponsesByQuestion = ({ feedback, ...props }) => {
  const printFeedbackFlag = useFeatureFlag('printFeedback');
  const history = useHistory();
  const isReviewArea = history.location.pathname.startsWith(REVIEWS);
  const { onPrint } = usePrintView();

  const renderEditor = () => (
    <Froala
      label="Objective"
      name="introduction"
      initialValue={feedback?.introduction || ''}
      isReadOnly
    />
  );

  const hookProps = {
    renderEditor,
    feedback,
    onPrintFeedback: () => onPrint({ location: `feedback/${feedback.id}/byQuestion` }),
    printFeedbackFlag,
    isReviewArea,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ResponsesByQuestion.propTypes = {
  feedback: FEEDBACK_SHAPE,
};

ResponsesByQuestion.defaultProps = {
  feedback: null,
};

export default ResponsesByQuestion;
