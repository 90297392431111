import { ReactText, useRef } from 'react';
import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { Goals } from '@leadr-hr/types';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { goalKeys } from '../const/queryKeys';

interface CreateGoalParams {
  goal: Goals.Requests.CreateGoalRequestPayload,
}

export interface CreateGoalResponse {
  goalId: string,
  createdInMillis: number,
}

const createGoal = ({ goal }: CreateGoalParams): Promise<HttpCallReturn<CreateGoalResponse>> => {
  const serverUrl = {
    host: getHost(hosts.goals, '3'),
    uri: `/organizations/${getOrganizationId() ?? ''}/goals`,
  };

  return postApi<CreateGoalResponse>(serverUrl, { ...goal }, {});
};

type UseCreateGoalParams = Omit<
  UseMutationOptions<HttpCallReturn<CreateGoalResponse>, Error, CreateGoalParams>, 'mutationFn'
>;

export const useCreateGoal = ({
  ...options
}: UseCreateGoalParams = {}): UseMutationResult<HttpCallReturn<CreateGoalResponse>, Error, CreateGoalParams> => {
  const toastId = useRef<ReactText | number | null>(null);
  const mutation = useMutation({
    mutationFn: createGoal,
    onMutate: () => {
      toastId.current = toast.info('Creating your goal...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error creating your goal. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully created your goal.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: goalKeys.all });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeGoals(getOrganizationId() ?? '') });
    },
    ...options,
  });

  return mutation;
};
