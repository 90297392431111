import { css } from '@emotion/react';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { ACTION_MENU_PROPS_SHAPE, MENU_ITEMS_SHAPE } from '~Common/const/proptypes';

import ActionMenu, { useActionMenu } from '~Meetings/components/shared/ActionMenu';
import FlatButton from '~Common/V3/components/FlatButton';
import { palette } from '~Common/styles/colors';

const styles = {
  container: css``,
  contextMenuContainer: css({
    border: `.0625rem solid ${palette.neutrals.gray300}`,
    color: palette.neutrals.gray500,
    borderRadius: '.1875rem',
    width: '1.75rem',
    height: '1.75rem',
    fontSize: '1.125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: palette.neutrals.white,
  }),
};

const View = ({
  doOpen, menuItems, actionMenuProps, onClickCallback, ...props
}) => (
  <div
    css={styles.container}
    {...props}
  >
    <FlatButton
      onClick={doOpen}
      renderButtonContents={() => (
        <div css={styles.contextMenuContainer}>
          <FontAwesomeIcon icon={faEllipsisV} size="lg" />
        </div>
      )}
    />
    <ActionMenu
      menuItems={menuItems}
      onClick={onClickCallback}
      {...actionMenuProps}
    />
  </div>
);

View.propTypes = {
  doOpen: PropTypes.func.isRequired,
  menuItems: MENU_ITEMS_SHAPE,
  actionMenuProps: ACTION_MENU_PROPS_SHAPE.isRequired,
  onClickCallback: PropTypes.func.isRequired,
};

View.defaultProps = {
  menuItems: [[]],
};

const AdminActionMenu = ({ menuItems, ...props }) => {
  const { doOpen, ...actionMenuProps } = useActionMenu();

  const onClickCallback = useCallback(() => {
    actionMenuProps.onClose();
  }, [actionMenuProps]);

  const hookProps = {
    doOpen,
    actionMenuProps,
    onClickCallback,
    menuItems,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

AdminActionMenu.propTypes = {
  menuItems: MENU_ITEMS_SHAPE.isRequired,
};

AdminActionMenu.defaultProps = {};

export { View };
export default AdminActionMenu;
