import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Switch from '~Deprecated/ui/components/Inputs/Switch';
import { useSkeletonLoadersStore } from '~Common/hooks/useSkeletonLoadersStore';

const styles = {
  container: css`
    text-align: center;
  `,
};

// Documentation: https://leadrhq.atlassian.net/l/c/E7qyTJD3
const View = ({
  skeletonLoadersOverride,
  setSkeletonLoadersOverride,
}) => (
  <div css={[styles.container]}>
    <div>
      <h5>
        Enabling Override will show skeletons that are utilizing the useSkeletonLoaders hook.
        The purpose of this is to test different positioning and styling without having to refresh a bunch
      </h5>
      <br />
      <br />
      <Switch
        label="Enable Skeleton Loaders Override"
        onChange={() => setSkeletonLoadersOverride(!skeletonLoadersOverride)}
        checked={skeletonLoadersOverride}
      />
    </div>
  </div>
);

View.propTypes = {
  skeletonLoadersOverride: PropTypes.bool.isRequired,
  setSkeletonLoadersOverride: PropTypes.func.isRequired,
};

const SkeletonLoaders = ({
  ...props
}) => {
  const [skeletonLoadersOverride, setSkeletonLoadersOverride] = useSkeletonLoadersStore();

  const hookProps = {
    skeletonLoadersOverride,
    setSkeletonLoadersOverride,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SkeletonLoaders.propTypes = {};

SkeletonLoaders.defaultProps = {};

export { View };
export default SkeletonLoaders;
