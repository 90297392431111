import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { MOBILE_APP_LANDING_PAGE, SELECT_ORGANIZATION } from '~Common/const/routes';
import { setUserId } from '~Common/utils/localStorage';
import { getApi } from '~Deprecated/services/HttpService';
import { getHost } from '~Deprecated/services/config';
import { AUTH0_OAUTH_STATE_KEY } from '~Root/const';
import { loginUserSuccessAction } from '~Deprecated/redux/actions/authenticationActions';
import { setRefreshToken, setToken } from '~Deprecated/utils/cookies';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';
import LeadrLogoIndigo from '~Assets/images/leadrLogoIndigo.svg';
import { redirectToAuth0 } from '~Root/functions/authRedirect';
import { authStyles } from './authStyles';
import { AuthLoader } from './AuthLoader';

interface Auth0CallbackSearchParameters {
  state: string | null,
  code: string | null,
  error: string | null,
  errorDescription: string | null,
}

interface AuthRedirectReturn {
  userId: string,
  accessToken: string,
  refreshToken: string,
}

function decodeSearchParameters(search?: string): Auth0CallbackSearchParameters {
  const params = new URLSearchParams(search);

  return {
    state: params.get('state'),
    code: params.get('code'),
    error: params.get('error'),
    errorDescription: params.get('error_description'),
  };
}

const styles = {
  ...authStyles,
};

interface ViewProps {
  errorMessage: string,
  showErrorState: boolean,
}

const View = ({
  errorMessage,
  showErrorState,
}: ViewProps): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.routeContainer}>
      <LeadrLogoIndigo css={styles.logo} title="Leadr logo" data-test-id="leadrLogoIndigo" />
      <div css={styles.innerContainer}>
        {!showErrorState && (
          <>
            <AuthLoader />
          </>
        )}
        {showErrorState && !errorMessage && (
          <p>
            We&apos;ve had an issue authenticating you. Please try refreshing your browser.
            {' '}
            If you have followed an invitation email to reach this screen, please ask the user to send you a new invitation.
            {' '}
            If you experience this error repeatedly, please consult our
            {' '}
            <a href="//status.leadr.com" target="_blank" rel="noreferrer">status page</a>
            {' '}
            for outages or scheduled maintenance, or
            {' '}
            <a href="mailto:support@leadr.com">contact our support team</a>
            {' '}
            for additional help.
          </p>
        )}
        {showErrorState && errorMessage !== '' && (
          <p>{errorMessage}</p>
        )}
      </div>
    </div>
  </div>
);

interface AuthLandingPageProps {
  validateState?: boolean,
}

export const AuthLandingPage = ({
  validateState = true,
}: AuthLandingPageProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showErrorState, setShowErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // If they made it here at all, they're on the Public router and not logged in.

  const { search } = useLocation();

  useEffect(() => {
    // Check for search params from Auth0
    const {
      state,
      code,
    } = decodeSearchParameters(search);

    // If we don't have them, redirect the user to Auth0
    if (!code || !state) {
      if (validateState) {
        if (IS_MOBILE_APP) {
          history.push(MOBILE_APP_LANDING_PAGE);
        } else {
          redirectToAuth0();
        }
      }
      return;
    }

    Cookies.remove(AUTH0_OAUTH_STATE_KEY);

    const params = new URLSearchParams({
      code,
      state,
      redirect_url: IS_MOBILE_APP ? 'com.leadr.app:///' : window.location.origin,
    });

    async function fetchTokensAfterLogin(): Promise<void> {
      const host = getHost('', '2');
      const url = {
        host,
        uri: `/auth/redirect?${params.toString()}`,
      };

      try {
        const { response } = await getApi<AuthRedirectReturn>(url);

        const {
          accessToken,
          refreshToken,
          userId,
        } = response;

        dispatch(loginUserSuccessAction(response));
        setToken(accessToken, refreshToken);
        setRefreshToken(refreshToken);
        setUserId(userId);

        if (validateState) {
          history.replace(SELECT_ORGANIZATION);
        } else {
          // If this works, I'm going to weep.
          setTimeout(() => history.replace(SELECT_ORGANIZATION, 1000));
        }
      } catch (e) {
        setShowErrorState(true);
        // @ts-expect-error : TODO: type this exception
        setErrorMessage(e.message as string);
      }
    }

    void fetchTokensAfterLogin();
  // eslint-disable-next-line react-hooks/exhaustive-deps -- only run this once
  }, [search]);

  return (
    <View
      errorMessage={errorMessage}
      showErrorState={showErrorState}
    />
  );
};
