import { useQuery } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { ReviewCycleWithParticipants } from '../Const/types';
import { EXTENDED_QUERY_STALE_TIME } from '../Const/defaults';

interface getCycleByIdProps {
  queryKey: string[],
}

const getCycleById = async ({ queryKey }: getCycleByIdProps): Promise<HttpCallReturn<ReviewCycleWithParticipants>> => {
  const [orgId, , cycleId, version] = queryKey;

  const serverUrl = {
    host: getHost(hosts.reviewCycles, version),
    uri: `/organizations/${orgId}/reviewCycles/${cycleId}`,
  };
  return getApi<ReviewCycleWithParticipants>(serverUrl, {});
};

interface UseGetCycleByIdProps {
  queryKey?: string[],
  id: string,
  isAdmin: boolean,
}

interface useGetCycleByIdReturnProps {
  data: ReviewCycleWithParticipants | undefined,
  status: string,
  isLoading: boolean,
}

export const useGetCycleById = ({
  queryKey = [getOrganizationId() ?? '', 'reviewCycles'],
  id,
  isAdmin = false,
}: UseGetCycleByIdProps): useGetCycleByIdReturnProps => {
  const version = '2.5';
  const cycleKey = [...queryKey, id, version];

  const result = useQuery({
    queryKey: cycleKey,
    queryFn: getCycleById,
    staleTime: EXTENDED_QUERY_STALE_TIME,
    enabled: !!id && isAdmin,
  });

  const data = result?.data?.response;

  return {
    data,
    status: result?.status,
    isLoading: result?.isLoading,
  };
};
