import { useQuery } from '@tanstack/react-query';

import { useMemo } from 'react';
import moment from 'moment-timezone';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { getApi } from '~Deprecated/services/HttpService';
import { MEETING_TYPES } from '~Meetings/const/meetingTypes';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { useLastExistedValue } from '~Deprecated/hooks/useLastExistedValue';

export const getMeetingTimelineService = async ({ queryKey }) => {
  const [organizationId, payload] = queryKey;
  // eslint-disable-next-line max-len
  const URL = `/organizations/${organizationId}/timelines?page=${payload.page || 1}&count=${payload.count || 50}&filter=${payload.filters}&relatedType=${payload.relatedType}&relatedId=${payload.relatedId}`;

  return getApi(URL, {}, {});
};

/*
  WARNING
  JANK SHIT AHEAD
  JANK - 0

  We had to work around how react-query handles API Params and the fact that the timeline API for some reason is overly specific in the ID's it asks for
  As a result, we trick react-query into thinking it doesn't need to re-fetch (because it doesn't), but still provide what I suspect is a d-graph 'starting point'
*/
export const useHuddleTimeline = ({ id, type }) => {
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const { organizer, attendeeOrgUserIds, factoryId } = meetingDetails ?? {};
  const { data: peopleData = {} } = usePeopleQuery({});
  const currentUser = getOrganizationUserId();
  const related = currentUser === organizer?.orgUserId ? peopleData?.[attendeeOrgUserIds?.[0]]?.userId : peopleData?.[organizer?.orgUserId]?.userId;
  const relatedId = type === MEETING_TYPES.COACHING ? related : factoryId;
  /*
    JANK - 1
    This is a new hook that will return the last non-null or non-undefined value, useful for filling in 'gaps' when switching meetings for example
  */
  const previousExistingRelatedId = useLastExistedValue(relatedId);

  const payload = {
    page: 1,
    count: 100,
    filters: type === MEETING_TYPES.COACHING ? 'COACHING,FEEDBACK,CONTENT' : 'TEAM_MEETING',
    relatedType: type === MEETING_TYPES.COACHING ? 'USER' : 'TEAM_MEETING',
    /*
      JANK - 2
      Since we fill in the 'gap' while meeting details is re-fetched, this always ends up being the same query key, which is fine because the timeline API
      is idempotent (unchanging) regardless of the instance id passed up
    */
  };

  const result = useQuery({
    queryKey: [getOrganizationId(), 'timeline', relatedId || previousExistingRelatedId, payload],
    queryFn: async ({ queryKey }) => {
      const [organizationId,,, partialPayload] = queryKey;

      const actualPayload = {
        ...partialPayload,
        relatedId: type === MEETING_TYPES.COACHING ? related : id,
        [type === MEETING_TYPES.COACHING ? 'coachingId' : 'meetingId']: id,
      };

      return getMeetingTimelineService({ queryKey: [organizationId, actualPayload] });
    },
    enabled: !!relatedId || !!previousExistingRelatedId,
  });

  const timeline = useMemo(() => result?.data?.response?.timeline ?? {}, [result?.data]);

  return {
    ...result,
    timeline,
  };
};

export const useMergedHuddleTimelineByStartTime = ({ id, type }) => {
  const { timezone } = useTimezone();
  const { timeline, ...result } = useHuddleTimeline({ id, type });
  // We want to merge instances together until they are tied to a huddle, otherwise the url link will break Meeting Details.
  const meetingTimeline = useMemo(() => {
    const condensedTimeline = Object.entries(timeline).reduce((mergedInstance, item) => {
      const [category, items] = item;
      /* eslint-disable no-param-reassign */
      items.forEach((event) => {
        // event is a single feedback,coaching,etc...
        // eslint-disable-next-line no-param-reassign
        event.category = category;

        const instanceDate = moment.tz(event.startTimeInMillis || event.createdDateInMills, timezone).format('MMMM DD, YYYY');

        if (!mergedInstance[instanceDate]) {
          // eslint-disable-next-line no-param-reassign
          mergedInstance[instanceDate] = {
            huddles: [],
            feedback: [],
            content: [],
          };
        }

        if (event.category === 'FEEDBACK') {
          mergedInstance[instanceDate].feedback.push(event);
        } else if (event.category === 'CONTENT') {
          mergedInstance[instanceDate].content.push(event);
        } else {
          mergedInstance[instanceDate].huddles.push(event);
        }
      });
      return mergedInstance;
    }, { });
    /* eslint-enable no-param-reassign */

    const { sortedCondensedTimeline } = Object.entries(condensedTimeline)
      // Sort so that future is the most left
      .sort((a, b) => moment.tz(b[0], timezone).valueOf() - moment.tz(a[0], timezone).valueOf())
      // Merge dates without huddles into the most future neighbor with a huddle
      /* eslint-disable no-param-reassign */
      .reduce((acc, item) => {
        const [, timelineData] = item;
        const { huddles, feedback, content } = timelineData;
        // No huddles in this batch of data, so we need to orphan the data for a future huddle
        if (huddles.length === 0) {
          // N-1 case, we have a huddle in the timeline already, merge into the previous, most future huddle.
          if (acc.sortedCondensedTimeline.length !== 0) {
            const previousIndex = acc.sortedCondensedTimeline.length - 1;
            const dataToPush = {};

            dataToPush.feedback = [...acc.sortedCondensedTimeline[previousIndex][1].feedback, ...feedback];
            dataToPush.content = [...acc.sortedCondensedTimeline[previousIndex][1].content, ...content];
            dataToPush.huddles = acc.sortedCondensedTimeline[previousIndex][1].huddles;

            acc.sortedCondensedTimeline[previousIndex][1] = dataToPush;
          // Case 0, we have to orphan this data until we find a huddle.
          // We haven't orphaned yet, so just use timelineData as a base
          } else if (!acc.currentOrphan) {
            acc.currentOrphan = timelineData;
          // Multiple orphans at the very start, merge into the previous orphan until we find a huddle
          } else {
            acc.currentOrphan.feedback = [...acc.currentOrphan.feedback, ...feedback];
            acc.currentOrphan.content = [...acc.currentOrphan.content, ...content];
          }
        // At this point, we are working with a date that has a huddle
        // First, check if there is orphaned feedback or learning
        } else if (acc.currentOrphan) {
          // Merge the orphaned content and feedback into the timeline
          const dataToPush = {};
          dataToPush.feedback = [...acc.currentOrphan.feedback, ...feedback];
          dataToPush.content = [...acc.currentOrphan.content, ...content];
          dataToPush.huddles = huddles;
          acc.currentOrphan = null;

          acc.sortedCondensedTimeline.push([item[0], dataToPush]);
          // No orphaned data, huddle exists, just pass it over and move on
        } else {
          acc.sortedCondensedTimeline.push(item);
        }

        return acc;
      }, { currentOrphan: null, sortedCondensedTimeline: [] });
      /* eslint-enable no-param-reassign */

    return sortedCondensedTimeline;
  }, [timeline, timezone]);

  return {
    ...result,
    timeline: meetingTimeline,
  };
};
