import {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { noop } from '~Deprecated/utils/index';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { selectParticipantsDrawerTemplate } from '~Common/components/Drawers/SelectParticipantsDrawer';
import DrawerInput from '~Common/V3/components/DrawerInput';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { palette, inputBackgrounds } from '~Common/styles/colors';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import Froala from '~Common/V3/components/Froala';
import { RequestSendSurvey, useSendSurveyState } from '~Surveys/Hooks/useSendSurveyState';
import { useCreateSurveyTemplate } from '~Surveys/Hooks/useSurveyTemplates';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import DrawerInfo from '~Common/components/Drawers/Info';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { createSurveyParticipants } from './Participants';
import DatePicker from '../../Shared/DatePicker';
import { useSurveyQuestions } from '../../../Hooks/useSurveyQuestions';
import Questions from './Questions';

export const createSurveyTemplate = {
  name: 'CREATE_SURVEY_TEMPLATE_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  icon: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -15px;
    pointer-events: none;
    position: absolute;
    right: 0;
  `,
  due: css`
    & input{
      font-weight: 40!important;
    }
  `,
  caret: css`
    color: ${palette.brand.indigo};
    font-size: 1rem;
    top:.5rem;
    pointer-events: none;
  `,
  buttonIcon: css`
    color: ${palette.brand.sky};

    & > div{
        width:18px !important;
        height:18px !important;

        & svg{
          width: inherit !important;
          height: inherit !important;
        }
    }
  `,
  inputField: css`
    margin-top: 1.125rem;
    margin-bottom: .625rem;
    font-size: 1rem;
    color: ${palette.neutrals.gray900};
    background: ${inputBackgrounds.rgba};
    border-radius: .5rem;
    border:none;
    position:relative;

    & input{
      font-weight: 600 !important;
      font-size: .875rem;
      padding: 0;
      min-height: auto;
    }
    & textarea{
      font-size: .875rem;
    }
    & label{
      font-size: .75rem;
      color: ${palette.neutrals.gray500};
      width: 100%;
      background: ${inputBackgrounds.hex};
      border-radius: .5rem;
      font-weight: 400;
    }
  `,
  sectionHeader: css`
    margin-top: 0;
    padding-top: 0;
    text-transform: uppercase;
    font-size:.625rem;
  `,
  questionsSection: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginBottom: '1.5rem',
  }),
};

const View = ({
  onFormSubmit,
  onAddParticipant,
  closeButton,
  backButton,
  onDateChange,
  drawerTitle,
  drawerState,
  frontendSurveyTemplates,
  onEditQuestion,
  setSurveyQuestions,
  onAddQuestion,
  questionKey,
  updateDrawer,
  onDescriptionChange,
  willSaveTemplate,
  setWillSaveTemplate,
  initalTitle,
  initalDescription,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        title={drawerTitle}
        renderBackButton={backButton}
        renderCloseButton={closeButton}
      />
    )}
    renderBody={(defaultBodyStyles) => (
      <div css={defaultBodyStyles}>
        <DrawerInput
          name="title"
          css={styles.inputField}
          initialValue={initalTitle}
          label="Title"
          required
          onChange={updateDrawer}
        />
        <Froala
          enableEmojis
          styleOverrides={styles.editor}
          froalaConfigProps={{
            charCounterCount: true,
            charCounterMax: 2000,
          }}
          label="Description"
          name="objective"
          richTextEditorProps={{
            name: 'objective',
            initialValue: initalDescription,
            onChange: onDescriptionChange,
          }}
        />
        <DatePicker
          label="Due By"
          required
          selectedDate={drawerState.survey?.due}
          onDateSelected={onDateChange}
          css={[styles.inputField, styles.due]}
          name="dueDate"
          disablePast
          rightIconType={() => (
            <div css={styles.icon}>
              <FontAwesomeIcon
                icon={faCaretDown}
                css={styles.caret}
              />
            </div>
          )}
          // onChange={updateDrawer}
        />
        {frontendSurveyTemplates && (
          <FormControlLabel
            label="Save as Template?"
            control={(
              <CustomCheckbox
                checked={willSaveTemplate}
                onChange={(e) => setWillSaveTemplate(e.target.checked)}
              />
            )}
          />
        )}
        <DrawerInfo
          css={styles.sectionHeader}
          info="Questions"
        />
        <div css={styles.questionsSection}>
          <Questions
            isReadOnly={false}
            questions={drawerState?.[questionKey]}
            onEditQuestion={onEditQuestion}
            setSurveyQuestions={setSurveyQuestions}
            onAddQuestion={onAddQuestion}
          />
          <div>
            <LeadrButton
              variant="ghost"
              data-test-id="surveysAddQuestion"
              onClick={onAddQuestion}
              type="submit"
              size="small"
            >
              + Add Question
            </LeadrButton>
          </div>
        </div>
        <LeadrButton
          onClick={onAddParticipant}
          disabled={!drawerState?.survey?.title?.length || !drawerState?.[questionKey]?.length}
          type="submit"
          data-test-id="surveysSaveAndSelectParticipants"
        >
          Save and Select Participants
        </LeadrButton>
      </div>
    )}
  />
);

View.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onAddParticipant: PropTypes.func.isRequired,
  closeButton: PropTypes.func.isRequired,
  backButton: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  questionKey: PropTypes.string.isRequired,
  drawerTitle: PropTypes.string.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  frontendSurveyTemplates: PropTypes.bool.isRequired,
  onEditQuestion: PropTypes.func,
  setSurveyQuestions: PropTypes.func.isRequired,
  onAddQuestion: PropTypes.func.isRequired,
  updateDrawer: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  willSaveTemplate: PropTypes.bool.isRequired,
  setWillSaveTemplate: PropTypes.func.isRequired,
  initalTitle: PropTypes.string,
  initalDescription: PropTypes.string,
};

View.defaultProps = {
  initalTitle: '',
  initalDescription: '',
  onEditQuestion: noop,
};

const CreateSurveyDrawer = ({
  drawerState, injectedState = null, setDrawerState, popDrawer, popAfter, pushDrawer, ...props
}) => {
  useEffect(() => {
    if (injectedState) {
      setDrawerState(injectedState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [injectedState]);

  const dispatch = useDispatch();
  const onDateChange = ({ date }) => {
    setDrawerState((prev) => ({
      ...prev,
      survey: {
        ...prev.survey,
        due: date,
      },
    }));
  };

  const {
    onAddQuestion, onEditQuestion, questionKey, setSurveyQuestions,
  } = useSurveyQuestions({
    setDrawerState,
    pushDrawer,
    popAfter,
    drawerName: createSurveyTemplate.name,
    drawerState,
  });

  const { mutate: saveTemplateMutation } = useCreateSurveyTemplate();
  const [willSaveTemplate, setWillSaveTemplate] = useState(false);

  const initalTitle = drawerState.survey?.title ?? '';
  const initalDescription = () => {
    if (drawerState.survey?.objective) {
      return drawerState.survey.objective;
    }
    return '';
  };

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton onClick={closeDrawerClick} tooltip="Close" type="button" icon={faTimes} css={closeButtonStyles} />
  );
  const updateDrawer = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    setDrawerState((prev) => ({
      ...prev,
      survey: {
        ...prev.survey,
        [name]: value,
      },
    }));
  };

  const onDescriptionChange = ({ value }) => {
    setDrawerState((prev) => ({
      ...prev,
      survey: {
        ...prev.survey,
        objective: value,
      },
    }));
  };
  const [sendSurvey, setSendSurvey] = useSendSurveyState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAddParticipant = () => {
    if (sendSurvey !== RequestSendSurvey.ALLOWED) {
      setSendSurvey(RequestSendSurvey.ALLOWED);
    }

    if (willSaveTemplate) {
      saveTemplateMutation({
        ...(drawerState?.survey ?? {}),
        questions: drawerState?.surveyQuestions ?? [],
      });
    }

    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...createSurveyParticipants,
      },
    }));
    // TODO: Fix should NOT be using this. Need a better way to handle transitions
    setTimeout(() => {
      popAfter({ drawerName: createSurveyParticipants.name });
      pushDrawer({
        drawer: {
          ...selectParticipantsDrawerTemplate,
          args: {
            allowSelf: false,
          },
        },
      });
    }, '500');
  };

  const frontendSurveyTemplates = useFeatureFlag('webClientSurveysTemplates');

  const hookProps = {
    drawerName: createSurveyTemplate.name,
    onAddParticipant,
    closeButton,
    drawerTitle: 'Create a Survey',
    frontendSurveyTemplates,
    onDateChange,
    onAddQuestion,
    setSurveyQuestions,
    questionKey,
    drawerState,
    onEditQuestion,
    updateDrawer,
    onDescriptionChange,
    willSaveTemplate,
    setWillSaveTemplate,
    initalTitle,
    initalDescription,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

CreateSurveyDrawer.propTypes = {
  popDrawer: PropTypes.func.isRequired,
  popAfter: PropTypes.func.isRequired,
  pushDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  injectedState: PropTypes.object,
};

CreateSurveyDrawer.defaultProps = {
  injectedState: null,
};

registerDrawer({
  templateName: createSurveyTemplate.name,
  component: CreateSurveyDrawer,
});

export { View };
export default CreateSurveyDrawer;
