import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { camelCase } from 'lodash';
import {
  ClosedGoalStatuses, GoalStatusLabel, OpenGoalStatuses, ViewPerspective,
} from '~Goals/const/types';
import { SelectChangeEvent } from '@mui/material';
import { GOAL_FILTER_STYLES } from '~Goals/const/styles';
import GoalStatus from './GoalStatus';

const styles = {
  ...GOAL_FILTER_STYLES,
  dropdown: (hasValue: boolean) => css({
    width: '11rem',
    border: '1px solid transparent',
    padding: 0,

    '.MuiSelect-select': {
      fontSize: '0.75rem',
      paddingLeft: '0.75rem',
    },

    // ToDo: Fix the placement of the dropdown arrow inside the common Dropdown component
    '.MuiSelect-icon': {
      right: '0.75rem',
    },
  }, hasValue && {
    borderColor: palette.brand.indigo,
  }),
  dropdownItemBoy: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
  }),
};

const renderValue = (value: Goals.GoalStatus[]): string => {
  if (value?.length) {
    const statusLabels = value.map((statusValue) => GoalStatusLabel[statusValue]);
    return statusLabels.join(', ');
  }

  return 'All Statuses';
};

const View = ({
  onChange,
  items,
  value,
  ...props
}: DropdownProps<Goals.GoalStatus[]>): JSX.Element => (
  <div>
    <p css={styles.filterLabel(!!value?.length)}>Status</p>
    <Dropdown
      multiple
      displayEmpty
      css={styles.dropdown(!!value?.length)}
      value={value}
      renderItem={(item: DropdownItem<Goals.GoalStatus[]>) => (
        <div css={styles.dropdownItemBoy}>
          <CustomCheckbox checked={value?.includes(item.value)} />
          <GoalStatus status={item.value} />
        </div>
      )}
      onChange={onChange}
      items={items}
      renderValue={renderValue}
      {...props}
    />
  </div>
);

interface StatusFilterUniqueProps {
  viewPerspective: ViewPerspective,
}

const StatusFilter = ({
  viewPerspective,
}: Omit<DropdownProps<Goals.GoalStatus[]>, 'items' | 'renderValue'> & StatusFilterUniqueProps): JSX.Element => {
  const [value, setStatusValue] = useQueryParamState<Goals.GoalStatus[]>('goals', 'status', [], true);
  const filters = viewPerspective === ViewPerspective.Open ? OpenGoalStatuses : ClosedGoalStatuses;
  const items = filters.map((statusValue) => ({
    value: statusValue,
    text: '',
    'data-test-id': `goalsFilter${camelCase(statusValue)}`,
  }));

  const onChange = (event: SelectChangeEvent<Goals.GoalStatus[]>): void => {
    setStatusValue(event.target.value as Goals.GoalStatus[]);
  };

  const hookProps = {
    value,
    items,
    onChange,
    renderValue,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default StatusFilter;
