import { useParams } from 'react-router';
import { CardSkeleton } from '~Common/V3/components/Card';
import { ListSectionSkeleton } from '~Common/V3/components/ListSection';
import { ListDashboardSkeleton } from '~Common/components/Loader/ListDashboard';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import PrintWrapper from '~Common/V3/components/Print/PrintWrapper';
import { useHistory } from 'react-router-dom';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '../Const/pageStyles';
import { useGetReviewById } from '../Hooks/useGetReviewById';
import { ReviewHistoryState, ReviewResponseDetails } from '../pages/ReviewResponseDetails';
import { ReviewShape } from '../Const/types';
import { ReadOnlyReviewResponseDetails } from '../pages/ReadOnlyReviewResponseDetails';
import { returnReviewParticipantType } from '../Hooks/returnReviewParticipantType';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
};

interface ViewProps {
  showSkeleton: boolean,
  review: ReviewShape | undefined,
  isParticipant: boolean,
}
const View = ({
  showSkeleton,
  review,
  isParticipant,
}: ViewProps): JSX.Element => (
  <PrintWrapper>
    {showSkeleton && (
      <PrintWrapper.BasePrintHeaderSkeleton />
    )}
    {!showSkeleton && (
      <PrintWrapper.BasePrintHeader
        title={`Reviews - ${review?.reviewCycle?.name ?? ''}`}
        subTitle={review?.reviewCycle?.description}
        printButtonDataTestId="printReviews"
      />
    )}
    <PrintWrapper.BasePrintBody>
      {showSkeleton && (
        <>
          <div css={[styles.topBar]}>
            <div css={styles.leftSide(false)}>
              <MultipleSkeletonLoaders
                css={styles.topBarSkellyLoader}
                numberOfSkeletons={1}
                renderSkeletonItem={() => (
                  <CardSkeleton css={styles.cardSkeleton} />
                )}
              />
            </div>
          </div>
          <ListSectionSkeleton css={styles.listSectionSkeleton} />
          <ListDashboardSkeleton
            amountOfLoaders={2}
          />
        </>
      )}
      {!showSkeleton && review && (
        <>
          {isParticipant && (
            <ReviewResponseDetails review={review} />
          )}
          {!isParticipant && (
            <ReadOnlyReviewResponseDetails review={review} />
          )}
        </>
      )}
    </PrintWrapper.BasePrintBody>
  </PrintWrapper>
);

interface ReviewDetailsParams {
  reviewId: string,
}

const ReviewsPrint = (): JSX.Element => {
  const { reviewId } = useParams<ReviewDetailsParams>();
  const history = useHistory();
  const routeState = history.location.state as ReviewHistoryState;
  const prevPage = routeState?.prevPage;
  const isComingFromAdminView = prevPage?.includes('reviews/admin/cycle/');
  const { data, isLoading } = useGetReviewById({
    id: reviewId,
    isAdminAccess: isComingFromAdminView,
  });
  const review = data?.response;
  const { isManager, isSecondaryManager, isReviewee } = returnReviewParticipantType(review);
  const [showSkeleton] = useSkeletonLoaders(isLoading);

  const hookProps = {
    showSkeleton,
    review,
    isParticipant: isManager || isSecondaryManager || isReviewee,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default ReviewsPrint;
