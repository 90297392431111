import { css } from '@emotion/react';
import { palette, userGroupsCard } from '~Common/styles/colors';
import { forMobile } from '~Common/styles/mixins';

export const PAGE_STYLES = {
  createButton: css({
    maxHeight: '3.75rem',
  }),
  description: css({
    marginTop: '.625rem',
  }),
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  }, forMobile(`
    flex-direction: column;
  `)),
  information: css({
    maxWidth: '60%',
  }),
  pageHeader: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1.875rem',
  }, forMobile(`
    flex-direction: column !important;
  `)),
  searchBox: css({
    backgroundColor: palette.neutrals.white,
    borderColor: userGroupsCard.searchBorder,
  }),
  tabNavigation: css({
    marginBottom: '1.6875rem',
  }),
  titleText: css({
    fontSize: '1.5rem',
    color: userGroupsCard.brandTextColor,
    fontWeight: '600',
  }),
  viewCard: css({
    borderRadius: '0.5rem',
  }),
  dropdown: css({
    fontSize: '0.75rem',
    marginLeft: '-0.5rem',
    padding: '0.25rem',
    paddingLeft: '0.75rem',
    width: 'auto',

    '.MuiInput-root': {
      fontSize: '0.75rem',
      marginTop: 0,
    },
  }),
  tabContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }, forMobile(`
    flex-direction: column-reverse;
  `)),
  tableControls: css({
    alignItems: 'center',
    color: palette.neutrals.gray500,
    columnGap: '1.25rem',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.75rem',
  }),
  adminWrapperButton: css({
    marginLeft: '.875rem',
  }, forMobile(`
    margin: 0 .875rem .875rem 0;
`)),
  transparentBG: (isTransparent: boolean) => css({
  }, !isTransparent && {
    backgroundColor: 'transparent',
  }),
  filtersWrapper: css({
    display: 'flex',
  }),
  noSearchResultsImage: css({
    maxHeight: '7.5rem',
  }),
  viewFiltersButton: css({
    fontSize: '1.125rem',
    color: palette.brand.indigo,
  }),
  emptySearchOrFilters: css({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: palette.neutrals.gray600,
    padding: '2rem 0',
  }),
  filterButton: css({
    border: `1px solid ${palette.neutrals.gray300}`,
    borderRadius: '5px',
    color: palette.brand.indigo,
    marginLeft: '1.5rem',
    height: '2.8125rem',
    width: '2.8125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:disabled': {
      opacity: '.3',
      backgroundColor: palette.neutrals.gray300,
      color: palette.neutrals.gray400,
    },
  }),
  buttonLoadingContainer: css({
    alignItems: 'center',
    columnGap: '0.75rem',
    display: 'flex',
    flexDirection: 'row',
  }),
  cardSkeleton: css`
    max-width: 100%;
    height: 5.625rem;
  `,
  listSection: css`
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill);
    width: 100%;
    padding: 1rem 0;
  `,
  importBtnContainer: css({
    display: 'flex',
    alignItems: 'center',
  }),
  disabledImportButton: css({
    backgroundColor: palette.neutrals.gray300,
    cursor: 'not-allowed',
    border: 0,
    '&:hover': {
      backgroundColor: palette.neutrals.gray300,
    },
  }),
};
