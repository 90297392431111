import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import SectionHeader, { SectionHeaderSkeleton } from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import { palette } from '~Common/styles/colors';
import { forMobile } from '~Common/styles/mixins';
import { noop } from '~Deprecated/utils';

const styles = {
  listWrapper: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: 0.5rem;
  `,
  sectionHeader: css`
    grid-column: 1/-1;
    position: sticky;
    top: -2px;
    background: ${palette.neutrals.gray50};
    z-index: 1;
    margin-inline: -0.75rem;
    width: calc(100% + 1.5rem);

    & > h6 {
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }

    ${forMobile(`
      margin-inline: -0.5rem;
      width: calc(100% + 1rem);
      padding: 0 0.5rem;
    `)}
  `,
};

const GroupedListView = ({
  listWrapperStyle, groupedIds, renderItem,
}) => (
  <div css={[styles.listWrapper, listWrapperStyle]}>
    { Object.keys(groupedIds).map((groupName, i) => (
      <React.Fragment key={`grouped-wrapper-${i + 1}`}>
        <SectionHeader text={groupName} css={styles.sectionHeader} />
        { groupedIds[groupName]?.map((groupItem, index) => renderItem(groupItem, index)) }
      </React.Fragment>
    )) }
  </div>
);

GroupedListView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listWrapperStyle: PropTypes.object,
  groupedIds: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))).isRequired,
  renderItem: PropTypes.func.isRequired,
};

GroupedListView.defaultProps = {
  listWrapperStyle: {},
};

const SkeletonView = ({
  listWrapperStyle, numberOfGroups, numberOfSkeletonCards, renderSkeletonItem,
}) => (
  <div css={[styles.listWrapper, listWrapperStyle]}>
    { [...Array(parseInt(numberOfGroups, 10))].map((_, i) => (
      <React.Fragment key={`grouped-skeleton-${i + 1}`}>
        <SectionHeaderSkeleton css={styles.sectionHeader} />
        {[...Array(parseInt(numberOfSkeletonCards, 10))].map((__, index) => (
          <React.Fragment key={`numberOfSkeletonCards-${index + 1}`}>
            {renderSkeletonItem()}
          </React.Fragment>
        ))}
      </React.Fragment>
    )) }
  </div>
);

SkeletonView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listWrapperStyle: PropTypes.object,
  numberOfGroups: PropTypes.number,
  numberOfSkeletonCards: PropTypes.number,
  renderSkeletonItem: PropTypes.func,
};

SkeletonView.defaultProps = {
  listWrapperStyle: {},
  numberOfGroups: 0,
  numberOfSkeletonCards: 0,
  renderSkeletonItem: noop,
};

export default GroupedListView;

export { SkeletonView as GroupedListViewSkeleton };
