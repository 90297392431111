import { Route, Switch } from 'react-router-dom';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import { GOALS_BASE_ROUTE } from '~Common/const/routes';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';
import LayoutBridge from '~Deprecated/ui/routes/layouts/LayoutBridge';
import NewGetContent from '~Deprecated/ui/routes/layouts/NewGetContent';
import GoalsTopBar from '~Goals/components/GoalsTopBar';
import ViewGoal from '~Goals/routes/ViewGoal';
import EditGoal from '~Goals/routes/EditGoal';
import CreateGoal from '~Goals/routes/CreateGoal';
import { ViewPerspective } from '~Goals/const/types';
import CanNotViewGoalDetails from '~Goals/components/Shared/CanNotViewGoalDetails';
import Goals from '~Goals/components';
import OldGoals from '~Goals/OldGoals';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';

export const GoalsRoutes = {
  Dashboard: `${GOALS_BASE_ROUTE}`,
  Create: `${GOALS_BASE_ROUTE}new`,
  ListOpen: `${GOALS_BASE_ROUTE}open`,
  ListComplete: `${GOALS_BASE_ROUTE}complete`,
  ViewById: `${GOALS_BASE_ROUTE}:goalId`,
  GoalStatus: `${GOALS_BASE_ROUTE}:goalId/statuses`,
  GoalStatusById: `${GOALS_BASE_ROUTE}:goalId/statuses/:statusId`,
  EditById: `${GOALS_BASE_ROUTE}:goalId/edit`,
  PermissionsDenied: `${GOALS_BASE_ROUTE}denied`,
} as const;

interface ViewProps {
  cascadingGoals: boolean,
}

const View = ({
  cascadingGoals,
}: ViewProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[GoalsRoutes.Dashboard, GoalsRoutes.ListOpen]}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<GoalsTopBar viewPerspective={ViewPerspective.Open} />}
            ChildComponent={cascadingGoals ? <Goals viewPerspective={ViewPerspective.Open} /> : <OldGoals viewPerspective={ViewPerspective.Open} />}
          />
        </BaseLayout>
      )}
    />
    <Route
      exact
      path={GoalsRoutes.ListComplete}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<GoalsTopBar viewPerspective={ViewPerspective.Completed} />}
            ChildComponent={cascadingGoals ? <Goals viewPerspective={ViewPerspective.Completed} /> : <OldGoals viewPerspective={ViewPerspective.Completed} />}
          />
        </BaseLayout>
      )}
    />
    <Route
      exact
      path={GoalsRoutes.Create}
      render={() => (
        <LayoutBridge
          Component={(
            <NewGetContent
              ChildComponent={<CreateGoal />}
            />
          )}
        />
      )}
    />
    <Route
      exact
      path={GoalsRoutes.PermissionsDenied}
      render={(routeProps) => (
        <LayoutBridge
          routeProps={routeProps}
          Component={(
            <NewGetContent
              ChildComponent={<CanNotViewGoalDetails />}
            />
        )}
        />
      )}
    />
    <Route
      exact
      path={GoalsRoutes.ViewById}
      render={(routeProps) => (
        <LayoutBridge
          routeProps={routeProps}
          Component={(
            <NewGetContent
              ChildComponent={<ViewGoal />}
            />
        )}
        />
      )}
    />

    <Route
      exact
      path={[GoalsRoutes.GoalStatus, GoalsRoutes.GoalStatusById]}
      render={() => (
        <BaseLayout>
          <ViewGoal
            isOnStatusRoute
          />
        </BaseLayout>
      )}
    />

    <Route
      exact
      path={GoalsRoutes.EditById}
      render={(routeProps) => (
        <LayoutBridge
          routeProps={routeProps}
          Component={(
            <NewGetContent
              ChildComponent={<EditGoal />}
            />
        )}
        />
      )}
    />
  </Switch>
);

const GoalsRouter = (): JSX.Element => {
  const { featureFlagEnableCascadingGoals } = useEnableCascadingGoals();

  const hookProps = {
    cascadingGoals: featureFlagEnableCascadingGoals,
  };

  return (
    <View {...hookProps} />
  );
};

export default GoalsRouter;
