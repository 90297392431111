import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { leadrPubSub } from '~Deprecated/services/EventService';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import FeedbackItemComponent from '~Deprecated/components/Feedback/FeedbackList/feedback-item.component';
import { parseQueryString } from '~Deprecated/utils';

// import './styles.scss';

const View = ({
  page,
  feedbackList,
}) => (
  <>
    { feedbackList?.map((f, key) => (
      <FeedbackItemComponent
        key={`feedback-item-${key}`}
        page={page}
        userId={getUserId()}
        orgId={getOrganizationId()}
        feedbackId={f.id}
        assigneeId={f.assigneeId}
      />
    ))}
  </>
);

// TODO: Rewrite this with new UI in meetings
/**
 * @deprecated Old UI
 */
const MeetingFeedback = ({ page }) => {
  const history = useHistory();

  const [feedbackList, setFeedbackList] = useState();

  useEffect(() => {
    const subscription = leadrPubSub.subject('timeline_date_selection').subscribe((d) => {
      setFeedbackList(d.data?.data?.FEEDBACK);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const hookProps = {
    page,
    feedbackList,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default MeetingFeedback;
