import { connect } from 'react-redux';
import EditPersonalityTypeDetailsDrawer from '~Deprecated/ui/components/DrawerManager/DrawerTemplates/EditPersonalityTypeDetailsDrawer';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { getPersonalityDetails } from '~Deprecated/selectors/personalities/getPersonalitesById';
import { editPersonalityDetailsById } from '~Deprecated/actions/personalities/editPersonalityDetailsById';
import { clearPersonalityDetails } from '~Deprecated/actions/personalities/clearPersonalityDetails';
import { loadPersonalitiesAction } from '~Deprecated/actions/personalities/loadPersonalities';
import { getPersonalityId } from '~Deprecated/selectors/personalities/getPersonalityId';
import { isEditPersonalityTypeLoading } from '~Deprecated/selectors/personalities/isPersonalitiesLoading';

const mapStateToProps = (state) => {
  const personalityId = getPersonalityId(state);

  return {
    details: getPersonalityDetails(state, { id: personalityId }),
    personalityId,
    hasUpdateCallPending: isEditPersonalityTypeLoading(state),
  };
};

const mapDispatchToProps = {
  popDrawer: popDrawerAction,
  editPersonalityDetailsById,
  clearPersonalityDetails,
  loadPersonalitiesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalityTypeDetailsDrawer);
