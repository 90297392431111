import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadPersonalitiesAction } from '~Deprecated/actions/personalities/loadPersonalities';

function PersonalitiesLoader() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPersonalitiesAction());
  }, [dispatch]);

  return false;
}

export default PersonalitiesLoader;
