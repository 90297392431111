import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useDispatch } from 'react-redux';
import { drawerInputBackground } from '~Deprecated/ui/styles/colors';
import { FEEDBACK_TEMPLATE_SHAPE, QUESTION_SHAPE } from '~Common/const/proptypes';
import Button, { BUTTON_COLOR, BUTTON_VARIANT } from '~Common/components/Buttons/Button';
/* eslint-disable import/no-cycle  */
import { useCreateFromTemplate } from '~Deprecated/hooks/feedback/useCreateFromTemplate';
/* eslint-entable */
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { forMobile } from '~Common/styles/mixins';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';
import { templateDetailsTemplate } from '~Feedback/components/Drawers/Templates/Detail';
import { palette } from '~Common/styles/colors';

const styles = {
  box: css`
    background: ${drawerInputBackground};
    color: ${palette.neutrals.gray700};
    padding: 16px 25px;
    box-sizing: border-box;
    border-radius: 8px;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.2rem;
    align-items: center;
    margin: 16px 0;
    display: flex;
    justify-content: space-between;

    h6 {
      font-size: 1rem;
      text-transform: unset;
    }
  `,
  buttons: (hasDescription) => css`
    display: flex;
    flex-direction: ${hasDescription ? 'column' : 'row'};
  `,
  button: css`
    margin-bottom: 10px;
    margin-top: 10px;

    ${forMobile(`
      &:not(:first-of-type) {
        margin-block-start: 0.5rem;
      }
    `)};

    & span {
      ${forMobile(`
        display: block;
      `)}
    }
  `,
  rteStyleOverrides: css({
    backgroundColor: 'transparent',
    padding: 0,
  }),
};
/* eslint-disable react/no-danger, react/no-danger-with-children */
const View = ({ template, onInfoClick, onAddClick }) => (
  <div
    css={styles.box}
  >
    <div>
      <h6>{ template.title }</h6>
      { template.description && (
        <Froala
          isReadOnly
          initialValue={template.description}
          styleOverrides={styles.rteStyleOverrides}
        />
      ) }
    </div>
    <div css={styles.buttons(!!template.description)}>
      <Button
        onClick={onAddClick}
        css={styles.button}
        variant={BUTTON_VARIANT.TEXT}
        buttonColor={BUTTON_COLOR.ADDITIVE}
        text="use"
      />
      <Button
        onClick={onInfoClick}
        css={styles.button}
        variant={BUTTON_VARIANT.TEXT}
        buttonColor={BUTTON_COLOR.OPTION}
        text="view"
      />
    </div>
  </div>
);
/* eslint-enable react/no-danger, react/no-danger-with-children */

View.propTypes = {
  template: FEEDBACK_TEMPLATE_SHAPE.isRequired,
  onInfoClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

// TODO: Delete this after feedback UI rewrite
/**
 * @deprecated Old TemplateItem UI
 */
const TemplateItem = ({
  template, drawerName, onlyQuestions, existingQuestions, ...props
}) => {
  const dispatch = useDispatch();
  const { onCreateFromTemplate } = useCreateFromTemplate({
    template, rootDrawerName: drawerName, onlyQuestions, existingQuestions,
  });

  const hookProps = {
    template,
    onInfoClick: () => {
      dispatch(pushDrawerAction({
        drawer: {
          ...templateDetailsTemplate,
          args: {
            id: template.id,
            isCurated: !!template.curatedTemplateId,
          },
        },
      }));
    },
    onAddClick: onCreateFromTemplate,
  };

  return (
    <View {...hookProps} {...props} />
  );
};

TemplateItem.propTypes = {
  template: FEEDBACK_TEMPLATE_SHAPE.isRequired,
  drawerName: PropTypes.string.isRequired,
  onlyQuestions: PropTypes.bool.isRequired,
  existingQuestions: PropTypes.arrayOf(QUESTION_SHAPE).isRequired,
};

export default TemplateItem;
