import { css, SerializedStyles } from '@emotion/react';
import { faArrowLeft, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useState, useEffect } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import Froala from '~Common/V3/components/Froala';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FrontendPlaylistLearning, FrontendRankedQuestion } from '~Learning/const/interfaces';
import DatePicker from '~Common/V3/components/DatePicker';
import { useCreateContentDetails } from '~Learning/hooks/utils/useCreateContentDetails';
import { useRankedListHelpers } from '~Learning/hooks/utils/useRankedListHelpers';
import Tooltip from '~Common/components/Tooltip';
import TitleAndLink from '~Learning/components/Shared/TitleAndLink';
import QuestionsList from '~Learning/components/Shared/QuestionList';
import moment, { Moment } from 'moment';
import { useGetCombinedLearningTemplateDetail } from '~Learning/hooks/utils/useGetCombinedLearningTemplateDetail';
import { createLearningPlaylistDrawerTemplate } from '..';

const styles = {
  icon: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  saveButton: css({
    marginRight: '.625rem',
  }),
  datePicker: css({
    marginTop: '0.5rem',
  }),
  datePickerLabel: css({
    display: 'flex',
    gap: '0.25rem',
    color: palette.neutrals.gray600,
    fontWeight: 500,
  }),
  caretIcon: css({
    color: palette.brand.indigo,
    fontSize: '1.2rem',
  }),
  buttonsContainer: css({
    marginTop: '2.875rem',
    display: 'flex',
  }),
  backButton: css({
    paddingLeft: '0px',
  }),
};

export interface AddNewContentDrawerState {
  contentTitle: string,
  contentLink: string,
  introduction: string,
  questions: FrontendRankedQuestion[],
  dueDate: Date,
  playlistContentList: FrontendPlaylistLearning[],
  shouldConfirmDrawerClose?: boolean,
  isCuratedByTableGroup: boolean,
}

interface AddNewContentDrawerParams extends DrawerProps<AddNewContentDrawerState> {
  templateId: string,
  contentIndexToEdit?: number,
  maxDueDate: Date,
}

export const addNewContentDrawerTemplate = {
  name: 'ADD_NEW_CONTENT_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

const AddNewContentDrawer = ({
  maxDueDate,
  drawerState,
  templateId,
  popDrawer,
  pushDrawer,
  setDrawerState,
  contentIndexToEdit,
}: AddNewContentDrawerParams): JSX.Element => {
  const [dueDate, setDueDate] = useState<Moment | null>(drawerState?.dueDate ? moment(drawerState.dueDate) : null);

  const { getNewItemRank } = useRankedListHelpers(drawerState.playlistContentList);

  const {
    onSubmit,
    questions,
    setQuestions,
    contentTitle,
    setContentTitle,
    contentLink,
    setContentLink,
    setContentLinkError,
  } = useCreateContentDetails({
    initialQuestions: drawerState.questions,
    initialContentLink: drawerState.contentLink,
    initialContentTitle: drawerState.contentTitle,
    afterSubmitValidationCallback: ({
      introduction: introductionFromForm,
      contentTitle: contentTitleFromForm,
      contentLink: contentLinkFromForm,
    }) => {
      let newLearningContentRank = 0;

      if (contentIndexToEdit === undefined) {
        newLearningContentRank = getNewItemRank();
      } else {
        newLearningContentRank = drawerState.playlistContentList[contentIndexToEdit].rank;
      }

      const newLearningContent = {
        title: contentTitleFromForm,
        contentURL: contentLinkFromForm,
        introduction: introductionFromForm,
        questions,
        rank: newLearningContentRank,
        dueDate: dueDate?.toDate(),
      };
      const newLearningContentList = [...drawerState.playlistContentList];

      if (contentIndexToEdit !== undefined) {
        newLearningContentList.splice(contentIndexToEdit, 1, newLearningContent);
      } else {
        newLearningContentList.push(newLearningContent);
      }

      setDrawerState((prev) => ({
        ...prev,
        playlistContentList: newLearningContentList,
      }));

      onGoBack();
    },
  });

  //  Begin Learning Template data grab (only used when templateId is passed into the drawer)
  const { learningTemplate } = useGetCombinedLearningTemplateDetail({
    templateId,
    isCuratedByTableGroup: drawerState.isCuratedByTableGroup,
  });

  useEffect(() => {
    if (learningTemplate) {
      setQuestions(learningTemplate.questions?.map((question) => ({ rank: parseInt(question.rank, 10), text: question.questionText })) ?? []);
      setContentTitle(learningTemplate.title);
      setContentLink(learningTemplate.contentURL);
    }
  }, [learningTemplate, setContentLink, setContentTitle, setQuestions]);
  // End Learning Template data grab

  const onGoBack = (): void => {
    setDrawerState((prev) => ({
      ...prev,
      contentTitle: '',
      contentLink: '',
      introduction: '',
      questions: [],
      dueDate: null,
    }));
    popDrawer({ popAll: true });
    pushDrawer({
      drawer: createLearningPlaylistDrawerTemplate,
    });
  };

  let title = 'Add New Content';

  if (contentIndexToEdit !== undefined) {
    title = 'Edit Learning';
  }

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title={title}
        renderBackButton={(backButtonStyles: SerializedStyles) => (
          <IconButton onClick={onGoBack} type="button" icon={faArrowLeft} css={[backButtonStyles, styles.backButton]} tooltip="Back" />
        )}
      />
    ),
    onSubmit,
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <TitleAndLink
          setContentLinkError={setContentLinkError}
          contentTitle={contentTitle}
          contentLink={contentLink}
          setContentTitle={setContentTitle}
        />
        <Froala
          name="introduction"
          label="Introduction"
          richTextEditorProps={{
            initialValue: drawerState.introduction,
          }}
        />
        <DatePicker
          maxDate={maxDueDate}
          clearable
          initialDate={dueDate?.toDate()}
          onDateSelected={({ date: newDate }) => setDueDate(newDate)}
          renderLabel={() => (
            <div css={styles.datePickerLabel}>
              <div>Due By</div>
              <Tooltip content="Please note that Learning Content due dates cannot exceed the Learning Playlist due date">
                <div><FontAwesomeIcon icon={faCircleInfo} /></div>
              </Tooltip>
            </div>
          )}
          name="dueDate"
          disablePast
          css={styles.datePicker}
          rightIconType={() => (
            <div css={styles.icon}>
              <FontAwesomeIcon
                icon={faCaretDown}
                css={styles.caretIcon}
              />
            </div>
          )}
        />
        <QuestionsList
          questions={questions}
          setQuestions={setQuestions}
        />
        <div css={styles.buttonsContainer}>
          <LeadrButton
            css={styles.saveButton}
            type="submit"
            data-test-id={contentIndexToEdit !== undefined ? 'learningPlaylistSaveContent' : 'learningPlaylistAddContent'}
          >
            {contentIndexToEdit !== undefined ? 'Save Content' : 'Add Content'}
          </LeadrButton>
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: addNewContentDrawerTemplate.name,
  component: AddNewContentDrawer,
});

export default AddNewContentDrawer;
