import {
  useQuery, UseQueryOptions, UseQueryResult,
} from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import {
  getApi,
  HttpCallReturn,
  HttpError,
} from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { ReviewShape } from '~Reviews/V2/Const/types';
import { EXTENDED_QUERY_STALE_TIME } from '../Const/defaults';

interface GetReviewParams {
  orgId: string,
  reviewId: string,
  isAdminAccess: boolean,
  version: string,
}

const getReview = async ({
  orgId,
  reviewId,
  isAdminAccess,
  version,
}: GetReviewParams): Promise<HttpCallReturn<ReviewShape>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, version),
    uri: isAdminAccess
      ? `/organizations/${orgId}/reviews/${reviewId}/admin`
      : `/organizations/${orgId}/reviews/${reviewId}`,
  };

  return getApi<ReviewShape>(serverUrl, {});
};

interface UseGetReviewByIdParams<T> extends Omit<UseQueryOptions<HttpCallReturn<ReviewShape>, HttpError, T>, 'queryKey' | 'queryFn' | 'staleTime'> {
  id: string,
  isAdminAccess?: boolean,
}

export const useGetReviewById = <T = HttpCallReturn<ReviewShape>>(
  { id, isAdminAccess = false, ...options }: UseGetReviewByIdParams<T>): UseQueryResult<T, HttpError> => {
  const version = '2.5';
  const orgId = getOrganizationId() ?? '';
  const queryKey = [orgId, 'reviews'];
  const reviewKey = [...queryKey, id, version] as readonly string[];

  return useQuery({
    queryKey: reviewKey,
    queryFn: () => getReview({
      orgId,
      reviewId: id,
      isAdminAccess,
      version,
    }),
    staleTime: EXTENDED_QUERY_STALE_TIME,
    ...options,
  });
};
