import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { deleteApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';

interface deleteTopicProps {
  id: string,
  topicId: string,
}

const deleteTopic = ({ id, topicId }: deleteTopicProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${id}/topics/${topicId}`,
  };

  return deleteApi(serverUrl, {});
};

export const useDeleteTopic = (): UseMutateFunction<HttpCallReturn<void>, unknown, deleteTopicProps, void> => {
  const queryKey = [getOrganizationId() ?? '', 'reviewTopics'];
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: deleteTopic,
    onMutate: () => {
      toastId.current = toast.info('Deleting the Question...', { autoClose: false });
    },
    onError: (error: string) => {
      toast.update(toastId.current, {
        render: error.toString() ?? 'There was an error deleting the review question. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Question Deleted.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewCycles'] });
    },
  });

  return mutation.mutate;
};
