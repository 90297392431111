import { useState } from 'react';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';

export interface HoverState {
  isHovering: boolean,
  handleMouseEnter: (id?: string | undefined) => void,
  handleMouseLeave: () => void,
  isHoveringId: string | undefined,
}

export const useHoverStateById = (): HoverState => {
  const isMobile = useIsMobileQuery();
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringId, setIsHoveringId] = useState<string>('');

  const handleMouseEnter = (id?: string): void => {
    setIsHovering(true);
    setIsHoveringId(id ?? '');
  };

  const handleMouseLeave = (): void => {
    setIsHovering(false);
    setIsHoveringId('');
  };

  return {
    isHovering: isHovering || isMobile, // Mobile doesn't have a hover, so we want these things to always be visible
    handleMouseEnter,
    handleMouseLeave,
    isHoveringId,
  };
};
