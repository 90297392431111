import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '~Reviews/V2/Const/pageStyles';
import {
  ViewPerspective, AllCycles, AllReviews,
} from '~Reviews/V2/Const/types';
import { ReviewDashboard } from '~Reviews/V2/ReviewDashboard';
import { useGetOrgCycles } from '~Reviews/V2/Hooks/useGetOrgCycles';
import { useGetReviews } from '~Reviews/V2/Hooks/useGetReviews';
import { useFilterCycles } from '~Reviews/V2/Hooks/useFilterCycles';
import { useFilterReviews } from '~Reviews/V2/Hooks/useFilterReviews';
import { GridDashboardSkeleton } from '~Common/components/Loader/GridDashboard';
import { ListSectionSkeleton } from '~Common/V3/components/ListSection';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useReviewsDashboardSearch } from '../stores/useReviewsDashboardSearch';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
};

interface ViewProps {
  showSkeleton: boolean,
  cycles: AllCycles,
  reviews: AllReviews,
  viewPerspective: ViewPerspective,
  searchText: string,
}

const View = ({
  showSkeleton,
  cycles,
  reviews,
  viewPerspective,
  searchText,
}: ViewProps): JSX.Element => (
  <>
    {showSkeleton && (
      <div>
        <ListSectionSkeleton css={styles.listSectionSkeleton} />
        <GridDashboardSkeleton
          amountOfLoaders={12}
        />
      </div>
    )}
    {!showSkeleton && (
      <ReviewDashboard
        viewPerspective={viewPerspective}
        reviews={viewPerspective === ViewPerspective.Admin ? cycles : reviews}
        searchText={searchText}
      />
    )}
  </>
);

interface ReviewsDashboardProps {
  viewPerspective: ViewPerspective,
}

const ReviewsDashboard = ({
  viewPerspective,
}: ReviewsDashboardProps): JSX.Element => {
  const { statusSortedCycles: cycles } = useGetOrgCycles({});
  const { isLoading, statusSortedReviews: myCycles } = useGetReviews({});
  const [showSkeleton] = useSkeletonLoaders(isLoading);

  const {
    debouncedSearchText: searchText,
  } = useReviewsDashboardSearch((state) => ({
    debouncedSearchText: state.debouncedSearchText,
  }));

  const filteredCycles = useFilterCycles(cycles, searchText);
  const filteredReviews = useFilterReviews(myCycles, searchText);

  const hookProps = {
    searchString: '',
    showSkeleton,
    cycles: filteredCycles,
    reviews: filteredReviews,
    searchText,
    viewPerspective,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ReviewsDashboard };
export default ReviewsDashboard;
