import { createShowModalStore } from '~Common/stores/ShowThingStore/createShowModalStore';
import { Attachment } from '../v2/useGetAgendas';

interface ModalProps {
  attachment: Attachment,
  huddleId: string,
  topicId: string,
  onConfirm?: (attachmentId: number) => void,
}

export const useConfirmAttachmentDeletionModal = createShowModalStore<ModalProps>('confirmAttachmentDeletion');
