import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { toast } from '~Common/components/Toasts';

import Loader from '~Common/components/Loader';
import ListView from '~Common/components/InfiniteList/ListView';
import LoadMoreObserver from '~Common/components/InfiniteList/LoadMoreObserver';
import GroupedListView from '~Common/components/InfiniteList/GroupedListView';

const styles = {
  loaderStyles: css`
    transform: translateY(calc(50vh - 112px));
    width: 100%;
  `,
};

const View = ({
  groupedIds,
  ids,
  renderItem,
  renderLoadMore,
  data,
  ...props
}) => (
  <>
    { groupedIds ? (
      <GroupedListView
        groupedIds={groupedIds}
        renderItem={renderItem}
        {...props}
      />
    ) : (
      <ListView
        ids={ids || []}
        renderItem={renderItem}
        data={data}
        {...props}
      />
    ) }
    { renderLoadMore() }
  </>
);

View.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  groupedIds: PropTypes.object,
  renderItem: PropTypes.func.isRequired,
  renderLoadMore: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

View.defaultProps = {
  ids: null,
  groupedIds: null,
  data: {},
};

const InfiniteListView = ({
  listQuery, loaderStyle, renderEmptyState, renderLoadingState, ...props
}) => {
  const {
    ids,
    result,
    groupedIds,
    initialPageItemCount,
    searchString,
    data,
  } = listQuery({});

  const {
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = result;

  const renderLoadMore = useCallback(() => {
    if (!hasNextPage) {
      return null;
    }

    return (
      <LoadMoreObserver isLoading={isFetchingNextPage} onObserve={fetchNextPage} />
    );
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  if (status === 'error') {
    if (process.env.NODE_ENV === 'development') {
      toast.error(error.message);
    }
    return null;
  }

  if (status === 'loading' || (!ids || (ids?.length === 0 && initialPageItemCount > 0 && !searchString))) {
    return (
      <>
        {renderLoadingState?.()}
        {!renderLoadingState && (
          <div css={[styles.loaderStyles, loaderStyle]}>
            <Loader />
          </div>
        )}
      </>
    );
  }

  if (status !== 'loading' && (ids.length === 0) && renderEmptyState) {
    return renderEmptyState();
  }

  const hookProps = {
    ids,
    renderLoadMore,
    groupedIds,
    data,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

InfiniteListView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listWrapperStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  loaderStyle: PropTypes.object,
  listQuery: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderEmptyState: PropTypes.func.isRequired,
  renderLoadingState: PropTypes.func,
};

InfiniteListView.defaultProps = {
  listWrapperStyle: {},
  loaderStyle: {},
  renderLoadingState: null,
};

export default InfiniteListView;
