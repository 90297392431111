import { css } from '@emotion/react';

import ReviewTitle from '~Reviews/V2/Shared/ReviewTitle';

import { OPTIMISTIC_ID } from '~Learning/const';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import GridCard from '~Common/components/Cards/GridCard';

const styles = {
  leadrCard: (isOptimisticUpdateCard: boolean) => css(
    isOptimisticUpdateCard && ({
      cursor: 'not-allowed',
    }),
  ),
};
interface CycleCardProps {
  reviewTitle: string,
  title: string,
  description: string,
  dueDate: string | Date | undefined,
  renderCompletedSection?: () => JSX.Element,
  isComplete: boolean,
  id: string,
}

const CycleCard = ({
  reviewTitle,
  title,
  description,
  dueDate,
  renderCompletedSection,
  isComplete,
  id,
  ...props
}: CycleCardProps): JSX.Element => (
  <GridCard
    css={styles.leadrCard(id === OPTIMISTIC_ID)}
    title={title}
    feature="reviewCycle"
    description={description}
    renderHeader={() => (
      <ReviewTitle
        reviewTitle={reviewTitle}
      />
    )}
    renderFooter={() => (
      <>
        <DueDateFromNow
          dueDate={dueDate}
          isComplete={isComplete}
        />
        {renderCompletedSection?.()}
      </>
    )}
    {...props}
  />
);

export default CycleCard;
