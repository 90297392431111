import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import {
  hosts,
} from '~Deprecated/services/config';
import { postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';

import { ReactText, useRef } from 'react';
import { toast } from '~Common/components/Toasts';

interface getCSVProps {
  cycleId: string,
  orgUserIds?: string[],
}

const getCSV = async ({ cycleId, orgUserIds }: getCSVProps): Promise<string | undefined> => {
  // if there are no ids, we want to export all users so we need to send an empty array
  const ids = orgUserIds ?? [];

  const serverUrl = {
    host: `${hosts.exports}/v2.5`,
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${cycleId}/export`,
  };
  const { response, message } = await postApi(serverUrl, ids);

  if (!response) {
    return undefined;
  }

  // Create a blob so we can make a downloadable file
  const blob = new Blob([response as BlobPart], { type: 'text/csv' });

  const url = window.URL.createObjectURL(blob);

  // Hack to create a button and click it to start the download of the CSV
  const downloadLink = document.createElement('a');
  downloadLink.href = url ?? '';
  downloadLink.download = message ?? 'Reviews Export';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  return url;
};

const createBackgroundJob = async ({ cycleId, orgUserIds }: getCSVProps): Promise<string | undefined> => {
  // if there are no ids, we want to export all users so we need to send an empty array
  const ids = orgUserIds ?? [];

  const serverUrl = {
    host: `${hosts.exports}/v2.5`,
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewExport/${cycleId}${ids.length > 0 ? '/reviews' : ''}`,
  };
  const { message } = await postApi(serverUrl, ids);

  return message;
};

export const useGetCSV = (): UseMutateFunction<string | undefined, unknown, getCSVProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: getCSV,
    onMutate: () => {
      toastId.current = toast.promise('Downloading the CSV...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error downloading the CSV. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully downloaded the CSV.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });

  return mutation.mutate;
};

export const useCreateCsvInBackground = (): UseMutateFunction<string | undefined, unknown, getCSVProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: createBackgroundJob,
    onMutate: () => {
      toastId.current = toast.promise('Sending request to export to CSV...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error requesting your export. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'We are working on exporting your data to CSV format. You will receive an email with a link to download when it\'s ready.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });

  return mutation.mutate;
};
