import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

import {
  OnUseTemplateParams,
  LearningPlaylistTemplateLearning,
  AssignLearningOptions,
  FrontendPlaylistLearning,
} from '~Learning/const/interfaces';
import { LearningPlaylistTemplateDetail } from '~Learning/hooks/templates/playlists/useGetLearningPlaylistTemplateDetail';
import PlaylistContentCard from '~Learning/components/LearningLibrary/ViewLearningPlaylistTemplateDrawer/PlaylistContentCard';
import { useState } from 'react';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import { useDeleteLearningPlaylistTemplate } from '~Learning/hooks/templates/playlists/useDeleteLearningPlaylistTemplate';
import { getUserId } from '~Common/utils/localStorage';
import { forMobileObject } from '~Common/styles/mixins';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { OPTIMISTIC_ID } from '~Learning/const';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useGetCombinedLearningPlaylistTemplateDetail } from '~Learning/hooks/utils/useGetCombinedLearningPlaylistTemplateDetail';
import { SkeletonView } from './SkeletonView';
import PlaylistTemplateDetailsCard from './PlaylistTemplateDetailsCard';
import { learningContentDetailDrawerTemplate } from './LearningContentDetailDrawer';
import { viewLearningLibraryDrawerTemplate } from '../ViewLearningLibraryDrawer';
import { editLearningPlaylistTemplateDrawerTemplate } from '../EditLearningPlaylistTemplateDrawer';

const styles = {
  drawerBody: css({
    padding: '1rem 2.1875rem 1rem 1.5rem',
  }),
  learningContentCard: css({
    marginTop: '2.5rem',
    marginBottom: '2.125rem',
  }),
  buttonsContainer: css({
    marginTop: '2.5rem',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    rowGap: '0.5rem',
    gridGap: '0.625rem',
  }),
  cardHeaderText: css({
    marginTop: '1.875rem',
    marginBottom: '.625rem',
    color: palette.neutrals.gray800,
    fontSize: '1.25rem',
    fontWeight: 500,
  }),
  modifyButtonsContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
  }, forMobileObject({
    gridRowStart: '2',
    justifyContent: 'flex-start',
  })),
  editButton: css(
    {},
    forMobileObject({
      marginRight: '.625rem',
    }),
  ),
  deleteButton: css({
    justifySelf: 'end',
  }),
  editInProgress: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
};

export const viewLearningPlaylistTemplateDrawerTemplate = {
  name: 'VIEW_LEARNING_PLAYLIST_TEMPLATE_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

export interface ViewLearningPlaylistTemplateDrawerState {
  playlistTitle: string,
  playlistDescription: string,
  playlistContentList: FrontendPlaylistLearning[],
  categoryId: string,
  workflow: AssignLearningOptions,
  isUpdatingLearningPlaylistTemplate?: boolean,
}

interface ViewLearningPlaylistTemplateDrawerProps extends DrawerProps<ViewLearningPlaylistTemplateDrawerState> {
  playlistTemplateId: number,
  page: number,
  count: number,
  isCuratedByLeadr: boolean,
  isCuratedByTableGroup: boolean,
  onUseTemplate: ({ templateId, isPlaylist }: OnUseTemplateParams) => void,
}

const ViewLearningPlaylistTemplateDrawer = ({
  playlistTemplateId,
  page,
  count,
  isCuratedByLeadr,
  isCuratedByTableGroup,
  onUseTemplate,
  setDrawerState,
  popDrawer,
  pushDrawer,
  drawerState,
}: ViewLearningPlaylistTemplateDrawerProps): JSX.Element => {
  const isPlaylistWorkflow = drawerState.workflow === AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST;

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: viewLearningPlaylistTemplateDrawerTemplate.name });
  };

  const openThisDrawer = (): void => {
    pushDrawer({
      drawer: {
        ...viewLearningPlaylistTemplateDrawerTemplate,
        args: {
          playlistTemplateId,
          page,
          count,
          isCuratedByLeadr,
          onUseTemplate,
          isCuratedByTableGroup,
        },
      },
    });
  };

  const {
    learningPlaylistTemplate: learningTemplate,
    isLoading,
  } = useGetCombinedLearningPlaylistTemplateDetail({
    playlistTemplateId,
    isCuratedByLeadr,
    isCuratedByTableGroup,
  });

  // Check for the time between an update being made and the get request coming back
  const containsOptimisticId = learningTemplate?.learnings.some((learning) => learning.id === OPTIMISTIC_ID);

  // Don't allow edits or deletions to happen when the playlist template is updating or we don't have the information back from the backend to replace the optimistic ids
  const shouldDisableMutationButtons = drawerState.isUpdatingLearningPlaylistTemplate || containsOptimisticId;

  const [selectedLearningId, setSelectedLearningId] = useState('');

  const isLearningSelected = (learningId: string): boolean => learningId === selectedLearningId;

  const deleteLearningPlaylistTemplateMutation = useDeleteLearningPlaylistTemplate({
    templateToPop: viewLearningPlaylistTemplateDrawerTemplate,
    page,
    count,
    categoryId: learningTemplate?.categoryId || '',
  });

  const onDeleteLearningPlaylistTemplate = (): void => {
    deleteLearningPlaylistTemplateMutation(playlistTemplateId);
  };

  const currentUserId = getUserId();

  const { isAdmin } = useUserPermissions();

  const isAdminOrTemplateOwner = isAdmin || learningTemplate?.owner.ownerId === currentUserId;
  const canDeletePlaylistTemplate = isAdminOrTemplateOwner && !isCuratedByLeadr && !isCuratedByTableGroup;
  const canEditPlaylistTemplate = isAdminOrTemplateOwner && !isCuratedByLeadr && !isCuratedByTableGroup;

  const onSelectThisPlaylistTemplateClick = (): void => {
    const playlistContentList = learningTemplate?.learnings.map((learning) => ({
      ...learning,
      id: undefined,
      questions: learning.questions.map((learningQuestion) => ({
        text: learningQuestion.questionText,
        rank: learningQuestion.rank,
        questionId: learningQuestion.questionId,
      })),
    }));

    onUseTemplate({
      templateId: playlistTemplateId.toString(),
      isPlaylist: true,
      playlistContentList,
      createWorkflowCallback: () => {
        setDrawerState((prev) => ({
          ...prev,
          playlistTitle: learningTemplate?.title,
          playlistDescription: learningTemplate?.description,
          playlistContentList,
          workflow: AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST,
        }));
      },
    });
  };

  const onEditClick = (): void => {
    const playlistContentList = learningTemplate?.learnings.map((learning) => ({
      ...learning,
      questions: learning.questions.map((learningQuestion) => ({
        text: learningQuestion.questionText,
        rank: learningQuestion.rank,
        questionId: learningQuestion.questionId,
      })),
    }));

    setDrawerState((prev) => ({
      ...prev,
      playlistTitle: learningTemplate?.title,
      playlistDescription: learningTemplate?.description,
      playlistContentList,
      categoryId: learningTemplate?.categoryId,
      workflow: AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST,
    }));

    popDrawer({ popAll: true });

    pushDrawer({
      drawer: {
        ...editLearningPlaylistTemplateDrawerTemplate,
        args: {
          playlistTemplateId,
          openViewPlaylistTemplateDrawer: openThisDrawer,
          page,
          count,
        },
      },
    });
  };

  const onLearningContentClick = (learning: LearningPlaylistTemplateLearning): void => {
    setSelectedLearningId(learning.id);

    // Close the learning library drawer
    popDrawer({ drawerName: viewLearningLibraryDrawerTemplate.name });

    // Reopen the view learning playlist template drawer since closing the parent learning library drawer closes this drawer too
    openThisDrawer();

    pushDrawer({
      drawer: {
        ...learningContentDetailDrawerTemplate,
        args: {
          title: learning.title,
          contentURL: learning.contentURL,
          introductionHTML: learning.introduction,
          questions: learning.questions,
          clearSelectedLearning: () => {
            setSelectedLearningId('');
          },
          setSelectedLearningId,
        },
      },
    });
  };

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title="Playlist Overview"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
      />
    ),
    renderBody: () => (
      <div css={styles.drawerBody}>
        {isLoading && SkeletonView()}
        {!isLoading && learningTemplate && (
          <View
            shouldDisableMutationButtons={shouldDisableMutationButtons}
            learningTemplate={learningTemplate}
            onSelectThisPlaylistTemplateClick={onSelectThisPlaylistTemplateClick}
            onLearningContentClick={onLearningContentClick}
            isLearningSelected={isLearningSelected}
            canDeletePlaylistTemplate={canDeletePlaylistTemplate}
            canEditPlaylistTemplate={canEditPlaylistTemplate}
            onDeleteLearningPlaylistTemplate={onDeleteLearningPlaylistTemplate}
            onEditClick={onEditClick}
            isPlaylistWorkflow={isPlaylistWorkflow}
          />
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

interface ViewProps {
  shouldDisableMutationButtons?: boolean,
  learningTemplate: LearningPlaylistTemplateDetail,
  isLearningSelected: (learningId: string) => boolean,
  onLearningContentClick: (learning: LearningPlaylistTemplateLearning) => void,
  onSelectThisPlaylistTemplateClick: () => void,
  canDeletePlaylistTemplate: boolean,
  canEditPlaylistTemplate: boolean,
  onEditClick: () => void,
  onDeleteLearningPlaylistTemplate: () => void,
  isPlaylistWorkflow: boolean,
}

const View = ({
  shouldDisableMutationButtons = false,
  learningTemplate,
  isLearningSelected,
  onLearningContentClick,
  onSelectThisPlaylistTemplateClick,
  canDeletePlaylistTemplate,
  canEditPlaylistTemplate,
  onEditClick,
  onDeleteLearningPlaylistTemplate,
  isPlaylistWorkflow,
}: ViewProps): JSX.Element => (
  <div>
    <PlaylistTemplateDetailsCard
      title={learningTemplate.title}
      descriptionHTML={learningTemplate.description}
      author={learningTemplate.owner.name}
    />
    <div>
      <div css={styles.cardHeaderText}>
        {`Playlist Content (${learningTemplate.numberOfLearnings})`}
      </div>
      {learningTemplate.learnings.map((learning) => (
        <PlaylistContentCard
          isLearningSelected={isLearningSelected(learning.id)}
          title={learning.title}
          introductionHTML={learning.introduction}
          contentUrl={learning.contentURL}
          onClick={() => onLearningContentClick(learning)}
        />
      ))}
    </div>
    <div css={styles.buttonsContainer}>
      <LeadrButton
        disabled={shouldDisableMutationButtons}
        onClick={onSelectThisPlaylistTemplateClick}
        data-test-id={isPlaylistWorkflow ? 'learningLibraryAddFromThisTemplate' : 'learningLibraryUseThisTemplate'}
      >
        {isPlaylistWorkflow ? 'Add from this Template' : 'Use this Template'}
      </LeadrButton>
      <div css={styles.modifyButtonsContainer}>
        {canEditPlaylistTemplate && (
          <LeadrButton
            variant="ghost"
            css={styles.editButton}
            disabled={shouldDisableMutationButtons}
            onClick={onEditClick}
            data-test-id="learningLibraryPlaylistEditTemplate"
          >
            {shouldDisableMutationButtons && (
              <LeadrButton.IconAndText
                icon={faSpinner}
                text="Edit in progress"
                iconProps={{
                  spin: true,
                }}
              />
            )}
            {!shouldDisableMutationButtons && (
              <>Edit</>
            )}
          </LeadrButton>
        )}
        {canDeletePlaylistTemplate && (
          <DeleteButtonWithConfirmation
            css={styles.deleteButton}
            renderDeleteButton={({ onClick }) => (
              <LeadrButton
                color="danger"
                onClick={onClick}
                disabled={shouldDisableMutationButtons}
                variant="ghost"
                data-test-id="learningLibraryPlaylistDeleteTemplate"
              >
                Delete
              </LeadrButton>
            )}
            renderConfirmationButtons={({
              informationStyles,
              optionStyles,
              popoverButtonStyles,
            }) => (
              <DeleteConfirmationButtons
                informationStyles={informationStyles}
                optionStyles={optionStyles}
                popoverButtonStyles={popoverButtonStyles}
                onDelete={onDeleteLearningPlaylistTemplate}
              />
            )}
          />
        )}
      </div>
    </div>
  </div>
);

registerDrawer({
  templateName: viewLearningPlaylistTemplateDrawerTemplate.name,
  component: ViewLearningPlaylistTemplateDrawer,
});

export default ViewLearningPlaylistTemplateDrawer;
