import { css } from '@emotion/react';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BUTTON_COLOR, BUTTON_VARIANT } from './Button';

const styles = {
  closeIcon: css({
    fontSize: '1.5rem',
    width: '1.5rem',
    height: '1.5rem',
  }),
};

const CloseDrawerButton = (props) => (
  <LeadrButton
    tooltip="Close"
    variant="icon"
    data-test-id="close-drawer"
    {...props}
  >
    <FontAwesomeIcon
      icon={faXmark}
      css={styles.closeIcon}
    />
  </LeadrButton>
);

CloseDrawerButton.propTypes = {};

CloseDrawerButton.defaultProps = {};

export default CloseDrawerButton;
export { BUTTON_COLOR, BUTTON_VARIANT };
