import { css } from '@emotion/react';
import { AgendaSection as AgendaSectionType, AgendaTopic as AgendaTopicType } from '~Meetings/hooks/v2/useGetAgendas';
import { palette } from '~Common/styles/colors';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import AgendaTopic from './AgendaTopic';

const styles = {
  agendaSection: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.25rem',
  }),
  textContainer: css({
    overflow: 'hidden',
    marginLeft: '.625rem',
  }),
  text: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    fontWeight: 600,
  }),
  collapseIcon: css({
    color: palette.neutrals.gray600,
    cursor: 'not-allowed',
  }),
  rightIcons: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
    marginLeft: 'auto',
  }),
  recurringIcon: css({
    color: palette.brand.indigo,
    fontSize: '.625rem',
  }),
  menuIcon: css({
    color: palette.neutrals.gray700,
    cursor: 'not-allowed',
  }),
  topicsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '.375rem',
    paddingLeft: '.5rem',
  }),
};

interface ViewProps {
  text: string,
  isRecurring: boolean,
  agendaTopics: AgendaTopicType[],
}

const View = ({
  text,
  isRecurring,
  agendaTopics,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.agendaSection}
    {...props}
  >
    <div css={styles.header}>
      <FontAwesomeIcon css={styles.collapseIcon} icon={faChevronDown} />
      <div css={styles.textContainer}>
        <TooltipOnTruncate css={styles.text} text={text} />
      </div>
      <div css={styles.rightIcons}>
        {isRecurring && (
          <FontAwesomeIcon css={styles.recurringIcon} icon={faArrowsRepeat} />
        )}
        <FontAwesomeIcon css={styles.menuIcon} icon={faEllipsisVertical} />
      </div>
    </div>
    <div css={styles.topicsContainer}>
      {agendaTopics.map((agendaTopic) => (
        <AgendaTopic key={agendaTopic.id} agendaTopic={agendaTopic} />
      ))}
    </div>
  </div>
);

interface AgendaSectionProps {
  agendaSection: AgendaSectionType,
}

const AgendaSection = ({
  agendaSection,
  ...props
}: AgendaSectionProps): JSX.Element => {
  const hookProps = {
    text: agendaSection.text,
    isRecurring: agendaSection.isRecurring,
    agendaTopics: agendaSection.children,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaSection;
