import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CacheProvider } from '@emotion/react';
import { domMax, LazyMotion } from 'framer-motion';
import { ThemeProvider } from '@mui/material/styles';

import moment from 'moment-timezone';
import { store } from '~Deprecated/store';
import { queryClient } from '~Common/const/queryClient';
import '~Deprecated/store/initReducer';
import '~Deprecated/store/initSaga';

import PreRouter from '~Deprecated/ui/routes';
import '~Deprecated/scss/index.scss';
import { emotionCache } from '~Common/utils';
import appTheme from '~Common/const/appTheme'; // createRoot(container!) if you use TypeScript

moment.updateLocale('en', {
  weekdaysMin: ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'],
});
LicenseInfo.setLicenseKey(process.env.MUI_X_PRO_KEY || '');

const parsedUrl = new DOMParser().parseFromString(window.location.href, 'text/html').documentElement.textContent;

if (parsedUrl !== window.location.href) {
  window.location.href = parsedUrl;
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

(async () => {
  // Start the launch darkly provider asynchronously.
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
  });
  root.render(
    (
      <ThemeProvider theme={appTheme}>
        <LDProvider>
          <CacheProvider value={emotionCache}>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <LazyMotion features={domMax} strict>
                  <PreRouter />
                  <ReactQueryDevtools buttonPosition="bottom-left" />
                </LazyMotion>
              </QueryClientProvider>
            </Provider>
          </CacheProvider>
        </LDProvider>
      </ThemeProvider>
    ),
  );
})();
