import { MouseEvent } from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import { getDateString } from '~Common/utils/dateString';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useDispatch } from 'react-redux';
import { WhatsDueItem } from '~Home/const/interfaces';
import { WhatsDueFilterOptions } from '~Home/const';
import { Link } from 'react-router-dom';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Tooltip from '~Common/components/Tooltip';

const WhatsDueTooltip: Record<WhatsDueFilterOptions, string> = {
  [WhatsDueFilterOptions.AllDue]: '',
  [WhatsDueFilterOptions.ActionItems]: 'Action Item',
  [WhatsDueFilterOptions.Feedback]: 'Feedback',
  [WhatsDueFilterOptions.Learning]: 'Learning',
  [WhatsDueFilterOptions.Reviews]: 'Review',
  [WhatsDueFilterOptions.Surveys]: 'Survey',
};

const styles = {
  cardLink: css({
    '&:hover': {
      textDecoration: 'none',
    },
  }),
  actionItem: (lastItem: boolean) => css({
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0',
    border: 'none',
    borderBottom: lastItem ? 0 : `solid .0625rem ${palette.neutrals.gray200}`,
    fontWeight: '400 !important',
    '&:hover': {
      backgroundColor: 'unset',
    },
  }),
  actionItemIcon: css({
    width: '20%',
    padding: '0 0.25rem',
  }),
  icon: css({
    color: palette.neutrals.gray600,
  }),
  actionItemText: css({
    fontSize: '0.75rem',
    flex: '1',
    padding: '0 0.5rem',
    width: '80%',
    color: palette.neutrals.gray700,
  }, withTruncate()),
  actionItemDueDate: (isPastDue?: boolean, isDueToday?: boolean, isDueTomorrow?: boolean) => {
    let color = palette.neutrals.gray600;

    if (isPastDue) {
      color = palette.brand.red;
    } else if (isDueToday || isDueTomorrow) {
      color = palette.brand.green;
    }

    return css({
      textAlign: 'right',
      minWidth: '25%',
      flex: 0,
      color,
      fontSize: '0.75rem',
    });
  },
};

interface HomeListItemProps {
  id: string | number,
  text: string,
  isPastDue?: boolean,
  isDueToday?: boolean,
  isDueTomorrow?: boolean,
  dueDateString: string,
  onItemClickHandler: (event: MouseEvent<HTMLButtonElement>) => void,
  iconToRender: IconProp,
  lastItem: boolean,
  type: WhatsDueFilterOptions,
  href?: string,
}

const View = ({
  id, text, onItemClickHandler, dueDateString, isPastDue, isDueToday, isDueTomorrow, iconToRender, lastItem, href, type,
}: HomeListItemProps):JSX.Element => (
  <>
    {type === WhatsDueFilterOptions.Reviews && (
      <Link
        key={id}
        css={styles.cardLink}
        to={href || ''}
      >
        <div css={styles.actionItem(lastItem)}>
          <Tooltip content={WhatsDueTooltip[type]}>
            <div>
              <FontAwesomeIcon
                css={styles.icon}
                icon={iconToRender}
              />
            </div>
          </Tooltip>
          <div css={styles.actionItemText}>
            {text}
          </div>
          <div css={styles.actionItemDueDate(isPastDue, isDueToday, isDueTomorrow)}>
            {dueDateString}
          </div>
        </div>
      </Link>
    )}
    {type !== WhatsDueFilterOptions.Reviews && (
      <LeadrButton
        data-test-id="home-list-item"
        variant="ghost"
        css={styles.actionItem(lastItem)}
        onClick={onItemClickHandler}
        onKeyDown={onItemClickHandler}
      >
        <Tooltip content={WhatsDueTooltip[type]}>
          <div>
            <FontAwesomeIcon
              icon={iconToRender}
              css={styles.icon}
            />
          </div>
        </Tooltip>
        <div css={styles.actionItemText}>
          {text}
        </div>
        <div css={styles.actionItemDueDate(isPastDue, isDueToday, isDueTomorrow)}>
          {dueDateString}
        </div>
      </LeadrButton>
    )}
  </>

);

interface HomeListItemContainerProps extends WhatsDueItem {
  lastItem: boolean;
  learningType?: string;
  learningStatus?: string;
}

const HomeListItem = ({
  id,
  text,
  iconToRender,
  dueDate,
  onItemClick,
  type,
  href,
  lastItem,
  learningStatus,
  learningType,
}:HomeListItemContainerProps):JSX.Element => {
  const dispatch = useDispatch();
  const {
    isPast: isPastDue,
    isTomorrow: isDueTomorrow,
    isToday: isDueToday,
    dateString: dueDateString,
  } = getDateString({ timestamp: dueDate });

  const onItemClickHandler = (event: MouseEvent<HTMLButtonElement>):void => {
    if (onItemClick) {
      onItemClick({
        id, dispatch, learningStatus, learningType, event,
      });
    }
  };

  const hookProps = {
    text,
    dueDateString: dueDateString || 'No Due Date',
    isPastDue,
    isDueToday,
    isDueTomorrow,
    iconToRender,
    onItemClickHandler,
    lastItem,
    type,
    id,
    href,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default HomeListItem;
