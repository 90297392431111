import { css } from '@emotion/react';
import LeadrSwitch from '~Common/V3/components/LeadrSwitch';
import {
  useCallback, ChangeEventHandler, ChangeEvent, useMemo, ComponentProps,
} from 'react';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import { useTimezonesList } from '~Deprecated/hooks/useTimezonesList';
import { Profile } from '~Common/const/interfaces';
import { SelectChangeEvent } from '@mui/material/Select';
import { useDebounceDraftState } from '~Common/hooks/useDebounceDraftState';
import { useUpdateUserSettings } from '~Deprecated/hooks/profile/useUserProfile';
import moment from 'moment-timezone';

const styles = {
  settingsSectionHeader: css({
    marginBottom: '1.5rem',
  }),
  filterDropdown: css({
    marginTop: '0.5rem',
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  initialTimezone: string,
  allTimezoneItems: DropdownItem<string>[],
  useDefaultTimezone: boolean,
  handleTimezoneChange: (event: SelectChangeEvent<string>) => void,
  handleDefaultTimezoneToggleChange: ChangeEventHandler<HTMLInputElement>,
}

const View = ({
  initialTimezone,
  allTimezoneItems,
  useDefaultTimezone,
  handleTimezoneChange,
  handleDefaultTimezoneToggleChange,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <SettingsSectionHeader
      css={styles.settingsSectionHeader}
      renderContents={() => (
        <>Timezone</>
      )}
    />
    <LeadrSwitch data-test-id="settingsEnableDefaultTimezone">
      <LeadrSwitch.Label label="Use Default Timezone">
        <LeadrSwitch.Switch
          onChange={handleDefaultTimezoneToggleChange}
          defaultChecked={useDefaultTimezone}
        />
      </LeadrSwitch.Label>
    </LeadrSwitch>
    <Dropdown
      css={styles.filterDropdown}
      items={allTimezoneItems}
      onChange={handleTimezoneChange}
      value={initialTimezone}
      disabled={useDefaultTimezone}
    />
  </div>
);

interface TimezoneSettingsProps extends ComponentProps<'div'> {
  userProfile: Profile,
}

const TimezoneSettings = ({
  userProfile,
  ...props
}: TimezoneSettingsProps): JSX.Element => {
  const { timezone: initialTimezone } = userProfile.orgUserSettings;
  const { useSettingsTimezone: initialUseSettingsTimezone } = userProfile.orgUserSettings;

  const { usTimezones, otherTimezones } = useTimezonesList();
  const { updateUserSettings } = useUpdateUserSettings({ silent: false });

  const usTimezoneItems = useMemo(() => Object.entries(usTimezones).map(([key, value]) => ({
    value: key,
    text: value,
  })), [usTimezones]);

  const otherTimezoneItems = useMemo(() => Object.entries(otherTimezones).map(([key, value]) => ({
    value: key,
    text: value,
  })), [otherTimezones]);

  const allTimezoneItems = useMemo(() => [...usTimezoneItems, ...otherTimezoneItems], [usTimezoneItems, otherTimezoneItems]);

  const updateUseDefaultTimezone = useCallback((newValue: boolean): void => {
    /*
      When useSettingsTimezone is true, the user picks thier timezone
      Since the logic is flipped (see comment below), we need to flip it back here
    */
    updateUserSettings({ useSettingsTimezone: !newValue });
  }, [updateUserSettings]);

  /*
    When useSettingsTimezone is true, the user picks thier timezone
    Flipping the logic here, so that the logic matches the UI
  */
  const [useDefaultTimezone, setUseDefaultTimezone] = useDebounceDraftState(!initialUseSettingsTimezone, updateUseDefaultTimezone, 500);

  const handleDefaultTimezoneToggleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setUseDefaultTimezone(event.target.checked);
  };

  const handleTimezoneChange = (event: SelectChangeEvent<string>): void => {
    updateUserSettings({ timezone: event.target.value });
    moment.tz.setDefault(event.target.value);
  };

  const hookProps = {
    initialTimezone,
    allTimezoneItems,
    useDefaultTimezone,
    handleTimezoneChange,
    handleDefaultTimezoneToggleChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TimezoneSettings;
