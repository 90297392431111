import { css } from '@emotion/react';
import { DonutChart } from '~Common/components/Charts/DonutChart';
import { palette } from '~Common/styles/colors';
import { withoutDesktop } from '~Common/styles/mixins';
import { PeoplePerspective } from '~Insights/const/types';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { MetricsForGroup } from '~Insights/hooks/usePeopleInsightsData';

const styles = {
  container: (isExpanded: boolean, isMobile: boolean) => css({
    alignItems: isExpanded ? 'initial' : 'center',
    display: 'flex',
    flexDirection: isExpanded || isMobile ? 'column' : 'row',
    flexShrink: 0,
    gap: isExpanded ? '1.625rem' : '1rem',
    paddingLeft: isExpanded ? '1rem' : '0',
  }, withoutDesktop(`
    justify-content: space-between;
    width: 100% !important;
  `)),
  donut: css({
    flexShrink: 0,
  }),
  donutSection: (isExpanded: boolean) => css({
    alignItems: isExpanded ? 'initial' : 'center',
    display: 'flex',
    flexDirection: isExpanded ? 'column' : 'row',
    flex: isExpanded ? 'auto' : '1',
    gap: '1rem',
    textAlign: isExpanded ? 'center' : 'left',
    color: palette.neutrals.gray800,
    maxWidth: '40rem',
  }, withoutDesktop(`
    flex: 1;
  `)),
};

interface DonutSidebarsProps extends MetricsForGroup {
  toggleMenuValue: PeoplePerspective,
  isExpanded?: boolean,
  userPerspective: UserPerspective,
  isMobile: boolean,
}

interface LabeledDonutProps {
  value: number,
  label: string,
  isExpanded: boolean,
}

const LabeledDonut = ({
  value,
  label,
  isExpanded = false,
}: LabeledDonutProps): JSX.Element => (
  <div css={styles.donutSection(isExpanded)}>
    <DonutChart
      css={styles.donut}
      actualValue={Math.ceil(value * 100)}
      isExpanded={isExpanded}
    />
    {`${Math.ceil(value * 100)}% ${label}`}
  </div>
);

export const DonutSidebars = ({
  toggleMenuValue,
  activeGoalsPercent,
  // activityLastLoginIn7DaysPercent,
  activityPerformedActionIn7DaysPercent,
  agendaItemPercent,
  feedbackCompletedByOnePersonIn30DaysPercent,
  feedbackRequestedIn30DaysPercent,
  goalsUpdatedIn30DaysPercent,
  oneOnOneWithManagerIn30DaysPercent,
  mamPercent,
  mauPercent,
  isExpanded = false,
  userPerspective,
  isMobile,
}: DonutSidebarsProps): JSX.Element => (
  <>
    {userPerspective === UserPerspective.manager && (
      <>
        {toggleMenuValue === PeoplePerspective.OneOnOne && (
          <div css={styles.container(isExpanded, isMobile)}>
            <LabeledDonut
              value={oneOnOneWithManagerIn30DaysPercent}
              label="of your direct reports have had 1:1's with you in the last 30 days"
              isExpanded={isExpanded}
            />
            <LabeledDonut
              value={agendaItemPercent}
              label="of your direct reports added non-recurring agenda items in their 1:1's with you in the last 30 days"
              isExpanded={isExpanded}
            />
          </div>
        )}
        {toggleMenuValue === PeoplePerspective.Goals && (
          <div css={styles.container(isExpanded, isMobile)}>
            <LabeledDonut
              value={activeGoalsPercent}
              label="of your direct reports have a goal currently in-progress."
              isExpanded={isExpanded}
            />
            <LabeledDonut
              value={goalsUpdatedIn30DaysPercent}
              label="of your direct reports have updated their goal in the last 30 days"
              isExpanded={isExpanded}
            />
          </div>
        )}
        {toggleMenuValue === PeoplePerspective.Feedback && (
          <div css={styles.container(isExpanded, isMobile)}>
            <LabeledDonut
              value={feedbackRequestedIn30DaysPercent}
              label="of your direct reports have requested feedback in the last 30 days"
              isExpanded={isExpanded}
            />
            <LabeledDonut
              value={feedbackCompletedByOnePersonIn30DaysPercent}
              label="of your direct reports have had their feedback requests completed by at least one person in the last 30 days"
              isExpanded={isExpanded}
            />
          </div>
        )}
        {toggleMenuValue === PeoplePerspective.Activity && (
          <div css={styles.container(isExpanded, isMobile)}>
            {/* Commenting out until we figure out the BE logged in/out issues
            <LabeledDonut
              value={activityLastLoginIn7DaysPercent}
              label="of your direct reports have logged into Leadr in the last 7 days"
              isExpanded={isExpanded}
            /> */}
            <LabeledDonut
              value={activityPerformedActionIn7DaysPercent}
              label="of your direct reports have taken action in Leadr in the last 7 days"
              isExpanded={isExpanded}
            />
          </div>
        )}
      </>
    )}

    {userPerspective === UserPerspective.executive && (
      <>
        {toggleMenuValue === PeoplePerspective.OneOnOne && (
          <div css={styles.container(isExpanded, isMobile)}>
            <LabeledDonut
              value={oneOnOneWithManagerIn30DaysPercent}
              label="of your people have had 1:1's with their direct manager in the last 30 days"
              isExpanded={isExpanded}
            />
            <LabeledDonut
              value={agendaItemPercent}
              label="of your people added non-recurring agenda items in their 1:1's with their direct manager in the last 30 days"
              isExpanded={isExpanded}
            />
          </div>
        )}
        {toggleMenuValue === PeoplePerspective.Goals && (
          <div css={styles.container(isExpanded, isMobile)}>
            <LabeledDonut
              value={activeGoalsPercent}
              label="of your people have a goal currently in-progress"
              isExpanded={isExpanded}
            />
            <LabeledDonut
              value={goalsUpdatedIn30DaysPercent}
              label="of your people have updated their goal in the last 30 days"
              isExpanded={isExpanded}
            />
          </div>
        )}
        {toggleMenuValue === PeoplePerspective.Feedback && (
          <div css={styles.container(isExpanded, isMobile)}>
            <LabeledDonut
              value={feedbackRequestedIn30DaysPercent}
              label="of your people requested feedback in the last 30 days"
              isExpanded={isExpanded}
            />
            <LabeledDonut
              value={feedbackCompletedByOnePersonIn30DaysPercent}
              label="of your people have had their feedback requests completed by at least one person in the last 30 days"
              isExpanded={isExpanded}
            />
          </div>
        )}
        {toggleMenuValue === PeoplePerspective.Activity && (
          <div css={styles.container(isExpanded, isMobile)}>
            {/* Commenting out until we figure out the BE logged in/out issues
            <LabeledDonut
              value={activityLastLoginIn7DaysPercent}
              label="of your people have logged into Leadr in the last 7 days"
              isExpanded={isExpanded}
            /> */}
            <LabeledDonut
              value={activityPerformedActionIn7DaysPercent}
              label="of your people have taken action in Leadr in the last 7 days"
              isExpanded={isExpanded}
            />
            {isExpanded && (
              <>
                <LabeledDonut
                  value={mauPercent}
                  label="of your people are monthly active users"
                  isExpanded={isExpanded}
                />
                <LabeledDonut
                  value={mamPercent}
                  label="of your managers are active monthly"
                  isExpanded={isExpanded}
                />
              </>
            )}
          </div>
        )}
      </>
    )}
  </>
);
