import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import DPicker from '~Deprecated/common/Components/Pickers/DatePicker';
import AssignDueDateButton from '~Common/components/Buttons/AssignDueDate';
import { BUTTON_PROPS_SHAPE, DPICKER_PROPS_SHAPE } from '~Common/const/proptypes';
import { usePrevious } from '~Deprecated/hooks/usePrevious';

const styles = {
  assignButton: css`
    margin-block-start: 1rem;
    margin-inline: unset;
  `,
};

const View = ({
  showPicker,
  disabled,
  renderAssignDueDateButton,
  renderDatePicker,
  ...props
}) => (
  <div
    {...props}
  >
    { (!showPicker && !disabled) && renderAssignDueDateButton() }
    { showPicker && renderDatePicker() }
  </div>
);

View.propTypes = {
  showPicker: PropTypes.bool.isRequired,
  renderAssignDueDateButton: PropTypes.func.isRequired,
  renderDatePicker: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

View.defaultProps = {
  disabled: false,
};

/**
 * @deprecated Old Date Picker UI, use packages/leadr-frontend/src/common/V3/components/DatePicker/index.tsx instead
 */
const OptionalDateField = ({
  initialValue,
  name,
  buttonProps,
  dPickerProps,
  showClear,
  disabled,
  forceDate,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  const previousInitialValue = usePrevious(initialValue);

  useEffect(() => {
    if (previousInitialValue !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue, previousInitialValue]);
  useEffect(() => {
    if (forceDate && !value) {
      setValue(new Date().getTime());
    }
  }, [forceDate, value]);

  const onAssignDueDate = () => {
    setValue(new Date());
  };

  const hookProps = {
    renderAssignDueDateButton: () => (
      <AssignDueDateButton
        css={styles.assignButton}
        onClick={onAssignDueDate}
        {...buttonProps}
      />
    ),
    renderDatePicker: () => (
      <DPicker
        selectedDate={value}
        disabled={disabled}
        onClear={showClear && !disabled ? setValue : undefined}
        name={name}
        {...dPickerProps}
      />
    ),
    showPicker: !!value || forceDate,
    disabled,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

OptionalDateField.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  name: PropTypes.string.isRequired,
  buttonProps: BUTTON_PROPS_SHAPE,
  dPickerProps: DPICKER_PROPS_SHAPE,
  disabled: PropTypes.bool,
  showClear: PropTypes.bool,
  forceDate: PropTypes.bool,
};

OptionalDateField.defaultProps = {
  initialValue: undefined,
  buttonProps: {},
  dPickerProps: {},
  disabled: false,
  showClear: false,
  forceDate: false,
};

export { View };
export default OptionalDateField;
