import { memo } from 'react';
import { isEqual } from 'lodash';
import { css } from '@emotion/react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import {
  ACTION_ITEMS_BASE_ROUTE,
  ADMIN_BASE_ROUTE,
  COACHING_ROUTE,
  COACHINGS_BASE_ROUTE,
  FEEDBACK_BASE_ROUTE,
  FEEDBACK_ROUTE,
  GOALS_BASE_ROUTE,
  MEETING_ROUTE,
  MEETINGS_BASE_ROUTE,
  ORG,
  PEOPLE,
  PRINT_BASE_ROUTE,
  REVIEWS,
  SETTINGS,
  SURVEY_ROUTE,
  SURVEYS_BASE_ROUTE,
  TASKS_BASE_ROUTE,
  LEARNING_BASE_ROUTE,
  LEARNING_ROUTE,
  INSIGHTS_BASE_ROUTE,
  INSIGHTS_MARKETING_ROUTE,
  ADMIN_REVIEWS_V2,
  REVIEWS_BY_CYCLE,
  ADMIN_REVIEWS_REFLECTIONS_V2,
  REVIEWS_CREATE_CYCLE,
  REVIEWS_CONTINUE_CREATING_CYCLE,
  HOME_BASE_ROUTE,
  RECOGNITION,
  RECOGNITION_BASE_ROUTE,
  RECOGNITION_BY_ID,
  GLOBAL_SEARCH,
  TEAMS,
  MY_TEAMS,
  ALL_TEAMS,
  TEAM_DETAILS_USERS,
  PEOPLE_ADMIN,
  PEOPLE_ADMIN_IMPORT,
  DEVELOPMENT_PLAN,
  // TEAM_DETAILS_GOALS, TODO: Coming later
} from '~Common/const/routes';
import { useRecognitionPrivileges } from '~Recognition/hooks/useRecognitionPrivleges';

import Agenda from '~Deprecated/ui/routes/Agenda';
import NewAdminRouter from '~Admin/routes/AdminRouter';
import LayoutBridge from '~Deprecated/ui/routes/layouts/LayoutBridge';
import { WorkbenchRouter } from '~Workbench/index';
import NewSurveys from '~Surveys/routes/Surveys';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { getUserId } from '~Common/utils/localStorage';
import { getFeatureFlags } from '~Deprecated/selectors/featureFlags/getFeatureFlags';
import Learning from '~Learning/routes';
import InsightsRouter from '~Insights/routes';
import { ActionItemsRouter } from '~ActionItems';
import HomeRoutes from '~Home/routes';
import { useGetLandingPageUrl } from '~Common/hooks/useGetHomePageUrl';
import ReviewsRouter from '~Reviews/V2/ReviewsRouter';
import Settings from '~Settings/routes';
import { getIsFullyInitialized } from '~Deprecated/selectors/featureFlags/getIsFullyInitialized';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import InsightsMarketingLayout from '~Insights/components/InsightsMarketingLayout';
import NewGetContent from '~Deprecated/ui/routes/layouts/NewGetContent';
import Feedback from '~Feedback/routes';
import GlobalSearch from '~GlobalSearch';

import RecognitionRouter from '~Recognition/routes';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useRootHooks } from '~Root/hooks/useRootHooks';
import GoalsRouter from '~Goals/routes/GoalsRouter';
import DevelopmentPlanRouter from '~DevelopmentPlan/routes/DevelopmentPlanRouter';
import { CircularProgress } from '@mui/material';
import { palette } from '~Common/styles/colors';
import Print from './Print';
import People from '../../../people/routes';
import SmartRedirect from '../components/SmartRedirect';
import OrgView from '../../../people/components/OrgChart';
import GetContent from './layouts/GetContent';

const styles = {
  loader: css({
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  }),
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const Org = () => {
  useRootHooks();
  const { user } = useUserProfile(getUserId());
  const { isAdmin } = useUserPermissions();
  const enableReviews = user?.orgDetails?.enableReviews ?? false;
  const landingPageUrl = useGetLandingPageUrl();

  const isLaunchDarklyFullyInitialized = useSelector(getIsFullyInitialized);
  const flags = useSelector(getFeatureFlags);

  const {
    frontendInsights,
    webClientSurveys,
    webClientUseLeadrHome,
    useGlobalSearch,
    webAppTeamsV2,
  } = flags;
  const { organizationInTrial } = useIsOrganizationInTrial();

  const hasInsights = !organizationInTrial && frontendInsights && user?.orgDetails?.enableInsights;
  const hasRecognition = useRecognitionPrivileges();
  return (
    <>
      {(!isLaunchDarklyFullyInitialized) && (
        <div
          css={styles.loader}
        >
          <CircularProgress css={styles.circularProgress} />
        </div>
      )}

      {isLaunchDarklyFullyInitialized && (
        <Switch>
          <Route
            path="/workbench"
            component={WorkbenchRouter}
          />

          {useGlobalSearch && (
            <Route
              path={GLOBAL_SEARCH}
              component={GlobalSearch}
            />
          )}

          <Route
            path={[
              ACTION_ITEMS_BASE_ROUTE,
              TASKS_BASE_ROUTE,
            ]}
            component={ActionItemsRouter}
          />

          {webClientUseLeadrHome && (
            <Route
              path={[
                HOME_BASE_ROUTE,
              ]}
              component={HomeRoutes}
            />
          )}

          <Route
            path={[
              COACHINGS_BASE_ROUTE,
              COACHING_ROUTE,
              MEETINGS_BASE_ROUTE,
              MEETING_ROUTE,
            ]}
            component={Agenda}
          />

          <Route
            path={[
              FEEDBACK_BASE_ROUTE,
              FEEDBACK_ROUTE,
            ]}
            component={Feedback}
          />
          {hasRecognition && (
            <Route
              path={[RECOGNITION_BASE_ROUTE, RECOGNITION, RECOGNITION_BY_ID]}
              component={RecognitionRouter}
            />
          )}

          {webClientSurveys && (
            <Route
              path={[SURVEYS_BASE_ROUTE, SURVEY_ROUTE]}
              component={NewSurveys}
            />
          )}

          <Route
            path={[
              LEARNING_BASE_ROUTE,
              LEARNING_ROUTE,
            ]}
            component={Learning}
          />

          <Route
            path={
              GOALS_BASE_ROUTE
            }
            component={GoalsRouter}
          />

          <Route
            exact
            path={ORG}
            render={(routeProps) => (
              <LayoutBridge
                routeProps={routeProps}
                Component={(
                  <GetContent
                    ChildComponent={OrgView}
                    className="w-100 org-chart"
                  />
                )}
              />
            )}
          />
          <Route
            path={SETTINGS}
            component={Settings}
          />

          {organizationInTrial && (
            <Route
              path={INSIGHTS_MARKETING_ROUTE}
              render={(routeProps) => (
                <LayoutBridge
                  routeProps={routeProps}
                  Component={(
                    <NewGetContent
                      ChildComponent={InsightsMarketingLayout}
                    />
            )}
                />
              )}
            />
          )}
          {organizationInTrial && (
            <Redirect from={INSIGHTS_BASE_ROUTE} to={INSIGHTS_MARKETING_ROUTE} />
          )}
          {hasInsights && (
            <Route
              path={INSIGHTS_BASE_ROUTE}
              component={InsightsRouter}
            />
          )}
          <Route
            exact
            path={[
              PEOPLE,
              TEAMS,
              MY_TEAMS,
              ALL_TEAMS,
              TEAM_DETAILS_USERS,
              PEOPLE_ADMIN,
              PEOPLE_ADMIN_IMPORT,
              // TEAM_DETAILS_GOALS, TODO: Coming later
            ]}
            component={People}
          />

          {enableReviews && (
            <Route
              path={[REVIEWS, ADMIN_REVIEWS_V2, REVIEWS_BY_CYCLE, ADMIN_REVIEWS_REFLECTIONS_V2, REVIEWS_CREATE_CYCLE, REVIEWS_CONTINUE_CREATING_CYCLE]}
              component={ReviewsRouter}
            />
          )}

          <Route
            path={PRINT_BASE_ROUTE}
            component={Print}
          />
          {isAdmin && !webAppTeamsV2 && (
            <Route
              path={
                ADMIN_BASE_ROUTE
              }
              render={() => (
                <NewAdminRouter />
              )}
            />
          )}
          <Route
            path={DEVELOPMENT_PLAN}
            component={DevelopmentPlanRouter}
          />
          <SmartRedirect from="*" to={landingPageUrl} />
        </Switch>
      )}
    </>
  );
};

Org.propTypes = {
};

Org.defaultProps = {
};

export default memo(Org, (prev, next) => isEqual(prev, next));
