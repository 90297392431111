import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { queryClient } from '~Common/const/queryClient';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getOrganizationUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { MEETING_FACTORY_TYPES } from '~Meetings/const/meetingTypes';
import { usePeopleQuery } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { getMeetingById } from '~Deprecated/redux/actions/meetingActions';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { homeQueryKeys } from '~Home/hooks/queryKeys';

const rescheduleMeeting = ({ id, startTimeInMillis, endTimeInMillis }) => {
  const serverUrl = `/organizations/${getOrganizationId()}/huddles`;
  return patchApi(serverUrl, {
    huddleId: id,
    startTimeInMillis,
    endTimeInMillis,
  });
};

export const useRescheduleMeeting = ({ template }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const { data: peopleData } = usePeopleQuery({});

  const mutation = useMutation({
    mutationFn: rescheduleMeeting,
    onMutate: () => {
      toastId.current = toast.info('Rescheduling this meeting... Hope you are having a good day.', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: <MeetingsErrorToast />,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: (data, variables) => {
      toast.update(toastId.current, {
        render: 'Your meeting has been rescheduled. Have a great day.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      const {
        id, lastState,
      } = variables;

      const {
        factoryType, organizer, attendeeOrgUserIds,
      } = lastState;

      const currentUser = getOrganizationUserId();

      dispatch(
        popDrawerAction({ drawerName: template.name }),
      );

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', id] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
      queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId()) });

      if (factoryType === MEETING_FACTORY_TYPES.COACHING) {
        dispatch({
          type: 'GET_FULL_TIMELINE',
          payload: {
            page: 1,
            count: 100,
            filters: 'COACHING,FEEDBACK,CONTENT',
            relatedType: 'USER',
            relatedId: currentUser === organizer ? peopleData[attendeeOrgUserIds[0]].userId : peopleData[organizer?.orgUserId].userId,
            coachingId: id,
            pageType: MEETING_FACTORY_TYPES.COACHING,
          },
        });

        dispatch(getMeetingById({ contextType: 'COACHING', data: id }));
      } else {
        dispatch({
          type: 'GET_FULL_TIMELINE',
          payload: {
            page: 1,
            count: 100,
            filters: MEETING_FACTORY_TYPES.TEAM_MEETING,
            relatedType: MEETING_FACTORY_TYPES.TEAM_MEETING,
            relatedId: id,
            meetingId: id,
            pageType: MEETING_FACTORY_TYPES.TEAM_MEETING,
          },
        });

        dispatch(getMeetingById({ contextType: 'TEAM_MEETING', data: id }));
      }
    },
  });

  return mutation.mutate;
};
