import {
  Switch, Route,
} from 'react-router-dom';

import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import SurveysTopbar from '~Surveys/Components/Topbars';
import { SURVEYS_BASE_ROUTE } from '~Common/const/routes';
import SurveyComponent from '~Surveys/index';
import { useGetSurveyTypes } from '~Surveys/Hooks/useSurveyTypes';
import { useSurveyViewTyperFilter } from '~Surveys/Hooks/useSurveyViewTypeFilter';
import { useSurveyRequestTypeFilter } from '~Surveys/Hooks/useSurveyRequestType';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import ModuleLandingPageWrapper from '~Common/V3/components/LandingPageWrapper';
import BaseLayout from '~Deprecated/ui/routes/layouts/BaseLayout';

interface ViewProps {
  isLoading: boolean,
}

const View = ({
  isLoading,
}: ViewProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={SURVEYS_BASE_ROUTE}
      render={() => (
        <BaseLayout>
          <ModuleLandingPageWrapper
            TopComponent={<SurveysTopbar />}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
            ChildComponent={<SurveyComponent isLoading={isLoading} />}
          />
        </BaseLayout>
      )}
    />
    <SmartRedirect from="*" to={SURVEYS_BASE_ROUTE} />
  </Switch>
);

const Surveys = (): JSX.Element => {
  const { isLoading } = useGetSurveyTypes({});
  const [showSkeletonLoaders] = useSkeletonLoaders(isLoading);

  const [surveyViewTypeFilter] = useSurveyViewTyperFilter();
  const [surveyRequestTypeFilter] = useSurveyRequestTypeFilter();
  const webClientSurveysTemplates = useFeatureFlag('webClientSurveysTemplates');

  const hookProps = {
    isLoading: showSkeletonLoaders,
    surveyViewTypeFilter,
    surveyRequestTypeFilter,
    webClientSurveysTemplates,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default Surveys;
