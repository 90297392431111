import _ from 'lodash';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { UserPerspective } from './useInsightsUserPerspective';

export interface PulseSurveyQuestion {
  id: number,
  text: string,
  category: keyof EngagementScores, // Probably needs .toLowerCase() to be used as such.
}
export interface EngagementScores {
  overall: number,
  clarity: number,
  maximization: number,
  rapport: number,
}

export interface EngagementDataForDate {
  surveyDate: string
  surveyId: number,
  surveysRequested: number,
  surveysAnswered: number,
  averages: EngagementScores,

  questions: PulseSurveyQuestion[],
}

export interface ExecutiveEngagementDataForDate extends EngagementDataForDate {
  departments: Array<{
    name: string,
    surveysRequested: number,
    surveysAnswered: number,
    averages: EngagementScores,
  }>,
  managers: Array<{
    uid: string,
    name: string,
    surveysRequested: number,
    surveysAnswered: number,
    averages: EngagementScores,
  }>
  userGroups: Array<{
    uid: string,
    name: string,
    surveysRequested: number,
    surveysAnswered: number,
    averages: EngagementScores,
  }>,
}

interface UseEngagementDataForDateRange {
  startDate: Date,
  endDate: Date,
  userPerspective: UserPerspective,
}

interface GetExecutiveEngagementDataQueryParams {
  organizationId: string,
  userPerspective: UserPerspective,
  from?: string,
  to?: string,
}

function getEngagementDataQuery<T extends EngagementDataForDate>({
  organizationId,
  userPerspective,
  ...options
}: GetExecutiveEngagementDataQueryParams): Promise<HttpCallReturn<T[]>> {
  const serverUrl = `/organizations/${organizationId}/reports/insights/engagement/${userPerspective}`;
  const queryParams = new URLSearchParams({
    ...options,
  });
  return getApi<T[]>(`${serverUrl}?${queryParams.toString()}`);
}

interface EngagementDataReturn<T> {
  data: T,
  isLoading: boolean,
}

export function useEngagementDataForDateRange<T extends EngagementDataForDate>({
  startDate,
  endDate,
  userPerspective,
}: UseEngagementDataForDateRange): EngagementDataReturn<T[]> {
  const organizationId = getOrganizationId() ?? '';
  const queryKey = [organizationId, 'insights', 'engagement', userPerspective, startDate.toLocaleString(), endDate.toLocaleString()];

  const result = useQuery({
    queryKey,
    queryFn: () => getEngagementDataQuery({
      organizationId,
      userPerspective,
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
    }),
    staleTime: 12 * 60 * 60 * 1000,
  });
  const data = _(result?.data?.response)
    .orderBy(['surveyDate', 'surveyId'], ['asc', 'asc'])
    .value() as T[];
  return {
    data,
    isLoading: result.isLoading,
  };
}
