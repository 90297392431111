import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';

interface ViewProps extends Omit<LeadrButtonProps<'button'>, 'data-test-id'> {
  isEdit: boolean,
  isMobile: boolean,
  isPrivate: boolean,
}

const SaveButton = ({
  isEdit,
  isMobile,
  isPrivate,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrButton
    data-test-id={isEdit ? 'meetingTopicSuggestionsSaveEditTopicTemplate' : 'meetingTopicSuggestionsSaveNewTopicTemplate'}
    type="submit"
    size={isMobile ? 'small' : 'standard'}
    {...props}
  >
    Save
    {isPrivate ? ' Private ' : ' Public '}
    Template
  </LeadrButton>
);

export default SaveButton;
