import { SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';
import { DropdownItem } from '~Common/V3/components/Dropdown';
import { useGetPeopleFilters } from '~Common/hooks/useGetPeopleFilters';
import FilterDropdownView from './DropdownView';

interface ViewProps {
  groupOptions: DropdownItem[],
  value: string,
  handleFilterChange: (event: SelectChangeEvent) => void,
}

const View = ({
  groupOptions,
  value,
  handleFilterChange,
  ...props
}: ViewProps): JSX.Element => (
  <FilterDropdownView
    options={groupOptions}
    value={value}
    handleFilterChange={handleFilterChange}
    labelForNoValue="Teams"
    data-test-id="peopleUserGroupsDropdown"
    {...props}
  />
);

type UserGroupsDropdownProps = Omit<ViewProps, 'groupOptions' | 'isInvalidDropdownValue'>

const UserGroupsDropdown = ({
  value,
  ...props
}: UserGroupsDropdownProps): JSX.Element => {
  const { groups } = useGetPeopleFilters();

  const groupOptions = useMemo(() => groups.map((group) => ({
    text: group.name,
    value: group.teamId,
    'data-test-id': `${group.name}Option`,
  })), [groups]);

  const hookProps = {
    groupOptions,
    value,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default UserGroupsDropdown;
