import {
  DirectReportReflection, ReflectionType, ReflectionsFavoriteType, ReflectionsIsActiveType,
} from '../types';
import { useGetDirectReportReflectionsList } from './useGetDirectReportReflectionsList';
import { useGetReflectionsList } from './useGetReflectionsList';

interface useGetProperReflectionsListProps {
  search: string,
  index: number,
  limit: number,
  reflectionType: ReflectionType[],
  favoriteStatus: ReflectionsFavoriteType[],
  isInActiveReview: ReflectionsIsActiveType[],
  ownerId: string,
  startDate: string,
  endDate: string,
  reviewPeriod: string,
  isManager: boolean,
}

interface useGetProperReflectionsListReturn {
  reflections?: DirectReportReflection[],
  isLoading: boolean,
}

export const useGetProperReflectionsList = ({
  search, index, limit, reflectionType, favoriteStatus, isInActiveReview,
  ownerId, startDate, endDate, reviewPeriod, isManager,
}: useGetProperReflectionsListProps): useGetProperReflectionsListReturn => {
  const {
    directReportReflections,
    isLoading: managerIsLoading,
  } = useGetDirectReportReflectionsList({
    search,
    index,
    limit,
    reflectionType,
    favoriteStatus,
    isInActiveReview,
    enabled: isManager,
    ownerId,
    startDate,
    endDate,
    reviewPeriod,
  });

  const {
    reflections: revieweeReflections,
    isLoading: revieweeIsLoading,
  } = useGetReflectionsList({
    search,
    index,
    limit,
    reflectionType,
    favoriteStatus,
    isInActiveReview,
    enabled: !isManager,
    startDate,
    endDate,
  });
  const reflections = isManager ? directReportReflections : revieweeReflections;
  const isLoading = isManager ? managerIsLoading : revieweeIsLoading;

  return {
    reflections,
    isLoading,
  };
};
