import { css } from '@emotion/react';
import { SURVEY_BY_PARTICIPANT_ANSWERS_SHAPE, SURVEY_QUESTION_TYPES_SHAPE } from '~Common/const/proptypes';

import { printColors } from '~Common/styles/colors';
import ByPersonQuestion from './ByPersonQuestion';

const styles = {
  container: css``,
  section: css`
    margin-top: 3.4375rem;
    &:not(:last-child) {
      padding-bottom: 3.75rem;
      border-bottom: 1px solid ${printColors.responseBorders};
    }
  `,
  personInfo: css`
    margin-bottom: 1.5rem;
    font-size: .875rem;

    & h3{
      font-size: 1.25rem;
    }
  `,
  jobTitle: css`
    color: ${printColors.jobtitle};
  `,
};

const View = ({ byParticipants, questionTypes, ...props }) => (
  <div
    css={styles.container}
    {...props}
  >
    {byParticipants.map((participantQuestionAndAnswer) => (
      <div css={styles.section} key={participantQuestionAndAnswer.participant.id}>
        <div css={styles.personInfo}>
          <h3>{participantQuestionAndAnswer.participant.participant.fullName}</h3>
          <div css={styles.jobTitle}>{participantQuestionAndAnswer.participant.participant.jobTitle}</div>
        </div>
        <ByPersonQuestion questionTypes={questionTypes} participantQuestionAndAnswer={participantQuestionAndAnswer} />
      </div>
    ))}
  </div>
);

View.propTypes = {
  questionTypes: SURVEY_QUESTION_TYPES_SHAPE.isRequired,
  byParticipants: SURVEY_BY_PARTICIPANT_ANSWERS_SHAPE.isRequired,
};

View.defaultProps = {};

const ByPersonResults = ({ ...props }) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ByPersonResults.propTypes = {};

ByPersonResults.defaultProps = {};

export { View };
export default ByPersonResults;
