import { createAction } from '@reduxjs/toolkit';
import { debounce, call } from 'redux-saga/effects';

import { sagaMiddleware } from '~Deprecated/store';
import { registerAction } from '~Deprecated/reducers/organizationInsights';
import { getOrganizations } from '~Deprecated/services/organizationInsights';
import { callApi } from '~Deprecated/sagas/callApi';
import { mutateState, flattenData } from '~Deprecated/utils/reduxUtils';

const loadOrganizationInsightsAction = createAction('LEADR.LOAD_ORGANIZATION_INSIGHTS');
const loadOrganizationInsightsSuccessAction = createAction('LEADR.LOAD_ORGANIZATION_INSIGHTS_SUCCESS');
const loadOrganizationInsightsFailureAction = createAction('LEADR.LOAD_ORGANIZATION_INSIGHTS_FAILURE');
const count = 50;

function* onloadOrganizationInsights(action) {
  yield call(callApi, {
    api: getOrganizations,
    payload: { ...action.payload, count },
    successAction: loadOrganizationInsightsSuccessAction,
    failureAction: loadOrganizationInsightsFailureAction,
  });
}

function* loadOrganizationInsightsWatcher() {
  yield debounce(750, loadOrganizationInsightsAction, onloadOrganizationInsights);
}

function loadOrganizationInsightsFailure(state, action) {
  if (action.payload.page !== 1) {
    return mutateState(state, (draftState) => {
      draftState.error = action.payload;
      return draftState;
    });
  }
  return mutateState(state, (draftState) => {
    draftState.ids = [];
    draftState.data = {};
    draftState.error = action.payload;

    return draftState;
  });
}

function loadOrganizationInsightsSuccess(state, action) {
  const { ids, data } = flattenData({
    responseData: action.payload.response.list,
    onItemFlatten: ({ address, ...orgData }) => {
      if (address) {
        const { addressState, ...addressData } = address[0];
        return { ...addressState, ...addressData, ...orgData };
      }
      return { address, ...orgData };
    },
  });

  if (action.payload.page && action.payload.page !== 1) {
    return mutateState(state, (draftState) => {
      draftState.ids = [...draftState.ids, ...ids];
      draftState.data = { ...draftState.data, ...data };
      draftState.isFilteredByFavourites = action.payload.filterFavourites;
      draftState.totalCount = action.payload.response.stats.totalSearched;
      return draftState;
    });
  }

  return mutateState(state, (draftState) => {
    draftState.ids = ids;
    draftState.data = data;
    draftState.isFilteredByFavourites = action.payload.filterFavourites;
    draftState.totalCount = action.payload.response.stats.totalSearched;

    return draftState;
  });
}

registerAction(loadOrganizationInsightsSuccessAction.toString(), loadOrganizationInsightsSuccess);
registerAction(loadOrganizationInsightsFailureAction.toString(), loadOrganizationInsightsFailure);

sagaMiddleware.run(loadOrganizationInsightsWatcher);

export {
  loadOrganizationInsightsAction,
};
