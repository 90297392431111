import { useEffect, useMemo, useCallback } from 'react';
import { QueryFunctionContext, useQuery, UseQueryResult } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import {
  getOrganizationId, getUserId, setTimezone,
} from '~Common/utils/localStorage';
import { Profile } from '~Common/const/interfaces';
import { useImpersonationStore } from '../useImpersonationStore';
import { useTimezonesList } from '../useTimezonesList';
import {
  loadPersonalities,
} from './api';
import {
  usePatchUserSettings,
  useResetPasswordMutator,
  useUpdateUserProfileMutator,
} from './mutators';
import { signout } from '~Deprecated/redux/actions/common';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';

interface UseUserProfileReturn {
  detail: UseQueryResult<HttpCallReturn<Profile>, unknown>,
  isLoading: boolean,
  isSuccess: boolean,
  user: Profile | undefined,
}

const getUserProfile = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<Profile>> => {
  const [orgId, _, userId] = queryKey;

  const URL = `/organizations/${orgId}/users/${userId}`;

  const headers = {
    'endpoint-version': '2',
  };

  return getApi(URL, headers);
};

export const useUserProfile = (userId: string): UseUserProfileReturn => {
  const dispatch = useDispatch();
  const organizationId = getOrganizationId() ?? '';
  const detail = useQuery({
    queryKey: [organizationId, 'profile', userId],
    queryFn: getUserProfile,
    enabled: !!userId && !!organizationId,
  });

  useEffect(() => {
    /*
      Trying to avoid situations where users get trapped in the gray screen of death loop.
      Because this is only used to get the current user's information, an error should clearly mean
      that they are no longer authorized / can't access the app.
    */
    if (detail?.isError) {
      dispatch(signout());
    }
  }, [detail?.isError]);

  const user = detail?.data?.response;

  return {
    detail,
    isLoading: detail?.isLoading,
    user,
    isSuccess: detail?.isSuccess,
  };
};

interface UseResetPasswordReturn {
  resetPassword: (email: string) => void,
  isLoading: boolean,
}

export const useResetPassword = (): UseResetPasswordReturn => {
  const mutation = useResetPasswordMutator();

  return {
    resetPassword: (email: string) => {
      mutation.mutate({ email });
    },
    isLoading: mutation.isPending,
  };
};

/*
  WARNING: there is no eslint after the following line
  this will surpress the @typescript-eslint errors for now, and much more
  feel free to move stuff above this line as it gets typed with the goal of typing everything in this file eventually
*/
/* eslint-disable */
// @ts-expect-error TODO: Remove this when this function is typed
export const useUpdateUserSettings = ({ queryKey = [getOrganizationId(), 'profile', getUserId()], silent, ...args }) => {
  // In most cases, this will be the current user. Not sure if we use a seperate workflow for Admin Edits or not.
  const mutation = usePatchUserSettings({
    queryKey,
    silent,
    ...args,
  });

  // @ts-expect-error TODO: Remove this when this function is typed
  const updateUserSettings = useCallback((data) => {
    if (data.timezone === '') {
      delete data.timezone;
    }
    if (data.orgUserSettingsId == null) {
      delete data.orgUserSettingsId;
    }
    mutation.mutate({
      userId: getUserId(),
      orgId: getOrganizationId(),
      data,
    });
  }, []);
  

  return {
    updateUserSettings,
    isUpdating: mutation.isPending,
  };
};

// @ts-expect-error TODO: Remove this when this function is typed
export const useUpdateUserProfile = (userId, template) => {
  const mutation = useUpdateUserProfileMutator();

  return {
    // @ts-expect-error TODO: Remove this when this function is typed
    updateUserProfile: (data) => {
      mutation.mutate({
        userId,
        orgId: getOrganizationId(),
        data,
        // @ts-expect-error TODO: Remove this when this function is typed
        template,
      });
    },
    isUpdating: mutation.isPending,
  };
};

export const useLoadPersonalitites = () => {
  const detail = useQuery({
    queryKey: [getOrganizationId(), 'referenceData', 'personalities'],
    queryFn: loadPersonalities
  });

  return {
    detail,
    isLoading: detail?.isLoading,
    data: detail?.data?.response,
  };
};

export const useTimezone = () => {
  const userId = getUserId();
  // @ts-expect-error TODO: Remove this when this function is typed
  const { user, isLoading: isUserProfileLoading } = useUserProfile(userId);
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { usTimezones, otherTimezones, isLoading: isTimezonesLoading } = useTimezonesList();

  const isLoading = isUserProfileLoading || isTimezonesLoading;

  return useMemo(() => {
    let timezone = '';
    let timezoneFriendlyName = '';

    if (user?.orgUserSettings && !isTimezonesLoading) {
      const { timezone: settingsTimezone, useSettingsTimezone } = user.orgUserSettings;

      timezone = useSettingsTimezone ? settingsTimezone : browserTimezone;
      timezoneFriendlyName = usTimezones?.[timezone] || otherTimezones?.[timezone];
      setTimezone(timezone); // We'll use this localstorage value for API request headers
    }

    return {
      timezone,
      timezoneFriendlyName,
      isLoading,
    };
  }, [user, browserTimezone, usTimezones, otherTimezones, isTimezonesLoading]);
};

export const useSyncTimezone = () => {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userId = getUserId();
  // @ts-expect-error TODO: Remove this when this function is typed
  const { user } = useUserProfile(userId);
  const { isImpersonating } = useImpersonationStore();
  const { updateUserSettings: doUserSettingsUpdate } = useUpdateUserSettings({ silent: true });

  useEffect(() => {
    if (user?.orgUserSettings && !isImpersonating) {
      const { useSettingsTimezone } = user.orgUserSettings;
      const userTimezone = user.orgUserSettings.timezone;

      // Default timezone is needed to make the Material Date Picker behave properly.
      moment.tz.setDefault(useSettingsTimezone ? userTimezone : browserTimezone);

      if (!useSettingsTimezone) {
        if (browserTimezone !== userTimezone) {
          doUserSettingsUpdate({ timezone: browserTimezone });
        }
      }
    }
  }, [user, isImpersonating]);
};

/* eslint-enable */
