import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import LeadrModal from '~Common/V3/components/LeadrModal';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useConfirmAttachmentDeletionModal } from '~Meetings/hooks/utils/useConfirmAttachmentDeletionModal';
import { useCallback } from 'react';
import { useRemoveAttachments } from '~Meetings/hooks/attachments/useRemoveAttachments';
import { queryClient } from '~Common/const/queryClient';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { fileExtensionToIcon } from '~Meetings/utils/fileExtensionToIcon';
import { AcceptedExtension } from '~Meetings/const/attachments';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = {
  attachmentBar: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '5px',
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 500,
    padding: '0.5rem 0.75rem',
  }),
  modalTitle: css({
    color: palette.brand.red,
  }),
  modalContent: css({
    color: palette.neutrals.gray800,
    fontSize: '1rem',
  }),
  modalContentSpace: css({
    marginTop: '1rem',
  }),
  warningText: css({
    color: palette.brand.red,
    fontWeight: '600',
  }),
  spacer: css({
    margin: '2rem 0',
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  cancelButton: css({
    marginLeft: 'auto',
  }),
  fileIcon: css({
    fontSize: '1.125rem',
    marginRight: '0.25rem',
  }),
};

interface DisplayReopenModalConfirmProps {
  closeModal: () => void,
  confirmDeletion: () => void,
  isOpen: boolean,
  fileIcon: IconDefinition,
  fileName?: string,
}

const View = ({
  closeModal,
  confirmDeletion,
  isOpen,
  fileIcon,
  fileName,
}: DisplayReopenModalConfirmProps): JSX.Element => (
  <LeadrModal
    open={isOpen}
    onClose={closeModal}
  >
    <LeadrModal.Header>
      <LeadrModal.Title css={styles.modalTitle}>
        Delete Attachment
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body>
      <p css={styles.modalContent}>
        Are you sure you would like to delete this file permanently?
      </p>
      <div
        css={[styles.attachmentBar, styles.modalContentSpace]}
      >
        <FontAwesomeIcon
          css={styles.fileIcon}
          icon={fileIcon}
        />

        <div>
          {fileName}
        </div>
      </div>
    </LeadrModal.Body>
    <LeadrModal.Footer css={styles.footer}>
      <LeadrButton
        onClick={confirmDeletion}
        data-test-id="confirmAttachmentDeletion"
        size="small"
        color="danger"
      >
        Delete Attachment
      </LeadrButton>
      <LeadrButton
        css={styles.cancelButton}
        onClick={closeModal}
        data-test-id="cancelAttachmentDeletion"
        variant="ghost"
        size="small"
        color="danger"
      >
        Cancel
      </LeadrButton>
    </LeadrModal.Footer>
  </LeadrModal>
);

export const ConfirmDeleteAttachmentModal = (): JSX.Element => {
  const {
    closeModal,
    useGetModalProps,
    useIsModalOpen,
  } = useConfirmAttachmentDeletionModal();

  const isOpen = useIsModalOpen();
  const {
    attachment: {
      fileExtension,
      fileName,
      id: attachmentId,
    } = {},
    huddleId,
    topicId,
    onConfirm,
  } = useGetModalProps();

  const { mutate: doRemoveAttachments } = useRemoveAttachments();

  const confirmDeletion = useCallback(() => {
    if (attachmentId) {
      onConfirm?.(attachmentId);
      doRemoveAttachments({
        huddleId,
        topicId,
        removeAttachmentIds: [attachmentId],
      }, {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
        },
      });
    }
    closeModal();
  }, [attachmentId, closeModal, doRemoveAttachments, huddleId, onConfirm, topicId]);

  const fileIcon = fileExtensionToIcon(fileExtension as AcceptedExtension);

  const hookProps = {
    attachmentId,
    closeModal,
    confirmDeletion,
    fileIcon,
    fileName,
    isOpen,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
