import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment-timezone';
import useDidUpdate from '@rooks/use-did-update';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { BI_WEEKLY_MEETING, WEEKLY_MEETING } from '~Common/const/constraints';

export const ordinalSuffix = (i) => {
  const j = i % 10;
  const
    k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

const weekDayNumberCalculator = (mDate, weekOfMonth) => {
  if (mDate.month() !== mDate.clone().subtract(1, 'week').month()) {
    return weekOfMonth;
  }
  return weekDayNumberCalculator(mDate.clone().subtract(1, 'week'), weekOfMonth + 1);
};

export const getDateInfo = (mDate) => {
  const weekOfMonth = weekDayNumberCalculator(mDate.clone(), 1);
  const isLastWeekDay = mDate.month() !== mDate.clone().add(1, 'week').month();
  const weekDay = mDate.format('dddd');

  return {
    weekOfMonth,
    isLastWeekDay,
    weekDay,
  };
};

const useMeetingDateTimeFrequencyWeekdayTimezone = ({
  defaultFrequency, showFrequency, isOneOnOne, initialSelectedDays = [], isAlreadyRecurring = false,
}) => {
  const unvalidatedBrowserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedDate, setSelectedDate] = useState();
  const { timezone, timezoneFriendlyName } = useTimezone();
  const [defaultWeekday, setDefaultWeekDay] = useState();
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState(defaultFrequency);
  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);

  const showWeekdaySelection = useMemo(() => (selectedFrequency === WEEKLY_MEETING || selectedFrequency === BI_WEEKLY_MEETING), [selectedFrequency]);

  const onDateChange = useCallback((value) => {
    const mDate = moment.tz(value, timezone || unvalidatedBrowserTimezone);
    setSelectedDate(mDate);
    setDefaultWeekDay(mDate.day());
  }, [timezone, unvalidatedBrowserTimezone]);

  const onFrequencyChange = useCallback((event) => {
    setSelectedFrequency(event.target.value);
  }, []);

  useEffect(() => {
    if (selectedDate && (selectedFrequency === 'MONTHLY' || selectedFrequency === 'MONTHLY_LAST')) {
      setSelectedDays([(selectedDate.day() + 6) % 7]);
    }
  }, [selectedFrequency, selectedDate]);

  useEffect(() => {
    if (showFrequency && selectedDate) {
      const { weekOfMonth, isLastWeekDay, weekDay } = getDateInfo(selectedDate);
      const repeatingDays = selectedDays.map((selectedDay) => {
        // moment weekday numbers start with sunday and backend starts with monday. convert backend to moment number.
        const shiftedWeekdayNumber = (selectedDay + 1) % 7;
        return moment.weekdays(shiftedWeekdayNumber);
      }).join(', ');

      const newFrequencyOptions = [];

      if (!isOneOnOne && !isAlreadyRecurring) {
        newFrequencyOptions.push({ value: 'ONE_TIME', text: 'One Time' });
      }

      newFrequencyOptions.push({
        value: 'WEEKLY', text: `Every ${repeatingDays || weekDay}`,
      });

      newFrequencyOptions.push({
        value: 'BI_WEEKLY', text: `Every other ${repeatingDays || weekDay}`,
      });

      if (weekOfMonth <= 4) {
        newFrequencyOptions.push({
          value: 'MONTHLY', text: `Monthly on the ${ordinalSuffix(weekOfMonth)} ${weekDay}`,
        });
      }

      if (isLastWeekDay) {
        newFrequencyOptions.push({
          value: 'MONTHLY_LAST', text: `Monthly on the last ${weekDay}`,
        });
      }

      setFrequencyOptions(newFrequencyOptions);
    }
  }, [isOneOnOne, selectedDate, selectedDays, showFrequency, isAlreadyRecurring]);

  useDidUpdate(() => {
    if (frequencyOptions?.length) {
      setSelectedFrequency((oldFrequency) => {
        const isSelected = frequencyOptions.map((f) => f.value).find((f) => f === oldFrequency);
        if (!isSelected) {
          // Edge case: When this changes from 'MONTHLY' to 'MONTHLY_LAST' or vice versa
          return frequencyOptions[frequencyOptions.length - 1]?.value;
        }
        return oldFrequency;
      });
    }
  }, [frequencyOptions]);

  return {
    timezone,
    timezoneFriendlyName,
    onFrequencyChange,
    onDateChange,
    showWeekdaySelection,
    selectedDate,
    defaultWeekday,
    frequencyOptions,
    selectedDays,
    setSelectedDays,
    selectedFrequency,
    showFrequency,
  };
};

export default useMeetingDateTimeFrequencyWeekdayTimezone;
