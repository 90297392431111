import { css } from '@emotion/react';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import { useSupportChat } from '~Common/hooks/useSupportChat';
import { palette } from '~Common/styles/colors';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  banner: css({
    width: '100%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    backgroundColor: 'transparent',
  }),
  container: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.brand.indigo,
    padding: '0.5rem',
    color: palette.neutrals.white,
    fontSize: '0.875rem',
  }),
  timeLeft: css({
    fontWeight: 700,
  }),
  linkButton: css({
    marginLeft: '0.75rem',
    padding: '0.25rem 0.5rem',
    border: `solid 1px ${palette.neutrals.white}`,
    borderRadius: '0.25rem',
    backgroundColor: 'transparent',
  }),
  cta: css({
    marginLeft: '0.75rem',
    color: palette.neutrals.white,
    border: `solid 1px ${palette.neutrals.white}`,
    backgroundColor: 'transparent',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '0.6875rem',
    ':hover': {
      textDecoration: 'none',
      color: palette.neutrals.white,
      backgroundColor: 'transparent',
    },
  }),
};

interface ViewProps {
  message: string;
  daysRemainingInTrial: number;
  organizationInTrial: boolean;
  openChatBox: () => void;
}

const View = ({
  message,
  daysRemainingInTrial,
  organizationInTrial,
  openChatBox,
}: ViewProps): JSX.Element => (
  <div css={styles.banner}>
    {organizationInTrial && (
      <div css={styles.container}>
        { daysRemainingInTrial === 0 && (
        <strong css={styles.timeLeft}>
          Your trial ends today
        </strong>
        )}
        { daysRemainingInTrial === 1 && (
        <div>
          <strong css={styles.timeLeft}>
            {`${daysRemainingInTrial} day `}
          </strong>
          {message}
        </div>
        )}
        {daysRemainingInTrial > 1 && (
        <div>
          <strong css={styles.timeLeft}>
            {`${daysRemainingInTrial} days `}
          </strong>
          {message}

        </div>
        )}

        <div>
          <LeadrButton
            data-test-id="trial-banner-cta"
            css={styles.cta}
            variant="ghost"
            size="small"
            onClick={openChatBox}
          >
            Chat with Sales
          </LeadrButton>
        </div>
      </div>
    )}

  </div>
);

const TrialBanner = (): JSX.Element => {
  const { daysRemainingInTrial, organizationInTrial } = useIsOrganizationInTrial();
  const { openChat } = useSupportChat();

  const openChatBox = (): void => {
    openChat("Hi, I'd like more information about buying Leadr.");
  };

  const hookProps = {
    message: 'remaining in your trial',
    daysRemainingInTrial,
    organizationInTrial,
    openChatBox,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default TrialBanner;
