import InsightStatusIndicator from '~Deprecated/ui/components/Insights/InsightDetails/InsightStatusIndicator';
import ScoringTooltipContent from '~Deprecated/ui/components/Insights/ScoringTooltipContent';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { InsightsStatusSize, Person } from '~Common/const/interfaces';
import Tooltip from '~Common/components/Tooltip';
import { scoringTooltipStyle } from '~Deprecated/ui/components/Insights/scoringTooltipStyle';

export interface StatusIndicatorTooltipContent {
  insightKey: string,
  insightValue: number,
  isAggregate?: boolean,
  tooltipWrapperCss?: string,
  tooltipInnerWrapperCss?: string
}
export interface ViewProps {
  orgUserId: string,
  id: string,
  size: InsightsStatusSize,
  scoreValue: number,
  className?: string,
  user?: Person,
  tooltipContent: StatusIndicatorTooltipContent
}
const View = ({
  orgUserId,
  id,
  size,
  scoreValue,
  className,
  user,
  tooltipContent,
}: ViewProps): JSX.Element => (
  <Tooltip
    css={scoringTooltipStyle}
    interactive
    content={(
      <ScoringTooltipContent
        {...tooltipContent}
        orgUserId={orgUserId}
        /* @ts-expect-error: TODO not typing this RN -- RJG 22/Dec/22 */
        id={id}
        user={user}
      />
    )}
  >
    <span>
      <InsightStatusIndicator
        size={size}
        scoreValue={scoreValue}
        className={className}
      />
    </span>
  </Tooltip>
);

export interface InsightStatusIndicatorTooltipProps {
  id: string,
  size: InsightsStatusSize,
  scoreValue: number,
  className?: string,
  user?: Person,
  tooltipContent: StatusIndicatorTooltipContent
}

const InsightStatusIndicatorTooltip = (props: InsightStatusIndicatorTooltipProps): JSX.Element => {
  const orgUserId = getOrganizationUserId();

  const hookProps = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    orgUserId: orgUserId!,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default InsightStatusIndicatorTooltip;
