import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ButtonBase } from '@mui/material';

import {
  timelineCardBackground, palette,
} from '~Common/styles/colors';
import { noop } from '~Deprecated/utils/';
import { withH4Font, withH6Font } from '~Common/styles/typography';
import { withTruncate } from '~Common/styles/mixins';

const styles = {
  drawerItem: (rippleColor, isClickable = true) => css`
    &.MuiButtonBase-root {
      width: 100%;
      height: 80px;
      padding: 1rem;
      background: ${isClickable ? timelineCardBackground : 'transparent'};
      border-radius: 0.5rem;
      display: flex;
      justify-content: flex-start;
      cursor: ${isClickable ? 'pointer' : 'default'}
    }

    .MuiTouchRipple-child {
      background: ${rippleColor} !important;
    }
  `,
  itemInfo: css`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
    padding: 0 1rem;
    overflow: hidden;
    text-align: left;

    & > h4 {
      ${withH4Font()}
      color: ${palette.neutrals.gray700};
      ${withTruncate()}
      width: 100%;
    }

    & > h6 {
      ${withH6Font()}
      color: ${palette.neutrals.gray700};
      text-transform: none;
      ${withTruncate()}
      width: 100%;
    }
  `,
  additionalInfo: css({
    marginTop: '0.5rem',
  }),
};

const DrawerListItem = ({
  onClick,
  rippleColor,
  primaryText,
  secondaryText,
  renderAvatar,
  renderAdornment,
  renderAdditionalInfo,
  itemInfoDataTestId,
  primaryTextDataTestId,
  secondaryTextDataTestId,
  dataTestId,
  ...props
}) => (
  <ButtonBase
    css={styles.drawerItem(rippleColor, onClick !== null)}
    onClick={onClick}
    data-test-id={dataTestId}
    {...props}
  >
    {renderAvatar()}

    <div css={styles.itemInfo} data-test-id={itemInfoDataTestId}>
      <h4 data-test-id={primaryTextDataTestId}>{primaryText}</h4>
      <h6 data-test-id={secondaryTextDataTestId}>{secondaryText}</h6>

      {renderAdditionalInfo(styles.additionalInfo)}
    </div>

    {renderAdornment()}
  </ButtonBase>
);

DrawerListItem.propTypes = {
  onClick: PropTypes.func,
  rippleColor: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  renderAvatar: PropTypes.func,
  renderAdornment: PropTypes.func,
  renderAdditionalInfo: PropTypes.func,
  dataTestId: PropTypes.string,
  itemInfoDataTestId: PropTypes.string,
  primaryTextDataTestId: PropTypes.string,
  secondaryTextDataTestId: PropTypes.string,
};

DrawerListItem.defaultProps = {
  onClick: null,
  rippleColor: palette.neutrals.gray500,
  primaryText: '',
  secondaryText: '',
  renderAvatar: noop,
  renderAdornment: noop,
  renderAdditionalInfo: noop,
  dataTestId: 'drawerListItem',
  itemInfoDataTestId: 'drawerListItemInformation',
  primaryTextDataTestId: 'drawerListItemPrimaryText',
  secondaryTextDataTestId: 'drawerListItemSecondaryText',
};

export default DrawerListItem;
