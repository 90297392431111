import PropTypes from 'prop-types';

import SearchWithDropdown from '~Deprecated/ui/components/SearchBar/SearchWithDropdown';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import { filterOptions } from '~Deprecated/hooks/peoplePicker/useNewPeople';

/**
 * @deprecated Old UI
 */
const PeoplePickerDropdown = ({
  onSearch,
  setSearchFilter,
  currentFilter,
  disableFilter,
}) => (
  <div>
    <SearchWithDropdown
      onFilterChange={setSearchFilter}
      onSearchChange={onSearch}
      placeholder="Search for someone..."
      filterOptions={filterOptions}
      disableDropdown={disableFilter}
    />
    {currentFilter === '' && (
      <SectionHeader text={`Currently showing: ${filterOptions[0].text}`} />
    )}
    {currentFilter !== '' && (
      <SectionHeader text={`Currently showing: ${currentFilter.text}`} />
    )}
  </div>
);

PeoplePickerDropdown.propTypes = {
  onSearch: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.oneOfType([
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  disableFilter: PropTypes.bool.isRequired,
};

PeoplePickerDropdown.defaultProps = {
  currentFilter: '',
};

export default PeoplePickerDropdown;
