import { ComponentProps, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { useDispatch } from 'react-redux';

import UserInsightsReport, { InsightsRenderTooltipProps } from '~Deprecated/ui/components/Insights/InsightDetails/UserInsightsReport';
import InsightStatusIndicatorTooltip from '~Deprecated/ui/views/Old/People/PeopleList/InsightStatusIndicatorTooltip';
import AvatarWithTooltip from '~Common/components/Avatar/AvatarWithTooltip';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { baseballCardDrawerTemplate } from '~People/BaseballCard/Drawers/BaseballCardDrawer';
import { InsightsPeer } from '~Home/hooks/useInsightsPeers';
import { HealthReportMetricType, InsightsStatusSize, Person } from '~Common/const/interfaces';
import { withTruncate } from '~Common/styles/mixins';
import { useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';

const styles = {
  container: css({
    border: 0,
  }),
  personContainer: css({
    width: '100%',
    display: 'flex',
    alignContent: 'center',
  }),
  skeletonContainer: css({
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    margin: '0.5rem 0',
  }),
  avatar: css({
    flex: 0,
    border: 0,
    padding: 0,
    background: 'transparent',
  }),
  avatarSkeleton: css({
    height: '30px',
    maxWidth: '30px',
    width: '100%',
    borderRadius: '5px',
    marginRight: '0.5rem',
  }),
  avatarBlock: css({
    maxWidth: '100%',
    width: '100%',
    height: '1rem',
    borderRadius: '5px',
  }),
  namePlate: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0.5rem',
    justifySelf: 'center',
    flex: '1',
    minWidth: 0,
    color: palette.neutrals.gray700,
  }),
  name: css({
    fontSize: '0.875rem',
  }, withTruncate()),
  jobTitle: css({
    fontSize: '0.625rem',
  }, withTruncate()),
  insights: css({
    flex: 0,
  }),
  icon: css({
    color: palette.neutrals.gray500,
  }),
};

interface PersonContainerViewProps extends ComponentProps<'div'> {
  isUserActive: boolean;
  insightsPeer: InsightsPeer;
  person?: Person;
  onPersonClick: () => void;
  hiddenMetrics: HealthReportMetricType[],
}

const View = ({
  isUserActive,
  insightsPeer,
  person,
  onPersonClick,
  hiddenMetrics,
  ...props
}: PersonContainerViewProps): JSX.Element => (
  <>
    {isUserActive && (

      <div
        css={styles.personContainer}
        {...props}
      >
        <button css={styles.avatar} onClick={onPersonClick}>
          <AvatarWithTooltip
            profileImageUrl={person?.profileImageUrl}
            firstName={person?.firstName ?? ''}
            lastName={person?.lastName ?? ''}
          />
        </button>

        <div css={styles.namePlate}>
          <div css={styles.name}>
            {person ? `${person?.firstName} ${person?.lastName}` : 'Leadr User'}
          </div>
          <div css={styles.jobTitle}>
            {person?.jobTitle}
          </div>
        </div>

        {insightsPeer.report && (
          <div css={styles.insights}>

            <UserInsightsReport
              hasManager={!!person?.managerId}
              size={InsightsStatusSize.ExtraSmall}
              metricHealthReport={insightsPeer.report}
              hiddenMetrics={hiddenMetrics}
              renderTooltip={(tooltipProps: InsightsRenderTooltipProps) => (
                <InsightStatusIndicatorTooltip
                  {...tooltipProps}
                  size={InsightsStatusSize.ExtraSmall}
                  id={insightsPeer.orgUserId}
                  user={person}
                />
              )}
            />
          </div>
        )}
      </div>
    )}
  </>
);

const SkeletonView = (): JSX.Element => (
  <div css={styles.skeletonContainer}>
    <SkeletonLoader
      css={styles.avatarSkeleton}
      renderComponent={() => <div />}
      variant="rectangular"
    />
    <SkeletonLoader
      css={styles.avatarBlock}
      renderComponent={() => <div />}
      variant="rectangular"
    />
  </div>
);

interface PersonContainerProps extends ComponentProps<'div'> {
  insightsPeer: InsightsPeer,
  isMeetingsDisabled?: boolean
  isGoalsDisabled?: boolean,
  isFeedbackDisabled?: boolean,
}

const PersonContainer = ({
  insightsPeer,
  isMeetingsDisabled,
  isGoalsDisabled,
  isFeedbackDisabled,
  ...props
}: PersonContainerProps): JSX.Element => {
  const dispatch = useDispatch();

  const { isLoading, person } = useGetPersonById({ orgUserId: insightsPeer.orgUserId });

  const [arePeopleLoading] = useSkeletonLoaders(isLoading);
  const isUserActive: boolean = person?.administrativeStatus === 'ACTIVE';

  const hiddenMetrics = useMemo(() => {
    const newHiddenMetrics: HealthReportMetricType[] = [];

    if (isMeetingsDisabled) {
      newHiddenMetrics.push(HealthReportMetricType.Coaching);
    }

    if (isGoalsDisabled) {
      newHiddenMetrics.push(HealthReportMetricType.Goal);
    }

    if (isFeedbackDisabled) {
      newHiddenMetrics.push(HealthReportMetricType.Feedback);
    }

    return newHiddenMetrics;
  }, [isMeetingsDisabled, isGoalsDisabled, isFeedbackDisabled]);

  const onPersonClick = useCallback(() => {
    dispatch(
      pushDrawerAction({
        drawer: {
          ...baseballCardDrawerTemplate,
          args: {
            id: insightsPeer.orgUserId,
          },
        },
      }),
    );
  }, [dispatch, insightsPeer]);

  const hookProps = {
    insightsPeer,
    person,
    onPersonClick,
    isUserActive,
    hiddenMetrics,
  };

  if (arePeopleLoading) {
    return (
      <SkeletonView />
    );
  }
  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default PersonContainer;
