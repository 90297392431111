import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

import Card from '~Common/V3/components/Card';
import Froala from '~Common/V3/components/Froala';
import { getOrganizationId } from '~Common/utils/localStorage';
import { ReceivedLearningQuestion } from '~Learning/const/interfaces';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const styles = {
  topic: css({
    color: palette.neutrals.gray900,
    fontSize: '1.125rem',
    marginBottom: '1rem',
  }),
  questionCard: css({
    marginTop: '1.125rem',
    overflow: 'initial',
  }),
};

interface ViewProps {
  question: ReceivedLearningQuestion,
  draftKey: string[],
  isReadOnly: boolean,
}

const View = ({
  question,
  draftKey,
  isReadOnly,
}: ViewProps): JSX.Element => (
  <Card
    css={styles.questionCard}
    renderContents={() => (
      <div>
        <HTMLRenderer css={styles.topic} htmlText={question.topic} />
        <Froala
          isReadOnly={isReadOnly}
          name={question.id}
          required
          richTextEditorProps={{
            draftKey,
            initialValue: question.response.text || '',
          }}
          froalaConfigProps={{
            charCounterCount: true,
            charCounterMax: 10000,
          }}
        />
      </div>
    )}
  />
);

interface ReceivedLearningQuestionCardProps {
  question: ReceivedLearningQuestion,
  learningId: string,
  isReadOnly: boolean,
}

const ReceivedLearningQuestionCard = ({ question, learningId, ...props }: ReceivedLearningQuestionCardProps): JSX.Element => {
  const draftKey = [getOrganizationId() ?? '', 'learning', learningId, 'question', question.id];

  const hookProps = {
    question,
    draftKey,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default ReceivedLearningQuestionCard;
