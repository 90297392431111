import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { palette } from '~Common/styles/colors';
import Tooltip from '~Common/components/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { GOAL_DETAIL_STYLES, PROGRESS_BAR_STYLES, SKELETON_STYLES } from '~Goals/const/styles';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { Skeleton } from '@mui/material';
import { useCallback } from 'react';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { getDateString } from '~Common/utils/dateString';
import LinkButton from '~Common/V3/components/LinkButton';

const styles = {
  ...PROGRESS_BAR_STYLES,
  ...GOAL_DETAIL_STYLES,
  ...SKELETON_STYLES,
  progressBarAdjustment: css({
    height: '.3125rem',
  }),
  historyItemWrap: css({
    margin: '.5rem 0 0 0',
    padding: 0,
  }),
  historyItem: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '.5rem',
  }),
  progressBarWrap: css({
    flexBasis: '30%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '.5rem',
  }),
  percent: css({
    width: '2rem',
    fontSize: '12px',
    fontWeight: 400,
    color: palette.neutrals.gray800,
    textAlign: 'right',
  }),
  statusLabel: css({
    fontSize: '.8125rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
  }),
  historySkelly: css({
    height: '1.5rem',
  }),
};

interface ViewProps {
  showSkeleton: boolean,
  getStatusUpdateUrl: (statusId: string) => string,
  statusUpdates: Goals.GoalStatusUpdate[],
}

const View = ({
  showSkeleton,
  getStatusUpdateUrl,
  statusUpdates,
}: ViewProps): JSX.Element => (
  <>
    <div>
      <span css={styles.subHeading}>Goal History</span>
      <div css={styles.historyItemWrap}>
        {showSkeleton && (
          <MultipleSkeletonLoaders
            numberOfSkeletons={5}
            renderSkeletonItem={() => (
              <Skeleton
                variant="text"
                animation="wave"
                css={[styles.allowProperHeight, styles.historySkelly, styles.historyItem]}
              />
            )}
          />
        )}
        {!showSkeleton && (
          <>
            {statusUpdates.map((status) => (
              <LinkButton
                variant="text"
                key={status.statusId}
                to={getStatusUpdateUrl(status.statusId)}
                renderContents={() => (
                  <div
                    css={styles.historyItem}
                  >
                    <span css={styles.statusLabel}>{getDateString({ timestamp: status.createdInMillis }).dateString}</span>
                    <div css={styles.progressBarWrap}>
                      <Tooltip content={`${status?.completionPercentage ?? 0}%`}>
                        <LinearProgress
                          css={[styles.progressBar(status?.status ?? Goals.GoalStatus.OnTrack), styles.progressBarAdjustment]}
                          variant="determinate"
                          value={status?.completionPercentage ?? 0}
                        />
                      </Tooltip>
                      <div css={styles.percent}>
                        {`${status?.completionPercentage || 0}`}
                        %
                      </div>
                    </div>
                  </div>
                )}
              />
            ))}
          </>
        )}
      </div>
    </div>
  </>
);

interface GoalDetailsHistoryProps {
  goalId: string,
  showSkeleton: boolean,
  statusUpdates?: Goals.GoalStatusUpdate[],
}

export const GoalDetailsHistory = ({
  showSkeleton,
  goalId,
  statusUpdates = [],
}: GoalDetailsHistoryProps): JSX.Element => {
  const getStatusUpdateUrl = useCallback((statusId: string) => {
    const baseUrl = GoalsRoutes.GoalStatusById.replace(':goalId', goalId);
    return baseUrl.replace(':statusId', statusId);
  }, [goalId]);

  const hookProps = {
    showSkeleton,
    getStatusUpdateUrl,
    statusUpdates,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
