import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toast } from '~Common/components/Toasts';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { hosts } from '~Deprecated/services/config';
import { presignAndUploadFile } from '~Common/hooks/useFileUpload';
import { editOrganization } from '~Nexus/hooks/useEditOrganization';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { buildRequestBody } from '~Common/utils';

export interface CreateOrganizationRequestData {
  organizationName: string,
  salesForceCustomerAccountNumber: number,
  salesforceId: number,
  enableISolvedModule: boolean,
  enableHRModule: boolean,
  easeUrl?: boolean,
  enableReviews: boolean,
  enableInsights: boolean,
  organizationInTrial: boolean,
  trialEndsAt?: number,
  enableOneWayCalendarIntegration: boolean,
}

export interface CreateOrganizationResponse {
  organizationId: string,
  organizationUserId: string,
}

export const createOrganization = async (requestData: CreateOrganizationRequestData): Promise<HttpCallReturn<CreateOrganizationResponse>> => {
  const url = {
    host: hosts.org,
    uri: '/organizations',
  };

  return postApi(url, buildRequestBody({
    timezone: 'America/Indiana/Indianapolis',
    ...requestData,
  }));
};

export interface CreateOrganizationAddressRequestData {
  id: string
  street: string,
  city: string,
  stateId: string,
  zip: number,
}

export interface CreateOrganizationAddressResponse {
  addressId: string,
}

export const createOrganizationAddress = async ({
  id,
  ...requestData
}: CreateOrganizationAddressRequestData): Promise<HttpCallReturn<CreateOrganizationAddressResponse>> => {
  const url = {
    host: hosts.org,
    uri: `/organizations/${id}/addresses`,
  };

  return postApi(url, buildRequestBody(requestData));
};

export interface CreateOrganizationProps {
  organizationName: string,
  street: string,
  city: string,
  stateId: string,
  zip: number,
  salesForceCustomerAccountNumber: number,
  salesforceId: number,
  logo: File,
  enableISolvedModule: boolean,
  enableHRModule: boolean,
  easeUrl?: boolean,
  organizationInTrial: boolean,
  trialEndsAt?: number,
  enableReviews: boolean,
  enableInsights: boolean,
  enableRecognition?: boolean,
  enableOneWayCalendarIntegration: boolean,
  enableTheTable: boolean,
  enableUnsupportedCalendarProviders: boolean,
  enableActionItems: boolean,
  enableFeedback: boolean,
  enableGoals: boolean,
  enableLearning: boolean,
  enablePdps: boolean,
}

export const combinedCreateOrganization = async ({
  logo,
  street,
  city,
  stateId,
  zip,
  ...other
}: CreateOrganizationProps): Promise<HttpCallReturn<CreateOrganizationResponse>> => {
  const createOrganizationResponse = await createOrganization(other);

  if (createOrganizationResponse.status !== 200) {
    throw createOrganizationResponse;
  }

  const id = createOrganizationResponse.response?.organizationId;
  const createOrganizationAddressResponse = await createOrganizationAddress({
    id: createOrganizationResponse.response.organizationId,
    street,
    city,
    stateId,
    zip,
  });

  if (createOrganizationAddressResponse.status !== 200) {
    throw createOrganizationAddressResponse;
  }

  if (logo?.size) {
    const presignAndUploadFileResponse = await presignAndUploadFile({
      orgId: id,
      file: logo,
    });

    if (presignAndUploadFileResponse.status !== 200) {
      throw presignAndUploadFileResponse;
    }

    const editOrganizationResponse = await editOrganization(id, {
      ...other,
      customerLogo: presignAndUploadFileResponse.response.resourceUrl,
    });

    if (editOrganizationResponse.status !== 200) {
      throw editOrganizationResponse;
    }
  }

  return createOrganizationResponse;
};

export interface UseCreateOrganizationProps {
  drawerName?: string,
}

export const useCreateOrganization = ({
  drawerName,
}: UseCreateOrganizationProps): UseMutationResult<HttpCallReturn<CreateOrganizationResponse>, unknown, CreateOrganizationProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: combinedCreateOrganization,
    onMutate: () => {
      toastId.current = toast.info('Creating organization...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'The organization creation failed due to a technical issue.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      // const { context, actionItems } = variables;

      if (drawerName) {
        // @ts-expect-error Removed once we type or replace Redux
        dispatch(popDrawerAction({ drawerName }));
      }

      toast.update(toastId.current, {
        render: 'The organization was successfully created.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });
};
