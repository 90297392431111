import EngagementBreakdownCard from '~Insights/components/cards/EngagementBreakdownCard';
import EngagementCard from '~Insights/components/cards/EngagementCard/index';
import ENPSCard from '~Insights/components/cards/ENPSCard/index';
import ENPSSmallCard from '~Insights/components/cards/ENPSSmallCard';
import LeadrCoachCard from '~Insights/components/cards/LeadrCoachCard';
import PulseSurveyCard from '~Insights/components/cards/PulseSurveyCard';
import PeopleCard from '~Insights/components/cards/PeopleCard';
import { CardDetail } from './types';

const cards: Readonly<CardDetail[]> = [
  {
    key: 'pulseSurvey',
    dashboardSize: 'large',
    dashboardComponent: <PulseSurveyCard />,
    requiresDirectReports: false,
  },
  {
    key: 'engagement',
    dashboardSize: 'large',
    tinyComponent: <EngagementCard size="tiny" />,
    dashboardComponent: <EngagementCard size="dashboard" />,
    expandedComponent: <EngagementCard size="expanded" />,
    requiresDirectReports: true,
  },
  {
    key: 'enps',
    dashboardSize: 'large',
    dashboardComponent: <ENPSCard size="dashboard" />,
    expandedComponent: <ENPSCard size="expanded" />,
    requiresDirectReports: true,
  },
  {
    key: 'engagementBreakdown',
    dashboardSize: 'small',
    tinyComponent: <EngagementBreakdownCard size="tiny" />,
    dashboardComponent: <EngagementBreakdownCard size="dashboard" />,
    requiresDirectReports: true,
  },
  {
    key: 'people',
    dashboardSize: 'large',
    dashboardComponent: <PeopleCard size="dashboard" />,
    expandedComponent: <PeopleCard size="expanded" />,
    requiresDirectReports: false,
  },
  {
    key: 'enpsSmall',
    dashboardSize: 'small',
    tinyComponent: <ENPSSmallCard size="tiny" />,
    dashboardComponent: <ENPSSmallCard size="dashboard" />,
    requiresDirectReports: true,
  },
  {
    key: 'coach',
    dashboardSize: 'small',
    tinyComponent: <LeadrCoachCard size="tiny" />,
    dashboardComponent: <LeadrCoachCard size="dashboard" />,
    expandedComponent: <LeadrCoachCard size="expanded" />,
    requiresDirectReports: true,
  },
] as const;

export default cards;
