import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import {
  useCallback, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  drawerInputBorder,
  drawerInputDisabled,
  drawerInputLabelText,
  drawerInputText,
  drawerInputBackground,
} from '~Deprecated/ui/styles/colors';

import { EMOTION_CSS_SHAPE, FONT_AWESOME_ICON, REF_SHAPE } from '~Common/const/proptypes';
import { TEXT_MAX_LENGTH } from '~Common/const/inputs';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { drawerHeaderFontColor } from '~Common/styles/colors';

const style = css`
  background: ${drawerInputBackground};
  border: .0625rem solid ${drawerInputBorder};
  padding: .4375rem 1rem;
  box-sizing: border-box;
  border-radius: .5rem;
  font-style: normal;
  font-weight: bold;
  font-size: .75rem;
  line-height: 1rem;
  display: inline-block;
  align-items: center;
  letter-spacing: .0313rem;
  height: auto;
  min-height: unset;

  &:focus {
    outline: none !important;
  }

  ::-webkit-input-placeholder {
    color: ${drawerInputText} !important;
  }

  label {
    color: ${drawerInputLabelText};
    margin-bottom: 0;
    text-transform: uppercase;
  }

  input {
    border: unset;
    background: unset;
    box-shadow: unset;
    border-radius: unset;
    color: ${drawerHeaderFontColor};
    height: auto;
    margin: 0;
    padding: 0.3rem 0;
    font-weight: 500;
    font-size: 1rem;
    min-height: 1.875rem;

    &:focus {
      box-shadow: unset;
    }
  }
`;

const iconStyle = css`
  width: 1rem !important;
  height: 1rem !important;
  margin-top: .0625rem;
`;

const inputContainer = css`
  display: flex;
  width: 100%;

  & svg {
    position: relative;
    align-self: center;
    margin-right: .625rem;
    stroke: ${drawerInputText};
  }

  & input {
    box-shadow: none;
    width: 100%;
  }
`;

const disabledInput = css`
  background: ${drawerInputDisabled};
  border: unset;
`;

const iconWrapper = css`
  margin-left: .5rem;
`;

const widths = {
  halfRow: css`
    width: 49%;
    margin-right: 1%;
  `,
  halfRowNoMargin: css`
    width: 49%;
    margin-right: 0;
  `,
  threeQuarterRow: css`
    width: 78%;
  `,
  fullRow: css`
    width: 100%; // Changed to 100%
  `,
};

export const SIZE_OPTIONS = {
  FULL_ROW: 'fullRow',
  THREE_QUARTER_ROW: 'threeQuarterRow',
  HALF_ROW: 'halfRow',
  HALF_ROW_NO_MARGIN: 'halfRowNoMargin',
};

const View = ({
  name,
  size,
  onChange,
  onBlur,
  label,
  disabled,
  className,
  inputRef,
  renderIcon,
  renderRightIcon,
  iconProps,
  value,
  renderAdornment,
  maxLength,
  displayValue,
  placeholder,
  ...props
}) => {
  const defaultIcon = useCallback(() => {
    const { icon, style: iconStyleOverrides } = iconProps;
    const onClick = disabled ? () => {} : iconProps.onClick;

    if (!icon) {
      return null;
    }

    return (
      <FontAwesomeIcon
        css={[iconStyle, iconStyleOverrides]}
        icon={icon}
        onClick={onClick}
      />
    );
  }, [iconProps, disabled]);

  const iconToUse = renderIcon || defaultIcon;
  const rightIconToUse = renderRightIcon;

  return (
    <div
      css={[
        style,
        widths[size],
        (disabled ? disabledInput : ''),
      ]}
      className={className}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <div
        css={inputContainer}
      >
        {iconToUse && iconToUse()}
        {!disabled && (
          <>
            <input
              placeholder={placeholder}
              ref={inputRef}
              name={!displayValue && name}
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              value={displayValue ?? value}
              disabled={disabled}
              maxLength={maxLength ?? TEXT_MAX_LENGTH}
              {...props}
            />
            {displayValue && (
              <input type="hidden" name={name} readOnly value={value} />
            )}
          </>
        )}
        {rightIconToUse && rightIconToUse()}
        {disabled && (
          <input type="text" readOnly value={value} name={name} />
        )}
        {renderAdornment
          && (
            <div css={iconWrapper}>
              {renderAdornment()}
            </div>
          )}
      </div>
    </div>
  );
};

View.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputRef: REF_SHAPE,
  value: PropTypes.string,
  renderIcon: PropTypes.func,
  renderRightIcon: PropTypes.func,
  renderAdornment: PropTypes.func,
  maxLength: PropTypes.number,
  displayValue: PropTypes.string,
  iconProps: PropTypes.shape({
    icon: FONT_AWESOME_ICON,
    style: EMOTION_CSS_SHAPE,
    onClick: PropTypes.func,
  }),
  placeholder: PropTypes.string.isRequired,
};

View.defaultProps = {
  size: SIZE_OPTIONS.FULL_ROW,
  onChange: () => {
  },
  onBlur: () => {
  },
  label: '',
  className: '',
  disabled: false,
  inputRef: null,
  iconProps: {},
  value: '',
  displayValue: null,
  renderIcon: null,
  renderRightIcon: null,
  renderAdornment: null,
  maxLength: null,
  name: null,
};

const useTextField = ({
  initialValue,
  onChange,
}) => {
  const [value, setValue] = useState(initialValue);
  const previousValue = usePrevious(initialValue);

  useEffect(() => {
    if (initialValue !== previousValue) {
      setValue(initialValue);
    }
  }, [initialValue, previousValue]);

  const doChange = useCallback((event) => {
    const text = event.target.value;
    setValue(text);
    event.persist();
    if (onChange) {
      onChange(event);
    }
  }, [onChange]);

  const onBlur = useCallback(() => {
    // Added string conversion, because values other than string do not have trim method.
    const trimmedValue = value?.toString().trim();

    if (trimmedValue !== value) {
      setValue(trimmedValue);
    }
  }, [value]);

  return {
    doChange,
    onBlur,
    value,
    setValue,
  };
};

/**
 * @deprecated Old TextField UI, use packages/leadr-frontend/src/common/V3/components/uncontrolled/TextField.tsx instead
 */
const TextField = ({
  initialValue,
  onChange,
  ...props
}) => {
  const {
    doChange,
    onBlur,
    value,
  } = useTextField({
    initialValue,
    onChange,
  });

  return (
    <View
      onChange={doChange}
      onBlur={onBlur}
      value={value}
      {...props}
    />
  );
};

TextField.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  initialValue: '',
  onChange: null,
};

export { View, useTextField };
export default TextField;
