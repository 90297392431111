import {
  ComponentProps,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { palette } from '~Common/styles/colors';
import { extractFileExtension, fileExtensionToIcon } from '~Meetings/utils/fileExtensionToIcon';
import {
  forMobileObject, withTruncate,
} from '~Common/styles/mixins';
import { AcceptedExtension } from '~Meetings/const/attachments';
import Tooltip from '~Common/components/Tooltip';
import { Attachment } from '~Meetings/hooks/v2/useGetAgendas';

const styles = {
  attachmentChip: (shouldShowRemove: boolean, isDark?: boolean, pendingRemove?: boolean) => css(
    {
      alignItems: 'center',
      backgroundColor: palette.neutrals.white,
      border: '1px solid transparent',
      borderRadius: '1rem',
      color: palette.neutrals.gray800,
      display: 'flex',
      fontSize: '0.75rem',
      padding: '0 0.5rem',
      position: 'relative',
      width: 'fit-content',
      maxWidth: '16rem',

      '& .removeButton': {
        display: 'none',
      },
    },
    shouldShowRemove && forMobileObject({
      '& .removeButton': {
        position: 'unset',
        display: 'flex',
      },
    }),
    shouldShowRemove && {
      '&:hover': {
        '& .removeButton': {
          backgroundColor: (isDark && !pendingRemove) ? palette.neutrals.gray200 : palette.neutrals.white,
          borderRadius: '0 0.5rem 0.5rem 0',
          display: 'flex',
        },
      },
    },
    isDark && {
      backgroundColor: palette.neutrals.gray200,
    },
    pendingRemove && {
      backgroundColor: palette.neutrals.white,
      borderColor: palette.brand.red,
      color: palette.brand.red,
    },
  ),

  icon: css({
    marginRight: '0.25rem',
  }),

  fileName: css({
    flexShrink: 1,
  }, withTruncate()),

  removeButton: css({
    position: 'absolute',
    right: '0.15rem',
    border: 0,
    padding: '0 0.25rem',
  }),

  removeIcon: (pendingRemove?: boolean) => css(
    {
      color: palette.neutrals.gray800,
      '&:hover': {
        color: palette.brand.red,
      },
    },
    pendingRemove && {
      color: palette.brand.red,
    },
  ),
};

export interface ViewProps extends ComponentProps<'button'> {
  dark?: boolean,
  fileExtension: string,
  fileIcon: IconDefinition,
  fileName: string,
  fileNameWithoutExtension: string,
  onClick: () => void,
  onRemove: (e: SyntheticEvent<HTMLButtonElement>) => void,
  pendingRemove?: boolean,
  setHoveringRemoveButton: Dispatch<SetStateAction<boolean>>,
  shouldShowRemove: boolean,
}

const View = ({
  dark,
  fileExtension,
  fileIcon,
  fileName,
  fileNameWithoutExtension,
  onClick,
  onRemove,
  pendingRemove,
  setHoveringRemoveButton,
  shouldShowRemove,
  ...props
}: ViewProps): JSX.Element => (
  <button
    css={styles.attachmentChip(shouldShowRemove, dark, pendingRemove)}
    type="button"
    onClick={onClick}
    {...props}
  >
    <Tooltip content={fileName}>
      <FontAwesomeIcon
        css={styles.icon}
        icon={fileIcon}
      />
    </Tooltip>

    <div
      css={styles.fileName}
    >
      {fileNameWithoutExtension}
    </div>
    <div>
      {`.${fileExtension}`}
    </div>

    {shouldShowRemove && (
      <button
        css={styles.removeButton}
        className="removeButton"
        data-test-id="removeAttachmentButton"
        type="button"
        onClick={onRemove}
        onMouseEnter={() => setHoveringRemoveButton(true)}
        onMouseLeave={() => setHoveringRemoveButton(false)}
      >
        <FontAwesomeIcon
          css={styles.removeIcon(pendingRemove)}
          icon={faCircleXmark}
        />
      </button>
    )}

  </button>
);

interface AttachmentChipProps extends Omit<ComponentProps<'button'>, 'onClick'> {
  attachment: Attachment,
  dark?: boolean
  onClick?: (attachmentId?: number) => void
  onRemove?: (attachment: Attachment) => void
  pendingRemove?: boolean,
}

const AttachmentChip = ({
  attachment,
  dark,
  onClick: doClick,
  onRemove: doRemove,
  pendingRemove,
  ...props
}: AttachmentChipProps): JSX.Element => {
  const { id: attachmentId, fileName } = attachment;
  const [hoveringRemoveButton, setHoveringRemoveButton] = useState(false);
  const [fileNameWithoutExtension, fileExtension] = extractFileExtension(fileName);
  const fileIcon = fileExtensionToIcon(fileExtension as AcceptedExtension);
  const shouldShowRemove = !!doRemove;

  const onClick = useCallback(() => {
    doClick?.(attachmentId);
  }, [doClick, attachmentId]);

  const onRemove = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    doRemove?.(attachment);
  }, [doRemove, attachment]);

  const hookProps = {
    dark,
    fileExtension,
    fileIcon,
    fileName,
    fileNameWithoutExtension,
    onClick,
    onRemove,
    pendingRemove: pendingRemove || hoveringRemoveButton,
    setHoveringRemoveButton,
    shouldShowRemove,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default AttachmentChip;
