import { css } from '@emotion/react';
import LeadrModal, { LeadrModalProps } from '~Common/V3/components/LeadrModal';
import { palette } from '~Common/styles/colors';
import { ModalCloseReason } from '~Common/const/ModalTypes';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerCloseButtonWithConfirmation from '~Learning/components/Shared/ConfirmationButtons/DrawerCloseButtonWithConfirmation';
import DrawerInput from '~Common/V3/components/DrawerInput';
import {
  FormEvent,
  SyntheticEvent,
  useMemo,
  useState,
} from 'react';
import { toast } from '~Common/components/Toasts';
import Froala from '~Common/V3/components/Froala';
import PeopleTagPicker from '~Common/components/PeopleTagPicker';
import { SelectOption } from '~Common/components/PeopleTagPicker/usePeopleTagPicker';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import { FormControlLabel } from '@mui/material';
import { UserGroup } from '~Common/const/userGroups';
import Tooltip from '~Common/components/Tooltip';
import {
  TEAM_CLARITY_ANSWER_MAX_LENGTH,
  TEAM_DESCRIPTION_MAX_LENGTH,
  TEAM_NAME_MAX_LENGTH,
} from '~People/components/Teams/const';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useShowCreateEditTeamModal } from '../../stores/useShowCreateEditTeamModal';
import {
  CreateEditTeamPayload,
  TeamClarityQuestion,
  TeamClarityQuestionBase,
  VisibilityType,
} from '../../const/types';
import { useCreateTeam } from '../../hooks/useCreateTeam';
import { useEditTeam } from '../../hooks/useEditTeam';

const styles = {
  leadrModal: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  form: css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  }),
  title: css({
    color: palette.brand.indigo,
  }),
  teamDetails: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  section: css({
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  sectionHeader: css({
    color: palette.neutrals.gray800,
  }),
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  }),
  publicCheckbox: css({
    '.Mui-disabled': {
      cursor: 'not-allowed',
      color: palette.neutrals.gray400,
    },
  }),
};

interface ViewProps extends Omit<LeadrModalProps, 'children' | 'open' | 'onClose'> {
  showModal: boolean,
  isEditMode: boolean,
  handleCloseClick: () => void,
  handleCloseModal: (_: unknown, reason?: ModalCloseReason) => void,
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void,
  initialData?: EditTeamInitialData,
  enableTheTable: boolean,
  teamClarityQuestionList: TeamClarityQuestionBase[],
  ownerOrgUserIds: string[],
  memberOrgUserIds: string[],
  handleOwnersChange: (event: SyntheticEvent, value: SelectOption[]) => void,
  handleMembersChange: (event: SyntheticEvent, value: SelectOption[]) => void,
  showVisibilityTypeOption: boolean,
  disabledOwnerOrgUserIds: string[],
  disabledMemberOrgUserIds: string[],
  requiredTeamOwnerOrgUserIds: string[],
  teamOwnerAccountTypesToInclude: UserGroup[],
  thematicGoalOnlyMode?: boolean,
}

const View = ({
  showModal,
  isEditMode,
  handleCloseClick,
  handleCloseModal,
  handleSubmit,
  initialData,
  enableTheTable,
  teamClarityQuestionList,
  ownerOrgUserIds,
  memberOrgUserIds,
  handleOwnersChange,
  handleMembersChange,
  showVisibilityTypeOption,
  disabledOwnerOrgUserIds,
  disabledMemberOrgUserIds,
  requiredTeamOwnerOrgUserIds = [],
  teamOwnerAccountTypesToInclude,
  thematicGoalOnlyMode,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrModal
    open={showModal}
    css={styles.leadrModal}
    // @ts-expect-error No TS, it is you who are wrong
    onClose={handleCloseModal}
    {...props}
  >
    <form css={styles.form} onSubmit={handleSubmit}>
      <LeadrModal.Header shouldConfirmClose>
        <LeadrModal.Title css={styles.title}>
          {isEditMode ? 'Edit Team' : 'Create Team'}
        </LeadrModal.Title>
      </LeadrModal.Header>
      <LeadrModal.Body>
        <div css={styles.teamDetails}>
          <DrawerInput
            label="Name"
            name="name"
            initialValue={initialData?.name}
            required
            maxLength={TEAM_NAME_MAX_LENGTH}
            disabled={thematicGoalOnlyMode}
          />
          <DrawerInput
            label="Description"
            name="description"
            initialValue={initialData?.description}
            maxLength={TEAM_DESCRIPTION_MAX_LENGTH}
            disabled={thematicGoalOnlyMode}
          />
        </div>
        {enableTheTable && (
          <div css={styles.section}>
            <div css={styles.sectionHeader}>Team Clarity</div>
            {teamClarityQuestionList.map((teamClarityQuestion) => (
              <Froala
                key={teamClarityQuestion.rank}
                label={teamClarityQuestion.question}
                isDisabled={thematicGoalOnlyMode && initialData && teamClarityQuestion.rank !== initialData.thematicGoalQuestionRank}
                froalaConfigProps={{
                  charCounterCount: true,
                  charCounterMax: TEAM_CLARITY_ANSWER_MAX_LENGTH,
                  toolbarButtons: {
                    moreText: {
                      buttons: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
                      buttonsVisible: 5,
                    },
                  },
                  pasteDeniedTags: ['script', 'style', 'img', 'table', 'tr', 'td', 'th', 'tbody', 'thead', 'tfoot', 'iframe'],
                  pastePlain: true,
                }}
                richTextEditorProps={{
                  initialValue: initialData?.teamClarityQuestionList.find((question) => question.rank === teamClarityQuestion.rank)?.answer,
                }}
                name={`teamClarityAnswer-${teamClarityQuestion.rank}`}
              />
            ))}
          </div>
        )}
        <div css={styles.section}>
          <div css={styles.sectionHeader}>Team Owners</div>
          <PeopleTagPicker
            disabled={thematicGoalOnlyMode}
            label="Select People"
            onChange={handleOwnersChange}
            disabledIds={disabledOwnerOrgUserIds}
            initialSelectedIds={ownerOrgUserIds}
            disableClearable
            chipProps={{
              shouldDisableChip: (value) => requiredTeamOwnerOrgUserIds.includes(value),
            }}
            accountTypesToInclude={teamOwnerAccountTypesToInclude}
          />
          <div css={styles.sectionHeader}>
            <span data-test-id="create-team-team-members">Team Members</span>
          </div>
          <PeopleTagPicker
            disabled={thematicGoalOnlyMode}
            label="Select People"
            onChange={handleMembersChange}
            disabledIds={disabledMemberOrgUserIds}
            initialSelectedIds={memberOrgUserIds}
            disableClearable
          />
        </div>
        <div css={styles.section}>
          <Tooltip content={showVisibilityTypeOption ? '' : 'Please contact your admin if you need to create a public team.'}>
            <FormControlLabel
              disabled={!showVisibilityTypeOption || thematicGoalOnlyMode}
              css={styles.publicCheckbox}
              control={(
                <CustomCheckbox
                  name="isPublicTeam"
                  defaultChecked={initialData?.visibilityType === VisibilityType.Public}
                />
              )}
              label="Public Team"
            />
          </Tooltip>
        </div>
      </LeadrModal.Body>
      <LeadrModal.Footer css={styles.footer}>
        <LeadrButton
          size="small"
          type="submit"
          data-test-id={isEditMode ? 'createEditTeamsModalSave' : 'createEditTeamsModalCreate'}
        >
          {isEditMode ? 'Save Changes' : 'Create'}
        </LeadrButton>
        <DrawerCloseButtonWithConfirmation
          renderDrawerCloseButton={({ onClick }) => (
            <LeadrButton
              size="small"
              variant="ghost"
              onClick={onClick}
              data-test-id="createEditTeamsModalCancel"
            >
              Cancel
            </LeadrButton>
          )}
          onCloseDrawer={handleCloseClick}
          shouldConfirmClose
        />
      </LeadrModal.Footer>
    </form>
  </LeadrModal>
);

export interface EditTeamInitialData {
  teamId: string,
  name: string,
  description: string,
  ownerOrgUserIds: string[],
  memberOrgUserIds: string[],
  teamClarityQuestionList: TeamClarityQuestion[],
  visibilityType: VisibilityType,
  thematicGoalQuestionRank: number,
}

interface CreateEditTeamModalProps extends Omit<LeadrModalProps, 'children' | 'open' | 'onClose'> {
  isEditMode?: boolean,
  initialData?: EditTeamInitialData,
  requiredTeamOwnerOrgUserIds?: string[],
  thematicGoalOnlyMode?: boolean,
}

const CreateEditTeamModal = ({
  isEditMode = false,
  initialData,
  requiredTeamOwnerOrgUserIds = [],
  thematicGoalOnlyMode,
  ...props
}: CreateEditTeamModalProps): JSX.Element => {
  const {
    showModal,
    setShowModal,
  } = useShowCreateEditTeamModal((state) => ({
    showModal: state.showModal,
    setShowModal: state.setShowModal,
  }));
  const { orgSettings, enableTheTable } = useOrgDetailsContext();

  const { isAdmin } = useUserPermissions();

  const [ownerOrgUserIds, setOwnerOrgUserIds] = useState<string[]>([...(initialData?.ownerOrgUserIds || []), ...(requiredTeamOwnerOrgUserIds || [])]);
  const [memberOrgUserIds, setMemberOrgUserIds] = useState<string[]>(initialData?.memberOrgUserIds || []);

  const disabledOwnerOrgUserIds = useMemo(() => (
    [...memberOrgUserIds, ...(requiredTeamOwnerOrgUserIds || [])]
  ), [requiredTeamOwnerOrgUserIds, memberOrgUserIds]);

  const disabledMemberOrgUserIds = useMemo(() => ([...ownerOrgUserIds]), [ownerOrgUserIds]);

  const teamOwnerAccountTypesToInclude = [UserGroup.Admin, UserGroup.Member, UserGroup.Executive];

  const { mutate: createTeamMutation } = useCreateTeam({
    handleCompletion: () => {
      setShowModal(false);
    },
  });

  const { mutate: editTeamMutation } = useEditTeam({
    handleCompletion: () => {
      setShowModal(false);
    },
  });

  const handleOwnersChange = (event: SyntheticEvent, value: SelectOption[]): void => {
    const tempOwnerOrgUserIds = value.map((option) => option.value);
    setOwnerOrgUserIds(tempOwnerOrgUserIds);
  };

  const handleMembersChange = (event: SyntheticEvent, value: SelectOption[]): void => {
    const tempMemberOrgUserIds = value.map((option) => option.value);
    setMemberOrgUserIds(tempMemberOrgUserIds);
  };

  const handleCloseClick = (): void => {
    setShowModal(false);
  };

  const handleCloseModal = (_: unknown, reason?: ModalCloseReason): void => {
    if (reason === ModalCloseReason.Backdrop_Click || reason === ModalCloseReason.Escape_Key) {
      return;
    }
    handleCloseClick();
  };

  const teamClarityQuestionList = orgSettings.teamClarityQuestionList || [];

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (ownerOrgUserIds.length === 0) {
      toast.error('You must select at least one team owner.');
      return;
    }

    const formData = new FormData(event.currentTarget);
    const {
      name,
      description,
    } = Object.fromEntries(formData.entries()) as { name: string, description: string };

    const visibilityType = formData.get('isPublicTeam') === 'on' ? VisibilityType.Public : VisibilityType.Private;

    const teamClarityAnswerList = teamClarityQuestionList.map((teamClarityQuestion) => {
      const answer = formData.get(`teamClarityAnswer-${teamClarityQuestion.rank}`) as string;

      return {
        rank: teamClarityQuestion.rank,
        answer,
      };
    }) || [];

    const payload: CreateEditTeamPayload = {
      name,
      description,
      teamClarityAnswerList,
      ownerOrgUserIds,
      memberOrgUserIds,
      visibilityType,
    };

    if (isEditMode && initialData) {
      if (thematicGoalOnlyMode) {
        const newTeamClarityAnswerList = initialData.teamClarityQuestionList.map((teamClarityQuestion) => ({
          rank: teamClarityQuestion.rank,
          answer: teamClarityQuestion.answer,
        }));

        const thematicGoalTeamClarityAnswer = newTeamClarityAnswerList.find((teamClarityAnswer) => (
          teamClarityAnswer.rank === initialData.thematicGoalQuestionRank
        ));

        if (thematicGoalTeamClarityAnswer) {
          thematicGoalTeamClarityAnswer.answer = teamClarityAnswerList.find((teamClarityAnswer) => (
            teamClarityAnswer.rank === initialData.thematicGoalQuestionRank
          ))?.answer ?? '';
        }

        editTeamMutation({
          teamId: initialData.teamId,
          editTeamPaylod: {
            name: initialData.name,
            description: initialData.description,
            ownerOrgUserIds: initialData.ownerOrgUserIds,
            memberOrgUserIds: initialData.memberOrgUserIds,
            visibilityType: initialData.visibilityType,
            teamClarityAnswerList: newTeamClarityAnswerList,
          },
        });
      } else {
        editTeamMutation({ teamId: initialData.teamId, editTeamPaylod: payload });
      }
    } else {
      createTeamMutation(payload);
    }
  };

  const hookProps = {
    showModal,
    handleCloseClick,
    handleCloseModal,
    handleSubmit,
    isEditMode,
    enableTheTable,
    teamClarityQuestionList,
    ownerOrgUserIds,
    memberOrgUserIds,
    handleOwnersChange,
    handleMembersChange,
    showVisibilityTypeOption: isAdmin,
    disabledOwnerOrgUserIds,
    disabledMemberOrgUserIds,
    requiredTeamOwnerOrgUserIds,
    teamOwnerAccountTypesToInclude,
    initialData,
    thematicGoalOnlyMode,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default CreateEditTeamModal;
