import { Location } from 'history';
import {
  SyntheticEvent, useMemo,
} from 'react';
import LeadrTabs from '~Common/V3/components/LeadrTabs';
import { ViewPerspective } from '~Goals/const/types';
import { OPEN_GOAL_TABS, COMPLETED_GOAL_TABS, GOAL_TABS_LABELS } from '~Goals/const/defaults';
import { Goals } from '@leadr-hr/types';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  goalTypeTabs: css({
    color: `${palette.neutrals.gray700} !important`,
    '&.Mui-selected': {
      color: `${palette.brand.indigo} !important`,
    },
  }),
};

interface TabRecord {
  label: string,
  value: string,
  toObject: Partial<Location>,
  ['data-test-id']: string,
}

interface ViewProps {
  activeTab: Goals.GoalContextType,
  tabs: TabRecord[],
  handleTabChange: (event: SyntheticEvent, tab: Goals.GoalContextType) => void,
  renderRightItem?: () => JSX.Element,
  isMobile?: boolean,
}

const View = ({
  activeTab,
  tabs,
  handleTabChange,
  isMobile = false,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrTabs
    value={activeTab}
    handleChange={handleTabChange}
    {...props}
  >
    {tabs.map((tab) => (
      <LeadrTabs.Tab
        label={tab.label === GOAL_TABS_LABELS.Organization.Label && isMobile ? 'Org Goals ' : tab.label}
        value={tab.value}
        key={tab.value}
        data-test-id={tab['data-test-id']}
        css={styles.goalTypeTabs}
      />
    ))}
  </LeadrTabs>
);

interface TabsProps extends Omit<ViewProps, 'tabs' | 'handleTabChange'> {
  setActiveTab: (tab: Goals.GoalContextType) => void,
  viewPerspective: ViewPerspective,
}

const OldTabs = ({
  setActiveTab,
  viewPerspective,
  ...props
}: TabsProps): JSX.Element => {
  const isCompleted = viewPerspective === ViewPerspective.Completed;
  const openGoalTabs = useMemo(() => OPEN_GOAL_TABS.map((tab) => ({
    ...tab,
  })), []);

  const completedGoalTabs = useMemo(() => COMPLETED_GOAL_TABS.map((tab) => ({
    ...tab,
  })), []);
  const handleTabChange = (event: SyntheticEvent, newTab: Goals.GoalContextType): void => {
    setActiveTab(newTab);
  };
  const isMobile = useIsMobileQuery();

  const hookProps = {
    tabs: isCompleted ? completedGoalTabs : openGoalTabs,
    handleTabChange,
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default OldTabs;
