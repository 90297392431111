export class NonLeadrUser {
  email: string;

  isNonLeadrUser: boolean;

  constructor(email: string) {
    this.email = email;
    this.isNonLeadrUser = true;
  }
}
