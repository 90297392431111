import { css } from '@emotion/react';

import { palette } from '~Common/styles/colors';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import Tooltip from '../Tooltip';

const styles = {
  button: css({
    // Generally I like doing the padding in the parent, but since the buttons are clickable, we will let them own the padding to increase the clickable area
    padding: '.625rem .5rem .625rem .5rem',
  }),
};

interface FloatingActionMenuButtonProps extends LeadrButtonProps<'button'> {
  tooltipContent: string,
}

const FloatingActionMenuButton = ({
  tooltipContent,
  children,
  ...props
}: FloatingActionMenuButtonProps): JSX.Element => (
  <Tooltip
    content={tooltipContent}
  >
    <LeadrButton
      variant="text"
      textButtonColor={palette.brand.indigo}
      css={styles.button}
      {...props}
    >
      {children}
    </LeadrButton>
  </Tooltip>
);

export default FloatingActionMenuButton;
