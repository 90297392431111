import { css } from '@emotion/react';
import {
  SyntheticEvent, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  ACCOUNT_SETTINGS, ADMIN_SETTINGS,
} from '~Common/const/routes';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import LeadrCard from '~Common/V3/components/LeadrCard';
import { palette } from '~Common/styles/colors';
import LeadrTabs from '~Common/V3/components/LeadrTabs';
import SettingsRouter from '~Settings/routes/SettingsRouter';

const styles = {
  leadrCard: css({
    padding: '1.5rem',
    width: '95%',
    margin: '1.875rem 0',
  }),
  headerText: css({
    color: palette.brand.indigo,
    fontSize: '1.5rem',
    fontWeight: 600,
    marginBottom: '1rem',
  }),
};

interface ViewProps {
  settingsTabs: SettingsTab[],
  activeTab: string,
  handleTabChange: (event: SyntheticEvent, tab: string) => void,
}

const View = ({
  settingsTabs,
  activeTab,
  handleTabChange,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrCard css={styles.leadrCard} {...props}>
    <div css={styles.headerText}>Settings</div>
    {settingsTabs.length > 1 && (
      <LeadrTabs
        value={activeTab}
        handleChange={handleTabChange}
      >
        {settingsTabs.map((settingsTab) => (
          <LeadrTabs.LinkTab
            label={settingsTab.label}
            to={settingsTab.link}
            key={settingsTab.link}
            value={settingsTab.link}
            data-test-id={settingsTab['data-test-id']}
          />
        ))}
      </LeadrTabs>
    )}
    <div>
      <SettingsRouter />
    </div>
  </LeadrCard>
);

interface SettingsTab {
  label: string,
  link: string,
  'data-test-id': string,
}

type SettingsCardProps = Omit<ViewProps, 'settingsTabValue'>;

const SettingsCard = ({ ...props }: SettingsCardProps): JSX.Element => {
  const { pathname } = useLocation();
  const { isAdmin: showAdminSettings } = useUserPermissions();

  const settingsTabs = useMemo<SettingsTab[]>(() => {
    const tabs = [
      {
        label: 'Account',
        link: ACCOUNT_SETTINGS,
        'data-test-id': 'settingsAccountTab',
      },
    ];

    if (showAdminSettings) {
      tabs.push({
        label: 'Admin',
        link: ADMIN_SETTINGS,
        'data-test-id': 'settingsAdminTab',
      });
    }

    return tabs;
  }, [showAdminSettings]);

  const defaultTab = settingsTabs[0];

  const currentSettingsTab = useMemo(() => {
    const matchedSettingsTabValue = settingsTabs.find((settingTab) => pathname.includes(settingTab.link));
    return matchedSettingsTabValue || defaultTab;
  }, [defaultTab, pathname, settingsTabs]);

  const [activeTab, setActiveTab] = useState(currentSettingsTab.link);

  const handleTabChange = (event: SyntheticEvent, newTab: string): void => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    // Ensure that the proper tab is selected, when navigating from the modal or directly to the page
    if (activeTab !== currentSettingsTab.link) {
      setActiveTab(currentSettingsTab.link);
    }
  }, [activeTab, setActiveTab, currentSettingsTab]);

  const hookProps = {
    settingsTabs,
    activeTab,
    handleTabChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default SettingsCard;
