import { css } from '@emotion/react';
import { Moment } from 'moment-timezone';
import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { MonthlyRecurrence } from '~ActionItems/hooks/useActionItemRecurrenceFrequency';
import { getMonthlyDayFrequencyString, getMonthlyWeekdayFrequencyString } from '~Common/utils/frequency';
import { forMobileObject } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';

const styles = {
  dropdown: css({
    marginLeft: '0.5rem',
    '.MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: '1rem',
      color: palette.neutrals.gray800,
      fontWeight: 500,
    },
  }, forMobileObject({
    width: '100%',
    marginLeft: 0,
  })),
};

type ViewProps = Omit<DropdownProps<MonthlyRecurrence>, 'renderItem'>;

const View = ({
  items,
  value,
  onChange,
  ...props
}:ViewProps): JSX.Element => (
  <Dropdown
    css={styles.dropdown}
    items={items}
    value={value}
    onChange={onChange}
    renderItem={(item: DropdownItem<string>) => (
      <div>
        {item.text}
      </div>
    )}
    {...props}
  />
);

interface MonthlyRecurrenceDropdownProps extends Omit<ViewProps, 'items'> {
  dueDate: Moment,
}

const MonthlyRecurrenceDropdown = ({ dueDate, ...props }: MonthlyRecurrenceDropdownProps):JSX.Element => {
  const monthlyOptions = [
    { value: MonthlyRecurrence.ByDay, text: getMonthlyDayFrequencyString(dueDate) },
    { value: MonthlyRecurrence.ByWeekday, text: getMonthlyWeekdayFrequencyString(dueDate) },
  ];

  const hookProps = {
    items: monthlyOptions,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MonthlyRecurrenceDropdown;
