import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { WorkingGenius } from '~Meetings/const/meetingsInterfaces';

export interface WorkingGeniusData {
  Wonder: WorkingGenius,
  Invention: WorkingGenius,
  Discernment: WorkingGenius,
  Galvanizing: WorkingGenius,
  Enablement: WorkingGenius,
  Tenacity: WorkingGenius,
}

interface GetWorkingGeniusMapParams {
  meetingOrgUserIdList: string[],
}

const getWorkingGeniusMap = async ({ meetingOrgUserIdList }: GetWorkingGeniusMapParams): Promise<HttpCallReturn<WorkingGeniusData>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/users/workingGeniusMap`;

  return postApi<WorkingGeniusData>(serverUrl, { orgUserIdList: meetingOrgUserIdList });
};

interface UseGetWorkingGeniusMapParams {
  meetingOrgUserIdList: string[],
}

export const useGetWorkingGeniusMap = ({
  meetingOrgUserIdList,
}: UseGetWorkingGeniusMapParams): UseQueryResult<HttpCallReturn<WorkingGeniusData>> => useQuery({
  queryKey: [getOrganizationId() ?? '', 'workingGeniusMap', meetingOrgUserIdList],
  queryFn: () => getWorkingGeniusMap({ meetingOrgUserIdList }),
  enabled: meetingOrgUserIdList.length > 0,
});
