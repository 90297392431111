import moment from 'moment-timezone';
import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { BarChart } from '~Common/components/Charts/BarChart';
import { GaugeChart } from '~Common/components/Charts/GaugeChart';
import { LineGraphChart } from '~Common/components/Charts/LineGraphChart';
import { palette } from '~Common/styles/colors';
import DateRangeSelect from '~Insights/components/DateRangeSelect';
import InsightsDelta from '~Insights/components/InsightsDelta';
import SidebarFilters, { SidebarFilterOptions } from '~Insights/components/SidebarFilters';
import {
  GraphDataset,
  GroupedENPSData,
  makeDelta,
  makeENPSGraphData,
  makeENPSGroupings,
} from '~Insights/const/dataUtils';
import { ViewPerspective } from '~Insights/const/types';
import { ExecutiveENPSDataForDate } from '~Insights/hooks/useENPSData';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import { ExpandedProps, styles } from './Expanded';

function makeSidebarFilters(
  groupedData: Record<string, GroupedENPSData[]>,
  viewPerspective: ViewPerspective,
): SidebarFilterOptions {
  return Object.entries(groupedData).map(([name, surveys]) => ({
    value: name,
    renderContents: () => (
      <div css={styles.deltaContainer}>
        <p>{viewPerspective === ViewPerspective.DirectReports ? `${name}'s Direct Reports` : name}</p>
        <InsightsDelta
          value={makeDelta(surveys[0].scores.score, surveys[surveys.length - 1].scores.score)}
        />
      </div>
    ),
  }));
}

interface ENPSSectionViewProps extends Omit<ENPSSectionProps, 'data'> {
  barGraphData: {
    labels: string[];
    datasets: {
        data: number[];
        backgroundColor: string[];
    }[];
  },
  delta: number,
  graphData: GraphDataset[],
  maxDataPoints: number,
  mostRecentGraphData: GraphDataset,
  selectedSidebarFilters: string[],
  setSidebarFilters: Dispatch<SetStateAction<string[]>>,
  sidebarFilters: SidebarFilterOptions,
  surveyDates: Date[],
}

const ENPSSectionView = ({
  barGraphData,
  delta,
  graphData,
  isMobile = false,
  maxDataPoints,
  mostRecentGraphData,
  onSetDateRange,
  pickerValue,
  selectedSidebarFilters,
  setSidebarFilters,
  sidebarFilters,
  surveyDates,
  toggleMenuValue,
  userPerspective,
}: ENPSSectionViewProps): JSX.Element => (
  <>
    <div css={styles.main}>
      <div css={[styles.header(isMobile), styles.smallHeader]}>
        <h3 css={styles.subtitle}>eNPS</h3>
        <DateRangeSelect
          initialValue={pickerValue}
          dates={surveyDates}
          onChange={onSetDateRange}
          defaultMonthsBack={12}
        />
      </div>
      <div css={styles.expandedLineGraph}>
        {maxDataPoints === 1 && (
          userPerspective === UserPerspective.manager
          || toggleMenuValue === ViewPerspective.Company
        ) && (
          <>
            <div css={styles.chartContainer}>
              <GaugeChart
                value={mostRecentGraphData?.data[0].y}
              />
            </div>
            <div css={styles.overallScore}>
              {Math.floor(mostRecentGraphData?.data[0].y)}
            </div>
            <div css={styles.label}>
              {moment(mostRecentGraphData?.data[0].x).utc().format('MMMM D, YYYY')}
            </div>
          </>
        )}
        {maxDataPoints === 1 && userPerspective === UserPerspective.executive && toggleMenuValue !== ViewPerspective.Company && (
          <div css={styles.barChart}>
            <BarChart
              data={barGraphData}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: false,
                    min: 0,
                    max: 200,
                    title: {
                      text: 'eNPS',
                      display: true,
                    },
                    ticks: {
                      // @ts-expect-error : There's something funky about the typing here.
                      callback: (tick: number) => (tick - 100).toString(),
                    },
                  },
                  x: {
                    title: {
                      // text: 'Average eNPS for Each Manager\'s Direct Reports',
                      display: true,
                    },
                  },
                },
              }}
              tooltipOptions={{
                callbacks: {
                  // @ts-expect-error : There's something funky about the typing here.
                  label: (ctx: { parsed: { y: number; }; }) => (ctx?.parsed?.y ?? 0) - 100,
                  // @ts-expect-error : There's something funky about the typing here.
                  footer: (ctx: { raw: { participantInfo: string }}[]) => ctx[0]?.raw?.participantInfo ?? '',
                },
                footerMarginTop: 8,
              }}
            />
          </div>
        )}
        {maxDataPoints > 1 && (
          <LineGraphChart
            height="20rem"
            datasets={graphData}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  ticks: {
                    autoSkip: false,
                  },
                },
                y: {
                  min: -100,
                  max: 100,
                },
              },
            }}
            tooltipOptions={{
              callbacks: {
                // @ts-expect-error : There's something funky about the typing here.
                footer: (ctx: { raw: { participantInfo: string; }; }[]) => (
                  toggleMenuValue === ViewPerspective.Company ? ctx[0]?.raw?.participantInfo ?? '' : ''
                ),
              },
              footerMarginTop: 8,
            }}
          />
        )}
        {maxDataPoints === 0 && (
          <div css={styles.emptyState}>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <img src={insightsEmptyGraph} alt="Empty graph" />
            <p css={styles.emptyStateText}>
              Data will show here as your team answers the eNPS question, which, when enabled, is included every 3 months.
            </p>
          </div>
        )}
      </div>
    </div>
    <div css={styles.verticalDivider(isMobile)} />
    <div css={[styles.sidebar, styles.expandedSidebar(isMobile)]}>
      <>
        {maxDataPoints !== 0 ? (
          <>
            {(userPerspective === UserPerspective.manager || toggleMenuValue === ViewPerspective.Company) && (
              <>
                {maxDataPoints > 1 && (
                  <div>
                    <p>eNPS</p>
                    <InsightsDelta value={delta} />
                  </div>
                )}
                {maxDataPoints === 1 && (
                  <div>
                    <p>eNPS</p>
                    <p css={styles.bigNumber}>
                      {Math.floor(mostRecentGraphData?.data[0].y)}
                    </p>
                  </div>
                )}
              </>
            )}
            {userPerspective === UserPerspective.executive
              && toggleMenuValue !== ViewPerspective.Company
              && (
                <>
                  {sidebarFilters.length > 0 ? (
                    <SidebarFilters
                      selectedValues={selectedSidebarFilters}
                      options={sidebarFilters}
                      onChange={(values) => setSidebarFilters([...values] as string[])}
                    />
                  ) : (
                    <div>
                      <p>eNPS</p>
                      <InsightsDelta value="N/A" />
                    </div>
                  )}
                </>
              )}
          </>
        ) : (
          <div>
            <p>eNPS</p>
            <p css={styles.bigNumber}>
              N/A
            </p>
          </div>
        )}
      </>
    </div>
  </>
);

type ENPSSectionProps = Pick<ExpandedProps,
  'data'
  | 'isMobile'
  | 'onSetDateRange'
  | 'pickerValue'
  | 'surveyDates'
  | 'toggleMenuValue'
  | 'userPerspective'
>;

export const HookedENPSSection = ({
  data,
  toggleMenuValue,
  userPerspective,
  ...props
}: ENPSSectionProps): JSX.Element => {
  const groupedData = userPerspective === UserPerspective.executive ? makeENPSGroupings(data, toggleMenuValue) : {};
  const [selectedSidebarFilters, setSidebarFilters] = useState<string[]>([]);

  useEffect(() => {
    setSidebarFilters([]);
  }, [toggleMenuValue, userPerspective]);

  const filters = selectedSidebarFilters.length ? selectedSidebarFilters : Object.keys(groupedData);

  const graphData = makeENPSGraphData({
    dataSlice: data as ExecutiveENPSDataForDate[],
    groupedData,
    includedCategories: filters,
    userPerspective,
    viewPerspective: toggleMenuValue,
  });
  const mostRecentGraphData = graphData[graphData.length - 1] ?? {};
  const maxDataPoints = Math.max(...graphData.map((slice) => slice.data.length), 0);

  // Note -- because eNPS is -100 to 100, Chart.js chokes. This is a hack to make it display correctly.
  const barGraphData = maxDataPoints !== 1 ? { labels: [], datasets: [] } : {
    labels: graphData.map((slice) => slice.label),
    datasets: [{
      data: graphData.map((slice) => slice.data[0].y + 100),
      backgroundColor: Object.values(palette.brand),
    }],
  };

  const delta = data.length ? makeDelta(
    data[0].scores.score,
    data[data.length - 1].scores.score,
  ) : 0;

  const hookProps = {
    barGraphData,
    delta,
    graphData,
    groupedData,
    maxDataPoints,
    mostRecentGraphData,
    sidebarFilters: makeSidebarFilters(groupedData, toggleMenuValue),
    selectedSidebarFilters,
    setSidebarFilters,
    toggleMenuValue,
    userPerspective,
  };

  return (
    <ENPSSectionView
      {...hookProps}
      {...props}
    />
  );
};
