import { css } from '@emotion/react';

import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';
import { NewActionItemStatus } from '~ActionItems/const/interfaces';
import ActionItemStatus from '~ActionItems/components/ActionItemStatus';

const styles = {
  dropdown: css({
    border: 0,
    padding: '0.3125rem 1rem',

    '.MuiInput-root': {
      display: 'flex',
      alignItems: 'center',
    },

    '.MuiSelect-select': {
      lineHeight: '20px',
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 0,
      fontFamily: 'ProximaNova !important',
    },
  }),
  label: css({
    fontSize: '1rem !important',
    color: `${palette.neutrals.gray700} !important`,
    fontWeight: '500 !important',
    fontFamily: 'ProximaNova !important',
    letterSpacing: '0.5px',
    fontStyle: 'normal',
  }),
  menuItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
  statusIcon: (status: NewActionItemStatus) => {
    let color;

    switch (status) {
      case NewActionItemStatus.InProgress:
        color = palette.brand.orange;
        break;
      case NewActionItemStatus.Completed:
        color = palette.brand.green;
        break;
      case NewActionItemStatus.Blocked:
        color = palette.brand.red;
        break;
      default:
        color = palette.neutrals.gray400;
    }

    return css({
      fontSize: '0.75rem',
      marginRight: '0.375rem',
      color,
    });
  },
};

const View = ({
  items,
  ...props
}: DropdownProps<NewActionItemStatus>): JSX.Element => (
  <Dropdown
    css={styles.dropdown}
    label="Status"
    items={items}
    renderItem={(item: DropdownItem<NewActionItemStatus>) => (
      <div css={styles.menuItem}>
        <ActionItemStatus
          status={item.value}
        />
      </div>
    )}
    {...props}
  />
);

export interface ActionItemStatusDropdownProps extends Omit<DropdownProps<NewActionItemStatus>, 'items' | 'value'> {
  value?: DropdownProps<NewActionItemStatus>['value'],
}

const ActionItemStatusDropdown = ({
  value = NewActionItemStatus.ToDo,
  ...props
}: ActionItemStatusDropdownProps): JSX.Element => {
  // Text not needed due to renderItem method, but still required in type
  const items = [
    { value: NewActionItemStatus.ToDo, text: '' },
    { value: NewActionItemStatus.InProgress, text: '' },
    { value: NewActionItemStatus.Blocked, text: '' },
  ];

  if (value === NewActionItemStatus.Completed) {
    items.push({ value: NewActionItemStatus.Completed, text: '' });
  }

  const hookProps = {
    value,
    items,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItemStatusDropdown;
