import { produce } from 'immer';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';
import { HttpCallReturn, deleteApi } from '~Deprecated/services/HttpService';
import { meetingKeys } from '~Meetings/const/queryKeys';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { AgendaTopic, GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';

interface DeleteAgendaSectionParams {
  huddleId: string,
  sectionId: string,
}
interface DeleteAgendaSectionResponse {
  id: string,
}

export const deleteAgendaSection = ({ huddleId, sectionId }: DeleteAgendaSectionParams): Promise<HttpCallReturn<DeleteAgendaSectionResponse>> => (
  deleteApi(`/organizations/${getOrganizationId() ?? ''}/huddles/${huddleId}/agendas/sections/${sectionId}`)
);

export const useDeleteAgendaSection = (
  huddleId: string,
): UseMutationResult<HttpCallReturn<DeleteAgendaSectionResponse>, unknown, DeleteAgendaSectionParams> => (
  useMutation({
    mutationFn: deleteAgendaSection,
    onMutate: async ({ sectionId }) => {
      const previousAgendas = queryClient.getQueryData(meetingKeys.agendaList(huddleId));
      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(huddleId) });
      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId), (originalData) => {
        if (originalData) {
          const agendaTopicsInSection = (originalData.response.agendas.find((agenda) => (agenda.id === sectionId))?.children ?? []) as AgendaTopic[];
          const agendaSectionIndex = originalData.response.agendas.findIndex((agenda) => (agenda.id === sectionId));
          return produce(originalData, (draft) => {
            draft.response.agendas.splice(agendaSectionIndex, 1, ...agendaTopicsInSection);
          });
        }

        return originalData;
      });

      return { previousAgendas };
    },
    onError: (_, { sectionId }, snapshot) => {
      toast.error(<MeetingsErrorToast />, {
        autoClose: 1500,
      });

      queryClient.setQueryData(meetingKeys.agendaList(sectionId), snapshot?.previousAgendas);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaLists() });
    },
  })
);
