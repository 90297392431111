import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import FormDrawer from '~Common/components/Drawers/Forms/Form';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { useTabs } from '~Common/hooks/useTabs';
import { DRAWER_STATE_SHAPE, FEEDBACK_SHAPE } from '~Common/const/proptypes';

import { toast } from '~Common/components/Toasts';
import CreateButton from '~Common/components/Buttons/Create';
import { useFeedbackQuestions } from '~Deprecated/hooks/feedback/useFeedbackQuestions';
import { useAddQuestionsToTemplate, useCreateFeedbackTemplate } from '~Deprecated/hooks/feedback/useCreateFeedbackTemplate';
import TemplateDetails from '../Tabs/TemplateDetails';
import { createTemplateQuestionTemplate } from '../Questions/Create';

export const createTemplateDrawerTemplate = {
  name: 'CREATE_NEW_TEMPLATE',
  width: DRAWER_WIDTHS.PRIMARY,
};

const CreateTemplateDrawer = ({
  drawerState, pushDrawer, templateContext, setDrawerState, popAfter, ...props
}) => {
  const [templateToUse, setTemplateToUse] = useState(templateContext);
  const questionKey = 'newTemplateQuestions';

  const clearDrawerState = () => {
    setDrawerState({ ...drawerState, [questionKey]: null });
  };

  const addQuestionsMutation = useAddQuestionsToTemplate({ template: createTemplateDrawerTemplate, nextMutation: clearDrawerState });
  const createMutation = useCreateFeedbackTemplate({ drawerTemplate: createTemplateDrawerTemplate, nextMutation: addQuestionsMutation });

  const tabQueryConfig = {
    module: 'feedback',
    workflow: 'create_template',
    defaultValue: 0,
  };

  useEffect(() => {
    if (drawerState?.[questionKey]) {
      setTemplateToUse({
        ...templateToUse,
        question: drawerState[questionKey],
      });
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [drawerState]);

  const { onAddQuestion, onEditQuestion, onRemoveQuestion } = useFeedbackQuestions({
    setDrawerState,
    pushDrawer,
    drawerState,
    popAfter,
    drawerName: createTemplateDrawerTemplate.name,
    existingQuestions: templateToUse?.question,
    questionKey: 'newTemplateQuestions',
    addQuestionTemplate: createTemplateQuestionTemplate,
  });

  const tabs = [
    ['Details', <TemplateDetails
      isReadOnly={false}
      template={templateToUse}
      onEditQuestion={onEditQuestion}
      onRemoveQuestion={onRemoveQuestion}
      isCreating
    />],
  ];

  const { activeTab, setActiveTab } = useTabs(tabQueryConfig);

  const onSubmit = (e) => {
    // Questions Tab
    if (drawerState?.[questionKey].length === 0) {
      toast.error('You must add at least one question for people to respond to.');
      return;
    }

    const formData = new FormData(e.target);
    const newTemplate = Object.fromEntries(formData.entries());

    createMutation({
      template: newTemplate,
      questions: drawerState[questionKey],
    });
  };

  const hookProps = {
    drawerName: createTemplateDrawerTemplate.name,
    isReadOnly: false,
    drawerTitle: 'Create Feedback Template',
    showNextButton: activeTab + 1 < tabs.length,
    onNextClick: () => {
      setActiveTab(activeTab + 1);
    },
    onSubmit,
    tabs,
    tabQueryConfig,
    formName: 'createFeedbackTemplate',
    renderBottomButtons: () => (
      <CreateButton
        text="Add Question"
        onClick={onAddQuestion}
      />
    ),
  };

  return (
    <FormDrawer
      {...hookProps}
      {...props}
    />
  );
};

CreateTemplateDrawer.propTypes = {
  pushDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
  popAfter: PropTypes.func.isRequired,
  templateContext: FEEDBACK_SHAPE,
};

CreateTemplateDrawer.defaultProps = {
  templateContext: null,
};

registerDrawer({ templateName: createTemplateDrawerTemplate.name, component: CreateTemplateDrawer });

export default CreateTemplateDrawer;
