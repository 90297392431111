import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';

export const useHasNexus = (): boolean => {
  const {
    isCustomerSuccessAdmin,
    isCustomerSuccessManager,
    isImpersonator,
    isBenefitsSpecialist,
  } = useOrgUserPermissionsContext();

  return isCustomerSuccessAdmin || isCustomerSuccessManager || isImpersonator || isBenefitsSpecialist;
};

export const useHasCSPermissions = (): boolean => {
  const {
    isCustomerSuccessAdmin,
    isCustomerSuccessManager,
  } = useOrgUserPermissionsContext();

  return isCustomerSuccessAdmin || isCustomerSuccessManager;
};
