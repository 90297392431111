import { css } from '@emotion/react';
import { ComponentProps } from 'react';

const styles = {
  banner: css({
    display: 'flex',
    borderRadius: '.3125rem',
    alignItems: 'center',
    padding: '1rem',
  }),
};

export interface BannerProps extends ComponentProps<'div'> {
  renderContents: () => JSX.Element,
}

const Banner = ({ renderContents, ...props }: BannerProps): JSX.Element => (
  <div css={styles.banner} {...props}>
    {renderContents()}
  </div>
);

export default Banner;
