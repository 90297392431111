import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

const styles = {
  // Please note that this sets the height but the overall height is set in the parent component <DataGridTable>
  emptyStateImage: css({
    height: '300px',
  }),
};

const StyledGridOverlay = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

interface CustomNoRowsOverlayProps {
  image: string;
  altText: string;
  bodyText: string;
}
export function CustomNoRowsOverlay({
  image,
  altText,
  bodyText,
}: CustomNoRowsOverlayProps): JSX.Element {
  return (
    <StyledGridOverlay>
      <img
        css={styles.emptyStateImage}
        // ToDo: Remove once default image typing is fixed
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        src={image}
        alt={altText}
      />
      <Box sx={{ marginTop: '1rem' }}>{bodyText}</Box>
    </StyledGridOverlay>
  );
}
