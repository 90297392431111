import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { getHost } from '~Deprecated/services/config';
import { cloneDeep } from 'lodash';
import { AgendaSection as AgendaSectionType, AgendaTopic, GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';
import { AgendaType } from '@leadr-hr/types';
import { useAgendaPermissionsStore } from '../utils/useAgendaPermissions';

export enum ParentType { // TODO: Grab this from the "@leadr-hr/types" package
  MeetingInstance = 'MEETING_INSTANCE',
  AgendaSection = 'AGENDA_SECTION',
}

export interface AgendaItemPosition {
  parentId: string,
  parentType: ParentType,
  index: number,
}

export interface ReorderAgendaItemRequest {
  id: string,
  type: AgendaType,
  before: AgendaItemPosition,
  after: AgendaItemPosition,
  // Optional fields
  eventId?: string,
}

interface ReorderAgendaItemParams {
  huddleId: string,
  reorderAgendaItemRequest: ReorderAgendaItemRequest,
}

interface ReorderAgendaItemResponse {
  id: string,
}

const reorderAgendaItem = async ({
  huddleId,
  reorderAgendaItemRequest,
}: ReorderAgendaItemParams): Promise<HttpCallReturn<ReorderAgendaItemResponse>> => {
  const url = {
    host: getHost('', '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/huddles/${huddleId}/agendas/reorder`,
  };

  return patchApi(url, reorderAgendaItemRequest);
};

export const useReorderAgendaItem = (sectionId?: string): UseMutationResult<HttpCallReturn<ReorderAgendaItemResponse>, unknown, ReorderAgendaItemParams> => {
  const orgId = getOrganizationId() ?? '';

  const {
    disableSectionWorkflows,
    enableSectionWorkflows,
    disableWorkflows,
    enableWorkflows,
  } = useAgendaPermissionsStore((state) => ({
    disableSectionWorkflows: state.disableSectionWorkflows,
    enableSectionWorkflows: state.enableSectionWorkflows,
    disableWorkflows: state.disableWorkflows,
    enableWorkflows: state.enableWorkflows,
  }));

  return useMutation({
    mutationFn: reorderAgendaItem,
    onMutate: async ({ huddleId, reorderAgendaItemRequest }) => {
      const {
        before,
        after,
        id: agendaItemId,
        type,
      } = reorderAgendaItemRequest;
      const previousAgendas = queryClient.getQueryData(meetingKeys.agendaList(huddleId));

      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      if (type === AgendaType.AgendaSection) {
        disableSectionWorkflows(agendaItemId);
      } else if (type === AgendaType.AgendaTopic) {
        disableWorkflows(agendaItemId, sectionId);
      }

      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId), (originalData) => {
        if (originalData) {
          const oldAgenda = originalData.response.agendas;
          const agendaResponse = cloneDeep(originalData);
          const newAgenda = agendaResponse.response.agendas;
          if (after.parentType === ParentType.MeetingInstance) {
            // Moving an item that started in the top level
            if (before.parentType === ParentType.MeetingInstance) {
              // Moving an item from the top level to the top level
              newAgenda.splice(before.index, 1);
              newAgenda.splice(after.index, 0, oldAgenda[before.index]);
            } else {
              // Moving an item from an agenda section to the top level
              const beforeParentId = before.parentId;
              const beforeAgendaSection = newAgenda.find((agenda) => agenda.id === beforeParentId) as AgendaSectionType;
              const movedAgendaItem = beforeAgendaSection?.children[before.index];
              // Remove the agenda item from the agenda section
              beforeAgendaSection?.children.splice(before.index, 1);
              // Add the agenda Item to the top level
              newAgenda.splice(after.index, 0, movedAgendaItem);
            }
          } else if (before.parentType === ParentType.MeetingInstance) {
            // Moving an item from the top level to an agenda section
            const movedAgendaItem = newAgenda[before.index] as AgendaTopic;
            const afterParentId = after.parentId;
            const afterAgendaSection = newAgenda.find((agendaSection) => agendaSection.id === afterParentId) as AgendaSectionType;

            newAgenda.splice(before.index, 1);
            afterAgendaSection?.children.splice(after.index, 0, movedAgendaItem);
          } else {
            // Moving an item from an agenda section to an agenda section
            // Get the source and agenda sections for mutating
            const beforeParentId = before.parentId;
            const afterParentId = after.parentId;
            const beforeAgendaSection = newAgenda.find((agendaSection) => agendaSection.id === beforeParentId) as AgendaSectionType;
            const afterAgendaSection = newAgenda.find((agendaSection) => agendaSection.id === afterParentId) as AgendaSectionType;

            // Grab the moved agenda item
            const movedAgendaItem = beforeAgendaSection?.children[before.index];

            // Update the source agenda section and the destination section to remove the moved agenda item and add it to the destination section
            beforeAgendaSection?.children.splice(before.index, 1);
            afterAgendaSection?.children.splice(after.index, 0, movedAgendaItem);
          }

          return agendaResponse;
        }

        return originalData;
      });

      return { previousAgendas };
    },
    onError: (_, { huddleId }, snapshot) => {
      toast.error('Failed to reorder the agenda item. Please try again.');
      queryClient.setQueryData(meetingKeys.agendaList(huddleId), snapshot?.previousAgendas);
    },
    onSettled: async (_, __, { huddleId, reorderAgendaItemRequest }) => {
      await queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(orgId) });
      await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      const { id: agendaItemId, type } = reorderAgendaItemRequest;

      if (type === AgendaType.AgendaSection) {
        enableSectionWorkflows(agendaItemId);
      } else if (type === AgendaType.AgendaTopic) {
        enableWorkflows(agendaItemId, sectionId);
      }
    },
  });
};
