import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { faBookmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as faSolidBookMark } from '@fortawesome/pro-solid-svg-icons';
import { JSX } from '@emotion/react/jsx-runtime';
import { palette } from '~Common/styles/colors';
import { css } from '@emotion/react';

const styles = {
  checkboxIcon: css({
    color: `${palette.neutrals.gray700} !important`,
  }),
  checkboxIconSolid: css({
    color: `${palette.brand.indigo} !important`,
  }),
};

// a wrapper class for material ui checkbox
// Since you are just using the mui checkbox, simply pass all the props through to restore functionality.
function CheckboxWrapper(props: JSX.IntrinsicAttributes & CheckboxProps): JSX.Element {
  return (
    <Checkbox
      icon={(
        <FontAwesomeIcon
          icon={faBookmark}
          css={styles.checkboxIcon}
        />
      )}
      checkedIcon={(
        <FontAwesomeIcon
          icon={faSolidBookMark}
          css={styles.checkboxIconSolid}
        />
      )}
      {...props}
    />
  );
}

export default CheckboxWrapper;
