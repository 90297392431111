function createReducer(name, initialState) {
  const typeToHandler = {};

  function reducer(state = initialState, action) {
    const handler = typeToHandler[action.type];

    return handler ? handler(state, action, initialState) : state;
  }

  function registerAction(type, handler) {
    typeToHandler[type] = handler;
  }

  return {
    registerAction,
    reducer,
  };
}

const createRootReducer = () => {};
const initRootReducer = () => {};

export { createReducer, createRootReducer, initRootReducer };
