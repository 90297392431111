import { RefObject, useRef, useState } from 'react';
import AgendaSectionMenuButton from '~Meetings/components/shared/AgendaSection/AgendaSectionMenuButton';
import { useAgendaPermissions } from '~Meetings/hooks/utils/useAgendaPermissions';
import { css } from '@emotion/react';
import Menu from './Menu';

const styles = {
  agendaSectionMenuButton: css({
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

interface ViewProps {
  handleOpenMenuClick: () => void,
  handleCloseMenuClick: () => void,
  handleAddTopicClick: () => void,
  menuButtonRef: RefObject<HTMLButtonElement>,
  numberOfAgendaItems: number,
  isMenuOpen: boolean,
  huddleId: string,
  sectionId: string,
  text: string,
  disableOverflowMenu: boolean,
  isRecurring: boolean,
}

const View = ({
  handleOpenMenuClick,
  handleCloseMenuClick,
  handleAddTopicClick,
  menuButtonRef,
  numberOfAgendaItems,
  isMenuOpen,
  huddleId,
  sectionId,
  text,
  disableOverflowMenu,
  isRecurring,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <AgendaSectionMenuButton
      css={styles.agendaSectionMenuButton}
      handleOpenMenuClick={handleOpenMenuClick}
      ref={menuButtonRef}
      data-test-id="meetingsAgendaSectionOpenKebab"
      data-agenda-section-id={sectionId}
      disabled={disableOverflowMenu}
    />
    <Menu
      huddleId={huddleId}
      sectionId={sectionId}
      text={text}
      anchorEl={menuButtonRef.current}
      numberOfAgendaItems={numberOfAgendaItems}
      isMenuOpen={isMenuOpen}
      handleCloseMenuClick={handleCloseMenuClick}
      handleAddTopicClick={handleAddTopicClick}
      isRecurring={isRecurring}
    />
  </div>
);

interface AgendaSectionMenuProps {
  numberOfAgendaItems: number,
  huddleId: string,
  sectionId: string,
  text: string,
  isRecurring: boolean,
  handleAddTopicClick: () => void,
}

const AgendaSectionMenu = ({
  huddleId,
  sectionId,
  ...props
}: AgendaSectionMenuProps): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const { getSectionPermissions } = useAgendaPermissions(huddleId);
  const { areWorkflowsDisabled } = getSectionPermissions(sectionId);

  const handleOpenMenuClick = (): void => {
    setIsMenuOpen(true);
  };

  const handleCloseMenuClick = (): void => {
    setIsMenuOpen(false);
  };

  const hookProps = {
    handleOpenMenuClick,
    handleCloseMenuClick,
    isMenuOpen,
    menuButtonRef,
    huddleId,
    sectionId,
    disableOverflowMenu: areWorkflowsDisabled,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaSectionMenu;
