import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { Goals } from '@leadr-hr/types';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { homeQueryKeys } from '~Home/hooks/queryKeys';
import { goalKeys } from '../const/queryKeys';

interface EditGoalProps {
  payload: Goals.Requests.UpdateGoalRequestPayload,
  goalId: string,
}

const createGoal = ({ payload, goalId }: EditGoalProps): Promise<HttpCallReturn<string>> => {
  const serverUrl = {
    host: getHost(hosts.goals, '3'),
    uri: `/organizations/${getOrganizationId() ?? ''}/goals/${goalId}`,
  };

  return patchApi<string>(serverUrl, { ...payload }, {});
};

export const useEditGoal = (): UseMutationResult<HttpCallReturn<string>, unknown, EditGoalProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const mutation = useMutation({
    mutationFn: createGoal,
    onMutate: () => {
      toastId.current = toast.info('Editing your goal...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error editing your goal. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully edited your goal.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: goalKeys.all });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
      void queryClient.invalidateQueries({ queryKey: homeQueryKeys.homeGoals(getOrganizationId() ?? '') });
    },
  });

  return mutation;
};
