import { css } from '@emotion/react';
import { Card, Collapse } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { surveyQuestionPreviewColors, palette } from '~Common/styles/colors';

const styles = {
  container: css`
    padding: 1rem 1.5rem;
    border-radius: 5px;
    background: ${palette.neutrals.white};
    box-shadow: 0px 4px 8px -2px rgba(28, 42, 55, 0.2);
    margin-bottom: 1.5rem;
  `,
  header: css`
    display: flex; 
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 0.625rem;
    padding-bottom: 10px;
  `,
  questionPreviewText: css`
    color: ${surveyQuestionPreviewColors.previewText};
  `,
  hidePreviewButton: css`
    color: ${surveyQuestionPreviewColors.hidePreviewText};
  `,
};

const View = ({
  showPreview, setShowPreview, renderBody, ...props
}) => (
  <Card css={styles.container}>
    <Collapse collapsedSize={15} in={showPreview} {...props}>
      <div css={styles.header}>
        <span css={styles.questionPreviewText}>Question Preview</span>
        <div
          css={styles.hidePreviewButton}
          onClick={setShowPreview}
          onKeyUp={setShowPreview}
          role="button"
          tabIndex={0}
        >
          {showPreview ? 'Hide Preview' : 'Show Preview'}
        </div>
      </div>
      {renderBody && renderBody()}
    </Collapse>
  </Card>
);

View.propTypes = {
  showPreview: PropTypes.bool.isRequired,
  setShowPreview: PropTypes.func.isRequired,
  renderBody: PropTypes.func,
};

View.defaultProps = {
  renderBody: null,
};

const QuestionPreview = ({ ...props }) => {
  const [showPreview, setShowPreview] = useState(false);

  const hookProps = {
    showPreview,
    setShowPreview: () => setShowPreview(!showPreview),
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

QuestionPreview.propTypes = {};

QuestionPreview.defaultProps = {};

export { View };
export default QuestionPreview;
