import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionItemStatus from '~ActionItems/components/ActionItemStatus';
import { NewActionItemStatus } from '~ActionItems/const/interfaces';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import DueDate, { DueDateProps } from '~Meetings/components/details/action/DueDate';
import Comments from '~Meetings/components/details/action/Comments';

const styles = {
  footer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '1.125rem',
  }),
  commentContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  }),
  commentCount: css({
    fontSize: '0.75rem',
    color: palette.neutrals.gray700,
  }),
  commentIcon: css({
    fontSize: '0.625rem',
    color: palette.neutrals.gray700,
  }),
  recurrenceIcon: css({
    color: palette.neutrals.gray500,
    marginLeft: '0.375rem',
  }),
};

interface FooterProps extends DueDateProps {
  status: NewActionItemStatus,
  commentCount: number,
  isRecurring: boolean,
}

const Footer = ({
  status,
  commentCount,
  isRecurring,
  dueDateInMillis,
  completedInMillis,
  ...props
}: FooterProps): JSX.Element => (
  <div css={styles.footer} {...props}>
    <DueDate
      dueDateInMillis={dueDateInMillis}
      completedInMillis={completedInMillis}
      status={status}
    />
    <ActionItemStatus status={status} />
    {commentCount > 0 && (
      <Comments commentCount={commentCount} />
    )}
    {isRecurring && (
      <FontAwesomeIcon css={styles.recurrenceIcon} icon={faArrowsRepeat} flip="horizontal" />
    )}
  </div>
);

export default Footer;
