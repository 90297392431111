import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';

export const NO_ACCESS_TOKEN = -1;

export interface Tokens {
  jsonWebToken: string,
  jsonRefreshToken: string,
}

export const getTokensFromCookie = (): Tokens => ({
  jsonWebToken: Cookies.get('jwt') as string,
  jsonRefreshToken: Cookies.get('jwtRT') as string,
});

export const getToken = (): string | null | undefined => {
  // Check if the user is being impersonated. If so, get the token from sessionStorage, which is unique for each tab.
  if (window.sessionStorage.getItem('sessionId')) {
    return window.sessionStorage.getItem('impersonationAccessToken');
  }

  return Cookies.get('jwt');
};

export const setToken = (accessToken: string, refreshToken: string): void => {
  // Check if the user is being impersonated. If so, store the tokens in a sessionStorage, which is unique for each tab.
  if (window.sessionStorage.getItem('sessionId')) {
    window.sessionStorage.setItem('impersonationAccessToken', accessToken);
    window.sessionStorage.setItem('impersonationRefreshToken', refreshToken);
    return;
  }
  const { exp: accessTokenExp = 0 } = decode(accessToken, { json: true }) || { exp: 0 };
  const tokenExpirationInMilliseconds = accessTokenExp * 1000;

  Cookies.set('jwt', accessToken, { path: '/', expires: (new Date(tokenExpirationInMilliseconds)) }); // Access token
};

export const getRefreshToken = (): string | null | undefined => {
  // Check if the user is being impersonated. If so, get the token from sessionStorage, which is unique for each tab.
  if (window.sessionStorage.getItem('sessionId')) {
    return window.sessionStorage.getItem('impersonationRefreshToken');
  }
  return Cookies.get('jwtRT');
};

export const setRefreshToken = (refreshToken: string): void => {
  // Check if the user is being impersonated. If so, store the tokens in a sessionStorage, which is unique for each tab.
  if (window.sessionStorage.getItem('sessionId')) {
    window.sessionStorage.setItem('impersonationRefreshToken', refreshToken);
    return;
  }
  const { exp: refreshTokenExp = 0 } = decode(refreshToken, { json: true }) || { exp: 0 };
  const tokenExpirationInMilliseconds = refreshTokenExp * 1000;

  Cookies.set('jwtRT', refreshToken, { path: '/', expires: (new Date(tokenExpirationInMilliseconds)) }); // Refresh token
};
