import {
  ChangeEvent,
  Dispatch, SetStateAction, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { SelectChangeEvent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter as faFilterLight } from '@fortawesome/pro-light-svg-icons';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';

import { LeadrCoachPrompt, translateLeadrCoachCategory } from '~Insights/hooks/useLeadrCoachPrompts';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { palette } from '~Common/styles/colors';
import { CARD_STYLES, EXPANDED_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import Card from '~Common/V3/components/Card';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import ShrinkButton from '~Insights/components/buttons/ShrinkButton';
import InsightCardTitle from '~Insights/components/InsightCardTitle';
import UserGroupsPagination from '~UserGroups/components/UserGroupsPage/UserGroupsPagination';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import Button from '~Common/V3/components/Button';
import { leadrCoachFiltersTemplate } from './FilterDrawer';
import LeadrCoachPromptRow from './LeadrCoachPromptRow';
import { SharedProps } from './index';

const PAGE_SIZE = 5;

const styles = {
  expanded: css({
    ...CARD_STYLES.expanded,
    padding: '0',
  }),
  ...EXPANDED_CARD_COMPONENTS,
  body: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  }),
  expandedUl: css({
    display: 'flex',
    flexDirection: 'column',
    margin: '0',
    padding: '0',
    paddingTop: '1.5rem',
    rowGap: '1.5rem',
  }),
  filterButton: css({
    borderColor: palette.neutrals.gray300,
    borderRadius: '.3125rem',
    color: palette.brand.indigo,
    height: '2.8125rem',
    width: '2.8125rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

interface ExpandedProps extends SharedProps {
  areFiltersActive: boolean,
  currentPage: number,
  onPageChange: (e: SelectChangeEvent) => void,
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
  openFilterDrawer: () => void,
  pageCount: number,
  searchString: string,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  isMobile: boolean,
}

const ExpandedView = ({
  areFiltersActive,
  currentPage,
  pageCount,
  prompts,
  onPageChange,
  onSearch,
  openFilterDrawer,
  searchString,
  setCurrentPage,
  isMobile,
}: ExpandedProps): JSX.Element => (
  <Card
    css={styles.expanded}
    renderContents={() => (
      <div css={styles.expandedContainer}>
        <div css={styles.header(false)}>
          <ShrinkButton css={styles.expandButton(false)} cardKey="coach" />
          <div css={styles.titleAndSubtitle(false)}>
            <InsightCardTitle>Leadr Coach</InsightCardTitle>
            <p>Leadr Coach helps you take action on the feedback received from your engagement scores.</p>
          </div>
          <LeadrSearchField data-test-id="insightsExpandedCardSearchField" onChange={onSearch} defaultValue={searchString} />
          <Button
            css={styles.filterButton}
            variant="outline"
            color="secondary"
            onClick={openFilterDrawer}
            renderContents={() => (
              <FontAwesomeIcon
                size="lg"
                icon={areFiltersActive ? faFilterSolid : faFilterLight}
              />
            )}
          />
        </div>
        <div css={styles.expandedCushion} />
        <div css={styles.horizontalDivider} />
        <div css={styles.body}>
          {prompts.length > 0 && (
            <>
              <ul css={styles.expandedUl}>
                {prompts.map((prompt) => (
                  <Card
                    key={prompt.id}
                    renderContents={() => (
                      <LeadrCoachPromptRow
                        prompt={prompt}
                        isMobile={isMobile}
                      />
                    )}
                  />
                ))}
              </ul>
              <UserGroupsPagination
                page={currentPage}
                onPageChange={onPageChange}
                numberOfPages={pageCount}
                onPreviousClick={() => setCurrentPage(currentPage - 1)}
                onNextClick={() => setCurrentPage(currentPage + 1)}
              />
            </>
          )}
          {!prompts.length && (
            <div css={styles.emptyState}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <img src={insightsEmptyGraph} alt="Empty graph" />
              <p css={styles.emptyStateText}>
                Data will show here after the first engagement survey is complete.
              </p>
            </div>
          )}
        </div>
      </div>
    )}
  />
);

const HookedExpanded = ({
  prompts,
  isMobile,
  ...props
}: SharedProps): JSX.Element => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategories] = useQueryParamState<string[]>('insights', 'categories', []);

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchString(event.target.value);
  };

  const onPageChange = (e: SelectChangeEvent): void => setCurrentPage(Number(e.target.value));

  const openFilterDrawer = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...leadrCoachFiltersTemplate,
      },
    }));
  };

  const areFiltersActive = selectedCategories.length > 0;

  function makeFilteredPrompts(): LeadrCoachPrompt[] {
    if (searchString === '' && !areFiltersActive) return prompts;

    const result: LeadrCoachPrompt[] = [];
    prompts.forEach((prompt) => {
      if (searchString !== '' && !prompt.prompt.prompt.toLowerCase().includes(searchString.toLowerCase())) return;
      if (areFiltersActive) {
        const translatedCategory = translateLeadrCoachCategory(prompt.prompt.category);
        if (!selectedCategories.includes(translatedCategory?.toLowerCase() as string)) return;
      }

      result.push(prompt);
    });

    return result;
  }

  const filteredPrompts = makeFilteredPrompts();
  const start = (currentPage - 1) * PAGE_SIZE;
  const pageCount = Math.ceil(filteredPrompts.length / PAGE_SIZE);
  const pageSlice = filteredPrompts.slice(start, start + PAGE_SIZE);

  const hookProps = {
    areFiltersActive,
    currentPage,
    onPageChange,
    onSearch,
    openFilterDrawer,
    pageCount,
    prompts: pageSlice,
    searchString,
    setCurrentPage,
    isMobile,
  };

  return (
    <ExpandedView
      {...hookProps}
      {...props}
    />
  );
};

export { ExpandedView, HookedExpanded };
