import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { UserPendingItems, USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import {
  AssignedLearning,
  LearningStatus,
  ReceivedLearning,
  AssignedPlaylistDetails,
} from '~Learning/const/interfaces';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { assignedPlaylistDetailDrawerTemplate } from '~Learning/components/AssignedLearningDashboard/AssignedPlaylistDetailDrawer';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { receivedLearningQueryKeys } from '../received/queryKeys';
import { assignedLearningQueryKeys } from './queryKeys';

interface RecallPlaylistParams {
  userIds: string[],
  playlistId: number,
  isRecallingFromAll: boolean,
}

const recallPlaylist = async ({ userIds, playlistId }: RecallPlaylistParams): Promise<HttpCallReturn<boolean>> => {
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/playlist/recall/${playlistId}`;

  return patchApi<boolean>(serverUrl, { userIds });
};

export const useRecallPlaylist = (): UseMutateFunction<HttpCallReturn<boolean>, unknown, RecallPlaylistParams> => {
  const dispatch = useDispatch();
  const currentUserId = getUserId() ?? ''; // User id of the user that is signed in

  const mutation = useMutation({
    mutationFn: recallPlaylist,
    onMutate: async (playlistToRecall: RecallPlaylistParams) => {
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.playlistDetail(playlistToRecall.playlistId) });

      const previousReceivedLearningList = queryClient.getQueryData(receivedLearningQueryKeys.list());
      const previousAssignedLearningList = queryClient.getQueryData(assignedLearningQueryKeys.list());
      const previousUserPendingItems = queryClient.getQueryData(USER_PENDING_ITEMS_QUERY_KEY);
      const previousAssignedPlaylistDetail = queryClient.getQueryData<HttpCallReturn<AssignedPlaylistDetails>>(
        assignedLearningQueryKeys.playlistDetail(playlistToRecall.playlistId),
      );

      // Recall All or Recall Last Person individually workflow
      if (playlistToRecall.isRecallingFromAll) {
        // Close all of the open drawers
        // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
        dispatch(popDrawerAction({ popAll: true }));

        // Remove the playlist from the assigned learning list
        queryClient.setQueryData(
          assignedLearningQueryKeys.list(),

          (oldAssignedLearningList?: AssignedLearning[]) => oldAssignedLearningList?.filter(
            (assignedPlaylist) => assignedPlaylist.id !== playlistToRecall.playlistId.toString(),
          ),
        );
      } else { // Recall from individual workflow
        queryClient.setQueryData<HttpCallReturn<AssignedPlaylistDetails>>(
          assignedLearningQueryKeys.playlistDetail(playlistToRecall.playlistId),
          (oldAssignedPlaylistDetail) => {
            if (oldAssignedPlaylistDetail) {
              const newAssignedPlaylistDetail = cloneDeep(oldAssignedPlaylistDetail);
              newAssignedPlaylistDetail.response.assignedUsersInfo = newAssignedPlaylistDetail.response.assignedUsersInfo.filter(
                (assignee) => !playlistToRecall.userIds.includes(assignee.assignee.id),
              );

              newAssignedPlaylistDetail.response.numberOfUsersAssigned -= 1;

              // Handle a case where you recalled the playlist from the last INCOMPLETE participant
              if (newAssignedPlaylistDetail.response.assignedUsersInfo.every((assignee) => assignee.status === LearningStatus.COMPLETED)) {
                newAssignedPlaylistDetail.response.status = LearningStatus.COMPLETED;
              }

              return newAssignedPlaylistDetail;
            }

            return oldAssignedPlaylistDetail;
          },
        );

        // Update the playlist card in the assigned learning list
        queryClient.setQueryData<AssignedLearning[]>(
          assignedLearningQueryKeys.list(),
          (oldAssignedLearningList) => {
            if (oldAssignedLearningList) {
              const newAssignedLearningList = cloneDeep(oldAssignedLearningList);

              const indexOfAssignedLearningToUpdate = newAssignedLearningList?.findIndex(
                (assignedPlaylist) => assignedPlaylist.id === playlistToRecall.playlistId.toString(),
              );

              const updatedAssignedPlaylist = cloneDeep(newAssignedLearningList[indexOfAssignedLearningToUpdate]);
              updatedAssignedPlaylist.assignedUsersInfo = updatedAssignedPlaylist.assignedUsersInfo.filter(
                (assignedUser) => !playlistToRecall.userIds.includes(assignedUser.id),
              );

              updatedAssignedPlaylist.numberOfUsersAssigned -= 1;

              // Handle a case where you recalled the playlist from the last INCOMPLETE participant
              if (updatedAssignedPlaylist.numberOfUsersAssigned === updatedAssignedPlaylist.numberOfUsersCompleted) {
                updatedAssignedPlaylist.status = LearningStatus.COMPLETED;
              }

              newAssignedLearningList.splice(indexOfAssignedLearningToUpdate, 1, updatedAssignedPlaylist);

              return newAssignedLearningList;
            }

            return oldAssignedLearningList;
          },
        );
      }

      // If the current signed in user is in the list of userIds to recall from, remove the playlist from the received learning list and decrement the pendingUserItems for learning
      if (playlistToRecall.userIds.includes(currentUserId)) {
        queryClient.setQueryData<ReceivedLearning[]>(
          receivedLearningQueryKeys.list(),
          (oldReceivedLearningList) => oldReceivedLearningList?.filter(
            (receivedPlaylist) => receivedPlaylist.id !== playlistToRecall.playlistId.toString(),
          ),
        );

        queryClient.setQueryData<HttpCallReturn<UserPendingItems>>(USER_PENDING_ITEMS_QUERY_KEY, (oldUserPendingItems) => {
          if (oldUserPendingItems) {
            const newUserPendingItems = cloneDeep(oldUserPendingItems);

            if (newUserPendingItems && previousAssignedPlaylistDetail) {
              newUserPendingItems.response.pendingLearningCount -= previousAssignedPlaylistDetail.response.numberOfLearnings;
            }

            return newUserPendingItems;
          }

          return oldUserPendingItems;
        });
      }

      // Return a context object with the old snapshotted values
      return {
        previousReceivedLearningList,
        previousAssignedLearningList,
        previousUserPendingItems,
        previousAssignedPlaylistDetail,
      };
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: assignedLearningQueryKeys.list() });
      // Invalidate the learning notification dot and received learning list, since you can send a learning to yourself :)
      await queryClient.invalidateQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
    onError: (_, playlistToRecall, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(receivedLearningQueryKeys.list(), snapshot?.previousReceivedLearningList);
      queryClient.setQueryData(assignedLearningQueryKeys.list(), snapshot?.previousAssignedLearningList);
      queryClient.setQueryData(USER_PENDING_ITEMS_QUERY_KEY, snapshot?.previousUserPendingItems);
      queryClient.setQueryData(assignedLearningQueryKeys.playlistDetail(playlistToRecall.playlistId), snapshot?.previousAssignedPlaylistDetail);

      // Recall All or Recall Last person individually workflow
      if (playlistToRecall.isRecallingFromAll) {
        // Reopen the assigned playlist detail drawer
        dispatch(pushDrawerAction({
          drawer: {
            ...assignedPlaylistDetailDrawerTemplate,
            args: {
              playlistId: playlistToRecall.playlistId,
            },
          },
        }));
      }

      toast.error('There was an error recalling the playlist. Please try again.', {
        autoClose: 1500,
      });
    },
  });

  return mutation.mutate;
};
