import { SyntheticEvent, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import {
  ACTION_ITEMS_COMPLETED,
  ACTION_ITEMS_OPEN,
} from '~Common/const/routes';

import { navigateAction } from '~Deprecated/actions/navigate';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';
import { SerializedStyles } from '@emotion/react';

const actionItemTabs = [
  {
    value: ACTION_ITEMS_OPEN,
    text: 'Open',
    'data-test-id': 'actionItemsOpen',
  },
  {
    value: ACTION_ITEMS_COMPLETED,
    text: 'Completed',
    'data-test-id': 'actionItemsCompleted',
  },
];

interface ViewProps {
  value: string,
  onChange: (event: SyntheticEvent, route: string) => void,
  toggleTabStyles: SerializedStyles,
}

const View = ({
  toggleTabStyles,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrToggleTabs {...props}>
    {actionItemTabs.map((actionItemTab) => (
      <LeadrToggleTabs.TextTab
        css={toggleTabStyles}
        data-test-id={actionItemTab['data-test-id']}
        key={actionItemTab.value}
        text={actionItemTab.text}
        value={actionItemTab.value}
      />
    ))}
  </LeadrToggleTabs>
);

interface ActionItemsTabNavigationProps extends Omit<ViewProps, 'value' | 'onChange'> {
  initialRoute: string,
}

const ActionItemsTabNavigation = ({ initialRoute, ...props }: ActionItemsTabNavigationProps): JSX.Element => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(initialRoute);

  useEffect(() => {
    setValue(initialRoute);
  }, [initialRoute]);

  const onChange = (event: SyntheticEvent, route: string): void => {
    if (route) {
      dispatch(navigateAction({ pathname: route }));
    }
  };

  const hookProps = {
    value,
    onChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItemsTabNavigation;
