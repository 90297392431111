import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  drawerInputText,
  drawerInputBorder,
  drawerInputLabelText,
  drawerInputDisabled,
} from '~Deprecated/ui/styles/colors';
import { FONT_AWESOME_ICON } from '~Common/const/proptypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

const boxStyle = css`
  background: ${drawerInputDisabled};
  border: 1px solid ${drawerInputBorder};
  padding: 7px 16px;
  border: unset;
  border-radius: 8px;
  color: ${drawerInputText};
  font-size: 12px;
  line-height: 2em;
  display: inline-block;
  align-items: center;
`;

const labelStyle = css`
  color: ${drawerInputLabelText};
  text-transform: uppercase;
`;

const valueStyle = css`
  font-size: 16px;
  font-weight: 700;
  min-height: 30px;
  padding-top: 3px;
  word-break: break-word;
`;

const inputWrapper = css`
  display: flex;
  overflow-wrap: anywhere;

  & svg {
    position: relative;
    top: 6px;
    margin-right: 10px;
    stroke: ${drawerInputText};
  }
`;

const widths = {
  halfRow: css`
    width: 49%;
    margin-right: 1%;
  `,
  halfRowNoMargin: css`
    width: 49%;
    margin-right: 0;
  `,
  fullRow: css`
    width: 100%; // Changed to 100%
  `,
};

const SIZE_OPTIONS = {
  FULL_ROW: 'fullRow',
  HALF_ROW: 'halfRow',
  HALF_ROW_NO_MARGIN: 'halfRowNoMargin',
};

/**
 * @deprecated Old UI
 */
const DisabledInputBox = ({
  label,
  value,
  iconType,
  size,
  className,
  renderValue,
  htmlValue,
}) => (
  <div
    css={[
      boxStyle,
      widths[size],
    ]}
    className={className}
  >
    {label && (
      <h6 css={labelStyle}>
        {label}
      </h6>
    )}
    <div
      css={inputWrapper}
    >
      {iconType && (
        <FontAwesomeIcon
          icon={iconType}
          size="1x"
        />
      )}
      {htmlValue !== '' ? (
        <HTMLRenderer htmlText={htmlValue} />
      ) : (
        <div css={valueStyle}>
          {renderValue ? renderValue({ value }) : value}
        </div>
      )}
    </div>
  </div>
);

DisabledInputBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  iconType: FONT_AWESOME_ICON,
  className: PropTypes.string,
  renderValue: PropTypes.func,
  htmlValue: PropTypes.string,
};

DisabledInputBox.defaultProps = {
  label: '',
  value: '',
  iconType: '',
  className: '',
  size: SIZE_OPTIONS.FULL_ROW,
  renderValue: null,
  htmlValue: '',
};

export default DisabledInputBox;
