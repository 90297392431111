import { css, SerializedStyles } from '@emotion/react';
import { faArrowLeft, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useMemo } from 'react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import moment from 'moment';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';

import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerInput from '~Common/V3/components/DrawerInput';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Tooltip from '~Common/components/Tooltip';

import { useDragAndDrop } from '~Deprecated/hooks/useDragAndDrop';
import DragAndDrop from '~Deprecated/ui/components/DragAndDrop/DragAndDrop';

import { palette } from '~Common/styles/colors';
import { LearningLibraryRenderBackButtonParams, viewLearningLibraryDrawerTemplate } from '~Learning/components/LearningLibrary/ViewLearningLibraryDrawer';
import { FrontendPlaylistLearning, LearningType, OnUseTemplateParams } from '~Learning/const/interfaces';
import Froala from '~Common/V3/components/Froala';
import DatePicker from '~Common/V3/components/DatePicker';
import { toast } from '~Common/components/Toasts';
import { useRankedListHelpers } from '~Learning/hooks/utils/useRankedListHelpers';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import { LEARNING_MAX_CONTENT_IN_PLAYLIST } from '~Learning/const/index';
import RequiredIcon from '~Common/V3/components/RequiredIcon';
import { personalDevelopmentShareLearningDrawer } from '~DevelopmentPlan/components/Drawers/Learning/ShareLearningDrawer';
import { ResourceType } from '~DevelopmentPlan/const/types';
import DrawerTabs from '~DevelopmentPlan/components/Tabs/DrawerTabs';
import {
  DEFAULT_DATE, DEFAULT_RESOURCE_TITLE, DUPLICATE_RESOURCE_TEXT, modalTabs,
} from '~DevelopmentPlan/const/defaults';
import { DrawerCompetencySelect } from '~DevelopmentPlan/components/Shared/DrawerCompetencySelect';
import { ExistingLearning } from '~DevelopmentPlan/components/Drawers/Learning/ExistingLearning';
import {
  conformToDto, CreateResourceDTO, createResourceFormSchema, FormValues,
} from '~DevelopmentPlan/schemata/addResourceSchemata';
import { ValidationErrors } from '~Goals/const/types';
import { useCreateResource } from '~DevelopmentPlan/hooks/useCreateResource';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { competencyDrawerSelect } from '~DevelopmentPlan/const/pageStyles';
import { addResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { checkShouldCreateResource } from '~DevelopmentPlan/const/functions';
import { useLearningSearch } from '~Learning/hooks/utils/useLearningSearch';
import { createLearningParticipantsDrawerTemplate } from '../CreateLearningDrawer/CreateLearningParticipantsDrawerTemplate';
import DrawerCloseButtonWithConfirmation from '../Shared/ConfirmationButtons/DrawerCloseButtonWithConfirmation';
import EditablePlaylistLearningCard from './EditablePlaylistLearningCard';
import { shareLearningDrawerTemplate } from '../ShareLearningDrawer';
import { addNewContentDrawerTemplate } from './AddNewContentDrawer';
import PlaylistContentBanner from '../Shared/PlaylistContentBanner';
import Details from './EditablePlaylistLearningCard/Details';
import ContentListCount from '../Shared/ContentListLength';

const styles = {
  detailsContainer: css({
  }),
  contentHeader: css({
    marginTop: '1.125rem',
  }),
  innerNoQuestionsText: css({
    color: palette.neutrals.gray800,
  }),
  learningPlaylistContentCard: css({
    margin: '.25rem',
    marginBottom: '.625rem',
  }),
  contentButtons: css({
    marginTop: '.625rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  }),
  addContentButton: css({
    marginRight: '.625rem',
  }),
  footerButtons: css({
    marginTop: '2.875rem',
    display: 'flex',
  }),
  saveAndSelectButton: css({
    marginRight: '.625rem',
  }),
  titleInput: css({
    marginBottom: '0.5rem',
  }),
  mainButtons: css({
    marginTop: '2.875rem',
    display: 'flex',
  }),
  datePicker: css({
    marginTop: '0.5rem',
  }),
  icon: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  caretIcon: css({
    color: palette.brand.indigo,
    fontSize: '1.2rem',
  }),
  dragAndDrop: css({
    color: palette.neutrals.gray700,
  }),
  backButton: css({
    paddingLeft: '0px',
  }),
  datePickerLabel: css({
    display: 'flex',
    gap: '0.25rem',
    color: palette.neutrals.gray600,
    fontWeight: 500,
  }),
  dueDateFromNow: css({
    marginTop: '.625rem',
  }),
  competencySelect: css({
    marginBottom: '.625rem',
    ...competencyDrawerSelect,
  }),
  drawerFooterBtns: css({
    margin: '1.25rem 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '.625rem',
  }),
};

export const createLearningPlaylistDrawerTemplate = {
  name: 'CREATE_LEARNING_PLAYLIST_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

export interface CreateLearningPlaylistDrawerState {
  playlistTitle: string,
  playlistDescription: string,
  playlistDueDate: Date,
  playlistContentList: FrontendPlaylistLearning[],
  shouldConfirmDrawerClose?: boolean,
  isPersonalDevelopment?: boolean,
  activeTab?: number,
  setActiveTab?: (tab: number) => void,
  runAddResourceValidations?: (resourceIdClicked: ResourceType, contentId: string | number) => void,
  competencyId?: number,
  setCompetencyId?: (competencyId: number) => void,
}

enum FormSubmittedByButton {
  ADD_NEW_CONTENT = 'ADD_NEW_CONTENT',
  ADD_FROM_TEMPLATE = 'ADD_FROM_TEMPLATE',
  SAVE_AND_SELECT_PARTICIPANTS = 'SAVE_AND_SELECT_PARTICIPANTS',
  EDIT_CONTENT_PIECE = 'EDIT_CONTENT_PIECE',
}

const CreateLearningPlaylistDrawer = ({
  popDrawer,
  pushDrawer,
  drawerState,
  setDrawerState,
}: DrawerProps<CreateLearningPlaylistDrawerState>): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchText] = useLearningSearch();
  const {
    isPersonalDevelopment, activeTab, setActiveTab,
  } = drawerState;

  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      shouldConfirmDrawerClose: true,
    }));
  }, [setDrawerState]);

  const openThisDrawer = (): void => {
    pushDrawer({
      drawer: createLearningPlaylistDrawerTemplate,
    });
  };

  const [contentList, setContentList] = useState<FrontendPlaylistLearning[]>(drawerState.playlistContentList || []);
  const [resourceIdSelected, setResourceIdSelected] = useState<string[]>([]);

  const [formSubmittedByButton, setFormSubmittedByButton] = useState<FormSubmittedByButton | null>();
  const [contentIndexToEdit, setContentIndexToEdit] = useState<number | null>(null);
  const [dueDate, setDueDate] = useState(drawerState.playlistDueDate ? moment(drawerState.playlistDueDate) : null);

  const earliestLearningDueDate = useMemo(() => {
    if (contentList?.length) {
      return moment(
        new Date(Math.max.apply(null, contentList?.map(
          (playlistContent) => (playlistContent.dueDate ? new Date(playlistContent.dueDate).valueOf() : -Infinity),
        ))),
      );
    }

    return null;
  }, [contentList]);

  const { getArrayWithoutItem } = useRankedListHelpers(contentList);

  const { onDragEnd } = useDragAndDrop();

  const onCloseDrawer = (): void => {
    popDrawer({ drawerName: createLearningPlaylistDrawerTemplate.name });
    setSearchText('');
  };

  const backDrawerClick = (): void => {
    setSearchText('');
    onCloseDrawer();

    if (isPersonalDevelopment) {
      pushDrawer({
        drawer: personalDevelopmentShareLearningDrawer,
      });
    } else {
      pushDrawer({ drawer: shareLearningDrawerTemplate });
    }
  };

  const onEditContent = (contentIndex: number): void => {
    setContentIndexToEdit(contentIndex);
    setFormSubmittedByButton(FormSubmittedByButton.EDIT_CONTENT_PIECE);
  };

  const onDeleteContent = (contentRank: number): void => {
    const newContentList = getArrayWithoutItem(contentRank);
    setContentList(newContentList);
    setDrawerState((prev) => ({
      ...prev,
      playlistContentList: newContentList,
    }));
  };

  const onAddNewContent = (): void => {
    setFormSubmittedByButton(FormSubmittedByButton.ADD_NEW_CONTENT);
  };

  const hasValidation = formSubmittedByButton === FormSubmittedByButton.SAVE_AND_SELECT_PARTICIPANTS;

  const onUseTemplate = ({ templateId, isPlaylist, playlistContentList }: OnUseTemplateParams): void => {
    popDrawer({ popAll: true });
    if (isPlaylist && playlistContentList) {
      const currentPlaylistContentList = drawerState.playlistContentList || [];
      const unrankedPlaylistContentList = [...currentPlaylistContentList, ...playlistContentList];

      const rankedPlaylistContentList = unrankedPlaylistContentList.map((learning, index) => ({
        ...learning,
        rank: index,
      }));

      setDrawerState((prev) => ({
        ...prev,
        playlistContentList: rankedPlaylistContentList,
      }));

      pushDrawer({
        drawer: {
          ...createLearningPlaylistDrawerTemplate,
        },
      });
    } else {
      pushDrawer({
        drawer: {
          ...addNewContentDrawerTemplate,
          args: {
            templateId,
            maxDueDate: dueDate,
          },
        },
      });
    }
  };

  const { setResourceContentTitle, pdpId, competencyId } = addResourceModalStore.getState();
  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (hasValidation) {
      if (contentList.length < 2) {
        toast.error('You need at least two pieces of content.');
        return;
      }
      if (contentList.length > LEARNING_MAX_CONTENT_IN_PLAYLIST) {
        toast.error(`You can have a max of ${LEARNING_MAX_CONTENT_IN_PLAYLIST} content pieces in a playlist.`);
        return;
      }
    }

    const formData = new FormData(event.currentTarget);
    const playlistTitle = formData.get('title') as string;
    const playlistDescription = formData.get('description') as string;
    setResourceContentTitle(playlistTitle);
    // Store all the information in drawer state, as temporarily closing this drawer.
    setDrawerState((prev) => ({
      ...prev,
      playlistContentList: contentList,
      playlistTitle,
      playlistDescription,
      playlistDueDate: dueDate,
    }));

    popDrawer({ popAll: true });

    if (formSubmittedByButton === FormSubmittedByButton.ADD_NEW_CONTENT) {
      // Open the AddNewContentDrawer to add new content
      pushDrawer({
        drawer: {
          ...addNewContentDrawerTemplate,
          args: {
            maxDueDate: dueDate,
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.ADD_FROM_TEMPLATE) {
      // Open the LearningLibrary Drawer
      const onBackClick = (closeLearningLibrary: () => void): void => {
        closeLearningLibrary();
        openThisDrawer();
      };

      pushDrawer({
        drawer: {
          ...viewLearningLibraryDrawerTemplate,
          args: {
            onUseTemplate,
            renderBackButton: ({ backButtonStyles, closeLearningLibrary }: LearningLibraryRenderBackButtonParams) => (
              <IconButton
                onClick={() => onBackClick(closeLearningLibrary)}
                type="button"
                icon={faArrowLeft}
                css={[backButtonStyles, styles.backButton]}
                tooltip="Back"
              />
            ),
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.EDIT_CONTENT_PIECE) {
      // Open the AddNewContentDrawer to edit content
      if (contentIndexToEdit !== null) {
        setDrawerState((prev) => ({
          ...prev,
          contentTitle: contentList[contentIndexToEdit].title,
          contentLink: contentList[contentIndexToEdit].contentURL,
          introduction: contentList[contentIndexToEdit].introduction,
          questions: contentList[contentIndexToEdit].questions,
          dueDate: contentList[contentIndexToEdit].dueDate,
        }));
      }

      pushDrawer({
        drawer: {
          ...addNewContentDrawerTemplate,
          args: {
            maxDueDate: dueDate,
            contentIndexToEdit,
          },
        },
      });
    } else if (formSubmittedByButton === FormSubmittedByButton.SAVE_AND_SELECT_PARTICIPANTS) {
      setDrawerState((prev) => ({
        ...prev,
        runAddResourceValidations,
        resourceId: ResourceType.LearningPlaylist,
      }));
      // Open the SelectParticipantsDrawer
      pushDrawer({ drawer: createLearningParticipantsDrawerTemplate });
    }
  };

  const { mutate: createResourceMutation } = useCreateResource();
  const runAddResourceValidations = (resourceIdClicked:ResourceType, contentId: string): void => {
    const {
      resourceContentTitle, resourceContentDueDate, resourceContentStatus,
    } = addResourceModalStore.getState();
    const fallBackDate = moment(resourceContentDueDate).valueOf() === moment(DEFAULT_DATE).valueOf();
    const addResourceDataToValidate = {
      contentId,
      contentTypeId: resourceIdClicked,
      competencyId,
      contentTitle: resourceContentTitle ?? DEFAULT_RESOURCE_TITLE,
      contentDueDate: fallBackDate ? undefined : resourceContentDueDate,
      contentStatus: resourceContentStatus,
    };
    // Need to run these validations as a faux form submission
    runValidations(addResourceDataToValidate);
  };

  const runValidations = (data: CreateResourceDTO): void => {
    const resourceFound = checkShouldCreateResource(data, pdpId ?? '');
    if (!resourceFound) {
      createResourceFormSchema
        .validate(data, { abortEarly: false })
        .then(() => {
          const resourceDataDTO = conformToDto(data as FormValues);
          createResourceMutation({ id: pdpId ?? '', resource: resourceDataDTO }, { onSuccess: onSuccessfulCreate });
        })
        .catch((err: ValidationErrors) => {
          if (err.errors) {
            err.errors.forEach((error) => {
              toast.error(error);
            });
          } else {
            toast.error('There was an issue creating this resource. Please try again.');
          }
        });
    } else {
      toast.error(DUPLICATE_RESOURCE_TEXT);
    }
  };
  const dispatch = useDispatch();
  const onSuccessfulCreate = (): void => {
    setSearchText('');
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
  };

  const hookProps = {
    noValidate: !hasValidation,
    onSubmit,
    renderHeader: () => (
      <DrawerHeader
        title="Assign Learning Playlist"
        renderBackButton={(backButtonStyles: SerializedStyles) => (
          <IconButton onClick={backDrawerClick} type="button" icon={faArrowLeft} css={[backButtonStyles, styles.backButton]} tooltip="Back" />
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        {isPersonalDevelopment && (
          <>
            <DrawerTabs
              resourceId={ResourceType.LearningPlaylist}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setDrawerState={setDrawerState}
            />
            {activeTab === modalTabs[ResourceType.LearningPlaylist].Existing.value && pdpId && (
              <>
                <DrawerCompetencySelect
                  pdpId={pdpId}
                  selectStyles={styles.competencySelect}
                  competencyId={competencyId}
                  setDrawerState={setDrawerState}
                />
                <ExistingLearning
                  resourceIdSelected={resourceIdSelected}
                  setResourceIdSelected={setResourceIdSelected}
                  filterResultsBy={LearningType.PLAYLIST}
                />
              </>
            )}
          </>
        )}
        {((isPersonalDevelopment && activeTab === modalTabs[ResourceType.Learning].New.value) || isPersonalDevelopment === undefined) && (
        <>
          <div>
            <div css={styles.detailsContainer}>
              <DrawerInput
                name="title"
                label="Learning Playlist Title"
                css={styles.titleInput}
                maxLength={250}
                required
                initialValue={drawerState.playlistTitle}
              />
              <Froala
                name="description"
                label="Description"
                required
                richTextEditorProps={{
                  initialValue: drawerState.playlistDescription,
                }}
                renderRightIcon={() => (
                  <RequiredIcon />
                )}
              />
              { /* Passing as a normal date doesn't work and passing as a moment date makes typescript cry */ }
              <DatePicker
                minDate={earliestLearningDueDate as unknown as Date}
                clearable
                initialDate={dueDate?.toDate()}
                onDateSelected={({ date: newDate }) => setDueDate(newDate)}
                renderLabel={() => (
                  <div css={styles.datePickerLabel}>
                    <div>Due By</div>
                    <Tooltip
                      content="Please note that the Learning Playlist due date must come after the individual Learning Content due dates."
                    >
                      <div><FontAwesomeIcon icon={faCircleInfo} /></div>
                    </Tooltip>
                  </div>
                )}
                name="dueDate"
                disablePast
                css={styles.datePicker}
                rightIconType={() => (
                  <div css={styles.icon}>
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      css={styles.caretIcon}
                    />
                  </div>
                )}
              />
            </div>
            <ContentListCount
              css={styles.contentHeader}
              contentListCount={contentList.length}
            />
            {contentList.length < 2 && (
            <PlaylistContentBanner
              contentListLength={contentList.length}
            />
            )}
            <DragAndDrop
              css={styles.dragAndDrop}
              list={contentList}
              renderItem={({ id: content }: { id: FrontendPlaylistLearning }, dragHandleProps: DraggableProvidedDragHandleProps) => (
                <EditablePlaylistLearningCard
                  key={`${content.contentURL}-${content.title}`}
                  css={styles.learningPlaylistContentCard}
                  contentUrl={content.contentURL}
                  onEditContent={() => onEditContent(content.rank)}
                  onDeleteContent={() => onDeleteContent(content.rank)}
                  dragHandleProps={dragHandleProps}
                  renderDetails={() => (
                    <Details
                      title={content.title}
                      introduction={content.introduction}
                      renderDueDate={() => (
                        <DueDateFromNow
                          dueDate={content.dueDate}
                          css={styles.dueDateFromNow}
                        />
                      )}
                    />
                  )}
                />
              )}
              onDragEnd={onDragEnd}
              dragEndProps={{
                initialArray: contentList,
                movedItem: (rank: string) => contentList.find((content) => content.rank.toString() === rank),
                // @ts-expect-error TODO: Remove once DragAndDrop is typed
                endFunctions: (newArray: FrontendPlaylistLearning[]) => {
                  const updatedContentList = newArray.map((content, index) => ({ ...content, rank: index }));
                  setContentList(updatedContentList);
                },
              }}
              valueToUse="rank"
            />
            {contentList.length < LEARNING_MAX_CONTENT_IN_PLAYLIST && (
            <div css={styles.contentButtons}>
              <LeadrButton
                css={styles.addContentButton}
                variant="ghost"
                size="small"
                type="submit"
                onClick={onAddNewContent}
                data-test-id="learningPlaylistAddNewContent"
              >
                + Add New Content
              </LeadrButton>
              <LeadrButton
                variant="ghost"
                size="small"
                type="submit"
                onClick={() => setFormSubmittedByButton(FormSubmittedByButton.ADD_FROM_TEMPLATE)}
                data-test-id="learningPlaylistAddFromTemplate"
              >
                + Add From Template
              </LeadrButton>
            </div>
            )}
          </div>
          <div css={styles.footerButtons}>
            <LeadrButton
              css={styles.saveAndSelectButton}
              type="submit"
              onClick={() => setFormSubmittedByButton(FormSubmittedByButton.SAVE_AND_SELECT_PARTICIPANTS)}
              data-test-id="learningPlaylistSaveAndSelectParticipants"
            >
              Save & Select Participants
            </LeadrButton>
            <DrawerCloseButtonWithConfirmation
              renderDrawerCloseButton={({ onClick }) => (
                <LeadrButton
                  onClick={onClick}
                  variant="ghost"
                  data-test-id="learningPlaylistCancelCreate"
                >
                  Cancel
                </LeadrButton>
              )}
              onCloseDrawer={onCloseDrawer}
              shouldConfirmClose={drawerState.shouldConfirmDrawerClose}
            />
          </div>
        </>
        )}
      </div>
    ),
    renderFooter: () => {
      if (activeTab === modalTabs[ResourceType.Learning].Existing.value && pdpId) {
        return (
          <div css={styles.drawerFooterBtns}>
            <LeadrButton
              data-test-id="addResourceDrawerSaveChanges"
              size="small"
              onClick={() => {
                if (runAddResourceValidations !== undefined) {
                  runAddResourceValidations(ResourceType.Learning, resourceIdSelected[0]);
                }
              }}
              disabled={resourceIdSelected.length === 0}
            >
              Link Learning
            </LeadrButton>
            <LeadrButton
              data-test-id="addResourceModalCancelChanges"
              onClick={backDrawerClick}
              size="small"
              variant="ghost"
            >
              Cancel
            </LeadrButton>
          </div>
        );
      }
      return <></>;
    },
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: createLearningPlaylistDrawerTemplate.name,
  component: CreateLearningPlaylistDrawer,
});

export default CreateLearningPlaylistDrawer;
