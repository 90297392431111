export const stackDrawers = (drawers, newDrawerWidth, isDuplicate, isRemove) => {
  let offset = 0;
  const windowWidth = window.innerWidth;
  const estimatedWidth = drawers.length > 0 ? drawers.reduce((accumulator, currentValue) => ({ width: accumulator.width + currentValue.width })).width : 0;
  let totalWidth = estimatedWidth + newDrawerWidth;

  const updatedDrawers = drawers.map((drawer, index) => {
    let drawerOffset = drawer?.offset;
    let isCollapsed = drawer.isCollapsed;
    const threshold = isRemove ? 3 : 2;

    if (!isDuplicate) {
      if (index < drawers.length - threshold || totalWidth > windowWidth) {
        drawerOffset = offset - drawer.width + 50;
        offset = drawerOffset + drawer.width;
        if (!isCollapsed || isRemove) {
          totalWidth -= drawer.width - 50;
          isCollapsed = true;
        }
      } else {
        drawerOffset = offset;
        offset += drawer.width;
        isCollapsed = false;
      }
    }
    return {
      ...drawer,
      offset: drawerOffset,
      isCollapsed,
    };
  });
  return {
    stackedDrawers: updatedDrawers,
    offset,
  };
};
