import PropTypes from 'prop-types';
import { SURVEY_QUESTION_SHAPE } from '~Common/const/proptypes';
import { useGetMarksMemoized } from '../../../Hooks/useGetMarksMemoized';
import SurveyScale from './SurveyScale';

const View = ({
  minLabel,
  maxLabel,
  marks,
  id,
  initialValue,
  disabled,
}) => (
  <>
    <SurveyScale
      marks={marks}
      minLabel={minLabel}
      maxLabel={maxLabel}
      id={id}
      initialValue={initialValue}
      disabled={disabled}
    />
  </>
);

View.propTypes = {
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  id: PropTypes.number.isRequired,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

View.defaultProps = {
  minLabel: '',
  maxLabel: '',
  initialValue: '',
  disabled: false,
};

const SurveyRating = ({
  question, initialValue, disabled, ...props
}) => {
  const { minLabel, maxLabel, maxValue } = JSON.parse(question.configuration);
  const marks = useGetMarksMemoized({ maxValue });
  const { id } = question;
  const hookProps = {
    marks,
    minLabel,
    maxLabel,
    maxValue,
    initialValue,
    id,
    disabled,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SurveyRating.propTypes = {
  question: SURVEY_QUESTION_SHAPE.isRequired,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

SurveyRating.defaultProps = {
  initialValue: null,
  disabled: false,
};

export { View };
export default SurveyRating;
