import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { withLineClamp, withTruncate } from '~Common/styles/mixins';
import { HTMLString } from '~Common/types';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';
import LearningSectionsCard, { LearningSectionsCardProps } from '~Learning/components/Shared/LearningSectionsCard';
import { LearningStatus, LearningType } from '~Learning/const/interfaces';

const styles = {
  body: css({
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    gap: '1.125rem',
  }),
  information: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
  }, withTruncate()),
  introduction: css({
    color: palette.neutrals.gray500,
    fontWeight: 600,
    marginTop: '.75rem',
  }, withLineClamp(2)),
  dueDateFromNow: css({
    marginTop: '.75rem',
  }),
};

interface ContentDetailsCardProps extends Omit<LearningSectionsCardProps, 'renderHeader' | 'renderBody'> {
  contentUrl: string,
  introductionHTML?: HTMLString,
  title: string,
  dueDate?: Date | string,
  status: LearningStatus,
}

const ContentDetailsCard = ({
  contentUrl,
  title,
  introductionHTML,
  dueDate,
  status,
  ...props
}: ContentDetailsCardProps): JSX.Element => (
  <LearningSectionsCard
    renderHeader={() => (
      <LearningTypeInfo
        learningType={LearningType.SINGLE_LEARNING}
      />
    )}
    renderBody={() => (
      <div css={styles.body}>
        <PreviewWithExternalNavigation
          contentUrl={contentUrl}
        />
        <div css={styles.information}>
          <div css={styles.title}>{title}</div>
          {introductionHTML && (
            <HTMLRenderer css={styles.introduction} htmlText={introductionHTML} />
          )}
          <DueDateFromNow
            css={styles.dueDateFromNow}
            dueDate={dueDate}
            isComplete={status === LearningStatus.COMPLETED}
          />
        </div>
      </div>
    )}
    {...props}
  />
);

export default ContentDetailsCard;
