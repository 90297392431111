import PropTypes from 'prop-types';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';
import { ViewType } from '~Common/const/interfaces';
import { useSurveyViewTyperFilter } from '../../Hooks/useSurveyViewTypeFilter';

const viewTypes = [
  {
    value: ViewType.GRID,
    icon: faGripHorizontal,
  },
  {
    value: ViewType.LIST,
    icon: faList,
  },
];

const View = ({
  options,
  surveyViewTypeFilter,
  ...props
}) => (
  <LeadrToggleTabs value={surveyViewTypeFilter} {...props}>
    {viewTypes.map((viewType) => (
      <LeadrToggleTabs.IconTab data-test-id={viewType['data-test-id']} key={viewType.value} icon={viewType.icon} value={viewType.value} />
    ))}
  </LeadrToggleTabs>
);

View.propTypes = {
  options: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  surveyViewTypeFilter: PropTypes.string.isRequired,
};

const SurveyToggle = ({ ...props }) => {
  const [surveyViewTypeFilter, setSurveyViewTypeFilter] = useSurveyViewTyperFilter();
  const onChange = (e, newValue) => {
    if (newValue) {
      setSurveyViewTypeFilter(newValue);
    }
  };
  const hookProps = {
    onChange,
    surveyViewTypeFilter,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SurveyToggle.propTypes = {};

SurveyToggle.defaultProps = {};

export { View };
export default SurveyToggle;
