import { css } from '@emotion/react';

const styles = {
  boldedText: css({
    fontWeight: 600,
  }),
};

interface FeedbackTextProps {
  isReviewee: boolean,
  revieweeFullName: string,
}

const FeedbackText = ({
  isReviewee,
  revieweeFullName,
  ...props
}: FeedbackTextProps): JSX.Element => (
  <span {...props}>
    {'Feedback '}
    {isReviewee && (
      <span>{'you have received '}</span>
    )}
    {!isReviewee && (
      <span>
        received by
        <span css={styles.boldedText}>{` ${revieweeFullName} `}</span>
      </span>
    )}
  </span>
);

export default FeedbackText;
