import PropTypes from 'prop-types';

import { faCalendar } from '@fortawesome/pro-duotone-svg-icons';
import moment from 'moment';

import { View as TextField } from '../TextField';
import HiddenField from '../HiddenField';

/**
 * @deprecated Old Date Picker UI, use packages/leadr-frontend/src/common/V3/components/DatePicker/index.tsx instead
 */

const DatePickerField = ({
  date, format, openPicker, name, valueFormat, ...props
}) => {
  // The Date Picker control from MUI is passing a bunch of junk here we wanna strip out.
  const {
    error, helperText, inputProps, InputProps, ...otherProps
  } = props;
  const value = date ? moment(date).format(format) : '';

  return (
    <>
      <TextField
        iconProps={{
          icon: faCalendar,
          onClick: openPicker,
        }}
        value={value}
        name={format ? name : null}
        {...otherProps}
      />
      {!!format && (
        <HiddenField value={date ? moment(date).format(valueFormat) : ''} name={name} />
      )}
    </>
  );
};

DatePickerField.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]),
  format: PropTypes.string.isRequired,
  openPicker: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  InputProps: PropTypes.object,
  valueFormat: PropTypes.string,
  name: PropTypes.string,
};

DatePickerField.defaultProps = {
  date: null,
  error: false,
  helperText: '',
  inputProps: {},
  InputProps: {},
  valueFormat: 'MM/DD/YYYY',
  name: '',
};

export default DatePickerField;
