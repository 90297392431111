import { SerializedStyles, css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { Link } from 'react-router-dom';
import { withTruncate } from '~Common/styles/mixins';
import SecondaryText from './SecondaryText';
import Icon from './Icon';
import ParticipantAvatars from './ParticipantAvatars';
import SecondarySection from './SecondarySection';

const styles = {
  sectionItem: css({
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1.5rem',

    '&:hover, &:focus': {
      textDecoration: 'none',
      backgroundColor: palette.neutrals.gray50,
    },
  }),
  body: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    minWidth: 0,
  }),
  textContainer: css({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    color: palette.neutrals.gray800,
    fontWeight: 500,
    minWidth: 0,
  }),
  mainText: css(withTruncate()),
  avatarSection: css({
    marginLeft: '.75rem',
  }),
};

interface SectionItemProps {
  renderLeftItem: () => JSX.Element,
  renderMainText: (mainTextStyles: SerializedStyles) => JSX.Element,
  renderSecondaryText: () => JSX.Element,
  renderAvatarSection: () => JSX.Element,
  link: string,
  'data-test-id': string,
}

const SectionItem = ({
  renderLeftItem,
  renderMainText,
  renderSecondaryText,
  renderAvatarSection,
  link,
  ...props
}: SectionItemProps): JSX.Element => (
  <Link
    to={link}
    css={styles.sectionItem}
    data-test-id={props['data-test-id']}
  >
    {renderLeftItem()}
    <div css={styles.body}>
      <div css={styles.textContainer}>
        {renderMainText(styles.mainText)}
        {renderSecondaryText()}
      </div>
      <div css={styles.avatarSection}>
        {renderAvatarSection()}
      </div>
    </div>
  </Link>
);

SectionItem.Icon = Icon;
SectionItem.SecondarySection = SecondarySection;
SectionItem.SecondaryText = SecondaryText;
SectionItem.ParticipantAvatars = ParticipantAvatars;

export default SectionItem;
