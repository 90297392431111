import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useDebounceDraftState } from '~Common/hooks/useDebounceDraftState';
import { getLocalId } from '~Common/utils/uuid';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import { useBookmarkAgendaTopic, useUnbookmarkAgendaTopic } from '~Meetings/hooks/useBookmarkAgendaTopic';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import {
  faArrowsRepeat,
  faBookmark,
  faBookmarkSlash,
  faClone,
  faEdit,
  faEyeSlash,
  faFilePlus,
  faPaperclip,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import DeleteConfirmationPopover, { useDeleteConfirmationPopover } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import { useDeleteAgendaItem } from '~Meetings/hooks/v2/useDeleteAgendaItem';
import { useEditAgendaItem } from '~Meetings/hooks/v2/useEditAgendaItem';
import { AgendaTopic } from '~Meetings/hooks/v2/useGetAgendas';
import { useCarryOverAgendaItem } from '~Meetings/hooks/v2/useCarryOverAgendaItem';
import { useAgendaPermissions } from '~Meetings/hooks/utils/useAgendaPermissions';
import { queryClient } from '~Common/const/queryClient';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { useShowAttachmentsModal } from '~Meetings/hooks/utils/useShowAttachmentsModal';
import { useConvertToActionItem } from '../../../../../hooks/useConvertToActionItem';

interface ViewProps {
  agendaItemId: string,
  anchorEl: HTMLElement | null,
  closeConfirmationPopover: (event: SyntheticEvent<HTMLElement>) => void,
  isConfirmationPopoverOpen: boolean,
  menuItems: MenuItem[],
  onDeleteAgendaItem: () => void,
  popoverId?: string,
  disableOverflowMenu: boolean,
}

const View = ({
  agendaItemId,
  anchorEl,
  closeConfirmationPopover,
  isConfirmationPopoverOpen,
  menuItems,
  onDeleteAgendaItem,
  popoverId,
  disableOverflowMenu,
}: ViewProps): JSX.Element => (
  <>
    <OverflowMenu
      menuItems={[menuItems]}
      renderOverflowMenuButton={(doOpen) => (
        <LeadrButton
          disabled={disableOverflowMenu}
          textButtonColor={palette.neutrals.gray700}
          onClick={doOpen}
          variant="text"
          data-test-id="agendaItem-action-menu"
          data-agenda-topic-id={agendaItemId}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </LeadrButton>
      )}
    />
    <DeleteConfirmationPopover
      closeConfirmationPopover={closeConfirmationPopover}
      open={isConfirmationPopoverOpen}
      popoverId={popoverId}
      anchorEl={anchorEl}
      renderConfirmationButtons={({
        informationStyles,
        optionStyles,
        popoverButtonStyles,
      }) => (
        <DeleteConfirmationButtons
          informationStyles={informationStyles}
          optionStyles={optionStyles}
          popoverButtonStyles={popoverButtonStyles}
          onDelete={onDeleteAgendaItem}
        />
      )}
    />
  </>
);

interface AgendaItemActionMenuProps {
  agendaItem: AgendaTopic,
  huddleId: string,
  meetingType: MeetingTypeEnum,
  onAgendaEditClick: () => void,
  sectionId?: string,
}

export const AgendaItemActionMenu = ({
  agendaItem,
  huddleId,
  meetingType,
  onAgendaEditClick,
  sectionId,
}: AgendaItemActionMenuProps): JSX.Element => {
  const {
    isBookmarked,
    isPrivate,
    isRecurring,
    text: agendaItemText,
    id: agendaItemId,
    attachments,
  } = agendaItem;

  const { getTopicPermissions } = useAgendaPermissions(huddleId);

  const {
    canCarryOverAgenda,
    canDeleteAgenda,
    canEditAgenda,
    canUpdateAgendaRecurrence,
    canBookmark,
    canMakeActionItem,
    canUpdateAgendaVisibility,
    hasMenuOptions,
    areWorkflowsDisabled,
    canAddAttachment,
  } = getTopicPermissions(agendaItemId, sectionId);

  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen: isConfirmationPopoverOpen,
    popoverId,
  } = useDeleteConfirmationPopover('agendaItemActionMenuDeleteTopicConfirmationPopover');

  const { mutate: doDeleteAgendaItem } = useDeleteAgendaItem({ sectionId });
  const { mutate: doEditAgendaItem } = useEditAgendaItem({ agendaItemId, sectionId });

  const bookmarkTopic = useBookmarkAgendaTopic();
  const unbookmarkTopic = useUnbookmarkAgendaTopic();

  const {
    openModal: openAttachmentsModal,
  } = useShowAttachmentsModal();

  const addAttachments = useCallback(() => {
    openAttachmentsModal({
      props: {
        huddleId,
        topicId: agendaItemId,
        attachments,
        addAttachment: async () => {
          const queryKey = meetingKeys.agendaList(huddleId);
          await queryClient.refetchQueries({ queryKey });
        },
      },
    });
  }, [agendaItemId, attachments, huddleId, openAttachmentsModal]);

  const onDeleteAgendaItem = useCallback(() => {
    doDeleteAgendaItem({
      huddleId,
      agendaItemId,

      // Needed for the backend to send a realtime delete success message
      eventId: getLocalId(),
    });
  }, [agendaItemId, doDeleteAgendaItem, huddleId]);

  const doBookmarkAgendaTopic = useCallback((draftIsBookmarked: boolean) => {
    const bookmarkData = {
      huddleId,
      topicId: agendaItemId,
    };

    if (draftIsBookmarked) {
      bookmarkTopic(bookmarkData);
    } else {
      unbookmarkTopic(bookmarkData);
    }
  }, [bookmarkTopic, unbookmarkTopic, huddleId, agendaItemId]);

  // ToDo: Update code to use returned draft once we have a good method of handling
  // optimistic updates in tandem with useDebounceDraftState
  const [, setDraftIsBookmarked] = useDebounceDraftState(isBookmarked, doBookmarkAgendaTopic);

  const onBookmarkAgendaTopicClick = useCallback(() => {
    setDraftIsBookmarked(!isBookmarked);
  }, [isBookmarked, setDraftIsBookmarked]);

  const { mutate: doCarryOverAgendaItem } = useCarryOverAgendaItem(sectionId);

  const { onConvertToActionItem } = useConvertToActionItem({
    agendaItemText,
    huddleId,
    meetingType,
  });

  const onMakePrivateClick = useCallback(() => {
    doEditAgendaItem({
      huddleId,
      agendaId: agendaItemId,
      text: agendaItemText,
      isRecurring,
      isPrivate: !isPrivate,

      // EventId needed for backend to send realtime events.
      eventId: getLocalId(),
    });
  }, [agendaItemId, agendaItemText, doEditAgendaItem, huddleId, isPrivate, isRecurring]);

  const onMakeRecurringClick = useCallback(() => {
    doEditAgendaItem({
      huddleId,
      agendaId: agendaItemId,
      text: agendaItemText,
      isRecurring: !isRecurring,
      isPrivate,

      // EventId needed for backend to send realtime events.
      eventId: getLocalId(),
    });
  }, [agendaItemId, agendaItemText, doEditAgendaItem, huddleId, isPrivate, isRecurring]);

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [];

    if (canEditAgenda) {
      items.push({
        text: 'Edit Topic',
        icon: faEdit,
        onClick: onAgendaEditClick,
        dataTestId: 'editTopic',
      });

      items.push({
        text: 'Add Attachments',
        icon: faPaperclip,
        onClick: addAttachments,
        isDeactivated: !canAddAttachment,
        tooltip: canAddAttachment ? '' : 'You can\'t add any more attachments to this topic.',
      });
    }

    if (canUpdateAgendaVisibility) {
      items.push({
        text: isPrivate ? 'Make Public' : 'Make Private',
        icon: faEyeSlash,
        onClick: onMakePrivateClick,
        dataTestId: isPrivate ? 'makePublic' : 'makePrivate',
      });
    }

    if (canCarryOverAgenda) {
      items.push({
        text: 'Carry Over to Next Meeting',
        icon: faClone,
        onClick: () => doCarryOverAgendaItem({
          huddleId,
          agendaId: agendaItemId,
        }),
        dataTestId: 'carryOverToNextMeeting',
      });
    }

    if (canUpdateAgendaRecurrence) {
      items.push({
        text: isRecurring ? 'End Recurrence' : 'Make Recurring',
        icon: faArrowsRepeat,
        onClick: onMakeRecurringClick,
        dataTestId: isRecurring ? 'endRecurrence' : 'makeRecurring',
      });
    }

    if (canMakeActionItem) {
      items.push({
        text: 'Convert to Action Item',
        icon: faFilePlus,
        onClick: onConvertToActionItem,
        dataTestId: 'convertToActionItem',
      });
    }

    if (canBookmark) {
      items.push({
        text: isBookmarked ? 'Remove Bookmark' : 'Bookmark for Reflections',
        icon: isBookmarked ? faBookmarkSlash : faBookmark,
        onClick: onBookmarkAgendaTopicClick,
        dataTestId: isBookmarked ? 'removeBookmark' : 'bookmarkForReflections',
      });
    }

    if (canDeleteAgenda) {
      items.push({
        text: 'Delete Topic',
        icon: faTrashCan,
        onClick: openConfirmationPopover,
        styles: {
          iconColor: palette.brand.red,
          menuText: css({
            color: palette.brand.red,
          }),
        },
        dataTestId: 'deleteTopic',
      });
    }

    return items;
  }, [
    addAttachments,
    agendaItemId,
    canAddAttachment,
    canBookmark,
    canCarryOverAgenda,
    canDeleteAgenda,
    canEditAgenda,
    canMakeActionItem,
    canUpdateAgendaRecurrence,
    canUpdateAgendaVisibility,
    doCarryOverAgendaItem,
    huddleId,
    isBookmarked,
    isPrivate,
    isRecurring,
    onAgendaEditClick,
    onBookmarkAgendaTopicClick,
    onConvertToActionItem,
    onMakePrivateClick,
    onMakeRecurringClick,
    openConfirmationPopover,
  ]);

  if (!hasMenuOptions && !areWorkflowsDisabled) {
    return <></>;
  }

  const hookProps = {
    agendaItemId,
    anchorEl,
    closeConfirmationPopover,
    isConfirmationPopoverOpen,
    menuItems,
    onDeleteAgendaItem,
    popoverId,
    disableOverflowMenu: areWorkflowsDisabled,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
