import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { BarChart } from '~Common/components/Charts/BarChart';
import { LineGraphChart } from '~Common/components/Charts/LineGraphChart';
import InsightsDelta from '~Insights/components/InsightsDelta';
import SidebarFilters, { SidebarFilterOptions } from '~Insights/components/SidebarFilters';
import { GraphDataset, makeDelta } from '~Insights/const/dataUtils';
import { EngagementCategory } from '~Insights/const/types';
import { EngagementDataForDate } from '~Insights/hooks/useEngagementData';
import { palette } from '~Common/styles/colors';
import { styles } from './Expanded';

interface StatementGraphData {
  x: string,
  y: number,
  label: string,
  participantInfo?: string,
  questionText?: string,
}
interface EngagementCategoryByStatementProps {
  chartedDates: string[],
  graphData: Record<number, StatementGraphData[]>,
  lineGraphData: GraphDataset[],
  maxDataPoints: number,
  questionsToShow: number[],
  questionLabels: Record<number, string>,
  isMobile: boolean,
}

const EngagementCategoryByStatement = ({
  chartedDates,
  graphData,
  lineGraphData,
  maxDataPoints,
  questionsToShow,
  questionLabels,
  isMobile,
}: EngagementCategoryByStatementProps): JSX.Element => {
  if (maxDataPoints > 2) {
    return (
      <div css={styles.chart}>
        <LineGraphChart
          height="20rem"
          datasets={lineGraphData}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                type: 'category',
                labels: chartedDates,
              },
              y: {
                min: 0,
                max: 5,
              },
            },
          }}
          tooltipOptions={{
            callbacks: {
              // @ts-expect-error : There's something funky about the typing here.
              footer: (ctx: { raw: { participantInfo: string; }; }[]) => ctx[0]?.raw?.participantInfo ?? '',
              // @ts-expect-error : There's something funky about the typing here.
              afterFooter: (ctx: { raw: { questionText: string}} []) => ctx[0]?.raw.questionText ?? '',
            },
            footerMarginTop: 8,
          }}
        />
      </div>
    );
  }

  // Chart.js wants a score from each question in each dataset, not datasets grouped by question.
  // const graphDataValues = Object.values(graphData);
  // const sideBySideGraphData = {
  //   labels: questionsToShow.map((questionId) => questionLabels[questionId]),
  //   datasets: Array(maxDataPoints).fill(0).map((_, valueIndex) => ({
  //     data: graphDataValues.map((slice) => slice[valueIndex]?.y ?? null),
  //     backgroundColor: Object.values(palette.brand),
  //   })),
  // };

  return (
    <div css={styles.barChart}>
      <BarChart
        isMobile={isMobile}
        data={{
          labels: [],
          datasets: questionsToShow.map((questionId) => ({
            data: graphData[questionId]?.map((slice) => ({ ...slice, x: `${questionLabels[questionId]} (${slice.x})` })),
            backgroundColor: [palette.brand.sky],
            grouped: false,
          })),
        }}
        options={{
          maintainAspectRatio: false,
          scales: {
            y: {
              max: 5,
              title: {
                text: 'Average Score',
                display: true,
              },
            },
          },
        }}
        tooltipOptions={{
          callbacks: {
            // @ts-expect-error : There's something funky about the typing here.
            footer: (ctx: { raw: { participantInfo: string; }; }[]) => ctx[0]?.raw?.participantInfo ?? '',
            // @ts-expect-error : There's something funky about the typing here.
            afterFooter: (ctx: { raw: { questionText: string}} []) => ctx[0]?.raw.questionText ?? '',
          },
          footerMarginTop: 8,
        }}
      />
    </div>
  );
};

interface EngagementCategoryByStatementSidebarProps {
  questionFilters: SidebarFilterOptions,
  selectedQuestions: number[],
  setSelectedQuestions: Dispatch<SetStateAction<number[]>>,
}

const EngagementCategoryByStatementSidebar = ({
  questionFilters,
  selectedQuestions,
  setSelectedQuestions,
}: EngagementCategoryByStatementSidebarProps): JSX.Element => (
  <SidebarFilters
    selectedValues={selectedQuestions}
    options={questionFilters}
    onChange={(values) => setSelectedQuestions([...values] as number[])}
  />
);

interface UseAveragesByStatementProps {
  data: EngagementDataForDate[],
  engagementCategory: keyof typeof EngagementCategory,
  subtabPerspective: string,
  startDate: Date,
  endDate: Date,
  isMobile: boolean,
}

interface UseAveragesByStatementReturn {
  AveragesByStatement: JSX.Element,
  AveragesByStatementSidebar: JSX.Element,
  allPossibleDates: string[],
}

export function useAveragesByStatement({
  data,
  engagementCategory,
  subtabPerspective,
  startDate,
  endDate,
  isMobile,
}: UseAveragesByStatementProps): UseAveragesByStatementReturn {
  const filteredDataByDate = data.filter((slice) => (
    // @ts-expect-error | Moment typing is screwed up.
    moment(slice.surveyDate).isBetween(startDate, endDate, 'day', true)
  ));
  const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);
  const questions = uniqBy(filteredDataByDate?.flatMap((slice) => slice.questions)
    .filter((question) => question.category.toLowerCase() === engagementCategory), 'id') ?? [];
  const questionsToShow = selectedQuestions.length ? selectedQuestions : questions.map((question) => question.id);
  const questionLabels = Object.fromEntries(questions.map((question, index) => [question.id, `Statement ${index + 1}`]));

  // We want to make sure the sidebar filters clear when categories/etc. change
  useEffect(() => {
    setSelectedQuestions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtabPerspective]);

  const graphData: Record<number, StatementGraphData[]> = {};
  filteredDataByDate.forEach((slice) => {
    const questionId = slice.questions.find((question) => question.category.toLowerCase() === engagementCategory)?.id ?? 0;

    if (questionsToShow.includes(questionId)) {
      graphData[questionId] ??= [];
      graphData[questionId].push({
        label: questionLabels[questionId],
        x: moment(slice.surveyDate).format('MMM D YY'),
        y: slice.averages[engagementCategory],
        participantInfo: `${slice.surveysAnswered} / ${slice.surveysRequested} Participants`,
        questionText: slice.questions.find((question) => question.id === questionId)?.text,
      });
    }
  });

  // We want to get all the possible dates that the selected questions could appear, even if outside the date range.
  const allPossibleDates: string[] = [];
  data.forEach((slice) => {
    const questionId = slice.questions.find((question) => question.category.toLowerCase() === engagementCategory)?.id ?? 0;
    if (selectedQuestions.length === 0 || questionsToShow.includes(questionId)) {
      allPossibleDates.push(slice.surveyDate);
    }
  });

  const lineGraphData: GraphDataset[] = Object.entries(graphData).map(([questionId, slice]) => ({
    data: slice,
    label: questionLabels[questionId],
  })).sort((a, b) => a.label.localeCompare(b.label));

  const chartedDates = lineGraphData.flatMap((slice) => slice.data.map((line) => line)).map((line) => line.x).sort((a, b) => moment(a).diff(b));

  const questionFilters = graphData ? questions.map((question) => {
    const questionData = graphData[question.id];

    return {
      value: question.id,
      renderContents: () => (
        <div css={styles.statement}>
          <h3>
            {questionLabels[question.id]}
          </h3>
          <p>
            {question.text}
          </p>
          <p>
            {questionData?.length <= 1 && (
              <p className="delta">
                {Math.round(questionData?.[0]?.y ?? '-')}
                /5
              </p>
            )}
            {questionData?.length > 1 && (
              <InsightsDelta value={makeDelta(
                questionData[0].y,
                questionData[questionData.length - 1].y,
              )}
              />
            )}
          </p>
        </div>
      ),
    };
  }) : [];

  const maxDataPoints = Math.max(...Object.values(graphData).map((node) => node.length), 0);

  return {
    AveragesByStatement: EngagementCategoryByStatement({
      chartedDates,
      questionsToShow,
      questionLabels,
      graphData,
      lineGraphData,
      maxDataPoints,
      isMobile,
    }),
    AveragesByStatementSidebar: EngagementCategoryByStatementSidebar({
      questionFilters,
      selectedQuestions,
      setSelectedQuestions,
    }),
    allPossibleDates,
  };
}
