import moment from 'moment';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerFooter from '~Common/V3/components/Drawers/DrawerFooter';
import IconButton from '~Meetings/components/buttons/IconButton';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import { cardDivider, surveyQuestionPreviewColors, palette } from '~Common/styles/colors';
import ViewCard, { ViewCardSkeleton } from '~Common/components/Cards/ViewCard';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { toast } from '~Common/components/Toasts';
import ViewSurveyQuestionCard from '../../Cards/ViewSurveyQuestionCard';
import SurveyMultipleChoice from './SurveyMultipleChoice';
import SurveyRating from './SurveyRating';
import { useGetSurveyTypes } from '../../../Hooks/useSurveyTypes';
import SurveyFreeform from './SurveyFreeform';
import ByPersonResult from '../Created/ByPersonResult';
import { useSubmitSurveyAnswers, useSurveyDetail } from '../../../Hooks/useSurveysList';

export const respondSurveyTemplate = {
  name: 'RESPOND_SURVEY_TEMPLATE',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  viewSurveyCard: css({
    marginBottom: '1rem',
    boxShadow: '0px 4px 8px -2px rgba(28, 42, 55, 0.2)',
    borderRadius: '5px',
  }),
  surveyTopCard: css({
    marginTop: '1rem',
    marginBottom: '2rem',
    boxShadow: '0px 4px 8px -2px rgba(28, 42, 55, 0.2)',
    borderRadius: '5px',
    border: 'none',
  }),
  summaryTitle: css`
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: ${palette.neutrals.gray900};
  `,
  summaryCardBody: css`
    color: ${palette.neutrals.gray700};
    font-weight: 400;
  `,
  summaryContent: css`
  color: ${palette.neutrals.gray700};
  font-weight: 400;
  `,
  summaryFooter: css`
    margin-top: 28px;
    border-top: 1px solid ${cardDivider};
    padding-top: 18px;
  `,
  slider: css`
    width: 90% !important;
    color: ${surveyQuestionPreviewColors.slider} !important;
  `,
  resultCard: css`
    margin-top: 1.125rem;
  `,
  drawerBody: css({
    padding: '1rem 1.5rem',
  }),
};

const RespondSurveyDrawer = ({
  surveyId,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isLoading, survey } = useSurveyDetail({ surveyId, withQuestions: true });
  const { QuestionTypes } = useGetSurveyTypes({});
  const submitSurveyAnswersMutation = useSubmitSurveyAnswers({ surveyId });

  const surveyParticipant = survey?.byParticipants?.find((participantAndAnswer) => participantAndAnswer.participant.participantUid === getOrganizationUserId());

  const isComplete = surveyParticipant?.participant?.completedOn || survey?.completedOn;

  const getTempAnswer = (answer) => {
    try {
      const tempAnswer = JSON.parse(answer);
      return tempAnswer;
    } catch (e) {
      return answer;
    }
  };

  const closeDrawerClick = () => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawer({ drawerName: respondSurveyTemplate.name }));
  };

  const hookProps = {
    isReadOnly: isComplete,
    showSendButton: true,
    drawerName: respondSurveyTemplate.name,
    drawerTitle: 'View Survey',
    onSubmit: (e) => {
      const formData = new FormData(e.target);
      const formAnswers = Object.fromEntries(formData.entries());

      // NOTE: Here is where you need to update if the name of the scale what updated.
      // eslint-disable-next-line max-len
      const filteredAnswers = Object.entries(formAnswers).filter(([key]) => key !== 'radioButtons' && key !== 'description' && !key.startsWith('option-')).map(([, value]) => (value));
      const answers = filteredAnswers.map((answer) => {
        const tempAnswer = getTempAnswer(answer);

        return {
          questionId: tempAnswer.id,
          answer: tempAnswer.response?.toString() ?? '',
        };
      });
      if (answers.some(({ answer }) => answer === '')) {
        toast.error('Please provide a response to all questions.');
      } else {
        submitSurveyAnswersMutation({ surveyId, surveyAnswers: { answers } });
      }
    },
    renderHeader: () => (
      <DrawerHeader
        title="View Survey"
        renderCloseButton={(closeButtonStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
      />
    ),
    renderBody: () => (
      <div css={styles.drawerBody}>

        <>
          {isLoading && (
            <>
              <ViewCardSkeleton height={100} css={styles.viewSurveyCard} />
              <MultipleSkeletonLoaders
                renderSkeletonItem={() => (
                  <ViewCardSkeleton height={200} css={styles.viewSurveyCard} />
                )}
                numberOfSkeletons={2}
              />
            </>
          )}
          {!isLoading && (
            <>
              <ViewCard
                css={[styles.viewSurveyCard, styles.surveyTopCard]}
                renderBody={() => (
                  <>
                    <div css={styles.summaryCardBody}>
                      <div css={styles.summaryTitle}>{survey.title}</div>
                      <HTMLRenderer htmlText={survey.objective} />
                    </div>
                    {survey.completeBy && (
                      <div css={styles.summaryFooter}>
                        Due:
                        {' '}
                        {moment(survey.completeBy).format('MM/DD/YYYY')}
                      </div>
                    )}
                  </>
                )}
              />
              {isComplete && (
                <>
                  {surveyParticipant.questionsAndAnswers.map((questionAndAnswer) => (
                    <ByPersonResult css={styles.resultCard} questionTypes={QuestionTypes} participantQuestionAndAnswer={questionAndAnswer} />
                  ))}
                </>
              )}
              {!isComplete && (
                <>
                  {survey.questions.map((question) => (
                    <>
                      <ViewSurveyQuestionCard
                        css={styles.viewSurveyCard}
                        question={question}
                        renderResponseContent={() => (
                          <>
                            {question.type.key === QuestionTypes.CustomScale.id && (
                            <SurveyRating question={question} disabled={isComplete} />
                            )}
                            {question.type.key === QuestionTypes.MultipleChoice.id && (
                            <SurveyMultipleChoice question={question} isReadOnly={isComplete} />
                            )}
                            {question.type.key === QuestionTypes.ShortAnswer.id && (
                            <SurveyFreeform question={question} isReadOnly={isComplete} />
                            )}
                          </>
                        )}
                      />
                    </>
                  ))}
                </>
              )}

            </>
          )}
        </>
      </div>
    ),
    renderFooter: () => (
      <>
        {isComplete && !isLoading && (
          <DrawerFooter />
        )}
        {!isComplete && !isLoading && (
          <DrawerFooter
            renderSaveButton={() => (
              <LeadrButton
                type="submit"
                data-test-id="surveySubmit"
              >
                Submit Survey
              </LeadrButton>
            )}
          />
        )}
      </>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
      {...props}
    />
  );
};

RespondSurveyDrawer.propTypes = {
  surveyId: PropTypes.number.isRequired,
};

RespondSurveyDrawer.defaultProps = {};

registerDrawer({
  templateName: respondSurveyTemplate.name,
  component: RespondSurveyDrawer,
});

export default RespondSurveyDrawer;
