import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { homeQueryKeys } from '~Home/hooks/queryKeys';

const transferOwnership = ({
  factoryId,
  huddleId,
  newOrganizerId,
}) => {
  const serverUrl = `/organizations/${getOrganizationId()}/huddles/series/owner`;
  return patchApi(serverUrl, {
    factoryId,
    huddleId,
    newOrganizerId,
  });
};

export const useTransferOwnership = ({ template }) => {
  const toastId = useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: transferOwnership,
    onMutate: () => {
      toastId.current = toast.info('Transferring ownership.', { autoClose: true });
    },
    onError: (variables) => {
      const { status } = variables ?? {};
      const { messages } = status ?? [];
      const { message } = messages?.[0] ?? {};
      if (message === 'Encountered error error.huddle.transferHuddleOwnership.new.organizer.unlinked.invalid.calendar') {
        toast.update(toastId.current, {
          render: 'Ownership has been transferred, but the new owner will need to link their calendar before this meeting is reflected in their calendar.',
          type: toast.TYPE.WARNING,
          autoClose: 5000,
        });
        dispatch(popDrawerAction({ drawerName: template.name }));
      } else {
        toast.update(toastId.current, {
          render: 'Our attempt to transfer ownership has failed, but you are not a failure.',
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      }
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'You have transferred the ownership. Enjoy the rest of your day.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      dispatch(
        popDrawerAction({ drawerName: template.name }),
      );
    },
    onSettled: (_, __, variables) => {
      const { huddleId } = variables;

      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', huddleId] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', 'upcoming'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', 'archived'] });
      queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'timeline'] });
      queryClient.invalidateQueries({ queryKey: homeQueryKeys.upcomingWeekLists(getOrganizationId()) });
    },
  });

  return mutation.mutate;
};
