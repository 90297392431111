import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { Hit, BaseHit } from 'instantsearch.js';
import { useHits, UseHitsProps } from 'react-instantsearch-hooks-web';
import { BaseInstanceBody } from '~GlobalSearch/const/types';
import EmptyState from './EmptyState';

const styles = {
  customHits: css({
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    fontWeight: 600,
    color: palette.neutrals.gray800,
    fontSize: '1rem',
    marginBottom: '.25rem',
  }),
  showMoreButton: css({
    marginTop: '.5rem',
  }),
};

interface RenderHitComponentParams {
  hit: Hit<BaseInstanceBody>,
}

interface ViewProps {
  hits: Hit<BaseHit>[],
  renderHitComponent: ({ hit }: RenderHitComponentParams) => JSX.Element,
  className?: string,
  query: string,
}

const View = ({
  hits,
  renderHitComponent,
  className,
  query,
}: ViewProps): JSX.Element => (
  <>
    {hits.length > 0 && (
      <ul
        css={styles.customHits}
        className={className}
      >
        {hits.map((hit) => (
          <li key={hit.objectID}>
            {renderHitComponent({ hit } as RenderHitComponentParams)}
          </li>
        ))}
      </ul>
    )}
    {hits.length === 0 && (
      <EmptyState query={query} />
    )}
  </>
);

type CustomHitsProps = Omit<ViewProps, 'hits' | 'query'> & UseHitsProps;

const CustomHits = ({
  className,
  renderHitComponent,
  ...props
}: CustomHitsProps): JSX.Element => {
  const { hits, results } = useHits({
    escapeHTML: false,
    ...props,
  });

  const query = results?.query ?? '';

  const hookProps = {
    hits,
    className,
    renderHitComponent,
    query,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default CustomHits;
