import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { drawerInputText } from '~Deprecated/ui/styles/colors';
import { printColors } from '~Common/styles/colors';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import DrawerAvatar from '~Deprecated/common/Components/People/Avatar/DrawerAvatar';
import { useGetPersonById } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';

const placeholderText = ({ status, richText }) => {
  switch (status) {
    case ANSWER_STATUS.COMPLETED:
      return richText;
    case ANSWER_STATUS.INCOMPLETE:
      return '<em>Waiting for Response...</em>';
    case ANSWER_STATUS.OPTED_OUT:
      return 'Opted Out';
    default:
      // Should we log something here?
      return '';
  }
};

const styles = {
  drawerItem: css`
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: flex-start;
    border: 1px solid ${printColors.responseBorders};
  `,
  itemInfo: css`
    background: transparent;
    color: ${drawerInputText};
    font-size: .875rem;
    line-height: .875rem;
    padding-top: 0;
    padding-bottom: 0.625rem;
  `,
  avatar: css`
    justify-content: flex-start;

    & img{
      width:1.875rem;
      height:1.875rem;
    }
  `,
  name: css`
    color: ${printColors.userName};
    padding-left: 1rem;
    font-size: .75rem;
  `,
};

const View = ({
  richText,
  status,
  className,
  person,
}) => (
  <div css={styles.drawerItem} className={className}>
    <div css={[styles.itemInfo, styles.avatar]}>
      {person && (
        <DrawerAvatar
          imgUrl={person?.profileImageUrl}
          name={`${person?.firstName} ${person?.lastName}`}
        />
      )}
    </div>
    <div css={styles.itemInfo}>
      <Froala
        value={placeholderText({ status, richText })}
        isReadOnly
        styleOverrides={styles.itemInfo}
      />
      <div css={styles.name}>
        {person?.firstName}
        {' '}
        {person?.lastName}
      </div>
    </div>
  </div>
);

View.propTypes = {
  richText: PropTypes.string,
  status: PropTypes.string,
  className: PropTypes.string,
  person: ATTENDEE_SHAPE,
};

View.defaultProps = {
  richText: '',
  status: ANSWER_STATUS.COMPLETED,
  className: '',
  person: null,
};

const FreeformResponse = ({ userId, ...props }) => {
  const person = useGetPersonById({ id: userId });

  const hookProps = {
    userId,
    person,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

FreeformResponse.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default FreeformResponse;
