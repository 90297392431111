import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { produce } from 'immer';

import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { getHost } from '~Deprecated/services/config';
import { AgendaSection, AgendaTopic, GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';

interface MarkAgendaItemCompleteRequest {
  huddleId: string;
  agendaId: string;
  isComplete: boolean,
  eventId?: string,
}

interface MarkAgendaItemCompleteReturn {
  id: string,
}

const markAgendaItemComplete = async (markAgendaItemCompleteRequest: MarkAgendaItemCompleteRequest): Promise<HttpCallReturn<MarkAgendaItemCompleteReturn>> => {
  const url = {
    host: getHost('', '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/huddles/agendas/status`,
  };

  return patchApi(url, markAgendaItemCompleteRequest);
};

interface UseMarkAgendaItemCompleteParams {
  sectionId?: string,
}

export const useMarkAgendaItemComplete = ({
  sectionId,
}: UseMarkAgendaItemCompleteParams = {}): UseMutationResult<
  HttpCallReturn<MarkAgendaItemCompleteReturn>, unknown, MarkAgendaItemCompleteRequest
> => useMutation({
  mutationFn: markAgendaItemComplete,
  onMutate: async ({ huddleId, agendaId, isComplete }) => {
    const previousAgendas = queryClient.getQueryData(meetingKeys.agendaList(huddleId));

    await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(huddleId) });

    queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId), (originalData) => {
      if (originalData) {
        if (sectionId) {
          // The agenda item is being delete from a section
          // Find the section and remove it there
          const sectionIndex = originalData.response.agendas.findIndex((agenda) => agenda.id === sectionId);
          if (sectionIndex !== -1) {
            return produce(originalData, (draft) => {
              const agendaSection = draft.response.agendas[sectionIndex] as AgendaSection;
              const agendaTopicIndex = agendaSection.children.findIndex((agenda) => agenda.id === agendaId);

              if (agendaTopicIndex !== -1) {
                const agendaTopicToUpdate = agendaSection.children[agendaTopicIndex];

                if (isComplete) {
                  agendaSection.totalCompletedChildren += 1;
                } else {
                  agendaSection.totalCompletedChildren -= 1;
                }

                agendaSection.children[agendaTopicIndex] = {
                  ...agendaTopicToUpdate,
                  isComplete,
                };
              }
            });
          }
        } else {
          return produce(originalData, (draft) => {
            const agendaIndex = draft.response.agendas.findIndex((agenda) => agenda.id === agendaId);

            if (agendaIndex !== -1) {
              const agendaTopicToUpdate = draft.response.agendas[agendaIndex] as AgendaTopic;

              draft.response.agendas[agendaIndex] = {
                ...agendaTopicToUpdate,
                isComplete,
              };
            }
          });
        }
      }

      return originalData;
    });

    return { previousAgendas };
  },
  // push failed for some reason, roll back and re-open the editor, rely on drafting to populate
  onError: (_, variables, snapshot) => {
    const { huddleId: id } = variables;
    toast.error(<MeetingsErrorToast />, {
      autoClose: 1500,
    });
    queryClient.setQueryData(meetingKeys.agendaList(id), snapshot?.previousAgendas);
  },
  onSettled: async (_, __, { huddleId }) => {
    await queryClient.invalidateQueries({ queryKey: meetingKeys.agendaList(huddleId) });
  },
});
