import { Goals } from '@leadr-hr/types';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useGetGoalsForCoaching } from '~Goals/hooks/useGetGoalsForCoaching';
import { meetingGoals } from '~Meetings/const/sharedStyles';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import {
  MEETING_MAX_GOALS_TO_SHOW,
  allCoachingGoalContextTypesValue,
  coachingGoalContextTypes,
} from '~Meetings/const/goals';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import ReactiveSortByDropdown from '~Meetings/components/details/goals/filters/ReactiveSortByDropdown';
import ReactiveGoalContextTypeDropdown from '~Meetings/components/details/goals/filters/ReactiveGoalContextTypeDropdown';
import MeetingGoalCard from '../MeetingGoalCard';
import MeetingGoalsLoader from './MeetingGoalsLoader';

const styles = {
  ...meetingGoals,
  noGoalsText: css({
    fontColor: palette.neutrals.gray700,
  }),
  filters: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
  }),
  dropdown: css({
    width: 'min-content',
  }),
};

interface ViewProps {
  goals: Goals.LinkedGoal[],
  isLoading: boolean,
  selectedContextType: Goals.GoalContextType | string,
  onContextTypeChange: (event: SelectChangeEvent<Goals.GoalContextType | string>) => void,
  sortBy: Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title,
  onSortByChange: (event: SelectChangeEvent<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>) => void,
  showGoals: boolean,
  cascadingGoals: boolean,
}

const View = ({
  goals,
  isLoading,
  selectedContextType,
  onContextTypeChange,
  showGoals,
  cascadingGoals,
  sortBy,
  onSortByChange,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    { (isLoading) && (
      <MeetingGoalsLoader />
    )}
    { !isLoading && showGoals && (
      <div
        css={styles.container}
      >
        <header css={styles.header}>
          <div css={styles.title}>
            {`Goals ${goals.length ? `(${goals.length})` : ''}`}
          </div>
          {cascadingGoals && (
            <div css={styles.filters}>
              <ReactiveSortByDropdown
                sortBy={sortBy}
                onSortByChange={onSortByChange}
              />
              <ReactiveGoalContextTypeDropdown
                items={coachingGoalContextTypes}
                selectedContextType={selectedContextType}
                onContextTypeChange={onContextTypeChange}
              />
            </div>
          )}
        </header>
        <hr css={styles.divider} />
        <div css={styles.body}>
          {goals.map((goal) => (
            <MeetingGoalCard
              key={goal.goalId}
              css={styles.goalCard}
              canAccessGoal
              goalId={goal.goalId}
              title={goal.title}
              status={goal.currentStatusUpdate?.status}
              category={goal.category}
              startTime={goal.startTimeInMillis}
              endTime={goal.endTimeInMillis}
              totalChildGoals={goal.totalChildGoals}
            />
          ))}
          {goals.length === 0 && (
            <div css={styles.noGoalsText}>
              No goals found
            </div>
          )}
        </div>
      </div>
    )}
  </div>
);

interface CoachingGoalsProps {
  meetingFactoryId: string,
}

const CoachingGoals = ({
  meetingFactoryId,
  ...props
}: CoachingGoalsProps): JSX.Element => {
  const { orgLevelEnableCascadingGoals } = useEnableCascadingGoals();
  const cascadingGoals = useFeatureFlag('cascadingGoals');
  const [selectedContextType, setSelectedContextType] = useState<Goals.GoalContextType | string>(allCoachingGoalContextTypesValue);
  const [sortBy, setSortBy] = useState<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>(Goals.GetGoalsSortBy.DueDate);

  const onContextTypeChange = (event: SelectChangeEvent<Goals.GoalContextType | string>): void => {
    setSelectedContextType(event.target.value);
  };

  const onSortByChange = (event: SelectChangeEvent<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>): void => {
    setSortBy(event.target.value as Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title);
  };

  const params: Goals.Requests.GetGoalsForOneOnOneMeetingRequestQueryParameters = {
    take: MEETING_MAX_GOALS_TO_SHOW,
    enableCascading: orgLevelEnableCascadingGoals,
    contextType: selectedContextType,
    sortBy,
    sortOrder: Goals.GetGoalsSortOrder.Ascending,
  };

  const { data, isLoading: areGoalsLoading } = useGetGoalsForCoaching({
    meetingFactoryId,
    queryParameters: params,
  });

  const goals = data?.response ?? [];

  const [isLoading] = useSkeletonLoaders(areGoalsLoading);

  const showGoals = !!goals.length || selectedContextType !== allCoachingGoalContextTypesValue;

  const hookProps = {
    isLoading,
    goals,
    selectedContextType,
    onContextTypeChange,
    showGoals,
    cascadingGoals,
    sortBy,
    onSortByChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CoachingGoals;
