import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { forDesktopObject, withoutDesktopObject } from '~Common/styles/mixins';

export const meetingRightPanelHeader = css({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '.5rem',
  marginRight: '0 !important',
  textTransform: 'uppercase',
  color: palette.neutrals.gray700,

  p: {
    fontSize: '0.6875rem',
    letterSpacing: '0.15rem',
    flex: 1,
  },
});

export const meetingRightPanelHeaderButton = css({
  fontSize: '0.75rem',
  fontWeight: 500,
  padding: 0,
});

export const meetingGoals = {
  container: css({
    padding: '1rem 1.75rem',
  }, withoutDesktopObject({
    boxShadow: 'none',
    padding: '1rem',
  }), forDesktopObject({
    backgroundColor: palette.neutrals.white,
    boxShadow: '0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.07)',
    borderRadius: '0.5rem',
  })),
  header: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.75rem',
    justifyContent: 'space-between',
  }),
  title: css({
    flex: 1,
    fontSize: '1.125rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
  }),
  divider: css({
    margin: '0 0 0.75rem 0',
  }),
  body: css({
    display: 'flex',
    overflowX: 'auto',
    // Needed so the box shadow of the goal cards isn't cut off
    padding: '0.5rem',
  }),
  goalCard: css({
    marginRight: '0.625rem',
  }),
};
