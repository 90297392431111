import { useIntl } from 'react-intl';

/**
 * Used to define error handler object structure.
 */
export class ErrorHandllerModel {
  /**
     * Used to create object for error details.
     * @param {*} errorDetails Used to store error details.
     */
  constructor(errorDetails) {
    // this.formatMessage = useIntl();
    this.status = errorDetails.status || 400;
    this.message = errorDetails.message || 'Message Missing';
    this.type = errorDetails.type || 'error';
  }

  /**
     * Used to return plain text format message using L10n key
     */
  getPlainTextMessage(key) {
    if (key) {
      return key;
    }
    return '';

    //  return this.formatMessage({ id: `error.${key}` });
  }
}
