import PropTypes from 'prop-types';

import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import { ensureLinkHasProtocol } from '~Deprecated/utils/';
import { stripUrl } from '~Common/const/socialLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagramSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import Tooltip from '~Common/components/Tooltip';

const SocialConnection = {
  instagram: 'instagram',
  linkedIn: 'linkedIn',
  twitter: 'twitter',
};

const socialIcons = {
  [SocialConnection.instagram]: faInstagramSquare,
  [SocialConnection.linkedIn]: faLinkedin,
  [SocialConnection.twitter]: faTwitterSquare,
};

const UserSocialLinks = ({ user, className }) => {
  const renderSocialIcon = (type) => {
    const icon = socialIcons[type];
    const socialConnection = user[type];
    if (!stripUrl(user[type], type)) return false;

    return (
      <Tooltip
        content={socialConnection}
      >
        <a
          href={ensureLinkHasProtocol(socialConnection)}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={icon}
          />
        </a>
      </Tooltip>
    );
  };

  return (
    <div className={className}>
      { renderSocialIcon(SocialConnection.instagram) }
      { renderSocialIcon(SocialConnection.linkedIn) }
      { renderSocialIcon(SocialConnection.twitter) }
    </div>
  );
};

UserSocialLinks.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  className: PropTypes.string,
};

UserSocialLinks.defaultProps = {
  className: '',
};

export default UserSocialLinks;
