import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { cardLabelColor } from '~Deprecated/ui/styles/colors';

const styles = css`
  color: ${cardLabelColor};
`;

/**
 * @deprecated Old Card UI, use packages/leadr-frontend/src/common/V3/components/Card.tsx instead
 */
function CardLabel({ content, ...props }) {
  return (
    <h6
      css={styles}
      {...props}
    >
      {content}
    </h6>
  );
}

CardLabel.propTypes = {
  content: PropTypes.string,
};

CardLabel.defaultProps = {
  content: 'Plano, TX',
};

export default CardLabel;
