import { usePendo } from '~Common/hooks/usePendo';
import { useHelpScout } from '~Common/hooks/useHelpScout';
import { useAlgoliaAuth } from '~GlobalSearch/hooks/useAlgoliaAuth';
import { useTimezone } from '~Deprecated/hooks/profile/useUserProfile';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useGlobalSearchKeyboardShortcut } from './useMeetingsSearchKeyboardShortcut';

declare global {
  interface Window {
    ideasPortalUrl: string;
  }
}

// A spot to load hooks once the user is fully signed into the app
export const useRootHooks = (): void => {
  // Let's make sure the timezone is in local storage
  useTimezone();
  useGlobalSearchKeyboardShortcut();
  useHelpScout();
  usePendo();
  useAlgoliaAuth();

  window.ideasPortalUrl = useFeatureFlag<string>('webClientIdeasPortalLocation');
};
