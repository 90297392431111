import {
  ComponentProps,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { css } from '@emotion/react';
import { Collapse } from '@mui/material';
import { Goals } from '@leadr-hr/types';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';

import { forDesktopObject, withoutDesktopObject } from '~Common/styles/mixins';
import { palette } from '~Common/styles/colors';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import MeetingGoalCard from '~Meetings/components/details/goals/MeetingGoalCard';
import OldMeetingGoalCard from '~Goals/OldGoals/OldMeetingGoalCard';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { INITIAL_STATIC_OPERATING_OBJECTIVE_GOALS_PARAMS } from '~Meetings/const/goals';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';

const styles = {
  container: css({
    padding: '1rem 1.75rem',
  }, withoutDesktopObject({
    boxShadow: 'none',
    padding: '1rem',
  }), forDesktopObject({
    backgroundColor: palette.neutrals.white,
    boxShadow: '0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.07)',
    borderRadius: '0.5rem',
  })),

  header: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.75rem',
  }),

  collapseButton: css({
    padding: 0,
    marginRight: '0.6125rem',
  }),

  title: css({
    fontSize: '1.125rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  }),
  addNewButton: css({
    fontSize: '.75rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  }),
  addNewButtonIcon: css({
    gap: '.25rem',
    svg: {
      fontSize: '.75rem',
      width: '.75rem',
      height: '.75rem',
    },
  }),
  divider: css({
    margin: '0 0 0.75rem 0',
  }),

  cardContainer: css({
    display: 'flex',
    overflowX: 'auto',

    // Needed so the box shadow of the goal cards isn't cut off
    padding: '0.5rem',
  }),

  goalCard: css({
    marginRight: '0.625rem',
  }),

  emptyStateButton: css({
    padding: 0,
    fontStyle: 'italic',
  }),

  emptyStateMessage: css({
    fontStyle: 'italic',
    color: palette.neutrals.gray700,
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  standardOperatingObjectives?: Goals.Goal[] | Goals.GoalWithCascading[],
  isLoading: boolean,
  isExpanded: boolean,
  onClick: () => void,
  cascadingGoals: boolean,
  isMobile: boolean,
  shouldShowActionableEmptyState?: boolean,
  isUserOnTeam?: boolean,
  isAdmin?: boolean,
  isExecutive?: boolean,
}

export const View = ({
  isLoading,
  isExpanded,
  standardOperatingObjectives = [],
  onClick,
  shouldShowActionableEmptyState,
  isUserOnTeam,
  isAdmin,
  isExecutive,
  cascadingGoals,
  isMobile,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    <header css={styles.header}>
      <LeadrButton
        css={styles.collapseButton}
        onClick={onClick}
        variant="icon"
        data-test-id="meetingDetailsStandardOperatingObjectivesToggle"
      >
        { isExpanded && (
          <FontAwesomeIcon
            icon={faChevronDown}
          />
        )}

        { !isExpanded && (
          <FontAwesomeIcon
            icon={faChevronUp}
          />
        )}
      </LeadrButton>

      <div css={styles.title}>
        <div>
          <span data-test-id="meetingDetailsStandardOperationObjectives">
            {`Standard Operating Objectives ${standardOperatingObjectives.length ? `(${standardOperatingObjectives.length})` : ''}`}
          </span>
        </div>
        { !!standardOperatingObjectives?.length && shouldShowActionableEmptyState && (
        <LeadrButton
          component={Link}
          to={GoalsRoutes.Create}
          variant="text"
          data-test-id="standardOperatingObjectivesAddNew"
          css={styles.addNewButton}
          textButtonColor={palette.brand.indigo}
        >
          <LeadrButton.IconAndText
            icon={faPlus}
            text={isMobile ? '' : 'Add New'}
            css={styles.addNewButtonIcon}
          />
        </LeadrButton>
        )}
      </div>
    </header>

    <Collapse
      in={isExpanded}
    >
      <hr css={styles.divider} />

      <div>
        { isLoading && (
          <div css={styles.cardContainer}>
            <MeetingGoalCard.Skeleton css={styles.goalCard} />
            <MeetingGoalCard.Skeleton css={styles.goalCard} />
            <MeetingGoalCard.Skeleton css={styles.goalCard} />
            <MeetingGoalCard.Skeleton css={styles.goalCard} />
          </div>
        )}

        { !isLoading && (
          <>
            {!!standardOperatingObjectives.length && (
              <div css={styles.cardContainer}>
                {cascadingGoals && (
                  <>
                    { standardOperatingObjectives.map((standardOperatingObjective) => {
                      if ('totalChildGoals' in standardOperatingObjective) {
                        return (
                          <MeetingGoalCard
                            key={standardOperatingObjective.goalId}
                            css={styles.goalCard}
                            canAccessGoal={isUserOnTeam || isAdmin || isExecutive}
                            goalId={standardOperatingObjective.goalId}
                            title={standardOperatingObjective.title}
                            status={standardOperatingObjective.statusUpdates[standardOperatingObjective.statusUpdates.length - 1].status}
                            category={standardOperatingObjective.category}
                            startTime={standardOperatingObjective.startTimeInMillis}
                            endTime={standardOperatingObjective.endTimeInMillis}
                            totalChildGoals={standardOperatingObjective.totalChildGoals}
                          />
                        );
                      }
                      return (
                        <MeetingGoalCard
                          key={standardOperatingObjective.goalId}
                          css={styles.goalCard}
                          canAccessGoal={isUserOnTeam || isAdmin || isExecutive}
                          goalId={standardOperatingObjective.goalId}
                          title={standardOperatingObjective.title}
                          status={standardOperatingObjective.statusUpdates[standardOperatingObjective.statusUpdates.length - 1].status}
                          category={standardOperatingObjective.category}
                          startTime={standardOperatingObjective.startTimeInMillis}
                          endTime={standardOperatingObjective.endTimeInMillis}
                          totalChildGoals={0}
                        />
                      );
                    })}
                  </>
                )}
                {!cascadingGoals && (
                  <>
                    {standardOperatingObjectives.map((standardOperatingObjective) => (
                      <OldMeetingGoalCard
                        key={standardOperatingObjective.goalId}
                        css={styles.goalCard}
                        goal={standardOperatingObjective}
                        canAccessGoal={isUserOnTeam || isAdmin || isExecutive}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
            {!standardOperatingObjectives.length && (
              <>
                {shouldShowActionableEmptyState && (
                  <div>
                    <LeadrButton
                      data-test-id="standardOperatingObjectivesEmptyStateButton"
                      css={styles.emptyStateButton}
                      variant="text"
                      component={Link}
                      to={GoalsRoutes.Create}
                    >
                      Add Objectives
                    </LeadrButton>

                    <span css={styles.emptyStateMessage}>
                      &nbsp;for this team
                    </span>
                  </div>
                )}

                {!shouldShowActionableEmptyState && (
                  <span css={styles.emptyStateMessage}>
                    Standard Operating Objectives not created
                  </span>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Collapse>
  </div>
);

export interface StandardOperatingObjectivesProps extends ComponentProps<'div'> {
  teamId: string,
  huddleId: string,
}

const StandardOperatingObjectives = ({
  teamId,
  huddleId,
  ...props
}: StandardOperatingObjectivesProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(true);
  const orgUserId = getOrganizationUserId();
  const { isAdmin, isExecutive } = useUserPermissions();
  const cascadingGoals = useFeatureFlag('cascadingGoals');
  const { orgLevelEnableCascadingGoals } = useEnableCascadingGoals();

  const { data: teamDetailsData, isLoading: isTeamDetailsLoading } = useGetTeamDetails({ teamId, huddleId });
  const params: Goals.Requests.GetGoalsRequestQueryParameters = {
    ...INITIAL_STATIC_OPERATING_OBJECTIVE_GOALS_PARAMS,
    contextId: teamId,
    enableCascading: orgLevelEnableCascadingGoals,
  };

  const { data, isLoading: isGoalsLoading } = useGetGoals({ params, enabled: !isTeamDetailsLoading });

  const shouldShowActionableEmptyState = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    isAdmin || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    isAdmin,
    orgUserId,
    teamDetailsData?.response.ownerOrgUserIds,
  ]);

  const [isLoading] = useSkeletonLoaders(isTeamDetailsLoading || isGoalsLoading);

  const onClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [
    isExpanded,
  ]);

  const isUserOnTeam = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    teamDetailsData?.response.memberOrgUserIds.includes(orgUserId!)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    orgUserId,
    teamDetailsData?.response,
  ]);

  const isMobile = useIsMobileQuery();

  const hookProps = {
    standardOperatingObjectives: data?.response ?? [],
    isLoading,
    isExpanded,
    onClick,
    shouldShowActionableEmptyState,
    isUserOnTeam,
    isAdmin,
    isExecutive,
    cascadingGoals,
    isMobile,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default StandardOperatingObjectives;
