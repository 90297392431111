import { css } from '@emotion/react';
import { useState, ChangeEvent } from 'react';
import { OrgSettings } from '~Common/const/Organization';

import { palette } from '~Common/styles/colors';
import DrawerInput from '~Common/V3/components/DrawerInput';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';

const styles = {
  customPersonalityTypeModels: css({}),
  descriptionText: css({
    color: palette.neutrals.gray700,
    marginBottom: '0.5rem',
  }),
  customPersonalityFieldContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  customPersonalityField: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
  }),
};

interface ViewProps {
  enableCustomPersonality1: boolean | undefined,
  customPersonality1Name: string | undefined,
  enableCustomPersonality2: boolean | undefined,
  customPersonality2Name: string | undefined,
  handleEnableCustomPersonality1Change: (event: ChangeEvent<HTMLInputElement>) => void,
  handleEnableCustomPersonality2Change: (event: ChangeEvent<HTMLInputElement>) => void,
}

const View = ({
  enableCustomPersonality1,
  handleEnableCustomPersonality1Change,
  customPersonality1Name,
  enableCustomPersonality2,
  handleEnableCustomPersonality2Change,
  customPersonality2Name,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.customPersonalityTypeModels}
    {...props}
  >
    <SettingsSectionHeader
      renderContents={() => (
        <>Custom Personality Type Models</>
      )}
    />
    <p css={styles.descriptionText}>
      Leadr profiles have 4 personality types built in (Enneagram, DiSC, Myers-Briggs, and StrengthsFinder).
      You may add up to 2 additional custom models for members of your Organization below (Max length of 50 characters).
    </p>
    <div css={styles.customPersonalityFieldContainer}>
      <div css={styles.customPersonalityField}>
        <CustomCheckbox
          name="enableCustomPersonality1"
          checked={enableCustomPersonality1}
          onChange={handleEnableCustomPersonality1Change}
        />
        <DrawerInput
          maxLength={50}
          name="customPersonality1Name"
          label="Custom Field 1"
          required={enableCustomPersonality1}
          initialValue={customPersonality1Name}
        />
      </div>
      <div css={styles.customPersonalityField}>
        <CustomCheckbox
          name="enableCustomPersonality2"
          checked={enableCustomPersonality2}
          onChange={handleEnableCustomPersonality2Change}
        />
        <DrawerInput
          maxLength={50}
          name="customPersonality2Name"
          label="Custom Field 2"
          required={enableCustomPersonality2}
          initialValue={customPersonality2Name}
        />
      </div>
    </div>
  </div>
);

interface CustomPersonalityTypeModelsProps {
  orgSettings: OrgSettings,
}

const CustomPersonalityTypeModels = ({
  orgSettings,
  ...props
}: CustomPersonalityTypeModelsProps): JSX.Element => {
  const {
    enableCustomPersonality1: initialEnableCustomPersonality1,
    customPersonality1Name,
    enableCustomPersonality2: initialEnableCustomPersonality2,
    customPersonality2Name,
  } = orgSettings;

  const [enableCustomPersonality1, setEnableCustomPersonality1] = useState(initialEnableCustomPersonality1);
  const [enableCustomPersonality2, setEnableCustomPersonality2] = useState(initialEnableCustomPersonality2);

  const handleEnableCustomPersonality1Change = (event: ChangeEvent<HTMLInputElement>): void => {
    setEnableCustomPersonality1(event.target.checked);
  };

  const handleEnableCustomPersonality2Change = (event: ChangeEvent<HTMLInputElement>): void => {
    setEnableCustomPersonality2(event.target.checked);
  };

  const hookProps = {
    enableCustomPersonality1,
    handleEnableCustomPersonality1Change,
    customPersonality1Name,
    enableCustomPersonality2,
    handleEnableCustomPersonality2Change,
    customPersonality2Name,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CustomPersonalityTypeModels;
