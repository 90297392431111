import Avatar, { AVATAR_CONTEXT } from './index';

const ImagePickerPreviewAvatar = ({
  ...props
}) => (
  <Avatar
    {...props}
    {...AVATAR_CONTEXT.PREVIEW}
  />
);

export default ImagePickerPreviewAvatar;
