import { ComponentProps, Fragment } from 'react';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { Question } from '~Insights/const/pulseSurveyQuestions';
import { palette } from '~Common/styles/colors';
import Button from '~Common/V3/components/Button';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import { forMobile, forMobileObject } from '~Common/styles/mixins';
import InsightCardTitle from './InsightCardTitle';

const styles = {
  category: css({
    color: palette.neutrals.gray700,
    fontSize: '14px',
    fontWeight: '300',
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.25rem',
  }),
  heading: css({
    alignItems: 'center',
    columnGap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
  }),
  label: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.gray50,
    borderRadius: '0.5rem',
    color: palette.neutrals.gray700,
    cursor: 'pointer',
    display: 'flex',
    flex: '1',
    fontSize: '14px',
    fontWeight: '400',
    justifyContent: 'center',
    margin: '0',
    minHeight: '2.75rem',
    padding: '0.5rem',
    textAlign: 'center',

    'input[type="radio"]:checked + &, &:active': {
      backgroundColor: palette.brand.sky,
      color: palette.neutrals.white,
    },
  }),
  navigation: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    flexShrink: '0',
  }),
  options: (optionCount: number, forceMobile?: boolean) => {
    const isAboveWrapLimit = optionCount > 5;

    const mobileGridTemplateColumns = isAboveWrapLimit ? 'repeat(3, 1fr)' : '1fr';
    const desktopGridTemplateColumns = `repeat(${optionCount}, 1fr)`;

    const mobileGridTemplateRows = isAboveWrapLimit ? `repeat(${Math.ceil(optionCount / 3)}, 1fr)` : '1fr';
    const desktopGridTemplateRows = '1fr';

    return css({
      display: 'grid',
      gridGap: '0.5rem',
      gridTemplateColumns: forceMobile ? mobileGridTemplateColumns : desktopGridTemplateColumns,
      gridTemplateRows: forceMobile ? mobileGridTemplateRows : desktopGridTemplateRows,
      alignItems: 'stretch',
      justifyContent: 'stretch',
      alignContent: 'stretch',
    }, forMobileObject({
      gridTemplateColumns: mobileGridTemplateColumns,
      gridTemplateRows: mobileGridTemplateRows,
    }));
  },
  question: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    rowGap: '0.25rem',

    '& p': {
      marginBlock: '0',
    },
  }),
  submitRow: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    button: {
      cursor: 'pointer',
    },
  }, forMobile(`
    & button {
      width: 100%;

      & div {
        justify-content: center;
      }
    }
  `)),

  navButton: css({
    border: `1px solid ${palette.neutrals.gray300}`,
    color: palette.neutrals.gray500,
    cursor: 'pointer',
    fontSize: '1.25rem',
    padding: '0.6rem 1.125rem 0.4rem',

    '&:disabled': {
      backgroundColor: palette.neutrals.gray50,
    },
  }),
  prevButton: css({
    borderRadius: '0.5rem 0 0 0.5rem',
    borderRight: '0',
  }),
  nextButton: css({
    borderRadius: '0 0.5rem 0.5rem 0',
  }),
};

export interface PulseSurveyQuestionProps extends ComponentProps<'section'> {
  isSubmitEnabled: boolean,
  onSelectValue: (value: number) => void,
  onClickPrevButton: () => void,
  onClickNextButton: () => void,
  onSubmit: () => void,
  question: Question,
  questionIndex: number,
  questionCount: number,
  selectedValue?: number,
  pulseSurveyIsSubmitting: boolean,
  forceMobile?: boolean,
}

const PulseSurveyQuestion = ({
  isSubmitEnabled,
  onSelectValue,
  onClickPrevButton,
  onClickNextButton,
  onSubmit,
  question,
  questionIndex,
  questionCount,
  selectedValue,
  pulseSurveyIsSubmitting,
  forceMobile,
  ...props
}: PulseSurveyQuestionProps): JSX.Element => (
  <section
    css={styles.container}
    {...props}
  >
    <div css={styles.heading}>
      <div css={styles.question}>
        <InsightCardTitle>
          {question.text}
          &nbsp;
          (
          {questionIndex + 1}
          /
          {questionCount}
          )
        </InsightCardTitle>
        {question.category && (
          <p css={styles.category}>
            {question.category}
          </p>
        )}
      </div>
      <div css={styles.navigation}>
        <Button
          css={[styles.navButton, styles.prevButton]}
          title="Previous Question"
          variant="text"
          disabled={questionIndex === 0}
          onClick={onClickPrevButton}
          renderContents={() => <FontAwesomeIcon icon={faChevronLeft} />}
        />
        <Button
          css={[styles.navButton, styles.nextButton]}
          title="Next Question"
          variant="text"
          disabled={questionIndex >= questionCount - 1}
          onClick={onClickNextButton}
          renderContents={() => <FontAwesomeIcon icon={faChevronRight} />}
        />
      </div>
    </div>
    <div css={styles.options(question.options.length, forceMobile)}>
      {question.options.map((option) => (
        <Fragment key={option.value}>
          <input
            name={questionIndex.toString()}
            id={`${questionIndex}-${option.value}`}
            type="radio"
            value={option.value}
            checked={option.value === selectedValue}
            onChange={() => onSelectValue(option.value)}
            hidden
          />
          <label
            htmlFor={`${questionIndex}-${option.value}`}
            key={option.value}
            css={styles.label}
          >
            {option.text}
          </label>
        </Fragment>
      ))}
    </div>
    {questionIndex === questionCount - 1 && (
      <div css={styles.submitRow}>
        <SpinnerButton
          color="sky"
          disabled={!isSubmitEnabled}
          isLoading={pulseSurveyIsSubmitting}
          label="Submit Responses"
          loadingLabel="Submitting..."
          onClick={() => onSubmit()}
        />
      </div>
    )}
  </section>
);

export default PulseSurveyQuestion;
