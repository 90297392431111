import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/pro-duotone-svg-icons';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import DrawerAvatar from '~Deprecated/common/Components/People/Avatar/DrawerAvatar';
import DrawerListItem from '../Drawers/DrawerListItem';

const ClickableDrawerPerson = ({
  onClick,
  person,
  disabled,
  ...props
}) => (
  <DrawerListItem
    primaryText={`${person?.firstName} ${person?.lastName} ${person?.administrativeStatus === 'INACTIVE' ? '(Deactivated User)' : ''}`}
    secondaryText={person?.jobTitle}
    onClick={onClick}
    disabled={disabled}
    renderAvatar={() => (
      <DrawerAvatar
        imgUrl={person?.profileImageUrl || person?.imageUrl}
        name={`${person?.firstName} ${person?.lastName} ${person?.administrativeStatus === 'INACTIVE' ? '(Deactivated User)' : ''}`}
      />
    )}
    renderAdornment={disabled ? undefined : () => (
      <FontAwesomeIcon
        icon={faExchangeAlt}
      />
    )}
    {...props}
  />
);

ClickableDrawerPerson.propTypes = {
  onClick: PropTypes.func.isRequired,
  person: ATTENDEE_SHAPE.isRequired,
  disabled: PropTypes.bool,
};

ClickableDrawerPerson.defaultProps = {
  disabled: false,
};

export default ClickableDrawerPerson;
