import { css } from '@emotion/react';
import TooltipOnTruncate from '~Common/V3/components/TooltipOnTruncate';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { useContext } from 'react';
import { TemplateItemActionsContext } from '~Meetings/components/topic-suggestions/contexts/TemplateItemsActionContext';
import { CreateAgendaTopicTemplateDetailsWithLocalID, EditAgendaTopicTemplateDetailsWithLocalID } from '~Meetings/components/topic-suggestions/const/types';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { isHTMLText } from '~Common/utils/isHTMLText';
import { withLineClamp } from '~Common/styles/mixins';
import AgendaTopicTextEditor from './AgendaTopicTextEditor';
import { useShowItemEditor } from '../../stores/useShowItemEditor';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';

const styles = {
  topicContainer: css({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
    padding: '.5rem 1rem',
    borderRadius: '.3125rem',
  }),
  textContainer: css({
    overflow: 'hidden',
  }),
  text: css({
    color: palette.neutrals.gray900,
  }),
  buttonsContainer: css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: '.5rem',
  }),
  agendaText: css({}, withLineClamp(1)),
};

interface ViewProps {
  agendaText: string,
  isAgendaTextHtml: boolean,
  topicLocalId: string,
  showAgendaTopicEditor: boolean,
  handleSaveTopicTextEdit: (topicText: string) => void,
  renderDragHandle?: () => JSX.Element,
  sectionLocalId?: string,
}

const View = ({
  agendaText,
  isAgendaTextHtml,
  topicLocalId,
  showAgendaTopicEditor,
  handleSaveTopicTextEdit,
  renderDragHandle,
  sectionLocalId,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {showAgendaTopicEditor && (
      <AgendaTopicTextEditor
        topicLocalId={topicLocalId}
        agendaTopicText={agendaText}
        onSave={handleSaveTopicTextEdit}
      />
    )}
    {!showAgendaTopicEditor && (
      <div css={styles.topicContainer}>
        {renderDragHandle?.()}
        <div css={styles.textContainer}>
          {isAgendaTextHtml && (
            <div>
              <HTMLRenderer css={styles.agendaText} htmlText={agendaText} />
            </div>
          )}
          {!isAgendaTextHtml && (
          <TooltipOnTruncate css={styles.text} text={agendaText} />
          )}
        </div>
        <div css={styles.buttonsContainer}>
          <EditButton topicLocalId={topicLocalId} />
          <DeleteButton topicLocalId={topicLocalId} sectionLocalId={sectionLocalId} />
        </div>
      </div>
    )}
  </div>
);

interface AgendaTopicProps {
  agendaTopic: CreateAgendaTopicTemplateDetailsWithLocalID | EditAgendaTopicTemplateDetailsWithLocalID,
  topicLocalId: string,
  renderDragHandle?: () => JSX.Element,
  sectionLocalId?: string,
}

const AgendaTopic = ({
  agendaTopic,
  topicLocalId,
  sectionLocalId,
  ...props
}: AgendaTopicProps): JSX.Element => {
  const {
    getIsItemEditorVisible,
  } = useShowItemEditor((state) => ({
    getIsItemEditorVisible: state.getIsItemEditorVisible,
  }));
  const showAgendaTopicEditor = getIsItemEditorVisible(topicLocalId);

  const { editTemplateItem } = useContext(TemplateItemActionsContext);

  const agendaText = agendaTopic.text;
  const isAgendaTextHtml = !!agendaText && isHTMLText(agendaText);

  const handleSaveTopicTextEdit = (topicText: string): void => {
    editTemplateItem({
      localId: topicLocalId,
      item: {
        ...agendaTopic,
        text: topicText,
      },
      sectionLocalId,
    });
  };

  const hookProps = {
    agendaText,
    isAgendaTextHtml,
    topicLocalId,
    showAgendaTopicEditor,
    handleSaveTopicTextEdit,
    sectionLocalId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaTopic;
