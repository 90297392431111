import { css } from '@emotion/react';
import { faMegaphone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent } from 'react';
import { hexToRGBA, palette } from '~Common/styles/colors';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { SurveyTemplate } from '~Surveys/Hooks/useSurveyTemplates';
import { DrawerTab } from '.';
import { TemplateRow } from './TemplateRow';

const styles = {
  bodyContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  bullhorn: css({
    color: palette.brand.blue,
    fontSize: '2rem',
  }),
  buttonList: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.625rem',
    padding: 0,
  }),
  infoBox: css({
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: hexToRGBA(palette.brand.sky, 0.1),
    color: palette.neutrals.gray800,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.875rem',
    fontWeight: 500,
    gap: '1rem',
    padding: '1rem',
  }),
  templatesHeading: css({
    color: palette.neutrals.gray500,
    fontSize: '0.625rem',
    letterSpacing: '0.2em',
    paddingTop: '0.6rem',
    textTransform: 'uppercase',
  }),
};

interface TemplatesDrawerBodyProps {
  activeTab: DrawerTab,
  companyName: string,
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
  templates: SurveyTemplate[],
}

export const TemplatesDrawerBody = ({
  activeTab,
  companyName,
  onSearch,
  templates,
}: TemplatesDrawerBodyProps): JSX.Element => (
  <div css={styles.bodyContainer}>
    <div css={styles.infoBox}>
      <FontAwesomeIcon
        icon={faMegaphone}
        css={styles.bullhorn}
      />
      {activeTab === DrawerTab.YourTemplates && (
        <>
          Leadr stores your created survey templates here.
        </>
      )}
      {activeTab === DrawerTab.CompanyTemplates && (
        <>
          {`This is where Leadr stores the survey templates that ${companyName}
          has created for you to use.`}
        </>
      )}
      {activeTab === DrawerTab.LeadrTemplates && (
        <>
          These are survey templates created by Leadr for your use.
        </>
      )}
    </div>
    <LeadrSearchField data-test-id="surveysTemplateSearchField" onChange={onSearch} />
    {templates.length > 0 && (
      <>
        <p css={styles.templatesHeading}>Templates</p>
        <ul css={styles.buttonList}>
          {templates?.map((template) => (
            <TemplateRow
              key={template.id}
              template={template}
            />
          ))}
        </ul>
      </>
    )}
    {templates.length === 0 && 'There are no templates to display.'}
  </div>
);
