import { capitalize } from 'lodash';
import { SelectChangeEvent } from '@mui/material';
import {
  SyntheticEvent, useState, Dispatch, SetStateAction, useEffect, ChangeEvent,
} from 'react';
import {
  GridSelectionModel, GridRowId, GridComparatorFn, gridStringOrNumberComparator, GridEventListener, GridFilterModel,
} from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter as faFilterLight } from '@fortawesome/pro-light-svg-icons';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';
import { filtersTemplate } from '~Common/components/Drawers/FilterDrawer';
import emptySearch from '~Assets/images/empty-views/emptySearch.png';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { User } from '~Common/const/interfaces';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { useGetAdminData } from '~Deprecated/hooks/admin/useGetUsersList';
import { PAGE_STYLES } from '~Admin/const/pageStyles';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

import ViewCard from '~Common/components/Cards/ViewCard';
import Dropdown from '~Common/V3/components/Dropdown';
import AdminPeopleTabNavigation from '~UserGroups/components/AdminPeopleTabNavigation';

import ActiveUsers from '~Admin/components/tables/ActiveUsers';
import ImportedUsers from '~Admin/components/tables/ImportedUsers';
import DeactivatedUsers from '~Admin/components/tables/DeactivatedUsers';
import InvitedUsers from '~Admin/components/tables/InvitedUsers';

import { useDeactivateUser } from '~Deprecated/hooks/admin/useDeactivateUser';
import { useDeleteUser } from '~Deprecated/hooks/admin/useDeleteUser';
import { useResendUserInvites } from '~Deprecated/hooks/admin/useResendUserInvites';
import { useSendInvitesToImportedUsers } from '~Deprecated/hooks/admin/useSendInvitesToImportedUsers';
import { useActivateUser } from '~Deprecated/hooks/admin/useActivateUser';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { PEOPLE_FILTERS_QUERY_KEY } from '~Common/hooks/useGetPeopleFilters';

import DeleteConfirmationPopover, { useDeleteConfirmationPopover } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import DeactivateConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeactivateConfirmationButtons';

import { useEditUser } from '~Admin/hooks/usePeopleTableActions';

import CSVFileUploadInterface from '~Common/const/CSVFileUploadInterface/CSVFileUploadInterface';
import FlatButton from '~Common/V3/components/FlatButton';

import { useStoreData } from '~Common/hooks/useStoreData';
import { CardSkeleton } from '~Common/V3/components/Card';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';

import { AdminTab, ActionType, rowsPerPageOptions } from '~Admin/const/defaults';
import { useFilterUsers } from '~Admin/hooks/useFilterUsers';
import { ADMIN_PEOPLE_NEW_IMPORT, PEOPLE_ADMIN, PEOPLE_ADMIN_IMPORT } from '~Common/const/routes';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { useIsOrganizationInTrial } from '~Common/hooks/useIsOrganizationInTrial';
import Tooltip from '~Common/components/Tooltip';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { Link } from 'react-router-dom';
import { useProfileEditRestrictions } from '~Common/hooks/people/useProfileEditRestrictions';
import { css } from '@emotion/react';
import PeoplePageTabNavigation from '~People/components/Topbars/PeoplePageTabNavigation';
import { navigateAction } from '~Deprecated/actions/navigate';
import { forMobileObject } from '~Common/styles/mixins';
import LeadrTabs from '~Common/V3/components/LeadrTabs';
import { toTitleCase } from '~Common/utils';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useImportStatus } from '~Admin/Importer/Hooks/useImportStatus';
import ThreeDotLoader from '~Common/V3/components/ThreeDotLoader';
import { blankUser } from '../schemata/InviteUserFormSchema';

const adminTabs = [
  {
    label: toTitleCase(AdminTab.Active),
    link: AdminTab.Active,
    'data-test-id': 'peopleAdminActiveTab',
  },
  {
    label: toTitleCase(AdminTab.Invited),
    link: AdminTab.Invited,
    'data-test-id': 'peopleAdminInvitedTab',
  },
  {
    label: toTitleCase(AdminTab.Imported),
    link: AdminTab.Imported,
    'data-test-id': 'peopleAdminImportedTab',
  },
  {
    label: toTitleCase(AdminTab.Deactivated),
    link: AdminTab.Deactivated,
    'data-test-id': 'peopleAdminDeactivatedTab',
  },
];

const styles = {
  ...PAGE_STYLES,
  buttonsContainer: css({
    marginLeft: 'auto',
    marginBottom: '1.875rem',
  }, forMobileObject({
    marginLeft: 0,
    marginTop: '0.75rem',
  })),
  leadrTabs: css({
    marginTop: '1.5rem',
  }),
};

interface ViewProps {
  allUsers: User[],
  changeRowsPerPage: (event: SelectChangeEvent) => void,
  changeTab: (event: SyntheticEvent, tab: AdminTab) => void,
  expandedTab: AdminTab,
  filteredUsers: User[],
  isLoading: boolean,
  numUsers: number,
  rowsPerPage: number,
  searchString: string,
  selectionModel: GridSelectionModel,
  setSelectionModel: (rowIds: GridSelectionModel) => void,
  filterModel: GridFilterModel,
  setFilterModel: (items: GridFilterModel) => void,
  setActionType: Dispatch<SetStateAction<ActionType | undefined>>,
  setDeactivateUser: Dispatch<SetStateAction<GridRowId[] | User[] | undefined>>,
  setDeleteUser: Dispatch<SetStateAction<GridRowId[] | undefined>>,
  setInviteUser: Dispatch<SetStateAction<User[] | undefined>>,
  setActivateUser: Dispatch<SetStateAction<User[] | undefined>>,
  isOpen: boolean,
  anchorEl: HTMLElement | null,
  popoverId: string | undefined,
  openConfirmationPopover: (event: SyntheticEvent<HTMLButtonElement>) => void,
  closeConfirmationPopover: (event: SyntheticEvent<HTMLElement>) => void,
  onDelete: () => void,
  handleClick: (event: SyntheticEvent<HTMLButtonElement>, action: ActionType, data: GridRowId[]) => void,
  inviteUserDrawer: () => void,
  showImportUI: boolean,
  setShowImportUI: Dispatch<SetStateAction<boolean>>,
  isFileUploading: boolean,
  setIsFileUploading: Dispatch<SetStateAction<boolean>>,
  refresh: () => Promise<void>,
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
  frontendUserGroups: boolean,
  openFilterDrawer: () => void,
  areFiltersActive: boolean,
  actionType: ActionType | undefined,
  searchText: string,
  handleCellClickEvent: GridEventListener<'cellClick'>,
  disableButton: boolean,
  frontendNewAdminBulkImport: boolean,
  organizationInTrial: boolean,
  disableStatusChanges: boolean,
  webAppTeamsV2: boolean,
  handleRouteChangeClick: (event: SyntheticEvent, route: string) => void,
  adminImportRoute: string,
  isMobile: boolean,
  isImporting: boolean,
}

interface NameImageCellValue {
  name: string;
  image: string;
}
const nameComparator: GridComparatorFn = (v1, v2, param1, param2) => gridStringOrNumberComparator(
  (v1 as NameImageCellValue).name,
  (v2 as NameImageCellValue).name,
  param1,
  param2,
);

const View = ({
  allUsers,
  changeRowsPerPage,
  changeTab,
  expandedTab,
  filteredUsers,
  isLoading,
  numUsers,
  rowsPerPage,
  searchString,
  selectionModel,
  setSelectionModel,
  filterModel,
  setFilterModel,
  setActionType,
  setDeactivateUser,
  setDeleteUser,
  setInviteUser,
  setActivateUser,
  isOpen,
  anchorEl,
  popoverId,
  openConfirmationPopover,
  closeConfirmationPopover,
  onDelete,
  handleClick,
  inviteUserDrawer,
  showImportUI,
  setShowImportUI,
  isFileUploading,
  setIsFileUploading,
  refresh,
  onSearch,
  frontendUserGroups,
  openFilterDrawer,
  areFiltersActive,
  actionType,
  searchText,
  handleCellClickEvent,
  disableButton,
  frontendNewAdminBulkImport,
  organizationInTrial,
  disableStatusChanges,
  webAppTeamsV2,
  handleRouteChangeClick,
  adminImportRoute,
  isMobile,
  isImporting,
}: ViewProps): JSX.Element => (
  <div>
    <div css={styles.pageHeader}>
      {webAppTeamsV2 && (
        <PeoplePageTabNavigation
          value={PEOPLE_ADMIN}
          handleRouteChangeClick={handleRouteChangeClick}
        />
      )}
      {!webAppTeamsV2 && (
        <AdminPeopleTabNavigation css={styles.tabNavigation} />
      )}
      <div css={styles.buttonsContainer}>
        {expandedTab === AdminTab.Active && (
          <>
            <LeadrButton
              css={[styles.adminWrapperButton, styles.transparentBG(disableButton)]}
              onClick={(e: SyntheticEvent<HTMLButtonElement>) => handleClick(e, ActionType.Deactivate, selectionModel)}
              type="submit"
              disabled={disableButton}
              variant={disableButton ? 'default' : 'ghost'}
              data-test-id="adminPeopleDeactivateUsers"
            >
              {selectionModel.length > 1 ? `Deactivate ${selectionModel.length} Users`
                : `Deactivate ${selectionModel.length ? selectionModel.length : ''} User`}
            </LeadrButton>
            <LeadrButton
              css={styles.adminWrapperButton}
              onClick={inviteUserDrawer}
              type="submit"
              disabled={isLoading || disableStatusChanges}
              data-test-id="adminPeopleInviteUser"
            >
              Invite Person
            </LeadrButton>
          </>
        )}
        {expandedTab === AdminTab.Invited && (
          <>
            <LeadrButton
              css={[styles.adminWrapperButton, styles.transparentBG(disableButton)]}
              onClick={(e: SyntheticEvent<HTMLButtonElement>) => handleClick(e, ActionType.Delete, selectionModel)}
              type="submit"
              disabled={disableButton}
              variant={disableButton ? 'default' : 'ghost'}
              data-test-id="adminPeopleDeleteInvites"
            >
              {selectionModel.length > 1 ? `Delete ${selectionModel.length} Invites`
                : `Delete ${selectionModel.length ? selectionModel.length : ''} Invite`}
            </LeadrButton>
            <LeadrButton
              css={[styles.adminWrapperButton, styles.transparentBG(disableButton)]}
              onClick={(e: SyntheticEvent<HTMLButtonElement>) => handleClick(e, ActionType.ResendInvite, selectionModel)}
              type="submit"
              disabled={disableButton}
              variant={disableButton ? 'default' : 'ghost'}
              data-test-id="adminPeopleResendInvites"
            >
              {selectionModel.length > 1 ? `Resend ${selectionModel.length} Invites`
                : `Resend ${selectionModel.length ? selectionModel.length : ''} Invite`}
            </LeadrButton>
            <LeadrButton
              css={styles.adminWrapperButton}
              onClick={inviteUserDrawer}
              type="submit"
              data-test-id="adminPeopleInviteUser"
              disabled={disableStatusChanges}
            >
              Invite Person
            </LeadrButton>
          </>
        )}
        {expandedTab === AdminTab.Imported && (
          <div css={styles.importBtnContainer}>
            {!showImportUI && (
            <>
              <LeadrButton
                css={[styles.adminWrapperButton, styles.transparentBG(disableButton)]}
                onClick={(e: SyntheticEvent<HTMLButtonElement>) => handleClick(e, ActionType.Delete, selectionModel)}
                type="submit"
                disabled={disableButton}
                variant={disableButton ? 'default' : 'ghost'}
                data-test-id="adminPeopleDeleteUsers"
              >
                {selectionModel.length > 1 ? `Delete ${selectionModel.length} Users`
                  : `Delete ${selectionModel.length ? selectionModel.length : ''} User`}
              </LeadrButton>
              <LeadrButton
                css={[styles.adminWrapperButton, styles.transparentBG(disableButton)]}
                onClick={(e: SyntheticEvent<HTMLButtonElement>) => handleClick(e, ActionType.Invite, selectionModel)}
                type="submit"
                disabled={disableButton}
                variant={disableButton ? 'default' : 'ghost'}
                data-test-id="adminPeopleSendInvites"
              >
                {selectionModel.length > 1 ? `Send ${selectionModel.length} Invites`
                  : `Send ${selectionModel.length ? selectionModel.length : ''} Invite`}
              </LeadrButton>
              {frontendNewAdminBulkImport && (
                <>
                  {!organizationInTrial && !disableStatusChanges && (
                    <LeadrButton
                      component={Link}
                      to={adminImportRoute}
                      css={styles.adminWrapperButton}
                      data-test-id="adminPeopleImportUsers"
                    >
                      Import Users
                    </LeadrButton>
                  )}
                  {organizationInTrial && (
                    <Tooltip content="Bulk import of users is not available during trials.">
                      <div>
                        <LeadrButton
                          css={[styles.adminWrapperButton, styles.disabledImportButton]}
                          component={Link}
                          to="#"
                          data-test-id="adminPeopleImportUsers"
                        >
                          Import Users
                        </LeadrButton>
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
              {!frontendNewAdminBulkImport && (
                <>
                  {!organizationInTrial && (
                    <LeadrButton
                      css={styles.adminWrapperButton}
                      onClick={(e: SyntheticEvent<HTMLButtonElement>) => handleClick(e, ActionType.ImportUI, selectionModel)}
                      type="submit"
                      disabled={isFileUploading}
                      data-test-id="adminPeopleImportUsers"
                    >
                      Import Users
                    </LeadrButton>
                  )}
                  {organizationInTrial && (
                    <Tooltip content="Bulk import of users is not available during trials.">
                      <div>
                        <LeadrButton
                          css={styles.adminWrapperButton}
                          type="submit"
                          disabled
                          data-test-id="adminPeopleImportUsers"
                        >
                          Import Users
                        </LeadrButton>
                      </div>
                    </Tooltip>
                  )}
                </>

              )}
            </>
            )}
            {showImportUI && (
              <LeadrButton
                css={styles.adminWrapperButton}
                onClick={refresh}
                type="submit"
                data-test-id="adminPeopleShowImportedUsers"
              >
                Show Imported People
              </LeadrButton>
            )}
          </div>
        )}
        {expandedTab === AdminTab.Deactivated && (
          <>
            <LeadrButton
              css={[styles.adminWrapperButton, styles.transparentBG(disableButton)]}
              onClick={(e: SyntheticEvent<HTMLButtonElement>) => handleClick(e, ActionType.Activate, selectionModel)}
              type="submit"
              disabled={disableButton}
              variant={disableButton ? 'default' : 'ghost'}
              data-test-id="adminPeopleReactivateUsers"
            >
              {selectionModel.length > 1 ? `Reactivate ${selectionModel.length} Users`
                : `Reactivate ${selectionModel.length ? selectionModel.length : ''} User`}
            </LeadrButton>
            <LeadrButton
              css={styles.adminWrapperButton}
              onClick={inviteUserDrawer}
              type="submit"
              data-test-id="adminPeopleInviteUser"
              disabled={disableStatusChanges}
            >
              Invite Person
            </LeadrButton>
          </>
        )}
        <DeleteConfirmationPopover
          closeConfirmationPopover={closeConfirmationPopover}
          open={isOpen}
          popoverId={popoverId}
          anchorEl={anchorEl}
          renderConfirmationButtons={({
            informationStyles,
            optionStyles,
            popoverButtonStyles,
          }) => (
            <>
              {actionType === ActionType.Deactivate && (
              <DeactivateConfirmationButtons
                informationStyles={informationStyles}
                optionStyles={optionStyles}
                popoverButtonStyles={popoverButtonStyles}
                onDelete={onDelete}
              />
              )}
              {actionType === ActionType.Delete && (
              <DeleteConfirmationButtons
                informationStyles={informationStyles}
                optionStyles={optionStyles}
                popoverButtonStyles={popoverButtonStyles}
                onDelete={onDelete}
              />
              )}
            </>
          )}
        />
      </div>
    </div>
    <ViewCard
      css={styles.viewCard}
      renderBody={() => (
        <div>
          <div css={styles.header}>
            <div css={styles.information}>
              <div css={styles.titleText}>People</div>
              <div css={styles.description}>
                View, edit, invite, and import users from your organization.
              </div>
            </div>
            <div css={styles.filtersWrapper}>
              <LeadrSearchField
                defaultValue={searchString}
                onChange={onSearch}
                data-test-id="userSearchBox"
              />
              {frontendUserGroups && (
                <LeadrButton
                  css={styles.filterButton}
                  variant="text"
                  onClick={openFilterDrawer}
                  disabled={(filteredUsers.length === 0 && searchText.length > 0) || (!areFiltersActive && filteredUsers.length === 0)}
                  data-test-id="adminPeopleFilterButton"
                >
                  <FontAwesomeIcon
                    size="lg"
                    icon={areFiltersActive ? faFilterSolid : faFilterLight}
                  />
                </LeadrButton>
              )}
            </div>
          </div>
          <LeadrTabs
            css={styles.leadrTabs}
            value={expandedTab}
            handleChange={changeTab}
            renderRightItem={isMobile ? undefined : () => (
              <div css={styles.tableControls}>
                <p>
                  {`${filteredUsers.length} ${capitalize(expandedTab)} User${filteredUsers.length === 1 ? '' : 's'}`}
                </p>
                Showing:
                <Dropdown
                  data-test-id="rowsDisplayed"
                  css={styles.dropdown}
                  items={rowsPerPageOptions}
                  onChange={changeRowsPerPage}
                  value={rowsPerPage.toString()}
                />
              </div>
            )}
          >
            {adminTabs.map((adminTab) => (
              <LeadrTabs.LinkTab
                label={adminTab.label}
                to={(location) => `${location.pathname}?tab=${adminTab.link}`}
                key={adminTab.link}
                value={adminTab.link}
                data-test-id={adminTab['data-test-id']}
              />
            ))}
          </LeadrTabs>
          {isLoading && (
            <MultipleSkeletonLoaders
              css={styles.listSection}
              numberOfSkeletons={1}
              renderSkeletonItem={() => (
                <CardSkeleton css={styles.cardSkeleton} />
              )}
            />
          )}
          {(isImporting || (!filteredUsers.length && !isLoading && !showImportUI)) && (
            <div css={styles.emptySearchOrFilters}>
              {!isImporting && (
                <>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <img css={styles.noSearchResultsImage} src={emptySearch} alt="No Search Results" data-test-id="adminNoSearchResults" />
                  {!areFiltersActive && searchText.length > 0 && !isImporting && (
                    <div>
                      {`No ${expandedTab} users to display. Try your search again.`}
                    </div>
                  )}
                  {!areFiltersActive && searchText.length === 0 && !isImporting && (
                    <div>
                      {`No ${expandedTab} users to display.`}
                    </div>
                  )}
                </>
              )}
              {isImporting && (
                <div>
                  <ThreeDotLoader
                    renderContents={() => (
                      <>
                        Import in Progress.
                        {' '}
                        <br />
                        {' '}
                        Leaving this page will not interrupt the import.
                        {' '}
                        <br />
                        {' '}
                        You will be notified via email once completed.
                      </>
                    )}
                  />
                </div>
              )}
              {areFiltersActive && (
                <>
                  <div>Your search or filters have no results.</div>
                  {filteredUsers.length === 0 && searchText.length === 0 && (
                    <FlatButton
                      css={styles.viewFiltersButton}
                      onClick={openFilterDrawer}
                      renderButtonContents={() => (
                        <div>View Filters</div>
                      )}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {showImportUI && expandedTab === AdminTab.Imported && (
          <CSVFileUploadInterface setIsFileUploading={setIsFileUploading} setShowImportUI={setShowImportUI} />
          )}
          {numUsers > 0 && !isLoading && filteredUsers.length > 0 && (
            <>
              {expandedTab === AdminTab.Active && (
                <ActiveUsers
                  filteredUsers={filteredUsers}
                  allUsers={allUsers}
                  pageSize={rowsPerPage}
                  selectionModel={selectionModel}
                  setSelectionModel={setSelectionModel}
                  setActionType={setActionType}
                  deactivateUser={setDeactivateUser}
                  openConfirmationPopover={openConfirmationPopover}
                  sortComparator={nameComparator}
                  handleCellClickEvent={handleCellClickEvent}
                  filterModel={filterModel}
                  setFilterModel={setFilterModel}
                />
              )}
              {expandedTab === AdminTab.Invited && (
                <InvitedUsers
                  filteredUsers={filteredUsers}
                  allUsers={allUsers}
                  pageSize={rowsPerPage}
                  selectionModel={selectionModel}
                  setSelectionModel={setSelectionModel}
                  openConfirmationPopover={openConfirmationPopover}
                  setActionType={setActionType}
                  deleteUser={setDeleteUser}
                  setInviteUser={setInviteUser}
                  filterModel={filterModel}
                  setFilterModel={setFilterModel}
                  handleCellClickEvent={handleCellClickEvent}
                />
              )}
              {expandedTab === AdminTab.Imported && !isImporting && (
                <>
                  {!showImportUI && (
                    <ImportedUsers
                      filteredUsers={filteredUsers}
                      allUsers={allUsers}
                      pageSize={rowsPerPage}
                      selectionModel={selectionModel}
                      setSelectionModel={setSelectionModel}
                      openConfirmationPopover={openConfirmationPopover}
                      setActionType={setActionType}
                      deleteUser={setDeleteUser}
                      setInviteUser={setInviteUser}
                      filterModel={filterModel}
                      setFilterModel={setFilterModel}
                      handleCellClickEvent={handleCellClickEvent}
                    />
                  )}
                </>
              )}
              {expandedTab === AdminTab.Deactivated && (
                <DeactivatedUsers
                  filteredUsers={filteredUsers}
                  pageSize={rowsPerPage}
                  selectionModel={selectionModel}
                  setSelectionModel={setSelectionModel}
                  setActionType={setActionType}
                  activateUser={setActivateUser}
                  filterModel={filterModel}
                  setFilterModel={setFilterModel}
                />
              )}
            </>
          )}
        </div>
      )}
    />
  </div>
);

const AdminIndex = (): JSX.Element => {
  const dispatch = useDispatch();

  const frontendUserGroups = useFeatureFlag('frontendUserGroups');
  const webAppTeamsV2 = useFeatureFlag('webAppTeamsV2');
  const frontendNewAdminBulkImport = useFeatureFlag('frontendNewAdminBulkImport');
  const [expandedTab, setExpandedTab] = useQueryParamState<AdminTab>('admin', 'tab', AdminTab.Active);
  const [rowsPerPage, setRowsPerPage] = useQueryParamState<number>('admin', 'rowsPerPage', 25);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [actionType, setActionType] = useState<ActionType>();
  const [deactivateUser, setDeactivateUser] = useState<GridRowId[] | User[]>();
  const deactivateUserMutation = useDeactivateUser();
  const [inviteUser, setInviteUser] = useState<User[]>();
  const resendUserInvitesMutation = useResendUserInvites();
  const sendInvitesToImportedUsersMutation = useSendInvitesToImportedUsers();
  const { organizationInTrial } = useIsOrganizationInTrial();
  const [deleteUser, setDeleteUser] = useState<GridRowId[]>();
  const deleteUserMutation = useDeleteUser();
  const adminImportRoute = webAppTeamsV2 ? PEOPLE_ADMIN_IMPORT : ADMIN_PEOPLE_NEW_IMPORT;
  const isMobile = useIsMobileQuery();
  const { isImporting } = useImportStatus();

  const [activateUser, setActivateUser] = useState<User[]>();
  const activateUserMutation = useActivateUser();

  const [showImportUI, setShowImportUI] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const { editUser } = useEditUser();
  const inviteUserDrawer = (): void => {
    const isInviteUser = true;
    const user = blankUser;
    editUser({ isInviteUser, user, allUsers });
  };

  const [searchText, setSearchText] = useState('');
  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };
  const handleClick = (event:SyntheticEvent<HTMLButtonElement>, action: ActionType, data:GridRowId[]): void => {
    if (action === ActionType.Deactivate) {
      openConfirmationPopover(event);
      setActionType(action);
      setDeactivateUser(data);
    }
    if (action === ActionType.Invite) {
      sendInvitesToImportedUsersMutation({ orgUserIds: [...data] });
    }
    if (action === ActionType.ResendInvite) {
      resendUserInvitesMutation({ orgUserIds: data });
    }
    if (action === ActionType.Delete) {
      openConfirmationPopover(event);
      setActionType(action);

      const temparray:GridRowId[] = [];
      data.forEach((orgUserId) => {
        temparray.push(orgUserId);
      });
      setDeleteUser(temparray);
    }
    if (action === ActionType.Activate) {
      data.forEach((id) => {
        activateUserMutation(id);
      });
    }
    if (action === ActionType.ImportUI) {
      setShowImportUI(true);
      // Need to do the new FF check here and redirect to the new area
    }
  };

  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('adminPeopleDeleteConfirmationPopover');

  const onDelete = (): void => {
    if (actionType === ActionType.Deactivate) {
      if (Array.isArray(deactivateUser)) {
        deactivateUser?.forEach((user) => {
          const loggedInUserId = getUserId();
          if (user !== loggedInUserId) {
            deactivateUserMutation(user);
          }
        });
      } else {
        deactivateUserMutation(deactivateUser);
      }
    }
    if (actionType === ActionType.Delete) {
      const conformDeleteUserDTO = {
        orgUserIds: deleteUser,
      };
      // @ts-expect-error once this is typed we can remove this
      deleteUserMutation(conformDeleteUserDTO, { onSuccess: setSelectionModel([]) });
    }
  };

  useEffect(() => {
    if (actionType === ActionType.ResendInvite) {
      if (Array.isArray(inviteUser)) {
        resendUserInvitesMutation({ orgUserIds: inviteUser });
      } else {
        resendUserInvitesMutation({ orgUserIds: [inviteUser] });
      }
    }
  }, [inviteUser, actionType, resendUserInvitesMutation]);

  useEffect(() => {
    if (actionType === ActionType.Invite) {
      if (Array.isArray(inviteUser)) {
        sendInvitesToImportedUsersMutation({ orgUserIds: inviteUser });
      } else {
        sendInvitesToImportedUsersMutation({ orgUserIds: [inviteUser] });
      }
    }
  }, [inviteUser, actionType, sendInvitesToImportedUsersMutation]);

  useEffect(() => {
    if (actionType === ActionType.Activate) {
      activateUserMutation(activateUser?.map((user) => user.id));
    }
  }, [activateUser, actionType, activateUserMutation]);

  const changeTab = (evt: SyntheticEvent, newTab: AdminTab): void => {
    setExpandedTab(newTab);
    setSelectionModel([]);
    // setLeadrSelectionModel([]);
  };

  const changeRowsPerPage = (event: SelectChangeEvent): void => {
    setRowsPerPage(Number(event.target.value));
  };

  const {
    users: allUsers, total: numUsers, isLoading,
  } = useGetAdminData({
    count: 10000,
    administrativeStatus: 'ACTIVE,CREATED,INVITED,INACTIVE',
  }) as { users: User[], total: number, isLoading: boolean };

  const refresh = async (): Promise<void> => {
    setShowImportUI(false);
    await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });
    await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'people'] });
    await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), PEOPLE_FILTERS_QUERY_KEY] });
  };

  const [filters, setFilters] = useQueryParamState<string[]>('admin', 'filters', []);

  const updateFilters = (newFilters: string[]): void => {
    setFilters(newFilters);
  };
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        columnField: 'name',
        operatorValue: 'contains',
        value: '',
      },
    ],
  });
  const filteredUsers = useFilterUsers(expandedTab, allUsers);
  const [dataStore, setDataStore] = useStoreData();

  useEffect(() => {
    // The below IF stops the re-render looping that was happening
    if (dataStore.length !== filteredUsers.length || filteredUsers.length !== allUsers.length) {
      setDataStore(filteredUsers);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredUsers, allUsers]);

  const openFilterDrawer = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...filtersTemplate,
        args: {
          allPeople: filteredUsers,
          updateFilters,
          drawerTitle: 'Admin',
          includeDirectReports: false,
          workflow: 'admin',
        },
      },
    }));
  };

  useEffect(() => {
    setFilterModel({
      items: [
        {
          columnField: 'fullName',
          operatorValue: 'contains',
          value: searchText,
        },
      ],
    });
  }, [searchText]);
  const handleCellClickEvent: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== 'actions' && params.field !== '__check__') {
      // I really hate this but we're in transition on the endpoints using id vs orgUserId
      // this will be removed once we're fully transitioned
      const userByOrdId = filteredUsers.find(({ orgUserId }) => orgUserId === params.id);
      const userById = filteredUsers.find(({ id }) => id === params.id);
      const user = userByOrdId ?? userById;
      editUser({ user, allUsers });
    }
  };

  const {
    isFieldDisabled: {
      status: disableStatusChanges,
    },
  } = useProfileEditRestrictions();

  const disableButton = disableStatusChanges || isLoading || selectionModel.length <= 0;

  const handleRouteChangeClick = (event: SyntheticEvent, newRoute: string): void => {
    if (newRoute) {
      dispatch(navigateAction({ pathname: newRoute }));
    }
  };

  const hookProps = {
    allUsers,
    filteredUsers,
    changeRowsPerPage,
    changeTab,
    expandedTab,
    isLoading,
    numUsers,
    rowsPerPage,
    searchString: '',
    selectionModel,
    setSelectionModel,
    filterModel,
    setFilterModel,
    setActionType,
    setDeactivateUser,
    setDeleteUser,
    setInviteUser,
    setActivateUser,
    isOpen,
    anchorEl,
    popoverId,
    openConfirmationPopover,
    closeConfirmationPopover,
    onDelete,
    handleClick,
    inviteUserDrawer,
    showImportUI,
    setShowImportUI,
    isFileUploading,
    setIsFileUploading,
    refresh,
    onSearch,
    frontendUserGroups,
    openFilterDrawer,
    areFiltersActive: filteredUsers.length !== allUsers.length && filters.length > 0,
    actionType,
    searchText,
    handleCellClickEvent,
    disableButton,
    frontendNewAdminBulkImport,
    organizationInTrial,
    disableStatusChanges,
    webAppTeamsV2,
    handleRouteChangeClick,
    adminImportRoute,
    isMobile,
    isImporting: isImporting && expandedTab === AdminTab.Imported,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, AdminIndex };
export default AdminIndex;
