import * as types from '../../actions';

const forgotPassword = (state = {}, action) => {
  const { response } = action;
  switch (action.type) {
    case types.FORGOT_PASSWORD_SUCCESS:
      return { ...state, response };
    case types.FORGOT_PASSWORD_ERROR:
      return { ...state, response };
    default:
      return state;
  }
};
export default forgotPassword;
