import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRepeat } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import { useFrequencyString } from '~Meetings/hooks/useFrequencyString';
import { useContext } from 'react';
import { MeetingInfoContext } from '../../../providers/MeetingInfoContext';

const styles = {
  recurrenceText: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
  icon: css({
    marginRight: '.375rem',
    width: '.75rem',
    height: '.75rem',
  }),
};

interface ViewProps {
  meetingFrequency: string,
}

const View = ({
  meetingFrequency,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.recurrenceText}
    {...props}
  >
    <FontAwesomeIcon
      css={styles.icon}
      icon={faArrowsRepeat}
    />
    <span>{meetingFrequency}</span>
  </div>
);

const RecurrenceText = ({
  ...props
}): JSX.Element => {
  const { frequency, daysOfWeek, startTimeInMillis } = useContext(MeetingInfoContext);

  const meetingFrequency = useFrequencyString({
    frequency,
    daysOfWeek,
    startTimeInMillis,
    includeTime: true,
  });

  const hookProps = {
    meetingFrequency,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default RecurrenceText;
