import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { palette } from '~Common/styles/colors';
import LeadrShieldIndigo from '~Assets/images/leadrShieldIndigo.svg';
import LeadrAdvantageIconLogo from '~Root/assets/images/leadrAdvantageIconLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch } from '@fortawesome/pro-regular-svg-icons';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useShowGlobalSearchModal } from '~GlobalSearch/stores/useShowGlobalSearchModal';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { navigationItemList } from '~Root/components/OldNavigationSidebar/navigationItemList';
import { OldNavigationSidebar } from '~Root/components/OldNavigationSidebar';
import { NavigationSidebar } from '~Root/components/NavigationSidebar';
import { useNavigationStore } from '~Root/components/OldNavigationSidebar/useNavigationStore';
import { hideForPrint } from '~Common/styles/mixins';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  header: css({
    alignItems: 'center',
    backgroundColor: palette.neutrals.white,
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1rem',
    height: '4.25rem',
    minHeight: '4.25rem',
    padding: '0.5rem',
    width: '100%',
  }, hideForPrint()),
  icon: css({
    fontSize: '1.5rem',
  }),
  logo: css({
    height: '2.25rem',
    marginLeft: '1rem',
  }),
  buttons: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem',
  }),
  menuWrapper: (isOpen: boolean) => css({
    height: '100vh',
    left: 0,
    maxHeight: '100vh',
    position: 'absolute',
    top: 0,
    transform: 'translateX(-100%)',
    transition: 'transform 250ms ease-in-out',
    width: '100%',
    zIndex: 10000,
  }, isOpen && {
    visibility: 'visible',
    transform: 'translateX(0%)',
  }),
  pageTitle: css({
    alignItems: 'center',
    color: palette.neutrals.gray900,
    display: 'flex',
    flex: 1,
    fontSize: '1.5rem',
    fontWeight: 600,
  }),
  partnerLogo: css({
    height: '2.5rem',
  }),
};

interface ViewProps {
  isMobileNavigationOpen: boolean,
  openMobileNavigation: () => void,
  handleShowSearchModalClick: () => void,
  pageTitle: string,
  useGlobalSearch: boolean,
  useSimplifiedNav: boolean,
  canViewTableGroup: boolean,
}

const View = ({
  isMobileNavigationOpen,
  openMobileNavigation,
  handleShowSearchModalClick,
  pageTitle,
  useGlobalSearch,
  useSimplifiedNav,
  canViewTableGroup,
}: ViewProps): JSX.Element => (
  <>
    <header css={styles.header}>
      {canViewTableGroup && (
        <LeadrAdvantageIconLogo css={[styles.logo, styles.partnerLogo]} title="The Table Group logo" data-test-id="leadrAdvantageIconLogo" />
      )}
      {!canViewTableGroup && (
        <LeadrShieldIndigo css={styles.logo} title="Leadr logo" data-test-id="leadrShieldIndigo" />
      )}
      <h1 css={styles.pageTitle}>
        {pageTitle}
      </h1>
      <div css={styles.buttons}>
        {useGlobalSearch && (
          <LeadrButton
            css={styles.icon}
            data-test-id="mobileSearchMeetings"
            variant="text"
            textButtonColor={palette.neutrals.gray700}
            onClick={handleShowSearchModalClick}
          >
            <FontAwesomeIcon icon={faSearch} />
          </LeadrButton>
        )}
        <LeadrButton
          css={styles.icon}
          data-test-id="openMobileNavigation"
          variant="text"
          textButtonColor={palette.neutrals.gray700}
          onClick={openMobileNavigation}
        >
          <FontAwesomeIcon icon={faBars} />
        </LeadrButton>
      </div>
    </header>
    <div css={styles.menuWrapper(isMobileNavigationOpen)}>
      {useSimplifiedNav && (
        <NavigationSidebar
          allowCollapse={false}
          isMobile
        />
      )}

      {!useSimplifiedNav && (
        <OldNavigationSidebar
          allowCollapse={false}
          isMobile
        />
      )}
    </div>
  </>
);

export const MobileNavigation = (): JSX.Element => {
  const isMobileNavigationOpen = useNavigationStore((state) => state.isMobileNavigationOpen);
  const openMobileNavigation = useNavigationStore((state) => state.openMobileNavigation);
  const { pathname } = useLocation();
  const useGlobalSearch = useFeatureFlag('useGlobalSearch');
  const useSimplifiedNav = useFeatureFlag('webAppSimplifiedNavigation');
  const isTableGroupCobrandingEnabled = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const { enableTheTable } = useOrgDetailsContext();
  const canViewTableGroup = enableTheTable && isTableGroupCobrandingEnabled;

  const setShowModal = useShowGlobalSearchModal((state) => state.setShowModal);

  const handleShowSearchModalClick = (): void => {
    setShowModal(true);
  };

  const activePage = useMemo(
    () => navigationItemList.find((item) => item.href && !!matchPath(pathname, item.href)),
    [pathname],
  );
  const pageTitle = activePage ? activePage.label : 'Leadr';

  const hookProps = {
    isMobileNavigationOpen,
    openMobileNavigation,
    handleShowSearchModalClick,
    pageTitle,
    useGlobalSearch,
    useSimplifiedNav,
    canViewTableGroup,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
