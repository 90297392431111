import { css } from '@emotion/react';
import {
  useCallback,
} from 'react';
import { palette } from '~Common/styles/colors';
import { withoutDesktopObject } from '~Common/styles/mixins';
import { Attachment, GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { LIGHTBOX_FILE_EXTENSIONS } from '~Meetings/const/attachments';
import { meetingKeys } from '~Meetings/const/queryKeys';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import { selectLightboxSlides } from '~Meetings/utils/selectLightboxSlides';
import { useLightbox } from '~Common/V3/components/Lightbox/useLightbox';
import { useConfirmAttachmentDeletionModal } from '~Meetings/hooks/utils/useConfirmAttachmentDeletionModal';
import { useAgendaPermissions } from '~Meetings/hooks/utils/useAgendaPermissions';
import { AttachmentsList } from '../AttachmentsList/AttachmentsList';

const styles = {
  bodyComponent: css({
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.25rem',
  }, withoutDesktopObject({
    justifyContent: 'flex-start',
  })),
  text: css({
    fontSize: '1rem',
    color: palette.neutrals.gray800,

    // Some targeted spacing control
    'ul, ol': {
      lineHeight: '1.5rem',
      marginBottom: '0.5rem',
    },
  }),
  editedTimestamp: css({
    color: palette.neutrals.gray600,
    fontSize: '0.75rem',
  }),
  dot: css({
    height: '0.375rem',
    width: '0.375rem',
    color: palette.neutrals.gray500,
    padding: '0 0.25rem',
  }),
  editButtonContainer: css({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
};

function ensureParagraphTags(text: string): string {
  if (text?.substring(0, 1) !== '<p') {
    return `<p>${text}</p>`;
  }

  return text;
}

interface ViewProps {
  canRemoveAttachments: boolean,
  hasBeenEdited: boolean,
  timeSinceComment: string,
  timeSinceEdit: string,
  attachments?: Attachment[],
  textHTML: HTMLString,
  handleAttachmentClick: (attachment: Attachment) => void,
  showAttachments?: boolean,
  onRemoveAttachment: (attachment: Attachment) => void,
}

const View = ({
  canRemoveAttachments,
  hasBeenEdited,
  timeSinceComment,
  timeSinceEdit,
  attachments,
  textHTML,
  showAttachments,
  handleAttachmentClick,
  onRemoveAttachment,
  ...props
}: ViewProps): JSX.Element => (
  <div css={styles.bodyComponent} {...props}>
    <HTMLRenderer css={styles.text} htmlText={textHTML} />
    <span css={styles.editedTimestamp}>
      {timeSinceComment}
      {hasBeenEdited && (
        <>
          {` (edited ${timeSinceEdit}) `}
        </>
      )}
    </span>

    {showAttachments && !!attachments?.length && (
      <AttachmentsList
        onRemoveAttachment={canRemoveAttachments ? onRemoveAttachment : undefined}
        attachments={attachments}
        handleAttachmentClick={handleAttachmentClick}
      />
    )}
  </div>
);

interface BodyComponentProps {
  agendaItemId: string,
  attachments?: Attachment[],
  hasBeenEdited: boolean,
  huddleId: string,
  sectionId?: string,
  showAttachments?: boolean,
  textHTML: HTMLString,
  timeSinceComment: string,
  timeSinceEdit: string,
}

const BodyComponent = ({
  textHTML: text,
  attachments,
  huddleId,
  agendaItemId,
  sectionId,
  ...props
}: BodyComponentProps): JSX.Element => {
  const wrappedText = ensureParagraphTags(text);
  const showAttachments = useFeatureFlag('webAppAttachmentsOnAgendaTopics');

  const { openLightbox } = useLightbox();

  const { getTopicPermissions } = useAgendaPermissions(huddleId);

  const {
    canEditAgenda: canRemoveAttachments,
  } = getTopicPermissions(agendaItemId, sectionId);

  const handleAttachmentClick = useCallback((attachment: Attachment) => {
    if (LIGHTBOX_FILE_EXTENSIONS.includes(attachment.fileExtension.toLowerCase())) {
      const acceptedAttachments = attachments?.filter((tempAttachment) => LIGHTBOX_FILE_EXTENSIONS.includes(tempAttachment.fileExtension.toLowerCase()));
      const initialSlideIndex = acceptedAttachments?.findIndex((slide) => slide.fileDownloadUrl === attachment.fileDownloadUrl);
      openLightbox({
        identifier: 'attachments',
        props: {
          index: initialSlideIndex,
          getSlideData: (data: HttpCallReturn<GetAgendaReturn>) => selectLightboxSlides({
            data,
            agendaItemId,
            sectionId,
          }),
          queryKeyToWatch: meetingKeys.agendaList(huddleId),
        },
      });
    } else {
      window.open(attachment.fileDownloadUrl, '_blank')?.focus();
    }
  }, [agendaItemId, attachments, huddleId, openLightbox, sectionId]);

  const {
    openModal: openAttachmentDeletionModal,
  } = useConfirmAttachmentDeletionModal();

  const onRemoveAttachment = useCallback((attachment: Attachment) => {
    openAttachmentDeletionModal({
      props: {
        attachment,
        huddleId,
        topicId: agendaItemId,
      },
    });
  }, [agendaItemId, huddleId, openAttachmentDeletionModal]);

  const hookProps = {
    canRemoveAttachments,
    textHTML: wrappedText,
    showAttachments,
    attachments,
    handleAttachmentClick,
    onRemoveAttachment,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default BodyComponent;
