import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-duotone-svg-icons';
import { ButtonBase } from '@mui/material';
import { withH5Font } from '~Common/styles/typography';
import { withLineClamp } from '~Common/styles/mixins';
import { baseballCardColors } from '~Common/styles/colors';

const styles = {
  container: css`
    display: flex;
  `,
  title: css`
    ${withH5Font()}
    ${withLineClamp(1)}
    color: ${baseballCardColors.buttonColor};
    cursor: pointer;
  `,
  text: css`
    padding-right: 5px;
  `,
};

const View = ({
  onClick, text,
}) => (
  <div css={styles.container}>
    <ButtonBase
      disableRipple
      onClick={onClick}
      css={styles.title}
    >
      <span css={styles.text}>{text}</span>
      <FontAwesomeIcon icon={faExternalLink} />
    </ButtonBase>
  </div>
);

View.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

View.defaultProps = {
  text: '',
};

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const FlatExternalLinkButton = ({
  link, ...props
}) => {
  const hookProps = {
    onClick: () => {
      window.open(link);
    },
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

FlatExternalLinkButton.propTypes = {
  link: PropTypes.string.isRequired,
};

FlatExternalLinkButton.defaultProps = {};

export default FlatExternalLinkButton;
