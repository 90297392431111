import { ReviewShape } from '~Reviews/V2/Const/types';
import { UseGetPersonByIdReturn, useGetPersonById } from '~Common/hooks/people/useGetPeople';
import { useReturnReviewee } from './useReturnReviewee';

export const useGetRevieweePersonInformation = (review: ReviewShape | undefined): UseGetPersonByIdReturn => {
  const reviewReviewee = useReturnReviewee(review);
  const personByIdInformation = useGetPersonById({ orgUserId: reviewReviewee.orgUserId });

  return {
    ...personByIdInformation,
  };
};
