import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import React, { useCallback, useEffect } from 'react';
import { withShadow } from '~Deprecated/ui/styles/mixins';
import { drawerColor } from '~Deprecated/ui/styles/colors';
import { withPrintStyles } from '~Deprecated/ui/styles/print';
import { DRAWER_STATE, TRANSITION_STATE } from '~Common/const/drawers';
import { removeDrawerAction } from '~Deprecated/actions/drawers/removeDrawer';
import { baseZ } from '~Common/const/zIndicies';
import { DRAWER_TYPE_SHAPE } from '~Common/const/proptypes';
import { forMobile } from '~Common/styles/mixins';

export const ANIMATION_TIME = 250;
const DRAWER_OVERLAP = 2;
const MOBILE_GUTTER = 0;

const styles = {
  baseDrawer: css`
    background-color: ${drawerColor};
    box-shadow: ${withShadow()};
    height: 100%;
    width: 100%;
    max-width: calc(100% - 20px);
    display: flex;
    flex-direction: column;

    ${withPrintStyles(css`
      width: 100%;
      max-width: unset;
    `)}
    
    ${forMobile(`
      width: 100%;
      max-width: unset;
    `)}
  `,
  buildDrawerStyles: (index, width, offset) => ({
    base: css`
      position: absolute;
      top: 0;
      right: ${offset - width}px;
      z-index: ${baseZ - index};
      width: ${width}px;
      transition: right ${ANIMATION_TIME}ms;

      ${withPrintStyles(css`
        width: 100%;
        max-width: unset;
      `)};

      ${forMobile(`
        right: -${width}px;
        top: ${index * DRAWER_OVERLAP}px;
        z-index: ${baseZ + index};
      `)}
    `,
    entering: css`
      right: ${offset - width}px;

      ${forMobile(`
        right: -${width}px;
      `)}
    `,
    entered: css`
      right: ${index > 0 ? offset - DRAWER_OVERLAP : offset}px;

      ${forMobile(`
        right: ${MOBILE_GUTTER}px;
        left: ${MOBILE_GUTTER}px;
      `)}
    `,
    exiting: css`
      right: -${width}px;
    `,
    exited: css`
      right: -${width}px;
    `,
  }),
};

const View = ({
  className,
  state,
  compiledStyles,
  children,
  ...props
}) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { transitionState: state, ...props });
    }
    return child;
  });

  return (
    <div
      css={[
        styles.baseDrawer,
        compiledStyles.base,
        compiledStyles[state],
      ]}
      className={className}
    >
      { childrenWithProps }
    </div>
  );
};

View.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  /* eslint-disable-next-line */
  drawer: DRAWER_TYPE_SHAPE.isRequired,
  state: PropTypes.string.isRequired,
  compiledStyles: PropTypes.objectOf(PropTypes.object).isRequired,
};

View.defaultProps = {
  children: null,
  className: '',
};

const BaseDrawer = ({
  drawer, drawerIndex, state, ...props
}) => {
  const dispatch = useDispatch();

  const removeDrawer = useCallback(
    () => dispatch(removeDrawerAction({ drawerName: drawer.name })),
    [dispatch, drawer],
  );

  const hookProps = {
    drawer,
    drawerIndex,
    state,
    compiledStyles: styles.buildDrawerStyles(drawerIndex, drawer.width, drawer.offset),
  };

  useEffect(() => {
    if (state === TRANSITION_STATE.EXITED && drawer.state === DRAWER_STATE.CLOSED) {
      removeDrawer();
    }
  }, [state, drawer, removeDrawer]);

  return (
    <View {...hookProps} {...props} />
  );
};

BaseDrawer.propTypes = {
  drawer: DRAWER_TYPE_SHAPE,
  drawerIndex: PropTypes.number,
  state: PropTypes.string.isRequired,
};

BaseDrawer.defaultProps = {
  drawer: null,
  drawerIndex: 0,
};

export default BaseDrawer;
