import {
  boolean,
  object,
  string,
  array,
  number,
} from 'yup';
import type { InferType } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Goals } from '@leadr-hr/types';
import { DEFAULT_TEAM_ID } from '../const/defaults';

const requiredMsg = {
  title: 'The Title field is required.',
  description: 'The Description field is required.',
  contextType: 'The Goal Type field is required.',
  team: 'Please select a team.',
  owner: 'Please select an owner.',
};

export const editGoalFormSchema = object({
  title: string().trim().required(requiredMsg.title),
  description: string().trim(),
  participants: array().of(
    object({
      orgUserId: string().trim().required(),
      role: string().oneOf(Object.values(Goals.GoalParticipantRole)).trim().required(),
    }),
  ),
  priority: number(),
  context: object({
    contextType: string().trim().required(requiredMsg.contextType),
  }),
  category: string().trim(),
  externalLink: string().trim(),
  isPrivate: boolean(),
  startTimeInMillis: number().required(),
  endTimeInMillis: number()
    .required()
    .test('endTime', 'End Time must be equal or greater than Start Time', function isEndTimeValid(value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const startTime = this.parent.startTimeInMillis;
      return startTime === undefined || value === undefined || value >= startTime;
    }),
  owner: string().required(requiredMsg.owner),
  type: string().trim(),
  team: string().trim().when('type', {
    is: 'Team',
    then: string().notOneOf([DEFAULT_TEAM_ID], requiredMsg.team),
  }),
});

export const editGoalFormResolver = yupResolver(editGoalFormSchema);
export type FormValues = InferType<typeof editGoalFormSchema>;

export interface CreateGoalDTO {
  title: string;
  description?: string;
  priority?: Goals.GoalPriority;
  category?: string;
  externalLink?: string;
  isPrivate: boolean;
  startTimeInMillis: number;
  endTimeInMillis: number;
  type: Goals.GoalContextType;
  participants:{
    orgUserId: string;
    role: Goals.GoalParticipantRole;
  }[];
  context: Goals.GoalContext;
  team?: string;
}

export function conformToDto(data: FormValues): Goals.Requests.CreateGoalRequestPayload {
  const {
    owner: ownerOrgUserId,
    title = '',
    description,
    category = Goals.GoalCategory.DefiningObjectives,
    priority,
    externalLink,
    isPrivate = false,
    startTimeInMillis = 0,
    endTimeInMillis = 0,
    type: contextTypeValue = Goals.GoalContextType.Personal,
    team,
    participants = [],
  } = data;

  const context = {
    contextType: contextTypeValue as Goals.GoalContextType || Goals.GoalContextType.Personal,
    ...(contextTypeValue === Goals.GoalContextType.Team ? { contextId: team } : {}),
  };

  const filteredParticipants = participants.reduce<{ orgUserId: string, role: Goals.GoalParticipantRole; }[]>((result, participant) => {
    if (participant.role === Goals.GoalParticipantRole.Owner) {
      result.push({
        orgUserId: ownerOrgUserId,
        role: Goals.GoalParticipantRole.Owner,
      });
    } else {
      result.push({
        orgUserId: participant.orgUserId,
        role: participant.role as Goals.GoalParticipantRole,
      });
    }

    return result;
  }, []);

  const result: Goals.Requests.CreateGoalRequestPayload = {
    title,
    description,
    context,
    isPrivate,
    startTimeInMillis,
    endTimeInMillis,
    externalLink,
    participants: filteredParticipants,
  };

  if (description && description !== '') {
    result.description = description;
  }

  if (priority !== undefined) {
    result.priority = priority as Goals.GoalPriority;
  }

  if (contextTypeValue === Goals.GoalContextType.Team) {
    result.category = category as Goals.GoalCategory;
  }

  return result;
}
