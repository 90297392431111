import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useMemo } from 'react';

interface UsePaginationProps {
  totalCount: number,
  pageSize: number,
  page: number,
  setPage: (page: number) => void,
}

export interface UsePaginationReturn {
  numberOfPages: number,
  onPageChange: (event: SelectChangeEvent<number>) => void,
  onPreviousClick: () => void,
  onNextClick: () => void,
}

export const usePagination = ({
  totalCount, pageSize, page, setPage,
}: UsePaginationProps): UsePaginationReturn => {
  const numberOfPages = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);

  const onPageChange = (event: SelectChangeEvent<number>): void => {
    setPage(event.target.value as number);
  };

  const onPreviousClick = (): void => {
    setPage(page - 1);
  };

  const onNextClick = (): void => {
    setPage(page + 1);
  };

  return {
    numberOfPages,
    onPageChange,
    onPreviousClick,
    onNextClick,
  };
};
