import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { cloneDeep } from 'lodash';
import { TopicShape } from '../Const/types';
import { reviewCycleKeys } from './queryKeys';

interface reorderTopicsProps {
  cycleId: string,
  topics: string[],
}

const reorderTopics = ({ cycleId, topics }: reorderTopicsProps): Promise<HttpCallReturn<unknown>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${cycleId}/topics`,
  };

  return patchApi<unknown>(serverUrl, topics, {});
};

const version = '2.5';

export const useReorderTopics = (cycleId: string): UseMutateFunction<HttpCallReturn<unknown>, Error, reorderTopicsProps> => {
  const toastId = useRef<ReactText | number | null>(null);
  const queryKey = [getOrganizationId(), 'reviewCycles'];

  const mutation = useMutation({
    mutationFn: reorderTopics,
    onMutate: async (reorderTopicsUpdate: reorderTopicsProps) => {
      toastId.current = toast.info('Reordering answers...', { autoClose: false });
      // cancel current querries
      await queryClient.cancelQueries({ queryKey: reviewCycleKeys.topicsForCycle(reorderTopicsUpdate.cycleId) });
      // get current state
      const previousTopics = queryClient.getQueryData(reviewCycleKeys.topicsForCycleWithVersion(cycleId, version));

      // update current state
      queryClient.setQueryData<HttpCallReturn<TopicShape[]>>(
        reviewCycleKeys.topicsForCycleWithVersion(reorderTopicsUpdate.cycleId, version),
        (oldTopicsOrder) => {
          if (oldTopicsOrder) {
            const newTopicsOrder = cloneDeep(oldTopicsOrder);

            newTopicsOrder.response = newTopicsOrder.response.map((topic) => ({
              ...topic,
              rank: reorderTopicsUpdate.topics.findIndex((topicId) => topicId === topic.uid),
            }));

            return newTopicsOrder;
          }

          return oldTopicsOrder;
        },
      );

      return {
        previousTopics,
      };
    },
    onError: (_, reorderTopicsUpdate, snapshot) => {
      queryClient.setQueryData(reviewCycleKeys.topicsForCycleWithVersion(reorderTopicsUpdate.cycleId, version), snapshot?.previousTopics);

      toast.update(toastId.current, {
        render: 'There was an error Reordering your answers. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully Reordering your answers.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: [...queryKey, cycleId] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'reviewTopics'] });
    },
  });

  return mutation.mutate;
};
