/* eslint-disable react-hooks/exhaustive-deps */
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { setIsIdleAction } from '~Deprecated/actions/idle/setIsIdle';

const IdleDetector = () => {
  const dispatch = useDispatch();
  const { timeout } = useSelector((state) => state.idle);

  const handleOnActive = () => {
    dispatch(setIsIdleAction({
      isIdle: false,
    }));
  };
  const handleOnIdle = () => {
    dispatch(setIsIdleAction({
      isIdle: true,
    }));
  };

  useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  return false;
};

export default IdleDetector;
