import PropTypes from 'prop-types';

import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import FormDrawer from '~Common/components/Drawers/Forms/Form';

import { useTabs } from '~Common/hooks/useTabs';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import FlagEditor from './Flags';

export const testToolsDrawerTemplate = {
  name: 'TEST_TOOLS_DRAWER',
  type: templateType.PRIMARY,
  width: DRAWER_WIDTHS.PAGE,
};

const tabQueryConfig = { module: 'testTools', workflow: 'drawer', defaultValue: 0 };

const TestToolDrawer = ({
  drawerState, setDrawerState, pushDrawer, popAfter, ...props
}) => {
  const { activeTab, setActiveTab } = useTabs(tabQueryConfig);

  const hookProps = {
    drawerName: testToolsDrawerTemplate.name,
    drawerTitle: 'Test Tools',
    tabs: [
      ['Flags', <FlagEditor />],
    ],
    onTabChange: (index) => {
      setActiveTab(index);
    },
    tabQueryConfig,
    activeTab,
    isReadOnly: true,
  };

  return (
    <FormDrawer
      {...hookProps}
      {...props}
    />
  );
};

TestToolDrawer.propTypes = {
  popAfter: PropTypes.func.isRequired,
  pushDrawer: PropTypes.func.isRequired,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: PropTypes.func.isRequired,
};

TestToolDrawer.defaultProps = {};

registerDrawer({ templateName: testToolsDrawerTemplate.name, component: TestToolDrawer });

export default TestToolDrawer;
