import { Goals } from '@leadr-hr/types';
import moment from 'moment';
import { useMemo } from 'react';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { DEFAULT_OWNER } from '~Goals/const/defaults';
import { getGoalDateString } from '~Goals/const/functions';
import { CascadingGoalRow, GoalRow } from '~Goals/const/types';
import flattenGoals, { FlattenedChildGoal, FlattenedGoal } from '~Goals/utils/flattenLinkedGoals';

interface UseGetTableRows {
  enableCascadingGoals: boolean,
  data: Goals.Goal[] | Goals.GoalWithCascading[],
}

const useGetTableRows = ({ enableCascadingGoals, data }: UseGetTableRows): CascadingGoalRow[] | GoalRow[] => {
  const tableRows = useMemo(() => {
    if (enableCascadingGoals) {
      return getCascadingTableRows(data as Goals.GoalWithCascading[]);
    }

    return getFlatTableRows(data as Goals.Goal[]);
  }, [data, enableCascadingGoals]);

  return tableRows;
};

// TODO: Commonalize the logic between this and childGoalInfo
const topLevelGoalInfo = (goal: FlattenedGoal): CascadingGoalRow => {
  const { startDate, endDate } = getGoalDateString(moment(goal.startTimeInMillis), moment(goal.endTimeInMillis));

  const loggedInUserIsParticipant = goal.participants.find((item) => item.orgUserId === getOrganizationUserId());
  const myRole = loggedInUserIsParticipant?.role ?? 'Viewer';
  const owner = goal?.participants?.find((participant) => participant.role === Goals.GoalParticipantRole.Owner && participant.firstName !== undefined);
  const ownerToUse = owner ?? DEFAULT_OWNER;
  const name = ownerToUse?.firstName ? `${ownerToUse?.firstName} ${ownerToUse?.lastName}` : '';
  const goalStatusUpdates = goal?.statusUpdates ?? [];
  const finalGoalStatus = goalStatusUpdates[goalStatusUpdates.length - 1];
  const priority = Goals.GoalPriority[goal?.priority ?? Goals.GoalPriority.Medium];

  return {
    id: goal.goalId,
    path: goal.path,
    title: goal.title,
    subText: goal.context.contextName,
    isPrivate: goal.isPrivate,
    progress: {
      percentage: finalGoalStatus?.completionPercentage ?? 0,
      status: finalGoalStatus?.status ?? '',
    },
    priority,
    startDate,
    endDate,
    role: myRole,
    objectiveType: goal.category,
    owner: {
      imgUrl: ownerToUse?.profileImageUrl ?? '',
      name,
    },
    totalChildGoals: goal.totalChildGoals,
  };
};

const childGoalInfo = (goal: FlattenedChildGoal): CascadingGoalRow => {
  const { startDate, endDate } = getGoalDateString(moment(goal.startTimeInMillis), moment(goal.endTimeInMillis));

  const loggedInUserIsParticipant = goal.participants.find((item) => item.orgUserId === getOrganizationUserId());
  const myRole = loggedInUserIsParticipant?.role ?? 'Viewer';
  const owner = goal?.participants?.find((participant) => participant.role === Goals.GoalParticipantRole.Owner && participant.firstName !== undefined);
  const ownerToUse = owner ?? DEFAULT_OWNER;
  const name = ownerToUse?.firstName ? `${ownerToUse?.firstName} ${ownerToUse?.lastName}` : '';
  const { completionPercentage, status } = goal.currentStatusUpdate;
  const priority = Goals.GoalPriority[goal?.priority ?? Goals.GoalPriority.Medium];

  return {
    id: goal.goalId,
    path: goal.path,
    title: goal.title,
    subText: goal.context.contextName,
    isPrivate: goal.isPrivate,
    progress: {
      percentage: completionPercentage ?? 0,
      status: status ?? '',
    },
    priority,
    startDate,
    endDate,
    role: myRole,
    objectiveType: goal.category,
    owner: {
      imgUrl: ownerToUse?.profileImageUrl ?? '',
      name,
    },
    totalChildGoals: goal.totalChildGoals,
  };
};

function isFlattenedGoal(goal: FlattenedGoal | FlattenedChildGoal): goal is FlattenedGoal {
  return (goal as FlattenedGoal).statusUpdates !== undefined;
}

const getCascadingTableRows = (data: Goals.GoalWithCascading[]): CascadingGoalRow[] => {
  const flattenedGoals = flattenGoals(data);

  const tableRows: CascadingGoalRow[] = flattenedGoals.map((goal) => {
    const formattedGoal = isFlattenedGoal(goal) ? topLevelGoalInfo(goal) : childGoalInfo(goal);
    return formattedGoal;
  }) ?? [];

  return tableRows;
};

const getFlatTableRows = (data: Goals.Goal[]): GoalRow[] => {
  const tableRows: GoalRow[] = data.map((goal) => {
    const { startDate, endDate } = getGoalDateString(moment(goal.startTimeInMillis), moment(goal.endTimeInMillis));

    const loggedInUserIsParticipant = goal.participants.find((item) => item.orgUserId === getOrganizationUserId());
    const myRole = loggedInUserIsParticipant?.role ?? 'Viewer';
    const owner = goal?.participants?.find((participant) => participant.role === Goals.GoalParticipantRole.Owner && participant.firstName !== undefined);
    const ownerToUse = owner ?? DEFAULT_OWNER;
    const name = ownerToUse?.firstName ? `${ownerToUse?.firstName} ${ownerToUse?.lastName}` : '';
    const goalStatusUpdates = goal?.statusUpdates ?? [];
    const finalGoalStatus = goalStatusUpdates[goalStatusUpdates.length - 1];
    const priority = Goals.GoalPriority[goal?.priority ?? Goals.GoalPriority.Medium];

    return {
      id: goal.goalId,
      title: goal.title,
      subText: goal.context.contextName,
      isPrivate: goal.isPrivate,
      progress: {
        percentage: finalGoalStatus?.completionPercentage ?? 0,
        status: finalGoalStatus?.status ?? '',
      },
      priority,
      startDate,
      endDate,
      role: myRole,
      objectiveType: goal.category,
      owner: {
        imgUrl: ownerToUse?.profileImageUrl ?? '',
        name,
      },
    };
  }) ?? [];

  return tableRows;
};

export default useGetTableRows;
