import { css } from '@emotion/react';
import { useIsDesktopQuery } from '~Common/hooks/useMediaListener';
import { ComponentProps } from 'react';
import { forMobileObject, forMobileTinyObject, forTabletObject } from '~Common/styles/mixins';

// A place for common module topbar layouts, if you need a custom layout, you can pass in a custom css object
const moduleTopbarLayouts = {
  default: css({
    gridTemplateAreas: '"toggleTabs viewToggleTabs searchField rightSection"',
    gridTemplateColumns: 'auto auto auto 1fr',
  }, forTabletObject({
    gridTemplateColumns: 'auto auto 1fr',
    gridTemplateAreas: '"toggleTabs searchField rightSection"',
  }), forMobileObject({
    gridTemplateAreas: `
      "toggleTabs rightSection"
      "searchField searchField"
    `,
    gridTemplateColumns: 'auto auto',
  }), forMobileTinyObject({
    gridTemplateAreas: `
      "toggleTabs"
      "searchField"
      "rightSection"
    `,
    gridTemplateColumns: '1fr',
  })),
  defaultWithoutRightSection: css({
    gridTemplateAreas: '"toggleTabs viewToggleTabs searchField"',
    gridTemplateColumns: 'auto auto 1fr',
  }, forTabletObject({
    gridTemplateAreas: '"toggleTabs searchField"',
    gridTemplateColumns: 'auto 1fr',
  }), forMobileObject({
    gridTemplateAreas: '"toggleTabs searchField"',
    gridTemplateColumns: 'auto 1fr',
  })),
  toggleTabsAndButtons: css({
    gridTemplateAreas: '"toggleTabs rightSection"',
    gridTemplateColumns: 'auto auto',
  }, forTabletObject({
    gridTemplateAreas: '"toggleTabs rightSection"',
    gridTemplateColumns: 'auto auto',
  }), forMobileObject({
    gridTemplateAreas: '"toggleTabs rightSection"',
  }), forMobileTinyObject({
    gridTemplateAreas: `
      "toggleTabs"
      "rightSection"
    `,
    gridTemplateColumns: '1fr',
  })),
  custom: css({}),
};

const styles = {
  moduleTopbar: css({
    display: 'grid',
    width: '100%',
    gridGap: '.625rem',
  }, forMobileObject({
    rowGap: '.75rem',
  })),
  tabNavigationToggle: css({
    gridArea: 'toggleTabs',
  }),
  viewToggle: css({
    gridArea: 'viewToggleTabs',
  }),
  searchField: css({
    maxWidth: '15.625rem',
    gridArea: 'searchField',
  }, forMobileTinyObject({
    maxWidth: '100%',
  })),
  rightSection: css({
    gridArea: 'rightSection',
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
    justifySelf: 'end',
  }, forMobileTinyObject({
    width: '100%',
  })),
};

interface ViewProps extends ComponentProps<'div'> {
  moduleTopbarLayout?: keyof typeof moduleTopbarLayouts,
  renderTabNavigationToggle?: () => JSX.Element,
  renderViewToggle?: () => JSX.Element,
  renderSearchField?: () => JSX.Element,
  renderRightSection?: () => JSX.Element,
  isDesktop: boolean,
}

const View = ({
  moduleTopbarLayout = 'default',
  renderTabNavigationToggle,
  renderViewToggle,
  renderSearchField,
  renderRightSection,
  isDesktop,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={[styles.moduleTopbar, moduleTopbarLayouts[moduleTopbarLayout]]}
    {...props}
  >
    {renderTabNavigationToggle && (
      <div css={styles.tabNavigationToggle}>
        {renderTabNavigationToggle()}
      </div>
    )}
    {isDesktop && renderViewToggle && (
      <div css={styles.viewToggle}>
        {renderViewToggle()}
      </div>
    )}
    {renderSearchField && (
      <div css={styles.searchField}>
        {renderSearchField()}
      </div>
    )}
    {renderRightSection && (
      <div css={styles.rightSection}>
        {renderRightSection()}
      </div>
    )}
  </div>
);

export type ModuleTopbarProps = Omit<ViewProps, 'isDesktop'>;

/**
  @param moduleTopbarLayout - Preset layout for the topbar, if you need a custom layout, you can pass in a custom css object
  The default layout has the following grid areas: "toggleTabs viewToggleTabs searchField rightSection"
*/
const ModuleTopbar = ({ ...props }: ModuleTopbarProps): JSX.Element => {
  const isDesktop = useIsDesktopQuery();

  const hookProps = {
    isDesktop,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ModuleTopbar;
