import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { getOrganizationId } from '~Common/utils/localStorage';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { toast } from '~Common/components/Toasts';
import { postApi } from '~Deprecated/services/HttpService';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';

const createTemplateMutation = ({ template }) => {
  const url = {
    host: hosts.feedback,
    uri: `/organizations/${getOrganizationId()}/templates/feedbacks`,
  };

  return postApi(url, template, {});
};

const createFeedbackQuestionMutation = ({ questions, id }) => {
  const url = {
    host: hosts.feedback,
    uri: `/organizations/${getOrganizationId()}/questions`,
  };

  return postApi(url, { questions, context: { type: 'FEEDBACK_TEMPLATE', id } }, {});
};

export const useCreateFeedbackTemplate = ({ queryKey = [getOrganizationId(), 'feedback', 'templates'], drawerTemplate, nextMutation }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: createTemplateMutation,
    onMutate: ({ template }) => {
      toastId.current = toast.info(`Creating ${template.title}...`, { autoClose: false });

      dispatch(
        popDrawerAction({ drawerName: drawerTemplate.name }),
      );
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error creating the template. Please check that all fields have been filled out and try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (drawerTemplate) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...drawerTemplate,
              args: {
                ...variables,
              },
            },
          }));
        });
      }
    },
    onSuccess: (data, variables, context) => {
      nextMutation({
        ...variables,
        id: data.response.feedbackTemplateId,
        queryKey,
        toastId,
      });
    },
  });

  return mutation.mutate;
};

export const useAddQuestionsToTemplate = ({ queryKey = [getOrganizationId(), 'feedback', 'templates'], template, nextMutation }) => {
  const toastId = React.useRef(null);
  const dispatch = useDispatch();
  let templateTitle = '';
  let isNewTemplate = true;

  const mutation = useMutation({
    mutationFn: createFeedbackQuestionMutation,
    onMutate: (variables) => {
      if (variables.toastId) {
        toastId.current = variables.toastId.current;
      } else {
        toastId.current = toast.info('Adding Question to Template', { autoClose: false });
        isNewTemplate = false;
      }
      templateTitle = variables.template?.title;
    },
    onError: (error, variables, context) => {
      toast.update(toastId.current, {
        render: 'There was an error creating the feedback request. Please check that all fields have been filled out and try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });

      if (template) {
        setTimeout(() => {
          dispatch(pushDrawerAction({
            drawer: {
              ...template,
              args: {
                feedbackContext: { ...variables },
              },
            },
          }));
        });
      }
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: isNewTemplate ? `Successfully Created ${templateTitle}` : 'Successfully Created the Question',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      nextMutation?.();

      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutate;
};
