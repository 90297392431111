import {
  EditPostShape, InfiniteQueryParamsProps, RecognitionShape,
} from '~Recognition/const/types';
import { css } from '@emotion/react';
import { useGetRecognition } from '~Recognition/hooks/useGetRecognition';
import EmptyRecognition from '~Recognition/assets/images/emptyRecognition.svg';
import { palette } from '~Common/styles/colors';
import { getIsFiltering } from '~Recognition/const/functions';
import { useEffect, useState } from 'react';
import {
  RECOGNITION_DEFAULT_DEPARTMENT, RECOGNITION_DEFAULT_END_DATE, RECOGNITION_DEFAULT_START_DATE, RECOGNITION_DEFAULT_USER_GROUP,
} from '~Recognition/const/defaults';
import InfiniteListView from '~Common/V3/components/InfiniteList';
import { PAGE_STYLES } from '../const/pageStyles';
import RecognitionListCards from './RecognitionListCards';

const styles = {
  ...PAGE_STYLES,
  listWrapper: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'auto 1fr',
    gridGap: '1.125rem',
  }),
  emptyState: css({
    marginTop: '3.125rem',

    '& h6': {
      width: '80%',
    },
  }),
  emptyStateImage: css({
    height: '15.625rem',
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '4.6875rem',
  }),
  imageContainer: css({
    width: '100%',
    alignContent: 'center',

  }),
  textContainer: css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1.125rem',
    fontWeight: 500,
    marginTop: '2rem',
    color: palette.neutrals.gray700,
  }),
  button: css({
    border: 'none',
    background: 'none',
    color: palette.brand.indigo,
    fontWeight: 500,
    padding: '0',
  }),
};

interface ViewProps {
  infiniteQueryParams: InfiniteQueryParamsProps,
  setEditPost: (value: EditPostShape) => void,
  filters: InfiniteQueryParamsProps,
  handleModalDisplay: (value: boolean) => void,
  isFiltering: boolean,
  onClearFilters: () => void,
}

const View = ({
  infiniteQueryParams,
  setEditPost,
  filters,
  handleModalDisplay,
  isFiltering,
  onClearFilters,
}: ViewProps): JSX.Element => (
  <>
    <div
      css={styles.dashboardSpace}
    >
      <InfiniteListView
        isMasonry
        listWrapperStyle={styles.listWrapper}
        listQuery={useGetRecognition}
        columns={{ min: 1, tablet: 2 }}
        spacing={4}
        infiniteQueryProp={infiniteQueryParams}
        renderItem={(_id: number, index: number, recognition: RecognitionShape) => (
          <RecognitionListCards
            index={index}
            recognition={recognition}
            setEditPost={setEditPost}
            filters={filters}
            infiniteQueryParams={infiniteQueryParams}
          />
        )}
        renderEmptyState={() => (
          <div css={styles.container}>

            <EmptyRecognition css={styles.emptyStateImage} title="Empty recognition" />
            <div css={styles.textContainer}>
              {isFiltering && (
                <>
                  <p>
                    {'No recognitions found. '}
                    <button
                      onClick={onClearFilters}
                      css={styles.button}
                    >
                      Clear Filters
                    </button>
                    {' to start again.'}
                  </p>
                </>
              )}
              {!isFiltering && (
                <>
                  <p>There are no recognitions to show at this time.</p>
                  <br />
                  <p>
                    <button
                      onClick={() => handleModalDisplay(true)}
                      css={styles.button}
                    >
                      Add a New Recognition
                    </button>
                    {' '}
                    to be the first to recognize someone!
                  </p>
                </>
              )}
            </div>
          </div>
        )}
        itemKeyName="recognitionId"
        loaderStyle={undefined}
        renderLoadingState={undefined}
        requestType={undefined}
      />
    </div>
  </>
);

interface RecognitionListProps {
  infiniteQueryParams: InfiniteQueryParamsProps;
  setEditPost: (value: EditPostShape) => void;
  filters: InfiniteQueryParamsProps;
  handleModalDisplay: (value: boolean) => void;
  setUserGroup: (userGroup: string) => void,
  setEndDate: (endDate: string) => void,
  setStartDate: (startDate: string) => void,
  setDepartment: (department: string) => void,
  setSearchText: (searchText: string) => void,
}

const RecognitionList = ({
  infiniteQueryParams,
  setEditPost,
  filters,
  handleModalDisplay,
  setUserGroup,
  setEndDate,
  setStartDate,
  setDepartment,
  setSearchText,
}: RecognitionListProps): JSX.Element => {
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    setIsFiltering(getIsFiltering(infiniteQueryParams));
  }, [infiniteQueryParams]);

  const onClearFilters = (): void => {
    setDepartment(RECOGNITION_DEFAULT_DEPARTMENT.value);
    setUserGroup(RECOGNITION_DEFAULT_USER_GROUP.value);
    setStartDate(RECOGNITION_DEFAULT_START_DATE);
    setEndDate(RECOGNITION_DEFAULT_END_DATE);
    setSearchText('');
  };

  const hookProps = {
    infiniteQueryParams,
    setEditPost,
    filters,
    handleModalDisplay,
    isFiltering,
    onClearFilters,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default RecognitionList;
