import moment from 'moment-timezone';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareDown, faSquareUp, faRectangleHistory } from '@fortawesome/pro-solid-svg-icons';
import { lighten } from 'polished';
import {
  ComponentProps,
  useCallback,
  useMemo,
  useState,
} from 'react';

import useAuditTrail, {
  AuditEvent,
  AuditEventDirection,
  AuditEventWorkflow,
} from '~Meetings/hooks/useAuditTrail';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { palette } from '~Common/styles/colors';
import { MeetingTypeEnum } from '~Meetings/const/meetingsInterfaces';
import Tooltip from '~Common/components/Tooltip';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { meetingRightPanelHeader } from '~Meetings/const/sharedStyles';
import { CircularProgress } from '@mui/material';

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.75rem',
  }),
  showButton: css({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }),
  directionIconContainer: (wasSuccessful: boolean) => css({
    fontSize: '1.5rem',
    marginRight: '0.5rem',
    color: wasSuccessful ? palette.neutrals.black : palette.brand.red,
    display: 'flex',
  }),
  workflowLabel: css({
    marginBottom: '0.25rem',
  }),
  auditEventCard: (wasSuccessful: boolean) => css({
    display: 'flex',
    borderRadius: '0.5rem',
    border: '1px solid',
    padding: '0.5rem',
    borderColor: wasSuccessful ? palette.neutrals.gray200 : palette.brand.red,
    marginBottom: '0.5rem',
    background: wasSuccessful ? palette.neutrals.white : lighten(0.4, palette.brand.red),
  }),
  auditSummaryCard: css({
    borderRadius: '0.5rem',
    padding: '0.5rem',
    marginBottom: '0.5rem',
    background: palette.neutrals.white,
  }),
  recentErrorCount: (hasRecentErrors: boolean) => css({
    fontWeight: 'bold',
    color: hasRecentErrors ? palette.brand.red : palette.neutrals.black,
    margin: '0 0.25rem',
  }),
  auditEventBody: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  errorMessage: css({
    marginBottom: '0.5rem',
    color: palette.brand.red,
  }),
  timestamp: css({
    color: palette.neutrals.gray700,
  }),
  syncStatus: css({
    display: 'flex',
  }),
  syncStatusLabel: css({
    flexShrink: 0,
    fontWeight: 'bold',
    marginRight: '0.25rem',
  }),
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const directionIconMap = {
  [AuditEventDirection.Outbound]: faSquareUp,
  [AuditEventDirection.Inbound]: faSquareDown,
};

const workflowFriendlyNameMap = {
  [AuditEventWorkflow.Create]: 'Create',
  [AuditEventWorkflow.Update]: 'Update',
  [AuditEventWorkflow.JobSuccess]: 'Job Success',
  [AuditEventWorkflow.Delete]: 'Delete',
};

interface AuditTrailViewProps {
  auditEvents?: AuditEvent[],
  shouldShowLoaders: boolean,
  shouldShowAuditTrail: boolean,
  onShowHistoryButtonClick: () => void,
  isSyncing?: boolean,
  getRecentErrorCount: () => JSX.Element,
}

const View = ({
  auditEvents,
  shouldShowLoaders,
  shouldShowAuditTrail,
  onShowHistoryButtonClick,
  isSyncing,
  getRecentErrorCount,
  ...props
}: AuditTrailViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    <div css={meetingRightPanelHeader}>
      <p>
        CALENDAR AUDIT SUMMARY
      </p>
    </div>

    <div css={styles.auditSummaryCard}>
      <div css={styles.syncStatus}>
        <div css={styles.syncStatusLabel}>
          Sync Status:
        </div>

        {isSyncing && (
          <>
            <span>
              Syncing Pending
            </span>

            <CircularProgress css={styles.circularProgress} />
          </>
        )}

        {!isSyncing && (
          <div>
            No Pending Sync
          </div>
        )}
      </div>

      <div>
        The last 20 events resulted in
        {getRecentErrorCount()}
        errors
      </div>
    </div>

    { !shouldShowAuditTrail && (
      <LeadrButton
        css={styles.showButton}
        onClick={onShowHistoryButtonClick}
        data-test-id="showCalendarHistory"
        color="light"
      >
        <LeadrButton.IconAndText icon={faRectangleHistory} text="VIEW CALENDAR HISTORY" />
      </LeadrButton>
    )}

    { shouldShowAuditTrail && (
      <>
        { shouldShowLoaders && (
          <>
            <SkeletonLoader renderComponent={() => <div />} />
            <SkeletonLoader renderComponent={() => <div />} />
            <SkeletonLoader renderComponent={() => <div />} />
          </>
        )}

        { !shouldShowLoaders && (
          <>
            <div css={meetingRightPanelHeader}>
              <p>
                CALENDAR EVENT HISTORY
              </p>
            </div>

            { auditEvents?.map((auditEvent) => (
              <div
                key={auditEvent.id}
                css={styles.auditEventCard(auditEvent.success)}
              >
                <Tooltip
                  content={auditEvent.direction === AuditEventDirection.Inbound ? 'Inbound' : 'Outbound'}
                >
                  <div css={styles.directionIconContainer(auditEvent.success)}>
                    <FontAwesomeIcon
                      icon={directionIconMap[auditEvent.direction]}
                    />
                  </div>
                </Tooltip>

                <div css={styles.auditEventBody}>
                  <h3 css={styles.workflowLabel}>
                    {workflowFriendlyNameMap[auditEvent.workflow]}
                  </h3>

                  {auditEvent.errorMessage && (
                    <div css={styles.errorMessage}>
                      { auditEvent.errorMessage }
                    </div>
                  )}

                  <div>
                    <Tooltip
                      content={moment(auditEvent.auditEventDate).format('LLLL')}
                    >
                      <span css={styles.timestamp}>
                        {moment(auditEvent.auditEventDate).fromNow()}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </>
    )}
  </div>
);

export interface AuditTrailProps extends ComponentProps<'div'> {
  id: string,
  type: MeetingTypeEnum
}

const AuditTrail = ({ id, type, ...props }: AuditTrailProps): JSX.Element => {
  const [shouldShowAuditTrail, setShouldShowAuditTrail] = useState(false);

  const { item: meetingDetails } = useMeetingDetails({ id, type });
  const { isLoading, data } = useAuditTrail({ factoryId: meetingDetails?.factoryId });
  const [shouldShowLoaders] = useSkeletonLoaders(isLoading);
  const auditEvents = data?.response;

  const isSyncing = useMemo(() => {
    const timeWindow = 3600000;
    const latestEvent = auditEvents?.[0];

    if (latestEvent) {
      const isLatestOutbound = latestEvent.direction === AuditEventDirection.Outbound;
      const isLatestSuccess = latestEvent.success;
      const eventDateInMs = new Date(latestEvent.auditEventDate).valueOf();
      const isInWindow = eventDateInMs - new Date().valueOf() < timeWindow;

      return isLatestOutbound && isLatestSuccess && isInWindow;
    }

    return false;
  }, [auditEvents]);

  const recentErrorCount = useMemo(() => (
    auditEvents?.filter((auditEvent) => !auditEvent.success).length
  ), [auditEvents]);

  const onShowHistoryButtonClick = useCallback(() => {
    setShouldShowAuditTrail(true);
  }, [setShouldShowAuditTrail]);

  const getRecentErrorCount = useCallback(() => (
    <span css={styles.recentErrorCount(!!recentErrorCount)}>
      {recentErrorCount || 'no'}
    </span>
  ), [recentErrorCount]);

  const hookProps = {
    shouldShowLoaders,
    auditEvents: data?.response,
    shouldShowAuditTrail,
    onShowHistoryButtonClick,
    isSyncing,
    getRecentErrorCount,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export { View };
export default AuditTrail;
