import { css } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import { palette } from '~Common/styles/colors';

const styles = {
  loadingContainer: (props) => css`
    display: flex;
    width: 100%;
    min-height: ${props.minHeight || '250px;'}
    justify-content: center;
    align-items: center;

    &.inner-data {
      background-color: ${palette.neutrals.white};
    }
  `,
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

/**
 * @deprecated Old Loader, we use packages/leadr-frontend/src/common/components/SkeletonLoader.tsx now
 */
const LoadingSpinner = (props) => (
  <div css={styles.loadingContainer(props)}>
    <CircularProgress css={styles.circularProgress} />
  </div>
);
export default LoadingSpinner;
