import {
  Switch, Route,
} from 'react-router-dom';

import {
  ACCOUNT_SETTINGS,
  ADMIN_SETTINGS,
  // LEARNING_SETTINGS,
  // NOTIFICATION_SETTINGS,
} from '~Common/const/routes';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import SmartRedirect from '~Deprecated/ui/components/SmartRedirect';
import AccountSettings from '~Settings/components/Tabs/Account';
import AdminSettings from '~Settings/components/Tabs/Admin';
// import LearningSettings from '~Settings/components/Tabs/Learning';
// import NotificationSettings from '~Settings/components/Tabs/Notifications';

interface ViewProps {
  showAdminSettings: boolean,
}

const View = ({
  showAdminSettings,
}: ViewProps): JSX.Element => (
  <Switch>
    <Route
      exact
      path={[ACCOUNT_SETTINGS]}
      render={() => (
        <AccountSettings />
      )}
    />
    {showAdminSettings && (
      <Route
        exact
        path={[ADMIN_SETTINGS]}
        render={() => (
          <AdminSettings />
        )}
      />
    )}
    {/* <Route
      exact
      path={[LEARNING_SETTINGS]}
      render={() => (
        <LearningSettings />
      )}
    /> */}
    {/* <Route
      exact
      path={[NOTIFICATION_SETTINGS]}
      render={() => (
        <NotificationSettings />
      )}
    /> */}
    <SmartRedirect from="*" to={ACCOUNT_SETTINGS} />
  </Switch>
);

const SettingsRouter = ({
  ...props
}): JSX.Element => {
  const { isAdmin: showAdminSettings } = useUserPermissions();

  const hookProps = {
    showAdminSettings,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default SettingsRouter;
