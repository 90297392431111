import { MouseEventHandler, useMemo } from 'react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useGetReviewById } from '~Reviews/V2/Hooks/useGetReviewById';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { Reflection, ReflectionTypeEnum } from '../../types';
import { useAddReflection } from '../../hooks/useAddReflection';
import { useRemoveReflection } from '../../hooks/useRemoveReflection';

interface ViewProps {
  isSelected: boolean,
  disableButtons: boolean,
  onRemove: MouseEventHandler<HTMLButtonElement>,
  onAdd: MouseEventHandler<HTMLButtonElement>,
}

const View = ({
  isSelected,
  disableButtons,
  onRemove,
  onAdd,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {isSelected && (
      <LeadrButton
        data-test-id="removeReflection"
        disabled={disableButtons}
        onClick={onRemove}
        size="small"
        color="danger"
        variant="ghost"
      >
        Remove
      </LeadrButton>
    )}
    {!isSelected && (
      <LeadrButton
        data-test-id="addReflection"
        disabled={disableButtons}
        onClick={onAdd}
        size="small"
      >
        + Add
      </LeadrButton>
    )}
  </div>
);

interface BaseProps extends Omit<ViewProps, 'isSelected' | 'onRemove' | 'onAdd' | 'disableButtons'>{
  isDrawerMode: boolean,
  reflection: Reflection,
  reviewTopicUid: string,
  reviewUid: string,
  contentTitle: string,
}

interface DrawerModeProps extends BaseProps {
  isDrawerMode: true,
  passedIsSelected?: boolean,
}

interface NonDrawerModeProps extends BaseProps {
  isDrawerMode: false,
  passedIsSelected: boolean,
}

type AddRemoveButtonsProps = DrawerModeProps | NonDrawerModeProps;

const AddRemoveButtons = ({
  isDrawerMode,
  reviewUid,
  reviewTopicUid,
  passedIsSelected,
  reflection,
  contentTitle,
  ...props
}: AddRemoveButtonsProps): JSX.Element => {
  /*
    Since drawers aren't reactive to props, once they are opened, we need to fetch the information in here
    I am still allowing passing in the data, since the other option is displaying these buttons in a list view and
    doing this much logic for every button in a list view probably isn't a good idea
  */
  const id = isDrawerMode ? reviewUid : '';
  const { data } = useGetReviewById({
    id,
    enabled: isDrawerMode && !!reviewUid,
  });
  const review = data?.response;
  const isSelected = useMemo(
    () => {
      if (isDrawerMode) {
        // TODO: This filtering can be removed after the backend handles it https://leadrhq.atlassian.net/browse/LW-15878
        const currentParticipantUID = review?.participants.find((participant) => participant.orgUserId === getOrganizationUserId() ?? '')?.uid ?? '';
        const currentTopicReflections = review?.topics.find((topic) => topic.uid === reviewTopicUid)?.reflections;
        const currentUserReflections = currentTopicReflections?.filter((tempReflection) => tempReflection.participantUid === currentParticipantUID) || [];
        const currentUserReflectionIds = currentUserReflections.map((tempReflection) => tempReflection.subjectUid);
        return currentUserReflectionIds.includes(reflection.contentId);
      }
      return passedIsSelected;
    },
    [isDrawerMode, passedIsSelected, review?.participants, review?.topics, reflection.contentId, reviewTopicUid],
  );

  const {
    mutate: addReflection,
    isPending: isAddingReflection,
  } = useAddReflection(reviewUid);

  const {
    mutate: removeReflectionMutation,
    isPending: isRemovingReflection,
  } = useRemoveReflection({
    reviewUid,
  });

  const handleAddReflection: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    addReflection({
      reflectionTypeEnum: reflection.contentTypeId as unknown as ReflectionTypeEnum,
      subjectUid: reflection.contentId,
      reviewTopicUid,
      contentTitle,
    });
  };

  const handleRemoveReflection: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    removeReflectionMutation({
      reviewUid,
      reviewTopicUid,
      subjectUid: reflection.contentId,
    });
  };

  const hookProps = {
    isSelected,
    disableButtons: isAddingReflection || isRemovingReflection,
    onRemove: handleRemoveReflection,
    onAdd: handleAddReflection,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default AddRemoveButtons;
