import { GLOBAL_SEARCH } from '~Common/const/routes';

export const MIN_CHARACTERS_TO_SEARCH = 2;
export const MODAL_RESULTS_PER_SECTION = 3;

export const MEETING_ENTITY_TYPES = ['MEETING_INSTANCE', 'AGENDA_TOPIC', 'TOPIC_COMMENT'];

export const GLOBAL_SEARCH_TABS = [
  {
    label: 'Meeting Titles',
    value: 'meeting-titles',
    toObject: {
      pathname: GLOBAL_SEARCH,
      search: '?tab=meeting-titles',
    },
    indexName: 'MEETING-INSTANCES',
    'data-test-id': 'searchResultsMeetingTitlesTab',
  },
  {
    label: 'Meeting Topics',
    value: 'meeting-topics',
    toObject: {
      pathname: GLOBAL_SEARCH,
      search: '?tab=meeting-topics',
    },
    indexName: 'AGENDA-TOPICS',
    'data-test-id': 'searchResultsMeetingTopicsTab',
  },
  {
    label: 'Meeting Comments',
    value: 'meeting-comments',
    toObject: {
      pathname: GLOBAL_SEARCH,
      search: '?tab=meeting-comments',
    },
    indexName: 'TOPIC-COMMENTS',
    'data-test-id': 'searchResultsMeetingCommentsTab',
  },
  // {
  //   label: 'Meeting Notes',
  //   value: 'meetingNotes',
  //   href: `${GLOBAL_SEARCH}?tab=meeting-notes`,
  // },
];
