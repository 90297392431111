import { useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { homeQueryKeys } from '~Home/hooks/queryKeys';

const editUser = async ({ userId, updatedUserInfo }) => {
  const URL = `/organizations/${getOrganizationId()}/users/${userId}`;
  return patchApi(URL, updatedUserInfo);
};

export const useEditUser = () => {
  const toastId = useRef(null);

  const mutation = useMutation({
    mutationFn: editUser,
    onMutate: () => {
      toastId.current = toast.info('Editing the user...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'The edit failed. Please try again...',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSettled: (data) => {
      if (data.status !== 200) {
        toast.update(toastId.current, {
          render: 'There was a problem editing the user.',
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      } else {
        toast.update(toastId.current, {
          render: 'Successfully updated the user information.',
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
        data.onSuccess?.();
        queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'admin'] });
        queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'people'] });
        queryClient.invalidateQueries({ queryKey: homeQueryKeys.insightsPeers(getOrganizationId()) });
      }
    },
  });

  return mutation;
};
