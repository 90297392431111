import { css } from '@emotion/react';

import { SelectChangeEvent } from '@mui/material';
import { useGetPeopleFilters } from '~Common/hooks/useGetPeopleFilters';
import { DropdownItem } from '~Common/V3/components/Dropdown';
import LeadrCheckbox from '~Common/V3/components/LeadrCheckbox';
import FilterDropdownView from './DropdownView';

const styles = {
  checkbox: css({
    marginRight: '0.5rem',
  }),
};

interface ViewProps {
  workingGeniusSkills: DropdownItem<string[]>[],
  value: string[],
  handleFilterChange: (event: SelectChangeEvent<string[]>) => void,
}

const emptyValue = 'Working Genius';

const View = ({
  workingGeniusSkills,
  value,
  handleFilterChange,
  ...props
}: ViewProps): JSX.Element => (
  <FilterDropdownView<string[]>
    options={workingGeniusSkills}
    value={value}
    handleFilterChange={handleFilterChange}
    labelForNoValue={emptyValue}
    data-test-id="workingGeniusDropdown"
    multiple
    displayEmpty={value.length === 0}
    renderItem={(item) => (
      <>
        <LeadrCheckbox
          css={styles.checkbox}
          size={20}
          checked={value.includes(item.value)}
          data-test-id={item['data-test-id'] as string}
        />
        {item.text}
      </>
    )}
    {...props}
  />
);

interface WorkingGeniusDropdownProps {
    value: string[],
    handleFilterChange: (event: SelectChangeEvent<string[]>) => void,
    otherFilterSelected: boolean,
}

const WorkingGeniusDropdown = ({
  value,
  otherFilterSelected,
  ...props
}: WorkingGeniusDropdownProps): JSX.Element => {
  const {
    workingGeniusSkills,
  } = useGetPeopleFilters();

  const hookProps = {
    workingGeniusSkills: workingGeniusSkills.map((skill) => ({
      text: skill,
      value: skill,
      disabled: value.length >= 2 && !value.includes(skill),
      'data-test-id': `${skill}Option`,
    })),
    value,
    disabled: otherFilterSelected && value.length === 0,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default WorkingGeniusDropdown;
