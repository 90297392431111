import { QueryFunctionContext, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Person } from '~Common/const/interfaces';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';

interface GetPeopleResponse {
  ids: string[],
  items: Person[],
  orgUserIds: string[],
  deptPeople: string[],
  downline: string[],
  directs: string[],
  userInteractions: string[]
  laUsers: string[],
  departments: string[],
}

const getPeople = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<GetPeopleResponse>> => {
  const [allowInvitedManagers] = queryKey.slice(-2);
  const queryString = buildQueryString({ allowInvitedManagers });
  const url = `/organizations/${getOrganizationId() ?? ''}/people${queryString}`;
  return getApi(url, {});
};

interface UseGetPeopleParams {
  allowInvitedManagers?: boolean,
}

export const useGetPeople = ({
  allowInvitedManagers = true,
}: UseGetPeopleParams = {}): UseQueryResult<HttpCallReturn<GetPeopleResponse>> => (
  useQuery({
    /*
      Remove the 'new' from the query key, when the useNewPeople hook is removed
      In the useNewPeople hook, the data is being manipulated before it is stored in the queryKey, so we can't share a queryKey here, since we read this data differently
    */
    queryKey: [getOrganizationId() ?? '', 'people', allowInvitedManagers.toString(), 'new'],
    queryFn: getPeople,
  })
);

interface UseGetPersonByIdProps extends UseGetPeopleParams {
  orgUserId: string,
}

export type UseGetPersonByIdReturn = Omit<UseQueryResult<HttpCallReturn<GetPeopleResponse>>, 'data'> & { person: Person | undefined };

export const useGetPersonById = ({ orgUserId, ...params }: UseGetPersonByIdProps): UseGetPersonByIdReturn => {
  const { data, ...rest } = useGetPeople({ ...params });
  const personData = data?.response.items?.find((person) => person.orgUserId === orgUserId);

  return {
    person: personData,
    ...rest,
  };
};
