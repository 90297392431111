import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import { Goals } from '@leadr-hr/types';
import { GoalStatusColor } from './types';

export const FORM_STYLES = {
  setupContainer: (isMobile: boolean) => css({
    margin: '1.125rem auto 0 auto',
    padding: '1.5rem',
    background: palette.neutrals.white,
    borderRadius: '8px',
    maxWidth: '80%',
  }, isMobile && {
    maxWidth: '100%',
  }),
  boxShadow: css({
    boxShadow: '0px 4px 8px -2px rgba(28, 42, 55, 0.2);',
  }),
  sectionHeader: (hideHeader: boolean) => css({
    fontSize: '1.188rem',
    lineHeight: '1.5rem',
    marginBottom: '1rem',
    fontWeight: 600,
    display: hideHeader ? 'none' : 'block',
  }),
  subTitle: css({
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: palette.neutrals.gray700,
    marginBottom: '.5rem',
  }),
  inputField: (isMobile: boolean) => css({
    marginTop: '.5rem',
    fontSize: '1rem',
    color: palette.neutrals.gray900,
    borderRadius: '.5rem',
    border: 'none',
    position: 'relative',
    width: '49%',

    '& input': {
      fontWeight: '600 !important',
      fontSize: '.875rem',
      minHeight: 'auto',
      lineHeight: '1.5rem',
    },
    '& textarea': {
      fontSize: '.875rem',
    },
    '& label': {
      color: `${palette.neutrals.gray700} !important`,
      width: '100%',
      borderRadius: '.5rem',
      fontWeight: '400',
    },
  }, isMobile && {
    width: '100%',
    marginBottom: '.75rem',
  }),
  datePickerLabel: css({
    fontWeight: 400,
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
    marginBottom: '-0.65rem',
    paddingTop: '.25rem',
    '& svg': {
      marginTop: '0.5rem',
      fontSize: '1.125rem',
    },
    '& input': {
      marginTop: '-2rem !important',
    },
  }),
  datePicker: css({
    '& input': {
      marginTop: '-1rem',
      paddingLeft: '1.5rem',
      fontSize: '1rem',
      fontWeight: '500 !important',
    },
  }),
  removeMargin: (isMobile: boolean) => css({
    margin: '0',
  }, isMobile && {
    margin: 'inherit',
  }),
  checkboxContainer: css({
    display: 'flex',
    alignItems: 'center',

    label: {
      margin: 0,
    },
  }),
  lockIcon: (isMobile: boolean) => css({
    color: palette.neutrals.gray400,
    marginLeft: '0.5rem',
  }, isMobile && {
    display: 'none',
  }),
  formInput: (hide: boolean) => css({
    marginBottom: '.625rem',
  }, hide && {
    display: 'none',
  }),
  tagsProfileImage: css({
    display: 'inline-flex',
    marginRight: '.5rem',
    alignItems: 'center',
    '& > :first-of-type': {
      marginRight: '0',
    },
  }),
  autocompleteOptionText: (isMobile: boolean) => css({
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0 !important',
    padding: '0 !important',

    '.MuiCheckbox-root': {
      padding: '0 .75rem !important',
    },
  }, isMobile && {
    fontSize: '.875rem',
  }),
  autocompleteOptionTextChip: css({
    marginRight: '.5rem !important',
  }),
  jobtitle: (isMobile: boolean) => css({
    marginLeft: 'auto',
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }, isMobile && {
    display: 'none',
  }),
  fullWidth: css({
    width: '100%',
    '> .MuiFormControl-root': {
      width: '100%',
    },
  }),
  formSpacing: css({
    marginBottom: '.75rem',
  }),
  formSpacingTop: css({
    marginTop: '.75rem',
  }),
  froalaSpacing: css({
    marginBottom: '.25rem',
  }),
  formBorderSpacer: css({
    paddingBottom: '.75rem',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
  }),
  formDoubleLayout: (isMobile: boolean) => css({
    display: 'flex',
    justifyContent: 'space-between',
  }, isMobile && {
    flexDirection: 'column',
  }),
};

export const PROGRESS_BAR_STYLES = {
  progressBar: (status: Goals.GoalStatus) => css({
    height: '.9375rem',
    flexGrow: 1,
    borderRadius: '.5rem',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: palette.neutrals.gray300,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: GoalStatusColor[status],
    },
  }),
};

export const SKELETON_STYLES = {
  allowProperHeight: css({
    transform: 'initial',
  }),
};

export const GOAL_DETAIL_STYLES = {
  subHeading: css({
    fontSize: '.75rem',
    color: palette.neutrals.gray700,
    fontWeight: 400,
    display: 'block',
  }),
};

export const GOAL_FILTER_STYLES = {
  filterLabel: (hasValue: boolean) => css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
  }, hasValue && {
    color: palette.brand.indigo,
  }),
};
