import { css } from '@emotion/react';
import { Goals } from '@leadr-hr/types';
import { SelectChangeEvent } from '@mui/material';
import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';

import { palette } from '~Common/styles/colors';

const styles = {
  dropdown: css({
    border: '1px solid transparent',
    height: '2rem',
    padding: 0,

    '.MuiSelect-select': {
      fontSize: '0.75rem',
      paddingLeft: '0.75rem',
    },

    '.MuiSelect-icon': {
      right: '0.75rem',
    },
  }),
  dropdownItem: css({
    color: palette.neutrals.gray700,
  }),
};

interface SortByDropdownProps extends Omit<DropdownProps<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>, 'renderItem' | 'value' | 'onChange'> {
  sortBy: Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title,
  onSortByChange: (event: SelectChangeEvent<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>) => void,
  items: DropdownItem<Goals.GetGoalsSortBy.DueDate | Goals.GetGoalsSortBy.Title>[],
}

const SortByDropdown = ({
  sortBy,
  onSortByChange: onContextTypeChange,
  items,
  ...props
}: SortByDropdownProps): JSX.Element => (
  <Dropdown
    css={styles.dropdown}
    value={sortBy}
    onChange={onContextTypeChange}
    renderItem={(item: DropdownItem<Goals.GetGoalsSortBy>) => (
      <div css={styles.dropdownItem}>
        {item.text}
      </div>
    )}
    items={items}
    {...props}
  />
);

export default SortByDropdown;
