import { css } from '@emotion/react';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {
  MutableRefObject,
  useRef,
  useState,
} from 'react';
import { palette } from '~Common/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import LeadrButton from '../LeadrButtons/LeadrButton';

const styles = {
  dropdownArrowButton: css({
    marginLeft: '.0625rem',
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  }),
  dropdownArrow: css({
    width: '.625rem',
  }),
  menuItem: css({
    color: palette.neutrals.gray900,
  }),
};

interface ViewProps {
  open: boolean,
  anchorRef: MutableRefObject<HTMLDivElement | null>,
  handleToggle: () => void,
  handleClose: (event: Event) => void,
  options: LeadrSplitButtonOption[],
  labelBeforeSelectedOption: string,
}

const View = ({
  open,
  anchorRef,
  handleToggle,
  handleClose,
  options,
  labelBeforeSelectedOption,
  ...props
}: ViewProps): JSX.Element => (
  <>
    <ButtonGroup
      variant="contained"
      ref={anchorRef}
      aria-label="split button"
      disableElevation
    >
      <LeadrButton
        size="small"
        onClick={handleToggle}
        data-test-id="settingsSeeCalendarProviderOptions"
        {...props}
      >
        {labelBeforeSelectedOption}
      </LeadrButton>
      <LeadrButton
        size="small"
        css={styles.dropdownArrowButton}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select calendar provider"
        aria-haspopup="menu"
        onClick={handleToggle}
        data-test-id="settingsSeeCalendarProviderOptions"
      >
        <FontAwesomeIcon css={styles.dropdownArrow} icon={faCaretDown} />
      </LeadrButton>
    </ButtonGroup>
    <Popper
      sx={{
        zIndex: 1,
      }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {options.map((option) => (
                  <MenuItem
                    css={styles.menuItem}
                    key={option.label}
                    onClick={option.onClick}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
);

export interface LeadrSplitButtonOption {
  label: string,
  onClick: () => void,
  ['data-test-id']: string,
}

interface LeadrSplitButtonProps {
  options: LeadrSplitButtonOption[],
  labelBeforeSelectedOption: string,
}

const LeadrSplitButton = ({
  options,
  ...props
}: LeadrSplitButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event): void => {
    if (anchorRef?.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const hookProps = {
    open,
    anchorRef,
    handleToggle,
    handleClose,
    options,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default LeadrSplitButton;
