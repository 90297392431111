import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { css } from '@emotion/react';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EngagementCategory, ViewPerspective, SetDisplay } from '~Insights/const/types';
import { CARD_STYLES, EXPANDED_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';

import SidebarFilters, { SidebarFilterOptions, ValidValue as ValidSidebarValue } from '~Insights/components/SidebarFilters';
import Card from '~Common/V3/components/Card';
import Dropdown, { DropdownItem } from '~Common/V3/components/Dropdown';
import InsightCardTitle from '~Insights/components/InsightCardTitle';
import InsightsDelta from '~Insights/components/InsightsDelta';
import ShrinkButton from '~Insights/components/buttons/ShrinkButton';
import DateRangeSelect from '~Insights/components/DateRangeSelect';
import { useIsMobileQuery, useIsTabletQuery } from '~Common/hooks/useMediaListener';
import { forMobile } from '~Common/styles/mixins';

import Button from '~Common/V3/components/Button';
import { usePrintView } from '~Deprecated/hooks/usePrintView';
import {
  EngagementDataForDate, EngagementScores, ExecutiveEngagementDataForDate, PulseSurveyQuestion,
} from '~Insights/hooks/useEngagementData';
import {
  GraphDataset, GroupedEngagementData, makeDelta, makeEngagementGraphData, makeEngagementGroupings,
} from '~Insights/const/dataUtils';
import LeadrToggleTabs from '~Common/V3/components/LeadrToggleTabs';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import { useAveragesByStatement } from './EngagementCategoryByStatement';
import { TotalEngagementGraph } from './TotalEngagementGraph';
import { SharedProps } from './index';
import { ExpandedSidebar } from './ExpandedSidebar';

export enum SubtabPerspective {
  OverallScore = 'overall',
  ScoreByStatement = 'statements',
}
interface MakeFilteredDataProps {
  data: EngagementDataForDate[],
  startDate: Date,
  endDate: Date,
  questionId?: number,
}

function makeFilteredData({
  data,
  startDate,
  endDate,
  questionId = 0,
}: MakeFilteredDataProps): ExecutiveEngagementDataForDate[] {
  return data
    .filter((slice) => (
      // @ts-expect-error | Moment typing is screwed up.
      moment(slice.surveyDate).isBetween(startDate, endDate, 'day', true)
      && (Number.isNaN(questionId) || slice.questions.find((question) => question.id === questionId)
      ))) as ExecutiveEngagementDataForDate[];
}

function makeDeltas(data: ExecutiveEngagementDataForDate[]): Record<keyof EngagementScores, number> {
  if (!data.length) return {} as Record<keyof EngagementScores, number>;

  const firstSlice = data[0];
  const lastSlice = data[data.length - 1];

  return Object.fromEntries(
    Object.keys(EngagementCategory).map((cat) => ([
      cat,
      makeDelta(
        firstSlice.averages[cat as keyof EngagementScores],
        lastSlice.averages[cat as keyof EngagementScores],
      ),
    ])),
  ) as Record<keyof EngagementScores, number>;
}

function makeSidebarFilters(
  groupedData: Record<string, GroupedEngagementData[]>,
  viewPerspective: ViewPerspective,
  engagementCategory: keyof EngagementScores,
): SidebarFilterOptions {
  if (viewPerspective === ViewPerspective.Company) return [];

  return Object.entries(groupedData).map(([name, surveys]) => ({
    value: name,
    renderContents: () => (
      <div css={styles.deltaContainer}>
        <p>{viewPerspective === ViewPerspective.ManagerReports ? `${name}'s Direct Reports` : name}</p>
        {surveys.length > 1 && (
          <InsightsDelta
            value={makeDelta(
              surveys[0].averages[engagementCategory],
              surveys[surveys.length - 1].averages[engagementCategory],
            )}
          />
        )}
        {surveys.length === 1 && (
          <p css={styles.bigNumber}>
            {Math.round(surveys[0]?.averages[engagementCategory])}
            /5
          </p>
        )}
      </div>
    ),
  }));
}

export const styles = {
  expanded: css({
    ...CARD_STYLES.expanded,
    padding: '0',
  }),
  ...EXPANDED_CARD_COMPONENTS,
  statement: css({
    color: palette.neutrals.gray800,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
    fontSize: '0.825rem',
    rowGap: '0.5rem',
    textAlign: 'left',

    '.delta': {
      fontSize: '1.125rem',
      fontWeight: 600,
    },

    h3: {
      color: palette.brand.indigo,
      fontSize: '1.125rem',
      fontWeight: 500,
    },
  }),
  menuItem: css({
    fontFamily: 'ProximaNova',
    fontSize: '0.825rem',
  }),
  select: css({
    border: '0',
    fontFamily: 'ProximaNova',
    maxWidth: '20rem',
    whiteSpace: 'normal',
    '& .MuiSelect-select': {
      backgroundColor: hexToRGBA(palette.neutrals.gray50, 0.6),
      border: '0',
      borderRadius: '0.5rem',
      fontFamily: 'ProximaNova',
      fontSize: '0.75rem',
      padding: '0.25rem 0.75rem',
    },
    '& .MuiSelect-icon': {
      color: palette.brand.indigo,
    },
    fieldset: {
      display: 'none',
    },
  }),
  selectContainer: () => css({
    alignItems: 'center',
    columnGap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    margin: '0.5rem 0',
    order: '3',
    minWidth: '30rem',
    justifyContent: 'flex-end',

    p: {
      fontSize: '0.75rem',
      margin: '0',
      whiteSpace: 'nowrap',
    },
  }, forMobile(`
    order:1;
  `)),
  tabRow: (isMobile: boolean) => css({
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    flexBasis: '100%',
    justifyContent: 'space-between',
  }, forMobile(`
    flex-direction: column;
  `)),
  dropdown: (isMobile: boolean) => css({
    flexBasis: isMobile ? '100%' : '50%',
    padding: '0.25rem 1rem',
    margin: '0.5rem 0',
    order: isMobile ? '2' : '1',

    div: {
      fontSize: '.75rem',
    },
  }, forMobile(`
    order: 2;
    flex-basis: 100%;
  `)),
  listSubheader: css({
    color: palette.neutrals.gray900,
    fontFamily: 'ProximaNova',
    fontSize: '0.825rem',
    fontWeight: 600,
  }),
  print: (isMobile: boolean) => css({
    color: palette.brand.indigo,
    display: isMobile ? 'none' : 'inline-block',
    order: '5',
    minWidth: '4rem',
  }),
  sidebarWrapper: (isMobile: boolean) => css({
    flexBasis: isMobile ? '100%' : '15%',
    maxHeight: '100%',
    overflowY: 'auto',
  }),
};
export interface ExpandedProps extends SharedProps {
  AveragesByStatement: JSX.Element,
  AveragesByStatementSidebar: JSX.Element,
  categoryQuestions: PulseSurveyQuestion[],
  changeExpandedTab: (evt: SyntheticEvent, value: keyof typeof EngagementCategory) => void,
  changeExpandedTabDropdown: (selectedCategory: keyof typeof EngagementCategory) => void,
  deltas: Record<keyof EngagementScores, number>,
  endDate: Date,
  expandedTab: keyof typeof EngagementCategory,
  graphData: GraphDataset[],
  isMobile: boolean,
  isTablet: boolean,
  mostRecentGraphData: GraphDataset,
  onClick: () => void,
  onSetDateRange: (dates: Date[], pickerValue: string) => void,
  pickerValue: string,
  selectedSidebarFilters: ValidSidebarValue[],
  setSidebarFilters: Dispatch<SetStateAction<ValidSidebarValue[]>>,
  setSubtabPerspective: (value: string) => void,
  sidebarFilters: SidebarFilterOptions,
  startDate: Date,
  subtabPerspective: string,
  surveyDates: Date[],
}

const ExpandedView = ({
  AveragesByStatement,
  AveragesByStatementSidebar,
  categoryQuestions,
  changeExpandedTab,
  changeExpandedTabDropdown,
  changeToggleMenu,
  data,
  deltas,
  endDate,
  expandedTab,
  graphData,
  isMobile,
  isTablet,
  mostRecentGraphData,
  onClick,
  onSetDateRange,
  pickerValue,
  selectedSidebarFilters,
  setSidebarFilters,
  setSubtabPerspective,
  sidebarFilters,
  startDate,
  subtabPerspective,
  surveyDates,
  toggleMenuOptions,
  toggleMenuValue,
  // userPerspective,
}: ExpandedProps): JSX.Element => (
  <Card
    css={styles.expanded}
    renderContents={() => (
      <div css={styles.expandedContainer}>
        <div css={styles.header(isMobile)}>
          <ShrinkButton css={styles.expandButton(isMobile)} cardKey="engagement" />
          <div css={styles.titleAndSubtitle(isMobile)}>
            <InsightCardTitle>Engagement</InsightCardTitle>
            <p>These charts are show monthly engagement survey scores.</p>
          </div>
          {isMobile && (
            <Dropdown
              items={toggleMenuOptions as DropdownItem[]}
              onChange={(event) => changeToggleMenu(event.target.value as ViewPerspective)}
              value={toggleMenuValue}
            />
          )}
          {!isMobile && toggleMenuValue !== ViewPerspective.DirectReports && (
            <LeadrToggleTabs
              value={toggleMenuValue}
              onChange={(e, newValue) => changeToggleMenu(newValue as ViewPerspective)}
            >
              {toggleMenuOptions.map((toggleMenuOption) => (
                <LeadrToggleTabs.TextTab
                  data-test-id={toggleMenuOption['data-test-id']}
                  key={toggleMenuOption.value}
                  text={toggleMenuOption.text}
                  value={toggleMenuOption.value}
                />
              ))}
            </LeadrToggleTabs>
          )}
          {!isMobile && toggleMenuValue === ViewPerspective.DirectReports && (
            <span
              css={styles.fauxToggleTab}
            >
              Direct Reports
            </span>
          )}
        </div>
        <div css={styles.expandedCushion} />
        <div css={styles.tabRow(isMobile)}>
          {(isMobile || isTablet) && (
            <Dropdown
              css={styles.dropdown(isMobile)}
              items={Object.entries(EngagementCategory).map(([key, value]) => ({
                text: value,
                value: key,
              }))}
              onChange={(event: SelectChangeEvent<string>) => changeExpandedTabDropdown(event.target.value as keyof typeof EngagementCategory)}
              value={expandedTab as string}
              label="Category"
            />
          )}
          {!isMobile && !isTablet && (
            <TabContext value={expandedTab}>
              <TabList css={styles.tabs} onChange={changeExpandedTab} aria-label="Detail Section">
                {Object.entries(EngagementCategory).map(([key, value]) => (
                  <Tab key={key} label={value} value={key} />
                ))}
              </TabList>
            </TabContext>
          )}
          <Button
            variant="text"
            css={styles.print(isMobile)}
            onClick={onClick}
            renderContents={() => (
              <>
                <FontAwesomeIcon
                  icon={faPrint}
                  color={palette.brand.indigo}
                />
                {' '}
                Print
              </>
            )}
          />
          {expandedTab !== 'overall' && (
            <div css={styles.selectContainer}>
              <p>Responses:</p>
              <Select
                css={styles.select}
                value={subtabPerspective}
                onChange={(event) => setSubtabPerspective(event.target.value)}
              >
                <MenuItem
                  css={styles.menuItem}
                  value={SubtabPerspective.OverallScore}
                >
                  {`Average ${EngagementCategory[expandedTab]} Score`}
                </MenuItem>
                {(toggleMenuValue === ViewPerspective.Company || toggleMenuValue === ViewPerspective.DirectReports) && (
                  <MenuItem
                    css={styles.menuItem}
                    value={SubtabPerspective.ScoreByStatement}
                  >
                    {`Average Score Across ${EngagementCategory[expandedTab]} Statements`}
                  </MenuItem>
                )}
                {/* MUI menus don't like fragments, so returning an array here. */}
                {(toggleMenuValue !== ViewPerspective.Company && toggleMenuValue !== ViewPerspective.DirectReports) && [
                  <Divider />,
                  <ListSubheader css={styles.listSubheader}>Individual Statements</ListSubheader>,
                  ...categoryQuestions.map((question) => (
                    <MenuItem
                      css={styles.menuItem}
                      key={question.id}
                      value={question.id}
                    >
                      {question.text}
                    </MenuItem>
                  )),
                ]}
              </Select>
            </div>
          )}
        </div>
        <div css={styles.horizontalDivider} />
        <div css={styles.expandedRow(isMobile)}>
          <div css={styles.main}>
            <div css={[styles.header(isMobile), styles.smallHeader]}>
              <h3 css={styles.subtitle}>
                {!Number.isNaN(subtabPerspective) && (
                  `${EngagementCategory[expandedTab]} - ${categoryQuestions.find((question) => question.id === Number(subtabPerspective))?.text
                    ?? 'Average Score'}`
                )}
                {Number.isNaN(subtabPerspective) && (
                  <>
                    {`Average ${EngagementCategory[expandedTab]} Score`}
                    {startDate === endDate ? '' : ' Over Time'}
                  </>
                )}
              </h3>
              <DateRangeSelect
                initialValue={pickerValue}
                dates={surveyDates}
                onChange={onSetDateRange}
                defaultMonthsBack={12}
              />
            </div>
            <div css={styles.expandedLineGraph}>
              {((expandedTab === 'overall' || subtabPerspective !== SubtabPerspective.ScoreByStatement) && mostRecentGraphData?.data?.length > 0) && (
                <TotalEngagementGraph
                  graphData={graphData}
                  endDate={endDate}
                  startDate={startDate}
                  isMobile={isMobile}
                />
              )}
              {expandedTab !== 'overall' && subtabPerspective === SubtabPerspective.ScoreByStatement && AveragesByStatement}
              {(graphData.length === 0 || mostRecentGraphData?.data?.length === 0) && (
                <div css={styles.emptyState}>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                  <img src={insightsEmptyGraph} alt="Empty graph" />
                  <p css={styles.emptyStateText}>
                    Data will show here as your team answers the engagement survey.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div css={styles.verticalDivider(isMobile)} />
          <div css={styles.sidebarWrapper(isMobile)}>
            <div css={[styles.sidebar, styles.expandedSidebar(isMobile)]}>
              <ExpandedSidebar
                data={data}
                deltas={deltas}
                endDate={endDate}
                expandedTab={expandedTab}
                startDate={startDate}
                subtabPerspective={subtabPerspective}
                toggleMenuValue={toggleMenuValue}
              />
              {toggleMenuValue !== ViewPerspective.Company
                && toggleMenuValue !== ViewPerspective.DirectReports
                && (
                  <>
                    {sidebarFilters.length > 0 ? (
                      <SidebarFilters
                        selectedValues={selectedSidebarFilters}
                        options={sidebarFilters}
                        onChange={(values) => setSidebarFilters([...values])}
                      />
                    ) : (
                      <>
                        <p css={styles.bigNumber}>
                          N/A
                        </p>
                        <p>Average Score</p>
                      </>
                    )}
                  </>
                )}
              {toggleMenuValue === ViewPerspective.Company
                && subtabPerspective === SubtabPerspective.ScoreByStatement
                && AveragesByStatementSidebar}
              {toggleMenuValue === ViewPerspective.DirectReports
                && subtabPerspective === SubtabPerspective.ScoreByStatement
                && AveragesByStatementSidebar}
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

const HookedExpanded = ({
  data,
  toggleMenuValue,
  userPerspective,
  ...props
}: SharedProps): JSX.Element => {
  const [setDisplay] = useQueryParamState<SetDisplay>('insights', 'setDisplay', SetDisplay.Desktop);
  const isTablet = useIsTabletQuery();
  const checkMobileQuery = useIsMobileQuery();
  const isMobile = setDisplay === SetDisplay.Mobile || checkMobileQuery === true;

  const [expandedTab, setExpandedTab] = useQueryParamState<keyof typeof EngagementCategory>('insights', 'engagementCategory', 'overall');
  const changeExpandedTab = (evt: SyntheticEvent, value: keyof typeof EngagementCategory): void => {
    setExpandedTab(value);
  };
  const changeExpandedTabDropdown = (value: keyof typeof EngagementCategory): void => {
    setExpandedTab(value);
  };

  const [pickerValue, setPickerValue] = useQueryParamState<string>('insights', 'dateRange', 'PRESET_12');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onSetDateRange = (dates: Date[], newPickerValue: string): void => {
    const [newStartDate, newEndDate] = dates;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPickerValue(newPickerValue);
  };

  const [subtabPerspective, setSubtabPerspective] = useQueryParamState<string>('insights', 'subtabPerspective', SubtabPerspective.OverallScore);
  const [selectedSidebarFilters, setSidebarFilters] = useState<ValidSidebarValue[]>([]);

  const categoryQuestions = useMemo(
    () => (
      expandedTab !== 'overall'
        ? uniqBy(data?.flatMap((slice) => slice.questions).filter((question) => question.category.toLowerCase() === expandedTab), 'id') ?? []
        : []
    ),
    [expandedTab, data],
  );

  useEffect(() => {
    setSidebarFilters([]);
  }, [toggleMenuValue, subtabPerspective, expandedTab]);

  useEffect(() => {
    // If it's numeric, it's a question ID, and it's not the same across tabs.
    if (Number(subtabPerspective)) {
      setSubtabPerspective(SubtabPerspective.OverallScore);
    }
    if (subtabPerspective === SubtabPerspective.ScoreByStatement && toggleMenuValue !== ViewPerspective.Company && expandedTab === 'overall') {
      setSubtabPerspective(SubtabPerspective.OverallScore);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedTab]);

  const { onPrint } = usePrintView();
  const onClick = (): void => {
    onPrint({
      // eslint-disable-next-line max-len
      location: `insights?dateRange=${pickerValue}&engagementCategory=${expandedTab}&perspective=${toggleMenuValue}&subtabPerspective=${subtabPerspective}&setDisplay=mobile&setCard=engagement`,
    });
  };

  const filteredDataByDate = makeFilteredData({
    data, startDate, endDate, questionId: parseInt(subtabPerspective, 10),
  });
  const {
    AveragesByStatement,
    AveragesByStatementSidebar,
    allPossibleDates,
  } = useAveragesByStatement({
    data,
    startDate,
    endDate,
    engagementCategory: expandedTab,
    subtabPerspective,
    isMobile,
  });

  // If we're viewing a specific statement, let's filter the survey dates to only include that statement.
  const surveyDates = subtabPerspective === SubtabPerspective.ScoreByStatement
    ? allPossibleDates.map((dateStr) => new Date(dateStr)).reverse()
    : filteredDataByDate.map((slice) => new Date(slice.surveyDate)).reverse();
  const groupedData = userPerspective === UserPerspective.executive ? makeEngagementGroupings(filteredDataByDate, toggleMenuValue) : {};
  const graphData = makeEngagementGraphData({
    dataSlice: filteredDataByDate,
    groupedData,
    engagementCategory: expandedTab !== 'overall' ? expandedTab : undefined,
    includedCategories: selectedSidebarFilters.length ? selectedSidebarFilters as string[] : undefined,
    userPerspective,
    viewPerspective: toggleMenuValue,
  });
  const mostRecentGraphData = graphData?.[graphData.length - 1] ?? {};

  const sidebarFilters = makeSidebarFilters(groupedData, toggleMenuValue, expandedTab);

  const hookProps = {
    AveragesByStatement,
    AveragesByStatementSidebar,
    categoryQuestions,
    changeExpandedTab,
    changeExpandedTabDropdown,
    data: filteredDataByDate,
    deltas: makeDeltas(filteredDataByDate),
    endDate,
    expandedTab,
    graphData,
    isMobile,
    isTablet,
    mostRecentGraphData,
    onClick,
    onSetDateRange,
    pickerValue,
    selectedSidebarFilters,
    setSidebarFilters,
    setSubtabPerspective,
    sidebarFilters,
    startDate,
    subtabPerspective,
    surveyDates,
    toggleMenuValue,
    userPerspective,
  };

  return (
    <ExpandedView
      {...hookProps}
      {...props}
    />
  );
};

export { ExpandedView, HookedExpanded };
