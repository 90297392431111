import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';
import { OrgChart } from '~People/const/interfaces';

const getOrgChart = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<OrgChart>> => {
  const [selectedUserId] = queryKey.slice(-1);
  const [includeSecondaryManagers] = queryKey.slice(-2);

  const queryString = buildQueryString({
    id: selectedUserId,
    includeSecondaryManagers,
  });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/charts${queryString}`;

  return getApi<OrgChart>(serverUrl);
};

interface UseGetOrgChartParams {
  selectedUserId: string,
  includeSecondaryManagers: boolean,
  enabled?: boolean,
}

interface UseGetOrgChartReturn {
  orgChart: OrgChart | undefined,
  isLoading: boolean,
}

export const useGetOrgChart = ({
  selectedUserId,
  includeSecondaryManagers,
  enabled = true,
}: UseGetOrgChartParams): UseGetOrgChartReturn => {
  const result = useQuery({
    queryKey: [getOrganizationId() ?? '', 'orgChart', includeSecondaryManagers.toString(), selectedUserId],
    queryFn: getOrgChart,
    enabled,
  });

  return {
    orgChart: result.data?.response,
    isLoading: result.isLoading,
  };
};
