import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/pro-light-svg-icons';
import { IMPORT_STYLES } from '~Admin/components/styles/styles';
import sampleCSV from '~Admin/Importer/Const/sample.csv';
import { CSVPerspective } from '~Admin/Importer/Const/types';
import { useUploadCSV } from '../Hooks/useUploadCSV';

const styles = {
  ...IMPORT_STYLES,
};

const CSVFileUploadInterface = ({
  setIsFileUploading,
  setDataResponseView,
}) => {
  const { uploadCSVMutation, isUploading: isUploadingFile } = useUploadCSV();
  setIsFileUploading(isUploadingFile);

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv, application/vnd.ms-excel,',
    multiple: false,
    // eslint-disable-next-line no-unused-vars
    onDrop(acceptedFiles, fileRejections) {
      const formData = new FormData();

      if (acceptedFiles) {
        formData.append('file', acceptedFiles[0], acceptedFiles[0].name);
      }
      setIsFileUploading(true);
      uploadCSVMutation({
        file: formData,
        onSuccessCallback: (response) => {
          setDataResponseView(response, false, CSVPerspective.Preview);
        },
        onErrorCallback: () => {
          setIsFileUploading(false);
        },
      });
    },
  });

  const onClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div css={styles.importUIContainer}>
      <form id="my-awesome-dropzone">
        <div {...getRootProps({ css: styles.dropZone })}>
          <input {...getInputProps()} />
          <div css={styles.uploadIcon} className="uploadIcon">
            <FontAwesomeIcon css={styles.icon} icon={faFileImport} />
          </div>
          <div css={styles.dropText}>
            Drag and drop the CSV to add the file here or
            { ' ' }
            <span css={styles.browse}>browse</span>
            { ' ' }
            to choose a file
          </div>
          <div css={styles.downloadSample}>
            <a download="sample.csv" href={sampleCSV} onClick={onClick}>Download sample CSV</a>
          </div>
        </div>
      </form>
    </div>
  );
};

CSVFileUploadInterface.propTypes = {
  setIsFileUploading: PropTypes.func.isRequired,
  setDataResponseView: PropTypes.func.isRequired,
};

export default CSVFileUploadInterface;
