import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { withH4Font } from '~Deprecated/ui/styles/typography';

const styles = {
  infoWrapper: css`
    ${withH4Font()}
    display: flex;
    flex-direction: column;
    margin-block-start: 0.5rem;
    padding-block-start: 1.5rem;

    &:first-of-type {
      margin-block-start: 0;
      padding-block-start: 0;
    }
  `,
  infoTitle: css`
    color: ${palette.neutrals.gray700};
  `,
  info: css`
    color: ${palette.neutrals.gray700};
    margin-block: 0.5rem;
  `,
};

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx
 * with packages/leadr-frontend/src/common/V3/components/Drawers/DrawerInstructions.tsx instead
 */
const DrawerInfo = ({
  info, infoTitle, ...props
}) => (
  <div
    css={styles.infoWrapper}
    {...props}
  >
    { infoTitle && (
      <div css={styles.infoTitle}>{infoTitle}</div>
    )}
    {
      info && (
        <div css={styles.info}>
          {info }
        </div>
      )
    }
  </div>
);

DrawerInfo.propTypes = {
  info: PropTypes.string,
  infoTitle: PropTypes.string,
};

DrawerInfo.defaultProps = {
  infoTitle: '',
  info: '',
};

export default DrawerInfo;
