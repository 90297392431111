import { css } from '@emotion/react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { palette } from '~Common/styles/colors';
import DatePicker, { DatePickerProps } from '~Common/V3/components/DatePicker';

const styles = {
  datePicker: css({}),
  datePickerLabel: css({
    display: 'flex',
    gap: '0.25rem',
    color: palette.neutrals.gray600,
    fontWeight: 500,
  }),
  caretIcon: css({
    color: palette.brand.indigo,
    fontSize: '1rem',
  }),
  icon: css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
};

interface LearningDueDatePickerProps extends Omit<DatePickerProps, 'disablePast' | 'initialDate' | 'clearable'> {
  initialDate?: Date | string,
}

const LearningDueDatePicker = ({ initialDate, ...props }: LearningDueDatePickerProps): JSX.Element => (
  <DatePicker
    clearable
    initialDate={initialDate as unknown as Date}
    label="Due By"
    disablePast
    css={styles.datePicker}
    rightIconType={() => (
      <div css={styles.icon}>
        <FontAwesomeIcon
          icon={faCaretDown}
          css={styles.caretIcon}
        />
      </div>
    )}
    {...props}
  />
);

export default LearningDueDatePicker;
