import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { MeetingFactoryType, CuratedMeetingTemplate } from '~Meetings/const/meetingsInterfaces';
import { meetingTopicTemplatesQueryKeys } from './queryKeys';

/*
  This is the old curated meeting templates API.
  However, we need to keep it around since Curated By Leadr templates only come from this call for now
  Eventually we will update the newer getCuratedMeetingTemplates to handle this as well
  But as of 2/27/2024, the newer getCuratedMeetingTemplates only returns Curated by The Table Group templates
*/

interface GetCuratedMeetingTemplatesParams {
  meetingTemplateType: MeetingFactoryType,
}

const getCuratedMeetingTemplates = ({
  meetingTemplateType,
}: GetCuratedMeetingTemplatesParams): Promise<HttpCallReturn<CuratedMeetingTemplate[]>> => {
  const searchParams = new URLSearchParams({
    meetingTemplateType,
  });

  const URL = `/templates/agenda-items-suggestions?${searchParams.toString()}`;

  return getApi<CuratedMeetingTemplate[]>(URL);
};

type UseGetCuratedMeetingTemplatesParams = GetCuratedMeetingTemplatesParams;

export const useGetOldCuratedMeetingTemplates = ({
  meetingTemplateType,
}: UseGetCuratedMeetingTemplatesParams): UseQueryResult<HttpCallReturn<CuratedMeetingTemplate[]>> => {
  const orgId = getOrganizationId() ?? '';

  return useQuery({
    queryKey: meetingTopicTemplatesQueryKeys.oldCuratedTemplatesList({ orgId, meetingTemplateType }),
    queryFn: () => getCuratedMeetingTemplates({
      meetingTemplateType,
    }),
  });
};
