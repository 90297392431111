import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useGetFilteredPeople } from './useGetFilteredPeople';
import { useGetPeopleFilters } from './useGetPeopleFilters';

export const FILTER_TYPE = {
  FILTERS: 'FILTERS',
  DEPARTMENT: 'DEPARTMENT',
  GROUPS: 'GROUPS',
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
};

export const useSelectParticipants = ({
  useOrgIds,
  allowSelf,
  selectedFilters,
  filterIds,
}) => {
  const [search, setSearch] = useState('');
  const [debouncedLoadingOverride, setDebouncedLoadingOverride] = useState(false); // Override for showing loading state after the debounced click on a filter is done

  // First grab
  const {
    isLoading: arePeopleLoading,
    data: initialParticipants,
    allData: allPeopleParticipants,
    peopleData,
  } = useNewPeople({
    useOrgIds,
    allowSelf,
  });

  const [baseParticipantIds, setBaseParticipantIds] = useState(initialParticipants || []); // all participants -- show this list if there are no filters
  const [filteredParticipantIds, setFilteredParticipantIds] = useState([]); // filtered participants -- show this list if there is any filters
  const [searchedParticipantIds, setSearchedParticipantIds] = useState([]); // searched participants -- show this list if there are no filters are there is a search
  const [searchedFilteredParticipantIds, setSearchedFilteredParticipantIds] = useState([]); // search filtered participants -- show this list if there are filters and search

  useEffect(() => {
    if (filterIds?.length) {
      setBaseParticipantIds(allPeopleParticipants.filter((participantId) => filterIds.includes(participantId)));
    } else {
      setBaseParticipantIds(allPeopleParticipants);
    }
  }, [allPeopleParticipants, filterIds]);

  // These are the participant ids that are returned from this hook, to be used in the selectParticipantsDrawer
  const participantIds = useMemo(() => {
    if (selectedFilters.length > 0) {
      if (search !== '') {
        return searchedFilteredParticipantIds;
      }

      return filteredParticipantIds;
    }

    if (search !== '') {
      return searchedParticipantIds;
    }

    return baseParticipantIds;
  }, [baseParticipantIds, searchedParticipantIds, filteredParticipantIds, searchedFilteredParticipantIds, search, selectedFilters]);

  const onSearch = useCallback((searchText) => {
    setSearch(searchText);
  }, []);

  const searchFilter = useCallback((id) => {
    const participantData = peopleData[id];
    const lowerFullName = `${participantData?.firstName} ${participantData?.lastName}`.toLowerCase();
    const lowerSearch = search.toLowerCase();

    return lowerFullName.includes(lowerSearch);
  }, [peopleData, search]);

  useEffect(() => {
    if (baseParticipantIds) {
      setSearchedParticipantIds(baseParticipantIds.filter(searchFilter));
    }
    if (filteredParticipantIds) {
      setSearchedFilteredParticipantIds(filteredParticipantIds.filter(searchFilter));
    }
  }, [search, baseParticipantIds, filteredParticipantIds, searchFilter]);

  const onGetFilteredPeopleSuccess = (filteredParticipantOrgIds) => {
    if (useOrgIds) {
      setFilteredParticipantIds(filteredParticipantOrgIds);
    } else {
      const filteredParticipantUserIds = filteredParticipantOrgIds.map((id) => (peopleData?.[id]?.userId)).filter((person) => person !== undefined);
      setFilteredParticipantIds(filteredParticipantUserIds);
    }
    setDebouncedLoadingOverride(false);
  };

  const { getFilteredPeopleMutation, isLoading: areFilteredPeopleLoading } = useGetFilteredPeople(onGetFilteredPeopleSuccess);

  const fetchFilteredPeople = useCallback((filterPeopleObject) => {
    getFilteredPeopleMutation(filterPeopleObject);
  }, [getFilteredPeopleMutation]);

  return {
    onSearch,
    isLoading: arePeopleLoading || areFilteredPeopleLoading || debouncedLoadingOverride,
    participants: participantIds ?? [],
    allParticipants: baseParticipantIds ?? [],
    fetchFilteredPeople,
    setDebouncedLoadingOverride,
  };
};

export const useDepartmentMenuItems = () => {
  const { departments } = useGetPeopleFilters();

  const departmentMenuItems = useMemo(() => (departments?.map((department) => ({
    text: department.value,
    filterType: FILTER_TYPE.DEPARTMENT,
    id: `${FILTER_TYPE.DEPARTMENT}/${department.value}`,
    total: department.total,
  }))), [departments]);

  return departmentMenuItems;
};

export const useGroupMenuItems = ({
  excludeGroupIds,
}) => {
  const { groups } = useGetPeopleFilters();

  const groupMenuItems = useMemo(() => (groups?.reduce((result, group) => {
    if (!excludeGroupIds?.includes(group.teamId)) {
      result.push({
        text: group.name,
        filterType: FILTER_TYPE.GROUPS,
        apiValue: group.teamId,
        id: `${FILTER_TYPE.GROUPS}/${group.name}`,
        total: group.total,
      });
    }

    return result;
  }, [])), [groups, excludeGroupIds]);

  return groupMenuItems;
};

export const useMyTeamsMenuItems = ({
  excludeGroupIds,
}) => {
  const { myTeams } = useGetPeopleFilters();

  const groupMenuItems = useMemo(() => (myTeams?.reduce((result, group) => {
    if (!excludeGroupIds?.includes(group.teamId)) {
      result.push({
        text: group.name,
        filterType: FILTER_TYPE.GROUPS,
        apiValue: group.teamId,
        id: `${FILTER_TYPE.GROUPS}/${group.name}`,
        total: group.total,
      });
    }

    return result;
  }, [])), [myTeams, excludeGroupIds]);

  return groupMenuItems;
};

export const useAccountTypeMenuItems = () => {
  const { accountTypes } = useGetPeopleFilters();

  const accountTypeMenuItems = useMemo(() => (accountTypes?.map((accountType) => ({
    text: accountType.name,
    filterType: FILTER_TYPE.ACCOUNT_TYPE,
    apiValue: accountType.typeId,
    id: `${FILTER_TYPE.ACCOUNT_TYPE}/${accountType.name}`,
    total: accountType.total,
  }))), [accountTypes]);

  return accountTypeMenuItems;
};

export const useFilterMenuItems = ({
  excludeGroupIds = [],
} = {}) => {
  const departmentMenuItems = useDepartmentMenuItems();
  const publicTeamMenuItems = useGroupMenuItems({ excludeGroupIds });
  const myTeamsMenuItems = useMyTeamsMenuItems({ excludeGroupIds });
  const flagUseTeamsManagement = useFeatureFlag('webAppTeamsV2');

  const [selectedFilters, setSelectedFilters] = useState([]);

  const filterMenuItems = [
    {
      text: 'Everyone',
      filterType: FILTER_TYPE.FILTERS,
      apiValue: 'everyone',
      id: 'everyone',
    },
    {
      text: 'Your Department',
      filterType: FILTER_TYPE.FILTERS,
      apiValue: 'department',
      id: 'department',
    },
    ...(departmentMenuItems?.length > 0 ? [{
      text: 'Departments',
      showSubList: false,
      subList: departmentMenuItems,
    }] : []),
    ...(publicTeamMenuItems?.length > 0 ? [{
      text: flagUseTeamsManagement ? 'Public Teams' : 'Groups',
      showSubList: false,
      subList: publicTeamMenuItems,
    }] : []),
    ...(flagUseTeamsManagement && myTeamsMenuItems?.length > 0 ? [{
      text: 'My Teams',
      showSubList: false,
      subList: myTeamsMenuItems,
    }] : []),
    {
      text: 'Your Direct Reports',
      filterType: FILTER_TYPE.FILTERS,
      apiValue: 'directs',
      id: 'directs',
    },
    {
      text: 'Your Downlines',
      filterType: FILTER_TYPE.FILTERS,
      apiValue: 'downline',
      id: 'downline',
    },
    {
      text: 'Recent',
      filterType: FILTER_TYPE.FILTERS,
      apiValue: 'userInteractions',
      id: 'recent',
    },
  ];

  return {
    filterMenuItems,
    selectedFilters,
    setSelectedFilters,
  };
};
