import { css } from '@emotion/react';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { ACTION_MENU_PROPS_SHAPE } from '~Common/const/proptypes';
import { withButtonStripper } from '~Common/styles/mixins';
import { menuInnerBorder, palette } from '~Common/styles/colors';

import useMeetingDetailMenu from '~Meetings/hooks/useMeetingDetailMenu';
import { usePrintPage, usePrintView } from '~Deprecated/hooks/usePrintView';
import ActionMenu, { useActionMenu } from './shared/ActionMenu';

const styles = {
  menuButton: css`
    ${withButtonStripper()}
    background: unset;
    border: 1px solid ${menuInnerBorder};
    border-radius: 3px;
    color: ${palette.neutrals.gray500};
    padding: 0;
    width: 32px;
    height: 32px;
  `,
};

const View = ({
  doOpen, menuItems, actionMenuProps,
}) => (
  <>
    <button
      onClick={doOpen}
      css={styles.menuButton}
    >
      <FontAwesomeIcon
        fixedWidth
        size="lg"
        icon={faEllipsisV}
      />
    </button>
    <ActionMenu
      menuItems={menuItems}
      {...actionMenuProps}
    />
  </>
);

View.propTypes = {
  doOpen: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
  }))).isRequired,
  actionMenuProps: ACTION_MENU_PROPS_SHAPE.isRequired,
};

View.defaultProps = {};

const MeetingDetailsMenu = ({ id, type, ...props }) => {
  const { doOpen, ...actionMenuProps } = useActionMenu();
  const onClickCallback = useCallback(() => {
    actionMenuProps.onClose();
  }, [actionMenuProps]);
  const menuItems = useMeetingDetailMenu({ id, type, onClickCallback });
  const { onPrint } = usePrintView();

  const printCallback = useCallback(() => {
    onPrint({ location: `${type.toLowerCase()}/${id}` });
  }, [id, onPrint, type]);

  usePrintPage(printCallback);

  const hookProps = {
    actionMenuProps,
    menuItems,
    doOpen,
    id,
    type,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

MeetingDetailsMenu.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

MeetingDetailsMenu.defaultProps = {};

export { View };
export default MeetingDetailsMenu;
