import { css } from '@emotion/react';
import { useSupportChat } from '~Common/hooks/useSupportChat';
import { pageBackground, palette } from '~Common/styles/colors';
import { forMobileObject, forTabletObject } from '~Common/styles/mixins';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';

const styles = {
  container: css({
    backgroundColor: pageBackground,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.neutrals.white,
    width: '100%',
    minHeight: '100vh',
  }),
  contentContainer: css({
    margin: '1.75rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  mastHead: css({
    textAlign: 'center',
    color: palette.brand.indigo,
    width: '50%',
    minWidth: '320px',
    '& h1': {
      fontSize: '2.25rem !important',
    },
  }, forMobileObject({
    '& h1': {
      fontSize: '1.5rem !important',
    },
  }), forTabletObject({
    width: '75%',
    '& h1': {
      fontSize: '1.75rem !important',
    },
  })),
  marketingText: css({
    textAlign: 'center',
    width: '45%',
    minWidth: '18.75rem',
    margin: '1rem 0',
    fontSize: '1rem',
    color: palette.neutrals.gray700,
    fontWeight: 500,
  }),
  linkCta: css({
    color: palette.brand.blue,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: palette.brand.blue,
    },
  }),
  videoHolder: css({
    minWidth: '18.75rem',
    '& iframe': {
      width: '35.625rem',
      height: '19.6875rem',
      margin: '0 auto',
    },
  }, forMobileObject({
    '& iframe': {
      width: '18.75rem',
    },
  }), forTabletObject({
    width: '90%',
    '& iframe': {
      width: '28.125rem',
    },
  })),
  secondaryText: css({
    margin: '1rem 0',
    color: palette.brand.indigo,
    textAlign: 'center',
    '& h2': {
      fontSize: '1.375rem !important',
    },
  }),
  contactCta: css({
    backgroundColor: palette.brand.indigo,
    color: palette.neutrals.white,
  }),

};

interface InsightsMarketingLayoutViewProps{
  openChatBox: () => void
}

const View = ({ openChatBox, ...props }: InsightsMarketingLayoutViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    <div css={styles.content}>
      <div css={[styles.contentContainer, styles.mastHead]}>
        <h1>Measuring employee engagement is easy with the right tools</h1>
      </div>
      <div css={[styles.contentContainer, styles.marketingText]}>
        Leadr Insights helps you measure your team’s engagement, track trends over time, and get practical action steps on how to improve.
        {' '}
        <span><a href="https://www.leadr.com/request-demo-insights" target="_blank" rel="noopener noreferrer" css={styles.linkCta}>Learn more.</a></span>
      </div>
      <div css={[styles.contentContainer, styles.videoHolder]}>
        <iframe src="https://www.youtube.com/embed/YJvFVTENqlI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
      </div>
      <div css={styles.secondaryText}>
        <h2>Activate Leadr today to start using Leadr Insights</h2>
      </div>
      <div css={styles.contentContainer}>
        <LeadrButton
          data-test-id="insightsContactSales"
          css={styles.contactCta}
          size="standard"
          variant="default"
          onClick={openChatBox}
        >
          Contact Sales
        </LeadrButton>
      </div>
    </div>
  </div>
);

interface InsightsMarketingLayoutProps {
  isLoading: boolean
}

const InsightsMarketingLayout = ({ isLoading }: InsightsMarketingLayoutProps): JSX.Element => {
  const { openChat } = useSupportChat();

  const openChatBox = (): void => {
    openChat("Hi, I'd like more information about buying Leadr.");
  };

  const hookProps = {
    isLoading,
    openChatBox,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default InsightsMarketingLayout;
