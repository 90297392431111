import { ReactText, useRef } from 'react';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { hosts, getHost } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { toast } from '~Common/components/Toasts';
import { getOrganizationId } from '~Common/utils/localStorage';

interface updateReviewersProps {
  cycleId: string,
}

const updateReviewers = ({ cycleId }: updateReviewersProps): Promise<HttpCallReturn<void>> => {
  const serverUrl = {
    host: getHost(hosts.reviewCycles, '2.5'),
    uri: `/organizations/${getOrganizationId() ?? ''}/reviewCycles/${cycleId}/updateReviewers`,
  };

  return postApi(serverUrl, {}, {});
};

export const useUpdateReviewers = (): UseMutateFunction<HttpCallReturn<void>, unknown, updateReviewersProps, void> => {
  const queryKey = [getOrganizationId() ?? '', 'reviewCycles'];
  const toastId = useRef<ReactText | number | null>(null);

  const mutation = useMutation({
    mutationFn: updateReviewers,
    onMutate: () => {
      toastId.current = toast.info('Updating participants...', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: 'There was an error updating participants. Please try again.',
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async () => {
      toast.update(toastId.current, {
        render: 'Updating participants completed successfully.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutate;
};
