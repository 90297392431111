import { ComponentProps } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import { palette } from '~Common/styles/colors';

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  topSection: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0.5rem 0',
  }),
  icon: css({
    color: palette.brand.blue,
  }),
  emptyStateImage: css({
    width: '100%',
  }),
  textSection: css({
    margin: '0.5rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  action: css({
    color: palette.brand.indigo,
    textDecoration: 'underline',
    fontSize: '0.75rem',
    fontWeight: 700,
    textUnderlineOffset: '0.1875rem',
  }),
  text: css({
    color: palette.neutrals.gray700,
    fontSize: '0.75rem',
  }),
};
interface EmptyStateProps extends ComponentProps<'div'> {
  image: string,
  renderIcon?: (iconStyles: SerializedStyles) => JSX.Element,
  renderAction?: (actionStyles: SerializedStyles) => JSX.Element,
  text: string,
  altText: string,
}

const EmptyState = ({
  image,
  renderIcon,
  renderAction,
  text,
  altText,
  ...props
}: EmptyStateProps): JSX.Element => (
  <div css={styles.container} {...props}>
    <div css={styles.topSection}>
      <div>
        {renderIcon?.(styles.icon)}
      </div>
      <div css={styles.textSection}>
        {renderAction && renderAction(styles.action)}
        <div css={styles.text}>
          {text}
        </div>
      </div>
    </div>
    <EmptyStateWithImage
      renderImage={() => (
        <img
          css={styles.emptyStateImage}
          src={image}
          alt={altText}
        />
      )}
    />
  </div>
);

export default EmptyState;
