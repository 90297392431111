import { css } from '@emotion/react';
import MaskedUrlField from '~Common/components/Fields/MaskedUrlField';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';

import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import SocialLinkStaticURLs from '~Common/const/socialLinks';
import { palette } from '~Common/styles/colors';

const styles = {
  container: css``,
  field: css({
    marginBottom: '10px',
    background: palette.neutrals.gray100,
    border: 'none',
    color: palette.neutrals.gray700,

    '& label': {
      color: palette.neutrals.gray700,
      fontWeight: '400',
      textTransform: 'none',
    },
  }),
};

const View = ({ user }) => (
  <div css={styles.container}>
    <SectionHeader text="Social Media Profiles" />
    <MaskedUrlField autocomplete="off" css={styles.field} staticUrl={SocialLinkStaticURLs.twitter} initialValue={user.twitter} name="twitter" />
    <MaskedUrlField autocomplete="off" css={styles.field} staticUrl={SocialLinkStaticURLs.instagram} initialValue={user.instagram} name="instagram" />
    <MaskedUrlField autocomplete="off" css={styles.field} staticUrl={SocialLinkStaticURLs.linkedIn} initialValue={user.linkedIn} name="linkedIn" />
  </div>
);

View.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
};

const SocialMediaProfiles = ({
  ...props
}) => {
  const hookProps = {};

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SocialMediaProfiles.propTypes = {};

SocialMediaProfiles.defaultProps = {};

export { View };
export default SocialMediaProfiles;
