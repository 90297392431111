import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import LeadrButton, { LeadrButtonProps } from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { useContext } from 'react';
import { TemplateItemActionsContext } from '~Meetings/components/topic-suggestions/contexts/TemplateItemsActionContext';

const styles = {
  deleteButton: css({
    padding: 0,
  }),
};

interface ViewProps extends Omit<LeadrButtonProps<'button'>, 'data-test-id'> {
  handleDeleteAgendaTopic: () => void,
}

const View = ({
  handleDeleteAgendaTopic,
  ...props
}: ViewProps): JSX.Element => (
  <DeleteButtonWithConfirmation
    renderDeleteButton={({ onClick }) => (
      <LeadrButton
        css={styles.deleteButton}
        data-test-id="meetingsTemplateDeleteAgendaTopic"
        onClick={onClick}
        variant="icon"
        size="small"
        textButtonColor={palette.neutrals.gray700}
        {...props}
      >
        <FontAwesomeIcon icon={faTimes} />
      </LeadrButton>
    )}
    renderConfirmationButtons={({
      informationStyles,
      optionStyles,
      popoverButtonStyles,
    }) => (
      <DeleteConfirmationButtons
        onDelete={handleDeleteAgendaTopic}
        informationStyles={informationStyles}
        optionStyles={optionStyles}
        popoverButtonStyles={popoverButtonStyles}
      />
    )}
  />
);

interface DeleteButtonProps {
  topicLocalId: string,
  sectionLocalId?: string,
}

const DeleteButton = ({
  topicLocalId,
  sectionLocalId,
  ...props
}: DeleteButtonProps): JSX.Element => {
  const { deleteTemplateItem } = useContext(TemplateItemActionsContext);

  const handleDeleteAgendaTopic = (): void => {
    deleteTemplateItem(topicLocalId, sectionLocalId);
  };

  const hookProps = {
    handleDeleteAgendaTopic,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default DeleteButton;
