import { css } from '@emotion/react';
import { useCallback } from 'react';

import Dropdown, { DropdownItem, DropdownProps } from '~Common/V3/components/Dropdown';
import { palette } from '~Common/styles/colors';
import { NewActionItemStatus } from '~ActionItems/const/interfaces';
import CustomCheckbox from '~Common/V3/components/Form/CustomCheckbox';
import ActionItemStatus from '~ActionItems/components/ActionItemStatus';

const StatusTranslation = {
  [NewActionItemStatus.ToDo]: 'To Do',
  [NewActionItemStatus.InProgress]: 'In Progress',
  [NewActionItemStatus.Completed]: 'Completed',
  [NewActionItemStatus.Blocked]: 'Blocked',
};

const styles = {
  dropdown: css({
    width: '11rem',
    padding: 0,

    '.MuiSelect-select': {
      fontSize: '0.75rem',
      paddingLeft: '0.75rem',
    },

    // ToDo: Fix the placement of the dropdown arrow inside the common Dropdown component
    '.MuiSelect-icon': {
      right: '0.75rem',
    },
  }),
  dropdownItemBoy: css({
    display: 'flex',
    alignItems: 'center',
    color: palette.neutrals.gray700,
  }),
};

const View = ({
  onChange,
  items,
  value,
  renderValue,
  ...props
}: DropdownProps<NewActionItemStatus[]>): JSX.Element => (
  <Dropdown
    multiple
    displayEmpty
    css={styles.dropdown}
    value={value}
    renderItem={(item: DropdownItem<NewActionItemStatus[]>) => (
      <div css={styles.dropdownItemBoy}>
        <CustomCheckbox checked={value?.includes(item.value)} />
        <ActionItemStatus status={item.value} />
      </div>
    )}
    onChange={onChange}
    items={items}
    renderValue={renderValue}
    {...props}
  />
);

const StatusFilter = ({
  value,
  ...props
}: Omit<DropdownProps<NewActionItemStatus[]>, 'items' | 'renderValue'>): JSX.Element => {
  const items = [
    { value: NewActionItemStatus.ToDo, text: '', 'data-test-id': 'actionItemsFilterToDoItem' },
    { value: NewActionItemStatus.InProgress, text: '', 'data-test-id': 'actionItemsFilterInProgressItem' },
    { value: NewActionItemStatus.Blocked, text: '', 'data-test-id': 'actionItemsFilterBlockedItem' },
  ];

  const renderValue = useCallback(() => {
    let selectedString;

    if (value?.length) {
      selectedString = value.map((filterValue) => StatusTranslation[filterValue]).join(', ');
    } else {
      selectedString = 'All';
    }

    return `Status: ${selectedString}`;
  }, [value]);

  const hookProps = {
    value,
    items,
    renderValue,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default StatusFilter;
