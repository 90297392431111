import Avatar, { AVATAR_CONTEXT } from './index';

const CardAvatar = ({
  ...props
}) => (
  <Avatar
    {...props}
    {...AVATAR_CONTEXT.CARD}
  />
);

export default CardAvatar;
