import {
  useFroalaConfig,
  useFroalaEvents,
  useRichTextEditor,
  clickFocusFroala,
  View as FroalaEditorView,
} from '~Common/V3/components/Froala';

const useFroala = ({
  enableEmojis = true,
  froalaEventsProps,
  richTextEditorProps,
  froalaConfigProps,
  ...props
}) => {
  const { editorRef, focusFroala } = useFroalaEvents(props);

  const {
    doChange,
    value,
    setValue,
    onBlur,
    removeDraft,
  } = useRichTextEditor({
    ...richTextEditorProps,
  });

  const froalaConfig = useFroalaConfig({
    ...froalaConfigProps,
    enableEmojis,
  });

  return {
    value,
    setValue,
    Froala: (
      <FroalaEditorView
        config={froalaConfig}
        doChange={doChange}
        value={value}
        editorRef={editorRef}
        clickFocusFroala={clickFocusFroala}
        onBlur={onBlur}
        {...props}
      />
    ),
    focusFroala,
    removeDraft,
  };
};

export default useFroala;
