import { css } from '@emotion/react';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import LeadrCard from '~Common/V3/components/LeadrCard';
import { Goals as GoalsTypes } from '@leadr-hr/types';
import { ViewPerspective } from '~Goals/const/types';
import { GOALS_PAGE_SIZE, OPEN_GOAL_TABS } from '~Goals/const/defaults';
import GoalsTable, { goalSortColumnField } from '~Goals/components/Tables/GoalsTable';
import TabHeader from '~Goals/components/TabHeader';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useGoalsIndexSearch } from '~Goals/stores/useGoalsIndexSearch';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import emptySearch from '~Assets/images/empty-views/emptySearch.png';
import emptyActionItems from '~ActionItems/assets/images/emptyActionItems.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetGoals } from '~Goals/hooks/useGetGoals';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { UsePaginationReturn, usePagination } from '~Common/hooks/usePagination';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { TeamsListScope } from '~People/components/Teams/const/types';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TableFilters } from '~Goals/components/TableFilters';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { GoalsRoutes } from '~Goals/routes/GoalsRouter';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { TableLoader } from '~Goals/components/Shared/TableLoader';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import LinkButton from '~Common/V3/components/LinkButton';
import Pagination from '~Common/V3/components/Pagination';
import { useEnableCascadingGoals } from '~Goals/hooks/utils/useEnableCascadingGoals';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';

const styles = {
  leadrCard: css({
    marginBottom: '1.5rem',
  }),
  emptyStateContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1.5rem',
  }),
  emptyStateImage: css({
    height: '10rem',
  }),
};

const dataGridToGoalsSortOrder: Record<'asc' | 'desc', GoalsTypes.GetGoalsSortOrder> = {
  asc: GoalsTypes.GetGoalsSortOrder.Ascending,
  desc: GoalsTypes.GetGoalsSortOrder.Descending,
};

interface ViewProps extends UsePaginationReturn {
  viewPerspective: ViewPerspective,
  activeTab: GoalsTypes.GoalContextType,
  setActiveTab: (tab: GoalsTypes.GoalContextType) => void,
  canViewTableGroup: boolean,
  showEmptyStateCreateGoal: boolean,
  areFiltersActive: boolean,
  clearAllFilters: () => void,
  isLoading: boolean,
  isMobile: boolean,
  isSearchedOrFiltered: boolean,
  goals: GoalsTypes.Goal[] | undefined,
  isCompleted: boolean,
  onSortModelChange: (sortModel: GridSortModel) => void,
  sortByField: GoalsTypes.GetGoalsSortBy,
  sortByOrder: GoalsTypes.GetGoalsSortOrder,
  isFetching: boolean,
  page: number,
}

const View = ({
  viewPerspective,
  activeTab,
  setActiveTab,
  canViewTableGroup,
  areFiltersActive,
  clearAllFilters,
  showEmptyStateCreateGoal,
  isMobile,
  isLoading,
  isSearchedOrFiltered,
  goals,
  isCompleted,
  onSortModelChange,
  sortByField,
  sortByOrder,
  isFetching,
  numberOfPages,
  page,
  onPageChange,
  onPreviousClick,
  onNextClick,
}:ViewProps): JSX.Element => (
  <LeadrCard
    css={styles.leadrCard}
  >
    <TabHeader
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      viewPerspective={viewPerspective}
    />
    <TableFilters
      activeTab={activeTab}
      canViewTableGroup={canViewTableGroup}
      viewPerspective={viewPerspective}
      areFiltersActive={areFiltersActive}
      clearAllFilters={clearAllFilters}
    />
    {isLoading && (
      <div css={styles.emptyStateContainer}>
        <TableLoader
          rowsNum={10}
          activeTab={activeTab}
          isMobile={isMobile}
        />
      </div>
    )}
    {!isLoading && (
      <>
        <div css={styles.emptyStateContainer}>
          {isSearchedOrFiltered && goals && goals.length === 0 && (
            <EmptyStateWithImage
              renderImage={() => (
                <img
                  css={styles.emptyStateImage}
                  src={emptySearch}
                  alt="No Search Results"
                  data-test-id="goalsNoSearchResults"
                />
              )}
              text="No goals found."
            />
          )}
          {!isSearchedOrFiltered && goals && goals.length === 0 && (
            <EmptyStateWithImage
              renderImage={() => (
                <img
                  css={styles.emptyStateImage}
                  src={emptyActionItems}
                  alt="Empty Goals"
                />
              )}
              renderText={() => (
                <>
                  {isCompleted && (
                  <span>
                    {`You have no completed ${activeTab.toLocaleLowerCase()} goals.`}
                  </span>
                  )}
                  {!isCompleted && (
                  <span>
                    {`You have no open ${activeTab.toLocaleLowerCase()} goals.`}
                    {showEmptyStateCreateGoal && (
                      <LinkButton
                        to={GoalsRoutes.Create}
                        variant="text"
                        data-test-id="actionItemsEmptyStateCreateActionItem"
                        renderContents={() => <>Click here to create one.</>}
                      />
                    )}
                  </span>
                  )}
                </>
              )}
            />
          )}
        </div>
        {!isLoading && goals && goals.length > 0 && (
          <>
            <GoalsTable
              data={goals}
              canViewTableGroup={canViewTableGroup}
              onSortModelChange={onSortModelChange}
              sortByField={sortByField}
              sortByOrder={sortByOrder}
              isFetching={isFetching}
            />
            {numberOfPages > 1 && (
              <Pagination
                page={page}
                onPageChange={onPageChange}
                numberOfPages={numberOfPages}
                onPreviousClick={onPreviousClick}
                onNextClick={onNextClick}
              />
            )}
          </>
        )}
      </>
    )}
  </LeadrCard>
);

interface GoalsProps {
  viewPerspective: ViewPerspective,
}

const Goals = ({
  viewPerspective,
}: GoalsProps): JSX.Element => {
  const [activeTab, setActiveTab] = useQueryParamState<GoalsTypes.GoalContextType>(
    'goals',
    'tab',
    OPEN_GOAL_TABS[0].value as GoalsTypes.GoalContextType,
  );

  const isTeamView = activeTab === GoalsTypes.GoalContextType.Team;
  const isTableGroupCobrandingEnabled = useFeatureFlag('webAppTgCobrandingAndOrgClarity');
  const { enableTheTable } = useOrgDetailsContext();
  const canViewTableGroup = enableTheTable && isTableGroupCobrandingEnabled && isTeamView;
  const debouncedSearchText = useGoalsIndexSearch((state) => state.debouncedSearchText);
  const orgUserId = getOrganizationUserId();

  const [statusFilter, setStatusFilter] = useQueryParamState<GoalsTypes.GoalStatus[]>('goals', 'status', [], true);
  const [priorityFilter, setPriorityFilter] = useQueryParamState<GoalsTypes.GoalPriority[]>('goals', 'priority', [], true);
  const [categoryFilter, setCategoryFilter] = useQueryParamState<GoalsTypes.GoalCategory[]>('goals', 'category', [], true);
  const [ownerFilter, setOwnerFilter] = useQueryParamState<string[]>('goals', 'owner', [], true);
  const [participantFilter, setParticipantFilter] = useQueryParamState<string[]>('goals', 'participant', orgUserId ? [orgUserId] : [], true);
  const [teamFilter, setTeamFilter] = useQueryParamState<string[]>('goals', 'team', [], true);

  const [page, setPage] = useState(1);

  const location = useLocation();
  const isCompleted = location.pathname === GoalsRoutes?.ListComplete;
  const [sortByField, setSortByField] = useState(GoalsTypes.GetGoalsSortBy.DueDate);
  const [sortByOrder, setSortByOrder] = useState(GoalsTypes.GetGoalsSortOrder.Ascending);

  const history = useHistory();

  const {
    orgLevelEnableCascadingGoals,
    userLevelEnableCascadingGoals,
  } = useEnableCascadingGoals();

  const enableCascadingGoals = orgLevelEnableCascadingGoals && userLevelEnableCascadingGoals;

  // If the filters/searchText change, reset the page number so we don't look at page 2 of a 1 page searchText as an example.
  useEffect(() => {
    setPage(1);
  }, [history.location.search, debouncedSearchText]);

  const params: GoalsTypes.Requests.GetGoalsRequestQueryParameters = {
    skip: (page - 1) * GOALS_PAGE_SIZE,
    take: GOALS_PAGE_SIZE,
    contextType: activeTab,
    contextId: activeTab === GoalsTypes.GoalContextType.Team && teamFilter.length ? teamFilter.join(',') : '',
    searchText: debouncedSearchText,
    searchField: debouncedSearchText ? GoalsTypes.GetGoalsSearchField.Title : undefined,
    isCompleted,
    ownerIds: ownerFilter.join(','),
    participantIds: activeTab !== GoalsTypes.GoalContextType.Organization ? participantFilter.join(',') : undefined,
    priority: priorityFilter.length ? priorityFilter.toString() : undefined,
    status: statusFilter.length ? statusFilter.toString() : undefined,
    category: activeTab === GoalsTypes.GoalContextType.Team && categoryFilter.length ? categoryFilter[0] : undefined,
    sortBy: sortByField,
    sortOrder: sortByOrder,
    enableCascading: enableCascadingGoals,
  };

  const {
    data, isLoading: goalsAreLoading, isFetching,
  } = useGetGoals({ params });

  const goals = useMemo(() => data?.response.filter((goal) => goal.permissions.includes(GoalsTypes.GoalPermission.CanViewGoal)), [data?.response]);
  const [isLoading] = useSkeletonLoaders(goalsAreLoading);

  const usePaginationProps = usePagination({
    totalCount: data?.meta?.total ?? 1,
    pageSize: GOALS_PAGE_SIZE,
    page,
    setPage,
  });

  const areFiltersActive = statusFilter.length > 0
    || priorityFilter.length > 0
    || categoryFilter.length > 0
    || ownerFilter.length > 0
    || participantFilter.length > 0
    || teamFilter.length > 0;

  const isSearchActive = !!debouncedSearchText;

  const isSearchedOrFiltered = areFiltersActive || isSearchActive;

  const clearAllFilters = (): void => {
    setStatusFilter([]);
    setPriorityFilter([]);
    setCategoryFilter([]);
    setOwnerFilter([]);
    setParticipantFilter([]);
    setTeamFilter([]);
  };

  // Clear out the status filters when changing between Open and Closed, since they are mutually exclusive.
  useEffect(() => {
    setStatusFilter([]);
  }, [setStatusFilter, viewPerspective]);

  // Clear out the category and team filters if we're not on the team tab.
  useEffect(() => {
    if (activeTab !== GoalsTypes.GoalContextType.Team) {
      setCategoryFilter([]);
      setTeamFilter([]);
    }
  }, [activeTab, setCategoryFilter, setTeamFilter]);

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (sortModel.length) {
      setSortByField(goalSortColumnField[sortModel[0].field]);
      if (sortModel[0].sort) {
        setSortByOrder(dataGridToGoalsSortOrder[sortModel[0].sort]);
      }
    }
  }, []);

  const {
    isAdmin,
    isExecutive,
  } = useUserPermissions();

  const listScope = isAdmin ? TeamsListScope.AllTeams : TeamsListScope.OwnedTeams;

  const { data: teamsData } = useGetTeams({
    page: 0,
    count: 1000, // TODO: Need to make this NOT a predefined number - Should update this to pull everything or change the select box to be a searchfilter or something
    listScope,
  });

  const teamsList = teamsData?.response.teams.map((team) => ({
    label: team.name,
    value: team.teamId,
  })) || [];

  const hasTeams = teamsList.length > 0;

  const showEmptyStateCreateGoal = ((activeTab === GoalsTypes.GoalContextType.Team && hasTeams)
  || (activeTab === GoalsTypes.GoalContextType.Personal)
  || (activeTab === GoalsTypes.GoalContextType.Organization && (isAdmin || isExecutive)));

  const isMobile = useIsMobileQuery();

  const hookProps = {
    viewPerspective,
    activeTab,
    setActiveTab,
    areFiltersActive,
    canViewTableGroup,
    clearAllFilters,
    goals,
    hasTeams,
    isCompleted,
    isFetching,
    isMobile,
    isSearchedOrFiltered,
    onSortModelChange,
    page,
    setTeamFilter,
    isLoading,
    sortByField,
    sortByOrder,
    teamFilter,
    showEmptyStateCreateGoal,
    ...usePaginationProps,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };

export default Goals;
