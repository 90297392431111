import { css } from '@emotion/react';
import { OrgSettings } from '~Common/const/Organization';
import { palette } from '~Common/styles/colors';
import DrawerInput from '~Common/V3/components/DrawerInput';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';

const styles = {
  customFavoritesFields: css({}),
  descriptionText: css({
    color: palette.neutrals.gray700,
    marginBottom: '0.5rem',
  }),
  customFavoriteFieldsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
  customFavoriteField: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.625rem',
  }),
};

interface ViewProps {
  workingGeniusUrl: string | undefined,
}

const View = ({
  workingGeniusUrl,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.customFavoritesFields}
    {...props}
  >
    <SettingsSectionHeader
      renderContents={() => (
        <>Working Genius</>
      )}
    />
    <p css={styles.descriptionText}>
      If your organization has paid for a working genius assessment, provide the unique assessment URL here for team members to access throughout Leadr.
    </p>
    <div css={styles.customFavoriteFieldsContainer}>
      <div css={styles.customFavoriteField}>
        <DrawerInput
          maxLength={200}
          name="workingGeniusUrl"
          label="Working Genius Organization URL"
          initialValue={workingGeniusUrl}
        />
      </div>
    </div>
  </div>
);

interface WorkingGeniusFieldsProps {
  orgSettings: OrgSettings,
}

const WorkingGeniusFields = ({
  orgSettings,
  ...props
}: WorkingGeniusFieldsProps): JSX.Element => {
  const {
    workingGeniusUrl,
  } = orgSettings;

  const hookProps = {
    workingGeniusUrl: workingGeniusUrl || 'https://www.workinggenius.com/',
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default WorkingGeniusFields;
