import { ReactText, useRef } from 'react';
import {
  InfiniteData,
  useMutation,
  UseMutationResult,
} from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import {
  CreateRecognitionShape, InfiniteQueryParamsProps, PeopleDataReturn, ErrorShape,
} from '~Recognition/const/types';
import { recognitionKeys } from '~Recognition/const/queryKeys';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { OPTIMISTIC_ID } from '~Recognition/const/defaults';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { produce } from 'immer';
import { RecognitionListResponse } from './useGetRecognition';

interface EditRecognitionProps {
  recognition: CreateRecognitionShape,
  id: number,
}

const editRecognition = ({ recognition, id }: EditRecognitionProps): Promise<HttpCallReturn<string>> => {
  const serverUrl = {
    host: getHost(hosts.recognition),
    uri: `/organizations/${getOrganizationId() ?? ''}/recognition/${id}`,
  };

  return patchApi<string>(serverUrl, { ...recognition }, {});
};

export interface RecognitionEditParams {
  id: number,
  recognition: CreateRecognitionShape,
}
interface UseEditRecognitionProps {
  infiniteQueryParams: InfiniteQueryParamsProps,
}

export const useEditRecognition = ({
  infiniteQueryParams,
}:UseEditRecognitionProps): UseMutationResult<HttpCallReturn<string>, ErrorShape, RecognitionEditParams, { previousRecognitionListQuery: unknown; }> => {
  const toastId = useRef<ReactText | number | null>(null);
  // TODO: Remove the casting when useNewPeople gets typed
  const { peopleData } = useNewPeople({
    useOrgIds: true,
    allowSelf: true,
  }) as unknown as Record<string, Record<string, PeopleDataReturn>>;

  const currentUserId = getUserId() ?? '';

  const mutation = useMutation({
    mutationFn: editRecognition,
    onMutate: async (recognitionToEdit: RecognitionEditParams) => {
      // Cancel any existing outbound queries
      await queryClient.cancelQueries({ queryKey: recognitionKeys.lists() });
      await queryClient.cancelQueries({ queryKey: recognitionKeys.detail(recognitionToEdit.id) });

      const previousRecognitionListQuery = queryClient.getQueryData(recognitionKeys.list({ ...infiniteQueryParams }));
      const createrPersonInformation = peopleData[currentUserId];

      const editedRecognitionDetails = {
        recognitionId: OPTIMISTIC_ID,
        canDelete: true,
        canEdit: true,
        content: recognitionToEdit.recognition.content,
        createdBy: {
          firstName: createrPersonInformation.firstName,
          lastName: createrPersonInformation.lastName,
          orgUserId: createrPersonInformation.orgUserId,
          profileImageUrl: createrPersonInformation.profileImageUrl || '',
        },
        recipients: recognitionToEdit.recognition.recipients.map((recipient) => {
          const recipientPersonInformation = peopleData[recipient];
          return {
            firstName: recipientPersonInformation.firstName,
            lastName: recipientPersonInformation.lastName,
            orgUserId: recipientPersonInformation.orgUserId,
            profileImageUrl: recipientPersonInformation.profileImageUrl || '',
          };
        }),
        createdOn: OPTIMISTIC_ID.toLocaleString(),
        modifiedOn: '', // TODO: Make this the current modified date?
      };

      // eslint-disable-next-line max-len
      queryClient.setQueryData<InfiniteData<HttpCallReturn<RecognitionListResponse>, unknown>>(recognitionKeys.list({ ...infiniteQueryParams }), (oldData) => {
        if (oldData && oldData.pages.length > 0) {
          return produce(oldData, (draft) => {
            if (draft.pages) {
              draft.pages = draft.pages.map((page) => ({
                ...page,
                response: {
                  ...page.response,
                  items: page.response.items.map((item) => {
                    if (item.recognitionId === recognitionToEdit.id) {
                      return editedRecognitionDetails;
                    }
                    return item;
                  }),
                },
              }));
            }
          });
        }

        return oldData;
      });
      toastId.current = toast.info('Updating your recognition...', { autoClose: false });

      return {
        previousRecognitionListQuery,
      };
    },
    onError: (error: ErrorShape, _, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(recognitionKeys.list({ ...infiniteQueryParams }), snapshot?.previousRecognitionListQuery);

      const errorMessage = error?.status?.messages[0]?.message ?? 'There was an error updating your recognition. Please try again.';
      toast.update(toastId.current, {
        render: errorMessage,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async (_, variables) => {
      toast.update(toastId.current, {
        render: 'Successfully updated your recognition.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      await queryClient.invalidateQueries({ queryKey: recognitionKeys.lists() });
      await queryClient.invalidateQueries({ queryKey: recognitionKeys.detail(variables.id) });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
  });

  return mutation;
};
