import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  buttonDefaultColor,
  buttonIconColor,
  buttonLinkColor,
  buttonBaseTextColor,
} from '~Deprecated/ui/styles/colors';

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
export const BUTTON_VARIANTS = {
  DEFAULT: 'default',
  LINK: 'link',
  ICON: 'icon',
  TEXT: 'text',
};

const baseStyle = css`
  height: 40px;
  padding: 8px 16px;
  color: ${buttonBaseTextColor};
  border: 0;
  background-color: inherit;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;

  &:hover, &:active, &:focus {
    outline: 0;
  }
`;

const VARIANT_TO_BUTTON = {
  default: css`
    background-color: ${buttonDefaultColor};
    border-radius: 4px;
    line-height: 1.5em;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      opacity: 0.8;
    }

    & svg {
      margin-right: 8px;
      vertical-align: middle;
      display: inline-block;
      padding-bottom: 3px;
    }
  `,

  link: css`
    padding: 0;
    text-transform: uppercase;
    color: ${buttonLinkColor};
    font-weight: 700;

    &:hover {
      opacity: 40%;
    }
    & svg {
      vertical-align: middle;
      display: inline-block;
      margin-bottom: 3px;
      margin-left: 8px;
      margin-right: 8px;
    }
  `,
  icon: css`
    padding: 0;
    color: ${buttonIconColor};
    & :focus {
      outline: none;
    }
    & svg {
      color: ${buttonIconColor};
    }
  `,
};

/**
 * @deprecated Old UI Button, use packages/leadr-frontend/src/common/V3/components/Button/index.tsx instead
 */
const Button = ({
  variant, type, children, ...props
}) => {
  const styleVariant = VARIANT_TO_BUTTON[variant];

  return (
    <button
      css={[baseStyle, styleVariant]}
      type={type}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

Button.defaultProps = {
  variant: BUTTON_VARIANTS.DEFAULT,
  type: 'button',
};

export default Button;
