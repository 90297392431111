import { css } from '@emotion/react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { useLeaveMeeting } from '~Meetings/hooks/useLeaveMeeting';
import { useUserProfile } from '~Deprecated/hooks/profile/useUserProfile';
import { getUserId } from '~Common/utils/localStorage';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import DrawerInstructions from '~Common/V3/components/Drawers/DrawerInstructions';

const styles = {
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

export const leaveMeetingTemplate = {
  name: 'LEAVE_MEETING',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton,
  createButton,
  renderDrawerInstructions,
  onFormSubmit,
}) => (
  <DrawerLayout
    onSubmit={onFormSubmit}
    renderHeader={() => (
      <DrawerHeader
        renderCloseButton={closeButton}
        title="Leave Meeting"
      />
    )}
    renderBody={() => (
      <div
        css={styles.drawerBody}
      >
        {renderDrawerInstructions()}
        <div />
        {createButton()}
      </div>
    )}
  />
);

View.propTypes = {
  closeButton: PropTypes.func.isRequired,
  createButton: PropTypes.func.isRequired,
  renderDrawerInstructions: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

View.defaultProps = {};

const LeaveMeeting = ({
  drawerState, id, type, ...props
}) => {
  const dispatch = useDispatch();
  const { item: meetingDetails } = useMeetingDetails({ id, type }) ?? {};
  const { user } = useUserProfile(getUserId());
  const organizerOrganizationUserId = user?.organizationUserId;

  const doLeaveMeeting = useLeaveMeeting({ template: leaveMeetingTemplate });

  const renderDrawerInstructions = () => {
    const drawerText = '<p>You are about to leave this meeting series.</p>';
    const drawerSubText = '<p>You will no longer see this meeting on your Upcoming or Archived list pages, '
      + 'and you will not be able to view or interact with agenda, notes, or anything else pertaining to any meeting in this series. '
      + 'This will not prevent you from being re-added to the meeting at a later date.</p>';

    return (
      <div>
        <DrawerInstructions
          html={drawerText}
        />
        <br />
        <DrawerInstructions
          html={drawerSubText}
        />
      </div>
    );
  };
  const createButton = useCallback(() => (
    <LeadrButton
      type="submit"
      color="danger"
      data-test-id="meetingLeave"
    >
      Leave Meeting
    </LeadrButton>
  ), []);

  const onFormSubmit = useCallback((e) => {
    e.preventDefault();
    const meetingAttendees = meetingDetails?.attendeeOrgUserIds;
    const updatedMeetingAttendees = meetingAttendees.filter((orgUserId) => orgUserId !== organizerOrganizationUserId);
    const leaveMeetingData = {
      id,
      factoryId: meetingDetails?.factoryId,
      attendeeIds: [meetingDetails?.organizer.orgUserId, ...updatedMeetingAttendees],
    };

    doLeaveMeeting(leaveMeetingData);
  }, [meetingDetails, id, organizerOrganizationUserId, doLeaveMeeting]);

  const closeDrawerClick = () => {
    dispatch(popDrawer({ popAll: true }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={closeButtonStyles}
      size="large"
    />
  );

  const hookProps = {
    closeButton,
    createButton,
    renderDrawerInstructions,
    onFormSubmit,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

LeaveMeeting.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  drawerState: PropTypes.func.isRequired,
  setDrawerState: PropTypes.func.isRequired,
};

LeaveMeeting.defaultProps = {};

registerDrawer({
  templateName: leaveMeetingTemplate.name,
  component: LeaveMeeting,
});

export { View };
export default LeaveMeeting;
