import { useEffect, useState, RefObject } from 'react';

type ReturnType<T> = RefObject<T>;

export const useComponentVisible = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
): ReturnType<T> => {
  const [isInside, setIsInside] = useState(false);

  const handleClick = (e: MouseEvent): void => {
    const isOutsideRef = ref.current && !ref.current.contains(e.target as Node);
    const isInsideCalendar = (e.target as HTMLElement).closest('.MuiCalendarPicker-root') !== null;

    if (isInside && isOutsideRef) {
      setIsInside(false);
      callback();
    } else {
      setIsInside(isInsideCalendar);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return ref;
};

export default useComponentVisible;
