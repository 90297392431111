import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { palette } from '~Common/styles/colors';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import EmptyGear from '~Meetings/assets/images/workingGenius/emptyGear.svg';
import { teamMapDrawerTemplate } from '~Meetings/components/details/WorkingGeniusMap/TeamMapDrawer';

const styles = {
  leadrButton: css({
    display: 'flex',
    alignItems: 'center',
    gap: '.375rem',
    paddingLeft: '0',
    fontWeight: 400,
    fontSize: '.75rem',
  }),
  image: css({
    width: '.75rem',
    height: '.75rem',
    filter: 'invert(33%) sepia(54%) saturate(622%) hue-rotate(181deg) brightness(94%) contrast(103%)', // palette.brand.indigo
  }),
};

interface ViewProps {
  handleViewTeamWorkingGeniusClick: () => void,
}

const View = ({
  handleViewTeamWorkingGeniusClick,
  ...props
}: ViewProps): JSX.Element => (
  <LeadrButton
    css={styles.leadrButton}
    variant="text"
    textButtonColor={palette.brand.indigo}
    onClick={handleViewTeamWorkingGeniusClick}
    data-test-id="meetingsMobileEditParticipants"
    {...props}
  >
    <EmptyGear css={styles.image} title="Working Genius gear" />
    <span>View Team Working Genius</span>
  </LeadrButton>
);

const MobileWorkingGeniusMap = ({ ...props }): JSX.Element => {
  const dispatch = useDispatch();

  const handleViewTeamWorkingGeniusClick = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...teamMapDrawerTemplate,
      },
    }));
  };

  const hookProps = {
    handleViewTeamWorkingGeniusClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MobileWorkingGeniusMap;
