import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  base: css({
    fontSize: '1.125rem',
    fontWeight: 600,
    margin: '0',
    wordBreak: 'keep-all',
  }),
  positive: css({
    color: palette.brand.green,
  }),
  negative: css({
    color: palette.brand.red,
  }),
  default: css({
    color: palette.neutrals.gray600,
  }),
};

interface InsightsDeltaProps {
  value: number | string | null,
}

const InsightsDelta = ({
  value,
}: InsightsDeltaProps): JSX.Element => (
  <>
    {typeof value === 'number' ? (
      <p className="delta" css={[styles.base, value >= 0 ? styles.positive : styles.negative]}>
        {value > 0 ? '+' : ''}
        {Math.round(value)}
        %
      </p>
    ) : (
      <p className="delta" css={[styles.base, styles.default]}>
        N/A
      </p>
    )}
  </>
);

export default InsightsDelta;
