import { css } from '@emotion/react';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';

import { palette } from '~Common/styles/colors';
import { HTMLString } from '~Common/types';
import { isHTMLText } from '~Common/utils/isHTMLText';

const styles = {
  teamClarityQuestion: css({
    color: palette.neutrals.gray700,
    fontWeight: 600,
    fontSize: '0.75rem',
  }),
  teamClarityAnswer: css({
    color: palette.neutrals.gray800,
  }),
};

interface TeamClarityQuestionDisplayProps {
  question: HTMLString,
  answer: string,
}

const TeamClarityQuestionDisplay = ({
  question,
  answer,
  ...props
}: TeamClarityQuestionDisplayProps): JSX.Element => (
  <div {...props}>
    <h4 css={styles.teamClarityQuestion}>{question}</h4>
    {isHTMLText(answer) && (
      <HTMLRenderer css={styles.teamClarityAnswer} htmlText={answer} />
    )}
    {!isHTMLText(answer) && (
      <div css={styles.teamClarityAnswer}>
        {answer}
      </div>
    )}
  </div>
);

export default TeamClarityQuestionDisplay;
