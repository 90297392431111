import { connect } from 'react-redux';

import { getOrganizationById } from '~Deprecated/selectors/organizationInsights/getOrganizationById';
import { loadOrganizationInsightDetailsAction } from '~Deprecated/actions/organizationInsights/loadOrganizationInsightDetails';
import { clearOrganizationMembersAction } from '~Deprecated/actions/organizationMembers/clearOrganizationMembers';

const mapStateToProps = (state, { id }) => {
  const organization = getOrganizationById(state, { id }) || {};
  return {
    ids: organization.members || [],
  };
};

const mapDispatchToProps = {
  loadOrganizationInsightDetailsAction,
  clearOrganizationMembersAction,

};

export default connect(mapStateToProps, mapDispatchToProps);
