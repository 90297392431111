import { css } from '@emotion/react';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hexToRGBA, palette } from '~Common/styles/colors';
import { ReviewShape } from '~Reviews/V2/Const/types';
import TextWrapper from './TextWrapper';
import AgendaTopicsText from './AgendaTopicsText';
import { ReflectionTypeEnum } from '../../types';
import FeedbackText from './FeedbackText';
import LearningText from './LearningText';
import GoalsText from './GoalsText';
import RecognitionText from './RecognitionText';
import { getReflectionIcon } from '../../const/functions';
import AccomplishmentText from './AccomplishmentText';

const styles = {
  icon: css({
    color: palette.brand.indigo,
    fontSize: '2rem',
  }),
  infoBox: css({
    alignItems: 'center',
    borderRadius: '5px',
    backgroundColor: hexToRGBA(palette.brand.sky, 0.1),
    color: palette.neutrals.gray800,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.875rem',
    fontWeight: 500,
    gap: '1rem',
    padding: '.75rem',
  }),
};

interface ViewProps {
  reflectionType: ReflectionTypeEnum,
  icon: IconDefinition,
  review: ReviewShape,
  reviewCycleDateRangeText: string,
}

export const View = ({
  reflectionType,
  icon,
  review,
  reviewCycleDateRangeText,
}: ViewProps): JSX.Element => (
  <div css={styles.infoBox}>
    <FontAwesomeIcon
      icon={icon}
      css={styles.icon}
    />
    <TextWrapper
      review={review}
      reviewCycleDateRangeText={reviewCycleDateRangeText}
      renderText={({
        isReviewee,
        reviewerFullName,
        secondaryReviewerFullName,
        revieweeFullName,
        hasSecondaryReviewer,
      }) => (
        <>
          {reflectionType === ReflectionTypeEnum.Accomplishment && (
            <AccomplishmentText
              isReviewee={isReviewee}
              revieweeFullName={revieweeFullName}
            />
          )}
          {reflectionType === ReflectionTypeEnum.BookmarkedTopic && (
            <AgendaTopicsText
              isReviewee={isReviewee}
              reviewerFullName={reviewerFullName}
              secondaryReviewerFullName={secondaryReviewerFullName}
              revieweeFullName={revieweeFullName}
              hasSecondaryReviewer={hasSecondaryReviewer}
            />
          )}
          {reflectionType === ReflectionTypeEnum.Feedback && (
            <FeedbackText
              isReviewee={isReviewee}
              revieweeFullName={revieweeFullName}
            />
          )}
          {reflectionType === ReflectionTypeEnum.Goal && (
            <GoalsText
              isReviewee={isReviewee}
              revieweeFullName={revieweeFullName}
            />
          )}
          {reflectionType === ReflectionTypeEnum.Recognition && (
            <RecognitionText
              isReviewee={isReviewee}
              revieweeFullName={revieweeFullName}
            />
          )}
          {reflectionType === ReflectionTypeEnum.Learning && (
            <LearningText
              isReviewee={isReviewee}
              revieweeFullName={revieweeFullName}
            />
          )}
        </>
      )}
    />
  </div>
);

interface InfoBoxProps extends Omit<ViewProps, 'reflectionType' | 'icon'> {
  reflectionType: ReflectionTypeEnum,
  review: ReviewShape,
}

export const InfoBox = ({
  reflectionType,
  review,
  ...props
}: InfoBoxProps): JSX.Element => {
  const icon = getReflectionIcon(reflectionType);

  const hookProps = {
    reflectionType,
    icon,
    review,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
