import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { LearningTemplateFromSearch } from '~Learning/const/interfaces';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';
import { learningTemplatesQueryKeys } from '../queryKeys';

interface GetLearningTemplateResponse {
  learningTemplates: LearningTemplateFromSearch[],
  total: number,
  count: number,
}

const getLearningTemplateSearch = async ({ queryKey }: QueryFunctionContext<readonly string[]>): Promise<HttpCallReturn<GetLearningTemplateResponse>> => {
  const [, , , , , curated, page, count, searchString] = queryKey;
  const queryParams = {
    searchString,
    page,
    count,
    curated,
  };

  const queryData = buildQueryString(queryParams);
  // eslint-disable-next-line max-len
  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/templates/search${queryData}`;

  return getApi<GetLearningTemplateResponse>(serverUrl);
};

interface LearningTemplateSearchQueryParameters {
  page: number,
  count: number,
  curated: boolean,
  searchString: string,
  isValidSearch: boolean,
  enabled: boolean,
}

export interface LearningTemplateSearchHookReturn {
  learningTemplates: LearningTemplateFromSearch[],
  isLoading: boolean,
  total: number,
  count: number,
}

export const useLearningTemplateSearch = ({
  page,
  count,
  curated,
  searchString,
  isValidSearch,
  enabled,
}: LearningTemplateSearchQueryParameters): LearningTemplateSearchHookReturn => {
  const result = useQuery({
    queryKey: learningTemplatesQueryKeys.search({
      curated,
      page,
      count,
      searchString,
    }),
    queryFn: getLearningTemplateSearch,
    enabled: enabled && !!searchString && isValidSearch,
  });

  return {
    learningTemplates: result.data?.response?.learningTemplates ?? [],
    count: result.data?.response?.count ?? 0,
    isLoading: result.isLoading,
    total: result.data?.response?.total ?? 0,
  };
};
