import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { forMobileObject, withBaseShadow } from '~Common/styles/mixins';
import { IS_MOBILE_APP } from '~Root/functions/isMobileApp';
import backgroundImageWave from '~Assets/images/backgroundImageWave.svg?url';

export const authStyles = {
  container: css({
    alignItems: 'center',
    // ToDo: Remove once we fix the typing issue with SVG imports
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    background: `url(${backgroundImageWave})`,
    backgroundColor: palette.brand.indigo,
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
  }, IS_MOBILE_APP && {
    paddingTop: 'env(safe-area-inset-top)',
  }),
  logo: css({
    display: 'block',
    height: '4rem',
    margin: '0.5rem auto',
  }),
  routeContainer: css({
    backgroundColor: palette.neutrals.white,
    border: `1px solid ${palette.neutrals.gray500}`,
    borderRadius: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    height: '75%',
    maxHeight: '37.5rem',
    overflow: 'auto',
    padding: '0.625rem',
    width: '35%',
    maxWidth: '30rem',
  }, forMobileObject({
    height: '90%',
    width: '90%',
  }), withBaseShadow()),
  innerContainer: css({
    flex: 1,
    justifyContent: 'center',
    padding: '0 1rem',
  }),
};
