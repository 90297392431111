import { produce } from 'immer';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { queryClient } from '~Common/const/queryClient';
import { getOrganizationId } from '~Common/utils/localStorage';

import { toast } from '~Common/components/Toasts';
import { HttpCallReturn, patchApi } from '~Deprecated/services/HttpService';
import { meetingKeys } from '~Meetings/const/queryKeys';
import MeetingsErrorToast from '~Meetings/components/MeetingErrorToast';
import { AgendaSection, GetAgendaReturn } from '~Meetings/hooks/v2/useGetAgendas';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import { useDraft } from '~Common/hooks/useDraft';
import { useAgendaPermissionsStore } from '../utils/useAgendaPermissions';

interface EditSection {
  text: string
  isRecurring: boolean,
}

interface EditAgendaSectionRequest {
  section: EditSection,
  huddleId: string,
  eventId?: string,
}

interface EditAgendaSectionResponse {
  id: string,
}

interface EditAgendaSectionProps {
  sectionId: string
  data: EditAgendaSectionRequest,
}

export const editAgendaSection = ({ sectionId, data }: EditAgendaSectionProps): Promise<HttpCallReturn<EditAgendaSectionResponse>> => (
  patchApi(`/organizations/${getOrganizationId() ?? ''}/huddles/agendas/sections/${sectionId}`, data, {})
);

interface UseEditAgendaSectionParams {
  huddleId: string,
  sectionId: string,
  draftKey?: string[],
}

export const useEditAgendaSection = ({
  huddleId,
  sectionId,
  draftKey,
}: UseEditAgendaSectionParams): UseMutationResult<HttpCallReturn<EditAgendaSectionResponse>, unknown, EditAgendaSectionProps> => {
  const showItemEditor = useShowItemEditor((state) => state.showItemEditor);
  const hideItemEditor = useShowItemEditor((state) => state.hideItemEditor);

  const {
    disableSectionWorkflows,
    enableSectionWorkflows,
  } = useAgendaPermissionsStore((state) => ({
    disableSectionWorkflows: state.disableSectionWorkflows,
    enableSectionWorkflows: state.enableSectionWorkflows,
  }));
  const { removeDraft } = useDraft(draftKey);

  return useMutation({
    mutationFn: editAgendaSection,
    onMutate: async ({ data }) => {
      const previousAgendas = queryClient.getQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId));

      hideItemEditor(sectionId);
      disableSectionWorkflows(sectionId);

      await queryClient.cancelQueries({ queryKey: meetingKeys.agendaList(huddleId) });

      queryClient.setQueryData<HttpCallReturn<GetAgendaReturn>>(meetingKeys.agendaList(huddleId), (originalData) => {
        if (originalData) {
          const index = originalData.response.agendas.findIndex((agenda) => agenda.id === sectionId);
          if (index !== -1) {
            return produce(originalData, (draft) => {
              draft.response.agendas[index] = {
                ...draft.response.agendas[index],
                text: data.section.text,
                isRecurring: data.section.isRecurring,
              } as AgendaSection;
            });
          }
        }

        return originalData;
      });

      return { previousAgendas };
    },
    onError: (_, { data }, snapshot) => {
      toast.error(<MeetingsErrorToast />, {
        autoClose: 1500,
      });

      queryClient.setQueryData(meetingKeys.agendaList(huddleId), snapshot?.previousAgendas);

      const editedAgendaSection = snapshot?.previousAgendas?.response.agendas.find((agenda) => agenda.id === sectionId);

      /*
        The only time the editor is available is when you are editing the title of the section
        We reuse this endpoint for editing text or recurrence of a section
        This if statement makes it so that we only reopen the editor if the request fails to update the title of the section
        If it fails to update the recurrence, then we don't want to reopen the editor, since you can't change it there
      */
      if (data.section.isRecurring === editedAgendaSection?.isRecurring) {
        showItemEditor(editedAgendaSection.id);
      }
    },
    onSuccess: () => {
      removeDraft();
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: meetingKeys.agendaLists() });
      enableSectionWorkflows(sectionId);
    },
  });
};
