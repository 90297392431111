import {
  ReactText, useEffect, useRef, useState,
} from 'react';
import { css } from '@emotion/react';
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { popAfterAction } from '~Deprecated/actions/drawers/popAfter';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { inputBackgrounds, palette } from '~Common/styles/colors';
import { forMobileObject } from '~Common/styles/mixins';
import {
  CreateSurveyDTO, SurveyQuestion, SurveyTemplate, useCreateSurveyTemplate, useUpdateSurveyTemplate,
} from '~Surveys/Hooks/useSurveyTemplates';

import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { Form, TextField } from '~Common/V3/components/uncontrolled';
import Froala from '~Common/V3/components/Froala';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import SpinnerButton from '~Common/V3/components/Buttons/SpinnerButton';
import { SURVEY_QUESTION_TYPES } from '~Common/const/surveys';
import { toast } from '~Common/components/Toasts';
import Questions from '../../Create/Questions';
import { surveyTemplatesTemplate } from '../SurveyTemplateDrawer';
import { newQuestionTemplate } from '../../NewQuestion';
import { editSurveyTemplateFormResolver, FormValues } from './EditSurveyTemplateFormSchema';

export const editSurveyTemplatesTemplate = {
  name: 'SURVEY_TEMPLATES_EDIT',
  width: DRAWER_WIDTHS.PRIMARY,
};

const styles = {
  addQuestionButton: css({
    marginTop: '0.625rem',
  }),
  button: css(forMobileObject({
    width: '100%',
  })),
  buttonContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75rem',
    padding: '0.5rem 1.5rem 0.5rem 1rem',
  }, forMobileObject({
    flexDirection: 'column',
  })),
  dragDrop: css({
    flexWrap: 'wrap',
    '& > div': {
      backgroundColor: inputBackgrounds.rgba,
    },
  }),
  drawerBody: css({
    padding: '1rem',
    paddingRight: '1.5rem',
  }),
  heading: css({
    color: palette.neutrals.gray500,
    fontSize: '0.625rem',
    letterSpacing: '0.2em',
    marginBottom: '0.625rem',
    marginTop: '1.125rem',
    textTransform: 'uppercase',
  }),
  input: css({
    marginBottom: '0.5rem',
    width: '100%',
  }),
};

function defaultValues(initialTemplate?: SurveyTemplate): FormValues | Record<string, never> {
  if (!initialTemplate) return {};

  const {
    title,
    objective,
    questions,
  } = initialTemplate;

  return {
    title,
    objective,
    questions: questions.map((question, index) => ({
      ...question,
      rank: question.rank ?? index,
      type: question.type.key,
    })),
  };
}

interface ViewProps {
  closeDrawerClick: () => void,
  formContext: UseFormReturn<FormValues>,
  isEditing: boolean,
  isMutationLoading: boolean,
  onAddQuestion: () => void,
  onEditQuestion: (question: SurveyQuestion) => void,
  onSave: SubmitHandler<FormValues>,
}

export const View = ({
  closeDrawerClick,
  formContext,
  isEditing,
  isMutationLoading,
  onAddQuestion,
  onEditQuestion,
  onSave,
}: ViewProps): JSX.Element => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title={isEditing ? 'Edit Survey Template' : 'Create Survey Template'}
        renderCloseButton={(closeButtonStyles) => (
          <IconButton
            css={closeButtonStyles}
            onClick={closeDrawerClick}
            type="button"
            icon={faTimes}
            tooltip="Close"
            size="large"
          />
        )}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody}>
        <Form
          formContext={formContext}
          onSubmit={() => null}
        >
          <TextField
            css={styles.input}
            name="title"
            required
          />
          <Froala
            enableEmojis
            label="Objective"
            name="objective"
            richTextEditorProps={{
              name: 'objective',
              initialValue: formContext.getValues().objective,
              onChange: ({ value: newValue }) => formContext.setValue('objective', newValue),
            }}
          />
          <p css={styles.heading}>
            Questions
          </p>
          <Questions
            questions={formContext.watch('questions')}
            onEditQuestion={onEditQuestion}
            setSurveyQuestions={(newQuestions: unknown[]) => formContext.setValue('questions', newQuestions)}
            isDragDisabled
          />
          <LeadrButton
            variant="ghost"
            size="small"
            css={styles.addQuestionButton}
            onClick={onAddQuestion}
            data-test-id="surveysEditTemplateAddQuestion"
          >
            + Add Question
          </LeadrButton>
        </Form>
      </div>
    )}
    renderFooter={() => (
      <div css={styles.buttonContainer}>
        <SpinnerButton
          css={styles.button}
          color="secondary"
          isLoading={isMutationLoading}
          disabled={!formContext.watch('questions')?.length}
          label="Save Changes"
          loadingLabel="Saving..."
          onClick={formContext.handleSubmit(onSave)}
        />
        <LeadrButton
          variant="ghost"
          css={styles.button}
          onClick={closeDrawerClick}
          data-test-id="surveysEditTemplateCancel"
        >
          Cancel
        </LeadrButton>
      </div>
    )}
  />
);

interface EditSurveyTemplateDrawerProps {
  initialTemplate?: SurveyTemplate,
  isEditing?: boolean,
}

export const EditSurveyTemplateDrawer = ({
  initialTemplate,
  isEditing = false,
}: EditSurveyTemplateDrawerProps): JSX.Element => {
  const formContext = useForm<FormValues>({
    defaultValues: defaultValues(initialTemplate),
    resolver: editSurveyTemplateFormResolver,
  });

  const dispatch = useDispatch();
  const [surveyDrawerState, setSurveyDrawerState] = useState<{ surveyQuestions: SurveyQuestion[]; }>({
    surveyQuestions: formContext.getValues().questions as SurveyQuestion[] ?? [],
  });

  const { mutateAsync: updateTemplateMutation, isPending: isUpdateMutationLoading } = useUpdateSurveyTemplate();
  const { mutateAsync: createTemplateMutation, isPending: isCreateMutationLoading } = useCreateSurveyTemplate();

  const isMutationLoading = isUpdateMutationLoading || isCreateMutationLoading;

  const closeDrawerClick = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...surveyTemplatesTemplate,
      },
    }));
  };

  const onSave: SubmitHandler<FormValues> = async (data) => {
    if (initialTemplate) {
      await updateTemplateMutation({ ...initialTemplate, ...data }, { onError: onErrorEditSurveyTemplate, onSuccess: onSuccessEditSurveyTemplate });
    } else {
      await createTemplateMutation(data as CreateSurveyDTO, { onError: onErrorCreateSurveyTemplate, onSuccess: onSuccessCreateSurveyTemplate });
    }
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popDrawerAction({ popAll: true }));
    dispatch(pushDrawerAction({
      drawer: {
        ...surveyTemplatesTemplate,
      },
    }));
  };
  const toastId = useRef<ReactText | number | null>(null);

  const onErrorEditSurveyTemplate = (): void => {
    toastId.current = toast.error('There was an error updating the template. Please try again....', { autoClose: 5000 });
  };
  const onSuccessEditSurveyTemplate = (): void => {
    toastId.current = toast.success('Successfully updated the template...', { autoClose: 5000 });
  };
  const onErrorCreateSurveyTemplate = (): void => {
    toastId.current = toast.error('There was an error creating your template. Please try again....', { autoClose: 5000 });
  };
  const onSuccessCreateSurveyTemplate = (): void => {
    toastId.current = toast.success('Successfully created your template...', { autoClose: 5000 });
  };

  const onAddQuestion = (): void => {
    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popAfterAction({ drawerName: editSurveyTemplatesTemplate.name }));
    dispatch(pushDrawerAction({
      drawer: {
        ...newQuestionTemplate,
        args: {
          drawerName: newQuestionTemplate.name,
          drawerTitle: 'Add Question to Survey Template',
          questionKey: 'surveyQuestions',
          isEditing: false,
          drawerState: {
            surveyQuestions: formContext.getValues().questions ?? [],
          },
          setDrawerState: setSurveyDrawerState,
        },
      },
    }));
  };

  const onEditQuestion = (question: SurveyQuestion): void => {
    const type = typeof question.type === 'number' ? question.type : question.type.key;
    const parsedQuestion = {
      ...question,
      configuration: typeof question.configuration === 'string' && type === SURVEY_QUESTION_TYPES.MULTIPLE_CHOICE.key
        ? JSON.parse(question.configuration) as Record<string, unknown>
        : question.configuration,
    };
    let formQuestions: SurveyQuestion[] = formContext.getValues().questions as SurveyQuestion[];
    if (question.rank) {
      formQuestions = [
        ...formQuestions.slice(0, question.rank),
        parsedQuestion,
        ...formQuestions.slice(question.rank + 1),
      ];
    }

    // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
    dispatch(popAfterAction({ drawerName: editSurveyTemplatesTemplate.name }));
    dispatch(pushDrawerAction({
      drawer: {
        ...newQuestionTemplate,
        args: {
          drawerTitle: 'Edit Question',
          initialQuestion: parsedQuestion,
          questionKey: 'surveyQuestions',
          isEditing: true,
          drawerState: {
            surveyQuestions: formQuestions,
          },
          setDrawerState: setSurveyDrawerState,
        },
      },
    }));
  };

  // The question drawers from Surveys modify drawerState, so let's react to that.
  useEffect(() => {
    const { surveyQuestions: drawerStateQuestions } = surveyDrawerState;

    if (drawerStateQuestions) {
      const existingQuestions = formContext.getValues().questions as SurveyQuestion[];
      formContext.setValue('questions', drawerStateQuestions.map((question, index) => (
        {
          ...existingQuestions?.[index],
          ...question,
        }
      )), {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDrawerState]);

  const hookProps = {
    closeDrawerClick,
    formContext,
    isEditing,
    isMutationLoading,
    onAddQuestion,
    onEditQuestion,
    onSave,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: editSurveyTemplatesTemplate.name,
  component: EditSurveyTemplateDrawer,
});
