import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useMemo } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { useGetWorkingGeniusMap } from '~Meetings/hooks/useGetWorkingGeniusMap';
import { useUrlQuery } from '~Common/hooks/useUrlQuery';
import { MeetingTypeEnum, WorkingGeniusWithType } from '~Meetings/const/meetingsInterfaces';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import useMeetingDetails from '~Meetings/hooks/useMeetingDetails';
import WorkingGeniusSection from './WorkingGeniusSection';

const styles = {
  cardSkeleton: css({
    minWidth: '100%',
    height: '8.75rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
  drawerBody: css({
    padding: '1rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.125rem',
  }),
};

export const teamMapDrawerTemplate = {
  name: 'TEAM_MAP_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

export interface LearningLibraryRenderBackButtonParams {
  backButtonStyles: SerializedStyles,
  closeLearningLibrary: () => void,
}

const TeamMapDrawer = ({
  popDrawer,
}: DrawerProps<unknown>): JSX.Element => {
  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: teamMapDrawerTemplate.name });
  };

  const location = useUrlQuery();
  const { meetingId } = location ?? {};

  // @ts-expect-error id is there
  const { item, isLoading: areMeetingDetailsLoading } = useMeetingDetails({ id: meetingId, type: MeetingTypeEnum.MEETING }) ?? {};

  const meetingOrgUserIdList = useMemo(() => {
    if (item) {
      return [...item.attendeeOrgUserIds, item.organizer.orgUserId];
    }

    return [];
  }, [item]);

  const { data, isLoading } = useGetWorkingGeniusMap({ meetingOrgUserIdList });

  const [areWorkingGeniusSectionsLoading] = useSkeletonLoaders(isLoading || areMeetingDetailsLoading);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const workingGeniusSections = useMemo(() => Object.entries(data?.response ?? {}).map(([key, value]) => ({
    type: key,
    ...value,
  })), [data]) as WorkingGeniusWithType[];

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title="Team Map"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
        renderDrawerInstructions={(instructionsStyles) => (
          <p css={instructionsStyles}>Here’s a breakdown of this team’s Working Genius</p>
        )}
      />
    ),
    renderBody: () => (
      <div css={styles.drawerBody}>
        {areWorkingGeniusSectionsLoading && (
          <MultipleSkeletonLoaders
            numberOfSkeletons={6}
            renderSkeletonItem={() => (
              <SkeletonLoader
                css={styles.cardSkeleton}
                variant="rectangular"
                renderComponent={() => <></>}
              />
            )}
          />
        )}
        {!areWorkingGeniusSectionsLoading && (
          <>
            {workingGeniusSections.map((workingGeniusSection) => (
              <WorkingGeniusSection key={workingGeniusSection.type} workingGeniusSection={workingGeniusSection} />
            ))}
          </>
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: teamMapDrawerTemplate.name,
  component: TeamMapDrawer,
});

export default TeamMapDrawer;
