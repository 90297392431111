import { css } from '@emotion/react';
import MUIMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { palette } from '~Common/styles/colors';
import { useContext } from 'react';
import {
  faEdit,
  faPlus,
  faSquareDashed,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useShowItemEditor } from '~Meetings/components/topic-suggestions/stores/useShowItemEditor';
import { TemplateItemActionsContext } from '~Meetings/components/topic-suggestions/contexts/TemplateItemsActionContext';
import { useShowAgendaSection } from '../../../stores/useShowAgendaSection';

const styles = {
  menu: css({
    '.MuiList-root': {
      padding: 0,
    },
  }),
  menuItem: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: palette.neutrals.gray900,
    fontSize: '.875rem',
    fontWeight: 500,
  }),
  menuIcon: css({
    color: palette.brand.indigo,
    width: '.875rem',
    height: '.875rem',
    marginLeft: '.75rem',
  }),
  hr: css({
    margin: '0 1rem',
  }),
  deleteMenuItem: css({
    color: palette.brand.red,
  }),
  deleteMenuIcon: css({
    color: palette.brand.red,
  }),
};

enum AgendaSectionRemoveOption {
  Delete = 'Delete',
  Ungroup = 'Ungroup',
}

interface ViewProps {
  anchorEl: HTMLElement | null,
  isMenuOpen: boolean,
  handleCloseMenuClick: () => void,
  handleAddTopicClick: () => void,
  handleUpdateClick: () => void,
  handleDeleteClick: () => void,
  handleUngroupClick: () => void,
  agendaSectionRemoveOption: AgendaSectionRemoveOption,
  showAddTopicButton: boolean,
}

const View = ({
  anchorEl,
  agendaSectionRemoveOption,
  isMenuOpen,
  handleCloseMenuClick,
  handleUpdateClick,
  handleAddTopicClick,
  handleDeleteClick,
  handleUngroupClick,
  showAddTopicButton,
  ...props
}: ViewProps): JSX.Element => (
  <MUIMenu
    anchorEl={anchorEl}
    open={isMenuOpen}
    onClose={handleCloseMenuClick}
    onClick={handleCloseMenuClick}
    css={styles.menu}
    {...props}
  >
    <MenuItem
      css={styles.menuItem}
      onClick={handleUpdateClick}
    >
      <span>Edit Section Title</span>
      <FontAwesomeIcon css={styles.menuIcon} icon={faEdit} />
    </MenuItem>
    {showAddTopicButton && (
      <MenuItem
        css={styles.menuItem}
        onClick={handleAddTopicClick}
      >
        <span>Add Topic to Section</span>
        <FontAwesomeIcon css={styles.menuIcon} icon={faPlus} />
      </MenuItem>
    )}
    <Divider css={styles.hr} />
    {agendaSectionRemoveOption === AgendaSectionRemoveOption.Ungroup && (
      <MenuItem
        css={[styles.menuItem, styles.deleteMenuItem]}
        onClick={handleUngroupClick}
      >
        <span>Ungroup Section</span>
        <FontAwesomeIcon css={[styles.menuIcon, styles.deleteMenuIcon]} icon={faSquareDashed} />
      </MenuItem>
    )}
    {agendaSectionRemoveOption === AgendaSectionRemoveOption.Delete && (
      <MenuItem
        css={[styles.menuItem, styles.deleteMenuItem]}
        onClick={handleDeleteClick}
      >
        <span>Delete Section</span>
        <FontAwesomeIcon css={[styles.menuIcon, styles.deleteMenuIcon]} icon={faTrash} />
      </MenuItem>
    )}
  </MUIMenu>
);

interface MenuProps {
  anchorEl: HTMLElement | null,
  isMenuOpen: boolean,
  handleCloseMenuClick: () => void,
  numberOfAgendaItems: number,
  sectionLocalId: string,
  newTopicLocalId: string,
  hasMaxTopics: boolean,
}

const Menu = ({
  numberOfAgendaItems,
  sectionLocalId,
  newTopicLocalId,
  hasMaxTopics,
  ...props
}: MenuProps): JSX.Element => {
  const agendaSectionRemoveOption = numberOfAgendaItems === 0 ? AgendaSectionRemoveOption.Delete : AgendaSectionRemoveOption.Ungroup;
  const expandAgendaSection = useShowAgendaSection((state) => state.expandAgendaSection);
  const showItemEditor = useShowItemEditor((state) => state.showItemEditor);
  const { deleteTemplateItem, ungroupSection } = useContext(TemplateItemActionsContext);

  const handleAddTopicClick = (): void => {
    expandAgendaSection(sectionLocalId);
    showItemEditor(newTopicLocalId);
  };

  const handleUpdateClick = (): void => {
    showItemEditor(sectionLocalId);
  };

  const handleDeleteClick = (): void => {
    deleteTemplateItem(sectionLocalId);
  };

  const handleUngroupClick = (): void => {
    ungroupSection(sectionLocalId);
  };

  const showAddTopicButton = !hasMaxTopics;

  const hookProps = {
    agendaSectionRemoveOption,
    handleAddTopicClick,
    handleUpdateClick,
    handleDeleteClick,
    handleUngroupClick,
    showAddTopicButton,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default Menu;
