import { useQuery } from '@tanstack/react-query';
import { getPersonalityDetailsById } from '~People/hooks/baseballCard/api';

export const useBaseballCardPersonalityTypeDetails = (id) => {
  const detail = useQuery({
    queryKey: ['personalityDetails', id],
    queryFn: getPersonalityDetailsById,
    enabled: !!id,
  });

  return {
    detail,
    isLoading: detail?.isLoading,
    personalityDetails: detail?.data?.response[0],
  };
};
