import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const styles = {
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    justify-items:flex-start;
    list-style: none;
    width: 100%;
  `,
};
/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */

function DrawerRow({ children, className }) {
  return (
    <div
      css={styles.wrapper}
      className={`drawerRow ${className}`}
    >
      {children}
    </div>
  );
}

DrawerRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
};

DrawerRow.defaultProps = {
  className: '',
  children: (<div> Row </div>),
};

export default DrawerRow;
