import { ACCOUNT_SETTINGS } from '~Common/const/routes';
import LinkButton from '~Common/V3/components/LinkButton';
import { css } from '@emotion/react';

const styles = {
  // Styles to make the link button line up with the normal button element, we might have to do this somewhere more common eventually
  linkButton: css({
    padding: '.0625rem .375rem',
    fontWeight: 400,
  }),
};

interface ViewProps {
  buttonText: string,
}

const ConnectYourCalendarButton = ({
  buttonText,
  ...props
}: ViewProps): JSX.Element => (
  <>
    <LinkButton
      css={styles.linkButton}
      to={{
        pathname: ACCOUNT_SETTINGS,
        state: { navigatedFromConnectCalendarButton: true },
      }}
      variant="text"
      renderContents={() => (
        <>{buttonText}</>
      )}
      {...props}
    />
  </>
);

export default ConnectYourCalendarButton;
