import { FormEvent, useCallback, useMemo } from 'react';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import { useGetTeams } from '~People/components/Teams/hooks/useGetTeams';
import { TeamsListScope } from '~People/components/Teams/const/types';
import { useEditMeetingLinkedTeam } from '~Meetings/hooks/useEditMeetingLinkedTeam';
import { LinkTeamDrawerView, LinkTeamFormData } from './LinkTeamDrawer';

export const editLinkTeamDrawerTemplate = {
  name: 'EDIT_LINK_TEAM_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

interface EditTeamLinkDrawerProps extends DrawerProps<Record<string, unknown>> {
  initialTeamId: string,
  factoryId: string,
  meetingId: string,
}

const EditLinkTeamDrawer = ({
  initialTeamId,
  factoryId,
  meetingId,
  popDrawer,
}: EditTeamLinkDrawerProps): JSX.Element => {
  const closeDrawerClick = (): void => {
    popDrawer({ popAll: true });
  };

  const { mutate: editMeetingLinkedTeamMutation } = useEditMeetingLinkedTeam({
    meetingId,
    onSuccessCallback: () => {
      closeDrawerClick();
    },
  });

  const { data } = useGetTeams({
    listScope: TeamsListScope.MyTeams,
    page: 0,
    count: 200,
  });

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const linkTeamFormData = Object.fromEntries(formData.entries()) as unknown as LinkTeamFormData;
    const { teamId: newTeamId } = linkTeamFormData;
    // Call Edit team mutation
    editMeetingLinkedTeamMutation({
      teamId: newTeamId,
      factoryId,
    });
  }, [editMeetingLinkedTeamMutation, factoryId]);

  const items = useMemo(() => (
    data?.response.teams.map((team) => ({
      value: team.teamId,
      text: team.name,
    }))
  ), [
    data?.response,
  ]);

  const hookProps = {
    items: items || [],
    closeDrawerClick,
    onSubmit,
    initialTeamId,
  };

  return (
    <LinkTeamDrawerView
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: editLinkTeamDrawerTemplate.name,
  component: EditLinkTeamDrawer,
});

export default EditLinkTeamDrawer;
