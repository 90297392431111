import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { DRAWER_STATE_SHAPE, SET_DRAWER_STATE_SHAPE } from '~Common/const/proptypes';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import usePeoplePicker from '~Common/hooks/usePeoplePicker';
import PeopleSearch from '~Common/components/search-boxes/PeopleSearch';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import { View } from '~Meetings/components/drawers/ParticipantDrawer';
import { drawerCollapseIconColor } from '~Common/styles/colors';

export const participantViewerTemplate = {
  name: 'PARTICIPANT_VIEWER',
  width: DRAWER_WIDTHS.BASE,
};

const styles = {
  collapseButton: css`
    font-size: 1rem;
    color: ${drawerCollapseIconColor};
  `,
};

const ParticipantViewer = ({
  useOrgIds,
  allowSelf,
  limit,
  drawerState,
  setDrawerState,
  selectedKey,
  defaultFilter,
  disableFilter,
  customFilter,
  filterOptions,
  onSelect,
  currentAttendees,
  title,
  ...props
}) => {
  const {
    onSearch,
    isLoading,
    attendees,
    setSearchFilter,
    searchFilter,
  } = usePeoplePicker({ useOrgIds, allowSelf, customFilter });

  const dispatch = useDispatch();

  useEffect(() => {
    if (filterOptions) {
      setSearchFilter(filterOptions[0]);
    }
  }, [setSearchFilter, filterOptions]);

  if (defaultFilter && defaultFilter !== searchFilter) {
    setSearchFilter(defaultFilter);
  }

  const renderSearchBox = () => (
    <PeopleSearch
      onSearch={onSearch}
      setSearchFilter={setSearchFilter}
      currentFilter={searchFilter}
      disableFilter
      onFilterChange={setSearchFilter}
    />
  );

  const closeDrawerClick = () => {
    dispatch(popDrawer({ drawerName: participantViewerTemplate.name }));
  };

  const closeButton = () => (
    <IconButton
      onClick={closeDrawerClick}
      tooltip="Close"
      type="button"
      icon={faTimes}
      css={styles.collapseButton}
      size="large"
    />
  );

  const hookProps = {
    isLoading,
    attendees,
    onAttendeeSelect: onSelect,
    renderSearchBox,
    setSearchFilter,
    searchFilter,
    customFilter,
    disableFilter,
    disableUsers: [],
    onSearch,
    closeButton,
    title,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};
ParticipantViewer.propTypes = {
  useOrgIds: PropTypes.bool,
  allowSelf: PropTypes.bool,
  limit: PropTypes.number,
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: SET_DRAWER_STATE_SHAPE.isRequired,
  popDrawer: PropTypes.func.isRequired,
  selectedKey: PropTypes.string,
  disableFilter: PropTypes.bool,
  onSelect: PropTypes.func,
  defaultFilter: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  customFilter: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  filterOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  currentAttendees: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

ParticipantViewer.defaultProps = {
  useOrgIds: true,
  allowSelf: true,
  limit: null,
  selectedKey: 'selectedAttendees',
  disableFilter: false,
  defaultFilter: null,
  customFilter: [],
  filterOptions: [],
  onSelect: null,
  currentAttendees: [],
  title: 'Meeting Participants',
};

registerDrawer({
  templateName: participantViewerTemplate.name,
  component: ParticipantViewer,
});

export { View };
export default ParticipantViewer;
