import { css } from '@emotion/react';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import EmptyStateWithImage from '~Common/components/EmptyStates/EmptyStateWithImage';
import { palette } from '~Common/styles/colors';
import emptyConversationBubbles from '~Meetings/assets/images/emptyConversationBubbles.png';

const styles = {
  emptyState: css({
    '& h6': {
      width: '100%',
      fontSize: '.875rem',
      color: palette.brand.indigo,
      textTransform: 'unset',
      fontWeight: 500,
      lineHeight: '1.125rem',
    },
  }),
  emptyStateImage: css({
    height: '6.5rem',
    marginTop: '1rem',
  }),
  emptyStateText: css({
    color: palette.neutrals.gray800,
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
    paddingTop: '.5rem',
    textTransform: 'none',
  }),
  createAgendaItemLinkButton: css({
    color: palette.brand.indigo,
    fontWeight: 600,
    padding: 0,
  }),
};

interface EmptyStateProps {
  onClick: () => void;
}

const EmptyState = ({ onClick, ...props }: EmptyStateProps): JSX.Element => (
  <EmptyStateWithImage
    css={styles.emptyState}
    renderImage={() => (
      <img
        css={styles.emptyStateImage}
        src={emptyConversationBubbles}
        alt="Empty Agenda"
      />
    )}
    renderText={() => (
      <p css={styles.emptyStateText}>
        Press &ldquo;
        <LeadrButton
          data-test-id="meetingsPageEmptyStateAddAgendaTopic"
          css={styles.createAgendaItemLinkButton}
          variant="text"
          onClick={onClick}
        >
          + Add Topic
        </LeadrButton>
        &rdquo; to create the first talking point.
      </p>
    )}
    {...props}
  />
);

export default EmptyState;
