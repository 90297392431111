import {
  ComponentProps,
  useCallback,
  useMemo,
} from 'react';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { forDesktopObject, withoutDesktopObject } from '~Common/styles/mixins';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import CreateEditTeamModal, { EditTeamInitialData } from '~People/components/Teams/components/CreateEditTeamModal';
import { useShowCreateEditTeamModal } from '~People/components/Teams/stores/useShowCreateEditTeamModal';
import { useGetTeamDetails } from '~People/components/Teams/hooks/useGetTeamDetails';
import { useGetTeamClarityQuestions } from '~People/components/Teams/hooks/utils/useGetTeamClarityQuestions';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';

const styles = {
  container: css({
    padding: '1rem 1.75rem',

    '&:hover': {
      '.editThematicGoalButton': {
        visibility: 'visible',
      },
    },
  }, withoutDesktopObject({
    boxShadow: 'none',
    padding: '1rem',
  }), forDesktopObject({
    backgroundColor: palette.neutrals.white,
    boxShadow: '0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.07)',
    borderRadius: '0.5rem',
  })),

  header: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.75rem',
  }),

  title: css({
    flex: 1,
    fontSize: '1.125rem',
    fontWeight: 600,
    color: palette.neutrals.gray800,
  }),

  editButton: css({
    visibility: 'hidden',
  }),

  divider: css({
    margin: '0 0 0.75rem 0',
  }),

  emptyState: css({
    fontStyle: 'italic',
    color: palette.neutrals.gray700,
  }),

  emptyStateButton: css({
    padding: 0,
    fontStyle: 'italic',
  }),

  skeleton: css({
    transform: 'none',
    maxWidth: '100%',
  }),
};

export interface ViewProps extends ComponentProps<'div'> {
  showModal: boolean,
  onEdit: () => void,
  editTeamData?: EditTeamInitialData,
  isLoading: boolean,
  thematicGoalHTML?: HTMLString,
  userCanEdit?: boolean,
}

export const View = ({
  showModal,
  onEdit,
  editTeamData,
  thematicGoalHTML,
  isLoading,
  userCanEdit,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.container}
    {...props}
  >
    <header css={styles.header}>
      <div css={styles.title} data-test-id="meetingDetailsThematicGoal">
        Thematic Goal
      </div>

      {userCanEdit && (
        <LeadrButton
          css={styles.editButton}
          className="editThematicGoalButton"
          onClick={onEdit}
          variant="text"
          disabled={isLoading}
          data-test-id="editThematicGoalButton"
        >
          Edit
        </LeadrButton>
      )}
    </header>

    <hr css={styles.divider} />

    {isLoading && (
      <SkeletonLoader
        css={styles.skeleton}
        height="1.5rem"
        renderComponent={() => <></>}
      />
    )}

    {!isLoading && (
      <div>
        {thematicGoalHTML && (
          <HTMLRenderer htmlText={thematicGoalHTML} />
        )}

        {!thematicGoalHTML && (
          <div>
            {userCanEdit && (
              <span>
                <LeadrButton
                  css={styles.emptyStateButton}
                  className="editThematicGoalButton"
                  variant="text"
                  onClick={onEdit}
                  data-test-id="thematicGoalEmptyStateButton"
                >
                  Add a Thematic Goal
                </LeadrButton>

                <span css={styles.emptyState}>
                  &nbsp;for this team
                </span>
              </span>
            )}

            {!userCanEdit && (
              <span css={styles.emptyState}>
                Thematic Goal not created
              </span>
            )}
          </div>
        )}
      </div>
    )}

    {showModal && (
      <CreateEditTeamModal
        initialData={editTeamData}
        isEditMode
        thematicGoalOnlyMode
      />
    )}
  </div>
);

export interface ThematicGoalProps extends ComponentProps<'div'> {
  teamId: string,
  huddleId: string,
}

const ThematicGoal = ({
  teamId,
  huddleId,
  ...props
}: ThematicGoalProps): JSX.Element => {
  const orgUserId = getOrganizationUserId();
  const { isAdmin } = useUserPermissions();
  const { data: teamDetailsData, isLoading: isTeamDetailsLoading } = useGetTeamDetails({ teamId, huddleId });
  const { teamClarityQuestionListEdit, isLoading: isTeamClarityQuestionsLoading } = useGetTeamClarityQuestions({ teamId, huddleId });

  const userCanEdit = useMemo(() => (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    isAdmin || teamDetailsData?.response.ownerOrgUserIds.includes(orgUserId!)
  ), [
    isAdmin,
    orgUserId,
    teamDetailsData?.response.ownerOrgUserIds,
  ]);

  const [isLoading] = useSkeletonLoaders(isTeamDetailsLoading && isTeamClarityQuestionsLoading);

  const thematicGoalHTML = useMemo(() => (
    teamDetailsData?.response.teamClarityAnswerList.find((teamClarityAnswer) => (
      teamClarityAnswer.rank === teamDetailsData.response.thematicGoalQuestionRank
    ))?.answer
  ), [
    teamDetailsData?.response,
  ]);

  const editTeamData = useMemo(() => {
    if (teamDetailsData?.response) {
      return {
        ...teamDetailsData.response,
        teamId,
        teamClarityQuestionList: teamClarityQuestionListEdit,
      };
    }

    return undefined;
  }, [
    teamDetailsData,
    teamId,
    teamClarityQuestionListEdit,
  ]);

  const {
    showModal,
    setShowModal,
  } = useShowCreateEditTeamModal((state) => ({
    showModal: state.showModal,
    setShowModal: state.setShowModal,
  }));

  const onEdit = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const hookProps = {
    showModal,
    onEdit,
    editTeamData,
    thematicGoalHTML,
    isLoading,
    userCanEdit,
  };

  return (
    <View
      {...props}
      {...hookProps}
    />
  );
};

export default ThematicGoal;
