import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { RenderConfirmationButtonsParams } from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';

interface RecallConfirmationButtonsProps extends RenderConfirmationButtonsParams {
  onRecall: () => void,
}

const RecallConfirmationButtons = ({
  informationStyles,
  optionStyles,
  popoverButtonStyles,
  onRecall,
}: RecallConfirmationButtonsProps): JSX.Element => (
  <>
    <LeadrButton
      variant="text"
      css={[popoverButtonStyles, informationStyles]}
      disabled
      data-test-id="learningRecallConfirmText"
    >
      Are You Sure?
    </LeadrButton>
    <LeadrButton
      variant="text"
      css={[popoverButtonStyles, optionStyles]}
      data-test-id="learningRecallConfirm"
      onClick={onRecall}
    >
      Yes, Recall
    </LeadrButton>
    <LeadrButton
      variant="text"
      css={[popoverButtonStyles, optionStyles]}
      data-test-id="learningRecallCancel"
    >
      No, Cancel
    </LeadrButton>
  </>
);

export default RecallConfirmationButtons;
