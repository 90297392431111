import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { registerDrawer, templateType } from '~Deprecated/ui/views/DrawerManager';
import { loadPersonalityDetailsById } from '~Deprecated/actions/personalities/loadPersonalityDetails';
import EditPersonalityTypeDetailsDrawer from '~Deprecated/ui/containers/Nexus/PersonalityTypes/EditPersonalityTypeDetailsDrawer';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { CircularProgress } from '@mui/material';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';

const styles = {
  circularProgress: css({
    color: palette.brand.indigo,
  }),
};

const PersonalityTypeDetailsComponent = ({ id }) => {
  const dispatch = useDispatch();
  const hasPendingCall = useSelector((state) => state.personalityDetails.hasPendingCall);

  useEffect(() => {
    if (id) {
      dispatch(loadPersonalityDetailsById(id));
    }
  }, [id, dispatch]);

  return (
    <div>
      { hasPendingCall ? (
        <div className="center">
          <CircularProgress css={styles.circularProgress} />
        </div>
      ) : <EditPersonalityTypeDetailsDrawer /> }
    </div>
  );
};

PersonalityTypeDetailsComponent.propTypes = {
  id: PropTypes.string,
};

PersonalityTypeDetailsComponent.defaultProps = {
  id: '',
};

export const editPersonalityTypeDetailsTemplate = {
  name: 'EDIT_PERSONALITY_TYPE_DETAILS',
  type: templateType.PRIMARY,
  width: DRAWER_WIDTHS.PRIMARY,
};

registerDrawer({
  templateName: editPersonalityTypeDetailsTemplate.name,
  component: PersonalityTypeDetailsComponent,
});

export default PersonalityTypeDetailsComponent;
