/// <reference types="@emotion/react/types/css-prop" />
import { css } from '@emotion/react';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { palette } from '~Common/styles/colors';
import Card from '~Common/V3/components/Card';
import { CARD_STYLES, TINY_CARD_COMPONENTS } from '~Insights/const/cardStyles';
import { EngagementCategory } from '~Insights/const/types';
import { EngagementDataForDate, EngagementScores, useEngagementDataForDateRange } from '~Insights/hooks/useEngagementData';
import { useInsightsUserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import Tooltip from '~Common/components/Tooltip';
import insightsEmptyGraph from '~Insights/images/insights-empty-graph.png';
import { defualtMonthsToSubtract } from '~Insights/const/defaults';
import InsightCardTitle from '../InsightCardTitle';

const colorOptions = Object.values(palette.brand);

const styles = {
  tiny: css({
    ...CARD_STYLES.tiny,
  }),
  dashboard: css({
    ...CARD_STYLES.dashboard,
  }),
  barContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.75rem',
    padding: '1.25rem 0',
  }),
  barRow: css({
    alignItems: 'center',
    columnGap: '0.625rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }),
  barLabel: css({
    flexShrink: '0',
    fontSize: '0.725rem',
    whiteSpace: 'break-spaces',
  }),
  bar: (mean: number, index: number) => css({
    backgroundColor: colorOptions[index],
    height: '1.5rem',
    minWidth: '0.45rem',
    width: `${Math.round(mean) * 1.15}rem`,
  }),
  footer: css({
    alignItems: 'center',
    color: palette.neutrals.gray700,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '0.6875rem',
    fontWeight: 400,
    justifyContent: 'space-between',
  }),
  footerIcon: css({
    color: palette.neutrals.gray700,
  }),
  ...TINY_CARD_COMPONENTS,
};

interface SharedProps {
  data: EngagementDataForDate[],
  // isLoading: boolean,
  toolTip: JSX.Element,
  className?: string,
}

const DashboardView = ({
  data,
  toolTip,
  className,
}: SharedProps): JSX.Element => (
  <Card
    className={className}
    css={styles.dashboard}
    renderContents={() => (
      <>
        <InsightCardTitle>Engagement</InsightCardTitle>
        <div css={styles.barContainer}>
          {data.length > 0 && Object.entries(data[data.length - 1]?.averages).map(([cat, value], index) => (
            <div key={cat} css={styles.barRow}>
              <div css={styles.bar(value as number, index)} />
              <div css={styles.barLabel}>
                {EngagementCategory[cat as keyof EngagementScores]}
                {' - '}
                {value ? `${Math.round(value as number)}/5` : 'N/A'}
              </div>
            </div>
          ))}
          {data.length <= 0 && (
            <div css={styles.emptyStateSmall}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <img src={insightsEmptyGraph} alt="Empty graph" />
              <p css={styles.emptyStateText}>
                Data will show here as your team answers the engagement survey.
              </p>
            </div>
          )}
        </div>
        <div css={styles.footer}>
          <p>Average based on most recent engagement survey</p>
          <Tooltip content={toolTip}>
            <div><FontAwesomeIcon css={styles.footerIcon} icon={faCircleInfo} /></div>
          </Tooltip>
        </div>
      </>
    )}
  />
);

interface EngagementBreakdownCardProps {
  size: 'tiny' | 'dashboard'
}

const EngagementBreakdownCard = ({
  ...props
}: EngagementBreakdownCardProps): JSX.Element => {
  const { userPerspective } = useInsightsUserPerspective();
  const { data, isLoading } = useEngagementDataForDateRange({
    startDate: moment().subtract(defualtMonthsToSubtract, 'months').startOf('day').toDate(),
    endDate: moment().startOf('day').toDate(),
    userPerspective,
  });

  const toolTip = (
    <>
      <p>Leadr Insights validates engagement across these three characteristics:</p>
      <ul>
        <li>Clarity - Knowing what&apos;s necessary to win in your role.</li>
        <li>Maximization - Having the resources to perform at your best.</li>
        <li>Rapport - Being connected to your organization&apos;s mission and goals.</li>
      </ul>
      <p>Engagement scores shown are the average of the scores from all three categories.</p>
    </>
  );

  const hookProps = {
    data,
    isLoading,
    toolTip,
  };

  // if (size === 'tiny') {
  //   return (
  //     <TinyView
  //       {...hookProps}
  //       {...props}
  //     />
  //   );
  // }

  return (
    <DashboardView
      {...hookProps}
      {...props}
    />
  );
};

export { DashboardView };
export default EngagementBreakdownCard;
