import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface ContextButtonsProps {
    renderContents: () => JSX.Element,
    portalId?: string,
}

export const ContextButtons: FC<ContextButtonsProps> = ({
  renderContents,
  portalId = 'contextButtons',
}) => {
  const [portalElement, setPortalElement] = useState<HTMLElement>();
  useEffect(() => {
    const portalRef = document.getElementById(portalId);
    if (portalRef) {
      setPortalElement(portalRef);
    }
  }, [portalId]);

  return (
    <>
      {portalElement && (
        <>
          {createPortal(renderContents(), portalElement)}
        </>
      )}
    </>
  );
};
