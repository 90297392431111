import PropTypes from 'prop-types';
import { useState } from 'react';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { faArrowFromLeft, faFilter } from '@fortawesome/pro-light-svg-icons';
import emptyParticipants from '~Assets/images/empty-views/emptyParticipants.png';
import useSelectedAttendees from '~Deprecated/hooks/attendees/useSelectedAttendees';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import SuggestedAttendeeList from '~Meetings/components/attendees/SuggestedAttendeeList';
import SearchBox from '~Common/components/Inputs/SearchBox';
import { withoutMobile } from '~Common/styles/mixins';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { drawerCollapseIconColor, palette } from '~Common/styles/colors';
import { useCoachingSuggestionsSearch } from '~Meetings/hooks/useCoachingSuggestions';
import { DRAWER_WIDTHS } from '~Common/const/drawers';
import { COLLEAGUE_TYPES } from '~Meetings/const/colleagueTypes';
import { ATTENDEE_SUGGESTIONS_SEARCH_DATA, DRAWER_STATE_SHAPE, SET_DRAWER_STATE_SHAPE } from '~Common/const/proptypes';

const styles = {
  searchWrapper: css({
    position: 'sticky',
    top: 0,
    padding: '.5rem 1rem',
    margin: '-.5rem -1rem',
    background: palette.neutrals.white,
    marginBottom: '1rem',
    zIndex: 1,
  }),
  attendeeCollapse: css`
    margin-bottom: 0.5rem;
  `,
  closeButton: css`
    font-size: 1rem;
    color: ${drawerCollapseIconColor};
    ${withoutMobile(`
      display: none;
    `)}
  `,
  noResults: css`
    text-align: center;
    margin-top: 50px;
    color: ${palette.brand.indigo};
  `,
  emptyStateImage: css`
    width: 100%;
  `,
  drawerBody: css({
    padding: '1.25rem 1.5rem 1.5rem 1.5rem',
  }),
};

const coachingSuggestionsDrawerTemplate = {
  name: 'COACHING_SUGGESTIONS_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

const View = ({
  closeButton,
  isSelected,
  onSelect,
  onSearch,
  searchData,
  shouldOverride,
  isLoading,
  ...props
}) => (
  <DrawerLayout
    renderHeader={() => (
      <DrawerHeader
        title="Choose Person for 1:1"
        renderCloseButton={closeButton}
      />
    )}
    renderBody={() => (
      <div css={styles.drawerBody} {...props}>
        <div css={styles.searchWrapper}>
          <SearchBox
            placeholder="Search..."
            onChange={onSearch}
            icon={faFilter}
          />
        </div>

        {shouldOverride && !searchData.count && !isLoading && (
          <div
            css={styles.noResults}
          >
            <h4>Your search has no results.</h4>
            <img src={emptyParticipants} alt="You have no participants." css={styles.emptyStateImage} />
          </div>
        )}

        {(!shouldOverride || isLoading || (shouldOverride && !!searchData.count)) && (
          <>
            <SuggestedAttendeeList
              css={styles.attendeeCollapse}
              title="Your Direct Reports"
              colleagueType={COLLEAGUE_TYPES.DIRECT_REPORTS}
              isSelected={isSelected}
              onSelect={onSelect}
              overrideAttendees={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.DIRECT_REPORTS].list : null}
              overrideAttendeesData={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.DIRECT_REPORTS].listData : null}
            />

            <SuggestedAttendeeList
              css={styles.attendeeCollapse}
              title="Your Leaders"
              colleagueType={COLLEAGUE_TYPES.LEADERS}
              isSelected={isSelected}
              onSelect={onSelect}
              overrideAttendees={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.LEADERS].list : null}
              overrideAttendeesData={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.LEADERS].listData : null}
            />

            <SuggestedAttendeeList
              css={styles.attendeeCollapse}
              title="Your Peers"
              colleagueType={COLLEAGUE_TYPES.PEERS}
              isSelected={isSelected}
              onSelect={onSelect}
              overrideAttendees={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.PEERS].list : null}
              overrideAttendeesData={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.PEERS].listData : null}
            />

            <SuggestedAttendeeList
              css={styles.attendeeCollapse}
              title="Skip Levels"
              colleagueType={COLLEAGUE_TYPES.SKIP_LEVELS}
              isSelected={isSelected}
              onSelect={onSelect}
              overrideAttendees={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.SKIP_LEVELS].list : null}
              overrideAttendeesData={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.SKIP_LEVELS].listData : null}
            />

            <SuggestedAttendeeList
              css={styles.attendeeCollapse}
              title="Others"
              colleagueType={COLLEAGUE_TYPES.OTHERS}
              isSelected={isSelected}
              onSelect={onSelect}
              overrideAttendees={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.OTHERS].list : null}
              overrideAttendeesData={shouldOverride && !isLoading ? searchData[COLLEAGUE_TYPES.OTHERS].listData : null}
            />
          </>
        )}

      </div>
    )}
  />
);

View.propTypes = {
  isSelected: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  closeButton: PropTypes.func.isRequired,
  searchData: ATTENDEE_SUGGESTIONS_SEARCH_DATA.isRequired,
  shouldOverride: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

View.defaultProps = {
};

const CoachingSuggestionsDrawer = ({
  drawerState,
  setDrawerState,
  allowSelf,
  limit,
  popDrawer,
  ...props
}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { searchData, isSearching, isLoading } = useCoachingSuggestionsSearch({ searchText: search });
  const {
    isAttendeeSelected,
    onAttendeeSelect,
  } = useSelectedAttendees({
    drawerState,
    setDrawerState,
    limit,
    allowSelf,
  });

  const closeDrawerClick = () => {
    dispatch(popDrawer({ drawerName: coachingSuggestionsDrawerTemplate.name }));
  };

  const closeButton = (closeButtonStyles) => (
    <IconButton
      onClick={closeDrawerClick}
      type="button"
      icon={faArrowFromLeft}
      css={[closeButtonStyles, styles.closeButton]}
      size="large"
    />
  );

  const onSearch = (searchText) => {
    setSearch(searchText);
  };

  const hookProps = {
    isSelected: isAttendeeSelected,
    shouldOverride: isSearching,
    onSelect: onAttendeeSelect,
    onSearch,
    closeButton,
    searchData,
    isLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

CoachingSuggestionsDrawer.propTypes = {
  drawerState: DRAWER_STATE_SHAPE.isRequired,
  setDrawerState: SET_DRAWER_STATE_SHAPE.isRequired,
  allowSelf: PropTypes.bool,
  limit: PropTypes.number,
  popDrawer: PropTypes.func.isRequired,
};

CoachingSuggestionsDrawer.defaultProps = {
  allowSelf: false,
  limit: 1,
};

registerDrawer({
  templateName: coachingSuggestionsDrawerTemplate.name,
  component: CoachingSuggestionsDrawer,
});

export { View, coachingSuggestionsDrawerTemplate };
export default CoachingSuggestionsDrawer;
