import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { palette } from '~Common/styles/colors';
import LeadrSplitButton, { LeadrSplitButtonOption } from '~Common/V3/components/LeadrSplitButton';
import { getEmail } from '~Common/utils/localStorage';
import { useGetNylasClientId } from '~Meetings/hooks/CalendarIntegration/useGetNylasClientId';
import { toast } from '~Common/components/Toasts';
import { ACCOUNT_SETTINGS } from '~Common/const/routes';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';

const styles = {
  emailAddressInput: css({
    marginBottom: '1.5rem',
  }),
  subHeadingText: css({
    color: palette.neutrals.gray700,
    fontSize: '1rem',
    marginBottom: '1.5rem',
  }),
  connectCalendarButton: (navigatedFromConnectCalendarButton: boolean) => css(
    navigatedFromConnectCalendarButton && ({
      animation: 'pulse 1s 5',
    }),
  ),
};

enum Provider {
  GOOGLE = 'gmail',
  OFFICE365 = 'office365',
}

const providersList = [
  {
    label: 'Connect to Google',
    provider: Provider.GOOGLE,
    'data-test-id': 'googleCalendarProviderOption',
  },
  {
    label: 'Connect to Microsoft Office 365',
    provider: Provider.OFFICE365,
    'data-test-id': 'office365CalendarProviderOption',
  },
];

interface ViewProps {
  navigatedFromConnectCalendarButton: boolean,
  options: LeadrSplitButtonOption[],
  enableUnsupportedCalendarProviders: boolean,
  handleConnectToCalendar: (provider?: Provider) => void,
}

const View = ({
  navigatedFromConnectCalendarButton,
  options,
  enableUnsupportedCalendarProviders,
  handleConnectToCalendar,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {enableUnsupportedCalendarProviders && (
      <>
        <p css={styles.subHeadingText}>
          Connecting Leadr to your calendar will provide an enhanced meetings management experience.
        </p>
        <LeadrButton
          css={styles.connectCalendarButton(navigatedFromConnectCalendarButton)}
          size="small"
          data-test-id="settingsConnectUnsupportedCalendar"
          onClick={handleConnectToCalendar}
        >
          Connect Calendar
        </LeadrButton>
      </>
    )}
    {!enableUnsupportedCalendarProviders && (
      <>
        <p css={styles.subHeadingText}>
          Connecting Leadr to your Google or Microsoft Office 365 calendar will provide an enhanced meetings management experience.
        </p>
        <LeadrSplitButton
          css={styles.connectCalendarButton(navigatedFromConnectCalendarButton)}
          options={options}
          labelBeforeSelectedOption="Connect a Calendar"
        />
      </>
    )}
  </div>
);

const ConnectCalendar = ({ ...props }): JSX.Element => {
  const { state } = useLocation<{ navigatedFromConnectCalendarButton?: boolean }>();
  const navigatedFromConnectCalendarButton = state?.navigatedFromConnectCalendarButton || false;
  const userEmail = getEmail() || '';
  const nylasClientId = useGetNylasClientId();
  const { enableUnsupportedCalendarProviders } = useOrgDetailsContext();
  const removeEmailScope = useFeatureFlag('nylasRemoveEmailScope');

  const handleConnectToCalendar = (provider?: Provider): void => {
    if (userEmail?.length) {
      const isLocal = window.location.href.indexOf('localhost') >= 0;
      const returnHost = isLocal ? 'http://localhost:3001' : process.env.REACT_APP_LEADR_SELF_URL;
      if (nylasClientId && returnHost) {
        const queryParams = new URLSearchParams({
          client_id: nylasClientId,
          redirect_uri: `${returnHost}${ACCOUNT_SETTINGS}`,
          response_type: 'token',
          login_hint: encodeURI(userEmail),
          provider: provider || '', // This will only be empty in the Unsupported Calendar Providers case
          disable_provider_selection: 'true',
          scopes: removeEmailScope ? 'calendar,calendar.free_busy' : 'calendar,email.send,calendar.free_busy',
        });
        if (enableUnsupportedCalendarProviders) {
          queryParams.delete('disable_providers');
          queryParams.delete('provider');
        }
        window.location.href = `https://api.nylas.com/oauth/authorize?${queryParams.toString()}`;
      } else {
        toast.error('Something went wrong, please try again...');
      }
    } else {
      toast.error('Please enter a valid email...');
    }
  };

  const options = providersList.map((provider) => ({
    ...provider,
    onClick: () => handleConnectToCalendar(provider.provider),
  }));

  const hookProps = {
    handleConnectToCalendar,
    navigatedFromConnectCalendarButton,
    options,
    enableUnsupportedCalendarProviders,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ConnectCalendar;
