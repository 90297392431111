import { css } from '@emotion/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NexusOrganizations from '~Deprecated/ui/containers/Nexus/NexusOrganizations';
import {
  IMPERSONATE,
  NEXUS_HOME,
  NEXUS_ORGANIZATIONS,
  PERSONALITY_TYPES,
} from '~Common/const/routes';
import LayoutBridge from '~Deprecated/ui/routes/layouts/LayoutBridge';
import PersonalityTypes from '~Deprecated/ui/containers/Nexus/PersonalityTypes';
import PersonalityTypesTopBar from '~Deprecated/ui/containers/Nexus/PersonalityTypes/PersonalityTypesTopBar';
import Impersonate from '~Deprecated/ui/containers/Nexus/Impersonate/Impersonate';
import { useImpersonationStore } from '~Deprecated/hooks/useImpersonationStore';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useGetLandingPageUrl } from '~Common/hooks/useGetHomePageUrl';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import GetContent from './layouts/GetContent';

const styles = css`
  padding-top: 0 !important;
  height: calc((var(--vh, 1vh) * 100) - 69px) !important;
  max-height: calc((var(--vh, 1vh) * 100) - 69px) !important;
  overflow-anchor: none;
`;

const Nexus = () => {
  const { isImpersonating } = useImpersonationStore();
  const canImpersonate = useFeatureFlag('whiteHatImpersonator');
  const {
    isImpersonator,
    isCustomerSuccessAdmin,
    isCustomerSuccessManager,
  } = useOrgUserPermissionsContext();
  const landingPageUrl = useGetLandingPageUrl();
  const getRedirectUrl = () => {
    if (isCustomerSuccessAdmin || isCustomerSuccessManager) {
      return NEXUS_HOME;
    }
    if (canImpersonate && !isImpersonating && isImpersonator) {
      return IMPERSONATE;
    }
    return landingPageUrl;
  };

  return (
    <Switch>
      { (isCustomerSuccessAdmin || isCustomerSuccessManager) && (
      <Route
        exact
        path={NEXUS_ORGANIZATIONS}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <GetContent
                css={styles}
                ChildComponent={NexusOrganizations}
                className="nexus-wrapper"
              />
                )}
          />
        )}
      />
      ) }
      { (isCustomerSuccessAdmin || isCustomerSuccessManager) && (
      <Route
        exact
        path={PERSONALITY_TYPES}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <GetContent
                ChildComponent={PersonalityTypes}
                TopComponent={PersonalityTypesTopBar}
              />
                )}
          />
        )}
      />
      ) }
      { (canImpersonate && !isImpersonating && isImpersonator) && (
      <Route
        exact
        path={IMPERSONATE}
        render={(routeProps) => (
          <LayoutBridge
            routeProps={routeProps}
            Component={(
              <GetContent
                css={styles}
                ChildComponent={Impersonate}
              />
                )}
          />
        )}
      />
      ) }
      <Redirect
        from="*"
        to={getRedirectUrl()}
      />
    </Switch>
  );
};

export default Nexus;
