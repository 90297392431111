import { useQuery, UseQueryResult, QueryFunctionContext } from '@tanstack/react-query';
import { getOrganizationId } from '~Common/utils/localStorage';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { Survey } from '~Surveys/const/Survey';
import { homeQueryKeys } from './queryKeys';

const getReceivedSurveys = async ({ queryKey }:QueryFunctionContext<readonly string[]>):Promise<HttpCallReturn<Survey[]>> => {
  const [orgId] = queryKey;
  const url = `/organizations/${orgId}/surveys/assignedSurveys?participants=true&isWhatsDue=true`;
  return getApi(url, {});
};

export const useHomeSurveys = ():UseQueryResult<HttpCallReturn<Survey[]>> => useQuery({
  queryKey: homeQueryKeys.homeSurvey(getOrganizationId() ?? ''),
  queryFn: getReceivedSurveys,
});
