import { getStorageItem } from '~Common/utils/localStorage';

export interface FeatureFlagState {
  featureFlags: {
    data: FeatureFlags,
    isOverriding: boolean,
    isFullyInitialized: boolean,
    version: string;
  };
}

interface FeatureFlags {
  [featureFlag: string]: boolean | number | string | undefined;
}

export const getFeatureFlags = (state: FeatureFlagState): FeatureFlags => {
  let flags: FeatureFlags | undefined;
  if (process.env.REACT_APP_ENV !== 'PROD') {
    const override = getStorageItem('flags');

    if (override) {
      flags = JSON.parse(override) as FeatureFlags;
    }
  }

  if (!flags) {
    flags = state.featureFlags.data;
  }

  return flags;
};

// The version needs extracted this way for some reason, otherwise the app blows up because the proxy that wraps the feature flags gets grumpy that this variation changed.
// ¯\_(ツ)_/¯
export const getFeatureVersion = (state: FeatureFlagState): string => {
  let version: string | undefined;
  if (process.env.REACT_APP_ENV !== 'PROD') {
    const override = getStorageItem('flags');

    if (override) {
      const flags = JSON.parse(override) as FeatureFlags;
      version = flags.webClientCurrentBuildVersion as string | undefined;
    }
  }

  if (!version) {
    version = state.featureFlags.version;
  }

  return version;
};
