import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import CancelNeutral from '~Common/components/Buttons/CancelNeutral';
import { destructiveOverlay } from '~Common/styles/colors';

const styles = {
  baseOverlay: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15rem;
  `,
  message: css`
    color: white;
    display: block;
    padding: 0 15%;
    font-size: 2rem;
    text-align: center;
    margin: 0 0 2rem;
  `,
  buttons: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  background: css`
  background: ${destructiveOverlay};
  padding-bottom: 3rem;
  padding-top: 2rem;
  `,
};

const BaseDrawerOverlay = ({
  message,
  renderConfirmButton,
  onClose,
  ...props
}) => (
  <div
    css={styles.baseOverlay}
    {...props}
  >
    <div css={styles.background}>
      <div css={styles.message}>
        {message}
      </div>
      <div css={styles.buttons}>
        {renderConfirmButton}
        <CancelNeutral text="Cancel" onClick={onClose} />
      </div>
    </div>
  </div>
);

BaseDrawerOverlay.propTypes = {
  message: PropTypes.string.isRequired,
  renderConfirmButton: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BaseDrawerOverlay;
