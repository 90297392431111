import queryString from 'query-string';

export function parseQueryString(url) {
  return queryString.parse(decodeURIComponent(url));
}

export const noop = () => {
};

export const makeInitials = ({ name }) => {
  let initials = '';
  const [firstName, lastName = ''] = name.split(' ');

  if (firstName && firstName[0]) {
    initials += firstName[0].toUpperCase();
  }

  if (lastName && lastName[0]) {
    initials += lastName[0].toUpperCase();
  }

  return initials;
};

export const ensureLinkHasProtocol = (link) => {
  if (!link.match(/^https?:\/\//i)) {
    return `https://${link}`;
  }

  return link;
};
