import { css } from '@emotion/react';
import {
  LearningStatus,
  LearningType, ReceivedPlaylistDetail,
} from '~Learning/const/interfaces';
import LearningSectionsCard from '~Learning/components/Shared/LearningSectionsCard';
import LearningTypeInfo from '~Learning/components/Shared/LearningDashboardCards/LearningTypeInfo';
import TitleAndIntroduction from '~Learning/components/Shared/TitleAndIntroduction';
import { palette } from '~Common/styles/colors';
import LearningCompletedCount from '~Learning/components/Shared/LearningDashboardCards/LearningCompletedCount';
import DueDateFromNow from '~Common/V3/components/DueDateFromNow';
import LearningAvatar from '~Learning/components/Shared/LearningAvatar';

const styles = {
  contentsTab: css({}),
  completedLearningsProgressBar: css({
    marginTop: '1.875rem',
    height: '1.875rem',
  }),
  learningContentHeader: css({
    margin: '1.5rem 0',
    color: palette.neutrals.gray800,
    fontWeight: 500,
    fontSize: '1.25rem',
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

interface DetailsProps {
  playlist: ReceivedPlaylistDetail,
}

const Details = ({
  playlist,
}: DetailsProps): JSX.Element => (
  <div css={styles.contentsTab}>
    <LearningSectionsCard
      renderHeader={() => (
        <LearningTypeInfo
          learningType={LearningType.PLAYLIST}
        />
      )}
      renderBody={() => (
        <>
          <TitleAndIntroduction
            title={playlist.title}
            introductionHTML={playlist.description}
            renderAvatar={() => (
              <LearningAvatar userId={playlist.assigner.id} />
            )}
          />
        </>
      )}
      renderFooter={() => (
        <div css={styles.footer}>
          <DueDateFromNow
            dueDate={playlist.dueDate}
            isComplete={playlist.status === LearningStatus.COMPLETED}
          />
          <LearningCompletedCount
            numberOfUsersAssigned={playlist.totalLearningsCount}
            numberOfUsersCompleted={playlist.completedLearningsCount}
          />
        </div>
      )}
    />
  </div>
);

export default Details;
