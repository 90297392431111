import { SelectChangeEvent } from '@mui/material';
import { usePagination } from 'react-instantsearch-hooks-web';
import Pagination from '~Common/V3/components/Pagination';

interface ViewProps {
  handlePageChange: (event: SelectChangeEvent<number>) => void,
  currentPage: number,
  numberOfPages: number,
  handlePreviousClick: () => void,
  handleNextClick: () => void,
}

const View = ({
  handlePageChange,
  currentPage,
  numberOfPages,
  handlePreviousClick,
  handleNextClick,
  ...props
}: ViewProps): JSX.Element => (
  <Pagination
    // Our pagination component starts from Page 1, but Algolia starts from Page 0
    page={currentPage + 1}
    onPageChange={handlePageChange}
    numberOfPages={numberOfPages}
    onPreviousClick={handlePreviousClick}
    onNextClick={handleNextClick}
    {...props}
  />
);

type AlgoliaCustomPaginationProps = Omit<ViewProps, 'handlePageChange' | 'currentPage' | 'numberOfPages' | 'handlePreviousClick' | 'handleNextClick'>;

const AlgoliaCustomPagination = ({ ...props }: AlgoliaCustomPaginationProps): JSX.Element => {
  const { refine, currentRefinement: currentPage, nbPages: numberOfPages } = usePagination();

  const handlePageChange = (event: SelectChangeEvent<number>): void => {
    refine(Number(event.target.value) - 1);
  };

  const handlePreviousClick = (): void => {
    refine(currentPage - 1);
  };

  const handleNextClick = (): void => {
    refine(currentPage + 1);
  };

  const hookProps = {
    handlePageChange,
    currentPage,
    numberOfPages: Math.max(numberOfPages, 1),
    handlePreviousClick,
    handleNextClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AlgoliaCustomPagination;
