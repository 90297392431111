import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { usePrevious } from '~Deprecated/hooks/usePrevious';
import { useSkeletonLoadersStore } from './useSkeletonLoadersStore';

// Documentation: https://leadrhq.atlassian.net/l/c/E7qyTJD3
export const useSkeletonLoaders = (isLoading: boolean): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [skeletonLoadersOverride] = useSkeletonLoadersStore();
  const previousIsLoading = usePrevious(isLoading);
  const isUpdating = previousIsLoading !== undefined && isLoading !== previousIsLoading;

  const [showSkeletonLoaders, setShowSkeletonLoaders] = useState(skeletonLoadersOverride || isLoading);

  useEffect(() => {
    if (skeletonLoadersOverride) {
      setShowSkeletonLoaders(true);
    } else {
      setShowSkeletonLoaders(isLoading);
    }
  }, [isLoading, showSkeletonLoaders, skeletonLoadersOverride]);

  return [
    isUpdating ? true : showSkeletonLoaders,
    setShowSkeletonLoaders,
  ];
};
