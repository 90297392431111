import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { withTruncate } from '~Common/styles/mixins';
import { HTMLString } from '~Common/types';
import Card, { CardProps } from '~Common/V3/components/Card';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';

const styles = {
  playlistContentCard: (isLearningSelected: boolean) => css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
    border: `1px solid ${palette.neutrals.white}`,
    '&:not(:last-child)': {
      marginBottom: '1.125rem',
    },
  }, isLearningSelected && ({
    border: `1px solid ${palette.brand.blue}`,
  })),
  contentDetails: css({
    display: 'grid',
    gridTemplateColumns: '30% 70%',
    columnGap: '1.125rem',
    alignItems: 'center',
  }),
  details: css({
    overflow: 'hidden',
  }),
  learningTitle: css({
    color: palette.neutrals.gray800,
    fontWeight: 600,
    fontSize: '1rem',
  }, withTruncate()),
  learningIntroduction: css({
    color: palette.neutrals.gray500,
    fontSize: '.8125rem',
    fontWeight: 600,
    marginTop: '.625rem',
  }, withTruncate()),
};

interface LearningCardProps extends Omit<CardProps, 'renderContents'> {
  title: string,
  introductionHTML: HTMLString,
  contentUrl: string,
  isLearningSelected: boolean,
}

const PlaylistContentCard = ({
  title,
  introductionHTML,
  contentUrl,
  isLearningSelected,
  ...props
}: LearningCardProps): JSX.Element => (
  <Card
    css={styles.playlistContentCard(isLearningSelected)}
    renderContents={() => (
      <div css={styles.contentDetails}>
        <PreviewWithExternalNavigation
          viewOption="Thumbnail"
          contentUrl={contentUrl}
        />
        <div css={styles.details}>
          <div css={styles.learningTitle}>{title}</div>
          <HTMLRenderer css={styles.learningIntroduction} htmlText={introductionHTML} />
        </div>
      </div>
    )}
    {...props}
  />
);

export default PlaylistContentCard;
