import { css } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { noop, uniqBy } from 'lodash';
import PropTypes from 'prop-types';

import { palette } from '~Common/styles/colors';

const styles = {
  filterContainer: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,
  filterText: css`
    color: ${palette.neutrals.gray600};
    margin-bottom: .625rem;
    max-width: fit-content;
    margin-right: 1rem;
  `,
  chip: css`
    border-radius: .5rem;
    background-color: ${palette.neutrals.gray50};
    color: ${palette.neutrals.gray600};
    margin-bottom: 0.5rem;
    max-width: 100%;

    &:not(:last-child) {
      margin-right: .625rem;
    }

    &:hover, &:focus, &:active {
      color: ${palette.brand.red};
      background-color: ${palette.neutrals.gray50};

      .MuiChip-deleteIcon {
        color: ${palette.brand.red};
      }
    }
  `,
  timesIcon: css``,
};

const View = ({ selectedFilters, onTagClick, ...props }) => (
  <div css={styles.filterContainer} {...props}>
    <div css={styles.filterText}>Filtering By:</div>
    {selectedFilters.map((filter) => (
      <Chip
        key={`tag-${filter.text}`}
        css={styles.chip}
        label={filter.text}
        onClick={() => onTagClick(filter)}
        onDelete={() => onTagClick(filter)}
        deleteIcon={<FontAwesomeIcon icon={faTimes} css={styles.timesIcon} height={11} width={11} />}
      />
    ))}
  </div>
);

View.propTypes = {
  onTagClick: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
};

View.defaultProps = {
  onTagClick: noop,
  selectedFilters: [],
};

const SelectedFilterChips = ({
  selectedFilters, setSelectedFilters, onFilterChange, ...props
}) => {
  const onTagClick = (text) => {
    const tempArray = [...selectedFilters];
    const index = tempArray.findIndex((filter) => filter === text);

    tempArray.splice(index, 1);
    setSelectedFilters(tempArray);
    onFilterChange(tempArray);
  };

  const hookProps = {
    selectedFilters: uniqBy(selectedFilters, 'text'),
    setSelectedFilters,
    onTagClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

SelectedFilterChips.propTypes = {
  selectedFilters: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
  })),
  setSelectedFilters: PropTypes.func,
  onFilterChange: PropTypes.func,
};

SelectedFilterChips.defaultProps = {
  selectedFilters: [],
  setSelectedFilters: noop,
  onFilterChange: noop,
};

export { View };
export default SelectedFilterChips;
