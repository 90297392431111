import { css } from '@emotion/react';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';

const styles = {
  listDashboard: css({
    display: 'grid',
    gridGap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill)',
    width: '100%',
  }),
  cardSkeleton: css({
    maxWidth: '100%',
    height: '6.25rem',
  }),
};

interface SkeletonViewProps {
  amountOfLoaders: number,
  }

const SkeletonView = ({
  amountOfLoaders,
}: SkeletonViewProps): JSX.Element => (
  <MultipleSkeletonLoaders
    css={styles.listDashboard}
    numberOfSkeletons={amountOfLoaders}
    renderSkeletonItem={() => (
      <CardSkeleton css={styles.cardSkeleton} />
    )}
  />
);

export { SkeletonView as ListDashboardSkeleton };
