import { css } from '@emotion/react';
import {
  DataGrid,
  GridColumns,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';

import { palette } from '~Common/styles/colors';
import { TeamUserRow, TeamUserRowAllowedAction } from '~People/components/Teams/const/types';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { UsePaginationReturn, usePagination } from '~Common/hooks/usePagination';
import { useTeamDetailsRowDisplayCount } from '~People/components/Teams/stores/useTeamDetailsRowDisplayCount';
import { useEffect, useState } from 'react';
import Pagination from '~Common/V3/components/Pagination';
import { useGetTeamUsers } from '~People/components/Teams/hooks/useGetTeamUsers';
import { useCareCard } from '~Meetings/hooks/useCareCard';
import { toTitleCase } from '~Common/utils';
import ActionMenu from './ActionMenu';
import ProfileCell from './ProfileCell';
import EmptyState from './EmptyState';

const styles = {
  table: css({
    color: palette.neutrals.gray800,
    border: 0,

    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },

    '.MuiDataGrid-columnHeaders': {
      backgroundColor: palette.neutrals.gray100,
      fontWeight: 600,
      border: 0,
      borderRadius: 0,
    },
  }),
  skeletonLoader: css({
    maxWidth: '100%',
    height: '15rem',
    transform: 'initial',
  }),
  pagination: css({
    marginTop: '1.5rem',
  }),
};

interface ViewProps extends UsePaginationReturn {
  rows: TeamUserRow[] | undefined,
  columns: GridColumns<TeamUserRow>,
  areUserDetailsLoading: boolean,
  handleCellClick: GridEventListener<'cellClick'>,
  page: number,
  pageSize: number,
}

const View = ({
  rows,
  columns,
  areUserDetailsLoading,
  handleCellClick,
  page,
  pageSize,
  numberOfPages,
  onPageChange,
  onPreviousClick,
  onNextClick,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    {areUserDetailsLoading && (
      <SkeletonLoader
        css={styles.skeletonLoader}
        renderComponent={() => <></>}
      />
    )}
    {!areUserDetailsLoading && rows && (
      <>
        <DataGrid
          css={styles.table}
          autoHeight
          columns={columns}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          sortingMode="server"
          headerHeight={30}
          rowHeight={46}
          hideFooter
          pageSize={pageSize}
          rows={rows}
          onCellClick={handleCellClick}
          getRowId={(row) => row.orgUserId}
          components={{
            NoRowsOverlay: EmptyState,
          }}
        />
        {rows.length > 0 && (
          <Pagination
            css={styles.pagination}
            page={page}
            onPageChange={onPageChange}
            numberOfPages={numberOfPages}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
          />
        )}
      </>
    )}
  </div>
);

interface TeamUsersTableProps {
  teamId: string,
}

const TeamUsersTable = ({
  teamId,
  ...props
}: TeamUsersTableProps): JSX.Element => {
  const isMobile = useIsMobileQuery();

  const [page, setPage] = useState(1);
  const pageSize = useTeamDetailsRowDisplayCount((state) => state.count);

  const { data: teamUsers, isLoading } = useGetTeamUsers({
    teamId,
    // The display page number and index page number are differed by 1
    page: page - 1,
    count: pageSize,
  });

  // If page size changes, set them back to first page, since changing page size can result in an empty page
  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const {
    numberOfPages,
    onPageChange,
    onPreviousClick,
    onNextClick,
  } = usePagination({
    totalCount: teamUsers?.response.totalMembers ?? 0,
    pageSize,
    page,
    setPage,
  });

  const rows = teamUsers?.response.members;

  const [areUserDetailsLoading] = useSkeletonLoaders(isLoading);

  const onClickOnAvatar = useCareCard();

  const handleCellClick: GridEventListener<'cellClick'> = (params): void => {
    if (params.field !== 'allowedActions') {
      // @ts-expect-error TODO: Remove when typed
      onClickOnAvatar({ orgUserId: params.id });
    }
  };

  const columns: GridColumns<TeamUserRow> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<TeamUserRowAllowedAction[], TeamUserRow>) => {
        const { firstName, lastName, profileImageUrl } = params.row;

        return (
          <ProfileCell
            fullName={`${firstName} ${lastName}`}
            profileImageUrl={profileImageUrl}
          />
        );
      },
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      flex: isMobile ? 1 : 0.5,
      sortable: false,
    },
    {
      field: 'department',
      headerName: 'Department',
      flex: 0.5,
      sortable: false,
      hide: isMobile,
    },
    {
      field: 'teamRole',
      headerName: 'Role',
      flex: 0.5,
      sortable: false,
      hide: isMobile,
      renderCell: (params: GridRenderCellParams<TeamUserRowAllowedAction[], TeamUserRow>) => {
        const { teamRole: userRole } = params.row;

        // Convert to Proper case (example: 'Member' or 'Owner')
        const userRoleString = toTitleCase(userRole);

        return (
          <div>
            {userRoleString}
          </div>
        );
      },
    },
    {
      field: 'allowedActions',
      headerName: '',
      maxWidth: 32,
      sortable: false,
      renderCell: (params: GridRenderCellParams<TeamUserRowAllowedAction[], TeamUserRow>) => (
        <ActionMenu
          orgUserId={params.row.orgUserId}
          allowedActions={params.row.allowedActions}
          // @ts-expect-error TODO: Remove when typed
          handleViewUser={() => onClickOnAvatar({ orgUserId: params.row.orgUserId })}
          teamId={teamId}
        />
      ),
    },
  ];

  const hookProps = {
    rows,
    columns,
    areUserDetailsLoading,
    handleCellClick,
    page,
    pageSize,
    numberOfPages,
    onPageChange,
    onPreviousClick,
    onNextClick,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default TeamUsersTable;
