import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { AdminImporterTabValues, ResponseDataShape } from '../Const/types';

function separateUsers(allSortedUsers: ResponseDataShape[]): Record<string, ResponseDataShape[]> {
  const successfulImports: ResponseDataShape[] = [];
  const errorImports: ResponseDataShape[] = [];
  const warningImports: ResponseDataShape[] = [];
  allSortedUsers.forEach((user) => {
    if (user.errors.length === 0) {
      successfulImports.push(user);
    }
    if (user.errors.length > 0) {
      errorImports.push(user);
    }
    if (user.warnings.length > 0) {
      warningImports.push(user);
    }
  });

  return {
    successfulImports, errorImports, warningImports,
  };
}

export const useFilterImportData = (allData: ResponseDataShape[], importTab: AdminImporterTabValues): ResponseDataShape[] => {
  const {
    successfulImports, errorImports, warningImports,
  } = useMemo(
    () => separateUsers(sortBy(allData, 'firstName')),
    [allData],
  );

  const filteredData = useMemo(
    (): ResponseDataShape[] => {
      switch (importTab) {
        case AdminImporterTabValues.Successful:
          return successfulImports;
        case AdminImporterTabValues.Errors:
          return errorImports;
        case AdminImporterTabValues.Warnings:
          return warningImports;
        default:
          return [];
      }
    },
    [errorImports, importTab, successfulImports, warningImports],
  );

  return filteredData;
};
