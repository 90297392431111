import { createAction } from '@reduxjs/toolkit';
import { takeLatest, call } from 'redux-saga/effects';

import { sagaMiddleware } from '~Deprecated/store';
import { registerAction } from '~Deprecated/reducers/personalities';
import { loadPersonalities } from '~Deprecated/services/personalityService';
import { callApi } from '~Deprecated/sagas/callApi';
import { mutateState, flattenData } from '~Deprecated/utils/reduxUtils';

const loadPersonalitiesAction = createAction('LEADR.LOAD_PERSONALITIES');
const loadPersonalitiesSuccessAction = createAction('LEADR.LOAD_PERSONALITIES_SUCCESS');
const loadPersonalitiesFailureAction = createAction('LEADR.LOAD_PERSONALITIES_FAILURE');

const setActiveTabForPersonalityTest = createAction('LEADR.SET_ACTIVE_TAB_FOR_PERSONALITY_TEST');

function* onloadPersonalities() {
  yield call(callApi, {
    api: loadPersonalities,
    successAction: loadPersonalitiesSuccessAction,
    failureAction: loadPersonalitiesFailureAction,
  });
}

const onLoadPersonalitiesBegin = (state) => mutateState(state, (draftState) => {
  draftState.hasPendingCall = true;
  return draftState;
});

function setActiveTabForPersonalityTestSuccess(state, action) {
  return mutateState(state, (draftState) => {
    draftState.activeTabIndex = action.payload;
    return draftState;
  });
}

function* loadPersonlitiesWatcher() {
  yield takeLatest(loadPersonalitiesAction, onloadPersonalities);
}

function loadPersonalitiesFailure(state, action) {
  return mutateState(state, (draftState) => {
    draftState.ids = [];
    draftState.data = {};
    draftState.error = action.payload;
    draftState.hasPendingCall = false;
    return draftState;
  });
}

function loadPersonalitiesSuccess(state, action) {
  const personalities = Object.keys(action.payload.response);
  const flattenedPersonalities = personalities.map((personality) => flattenData({
    responseData: action.payload.response[personality],
    onItemFlatten: ({ label }) => ({ label }),
  }));

  return mutateState(state, (draftState) => {
    draftState.data = flattenedPersonalities.reduce((data, personality) => ({ ...data, ...personality.data }), {});

    personalities.forEach((key, index) => {
      draftState[key.toLowerCase().replace(/ +/g, '')] = flattenedPersonalities[index].ids;
    });

    draftState.hasPendingCall = false;
  });
}

registerAction(loadPersonalitiesSuccessAction, loadPersonalitiesSuccess);
registerAction(loadPersonalitiesFailureAction, loadPersonalitiesFailure);
registerAction(setActiveTabForPersonalityTest, setActiveTabForPersonalityTestSuccess);
registerAction(loadPersonalitiesAction, onLoadPersonalitiesBegin);

sagaMiddleware.run(loadPersonlitiesWatcher);

export {
  loadPersonalitiesAction,
  setActiveTabForPersonalityTest,
};
