import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { toast } from '~Common/components/Toasts';

import Loader from '~Common/components/Loader';
import LoadMoreObserver from '~Common/components/InfiniteList/LoadMoreObserver';
import ListView from './ListView';
import GroupedListView from './GroupedListView';
import MasonryListView from './MasonryListView';

const styles = {
  loaderStyles: css({
    transform: 'translateY(calc(50vh - 112px))',
    width: '100%',
  }),
};

const View = ({
  groupedIds,
  ids,
  renderItem,
  renderLoadMore,
  data,
  requestType,
  isMasonry,
  ...props
}) => (
  <>
    {isMasonry && (
      <MasonryListView
        ids={ids}
        renderItem={renderItem}
        data={data}
        {...props}
      />
    )}
    {!isMasonry && (
      <>
        { groupedIds ? (
          <GroupedListView
            groupedIds={groupedIds}
            renderItem={renderItem}
            requestType={requestType}
            {...props}
          />
        ) : (
          <ListView
            ids={ids || []}
            renderItem={renderItem}
            data={data}
            requestType={requestType}
            {...props}
          />
        ) }
      </>
    )}
    { renderLoadMore() }
  </>
);

View.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  groupedIds: PropTypes.object,
  renderItem: PropTypes.func.isRequired,
  renderLoadMore: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  requestType: PropTypes.string,
  isMasonry: PropTypes.bool,
};

View.defaultProps = {
  ids: null,
  groupedIds: null,
  data: {},
  requestType: '',
  isMasonry: false,
};

const InfiniteListView = ({
  listQuery,
  loaderStyle,
  renderEmptyState,
  renderLoadingState,
  requestType,
  infiniteQueryProp,
  ...props
}) => {
  const {
    ids,
    result,
    groupedIds,
    initialPageItemCount,
    searchString,
    data,
  } = listQuery(infiniteQueryProp ?? {});

  const {
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = result;

  const renderLoadMore = useCallback(() => {
    if (!hasNextPage) {
      return null;
    }

    return (
      <LoadMoreObserver isLoading={isFetchingNextPage} onObserve={fetchNextPage} />
    );
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  if (status === 'error') {
    if (process.env.NODE_ENV === 'development') {
      toast.error(error.message);
    }
    return null;
  }

  if (status === 'loading' || (!ids || (ids?.length === 0 && initialPageItemCount > 0 && !searchString))) {
    return (
      <>
        {renderLoadingState?.()}
        {!renderLoadingState && (
          <div css={[styles.loaderStyles, loaderStyle]}>
            <Loader />
          </div>
        )}
      </>
    );
  }

  if (status !== 'loading' && (ids.length === 0) && renderEmptyState) {
    return renderEmptyState();
  }

  const hookProps = {
    ids,
    renderLoadMore,
    groupedIds,
    data,
    requestType,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

InfiniteListView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listWrapperStyle: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  loaderStyle: PropTypes.object,
  listQuery: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderEmptyState: PropTypes.func.isRequired,
  renderLoadingState: PropTypes.func,
  requestType: PropTypes.string,
  infiniteQueryProp: PropTypes.object,
};

InfiniteListView.defaultProps = {
  listWrapperStyle: {},
  loaderStyle: {},
  renderLoadingState: null,
  requestType: '',
  infiniteQueryProp: {},
};

export default InfiniteListView;
