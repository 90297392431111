import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { ActionItem, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { getHost, hosts } from '~Deprecated/services/config';
import { buildQueryString } from '~Common/utils';
import { ActionItemSortField } from '~ActionItems/hooks/useActionItems';
import { SortDirection } from '~Common/const/interfaces';

export interface GetGoalActionItemsParams {
  factoryId: string,
  status?: NewActionItemStatus | NewActionItemStatus[],
  sortByField?: ActionItemSortField,
  sortByOrder?: SortDirection,
}

const getMeetingActionItems = async ({
  factoryId,
  status,
  sortByField,
  sortByOrder,
}: GetGoalActionItemsParams): Promise<HttpCallReturn<ActionItem[]>> => {
  const queryString = buildQueryString({
    status,
    sortByField,
    sortByOrder,
  });

  const url = {
    host: getHost(hosts.action, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/action-items/meeting/${factoryId}${queryString}`,
  };

  return getApi<ActionItem[]>(url);
};

export interface UseGoalActionItemsProps {
  factoryId: string,
  status?: NewActionItemStatus | NewActionItemStatus[],
  sortByField?: ActionItemSortField,
  sortByOrder?: SortDirection,
}

export const useMeetingActionItems = ({
  factoryId,
  status,
  sortByField,
  sortByOrder,
}: UseGoalActionItemsProps): UseQueryResult<HttpCallReturn<ActionItem[]>> => (
  useQuery({
    queryKey: [
      getOrganizationId(),
      'action-items',
      'goal',
      factoryId,
      status,
      sortByField,
      sortByOrder,
    ],
    queryFn: () => getMeetingActionItems({
      factoryId,
      status,
      sortByField,
      sortByOrder,
    }),
    enabled: !!factoryId,
  })
);
