import { ReactText, useRef } from 'react';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { getOrganizationId } from '~Common/utils/localStorage';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { ActionItemContext, ActionItemContextType, NewActionItemStatus } from '~ActionItems/const/interfaces';
import { queryClient } from '~Common/const/queryClient';
import { toast } from '~Common/components/Toasts';
import { popDrawerAction as popDrawer } from '~Deprecated/actions/drawers/popDrawer';
import SupportChatErrorToast from '~Common/components/SupportChatErrorToast';
import { USER_PENDING_ITEMS_QUERY_KEY } from '~Common/hooks/user/useUserPendingItems';

export interface ActionItemDataAssignee {
  userId: string,
  orgUserId: string,
}

export interface ActionItemData {
  assignees: ActionItemDataAssignee[],
  dueDateInMillis?: number,
  externalLink?: string,
  status: NewActionItemStatus,
  text: string,
  isRecurring?: boolean,
  recurrenceRule?: string,
}

export interface NormalizedActionItemData {
  assigneeId: string,
  assigneeUserId: string,
  dueDateInMillis?: number,
  externalLink?: string,
  status: NewActionItemStatus,
  text: string,
  isRecurring?: boolean,
  recurrenceRule?: string,
}

export interface CreateActionItemProps {
  actionItems: ActionItemData[],
  context?: ActionItemContext,
}

export interface CreateActionItemsRequestData {
  items: NormalizedActionItemData[],
  context?: ActionItemContext,
}

export const normalizeActionItems = (actionItems: ActionItemData[]): NormalizedActionItemData[] => (
  actionItems.flatMap((actionItem) => {
    const { assignees, ...actionItemData } = actionItem;

    return assignees.map((assignee) => ({
      assigneeId: assignee.orgUserId,
      assigneeUserId: assignee.userId,
      ...actionItemData,
    }));
  })
);

export const createActionItems = async ({
  context,
  actionItems,
}: CreateActionItemProps): Promise<HttpCallReturn<null>> => {
  const url = {
    host: getHost(hosts.action, '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/action-items`,
  };

  const requestData: CreateActionItemsRequestData = {
    items: normalizeActionItems(actionItems),
  };

  if (context) {
    requestData.context = context;
  }
  return postApi(url, requestData);
};

export interface UseCreateActionItemsProps {
  drawerName?: string,
}

export const useCreateActionItems = ({
  drawerName,
}: UseCreateActionItemsProps): UseMutationResult<HttpCallReturn<null>, unknown, CreateActionItemProps, void> => {
  const toastId = useRef<ReactText | number | null>(null);
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: createActionItems,
    onMutate: () => {
      toastId.current = toast.info('Creating action item', { autoClose: false });
    },
    onError: () => {
      toast.update(toastId.current, {
        render: (
          <SupportChatErrorToast
            error="The action item creation failed due to a technical issue on our end."
            chatMessage="Trouble creating an action item."
          />
        ),
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: async (_, variables) => {
      const { context, actionItems } = variables;
      let successMessage = 'Your action item was successfully created.';

      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'action-items'] });
      await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'homeActionItems', 'all'] });
      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });

      if (context?.type === ActionItemContextType.Meeting) {
        await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'huddles', context.id, 'actionItems'] });
      }

      if (context?.type === ActionItemContextType.Goal) {
        await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'goals', context.id] });
        await queryClient.invalidateQueries({ queryKey: [getOrganizationId(), 'action-items', 'goal', context.id] });
      }

      if (drawerName) {
        // @ts-expect-error Removed once we type or replace Redux
        dispatch(popDrawer({ drawerName }));
      }

      const totalCreatedActionItems = actionItems.reduce((acc, actionItem) => acc + actionItem.assignees.length, 0);

      if (totalCreatedActionItems > 1) {
        successMessage = `Successfully created ${totalCreatedActionItems} action items.`;
      }

      toast.update(toastId.current, {
        render: successMessage,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    },
  });

  return mutation;
};
