import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import LeadrModal from '~Common/V3/components/LeadrModal';
import { palette } from '~Common/styles/colors';
import { DEFAULT_DATE, DEFAULT_RESOURCE_TITLE, PersonalDevelopmentResourceTypeLabels } from '~DevelopmentPlan/const/defaults';
import { useAddResourceModalStore } from '~DevelopmentPlan/stores/useAddResourceModalStore';
import { ResourceType } from '~DevelopmentPlan/const/types';
import { AddResourceModalBody } from './AddResourceModalBody';

const styles = {
  footer: css({
    '&>#modalButtons': {
      alignItems: 'center',
      columnGap: '0.5rem',
      display: 'flex',
      flexDirection: 'row',
    },
  }),
  modal: css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }),
  modalBody: css({
    flex: 1,
    overflow: 'unset',
  }),
  title: css({
    color: palette.neutrals.gray800,
    fontSize: '1.125rem',
    fontWeight: 600,
  }),
  tabWrapper: css({
    backgroundColor: palette.neutrals.gray100,
    display: 'flex',
    height: '3.125rem',
    alignItems: 'flex-end',
    position: 'relative',

    ':before': {
      width: '300%',
      height: '100%',
      content: '""',
      background: 'inherit',
      position: 'absolute',
      top: 0,
      left: '-100%',
      zIndex: -1,
    },
  }),
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

interface ViewProps {
  activeTab: number,
  setActiveTab: (tab: number) => void,
  closeAddResourceModal: () => void,
  showAddResourceModal: boolean,
  getResourceTypeString: () => string,
  pdpId: string,
}

const View = ({
  activeTab,
  setActiveTab,
  closeAddResourceModal,
  showAddResourceModal,
  getResourceTypeString,
  pdpId,
}: ViewProps): JSX.Element => (
  <LeadrModal
    css={styles.modal}
    open={showAddResourceModal}
    onClose={closeAddResourceModal}
  >
    <LeadrModal.Header>
      <LeadrModal.Title css={styles.title}>
        {getResourceTypeString()}
      </LeadrModal.Title>
    </LeadrModal.Header>
    <LeadrModal.Body css={styles.modalBody}>
      <AddResourceModalBody
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        pdpId={pdpId}
      />
    </LeadrModal.Body>
    <LeadrModal.Footer css={styles.footer}>
      <div id="modalButtons" />
    </LeadrModal.Footer>
  </LeadrModal>
);

interface AddResourceModalProps {
  pdpId: string,
}

export const AddResourceModal = ({
  pdpId,
}: AddResourceModalProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    closeAddResourceModal,
    showAddResourceModal,
    resourceId,
    setResourceContentDueDate,
    setResourceContentTitle,
  } = useAddResourceModalStore((state) => ({
    closeAddResourceModal: state.closeAddResourceModal,
    showAddResourceModal: state.showAddResourceModal,
    setResourceContentDueDate: state.setResourceContentDueDate,
    setResourceContentTitle: state.setResourceContentTitle,
    resourceId: state.resourceId,
  }));
  const resourceTypeTitle = PersonalDevelopmentResourceTypeLabels[resourceId ?? ResourceType.All];
  const getResourceTypeString = (): string => (resourceTypeTitle !== PersonalDevelopmentResourceTypeLabels[ResourceType.Accomplishment]
    ? `Add ${resourceTypeTitle}` : `New ${resourceTypeTitle}`);
  // We want to reset data when we go to create a new resource
  useEffect(() => {
    setResourceContentDueDate(DEFAULT_DATE);
    setResourceContentTitle(DEFAULT_RESOURCE_TITLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hookProps = {
    closeAddResourceModal,
    showAddResourceModal,
    activeTab,
    setActiveTab,
    pdpId,
    getResourceTypeString,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
