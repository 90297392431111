import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { palette } from '~Common/styles/colors';
import OverflowMenu from '~Common/V3/components/OverflowMenu';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '~Meetings/components/shared/ActionMenu';
import {
  faBullseyeArrow, faPenToSquare, faTrash, faEllipsisVertical, faArrowUpRightFromSquare,
} from '@fortawesome/pro-light-svg-icons';
import DeleteConfirmationPopover, {
  UseDeleteConfirmationPopoverHookReturn,
  useDeleteConfirmationPopover,
} from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationPopover';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import { Goals } from '@leadr-hr/types';
import { useHistory } from 'react-router-dom';
import { useDeleteGoal } from '../hooks/useDeleteGoal';
import { useGoalStatusModalStore } from '../stores/useGoalsStatusModalStore';
import { GoalsRoutes } from '../routes/GoalsRouter';

const styles = {
  actionMenu: css({
    backgroundColor: palette.neutrals.gray500,
  }),
  icon: css({
    height: '1.125rem',
    color: palette.neutrals.gray700,
  }),
  deleteGoal: css({
    color: palette.brand.red,
    borderTop: `1px solid ${palette.neutrals.gray300}`,

    '&:first-of-type': {
      border: 'none',
    },
  }),
  actionButton: css({
    display: 'flex',
    flexDirection: 'row',
  }),
};

interface ViewProps extends Omit<UseDeleteConfirmationPopoverHookReturn, 'openConfirmationPopover'> {
  menuItems: MenuItem[],
  handleConfirmDeletion: () => void,
}

const View = ({
  menuItems,
  closeConfirmationPopover,
  isOpen,
  popoverId,
  anchorEl,
  handleConfirmDeletion,
  ...props
}: ViewProps): JSX.Element => (
  <>
    <OverflowMenu
      menuItems={[menuItems]}
      renderOverflowMenuButton={(doOpen) => (
        <LeadrButton
          onClick={doOpen}
          variant="text"
          data-test-id="peopleTeamsActionMenu"
          css={styles.actionButton}
        >
          <FontAwesomeIcon css={styles.icon} icon={faEllipsisVertical} />
        </LeadrButton>
      )}
      {...props}
    />
    <DeleteConfirmationPopover
      closeConfirmationPopover={closeConfirmationPopover}
      open={isOpen}
      popoverId={popoverId}
      anchorEl={anchorEl}
      renderConfirmationButtons={({
        informationStyles,
        optionStyles,
        popoverButtonStyles,
      }) => (
        <DeleteConfirmationButtons
          informationStyles={informationStyles}
          optionStyles={optionStyles}
          popoverButtonStyles={popoverButtonStyles}
          onDelete={handleConfirmDeletion}
        />
      )}
    />
  </>
);

interface GoalActionMenuProps {
  goal: Goals.Goal,
  showExpandedActionMenu?: boolean,
}

export const GoalActionMenu = ({
  goal,
  showExpandedActionMenu = false,
  ...props
}: GoalActionMenuProps): JSX.Element => {
  const {
    anchorEl,
    openConfirmationPopover,
    closeConfirmationPopover,
    isOpen,
    popoverId,
  } = useDeleteConfirmationPopover('goalsViewGoalDeleteConfirmationPopover');

  const { openStatusUpdateModal } = useGoalStatusModalStore((state) => ({
    openStatusUpdateModal: state.openStatusUpdateModal,
  }));

  const history = useHistory();

  const { goalId } = goal;

  const { mutate: deleteGoalMutation } = useDeleteGoal();

  const handleConfirmDeletion = (): void => {
    deleteGoalMutation({ goalId }, { onSuccess: () => history.push(GoalsRoutes.Dashboard) });
  };

  const dispatch = useDispatch();
  const menuItems: MenuItem[] = [];

  if (goal.permissions.includes(Goals.GoalPermission.CanEditGoal)) {
    menuItems.push({
      text: 'Edit Goal',
      icon: faPenToSquare,
      onClick: () => {
        history.push(GoalsRoutes.EditById.replace(':goalId', goal?.goalId ?? ''));
        if (showExpandedActionMenu) {
          // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
          dispatch(popDrawerAction({ popAll: true }));
        }
      },
    });
  }

  if (goal.permissions.includes(Goals.GoalPermission.CanEditGoalStatus)) {
    menuItems.push({
      text: 'Update Status',
      icon: faBullseyeArrow,
      onClick: () => {
        openStatusUpdateModal(goal);
      },
    });
  }
  if (goal.permissions.includes(Goals.GoalPermission.CanDeleteGoal)) {
    menuItems.push({
      text: 'Delete Goal',
      icon: faTrash,
      onClick: openConfirmationPopover,
      styles: {
        menuText: styles.deleteGoal,
        menuItem: styles.deleteGoal,
        iconColor: 'red',
      },
    });

    if (showExpandedActionMenu) {
      menuItems.unshift({
        text: 'Expand Goal',
        icon: faArrowUpRightFromSquare,
        onClick: () => {
          const openGoal = GoalsRoutes.ViewById.replace(':goalId', goal?.goalId ?? '');
          window.open(openGoal, '_blank');
        },
      });
    }
  }

  if (!menuItems.length) {
    return <></>;
  }

  const hookProps = {
    anchorEl,
    closeConfirmationPopover,
    handleConfirmDeletion,
    isOpen,
    menuItems,
    popoverId,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
