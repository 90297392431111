import { css, SerializedStyles } from '@emotion/react';
import {
  faTimes, faEdit, faFolderOpen, faArrowLeft,
} from '@fortawesome/pro-light-svg-icons';
import { useCallback } from 'react';

import DrawerOptionSelectItem from '~Learning/components/Shared/DrawerOptionSelectItem/DrawerOptionSelectItem';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import IconButton from '~Meetings/components/buttons/IconButton';
import { createLearningTemplate } from '~Learning/components/CreateLearningDrawer';
import { useLearningLibrary } from '~Learning/hooks/utils/useLearningLibrary';
import { AssignLearningOptions } from '~Learning/const/interfaces';
import { shareLearningDrawerTemplate } from '../ShareLearningDrawer';

const styles = {
  drawerBody: css({}),
  instructions: css({
  }),
  listItem: css({
    ':not(:first-of-type)': {
      marginTop: '.625rem',
    },
  }),
};

export const learningTypePickerTemplate = {
  name: 'SELECT_LEARNING_TYPE',
  width: DRAWER_WIDTHS.BASE,
};

const options = [
  {
    content: AssignLearningOptions.START_FROM_SCRATCH,
    icon: faEdit,
    dataTestId: 'learningStartFromScratchButton',
  },
  {
    content: AssignLearningOptions.START_WITH_A_TEMPLATE,
    icon: faFolderOpen,
    dataTestId: 'learningStartWithATemplateButton',
  },
];

const LearningTypePicker = ({ popDrawer, pushDrawer, setDrawerState }: DrawerProps<Record<string, unknown>>): JSX.Element => {
  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: learningTypePickerTemplate.name });
  };

  const backDrawerClick = (): void => {
    closeDrawerClick();
    pushDrawer({ drawer: shareLearningDrawerTemplate });
  };

  const { openLibraryWithAssignLearningTemplate: defaultOpenLearningLibrary } = useLearningLibrary();

  const onPathwayClick = useCallback((item: AssignLearningOptions): void => {
    popDrawer({ popAll: true });
    if (item === AssignLearningOptions.START_FROM_SCRATCH) {
      setDrawerState((prev) => ({
        ...prev,
        workflow: AssignLearningOptions.START_FROM_SCRATCH,
      }));
      pushDrawer({
        drawer: createLearningTemplate,
      });
    } else if (item === AssignLearningOptions.START_WITH_A_TEMPLATE) {
      setDrawerState((prev) => ({
        ...prev,
        workflow: AssignLearningOptions.START_WITH_A_TEMPLATE,
      }));
      defaultOpenLearningLibrary();
    }
  }, [popDrawer, setDrawerState, pushDrawer, defaultOpenLearningLibrary]);

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title="Select Learning Type"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
        renderBackButton={(backButtonStyles: SerializedStyles) => (
          <IconButton onClick={backDrawerClick} type="button" icon={faArrowLeft} css={backButtonStyles} tooltip="Back" />
        )}
        renderDrawerInstructions={(drawerInstructionsStyles) => (
          <div css={drawerInstructionsStyles}>
            Would you like to start with a template or begin creating it from scratch?
          </div>
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div
        css={defaultBodyPadding}
      >
        <div>
          {options.map((option) => (
            <DrawerOptionSelectItem
              key={option.content}
              onClick={() => onPathwayClick(option.content)}
              css={styles.listItem}
              icon={option.icon}
              content={option.content}
              data-test-id={option.dataTestId}
            />
          ))}
        </div>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: learningTypePickerTemplate.name,
  component: LearningTypePicker,
});

export default LearningTypePicker;
