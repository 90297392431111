import {
  LegacyRef,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import TabDrawerHeader from '~Common/V3/components/Drawers/TabDrawerHeader';
import TabNavItem from '~Common/V3/components/Drawers/TabNavItem';
import { getOrganizationName } from '~Common/utils/localStorage';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';

import { truncate } from '~Common/styles/mixins';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { DropdownSkeleton } from '~Common/V3/components/Dropdown';
import DrawerCloseButtonWithConfirmation from '~Learning/components/Shared/ConfirmationButtons/DrawerCloseButtonWithConfirmation';
import { useGetLearningTemplateCategories } from '~Learning/hooks/templates/singleContent/useGetLearningTemplateCategories';
import { AssignLearningOptions, OnUseTemplateParams } from '~Learning/const/interfaces';
import { CURATED_BY_LEADR_TEXT, CURATED_BY_TABLE_GROUP_TEXT } from '~Common/const/curated';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { useOrgDetailsContext } from '~Common/V3/components/OrgDetailsContext';
import LearningLibraryDrawerTab from './LearningLibraryDrawerTab';

const styles = {
  tabNavItem: css({
    display: 'flex',
    alignItems: 'center',
  }),
  tabNavItemTruncated: truncate,
  cardSkeleton: css({
    minWidth: '100%',
    height: '6.875rem',
    ':not(:first-of-type)': {
      marginTop: '1.125rem',
    },
  }),
  drawerBody: css({
    padding: '1rem 1.5rem 3.75rem',
  }),
  dropdown: css({
    marginBottom: '.625rem',
  }),
  backButton: css({
    paddingLeft: '0px',
  }),
};

export enum LearningLibraryDrawerTabs {
  ORGANIZATION = 0,
  CURATED_BY_LEADR = 1,
  CURATED_BY_TABLE_GROUP = 2,
}

export const viewLearningLibraryDrawerTemplate = {
  name: 'VIEW_LEARNING_LIBRARY_DRAWER',
  width: DRAWER_WIDTHS.PRIMARY,
};

export interface LearningLibraryRenderBackButtonParams {
  backButtonStyles: SerializedStyles,
  closeLearningLibrary: () => void,
}

interface ViewLearningLibraryDrawerProps extends DrawerProps<ViewLearningLibraryDrawerState> {
  renderBanner?: () => JSX.Element,
  renderBackButton?: ({ backButtonStyles, closeLearningLibrary }: LearningLibraryRenderBackButtonParams) => JSX.Element,
  onUseTemplate: ({ templateId, isPlaylist }: OnUseTemplateParams) => void,
  canCreateLearningTemplate?: boolean,
}

export interface ViewLearningLibraryDrawerState {
  shouldConfirmDrawerClose?: boolean,
  workflow?: AssignLearningOptions,
}

const ViewLearningLibraryDrawer = ({
  canCreateLearningTemplate,
  renderBanner,
  renderBackButton,
  onUseTemplate,
  drawerState,
  setDrawerState,
  pushDrawer,
  popDrawer,
}: ViewLearningLibraryDrawerProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const isCuratedTab = activeTab === LearningLibraryDrawerTabs.CURATED_BY_LEADR || activeTab === LearningLibraryDrawerTabs.CURATED_BY_TABLE_GROUP;

  const { enableTheTable } = useOrgDetailsContext();
  const webAppTgxCuratedLearnings = useFeatureFlag('webAppTgxCuratedLearnings');

  const showCuratedByTheTableGroupTemplates = enableTheTable && webAppTgxCuratedLearnings;

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: viewLearningLibraryDrawerTemplate.name });
  };

  const openThisDrawer = (): void => {
    pushDrawer({
      drawer: {
        ...viewLearningLibraryDrawerTemplate,
        args: {
          canCreateLearningTemplate,
          renderBanner,
          onUseTemplate,
        },
      },
    });
  };

  const { learningTemplateCategories, isLoading: areTemplateCategoriesLoading } = useGetLearningTemplateCategories(activeTab);

  const learningTemplateCategoryItems = useMemo(() => learningTemplateCategories?.map((category) => ({
    text: category.categoryName,
    value: category.categoryId,
  })), [learningTemplateCategories]);

  const isCuratedByTableGroup = useMemo(() => activeTab === LearningLibraryDrawerTabs.CURATED_BY_TABLE_GROUP, [activeTab]);

  const handleTabChange = (tabIndex: number): void => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      isCuratedByTableGroup,
    }));
  }, [isCuratedByTableGroup, setDrawerState]);

  const hookProps = {
    renderHeader: () => (
      <TabDrawerHeader
        title="Learning Library"
        renderCloseButton={drawerState.workflow !== AssignLearningOptions.CREATE_A_LEARNING_PLAYLIST ? (closeButtonStyles: SerializedStyles) => (
          <DrawerCloseButtonWithConfirmation
            renderDrawerCloseButton={({ onClick }) => (
              <IconButton onClick={onClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
            )}
            onCloseDrawer={closeDrawerClick}
            shouldConfirmClose={drawerState.shouldConfirmDrawerClose}
          />
        ) : undefined}
        renderBackButton={(backButtonStyles: SerializedStyles) => renderBackButton?.({ backButtonStyles, closeLearningLibrary: closeDrawerClick })}
        renderTabNav={(tabNavStyles: SerializedStyles, scroller: unknown) => (
          <div css={tabNavStyles} ref={scroller as LegacyRef<HTMLDivElement>}>
            <TabNavItem
              css={styles.tabNavItem}
              isActive={activeTab === LearningLibraryDrawerTabs.ORGANIZATION}
              onClick={() => handleTabChange(LearningLibraryDrawerTabs.ORGANIZATION)}
              renderNavItem={() => (
                <div>{getOrganizationName() ?? ''}</div>
              )}
            />
            <TabNavItem
              css={styles.tabNavItem}
              onClick={() => handleTabChange(LearningLibraryDrawerTabs.CURATED_BY_LEADR)}
              isActive={activeTab === LearningLibraryDrawerTabs.CURATED_BY_LEADR}
              renderNavItem={() => (
                <div>{`Curated by ${CURATED_BY_LEADR_TEXT}`}</div>
              )}
            />
            {showCuratedByTheTableGroupTemplates && (
              <TabNavItem
                css={styles.tabNavItem}
                isActive={activeTab === LearningLibraryDrawerTabs.CURATED_BY_TABLE_GROUP}
                onClick={() => handleTabChange(LearningLibraryDrawerTabs.CURATED_BY_TABLE_GROUP)}
                renderNavItem={() => (
                  <div>{`Curated by ${CURATED_BY_TABLE_GROUP_TEXT}`}</div>
                )}
              />
            )}
          </div>
        )}
      />
    ),
    renderBody: () => (
      <div css={styles.drawerBody}>
        {areTemplateCategoriesLoading && (
          <>
            <DropdownSkeleton
              css={styles.dropdown}
            />
            <MultipleSkeletonLoaders
              numberOfSkeletons={5}
              renderSkeletonItem={() => (
                <CardSkeleton css={styles.cardSkeleton} />
              )}
            />
          </>
        )}
        {!areTemplateCategoriesLoading && (
          <LearningLibraryDrawerTab
            canCreateLearningTemplate={canCreateLearningTemplate}
            renderBanner={renderBanner}
            onUseTemplate={onUseTemplate}
            learningTemplateCategoryItems={learningTemplateCategoryItems}
            isCurated={isCuratedTab}
            isCuratedByLeadr={activeTab === LearningLibraryDrawerTabs.CURATED_BY_LEADR}
            isCuratedByTableGroup={isCuratedByTableGroup}
            setDrawerState={setDrawerState}
            key={activeTab}
            openTemplateLibrary={openThisDrawer}
          />
        )}
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: viewLearningLibraryDrawerTemplate.name,
  component: ViewLearningLibraryDrawer,
});

export default ViewLearningLibraryDrawer;
