import { useMutation } from '@tanstack/react-query';

import { createBaseballCardUserNote, updateBaseballCardUserNote } from './api';

export const useCreateBaseballCardNote = () => useMutation({
  mutationFn: createBaseballCardUserNote,
});

export const useUpdateBaseballCardNote = () => useMutation({
  mutationFn: updateBaseballCardUserNote,
});
