import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import moment from 'moment-timezone';

import PhoneNumberField from '~Common/components/Fields/PhoneNumberField';
import TextField from '~Common/components/Fields/TextField';
import DatePicker from '~Deprecated/common/Components/Pickers/DatePicker';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';
import SectionHeader from '~Deprecated/ui/components/DrawerManager/SectionHeader';
import Tooltip from '~Common/components/Tooltip';
import { useProfileEditRestrictions } from '~Common/hooks/people/useProfileEditRestrictions';
import { palette } from '~Common/styles/colors';

const styles = {
  container: css({
    marginBottom: '1.5625rem',
  }),
  field: css({
    marginBottom: '.625rem',
    background: palette.neutrals.gray200,
    fontSize: '1rem',
    color: palette.neutrals.gray800,
    fontWeight: '500',
    border: 'none',

    label: {
      textTransform: 'none',
      color: palette.neutrals.gray700,
      fontSize: '.75rem',
      fontWeight: '400',
    },
  }),
};

const View = ({
  user, birthdayDate, joiningDate, disableName,
}) => (
  <>
    <div css={styles.container}>
      <SectionHeader text="Basic Information" />
      <Tooltip content="This can only be changed by your IT administrator." disabled={!disableName}>
        <span>
          <TextField css={styles.field} label="First Name" initialValue={user.firstName} name="firstName" disabled={disableName} />
        </span>
      </Tooltip>
      <Tooltip content="This can only be changed by your IT administrator." disabled={!disableName}>
        <span>
          <TextField css={styles.field} label="Last Name" initialValue={user.lastName} name="lastName" disabled={disableName} />
        </span>
      </Tooltip>
      <PhoneNumberField css={styles.field} initialValue={user.phoneNumber} />
      <DatePicker css={styles.field} format="MMM Do" maxDate={moment()} label="Birthday" name="birthday" selectedDate={birthdayDate} />
      <DatePicker css={styles.field} label="Work Anniversary" name="joiningDate" selectedDate={joiningDate} />
      <Tooltip content="This field is only editable by your admin.">
        <span>
          <TextField css={styles.field} label="Title" initialValue={user.jobTitle} name="jobTitle" disabled />
        </span>
      </Tooltip>
      <Tooltip content="This field is only editable by your admin.">
        <span>
          <TextField css={styles.field} label="Department" initialValue={user.department} name="department" disabled />
        </span>
      </Tooltip>
    </div>
  </>
);

View.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
  birthdayDate: PropTypes.instanceOf(Date),
  joiningDate: PropTypes.instanceOf(Date),
  disableName: PropTypes.bool,
};

View.defaultProps = {
  birthdayDate: null,
  joiningDate: null,
  disableName: false,
};

const BasicInfo = ({
  user, ...props
}) => {
  const {
    isFieldDisabled: {
      name: disableName,
    },
  } = useProfileEditRestrictions();

  const birthday = useMemo(() => user.specialDays?.find((specialDay) => specialDay.type === 'BIRTHDAY'), [user.specialDays]);
  const joining = useMemo(() => user.specialDays?.find((specialDay) => specialDay.type === 'JOINING_DATE'), [user.specialDays]);
  const isLeadrCoach = user.firstName === 'Leadr' && user.lastName === 'Coach';

  let birthdayDate;
  if (birthday) {
    birthdayDate = new Date(`${birthday.month}/${birthday.date}/${birthday.year}`);
  }

  let joiningDate;
  if (joining) {
    joiningDate = new Date(`${joining.month}/${joining.date}/${joining.year}`);
  }

  const hookProps = {
    user,
    birthdayDate,
    joiningDate,
    disableName: disableName || isLeadrCoach,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

BasicInfo.propTypes = {
  user: ATTENDEE_SHAPE.isRequired,
};

BasicInfo.defaultProps = {};

export { View };
export default BasicInfo;
