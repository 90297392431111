import { css } from '@emotion/react';
import { useState, useCallback, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import {
  REVIEW_SETUP_LAYOUT, REVIEW_DISPLAY_STYLES, BUTTON_STYLES,
} from '~Reviews/V2/Const/pageStyles';
import {
  MultiRadio,
  Form,
} from '~Common/V3/components/uncontrolled';
import { palette } from '~Common/styles/colors';
import {
  UseFormReturn,
  useForm,
  SubmitHandler,
  useWatch,
} from 'react-hook-form';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import Tooltip from '~Common/components/Tooltip';
import { CustomScale } from '~Common/V3/components/uncontrolled/CustomScale';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { getOrganizationId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { HttpCallReturn } from '~Deprecated/services/HttpService';
import useFroala from '~Common/hooks/useFroala';
import { useDebounceDraftState } from '~Common/hooks/useDebounceDraftState';
import { SaveIndicator } from '~Common/V3/components/SaveIndicator';

import {
  TopicShape, TopicTypeEnum, multiChoiceTopicConfig, linearTopicConfig, ReviewShape,
  AssignedReflection, ReviewCycleTypeEnum, ReviewAnswer, ReviewStatusEnum,
} from '../Const/types';

import { FormValues, conformToDto, responseTopicForReview } from '../schemata/ResponseTopicForReview';
import { useAnswerTopic } from '../Hooks/useAnswerTopic';
import { DisplayReflection } from '../reflections/DisplayReflection';
import { useSubmitAllTopics } from '../Hooks/useSubmitAllTopics';
import { returnReviewParticipantType } from '../Hooks/returnReviewParticipantType';
import { checkDirtyFields } from '../Const/functions';
import { useAddReflectionDrawer } from '../reflections/hooks/useAddReflectionDrawer';
import { ReviewSaveState, useReviewSaveStateStore } from '../stores/useReviewSaveStateStore';

const styles = {
  ...REVIEW_SETUP_LAYOUT,
  ...REVIEW_DISPLAY_STYLES,
  ...BUTTON_STYLES,
  radio: css({
    marginBottom: '1.5rem',

    '& .MuiFormGroup-root label, & .MuiFormControlLabel-label.Mui-disabled': {
      border: 'none !important',
      background: 'none !important',
      padding: '0 !important',
      margin: '0 !important',
      color: `${palette.neutrals.gray800} !important`,
    },
    '& .MuiRadio-root': {
      padding: '.25rem .5rem .25rem 0',
    },
  }),
  additionalContextButton: css({
    fontSize: '1rem',
    fontWeight: '500',
  }),
  clearExplanationButton: css({
    margin: '1rem 0 2rem 0',
  }),
  contextButtons: css({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  }),
  showingOpenResponses: (isLastQuestion: boolean) => css({
    marginTop: '1.5rem',
    paddingBottom: '4.4375rem',
    borderBottom: `1px solid ${palette.neutrals.gray400}`,
  }, isLastQuestion && {
    borderBottom: 'none',
    paddingBottom: '0',
  }),
  questionWrapOveride: css({
    padding: '0 !important',
    border: 'none',
  }),
  explanationTitle: css({
    color: palette.neutrals.gray800,
    fontWeight: 500,
  }),
  buttonContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  saveIndicator: css({
    marginLeft: 'auto',
  }),
};

interface ViewProps {
  displayExplanation: boolean,
  formContext: UseFormReturn<FormValues>,
  isSaving: boolean,
  linearChoiceOptions: linearTopicConfig | undefined,
  multipleChoiceOptions: multiChoiceTopicConfig | undefined,
  numericResponse?: number,
  onClearExplanation: () => void,
  onClickAddReflection: () => void,
  onDisplayExplanation: (value: boolean) => void,
  reflectionRecords?: AssignedReflection[],
  reviewTopicUid: string,
  reviewUid: string,
  topic: TopicShape,
  FroalaEditor: JSX.Element,
  isSaveRetrying: boolean,
}

const View = ({
  displayExplanation,
  formContext,
  isSaving,
  linearChoiceOptions,
  multipleChoiceOptions,
  numericResponse,
  onClearExplanation,
  onClickAddReflection,
  onDisplayExplanation,
  reflectionRecords,
  reviewTopicUid,
  reviewUid,
  topic,
  FroalaEditor,
  isSaveRetrying,
}: ViewProps): JSX.Element => (
  <>
    <Form
      name={`topic-response-${topic.uid}`}
      formContext={formContext}
      onSubmit={() => null}
    >
      <div
        css={[styles.questionWrap(true), styles.questionWrapOveride]}
      >
        {topic.typeEnum === TopicTypeEnum.Freeform && (
          <div css={styles.answerDescription}>
            {FroalaEditor}
          </div>
        )}

        {topic.typeEnum === TopicTypeEnum.MultipleChoice && multipleChoiceOptions && (
          <div css={styles.radio}>
            <MultiRadio
              name="numericResponse"
              defaultValue={numericResponse}
              vertical
              label=""
              radios={multipleChoiceOptions.multipleChoiceOptions.map((option: { text: string; }, index) => ({
                name: (
                  <p>
                    <span>{option.text}</span>
                  </p>
                ),
                value: index + 1,
              }))}
            />
          </div>
        )}

        {topic.typeEnum === TopicTypeEnum.Linear && linearChoiceOptions && (
          <CustomScale
            maxValue={linearChoiceOptions.numScale}
            minLabel={linearChoiceOptions.minLabel}
            maxLabel={linearChoiceOptions.maxLabel}
            defaultValue={numericResponse}
            name="numericResponse"
            key={`${reviewTopicUid}-numericResponse`}
          />
        )}

      </div>
      {displayExplanation && (
        <div>
          {FroalaEditor}
          <LeadrButton
            variant="text"
            size="small"
            textButtonColor={palette.brand.red}
            css={styles.clearExplanationButton}
            onClick={onClearExplanation}
            data-test-id="reviewsClearExplanation"
          >
            Remove Explanation
          </LeadrButton>
        </div>
      )}
      <div
        css={styles.contextButtons}
      >
        <div css={styles.buttonContainer}>
          <div>
            {topic.typeEnum !== TopicTypeEnum.Freeform && !displayExplanation && (
              <LeadrButton
                data-test-id="reviewsAddExplanation"
                onClick={() => onDisplayExplanation(true)}
                css={styles.additionalContextButton}
                variant="text"
              >
                <Tooltip interactive content="Explanations allow you to explain your response to the question above.">
                  <div>+ Add Explanation</div>
                </Tooltip>
              </LeadrButton>
            )}
            <LeadrButton
              css={styles.additionalContextButton}
              data-test-id="reviewsAttachReflection"
              variant="text"
              textButtonColor={palette.brand.indigo}
              onClick={onClickAddReflection}
            >
              <Tooltip
                interactive
                content="Reflections allow you to reference other Leadr features within your review such as Goals, Feedback, Agenda Topics, and Learning."
              >
                <div>
                  + Attach Reflection
                </div>
              </Tooltip>
            </LeadrButton>
          </div>
          <SaveIndicator css={styles.saveIndicator} isSaving={isSaving} isSaveRetrying={isSaveRetrying} isDirty={formContext.formState.isDirty} />
        </div>
        {reflectionRecords && reflectionRecords.length > 0 && (
        <>
          <div
            css={styles.reflectionWrapper}
          >
            {reflectionRecords.map((reflection) => (
              <>
                <DisplayReflection key={reflection.uid} reflection={reflection} reviewUid={reviewUid} />
              </>
            ))}
          </div>
        </>
        )}
      </div>
    </Form>
  </>
);

interface ReviewResponseFormProps {
  answerRecord?: ReviewAnswer,
  topic: TopicShape,
  reflectionRecords?: AssignedReflection[],
  review: ReviewShape,
}

export const ReviewResponseForm = ({
  answerRecord,
  topic,
  reflectionRecords,
  review,
}: ReviewResponseFormProps): JSX.Element => {
  const { isManager, participantUid } = returnReviewParticipantType(review);
  const autoSaveDebounceTime = useFeatureFlag<number>('autoSaveDebounceTime');
  const OVERRIDEDEBOUNCESAVETIME = useFeatureFlag<number>('reviewsDisableAutoSave');
  const [isSaveRetrying, setSaveRetrying] = useState(false);

  const reviewTopicUid = topic?.uid;
  const stringResponse = answerRecord?.stringResponse ?? '';
  const numericResponse = answerRecord?.numericResponse;

  const formValues = (): FormValues => ({
    stringResponse,
    numericResponse,
  });

  const formContext = useForm<FormValues>({
    defaultValues: formValues(),
    resolver: responseTopicForReview,
  });
  // @ts-expect-error Needs fixed once this is typed
  const { setValue: setFroalaValue, Froala: FroalaEditor } = useFroala({
    name: 'stringResponse',
    label: 'Explanation',
    froalaConfigProps: {
      charCounterCount: true,
      charCounterMax: 5000,
      onBlur: () => (OVERRIDEDEBOUNCESAVETIME ? saveAnswer() : null),
    },
    richTextEditorProps: {
      name: 'stringResponse',
      onChange: ({ value: newText }: Record<string, string>) => {
        formContext.setValue('stringResponse', newText, { shouldDirty: true });
        if (!OVERRIDEDEBOUNCESAVETIME) {
          setStringResponse(newText);
        }
      },
      initialValue: stringResponse,
    },
  });

  let multipleChoiceOptions;
  let linearChoiceOptions;
  if (typeof topic?.typeConfig === 'string' && topic?.typeConfig) {
    multipleChoiceOptions = JSON.parse(topic?.typeConfig) as multiChoiceTopicConfig;
    linearChoiceOptions = JSON.parse(topic?.typeConfig) as linearTopicConfig;
  }
  const { mutate: answerTopicMutation, isPending: isSaving } = useAnswerTopic(review.uid, setSaveRetrying);

  /** used to disable the submit button if form values are not fully saved */
  const updateReviewSaveState = useReviewSaveStateStore((state: ReviewSaveState) => state.updateReviewSaveState);

  useEffect(() => {
    updateReviewSaveState(review.uid, !isSaving && !formContext.formState.isDirty);
  }, [isSaving, review.uid, updateReviewSaveState, formContext.formState.isDirty]);
  /** end */

  const { registerSubmitHandler } = useSubmitAllTopics();

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    const formData = conformToDto(data);
    const updatedAt = new Date().toISOString();

    answerTopicMutation({
      reviewUid: review.uid,
      answers: {
        ...formData,
        reviewTopicUid,
        updatedAt,
      },
    }, {
      onSuccess: () => {
        formContext.reset({}, { keepValues: true });
      },
    });
  };

  // We hook this up so we can fire it at will when the review is submitted.
  registerSubmitHandler(reviewTopicUid, () => formContext.handleSubmit(onFormSubmit)());

  const [displayExplanation, setDisplayExplanation] = useState(stringResponse !== '' && topic?.typeEnum !== TopicTypeEnum.Freeform);

  const onDisplayExplanation = (value: boolean): void => {
    setDisplayExplanation(value);
  };

  const onClearExplanation = (): void => {
    formContext.setValue('stringResponse', '', { shouldDirty: true });
    setFroalaValue('');
    setDisplayExplanation(false);
    saveAnswer();
  };

  const reviewByIdQueryKey = [getOrganizationId() ?? '', 'reviews', review.uid, '2.5'];
  const saveAnswer = useCallback((): void => {
    const cacheReview = queryClient.getQueryData(reviewByIdQueryKey) as HttpCallReturn<ReviewShape>;
    const reviewParticipantCacheData = cacheReview.response.participants.find((participant) => participant.uid === participantUid);

    if (reviewParticipantCacheData?.statusEnum !== ReviewStatusEnum.Submitted
      && reviewParticipantCacheData?.statusEnum !== ReviewStatusEnum.Finished
      && !isSaving
      && !isSaveRetrying) {
      formContext.handleSubmit(onFormSubmit)().catch(() => {
      // eslint-disable-next-line no-console
        console.log('Auto-save failed.');
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext, isSaving, isSaveRetrying]);

  const watchedData = useWatch({
    control: formContext.control,
    defaultValue: formValues(),
  });
  // The idea here is to run both through debouncer so they won't get out of order and cause issues
  // with numeric firing before the debounce time has finished on stringResponse
  // this should restart the string response timer with the numeric timer which is okay since the user has clearly stopped typing
  const [, setStringResponse] = useDebounceDraftState(formContext.getValues('stringResponse'), saveAnswer, autoSaveDebounceTime ?? 3000);
  const [, setNumericResponse] = useDebounceDraftState(formContext.getValues('numericResponse'), saveAnswer, 50);

  useDeepCompareEffect(() => {
    const isNumericResponseType: boolean = checkDirtyFields(formContext.formState.dirtyFields, 'numericResponse');
    // Doing this because we do not have an onChange function for non-controlled forms
    if (formContext.formState.isDirty && isNumericResponseType) {
      setNumericResponse(formContext.getValues('numericResponse'));
    }
  }, [watchedData]);

  const { openDrawer } = useAddReflectionDrawer();

  const onClickAddReflection = (): void => {
    openDrawer({
      reviewUid: review.uid,
      reviewTopicUid,
    });
  };

  const hookProps = {
    displayExplanation,
    formContext,
    isSaving,
    linearChoiceOptions,
    multipleChoiceOptions,
    numericResponse,
    onClearExplanation,
    onClickAddReflection,
    onDisplayExplanation,
    reflectionRecords,
    reviewTopicUid,
    reviewUid: review.uid,
    topic,
    isOpenReview: review.reviewCycle.typeEnum === ReviewCycleTypeEnum.Open,
    isManager,
    review,
    FroalaEditor,
    isSaveRetrying,
  };

  return (
    <View
      {...hookProps}
    />
  );
};
