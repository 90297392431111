import auth from '../auth';
import user from '../user';
import feedback from '../feedback';
import content from '../content';
import error from '../error';
import loaderState from '../loaderState';
import { initRootReducer } from './index';

function initLegacyReducers(store) {
  const legacyReducers = {
    auth,
    user,
    feedback,
    content,
    error,
    loaderState,
  };

  initRootReducer(store, legacyReducers);
}

export { initLegacyReducers };
