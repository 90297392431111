import {
  ChangeEvent,
  FC, MouseEventHandler, useEffect, useMemo, useState,
} from 'react';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter as faFilterLight } from '@fortawesome/pro-light-svg-icons';
import { faFilter as faFilterSolid } from '@fortawesome/pro-solid-svg-icons';
import { PeoplePerspective } from '~Insights/const/types';

import { User } from '~Common/const/interfaces';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import UserGroupsPagination from '~UserGroups/components/UserGroupsPage/UserGroupsPagination';
import { palette } from '~Common/styles/colors';
import { UserPerspective } from '~Insights/hooks/useInsightsUserPerspective';
import { getOrganizationName } from '~Common/utils/localStorage';
import { MetricsForPerson } from '~Insights/hooks/usePeopleInsightsData';
import SkeletonLoader from '~Common/components/SkeletonLoader';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import Button from '~Common/V3/components/Button';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { FilterType, peopleMetricsFiltersTemplate } from './FilterDrawer';
import { ActivityGroup } from './Activity/ActivityGroup';
import { FeedbackGroup } from './Feedback/FeedbackGroup';
import { GoalGroup } from './Goal/GoalGroup';
import { OneOnOneGroup } from './OneonOne/OneonOneGroup';

function makeFilteredOrgUserIds(allPeople: User[], filterType: FilterType, filterValue: string, searchText: string): string[] {
  let filteredPeople = allPeople;

  if (filterType === FilterType.Department) {
    filteredPeople = filteredPeople.filter((person) => person.department === filterValue);
  } else if (filterType === FilterType.Manager) {
    filteredPeople = filteredPeople.filter((person) => person.managerId === filterValue);
  } else if (filterType === FilterType.UserGroup) {
    filteredPeople = filteredPeople.filter((person) => person.teams.map((team) => team.teamId).includes(filterValue));
  }

  if (searchText !== '') {
    filteredPeople = filteredPeople.filter((person) => (`${person.firstName} ${person.lastName}`).toLowerCase().includes(searchText.toLowerCase()));
  }

  return filteredPeople.map((person) => person.orgUserId);
}

const styles = {
  tableRowColor: css({
    backgroundColor: palette.neutrals.gray100,
  }),

  filterButton: css({
    borderColor: palette.neutrals.gray300,
    borderRadius: '5px',
    color: palette.brand.indigo,
    height: '3.5rem',
  }),
  peopleMetricHeader: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    paddingBottom: '1.25rem',
    p: {
      margin: '0',
    },
  }),
  skeleton: css({
    width: '100%',
    height: '2rem',
    marginTop: '1rem',
    maxWidth: '100%',
    borderRadius: '5px',
  }),
  subtitlePeopleMetricWrap: css({
    display: 'flex',
    flex: '1',
    flexWrap: 'wrap',

    div: {
      flexBasis: '100%',
    },
  }),
  subtitlePeopleMetric: css({
    color: palette.brand.indigo,
    flex: '1',
    fontSize: '1.25rem',
    display: 'flex',
    alignItems: 'center',
  }),
  wrapper: css({
    maxWidth: '100%',
    overflow: 'auto',
  }),
};

export interface SubtabComponentProps {
  pageSlice: MetricsForPerson[],
}

export interface PeopleInsightsRow {
  userData: MetricsForPerson,
}

interface ViewProps extends SubtabComponentProps {
  areFiltersActive: boolean,
  cardTitle: string,
  CardComponent: FC<SubtabComponentProps>,
  isLoading: boolean,
  page: number,
  numberOfPages: number,
  onPageChange: (e: SelectChangeEvent) => void,
  onPreviousClick: MouseEventHandler,
  onNextClick: MouseEventHandler,
  openFilterDrawer: () => void,
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void,
  userPerspective: UserPerspective,
}

const View = ({
  areFiltersActive,
  cardTitle,
  CardComponent,
  isLoading,
  page,
  pageSlice,
  numberOfPages,
  onPageChange,
  onPreviousClick,
  onNextClick,
  openFilterDrawer,
  onSearch,
  userPerspective,
}: ViewProps): JSX.Element => (
  <>
    <div>
      <div css={styles.peopleMetricHeader}>
        <div css={styles.subtitlePeopleMetricWrap}>
          <h3 css={styles.subtitlePeopleMetric}>
            {cardTitle}
          </h3>
        </div>
        <LeadrSearchField data-test-id="insightsExpandedSubTabSearchField" onChange={onSearch} />
        {userPerspective === UserPerspective.executive && (
          <Button
            css={styles.filterButton}
            variant="outline"
            color="secondary"
            onClick={openFilterDrawer}
            renderContents={() => (
              <FontAwesomeIcon
                size="lg"
                icon={areFiltersActive ? faFilterSolid : faFilterLight}
              />
            )}
          />
        )}
      </div>
      {isLoading && (
        <MultipleSkeletonLoaders
          numberOfSkeletons={5}
          renderSkeletonItem={() => (
            <SkeletonLoader
              variant="rectangular"
              css={styles.skeleton}
              renderComponent={() => <div />}
            />
          )}
        />
      )}
      {!isLoading && (
        <div css={styles.wrapper}>
          <CardComponent
            pageSlice={pageSlice}
          />
        </div>
      )}
    </div>
    {numberOfPages > 1 && (
      <UserGroupsPagination
        page={page}
        onPageChange={onPageChange}
        numberOfPages={numberOfPages}
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
      />
    )}
  </>
);

interface ExpandedSubtabProps {
  allPeople: User[],
  selectedTab: PeoplePerspective,
  peopleInsightData: MetricsForPerson[],
  userPerspective: UserPerspective,
}

const ROWS_PER_PAGE = 10;

const ExpandedSubtab = ({
  allPeople,
  selectedTab,
  peopleInsightData,
  userPerspective,
}: ExpandedSubtabProps): JSX.Element => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState('');
  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const [filterType, setFilterType] = useQueryParamState<FilterType>('insights', 'filterType', FilterType.Everyone);
  const [filterValue, setFilterValue] = useQueryParamState<string>('insights', 'filterValue', '');
  const updateFilteredOrgUserIds = (clickedFilterType: FilterType, clickedFilterValue: string): void => {
    setFilterType(clickedFilterType);
    setFilterValue(clickedFilterValue);
  };
  const filteredOrgUserIds = useMemo(
    () => makeFilteredOrgUserIds(allPeople, filterType, filterValue, searchText),
    [allPeople, filterType, filterValue, searchText],
  );

  const openFilterDrawer = (): void => {
    dispatch(pushDrawerAction({
      drawer: {
        ...peopleMetricsFiltersTemplate,
        args: {
          allPeople,
          updateFilteredOrgUserIds,
        },
      },
    }));
  };

  const filtered = useMemo(
    () => peopleInsightData.filter((person) => filteredOrgUserIds.includes(person.orgUserId)),
    [peopleInsightData, filteredOrgUserIds],
  );
  const numberOfPages = Math.ceil(filtered.length / ROWS_PER_PAGE);
  const pageSlice = filtered.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE);

  useEffect(() => {
    setPage(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered]);

  let tabTitle = 'Metrics';
  let CardComponent: FC<SubtabComponentProps>;

  switch (selectedTab) {
    case PeoplePerspective.OneOnOne:
      tabTitle = '1:1';
      CardComponent = OneOnOneGroup;
      break;
    case PeoplePerspective.Goals:
      tabTitle = 'Goals';
      CardComponent = GoalGroup;
      break;
    case PeoplePerspective.Feedback:
      tabTitle = 'Feedback';
      CardComponent = FeedbackGroup;
      break;
    case PeoplePerspective.Activity:
      tabTitle = 'Activity';
      CardComponent = ActivityGroup;
      break;
    default:
      CardComponent = () => <></>;
      break;
  }

  const organizationName = userPerspective === UserPerspective.executive ? getOrganizationName() ?? 'Organization' : 'Direct Report';
  const cardTitle = `${organizationName} ${tabTitle} Metrics`;

  const hookProps = {
    areFiltersActive: filtered.length !== peopleInsightData.length,
    cardTitle,
    CardComponent,
    isLoading: false,
    openFilterDrawer,
    numberOfPages,
    page,
    pageSlice,
    onPageChange: (event: SelectChangeEvent) => { setPage(Number(event.target.value)); },
    onPreviousClick: () => setPage(page - 1),
    onNextClick: () => setPage(page + 1),
    onSearch,
    userPerspective,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { ExpandedSubtab, View };
export default ExpandedSubtab;
