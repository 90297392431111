import { hosts } from './config';
import { getApi, patchApi } from './HttpService';

export const getPersonalityDetailsById = (id) => {
  const serverUrl = {
    host: hosts.feedback,
    uri: `/referenceData/personalities/${id}`,
  };
  return getApi(serverUrl, {});
};

export const loadPersonalities = () => {
  const serverUrl = {
    host: hosts.feedback,
    uri: '/referenceData/personalities',
  };
  return getApi(serverUrl, {}, {});
};

export const updatePersonalityDetailsById = (data) => {
  const body = {
    commentary: data.commentary,
    label: data.label,
  };
  const serverUrl = {
    host: hosts.feedback,
    uri: `/referenceData/personalities/${data.personalityId}`,
  };
  return patchApi(serverUrl, body, {});
};
