import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import QuestionDisplay from '~Common/components/Drawers/QuestionDisplay';
import AnswerDisplay from '~Common/components/Drawers/AnswerDisplay';
import { FEEDBACK_SHAPE, PERSON } from '~Common/const/proptypes';
import { ANSWER_STATUS } from '~Common/const/answerStatus';
import { noop } from '~Deprecated/utils/';
import { drawerInputDisabled, drawerInputText } from '~Deprecated/ui/styles/colors';
import { BUTTON_COLOR } from '~Common/components/Buttons/Button';
import AddToMeetingButton from '~Common/components/Buttons/AddToMeeting';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import Froala from '~Common/components/Fields/RichTextEditors/Froala';
import DrawerButton from '~Deprecated/ui/components/Buttons/DrawerButton';
import { BUTTON_VARIANTS } from '~Common/const/buttonVariants';
import { usePrintView } from '~Deprecated/hooks/usePrintView';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import { REVIEWS } from '~Common/const/routes';
import PersonDisplay from './PersonDisplay';

const styles = {
  question: css`
    margin-top: 20px;
    margin-bottom: 20px;
  `,
  response: css`
    margin-top: 8px;
    margin-bottom: 8px;
    background: ${drawerInputDisabled};

    & > div {
      color: ${drawerInputText} !important;
    }
  `,
  printButton: css`
    margin-bottom: 1.125rem;
  `,
};

const View = ({
  answeredFirstSortedPeople, onAddToMeeting, renderRightButton, introduction, renderEditor, onPrintFeedback, printFeedbackFlag, isReviewArea,
}) => (
  <div>
    {printFeedbackFlag && !isReviewArea && (
      <DrawerButton
        css={styles.printButton}
        text="Print Feedback"
        iconName={faPrint}
        onClick={onPrintFeedback}
        variant={BUTTON_VARIANTS.NEUTRAL}
      />
    )}
    {introduction && (
      <>
        {renderEditor()}
      </>
    )}
    {answeredFirstSortedPeople.map((person) => (
      <React.Fragment key={person.userId}>
        <div>
          <PersonDisplay
            userId={person.userId}
            status={person.hasCompletedFeedback ? ANSWER_STATUS.COMPLETED : ANSWER_STATUS.INCOMPLETE}
            css={styles.view}
            renderRightButton={() => renderRightButton(person)}
          />
        </div>
        {person.questions?.map((question) => (
          <div key={question.questionId} css={styles.question}>
            <QuestionDisplay
              userId={person.userId}
              richText={question?.questionText || ''}
            />
            <AnswerDisplay
              userId={person.userId}
              richText={question?.responseText || '<em>Waiting for Response...</em>'}
              status={person.hasCompletedFeedback ? ANSWER_STATUS.COMPLETED : ANSWER_STATUS.INCOMPLETE}
              css={styles.response}
              key={person.userId}
              onClick={() => onAddToMeeting({ otherUserId: person.userId, questions: [question] })}
              tooltip="Add this response to the next meeting"
            />
          </div>
        ))}
      </React.Fragment>
    ))}
  </div>
);

View.propTypes = {
  answeredFirstSortedPeople: PropTypes.arrayOf(PERSON),
  onAddToMeeting: PropTypes.func,
  renderRightButton: PropTypes.func.isRequired,
  introduction: PropTypes.string.isRequired,
  renderEditor: PropTypes.func.isRequired,
  onPrintFeedback: PropTypes.func.isRequired,
  printFeedbackFlag: PropTypes.bool.isRequired,
  isReviewArea: PropTypes.bool,
};

View.defaultProps = {
  answeredFirstSortedPeople: null,
  onAddToMeeting: noop,
  isReviewArea: false,
};

const ResponsesByPerson = ({
  feedback, onAddToMeeting, ...props
}) => {
  const printFeedbackFlag = useFeatureFlag('printFeedback');
  const history = useHistory();
  const isReviewArea = history.location.pathname.startsWith(REVIEWS);
  const { onPrint } = usePrintView();

  const answeredFirstSortedPeople = useMemo(() => feedback.groupByPeople?.sort((person, person2) => {
    if (person.hasCompletedFeedback === person2.hasCompletedFeedback) {
      return 0;
    } if (person.hasCompletedFeedback) {
      return -1;
    }
    return 1;
  }), [feedback.groupByPeople]);

  const renderRightButton = (person) => (
    person.hasCompletedFeedback ? (
      <AddToMeetingButton
        buttonColor={BUTTON_COLOR.DEFAULT}
        tooltip="Add all responses to the next meeting"
        onClick={() => onAddToMeeting({ otherUserId: person.userId, questions: person.questions })}
      />
    ) : null
  );

  const renderEditor = () => (
    <Froala
      label="Objective"
      name="introduction"
      initialValue={feedback.introduction || ''}
      isReadOnly
    />
  );

  const hookProps = {
    answeredFirstSortedPeople,
    renderRightButton,
    onAddToMeeting,
    introduction: feedback.introduction,
    renderEditor,
    onPrintFeedback: () => onPrint({ location: `feedback/${feedback.id}/byPerson` }),
    printFeedbackFlag,
    isReviewArea,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

ResponsesByPerson.propTypes = {
  feedback: FEEDBACK_SHAPE,
  onAddToMeeting: PropTypes.func,
};

ResponsesByPerson.defaultProps = {
  feedback: null,
  onAddToMeeting: noop,
};

export { View };
export default ResponsesByPerson;
