import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '~Deprecated/ui/components/List';
import CardListWithControls from '~Deprecated/ui/components/Layouts/CardListWithControls';
import SearchBox from '~Deprecated/ui/components/Inputs/SearchBox';
import Button from '~Deprecated/ui/components/Buttons/Button';
import Toggle from '~Deprecated/ui/components/Inputs/Switch';
import Input, { INPUT_TYPES } from '~Deprecated/ui/components/Inputs/Input';
import { usePrevious } from '~Deprecated/hooks/usePrevious';
// TODO: We need a better way to alert
import useAlert from '~Deprecated/hooks/useAlert';

import DetailDrawerContainer from '~Deprecated/ui/containers/Nexus/OrganizationDetailDrawer';
import { getFeatureFlags } from '~Deprecated/selectors/featureFlags/getFeatureFlags';
import { withoutDesktop } from '~Deprecated/ui/styles/mixins';
import { createEditOrganizationDrawerTemplate } from '~Nexus/components/drawers/CreateEditOrganizationDrawer';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { palette } from '~Common/styles/colors';
import { useOrgUserPermissionsContext } from '~Common/V3/components/OrgUserPermissionsContext';
import OrganizationDetailDrawer from './Drawers/OrganizationDetailDrawer';
import Alert from '../../../components/Alert';
import OrganizationCard from './OrganizationCard';
import { useNexusOrgStore } from './useNexusOrgStore';

const DetailDrawer = DetailDrawerContainer(OrganizationDetailDrawer);

const style = css`
  min-width: 360px;
  width: 55%;
  align-self: center;

  ${withoutDesktop(`
    width: 90%;
  `)}
`;

const disabled = css`
  cursor: not-allowed;
  background-color: ${palette.neutrals.gray500};
  border-color: ${palette.neutrals.gray500};

  > svg {
    stroke: ${palette.neutrals.white};
  }
`;

const listStyle = css`
  padding: 0 5px;
  justify-content: center;
  align-items: center;

  & .card {
    width: calc(100% - 20px);
    margin: 25px 0;
  }
`;

const controlStyle = css`
  padding-top: 20px;
  width: 100%;
  margin-bottom: 10px;
  position: sticky;
  top: 50px;
`;

const contentRow = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const toggleStyle = css`
  padding-top: 8px;
`;

export const nexusSortOptions = {
  0: {
    value: 0,
    text: 'Name',
    data: 'name',
  },
  1: {
    value: 1,
    text: 'Size',
    data: 'size',
  },
};

const NexusOrganizations = ({
  ids,
  totalCount,
  loadOrganizationInsightsAction,
  hasPendingCall,
  success,
  error,
  clearOrganizationInsightsAction,
}) => {
  const dispatch = useDispatch();
  const { isCustomerSuccessAdmin: canCreateOrgs } = useOrgUserPermissionsContext();

  const {
    searchKeyword,
    setSearchKeyword,
    shouldFilterByFavorites,
    setShouldFilterByFavorites,
    sortBy,
    setSortBy,
    page,
    setPage,
  } = useNexusOrgStore((state) => ({
    searchKeyword: state.searchKeyword,
    setSearchKeyword: state.setSearchKeyword,
    shouldFilterByFavorites: state.shouldFilterByFavorites,
    setShouldFilterByFavorites: state.setShouldFilterByFavorites,
    sortBy: state.sortBy,
    setSortBy: state.setSortBy,
    page: state.page,
    setPage: state.setPage,
  }));

  const [isOrgDetailOpen, toggleDetailOrg] = useState(false);
  const [drawerId, setDrawerId] = useState('');
  const [alertType, setAlertType] = useState('success');
  const useNewLayout = !!useSelector(getFeatureFlags).useNewLayout;

  const prevHasPendingCall = usePrevious(hasPendingCall);

  const { alertMessage, showAlertMessage } = useAlert();

  const onClose = () => {
    toggleDetailOrg(false);
  };

  const onNewCustomerClick = () => {
    dispatch(pushDrawerAction({
      drawer: createEditOrganizationDrawerTemplate,
    }));
  };

  const onEditOrganizationClick = (id) => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditOrganizationDrawerTemplate,
        args: {
          id,
        },
      },
    }));
  };

  const onInfoOrganizationClick = (id) => {
    toggleDetailOrg(true);
    setDrawerId(id);
  };

  useEffect(() => {
    loadOrganizationInsightsAction({
      sort: nexusSortOptions[sortBy].data,
      filterFavourites: shouldFilterByFavorites,
      page,
      searchKeyword,
    });

    return () => {
      clearOrganizationInsightsAction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOrganizationInsightsAction, searchKeyword, shouldFilterByFavorites, sortBy, clearOrganizationInsightsAction]);

  useEffect(() => {
    setPage(1);
    loadOrganizationInsightsAction({
      sort: nexusSortOptions[sortBy].data,
      filterFavourites: shouldFilterByFavorites,
      page: 1,
      searchKeyword,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  useEffect(() => {
    if (prevHasPendingCall === true && hasPendingCall === false) {
      if (success) {
        setAlertType('success');
        showAlertMessage(success);
      } else {
        setAlertType('danger');
        showAlertMessage(error);
      }
    }
  }, [prevHasPendingCall, hasPendingCall, setAlertType, showAlertMessage, success, error]);

  useEffect(() => {
    if (prevHasPendingCall === true && hasPendingCall === false) {
      onClose();
      loadOrganizationInsightsAction({
        sort: nexusSortOptions[sortBy].data,
        filterFavourites: shouldFilterByFavorites,
        page,
        searchKeyword,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevHasPendingCall, hasPendingCall, loadOrganizationInsightsAction, searchKeyword, shouldFilterByFavorites, sortBy]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    loadOrganizationInsightsAction({
      sort: nexusSortOptions[sortBy].data,
      filterFavourites: shouldFilterByFavorites,
      page: nextPage,
      searchKeyword,
    });
  };
  return (
    <div
      css={style}
    >
      { alertMessage && <Alert className="alert-box" type={alertType} message={alertMessage} /> }
      <DetailDrawer
        id={drawerId}
        isOpen={isOrgDetailOpen}
        onClose={onClose}
      />
      <CardListWithControls
        useNewLayout={useNewLayout}
        list={(
          <List
            css={listStyle}
            ids={ids}
            renderTemplate={({ id }) => (
              <OrganizationCard
                id={id}
                key={id}
                onEdit={onEditOrganizationClick}
                onInfo={onInfoOrganizationClick}
              />
            )}
          />
        )}
        controls={(
          <div
            css={controlStyle}
          >
            <div
              css={contentRow}
            >
              <div>
                <SearchBox
                  placeholder="Search"
                  onChange={(value) => {
                    setSearchKeyword(value);
                  }}
                />
              </div>
              <div>
                { canCreateOrgs && (
                  <Button
                    onClick={onNewCustomerClick}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    New Customer
                  </Button>
                ) }
              </div>
            </div>
            <div
              css={contentRow}
            >
              <div>
                <Toggle
                  checked={shouldFilterByFavorites}
                  css={toggleStyle}
                  label="Starred"
                  onChange={(checked) => {
                    setShouldFilterByFavorites(checked);
                  }}
                />
              </div>
              <div>
                <Input
                  type={INPUT_TYPES.SELECT}
                  onChange={(event) => {
                    setSortBy(event.target.value);
                  }}
                  label="Sort"
                  value={sortBy}
                  name="Sort"
                  items={Object.values(nexusSortOptions)}
                />
              </div>
            </div>
          </div>
        )}
        footer={ids.length !== 0 ? (
          <div className="row">
            <div className="col-12 text-center mb-3 mt-3">
              <Button
                onClick={loadMore}
                disabled={ids.length === totalCount}
                css={ids.length === totalCount ? disabled : null}
              >
                Load More
              </Button>
            </div>
          </div>
        ) : null}
      />

    </div>
  );
};

NexusOrganizations.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalCount: PropTypes.number,
  loadOrganizationInsightsAction: PropTypes.func.isRequired,
  hasPendingCall: PropTypes.bool,
  success: PropTypes.string,
  error: PropTypes.string,
  clearOrganizationInsightsAction: PropTypes.func.isRequired,
};

NexusOrganizations.defaultProps = {
  hasPendingCall: false,
  success: null,
  error: null,
  totalCount: 0,
};

export default memo(NexusOrganizations);
