import { HTMLString } from '~Common/types';
import { UserGroup } from './userGroups';
import { UserOrgDetails } from './Organization';

/*
  This is a type that only requires the firstName and lastName
  The profileImageUrl is optional, which is taken from the Partial<Person> type
  id is also optional, it isn't included in the Person type, but some apis pass a users id as just id
  This is used in the AvatarMaybeMultipleMaybeNot component,
    setting it up like this, should make it so we have to do less mapping of people data to be able to use it with the AvatarMaybeMultipleMaybeNot component
*/
export interface PersonDisplayInformation extends Partial<Person> {
  id?: string,
  uid?: string,
  firstName: string,
  lastName: string,
}

// Used for structured return by people API
// Not to be confused by User, which is returned by users API
// THESE TWO INTERFACES HAVE SUBTLE DIFFERENCES MAKE SURE YOU ARE USING THE RIGHT ONE
export interface Person {
  firstName: string,
  lastName: string,
  jobTitle: string,
  profileImageUrl?: string,
  administrativeStatus: 'ACTIVE' | 'CREATED' | 'INVITED' | 'INACTIVE',
  applicationGroupName: UserGroup,
  department: string,
  directsCount: number,
  eligibleForBenefits: boolean,
  email: string,
  employmentType?: string,
  importedAtInMillis: number,
  invitedAtInMillis: number,
  isDisabled: boolean,
  isExecutive: boolean,
  isVerified: boolean,
  lastActivityInMillis: number,
  managerEmail: string,
  managerId: string,
  mentors: string[],
  orgUserId: string,
  userId: string,
  teams: {
    teamId: string,
    teamName: string,
    userId: string,
  }[],
  userGroupId: string,
  userGroupName: UserGroup,
}

// Used for structured return by user API
// Not to be confused by Person, which is returned by people API
// THESE TWO INTERFACES HAVE SUBTLE DIFFERENCES MAKE SURE YOU ARE USING THE RIGHT ONE
export interface User {
  administrativeStatus: string,
  applicationGroupName: string,
  department: string,
  directsCount: number,
  eligibleForBenefits: boolean,
  email: string,
  firstName: string,
  id:string,
  importedAtInMillis: number,
  invitedAtInMillis: number,
  isDisabled: boolean,
  isVerified: boolean,
  isExecutive: boolean,
  jobTitle: string,
  lastActivityInMillis:number,
  lastName: string,
  managerEmail: string,
  managerId: string,
  orgUserId: string,
  profileImageUrl?: string,
  mentors?: string[],
  teams: {
    teamId: string,
    teamName: string,
    userId: string
  }[],
  userGroupId: string,
  userGroupName: UserGroup,
}

export interface PeoplePickerById extends Person {
  isLoading: boolean;
}

export enum ViewType {
  GRID = 'GRID',
  LIST = 'LIST',
}

export enum ApplicationGroupType {
  LIMITED_ACCESS = 'LIMITED ACCESS',
  EXECUTIVE = 'EXECUTIVE',
  MEMBER = 'MEMBER',
  ADMIN = 'ADMIN',
}

export enum SortDirection {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum UserStatus {
  active = 'ACTIVE',
  created = 'CREATED',
  invited = 'INVITED',
  inactive = 'INACTIVE',
}

export enum SendMeetingReminderType {
  Days = 'DAYS',
  Hours = 'HOURS'
}

export enum SendActionItemReminderType {
  Days = 'DAYS',
  Hours = 'HOURS'
}

export enum SpecialDayType {
  JoinDate = 'JOINING_DATE',
  Birthday = 'BIRTHDAY'
}

export interface SpecialDay {
  month: number,
  day: number,
  year: number,
  type: SpecialDayType,
}

export interface Personality {
  personalityId: string,
  label: string
  link?: string,
}

export interface WorkingGeniusPersonality extends Personality {
  skills: string[],
  workingCompetencies: string[],
  workingFrustrations: string[],
}

export interface ProfilePersonalities {
  'Myers Briggs': Personality,
  Enneagram: Personality,
  DiSC: Personality,
  'Strength Finder': Personality,
  'Working Genius': WorkingGeniusPersonality,
}

export enum SpecialDaySummaryEmailFilter {
  Everyone = 'EVERYONE',
  Department = 'DEPARTMENT',
  Directs = 'DIRECTS',
  Downline = 'DOWNLINE',
  None = 'NONE',
}

export interface OrgUserSettings {
  orgUserSettingsId: string,
  enableSummaryEmail: boolean,
  useSettingsTimezone: boolean,
  timezone: string,
  includeSpecialDaysInSummaryEmailFor: SpecialDaySummaryEmailFilter,
}

export enum EmploymentType {
  FullTime = 'full-time',
  PartTime = 'part-time',
  Other = 'other',
}

export interface Team {
  name: string,
  teamName: string,
  teamId?: string,
}

interface OrgUserProfile {
  id: string,
  customFavorite1Value: string,
  customFavorite2Value: string,
  customPersonality1Value: string,
  customPersonality1UrlValue: string,
  customPersonality2Value: string,
  customPersonality2UrlValue: string,
}

export interface Profile {
  aboutMe: HTMLString,
  applicationGroupId: string,
  applicationGroupName: string,
  applicationGroupType: ApplicationGroupType,
  countActiveDirects: number,
  department: string,
  drink: string,
  eligibleForBenefits: boolean,
  email: string,
  employmentType: EmploymentType,
  firstName: string,
  food: string,
  instagram: string,
  isExecutive: boolean,
  jobTitle: string,
  lastActivityInMillis: number,
  lastName: string,
  linkedIn: string,
  managerFirstName: string,
  managerLastName: string,
  managerUserId: string,
  organizationUserId: string,
  phoneNumber: string,
  profileImageUrl: string,
  sendActionItemReminderType: SendActionItemReminderType,
  sendActionItemReminderValue: number
  sendMeetingReminderType: SendMeetingReminderType,
  sendMeetingReminderValue: number,
  shouldSendActionItemReminder: boolean,
  shouldSendAssignedActionItemNotification: boolean,
  shouldSendMeetingReminder: boolean,
  shouldSendTimezoneTrackerNotification: boolean,
  snacks: string,
  twitter: string,
  userGroupId: string,
  userGroupName: UserGroup,
  userId: string,
  hasDirects: boolean,
  specialDays: SpecialDay[],
  orgUserProfile: OrgUserProfile,
  personalizedCallouts: { message: string }[], // Not sure what tall else should be in here
  personalities: ProfilePersonalities,
  orgDetails: UserOrgDetails,
  orgUserSettings: OrgUserSettings,
  teams: Team[],
  mentors: string[],
  strengths?: string[],
  workingFrustrations: string[],
  workingCompetencies: string[],
  workingGeniuses: string[],
}

export interface CustomPersonalities {
  enabled: boolean,
  link: string,
  title: string,
  urlValueName: string,
  value: string,
  valueName: string,
}

export interface CommonComponentSharedProps {
  ['data-test-id']: string,
}

export enum NoteContextType {
  AgendaItem = 'AGENDA_ITEM',
  ActionItem = 'ACTION_ITEM',
  TeamMeeting = 'TEAM_MEETING',
  User = 'USER',
}

export interface NoteContext {
  type: NoteContextType,
  id: string
  meetingId?: string,
}

export interface NoteData {
  text: string,
  rank: number,
  isPrivate?: boolean,
}

export interface Note {
  id: string,
  creator: string,
  rank: number,
  text: string,
  createdInMillis: number,
  lastModifiedInMillis?: number,
}

export enum HealthReportMetricType {
  Coaching = 'coaching',
  Goal = 'goal',
  Feedback = 'feedback',
  Activity = 'activity',
}

export enum InsightsStatusSize {
  Small = 0,
  Large = 1,
  ExtraSmall = 2,
}
