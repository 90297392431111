import { ComponentProps, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';

import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import DeleteConfirmationButtons from '~Common/V3/components/DeleteConfirmation/DeleteConfirmationButtons';
import Tooltip from '~Common/components/Tooltip';
import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { NoteContextType } from '~Common/const/interfaces';
import { useDeleteActionItemComment } from '~ActionItems/hooks/useDeleteActionItemComment';
import { lighten } from 'polished';

const styles = {
  commentControls: css({
    display: 'flex',
    padding: '0.625rem',
    borderRadius: '5px',
    background: palette.neutrals.white,
    border: `1px solid ${palette.neutrals.gray200}`,
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.07)',
  }),
  icon: css({
    fontSize: '1rem',
  }),
  iconButton: css({
    color: palette.brand.indigo,
    border: 0,
    background: 'transparent',
    padding: 0,

    '&:disabled': {
      color: lighten(0.3, palette.brand.indigo),
      cursor: 'not-allowed',
    },
  }),
  editButton: css({
    marginRight: '1.25rem',
  }),
};

export interface CommentFormData {
  comment: string,
}

export interface ViewProps extends Omit<ComponentProps<'div'>, 'onSubmit'> {
  onEdit: () => void,
  onDelete: () => void,
  canEdit: boolean,
}

const View = ({
  onEdit,
  onDelete,
  canEdit,
  ...props
}: ViewProps): JSX.Element => (
  <div
    css={styles.commentControls}
    {...props}
  >
    <Tooltip content="Edit this comment">
      <button
        data-test-id="commentEditButton"
        css={[styles.iconButton, styles.editButton]}
        onClick={onEdit}
        disabled={!canEdit}
      >
        <FontAwesomeIcon
          css={styles.icon}
          icon={faPencil}
        />
      </button>
    </Tooltip>

    <DeleteButtonWithConfirmation
      renderDeleteButton={({ onClick }) => (
        <Tooltip
          content="Delete this comment"
        >
          <button
            data-test-id="commentDeleteButton"
            css={styles.iconButton}
            onClick={onClick}
          >
            <FontAwesomeIcon
              css={styles.icon}
              icon={faTrash}
            />
          </button>
        </Tooltip>
      )}
      renderConfirmationButtons={({
        informationStyles,
        optionStyles,
        popoverButtonStyles,
      }) => (
        <DeleteConfirmationButtons
          onDelete={onDelete}
          informationStyles={informationStyles}
          optionStyles={optionStyles}
          popoverButtonStyles={popoverButtonStyles}
        />
      )}
    />
  </div>
);

export interface ActionItemComments extends Omit<ComponentProps<'div'>, 'onSubmit'> {
  commentId: string,
  actionItemId: string,
  onEdit: () => void,
  canEdit?: boolean,
}

const CommentControls = ({
  commentId,
  actionItemId,
  onEdit,
  canEdit = true,
  ...props
}: ActionItemComments): JSX.Element => {
  const { mutate: doDelete } = useDeleteActionItemComment();

  const onDelete = useCallback(() => {
    doDelete({
      id: commentId,
      context: { type: NoteContextType.ActionItem, id: actionItemId },
    });
  }, [commentId, actionItemId, doDelete]);

  const hookProps = {
    onEdit,
    onDelete,
    canEdit,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CommentControls;
