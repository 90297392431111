import { useCallback, useMemo } from 'react';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useDispatch } from 'react-redux';
import { navigateAction } from '~Deprecated/actions/navigate';
import { TEAMS } from '~Common/const/routes';
import { getArrayDifferenceAsNewArray } from '~Common/utils';
import { useEditTeam } from '../useEditTeam';
import { useGetTeamDetails } from '../useGetTeamDetails';

interface UseGetTeamClarityQuestionsParams {
  teamId: string,
}

interface HandleEditTeamParams {
  newMemberOrgUserIds: string[],
  newOwnerOrgUserIds: string[],
}

interface UseTeamUserActionsReturn {
  changeUsersToOwner: (orgUserIds: string[]) => void,
  changeUsersToMember: (orgUserIds: string[]) => void,
  removeUsersFromTeam: (orgUserIds: string[]) => void,
}

export const useTeamUserActions = ({ teamId }: UseGetTeamClarityQuestionsParams): UseTeamUserActionsReturn => {
  const { data } = useGetTeamDetails({ teamId });
  const teamDetails = useMemo(() => data?.response, [data]);
  const dispatch = useDispatch();

  const {
    mutate: editTeamMutation,
  } = useEditTeam({
    handleCompletion: (_, variables) => {
      // If the current logged in user removes themselves from the team, navigate back to the teams table page
      if (teamDetails) {
        const currentOrgUserId = getOrganizationUserId() ?? '';
        if (
          (teamDetails.memberOrgUserIds.includes(currentOrgUserId) || teamDetails.ownerOrgUserIds.includes(currentOrgUserId))
          && !variables.editTeamPaylod.memberOrgUserIds.includes(currentOrgUserId)
          && !variables.editTeamPaylod.ownerOrgUserIds.includes(currentOrgUserId)
        ) {
          dispatch(navigateAction({
            pathname: TEAMS,
          }));
        }
      }
    },
  });

  const handleEditTeam = useCallback(({
    newMemberOrgUserIds,
    newOwnerOrgUserIds,
  }: HandleEditTeamParams): void => {
    if (teamDetails) {
      const {
        id,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        canEdit,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        thematicGoalQuestionRank,
        ...otherTeamDetails
      } = teamDetails;

      editTeamMutation({
        teamId: id,
        editTeamPaylod: {
          ...otherTeamDetails,
          memberOrgUserIds: newMemberOrgUserIds,
          ownerOrgUserIds: newOwnerOrgUserIds,
        },
      });
    }
  }, [editTeamMutation, teamDetails]);

  const changeUsersToOwner = useCallback((orgUserIds: string[]): void => {
    if (teamDetails) {
      const newMemberOrgUserIds = getArrayDifferenceAsNewArray(teamDetails.memberOrgUserIds, orgUserIds);
      const newOwnerOrgUserIds = teamDetails.ownerOrgUserIds.concat(orgUserIds);

      handleEditTeam({ newMemberOrgUserIds, newOwnerOrgUserIds });
    }
  }, [teamDetails, handleEditTeam]);

  const changeUsersToMember = useCallback((orgUserIds: string[]): void => {
    if (teamDetails) {
      const newMemberOrgUserIds = teamDetails.memberOrgUserIds.concat(orgUserIds);
      const newOwnerOrgUserIds = getArrayDifferenceAsNewArray(teamDetails.ownerOrgUserIds, orgUserIds);

      handleEditTeam({ newMemberOrgUserIds, newOwnerOrgUserIds });
    }
  }, [teamDetails, handleEditTeam]);

  const removeUsersFromTeam = useCallback((orgUserIds: string[]): void => {
    if (teamDetails) {
      const newMemberOrgUserIds = getArrayDifferenceAsNewArray(teamDetails.memberOrgUserIds, orgUserIds);
      const newOwnerOrgUserIds = getArrayDifferenceAsNewArray(teamDetails.ownerOrgUserIds, orgUserIds);

      handleEditTeam({ newMemberOrgUserIds, newOwnerOrgUserIds });
    }
  }, [teamDetails, handleEditTeam]);

  return {
    changeUsersToOwner,
    changeUsersToMember,
    removeUsersFromTeam,
  };
};
