import { css } from '@emotion/react';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';

import { palette } from '~Common/styles/colors';
import GoalContextTypeDropdown, { GoalContextTypeDropdownProps } from '~Home/components/Module/Goals/Filters/GoalContextTypeDropdown';

const styles = {
  label: css({
    color: palette.neutrals.gray700,
    fontSize: '.75rem',
  }),
  dropdown: css({
    width: 'min-content',
  }),
};

interface ViewProps extends Pick<GoalContextTypeDropdownProps, 'items' | 'selectedContextType' | 'onContextTypeChange'> {
  isMobile: boolean,
}

const View = ({
  items,
  isMobile,
  selectedContextType,
  onContextTypeChange,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    {isMobile && (
      <div>
        <p css={styles.label}>Type</p>
        <GoalContextTypeDropdown
          css={styles.dropdown}
          data-test-id="meetingsGoalsContextTypeDropdown"
          items={items}
          selectedContextType={selectedContextType}
          onContextTypeChange={onContextTypeChange}
          renderValue={(value) => (<span>{items.find((item) => item.value === value)?.text}</span>)}
        />
      </div>
    )}
    {!isMobile && (
      <GoalContextTypeDropdown
        css={styles.dropdown}
        data-test-id="meetingsGoalsContextTypeDropdown"
        items={items}
        selectedContextType={selectedContextType}
        onContextTypeChange={onContextTypeChange}
        renderValue={(value) => (<span>{items.find((item) => item.value === value)?.text}</span>)}
      />
    )}
  </div>
);

type ReactiveGoalContextTypeDropdownProps = Pick<GoalContextTypeDropdownProps, 'items' | 'selectedContextType' | 'onContextTypeChange'>;

const ReactiveGoalContextTypeDropdown = ({ ...props }: ReactiveGoalContextTypeDropdownProps): JSX.Element => {
  const isMobile = useIsMobileQuery();

  const hookProps = {
    isMobile,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ReactiveGoalContextTypeDropdown;
