interface SurveyUser {
  uid: string,
  firstName: string,
  lastName: string,
  fullName: string,
  jobTitle: string,
  emailAddress: string,
  profileImageUrl?: string,
}

export enum SurveyStatusValue {
  Active = 'Active',
  Closed = 'Closed',
  Completed = 'Completed',
}

export interface SurveyStatus {
  key: number,
  value: SurveyStatusValue,
}

export interface SurveyParticipant {
  completedOn: Date | null;
  id: number;
  isDeleted: boolean;
  participant: SurveyUser;
  participantUid: string;
  surveyId: number;
}

// This is what the API returns, however, it's not currently used, since we are normalizing the data in useSurveysList
export interface CreatedSurvey {
  completeBy: Date | null,
  completedOn: Date | null,
  createdBy: SurveyUser,
  createdByUid: string,
  id: number,
  isAnonymous: boolean,
  objective: string,
  organizationUid: string,
  participants: SurveyParticipant[],
  questions: unknown[], // these are empty
  status: SurveyStatus,
  surveySeriesId: null,
  title: string,
}

export interface Survey {
  id: number,
  organizationUid: string,
  surveySeriesId: number,
  title: string,
  objective: string,
  completeBy: string | null,
  completedOn: string | null,
  createdByUid: string,
  createdBy: SurveyUser,

  configuration: string, // JSON

  isAnonymous: boolean,

  status: SurveyStatus,

  participant: SurveyParticipant,

  questions: {
    answer: unknown,
    id: number,
    surveyId: number,
    type: {
      key: number,
      value: string, // MultipleChoice
    },
    configuration: JSON,
    description: string,
    question: string,
    rank: number,
  }[],
}

// Created because useSurveysList normalizes the data, ideally we get rid of the normalizing in the future
export interface NormalizedCreatedSurvey {
  participants: SurveyUser[],
  questions: unknown[], // these are empty
  id: number,
  organizationUid: string,
  surveySeriesId: null,
  title: string,
  objective: string,
  completeBy: string | null,
  completedOn: string | null,
  createdByUid: string,
  createdBy: SurveyUser,
  isAnonymous: boolean,
  status: SurveyStatus,
  surveyParticipants: SurveyParticipant[],
}

// Created because useSurveysList normalizes the data, ideally we get rid of the normalizing in the future
export interface NormalizedReceivedSurvey {
  participant: SurveyUser,
  questions: unknown[], // these are empty
  id: number,
  organizationUid: string,
  surveySeriesId: null,
  title: string,
  objective: string,
  completeBy: string | null,
  completedOn: string | null,
  createdByUid: string,
  createdBy: SurveyUser,
  isAnonymous: boolean,
  status: SurveyStatus,
  surveyParticipant: SurveyParticipant,
}

export interface modalParamsProps {
  id: string,
  name: string,
}
