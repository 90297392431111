import { css } from '@emotion/react';
import {
  ChangeEvent,
  ComponentProps,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { createEditActionItemTemplate } from '~ActionItems/components/Drawers/CreateEditActionItemDrawer';
import { forMobileTinyObject } from '~Common/styles/mixins';
import LeadrSearchField from '~Common/V3/components/LeadrSearchField';
import { useActionItemsSearch } from '~ActionItems/stores/useActionItemsSearch';
import ModuleTopbar from '~Common/V3/components/ModuleTopbar';
import CreateButton from '~Common/V3/components/ModuleTopbar/CreateButton';
import { useUserPermissions } from '~Common/hooks/user/useUserPermissions';
import ActionItemsTabNavigation from './ActionItemsTabNavigation';

const styles = {
  moduleTopbar: (hasLimitedAccess: boolean | undefined) => css({
    gridTemplateAreas: '"toggleTabs searchField rightSection"',
    gridTemplateColumns: 'auto 1fr auto',
  }, hasLimitedAccess && {
    gridTemplateAreas: '"toggleTabs searchField"',
    gridTemplateColumns: 'auto 1fr',
  }, forMobileTinyObject({
    gridTemplateAreas: `
      "toggleTabs toggleTabs"
      "searchField rightSection"
    `,
    gridTemplateColumns: '1fr auto',
  }), hasLimitedAccess && forMobileTinyObject({
    gridTemplateAreas: `
      "toggleTabs"
      "searchField"
    `,
  })),
  toggleTab: css({
    justifyContent: 'center',
  }, forMobileTinyObject({
    width: '50%',
  })),
};

export interface ActionItemsHeaderViewProps extends ComponentProps<'div'> {
  onAddActionItemClick: () => void,
  hasLimitedAccess?: boolean,
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
  initialRoute: string,
}

const View = ({
  onAddActionItemClick,
  hasLimitedAccess,
  handleSearchTextChange,
  initialRoute,
}: ActionItemsHeaderViewProps): JSX.Element => (
  <ModuleTopbar
    css={styles.moduleTopbar(hasLimitedAccess)}
    moduleTopbarLayout="custom"
    renderTabNavigationToggle={() => (
      <ActionItemsTabNavigation
        toggleTabStyles={styles.toggleTab}
        initialRoute={initialRoute}
      />
    )}
    renderSearchField={() => (
      <LeadrSearchField
        data-test-id="actionItemsSearchField"
        onChange={handleSearchTextChange}
      />
    )}
    renderRightSection={!hasLimitedAccess ? () => (
      <CreateButton
        itemName="Action Item"
        onClick={onAddActionItemClick}
        data-test-id="actionItemsCreateNew"
      />
    ) : undefined}
  />
);

interface ActionItemsHeaderProps {
  initialRoute: string,
}

const ActionItemsHeader = ({
  ...props
}: ActionItemsHeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isLimitedAccess: hasLimitedAccess } = useUserPermissions();
  const doOpenCreateDrawer = useCallback(() => {
    dispatch(pushDrawerAction({
      drawer: {
        ...createEditActionItemTemplate,
      },
    }));
  }, [dispatch]);

  const {
    setSearchText,
  } = useActionItemsSearch((state) => ({
    setSearchText: state.setSearchText,
  }));

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };

  const hookProps = {
    onAddActionItemClick: doOpenCreateDrawer,
    hasLimitedAccess,
    handleSearchTextChange,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default ActionItemsHeader;
