import { css } from '@emotion/react';
import { WhatsDueFilterOptions, whatsDueOptionsTranslation } from '~Home/const/index';
import { ComponentProps } from 'react';

const styles = {
  status: css({
    display: 'flex',
    alignItems: 'center',
  }),
  label: css({
    fontWeight: 400,
  }),
};

interface ViewProps extends ComponentProps<'div'> {
  option: WhatsDueFilterOptions;
}

const View = ({ option, ...props }: ViewProps): JSX.Element => (
  <div
    css={styles.status}
    {...props}
  >
    <span css={styles.label}>
      {whatsDueOptionsTranslation[option]}
    </span>
  </div>
);

export interface WhatsDueDropdownProps extends ComponentProps<'div'> {
  option: WhatsDueFilterOptions;
}

const WhatsDueOptions = ({ option, ...props }: WhatsDueDropdownProps): JSX.Element => {
  const hookProps = {
    option,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export default WhatsDueOptions;
