import { WorkingGeniusType } from './meetingsInterfaces';

export const WorkingGeniusMap = {
  [WorkingGeniusType.Wonder]: {
    text: 'Wonder',
  },
  [WorkingGeniusType.Invention]: {
    text: 'Invention',
  },
  [WorkingGeniusType.Discernment]: {
    text: 'Discernment',
  },
  [WorkingGeniusType.Galvanizing]: {
    text: 'Galvanizing',
  },
  [WorkingGeniusType.Enablement]: {
    text: 'Enablement',
  },
  [WorkingGeniusType.Tenacity]: {
    text: 'Tenacity',
  },
};
