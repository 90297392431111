import { css } from '@emotion/react';
import { GoalPriorities } from '~Goals/const/defaults';
import { Goals } from '@leadr-hr/types';
import { Select } from '~Common/V3/components/uncontrolled';
import MenuItem from '@mui/material/MenuItem';
import { palette } from '~Common/styles/colors';

const styles = {
  priority: css({
    minWidth: '10rem',
    color: palette.neutrals.gray700,
    fontWeight: 400,

    '.MuiSelect-select': {
      padding: '1.25rem 1rem 0.5rem 1rem',
    },
  }),
};

interface PrioritySelectionProps {
  goal: Goals.Requests.CreateGoalRequestPayload,
}

export function PrioritySelection({
  goal,
}: PrioritySelectionProps): JSX.Element {
  return (
    <>
      <Select
        css={styles.priority}
        id="priority"
        name="priority"
        defaultValue={goal.priority}
        label="Priority"
        required
      >
        {GoalPriorities.map((type) => (
          <MenuItem
            key={type.value}
            value={type.value}
          >
            {type.text}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
