import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from '~Deprecated/utils';

const View = ({
  isSelected,
  renderContent,
  ...props
}) => (
  <MenuItem {...props}>
    {renderContent(isSelected)}
  </MenuItem>
);

View.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  toggleIsSelected: PropTypes.func.isRequired,
  renderContent: PropTypes.func,
};

View.defaultProps = {
  renderContent: noop,
};

const GroupItem = ({ ...props }) => {
  const hookProps = { };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

GroupItem.propTypes = {
  initialIsSelected: PropTypes.bool,
};

GroupItem.defaultProps = {
  initialIsSelected: false,
};

export { View };
export default GroupItem;
