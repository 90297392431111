import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import { getApi, HttpCallReturn } from '~Deprecated/services/HttpService';
import { getOrganizationId } from '~Common/utils/localStorage';
import { buildQueryString } from '~Common/utils';
import { useEffect } from 'react';
import { SCReceivedLearningDetail } from '../../const/interfaces';
import { receivedLearningQueryKeys } from './queryKeys';

interface GetReceivedLearningDetailParams {
  learningId: string,
  asReadOnly?: boolean,
  viewPerspectiveUid?: string,
}

const getReceivedLearningDetail = async ({
  learningId,
  asReadOnly,
  viewPerspectiveUid, // User Id
}: GetReceivedLearningDetailParams): Promise<HttpCallReturn<SCReceivedLearningDetail>> => {
  // These currently only matter for Reviews Reflections, as a way to let users that don't own the content still view it
  const queryString = buildQueryString({
    asReadOnly,
    viewPerspectiveUid,
  });

  const serverUrl = `/organizations/${getOrganizationId() ?? ''}/learning/received/${learningId}${queryString}`;

  return getApi<SCReceivedLearningDetail>(serverUrl);
};

interface ReceivedLearningDetailHookReturn {
  detail: SCReceivedLearningDetail | undefined,
  isLoading: boolean,
}

export const useGetReceivedLearningDetail = ({
  learningId,
  asReadOnly,
  viewPerspectiveUid,
}: GetReceivedLearningDetailParams): ReceivedLearningDetailHookReturn => {
  const dispatch = useDispatch();

  const result = useQuery({
    queryKey: receivedLearningQueryKeys.singleContentDetail(learningId),
    queryFn: () => getReceivedLearningDetail({
      learningId,
      asReadOnly,
      viewPerspectiveUid,
    }),
    meta: {
      errorMessage: 'There was an error getting the learning information. Please try again.',
    },
  });

  useEffect(() => {
    if (result.isError) {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ popAll: true }));
    }
  }, [dispatch, result.isError]);

  return {
    detail: result.data?.response,
    isLoading: result.isLoading,
  };
};
