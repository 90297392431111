import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';

import { palette } from '~Common/styles/colors';

import PreviewWithExternalNavigation from '~Learning/components/Shared/ContentPreviews/PreviewWithExternalNavigation';
import LearningSectionsCard from '~Learning/components/Shared/LearningSectionsCard';
import Froala from '~Common/V3/components/Froala';
import { LearningPlaylistTemplateLearningsQuestion } from '~Learning/const/interfaces';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import HTMLRenderer from '~Common/V3/components/HTML/HTMLRenderer';
import { HTMLString } from '~Common/types';

const styles = {
  learningSectionsCard: css({
    marginTop: '2.125rem',
  }),
  title: css({
    fontSize: '1.25rem',
    fontWeight: 500,
    color: palette.neutrals.gray800,
  }),
  introduction: css({
    color: palette.neutrals.gray600,
    marginTop: '1.25rem',
  }),
  afterQuestionSpace: css({
    marginBottom: '1.5rem',
  }),
  questionsSectionTitleText: css({
    color: palette.neutrals.gray500,
    fontSize: '.625rem',
    marginTop: '2.125rem',
    marginBottom: '.625rem',
  }),
  question: css({
    marginTop: '.625rem',
  }),
};

export const learningContentDetailDrawerTemplate = {
  name: 'LEARNING_CONTENT_DETAIL_DRAWER',
  width: DRAWER_WIDTHS.BASE,
};

interface LearningContentDetailDrawerProps extends DrawerProps<unknown> {
  contentURL: string,
  title: string,
  introductionHTML: HTMLString,
  questions: LearningPlaylistTemplateLearningsQuestion[],
  clearSelectedLearning: () => void,
}

const ViewLearningPlaylistTemplateDrawer = ({
  contentURL,
  title,
  questions,
  introductionHTML,
  clearSelectedLearning,
  popDrawer,
}: LearningContentDetailDrawerProps): JSX.Element => {
  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: learningContentDetailDrawerTemplate.name });
  };

  const onCancel = (): void => {
    clearSelectedLearning();
    closeDrawerClick();
  };

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        title="Learning Content"
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={onCancel} type="button" icon={faTimes} css={closeButtonStyles} tooltip="Close" />
        )}
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <PreviewWithExternalNavigation
          contentUrl={contentURL}
        />
        <LearningSectionsCard
          css={styles.learningSectionsCard}
          renderBody={() => (
            <div>
              <div css={styles.title}>{title}</div>
              {introductionHTML && (
                <HTMLRenderer css={styles.introduction} htmlText={introductionHTML} />
              )}
            </div>
          )}
        />
        {questions && (
          <div css={styles.afterQuestionSpace}>
            <div css={styles.questionsSectionTitleText}>
              QUESTIONS
            </div>
            {questions.map((question) => (
              <div css={styles.question} key={question.questionId}>
                <Froala
                  isReadOnly
                  richTextEditorProps={{
                    initialValue: question.questionText,
                  }}
                />
              </div>
            ))}
          </div>
        )}
        <LeadrButton
          variant="ghost"
          onClick={onCancel}
          data-test-id="learningLibraryPlaylistContentCancel"
        >
          Cancel
        </LeadrButton>
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: learningContentDetailDrawerTemplate.name,
  component: ViewLearningPlaylistTemplateDrawer,
});

export default ViewLearningPlaylistTemplateDrawer;
