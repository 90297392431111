import { getApi } from '~Deprecated/services/HttpService';
import { hosts } from '~Deprecated/services/config';
import { buildQueryString } from '~Common/utils';

export const getPeople = async ({ queryKey, pageParam }) => {
  const [
    orgId,
    _,
    sort,
    targetUserId,
    searchKeyword,
    insightType,
    insightKey,
    insightValue,
  ] = queryKey;
  const queryParams = {
    skip: pageParam || 0,
    // Kind of arbritrary, but we show these in columns of 1,2,3 and those are all evenly divisible from 36
    take: 36,
    sort,
  };

  if (searchKeyword) {
    queryParams.search = searchKeyword;
  }

  queryParams.insightsFor = insightType;
  if (insightKey) {
    queryParams[insightKey] = insightValue;
  }

  if (targetUserId) {
    queryParams.targetUserId = targetUserId;
  }
  const URL = {
    host: hosts.action,
    uri: `/organizations/${orgId}/insights${buildQueryString(queryParams)}`,
  };

  const headers = {
    'endpoint-version': 2,
  };

  return getApi(URL, headers, {});
};
