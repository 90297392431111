import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import { ManagerReviewStatuses } from '~Reviews/V2/Const/types';

const styles = {
  status: css({
    borderRadius: '50%',
    display: 'inline-block',
    height: '0.625rem',
    marginRight: '0.625rem',
    width: '0.625rem',
  }),
  statusColor: (status: string) => {
    let background = '';
    switch (status) {
      case ManagerReviewStatuses.Complete:
        background = palette.brand.green;
        break;
      case ManagerReviewStatuses.Signoff_Review:
      case ManagerReviewStatuses.Release_Review:
      case ManagerReviewStatuses.Awaiting_Responses:
      case ManagerReviewStatuses.Awaiting_Release:
      case ManagerReviewStatuses.Submit_Review:
        background = palette.brand.orange;
        break;
      case ManagerReviewStatuses.Start_Review:
        background = palette.brand.red;
        break;
      default:
        background = palette.neutrals.gray400;
    }
    return css`background: ${background}`;
  },
  avatar: css`
    margin-right: .5rem;
  `,
};

interface ViewProps {
  match: string,
}

const View = ({
  match,
}: ViewProps): JSX.Element => (
  <div>
    <span css={[styles.status, styles.statusColor(match)]} />
  </div>
);

interface StatusIndicatorProps {
  status: unknown | string,
}

const StatusIndicator = ({
  status,
}: StatusIndicatorProps): JSX.Element => {
  const match = Object.entries(ManagerReviewStatuses).find(([, value]) => value.toString() === status?.toString());

  const hookProps = {
    match: match !== undefined ? match?.[1].toString() : 'na' as unknown as ManagerReviewStatuses,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export default StatusIndicator;
