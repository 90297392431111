import { css } from '@emotion/react';
import { ComponentProps, useEffect } from 'react';
import { AUTH_STATUS } from '~Common/const/calendarIntegration';
import { useGetCalendarAuthStatus } from '~Meetings/hooks/CalendarIntegration/Authentication/useGetCalendarAuthStatus';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';
import { useNylasAuthentication } from '~Meetings/hooks/CalendarIntegration/Authentication/useNylasAuthentication';
import { useLocation } from 'react-router-dom';
import ConnectCalendar from './ConnectCalendar';
import SelectCalendar from './SelectCalendar';
import CalendarSettingsSkeleton from './CalendarSettingsSkeleton';

const styles = {
  settingsSectionHeader: css({}),
  selectCalendar: css({}),
};

interface ViewProps extends ComponentProps<'div'> {
  showConnectCalendarOption: boolean,
  isLoading: boolean,
}

const View = ({
  showConnectCalendarOption,
  isLoading,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <SettingsSectionHeader
      css={styles.settingsSectionHeader}
      renderContents={() => (
        <>Connect Your Calendar</>
      )}
    />
    {isLoading && (
      <CalendarSettingsSkeleton />
    )}
    {!isLoading && (
      <>
        {showConnectCalendarOption && (
          <ConnectCalendar />
        )}
        {!showConnectCalendarOption && (
          <SelectCalendar css={styles.selectCalendar} />
        )}
      </>
    )}
  </div>
);

const CalendarSettings = ({ ...props }): JSX.Element => {
  const { search } = useLocation();
  const { authMutation, isLoading: isAuthMutationLoading } = useNylasAuthentication();
  const { data: authStatus, isLoading: isCalendarAuthStatusLoading } = useGetCalendarAuthStatus({
    /*
      This is here to stop from hitting a weird state, where when you get redirected back from Nylas,
      you get a return saying your auth status is unlinked, and then the authMutation happens, and then another authStatus call happens
    */
    isEnabled: !isAuthMutationLoading && search === '',
  });

  /*
    Search is in here because there is a state between when useGetCalendarAuthStatus is done loading and when the useNylasAuthentication starts loading
    In this time, the only thing we have to know that we should still be in the loading state is search
    After the authMutation is done loading, search will be an empty string and that will evaulate to false
  */
  const [isLoading] = useSkeletonLoaders(isCalendarAuthStatusLoading || isAuthMutationLoading || !!search);

  // When you get redirected back from Nylas after signing into your calendar account, this will trigger an auth mutation that is required with our backend
  useEffect(() => {
    if (search && search !== '?') {
      authMutation({ queryString: search });
    }
  }, [search, authMutation]);

  const showConnectCalendarOption = authStatus !== AUTH_STATUS.LINKED;

  const hookProps = {
    showConnectCalendarOption,
    isLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default CalendarSettings;
