import { learningQueryKeys } from '../queryKeys';

export const assignedLearningQueryKeys = {
  all: [...learningQueryKeys.all, 'assigned'] as const,
  list: () => [...assignedLearningQueryKeys.all, 'list'] as const,
  details: () => [...assignedLearningQueryKeys.list(), 'detail'] as const,
  singleContentDetails: () => [...assignedLearningQueryKeys.details(), 'single content'] as const,
  // eslint-disable-next-line max-len
  singleContentDetail: (learningId: string) => [...assignedLearningQueryKeys.singleContentDetails(), learningId] as const,
  playlistDetails: () => [...assignedLearningQueryKeys.details(), 'playlist'] as const,
  playlistDetail: (playlistId: number) => [...assignedLearningQueryKeys.playlistDetails(), playlistId.toString()] as const,
};
