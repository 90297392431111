import { RefObject, useRef, useState } from 'react';
import AgendaSectionMenuButton from '~Meetings/components/shared/AgendaSection/AgendaSectionMenuButton';
import Menu from './Menu';

interface ViewProps {
  handleOpenMenuClick: () => void,
  handleCloseMenuClick: () => void,
  menuButtonRef: RefObject<HTMLButtonElement>,
  numberOfAgendaItems: number,
  isMenuOpen: boolean,
  sectionLocalId: string,
  newTopicLocalId: string,
  hasMaxTopics: boolean,
}

const View = ({
  handleOpenMenuClick,
  handleCloseMenuClick,
  menuButtonRef,
  numberOfAgendaItems,
  isMenuOpen,
  sectionLocalId,
  newTopicLocalId,
  hasMaxTopics,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    <AgendaSectionMenuButton
      handleOpenMenuClick={handleOpenMenuClick}
      ref={menuButtonRef}
      data-test-id="meetingsTemplateAgendaSectionOpenKebab"
    />
    <Menu
      anchorEl={menuButtonRef.current}
      numberOfAgendaItems={numberOfAgendaItems}
      isMenuOpen={isMenuOpen}
      handleCloseMenuClick={handleCloseMenuClick}
      sectionLocalId={sectionLocalId}
      newTopicLocalId={newTopicLocalId}
      hasMaxTopics={hasMaxTopics}
    />
  </div>
);

interface AgendaSectionMenuProps {
  sectionLocalId: string,
  numberOfAgendaItems: number,
  newTopicLocalId: string,
  hasMaxTopics: boolean,
}

const AgendaSectionMenu = ({
  ...props
}: AgendaSectionMenuProps): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const handleOpenMenuClick = (): void => {
    setIsMenuOpen(true);
  };

  const handleCloseMenuClick = (): void => {
    setIsMenuOpen(false);
  };

  const hookProps = {
    handleOpenMenuClick,
    handleCloseMenuClick,
    isMenuOpen,
    menuButtonRef,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default AgendaSectionMenu;
