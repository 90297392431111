import PropTypes from 'prop-types';
import isURL from 'validator/es/lib/isURL';

function LocationLink({ text, meetingLocation, className }) {
  const meetingLocationTrimmed = meetingLocation.trim();
  // const meetingLocation = props.meetingLocation.trim();

  let prefix = '';
  let LocLink;

  if (isURL(meetingLocationTrimmed, {
    require_valid_protocol: false,
  })) {
    if (meetingLocationTrimmed.substring(0, 4) !== 'http') {
      prefix = 'https://';
    }

    let textToDisplay = text ?? meetingLocationTrimmed;

    if (textToDisplay.includes('.zoom.')) {
      textToDisplay = 'Zoom';
    } else if (textToDisplay.includes('discord.gg')) {
      textToDisplay = 'Discord';
    } else if (textToDisplay.includes('microsoftteams')) {
      textToDisplay = 'Microsoft Teams';
    }

    LocLink = (
      <a
        className={className}
        href={`${prefix}${meetingLocationTrimmed}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {textToDisplay}
      </a>
    );
  } else {
    LocLink = (
      <a
        className={className}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.google.com/maps/search/?api=1&query=${meetingLocationTrimmed.split(' ').join('+')}`}
      >
        {text ?? meetingLocationTrimmed}
      </a>
    );
  }

  return LocLink;
}

LocationLink.propTypes = {
  meetingLocation: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
};

export default LocationLink;
