import { css } from '@emotion/react';

import Avatar from '~Deprecated/common/Components/People/Avatar';
import {
  drawerInputDisabled,
  drawerInputLabelText,
} from '~Deprecated/ui/styles/colors';
import { withBaseShadow } from '~Common/styles/mixins';
import { truncate } from '~Deprecated/ui/styles/mixins';
import { ATTENDEE_SHAPE } from '~Common/const/proptypes';

const styles = {
  requestorContainer: css`
    margin: 4px 0;
    border-radius: 8px;
    background: ${drawerInputDisabled};
    padding: 1rem;
  `,
  requestorLabel: css`
    color: ${drawerInputLabelText};
    font-weight: bold;
  `,
  detailsContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  avatarCard: css`
    width: 40px;
    height: 40px;
    margin-left: 12px;
    border-radius: 4px;
    font-size: 1rem;
    ${withBaseShadow()}

    .initials {
      width: 100%;
      height: 100%;
      font-size: 1.5rem;
    }
  `,
};

const Requestor = ({
  requestor,
}) => (
  <div css={styles.requestorContainer}>
    <h5 css={styles.requestorLabel}>REQUESTOR</h5>
    <div css={styles.detailsContainer}>
      <h4 css={truncate}>{`${requestor.firstName} ${requestor.lastName}`}</h4>
      <Avatar name={`${requestor.firstName} ${requestor.lastName}`} imgUrl={requestor.profileImageUrl} css={styles.avatarCard} />
    </div>
  </div>
);

Requestor.propTypes = {
  requestor: ATTENDEE_SHAPE,
};

Requestor.defaultProps = {
  requestor: null,
};

export default Requestor;
