import { useGenericStoreAndLocalStorage } from './useGenericStoreAndLocalStorage';

type UseSkeletonLoadersStore = [boolean, (value: boolean) => void];

// Documentation: https://leadrhq.atlassian.net/l/c/E7qyTJD3
export const useSkeletonLoadersStore = (): UseSkeletonLoadersStore => {
  const [skeletonLoadersOverride, setSkeletonLoadersOverride] = useGenericStoreAndLocalStorage<boolean>(
    {
      localStorageKey: 'skeletonLoadersOverride',
      defaultValue: false,
      genericStoreProps: {
        module: 'leadr',
        workflow: 'loaders',
        feature: 'skeleton-loaders',
      },
    },
  );

  return [skeletonLoadersOverride, setSkeletonLoadersOverride];
};
