import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import SettingsSectionHeader from '~Settings/components/Shared/SettingsSectionHeader';
import { OrgClarityQuestion, OrgSettings } from '~Common/const/Organization';
import OrgClarityInputs from './OrgClarityInputs';

const styles = {
  descriptionText: css({
    color: palette.neutrals.gray700,
    marginBottom: '0.5rem',
  }),
  orgClarityQuestionsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  }),
};

interface ViewProps {
  orgClarityQuestionList: OrgClarityQuestion[],
}

const View = ({
  orgClarityQuestionList,
  ...props
}: ViewProps): JSX.Element => (
  <div
    {...props}
  >
    <SettingsSectionHeader
      renderContents={() => (
        <>Organizational Clarity</>
      )}
    />
    <p css={styles.descriptionText}>
      Answer these questions so that everyone has clarity about what they should do to make the organization successful.
      These will be shown in the Organizational Clarity widget on Leadr Home.
    </p>
    <div css={styles.orgClarityQuestionsContainer}>
      {orgClarityQuestionList?.map((orgClarityQuestion) => (
        <OrgClarityInputs
          key={orgClarityQuestion.id}
          orgClarityQuestion={orgClarityQuestion}
        />
      ))}
    </div>
  </div>
);

interface OrgClarityProps {
  orgSettings: OrgSettings,
}

const OrgClarity = ({
  orgSettings,
  ...props
}: OrgClarityProps): JSX.Element => {
  const { orgClarityQuestionList = [] } = orgSettings;

  const hookProps = {
    orgClarityQuestionList,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default OrgClarity;
