import { css } from '@emotion/react';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';

import { palette } from '~Common/styles/colors';
import { withVisibleOnHover } from '~Common/styles/mixins';
import Button from '~Common/V3/components/Button';
import { useAddMeetingToCalendar } from '~Meetings/hooks/CalendarIntegration/useAddMeetingToCalendar';
import { DaySectionSkeleton } from '~Home/components/Module/Meetings/DaySection';
import MeetingCardBase from '~Home/components/Module/Meetings/MeetingCard/MeetingCardBase';
import { MyScheduleMeeting } from '~Home/const/interfaces';
import { UnsyncedLeadrMeeting, useGetUnsyncedLeadrMeetings } from '~Meetings/hooks/CalendarIntegration/useGetUnsyncedLeadrMeetings';
import ReviewUnsyncedMeetingsDrawerTabBody from '../ReviewUnsyncedMeetingsDrawerTabBody';

const styles = {
  daySection: css({
    '&:not(:first-of-type)': {
      paddingTop: '1.125rem',
      borderTop: `1px solid ${palette.neutrals.gray400}`,
    },
    '&:not(:last-of-type)': {
      paddingBottom: '1.125rem',
    },
  }),
  meetingCard: css({
    marginBottom: '0.5rem',
  }),
  addButton: css({
    color: palette.brand.blue,
    fontSize: '.625rem',
    paddingRight: 0,
  }),
  visibleOnHover: (isHovering: boolean) => css({
    display: 'flex',
  }, withVisibleOnHover(isHovering)),
};

interface ViewProps {
  handleAddToCalendar: (myScheduleMeeting: MyScheduleMeeting) => void,
  areUnyncedLeadrMeetingsLoading: boolean,
  unsyncedLeadrMeetings: UnsyncedLeadrMeeting[] | undefined,
}

const View = ({
  handleAddToCalendar,
  unsyncedLeadrMeetings,
  areUnyncedLeadrMeetingsLoading,
  ...props
}: ViewProps): JSX.Element => (
  <div {...props}>
    {areUnyncedLeadrMeetingsLoading && (
      <DaySectionSkeleton css={styles.daySection} />
    )}
    {!areUnyncedLeadrMeetingsLoading && (
      <>
        {(!unsyncedLeadrMeetings || unsyncedLeadrMeetings.length === 0) && (
          <div>No unsynced Leadr meetings</div>
        )}
        {unsyncedLeadrMeetings && unsyncedLeadrMeetings.length > 0 && (
          <ReviewUnsyncedMeetingsDrawerTabBody
            unsyncedMeetings={unsyncedLeadrMeetings}
            renderMeetingCard={(meeting) => (
              <MeetingCardBase
                key={meeting.meetingFactoryId}
                css={styles.meetingCard}
                meeting={meeting}
                renderRightFooter={(isHovering: boolean) => (
                  <Button
                    css={[styles.addButton, styles.visibleOnHover(isHovering)]}
                    variant="text"
                    onClick={() => handleAddToCalendar(meeting)}
                    renderContents={() => (
                      <>Add to Calendar</>
                    )}
                  />
                )}
                {...props}
              />
            )}
          />
        )}
      </>
    )}
  </div>
);

const MeetingsInLeadr = ({ ...props }): JSX.Element => {
  const { unsyncedLeadrMeetings, isLoading } = useGetUnsyncedLeadrMeetings();
  const [areUnyncedLeadrMeetingsLoading] = useSkeletonLoaders(isLoading);
  const addToCalendarMutation = useAddMeetingToCalendar();

  const handleAddToCalendar = (myScheduleMeeting: MyScheduleMeeting): void => {
    addToCalendarMutation({ meetingFactoryId: myScheduleMeeting.meetingFactoryId });
  };

  const hookProps = {
    handleAddToCalendar,
    unsyncedLeadrMeetings,
    areUnyncedLeadrMeetingsLoading,
  };

  return (
    <View
      {...hookProps}
      {...props}
    />
  );
};

export { View };
export default MeetingsInLeadr;
