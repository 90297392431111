import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { faPenToSquare, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { css } from '@emotion/react';
import AdminActionMenu from '~Admin/components/tables/AdminActionMenu';
import { PERSON } from '~Common/const/proptypes';
import { palette } from '~Common/styles/colors';
import { useEditUser } from '~Admin/hooks/usePeopleTableActions';
import SquareAvatar from '~Common/components/Users/Avatars/SquareAvatar';
import { ActionType } from '~Admin/const/defaults';
import { getUserId } from '~Common/utils/localStorage';
import DataGridTable from '~Common/components/Tables/DataGridTable';
import { useProfileEditRestrictions } from '~Common/hooks/people/useProfileEditRestrictions';
import { dateRowComparator, dateRowRelativeFormatter } from '~Admin/functions';

const styles = {
  censusStatus: css({
    borderRadius: '50%',
    display: 'inline-block',
    height: '0.625rem',
    marginRight: '0.625rem',
    width: '0.625rem',
  }),
  complete: css({
    background: palette.brand.green,
  }),
  incomplete: css({
    background: palette.brand.red,
  }),
  na: css({
    background: palette.neutrals.gray400,
  }),
  avatar: css({
    marginRight: '.5rem',
  }),
};
export function getFullName(params) {
  return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
}
const ActiveUsersTable = ({
  filteredUsers,
  allUsers,
  pageSize,
  selectionModel,
  setSelectionModel,
  setActionType,
  deactivateUser,
  openConfirmationPopover,
  filterModel,
  setFilterModel,
  sortComparator,
  handleCellClickEvent,
}) => {
  const { editUser } = useEditUser();
  const loggedInUserId = getUserId();
  const {
    isFieldDisabled: {
      status: disableStatusChanges,
    },
  } = useProfileEditRestrictions();

  const menuItems = (param) => {
    const items = [[
      {
        text: `Edit ${param.name}`,
        icon: faPenToSquare,
        onClick: () => {
          const user = filteredUsers.find(({ id }) => id === param.id);
          editUser({ user, allUsers });
        },
        dataTestId: 'actionMenuItem-edit',
      },
    ]];
    if (loggedInUserId !== param.id && !disableStatusChanges) {
      items[0].push({
        text: `Deactivate ${param.name}`,
        icon: faTrashCan,
        onClick: (e) => {
          const userData = filteredUsers.filter((user) => user.id === param.id);
          setActionType(ActionType.Deactivate);
          openConfirmationPopover(e);
          deactivateUser(userData[0].id);
        },
        dataTestId: 'actionMenuItem-deactivate',
      });
    }
    return items;
  };

  const tableColumnHeaders = [
    {
      field: 'name',
      headerName: 'Name',
      maxWidth: 750,
      flex: 1,
      renderCell: (cellValues) => (
        <>
          <SquareAvatar
            imgUrl={cellValues.row.name.image}
            name={`${cellValues.row.name.name}`}
            width={30}
            height={30}
            includeInitials
            css={styles.avatar}
          />
          <div className="MuiDataGrid-cellContent">{cellValues.row.name.name}</div>
        </>
      ),
      type: 'object',
      valueGetter: (params) => params.row.name.name,
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      maxWidth: 1750,
      flex: 1,
      valueGetter: getFullName,
      hide: true,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      maxWidth: 1750,
      flex: 1,
      hide: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      maxWidth: 1750,
      flex: 1,
      hide: true,
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      maxWidth: 1750,
      flex: 1,
    },
    {
      field: 'department',
      headerName: 'Department',
      maxWidth: 1750,
      flex: 1,
    },
    {
      field: 'userGroupName',
      headerName: 'Account Type',
      maxWidth: 200,
      flex: 1,
    },
    {
      field: 'managerEmail',
      headerName: 'Manager Email',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'lastActivityInMillis',
      headerName: 'Last Activity',
      valueFormatter: dateRowRelativeFormatter,
      sortComparator: dateRowComparator,
      maxWidth: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      maxWidth: 100,
      headerAlign: 'right',
      align: 'right',
      sortable: false,
      renderCell: (cellValues) => (
        <AdminActionMenu menuItems={menuItems({ id: cellValues.row.id, name: cellValues.row.name.name })} />
      ),
      flex: 1,
    },
  ];

  const userRows = useMemo(() => filteredUsers.map((user) => ({
    id: user.id,
    name: { name: `${user.firstName} ${user.lastName}`, image: user.profileImageUrl },
    firstName: user.firstName,
    lastName: user.lastName,
    jobTitle: user.jobTitle ?? '-',
    department: user.department ?? '-',
    userGroupName: user.userGroupName ?? '-',
    managerEmail: user.managerEmail,
    lastActivityInMillis: user.lastActivityInMillis,
    actions: user.id,
  })), [filteredUsers]);

  return (
    <DataGridTable
      rows={userRows}
      columns={tableColumnHeaders}
      pageSize={pageSize}
      selectionModel={selectionModel}
      setSelectionModel={setSelectionModel}
      filterModel={filterModel}
      setFilterModel={setFilterModel}
      handleCellClickEvent={handleCellClickEvent}
      sortComparator={sortComparator}
    />
  );
};

ActiveUsersTable.propTypes = {
  filteredUsers: PropTypes.arrayOf(PERSON).isRequired,
  allUsers: PropTypes.arrayOf(PERSON).isRequired,
  pageSize: PropTypes.number.isRequired,
  setSelectionModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  selectionModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  setFilterModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  filterModel: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  setActionType: PropTypes.func,
  deactivateUser: PropTypes.func,
  openConfirmationPopover: PropTypes.func.isRequired,
  sortComparator: PropTypes.func.isRequired,
  handleCellClickEvent: PropTypes.func.isRequired,
};

ActiveUsersTable.defaultProps = {
  setActionType: '',
  deactivateUser: null,
};

export default ActiveUsersTable;
