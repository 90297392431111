import { css } from '@emotion/react';

const styles = {
  boldedText: css({
    fontWeight: 600,
  }),
};

interface AccomplishmentTextProps {
  isReviewee: boolean,
  revieweeFullName: string,
}

const AccomplishmentText = ({
  isReviewee,
  revieweeFullName,
  ...props
}: AccomplishmentTextProps): JSX.Element => (
  <span {...props}>
    {'Accomplishments '}
    {isReviewee && (
      <span>{'you have added '}</span>
    )}
    {!isReviewee && (
      <span>
        added by
        <span css={styles.boldedText}>{` ${revieweeFullName} `}</span>
      </span>
    )}
  </span>
);

export default AccomplishmentText;
