import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { queryClient } from '~Common/const/queryClient';
import { hosts } from '~Deprecated/services/config';
import { toast } from '~Common/components/Toasts';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { useDraft } from '~Common/hooks/useDraft';
import { USER_PENDING_ITEMS_QUERY_KEY, UserPendingItems } from '~Common/hooks/user/useUserPendingItems';
import { popDrawerAction } from '~Deprecated/actions/drawers/popDrawer';
import {
  LearningStatus,
  ReceivedLearning,
  AssignedLearning,
  AssignedPlaylistDetails,
  ReceivedPlaylistDetail,
  ReceivedPlaylistLearning,
  LearningPersonDisplayInformation,
  CompleteLearningData,
} from '~Learning/const/interfaces';
import { pushDrawerAction } from '~Deprecated/actions/drawers/pushDrawer';
import { OPTIMISTIC_ID } from '~Learning/const';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { learningContentDrawerTemplate } from '~Learning/components/ReceivedLearningDashboard/ReceivedPlaylistDrawer/Tabs/Content/LearningContentDrawer';
import { receivedPlaylistDrawerTemplate } from '~Learning/components/ReceivedLearningDashboard/ReceivedPlaylistDrawer';
import { pdpPlanKeys } from '~DevelopmentPlan/const/queryKeys';
import { assignedLearningQueryKeys } from '../assigned/queryKeys';
import { receivedLearningQueryKeys } from './queryKeys';

interface SubmitResponsesProps {
  learningId: string,
  learningData?: CompleteLearningData
}

const submitLearningResponses = ({ learningData }: SubmitResponsesProps): Promise<HttpCallReturn<boolean>> => {
  const url = {
    host: hosts.questions,
    uri: `/organizations/${getOrganizationId() ?? ''}/learning/complete`,
  };

  return postApi<boolean>(url, learningData);
};

interface UseSubmitLearningResponsesParams {
  learningId: string,
  playlistId: number,
  learning: ReceivedPlaylistLearning,
  learningIndex: number,
  totalLearningsCount: number,
  assigner: LearningPersonDisplayInformation,
  clearSelectedLearning: () => void,
  setSelectedLearningId: (learningId: string) => void,
}

export const useSubmitPlaylistLearningResponses = ({
  learningId,
  playlistId,
  setSelectedLearningId,
  ...args
}: UseSubmitLearningResponsesParams): UseMutateFunction<HttpCallReturn<boolean>, unknown, SubmitResponsesProps> => {
  const dispatch = useDispatch();
  const currentUserId = getUserId() ?? ''; // User id of the user that is signed in
  // TODO: Remove the casting when useNewPeople gets typed
  const { peopleData } = useNewPeople({}) as unknown as Record<string, Record<string, LearningPersonDisplayInformation>>;
  const { buildDraftKey, removeDraftKeyFromLocalStorage } = useDraft();

  const mutation = useMutation({
    mutationFn: submitLearningResponses,
    onMutate: async (submitResponsesProps: SubmitResponsesProps) => {
      // @ts-expect-error TODO: Remove if we add Typescript to Redux files, or remove Redux entirely
      dispatch(popDrawerAction({ drawerName: learningContentDrawerTemplate.name }));

      // #region Cancel Queries
      await queryClient.cancelQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.cancelQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
      // #endregion

      // #region Get Previous Data
      const previousReceivedPlaylistDetail = queryClient.getQueryData<HttpCallReturn<ReceivedPlaylistDetail>>(
        receivedLearningQueryKeys.playlistDetail(playlistId),
      );
      const previousAssignedPlaylistDetail = queryClient.getQueryData<HttpCallReturn<AssignedPlaylistDetails>>(
        assignedLearningQueryKeys.playlistDetail(playlistId),
      );
      const previousReceivedLearningList = queryClient.getQueryData(receivedLearningQueryKeys.list());
      const previousAssignedLearningList = queryClient.getQueryData(assignedLearningQueryKeys.list());
      const previousUserPendingItems = queryClient.getQueryData(USER_PENDING_ITEMS_QUERY_KEY);
      // #endregion

      let isLastLearningInPlaylist = false;

      if (previousReceivedPlaylistDetail) {
        isLastLearningInPlaylist = previousReceivedPlaylistDetail.response.completedLearningsCount + 1
          === previousReceivedPlaylistDetail.response.totalLearningsCount;
      }

      // #region Update Single Playlist Details for Learning that was just completed
      queryClient.setQueryData<HttpCallReturn<ReceivedPlaylistDetail>>(
        receivedLearningQueryKeys.playlistDetail(playlistId),
        (oldReceivedPlaylistDetail) => {
          if (oldReceivedPlaylistDetail) {
            const newReceivedPlaylistDetail = cloneDeep(oldReceivedPlaylistDetail);
            newReceivedPlaylistDetail.response.completedLearningsCount += 1;

            if (newReceivedPlaylistDetail.response.completedLearningsCount === newReceivedPlaylistDetail.response.totalLearningsCount) {
              newReceivedPlaylistDetail.response.status = LearningStatus.COMPLETED;
            }

            const learningToUpdateIndex = newReceivedPlaylistDetail.response.learnings.findIndex((learning) => learning.id === learningId);

            const newLearning = cloneDeep(newReceivedPlaylistDetail.response.learnings[learningToUpdateIndex]);

            newLearning.questions = newLearning.questions.map((question) => ({
              ...question,
              response: {
                text: submitResponsesProps.learningData?.responses.find((response) => response.questionId === question.id)?.responseText || '',
                id: OPTIMISTIC_ID,
              },
            }));

            newLearning.status = LearningStatus.COMPLETED;

            newReceivedPlaylistDetail.response.learnings.splice(learningToUpdateIndex, 1, newLearning);

            return newReceivedPlaylistDetail;
          }

          return oldReceivedPlaylistDetail;
        },
      );
      // #endregion

      // #region Update Received List to have that Learning completed
      queryClient.setQueryData<ReceivedLearning[]>(receivedLearningQueryKeys.list(), (oldReceivedLearningList) => {
        if (oldReceivedLearningList) {
          const newReceivedLearningList = [...oldReceivedLearningList];

          const receivedPlaylistToUpdateIndex = newReceivedLearningList.findIndex((receivedLearning) => receivedLearning.id === playlistId.toString());

          const newReceivedPlaylist = cloneDeep(newReceivedLearningList[receivedPlaylistToUpdateIndex]);

          newReceivedPlaylist.completedLearningsCount += 1;

          if (newReceivedPlaylist.completedLearningsCount === newReceivedPlaylist.totalLearningsCount) {
            newReceivedPlaylist.status = LearningStatus.COMPLETED;
          }

          newReceivedLearningList.splice(receivedPlaylistToUpdateIndex, 1, newReceivedPlaylist);

          return newReceivedLearningList;
        }

        return [];
      });
      // #endregion

      // #region Update Notification Dot in the Navbar
      queryClient.setQueryData<HttpCallReturn<UserPendingItems>>(USER_PENDING_ITEMS_QUERY_KEY, (oldUserPendingItems) => {
        if (oldUserPendingItems) {
          const newUserPendingItems = cloneDeep(oldUserPendingItems);
          newUserPendingItems.response.pendingLearningCount -= 1;
          return newUserPendingItems;
        }

        return oldUserPendingItems;
      });
      // #endregion

      // #region If the user sent the playlist to themselves
      if (previousAssignedPlaylistDetail?.response.assignedUsersInfo.some((assignee) => assignee.assignee.id === currentUserId)) {
        // Assigned Learning List
        queryClient.setQueryData<AssignedLearning[]>(
          assignedLearningQueryKeys.list(),
          (oldAssignedLearningList) => {
            if (oldAssignedLearningList) {
              const newAssignedLearningList = cloneDeep(oldAssignedLearningList);

              const indexOfPlaylistToUpdate = newAssignedLearningList?.findIndex(
                (assignedLearning) => assignedLearning.id === learningId,
              );

              const updatedAssignedPlaylist = cloneDeep(newAssignedLearningList[indexOfPlaylistToUpdate]);

              // Handles a case where the user just completed the last Learning in the Playlist
              if (isLastLearningInPlaylist) {
                updatedAssignedPlaylist.numberOfUsersCompleted += 1;
              }

              // Handle a case where the person who sent the Playlist is the last user to complete it
              if (updatedAssignedPlaylist.numberOfUsersCompleted === updatedAssignedPlaylist.numberOfUsersAssigned) {
                updatedAssignedPlaylist.status = LearningStatus.COMPLETED;
              }

              newAssignedLearningList.splice(indexOfPlaylistToUpdate, 1, updatedAssignedPlaylist);

              return newAssignedLearningList;
            }

            return oldAssignedLearningList;
          },
        );

        // Assigned Playlist details
        queryClient.setQueryData<HttpCallReturn<AssignedPlaylistDetails>>(
          assignedLearningQueryKeys.playlistDetail(playlistId),
          (oldAssignedLearningDetail) => {
            if (oldAssignedLearningDetail) {
              const newAssignedPlaylistDetail = cloneDeep(oldAssignedLearningDetail);

              if (isLastLearningInPlaylist) {
                newAssignedPlaylistDetail.response.assignedUsersInfo = newAssignedPlaylistDetail.response.assignedUsersInfo.map((assignee) => {
                  if (assignee.assignee.id === currentUserId) {
                    return {
                      ...assignee,
                      status: LearningStatus.COMPLETED,
                    };
                  }

                  return assignee;
                });

                newAssignedPlaylistDetail.response.numberOfUsersComplete += 1;

                if (newAssignedPlaylistDetail.response.numberOfUsersComplete === newAssignedPlaylistDetail.response.numberOfUsersAssigned) {
                  newAssignedPlaylistDetail.response.status = LearningStatus.COMPLETED;
                }
              } else {
                newAssignedPlaylistDetail.response.assignedUsersInfo = newAssignedPlaylistDetail.response.assignedUsersInfo.map((assignee) => {
                  if (assignee.assignee.id === currentUserId) {
                    return {
                      ...assignee,
                      status: LearningStatus.STARTED,
                    };
                  }

                  return assignee;
                });

                newAssignedPlaylistDetail.response.canRecallFromAllParticipants = false;
              }

              const learningToUpdateIndex = newAssignedPlaylistDetail.response.learnings.findIndex((learning) => learning.id === learningId);

              const newLearning = cloneDeep(newAssignedPlaylistDetail.response.learnings[learningToUpdateIndex]);

              newLearning.questions = newLearning.questions.map((question) => {
                const currentUserInformation = peopleData[currentUserId];

                const newResponse = {
                  text: submitResponsesProps.learningData?.responses.find((response) => response.questionId === question.id)?.responseText || '',
                  id: OPTIMISTIC_ID,
                  assignee: {
                    id: currentUserId,
                    firstName: currentUserInformation.firstName,
                    lastName: currentUserInformation.lastName,
                    profileImageUrl: currentUserInformation.profileImageUrl,
                    jobTitle: currentUserInformation.jobTitle,
                  },
                };

                return {
                  ...question,
                  responses: [...question.responses, newResponse],
                };
              });

              newAssignedPlaylistDetail.response.learnings.splice(learningToUpdateIndex, 1, newLearning);

              return newAssignedPlaylistDetail;
            }

            return oldAssignedLearningDetail;
          },
        );
      }
      // #endregion

      // Return a context object with the old snapshotted values
      return {
        previousReceivedPlaylistDetail,
        previousAssignedPlaylistDetail,
        previousReceivedLearningList,
        previousAssignedLearningList,
        previousUserPendingItems,
      };
    },
    onError: (_, __, snapshot) => {
      toast.error('There was an error submitting learning responses. Please try again or contact support.', {
        autoClose: 1500,
      });

      // Open back up the View Learning Playlist and Learning Content Drawer
      dispatch(pushDrawerAction({
        drawer: {
          ...receivedPlaylistDrawerTemplate,
          args: {
            playlistId,
          },
        },
      }));

      dispatch(pushDrawerAction({
        drawer: {
          ...learningContentDrawerTemplate,
          args: {
            playlistId,
            ...args,
          },
        },
      }));

      setSelectedLearningId(learningId);

      // Revert the data to the previous data
      queryClient.setQueryData(receivedLearningQueryKeys.playlistDetail(playlistId), snapshot?.previousReceivedPlaylistDetail);
      queryClient.setQueryData(assignedLearningQueryKeys.playlistDetail(playlistId), snapshot?.previousAssignedPlaylistDetail);
      queryClient.setQueryData(receivedLearningQueryKeys.list(), snapshot?.previousReceivedLearningList);
      queryClient.setQueryData(assignedLearningQueryKeys.list(), snapshot?.previousAssignedLearningList);
      queryClient.setQueryData(USER_PENDING_ITEMS_QUERY_KEY, snapshot?.previousUserPendingItems);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: receivedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: assignedLearningQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: USER_PENDING_ITEMS_QUERY_KEY });
    },
    onSuccess: (_, variables) => {
      // Clear the local storage of the draft logic
      variables.learningData?.responses.forEach((questionResponse) => {
        const draftKey = buildDraftKey([getOrganizationId() ?? '', 'learning', learningId, 'question', questionResponse.questionId]);
        removeDraftKeyFromLocalStorage(draftKey);
      });
      void queryClient.invalidateQueries({ queryKey: pdpPlanKeys.all });
    },
  });

  return mutation.mutate;
};
