import { createReducer } from '../redux/reducers/index';

const initialState = {
  data: {},
  enneagram: [],
  myersbriggs: [],
  disc: [],
  activeTabIndex: 0,
  hasPendingCall: false,
  error: null,
  success: null,
};

const { reducer, registerAction } = createReducer('personalities', initialState);

export { reducer, registerAction, initialState };
