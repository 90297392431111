/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  GridColumns, GridEventListener, GridSelectionModel,
} from '@mui/x-data-grid';
import moment from 'moment';
import {
  SyntheticEvent, useEffect, useMemo, useState,
} from 'react';
import DataGridTable from '~Common/components/Tables/DataGridTable';
import { ViewPerspective } from '~Reviews/V2/Const/types';
import Checkbox from '~Reviews/V2/reflections/Components/Checkbox';
import Card from '~Common/V3/components/Card';
import { useQueryParamState } from '~Common/hooks/useQueryParamState';
import Pagination from '~Common/V3/components/Pagination';
import { DropdownItem } from '~Common/V3/components/Dropdown';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { Person } from '~Common/const/interfaces';
import { getOrganizationUserId } from '~Common/utils/localStorage';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { useReviewsDashboardSearch } from '~Reviews/V2/stores/useReviewsDashboardSearch';
import { stripHtml } from '~Common/utils/stripHtml';
import { useFavoriteReflection } from '~Reviews/V2/reflections/hooks/useFavoriteReflection';
import { useReflectionDetailsDrawer } from '~Reviews/V2/reflections/hooks/useReflectionDetailsDrawer';
import { useGetReflectionsList } from '~Reviews/V2/reflections/hooks/useGetReflectionsList';
import { useGetDirectReportsFilterItems } from '~Reviews/V2/reflections/hooks/useGetDirectReportsFilterItems';
import { useGetDirectReportReflectionsList } from '~Reviews/V2/reflections/hooks/useGetDirectReportReflectionsList';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { css } from '@emotion/react';
import { CustomNoRowsOverlay } from '~Reviews/V2/reflections/Components/DataGridNoRowsDisplay';
import {
  tableColumnHeadersMembers, tableColumnHeadersManager,
} from '../const/functions';
import {
  ReflectionsUserRowsProps, DashboardTab, ReflectionsItem, ReflectionType, ReflectionsFavoriteType, ReflectionTypeEnum, ReflectionsIsActiveType,
} from '../types';
import ReflectionsHeader from '../Components/ReflectionsHeader';
import { REFLECTIONS_DEFAULT_PAGE_NUMBER, REFLECTIONS_DEFAULT_PAGE_SIZE } from '../const/defaults';
import ReflectionsTableControls from '../Components/ReflectionsTableControls';

const styles = {
  pagination: css({
    marginBottom: '2rem',
  }),
};

interface ViewProps {
  viewPerspective: ViewPerspective,
  userRows: ReflectionsUserRowsProps[],
  selectionModel: GridSelectionModel,
  setSelectionModel: (rowIds: GridSelectionModel) => void,
  handleCellClickEvent: GridEventListener<'cellClick'>,
  changeTab: (event: SyntheticEvent, tab: DashboardTab) => void,
  expandedTab: DashboardTab,
  tableColumnHeaders: GridColumns,
  directReport: string[],
  setDirectReport: (directReport: string[]) => void,
  directReportsFilterItems: DropdownItem<ReflectionsItem[] | string[]>[],
  tablePageSize: string,
  setTablePageSize: (pageSize: string) => void,
  isLoading: boolean,
  reflectionsFavoriteValue: ReflectionsFavoriteType[],
  setReflectionsFavoriteValue: (reflectionsFavoriteValue: ReflectionsFavoriteType[]) => void,
  reflectionsIsActiveValue: ReflectionsIsActiveType[],
  setIsActiveValue: (reflectionsIsActiveValue: ReflectionsIsActiveType[]) => void,
  reflectionsTypeValue: ReflectionType[],
  setReflectionsTypeValue: (reflectionsTypeValue: ReflectionType[]) => void,
  onPageChange: (event: SelectChangeEvent<number>) => void,
  onPreviousClick: () => void,
  onNextClick: () => void,
  page: number,
  totalNumberOfPages: number,
}

const View = ({
  viewPerspective,
  userRows,
  selectionModel,
  setSelectionModel,
  handleCellClickEvent,
  changeTab,
  expandedTab,
  tableColumnHeaders,
  directReport,
  setDirectReport,
  directReportsFilterItems,
  tablePageSize,
  setTablePageSize,
  isLoading,
  reflectionsFavoriteValue,
  setReflectionsFavoriteValue,
  reflectionsIsActiveValue,
  setIsActiveValue,
  reflectionsTypeValue,
  setReflectionsTypeValue,
  onPageChange,
  onPreviousClick,
  onNextClick,
  page,
  totalNumberOfPages,
}: ViewProps): JSX.Element => (
  <>
    {viewPerspective && (
    <>
      <Card
        renderContents={() => (
          <>
            <ReflectionsHeader
              isLoading={isLoading}
            />
            <ReflectionsTableControls
              changeTab={changeTab}
              expandedTab={expandedTab}
              directReport={directReport}
              setDirectReport={setDirectReport}
              directReportsFilterItems={directReportsFilterItems}
              tablePageSize={tablePageSize}
              setTablePageSize={setTablePageSize}
              isLoading={isLoading}
              reflectionsFavoriteValue={reflectionsFavoriteValue}
              setReflectionsFavoriteValue={setReflectionsFavoriteValue}
              reflectionsIsActiveValue={reflectionsIsActiveValue}
              setIsActiveValue={setIsActiveValue}
              reflectionsTypeValue={reflectionsTypeValue}
              setReflectionsTypeValue={setReflectionsTypeValue}
            />
            <DataGridTable
              rows={userRows}
              columns={tableColumnHeaders}
              pageSize={parseInt(tablePageSize, 10)}
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              handleCellClickEvent={handleCellClickEvent}
              isLoading={isLoading}
              checkboxSelection={false}
              // @ts-expect-error Not really sure why this hates this since the docs say it's a valid prop
              components={{
                BaseCheckbox: Checkbox,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              hideFooter
            />
            <Pagination
              page={page}
              onPageChange={onPageChange}
              numberOfPages={totalNumberOfPages}
              onPreviousClick={onPreviousClick}
              onNextClick={onNextClick}
              isLoading={isLoading}
              css={styles.pagination}
            />
          </>
        )}
      />
    </>
    )}
  </>
);

interface ReflectionsLayoutProps {
  viewPerspective: ViewPerspective,
}

const ReflectionsDashboard = ({
  viewPerspective,
}: ReflectionsLayoutProps): JSX.Element => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [expandedTab, setExpandedTab] = useQueryParamState<DashboardTab>('recognitions', 'tab', DashboardTab.Member);
  const [directReport, setDirectReport] = useState(['']);

  const [reflectionsFavoriteValue, setReflectionsFavoriteValue] = useState<ReflectionsFavoriteType[]>([ReflectionsFavoriteType.All]);
  const [reflectionsIsActiveValue, setIsActiveValue] = useState<ReflectionsIsActiveType[]>([ReflectionsIsActiveType.All]);
  const [reflectionsTypeValue, setReflectionsTypeValue] = useState<ReflectionType[]>([ReflectionType.All]);

  const [tablePageSize, setTablePageSize] = useState(REFLECTIONS_DEFAULT_PAGE_SIZE.toLocaleString());
  const [page, setPage] = useState(REFLECTIONS_DEFAULT_PAGE_NUMBER);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(REFLECTIONS_DEFAULT_PAGE_NUMBER);

  const searchText = useReviewsDashboardSearch((state) => state.debouncedSearchText);

  const isManagerPerspective = expandedTab === DashboardTab.Manager;
  const changeTab = (evt: SyntheticEvent, newTab: DashboardTab): void => {
    setExpandedTab(newTab);
  };

  const onPageChange = (event: SelectChangeEvent<number>): void => {
    setPage(event.target.value as number);
  };

  const onPreviousClick = (): void => {
    setPage(page - 1);
  };

  const onNextClick = (): void => {
    setPage(page + 1);
  };

  const {
    totalPages,
    reflections,
    isLoading: isLoadingReflections,
  } = useGetReflectionsList({
    search: searchText,
    index: page,
    limit: parseInt(tablePageSize, 10),
    reflectionType: reflectionsTypeValue,
    favoriteStatus: reflectionsFavoriteValue,
    isInActiveReview: reflectionsIsActiveValue,
    enabled: expandedTab === DashboardTab.Member,
    startDate: '',
    endDate: '',
  });

  const {
    totalPages: totalPagesDirectReport,
    directReportReflections: managerReflections,
    isLoading: isLoadingDirectReportReflections,
  } = useGetDirectReportReflectionsList({
    search: searchText,
    index: page,
    limit: parseInt(tablePageSize, 10),
    reflectionType: reflectionsTypeValue,
    favoriteStatus: reflectionsFavoriteValue,
    isInActiveReview: reflectionsIsActiveValue,
    startDate: '',
    endDate: '',
    ownerId: directReport[0],
    reviewPeriod: '',
    enabled: expandedTab === DashboardTab.Manager,
  });

  useEffect(() => {
    setPage(REFLECTIONS_DEFAULT_PAGE_NUMBER);
    setTablePageSize(tablePageSize);
  }, [tablePageSize]);

  useEffect(() => {
    setPage(REFLECTIONS_DEFAULT_PAGE_NUMBER);
  }, [reflectionsFavoriteValue, reflectionsIsActiveValue, reflectionsTypeValue, directReport]);

  useEffect(() => {
    if (expandedTab === DashboardTab.Member) {
      const setValue = totalPages > 0 ? totalPages : REFLECTIONS_DEFAULT_PAGE_NUMBER;
      setTotalNumberOfPages(setValue);
    } else {
      const setValue = totalPagesDirectReport > 0 ? totalPagesDirectReport : REFLECTIONS_DEFAULT_PAGE_NUMBER;
      setTotalNumberOfPages(setValue);
    }
  }, [totalPagesDirectReport, totalPages, expandedTab]);

  const [isLoading] = useSkeletonLoaders(isLoadingReflections || isLoadingDirectReportReflections);
  const { peopleData, directs } = useNewPeople({}) as unknown as { peopleData: Record<string, Person>; directs: string[] };
  const currentUser = getOrganizationUserId();

  const directReportsFilterItems = useGetDirectReportsFilterItems(directs, peopleData, currentUser ?? '');
  const tableColumnHeaders = isManagerPerspective ? tableColumnHeadersManager : tableColumnHeadersMembers;

  const userRowsManager = useMemo<ReflectionsUserRowsProps[]>(() => managerReflections?.map((reflection) => {
    const manipulatedTitle = stripHtml(reflection.contentTitle);
    return {
      id: reflection.id,
      isFavorite: reflection.isFavorite,
      contentId: reflection.contentId,
      ownedBy: {
        name: `${reflection.owner.firstName} ${reflection.owner.lastName}`,
        image: reflection.owner.profileImageUrl,
        orgUserId: reflection.owner.orgUserId,
      },
      content: manipulatedTitle,
      contentTypeId: reflection.contentTypeId,
      dateCreated: moment(reflection.contentCreatedDate).format('MMMM Do, YYYY'),
      isInActiveReview: reflection.isInActiveReview,
    };
  }) || [], [managerReflections]);

  const userRows = useMemo(() => reflections?.map((reflection) => {
    const manipulatedTitle = stripHtml(reflection.contentTitle);
    return {
      id: reflection.id,
      isFavorite: reflection.isFavorite,
      contentId: reflection.contentId,
      ownedBy: {
        name: `${reflection.owner.firstName} ${reflection.owner.lastName}`,
        image: reflection.owner.profileImageUrl,
        orgUserId: reflection.owner.orgUserId,
      },
      content: manipulatedTitle,
      contentTypeId: reflection.contentTypeId,
      dateCreated: moment(reflection.contentCreatedDate).format('MMMM Do, YYYY'),
      isInActiveReview: reflection.isInActiveReview,
    };
  }), [reflections]) as ReflectionsUserRowsProps[];

  const favoriteMutation = useFavoriteReflection({
    search: searchText,
    index: page,
    limit: parseInt(tablePageSize, 10),
    reflectionType: reflectionsTypeValue,
    favoriteStatus: reflectionsFavoriteValue,
    isInActiveReview: reflectionsIsActiveValue,
    startDate: '',
    endDate: '',
  });

  const { openDrawer: openReflectionDetailsDrawer } = useReflectionDetailsDrawer();
  const handleCellClickEvent: GridEventListener<'cellClick'> = (params) => {
    const { id, row, colDef } = params;
    const { contentTypeId, ownedBy, contentId } = row;
    const { orgUserId } = ownedBy;
    const { field } = colDef;

    if (field === 'isFavorite' && expandedTab === DashboardTab.Member) {
      const IdWithoutCommas = id.toString().replace(/,/g, '');
      if (IdWithoutCommas) {
        onFavoriteChange(IdWithoutCommas);
      }
    } else {
      openReflectionDetailsDrawer({
        reflectionTypeEnum: contentTypeId as ReflectionTypeEnum,
        subjectUid: contentId,
        revieweeId: orgUserId as string,
        revieweeOrgID: orgUserId,
        reflectionId: contentId,
      });
    }
  };
  const onFavoriteChange = (id: string): void => {
    favoriteMutation({ id });
  };

  const hookProps = {
    viewPerspective,
    tableColumnHeaders,
    userRows: isManagerPerspective ? userRowsManager : userRows,
    selectionModel,
    setSelectionModel,
    handleCellClickEvent,
    changeTab,
    expandedTab,
    page,
    directReport,
    setDirectReport,
    directReportsFilterItems,
    tablePageSize,
    setTablePageSize,
    isLoading,
    reflectionsFavoriteValue,
    setReflectionsFavoriteValue,
    reflectionsIsActiveValue,
    setIsActiveValue,
    reflectionsTypeValue,
    setReflectionsTypeValue,
    onPageChange,
    onPreviousClick,
    onNextClick,
    totalNumberOfPages,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View, ReflectionsDashboard };
export default ReflectionsDashboard;
