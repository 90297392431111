import { UseMutationResult, useMutation } from '@tanstack/react-query';

import {
  getFirstName,
  getLastName,
  getOrganizationId,
} from '~Common/utils/localStorage';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { queryClient } from '~Common/const/queryClient';
import { AgendaTemplates } from '@leadr-hr/types';
import { getLocalId } from '~Common/utils/uuid';
import { produce } from 'immer';
import { toast } from '~Common/components/Toasts';
import {
  CreateMeetingTemplatePayload,
  CreateMeetingTemplatesResponse,
  MeetingTemplateType,
  CreateAgendaTemplateDetails,
  CreateAgendaSectionTemplateDetails,
} from '~Meetings/components/topic-suggestions/const/types';
import { meetingTopicTemplatesQueryKeys } from './queryKeys';
import { GetMeetingTemplatesResponse } from './useGetCustomMeetingTemplates';

const createMeetingTemplate = ({
  type,
  text,
  isPrivate,
  isManagerOnly,
  children,
}: CreateMeetingTemplatePayload): Promise<HttpCallReturn<CreateMeetingTemplatesResponse>> => {
  const URL = `/organizations/${getOrganizationId() ?? ''}/agenda-templates`;

  return postApi<CreateMeetingTemplatesResponse>(URL, {
    type,
    text,
    isPrivate,
    isManagerOnly,
    children,
  });
};

function isTemplateAgendaSection(item: CreateAgendaTemplateDetails): item is CreateAgendaSectionTemplateDetails {
  return (item as CreateAgendaSectionTemplateDetails).children !== undefined;
}

interface UseCreateMeetingTemplateParams {
  onMutateCallback?: () => void,
  meetingTemplateType: MeetingTemplateType,
  templateVisibility: string,
}

export const useCreateMeetingTemplate = ({
  onMutateCallback,
  meetingTemplateType,
  templateVisibility,
}: UseCreateMeetingTemplateParams):UseMutationResult<HttpCallReturn<CreateMeetingTemplatesResponse>, unknown, CreateMeetingTemplatePayload> => {
  const orgId = getOrganizationId() ?? '';

  const queryKeyForList = meetingTopicTemplatesQueryKeys.customTemplatesList({
    orgId,
    meetingTemplateType,
    templateVisibility,
  });

  return useMutation({
    mutationFn: createMeetingTemplate,
    onMutate: async ({
      text,
      isPrivate,
      isManagerOnly,
      children,
    }) => {
      onMutateCallback?.();

      const previousMeetingTemplates = queryClient.getQueryData(queryKeyForList);
      await queryClient.cancelQueries({ queryKey: queryKeyForList });

      queryClient.setQueryData<HttpCallReturn<GetMeetingTemplatesResponse>>(queryKeyForList, (originalData) => {
        if (originalData) {
          const formattedTemplateAgendaItems = children.map((templateAgendaItem) => {
            if (isTemplateAgendaSection(templateAgendaItem)) {
              const templateAgendaSection = {
                ...templateAgendaItem,
                id: getLocalId(),
                permissions: [AgendaTemplates.AgendaTemplatePermission.EditTemplate],
                children: templateAgendaItem.children.map((templateAgendaTopic) => ({
                  ...templateAgendaTopic,
                  id: getLocalId(),
                  permissions: [AgendaTemplates.AgendaTemplatePermission.EditTemplate],
                })),
              };

              return templateAgendaSection;
            }

            return {
              ...templateAgendaItem,
              id: getLocalId(),
              permissions: [AgendaTemplates.AgendaTemplatePermission.EditTemplate],
            };
          });

          const newMeetingTemplate = {
            id: getLocalId(),
            text,
            creator: {
              firstName: getFirstName() ?? '',
              lastName: getLastName() ?? '',
              orgUserId: '',
            },
            isIndividualQuestionsTemplate: false,
            isPrivate: isPrivate ?? false,
            isManagerOnly: isManagerOnly ?? false,
            permissions: [AgendaTemplates.AgendaTemplatePermission.EditTemplate],
            children: formattedTemplateAgendaItems,
          };

          return produce(originalData, (draft) => {
            draft.response.meetingTemplates.push(newMeetingTemplate);
          });
        }

        return originalData;
      });

      return { previousMeetingTemplates };
    },
    onError: (_, __, snapshot) => {
      toast.error('There was an error creating the template. Please try again.', {
        autoClose: 1500,
      });

      queryClient.setQueryData(queryKeyForList, snapshot?.previousMeetingTemplates);

      // TODO: Reopen the drawer?
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: meetingTopicTemplatesQueryKeys.customTemplatesLists(getOrganizationId() ?? '') });
    },
  });
};
