import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Button, { BUTTON_VARIANTS } from '~Deprecated/ui/components/Buttons/Button';
import { noop } from '~Deprecated/utils';

const style = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

/**
 * @deprecated Old Drawer UI, use packages/leadr-frontend/src/common/V3/components/Drawers/DrawerLayout.tsx instead
 */
const DrawerActionHeader = ({
  actionText, cancelText, onAction, onCancel, actionType, disableSubmitButton,
}) => (
  <div
    css={style}
  >
    <div>
      { onAction && (
        <Button
          disabled={disableSubmitButton}
          onClick={onAction}
          variant={BUTTON_VARIANTS.LINK}
          type={actionType}
        >
          { actionText.toLowerCase() }
        </Button>
      ) }
    </div>
    <Button
      onClick={onCancel}
      variant={BUTTON_VARIANTS.LINK}
    >
      { cancelText.toLowerCase() }
    </Button>
  </div>
);

DrawerActionHeader.propTypes = {
  actionText: PropTypes.string,
  onAction: PropTypes.func,
  actionType: PropTypes.oneOf(['button', 'submit', 'reset']),
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  disableSubmitButton: PropTypes.bool,
};

DrawerActionHeader.defaultProps = {
  cancelText: 'cancel',
  actionText: 'action',
  actionType: 'button',
  onAction: noop,
  onCancel: noop,
  disableSubmitButton: false,
};

export default DrawerActionHeader;
