import moment from 'moment';
import { PRINTVIEW_TIMESTAMP } from '~Common/const/dateFormats';
import { usePrintView } from '~Common/stores/usePrintView';

export enum DUE_DATE_STATUS {
  FUTURE = 'FUTURE',
  FUTURE_WITHIN_ONE_WEEK = 'FUTURE_WITHIN_ONE_WEEK',
  PAST = 'PAST',
  COMPLETED = 'COMPLETED',
}

export type DueDateStatusOrNull = DUE_DATE_STATUS | null;

interface useDueDateFromNowProps {
  dueDate: string | Date | number | undefined,
  isComplete?: boolean,
  completedDate?: string | Date | undefined,
}
interface useDueDateFromNowReturn {
  dueDateText: string,
  dueDateStatus: DueDateStatusOrNull,
}

export const useDueDateFromNow = ({ dueDate, isComplete = false, completedDate }: useDueDateFromNowProps): useDueDateFromNowReturn => {
  let dueDateText = 'No Due Date';
  let dueDateStatus: DueDateStatusOrNull = null;

  const isPrintView = usePrintView((state) => state.isPrintView);

  if (isComplete) {
    dueDateText = 'Completed';
    if (completedDate) {
      const momentCompletedDate = moment(completedDate).format('MM/D/YY');
      dueDateText += ` (${momentCompletedDate})`;
    }
    dueDateStatus = DUE_DATE_STATUS.COMPLETED;
  } else if (dueDate) {
    // Moment the dueDate that was passed in
    const momentDueDate = moment(new Date(dueDate));
    // Gets todays date
    const currentDateAndTime = moment(new Date());

    const momentDueDateText = isPrintView ? momentDueDate.format(PRINTVIEW_TIMESTAMP) : momentDueDate.fromNow();

    // Check if the due date is in the future, or in the past
    if (momentDueDate.isSameOrAfter(currentDateAndTime)) {
      const dayDiff = momentDueDate.diff(currentDateAndTime, 'days');
      if (dayDiff <= 7) {
        dueDateStatus = DUE_DATE_STATUS.FUTURE_WITHIN_ONE_WEEK;
      } else {
        dueDateStatus = DUE_DATE_STATUS.FUTURE;
      }
    } else {
      dueDateStatus = DUE_DATE_STATUS.PAST;
    }

    dueDateText = `Due ${momentDueDateText}`;
  }

  return {
    dueDateText,
    dueDateStatus,
  };
};
