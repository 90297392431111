import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { decode } from 'jsonwebtoken';
import { NO_ACCESS_TOKEN } from '~Deprecated/utils/cookies';
import Cookies from 'js-cookie';
import { getOrganizationId } from '~Common/utils/localStorage';
import { useFeatureFlag } from '~Common/hooks/useFeatureFlag';
import { getHost } from '~Deprecated/services/config';
import { useEffect } from 'react';

interface Tokens {
  algoliaUserToken: string,
}

export const getTokensFromCookie = (): Tokens => ({
  algoliaUserToken: Cookies.get('algoliaUserToken') as string,
});

export const calculateTokenUpdateTime = (): number => {
  const { algoliaUserToken } = getTokensFromCookie();
  const { exp = NO_ACCESS_TOKEN } = decode(algoliaUserToken, { json: true }) ?? {};

  // Refresh the token 5 minutes early if possible.
  return exp === NO_ACCESS_TOKEN ? exp : (exp - 300) * 1000;
};

export const getAlgoliaToken = (): string | null | undefined => {
  // Check if the user is being impersonated. If so, get the token from sessionStorage, which is unique for each tab.
  if (window.sessionStorage.getItem('sessionId')) {
    return window.sessionStorage.getItem('algoliaUserToken');
  }

  return Cookies.get('algoliaUserToken');
};

const setAlgoliaToken = (accessToken: string, validUntil: number): void => {
  // Check if the user is being impersonated. If so, store the tokens in a sessionStorage, which is unique for each tab.
  if (window.sessionStorage.getItem('sessionId')) {
    window.sessionStorage.setItem('algoliaUserToken', accessToken);
    return;
  }

  const tokenExpirationInMilliseconds = validUntil * 1000;

  Cookies.set('algoliaUserToken', accessToken, { path: '/', expires: (new Date(tokenExpirationInMilliseconds)) }); // Access token
};

interface AlgoliaAuthReturn {
  searchAccessToken: string,
  validUntil: number,
}

const AlgoliaAuth = (): Promise<HttpCallReturn<AlgoliaAuthReturn>> => {
  const serverURL = {
    host: getHost('', '2'),
    uri: `/organizations/${getOrganizationId() ?? ''}/auth/searchAccessToken`,
  };

  return postApi<AlgoliaAuthReturn>(serverURL, {});
};

export const useAlgoliaAuth = (): UseQueryResult<HttpCallReturn<AlgoliaAuthReturn>, unknown> => {
  const ffEnabled = useFeatureFlag('useGlobalSearch');

  const result = useQuery({
    queryKey: ['algolia', 'searchAccessToken'],
    queryFn: AlgoliaAuth,
    staleTime: 0,
    gcTime: 0,
    enabled: ffEnabled && !getAlgoliaToken(),
  });

  useEffect(() => {
    if (!getAlgoliaToken() && result.isSuccess && result.data?.response) {
      const { response } = result.data;
      setAlgoliaToken(response.searchAccessToken, response.validUntil);
    }
  }, [result.data, result.data?.response, result.isSuccess]);

  return result;
};
