import { css, SerializedStyles } from '@emotion/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useEffect } from 'react';
import DrawerLayout from '~Common/V3/components/Drawers/DrawerLayout';
import { registerDrawer } from '~Deprecated/ui/views/DrawerManager';
import { DrawerProps, DRAWER_WIDTHS } from '~Common/const/drawers';
import IconButton from '~Common/V3/components/Buttons/IconButton';
import DrawerHeader from '~Common/V3/components/Drawers/DrawerHeader';
import { LearningAssignee, LearningStatus } from '~Learning/const/interfaces';
import { useRequestedLearningAssigneeResults } from '~Learning/hooks/utils/useRequestedLearningAssigneeResults';

import { RequestedLearningDrawerTab, useRequestedLearningDrawerTab } from '~Learning/hooks/utils/useRequestedLearningDrawerTab';
import { palette } from '~Common/styles/colors';
import LearningSectionCollapse from '~Learning/components/Shared/LearningSectionCollapse';
import LeadrButton from '~Common/V3/components/LeadrButtons/LeadrButton';
import CollapseSectionHeaderIcon from '~Learning/components/AssignedLearningDashboard/CollapseSectionHeaderIcon';
import AttendeeItem from '~Meetings/components/attendees/AttendeeItem';
import DeleteButtonWithConfirmation from '~Common/V3/components/DeleteConfirmation/DeleteButtonWithConfirmation';
import { useIsMobileQuery } from '~Common/hooks/useMediaListener';
import { useRecallPlaylist } from '~Learning/hooks/assigned/useRecallPlaylist';
import RecallConfirmationButtons from '../RecallConfirmationButtons';
import NoResponseLearningAttendeeCard from '../NoResponseLearningAttendeeCard';

const styles = {
  responseSectionHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  sectionHeaderText: css({
    color: palette.neutrals.gray700,
    fontWeight: 600,
    fontSize: '.6875rem',
    margin: '.9375rem 0',
  }),
  participantCard: css({
    marginTop: '.3125rem',
  }),
  emptyStateText: css({
    color: palette.neutrals.gray800,
  }),
  startedSection: css({
    marginTop: '1rem',
  }),
  incompleteSection: css({
    marginTop: '1rem',
  }),
};

export const playlistParticipantsDrawerTemplate = {
  name: 'PLAYLIST_PARTICIPANTS_DRAWER',
  width: DRAWER_WIDTHS.SECONDARY,
};

export interface PlaylistParticipantsDrawerState {
  isLearningParticipantsDrawerOpen: boolean,
}

interface PlaylistParticipantsDrawerProps extends DrawerProps<PlaylistParticipantsDrawerState> {
  assignees: LearningAssignee[],
  playlistId: number,
}

const PlaylistParticipantsDrawer = ({
  assignees,
  playlistId,
  popDrawer,
  setDrawerState,
}: PlaylistParticipantsDrawerProps): JSX.Element => {
  useEffect(() => {
    setDrawerState((prev) => ({
      ...prev,
      isLearningParticipantsDrawerOpen: true,
    }));

    return () => {
      setDrawerState((prev) => ({
        ...prev,
        isLearningParticipantsDrawerOpen: false,
      }));
    };
  }, [setDrawerState]);

  const [, setPersonIndex] = useRequestedLearningAssigneeResults();
  const [activeTab, setActiveTab] = useRequestedLearningDrawerTab();

  const closeDrawerClick = (): void => {
    popDrawer({ drawerName: playlistParticipantsDrawerTemplate.name });
  };

  const incompleteAssignees = assignees.filter((learningAssignee) => learningAssignee.status === LearningStatus.INCOMPLETE)
    .map((learningAssignee) => (learningAssignee.assignee));

  const startedAssignees = assignees.filter((learningAssignee) => learningAssignee.status === LearningStatus.STARTED)
    .map((learningAssignee) => (learningAssignee.assignee));

  const completeAssignees = assignees.filter((learningAssignee) => learningAssignee.status === LearningStatus.COMPLETED)
    .map((learningAssignee) => (learningAssignee.assignee));

  const onAttendeeItemClick = (assigneeId: string): void => {
    setPersonIndex(assignees.findIndex((assignee) => assignee.assignee.id === assigneeId));

    if (activeTab !== RequestedLearningDrawerTab.PERSON) {
      setActiveTab(RequestedLearningDrawerTab.PERSON);
    }
  };

  const recallPlaylistMutation = useRecallPlaylist();

  const onRecallLearning = (assigneeId: string): void => {
    recallPlaylistMutation({ playlistId, userIds: [assigneeId], isRecallingFromAll: assignees.length === 1 });
  };

  const isMobile = useIsMobileQuery();

  const hookProps = {
    renderHeader: () => (
      <DrawerHeader
        renderCloseButton={(closeButtonStyles: SerializedStyles) => (
          <IconButton onClick={closeDrawerClick} type="button" icon={faTimes} css={closeButtonStyles} tooltip={undefined} />
        )}
        title="Participants"
      />
    ),
    renderBody: (defaultBodyPadding: SerializedStyles) => (
      <div css={defaultBodyPadding}>
        <LearningSectionCollapse
          startCollapseOpen
          renderSectionHeader={({ showContent: showCompleteAssignees, toggleShowContent: toggleShowCompleteAssignees }) => (
            <div css={styles.responseSectionHeader}>
              <h4 css={styles.sectionHeaderText}>
                RESPONDED - CLICK TO SELECT
              </h4>
              <LeadrButton
                variant="text"
                onClick={toggleShowCompleteAssignees}
                data-test-id="learningPlaylistParticipantsShowCompleteAssignees"
              >
                <CollapseSectionHeaderIcon isContentVisible={showCompleteAssignees} />
              </LeadrButton>
            </div>
          )}
          renderCollapseContents={() => (
            <>
              {completeAssignees.map((assignee) => (
                <AttendeeItem
                  id={assignee.id}
                  key={assignee.id}
                  isAttendeeSelected={() => false}
                  onClick={() => onAttendeeItemClick(assignee.id)}
                  css={styles.participantCard}
                />
              ))}
              {completeAssignees.length === 0 && (
                <div css={styles.emptyStateText}>No participants have completed the playlist.</div>
              )}
            </>
          )}
        />
        <LearningSectionCollapse
          css={styles.startedSection}
          startCollapseOpen
          renderSectionHeader={({ showContent: showStartedAssignees, toggleShowContent: toggleShowStartedAssignees }) => (
            <div css={styles.responseSectionHeader}>
              <h4 css={styles.sectionHeaderText}>
                STARTED - CLICK TO SELECT
              </h4>
              <LeadrButton
                variant="text"
                onClick={toggleShowStartedAssignees}
                data-test-id="learningPlaylistParticipantsShowStartedAssignees"
              >
                <CollapseSectionHeaderIcon isContentVisible={showStartedAssignees} />
              </LeadrButton>
            </div>
          )}
          renderCollapseContents={() => (
            <>
              {startedAssignees.map((assignee) => (
                <AttendeeItem
                  id={assignee.id}
                  key={assignee.id}
                  isAttendeeSelected={() => false}
                  onClick={() => onAttendeeItemClick(assignee.id)}
                  css={styles.participantCard}
                />
              ))}
              {startedAssignees.length === 0 && (
                <div css={styles.emptyStateText}>No participants have started and not completed the playlist.</div>
              )}
            </>
          )}
        />
        <LearningSectionCollapse
          css={styles.incompleteSection}
          startCollapseOpen
          renderSectionHeader={({ showContent: showIncompleteAssignees, toggleShowContent: toggleShowIncompleteAssignees }) => (
            <div css={styles.responseSectionHeader}>
              <h4 css={styles.sectionHeaderText}>
                {`NO RESPONSE - ${isMobile ? 'CLICK TO SEE RECALL' : 'HOVER TO SEE RECALL'}`}
              </h4>
              <LeadrButton
                variant="text"
                onClick={toggleShowIncompleteAssignees}
                data-test-id="learningPlaylistParticipantsShowIncompleteAssignees"
              >
                <CollapseSectionHeaderIcon isContentVisible={showIncompleteAssignees} />
              </LeadrButton>
            </div>
          )}
          renderCollapseContents={() => (
            <>
              {incompleteAssignees.map((assignee) => (
                <NoResponseLearningAttendeeCard
                  id={assignee.id}
                  key={assignee.id}
                  isAttendeeSelected={() => false}
                  renderRightItem={(isHovering: boolean) => (
                    <>
                      {isHovering && (
                      <DeleteButtonWithConfirmation
                        renderDeleteButton={({ onClick }) => (
                          <LeadrButton
                            onClick={onClick}
                            color="danger"
                            data-test-id="learningPlaylistRecall"
                            size="small"
                          >
                            Recall
                          </LeadrButton>
                        )}
                        renderConfirmationButtons={({ informationStyles, optionStyles, popoverButtonStyles }) => (
                          <RecallConfirmationButtons
                            informationStyles={informationStyles}
                            optionStyles={optionStyles}
                            popoverButtonStyles={popoverButtonStyles}
                            onRecall={() => onRecallLearning(assignee.id)}
                          />
                        )}
                      />
                      )}
                    </>
                  )}
                  onClick={() => onAttendeeItemClick(assignee.id)}
                  css={styles.participantCard}
                />
              ))}
              {incompleteAssignees.length === 0 && (
                <div css={styles.emptyStateText}>All participants have completed the playlist.</div>
              )}
            </>
          )}
        />
      </div>
    ),
  };

  return (
    <DrawerLayout
      {...hookProps}
    />
  );
};

registerDrawer({
  templateName: playlistParticipantsDrawerTemplate.name,
  component: PlaylistParticipantsDrawer,
});

export default PlaylistParticipantsDrawer;
