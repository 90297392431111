import { useMemo } from 'react';
import {
  ParticipantTypeEnum, ReviewCycleDetail, FilterType,
} from '~Reviews/V2/Const/types';

function cycleWithFilteredReviews(cycle: ReviewCycleDetail, filterType: FilterType[], filterValue: string[], searchText: string): ReviewCycleDetail {
  if (searchText === '' && filterValue.length === 0) {
    return cycle;
  }
  let tempCycle: ReviewCycleDetail = cycle;
  if (filterType.includes(FilterType.Managers)) {
    const filteredReviewsByManager = tempCycle.reviews.filter((review) => {
      const filteredParticipantsManager = review.participants.filter((participant) => (participant.participantTypeEnum === ParticipantTypeEnum.Reviewer
    || participant.participantTypeEnum === ParticipantTypeEnum.SecondaryReviewer)
      && filterValue.includes(`${participant.firstName} ${participant.lastName}`));
      return filteredParticipantsManager.length > 0;
    });
    tempCycle = {
      ...tempCycle,
      reviews: [...filteredReviewsByManager],
    };
  }
  if (filterType.includes(FilterType.Status)) {
    const filteredReviewsByStatus = tempCycle.reviews.filter((review) => filterValue.includes(review.revieweeStatus)
      || filterValue.includes(review.reviewerStatus) || filterValue.includes(review.secondaryReviewerStatus));
    tempCycle = {
      ...tempCycle,
      reviews: [...filteredReviewsByStatus],
    };
  }

  // Eliminate any reviews which do not have participants matching searchText
  const filteredReviewsBySearch = tempCycle.reviews.filter((review) => {
    const filteredParticipantsName = review.participants.filter((participant) => participant.participantTypeEnum === ParticipantTypeEnum.Reviewee
      && `${participant.firstName} ${participant.lastName}`.toLocaleLowerCase().includes(searchText));

    return filteredParticipantsName.length > 0;
  });

  const finalFilteredCycle = {
    ...tempCycle,
    reviews: filteredReviewsBySearch,
  };
  return finalFilteredCycle;
}

export const useFilterParticipantOnCycle = (cycle: ReviewCycleDetail | undefined, searchText: string, filterType: FilterType[], filterValue: string[])
  : ReviewCycleDetail | undefined => {
  const filteredCycle = useMemo(
    () => (cycle ? cycleWithFilteredReviews(cycle, filterType, filterValue, searchText.toLocaleLowerCase()) : cycle),
    [cycle, filterType, filterValue, searchText],
  );

  return filteredCycle;
};
