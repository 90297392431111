import { useMemo } from 'react';
import { StoreApi, UseBoundStore, create } from 'zustand';
import {
  CleanupThingParams,
  CreateShowThingStoreParams,
  CreateUseShowThingActionsParams,
  CreateUseShowThingActionsReturn,
  HideThingParams,
  ShowThingParams,
  ShowThingState,
  UseGetThingPropsParams,
  UseIsThingVisibleParams,
} from './types';

export const createUseShowThingActions = <
  T,
  A extends string,
  B extends string,
  C extends string,
  D extends string,
  E extends string,
>({
    prefix,
    actionNames,
    enableDebugging,
    defaultShow = false,
    defaultIdentifier = 'thing',
  }: CreateUseShowThingActionsParams<A, B, C, D, E>): (() => CreateUseShowThingActionsReturn<T, A, B, C, D, E>) => {
  const useShowThingStore = createShowThingStore<T>({
    identifierPrefix: prefix,
    enableDebugging,
    defaultIdentifier,
  });

  const useIsThingVisible = ({ identifier = defaultIdentifier }: UseIsThingVisibleParams = { identifier: defaultIdentifier }): boolean => (
    useShowThingStore((state) => state.things[`${prefix}${identifier}`]?.show ?? defaultShow)
  );

  const useGetThingProps = ({ identifier = defaultIdentifier }: UseGetThingPropsParams = { identifier: defaultIdentifier }): T => (
    useShowThingStore((state) => state.things[`${prefix}${identifier}`]?.props ?? {} as T)
  );

  // Return all actions and utility hooks with custom names
  return () => {
    const storeActions = useShowThingStore((state) => state.actions);

    return useMemo(() => ({
      [actionNames.showActionName || 'showThing']: storeActions.showThing,
      [actionNames.hideActionName || 'hideThing']: storeActions.hideThing,
      [actionNames.cleanupActionName || 'cleanupThing']: storeActions.cleanupThing,
      [actionNames.useGetThingPropsActionName || 'useGetThingProps']: useGetThingProps,
      [actionNames.useIsThingVisibleActionName || 'useIsThingVisible']: useIsThingVisible,
    }) as CreateUseShowThingActionsReturn<T, A, B, C, D, E>, [storeActions]);
  };
};

const createShowThingStore = <T>({
  enableDebugging = false,
  identifierPrefix,
  defaultIdentifier = '',
}: CreateShowThingStoreParams): UseBoundStore<StoreApi<ShowThingState<T>>> => create<ShowThingState<T>>((set, get) => ({
  things: {},
  actions: {
    showThing: ({ identifier = defaultIdentifier, props }: ShowThingParams<T> = { identifier: defaultIdentifier }) => {
      if (enableDebugging) {
        console.log('Show Thing: ', { identifier, props });
      }
      return set(() => ({
        things: {
          ...get().things,
          [`${identifierPrefix}${identifier}`]: {
            show: true,
            props: props ?? {} as T,
          },
        },
      }));
    },
    hideThing: ({ identifier = defaultIdentifier, propsToPersist = [] }: HideThingParams<T> = { identifier: defaultIdentifier }) => {
      if (enableDebugging) {
        // eslint-disable-next-line no-console
        console.log('Hide Thing: ', { identifier, propsToPersist });
      }
      return set(() => ({
        things: {
          ...get().things,
          [`${identifierPrefix}${identifier}`]: {
            show: false,
            props: propsToPersist.length > 0 ? propsToPersist.reduce((acc, key) => ({
              ...acc,
              [key]: get().things[`${identifierPrefix}${identifier}`].props[key],
            }), {} as T) : {} as T,
          },
        },
      }));
    },
    cleanupThing: ({ identifier = defaultIdentifier }: CleanupThingParams = { identifier: defaultIdentifier }) => set(() => {
      if (enableDebugging) {
        // eslint-disable-next-line no-console
        console.log('Cleanup Thing: ', { identifier });
      }
      const newThings = { ...get().things };
      delete newThings[`${identifierPrefix}${identifier}`];
      return {
        things: newThings,
      };
    }),
  },
}));
