import { ReactText, useRef } from 'react';
import moment from 'moment-timezone';
import {
  InfiniteData,
  useMutation,
  UseMutationResult,
} from '@tanstack/react-query';
import { getHost, hosts } from '~Deprecated/services/config';
import { HttpCallReturn, postApi } from '~Deprecated/services/HttpService';
import { getOrganizationId, getUserId } from '~Common/utils/localStorage';
import { toast } from '~Common/components/Toasts';
import { queryClient } from '~Common/const/queryClient';
import {
  CreateRecognitionShape, ErrorShape, InfiniteQueryParamsProps, PeopleDataReturn,
} from '~Recognition/const/types';
import { recognitionKeys } from '~Recognition/const/queryKeys';
import { useNewPeople } from '~Deprecated/hooks/peoplePicker/useNewPeople';
import { OPTIMISTIC_ID } from '~Recognition/const/defaults';
import { produce } from 'immer';
import { RecognitionEditParams } from './useEditRecognition';
import { RecognitionListResponse } from './useGetRecognition';

interface CreateRecognitionProps {
  recognition: CreateRecognitionShape,
}

const createRecognition = ({ recognition }: CreateRecognitionProps): Promise<HttpCallReturn<string>> => {
  const serverUrl = {
    host: getHost(hosts.recognition),
    uri: `/organizations/${getOrganizationId() ?? ''}/recognition`,
  };

  return postApi<string>(serverUrl, { ...recognition }, {});
};

interface UseCreateRecognitionProps {
  infiniteQueryParams: InfiniteQueryParamsProps,
}

export const useCreateRecognition = ({
  infiniteQueryParams,
// eslint-disable-next-line max-len
}:UseCreateRecognitionProps): UseMutationResult<HttpCallReturn<string>, ErrorShape, Omit<RecognitionEditParams, 'id'>, { previousRecognitionListQuery: unknown; }> => {
  const toastId = useRef<ReactText | number | null>(null);

  // TODO: Remove the casting when useNewPeople gets typed
  const { peopleData } = useNewPeople({
    useOrgIds: true,
    allowSelf: true,
  }) as unknown as Record<string, Record<string, PeopleDataReturn>>;

  const currentUserId = getUserId() ?? '';

  const mutation = useMutation({
    mutationFn: createRecognition,
    onMutate: async (recognitionToAdd: Omit<RecognitionEditParams, 'id'>) => {
      // Cancel any existing outbound queries
      await queryClient.cancelQueries({ queryKey: recognitionKeys.lists() });

      const previousRecognitionListQuery = queryClient.getQueryData(recognitionKeys.list({ ...infiniteQueryParams }));
      const createrPersonInformation = peopleData[currentUserId];

      const createdRecognitionDetails = {
        recognitionId: OPTIMISTIC_ID,
        canDelete: true,
        canEdit: true,
        content: recognitionToAdd.recognition.content,
        createdBy: {
          firstName: createrPersonInformation.firstName,
          lastName: createrPersonInformation.lastName,
          orgUserId: createrPersonInformation.orgUserId,
          profileImageUrl: createrPersonInformation.profileImageUrl ?? '',
        },
        recipients: recognitionToAdd.recognition.recipients.map((recipient) => {
          const recipientPersonInformation = peopleData[recipient];
          return {
            firstName: recipientPersonInformation.firstName,
            lastName: recipientPersonInformation.lastName,
            orgUserId: recipientPersonInformation.orgUserId,
            profileImageUrl: recipientPersonInformation.profileImageUrl ?? '',
          };
        }),
        createdOn: moment().format(),
        modifiedOn: null,
      };

      // eslint-disable-next-line max-len
      queryClient.setQueryData<InfiniteData<HttpCallReturn<RecognitionListResponse>, unknown>>(recognitionKeys.list({ ...infiniteQueryParams }), (oldData) => {
        if (oldData && oldData.pages.length > 0) {
          return produce(oldData, (draft) => {
            draft.pages[0].response.items.unshift(createdRecognitionDetails);
            draft.pages[0].response.items.pop();
          });
        }

        return oldData;
      });

      toastId.current = toast.info('Creating your recognition...', { autoClose: false });

      return {
        previousRecognitionListQuery,
      };
    },
    onError: (error: ErrorShape, _, snapshot) => {
      // Revert the data to the previous data
      queryClient.setQueryData(recognitionKeys.list({ ...infiniteQueryParams }), snapshot?.previousRecognitionListQuery);

      const errorMessage = error?.status?.messages[0]?.message ?? 'There was an error creating your recognition. Please try again.';
      toast.update(toastId.current, {
        render: errorMessage,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    },
    onSuccess: () => {
      toast.update(toastId.current, {
        render: 'Successfully created your recognition.',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });

      void queryClient.invalidateQueries({ queryKey: recognitionKeys.all });
    },
  });

  return mutation;
};
