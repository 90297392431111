import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signout } from '~Deprecated/redux/actions/common';

export const Logout = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(signout());
  history.push('/');

  return <></>;
};
