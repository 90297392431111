import { css } from '@emotion/react';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import { SyntheticEvent } from 'react';
import { PAGE_STYLES, PAGE_STYLES_OVERRIDE } from '~Reviews/V2/Const/pageStyles';
import MultipleSkeletonLoaders from '~Common/components/MultipleSkeletonLoaders';
import { CardSkeleton } from '~Common/V3/components/Card';
import { palette } from '~Common/styles/colors';
import { useSkeletonLoaders } from '~Common/hooks/useSkeletonLoaders';
import { AdminImporterTabValues } from '../Const/types';

const styles = {
  ...PAGE_STYLES,
  ...PAGE_STYLES_OVERRIDE,
  dataTableFilterBarWrapper: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    borderBottom: `1px solid ${palette.neutrals.gray200}`,
  }),
  filtersArea: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  tabs: css({

    '& .MuiTab-root': {
      minWidth: 'unset',
      paddingBottom: '1.5rem',
      paddingLeft: '0',
      paddingRight: '0',
      textTransform: 'unset',
      color: palette.brand.indigo,
      fontWeight: 600,
      fontSize: '.875rem',
    },
    '& .MuiTabs-flexContainer': {
      columnGap: '1.875rem',
      rowGap: '.5rem',
    },
  }),
};

interface ViewProps {
    expandedTab: AdminImporterTabValues,
    changeTab: (event: SyntheticEvent, tab: AdminImporterTabValues) => void,
    showSkeleton: boolean,
    totalWarningRecords: number,
    totalErrorRecords: number,
    totalSuccessfulRecords: number,
    // totalRecords: number,
}

const View = ({
  expandedTab,
  changeTab,
  showSkeleton,
  totalWarningRecords,
  totalErrorRecords,
  totalSuccessfulRecords,
  // totalRecords,
}: ViewProps): JSX.Element => (
  <>
    <div>
      {showSkeleton && (
        <MultipleSkeletonLoaders
          css={styles.topBarSkellyLoader}
          numberOfSkeletons={1}
          renderSkeletonItem={() => (
            <CardSkeleton css={styles.cardSkeleton} />
          )}
        />
      )}
      {!showSkeleton && (
        <>
          <TabContext value={expandedTab}>
            <TabList css={styles.tabs} onChange={changeTab} aria-label="Admin Importer Tabs">
              {Object.entries(AdminImporterTabValues).map(([label, value]) => (
                <Tab
                  data-test-id={`adminImporterTab-${label}`}
                  label={(
                    <>
                      {value === AdminImporterTabValues.Errors && (
                        `${label} (${totalErrorRecords})`
                      )}
                      {value === AdminImporterTabValues.Warnings && (
                        `${label} (${totalWarningRecords})`
                      )}
                      {value !== AdminImporterTabValues.Warnings && value !== AdminImporterTabValues.Errors && (
                        `${label} (${totalSuccessfulRecords})`
                      )}
                      {/* {value !== AdminImporterTabValues.Warnings && value !== AdminImporterTabValues.Errors && value !== AdminImporterTabValues.Edit && (
                        `${label} (${totalSuccessfulRecords})`
                      )} */}
                      {/* {value === AdminImporterTabValues.Edit && (
                        `${label} (${totalRecords})`
                      )} */}
                    </>
                  )}
                  key={value}
                  value={value}
                />
              ))}
            </TabList>
          </TabContext>
        </>
      )}
    </div>
  </>
);

interface AdminImporterTabsProps {
    expandedTab: AdminImporterTabValues,
    changeTab: (event: SyntheticEvent, tab: AdminImporterTabValues) => void,
    isLoading: boolean,
    totalWarningRecords: number,
    totalErrorRecords: number,
    totalSuccessfulRecords: number,
    totalRecords: number,
}

const AdminImporterTabs = ({
  expandedTab,
  changeTab,
  isLoading,
  totalWarningRecords,
  totalErrorRecords,
  totalSuccessfulRecords,
  totalRecords,
}: AdminImporterTabsProps): JSX.Element => {
  const [showSkeleton] = useSkeletonLoaders(isLoading);

  const hookProps = {
    expandedTab,
    changeTab,
    showSkeleton,
    totalWarningRecords,
    totalErrorRecords,
    totalSuccessfulRecords,
    totalRecords,
  };

  return (
    <View
      {...hookProps}
    />
  );
};

export { View };
export default AdminImporterTabs;
