import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { truncate } from '~Deprecated/ui/styles/mixins';
import { cardHeaderTextColor } from '~Deprecated/ui/styles/colors';

const style = (h3Color) => css`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & .avatarGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }

  & h3 {
    font-size: 16px;
    margin-left: 8px;
    vertical-align: middle;
    display: inline-block;
    color: ${h3Color};
  }
`;

/**
 * @deprecated Old Card UI, use packages/leadr-frontend/src/common/V3/components/Card.tsx instead
 */
function CardHeader({
  name, imgUrl, renderAvatar, renderMeta, className, textStyle, textColor,
}) {
  return (
    <div
      css={style(textColor || cardHeaderTextColor)}
      className={`cardHeader ${className}`}
    >
      <div
        className="avatarGroup"
      >
        {renderAvatar({
          imgUrl,
          name,
          size: 32,
        })}
        <h3
          css={[truncate, textStyle]}
        >
          {name}
        </h3>
      </div>
      {renderMeta && renderMeta({})}
    </div>
  );
}

CardHeader.propTypes = {
  name: PropTypes.string,
  imgUrl: PropTypes.string,
  renderMeta: PropTypes.func,
  renderAvatar: PropTypes.func.isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  textStyle: PropTypes.object,
  textColor: PropTypes.string,
};

CardHeader.defaultProps = {
  name: 'Leadr',
  imgUrl: '',
  renderMeta: () => {},
  className: '',
  textStyle: {},
  textColor: null,
};

export default CardHeader;
