import { css } from '@emotion/react';
import { palette } from '~Common/styles/colors';
import yellowQuestionMark from '~Assets/images/empty-views/yellowQuestionMark.png';
import EmptyRecognition from '~Recognition/assets/images/emptyRecognition.svg';
import EmptyReflectionAgendaItem from '~Reviews/assets/images/emptyReflectionAgendaItem.svg';
import EmptyReflectionGoal from '~Reviews/assets/images/emptyReflectionGoal.svg';
import EmptyReflectionLearning from '~Reviews/assets/images/emptyReflectionLearning.svg';
import { ComponentProps } from 'react';
import { ReflectionTypeEnum } from '../../types';

const styles = {
  emptyState: css({
    textAlign: 'center',
    color: palette.brand.indigo,
    fontSize: '0.875rem',
    fontWeight: 600,
  }),
  emptyImage: css({
    height: '100%',
    margin: '1.25rem auto .625rem auto',
    maxHeight: '16rem',
    maxWidth: '16rem',
  }),
  emptyAgendaImage: css({
    maxHeight: '10.5rem',
    maxWidth: '18.625rem',
  }),
  emptyGoalImage: css({
    maxHeight: '15.3125rem',
    maxWidth: '14.25rem',
  }),
  emptyLearningImage: css({
    maxHeight: '13.75rem',
    maxWidth: '14.375rem',
  }),
  emptyRecognitionImage: css({
    maxHeight: '12.25rem',
    maxWidth: '11.375rem',
  }),
};

interface ReflectionEmptyStateProps extends ComponentProps<'div'> {
  headingText: string,
  reflectionType: ReflectionTypeEnum,
}

export const ReflectionEmptyState = ({
  headingText,
  reflectionType,
}: ReflectionEmptyStateProps): JSX.Element => (

  <div css={styles.emptyState}>
    {reflectionType === ReflectionTypeEnum.Accomplishment && (
      <EmptyReflectionAgendaItem css={[styles.emptyImage, styles.emptyAgendaImage]} title="Empty accomplishments" />
    )}
    {reflectionType === ReflectionTypeEnum.BookmarkedTopic && (
      <EmptyReflectionAgendaItem css={[styles.emptyImage, styles.emptyAgendaImage]} title="Empty bookmarked agenda items" />
    )}
    {reflectionType === ReflectionTypeEnum.Feedback && (
      <EmptyReflectionAgendaItem css={[styles.emptyImage, styles.emptyAgendaImage]} title="Empty feedback items" />
    )}
    {reflectionType === ReflectionTypeEnum.Goal && (
      <EmptyReflectionGoal css={[styles.emptyImage, styles.emptyGoalImage]} title="Empty goal items" />
    )}
    {reflectionType === ReflectionTypeEnum.Learning && (
      <EmptyReflectionLearning css={[styles.emptyImage, styles.emptyLearningImage]} title="Empty learning items" />
    )}
    {reflectionType === ReflectionTypeEnum.Recognition && (
      <EmptyRecognition css={[styles.emptyImage, styles.emptyRecognitionImage]} title="Empty recognition items" />
    )}
    {reflectionType === ReflectionTypeEnum.Unknown && (
    <img
      src={yellowQuestionMark}
      css={styles.emptyImage}
      alt="Empty box with yellow question mark"
    />
    )}
    <p>
      {`You have no ${headingText}`}
      <br />
      within the review period.
    </p>
  </div>
);
